import React, {useContext} from 'react';

import {pushQueryParams} from 'client/history';

import {MetricsSelector} from 'client/Reports/MetricsSelector/MetricsSelector.react';

import {DimensionColumn, MetricColumn} from 'client/Reports/reports.types';

import {cloneDeep} from 'lodash';

import {ReportsContext} from '../../Reports.context';

export default function Metrics({isCollapsed}: {isCollapsed?: boolean}) {
  const {
    availableDimensionColumns,
    dimensionColumnKeyToIsDimensionToggledOn,
    dimensions,
    metrics,
    setDimensionColumnKeyToIsDimensionToggledOn,
    topLevelFilterType,
    variables
  } = useContext(ReportsContext);

  const metricKeys = metrics.map((m) => m.key);

  function isSelected(option: DimensionColumn | MetricColumn) {
    const {columnType, key} = option;

    return columnType === 'metric'
      ? metricKeys.includes(key)
      : dimensionColumnKeyToIsDimensionToggledOn.get(key) ?? false;
  }

  function onSelect(option: DimensionColumn | MetricColumn) {
    const {columnType, key} = option;

    if (columnType === 'metric') {
      const updatedMetrics = metricKeys.includes(key)
        ? metrics.filter((metric) => metric.key !== key)
        : [...metrics, option];

      pushQueryParams({
        metrics: updatedMetrics.map((m) => m.key).join(','),
        sort: null
      });
    } else {
      const updatedDimensionColumnKeyToIsDimensionToggledOn = cloneDeep(
        dimensionColumnKeyToIsDimensionToggledOn
      );

      const columnToggleStatus = updatedDimensionColumnKeyToIsDimensionToggledOn.get(key) ?? false;

      updatedDimensionColumnKeyToIsDimensionToggledOn.set(key, !columnToggleStatus);

      setDimensionColumnKeyToIsDimensionToggledOn(updatedDimensionColumnKeyToIsDimensionToggledOn);
    }
  }

  return (
    <MetricsSelector
      dimensions={dimensions}
      filters={variables}
      topLevelFilterType={topLevelFilterType}
      isSelected={(option) => isSelected(option)}
      onSelect={(option) => {
        onSelect(option);
      }}
      isCollapsed={isCollapsed}
      availableDimensionColumns={availableDimensionColumns}
    />
  );
}
