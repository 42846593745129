import React, {forwardRef, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Popper} from 'react-popper';

import {Tooltip} from './Tooltip.react';

const TooltipWithLiveRegion = forwardRef(
  ({className, children, isVisible, onBlur, placement, ...rest}, ref) => {
    return (
      <div onBlur={onBlur} role='status'>
        {isVisible ? (
          <Popper positionFixed target={ref.current} placement={placement}>
            <Tooltip className={className} {...rest}>
              {children}
            </Tooltip>
          </Popper>
        ) : null}
      </div>
    );
  }
);

TooltipWithLiveRegion.displayName = 'TooltipWithLiveRegion';

TooltipWithLiveRegion.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isVisible: PropTypes.bool,
  onBlur: PropTypes.func,
  placement: PropTypes.string
};

/**
 * @typedef {object} RefType
 * @property {object} current - the persisted value, in this case an anchoring component, stored in the ref
 *
 * @typedef {object} componentAndHelpers - TooltipWithLiveRegion instance, props, and related methods
 *
 * This hook takes a node ref and forwards it on to a TooltipWithLiveRegion
 * instance that conditionally displays a tooltip alongside the given DOM reference.
 *
 * The hook returns this TooltipWithLiveRegion instance as well as a set
 * of methods--click, blur, and keypress handlers--for toggling it.
 *
 * Most important for us, this follows best practices for accessible
 * tooltips. Using a live region, we announce the tooltip to screen readers,
 * while not only preserving tab order but also supporting common keyboard
 * interactions, like "Escape".
 *
 * @param {RefType} anchorRef - DOM reference to the anchoring element, required by Popper.js
 *
 * @returns {componentAndHelpers} - TooltipWithLiveRegion instance, props, and related methods
 */
export default function useTooltipWithLiveRegion(anchorRef) {
  const [isVisible, setIsVisible] = useState(false);
  const tooltipContentRef = useRef(null);
  const timeoutsRef = useRef([]);

  useEffect(() => {
    const timeouts = timeoutsRef.current;
    return () => {
      if (timeouts.length) {
        timeouts.forEach((id) => clearTimeout(id));
      }
    };
  }, []);

  const closeTooltip = (e) => {
    if (
      tooltipContentRef.current &&
      (e?.relatedTarget === tooltipContentRef.current ||
        tooltipContentRef.current.contains(e?.relatedTarget))
    ) {
      return;
    }
    setIsVisible(false);
  };

  const openTooltip = () => {
    setIsVisible(false);
    timeoutsRef.current.push(
      setTimeout(() => {
        setIsVisible(true);
      }, 100)
    );
  };

  const handleEscape = (e) => {
    if (e.key === 'Escape') {
      closeTooltip();
    }
  };

  const tooltipProps = {
    isVisible,
    onBlur: closeTooltip,
    ref: anchorRef
  };

  return {
    TooltipWithLiveRegion,
    closeTooltip,
    handleEscape,
    openTooltip,
    tooltipContentRef,
    tooltipProps
  };
}
