import React from 'react';

import {Button, Modal, Dialogue, Text, Icon} from '@albert-io/atomic';
import {history} from 'client/history';
import {callAction} from 'client/framework';

import masqueradeStore from 'client/generic/Masquerade/Masquerade.store';
import masqueradeActions from 'client/generic/Masquerade/Masquerade.actions';

const handleExit = () => {
  callAction(masqueradeActions.DISABLE_MASQUERADING, {
    preventRedirect: true
  });
};

const BlockedAccessModal = () => {
  return (
    <Modal ariaLabel='Blocked Subject Access' dismissable={false}>
      {({modalContentStyle}) => {
        return (
          <Dialogue inModal hideCloseBtn className={modalContentStyle} size='s'>
            <Dialogue.Title align='center'>
              <Icon icon={['far', 'exclamation-triangle']} />
            </Dialogue.Title>
            <Dialogue.Body align='center'>
              <Text paragraph>In order to view subjects, you must view this page as yourself.</Text>
              <Text bold>
                Would you like to stop viewing as {masqueradeStore.getUser().getFullName()} and
                switch to your account?
              </Text>
            </Dialogue.Body>
            <Dialogue.BtnGroup direction='column'>
              <Button color='secondary' onClick={handleExit}>
                Yes, switch to my account
              </Button>
              <Button onClick={history.goBack}>No, go back</Button>
            </Dialogue.BtnGroup>
          </Dialogue>
        );
      }}
    </Modal>
  );
};

export default BlockedAccessModal;
