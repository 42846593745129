// @flow
import PropTypes from 'prop-types';
import {merge} from 'lodash';
import validator from 'validator';
import {
  getResource,
  getResourceMetadata,
  getResourcePromise,
  invalidateInterest,
  isResourcePopulated,
  isResourceReady,
  isResourceValid,
  buildEndpoint
} from 'resources/mandark.resource';

export type QueryBuilder = {
  customQuery: () => QueryBuilder,
  defaultModel: () => QueryBuilder,
  done: () => Object,
  fields: () => QueryBuilder,
  equals: () => boolean,
  filter: () => QueryBuilder,
  findOne: () => QueryBuilder,
  getResource: () => any,
  getResourcePromise: () => Promise<*>,
  include: () => QueryBuilder,
  isResourcePopulated: () => boolean,
  isResourceReady: () => boolean,
  isResourceValid: () => boolean,
  meta: () => QueryBuilder,
  page: () => QueryBuilder,
  pageSize: () => QueryBuilder,
  sort: () => QueryBuilder,
  with: () => QueryBuilder,
  withMeta: () => QueryBuilder
};

function val(a: Object, b: ?boolean, c: ?Object): Object {
  return b ? a : c;
}

function filter(obj): Function {
  return (filterParam) =>
    query(obj)
      .with({filter: filterParam})
      .done();
}

function findOne(obj: Object): Function {
  return () =>
    query(obj)
      .with({resourcePath: obj.resourcePath.unshift('lookup')})
      .done();
}

function customQuery(obj: Object): Function {
  return (queryParam) =>
    query(obj)
      .with({customQuery: queryParam})
      .done();
}

function customHeader(obj: Object): Function {
  return (header) =>
    query(obj)
      .with({customHeader: header})
      .done();
}

function fields(obj: Object): Function {
  return (fields) => customQuery(obj)({fields: fields});
}

function page(obj: Object): Function {
  return (page) => {
    const pageQuery = validator.isUUID(page.toString()) ? {id: page} : {page};
    return customQuery(obj)({page: pageQuery});
  };
}

function pageSize(obj: Object): Function {
  return (pageSize) => customQuery(obj)({page: {page_size: pageSize}});
}

function sort(obj: Object): Function {
  return (sortParam) => customQuery(obj)({sort: sortParam});
}

function meta(obj: Object): Function {
  return (customMeta) => customQuery(obj)({meta: customMeta});
}

function withMeta(obj: Object): Function {
  return (str) => customQuery(obj)({with_meta: str});
}

function includes(obj: Object): Function {
  return (str) =>
    obj.include
      ? Object.assign({}, obj, {include: `${obj.include},${str}`})
      : Object.assign({}, obj, {include: str});
}

function invoke(...args): Function {
  const obj = args[0];
  const a = args[1];
  const b = args.length === 3 ? Boolean(args[2]) : true;

  const v = val(a, b, obj);
  return (func) => (v === obj ? query(v) : query(func(v)));
}

export function query(obj = {}): QueryBuilder {
  return {
    /* eslint-disable sort-keys */
    with: (...args) => invoke(obj, ...args)((merger) => merge(merger, obj)),
    filter: (...args) => invoke(obj, ...args)(filter(obj)),
    findOne: (...args) => invoke(obj, ...args)(findOne(obj)),
    customQuery: (...args) => invoke(obj, ...args)(customQuery(obj)),
    fields: (...args) => invoke(obj, ...args)(fields(obj)),
    page: (...args) => invoke(obj, ...args)(page(obj)),
    pageSize: (...args) => invoke(obj, ...args)(pageSize(obj)),
    sort: (...args) => invoke(obj, ...args)(sort(obj)),
    meta: (...args) => invoke(obj, ...args)(meta(obj)),
    withMeta: (...args) => invoke(obj, ...args)(withMeta(obj)),
    include: (...args) => invoke(obj, ...args)(includes(obj)),
    customHeader: (...args) => invoke(obj, ...args)(customHeader(obj)),
    done: () => obj,
    // Mandark resource methods below
    defaultModel: (defaultModel) => query(merge(obj, {defaultModel})),
    getResource: () => {
      return obj.defaultModel && !isResourcePopulated(obj)
        ? new obj.defaultModel()
        : getResource(obj);
    },
    getResourceMetadata: () => getResourceMetadata(obj),
    getResourcePromise: async () => {
      try {
        return await getResourcePromise(obj);
      } catch (error) {
        throw error;
      }
    },
    invalidateInterest: () => invalidateInterest(obj),
    isResourcePopulated: () => isResourcePopulated(obj),
    isResourceReady: () => isResourceReady(obj),
    isResourceValid: () => isResourceValid(obj),
    equals: (objToCompare) => buildEndpoint(obj) === buildEndpoint(objToCompare.done())
    /* eslint-enable sort-keys */
  };
}

export function mandarkEndpoint(resourcePath): QueryBuilder {
  return query().with({resourcePath});
}

export const queryBuilderPropType = PropTypes.shape({
  customQuery: PropTypes.func,
  defaultModel: PropTypes.func,
  done: PropTypes.func,
  fields: PropTypes.func,
  equals: PropTypes.func,
  filter: PropTypes.func,
  findOne: PropTypes.func,
  getResource: PropTypes.func,
  getResourcePromise: PropTypes.func,
  include: PropTypes.func,
  isResourcePopulated: PropTypes.func,
  isResourceReady: PropTypes.func,
  isResourceValid: PropTypes.func,
  meta: PropTypes.func,
  page: PropTypes.func,
  pageSize: PropTypes.func,
  sort: PropTypes.func,
  with: PropTypes.func,
  withMeta: PropTypes.func
});
