import React, {useContext, useEffect, useState} from 'react';
import {Heading, Icon, Text, LoadingSpinner, Toggle, Card, Tab} from '@albert-io/atomic';
import sessionStore from 'client/Session/SessionStore';
import {ClassroomModelV1} from 'resources/augmented/Classroom/ClassroomModel.v1';
import {SchoolModelV5} from 'resources/augmented/School/SchoolModel.v5';
import {List} from 'immutable';
import {
  GlobalNavBehaviorEnum,
  GlobalNavBehaviorContext
} from 'client/GlobalNavigation/GlobalNavBehavior.context';

import classNames from 'classnames';

import {ReportsContext} from '../Reports.context';
import {getClassroomsQuery, getSchoolsQuery} from '../reports.utils';

import './reports-selector.scss';

import {TopLevelFilter} from '../reports.types';
import Report from '../Report/Report.react';

import ReportSelectorOptions from './ReportSelectorOptions.react';
import SharedBanner from './SharedBanner.react';

function ReportSelector({children}: PropsWithChildrenRequired) {
  const [showArchived, setShowArchived] = useState(false);
  const [schoolsOrClassrooms, setSchoolsOrClassrooms] = useState<TopLevelFilter>('classrooms');
  const isAdmin = sessionStore.isSchoolAdministrator();

  const {navBehavior} = useContext(GlobalNavBehaviorContext);
  const {topLevelFilterType, topLevelFilterId, dimensions} = useContext(ReportsContext);

  const [schools, setSchools] = useState<List<SchoolModelV5>>(List([]));
  const [classrooms, setClassrooms] = useState<List<ClassroomModelV1>>(List([]));

  const [isLoading, setIsLoading] = useState(true);
  const userId = sessionStore.getUserId();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (schoolsOrClassrooms === 'classrooms') {
        const classroomsResponse = await getClassroomsQuery().getResourcePromise();
        setClassrooms(classroomsResponse);
      } else if (schoolsOrClassrooms === 'schools') {
        const schoolsResponse = await getSchoolsQuery(userId).getResourcePromise();
        setSchools(schoolsResponse);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [showArchived, schoolsOrClassrooms, userId]);

  if (isLoading) {
    return <LoadingSpinner size={2} />;
  }

  return (
    <>
      <div
        className={classNames('u-display_flex u-flex-align_center u-flex-direction_column', {
          'reports-landing-page-wrapper': navBehavior === GlobalNavBehaviorEnum.VISIBLE_STICKY
        })}
      >
        <SharedBanner
          classrooms={classrooms}
          topLevelFilterId={topLevelFilterId}
          topLevelFilterType={topLevelFilterType}
          userId={userId}
        />
        {!topLevelFilterId && (
          <div className='reports-landing-page'>
            <div className='reports-landing-page__header u-display_flex u-align-items_center u-bgc_white'>
              <Heading className='u-mar-r_3' as='span'>
                Reports
              </Heading>
            </div>
            <div className='reports-selector__outer-wrapper u-display_flex u-justify-content_center'>
              <div className='reports-selector__wrapper u-display_flex'>
                {isAdmin && <Heading size='s'>Choose...</Heading>}
                <div className='u-display_flex u-flex-direction_row u-justify-content_space-between'>
                  {isAdmin && (
                    <div className='u-display_flex u-flex-direction_row'>
                      <Tab
                        isActive={schoolsOrClassrooms === 'schools'}
                        onClick={() => {
                          setSchoolsOrClassrooms('schools');
                        }}
                      >
                        <Icon className='u-mar-r_1' icon='school' iconStyle='regular' />
                        Schools
                      </Tab>
                      <Tab
                        isActive={schoolsOrClassrooms === 'classrooms'}
                        onClick={() => {
                          setSchoolsOrClassrooms('classrooms');
                        }}
                      >
                        <Icon className='u-mar-r_1' icon='chalkboard-user' iconStyle='regular' />
                        My Classes
                      </Tab>
                    </div>
                  )}
                  {schoolsOrClassrooms === 'classrooms' && classrooms && classrooms.size > 0 && (
                    <>
                      {!isAdmin && <Heading size='s'>Choose...</Heading>}
                      {/* for teachers the Choose... should be displayed only if there are classrooms */}
                      <div className='u-display_flex u-flex-direction_row u-align-items_center'>
                        <Icon
                          className='u-mar-r_1'
                          icon='archive'
                          iconStyle='light'
                          emphasis='lowest'
                          color='primary'
                        />
                        <Text color='tertiary' className='u-mar-r_2'>
                          Show Archived
                        </Text>
                        <Toggle
                          checked={showArchived}
                          onChange={(e) => setShowArchived((e.target as HTMLInputElement).checked)}
                        />
                      </div>
                    </>
                  )}
                </div>
                <Card border='regular' roundness='small' paddingLevel={1}>
                  <ReportSelectorOptions
                    showArchived={showArchived}
                    schoolsOrClassrooms={schoolsOrClassrooms}
                    schools={schools}
                    classrooms={classrooms}
                    isLoading={isLoading}
                  />
                </Card>
              </div>
            </div>
          </div>
        )}
        {topLevelFilterId && dimensions.length === 0 && <Report />}
      </div>
      {children}
    </>
  );
}

export default ReportSelector;
