import React, {useState, useCallback} from 'react';
import {v4 as uuid} from 'uuid';
import {fromJS, Map} from 'immutable';
import SupplementManagerModalButton from 'client/Supplements/SupplementManager/SupplementManagerModalButton.react';
import Button from 'sg/Button/Button.react';
import {AuthoringQuestionModelV1} from 'resources/augmented/AuthoringQuestion/AuthoringQuestionModel.v1';
import AuthoringTextInput from 'client/EditPage/V2/AuthoringTextInput/AuthoringTextInput.react';

import './fill-in-the-blank-editor.scss';
import {useQuestionEditorV2Store} from 'client/EditPage/V2/QuestionEditorV2Store';
import {PRIMARY_CONTENT_LANGUAGE} from 'client/EditPage/V2/QuestionEditorV2Store.types';

import {useShallow} from 'zustand/react/shallow';

import {DropdownEditArea} from './DropdownEditArea';

interface FillInTheBlankEditorProps {
  question: AuthoringQuestionModelV1;
  onModelChange: (question: AuthoringQuestionModelV1, ...fields: any[]) => void;
  validationErrors: Map<string, any>;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const FillInTheBlankEditor = ({
  question,
  onModelChange,
  validationErrors,
  onBlur = () => {}
}: FillInTheBlankEditorProps) => {
  const {currentLanguage, translatedDropdownText, updateTranslatedQuestionField} =
    useQuestionEditorV2Store(
      useShallow((state) => ({
        currentLanguage: state.currentLanguage,
        translatedDropdownText:
          state
            .currentTranslatedQuestion()
            ?.translated_fields?.find((field) => field.field === 'dropdown_text')?.text || '',
        updateTranslatedQuestionField: state.updateTranslatedQuestionField
      }))
    );

  const [cursor, setCursor] = useState(question.getDropdownText()?.length || 0);

  const onChange = useCallback(
    (e) => {
      if (currentLanguage === PRIMARY_CONTENT_LANGUAGE) {
        const updatedQuestion = question.setDropdownText(e.target.value);
        onModelChange(updatedQuestion, 'dropdownText');
      } else {
        updateTranslatedQuestionField(currentLanguage, 'dropdown_text', 'text', e.target.value);
      }
    },
    [question, onModelChange, currentLanguage, updateTranslatedQuestionField]
  );

  const handleBlur = useCallback(
    (e) => {
      onBlur(e);
      setCursor(e.target.selectionStart);
    },
    [onBlur]
  );

  const insertDropdownString = useCallback(
    (e) => {
      e.stopPropagation();
      const text = question.getDropdownText();
      const left = text.slice(0, cursor);
      const right = text.slice(cursor);
      const updatedText = `${left} {{dropdown}} ${right}`;

      const updatedQuestion = question.setDropdownText(updatedText);
      onModelChange(updatedQuestion, 'dropdownText');
    },
    [cursor, question, onModelChange]
  );

  const addDropdown = useCallback(() => {
    const dropdowns = question.getDropdowns();
    const newDropdown = fromJS({
      id: uuid(),
      position: dropdowns.size,
      choices: [
        {
          id: uuid(),
          value: ''
        }
      ]
    });

    const updatedDropdowns = dropdowns
      .push(newDropdown)
      .sortBy((dropdown) => dropdown.get('position'))
      .map((dropdown, position) => dropdown.set('position', position));
    const updatedQuestion = question.setDropdowns(updatedDropdowns);

    onModelChange(updatedQuestion, 'dropdowns');
  }, [question, onModelChange]);

  const onSortEnd = useCallback(
    ({oldIndex, newIndex}) => {
      if (oldIndex === newIndex) {
        return;
      }

      const normalizeIndices = (dropdown, i) => dropdown.set('position', i);
      const dropdowns = question.getDropdowns().map(normalizeIndices);
      const dropdownToMove = dropdowns.get(oldIndex);
      const updatedDropdowns = dropdowns
        .delete(oldIndex)
        .insert(newIndex, dropdownToMove)
        .map(normalizeIndices);

      const updatedQuestion = question.setDropdowns(updatedDropdowns);

      onModelChange(updatedQuestion);
    },
    [question, onModelChange]
  );

  const errors = validationErrors;
  const dropdownTextValue =
    currentLanguage === PRIMARY_CONTENT_LANGUAGE
      ? question.getDropdownText()
      : translatedDropdownText;
  const dropdownTextErrors =
    currentLanguage === PRIMARY_CONTENT_LANGUAGE ? errors.get('dropdownText') : null;
  const dropdownsError =
    errors.get('dropdowns') && typeof errors.get('dropdowns') === 'string' ? (
      <div className='a-form-input__error'>{errors.get('dropdowns')}</div>
    ) : null;

  return (
    <div className='fill-in-the-blank-editor'>
      <AuthoringTextInput
        value={dropdownTextValue}
        error={dropdownTextErrors}
        onChange={onChange}
        className='question-editor__question-detail--fullwidth'
        label='Dropdown Prompt'
        name='dropdownText'
        onBlur={handleBlur}
        data-field-path='dropdown_text'
      />
      {currentLanguage === PRIMARY_CONTENT_LANGUAGE && (
        <div className='u-display_flex'>
          <Button
            className='fill-in-the-blank-editor__insert-dropdown-btn'
            color='gray'
            onClick={insertDropdownString}
          >
            Insert dropdown
          </Button>
          <SupplementManagerModalButton
            /**
             * The `SupplementManagerModalButton` winds up triggering
             * `INSERT_SUPPLEMENT_AT_CURSOR_POSITION`, the handler for
             * this method does a `setFieldIn` so we **must** use the
             * actual property name, not the setter name.
             */
            pathToProperty='dropdown_text'
          />
        </div>
      )}
      {currentLanguage === PRIMARY_CONTENT_LANGUAGE && dropdownsError}
      <DropdownEditArea
        question={question}
        onModelChange={onModelChange}
        validationErrors={validationErrors}
        onSortEnd={onSortEnd}
        helperClass='fill-in-the-blank-editor__drag-helper'
      />
      {currentLanguage === PRIMARY_CONTENT_LANGUAGE && (
        <Button onClick={addDropdown} color='green' text='New dropdown' />
      )}
    </div>
  );
};

export default FillInTheBlankEditor;
