import React from 'react';
import PropTypes from 'prop-types';
import makeRef from 'lib/makeRef';

export default class PassageCorrectionPopover extends React.Component {
  static propTypes = {
    activeIndex: PropTypes.number,
    anchorNode: PropTypes.any, // DOM Node
    rendererWrapperNode: PropTypes.any, // DOM Node
    onReset: PropTypes.func
  };

  static defaultProps = {
    onReset: () => {}
  };

  constructor(props) {
    super(props);
    this.wrapperNode = null;
  }

  componentDidMount() {
    global.addEventListener('click', this.handleClickOutsideComponent);
    global.addEventListener('keydown', this.handleEscapeKeyPress);
    this.positionPopoverHandler();
  }

  componentDidUpdate(prevProps) {
    this.positionPopoverHandler(prevProps.anchorNode);
  }

  componentWillUnmount() {
    global.removeEventListener('scroll', this.positionPopover);
    global.removeEventListener('click', this.handleClickOutsideComponent);
    global.removeEventListener('keydown', this.handleEscapeKeyPress);
  }

  handleClickOutsideComponent = (e) => {
    if (
      this.props.rendererWrapperNode &&
      this.isActive() &&
      !this.props.rendererWrapperNode.contains(e.target)
    ) {
      this.props.onReset();
    }
  };

  handleEscapeKeyPress = (e) => {
    if (e.key === 'Escape' && this.isActive()) {
      this.props.onReset();
    }
  };

  positionPopoverHandler(previousAnchorNode = null) {
    if (!this.isActive()) {
      global.removeEventListener('scroll', this.positionPopover);
      return;
    }

    if (previousAnchorNode !== this.props.anchorNode) {
      global.removeEventListener('scroll', this.positionPopover);
      this.positionPopover();
      global.addEventListener('scroll', this.positionPopover);
    }
  }

  positionPopover = () => {
    const anchorRect = this.props.anchorNode.getBoundingClientRect();
    const top = `${anchorRect.top + anchorRect.height}px`;
    const right = `${global.innerWidth - anchorRect.right - anchorRect.width / 2}px`;
    this.wrapperNode.style.top = top;
    this.wrapperNode.style.right = right;
  };

  isActive() {
    return Boolean(this.props.activeIndex !== null && this.props.anchorNode);
  }

  makeRef = makeRef.bind(this);

  render() {
    if (!this.isActive()) {
      return null;
    }
    return (
      <div
        className='passage-correction-popover card card--no-padding'
        ref={this.makeRef}
        data-ref-name='wrapperNode'
      >
        {this.props.children}
      </div>
    );
  }
}
