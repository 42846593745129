import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {callAction} from 'client/framework';
import {queueConstants} from 'client/Dennis/Content/Queue/Queue.react';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import TextInput from 'generic/Forms/TextInput/TextInput';
import TextArea from 'generic/Forms/TextArea/TextArea.react';
import Button from 'sg/Button/Button.react';
import {AuthoringQuestionModelV1} from 'resources/GeneratedModels/AuthoringQuestion/AuthoringQuestionModel.v1';

import makeRef from 'lib/makeRef';

import passageCorrectionEditorActions from './PassageCorrectionEditor.actions';
import passageCorrectionEditorStore from './PassageCorrectionEditor.store';
import {
  addRegion,
  getActiveRegionIndices,
  getCorrectedWordIndices,
  intersectsExistingRegion,
  isInExistingRegion,
  removeRegion,
  removeAllRegions,
  updateRegion,
  validReponseRegionValidator,
  makeAllWordsEditable
} from './passageCorrectionEditor.utils';

export class DefineCorrectionsEditor extends React.Component {
  static propTypes = {
    onModelChange: PropTypes.func,
    question: PropTypes.instanceOf(AuthoringQuestionModelV1)
  };

  wrapperNode = null;

  parseIndex = (e) => (e.target.dataset.idx ? parseInt(e.target.dataset.idx, 10) : null);

  getPendingIndices() {
    return {
      pendingStartIndex: passageCorrectionEditorStore.getPendingStartIndex(),
      pendingEndIndex: passageCorrectionEditorStore.getPendingEndIndex()
    };
  }

  handleMouseDown = (e) => {
    if (!['TEXTAREA', 'INPUT', 'BUTTON'].includes(e.target.nodeName)) {
      e.preventDefault();
    }
    const index = this.parseIndex(e);
    if (index === null || isInExistingRegion(this.props.question, index)) {
      return;
    }
    callAction(passageCorrectionEditorActions.SELECTION_START, index);
    global.document.addEventListener('mouseup', this.handleMouseUp);
  };

  handleMouseOver = (e) => {
    const index = this.parseIndex(e);
    const {pendingStartIndex} = this.getPendingIndices();
    if (
      index === null ||
      pendingStartIndex === null ||
      intersectsExistingRegion(
        this.props.question,
        ...[pendingStartIndex, index].sort((a, b) => a - b)
      )
    ) {
      return;
    }
    callAction(passageCorrectionEditorActions.SELECTION_UPDATE, {
      pendingStartIndex,
      pendingEndIndex: index
    });
  };

  handleMouseUp = () => {
    const pendingIndices = this.getPendingIndices();
    if (Object.values(pendingIndices).every((index) => index !== null)) {
      const {updatedQuestion, regionIndex} = addRegion(this.props.question, pendingIndices);
      this.props.onModelChange(updatedQuestion);
      // For some reason, the question prop is not updated on the following rerender. This was causing issues
      // with the active region not being found in the question's valid response. Because of this, I'm delaying
      // the calling of this action
      setTimeout(() => {
        callAction(passageCorrectionEditorActions.SET_ACTIVE_REGION, regionIndex);
      }, 0);
    }
    callAction(passageCorrectionEditorActions.SELECTION_END);
    global.document.removeEventListener('mouseup', this.handleMouseUp);
  };

  handleClick = (e) => {
    const {regionIndex} = e.target.dataset;
    if (regionIndex === undefined) {
      return;
    }
    callAction(passageCorrectionEditorActions.SET_ACTIVE_REGION, parseInt(regionIndex, 10));
  };

  onClearSelection = () => {
    const activeRegion = passageCorrectionEditorStore.getActiveRegion();
    const updatedQuestion = removeRegion(this.props.question, activeRegion);
    this.props.onModelChange(updatedQuestion);
    callAction(passageCorrectionEditorActions.SET_ACTIVE_REGION, null);
  };

  onSaveSelection = ({corrections, annotation}) => {
    const updatedQuestion = updateRegion({
      question: this.props.question,
      regionIndex: passageCorrectionEditorStore.getActiveRegion(),
      corrections,
      annotation
    });
    this.props.onModelChange(updatedQuestion);
    callAction(passageCorrectionEditorActions.SET_ACTIVE_REGION, null);
  };

  wordPropsFunc = (text, optionIndex) => {
    const {question} = this.props;
    const hasActiveRegion = passageCorrectionEditorStore.hasActiveRegion();
    const activeRegion = passageCorrectionEditorStore.getActiveRegion();
    const pendingIndices = this.getPendingIndices();
    const activeIndices = hasActiveRegion
      ? getActiveRegionIndices(question, activeRegion)
      : Object.values(pendingIndices).sort((a, b) => a - b);
    const regions = question.getValidResponse();
    const matchedRegionIndex = regions.findKey((region) => {
      return optionIndex >= region.get('start_index') && optionIndex <= region.get('end_index');
    });
    const isCorrected = matchedRegionIndex !== undefined;
    const isActive = activeIndices.every((idx) => idx !== null)
      ? optionIndex >= activeIndices[0] && optionIndex <= activeIndices[1]
      : false;
    const isRangeStart = optionIndex === activeIndices[0];
    const isRangeEnd = optionIndex === activeIndices[1];
    return {
      isActive,
      isRangeEnd,
      isRangeStart,
      isCorrected,
      matchedRegionIndex,
      isSelectable: true,
      text
    };
  };

  handleRemoveAllRegions = () => {
    const updatedQuestion = removeAllRegions(this.props.question);
    callAction(passageCorrectionEditorActions.SET_ACTIVE_REGION, null);
    this.props.onModelChange(updatedQuestion);
  };

  handleMakeAllEditable = () => {
    const returnedQuestion = makeAllWordsEditable(this.props.question);
    if (returnedQuestion !== this.props.question) {
      this.props.onModelChange(returnedQuestion);
    }
  };

  render() {
    const {question} = this.props;
    const activeRegion = passageCorrectionEditorStore.getActiveRegion();
    const rendererClassName = classnames(
      'passage-correction-editor-define-corrections__corrections',
      {
        // eslint-disable-next-line max-len
        'passage-correction-editor-define-corrections__corrections--is-selecting-region':
          passageCorrectionEditorStore.isSelectingRegion()
      }
    );
    return (
      <div className='passage-correction-editor-define-corrections'>
        <p className='passage-correction-editor-define-corrections__label'>Define corrections</p>
        <p className='passage-correction-editor-define-corrections__aside'>
          Define the corrections by highlighting a word or group of words
        </p>
        <MarkdownRendererV2
          passageCorrection
          className={rendererClassName}
          text={question.getUncorrectedText()}
          onClick={this.handleClick}
          onMouseDown={this.handleMouseDown}
          onMouseOver={this.handleMouseOver}
          correctedWordIndices={getCorrectedWordIndices(question)}
          wordPropsFunc={this.wordPropsFunc}
          popover={
            <PassageCorrectionEditorPopover
              onClearSelection={this.onClearSelection}
              onSaveSelection={this.onSaveSelection}
              activeRegion={activeRegion}
              question={question}
            />
          }
        />
        <div className='passage-correction-editor-define-corrections__buttons'>
          <Button color='green' onClick={this.handleMakeAllEditable}>
            Make all words editable
          </Button>
          <Button color='green' onClick={this.handleRemoveAllRegions}>
            Remove all corrections
          </Button>
        </div>
      </div>
    );
  }
}

class PassageCorrectionEditorPopover extends React.Component {
  static propTypes = {
    onClearSelection: PropTypes.func,
    onSaveSelection: PropTypes.func,
    activeRegion: PropTypes.string,
    // eslint-disable-next-line react/no-unused-prop-types
    question: PropTypes.instanceOf(AuthoringQuestionModelV1),
    anchorNode: PropTypes.any // DOM Node
  };

  constructor() {
    super();
    this.wrapperNode = null;
    this.scrollableWrapper = null;
    this.resizeObserver = null;
  }

  state = {
    corrections: [],
    annotation: ''
  };

  componentDidMount() {
    this.scrollableWrapper = global.document.querySelector(
      `.${queueConstants.MAIN_CONTENT_AREA_CLASS_NAME}`
    );
    this.resizeObserver = new ResizeObserver(() => {
      this.positionPopover();
    });
    this.resizeObserver.observe(this.scrollableWrapper);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    /**
     * If we're receiving a new region to edit, reset the state to its initial values.
     */
    if (nextProps.activeRegion !== null && this.props.activeRegion !== nextProps.activeRegion) {
      const currentRegion = nextProps.question
        .getValidResponse()
        .get(nextProps.activeRegion)
        .toJS();
      this.setState({
        corrections: currentRegion.solutions || [],
        annotation: currentRegion.annotation || ''
      });
      return;
    }

    if (!passageCorrectionEditorStore.hasActiveRegion() || !nextProps.anchorNode) {
      this.setState({
        corrections: [],
        annotation: ''
      });
    }
  }

  componentDidUpdate(prevProps) {
    this.positionPopoverHandler(prevProps);
  }

  componentWillUnmount() {
    this.scrollableWrapper.removeEventListener('scroll', this.positionPopover);
    this.resizeObserver.disconnect();
  }

  positionPopoverHandler(prevProps) {
    if (!passageCorrectionEditorStore.hasActiveRegion() || !this.props.anchorNode) {
      this.scrollableWrapper.removeEventListener('scroll', this.positionPopover);
      return;
    }
    if (prevProps.anchorNode !== this.props.anchorNode) {
      this.scrollableWrapper.removeEventListener('scroll', this.positionPopover);
      this.positionPopover();
      this.scrollableWrapper.addEventListener('scroll', this.positionPopover);
    }
  }

  positionPopover = () => {
    if (!this.props.anchorNode) {
      return;
    }
    const anchorRect = this.props.anchorNode.getBoundingClientRect();
    const top = `${anchorRect.top + anchorRect.height}px`;
    const right = `${global.innerWidth - anchorRect.right}px`;
    this.wrapperNode.style.top = top;
    this.wrapperNode.style.right = right;
  };

  updateCorrection = (e) => {
    const updatedCorrections = [...this.state.corrections];
    updatedCorrections[parseInt(e.target.dataset.correctionIndex, 10)] = e.target.value;
    this.setState({
      corrections: updatedCorrections
    });
  };

  addCorrection = () => {
    const updatedCorrections = [...this.state.corrections];
    updatedCorrections.push('');
    this.setState({
      corrections: updatedCorrections
    });
  };

  removeCorrection = (e) => {
    const updatedCorrections = [...this.state.corrections];
    updatedCorrections.splice(parseInt(e.currentTarget.dataset.correctionIndex, 10), 1);
    this.setState({
      corrections: updatedCorrections
    });
  };

  updateAnnotation = (e) => {
    this.setState({
      annotation: e.target.value
    });
  };

  getError() {
    const activeRegion = this.props.question.getValidResponse().get(this.props.activeRegion);
    const {corrections, annotation} = this.state;
    return validReponseRegionValidator({
      activeRegion,
      annotation,
      pendingCorrections: corrections
    });
  }

  handleSaveSelection = () => {
    if (this.getError() !== null) {
      return;
    }
    const {corrections, annotation} = this.state;
    this.props.onSaveSelection({corrections, annotation});
  };

  makeRef = makeRef.bind(this);

  render() {
    if (!passageCorrectionEditorStore.hasActiveRegion() || !this.props.anchorNode) {
      return null;
    }
    const [inputCorrection, ...restCorrections] = this.state.corrections;
    const error = this.getError();
    return (
      <div
        className='passage-correction-editor-popover card card--no-padding'
        ref={this.makeRef}
        data-ref-name='wrapperNode'
      >
        <div className='passage-correction-editor-popover__section'>
          <TextInput
            label='Correction'
            value={inputCorrection}
            onChange={this.updateCorrection}
            data-correction-index={0}
          />
          {restCorrections.map((correction, i) => (
            <div className='passage-correction-editor-popover__alt-correction-wrapper' key={i}>
              <TextInput
                value={correction}
                onChange={this.updateCorrection}
                data-correction-index={i + 1}
              />
              <Button
                linkButton
                color='gray'
                onClick={this.removeCorrection}
                data-correction-index={i + 1}
              >
                <span className='fa fa-times' />
              </Button>
            </div>
          ))}
          <div className='passage-correction-editor-popover__add-alt-correction-wrapper'>
            <Button linkButton text='Add alternate correction' onClick={this.addCorrection} />
          </div>
        </div>
        <div className='passage-correction-editor-popover__section'>
          <TextArea
            label='Annotation'
            placeholder='This annotation will appear on hover when a student is reviewing their performance.'
            onChange={this.updateAnnotation}
            value={this.state.annotation}
          />
          <div className='passage-correction-editor-popover__actions'>
            <Button
              whiteBg
              text='Clear selection'
              color='red'
              onClick={this.props.onClearSelection}
            />
            <Button
              text='Save'
              onClick={this.handleSaveSelection}
              disabled={error !== null}
              disabledTooltipContent={error}
            />
          </div>
        </div>
      </div>
    );
  }
}
