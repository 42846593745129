import React, {useState, useCallback} from 'react';
import {pluralize, upperFirst} from 'lib/stringUtils';
import awaitMandarkQueries from 'lib/hocs/awaitMandarkQueries';

import {resource} from '@albert-io/json-api-framework/request/builder';
import OldButton from 'sg/Button/Button.react';

import {AuthoringQuestionSetModelV1} from 'resources/GeneratedModels/AuthoringQuestionSet/AuthoringQuestionSetModel.v1';

import {Text, Button, Chip, WithTooltip} from '@albert-io/atomic';
import FeatureFlag from 'client/components/FeatureFlag/FeatureFlag.react';
import questionEditorStore from 'client/EditPage/V2/QuestionEditor/QuestionEditor.store';

import {QuestionEditorIcon} from '../QuestionEditorIcon/QuestionEditorIcon';

import './question-set-alignment.scss';
import {QuestionSetAlignmentModal} from './QuestionSetAlignmentModal.react';

function getQuestionSetQuery(questionSetId: string) {
  return resource('authoring_question_set_v1')
    .mandarkEndpoint(['authoring_question_sets_v1', questionSetId])
    .include('authoring_subject_v1.authoring_guides_v1.authoring_guide_levels_v1')
    .include('authoring_questions_v1')
    .include('authoring_guide_levels_v1')
    .include('sections_v1')
    .withMeta('authoring_guide_level_v1,authoring_guide_v1,authoring_question_set_v1')
    .meta({
      context: {
        // eslint-disable-next-line camelcase
        alignment_details: true
      }
    });
}

interface QuestionSetAlignmentExposedProps {
  questionSetId?: string;
}

export default awaitMandarkQueries(
  ({questionSetId}: QuestionSetAlignmentExposedProps) => ({
    queries: questionSetId
      ? {
          questionSet: getQuestionSetQuery(questionSetId)
        }
      : {}
  }),
  function QuestionSetAlignment({questionSet}) {
    return (
      <FeatureFlag name='question_editor_v2'>
        <QuestionSetAlignmentV2 questionSet={questionSet} />
        <FeatureFlag.Fallback>
          <QuestionSetAlignmentV1 questionSet={questionSet} />
        </FeatureFlag.Fallback>
      </FeatureFlag>
    );
  }
);

interface QuestionSetAlignmentProps {
  questionSet?: AuthoringQuestionSetModelV1;
}

const QuestionSetAlignmentV2 = ({questionSet}: QuestionSetAlignmentProps) => {
  const [showModal, setShowModal] = useState(false);

  const showModalHandler = useCallback(() => {
    setShowModal(true);
  }, []);

  const closeModalHandler = useCallback(() => {
    setShowModal(false);
  }, []);

  return (
    <div className='u-display_flex u-flex-direction_column u-gap_space-x1'>
      <div className='u-display_flex u-justify-content_space-between u-align-items_center'>
        <div className='u-display_flex u-gap_space-x4 u-align-items_center'>
          <QuestionEditorIcon icon={['fal', 'list-tree']} />
          <Text bold>Guide Level</Text>
        </div>

        <WithTooltip
          content='Please save the question before changing its alignment'
          enabled={!questionSet}
        >
          <Button onClick={questionSet ? showModalHandler : () => {}} disabled={!questionSet}>
            Update alignment
          </Button>
        </WithTooltip>
      </div>
      <ExistingAlignment questionSet={questionSet} />
      {showModal ? (
        <QuestionSetAlignmentModal closeModal={closeModalHandler} questionSet={questionSet} />
      ) : null}
    </div>
  );
};

const ExistingAlignment = ({questionSet}: QuestionSetAlignmentProps) => {
  const forecastedAlignments = questionSet?.getMeta()?.getForecastedAlignment();

  if (!forecastedAlignments) {
    return null;
  }

  const paths = forecastedAlignments
    .map((alignment) => {
      return alignment
        .map((level) => {
          return level.get('name');
        })
        .join(' → ');
    })
    .toJS();

  return (
    <div className='u-display_flex u-gap_space-x2 u-mar-l_7 u-mar-t_2'>
      {paths.map((path) => (
        <Chip key={path} color='brand' icon='times' className='u-white-space_normal'>
          {path}
        </Chip>
      ))}
    </div>
  );
};

const QuestionSetAlignmentV1 = ({questionSet}: QuestionSetAlignmentProps) => {
  const [showModal, setShowModal] = useState(false);

  const showModalHandler = useCallback(() => {
    setShowModal(true);
  }, []);

  const closeModalHandler = useCallback(() => {
    setShowModal(false);
  }, []);

  const getUpdateAlignmentButton = useCallback(() => {
    const isDisabled = !questionSet || !questionEditorStore.getQuestion().getChangeMap().isEmpty();
    return (
      <OldButton
        text='Update alignment'
        onClick={questionSet ? showModalHandler : null}
        disabled={isDisabled}
        disabledTooltipContent='Please save the question before changing its alignment'
      />
    );
  }, [questionSet, showModalHandler]);

  const getAlignmentStrings = useCallback((alignments) => {
    const paths = alignments.reduce((acc, alignment) => {
      acc.push(
        alignment
          .map((level) => {
            return level.get('name');
          })
          .join(' → ')
      );
      return acc;
    }, []);

    return paths.map((path) => (
      <span key={path} className='current-alignment__content'>
        {path}
      </span>
    ));
  }, []);

  const getCurrentAlignment = useCallback(() => {
    if (!questionSet) {
      return null;
    }
    const forecastedAlignments = questionSet.getMeta().getForecastedAlignment();

    if (!forecastedAlignments) {
      return null;
    }

    const displayPaths = forecastedAlignments ? getAlignmentStrings(forecastedAlignments) : [];

    return (
      <p className='current-alignment'>
        <strong>
          Current {upperFirst(questionSet.getCategory())}{' '}
          {pluralize('Alignment', displayPaths.length)}:
        </strong>
        {displayPaths}
      </p>
    );
  }, [questionSet, getAlignmentStrings]);

  return (
    <div>
      <p>
        <strong>Question Set Alignment</strong>
      </p>
      {getUpdateAlignmentButton()}
      {getCurrentAlignment()}
      {showModal ? (
        <QuestionSetAlignmentModal closeModal={closeModalHandler} questionSet={questionSet} />
      ) : null}
    </div>
  );
};
