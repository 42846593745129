// @flow
import {Map} from 'immutable';
import {MandarkResourceStatsInterface} from '../Mandark.interface';
import stats from 'lib/StatsUtil';

export class AssignmentStudentStatsInterface extends MandarkResourceStatsInterface {
  getId() {
    return 'id';
  }
  getQuestionsAnswered(id: string) {
    return this.getStatsForIdInFieldname('students', id).get('questions_answered');
  }

  getQuestionsCorrectlyAnswered(id: string) {
    return this.getStatsForIdInFieldname('students', id).get('questions_answered_correctly');
  }

  getCompletionDateTime(id: string) {
    return this.getStatsForIdInFieldname('students', id).get('completed_at');
  }

  getQuestionsInAssignment() {
    return this._data.getIn(['data', 'questions_in_assignment']);
  }

  getStudentAccuracies(): Map<string, number> {
    const totalQuestions = this.getQuestionsInAssignment();

    return this._data.getIn(['data', 'students']).reduce((accuracies, student, id) => {
      return accuracies.set(
        id,
        stats.percentage(student.get('questions_answered_correctly'), totalQuestions)
      );
    }, new Map());
  }

  getStudentStatuses(): Map<string, string> {
    return this._data.getIn(['data', 'students']).reduce((statuses, student, id) => {
      return statuses.set(
        id,
        stats.getStatusNameByAccuracy(
          stats.percentage(
            student.get('questions_answered_correctly'),
            student.get('questions_answered')
          ),
          student.get('questions_answered')
        )
      );
    }, new Map());
  }

  getAverageGradeForStudents(): number {
    const totalQuestions = this.getQuestionsInAssignment();
    const totalGrade = this._data
      .getIn(['data', 'students'], new Map())
      .reduce((reduction, student) => {
        const grade: number = stats.percentage(
          student.get('questions_answered_correctly'),
          totalQuestions
        );
        return reduction + grade;
      }, 0);

    const totalStudents = this._data.getIn(['data', 'students'], new Map()).size;
    return stats.divide(totalGrade, totalStudents);
  }

  get all(): Map<string, Map<*>> {
    return this._data.getIn(['data', 'students']);
  }
}
