import React from 'react';

import Text, {Props as TextProps} from '../Text/Text.react';

import {CommonTextProps} from './types';

export interface Props extends CommonTextProps {
  color?: TextProps['color'];
}

export default function MenuListItemCategoryText({
  className,
  children,
  size = 'm',
  ...rest
}: Props) {
  return (
    <Text className={className} bold size={size} color='tertiary' {...rest}>
      {children}
    </Text>
  );
}
