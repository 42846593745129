import {Store} from 'client/framework';
import appStore from 'client/AppStore';
import {mandarkEndpoint} from '@albert-io/json-api-framework/request/builder/legacy';
import {GuideLevelModelV2} from 'resources/augmented/GuideLevel/GuideLevelModel.v2';
import {history} from 'client/history';

class TopicSummaryStore extends Store {
  getGuideLevelQuery() {
    return mandarkEndpoint(['guide_levels_v2'])
      .defaultModel(GuideLevelModelV2)
      .findOne()
      .include('parent_v2.subject_v2,subject_v2')
      .filter({url_slug: appStore.routerProps.params.guideLevelSlug})
      .filter({
        guide_v1: {
          guide_type: 'practice'
        }
      })
      .filter({
        subject_v2: {
          url_slug: appStore.routerProps.params.subject
        }
      });
  }

  getGuideLevel() {
    return this.getGuideLevelQuery().getResource();
  }

  isTopicSummaryPath() {
    return appStore.routerProps.location.pathname.split('/').pop() === 'topic-summary';
  }

  isTopicSummaryValid() {
    return (
      /**
       * Can only have valid topic summary if there is actually a guideLevelSlug
       */
      appStore.routerProps.params.guideLevelSlug &&
      this.getGuideLevelQuery().isResourcePopulated() &&
      (this.getGuideLevel().getSummary() || this.getGuideLevel().getDescription())
    );
  }

  /**
   * If we're viewing the topic summary, we return the path to the IE without /topic-summary
   * at the end. If we're viewing the regular IE, we return the path with /toic-summary appended
   */
  getTopicSummaryLinkPath() {
    const routeParams = appStore.routerProps.params;
    const basePath = `/learn/${routeParams.subject}/${routeParams.guideLevelSlug}`;
    return this.isTopicSummaryPath() ? basePath : `${basePath}/topic-summary`;
  }

  pushStateToQuestionPath() {
    if (!this.isTopicSummaryPath()) {
      return;
    }
    const location = history.getCurrentLocation();
    const splitPath = location.pathname.split('/');
    let updatedPath = splitPath.slice(0, splitPath.length - 1).join('/');
    const search = location.search;
    if (search) {
      updatedPath += search;
    }
    history.pushState(null, updatedPath);
  }

  pushStateToTopicSummaryPath() {
    if (this.isTopicSummaryPath()) {
      return;
    }
    const {subject, guideLevelSlug} = appStore.routerProps.params;
    let updatedPath = `/learn/${subject}/${guideLevelSlug}/topic-summary`;
    const search = history.getCurrentLocation().search;
    if (search) {
      updatedPath += search;
    }
    history.pushState(null, updatedPath);
  }
}

export default new TopicSummaryStore('TopicSummaryStore');
