import React from 'react';
import classnames from 'classnames';
import {callAction} from 'client/framework';

import {Button, Text, Logo, LoadingSpinner} from '@albert-io/atomic';

import masqueradePanelStore from '../Masquerade.store';
import masqueradeActions from '../Masquerade.actions';

import './masquerade-panel.scss';

export default class MasqueradePanel extends React.Component {
  handleExit() {
    callAction(masqueradeActions.DISABLE_MASQUERADING);
  }

  generatePanelContents() {
    if (masqueradePanelStore.isMasquerading() === false) {
      return null;
    }

    const userInformation =
      masqueradePanelStore.getUserQuery().isResourceReady() &&
      masqueradePanelStore.getSchoolQuery().isResourceReady() ? (
        <Text as='div' color='primary-inverse' className='u-mar_1'>
          <b>{masqueradePanelStore.getUser().getFullName()}</b>
          <br />
          <small>{masqueradePanelStore.getSchool().getName()}</small>
        </Text>
      ) : (
        <LoadingSpinner
          className='masquerade-panel__spinner'
          assistiveText='Fetching account details...'
          color='white'
        />
      );

    return (
      <>
        <div className='masquerade-panel__logo'>
          <Logo width='112px' light type='combined' />
        </div>
        <div className='u-text-align_right u-display_flex u-align-items_center'>
          {userInformation}
          <Button className='u-mar-l_1' size='xs' color='positive' onClick={this.handleExit}>
            Back to my account
          </Button>
        </div>
      </>
    );
  }

  render() {
    return (
      <div
        className={classnames('masquerade-panel u-pad_1', {
          'masquerade-panel--visible': masqueradePanelStore.isMasquerading()
        })}
      >
        {this.generatePanelContents()}
      </div>
    );
  }
}
