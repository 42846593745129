/**
 * # MenuListItem
 *
 * A component that represents a list item with hover and click states. It can display text, icons, and handle click events.
 *
 * ## Props
 *
 * - `as` (optional): The HTML element or React component to be rendered as the root element of the component. Default is `div`.
 * - `children` (optional): The content to be rendered inside the MenuListItem.
 * - `className` (optional): Additional CSS class names to be applied to the MenuListItem.
 * - `clickable` (optional): Determines if the MenuListItem is clickable. Default is `true`.
 * - `hoverable` (optional): Determines if the MenuListItem is hoverable. Default is `true`.
 * - `isCategoryItem` (optional): Determines if the MenuListItem is a category item. Default is `false`.
 * - `iconButtonProps` (optional): Additional props to be passed to the IconButton component used in the MenuListItem.
 * - `text` (optional): The text to be displayed in the MenuListItem.
 * - `icon` (optional): The icon to be displayed in the MenuListItem.
 * - `onMenuItemClick` (optional): The click event handler for the MenuListItem.
 * - `onIconButtonClick` (optional): The click event handler for the IconButton component used in the MenuListItem.
 * - `selected` (optional): Determines if the MenuListItem is selected. Default is `false`.
 * - `disableWhenSelected` (optional): Determines if the MenuListItem should be disabled when selected. Default is `true`.
 * - `indentLevel` (optional): The indent level of the MenuListItem. Can be '0', '1', or '2'.
 *
 * ## Usage
 *
 * ```tsx
 * import { MenuListItem } from './MenuListItem';
 *
 * const MyComponent = () => {
 *   return (
 *     <MenuListItem
 *       text="Menu Item"
 *       icon="icon-name"
 *       onMenuItemClick={() => {
 *         console.log('Menu item clicked');
 *       }}
 *     />
 *   );
 * };
 * ```
 */

import classnames from 'classnames';

import React, {createRef, ElementType, forwardRef, KeyboardEvent, MouseEvent} from 'react';

import IconButton, {IconButtonProps} from '@albert-io/atomic/molecules/IconButton/IconButton.react';

import Icon, {IconProp} from '../Icon/Icon.react';

import MenuListItemCategoryText, {
  Props as MenuListItemCategoryTextProps
} from './MenuListItemCategoryText';
import MenuListItemRegularText, {
  Props as MenuListItemRegularTextProps
} from './MenuListItemRegularText';

import './menu-list-item.scss';

type HandleButtonClickType = () => void;

// requires button label along with icon button click handler for aria support
export type ConditionalButtonClickProps =
  | {
      iconButtonLabel: string;
      iconButtonProps?: Partial<IconButtonProps<'button'>>;
      onIconButtonClick?: HandleButtonClickType;
    }
  | {
      iconButtonLabel?: never;
      iconButtonProps?: never;
      onIconButtonClick?: never;
    };

interface RegularTextProps {
  categoryTextProps?: never;
  isCategoryItem: false;
  regularTextProps?: Partial<MenuListItemRegularTextProps>;
  text?: string;
}

interface CategoryTextProps {
  categoryTextProps?: Partial<MenuListItemCategoryTextProps>;
  isCategoryItem: true;
  regularTextProps?: never;
  text?: string;
}

export type ConditionalTextProps = RegularTextProps | CategoryTextProps;

type ConditionalProps =
  | ({
      iconButtonLabel: never;
      iconButtonProps?: never;
      onIconButtonClick?: never;
      text?: never;
      icon?: never;
    } & PropsWithChildrenOptional)
  | ({
      children?: never;
      icon?: IconProp;
    } & ConditionalButtonClickProps &
      ConditionalTextProps);

export interface CommonProps<C extends ElementType = 'div'> extends PropsWithClassNameOptional {
  as?: C;
  clickable?: boolean;
  disableWhenSelected?: boolean;
  hoverable?: boolean;
  indentLevel?: '0' | '1' | '2';
  isCategoryItem?: boolean;
  // onClick applied to entire item
  onMenuItemClick?: () => void; // TODO: determine if this needs to pass an event
  selected?: boolean;
}

export type Props<C extends ElementType> = PolymorphicComponentProps<
  C,
  CommonProps<C> & ConditionalProps
>;

function MenuListItem<C extends ElementType = 'div'>(
  {
    as,
    categoryTextProps,
    children,
    className,
    clickable = true,
    disableWhenSelected = true,
    hoverable = true,
    icon,
    iconButtonLabel,
    iconButtonProps,
    indentLevel = '0',
    isCategoryItem,
    onIconButtonClick,
    onMenuItemClick,
    regularTextProps,
    selected = false,
    text = '',
    ...rest
  }: Props<C>,
  ref
) {
  const handleIconButtonClick = (e: MouseEvent) => {
    if (onIconButtonClick !== undefined) {
      onIconButtonClick();
    }

    e.stopPropagation();
  };

  const WrapperElement = as || 'div';
  const menuItemRef = ref ?? createRef();

  function getChildren() {
    const textClasses = classnames(
      'a-menu-list-item__text',
      'u-align-items_center u-display_flex u-pad-r_2 u-width_100pc',
      `u-pad-l_${parseInt(indentLevel, 10) * 2 + 2}`
    );

    return (
      <>
        {text !== '' && (
          <div
            className={classnames('u-display_flex u-flex-grow_1', {
              'a-menu-list-item--hoverable': hoverable
            })}
          >
            {isCategoryItem ? (
              <MenuListItemCategoryText className={textClasses} {...categoryTextProps}>
                {text}
              </MenuListItemCategoryText>
            ) : (
              <MenuListItemRegularText
                className={textClasses}
                selected={selected}
                {...regularTextProps}
              >
                {text}
              </MenuListItemRegularText>
            )}
          </div>
        )}

        {icon !== undefined && (
          <div
            className={classnames('u-align-items_center u-display_flex u-justify-content_center', {
              'a-menu-list-item__decorative-icon': onIconButtonClick === undefined,
              'a-menu-list-item--hoverable': hoverable,
              'u-width_100pc': text === ''
            })}
          >
            {onIconButtonClick === undefined ? (
              <Icon icon={icon} />
            ) : (
              <IconButton
                label={iconButtonLabel}
                onClick={handleIconButtonClick}
                icon={icon}
                {...iconButtonProps}
              />
            )}
          </div>
        )}
      </>
    );
  }

  // Since we do have support for an iconButton to be a child we need this to make sure that keypresses on the iconButtons don't activate the parent
  const handleKeyPress = (e: KeyboardEvent) => {
    const keys = [' ', 'Enter'];
    if (global.document.activeElement === menuItemRef?.current && keys.includes(e.key)) {
      e.preventDefault();
      onMenuItemClick?.();
    }
  };

  return (
    <WrapperElement
      className={classnames(
        className,
        'a-menu-list-item',
        'u-border_none',
        'u-border-radius_1',
        'u-display_flex',
        'u-overflow_hidden',
        'u-position_relative',
        'u-text-decoration_none',
        'u-width_100pc',
        {
          'a-menu-list-item--clickable': clickable,
          'a-menu-list-item--disabled': disableWhenSelected && selected,
          'a-menu-list-item--hoverable': hoverable && children !== undefined,
          'a-menu-list-item--segmented': text !== '' && icon !== undefined,
          'a-menu-list-item--selected': selected,
          'background-brand-subtle': selected,
          'content-brand': selected,
          'u-cursor_pointer': clickable,
          'u-justify-content_center': !text,
          'u-justify-content_space-between': text,
          'u-pad-lr_2 u-pad-tb_1': children !== undefined
        }
      )}
      ref={menuItemRef}
      data-name='menu-list-item'
      disabled={WrapperElement === 'div' ? undefined : disableWhenSelected && selected}
      role={clickable ? 'button' : undefined}
      tabIndex={clickable ? 0 : undefined}
      onClick={onMenuItemClick}
      onKeyPress={handleKeyPress}
      {...rest}
    >
      {children ?? getChildren()}
    </WrapperElement>
  );
}

export default forwardRef(MenuListItem);
