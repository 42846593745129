import React, {useCallback, useMemo, useState} from 'react';
import {addToast, Button, Dropdown, DropdownItem, Icon, Text} from '@albert-io/atomic';

import {useShallow} from 'zustand/react/shallow';

import {PRIMARY_CONTENT_LANGUAGE} from 'client/EditPage/V2/QuestionEditorV2Store.types';

import {RegenerateTranslationsModal} from 'client/EditPage/V2/Translations/RegenerateTranslationsModal';

import {useSupplementEditorV2Store} from './SupplementEditorV2Store';
import supplementEditorStore from './SupplementEditor.store';

export const GenerateTranslationsButton = () => {
  const {
    generateTranslations,
    generatingTranslations,
    currentLanguage,
    meetsTranslationRequirements,
    translationsHaveChanges,
    hasPublishedTranslations
  } = useSupplementEditorV2Store(
    useShallow((state) => ({
      generateTranslations: state.generateTranslations,
      generatingTranslations: state.generatingTranslations,
      currentLanguage: state.currentLanguage,
      meetsTranslationRequirements:
        !!state.currentTranslatedSupplement()?.meets_translation_requirements,
      translationsHaveChanges: state.hasChanges(),
      hasPublishedTranslations: state.hasPublishedTranslations()
    }))
  );

  const activeSupplement = supplementEditorStore.getActiveSupplement();

  const canGenerateTranslations = useMemo(() => {
    if (!activeSupplement) {
      return false;
    }
    const isNew = !activeSupplement.existsOnServer();
    const supplementHasChanges =
      !activeSupplement.getChangeMap().isEmpty() || translationsHaveChanges;

    return !isNew && !supplementHasChanges && activeSupplement.isTranslate();
  }, [activeSupplement, translationsHaveChanges]);

  const doGenerateTranslations = useCallback(
    async (type: 'full' | 'partial') => {
      if (currentLanguage === PRIMARY_CONTENT_LANGUAGE) {
        return;
      }
      try {
        await generateTranslations(currentLanguage, type);
        addToast({
          color: 'positive',
          message: 'Translations generated successfully',
          duration: 4000
        });
      } catch (e) {
        addToast({
          message: 'There was an error generating translations. Please try again.',
          title: 'Error',
          color: 'negative'
        });
      }
    },
    [currentLanguage, generateTranslations]
  );

  const [showConfirmRegenerateModal, setShowConfirmRegenerateModal] = useState(false);

  return (
    <>
      {hasPublishedTranslations ? (
        <Dropdown
          trigger={
            <Button
              disabled={!canGenerateTranslations || generatingTranslations}
              variant='outlined'
            >
              Generate Translations
              {generatingTranslations && <Icon icon='refresh' spin className='u-mar-l_1' />}
              {!generatingTranslations && <Icon icon='caret-down' className='u-mar-l_1' />}
            </Button>
          }
          closeOnItemClicked
        >
          <DropdownItem onClick={() => setShowConfirmRegenerateModal(true)} bold={false}>
            <div className='u-display_flex u-flex-direction_column'>
              <Text size='m'>Full Translation</Text>
              <Text size='xs' color='secondary'>
                Translate all fields, including published fields
              </Text>
            </div>
          </DropdownItem>
          <DropdownItem
            disabled={meetsTranslationRequirements}
            onClick={() => doGenerateTranslations('partial')}
            bold={false}
          >
            <div className='u-display_flex u-flex-direction_column'>
              <Text size='m'>Partial Translation</Text>
              <Text size='xs' color='secondary'>
                Translate fields, excluding published fields.
              </Text>
            </div>
          </DropdownItem>
        </Dropdown>
      ) : (
        <Button
          onClick={() => doGenerateTranslations('full')}
          disabled={!canGenerateTranslations || generatingTranslations}
        >
          <span>Generate Translations</span>
          {generatingTranslations && <Icon icon='refresh' spin className='u-mar-l_1' />}
        </Button>
      )}

      {showConfirmRegenerateModal && (
        <RegenerateTranslationsModal
          type='supplement'
          doGenerateTranslations={() => doGenerateTranslations('full')}
          setShowConfirmRegenerateModal={setShowConfirmRegenerateModal}
        />
      )}
    </>
  );
};
