import React from 'react';
import PropTypes from 'prop-types';
import {trim} from 'lodash';
import {List, Map} from 'immutable';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import createLocalStore from 'lib/decorators/createLocalStore';
import Button from 'sg/Button/Button.react';

import {AuthoringQuestionModelV1} from 'resources/augmented/AuthoringQuestion/AuthoringQuestionModel.v1';

import formatHighlightPrompt from './formatHighlightPrompt';

@createLocalStore(
  Map({
    isHighlightDisabled: false
  }),
  'AnswerPermutationStore'
)
class AnswerPermutation extends React.Component {
  static propTypes = {
    highlightPrompt: PropTypes.string,
    highlights: PropTypes.instanceOf(List),
    permutationId: PropTypes.string,
    preferredPermutationId: PropTypes.string,
    question: PropTypes.instanceOf(AuthoringQuestionModelV1),
    onModelChange: PropTypes.func,
    setProperty: PropTypes.func,
    store: PropTypes.object,
    error: PropTypes.node
  };

  changePreferredPermutation = () => {
    const {permutationId} = this.props;
    const updatedQuestion = this.props.question.setPreferredPermutation(permutationId);

    this.props.onModelChange(updatedQuestion);
  };

  removePermutation = () => {
    const validResponse = this.props.question.getValidResponse().remove(this.props.permutationId);
    let updatedQuestion = this.props.question.setValidResponse(validResponse);

    if (this.props.permutationId === this.props.preferredPermutationId) {
      updatedQuestion = updatedQuestion.setPreferredPermutation('');
    }

    this.props.onModelChange(updatedQuestion, 'preferredPermutation');
  };

  handleMouseDown = (e) => {
    const targetData = e.target.dataset;
    const {start} = targetData;
    const {end} = targetData;

    if (start && end) {
      this.removeTextSelection(parseInt(start, 10));
    }

    this.props.setProperty('isHighlightDisabled', true);
  };

  handleMouseUp = () => {
    const selection = global.getSelection();
    if (!selection) {
      return;
    }

    if (selection.anchorOffset === selection.focusOffset) {
      this.props.setProperty('isHighlightDisabled', false);
      return;
    }

    const selectionIndex = List([selection.anchorOffset, selection.focusOffset]).sort();
    const selectedString = selection.toString();
    const start = selectionIndex.first() + selectedString.search(/\S/);
    const end = start + trim(selectedString).length - 1;
    const newSelection = Map({start, end});

    const updatedPermutation = this.props.question
      .getValidResponse()
      .get(this.props.permutationId)
      .filterNot((selection) => {
        const isInside = newSelection.some(
          (val) => selection.get('start') <= val && val <= selection.get('end')
        );
        const doesSurround =
          newSelection.get('end') <= selection.get('start') &&
          newSelection.get('end') >= selection.get('end');
        return isInside || doesSurround;
      })
      .push(newSelection)
      .sortBy((index) => index.get('start'));

    const validResponse = this.props.question
      .getValidResponse()
      .set(this.props.permutationId, updatedPermutation);
    const updatedQuestion = this.props.question.setValidResponse(validResponse);

    this.props.onModelChange(updatedQuestion);
    this.props.setProperty('isHighlightDisabled', false);
  };

  removeTextSelection(index) {
    const currentPermutation = this.props.question.getValidResponse().get(this.props.permutationId);
    const indexToDelete = currentPermutation.findIndex((selection) => {
      return selection.get('start') <= index && index <= selection.get('end');
    });

    if (indexToDelete < 0) {
      return;
    }

    const updatedPermutation = currentPermutation.delete(indexToDelete);
    const validResponse = this.props.question
      .getValidResponse()
      .set(this.props.permutationId, updatedPermutation);
    const updatedQuestion = this.props.question.setValidResponse(validResponse);

    this.props.onModelChange(updatedQuestion);
  }

  render() {
    let {highlightPrompt} = this.props;
    if (highlightPrompt && !this.props.store.get('isHighlightDisabled')) {
      const {highlights} = this.props;
      highlightPrompt = formatHighlightPrompt(highlightPrompt, highlights);
    }

    const isPreferredPermutation = this.props.permutationId === this.props.preferredPermutationId;
    return (
      <div className='text-highlight-editor__single-permutation'>
        <div className='text-highlight-answer-permutation__header'>
          <label className='text-highlight-answer-permutation__correct-permutation-wrapper'>
            <input
              name='classroom'
              type='radio'
              checked={isPreferredPermutation}
              onChange={this.changePreferredPermutation}
            />
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <span className='fancy-radio' role='radio' aria-checked={isPreferredPermutation} />
            Preferred Permutation
          </label>
          <Button onClick={this.removePermutation} text='Remove permutation' color='red' small />
        </div>
        <div className='text-highlight-answer-permutation__body'>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div
            dangerouslySetInnerHTML={{__html: highlightPrompt}}
            onMouseUp={this.handleMouseUp}
            onMouseDown={this.handleMouseDown}
            onTouchStart={this.handleMouseDown}
            onTouchEnd={this.handleMouseUp}
            className='answer-input--text-highlight'
          />
        </div>
        {this.props.error}
        <hr />
      </div>
    );
  }
}

export default AnswerPermutation;
