import React from 'react';
import PropTypes from 'prop-types';
import track from 'react-tracking';
import {history} from 'client/history';
import {List, OrderedSet} from 'immutable';
import {Link} from 'react-router';
import {Button} from '@albert-io/atomic';
import {callAction} from 'client/framework';
import {queryBuilderPropType} from '@albert-io/json-api-framework/request/builder';
import {showGlobalLoginModal} from 'client/LogIn/utils';
import awaitMandarkQueries from 'lib/hocs/awaitMandarkQueries';
import PracticeView from 'components/PracticeView/PracticeView.react';
import clearGuess from 'components/PracticeView/QuestionActions/clearGuess';
import {
  EditQuestionLink,
  MoreInfo,
  Next,
  QuestionActionsWrapper,
  SubmitAnswer,
  ToggleViewSolution,
  TryAgain
} from 'components/PracticeView/QuestionActions';
import HeadingActions from 'components/PracticeView/HeadingActions/HeadingActions.react';
import {
  makePreviousAttemptsQuery,
  hasPreviousAttempts,
  hasEditPermissions,
  canAddAllToFolder,
  canRevealSolution,
  hasMoreQuestionInfo,
  canSaveQuestionSetToFolder,
  showPublicViewForSubject
} from 'components/PracticeView/practiceView.utils';
import TitleBar from 'components/PracticeView/TitleBar/TitleBar.react';
import QuestionsListPaginator from 'components/PracticeView/QuestionsListPaginator/QuestionsListPaginator.react';
import QuestionsListHeader from 'components/PracticeView/QuestionsListHeader/QuestionsListHeader.react';
import FoldersModal from 'components/PracticeView/FoldersModal/FoldersModal.react';
import LoadingView from 'components/PracticeView/LoadingView/LoadingView.react';
import {pluralize, upperFirst} from 'lib/stringUtils';
import {getDoesUserBelongToAnyLicensedClassroomsQuery} from 'lib/UserAccessUtil';
import sessionStore from 'client/Session/SessionStore';
import {TopicSummary} from 'components/TopicSummary/TopicSummary.react';
import {getQuestionEditPath} from 'client/Dennis/Content/Queue/shared';
import masqueradeStore from 'generic/Masquerade/Masquerade.store';
import BlockedQuestionsModal from 'components/PracticeView/BlockedQuestionsModal/BlockedQuestionsModal.react';
import {getModelForResourceType} from 'resources/modelRegistry';
import {isSearchMode, addSearchParamsToLink} from 'components/AdvancedSearch/AdvancedSearch.utils';
import globalNavigationActions from 'client/GlobalNavigation/GlobalNavigationActions';
import {
  makeGuessResultManager,
  getGuessesFromQuestionSets
} from 'components/PracticeView/helpers/guessResultManager';
import {
  getPreviousQuestionState,
  getNextQuestionState
} from 'components/PracticeView/helpers/questionNavigation.utils';
import PaginatedResultsManager from 'components/PracticeView/helpers/paginatedResultsManager';
import makeTimeElapsedManager from 'components/PracticeView/helpers/makeTimeElapsedManager';
import {destroyQuestionStoresForQuestionSets} from 'client/components/QuestionTypes/questionTypes.utils';
import {CreateAssignmentContext} from 'client/CreateAssignmentV2/CreateAssignmentProvider';
import {getQuestionSetsQuery} from 'client/CreateAssignmentV2/utils/createAssignment.queries';
import UpgradeSubjectModal from 'components/PracticeView/UpgradeSubjectModal/UpgradeSubjectModal.react';
import {MobilePracticeViewProvider} from 'components/PracticeView/MobilePracticeViewProvider.context';

import PublicQuestions from 'components/PublicQuestions';

import {
  getSingleQuestionSetQuery,
  getSubjectGuideLevelBySlug,
  getQuestionGuideLevels,
  getSubjectBySlugQuery,
  getQuestionSetsData,
  queryTypes
} from './subjectPracticeView.queries';
import {
  getRedirectPath,
  getActiveQuestionPath,
  getActiveSetAndQuestion,
  getPathToQuestionSetAndQuestion,
  hasTopicSummary,
  getTopicSummaryPath
} from './subjectPracticeView.utils';
import SubjectPracticeViewHelmet from './SubjectPracticeViewHelmet.react';
import NoQuestionSetsFoundSubjectPracticeView from './NoQuestionSetsFoundSubjectPracticeView/NoQuestionSetsFoundSubjectPracticeView.react';
import SubjectPracticeViewToolbar from './SubjectPracticeViewToolbar/SubjectPracticeViewToolbar.react';
import RetailAssessmentModal from './RetailAssessmentModal/RetailAssessmentModal.react';

@track({feature: 'Subject Practice View'})
class BootstrappedSubjectPracticeView extends React.Component {
  static propTypes = {
    guideLevel: PropTypes.instanceOf(getModelForResourceType('guide_level_v2')),
    subject: PropTypes.instanceOf(getModelForResourceType('subject_v2')),
    questionSets: PropTypes.instanceOf(List),
    questionSetsQuery: queryBuilderPropType,
    questionCount: PropTypes.number,
    questionSetsCount: PropTypes.number,
    route: PropTypes.object,
    params: PropTypes.object,
    router: PropTypes.object,
    isLoading: PropTypes.bool,
    searchQuestionIds: PropTypes.object,
    location: PropTypes.object,
    prevLocation: PropTypes.string,
    retailStudentInAssessment: PropTypes.bool
  };

  constructor(props) {
    super(props);
    const {
      activeQuestionSet,
      activeQuestion,
      guessResults,
      selectedQuestionSets,
      activeGuess,
      paginatedQuestionsListManager
    } = this.getBootstrapValues();
    this.state = {
      activeQuestionSet,
      activeQuestion,
      guessResults,
      selectedQuestionSets,
      activeGuess,
      openedModalFromHeading: false,
      showFolderModal: false,
      isSelectAllQuestionSetsMode: false,
      showAttemptIndicators: true,
      showRetailAssessmentModal: props.retailStudentInAssessment
    };
    this.paginatedQuestionsListManager = paginatedQuestionsListManager;
    this.timeElapsedManager = makeTimeElapsedManager(activeQuestion);
    this.scrollToQuestionById = React.createRef();
  }

  componentDidMount() {
    const {route, router, params, location, guideLevel, questionSets} = this.props;
    const {activeQuestion, activeQuestionSet} = this.state;
    const redirectPath = getRedirectPath({
      route,
      params,
      location,
      guideLevel,
      questionSets,
      activeQuestion,
      activeQuestionSet
    });
    if (redirectPath) {
      router.replace(redirectPath);
    }
    /**
     * This button should be handling its show/hide state. Update that.
     */
    callAction(globalNavigationActions.SET_SHOULD_SHOW_UPGRADE_BUTTON, true);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.questionSetsQuery.equals(this.props.questionSetsQuery)) {
      const {
        activeQuestionSet,
        activeQuestion,
        guessResults,
        selectedQuestionSets,
        activeGuess,
        paginatedQuestionsListManager
      } = this.getBootstrapValues();
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        activeQuestionSet,
        activeQuestion,
        guessResults,
        selectedQuestionSets,
        activeGuess
      });
      this.paginatedQuestionsListManager = paginatedQuestionsListManager;
    }
    /**
     * @todo: QuestionStores should be the only ones responsible for keeping track of their active guess.
     * That's not the case right now, as we're also keeping track of the active guess in component state.
     * Once we consolidate the active guess to once place, we can remove this.
     */
    if (
      prevState.activeQuestion &&
      (!this.state.activeQuestion ||
        prevState.activeQuestion.getId() !== this.state.activeQuestion.getId())
    ) {
      clearGuess(prevState.activeQuestion.getStore().getName());
    }

    this.timeElapsedManager.handleQuestionChange(this.state.activeQuestion);
  }

  componentWillUnmount() {
    /**
     * This button should be handling its show/hide state. Update that.
     */
    callAction(globalNavigationActions.SET_SHOULD_SHOW_UPGRADE_BUTTON, false);
    destroyQuestionStoresForQuestionSets(this.props.questionSets);
  }

  getBootstrapValues() {
    const {questionSets, questionSetsQuery} = this.props;
    const activeSetAndQuestion = getActiveSetAndQuestion(this.props);
    const initialGuesses = getGuessesFromQuestionSets(questionSets);
    return {
      activeQuestionSet: activeSetAndQuestion.activeQuestionSet,
      activeQuestion: activeSetAndQuestion.activeQuestion,
      guessResults: makeGuessResultManager(initialGuesses),
      selectedQuestionSets: OrderedSet(),
      activeGuess: null,
      paginatedQuestionsListManager: new PaginatedResultsManager({
        initialQuery: questionSetsQuery,
        onUpdate: (incomingQuestionSets) => {
          if (!incomingQuestionSets) {
            this.forceUpdate();
          } else {
            this.setState((state) => {
              const incomingGuesses = getGuessesFromQuestionSets(incomingQuestionSets);
              return {
                guessResults: state.guessResults.addGuessResults(incomingGuesses)
              };
            });
          }
        }
      })
    };
  }

  toggleRetailAssessmentModal = () => {
    this.setState((state) => ({
      showRetailAssessmentModal: !state.showRetailAssessmentModal
    }));
  };

  toggleFoldersModal = () => {
    if (this.state.showFolderModal) {
      this.setState({
        showFolderModal: false,
        openedModalFromHeading: false
      });
    } else {
      this.setState({
        showFolderModal: true
      });
    }
  };

  toggleFoldersModalFromHeading = () => {
    this.setState({
      openedModalFromHeading: true
    });
    this.toggleFoldersModal();
  };

  makeQuestionSetOnChangeHandler = (questionSet) => {
    return () => {
      const questionSetId = questionSet.getId();
      this.setState((state) => {
        const newSelectedSets = state.selectedQuestionSets.includes(questionSetId)
          ? state.selectedQuestionSets.delete(questionSetId)
          : state.selectedQuestionSets.add(questionSetId);
        const isDeselectingLastSelectAllSet =
          state.isSelectAllQuestionSetsMode &&
          newSelectedSets.size === this.props.questionSetsCount;

        return isDeselectingLastSelectAllSet
          ? {
              selectedQuestionSets: OrderedSet(),
              isSelectAllQuestionSetsMode: false
            }
          : {
              selectedQuestionSets: newSelectedSets
            };
      });
    };
  };

  handleGuessSubmission = (guess) => {
    const question = guess.getQuestion();
    const questionId = question.getId();
    const questionSetId = question.getQuestionSet().getId();

    makePreviousAttemptsQuery(questionId).invalidateInterest();

    this.paginatedQuestionsListManager.updateQuestionForQuestionSetId({
      questionSetId,
      question
    });

    this.setState((state) => ({
      activeQuestion: question,
      activeGuess: guess,
      guessResults: state.guessResults.updateGuess(questionId, guess)
    }));
  };

  resetGuess = () => {
    this.setState({activeGuess: null});
    this.timeElapsedManager.resetTimeForActiveQuestion();
  };

  setActiveGuess = (guess) => {
    this.setState({activeGuess: guess});
  };

  getStageComponent() {
    if (this.props.route.isTopicSummary) {
      return (
        <TopicSummary
          guideLevel={this.props.guideLevel}
          practiceButtonLink={getActiveQuestionPath(this.props)}
        />
      );
    }
    return null;
  }

  makeNavigateProps(upcomingSetAndQuestion) {
    if (!upcomingSetAndQuestion) {
      return {disabled: true};
    }
    const {questionSet, question} = upcomingSetAndQuestion;
    return {
      disabled: false,
      handleNavigation: () => {
        this.scrollToQuestionById.current(questionSet.getId());
        this.navigateToQuestion(questionSet, question);
      }
    };
  }

  makeNavigateBackProps = () => {
    const questionSets = this.paginatedQuestionsListManager.getResults();
    if (questionSets.isEmpty()) {
      return null;
    }
    const previousSetAndQuestion = getPreviousQuestionState({
      questionSets,
      activeSet: this.state.activeQuestionSet,
      activeQuestion: this.state.activeQuestion
    });
    return this.makeNavigateProps(previousSetAndQuestion);
  };

  makeNavigateNextProps = () => {
    const questionSets = this.paginatedQuestionsListManager.getResults();
    if (questionSets.isEmpty()) {
      return null;
    }
    const nextSetAndQuestion = getNextQuestionState({
      questionSets,
      activeSet: this.state.activeQuestionSet,
      activeQuestion: this.state.activeQuestion
    });
    return this.makeNavigateProps(nextSetAndQuestion);
  };

  navigateToQuestion(questionSet, question) {
    return new Promise((res) => {
      const path = getPathToQuestionSetAndQuestion({
        subjectSlug: this.props.params.subjectSlug,
        guideLevelSlug: this.props.params.guideLevelSlug,
        questionSet,
        question
      });
      this.props.router.push(path);
      this.setState(
        {
          activeQuestionSet: questionSet,
          activeQuestion: question,
          /**
           * @todo: Figure out if we wanna load the previous guess when navigating to questions
           */
          activeGuess: null
        },
        () => {
          res();
        }
      );
    });
  }

  navigateAndScrollToPage(pageNum) {
    const firstSetOnPage = this.paginatedQuestionsListManager
      .getQuestionSetsInPage(pageNum)
      .first();
    const firstQuestion = firstSetOnPage.getQuestions().first();
    this.navigateToQuestion(firstSetOnPage, firstQuestion).then(() =>
      this.scrollToQuestionById.current(firstSetOnPage.getId())
    );
  }

  handlePaginatorPreviousClick = async () => {
    const previousPage =
      this.paginatedQuestionsListManager.getPageForId(this.state.activeQuestionSet.getId()) - 1;
    if (!this.paginatedQuestionsListManager.hasFetchedPage(previousPage)) {
      await this.paginatedQuestionsListManager.fetchPreviousPage();
    }
    this.navigateAndScrollToPage(previousPage);
  };

  handlePaginatorNextClick = async () => {
    const nextPage =
      this.paginatedQuestionsListManager.getPageForId(this.state.activeQuestionSet.getId()) + 1;
    if (!this.paginatedQuestionsListManager.hasFetchedPage(nextPage)) {
      await this.paginatedQuestionsListManager.fetchNextPage();
    }
    this.navigateAndScrollToPage(nextPage);
  };

  handleSelectAssign = () => {
    const {bootstrapAssignment} = this.context;
    const {isSelectAllQuestionSetsMode, selectedQuestionSets} = this.state;
    const guideLevelId = this.props.guideLevel?.getId();
    const selectedQuestionSetIds = selectedQuestionSets.toArray();
    // if a user manually checks question sets, we directly pass all sets to the ACF
    // this is because we have already loaded and have access to all needed questions
    // else, a user is using `selectAll` mode and we don't have direct access to all sets due to pagination
    const allQuestionSetsInMemory = this.paginatedQuestionsListManager.getResults();
    const bootstrapAssignmentObject = {
      resourceType: 'guide_levels_v2',
      resourceId: guideLevelId
    };
    if (!isSelectAllQuestionSetsMode && !!selectedQuestionSetIds.length) {
      const selectedSets = allQuestionSetsInMemory.filter((set) =>
        selectedQuestionSets.has(set.getId())
      );
      bootstrapAssignmentObject.questionSets = selectedSets;
    } else if (allQuestionSetsInMemory.size === this.props.questionSetsCount) {
      // If a user is assigning but has no selected question sets, we know they want to assign all
      // This stops of from making another request if we already have all sets in local state.
      // This also allows the single question PV view to work without any extra effort.
      bootstrapAssignmentObject.questionSets = allQuestionSetsInMemory.filter(
        (questionSet) => !selectedQuestionSetIds.includes(questionSet.getId())
      );
    } else {
      bootstrapAssignmentObject.query = getQuestionSetsQuery(
        'guide_levels_v2',
        guideLevelId,
        selectedQuestionSetIds
      );
    }
    bootstrapAssignment(bootstrapAssignmentObject);
    history.pushState(null, `/create-assignment/new-assignment`);
  };

  clearQuestionSetSelections = () => {
    this.setState({
      selectedQuestionSets: OrderedSet(),
      isSelectAllQuestionSetsMode: false
    });
  };

  getHeadingActions() {
    const {activeQuestion, activeQuestionSet} = this.state;
    const {subject} = this.props;
    if (!activeQuestion) {
      return null;
    }

    const isQuestionBlocked = activeQuestion.getMeta().isBlockedAssignmentQuestion();

    const actions = [];
    if (!isQuestionBlocked && hasPreviousAttempts(activeQuestion)) {
      actions.push(
        <HeadingActions.PreviousAttempts
          key='PreviousAttempts'
          onGuessClick={this.setActiveGuess}
        />
      );
    }
    if (hasEditPermissions(activeQuestion)) {
      const subjectId = this.paginatedQuestionsListManager
        .getResults()
        .first()
        .getSubject()
        .getId();
      const questionSetId = activeQuestionSet.getId();
      actions.push(
        <HeadingActions.EditQuestionItem
          key='EditQuestionItem'
          editPath={getQuestionEditPath({
            questionSetId: activeQuestionSet.getId(),
            questionId: activeQuestion.getId(),
            customSearch: {
              status: 'published',
              subject: subjectId,
              page: questionSetId,
              fallback_page: 1
            }
          })}
        />
      );
    }
    if (!isQuestionBlocked && sessionStore.hasValidSession()) {
      actions.push(<HeadingActions.QuestionFeedbackItem key='QuestionFeedbackItem' />);
    }
    if (!isQuestionBlocked && canSaveQuestionSetToFolder(subject)) {
      actions.push(
        <HeadingActions.SaveToItem key='SaveToItem' onClick={this.toggleFoldersModalFromHeading} />
      );
    }

    return !!actions.length && <HeadingActions>{actions}</HeadingActions>;
  }

  getTitleBarActions() {
    const actions = [];
    /**
     * @todo: Add this once the backend is ready to allow for 'assign all' functionality.
     * This means knowing which questions to omit (e.g. questions the user is not licensed for)
     * since we'll no longer be providing a list of IDs.
     */
    // eslint-disable-next-line
    if (false) {
      actions.push(
        <TitleBar.DropdownItem
          key='assignAll'
          onClick={() => {
            /**
             * @todo: Implement this
             */
          }}
        >
          Assign {this.props.questionSetsCount} Questions
        </TitleBar.DropdownItem>
      );
    }

    if (!this.props.route.isTopicSummary && hasTopicSummary(this.props)) {
      const guideLevelType = this.props.guideLevel.getGuideLevelType();
      actions.push(
        <TitleBar.DropdownItem
          key='viewSubjectOverview'
          as={Link}
          to={getTopicSummaryPath(this.props)}
          preventDefaultClickHandler={false}
        >
          View {upperFirst(guideLevelType)} Overview
        </TitleBar.DropdownItem>
      );
    }

    return !!actions.length && <TitleBar.Dropdown>{actions}</TitleBar.Dropdown>;
  }

  getFooterActions() {
    const {subject} = this.props;
    const {activeQuestion, activeGuess, activeQuestionSet} = this.state;
    const isQuestionBlocked = activeQuestion.getMeta().isBlockedAssignmentQuestion();

    const actions = [];
    let key = 0;
    if (isQuestionBlocked) {
      actions.push(
        <Button as={Link} to='/classes'>
          Go to classes
        </Button>
      );
    }
    if (hasMoreQuestionInfo()) {
      actions.push(
        <MoreInfo
          key={(key += 1)}
          questionSetId={activeQuestionSet.getId()}
          userId={masqueradeStore.getUserIdByMasqueradeState()}
        />
      );
    }
    if (
      canRevealSolution({
        question: activeQuestion,
        questionSet: activeQuestionSet,
        subject
      })
    ) {
      actions.push(
        <ToggleViewSolution
          handleToggleSolution={activeGuess ? this.resetGuess : this.setActiveGuess}
          isViewingSolution={!!activeGuess}
          key={(key += 1)}
          question={activeQuestion}
          questionStoreName={activeQuestion.getStore().getName()}
        />
      );
    }
    if (activeGuess || isQuestionBlocked) {
      const {handleNavigation} = this.makeNavigateNextProps();
      if (handleNavigation) {
        actions.push(<Next key={(key += 1)} onClick={handleNavigation} />);
      }
    }
    if (activeGuess) {
      actions.push(
        <TryAgain
          handleResetGuess={this.resetGuess}
          key={(key += 1)}
          questionStoreName={activeQuestion.getStore().getName()}
        />
      );
    }
    if (!activeGuess && !isQuestionBlocked) {
      actions.push(
        <SubmitAnswer
          hasAccess
          getTimeElapsed={() => this.timeElapsedManager.getTimeElapsed(activeQuestion)}
          key={(key += 1)}
          onSuccess={this.handleGuessSubmission}
          question={activeQuestion}
          subject={subject}
        />
      );
    }
    if (hasEditPermissions(activeQuestion)) {
      actions.push(
        <EditQuestionLink
          key={(key += 1)}
          question={activeQuestion}
          questionSet={activeQuestionSet}
        />
      );
    }
    return <QuestionActionsWrapper>{actions}</QuestionActionsWrapper>;
  }

  toggleSelectAllQuestionSets = (e) => {
    this.setState({
      isSelectAllQuestionSetsMode: e.target.checked,
      selectedQuestionSets: OrderedSet()
    });
  };

  isQuestionSetSelected = (questionSet) => {
    const {selectedQuestionSets, isSelectAllQuestionSetsMode} = this.state;
    const isIncluded = selectedQuestionSets.includes(questionSet.getId());
    return isSelectAllQuestionSetsMode ? !isIncluded : isIncluded;
  };

  getSelectedQuestionCount() {
    const {selectedQuestionSets, isSelectAllQuestionSetsMode} = this.state;
    const selectedCount = selectedQuestionSets.reduce((acc, id) => {
      return acc + this.paginatedQuestionsListManager.getQuestionSetById(id).getQuestions().size;
    }, 0);
    return isSelectAllQuestionSetsMode ? this.props.questionCount - selectedCount : selectedCount;
  }

  static contextType = CreateAssignmentContext;

  render() {
    const {
      questionSetsQuery,
      guideLevel,
      params,
      isLoading,
      subject,
      route,
      prevLocation,
      retailStudentInAssessment
    } = this.props;
    const {
      activeQuestion,
      activeQuestionSet,
      activeGuess,
      rearrangeable,
      showAttemptIndicators,
      strikethroughQuestionInfo,
      isSelectAllQuestionSetsMode
    } = this.state;
    const questionSets = this.paginatedQuestionsListManager.getResults();
    const {subjectSlug, guideLevelSlug} = params;
    const activeSubject = activeQuestionSet ? activeQuestionSet.getSubject() : subject;
    const toolbar = (
      <SubjectPracticeViewToolbar
        location={this.props.location}
        questionSetsQuery={this.props.questionSetsQuery}
        isSelectAllQuestionSetsMode={this.state.isSelectAllQuestionSetsMode}
        activeQuestionSet={this.state.activeQuestionSet}
        subject={this.props.subject}
        guideLevel={guideLevel}
        selectedQuestionCount={this.getSelectedQuestionCount()}
        clearQuestionSetSelections={this.clearQuestionSetSelections}
        handleSelectAssign={this.handleSelectAssign}
        toggleFoldersModal={this.toggleFoldersModal}
        canAssignGuide={canAddAllToFolder(activeSubject, guideLevel)}
        isEmptyPracticeView={!activeQuestion}
        toggleRetailAssessmentModal={retailStudentInAssessment && this.toggleRetailAssessmentModal}
        isLoading={isLoading}
      />
    );
    if (questionSets.isEmpty()) {
      return (
        <NoQuestionSetsFoundSubjectPracticeView
          subject={subject}
          guideLevel={guideLevel}
          subjectSlug={subjectSlug}
          toolbar={toolbar}
        />
      );
    }

    const totalQuestions = questionSetsQuery
      .getResourceMetadata()
      .getIn(['request', 'count_of_questions'], null);

    const navigateBackProps = this.makeNavigateBackProps();
    const navigateNextProps = this.makeNavigateNextProps();
    const curriculumAreaSlug = subject.getCurriculumAreaSlug();
    const backgroundColor = `var(--background-domain-${curriculumAreaSlug})`;
    const selectedQuestionCount = this.getSelectedQuestionCount();

    return (
      <MobilePracticeViewProvider>
        <SubjectPracticeViewHelmet
          route={route}
          subject={activeSubject}
          guideLevel={guideLevel}
          activeQuestion={activeQuestion}
          activeQuestionSet={activeQuestionSet}
        />
        <PracticeView
          activeQuestion={activeQuestion}
          activeQuestionSet={activeQuestionSet}
          activeQuestionStore={activeQuestion && activeQuestion.getStore()}
          activeGuess={activeGuess}
          activeSubject={activeSubject}
          questionSets={questionSets}
          isLoading={isLoading}
          toolbar={!this.props.route.isTopicSummary && toolbar}
          titleBar={
            <TitleBar
              backgroundColor={backgroundColor}
              title={`${isSearchMode() ? 'Search Results in ' : ''}${
                guideLevel ? guideLevel.getName() : activeSubject.getName()
              }`}
              imgHref={activeSubject.getLogoLocation().href}
              backButton={
                <TitleBar.BackButton
                  as={Link}
                  label='Back To Guide'
                  to={prevLocation || addSearchParamsToLink(`/${subjectSlug}`)}
                />
              }
              dropdown={this.getTitleBarActions()}
            />
          }
          backButtonProps={{
            as: Link,
            label: 'Back To Guide',
            to: `/${subjectSlug}`
          }}
          questionsListProps={{
            onScrollLastQuestionIntoView: () => {
              if (!this.paginatedQuestionsListManager.isRequestInFlight) {
                this.paginatedQuestionsListManager.fetchNextPage();
              }
            },
            scrollToQuestionById: this.scrollToQuestionById,
            header: (
              <QuestionsListHeader
                checkboxProps={
                  canAddAllToFolder(activeSubject, guideLevel)
                    ? {
                        checked:
                          isSelectAllQuestionSetsMode || selectedQuestionCount === totalQuestions,
                        onChange: this.toggleSelectAllQuestionSets,
                        indeterminate:
                          selectedQuestionCount > 0 && selectedQuestionCount !== totalQuestions
                      }
                    : null
                }
                heading={
                  totalQuestions !== null
                    ? `${totalQuestions} ${pluralize('question', totalQuestions)}`
                    : null
                }
                selectedQuestionCount={this.getSelectedQuestionCount()}
              />
            ),
            footer: (() => {
              const currentPage = this.paginatedQuestionsListManager.getPageForId(
                activeQuestionSet.getId()
              );
              return (
                <div>
                  <QuestionsListPaginator
                    currentPage={currentPage}
                    isRequestInFlight={this.paginatedQuestionsListManager.isRequestInFlight}
                    totalPages={this.paginatedQuestionsListManager.totalPages}
                    isPreviousDisabled={
                      this.paginatedQuestionsListManager.isRequestInFlight || currentPage === 1
                    }
                    handlePreviousPage={this.handlePaginatorPreviousClick}
                    isNextDisabled={
                      this.paginatedQuestionsListManager.isRequestInFlight ||
                      currentPage === this.paginatedQuestionsListManager.totalPages
                    }
                    handleNextPage={this.handlePaginatorNextClick}
                  />
                </div>
              );
            })()
          }}
          questionSetProps={{
            selectable: (questionSet) => canSaveQuestionSetToFolder(activeSubject, questionSet),
            rearrangeable,
            isSelected: this.isQuestionSetSelected,
            selectedQuestionSets: this.state.selectedQuestionSets,
            questionSetOnChangeHandler: this.makeQuestionSetOnChangeHandler,
            isSelectAllQuestionSetsMode: this.state.isSelectAllQuestionSetsMode
          }}
          questionProps={{
            showAttemptIndicators,
            strikethroughQuestionInfo,
            questionPath: ({questionSet, question}) =>
              getPathToQuestionSetAndQuestion({
                subjectSlug,
                guideLevelSlug,
                questionSet,
                question
              }),
            attemptIndicatorValue: ({question}) => {
              return (
                !question.getMeta().isBlockedAssignmentQuestion() &&
                this.state.guessResults.getGuessResult(question.getId())
              );
            },
            // @todo: Implement this once folder metadata exists
            // eslint-disable-next-line no-unused-vars
            isBookmarked: () => false,
            onQuestionClick: ({question, questionSet}) => {
              return () => {
                this.setState({
                  activeQuestion: question,
                  activeQuestionSet: questionSet,
                  activeGuess: null
                });
              };
            },
            isNonMatchingSearchResult: ({question}) => {
              return (
                !!this.props.searchQuestionIds && !this.props.searchQuestionIds[question.getId()]
              );
            }
          }}
          questionRendererProps={{
            actions: this.getFooterActions(),
            rendererType: activeGuess ? 'PostGuess' : 'PreGuess',
            navigateBackProps,
            navigateNextProps,
            headingActions: this.getHeadingActions()
          }}
          stageComponent={this.getStageComponent()}
        />
        {this.state.showFolderModal && (
          <FoldersModal
            selectedQuestionSetIds={
              this.state.openedModalFromHeading
                ? [activeQuestionSet.getId()]
                : [...this.state.selectedQuestionSets]
            }
            questionSetsQuery={questionSetsQuery}
            userId={sessionStore.getUserId()}
            closeModalFunc={this.toggleFoldersModal}
            isSelectAllQuestionSetsMode={isSelectAllQuestionSetsMode}
            selectedQuestionCount={this.getSelectedQuestionCount()}
          />
        )}
        {this.state.showRetailAssessmentModal && (
          <RetailAssessmentModal handleClose={this.toggleRetailAssessmentModal} />
        )}
      </MobilePracticeViewProvider>
    );
  }
}

const getCurrentGuideLevel = async (
  subjectId: string,
  guideLevelSlug?: string,
  questionSlug?: string
) => {
  // attempt to get guide level from slug
  const subjectGuideLevel = await getSubjectGuideLevelBySlug(subjectId, guideLevelSlug);
  if (subjectGuideLevel) {
    return subjectGuideLevel;
  }

  // get direct parent guide level from single question
  const guideLevelResources = await getQuestionGuideLevels(questionSlug);
  const guideLevels = guideLevelResources.getCollection().toArray();
  const parentLevel = Math.max(...guideLevels.map((guideLevel) => guideLevel.getNlevel()));
  return guideLevels.find((guideLevel) => guideLevel.getNlevel() === parentLevel);
};

class SubjectPracticeView extends React.Component {
  static propTypes = {
    params: PropTypes.object,
    location: PropTypes.object,
    router: PropTypes.object,
    route: PropTypes.object,
    subject: PropTypes.instanceOf(getModelForResourceType('subject_v2')),
    questionSets: PropTypes.instanceOf(List)
  };

  constructor(props) {
    super(props);

    const showPublicOnlyView = showPublicViewForSubject(props.subject);

    let questionSetsQuery = null;
    let questionSets = null;
    let isReady = false;
    let isLoading = true;

    if (props.questionSets) {
      questionSetsQuery = getSingleQuestionSetQuery(props);
      questionSets = props.questionSets;
      isReady = true;
      isLoading = false;
    }

    this.state = {
      questionSets,
      questionSetsQuery,
      isReady,
      isLoading,
      guideLevel: null,
      subject: props.subject,
      blockedQuestionCount: 0,
      searchQuestionIds: null,
      showBlockedAssignmentQuestionModal: false,
      queryDiffString: JSON.stringify(props.location.query),
      errorResponse: null,
      showUpgradeModal: true,
      prevLocation: '',
      userClassrooms: List(),
      showPublicOnlyView
    };
  }

  componentDidMount() {
    if (!this.props.questionSets) {
      this.setInitialState().then(this.checkForBlockedQuestions);
    }
  }

  componentDidUpdate() {
    const latestQueryDiffString = JSON.stringify(this.props.location.query);
    if (this.state.queryDiffString !== latestQueryDiffString) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState(
        {
          queryDiffString: latestQueryDiffString,
          isLoading: true
        },
        this.setInitialState
      );
    }
  }

  static queryTypes = queryTypes;

  setInitialState = async () => {
    const userId = masqueradeStore.getUserIdByMasqueradeState();
    /* There is asynchronous behavior downstream in getQuestionSetsData we must resolve first */
    const userClassrooms = await getDoesUserBelongToAnyLicensedClassroomsQuery(
      true
    ).getResourcePromise();
    const guideLevel = await getCurrentGuideLevel(
      this.props.subject.getId(),
      this.props.params.guideLevelSlug || '',
      this.props.params.questionSlug || ''
    );
    const questionSetsData = await getQuestionSetsData(
      this.props,
      userId,
      guideLevel?.getId(),
      !guideLevel?.isFree() && this.state.showPublicOnlyView
    );
    const {
      questionSetsQuery,
      questionSets,
      blockedQuestionCount,
      searchQuestionIds,
      errorResponse
    } = questionSetsData;
    const prevLocation = this.props.location?.state?.from;
    this.setState({
      questionSetsQuery,
      questionSets,
      guideLevel,
      blockedQuestionCount,
      searchQuestionIds,
      isReady: true,
      isLoading: false,
      errorResponse,
      prevLocation,
      userClassrooms
    });
  };

  checkForBlockedQuestions = () => {
    if (this.state.blockedQuestionCount > 0) {
      this.setState({showBlockedAssignmentQuestionModal: true});
    }
  };

  dismissModal = () => {
    this.setState({
      showBlockedAssignmentQuestionModal: false
    });
  };

  toggleUpgradeModal = () => {
    this.setState({
      showUpgradeModal: false
    });
  };

  render() {
    const {
      isReady,
      isLoading,
      questionSetsQuery,
      blockedQuestionCount,
      searchQuestionIds,
      questionSets,
      guideLevel,
      subject,
      showBlockedAssignmentQuestionModal,
      errorResponse,
      showUpgradeModal,
      prevLocation,
      userClassrooms,
      showPublicOnlyView
    } = this.state;
    const {params, route} = this.props;

    if (!isReady) {
      return <LoadingView />;
    }
    const blockAccess =
      errorResponse?.forbidden || (!sessionStore.hasValidSession() && errorResponse);
    if (blockAccess) {
      return (
        <>
          <PracticeView
            titleBar={
              <TitleBar
                backButton={
                  <TitleBar.BackButton as={Link} label='Navigate to subject' to='/subjects' />
                }
                title='To course library'
              />
            }
            questionsListProps={{
              header: <QuestionsListHeader />,
              noQuestionSetsMessage: 'You do not have access to these question(s).'
            }}
          />
          {sessionStore.hasValidSession()
            ? showUpgradeModal && (
                <UpgradeSubjectModal handleClose={this.toggleUpgradeModal} subject={subject} />
              )
            : showGlobalLoginModal()}
        </>
      );
    }
    if (errorResponse) {
      this.props.router.pushState(null, '/error');
    }
    // redirect licensed students without IP access back to subject guide
    if (
      sessionStore.hasValidSession() &&
      sessionStore.isStudent() &&
      !userClassrooms.isEmpty() &&
      !subject.doesUserHaveAccess()
    ) {
      history.replace(`/${subject.getUrlSlug()}`);
    }

    if (!sessionStore.hasValidSession() && guideLevel.getGuide().getGuideType() !== 'practice') {
      showGlobalLoginModal();
      return null;
    }

    // if the user is not licensed for the current subject, or the selected guide
    // level is not free, block all question data except title in a
    // read-only view. PracticeView should ultimately be updated for more flexibility
    // around displaying question content, but this shim exists for now to block
    // content for unlicensed users
    if (showPublicOnlyView && !guideLevel?.isFree()) {
      const title = guideLevel ? guideLevel.getName() : subject.getName();
      const curriculumAreaSlug = subject.getCurriculumAreaSlug();
      const subjectColor = `var(--background-domain-${curriculumAreaSlug})`;
      return (
        <PublicQuestions
          backLocation={prevLocation || addSearchParamsToLink(`/${params.subjectSlug}`)}
          guideLevelSlug={params.guideLevelSlug}
          iconUrl={subject.getLogoLocation().href}
          onForbidden={({response}) => this.setState({errorResponse: response})}
          queryType={route.queryType}
          questionSetSlug={params.questionSetSlug}
          questionSlug={params.questionSlug}
          router={this.props.router}
          subjectColor={subjectColor}
          subjectSlug={params.subjectSlug}
          title={title}
        />
      );
    }

    const isAssessment = guideLevel?.getGuide().getGuideType() === 'assessment';
    const retailStudentInAssessment =
      sessionStore.isStudent() && userClassrooms.isEmpty() && isAssessment;
    const questionSetMeta = questionSetsQuery.getResourceMetadata();
    const questionCount = questionSetMeta.getIn(['request', 'count_of_questions']);

    return (
      <div>
        <BootstrappedSubjectPracticeView
          {...this.props}
          isLoading={isLoading}
          questionSetsQuery={questionSetsQuery}
          questionSetsCount={questionSetMeta.getIn(['page', 'total_entries'], 0)}
          questionCount={questionCount}
          questionSets={questionSets}
          guideLevel={guideLevel}
          subject={subject}
          searchQuestionIds={searchQuestionIds}
          prevLocation={prevLocation}
          retailStudentInAssessment={retailStudentInAssessment}
        />
        {showBlockedAssignmentQuestionModal && (
          <BlockedQuestionsModal
            blockedQuestionCount={blockedQuestionCount}
            questionCount={questionCount}
            subjectSlug={params.subjectSlug}
            handleDismiss={this.dismissModal}
          />
        )}
      </div>
    );
  }
}

const getInitialQueries = (props) => {
  const userId = masqueradeStore.getUserIdByMasqueradeState();
  const queries = {
    subject: getSubjectBySlugQuery(props.params.subjectSlug, userId)
  };
  if (!props.params.guideLevelSlug && !sessionStore.hasValidSession()) {
    return {
      ...queries,
      questionSets: getSingleQuestionSetQuery(props, userId)
    };
  }
  return queries;
};

export default awaitMandarkQueries((props) => {
  const queries = getInitialQueries(props);
  return {queries};
}, SubjectPracticeView);
