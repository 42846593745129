/**
 * # Modal
 *
 * This is a modal component that renders a shaded background along with its children.
 * Showing and hiding the modal should be handled from the parent component.
 * The content of the modal is up to the developer.
 * Most modals in the application have a child component of a `Card` or `Dialogue`
 *
 * ## Props
 *
 * - `ariaLabel` (string, required): A label describing the purpose of the modal.
 * - `backdropClassName` (string): Additional class name for the modal backdrop.
 * - `children` (function): A render prop that receives an object with the following properties:
 *   - `isLoading` (boolean): Indicates whether the modal is in a loading state.
 *   - `CloseButtonWrapper` (function): A render prop that receives an object with the following properties:
 *     - `className` (string): Additional class name for the close button.
 *     - `children` (ReactNode): The content of the close button.
 *   - `modalContentStyle` (string): A string that applies classes to the object being shown in the modal.
 * - `closeModalPredicate` (function): A function that returns a boolean which can dictate whether or not to close the modal.
 * - `fullscreen` (boolean): Indicates whether the modal should be displayed in fullscreen.
 * - `role` ('dialog' | 'alertdialog'): Defines ARIA semantics. Use 'dialog' for regular modals and 'alertdialog' for urgent information.
 * - `isLoading` (boolean): Indicates whether the modal is in a loading state.
 * - `longModal` (boolean): Reduces the default top padding of the modal.
 * - `focusCloseButton` (boolean): Indicates whether the close button should be focused for accessibility support.
 * - `className` (string): Additional class name for the modal.
 *
 *  ## RenderProps:
 *
 *   closeButtonWrapper:
 *    in order to comply with WAI-ARIA controls the modal close button must be sent as a parameter
 *      in the closeButtonWrapper() render prop.
 *    closeButtonWrapper() will apply the passed onClick to a wrapping span so
 *      _DO NOT_ add an onClick to the passed in object
 *    If multiple close buttons exist, ONLY WRAP ONE button.
 *   modalContentStyle:
 *    A string that will apply classes to the object being shown in modal
 *    should be added as a className to the outermost element within the <Modal>
 *    adds fade-in/out animation, overflow, and a box-shadow
 *   IsLoading:
 *    can be used to place a loading indicator within the children of the modal
 *    this is needed because occasionally if outside state didn't cause a modal rerender it would appear to always be loading
 *
 * ## Usage
 *
 * ```tsx
 * import Modal from './Modal';
 *
 * const MyComponent = () => {
 *   const handleClose = () => {
 *     // Handle close logic here
 *   };
 *
 *   return (
 *     <Modal
 *       ariaLabel="My Modal"
 *       handleClose={handleClose}
 *       closeModalPredicate={() => true}
 *       isLoading={false}
 *       focusCloseButton={true}
 *     >
 *       {({ isLoading, CloseButtonWrapper, modalContentStyle }) => (
 *         <div className={modalContentStyle}>
 *           {isLoading ? (
 *             <div>Loading...</div>
 *           ) : (
 *             <>
 *               <h1>Modal Content</h1>
 *               <CloseButtonWrapper>
 *                 <button>Close</button>
 *               </CloseButtonWrapper>
 *             </>
 *           )}
 *         </div>
 *       )}
 *     </Modal>
 *   );
 * };
 * ```
 */
import React from 'react';
import PropTypes from 'prop-types';
import FocusLock from 'react-focus-lock';
import {addScrollLock, removeScrollLock} from 'lib/scrollUtil';
import classnames from 'classnames';
import {AppPortal} from 'client/Portals/AppPortal/AppPortal.react';
import './modal.scss';

/**
 * This is a modal that renders a shaded background along with its children. Showing and hiding the modal
 * should be handled from the parent component
 *
 * @see [Design ticket]{@link https://github.com/albert-io/project-management/issues/2326}
 *
 * Props:
 *   ariaLabel - Required - a label describing the purpose of the modal
 *   handleClose - Required - is a function that handles closing the modal and whatever other logic may be needed on close
 *   closeModalPredicate - a function that returns a boolean which can dictate whether or not to close the modal
 *   role - dialog or alertdialog to define ARIA semantics. Only use alertdialog for urgent information.
 *   longModal - reduces the default top padding
 *   focusCloseButton - default true for a11y support, if the modal is reliant on something that can't be wrapped in `closeButtonWrapper`
 *   this can be set to false to stop the `focusCloseButtonCounter` from being incremented to 10
 *                      ex: UpgradeSubectModal when a student is licensed and doesn't have access to assessments
 *
 * RenderProps:
 *   closeButtonWrapper:
 *    in order to comply with WAI-ARIA controls the modal close button must be sent as a parameter
 *      in the closeButtonWrapper() render prop.
 *    closeButtonWrapper() will apply the passed onClick to a wrapping span so
 *      _DO NOT_ add an onClick to the passed in object
 *    If multiple close buttons exist, ONLY WRAP ONE button.
 *   modalContentStyle:
 *    A string that will apply classes to the object being shown in modal
 *    should be added as a className to the outermost element within the <Modal>
 *    adds fade-in/out animation, overflow, and a box-shadow
 *   IsLoading:
 *    can be used to place a loading indicator within the children of the modal
 *    this is needed because occasionally if outside state didn't cause a modal rerender it would appear to always be loading
 */

type ConditionalProps =
  | {dismissable?: false; handleClose?: () => void}
  | {dismissable?: true; handleClose: () => void};

interface Props extends PropsWithClassNameOptional {
  ariaLabel: string;
  backdropClassName?: string;
  children(data: ChildrenProps): React.ReactElement;
  closeModalPredicate: () => boolean;
  fullscreen?: boolean;
  role?: 'dialog' | 'alertdialog';
  isLoading: boolean;
  longModal?: boolean;
  focusCloseButton: boolean;
}

interface ChildrenProps {
  isLoading: boolean;
  CloseButtonWrapper: ({
    className,
    children
  }: PropsWithChildrenRequired & PropsWithClassNameOptional) => JSX.Element;
  modalContentStyle: string;
}

type ModalProps = Props & ConditionalProps;

interface ModalState {
  showModal: boolean;
  isMounted: boolean;
}

export default class Modal extends React.Component<ModalProps, ModalState> {
  private closeButtonRef: React.RefObject<HTMLButtonElement | null>;

  private closeBtnTimeout: ReturnType<typeof setTimeout> | null;

  private closeModalTimeout: ReturnType<typeof setTimeout> | null;

  static propTypes = {
    ariaLabel: PropTypes.string.isRequired,
    backdropClassName: PropTypes.string,
    children: PropTypes.func.isRequired,
    closeModalPredicate: PropTypes.func,
    fullscreen: PropTypes.bool,
    handleClose: (props, propName, componentName) => {
      if (!props.dismissable || typeof props[propName] === 'function') {
        return null;
      }
      throw new Error(
        `Invalid prop ${propName} passed to ${componentName}. You must provide a function for dismissable Modals.`
      );
    },
    dismissable: PropTypes.bool,
    role: PropTypes.oneOf(['dialog', 'alertdialog']),
    isLoading: PropTypes.bool,
    longModal: PropTypes.bool,
    focusCloseButton: PropTypes.bool
  };

  static defaultProps = {
    closeModalPredicate: () => true,
    role: 'dialog',
    dismissable: true,
    isLoading: false,
    focusCloseButton: true
  };

  // showModal: used for updating classes to fade in/out child component
  // counter: makes sure `focusCloseButton` doesn't infinite loop
  constructor(props: ModalProps) {
    super(props);
    this.state = {
      showModal: true,
      isMounted: false
    };

    this.closeButtonRef = React.createRef();
    this.closeBtnTimeout = null;
    this.closeModalTimeout = null;
  }

  componentDidMount() {
    this.focusCloseButton();
    addScrollLock('html');
    // eslint-ignore-next-line react/no-did-mount-set-state
    this.setState({isMounted: true});
  }

  componentWillUnmount() {
    removeScrollLock('html');
    if (this.closeBtnTimeout) {
      clearTimeout(this.closeBtnTimeout);
    }
    if (this.closeModalTimeout) {
      clearTimeout(this.closeModalTimeout);
    }
  }

  /**
   * This is so we can `focus` the modal cancel button to comply with WAI-ARIA controls
   */

  focusCloseButton = () => {
    // ensures that all rendering has completed and the ref has been applied
    this.closeBtnTimeout = setTimeout(() => {
      if (this.closeButtonRef.current && this.props.focusCloseButton) {
        this.closeButtonRef.current.focus();
      }
    }, 0);
  };

  closeModal = () => {
    if (!this.props.closeModalPredicate?.()) {
      return;
    }

    if (!this.props.dismissable) {
      return;
    }
    this.setState({
      showModal: false
    });
    // Allows for animation time
    this.closeModalTimeout = setTimeout(() => {
      this.props.handleClose?.();
    }, 50);
  };

  handleBackdropClick = (e) => {
    if (e.target.isSameNode(e.currentTarget)) {
      this.closeModal();
    }
  };

  handleEscKeyPress = ({key}) => {
    if (key === 'Escape') {
      this.closeModal();
    }
  };

  // Applies focus to the element passed to the wrapper
  CloseButtonWrapper = ({
    className,
    children
  }: PropsWithChildrenRequired & PropsWithClassNameOptional) => {
    return (
      <>
        {React.Children.toArray(children).map((child) => {
          if (React.isValidElement(child)) {
            const closeButtonOnClick = child.props.onClick || this.closeModal;
            const closeButtonProps = {
              className: classnames(child.props.className, className),
              ref: this.closeButtonRef,
              'aria-label': 'close modal',
              onClick: closeButtonOnClick
            };
            return React.cloneElement(child, closeButtonProps);
          }
          return child;
        })}
      </>
    );
  };

  render() {
    const {backdropClassName, role, ariaLabel, isLoading, children, longModal, fullscreen} =
      this.props;
    const renderProps = {
      isLoading,
      CloseButtonWrapper: this.CloseButtonWrapper,
      modalContentStyle: classnames({
        'm-modal__content--fade-in': !this.state.isMounted,
        'm-modal__content': this.state.showModal,
        'm-modal__content-close': !this.state.showModal
      })
    };

    return (
      <AppPortal>
        <FocusLock
          className={classnames('modal__backdrop', backdropClassName, {
            'modal__backdrop-fade-out': !this.state.showModal,
            'modal__long-modal': longModal,
            'modal__full-screen': fullscreen
          })}
          as='div'
          lockProps={{
            'aria-modal': 'true',
            'aria-label': ariaLabel,
            onClick: this.handleBackdropClick,
            onKeyDown: this.handleEscKeyPress,
            tabIndex: '-1',
            role
          }}
          returnFocus
        >
          {children(renderProps)}
        </FocusLock>
      </AppPortal>
    );
  }
}
