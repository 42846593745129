/**
 * # WithToggle
 *
 * A higher-order component that provides toggle functionality to its child component.
 *
 * ## Props
 * - `as` (string|node): The HTML tag or React component to be rendered as the wrapper element. Default: 'div'.
 * - `className` (string): Additional CSS class name(s) to be applied to the wrapper element.
 * - `defaultOnState` (bool): The initial state of the toggle. Default: false.
 * - `dismissOnBlur` (bool): Whether to dismiss the toggle when clicking outside the component. Default: true.
 * - `dismissOnEscape` (bool): Whether to dismiss the toggle when pressing the Escape key. Default: false.
 * - `children` (func): A function that returns the child component with toggle-related props.
 * - `freeze` (bool): Whether to freeze the toggle state. Default: false.
 * - `onDismiss` (func): A callback function to be called when the toggle is dismissed.
 *
 * ## Usage
 *
 * ```jsx
 * import WithToggle from './WithToggle.react.js';
 *
 * const MyComponent = () => (
 *   <WithToggle
 *     as="button"
 *     className="my-toggle-button"
 *     defaultOnState={true}
 *     dismissOnBlur={false}
 *     dismissOnEscape={true}
 *     freeze={false}
 *     onDismiss={() => console.log('Toggle dismissed')}
 *   >
 *     {({ on, onBlur, onClick, onMouseEnter, onMouseLeave }) => (
 *       <button
 *         className={`my-component ${on ? 'active' : ''}`}
 *         onBlur={onBlur}
 *         onClick={onClick}
 *         onMouseEnter={onMouseEnter}
 *         onMouseLeave={onMouseLeave}
 *       >
 *         Toggle
 *       </button>
 *     )}
 *   </WithToggle>
 * );
 * ```
 */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default class WithToggle extends React.Component {
  static propTypes = {
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    className: PropTypes.string,
    defaultOnState: PropTypes.bool,
    dismissOnBlur: PropTypes.bool,
    dismissOnEscape: PropTypes.bool,
    children: PropTypes.func,
    freeze: PropTypes.bool,
    onDismiss: PropTypes.func
  };

  static defaultProps = {
    as: 'div',
    defaultOnState: false,
    dismissOnBlur: true,
    dismissOnEscape: false,
    onDismiss: () => {}
  };

  constructor(props) {
    super(props);

    this.state = {
      on: props.defaultOnState
    };

    this.toggleRef = React.createRef();
    this.timeoutId = null;
  }

  componentDidMount() {
    if (this.props.dismissOnBlur) {
      global.document.addEventListener('mousedown', this.handleClickOutsideComponent);
    }

    if (this.props.dismissOnEscape) {
      global.document.addEventListener('keydown', this.handleEscape);
    }
  }

  componentWillUnmount() {
    global.document.removeEventListener('mousedown', this.handleClickOutsideComponent);
    global.document.removeEventListener('keydown', this.handleEscape);
    clearTimeout(this.timeoutId);
  }

  handleClickOutsideComponent = (e) => {
    // the state.on check stops all instances of the eventListener from being triggered
    if (!this.toggleRef.current.contains(e.target) && this.state.on && !this.props.freeze) {
      this.setState({on: false});
      this.props.onDismiss(e);
    }
  };

  handleEscape = (e) => {
    if (e.key === 'Escape') {
      this.setState({on: false});
    }
  };

  toggleByClick = (e) => {
    if (e) {
      e.stopPropagation();
    }
    // OnMouseLeave shouldn't trigger closing if the element is clicked
    if (!this.state.on || this.state.onByMouseEnter) {
      this.setState({on: true, onByMouseEnter: false});
    } else {
      this.setState({on: false});
    }
  };

  onMouseEnter = () => {
    if (!this.state.on) {
      this.setState({
        on: true,
        onByMouseEnter: true
      });
    }
  };

  onMouseLeave = () => {
    if (this.state.onByMouseEnter) {
      this.setState({
        on: false,
        onByMouseEnter: false
      });
    }
  };

  // The setTimeout is needed to allow a render for the popover to be created
  reset = () => {
    this.timeoutId = setTimeout(() => {
      if (!this.toggleRef.current.contains(global.document.activeElement)) {
        this.setState({
          on: false,
          onByMouseEnter: false
        });
      }
    }, 0);
  };

  render() {
    const {as: WrapperElement, className} = this.props;
    return (
      <WrapperElement
        className={classnames('with-toggle__wrapper', className)}
        ref={this.toggleRef}
      >
        {this.props.children({
          ...this.state,
          onBlur: this.reset,
          onClick: this.toggleByClick,
          onMouseEnter: this.onMouseEnter,
          onMouseLeave: this.onMouseLeave
        })}
      </WrapperElement>
    );
  }
}
