// @flow
import * as React from 'react';

/**
 * deferComponentRender HOC
 *
 * This HOC serves to defer the rendering of large trees of components, such as you find in the Subject View,
 * until all other UI updates are complete.  This improves perceived importance by moving all other UI behavior
 * to the front of the line, and saving expensive renders for later.
 *s
 * See "Defer Rendering When Mounting & Unmounting Many Components"
 * https://medium.com/@paularmstrong/twitter-lite-and-high-performance-react-progressive-web-apps-at-scale-d28a00e780a3
 */
type State = {shouldRender: boolean};

export default function deferComponentRender(
  WrappedComponent: React.ComponentType<*>
): React.ComponentType<*> {
  return class DeferredRenderWrapper extends React.Component<*, State> {
    state = {
      shouldRender: false
    };

    componentDidMount() {
      global.requestAnimationFrame(() => {
        global.requestAnimationFrame(() => this.setState({shouldRender: true}));
      });
    }

    render(): ?React.Node {
      return process.env.IS_BROWSER && !this.state.shouldRender ? null : (
        <WrappedComponent {...this.props} />
      );
    }
  };
}
