import {PublicQuestionModelV1} from 'resources/GeneratedModels/PublicQuestion/PublicQuestionModel.v1';
import constants from 'client/constants';

const difficultiesMap = Object.entries(constants.QUESTION_IMPORT.DIFFICULTIES).reduce(
  (acc, [label, value]) => {
    acc[value] = label;
    return acc;
  },
  {}
);

PublicQuestionModelV1.extend({
  getDifficultyLabel() {
    return difficultiesMap[this.getDifficulty()];
  }
});

export {PublicQuestionModelV1};
