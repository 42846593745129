import ImmutablePropTypes from 'react-immutable-proptypes';
import React from 'react';
import PropTypes from 'prop-types';
import {callTargetedAction, setUpStore} from 'client/framework';
import EliminationTool from './EliminationTool/EliminationTool.react';
import eliminationToolStore from './EliminationTool/EliminationTool.store';
import AnswerOptionEliminator from './EliminationTool/AnswerOptionEliminator.react';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import multipleChoiceQuestionActions from './MultipleChoiceQuestion.actions';
import MultipleChoiceOption from './MultipleChoiceOption.react';
import MultipleChoiceQuestionStore from './MultipleChoiceQuestion.store';
import './multiple-choice-question.scss';
import './EliminationTool/elimination-tool.scss';

export default class MultipleChoiceQuestion extends React.Component {
  static propTypes = {
    isGuessSubmitted: PropTypes.bool,
    storeName: PropTypes.string,
    question: ImmutablePropTypes.record,
    questionId: PropTypes.string
  };

  static defaultProps = {
    storeName: 'multipleChoiceQuestionStore'
  };

  componentDidUpdate() {
    if (!eliminationToolStore.isEnabled() && this.getStore().areOptionsEliminated()) {
      callTargetedAction({
        name: multipleChoiceQuestionActions.SET_ELIMINATED_TO_EMPTY,
        targetStore: this.props.storeName
      });
    }
  }

  getStore() {
    return setUpStore(
      MultipleChoiceQuestionStore,
      this.props.storeName,
      this.props.questionId,
      this.props.question
    );
  }

  render() {
    const store = this.getStore();
    const options = this.props.question.getOptions();
    const prompt = this.props.question.getPrompt();
    const {storeName, isGuessSubmitted} = this.props;
    return (
      <div className='mcq'>
        <MarkdownRendererV2 className='mcq__question-content' text={prompt} />
        <EliminationTool hidden={isGuessSubmitted} storeName={storeName} />
        <div className='mcq__options-wrapper'>
          {options.map((option, index) => {
            const optionId = option.getId();
            return (
              <AnswerOptionEliminator
                key={optionId}
                store={store}
                optionId={optionId}
                isGuessSubmitted={isGuessSubmitted}
              >
                <MultipleChoiceOption
                  index={index}
                  option={option}
                  isGuessSubmitted={isGuessSubmitted}
                  isSelected={store.isSelectedOption(optionId)}
                  isCorrectAnswer={store.correctAnswer.has(optionId)}
                  isEliminated={store.isOptionEliminated(optionId)}
                  targetStore={store.getName()}
                />
              </AnswerOptionEliminator>
            );
          })}
        </div>
      </div>
    );
  }
}
