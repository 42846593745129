// @flow
import * as React from 'react';
import {debounce} from 'lodash';
import {callAction} from 'client/framework';

import TextInput from 'generic/Forms/TextInput/TextInput';

import {
  SEARCH_PARAMETERS,
  getURLSearchParameter,
  setURLSearchParameter
} from '../URLSearchParameters';

import titleSearchActions from './TitleSearch.actions';
import titleSearchStore from './TitleSearch.store';

export class TitleSearch extends React.Component<{}> {
  componentDidMount() {
    callAction(
      titleSearchActions.SET_SEARCH_STRING,
      getURLSearchParameter(SEARCH_PARAMETERS.search) || ''
    );
  }

  handleChange = (e) => {
    const {value} = e.target;
    callAction(titleSearchActions.SET_SEARCH_STRING, value);
    this.setUrlSearchParam(value);
  };

  setUrlSearchParam = debounce((string: string) => {
    setURLSearchParameter(SEARCH_PARAMETERS.search, string);
  }, 500);

  render(): React.Node {
    return (
      <TextInput
        className='queue-search-panel__facet'
        label='Question search'
        maxLength={128}
        onChange={this.handleChange}
        type='search'
        value={titleSearchStore.getSearchString()}
      />
    );
  }
}
