import React from 'react';
import {Map} from 'immutable';
import moment from 'moment';

import {
  getAuthorQuestionCountStats,
  getAuthorQuery,
  getQuestionSetQuery,
  getActivityQuery,
  getMetricsQuery,
  getQuestionSetWithStatusTrackingQuery
} from '../helpers/queries';
import {createYearObject, createTypeObject} from '../helpers/utils';

export const AuthorDashboardContext = React.createContext();

const DATE_RANGES = {
  'last-year': [
    moment().subtract(1, 'years').startOf('year').valueOf(),
    moment().startOf('year').valueOf()
  ],
  'this-year': [
    moment().startOf('year').valueOf(),
    moment().add(1, 'years').startOf('year').valueOf()
  ]
};

const SHOW_MORE_RATE = 8;

export default class AuthorDashboardProvider extends React.Component {
  constructor() {
    super();
    this.state = {
      data: null,
      isLoaded: false,
      indexOfActivity: SHOW_MORE_RATE,
      count: {
        allTime: null,
        thisMonth: null,
        thisYear: null
      },
      questions: null,
      questionSets: null,
      sortedQuestions: null,
      recentQuestions: null,
      questionDifficulties: null,
      questionTypes: null,
      handleShowMore: this.handleShowMore,
      handleDateChange: this.handleDateChange,
      handleSubjectSelection: this.handleSubjectSelection,
      storeCustomStartDate: this.storeCustomStartDate,
      storeCustomEndDate: this.storeCustomEndDate,
      dateRange: DATE_RANGES['this-year'],
      publishedSubjects: [],
      selectedSubjects: [],
      startDate: 'initial',
      endDate: 'initial',
      noActivity: false,
      noRecent: false,
      isMetricsLoaded: false,
      selectValue: '',
      isNewAuthor: false
    };
  }

  /* Lifecycle methods */
  async componentDidMount() {
    this.calculateQuestionMetrics(DATE_RANGES['this-year'], this.state.publishedSubjects);
    const count = await getAuthorQuestionCountStats();
    this.fetchAuthorInfo();
    const authoredQuestions = await getQuestionSetQuery().getResourcePromise();
    const isNewAuthor = authoredQuestions.size === 0;
    this.setState({
      //eslint-disable-line
      count,
      isNewAuthor
    });
  }

  /* Event Handlers */

  handleShowMore = () => {
    this.setState(({indexOfActivity}) => {
      return {indexOfActivity: indexOfActivity + SHOW_MORE_RATE};
    });
  };

  handleDateChange = (e) => {
    let dateRange;
    if (e.target.value === 'this-year' || e.target.value === 'last-year') {
      dateRange = DATE_RANGES[e.target.value];
      this.setState({dateRange, selectValue: e.target.value}, () => {
        this.calculateQuestionMetrics(this.state.dateRange, this.state.selectedSubjects);
      });
    } else {
      // handle custom case, where we dont want to caluclate the metrics just yet.
      this.setState({selectValue: e.target.value});
    }
  };

  handleSubjectSelection = (e, subjectIndex) => {
    const subject = this.state.publishedSubjects.get(subjectIndex);
    const {selectedSubjects} = this.state;

    if (!selectedSubjects.includes(subject)) {
      this.setState({selectedSubjects: selectedSubjects.concat(subject)}, () => {
        this.calculateQuestionMetrics(this.state.dateRange, this.state.selectedSubjects);
      });
    } else {
      this.setState(
        {selectedSubjects: selectedSubjects.splice(selectedSubjects.indexOf(subject), 1)},
        () => {
          if (this.state.selectedSubjects.size !== 0) {
            this.calculateQuestionMetrics(this.state.dateRange, this.state.selectedSubjects);
          } else {
            this.setState({
              publishedQuestionDates: {},
              questionDifficulties: {all: 0, easy: 0, moderate: 0, difficult: 0},
              questionTypes: {
                all: 0,
                multipleChoice: 0,
                freeEntry: 0,
                textHighlight: 0,
                fillInTheBlank: 0,
                twoWay: 0,
                graphing: 0
              }
            });
          }
        }
      );
    }
  };

  storeCustomStartDate = (startDate) => {
    this.setState(
      {
        startDate,
        dateRange: [startDate, this.state.endDate]
      },
      () => {
        const now = moment().endOf('day').valueOf();
        const yearAfterStartDate = moment(this.state.startDate).add(1, 'years');
        const isBefore = moment(this.state.endDate).isBefore(yearAfterStartDate);
        if (
          this.state.startDate &&
          this.state.endDate &&
          this.state.startDate <= this.state.endDate &&
          this.state.endDate <= now &&
          isBefore
        ) {
          this.calculateQuestionMetrics(this.state.dateRange, this.state.selectedSubjects);
        }
      }
    );
  };

  storeCustomEndDate = (endDate) => {
    this.setState(
      {
        endDate,
        dateRange: [this.state.startDate, endDate]
      },
      () => {
        const now = moment().endOf('day').valueOf();
        const yearAfterStartDate = moment(this.state.startDate).add(1, 'years');

        const isBefore = moment(this.state.endDate).isBefore(yearAfterStartDate);

        if (
          this.state.startDate &&
          this.state.endDate &&
          this.state.startDate <= this.state.endDate &&
          this.state.endDate <= now &&
          isBefore
        ) {
          this.calculateQuestionMetrics(this.state.dateRange, this.state.selectedSubjects);
        }
      }
    );
  };

  /* Calculations */
  calculateQuestionMetrics = async (dateRange, selectedSubjects) => {
    this.setState({isMetricsLoaded: false});
    let baseQuery = getMetricsQuery();

    if (dateRange) {
      baseQuery = baseQuery.filter({
        published_at: {
          greater_than_inclusive: dateRange[0],
          less_than_inclusive: dateRange[1]
        }
      });
    }

    selectedSubjects.forEach((subject) => {
      baseQuery = baseQuery.filter({
        any_of: [
          {
            authoring_subject_v1: {
              name: {
                case_insensitive_substring: subject
              }
            }
          }
        ]
      });
    });

    const baseQueryWithMaxPageSize = baseQuery.pageSize(250);
    const publishedQuestionSets = await baseQueryWithMaxPageSize.getResourcePromise();
    // const publishedQuestions = this.state.data.getAuthoredQuestionSets();
    const publishedQuestionDates = createYearObject();
    const questionDifficulties = {
      all: 0,
      easy: 0,
      moderate: 0,
      difficult: 0
    };

    const questionTypesResult = createTypeObject();
    publishedQuestionSets.forEach((set) => {
      const month = set.getPublishedAt().format('MMM');

      publishedQuestionDates[month].count += set.getMeta().getCountOfQuestions();
      publishedQuestionDates[month].easy += set.getMeta().getCountOfEasyQuestions();
      publishedQuestionDates[month].moderate += set.getMeta().getCountOfModerateQuestions();
      publishedQuestionDates[month].difficult += set.getMeta().getCountOfDifficultQuestions();

      questionDifficulties.all += set.getMeta().getCountOfQuestions();
      questionDifficulties.easy += set.getMeta().getCountOfEasyQuestions();
      questionDifficulties.moderate += set.getMeta().getCountOfModerateQuestions();
      questionDifficulties.difficult += set.getMeta().getCountOfDifficultQuestions();

      questionTypesResult.all += set.getMeta().getCountOfQuestions();
      questionTypesResult.freeResponse += set.getMeta().getCountOfFreeResponseQuestionsPublished();
      questionTypesResult.twoWay += set.getMeta().getCountOfTwoWayQuestionsPublished();
      questionTypesResult.multipleChoice += set
        .getMeta()
        .getCountOfMultipleChoiceQuestionsPublished();
      questionTypesResult.freeEntry += set.getMeta().getCountOfFreeEntryQuestionsPublished();
      questionTypesResult.snippetSelection += set
        .getMeta()
        .getCountOfSnippetSelectionQuestionsPublished();
      questionTypesResult.fillInTheBlank += set
        .getMeta()
        .getCountOfFillInTheBlankQuestionsPublished();
      questionTypesResult.passageCorrection += set
        .getMeta()
        .getCountOfPassageCorrectionQuestionsPublished();
      questionTypesResult.textHighlight += set
        .getMeta()
        .getCountOfTextHighlightQuestionsPublished();
      questionTypesResult.graphing += set.getMeta().getCountOfGraphingQuestionsPublished();
    });

    this.setState({
      questionDifficulties,
      questionTypes: questionTypesResult,
      publishedQuestionDates,
      isMetricsLoaded: true
    });
  };

  /* Fetchers/Getters */

  fetchAuthorInfo = async () => {
    // data = author information
    const data = await getAuthorQuery().getResourcePromise();
    const recentQuestionSets = await getQuestionSetWithStatusTrackingQuery().getResourcePromise();
    const questions = this.getQuestions(recentQuestionSets);

    // questions should come back sorted once query is correct
    const sortedQuestions = questions.sort((question1, question2) =>
      question1.get('question').getMeta().getQuestionOrStatusUpdatedAt() <=
      question2.get('question').getMeta().getQuestionOrStatusUpdatedAt()
        ? 1
        : -1
    );

    const publishedSubjects = this.getPublishedSubjects(data.getAuthoringSubjects());
    const selectedSubjects = publishedSubjects;
    const activityQuestionSets = await getActivityQuery().getResourcePromise();
    const activityItems = this.getActivityItems(activityQuestionSets);
    const recentQuestions = sortedQuestions.slice(0, 4);
    if (recentQuestions.length === 0) {
      this.setState({noRecent: true});
    }

    // CREATE LIST OF SUBJECTS

    // eslint-disable-next-line
    this.setState({
      isLoaded: true,
      data,
      questions,
      // qestionSets,
      activityItems,
      sortedQuestions,
      recentQuestions,
      selectedSubjects,
      publishedSubjects
    });
  };

  getQuestions = (questionSet) => {
    return questionSet.reduce((acc, questionSet) => {
      const questions = questionSet.get('authoring_questions');
      if (questions.size > 0) {
        questions.map((question) => {
          acc.push(Map({question, questionSet}));
        });
      }
      return acc;
    }, []);
  };

  getPublishedSubjects = (questionSets) => {
    return questionSets
      .map((set) => {
        return set.getName();
      })
      .toSet()
      .toList();
  };

  getActivityItems = (questionSets) => {
    // IF QUESTON HAS FEEDBACK, NEED:
    // Subject (questionSet)
    // setId (questionSet)
    // questionId (question)
    // Title (question)
    // Message (question -> Feedback -> message)
    // updatedAt (feedback)

    // return as: map

    const activityItems = [];

    questionSets.forEach((set) => {
      const setId = set.getId();
      const subject = set.getAuthoringSubject().getName();
      const questions = set.getAuthoringQuestions();
      // if its published, append the published set
      questions.forEach((question) => {
        const feedback = question.getAuthoringFeedback();
        const questionId = question.getId();
        const title = question.getTitle();
        if (set.getPublishedAt()) {
          const publishedAt = set.getPublishedAt();
          const publishedAtDisplay = set.getPublishedAt().format('DD MMMM YYYY hh:mm A');
          activityItems.push({
            type: 'publishedQuestion',
            time: publishedAt,
            setId,
            subject,
            questionId,
            publishedAt,
            publishedAtDisplay,
            title
          });
        }
        if (feedback.size > 0) {
          feedback.forEach((fb) => {
            activityItems.push({
              type: 'feedback',
              time: fb.getUpdatedAt(),
              setId,
              subject,
              questionId,
              title,
              user: `${fb.getMeta().getUserFirstName()} ${fb.getMeta().getUserLastName()}`,
              message: fb.getMessage(),
              updatedAt: fb.getUpdatedAt(),
              updatedAtDisplay: fb.getUpdatedAt().format('DD MMMM YYYY hh:mm A'),
              feedbackId: fb.getId()
            });
          });
        }
      });
    });
    const sortedActivityItems = activityItems.sort((item1, item2) => {
      return item1.time.isBefore(item2.time) ? 1 : -1;
    });

    // for empty state
    if (sortedActivityItems.length === 0) {
      this.setState({noActivity: true});
    }
    return sortedActivityItems;
  };

  render() {
    return (
      <AuthorDashboardContext.Provider value={this.state}>
        {this.props.children}
      </AuthorDashboardContext.Provider>
    );
  }
}
