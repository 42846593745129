import React from 'react';

import {
  QuestionComponent,
  QuestionComponentProps
} from '../shared/QuestionComponent/QuestionComponent.react';

import {MultipleChoiceInputArea} from './MultipleChoiceInputArea.react';
import {MCQHowOthersAnswered} from './MCQHowOthersAnswered.react';

const MultipleChoiceQuestionComponent = (props: QuestionComponentProps) => {
  const {activeQuestion} = props;
  return (
    <QuestionComponent
      {...props}
      customHOA={<MCQHowOthersAnswered question={activeQuestion} />}
      ActiveQuestionInputArea={MultipleChoiceInputArea}
    />
  );
};

type MultipleChoiceQuestionTypeProps = Omit<QuestionComponentProps, 'questionState'>;

const MultipleChoiceQuestion = {
  PreGuess: (props: MultipleChoiceQuestionTypeProps) => (
    <MultipleChoiceQuestionComponent {...props} questionState='PreGuess' />
  ),
  PostGuess: (props: MultipleChoiceQuestionTypeProps) => (
    <MultipleChoiceQuestionComponent {...props} questionState='PostGuess' />
  )
};

export {MultipleChoiceQuestion};
