/* eslint-disable no-underscore-dangle */
import {fromJS, Map} from 'immutable';
import constants from 'client/constants';
import {camelCase, flow, upperFirst} from 'lodash';

import {getRequestAgent} from '@albert-io/mandark/authentication';

import {Store} from 'client/framework';

import notifier from '@albert-io/notifier';
import {AuthoringSupplementModelV1} from 'resources/augmented/AuthoringSupplement/AuthoringSupplementModel.v1';
import {AuthoringSubjectModelV1} from 'resources/augmented/AuthoringSubject/AuthoringSubjectModel.v1';

import supplementEditorActions from './SupplementEditor.actions';

const ATTRIBUTE_NAMES = [
  'alttext',
  'audio',
  'caption',
  'imageUrl',
  'name',
  'text',
  'type',
  'videoId'
];

class SupplementEditorStore extends Store {
  constructor(name) {
    super(name);

    this.initialData = fromJS({
      activeSubject: {},
      activeSupplement: {},
      errors: {},
      hasErrors: false,
      response: null,
      isUploadPending: false
    });

    this.setInitialData(this.initialData);

    // Render the editor with an empty image supplement by default
    this.writeData(
      'activeSupplement',
      AuthoringSupplementModelV1.getDefaultModel().setType('image').setTranslate(true)
    );
    this.writeData('activeSubject', AuthoringSubjectModelV1.getDefaultModel());

    this.handle(supplementEditorActions.MODIFY_ACTIVE_SUPPLEMENT, this._modifyActiveSupplement);
    this.handle(supplementEditorActions.RESET_STORE, this._resetStore);
    this.handle(supplementEditorActions.SET_ACTIVE_SUBJECT, this.setProperty('activeSubject'));
    this.handle(
      supplementEditorActions.SET_ACTIVE_SUPPLEMENT,
      this.setProperty('activeSupplement')
    );
    this.handle(supplementEditorActions.VALIDATE_SUPPLEMENT, this._validateSupplement);
    this.handle(supplementEditorActions.UPLOAD_FILE, this._uploadFile);
  }

  /*
   * For a given array of setter and value key-value pairs, call the setter with the value on the supplement
   */
  _modifyActiveSupplement(setterArgsParam) {
    const setterArgs = Array.isArray(setterArgsParam) ? setterArgsParam : [setterArgsParam];
    let updatedSupplement = this.getActiveSupplement();
    setterArgs.forEach(({setter, value}) => {
      updatedSupplement = updatedSupplement[setter](value);
    });
    this.writeData('activeSupplement', updatedSupplement);
  }

  _resetStore() {
    this.writeData(this.initialData);
    this.writeData((store) => {
      return store
        .set(
          'activeSupplement',
          AuthoringSupplementModelV1.getDefaultModel().setType('image').setTranslate(true)
        )
        .set('activeSubject', AuthoringSubjectModelV1.getDefaultModel());
    });
  }

  async _uploadFile({name, setter, file}) {
    this.writeData('isUploadPending', true);
    try {
      /**
       * @todo DRY:IMAGE_UPLOADING
       */
      const response = await getRequestAgent()
        .post(constants.MANDARK_FULL_PATH + ['/google_cloud_api/supplements'])
        .field('file', file)
        .timeout(constants.ALBERT_TIMEOUT);
      this._modifyActiveSupplement({
        setter,
        value: response.body?.filename
      });
      /**
       * @todo It's unclear whether or not this is actually neccessary... it seems like we attempt to allow
       * validation on the attribute an uploaded asset URL is found on, but we don't seem to actually
       * be setting a `name` on most of the elements that call this action.
       */
      if (name) {
        this._validateSupplement([name]);
      }
    } catch (error) {
      let message = 'There was a problem with your upload. Please try again.';
      if (error.status === 413) {
        message = 'Uploads over 10MB are not allowed.';
      }
      notifier.notify(error, {
        fingerprint: 'SupplementUploadFailure',
        component: 'SupplementEditor'
      });
      this.writeData((store) => {
        return store
          .set('hasErrors', true)
          .set('errors', this.getErrors().set('imageUrl', message));
      });
    } finally {
      this.writeData('isUploadPending', false);
    }
  }

  _validateSupplement(attributes = ATTRIBUTE_NAMES) {
    // Validates all fields by default
    let errors = this.getErrors();
    const {validators} = this.getActiveSupplement();

    for (const attributeName of attributes) {
      const validatorName = `get${flow(camelCase, upperFirst)(attributeName)}`;

      const error = validators[validatorName](); // returns List or null

      errors = errors.set(attributeName, error ? error.first() : null);
    }

    /**
     * @todo Replace this means for validating subjects once
     * the missing ones have been remediated on the back-end
     */
    const shouldRequireSubject = !this.getActiveSupplement().existsOnServer();
    const isMissingSubject = shouldRequireSubject && !this.getActiveSubject();
    const hasErrors =
      isMissingSubject || this.getActiveSupplement().validators.hasValidationErrors();

    this.writeData((store) => {
      return store.set('hasErrors', hasErrors).set('errors', errors);
    });
  }

  getActiveSubject() {
    return this.readData('activeSubject');
  }

  getActiveSupplement() {
    return this.readData('activeSupplement');
  }

  getErrors() {
    return this.readData().get('errors', new Map());
  }

  hasErrors() {
    return this.readData('hasErrors');
  }

  isUploadPending() {
    return this.readData('isUploadPending');
  }
}

export default new SupplementEditorStore('SupplementEditorStore');
