import React, {useRef} from 'react';
import {isEqual} from 'lodash';
import {
  Button,
  Card,
  Dropdown,
  Icon,
  MenuListItem,
  MenuListItemCategoryText,
  MenuListItemRegularText,
  Toggle,
  WithToggle
} from '@albert-io/atomic';

import {
  Dimension,
  DimensionColumn,
  MetricColumn,
  QueryVariables,
  TopLevelFilter
} from '../reports.types';

import {
  columnIsAvailable,
  getAvailableColumns,
  METRIC_COLUMNS,
  sortColumns
} from '../reports.utils';

import './metrics-selector.scss';

const getDimensionsTitle = (dimensions: Dimension[]) => {
  if (isEqual(dimensions, ['students'])) {
    return 'Student';
  }
  if (isEqual(dimensions, ['assignments'])) {
    return 'Assignment';
  }
  if (isEqual(dimensions, ['standards'])) {
    return 'Standard';
  }
  if (isEqual(dimensions, ['questions'])) {
    return 'Question';
  }
  if (isEqual(dimensions, ['students', 'assignments'])) {
    return 'Gradebook';
  }
  return '';
};

interface Props {
  dimensions: Dimension[];
  filters: QueryVariables;
  topLevelFilterType: TopLevelFilter;
  isSelected: (option: DimensionColumn | MetricColumn) => boolean;
  onSelect: (option: DimensionColumn | MetricColumn) => void;
  isCollapsed?: boolean;
  availableDimensionColumns: DimensionColumn[];
}

export const MetricsSelector = ({
  dimensions,
  filters,
  isSelected,
  onSelect,
  topLevelFilterType,
  isCollapsed,
  availableDimensionColumns
}: Props) => {
  const toggleRef = useRef(null);

  const toggleableAvailableDimensionColumns = availableDimensionColumns.filter(
    ({isToggleable = false}) =>
      typeof isToggleable === 'boolean' ? isToggleable : isToggleable(dimensions, filters)
  );

  const availableMetricColumns = getAvailableColumns(
    METRIC_COLUMNS,
    dimensions,
    filters,
    topLevelFilterType
  );

  const sortedAvailableColumns = sortColumns(
    toggleableAvailableDimensionColumns,
    availableMetricColumns
  );

  const unavailablecolumns = METRIC_COLUMNS.filter(
    (o) => !columnIsAvailable(o, dimensions, filters, topLevelFilterType)
  );

  const renderOption = (option: DimensionColumn | MetricColumn, disabled: boolean) => (
    <Option
      option={option}
      dimensions={dimensions}
      variables={filters}
      disabled={disabled}
      selected={isSelected(option)}
      onSelect={onSelect}
      key={option.key}
    />
  );

  const availableMetricsTitle = `${getDimensionsTitle(dimensions)} Selections`;

  return (
    <WithToggle>
      {({on, onClick}) => (
        <>
          <Button
            color='secondary'
            variant='outlined'
            onClick={onClick}
            ref={toggleRef}
            className='u-text-wrap_nowrap u-gap_space-x1'
            size={isCollapsed ? 's' : 'm'}
          >
            <Icon icon='table-columns' iconStyle='regular' />
            <span>Column Options</span>
            <Icon icon='caret-down' />
          </Button>
          {on ? (
            <Dropdown.Tray
              className='metrics-selector__dropdown-tray'
              positionFixed
              placement='bottom-end'
              target={toggleRef.current}
            >
              <Card paddingLevel={1}>
                {sortedAvailableColumns.length > 0 ? (
                  <MenuListItem
                    className='u-justify-content_flex-start'
                    clickable={false}
                    hoverable={false}
                  >
                    <MenuListItemCategoryText
                      size='s'
                      className='u-font-weight_normal u-color_slate-600'
                    >
                      {availableMetricsTitle}
                    </MenuListItemCategoryText>
                  </MenuListItem>
                ) : null}
                {sortedAvailableColumns.map((o) => renderOption(o, false))}

                {unavailablecolumns.length > 0 ? (
                  <MenuListItem
                    className='u-justify-content_flex-start'
                    clickable={false}
                    hoverable={false}
                  >
                    <MenuListItemCategoryText
                      size='s'
                      className='u-font-weight_normal u-mar-t_2 u-color_slate-600'
                    >
                      Unavailable For This View
                    </MenuListItemCategoryText>
                  </MenuListItem>
                ) : null}
                {unavailablecolumns.map((o) => renderOption(o, true))}
              </Card>
            </Dropdown.Tray>
          ) : null}
        </>
      )}
    </WithToggle>
  );
};

const Option = ({
  option,
  dimensions,
  variables,
  disabled,
  selected,
  onSelect
}: {
  option: DimensionColumn | MetricColumn;
  dimensions: Dimension[];
  variables: QueryVariables;
  disabled: boolean;
  selected: boolean;
  onSelect: (option: DimensionColumn | MetricColumn) => void;
}) => {
  const TextComponent = disabled ? MenuListItemCategoryText : MenuListItemRegularText;
  return (
    <MenuListItem
      className='u-justify-content_flex-start u-pad_1'
      hoverable={!disabled}
      clickable={!disabled}
      onMenuItemClick={() => {
        if (!disabled) {
          onSelect(option);
        }
      }}
    >
      <Toggle
        className='u-mar-r_2'
        checked={selected && !disabled} // show as "off" if this column is not available
        disabled={disabled}
        onChange={() => onSelect(option)}
      />
      <TextComponent>
        {'title' in option ? (
          option.title
        ) : (
          <option.renderTitle dimensions={dimensions} variables={variables} />
        )}
      </TextComponent>
    </MenuListItem>
  );
};
