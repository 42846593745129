import {Map} from 'immutable';

import SupplementNotFound from './SupplementNotFound';

export default Map({
  renderer: SupplementNotFound,
  type: 'not-found',
  label: 'Not Found',
  hidden: true
});
