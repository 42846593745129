/*
  This object is a mapping from subjectId to the subject's blog post on how to review for the AP exam.
  The object is used within  `MarketingBanner.react`
*/
const updatedSubjects = {
  '7712e9bb-0595-4cb0-b7f9-9aeede95a3d2': '/blog/ap-biology-review/',
  '5bedb647-f913-429b-86c7-16d6ba0c2a2c': '/blog/ap-calculus-ab-bc-review/',
  'f1707a65-a966-4732-876b-edbbc80fbc9b': '/blog/ap-chemistry-review/',
  '9a32d19f-3fdb-48f5-a279-c7a7df6401c5': '/blog/ap-english-language-review/',
  '92fee573-1eac-469f-a807-7618401f74f2': '/blog/ap-english-literature-review/',
  '1de2e4c2-2e4d-4c35-ab07-a6e66d66138e': '/blog/ap-environmental-science-review/',
  'bb894d4b-e307-4094-8d39-77b1880905f4': '/blog/ap-human-geography-review/',
  '50118483-8160-4fd7-ad2e-804465ab52aa': '/blog/ap-macroeconomics-review/',
  '17225a1c-7d4a-46f9-80f0-e04784ced466': '/blog/ap-microeconomics-review/',
  '2420d273-d77e-4c63-9008-e5945db0cdb3': '/blog/ap-physics-1-review/',
  '2bab5308-a372-4877-a915-9af5bc1812c5': '/blog/ap-psychology-review/',
  '1b026ba2-0a62-4147-8ad7-fd92a724666a': '/blog/ap-statistics-review/',
  'a5ece7db-45db-44b4-893c-a4e438c56c93': '/blog/ap-us-government-review/',
  'f68fb63e-9ef6-4def-8358-56f25954c9e6': '/blog/ap-us-history-review/',
  '507acbc1-4c32-4fec-aba6-a2240274924a': '/blog/ap-world-history-review/'
};

export default updatedSubjects;
