/**
 * # WindowSizeProvider
 *
 * WindowSizeProvider is a component that exposes the window's inner height, width and our defined breakpoints to its children
 *
 * ## Props
 * - None
 *
 * ## Usage
 *
 * ```jsx
 * <WindowSizeProvider>
 *   {/* Your components here *`/}
 * </WindowSizeProvider>
 */
import React from 'react';
import {debounce} from 'lodash';
import {BREAKPOINTS as breakpoints} from 'client/constants';

export const WindowSizeContext = React.createContext();

/**
 * @callback functionAsChild
 * @param props
 * @param props.viewportHeight - Current height of the viewport
 * @param props.viewportWidth - Current width of the viewport
 * @param props.breakpoints - Object of the breakpoints we use in our application
 */

/**
 * WindowSizeConsumer is a context consumer which provides the window's inner height, width,
 * and the app's breakpoints to a function as child.
 *
 * @example
 * <WindowSizeConsumer>
 *   {({
 *     viewportHeight,
 *     viewportWidth
 *   }) => <p>The viewport's height is {viewportHeight} and its width is {viewportWidth}</p>}
 * </WindowSizeConsumer>
 * @augments {React.Component<{{children: functionAsChild}}>}
 */
export const WindowSizeConsumer = WindowSizeContext.Consumer;

let instanceCount = 0;

/**
 * WindowSizeProvider should be placed once, at the top of the component tree, so any children may
 * use the WindowSizeConsumer.
 *
 * @augments {React.Component<any>}
 */
export class WindowSizeProvider extends React.Component {
  state = {
    viewportHeight: process.env.IS_BROWSER ? global.innerHeight : null,
    viewportWidth: process.env.IS_BROWSER ? global.innerWidth : null,
    // Adding breakpoints in here so we can pass the whole state object as WindowSizeContext.Provider's value
    breakpoints
  };

  componentDidMount() {
    instanceCount++;
    if (instanceCount > 1) {
      throw new Error(
        'Only one WindowSizeProvider should be mounted at a time, ' +
          'as it adds a global resize event listener when it mounts.'
      );
    }
    if (!process.env.IS_BROWSER) {
      return;
    }
    global.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    instanceCount--;
    global.removeEventListener('resize', this.handleResize);
  }

  handleResize = debounce(() => {
    this.setState({
      viewportHeight: global.innerHeight,
      viewportWidth: global.innerWidth
    });
  }, 250);

  render() {
    return (
      <WindowSizeContext.Provider value={this.state}>
        {this.props.children}
      </WindowSizeContext.Provider>
    );
  }
}
