import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Heading from '../../../atoms/Heading/Heading.react';
import Text from '../../../atoms/Text/Text.react';
import './empty-table.scss';

export default class EmptyTable extends React.PureComponent {
  static propTypes = {
    header: PropTypes.string,
    hideHeader: PropTypes.bool,
    text: PropTypes.string,
    buttonContent: PropTypes.element,
    colSpan: PropTypes.number
  };

  static defaultProps = {
    header: 'Table has no data',
    hideHeader: false,
    colSpan: 100
  };

  render() {
    return (
      <tr>
        <td className='o-table__empty u-pad_5' colSpan={this.props.colSpan}>
          {!this.props.hideHeader && <Heading>{this.props.header}</Heading>}
          {this.props.text && (
            <Text
              as='div'
              className={classnames('o-table__empty-text', 'u-mar-lr_auto', {
                'u-mar-t_2': !this.props.hideHeader
              })}
              size='l'
              color='secondary'
            >
              {this.props.text}
            </Text>
          )}
          {this.props.buttonContent && <div className='u-mar-t_5'>{this.props.buttonContent}</div>}
        </td>
      </tr>
    );
  }
}
