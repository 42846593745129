import React, {useCallback, useState} from 'react';
import {Helmet} from 'react-helmet';
import validator from 'validator';

import {title} from 'lib/head';

import {genericMandarkRequest} from 'resources/mandark.resource';
import {Banner, Button, Card, Form, TextField, addToast} from '@albert-io/atomic';

import './assets/forgot-password.scss';

export default function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [hasError, setError] = useState(false);

  const validate = useCallback(() => {
    setError(!validator.isEmail(email));
  }, [email]);

  const sendResetLink = useCallback(
    async (e) => {
      e.preventDefault();
      if (!email || hasError) {
        return;
      }
      try {
        await genericMandarkRequest(
          'post',
          {
            resourcePath: ['auth', 'forgot_password']
          },
          {email}
        );

        addToast({
          color: 'positive',
          title: 'Reset link is sent successfully!',
          message: `We've sent you a reset link to the email you entered.`
        });
      } catch (err) {
        addToast({
          color: 'negative',
          title: 'Unable to reset password.',
          message: 'We were unable to send a reset password email to your provided address.'
        });
      }
    },
    [email, hasError]
  );

  const metaDescription = `Enter your email address and we'll send you a reset link.`;

  return (
    <div className='forgot-password-wrapper'>
      <Helmet>{title('Albert | Reset Password')}</Helmet>
      <Card shadow='default' paddingLevel={4} className='forgot-password__content u-mar-t_5'>
        <Banner className='forgot-password__banner u-mar-b_2'>
          <Banner.Icon icon='info-circle' iconStyle='regular' />
          <p>
            If you signed up with a password provided by your teacher and can&apos;t access your
            email address, please ask your teacher to reset your password for you. Once they&apos;ve
            given you the new password, please log in.
          </p>
        </Banner>
        <Form onSubmit={sendResetLink}>
          <Form.Header subtitle={metaDescription}>Reset password</Form.Header>
          <TextField
            border
            name='email'
            label='Email'
            type='email'
            autoComplete='email'
            required
            maxLength={128}
            defaultValue={email}
            error={hasError}
            message={hasError ? 'Please enter a valid email address.' : null}
            onChange={(e) => setEmail(e.target.value)}
            onBlur={validate}
          />
          <Button type='submit' className='u-mar-t_2 forgot-password__submit'>
            Get reset link
          </Button>
        </Form>
        <Card
          backgroundColor='subtle'
          className='forgot-password__instructions u-mar-t_2'
          paddingLevel={3}
        >
          <b>
            If you can&apos;t find the reset password email in your inbox, please try one of the
            following:
          </b>
          <ol className='u-pad-l_2'>
            <li className='u-mar-b_1'>
              Check your spam folder and double-check your inbox for a message titled &quot;Reset
              your Albert.io password&quot;
            </li>
            <li className='u-mar-b_1'>
              Contact your school administrator or IT administrator and ensure they are not blocking
              emails coming from the albert.io domain.
            </li>
            <li className='u-mar-b_1'>Try resubmitting the form above.</li>
          </ol>
        </Card>
      </Card>
    </div>
  );
}
