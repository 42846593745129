import React, {useCallback} from 'react';
import {v4 as uuid} from 'uuid';
import {useQuestionEditorV2Store} from 'client/EditPage/V2/QuestionEditorV2Store';
import {PRIMARY_CONTENT_LANGUAGE} from 'client/EditPage/V2/QuestionEditorV2Store.types';
import {setUpStore} from 'client/framework';
import {fromJS, Map, List} from 'immutable';

import NumberInput from 'generic/Forms/NumberInput/NumberInput.react';
import {AuthoringQuestionModelV1} from 'resources/augmented/AuthoringQuestion/AuthoringQuestionModel.v1';
import simpleDropdownStore from 'generic/SimpleDropdown/SimpleDropdown.store';
import SimpleDropdown from 'generic/SimpleDropdown/SimpleDropdown.react';
import Button from 'sg/Button/Button.react';

import FreeEntryInput from './FreeEntryInput';
import './free-entry-editor.scss';

const freeEntryTypes = fromJS([
  {
    id: 'contains',
    name: 'Text'
  },
  {
    id: 'mathContains',
    name: 'Math'
  }
]);

interface Props {
  question: AuthoringQuestionModelV1;
  onBlur: () => void;
  onModelChange: (question: AuthoringQuestionModelV1, field?: string) => void;
  validationErrors: Map<string, any>;
}

const FreeEntryEditor = ({question, onBlur, onModelChange, validationErrors}: Props) => {
  const isPrimaryLanguage = useQuestionEditorV2Store(
    (state) => state.currentLanguage === PRIMARY_CONTENT_LANGUAGE
  );
  const getFreeEntryDropdownStore = () => {
    return setUpStore(simpleDropdownStore, 'FreeEntryDropdownStore');
  };

  const onChangeRubricType = useCallback(
    (selection) => {
      const updatedQuestion = question.setRubricType(selection.get('id'));
      onModelChange(updatedQuestion);
    },
    [question, onModelChange]
  );

  const onChangeThreshold = useCallback(
    (e) => {
      const updatedQuestion = question.setRubricThreshold(e.target.value);
      onModelChange(updatedQuestion);
    },
    [question, onModelChange]
  );

  const addInput = useCallback(() => {
    const newInput = Map({
      text: '',
      id: uuid()
    });
    const inputs = question.getInputs().push(newInput);
    const validResponse = question.getValidResponse().set(newInput.get('id'), List(['']));
    const updatedQuestion = question.setInputs(inputs).setValidResponse(validResponse);

    onModelChange(updatedQuestion, 'inputs');
  }, [question, onModelChange]);

  const rubric = question.getRubric();
  const errors = validationErrors;
  const inputsError =
    errors.get('inputs') && typeof errors.get('inputs') === 'string' ? (
      <div className='a-form-input__error'>{errors.get('inputs')}</div>
    ) : null;

  return (
    <div className='free-entry-editor'>
      <SimpleDropdown
        disabled={!isPrimaryLanguage}
        activeItemId={rubric.get('type')}
        label='Answer format'
        options={freeEntryTypes}
        onChange={onChangeRubricType}
        storeName={getFreeEntryDropdownStore().getName()}
      />

      {rubric.get('type') === 'mathContains' && (
        <NumberInput
          disabled={!isPrimaryLanguage}
          className='free-entry-editor__threshold'
          label='Threshold %'
          name='threshold'
          placeholder='1%'
          onChange={onChangeThreshold}
          value={rubric.get('threshold')}
        />
      )}

      <hr />
      {inputsError}

      {question.getInputs().map((input, key) => (
        <FreeEntryInput
          index={key}
          input={input}
          key={input.get('id')}
          question={question}
          onBlur={onBlur}
          onModelChange={onModelChange}
          validationErrors={validationErrors}
        />
      ))}

      {isPrimaryLanguage && <Button onClick={addInput} color='green' text='New input prompt' />}
    </div>
  );
};

export default FreeEntryEditor;
