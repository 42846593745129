import moment from 'moment';
import {mapValues} from 'lodash';
import sessionStore from 'client/Session/SessionStore';
import {resource} from '@albert-io/json-api-framework/request/builder';

export function getAuthorQuery() {
  return resource('author_v1')
    .mandarkEndpoint(['authors_v1', sessionStore.getUserId()])
    .include('authoring_subjects_v1')
    .filter({
      authoring_subjects_v1: {
        published: true
      }
    });
}

// Base Query that all branch off of
export function getQuestionSetQuery() {
  return resource('authoring_question_set_v1')
    .mandarkEndpoint(['authoring_question_sets_v1'])
    .include('authoring_subject_v1')
    .filter({
      author_v1: sessionStore.getUserId()
    });
}

export function getActivityQuery() {
  return getRecentQuestionSetQuery().customQuery({
    with_meta: 'authoring_feedback_v1,authoring_question_v1'
  });
}

export function getPublishedQuestionSetQuery() {
  return getQuestionSetQuery().filter({
    status: 'published'
  });
}

export function getMetricsQuery() {
  return getPublishedQuestionSetQuery()
    .customQuery({
      fields: {
        authoring_question_set_v1: 'difficulty,published_at,status,authoring_subject'
      }
    })
    .customQuery({
      with_meta: 'authoring_question_set_v1'
    })
    .meta({
      context: {
        authoring_question_set: {
          answer_difficulty_counts: true,
          answer_type_counts: true
        }
      }
    });
}

export function getQuestionSetWithStatusTrackingQuery() {
  return getRecentQuestionSetQuery()
    .customQuery({
      with_meta: 'authoring_feedback_v1,authoring_question_v1'
    })
    .meta({
      context: {
        status_tracking: true
      }
    });
  // .sort({
  //   included: {
  //     authoring_questions_v1: 'meta.question_or_status_updated_at'
  //   }
  // });
}

function getRecentQuestionSetQuery() {
  const threeMonthsAgo = moment()
    .subtract(3, 'months')
    .valueOf();

  return getQuestionSetQuery()
    .include('authoring_questions_v1.authoring_feedback_v1')
    .filter({
      any_of: [
        {
          authoring_questions_v1: {
            authoring_feedback_v1: {
              updated_at: {
                greater_than_inclusive: threeMonthsAgo
              }
            }
          }
        }
      ]
    })
    .filter({
      any_of: [
        {
          published_at: {
            greater_than_inclusive: threeMonthsAgo
          }
        }
      ]
    });
}

export async function getAuthorQuestionTypeStats(baseQuery) {
  const withTypeFilters = {
    all: baseQuery,
    multipleChoice: baseQuery.filter({
      included: {
        authoring_questions_v1: {
          question_type: 'multiple-choice'
        }
      }
    }),
    freeEntry: baseQuery.filter({
      included: {
        authoring_questions_v1: {
          question_type: 'free-entry'
        }
      }
    }),
    textHighlight: baseQuery.filter({
      included: {
        authoring_questions_v1: {
          question_type: 'text-highlight'
        }
      }
    }),
    fillInTheBlank: baseQuery.filter({
      included: {
        authoring_questions_v1: {
          question_type: 'fill-in-the-blank'
        }
      }
    }),
    twoWay: baseQuery.filter({
      included: {
        authoring_questions_v1: {
          question_type: 'two-way'
        }
      }
    }),
    snippetSelection: baseQuery.filter({
      included: {
        authoring_questions_v1: {
          question_type: 'snippet-selection'
        }
      }
    }),
    freeResponse: baseQuery.filter({
      included: {
        authoring_questions_v1: {
          question_type: 'free-response'
        }
      }
    }),
    passageCorrection: baseQuery.filter({
      included: {
        authoring_questions_v1: {
          question_type: 'passage-correction'
        }
      }
    })
  };

  const queryPromiseArray = Object.values(withTypeFilters).map((query) =>
    query.getResourcePromise()
  );

  await Promise.all(queryPromiseArray);

  return mapValues(withTypeFilters, (query) => {
    return query.getResourceMetadata().getIn(['request', 'count_of_included_questions'], 0);
  });
}

export async function getAuthorQuestionCountStats() {
  const baseQuery = resource('authoring_question_sets_v1')
    .mandarkEndpoint(['authoring_question_sets_v1'])
    .include('authoring_questions_v1')
    .filter({
      author_v1: sessionStore.getUserId(),
      status: 'published'
    })
    .pageSize(0);

  const withDateFilters = {
    allTime: baseQuery,
    thisYear: baseQuery.filter({
      published_at: {
        greater_than: moment()
          .startOf('year')
          .valueOf()
      }
    }),
    thisMonth: baseQuery.filter({
      published_at: {
        greater_than: moment()
          .startOf('month')
          .valueOf()
      }
    })
  };

  const queryPromiseArray = Object.values(withDateFilters).map((query) =>
    query.getResourcePromise()
  );

  await Promise.all(queryPromiseArray);

  return mapValues(withDateFilters, (query) => {
    return query.getResourceMetadata().getIn(['request', 'count_of_included_questions'], 0);
  });
}
