import {
  Range,
  Transforms,
  Editor,
  CustomTypes,
  Text,
  Path,
  Node,
  Element as SlateElement,
  BaseSelection
} from 'slate';
import isHotkey from 'is-hotkey';

import {ListItemElement} from '../slate.types';

import {
  handleIndent,
  toggleBlock,
  toggleMark,
  unwrapLink,
  isBlockActive,
  isFont
} from './editor.utils';

const shortCutHandler = (e: React.KeyboardEvent<HTMLInputElement>, editor: Editor) => {
  const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
    'mod+`': 'code'
  };
  for (const hotkey in HOTKEYS) {
    if (isHotkey(hotkey, e)) {
      e.preventDefault();
      const mark = HOTKEYS[hotkey];
      toggleMark(editor, mark);
    }
  }
};

const fontHandler = (e: React.KeyboardEvent<HTMLInputElement>, editor: Editor) => {
  const {selection} = editor;
  const {nativeEvent} = e;
  if (selection) {
    if (isHotkey('enter', nativeEvent)) {
      const selectedElement = Node.descendant(
        editor,
        selection.anchor.path.slice(0, -1)
      ) as SlateElement;

      if (isFont(selectedElement.type)) {
        e.preventDefault();
        const selectedLeaf = Node.descendant(editor, selection.anchor.path) as CustomTypes['Text'];
        // anchor is the start of selection

        if (selectedLeaf.text.length === selection.anchor.offset) {
          Transforms.insertNodes(editor, {
            type: 'paragraph',
            children: [{text: ''}]
          });
        } else {
          Transforms.splitNodes(editor, {always: true});
          Transforms.setNodes(editor, {type: 'paragraph'});
        }
      }
    }
  }
};

const linkHandler = (e: React.KeyboardEvent<HTMLInputElement>, editor: Editor) => {
  const {selection} = editor;
  const {nativeEvent} = e;

  // taken from https://github.com/ianstormtaylor/slate/commit/f1b7d18f43913474617df02f747afa0e78154d85#diff-98b609559db12cb7cc755fd41741b9c72affed934a10cd75db684d5455f3868cR65

  if (selection && Range.isCollapsed(selection)) {
    if (isHotkey('left', nativeEvent)) {
      e.preventDefault();
      Transforms.move(editor, {unit: 'offset', reverse: true});
      return;
    }
    if (isHotkey('right', nativeEvent)) {
      e.preventDefault();
      Transforms.move(editor, {unit: 'offset'});
    }
  }
  // additonal edge cases
  if (selection) {
    const [parentNode] = Editor.parent(editor, selection.focus?.path) as [
      CustomTypes['Element'],
      Path
    ];
    // Prevent links from being multiline
    if (parentNode.type === 'link') {
      if (isHotkey('space', nativeEvent)) {
        const selectedLeaf = Node.descendant(editor, selection.anchor.path) as CustomTypes['Text'];

        if (selectedLeaf.text.length === selection.anchor.offset) {
          Transforms.move(editor, {unit: 'offset'});
          Transforms.insertText(editor, ' ');
          e.preventDefault();
        }
      }
    }
  }
};

const tabHandler = (e: React.KeyboardEvent<HTMLInputElement>, editor: Editor) => {
  const {selection} = editor;
  const {nativeEvent} = e;
  if (selection && isStartOfLine(selection) && isHotkey('tab', nativeEvent)) {
    const node = editor.children[selection.anchor.path[0]];
    if (
      SlateElement.isElement(node) &&
      (node.type === 'paragraph' || node.type === 'bulleted-list' || node.type === 'numbered-list')
    ) {
      e.preventDefault();
      handleIndent(editor, 'indent');
    }
  }
};

const onEnterHandler = (e: React.KeyboardEvent<HTMLInputElement>, editor: Editor) => {
  const {selection} = editor;
  const {nativeEvent} = e;

  if (selection && isHotkey('enter', nativeEvent)) {
    if (isStartOfLine(selection)) {
      const [listAndPath] = Array.from(
        Editor.nodes(editor, {
          at: Editor.unhangRange(editor, selection),
          match: (n: Node) => {
            return !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'list-item';
          }
        })
      ) as [ListItemElement, Path][];

      if (!listAndPath) return;
      const [list] = listAndPath;

      const notEmpty = list.children.some((child) => {
        const isEmptyText = Text.isText(child) && child.text.length === 0;
        const isLink = SlateElement.isElement(child) && child.type === 'link';
        if (isLink) return true;
        if (!isEmptyText) return true;
        return false;
      });

      if (!notEmpty) {
        e.preventDefault();
        toggleBlock(editor, 'list-item');
      }
    } else {
      const [parentNode] = Editor.parent(editor, selection.focus?.path) as [
        CustomTypes['Element'],
        Path
      ];
      if (parentNode.type === 'link') {
        const inList = isBlockActive(editor, 'list-item');

        const selectedLeaf = Node.descendant(editor, selection.anchor.path) as CustomTypes['Text'];

        if (selectedLeaf.text.length === selection.anchor.offset) {
          Transforms.splitNodes(editor, {always: true});
          unwrapLink(editor);
          Transforms.setNodes(editor, {type: inList ? 'list-item' : 'paragraph'});

          e.preventDefault();
        } else {
          Transforms.splitNodes(editor, {always: true});
          unwrapLink(editor);
          Transforms.setNodes(editor, {type: inList ? 'list-item' : 'paragraph'});
          e.preventDefault();
        }
      }
    }
  }
};

const imageHandler = (e: React.KeyboardEvent<HTMLInputElement>, editor: Editor) => {
  const {selection} = editor;
  const {nativeEvent} = e;
  if (selection && isHotkey('enter', nativeEvent)) {
    const [parentNode] = Editor.parent(editor, selection.focus?.path) as [
      CustomTypes['Element'],
      Path
    ];
    if (parentNode.type === 'image') {
      e.preventDefault();
      Editor.insertNode(editor, {
        type: 'paragraph',
        children: [{text: ''}]
      });
    }
  }
};

const audioChipHandler = (e: React.KeyboardEvent<HTMLInputElement>, editor: Editor) => {
  const {selection} = editor;
  const {nativeEvent} = e;

  if (selection && Range.isCollapsed(selection)) {
    const [node, path] = Editor.node(editor, selection);

    // Check if the current node is an audio-chip
    if (SlateElement.isElement(node) && node.type === 'audio-chip') {
      // Prevent any editing inside the chip
      e.preventDefault();

      // Handle arrow keys to navigate out of the chip
      if (isHotkey('left', nativeEvent) || isHotkey('up', nativeEvent)) {
        Transforms.select(editor, Editor.start(editor, path));
        Transforms.move(editor, {unit: 'offset', reverse: true});
        return;
      }
      if (isHotkey('right', nativeEvent) || isHotkey('down', nativeEvent)) {
        Transforms.select(editor, Editor.end(editor, path));
        Transforms.move(editor, {unit: 'offset'});
        return;
      }

      // Handle enter key to insert a new paragraph after the chip
      if (isHotkey('enter', nativeEvent)) {
        Transforms.insertNodes(
          editor,
          {type: 'paragraph', children: [{text: ''}]},
          {at: Path.next(path)}
        );
        Transforms.select(editor, Path.next(path));
      }
    }
  }
};

const keyboardHandler = (event: React.KeyboardEvent<HTMLInputElement>, editor: Editor) => {
  audioChipHandler(event, editor);
  imageHandler(event, editor);
  shortCutHandler(event, editor);
  linkHandler(event, editor);
  fontHandler(event, editor);
  tabHandler(event, editor);
  onEnterHandler(event, editor);
};

export const isStartOfLine = (selection: BaseSelection) => {
  return (
    selection &&
    selection.focus.offset === 0 &&
    selection.anchor.offset === 0 &&
    Range.isCollapsed(selection)
  );
};

export default keyboardHandler;
