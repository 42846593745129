import React from 'react';
import PropTypes from 'prop-types';
import {fromJS, List} from 'immutable';
import {SecondaryHeader} from 'generic/HorizontalNavigationComponent/HorizontalNavigationComponent.react';
import sessionStore from 'client/Session/SessionStore';
import appStore from 'client/AppStore';
import masqueradeStore from 'generic/Masquerade/Masquerade.store';

import {archivedClassroomsPath} from '../../ClassroomsList/TeacherClassroomsList/ArchivedClassroomsList.react';

import classroomV2Store from '../../Classroom/ClassroomV2.store';

import LeftContent from './LeftContent.react';
import RightContent from './RightContent.react';

export default class ClassesSecondaryHeader extends React.Component {
  static propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string
    }),
    params: PropTypes.shape({
      classId: PropTypes.string
    })
  };

  /**
   * @returns {Immutable.List}
   */
  getLinks() {
    if (!this.props.params.classId) {
      return new List();
    }

    const hasTeacherAccess = sessionStore.hasTeacherAccess();
    let links = getSecondaryHeaderLinks(this.props.params.classId);

    if (!hasTeacherAccess) {
      return links.get('student');
    }
    if (masqueradeStore.isMasquerading()) {
      return links.get('teacher');
    }

    links = links.get('teacher');

    return links;
  }

  generateLeftContent() {
    /**
     * If we're looking at the archived classrooms list, or an individual archived classroom, we'll set the selected
     * item in of the SimpleDropdown to be the archived classroom item
     */
    const isViewingArchivedClassroomsList =
      appStore.routerProps.location.pathname === archivedClassroomsPath;
    const isViewingArchivedClassroom =
      classroomV2Store.getActiveClassroomId() && classroomV2Store.getActiveClassroom().isArchived();
    const activeItemId =
      isViewingArchivedClassroomsList || isViewingArchivedClassroom
        ? archivedClassroomsPath
        : this.props.params.classId;

    return <LeftContent activeItemId={activeItemId} />;
  }

  generateRightContent() {
    return sessionStore.hasTeacherAccess() ? null : <RightContent />;
  }

  render() {
    /**
     * @todo - Accounts for secondary header being removed only for students and only for the base route of /classes
     * This can be removed with either of these conditions:
     * 1) .common-content--with-seconday-header starts getting utilized (see assets/stylesheets/modules/_content.scss)
     * 2) product/design solidifies requirements for new designs across this dashboard that will utilize this header or replace it
     */
    const hasTeacherAccess = sessionStore.hasTeacherAccess();
    if (
      (this.props.location.pathname === '/classes' &&
        (!hasTeacherAccess ||
          (hasTeacherAccess && !sessionStore.isTeacher()) ||
          !this.props.location.pathname.includes(this.props.params.classId))) ||
      this.props.location.pathname === '/classes/archived'
    ) {
      return null;
    }

    return (
      <SecondaryHeader
        identity='ClassroomsSecondaryHeader'
        links={this.getLinks()}
        leftContent={this.generateLeftContent()}
        rightContent={this.generateRightContent()}
        aria-label='secondary'
      />
    );
  }
}

function getSecondaryHeaderLinks(id) {
  function to(path) {
    return `/classes/${id}/${path}`;
  }

  const teacherLinks = fromJS([
    {
      name: 'People',
      to: to('people')
    },
    {
      name: 'Class Subjects',
      to: to('subjects')
    },
    {
      name: 'Assignments',
      to: to('assignments')
    },
    {
      name: 'Overview',
      to: to('students/overview')
    },
    {
      name: 'Insights',
      to: to('insights')
    },
    {
      name: 'Settings',
      to: to('settings')
    }
  ]);

  const studentLinks = fromJS([
    {
      name: 'Assignments',
      to: to('my-assignments')
    }
  ]);

  return fromJS({
    teacher: teacherLinks,
    student: studentLinks
  });
}
