import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import constants from 'client/constants';
import errorCodes from 'client/errorCodes';

import {
  GooglePopupBlockerWarning,
  GoogleContext,
  GoogleCookiesWarning,
  GoogleSignInButton,
  GoogleSwitchAccountsButton
} from 'client/components/Google';

import HardCapWarningBanner from 'client/components/HardCapWarningBanner/HardCapWarningBanner.react';
import HardCapErrorModal from 'client/components/HardCapErrorModal/HardCapErrorModal.react';

import {
  Anchor,
  Banner,
  Button,
  Dialogue,
  Heading,
  InputGroup,
  LoadingSpinner,
  Text
} from '@albert-io/atomic';

import {getModelForResourceType} from 'resources/modelRegistry';
import ArrayToReadableFragment from 'lib/hocs/arrayToReadableFragment';

/**
 * Google Classroom Importer
 * =========================
 *
 * Below these several configurations of Dialogue.react.js is GoogleClassroomImporter,
 * which links these various dialogue components with the Google API via context. These
 * stateless functional components represent the several different states in which a user
 * can find themselves in the Import Google Classroom flow, depending on whether they
 * are signed in, have selected a class or not, or whether a request to Google is in flight.
 */

const SignInPrompt = ({
  emailDomains,
  handleClose,
  restrictClassroomEnrollment,
  classroom,
  title,
  ...dialogueProps
}) => (
  <Dialogue handleClose={handleClose} {...dialogueProps} paddingLevel={0}>
    <Dialogue.Title size='xs' className='u-pad-lr_2 u-pad-t_2'>
      {title}
    </Dialogue.Title>
    {classroom.getSchool().isHardCap() && (
      <HardCapWarningBanner
        seatsRemaining={classroom.getMeta().getCountOfSchoolLicenseSeatsRemaining()}
      />
    )}
    <Dialogue.Body className='u-pad_3'>
      <Text as='p' className='u-mar-b_2 u-max-width_text'>
        When you import your Google classroom, your students will receive an email invitation to
        join your class. They will need to login or create an account with the associated email in
        order to join your classroom. Share this{' '}
        <Anchor
          as='a'
          rel='noopener noreferrer'
          target='_blank'
          href='https://help.albert.io/en/articles/2958073-how-do-i-sign-up-for-albert-using-google'
        >
          help article
        </Anchor>{' '}
        with your students to support account creation.
      </Text>
      <Text as='p' className='u-mar-b_2 u-max-width_text'>
        <strong>Note:</strong> if you have already enrolled students in this classroom, then syncing
        with Google Classroom will remove <strong>any previously enrolled students</strong> who are
        not included in your Google Classroom roster. Learn more about importing your Google
        Classroom{' '}
        <Anchor
          as='a'
          rel='noopener noreferrer'
          target='_blank'
          href='https://help.albert.io/en/articles/1885029-how-do-i-sync-my-albert-classroom-with-a-google-classroom'
        >
          here
        </Anchor>
        .
      </Text>
      {restrictClassroomEnrollment && !emailDomains.isEmpty() && (
        <Banner>
          <span>
            Invitations are restricted to accounts ending in{' '}
            <ArrayToReadableFragment
              arr={[...emailDomains.map((domain) => domain.getName())]}
              as='strong'
            />
          </span>
        </Banner>
      )}
    </Dialogue.Body>
    <Dialogue.BtnGroup className='u-pad-r_3 u-pad-b_3'>
      <Button color='secondary' onClick={handleClose}>
        Cancel
      </Button>
      <GoogleSignInButton />
    </Dialogue.BtnGroup>
  </Dialogue>
);

SignInPrompt.propTypes = {
  emailDomains: ImmutablePropTypes.listOf(getModelForResourceType('email_domain_v1')),
  handleClose: PropTypes.func,
  restrictClassroomEnrollment: PropTypes.bool,
  classroom: PropTypes.instanceOf(getModelForResourceType('classroom_v1')).isRequired,
  title: PropTypes.string
};

const RequestPendingIndicator = (dialogueProps) => (
  <Dialogue {...dialogueProps}>
    <Dialogue.Body>
      <LoadingSpinner className='u-mar-b_2' size='2' />
    </Dialogue.Body>
  </Dialogue>
);

const ConfirmSelectionPrompt = ({
  emailDomains,
  onCancel,
  onConfirm,
  restrictClassroomEnrollment,
  ...dialogueProps
}) => (
  <Dialogue {...dialogueProps}>
    <Dialogue.Body>
      <Heading as='h2' className='u-mar-b_2' size='s'>
        Confirm Email Invitations
      </Heading>
      <Text as='p' className='u-mar-b_2'>
        Importing will remove <strong>any current students</strong> who are missing from your roster
        in Google Classroom.
      </Text>
      {restrictClassroomEnrollment && !emailDomains.isEmpty() && (
        <Banner>
          <span>
            Invitations are restricted to accounts ending in{' '}
            <ArrayToReadableFragment
              arr={[...emailDomains.map((domain) => domain.getName())]}
              as='strong'
            />
            .
          </span>
        </Banner>
      )}
    </Dialogue.Body>
    <Dialogue.BtnGroup className='u-mar-b_2'>
      <Button color='secondary' onClick={onCancel}>
        Cancel
      </Button>
      <Button onClick={onConfirm}>Import classroom</Button>
    </Dialogue.BtnGroup>
  </Dialogue>
);

ConfirmSelectionPrompt.propTypes = {
  emailDomains: ImmutablePropTypes.listOf(getModelForResourceType('email_domain_v1')),
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  restrictClassroomEnrollment: PropTypes.bool
};

const ErrorOnImportPrompt = ({emailDomains, onCancel, ...rest}) => (
  <Dialogue {...rest} title='Error'>
    <Dialogue.Body>
      <Banner className='u-mar-b_2' type='negative'>
        <Banner.Icon icon='exclamation-triangle' />
        <span>
          Invitations are restricted to accounts ending in{' '}
          <ArrayToReadableFragment
            arr={[...emailDomains.map((domain) => domain.getName())]}
            as='strong'
          />
          .
        </span>
      </Banner>
      <Text as='p'>
        One or more students in your classroom have accounts with an email domain that is restricted
        by your school. Please use the enrollment code for enrolling your students.
      </Text>
      <Text as='p'>
        If using Google Classroom is integral to your classroom, please contact&nbsp;
        <Anchor href={`mailto:${constants.CONTACT.EMAIL.SUPPORT}`}>
          {constants.CONTACT.EMAIL.SUPPORT}
        </Anchor>
        &nbsp;for more information.
      </Text>
    </Dialogue.Body>
    <Dialogue.BtnGroup>
      <Button color='secondary' onClick={onCancel}>
        Back
      </Button>
    </Dialogue.BtnGroup>
  </Dialogue>
);

ErrorOnImportPrompt.propTypes = {
  emailDomains: ImmutablePropTypes.listOf(getModelForResourceType('email_domain_v1')),
  onCancel: PropTypes.func
};

export default class GoogleClassroomImporter extends React.Component {
  static propTypes = {
    /** An Albert classroom instance */
    classroom: PropTypes.instanceOf(getModelForResourceType('classroom_v1')).isRequired,
    /**
     * A callback that fires upon clicking the cancel button (e.g.
     * if the importer is in a modal, use this to close the modal)
     */
    emailDomains: ImmutablePropTypes.listOf(getModelForResourceType('email_domain_v1')),
    handleClose: PropTypes.func,
    onCancel: PropTypes.func,
    /**
     * A callback that fires upon successful import,
     * (e.g. use this to invalidate a query)
     */
    onImport: PropTypes.func,
    restrictClassroomEnrollment: PropTypes.bool,
    title: PropTypes.string
  };

  static defaultProps = {
    onCancel: () => {},
    onImport: () => {},
    title: 'Import Google Classroom'
  };

  constructor() {
    super();

    this.state = {
      mustConfirmSelection: false,
      selectedClassroom: null
    };
  }

  handleChange = (selectedClassroom) => {
    this.setState({selectedClassroom});
  };

  handleImportClassroom = () => {
    this.context.handleImportClassroom({
      callback: () => this.props.onImport(),
      classroomId: this.props.classroom.getId(),
      googleClassroomId: this.state.selectedClassroom.getId()
    });
  };

  static contextType = GoogleContext;

  render() {
    const {classrooms, error, isRequestPending, isSignedIn} = this.context;
    if (isRequestPending) {
      return <RequestPendingIndicator {...this.props} />;
    }

    if (error === errorCodes.GOOGLE.POPUP_BLOCKED) {
      return (
        <GooglePopupBlockerWarning
          {...this.props}
          handleClose={() => {
            this.context.clearError();
            this.props.handleClose();
          }}
          onCancel={this.context.clearError}
        />
      );
    }

    if (error === errorCodes.GOOGLE.COOKIES_DISABLED) {
      return (
        <GoogleCookiesWarning
          {...this.props}
          handleClose={() => {
            this.context.clearError();
            this.props.handleClose();
          }}
          onCancel={this.context.clearError}
        />
      );
    }

    if (!isSignedIn) {
      return <SignInPrompt {...this.props} />;
    }

    if (error === errorCodes.BAD_REQUEST.VALIDATION_ERROR.INVALID_EMAIL_DOMAIN) {
      return (
        <ErrorOnImportPrompt
          {...this.props}
          onCancel={() => {
            this.context.clearError();
            this.setState({mustConfirmSelection: false});
          }}
        />
      );
    }

    if (error === errorCodes.FORBIDDEN.EXCEEDED_HARD_CAP_LIMIT) {
      return (
        <HardCapErrorModal
          onDismiss={() => {
            this.context.clearError();
            this.setState({mustConfirmSelection: false});
          }}
        />
      );
    }

    return this.state.mustConfirmSelection ? (
      <ConfirmSelectionPrompt
        {...this.props}
        onCancel={() => this.setState({mustConfirmSelection: false})}
        onConfirm={this.handleImportClassroom}
      />
    ) : (
      <Dialogue {...this.props}>
        <Dialogue.Body>
          {!classrooms.isEmpty() ? (
            <InputGroup className='u-mar-b_2' direction='column'>
              {classrooms.map((classroom, i) => (
                <label className='u-display-flex u-align-items_center' key={i}>
                  <input
                    checked={classroom.getId() === this.state.selectedClassroom?.getId()}
                    className='u-mar-r_1'
                    name='google-classroom-importer'
                    onChange={() => this.handleChange(classroom)}
                    type='radio'
                    value={classroom.getId()}
                  />
                  {classroom.getName()}
                </label>
              ))}
            </InputGroup>
          ) : (
            <Text as='div' className='u-mar-b_2'>
              This account is not associated with any Google Classrooms.
            </Text>
          )}
        </Dialogue.Body>
        <div className='u-align-items_center u-mar-t_5 u-mar-b_2 u-display_flex'>
          <GoogleSwitchAccountsButton className='u-mar-r_auto' />
          <Button className='u-mar-l_auto' color='secondary' onClick={this.props.onCancel}>
            Cancel
          </Button>
          <Button
            className='u-mar-l_1'
            disabled={isRequestPending || classrooms.isEmpty() || !this.state.selectedClassroom}
            onClick={() => this.setState({mustConfirmSelection: true})}
          >
            Import class
          </Button>
        </div>
      </Dialogue>
    );
  }
}
