import React, {lazy} from 'react';
import {Route, Redirect, IndexRedirect, IndexRoute} from 'react-router';

import subjectRoutes from 'client/Dennis/V2/Subjects/routes';
import BatchRoutes from 'client/Dennis/BatchUtils/routes';

const GuideLevelPracticeView = lazy(() =>
  import('./GuideLevelPracticeView/GuideLevelPracticeView.react')
);

const Dennis = lazy(() => import(/* webpackChunkName: "Dennis" */ './Dennis.react'));
/**
 * "Content" Components
 */
const Content = lazy(() => import('./Content/Content.react'));
const Queue = lazy(() => import('./Content/Queue/Queue.react'));
const SupplementManager = lazy(() =>
  import('client/Supplements/SupplementManager/SupplementManager')
);
const Standards = lazy(() => import('./StandardsV2/Standards.react'));
/**
 * "Users" Components
 */
const Users2 = lazy(() => import('./Users2/Users.react'));
const UserSearchWrapper = lazy(() => import('./UserEdit2/UserEditSearchWrapper.react'));
const UserForm = lazy(() => import('./UserEdit2/UserForm/UserForm.react'));
const UserWrapper = lazy(() => import('./UserEdit2/Wrapper/UserFormWrapper.react'));
const UserSubscriptions = lazy(() => import('./UserEdit2/Subscriptions/UserSubscriptions.react'));
const UserAuthoringPermissions = lazy(() =>
  import('./UserEdit2/Authoring/UserAuthoringPermissions.react')
);
/**
 * "Schools" Components
 */
const SchoolsLanding = lazy(() => import('./Schools/SchoolsLanding/SchoolsLanding.react'));
const SchoolsIndex = lazy(() => import('./Schools/SchoolsIndex/SchoolsIndex.react'));
const SchoolEdit = lazy(() => import('./Schools/SchoolsIndex/Edit/SchoolEdit.react'));
const SchoolCreate = lazy(() => import('./Schools/SchoolsIndex/Create/SchoolCreate.react'));
const SubjectGroupsIndex = lazy(() => import('./Schools/SubjectGroups/SubjectGroupsIndex.react'));
const SubjectGroupEdit = lazy(() => import('./Schools/SubjectGroups/Edit/SubjectGroupEdit.react'));
const SubjectGroupCreate = lazy(() =>
  import('./Schools/SubjectGroups/Create/SubjectGroupCreate.react')
);

const DistrictsList = lazy(() => import('./Districts/DistrictsList.react'));

const DennisRoutes = (
  <Route path='dennis' name='dennis' component={Dennis}>
    {BatchRoutes}
    <Route path='content' component={Content}>
      <Redirect from='question-set-search' to='queue' />
      <Redirect from='frq-queue' to='queue' />
      <IndexRedirect to='queue' />
      <Route
        path='queue(/:questionSetId)(/:questionId)'
        component={Queue}
        title='Queue | Albert.io'
      />
      {subjectRoutes}
      <Route path='supplements' component={SupplementManager} title='Supplements | Albert.io' />
      <Route path='standards' component={Standards} title='Standards | Dennis | Albert.io'>
        {/* @todo: Make this work */}
        <Route path=':subjectId' component={Standards} />
      </Route>
    </Route>
    <Route path='users' component={Users2}>
      <IndexRedirect to='search' />
      <Route path='search' component={UserSearchWrapper} />
      <Route path='edit/:userId' component={UserWrapper} title='User Edit | Dennis | Albert.io'>
        <IndexRoute component={UserForm} />
        <Route
          path='subscriptions'
          title='User Subscriptions Edit | Dennis | Albert.io'
          component={UserSubscriptions}
        />
        <Route
          path='authoring-permissions'
          title='User Authoring Permissions Edit | Dennis | Albert.io'
          component={UserAuthoringPermissions}
        />
      </Route>
      <Route path='create' component={UserWrapper} title='User Create | Dennis | Albert.io'>
        <IndexRoute component={UserForm} />
      </Route>
    </Route>
    <Route path='districts' component={DistrictsList} />
    <Route path='schools' component={SchoolsLanding}>
      <IndexRedirect to='school-list' />
      <Route path='school-list' component={SchoolsIndex} />
      <Route path='school-list/create' component={SchoolCreate} />
      <Route path='school-list/edit/:schoolId' component={SchoolEdit} />
      <Route path='subject-groups' component={SubjectGroupsIndex} />
      <Route path='subject-groups/create' component={SubjectGroupCreate} />
      <Route path='subject-groups/edit/:subjectGroupId' component={SubjectGroupEdit} />
    </Route>
    <Route path=':subjectSlug/:guideLevelSlug' component={GuideLevelPracticeView} />
  </Route>
);

export {DennisRoutes};
