import React, {useRef, useState, useCallback, useEffect} from 'react';

import {BlockButton} from './ToolbarComponents/BlockButton.react';
import {MarkButton} from './ToolbarComponents/MarkButton.react';
import {FontDropdown} from './ToolbarComponents/FontDropdown.react';
import {InOutdentButton} from './ToolbarComponents/InOutdentButton.react';
import {LinkButton} from './ToolbarComponents/LinkButton.react';
import {AddMediaButton} from './ToolbarComponents/Media/AddMediaButton.react';

const TOTAL_INTERACTIVE_ELEMENTS = 13;

const Toolbar = () => {
  const toolbarRef = useRef<HTMLDivElement>(null);
  const [activeCursor, setActiveCursor] = useState<number>(0);

  const handleKeyPress = useCallback((event: KeyboardEvent) => {
    if (toolbarRef.current && toolbarRef.current.contains(event.target as Node)) {
      if (event.key === 'ArrowRight') {
        setActiveCursor((oldCursor) => (oldCursor + 1) % TOTAL_INTERACTIVE_ELEMENTS);
      } else if (event.key === 'ArrowLeft') {
        setActiveCursor(
          (oldCursor) => (oldCursor - 1 + TOTAL_INTERACTIVE_ELEMENTS) % TOTAL_INTERACTIVE_ELEMENTS
        );
      }
    }
  }, []);

  const handleFocus = useCallback((event: KeyboardEvent) => {
    if (toolbarRef.current && toolbarRef.current.contains(event.target as Node)) {
      const interactiveElements = Array.from(toolbarRef.current.querySelectorAll('button'));
      const index = interactiveElements.findIndex((el) => el === event.target);
      if (index !== -1) {
        setActiveCursor(index);
      }
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    toolbarRef.current?.addEventListener('keyup', handleFocus);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
      toolbarRef.current?.removeEventListener('keyup', handleFocus);
    };
  }, [handleKeyPress, handleFocus]);

  useEffect(() => {
    if (toolbarRef.current) {
      const interactiveElements = toolbarRef.current.querySelectorAll('button');
      if (activeCursor >= 0 && activeCursor < interactiveElements.length) {
        const selected = interactiveElements[activeCursor] as HTMLElement;
        if (selected) {
          selected.focus();
        }
      }
    }
  }, [activeCursor]);

  const getTabIndex = (index: number) => (index === activeCursor ? 0 : -1);

  return (
    <div
      ref={toolbarRef}
      role='toolbar'
      className='written-submission__toolbar'
      aria-label='Text Formatting Toolbar'
      aria-orientation='horizontal'
    >
      <div className='written-submission__toolbar-group' role='group' aria-label='Text Style'>
        <FontDropdown tabIndex={getTabIndex(0)} />
        <MarkButton icon='bold' type='bold' tabIndex={getTabIndex(1)} />
        <MarkButton icon='italic' type='italic' tabIndex={getTabIndex(2)} />
        <MarkButton icon='underline' type='underline' tabIndex={getTabIndex(3)} />
      </div>
      <div
        className='written-submission__toolbar-group'
        role='group'
        aria-label='List and Indentation'
      >
        <Divider />
        <BlockButton icon='list-ol' type='numbered-list' tabIndex={getTabIndex(4)} />
        <BlockButton icon='list-ul' type='bulleted-list' tabIndex={getTabIndex(5)} />
        <InOutdentButton icon='indent' type='indent' tabIndex={getTabIndex(6)} />
        <InOutdentButton icon='outdent' type='outdent' tabIndex={getTabIndex(7)} />
      </div>
      <div className='written-submission__toolbar-group' role='group' aria-label='Text Formatting'>
        <Divider />
        <MarkButton icon='superscript' type='superscript' tabIndex={getTabIndex(8)} />
        <MarkButton icon='subscript' type='subscript' tabIndex={getTabIndex(9)} />
        <MarkButton icon='code' type='code' tabIndex={getTabIndex(10)} />
      </div>
      <div className='written-submission__toolbar-group' role='group' aria-label='Insert'>
        <Divider />
        <LinkButton tabIndex={getTabIndex(11)} />
        <AddMediaButton tabIndex={getTabIndex(12)} />
      </div>
    </div>
  );
};

const Divider = () => {
  return (
    <div
      className='written-submission__toolbar--divider'
      role='separator'
      aria-orientation='vertical'
    >
      <div className='written-submission__toolbar--divider-inside' />
    </div>
  );
};

export default Toolbar;
