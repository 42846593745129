import React, {useEffect} from 'react';
import {withRouter} from 'react-router';
import PropTypes from 'prop-types';

import {Modal} from '@albert-io/atomic';

import LogInForm from './LogInForm.react';

const LogInModal = ({handleClose, router, location}) => {
  /**
   * Make sure <Modal> clean-up occurs on unmount.
   */
  useEffect(() => {
    return () => {
      handleClose();
    };
  }, [handleClose]);

  function onAuthentication() {
    handleClose();
    /**
     * When authentication occurs, in the case of the modal we make sure the
     * route matching (`getComponents`) is triggered again to allow for handling
     * of the updated session state.
     *
     * @see src/client/Classrooms/routes.js:146
     */
    router.replace(location);
  }

  return (
    <Modal ariaLabel='Log in to access Albert' handleClose={handleClose}>
      {({CloseButtonWrapper, modalContentStyle}) => {
        return (
          <LogInForm
            className={modalContentStyle}
            onAuthentication={onAuthentication}
            withCloseButtonWrapper={CloseButtonWrapper}
          />
        );
      }}
    </Modal>
  );
};

LogInModal.propTypes = {
  handleClose: PropTypes.func,
  router: PropTypes.object,
  location: PropTypes.object
};

export default withRouter(LogInModal);
