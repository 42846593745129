// @flow
import {Map, List} from 'immutable';
import appStore from 'client/AppStore';
import type {QueryBuilder} from '@albert-io/json-api-framework/request/builder/legacy';
import {mandarkEndpoint} from '@albert-io/json-api-framework/request/builder/legacy';
import type {SubjectModel} from 'resources/Subject/Subject.model';
import {isSearchMode} from 'client/components/AdvancedSearch/AdvancedSearch.utils';

function getSubjectCrumb(subject: SubjectModel): List<Map<string, *>> {
  return new List([
    new Map({
      displayName: subject.getName(),
      helperCopy: 'View guide for',
      urlSlug: subject.getUrlSlug(),
      type: 'subject',
      isFree: false
    })
  ]);
}

function getSubjectsEndpoint(): QueryBuilder {
  return mandarkEndpoint(['subjects_v1'])
    .findOne()
    .filter({url_slug: appStore.routerProps.params.subject})
    .include('themes_v1.topics_v1.subtopics_v1,topics_v1,subtopics_v1,guide_levels_v2');
}

// eslint-disable-next-line import/prefer-default-export
export function getBreadcrumbs(): List<Map<string, *>> {
  if (!getSubjectsEndpoint().isResourceReady()) {
    return new List();
  }

  const subject = getSubjectsEndpoint().getResource();
  if (isSearchMode()) {
    const searchString = appStore.routerProps.location.search;
    return List([
      Map({
        displayName: 'Search Results',
        helperCopy: 'Go back to',
        isFree: false,
        type: 'search',
        urlSlug: subject.getUrlSlug() + searchString
      })
    ]);
  }

  const subjectCrumb = getSubjectCrumb(subject);
  const {guideLevelSlug} = appStore.routerProps.params;
  if (!guideLevelSlug) {
    return subjectCrumb;
  }
  const guideLevels = subject.getGuideLevelFullPathObject(guideLevelSlug);
  if (!guideLevels) {
    return new List();
  }
  return ['theme', 'topic', 'subtopic'].reduce((acc, level) => {
    const currentGuideLevel = guideLevels.get(level, null);
    if (!currentGuideLevel) {
      return acc;
    }
    return acc.push(
      new Map({
        displayName: currentGuideLevel.getName(),
        helperCopy: 'View questions for',
        urlSlug: currentGuideLevel.getUrlSlug(),
        guideLevelType: level,
        isFree: currentGuideLevel.isFree()
      })
    );
  }, subjectCrumb);
}
