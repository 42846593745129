import classnames from 'classnames';
import {Map, Range} from 'immutable';
import React from 'react';
import PropTypes from 'prop-types';
import {callAction, callTargetedAction, setUpStore} from 'client/framework';
import PaginationStore from './Pagination.store';
import paginationActions from './Pagination.actions';

import './pagination.scss';

/*
  This pagination component is very special.

  It is also very dumb. It exposes
    - activePage
    - listWindowStart
    - listWindowEnd
    - windowSize

  `activePage` is the value of the currently selected page, and the other `window`
  related properties are for purposes of displaying the page buttons, explained durther below.

  If you are using this component, and reading values from its store, you should only care EVER
  about 'activePage'


  Usage:

    <Pagination
      storeName='paginationStore'
      totalPages={32}
    />

    ... and later:

    getStoreByName('paginationStore').activePage -> whatever page is selected.

    The active page value will be the same as what is highlighted as active in the UI.

  This will show a pagination component with 32 different page numbers 10 at time.
  The 10 is not yet customizable.


  How does this work?
    Well, essentially all of our buttons are generated up front and we show a certain number
    of them at a time in what we'll call a 'window'. The active window will 'shift' when the user
    pages through their available pages.

    ...  8  9  10  |  11 12 13 14 15 16 17 18 19 20  | 21 22 23 ...
          |         \                               /     |
          |           \ _ _ _ _ _ _ _ _ _ _ _ _ _ /       |
          |                                               |
          |             Active Pagination Window           \
          |                                                 \
          |                                                   Pages Available in the 'next' window
      Pages in the 'previous' window

    We just adjust the window's start and end values and perform a 'slice' into the
    page number button list, and render those elements.

    The onClicks are all generated when that list is created, so there's not much to do there.
    The click handlers on the next/previous page buttons don't change at all as the window moves,
    they just add or subtract the window length. Cool.

    The next/previous page buttons only render if stuff is to the left or right of the
    window start/end values respectively.


  What happens when I click a page button?
    Two things:
      1. SET_ACTIVE_PAGE pops off, and updates the active page in our pagination store
      2. PAGE_CHANGED event fires w/ the name of the paginaitonStore as a value

*/

export default class PaginationV2 extends React.Component {
  static propTypes = {
    storeName: PropTypes.string.isRequired,
    totalPages: PropTypes.number.isRequired
  };

  constructor(props) {
    super(props);

    this.windowSize = this.getStore().windowSize;
    /*
       Page numbers is a list from 1 ... this.props.totalPages
       that will be mapped over to build our buttons

       Because immutable's Range is dumb (re: exclusive), I have to + 1
    */
    this.pageNumbers = Range(1, this.props.totalPages + 1);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (this.props.totalPages !== nextProps.totalPages) {
      this.pageNumbers = Range(1, nextProps.totalPages + 1);
      callTargetedAction({
        name: paginationActions.SET_PAGE_LIST_WINDOW_INDICES,
        targetStore: this.props.storeName,
        payload: this._makeListWindowPayload(0, this.windowSize)
      });
      callAction(paginationActions.PAGE_CHANGED, this.props.storeName);
    }
  }

  getStore() {
    return setUpStore(PaginationStore, this.props.storeName);
  }

  _makeListWindowPayload(start, end) {
    return new Map({
      listWindowStart: start,
      listWindowEnd: end
    });
  }

  _makeWindowShiftHandler(start, end, scaleValue) {
    const newWindowStart = scaleValue(start);
    const newWindowEnd = scaleValue(end);

    return () => {
      /*
        Here, the SET_PAGE_LIST_WINDOW_INDICES action is ALSO adjusting the
        activePage value behind the scenes. It is being set to the 'first'
        item in the newly selected window.

        We also fire off our more global-use PAGE_CHANGED here, because the
        activePage is also modified.
      */
      callTargetedAction({
        name: paginationActions.SET_PAGE_LIST_WINDOW_INDICES,
        targetStore: this.props.storeName,
        payload: this._makeListWindowPayload(newWindowStart, newWindowEnd)
      });
      callAction(paginationActions.PAGE_CHANGED, this.props.storeName);
    };
  }

  _getPaginationButtons() {
    const isPageSelected = (number) => number === this.getStore().activePage;

    return this.pageNumbers.map((number) => {
      const onClickHandler = () => {
        callTargetedAction({
          name: paginationActions.SET_ACTIVE_PAGE,
          targetStore: this.props.storeName,
          payload: number
        });

        callAction(paginationActions.PAGE_CHANGED, this.props.storeName);
      };

      return (
        /*
          Builds a button that has our click handler as well as the appropriate
          class / classes depending on if it is active or just a regular button.
        */
        <div
          className={classnames('paginationv2__number', {
            'paginationv2__number--selected': isPageSelected(number)
          })}
          onClick={isPageSelected(number) ? null : onClickHandler}
          key={number}
        >
          {number}
        </div>
      );
    });
  }

  generateButtonSet() {
    const store = this.getStore();
    const buttonWindow = this._getPaginationButtons().slice(
      store.listWindowStart,
      store.listWindowEnd
    );

    return (
      <div className='paginationv2__number-list'>
        {buttonWindow.map((buttonItem) => {
          return buttonItem;
        })}
      </div>
    );
  }

  getPreviousButton() {
    // If there's nothing we can PAGE LEFT to, don't.
    if (this.props.totalPages < this.windowSize) {
      return null;
    }
    const store = this.getStore();
    const listWindowStart = store.listWindowStart;
    if (listWindowStart === 0) {
      return (
        <div className='paginationv2__navigation-button--disabled paginationv2__navigation-button--left'>
          <span className='fa fa-caret-left' />
        </div>
      );
    }

    const listWindowEnd = store.listWindowEnd;
    // Shift handler that moves the window to the LEFT
    const windowShiftLeft = this._makeWindowShiftHandler(
      listWindowStart,
      listWindowEnd,
      (windowValue) => {
        /*
          We normalize our LEFT scaled value to be 0 if it goes negative
        */
        const scaledValue = windowValue - this.windowSize;
        return scaledValue < 0 ? 0 : scaledValue;
      }
    );

    return (
      <div className='paginationv2__navigation-button paginationv2__navigation-button--left'>
        <span className='fa fa-caret-left' onClick={windowShiftLeft} />
      </div>
    );
  }

  getNextButton() {
    // if there's nothing to PAGE RIGHT to, don't.
    if (this.props.totalPages < this.windowSize) {
      return null;
    }
    const store = this.getStore();
    const listWindowEnd = store.listWindowEnd;
    if (listWindowEnd >= this.props.totalPages) {
      return (
        <div className='paginationv2__navigation-button--disabled paginationv2__navigation-button--right'>
          <span className='fa fa-caret-right' />
        </div>
      );
    }

    const listWindowStart = store.listWindowStart;
    // Shift handler that moves the window to the RIGHT
    const windowShiftRight = this._makeWindowShiftHandler(
      listWindowStart,
      listWindowEnd,
      (windowValue) => {
        return windowValue + this.windowSize;
      }
    );

    return (
      <div className='paginationv2__navigation-button paginationv2__navigation-button--right'>
        <span className='fa fa-caret-right' onClick={windowShiftRight} />
      </div>
    );
  }

  render() {
    return (
      <div className='paginationv2'>
        {this.getPreviousButton()}
        {this.generateButtonSet()}
        {this.getNextButton()}
      </div>
    );
  }
}
