import {List, Map} from 'immutable';

export const authoringSubjectValidators = {
  getAlternateName() {
    return null;
  },

  getCode() {
    if (this.getCode().length === 0) {
      return List(['You must provide a code.']);
    }
    return null;
  },

  getDescription() {
    if (this.getDescription().length === 0) {
      return List(['You must provide a description.']);
    }
    return null;
  },

  getDomain() {
    if (this.getDomain().length === 0) {
      return List(['You must select a domain.']);
    }
    return null;
  },

  getFooterText() {
    return null;
  },

  getGroup() {
    if (this.getGroup().length === 0) {
      return List(['You must select a group.']);
    }
    return null;
  },

  getName() {
    if (this.getName().length === 0) {
      return List(['You must provide a name.']);
    }
    return null;
  },

  getSubscriptionsPrices() {
    /*
      Validates nested subscription values and returns mapped error details
    */
    const subscriptionsPrices = this.getSubscriptionsPrices();
    const errors = subscriptionsPrices.map((term) => {
      const expiresInMonths = parseInt(term.get('expiresInMonths'), 10);
      const price = parseInt(term.get('price'), 10);

      let expiresInMonthsError = null;
      if (isNaN(expiresInMonths)) {
        expiresInMonthsError = List(['Please enter a valid term.']);
      }
      if (expiresInMonths < 1) {
        expiresInMonthsError = List(['The minimum term is 1 month.']);
      }
      if (expiresInMonths > 12) {
        expiresInMonthsError = List(['The maximum term is 12 months.']);
      }

      let priceError = null;
      if (isNaN(price) || price < 0) {
        priceError = List(['Please enter a valid price.']);
      }

      return Map()
        .set('expiresInMonths', expiresInMonthsError)
        .set('price', priceError);
    });

    return errors;
  },

  getUrlSlug() {
    if (this.getUrlSlug().length === 0) {
      return List(['You must provide a URL slug.']);
    }
    return null;
  },

  isShouldShowLabelTable() {
    return null;
  }
};
