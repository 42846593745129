import React from 'react';
import PropTypes from 'prop-types';
import {Set} from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import {callTargetedAction, setUpStore} from 'client/framework';
import checkboxGroupActions from './CheckboxGroup.actions';
import CheckboxGroupStore from './CheckboxGroup.store';

import CheckboxToggle from 'client/styleguide/CheckboxToggle/CheckboxToggle.react';

import './checkbox-group.scss';

export default class CheckboxGroup extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    data: ImmutablePropTypes.list.isRequired,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    storeName: PropTypes.string.isRequired,
    startSelected: PropTypes.bool
  };

  static defaultProps = {
    className: '',
    disabled: false,
    onChange: () => {}
  };

  componentDidMount() {
    if (this.props.startSelected) {
      const values = this.props.data.map((datum) => datum.get('value'));
      this.checkboxAction(new Set(values), checkboxGroupActions.CHECKBOX_GROUP_SELECTION);
    }
  }

  componentWillUnmount() {
    callTargetedAction({
      name: checkboxGroupActions.RESET_STORE,
      targetStore: this.props.storeName
    });
  }

  getStore() {
    return setUpStore(CheckboxGroupStore, this.props.storeName);
  }

  checkboxAction(value, action) {
    if (this.props.disabled) {
      return;
    }

    callTargetedAction({
      name: action,
      payload: value,
      targetStore: this.props.storeName
    });

    this.props.onChange();
  }

  handleSelection(value) {
    this.checkboxAction(new Set([value]), checkboxGroupActions.CHECKBOX_GROUP_SELECTION);
  }

  handleDeselection(value) {
    this.checkboxAction(value, checkboxGroupActions.CHECKBOX_GROUP_DESELECTION);
  }

  generateOptions(options) {
    const selectedCheckboxes = this.getStore().getSelectedOptions();
    return options.map((option, key) => {
      const isChecked = selectedCheckboxes.contains(option.get('value'));
      return (
        <CheckboxToggle
          disabled={this.props.disabled}
          key={key}
          checked={isChecked}
          label={option.get('label')}
          name={`${option.get('value')}`}
          onChange={() => {
            if (isChecked) {
              this.handleDeselection(option.get('value'));
            } else {
              this.handleSelection(option.get('value'));
            }
          }}
        />
      );
    });
  }

  render() {
    const checkboxes = this.generateOptions(this.props.data);
    return (
      <div className={classnames('checkbox-toggle-group', this.props.className)}>{checkboxes}</div>
    );
  }
}
