/**
 * # Heading
 *
 * A component for rendering headings with customizable styles and options.
 *
 * ## Props
 *
 * - `as` (optional): The HTML element type to be used for the heading. Defaults to `'div'`.
 * - `className` (optional): Additional CSS class name(s) for the heading.
 * - `size` (optional): The size of the heading. Can be one of `'2xl'`, `'xl'`, `'l'`, `'m'`, `'s'`, `'xs'`, or `'2xs'`. Defaults to `'m'`.
 * - `uppercase` (optional): Specifies whether the heading should be displayed in uppercase.
 * - `withMargin` (optional): Specifies whether the heading should have margin.
 *
 * ## Usage
 *
 * ```tsx
 * import React from 'react';
 * import Heading from './Heading';
 *
 * const MyComponent = () => {
 *   return (
 *     <div>
 *       <Heading size="xl" uppercase>
 *         Hello, World!
 *       </Heading>
 *     </div>
 *   );
 * };
 *
 * export default MyComponent;
 * ```
 */

import React, {HTMLAttributes} from 'react';
import classnames from 'classnames';

import './heading.scss';
import {TextColor} from '../Text/Text.react';

export interface Props
  extends PropsWithChildrenRequired,
    PropsWithClassNameOptional,
    OmitChildren<HTMLAttributes<HTMLHeadingElement>> {
  as?: React.ElementType;
  children: React.ReactNode;
  size?: '2xl' | 'xl' | 'l' | 'm' | 's' | 'xs' | '2xs';
  color?: TextColor;
  uppercase?: boolean;
  withMargin?: boolean;
}

export const Heading = ({
  as = 'div',
  className,
  size = 'm',
  color = 'inherit',
  uppercase,
  withMargin,
  ...rest
}: Props) => {
  const WrapperElement = as;
  return (
    <WrapperElement
      className={classnames(className, 'a-heading', `a-heading--${size}`, `content-${color}`, {
        'a-heading--with-margin': withMargin,
        'a-heading--uppercase': uppercase
      })}
      {...rest}
    />
  );
};

export default Heading;
