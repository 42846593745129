/**
 * # Card
 *
 * Component used for medium and large layouts. Can often be thought of and used as a container for content. Most items with a border end up being a Card along with modals, and dialogue components.
 *
 * ## Props
 *
 * - `as` (optional): The HTML element or React component to be used as the wrapper element. Defaults to `'div'`.
 * - `backgroundColor` (optional): The background color of the card. Defaults to `'white'`.
 * - `border` (optional): The type of border for the card. Can be `'none'`, `'regular'`, or `'darker'`. Defaults to `'regular'`.
 * - `className` (optional): Additional CSS class names for the card.
 * - `roundness` (optional): The roundness of the card corners. Can be `'small'`, `'medium'`, `'large'`, `'pill'`, or `'none'`. Defaults to `'small'`.
 * - `shadow` (optional): The shadow intensity of the card. Can be `'closest'`, `'close'`, `'default'`, `'far'`, or `'farthest'`.
 * - `paddingLevel` (optional): The padding level of the card. Can be a number or `null`. Defaults to `0`.
 * - `children` (optional): The content of the card.
 *
 * ## Usage
 *
 * ```tsx
 * import React from 'react';
 * import Card from './Card';
 *
 * const MyComponent = () => {
 *   return (
 *     <Card
 *       backgroundColor="blue"
 *       border="darker"
 *       roundness="medium"
 *       shadow="default"
 *       paddingLevel={2}
 *     >
 *       <h1>Hello, World!</h1>
 *     </Card>
 *   );
 * };
 *
 * export default MyComponent;
 * ```
 */

import React, {ElementType, ForwardRefExoticComponent, forwardRef, RefAttributes} from 'react';
import classnames from 'classnames';
import './card.scss';

export interface Props<C extends ElementType>
  extends PropsWithChildrenOptional,
    PropsWithClassNameOptional {
  as?: C;
  backgroundColor?: 'subtle' | 'primary' | 'bold' | 'inherit';
  border?: 'none' | 'regular' | 'darker';
  roundness?: 'small' | 'medium' | 'large' | 'pill' | 'none';
  shadow?: 'closest' | 'close' | 'default' | 'far' | 'farthest';
  paddingLevel?: number | null;
  to?: string;
}

export const Card: ForwardRefExoticComponent<Props<ElementType<any>> & RefAttributes<unknown>> &
  PropsWithChildrenOptional = forwardRef(<C extends ElementType = 'div'>(props: Props<C>, ref) => {
  const {
    as: WrapperElement = 'div',
    // if we don't want the card to control bgc use `inherit`
    backgroundColor = 'primary',
    border = 'regular',
    className,
    roundness = 'small',
    shadow,
    paddingLevel = 0,
    ...rest
  } = props;
  return (
    <WrapperElement
      className={classnames(className, 'a-card', {
        [`u-pad_${paddingLevel}`]: paddingLevel !== null && paddingLevel !== undefined,
        [`a-card--border-${border}`]: border,
        [`a-card--roundness-${roundness}`]: roundness,
        [`a-card--shadow-${shadow}`]: shadow,
        [`background-${backgroundColor}`]: backgroundColor !== 'inherit'
      })}
      {...rest}
      ref={ref}
    />
  );
});

export default Card;
