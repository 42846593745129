//@flow
import {fromJS} from 'immutable';
import {Store} from 'client/framework';
import {history} from 'client/history';
import templateAssignmentStageActions from './TemplateAssignmentStage.actions';
import {mandarkEndpoint} from '@albert-io/json-api-framework/request/builder/legacy';
import {genericMandarkRequest} from 'resources/mandark.resource';
import sessionStore from 'client/Session/SessionStore';

class TemplateAssignmentStageStore extends Store {
  constructor(name: string) {
    super(name);
    this.initialData = fromJS({
      cloning: false,
      cloned: false,
      error: null
    });
    this.setInitialData(this.initialData);
    this.handle(templateAssignmentStageActions.RESET_STORE, this.resetStore);
    this.handle(templateAssignmentStageActions.COPY_TEMPLATE, this._handleCopyTemplate);
  }

  async _handleCopyTemplate({templateId}: {templateId: string}): Promise<*> {
    if (this.isCloning()) {
      return;
    }
    this.writeData('cloning', true);

    const query = mandarkEndpoint(['templates_v1', 'copy_template', templateId]).done();

    try {
      await genericMandarkRequest('post', query, {
        data: {
          attributes: {},
          relationships: {
            teacher_v1: {
              data: {
                id: sessionStore.getUserId(),
                type: 'teacher_v1'
              }
            }
          }
        }
      });
      this.writeData('cloned', true);
    } catch (e) {
      this.writeData('error', e);
    } finally {
      this.writeData('cloning', false);
    }

    if (!this.hasCloned()) {
      return;
    }

    history.pushState(null, `/saved`);
  }

  isCloning(): boolean {
    return this.readData('cloning') === true;
  }

  hasCloned(): boolean {
    return this.readData('cloned') === true;
  }
}

export default new TemplateAssignmentStageStore('TemplateAssignmentStageStore');
