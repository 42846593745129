/**
 * # FilterChip
 *
 * A custom filter chip component built with React and React Select.
 *
 * ## Props
 *
 * - `value` (object, required): The selected value of the filter chip.
 * - `onChange` (function, required): The callback function called when the value of the filter chip changes.
 * - `label` (string): The label of the filter chip.
 * - `color` (string): The color of the filter chip. Must be one of type ChipColor
 * - `options` (array): The options for the filter chip.
 * - `disabled` (boolean): Specifies whether the filter chip is disabled.
 * - `hasFocus` (boolean): Specifies whether the filter chip has focus.
 * - `hasHover` (boolean): Specifies whether the filter chip is being hovered.
 * - `role` (string): The role of the filter chip.
 * - `className` (string): The CSS class name for the filter chip.
 *
 * ## Usage
 *
 * ```jsx
 * import FilterChip from './FilterChip.react';
 *
 * const options = [
 *   { value: 'option1', label: 'Option 1' },
 *   { value: 'option2', label: 'Option 2' },
 *   { value: 'option3', label: 'Option 3' },
 * ];
 *
 * const MyComponent = () => {
 *   const [selectedValue, setSelectedValue] = useState(null);
 *
 *   const handleChange = (value) => {
 *     setSelectedValue(value);
 *   };
 *
 *   return (
 *     <FilterChip
 *       value={selectedValue}
 *       onChange={handleChange}
 *       label="Filter"
 *       color="brand"
 *       options={options}
 *       disabled={false}
 *       hasFocus={false}
 *       hasHover={false}
 *       role="button"
 *       className="my-filter-chip"
 *     />
 *   );
 * };
 * ```
 */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Select, {components} from 'react-select';

import {Chip, Text, WithHoverAndFocus} from '@albert-io/atomic';

import './filter-chip.scss';

const {ValueContainer} = components;

const customStyles = {
  control: () => ({
    display: 'flex',
    cursor: 'pointer',
    width: '100%'
  }),
  dropdownIndicator: () => ({
    color: 'inherit',
    display: 'flex'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px solid #dfe0e0',
    color: state.isSelected ? '#399fdf' : 'rgba(0, 0, 0, 0.7)', // selected: $blue-400
    cursor: 'pointer',
    fontWeight: 700,
    padding: 16,
    background: 'transparent'
  }),
  menu: (provided) => ({
    ...provided,
    left: 0,
    width: 'auto',
    minWidth: '100%'
  }),
  valueContainer: () => ({
    flexWrap: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    flex: 1
  }),
  menuPortal: (base) => ({...base, zIndex: 9999})
};

const CustomControl = ({children, innerRef, innerProps, selectProps, ...props}) => {
  return (
    <WithHoverAndFocus>
      {({hasFocus, hasHover, ...withHoverAndFocusHandlers}) => (
        <Chip
          ref={innerRef}
          hasFocus={hasFocus}
          hasHover={hasHover}
          style={{cursor: 'pointer'}}
          {...innerProps}
          {...withHoverAndFocusHandlers}
          {...selectProps}
          {...props}
        >
          {children}
        </Chip>
      )}
    </WithHoverAndFocus>
  );
};

CustomControl.propTypes = {
  children: PropTypes.node,
  innerRef: PropTypes.object,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object
};

const CustomValueContainer = ({children, selectProps, ...props}) => {
  const selectInput = React.Children.toArray(children).find(
    (input) => input.type.name === 'Input' || input.type.name === 'DummyInput'
  );
  const {label, value, ...restSelectProps} = selectProps;
  return (
    <ValueContainer {...restSelectProps} {...props}>
      <Text bold size='s' color='inherit' className='u-mar-r_1'>
        {label}:
      </Text>
      <Text size='s' color='inherit' className='u-overflow_hidden u-text-overflow_ellipsis'>
        {value.value || 'Any'}
      </Text>
      {selectInput}
    </ValueContainer>
  );
};

CustomValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object
};

const FilterChip = ({
  value,
  onChange,
  label = 'filterLabel',
  color,
  options = [],
  disabled,
  className,
  ...rest
}) => {
  return (
    <Select
      className={classnames('m-filterchip__select', {
        [className]: !!className
      })}
      styles={customStyles}
      aria-label={label}
      label={label}
      color={color}
      isSearchable={false}
      disabled={disabled}
      onChange={onChange}
      value={value}
      data-testid='m-filterchip__select'
      options={options}
      components={{
        ValueContainer: CustomValueContainer,
        Control: CustomControl
      }}
      {...rest}
    />
  );
};

FilterChip.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.array,
  hasFocus: PropTypes.bool,
  hasHover: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
  role: PropTypes.string
};

export default FilterChip;
