// @flow
import {List, Map, Set} from 'immutable';
import type {AuthoringGuideModelV1} from 'resources/GeneratedModels/AuthoringGuide/AuthoringGuideModel.v1';
import type {GuideModelV1} from 'resources/GeneratedModels/Guide/GuideModel.v1';
import type {AuthoringGuideLevelModelV2} from 'resources/GeneratedModels/AuthoringGuideLevel/AuthoringGuideLevelModel.v1';
import type {GuideLevelModelV2} from 'resources/GeneratedModels/GuideLevel/GuideLevelModel.v2';
import appStore from 'client/AppStore';

function getListFromStructure(
  guide: AuthoringGuideModelV1 | GuideModelV1,
  structure: Map<string, *>,
  oldList: List<any>
): List<Map<string, *>> {
  const newList = structure
    .reduce((prev, curr, key) => {
      const position = guide.getGuideLevelById(key).getPosition();
      if (Map.isMap(structure.get(key)) && !structure.get(key).isEmpty()) {
        const children = getListFromStructure(guide, structure.get(key), List());
        prev = prev.set(position, Map().set(key, children));
      } else {
        prev = prev.set(position, Map().set(key, List()));
      }
      return prev;
    }, oldList)
    /**
     * Restoring this filter for a smoother QA experience since
     * faulty data will persist on dev unless explicitly fixed.
     * See PR for issue #671
     */
    .filterNot((val) => val === undefined);
  return newList;
}

function getLastLevelFromTheme(guideLevel: Map<string, List<any>>): string {
  const id = guideLevel.keySeq().first();
  const children = guideLevel.first();
  if (children.isEmpty()) {
    return id;
  }
  return getLastLevelFromTheme(children.last());
}

export const baseGuideExtensions = {
  _getGuideLevelById(): AuthoringGuideLevelModelV2 | GuideLevelModelV2 {
    // Pass-through added for legacy model references
    return this.getGuideLevelById();
  },

  getLastGuideLevelsFromThemes(): List<string> {
    return this.cache(`getLastGuideLevelsFromThemes${this.getId()}`, () => {
      const lastLevels = this.getListFromStructure().reduce((result, theme) => {
        const id = getLastLevelFromTheme(theme);
        result = result.push(id);
        return result;
      }, List());

      return lastLevels;
    });
  },

  getListFromStructure(): List<Map<string, *>> {
    return this.cache('list_from_structure', () => {
      const structure = this.getMeta().getStructure();
      return getListFromStructure(this, structure, List());
    });
  },

  getGuideLevelById(id: string): GuideLevelModelV2 {
    return this.cache(`getGuideLevelById${id}`, () =>
      this.getGuideLevels()
        .getCollection()
        .find((guideLevel) => guideLevel.getId() === id)
    );
  },

  getGuideLevelsWithAnswers(): List<GuideLevelModelV2> | List<AuthoringGuideLevelModelV2> {
    return this.cache('answered-guide-levels', () =>
      this.getGuideLevels()
        .getCollection()
        .filter((guideLevel) => {
          return guideLevel.getMetaStudentCountOfGuesses() > 0;
        })
    );
  },

  getMetaGuideLevelTree(): Map<string, *> {
    // Aliased for legacy model references
    return this.getMeta().getStructure();
  },

  getThemes(): List<GuideLevelModelV2> | List<AuthoringGuideLevelModelV2> {
    return this.cache('themes', () => {
      return this.getGuideLevels()
        .getCollection()
        .filter((guideLevel) => guideLevel.isTheme());
    });
  },

  getTopics(themeId: ?string): List<GuideLevelModelV2> | List<AuthoringGuideLevelModelV2> {
    if (!themeId) {
      return this.cache('topics', () => {
        return this.getGuideLevels()
          .getCollection()
          .filter((guideLevel) => guideLevel.isTopic());
      });
    }

    return this.cache(`${themeId}-topics`, () => {
      const ids = this.getMetaGuideLevelTree().get(themeId, new Map()).keySeq().toList();

      return this.getGuideLevels()
        .getCollection()
        .filter((guideLevel) => {
          return ids.contains(guideLevel.getId());
        });
    });
  },

  getSubtopics(
    themeId: ?string,
    topicId: ?string
  ): List<GuideLevelModelV2> | List<AuthoringGuideLevelModelV2> {
    // TODO: can support only specifying one of themeId and topicId... but we probably never need that.
    if (!themeId && !topicId) {
      return this.cache('subtopics', () => {
        return this.getGuideLevels()
          .getCollection()
          .filter((guideLevel) => guideLevel.isSubtopic());
      });
    }

    return this.cache(`${themeId}-${topicId}-subtopics`, () => {
      const ids = this.getMetaGuideLevelTree()
        .getIn([themeId, topicId], new Map())
        .keySeq()
        .toList();

      return this.getGuideLevels()
        .getCollection()
        .filter((guideLevel) => {
          return ids.contains(guideLevel.getId());
        });
    });
  },

  getSortedThemes(): List<GuideLevelModelV2> | List<AuthoringGuideLevelModelV2> {
    return this.cache('sorted-themes', () =>
      this.getThemes().sortBy((theme) => theme.getPosition())
    );
  },

  getSortedTopics(themeId: string): List<GuideLevelModelV2> | List<AuthoringGuideLevelModelV2> {
    return this.cache(`${themeId}-sorted-topics`, () =>
      this.getTopics(themeId).sortBy((topic) => topic.getPosition())
    );
  },

  getSortedSubtopics(
    themeId: string,
    topicId: string
  ): List<GuideLevelModelV2> | List<AuthoringGuideLevelModelV2> {
    return this.cache(`${themeId}-${topicId}-sorted-subtopics`, () => {
      return this.getSubtopics(themeId, topicId).sortBy((subtopic) => subtopic.getPosition());
    });
  },

  _getTags(guideLevels: List<GuideLevelModelV2> | List<AuthoringGuideLevelModelV2>): Set<string> {
    return guideLevels.reduce((tags, guideLevel) => tags.concat(guideLevel.getTags()), new Set());
  },

  getThemeTags(): Set<string> {
    return this.cache('theme-tags', () => this._getTags(this.getThemes()));
  },

  getTopicTags(themeId: ?string): Set<string> {
    return this.cache(`${themeId}-topic-tags`, () => this._getTags(this.getTopics(themeId)));
  },

  getSubtopicTags(themeId: ?string, topicId: ?string): Set<string> {
    return this.cache(`${themeId}-${topicId}-subtopic-tags`, () =>
      this._getTags(this.getSubtopics(themeId, topicId))
    );
  },

  isTopicFree(themeId: string, topicId: string): boolean {
    return this.cache(`${topicId}-is-free`, () => {
      const topic = this.getGuideLevelById(topicId);
      if (topic === undefined) {
        return false;
      }

      return (
        topic.isFree() && this.getSubtopics(themeId, topicId).every((subtopic) => subtopic.isFree())
      );
    });
  },

  getSortedGuideLevels(): List<GuideLevelModelV2> | List<AuthoringGuideLevelModelV2> {
    return this.cache(`sortedGuideLevels${appStore.routerProps.location.search}`, () => {
      let sortedCollection = List();
      this.getSortedThemes().forEach((theme) => {
        sortedCollection = sortedCollection.push(theme);
        this.getSortedTopics(theme.getId()).forEach((topic) => {
          sortedCollection = sortedCollection.push(topic);
          this.getSortedSubtopics(theme.getId(), topic.getId()).forEach((subtopic) => {
            sortedCollection = sortedCollection.push(subtopic);
          });
        });
      });
      return sortedCollection;
    });
  },

  getSortedFRQItems(usePublic?: boolean): List<*> {
    return this.cache(`sortedFRQGuideLevels${this.getId()}`, () => {
      let sortedCollection = List();
      this.getSortedThemes().forEach((theme) => {
        sortedCollection = sortedCollection.push(theme);
        this.getSortedTopics(theme.getId()).forEach((topic) => {
          sortedCollection = sortedCollection.push(topic);
          // sorting question sets by position which is only accessible via the `relationshipMeta` object
          const qSets = topic
            .getQuestionSets()
            .sortBy((set) =>
              set.relationshipMeta?.get('guide_level')?.get(topic.getId())?.get('position')
            );
          qSets.forEach((set) => {
            sortedCollection = sortedCollection.concat(
              usePublic ? set.getPublicQuestions() : set.getQuestions()
            );
          });
          this.getSortedSubtopics(theme.getId(), topic.getId()).forEach((subtopic) => {
            sortedCollection = sortedCollection.push(subtopic);
            subtopic.getQuestionSets().forEach((set) => {
              sortedCollection = sortedCollection.concat(
                usePublic ? set.getPublicQuestions() : set.getQuestions()
              );
            });
          });
        });
      });
      return sortedCollection;
    });
  }
};
