import {create} from 'zustand';
import {devtools, subscribeWithSelector} from 'zustand/middleware';
import {immer} from 'zustand/middleware/immer';

import {createTopicsModalSlice} from '../SubjectAndGuideSelector/Redesign/TopicsModalStore';

import {createFolderStoreSlice} from '../Document/AssignmentNavigation/FoldersStore';

import {createNavigationStoreSlice} from './slices/Navigation.store';

import {createAssignmentSlice} from './slices/Assignment.store';
import {createSettingsFormSlice} from './slices/SettingsForm.store';
import {createDraftSlice} from './slices/Draft.store';
import {createSaveSlice} from './slices/Save.store';
import {createStandardsSlice} from './slices/Standards.store';
import {createFilterSlice} from './slices/Filter.store';

import type {ContentDiscoveryStoreState} from './ContentDiscoveryStore.types';

export const useContentDiscoveryStore = create<ContentDiscoveryStoreState>()(
  devtools(
    immer(
      subscribeWithSelector((set, get, ...args) => ({
        isLoading: false,
        topicsModal: {...createTopicsModalSlice(set, get, ...args)},
        folderSlice: {...createFolderStoreSlice(set, get, ...args)},
        navigation: {...createNavigationStoreSlice(set, get, ...args)},
        assignment: {...createAssignmentSlice(set, get, ...args)},
        settingsForm: {...createSettingsFormSlice(set, get, ...args)},
        draft: {...createDraftSlice(set, get, ...args)},
        save: {...createSaveSlice(set, get, ...args)},
        filter: {...createFilterSlice(set, get, ...args)},
        standards: {...createStandardsSlice(set, get, ...args)},
        init: () => {
          // run initial setup here
        },
        reset: () => {
          // reset all slices
          set((state) => {
            state.isLoading = false;
          });
          get().topicsModal.reset();
          get().folderSlice.reset();
          get().assignment.reset();
          get().settingsForm.reset();
          get().save.reset();
          get().draft.reset();
          get().navigation.reset();
          get().standards.reset();
          get().filter.reset();
        }
      }))
    )
  )
);
