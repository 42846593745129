import React, {useContext} from 'react';

import {AnalyticsApiResult, Dimension, QueryVariables} from 'client/Reports/reports.types';
import {ReportsContext} from 'client/Reports/Reports.context';
import {Badge, WithTooltip} from '@albert-io/atomic';
import {isEqual} from 'lodash';

import classNames from 'classnames';

import {getStudentAssignment} from 'client/Reports/reports.utils';

interface Props {
  result: AnalyticsApiResult;
  dimensions: Dimension[];
  filters: QueryVariables;
}

const getAccuracy = (result: AnalyticsApiResult) => {
  const {accuracy} = result.metrics;
  if (accuracy !== undefined) {
    return Math.round(accuracy * 100);
  }
  return undefined;
};

const getGrade = (result: AnalyticsApiResult) => {
  const {grade} = result.metrics;
  if (grade !== undefined) {
    return Math.round(grade * 100);
  }
  return undefined;
};

const formatPercent = (value: number) => `${Math.round(value)}%`;

const formatAccuracy = (
  accuracy: number | undefined,
  dimensions: Dimension[],
  filters: QueryVariables
) => {
  if (accuracy === undefined) {
    return 'N/A';
  }
  if (
    (isEqual(dimensions, ['students']) && filters.questionFilter) ||
    (isEqual(dimensions, ['questions']) && filters.studentFilter)
  ) {
    return accuracy === 100 ? 'Correct' : 'Incorrect';
  }
  return formatPercent(accuracy);
};

const formatGrade = (grade: number | undefined) => {
  if (grade === undefined) {
    return 'N/A';
  }

  return formatPercent(grade);
};

const isAssignmentUnsubmittedAndNotPastDue = (result: AnalyticsApiResult) => {
  const studentAssignment = getStudentAssignment(result);

  if (!studentAssignment) {
    return false;
  }

  const {submitted} = studentAssignment;
  const dueDate = new Date(
    studentAssignment?.settings?.due_date ||
      studentAssignment?.classroom_assignments?.[0]?.settings?.due_date
  );

  return !submitted && dueDate > new Date();
};

const getMastery = (
  result: AnalyticsApiResult,
  dimensions: Dimension[],
  filters: QueryVariables,
  performanceCalculateBy: 'accuracy' | 'grade'
) => {
  let value;
  if (performanceCalculateBy === 'grade') {
    value = getGrade(result);
  } else {
    value = getAccuracy(result);
  }

  const formattedValue =
    performanceCalculateBy === 'grade'
      ? formatGrade(value)
      : formatAccuracy(value, dimensions, filters);

  const countAttempts = result.metrics.count_attempts;
  const studentAssignment = getStudentAssignment(result);

  if (
    !countAttempts &&
    studentAssignment &&
    new Date(
      studentAssignment?.settings?.due_date ||
        studentAssignment?.classroom_assignments?.[0]?.settings?.due_date
    ) > new Date()
  ) {
    return {text: 'Not Started', tooltipText: 'Not Started', color: 'u-bgc_slate-50'} as const;
  }
  if (performanceCalculateBy === 'grade' && isAssignmentUnsubmittedAndNotPastDue(result)) {
    return {
      text: formattedValue,
      tooltipText: 'In Progress',
      color: 'u-bgc_slate-50'
    } as const;
  }

  if (value === undefined) {
    return {text: formattedValue, tooltipText: '', color: 'u-bgc_slate-500'} as const;
  }
  if (value < 55) {
    return {
      text: formattedValue,
      tooltipText: 'Struggling',
      color: 'background-struggling'
    } as const;
  }
  if (value < 70) {
    return {text: formattedValue, tooltipText: 'Passing', color: 'background-passing'} as const;
  }
  if (value < 80) {
    return {
      text: formattedValue,
      tooltipText: 'Proficient',
      color: 'background-proficient'
    } as const;
  }
  if (value < 90) {
    return {text: formattedValue, tooltipText: 'Excelling', color: 'background-excelling'} as const;
  }
  return {text: formattedValue, tooltipText: 'Mastery', color: 'background-mastery'} as const;
};

export const Performance = ({result, dimensions, filters}: Props) => {
  const {performanceCalculateBy} = useContext(ReportsContext);
  const showMastery = !!filters.studentFilter || dimensions.includes('students');

  const {text, tooltipText, color} = getMastery(
    result,
    dimensions,
    filters,
    performanceCalculateBy
  );
  const badgeColor = showMastery ? color : 'background-na';
  return (
    <div className='u-text-align_center'>
      <WithTooltip content={tooltipText} placement='left' enabled={showMastery && !!tooltipText}>
        <Badge
          emphasis='bold'
          className={classNames('u-white-space_nowrap', badgeColor, {
            'u-color_white': badgeColor !== 'u-bgc_slate-50'
          })}
        >
          {text}
        </Badge>
      </WithTooltip>
    </div>
  );
};
