import {Descendant, BaseEditor} from 'slate';
import {ReactEditor} from 'slate-react';
import {HistoryEditor} from 'slate-history';

export const maxIndent = 8 as const;

export const indentLevels = [undefined, 1, 2, 3, 4, 5, 6, 7, maxIndent] as const;

export type IndentLevel = (typeof indentLevels)[number];

export type BlockQuoteElement = {type: 'block-quote'; children: Descendant[]};

export type BulletedListElement = {
  type: 'bulleted-list';
  children: Descendant[];
};

export type OrderedListElement = {
  type: 'numbered-list';
  children: Descendant[];
};

export type HeadingElement = {type: 'heading'; children: Descendant[]}; // title

export type HeadingOneElement = {type: 'heading-one'; children: Descendant[]}; // header

export type HeadingTwoElement = {type: 'heading-two'; children: Descendant[]}; // sub title

export type ListItemElement = {type: 'list-item'; indent?: IndentLevel; children: Descendant[]};

export type ParagraphElement = {type: 'paragraph'; indent?: IndentLevel; children: Descendant[]};

export type LinkElement = {type: 'link'; url: string; children: Descendant[]};

export type AudioChipElement = {
  type: 'audio-chip';
  id: string;
  userName: string;
  caption?: string;
  children: EmptyText[];
};

export type UploadingAudioChipElement = {
  type: 'uploading-audio-chip';
  children: EmptyText[];
};

export type DeletedAudioChipElement = {type: 'deleted-audio-chip'; children: EmptyText[]};

export type DeletedImageElement = {type: 'deleted-image'; children: EmptyText[]};

export type ImageElement = {
  type: 'image';
  id: string;
  rotation: 0 | 1;
  children: EmptyText[];
};

export type CustomElement =
  | BlockQuoteElement
  | BulletedListElement
  | OrderedListElement
  | HeadingElement
  | HeadingOneElement
  | HeadingTwoElement
  | ImageElement
  | ListItemElement
  | LinkElement
  | ParagraphElement
  | DeletedImageElement
  | AudioChipElement
  | UploadingAudioChipElement
  | DeletedAudioChipElement;

// Make sure each CustomElement type above is in array below

export const blockTypes = [
  'block-quote',
  'bulleted-list',
  'numbered-list',
  'heading',
  'heading-one',
  'heading-two',
  'list-item',
  'paragraph',
  'link',
  'image',
  'deleted-image',
  'audio-chip',
  'deleted-audio-chip',
  'uploading-audio-chip'
] as const;

export type BlockType = (typeof blockTypes)[number];

// --- text decorators ---

export const markTypes = [
  'bold',
  'italic',
  'underline',
  'superscript',
  'subscript',
  'code'
] as const;

export type MarkType = (typeof markTypes)[number];

export type EmptyText = {
  text: string;
};

type MarkText = {
  [key in MarkType]?: boolean;
};

/**
 * Object with 'text' property and optional boolean properties like bold, italic etc
 * {
 * text: 'sdfsfs',
 * bold: true,
 * italic: true
 * ...
 * }
 */
export type CustomText = MarkText & EmptyText;

export type NodeType = BlockType | MarkType;

export type CustomEditor = BaseEditor & ReactEditor & HistoryEditor;

declare module 'slate' {
  interface CustomTypes {
    Editor: CustomEditor;
    Element: CustomElement;
    Text: CustomText | EmptyText;
  }
}
