/**
 * # ListGroup
 *
 * A component that renders a list group with customizable border and roundness. Designed for ListGroupItem atom to be used as descendants.
 *
 * ## Props
 *
 * - `children`: The content of the list group. It can be an iterable of JSX elements, an array of React elements, or any other type.
 * - `className`: Optional. The CSS class name to apply to the list group.
 * - `as`: Optional. The HTML element or React component to use as the root element of the list group. Defaults to `'div'`.
 * - `border`: Optional. The border style of the list group. Accepts the same values as the `border` prop of the `Card` component.
 * - `roundness`: Optional. The roundness style of the list group. Accepts the same values as the `roundness` prop of the `Card` component.
 *
 * ## Usage
 *
 * ```tsx
 * import ListGroup from './ListGroup.react';
 *
 * const MyComponent = () => {
 *   return (
 *     <ListGroup className="my-list-group" border="solid" roundness="rounded">
 *       <div>Item 1</div>
 *       <div>Item 2</div>
 *       <div>Item 3</div>
 *     </ListGroup>
 *   );
 * };
 * ```
 */

import React from 'react';
import {Iterable} from 'immutable';

import Card, {Props as CardProps} from '../Card/Card.react';

interface Props<C extends React.ElementType> extends PropsWithClassNameOptional {
  children: Iterable<number, JSX.Element> | (React.ReactElement | null)[] | any;
  as?: C;
  border?: CardProps<C>['border'];
  roundness?: CardProps<C>['roundness'];
}

const ListGroup = <C extends React.ElementType = 'div'>({
  children,
  className,
  ...rest
}: Props<C>) => {
  return (
    <Card paddingLevel={undefined} className={className} {...rest}>
      {React.Children.toArray(children).map((child, i, childrenArr) => {
        const isFirst = i === 0;
        const isLast = i + 1 === childrenArr.length;
        if (!isFirst && !isLast) {
          return child;
        }
        return React.cloneElement(child as React.ReactElement<any>, {
          header: isFirst,
          footer: isLast
        });
      })}
    </Card>
  );
};

export default ListGroup;
