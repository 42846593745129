import React, {useContext} from 'react';
import classNames from 'classnames';
import {isUndefined} from 'lodash';

import {LoadingSpinnerLong} from '@albert-io/atomic';

import {ReportsContext} from 'client/Reports/Reports.context';

import SummaryStatsDonutChart, {
  type DonutChartData,
  type DonutChartDataKey
} from './SummaryStatsDonutChart/SummaryStatsDonutChart';

import SummaryStatsOtherStats, {
  type OtherStatsData,
  type OtherStatsDataKey
} from './SummaryStatsOtherStats/SummaryStatsOtherStats';

import './summary-stats.scss';

export default function SummaryStats() {
  const {dimensionsInPath, studentId, summaryStats} = useContext(ReportsContext);

  function getFilteredDonutChartData() {
    const donutChartData: DonutChartData = new Map();

    if (isUndefined(summaryStats)) {
      return donutChartData;
    }

    const donutChartDataKeys: DonutChartDataKey[] = [
      'count_excelling_students',
      'count_mastery_students',
      'count_not_started_students',
      'count_passing_students',
      'count_proficient_students',
      'count_struggling_students'
    ];

    donutChartDataKeys.forEach((key) => {
      const value = summaryStats[key];

      if (value > 0) {
        donutChartData.set(key, value);
      }
    });

    return donutChartData;
  }

  function getOtherStatsData() {
    const otherStatsData: OtherStatsData = new Map();

    if (isUndefined(summaryStats)) {
      return otherStatsData;
    }

    const otherStatsDataKeys: OtherStatsDataKey[] = [
      'accuracy',
      'count_attempts',
      'avg_time_spent_per_student'
    ];

    otherStatsDataKeys.forEach((key) => {
      const value = summaryStats[key];

      otherStatsData.set(key, value);
    });

    return otherStatsData;
  }

  function isSingleStudentView() {
    return dimensionsInPath.includes('students') && studentId !== '';
  }

  return (
    <div
      className={classNames('summary-stats u-display_flex u-gap_space-x2 u-mar-b_2', {
        'u-justify-content_center u-pad-t_4': isUndefined(summaryStats)
      })}
      id='summary-stats'
    >
      {isUndefined(summaryStats) ? (
        <LoadingSpinnerLong size={2} />
      ) : (
        <>
          <SummaryStatsDonutChart
            accuracy={summaryStats.accuracy}
            data={getFilteredDonutChartData()}
            isSingleStudentView={isSingleStudentView()}
          />
          <SummaryStatsOtherStats
            data={getOtherStatsData()}
            isSingleStudentView={isSingleStudentView()}
          />
        </>
      )}
    </div>
  );
}
