import React, {useState} from 'react';
import {Button, Icon} from '@albert-io/atomic';

import FoldersModal from 'client/components/PracticeView/FoldersModal/FoldersModal.react';
import sessionStore from 'client/Session/SessionStore';

import {AnalyticsApiResult} from 'client/Reports/reports.types';

interface Props {
  result: AnalyticsApiResult;
}

const QuestionActions = ({result}: Props) => {
  const [folderModalQuestionSetId, setFolderModalQuestionSetId] = useState('');
  const {question} = result;

  if (!question) {
    return null;
  }

  return (
    <span className='u-display_flex'>
      {folderModalQuestionSetId ? (
        <FoldersModal
          isReady
          selectedQuestionSetIds={[folderModalQuestionSetId]}
          userId={sessionStore.getUserId()}
          closeModalFunc={() => setFolderModalQuestionSetId('')}
          selectedQuestionCount={1}
        />
      ) : null}
      <Button
        variant='text'
        onClick={(e) => {
          e.stopPropagation();
          window.open(
            `/learn/question/${question.question_set.subject.url_slug}/${question.slug_id}`,
            '_blank'
          );
        }}
      >
        View <Icon icon='external-link-alt' className='u-mar-l_1' />
      </Button>
      <Button
        variant='text'
        onClick={(e) => {
          e.stopPropagation();
          setFolderModalQuestionSetId(question.question_set_id!);
        }}
      >
        Save...
      </Button>
    </span>
  );
};

export default QuestionActions;
