import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';

export class FreeResponseQuestion extends React.Component {
  static propTypes = {
    question: ImmutablePropTypes.record
  };

  shouldComponentUpdate(nextProps) {
    return nextProps.question.getPrompt() !== this.props.question.getPrompt();
  }

  render() {
    const prompt = this.props.question.getPrompt();

    return (
      <div className='free-response-question'>
        <div className='free-response-question-content'>
          <MarkdownRendererV2 text={prompt} />
        </div>
      </div>
    );
  }
}
