import React from 'react';
import PropTypes from 'prop-types';

import {AuthoringSupplementModelV1} from 'resources/augmented/AuthoringSupplement/AuthoringSupplementModel.v1';
import {SupplementModelV1} from 'resources/augmented/Supplement/SupplementModel.v1';
import {
  getImgixURL,
  isSupplementCropped,
  getImgixURLWithCrop
} from 'client/SupplementTypes/Image/utils';

export default class Imgix extends React.Component {
  willUnmountHasFired = false;

  static propTypes = {
    onClick: PropTypes.func,
    supplement: PropTypes.oneOfType([
      PropTypes.instanceOf(AuthoringSupplementModelV1),
      PropTypes.instanceOf(SupplementModelV1)
    ]).isRequired,
    className: PropTypes.string
  };

  static defaultProps = {
    onClick: () => {}
  };

  constructor(props) {
    super(props);
    this.state = {};
    const {supplement} = props;
    /**
     * The SupplementStore that provides the supplement can still be resolving information...
     */
    const {path} = supplement.getImageLocation();
    if (!path) {
      return;
    }

    if (process.env.IS_SERVER) {
      /**
       * For the iso-snapshot we intentionally set the max-width to ensure we don't toll an end-user
       * with full-image load time, twice.
       * @type {(String|null)}
       */
      this.state.src = getImgixURL(supplement, {'max-w': 250});
      return;
    }
    /**
     * If there is no crop information for the supplement, we can render it as-is
     * without making a request for metadata.
     */
    if (!isSupplementCropped(props.supplement)) {
      this.state.src = getImgixURL(supplement);
    }
  }

  componentDidMount() {
    /**
     * The SupplementStore that provides the supplement can still be resolving information...
     */
    const {path} = this.props.supplement.getImageLocation();
    if (!path || this.state.src) {
      return;
    }
    this.fetchImageSrc();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.supplement !== this.props.supplement) {
      this.fetchImageSrc();
    }
  }

  componentWillUnmount() {
    this.willUnmountHasFired = true;
  }

  /**
   * "Fecth" the `src` for the image based on the properties of the supplment.
   */
  fetchImageSrc() {
    /**
     * If the supplement doesn't have crop values, we don't have to worry
     * about getting the proper sizing from the API and cropping... we can
     * just use the "raw" path.
     */
    if (!isSupplementCropped(this.props.supplement)) {
      this.setState({
        src: getImgixURL(this.props.supplement)
      });
      return;
    }
    getImgixURLWithCrop(this.props.supplement).then((src) => {
      if (this.willUnmountHasFired) {
        return;
      }
      this.setState({
        src
      });
    });
  }

  preventDefault(e) {
    e.preventDefault();
  }

  render() {
    const {className} = this.props;
    /**
     * Wait for a lifecycle to resolve `fetchImageSrc`
     */
    if (!this.state.src) {
      return null;
    }

    return (
      <img
        className={className}
        src={this.state.src}
        alt={this.props.supplement.getAlttext()}
        onContextMenu={this.preventDefault}
        onClick={this.props.onClick}
      />
    );
  }
}
