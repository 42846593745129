import {List} from 'immutable';

import {AuthoringQuestionSetModelV1} from 'resources/GeneratedModels/AuthoringQuestionSet/AuthoringQuestionSetModel.v1';

export const authoringQuestionSetModelValidators = {
  getName(this: AuthoringQuestionSetModelV1) {
    if (this.getName().length === 0) {
      return List(['You must provide a question set name.']);
    }
    if (this.getName().length > 65) {
      return List(['Question set names must be 65 characters or less.']);
    }
    return null;
  }
};
