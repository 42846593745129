import {create} from 'zustand';
import {genericMandarkRequest} from 'resources/mandark.resource';
import {chunk} from 'lodash';
import {QuestionModelV3, SubjectModelV2} from 'resources/augmented/Question/QuestionModel.v3';
import {devtools} from 'zustand/middleware';
import {getFeatureFlagStatus} from 'client/components/FeatureFlag/FeatureFlag.react';
import {getTranslationCoverage} from './Translation.utils';

import {
  TranslatedQuestion,
  TranslatedSupplement,
  AlternateContentLanguage,
  ALTERNATE_CONTENT_LANGUAGES,
  TranslationCoverage
} from './Translation.types';

interface QuestionTranslationState {
  translatedQuestionInfo: TranslatedQuestion | null;
  // legacy complicated components that just need dropdowns etc rendered
  translatedSupplements: TranslatedSupplement[] | null;
  fetchingTranslations: boolean;
  translationCoverage: TranslationCoverage;
  hasTranslationAccess: boolean;
  isReady: boolean;
  toggleStatus: {
    prompt: boolean;
    answer: boolean;
  };
}

interface QuestionTranslationActions {
  fetchTranslation: (
    questionId: string,
    supplementIds: string[],
    question: QuestionModelV3,
    subject: SubjectModelV2,
    language?: AlternateContentLanguage
  ) => Promise<void>;
  init: () => Promise<void>;
  onToggle: {
    prompt: () => void;
    answer: () => void;
  };
  // refreshTranslations(): Promise<void>;
}

export const useQuestionTranslationStore = create<
  QuestionTranslationState & QuestionTranslationActions
>()(
  devtools((set, get) => ({
    translatedQuestionInfo: null,
    translatedSupplements: null,
    fetchingTranslations: false,
    translationCoverage: 'none',
    hasTranslationAccess: false,
    isReady: false,
    toggleStatus: {
      prompt: false,
      answer: false
    },
    init: async () => {
      const flagEnabled = await getFeatureFlagStatus('assignment_question_translations');
      set({
        isReady: true,
        hasTranslationAccess: flagEnabled
      });
    },
    onToggle: {
      prompt: () => {
        const currentStatus = get().toggleStatus;
        set({
          toggleStatus: {
            answer: currentStatus.answer,
            prompt: !currentStatus.prompt
          }
        });
      },
      answer: () => {
        const currentStatus = get().toggleStatus;
        set({
          toggleStatus: {
            answer: !currentStatus.answer,
            prompt: currentStatus.prompt
          }
        });
      }
    },
    fetchTranslation: async (
      questionId,
      supplementIds,
      question,
      subject,
      language = ALTERNATE_CONTENT_LANGUAGES[0]
    ) => {
      if (get().isReady && !get().hasTranslationAccess) {
        // Don't fetch if no access to translation info
        return;
      }

      set({
        fetchingTranslations: true,
        // reset toggles to false
        toggleStatus: {
          answer: false,
          prompt: false
        }
      });
      const [{info}, translatedSupplements] = await Promise.all([
        fetchTranslatedQuestion(questionId, language),
        fetchTranslatedSupplements(supplementIds)
      ]);

      const translationCoverage = getTranslationCoverage(info, translatedSupplements, {
        question,
        subject
      });
      set({
        // translatedQuestion,
        translatedSupplements,
        translationCoverage, // TODO: change to match api field,
        // reflect this status on FE instead of just a can view toggle
        translatedQuestionInfo: info,
        fetchingTranslations: false
      });
    }
  }))
);

const fetchTranslatedQuestion = async (questionId: string, language: AlternateContentLanguage) => {
  const response = await genericMandarkRequest('get', {
    resourcePath: ['json', 'translations', 'translated_questions', questionId],
    customQuery: {language, include: 'translated_fields'}
  });

  return {info: response.toJS()}; // Map(response);
};

const fetchTranslatedSupplements = async (supplementIds: string[]) => {
  const chunkSize = 10;
  const chunks = chunk(supplementIds, chunkSize);

  const translatedSupplements: TranslatedSupplement[] = [];

  for (const supplementChunk of chunks) {
    const requests = supplementChunk.map((id) =>
      genericMandarkRequest('get', {
        resourcePath: ['json', 'translations', 'translated_supplements', id],
        customQuery: {language: 'es', include: 'translated_fields'}
      })
    );

    // TODO: get info

    // eslint-disable-next-line no-await-in-loop
    const responses = await Promise.all(requests);
    translatedSupplements.push(...responses.map((response) => response));
  }

  return translatedSupplements;
};
