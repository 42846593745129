import React, {lazy} from 'react';
import {Route, IndexRoute} from 'react-router';
import {resource} from '@albert-io/json-api-framework/request/builder';

import sessionStore from 'client/Session/SessionStore';

import Report from './Report/Report.react';
import ReportSelector from './ReportSelector/ReportSelector.react';
import {
  DIMENSION_CONFIG,
  getGradebookRowLimitFromLocalStorage,
  getReportRowLimitFromLocalStorage,
  getValidRemainingDrilldownDimensionsForURL,
  processReportPath,
  removeTrailingSlash
} from './reports.utils';

const Reports = lazy(() => import(/* webpackChunkName: "Reports" */ './Reports.react'));

// This function is used to process the report url onEntering the report route.
// if the url is not complete due no report selected then it will automatically redirect user to proper report.
// for example if the url is /reports/classrooms/6a987bef-08e8-46fe-bf15-1c98fe4a4ab1 it will redirect to /reports/classrooms/6a987bef-08e8-46fe-bf15-1c98fe4a4ab1/assignments
// if the url is complete and reportSelected is standard, it will redirect user to user preferences
const onReportRouteEnter = async (nextState, replace, callback) => {
  const {pathname, query} = nextState.location;
  try {
    maybeRedirectFromLegacyUrl(nextState, replace);
    const {topLevelFilterType, reportSelected} = processReportPath(pathname);

    const gradebookRowLimitFromLocalStorage = getGradebookRowLimitFromLocalStorage();
    const reportRowLimitFromLocalStorage = getReportRowLimitFromLocalStorage();

    if (!query.limit && reportSelected === 'gradebook' && gradebookRowLimitFromLocalStorage) {
      replace({pathname, query: {...query, limit: gradebookRowLimitFromLocalStorage}});
      callback();
    }

    if (!query.limit && reportSelected !== 'gradebook' && reportRowLimitFromLocalStorage) {
      replace({pathname, query: {...query, limit: reportRowLimitFromLocalStorage}});
      callback();
    }

    if (!reportSelected) {
      // An example of when reportSelected would be false is if pathname = /reports/classrooms/:id/assignments/:id
      // if here we need to fetch the default report for the last filter in the pathname

      const pathParts = removeTrailingSlash(pathname).split('/');
      const lastDimensionInPath = pathParts[pathParts.length - 2]; // since it's Dimension/DimensionId
      if (lastDimensionInPath === 'school-admin') {
        replace({
          pathname: `${removeTrailingSlash(pathname)}/students`,
          query
        });
        callback();
      }
      const defaultReportForLastDimensionInPath = DIMENSION_CONFIG[lastDimensionInPath].defaultPath;
      const validRemainingDrilldownDimensions = getValidRemainingDrilldownDimensionsForURL(
        topLevelFilterType,
        pathname
      );
      if (validRemainingDrilldownDimensions && validRemainingDrilldownDimensions.length) {
        // the thought process here is that if there are validRemainingDrilldownDimensions we see if the default report for the last dimension in the path is valid
        // if it is, we redirect the user to that report
        // if it is not, we redirect the user to the first valid remaining drilldown dimension
        replace({
          pathname: `${removeTrailingSlash(pathname)}/${
            validRemainingDrilldownDimensions.includes(defaultReportForLastDimensionInPath)
              ? defaultReportForLastDimensionInPath
              : validRemainingDrilldownDimensions[0]
          }`,
          query
        });
        callback();
      } else {
        // if the default report for the last dimension in the path is not valid, send user back to previous dimension report
        const pathWithoutLastDimensionId = pathParts.slice(0, -1).join('/');
        replace(pathWithoutLastDimensionId);
        callback();
      }
    } else if (reportSelected === 'standards') {
      redirectToUserPreferences(nextState, replace, callback);
    } else {
      // if the report URL is valid and a report is selected, then just render the component
      callback();
    }
  } catch (e) {
    // no-op if there's an error from processReportPath.
    // we should render components as usual including Reports.react.tsx which contains the error boundary wrapping the ReportsProvider
    // when ReportsProvider mounts, processReportPath will be called again in useReportRoute causing the same error to be thrown again
    // the error will be then caught be caught by the error boundary and shown in console nicely
    callback();
  }
};

/*
  If a user is navigating to /reports/:classroom_id/standards and the URL does NOT already have
  standard sets as a query param, auto populate standard sets with the user's
   personalized preferences (if they exist). The "personalization" queryParam is used
   to display a message in the standard set filter that the preferences were auto applied.
   Once a user makes any manual changes to the filter, this queryParam goes away.
*/
const redirectToUserPreferences = async (nextState, replace, callback) => {
  try {
    if (nextState.location.query.standardSets) {
      callback();
    } else {
      const standardSets = await fetchStandardSets();
      if (standardSets.size) {
        const queryParams = new URLSearchParams({
          ...nextState.location.query,
          standardSets: standardSets.map((standardSet) => standardSet.getId()).join(','),
          personalization: true
        });
        replace(`${nextState.location.pathname}?${queryParams.toString()}`);
      }
      callback();
    }
  } catch (e) {
    // no-op if there's an error loading standard sets
    callback();
  }
};

function fetchStandardSets() {
  return resource('standard_sets_v1')
    .mandarkEndpoint(['standard_sets_v1'])
    .pageSize(100)
    .withMeta('standard_set_v1')
    .customQuery({
      meta: {
        context: {
          user: {
            id: sessionStore.getUserId(),
            personalization_settings: true
          }
        }
      }
    })
    .filter({
      'meta.included_in_personalization_settings': true
    })
    .getResourcePromise();
}

const maybeRedirectFromLegacyUrl = ({location, params}, replace) => {
  const {search, pathname} = location;
  const urlParts = pathname.split('/').slice(2);

  if (urlParts.length >= 1) {
    const maybeId = params.topLevelFilterType;
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    if (uuidRegex.test(maybeId)) {
      replace(`/reports/classrooms/${urlParts.join('/')}${search}`);
    } else if (maybeId.startsWith('teacher-')) {
      replace(`/reports/teachers/${maybeId.slice(8)}/${urlParts.slice(1).join('/')}${search}`);
    }
  }
};

export default (
  <Route component={Reports} path='/reports'>
    <IndexRoute component={ReportSelector} />
    <Route
      path=':topLevelFilterType(/:topLevelFilterId)'
      onEnter={onReportRouteEnter}
      component={ReportSelector}
    >
      <IndexRoute component={Report} />
      <Route onEnter={onReportRouteEnter} path='*' component={Report} />
    </Route>
  </Route>
);
