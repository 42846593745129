import {createActions} from './framework';

// Exporting as const and default for legacy support
export const appActions = createActions(null, [
  'SET_ROUTER_PROPS',
  'SET_ZONE',
  'SET_IS_MOBILE_VIEW',
  'SET_FOOTER_DISPLAY',
  'RESET_METADATA',
  'UPDATE_METADATA',
  'ROUTE_CHANGE'
]);
export default appActions;
