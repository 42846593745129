import React from 'react';
import icon from './icons/apple-touch-icon.png';
import i180 from './icons/apple-touch-icon-180x180.png';
import i167 from './icons/apple-touch-icon-167x167.png';
import i152 from './icons/apple-touch-icon-152x152.png';
import i120 from './icons/apple-touch-icon-120x120.png';
import i98 from './icons/apple-touch-icon-98x98.png';
import i87 from './icons/apple-touch-icon-87x87.png';
import i84 from './icons/apple-touch-icon-84x84.png';
import i80 from './icons/apple-touch-icon-80x80.png';
import i76 from './icons/apple-touch-icon-76x76.png';
import i72 from './icons/apple-touch-icon-72x72.png';
import i60 from './icons/apple-touch-icon-60x60.png';
import i57 from './icons/apple-touch-icon-57x57.png';
import i50 from './icons/apple-touch-icon-50x50.png';
import i40 from './icons/apple-touch-icon-40x40.png';
import i29 from './icons/apple-touch-icon-29x29.png';
/**
 * All tags are pulled from the Apple "Safari Web Content Guide"
 * @returns {React.ReactNode[]}
 * @see https://developer.apple.com/library/content/documentation/AppleApplications/Reference/SafariWebContent/ConfiguringWebApplications/ConfiguringWebApplications.html
 */
export function getSafariWebContent() {
  return [
    <meta name='apple-mobile-web-app-title' content='Albert' key='apple-mobile-web-app-title' />
  ].concat(getAppleTouchIcons());
}
/**
 * @returns {React.ReactNode[]}
 */
function getAppleTouchIcons() {
  return [
    <link rel='apple-touch-icon' key='apple-touch-icon' href={icon} />,
    <link rel='apple-touch-icon' sizes='180x180' href={i180} key='180' />,
    <link rel='apple-touch-icon' sizes='167x167' href={i167} key='167' />,
    <link rel='apple-touch-icon' sizes='152x152' href={i152} key='152' />,
    <link rel='apple-touch-icon' sizes='120x120' href={i120} key='120' />,
    <link rel='apple-touch-icon' sizes='98x98' href={i98} key='98' />,
    <link rel='apple-touch-icon' sizes='87x87' href={i87} key='87' />,
    <link rel='apple-touch-icon' sizes='84x84' href={i84} key='84' />,
    <link rel='apple-touch-icon' sizes='80x80' href={i80} key='80' />,
    <link rel='apple-touch-icon' sizes='76x76' href={i76} key='76' />,
    <link rel='apple-touch-icon' sizes='72x72' href={i72} key='72' />,
    <link rel='apple-touch-icon' sizes='60x60' href={i60} key='60' />,
    <link rel='apple-touch-icon' sizes='57x57' href={i57} key='57' />,
    <link rel='apple-touch-icon' sizes='50x50' href={i50} key='50' />,
    <link rel='apple-touch-icon' sizes='40x40' href={i40} key='40' />,
    <link rel='apple-touch-icon' sizes='29x29' href={i29} key='29' />
  ];
}
