// @flow
import {Iterable, List} from 'immutable';

function deepFind(iterable: Iterable<*, *>, pathToProperty: Array<*> = []): string {
  const match = pathToProperty.reduce((entries, key) => {
    return entries && entries.get(key) ? entries.get(key) : List();
  }, iterable);
  if (match.some((entry) => typeof entry !== 'string')) {
    logger.warn('At least one nested entry found at path provided. Try a more specific path.');
  }
  return List(match)
    .flatten()
    .join('');
}

export class Validators {
  getValidationErrors({
    skip = []
  }: {
    skip: Array<string>
  } = {}): List<string> | null {
    return Object.keys(this).reduce((errors, method) => {
      if (skip.includes(method)) {
        return errors;
      }
      const result = this[method]();
      const fieldErrors: List<string> = Iterable.isIterable(result)
        ? result.toList().flatten()
        : result;
      if (fieldErrors !== null) {
        return errors ? errors.concat(fieldErrors) : fieldErrors;
      }
      return errors;
    }, null);
  }

  hasValidationErrors({
    skip = []
  }: {
    skip: Array<string>
  } = {}): boolean {
    return Object.keys(this).some((method) => {
      if (skip.includes(method)) {
        return false;
      }
      const result = Iterable.isIterable(this[method]())
        ? this[method]()
            .toList()
            .flatten()
        : this[method]();
      return result === null ? false : result.some((val) => val !== null);
    });
  }

  hasValidator(method: string): boolean {
    if (!this.hasOwnProperty(method)) {
      throw new Error(`No such validator ${method} found on model.`);
    }
    return true;
  }

  get errors(): Object {
    return Object.keys(this).reduce((obj, method) => {
      const messages = this[method]() || List(); // Definitively replace `null` with empty list
      obj[method] = (pathToProperty) => deepFind(messages, pathToProperty);
      return obj;
    }, {});
  }
}
