import React from 'react';
import './restricted-question-message.scss';

export default class RestrictedQuestionMessage extends React.Component {
  render() {
    return (
      <div className='restricted-question-message'>
        <span className='restricted-question-message__icon fa fa-exclamation-triangle' />
        <p className='restricted-question-message__message'>
          <span className='avoid-wrap'>Sorry, this question is part of an active assignment.</span>
          <span className='avoid-wrap'>
            You won{"'"}t be able to access it until the assignment is complete.
          </span>
        </p>
      </div>
    );
  }
}
