import React from 'react';
import {isValidHTMLElement} from 'lib/isValidHTMLElement';

function smartQuotesToDumbQuotes(str) {
  return str.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
}

/**
 * Takes strings like this: "class='foo' id='bar'"
 * and turns them into { class: 'foo', id: 'bar' }
 */
function getAttributes(attributesString) {
  if (!attributesString) {
    return null;
  }
  const propertiesRegex = /(\w+)=("|')(.+?)\2/i;
  const globalRegex = new RegExp(propertiesRegex.source, 'g');
  const formattedString = smartQuotesToDumbQuotes(attributesString);
  const attributes = formattedString.match(globalRegex);
  if (!attributes) {
    return null;
  }
  return attributes.reduce((r, substring) => {
    const match = propertiesRegex.exec(substring);

    if (match) {
      const property = match[1].trim().toLowerCase();
      const value = match[3].trim();
      r[property] = value;
    }
    return r;
  }, {});
}

function getFontTagStyles(attributesString) {
  const attributes = getAttributes(attributesString);
  if (!attributes) {
    return null;
  }
  const styles = Object.keys(attributes).reduce((r, prop) => {
    let styleProp;
    switch (prop) {
      case 'color':
        styleProp = 'color';
        break;
      case 'face':
        styleProp = 'fontFamily';
        break;
      case 'size':
        styleProp = 'fontSize';
        break;
      default:
        return r;
    }
    r[styleProp] = attributes[prop];
    return r;
  }, {});
  return Object.keys(styles).length > 0 ? {style: styles} : null;
}

function getIframeAttributes(attributesString) {
  const attributes = getAllowedAttributes(attributesString);
  if (!attributes || !attributes.src) {
    return null;
  }
  // Only allows iframes which have a src from youtube, vimeo, soundcloud, genially, or phet.colorado.edu
  return /https:\/\/(?:[^\s.]*.)?(youtube|vimeo|soundcloud|view\.genially|phet.colorado)\./.test(
    attributes.src
  )
    ? attributes
    : null;
}

/**
 * Exports
 * =======
 */

/**
 * Ensures only whitelisted attributes can be used
 */
const attributesWhitelist = {
  alt: 'alt',
  cite: 'cite',
  colspan: 'colSpan',
  height: 'height',
  href: 'href',
  rowspan: 'rowSpan',
  src: 'src',
  // style: 'style', @todo: Figure out if we use inline styles. If so, we'll need to convert this string into an object.
  width: 'width',
  frameborder: 'frameBorder',
  allowfullscreen: 'allowFullScreen',
  id: 'id'
};
export function getAllowedAttributes(attributesString) {
  const allowedAttributes = {};
  const rawAttributes = getAttributes(attributesString);
  if (!rawAttributes) {
    return allowedAttributes;
  }
  Object.keys(rawAttributes).forEach((attribute) => {
    if (attributesWhitelist[attribute]) {
      allowedAttributes[attributesWhitelist[attribute]] = rawAttributes[attribute];
    }
  });
  return allowedAttributes;
}

export function getReactOrHTMLElement(node, output, asHtml = false) {
  let tagName = node.tagName.toLowerCase();
  let props;

  if (tagName === 'font') {
    props = tagName === 'font' && node.attributes ? getFontTagStyles(node.attributes) : null;
    tagName = 'span';
  } else if (tagName === 'center') {
    props = {className: 'center'};
    tagName = 'div';
  } else if (tagName === 'iframe') {
    props = getIframeAttributes(node.attributes);
    if (!props) {
      return null;
    }
  } else {
    props = getAllowedAttributes(node.attributes);
  }
  if (!props) {
    props = {};
  }
  props.key = node.tokenId;

  output = output ? output(node.content) : null;

  if (asHtml) {
    const tag = isValidHTMLElement(tagName) ? tagName : 'span';
    return `<${tag}>${output}</${tag}>`;
  }

  return React.createElement(isValidHTMLElement(tagName) ? tagName : 'span', props, output);
}
