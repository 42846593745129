import classnames from 'classnames';
import {callTargetedAction, setUpStore} from 'client/framework';
import React from 'react';
import PropTypes from 'prop-types';
import dateRangeActions from './DateRange.actions';
import DateRangeStore from './DateRange.store';
import BlockyDatePicker from 'generic/BlockyDatePicker/BlockyDatePicker.react';
/*
  XXX:
    This is a temporary implementation that will most certainly need to be replaced.

    This exists until our fancy new single-input-field date picker widget is created.
*/

import './date-range.scss';

export default class DateRange extends React.Component {
  static propTypes = {
    allowNull: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    endDateLabel: PropTypes.string,
    storeName: PropTypes.string.isRequired,
    maxRange: PropTypes.number,
    resetStoreOnUnmount: PropTypes.bool,
    shouldShowErrors: PropTypes.bool,
    startDateLabel: PropTypes.string,
    /*
    Change event fired on VALID date range being entered.

    This is used to only fire some function if both inputs make a valid range.
    Very useful and necessary when used in conjunction with some filter that re-builds
    on re-render, and we only want to change that filter if we've got a valid thing here.
    */
    onValidChange: PropTypes.func,
    onInvalidChange: PropTypes.func,
    // If true, when you go from a vaild range to an invalid range, the component will revert
    // the value after the change occurs to the previous value if that value was valid
    preventInvalidChange: PropTypes.bool,
    // ISO 8601 string for moment's sake
    initialStartDate: PropTypes.string,
    initialEndDate: PropTypes.string
  };

  static defaultProps = {
    allowNull: false,
    endDateLabel: 'End Date',
    resetStoreOnUnmount: true,
    onValidChange: () => {},
    onInvalidChange: () => {},
    shouldShowErrors: true,
    startDateLabel: 'Start Date'
  };

  constructor(props) {
    super(props);

    this.initialState = {
      startDate: props.initialStartDate || this.getStore().getStartDate(),
      endDate: props.initialEndDate || this.getStore().getEndDate()
    };

    this.state = this.initialState;
  }

  /* eslint-disable-next-line camelcase */
  UNSAFE_componentWillMount() {
    callTargetedAction({
      name: dateRangeActions.SET_ALLOW_NULL,
      targetStore: this.props.storeName,
      payload: Boolean(this.props.allowNull)
    });
  }

  componentDidMount() {
    // If provided, set initial dates for our input field if we do not already have a date written in our store
    if (!this.getStore().getStartDate()) {
      callTargetedAction({
        name: dateRangeActions.SET_START_DATE,
        targetStore: this.props.storeName,
        payload: this.state.startDate
      });
    }
    if (!this.getStore().getEndDate()) {
      callTargetedAction({
        name: dateRangeActions.SET_END_DATE,
        targetStore: this.props.storeName,
        payload: this.state.endDate
      });
    }
    if (this.props.maxRange) {
      callTargetedAction({
        name: dateRangeActions.SET_MAX_RANGE,
        targetStore: this.props.storeName,
        payload: this.props.maxRange
      });
    }
    callTargetedAction({
      name: dateRangeActions.VALIDATE_FORM,
      targetStore: this.props.storeName
    });
  }

  componentDidUpdate(_, prevState) {
    const {startDate, endDate} = this.state;
    if (prevState.startDate !== startDate) {
      this.startDateChange(startDate);
    }

    if (prevState.endDate !== endDate) {
      this.endDateChange(endDate);
    }
  }

  componentWillUnmount() {
    if (this.props.resetStoreOnUnmount) {
      callTargetedAction({
        name: dateRangeActions.RESET_STORE,
        targetStore: this.props.storeName
      });
    }
  }

  getStore() {
    return setUpStore(DateRangeStore, this.props.storeName);
  }

  handleDateChange({value, currentDate, action, isValid}) {
    if (currentDate === value) {
      return;
    }

    callTargetedAction({
      name: action,
      targetStore: this.props.storeName,
      payload: value
    });

    callTargetedAction({
      name: dateRangeActions.VALIDATE_FORM,
      targetStore: this.props.storeName
    });

    if (this.getStore().isValid()) {
      this.props.onValidChange();
    } else {
      this.props.onInvalidChange();

      if (this.props.preventInvalidChange && isValid) {
        callTargetedAction({
          name: action,
          targetStore: this.props.storeName,
          payload: currentDate
        });
      }
    }
  }

  startDateChange = (value) =>
    this.handleDateChange({
      value,
      currentDate: this.getStore().getStartDate(),
      action: dateRangeActions.SET_START_DATE,
      isValid: this.getStore().isStartDateValid()
    });

  endDateChange = (value) =>
    this.handleDateChange({
      value,
      currentDate: this.getStore().getEndDate(),
      action: dateRangeActions.SET_END_DATE,
      isValid: this.getStore().isEndDateValid()
    });

  render() {
    const hasError =
      this.props.shouldShowErrors &&
      !this.getStore().isValid() &&
      Boolean(this.getStore().getRangeErrorMessage());
    const errorDiv = hasError ? (
      <div className='a-form-input__error'>{this.getStore().getRangeErrorMessage()}</div>
    ) : null;
    const wrapperClass = classnames('date-range-wrapper', this.props.className);
    return (
      <div className={wrapperClass}>
        <div className='date-range'>
          <BlockyDatePicker
            disabled={this.props.disabled}
            className='date-range__item'
            error={hasError}
            label={this.props.startDateLabel}
            name='startDate'
            onChange={(e) => this.setState({startDate: e.target.value})}
            value={this.state.startDate}
          />
          <BlockyDatePicker
            disabled={this.props.disabled}
            className='date-range__item'
            error={hasError}
            label={this.props.endDateLabel}
            name='startDate'
            onChange={(e) => this.setState({endDate: e.target.value})}
            value={this.state.endDate}
          />
        </div>
        {errorDiv}
      </div>
    );
  }
}
