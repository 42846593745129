import React from 'react';
import {Link} from 'react-router';

import './assets/log-in-link.scss';

interface Props {
  toggleMobileMenu: () => void;
}

export default function LogInLink(props: Props) {
  return (
    <Link
      onClick={props.toggleMobileMenu}
      data-testid='log-in-button'
      className='log-in-link-button'
      to='/log-in'
    >
      Log In
    </Link>
  );
}
