import React from 'react';
import PropTypes from 'prop-types';

export default class FreeEntryQuestionResponseStatsPercentageIndicator extends React.Component {
  static propTypes = {
    index: PropTypes.number,
    className: PropTypes.string,
    answerPercentage: PropTypes.number
  };

  render() {
    const className = this.props.className;
    const answerPercentage = this.props.answerPercentage;

    return (
      <div className={className}>
        <span className='question-response-stats__item-label'>Input {this.props.index + 1}</span>
        <span
          className='question-response-stats__item-fillbar'
          data-fill-percentage={answerPercentage}
        />
        <span className='question-response-stats__item-percentage'>{answerPercentage + '%'}</span>
      </div>
    );
  }
}
