/**
 * # Grid
 *
 * Grid component is used for creating responsive layouts by dividing the available space into a grid system. In most cases we can reach for CSS grid instead of relying on this atom. 
 *
 * ## Props
 *
 * - `children` (ReactNode): The content to be rendered inside the Grid component.
 *
 * ## Usage
 *
 * ```jsx
 * import React from 'react';
 * import Grid from './Grid.react';
 *
 * function App() {
 *   return (
 *     <Grid>
 *       <Grid.Row>
 *         <Grid.Col>
 *           Content 1
 *         </Grid.Col>
 *         <Grid.Col>
 *           Content 2
 *         </Grid.Col>
 *       </Grid.Row>
 *     </Grid>
 *   );
 * }
 *
 * export default App;
 * ```
 */
import React from 'react';

import Col from './Col/Col.react';
import Container from './Container/Container.react';
import Row from './Row/Row.react';

export default class Grid extends React.Component {
  static Col = Col;

  static Container = Container;

  static Row = Row;
}
