/**
 * # LoadingSpinner
 *
 * A component that displays a loading spinner with optional text.
 *
 * ## Props
 *
 * - `assistiveText?: string` (optional): The text to be displayed for screen readers.
 * - `className?: string` (optional): Additional CSS class names for the component.
 * - `size?: 'lg' | 2 | 3 | 4 | 5` (optional): The size of the spinner. Can be one of 'lg', 2, 3, 4, or 5.
 *
 * ## Usage
 *
 * ```tsx
 * import LoadingSpinner from './LoadingSpinner.react';
 *
 * const ExampleComponent = () => {
 *   return (
 *     <div>
 *       <LoadingSpinner assistiveText="Loading..." size="lg" />
 *       <LoadingSpinner assistiveText="Please wait..." size={3} />
 *     </div>
 *   );
 * };
 * ```
 */

import React from 'react';
import classnames from 'classnames';

import Icon from '../../atoms/Icon/Icon.react';

interface Props extends PropsWithClassNameOptional {
  assistiveText?: string;
  bottomMarginLevel?: number;
  size?: 'lg' | 2 | 3 | 4 | 5;
}

const LoadingSpinner = ({
  assistiveText = 'Content is loading...',
  bottomMarginLevel = 2,
  className,
  size,
  ...rest
}: Props) => {
  return (
    <div
      className={classnames(
        className,
        'u-display_flex u-justify-content_center',
        `u-mar-b_${bottomMarginLevel}`
      )}
    >
      <Icon
        className={classnames('fa-fw', {
          'fa-lg': size === 'lg',
          'fa-2x': size === 2,
          'fa-3x': size === 3,
          'fa-4x': size === 4,
          'fa-5x': size === 5
        })}
        icon='spinner'
        pulse
        {...rest}
      />
      <label className='sr-only'>
        <progress />
        {assistiveText}
      </label>
    </div>
  );
};

export default LoadingSpinner;
