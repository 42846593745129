import React from 'react';
import {Heading, Text, ListGroupItem, IconButton} from '@albert-io/atomic';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {QuestionModelV3 as Question} from 'src/resources/augmented/Question/QuestionModel.v3';
import {QuestionSetModelV1 as QuestionSet} from 'resources/augmented/QuestionSet/QuestionSetModel.v1';

interface Props {
  question: Question;
  questionSet: QuestionSet;
  navigateBackProps?: {disabled: boolean; handleNavigation: () => void};
  navigateNextProps?: {disabled: boolean; handleNavigation: () => void};
  headingActions?: any;
  modifyTitle?: (args: any) => void;
  showDifficulty: boolean;
  customHeader?: any;
}

const HeadingBar = ({
  question,
  questionSet,
  navigateBackProps,
  navigateNextProps,
  headingActions,
  modifyTitle,
  showDifficulty = true,
  customHeader = null
}: Props) => {
  return (
    <ListGroupItem className='q-heading-block'>
      {!customHeader && (
        <div className='q-heading-block__heading'>
          <Heading as='h1' size='xs' className='u-mar_0'>
            {/* They key is added to force a rerender on question change.
           This is useful when translating. See `markdownRendererUtils.js` for more info. */}
            <MarkdownRendererV2
              key={question.getId()}
              text={modifyTitle ? modifyTitle({question, questionSet}) : question.getTitle()}
            />
          </Heading>
          {showDifficulty && <Text size='xs'>{question.getDifficultyLabel()}</Text>}
        </div>
      )}
      {customHeader && customHeader}

      {navigateBackProps && (
        <IconButton
          disabled={navigateBackProps.disabled}
          onClick={navigateBackProps.handleNavigation}
          variant='text'
          icon='chevron-left'
          aria-label='Go to previous question'
          aria-hidden={false}
        />
      )}
      {navigateNextProps && (
        <IconButton
          disabled={navigateNextProps.disabled}
          onClick={navigateNextProps.handleNavigation}
          variant='text'
          className='u-mar-l_1'
          icon='chevron-right'
          aria-label='Go to next question'
          aria-hidden={false}
        />
      )}
      {headingActions && React.cloneElement(headingActions, {question})}
    </ListGroupItem>
  );
};

// eslint-disable-next-line import/prefer-default-export
export {HeadingBar};
