import React from 'react';
import {Map} from 'immutable';
import {getStoreByName} from 'client/framework';
import shallowEqual from 'shallowequal';

/*
 * Takes in one or multiple store instaces or store names as arguments.
 * Decorated component will not update unless stores, props, or state change.
 * Passes addConnectedStores callback function as prop to decorated component that
 * lets the decorated component connect additional stores.
 */
export default function connectToStores(...stores) {
  return (DecoratedComponent) =>
    class extends React.Component {
      displayName = 'ConnectToStoresHOC';

      constructor(props) {
        super(props);
        this.storeChangeIds = new Map();
        this.boundAddConnectedStores = this.addConnectedStores.bind(this);
      }

      componentDidMount() {
        this.connectStores(stores);
      }

      shouldComponentUpdate(nextProps, nextState) {
        const storesHaveChanged = this.storeChangeIds.reduce(
          (haveStoresChanged, changeId, storeName) => {
            const latestChangeId = getStoreByName(storeName).getChangeId();
            if (latestChangeId !== changeId) {
              this.storeChangeIds = this.storeChangeIds.set(storeName, latestChangeId);
              haveStoresChanged = true;
            }
            return haveStoresChanged;
          },
          false
        );

        if (
          storesHaveChanged ||
          !shallowEqual(this.props, nextProps) ||
          !shallowEqual(this.state, nextState)
        ) {
          return true;
        } else {
          return false;
        }
      }

      addConnectedStores(...stores) {
        this.connectStores(stores);
      }

      connectStores(storesArray) {
        this.storeChangeIds = storesArray.reduce((storeChangeIds, store) => {
          let storeInstance;
          if (typeof store === 'string') {
            storeInstance = getStoreByName(store);
            if (!storeInstance) {
              console.error(`Could not find a store with name ${store}`);
              return storeChangeIds;
            }
          } else {
            storeInstance = store;
          }

          return storeChangeIds.set(storeInstance.getName(), storeInstance.getChangeId());
        }, this.storeChangeIds);
      }

      render() {
        return (
          <DecoratedComponent {...this.props} addConnectedStores={this.boundAddConnectedStores} />
        );
      }
    };
}
