import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import {callAction} from 'client/framework';
import {buildStores} from 'client/InteractionEngineV2/InteractionEngine.util';
import interactionEngineActions from 'client/InteractionEngineV2/InteractionEngineActions';
import LoadingIndicator from 'generic/LoadingIndicator.react';
import Button from 'sg/Button/Button.react';
import {Section as SectionV1} from 'resources/GeneratedModels/Section/SectionModel.v1';
import {AssignmentModelV3} from 'resources/GeneratedModels/Assignment/AssignmentModel.v3';
import assignmentV2IEActions from '../AssignmentV2IE.actions';
import assignmentV2IEStore, {sectionStatuses} from '../AssignmentV2IE.store';
import questionsListActions from '../../../shared/QuestionsList/QuestionsListActions';
import stats from 'lib/StatsUtil';
import {addGenericErrorToast} from '@albert-io/atomic';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import appStore from 'client/AppStore';
import 'client/InteractionEngineV2/shared/AssignmentsV2SharedStyles/assignment-v2-ie-details.scss';

export default class AssignmentV2IEDetails extends React.Component {
  static propTypes = {
    assignment: PropTypes.any,
    isReady: PropTypes.bool,
    topBanner: PropTypes.node
  };

  componentDidMount() {
    if (process.env.IS_BROWSER) {
      global.scrollTo(0, 0);
    }
  }

  getActiveSectionId(assignment) {
    return assignment.isAssignmentOpenForStudent()
      ? assignmentV2IEStore.getOpenAssignmentActiveSectionId()
      : assignmentV2IEStore.getSelectedActiveSectionId();
  }

  setActiveSection = (sectionId) => {
    if (assignmentV2IEStore.getSelectedActiveSectionId() === sectionId) {
      return;
    }

    callAction(assignmentV2IEActions.SET_SELECTED_ACTIVE_SECTION_ID, sectionId);
    // When moving from section to section, wipe the currently active question or you will get into a spooky state.
    callAction(questionsListActions.SET_ACTIVE_QUESTION, null);
    callAction(questionsListActions.SET_ACTIVE_QUESTION_SET, null);
  };

  render() {
    if (!this.props.isReady) {
      return <LoadingIndicator />;
    }
    const {assignment} = this.props;
    const activeSectionId = this.getActiveSectionId(assignment);
    return (
      <div className='assignment-details'>
        {this.props.topBanner}
        <div className='assignment-details__card card card--no-padding'>
          <AssignmentSectionsList
            assignment={assignment}
            activeSectionId={activeSectionId}
            onSectionClick={this.setActiveSection}
          />
          <AssignmentContentArea assignment={assignment} activeSectionId={activeSectionId} />
        </div>
      </div>
    );
  }
}

class AssignmentSectionsList extends React.PureComponent {
  static propTypes = {
    assignment: PropTypes.instanceOf(AssignmentModelV3).isRequired,
    onSectionClick: PropTypes.func.isRequired,
    activeSectionId: PropTypes.string
  };

  render() {
    const {assignment, activeSectionId, onSectionClick} = this.props;
    const timeLimit = assignment.hasSections()
      ? assignment.getSections().reduce((acc, section) => acc + (section.getTimeLimit() || 0), 0)
      : assignment.getTimeLimit();
    const isAssignmentOpen = assignment.isAssignmentOpenForStudent();
    const isExamDetailsActive = activeSectionId === null;
    return (
      <div className='assignment-sections'>
        <div
          className={classnames('assignment-sections__overview', {
            'assignment-sections__overview--active': isExamDetailsActive
          })}
        >
          <div>
            <h2 className='assignment-sections__heading'>Assessment Details</h2>
            <div className='assignment-sections__about'>
              <span className='fa fa-clock-o' /> {timeLimit !== 0 ? `${timeLimit} min` : 'No Limit'}{' '}
              • {assignment.getMeta().getCountOfQuestions()} questions
            </div>
          </div>
          <ViewSectionButton
            isActive={isExamDetailsActive}
            sectionId={null}
            onSectionClick={this.props.onSectionClick}
          />
        </div>
        <ul className='assignment-sections__list'>
          {assignment.getSortedSections().map((section, i) => (
            <AssignmentSection
              key={i}
              section={section}
              activeSectionId={activeSectionId}
              onSectionClick={onSectionClick}
              isAssignmentOpen={isAssignmentOpen}
            />
          ))}
        </ul>
      </div>
    );
  }
}

class AssignmentSection extends React.PureComponent {
  static propTypes = {
    section: PropTypes.instanceOf(SectionV1).isRequired,
    onSectionClick: PropTypes.func.isRequired,
    activeSectionId: PropTypes.string,
    isAssignmentOpen: PropTypes.bool
  };

  render() {
    const {section, activeSectionId} = this.props;
    const isActive = activeSectionId === section.getId();
    const itemClass = classnames('assignment-sections__section', {
      'assignment-sections__section--active': isActive
    });
    const timeLimit = section.getTimeLimit();
    return (
      <li className={itemClass}>
        <div className='assignment-sections__details-wrapper'>
          <h3 className='assignment-sections__name'>
            <MarkdownRendererV2 text={section.getTitle()} />
          </h3>
          <div className='assignment-sections__about'>
            <span className='fa fa-clock-o' />
            &nbsp;
            {timeLimit ? `${timeLimit} min` : 'No Limit'} •{' '}
            {section.getMeta().getCountOfQuestions()} questions
          </div>
        </div>
        {this.props.isAssignmentOpen ? (
          <AssignmentSectionStatus section={section} />
        ) : (
          <AssignmentSectionResults section={section} />
        )}
        <ViewSectionButton
          isActive={this.props.activeSectionId === section.getId()}
          sectionId={section.getId()}
          onSectionClick={this.props.onSectionClick}
        />
      </li>
    );
  }
}

class AssignmentSectionStatus extends React.PureComponent {
  static propTypes = {
    section: PropTypes.instanceOf(SectionV1).isRequired
  };

  render() {
    const status = assignmentV2IEStore.getSectionCompletionStatus(this.props.section);
    const itemClass = classnames('assignment-sections__completion', {
      'assignment-sections__completion--complete': status === sectionStatuses.COMPLETE,
      'assignment-sections__completion--in-progress': status === sectionStatuses.IN_PROGRESS,
      'assignment-sections__completion--not-started': status === sectionStatuses.NOT_STARTED
    });
    const iconClass = classnames({
      'fa fa-check': status === sectionStatuses.COMPLETE
    });
    let statusCopy = null;
    if (status === sectionStatuses.COMPLETE) {
      statusCopy = 'Complete';
    } else if (status === sectionStatuses.IN_PROGRESS) {
      statusCopy = 'In progress';
    } else if (status === sectionStatuses.NOT_STARTED) {
      statusCopy = 'Not started';
    }
    return (
      <div className={itemClass}>
        <span className={iconClass} /> {statusCopy}
      </div>
    );
  }
}

class AssignmentSectionResults extends React.Component {
  static propTypes = {
    section: PropTypes.instanceOf(SectionV1).isRequired
  };

  getPercentageCorrect() {
    const sectionMeta = this.props.section.getMeta();
    return stats.percentage(
      sectionMeta.getStudentCountOfCorrectGuesses(),
      sectionMeta.getCountOfQuestions()
    );
  }

  render() {
    return <div className='assignment-section-accuracy'>{this.getPercentageCorrect()}%</div>;
  }
}

class AssignmentContentArea extends React.Component {
  static propTypes = {
    assignment: PropTypes.instanceOf(AssignmentModelV3).isRequired,
    activeSectionId: PropTypes.string
  };

  // @todo: Initializing for the continue case can be done on load of the IE. Maybe do that then.
  async handleStartContinueExam(e) {
    if (assignmentV2IEStore.isRequestPending()) {
      return;
    }
    // Dataset properties, so they are passed as strings
    const shouldDismissStaging = e.currentTarget.dataset.dismissStaging !== 'false';
    const shouldPrefetchLatestSection = e.currentTarget.dataset.prefetchLatestSection !== 'false';
    callAction(assignmentV2IEActions[e.currentTarget.dataset.action]);
    let hasError = false;
    await assignmentV2IEStore.getAssignmentPromise().catch((error) => {
      hasError = true;
      logger.error(error);
      addGenericErrorToast();
    });
    if (hasError) {
      return;
    }
    const assignment = assignmentV2IEStore.getAssignment();
    let latestStartedSection;
    if (shouldPrefetchLatestSection && !assignment.getSections().isEmpty()) {
      try {
        latestStartedSection = await assignmentV2IEStore
          .getLatestStartedSectionWithQuestionSetsQuery()
          .getResourcePromise();
      } catch (error) {
        logger.error(error);
        addGenericErrorToast();
        return;
      }
    }
    if (shouldDismissStaging) {
      callAction(assignmentV2IEActions.SET_IS_VIEWING_STAGING_DETAILS, false);
    }

    if (!shouldPrefetchLatestSection) {
      return;
    }
    // If assignment does not have sections, get the assignment questions and bootstrap
    let builtStores;
    const assignmentId = assignmentV2IEStore.getAssignmentId();
    if (assignment.getSections().isEmpty()) {
      builtStores = buildStores(assignment.getQuestionSets(), assignmentId);
    } else {
      builtStores = buildStores(latestStartedSection.getQuestionSets(), assignmentId);
    }
    callAction(interactionEngineActions.SET_BOOTSTRAPPED_STORES, builtStores);
    callAction(questionsListActions.SET_INITIAL_ACTIVE_QUESTION);
  }

  getActiveSection() {
    return this.props.assignment
      .getSortedSections()
      .find((section) => section.getId() === this.props.activeSectionId);
  }

  getExamActions() {
    if (!assignmentV2IEStore.getAssignment().isAssignmentOpenForStudent()) {
      return null;
    }
    const hasStartedAssignment =
      assignmentV2IEStore.getCurrentStudentAssignmentMeta().getStartTime() !== null;
    const assignment = assignmentV2IEStore.getAssignment();
    let buttonCopy;
    let action;
    let shouldDismissStaging = true;
    let shouldPrefetchLatestSection = true;
    const isFirstSectionStarted =
      assignment.hasSections() &&
      assignmentV2IEStore.hasStudentStartedSection(assignment.getSortedSections().first());

    if (!hasStartedAssignment) {
      buttonCopy = `Start ${
        assignment.getAssignmentType() === 'exam' ? 'assessment' : 'assignment'
      }`;
      action = 'START_ASSIGNMENT';
      shouldDismissStaging = false;
      shouldPrefetchLatestSection = false;
    } else if (!isFirstSectionStarted) {
      buttonCopy = 'Start section';
      action = 'START_NEXT_SECTION';
    } else if (assignment.hasSections() && assignmentV2IEStore.isLatestStartedSectionSubmitted()) {
      buttonCopy = 'Start section';
      action = 'START_NEXT_SECTION';
    } else if (assignmentV2IEStore.isLatestStartedSectionOutOfTime()) {
      buttonCopy = `Submit ${assignment.hasSections() ? 'section' : 'sssignment'}`;
      action = 'SUBMIT_CURRENT_SECTION';
      shouldDismissStaging = false;
    } else {
      buttonCopy = 'Continue';
      action = 'CONTINUE_ASSIGNMENT';
    }
    return (
      <Button
        color='green'
        data-action={action}
        data-dismiss-staging={shouldDismissStaging}
        data-prefetch-latest-section={shouldPrefetchLatestSection}
        onClick={this.handleStartContinueExam}
        disabled={assignmentV2IEStore.isRequestPending()}
      >
        {buttonCopy}
      </Button>
    );
  }

  render() {
    const {activeSectionId, assignment} = this.props;
    const isViewingSection = activeSectionId !== null;
    const activeItem = isViewingSection ? this.getActiveSection() : assignment;

    const message =
      !isViewingSection && activeItem.getMessage() ? (
        <div>
          <dt className='assignment-details__heading'>Message</dt>
          <dd className='assignment-details__content'>
            <MarkdownRendererV2 text={activeItem.getMessage()} />
          </dd>
        </div>
      ) : null;

    const description = activeItem.getDescription() ? (
      <div>
        <dt className='assignment-details__heading'>Description</dt>
        <dd className='assignment-details__content'>
          <MarkdownRendererV2 text={activeItem.getDescription()} />
        </dd>
      </div>
    ) : null;

    const instructions = activeItem.getInstructions() ? (
      <div>
        <dt className='assignment-details__heading'>Instructions</dt>
        <dd className='assignment-details__content'>
          <MarkdownRendererV2 text={activeItem.getInstructions()} />
        </dd>
      </div>
    ) : null;
    return (
      <div className='assignment-details__content-wrapper'>
        <h1 className='assignment-details__name'>
          <MarkdownRendererV2
            text={isViewingSection ? activeItem.getTitle() : activeItem.getName()}
          />
        </h1>
        <dl>
          {description}
          {instructions}
          {message}
        </dl>
        <div className='assignment-details__footer-wrapper'>
          {!assignmentV2IEStore.getAssignment().isAssignmentOpenForStudent() ? (
            <AssignmentDetailsStats assignment={assignment} activeItem={activeItem} />
          ) : (
            this.getExamActions(assignment)
          )}
        </div>
      </div>
    );
  }
}

class AssignmentDetailsStats extends React.Component {
  static propTypes = {
    assignment: PropTypes.instanceOf(AssignmentModelV3).isRequired,
    activeItem: PropTypes.any
  };

  getPercentageCorrect(activeItemMeta) {
    return stats.percentage(
      activeItemMeta.getStudentCountOfCorrectGuesses(),
      activeItemMeta.getCountOfQuestions()
    );
  }

  getTimeSpent(activeItemMeta) {
    const duration = moment.duration(activeItemMeta.getStudentTimeSpent());
    return `${duration.minutes()} min ${duration.seconds()} sec`;
  }

  render() {
    const {assignment, activeItem} = this.props;
    if (assignment.hasSections() && activeItem instanceof AssignmentModelV3) {
      return null;
    }
    const activeItemMeta = activeItem.getMeta();
    const timeLimit = activeItem.getTimeLimit();
    return (
      <dl className='assignment-details-stats'>
        <dt className='assignment-details-stats__term'>Grade:</dt>
        <dd className='assignment-details-stats__detail'>
          {this.getPercentageCorrect(activeItemMeta)}%
        </dd>
        <dt className='assignment-details-stats__term'>Time spent:</dt>
        <dd className='assignment-details-stats__detail'>
          {this.getTimeSpent(activeItem)} {timeLimit ? ` /  ${timeLimit} min` : ''}
        </dd>
        <dt className='assignment-details-stats__term'>Answered:</dt>
        <dd className='assignment-details-stats__detail'>
          {activeItemMeta.getStudentCountOfGuesses()} / {activeItemMeta.getCountOfQuestions()}{' '}
          questions
        </dd>
      </dl>
    );
  }
}

class ViewSectionButton extends React.Component {
  static propTypes = {
    isActive: PropTypes.bool,
    sectionId: PropTypes.any,
    onSectionClick: PropTypes.func
  };

  handleSectionChange = () => {
    if (this.props.isActive) {
      return;
    }
    this.props.onSectionClick(this.props.sectionId);
  };

  render() {
    if (appStore.isMobileView()) {
      return null;
    }
    const assignment = assignmentV2IEStore.getAssignment();
    if (
      // If the assignment is open, do not allow the student to view the details of the non-current section
      assignment.isAssignmentOpenForStudent() ||
      // If the assignment is closed, but we shouldn't show guess feedback, bail
      !assignment.shouldShowGuessFeedback()
    ) {
      return null;
    }
    return (
      <Button
        small
        className='assignment-section-view-btn'
        color={this.props.isActive ? 'translucent' : 'green'}
        disabled={this.props.isActive}
        onClick={this.handleSectionChange}
        onKeyDown={this.handleSectionChange}
        text='View'
      />
    );
  }
}
