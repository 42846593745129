import React, {useContext, createContext} from 'react';
import PropTypes from 'prop-types';
import {Set} from 'immutable';

import {Toggle, Anchor, Text} from '@albert-io/atomic';

import './option-eliminator.scss';

export const OptionEliminatorContext = createContext();

export class OptionEliminatorProvider extends React.Component {
  static propTypes = {
    children: PropTypes.node
  };

  static getInitialState = () => ({
    isEnabled: false,
    hideEliminatedOptions: false,
    questions: {
      /**
       * This map is meant to hold the options that are eliminated for a given
       * question, where the key is the questionId and the value is a shape that
       * is dictated by the question type. E.g. each question type may implement
       * its own shape for the eliminated options.
       */
    },
    eliminatedOptions: Set()
  });

  constructor() {
    super();
    this.state = {
      ...OptionEliminatorProvider.getInitialState(),
      /* eslint-disable react/no-unused-state */
      getQuestionState: this.getQuestionState,
      toggleIsEnabled: this.toggleIsEnabled,
      updateQuestionState: this.updateQuestionState,
      toggleHideEliminatedOptions: this.toggleHideEliminatedOptions
      /* eslint-enable react/no-unused-state */
    };
  }

  toggleIsEnabled = () => {
    this.setState(({isEnabled}) => {
      return {
        isEnabled: !isEnabled
      };
    });
  };

  toggleHideEliminatedOptions = () => {
    this.setState(({hideEliminatedOptions}) => ({
      hideEliminatedOptions: !hideEliminatedOptions
    }));
  };

  updateQuestionState = (questionId, updateFunc) => {
    this.setState(({questions}) => {
      return {
        questions: {
          ...questions,
          [questionId]: updateFunc(questions[questionId])
        }
      };
    });
  };

  getQuestionState = (questionId) => this.state.questions[questionId];

  render() {
    return (
      <OptionEliminatorContext.Provider value={this.state}>
        {this.props.children}
      </OptionEliminatorContext.Provider>
    );
  }
}

export const OptionEliminatorActions = () => {
  const {
    isEnabled,
    toggleIsEnabled,
    hideEliminatedOptions,
    toggleHideEliminatedOptions
  } = useContext(OptionEliminatorContext);
  return (
    <div className='option-eliminator-actions u-mar-b_2'>
      <Text bold size='m' as='label' className='option-eliminator-actions__toggle-wrapper'>
        <span className='u-mar-r_2'>Elimination Tool</span>
        <Toggle checked={isEnabled} onClick={toggleIsEnabled} />
      </Text>
      {isEnabled && (
        <Anchor as='button' onClick={toggleHideEliminatedOptions}>
          {hideEliminatedOptions ? 'Show' : 'Hide'} eliminated answers
        </Anchor>
      )}
    </div>
  );
};
