/**
 * # ButtonGroup
 *
 * A component that displays a group of Button atoms in a horizontal or vertical layout.
 *
 * ## Props
 *
 * - `align` (optional): Specifies the horizontal alignment of the button group. Can be one of `'center'`, `'right'`, or `'left'`.
 * - `children`: The buttons or elements to be rendered inside the button group.
 * - `className` (optional): Additional CSS class names to be applied to the button group container.
 * - `description` (optional): A description for the button group, used for accessibility purposes.
 * - `direction` (optional): Specifies the direction of the button group. Can be one of `'row'` or `'column'`.
 * - `fillColumn` (optional): If `true`, the button group will fill the entire vertical space when the direction is set to `'column'`.
 * - `noFill` (optional): If `true`, the button group will not fill the available space.
 * - `label` (optional): A label for the button group, used for accessibility purposes.
 * - `positionAtBottom` (optional): If `true`, the button group will be positioned at the bottom of its container.
 *
 * ## Usage
 *
 * ```tsx
 * import React from 'react';
 * import ButtonGroup from './ButtonGroup';
 *
 * const MyComponent = () => {
 *   return (
 *     <ButtonGroup
 *       align="center"
 *       direction="row"
 *       fillColumn={false}
 *       noFill={false}
 *       positionAtBottom={false}
 *     >
 *       <Button>Button 1</Button>
 *       <Button>Button 2</Button>
 *       <Button>Button 3</Button>
 *     </ButtonGroup>
 *   );
 * };
 *
 * export default MyComponent;
 * ```
 */

import React from 'react';
import classnames from 'classnames';
import makeConstants from 'lib/makeConstants';
import './button-group.scss';

const justifyProps = {
  center: 'center',
  right: 'flex-end',
  left: 'flex-start'
};

const directionProps = makeConstants('row', 'column');

interface ButtonGroupProps extends PropsWithChildrenRequired, PropsWithClassNameOptional {
  align?: keyof typeof justifyProps;
  description?: string;
  direction?: keyof typeof directionProps;
  fillColumn?: boolean;
  noFill?: boolean;
  label?: string;
  positionAtBottom?: boolean;
}

export const ButtonGroup = ({
  align,
  children,
  className,
  description,
  direction = 'row',
  fillColumn,
  noFill,
  label,
  positionAtBottom
}: ButtonGroupProps) => (
  <div
    className={classnames('btn-group', {
      [`u-justify-content_${justifyProps[align as string]}`]: align,
      'btn-group--position-at-bottom': positionAtBottom
    })}
  >
    <div
      aria-describedby={description}
      aria-label={label}
      className={classnames('btn-group__items-wrapper', className, {
        [`btn-group__items-wrapper--${direction}`]: directionProps[direction],
        'btn-group__items-wrapper--no-fill': noFill,
        'btn-group__items-wrapper--column-fill': fillColumn
      })}
      role='group'
    >
      {React.Children.toArray(children).map((child) => {
        return React.isValidElement(child)
          ? React.cloneElement(child, {
              className: classnames(child.props.className, 'btn-group__item', {
                [`btn-group__item--${direction}`]: directionProps[direction]
              })
            } as PropsWithClassNameRequired)
          : null;
      })}
    </div>
  </div>
);

export default ButtonGroup;
