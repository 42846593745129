import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import validator from 'validator';
import notifier from '@albert-io/notifier';
import {consentOptionPropTypes} from 'client/Onboarding/Steps/ParentalConsent/SelectConsentOption/SelectConsentOption.react';
import TextInput from 'client/generic/Forms/TextInput/TextInput';
import OldButton from 'sg/Button/Button.react';
import {addToast, Button, Grid, Heading, Text} from '@albert-io/atomic';
import awaitMandarkQueries from 'lib/hocs/awaitMandarkQueries';
import {resource} from '@albert-io/json-api-framework/request/builder';
import {invalidatePartialInterest} from 'resources/mandark.resource';
import {CoppaConsentGrantModelV1} from 'resources/GeneratedModels/CoppaConsentGrant/CoppaConsentGrantModel.v1';
import './parental-consent.scss';

import 'client/Onboarding/Steps/ParentalConsent/SelectConsentOption/select-consent-option-shared.scss';

function getConsentGrantQuery(userId) {
  return resource('coppa_consent_grants_v1')
    .mandarkEndpoint(['users_v2', userId, 'coppa_consent_grants_v1'])
    .filter({
      consent_method: 'email'
    })
    .sort('-inserted_at');
}

export default awaitMandarkQueries(
  (props) => ({
    queries: {
      consentGrants: getConsentGrantQuery(props.user.getId())
    }
  }),
  class ParentalConsent extends React.Component {
    static propTypes = {
      ...consentOptionPropTypes,
      consentGrants: ImmutablePropTypes.listOf(PropTypes.instanceOf(CoppaConsentGrantModelV1))
        .isRequired
    };

    state = {
      error: null
    };

    async saveUser(user) {
      try {
        await user.save();
        this.props.onComplete();
        addToast({
          message: `We've sent your parent an email. Once they approve your request, you can use Albert.`,
          title: 'Success!',
          color: 'positive'
        });

        /**
         * @todo: this could be made unneccesary with a controlled input
         */
        invalidatePartialInterest({
          resourcePath: ['users_v2', user.getId(), 'coppa_consent_grants_v1']
        });
      } catch (e) {
        addToast({
          message: e.response.body.errors[0].detail,
          title: 'Error',
          color: 'negative'
        });
        notifier.notify(e, {
          component: 'ParentalConsent',
          name: 'Onboarding: failed to set parental consent email',
          userId: user.getId()
        });
      }
    }

    onFocus = () => {
      if (this.state.error) {
        this.setState({error: null});
      }
    };

    onSubmit = () => {
      const consentEmail = this.textInputNode.value;
      if (!validator.isEmail(consentEmail)) {
        this.setState({
          error: 'Invalid email address'
        });
        return;
      }
      if (consentEmail === this.props.user.getEmail()) {
        this.setState({
          error: 'That email is already associated to your account'
        });
        return;
      }

      const user = this.props.user.setCoppaConsentEmail(consentEmail);
      this.saveUser(user);
    };

    render() {
      const coppaGrant = this.props.consentGrants.first();

      const parentalConsentEmail = coppaGrant ? coppaGrant.getConsentEmail() : '';
      return (
        <Grid.Container>
          <Grid.Row justify='center'>
            <Grid.Col className='parental-consent' xs={12} all={6}>
              <Grid.Container nested>
                <Grid.Row justify='flex-start'>
                  <Grid.Col all={2} xs={12}>
                    <Button
                      as='div'
                      className='consent-option-back-button'
                      variant='text'
                      onClick={this.props.clearConsentState}
                    >
                      <span className='fa fa-arrow-left' />
                      Back
                    </Button>
                  </Grid.Col>
                </Grid.Row>
              </Grid.Container>
              <Grid.Container nested>
                <Grid.Row justify='center'>
                  <Grid.Col className='consent-option-copy' all={6} xs={12}>
                    <Heading as='h2'>Request parental permission</Heading>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row justify='center'>
                  <Grid.Col className='consent-option-copy' all={6} xs={12}>
                    <Text as='p'>
                      Tell us your parent&#39;s email and we&#39;ll ask them if it&#39;s okay for
                      you to use Albert.
                    </Text>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row justify='center'>
                  <Grid.Col xs={12} all={6} className='parental-consent__input-wrapper'>
                    <TextInput
                      className='parental-consent__input'
                      value={parentalConsentEmail}
                      placeholder='Enter email'
                      onFocus={this.onFocus}
                      error={Boolean(this.state.error)}
                      errorMessage={this.state.error}
                      inputNodeRef={(ref) => {
                        this.textInputNode = ref;
                      }}
                    />
                    <OldButton
                      className='parental-consent__button'
                      text={parentalConsentEmail ? 'Resend' : 'Submit'}
                      onClick={this.onSubmit}
                    />
                  </Grid.Col>
                </Grid.Row>
              </Grid.Container>
            </Grid.Col>
          </Grid.Row>
        </Grid.Container>
      );
    }
  }
);
