import {TemplateModelV1, QuestionSetModelV1} from '@albert-io/models';
import {StateCreator} from 'zustand';
import {List, Map} from 'immutable';

import sessionStore from 'client/Session/SessionStore';
import {
  getFolder,
  getFolderQuestionSetsQuery
} from 'client/FolderPracticeView/folderPracticeView.queries';
import type {ContentDiscoveryStoreState} from 'client/components/ContentDiscovery/store/ContentDiscoveryStore.types';

import {invalidatePartialInterest} from 'resources/mandark.resource';

type FolderState = {
  folder: null | TemplateModelV1;
  savedQuestionSets: List<QuestionSetModelV1>;
  folderId: null | string;
  isFolderOwner: boolean;
  isLoadingFolder: boolean;
  errorLoadingFolder: boolean;
};

type FolderActions = {
  setFolderId: (folderId: string) => void;
  setFolder: (folder: TemplateModelV1) => void;
  // setIsLoadingFolder: (isLoading: boolean) => void;

  /* async operations */
  fetchAndSetFolderOrError: (folderId: string) => Promise<fetchFolderReturnObj>;
  saveFolder: (questionSets: List<QuestionSetModelV1>) => Promise<boolean>;
  reset: () => void;
};

type fetchFolderReturnObj = {
  folder: FolderState['folder'];
  questionSets: FolderState['savedQuestionSets'];
  isFolderOwner: FolderState['isFolderOwner'];
  errorLoadingFolder: FolderState['errorLoadingFolder'];
  error?: any;
};

const initialState = {
  folderId: null,
  folder: null,
  savedQuestionSets: List<QuestionSetModelV1>(),
  isFolderOwner: false,
  isLoadingFolder: false,
  errorLoadingFolder: false
};

export type FolderStoreSlice = FolderState & FolderActions;

export const createFolderStoreSlice: StateCreator<
  ContentDiscoveryStoreState,
  [['zustand/immer', never]],
  [],
  FolderStoreSlice
> = (set, get, _): FolderStoreSlice => ({
  ...initialState,
  setFolderId: (folderId) =>
    set((state) => {
      state.folderSlice.folderId = folderId;
    }),
  setFolder: (folder) =>
    set((state) => {
      state.folderSlice.folder = folder;
    }),
  fetchAndSetFolderOrError: async (folderId) => {
    set((state) => {
      state.folderSlice.isLoadingFolder = true;
    });
    const returnObj: fetchFolderReturnObj = {
      folder: null,
      questionSets: List(),
      isFolderOwner: false,
      errorLoadingFolder: false
    };

    try {
      const [folderResult, questionSets] = await Promise.all([
        getFolder(folderId),
        getFolderQuestionSetsQuery(folderId, 'infinite').getResourcePromise()
      ]);
      set((state) => {
        state.folderSlice.folder = folderResult;
        state.folderSlice.savedQuestionSets = questionSets;
        state.folderSlice.isFolderOwner =
          sessionStore.getUserId() === folderResult.getMeta().getOwnerId();
      });
      returnObj.folder = folderResult;
      returnObj.questionSets = questionSets;
    } catch (err) {
      set((state) => {
        state.folderSlice.errorLoadingFolder = true;
      });
      returnObj.errorLoadingFolder = true;
      returnObj.error = err;
    } finally {
      set((state) => {
        state.folderSlice.isLoadingFolder = false;
      });
    }
    return returnObj;
  },
  saveFolder: async (questionSets) => {
    set((state) => {
      state.folderSlice.isLoadingFolder = true;
    });
    let saveSuccessfull = false;
    try {
      const {folder} = get().folderSlice;

      const questionSetPositions = questionSets.reduce(
        (map, questionSet, index) => map!.set(questionSet.getId(), {position: index}),
        Map()
      );

      const updatedFolder = await folder
        .replaceRelationship({
          type: 'question_sets_v1',
          relation: questionSets,
          customMeta: questionSetPositions
        })
        .save();

      invalidatePartialInterest({resourcePath: ['templates_v1', updatedFolder.getId()]});
      invalidatePartialInterest({
        resourcePath: ['templates_v1', updatedFolder.getId(), 'question_sets_v1']
      });
      set((state) => {
        state.folderSlice.folder = updatedFolder;
        state.folderSlice.savedQuestionSets = questionSets;
      });
      saveSuccessfull = true;
    } catch (err) {
      console.log(err);
      // toast ?? or set saving error
    } finally {
      set((state) => {
        state.folderSlice.isLoadingFolder = false;
      });
    }
    return saveSuccessfull;
  },

  reset: () =>
    set((state) => {
      state.folderSlice = {...state.folderSlice, ...initialState};
    })
});
