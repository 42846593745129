import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {callTargetedAction} from 'client/framework';
import GraphingInput, {modes, flavorTypes} from 'components/GraphingInput/GraphingInput.react';
import {renderToolbar} from 'components/QuestionTypes/Graphing/Toolbar/Toolbar.react';
import {getModelForResourceType} from 'resources/modelRegistry';

import graphingQuestionActions from '../GraphingQuestion.actions';

import {useMaxGraphDimensions} from './graphingQuestionInput.utils';

import './graphing-question-input.scss';

function updateGuessEntities(storeName, entities) {
  const guessEntities = entities
    .filter(({flavor}) => flavor === 'studentGuess')
    .map(({flavor, ...rest}) => rest);

  callTargetedAction({
    name: graphingQuestionActions.SET_GUESS_ENTITIES,
    payload: guessEntities,
    targetStore: storeName
  });
}

function getSolutionEntities({question, guess}) {
  if (!guess?.existsOnServer()) {
    return question
      .getValidResponse()
      .toJS()
      .map(({correctAnswerSettings, ...rest}) => rest);
  }

  return guess
    .getCorrectnessContext()
    .toJS()
    .solution_entities.map(({correctAnswerSettings, ...rest}) => rest);
}

const GraphingQuestionInput = ({
  draftGuessEntities,
  guess,
  isExplanation,
  mode,
  question,
  storeName
}) => {
  const isReviewing = mode === modes.reviewing;

  const {
    allowed_entities: allowedEntities,
    label_bottom: labelBottom,
    label_left: labelLeft,
    provided_entities: providedEntities,
    x_interval: xInterval,
    x_label: xLabel,
    x_max: xMax,
    x_min: xMin,
    y_interval: yInterval,
    y_label: yLabel,
    y_max: yMax,
    y_min: yMin
  } = question.getQuestionTypeAttributes().get('graph_contains').toJS();

  const {wrapperRef, ready, maxHeight, maxWidth} = useMaxGraphDimensions({
    hasLeftLabel: !!labelLeft,
    hasBottomLabel: !!labelBottom
  });

  let initialEntities = providedEntities.map((entity) => ({
    ...entity,
    flavor: 'authorProvided'
  }));

  const handleChange = useCallback(
    (entities) => updateGuessEntities(storeName, entities),
    [storeName]
  );

  if (isExplanation) {
    const solutionEntities = getSolutionEntities({question, guess}).map((entity) => ({
      ...entity,
      flavor: 'solution'
    }));

    initialEntities = initialEntities.concat(solutionEntities);
  } else if (isReviewing && !guess?.existsOnServer()) {
    /**
     * Teacher or admin is viewing the correct answer,
     * so show them the solution as the correct answer
     */
    const correctEntities = getSolutionEntities({question}).map((entity) => ({
      ...entity,
      flavor: 'correctAnswer'
    }));

    initialEntities = initialEntities.concat(correctEntities);
  } else if (isReviewing) {
    const studentEntities = guess.getCorrectnessContext().toJS().evaluated_entities || [];

    const {correct, incorrect} = studentEntities.reduce(
      (acc, evaluatedEntity) => {
        acc[evaluatedEntity.correct ? 'correct' : 'incorrect'].push(evaluatedEntity.submitted);
        return acc;
      },
      {
        correct: [],
        incorrect: []
      }
    );

    const correctEntities = correct.map((entity) => ({
      ...entity,
      flavor: 'correctAnswer'
    }));

    const incorrectEntities = incorrect.map((entity) => ({
      ...entity,
      flavor: 'incorrectAnswer'
    }));

    initialEntities = initialEntities.concat(correctEntities, incorrectEntities);
  } else if (draftGuessEntities.length > 0) {
    initialEntities = initialEntities.concat(
      draftGuessEntities.map((draftGuessEntity) => ({...draftGuessEntity, flavor: 'studentGuess'}))
    );
  }

  return (
    <div ref={wrapperRef}>
      {ready && (
        <GraphingInput
          id={`${storeName}${isExplanation ? '-explanation' : ''}${guess?.getId() || ''}`}
          className='graphing-question-input'
          mode={mode}
          currentFlavor={flavorTypes.studentGuess}
          renderToolbar={isReviewing ? () => null : renderToolbar}
          boundingBox={[xMin, yMax, xMax, yMin]}
          xTickInterval={xInterval}
          yTickInterval={yInterval}
          xAxisLabel={xLabel}
          yAxisLabel={yLabel}
          allowedEntities={allowedEntities}
          initialEntities={initialEntities}
          labels={{
            bottom: labelBottom,
            left: labelLeft
          }}
          onChange={handleChange}
          maxHeight={maxHeight}
          maxWidth={maxWidth}
          draftGuessEntities={draftGuessEntities}
        />
      )}
    </div>
  );
};

GraphingQuestionInput.propTypes = {
  draftGuessEntities: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      elements: PropTypes.object.isRequired,
      attributes: PropTypes.object
    })
  ),
  guess: PropTypes.instanceOf(getModelForResourceType('guess_v1')),
  isExplanation: PropTypes.bool,
  mode: PropTypes.oneOf(Object.values(modes)).isRequired,
  question: PropTypes.instanceOf(getModelForResourceType('question_v3')).isRequired,
  storeName: PropTypes.string.isRequired
};

export default GraphingQuestionInput;
