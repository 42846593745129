import React from 'react';
import PropTypes from 'prop-types';
import {Map} from 'immutable';
import {getSupplementTypeDefinition} from 'client/SupplementTypes/SupplementTypeDefinitions';
import supplementStore from 'client/Supplement/SupplementStore';
import supplementActions from 'client/Supplement/SupplementActions';
import {LoadingSpinner, Text} from '@albert-io/atomic';

import {callAction} from '../framework';

export default class SupplementRenderer extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    attributes: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      error: false,
      isReady: supplementStore.hasSupplement(props.id)
    };
    if (this.state.isReady === false) {
      this.bootstrapSupplement();
    }
  }

  bootstrapSupplement() {
    callAction(supplementActions.FETCH_SUPPLEMENT, this.props.id);
    supplementStore
      .getFetchSupplementPromise()
      .then(() => this.setState({isReady: true}))
      .catch(() => {
        this.setState(() => ({
          error: true,
          isReady: true
        }));
      });
  }

  render() {
    if (!this.state.isReady || this.state.error) {
      const contents = this.state.error ? (
        <Text color='negative'>There was a problem loading the attached supplement.</Text>
      ) : (
        <LoadingSpinner assistiveText='Loading question supplement...' size={2} />
      );
      return <div className='u-display_flex u-justify-content_center'>{contents}</div>;
    }
    const supplement = supplementStore.getSupplement(this.props.id);
    const Renderer = getSupplementTypeDefinition(supplement.get('type')).get('renderer');
    return (
      <Renderer
        id={supplement.getId()}
        name={supplement.get('name')}
        content={supplement.get('content', Map())}
        supplement={supplement}
        attributes={this.props.attributes}
      />
    );
  }
}
