import {history} from 'client/history';
import {getQueueNewQuestionSetPath} from 'client/Dennis/Content/Queue/shared';
import questionEditorStore from 'client/EditPage/V2/QuestionEditor/QuestionEditor.store';
import {addToast} from '@albert-io/atomic';

// eslint-disable-next-line import/prefer-default-export
export const deleteQuestionSet = async (questionSet) => {
  /**
   * If this was a new question that was left unsaved,
   * we must skip the usual deletion behavior.
   */
  if (!questionSet.existsOnServer()) {
    return;
  }

  const redirect = getQueueNewQuestionSetPath();

  try {
    await questionSet.delete();
    questionEditorStore.resetStore();
    history.pushState(null, redirect);
  } catch (err) {
    addToast({
      color: 'negative',
      title: 'Error',
      message: 'Unable to delete question set'
    });
  }
};
