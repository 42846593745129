import React from 'react';
import PropTypes from 'prop-types';
import SimpleMarkdown from 'simple-markdown';

/**
 * @example
 * [center]
 * Hello world.
 *
 * I feel like a pickle.
 * [/center]
 */

const regex = /^\[center]((?:[\w\W](?!\[\/center]))+[\w\W])\[\/center]/;

const textAlignCenterRule = {
  order: SimpleMarkdown.defaultRules.paragraph.order - 0.5,
  match: (source) => regex.exec(source),
  parse: (capture, recurseParse, state) => {
    const [, content] = capture;
    return {
      content: recurseParse(`${content}\n`, state)
    };
  },
  react({content}, output, {key}) {
    return (
      <div key={key} className='u-text-align_center'>
        {output(content)}
      </div>
    );
  }
};

textAlignCenterRule.react.propTypes = {
  content: PropTypes.node
};

export default textAlignCenterRule;
