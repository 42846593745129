// @flow
import {List} from 'immutable';
import {GenericListCollectionModel} from '../Generic/GenericCollection.model';
import {stats, BUCKETS} from 'lib/StatsUtil';
import type {StudentModelV2} from 'resources/GeneratedModels/Student/StudentModel.v2';

function makeBucketFilterFunc(bucket): Function {
  return function(): List<*> {
    return this.filter((student) => {
      return (
        student.getMeta().getCountOfGuesses() > 0 &&
        stats.inBucket(bucket, student.getAccuracyFromMeta())
      );
    });
  };
}

const methods = {
  /* eslint-disable sort-keys */
  getStrugglingStudents: makeBucketFilterFunc(BUCKETS.STRUGGLING),
  getPassingStudents: makeBucketFilterFunc(BUCKETS.PASSING),
  getProficientStudents: makeBucketFilterFunc(BUCKETS.PROFICIENT),
  getExcellingStudents: makeBucketFilterFunc(BUCKETS.EXCELLING),
  getMasteryStudents: makeBucketFilterFunc(BUCKETS.MASTERY),

  getActiveStudents(): List<StudentModelV2> {
    return this.filter((student) => student.getMeta().getCountOfGuesses() > 0);
  },

  getActiveStudentsCount(): number {
    return this.getActiveStudents().count();
  },

  getInactiveStudentsCount(): number {
    return this.size - this.getActiveStudentsCount();
  },

  getTotalNumberOfAttempts(): number {
    return this.reduce((acc, student) => acc + student.getMeta().getCountOfGuesses(), 0);
  },

  getTotalPointsEarned(): number {
    return this.reduce((acc, student) => acc + student.getMeta().getSumOfGuessesPointsEarned(), 0);
  },

  getActiveStudentsAverageAccuracy({round}: {round: ?boolean} = {round: true}): number {
    const accuracies = this.getActiveStudents().map((student) => student.getAccuracyFromMeta());
    const average = stats.average(...accuracies);
    return round ? Math.round(average) : average;
  },

  getTotalTimeElapsed(): number {
    return this.reduce((acc, student) => acc + student.getMeta().getSumOfGuessesTimeElapsed(), 0);
  },

  sortedByAccuracy(): List<*> {
    /**
     * 'raw_accuracy' is not somethign we can currently sort by, so if we call this method,
     * we'll sort on the front-end. Also, because sortBy returns a new List, we'll need to
     * cast it into the StudentsListCollectionModel again
     */
    const students = this.sortBy(function(student): List<StudentModelV2> {
      return student.getAccuracyFromMeta() * -1;
    });
    return new StudentsListCollectionModel(students);
  },

  getMaxNumberOfAttempts(): number {
    const studentWithMaxNumberOfAttempts = this.maxBy((student) =>
      student.getMeta().getMaxAttemptNumber()
    );
    return studentWithMaxNumberOfAttempts
      ? studentWithMaxNumberOfAttempts.getMeta().getMaxAttemptNumber()
      : 0;
  }
  /* eslint-enable sort-keys */
};

export function StudentsListCollectionModel(students: List<StudentModelV2> = new List()): List<*> {
  const collection = GenericListCollectionModel(students);

  Object.keys(methods).forEach(function(methodName) {
    collection[methodName] = function(): any {
      const method = methods[methodName].bind(collection);
      if (collection.isEmpty()) {
        // If our collection is empty (e.g. if the data hasn't resolved), we'll return the normal method call since we don't want
        // to cache the result of our function call that was called on empty data
        return method();
      }
      return this.cache(methodName, method);
    };
  });

  return collection;
}
