import {createActions} from 'client/framework';

export default createActions(null, [
  'ADD_PAGE_TO_FETCH',
  'COPY_QUESTION_SETS',
  'SET_PAGE_NUMBER',
  'SINGLE_SET_MODE',
  'QUESTION_SET_STORES_BUILT',
  'UPDATE_ROUTE'
]);
