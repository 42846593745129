import React, {useCallback} from 'react';
import {Map, List} from 'immutable';
import Button from 'sg/Button/Button.react';

import SupplementManagerModalButton from 'client/Supplements/SupplementManager/SupplementManagerModalButton.react';
import {AuthoringQuestionModelV1} from 'resources/augmented/AuthoringQuestion/AuthoringQuestionModel.v1';
import AuthoringTextInput from 'client/EditPage/V2/AuthoringTextInput/AuthoringTextInput.react';

import {useQuestionEditorV2Store} from 'client/EditPage/V2/QuestionEditorV2Store';
import {useShallow} from 'zustand/react/shallow';

import {PRIMARY_CONTENT_LANGUAGE} from 'client/EditPage/V2/QuestionEditorV2Store.types';

import TwoWayRowColumn from './TwoWayRowColumn';

interface Props {
  row: any;
  rowIndex: number;
  question: AuthoringQuestionModelV1;
  onBlur: (e: any) => void;
  onModelChange: (question: AuthoringQuestionModelV1, ...fields: string[]) => void;
  validationErrors: Map<string, string>;
}

const TwoWayRow = ({row, rowIndex, question, onBlur, onModelChange, validationErrors}: Props) => {
  const {currentLanguage, translatedStatement, updateTranslatedQuestionField} =
    useQuestionEditorV2Store(
      useShallow((state) => ({
        currentLanguage: state.currentLanguage,
        updateTranslatedQuestionField: state.updateTranslatedQuestionField,
        translatedStatement:
          state
            .currentTranslatedQuestion()
            ?.translated_fields?.find((field) => field.field === `rows.${rowIndex}.statement`)
            ?.text || ''
      }))
    );
  const isPrimaryLanguage = currentLanguage === PRIMARY_CONTENT_LANGUAGE;

  const removeRow = useCallback(() => {
    const rows = question.getRows();

    const columnIds = List()
      .push(rows.getIn([rowIndex, 'columns', 'left', 'id']))
      .push(rows.getIn([rowIndex, 'columns', 'right', 'id']));

    const validResponse = question
      .getValidResponse()
      .filterNot((_value, key) => columnIds.includes(key));

    const updatedRows = rows
      .remove(rowIndex)
      .sortBy((r) => r.get('index'))
      .map((r, index) => r.set('index', index));

    const updatedQuestion = question.setValidResponse(validResponse).setRows(updatedRows);

    onModelChange(updatedQuestion, 'rows');
  }, [question, rowIndex, onModelChange]);

  const onStatementChange = useCallback(
    (e) => {
      if (isPrimaryLanguage) {
        const rows = question.getRows().setIn([rowIndex, 'statement'], e.target.value);

        const updatedQuestion = question.setRows(rows);
        onModelChange(updatedQuestion);
      } else {
        updateTranslatedQuestionField(
          currentLanguage,
          `rows.${rowIndex}.statement`,
          'text',
          e.target.value
        );
      }
    },
    [
      question,
      rowIndex,
      onModelChange,
      currentLanguage,
      updateTranslatedQuestionField,
      isPrimaryLanguage
    ]
  );

  const generateRowColumn = useCallback(
    (side: 'left' | 'right') => {
      const column = row.getIn(['columns', side]);

      return (
        <TwoWayRowColumn
          rowIndex={rowIndex}
          side={side}
          column={column}
          question={question}
          onBlur={onBlur}
          onModelChange={onModelChange}
        />
      );
    },
    [row, rowIndex, question, onBlur, onModelChange]
  );

  const rowError = validationErrors.getIn(['rubric', row.get('id')]);
  const fieldPath = `rows,${rowIndex},statement`;
  const isFirstRow = rowIndex === 0;

  const statementValue = isPrimaryLanguage ? row.get('statement') : translatedStatement;

  return (
    <div className='two-way-editor__row-wrapper'>
      <div className='two-way-editor__row two-way-editor__row--nowrap'>
        {generateRowColumn('left')}
        {generateRowColumn('right')}
        <div className='two-way-editor__column two-way-editor__column--statement'>
          <div className='two-way-editor__row two-way-editor__row--statement-input two-way-editor__row-statement'>
            {isFirstRow ? <div className='a-form-input__label'>Row Prompt</div> : null}
            <AuthoringTextInput
              height='auto'
              value={statementValue}
              onChange={onStatementChange}
              onBlur={onBlur}
              data-field-path={fieldPath}
              className='two-way-editor__row-statement-input'
              name='statement'
            />
            {isPrimaryLanguage && (
              <SupplementManagerModalButton
                className='two-way-editor__add-supplement-btn two-way-editor__row-add-supplement-btn two-way-editor__row-add-supplement-btn--statement'
                color='blue'
                linkButton
                pathToProperty={fieldPath}
              />
            )}
          </div>
        </div>
        {isPrimaryLanguage && (
          <div>
            {isFirstRow ? <div className='a-form-input__label'>&nbsp;</div> : null}
            <Button onClick={removeRow} className='fa fa-times' color='red' outline noBorder />
          </div>
        )}
      </div>
      {isPrimaryLanguage && rowError ? <div className='a-form-input__error'>{rowError}</div> : null}
    </div>
  );
};

export default TwoWayRow;
