import React from 'react';
import PropTypes from 'prop-types';
import {Banner} from '@albert-io/atomic';
import {getModelForResourceType} from 'resources/modelRegistry';

import {isGuessOrDraftGuessEmpty} from '../../../../AssignmentPracticeView/assignmentPracticeView.utils';

const ResultIndicatorBar = ({guess, additionalInfo}) => {
  const isCorrect = guess.isCorrect();
  // If a guess doesn't have an ID, we can assume it doesn't
  // exist in the DB, so the guess was never submitted.
  const isUnsubmitted = !guess.getId() || isGuessOrDraftGuessEmpty(guess);
  let bannerCopy = isCorrect ? 'Correct' : 'Incorrect';
  if (isUnsubmitted) {
    bannerCopy = 'No answer was submitted for this question';
  }
  return (
    <div className='u-mar-b_4'>
      <Banner type={isCorrect ? 'positive' : 'negative'}>
        <Banner.Icon icon={isCorrect ? 'check' : 'times'} />
        {bannerCopy
          .concat(additionalInfo || isUnsubmitted ? '. ' : '')
          .concat(additionalInfo || '')}
      </Banner>
    </div>
  );
};

ResultIndicatorBar.propTypes = {
  guess: PropTypes.instanceOf(getModelForResourceType('guess_v1')),
  additionalInfo: PropTypes.string
};

export {ResultIndicatorBar};
