import React, {useState, useEffect} from 'react';
import {resource} from '@albert-io/json-api-framework/request/builder';
import './attribution-info.scss';
import FeatureFlag from 'client/components/FeatureFlag/FeatureFlag.react';
import {Anchor, Text} from '@albert-io/atomic';

function getQuestionSetQuery(questionSetId) {
  return resource('authoring_question_set_v1')
    .mandarkEndpoint(['authoring_question_sets_v1', questionSetId])
    .include('authoring_questions_v1.authoring_supplements_v1,author_user_v2');
}

interface AttributionInfoProps {
  questionSetId: string;
}

const AttributionInfo = ({questionSetId}: AttributionInfoProps) => {
  const [author, setAuthor] = useState<any | null>(null);

  useEffect(() => {
    (async () => {
      if (questionSetId) {
        const query = getQuestionSetQuery(questionSetId);
        const result = await query.getResourcePromise();
        setAuthor(result.getAuthorUser());
      }
    })();
  }, [questionSetId]);

  if (!questionSetId || !author || author.isEmpty()) {
    return null;
  }

  return (
    <FeatureFlag name='question_editor_v2'>
      <Text size='s'>
        {author.getDisplayName()}
        <Anchor
          className='content-tertiary u-text-decoration_none u-mar-l_1'
          href={`mailto:${author.getEmail()}`}
        >
          ({author.getEmail()})
        </Anchor>
      </Text>

      <FeatureFlag.Fallback>
        <div className='author-attribution-container'>
          <div className='author-attribution__info'>
            <div className='author-attribution__info-author'>
              <div className='author-attribution__info-author-info'>
                {author.getDisplayName()}{' '}
                <span className='author-name'>({author.getUsername()})</span>
              </div>
              <div className='author-attribution__info-author-email'>
                <a href={`mailto:${author.getEmail()}`}>{author.getEmail()}</a>
              </div>
            </div>
          </div>
        </div>
      </FeatureFlag.Fallback>
    </FeatureFlag>
  );
};

export default AttributionInfo;
