import React from 'react';
import {fromJS, List} from 'immutable';
import CheckboxGroup from 'client/generic/CheckboxGroup/CheckboxGroup.react';
import {
  SEARCH_PARAMETERS,
  getURLSearchParameter,
  setURLSearchParameter
} from '../URLSearchParameters';
import {getStoreByName} from 'client/framework';

export class DifficultySelector extends React.Component {
  constructor() {
    super();
    const param = getURLSearchParameter(SEARCH_PARAMETERS.difficulty);
    this.defaultValue = List(
      param !== null ? param.split(',') : SEARCH_PARAMETERS.difficulty.default().split(',')
    );
  }

  storeName = 'QueueFacet--DifficultySelectorStore';

  options = fromJS([
    {
      label: 'Easy',
      value: 1
    },
    {
      label: 'Moderate',
      value: 2
    },
    {
      label: 'Difficult',
      value: 3
    }
  ]);

  handleChange() {
    setURLSearchParameter(
      SEARCH_PARAMETERS.difficulty,
      getStoreByName(this.storeName)
        .getSelectedOptions()
        .join(',')
    );
  }

  render() {
    return (
      <label className='queue-search-panel__facet'>
        <div className='form__label-text'>Difficulty</div>
        <CheckboxGroup
          startSelected
          data={this.options}
          defaultValue={this.defaultValue}
          storeName={this.storeName}
          onChange={this.handleChange}
        />
      </label>
    );
  }
}
