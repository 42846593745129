import {history} from 'client/history';
import {isSearchMode} from 'components/AdvancedSearch/AdvancedSearch.utils';

import {queryTypes} from './subjectPracticeView.queries';

export const singleQuestionPath = '/learn/question/:subjectSlug(/:questionSlug)';
export const editorLinkQuestionPath = '/dennis/content/queue/:questionSetId(/:questionId)';

/**
 * @param {{
 *   activeQuestionSet: import('resources/augmented/QuestionSet/QuestionSetModel.v1').QuestionSetModelV1,
 *   activeQuestion: import('resources/augmented/Question/QuestionModel.v3.js').QuestionModelV3
 * }} arg
 * @returns {?string}
 */
export function getCanonicalLink({activeQuestionSet, activeQuestion}) {
  let subjectSlug;

  if (!activeQuestionSet.getSubject().isEmpty()) {
    subjectSlug = activeQuestionSet.getSubject().getUrlSlug();
  } else if (!activeQuestionSet.getAuthoringSubject().isEmpty()) {
    subjectSlug = activeQuestionSet.getAuthoringSubject().getUrlSlug();
  } else {
    return null;
  }

  return singleQuestionPath
    .replace(/[()]/g, '')
    .replace(':subjectSlug', subjectSlug)
    .replace(':questionSlug', activeQuestion.getSlugId());
}

export function getEditorLink({activeQuestionSet, activeQuestion}) {
  return editorLinkQuestionPath
    .replace(/[()]/g, '')
    .replace(':questionSetId', `${activeQuestionSet.getId()}`)
    .replace(':questionId', `${activeQuestion.getId()}`);
}

/**
 * Returns the appropriate place to redirect to on initial enter of the practice view.
 * Returns null if no redirect is required.
 *
 * @param {Object} props
 * @param props.route
 * @param props.params
 * @param props.location
 * @param props.guideLevel
 * @param props.questionSets
 * @param props.activeQuestion
 * @param props.activeQuestionSet
 * @returns {?string}
 */
export function getRedirectPath({
  route,
  params,
  location,
  guideLevel,
  questionSets,
  activeQuestion,
  activeQuestionSet
}) {
  if (route.isTopicSummary || params.questionSetSlug || !activeQuestion) {
    return null;
  }

  if (route.queryType === queryTypes.singleQuestion) {
    const canonicalLink = getCanonicalLink({
      activeQuestion,
      activeQuestionSet
    });
    return location.pathname.startsWith(canonicalLink) ? null : canonicalLink;
  }

  if (hasTopicSummary({guideLevel}) && !isSearchMode()) {
    return getTopicSummaryPath({params});
  }

  return getActiveQuestionPath({
    params,
    questionSets
  });
}

export function getTopicSummaryPath({params}) {
  const {subjectSlug, guideLevelSlug} = params;
  return `/learn/${subjectSlug}/${guideLevelSlug}/topic-summary`;
}

export function hasTopicSummary({guideLevel}) {
  return !!guideLevel && !!guideLevel.getSummary();
}

/**
 * Returns the URL to the currently active question.
 *
 * @param {Object} props
 * @param props.params
 * @param props.questionSets
 */
export function getActiveQuestionPath({params, questionSets}) {
  const {activeQuestionSet, activeQuestion} = getActiveSetAndQuestion({
    params,
    questionSets
  });

  return getPathToQuestionSetAndQuestion({
    subjectSlug: params.subjectSlug,
    guideLevelSlug: params.guideLevelSlug,
    questionSet: activeQuestionSet,
    question: activeQuestion
  });
}

/**
 * Returns the URL to the currently active question.
 *
 * @param {Object} namedParams
 * @param {string} namedParams.subjectSlug
 * @param {string} namedParams.guideLevelSlug
 * @param {Object} namedParams.questionSet
 * @param {Object} namedParams.question
 */
export function getPathToQuestionSetAndQuestion({
  subjectSlug,
  guideLevelSlug = 'question',
  questionSet,
  question
}) {
  if (!questionSet) {
    return null;
  }
  const path = ['/learn', subjectSlug, guideLevelSlug, questionSet.getURLSlugPart()];
  if (questionSet.getQuestions().size > 1) {
    path.push(question.getKebebCasedTitle());
  }
  const basePath = path.join('/');
  const {search} = history.getCurrentLocation();
  return basePath + search;
}

/**
 * Given the current URL, returns the matching question set and question, or the first
 * question set and question, if no match is found.
 *
 * @param {Object} props
 * @param props.params
 * @param props.questionSets
 */
export function getActiveSetAndQuestion({params, questionSets}) {
  if (questionSets.isEmpty()) {
    return {
      activeQuestionSet: null,
      activeQuestion: null
    };
  }
  const activeQuestionSet = getMatchingQuestionSet(questionSets, params.questionSetSlug);
  const activeQuestion = getMatchingQuestion(activeQuestionSet.getQuestions(), params.questionSlug);

  return {
    activeQuestionSet,
    activeQuestion
  };
}

function getMatchingQuestionSet(questionSets, questionSetSlug) {
  return questionSetSlug
    ? questionSets.find(
        (set) => set.getURLSlugPart() === questionSetSlug,
        null,
        questionSets.first()
      )
    : questionSets.first();
}

function getMatchingQuestion(questions, questionSlug) {
  return questionSlug && questions.size > 1
    ? questions.find(
        (question) => question.getKebebCasedTitle() === questionSlug,
        null,
        questions.first()
      )
    : questions.first();
}
