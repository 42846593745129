import React from 'react';
import {
  addToast,
  Banner,
  Button,
  copyToClipboard,
  Heading,
  Icon,
  Input,
  Text,
  WithTooltip
} from '@albert-io/atomic';
import {isCroppedOrScaled} from 'client/SupplementTypes/Image/utils';

import {AuthoringSupplementModelV1} from 'resources/augmented/AuthoringSupplement/AuthoringSupplementModel.v1';

interface Props {
  supplement: AuthoringSupplementModelV1;
  type: 'passage' | 'video' | 'audio' | 'image';
}

export const SupplementCodeSection = ({supplement, type}: Props) => {
  if (type === 'image') {
    return <ImageSupplementCodesSection supplement={supplement} />;
  }

  return (
    <>
      <Heading size='s'>Supplement Code</Heading>
      <div className='u-display_flex'>
        <Input
          className='supplement-editor__code-input'
          value={`[s:${supplement.getId()}:${type}]`}
        />
        <div className='supplement-editor__code-copy-btn'>
          <Button
            variant='text'
            onClick={() =>
              copyToClipboard(`[s:${supplement.getId()}:${type}]`, () => {
                addToast({
                  message: 'Supplement Code Copied!',
                  color: 'positive'
                });
              })
            }
          >
            Copy supplement code
          </Button>
        </div>
      </div>
    </>
  );
};

interface ImageSupplementCodesSectionProps {
  supplement: AuthoringSupplementModelV1;
}

const ImageSupplementCodesSection = ({supplement}: ImageSupplementCodesSectionProps) => {
  if (isCroppedOrScaled(supplement)) {
    return (
      <>
        <Banner className='u-mar-b_2'>
          <Text>
            <Heading size='xs'>
              Looking for &quot;Viewable&quot; or &quot;Downloadable&quot; Supplement Codes?
            </Heading>
            Since this image is cropped (or scaled) the &quot;Downloadable&quot; and
            &quot;Viewable&quot; functionality will not work reliably for the end-user. You&apos;ll
            still be able to crop and scale the image here, but if you want to take advantage of
            supplement codes, we recommend editing the image prior to uploading.
          </Text>
        </Banner>
      </>
    );
  }

  return (
    <>
      <Heading size='s'>Image Supplement Codes</Heading>
      <Heading size='xs'>
        Viewable
        <WithTooltip
          content='A "viewable" image supplement will render as button. When clicked, it will follow the link to the supplement source directly.'
          zLevel='toast'
        >
          <Icon className='u-mar-l_1' size='standalone' icon='info-circle' />
        </WithTooltip>
      </Heading>
      <div className='u-display_flex'>
        <Input
          value={`[s:${supplement.getId()}:viewable]`}
          className='supplement-editor__code-input'
        />
        <div className='supplement-editor__code-copy-btn'>
          <Button
            variant='text'
            onClick={() => {
              copyToClipboard(`[s:${supplement.getId()}:viewable]`, () => {
                addToast({
                  title: 'Supplement code copied!',
                  color: 'positive'
                });
              });
            }}
          >
            Copy viewable code
          </Button>
        </div>
      </div>
      <Heading size='xs'>
        Downloadable
        <WithTooltip
          content='A "downloadable" image supplement will render as button. When clicked, it will attempt to initiate the download of the supplement source.'
          zLevel='toast'
        >
          <Icon className='u-mar-l_1' size='standalone' icon='info-circle' />
        </WithTooltip>
      </Heading>
      <div className='u-display_flex'>
        <Input
          value={`[s:${supplement.getId()}:downloadable]`}
          className='supplement-editor__code-input'
        />
        <div className='supplement-editor__code-copy-btn'>
          <Button
            color='primary'
            variant='text'
            onClick={() => {
              copyToClipboard(`[s:${supplement.getId()}:downloadable]`, () => {
                addToast({
                  title: 'Supplement code copied!',
                  color: 'positive'
                });
              });
            }}
          >
            Copy downloadable code
          </Button>
        </div>
      </div>
    </>
  );
};
