import React, {useCallback, useEffect, useState} from 'react';
import {
  Button,
  Dropdown,
  Icon,
  Label,
  DropdownItem,
  Grid,
  ListGroupItem,
  Toggle,
  LoadingSpinner,
  addToast
} from '@albert-io/atomic';
import {ClassroomModelV1} from '@albert-io/models';
import {capitalize} from 'lodash';
import sessionStore from 'client/Session/SessionStore';
import classnames from 'classnames';

import './global-assignments.scss';

import {classesString} from './AssignmentCard.react';
import {FilterObj, SharedOptions} from './SearchFilter.react';
import {getClassesQuery} from './GlobalAssignments.queries';

interface AssignmentFiltersProps {
  filters: FilterObj;
  setFilters: (value: any) => void;
  setFiltersToUse: (value: any) => void;
  assignmentsLoading: boolean;
}

const AssignmentFilters: React.FC<AssignmentFiltersProps> = ({
  setFilters,
  filters,
  setFiltersToUse,
  assignmentsLoading
}) => {
  const [classesLoading, setClassesLoading] = useState<boolean>(false);
  const [classes, setClasses] = useState<ClassroomModelV1[]>([]);
  const [classesPage, setClassesPage] = useState<number>(1);
  const [totalNumPagesClasses, setTotalNumPagesClasses] = useState<number>(0);
  const [showArchived, setShowArchived] = useState<boolean>(false);

  const onClickFilterOption = useCallback(
    (prop, option) => {
      if (prop === 'status' || prop === 'shared') {
        if (filters[prop] === option) {
          setFilters({...filters, [prop]: null});
        } else {
          setFilters({...filters, [prop]: option});
        }
      } else if (filters[prop][option.id]) {
        const newClasses = filters[prop];
        delete newClasses[option.id];
        setFilters({...filters, [prop]: newClasses});
      } else {
        setFilters({...filters, [prop]: {...filters[prop], [option.id]: option.name}});
      }
    },
    [filters, setFilters]
  );

  const fetchClasses = useCallback(
    async ({pageNum} = {pageNum: 1}) => {
      try {
        setClassesLoading(true);
        const isAdmin = sessionStore.isSchoolAdministrator();
        let totalNumPages = 1;
        const allClassesQuery = getClassesQuery({
          includeArchived: showArchived,
          isTeacher: isAdmin,
          page: pageNum
        });
        const allClasses = await allClassesQuery.getResourcePromise();
        totalNumPages = allClassesQuery.getResourceMetadata().getIn(['page', 'total_pages'], 0);
        setTotalNumPagesClasses(totalNumPages);
        if (pageNum === 1) {
          setClasses(allClasses.toArray());
        } else {
          setClasses((prevClasses) => [...prevClasses, ...allClasses.toArray()]);
        }
        setClassesLoading(false);
      } catch (e) {
        setClassesLoading(false);
        addToast({
          color: 'negative',
          title: "Couldn't fetch classes",
          message: 'Error fetching classes.'
        });
      }
    },
    [showArchived]
  );

  const handleScrollClasses = useCallback(
    (e) => {
      if (!classesLoading) {
        const {scrollHeight, scrollTop, clientHeight} = e.target;
        const nearBottom = scrollHeight - clientHeight - scrollTop < 100;

        if (nearBottom && classesPage < totalNumPagesClasses) {
          setClassesLoading(true);
          fetchClasses({pageNum: classesPage + 1});
          setClassesPage((prevPage) => prevPage + 1);
        }
      }
    },
    [fetchClasses, classesPage, totalNumPagesClasses, classesLoading]
  );

  useEffect(() => {
    setClassesPage(1);
    fetchClasses();
  }, [fetchClasses]);

  return (
    <div className='global-assignments__filters'>
      <Grid.Container className='global-assignments__filters-grid-container'>
        <Grid.Row>
          <Grid.Col all={4} s={12}>
            <div className='u-display_flex u-align-items_center u-justify-content_space-between u-mar-b_1'>
              <Label>Status</Label>
              {filters.status !== '' && filters.status !== null && (
                <button
                  className='global-assignments__filters-clear'
                  type='button'
                  onClick={() =>
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      status: ''
                    }))
                  }
                >
                  Clear
                </button>
              )}
            </div>
            <Dropdown
              trigger={
                <Button
                  className='u-font-weight_normal'
                  color='secondary'
                  variant='outlined'
                  data-testid='status-trigger'
                >
                  {capitalize(filters.status || '')}
                  <Icon icon='caret-down' />
                </Button>
              }
              position='bottom-start'
              withMaxHeight
            >
              <div>
                {['open', 'closed', 'scheduled'].map((status) => (
                  <DropdownItem
                    bold={false}
                    onClick={() => onClickFilterOption('status', status)}
                    data-testid={`${status}-option`}
                    key={status}
                  >
                    <Icon
                      icon='check'
                      className={classnames('u-mar-r_2 fa-fw', {
                        'u-opacity_full': filters.status === status,
                        'u-opacity_0': filters.status !== status
                      })}
                    />

                    {capitalize(status)}
                  </DropdownItem>
                ))}
              </div>
            </Dropdown>
          </Grid.Col>
          <Grid.Col all={4} s={12}>
            <div className='u-display_flex u-align-items_center u-justify-content_space-between u-mar-b_1'>
              <Label>Classes</Label>
              {!!Object.keys(filters.classes).length && (
                <button
                  className='global-assignments__filters-clear'
                  type='button'
                  onClick={() =>
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      classes: {}
                    }))
                  }
                >
                  Clear
                </button>
              )}
            </div>
            <Dropdown
              trigger={
                <Button
                  className='u-font-weight_normal'
                  color='secondary'
                  variant='outlined'
                  data-testid='class-trigger'
                >
                  {classesString(filters.classes)}
                  <Icon icon='caret-down' />
                </Button>
              }
              position='bottom-start'
              withMaxHeight
            >
              <div
                className='m-dropdown__content--max u-position_relative'
                onScroll={handleScrollClasses}
              >
                <>
                  {classes.map((c) => (
                    <DropdownItem
                      bold={false}
                      key={c.id}
                      onClick={() => onClickFilterOption('classes', c)}
                      className={
                        c.status === 'archived' ? 'global-assignments__archived-class-entry' : ''
                      }
                      data-testid={`${c.id}-option`}
                      color={c.status === 'archived' ? 'inherit' : 'black'}
                    >
                      <div>
                        <Icon
                          icon='check'
                          className={classnames('u-mar-r_2 fa-fw', {
                            'u-opacity_full': filters.classes[c.id],
                            'u-opacity_0': !filters.classes[c.id]
                          })}
                        />
                        {c.name}
                      </div>
                      {c.status === 'archived' && <Icon icon='box-archive' className='u-mar-l_1' />}
                    </DropdownItem>
                  ))}
                  {classesLoading && (
                    <div className='u-display_flex u-justify-content_center u-mar-t_2'>
                      <LoadingSpinner size={2} />
                    </div>
                  )}
                </>
                <ListGroupItem
                  key='archived'
                  className='global-assignments__filters-archived-toggle'
                >
                  <span className='u-mar-r_1'>Show archived</span>
                  <Toggle
                    checked={showArchived}
                    onChange={() => {
                      setClassesPage(1);
                      setShowArchived(!showArchived);
                    }}
                    disabled={classesLoading}
                  />
                </ListGroupItem>
              </div>
            </Dropdown>
          </Grid.Col>
          <Grid.Col all={4} s={12}>
            <div className='u-display_flex u-align-items_center u-justify-content_space-between u-mar-b_1'>
              <Label>Shared</Label>
              {filters.shared !== '' && filters.shared !== null && (
                <button
                  className='global-assignments__filters-clear'
                  type='button'
                  onClick={() =>
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      shared: ''
                    }))
                  }
                >
                  Clear
                </button>
              )}
            </div>
            <Dropdown
              trigger={
                <Button
                  className='u-font-weight_normal'
                  color='secondary'
                  variant='outlined'
                  data-testid='shared-trigger'
                >
                  {filters.shared}
                  <Icon icon='caret-down' />
                </Button>
              }
              position='bottom-start'
              withMaxHeight
            >
              <div>
                {SharedOptions.map((shared) => (
                  <DropdownItem
                    bold={false}
                    onClick={() => onClickFilterOption('shared', shared)}
                    data-testid={`${shared}-option`}
                    key={shared}
                  >
                    <Icon
                      icon='check'
                      className={classnames('u-mar-r_2 fa-fw', {
                        'u-opacity_full': filters.shared === shared,
                        'u-opacity_0': filters.shared !== shared
                      })}
                    />
                    {shared}
                  </DropdownItem>
                ))}
              </div>
            </Dropdown>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
      <Button
        color='primary'
        onClick={() => {
          setFiltersToUse(filters);
        }}
        disabled={assignmentsLoading}
        data-testid='apply-filters'
      >
        Apply
      </Button>
    </div>
  );
};

export default AssignmentFilters;
