import React from 'react';
import awaitMandarkQueries from 'lib/hocs/awaitMandarkQueries';
import sessionStore from 'client/Session/SessionStore';
import {resource} from '@albert-io/json-api-framework/request/builder';
import {Badge} from '@albert-io/atomic';

export function getUserPointsQuery(id) {
  return resource('user_v2').mandarkEndpoint(['users_v2', id]).fields({
    user_v2: 'points_earned'
  });
}

const PointsIndicator = awaitMandarkQueries(
  () => ({
    queries: {
      userPoints: getUserPointsQuery(sessionStore.getUserId())
    },
    loadingElement: <div />
  }),
  ({userPoints}) => (
    <Badge emphasis='bold' status='info'>
      {userPoints.getPointsEarned()} pts
    </Badge>
  )
);

export default PointsIndicator;
