/* eslint-disable */


// Programatically generated on Mon Mar 31 2025 17:10:40 GMT+0000
import {fromJS, List, Map, Record, Set} from 'immutable';
import {GenericModel} from 'resources/Generic.model';
import {validate, Empty} from '@albert-io/json-api-framework/models/helpers';
import moment from 'moment';

const ModelConfig = fromJS(
  {
    'endpoint': 'authoring_standards_v1',
    'attributes': {
      'document_stems': {
        'type': 'tsvector',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': true
      },
      'case_id': {
        'type': 'binary_id',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'uri': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'updated_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'title': {
        'type': 'string',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'status_start_date': {
        'type': 'date',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'inserted_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'id': {
        'type': 'binary_id',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'document': {
        'type': 'tsvector',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': true
      },
      'description': {
        'type': 'string',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'status_end_date': {
        'type': 'date',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      }
    },
    'relationships': {
      'authoring_questions_v1': {
        'type': 'one-to-many'
      },
      'authoring_standard_set_v1': {
        'type': 'one-to-one'
      }
    }
  }
);

const ModelRecord = Record(
  {
    'authoring_questions': Empty,
    'document_stems': Empty,
    'authoring_standard_set': Empty,
    'case_id': Empty,
    'uri': Empty,
    'updated_at': Empty,
    'title': Empty,
    'status_start_date': Empty,
    'inserted_at': Empty,
    'id': Empty,
    'document': Empty,
    'description': Empty,
    'relationshipMeta': Empty,
    'status_end_date': Empty,
    'meta': new Map({
    'content_discovery_question_count': Empty,
    'included_in_personalization_settings': Empty,
    'populated_metadata_fields': Empty,
    'question_count': Empty,
    'search_relevance': Empty
  })
  }
);

const DefaultModel = fromJS(
  {
    'case_id': '',
    'uri': '',
    'title': '',
    'id': '',
    'description': '',
    'updated_at': new Date(),
    'inserted_at': new Date(),
    'authoring_questions': new List(),
    'meta': new Map(),
    'document_stems': new Map(),
    'authoring_standard_set': new Map(),
    'status_start_date': new Map(),
    'document': new Map(),
    'relationshipMeta': new Map(),
    'status_end_date': new Map()
  }
);

export class AuthoringStandardModelV1 extends GenericModel(ModelRecord, ModelConfig, 'AuthoringStandardModelV1') {
  // -- Query Rules --
  /**
   * @returns {boolean}
   */
  static allowsInclude(include) {
    // AllowedIncludes is currently disabled.
    return true; // AllowedIncludes.has(include);
  }

  /**
   * @returns {boolean}
   */
  static allowsFilter(filterField, filterType) {
    return filterType ?
      FilterRules.get(filterField).has(filterType) :
      FilterRules.has(filterField);
  }

  /**
   * @returns {boolean}
   */
  static allowsSort(sort) {
    return sort.split(',').filter((s) => AllowedSorts.has(s)).length > 0;
  }

  /**
   * @returns {Array}
   */
  static getAllowedSorts() {
    return AllowedSorts.keySeq().toJS();
  }

  static getMetaContextQuery(key) {
    if (!MetadataContextRules.hasIn(['optional', key]) && !MetadataContextRules.hasIn(['required', key])) {
      console.error('Rule for meta context key ' + key + ' does not exist');
    }

    return MetadataContextRules.getIn(['optional', key]) || MetadataContextRules.getIn(['required', key]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getRequiredMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values()]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getAllMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values(), ...MetadataContextRules.get('optional').values()]);
  }

  static getDefaultModel() {
    _emptyAuthoringStandardModelV1Model = _emptyAuthoringStandardModelV1Model ? _emptyAuthoringStandardModelV1Model : new AuthoringStandardModelV1(DefaultModel);
    return _emptyAuthoringStandardModelV1Model;
  }

  static supportsMetaContext() {
    return MetadataContextRules !== null;
  }

  /**
   * @returns {string}
   */
  static getModelName() {
    return 'AuthoringStandardModelV1';
  }

  /**
   * @returns {boolean}
   */
  static _isFullSpec() {
    return true;
  }

  constructor() {
    super(...arguments);
    this.__meta = null;
  }

  /**
   * @returns {string}
   */
  getJsonApiType() {
    return 'authoring_standard_v1';
  }

  /**
   * @params {string} - An attribute name
   * @params {*} [def] - Optional default value override for the attribute
   */
  getValueForAttribute(attribute, def) {
    let value = this.get(attribute);
    validate(value, attribute);
    if (value === Empty) {
      value = def !== undefined ? def : DefaultModel.get(attribute);
    }
    return value;
  }

  // -- Primary Attributes --
  
  /**
   * @type {binary_id}
   */
  getCaseId() {
    const value = this.getValueForAttribute('case_id');
    return value;
  }

  setCaseId(value) {
    return this.setField('case_id', value);
  }

  /**
   * @type {string}
   */
  getDescription() {
    const value = this.getValueForAttribute('description');
    return value;
  }

  setDescription(value) {
    return this.setField('description', value);
  }

  /**
  * @type {tsvector}
  */
  setDocument(value) {
    return this.setField('document', value);
  }

  /**
  * @type {tsvector}
  */
  setDocumentStems(value) {
    return this.setField('document_stems', value);
  }

  /**
   * @type {binary_id}
   */
  getId() {
    const value = this.getValueForAttribute('id');
    return value;
  }

  setId(value) {
    return this.setField('id', value);
  }

  /**
   * @type {datetime}
   */
  getInsertedAt() {
    const value = this.getValueForAttribute('inserted_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {date}
   */
  getStatusEndDate() {
    const value = this.getValueForAttribute('status_end_date');
    return value;
  }

  setStatusEndDate(value) {
    return this.setField('status_end_date', value);
  }

  /**
   * @type {date}
   */
  getStatusStartDate() {
    const value = this.getValueForAttribute('status_start_date');
    return value;
  }

  setStatusStartDate(value) {
    return this.setField('status_start_date', value);
  }

  /**
   * @type {string}
   */
  getTitle() {
    const value = this.getValueForAttribute('title');
    return value;
  }

  setTitle(value) {
    return this.setField('title', value);
  }

  /**
   * @type {datetime}
   */
  getUpdatedAt() {
    const value = this.getValueForAttribute('updated_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {string}
   */
  getUri() {
    const value = this.getValueForAttribute('uri');
    return value;
  }

  setUri(value) {
    return this.setField('uri', value);
  }

  // -- Meta Attributes --
  
    /**
     * LEGACY META ACCESSORS
     * @todo These should no longer be used, instead use this.getMeta()[...]
     */
    
      getContentDiscoveryQuestionCount() {
        return this.getMeta().getContentDiscoveryQuestionCount();
      }
    
      isIncludedInPersonalizationSettings() {
        return this.getMeta().isIncludedInPersonalizationSettings();
      }
    
      getPopulatedMetadataFields() {
        return this.getMeta().getPopulatedMetadataFields();
      }
    
      getQuestionCount() {
        return this.getMeta().getQuestionCount();
      }
    
      getSearchRelevance() {
        return this.getMeta().getSearchRelevance();
      }
    
    getMeta() {
      if (this.__meta) {
        return this.__meta;
      }
      const meta = this.get('meta', Map());
      this.__meta = {
        
          /**
           * @type {integer}
           */
          getContentDiscoveryQuestionCount() {
            const value = meta.get('content_discovery_question_count');
            validate(value, 'content_discovery_question_count');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {boolean}
           */
          isIncludedInPersonalizationSettings() {
            const value = meta.get('included_in_personalization_settings');
            validate(value, 'included_in_personalization_settings');
            return value;
          },
  
          /**
           * @type {string[]}
           */
          getPopulatedMetadataFields() {
            const value = meta.get('populated_metadata_fields');
            validate(value, 'populated_metadata_fields');
            return value;
          },
  
          /**
           * @type {integer}
           */
          getQuestionCount() {
            const value = meta.get('question_count');
            validate(value, 'question_count');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {float}
           */
          getSearchRelevance() {
            const value = meta.get('search_relevance');
            validate(value, 'search_relevance');
            
      const float = parseFloat(value, 10);
      return isNaN(float) ? value : float;
    
          },
  
      };
      return this.__meta;
    }
  
  // -- Relationships --
  
  /**
   * @type {authoring_standard_set_v1 (one)}
   */
  getAuthoringStandardSet() {
    const value = this.getValueForAttribute('authoring_standard_set');
    return value;
  }

  // -- Relationship Fields: authoring_standard_set --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {authoring_question_v1 (many)}
   */
  getAuthoringQuestions() {
    const value = this.getValueForAttribute('authoring_questions');
    return value;
  }

  // -- Relationship Fields: authoring_questions --
  // no 'fields' found, skipped generating accessor(s)
  
}

// -- Empty Model Instance --
let _emptyAuthoringStandardModelV1Model = null;

// -- Sort Rules --

const AllowedSorts = fromJS({
  'case_id': null,
  'description': null,
  'id': null,
  'inserted_at': null,
  'meta.content_discovery_question_count': null,
  'meta.included_in_personalization_settings': null,
  'meta.question_count': null,
  'meta.search_relevance': null,
  'status_end_date': null,
  'status_start_date': null,
  'title': null,
  'updated_at': null,
  'uri': null,
});

// -- Include Rules --
// List []

// -- Filter Rules --
const FilterRules = new Map({
  'authoring_questions_v1': new Set(['associated']),
  'authoring_standard_set_v1': new Set(['associated']),
  'case_id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'description': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'document': new Set(['search_phrases']),
  'document_stems': new Set(['search_tokens']),
  'id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'inserted_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.content_discovery_question_count': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.included_in_personalization_settings': new Set(['exact','not','null']),
  'meta.populated_metadata_fields': new Set(['null']),
  'meta.question_count': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.search_relevance': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'status_end_date': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'status_start_date': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'title': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'updated_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'uri': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
});
    

// -- Metadata Context Rules --
const MetadataContextRules = fromJS({
  optional: {
    'content_discovery_filtering': 'meta.context.content_discovery_filtering',
    'content_discovery_filters_guide_levels': 'meta.context.content_discovery_filters.guide_levels',
    'content_discovery_filters_hide_assessment_questions': 'meta.context.content_discovery_filters.hide_assessment_questions',
    'content_discovery_filters_hide_previously_assigned': 'meta.context.content_discovery_filters.hide_previously_assigned',
    'content_discovery_filters_question_difficulties': 'meta.context.content_discovery_filters.question_difficulties',
    'content_discovery_filters_question_types': 'meta.context.content_discovery_filters.question_types',
    'content_discovery_filters_subjects': 'meta.context.content_discovery_filters.subjects',
    'question_count': 'meta.context.question_count',
    'search_fields': 'meta.context.search.fields',
    'search_term': 'meta.context.search.term',
    'user_id': 'meta.context.user.id',
    'personalization_settings': 'meta.context.user.personalization_settings',
  },
  required: {
  }
});

// -- Legacy Name Export --
export const AuthoringStandard = AuthoringStandardModelV1;
