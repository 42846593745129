import React, {useEffect, useState} from 'react';
import classnames from 'classnames';
import {List} from 'immutable';
import notifier from '@albert-io/notifier';
import {pushQueryParams} from 'client/history';
import {callAction, callTargetedAction} from 'client/framework';
import {useShallow} from 'zustand/react/shallow';

import {addToast, addGenericErrorToast, Heading, Text, Toggle} from '@albert-io/atomic';
import Button from 'sg/Button/Button.react';
import SimpleDropdown from 'generic/SimpleDropdown/SimpleDropdown.react';
import simpleDropdownActions from 'generic/SimpleDropdown/SimpleDropdown.actions';
import SimpleDropdownStore from 'generic/SimpleDropdown/SimpleDropdown.store';
import WindowConfirmStore from 'generic/WindowConfirm/WindowConfirmStore';
import FeatureFlag from 'client/components/FeatureFlag/FeatureFlag.react';
import {
  ALTERNATE_CONTENT_LANGUAGES,
  AlternateContentLanguage,
  PRIMARY_CONTENT_LANGUAGE,
  SUPPORTED_CONTENT_LANGUAGES,
  SupportedContentLanguage
} from 'client/EditPage/V2/QuestionEditorV2Store.types';
import {LanguageToggle} from 'client/EditPage/V2/Translations/LanguageToggle';
import ImageEditor from 'client/SupplementTypes/Image/ImageEditor';
import PassageEditor from 'client/SupplementTypes/Passage/PassageEditor';
import AudioEditor from 'client/SupplementTypes/Audio/AudioEditor';

/**
 * @todo Adding `video` supplements has been disabled since March 2016.
 */
// import VideoEditor from 'client/SupplementTypes/VideoSupplement/VideoSupplementEditor.react';
import SortableTableEditor from 'client/SupplementTypes/SortableTableSupplement/SortableTableEditor';
import FreeFormEditor from 'client/SupplementTypes/FreeForm/FreeFormEditor.react';
import WindowConfirm from 'client/generic/WindowConfirm/WindowConfirm.react';
import windowConfirmActions from 'generic/WindowConfirm/WindowConfirmActions';

import supplementManagerStore from 'client/Supplements/SupplementManager/SupplementManager.store';

import supplementManagerActions from 'client/Supplements/SupplementManager/SupplementManager.actions';
import sessionStore from 'client/Session/SessionStore';
import {AuthoringSupplementModelV1} from 'resources/augmented/AuthoringSupplement/AuthoringSupplementModel.v1';
import {AuthoringSubjectModelV1} from 'resources/augmented/AuthoringSubject/AuthoringSubjectModel.v1';
import errorCodes from 'client/errorCodes';
import supplementActions from 'client/Supplement/SupplementActions';
import awaitMandarkQueries from 'lib/hocs/awaitMandarkQueries';
import {resource} from '@albert-io/json-api-framework/request/builder';
import {invalidatePartialInterest} from 'resources/mandark.resource';

import {SupplementSubjectDropdownTemplate} from '../SupplementSubjectDropdownTemplate.react';

import {SupplementCodeSection} from './SupplementCodeSection';
import supplementEditorActions from './SupplementEditor.actions';
import supplementEditorStore from './SupplementEditor.store';

import './supplement-editor.scss';

import {useSupplementEditorV2Store} from './SupplementEditorV2Store';
import {GenerateTranslationsButton} from './GenerateTranslationsButton';

/* SupplementEditor

 * Like its cousin SupplementSearch, SupplementEditor looks to Mandark to populate our Subjects
 * dropdown before proceeding with rendering. Once we've received our domain and subjects, we
 * check them against the question we're currently editing, and if we have one we prepopulate
 * our domain and subject fields to match our active question.

 * We perform a set of checks on each UNSAFE_componentWillUpdate to confirm that our two dropdowns
 * don't fall out of sync with one another.  We also confirm that we are rendering the correct
 * editor for the selected supplement type.

 * Saving is straightforward, while inserting awaits the promise returned by the model's save()
 * method and forwards on our newly minted supplement to be serialized and pasted into the
 * question content.
 */

function getSupplementQuery(id) {
  return resource('authoring_supplement_v1')
    .mandarkEndpoint(['authoring_supplements_v1', id])
    .include('authoring_subject_v1');
}
function getSubjectsQuery(id) {
  return resource('authoring_subject_v1')
    .mandarkEndpoint(['authoring_subjects_v1'])
    .fields({
      authoring_subject_v1: 'id,domain,name'
    })
    .filter(
      {
        id
      },
      !sessionStore.isSuper() && id
    )
    .pageSize('infinite')
    .sort('domain,name');
}

const subjectDropdownStore = new SimpleDropdownStore('SupplementEditorSubjectDropdownStore');
const typeDropdownStore = new SimpleDropdownStore('SupplementEditorTypeDropdownStore');
const windowConfirmStore = new WindowConfirmStore('SupplementEditorWindowConfirmStore');

interface Props {
  isModal: boolean;
  activeSubjectId: string;
  activeSupplementId: string;
  showInsertButton?: boolean;
  subjects: List<AuthoringSubjectModelV1>;
  supplement: AuthoringSupplementModelV1;
  type: 'passage' | 'video' | 'audio' | 'image';
  typeOptions: List<any>;
}

/**
 * @todo We might want this to use `SupplementTypes/SuppplementTypeDefinitions` eventually. However,
 * based on how this is currently implemented we actually benefit from supplement editors only being bundled
 * if the user has access to the `SupplementEditor` (Authoring)
 */
const editorsByType = {
  image: ImageEditor,
  passage: PassageEditor,
  audio: AudioEditor,
  // video: VideoEditor,
  'sortable-table': SortableTableEditor,
  'free-form': FreeFormEditor
};

export const SupplementEditor = awaitMandarkQueries(
  (props: Props) => {
    const options: any = {
      queries: {
        subjects: getSubjectsQuery(props.activeSubjectId)
      }
    };
    if (props.activeSupplementId) {
      options.queries.supplement = getSupplementQuery(props.activeSupplementId);
    }
    return options;
  },
  function SupplementEditor(props: Props) {
    const [savePending, setSavePending] = useState(false);

    const {init, currentLanguage, setCurrentLanguage, saveTranslatedFields} =
      useSupplementEditorV2Store(
        useShallow((state) => ({
          init: state.init,
          currentLanguage: state.currentLanguage,
          setCurrentLanguage: state.setCurrentLanguage,
          saveTranslatedFields: state.saveTranslatedFields
        }))
      );

    useEffect(() => {
      setCurrentLanguage(PRIMARY_CONTENT_LANGUAGE);
    }, [setCurrentLanguage]);

    useEffect(() => {
      if (props.activeSupplementId) {
        init(props.activeSupplementId);
      } else {
        init(null);
      }

      const activeSupplement = props.activeSupplementId
        ? props.supplement
        : AuthoringSupplementModelV1.getDefaultModel().setType('image').setTranslate(true);
      callAction(supplementEditorActions.SET_ACTIVE_SUPPLEMENT, activeSupplement);

      const activeSubject = props.activeSupplementId
        ? props.supplement.getAuthoringSubject()
        : props.subjects.find((subject) => subject.getId() === props.activeSubjectId);
      callAction(supplementEditorActions.SET_ACTIVE_SUBJECT, activeSubject);

      return () => {
        callTargetedAction({
          name: simpleDropdownActions.RESET_STORE,
          targetStore: subjectDropdownStore.getName()
        });
        callAction(supplementEditorActions.RESET_STORE);
      };
    }, [
      props.activeSupplementId,
      props.supplement,
      props.subjects,
      props.activeSubjectId,
      init,
      setCurrentLanguage
    ]);

    const onChange = (e) => {
      const element = e.target;
      callAction(supplementEditorActions.MODIFY_ACTIVE_SUPPLEMENT, {
        setter: element.dataset.setterMethod,
        value: element.value
      });
      callAction(supplementEditorActions.VALIDATE_SUPPLEMENT, [element.name]);
    };

    const onTypeChange = (item) => {
      const activeSupplement = AuthoringSupplementModelV1.getDefaultModel()
        .setType(item.get('id'))
        .setTranslate(true);
      callAction(supplementEditorActions.SET_ACTIVE_SUPPLEMENT, activeSupplement);
      callAction(supplementEditorActions.VALIDATE_SUPPLEMENT, ['type']);
    };

    const onCheckChange = (e) => {
      const element = e.target;
      callAction(supplementEditorActions.MODIFY_ACTIVE_SUPPLEMENT, {
        setter: element.dataset.setterMethod,
        value: element.checked
      });
      /* Invoking validator without any args will safely validate all required fields by default */
      callAction(supplementEditorActions.VALIDATE_SUPPLEMENT);
    };

    const handleCancelClick = () => {
      pushQueryParams({
        supplementMode: 'search',
        supplement: ''
      });
    };

    const handleDeleteClick = () => {
      callTargetedAction({
        name: windowConfirmActions.SHOW_MODAL,
        targetStore: windowConfirmStore.getName()
      });
    };

    const invalidateTableResults = () => {
      invalidatePartialInterest({
        resourcePath: ['authoring_supplements_v1'],
        filter: {
          authoring_subject_v1: {
            id: props.activeSubjectId
          }
        }
      });
    };

    const onDeleteConfirm = async () => {
      try {
        await supplementEditorStore.getActiveSupplement().delete();
        pushQueryParams({
          supplementMode: 'search',
          supplement: ''
        });
        addToast({
          color: 'positive',
          title: 'Success!',
          message: 'Your supplemented has been deleted.'
        });
        invalidateTableResults();
      } catch (error) {
        const supplementInUseError = (error as any).response.body.errors.find((responseError) => {
          return (
            responseError.code ===
            errorCodes.BAD_REQUEST.VALIDATION_ERROR.SUPPLEMENT_USED_IN_QUESTION
          );
        });

        if (supplementInUseError) {
          addToast({
            color: 'negative',
            title: supplementInUseError.title,
            message: supplementInUseError.detail
          });
        } else {
          logger.error('Failed deleting supplement: %s', (error as any).message || 'unknown error');
          addGenericErrorToast();
        }
      }
    };

    /* eslint-disable consistent-return */
    const handleSaveClick = async () => {
      // (1) Validate that required info is provided, including a subject id
      // (1a) Bail if any required fields are missing
      // (2) Make the post to Mandark
      // (3) Display a toast message
      callAction(supplementEditorActions.VALIDATE_SUPPLEMENT);

      try {
        setSavePending(true);
        if (supplementEditorStore.hasErrors()) {
          /* eslint-disable no-throw-literal */
          throw new Error('Please complete all fields and try again.');
        }

        const supplement = supplementEditorStore.getActiveSupplement();

        const response = await supplement
          .addRelationship({
            type: 'owner_v2',
            relation: sessionStore.getUserId(),
            condition: !supplement.existsOnServer()
          })
          .addRelationship({
            type: 'authoring_subject_v1',
            relation: supplementEditorStore.getActiveSubject(),
            condition: !props.activeSupplementId
          })
          .save({
            customQuery: {
              include: 'owner_v2,authoring_subject_v1'
            }
          });

        await saveTranslatedFields();

        if (!props.activeSupplementId) {
          await init(response.getId());
        }

        callAction(supplementEditorActions.SET_ACTIVE_SUPPLEMENT, response);
        callAction(supplementActions.ADD_SUPPLEMENT, response);
        addToast({
          message: 'Your changes have been saved.',
          title: 'Success!',
          color: 'positive'
        });
        invalidateTableResults();
        return response;
      } catch (error) {
        if (supplementEditorStore.hasErrors()) {
          const errors = supplementEditorStore
            .getErrors()
            .toList()
            .filter((storeError) => storeError !== null);

          addToast({
            message:
              errors.size > 1 ? (
                <ul>
                  {errors.map((_error, key) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={key}>{_error}</li>
                  ))}
                </ul>
              ) : (
                <p>{errors.first()}</p>
              ),
            title: 'Something went wrong',
            color: 'negative'
          });
        } else {
          logger.error(
            'Failed uploading supplement: %s',
            (error as any).message || 'unknown error'
          );
          notifier.notify(error, {
            component: 'SupplementEditor',
            fingerprint: 'SupplementSaveFailure'
          });
          addGenericErrorToast();
        }
      } finally {
        setSavePending(false);
      }
    };

    const activeSupplement = supplementEditorStore.getActiveSupplement();
    const activeSubjectId = !activeSupplement.existsOnServer()
      ? props.activeSubjectId
      : activeSupplement.getAuthoringSubject().getId();
    const errors = supplementEditorStore.getErrors();
    const type = activeSupplement.getType();
    const subjectDropdownClassnames = classnames(
      'supplement-editor__input supplement-editor__input--subject',
      {
        'supplement-editor__input--error':
          !props.activeSupplementId && !supplementEditorStore.getActiveSubject()
      }
    );
    const Editor = editorsByType[type];

    return (
      <div
        className={classnames('supplement-editor__wrapper', {
          'supplement-editor__wrapper--in-modal': props.isModal
        })}
      >
        <div
          className={classnames('supplement-editor__header-section', {
            'supplement-editor__header-section--in-modal': props.isModal
          })}
        >
          <div className='supplement-editor__header'>
            <Heading size='s' className='u-truncate'>
              {activeSupplement.existsOnServer()
                ? activeSupplement.getName() || 'Supplement'
                : activeSupplement.getName() || 'Untitled Supplement'}
            </Heading>
          </div>

          <FeatureFlag name='dennis_translation_authoring'>
            <SupplementEditorLanguageToggle savePending={savePending} />
          </FeatureFlag>
        </div>

        <div className='supplement-editor'>
          {/* translation settings and generate button */}
          {currentLanguage !== PRIMARY_CONTENT_LANGUAGE && (
            <FeatureFlag name='dennis_translation_authoring'>
              <div className='u-display_flex u-flex-direction_column u-justify-content_space-between u-align-items_center u-gap_space-x4 u-mar-b_4'>
                <div className='u-display_flex u-align-items_center u-justify-content_space-between u-align-self_stretch'>
                  <Text size='m'>Allow translations on this supplement</Text>
                  <Toggle
                    checked={activeSupplement.isTranslate()}
                    onChange={onCheckChange}
                    data-setter-method='setTranslate'
                    disabled={savePending}
                  />
                </div>
                {activeSupplement.isTranslate() && (
                  <>
                    <div className='u-border-b u-border-color_slate-300 u-align-self_stretch' />
                    <div className='u-display_flex u-flex-direction_column u-align-items_center u-gap_space-x2'>
                      <Text size='m'>
                        Would you like to generate AI Spanish translations for this supplement?
                      </Text>
                      <GenerateTranslationsButton />
                    </div>
                  </>
                )}
              </div>
            </FeatureFlag>
          )}

          <SimpleDropdown
            activeItemId={activeSubjectId}
            className={subjectDropdownClassnames}
            disabled={!!props.activeSupplementId || currentLanguage !== PRIMARY_CONTENT_LANGUAGE}
            fullWidth
            hasOptionTemplate
            label='Subject'
            onChange={(item) => callAction(supplementEditorActions.SET_ACTIVE_SUBJECT, item)}
            options={props.subjects}
            optionTemplate={SupplementSubjectDropdownTemplate}
            placeholder='Choose one'
            storeName={subjectDropdownStore.getName()}
          />
          <SimpleDropdown
            activeItemId={type}
            className='supplement-editor__input supplement-editor__input--type'
            destroyStoreOnUnmount
            disabled={!!props.activeSupplementId || currentLanguage !== PRIMARY_CONTENT_LANGUAGE}
            fullWidth
            label='Type'
            options={props.typeOptions}
            onChange={onTypeChange}
            placeholder='Choose one'
            storeName={typeDropdownStore.getName()}
          />

          {(currentLanguage === PRIMARY_CONTENT_LANGUAGE || activeSupplement.isTranslate()) && (
            <>
              <Editor
                supplement={activeSupplement}
                onChange={onChange}
                onCheckChange={onCheckChange}
                errors={errors}
              />
            </>
          )}

          <SupplementCodeSection type={props.type} supplement={activeSupplement} />

          <div className='supplement-editor__btn-wrapper'>
            <Button
              className='supplement-editor__btn supplement-editor__btn--cancel'
              color='gray'
              onClick={handleCancelClick}
              text='Back'
            />
            {supplementEditorStore.getActiveSupplement().existsOnServer() ? (
              <Button
                className='supplement-editor__btn supplement-editor__btn--cancel'
                color='red'
                onClick={handleDeleteClick}
                text='Delete'
              />
            ) : null}
            {props.showInsertButton ? (
              <Button
                className='supplement-editor__btn supplement-editor__btn--insert'
                color='gray'
                disabled={supplementEditorStore.isUploadPending()}
                onClick={() => {
                  handleSaveClick()
                    .then((supplement) => {
                      callAction(supplementManagerActions.INSERT_SUPPLEMENT_AT_CURSOR_POSITION, {
                        fieldPath: supplementManagerStore.getCurrentEditorField(),
                        supplement
                      });
                      callAction(supplementManagerActions.SHOW_MODAL, false);
                    })
                    .catch();
                }}
                text='Save &amp; Insert'
              />
            ) : null}
            <Button
              className='supplement-editor__btn supplement-editor__btn--save'
              color='gray'
              disabled={supplementEditorStore.isUploadPending()}
              onClick={handleSaveClick}
              text='Save'
            />
          </div>
        </div>
        <WindowConfirm
          confirmButtonColor='red'
          confirmButtonText='Delete'
          message='This action cannot be undone.'
          onConfirm={onDeleteConfirm}
          storeName={windowConfirmStore.getName()}
          title='Are you sure you want to delete this supplement?'
        />
      </div>
    );
  }
);

const SupplementEditorLanguageToggle = ({savePending}: {savePending: boolean}) => {
  const {
    currentLanguage,
    setCurrentLanguage,
    savingTranslatedFields,
    translatedSupplements,
    dirtyTranslatedFields
  } = useSupplementEditorV2Store(
    useShallow((state) => ({
      currentLanguage: state.currentLanguage,
      setCurrentLanguage: state.setCurrentLanguage,
      savingTranslatedFields: state.savingTranslatedFields,
      translatedSupplements: state.translatedSupplements,
      dirtyTranslatedFields: state.dirtyTranslatedFields
    }))
  );

  const activeSupplement = supplementEditorStore.getActiveSupplement();
  const primaryLanguageHasChanges = Object.keys(activeSupplement.getChangeMap().toJS()).some(
    (k) => k !== 'translate'
  );
  const translationSettingsHaveChanges = Object.keys(
    activeSupplement.getChangeMap().toJS()
  ).includes('translate');

  const languagesHaveChanges = SUPPORTED_CONTENT_LANGUAGES.reduce((acc, lang) => {
    if (lang === PRIMARY_CONTENT_LANGUAGE) {
      return {...acc, [lang]: primaryLanguageHasChanges};
    }
    const relevantDirtyFields = dirtyTranslatedFields.filter((field) => field.language === lang);
    return {
      ...acc,
      [lang]: relevantDirtyFields.length > 0 || translationSettingsHaveChanges
    };
  }, {} as Record<SupportedContentLanguage, boolean>);

  const languagesHaveUnpublishedTranslations = ALTERNATE_CONTENT_LANGUAGES.reduce((acc, lang) => {
    const translatedSupplement = translatedSupplements[lang];
    return {
      ...acc,
      [lang]:
        !!translatedSupplement &&
        translatedSupplement.translated_fields.some((f) => f.status !== 'published')
    };
  }, {} as Record<AlternateContentLanguage, boolean>);

  return (
    <LanguageToggle
      currentLanguage={currentLanguage}
      setCurrentLanguage={setCurrentLanguage}
      disabled={savePending || savingTranslatedFields || !activeSupplement.existsOnServer()}
      languagesHaveChanges={languagesHaveChanges}
      languagesHaveUnpublishedTranslations={languagesHaveUnpublishedTranslations}
    />
  );
};
