// @flow
import {Store} from 'client/framework';
import {fromJS} from 'immutable';
import searchDropdownActions from './SearchDropdown.actions';

export default class DropdownStore extends Store {
  constructor(name: string) {
    super(name);
    this.setInitialData(
      fromJS({
        searchString: '',
        selectedOption: null,
        isDropdownOpen: false,
        isOpenOnFocus: false
      })
    );
    this.handleTargeted(
      searchDropdownActions.SET_IS_DROPDOWN_OPEN,
      this.setProperty('isDropdownOpen')
    );
    this.handleTargeted(
      searchDropdownActions.SET_IS_OPEN_ON_FOCUS,
      this.setProperty('isOpenOnFocus')
    );
    this.handleTargeted(searchDropdownActions.SET_SEARCH_STRING, this._setSearchString);
    this.handleTargeted(searchDropdownActions.SET_SELECTED_OPTION, this._setSelectedOption);
    this.handleTargeted(searchDropdownActions.RESET_STORE, this.resetStore);
  }

  _setSelectedOption(option: any) {
    this.writeData((store) => store.set('selectedOption', option).set('isDropdownOpen', false));
  }

  _setSearchString(str: string) {
    this.writeData('searchString', str);
    if (!this.isOpenOnFocus() && str !== '') {
      this.writeData('isDropdownOpen', true);
    }
  }

  getSearchString = (): string => this.readData('searchString');

  getSelectedOption = (): any => this.readData('selectedOption');

  isDropdownOpen = (): boolean => this.readData('isDropdownOpen');

  isOpenOnFocus = (): boolean => this.readData('isOpenOnFocus');
}
