// @flow
import * as React from 'react';
import PropTypes from 'prop-types';
import {LabelModelV1} from 'resources/GeneratedModels/Label/LabelModel.v1';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {TagOrStandardGroup} from 'client/components/TagOrStandardGroup/TagOrStandardGroup.react';
import {queryBuilderPropType} from '@albert-io/json-api-framework/request/builder';

import './label-table.scss';

export default class LabelTable extends React.Component<any, any> {
  static propTypes = {
    query: PropTypes.instanceOf(queryBuilderPropType).isRequired
  };

  getDescriptionText(label: LabelModelV1): string {
    return `${label.getTitle()}: ${label.getDescription()}`;
  }

  generateTableContent = (standard: LabelModelV1, key: number): React.Node => (
    <tr key={key}>
      <td className='label-table__category-name'>{standard.getStandardSet().getTitle()}</td>
      <td>
        <span>
          <MarkdownRendererV2
            className='label-table__name'
            text={this.getDescriptionText(standard)}
          />
        </span>
      </td>
    </tr>
  );

  render(): React.Node {
    const isReady = this.props.query.isResourcePopulated();
    if (!isReady) {
      return null;
    }

    const isEmpty = this.props.query.getResource().isEmpty();
    if (isEmpty) {
      return null;
    }

    return (
      <div className='label-table'>
        <table>
          <thead>
            <tr>
              <th>Standard</th>
              <th>Description</th>
            </tr>
          </thead>
          <TagOrStandardGroup
            as='tbody'
            query={this.props.query}
            renderFunc={this.generateTableContent}
          />
        </table>
      </div>
    );
  }
}
