import {fromJS} from 'immutable';
import freeEntryQuestionActions from './FreeEntryQuestion.actions';
import QuestionTypeStore from 'client/QuestionTypes/common/V2/QuestionType.store';
import {activeQuestionActions} from 'client/InteractionEngineV2/shared/ActiveQuestion/ActiveQuestionActions';
import {GuessModel} from 'resources/Guess/Guess.model';

export default class FreeEntryQuestionStore extends QuestionTypeStore {
  constructor(name, questionId, question) {
    super(name);
    this.initialData = fromJS({
      questionId: questionId,
      openTopFive: [],
      inputValues: {},
      shouldShowExplanation: false
    });

    this.setInitialData(this.initialData);
    this.writeData('__question', question);
    this.handleTargeted(activeQuestionActions.ACTIVE_QUESTION_LOAD_GUESS, this._loadGuess);
    this.handleTargeted(freeEntryQuestionActions.UPDATE_INPUT, this._updateInput);
    this.handleTargeted(freeEntryQuestionActions.TOGGLE_TOP_FIVE, this._toggleTopFive);
  }

  _closeTopFive() {
    this.writeData('openTopFive', null);
  }

  _toggleTopFive(inputId) {
    let openTopFive = this.openTopFive.toSet();
    openTopFive = openTopFive[!openTopFive.has(inputId) ? 'add' : 'delete'](inputId);
    this.writeData('openTopFive', openTopFive);
  }

  _loadGuess(guess) {
    // Parent store needs points data from guess and needs to register this question has a guess
    super._loadGuess(guess);
    this.writeData('inputValues', guess.get('content'));
  }

  _clearGuess() {
    // Need to call this on the parent class
    super._clearGuess();
    this.writeData('inputValues', this.initialData.get('inputValues'));
  }

  buildCorrectGuess() {
    const question = this._fetchQuestion();
    return new GuessModel({
      content: question.getValidResponse().map((val) => val.first()),
      correct: true,
      rubric: question.getRubric(),
      solutionText: question.getSolutionText(),
      pointsPossible: question.getPoints(),
      pointsEarned: question.getPoints(),
      question: question
    });
  }

  buildGuessContent() {
    return this.readData('inputValues').toJS();
  }

  _updateInput({inputId, value}) {
    this.writeData((store) => store.setIn(['inputValues', inputId], value));
  }

  isTopFiveOpen(inputId) {
    return this.openTopFive.has(inputId);
  }

  get openTopFive() {
    return this.readData('openTopFive');
  }

  get selectedAnswer() {
    return this.readData('inputValues');
  }

  getValueForId(inputId) {
    return this.readData(['inputValues', inputId]);
  }
}
