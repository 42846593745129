import React, {useState} from 'react';
import {Iterable} from 'immutable';

import {Card, DropdownItem, IconButton, WithTooltip} from '@albert-io/atomic';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';

import './free-form.scss';

interface FreeFormProps {
  content: any;
  name: string;
}

const FreeForm = ({content, name}: FreeFormProps) => {
  const [isHidden, setIsHidden] = useState(true);
  const freeFormContent = Iterable.isIterable(content) ? content.toJS() : content;

  let freeFormText = freeFormContent.text;
  if (!freeFormText) {
    freeFormText = 'No content entered.';
  }

  return (
    <Card className='u-border-radius_1'>
      <DropdownItem
        as='button'
        bold={false}
        onClick={(e) => {
          e.stopPropagation();
          setIsHidden(!isHidden);
        }}
        className='u-pad_1 u-gap_2 u-border-radius_1 free-form__dropdown-item'
        data-testid='free-form__expandable-item'
      >
        <WithTooltip placement='top' content={isHidden ? 'Expand' : 'Collapse'}>
          <IconButton
            as='div'
            size='s'
            aria-hidden
            icon={isHidden ? 'chevron-right' : 'chevron-down'}
          />
        </WithTooltip>
        <MarkdownRendererV2 className='u-mar-l_2 u-pad-tb_1' text={name} />
      </DropdownItem>
      {!isHidden && (
        <div className='u-pad-lr_2 u-pad-b_2 u-pad-t_1'>
          <MarkdownRendererV2 text={freeFormText} data-testid='free-form__markdown-renderer' />
        </div>
      )}
    </Card>
  );
};

export default FreeForm;
