const maxOpacity = 1;
const minOpacityStep = 0.5;
const minOpacity = 0.1;

function preciseToFixed(number, digits) {
  return Number(Math.round(number + `e${digits}`) + `e-${digits}`);
}

function calcOpacityStep(numberOfAttempts) {
  let opacityStep = preciseToFixed(maxOpacity / numberOfAttempts, 1);

  if (opacityStep < minOpacityStep) {
    opacityStep = minOpacityStep;
  }

  return opacityStep;
}

function calcProximityForSingleAttempt({activeAttemptIndex, attemptIndex}) {
  return Math.abs(activeAttemptIndex - attemptIndex);
}

function calcOpacityForSingleAttempt({numberOfAttempts, activeAttemptIndex, attemptIndex}) {
  const opacityStep = calcOpacityStep(numberOfAttempts);
  const proximityToActiveAttempt = calcProximityForSingleAttempt({
    activeAttemptIndex,
    attemptIndex
  });
  let attemptOpacity = preciseToFixed(maxOpacity - proximityToActiveAttempt * opacityStep, 1);

  if (attemptOpacity < minOpacity) {
    attemptOpacity = minOpacity;
  }

  return attemptOpacity;
}

export {calcOpacityForSingleAttempt};
