import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {findKey, isFunction} from 'lodash';
import classnames from 'classnames';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import {callAction} from 'client/framework';
import {QUESTION_IMPORT} from 'client/constants';

import {AuthoringQuestionSet as AuthoringQuestionSetModelV1} from 'resources/GeneratedModels/AuthoringQuestionSet/AuthoringQuestionSetModel.v1';
import {QuestionSet as QuestionSetModelV1} from 'resources/GeneratedModels/QuestionSet/QuestionSetModel.v1';
import LoadingIndicator from 'generic/LoadingIndicator.react';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {isSearchMode} from 'client/components/AdvancedSearch/AdvancedSearch.utils';
import {IconButton, Popover} from '@albert-io/atomic';

import QuestionsListStore from './QuestionsListStore';
import questionsListActions from './QuestionsListActions';

import './questions-list.scss';

let questionSetsWrapperNode = null;

function autoScrollList(activeQuestionNode) {
  if (!questionSetsWrapperNode || !activeQuestionNode) {
    return;
  }
  const listTop = questionSetsWrapperNode.getBoundingClientRect().top;
  const listTopPadding = parseInt(global.getComputedStyle(questionSetsWrapperNode).paddingTop, 10);
  const visibleListPos = listTop + listTopPadding;
  const activeQuestionTop = activeQuestionNode.getBoundingClientRect().top;
  const scrollBy = activeQuestionTop - visibleListPos;
  questionSetsWrapperNode.scrollBy({
    left: 0,
    top: scrollBy - 100,
    behavior: 'smooth'
  });
}

export default class QuestionsList extends React.Component {
  static propTypes = {
    /**
     * @todo: This activeQuestionOverrideId prop is temporary. Only the question editor
     * store is using it. Since the questions list store stores the active question, we
     * should use that to source the question being edited. We can do that post-OSD. It'd
     * also let us cut down on the number of queries we're using to get the questions.
     */
    activeQuestionOverrideId: PropTypes.string,
    className: PropTypes.string,
    sortable: PropTypes.bool,
    onListScroll: PropTypes.func,
    onSortEnd: PropTypes.func,
    questionSetWidget: PropTypes.element,
    singleQuestionWidget: PropTypes.element,
    noQuestionSetsFoundEl: PropTypes.element,
    questionSets: PropTypes.oneOfType([
      ImmutablePropTypes.list,
      ImmutablePropTypes.map,
      ImmutablePropTypes.orderedSet
    ]),
    questionSetClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    singleQuestionClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    bottomDrawer: PropTypes.element,
    topDrawer: PropTypes.element,
    questionItemToGenerator: PropTypes.func,
    questionsListStore: PropTypes.instanceOf(QuestionsListStore).isRequired,
    questionSetsAreLoading: PropTypes.bool,
    shouldQuestionSetsWrapperUpdate: PropTypes.func,
    onQuestionClick: PropTypes.func,
    /**
     * Disable the question click handler.
     */
    disableQuestionClick: PropTypes.bool,
    hideQuestionTitles: PropTypes.bool,
    questionPositionMap: PropTypes.objectOf(PropTypes.number)
  };

  constructor(props) {
    super(props);
    this.questionsListNode = null;
    this.topDrawerNode = null;
    this.topDrawerHeight = null;
    this.bottomDrawerNode = null;
    this.bottomDrawerHeight = null;
  }

  componentDidMount() {
    if (this.props.sortable && !this.props.onSortEnd) {
      throw new Error('Sortable <QuestionList /> component requires an onSortEnd function');
    }
  }

  componentDidUpdate() {
    let forceUpdate = false;
    if (this.bottomDrawerNode && this.bottomDrawerNode.clientHeight !== this.bottomDrawerHeight) {
      this.bottomDrawerHeight = this.bottomDrawerNode.clientHeight;
      forceUpdate = true;
    }

    if (this.topDrawerNode && this.topDrawerNode.clientHeight !== this.topDrawerHeight) {
      this.topDrawerHeight = this.topDrawerNode.clientHeight;
      forceUpdate = true;
    }

    if (forceUpdate) {
      this.forceUpdate();
    }
  }

  render() {
    const {sortable} = this.props;
    const topDrawer = this.props.topDrawer ? (
      <div
        className='questions-list__top-drawer'
        ref={(node) => {
          this.topDrawerNode = node;
        }}
      >
        {this.props.topDrawer}
      </div>
    ) : null;
    const bottomDrawer = this.props.bottomDrawer ? (
      <div
        className='questions-list__bottom-drawer'
        ref={(node) => {
          this.bottomDrawerNode = node;
        }}
      >
        {this.props.bottomDrawer}
      </div>
    ) : null;
    const loadingIndicator = this.props.questionSetsAreLoading ? (
      <LoadingIndicator
        className='questions-list__loading-indicator'
        style={this.bottomDrawerNode ? {bottom: `${this.bottomDrawerNode.clientHeight}px`} : null}
      />
    ) : null;
    const QuestionSetsWrapperType = sortable ? SortableQuestionSetsWrapper : QuestionSetsWrapper;
    const wrapperClass = classnames('questions-list', this.props.className);
    return (
      <div
        className={wrapperClass}
        ref={(node) => {
          this.questionsListNode = node;
        }}
      >
        {topDrawer}
        <QuestionSetsWrapperType
          helperClass='questions-list-sortable-item-helper-class'
          topDrawer={!!this.props.topDrawer}
          topDrawerHeight={this.topDrawerNode ? this.topDrawerHeight : null}
          bottomDrawer={!!this.props.bottomDrawer}
          bottomDrawerHeight={this.bottomDrawerNode ? this.bottomDrawerHeight : null}
          noQuestionSetsFoundEl={this.props.noQuestionSetsFoundEl}
          onListScroll={this.props.onListScroll}
          onSortEnd={this.props.onSortEnd}
          questionsListStore={this.props.questionsListStore}
          questionItemToGenerator={this.props.questionItemToGenerator}
          questionSetClassName={this.props.questionSetClassName}
          questionSets={this.props.questionSets}
          questionSetWidget={this.props.questionSetWidget}
          singleQuestionClassName={this.props.singleQuestionClassName}
          singleQuestionWidget={this.props.singleQuestionWidget}
          sortable={sortable}
          useDragHandle
          shouldQuestionSetsWrapperUpdate={this.props.shouldQuestionSetsWrapperUpdate}
          onQuestionClick={this.props.onQuestionClick}
          disableQuestionClick={this.props.disableQuestionClick}
          hideQuestionTitles={this.props.hideQuestionTitles}
          questionPositionMap={this.props.questionPositionMap}
          activeQuestionOverrideId={this.props.activeQuestionOverrideId}
        />
        {loadingIndicator}
        {bottomDrawer}
      </div>
    );
  }
}

const SortableQuestionSetsWrapper = SortableContainer(
  ({
    sortable,
    topDrawer,
    topDrawerHeight,
    bottomDrawer,
    bottomDrawerHeight,
    noQuestionSetsFoundEl,
    onListScroll,
    onSortEnd,
    questionsListStore,
    questionSets,
    questionSetWidget,
    questionSetClassName,
    singleQuestionWidget,
    singleQuestionClassName,
    onQuestionClick,
    disableQuestionClick,
    hideQuestionTitles,
    questionPositionMap,
    activeQuestionOverrideId
  }) => {
    return (
      <QuestionSetsWrapper
        topDrawer={topDrawer}
        topDrawerHeight={topDrawerHeight}
        bottomDrawer={bottomDrawer}
        bottomDrawerHeight={bottomDrawerHeight}
        noQuestionSetsFoundEl={noQuestionSetsFoundEl}
        onListScroll={onListScroll}
        onSortEnd={onSortEnd}
        questionsListStore={questionsListStore}
        questionSetClassName={questionSetClassName}
        questionSets={questionSets}
        questionSetWidget={questionSetWidget}
        singleQuestionClassName={singleQuestionClassName}
        singleQuestionWidget={singleQuestionWidget}
        sortable={sortable}
        onQuestionClick={onQuestionClick}
        disableQuestionClick={disableQuestionClick}
        hideQuestionTitles={hideQuestionTitles}
        questionPositionMap={questionPositionMap}
        activeQuestionOverrideId={activeQuestionOverrideId}
      />
    );
  }
);

class QuestionSetsWrapper extends React.Component {
  static propTypes = {
    topDrawer: PropTypes.bool,
    topDrawerHeight: PropTypes.number,
    bottomDrawer: PropTypes.bool,
    bottomDrawerHeight: PropTypes.number,
    onListScroll: PropTypes.func,
    questionsListStore: PropTypes.instanceOf(QuestionsListStore),
    noQuestionSetsFoundEl: PropTypes.element,
    questionSets: PropTypes.oneOfType([
      ImmutablePropTypes.list,
      ImmutablePropTypes.map,
      ImmutablePropTypes.orderedSet
    ]),
    questionSetClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    singleQuestionClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    questionItemToGenerator: PropTypes.func,
    questionSetWidget: PropTypes.element,
    singleQuestionWidget: PropTypes.element,
    sortable: PropTypes.bool,
    shouldQuestionSetsWrapperUpdate: PropTypes.func,
    onQuestionClick: PropTypes.func,
    disableQuestionClick: PropTypes.bool,
    hideQuestionTitles: PropTypes.bool,
    questionPositionMap: PropTypes.objectOf(PropTypes.number)
  };

  static defaultProps = {
    shouldQuestionSetsWrapperUpdate: () => true,
    bottomDrawerHeight: 0,
    topDrawerHeight: 0
  };

  constructor(props) {
    super(props);
    this.wrapperNode = null;
  }

  componentDidMount() {
    if (this.props.onListScroll) {
      this.wrapperNode.addEventListener('scroll', this.props.onListScroll);
    }
  }

  shouldComponentUpdate(nextProps) {
    return this.props.shouldQuestionSetsWrapperUpdate(this.props, nextProps);
  }

  componentWillUnmount() {
    // @todo Check this and consider a ref so the function references remain stable
    this.wrapperNode.removeEventListener('scroll', this.props.onListScroll);
    questionSetsWrapperNode = null;
  }

  throttledOnListScroll = (e) => {
    if (this.onListScrollTicking) {
      return;
    }
    this.onListScrollTicking = true;
    this.props.onListScroll(e);
    this.onListScrollTicking = false;
  };

  render() {
    const {sortable} = this.props;
    const questionListClasses = classnames('questions-list__questions', {
      'questions-list__questions--top-drawer': this.props.topDrawer,
      'questions-list__questions--bottom-drawer': this.props.bottomDrawer
    });
    const QuestionSetItemGeneratorType = sortable
      ? SortableQuestionSetItemGenerator
      : QuestionSetItemGenerator;
    const noQuestionSetsFoundEl = this.props.noQuestionSetsFoundEl ? (
      <li>{this.props.noQuestionSetsFoundEl}</li>
    ) : null;
    const questionsContent =
      !this.props.questionSets || this.props.questionSets.isEmpty()
        ? noQuestionSetsFoundEl
        : this.props.questionSets.map((questionSet, index) => {
            return (
              <QuestionSetItemGeneratorType
                key={questionSet.getId()}
                // Why two indices when they do the same thing? index is for react-sortable-hoc, which it needs to make
                // the list sortable. The other is so that the question sets and question items have the property available
                // to them in case they need it.
                index={index}
                questionSetIndex={index}
                sortable={sortable}
                questionsListStore={this.props.questionsListStore}
                questionItemToGenerator={this.props.questionItemToGenerator}
                questionSet={questionSet}
                questionSetWidget={this.props.questionSetWidget}
                questionSetClassName={this.props.questionSetClassName}
                singleQuestionWidget={this.props.singleQuestionWidget}
                singleQuestionClassName={this.props.singleQuestionClassName}
                onQuestionClick={this.props.onQuestionClick}
                disableQuestionClick={this.props.disableQuestionClick}
                hideQuestionTitles={this.props.hideQuestionTitles}
                questionPositionMap={this.props.questionPositionMap}
                activeQuestionOverrideId={this.props.activeQuestionOverrideId}
              />
            );
          });
    return (
      <ul
        className={questionListClasses}
        ref={(node) => {
          this.wrapperNode = node;
          questionSetsWrapperNode = node;
        }}
        style={{
          paddingBottom: `${this.props.bottomDrawerHeight}px`,
          paddingTop: `${this.props.topDrawerHeight}px`
        }}
      >
        {questionsContent}
      </ul>
    );
  }
}

const SortableQuestionSetItemGenerator = SortableElement(
  ({
    sortable,
    questionsListStore,
    questionItemToGenerator,
    questionSet,
    questionSetIndex,
    questionSetWidget,
    questionSetClassName,
    singleQuestionWidget,
    singleQuestionClassName,
    onQuestionClick,
    disableQuestionClick,
    hideQuestionTitles,
    questionPositionMap,
    activeQuestionOverrideId
  }) => {
    return (
      <QuestionSetItemGenerator
        sortable={sortable}
        questionsListStore={questionsListStore}
        questionItemToGenerator={questionItemToGenerator}
        questionSet={questionSet}
        questionSetIndex={questionSetIndex}
        questionSetWidget={questionSetWidget}
        questionSetClassName={questionSetClassName}
        singleQuestionWidget={singleQuestionWidget}
        singleQuestionClassName={singleQuestionClassName}
        onQuestionClick={onQuestionClick}
        disableQuestionClick={disableQuestionClick}
        hideQuestionTitles={hideQuestionTitles}
        questionPositionMap={questionPositionMap}
        activeQuestionOverrideId={activeQuestionOverrideId}
      />
    );
  }
);

class QuestionSetItemGenerator extends React.Component {
  static propTypes = {
    sortable: PropTypes.bool,
    questionSetIndex: PropTypes.number,
    questionSetWidget: PropTypes.element,
    singleQuestionWidget: PropTypes.element,
    questionSetClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    singleQuestionClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    questionItemToGenerator: PropTypes.func,
    questionsListStore: PropTypes.instanceOf(QuestionsListStore),
    questionSet: PropTypes.oneOfType([
      PropTypes.instanceOf(QuestionSetModelV1),
      PropTypes.instanceOf(AuthoringQuestionSetModelV1)
    ]),
    onQuestionClick: PropTypes.func,
    disableQuestionClick: PropTypes.bool,
    hideQuestionTitles: PropTypes.bool,
    questionPositionMap: PropTypes.objectOf(PropTypes.number)
  };

  render() {
    const {questionSet} = this.props;
    const questionCount = questionSet.getQuestions().size;
    if (questionCount === 1) {
      const singleQuestion = questionSet.getQuestions().first();
      const {activeQuestion} = this.props.questionsListStore;
      const isActiveQuestion =
        this.props.activeQuestionOverrideId !== undefined
          ? this.props.activeQuestionOverrideId === singleQuestion.getId()
          : activeQuestion && singleQuestion.getId() === activeQuestion.getId();
      return (
        <QuestionListItem
          isActiveQuestion={isActiveQuestion}
          question={singleQuestion}
          questionSet={questionSet}
          questionSetIndex={this.props.questionSetIndex}
          questionSetClassName={this.props.questionSetClassName}
          questionSetWidget={this.props.questionSetWidget}
          questionsListStore={this.props.questionsListStore}
          questionItemToGenerator={this.props.questionItemToGenerator}
          singleQuestionClassName={this.props.singleQuestionClassName}
          singleQuestionWidget={this.props.singleQuestionWidget}
          sortable={this.props.sortable}
          onQuestionClick={this.props.onQuestionClick}
          disableQuestionClick={this.props.disableQuestionClick}
          hideQuestionTitles={this.props.hideQuestionTitles}
          questionPositionMap={this.props.questionPositionMap}
          activeQuestionOverrideId={this.props.activeQuestionOverrideId}
        />
      );
    }
    return (
      <MultipleQuestionSet
        questionSet={questionSet}
        questionSetIndex={this.props.questionSetIndex}
        sortable={this.props.sortable}
        questionsListStore={this.props.questionsListStore}
        questionItemToGenerator={this.props.questionItemToGenerator}
        questionSetWidget={this.props.questionSetWidget}
        questionSetClassName={this.props.questionSetClassName}
        singleQuestionWidget={this.props.singleQuestionWidget}
        singleQuestionClassName={this.props.singleQuestionClassName}
        hideQuestionTitles={this.props.hideQuestionTitles}
        questionPositionMap={this.props.questionPositionMap}
        onQuestionClick={this.props.onQuestionClick}
        disableQuestionClick={this.props.disableQuestionClick}
        activeQuestionOverrideId={this.props.activeQuestionOverrideId}
      />
    );
  }
}

const DragHandle = SortableHandle(() => (
  <span className='fa fa-arrows-v question-list-sortable-drag-handle' />
));

class MultipleQuestionSet extends React.Component {
  static propTypes = {
    sortable: PropTypes.bool,
    questionSet: PropTypes.oneOfType([
      PropTypes.instanceOf(QuestionSetModelV1),
      PropTypes.instanceOf(AuthoringQuestionSetModelV1)
    ]),
    questionSetIndex: PropTypes.number,
    questionSetWidget: PropTypes.element,
    questionSetClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    singleQuestionClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    questionsListStore: PropTypes.instanceOf(QuestionsListStore),
    questionItemToGenerator: PropTypes.func,
    singleQuestionWidget: PropTypes.element,
    onQuestionClick: PropTypes.func,
    disableQuestionClick: PropTypes.bool,
    hideQuestionTitles: PropTypes.bool,
    questionPositionMap: PropTypes.objectOf(PropTypes.number)
  };

  getDynamicClassNames() {
    const {questionSetClassName} = this.props;
    return isFunction(questionSetClassName)
      ? questionSetClassName(this.props.questionSet)
      : questionSetClassName;
  }

  getQuestionSetWidget() {
    if (!this.props.questionSetWidget) {
      return null;
    }
    return React.cloneElement(this.props.questionSetWidget, {questionSet: this.props.questionSet});
  }

  generateQuestions(questionSet) {
    const {activeQuestion} = this.props.questionsListStore;
    return questionSet.getQuestions().map((question, i) => {
      const isActiveQuestion =
        this.props.activeQuestionOverrideId !== undefined
          ? this.props.activeQuestionOverrideId === question.getId()
          : activeQuestion && question.getId() === activeQuestion.getId();
      return (
        <QuestionListItem
          isActiveQuestion={isActiveQuestion}
          key={question.getId() || i}
          question={question}
          questionSet={questionSet}
          questionSetIndex={this.props.questionSetIndex}
          questionsListStore={this.props.questionsListStore}
          questionItemToGenerator={this.props.questionItemToGenerator}
          singleQuestionClassName={this.props.singleQuestionClassName}
          singleQuestionWidget={this.props.singleQuestionWidget}
          onQuestionClick={this.props.onQuestionClick}
          disableQuestionClick={this.props.disableQuestionClick}
          hideQuestionTitles={this.props.hideQuestionTitles}
          questionPositionMap={this.props.questionPositionMap}
        />
      );
    });
  }

  render() {
    const {questionSet} = this.props;
    const widget = this.getQuestionSetWidget();
    const classNames = classnames('multiple-question-set', {
      [this.getDynamicClassNames()]: this.props.questionSetClassName
    });
    const title = this.props.hideQuestionTitles ? null : (
      <MarkdownRendererV2 text={questionSet.getName()} className='multiple-question-set__name' />
    );
    return (
      <li className={classNames}>
        <div className='multiple-question-set__heading'>
          {title}
          {widget}
          {this.props.sortable ? <DragHandle /> : null}
        </div>
        <ul>{this.generateQuestions(questionSet)}</ul>
      </li>
    );
  }
}

class QuestionListItem extends React.Component {
  static propTypes = {
    sortable: PropTypes.bool,
    question: ImmutablePropTypes.record,
    questionItemToGenerator: PropTypes.func,
    questionSet: PropTypes.oneOfType([
      PropTypes.instanceOf(QuestionSetModelV1),
      PropTypes.instanceOf(AuthoringQuestionSetModelV1)
    ]),
    questionSetIndex: PropTypes.number,
    questionSetWidget: PropTypes.element,
    singleQuestionWidget: PropTypes.element,
    questionSetClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    singleQuestionClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    isActiveQuestion: PropTypes.bool,
    questionsListStore: PropTypes.instanceOf(QuestionsListStore),
    onQuestionClick: PropTypes.func,
    disableQuestionClick: PropTypes.bool,
    hideQuestionTitles: PropTypes.bool,
    questionPositionMap: PropTypes.objectOf(PropTypes.number)
  };

  static defaultProps = {
    onQuestionClick: () => {},
    disableQuestionClick: false
  };

  constructor(props) {
    super(props);
    this.itemNode = React.createRef();
    this.iconButtonNode = React.createRef();
    this.state = {
      showTooltip: false,
      isSearchResult: true
    };
  }

  componentDidMount() {
    if (this.props.isActiveQuestion && this.props.questionsListStore.shouldAutoScrollOnMount()) {
      autoScrollList(this.itemNode.current);
    }
    if (isSearchMode()) {
      this.props.questionsListStore.getSearchQuestions().then((searchQuestions) => {
        const matchIndex = searchQuestions.findIndex(
          (question) => question.getId() === this.props.question.getId()
        );
        const isSearchResult = matchIndex > -1;
        this.setState({isSearchResult});
      });
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (!this.props.isActiveQuestion && nextProps.isActiveQuestion) {
      autoScrollList(this.itemNode.current);
    }
  }

  getWidget() {
    if (this.props.questionSetWidget) {
      return React.cloneElement(this.props.questionSetWidget, {
        questionSet: this.props.questionSet,
        question: this.props.question
      });
    }
    if (this.props.singleQuestionWidget) {
      return React.cloneElement(this.props.singleQuestionWidget, {question: this.props.question});
    }
    return null;
  }

  generateDifficultyIndicator(question) {
    const difficulty = findKey(QUESTION_IMPORT.DIFFICULTIES, (difficulty) => {
      return question.get('difficulty') === difficulty;
    });
    const baseClass = 'question-list-item__difficulty';
    const indicatorClass = classnames(
      baseClass,
      difficulty ? `${baseClass}--${difficulty.toLowerCase()}` : null,
      {
        [`${baseClass}--hidden`]: this.props.hideQuestionTitles
      }
    );
    return <div className={indicatorClass}>{difficulty ? difficulty.charAt(0) : '—'}</div>;
  }

  setActiveQuestion({question, questionSet}) {
    callAction(questionsListActions.SET_ACTIVE_QUESTION, question);
    callAction(questionsListActions.SET_ACTIVE_QUESTION_SET, questionSet);
  }

  getDynamicClassNames() {
    const {questionSetClassName} = this.props;
    const {singleQuestionClassName} = this.props;
    if (!questionSetClassName && !singleQuestionClassName) {
      return null;
    }
    return [
      isFunction(questionSetClassName)
        ? questionSetClassName(this.props.questionSet)
        : questionSetClassName,
      isFunction(singleQuestionClassName)
        ? questionSetClassName(this.props.question)
        : singleQuestionClassName
    ];
  }

  handleLinkClick = (e) => {
    // If clientX and clientY are 0, we've simluated the click event by clicking the
    // next or previous question buttons, and we _should_ auto scroll the list
    if (e.clientX === 0 && e.clientY === 0) {
      autoScrollList(this.itemNode.current);
    }

    // If we don't have a questionItemToGenerator, we won't have a `to` property,
    // so prevent the default link click action
    if (!this.props.questionItemToGenerator) {
      e.preventDefault();
    }
    if (this.props.disableQuestionClick === true) {
      return;
    }
    this.props.onQuestionClick();
    this.setActiveQuestion({
      question: this.props.question,
      questionSet: this.props.questionSet
    });
  };

  showTooltip = () => this.setState({showTooltip: true});

  hideTooltip = () => this.setState({showTooltip: false});

  getSearchIndicatorAndTooltip() {
    if (isSearchMode() && !this.state.isSearchResult) {
      return (
        <span ref={this.iconButtonNode}>
          <IconButton
            aria-label='Show more information about this question'
            icon='eye-slash'
            onBlur={this.hideTooltip}
            onClick={this.showTooltip}
            onFocus={this.showTooltip}
            role='trigger'
          />
          {this.state.showTooltip && (
            <Popover
              className='questions-list-item__tooltip'
              expanded={this.state.showTooltip}
              optimizeForMobile={false}
              position='right-start'
              targetRef={this.iconButtonNode}
            >
              This question does not meet your search or filter criteria. However, if the group is
              selected, this question <strong>will be included in your selection.</strong>
            </Popover>
          )}
        </span>
      );
    }
    return null;
  }

  render() {
    const {question} = this.props;
    const isActive = this.props.isActiveQuestion;
    const widget = this.getWidget();
    const classNames = classnames('question-list-item', this.getDynamicClassNames(), {
      'question-list-item--active': isActive,
      'question-list-item--disabled': this.props.disableQuestionClick,
      'question-list-item--excluded-from-search': isSearchMode() && !this.state.isSearchResult
    });

    let to = false;
    if (this.props.questionItemToGenerator) {
      to = this.props.questionItemToGenerator(
        this.props.questionSet,
        this.props.question,
        this.props.questionSetIndex
      );
    }
    const {questionPositionMap} = this.props;
    const questionNumber =
      questionPositionMap && questionPositionMap.hasOwnProperty(question.getId())
        ? `${questionPositionMap[question.getId()]} `
        : null;
    const questionTitle = this.props.hideQuestionTitles
      ? questionNumber
      : `${questionNumber ? `${questionNumber}. ` : ''}${question.getTitle()}`;
    return (
      <li className={classNames} ref={this.itemNode}>
        <Link
          className={classnames('question-list-item__content', {
            'question-list-item__content--excluded-from-search':
              isSearchMode() && !this.state.isSearchResult
          })}
          to={to || '#'}
          onClick={this.handleLinkClick}
          id={`question-id-${question.getId()}`}
        >
          {this.generateDifficultyIndicator(question)}
          <MarkdownRendererV2 text={questionTitle} className='question-list-item__title' />
          {widget}
          {this.props.sortable ? <DragHandle /> : null}
        </Link>
        {this.getSearchIndicatorAndTooltip()}
      </li>
    );
  }
}
