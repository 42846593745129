/* global window */

import React, {useEffect, useState} from 'react';
import {getOrigin} from '@albert-io/environment';
import {withRouter} from 'react-router';

import {Modal, Dialogue, Button, Logo, Text, Anchor} from '@albert-io/atomic';

interface Props {
  location: {
    pathname: string;
  };
}

export const CanvasIframeOverlay = ({location}: Props) => {
  const [isIframe, setIsIframe] = useState(false);
  const {pathname} = location;
  const domain = getOrigin();

  useEffect(() => {
    if (typeof window !== 'undefined' && window !== window.parent) {
      setIsIframe(true);
    }
  }, []);

  if (isIframe) {
    return (
      <Modal
        ariaLabel='Open Albert.io in a new window'
        backdropClassName='u-bgc_blue-800'
        className='u-display_flex u-align-items_center u-justify-content_center'
      >
        {({modalContentStyle}) => (
          <Dialogue size='m' className={`${modalContentStyle}`} hideCloseBtn>
            <Dialogue.Body
              align='center'
              className='u-display_flex u-flex-direction_column u-align-items_center'
            >
              <Logo type='mark' width='50px' />

              <Text className='u-mar-b_5 u-mar-t_4'>
                Continue this process by clicking the button below. A new tab will open in your
                browser.
              </Text>

              <Button as='a' target='_blank' href={`${domain}${pathname}`} className='u-mar-b_3'>
                Open in a new window
              </Button>
              <Anchor
                as='a'
                href='https://help.albert.io/en/articles/5350870-how-do-i-use-canvas-on-albert-as-a-teacher'
                target='_blank'
                underlined
              >
                Learn more
              </Anchor>
            </Dialogue.Body>
          </Dialogue>
        )}
      </Modal>
    );
  }
  return null;
};

export default withRouter(CanvasIframeOverlay);
