import {IconButton, WithTooltip} from '@albert-io/atomic';
import moment from 'moment';
import React, {useContext, useEffect, useState} from 'react';

import {ReportsContext} from '../Reports.context';

interface Props extends PropsWithClassNameOptional {
  isCollapsed: boolean;
}

export const RefreshButton = ({isCollapsed, className}: Props) => {
  (window as any).moment = moment;
  const {report, loading, refreshReport} = useContext(ReportsContext);
  return (
    <WithTooltip
      enabled={!!report && !loading}
      content={<RefreshButtonTooltipContent report={report} />}
      placement='bottom'
    >
      <IconButton
        variant='outlined'
        icon='refresh'
        roundness='square'
        aria-hidden
        disabled={!report || loading}
        iconProps={{spin: loading}}
        onClick={() => {
          refreshReport();
        }}
        size={isCollapsed ? 's' : 'm'}
        className={className}
      />
    </WithTooltip>
  );
};

const RefreshButtonTooltipContent = ({report}: {report: any}) => {
  useRerenderOnInterval(5000);

  if (report) {
    const completedAt = moment(report.completed_at);
    return (
      <div>
        <div>Click to refresh data</div>
        <div className='content-tertiary-inverse'>Updated {completedAt.fromNow()}</div>
      </div>
    );
  }
  return null;
};

const useRerenderOnInterval = (time: number) => {
  const [_triggerRerenderState, setTriggerRerenderState] = useState(false);
  const triggerRerender = () => setTriggerRerenderState((prev) => !prev);

  useEffect(() => {
    const interval = setInterval(() => {
      triggerRerender();
    }, time);

    return () => clearInterval(interval);
  }, [time]);
};
