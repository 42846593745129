import React from 'react';
import GenericExplanation from '../../common/V2/GenericExplanation.react';
import TextHighlightPrompt from './TextHighlightPrompt.react';

export default class TextHighlightExplanation extends React.Component {
  render() {
    const questionStoreName = this.props.questionStoreName;
    return (
      <GenericExplanation {...this.props}>
        <div className='text-highlight-explanation'>
          <h2>Correct Selections</h2>
          <TextHighlightPrompt explanation storeName={questionStoreName} isGuessSubmitted={true} />
        </div>
      </GenericExplanation>
    );
  }
}
