import React from 'react';
import PropTypes from 'prop-types';
import SimpleMarkdown from 'simple-markdown';
import {List} from 'immutable';
import {makeParser} from 'generic/MarkdownRendererV2/markdownRendererUtils';
import FillInTheBlankQuestionStore from 'client/components/QuestionTypes/FillInTheBlank/FillInTheBlankQuestion.store';
import {QuestionModelV3} from 'resources/augmented/Question/QuestionModel.v3';

import FitbDropdown from './FitbDropdown.react';

export default class DropdownRenderer extends React.Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    store: PropTypes.instanceOf(FillInTheBlankQuestionStore),
    dropdowns: PropTypes.instanceOf(List).isRequired,
    isExplanation: PropTypes.bool,
    question: PropTypes.instanceOf(QuestionModelV3),
    isGuessSubmitted: PropTypes.bool
  };

  dropdownIndex = 0;

  createDropdownElement(dropdownContent, isExplanation) {
    if (!dropdownContent) {
      return null;
    }

    const dropdownId = dropdownContent.getId();

    return (
      <FitbDropdown
        key={dropdownId}
        choices={dropdownContent.getChoices()}
        dropdownId={dropdownId}
        store={this.props.store}
        isGuessSubmitted={this.props.isGuessSubmitted}
        isExplanation={isExplanation}
        question={this.props.question}
      />
    );
  }

  createOutput() {
    const {dropdowns, isExplanation} = this.props;
    const dropdownRule = {
      order: SimpleMarkdown.defaultRules.em.order - 0.5,
      match: (source) => {
        return /^{{(dropdown)}}/.exec(source);
      },
      parse: (capture, parse, state) => {
        return {
          content: parse(capture[1], state)
        };
      },
      react: () => {
        const dropdown = this.createDropdownElement(
          dropdowns.get(this.dropdownIndex),
          isExplanation
        );
        this.dropdownIndex = this.dropdownIndex !== dropdowns.size - 1 ? this.dropdownIndex + 1 : 0;
        return dropdown;
      }
    };

    const parser = makeParser({dropdown: dropdownRule});
    const syntaxTree = parser.makeSyntaxTree(this.props.text);
    return parser.reactOutput(syntaxTree);
  }

  render() {
    return <>{this.createOutput()}</>;
  }
}
