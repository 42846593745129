import React, {useMemo, useState} from 'react';
import {Expandable, Icon, Text} from '@albert-io/atomic';

import './translation-status-manager-section.scss';
import classNames from 'classnames';

import {useQuestionEditorV2Store} from '../QuestionEditorV2Store';
import {TranslatedField} from '../QuestionEditorV2Store.types';

import {TranslationStatusManager} from './TranslationStatusManager';

interface Props {
  fieldPredicate: (field: string) => boolean;
}

export const TranslationStatusManagerSection = ({fieldPredicate}: Props) => {
  const [open, setOpen] = useState(false);
  const [expandableTriggerId] = useState(
    () => `translation-status-manager-button-${Math.random()}`
  );
  const [expandableId] = useState(() => `translation-status-manager-${Math.random()}`);

  const translatedQuestion = useQuestionEditorV2Store((state) => state.currentTranslatedQuestion());

  const requiredFields = translatedQuestion?.required_fields;
  const unfilteredTranslatedFields = translatedQuestion?.translated_fields;
  const translatedFields = useMemo(() => {
    return requiredFields?.reduce((acc, item) => {
      if (!fieldPredicate(item)) {
        return acc;
      }
      const field = unfilteredTranslatedFields?.find((f) => f.field === item);
      acc[item] = field || null;
      return acc;
    }, {} as {[field: string]: TranslatedField | null});
  }, [unfilteredTranslatedFields, fieldPredicate, requiredFields]);

  if (!translatedFields || Object.keys(translatedFields).length === 0) {
    return null;
  }

  return (
    <div className='background-subtle'>
      <button
        id={expandableTriggerId}
        aria-controls={expandableId}
        className='question-translation-editor__toggle-button'
        type='button'
        onClick={() => setOpen((prev) => !prev)}
      >
        <div className='u-display_flex u-align-items_center u-gap_space-x2'>
          <Icon
            icon='chevron-right'
            className={classNames('question-translation-editor__toggle-icon', {
              'question-translation-editor__toggle-icon--rotated': open
            })}
          />
          <Text size='m'>Translation Status</Text>
        </div>

        <TranslationStatus translatedFields={translatedFields} />
      </button>
      <Expandable expanded={open} aria-labelledby={expandableTriggerId} id={expandableId}>
        <div className='u-display_flex u-flex-direction_column u-gap_space-x2 u-pad-lr_3 u-pad-b_3 u-pad-t_2'>
          {Object.keys(translatedFields).map((field) => (
            <TranslationStatusManager
              field={field}
              translatedField={translatedFields[field]}
              key={field}
            />
          ))}
        </div>
      </Expandable>
    </div>
  );
};

interface TranslationStatusProps {
  translatedFields: {[field: string]: TranslatedField | null};
}

const TranslationStatus = ({translatedFields}: TranslationStatusProps) => {
  const allFieldsPublished = useMemo(
    () => Object.values(translatedFields).every((field) => field && field.status === 'published'),
    [translatedFields]
  );

  const noFieldsGenerated = useMemo(
    () => Object.values(translatedFields).every((field) => field === null),
    [translatedFields]
  );

  if (allFieldsPublished) {
    return (
      <div className='u-display_flex u-align-items_center u-gap_space-x1'>
        <Text size='m' color='positive'>
          <Icon icon={['fal', 'circle-check']} className='u-mar-r_1' />
          Published
        </Text>
      </div>
    );
  }

  if (noFieldsGenerated) {
    return (
      <div className='u-display_flex u-align-items_center u-gap_space-x1'>
        <Text size='m' color='secondary'>
          Translations not yet generated
        </Text>
      </div>
    );
  }

  return (
    <div className='u-display_flex u-align-items_center u-gap_space-x1'>
      <Text size='m' color='warning'>
        <Icon icon={['fal', 'hand']} className='u-mar-r_1' />
        Needs Review
      </Text>
    </div>
  );
};
