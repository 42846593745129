import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {Map} from 'immutable';
import sessionStore from 'client/Session/SessionStore';
import masqueradeStore from 'generic/Masquerade/Masquerade.store';
import {resource} from '@albert-io/json-api-framework/request/builder';
import awaitMandarkQueries from 'lib/hocs/awaitMandarkQueries';
import SubjectCard from 'client/generic/SubjectMenuRenderer/SubjectCard/SubjectCard.react';
import {getActiveClassroomsWithSubjectsQuery} from 'generic/SubjectMenuRenderer/helpers/queries';
import SubjectCourseCard from 'generic/SubjectMenuRenderer/SubjectCourseCard/SubjectCourseCard.react';
import {ClassroomModelV1} from 'resources/GeneratedModels/Classroom/ClassroomModel.v1';
import EmptyStateCta from 'client/Classrooms/Teacher/EmptyStateCta/EmptyStateCta.react';

import {Heading} from '@albert-io/atomic';

import './class-subjects.scss';

/*
 * @todo: remove `SubjectCard` code once the classroom creation wizard is updated
 *
 * This file has added complexity to support the older SubjectCard component that is being used within marketplace and the classroom creation wizard.
 * Normally, added sidebar components are excluded from these two areas but we wanted to have acces to this component within the classroom creation wizard.
 * Instead of updating the newer `CourseCard` component to have the add class functionality, it was easier to optionally display the older `SubjectCard`
 * component since the classroom creation wizard will soon be updated to direct straight to the course library instead of being its own instance of `SubjectMenuRenderer`
 *
 * All parts of the file that can be removed once class creation is updated have been noted throughout the file
 */
const ClassSubjects = ({
  allCourses,
  makeSubjectCardHref,
  classrooms,
  isClassCreation,
  makeSubjectCardContent,
  viewportWidth,
  useLongSubjectCards,
  longDirection,
  emptyState,
  isSearching,
  isUserLicensedTeacher,
  activeClassrooms,
  searchString
}) => {
  const subjectsInClassrooms = useMemo(() => {
    let subjectMappings = Map();
    const subjectIds = allCourses.map((subject) => subject.getId());
    classrooms.forEach((classroom) => {
      classroom.getSubjects().forEach((subject) => {
        if (
          !subjectMappings.has(subject.getId()) &&
          !subject.isHidden() &&
          subjectIds.has(subject.getId())
        ) {
          subjectMappings = subjectMappings.set(subject.getId(), subject);
        }
      });
    });
    return subjectMappings.valueSeq().toArray();
  }, [allCourses, classrooms]);

  const courseCards = subjectsInClassrooms
    .sort((a, b) => (a.getName() > b.getName() ? 1 : -1))
    .map((subject) => {
      /* color const and Subject card can be removed once the classroom creation subject menu is removed */
      const color =
        !subject.getCurriculumArea().isEmpty() && subject.getCurriculumArea().getColor();
      return !isClassCreation ? (
        <SubjectCourseCard
          isAssessmentsOnly={subject
            .getGuides()
            .every((guide) => guide.getGuideType() === 'assessment')}
          key={subject.getId()}
          subject={subject}
          to={makeSubjectCardHref(subject)}
          content={subject.getDescription()}
          className='content-discovery-results__course'
          isUserLicensedTeacher={isUserLicensedTeacher}
          activeClassrooms={activeClassrooms}
        />
      ) : (
        <SubjectCard
          color={color}
          title={subject.getName()}
          key={subject.getId()}
          long={useLongSubjectCards}
          longDirection={longDirection}
          subject={subject}
          content={makeSubjectCardContent(subject, viewportWidth)}
          className='content-discovery-results__course'
        />
      );
    });

  if (classrooms.isEmpty()) {
    return (
      <EmptyStateCta>
        <EmptyStateCta.Heading>No class subjects</EmptyStateCta.Heading>
        <EmptyStateCta.Text>
          Subjects that are used in your classrooms will appear here. To get started, create a
          class.
        </EmptyStateCta.Text>
        <EmptyStateCta.Button to='/classes?createClassroomModal'>
          Create a class
        </EmptyStateCta.Button>
        <EmptyStateCta.SubText>
          Clicking this link will take you to your Classes page, where you will be prompted to
          create a new class.
        </EmptyStateCta.SubText>
      </EmptyStateCta>
    );
  }

  if (subjectsInClassrooms.length === 0 && !isSearching) {
    return (
      <EmptyStateCta>
        <EmptyStateCta.Heading>No class subjects</EmptyStateCta.Heading>
        <EmptyStateCta.Text>
          To get started, create an assignment or open subjects to allow independent practice for
          your students.
        </EmptyStateCta.Text>
        <EmptyStateCta.Anchor href='https://help.albert.io/en/articles/3816818-allow-students-to-independently-practice-a-subject'>
          Learn more about independent practice
        </EmptyStateCta.Anchor>
      </EmptyStateCta>
    );
  }

  return (
    <div className='class-subjects'>
      <Heading className='u-mar-t_2 u-mar-b_4 u-mar-r_4' as='h2'>
        {searchString ? `Results for "${searchString}"` : 'Class Subjects'}
      </Heading>
      {courseCards.length !== 0 ? (
        <div
          className={classnames({
            'class-subjects__subject-cards-container': isClassCreation,
            'class-subjects__course-cards-container': !isClassCreation
          })}
        >
          {courseCards}
        </div>
      ) : (
        emptyState
      )}
    </div>
  );
};

ClassSubjects.propTypes = {
  makeSubjectCardHref: PropTypes.func,
  classrooms: ImmutablePropTypes.list,
  allCourses: ImmutablePropTypes.orderedSet,
  isClassCreation: PropTypes.bool,
  /* All props below can be removed once the classroom subject menu is removed */
  makeSubjectCardContent: PropTypes.func,
  viewportWidth: PropTypes.number,
  useLongSubjectCards: PropTypes.bool,
  longDirection: PropTypes.oneOf(['normal', 'reverse']),
  emptyState: PropTypes.node,
  isSearching: PropTypes.bool,
  isUserLicensedTeacher: PropTypes.bool,
  activeClassrooms: ImmutablePropTypes.listOf(PropTypes.instanceOf(ClassroomModelV1)),
  searchString: PropTypes.string
};

const subjectsInClassroomsQuery = () => {
  return resource('classrooms_v1')
    .mandarkEndpoint(['teachers_v1', masqueradeStore.getUserIdByMasqueradeState(), 'classrooms_v1'])
    .include('subjects_v2.curriculum_area_v1')
    .include('subjects_v2.guides_v1')
    .pageSize(250);
};

export default awaitMandarkQueries(() => {
  const queries = {
    queries: {
      classrooms: subjectsInClassroomsQuery()
    }
  };
  /**
   * @todo A hidden assumption that you can only masquerade as a teacher is being made here.
   */
  if (sessionStore.isTeacher() || masqueradeStore.isMasquerading()) {
    queries.queries.activeClassrooms = getActiveClassroomsWithSubjectsQuery();
  }
  return queries;
}, ClassSubjects);
