import React from 'react';
import moment from 'moment';
import {setUpStore} from 'client/framework';
import SelectableDateRange from 'generic/SelectableDateRange/SelectableDateRange.react';
import SelectableDateRangeStore from 'generic/SelectableDateRange/SelectableDateRange.store';
import {dateRangeOptions, RANGES} from 'generic/SelectableDateRange/shared';
import {SEARCH_PARAMETERS, getURLSearchParameter} from '../URLSearchParameters';
import {pushQueryParams, removeQueryParams} from 'client/history';
import {QUESTIONSET_STATUSES} from 'client/constants';
import './date-filters.scss';

const filteredDateRangeOptions = dateRangeOptions
  .filter((option) => [RANGES.ALL_TIME, RANGES.THIS_SCHOOL_YEAR].includes(option.get('name')))
  .map((item) => item.set('shouldHideDateRangeSelector', true));

/**
 * Tried using lodash's omitBy, but our version of lodash doesn't have it.
 *
 * @todo: Remove this and use lodash's omitBy once we bump our lodash.
 */
const omitBy = (obj, func) =>
  Object.entries(obj).reduce((acc, [key, value]) => {
    if (func(value, key)) {
      acc[key] = value;
    }
    return acc;
  }, {});

export const selectableDateRangeStoreName = 'QueueDateFiltersStore';

export class DateFilters extends React.Component {
  store = setUpStore(SelectableDateRangeStore, selectableDateRangeStoreName);

  onDateChange = () => {
    const dateRangeStore = this.store.getDateRangeStore();
    const startDate = dateRangeStore.getStartDate();
    const endDate = dateRangeStore.getEndDate();
    const dateFilters = {
      [SEARCH_PARAMETERS.publishDateStart.parameter]: startDate
        ? moment(startDate).valueOf()
        : null,
      [SEARCH_PARAMETERS.publishDateEnd.parameter]: endDate
        ? moment(endDate)
            .endOf('day')
            .valueOf()
        : null
    };
    const add = omitBy(dateFilters, (val) => val);
    const remove = omitBy(dateFilters, (val) => !val);
    const removeKeys = Object.keys(remove);
    if (removeKeys.length) {
      removeQueryParams(...removeKeys);
    }
    if (Object.keys(add).length) {
      pushQueryParams(add);
    }
  };

  isDisabled() {
    return getURLSearchParameter(SEARCH_PARAMETERS.status) !== QUESTIONSET_STATUSES.PUBLISHED;
  }

  render() {
    return (
      <SelectableDateRange
        label='Date published'
        className='queue-date-filters'
        options={filteredDateRangeOptions}
        defaultDateRange={RANGES.ALL_TIME}
        onDateChange={this.onDateChange}
        storeName={this.store.getName()}
        disabled={this.isDisabled()}
      />
    );
  }
}
