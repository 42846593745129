import {fromJS} from 'immutable';
import {Store} from 'client/framework';
import paginationActions from './Pagination.actions';

export default class PaginationV2Store extends Store {
  constructor(name) {
    super(name);
    const windowSize = 10;

    this.initialData = fromJS({
      activePage: 1,
      /*
        These values  are used to show a changing 'window' into our list
        of page buttons.

        windowStart begins at 0 because our list of number buttons, like
        any list in JS is 0-indexed array[0] is our first page button - the
        page button jsut happens to have `1` as its value.

        To summarize, the window start/end values refer to list indices.
      */
      listWindowStart: 0,
      listWindowEnd: windowSize,
      windowSize: windowSize
    });

    this.setInitialData(this.initialData);
    this.handleTargeted(paginationActions.SET_ACTIVE_PAGE, this._setActivePage);
    this.handleTargeted(paginationActions.RESET_STORE, this._resetStore);
    this.handleTargeted(paginationActions.SET_PAGE_LIST_WINDOW_INDICES, this._setListWindowIndices);
  }

  _setActivePage(pageNumber) {
    this.writeData('activePage', pageNumber);
  }

  _setListWindowIndices(indices) {
    this.writeData((state) => {
      return (
        state
          .set('listWindowStart', indices.get('listWindowStart'))
          .set('listWindowEnd', indices.get('listWindowEnd'))
          /*
          Set the active page to the first item in the 'new window'

          the + 1 is happening here because the ACTIVE PAGE actually is
          not a one to one mapping of list indices. The active page will be 1,
          but the actual list index is 0, etc.
        */
          .set('activePage', indices.get('listWindowStart') + 1)
      );
    });
  }

  _resetStore() {
    this.writeData(this.initialData);
  }

  get activePage() {
    return this.readData('activePage');
  }

  get windowSize() {
    return this.readData('windowSize');
  }

  get listWindowStart() {
    return this.readData('listWindowStart');
  }

  get listWindowEnd() {
    return this.readData('listWindowEnd');
  }
}
