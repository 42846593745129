import React from 'react';
import PropTypes from 'prop-types';
import {Breadcrumb} from './Breadcrumb.react';

export class BreadcrumbList extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.instanceOf(Breadcrumb),
      PropTypes.arrayOf(PropTypes.instanceOf(Breadcrumb))
    ])
  };

  render() {
    return (
      <nav aria-label='Breadcrumb' className='hero-unit__breadcrumbs-wrapper'>
        <ul className='hero-unit__breadcrumbs'>
          {this.props.children.map((child, i) => {
            return (
              <li
                className='hero-unit__breadcrumb-item hero-unit__text hero-unit__text--superscript'
                key={i}
              >
                {child}
              </li>
            );
          })}
        </ul>
      </nav>
    );
  }
}
