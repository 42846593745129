import React from 'react';
import PropTypes from 'prop-types';
import {Map} from 'immutable';
import {Link} from 'react-router';
import classnames from 'classnames';
import {callTargetedAction} from 'client/framework';
import Drawer, {colorSchemes} from 'generic/Drawer/Drawer.react';
import {drawerActions} from 'client/generic/Drawer/DrawerActions';
import {getFeatureFlagStatus} from 'client/components/FeatureFlag/FeatureFlag.react';

import {getNavigationLinksForPath} from '../navLinks';
import {HelpCenterLink} from '../NavWidgets/NavWidgets.react';

import './global-nav-drawer.scss';

export default class GlobalNavDrawer extends React.Component {
  static propTypes = {
    pathname: PropTypes.string
  };

  constructor() {
    super();
    this.getGlobalAssignmentsEnabled();
  }

  state = {
    globalAssignmentsEnabled: false
  };

  async getGlobalAssignmentsEnabled() {
    const globalAssignmentsFeatureFlag = await getFeatureFlagStatus('global_assignments');
    this.setState({globalAssignmentsEnabled: globalAssignmentsFeatureFlag});
  }

  isMatchingRoute(link) {
    const {pathname} = this.props;
    const linkRoute = link.get('route');
    let isMatchingRoute = false;
    if (
      /**
       * This ensures that the Subjects header link will contain the active class
       * regardless of which group/subject combination is rendered. It will be replaced soon.
       */
      (link.has('matchingRoutes') && link.get('matchingRoutes').contains(pathname)) ||
      linkRoute === pathname ||
      pathname.startsWith(linkRoute) ||
      (link.has('matchingRoutes') &&
        link
          .get('matchingRoutes')
          .filter((path) => pathname.indexOf(path) === 0)
          .count() > 0)
    ) {
      isMatchingRoute = true;
    }
    return isMatchingRoute;
  }

  handleOpenGlobalDrawer = () => {
    callTargetedAction({
      name: drawerActions.DRAWER_SET_DRAWER_VISIBILITY,
      payload: true,
      targetStore: 'GloablNavDrawerStore'
    });
  };

  toggleIconOnKeyPress = (e) => {
    if (e.which === 13) {
      // Return key
      this.handleOpenGlobalDrawer(e);
    }
  };

  render() {
    const links = getNavigationLinksForPath(this.props.pathname, {
      globalAssignmentsEnabled: this.state.globalAssignmentsEnabled
    });
    return (
      <div
        className='global-nav-drawer'
        onClick={this.handleOpenGlobalDrawer}
        role='button'
        tabIndex='0'
      >
        <span
          className='global-nav-drawer__toggle'
          role='button'
          onKeyDown={this.toggleIconOnKeyPress}
          tabIndex={0}
        >
          Menu
        </span>
        <Drawer
          colorScheme={colorSchemes.BLUE}
          direction='left'
          hasCloseButton={false}
          drawerTitle='Menu'
          storeName='GloablNavDrawerStore'
        >
          <div className='global-nav-drawer__links-wrapper'>
            {links.map((link, key) => {
              const isMatchingRoute = this.isMatchingRoute(link);
              const linkClasses = classnames('global-nav-drawer__link', {
                'global-nav-drawer__link--active': isMatchingRoute
              });

              if (link.has('dropdownLinks')) {
                return <Dropdown link={link} />;
              }

              return link.get('hardLink', false) ? (
                <a key={key} href={link.get('route')} className={linkClasses}>
                  {link.get('name')}
                </a>
              ) : (
                <Link
                  key={key}
                  to={link.get('route')}
                  className={linkClasses}
                  onClick={(e) => {
                    e.stopPropagation();
                    callTargetedAction({
                      name: drawerActions.DRAWER_SET_DRAWER_VISIBILITY,
                      payload: false,
                      targetStore: 'GloablNavDrawerStore'
                    });
                  }}
                >
                  {link.get('name')}
                </Link>
              );
            })}
            <HelpCenterLink className='global-nav-drawer__link' />
          </div>
        </Drawer>
      </div>
    );
  }
}

class Dropdown extends React.Component {
  static propTypes = {
    link: PropTypes.instanceOf(Map)
  };

  state = {
    isOpen: false
  };

  toggleDropdown = () => {
    const {isOpen} = this.state;
    this.setState({isOpen: !isOpen});
  };

  render() {
    const {link} = this.props;
    const dropdownClasses = classnames('global-nav-drawer__link-dropdown', {
      'global-nav-drawer__link-dropdown--open': this.state.isOpen
    });

    return (
      <div
        className='global-nav-drawer__link global-nav-drawer__link-dropdown-wrapper'
        onClick={this.toggleDropdown}
        role='button'
        tabIndex={0}
      >
        {link.get('name')} <span className='fa fa-caret-down' />
        <div className={dropdownClasses}>
          {link.get('dropdownLinks').map((link, key) => {
            return link.get('hardLink', false) ? (
              <a href={link.get('route')} key={key}>
                {link.get('name')}
              </a>
            ) : (
              <Link
                key={key}
                to={link.get('route')}
                onClick={(e) => {
                  e.stopPropagation();
                  callTargetedAction({
                    name: drawerActions.DRAWER_SET_DRAWER_VISIBILITY,
                    payload: false,
                    targetStore: 'GloablNavDrawerStore'
                  });
                }}
              >
                {link.get('name')}
              </Link>
            );
          })}
        </div>
      </div>
    );
  }
}
