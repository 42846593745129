import React, {useCallback, useContext, useState, useEffect, useRef} from 'react';
import {withRouter} from 'react-router';

import {pushQueryParams} from 'client/history';
import {DropdownItem, Icon, Popover, WithToggle, WithTooltip} from '@albert-io/atomic';
import {getNumberWithSuffix} from 'lib/stringUtils';

import {ReportsContext} from '../../Reports.context';
import {DIMENSIONS} from '../../reports.utils';

import './filters.scss';
import ChipButton from './ChipButton';

interface AttemptOption {
  id: string;
  value: number | 'mostRecent';
  label: string;
}

function AttemptFilter() {
  const buttonRef = useRef(null);
  const {loading, attemptNumber, path} = useContext(ReportsContext);

  const [options, setOptions] = useState<AttemptOption[]>([]);

  const handleChange = useCallback((attempt) => {
    pushQueryParams({attempt});
  }, []);

  useEffect(() => {
    const updatedOptions: AttemptOption[] = [];

    for (let num = 1; num <= 10; num += 1) {
      updatedOptions.push({
        id: `${num}`,
        value: num,
        label: `${num}`
      });
    }

    updatedOptions.unshift({id: 'mostRecent', value: 'mostRecent', label: 'Most recent'});

    setOptions(updatedOptions);
  }, []);

  const hasAssignmentInDrilldown =
    path.includes(DIMENSIONS.assignments) || path.includes('gradebook');

  return (
    <WithToggle dismissOnEscape>
      {({onClick, on}) => {
        const attemptLabel =
          attemptNumber === 'mostRecent'
            ? 'Most Recent Attempt'
            : `${getNumberWithSuffix(attemptNumber)} attempt`;
        return (
          <>
            <WithTooltip
              enabled={hasAssignmentInDrilldown}
              content='Assignments only have one attempt'
              placement='bottom'
              anchoredFixedPositionProps={{
                modifiers: {
                  preventOverflow: {
                    boundariesElement: 'viewport'
                  }
                }
              }}
            >
              <ChipButton
                onClick={onClick}
                buttonRef={buttonRef}
                disabled={loading || hasAssignmentInDrilldown}
              >
                {attemptLabel}
                <Icon icon='caret-down' />
              </ChipButton>
            </WithTooltip>
            {on && (
              <Popover
                usePortal={false}
                border='none'
                expanded={on}
                position='bottom-start'
                paddingLevel={null}
                modifiers={{
                  flip: {enabled: false},
                  preventOverflow: {
                    boundariesElement: 'viewport'
                  }
                }}
                targetRef={buttonRef}
                className='attempt-filter_tray'
              >
                {options.map((option) => {
                  const selected = option.value === attemptNumber;
                  return (
                    <DropdownItem
                      key={option.id}
                      tabIndex={0}
                      onClick={() => handleChange(option.value)}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          handleChange(option.value);
                        }
                      }}
                      className='attempt-filter_dropdown-item'
                    >
                      <div className='attempt-filter_dropdown-item-content'>
                        {option.label}
                        {selected && (
                          <Icon
                            className='u-mar-l_2'
                            icon='check'
                            iconStyle='regular'
                            emphasis='lowest'
                          />
                        )}
                      </div>
                    </DropdownItem>
                  );
                })}
              </Popover>
            )}
          </>
        );
      }}
    </WithToggle>
  );
}

export default withRouter(AttemptFilter);
