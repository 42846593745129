import {GuessModelV1} from 'resources/GeneratedModels/Guess/GuessModel.v1';

GuessModelV1.extend({
  async submitGuess({userId, assignmentId = false, questionType}) {
    const saveArgs = {
      customQuery: {
        include: [
          'question_v3.question_set_v1.subject_v2',
          'question_v3.standards_v1',
          'question_v3.standards_v1.standard_set_v1',
          'question_v3.question_set_v1.tags_v1'
        ].join(','),
        customQuery: {
          with_meta: 'question_v3,standard_v1',
          meta: {
            context: {
              user: {
                id: userId,
                personalization_settings: true
              }
            }
          }
        }
      }
    };
    if (questionType === 'graph-contains') {
      saveArgs.customQuery.customQuery.with_meta += ',guess_v1';
      saveArgs.customQuery.customQuery.meta.context.correctness_context = true;
    }
    return this.updateRelationship('assignment_v3', assignmentId, assignmentId).save(saveArgs);
  }
});

export {GuessModelV1};
