// @flow
/**
 * This file contains shared functions and base queries used throughout the question queue.
 */
import {resource, type QueryBuilder} from '@albert-io/json-api-framework/request/builder';
import {fromJS} from 'immutable';
import {pick} from 'lodash';
import {QUESTIONSET_STATUSES} from 'client/constants';
import {
  SEARCH_PARAMETERS,
  getDefaultParameters,
  getURLSearchParameter,
  hasValidURLSearchParameter
} from 'client/Dennis/Content/Queue/QueueSearchPanel/URLSearchParameters';
import sessionStore from 'client/Session/SessionStore';
import appStore from 'client/AppStore';
import {history} from 'client/history';

/**
 * Retrieves queue path based on the current user's session.
 */
export function getQueuePathFromSession(): string {
  return !sessionStore.isSuper() ? '/authoring/queue' : '/dennis/content/queue';
}
/**
 * Retrieves the queue path based on the current location.
 *
 * @todo It might make sense to update this to use "zones", but we might be merging these paths anyway.
 */
export function getQueuePathFromRoute(): string {
  const {pathname} = appStore.routerProps.location;
  return pathname.startsWith('/dennis/content/queue') === true
    ? '/dennis/content/queue'
    : '/authoring/queue';
}

/**
 * Returns a question set path with no questionSetId or questionId params, plus the current search.
 */
export function getQueueNewQuestionSetPath(): string {
  return getQueuePathFromRoute() + appStore.routerProps.location.search;
}

/**
 * Gets the edit path for a question set (and question) using the `getQueuePathFromRoute` method
 * and* appends the current search. This method is used for edit links presented within the queue.
 *
 * @param questionSetId
 * @param questionId
 */
export function getQueueQuestionEditPath(questionSetId: string, questionId: ?string): string {
  const path = `${getQueuePathFromRoute()}/${questionSetId}`;
  return (questionId ? `${path}/${questionId}` : path) + appStore.routerProps.location.search;
}
/**
 * Returns the edit path for a given question set (and question). This method uses the `getQueuePathFromSession`
 * method, making it useful for calls outside of a queue context (`ActiveQuestion`)
 *
 * @param root0
 * @param root0.questionSetId
 * @param root0.questionId
 * @param root0.customSearch
 */
export function getQuestionEditPath({
  questionSetId,
  questionId,
  customSearch = {}
}: {
  questionSetId: string,
  questionId: ?string,
  customSearch: Object
}): string {
  const questionSetPath = `${getQueuePathFromSession()}/${questionSetId}`;
  const path = questionId ? `${questionSetPath}/${questionId}` : questionSetPath;
  return Object.entries({...getDefaultParameters(), ...customSearch}).reduce(
    (acc, [param, value], i, arr) => {
      if (value === undefined) {
        return acc;
      }
      return `${acc}${param}=${value}${i + 1 !== arr.length ? '&' : ''}`;
    },
    `${path}?`
  );
}

/**
 * Returns the questionSetId and questionId URL params. We were previously using appStore.routerProps.params to get
 * these, but when creating new question sets, it's possible that the router props are out of sync with the URL
 * (e.g. in the event of a pushState). This ensures we're sourcing the params from what's currently in the URL.
 */
export function getQuestionSetParams(): {
  questionSetId?: string,
  questionId?: string
} {
  if (!process.env.IS_BROWSER) {
    return {};
  }
  const basePath = getQueuePathFromRoute();
  const {pathname} = history.getCurrentLocation();
  return pathname
    .replace(basePath, '')
    .split('/')
    .filter((item) => item !== '')
    .reduce((acc, id, index) => {
      if (index === 0) {
        acc.questionSetId = id;
      }
      if (index === 1) {
        acc.questionId = id;
      }
      return acc;
    }, {});
}

export function getSubjectAuthorPermissionsQuery(): QueryBuilder {
  return resource('authors_v1')
    .mandarkEndpoint(['authors_v1', sessionStore.getUserId(), 'subject_author_permissions_v1'])
    .include('authoring_subject_v1')
    .pageSize('infinite');
}

export const STATUSES = fromJS([
  {
    name: 'All',
    id: ''
  },
  {
    name: 'Rejected',
    id: QUESTIONSET_STATUSES.REJECTED
  },
  {
    name: 'Draft',
    id: QUESTIONSET_STATUSES.DRAFT_MODE
  },
  {
    name: 'In Review',
    id: QUESTIONSET_STATUSES.IN_REVIEW
  },
  {
    name: 'Published',
    id: QUESTIONSET_STATUSES.PUBLISHED
  },
  {
    name: 'Hidden',
    id: QUESTIONSET_STATUSES.HIDDEN
  },
  {
    name: 'Archived',
    id: QUESTIONSET_STATUSES.ARCHIVED
  }
]);

function getCategoryFilterFromAlignmentParameter(alignment: ?string): Object {
  const filter = {};
  if (alignment === 'exam') {
    filter.category = 'exam';
  } else if (alignment === 'subject') {
    filter.category = 'guide';
  } else if (alignment === 'none') {
    filter.category = {null: true};
  }
  return filter;
}

export function getQueueFiltersFromQuery(query: Object): Object {
  return pick(query, Object.keys(SEARCH_PARAMETERS));
}

export function getQuestionSetsQuery(): QueryBuilder {
  const userId = sessionStore.getUserId();

  return resource('authoring_question_sets_v1')
    .mandarkEndpoint(['authoring_question_sets_v1'])
    .include('authoring_questions_v1')
    .include('authoring_guide_levels_v1')
    .withMeta('authoring_question_set_v1')
    .filter(
      (() => {
        // Currently, resource doesn't let us apply two filters with the same keys separately.
        // Therefore, we're gonna construct our authoring_subject_v1 filter in one go.
        const baseSubjectQuery = {};
        if (!sessionStore.isSuper()) {
          // If the user is a regular author, we need to scope the
          // question sets to the subjects they're allowed to author
          baseSubjectQuery.authors_v1 = {
            id: userId
          };
        }
        if (hasValidURLSearchParameter(SEARCH_PARAMETERS.subject)) {
          // If there's a subject search param, we want to scope the question sets to that subject only
          baseSubjectQuery.id = getURLSearchParameter(SEARCH_PARAMETERS.subject);
        }
        return Object.keys(baseSubjectQuery).length ? {authoring_subject_v1: baseSubjectQuery} : {};
      })()
    )
    .filter(
      {
        // If the user is a regular author, we need to scope the
        // question sets to the subjects they're allowed to author
        authoring_subject_v1: {
          authors_v1: {
            id: userId
          }
        }
      },
      !sessionStore.isSuper()
    )
    .filter(
      {
        'meta.duplicate_alignment': true
      },
      getURLSearchParameter(SEARCH_PARAMETERS.flag) === 'duplicate_alignment'
    )
    .customQuery(
      {
        meta: {
          context: {
            guide_level: {
              duplicate_aligned: true
            }
          }
        }
      },
      getURLSearchParameter(SEARCH_PARAMETERS.flag) === 'duplicate_alignment'
    )
    .filter({
      'meta.count_of_questions': {
        greater_than: 0
      }
    })
    .filter(
      {
        published_at: {
          greater_than_inclusive: getURLSearchParameter(SEARCH_PARAMETERS.publishDateStart)
        }
      },
      hasValidURLSearchParameter(SEARCH_PARAMETERS.publishDateStart)
    )
    .filter(
      {
        published_at: {
          less_than_inclusive: getURLSearchParameter(SEARCH_PARAMETERS.publishDateEnd)
        }
      },
      hasValidURLSearchParameter(SEARCH_PARAMETERS.publishDateEnd)
    )
    .filter(
      {
        status: getURLSearchParameter(SEARCH_PARAMETERS.status)
      },
      hasValidURLSearchParameter(SEARCH_PARAMETERS.status)
    )
    .filter(
      {
        author_v1: getURLSearchParameter(SEARCH_PARAMETERS.author)
      },
      hasValidURLSearchParameter(SEARCH_PARAMETERS.author)
    )
    .filter(
      {
        authoring_questions_v1: {
          question_type: {
            exact: getURLSearchParameter(SEARCH_PARAMETERS.type)
          }
        }
      },
      hasValidURLSearchParameter(SEARCH_PARAMETERS.type)
    )
    .filter(
      getCategoryFilterFromAlignmentParameter(getURLSearchParameter(SEARCH_PARAMETERS.alignment)),
      hasValidURLSearchParameter(SEARCH_PARAMETERS.alignment)
    )
    .filter(
      {
        difficulty: getURLSearchParameter(SEARCH_PARAMETERS.difficulty)
      },
      hasValidURLSearchParameter(SEARCH_PARAMETERS.difficulty)
    )
    .filter(
      {
        any_of: [
          {
            name: {
              case_insensitive_substring: getURLSearchParameter(SEARCH_PARAMETERS.search)
            }
          },
          {
            authoring_questions_v1: {
              title: {
                case_insensitive_substring: getURLSearchParameter(SEARCH_PARAMETERS.search)
              }
            }
          }
        ]
      },
      hasValidURLSearchParameter(SEARCH_PARAMETERS.search)
    )
    .filter(
      {
        any_of: [
          {
            authoring_guide_levels_v1: getURLSearchParameter(SEARCH_PARAMETERS.guideLevelIds)
          }
        ]
      },
      hasValidURLSearchParameter(SEARCH_PARAMETERS.guideLevelIds)
    )
    .filter(
      {
        id: getURLSearchParameter(SEARCH_PARAMETERS.questionSetId)
      },
      hasValidURLSearchParameter(SEARCH_PARAMETERS.questionSetId)
    )
    .sort(
      getURLSearchParameter(SEARCH_PARAMETERS.sort),
      hasValidURLSearchParameter(SEARCH_PARAMETERS.sort)
    )
    .page(
      getURLSearchParameter(SEARCH_PARAMETERS.page),
      hasValidURLSearchParameter(SEARCH_PARAMETERS.page)
    )
    .customQuery(
      {
        page: {
          fallback_page: getURLSearchParameter(SEARCH_PARAMETERS.fallbackPage)
        }
      },
      hasValidURLSearchParameter(SEARCH_PARAMETERS.fallbackPage)
    );
}
