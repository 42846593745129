import sessionStore from 'client/Session/SessionStore';
import {history} from 'client/history';
import {getDoesStudentHaveActiveClassroomQuery} from 'lib/UserAccessUtil';

export async function redirect({location} = {}) {
  let target = null;

  if (location) {
    target = getLocationRedirectTarget(location.query);
  }

  if (sessionStore.hasValidSession()) {
    target = await getUserTypeRedirectTarget();
  }

  if (
    sessionStore.isAuthor() &&
    !sessionStore.isSuper() &&
    location &&
    location.pathname &&
    location.pathname.startsWith('/dennis')
  ) {
    target = location.pathname.startsWith('/dennis/content/queue')
      ? location.pathname.replace('/dennis/content/queue', '/authoring/queue') + location.search
      : '/authoring/queue';
  }

  if (target) {
    history.pushState(null, target);
  } else {
    /**
     * The default case is to take a user home.
     *
     * This is tripped if a user is
     * 1. logged out
     * 2. no `location` is provided
     */
    global.document.location = '/';
  }
}

function getLocationRedirectTarget(query) {
  if (query && query.classroomId && query.classroomInvitationId) {
    return `/classes?classroomId=${query.classroomId}&classroomInvitationId=${query.classroomInvitationId}`;
  }

  return null;
}

export async function getUserTypeRedirectTarget() {
  if (sessionStore.isSuper()) {
    return '/dennis';
  }

  if (sessionStore.isAuthor()) {
    return '/authoring/dashboard';
  }
  /**
   * Teachers always go to `/classes`
   */
  if (sessionStore.isTeacher()) {
    return '/classes';
  }
  /**
   * For any other user type (students), we redirect them to `/classes`
   * if they are in a licensed class.
   */
  if (sessionStore.hasValidSession()) {
    const activeClassrooms = await getDoesStudentHaveActiveClassroomQuery(
      sessionStore.getUserId()
    ).getResourcePromise();
    if (!activeClassrooms.isEmpty()) {
      return '/classes';
    }
  }

  return '/dashboard';
}

export function shouldRedirectFromPathname(pathname, {includeHome = false} = {}) {
  const redirectPaths = ['/forgot-password', '/reset-password', '/signup', '/social'];
  if (includeHome) {
    redirectPaths.push('/home');
  }
  return (
    (includeHome && pathname === '/') ||
    redirectPaths.some((path) => path === pathname) ||
    (sessionStore.isAuthor() && !sessionStore.isSuper() && pathname.startsWith('/dennis'))
  );
}
