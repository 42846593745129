// @flow
import {fromJS, Record, List, Map} from 'immutable';
import {GenericModel} from 'resources/Generic.model';
import type {ClassroomModelV1} from 'resources/augmented/Classroom/ClassroomModel.v1';
import type {StudentAssignment as StudentAssignmentModelV1} from 'resources/GeneratedModels/StudentAssignment/StudentAssignmentModel.v1';

import {stats} from 'lib/StatsUtil';

const studentConfig = fromJS({
  endpoint: 'students_v1',
  relationships: {
    assignment_v2: {
      required: false,
      type: 'one-to-many'
    },
    classroom_v1: {
      required: false,
      type: 'one-to-many'
    },
    student_assignment_v1: {
      required: false,
      type: 'one-to-many'
    }
  }
});

const StudentRecord = Record({
  email: '',
  first_name: '',
  last_name: '',
  id: '',
  username: '',
  assignments: new List(),
  classrooms: new List(),
  school: new Map(),
  meta: new Map({
    counts: new Map({
      in_time_frame: new Map({
        correct_answers: 0,
        questions_answered: 0
      }),
      all_time: new Map({
        correct_answers: 0,
        questions_answered: 0
      })
    })
  }),
  relationshipMeta: new Map({
    assignment: new Map({
      deleted: null,
      start_time: null,
      submitted: null
    })
  }),
  student_assignments: new List()
});

export class StudentModel extends GenericModel(StudentRecord, studentConfig) {
  getEmail(): string {
    return this.get('email');
  }

  getFirstName(): string {
    /**
     * first_name CAN be null (mostly on legacy accounts). This ensures a string instead of null.
     */
    return this.get('first_name') ? this.get('first_name') : '';
  }

  getLastName(): string {
    /**
     * first_name CAN be null (mostly on legacy accounts). This ensures a string instead of null.
     */
    return this.get('last_name') ? this.get('last_name') : '';
  }

  getFullName(): string {
    const firstName = this.getFirstName();
    const lastName = this.getLastName();
    if (firstName && lastName) {
      return `${firstName} ${lastName}`;
    }
    if (!firstName && !lastName) {
      return this.getUsername();
    }
    return firstName || lastName;
  }

  getId(): string {
    return this.get('id');
  }

  getPointsEarned(): number {
    return this.getIn(['meta', 'points_earned'], 0);
  }

  getUsername(): string {
    return this.get('username');
  }

  getClassrooms(): List<ClassroomModelV1> {
    return this.get('classrooms');
  }

  getAssignments(): List<*> {
    return this.get('assignments');
  }

  getSchool() {
    return this.get('school');
  }

  getStudentAssignments(): List<StudentAssignmentModelV1> {
    return this.get('student_assignments');
  }

  /**
   * meta methods
   */
  getCorrectAnswersInTimeFrame(): number {
    return this.getIn(['meta', 'counts', 'in_time_frame', 'correct_answers']);
  }

  getQuestionsAnsweredInTimeFrame(): number {
    return this.getIn(['meta', 'counts', 'in_time_frame', 'questions_answered']);
  }

  getIncorrectAnswersInTimeFrame(): number {
    return this.getQuestionsAnsweredInTimeFrame() - this.getCorrectAnswersInTimeFrame();
  }

  getAccuracyInTimeFrame(): number {
    return stats.percentage(
      this.getCorrectAnswersInTimeFrame(),
      this.getQuestionsAnsweredInTimeFrame()
    );
  }

  getCorrectAnswersAllTime(): number {
    return this.getIn(['meta', 'counts', 'all_time', 'correct_answers']);
  }

  getQuestionsAnsweredAllTime(): number {
    return this.getIn(['meta', 'counts', 'all_time', 'questions_answered']);
  }

  getIncorrectAnswersAllTime(): number {
    return this.getQuestionsAnsweredAllTime() - this.getCorrectAnswersAllTime();
  }

  getAccuracyAllTime(): number {
    return stats.percentage(this.getCorrectAnswersAllTime(), this.getQuestionsAnsweredAllTime());
  }

  _getRelationshipMeta(): Map<string, *> {
    return this.get('relationshipMeta');
  }

  getAssignmentRelationshipMeta(assignmentId: string): Map<string, *> {
    return this._getRelationshipMeta().getIn(['assignment', assignmentId]);
  }
}
