/* eslint-disable import/prefer-default-export */
import React from 'react';
import PropTypes from 'prop-types';
import {Chip, Heading, Popover, Text} from '@albert-io/atomic';
import MarkdownRendererV2 from 'client/generic/MarkdownRendererV2/MarkdownRendererV2.react';

import {StandardModelV1} from 'resources/GeneratedModels/Standard/StandardModel.v1';

export class StandardChip extends React.Component {
  static propTypes = {
    enableTooltip: PropTypes.bool,
    standard: PropTypes.instanceOf(StandardModelV1)
  };

  constructor() {
    super();
    this.state = {
      showTooltip: false
    };
  }

  ref = React.createRef();

  showTooltip = () => this.setState({showTooltip: true});

  hideTooltip = () => this.setState({showTooltip: false});

  render() {
    const {enableTooltip, standard, ...rest} = this.props;
    const title = standard.getTitle();
    const description = standard.getDescription();
    const standardSetTitle = standard.getTitle();
    return (
      <span ref={this.ref}>
        <Chip
          {...rest}
          onFocus={this.showTooltip}
          onBlur={this.hideTooltip}
          onMouseEnter={this.showTooltip}
          onMouseLeave={this.hideTooltip}
        >
          <MarkdownRendererV2 text={title} />
          {enableTooltip && this.state.showTooltip && (
            <Popover
              optimizeForMobile={false}
              expanded={this.state.showTooltip}
              targetRef={this.ref}
            >
              <Text color='secondary' size='xs'>
                <MarkdownRendererV2 text={standardSetTitle} />
              </Text>
              <Heading size='s'>
                <MarkdownRendererV2 text={title} />
              </Heading>
              <MarkdownRendererV2 text={description} />
            </Popover>
          )}
        </Chip>
      </span>
    );
  }
}
