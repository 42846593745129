import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {callAction} from 'client/framework';
import QuestionsList from 'client/InteractionEngineV2/shared/QuestionsList/QuestionsList.react';
import QuestionsListTopWidget from '../../../shared/QuestionsListTopWidget/QuestionsListTopWidget.react';
import assignmentV2IEQuestionsListStore from './AssignmentV2IEQuestionsList.store';
import assignmentV2IEActions from '../AssignmentV2IE.actions';
import assignmentV2IEStore from '../AssignmentV2IE.store';
import assignmentV2IEQuestionsListActions from './AssignmentV2IEQuestionsList.actions';
import CheckboxToggle from 'sg/CheckboxToggle/CheckboxToggle.react';
import questionsListActions from '../../../shared/QuestionsList/QuestionsListActions';
import interactionEngineActions from 'client/InteractionEngineV2/InteractionEngineActions';
import {buildStores} from 'client/InteractionEngineV2/InteractionEngine.util';
import {AssignmentModelV3} from 'resources/augmented/Assignment/AssignmentModel.v3';

export default class AssignmentV2IEQuestionsList extends React.Component {
  static propTypes = {
    isReady: PropTypes.bool
  };

  UNSAFE_componentWillMount() {
    this.buildStores();
  }

  UNSAFE_componentWillUpdate() {
    this.buildStores();
  }

  buildStores() {
    const questionSets = assignmentV2IEStore.getQuestionSets();
    if (questionSets.isEmpty()) {
      return;
    }
    const assignmentId = assignmentV2IEStore.getAssignment().getId();
    const builtStores = buildStores(assignmentV2IEStore.getQuestionSets(), assignmentId);
    callAction(interactionEngineActions.SET_BOOTSTRAPPED_STORES, builtStores);
  }

  handleQuestionClick() {
    if (!assignmentV2IEStore.getAssignment().isAssignmentOpenForStudent()) {
      callAction(assignmentV2IEActions.SET_IS_VIEWING_STAGING_DETAILS, false);
    }
  }

  shouldPreventViewingQuestions(assignment) {
    return (
      !assignment ||
      (assignment.hasStudentSubmittedAssignment() && !assignment.shouldShowGuessFeedback())
    );
  }

  render() {
    const hasNoQuestionSets = assignmentV2IEQuestionsListStore.questionSets.isEmpty();
    const assignment = assignmentV2IEStore.getAssignment();
    const hideTopDrawer =
      !this.props.isReady ||
      hasNoQuestionSets ||
      (assignmentV2IEStore.isViewingStagingDetails() && assignment.isAssignmentOpenForStudent());
    let questionPositionMap;
    let hideQuestionTitles;
    if (this.props.isReady) {
      const currentSection = assignment.isAssignmentOpenForStudent()
        ? assignmentV2IEStore.getOpenAssignmentActiveSection()
        : assignmentV2IEStore.getClosedAssignmentActiveSection();
      questionPositionMap = currentSection ? currentSection.getQuestionPositionMap() : null;
      hideQuestionTitles = assignment.isAssignmentOpenForStudent();
    }
    return (
      <QuestionsList
        questionsListStore={assignmentV2IEQuestionsListStore}
        questionSets={assignmentV2IEQuestionsListStore.questionSets}
        hide={!this.props.isReady || hasNoQuestionSets}
        singleQuestionWidget={<SingleQuestionWidget assignment={assignment} />}
        topDrawer={
          this.shouldPreventViewingQuestions(assignment) ? null : (
            <AssignmentV2IEQuestionsListTopDrawer hide={hideTopDrawer} />
          )
        }
        onQuestionClick={this.handleQuestionClick}
        disableQuestionClick={this.shouldPreventViewingQuestions(assignment)}
        hideQuestionTitles={hideQuestionTitles}
        questionPositionMap={questionPositionMap}
      />
    );
  }
}

class SingleQuestionWidget extends React.Component {
  static propTypes = {
    question: PropTypes.any,
    assignment: PropTypes.instanceOf(AssignmentModelV3)
  };

  render() {
    const guess = assignmentV2IEStore.getGuessForQuestion(this.props.question.getId());
    const showFeedback = this.props.assignment.shouldShowGuessFeedback();
    const hasGuess = guess !== null;
    const classNames = classnames('question-attempt-indicator', {
      'question-attempt-indicator--unanswered': !hasGuess,
      'question-attempt-indicator--no-feedback': hasGuess && !showFeedback,
      'question-attempt-indicator--correct': hasGuess && showFeedback && guess.isCorrect(),
      'question-attempt-indicator--incorrect': hasGuess && showFeedback && !guess.isCorrect()
    });
    return <span className={classNames} />;
  }
}

class AssignmentV2IEQuestionsListTopDrawer extends React.Component {
  static propTypes = {
    hide: PropTypes.bool
  };

  onIconClick = () => {
    callAction(assignmentV2IEQuestionsListActions.TOGGLE_IS_TOP_DRAWER_OPEN);
  };

  onTextClick = () => {
    const isViewingStagingDetails = assignmentV2IEStore.isViewingStagingDetails();
    callAction(assignmentV2IEActions.SET_IS_VIEWING_STAGING_DETAILS, !isViewingStagingDetails);

    if (isViewingStagingDetails && !assignmentV2IEQuestionsListStore.activeQuestion) {
      callAction(questionsListActions.SET_INITIAL_ACTIVE_QUESTION);
    }
  };

  render() {
    return (
      <QuestionsListTopWidget
        text={
          assignmentV2IEStore.isViewingStagingDetails()
            ? 'View Questions'
            : 'View Assessment Details'
        }
        icon={assignmentV2IEStore.isAssignmentSubmitted() ? null : 'cog'}
        hide={this.props.hide}
        onTextClick={this.onTextClick}
        onIconClick={this.onIconClick}
        showIconNotifier={assignmentV2IEQuestionsListStore.shouldHideAnsweredQuestions()}
        showDrawer={
          assignmentV2IEStore.isAssignmentSubmitted()
            ? false
            : assignmentV2IEQuestionsListStore.isTopDrawerOpen()
        }
        drawerContents={<ShowUnansweredQuestionsToggle />}
      />
    );
  }
}

class ShowUnansweredQuestionsToggle extends React.Component {
  toggleHideAnsweredQuestions = () => {
    callAction(assignmentV2IEQuestionsListActions.TOGGLE_HIDE_ANSWERED_QUESTIONS);
  };

  render() {
    return (
      <CheckboxToggle
        activeText='On'
        checked={assignmentV2IEQuestionsListStore.shouldHideAnsweredQuestions()}
        label='Hide answered questions'
        onChange={this.toggleHideAnsweredQuestions}
        value='hide-unanswered-questions'
      />
    );
  }
}
