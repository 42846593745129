/* eslint-disable no-underscore-dangle */
import {fromJS, Set, Map} from 'immutable';

import QuestionTypeStore from 'components/QuestionTypes/QuestionType.store';
import {GuessModel} from 'resources/Guess/Guess.model';
import questionTypeActions from 'components/QuestionTypes/questionType.actions';

import passageCorrectionQuestionActions from './PassageCorrectionQuestion.actions';

export default class PassageCorrectionQuestionStore extends QuestionTypeStore {
  constructor(name, questionId, question) {
    super(name);

    this.setInitialData(
      fromJS({
        ...this.draftGuessInitialData,
        activeOption: null,
        corrections: {},
        initialCorrections: null,
        questionId,
        selectedOptions: Set(),
        shouldShowExplanation: false
      })
    );

    this.writeData('__question', question);
    this.writeData('selectedOptions', Set());

    this.handleTargeted(questionTypeActions.CLEAR_GUESS, this._clearGuess);
    this.handleTargeted(questionTypeActions.LOAD_GUESS, this._loadGuess);
    this.handleTargeted(
      passageCorrectionQuestionActions.COMMIT_CORRECTION_CHANGE,
      this._commitCorrectionChange
    );
    this.handleTargeted(
      passageCorrectionQuestionActions.REMOVE_ACTIVE_OPTION_CORRECTION,
      this._removeActiveOptionCorrection
    );
    this.handleTargeted(
      passageCorrectionQuestionActions.SET_INITIAL_CORRECTIONS,
      this.setProperty('initialCorrections')
    );
    this.handleTargeted(
      passageCorrectionQuestionActions.SET_ACTIVE_OPTION,
      this.setProperty('activeOption')
    );
  }

  afterLoadDraftGuess(draftGuess) {
    const questionRegionIndices = this.getQuestion().get('region_indices');

    this.writeData(
      'corrections',
      draftGuess.getContent().map((correction, correctionIndex) => {
        const wordIndex = questionRegionIndices.getIn([correctionIndex, 'start_index']);
        return new Map().set(wordIndex, correction);
      })
    );
  }

  buildCorrectGuess(question) {
    return new GuessModel({
      content: question
        .getValidResponse()
        .filter((region) => region.get('solutions'))
        .map((region) => region.get('solutions').first()),
      correct: true,
      rubric: question.getMeta().getQuestionRubric(),
      solutionText: question.getSolutionText(),
      pointsPossible: question.getPoints(),
      pointsEarned: question.getPoints(),
      question_v1: question
    });
  }

  buildGuessContent() {
    const corrections = this.getCorrections();
    const initialCorrections = this.getInitialCorrections();

    if (initialCorrections === null || corrections.isEmpty()) {
      return {};
    }

    /**
     * Sorry this is hard to read, I tried splitting this up into smaller functions
     * but I need the closure from the outer reduce in the inner reduce, so it was
     * difficult to do that.
     *
     * Here's what's happening:
     * When we initialize our question, we go through each region, and copy the words which
     * make up that region to our store (initialCorrections).
     *
     * As the student goes and makes corrections, we hold onto those corrections in a separate
     * Map (corrections). When the student goes to submit the guess, we take the initialCorrections,
     * and for every region, we reduce the entries in the region down to a string.
     *
     * We do this by seeing if there is an entry for that index in the corrections. If there is,
     * we use its value to build our string, otherwise we use the initialCorrection.
     */
    return corrections.reduce((acc, regionCorrections, regionKey) => {
      const regionString = initialCorrections
        .get(regionKey)
        .sortBy((val, optionKey) => optionKey)
        .reduce((acc, initialCorrection, optionKey) => {
          const indexString = regionCorrections.has(optionKey)
            ? regionCorrections.get(optionKey)
            : initialCorrection;
          const trimmed = indexString.trim().split(/\s+/).join(' ');
          acc.push(trimmed);
          return acc;
        }, [])
        .join(' ');
      acc[regionKey] = regionString;
      return acc;
    }, {});
  }

  _clearGuess() {
    // Need to call this on the parent class
    super._clearGuess();
    this.resetProperties('corrections');
    super.kickoffDebouncedSaveDraftGuess();
  }

  _commitCorrectionChange(newValue) {
    const {activeRegion, activeOption} = this.getActiveOptionAndRegion();
    const updatedCorrections = this.getCorrections().setIn([activeRegion, activeOption], newValue);
    this.writeData('corrections', updatedCorrections);
    this.resetProperties('activeOption');
    super.kickoffDebouncedSaveDraftGuess();
  }

  _convertGuessToCorrections(guess) {
    return guess.getContent().map((regionContent) => {
      return regionContent.split(/\s/).reduce((acc, word, i) => acc.set(i, word), Map());
    });
  }

  getActiveCorrectedValue() {
    if (this.getActiveOption() === null) {
      return '';
    }

    const {activeOption, activeRegion} = this.getActiveOptionAndRegion();
    return this.getCorrections().getIn([activeRegion, activeOption], '');
  }

  getActiveOption = () => this.readData('activeOption');

  getActiveOptionAndRegion() {
    const activeOption = this.getActiveOption();
    return {
      activeOption,
      activeRegion: this.getInitialCorrections().findKey((region) => region.has(activeOption))
    };
  }

  getActiveOriginalValue() {
    const initialCorrections = this.getInitialCorrections();
    return initialCorrections !== null
      ? initialCorrections.flatten().get(this.getActiveOption(), '')
      : '';
  }

  getCorrectionAsJoinedStrings(regionKey) {
    return this.getCorrections().has(regionKey)
      ? this.getCorrections()
          .get(regionKey)
          .sortBy((val, key) => key)
          .join(' ')
      : null;
  }

  getCorrections = () => this.readData('corrections');

  getInitialCorrectionAsJoinedString(regionKey) {
    return this.getInitialCorrections().has(regionKey)
      ? this.getInitialCorrections()
          .get(regionKey)
          .sortBy((val, key) => key)
          .join(' ')
      : null;
  }

  getInitialCorrections = () => this.readData('initialCorrections');

  hasActiveOption = () => this.getActiveOption() !== null;

  hasInitialCorrections = () => this.getInitialCorrections() !== null;

  isOptionCorrected(optionIndex) {
    return this.getCorrections().some((region) => region.has(optionIndex));
  }

  _loadGuess(guess) {
    // Parent store needs points data from guess and needs to register this question has a guess
    super._loadGuess(guess);
    this.writeData('corrections', this._convertGuessToCorrections(guess));
  }

  _removeActiveOptionCorrection() {
    const {activeRegion, activeOption} = this.getActiveOptionAndRegion();
    let updatedCorrections = this.getCorrections().deleteIn([activeRegion, activeOption]);
    if (updatedCorrections.has(activeRegion) && updatedCorrections.get(activeRegion).isEmpty()) {
      updatedCorrections = updatedCorrections.delete(activeRegion);
    }
    this.writeData('corrections', updatedCorrections);
    this.resetProperties('activeOption');
    super.kickoffDebouncedSaveDraftGuess();
  }
}
