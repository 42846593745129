// @flow
import {EventEmitter} from 'eventemitter3';
import {Map} from 'immutable';

/**
 * @class State
 * @extends {eventemitter3.EventEmitter}
 */
export class State extends EventEmitter {
  _state: Map<*, *>;
  _previousState: Map<*, *> = Map();
  _restoreState: ?Map<*, *> = null;
  _pendingEmit: boolean = false;
  _emitPromise: Promise<*> = Promise.resolve();

  constructor(state: Map<*, *>) {
    super();
    state = state || Map();
    this.set(state);
  }

  /**
   * @param {Map} state The `state` to update to.
   * @param {Boolean} shouldEmitChange Whether or not the `State` instance should emit a `change` event after the update.
   */
  set(state: Map<*, *>, shouldEmitChange: boolean = false) {
    /**
     * If the provided `state` matches the current, `.set` is a no-op.
     */
    if (this._state === state) {
      return;
    }
    /**
     * This makes it such that we guarantee _previousState is the state tree
     * _at the previous render_ (as we can have multiple updates to a store)
     * between renders. When there is not a pending emit in place, that means
     * this is the first update to the state tree this run-through.
     */
    if (!this._pendingEmit) {
      this._previousState = this._state;
    }
    /**
     * Update the local state.
     */
    this._state = state;
    if (shouldEmitChange && !this._pendingEmit) {
      this._pendingEmit = true;
      setTimeout(() => {
        this._pendingEmit = false;
        this.emit('change', this._state);
      }, 0);
    }
  }

  /**
   * Rewind the active state to the last `State._previousState`
   * Be sure to call `State.restore` once you are done!
   */
  rewind() {
    this._restoreState = this._state;
    this._state = this._previousState;
  }

  /**
   * Restore the active state back to "normal" after a `State.rewind`
   */
  restore() {
    if (this._restoreState) {
      this._state = this._restoreState;
      this._restoreState = null;
    }
  }

  /**
   * Force update by emitting a `change` event if there is no pending emit.
   */
  forceUpdate(): Promise<*> {
    if (!this._pendingEmit) {
      this._emitPromise = new Promise((resolve) => {
        this._pendingEmit = true;
        setTimeout(() => {
          this._pendingEmit = false;
          this.emit('change', this._state);
          resolve();
        }, 0);
      });
    }

    return this._emitPromise;
  }

  /**
   * Returns the current active state (`State._state`)
   */
  get(): Map<*, *> {
    return this._state;
  }

  cursor(path): () => * {
    return (update, shouldEmitChange = true) => {
      if (update) {
        if (this._restoreState !== null) {
          throw new Error(
            `Attempt to write to historical state! Did you forget to restore() the state tree?`
          );
        }
        this.set(this._state.updateIn(path, update), shouldEmitChange);
      } else {
        return this._state.getIn(path);
      }
    };
  }
}
