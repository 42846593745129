import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {range} from 'lodash';

import Row from '../Row/Row.react';
import './grid-container.scss';

/**
 * Represents a container for creating a grid.
 *
 * Applies the grid max-width to its contents, as prescribed in the style guide.
 *
 * nested: Used for nesting containers inside <Col /> components, adds a utility class
 *   collapsing the outer margins of the container's contents keeping them flush
 * @param {Object} props
 */
const Container = ({
  as: WrapperElement,
  children,
  className,
  colGap,
  debug,
  nested,
  rowGap,
  ...rest
}) => (
  <WrapperElement
    className={classnames('a-grid__container', className, {
      'a-grid__container--nested': nested,
      'a-grid__container--debug': debug
    })}
    {...rest}
  >
    {React.Children.map(children, (child) => {
      if (child && child.type === Row) {
        return React.cloneElement(child, {
          colGap: child.props.colGap || colGap,
          rowGap: child.props.rowGap || rowGap
        });
      }
      return child;
    })}
  </WrapperElement>
);

Container.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  className: PropTypes.string,
  colGap: PropTypes.oneOfType([PropTypes.oneOf(range(1, 11)), PropTypes.object]).isRequired,
  debug: PropTypes.bool,
  nested: PropTypes.bool,
  rowGap: PropTypes.oneOf(range(1, 11))
};

Container.defaultProps = {
  as: 'div',
  colGap: 2
};

export default Container;
