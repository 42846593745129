import * as React from 'react';
import PropTypes from 'prop-types';
import {Map} from 'immutable';
import {pushQueryParams} from 'client/history';
import {setUpStore} from 'client/framework';
import sessionStore from 'client/Session/SessionStore';
import {resource} from '@albert-io/json-api-framework/request/builder';
import LoadingIndicator from 'client/generic/LoadingIndicator.react';
import SearchDropdown from 'sg/Dropdowns/SearchDropdown/SearchDropdown.react';
import SearchDropdownStore from 'sg/Dropdowns/SearchDropdown/SearchDropdown.store';
import './author-dropdown.scss';

export class AuthorDropdown extends React.Component<*> {
  static propTypes = {
    defaultSelectedId: PropTypes.string,
    displayName: PropTypes.string,
    label: PropTypes.string,
    openOnFocus: PropTypes.bool,
    subjectId: PropTypes.string
  };

  getStore() {
    return setUpStore(SearchDropdownStore, this.props.storeName);
  }

  isReady() {
    return !this.props.subjectId || this.getQuery().isResourceReady();
  }

  getQuery() {
    return resource('authors_v1')
      .mandarkEndpoint(['authoring_subjects_v1', this.props.subjectId])
      .withMeta('authoring_subject_v1');
  }

  getAuthorsInSubject() {
    const query = this.getQuery();
    return query
      .getResource()
      .getMeta()
      .getAuthoringQuestionSetAuthors()
      .map((author) =>
        Map({
          firstName: author.get('author_first_name'),
          lastName: author.get('author_last_name'),
          fullName: `${author.get('author_first_name')} ${author.get('author_last_name')}`,
          email: author.get('author_email'),
          id: author.get('author_id')
        })
      );
  }

  getAuthorByIdQuery(authors, id) {
    if (!authors) {
      return null;
    }
    return authors.find((author) => author.get('id') === id);
  }

  authorFilterFunc = (author, searchString) => {
    const str = searchString
      .toLowerCase()
      .trim()
      .split(' ');
    // allows for a single string search or fullName
    if (str.length > 1) {
      return (
        author
          .get('firstName')
          .toLowerCase()
          .includes(str[0]) &&
        author
          .get('lastName')
          .toLowerCase()
          .includes(str[1])
      );
    } else {
      return author
        .get('fullName')
        .toLowerCase()
        .includes(str);
    }
  };

  handleOwnerOptionChange = (option) => {
    pushQueryParams({author: option ? option.get('id') : ''});
  };

  render() {
    if (!this.isReady()) {
      return <LoadingIndicator />;
    }
    let placeholderText = 'All Authors';
    if (sessionStore.getUserId() === this.props.defaultSelectedId) {
      placeholderText = 'Me';
    }
    const authors = this.props.subjectId ? this.getAuthorsInSubject() : null;
    const selectedAuthor = this.props.defaultSelectedId
      ? this.getAuthorByIdQuery(authors, this.props.defaultSelectedId)
      : '';

    return (
      <SearchDropdown
        areResultsLoading={!authors && this.props.subjectId ? true : false}
        className={this.props.className}
        customClearOption=''
        disabled={this.props.disabled}
        defaultOption={selectedAuthor}
        displayName={this.props.displayName}
        filterFunc={this.authorFilterFunc}
        handleOptionChange={this.handleOwnerOptionChange}
        label={this.props.label}
        openOnFocus={this.props.openOnFocus}
        options={authors}
        placeholder={placeholderText}
        renderFunc={(option) => (
          <div>
            <div>{option.get('email')}</div>
            <div>
              {option.get('firstName')} {option.get('lastName')}
            </div>
          </div>
        )}
        storeName={this.getStore().getName()}
      />
    );
  }
}
