import {Link, withRouter} from 'react-router';
import {OrderedMap, List} from 'immutable';
import React from 'react';

import {
  Card,
  ExpandableMenuListItem,
  Icon,
  MenuListItem,
  MobileDropdown,
  Text
} from '@albert-io/atomic';

import {getOverriddenDomainString} from '../stringOverrideHelpers';
import './sidebar-menu.scss';

const ALL_SUBJECTS_STRING = 'All Subjects';

interface Props {
  activePath: string[];
  additionalSidebarMenuItems?: OrderedMap<string, string>;
  group: string;
  makeItemHref?: (category: string, item: string) => string;
  menuItems: OrderedMap<string, List<string>>;
  onItemClick?: (category: string, item: string) => void;
  router: any;
  subgroup: string;
}

function SidebarMenu({
  activePath,
  additionalSidebarMenuItems = OrderedMap(),
  group,
  makeItemHref,
  menuItems,
  onItemClick = () => {},
  router,
  subgroup
}: Props) {
  function getCombinedAdditionalSidebarMenuItems() {
    return List([ALL_SUBJECTS_STRING]).concat(additionalSidebarMenuItems.keySeq());
  }

  /**
   * Since 'activePath' was created to traverse 'subjectMenuData' and not handle
   * outside categories such as 'Discover' and 'All Subjects' we need to do extra manual checks to
   * apply the selected state to any domains that are not in the menuData.
   *
   * 'All Subjects' exists in all instances of the sidebar and requires a special check due to being hardcoded
   *
   * @param  {string}  category
   * @param  {string}  item
   * @returns {boolean}
   */
  function isItemActive(category: string, item: string) {
    const [activeCategory, activeItem] = activePath;

    if (
      (!activeCategory && category === ALL_SUBJECTS_STRING) ||
      activeCategory === category.toLowerCase()
    ) {
      return true;
    }

    const isActiveCategory = activeCategory === category;
    const isActiveItem = activeItem ? activeItem === item : item === 'All';

    return isActiveCategory && isActiveItem;
  }

  function renderAdditionalSidebarMenuItems() {
    const WrapperComponent = makeItemHref ? Link : 'div';

    return (
      <div className='u-display_flex u-flex-direction_column u-gap_space-x1'>
        {getCombinedAdditionalSidebarMenuItems().map((category = '') => (
          <MenuListItem
            as={WrapperComponent}
            onMenuItemClick={() => onItemClick(category, 'All')}
            regularTextProps={{bold: true}}
            selected={isItemActive(category, 'All')}
            text={getOverriddenDomainString(category) ?? category}
            to={makeItemHref !== undefined ? makeItemHref(category, 'All') : null}
          />
        ))}
      </div>
    );
  }

  const renderCategories = (category: string, items: List<string>) => {
    const isCategoryActive = activePath[0] === category;

    return (
      <li className='cl-sidebar-menu__category' key={category}>
        <hr className='u-mar-tb_2' />
        <ExpandableMenuListItem
          categoryTextProps={{color: 'inherit'}}
          className='content-primary'
          defaultOnState={isCategoryActive}
          expandOnMenuItemClick
          isCategoryItem
          iconButtonLabel='Expand'
          id={`${category}_expand`}
          onMenuItemClick={() => {
            if (makeItemHref !== undefined) {
              router.push(makeItemHref(category, 'All'));
            }
          }}
          selected={activePath.length === 1 && isCategoryActive}
          text={getOverriddenDomainString(category) ?? category}
        >
          {renderItems(category, items)}
        </ExpandableMenuListItem>
      </li>
    );
  };

  function renderItems(category: string, items: List<string>) {
    const WrapperComponent = makeItemHref !== undefined ? Link : 'li';

    return (
      <ul className='cl-sidebar-menu__items-list'>
        {items.map((item = '') => (
          <MenuListItem
            as={WrapperComponent}
            indentLevel='1'
            key={`${category}-${item}`}
            onMenuItemClick={() => onItemClick(category, item)}
            regularTextProps={{bold: false}}
            selected={isItemActive(category, item)}
            text={item}
            to={makeItemHref !== undefined ? makeItemHref(category, item) : null}
          />
        ))}
      </ul>
    );
  }

  const triggerElement = (
    <Card paddingLevel={2} className='cl-sidebar-menu__dropdown'>
      <div className='cl-sidebar-menu__dropdown-text'>
        <Text size='s' color='tertiary'>
          {group}
        </Text>
        <Text>{subgroup}</Text>
      </div>
      <Icon icon='caret-down' />
    </Card>
  );

  return (
    <MobileDropdown fillWrapper className='cl-sidebar-menu' trigger={triggerElement}>
      <ul className='cl-sidebar-menu__category-list'>
        {renderAdditionalSidebarMenuItems()}
        {menuItems.map((value = List(), key = '') => renderCategories(key, value)).valueSeq()}
      </ul>
    </MobileDropdown>
  );
}

export default withRouter(SidebarMenu);
