import React, {ElementType} from 'react';

import Heading from '../../../atoms/Heading/Heading.react';

export interface Props extends PropsWithChildrenRequired {
  as?: ElementType;
}

export const Title = ({as, children, ...rest}: Props) => (
  <Heading className='o-wall-title' as={as} {...rest}>
    {children}
  </Heading>
);
