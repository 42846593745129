import React, {useReducer, useState, useEffect} from 'react';

import {callAction} from 'client/framework';

import appActions from 'client/AppActions';

import {LoadingSpinner, addToast} from '@albert-io/atomic';

import {PublicQuestionModelV1} from 'src/resources/augmented/PublicQuestion/PublicQuestionModel.v1';

import {PublicQuestion} from './PublicQuestion';
import {PublicQuestionList} from './PublicQuestionList';
import {Header} from './Header';
import {
  PublicQuestionsContext,
  PublicQuestionsDispatch,
  PublicQuestionsState,
  PublicQuestionsReducer
} from './PublicQuestionsContext';
import {
  fetchInitialQuestions,
  fetchSingleQuestion,
  fetchGuideQuestion,
  fetchQuestionsForPage,
  fetchGuideLevel,
  getQuestionCount
} from './queries';
import {QueryType, QUERY_PAGE_SIZE} from './util';
import './public-questions.scss';

type PublicQuestionsProps = {
  backLocation: string;
  guideLevelSlug: string;
  iconUrl: string;
  onForbidden?: (error: unknown) => void;
  queryType: QueryType;
  questionSetSlug?: string;
  questionSlug?: string;
  router: any;
  subjectColor: string;
  subjectSlug: string;
  title: string;
};
const PublicQuestions = ({
  backLocation,
  guideLevelSlug,
  iconUrl,
  onForbidden = () => {},
  queryType,
  questionSetSlug,
  questionSlug,
  router,
  subjectColor,
  subjectSlug,
  title
}: PublicQuestionsProps) => {
  const initialState: PublicQuestionsState = {
    title,
    subjectColor,
    subjectSlug,
    guideLevelSlug,
    backLocation,
    questions: [],
    queryType,
    initialQuestionLoaded: false,
    iconUrl,
    router,
    questionCount: 0
  };
  const [state, dispatch] = useReducer(PublicQuestionsReducer, initialState);
  const [loading, setLoading] = useState<boolean>(true);

  // single question
  const loadSingleQuestion = async () => {
    dispatch({
      type: 'setQuestionCount',
      count: 1
    });

    const singleQuestion = await fetchSingleQuestion(questionSlug!);
    if (!singleQuestion) {
      dispatch({
        type: 'setQuestions',
        questions: []
      });
      addToast({
        color: 'negative',
        title: 'Error',
        message: 'Question has been deleted or does not exist.'
      });
    } else {
      dispatch({
        type: 'setQuestions',
        questions: [singleQuestion],
        currentQuestion: singleQuestion
      });
    }
    setLoading(false);
  };

  const fallbackQuestion = (questions) =>
    questions.find((q) => q.getQuestionSet().getSlugId().split('@')[1] === questionSetSlug);

  const loadPagedQuestions = async (
    questionList: PublicQuestionModelV1[],
    guideLevelId: string,
    findInitialQuestion: boolean,
    page?: number
  ) => {
    const currentPage = page || 1;

    let questions;

    try {
      questions = await fetchQuestionsForPage(subjectSlug, guideLevelId, currentPage);
    } catch (error) {
      onForbidden(error);
    }

    const allQuestionsLoaded = questions.length < QUERY_PAGE_SIZE;
    const updatedQuestions = [...questionList, ...questions];
    dispatch({
      type: 'setQuestions',
      questions: updatedQuestions
    });

    const initialQuestion = fallbackQuestion(updatedQuestions);
    if (findInitialQuestion && initialQuestion) {
      dispatch({
        type: 'setCurrentQuestion',
        question: initialQuestion
      });
    }
    if (!allQuestionsLoaded) {
      loadPagedQuestions(
        updatedQuestions,
        guideLevelId,
        findInitialQuestion && !initialQuestion,
        currentPage + 1
      );
    } else if (findInitialQuestion && !initialQuestion) {
      // question with url slug not found in question list so select the first by default
      dispatch({
        type: 'setCurrentQuestion',
        question: updatedQuestions[0]
      });
    }
  };

  // guide level questions
  const loadGuideLevelQuestions = async () => {
    const guideLevel = await fetchGuideLevel(guideLevelSlug);

    let initialQuestions;

    try {
      initialQuestions = await fetchInitialQuestions(subjectSlug, guideLevel.getId());
    } catch (error) {
      onForbidden(error);
    }

    const count = getQuestionCount(subjectSlug, guideLevel.getId());
    dispatch({
      type: 'setQuestionCount',
      count
    });

    if (initialQuestions.length === 0) {
      // no questions found
      dispatch({
        type: 'setQuestions',
        questions: []
      });
      setLoading(false);
    } else {
      const initialQuestion = questionSetSlug
        ? (await fetchGuideQuestion(subjectSlug, questionSetSlug)) ||
          fallbackQuestion(initialQuestions)
        : initialQuestions[0];
      const continuePaging = initialQuestions.length >= QUERY_PAGE_SIZE;

      dispatch({
        type: 'setQuestions',
        questions: initialQuestions,
        currentQuestion: initialQuestion
      });

      setLoading(false);
      if (continuePaging) {
        loadPagedQuestions(initialQuestions, guideLevel.getId(), !initialQuestion, 2);
      }
    }
  };

  useEffect(() => {
    // this removes the footer from the outer wrapper so the
    // minimal footer can be displayed under the question content
    callAction(appActions.SET_FOOTER_DISPLAY, false);

    // load questions by query type
    if (queryType === 'singleQuestion') {
      loadSingleQuestion();
    } else {
      loadGuideLevelQuestions();
    }
  }, []);

  useEffect(() => {
    dispatch({
      type: 'setTitle',
      title
    });
  }, [title]);

  return (
    <PublicQuestionsContext.Provider value={state}>
      <PublicQuestionsDispatch.Provider value={dispatch}>
        <div id='public-questions-view'>
          <Header />
          {loading ? (
            <LoadingSpinner size={3} className='public-questions-view__loading u-pad-t_2' />
          ) : (
            <div className='public-questions-content'>
              <PublicQuestionList />
              <PublicQuestion />
            </div>
          )}
        </div>
      </PublicQuestionsDispatch.Provider>
    </PublicQuestionsContext.Provider>
  );
};

export default PublicQuestions;
