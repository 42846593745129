import {List} from 'immutable';

export const baseSupplementValidators = {
  getAlttext() {
    // follows the convention set by Mandark
    if (
      this.isAttributeRequired('alttext') &&
      this.getValueForContentAttribute('alttext', '').length === 0
    ) {
      return new List(['You must provide alt text.']);
    }
    return null;
  },

  getAudio() {
    if (
      this.isAttributeRequired('audio') &&
      this.getValueForContentAttribute('audio', '').length === 0
    ) {
      return new List(['You must provide audio.']);
    }
    return null;
  },

  getCaption() {
    if (
      this.isAttributeRequired('caption') &&
      this.getValueForContentAttribute('caption', '').length === 0
    ) {
      return new List(['You must provide a caption.']);
    }
    return null;
  },

  getImageUrl() {
    if (
      this.isAttributeRequired('image') &&
      this.getValueForContentAttribute('image', '').length === 0
    ) {
      return new List(['You must provide an image.']);
    }
    return null;
  },

  getName() {
    if (this.getName().length === 0) {
      return new List(['You must provide a name.']);
    }
    return null;
  },

  getText() {
    if (
      this.isAttributeRequired('text') &&
      this.getValueForContentAttribute('text', '').length === 0
    ) {
      return new List(['You must provide text.']);
    }
    return null;
  },

  getType() {
    if (this.getType().length === 0) {
      return new List(['You must provide Type']);
    }
    return null;
  },

  getVideoId() {
    if (
      this.isAttributeRequired('videoId') &&
      this.getValueForContentAttribute('videoId', '').length === 0
    ) {
      return new List(['You must provide VideoId']);
    }
    return null;
  }
};
