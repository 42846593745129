/**
 * # Label
 *
 * React component for rendering a label element.
 *
 * ## Props
 * - `className` (string): Additional CSS class name for the label.
 * - `htmlFor` (string): The ID of the form element associated with the label.
 *
 * ## Usage
 *
 * ```jsx
 * import React from 'react';
 * import Label from './Label.react';
 *
 * const MyComponent = () => {
 *   return (
 *     <div>
 *       <Label htmlFor="myInput">Name:</Label>
 *       <input id="myInput" type="text" />
 *     </div>
 *   );
 * };
 * ```
 */

import React from 'react';
import PropTypes from 'prop-types';

const Label = (props) => <label {...props} />;

Label.propTypes = {
  className: PropTypes.string,
  htmlFor: PropTypes.string
};

export default Label;
