import React from 'react';
import PropTypes from 'prop-types';

import {refreshSession} from '@albert-io/mandark/authentication';

import awaitMandarkQueries from 'lib/hocs/awaitMandarkQueries';
import {UserModelV2} from 'resources/augmented/User/UserModel.v2';
import {getBaseUserQuery} from 'client/User/UserQueryUtil';
import {
  hasCompletedOnboarding,
  getActiveOnboardingComponentForUser,
  isUserOnFinalStep
} from 'lib/OnboardingUtil/OnboardingUtil';
import sessionStore from 'client/Session/SessionStore';
import {invalidateInterest} from 'resources/mandark.resource';
import {redirect} from 'client/User/UserRedirectUtil';
import {history} from 'client/history';

export const onboardingPropTypes = {
  user: PropTypes.instanceOf(UserModelV2).isRequired,
  onComplete: PropTypes.func.isRequired
};

function getUserQuery() {
  return getBaseUserQuery(sessionStore.getUserId()).include('standard_set_creators_v1');
}

/**
 * Renders onboarding steps from `OnboardingUtil` in sequence until the user's onboarding step list
 * indicates they have all been completed.
 */
class ForceOnboarding extends React.Component {
  static propTypes = {
    user: PropTypes.instanceOf(UserModelV2).isRequired,
    onFinalStepComplete: PropTypes.func,
    children: PropTypes.node
  };

  static defaultProps = {
    onFinalStepComplete: () => {
      refreshSession().then(() => {
        const {pathname} = history.getCurrentLocation();
        global.scroll(0, 0);
        // redirect the user to default page if they're on the base page
        if (pathname === '/') {
          redirect();
        }
      });
    }
  };

  render() {
    const onComplete = () => {
      invalidateInterest(getUserQuery().done());
    };

    if (!hasCompletedOnboarding(this.props.user)) {
      return getActiveOnboardingComponentForUser(this.props.user)({
        user: this.props.user,
        onComplete: isUserOnFinalStep(this.props.user) ? this.props.onFinalStepComplete : onComplete
      });
    }

    return this.props.children;
  }
}

export default awaitMandarkQueries(
  () => ({
    queries: {
      user: getUserQuery()
    }
  }),
  ForceOnboarding
);
