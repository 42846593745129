import {kebabCase} from 'lodash';
import {Map} from 'immutable';
import {QuestionModelV3} from 'resources/GeneratedModels/Question/QuestionModel.v3';
import constants from 'client/constants';
import {questionTypeSetup} from 'resources/extensions/questionHelpers/questionTypeSetup';
import {setUpStore} from 'client/framework';

// NEW QUESTION STORE LOCATIONS
import FillInTheBlankQuestionStore from 'client/components/QuestionTypes/FillInTheBlank/FillInTheBlankQuestion.store';
import FreeEntryQuestionStore from 'client/components/QuestionTypes/FreeEntry/FreeEntryQuestion.store';
import FreeResponseQuestionStore from 'client/components/QuestionTypes/FreeResponse/FreeResponseQuestion.store';
import GraphingQuestionStore from 'client/components/QuestionTypes/Graphing/GraphingQuestion.store';
import MultipleChoiceQuestionStore from 'client/components/QuestionTypes/MultipleChoice/MultipleChoiceQuestion.store';
import PassageCorrectionQuestionStore from 'client/components/QuestionTypes/PassageCorrection/PassageCorrectionQuestion.store';
import SnippetSelectionQuestionStore from 'client/components/QuestionTypes/SnippetSelection/SnippetSelectionQuestion.store';
import TextHighlightQuestionStore from 'client/components/QuestionTypes/TextHighlight/TextHighlightQuestion.store';
import TwoWayQuestionStore from 'client/components/QuestionTypes/TwoWay/TwoWayQuestion.store';

const questionTypesMap = {
  'fill-in-the-blank': FillInTheBlankQuestionStore,
  'free-entry': FreeEntryQuestionStore,
  'free-response': FreeResponseQuestionStore,
  'graph-contains': GraphingQuestionStore,
  'multiple-choice': MultipleChoiceQuestionStore,
  'passage-correction': PassageCorrectionQuestionStore,
  'snippet-selection': SnippetSelectionQuestionStore,
  'text-highlight': TextHighlightQuestionStore,
  'two-way': TwoWayQuestionStore
};

const difficultiesMap = Object.entries(constants.QUESTION_IMPORT.DIFFICULTIES).reduce(
  (acc, [label, value]) => {
    acc[value] = label;
    return acc;
  },
  {}
);

QuestionModelV3.extend({
  getDifficultyLabel() {
    return difficultiesMap[this.getDifficulty()];
  },

  getStoreName() {
    return `QuestionStoreV3/${this.getId()}`;
  },

  getStore() {
    const Store = questionTypesMap[this.getQuestionType()];
    const storeName = this.getStoreName();
    return setUpStore(Store, storeName, this.getId(), this);
  },

  getKebebCasedTitle() {
    return kebabCase(this.getTitle().toLowerCase());
  },

  getValidResponse() {
    const rubric = this.getMeta().getQuestionRubric();
    if (!rubric) {
      return null;
    }
    return rubric.has('validResponse')
      ? rubric.get('validResponse', Map())
      : rubric.get('valid_response', Map());
  },

  hasSelectedAnswer() {
    if (this.getRubricType() === 'markAsCorrect') {
      return true;
    }

    const guessContent = this.getStore().buildGuessContent();

    return Array.isArray(guessContent)
      ? guessContent.length !== 0
      : Object.keys(guessContent).length !== 0;
  }
});

QuestionModelV3.addDataTransformer('QuestionModelV3', questionTypeSetup);

export {QuestionModelV3};
