import assignmentV2IEStore from '../TeacherAssignmentV2IE.store';
import QuestionsListStore from 'client/InteractionEngineV2/shared/QuestionsList/QuestionsListStore';

class TeacherAssignmentV2IEQuestionsListStore extends QuestionsListStore {
  constructor(name) {
    super(name);
  }

  get questionSets() {
    return assignmentV2IEStore.getQuestionSets();
  }
}

export default new TeacherAssignmentV2IEQuestionsListStore(
  'TeacherAssignmentV2IEQuestionsListStore'
);
