import React from 'react';
import PropTypes from 'prop-types';
import withTracking from 'lib/hocs/withTracking';
import track from 'react-tracking';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {Icon, Dropdown, Anchor, SplitCard, Heading} from '@albert-io/atomic';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import masqueradeStore from 'generic/Masquerade/Masquerade.store';
import SubjectSplitCard from 'client/components/SubjectSplitCard/SubjectSplitCard.react';

import {getActiveClassroomsWithSubjectsQuery} from 'client/generic/SubjectMenuRenderer/helpers/queries';

import {getModelForResourceType} from 'resources/modelRegistry';

import classnames from 'classnames';

import '../subject-menu-renderer.scss';

import FreePracticeOptions from './FreePracticeOptions/FreePracticeOptions.react';
import './subject-course-card.scss';

const TrackedAnchor = withTracking(Anchor, {
  onClick: {
    event: 'click',
    type: 'dropdown',
    feature: 'Course Library',
    component: 'Subject Card',
    content: (e) => e.target.dataset.trackingSubject
  }
});

@track({component: 'Subject Card', feature: 'Course Library'})
class SubjectCourseCard extends React.PureComponent {
  static propTypes = {
    activeClassrooms: ImmutablePropTypes.listOf(
      PropTypes.instanceOf(getModelForResourceType('classroom_v1'))
    ),
    className: PropTypes.string,
    content: PropTypes.node,
    isAssessmentsOnly: PropTypes.bool,
    isUserLicensedTeacher: PropTypes.bool,
    isLoggedOutOrStudent: PropTypes.bool,
    subject: PropTypes.instanceOf(getModelForResourceType('subject_v2')),
    to: PropTypes.string,
    tracking: PropTypes.object
  };

  constructor() {
    super();

    this.state = {
      disableDropdown: false,
      showDropdown: false,
      showDescription: false
    };

    this.timeoutId = null;
  }

  componentDidMount() {
    global.document.addEventListener('mousedown', this.handleClickOutsideComponent);
  }

  componentDidUpdate(_, prevState) {
    if (!prevState.showDropdown && this.state.showDropdown) {
      global.document.addEventListener('keydown', this.handleEscape);
    }

    /** Prevent users from reopening dropdown while invalidation is in process */
    if (!this.props.isAssessmentsOnly && prevState.showDropdown && !this.state.showDropdown) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({disableDropdown: true});
      const enableDropdown = () => this.setState({disableDropdown: false});
      this.timeoutId = setTimeout(enableDropdown, 500);

      global.document.removeEventListener('keydown', this.handleEscape);
    }
  }

  componentWillUnmount() {
    global.document.removeEventListener('mousedown', this.handleClickOutsideComponent);
    global.document.removeEventListener('keydown', this.handleEscape);
    clearTimeout(this.timeoutId);
  }

  dropdownWrapperRef = React.createRef();

  toggleRef = React.createRef();

  linkRef = React.createRef();

  descriptionRef = React.createRef();

  handleEscape = (e) => {
    if (e.key === 'Escape') {
      this.setState({showDropdown: false});
    }
  };

  handleClickOutsideComponent = (e) => {
    if (this.state.showDropdown) {
      const rootPortalElement = global.document.getElementById('portal');
      const dropdownWrapper = this.dropdownWrapperRef;

      if (dropdownWrapper.current.contains(e.target) || rootPortalElement.contains(e.target)) {
        return;
      }
      this.setState({showDropdown: false});
    }
  };

  render() {
    const {
      activeClassrooms,
      subject,
      to,
      content,
      className,
      isAssessmentsOnly,
      isUserLicensedTeacher,
      isLoggedOutOrStudent
    } = this.props;

    const {showDropdown, showDescription} = this.state;

    /** Prevent users from reopening dropdown while invalidation is in process  */
    const disableDropdownBtn =
      !isLoggedOutOrStudent &&
      (!getActiveClassroomsWithSubjectsQuery().isResourceReady() || this.state.disableDropdown);
    const curriculumAreaSlug = subject.getCurriculumAreaSlug();
    const showActionBar = !isLoggedOutOrStudent || masqueradeStore.isMasquerading();

    return (
      <SubjectSplitCard
        className={classnames(className, {
          'subject-course-card--description-in-view': showDescription,
          'subject-course-card--action-bar-in-view': showActionBar
        })}
        key={subject.getId()}
      >
        <SubjectSplitCard.Top
          className={classnames(
            `subject-course-card__details u-display_flex u-align-items_flex-end u-pad-lr_3 u-pad-tb_3 u-color_white u-overflow_hidden background-domain-${curriculumAreaSlug}`
          )}
          onClick={(e) => {
            const link = this.linkRef.current;
            const description = this.descriptionRef.current;
            if (link !== e.target && description !== e.target) {
              link.click();
              this.props.tracking.trackEvent({
                event: 'click',
                type: 'button',
                content: subject.getName()
              });
            }
          }}
        >
          <SubjectSplitCard.Thumbnail xlinkHref={subject.getLogoLocation().href} />
          <SubjectSplitCard.Title className='u-display_flex u-flex-direction_column'>
            <Heading className={classnames({'subject-course-card__title': content})}>
              <Anchor innerRef={this.linkRef} to={to} inheritColor>
                {subject.getName()}
              </Anchor>
            </Heading>
            <Anchor
              as='button'
              onClick={() => this.setState((state) => ({showDescription: !state.showDescription}))}
              inheritColor
              className='subject-course-card__description-link'
              role='switch'
              aria-checked={showDescription}
              ref={this.descriptionRef}
            >
              {showDescription ? 'Hide description' : 'Show description'}
            </Anchor>
          </SubjectSplitCard.Title>
        </SubjectSplitCard.Top>
        <SplitCard.Section
          aria-hidden={!showDescription}
          className='subject-course-card__description u-pad-lr_3 u-overflow_hidden u-flex-grow_1'
        >
          <MarkdownRendererV2 text={content} />
        </SplitCard.Section>
        {showActionBar && (
          <SubjectSplitCard.Bottom
            paddingLevel={0}
            border='none'
            className='subject-course-card__action-bar u-display_flex u-justify-content_flex-end u-text-align_right u-pad-lr_3 u-pad-tb_1 u-font-weight_bold content-secondary'
            onClick={(e) => {
              e.stopPropagation();
              this.setState({showDropdown: !showDropdown});
            }}
          >
            <TrackedAnchor
              onClick={(e) => {
                e.stopPropagation();
                this.setState({showDropdown: !showDropdown});
              }}
              ref={this.toggleRef}
              role='switch'
              as='button'
              inheritColor
              disabled={disableDropdownBtn}
              aria-checked={showDropdown}
              data-tracking-subject={subject.getName()}
            >
              Practice Settings
              <Icon icon='caret-down' className='u-mar-l_1' />
            </TrackedAnchor>
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
            <div onClick={(e) => e.stopPropagation()} ref={this.dropdownWrapperRef}>
              {showDropdown ? (
                <Dropdown.Tray
                  className='subject-course-card__popper'
                  positionFixed
                  placement='bottom-start'
                  target={this.toggleRef.current}
                >
                  <FreePracticeOptions
                    className='subject-course-card__popper__dropdown-items u-overflow_auto u-text-align_left'
                    classrooms={activeClassrooms}
                    subject={subject}
                    isAssessmentsOnly={isAssessmentsOnly}
                    isUserLicensedTeacher={isUserLicensedTeacher}
                  />
                </Dropdown.Tray>
              ) : null}
            </div>
          </SubjectSplitCard.Bottom>
        )}
      </SubjectSplitCard>
    );
  }
}

export default SubjectCourseCard;
