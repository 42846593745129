/**
 * # Footer
 *
 * React component that represents the footer section of Albert.
 *
 * ## Props
 *
 * - `className` (string): Additional CSS class name for the footer.
 * - `minimal` (boolean): Flag to indicate if the footer should be rendered in minimal mode.
 */
import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import moment from 'moment';
import constants from 'client/constants';
import Logo from '@albert-io/atomic/atoms/Logo/Logo.react';

import {List} from 'immutable';

import Divider from '../../atoms/Divider/Divider.react';
import Anchor from '../../atoms/Anchor/Anchor.react';
import Text from '../../atoms/Text/Text.react';

import {FooterItemData, getFooterData} from './FooterData';

import './footer.scss';

interface FooterProps extends PropsWithClassNameOptional {
  minimal?: boolean;
}

const Footer = ({className, minimal}: FooterProps) => (
  <footer className={classnames('footer', className)} role='contentinfo'>
    {!minimal && (
      <>
        <div className='footer-logo-image__wrapper u-mar-b_4 u-pad-t_5'>
          <Logo />
        </div>
        <div className='footer__columns'>
          {getFooterData()
            .get('columns')
            .map((datum) => {
              return (
                <Column
                  key={datum?.get('title')}
                  title={datum?.get('title') as string}
                  items={datum?.get('items') as List<FooterItemData>}
                />
              );
            })}
        </div>
        <div>
          <Anchor
            icon={['fab', 'instagram']}
            href='https://www.instagram.com/learnwithalbert/'
            rel='noopener noreferrer'
            target='_blank'
            className='u-mar-r_2 footer-media'
          >
            Instagram
          </Anchor>
          <Anchor
            icon={['fab', 'facebook-f']}
            href='https://www.facebook.com/learnwithalbert/'
            rel='noopener noreferrer'
            target='_blank'
            className='u-mar-r_2 footer-media'
          >
            Facebook
          </Anchor>
          <Anchor
            icon={['fab', 'twitter']}
            href='https://twitter.com/learnwithalbert'
            rel='noopener noreferrer'
            target='_blank'
            className='footer-media'
          >
            Twitter
          </Anchor>
        </div>
        <div>
          <Divider spacing={4} />
        </div>
      </>
    )}
    <div className='footer-legal'>
      <Text as='p' size='m' color='tertiary'>
        Copyright {moment().year()} Learn By Doing, Inc.
      </Text>
      <Text as='p' size='m' color='tertiary' className='u-mar-b_0 footer-legal__disclaimer'>
        {constants.FULL_DISCLAIMER}
      </Text>
    </div>
  </footer>
);

Footer.propTypes = {
  className: PropTypes.string,
  minimal: PropTypes.bool
};

type ColumnProps = {
  title: string;
  items: List<FooterItemData>;
};

const Column = ({title, items}: ColumnProps) => {
  return (
    <div className='footer-column u-mar-b_5'>
      <Text bold color='secondary' className='u-mar-b_2' size='m' as='p'>
        {title}
      </Text>
      <ul className='footer-column__list'>
        {items.map((item) => {
          return <FooterItem key={item?.get('label')} item={item as FooterItemData} />;
        })}
      </ul>
    </div>
  );
};

Column.propTypes = {
  title: PropTypes.string,
  items: ImmutablePropTypes.list
};

type FooterItemProps = {
  item: FooterItemData;
};

const FooterItem = ({item}: FooterItemProps) => {
  const label = item.get('label');
  const to = item.get('to');
  const href = item.get('href');
  const onClick = item.get('onClick');
  const icon = item.get('icon');

  // Defaults to Link, which is what we want if we are passing a 'to' prop
  let as;
  if (href) {
    as = 'a';
  } else if (onClick) {
    as = 'button';
  }

  return (
    <Anchor
      as={as}
      className='footer-item u-mar-b_2'
      onClick={onClick}
      to={to}
      href={href}
      {...(icon ? {icon} : {})}
    >
      {label}
    </Anchor>
  );
};

FooterItem.propTypes = {
  item: ImmutablePropTypes.map
};

export default Footer;
