import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import moment from 'moment';
import sessionStore from 'client/Session/SessionStore';
import MarketingBanner from 'client/components/MarketingBanner/MarketingBanner.react';
import {hasSubjectAccess, isFreemiumUser} from 'lib/UserAccessUtil';
import {Anchor, Banner} from '@albert-io/atomic';
import {SubjectModelV2} from 'resources/augmented/Subject/SubjectModel.v2';
import {UserModelV2} from 'resources/GeneratedModels/User/UserModel.v2';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';

import updatedSubjects from './updatedSubjects';

export default class SubjectBanner extends React.Component {
  static propTypes = {
    subject: PropTypes.instanceOf(SubjectModelV2),
    user: PropTypes.instanceOf(UserModelV2),
    classname: PropTypes.string
  };

  /**
   * Handles all of the checks needed for the banner text. Checks in order of if statement:
   *  retail student (not in a classroom)
   *      - is AP subject and doesn't have access (currently never reached out while covid update banner exists)
   *      - is ACT/SAT subject and doesn't have access
   *  freemium user
   *      - in free subject
   *   Is one of AP subjects in `updatedSubjects` aka subjects with a review blog post
   *   anyone else who should see a banner (retail stud not in AP/ACT/SAT)
   *
   * @param subject
   * @param user
   * @param userHasSubjectAccess
   */
  getBannerContent = (subject, user, userHasSubjectAccess) => {
    const {className} = this.props;
    const subjectId = subject.getId();

    let content;
    let to;
    let anchor;
    let marketingBanner;

    const domain = subject.getDomainSlug();
    const group = subject.getGroupSlug();
    const marketplaceLink = `/marketplace/subjects?domain=${domain}&group=${group}&subject=${subject.getUrlSlug()}&subjectId=${subject.getId()}`; // eslint-disable-line

    const retailStudent =
      !sessionStore.hasValidSession() ||
      (sessionStore.isStudent() && user.getStudent().getStudentClassrooms().isEmpty());

    // Calculates the 12-month price of the subscriptions when user is a retail student
    // Price is expected to be non-null in use because it's used only after checking that user is a retail student
    const prices = retailStudent ? subject.getSubscriptionsPrices().first() : null;
    const price = prices
      ? (prices.get('expiresInMonths') === 12 ? prices.get('price') : 4900) / 100
      : null;

    const isACTorSAT = subject.getName().includes('ACT®') || subject.getName().includes('SAT®');
    // can be commented back after ap exams
    // if (subject.getName().includes('AP®') && retailStudent && !userHasSubjectAccess) {
    //   const text = `Looking to get a 5 on this year's exam? `;
    //   const headerText = `Purchase this course for $${price} and get access to hundreds of authentic exam questions with detailed explanations.`;
    //   marketingBanner = (
    //     <MarketingBanner
    //       text={text}
    //       headingText={headerText}
    //       buttonText='Upgrade now'
    //       className='u-mar-b_2'
    //       buttonProps={{
    //         as: Link,
    //         to: marketplaceLink
    //       }}
    //     />
    //   );
    // } else
    if (subject.getBanner()) {
      content = <MarkdownRendererV2 text={subject.getBanner()} />;
    } else if (isACTorSAT && retailStudent && !userHasSubjectAccess) {
      const text = `Looking to get a perfect score on the exam?`;
      const headerText = `Purchase this course for $${price} and get access to hundreds of authentic exam questions with detailed explanations.`;
      marketingBanner = (
        <MarketingBanner
          className={className}
          text={text}
          headingText={headerText}
          buttonText='Upgrade now'
          buttonProps={{
            as: Link,
            to: marketplaceLink
          }}
        />
      );
      content = `Purchase this course for $${price} to get full access.`;
      to = marketplaceLink;
      anchor = 'Upgrade now!';
    } else if (updatedSubjects[subjectId]) {
      /* eslint-disable max-len */
      // disabling the max-len rule is because we have a `white-space: pre-wrap` on the content in the atomic banner component
      content = `Stressed for your test? Get Albert's free ${moment().format(
        'YYYY'
      )} ${subject.getName()} review guide to help with your exam prep here.`;
      /* eslint-enable max-len */
      to = updatedSubjects[subjectId];
      anchor = 'Find out more!';
    } else if (subject.getName().includes('AP®')) {
      content = `Stressed for your test? Read Albert's free How to Study for Online AP Exams guide here.`;
      to = `/blog/how-to-study-for-online-ap-exams-coronavirus/?utm_source=subject-banner&utm_campaign=ap-prep-banner`;
      anchor = 'Find out more!';
    } else if (isFreemiumUser(user)) {
      // Logged out, super users, or unlicensed teacher/admin
      content = 'Want the full Albert experience for your school or class?';
      to = '/pricing-schools?utm_source=Global%20unlicensed%20teacher%20banner&utm_medium=in-app';
      anchor = 'Learn more!';
    }
    return {content, to, anchor, marketingBanner};
  };

  render() {
    const {subject, user, className} = this.props;
    const {isReady, value} = hasSubjectAccess(subject.getId());

    if (!isReady) {
      return null;
    }

    const {content, to, anchor, marketingBanner} = this.getBannerContent(subject, user, value);

    if (marketingBanner) {
      return marketingBanner;
    }

    if (!(content || to || anchor)) {
      return null;
    }

    return (
      <Banner className={className}>
        <div className='u-display_flex u-justify-content_space-between u-align-items_center u-flex-grow_1 u-gap_space-x2'>
          <span>{content}</span>
          <Anchor as='a' href={to} inheritColor underlined>
            {anchor}
          </Anchor>
        </div>
      </Banner>
    );
  }
}
