import React from 'react';
import constants from 'client/constants';
import {callAction, getStoreByName} from 'client/framework';
import {history} from 'client/history';
import sessionStore from 'client/Session/SessionStore';
import Drawer from 'generic/Drawer/Drawer.react';
import {invalidatePartialInterest} from 'resources/mandark.resource';
import {mandarkEndpoint} from '@albert-io/json-api-framework/request/builder/legacy';
import TemplateList from 'client/Assignments/Templates/TemplateList.react';
import IEContentWrapper from 'client/InteractionEngineV2/shared/IEContentWrapper/IEContentWrapper.react';
import ActiveQuestion from 'client/InteractionEngineV2/shared/ActiveQuestion/ActiveQuestion.react';
import interactionEngineStore from 'client/InteractionEngineV2/InteractionEngineStore';
import AssignmentInitializer from '../AssignmentInitializer/AssignmentInitializer.react';
import AssignmentNavigation from '../AssignmentNavigation/AssignmentNavigation.react';
import CopyToClipboard from 'generic/Clipboard/CopyToClipboard.react';
import teacherAssignmentQuestionsListStore from './TeacherAssignmentQuestionsList/TeacherAssignmentQuestionsList.store';
import TeacherAssignmentQuestionsList from './TeacherAssignmentQuestionsList/TeacherAssignmentQuestionsList.react';
import teacherAssignmentQuestionsListActions from './TeacherAssignmentQuestionsList/TeacherAssignmentQuestionsList.actions';

export default class TeacherAssignmentIE extends React.Component {
  getAssignmentActions() {
    const id = teacherAssignmentQuestionsListStore.assignmentId;
    return (
      <div className='interaction-engine-v2__actions'>
        <CopyToClipboard
          inline
          showToastOnSuccess
          toastMessage='The link to your folder has been copied to your clipboard. Share the link with other teachers to let them copy your folder.'
          value={`${constants.ALBERT_FULL_PATH}/folder/${id}`}
          button={
            <button className='interaction-engine-v2__button interaction-engine-v2__button--save'>
              Share
            </button>
          }
        />
        <button
          className='interaction-engine-v2__button interaction-engine-v2__button--save'
          disabled={teacherAssignmentQuestionsListStore.isSavePending()}
          onClick={() => {
            callAction(teacherAssignmentQuestionsListActions.SAVE_TEMPLATE_SET_LIST);
            invalidatePartialInterest(mandarkEndpoint(['templates_v1']).done());
          }}
        >
          Save
        </button>
        <button
          className='interaction-engine-v2__button interaction-engine-v2__button--assign'
          disabled={teacherAssignmentQuestionsListStore.isTemplateEmpty()}
          onClick={() => {
            /*
             * The following collects all the data we need to build
             * the assignment creator front-end. Edit with caution.
             */
            history.pushState(null, `/create-assignment/folder/${id}/`);
          }}
        >
          Create assignment
        </button>
      </div>
    );
  }

  getQuestionContent() {
    if (teacherAssignmentQuestionsListStore.isTemplateEmpty()) {
      return (
        <div className='interaction-engine-v2__empty-template card'>
          <h1>This folder is empty</h1>
          <p>
            You must add questions to this
            <br />
            folder before it can be assigned
          </p>
        </div>
      );
    }
    const activeQuestionId = teacherAssignmentQuestionsListStore.activeQuestion.getId();
    const questionStoreName = interactionEngineStore.getStoreNameForQuestionId(activeQuestionId);
    const questionStore = getStoreByName(questionStoreName);
    const showPrevButton = teacherAssignmentQuestionsListStore.hasPreviousQuestion(
      activeQuestionId
    );
    const showNextButton = teacherAssignmentQuestionsListStore.hasNextQuestion(activeQuestionId);

    return (
      <ActiveQuestion
        hideSubmitButton
        assignmentId={teacherAssignmentQuestionsListStore.assignmentId}
        question={teacherAssignmentQuestionsListStore.activeQuestion}
        questionSet={teacherAssignmentQuestionsListStore.activeQuestionSet}
        questionStoreName={questionStoreName}
        showPrevButton={showPrevButton}
        showNextButton={showNextButton}
        shouldShowExplanation={questionStore && questionStore.shouldShowExplanation()}
        shouldShowExplanationAfterGuessIsSubmitted
        isAssignmentOverdue={false}
      />
    );
  }

  getTemplatesContent() {
    return (
      <Drawer storeName='IEDrawerStore' drawerTitle='Select a folder'>
        <TemplateList />
      </Drawer>
    );
  }

  render() {
    return (
      <AssignmentInitializer
        key={teacherAssignmentQuestionsListStore.assignmentId}
        assignmentStore={teacherAssignmentQuestionsListStore}
        isLoggedIn={sessionStore.hasValidSession()}
        getChildren={() => {
          return (
            <IEContentWrapper
              subheader={
                <AssignmentNavigation
                  assignmentStore={teacherAssignmentQuestionsListStore}
                  assignmentActions={this.getAssignmentActions()}
                />
              }
              questionsList={<TeacherAssignmentQuestionsList />}
              questionArea={this.getQuestionContent()}
              statsArea={null}
              modals={this.getTemplatesContent()}
            />
          );
        }}
      />
    );
  }
}
