import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  Dropdown as AtomicDropdown,
  DropdownItem as AtomicDropdownItem,
  IconButton,
  Heading
} from '@albert-io/atomic';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';

import './title-bar.scss';

interface Props extends PropsWithClassNameOptional {
  backButton?: React.ReactNode;
  backgroundColor?: string;
  dropdown?: any;
  imgHref?: string;
  title: string;
}

const TitleBar = ({backButton, backgroundColor, className, dropdown, imgHref, title}: Props) => (
  <div
    className={classnames('pv-title-bar u-pad_3 u-color_white', className)}
    style={backgroundColor ? {backgroundColor} : undefined}
  >
    {backButton || null}
    {imgHref ? (
      <div
        className='pv-title-bar__img u-mar-l_1'
        style={{
          backgroundImage: `url(${imgHref})`
        }}
      />
    ) : null}
    <Heading
      as={MarkdownRendererV2}
      className='pv-title-bar__title u-mar-l_2'
      size='s'
      // @ts-ignore this was throwing an error for some reason, but is necessary
      text={title}
    />
    {dropdown || null}
  </div>
);

TitleBar.propTypes = {
  backButton: PropTypes.node,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  dropdown: PropTypes.node,
  imgHref: PropTypes.string,
  title: PropTypes.string
};

TitleBar.defaultProps = {
  backgroundColor: 'var(--background-domain-default)',
  title: `&nbsp;`
};

const BackButton = (props) => (
  <IconButton {...props} variant='text' className='u-color_white' size='l' />
);

BackButton.defaultProps = {
  icon: 'arrow-left'
};

TitleBar.BackButton = BackButton;

const Dropdown = (props) => (
  <AtomicDropdown
    {...props}
    className='title-bar-dropdown u-mar-l_1'
    trigger={<IconButton icon='ellipsis-v' label='Dropdown' />}
    position='bottom-end'
  />
);

TitleBar.Dropdown = Dropdown;
TitleBar.DropdownItem = AtomicDropdownItem;

export default TitleBar;
