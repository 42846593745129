import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SubSearch from 'client/components/SubSearch/SubSearch.react';
import {
  Button,
  Checkbox,
  LegacyFindAndApply,
  Label,
  ListGroup,
  ListGroupItem,
  Text
} from '@albert-io/atomic';
import {List} from 'immutable';
import {resource} from '@albert-io/json-api-framework/request/builder';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';

import StandardSetFilter from './StandardSetFilter.react';

export default class StandardsSubSearch extends React.Component {
  static propTypes = {
    subjectId: PropTypes.string.isRequired,
    guideType: PropTypes.string.isRequired,
    onApply: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      showDescriptions: false,
      standardSets: List()
    };
  }

  makeQuery = (searchString) => {
    return resource('standards_v1')
      .mandarkEndpoint(['standards_v1'])
      .include('standard_set_v1')
      .filter({
        questions_v3: {
          question_set_v1: {
            guide_levels_v2: {
              nlevel: 1,
              guide_v1: {
                guide_type: this.props.guideType
              }
            },
            subject_v2: this.props.subjectId
          }
        }
      })
      .filter(
        {
          standard_set_v1: this.state.standardSets
            .map((standardSet) => standardSet.getId())
            .join(',')
        },
        this.state.standardSets.size
      )
      .withMeta('standard_v1')
      .pageSize(250)
      .sort('-meta.search_relevance', !!searchString)
      .customQuery(
        {
          meta: {
            context: {
              search: {
                term: searchString,
                fields: 'title,description'
              }
            }
          }
        },
        !!searchString.length
      );
  };

  toggleDescriptions = () =>
    this.setState(({showDescriptions}) => ({showDescriptions: !showDescriptions}));

  render() {
    return (
      <SubSearch
        {...this.props}
        resourceType='search_standards_v2'
        filters={this.getCustomFilters}
        queryFunc={this.makeQuery}
        queryKeyword={this.state.standardSets}
        renderOptionalHeaderFunc={() => (
          <StandardSetFilter
            onApply={(standardSets) => {
              this.setState({standardSets});
            }}
            standardSets={this.state.standardSets}
            subjectId={this.props.subjectId}
          />
        )}
        leftFooterBtnFunc={({results}) => (
          <Button
            variant='text'
            color='secondary'
            className='u-mar-r_5 u-mar-l_0'
            aria-checked={this.state.showDescriptions}
            disabled={results.isEmpty()}
            onClick={this.toggleDescriptions}
            role='switch'
          >
            Show Descriptions
          </Button>
        )}
        renderResultsFunc={({onCheck, results, selectedItems}) => (
          <ListGroup
            as='ul'
            border='none'
            className='sub-search__results'
            paddingLevel={null}
            roundness={null}
          >
            {results.map((result) => (
              <ListGroupItem
                as='li'
                className={classnames('sub-search__result', {
                  'sub-search__result--description-hidden': !this.state.showDescriptions
                })}
                key={result.getId()}
              >
                <Checkbox
                  id={result.getId()}
                  checked={selectedItems.has(result.getId())}
                  onChange={(e) => onCheck(e, result)}
                />
                <div className='sub-search__result-details u-mar-l_1'>
                  <Label
                    className={classnames('sub-search__result-label', {
                      'u-mar-b_1': this.state.showDescriptions
                    })}
                    htmlFor={result.getId()}
                  >
                    <Text className='u-mar-r_1'>{result.getTitle()}</Text>
                    <Text color='tertiary'>{result.getStandardSet().getTitle()}</Text>
                  </Label>
                  <Text
                    aria-hidden={!this.state.showDescriptions}
                    as='div'
                    className={classnames('sub-search__result-description', {
                      'sub-search__result-description--hidden': !this.state.showDescriptions
                    })}
                    color='tertiary'
                    size='xs'
                  >
                    <MarkdownRendererV2 text={result.getDescription()} />
                  </Text>
                </div>
              </ListGroupItem>
            ))}
          </ListGroup>
        )}
        renderStagingFunc={() => (
          <LegacyFindAndApply.Message className='u-pad_3'>
            <Text color='tertiary'>
              All Albert questions are standards aligned. You can search for standards by name (e.g.
              RA R.1), category (e.g. Common Core), or description.
            </Text>
          </LegacyFindAndApply.Message>
        )}
      />
    );
  }
}
