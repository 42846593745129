import React, {useContext} from 'react';
import {PublicQuestionsContext, PublicQuestionsDispatch} from './PublicQuestionsContext';
import {Text, ListGroupItem, WindowSizeConsumer} from '@albert-io/atomic';
import {PublicQuestionModelV1} from 'src/resources/augmented//PublicQuestion/PublicQuestionModel.v1';
import MarkdownRenderer from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import classNames from 'classnames';

const QuestionDifficulty = ({question}: {question: PublicQuestionModelV1}) => {
  if (!question.getDifficulty()) {
    return null;
  }
  return (
    <div>
      <Text size='xs'>{question.getDifficultyLabel()}</Text>
    </div>
  );
};

const QuestionListItem = ({
  question,
  isMobile
}: {
  question: PublicQuestionModelV1;
  isMobile: boolean;
}) => {
  const dispatch = useContext(PublicQuestionsDispatch);
  const state = useContext(PublicQuestionsContext);
  const {currentQuestion} = state;
  const selectedClass = currentQuestion?.getId() === question.getId() ? 'selected ' : '';
  const classNames = `${selectedClass}public-question-list__question public-question-list__list-item`;

  return (
    <ListGroupItem
      className={classNames}
      role='listitem'
      as='button'
      onClick={() => {
        dispatch({
          type: 'setCurrentQuestion',
          question
        });
        if (isMobile) {
          dispatch({
            type: 'hideQuestionList'
          });
        }
      }}
    >
      <Text>
        <MarkdownRenderer key={question.getId()} text={question.getTitle()} />
      </Text>
      <QuestionDifficulty question={question} />
    </ListGroupItem>
  );
};

export const PublicQuestionList = () => {
  const state = useContext(PublicQuestionsContext);
  const {questions, questionCount, showQuestionList} = state;

  return (
    <WindowSizeConsumer>
      {({breakpoints, viewportWidth}) => {
        const isMobile = viewportWidth <= breakpoints.m;
        return (
          <div
            className={classNames('public-question-list u-mar-tb_2 highlighted-content-area', {
              'public-question-list--mobile': isMobile,
              'public-question-list--mobile--open': isMobile && showQuestionList
            })}
          >
            <div className='public-question-list__header u-pad-l_2 u-pad-t_2 u-pad-b_2 public-question-list__list-item'>
              <Text bold>{`${questionCount} questions`}</Text>
            </div>
            <div className='public-question-list__questions'>
              {questions.map((question) => (
                <QuestionListItem question={question} key={question.getId()} isMobile={isMobile} />
              ))}
            </div>
          </div>
        );
      }}
    </WindowSizeConsumer>
  );
};
