import {Map, List, fromJS, Range} from 'immutable';
import {range, last} from 'lodash';

/**
 * @todo: The methods below below likely belong as extensions in the authoring question model.
 * That said, the number of question-specific methods would add noise to the authoring question model,
 * therefore these methods will live here until we figure out a good way to split up the question types
 * and the parent model.
 */

/**
 * Adds a new empty region to a question's rubric.
 *
 * @param {AuthoringQuestionModelV1} question
 * @param {Object} regionIndices - Start and end indices to set on the region
 * @param {string} regionIndices.pendingStartIndex
 * @param {string} regionIndices.pendingEndIndex
 */
export function addRegion(question, regionIndices) {
  let rubric = question.getRubric();
  if (rubric.get('type') !== 'editableRegions') {
    rubric = question.getRubric().set('type', 'editableRegions');
  }

  const [start_index, end_index] = Object.values(regionIndices).sort((a, b) => a - b);
  const region = Map({
    start_index,
    end_index,
    solutions: null,
    annotation: null
  });

  const updatedRubric = rubric.update('valid_response', (rubric) =>
    rubric
      .toList()
      .push(region)
      .sortBy((region) => region.get('start_index'))
      .reduce((acc, region, i) => acc.set(`${i}`, region), Map())
  );

  return {
    updatedQuestion: question.setRubric(updatedRubric),
    regionIndex: updatedRubric.get('valid_response').keyOf(region)
  };
}

/**
 * Returns an array of arrays where the child arrays contain the region's start index at position 0,
 * and end index at position 1.
 *
 * @param {AuthoringQuestionModelV1} question
 */
export function getCorrectedWordIndices(question) {
  return question.getValidResponse().reduce((acc, region) => {
    acc.push([region.get('start_index'), region.get('end_index')]);
    return acc;
  }, []);
}

/**
 * Returns whether a given index is in an existing region.
 *
 * @param {AuthoringQuestionModelV1} question
 * @param {number} index
 */
export function isInExistingRegion(question, index) {
  return getCorrectedWordIndices(question).some(([startIndex, endIndex]) => {
    return startIndex <= index && index <= endIndex;
  });
}

/**
 * Returns whether or not a pending region's indices exist in one of the question's current regions.
 *
 * @param {AuthoringQuestionModelV1} question
 * @param {*} startIndex - Potential region's start index
 * @param {*} endIndex  - Potential region's end index
 */
export function intersectsExistingRegion(question, startIndex, endIndex) {
  if (question.getValidResponse().isEmpty()) {
    return false;
  }

  const pendingRegion = List([startIndex, endIndex]);
  const combinedRegions = question
    .getValidResponse()
    .toList()
    .map((region) => List([region.get('start_index'), region.get('end_index')]))
    .push(pendingRegion)
    .sort((a, b) => a.first() - b.first());
  const pendingRegionIndex = combinedRegions.indexOf(pendingRegion);

  if (pendingRegionIndex === 0) {
    // If our pending region is the first one, we're clashing if the pending region's endIndex is higher than the
    // next regions's startIndex
    return endIndex >= combinedRegions.get(1).first();
  } else if (pendingRegionIndex === combinedRegions.size - 1) {
    // If our pending region is the last one, we're clashing if the pending region's startIndex is lower than the
    // previous regions's endIndex
    return startIndex <= combinedRegions.get(combinedRegions.size - 2).last();
  } else {
    // If it's in the middle we have to do both comparisons.
    const prevRegion = combinedRegions.get(pendingRegionIndex - 1);
    const nextRegion = combinedRegions.get(pendingRegionIndex + 1);
    return startIndex <= prevRegion.last() || endIndex >= nextRegion.first();
  }
}

/**
 * Returns an array where the first item is the active region's start index,
 * and the second item is the activeRegions's end index.
 *
 * @param {AuthoringQuestionModelV1} question
 * @param {*} activeRegion
 */
export function getActiveRegionIndices(question, activeRegion) {
  const {start_index, end_index} = question
    .getValidResponse()
    .get(activeRegion)
    .toJS();
  return [start_index, end_index];
}

/**
 * Deletes region with key of regionIndex. Note that region keys are numbers cast to strings.
 *
 * @param {AuthoringQuestionModelV1} question
 * @param {string} regionIndex
 */
export function removeRegion(question, regionIndex) {
  const updatedRegions = question
    .getValidResponse()
    .delete(regionIndex)
    .toList()
    .sortBy((region) => region.get('start_index'))
    .reduce((acc, region, i) => acc.set(`${i}`, region), Map());
  return question.setValidResponse(updatedRegions);
}

/**
 * Removes all corrections associated with the question.
 *
 * @param {AuthoringQuestionModelV1} question
 */
export function removeAllRegions(question) {
  return question.setValidResponse(Map());
}

/**
 * This method updates a given region, and also ensures that the `validResponse` does not contain any Unannotated
 * Distractors.
 *
 * ```
 * validResponse: {
 *   "0": {
 *     start_index: 1,              -|
 *     end_index: 3,                 |--- Correction
 *     solution: ['foo bar baz'],    |
 *     annotation: 'Example'        -|
 *   },
 *   "1": {
 *     start_index: 5,              -|
 *     end_index: 7,                 |--- Annotated distractor
 *     solution: null,               |
 *     annotation: 'Borp 123'       -|
 *   },
 *   "2": {
 *     start_index: 9,              -|
 *     end_index: 12,                |--- Unannotated Distractor (this needs to be broken up)
 *     solution: null,               |
 *     annotation: null             -|
 *   }
 * }
 * ```
 *
 * Above is a sample `validResponse` for a passage correction rubric. "0", "1", and "2" are regions. Regions
 * can be one of the three types pointed out above. When an author creates a region, they can make it span multiple
 * words. In the case of a distractor without annotations, we need to split the region up into one region per word,
 * because one of the requirements is that if a student corrects an unannotated distractor, only the word that was
 * incorrectly corrected should be highlighted as incorrect (this is not the case with Corrections or Annotated
 * Distractors, where the whole region is supposed to be marked as incorrect).
 *
 * @param {Object} param0
 * @param {AuthoringQuestionModelV1} param0.question
 * @param {string} param0.regionIndex
 * @param {string[]} param0.corrections
 * @param {string} param0.annotation
 */
export function updateRegion({question, regionIndex, corrections, annotation}) {
  const normalizedCorrections = corrections.filter((correction) => correction !== '');
  let updatedRegionIndex = 0;

  const updatedValidResponse = question
    .getValidResponse()
    .update(regionIndex, (region) =>
      region.merge(
        fromJS({
          solutions: normalizedCorrections.length ? normalizedCorrections : null,
          annotation: annotation ? annotation : null
        })
      )
    )
    .sortBy((val, key) => Number(key))
    .reduce((acc, value) => {
      const startIndex = value.get('start_index');
      const endIndex = value.get('end_index');
      const wordCount = endIndex - startIndex + 1;

      if (
        // If an unannotated distractor has multiple words, it must be broken up into one region per word
        value.get('annotation') === null &&
        wordCount > 1
      ) {
        Range(startIndex, endIndex + 1).forEach((index) => {
          acc = acc.set(
            `${updatedRegionIndex++}`,
            Map({
              start_index: index,
              end_index: index,
              annotation: null,
              solutions: null
            })
          );
        });
      } else {
        acc = acc.set(`${updatedRegionIndex++}`, value);
      }

      return acc;
    }, Map());
  return question.setValidResponse(updatedValidResponse);
}

/**
 * Turns all words in the question into distractors.
 *
 * @param {AuthoringQuestionModelV1} question
 * @return AuthoringQuestionModelV1 - updated model where all word indices are active regions
 */
export function makeAllWordsEditable(question) {
  let newQuestion = question;

  // regex is in case someone double spaces after a word
  newQuestion
    .getUncorrectedText()
    .replace(/ {1,}/g, ' ')
    .split(' ')
    .map((w, idx) => {
      if (!isInExistingRegion(newQuestion, idx)) {
        const {updatedQuestion} = addRegion(newQuestion, [idx, idx]);
        newQuestion = updatedQuestion;
      }
    });

  return newQuestion;
}

/**
 * Temporary validator to prevent authors from creating invalid questions. This is a stopgap
 * so we don't make questions with invalid rubrics. We will hopefully soon be moving away from
 * Question.model and using generated models instead. When we do that, we can write proper
 * validators.
 *
 * @param {Object} param0
 * @param {*} param0.activeRegion
 * @param {string[]} param0.pendingCorrections
 * @param {string} param0.annotation
 */
export function validReponseRegionValidator({activeRegion, pendingCorrections, annotation}) {
  const regionWordCount = activeRegion.get('end_index') - activeRegion.get('start_index') + 1;
  const hasEmptyCorrections =
    pendingCorrections.length > 1 && pendingCorrections.some((correction) => correction === '');

  if (hasEmptyCorrections) {
    return 'Regions that are not distractors must not have empty corrections.';
  }

  const normalizedCorrections = pendingCorrections.filter((correction) => correction !== '');
  const hasTooShortCorrections = normalizedCorrections.some((correction) => {
    return correction.split(/\s/).length < regionWordCount;
  });

  if (hasTooShortCorrections) {
    return 'Corrections must contain at least as many words as the uncorrected text contains.';
  }

  const isMissingCorrectedAnnotation = normalizedCorrections.length > 0 && !annotation;

  if (isMissingCorrectedAnnotation) {
    return 'Regions with corrections must have an annotation.';
  }

  return null;
}
