import {createContext} from 'react';
import {fromJS} from 'immutable';
import moment from 'moment';
import systemTimeOffsetStore from 'client/generic/SystemTimeOffset/SystemTimeOffset.store';
import makeConstants from 'lib/makeConstants';
import {ClassroomModelV1} from 'resources/augmented/Classroom/ClassroomModel.v1';
import {StudentModelV2} from 'resources/augmented/Student/StudentModel.v2';

export interface AssignmentContextProps {
  assignment: any;
  classroom: ClassroomModelV1;
  student: StudentModelV2;
  status: any;
  isGraded: boolean;
  isOutOfTime: boolean;
  isAnswerable: boolean;
  isPastDue: boolean;
  isSavingDraftGuess: boolean;
  classroomId: string;
  setIsSavingDraftGuess: (value: boolean) => void;
  studentId: string;
}

export const AssignmentContext = createContext({} as AssignmentContextProps);

/**
 * @typedef {import('../../resources/augmented/Assignment/AssignmentModel.v3').AssignmentModelV3} Assignment
 * @typedef {import('moment').Moment} Moment
 */

export const correctAnswerSettings = makeConstants(
  'showAfterAll',
  'showAfterEach',
  'showAfterDueDate'
);

export const statusTypes = makeConstants('notStarted', 'inProgress', 'submitted');

/**
 * @param root0
 * @param root0.assignment
 * @returns {{
 *   getConfirmedSentEmailAt: () => ?Moment,
 *   getDeletedAt: () => ?Moment,
 *   getSentEmailAt: () => ?Moment,
 *   getStartTime: () => ?Moment,
 *   getSubmitted: () => ?Moment
 * }}
 */
function getStudentRelationshipData({assignment}) {
  return assignment.getStudents().first().getAssignmentRelationships(assignment.getId());
}

/**
 * @param root0
 * @param root0.assignment
 * @returns {string}
 */
function getSubmissionStatus({assignment}) {
  const relationshipData = getStudentRelationshipData({assignment});
  const isSubmitted =
    assignment.getMeta().isStudentSubmitted() || !!relationshipData.getSubmitted();
  const isStarted = !!relationshipData.getStartTime();

  if (isSubmitted) {
    return statusTypes.submitted;
  }
  if (!isStarted) {
    return statusTypes.notStarted;
  }
  return statusTypes.inProgress;
}

/**
 * Graded means that the student is able to see how they did in their assignment, and they can also see
 * the explanation of all questions, even ones they did not answer.
 *
 * @param root0
 * @param root0.assignment
 * @param root0.classroomId
 * @param root0.studentId
 * @returns {boolean}
 */
export function isGraded({assignment, classroomId, studentId}) {
  const submissionStatus = getSubmissionStatus({assignment});
  const correctAnswerSetting = assignment.getCorrectAnswerSettingFromSettings({
    classroomId,
    studentId
  });

  /**
   * If the assignment is `submitted`, explanations are _available_ if the assignment is not `showAfterDueDate`,
   * or if the due date is in the past.
   */
  if (
    submissionStatus === statusTypes.submitted &&
    (correctAnswerSetting !== correctAnswerSettings.showAfterDueDate ||
      isDueDatePast({assignment, classroomId, studentId}))
  ) {
    return true;
  }

  const allowsLateSubmissions = assignment.getIsAllowLateSubmissionsFromSettings({
    classroomId,
    studentId
  });
  const pastDue = isPastDue({assignment, classroomId, studentId});
  /**
   * If the assignment is `pastDue`, explanations are _available_ if the assignment is not `allowsLateSubmissions`
   */
  if (pastDue && !allowsLateSubmissions) {
    return true;
  }

  return false;
}

/**
 * Provides the data required to render     an assignment's details
 *
 * @param root0
 * @param root0.assignment
 * @returns {{
 *   isStarted: boolean,
 *   startTime: *,
 *   isSubmitted: boolean,
 *   submittedAt: *,
 *   status: string
 * }}
 */
export function getStudentAssignmentData({assignment}) {
  const student = assignment.getStudents().first();
  const assignmentMeta = assignment.getMeta();
  const relationshipData = student.getAssignmentRelationships(assignment.getId());
  const submittedAt = relationshipData.getSubmitted();
  const isSubmitted = submittedAt !== null;
  const status = getSubmissionStatus({assignment});
  const classroom = assignment.getStudentClassrooms().isEmpty()
    ? null
    : assignment.getClassroomWithLatestAssignmentDueDate();
  const questionCount = assignmentMeta.getCountOfQuestions();
  const startTime = relationshipData.getStartTime();
  const isStarted = startTime !== null;
  const classroomId = classroom?.getId?.();
  const studentId = student.getId();
  return {
    isStarted,
    startTime,
    isSubmitted,
    submittedAt,
    status,
    classroom,
    questionCount,
    student,
    isGraded: isGraded({assignment, classroomId, studentId})
  };
}

/**
 * Provides the data required to render an assignment's details
 *
 * @param root0
 * @param root0.assignment
 * @returns {*}
 */
export function getStudentStartTime({assignment}) {
  const startTime = getStudentRelationshipData({assignment}).getStartTime();
  return startTime ? moment(startTime) : null;
}

export async function startAssignment({assignment}) {
  const student = assignment.getStudents().first();
  const assignmentId = assignment.getId();
  return student
    .addRelationship({
      type: 'assignments_v3',
      relation: [assignmentId],
      customMeta: fromJS({
        [assignmentId]: {
          start_time: 'now'
        }
      })
    })
    .save();
}

export async function submitAssignment({assignment}) {
  const student = assignment.getStudents().first();
  const assignmentId = assignment.getId();
  return student
    .addRelationship({
      type: 'assignments_v3',
      relation: [assignmentId],
      customMeta: fromJS({
        [assignmentId]: {
          submitted: 'now'
        }
      })
    })
    .save();
}

/**
 * Given a list of question sets with questions included, returns the first question that
 * does not have a guess, and its question set. If all questions have guesses or if the assignment
 * is submitted, it returns the first question and its set.
 *
 * @param root0
 * @param root0.questionSets
 * @param root0.guesses
 * @param root0.isGraded
 */
export function getInitialSetAndQuestion({questionSets, guesses, isGraded: isGradedValue}) {
  let initialSet;
  let initialQuestion;

  if (!isGradedValue) {
    initialSet = questionSets.find(
      (set) => {
        const unansweredQuestion = set.getQuestions().find((question) => {
          return guesses.some((guess) => guess.getQuestion().getId() !== question.getId());
        });

        if (unansweredQuestion) {
          initialQuestion = unansweredQuestion;
          return true;
        }

        return false;
      },
      null,
      questionSets.first()
    );
  } else {
    initialSet = questionSets.first();
    initialQuestion = initialSet.getQuestions().first();
  }

  return {
    questionSet: initialSet,
    question: initialQuestion || initialSet?.getQuestions?.()?.first?.()
  };
}

/**
 * An assignment is past due if it's not submitted and the due date
 * is in the past, or if was submitted after its due date.
 *
 * @param root0
 * @param root0.assignment
 * @param root0.classroomId
 * @param root0.studentId
 */
export function isPastDue({assignment, classroomId, studentId}) {
  const now = systemTimeOffsetStore.getCurrentTime();
  const dueDate = assignment.getDueDateFromSettings({
    classroomId,
    studentId
  });

  // If the assignment's due date is in the future, it's not past due
  if (dueDate.isAfter(now)) {
    return false;
  }

  // If the assignment was submitted before the due date, it's not past due
  const relationshipData = getStudentRelationshipData({assignment});
  const submittedAt = relationshipData.getSubmitted();
  if (submittedAt && moment(submittedAt).isBefore(dueDate)) {
    return false;
  }

  return true;
}

/**
 * Returns whether or not guesses are able to be placed on an assignment, either now or in
 * the future. This includes assignments which are not started as long as guesses will eventually
 * be able to placed against them.
 *
 * @param root0
 * @param root0.assignment
 * @param root0.classroomId
 * @param root0.studentId
 */
export function isAnswerable({assignment, classroomId, studentId}) {
  if (
    isGraded({assignment, classroomId, studentId}) ||
    getSubmissionStatus({assignment}) === statusTypes.submitted
  ) {
    return false;
  }

  const secondsLeft = getSecondsLeftInTimeLimit({assignment, classroomId, studentId});
  if (secondsLeft !== null && secondsLeft <= 0) {
    return false;
  }

  // If we haven't submitted the assignment, and it was due in the past,
  // and it does not allow late submissions, we can't place guesses
  if (
    isDueDatePast({assignment, classroomId, studentId}) &&
    !assignment.getIsAllowLateSubmissionsFromSettings({classroomId, studentId})
  ) {
    return false;
  }

  return true;
}

/**
 * @param root0
 * @param root0.assignment
 * @param root0.classroomId
 * @param root0.studentId
 */
export function isDueDatePast({assignment, classroomId, studentId}) {
  const now = systemTimeOffsetStore.getCurrentTime();
  return assignment.getDueDateFromSettings({classroomId, studentId}).isBefore(now);
}

/**
 * If the assignment has a time limit and it has been started, this returns the amount of seconds left
 * until that time limit has been reached. Otherwise, it returns null.
 *
 * @param root0
 * @param root0.assignment
 * @param root0.classroomId
 * @param root0.studentId
 * @returns {?number}
 */
export function getSecondsLeftInTimeLimit({assignment, classroomId, studentId}) {
  const startTime = getStudentStartTime({assignment});
  const timeLimit = assignment.getTimeLimitFromSettings({classroomId, studentId});

  if (!timeLimit || !startTime) {
    return null;
  }

  const outOfTimeDate = moment(startTime).add(timeLimit, 'minutes');
  const now = systemTimeOffsetStore.getCurrentTime();
  return outOfTimeDate.diff(now, 'seconds');
}

export function isOutOfTime({assignment, classroomId, studentId}) {
  const secondsLeft = getSecondsLeftInTimeLimit({assignment, classroomId, studentId});
  return secondsLeft !== null && secondsLeft <= 0;
}

export function isGuessOrDraftGuessEmpty(guessOrDraftGuess: any) {
  const content = guessOrDraftGuess.get('content');

  // All draft guesses except graphing, free-entry, and free-response.
  if (content.isEmpty()) {
    return true;
  }

  // Graphing draft guesses
  if (content.has('entities') && content.get('entities').size === 0) {
    return true;
  }

  // Free-response draft guesses
  if (content.has('text') && content.has('raw') && !hasFreeResponseDraftGuess(content)) {
    return true;
  }

  // Free-entry draft guesses
  if (content.every((freeEntryValue) => freeEntryValue === '')) {
    return true;
  }

  return false;
}

function hasFreeResponseDraftGuess(content) {
  const hasTextSubmission = content.get('text')?.trim() !== '';

  const rawContent = JSON.parse(content.get('raw') || '[]');

  const hasNonTextSubmission = rawContent.some((node) => {
    return ['audio-chip', 'image'].includes(node.type);
  });

  return hasTextSubmission || hasNonTextSubmission;
}
