/* eslint-disable */


// Programatically generated on Mon Mar 31 2025 17:10:40 GMT+0000
import {fromJS, List, Map, Record, Set} from 'immutable';
import {GenericModel} from 'resources/Generic.model';
import {validate, Empty} from '@albert-io/json-api-framework/models/helpers';
import moment from 'moment';

const ModelConfig = fromJS(
  {
    'endpoint': 'questions_v2',
    'attributes': {
      'dropdowns': {
        'type': 'map[]',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'points': {
        'type': 'integer',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'question_type_attributes': {
        'type': 'map',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'prompt': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'snippet_prompt': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'preferred_permutation': {
        'type': 'binary_id',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'position': {
        'type': 'integer',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'inputs': {
        'type': 'map[]',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'header_right': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'rubric_type': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'slug_id': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'highlight_prompt': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'plagiarism_report_link': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'question_sub_type': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'question_type': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'updated_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'title': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'rows': {
        'type': 'map[]',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'header_left': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'inserted_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'dropdown_text': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'id': {
        'type': 'binary_id',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'difficulty': {
        'type': 'integer',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'region_indices': {
        'type': 'map',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'uncorrected_text': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'is_select_multiple': {
        'type': 'boolean',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'options': {
        'type': 'map[]',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'plagiarism_checked_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      }
    },
    'relationships': {
      'supplements_v1': {
        'type': 'one-to-many'
      },
      'tags_v1': {
        'type': 'one-to-many'
      },
      'user_feedback_v1': {
        'type': 'one-to-many'
      },
      'authoring_supplements_v1': {
        'type': 'one-to-many'
      },
      'authoring_question_set_v1': {
        'type': 'one-to-one'
      },
      'search_tags_v1': {
        'type': 'one-to-many'
      },
      'question_set_v1': {
        'type': 'one-to-one'
      },
      'draft_guesses_v1': {
        'type': 'one-to-many'
      },
      'authoring_labels_v1': {
        'type': 'one-to-many'
      },
      'guesses_v1': {
        'type': 'one-to-many'
      },
      'labels_v1': {
        'type': 'one-to-many'
      },
      'search_standards_v1': {
        'type': 'one-to-many'
      },
      'search_standards_v2': {
        'type': 'one-to-many'
      }
    }
  }
);

const ModelRecord = Record(
  {
    'dropdowns': Empty,
    'points': Empty,
    'question_type_attributes': Empty,
    'prompt': Empty,
    'guesses': Empty,
    'snippet_prompt': Empty,
    'draft_guesses': Empty,
    'authoring_labels': Empty,
    'preferred_permutation': Empty,
    'question_set': Empty,
    'position': Empty,
    'inputs': Empty,
    'header_right': Empty,
    'rubric_type': Empty,
    'slug_id': Empty,
    'highlight_prompt': Empty,
    'search_tags': Empty,
    'authoring_question_set': Empty,
    'plagiarism_report_link': Empty,
    'authoring_supplements': Empty,
    'question_sub_type': Empty,
    'question_type': Empty,
    'updated_at': Empty,
    'title': Empty,
    'tags': Empty,
    'user_feedback': Empty,
    'rows': Empty,
    'header_left': Empty,
    'inserted_at': Empty,
    'supplements': Empty,
    'dropdown_text': Empty,
    'id': Empty,
    'difficulty': Empty,
    'region_indices': Empty,
    'uncorrected_text': Empty,
    'relationshipMeta': Empty,
    'is_select_multiple': Empty,
    'options': Empty,
    'plagiarism_checked_at': Empty,
    'search_standards': Empty,
    'labels': Empty,
    'meta': new Map({
    'guess_placed_by_student_in_assignment_correct': Empty,
    'populated_metadata_fields': Empty,
    'section_sum_of_time_elapsed': Empty,
    'assignment_count_of_incorrect_guesses': Empty,
    'question_rubric': Empty,
    'section_average_time_elapsed': Empty,
    'matches_label': Empty,
    'how_others_answered': Empty,
    'sample': Empty,
    'assignment_count_of_correct_guesses': Empty,
    'assignment_sum_of_time_elapsed': Empty,
    'section_count_of_incorrect_guesses': Empty,
    'author_can_edit': Empty,
    'guess_placed_by_student': Empty,
    'matches_tag': Empty,
    'section_count_of_correct_guesses': Empty,
    'solution_text': Empty,
    'guess_placed_by_student_in_assignment_time_elapsed': Empty,
    'guess_placed_by_student_in_assignment': Empty,
    'classroom_insights_count_correct_students': Empty,
    'classroom_insights_average_time_spent': Empty,
    'guess_placed_by_student_correct': Empty,
    'classroom_insights_count_unanswered_students': Empty,
    'classroom_insights_count_incorrect_students': Empty,
    'matches_keyword': Empty,
    'assignment_average_time_elapsed': Empty,
    'active_assignment_question': Empty
  })
  }
);

const DefaultModel = fromJS(
  {
    'dropdowns': new List(),
    'points': 0,
    'prompt': '',
    'snippet_prompt': '',
    'preferred_permutation': '',
    'header_right': '',
    'rubric_type': '',
    'slug_id': '',
    'highlight_prompt': '',
    'plagiarism_report_link': '',
    'question_sub_type': '',
    'question_type': '',
    'title': '',
    'header_left': '',
    'dropdown_text': '',
    'id': '',
    'uncorrected_text': '',
    'updated_at': new Date(),
    'inserted_at': new Date(),
    'plagiarism_checked_at': new Date(),
    'guesses': new List(),
    'draft_guesses': new List(),
    'authoring_labels': new List(),
    'inputs': new List(),
    'search_tags': new List(),
    'authoring_supplements': new List(),
    'tags': new List(),
    'user_feedback': new List(),
    'rows': new List(),
    'supplements': new List(),
    'options': new List(),
    'search_standards': new List(),
    'labels': new List(),
    'question_type_attributes': new Map(),
    'meta': new Map(),
    'question_set': new Map(),
    'position': 0,
    'authoring_question_set': new Map(),
    'difficulty': 0,
    'region_indices': new Map(),
    'relationshipMeta': new Map(),
    'is_select_multiple': false
  }
);

export class QuestionModelV2 extends GenericModel(ModelRecord, ModelConfig, 'QuestionModelV2') {
  // -- Query Rules --
  /**
   * @returns {boolean}
   */
  static allowsInclude(include) {
    // AllowedIncludes is currently disabled.
    return true; // AllowedIncludes.has(include);
  }

  /**
   * @returns {boolean}
   */
  static allowsFilter(filterField, filterType) {
    return filterType ?
      FilterRules.get(filterField).has(filterType) :
      FilterRules.has(filterField);
  }

  /**
   * @returns {boolean}
   */
  static allowsSort(sort) {
    return sort.split(',').filter((s) => AllowedSorts.has(s)).length > 0;
  }

  /**
   * @returns {Array}
   */
  static getAllowedSorts() {
    return AllowedSorts.keySeq().toJS();
  }

  static getMetaContextQuery(key) {
    if (!MetadataContextRules.hasIn(['optional', key]) && !MetadataContextRules.hasIn(['required', key])) {
      console.error('Rule for meta context key ' + key + ' does not exist');
    }

    return MetadataContextRules.getIn(['optional', key]) || MetadataContextRules.getIn(['required', key]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getRequiredMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values()]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getAllMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values(), ...MetadataContextRules.get('optional').values()]);
  }

  static getDefaultModel() {
    _emptyQuestionModelV2Model = _emptyQuestionModelV2Model ? _emptyQuestionModelV2Model : new QuestionModelV2(DefaultModel);
    return _emptyQuestionModelV2Model;
  }

  static supportsMetaContext() {
    return MetadataContextRules !== null;
  }

  /**
   * @returns {string}
   */
  static getModelName() {
    return 'QuestionModelV2';
  }

  /**
   * @returns {boolean}
   */
  static _isFullSpec() {
    return true;
  }

  constructor() {
    super(...arguments);
    this.__meta = null;
  }

  /**
   * @returns {string}
   */
  getJsonApiType() {
    return 'question_v2';
  }

  /**
   * @params {string} - An attribute name
   * @params {*} [def] - Optional default value override for the attribute
   */
  getValueForAttribute(attribute, def) {
    let value = this.get(attribute);
    validate(value, attribute);
    if (value === Empty) {
      value = def !== undefined ? def : DefaultModel.get(attribute);
    }
    return value;
  }

  // -- Primary Attributes --
  
  /**
   * @type {integer}
   */
  getDifficulty() {
    const value = this.getValueForAttribute('difficulty');
    
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
  }

  setDifficulty(value) {
    return this.setField('difficulty', value);
  }

  /**
   * @type {string}
   */
  getDropdownText() {
    const value = this.getValueForAttribute('dropdown_text');
    return value;
  }

  setDropdownText(value) {
    return this.setField('dropdown_text', value);
  }

  /**
   * @type {map[]}
   */
  getDropdowns() {
    const value = this.getValueForAttribute('dropdowns');
    return value;
  }

  setDropdowns(value) {
    return this.setField('dropdowns', value);
  }

  /**
   * @type {string}
   */
  getHeaderLeft() {
    const value = this.getValueForAttribute('header_left');
    return value;
  }

  setHeaderLeft(value) {
    return this.setField('header_left', value);
  }

  /**
   * @type {string}
   */
  getHeaderRight() {
    const value = this.getValueForAttribute('header_right');
    return value;
  }

  setHeaderRight(value) {
    return this.setField('header_right', value);
  }

  /**
   * @type {string}
   */
  getHighlightPrompt() {
    const value = this.getValueForAttribute('highlight_prompt');
    return value;
  }

  setHighlightPrompt(value) {
    return this.setField('highlight_prompt', value);
  }

  /**
   * @type {binary_id}
   */
  getId() {
    const value = this.getValueForAttribute('id');
    return value;
  }

  setId(value) {
    return this.setField('id', value);
  }

  /**
   * @type {map[]}
   */
  getInputs() {
    const value = this.getValueForAttribute('inputs');
    return value;
  }

  setInputs(value) {
    return this.setField('inputs', value);
  }

  /**
   * @type {datetime}
   */
  getInsertedAt() {
    const value = this.getValueForAttribute('inserted_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {boolean}
   */
  isSelectMultiple() {
    const value = this.getValueForAttribute('is_select_multiple');
    return value;
  }

  setIsSelectMultiple(value) {
    return this.setField('is_select_multiple', value);
  }

  /**
   * @type {map[]}
   */
  getOptions() {
    const value = this.getValueForAttribute('options');
    return value;
  }

  setOptions(value) {
    return this.setField('options', value);
  }

  /**
   * @type {datetime}
   */
  getPlagiarismCheckedAt() {
    const value = this.getValueForAttribute('plagiarism_checked_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  setPlagiarismCheckedAt(value) {
    const momentValue = moment(value);
    return this.setField('plagiarism_checked_at', momentValue.isValid() ? momentValue : value);
  }

  /**
   * @type {string}
   */
  getPlagiarismReportLink() {
    const value = this.getValueForAttribute('plagiarism_report_link');
    return value;
  }

  setPlagiarismReportLink(value) {
    return this.setField('plagiarism_report_link', value);
  }

  /**
   * @type {integer}
   */
  getPoints() {
    const value = this.getValueForAttribute('points');
    
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
  }

  setPoints(value) {
    return this.setField('points', value);
  }

  /**
   * @type {integer}
   */
  getPosition() {
    const value = this.getValueForAttribute('position');
    
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
  }

  setPosition(value) {
    return this.setField('position', value);
  }

  /**
   * @type {binary_id}
   */
  getPreferredPermutation() {
    const value = this.getValueForAttribute('preferred_permutation');
    return value;
  }

  setPreferredPermutation(value) {
    return this.setField('preferred_permutation', value);
  }

  /**
   * @type {string}
   */
  getPrompt() {
    const value = this.getValueForAttribute('prompt');
    return value;
  }

  setPrompt(value) {
    return this.setField('prompt', value);
  }

  /**
   * @type {string}
   */
  getQuestionSubType() {
    const value = this.getValueForAttribute('question_sub_type');
    return value;
  }

  setQuestionSubType(value) {
    return this.setField('question_sub_type', value);
  }

  /**
   * @type {string}
   */
  getQuestionType() {
    const value = this.getValueForAttribute('question_type');
    return value;
  }

  setQuestionType(value) {
    return this.setField('question_type', value);
  }

  /**
   * @type {map}
   */
  getQuestionTypeAttributes() {
    const value = this.getValueForAttribute('question_type_attributes');
    return value;
  }

  setQuestionTypeAttributes(value) {
    return this.setField('question_type_attributes', value);
  }

  /**
   * @type {map}
   */
  getRegionIndices() {
    const value = this.getValueForAttribute('region_indices');
    return value;
  }

  /**
   * @type {map[]}
   */
  getRows() {
    const value = this.getValueForAttribute('rows');
    return value;
  }

  setRows(value) {
    return this.setField('rows', value);
  }

  /**
   * @type {string}
   */
  getRubricType() {
    const value = this.getValueForAttribute('rubric_type');
    return value;
  }

  /**
   * @type {string}
   */
  getSlugId() {
    const value = this.getValueForAttribute('slug_id');
    return value;
  }

  /**
   * @type {string}
   */
  getSnippetPrompt() {
    const value = this.getValueForAttribute('snippet_prompt');
    return value;
  }

  setSnippetPrompt(value) {
    return this.setField('snippet_prompt', value);
  }

  /**
   * @type {string}
   */
  getTitle() {
    const value = this.getValueForAttribute('title');
    return value;
  }

  setTitle(value) {
    return this.setField('title', value);
  }

  /**
   * @type {string}
   */
  getUncorrectedText() {
    const value = this.getValueForAttribute('uncorrected_text');
    return value;
  }

  setUncorrectedText(value) {
    return this.setField('uncorrected_text', value);
  }

  /**
   * @type {datetime}
   */
  getUpdatedAt() {
    const value = this.getValueForAttribute('updated_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  // -- Meta Attributes --
  
    /**
     * LEGACY META ACCESSORS
     * @todo These should no longer be used, instead use this.getMeta()[...]
     */
    
      isActiveAssignmentQuestion() {
        return this.getMeta().isActiveAssignmentQuestion();
      }
    
      getAssignmentAverageTimeElapsed() {
        return this.getMeta().getAssignmentAverageTimeElapsed();
      }
    
      getAssignmentCountOfCorrectGuesses() {
        return this.getMeta().getAssignmentCountOfCorrectGuesses();
      }
    
      getAssignmentCountOfIncorrectGuesses() {
        return this.getMeta().getAssignmentCountOfIncorrectGuesses();
      }
    
      getAssignmentSumOfTimeElapsed() {
        return this.getMeta().getAssignmentSumOfTimeElapsed();
      }
    
      isAuthorCanEdit() {
        return this.getMeta().isAuthorCanEdit();
      }
    
      getClassroomInsightsAverageTimeSpent() {
        return this.getMeta().getClassroomInsightsAverageTimeSpent();
      }
    
      getClassroomInsightsCountCorrectStudents() {
        return this.getMeta().getClassroomInsightsCountCorrectStudents();
      }
    
      getClassroomInsightsCountIncorrectStudents() {
        return this.getMeta().getClassroomInsightsCountIncorrectStudents();
      }
    
      getClassroomInsightsCountUnansweredStudents() {
        return this.getMeta().getClassroomInsightsCountUnansweredStudents();
      }
    
      isGuessPlacedByStudent() {
        return this.getMeta().isGuessPlacedByStudent();
      }
    
      isGuessPlacedByStudentCorrect() {
        return this.getMeta().isGuessPlacedByStudentCorrect();
      }
    
      isGuessPlacedByStudentInAssignment() {
        return this.getMeta().isGuessPlacedByStudentInAssignment();
      }
    
      isGuessPlacedByStudentInAssignmentCorrect() {
        return this.getMeta().isGuessPlacedByStudentInAssignmentCorrect();
      }
    
      getGuessPlacedByStudentInAssignmentTimeElapsed() {
        return this.getMeta().getGuessPlacedByStudentInAssignmentTimeElapsed();
      }
    
      getHowOthersAnswered() {
        return this.getMeta().getHowOthersAnswered();
      }
    
      isMatchesKeyword() {
        return this.getMeta().isMatchesKeyword();
      }
    
      isMatchesLabel() {
        return this.getMeta().isMatchesLabel();
      }
    
      isMatchesTag() {
        return this.getMeta().isMatchesTag();
      }
    
      getPopulatedMetadataFields() {
        return this.getMeta().getPopulatedMetadataFields();
      }
    
      getQuestionRubric() {
        return this.getMeta().getQuestionRubric();
      }
    
      getSample() {
        return this.getMeta().getSample();
      }
    
      getSectionAverageTimeElapsed() {
        return this.getMeta().getSectionAverageTimeElapsed();
      }
    
      getSectionCountOfCorrectGuesses() {
        return this.getMeta().getSectionCountOfCorrectGuesses();
      }
    
      getSectionCountOfIncorrectGuesses() {
        return this.getMeta().getSectionCountOfIncorrectGuesses();
      }
    
      getSectionSumOfTimeElapsed() {
        return this.getMeta().getSectionSumOfTimeElapsed();
      }
    
      getSolutionText() {
        return this.getMeta().getSolutionText();
      }
    
    getMeta() {
      if (this.__meta) {
        return this.__meta;
      }
      const meta = this.get('meta', Map());
      this.__meta = {
        
          /**
           * @type {boolean}
           */
          isActiveAssignmentQuestion() {
            const value = meta.get('active_assignment_question');
            validate(value, 'active_assignment_question');
            return value;
          },
  
          /**
           * @type {float}
           */
          getAssignmentAverageTimeElapsed() {
            const value = meta.get('assignment_average_time_elapsed');
            validate(value, 'assignment_average_time_elapsed');
            
      const float = parseFloat(value, 10);
      return isNaN(float) ? value : float;
    
          },
  
          /**
           * @type {integer}
           */
          getAssignmentCountOfCorrectGuesses() {
            const value = meta.get('assignment_count_of_correct_guesses');
            validate(value, 'assignment_count_of_correct_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getAssignmentCountOfIncorrectGuesses() {
            const value = meta.get('assignment_count_of_incorrect_guesses');
            validate(value, 'assignment_count_of_incorrect_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getAssignmentSumOfTimeElapsed() {
            const value = meta.get('assignment_sum_of_time_elapsed');
            validate(value, 'assignment_sum_of_time_elapsed');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {boolean}
           */
          isAuthorCanEdit() {
            const value = meta.get('author_can_edit');
            validate(value, 'author_can_edit');
            return value;
          },
  
          /**
           * @type {integer}
           */
          getClassroomInsightsAverageTimeSpent() {
            const value = meta.get('classroom_insights_average_time_spent');
            validate(value, 'classroom_insights_average_time_spent');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getClassroomInsightsCountCorrectStudents() {
            const value = meta.get('classroom_insights_count_correct_students');
            validate(value, 'classroom_insights_count_correct_students');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getClassroomInsightsCountIncorrectStudents() {
            const value = meta.get('classroom_insights_count_incorrect_students');
            validate(value, 'classroom_insights_count_incorrect_students');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getClassroomInsightsCountUnansweredStudents() {
            const value = meta.get('classroom_insights_count_unanswered_students');
            validate(value, 'classroom_insights_count_unanswered_students');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {boolean}
           */
          isGuessPlacedByStudent() {
            const value = meta.get('guess_placed_by_student');
            validate(value, 'guess_placed_by_student');
            return value;
          },
  
          /**
           * @type {boolean}
           */
          isGuessPlacedByStudentCorrect() {
            const value = meta.get('guess_placed_by_student_correct');
            validate(value, 'guess_placed_by_student_correct');
            return value;
          },
  
          /**
           * @type {boolean}
           */
          isGuessPlacedByStudentInAssignment() {
            const value = meta.get('guess_placed_by_student_in_assignment');
            validate(value, 'guess_placed_by_student_in_assignment');
            return value;
          },
  
          /**
           * @type {boolean}
           */
          isGuessPlacedByStudentInAssignmentCorrect() {
            const value = meta.get('guess_placed_by_student_in_assignment_correct');
            validate(value, 'guess_placed_by_student_in_assignment_correct');
            return value;
          },
  
          /**
           * @type {integer}
           */
          getGuessPlacedByStudentInAssignmentTimeElapsed() {
            const value = meta.get('guess_placed_by_student_in_assignment_time_elapsed');
            validate(value, 'guess_placed_by_student_in_assignment_time_elapsed');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {map}
           */
          getHowOthersAnswered() {
            const value = meta.get('how_others_answered');
            validate(value, 'how_others_answered');
            return value;
          },
  
          /**
           * @type {boolean}
           */
          isMatchesKeyword() {
            const value = meta.get('matches_keyword');
            validate(value, 'matches_keyword');
            return value;
          },
  
          /**
           * @type {boolean}
           */
          isMatchesLabel() {
            const value = meta.get('matches_label');
            validate(value, 'matches_label');
            return value;
          },
  
          /**
           * @type {boolean}
           */
          isMatchesTag() {
            const value = meta.get('matches_tag');
            validate(value, 'matches_tag');
            return value;
          },
  
          /**
           * @type {string[]}
           */
          getPopulatedMetadataFields() {
            const value = meta.get('populated_metadata_fields');
            validate(value, 'populated_metadata_fields');
            return value;
          },
  
          /**
           * @type {map}
           */
          getQuestionRubric() {
            const value = meta.get('question_rubric');
            validate(value, 'question_rubric');
            return value;
          },
  
          /**
           * @type {string}
           */
          getSample() {
            const value = meta.get('sample');
            validate(value, 'sample');
            return value;
          },
  
          /**
           * @type {float}
           */
          getSectionAverageTimeElapsed() {
            const value = meta.get('section_average_time_elapsed');
            validate(value, 'section_average_time_elapsed');
            
      const float = parseFloat(value, 10);
      return isNaN(float) ? value : float;
    
          },
  
          /**
           * @type {integer}
           */
          getSectionCountOfCorrectGuesses() {
            const value = meta.get('section_count_of_correct_guesses');
            validate(value, 'section_count_of_correct_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getSectionCountOfIncorrectGuesses() {
            const value = meta.get('section_count_of_incorrect_guesses');
            validate(value, 'section_count_of_incorrect_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getSectionSumOfTimeElapsed() {
            const value = meta.get('section_sum_of_time_elapsed');
            validate(value, 'section_sum_of_time_elapsed');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {string}
           */
          getSolutionText() {
            const value = meta.get('solution_text');
            validate(value, 'solution_text');
            return value;
          },
  
      };
      return this.__meta;
    }
  
  // -- Relationships --
  
  /**
   * @type {guess_v1 (many)}
   */
  getGuesses() {
    const value = this.getValueForAttribute('guesses');
    return value;
  }

  // -- Relationship Fields: guesses --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {draft_guess_v1 (many)}
   */
  getDraftGuesses() {
    const value = this.getValueForAttribute('draft_guesses');
    return value;
  }

  // -- Relationship Fields: draft_guesses --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {authoring_label_v1 (many)}
   */
  getAuthoringLabels() {
    const value = this.getValueForAttribute('authoring_labels');
    return value;
  }

  // -- Relationship Fields: authoring_labels --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {question_set_v1 (one)}
   */
  getQuestionSet() {
    const value = this.getValueForAttribute('question_set');
    return value;
  }

  // -- Relationship Fields: question_set --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {search_tag_v1 (many)}
   */
  getSearchTags() {
    const value = this.getValueForAttribute('search_tags');
    return value;
  }

  // -- Relationship Fields: search_tags --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {authoring_question_set_v1 (one)}
   */
  getAuthoringQuestionSet() {
    const value = this.getValueForAttribute('authoring_question_set');
    return value;
  }

  // -- Relationship Fields: authoring_question_set --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {authoring_supplement_v1 (many)}
   */
  getAuthoringSupplements() {
    const value = this.getValueForAttribute('authoring_supplements');
    return value;
  }

  // -- Relationship Fields: authoring_supplements --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withAuthoringSupplementRelationshipGetters(data) {
    return {
      getContentOverride() {
        return data.get('content_override', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getAuthoringSupplementRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'authoring_supplement'], new Map());
    if (!id) {
      return data.map((rel) => this.__withAuthoringSupplementRelationshipGetters(rel));
    } else {
      return this.__withAuthoringSupplementRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {tag_v1 (many)}
   */
  getTags() {
    const value = this.getValueForAttribute('tags');
    return value;
  }

  // -- Relationship Fields: tags --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {user_feedback_v1 (many)}
   */
  getUserFeedback() {
    const value = this.getValueForAttribute('user_feedback');
    return value;
  }

  // -- Relationship Fields: user_feedback --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {supplement_v1 (many)}
   */
  getSupplements() {
    const value = this.getValueForAttribute('supplements');
    return value;
  }

  // -- Relationship Fields: supplements --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withSupplementRelationshipGetters(data) {
    return {
      getContentOverride() {
        return data.get('content_override', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getSupplementRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'supplement'], new Map());
    if (!id) {
      return data.map((rel) => this.__withSupplementRelationshipGetters(rel));
    } else {
      return this.__withSupplementRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {search_standard_v2 (many)}
   */
  getSearchStandards() {
    const value = this.getValueForAttribute('search_standards');
    return value;
  }

  // -- Relationship Fields: search_standards --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {label_v1 (many)}
   */
  getLabels() {
    const value = this.getValueForAttribute('labels');
    return value;
  }

  // -- Relationship Fields: labels --
  // no 'fields' found, skipped generating accessor(s)
  
}

// -- Empty Model Instance --
let _emptyQuestionModelV2Model = null;

// -- Sort Rules --

const AllowedSorts = fromJS({
  'difficulty': null,
  'dropdown_text': null,
  'header_left': null,
  'header_right': null,
  'highlight_prompt': null,
  'id': null,
  'inserted_at': null,
  'is_select_multiple': null,
  'meta.assignment_average_time_elapsed': null,
  'meta.assignment_count_of_correct_guesses': null,
  'meta.assignment_count_of_incorrect_guesses': null,
  'meta.assignment_sum_of_time_elapsed': null,
  'meta.author_can_edit': null,
  'meta.classroom_insights_average_time_spent': null,
  'meta.classroom_insights_count_correct_students': null,
  'meta.classroom_insights_count_incorrect_students': null,
  'meta.classroom_insights_count_unanswered_students': null,
  'meta.guess_placed_by_student': null,
  'meta.guess_placed_by_student_correct': null,
  'meta.guess_placed_by_student_in_assignment': null,
  'meta.guess_placed_by_student_in_assignment_correct': null,
  'meta.guess_placed_by_student_in_assignment_time_elapsed': null,
  'meta.matches_keyword': null,
  'meta.matches_label': null,
  'meta.matches_tag': null,
  'meta.sample': null,
  'meta.section_average_time_elapsed': null,
  'meta.section_count_of_correct_guesses': null,
  'meta.section_count_of_incorrect_guesses': null,
  'meta.section_sum_of_time_elapsed': null,
  'meta.solution_text': null,
  'plagiarism_checked_at': null,
  'plagiarism_report_link': null,
  'points': null,
  'position': null,
  'preferred_permutation': null,
  'prompt': null,
  'question_sub_type': null,
  'question_type': null,
  'rubric_type': null,
  'slug_id': null,
  'snippet_prompt': null,
  'title': null,
  'uncorrected_text': null,
  'updated_at': null,
});

// -- Include Rules --
// List []

// -- Filter Rules --
const FilterRules = new Map({
  'authoring_labels_v1': new Set(['associated']),
  'authoring_question_set_v1': new Set(['associated']),
  'authoring_supplements_v1': new Set(['associated']),
  'difficulty': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'draft_guesses_v1': new Set(['associated']),
  'dropdown_text': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'dropdowns': new Set(['null']),
  'guesses_v1': new Set(['associated']),
  'header_left': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'header_right': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'highlight_prompt': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'inputs': new Set(['null']),
  'inserted_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'is_select_multiple': new Set(['exact','not','null']),
  'labels_v1': new Set(['associated']),
  'meta.assignment_average_time_elapsed': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.assignment_count_of_correct_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.assignment_count_of_incorrect_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.assignment_sum_of_time_elapsed': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.author_can_edit': new Set(['exact','not','null']),
  'meta.classroom_insights_average_time_spent': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.classroom_insights_count_correct_students': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.classroom_insights_count_incorrect_students': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.classroom_insights_count_unanswered_students': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.guess_placed_by_student': new Set(['exact','not','null']),
  'meta.guess_placed_by_student_correct': new Set(['exact','not','null']),
  'meta.guess_placed_by_student_in_assignment': new Set(['exact','not','null']),
  'meta.guess_placed_by_student_in_assignment_correct': new Set(['exact','not','null']),
  'meta.guess_placed_by_student_in_assignment_time_elapsed': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.how_others_answered': new Set(['null']),
  'meta.matches_keyword': new Set(['exact','not','null']),
  'meta.matches_label': new Set(['exact','not','null']),
  'meta.matches_tag': new Set(['exact','not','null']),
  'meta.populated_metadata_fields': new Set(['null']),
  'meta.question_rubric': new Set(['null']),
  'meta.sample': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'meta.section_average_time_elapsed': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.section_count_of_correct_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.section_count_of_incorrect_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.section_sum_of_time_elapsed': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.solution_text': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'options': new Set(['null']),
  'plagiarism_checked_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'plagiarism_report_link': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'points': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'position': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'preferred_permutation': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'prompt': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'question_set_v1': new Set(['associated']),
  'question_sub_type': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'question_type': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'question_type_attributes': new Set(['null']),
  'region_indices': new Set(['null']),
  'rows': new Set(['null']),
  'rubric_type': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'search_standards_v1': new Set(['associated']),
  'search_standards_v2': new Set(['associated']),
  'search_tags_v1': new Set(['associated']),
  'slug_id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'snippet_prompt': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'supplements_v1': new Set(['associated']),
  'tags_v1': new Set(['associated']),
  'title': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'uncorrected_text': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'updated_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'user_feedback_v1': new Set(['associated']),
});
    

// -- Metadata Context Rules --
const MetadataContextRules = fromJS({
  optional: {
    'assignment_id': 'meta.context.assignment.id',
    'classroom_id': 'meta.context.classroom.id',
    'classroom_insights': 'meta.context.classroom.insights',
    'csv_headers': 'meta.context.csv_headers',
    'end_date': 'meta.context.end_date',
    'attempt_number': 'meta.context.guess.attempt_number',
    'most_recent': 'meta.context.guess.most_recent',
    'show_active_assignment': 'meta.context.question.show_active_assignment',
    'keyword_search': 'meta.context.search.keyword',
    'label_search': 'meta.context.search.label',
    'tag_search': 'meta.context.search.tag',
    'section_id': 'meta.context.section.id',
    'start_date': 'meta.context.start_date',
    'student_id': 'meta.context.student.id',
    'teacher_id': 'meta.context.teacher.id',
    'user_id': 'meta.context.user.id',
  },
  required: {
  }
});

// -- Legacy Name Export --
export const Question = QuestionModelV2;
