import React from 'react';
import PropTypes from 'prop-types';
import {setUpStore} from 'client/framework';
import SearchDropdownStore from 'sg/Dropdowns/SearchDropdown/SearchDropdown.store';
import {mandarkEndpoint} from '@albert-io/json-api-framework/request/builder/legacy';
import MandarkSearchDropdown, {
  mandarkSearchDropdownProps
} from 'sg/Dropdowns/MandarkSearchDropdown/MandarkSearchDropdown.react';

import validator from 'validator';

const pageSize = 25;
const dropDownRenderFunction = (option) => (
  <div className='search-option'>
    <div className='search-option-value'>{option.getName()}</div>
    <div className='search-option-detail'>{option.getFullAddress()}</div>
    <div className='search-option-district'>{option.getDistrict()}</div>
  </div>
);

export default class SchoolSearch extends React.Component {
  static propTypes = {
    country: PropTypes.string,
    zipCode: PropTypes.string,
    ...mandarkSearchDropdownProps
  };

  static defaultProps = {
    pageSize,
    renderFunc: dropDownRenderFunction
  };

  constructor(props) {
    super(props);
    setUpStore(SearchDropdownStore, props.storeName);
  }

  getSearchSchoolsByNameQuery = (searchString) => {
    const {country, zipCode} = this.props;
    const isUUID = validator.isUUID(searchString);

    return mandarkEndpoint(['schools_v4'])
      .pageSize(pageSize)
      .filter(
        {
          id: searchString
        },
        isUUID
      )
      .filter(
        {
          name: {
            case_insensitive_substring: searchString
          }
        },
        !isUUID
      )
      .filter(
        {
          /**
           * @see https://github.com/albert-io/project-management/issues/4314
           */
          country: encodeURIComponent(this.props.country)
        },
        !isUUID && country
      )
      .filter(
        {
          postal_code: zipCode
        },
        !isUUID && zipCode
      )
      .sort('name', !isUUID)
      .customQuery(
        {
          sort_context: {
            search_by_name: {
              name: searchString
            }
          }
        },
        !isUUID
      );
  };

  getSchoolByIdQuery(id) {
    return mandarkEndpoint(['schools_v5', id]);
  }

  render() {
    return (
      <div data-testid='school-search'>
        <MandarkSearchDropdown
          byIdQuery={this.getSchoolByIdQuery}
          indexQuery={this.getSearchSchoolsByNameQuery}
          {...this.props}
        />
      </div>
    );
  }
}
