import React, {ChangeEvent, useContext, useRef} from 'react';
import {range, startCase} from 'lodash';
import {withRouter} from 'react-router';

import {pushQueryParams} from 'client/history';
import {
  Button,
  Icon,
  Popover,
  Select,
  Text,
  Toggle,
  WithToggle,
  WithTooltip
} from '@albert-io/atomic';
import {getNumberWithSuffix} from 'lib/stringUtils';

import {ReportsContext} from '../../../Reports.context';
import {DIMENSIONS} from '../../../reports.utils';

import './attempt-filter-include-exclude-draft-guesses.scss';
import ChipButton from '../ChipButton';

function AttemptFilterIncludeExcludeDraftGuesses() {
  const buttonRef = useRef(null);
  const {attemptNumber, includeDraftGuesses, loading, path} = useContext(ReportsContext);

  function handleAttemptChange(event: ChangeEvent<HTMLInputElement>) {
    pushQueryParams({attempt: event.target.value});
  }

  function handleIncludeDraftGuessesChange(newIncludeDraftGuesses: boolean) {
    pushQueryParams({includeDraftGuesses: newIncludeDraftGuesses ? null : newIncludeDraftGuesses});
  }

  const hasAssignmentInDrilldown =
    path.includes(DIMENSIONS.assignments) || path.includes('gradebook');

  function getOptions() {
    return hasAssignmentInDrilldown ? [1] : ['mostRecent', ...range(1, 11)];
  }

  return (
    <WithToggle dismissOnEscape>
      {({onClick: togglePopover, on}) => {
        const attemptLabel =
          attemptNumber === 'mostRecent'
            ? 'Most Recent Attempts'
            : `${getNumberWithSuffix(attemptNumber)} Attempts`;
        return (
          <>
            <ChipButton buttonRef={buttonRef} onClick={togglePopover}>
              {includeDraftGuesses && <Icon icon={['fas', 'asterisk']} />}
              {attemptLabel}
              <Icon icon='caret-down' />
            </ChipButton>
            {on && (
              <Popover
                border='none'
                className='attempt-filter-include-exclude-draft-guesses__popover'
                expanded={on}
                modifiers={{
                  flip: {enabled: false},
                  preventOverflow: {
                    boundariesElement: 'viewport'
                  }
                }}
                paddingLevel={null}
                position='bottom-start'
                targetRef={buttonRef}
                usePortal={false}
              >
                <div className='u-display_flex u-flex-direction_row u-gap_space-x2 u-pad_3'>
                  <div>
                    <Text as='div' size='m'>
                      Include In-progress Attempts
                    </Text>
                    <Text as='div' color='secondary' size='s'>
                      Includes answers that have not yet been submitted by students on open
                      assignments.
                    </Text>
                  </div>
                  <Toggle
                    checked={includeDraftGuesses}
                    disabled={loading}
                    onChange={() => {
                      handleIncludeDraftGuessesChange(!includeDraftGuesses);
                    }}
                  />
                </div>
                <hr className='u-mar_0' />
                <div className='u-display_flex u-flex-direction_column u-pad_3'>
                  <WithTooltip
                    anchoredFixedPositionProps={{
                      modifiers: {
                        preventOverflow: {
                          boundariesElement: 'viewport'
                        }
                      }
                    }}
                    content='Assignments only have one attempt'
                    display='block'
                    enabled={hasAssignmentInDrilldown}
                    placement='bottom'
                    wrapperProps={{className: 'u-mar-b_3'}}
                  >
                    <Select
                      disabled={hasAssignmentInDrilldown || loading}
                      onChange={(event) => {
                        handleAttemptChange(event);
                      }}
                      value={attemptNumber}
                    >
                      {getOptions().map((option) => (
                        <option disabled={hasAssignmentInDrilldown} key={option} value={option}>
                          {typeof option === 'number' ? option : startCase(option)}
                        </option>
                      ))}
                    </Select>
                  </WithTooltip>
                  <Button
                    className='u-align-self_flex-end'
                    disabled={loading}
                    onClick={togglePopover}
                    variant='text'
                  >
                    Done
                  </Button>
                </div>
              </Popover>
            )}
          </>
        );
      }}
    </WithToggle>
  );
}

export default withRouter(AttemptFilterIncludeExcludeDraftGuesses);
