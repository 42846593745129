/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';
import {debounce} from 'lodash';

import constants from 'client/constants';

import {
  addToast,
  Button,
  Checkbox,
  Dialogue,
  Fieldset,
  Form,
  Label,
  PasswordField,
  Text
} from '@albert-io/atomic';

import ResetPasswordContext from './ResetPassword.context';

/**
 * ResetPassword
 *
 * This component is not to be confused with ChangePassword**
 *
 * RestPassword is used in the ResetPasswordModal component, which teachers
 * and administrators use to clear and reset students' passwords.
 *
 * It is also a dependency of the ChangePassword* family of components.
 *
 * The ResetPassword components's main purpose is to expose a set of primitives,
 * --mainly the "New Password" field and "Confirm Password" fields--which are
 * hooked into the same ResetPassword context.
 *
 * Thus the primitives come with their validation behaviors built in, including
 * enabling/disabling the form's "Save" button until all password criteria are
 * satisfied.
 *
 * By extending the ResetPassword context object to other children, additional
 * criteria can be added at any time (e.g. ChangePassword's "Old Password" field).
 *
 * @see https://github.com/albert-io/albert-marketplace/pull/9062
 *
 * Props:
    customDisableSubmitCondition - prop to override the enabling/disabling of the
      "Save" button
    onCancel - click handler added to the "Cancel" button. If undefined, we hide the
      "Cancel" button
    onError - request error handler, takes an Error() object instance
    onSubmit - request handler that runs in our "Save" button click handler. Takes the
      new password as an argument and must return a Promise.
    onSubmitCallback - an additional callback that runs on successful resolution
      of the onSubmit function.
    title: The text that will appear in the dialogue's header
 */

const NewPasswordField = (props) => {
  const {inputType, handlePasswordChange, isPasswordValid, passwordInputValue} =
    React.useContext(ResetPasswordContext);
  const hasError = passwordInputValue && !isPasswordValid;
  return (
    <PasswordField
      border
      className='u-mar-b_2'
      error={hasError}
      label='New password'
      message={
        hasError
          ? 'Password must have at least 8 characters and at least one number or special character.'
          : ''
      }
      onChange={handlePasswordChange}
      type={inputType}
      value={passwordInputValue}
      {...props}
    />
  );
};

const ConfirmPasswordField = (props) => {
  const {confirmInputValue, doPasswordsMatch, handleConfirmPasswordChange, inputType} =
    React.useContext(ResetPasswordContext);
  const hasError = confirmInputValue && !doPasswordsMatch;
  return (
    <PasswordField
      border
      className='u-mar-b_2'
      error={hasError}
      label='Confirm new password'
      message={hasError ? 'Passwords do not match' : ''}
      onChange={handleConfirmPasswordChange}
      type={inputType}
      value={confirmInputValue}
      {...props}
    />
  );
};

const ShowPasswordToggle = () => {
  const {setInputType} = React.useContext(ResetPasswordContext);
  return (
    <Label>
      <Checkbox className='u-mar-r_2' onChange={setInputType} />
      Show password
    </Label>
  );
};

const PasswordForm = ({children}) => {
  const {handleSubmit} = React.useContext(ResetPasswordContext);
  return (
    <Form onSubmit={handleSubmit}>
      <Fieldset className='u-display_flex u-flex-direction_column'>{children}</Fieldset>
    </Form>
  );
};

PasswordForm.propTypes = {
  children: PropTypes.node
};

const Legend = ({children}) => (
  <Text as='legend' className='u-mar-b_5' color='secondary'>
    {children}
  </Text>
);

Legend.propTypes = {
  children: PropTypes.node
};

export default class ResetPassword extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    customDisableSubmitCondition: PropTypes.bool,
    onCancel: PropTypes.func,
    onError: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    onSubmitCallback: PropTypes.func,
    title: PropTypes.string
  };

  static defaultProps = {
    onError: () => {
      addToast({
        title: 'Oops! Looks like something went wrong',
        color: 'negative',
        message: 'There was a problem with your request. If the problem persists, contact us.'
      });
    },
    onSubmitCallback: () => {},
    title: 'Reset password'
  };

  constructor() {
    super();

    this.state = {
      confirmInputValue: '',
      disableSubmit: true,
      doPasswordsMatch: true,
      passwordInputValue: '',
      inputType: 'password',
      isPasswordValid: true,
      isRequestInflight: false,
      setInputType: this.setInputType,
      handleConfirmPasswordChange: this.handleConfirmPasswordChange,
      handlePasswordChange: this.handlePasswordChange,
      handleSubmit: this.handleSubmit
    };
  }

  static ConfirmPasswordField = ConfirmPasswordField;

  static Form = PasswordForm;

  static NewPasswordField = NewPasswordField;

  static ShowPasswordToggle = ShowPasswordToggle;

  static Legend = Legend;

  shouldDisableSubmit = () => {
    const {customDisableSubmitCondition} = this.props;
    const {
      doPasswordsMatch,
      isPasswordValid,
      isRequestInflight,
      passwordInputValue,
      confirmInputValue
    } = this.state;
    const hasEnteredAndConfirmedPassword = passwordInputValue && confirmInputValue;

    return (
      isRequestInflight ||
      customDisableSubmitCondition ||
      !hasEnteredAndConfirmedPassword ||
      !doPasswordsMatch ||
      !isPasswordValid
    );
  };

  setInputType = (e) => {
    const inputType = e.target.checked ? 'text' : 'password';
    this.setState({inputType});
  };

  handleSubmit = async () => {
    if (this.shouldDisableSubmit()) {
      return;
    }
    this.setState({isRequestInflight: true});
    const value = this.state.passwordInputValue;

    try {
      await this.props.onSubmit(value);
      addToast({
        title: 'Success!',
        color: 'positive',
        message: `Your changes have been saved.`
      });
      this.props.onSubmitCallback();
    } catch (error) {
      this.props.onError(error);
    }
    this.setState({isRequestInflight: false});
  };

  /**
   * Using the forwardRef API on their descendents, handlePasswordChange, handleConfirmPasswordChange,
   * and validatePassword perform the necessary comparison and validation behaviors on our two inputs
   * without writing any sensitive information to our state tree. This results in a more secure component.
   *
   * @param e
   */
  handlePasswordChange = (e) => {
    const {value} = e.target;
    this.setState({
      passwordInputValue: value,
      confirmInputValue: ''
    });
    this.validatePassword(value);
  };

  validatePassword = (str) =>
    debounce((val) => {
      const passwordRegex = constants.REGEX_COLLECTION.PASSWORD;
      this.setState({
        disableSubmit: passwordRegex.test(val),
        isPasswordValid: passwordRegex.test(val)
      });
    }, 500)(str);

  handleConfirmPasswordChange = (e) => {
    const {value} = e.target;
    this.setState({confirmInputValue: value});
    this.validateConfirmPassword(value);
  };

  validateConfirmPassword = (str) =>
    debounce((val) => {
      this.setState(({passwordInputValue}) => ({
        disableSubmit: val !== passwordInputValue,
        doPasswordsMatch: val === passwordInputValue
      }));
    }, 500)(str);

  render() {
    const {onCancel, title, ...rest} = this.props;

    return (
      <ResetPasswordContext.Provider value={this.state}>
        <Dialogue title={title} {...rest}>
          <Dialogue.Body>{this.props.children}</Dialogue.Body>
          <Dialogue.BtnGroup>
            <Button disabled={this.shouldDisableSubmit()} onClick={this.handleSubmit}>
              Update password
            </Button>
            {typeof onCancel === 'function' && (
              <Button color='secondary' onClick={onCancel}>
                Cancel
              </Button>
            )}
          </Dialogue.BtnGroup>
        </Dialogue>
      </ResetPasswordContext.Provider>
    );
  }
}
