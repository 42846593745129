import React from 'react';
import {Heading} from '@albert-io/atomic';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const FillInTheBlankExplanation = ({question, isGuessSubmitted}) => {
  const dropdowns = question.getDropdowns();
  return (
    <div className='fitb-v2-explanation'>
      <Heading as='h2' size='s'>
        Correct Answer(s)
      </Heading>
      <MarkdownRendererV2
        className={classnames({
          'fitb-input-area-dropdown': !dropdowns.isEmpty()
        })}
        isExplanation
        text={question.getDropdownText()}
        dropdowns={dropdowns}
        isGuessSubmitted={isGuessSubmitted}
        store={question.getStore()}
        question={question}
      />
    </div>
  );
};

FillInTheBlankExplanation.propTypes = {
  question: PropTypes.object,
  isGuessSubmitted: PropTypes.bool
};

export default FillInTheBlankExplanation;
