/**
 * # Checkbox
 *
 * A checkbox component that allows users to select or deselect an option.
 *
 * ## Props
 *
 * - `className` (optional): Additional CSS class name for the checkbox.
 * - `indeterminate` (optional): Specifies whether the checkbox is in an indeterminate state.
 * - `size` (optional): The size of the checkbox. Can be 's' or 'm'.
 * - `label` (optional): The label text for the checkbox.
 * - `aria-hidden` (optional): Specifies whether the checkbox is hidden from assistive technologies.
 * - `round` (optional): Specifies whether the checkbox has rounded corners.
 *
 * ## Usage
 *
 * ```tsx
 * import Checkbox from './Checkbox.react';
 *
 * const MyComponent = () => {
 *   return (
 *     <Checkbox
 *       className="my-checkbox"
 *       indeterminate={false}
 *       size="m"
 *       label="Check me"
 *       aria-hidden={false}
 *       round={true}
 *     />
 *   );
 * };
 * ```
 */

import React from 'react';
import classnames from 'classnames';

import Icon from '../Icon/Icon.react';

import './checkbox.scss';

interface Props
  extends Omit<React.ComponentPropsWithoutRef<'input'>, 'size'>,
    PropsWithClassNameOptional {
  indeterminate?: boolean;
  size?: 's' | 'm';
  label?: string;
  'aria-hidden'?: boolean;
  round?: boolean;
}

export type CheckboxProps = RequireAtLeastOne<Props, 'label' | 'aria-hidden'>;

export const Checkbox = ({
  className,
  checked,
  size = 'm',
  disabled = false,
  indeterminate = false,
  label,
  defaultChecked,
  round,
  ...rest
}: CheckboxProps) => {
  const checkedValue = indeterminate ? 'mixed' : defaultChecked || checked;
  return (
    <span
      className={classnames(className, `a-checkbox`, {
        'a-checkbox--disabled': disabled
      })}
    >
      <input
        className='a-checkbox__input'
        aria-label={label}
        type='checkbox'
        disabled={disabled}
        aria-checked={checkedValue}
        checked={checked}
        {...rest}
      />
      <span
        className={classnames('a-checkbox__display', {
          'a-checkbox__display--indeterminate': indeterminate,
          'a-checkbox__display--size-s': size === 's',
          'a-checkbox__display--round': round
        })}
      >
        <Icon
          size='inherit'
          className={classnames(`a-checkbox__icon`, {
            'a-checkbox__icon--size-s': size === 's'
          })}
          icon={indeterminate ? 'minus' : 'check'}
        />
      </span>
    </span>
  );
};

export default Checkbox;
