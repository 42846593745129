import React from 'react';
import {debounce} from 'lodash';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import {SortableDragHandle} from 'sg/DragHandle/DragHandle.react';
import {callAction} from 'client/framework';
import {addGenericErrorToast, addToast} from '@albert-io/atomic';

import Button from 'sg/Button/Button.react';
import SimpleModal from 'generic/SimpleModal/SimpleModal.react';
import TextInput from 'generic/Forms/TextInput/TextInput';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';

import queueResultsStore from '../QueueResults.store';
import queueResultsActions from '../QueueResults.actions';
import './edit-question-set-modal.scss';

export default class EditQuestionSetModal extends React.Component {
  handleClose = () => {
    callAction(queueResultsActions.SET_QUESTION_SET_UNDER_EDIT, null);
  };

  handleSave = () => {
    callAction(queueResultsActions.SAVE_QUESTION_SET);
    queueResultsStore
      .getEditQuestionSetPromise()
      .then(() => {
        addToast({
          message: 'Question set saved successfully.',
          title: 'Success',
          color: 'positive'
        });
      })
      .catch(() => {
        addGenericErrorToast();
      })
      .finally(this.handleClose);
  };

  render() {
    const questionSetUnderEdit = queueResultsStore.getQuestionSetUnderEdit();
    if (!questionSetUnderEdit) {
      return null;
    }
    return (
      <SimpleModal
        title='Edit question set'
        onModalClose={this.handleClose}
        className='edit-question-set-modal'
      >
        <EditName />
        <EditQuestionPositions />
        <div className='a-form-row a-form-row--align-right'>
          <Button color='red' text='Cancel' onClick={this.handleClose} />
          <Button color='green' text='Save changes' onClick={this.handleSave} />
        </div>
      </SimpleModal>
    );
  }
}

class EditName extends React.Component {
  handleChange = (e) => {
    this.updateQuestionSetName(e.target.value);
  };

  updateQuestionSetName = debounce((name) => {
    const updatedQuestionSet = queueResultsStore.getQuestionSetUnderEdit().setName(name);
    callAction(queueResultsActions.SET_QUESTION_SET_UNDER_EDIT, updatedQuestionSet);
  }, 100);

  render() {
    return (
      <div className='a-form-row'>
        <TextInput
          label='Edit Title'
          onChange={this.handleChange}
          value={queueResultsStore.getQuestionSetUnderEdit().getName()}
        />
      </div>
    );
  }
}

const SortableQuestion = SortableElement(({question}) => (
  <li className='edit-question-positions-item'>
    <MarkdownRendererV2 text={question.getTitle()} />
    <SortableDragHandle />
  </li>
));

const SortableQuestionsList = SortableContainer(({questions}) => (
  <ul className='edit-question-positions-list'>
    {questions.map((question, index) => (
      <SortableQuestion key={question.getId()} index={index} question={question} />
    ))}
  </ul>
));

class EditQuestionPositions extends React.Component {
  /**
   * Because the qusetion set's reorder_quetsions attribute is read only, we can't use it to
   * show the pending order before we commit the new positions. Therefore, we'll keep a List
   * of the questions in their pending order (starting with their initial order), and as they're
   * updated, we'll use this list to set the new positions on the question set resource.
   */
  state = {
    sortedQuestions: queueResultsStore.getQuestionSetUnderEdit().getQuestions()
  };

  handleSortEnd = ({oldIndex, newIndex}) => {
    if (oldIndex === newIndex) {
      return;
    }
    const prevQuestions = this.state.sortedQuestions;
    const itemToMove = prevQuestions.get(oldIndex);
    const sortedQuestions = prevQuestions.delete(oldIndex).insert(newIndex, itemToMove);
    this.setState(() => ({sortedQuestions}), this.updateQuestionSet);
  };

  updateQuestionSet = () => {
    const newPositions = this.state.sortedQuestions.map((question) => question.getId()).toJS();
    const updatedQuestionSet = queueResultsStore
      .getQuestionSetUnderEdit()
      .setReorderQuestions(newPositions);
    callAction(queueResultsActions.SET_QUESTION_SET_UNDER_EDIT, updatedQuestionSet);
  };

  render() {
    return (
      <div>
        <p className='a-form-input__label'>Edit question positions</p>
        <SortableQuestionsList
          useDragHandle
          questions={this.state.sortedQuestions}
          onSortEnd={this.handleSortEnd}
        />
      </div>
    );
  }
}
