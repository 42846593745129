import {Button} from '@albert-io/atomic';
import React from 'react';

import './chip-button.scss';
import classNames from 'classnames';
import {ButtonProps} from '@albert-io/atomic/atoms/Button/Button.react';

interface ChipButtonProps
  extends ButtonProps<'button'>,
    PropsWithChildrenRequired,
    PropsWithClassNameOptional {
  onClick: () => void;
  disabled?: boolean;
  buttonRef?: React.RefObject<HTMLButtonElement>;
}

export default function ChipButton({
  children,
  onClick,
  disabled,
  className,
  buttonRef,
  ...rest
}: ChipButtonProps) {
  return (
    <Button
      onClick={() => {
        if (!disabled) onClick();
      }}
      className={classNames('chip-button', className, {
        'u-cursor_pointer ': !disabled
      })}
      color={disabled ? 'secondary' : 'primary'}
      variant='faded'
      size='s'
      ref={buttonRef}
      {...rest}
    >
      {children}
    </Button>
  );
}
