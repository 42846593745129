import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';
import {title, meta} from 'lib/head';
import {isSearchMode} from 'components/AdvancedSearch/AdvancedSearch.utils';
import {getModelForResourceType} from 'resources/modelRegistry';

import {getCanonicalLink} from './subjectPracticeView.utils';

const SubjectPracticeViewHelmet = React.memo(
  ({route, guideLevel, subject, activeQuestion, activeQuestionSet}) => {
    const metadata = {
      title: [],
      description: null
    };
    let canonicalPath;
    if (route.isTopicSummary) {
      metadata.title = [guideLevel.getName(), subject.getName(), 'Practice'];
    } else {
      metadata.title = [activeQuestion.getTitle()];
      if (isSearchMode()) {
        metadata.title.push('Search Results');
      }
      metadata.title.push(subject.getName(), 'Practice');
      metadata.description = `Practice question "${activeQuestion.getTitle()}"${
        guideLevel ? ` in topic "${guideLevel.getName()}"` : ''
      }. Part of Albert's supplemental practice for ${subject.getName()}.`;

      if (activeQuestion && activeQuestionSet) {
        canonicalPath = getCanonicalLink({activeQuestion, activeQuestionSet});
      }
    }
    metadata.title.push('Albert');
    return (
      <Helmet>
        {title(metadata.title.join(' | '))}
        {meta.description(metadata.description)}
        {canonicalPath && <link rel='canonical' href={canonicalPath} />}
      </Helmet>
    );
  }
);

SubjectPracticeViewHelmet.propTypes = {
  route: PropTypes.object,
  guideLevel: PropTypes.instanceOf(getModelForResourceType('guide_level_v2')),
  subject: PropTypes.instanceOf(getModelForResourceType('subject_v2')),
  activeQuestion: PropTypes.instanceOf(getModelForResourceType('question_v3')),
  activeQuestionSet: PropTypes.instanceOf(getModelForResourceType('question_set_v1'))
};

export default SubjectPracticeViewHelmet;
