import {resource} from '@albert-io/json-api-framework/request/builder';
import {callAction} from 'client/framework';
import sessionStore from 'client/Session/SessionStore';

import {showModal} from 'client/Modals/ModalActions';
import IssuesModalActions from 'client/Modals/IssuesModal/IssuesModalActions';
import issuesModalStore from 'client/Modals/IssuesModal/IssuesModalStore';
import {issuesModalName} from 'client/Modals/IssuesModal/IssuesModal.react';

export function makePreviousAttemptsQuery(questionId) {
  return resource('guesses_v1')
    .mandarkEndpoint(['questions_v3', questionId, 'guesses_v1'])
    .pageSize(10)
    .sort('-attempt_number')
    .filter({
      student_v2: sessionStore.getUserId()
    });
}

export function hasPreviousAttempts(question) {
  if (!sessionStore.hasValidSession()) {
    return false;
  }
  return !makePreviousAttemptsQuery(question.getId()).getResource().isEmpty();
}

export function hasEditPermissions(question) {
  return question.getMeta().isAuthorCanEdit() || sessionStore.isSuper();
}

export function openQuestionFeedbackModal(question) {
  issuesModalStore.clearIssue();

  callAction(IssuesModalActions.MODIFY_ISSUE, {questionId: question.getId()});
  showModal(issuesModalName);
}

export function canRevealSolution({question, questionSet, subject}) {
  if (question.getMeta().isBlockedAssignmentQuestion()) {
    return false;
  }
  if (hasPreviousAttempts(question)) {
    return true;
  }
  return sessionStore.hasTeacherAccess() && canSaveQuestionSetToFolder(subject, questionSet);
}

export function hasMoreQuestionInfo() {
  return sessionStore.hasTeacherAccess();
}

export function canAddAllToFolder(subject, guideLevel) {
  /**
   * @todo: Remove the isTeacher check when all users can create folders
   */
  return (guideLevel?.isFree() || subject.getMeta().isUserHasAccess()) && sessionStore.isTeacher();
}

export function canSaveQuestionSetToFolder(subject, questionSet) {
  /**
   * @todo: Remove the hasTeacherAccess check when all users can create folders
   */
  return (
    questionSet &&
    sessionStore.hasTeacherAccess() &&
    (subject.getMeta().isUserHasAccess() ||
      questionSet.getMeta().isFree() ||
      sessionStore.isSuper())
  );
}

export const showPublicViewForSubject = (subject: any) => {
  // if the current user is a super user or has access to the current subject
  // then show the original view. otherwise show a view for non licensed users
  if (
    sessionStore.isSuper() ||
    subject.getMeta().isUserHasAccess()
  ) {
    return false;
  }
  return true;
}
