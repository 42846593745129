import React, {useState, useRef, useCallback, useEffect} from 'react';
import {Button, Text, Icon} from '@albert-io/atomic';
import {ImageUploadButton} from './UploadButtons/ImageUploadButton.react';
import {AudioUploadButton} from './UploadButtons/AudioUploadButton.react';
import useOnClickOutside from 'lib/hooks/useOnClickOutside';
import classnames from 'classnames';
import FeatureFlag from 'client/components/FeatureFlag/FeatureFlag.react';

interface AddMediaButtonProps {
  tabIndex?: number;
}

export const AddMediaButton = ({tabIndex}: AddMediaButtonProps) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [activeCursor, setActiveCursor] = useState<number>(0);

  useOnClickOutside(buttonRef, () => {
    if (showDropdown) {
      setShowDropdown(false);
    }
  });

  const keyHandlers = {
    ArrowDown: () => setActiveCursor((current) => (current === 0 ? 1 : 0)),
    ArrowUp: () => setActiveCursor((current) => (current === 0 ? 1 : 0)),
    ArrowRight: () => setShowDropdown(false),
    ArrowLeft: () => setShowDropdown(false),
    Escape: () => setShowDropdown(false),
    Enter: () => {
      const selected = dropdownRef.current!.children[activeCursor] as HTMLElement;
      if (selected) {
        selected.click();
      }
    }
  };

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (showDropdown && dropdownRef.current) {
        event.preventDefault();
        const handler = keyHandlers[event.key];
        handler?.();
      }
    },
    [activeCursor]
  );

  useEffect(() => {
    if (showDropdown && dropdownRef.current) {
      const selected = dropdownRef.current.children[activeCursor] as HTMLElement;
      if (selected) {
        selected.focus();
      }
    }
  }, [activeCursor, showDropdown]);

  useEffect(() => {
    global.document.addEventListener('keydown', handleKeyPress);
    return () => {
      global.document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <div className='written-submission__add-media__container' ref={buttonRef}>
      <Button
        className='u-gap_space-x1'
        color='secondary'
        variant='outlined'
        onClick={() => {
          setShowDropdown(!showDropdown);
        }}
        tabIndex={tabIndex}
      >
        <Text>Add Media</Text>
        <Icon icon='caret-down' />
      </Button>

      <MediaDropdown
        setShowDropdown={setShowDropdown}
        showDropdown={showDropdown}
        dropdownRef={dropdownRef}
        activeCursor={activeCursor}
      />
    </div>
  );
};

interface MediaDropdownProps {
  setShowDropdown: (boolean) => void;
  showDropdown: boolean;
  dropdownRef: React.RefObject<HTMLDivElement>;
  activeCursor: number;
}

const MediaDropdown = ({
  setShowDropdown,
  showDropdown,
  dropdownRef,
  activeCursor
}: MediaDropdownProps) => {
  const className = classnames(
    'written-submission__media-dropdown__container',
    showDropdown ? 'u-display_flex' : 'u-display_none'
  );

  return (
    <div className={className} ref={dropdownRef}>
      <ImageUploadButton setShowDropdown={setShowDropdown} tabIndex={activeCursor === 0 ? 0 : -1} />
      <FeatureFlag name='frq_submission_audio_support'>
        <AudioUploadButton
          setShowDropdown={setShowDropdown}
          tabIndex={activeCursor === 0 ? 0 : -1}
        />
      </FeatureFlag>
    </div>
  );
};
