// @flow
import moment from 'moment';
import {List, Map, Record, fromJS} from 'immutable';
import {GenericModel} from 'resources/Generic.model';
import * as TimeUtil from 'lib/timeUtils';
import {StudentModel} from 'resources/Student/Student.model';
import type {QuestionSetModelV1} from 'resources/GeneratedModels/QuestionSet/QuestionSetModel.v1';
import type {QuestionType} from 'resources/Question/Question.model';

const AssignmentRelationshipConfig = fromJS({
  /* eslint-disable camelcase */
  endpoint: 'assignments_v2',
  relationships: {
    classroom_v1: {
      required: false,
      type: 'one-to-many'
    },
    guess_v1: {
      required: false,
      type: 'one-to-many'
    },
    question_set_v1: {
      required: false,
      type: 'one-to-many'
    },
    student_v1: {
      required: false,
      type: 'one-to-many'
    },
    student_v2: {
      required: false,
      type: 'one-to-many'
    },
    teacher_v1: {
      required: true,
      type: 'one-to-one'
    }
  },
  /*
    This represents the API that will be generated by the generic model.
    Simple getters and setters will be added using this map.

    Omission of a set attribute will cause the method for that attribute to be not added.
    get attribute is required.
  */
  fieldToMethodMap: {
    allow_late_submissions: true,
    correct_answer_setting: true,
    id: {
      get: true
    },
    message: true,
    name: true,
    status: true,
    template: {
      get: 'isTemplate',
      set: 'setIsTemplate'
    }
  }
  /* eslint-enable camelcase */
});

const AssignmentRecord = Record({
  /* eslint-disable camelcase */
  allow_late_submissions: true,
  auto_assign_new_classroom_enrollees: true,
  classrooms: new List(),
  correct_answer_setting: '',
  due_date: null,
  id: '',
  inserted_at: null,
  message: '',
  meta: new Map({
    average_accuracy: null,
    average_grade: null,
    classroom_names: null,
    count_of_classrooms: null,
    count_of_completed_students: null,
    count_of_correct_guesses: null,
    count_of_guesses: null,
    count_of_question_sets: null,
    count_of_questions: null,
    count_of_students: null,
    question_sets_v1: new Map({
      count: 0,
      questions_v1: new Map({
        count: 0
      })
    }),
    student_count_of_correct_guesses: null,
    student_count_of_guesses: null,
    student_late: null,
    student_submitted: null,
    student_time_spent: null
  }),
  name: '',
  question_sets: new List(),
  relationshipMeta: new Map({
    student: new Map({
      deleted: null,
      start_time: null,
      submitted: null
    })
  }),
  start_date: null,
  status: 'assigned', // this key value is always "assigned", not calculated on backend
  students: new List(),
  template: false,
  time_limit: null,
  updated_at: null
  /* eslint-enable camelcase */
});

const BaseAssignmentModel = GenericModel(AssignmentRecord, AssignmentRelationshipConfig);

export class AssignmentModelV2 extends BaseAssignmentModel {
  getTimeLimit(): number | string {
    return this.get('time_limit', '');
  }

  setTimeLimit(value: number | null): AssignmentModelV2 {
    return this.setField('time_limit', value);
  }

  getStartDate(): moment | null {
    const startDate = this.get('start_date');

    return startDate === null ? null : moment(startDate);
  }

  setStartDate(value: string): AssignmentModelV2 {
    const startDate = moment(value);
    const startTime = this.getStartTime() || '00:00:00';

    const startDateToUnixTimestamp = startDate.isValid()
      ? TimeUtil.convertDateAndTimeToUnixTimestamp({
          date: startDate.format('YYYY-MM-DD'),
          time: startTime
        })
      : null;

    return this.setField('start_date', startDateToUnixTimestamp);
  }

  getStartTime(): moment | null {
    const startDate = this.getStartDate();

    return startDate
      ? moment(startDate)
          .startOf('minute')
          .format('HH:mm:ss')
      : null;
  }

  setStartTime(value: string): AssignmentModelV2 {
    /*
      <input name='start_time' /> is disabled if start_date === null
      so the following assignment operation is safe.
    */
    const startTime = value ? value : '00:00:00';
    const startDate = this.getStartDate().format('YYYY-MM-DD');
    const updatedStartDate = TimeUtil.convertDateAndTimeToUnixTimestamp({
      date: startDate,
      time: startTime
    });

    return this.setField('start_date', updatedStartDate);
  }

  getDueDate(): moment | null {
    const dueDate = this.get('due_date');

    return dueDate === null ? null : moment(dueDate);
  }

  getInsertedAt(): moment | null {
    const insertedAtDate = this.get('inserted_at');

    return insertedAtDate === null ? null : moment(insertedAtDate);
  }

  setDueDate(value: moment | null): AssignmentModelV2 {
    const dueDate = moment(value);
    const dueTime = this.getDueTime() || '00:00:00';

    const dueDateToUnixTimestamp = dueDate.isValid()
      ? TimeUtil.convertDateAndTimeToUnixTimestamp({
          date: dueDate.format('YYYY-MM-DD'),
          time: dueTime
        })
      : null;

    return this.setField('due_date', dueDateToUnixTimestamp);
  }

  getDueTime(): moment | null {
    const dueDate = this.getDueDate();

    return dueDate ? moment(dueDate).format('HH:mm:ss') : null;
  }

  getParsedDueDate(): string {
    const dueDate = this.getDueDate();

    return dueDate ? moment(dueDate).format('YYYY-MM-DD') : '';
  }

  getParsedStartDate(): string {
    const startDate = this.getStartDate();

    return startDate ? moment(startDate).format('YYYY-MM-DD') : '';
  }

  getUpdatedAtDate(): moment | null {
    const updatedAtDate = this.get('updated_at');
    return updatedAtDate === null ? null : moment(updatedAtDate);
  }

  getQuestionSets(): List<QuestionSetModelV1> {
    return this.get('question_sets');
  }

  getSortedQuestionSets(): List<QuestionSetModelV1> {
    const sortedQuestionSetsFunc = () => {
      return this.getQuestionSets().sortBy((questionSet) => {
        return questionSet.getIn(['relationshipMeta', 'assignment', this.getId(), 'position']);
      });
    };
    return this.cache('getSortedQuestionSets', sortedQuestionSetsFunc);
  }

  getSortedQuestions(): List<QuestionType> {
    const sortedQuestionsFunc = () => {
      return this.getSortedQuestionSets().reduce((acc, questionSet) => {
        return acc.concat(questionSet.getQuestions());
      }, List());
    };
    return this.cache('getSortedQuestions', sortedQuestionsFunc);
  }

  getQuestionSetForActiveQuestion(questionId: string): QuestionSetModelV1 {
    return this.getQuestionSets().find((set) => {
      const questions = set.getQuestions();
      const matchingSet = questions.find((question) => {
        return question.getId() === questionId;
      });
      return matchingSet;
    });
  }

  getStudents(): List<StudentModel> {
    return this.get('students');
  }

  getStudentIds(): List<string> {
    return this.getStudents().reduce((prev, curr) => {
      prev = prev.push(curr.getId());
      return prev;
    }, new List());
  }

  getAllowLateSubmissions(): boolean {
    return this.get('allow_late_submissions');
  }

  setAllowLateSubmissions(value: boolean): AssignmentModelV2 {
    return this.setField('allow_late_submissions', value);
  }

  isAssigned(): boolean {
    return this.getStatus() === 'assigned';
  }

  isDraft(): boolean {
    return this.getStatus() === 'draft';
  }

  isPastDue(): boolean {
    return this.getDueDate() !== null && moment().isSameOrAfter(this.getDueDate());
  }

  isOpen(): boolean {
    return (
      this.isAssigned() &&
      !this.isScheduled() &&
      (this.getAllowLateSubmissions() === true || this.isPastDue() === false)
    );
  }

  isScheduled(): boolean {
    return (
      this.isAssigned() && this.getStartDate() !== null && moment().isBefore(this.getStartDate())
    );
  }

  isClosed(): boolean {
    /**
     * Full expression for calculating a current state of an assignment
     * should be the one described below:
     *
     * return this.getStatus() === 'closed' ||
     *   this.isAssigned() && this.isPastDue() === true && this.getAllowLateSubmissions() === false;
     *
     * this.getStatus() === 'closed' - is currently calculated outside of this model,
     * based on totalStudentsInAssignment === completedAssignmentStudents conditional
     * from assignment stats in TeacherAssignmentStats.interface.js
     * as status of any assignment (that is not template) is always equal to "assigned" currently
     */
    return (
      this.isAssigned() && this.isPastDue() === true && this.getAllowLateSubmissions() === false
    );
  }

  getAutoAssignNewClassroomEnrollees(): boolean {
    return this.get('auto_assign_new_classroom_enrollees');
  }
  /*
    Meta accessors
  */
  _getMeta(): Map<string, *> {
    return this.get('meta');
  }

  getQuestionCount(): number | void {
    return this._getMeta().get('count_of_questions', undefined);
  }
  getAverageAccuracy(): number | void {
    return this._getMeta().get('average_accuracy', undefined);
  }

  getAverageGrade(): number | void {
    return this._getMeta().get('average_grade');
  }

  getClassrooms(): List<string> {
    return this.get('classrooms');
  }

  getClassroomNames(): string {
    return this._getMeta().get('classroom_names', '');
  }

  getClassroomCount(): number | void {
    return this._getMeta().get('count_of_classrooms', undefined);
  }

  getCountOfCompletedStudents(): number | void {
    return this._getMeta().get('count_of_completed_students', undefined);
  }

  getCountOfCorrectGuesses(): number | void {
    return this._getMeta().get('count_of_correct_guesses', undefined);
  }

  getCountOfGuesses(): number | void {
    return this._getMeta().get('count_of_guesses', undefined);
  }

  getCountOfQuestionSets(): number | void {
    return this._getMeta().get('count_of_question_sets', undefined);
  }

  getCountOfQuestions(): number | void {
    return this._getMeta().get('count_of_questions', undefined);
  }

  getCountOfStudents(): number | void {
    return this._getMeta().get('count_of_students', undefined);
  }

  hasStudentSubmitted(): boolean {
    return this._getMeta().get('student_submitted');
  }

  isStudentLate(): boolean {
    return this._getMeta().get('student_late');
  }

  getStudentCorrectGuessCount(): number {
    return this._getMeta().get('student_count_of_correct_guesses');
  }

  getStudentTotalGuessCount(): number {
    return this._getMeta().get('student_count_of_guesses');
  }

  getStudentTimeSpentRaw(): number {
    return this._getMeta().get('student_time_spent');
  }

  getStudentReadableTimeSpent(): string | void {
    const timeSpent = parseInt(this.getStudentTimeSpentRaw(), 10);
    if (isNaN(timeSpent)) {
      return undefined;
    }
    const timeSpentDuration = moment.duration(timeSpent);
    return TimeUtil.humanReadableDurationString(timeSpentDuration);
  }

  _getRelationshipMeta(): Map<string, *> {
    return this.get('relationshipMeta');
  }

  getStudentRelationshipMeta(): Map<string, *> {
    return this._getRelationshipMeta().get('student');
  }

  getFinishedStudents(): List<StudentModel> {
    const assignmentId = this.getId();
    return this.getStudents().filter((student) => {
      let submitted = null;
      if (typeof student.getAssignmentRelationships === 'function') {
        submitted = student.getAssignmentRelationships(assignmentId).getSubmitted();
      } else {
        /**
         * It's a legacy, non-generated model.
         */
        submitted = student.getAssignmentRelationshipMeta(assignmentId).get('submitted');
      }
      return submitted !== null;
    });
  }
}
