import React, {useCallback} from 'react';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import classnames from 'classnames';
import {v4 as uuid} from 'uuid';
import SupplementManagerModalButton from 'client/Supplements/SupplementManager/SupplementManagerModalButton.react';
import Button from 'sg/Button/Button.react';
import {AuthoringQuestionModelV1} from 'resources/augmented/AuthoringQuestion/AuthoringQuestionModel.v1';
import {fromJS, Map} from 'immutable';
import TextInput from 'generic/Forms/TextInput/TextInput';
import {useQuestionEditorV2Store} from 'client/EditPage/V2/QuestionEditorV2Store';
import {PRIMARY_CONTENT_LANGUAGE} from 'client/EditPage/V2/QuestionEditorV2Store.types';
import {useShallow} from 'zustand/react/shallow';

interface DropdownEditAreaProps {
  question: any;
  onModelChange: (question: any, ...fields: any[]) => void;
  validationErrors: Map<string, any>;
}

export const DropdownEditArea = SortableContainer(
  ({question, validationErrors, onModelChange}: DropdownEditAreaProps) => {
    const isPrimaryLanguage = useQuestionEditorV2Store(
      (state) => state.currentLanguage === PRIMARY_CONTENT_LANGUAGE
    );
    return (
      <div className='fill-in-the-blank-dropdown-editor'>
        {question.getDropdowns().map((dropdown, index) => (
          <SortableDropdown
            // index is for react-sortable-hoc, dropdownIndex is for the dropdown itself
            index={index}
            dropdownIndex={index}
            key={dropdown.get('id')}
            // disable sorting if not in primary language
            disabled={!isPrimaryLanguage}
            question={question}
            dropdown={dropdown}
            onModelChange={onModelChange}
            validationErrors={validationErrors}
          />
        ))}
      </div>
    );
  }
);

const SortHandle = SortableHandle(() => {
  return (
    <div className='fill-in-the-blank-editor__sort-handle-wrapper'>
      <span className='fa fa-ellipsis-v' />
      <span className='fa fa-ellipsis-v' />
    </div>
  );
});

interface DropdownProps {
  question: AuthoringQuestionModelV1;
  dropdown: any;
  onModelChange: (question: AuthoringQuestionModelV1, ...fields: any[]) => void;
  validationErrors: any;
  dropdownIndex: number;
}

const SortableDropdown = SortableElement(
  ({question, dropdown, onModelChange, validationErrors, dropdownIndex}: DropdownProps) => {
    const isPrimaryLanguage = useQuestionEditorV2Store(
      (state) => state.currentLanguage === PRIMARY_CONTENT_LANGUAGE
    );

    const deleteDropdown = useCallback(() => {
      const dropdowns = question
        .getDropdowns()
        .filterNot((d) => d.get('id') === dropdown.getId())
        .map((d, i) => d.set('position', i));
      const validResponse = question.getValidResponse().remove(dropdown.getId());

      const updatedQuestion = question.setDropdowns(dropdowns).setValidResponse(validResponse);

      onModelChange(updatedQuestion, 'dropdowns');
    }, [question, dropdown, onModelChange]);

    const addChoice = useCallback(() => {
      const choices = question
        .getDropdowns()
        .getIn([dropdown.getPosition(), 'choices'])
        .push(
          fromJS({
            id: uuid(),
            value: ''
          })
        );
      const updatedDropdowns = question
        .getDropdowns()
        .setIn([dropdown.getPosition(), 'choices'], choices);
      const updatedQuestion = question.setDropdowns(updatedDropdowns);

      onModelChange(updatedQuestion, 'dropdowns');
    }, [question, dropdown, onModelChange]);

    const dropdownError = validationErrors.getIn(['rubric', dropdown.get('id')]);

    return (
      <div className='fill-in-the-blank-editor__dropdown'>
        <h3 className='fill-in-the-blank-editor__dropdown-number'>
          Dropdown {dropdown.getPosition() + 1}
          {isPrimaryLanguage && (
            <Button onClick={deleteDropdown} color='red' outline noBorder className='fa fa-trash' />
          )}
        </h3>
        {isPrimaryLanguage && dropdownError ? (
          <div className='a-form-input__error'>{dropdownError}</div>
        ) : null}
        <div className='fill-in-the-blank-editor__choices-wrapper'>
          <div className='fill-in-the-blank-editor__choices'>
            {dropdown.getChoices().map((choice, choiceIndex) => (
              <DropdownChoice
                key={choice.get('id')}
                question={question}
                choice={choice}
                dropdown={dropdown}
                choiceIndex={choiceIndex}
                dropdownIndex={dropdownIndex}
                onModelChange={onModelChange}
              />
            ))}
          </div>
          {isPrimaryLanguage && <SortHandle />}
        </div>
        {isPrimaryLanguage && <Button onClick={addChoice} text='Add choice' />}
        <hr />
      </div>
    );
  }
);

interface DropdownChoiceProps {
  question: AuthoringQuestionModelV1;
  dropdown: any;
  choice: any;
  choiceIndex: number;
  onModelChange: (question: AuthoringQuestionModelV1, ...fields: any[]) => void;
  dropdownIndex: number;
}

const DropdownChoice = ({
  question,
  dropdown,
  choice,
  choiceIndex,
  onModelChange,
  dropdownIndex
}: DropdownChoiceProps) => {
  const {currentLanguage, translatedChoiceText, updateTranslatedQuestionField} =
    useQuestionEditorV2Store(
      useShallow((state) => ({
        currentLanguage: state.currentLanguage,
        updateTranslatedQuestionField: state.updateTranslatedQuestionField,
        translatedChoiceText:
          state
            .currentTranslatedQuestion()
            ?.translated_fields?.find(
              (field) => field.field === `dropdowns.${dropdownIndex}.choices.${choiceIndex}.value`
            )?.text || ''
      }))
    );

  const onChange = useCallback(
    (e) => {
      if (currentLanguage === PRIMARY_CONTENT_LANGUAGE) {
        const updatedChoice = Map({
          id: choice.getId(),
          value: e.target.value
        });

        const updatedDropdowns = question
          .getDropdowns()
          .setIn([dropdown.getPosition(), 'choices', choiceIndex], updatedChoice);

        const updatedQuestion = question.setDropdowns(updatedDropdowns);
        onModelChange(updatedQuestion);
      } else {
        updateTranslatedQuestionField(
          currentLanguage,
          `dropdowns.${dropdownIndex}.choices.${choiceIndex}.value`,
          'text',
          e.target.value
        );
      }
    },
    [
      question,
      dropdown,
      choice,
      choiceIndex,
      onModelChange,
      currentLanguage,
      updateTranslatedQuestionField,
      dropdownIndex
    ]
  );

  const selectChoice = useCallback(() => {
    const validResponse = question.getValidResponse().set(dropdown.getId(), choice.getId());
    const updatedQuestion = question.setValidResponse(validResponse);
    onModelChange(updatedQuestion, 'rubric');
  }, [question, dropdown, choice, onModelChange]);

  const deleteChoice = useCallback(() => {
    const choiceId = question
      .getDropdowns()
      .getIn([dropdown.getPosition(), 'choices', choiceIndex, 'id']);
    const updatedDropdowns = question
      .getDropdowns()
      .removeIn([dropdown.get('position'), 'choices', choiceIndex]);
    let updatedQuestion = question.setDropdowns(updatedDropdowns);

    if (choiceId === updatedQuestion.getValidResponse().get(dropdown.getId())) {
      const validResponse = updatedQuestion.getValidResponse().remove(dropdown.getId());
      updatedQuestion = updatedQuestion.setValidResponse(validResponse);
    }

    onModelChange(updatedQuestion, 'rubric');
  }, [question, dropdown, choiceIndex, onModelChange]);

  const fieldPath = `dropdowns,${dropdownIndex},choices,${choiceIndex},value`;
  const dropdownId = dropdown.getId();
  const choiceId = choice.getId();
  const isCorrectChoice = question.getValidResponse().get(dropdownId) === choiceId;
  const inputClasses = classnames('fill-in-the-blank-editor__dropdown-choice-input', {
    'is-correct-choice': isCorrectChoice
  });

  const value =
    currentLanguage === PRIMARY_CONTENT_LANGUAGE ? choice.getValue() : translatedChoiceText;

  return (
    <div>
      <div className='fill-in-the-blank-editor-dropdown-choice u-pad-t_2'>
        <input
          type='checkbox'
          checked={isCorrectChoice}
          onChange={selectChoice}
          disabled={currentLanguage !== PRIMARY_CONTENT_LANGUAGE}
        />
        <TextInput
          className={inputClasses}
          name='value'
          onChange={onChange}
          placeholder='Type answer here.'
          value={value}
        />
        {currentLanguage === PRIMARY_CONTENT_LANGUAGE && (
          <Button className='fa fa-times' onClick={deleteChoice} color='red' outline noBorder />
        )}
      </div>
      {currentLanguage === PRIMARY_CONTENT_LANGUAGE && (
        <div className='fill-in-the-blank-editor__supplement-manager'>
          <SupplementManagerModalButton linkButton pathToProperty={fieldPath} color='blue' />
        </div>
      )}
    </div>
  );
};
