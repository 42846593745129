/* eslint-disable no-param-reassign */
import React from 'react';
import notifier from '@albert-io/notifier';
import {Button, NumberInput, Heading, Grid} from '@albert-io/atomic';

import {completeCoppaAgeScreener, completeParentalConsent} from 'lib/OnboardingUtil/OnboardingUtil';

import {onboardingPropTypes} from '../../ForceOnboarding.react';
import './coppa-age-screener.scss';

export default class CoppaAgeScreener extends React.Component {
  static propTypes = onboardingPropTypes;

  constructor() {
    super();

    this.state = {
      value: ''
    };
  }

  async saveUser(user) {
    try {
      user = completeCoppaAgeScreener(user);

      if (!user.isNeedsCoppa()) {
        user = completeParentalConsent(user);
      }

      await user.save();
      this.props.onComplete();
    } catch (e) {
      notifier.notify(new Error('Onboarding: unable to save coppa age screener for user'), {
        component: 'CoppaAgeScreener',
        user
      });
    }
  }

  onSubmit = () => {
    const needsCoppa = this.state.value < 13;
    const user = this.props.user.setNeedsCoppa(needsCoppa);
    this.saveUser(user);
  };

  render() {
    const {value} = this.state;
    return (
      <Grid.Container>
        <Grid.Row justify='center'>
          <Grid.Col className='age-screener' all={6} xs={12}>
            <Heading as='h2'>Welcome!</Heading>
            <p>In order to use Albert, we need to know how old you are.</p>
            <Grid.Container nested>
              <Grid.Row justify='center'>
                <Grid.Col all={6} xs={12} className='age-screener__input-wrapper'>
                  <NumberInput
                    aria-label='Age'
                    border
                    className='age-screener__input'
                    min={0}
                    placeholder='Enter your age'
                    onChange={(e) => this.setState({value: e.target.value})}
                    value={value}
                  />
                  <Button
                    className='age-screener__button'
                    disabled={!value}
                    onClick={this.onSubmit}
                    type='button'
                  >
                    Submit
                  </Button>
                </Grid.Col>
              </Grid.Row>
            </Grid.Container>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    );
  }
}
