import {useEffect, useState} from 'react';

type Direction = 'up' | 'down';

/**
 * Hook to determine the scroll direction of the page
 *
 * @param {number | undefined} "upperThreshold" - The threshold at which the scroll direction changes to up
 * @returns {Direction} The direction of the scroll
 */
export function useScrollDirection(upperThreshold: number | undefined): Direction {
  const [scrollDirection, setScrollDirection] = useState<Direction>('up');

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? 'down' : 'up';
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 1 ||
          (scrollY - lastScrollY < -1 && (!upperThreshold || scrollY < upperThreshold)))
      ) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener('scroll', updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener('scroll', updateScrollDirection); // clean up
    };
  }, [scrollDirection]);

  return scrollDirection;
}
