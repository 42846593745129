import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {Anchor, Button, Heading, Text} from '@albert-io/atomic';

const EmptyModalContents = ({folders, showNewFoldersModal}) => {
  if (!folders.isEmpty()) {
    return (
      <div className='folders-modal-empty-state'>
        <Text color='secondary' size='l'>
          No Results
        </Text>
      </div>
    );
  }
  return (
    <div className='folders-modal-empty-state'>
      <Heading className='u-mar-b_2'>You don&apos;t have any folders yet</Heading>
      <Text color='secondary' size='l'>
        You can use folders to save custom collections of questions.
      </Text>
      <Text color='secondary' size='l'>
        <Anchor href='http://help.albert.io/en/articles/3002392-what-are-folders'>
          Learn more
        </Anchor>
        .
      </Text>
      <Button className='u-mar-t_5' onClick={showNewFoldersModal}>
        Create new folder
      </Button>
    </div>
  );
};

EmptyModalContents.propTypes = {
  folders: ImmutablePropTypes.list,
  showNewFoldersModal: PropTypes.func
};

export default EmptyModalContents;
