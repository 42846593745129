import {List} from 'immutable';

function simpleValidator(attr, error) {
  const attribute = attr || '';
  if (attribute.length === 0) {
    return List([error]);
  }

  return null;
}

export const schoolModelValidators = {
  getName() {
    return simpleValidator(this.getName(), 'You must enter a school name.');
  },

  getSchoolType() {
    return simpleValidator(this.getSchoolType(), 'You must select a school type.');
  },

  getAddressLine1() {
    return simpleValidator(this.getAddressLine1(), 'You must enter an address.');
  },

  getCountry() {
    return simpleValidator(this.getCountry(), 'You must select a country.');
  },

  getState() {
    return simpleValidator(this.getState(), 'You must select a state.');
  },

  getPostalCode() {
    return simpleValidator(this.getPostalCode(), 'You must enter a postal code.');
  },

  getCity() {
    return simpleValidator(this.getCity(), 'You must enter a city.');
  }
};
