import React from 'react';
import SimpleMarkdown from 'simple-markdown';
import {CollapsibleArea} from './CollapsibleArea.react';

const collapsibleRegex = /^\[collapsible(!?):([^\n]+?)\]([\s\S]+?)\[\/collapsible\]/;

export const collapsibleAreaRule = {
  order: SimpleMarkdown.defaultRules.paragraph.order - 0.5,
  match: (source) => collapsibleRegex.exec(source),
  parse: (capture, parse) => {
    /**
     * We have to fuss with newlines so that the block parser works correctly.
     * The block parser needs two newlines around content to detect block elements,
     * so we add the necessary number of newlines to both the summary and the content.
     */
    const summaryText = `\n\n${capture[2].trim()}\n\n`;
    const contentText = `\n${capture[3]}\n`;
    return {
      isDefaultOpen: !!capture[1],
      summary: parse(summaryText, {inline: false}),
      content: parse(contentText, {inline: false})
    };
  },
  react: function CollapsibleAreaRule(node, output) {
    return (
      <CollapsibleArea
        isDefaultOpen={node.isDefaultOpen}
        summary={output(node.summary)}
        content={output(node.content)}
        key={node.tokenId}
      />
    );
  }
};
