import React from 'react';
import PropTypes from 'prop-types';
import {Map, List} from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {getStoreByName} from 'client/framework';
import SimpleModal from 'generic/SimpleModal/SimpleModal.react';
import Button from 'sg/Button/Button.react';
import awaitMandarkQueries from 'lib/hocs/awaitMandarkQueries';
import {query} from '@albert-io/json-api-framework/request/builder';
import SimpleDropdown from 'generic/SimpleDropdown/SimpleDropdown.react';

import './reassign-question-set-modal.scss';

function getSubjectPermissionsWithAuthorsQuery(subjectId) {
  return query()
    .mandarkEndpoint(['authoring_subjects_v1', subjectId, 'subject_author_permissions_v1'])
    .include('author_v1')
    .pageSize('infinite')
    .filter({
      author_v1: {
        id: {
          null: false
        }
      }
    });
}

const ModalContents = awaitMandarkQueries(
  ({subjectId}) => ({
    queries: {
      subjectPermissionsWithAuthors: getSubjectPermissionsWithAuthorsQuery(subjectId)
    }
  }),
  class ModalContents extends React.Component {
    static propTypes = {
      handleClose: PropTypes.any,
      handleSubmit: PropTypes.any,
      subjectPermissionsWithAuthors: ImmutablePropTypes.list.isRequired,
      subjectId: PropTypes.string,
      currentAuthorId: PropTypes.string
    };

    handleSubmitAndClose = () => {
      const store = getStoreByName(this.dropdownStoreName);
      this.props.handleClose();
      this.props.handleSubmit(store.getSelectedItem().get('id'));
    };

    /**
     * `authors` is the reduction of subject authors not found
     * in the permissions query.
     */
    authors = this.props.subjectPermissionsWithAuthors.reduce((options, permission) => {
      const author = permission.getAuthor();
      return options.push(
        Map({
          name: author.getDisplayName(),
          id: author.getId(),
          displayName: (
            <div>
              {author.getDisplayName()}
              <br />
              <small>{author.getEmail()}</small>
            </div>
          )
        })
      );
    }, List());

    dropdownStoreName = `ReassignQuestionSetAuthors/${this.props.subjectId}`;

    render() {
      return (
        <div>
          <SimpleDropdown
            fullWidth
            label='Select an author'
            options={this.authors}
            storeName={this.dropdownStoreName}
            defaultItemId={this.props.currentAuthorId}
          />
          <div className='reassign-question-set-modal__actions a-form-row a-form-row--align-right'>
            <Button text='Cancel' color='gray' onClick={this.props.handleClose} />
            <Button text='Reassign' color='green' onClick={this.handleSubmitAndClose} />
          </div>
        </div>
      );
    }
  }
);

export default class ReassignQuestionSetModal extends React.Component {
  static propTypes = {
    handleClose: PropTypes.any
  };

  render() {
    return (
      <SimpleModal
        title='Reassign question set'
        className='reassign-question-set-modal'
        onModalClose={this.props.handleClose}
      >
        <ModalContents {...this.props} />
      </SimpleModal>
    );
  }
}
