import React from 'react';
import SimpleMarkdown from 'simple-markdown';

export const escapedCharacterRule = {
  order: SimpleMarkdown.defaultRules.em.order - 0.5,
  match: (source) => /^(&#?(?:[\w\d])+;)/.exec(source),
  parse: (capture) => {
    return {
      content: capture[1]
    };
  },
  react(node) {
    // eslint-disable-next-line react/no-danger
    return <span key={node.tokenId} dangerouslySetInnerHTML={{__html: node.content}} />;
  }
};
