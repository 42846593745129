import React from 'react';
import PropTypes from 'prop-types';
import {v4 as uuid} from 'uuid';
import {EventEmitter} from 'eventemitter3';

import Toast from '../../molecules/Toast/Toast.react';
import './global-toast-provider.scss';

const toastEmitter = new EventEmitter();
const toastEvent = Symbol('toast');
const removeToastEvent = Symbol('toast-remove');

/**
 * Allows you to call a toast without using the context provider.
 *
 * @param {Object} toast
 * @param {string} toast.color One of ['info', 'positive', 'negative', 'warning']
 * @param {string} toast.title Title of the toast. If not provided, toast must have ariaLabel
 * @param {string} toast.message Body of the toast
 * @param {string} toast.ariaLabel Label for toast. Required if title is not provided.
 * @param {bool} toast.permanent Determines if toast will be non-timed
 *
 * @returns {function} A function that removes the toast that was created
 */

export function addToast(toast) {
  const toastId = toast.toastId || uuid();
  toastEmitter.emit(toastEvent, {...toast, toastId});

  return () => toastEmitter.emit(removeToastEvent, toastId);
}

export function removeToast(toastId) {
  toastEmitter.emit(removeToastEvent, toastId);
}

export function addGenericErrorToast() {
  toastEmitter.emit(toastEvent, {
    message: 'An error was encountered. Please try again. If the problem persists, contact us.',
    title: 'Something went wrong',
    color: 'negative'
  });
}

const GlobalToastContext = React.createContext({addToast, addGenericErrorToast});

export const GlobalToastConsumer = GlobalToastContext.Consumer;

export class GlobalToastProvider extends React.Component {
  static propTypes = {
    children: PropTypes.node
  };

  constructor(props) {
    super(props);
    this.providedValues = {
      addToast: this.addToast
    };
    this.state = {
      toasts: []
    };
    toastEmitter.on(toastEvent, this.addToast);
    toastEmitter.on(removeToastEvent, this.removeToast);
  }

  addToast = ({color, title, message, ariaLabel, permanent, toastId}) => {
    this.setState((state) => ({
      toasts: [
        ...state.toasts,
        {
          color,
          title,
          message,
          ariaLabel,
          uuid: toastId || uuid(),
          permanent
        }
      ]
    }));
  };

  removeToast = (id) => {
    this.setState((state) => ({
      toasts: state.toasts.map((toast) => {
        if (toast.uuid === id) {
          return {
            ...toast,
            shouldDismiss: true
          };
        }
        return toast;
      })
    }));
  };

  handleRemoveToast = (index) => {
    this.setState((state) => ({
      toasts: state.toasts.filter((_, i) => i !== index)
    }));
  };

  render() {
    return (
      <GlobalToastContext.Provider value={this.providedValues}>
        <div>
          {this.props.children}
          <ToastsList toasts={this.state.toasts} handleRemoveToast={this.handleRemoveToast} />
        </div>
      </GlobalToastContext.Provider>
    );
  }
}

const ToastsList = ({toasts, handleRemoveToast}) => (
  <div className='h-toasts-list'>
    {toasts.map((toast, i) => (
      <Toast
        key={toast.uuid}
        color={toast.color}
        title={toast.title}
        message={toast.message}
        ariaLabel={toast.ariaLabel}
        permanent={toast.permanent}
        shouldDismiss={toast.shouldDismiss}
        onDismissEnd={() => handleRemoveToast(i)}
      />
    ))}
  </div>
);

ToastsList.propTypes = {
  toasts: PropTypes.array.isRequired,
  handleRemoveToast: PropTypes.func.isRequired
};
