const SIMPLE_QUESTION_FIELDS = [
  'title',
  'prompt',
  'solution_text',
  'dropdown_text',
  'sample',
  'uncorrected_text',
  'snippet_prompt',
  'highlight_prompt',
  'header_left',
  'header_right'
];

export interface TranslationSubject {
  type: 'question' | 'supplement';
  subject: any;
}

const getModelInputForQuestion = (question: any) => {
  const simpleFields = SIMPLE_QUESTION_FIELDS.filter((f) => !!question[f]).map((f) => ({
    key: f,
    value: question[f]
  }));

  const options = question.options
    ? question.options.toJS().map((option, i) => {
        return {
          key: `options.${i}`,
          value: option.value
        };
      })
    : [];

  const dropdowns = question.dropdowns
    ? question.dropdowns
        .toJS()
        .map((dropdown, i) => {
          return dropdown.choices.map((choice, j) => {
            return {
              key: `dropdowns.${i}.choices.${j}`,
              value: choice.value
            };
          });
        })
        .flat()
    : [];

  const inputs = question.inputs
    ? question.inputs.toJS().map((input, i) => {
        return {
          key: `inputs.${i}`,
          value: input.text
        };
      })
    : [];

  const rows = question.rows
    ? question.rows
        .toJS()
        .map((row, i) => {
          return [
            {key: `rows.${i}.statement`, value: row.statement},
            {key: `rows.${i}.columns.left.text`, value: row.columns.left.text},
            {key: `rows.${i}.columns.right.text`, value: row.columns.right.text}
          ];
        })
        .flat()
    : [];

  return simpleFields.concat(options).concat(dropdowns).concat(inputs).concat(rows);
};

const getModelInputForSupplement = (supplement: any) => {
  const ret = [{key: 'name', value: supplement.name}];

  if (
    ['free-form', 'passage', 'sortable-table'].includes(supplement.type) &&
    !!supplement.content.text
  ) {
    ret.push({key: 'content.text', value: supplement.content.text});
  }
  if (['image', 'audio'].includes(supplement.type) && !!supplement.content.caption) {
    ret.push({key: 'content.caption', value: supplement.content.caption});
  }
  if (supplement.type === 'image' && !!supplement.content.alttext) {
    ret.push({key: 'content.alttext', value: supplement.content.alttext});
  }
  return ret;
};

export const getModelInput = (subject: TranslationSubject | null) => {
  if (!subject) {
    return null;
  }

  if (subject.type === 'question') {
    return getModelInputForQuestion(subject.subject);
  }
  return getModelInputForSupplement(subject.subject.toJS());
};
