import {List} from 'immutable';
import {MandarkResourceInterface} from '../Mandark.interface';

// XXX Specify a map of versions for each resource to use
// put it on the resource, pass to the interface
export class AssignmentsInterface extends MandarkResourceInterface {
  get timeLimit() {
    return this.getMainResourceAttributes().get('time_limit');
  }

  get name() {
    return this.getMainResourceAttributes().get('name');
  }

  get id() {
    return this.getMainResourceAttributes().get('id');
  }

  get message() {
    return this.getMainResourceAttributes().get('message');
  }

  get dueDate() {
    return this.getMainResourceAttributes().get('due_date');
  }

  get startDate() {
    return this.getMainResourceAttributes().get('start_date');
  }

  get allowLateSubmissions() {
    return this.getMainResourceAttributes().get('allow_late_submissions');
  }

  get correctAnswerSetting() {
    return this.getMainResourceAttributes().get('correct_answer_setting');
  }

  getQuestionDifficulty(id) {
    const questionVersion = this.versions.get('question');
    return this.getIncludedResourceAttributeById(questionVersion, id).getIn([
      'attributes',
      'difficulty'
    ]);
  }

  getQuestionTitle(id) {
    const questionVersion = this.versions.get('question');
    return this.getIncludedResourceAttributeById(questionVersion, id).getIn([
      'attributes',
      'title'
    ]);
  }

  getQuestionSetPosition(id) {
    // @todo: this should be defined in the versions bootstrap.
    // const questionSetVersion = this.versions.get('question_sets');

    let data;
    const cacheKey = `question_set_position`;
    if (this._cache.get(cacheKey)) {
      data = this._cache.get(cacheKey);
    } else {
      /**
       * @todo – we should be offering a method to get the main relationships object.
       */
      data = this._data.getIn(['data', 'relationships', 'question_sets_v1', 'data'], new List());
      this._cache = this._cache.set(cacheKey, data);
    }

    const question = data.find((question) => {
      return question.get('id') === id;
    });

    return question ? question.getIn(['meta', 'position']) : 0;
  }

  getSlugForQuestion(id) {
    const cacheKey = `question_slug_for_question_${id}`;
    if (this._cache.has(cacheKey)) {
      return this._cache.get(cacheKey);
    }

    /*
      We have a question's Id. We can use that to grab the correct set
      by examining the [relationships][question_version] attribute of
      the included resource, and seeing if there is a key there that matches our
      id. Requires a scan of all included resources.
    */

    // TODO: figure out how to get the plural questions_v1 from this.versions
    // I can't find out how to do that atm.
    const questionSetResources = this.questionSetResourceList;
    const foundSet = questionSetResources.find((questionSet) => {
      const relationships = questionSet.get('relationships');
      const relatedQuestions = relationships.getIn(['questions_v1', 'data'], new List());

      return relatedQuestions.find((questionRelation) => {
        return questionRelation.get('id') === id;
      });
    });

    const slug = foundSet.getIn(['attributes', 'slug_id']);
    this._cache = this._cache.set(cacheKey, slug);
    return slug;
  }

  get studentList() {
    const studentVersion = this.versions.get('student');
    return this.getIncludedResourceAttributesByType(studentVersion);
  }

  get classroomList() {
    const studentVersion = this.versions.get('classroom');
    return this.getIncludedResourceAttributesByType(studentVersion);
  }

  get questionList() {
    const questionVersion = this.versions.get('question');
    return this.getIncludedResourceAttributesByType(questionVersion);
  }

  get questionSetList() {
    const cacheKey = `question_sets`;
    if (this._cache.has(cacheKey)) {
      return this._cache.get(cacheKey);
    }

    const questionSetVersion = this.versions.get('question_set');
    const sets = this.getIncludedResourceAttributesByType(questionSetVersion)
      .sortBy((set) => set.getIn(['meta', 'position'], 0))
      .map((set) => set.get('attributes'));

    this._cache = this._cache.set(cacheKey, sets);
    return sets;
  }

  get questionSetResourceList() {
    const questionSetVersion = this.versions.get('question_set');
    return this.getIncludedResourceAttributesByType(questionSetVersion);
  }

  get questionSetIds() {
    const cacheKey = `question_set_ids`;
    if (this._cache.has(cacheKey)) {
      return this._cache.get(cacheKey);
    }

    const questionSetVersion = this.versions.get('question_set');
    const setIds = this.getIncludedResourceAttributesByType(questionSetVersion)
      .sortBy((set) => set.getIn(['meta', 'position'], 0))
      .map((set) => set.get('id'));

    this._cache = this._cache.set(cacheKey, setIds);
    return setIds;
  }

  get questionIds() {
    const cacheKey = `question_ids`;
    if (this._cache.has(cacheKey)) {
      return this._cache.get(cacheKey);
    }

    const questionSetVersion = this.versions.get('question');
    const questionIds = this.getIncludedResourceAttributesByType(questionSetVersion).map((set) =>
      set.get('id')
    );

    this._cache = this._cache.set(cacheKey, questionIds);
    return questionIds;
  }
}
