/* eslint-disable */


// Programatically generated on Mon Mar 31 2025 17:10:40 GMT+0000
import {fromJS, List, Map, Record, Set} from 'immutable';
import {GenericModel} from 'resources/Generic.model';
import {validate, Empty} from '@albert-io/json-api-framework/models/helpers';
import moment from 'moment';

const ModelConfig = fromJS(
  {
    'endpoint': 'users_v2',
    'attributes': {
      'is_author': {
        'type': 'boolean',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'google_id': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'customer_feedback_reminder_date': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'district_clever_id': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'onboarding_steps': {
        'type': 'string[]',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'sign_up_method': {
        'type': 'string',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'sent_password_reset_email_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'verify_password_reset_link': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'sent_created_user_email_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'is_teacher': {
        'type': 'boolean',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'coppa_consent_email': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'content_preferences': {
        'type': 'map',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'status': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'points_earned': {
        'type': 'integer',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'needs_coppa': {
        'type': 'boolean',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'clever_id': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'facebook_id': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'last_name': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'username': {
        'type': 'string',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'is_platform_administrator': {
        'type': 'boolean',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'is_school_administrator': {
        'type': 'boolean',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'updated_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'salutation': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'lti_id': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'sent_confirmation_email_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'verify_old_password': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'inserted_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'first_name': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'id': {
        'type': 'binary_id',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'password': {
        'type': 'string',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': true
      },
      'super': {
        'type': 'boolean',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'is_point_of_contact': {
        'type': 'boolean',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'email': {
        'type': 'string',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'verify_confirmation_link': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      }
    },
    'relationships': {
      'supplements_v1': {
        'type': 'one-to-many'
      },
      'student_v2': {
        'type': 'one-to-one'
      },
      'user_feedback_v1': {
        'type': 'one-to-many'
      },
      'subscriptions_v1': {
        'type': 'one-to-many'
      },
      'school_v1': {
        'type': 'one-to-one'
      },
      'school_v2': {
        'type': 'one-to-one'
      },
      'authoring_supplements_v1': {
        'type': 'one-to-many'
      },
      'school_v3': {
        'type': 'one-to-one'
      },
      'school_v4': {
        'type': 'one-to-one'
      },
      'school_v5': {
        'type': 'one-to-one'
      },
      'clever_course_syncs_v1': {
        'type': 'one-to-many'
      },
      'author_v1': {
        'type': 'one-to-one'
      },
      'standard_set_creators_v1': {
        'type': 'one-to-many'
      },
      'coppa_consent_grants_v1': {
        'type': 'one-to-many'
      },
      'curriculum_areas_v1': {
        'type': 'one-to-many'
      },
      'teacher_v1': {
        'type': 'one-to-one'
      },
      'student_v1': {
        'type': 'one-to-one'
      }
    }
  }
);

const ModelRecord = Record(
  {
    'is_author': Empty,
    'google_id': Empty,
    'curriculum_areas': Empty,
    'customer_feedback_reminder_date': Empty,
    'district_clever_id': Empty,
    'teacher': Empty,
    'onboarding_steps': Empty,
    'sign_up_method': Empty,
    'sent_password_reset_email_at': Empty,
    'coppa_consent_grants': Empty,
    'standard_set_creators': Empty,
    'verify_password_reset_link': Empty,
    'author': Empty,
    'clever_course_syncs': Empty,
    'sent_created_user_email_at': Empty,
    'is_teacher': Empty,
    'coppa_consent_email': Empty,
    'content_preferences': Empty,
    'authoring_supplements': Empty,
    'status': Empty,
    'points_earned': Empty,
    'needs_coppa': Empty,
    'clever_id': Empty,
    'facebook_id': Empty,
    'last_name': Empty,
    'school': Empty,
    'username': Empty,
    'is_platform_administrator': Empty,
    'subscriptions': Empty,
    'is_school_administrator': Empty,
    'updated_at': Empty,
    'salutation': Empty,
    'user_feedback': Empty,
    'lti_id': Empty,
    'sent_confirmation_email_at': Empty,
    'verify_old_password': Empty,
    'inserted_at': Empty,
    'first_name': Empty,
    'supplements': Empty,
    'id': Empty,
    'student': Empty,
    'password': Empty,
    'super': Empty,
    'is_point_of_contact': Empty,
    'email': Empty,
    'relationshipMeta': Empty,
    'verify_confirmation_link': Empty,
    'meta': new Map({
    'allow_clever_sync': Empty,
    'current_clever_sync_id': Empty,
    'is_beta_testing': Empty,
    'is_complete_profile': Empty,
    'is_coppa_approved': Empty,
    'last_clever_sync_date': Empty,
    'license_status': Empty,
    'populated_metadata_fields': Empty
  })
  }
);

const DefaultModel = fromJS(
  {
    'is_author': false,
    'google_id': '',
    'district_clever_id': '',
    'sign_up_method': '',
    'verify_password_reset_link': '',
    'coppa_consent_email': '',
    'status': '',
    'clever_id': '',
    'facebook_id': '',
    'last_name': '',
    'username': '',
    'salutation': '',
    'lti_id': '',
    'verify_old_password': '',
    'first_name': '',
    'id': '',
    'password': '',
    'email': '',
    'verify_confirmation_link': '',
    'customer_feedback_reminder_date': new Date(),
    'sent_password_reset_email_at': new Date(),
    'sent_created_user_email_at': new Date(),
    'updated_at': new Date(),
    'sent_confirmation_email_at': new Date(),
    'inserted_at': new Date(),
    'curriculum_areas': new List(),
    'onboarding_steps': new List(),
    'coppa_consent_grants': new List(),
    'standard_set_creators': new List(),
    'clever_course_syncs': new List(),
    'authoring_supplements': new List(),
    'subscriptions': new List(),
    'user_feedback': new List(),
    'supplements': new List(),
    'teacher': new Map(),
    'meta': new Map(),
    'author': new Map(),
    'is_teacher': false,
    'content_preferences': new Map(),
    'points_earned': 0,
    'needs_coppa': false,
    'school': new Map(),
    'is_platform_administrator': false,
    'is_school_administrator': false,
    'student': new Map(),
    'super': false,
    'is_point_of_contact': false,
    'relationshipMeta': new Map()
  }
);

export class UserModelV2 extends GenericModel(ModelRecord, ModelConfig, 'UserModelV2') {
  // -- Query Rules --
  /**
   * @returns {boolean}
   */
  static allowsInclude(include) {
    // AllowedIncludes is currently disabled.
    return true; // AllowedIncludes.has(include);
  }

  /**
   * @returns {boolean}
   */
  static allowsFilter(filterField, filterType) {
    return filterType ?
      FilterRules.get(filterField).has(filterType) :
      FilterRules.has(filterField);
  }

  /**
   * @returns {boolean}
   */
  static allowsSort(sort) {
    return sort.split(',').filter((s) => AllowedSorts.has(s)).length > 0;
  }

  /**
   * @returns {Array}
   */
  static getAllowedSorts() {
    return AllowedSorts.keySeq().toJS();
  }

  static getMetaContextQuery(key) {
    if (!MetadataContextRules.hasIn(['optional', key]) && !MetadataContextRules.hasIn(['required', key])) {
      console.error('Rule for meta context key ' + key + ' does not exist');
    }

    return MetadataContextRules.getIn(['optional', key]) || MetadataContextRules.getIn(['required', key]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getRequiredMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values()]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getAllMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values(), ...MetadataContextRules.get('optional').values()]);
  }

  static getDefaultModel() {
    _emptyUserModelV2Model = _emptyUserModelV2Model ? _emptyUserModelV2Model : new UserModelV2(DefaultModel);
    return _emptyUserModelV2Model;
  }

  static supportsMetaContext() {
    return MetadataContextRules !== null;
  }

  /**
   * @returns {string}
   */
  static getModelName() {
    return 'UserModelV2';
  }

  /**
   * @returns {boolean}
   */
  static _isFullSpec() {
    return true;
  }

  constructor() {
    super(...arguments);
    this.__meta = null;
  }

  /**
   * @returns {string}
   */
  getJsonApiType() {
    return 'user_v2';
  }

  /**
   * @params {string} - An attribute name
   * @params {*} [def] - Optional default value override for the attribute
   */
  getValueForAttribute(attribute, def) {
    let value = this.get(attribute);
    validate(value, attribute);
    if (value === Empty) {
      value = def !== undefined ? def : DefaultModel.get(attribute);
    }
    return value;
  }

  // -- Primary Attributes --
  
  /**
   * @type {string}
   */
  getCleverId() {
    const value = this.getValueForAttribute('clever_id');
    return value;
  }

  setCleverId(value) {
    return this.setField('clever_id', value);
  }

  /**
   * @type {map}
   */
  getContentPreferences() {
    const value = this.getValueForAttribute('content_preferences');
    return value;
  }

  setContentPreferences(value) {
    return this.setField('content_preferences', value);
  }

  /**
   * @type {string}
   */
  getCoppaConsentEmail() {
    const value = this.getValueForAttribute('coppa_consent_email');
    return value;
  }

  setCoppaConsentEmail(value) {
    return this.setField('coppa_consent_email', value);
  }

  /**
   * @type {datetime}
   */
  getCustomerFeedbackReminderDate() {
    const value = this.getValueForAttribute('customer_feedback_reminder_date');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  setCustomerFeedbackReminderDate(value) {
    const momentValue = moment(value);
    return this.setField('customer_feedback_reminder_date', momentValue.isValid() ? momentValue : value);
  }

  /**
   * @type {string}
   */
  getDistrictCleverId() {
    const value = this.getValueForAttribute('district_clever_id');
    return value;
  }

  /**
   * @type {string}
   */
  getEmail() {
    const value = this.getValueForAttribute('email');
    return value;
  }

  setEmail(value) {
    return this.setField('email', value);
  }

  /**
   * @type {string}
   */
  getFacebookId() {
    const value = this.getValueForAttribute('facebook_id');
    return value;
  }

  setFacebookId(value) {
    return this.setField('facebook_id', value);
  }

  /**
   * @type {string}
   */
  getFirstName() {
    const value = this.getValueForAttribute('first_name');
    return value;
  }

  setFirstName(value) {
    return this.setField('first_name', value);
  }

  /**
   * @type {string}
   */
  getGoogleId() {
    const value = this.getValueForAttribute('google_id');
    return value;
  }

  setGoogleId(value) {
    return this.setField('google_id', value);
  }

  /**
   * @type {binary_id}
   */
  getId() {
    const value = this.getValueForAttribute('id');
    return value;
  }

  setId(value) {
    return this.setField('id', value);
  }

  /**
   * @type {datetime}
   */
  getInsertedAt() {
    const value = this.getValueForAttribute('inserted_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {boolean}
   */
  isAuthor() {
    const value = this.getValueForAttribute('is_author');
    return value;
  }

  setIsAuthor(value) {
    return this.setField('is_author', value);
  }

  /**
   * @type {boolean}
   */
  isPlatformAdministrator() {
    const value = this.getValueForAttribute('is_platform_administrator');
    return value;
  }

  setIsPlatformAdministrator(value) {
    return this.setField('is_platform_administrator', value);
  }

  /**
   * @type {boolean}
   */
  isPointOfContact() {
    const value = this.getValueForAttribute('is_point_of_contact');
    return value;
  }

  setIsPointOfContact(value) {
    return this.setField('is_point_of_contact', value);
  }

  /**
   * @type {boolean}
   */
  isSchoolAdministrator() {
    const value = this.getValueForAttribute('is_school_administrator');
    return value;
  }

  setIsSchoolAdministrator(value) {
    return this.setField('is_school_administrator', value);
  }

  /**
   * @type {boolean}
   */
  isTeacher() {
    const value = this.getValueForAttribute('is_teacher');
    return value;
  }

  setIsTeacher(value) {
    return this.setField('is_teacher', value);
  }

  /**
   * @type {string}
   */
  getLastName() {
    const value = this.getValueForAttribute('last_name');
    return value;
  }

  setLastName(value) {
    return this.setField('last_name', value);
  }

  /**
   * @type {string}
   */
  getLtiId() {
    const value = this.getValueForAttribute('lti_id');
    return value;
  }

  setLtiId(value) {
    return this.setField('lti_id', value);
  }

  /**
   * @type {boolean}
   */
  isNeedsCoppa() {
    const value = this.getValueForAttribute('needs_coppa');
    return value;
  }

  setNeedsCoppa(value) {
    return this.setField('needs_coppa', value);
  }

  /**
   * @type {string[]}
   */
  getOnboardingSteps() {
    const value = this.getValueForAttribute('onboarding_steps');
    return value;
  }

  setOnboardingSteps(value) {
    return this.setField('onboarding_steps', value);
  }

  /**
  * @type {string}
  */
  setPassword(value) {
    return this.setField('password', value);
  }

  /**
   * @type {integer}
   */
  getPointsEarned() {
    const value = this.getValueForAttribute('points_earned');
    
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
  }

  /**
   * @type {string}
   */
  getSalutation() {
    const value = this.getValueForAttribute('salutation');
    return value;
  }

  setSalutation(value) {
    return this.setField('salutation', value);
  }

  /**
   * @type {datetime}
   */
  getSentConfirmationEmailAt() {
    const value = this.getValueForAttribute('sent_confirmation_email_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  setSentConfirmationEmailAt(value) {
    const momentValue = moment(value);
    return this.setField('sent_confirmation_email_at', momentValue.isValid() ? momentValue : value);
  }

  /**
   * @type {datetime}
   */
  getSentCreatedUserEmailAt() {
    const value = this.getValueForAttribute('sent_created_user_email_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  setSentCreatedUserEmailAt(value) {
    const momentValue = moment(value);
    return this.setField('sent_created_user_email_at', momentValue.isValid() ? momentValue : value);
  }

  /**
   * @type {datetime}
   */
  getSentPasswordResetEmailAt() {
    const value = this.getValueForAttribute('sent_password_reset_email_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  setSentPasswordResetEmailAt(value) {
    const momentValue = moment(value);
    return this.setField('sent_password_reset_email_at', momentValue.isValid() ? momentValue : value);
  }

  /**
   * @type {string}
   */
  getSignUpMethod() {
    const value = this.getValueForAttribute('sign_up_method');
    return value;
  }

  setSignUpMethod(value) {
    return this.setField('sign_up_method', value);
  }

  /**
   * @type {string}
   */
  getStatus() {
    const value = this.getValueForAttribute('status');
    return value;
  }

  setStatus(value) {
    return this.setField('status', value);
  }

  /**
   * @type {boolean}
   */
  isSuper() {
    const value = this.getValueForAttribute('super');
    return value;
  }

  setSuper(value) {
    return this.setField('super', value);
  }

  /**
   * @type {datetime}
   */
  getUpdatedAt() {
    const value = this.getValueForAttribute('updated_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {string}
   */
  getUsername() {
    const value = this.getValueForAttribute('username');
    return value;
  }

  setUsername(value) {
    return this.setField('username', value);
  }

  /**
   * @type {string}
   */
  getVerifyConfirmationLink() {
    const value = this.getValueForAttribute('verify_confirmation_link');
    return value;
  }

  setVerifyConfirmationLink(value) {
    return this.setField('verify_confirmation_link', value);
  }

  /**
   * @type {string}
   */
  getVerifyOldPassword() {
    const value = this.getValueForAttribute('verify_old_password');
    return value;
  }

  setVerifyOldPassword(value) {
    return this.setField('verify_old_password', value);
  }

  /**
   * @type {string}
   */
  getVerifyPasswordResetLink() {
    const value = this.getValueForAttribute('verify_password_reset_link');
    return value;
  }

  setVerifyPasswordResetLink(value) {
    return this.setField('verify_password_reset_link', value);
  }

  // -- Meta Attributes --
  
    /**
     * LEGACY META ACCESSORS
     * @todo These should no longer be used, instead use this.getMeta()[...]
     */
    
      isAllowCleverSync() {
        return this.getMeta().isAllowCleverSync();
      }
    
      getCurrentCleverSyncId() {
        return this.getMeta().getCurrentCleverSyncId();
      }
    
      isBetaTesting() {
        return this.getMeta().isBetaTesting();
      }
    
      isCompleteProfile() {
        return this.getMeta().isCompleteProfile();
      }
    
      isCoppaApproved() {
        return this.getMeta().isCoppaApproved();
      }
    
      getLastCleverSyncDate() {
        return this.getMeta().getLastCleverSyncDate();
      }
    
      getLicenseStatus() {
        return this.getMeta().getLicenseStatus();
      }
    
      getPopulatedMetadataFields() {
        return this.getMeta().getPopulatedMetadataFields();
      }
    
    getMeta() {
      if (this.__meta) {
        return this.__meta;
      }
      const meta = this.get('meta', Map());
      this.__meta = {
        
          /**
           * @type {boolean}
           */
          isAllowCleverSync() {
            const value = meta.get('allow_clever_sync');
            validate(value, 'allow_clever_sync');
            return value;
          },
  
          /**
           * @type {string}
           */
          getCurrentCleverSyncId() {
            const value = meta.get('current_clever_sync_id');
            validate(value, 'current_clever_sync_id');
            return value;
          },
  
          /**
           * @type {boolean}
           */
          isBetaTesting() {
            const value = meta.get('is_beta_testing');
            validate(value, 'is_beta_testing');
            return value;
          },
  
          /**
           * @type {boolean}
           */
          isCompleteProfile() {
            const value = meta.get('is_complete_profile');
            validate(value, 'is_complete_profile');
            return value;
          },
  
          /**
           * @type {boolean}
           */
          isCoppaApproved() {
            const value = meta.get('is_coppa_approved');
            validate(value, 'is_coppa_approved');
            return value;
          },
  
          /**
           * @type {datetime}
           */
          getLastCleverSyncDate() {
            const value = meta.get('last_clever_sync_date');
            validate(value, 'last_clever_sync_date');
            
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
          },
  
          /**
           * @type {string}
           */
          getLicenseStatus() {
            const value = meta.get('license_status');
            validate(value, 'license_status');
            return value;
          },
  
          /**
           * @type {string[]}
           */
          getPopulatedMetadataFields() {
            const value = meta.get('populated_metadata_fields');
            validate(value, 'populated_metadata_fields');
            return value;
          },
  
      };
      return this.__meta;
    }
  
  // -- Relationships --
  
  /**
   * @type {curriculum_area_v1 (many)}
   */
  getCurriculumAreas() {
    const value = this.getValueForAttribute('curriculum_areas');
    return value;
  }

  // -- Relationship Fields: curriculum_areas --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {teacher_v1 (one)}
   */
  getTeacher() {
    const value = this.getValueForAttribute('teacher');
    return value;
  }

  // -- Relationship Fields: teacher --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {coppa_consent_grant_v1 (many)}
   */
  getCoppaConsentGrants() {
    const value = this.getValueForAttribute('coppa_consent_grants');
    return value;
  }

  // -- Relationship Fields: coppa_consent_grants --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {standard_set_creator_v1 (many)}
   */
  getStandardSetCreators() {
    const value = this.getValueForAttribute('standard_set_creators');
    return value;
  }

  // -- Relationship Fields: standard_set_creators --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {author_v1 (one)}
   */
  getAuthor() {
    const value = this.getValueForAttribute('author');
    return value;
  }

  // -- Relationship Fields: author --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {clever_course_sync_v1 (many)}
   */
  getCleverCourseSyncs() {
    const value = this.getValueForAttribute('clever_course_syncs');
    return value;
  }

  // -- Relationship Fields: clever_course_syncs --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {authoring_supplement_v1 (many)}
   */
  getAuthoringSupplements() {
    const value = this.getValueForAttribute('authoring_supplements');
    return value;
  }

  // -- Relationship Fields: authoring_supplements --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {school_v5 (one)}
   */
  getSchool() {
    const value = this.getValueForAttribute('school');
    return value;
  }

  // -- Relationship Fields: school --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {subscription_v1 (many)}
   */
  getSubscriptions() {
    const value = this.getValueForAttribute('subscriptions');
    return value;
  }

  // -- Relationship Fields: subscriptions --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {user_feedback_v1 (many)}
   */
  getUserFeedback() {
    const value = this.getValueForAttribute('user_feedback');
    return value;
  }

  // -- Relationship Fields: user_feedback --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {supplement_v1 (many)}
   */
  getSupplements() {
    const value = this.getValueForAttribute('supplements');
    return value;
  }

  // -- Relationship Fields: supplements --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {student_v2 (one)}
   */
  getStudent() {
    const value = this.getValueForAttribute('student');
    return value;
  }

  // -- Relationship Fields: student --
  // no 'fields' found, skipped generating accessor(s)
  
}

// -- Empty Model Instance --
let _emptyUserModelV2Model = null;

// -- Sort Rules --

const AllowedSorts = fromJS({
  'clever_id': null,
  'coppa_consent_email': null,
  'customer_feedback_reminder_date': null,
  'district_clever_id': null,
  'email': null,
  'facebook_id': null,
  'first_name': null,
  'google_id': null,
  'id': null,
  'inserted_at': null,
  'is_author': null,
  'is_platform_administrator': null,
  'is_point_of_contact': null,
  'is_school_administrator': null,
  'is_teacher': null,
  'last_name': null,
  'lti_id': null,
  'meta.current_clever_sync_id': null,
  'meta.is_beta_testing': null,
  'meta.is_complete_profile': null,
  'meta.is_coppa_approved': null,
  'meta.last_clever_sync_date': null,
  'meta.license_status': null,
  'needs_coppa': null,
  'password': null,
  'points_earned': null,
  'salutation': null,
  'sent_confirmation_email_at': null,
  'sent_created_user_email_at': null,
  'sent_password_reset_email_at': null,
  'sign_up_method': null,
  'status': null,
  'super': null,
  'updated_at': null,
  'username': null,
  'verify_confirmation_link': null,
  'verify_old_password': null,
  'verify_password_reset_link': null,
});

// -- Include Rules --
// List []

// -- Filter Rules --
const FilterRules = new Map({
  'author_v1': new Set(['associated']),
  'authoring_supplements_v1': new Set(['associated']),
  'clever_course_syncs_v1': new Set(['associated']),
  'clever_id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'content_preferences': new Set(['null']),
  'coppa_consent_email': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'coppa_consent_grants_v1': new Set(['associated']),
  'curriculum_areas_v1': new Set(['associated']),
  'customer_feedback_reminder_date': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'district_clever_id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'email': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'facebook_id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'first_name': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'google_id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'inserted_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'is_author': new Set(['exact','not','null']),
  'is_platform_administrator': new Set(['exact','not','null']),
  'is_point_of_contact': new Set(['exact','not','null']),
  'is_school_administrator': new Set(['exact','not','null']),
  'is_teacher': new Set(['exact','not','null']),
  'last_name': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'lti_id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'meta.current_clever_sync_id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'meta.is_beta_testing': new Set(['exact','not','null']),
  'meta.is_complete_profile': new Set(['exact','not','null']),
  'meta.is_coppa_approved': new Set(['exact','not','null']),
  'meta.last_clever_sync_date': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.license_status': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'meta.populated_metadata_fields': new Set(['null']),
  'needs_coppa': new Set(['exact','not','null']),
  'onboarding_steps': new Set(['null']),
  'password': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'points_earned': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'salutation': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'school_v1': new Set(['associated']),
  'school_v2': new Set(['associated']),
  'school_v3': new Set(['associated']),
  'school_v4': new Set(['associated']),
  'school_v5': new Set(['associated']),
  'sent_confirmation_email_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'sent_created_user_email_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'sent_password_reset_email_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'sign_up_method': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'standard_set_creators_v1': new Set(['associated']),
  'status': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'student_v1': new Set(['associated']),
  'student_v2': new Set(['associated']),
  'subscriptions_v1': new Set(['associated']),
  'super': new Set(['exact','not','null']),
  'supplements_v1': new Set(['associated']),
  'teacher_v1': new Set(['associated']),
  'updated_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'user_feedback_v1': new Set(['associated']),
  'username': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'verify_confirmation_link': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'verify_old_password': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'verify_password_reset_link': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
});
    

// -- Metadata Context Rules --
const MetadataContextRules = fromJS({
  optional: {
    'allow_clever_sync': 'meta.context.user.allow_clever_sync',
    'current_clever_sync_id': 'meta.context.user.current_clever_sync_id',
    'is_beta_testing': 'meta.context.user.is_beta_testing',
    'last_clever_sync_date': 'meta.context.user.last_clever_sync_date',
    'license_status': 'meta.context.user.license_status',
  },
  required: {
  }
});

// -- Legacy Name Export --
export const User = UserModelV2;
