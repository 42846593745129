/**
 * # Expandable
 *
 * Helper component to create expandable areas. When expanded={false}, it will have a height of 0px.
 * When expanded={true}, it will grow to the size of its children.
 *
 * For accessibility purposes, you must give <Expandable /> an id, and you must specify the id of the
 * trigger element that expands/collapses it.
 *
 * @see [GitHub Issue](https://github.com/albert-io/project-management/issues/2043)
 *
 * ## Props
 *
 * - `as?: React.ElementType` (extends `React.ElementType`): The HTML element or React component to be used as the wrapper element. Default value is `div`.
 * - `aria-labelledby: string` (required): ID of the trigger element which expands/collapses this.
 * - `children?: React.ReactNode`: The content to be rendered inside the expandable area.
 * - `className?: string`: Additional CSS class name(s) to be applied to the expandable area.
 * - `expanded?: boolean`: Whether the expandable area is initially expanded or collapsed.
 * - `id: string` (required): ID of the expandable area. Make sure to provide the trigger element with an `aria-controls` property of the same value.
 *
 * ## Usage
 *
 * ```tsx
 * import Expandable from './Expandable';
 *
 * function App() {
 *   return (
 *     <div>
 *       <button id="trigger">Toggle Expandable</button>
 *       <Expandable aria-labelledby="trigger" id="expandable" expanded={false}>
 *         <p>This is the content of the expandable area.</p>
 *       </Expandable>
 *     </div>
 *   );
 * }
 * ```
 */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './expandable.scss';

interface ExpandableProps extends PropsWithChildrenOptional, PropsWithClassNameOptional {
  as?: React.ElementType;
  ['aria-labelledby']: string;
  expanded?: boolean;
  id?: string;
}

interface ExpandableState {
  isExpanded: boolean;
}

/**
 * Helper component to create expandable areas. When expanded={false}, it will have a
 * height of 0px. When expanded={true}, it will grow to the size of its children.
 *
 * For accessibility purposes, you must give <Expandable /> an id, and you must
 * specify the id of the trigger element that expands/collapses it.
 *
 * @see https://github.com/albert-io/project-management/issues/2043
 * @augments {React.Component<Object>}
 */
export default class Expandable extends React.Component<ExpandableProps, ExpandableState> {
  private wrapperRef: React.RefObject<any>;

  private childWrapperRef: React.RefObject<HTMLDivElement>;

  private timeoutId: null | ReturnType<typeof setTimeout>;

  static propTypes = {
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    /** ID of the trigger element which expands/collapses this */
    'aria-labelledby': PropTypes.string.isRequired,
    className: PropTypes.string,
    children: PropTypes.node,
    expanded: PropTypes.bool,
    /**
     * ID of the expandable area. Make sure to provide the trigger element
     * with an aria-controls property of the same value
     */
    id: PropTypes.string.isRequired
  };

  static defaultProps = {
    as: 'div'
  };

  constructor(props) {
    super(props);
    this.state = {
      isExpanded: props.expanded
    };

    this.wrapperRef = React.createRef();
    this.childWrapperRef = React.createRef();
    this.timeoutId = null;
  }

  /**
   * This logic is to allow for nested expandable objects.
   * Due to having overflow hidden and inline styling the height, we need to assign
   * and un-assign height to ensure the animation ocurrs.
   *
   * @param prevProps
   */
  componentDidUpdate = (prevProps) => {
    const {expanded} = this.props;
    const prevExpanded = prevProps.expanded;
    if (expanded === prevExpanded) {
      return;
    }
    if (expanded) {
      this.wrapperRef.current.addEventListener('transitionend', this.handleExpandedTransitionEnd);
    } else {
      this.timeoutId = setTimeout(() => {
        this.setState({
          isExpanded: false
        });
      }, 0);
    }
  };

  componentWillUnmount() {
    clearTimeout(this.timeoutId!);
    this.wrapperRef.current.removeEventListener('transitionend', this.handleExpandedTransitionEnd);
  }

  handleExpandedTransitionEnd = () => {
    this.wrapperRef.current.removeEventListener('transitionend', this.handleExpandedTransitionEnd);
    if (!this.props.expanded) {
      return;
    }
    this.setState({
      isExpanded: true
    });
  };

  render() {
    const {
      as: WrapperElement = 'div',
      'aria-labelledby': labelledBy,
      children,
      className,
      expanded,
      id,
      ...rest
    } = this.props;
    const {isExpanded} = this.state;
    const shouldSetHeight = !isExpanded || isExpanded !== expanded;
    let height = 0;
    if (this.childWrapperRef.current && (isExpanded || expanded)) {
      height = this.childWrapperRef.current.clientHeight;
    }
    return (
      <WrapperElement
        {...rest}
        ref={this.wrapperRef}
        className={classnames('h-expandable', className)}
        aria-expanded={expanded}
        aria-labelledby={labelledBy}
        id={id}
        style={
          shouldSetHeight
            ? {
                height: `${height}px`
              }
            : null
        }
      >
        <div ref={this.childWrapperRef}>{children}</div>
      </WrapperElement>
    );
  }
}
