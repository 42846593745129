import React from 'react';

import Text from '../Text/Text.react';

import {CommonTextProps} from './types';

export interface Props extends CommonTextProps {
  selected?: boolean;
}

export default function MenuListItemRegularText({
  bold = true,
  children,
  className,
  selected = false,
  size = 'm',
  ...rest
}: Props) {
  return (
    <Text
      bold={bold}
      className={className}
      size={size}
      color={selected ? 'brand' : 'primary'}
      {...rest}
    >
      {children}
    </Text>
  );
}
