import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {Link} from 'react-router';
import {Dropdown, IconButton, ExpandableDropdownItem, DropdownItem, Text} from '@albert-io/atomic';
import awaitMandarkQueries from 'lib/hocs/awaitMandarkQueries';
import {renderMoment} from 'lib/timeUtils';
import {makePreviousAttemptsQuery, openQuestionFeedbackModal} from '../practiceView.utils';
import {callTargetedAction} from 'client/framework';
import questionTypeActions from 'components/QuestionTypes/questionType.actions';
import {getModelForResourceType} from 'resources/modelRegistry';

const PreviousAttempts = awaitMandarkQueries(
  ({question}) => {
    return {
      queries: {
        guesses: makePreviousAttemptsQuery(question.getId())
      }
    };
  },
  class BasePreviousAttempts extends React.Component {
    static propTypes = {
      onGuessClick: PropTypes.func.isRequired,
      // Props below are provided automatically and do not need to be passed in
      closeDropdown: PropTypes.func.isRequired,
      guesses: ImmutablePropTypes.list,
      question: PropTypes.instanceOf(getModelForResourceType('question_v3'))
    };

    handleGuessClick(guess) {
      callTargetedAction({
        name: questionTypeActions.LOAD_GUESS,
        targetStore: this.props.question.getStore().getName(),
        payload: guess
      });
      this.props.onGuessClick(guess);
    }

    render() {
      const {guesses, closeDropdown} = this.props;
      return guesses.isEmpty() ? null : (
        <ExpandableDropdownItem label='Attempts' closeDropdown={closeDropdown}>
          {guesses.map((guess, i) => (
            <DropdownItem
              key={i}
              onClick={() => this.handleGuessClick(guess)}
              icon={guess.isCorrect() ? 'check' : 'times'}
              iconColor={guess.isCorrect() ? 'positive' : 'negative'}
            >
              <Text bold size='s'>
                {renderMoment(guess.getInsertedAt(), 'date')}
              </Text>
            </DropdownItem>
          ))}
        </ExpandableDropdownItem>
      );
    }
  }
);

/**
 * @param {Object} props - Props are passed in by the Dropdown parent component.
 * You don't have to pass these props directly
 */
/* eslint-disable-next-line react/prop-types */
const QuestionFeedbackItem = ({closeDropdown, question}) => (
  <DropdownItem closeDropdown={closeDropdown} onClick={() => openQuestionFeedbackModal(question)}>
    Question Feedback
  </DropdownItem>
);

/**
 * The closeDropdown prop is passed by the parent component automatically but the onClick needs to be passed down
 */
/* eslint-disable-next-line react/prop-types */
const SaveToItem = ({closeDropdown, onClick}) => (
  <DropdownItem closeDropdown={closeDropdown} onClick={onClick}>
    Save To...
  </DropdownItem>
);

/**
 * @param {Object} props
 * @param {() => void} props.closeDropdown Passed by parent Dropdown component
 * @param {string} props.editPath Path to the question in its edit mode. Authoring for authors, Dennis queue for supers.
 */
const EditQuestionItem = ({
  /* eslint-disable-next-line react/prop-types */
  closeDropdown,
  editPath
}) => {
  return (
    <DropdownItem
      preventDefaultClickHandler={false}
      as={Link}
      closeDropdown={closeDropdown}
      to={editPath}
    >
      Edit
    </DropdownItem>
  );
};

EditQuestionItem.propTypes = {
  editPath: PropTypes.string
};

export default class HeadingActions extends React.Component {
  static PreviousAttempts = PreviousAttempts;

  static QuestionFeedbackItem = QuestionFeedbackItem;

  static EditQuestionItem = EditQuestionItem;

  static SaveToItem = SaveToItem;

  render() {
    return (
      <Dropdown
        className='u-mar-l_1'
        position='bottom-start'
        trigger={<IconButton icon='ellipsis-v' size='s' label='Question actions' />}
      >
        {React.Children.map(this.props.children, (child) => React.cloneElement(child, this.props))}
      </Dropdown>
    );
  }
}
