import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import PureComponent from 'lib/purecomponent';
import {SUBHEADER_CLASS} from 'client/InteractionEngineV2/interactionEngineConstants';
import LoadingIndicator from 'generic/LoadingIndicator.react';
import './ie-subheader.scss';

function getMaxChildWidth(parentNode) {
  return [...parentNode.children].reduce(
    (currentMax, child) => Math.max(currentMax, child.getBoundingClientRect().width),
    0
  );
}

export default class IESubheader extends PureComponent {
  static propTypes = {
    content: ImmutablePropTypes.list,
    rightContent: PropTypes.node,
    isLoading: PropTypes.bool
  };

  constructor() {
    super();
    this.hasSizedItems = false;
    this.itemNameRefs = null;
  }

  componentDidMount() {
    if (!this.itemNameRefs) {
      return;
    }
    this.sizeItemEls();
  }

  componentDidUpdate() {
    if (!this.hasSizedItems && this.itemNameRefs) {
      this.sizeItemEls();
    }
  }

  sizeItemEls() {
    this.itemNameRefs.forEach((item) => {
      item.style.width = getMaxChildWidth(item) + 'px';
    });
    this.hasSizedItems = true;
  }

  generateSubheaderItems() {
    const itemNameRefs = [];
    const items = this.props.content.map((item, i, list) => {
      const isLast = i + 1 === list.size;
      const hoverHelperText =
        (!isLast || item.get('forceHoverText')) && item.has('hoverText') ? (
          <span className='ie-subheader__item-hover-text'>{item.get('hoverText')}</span>
        ) : null;
      const itemDisplayName = (
        <span className='ie-subheader__item-label'>{item.get('displayName')}</span>
      );
      const nameContent = (
        <div
          className={classnames('ie-subheader__item-name', {
            'ie-subheader__item-name--hoverable': hoverHelperText
          })}
          ref={(node) => {
            itemNameRefs.push(node);
          }}
        >
          {hoverHelperText}
          {itemDisplayName}
        </div>
      );
      const badge = item.has('badge') ? item.get('badge')(item) : null;
      const ItemEl = item.has('to') ? Link : EmptyDiv;
      return (
        <ItemEl to={item.get('to')} className='ie-subheader__item' key={i}>
          {nameContent}
          {badge}
        </ItemEl>
      );
    });
    this.itemNameRefs = itemNameRefs;
    return items;
  }

  render() {
    const rightContent = this.props.rightContent ? (
      <div className='ie-subheader__right-content'>{this.props.rightContent}</div>
    ) : null;
    const content = this.props.isLoading ? (
      <LoadingIndicator inline />
    ) : (
      this.generateSubheaderItems()
    );
    return (
      <div className={classnames('ie-subheader', SUBHEADER_CLASS)}>
        {content}
        {rightContent}
      </div>
    );
  }
}

class EmptyDiv extends PureComponent {
  render = () => <div {...this.props} />;
}
