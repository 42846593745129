/**
 * # Option
 *
 * A component representing an option in a Select dropdown.
 *
 * ## Props
 *
 * - `children` (optional): The content of the option.
 * - `disabled` (optional): Whether the option is disabled.
 * - `label` (optional): The label of the option.
 * - `value` (required): The value of the option.
 *
 * ## Usage
 *
 * ```jsx
 * import Option from './Option.react';
 *
 * const MyComponent = () => {
 *   return (
 *     <Select>
 *       <Option value="1" label="Option 1" />
 *       <Option value="2" label="Option 2" />
 *       <Option value="3" label="Option 3" disabled />
 *     </Select>
 *   );
 * };
 * ```
 */

import React from 'react';

export interface OptionProps extends PropsWithChildrenOptional {
  disabled?: boolean;
  label?: string;
  value: string | number;
}

const Option = ({label, value, ...rest}: OptionProps) => {
  return (
    <option value={value} {...rest}>
      {label || value}
    </option>
  );
};

export default Option;
