import React from 'react';
import PropTypes from 'prop-types';
import {kebabCase} from 'lodash';
import {callAction} from 'client/framework';

import {UserModelV2} from 'resources/GeneratedModels/User/UserModel.v2';
import globalNavigationActions from 'client/GlobalNavigation/GlobalNavigationActions';
import {SubjectMenuRenderer} from 'generic/SubjectMenuRenderer/SubjectMenuRenderer.react';
import {OrderedMap} from 'immutable';
import sessionStore from 'client/Session/SessionStore';
import masqueradeStore from 'generic/Masquerade/Masquerade.store';

import {
  getActivePathFromPathname,
  getSubgroupItemsAsLinkContents
} from 'generic/SubjectMenuRenderer/subjectMenuData.utils';
import {getUserQuery} from 'lib/UserAccessUtil';
import {Heading, Banner} from '@albert-io/atomic';
import {history} from 'client/history';

import awaitMandarkQueries from 'lib/hocs/awaitMandarkQueries';

import {withApplicationMetadata} from '../Routing/withApplicationMetadata';

import ContentDiscovery from './ContentDiscovery/ContentDiscovery.react';
import ClassSubjects from './ClassSubjects/ClassSubjects.react';

const allSubjectsPath = 'subjects';

class CourseLibrary extends React.Component {
  static propTypes = {
    location: PropTypes.object,
    route: PropTypes.object,
    user: PropTypes.instanceOf(UserModelV2)
  };

  constructor() {
    super();
    callAction(globalNavigationActions.SET_SHOULD_SHOW_UPGRADE_BUTTON_NO_EMIT, true);
  }

  componentDidMount() {
    callAction(globalNavigationActions.SET_SHOULD_SHOW_UPGRADE_BUTTON, true);
  }

  componentWillUnmount() {
    callAction(globalNavigationActions.SET_SHOULD_SHOW_UPGRADE_BUTTON, false);
  }

  makeSidebarItemHref(domain, group) {
    const base = `/${
      domain === 'All Subjects' ? allSubjectsPath : `subjects/${kebabCase(domain)}`
    }`;
    return group === 'All' ? base : `${base}/${kebabCase(group)}`;
  }

  makeSubjectCardHref(subject) {
    return `/${subject.getUrlSlug()}`;
  }

  makeSubjectCardContent(subject) {
    return subject.getDescription();
  }

  onSearchStringUpdate = () => {
    history.pushState(null, `/${allSubjectsPath}`);
  };

  getFreeSubjectBanner = () => {
    return (
      <Banner className='u-mar-lr_5 u-mar-t_5'>
        All questions in the subjects below are free. You can create classes, enroll students,
        assign these questions, and view results!
      </Banner>
    );
  };

  render() {
    const activePath = getActivePathFromPathname(this.props.location.pathname, '/subjects');
    const subgroupMenuContent = getSubgroupItemsAsLinkContents(activePath, '/subjects').filter(
      (tab) => tab.get('label') !== 'Free'
    );

    // This allows us to control the order that the items appear in the sidebar
    let additionalSidebarMenuItems = OrderedMap();

    let isUserLicensedTeacher = false;
    if (
      sessionStore.hasValidSession() &&
      !sessionStore.isStudent() &&
      !masqueradeStore.isMasquerading()
    ) {
      additionalSidebarMenuItems = additionalSidebarMenuItems.set('Discover', 'discover');
    }
    if (sessionStore.isTeacher()) {
      additionalSidebarMenuItems = additionalSidebarMenuItems.set(
        'Class Subjects',
        'class-subjects'
      );
      const schoolId = this.props.user.getTeacher().getSchool().getId();
      const isSchoolLicensed = this.props.user.getTeacher().getLicenseStatusForSchool(schoolId);
      // isUserLicensedTeacher refers to teachers being approved at the school AND the school having an active license
      isUserLicensedTeacher =
        this.props.user.getTeacher().getMeta().isLicensed() && isSchoolLicensed;
    }

    const isLoggedOutOrStudent = !sessionStore.hasValidSession() || sessionStore.isStudent();

    let subjectAreaContent;
    const contentRoute = this.props.route.content;
    if (contentRoute === 'discover') {
      subjectAreaContent = <ContentDiscovery isUserLicensedTeacher={isUserLicensedTeacher} />;
    } else if (contentRoute === 'class-subjects') {
      subjectAreaContent = <ClassSubjects isUserLicensedTeacher={isUserLicensedTeacher} />;
    }
    return (
      <div className='course-library'>
        <SubjectMenuRenderer
          className='course-library__subject-menu'
          longDirection='reverse'
          activePath={activePath}
          headerContent={
            <Heading className='u-pad-r_4' as='h1' size='l'>
              Course library
            </Heading>
          }
          headerProps={{justifyContent: 'flex-start'}}
          makeSidebarItemHref={this.makeSidebarItemHref}
          subgroupMenuContent={subgroupMenuContent}
          makeSubjectCardContent={this.makeSubjectCardContent}
          makeSubjectCardHref={this.makeSubjectCardHref}
          onSearchStringUpdate={this.onSearchStringUpdate}
          subjectAreaContent={subjectAreaContent}
          additionalSidebarMenuItems={additionalSidebarMenuItems}
          isUserLicensedTeacher={isUserLicensedTeacher}
          isLoggedOutOrStudent={isLoggedOutOrStudent}
        />
      </div>
    );
  }
}

export default withApplicationMetadata(
  awaitMandarkQueries(() => {
    const options = {
      queries: {}
    };
    if (sessionStore.hasValidSession()) {
      options.queries.user = getUserQuery();
    }
    return options;
  }, CourseLibrary),
  (props) => {
    const [domain = 'All Subjects', group, subgroup] = getActivePathFromPathname(
      props.location.pathname,
      '/subjects'
    );
    const parts = [domain, 'Practice', 'Albert'];

    if (group) {
      parts.unshift(group);
    }

    if (subgroup) {
      parts.unshift(subgroup);
    }
    return {
      title: parts.join(' | ')
    };
  }
);
