import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {setUpStore} from 'client/framework';
import FillInTheBlankQuestionStore from './FillInTheBlankQuestion.store';

export default class FillInTheBlankResponseStats extends React.Component {
  static propTypes = {
    question: ImmutablePropTypes.record,
    storeName: PropTypes.string
  };

  getStore() {
    return setUpStore(
      FillInTheBlankQuestionStore,
      this.props.storeName,
      this.props.question.getId()
    );
  }

  getChoice(choice, dropdownId) {
    const question = this.props.question;
    const choiceId = choice.getId();
    const isChosenOption = this.getStore().isChosenOption(choiceId, dropdownId);
    const isCorrectOption = question.getValidResponse().includes(choiceId);
    return (
      <div key={choiceId} className='fitb-question-response-stats__choice'>
        {isCorrectOption ? (
          <span className='fitb-question-response-stats__correct-answer fa fa-check-circle' />
        ) : null}
        {!isCorrectOption && isChosenOption ? (
          <span className='fitb-question-response-stats__incorrect-answer fa fa-times-circle' />
        ) : null}
        <span className='fitb-question-response-stats__choice-percentage'>
          {question.getPercentageSelectedInputValue(dropdownId, choiceId)}%
        </span>
        <span className='fitb-question-response-stats__choice-content'>{choice.getValue()}</span>
      </div>
    );
  }

  render() {
    /**
     * @todo: Consolidate this logic into a parent component that manages rendering of How Others Answered.
     */
    if (this.props.question.getTotalAnswerCount() < 50) {
      return null;
    }
    const dropdowns = this.props.question.getDropdowns().map((dropdown, index) => {
      const dropdownId = dropdown.getId();
      const choices = dropdown.getChoices().map((choice) => this.getChoice(choice, dropdownId));

      return (
        <div key={dropdownId} className='fitb-question-response-stats'>
          <div className='fitb-question-response-stats__item'>
            <div className='fitb-question-response-stats__header'>Dropdown {index + 1}</div>
            {choices}
          </div>
        </div>
      );
    });

    return (
      <div className='question-response-stats'>
        <div className='question-response-stats__header'>How Others Answered</div>
        {dropdowns}
      </div>
    );
  }
}
