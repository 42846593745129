import React from 'react';
import PropTypes from 'prop-types';
import {callTargetedAction} from 'client/framework';
import {QuestionModelV3} from 'resources/augmented/Question/QuestionModel.v3';

import TextHighlightPrompt from './TextHighlightPrompt.react';
import textHighlightQuestionActions from './TextHighlightQuestion.actions';

import './text-highlight-question.scss';

export default class TextHighlightInputArea extends React.Component {
  static propTypes = {
    isGuessCorrect: PropTypes.bool,
    isGuessSubmitted: PropTypes.bool,
    question: PropTypes.instanceOf(QuestionModelV3),
    questionId: PropTypes.string,
    storeName: PropTypes.string
  };

  static defaultProps = {
    storeName: 'textHighlightQuestionStore'
  };

  constructor(props) {
    super(props);
    this.setUpQuestion(this.props.question);
  }

  componentDidMount() {
    this.shouldClearSelection();
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (this.props.storeName !== nextProps.storeName) {
      this.setUpQuestion(this.props.question);
    }
  }

  componentDidUpdate() {
    this.shouldClearSelection();
  }

  shouldClearSelection() {
    if (
      this.props.question.getStore().isGuessSubmitted === true &&
      this.props.isGuessSubmitted === false
    ) {
      this.clearSelections();
    }
  }

  clearSelections() {
    callTargetedAction({
      name: textHighlightQuestionActions.CLEAR_SELECTIONS,
      targetStore: this.props.storeName
    });
  }

  setUpQuestion(question) {
    callTargetedAction({
      name: textHighlightQuestionActions.SET_UP_QUESTION,
      payload: {
        highlightPrompt: question.getHighlightPrompt(),
        preferredPermutation: question.getPreferredPermutation()
      },
      targetStore: this.props.storeName
    });
  }

  render() {
    return (
      <div className='text-highlight-question'>
        <h2>Highlight Answer(s) Below</h2>
        <TextHighlightPrompt
          storeName={this.props.storeName}
          question={this.props.question}
          isGuessCorrect={this.props.isGuessCorrect}
          isGuessSubmitted={this.props.isGuessSubmitted}
        />
      </div>
    );
  }
}
