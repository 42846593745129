import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default class StaticField extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    labelRightContent: PropTypes.node
  };

  render() {
    const wrapperClass = classnames('a-form-input', this.props.className);
    const label = this.props.label ? (
      <div className='a-form-input__label'>{this.props.label}</div>
    ) : null;
    return (
      <div className={wrapperClass}>
        <div className='a-form-input__label-wrapper'>
          {label}
          {this.props.labelRightContent}
        </div>
        <div className='a-form-input__static-element'>{this.props.value}</div>
      </div>
    );
  }
}
