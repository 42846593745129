// @flow
import {fromJS, Map} from 'immutable';
import {Store, destroyStore} from 'client/framework';
import questionsListActions from './shared/QuestionsList/QuestionsListActions';
import interactionEngineActions from 'client/InteractionEngineV2/InteractionEngineActions';
import {ieTypes} from 'client/InteractionEngineV2/interactionEngineConstants';
import sessionStore from 'client/Session/SessionStore';

class InteractionEngineStore extends Store {
  constructor(name: string) {
    super(name);
    this.initialData = fromJS({
      areStoresBootstrapped: false,
      sessionType: '',
      questionStoreNames: {},
      builtQuestionSets: {},
      isMobileQuestionAreaInView: false,
      isTeacherMode: true
    });
    this.setInitialData(this.initialData);

    /**
     * ---FIXME---
     * Wow, some more isomorphism stuff. Basically we end up in a weird state where question sets are built on the
     * server but not the client. Map comes up filled from the server causing stores not to be built in VanillaIE's
     * validateSession method. Results in a disconnect between state tree and store registry and you hit a bug where
     * the framework tries to destroy a store that is built but not registered. This is by no means the "right" fix,
     * but it'll stop the bleeding until we can revisit the whole store building management for the IE.
     */
    this.writeData('builtQuestionSets', new Map());
    this.handle(questionsListActions.SET_ACTIVE_QUESTION, this._showMobileQuestionArea);
    this.handle(interactionEngineActions.HIDE_MOBILE_QUESTION_AREA, this._hideMobileQuestionArea);
    this.handle(
      interactionEngineActions.RESET_ARE_STORES_BOOTSTRAPPED,
      this._resetAreStoresBootstrapped
    );
    this.handle(interactionEngineActions.RESET_STORE, this.resetStore);
    this.handle(interactionEngineActions.SET_BOOTSTRAPPED_STORES, this._setBootstrappedStores);
    this.handle(interactionEngineActions.SET_SESSION_TYPE, this._setSessionType);
    this.handle(interactionEngineActions.SHOW_MOBILE_QUESTION_AREA, this._showMobileQuestionArea);
    this.handle(interactionEngineActions.SET_TEACHER_MODE, this._setTeacherMode);
    this.handle(interactionEngineActions.WIPE_BUILT_QUESTION_STORES, this._wipeBuiltQuestionStores);
  }

  _wipeBuiltQuestionStores() {
    this.questionStoreNames.forEach((storeName) => {
      destroyStore(storeName);
    });
    this.writeData((store) =>
      store.set('questionStoreNames', Map()).set('builtQuestionSets', Map())
    );
  }

  _showMobileQuestionArea() {
    this.writeData('isMobileQuestionAreaInView', true);
  }

  _hideMobileQuestionArea() {
    this.writeData('isMobileQuestionAreaInView', false);
  }

  _setBootstrappedStores(builtStores: Map<string, *>) {
    this.writeData((store) => {
      return store
        .set(
          'questionStoreNames',
          this.questionStoreNames.merge(builtStores.get('questionStoreNames'))
        )
        .set(
          'builtQuestionSets',
          this.builtQuestionSets.merge(builtStores.get('builtQuestionSets'))
        )
        .set('areStoresBootstrapped', true);
    });
  }

  _resetAreStoresBootstrapped() {
    this.writeData('areStoresBootstrapped', false);
  }

  _setSessionType(type: string) {
    this.writeData('sessionType', type);
  }

  _setTeacherMode(status: boolean) {
    this.writeData('isTeacherMode', status);
  }

  get questionStoreNames(): Map<string, *> {
    return this.readData('questionStoreNames');
  }

  get builtQuestionSets(): Map<string, string> {
    return this.readData('builtQuestionSets');
  }

  get areStoresBootstrapped(): boolean {
    return this.readData('areStoresBootstrapped');
  }

  get isMobileQuestionAreaInView(): boolean {
    return this.readData('isMobileQuestionAreaInView');
  }

  get isStudentAssignmentSession(): boolean {
    return this.getSessionType() === ieTypes.ASSIGNMENT && sessionStore.isTeacher() === false;
  }

  get isTeacherAssignmentSession(): boolean {
    return this.getSessionType() === ieTypes.ASSIGNMENT && sessionStore.isTeacher();
  }

  get isVanillaIESession(): boolean {
    return this.getSessionType() === ieTypes.VANILLA_IE;
  }

  get isPracticeExamSession(): boolean {
    return this.getSessionType() === ieTypes.PRACTICE_EXAM;
  }

  get isPracticeExamEditorSession(): boolean {
    return this.getSessionType() === ieTypes.PRACTICE_EXAM_EDITOR;
  }

  get isAssignmentV2Session(): boolean {
    return this.getSessionType() === ieTypes.ASSIGNMENT_V2;
  }

  getSessionType(): string {
    return this.readData('sessionType');
  }

  getStoreNameForQuestionId(questionId: string): string {
    return this.readData(['questionStoreNames', questionId]);
  }

  isTeacherModeEnabled(): boolean {
    return sessionStore.hasTeacherAccess() && this.readData('isTeacherMode') === true;
  }
}

const interactionEngineStore = new InteractionEngineStore(
  'InteractionEngineStoreREMOVETHISLATERNEWNEWNEW'
);
export default interactionEngineStore;
