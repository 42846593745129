// @flow
import {Map} from 'immutable';
import {Store} from './framework';
import {appActions} from './AppActions';
import constants from './constants';

class AppStore extends Store {
  constructor(name: string) {
    super(name);
    this.initialData = Map({
      title: 'Albert | Learn by doing | Grades 5-12 reading, writing, math, science, and test prep',
      metaDescription:
        'Albert provides students with personalized learning experiences in core academic areas while providing educators with actionable data. Leverage world-class, standards aligned practice content for AP, Common Core, NGSS, SAT, ACT, and more.',
      routerProps: {}, // Purposely using a plain ol' object given that's how route props are passed to route components
      prevRouterProps: {}, // Ditto
      zone: null,
      windowInnerWidth: 0,
      isMobileView: false,
      isFooterVisible: true
    });
    this.setInitialData(this.initialData);
    this.handle(appActions.SET_ROUTER_PROPS, this._setRouterProps);
    this.handle(appActions.SET_IS_MOBILE_VIEW, this._setIsMobileView);
    this.handle(appActions.SET_ZONE, this._setZone);
    this.handle(appActions.RESET_METADATA, this._resetPageMetadata);
    this.handle(appActions.UPDATE_METADATA, this._updatePageMetadata);
    this.handle(appActions.SET_FOOTER_DISPLAY, this._setFooterDisplay);
  }

  _setRouterProps({routerProps, prevRouterProps}: {prevRouterProps: Object, routerProps: Object}) {
    this.writeDataNoEmit((store) => {
      return store.set('routerProps', routerProps).set('prevRouterProps', prevRouterProps);
    });
  }

  _setZone(zone: string) {
    this.writeDataNoEmit('zone', zone);
  }

  _setWindowInnerWidth(windowInnerWidth: number) {
    this.writeData('windowInnerWidth', windowInnerWidth);
  }

  _setIsMobileView(isMobileView: boolean) {
    this.writeData('isMobileView', isMobileView);
  }

  _resetPageMetadata(resetList: Array<string> = ['title', 'metaDescription']) {
    const pageMetaData = resetList.reduce((acc, key) => {
      acc[key] = this.initialData.get(key, null);
      return acc;
    }, {});
    this._updatePageMetadata(pageMetaData);
  }

  _updatePageMetadata(updatedMetaDataFields: Object = {}) {
    const pageMetaData = new Map({
      title: this.readData('title'),
      metaDescription: this.readData('metaDescription')
    })
      .merge(updatedMetaDataFields)
      .update('title', (title) => (title ? title.replace(/®/g, '') : ''));

    // clean this up
    this.writeData((store) => store.merge(pageMetaData));
  }

  _setFooterDisplay(isFooterVisible: boolean) {
    this.writeData('isFooterVisible', isFooterVisible);
  }

  getTitle(): string {
    return this.readData('title');
  }

  getMetaDescription(): string {
    return this.readData('metaDescription');
  }

  getZone(): string | null {
    return this.readData('zone');
  }

  isFooterVisible(): boolean {
    return this.readData('isFooterVisible');
  }

  isZone(zone: string): boolean {
    return zone === this.getZone();
  }

  isZoneDennis(): boolean {
    return this.isZone(constants.ZONES.DENNIS);
  }

  isZoneAlbert(): boolean {
    return this.isZone(constants.ZONES.ALBERT);
  }

  isMobileView(): boolean {
    return this.readData('isMobileView');
  }

  get routerProps(): Object {
    return this.readData('routerProps');
  }

  get prevRouterProps(): Object {
    return this.readData('prevRouterProps');
  }
}

export default new AppStore('AppStore');
