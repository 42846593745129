import React from 'react';
import {Heading} from '@albert-io/atomic';
import MarkdownRenderer from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';

interface Props {
  heading?: string;
  text: string;
}

export default function MarkdownBlock({heading, text}: Props) {
  return (
    <div className='u-mar-b_4'>
      {heading && (
        <Heading as='h2' size='s'>
          {heading}
        </Heading>
      )}
      <MarkdownRenderer text={text} />
    </div>
  );
}
