//@flow
import {List, fromJS} from 'immutable';
import QuestionListStore from 'client/InteractionEngineV2/shared/QuestionsList/QuestionsListStore';
import {getQuestionSetsQuery} from 'client/Dennis/Content/Queue/shared';
import {AuthoringQuestionSetModelV1} from 'resources/GeneratedModels/AuthoringQuestionSet/AuthoringQuestionSetModel.v1';
import questionEditorStore from 'client/EditPage/V2/QuestionEditor/QuestionEditor.store';
import queueResultsActions from './QueueResults.actions';

class QueueResultsStore extends QuestionListStore {
  constructor(name: string) {
    super(name);
    this.initialData = this.initialData.merge(
      fromJS({
        questionSetUnderEdit: null
      })
    );
    this.handle(
      queueResultsActions.SET_QUESTION_SET_UNDER_EDIT,
      this.setProperty('questionSetUnderEdit')
    );
    this.handle(queueResultsActions.SAVE_QUESTION_SET, this._saveQuestionSet);
  }

  getQuestionSets(): List<AuthoringQuestionSetModelV1> {
    const editorQuestion = questionEditorStore.getQuestion();
    let editorQuestionSet = questionEditorStore.getQuestionSet();
    // $FlowFixMe
    const questionSets: List<AuthoringQuestionSetModelV1> = getQuestionSetsQuery().getResource();
    if (editorQuestionSet && !editorQuestionSet.existsOnServer()) {
      // Totally new question set
      editorQuestionSet = editorQuestionSet.setField(
        'authoring_questions',
        List.of(editorQuestion)
      );
      return questionSets.unshift(editorQuestionSet);
    } else if (editorQuestion && !editorQuestion.existsOnServer()) {
      // New question in existing set
      const matchingQuestionSetIndex = questionSets.findIndex(
        (set) => set.getId() === editorQuestionSet.getId()
      );
      return questionSets.update(matchingQuestionSetIndex, (set) => {
        const questions = set.getQuestions();
        return set.setField('authoring_questions', questions.push(editorQuestion));
      });
    } else {
      return questionSets;
    }
  }

  _saveQuestionSet = this.withSavedPromise(async () => {
    try {
      await this.getQuestionSetUnderEdit().save();
      getQuestionSetsQuery().invalidateInterest();
    } catch (err) {
      logger.error('Error when changing question set name: %s', err.message || 'unknown error');
      throw err;
    }
  }, 'editQuestionSetPromise');

  getQuestionSetUnderEdit = (): AuthoringQuestionSetModelV1 | null =>
    this.readData('questionSetUnderEdit');

  getEditQuestionSetPromise = (): ?Promise<any> => this.readData('editQuestionSetPromise');
}

export default new QueueResultsStore('QueueResultsStore');
