import {createActions} from 'client/framework';

export default createActions(null, [
  'CLEAR_PENDING_SELECTION',
  'CLEAR_SELECTIONS',
  'END_SELECTION',
  'POTENTIAL_SELECTION',
  'REMOVE_SELECTION',
  'RESET_STORE',
  'START_SELECTION',
  'SET_UP_QUESTION',
  'SET_SHOULD_SET_SELECTION_ON_TOUCH_END'
]);
