import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import shallowEqual from 'shallowequal';
import makeDataProps from 'lib/makeDataProps';
import './inputs.scss';

export default class TextTypeInput extends React.Component {
  static propTypes = {
    autoCapitalize: PropTypes.string,
    autoComplete: PropTypes.string,
    autoCorrect: PropTypes.bool,
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    focusOnMount: PropTypes.bool,
    // eslint-disable-next-line react/no-unused-prop-types
    updateInputOnValueMismatch: PropTypes.bool,
    id: PropTypes.string,
    inputNodeRef: PropTypes.func,
    label: PropTypes.string,
    maxLength: PropTypes.number,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyUp: PropTypes.func,
    onPaste: PropTypes.func,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    tabIndex: PropTypes.number,
    textElementTagName: PropTypes.oneOf(['input', 'textarea']).isRequired,
    type: PropTypes.oneOf(['hidden', 'text', 'url', 'tel', 'email', 'password', 'search']),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  };

  static defaultProps = {
    autoCapitalize: 'none',
    autoCorrect: false,
    autoComplete: 'on',
    autoFocus: false,
    className: '',
    disabled: false,
    error: false,
    errorMessage: 'Please enter correct data',
    focusOnMount: false,
    updateInputOnValueMismatch: true,
    inputNodeRef: () => {},
    name: '',
    placeholder: '',
    readOnly: false,
    required: false,
    tabIndex: 0,
    type: 'text',
    value: ''
  };

  componentDidMount() {
    if (this.props.focusOnMount) {
      this.inputNode.focus();
    }
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.value !== this.inputNode.value || !shallowEqual(this.props, nextProps);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.updateInputOnValueMismatch && nextProps.value !== this.inputNode.value) {
      this.inputNode.value = nextProps.value;
    }
  }

  render() {
    const hasError = this.props.error;

    const inputClass = classnames('a-form-input__element', {
      'a-form-input__element--error': hasError
    });
    const errorContainer = hasError ? (
      <div className='a-form-input__error'>{this.props.errorMessage}</div>
    ) : null;

    const label = this.props.label ? (
      <div className='a-form-input__label'>{this.props.label}</div>
    ) : null;
    const wrapperClass = classnames('a-form-input', this.props.className, {
      'a-form-input--required': this.props.required
    });
    const dataProps = makeDataProps(this.props);
    const TextElementTagName = this.props.textElementTagName;
    // Prevents ID from being passed unconditionally, which causes React 16 to warn of multiple components with same ID
    const idProp = this.props.id ? {id: this.props.id} : {};
    return (
      <label className={wrapperClass}>
        {label}
        <TextElementTagName
          ref={(node) => {
            this.inputNode = node;
            this.props.inputNodeRef(node);
          }}
          {...idProp}
          {...dataProps}
          className={inputClass}
          type={this.props.type}
          name={this.props.name}
          autoCapitalize={this.props.autoCapitalize ? 'on' : 'off'}
          autoCorrect={this.props.autoCorrect ? 'on' : 'off'}
          autoComplete={this.props.autoComplete ? 'on' : 'off'}
          required={this.props.required}
          maxLength={this.props.maxLength}
          placeholder={this.props.placeholder}
          disabled={this.props.disabled}
          autoFocus={this.props.autoFocus}
          defaultValue={this.props.value}
          onChange={this.props.onChange}
          onFocus={this.props.onFocus}
          onBlur={this.props.onBlur}
          onKeyDown={this.props.onKeyDown}
          onKeyUp={this.props.onKeyUp}
          onPaste={this.props.onPaste}
          readOnly={this.props.readOnly}
          tabIndex={this.props.tabIndex}
          style={this.props.style}
        />
        {errorContainer}
      </label>
    );
  }
}
