import React from 'react';
import PropTypes from 'prop-types';
import {setUpStore, callTargetedAction} from 'client/framework';
import TextInput from 'generic/Forms/TextInput/TextInput';
import StaticField from 'generic/Forms/StaticField/StaticField.react';
import Button from 'sg/Button/Button.react';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';

import {questionPropType} from 'resources/Question/Question.model';

import PassageCorrectionPopover from '../shared/PassageCorrectionPopover.react';

import PassageCorrectionQuestionStore from './PassageCorrectionQuestion.store';
import passageCorrectionQuestionActions from './PassageCorrectionQuestion.actions';
import './passage-correction-question.scss';

export default class PassageCorrectionPreSubmissionRenderer extends React.Component {
  static propTypes = {
    storeName: PropTypes.string,
    questionId: PropTypes.string,
    question: questionPropType,
    onRenderWordFunc: PropTypes.func
  };

  getStore() {
    return setUpStore(PassageCorrectionQuestionStore, this.props.storeName, this.props.questionId);
  }

  callAction(name, payload) {
    const actionToCall = {
      name,
      targetStore: this.getStore().getName()
    };
    if (payload !== undefined) {
      actionToCall.payload = payload;
    }
    callTargetedAction(actionToCall);
  }

  handleClick = (e) => {
    const {regionIndex} = e.target.dataset;
    if (regionIndex === undefined) {
      return;
    }
    this.callAction(
      passageCorrectionQuestionActions.SET_ACTIVE_OPTION,
      parseInt(e.target.dataset.idx, 10)
    );
  };

  commitCorrectionChange = (updatedCorrection) => {
    this.callAction(passageCorrectionQuestionActions.COMMIT_CORRECTION_CHANGE, updatedCorrection);
  };

  handleCorrectionReset = () => {
    this.callAction(passageCorrectionQuestionActions.REMOVE_ACTIVE_OPTION_CORRECTION);
  };

  wordPropsFunc = (text, optionIndex) => {
    const store = this.getStore();
    const {question} = this.props;
    const matchedRegionIndex = question.getRegionKeyForOptionIndex(optionIndex);
    const activeOption = store.getActiveOption();
    const isActive = activeOption === optionIndex;
    const correctedText = store.getCorrections().hasIn([matchedRegionIndex, optionIndex])
      ? store.getCorrections().getIn([matchedRegionIndex, optionIndex])
      : text;
    return {
      isActive,
      isRangeEnd: isActive,
      isRangeStart: isActive,
      isCorrected: store.isOptionCorrected(optionIndex),
      matchedRegionIndex,
      isSelectable: question.isOptionIndexInteractive(optionIndex),
      onRenderWordFunc: this.props.onRenderWordFunc,
      correctedText
    };
  };

  render() {
    const store = this.getStore();
    const {question} = this.props;
    const activeIndex = store.hasActiveOption() ? store.getActiveOption() : null;
    return (
      <MarkdownRendererV2
        passageCorrection
        className='passage-correction-question__input-area'
        text={question.getUncorrectedText()}
        onClick={this.handleClick}
        wordPropsFunc={this.wordPropsFunc}
        popover={
          <Popover
            activeIndex={activeIndex}
            key={activeIndex}
            commitChange={this.commitCorrectionChange}
            correctedValue={store.getActiveCorrectedValue()}
            onReset={this.handleCorrectionReset}
            originalText={store.getActiveOriginalValue()}
          />
        }
      />
    );
  }
}

class Popover extends React.Component {
  static propTypes = {
    commitChange: PropTypes.func,
    correctedValue: PropTypes.string,
    onReset: PropTypes.func,
    originalText: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      pendingCorrectionValue: props.correctedValue
    };
  }

  updateCorrection = (e) => {
    this.setState({
      pendingCorrectionValue: e.target.value
    });
  };

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.handleCommitChange();
    }
  };

  handleCommitChange = () => {
    if (this.state.pendingCorrectionValue.length === 0) {
      return;
    }
    this.props.commitChange(this.state.pendingCorrectionValue);
  };

  render() {
    return (
      <PassageCorrectionPopover {...this.props}>
        <div className='passage-correction-popover__correction-wrapper'>
          <TextInput
            className='passage-correction-popover__input'
            label='Enter correction'
            value={this.state.pendingCorrectionValue}
            onChange={this.updateCorrection}
            onKeyDown={this.handleKeyDown}
          />
          <div className='passage-correction-popover__button-wrapper'>
            <Button
              small
              text='Change'
              onClick={this.handleCommitChange}
              disabled={this.state.pendingCorrectionValue.length === 0}
              disabledTooltipContent='The correction may not be empty'
              tooltipPosition='right'
            />
          </div>
        </div>
        <div className='passage-correction-popover__original-text'>
          <StaticField
            label='Original text'
            value={this.props.originalText}
            labelRightContent={
              <Button noPadding linkButton text='Reset' onClick={this.props.onReset} />
            }
          />
        </div>
      </PassageCorrectionPopover>
    );
  }
}
