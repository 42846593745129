/**
 * # TextArea
 *
 * A component that renders a textarea input field.
 *
 * ## Props extends InputHtmlAttributes<HTMLInputElement>
 *
 * - `as` (optional): The HTML element type to render as. Defaults to `textarea`.
 * - `className` (optional): Additional CSS class name for styling.
 * - `onBlur` (optional): Event handler for the blur event.
 * - `onFocus` (optional): Event handler for the focus event.
 * - `onMouseEnter` (optional): Event handler for the mouse enter event.
 * - `onMouseLeave` (optional): Event handler for the mouse leave event.
 * - `error` (optional): Indicates whether the textarea has an error.
 * - `size` (optional): The size of the textarea. Can be one of `'s'`, `'regular'`, or `'l'`.
 * - `success` (optional): Indicates whether the textarea is in a success state.
 * - `defaultValue` (optional): The default value of the textarea.
 * - `placeholder` (optional): The placeholder text for the textarea.
 * - `readOnly` (optional): Indicates whether the textarea is read-only.
 * - `type` (optional): The type of the textarea.
 * - `children` (optional): The content to render inside the textarea.
 * - `value` (optional): The value of the textarea.
 * - `...rest`: Any property of `InputHTMLAttributes<HTMLInputElement>`
 *
 * ## Usage
 *
 * ```tsx
 * import TextArea from './TextArea.react';
 *
 * const MyComponent = () => {
 *   return (
 *     <div>
 *       <TextArea placeholder="Enter your message" />
 *     </div>
 *   );
 * };
 * ```
 */

import React from 'react';

import Input, {Props} from '../Input/Input.react';

export type TextAreaProps = Omit<Props, 'as'>;

export const TextArea = (props: TextAreaProps) => <Input as='textarea' {...props} />;

export default TextArea;
