import {MouseEvent} from 'react';
import {StateCreator} from 'zustand';
import {List, Map} from 'immutable';
import {addToast} from '@albert-io/atomic';
import {DraftAssignmentModelV1, QuestionSetModelV1} from '@albert-io/models';

import sessionStore from 'client/Session/SessionStore';
import {
  getDraftQuery,
  getDraftQuestionSetsQuery,
  getDraftQuestionsRelationshipQuery
} from 'client/components/ContentDiscovery/ContentDiscovery.queries';
import type {ContentDiscoveryStoreState} from 'client/components/ContentDiscovery/store/ContentDiscoveryStore.types';
import {genericMandarkRequest, invalidatePartialInterest} from 'resources/mandark.resource';

type DraftState = {
  draftId: string;
  draftName: string;
  draftNameError: boolean;
  draftAssignment: DraftAssignmentModelV1 | null;
  draftSort: string | null;
  draftQuestionsQuery: any;
  draftHasChanges: boolean;
  draftHasPendingChanges: boolean;
  draftHasSavedDuringSession: boolean;
  questionSetInDraftIds: List<string>;
  questionCount: number;
  autoSave: boolean;
  draftSaveError: any | null;
  selectedQuestionSets: List<QuestionSetModelV1>;
};

type DraftActions = {
  setDraftId: (id: string) => void;
  setDraftName: (name: string) => void;
  setDraftNameError: (error: boolean) => void;
  setDraftSort: (sort: string) => void;
  setDraftHasChanges: (hasChanges: boolean) => void;
  setDraftHasSavedDuringSession: (hasSavedDuringSession: boolean) => void;
  setDraftQuestionsQuery: (query: any) => void;
  setDraftSaveError: (error: any) => void;
  updateDraftName: (name: string) => void;
  updateDraftQuestionSets: (sets: List<QuestionSetModelV1>, name?: string) => Promise<void>;
  removeQuestionSet: (
    e: MouseEvent<HTMLElement>,
    questionSet: QuestionSetModelV1 | List<QuestionSetModelV1>,
    onSuccess?: () => void
  ) => Promise<void>;
  fetchDraftData: () => Promise<void>;
  isQuestionSetSelected: (questionSet: QuestionSetModelV1) => boolean;
  setSelectedQuestionSets: (questionSets: List<QuestionSetModelV1>) => void;
  addSelectedQuestionSets: (questionSets: List<QuestionSetModelV1>) => void;
  getTotalQuestionCount: () => number;
  updateDraftSortOnQuery: () => void;
  reset: () => void;
};

const initialState: DraftState = {
  draftId: '',
  draftName: '',
  draftNameError: false,
  draftAssignment: null,
  draftSort: null,
  draftQuestionsQuery: null,
  draftHasChanges: false,
  draftHasPendingChanges: false,
  draftHasSavedDuringSession: false,
  questionSetInDraftIds: List(),
  questionCount: 0,
  autoSave: false,
  draftSaveError: null,
  selectedQuestionSets: List()
};

export type DraftSlice = DraftState & DraftActions;

// add type guard to isList function
const isList = <T>(item: T | List<T>): item is List<T> => List.isList(item as List<T>);

const createRelationshipList = (relationshipQuestionSetsMap): List<QuestionSetModelV1> =>
  relationshipQuestionSetsMap.get('data').reduce((acc, item) => {
    const newList = acc.push(item.get('id'));
    return newList;
  }, List());

export const createDraftSlice: StateCreator<
  ContentDiscoveryStoreState,
  [['zustand/immer', never]],
  [],
  DraftSlice
> = (set, get): DraftSlice => ({
  ...initialState,

  setDraftId: (id) =>
    set((state) => {
      state.draft.draftId = id;
    }),

  setDraftName: (name) =>
    set((state) => {
      state.draft.draftName = name;
    }),

  setDraftNameError: (error) =>
    set((state) => {
      state.draft.draftNameError = error;
    }),

  setDraftSort: (sort) =>
    set((state) => {
      state.draft.draftSort = sort;
    }),

  setDraftHasChanges: (hasChanges) =>
    set((state) => {
      state.draft.draftHasChanges = hasChanges;
    }),

  setDraftHasSavedDuringSession: (hasSavedDuringSession) =>
    set((state) => {
      state.draft.draftHasSavedDuringSession = hasSavedDuringSession;
    }),

  setDraftQuestionsQuery: (query) =>
    set((state) => {
      state.draft.draftQuestionsQuery = query;
    }),

  setDraftSaveError: (error) =>
    set((state) => {
      state.draft.draftSaveError = error;
    }),

  setSelectedQuestionSets: (questionSets) =>
    set((state) => {
      state.draft.selectedQuestionSets = questionSets;
    }),

  addSelectedQuestionSets: (questionSets) =>
    set((state) => {
      state.draft.selectedQuestionSets = List(
        state.draft.selectedQuestionSets.concat(questionSets)
      );
    }),

  updateDraftName: async (name) => {
    if (!name) {
      return;
    }

    const {draftAssignment, draftId} = get().draft;

    let promiseResponse;

    try {
      set((state) => {
        state.draft.draftHasPendingChanges = true;
      });

      if (draftAssignment?.existsOnServer()) {
        await draftAssignment?.setName(name).save();
      } else {
        const draftObject = draftAssignment || new DraftAssignmentModelV1();
        promiseResponse = await draftObject
          .setName(name)
          .addRelationship({
            type: 'teacher_v1',
            relation: sessionStore.getUserId()
          })
          .save();

        set((state) => {
          state.draft.draftId = promiseResponse.getId();
        });
      }

      // todo: could consolidate to function in provider
      invalidatePartialInterest({
        resourcePath: getDraftQuestionSetsQuery(draftId || promiseResponse.getId()).done()
          .resourcePath
      });

      // needed for invalidating draft list
      invalidatePartialInterest({
        resourcePath: ['draft_assignments_v1']
      });

      set((state) => {
        state.draft.draftHasChanges = true;
        state.draft.draftHasSavedDuringSession = true;
        state.draft.draftSaveError = false;
      });
    } catch (e) {
      addToast({message: 'something went wrong saving the draft', color: 'negative'});
      set((state) => {
        state.draft.draftSaveError = e;
      });
    } finally {
      set((state) => {
        state.draft.draftHasPendingChanges = false;
      });
    }
  },

  updateDraftSortOnQuery: async () => {
    const {draftSort, draftAssignment, draftQuestionsQuery} = get().draft;
    if (!draftAssignment) {
      set((state) => {
        state.draft.draftQuestionsQuery = null;
      });
    } else {
      const baseQuery = draftQuestionsQuery
        ? draftQuestionsQuery.unset('customQuery.sort')
        : getDraftQuestionSetsQuery(draftAssignment.getId());
      const newQuery = draftSort ? baseQuery.sort(draftSort) : baseQuery;
      set((state) => {
        state.draft.draftQuestionsQuery = newQuery;
      });
    }
  },

  // Updates the draft model
  updateDraftQuestionSets: async (sets, name = 'Unnamed draft') => {
    const questionSetCustomMeta = sets.reduce<Map<string, {position?: number}>>(
      (acc, questionSet, index) => {
        if (questionSet) {
          return acc!.set(questionSet.getId(), {position: index});
        }
        return acc as Map<string, {position: number}>;
      },
      Map()
    );

    const {draftAssignment, draftId} = get().draft;

    try {
      set((state) => {
        state.draft.draftHasPendingChanges = true;
        state.draft.questionCount += List(sets).size;
      });

      let promiseResponse;
      let relationshipQuestionSetsMap;

      if (draftAssignment?.existsOnServer()) {
        await draftAssignment.updateRelationship('question_sets_v1', sets).save();
        relationshipQuestionSetsMap = await getDraftQuestionsRelationshipQuery(draftId);
      } else {
        const draftObject = draftAssignment || new DraftAssignmentModelV1();
        promiseResponse = await draftObject
          .setName(draftAssignment?.getName() || name)
          .updateRelationship('question_sets_v1', sets, true, questionSetCustomMeta)
          .addRelationship({type: 'teacher_v1', relation: sessionStore.getUserId()})
          .save();

        set((state) => {
          state.draft.draftId = promiseResponse.getId();
        });

        relationshipQuestionSetsMap = await getDraftQuestionsRelationshipQuery(
          promiseResponse.getId()
        );
      }

      set((state) => {
        state.draft.questionSetInDraftIds = createRelationshipList(relationshipQuestionSetsMap);
      });

      invalidatePartialInterest({
        resourcePath: getDraftQuestionSetsQuery(draftId || promiseResponse?.getId()).done()
          .resourcePath
      });

      // needed for invalidating draft list
      invalidatePartialInterest({resourcePath: ['draft_assignments_v1']});

      set((state) => {
        state.draft.draftHasChanges = true;
        state.draft.draftHasSavedDuringSession = true;
        state.draft.draftSaveError = null;
      });
    } catch (error) {
      if (draftAssignment) {
        // revert to last saved draft question count on fail
        set((state) => {
          state.draft.questionCount = draftAssignment?.getMeta()?.getCountOfQuestions() || 0;
        });
      }

      addToast({message: 'something went wrong saving the draft', color: 'negative'});

      set((state) => {
        state.draft.draftSaveError = error;
      });
    } finally {
      set((state) => {
        state.draft.draftHasPendingChanges = false;
      });
    }
  },

  removeQuestionSet: async (e, questionSet, onSuccess = () => {}) => {
    e.stopPropagation();

    const {draft, save} = get();
    const {autoSave} = save;
    const {selectedQuestionSets, draftAssignment, draftId} = draft;

    if (!autoSave) {
      let filteredQuestionSets;
      if (isList<QuestionSetModelV1>(questionSet)) {
        const ids = questionSet.toArray().map((qSet) => qSet!.getId());
        // for each question set remove
        filteredQuestionSets = selectedQuestionSets.filter(
          (selectedQSet) => !ids.some((id) => id === (selectedQSet!.getId() as string))
        );
      } else {
        filteredQuestionSets = selectedQuestionSets.filter(
          (item) => item!.getId() !== questionSet.getId()
        );
      }
      set((state) => {
        state.draft.selectedQuestionSets = List(filteredQuestionSets);
      });
      return;
    }

    let payload;
    let errorMessage = 'An issue occurred trying to remove this question.';

    if (isList<QuestionSetModelV1>(questionSet)) {
      payload = {
        data: questionSet.map((question) => ({type: 'question_sets_v1', id: question!.getId()}))
      };
      errorMessage = 'An issue occured try to remove all questions';
    } else {
      payload = {
        data: [
          {
            type: 'question_sets_v1',
            id: questionSet.getId()
          }
        ]
      };
    }

    try {
      set((state) => {
        state.isLoading = true;
        state.draft.draftHasPendingChanges = true;
        state.draft.questionCount = state.draft.questionCount - payload.data.length;
      });

      await genericMandarkRequest(
        'delete',
        {
          resourcePath: [
            'draft_assignments_v1',
            draftAssignment.getId(),
            'relationships',
            'question_sets_v1'
          ]
        },
        payload
      );

      invalidatePartialInterest({
        resourcePath: getDraftQuestionSetsQuery(draftAssignment.getId()).done().resourcePath
      });
      invalidatePartialInterest({
        resourcePath: getDraftQuery(draftAssignment.getId()).done().resourcePath
      });

      onSuccess();

      set((state) => {
        state.draft.draftQuestionsQuery = getDraftQuestionSetsQuery(draftId);
        state.draft.draftHasChanges = true;
        state.draft.draftHasSavedDuringSession = true;
        state.draft.draftSaveError = null;
      });
    } catch (e) {
      if (draftAssignment) {
        set((state) => {
          state.draft.questionCount = draftAssignment.getMeta().getCountOfQuestions();
        });
      }

      addToast({
        title: 'error',
        message: errorMessage,
        color: 'negative'
      });

      set((state) => {
        state.draft.draftSaveError = e;
      });
    } finally {
      set((state) => {
        state.isLoading = false;
        state.draft.draftHasPendingChanges = false;
      });
    }
  },

  fetchDraftData: async () => {
    try {
      const {draftId} = get().draft;

      if (!draftId) {
        return;
      }

      const draft = await getDraftQuery(draftId).getResourcePromise();
      const relationshipMap = await getDraftQuestionsRelationshipQuery(draftId);
      createRelationshipList(relationshipMap);

      set((state) => {
        state.draft.draftAssignment = draft;
        state.draft.questionCount = draft.getMeta().getCountOfQuestions();
        state.draft.draftHasChanges = false;
        state.draft.questionSetInDraftIds = createRelationshipList(relationshipMap);
      });
    } catch (error) {
      addToast({message: 'Something went wrong getting your draft.', color: 'negative'});
    }
  },

  isQuestionSetSelected: (questionSet) => {
    const {save, draft} = get();
    const {autoSave} = save;
    const {questionSetInDraftIds, selectedQuestionSets} = draft;

    if (autoSave) {
      return questionSetInDraftIds.some((id) => id === questionSet.getId());
    }
    return selectedQuestionSets.some(
      (selectedQSet) => selectedQSet!.getId() === questionSet.getId()
    );
  },

  getTotalQuestionCount: () => {
    const {draft, save} = get();
    const {autoSave} = save;
    const {draftAssignment, questionCount, selectedQuestionSets} = draft;

    if (autoSave && draftAssignment) {
      return questionCount;
    }
    return selectedQuestionSets.size;
  },

  reset: () =>
    set((state) => {
      state.draft = {...state.draft, ...initialState};
    })
});
