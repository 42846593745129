/**
 * # DatePicker
 *
 * A date picker component that allows users to select a date range.
 *
 * !!! note
 *     This component is used for selecting a date range with a start and end. If you need to select a single date, use `<Input type='date'>` instead.
 *
 * ## Props
 *
 * - `className?: string`: Additional CSS class name for the component.
 * - `excludeDates: DateRange[]`: An array of date ranges to exclude from selection.
 * - `onSubmit: (object: {range: string; start: moment.Moment; end: moment.Moment}) => void`: A callback function triggered when the date range is submitted.
 * - `options?: Immutable.List<DateRangeOption>`: A list of date range options to display in the dropdown.
 * - `value?: DateRangeOption`: The currently selected date range option.
 * - `defaultValue?: DateRangeOption`: The default selected date range option.
 *
 * ## Usage
 *
 * ```tsx
 * <DatePicker
 *   className="custom-datepicker"
 *   excludeDates={[{ start: '2022-01-01', end: '2022-01-10' }]}
 *   onSubmit={(dateRange) => console.log(dateRange)}
 *   options={dateRangeOptions}
 *   value={selectedDateRange}
 *   defaultValue={defaultDateRange}
 * />
 * ```
 */

import React, {useEffect, useContext, useState, useRef} from 'react';
import moment from 'moment-timezone';
import classnames from 'classnames';
import Immutable, {fromJS} from 'immutable';

import Chip from '../../atoms/Chip/Chip.react';
import Icon from '../../atoms/Icon/Icon.react';
import Text from '../../atoms/Text/Text.react';
import Button from '../../atoms/Button/Button.react';
import AtomicSelectField from '../../molecules/SelectField/SelectField.react';

import {dateRangeOptions, DateRange, DateRangeOption} from './DatePicker.helpers';
import './date-picker.scss';

// @todo do we still need this for safari?
if (process.env.IS_BROWSER) {
  /**
   * HTML5 `<input type="date">` polyfill for Safari
   */
  /* eslint-disable-next-line no-unused-expressions */
  import('date-input-polyfill');
}

interface InvalidDateError {
  validationError: string | null;
  invalidDates: string[];
}

type GenericCallback = (...args: any[]) => any;

interface State {
  range?: string;
  start?: string;
  dateRanges: Immutable.List<DateRangeOption>;
  end?: string;
  error?: InvalidDateError | null;
  handleSelectChange: (e: React.ChangeEvent<HTMLSelectElement>, callback: GenericCallback) => void;
  handleStartChange: (e: React.ChangeEvent<HTMLInputElement>, callback: GenericCallback) => void;
  handleEndChange: (e: React.ChangeEvent<HTMLInputElement>, callback: GenericCallback) => void;
  handleSubmit: (e: React.ChangeEvent<HTMLButtonElement>, callback: GenericCallback) => void;
  hasStartDateError?: boolean;
  hasEndDateError?: boolean;
  setRange?: React.Dispatch<React.SetStateAction<DateRange>>;
  setStart?: React.Dispatch<React.SetStateAction<moment.Moment>>;
  setEnd?: React.Dispatch<React.SetStateAction<moment.Moment>>;
  setError?: React.Dispatch<React.SetStateAction<InvalidDateError | null>>;
}

const DatePickerContext = React.createContext<State>(undefined!);

type RangeState = DateRange;

function validateDateRanges({allowNull = false, start, end}): InvalidDateError {
  if ((!start || !end) && !allowNull) {
    return {
      validationError: 'Please enter a date.',
      invalidDates: [...(!start ? ['start'] : []), ...(!end ? ['end'] : [])]
    };
  }

  let isValid = true;
  if (start && end) {
    const startMoment = moment(start, ['YYYY-MM-DD', moment.ISO_8601], true).tz(moment.tz.guess());
    const endMoment = moment(end, ['YYYY-MM-DD', moment.ISO_8601], true).tz(moment.tz.guess());

    // Check if both dates are valid
    if (!startMoment.isValid() || !endMoment.isValid()) {
      return {
        validationError: 'Please enter valid dates.',
        invalidDates: [
          ...(!startMoment.isValid() ? ['start'] : []),
          ...(!endMoment.isValid() ? ['end'] : [])
        ]
      };
    }

    // Check if start is before end
    isValid = startMoment.startOf('day').isBefore(endMoment.endOf('day'));
  } else if (!allowNull) {
    isValid = false;
  }

  if (!isValid) {
    return {
      validationError: 'Please enter a valid date range.',
      invalidDates: ['start', 'end']
    };
  }

  return {
    validationError: null,
    invalidDates: []
  };
}

const defaultDateRanges = dateRangeOptions.push(
  fromJS({
    value: 'Custom',
    id: 'Custom',
    start: '',
    end: ''
  })
);

interface DatePickerProps extends PropsWithClassNameOptional {
  excludeDates: DateRange[];
  onSubmit: (object: {range: string; start: moment.Moment; end: moment.Moment}) => void;
  options?: Immutable.List<DateRangeOption>;
  value?: DateRangeOption;
  defaultValue?: DateRangeOption;
}

function DatePicker({
  className,
  options = defaultDateRanges, // @todo add support for custom options @see @/albert-io/atomic/organisms/DatePicker/DatePicker.stories.js#L45
  excludeDates = [],
  onSubmit = () => {},
  value,
  defaultValue
}: DatePickerProps) {
  return (
    <DatePicker.Provider
      className={className}
      excludeDates={excludeDates}
      onSubmit={onSubmit}
      options={options}
      value={value}
      defaultValue={defaultValue}
    >
      <DatePicker.SelectField className='u-mar-b_2' label='Date range' />
      <div>
        <DatePicker.StartDateField />
        <DatePicker.ArrowIcon className='u-mar-lr_1' />
        <DatePicker.EndDateField />
      </div>
      <DatePicker.ErrorMsg />
      <DatePicker.ApplyBtn className='u-width_100pc u-mar-t_2' />
    </DatePicker.Provider>
  );
}

interface DateInputProps {
  label: string;
  name: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  error?: boolean;
}

function DateInput({label, name, onChange = () => {}, value, error, ...rest}: DateInputProps) {
  const dateInputEl: React.MutableRefObject<HTMLInputElement | null> = useRef(null);

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (dateInputEl.current?.hasAttribute('data-has-picker')) {
      onChange(e);
    }
  };

  return (
    <label className='o-date-picker__label'>
      <span className='sr-only'>{label}</span>
      <Chip
        as='input'
        ref={dateInputEl}
        className='o-date-picker__input'
        color={error ? 'negative' : 'brand'}
        type='date'
        date-format='yyyy-mm-dd'
        placeholder='yyyy/mm/dd'
        name={name}
        onBlur={handleBlur}
        onChange={onChange}
        value={value}
        {...rest}
      />
    </label>
  );
}

interface ProviderProps extends PropsWithChildrenRequired, PropsWithClassNameOptional {
  excludeDates: string[];
  onSubmit: (object: {range: string; start: moment.Moment; end: moment.Moment}) => void;
  options: Immutable.List<DateRangeOption>;
  value?: DateRangeOption;
  defaultValue?: DateRangeOption;
}

DatePicker.Provider = function Provider({
  children,
  className,
  excludeDates = [],
  onSubmit = () => {},
  options = defaultDateRanges, // @todo add support for custom options @see @/albert-io/atomic/organisms/DatePicker/DatePicker.stories.js#L45
  value,
  defaultValue
}: ProviderProps) {
  const dateRanges: Immutable.List<DateRangeOption> = options
    .filter((date) => {
      if (date === undefined) {
        return true;
      }
      return excludeDates.includes(date.get('value')) === false;
    })
    .toList();

  const [range, setRange] = useState<RangeState>(dateRanges.first().get('id'));
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [error, setError] = useState<InvalidDateError | null>(null);

  const handleSelectChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    onSelectCallback: (...args: any[]) => any
  ) => {
    e.persist();
    const selectedRange = dateRanges.find((date) => date?.get('value') === e.target.value);
    setRange(selectedRange.get('id'));
    setStart(selectedRange.get('start'));
    setEnd(selectedRange.get('end'));

    if (error?.validationError) {
      setError(null);
    }

    if (typeof onSelectCallback === 'function') {
      onSelectCallback(e, error);
    }
  };

  const handleStartChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    onStartChangeCallback: (...args: any[]) => any
  ) => {
    setStart(e.target.value);
    if (range !== 'Custom' && e.target.value !== start) {
      setRange('Custom');
    }

    if (error?.validationError) {
      setError(null);
    }

    if (typeof onStartChangeCallback === 'function') {
      onStartChangeCallback(e, error);
    }
  };

  const handleEndChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    onEndChangeCallback: (...args: any[]) => any
  ) => {
    e.persist();
    setEnd(e.target.value);
    if (range !== 'Custom' && e.target.value !== end) {
      setRange('Custom');
    }

    if (error?.validationError) {
      setError(null);
    }

    if (typeof onEndChangeCallback === 'function') {
      onEndChangeCallback(e, error);
    }
  };

  const handleSubmit = (
    e: React.ChangeEvent<HTMLButtonElement>,
    onSubmitCallback: (...args: any[]) => any
  ) => {
    e.persist();
    // Clear any existing errors first
    if (error?.validationError) {
      setError(null);
    }

    const {validationError, invalidDates} = validateDateRanges({
      allowNull: range === 'All-time',
      start,
      end
    });

    if (validationError) {
      setError({validationError, invalidDates});
    } else {
      onSubmit({range, start, end});
      if (typeof onSubmitCallback === 'function') {
        onSubmitCallback(e, {range, start, end}, {validationError, invalidDates});
      }
    }
  };

  const hasStartDateError = error ? error.invalidDates.includes('start') : false;
  const hasEndDateError = error ? error.invalidDates.includes('end') : false;

  const [didMount, setDidMount] = useState(false);

  useEffect(() => {
    if (!didMount && defaultValue !== undefined) {
      setRange(defaultValue.get('id'));
      setStart(defaultValue.get('start'));
      setEnd(defaultValue.get('end'));
      setDidMount(true);
    }
  }, [defaultValue, didMount]);

  useEffect(() => {
    if (value !== undefined) {
      setRange(value.get('id'));
      setStart(value.get('start'));
      setEnd(value.get('end'));
    }
  }, [value]);

  return (
    <DatePickerContext.Provider
      value={{
        dateRanges,
        range,
        start,
        end,
        error,
        handleSelectChange,
        handleStartChange,
        handleEndChange,
        handleSubmit,
        hasStartDateError,
        hasEndDateError,
        setRange,
        setStart,
        setEnd,
        setError
      }}
    >
      <div
        className={classnames('o-date-picker u-display_flex u-flex-direction_column', className)}
      >
        {children}
      </div>
    </DatePickerContext.Provider>
  );
};

interface SelectFieldProps extends PropsWithClassNameOptional {
  label?: string;
  onChange?: GenericCallback;
}

DatePicker.SelectField = function SelectField({
  className,
  label,
  onChange = () => {}
}: SelectFieldProps) {
  const context = useContext(DatePickerContext);
  if (!context) {
    throw Error('DatePicker.SelectField requires DatePicker.Provider as a parent');
  }

  const {dateRanges, handleSelectChange, range} = context;

  return (
    <AtomicSelectField
      className={className}
      value={range}
      label={label}
      options={dateRanges.toJS()}
      onChange={(e) => handleSelectChange(e, onChange)}
    />
  );
};

DatePicker.StartDateField = function StartDateField({
  onChange = () => {},
  ...rest
}: {
  onChange?: GenericCallback;
}) {
  const context = useContext(DatePickerContext);
  if (!context) {
    throw new Error('DatePicker.SelectField requires DatePicker.Provider as a parent');
  }

  const {handleStartChange, hasStartDateError, start} = context;

  return (
    <DateInput
      value={start}
      label='Start date'
      name='start-date'
      onChange={(e) => handleStartChange(e, onChange)}
      error={hasStartDateError}
      {...rest}
    />
  );
};

DatePicker.EndDateField = function EndDateField({
  onChange = () => {},
  ...rest
}: {
  onChange?: GenericCallback;
}) {
  const context = useContext(DatePickerContext);
  if (!context) {
    throw new Error('DatePicker.SelectField requires DatePicker.Provider as a parent');
  }

  const {handleEndChange, hasEndDateError, end} = context;

  return (
    <DateInput
      value={end}
      label='End date'
      name='end-date'
      onChange={(e) => handleEndChange(e, onChange)}
      error={hasEndDateError}
      {...rest}
    />
  );
};

DatePicker.ArrowIcon = function ArrowIcon({className}: PropsWithClassNameRequired) {
  return <Icon aria-hidden className={className} icon='arrow-right' iconStyle='regular' />;
};

DatePicker.ErrorMsg = function ErrorMsg() {
  const context = useContext(DatePickerContext);
  if (!context) {
    throw new Error('DatePicker.SelectField requires DatePicker.Provider as a parent');
  }

  const {error} = context;

  return error?.validationError ? (
    <Text as='div' className='u-mar-t_2' color='negative'>
      {error.validationError}
    </Text>
  ) : null;
};

DatePicker.ApplyBtn = function ApplyBtn({
  className,
  onClick = () => {},
  ...rest
}: {
  onClick?: GenericCallback;
} & PropsWithClassNameOptional) {
  const context = useContext(DatePickerContext);
  if (!context) {
    throw new Error('DatePicker.ApplyBtn requires DatePicker.Provider as a parent');
  }

  const {handleSubmit, range, start, end, error} = context;

  return (
    <Button
      className={className}
      /* Included for tracking purposes @see albert-io/project-management#5447  */
      data-range={range}
      data-start={start}
      data-end={end}
      data-valid={error?.validationError ? 'true' : 'false'}
      onClick={(e) => handleSubmit(e, onClick)}
      {...rest}
    >
      Apply
    </Button>
  );
};

export default DatePicker;
