import React from 'react';
import PropTypes from 'prop-types';
import {debounce} from 'lodash';
import {SearchInput} from '@albert-io/atomic';

export class KeywordSearch extends React.Component {
  static propTypes = {
    /** The callback passed to our change handler's setTimeout. Expects a string. */
    onChange: PropTypes.func
  };

  handleChange = (e) => this.debouncedHandleChange(e.target.value);

  debouncedHandleChange = debounce((searchString) => this.props.onChange(searchString), 750);

  render() {
    const {
      /* eslint-disable-next-line no-unused-vars */
      onChange,
      ...rest
    } = this.props;

    return (
      <SearchInput
        {...rest}
        data-testid='advanced-search__search-input'
        onChange={this.handleChange}
      />
    );
  }
}
