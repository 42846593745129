import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {attemptState, attemptTitle} from './PreviousAttemptsSingleAttempt.util';
import './previous-attempts-single-attempt.scss';

export default class PreviousAttemptsSingleAttempt extends React.Component {
  static propTypes = {
    isActive: PropTypes.bool.isRequired,
    state: PropTypes.oneOf(Object.values(attemptState)),
    style: PropTypes.object
  };

  static defaultProps = {
    isActive: true
  };

  render() {
    const isActive = this.props.isActive;
    const style = this.props.style;
    const state = this.props.state;
    const isCorrect = state === attemptState.CORRECT;
    const isIncorrect = state === attemptState.INCORRECT;
    const isUsed = state === attemptState.USED;
    const isNotUsed = state === attemptState.NOT_USED;
    const previousAttemptStyle = style ? style : {};
    const previousAttemptContentTitle = attemptTitle[state];
    const previousAttemptContentTitleActive = isActive ? previousAttemptContentTitle : '';
    const previousAttemptClass = classnames({
      'previous-attempts-single-attempt': true,
      'previous-attempts-single-attempt--active': isActive,
      'previous-attempts-single-attempt--correct': isCorrect,
      'previous-attempts-single-attempt--incorrect': isIncorrect,
      'previous-attempts-single-attempt--used': isUsed,
      'previous-attempts-single-attempt--not-used': isNotUsed
    });
    const previousAttemptContentClass = classnames({
      'previous-attempts-single-attempt__icon': true,
      'fa fa-check': isCorrect,
      'fa fa-times': isIncorrect
    });
    const previousAttemptContent =
      isCorrect || isIncorrect ? <i className={previousAttemptContentClass} /> : null;

    return (
      <div
        className={previousAttemptClass}
        style={previousAttemptStyle}
        title={previousAttemptContentTitleActive}
      >
        {previousAttemptContent}
      </div>
    );
  }
}
