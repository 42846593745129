import React from 'react';
import classnames from 'classnames';

const TableHead = ({className, ...rest}: PropsWithClassNameOptional) => {
  return (
    <thead className={classnames('o-table__table-header', 'u-display_flex', className)} {...rest} />
  );
};

export default TableHead;
