import React, {RefObject, useCallback, useEffect, useState} from 'react';
import {List} from 'immutable';
import {
  Button,
  DropdownItem,
  Icon,
  MenuListItem,
  MenuListItemRegularText,
  Text,
  WindowSizeConsumer
} from '@albert-io/atomic';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';

import {GuideLevelModelV2} from '@albert-io/models';
import {isSearchMode} from 'client/components/AdvancedSearch/AdvancedSearch.utils';

import './thememenu.scss';

export const STICKY_SIDEBAR_OFFSET = 400;
export const SCROLL_PADDING = 160;

export type ThemeRefs = {
  [themeId: string]: RefObject<any>;
};

export const ThemeMenu = ({
  initialTheme,
  themes,
  themeRefs,
  hideSidebar
}: {
  initialTheme?: string;
  themes: List<GuideLevelModelV2>;
  themeRefs: ThemeRefs;
  hideSidebar: boolean;
}) => {
  if (!themes || themes.size === 0) {
    return null;
  }

  useEffect(() => {
    if (initialTheme) {
      themeRefs[initialTheme].current.scrollIntoView();
    }
  }, [initialTheme, themeRefs]);

  if (hideSidebar) {
    return null;
  }

  return <ThemeSidebar themes={themes} themeRefs={themeRefs} />;
};

const ThemeSidebar = ({themes, themeRefs}: {themes: List<any>; themeRefs: ThemeRefs}) => {
  const [selectedThemeId, setSelectedThemeId] = useState(themes.first().getId());

  const handleScroll = useCallback(() => {
    const scrolledToTheme = Object.entries(themeRefs).reduce((selectedTheme, currentThemeRef) => {
      const [themeId, ref] = currentThemeRef;
      const refTop = ref.current.parentNode.getBoundingClientRect().top - SCROLL_PADDING;
      if (refTop <= 0) {
        return themeId;
      }
      return selectedTheme;
    }, selectedThemeId);
    setSelectedThemeId(scrolledToTheme);
  }, [selectedThemeId, themeRefs]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {passive: true});
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <div id='study-guide__sidebar' className='study-guide__sidebar u-pad-b_2 u-pad-t_1'>
      <Text className='u-mar-b_2 u-mar-l_2' size='s'>
        Contents:
      </Text>
      <ul className='u-pad_0 u-mar_0'>
        {themes.map((theme) => (
          <MenuListItem
            className='study-guide__menu-item'
            key={theme.getId()}
            text={theme.getName()}
            as='li'
            onMenuItemClick={() => {
              const ref = themeRefs[theme.getId()];
              ref.current.scrollIntoView({behavior: 'instant'});
            }}
            clickable
            selected={theme.getId() === selectedThemeId}
          >
            <MenuListItemRegularText
              className='u-font-weight_normal'
              selected={theme.getId() === selectedThemeId}
            >
              <MarkdownRendererV2 text={theme.getName()} />
            </MenuListItemRegularText>
          </MenuListItem>
        ))}
      </ul>
    </div>
  );
};

export const ThemeDropdown = ({
  themes,
  themeRefs,
  hideCTAText,
  onToggle,
  show
}: {
  themes: List<GuideLevelModelV2>;
  themeRefs: ThemeRefs;
  hideCTAText: boolean;
  onToggle: () => void;
  show: boolean;
}) => (
  <>
    <Button
      color='secondary'
      variant='outlined'
      className='study-guide-dropdown--mobile u-mar-r_1 u-white-space_nowrap'
      onClick={onToggle}
    >
      <Icon icon='list-ul' className='u-mar-r_1' />
      {!hideCTAText && 'Jump To...'}
      <Icon icon='caret-down' className='u-mar-l_1' />
    </Button>
    {show && (
      <div className='study-guide__dropdown-list'>
        {themes.map((theme) => (
          <DropdownItem
            className='study-guide__dropdown-item'
            noWrap={false}
            key={theme.getId()}
            onClick={() => {
              const ref = themeRefs[theme.getId()];
              // account for offset from heights of the stickied theme menu and search input if on
              const offset = isSearchMode() ? 154 : 92;
              ref.current.scrollIntoView({behavior: 'instant'});
              window.scrollBy(0, -offset);
              onToggle();
            }}
          >
            <Text>
              <MarkdownRendererV2 text={theme.getName()} />
            </Text>
          </DropdownItem>
        ))}
      </div>
    )}
  </>
);
