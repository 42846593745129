// @flow
import {fromJS} from 'immutable';
import {Store} from 'client/framework';
import type {QueryBuilder} from '@albert-io/json-api-framework/request/builder';
import {resource} from '@albert-io/json-api-framework/request/builder';
import {SubjectModelV2} from 'resources/GeneratedModels/Subject/SubjectModel.v2';

import upgradeCallToActionModalActions from './UpgradeCallToActionModalActions';

class UpgradeCallToActionStore extends Store {
  constructor(name) {
    super(name);
    this.initialData = fromJS({
      subjectId: ''
    });

    this.setInitialData(this.initialData);
    this.handle(upgradeCallToActionModalActions.SET_ACTIVE_SUBJECT_ID, this._setSubjectId);
  }

  _setSubjectId(subjectId) {
    this.writeData('subjectId', subjectId);
  }

  _getSubjectId(): string {
    return this.readData('subjectId');
  }

  hasSubjects(): boolean {
    return this._getSubjectId() && this.getSubjectQuery().isResourcePopulated();
  }

  getSubjectQuery(): QueryBuilder {
    return resource('subjects_v2')
      .mandarkEndpoint(['subjects_v2', this._getSubjectId()])
      .withMeta('subject_v2')
      .meta({
        context: {
          subject: {
            counts: true
          }
        }
      });
  }

  getSubject(): SubjectModelV2 {
    return this._getSubjectId() && this.hasSubjects()
      ? this.getSubjectQuery().getResource()
      : SubjectModelV2.getDefaultModel();
  }
}

export default new UpgradeCallToActionStore('UpgradeCallToActionStore');
