// @flow
import {resource, type QueryBuilder} from '@albert-io/json-api-framework/request/builder';

// eslint-disable-next-line import/prefer-default-export
export function getClassroomInvitationsQuery(studentEmail: string): QueryBuilder {
  return resource('classroom_invitations_v1')
    .mandarkEndpoint(['classroom_invitations_v1'])
    .include('classroom_v1.subjects_v2.curriculum_area_v1,classroom_v1.teachers_v1,issuer_v1')
    .filter({
      email: encodeURIComponent(studentEmail),
      accepted_at: {
        null: true
      },
      included: {
        classroom_v1: {
          status: 'active'
        }
      }
    });
}
