import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import moment from 'moment';
import {List} from 'immutable';
import {callTargetedAction, getStoreByName, setUpStore} from 'client/framework';
import {getResource} from 'resources/mandark.resource';
import sessionStore from 'client/Session/SessionStore';
import Tooltip from 'generic/Tooltip/Tooltip.react';
import {tooltipActions} from 'generic/Tooltip/Tooltip.actions';
import {makeGuessQuery} from 'resources/Guess/Guess.queries';
import LoadingIndicator from 'generic/LoadingIndicator.react';

import PreviousAttemptsSingleAttempt from './PreviousAttemptsSingleAttempt/PreviousAttemptsSingleAttempt.react';
import {attemptState} from './PreviousAttemptsSingleAttempt/PreviousAttemptsSingleAttempt.util';
import previousAttemptsActions from './PreviousAttempts.actions';
import PreviousAttemptsStore from './PreviousAttempts.store';
import {calcOpacityForSingleAttempt} from './PreviousAttempts.util';
import './previous-attempts.scss';

export default class PreviousAttempts extends React.Component {
  static propTypes = {
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    initialIsCorrectStatus: PropTypes.bool,
    storeName: PropTypes.string.isRequired,
    numberOfAttemptsToShow: PropTypes.number,
    showTooltip: PropTypes.bool,
    questionId: PropTypes.string,
    userId: PropTypes.string,
    onAttemptSelection: PropTypes.func,
    specificAttemptNumberToShow: PropTypes.string,
    tooltipHeader: PropTypes.string,
    disableAttemptLink: PropTypes.bool
  };

  static defaultProps = {
    storeName: 'PreviousAttemptsStore',
    numberOfAttemptsToShow: 3,
    showTooltip: false,
    tooltipHeader: 'All attempts',
    disableAttemptLink: false,
    onAttemptSelection: () => {}
  };

  componentWillUnmount() {
    callTargetedAction({
      name: previousAttemptsActions.RESET_STORE,
      targetStore: this.props.storeName
    });
  }

  getStore() {
    return setUpStore(PreviousAttemptsStore, this.props.storeName);
  }

  getTooltipStoreName() {
    return `TooltipStore${this.props.storeName}`;
  }

  getAttempts = () => {
    const {questionId} = this.props;
    const userId = this.props.userId || sessionStore.getUserId();
    if (!questionId || !userId) {
      return new List();
    }

    const guessesQuery = makeGuessQuery({
      questionId,
      userId
    });

    const {filter} = guessesQuery;
    const {specificAttemptNumberToShow} = this.props;
    if (specificAttemptNumberToShow) {
      filter.attempt_number = specificAttemptNumberToShow;
    }
    const {startDate} = this.props;
    const {endDate} = this.props;
    if (startDate || endDate) {
      filter.inserted_at = {};
    }
    if (startDate) {
      filter.inserted_at.greater_than_inclusive = startDate;
    }
    if (endDate) {
      filter.inserted_at.less_than_inclusive = endDate;
    }

    return getResource(guessesQuery)
      .toList()
      .sortBy((guess) => {
        return guess.getTimestampAsMoment().valueOf() * -1;
      });
  };

  getAttemptsByNumberOfAttemptsToShow() {
    const allAttempts = this.getAttempts();
    const numberOfAttemptsToShow = this.getAdjustedNumberOfAttemptsToShow();
    const activeAttemptIndex = this.getActiveAttemptIndex();
    const numberOfAttemptsBefore = Math.floor(numberOfAttemptsToShow / 2);
    const numberOfAttemptsAfter = numberOfAttemptsToShow - numberOfAttemptsBefore;
    let sliceBeginIndex = activeAttemptIndex - numberOfAttemptsBefore;
    let sliceEndIndex = activeAttemptIndex + numberOfAttemptsAfter;

    if (sliceBeginIndex <= 0) {
      sliceBeginIndex = 0;
      sliceEndIndex = numberOfAttemptsToShow;
    }

    if (sliceEndIndex >= allAttempts.size) {
      sliceBeginIndex = allAttempts.size - numberOfAttemptsToShow;
      sliceEndIndex = allAttempts.size;
    }

    return allAttempts.slice(sliceBeginIndex, sliceEndIndex);
  }

  getActiveAttemptIndexWithinAttemptsToShow() {
    const activeAttemptId = this.getStore().getActiveAttemptId();
    const attemptsByNumberToShow = this.getAttemptsByNumberOfAttemptsToShow();

    return attemptsByNumberToShow.findIndex((attempt) => {
      return attempt.getId() === activeAttemptId;
    });
  }

  getAdjustedNumberOfAttemptsToShow() {
    const {numberOfAttemptsToShow} = this.props;
    const allAttemptsCount = this.getAttempts().size;

    if (numberOfAttemptsToShow > allAttemptsCount) {
      return allAttemptsCount;
    }

    return numberOfAttemptsToShow;
  }

  getActiveAttemptIndex() {
    const activeAttemptId = this.getStore().getActiveAttemptId();
    const allAttempts = this.getAttempts();

    return allAttempts.findIndex((attempt) => {
      return attempt.getId() === activeAttemptId;
    });
  }

  render() {
    const {showTooltip} = this.props;
    const attemptsWrapperClassNames = classnames({
      'previous-attempts-wrapper': true,
      'previous-attempts-wrapper--show-tooltip': showTooltip
    });
    const singleAttemptsWrapperClassNames = classnames({
      'previous-attempts-single-attempts-wrapper': true,
      'previous-attempts-single-attempts-wrapper--show-tooltip': showTooltip
    });

    if (this.props.hasOwnProperty('initialIsCorrectStatus')) {
      const tooltipStoreName = this.getTooltipStoreName();
      const tooltipStore = getStoreByName(tooltipStoreName);
      const tooltipIsOpen = tooltipStore && tooltipStore.getIsOpen();
      const attempts = tooltipIsOpen ? this.getAttempts() : List();
      const activeAttemptIndex = tooltipIsOpen ? this.getActiveAttemptIndex() : null;
      const tooltipContent = this.props.showTooltip ? (
        <PreviousAttemptTooltip
          activeAttemptIndex={activeAttemptIndex}
          attempts={attempts}
          delayQuery
          disableCurrentlyViewing
          storeName={tooltipStoreName}
          previousAttemptsStore={this.getStore()}
          onAttemptSelection={this.props.onAttemptSelection}
          tooltipHeader={this.props.tooltipHeader}
          disableAttemptLink={this.props.disableAttemptLink}
        />
      ) : null;

      return (
        <div className={attemptsWrapperClassNames}>
          <div className={singleAttemptsWrapperClassNames}>
            <PreviousAttemptsSingleAttempt
              state={
                this.props.initialIsCorrectStatus ? attemptState.CORRECT : attemptState.INCORRECT
              }
            />
          </div>
          {tooltipContent}
        </div>
      );
    }

    if (this.getAttempts().isEmpty()) {
      return (
        <PreviousAttemptsSingleAttempt
          state={attemptState.NOT_USED}
          style={{
            visibility: 'hidden'
          }}
        />
      );
    }

    const attemptsByAttemptsToShow = this.getAttemptsByNumberOfAttemptsToShow();
    const activeAttemptId = this.getStore().getActiveAttemptId();
    const activeAttemptIndexWithinAttemptsToShow = this.getActiveAttemptIndexWithinAttemptsToShow();
    const activeAttemptIndexWithinAttemptsToShowForCalc =
      activeAttemptIndexWithinAttemptsToShow !== -1 ? activeAttemptIndexWithinAttemptsToShow : 0;

    const attemptsContent = attemptsByAttemptsToShow.map((attempt, i, attemptsList) => {
      const isCorrect = attempt.isCorrect();
      const state = isCorrect ? attemptState.CORRECT : attemptState.INCORRECT;
      const isActive = activeAttemptId === attempt.getId();
      const attemptOpacity = calcOpacityForSingleAttempt({
        numberOfAttempts: attemptsList.size,
        activeAttemptIndex: activeAttemptIndexWithinAttemptsToShowForCalc,
        attemptIndex: i
      });
      const attemptZIndex = attemptOpacity * 10;

      return (
        <PreviousAttemptsSingleAttempt
          key={i}
          state={state}
          isActive={isActive}
          style={{
            opacity: attemptOpacity,
            zIndex: attemptZIndex
          }}
        />
      );
    });

    const tooltipContent = showTooltip ? (
      <PreviousAttemptTooltip
        activeAttemptIndex={this.getActiveAttemptIndex()}
        attempts={this.getAttempts()}
        storeName={this.getTooltipStoreName()}
        previousAttemptsStore={this.getStore()}
        onAttemptSelection={this.props.onAttemptSelection}
        disableAttemptLink={this.props.disableAttemptLink}
      />
    ) : null;

    return (
      <div className={attemptsWrapperClassNames}>
        <div className={singleAttemptsWrapperClassNames}>{attemptsContent}</div>
        {tooltipContent}
      </div>
    );
  }
}

class PreviousAttemptTooltip extends React.Component {
  /**
   * DelayQuery helps prevent any guess query from being made until the tooltip is opened.
   * This is useful for when you have a table full of previousAttempts components.
   * We don't want every single row making a guess query initially, potentially hitting our cap
   * of requests.
   */
  static propTypes = {
    attempts: ImmutablePropTypes.list,
    delayQuery: PropTypes.bool,
    disableCurrentlyViewing: PropTypes.bool,
    storeName: PropTypes.string,
    disableAttemptLink: PropTypes.bool
  };

  state = {
    loadTooltipContent: false
  };

  generatePreviousAttemptsList() {
    if (this.props.attempts.isEmpty()) {
      return <LoadingIndicator />;
    }

    const {attempts} = this.props;
    const activeAttemptId = this.props.previousAttemptsStore.getActiveAttemptId();
    return attempts.map((attempt, i) => {
      const attemptId = attempt.getId();
      const isActive = attemptId === activeAttemptId;
      const isCorrect = attempt.isCorrect();
      const isLatest = i === 0 && attempts.size > 1;
      const attemptDate = attempt.getTimestamp();

      return (
        <PreviousAttemptTooltipAttempt
          key={i}
          isActive={isActive}
          isCorrect={isCorrect}
          isLatest={isLatest}
          attemptDate={attemptDate}
          disableAttemptLink={this.props.disableAttemptLink}
          setActiveAttemptId={
            !this.props.disableCurrentlyViewing
              ? () => {
                  callTargetedAction({
                    name: previousAttemptsActions.SET_ACTIVE_ATTEMPT_ID,
                    payload: attemptId,
                    targetStore: this.props.previousAttemptsStore.name
                  });
                  callTargetedAction({
                    name: tooltipActions.SET_IS_OPEN,
                    payload: false,
                    targetStore: this.props.storeName
                  });
                  this.props.onAttemptSelection(attempt);
                }
              : undefined
          }
        />
      );
    });
  }

  generateCurrentlyViewingContent() {
    const activeAttempt = this.getActiveAttempt();
    const activeAttemptId = this.props.previousAttemptsStore.getActiveAttemptId();
    return activeAttempt
      ? [
          <h4 key={0}>Currently viewing</h4>,
          <div key={1} className='previous-attempts-tooltip-attempts'>
            <PreviousAttemptTooltipAttempt
              isActive={activeAttempt.getId() === activeAttemptId}
              isCorrect={activeAttempt.isCorrect()}
              isLatest={this.isActiveAttemptLatestAttempt()}
              attemptDate={activeAttempt.getTimestamp()}
              disableAttemptLink={this.props.disableAttemptLink}
            />
          </div>
        ]
      : null;
  }

  getActiveAttempt() {
    const activeAttemptId = this.props.previousAttemptsStore.getActiveAttemptId();
    const allAttempts = this.props.attempts;

    const activeAttempt = allAttempts.find((attempt) => {
      return attempt.getId() === activeAttemptId;
    });

    return activeAttempt || null;
  }

  isActiveAttemptLatestAttempt() {
    return this.props.activeAttemptIndex === 0;
  }

  render() {
    return (
      <Tooltip
        onOpen={() => {
          if (this.props.delayQuery) {
            this.setState({loadTooltipContent: true});
          }
        }}
        storeName={this.props.storeName}
      >
        {!this.props.delayQuery || this.state.loadTooltipContent ? (
          <div className='previous-attempts-tooltip-attempts-wrapper'>
            {this.generateCurrentlyViewingContent()}
            <h4>{this.props.tooltipHeader}</h4>
            <div className='previous-attempts-tooltip-attempts'>
              {this.generatePreviousAttemptsList()}
            </div>
          </div>
        ) : null}
      </Tooltip>
    );
  }
}

class PreviousAttemptTooltipAttempt extends React.Component {
  static propTypes = {
    isActive: PropTypes.bool,
    isCorrect: PropTypes.bool,
    isLatest: PropTypes.bool,
    attemptDate: PropTypes.string,
    setActiveAttemptId: PropTypes.func,
    disableAttemptLink: PropTypes.bool
  };

  static defaultProps = {
    isActive: false,
    setActiveAttemptId: () => {}
  };

  render() {
    const {isActive, disableAttemptLink, isCorrect, isLatest, attemptDate} = this.props;
    const dateString = moment(attemptDate).format('MM/DD/YYYY [at] h:mma');
    const state = isCorrect ? attemptState.CORRECT : attemptState.INCORRECT;
    const attemptClassNames = classnames({
      'previous-attempts-tooltip-single-attempt': true,
      'previous-attempts-tooltip-single-attempt--active': isActive,
      'previous-attempts-tooltip-single-attempt--no-hover': disableAttemptLink
    });
    const attemptMetaLatestContent = isLatest ? (
      <span className='previous-attempts-tooltip-single-attempt__meta'>(most recent)</span>
    ) : null;

    return (
      <div
        className={attemptClassNames}
        onClick={() => {
          if (!isActive) {
            this.props.setActiveAttemptId();
          }
        }}
      >
        <div className='previous-attempts-tooltip-single-attempt__icon'>
          <PreviousAttemptsSingleAttempt state={state} isActive={isActive} />
        </div>
        <span className='previous-attempts-tooltip-single-attempt__date'>{dateString}</span>
        {attemptMetaLatestContent}
      </div>
    );
  }
}
