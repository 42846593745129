import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {camelCase} from 'lodash';
import {Link} from 'react-router';
import classnames from 'classnames';
import sessionStore from 'client/Session/SessionStore';
import awaitMandarkQueries from 'lib/hocs/awaitMandarkQueries';
import {getUserQuery} from 'lib/UserAccessUtil';
import {UserModelV2} from 'resources/GeneratedModels/User/UserModel.v2';
import SignUpLink from 'generic/SignUpLink/SignUpLink';
import LogInLink from 'generic/LogInLink/LogInLink';
import {Logo, NavItem, Card, Heading, WindowSizeConsumer, IconButton} from '@albert-io/atomic';
import {useTracking} from 'react-tracking';

import {navLinks} from './navLinks';
import './marketing-navigation.scss';

/**
 * Returns a formatted `<a>` or `<Link>` based on the presence of `hardLink` in the `link`
 * configuration.
 *
 * @param {Immutable.Map} link Configuration for the link.
 * @param {?UserModelV2} [user=null] The authenticated user (with teacher meta). This will be empty when there isn't a valid session (see ~L77)
 */
function generateLinkElement(link, user = null) {
  if (link.get('hardLink', false)) {
    return <a href={link.get('route')}>{link.get('name')}</a>;
  }
  let route = link.get('route', false);
  /**
   * When users are signed in we alter the `Subjects` route based on the type.
   *
   * @see https://github.com/albert-io/project-management/issues/3301
   */
  if (link.get('name') === 'Library') {
    route = generateSubjectsRoute(user);
  }
  return <Link to={route}>{link.get('name')}</Link>;
}

/**
 * Returns the route for our "Subjects" menu item (based on session and account state).
 *
 * @param {?UserModelV2} [user=null] The authenticated user (with teacher meta). This will be empty when there isn't a valid session (see ~L77)
 * @returns {string}
 */
function generateSubjectsRoute(user = null) {
  if (!user || !sessionStore.hasValidSession()) {
    return '/subjects';
  }
  /**
   * All "students" go to High School > ELA > Core
   */
  if (sessionStore.isStudent()) {
    return '/subjects';
  }
  /**
   * All other non-students will get pushed to Discover
   */
  return '/subjects/discover';
}

const HelpCenterLink = () => {
  const {trackEvent} = useTracking({
    event: 'click',
    type: 'link',
    feature: null,
    component: 'primary nav',
    content: 'Help Center'
  });
  return (
    <NavItem icon='question-circle' href='https://help.albert.io' onClick={() => trackEvent()}>
      Help Center
    </NavItem>
  );
};

function generateLinkProps(link, user) {
  let route = link.get('route');
  if (link.get('name') === 'Library') {
    route = generateSubjectsRoute(user);
  }

  const LinkElement = link.get('hardLink', false) ? 'a' : Link;
  return {
    [link.get('hardLink', false) ? 'href' : 'to']: route,
    as: link.has('dropdown') ? 'div' : LinkElement
  };
}
export default awaitMandarkQueries(
  () => {
    if (!sessionStore.hasValidSession()) {
      return {
        queries: []
      };
    }
    return {
      queries: {
        user: getUserQuery()
      }
    };
  },
  class MarketingNavigation extends React.Component {
    static propTypes = {
      location: PropTypes.object,
      user: PropTypes.instanceOf(UserModelV2)
    };

    constructor() {
      super();

      this.state = {
        isOpen: false,
        openDropdown: null
      };
    }

    toggleMobileMenu = () => {
      this.setState((state) => ({isOpen: !state.isOpen}));
    };

    toggleMobileDropdown = (e) => {
      const currentDropdown = this.state.openDropdown;
      const {dropdownName} = e.currentTarget.dataset;
      const nextDropdown = currentDropdown === dropdownName ? null : dropdownName;

      this.setState({
        openDropdown: nextDropdown
      });
    };

    getNavLinks(isMobile) {
      return navLinks.map((link) => {
        if (link.has('dropdown')) {
          return (
            <Dropdown
              user={this.props.user}
              link={link}
              isMobile={isMobile}
              toggleMobileDropdown={this.toggleMobileDropdown}
              openDropdown={this.state.openDropdown}
            />
          );
        }

        return (
          <li key={camelCase(link.get('name'))}>
            <NavItem onClick={this.toggleMobileMenu} {...generateLinkProps(link, this.props.user)}>
              {link.get('name')}
            </NavItem>
          </li>
        );
      });
    }

    render() {
      const {isOpen} = this.state;
      return (
        <WindowSizeConsumer>
          {({breakpoints, viewportWidth}) => {
            const isMobile = viewportWidth <= breakpoints.m;
            return (
              <header
                className={classnames('marketing-header', {
                  'marketing-header__mobile-menu--open': isOpen
                })}
              >
                <NavItem as='a' href='/' className='marketing-header__logo'>
                  <Logo light={!isMobile || isOpen === false} />
                </NavItem>
                {isMobile && (
                  <IconButton
                    className='marketing-nav__menu-toggle'
                    label={isOpen ? 'Close Menu' : 'Open Menu'}
                    color='primary'
                    variant={isOpen ? 'text' : 'solid'}
                    icon={isOpen ? 'times' : 'bars'}
                    onClick={this.toggleMobileMenu}
                  />
                )}
                <nav
                  className={classnames('marketing-nav', {'marketing-nav--open': isOpen})}
                  aria-label='main'
                >
                  <ul className='marketing-nav__menu'>
                    {this.getNavLinks(isMobile)}
                    <li>
                      <HelpCenterLink />
                    </li>
                    <li className='marketing-nav__log-in'>
                      <LogInLink toggleMobileMenu={this.toggleMobileMenu} />
                    </li>
                    <li className='marketing-nav__sign-up'>
                      <NavItem as='span' stateless>
                        <SignUpLink toggleMobileMenu={this.toggleMobileMenu} />
                      </NavItem>
                    </li>
                  </ul>
                </nav>
              </header>
            );
          }}
        </WindowSizeConsumer>
      );
    }
  }
);

class Dropdown extends React.Component {
  static propTypes = {
    link: ImmutablePropTypes.map,
    isMobile: PropTypes.bool,
    toggleMobileDropdown: PropTypes.func,
    openDropdown: PropTypes.string,
    user: PropTypes.instanceOf(UserModelV2)
  };

  constructor() {
    super();

    this.state = {
      isOpen: false
    };

    this.containerRef = React.createRef();
    this.navItemRef = React.createRef();
    this.dropdownRef = React.createRef();
  }

  componentDidMount() {
    global.addEventListener('click', this.onClickOutsideComponent);
  }

  componentWillUnmount() {
    global.removeEventListener('click', this.onClickOutsideComponent);
  }

  handleToggle = () => {
    this.setState((state) => ({isOpen: !state.isOpen}));
  };

  handleClose = () => {
    this.setState({isOpen: false});
    this.navItemRef.current.focus();
  };

  onClickOutsideComponent = (e) => {
    if (this.state.isOpen && !this.containerRef.current.contains(e.target)) {
      this.handleClose();
    }
  };

  handleNavItemKeyDown = (e) => {
    // Enter Key
    if (e.key === 'Enter') {
      this.handleToggle();
    }
    // ESC Key
    if (e.key === 'Escape') {
      this.handleClose();
    }
    // Right/Down Arrow Keys
    if (['ArrowRight', 'ArrowDown'].includes(e.key) && this.state.isOpen) {
      e.preventDefault();
      this.dropdownRef.current
        .querySelector('.marketing-nav__dropdown-item a, .marketing-nav__column-item')
        .focus();
    }
  };

  handleDropdownKeyDown = (e) => {
    // ESC & Tab Key
    if (
      e.key === 'Escape' ||
      (e.key === 'Tab' && this.dropdownRef.current.lastChild === e.target.parentNode)
    ) {
      this.handleClose();
    }
    // Right/Down Arrow Keys
    if (['ArrowRight', 'ArrowDown'].includes(e.key)) {
      e.preventDefault();
      const {nextSibling, parentNode} = e.target.parentNode;
      if (nextSibling) {
        nextSibling.firstChild.focus();
      } else {
        parentNode.firstChild.firstChild.focus();
      }
    }
    // Left/Up Arrow Keys
    if (['ArrowLeft', 'ArrowUp'].includes(e.key)) {
      e.preventDefault();
      const {previousSibling, parentNode} = e.target.parentNode;
      if (previousSibling) {
        previousSibling.firstChild.focus();
      } else {
        parentNode.lastChild.firstChild.focus();
      }
    }
  };

  handleMegaDropdownKeyDown = (e) => {
    // ESC & Tab Key
    if (
      e.key === 'Escape' ||
      (e.key === 'Tab' &&
        this.dropdownRef.current.firstChild.lastChild.lastChild.lastChild === e.target.parentNode)
    ) {
      this.handleClose();
    }
    // Down Arrow Key
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      const columnItems = this.dropdownRef.current.querySelectorAll('.marketing-nav__column-item');
      this.getNextItemInList(columnItems, e.target, 1).focus();
    }
    // Up Arrow Key
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      const columnItems = this.dropdownRef.current.querySelectorAll('.marketing-nav__column-item');
      this.getNextItemInList(columnItems, e.target, -1).focus();
    }
    // Left/Right Arrow Keys
    if (['ArrowRight', 'ArrowLeft'].includes(e.key)) {
      e.preventDefault();
      const {nextSibling, previousSibling} = e.target.parentNode.parentNode.parentNode;
      if (nextSibling) {
        nextSibling.querySelector('.marketing-nav__column-item').focus();
      } else {
        previousSibling.querySelector('.marketing-nav__column-item').focus();
      }
    }
  };

  getNextItemInList = (itemsList, currentItem, nextIndex) => {
    let itemIndex = Array.prototype.indexOf.call(itemsList, currentItem) + nextIndex;
    if (nextIndex < 0) {
      itemIndex = itemIndex < 0 ? itemsList.length - 1 : itemIndex;
    } else {
      itemIndex = itemIndex === itemsList.length ? 0 : itemIndex;
    }
    return itemsList[itemIndex];
  };

  getColumnItem(link) {
    const LinkElement = link.get('hardLink', false) ? 'a' : Link;
    const props = {
      [link.get('hardLink', false) ? 'href' : 'to']: link.get('route')
    };
    return (
      <LinkElement className='marketing-nav__column-item' {...props}>
        <div className='marketing-nav__column-item-details'>
          <div className='marketing-nav__column-item-title' style={{color: link.get('color')}}>
            {link.get('name')}
          </div>
          <div className='marketing-nav__column-item-description'>{link.get('description')}</div>
        </div>
      </LinkElement>
    );
  }

  getColumnDropdown() {
    const dropdownItems = this.props.link.get('dropdown');
    const leftContent = dropdownItems.get('leftContent');
    const rightContent = dropdownItems.get('rightContent');

    return (
      <ul className='marketing-nav__column-dropdown'>
        <li className='marketing-nav__column'>
          <Heading size='2xs' color='primary' className='marketing-nav__column-title'>
            {leftContent.get('name')}
          </Heading>
          <ul className='marketing-nav__column-list'>
            {leftContent.get('links').map((link) => {
              return <li key={camelCase(link.get('name'))}>{this.getColumnItem(link)}</li>;
            })}
          </ul>
        </li>
        <li className='marketing-nav__column'>
          <Heading size='2xs' color='primary' className='marketing-nav__column-title'>
            {rightContent.get('name')}
          </Heading>
          <ul className='marketing-nav__column-list'>
            {rightContent.get('links').map((link) => {
              return <li key={camelCase(link.get('name'))}>{this.getColumnItem(link)}</li>;
            })}
          </ul>
        </li>
      </ul>
    );
  }

  getDropdown() {
    return this.props.link.get('dropdown').map((link) => {
      return (
        <li key={camelCase(link.get('name'))} className='marketing-nav__dropdown-item'>
          {generateLinkElement(link, this.props.user)}
        </li>
      );
    });
  }

  render() {
    const {link, isMobile, toggleMobileDropdown, openDropdown} = this.props;
    const dropdownItems = link.get('dropdown');
    const dropdownCard = dropdownItems.has('leftContent')
      ? this.getColumnDropdown()
      : this.getDropdown();

    const dropdownClasses = classnames('marketing-nav__dropdown', {
      'marketing-nav__dropdown--with-columns': dropdownItems.has('leftContent')
    });

    return (
      <li
        key={camelCase(link.get('name'))}
        ref={this.containerRef}
        className={classnames({
          'marketing-nav__has-dropdown': link.has('dropdown'),
          'marketing-nav__has-dropdown--open': openDropdown === link.get('name')
        })}
      >
        <NavItem
          {...generateLinkProps(link)}
          ref={this.navItemRef}
          tabIndex={0}
          icon='caret-down'
          iconPosition='right'
          aria-haspopup
          aria-expanded={this.state.isOpen || openDropdown === link.get('name')}
          data-dropdown-name={isMobile ? link.get('name') : null}
          onClick={isMobile ? toggleMobileDropdown : null}
          onKeyDown={this.handleNavItemKeyDown}
        >
          {link.get('name')}
        </NavItem>
        <Card
          ref={this.dropdownRef}
          hidden={!this.state.isOpen}
          as={dropdownItems.has('leftContent') ? 'div' : 'ul'}
          className={dropdownClasses}
          shadow='default'
          border='none'
          onKeyDown={
            dropdownItems.has('leftContent')
              ? this.handleMegaDropdownKeyDown
              : this.handleDropdownKeyDown
          }
        >
          {dropdownCard}
        </Card>
      </li>
    );
  }
}
