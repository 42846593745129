import {Map} from 'immutable';

import GenericExplanation from '../common/V2/GenericExplanation.react';

import FreeEntryQuestionV2 from './V2/FreeEntryQuestion.react';
import FreeEntryResponseStats from './V2/FreeEntryQuestionResponseStats.react';
import FreeEntryEditor from './FreeEntryEditor/FreeEntryEditor';

export const FreeEntryQuestionType = Map({
  type: 'free-entry',
  label: 'Free Entry',
  questionRenderer: FreeEntryQuestionV2,
  explanationRenderer: GenericExplanation,
  responseStatsRenderer: FreeEntryResponseStats,
  editor: FreeEntryEditor,
  isHidden: false
});
