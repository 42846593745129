/**
 * # InputStepper
 *
 * A component that allows users to increment or decrement a numeric value using `+` or `-` buttons.
 *
 * ## Props
 *
 * - `id` (string): The ID of the input element.
 * - `ariaLabel` (string): The ARIA label for the input element.
 * - `className` (string): Additional CSS class name for the component.
 * - `error` (bool): Indicates whether there is an error with the input.
 * - `errorMessage` (string): The error message to display when there is an error.
 * - `max` (number): The maximum value allowed for the input.
 * - `min` (number): The minimum value allowed for the input.
 * - `onChange` (function): The callback function to be called when the value changes.
 * - `step` (number): The increment or decrement step value.
 * - `value` (number): The current value of the input.
 *
 * ## Usage
 *
 * ```jsx
 * import InputStepper from './InputStepper.react';
 *
 * const MyComponent = () => {
 *   const handleChange = (value) => {
 *     console.log('New value:', value);
 *   };
 *
 *   return (
 *     <InputStepper
 *       id="myInputStepper"
 *       ariaLabel="Input Stepper"
 *       className="my-input-stepper"
 *       error={false}
 *       errorMessage="Something went wrong"
 *       max={10}
 *       min={0}
 *       onChange={handleChange}
 *       step={2}
 *       value={4}
 *     />
 *   );
 * };
 *
 * export default MyComponent;
 * ```
 */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {useTracking} from 'react-tracking';

import Input from '../../atoms/NumberInput/NumberInput.react';
import IconButton from '../IconButton/IconButton.react';
import Text from '../../atoms/Text/Text.react';

import './input-stepper.scss';

const InputStepper = ({
  min = 0,
  max,
  step = 1,
  onChange,
  value,
  error,
  errorMessage = 'Something went wrong.',
  ariaLabel,
  id = 'inputStepper',
  className,
  ...rest
}) => {
  const {trackEvent} = useTracking({type: 'button', event: 'click'});
  return (
    <div className={classnames('m-input-stepper', className)}>
      <IconButton
        variant='text'
        color='primary'
        label={`decrement by ${step}`}
        disabled={value <= min}
        icon='minus'
        onClick={() => {
          trackEvent({content: '-'});
          onChange(value - step);
        }}
      />
      <Input
        error={error}
        className='m-input-stepper__input'
        border
        max={max}
        min={min}
        value={value}
        step={step}
        onChange={(e) => onChange(e.target.value)}
        aria-label={ariaLabel}
        aria-describedby={error ? id : null}
        type='number'
        pattern='[0-9]*'
        {...rest}
      />
      <IconButton
        variant='text'
        color='primary'
        label={`increment by ${step}`}
        disabled={max && value >= max}
        icon='plus'
        onClick={() => {
          trackEvent({content: '+'});
          onChange(value + step);
        }}
      />
      {error && (
        <Text as='p' id={id} size='s' color='negative' className='m-input-stepper__text'>
          {errorMessage}
        </Text>
      )}
    </div>
  );
};

InputStepper.propTypes = {
  id: PropTypes.string,
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  step: PropTypes.number,
  value: PropTypes.number.isRequired
};

export default InputStepper;
