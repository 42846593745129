import React from 'react';

import {
  Icon,
  Dialogue,
  Card,
  Banner,
  Heading,
  Text,
  Button,
  addToast,
  Modal
} from '@albert-io/atomic';
import {resource} from '@albert-io/json-api-framework/request/builder';
import {refreshSession} from '@albert-io/mandark/authentication';

import sessionStore from 'client/Session/SessionStore';
import appStore from 'client/AppStore';
import {genericCancelableMandarkRequest} from 'resources/mandark.resource';
import {getBaseUserQuery} from 'client/User/UserQueryUtil';
import {redirect} from 'client/User/UserRedirectUtil';
import {hardRedirectToLoginPage} from 'client/LogIn/utils';

import './email-verification-notice.scss';

/**
 * The confirm email component will confirm an email for a user that is logged in, logged out, or
 * even logged in as a different user than the one they are trying to confirm.
 *
 * The confirm email depp link that takes users to this view contains the following query parameters
 * that are used by this view:
 *
 * @example
 * {
 *   userId: '1234', // id of the user we will confirm
 *   emailLinkId: '4321' // special token used by the BE to ensure we are who we say we are
 * }
 *
 * The incoming query parameters BOTH have to be present for this view to make a request; they both make up
 * the payload we're sending.
 *
 *
 * Only a logged in user will see the option to RESEND their confirmation email.
 */
export default class ConfirmEmail extends React.Component {
  constructor() {
    super();
    this.state = {
      error: null,
      isConfirmed: false
    };
    this._isMounted = false;
    this._cancelCallback = null;
  }

  componentDidMount() {
    this._isMounted = true;
    this.processEmailConfirmation();
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this._cancelCallback) {
      this._cancelCallback();
      this._cancelCallback = null;
    }
  }

  async processEmailConfirmation() {
    if (sessionStore.hasValidSession() && sessionStore.isUserConfirmed()) {
      await redirect();
      return;
    }

    /**
     * Since this component can short-circuit rendering via logic in `App.react.js` we
     * bail if we're in a state where we are unable to confirm our email.
     */
    if (
      !appStore.routerProps.location.query.emailLinkId &&
      !appStore.routerProps.location.query.userId
    ) {
      return;
    }

    try {
      const customQuery = {
        verify_confirmation_link: appStore.routerProps.location.query.emailLinkId
      };
      const query = resource('users_v2')
        .mandarkEndpoint([
          'users_v2',
          appStore.routerProps.location.query.userId,
          'confirm_account'
        ])
        .customQuery(customQuery)
        .done();

      const {promise, cancel} = genericCancelableMandarkRequest('patch', query);
      this._cancelCallback = cancel;
      await promise;

      if (this._isMounted) {
        this.setState({
          isConfirmed: true
        });
      }
    } catch (err) {
      let error = 'We were unable to process your verification request.';
      /**
       * Since we're catching a `genericMandarkRequest` the thrown error isn't
       * fully parsed. We'll introspect the message for a status code we know how
       * to handle... otherwise set a generic error message.
       */
      if (err.message?.includes('Errors.BadRequest.ValidationError.InvalidConfirmationLink')) {
        error = 'The confirmation link you have provided is no longer valid.';
      }

      if (this._isMounted) {
        this.setState({
          error
        });
      }
    }
  }

  resendConfirmationEmail = async () => {
    const user = await this.getActiveUserQuery().getResourcePromise();
    try {
      await user.setSentConfirmationEmailAt(null).save();
      addToast({
        title: 'Email sent.',
        color: 'positive',
        message: "A confirmation email has been sent to the email you've provided."
      });
    } catch (err) {
      addToast({
        title: 'An error was encountered.',
        color: 'negative',
        message: 'We were unable to resend a confirmation email at this time, please try again.'
      });
    }
  };

  getActiveUserQuery = () => {
    return getBaseUserQuery(sessionStore.getUserId());
  };

  getErrorBanner() {
    const {error} = this.state;
    if (!error) {
      return null;
    }
    return (
      <Banner type='negative' className='u-mar-b_1'>
        <Banner.Icon icon='exclamation-triangle' iconStyle='regular' />
        <Text>
          <b>We were unable to verify your account.</b>
          <br />
          {error}
        </Text>
      </Banner>
    );
  }

  handleModalClose = () => {
    if (sessionStore.hasValidSession()) {
      refreshSession().then(() => {
        redirect();
      });
    } else {
      hardRedirectToLoginPage();
    }
  };

  getConfirmationModal() {
    const {isConfirmed} = this.state;
    if (!isConfirmed) {
      return null;
    }
    const message = sessionStore.hasValidSession() ? (
      <Text>Your email has been confirmed. Welcome to Albert!</Text>
    ) : (
      <Text>
        Your email has been confirmed. Welcome to Albert! In order to continue, you&apos;ll need to
        log in.
      </Text>
    );
    const button = sessionStore.hasValidSession() ? <Button>Ok</Button> : <Button>Log in</Button>;
    return (
      <Modal ariaLabel='Your account has been verified.' handleClose={this.handleModalClose}>
        {({CloseButtonWrapper, modalContentStyle}) => (
          <Dialogue
            inModal
            hideCloseBtn
            alignTitle='center'
            title={<Icon icon='check-circle' iconStyle='regular' />}
            className={`email-verification-modal ${modalContentStyle}`}
            handleClose={this.handleModalClose}
          >
            <Dialogue.Body align='center'>{message}</Dialogue.Body>
            <Dialogue.BtnGroup fillColumn direction='column'>
              <CloseButtonWrapper>{button}</CloseButtonWrapper>
            </Dialogue.BtnGroup>
          </Dialogue>
        )}
      </Modal>
    );
  }

  /*
    When logged in, we render the re-send button, we want the user to be able to re-send their email
    at any time, not necessarily only if their request failed.

    The actual CONFIRMATION is only happening when the user mounts this view: when they enter their
    email link in the browser and come to this page, or when they click the email link and get to this
    page.
  */
  render() {
    return (
      <div className='email-verification-notice'>
        {this.getConfirmationModal()}
        {this.getErrorBanner()}
        {sessionStore.hasValidSession() ? (
          <Card paddingLevel={4} className='u-text-align_center'>
            <Heading as='h2'>Please Verify Your Email</Heading>
            <Text>
              <p>
                We&apos;ve sent a confirmation email to{' '}
                <b>{this.getActiveUserQuery().getResource().getEmail()}</b>. Please confirm your
                email address by clicking the confirmation link in the email.
              </p>
              <p>
                If you can&apos;t find the confirmation email in your inbox, please try one of the
                following:
              </p>
              <ol className='email-verification-notice__instructions u-mar-t_4'>
                <li className='u-mar-b_1'>
                  Check your spam folder and double-check your inbox for a message titled
                  &quot;Please verify your email for Albert.io&quot;
                </li>
                <li className='u-mar-b_1'>
                  Contact your school administrator or IT administrator and ensure they are not
                  blocking emails coming from the albert.io domain.
                </li>
                <li className='u-mar-b_1'>
                  Click the button below to have a new confirmation email sent to your email
                  address.
                </li>
              </ol>
              <Button
                disabled={!this.getActiveUserQuery().isResourcePopulated()}
                small
                onClick={this.resendConfirmationEmail}
                className='u-mar-t_4'
              >
                Resend confirmation email
              </Button>
            </Text>
          </Card>
        ) : null}
      </div>
    );
  }
}
