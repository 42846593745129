'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import {List, Map} from 'immutable';

export default class DropDown extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false
    };
  }

  _selectOption(option) {
    this._toggleOpen();
    this.props.selectCallback(option.get('value'));
  }

  _toggleOpen() {
    this.setState({open: !this.state.open});
  }

  render() {
    var className = 'dropdown-menu dropdown-select ';
    if (this.state.open) {
      className += 'show-menu';
    }
    return (
      <div className='dropdown'>
        <div className='dropdown-container'>
          <p className='dropdown-description'>{this.props.description}</p>
          <p
            className='dropdown-button'
            onClick={() => {
              this._toggleOpen();
            }}
          >
            {this.props.buttonText}
          </p>
          <ul className={className}>
            {this.props.options.map((option) => {
              return (
                <li
                  key={option.get('value')}
                  value={option.get('value')}
                  onClick={() => {
                    this._selectOption(option);
                  }}
                >
                  {option.get('displayText')}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}

DropDown.propTypes = {
  options: PropTypes.oneOfType([PropTypes.instanceOf(List), PropTypes.instanceOf(Map)]),
  description: PropTypes.string,
  buttonText: PropTypes.string,
  selectCallback: PropTypes.func
};
