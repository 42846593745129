import React from 'react';
import {is} from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import systemTimeOffsetStore from 'client/generic/SystemTimeOffset/SystemTimeOffset.store';
import QuestionsList from 'client/InteractionEngineV2/shared/QuestionsList/QuestionsList.react';
import studentAssignmentQuestionsListStore from './StudentAssignmentQuestionsList.store';
import sessionStore from 'client/Session/SessionStore';

export default class StudentAssignmentQuestionsList extends React.Component {
  componentDidMount() {
    this.activeQuestion = studentAssignmentQuestionsListStore.activeQuestion;
    this.currentSets = studentAssignmentQuestionsListStore.questionSets;
  }

  shouldComponentUpdate() {
    const haveQuestionSetsChanged = !is(
      this.currentSets,
      studentAssignmentQuestionsListStore.questionSets
    );
    const hasActiveQuestionChanged =
      this.activeQuestion !== studentAssignmentQuestionsListStore.activeQuestion;
    return haveQuestionSetsChanged || hasActiveQuestionChanged;
  }

  componentDidUpdate() {
    this.activeQuestion = studentAssignmentQuestionsListStore.activeQuestion;
    this.currentSets = studentAssignmentQuestionsListStore.questionSets;
  }

  render() {
    const assignment = studentAssignmentQuestionsListStore.getAssignment();
    const currentTime = systemTimeOffsetStore.getCurrentTime();
    const student = sessionStore.getUserId();

    /**
     * If the student has not started the assignment and they can still "take action" on the assignment,
     * don't allow them to click the questions in the question list. They forced to click "Get Started" first
     * for a more explicit opt-in.
     */
    const disableQuestionClick =
      assignment.hasStudentStartedAssignment(student) === false &&
      assignment.isStudentFinished(student, currentTime) === false;

    return (
      <QuestionsList
        questionsListStore={studentAssignmentQuestionsListStore}
        questionSets={studentAssignmentQuestionsListStore.questionSets}
        singleQuestionWidget={<AssignmentQuestionIndicator />}
        disableQuestionClick={disableQuestionClick}
      />
    );
  }
}

class AssignmentQuestionIndicator extends React.Component {
  static propTypes = {
    question: ImmutablePropTypes.record
  };

  componentDidMount() {
    this.getAttemptResult();
  }

  componentDidUpdate() {
    this.getAttemptResult();
  }

  getAttemptResult() {
    const assignmentId = studentAssignmentQuestionsListStore.assignmentId;

    if (!this.props.question.hasGuessForAssignment(assignmentId)) {
      return 'unanswered';
    }

    if (!studentAssignmentQuestionsListStore.getAssignment().shouldShowGuessFeedback()) {
      return 'no-feedback';
    }

    return this.props.question.getGuessFeedbackForAssignment(assignmentId)
      ? 'correct'
      : 'incorrect';
  }

  render() {
    // Indicator styles coming from assets/stylesheets/component_library/_question-attempt-indicators.scss
    // styles available are ['unanswered', 'correct', 'incorrect', 'no-feedback']
    const attemptResult = this.getAttemptResult();
    const classNames = `question-attempt-indicator question-attempt-indicator--${attemptResult}`;
    return <div className={classNames} />;
  }
}
