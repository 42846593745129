// @flow
import {List, type Map} from 'immutable';
import type {TeacherModelV1} from 'resources/augmented/Teacher/TeacherModel.v1';
export default {
  /**
   * Archive a classroom by setting `status` to `archived`
   */
  archiveClassroom(): * {
    return this.setField('status', 'archived');
  },
  /**
   * Unarchive a classroom by setting `status` to `active`
   */
  unarchiveClassroom(): * {
    return this.setField('status', 'active');
  },

  getInviteCode(): string {
    return this.get('code');
  },
  resetInviteCode(): * {
    return this.setField('code', null);
  },

  hasSubject(id: string): boolean {
    return (
      this.getSubjects().find((subject) => {
        return subject.getId() === id;
      }) !== undefined
    );
  },

  isArchived(): boolean {
    return this.getStatus() === 'archived';
  },

  getCountOfStudents(): number {
    return this.getIn(['meta', 'count_of_students']) || 0;
  },

  getCountOfQuestionsAnswered(): number {
    return this.getIn(['meta', 'count_of_questions_answered']) || 0;
  },
  /**
   * @todo This no longer actually contains "weighted accuracies", we use it to to grab `correct_guesses` and
   * `incorrect_guesses`. Previously, we used `weighted_correct` and `weighted_possible`. We should just get rid
   * of this completely.
   */
  getWeightedAccuracies(): List<
    Map<{correct_guesses: number, incorrect_guesses: number, student_id: string}>
  > {
    return this.getIn(['meta', 'weighted_accuracies']) || List();
  },

  hasStudents(): boolean {
    return this.getCountOfStudents() > 0 || this.getStudents().isEmpty() === false;
  },

  hasCreatedClassroom(): boolean {
    return Boolean(this.getId());
  },

  hasInvitedStudent(): boolean {
    return (
      this.getMeta().getCountOfStudents() > 0 ||
      this.getMeta().getCountOfPendingClassroomInvitations() > 0
    );
  },

  hasCreatedAssignment(): boolean {
    return this.isSkipAssignmentCreation() || this.getMeta().getCountOfAssignments() > 0;
  }
};
