// @flow
import validator from 'validator';
import moment from 'moment';
import appStore from 'client/AppStore';
import {pushQueryParams} from 'client/history';
import {QUESTIONSET_STATUSES, QUESTION_TYPES} from 'client/constants';
import sessionStore from 'client/Session/SessionStore';

type URLSearchParameter = {
  parameter: string,
  validator?: Function,
  default?: () => ?string | ?number
};

export const SEARCH_PARAMETERS = {
  questionSetId: {
    parameter: 'questionSetId',
    validator: isValidQuestionSetId
  },
  status: {
    parameter: 'status',
    validator: isValidStatus,
    default: () => 'pending'
  },
  subject: {
    parameter: 'subject',
    validator: isValidUUID
  },
  search: {
    parameter: 'search'
  },
  author: {
    parameter: 'author',
    validator: isValidUUID,
    default: () => (sessionStore.isSuper() ? '' : sessionStore.getUserId())
  },
  type: {
    parameter: 'type',
    validator: isValidType
  },
  alignment: {
    parameter: 'alignment',
    validator: isValidAlignment
  },
  flag: {
    parameter: 'flag'
  },
  difficulty: {
    parameter: 'difficulty',
    validator: isValidDifficulty,
    default: () => '1,2,3'
  },
  sort: {
    parameter: 'sort',
    default: () => 'name'
  },
  publishDateStart: {
    parameter: 'publishDateStart',
    validator: isValidPublishDate
  },
  publishDateEnd: {
    parameter: 'publishDateEnd',
    validator: isValidPublishDate
  },
  page: {
    parameter: 'page',
    validator: isValidPage
  },
  fallbackPage: {
    parameter: 'fallbackPage',
    validator: isValidFallbackPage,
    default: () => 1
  },
  guideLevelIds: {
    parameter: 'guideLevelIds',
    validator: (str) => str.split(',').every((entry) => validator.isUUID(entry))
  },
  examIds: {
    parameter: 'examIds',
    validator: (str) => str.split(',').every((entry) => validator.isUUID(entry))
  }
};

export function getDefaultParameters() {
  return Object.entries(SEARCH_PARAMETERS).reduce((acc, [param, options]) => {
    if (options.default) {
      acc[param] = options.default();
    }
    return acc;
  }, {});
}

function isValidPublishDate(date: string): boolean {
  // We can only filter by published_at if the question sets we'er looking at are published. Therefore,
  // if we're not looking at published question sets, we bail.
  if (getURLSearchParameter(SEARCH_PARAMETERS.status) !== QUESTIONSET_STATUSES.PUBLISHED) {
    return false;
  }
  const startDate = getURLSearchParameter(SEARCH_PARAMETERS.publishDateStart);
  const endDate = getURLSearchParameter(SEARCH_PARAMETERS.publishDateEnd);
  if (startDate && endDate) {
    const momentStartDate = moment(parseInt(startDate, 10));
    const momentEndDate = moment(parseInt(endDate, 10));
    return momentStartDate.isBefore(momentEndDate);
  }
  return moment(parseInt(date, 10)).isValid();
}

function isValidDifficulty(str: string): boolean {
  const difficulties = str.split(',') || [];
  return (
    difficulties
      .map((d) => {
        return ['1', '2', '3'].includes(d);
      })
      .some((result) => result === false) !== true
  );
}

function isValidFallbackPage(int: number): boolean {
  return validator.isInt(int);
}

function isValidAlignment(str: string): boolean {
  return ['subject', 'exam', 'none'].includes(str);
}

function isValidQuestionSetId(str: string): boolean {
  return str.split(',').some((id) => validator.isUUID(id));
}

function isValidStatus(str: string): boolean {
  return ['rejected', 'draft', 'pending', 'published', 'hidden', 'archived'].includes(str);
}

function isValidType(str: string): boolean {
  return Object.values(QUESTION_TYPES).some((type) => type.name === str);
}

function isValidUUID(str: string): boolean {
  return Boolean(str);
}

function isValidURLSearchParameter(str: string): boolean {
  return Boolean(str);
}

function isValidPage(str): boolean {
  return validator.isInt(str) || validator.isUUID(str);
}

export function hasValidURLSearchParameter(URLSearchParameter: URLSearchParameter): boolean {
  /**
   * If there isn't a search query (`?<search>`) or the requested
   * parameter is not in the query, then it's not valid.
   */
  if (
    !appStore.routerProps.location.query ||
    !appStore.routerProps.location.query[URLSearchParameter.parameter]
  ) {
    return false;
  }
  /**
   * If the provided search parameter defines a `validator`, we use that to
   * validate. Otherwise, we use a generic validator.
   */
  const validator = URLSearchParameter.validator || isValidURLSearchParameter;
  return validator(appStore.routerProps.location.query[URLSearchParameter.parameter]);
}

/**
 * Retrieve a `URLSearchParameter` value from the current URL search string.
 * @param {Object} URLSearchParameter
 * @returns {string|null} Will return `null` if there is no search string, or the requested
 * parameter is not set.
 */
export function getURLSearchParameter(URLSearchParameter: URLSearchParameter): string | null {
  if (
    !appStore.routerProps.location.query ||
    !appStore.routerProps.location.query[URLSearchParameter.parameter]
  ) {
    return URLSearchParameter.defaultValue || null;
  }
  return decodeURIComponent(appStore.routerProps.location.query[URLSearchParameter.parameter]);
}

/**
 * "set" a `URLSearchParameter` by calling `pushQueryParams` to the defined `parameter`.
 * @param {Object} URLSearchParameter
 * @param {String} value
 */
export function setURLSearchParameter(URLSearchParameter: URLSearchParameter, value: string) {
  pushQueryParams({
    [URLSearchParameter.parameter]: value
  });
}
