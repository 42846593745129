import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {Modal, Card, Text, Button, Anchor, Banner} from '@albert-io/atomic';
import {sanitizeFilename} from 'lib/stringUtilsTs';
import Imgix from 'client/generic/Imgix.react';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {AuthoringSupplementModelV1} from 'resources/augmented/AuthoringSupplement/AuthoringSupplementModel.v1';
import {SupplementModelV1} from 'resources/augmented/Supplement/SupplementModel.v1';

import {getImgixURL, isSupplementCropped} from 'client/SupplementTypes/Image/utils';

import FeatureFlag from 'client/components/FeatureFlag/FeatureFlag.react';

import {ImageV2} from './ImageV2.react';

import './image.scss';

const SupplementPropType = PropTypes.oneOfType([
  PropTypes.instanceOf(AuthoringSupplementModelV1),
  PropTypes.instanceOf(SupplementModelV1)
]);

const ImageFigure = ({img, caption, inverse}) => (
  <figure
    className={classnames('image-figure', {
      'image-figure--with-caption': Boolean(caption),
      'image-figure--inverse': inverse
    })}
  >
    {img}
    {caption ? (
      <Text as='figcaption' size='xs' color='secondary' bold>
        {caption}
      </Text>
    ) : null}
  </figure>
);

ImageFigure.propTypes = {
  img: PropTypes.node.isRequired,
  caption: PropTypes.node,
  inverse: PropTypes.bool
};

ImageFigure.defaultProps = {
  inverse: false
};

/**
 * The `<BasicImage>` component is ueds as a fallback rendering method for images when we are unable to
 * rely on the Imgix API.
 */
class BasicImage extends React.PureComponent {
  static propTypes = {
    supplement: SupplementPropType
  };

  render() {
    const {supplement} = this.props;
    /**
     * Since we don't really support embedding PDFs (we rely on Imgix reformatting the output), if the image
     * supplement might be a PDF, we render it as a `<ViewableImage>` with a disclaimer.
     */
    const img = supplement.mightBePDF() ? (
      <>
        <Banner className='u-mar-b_1'>
          <Text>
            We are unable to render this image at the moment, you can view this image by using the
            button below.
          </Text>
        </Banner>
        <ViewableImage supplement={supplement} />
      </>
    ) : (
      <img alt={supplement.getAlttext()} src={supplement.getImageLocation().href} />
    );

    return (
      <>
        {
          /**
           * If the image supplement is usually cropped via Imgix, we add a disclaimer since we can't
           * guarantee the uncropped image will fix the question context.
           */
          isSupplementCropped(supplement) && (
            <Banner className='u-mar-b_1'>
              <Text>
                We&apos;re currently experiencing issues with our image hosting service.
                <br />
                At the moment, the proportions of this image might not reflect the intended settings
                for this question.
                <br />
                If you are able to, please return to this question later.
              </Text>
            </Banner>
          )
        }
        {img}
      </>
    );
  }
}

const ImageSupplement = ({className, caption, img, withZoom, onClick, ...rest}) => (
  <div className={classnames('image-supplement', className)} {...rest}>
    <Card
      backgroundColor='subtle'
      className={classnames('image-supplement__card', {
        'image-supplement__card--with-zoom': withZoom
      })}
      paddingLevel={1}
      onClick={onClick}
    >
      <ImageFigure img={img} caption={caption} />
    </Card>
  </div>
);

ImageSupplement.propTypes = {
  img: PropTypes.node,
  caption: PropTypes.node,
  withZoom: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func
};

ImageSupplement.defaultProps = {
  img: null,
  caption: null,
  withZoom: true
};

class DownloadableImage extends React.PureComponent {
  static propTypes = {
    supplement: SupplementPropType.isRequired,
    renderUsingImgix: PropTypes.bool
  };

  render() {
    const {supplement, renderUsingImgix} = this.props;
    const segments = supplement.getImageLocation().path.split('/');
    const name = segments.pop();
    const parts = name.split('.');
    let extension = parts.pop();
    if (extension === 'pdf') {
      extension = 'png';
    }
    const filename = `${sanitizeFilename(parts.join('_'))}.png`;
    const url = getImgixURL(supplement, {
      dl: filename
    });
    return renderUsingImgix ? (
      <Button as='a' href={url}>
        Download
      </Button>
    ) : (
      <Banner>
        <Text>
          Image downloading is currently unavailable.
          <br />
          You may download the this file by clicking{' '}
          <Anchor
            href={supplement.getImageLocation().href}
            target='_blank'
            rel='noopener noreferrer'
          >
            here
          </Anchor>{' '}
          and using your browser&apos;s &quot;Save As...&quot; functionality.
        </Text>
      </Banner>
    );
  }
}

class ViewableImage extends React.PureComponent {
  static propTypes = {
    supplement: SupplementPropType.isRequired
  };

  render() {
    const {supplement} = this.props;
    return (
      <>
        <Button
          as='a'
          target='_blank'
          rel='noopener noreferrer'
          href={supplement.getImageLocation().href}
        >
          {supplement.getName() || 'View image'}
        </Button>
      </>
    );
  }
}

export class Image extends React.Component {
  static propTypes = {
    withZoom: PropTypes.bool,
    supplement: SupplementPropType,
    renderUsingImgix: PropTypes.bool,
    attributes: PropTypes.object
  };

  static defaultProps = {
    withZoom: true,
    renderUsingImgix: true,
    attributes: {}
  };

  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  toggleModal = () => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal
    }));
  };

  render() {
    const {supplement, attributes, renderUsingImgix} = this.props;
    /**
     * The "viewable" property takes precedence over other configurations.
     */
    if (attributes.viewable) {
      return <ViewableImage supplement={supplement} />;
    }

    if (attributes.downloadable) {
      return <DownloadableImage supplement={supplement} renderUsingImgix={renderUsingImgix} />;
    }

    const hasCaption = Boolean(supplement.getCaption());

    let caption = null;
    if (hasCaption) {
      caption = <MarkdownRendererV2 text={supplement.getCaption()} />;
    }

    const hasZoomEnabled = this.props.withZoom && renderUsingImgix;
    const clickHandler = hasZoomEnabled ? this.toggleModal : () => {};

    const img = renderUsingImgix ? (
      <Imgix supplement={supplement} />
    ) : (
      <BasicImage supplement={supplement} />
    );

    const modalImg =
      !renderUsingImgix || supplement.getImageSize() >= 100 ? (
        img
      ) : (
        <Imgix
          // In the zoom modal, if the supplement is scaled down, we return it to 100% size
          supplement={supplement.setImageSize(100)}
        />
      );

    return (
      <div>
        <ImageSupplement
          img={img}
          caption={caption}
          onClick={clickHandler}
          withZoom={hasZoomEnabled}
        />
        {this.state.showModal ? (
          <Modal
            ariaLabel={`Zoom View: ${supplement.getAlttext() || 'Supplement'}`}
            handleClose={this.toggleModal}
          >
            {({CloseButtonWrapper, modalContentStyle}) => {
              return (
                <Card
                  className={`image-supplement--zoom-modal ${modalContentStyle}`}
                  backgroundColor='subtle'
                  paddingLevel={1}
                >
                  <ImageFigure img={modalImg} caption={caption} inverse />
                  <CloseButtonWrapper>
                    <Button className='image-supplement-zoom__close' color='secondary'>
                      Close
                    </Button>
                  </CloseButtonWrapper>
                </Card>
              );
            }}
          </Modal>
        ) : null}
      </div>
    );
  }
}

const FeatureFlaggedImage = (props) => (
  <FeatureFlag name='image_supplements'>
    <ImageV2 {...props} />
    <FeatureFlag.Fallback>
      <Image {...props} />
    </FeatureFlag.Fallback>
  </FeatureFlag>
);

export default FeatureFlaggedImage;
