import React from 'react';
import {Link} from 'react-router';

import {Button} from '@albert-io/atomic';
import {getQuestionEditPath} from 'client/Dennis/Content/Queue/shared';
import {QuestionModelV3, QuestionSetModelV1} from '@albert-io/models';

interface Props {
  question: QuestionModelV3;
  questionSet: QuestionSetModelV1;
}

export default function EditQuestionLink({question, questionSet}: Props) {
  return (
    <Button
      color='secondary'
      as={Link}
      to={getQuestionEditPath({
        questionSetId: questionSet.getId(),
        questionId: question.getId(),
        customSearch: {
          status: 'published',
          subject: !questionSet.getSubject().isEmpty()
            ? questionSet.getSubject().getId()
            : questionSet.getAuthoringSubject().getId(),
          page: questionSet.getId(),
          fallback_page: 1
        }
      })}
    >
      Edit
    </Button>
  );
}
