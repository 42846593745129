import React from 'react';
import {EventEmitter} from 'eventemitter3';

const emitter = new EventEmitter();
const events = {
  show: Symbol('modal--show'),
  remove: Symbol('modal--remove')
};

/**
 * Show a modal in our global modal component.
 *
 * @param {React.Component<{handleClose: Function}>} Component The modal Component to render. This should be a wrapped `<Modal />` that takes a `handleClose` prop.
 */
export function showGlobalModal(Component) {
  emitter.emit(events.show, Component);
}

/**
 * Remove the current global modal.
 */
export function removeGlobalModal() {
  emitter.emit(events.remove);
}

/**
 * `<GlobalModal>` should be rendered at a high-level (`App.react.js`) in order to allow us to trigger
 * modals deep within our application tree _without_ having to manage a local modal state.
 */
export class GlobalModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Component: null
    };
    emitter.on(events.show, this.handleShowModal);
    emitter.on(events.remove, this.handleRemoveModal);
  }

  componentWillUnmount() {
    emitter.off(events.show, this.handleShowModal);
    emitter.off(events.remove, this.handleRemoveModal);
  }

  handleShowModal = (Component) => {
    this.setState({
      Component
    });
  };

  handleRemoveModal = () => {
    this.setState({
      Component: null
    });
  };

  render() {
    const {Component} = this.state;
    return Component && <Component handleClose={this.handleRemoveModal} />;
  }
}
