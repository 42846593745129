/**
 * # InputGroup
 *
 * Component for grouping input elements together either horizontally or vertically in a flex layout.
 *
 * ## Props
 *
 * - `alwaysRow` (optional): Specifies whether the input elements should always be displayed in a row. Default is `false`.
 * - `className` (optional): Additional CSS class name for the input group container.
 * - `direction` (optional): Specifies the direction of the input elements. Can be either `'row'` or `'column'`. Default is `'row'`.
 * - `marginLevel` (optional): Specifies the margin level for the input elements. Can be a number from 1 to 6. Default is `undefined`.
 * - `children`: The input elements to be grouped together.
 *
 * ## Usage
 *
 * ```tsx
 * import React from 'react';
 * import InputGroup from './InputGroup';
 *
 * const MyComponent = () => (
 *   <InputGroup alwaysRow className="my-input-group" direction="column" marginLevel={3}>
 *     <input type="text" />
 *     <input type="checkbox" />
 *     <button>Submit</button>
 *   </InputGroup>
 * );
 * ```
 */

import React from 'react';

import classnames from 'classnames';
import './input-group.scss';

interface InputGroupProps extends PropsWithChildrenRequired, PropsWithClassNameOptional {
  alwaysRow?: boolean;
  direction?: 'row' | 'column';
  marginLevel?: 1 | 2 | 3 | 4 | 5 | 6;
}

export const InputGroup = ({
  alwaysRow,
  className,
  direction = 'row',
  marginLevel,
  children
}: InputGroupProps) => (
  <div
    role='group'
    className={classnames('m-input-group', className, {
      [`m-input-group--${direction}`]: direction,
      'm-input-group--always-row': alwaysRow
    })}
  >
    {React.Children.toArray(children).map((child, i, arr) => {
      if (!React.isValidElement(child)) return null;
      const defaultMargin = child.props.size && child.props.size === 's' ? 1 : 2;
      const margin = marginLevel || defaultMargin;
      const isNotLast = i + 1 !== arr.length;
      return React.cloneElement(child, {
        className: classnames(child.props.className, 'm-input-group__item', {
          [`m-input-group__item--${direction}`]: direction,
          [`u-mar-r_${margin}`]: isNotLast,
          [`u-mar-b_${margin}`]: isNotLast
        })
      } as PropsWithClassNameRequired);
    })}
  </div>
);

export default InputGroup;
