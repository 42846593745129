/**
 * # PasswordField
 *
 * PasswordField returns a Field instance that accepts a forwarded ref.
 * This makes it easy to manage PasswordField's state without actually
 * writing its contents anywhere in our state tree. This is more secure.
 *
 * ## Props
 *
 * PasswordField component accepts all the props from the Field component,
 * which is a generic input field component. It also accepts the following
 * additional props:
 *
 * - `type` (optional): The type of the input field. Defaults to 'password'.
 *
 * ## Usage
 *
 * ```tsx
 * import PasswordField from './PasswordField.react';
 *
 * const MyForm = () => {
 *   const passwordRef = React.useRef<HTMLInputElement>(null);
 *
 *   const handleSubmit = () => {
 *     const password = passwordRef.current?.value;
 *     // Handle form submission with password value
 *   };
 *
 *   return (
 *     <form onSubmit={handleSubmit}>
 *       <PasswordField ref={passwordRef} name="password" />
 *       <button type="submit">Submit</button>
 *     </form>
 *   );
 * };
 * ```
 */

import React from 'react';

import Field, {FieldProps} from '../Field/Field.react';

type PasswordFieldProps = FieldProps<'input'>;

const PasswordField = React.forwardRef<HTMLInputElement, PasswordFieldProps>(
  ({type = 'password', ...props}: PasswordFieldProps, ref) => (
    <Field type={type} {...props} ref={ref} />
  )
);

export default PasswordField;
