import {resource} from '@albert-io/json-api-framework/request/builder';
import {applyFilters} from 'components/PracticeView/PracticeViewToolbar/Filters/filters.utils';
import {applySort} from 'components/PracticeView/PracticeViewToolbar/Sort/sort.utils';

/**
 *
 * @param {string} resourceString - one of ['templates_v1', 'guide_levels_v1', 'assignments_v3']
 * @param {string} id - id of folder or guide level
 * @param {Array<string>} excludedQuestionSetIds - list of question set Ids
 * @param includedQuestionSetIds
 */
// eslint-disable-next-line import/prefer-default-export
export const getQuestionSetsQuery = (
  resourceString,
  id,
  excludedQuestionSetIds = [],
  includedQuestionSetIds = []
) => {
  // we only want to add included is we need specific data, guideLevels are needed for breadcrumbs
  const resourcesToBeIncluded = ['guide_levels_v2'];

  const questionSetsQuery = resource('question_sets_v1')
    .mandarkEndpoint([resourceString, id, 'question_sets_v1'])
    .include('subject_v2')
    .include('questions_v3')
    .include(resourceString, resourcesToBeIncluded.includes(resourceString))
    .filter(
      {
        id: {
          not: excludedQuestionSetIds.join(',')
        }
      },
      excludedQuestionSetIds.length
    )
    .filter(
      {
        id: includedQuestionSetIds.join(',')
      },
      includedQuestionSetIds.length
    )
    .pageSize('infinite');

  return applyFilters(applySort(questionSetsQuery));
};

export const getQuestionSetPositionsQuery = (resourceString, id) => {
  const metaContext = resourceString.includes('guide_level')
    ? {
        meta: {
          context: {
            guide_level: {
              id
            }
          }
        }
      }
    : {
        meta: {
          context: {
            template: {
              id
            }
          }
        }
      };
  let guideQuestionSetQuery = resource('question_sets_v1')
    .mandarkEndpoint([resourceString, id, 'question_sets_v1'])
    .withMeta('question_set_v1')
    .fields({
      question_set_v1: 'meta'
    })
    .pageSize('infinite');

  guideQuestionSetQuery = guideQuestionSetQuery.customQuery(metaContext);

  return guideQuestionSetQuery.getResourcePromise();
};
