import React from 'react';
import PropTypes from 'prop-types';
import {pick} from 'lodash';
import {withRouter} from 'react-router';
import {OrderedMap, List} from 'immutable';
import {Button, Icon, Popover} from '@albert-io/atomic';

import {pluralize} from 'lib/stringUtils';

import {pushQueryParams} from 'client/history';

import {ThemeDropdown} from 'client/StudyGuide/ThemeMenu/ThemeMenu';

import StandardsSubSearch from './StandardsSubSearch/StandardsSubSearch.react';
import TagsSubSearch from './TagsSubSearch/TagsSubSearch.react';
import {KeywordSearch} from './KeywordSearch/KeywordSearch.react';

import {
  getStandardsByIdQuery,
  getTagsByIdQuery,
  hasStandardsQuery,
  hasTagsQuery
} from './AdvancedSearch.queries';

import './advanced-search.scss';

/**
 * AdvancedSearch
 * ===
 * This component wraps up a configuration of atomic and application components for usage
 * in the Subject View's Study Guide tab. It serves as an interface layer between the raw
 * data we receive when we bootstrap the Study Guide and the atomic components that consume it.
 *
 * AdvancedSearch mutates its props into the form these components expect before administering them.
 *
 * At the same time, it receives back input from the FindAndApply instances among its children and
 * re-formats that feedback into query params that our search toolkit understands.
 *
 * Props:
 *  subjectId - the ID of the subject to which our search is scoped
 *  standards - an immutable list of the resource entities of the standards (FKA labels)
 *    by which we are filtering our search results
 *  tags - an immutable list of the resource entities of the tags by which we are filtering
 *    our search results
 *  searchString - an iterable of the phrases and tokens by which we are filtering
 *    our search results
 */
const filterableResourceQueries = {
  standards: getStandardsByIdQuery,
  tags: getTagsByIdQuery
};

// This method re-formats our filterable resources into maps keyed by each
// entity's ID, reducing them to a format compatible with the FindAndApply
// instances among the children.
function mapResourceByKey(resourceList) {
  return resourceList.toOrderedMap().mapKeys((key) => resourceList.get(key).getId());
}

const AdvancedSearchBtn = ({children, ...rest}) => (
  <Button {...rest} className='advanced-search__btn' variant='outlined' color='secondary'>
    {children}
    <Icon className='advanced-search__btn-icon u-mar-l_1' icon='caret-down' />
  </Button>
);

AdvancedSearchBtn.propTypes = {
  children: PropTypes.node
};

const popoverModifier = {
  flip: {
    enabled: false
  }
};

class AdvancedSearch extends React.Component {
  static propTypes = {
    location: PropTypes.shape({
      query: PropTypes.shape({
        s: PropTypes.string,
        standards: PropTypes.string,
        tags: PropTypes.string
      }),
      pathname: PropTypes.string,
      search: PropTypes.string
    }),
    onSearchCallback: PropTypes.func,
    subjectId: PropTypes.string.isRequired,
    isMobileView: PropTypes.bool,
    isEmptySearches: PropTypes.bool,
    themes: PropTypes.array,
    themeRefs: PropTypes.object,
    hideThemeDropdownCTAText: PropTypes.bool,
    setHasPopover: PropTypes.func,
    showFilters: PropTypes.bool
  };

  static defaultProps = {
    onSearchCallback: () => {}
  };

  constructor(props) {
    super(props);
    this.state = {
      error: List(), // @todo: Display toasts for errors
      shownDropdown: null, // standards | tags | themes
      standards: OrderedMap(),
      tags: OrderedMap(),
      searchString: props.location ? props.location.query.s : '',
      guideType: props.location.pathname.includes('assessments') ? 'assessment' : 'practice',
      showMobileSearch: !!props?.location?.query?.s
    };

    this.standardsBtnRef = React.createRef();
    this.tagsBtnRef = React.createRef();
  }

  componentDidMount() {
    const {query} = this.props.location;

    // Reduce our query object down to the params we care about
    const params = pick(query, Object.keys(filterableResourceQueries));

    // If we're in an active search, update the child SubSearch components with the appropriate defaults
    if (Object.keys(params).length) {
      this.fetchData(params);
    }
  }

  componentDidUpdate(prevProps) {
    // Verify whether there are changes to the outer standards or tags props we must register with state
    const hasChanges = this.props.location.search !== prevProps.location.search;
    if (hasChanges) {
      const params = pick(this.props.location.query, Object.keys(filterableResourceQueries));
      this.fetchData(params);
    }
  }

  async fetchData(params) {
    // eslint-disable-next-line guard-for-in
    for (const resourceType in params) {
      const ids = params[resourceType];
      if (!ids) {
        this.setState(() => ({[resourceType]: OrderedMap()}));
        // eslint-disable-next-line no-continue
        continue;
      }
      try {
        // eslint-disable-next-line no-await-in-loop
        const resource = await filterableResourceQueries[resourceType](ids).getResourcePromise();
        this.setState(() => ({[resourceType]: mapResourceByKey(resource)}));
      } catch (e) {
        this.setState(({error}) => ({error: error.push(e)}));
      }
    }
  }

  updateSearchParams({standards, search, tags}) {
    // @todo: Add properties representing tags and questionSearchString to this object
    pushQueryParams({
      ...(typeof search === 'string' && {s: search}),
      ...(standards && {standards: standards.keySeq().join(',')}),
      ...(tags && {tags: tags.keySeq().join(',')})
    });
    this.props.onSearchCallback();
  }

  handleSearchSubmit = (searchString) => {
    this.updateSearchParams({search: searchString});
  };

  handleSearchReset = () => {
    const {location} = this.props;
    const query = location?.query?.s;
    if (query) {
      this.updateSearchParams({search: ''});
    }
  };

  hasResults(query) {
    return (
      query.isResourcePopulated() &&
      query.getResourceMetadata().getIn(['page', 'total_entries'], 0) > 0
    );
  }

  hasTags() {
    return this.hasResults(hasTagsQuery(this.props.subjectId));
  }

  hasStandards() {
    return this.hasResults(hasStandardsQuery(this.props.subjectId));
  }

  clearDropdown = () => {
    this.setState({shownDropdown: null});
    this.props.setHasPopover(false);
  };

  toggleDropdown = (dropdown) => {
    const {setHasPopover} = this.props;
    const {shownDropdown} = this.state;
    const updatedDropdown = shownDropdown === dropdown ? null : dropdown;
    this.setState({shownDropdown: updatedDropdown});
    // have to handle a higher zIndex in the parent when the popover backdrop modal is shown, otherwise the global nav will be on top
    setHasPopover(['standards', 'tags'].includes(updatedDropdown));
  };

  toggleMobileSearch = () => {
    this.setState(
      (prev) => ({
        showMobileSearch: !prev.showMobileSearch,
        searchString: prev.showMobileSearch ? '' : prev.searchString
      }),
      () => {
        if (!this.state.showMobileSearch) {
          this.handleSearchReset();
        }
      }
    );
  };

  render() {
    const {showMobileSearch, shownDropdown, tags, standards, searchString, guideType} = this.state;
    const {
      isMobileView,
      isEmptySearches,
      themes,
      themeRefs,
      hideThemeDropdownCTAText,
      showFilters
    } = this.props;
    const showStandards = shownDropdown === 'standards';
    const showTags = shownDropdown === 'tags';
    const showThemes = shownDropdown === 'themes';
    // These could also be helper funcs
    const standardsBtnLabel = !standards.isEmpty()
      ? `${standards.size} ${pluralize('standard', standards.size)}`
      : 'Standards';
    const tagsBtnLabel = !tags.isEmpty() ? `${tags.size} ${pluralize('tag', tags.size)}` : 'Tags';
    return (
      <>
        {isMobileView && !isEmptySearches && (
          <ThemeDropdown
            themes={themes}
            themeRefs={themeRefs}
            hideCTAText={hideThemeDropdownCTAText}
            onToggle={() => this.toggleDropdown('themes')}
            show={showThemes}
          />
        )}
        {showFilters && (
          <div className='advanced-search'>
            {/* @todo: Add A11y support autofocusing on subsearch search field upon toggling */}
            {this.hasStandards() && (
              <span className='advanced-search__btn-wrapper' ref={this.standardsBtnRef}>
                <AdvancedSearchBtn
                  aria-expanded={showStandards}
                  onClick={() => this.toggleDropdown('standards')}
                  data-testid='advanced-search__standards-btn'
                >
                  {standardsBtnLabel}
                </AdvancedSearchBtn>
              </span>
            )}
            {/* @todo: Add A11y support autofocusing on subsearch search field upon toggling */}
            {this.hasTags() && (
              <span className='advanced-search__btn-wrapper' ref={this.tagsBtnRef}>
                <AdvancedSearchBtn
                  aria-expanded={showTags}
                  onClick={() => this.toggleDropdown('tags')}
                  data-testid='advanced-search__tags-btn'
                >
                  {tagsBtnLabel}
                </AdvancedSearchBtn>
              </span>
            )}
            {/*
        The input provided by KeywordSearch is hidden by default on mobile
        and will render below in a separate row based on the state handled by the below toggle.
        */}
            {isMobileView && (
              <Button
                onClick={this.toggleMobileSearch}
                variant={showMobileSearch ? 'solid' : 'outlined'}
              >
                <Icon
                  icon={showMobileSearch ? 'times' : 'search'}
                  color={showMobileSearch ? 'primary-inverse' : 'primary'}
                />
              </Button>
            )}
            {!isMobileView && (
              <KeywordSearch
                aria-label='Search question text'
                defaultValue={searchString}
                onReset={this.handleSearchReset}
                onChange={this.handleSearchSubmit}
                placeholder='Search'
              />
            )}
          </div>
        )}
        {isMobileView && showMobileSearch && (
          <KeywordSearch
            aria-label='Search question text'
            className='advanced-search__search-input--mobile'
            defaultValue={searchString}
            onReset={this.handleSearchReset}
            onChange={this.handleSearchSubmit}
            placeholder='Search'
          />
        )}
        {showStandards && (
          <Popover
            usePortal={false}
            border='none'
            className='advanced-search__popover'
            expanded={showStandards}
            paddingLevel={null}
            targetRef={this.standardsBtnRef}
            modifiers={popoverModifier}
          >
            <StandardsSubSearch
              guideType={this.state.guideType}
              className='advanced-search__sub-search advanced-search__sub-search--standards'
              defaultSelectedItems={standards}
              subjectId={this.props.subjectId}
              onApply={(updatedStandards) => {
                this.updateSearchParams({standards: updatedStandards});
                this.clearDropdown();
              }}
              onCancel={this.clearDropdown}
            />
          </Popover>
        )}
        {showTags && (
          <Popover
            usePortal={false}
            border='none'
            className='advanced-search__popover'
            expanded={showTags}
            paddingLevel={null}
            targetRef={this.tagsBtnRef}
            modifiers={popoverModifier}
          >
            <TagsSubSearch
              guideType={guideType}
              defaultSelectedItems={tags}
              onApply={(tags) => {
                this.updateSearchParams({tags});
                this.clearDropdown();
              }}
              onCancel={this.clearDropdown}
              subjectId={this.props.subjectId}
            />
          </Popover>
        )}
      </>
    );
  }
}

export default withRouter(AdvancedSearch);
