// @flow
import {baseGuideLevelExtensions} from '../guideLevelHelpers/baseGuideLevel.extensions';
import {pluralize} from 'lib/stringUtils';

export const extensions = {
  ...baseGuideLevelExtensions,

  getLinkToQuestions(): string {
    return `/learn/${this.getSubject().getUrlSlug()}/${this.getUrlSlug()}`;
  },

  getQuestionMetadataForSubjectView(hasTeacherAccess: boolean = false): string {
    const questions = this.getMeta().getCountOfQuestions();
    const questionsAnswered = this.getMeta().getStudentCountOfUniqueGuesses();
    if (hasTeacherAccess || !questionsAnswered) {
      return `${questions} ${pluralize('question', questions)}`;
    }
    return `${questionsAnswered}/${questions} ${pluralize('question', questions)}`;
  }
};
