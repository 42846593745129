import React from 'react';
import {List} from 'immutable';
import notifier from '@albert-io/notifier';
import {history} from 'client/history';
import CoppaConfirm from './CoppaConfirm.react';
import CoppaReject from './CoppaReject.react';

const ACCEPT = 'accept';
const REJECT = 'reject';
const validDecisions = List(ACCEPT, REJECT);

import './coppa.scss';

export default class Coppa extends React.Component {
  componentDidMount() {
    let error;
    if (
      !this.props.location.query.verification_code ||
      !this.props.location.query.consent_id ||
      !this.props.location.query.decision
    ) {
      notifier.notify(new Error('Coppa: missing required query parameters'), {
        component: 'Coppa',
        decision: this.props.location.query.decision,
        verificationCode: this.props.location.query.verification_code,
        consentId: this.props.location.query.consent_id
      });

      error = true;
    }

    if (validDecisions.includes(this.props.location.query.decision)) {
      notifier.notify(new Error('Coppa: decision query parameter is not valid'), {
        component: 'Coppa',
        decision: this.props.location.query.decision,
        verificationCode: this.props.location.query.verification_code,
        consentId: this.props.location.query.consent_id
      });

      error = true;
    }

    /**
     * @todo: unclear if this is an appropriate thing to do here - will confer w/ product at some point
     */
    if (error) {
      history.pushState(null, '/error');
    }
  }

  render() {
    if (this.props.location.query.decision === ACCEPT) {
      return (
        <CoppaConfirm
          verificationCode={this.props.location.query.verification_code}
          consentId={this.props.location.query.consent_id}
        />
      );
    } else if (this.props.location.query.decision === REJECT) {
      return (
        <CoppaReject
          verificationCode={this.props.location.query.verification_code}
          consentId={this.props.location.query.consent_id}
        />
      );
    } else {
      return null;
    }
  }
}
