import React from 'react';
import {fromJS} from 'immutable';
import {renderMoment} from 'lib/timeUtils';
import {resource} from '@albert-io/json-api-framework/request/builder';
import appStore from 'client/AppStore';
import interactiveEngineStore from 'client/InteractionEngineV2/InteractionEngineStore';
import masqueradeStore from 'generic/Masquerade/Masquerade.store';
import Modal, {ModalContent} from '../Modal.react';
import {hideModal} from '../ModalActions';
import Tabs from 'sg/Tabs/Tabs.react';
import {MandarkTable, Column} from 'sg/Tables/MandarkTable/MandarkTable.react';
import Button from 'client/styleguide/Button/Button.react';
import questionTrackerModalStore from './QuestionTrackerModal.store';
import './question-tracker.scss';

export default class QuestionTrackerModal extends React.Component {
  constructor() {
    super();
    this.assignmentsTab = fromJS([
      {
        label: 'Assignments',
        query: {
          questionTrackerType: null
        }
      }
    ]);
    this.templatesTab = fromJS({
      label: 'Folders',
      query: {
        questionTrackerType: 'templates'
      }
    });
  }

  getTabsData() {
    const assignmentsTab = this.assignmentsTab;
    return interactiveEngineStore.isPracticeExamSession
      ? assignmentsTab
      : assignmentsTab.push(this.templatesTab);
  }

  isTemplateTab() {
    const statusParam = appStore.routerProps.location.query.questionTrackerType;
    return statusParam === 'templates';
  }

  getQuery() {
    return this.isTemplateTab() ? this.getTemplatesQuery() : this.getAssignmentsQuery();
  }

  getAssignmentsQuery() {
    return resource('assignment_v3')
      .mandarkEndpoint(['assignments_v3'])
      .include('classrooms_v1')
      .include('subject_v2')
      .filter({
        classrooms_v1: {
          status: 'active'
        },
        teacher_v1: masqueradeStore.getUserIdByMasqueradeState(),
        question_sets_v1: questionTrackerModalStore.getQuestionSetId()
      });
  }

  getTemplatesQuery() {
    return resource('template_v1')
      .mandarkEndpoint(['templates_v1'])
      .include('question_sets_v1')
      .filter({
        teacher_v1: masqueradeStore.getUserIdByMasqueradeState(),
        question_sets_v1: questionTrackerModalStore.getQuestionSetId()
      });
  }

  getAssignmentDetailsContent(item) {
    const classroomNames = item.getClassroomNames();
    const classroomNamesContent =
      classroomNames !== '' ? (
        <span>
          {classroomNames}
          <span className='question-tracker-modal__names-content-divider'>&#9679;</span>
        </span>
      ) : null;
    return (
      <div>
        <div className='question-association-details__name'>{item.getName()}</div>
        <div className='question-association-details__classroom-content'>
          {classroomNamesContent}
          {renderMoment(item.getDueDateFromSettings(), 'dateTime')}
        </div>
      </div>
    );
  }

  getTemplateDetailsContent(item) {
    return <div className='question-association-details__name'>{item.getName()}</div>;
  }

  getAssignmentViewButtonContent = (item) => {
    const classrooms = item.getClassrooms();
    if (classrooms.isEmpty()) {
      return null;
    }
    const classroom = classrooms.first().getId();
    const itemId = item.getId();
    const slug =
      item.getAssignmentType() === 'assignment'
        ? `/classes/${classroom}/assignments/${itemId}/student-detail`
        : `/classes/${classroom}/exams/${itemId}`;
    return (
      <Button
        className='question-tracker-modal__view-button'
        color='white'
        onClick={this.closeModal}
        small
        to={slug}
      >
        View
      </Button>
    );
  };

  getTemplateViewButtonContent = (item) => {
    return (
      <Button
        className='question-tracker-modal__view-button'
        color='white'
        onClick={this.closeModal}
        small
        to={`/assignment/${item.getId()}`}
      >
        View
      </Button>
    );
  };

  closeModal() {
    hideModal('questionTracker');
  }

  render() {
    return (
      <Modal hideHeader modalKey='questionTracker' modalClass='question-tracker-modal'>
        <ModalContent>
          <div
            className='question-tracker-modal__top-close-button simple-modal__close-btn'
            onClick={this.closeModal}
            role='button'
            tabIndex={0}
          >
            <span className='fa fa-times' />
          </div>
          <div className='question-tracker-modal__header'>
            <div className='question-tracker-modal__header-name'>Question usage</div>
            <div className='question-tracker-modal__header-description'>
              This question is used in the following places. Note that the assignment list reflects
              assignments for active classrooms only.
            </div>
          </div>
          <Tabs content={this.getTabsData()}>
            <MandarkTable mobileBreakpoint={600} query={this.getQuery()}>
              <Column
                content={
                  this.isTemplateTab()
                    ? this.getTemplateDetailsContent
                    : this.getAssignmentDetailsContent
                }
              />
              <Column
                content={
                  this.isTemplateTab()
                    ? this.getTemplateViewButtonContent
                    : this.getAssignmentViewButtonContent
                }
              />
            </MandarkTable>
          </Tabs>
          <div className='question-tracker-modal__bottom-close-button-wrapper'>
            <Button
              className='question-tracker-modal__bottom-close-button'
              color='green'
              onClick={this.closeModal}
            >
              Done
            </Button>
          </div>
        </ModalContent>
      </Modal>
    );
  }
}
