import React from 'react';
import PropTypes from 'prop-types';
import {callAction} from 'client/framework';
import SimpleModal from 'generic/SimpleModal/SimpleModal.react';
import SubmitSectionModalBody from './shared/SubmitSectionModalBody.react';
import assignmentV2IEActions from '../AssignmentV2IE.actions';
import assignmentV2IEStore from '../AssignmentV2IE.store';

export default class SubmitUnfinishedSectionModal extends React.PureComponent {
  static propTypes = {
    shouldShow: PropTypes.bool,
    title: PropTypes.string
  };

  static defaultProps = {
    title: 'You have unanswered questions'
  };

  dismissSubmitSectionModal = () => {
    if (assignmentV2IEStore.isRequestPending()) {
      return;
    }
    callAction(assignmentV2IEActions.SET_SHOW_SUBMIT_SECTION_MODAL, false);
  };

  render() {
    if (!this.props.shouldShow) {
      return null;
    }
    return (
      <SimpleModal
        title={this.props.title}
        className='submit-section-modal'
        onModalClose={this.dismissSubmitSectionModal}
      >
        <SubmitSectionModalBody />
      </SimpleModal>
    );
  }
}
