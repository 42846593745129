import React, {useContext, useRef, useState} from 'react';
import {unparse} from 'papaparse';
import download from 'downloadjs';

import {upperFirst} from 'lib/stringUtils';
import {
  Button,
  Chip,
  Dialogue,
  Dropdown,
  Icon,
  LoadingSpinner,
  Modal,
  Text,
  WithToggle
} from '@albert-io/atomic';
import {resource} from '@albert-io/json-api-framework/request/builder';

import {ReportsContext} from 'client/Reports/Reports.context';
import {State, fetchDataForCsv} from 'client/Reports/useReportRoute';
import {
  DIMENSIONS,
  DIMENSION_COLUMNS,
  columnIsAvailable,
  getClassroomQuery,
  isDrillingDown
} from 'client/Reports/reports.utils';

import {Column, MetricColumn, AnalyticsApiResult, Dimension} from 'client/Reports/reports.types';

import {uniqBy} from 'lodash';

import {
  createStudentAssignmentMetricMatrix,
  createStudentAssignmentMetricMatrixKey
} from '../GradebookTable/GradebookTable.react';

import ExcelExport from './ExcelExport.react';
import './reports-export.scss';

interface ModalProps {
  CloseButtonWrapper: ({children}: PropsWithChildrenRequired) => JSX.Element;
  modalContentStyle: string;
}

interface ErrorModalProps extends ModalProps {
  createCsv: () => void;
}

const ErrorModal = ({CloseButtonWrapper, modalContentStyle, createCsv}: ErrorModalProps) => {
  return (
    <Dialogue inModal hideCloseBtn className={modalContentStyle} size='s'>
      <div className='u-display_flex u-flex-direction_column u-align-items_center u-justify-content_center'>
        <Icon
          size='standalone'
          iconStyle='light'
          icon='exclamation-circle'
          className='u-color_red-500 fa-3x'
        />
        <Text className='u-mar-t_3 u-color_slate-700 u-font-weight_bold'>Something went wrong</Text>
        <Text className='u-mar-tb_3 u-color_slate-700 u-text-align_center'>
          A problem occurred while exporting your report.
        </Text>
        <Button
          variant='outlined'
          className='u-width_100pc u-color_blue-500 u-mar-b_1 u-display_flex u-white-space_nowrap'
          onClick={() => createCsv()}
        >
          <Icon icon='sync' className='u-mar-r_1' />
          Retry
        </Button>
        <CloseButtonWrapper>
          <Button variant='outlined' className='u-width_100pc u-color_slate-700'>
            Cancel
          </Button>
        </CloseButtonWrapper>
      </div>
    </Dialogue>
  );
};

const LoadingModal = ({CloseButtonWrapper, modalContentStyle}: ModalProps) => {
  return (
    <Dialogue inModal hideCloseBtn className={modalContentStyle} size='s'>
      <div className='u-display_flex u-flex-direction_column u-align-items_center u-justify-content_center'>
        <LoadingSpinner size={4} className='u-color_slate-300' />
        <Text className='u-mar-tb_3 u-color_slate-700'>Generating report...</Text>
        <CloseButtonWrapper>
          <Button variant='outlined' className='u-width_100pc u-color_slate-700'>
            Cancel
          </Button>
        </CloseButtonWrapper>
      </div>
    </Dialogue>
  );
};

const getDimension = (dimension: Dimension, result: AnalyticsApiResult) => {
  switch (dimension) {
    case DIMENSIONS.assignments:
      return result.assignment || result.secondary_assignment;
    case DIMENSIONS.questions:
      return result.question;
    case DIMENSIONS.standards:
      return result.standard;
    case DIMENSIONS.students:
      return result.student;
    default:
      return null;
  }
};

const getAllReportPages = async (
  request: Pick<
    State,
    | 'variables'
    | 'dimensions'
    | 'sort'
    | 'metrics'
    | 'performanceCalculateBy'
    | 'topLevelFilterType'
  >
) => {
  const {dimensions} = request;
  const fullData = await fetchDataForCsv(request);

  if (request.dimensions.length === 2) {
    const studentAssignmentMetricMatrix = createStudentAssignmentMetricMatrix(fullData);
    const allFirstDimensions = fullData.map((d) => getDimension(dimensions[0], d));
    const uniqueFirstDimensions = uniqBy(allFirstDimensions, 'id');
    // for each unique first dimension, get all the second dimensions
    return uniqueFirstDimensions.flatMap((firstDimension) => {
      const secondDimensions = fullData
        .filter(
          (result) =>
            getDimension(dimensions[0], result).id === firstDimension.id &&
            result.metrics.dimensions_are_related
        )
        .map((result) => getDimension(dimensions[1], result));

      return secondDimensions.map(
        (secondDimension) =>
          studentAssignmentMetricMatrix[
            createStudentAssignmentMetricMatrixKey(firstDimension.id, secondDimension.id)
          ]
      );
    });
  }

  return fullData;
};

/* @todo - format appropriate numbers */
function ReportsExport({
  disabled,
  metrics,
  path,
  isCollapsed
}: {
  disabled: boolean;
  metrics: MetricColumn[];
  path: string[];
  isCollapsed?: boolean;
}) {
  const {
    assignmentId,
    questionId,
    subjectId: subjectIdFromDrilldown,
    subjectIdFromSearchParam,
    standardId,
    studentId,
    variables,
    sort,
    dimensions,
    performanceCalculateBy,
    topLevelFilterType,
    topLevelFilterId,
    isColumnEnabledOrSoftToggleable,
    isColumnSoftDisabled
  } = useContext(ReportsContext);
  const toggleRef = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [csvError, setCsvError] = useState(false);

  /* We make a copy of the path array that we can freely mutate */
  const breadcrumbTrail = [...path];
  const lastThreeItems = breadcrumbTrail.splice(path.length - 3);
  const [dimensionInFocus, _dimensionInFocusId, _currDimension] = lastThreeItems;
  const subjectId = subjectIdFromDrilldown || subjectIdFromSearchParam;

  const createCsv = async () => {
    try {
      let filename: string;
      if (dimensions.length === 1) {
        const fetchGuessFactsForFilename = async () => {
          const stem = await getFileStemFromResponse(dimensionInFocus);
          return `${stem} - ${upperFirst(dimensions[0])}.csv`;
        };

        filename = isDrillingDown(path)
          ? await fetchGuessFactsForFilename()
          : `${upperFirst(dimensions[0])}.csv`;
      } else {
        const classroom = await getClassroomQuery(topLevelFilterId).getResourcePromise();
        const classroomName = classroom.getName().replace(/[^a-zA-Z0-9 ]/g, '');
        filename = `${classroomName} - Gradebook.csv`;
      }

      setCsvError(false);
      const fullData = await getAllReportPages({
        metrics,
        variables,
        dimensions,
        sort,
        performanceCalculateBy,
        topLevelFilterType
      });

      const columns: Column[] = uniqBy(
        dimensions
          .flatMap((dimension) => {
            return [...DIMENSION_COLUMNS[dimension]] as Column[];
          })
          .concat(metrics)
          .filter((m) => columnIsAvailable(m, dimensions, variables, topLevelFilterType)),
        'key'
      );

      const headers: string[] = [];

      columns.forEach((column) => {
        const {columnType, export: renamedColumnExport, exportHeaders} = column;
        if (
          columnType === 'dimension' &&
          renamedColumnExport !== undefined &&
          isColumnEnabledOrSoftToggleable(column)
        ) {
          const {dimension} = column;

          const dimensionSingular = {
            assignments: 'Assignment',
            questions: 'Question',
            standards: 'Standard',
            students: 'Student'
          }[dimension];
          let dimensionHeaders = exportHeaders(dimensions);

          if (dimensions.length > 1) {
            dimensionHeaders = dimensionHeaders.map((header) => `${dimensionSingular} ${header}`);
          }

          headers.push(...dimensionHeaders);
        } else if (columnType === 'metric') {
          const metricHeaders = exportHeaders(dimensions, variables, {performanceCalculateBy});
          headers.push(...metricHeaders);
        }
      });

      const csvData = fullData.reduce((arr: Object[], result) => {
        const row: string[] = [];
        columns.forEach((column) => {
          const {columnType, export: renamedColumnExport} = column;

          if (
            columnType === 'dimension' &&
            renamedColumnExport &&
            isColumnEnabledOrSoftToggleable(column)
          ) {
            const values = renamedColumnExport(result, isColumnSoftDisabled(column));
            row.push(...values);
          } else if (columnType === 'metric') {
            const values = renamedColumnExport(result, dimensions, variables, {
              performanceCalculateBy
            });
            values.forEach((v) => row.push(v));
          }
        });

        arr.push(row);
        return arr;
      }, []);

      const csv = unparse({
        fields: headers,
        data: csvData
      });

      download(csv, filename, 'text/csv');
      setModalOpen(false);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setCsvError(true);
    }
  };

  const getFileStemFromResponse = async (targetDimension) => {
    if (targetDimension === DIMENSIONS.assignments) {
      const assignment = await resource('assignments_v3')
        .mandarkEndpoint(['assignments_v3', assignmentId])
        .getResourcePromise();
      return assignment.getName();
    }
    if (targetDimension === DIMENSIONS.questions) {
      const question = await resource('questions_v3')
        .mandarkEndpoint(['questions_v3', questionId])
        .getResourcePromise();
      return question.getTitle();
    }
    if (targetDimension === DIMENSIONS.standards) {
      const standard = await resource('standards_v1')
        .mandarkEndpoint(['standards_v1', standardId])
        .getResourcePromise();
      return standard.getTitle();
    }
    if (targetDimension === DIMENSIONS.students) {
      const student = await resource('students_v1')
        .mandarkEndpoint(['students_v1', studentId])
        .getResourcePromise();
      return `${student.getLastName()}, ${student.getFirstName()}`;
    }

    return 'Unknown';
  };

  if (dimensions.length === 0) {
    return (
      <Button className='u-white-space_nowrap' color='secondary' variant='outlined' disabled>
        <Icon className='u-mar-r_1' icon='download' />
        Download Report
      </Button>
    );
  }

  const excelUnavailable = topLevelFilterType !== 'classrooms' || !subjectId === !assignmentId;

  const options = [
    {
      title: 'CSV Report',
      fileType: '.CSV',
      description: 'Your filter, metric and column selections will affect this report.',
      button: (
        <Button
          variant='outlined'
          disabled={disabled}
          onClick={() => {
            setModalOpen(true);
            createCsv();
          }}
        >
          Download
        </Button>
      )
    },
    excelUnavailable
      ? {
          unavailable: true,
          message:
            '(Excel reports are only available for subject level reports or single assignment reports.)'
        }
      : {
          title: 'Excel Report',
          fileType: '.XLSX',
          description:
            'Includes student breakdowns. Includes all data, ignoring your filter and metric selections.',
          button: <ExcelExport disabled={disabled} />
        }
  ];

  return (
    <WithToggle>
      {({on, onClick}) => (
        <div>
          {modalOpen ? (
            <Modal ariaLabel='Creating CSV Report...' handleClose={() => setModalOpen(false)}>
              {({CloseButtonWrapper, modalContentStyle}) => {
                return csvError ? (
                  <ErrorModal
                    CloseButtonWrapper={CloseButtonWrapper}
                    modalContentStyle={modalContentStyle}
                    createCsv={createCsv}
                  />
                ) : (
                  <LoadingModal
                    CloseButtonWrapper={CloseButtonWrapper}
                    modalContentStyle={modalContentStyle}
                  />
                );
              }}
            </Modal>
          ) : null}
          <Button
            className='u-white-space_nowrap'
            color='secondary'
            variant='outlined'
            disabled={disabled}
            onClick={onClick}
            ref={toggleRef}
            size={isCollapsed ? 's' : 'm'}
          >
            Download Report
            <Icon className='u-mar-l_1' icon='caret-down' />
          </Button>
          {on && (
            <Dropdown.Tray
              className='u-pad_2 u-mar-t_1 reports-export-dropdown u-border-radius_2 u-bgc_white u-display_block u-border u-border-color_slate-300 u-overflow_hidden'
              positionFixed
              placement='bottom-start'
              target={toggleRef.current}
            >
              {options.map((option) => {
                if (option.unavailable) {
                  return (
                    <Text
                      as='div'
                      italic
                      color='secondary'
                      size='s'
                      className='u-text-align_center u-pad_2'
                    >
                      {option.message}
                    </Text>
                  );
                }

                return (
                  <div
                    key={option.title}
                    className='u-display_flex u-justify-content_space-between u-pad_2'
                  >
                    <div className='u-display_flex u-flex-direction_column'>
                      <div className='u-display_flex u-align-items_center'>
                        <Text bold>{option.title}</Text>
                        <Chip size='small' className='reports-export__file-type-chip'>
                          {option.fileType}
                        </Chip>
                      </div>
                      <Text color='secondary' size='s'>
                        {option.description}
                      </Text>
                    </div>
                    <div className='u-mar-l_4'>{option.button}</div>
                  </div>
                );
              })}
            </Dropdown.Tray>
          )}
        </div>
      )}
    </WithToggle>
  );
}

export default ReportsExport;
