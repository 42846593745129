/**
 * # Icon
 *
 * A component that renders an icon using FontAwesome library.
 *
 * ## Props
 *
 * - `className` (optional): Additional CSS class name for the icon.
 * - `color` (optional): The color of the icon. Possible values are:
 *   - `'primary'`
 *   - `'primary-inverse'`
 *   - `'primary-inverse-static'`
 *   - `'secondary'`
 *   - `'tertiary'`
 *   - `'brand'`
 *   - `'inherit'`
 *   - `'link'`
 *   - `'negative'`
 *   - `'positive'`
 *   - `'warning'`
 *   - `'info'`
 * - `icon`: The name of the icon to be rendered. It can be a string representing the icon name, an array of `[prefix, iconName]`, or an `IconLookup` object.
 * - `size` (optional): The size of the icon. Possible values are:
 *   - `'inherit'`
 *   - `'standalone'`
 * - `emphasis` (optional): The emphasis level of the icon. Possible values are:
 *   - `'high'`
 *   - `'medium'`
 *   - `'low'`
 *   - `'lowest'`
 *   - `'disabled'`
 * - `iconStyle` (optional): The style of the icon. Possible values are:
 *   - `'light'`
 *   - `'regular'`
 *   - `'solid'`
 * - `pulse` (optional): Whether the icon should have a pulse animation.
 * - `spin` (optional): Whether the icon should have a spin animation.
 * - `style` (optional): Additional inline styles for the icon.
 * - `fixedWidth` (optional): Whether the icon should have a fixed width.
 *
 * ## Usage
 *
 * ```tsx
 * import Icon from './Icon';
 *
 * const MyComponent = () => {
 *   return (
 *     <div>
 *       <Icon icon="check" color="success" size="inherit" />
 *       <Icon icon={['fab', 'github']} color="black" size="standalone" />
 *     </div>
 *   );
 * };
 * ```
 */

import React from 'react';
import {isArray} from 'lodash';
import classnames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconName, IconPrefix, IconLookup} from '@fortawesome/fontawesome-common-types';

import '../../helpers/FontAwesome';
import './icon.scss';

function getFontAwesomeStyle(iconStyle: IconStyle): IconPrefix {
  return {
    light: 'fal',
    regular: 'far',
    solid: 'fas'
  }[iconStyle] as IconPrefix;
}

export const colors = [
  'primary',
  'primary-inverse',
  'primary-inverse-static',
  'secondary',
  'tertiary',
  'brand',
  'inherit',
  'link',
  'negative',
  'positive',
  'warning',
  'info'
] as const;

export type IconStyle = 'light' | 'regular' | 'solid';
export type IconProp = IconName | [IconPrefix, IconName] | IconLookup;
export type IconColors = (typeof colors)[number];

export interface Props extends PropsWithClassNameOptional {
  className?: string;
  color?: IconColors;
  icon: IconProp;
  size?: 'inherit' | 'standalone';
  emphasis?: 'high' | 'medium' | 'low' | 'lowest' | 'disabled';
  iconStyle?: IconStyle;
  pulse?: boolean;
  spin?: boolean;
  style?: any;
  fixedWidth?: boolean;
}

export const Icon = ({
  className,
  color = 'inherit',
  icon,
  size = 'inherit',
  emphasis,
  iconStyle = 'solid',
  ...rest
}: Props) => {
  let iconArray = icon;
  if (!isArray(iconArray)) {
    iconArray = [getFontAwesomeStyle(iconStyle), icon as IconName];
  }
  const faSizes = ['fa-fw', 'fa-xs', 'fa-sm', 'fa-lg', 'fa-2x', 'fa-3x', 'fa-4x', 'fa-5x'];
  const customClassHasSize =
    className && faSizes.some((sizeClass) => className.includes(sizeClass));
  return (
    <FontAwesomeIcon
      {...rest}
      icon={iconArray}
      className={classnames(className, 'a-icon', {
        [`content-${color}`]: color,
        [`a-icon--size-${size}`]: size && !customClassHasSize,
        [`a-icon--emphasis-${emphasis}`]: color === 'primary' && emphasis
      })}
    />
  );
};

export default Icon;
