import {resource} from '@albert-io/json-api-framework/request/builder';
import sessionStore from 'client/Session/SessionStore';

export const getSupplementsQuery = ({owner, searchString = '', subject, type}) =>
  resource('authoring_supplements_v1')
    .mandarkEndpoint(['authoring_supplements_v1'])
    .pageSize(10)
    .include('authoring_subject_v1')
    .customQuery({
      with_meta: 'authoring_supplement_v1'
    })
    .filter(
      {
        authoring_subject_v1: {
          id: subject
        }
      },
      subject
    )
    .filter(
      {
        type
      },
      type
    )
    .filter(
      {
        owner_v2: {
          id: owner
        }
      },
      sessionStore.isSuper() && owner
    )
    .filter(
      {
        name: {
          case_insensitive_substring: encodeURIComponent(searchString)
        }
      },
      searchString
    );

export const getAuthorByIdQuery = (id) => resource('author_v1').mandarkEndpoint(['authors_v1', id]);

export const getAuthorsByEmailQuery = (searchString) =>
  resource('author_v1')
    .mandarkEndpoint(['authors_v1'])
    .filter({email: {case_insensitive_substring: encodeURIComponent(searchString)}})
    .fields({authors_v1: 'id, email'});
