/* eslint-disable no-underscore-dangle */
import {camelCase, upperFirst} from 'lodash';
import {fromJS, Map} from 'immutable';
import {addToast} from '@albert-io/atomic';

import {Store} from 'client/framework';
import appStore from 'client/AppStore';
import sessionStore from 'client/Session/SessionStore';
import {history} from 'client/history';
import {invalidatePartialInterest} from 'resources/mandark.resource';
import {resource} from '@albert-io/json-api-framework/request/builder';

import supplementManagerActions from 'client/Supplements/SupplementManager/SupplementManager.actions';
import {
  getQueueQuestionEditPath,
  getQuestionSetParams,
  getQuestionSetsQuery
} from 'client/Dennis/Content/Queue/shared';
import {AuthoringQuestionModelV1} from 'resources/augmented/AuthoringQuestion/AuthoringQuestionModel.v1';
import {AuthoringQuestionSetModelV1} from 'resources/GeneratedModels/AuthoringQuestionSet/AuthoringQuestionSetModel.v1';
import constants from 'client/constants';
import makePayloadOmitter from 'lib/makePayloadOmitter';

import questionEditorActions from './QuestionEditor.actions';

const popToast = (color, text) => {
  addToast({
    color,
    message: text,
    duration: 4000
  });
};

export class QuestionEditorStore extends Store {
  constructor(name) {
    super(name);
    this.setInitialData(
      fromJS({
        savePromise: null,
        pendingNewQuestions: [],
        question: null,
        questionSet: null,
        isReady: false,
        savePending: false,
        cursorPosition: {},
        newQuestionType: '',
        subjectId: '',
        hasChanges: false,
        validationErrors: {},
        isPreviewMode: false
      })
    );
    this.handle(questionEditorActions.INITIALIZE_STORE, this._initialize);
    this.handle(questionEditorActions.SAVE_QUESTION, this._saveQuestion);
    this.handle(questionEditorActions.SAVE_CURSOR_POSITION, this._saveCursorPosition);
    this.handle(questionEditorActions.SET_IS_PREVIEW_MODE, this.setProperty('isPreviewMode'));
    this.handle(questionEditorActions.SET_SUBJECT_ID, this._setSubjectId);
    this.handle(questionEditorActions.SUBMIT_FOR_REVIEW, this._submitForReview);
    this.handle(questionEditorActions.REVERT_TO_DRAFT, this._revertToDraft);
    this.handle(questionEditorActions.APPROVE_QUESTION_SET, this._approveQuestionSet);
    this.handle(questionEditorActions.REASSIGN_QUESTION_SET, this._reassignQuestionSet);
    this.handle(questionEditorActions.REJECT_QUESTION_SET, this._rejectQuestionSet);
    this.handle(questionEditorActions.UPDATE_QUESTION, this._updateQuestion);
    this.handle(questionEditorActions.UPDATE_QUESTION_DETAILS, this._updateQuestionDetails);
    this.handle(questionEditorActions.UPDATE_QUESTION_SET_STATUS, this._updateQuestionSetStatus);
    this.handle(questionEditorActions.UPDATE_QUESTION_SET_TAGS, this._updateQuestionSetTags);
    this.handle(questionEditorActions.UPDATE_QUESTION_STANDARDS, this._updateQuestionStandards);
    this.handle(questionEditorActions.VALIDATE_QUESTION_FIELD, this._validateQuestionField);
    this.handle(questionEditorActions.RESET_STORE, this.resetStore);
    this.handle(
      supplementManagerActions.INSERT_SUPPLEMENT_AT_CURSOR_POSITION,
      this._insertSupplementAtCursorPosition
    );
  }

  async _initialize(type) {
    this.writeData('isReady', false);

    // This will ensure that going from a new question to a new question will trigger the update logic
    // that question editors may need in order to re-initialize themselves
    await new Promise((res) => setTimeout(res, 0));

    let question;
    let questionSet;

    const {questionId, questionSetId} = getQuestionSetParams();
    try {
      question = questionId
        ? await this.getQuestionQuery().getResourcePromise()
        : AuthoringQuestionModelV1.getDefaultModel()
            .setTranslate(true)
            .setTranslateSupplements(true);
      questionSet = questionSetId
        ? await this.getQuestionSetQuery().getResourcePromise()
        : AuthoringQuestionSetModelV1.getDefaultModel();
    } catch (e) {
      popToast('negative', 'There was a problem fetching the question.');
    }

    if (type) {
      this._setNewQuestionType(type);
    }

    if (questionSetId && !questionId && !this.getQuestionType()) {
      /* eslint-disable-next-line no-shadow */
      const questionId = questionSet.getAuthoringQuestions().first().getId();
      history.pushState(null, `${appStore.routerProps.location.pathname}/${questionId}`);
    }

    if (!questionId) {
      question = question.setDefaultsByQuestionType(this.getQuestionType());
    }

    if (!questionSetId) {
      questionSet = questionSet
        .setStatus('draft')
        .setSubjectId(this.getSubjectId())
        .addRelationship({
          type: 'authoring_subject_v1',
          relation: this.getSubjectId()
        })
        .addRelationship({
          type: 'author_v1',
          relation: sessionStore.getUserId()
        })
        .addRelationship({
          type: 'owner_v2',
          relation: sessionStore.getUserId()
        });
    }
    this.writeData('question', question);
    this.writeData('questionSet', questionSet);
    this.writeData('validationErrors', Map());
    this.writeData('isReady', true);
    this.writeData('hasChanges', false);
  }

  _insertSupplementAtCursorPosition({fieldPath, supplement}) {
    /* eslint-disable-next-line no-param-reassign */
    fieldPath = Array.isArray(fieldPath) ? fieldPath : fieldPath.split(',');
    const cursorPosition = this.getCursorPosition(fieldPath);
    const start = cursorPosition.get('start');
    const end = cursorPosition.get('end');
    const escapedName = supplement.getName().replace(/[:]/g, '-');
    const supplementShortcode = `[s:${supplement.getId()}:${escapedName}:${supplement.getType()}]`;
    const stringToModify = this.getQuestion().getIn(fieldPath, '');
    let modifiedString;
    if (start && end) {
      modifiedString =
        stringToModify.substr(0, start) + supplementShortcode + stringToModify.substr(end);
    } else {
      modifiedString = stringToModify + supplementShortcode;
    }
    const updatedQuestion = this.getQuestion().setFieldIn(fieldPath, modifiedString);
    this.writeData('question', updatedQuestion);
    this._setHasChanges();
  }

  _saveCursorPosition({start, end, name}) {
    const updatedCursorPosition = this.getCursorPosition()
      .setIn(name.split(',').concat('start'), start)
      .setIn(name.split(',').concat('end'), end);
    this.writeDataNoEmit('cursorPosition', updatedCursorPosition);
  }

  _saveQuestion = async () => {
    let resolve = null;
    const savePromise = new Promise((res) => {
      resolve = res;
    });
    this.writeData('savePromise', savePromise);
    const question = this.getQuestion();
    const questionSet = this.getQuestionSet();

    if (this.hasErrors()) {
      this._validateQuestion();
      popToast('negative', 'The question could not be saved due to validation errors.');
      return;
    }

    // Mandark needs the payload to be validResponse instead of valid_response ¯\_(ツ)_/¯
    const rubricValidResponse = question.getRubric().get('valid_response', Map());
    const updatedRubric = question
      .getRubric()
      .set('validResponse', rubricValidResponse)
      .remove('valid_response');
    const formattedQuestion = question.setRubric(updatedRubric);
    this.writeData('savePending', true);
    try {
      const savedQuestionSet = await questionSet
        .replaceRelationship({
          type: 'tags_v1',
          relation: questionSet.getTags().map((tag) => tag.getId())
        })
        .save({
          customQuery: this._getSaveQuestionSetCustomQuery(),
          payloadMutator: makePayloadOmitter({
            reorder_questions: (val) => !val || val.length === 0,
            slug_id: (val) => !val
          })
        });
      const savedQuestion = await formattedQuestion
        .replaceRelationship({
          type: 'standards_v1',
          relation: question.getStandards().map((standard) => standard.getId())
        })
        .addRelationship({
          type: 'authoring_question_set_v1',
          relation: savedQuestionSet.getId()
        })
        .save({
          customQuery: this._getSaveQuestionCustomQuery(),
          payloadMutator: makePayloadOmitter({
            published: () => true
          })
        });
      this._clearHasChanges();
      popToast('positive', 'The question was successfully saved.');
      this.writeData('questionSet', savedQuestionSet);
      this.writeData('question', savedQuestion);
      if (!question.getId()) {
        history.pushState(
          null,
          getQueueQuestionEditPath(savedQuestionSet.getId(), savedQuestion.getId())
        );
      }
    } catch (err) {
      logger.error('There was an error saving a question: ', err.detail || err);
      popToast('negative', 'There was a problem saving the question.');
    } finally {
      this.writeData('savePending', false);
      invalidatePartialInterest({
        resourcePath: getQuestionSetsQuery().done().resourcePath
      });
    }
    resolve();
    this.writeData('savePromise', null);
  };

  async _saveTag(tagModel) {
    /* eslint-disable-next-line @typescript-eslint/return-await */
    return await tagModel.save();
  }

  _submitForReview() {
    this._saveNewQuestionSetStatus({
      status: constants.QUESTIONSET_STATUSES.IN_REVIEW
    });
  }

  _revertToDraft() {
    this._saveNewQuestionSetStatus({
      status: constants.QUESTIONSET_STATUSES.DRAFT_MODE
    });
  }

  _approveQuestionSet() {
    this._saveNewQuestionSetStatus({
      status: constants.QUESTIONSET_STATUSES.PUBLISHED
    });
  }

  _rejectQuestionSet() {
    this._saveNewQuestionSetStatus({
      status: constants.QUESTIONSET_STATUSES.REJECTED
    });
  }

  async _saveNewQuestionSetStatus({status}) {
    const successMessage = 'Status updated and changes saved.';
    await this._saveQuestion({skipSaveToast: true});

    this.writeData('savePending', true);
    try {
      const updatedQuestionSet = await this.getQuestionSet().setStatus(status).save({
        customQuery: this._getSaveQuestionSetCustomQuery()
      });
      this.writeData('questionSet', updatedQuestionSet);
      popToast('positive', successMessage);
      invalidatePartialInterest({
        resourcePath: getQuestionSetsQuery().done().resourcePath
      });
    } catch (err) {
      logger.error('There was an error updating question set status: ', err.message || err);
      popToast('negative', 'We encountered a problem. Please try again.');
    } finally {
      this.writeData('savePending', false);
    }
  }

  async _reassignQuestionSet(newAuthorId) {
    this.writeData('savePending', true);
    try {
      if (newAuthorId && this.getQuestionSet().getMeta().getAuthorId() !== newAuthorId) {
        const updatedQuestionSet = await this.getQuestionSet()
          .replaceRelationship({
            type: 'author_v1',
            relation: newAuthorId
          })
          .replaceRelationship({
            type: 'owner_v1',
            relation: newAuthorId
          })
          .save({
            customQuery: this._getSaveQuestionSetCustomQuery()
          });
        invalidatePartialInterest({
          resourcePath: getQuestionSetsQuery().done().resourcePath
        });
        this.writeData('questionSet', updatedQuestionSet);
        popToast('positive', 'Successfully reassigned question set!');
      }
    } catch (err) {
      logger.error("There was an error reassining a question set's author: ", err.message || err);
      popToast('negative', 'We encountered a problem. Please try again.');
    } finally {
      this.writeData('savePending', false);
    }
  }

  _setSubjectId(subjectId) {
    this.writeData('subjectId', subjectId);
  }

  _setNewQuestionType(type) {
    this.writeData('newQuestionType', type);
  }

  _validateQuestion = () => {
    const validationErrors = Object.entries(this.getQuestion().validators).reduce(
      (acc, [key, func]) => {
        const errors = func();
        if (errors) {
          const fieldName = key.replace(/get\w/, (match) => match[match.length - 1].toLowerCase());
          return acc.set(fieldName, errors.first());
        }
        return acc;
      },
      Map()
    );
    this.writeData('validationErrors', validationErrors);
  };

  _validateQuestionField(validateMethod) {
    const functionToValidate = `get${upperFirst(camelCase(validateMethod))}`;
    const error = this.getQuestion().validators[functionToValidate]();
    const errorMessage = error ? error.first() : null;
    const validationErrors = this.getValidationErrors().set(validateMethod, errorMessage);
    this.writeData('validationErrors', validationErrors);
  }

  _updateQuestion(question) {
    this.writeData('question', question);
    this._setHasChanges();
  }

  _updateQuestionDetails({setter, value}) {
    const updatedQuestion = this.getQuestion()[setter](value);
    this.writeData('question', updatedQuestion);

    const questionSet = this.getQuestionSet();
    const countOfQuestions = questionSet.getMeta().getCountOfQuestions() || 0;

    if (setter === 'setTitle' && countOfQuestions <= 1) {
      const updatedQuestionSet = questionSet.setName(value);
      this.writeData('questionSet', updatedQuestionSet);
    }

    this._setHasChanges();
  }

  _updateQuestionSetStatus({status}) {
    const updatedQuestionSet = this.getQuestionSet().setStatus(status);
    this.writeData('questionSet', updatedQuestionSet);
    this._setHasChanges();
  }

  _updateQuestionSetTags({tag, remove}) {
    const questionSet = this.getQuestionSet();
    let tags = questionSet.getTags();
    const hasTag = tags.find((existingTag) => {
      return existingTag.getName() === tag.getName();
    });
    if (!remove && hasTag) {
      return;
    }
    if (remove) {
      const tagIndex = tags.indexOf(tag);
      tags = tags.delete(tagIndex);
    } else if (tag.existsOnServer() === true) {
      tags = tags.push(tag);
    }
    const updatedQuestionSet = questionSet.setField('tags', tags);
    this.writeData('questionSet', updatedQuestionSet);
    this._setHasChanges();
  }

  _updateQuestionStandards(standards) {
    const question = this.getQuestion();
    const updatedQuestion = question.setField('standards', standards);
    this.writeData('question', updatedQuestion);
    this._setHasChanges();
  }

  _clearHasChanges() {
    this.writeData('hasChanges', false);
  }

  _setHasChanges() {
    if (!this.hasChanges()) {
      this.writeData('hasChanges', true);
    }
  }

  /**
   * Since we repopulate the store with the saved question, we have
   * to make sure the response has all the proper includes and metadata.
   *
   * @example
   * .save({ customQuery: this._getSaveQuestionCustomQuery() })
   */
  _getSaveQuestionCustomQuery() {
    return {
      include: this.getQuestionQuery().done().include
    };
  }

  /**
   * Since we repopulate the store with the saved question set, we have
   * to make sure the response has all the proper includes and metadata.
   *
   * @example
   * .save({ customQuery: this._getSaveQuestionSetCustomQuery() })
   */
  _getSaveQuestionSetCustomQuery() {
    const builtQuestionSetQuery = this.getQuestionSetQuery().done();
    return {
      include: builtQuestionSetQuery.include,
      customQuery: builtQuestionSetQuery.customQuery
    };
  }

  getQuestionQuery() {
    const {questionId} = appStore.routerProps.params;
    return resource('authoring_question_v1')
      .mandarkEndpoint(['authoring_questions_v1', questionId])
      .withMeta('authoring_question_v1')
      .include('authoring_question_set_v1.authoring_guide_levels_v1,authoring_supplements_v1')
      .include('authoring_question_set_v1.tags_v1')
      .include('authoring_question_set_v1.author_v1', sessionStore.isSuper())
      .include('standards_v1.standard_set_v1');
  }

  getQuestionSetQuery() {
    const {questionSetId} = appStore.routerProps.params;
    return resource('authoring_question_set_v1')
      .mandarkEndpoint(['authoring_question_sets_v1', questionSetId])
      .include('tags_v1')
      .include('authoring_guide_levels_v1')
      .include('sections_v1')
      .include('authoring_subject_v1')
      .include('authoring_questions_v1')
      .withMeta('authoring_question_set_v1');
  }

  getSavePromise() {
    return this.readData('savePromise');
  }

  getQuestion() {
    return this.readData('question');
  }

  getQuestionSet() {
    return this.readData('questionSet');
  }

  getQuestionType() {
    return this.readData('newQuestionType');
  }

  getSubjectId() {
    return this.readData('subjectId');
  }

  getCursorPosition(fieldPath = []) {
    return this.readData('cursorPosition').getIn(fieldPath, Map());
  }

  getValidationErrors() {
    return this.readData('validationErrors');
  }

  hasErrors() {
    return this.getQuestion().validators.hasValidationErrors();
  }

  hasChanges() {
    return this.readData('hasChanges');
  }

  isReady() {
    return this.readData('isReady');
  }

  isPreviewMode() {
    return this.readData('isPreviewMode');
  }

  isSavePending() {
    return this.readData('savePending');
  }
}

export default new QuestionEditorStore('QuestionEditorStore');
