import React from 'react';
import PropTypes from 'prop-types';
import {fromJS, Map} from 'immutable';
import IESubheader from 'client/InteractionEngineV2/shared/IESubheader/IESubheader.react';
import {AssignmentModelV3} from 'resources/GeneratedModels/Assignment/AssignmentModel.v3';
import {ClassroomModelV1} from 'resources/augmented/Classroom/ClassroomModel.v1';

export default class AssignmentV2IESubheader extends React.Component {
  static propTypes = {
    isReady: PropTypes.bool.isRequired,
    assignment: PropTypes.instanceOf(AssignmentModelV3),
    classroom: PropTypes.instanceOf(ClassroomModelV1),
    activeSection: PropTypes.string,
    rightContent: PropTypes.node
  };

  getContent() {
    if (!this.props.isReady) {
      return null;
    }
    let subheaderContent = fromJS([
      {
        displayName: 'Back to Class',
        to: `/classes/${this.props.classroom.getId()}/exams/${this.props.assignment.getId()}`
      },
      {
        displayName: this.props.assignment.getName()
      }
    ]);
    if (this.props.activeSection) {
      subheaderContent = subheaderContent.push(
        Map({
          displayName: this.props.activeSection
        })
      );
    }
    return subheaderContent;
  }

  render() {
    return (
      <IESubheader
        isLoading={!this.props.isReady}
        content={this.getContent()}
        rightContent={this.props.rightContent}
      />
    );
  }
}
