import {Map} from 'immutable';

import GenericExplanation from '../common/V2/GenericExplanation.react';

import MultipleChoiceQuestionEditor from './MultipleChoiceEditor';
import MultipleChoiceQuestionV2 from './V2/MultipleChoiceQuestion.react';
import MultipleChoiceResponseStats from './V2/MultipleChoiceResponseStats.react';

export const MultipleChoiceQuestionType = Map({
  type: 'multiple-choice',
  label: 'Multiple Choice',
  questionRenderer: MultipleChoiceQuestionV2,
  explanationRenderer: GenericExplanation,
  responseStatsRenderer: MultipleChoiceResponseStats,
  editor: MultipleChoiceQuestionEditor,
  isHidden: false
});
