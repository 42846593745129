import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {callTargetedAction, getStoreByName, setUpStore} from 'client/framework';
import blockyDropdownActions from './BlockyDropdownActions';
import BlockyDropdownStore from './BlockyDropdownStore';

/*
 * Oh snap, it's the <BlockyDropdown /> component! When you need a dropdown, but
 * it's gotta be fat and you're all about that #BlockyLife!
 *
 * The <BlockyDropdown /> component takes an Immutable.Iterable as a data prop and,
 * when clicked, it your data is turned into a nice little dropdown for the user to
 * select an item from.
 *
 * Props
 * storeName: PropTypes.string (required)
 * data: ImmutablePropTypes.iterable (required)
 *   Data which will be used to create the dropdown. When dropdown item is clicked,
 *   iterable item will be put in the component's store as the 'selectedItem'
 * defaultValue: PropTypes.any
 *   If passed, prop will be set as the selected value on componentDidMount
 * displayProperty: PropTypes.string
 *   When data is an Immutable.Map or a Immutable.List of objects, displayProperty specifies
 *   what key will be used as the display property for the item
 * label: PropTypes.string
 *   If passed, its contents will be displayed above the dropdown
 * noResults: PropTypes.bool
 *   If true, hide caret and display 'No Results Found' in BlockyDropdown
 * onChange: PropTypes.func
 *   If passed, function will run when the current value of the selected item is different
 *   than the value which the selected item is about to be set to
 * placeholder: PropTypes.string
 *   If passed, its contents will be displayed in the dropdown if no item is selected
 * small: PropTypes.bool
 *   If passed, the BlockyDropdown component will be smaller
 */

export default class BlockyDropdown extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    data: ImmutablePropTypes.iterable,
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool,
    displayProperty: PropTypes.string,
    label: PropTypes.string,
    noResults: PropTypes.bool,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    resetStoreOnUnmount: PropTypes.bool,
    small: PropTypes.bool,
    storeName: PropTypes.string.isRequired,
    errorMessage: PropTypes.string
  };

  static defaultProps = {
    resetStoreOnUnmount: true
  };

  constructor(props) {
    super(props);
    this.boundDropdownHandler = this.closeDropdown.bind(this);

    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    const defaultValue = this.props.defaultValue;
    if (defaultValue && !this.getStore().getSelectedItem()) {
      callTargetedAction({
        name: blockyDropdownActions.SET_SELECTED_ITEM,
        payload: defaultValue,
        targetStore: this.props.storeName
      });
    }
  }

  componentWillUnmount() {
    if (this.props.resetStoreOnUnmount) {
      callTargetedAction({
        name: blockyDropdownActions.RESET_STORE,
        targetStore: this.props.storeName
      });
    }
    global.document.removeEventListener('click', this.boundDropdownHandler);
  }

  getStore() {
    return setUpStore(BlockyDropdownStore, this.props.storeName);
  }

  closeDropdown(e) {
    const currentNode = this.wrapperRef.current;
    const isCurrentNode = currentNode.contains(e.target);
    if (!isCurrentNode) {
      callTargetedAction({
        name: blockyDropdownActions.CLOSE_DROPDOWN,
        targetStore: this.props.storeName
      });
      global.document.removeEventListener('click', this.boundDropdownHandler);
    }
  }

  openDropdown() {
    if (!this.props.data || this.props.data.isEmpty()) {
      return;
    }
    callTargetedAction({
      name: blockyDropdownActions.OPEN_DROPDOWN,
      targetStore: this.props.storeName
    });
    global.document.addEventListener('click', this.boundDropdownHandler);
  }

  render() {
    let label = null;
    const labelInsideInput = this.props.labelInsideInput;
    if (this.props.label) {
      label = <div className='blocky-dropdown__label form__label-text'>{this.props.label}</div>;
    }
    let selectedItem = this.getStore().getSelectedItem();
    if (selectedItem && this.props.displayProperty) {
      selectedItem = selectedItem.get(this.props.displayProperty);
    }
    if (selectedItem !== 'Choose' && labelInsideInput) {
      selectedItem += ` ${labelInsideInput}`;
    }
    selectedItem = selectedItem ? selectedItem : this.props.placeholder || String.fromCharCode(160); // &nbsp;
    const dropdown = (
      <Dropdown
        data={this.props.data}
        displayProperty={this.props.displayProperty}
        onChange={this.props.onChange}
        storeName={this.props.storeName}
      />
    );
    const dropdownClasses = classnames('blocky-dropdown', this.props.className, {
      'blocky-dropdown--small': this.props.small
    });
    // TODO: give this appropriate styles for the blocky dropdown; no time atm
    const error = this.props.errorMessage ? (
      <div className='a-form-input__element--error'>{this.props.errorMessage}</div>
    ) : null;
    const noResults = this.props.noResults;
    return (
      <div className={dropdownClasses} ref={this.wrapperRef}>
        {label}
        <div
          className={`blocky-dropdown__selection${this.props.disabled ? '--disabled' : ''} ${
            noResults ? 'blocky-dropdown__selection--no-results' : ''
          }`}
          onClick={() => {
            if (noResults) {
              return;
            }
            this.props.disabled ? null : this.openDropdown();
          }}
        >
          <span className='blocky-dropdown__selection__active-item'>
            {noResults ? 'No Results Found' : selectedItem}
          </span>
        </div>
        {error}
        {this.getStore().isDropdownOpen() ? dropdown : null}
      </div>
    );
  }
}

class Dropdown extends React.Component {
  static propTypes = {
    storeName: PropTypes.string
  };

  getStore() {
    return getStoreByName(this.props.storeName);
  }

  handleItemClick(item) {
    callTargetedAction({
      name: blockyDropdownActions.CLOSE_DROPDOWN,
      targetStore: this.props.storeName
    });

    const currentSelection = this.getStore().getSelectedItem();
    if (currentSelection === item) {
      return;
    }

    callTargetedAction({
      name: blockyDropdownActions.SET_SELECTED_ITEM,
      payload: item,
      targetStore: this.props.storeName
    });

    if (this.props.onChange) {
      this.props.onChange(item);
    }
  }

  render() {
    return (
      <div className='blocky-dropdown__results-wrapper'>
        {this.props.data.map((item, key) => {
          const displayProperty = this.props.displayProperty
            ? item.get(this.props.displayProperty)
            : item;
          return (
            <div
              className='blocky-dropdown__result'
              onClick={() => this.handleItemClick(item)}
              key={key}
            >
              {displayProperty}
            </div>
          );
        })}
      </div>
    );
  }
}
