import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {useTracking} from 'react-tracking';
import {mergeRefs} from 'lib/reactUtils';

// How to use this component:
// This component is used to track events on components within the Albert Atomic library.
// Actions are tracked via props including React synthetic events

// When to use useTracking vs @track decorator:
// This component is used to trigger a .trackEvent action which bubbles up to main.js and
// emits event data to our event analytics provider.

// The @track decorator is placed on top of class components to add additional context to the data
// sent up to main.js. (i.e. @track({ component: MyComponent }))

// Arguments:
// - Component
// import {Button} from '@albert-io/atomic';

// - events = {
//  onClick: { event: 'click', content: (e, props) => getNodeText(props.children) },
//  onPaste: { event: 'paste', value: (e) => e.clipboardData.getData('Text')}
// }

// Example Usage (functional component):
// import withTracking from 'lib/hocs/withTracking';
// import { Button } from '@albert-io/atomic';
// import track from 'react-tracking';
// import {getNodeText} from 'lib/reactUtils';
//
// const TrackedButton = withTracking(Button, {
//    onClick: { event: 'click', content: (e, props, ref) => getNodeText(ref) }
// })
// const TrackedInput = withTracking(Button, {
//    onPaste: {content: (e) => e.clipboardData.getData('Text'), event: 'paste', type: null}
// })
//
// @track({component: 'MyComponent'})
// class MyComponent extends React.Component {
// ...
//    render() {
//      return (
//        ...
//        <TrackedButton
//          onClick={someClickAction} />
//        <TrackedInput
//          onPaste={somePasteAction} />
//        ...
//      )
//    }
// }

// Example Usage (class component):
// import withTracking from 'lib/hocs/withTracking';
// import { Button } from '@albert-io/atomic';
// import track from 'react-tracking';
// import {getNodeText} from 'lib/reactUtils';
//

//
// withTracking(
//    class MyComponent extends React.Component {
//    ...
//       someFunction() {
//           // withTracking injects the trackEvent prop to the class component
//           this.props.trackEvent({ content: 'myCustomEventFired!' })
//           someOtherFunctionality()
//           ...
//       }
//
//       render() {
//         return (
//           ...
//           <Button
//             onClick={someFunction} />
//           ...
//         )
//       }
//    },
//    {} We leave this second arg empty because we aren't attaching to events being passed in as props (they are defined in the class)
// )

const withTracking = (Component, events) => {
  const WrappedComponent = React.forwardRef(({...props}, ref) => {
    const {trackEvent} = useTracking();
    const localRef = useRef(null);

    // wrap the function we are tracking with a trackEvent
    const trackFunctionWrapper = (e, functionName, eventTags) => {
      const populatedEventTags = Object.keys(eventTags).reduce((acc, current) => {
        if (typeof eventTags[current] === 'function') {
          // if the value of the event tag is a function, call it with the event, the component's props, and a ref to the component
          acc[current] = eventTags[current](e, props, localRef);
        } else {
          // else it's just a primitive value, so assign it
          acc[current] = eventTags[current];
        }
        return acc;
      }, {});
      trackEvent(populatedEventTags);
      if (props[functionName]) {
        props[functionName](e);
      }
    };

    const eventProps = Object.keys(events).reduce((acc, current) => {
      acc[current] = (e) => trackFunctionWrapper(e, current, events[current]);
      return acc;
    }, {});

    return (
      <Component
        {...props}
        {...eventProps}
        trackEvent={trackEvent}
        ref={mergeRefs(localRef, ref)}
      />
    );
  });

  WrappedComponent.propTypes = {
    onClick: PropTypes.func
  };

  return WrappedComponent;
};

export default withTracking;
