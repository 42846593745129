// @flow
import {baseQuestionExtensions} from './questionHelpers/baseQuestion.extensions';

export const authoringQuestionExtensions = {
  ...baseQuestionExtensions,
  containsLatex(): boolean {
    const func = () => {
      const regex = /\$.+\$/;
      if (regex.test(this.getLeftHeader()) || regex.test(this.getRightHeader())) {
        return true;
      }
      return this.getRows().some(
        (row) =>
          regex.test(row.getLeftColumn().getText()) ||
          regex.test(row.getRightColumn().getText()) ||
          regex.test(row.getStatement())
      );
    };
    return this.cache('containsLatex', func);
  }
};
