/**
 * # Anchor
 *
 * React component that renders an anchor element with optional label, children, and icon.
 *
 * ## Props
 *
 * - `as` (optional): The wrapper element to use for the anchor. Defaults to `Link` from `react-router`.
 * - `className` (optional): Additional CSS class name for the anchor.
 * - `disabled` (optional): Specifies if the anchor is disabled.
 * - `icon` (optional): The icon to display alongside the anchor.
 * - `iconPosition` (optional): The position of the icon relative to the anchor. Can be `'left'` or `'right'`. Defaults to `'left'`.
 * - `inheritColor` (optional): Specifies if the anchor should inherit its color from its parent.
 * - `to` (optional): The URL to navigate to when the anchor is clicked.
 * - `underlined` (optional): Specifies if the anchor should be underlined.
 * - `noUnderline` (optional): Specifies if the anchor should have no underline.
 * - `children` (optional): The content to render inside the anchor.
 * - `label` (optional): The label to display alongside the anchor.
 *
 * ## Usage
 *
 * ```tsx
 * import Anchor from './Anchor';
 *
 * const MyComponent = () => {
 *   return (
 *     <Anchor to="/home" label="Home" icon="home" iconPosition="left" inheritColor>
 *       Go to Home
 *     </Anchor>
 *   );
 * };
 * ```
 */

import React, {AnchorHTMLAttributes, forwardRef} from 'react';
import classnames from 'classnames';
import {Link} from 'react-router';

import Icon, {IconProp} from '../Icon/Icon.react';

import './anchor.scss';

interface BaseProps
  extends PropsWithClassNameOptional,
    OmitChildren<AnchorHTMLAttributes<HTMLAnchorElement>> {
  as?: React.ElementType;
  disabled?: boolean;
  icon?: IconProp;
  iconPosition?: 'left' | 'right';
  inheritColor?: boolean;
  to?: string;
  underlined?: boolean;
  noUnderline?: boolean;
}

interface PropsWithLabel extends BaseProps, PropsWithChildrenOptional {
  label: string;
}

interface PropsWithChildren extends BaseProps, PropsWithChildrenRequired {
  label?: string;
}

export const Anchor = forwardRef(
  (
    {
      as: WrapperElement = Link,
      children,
      className,
      disabled,
      icon,
      iconPosition = 'left',
      inheritColor,
      underlined,
      label,
      to,
      noUnderline,
      ...rest
    }: PropsWithLabel | PropsWithChildren, // Anchor needs either a label or children
    ref
  ) => {
    const classNames = classnames('a-anchor', className, {
      'a-anchor--disabled': disabled,
      'a-anchor--underlined': underlined,
      'a-anchor--no-underlined': noUnderline,
      'a-anchor--with-icon': icon,
      'a-anchor--with-inherited-color': inheritColor
    });

    return (
      <WrapperElement
        aria-label={label}
        className={classNames}
        disabled={disabled}
        ref={ref}
        to={to}
        {...rest}
      >
        {icon && iconPosition === 'left' && <Icon icon={icon} className='a-anchor__icon--left' />}
        {children}
        {icon && iconPosition === 'right' && <Icon icon={icon} className='a-anchor__icon--right' />}
      </WrapperElement>
    );
  }
);

export default Anchor;
