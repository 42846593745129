import React from 'react';
import {QuestionModelV3} from 'resources/augmented/Question/QuestionModel.v3';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {Card, Icon, Text, Button, WithToggle, Popover, IconButton} from '@albert-io/atomic';

import './how-others-answered.scss';

/**
 * This file has four components:
 *   HowOthersAnswered - the exported component - handles logic for questions without a breakdown
 *   HowOthersAnsweredText - shows icon, text, and percentage
 *   BreakdownList - handles logic if there are multiple input breakdowns to show
 *   IndividualBreakdown - takes an individual input's breakdown and creates its list along with a label if needed
 */
export default class HowOthersAnswered extends React.Component {
  // for Popover
  targetRef = React.createRef();

  static propTypes = {
    question: PropTypes.instanceOf(QuestionModelV3),
    expandable: PropTypes.bool,
    percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    breakdown: PropTypes.array,
    shouldRound: PropTypes.bool
  };

  static defaultProps = {
    shouldRound: true
  };

  render() {
    const {expandable, question} = this.props;

    const countOfCorrectGuesses = question.getMeta().getCountOfCorrectGuesses();
    const countOfIncorrectGuesses = question.getMeta().getCountOfIncorrectGuesses();
    const totalGuesses = countOfIncorrectGuesses + countOfCorrectGuesses;
    const percentage = this.props.percentage
      ? this.props.percentage
      : ((countOfCorrectGuesses / totalGuesses) * 100).toFixed(0);

    const buttonText = this.props.shouldRound ? 'top answers' : 'breakdown';

    if (totalGuesses < 100) {
      return (
        <WithToggle>
          {({onClick, on}) => {
            return (
              <Card className='u-pad_2 u-mar-b_4 how-others-answered__header-not-enough-answers'>
                <Text>We do not have enough data to show comparative scores across Albert</Text>
                <span ref={this.targetRef} style={{display: 'inline-block'}}>
                  <IconButton onClick={onClick} icon='question' label='question' size='l' />
                  {on && (
                    <Popover
                      expanded={on}
                      targetRef={this.targetRef}
                      className='how-others-answered__popover'
                    >
                      <Text>
                        We wait until a significant number of users have answered the question
                        before publishing answer breakdowns. Check back at a later point to see this
                        data.
                      </Text>
                    </Popover>
                  )}
                </span>
              </Card>
            );
          }}
        </WithToggle>
      );
    }

    return (
      <WithToggle dismissOnBlur={false}>
        {({onClick, on}) => {
          return (
            <Card className='u-pad_2 u-mar-b_4'>
              <div className={classnames('how-others-answered__header', {'u-pad-b_3': on})}>
                <HowOthersAnsweredText
                  isCorrect
                  className='how-others-answered__text'
                  percentage={percentage}
                  text='answered this question'
                  boldedText='correctly'
                />
                {expandable ? (
                  <Button
                    variant='text'
                    className='u-pad-1 u-mar-t_1 u-mar-l_4'
                    onClick={onClick}
                    size='s'
                  >
                    {on ? 'Hide' : 'Show'} {buttonText}
                  </Button>
                ) : null}
              </div>
              {expandable ? (
                <BreakdownList
                  shouldRound={this.props.shouldRound}
                  showBreakdown={on}
                  breakdown={this.props.breakdown}
                />
              ) : null}
            </Card>
          );
        }}
      </WithToggle>
    );
  }
}

class HowOthersAnsweredText extends React.Component {
  static propTypes = {
    percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isCorrect: PropTypes.bool,
    text: PropTypes.string,
    boldedText: PropTypes.string,
    className: PropTypes.string
  };

  render() {
    return (
      <div className={this.props.className}>
        <Icon
          className='how-others-answered__header-icon'
          color={this.props.isCorrect ? 'positive' : 'tertiary'}
          icon={this.props.isCorrect ? 'check' : 'times'}
          size='standalone'
        />
        <div>
          <Text bold>{this.props.percentage}% </Text>
          <Text>{this.props.text}</Text>
          <Text bold> {this.props.boldedText}</Text>
        </div>
      </div>
    );
  }
}

class BreakdownList extends React.Component {
  static propTypes = {
    breakdown: PropTypes.array,
    shouldRound: PropTypes.bool,
    showBreakdown: PropTypes.bool
  };

  render() {
    const showLabel = this.props.breakdown.length > 1;
    const breakdowns = this.props.breakdown.map((obj, index) => (
      <IndividualBreakdown
        key={index}
        shouldRound={this.props.shouldRound}
        showLabel={showLabel}
        label={obj.label}
        individualBreakdown={obj.breakdown}
      />
    ));

    return (
      <div
        className={classnames('how-others-answered__breakdown', {
          'how-others-answered__breakdown--hide': !this.props.showBreakdown
        })}
      >
        {breakdowns}
      </div>
    );
  }
}

class IndividualBreakdown extends React.Component {
  static propTypes = {
    individualBreakdown: PropTypes.array,
    label: PropTypes.string,
    shouldRound: PropTypes.bool,
    showLabel: PropTypes.bool
  };

  render() {
    const totalPercentage = this.props.individualBreakdown.reduce((result, val) => {
      return result + parseInt(val.percentage, 10);
    }, 0);

    let shouldRound = false;
    let roundAmount = 0;
    if (totalPercentage !== 100 && this.props.shouldRound) {
      shouldRound = true;
      roundAmount = 100 - totalPercentage;
    }
    const breakdown = this.props.individualBreakdown.map((option, index) => {
      let optionPercentage = option.percentage;
      if (!option.isCorrect && shouldRound) {
        optionPercentage = parseInt(option.percentage, 10) + roundAmount;
        shouldRound = false;
      }
      return (
        <HowOthersAnsweredText
          key={index}
          boldedText={option.boldedText}
          className='how-others-answered__list-item how-others-answered__text'
          isCorrect={option.isCorrect}
          percentage={optionPercentage}
          text='answered'
        />
      );
    });

    const label = this.props.showLabel ? <p className='u-mar-b_1'>{this.props.label}</p> : null;

    return (
      <div className='u-pad-b_3'>
        {label}
        {breakdown}
      </div>
    );
  }
}
