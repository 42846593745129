// @flow
import {fromJS, Set} from 'immutable';
import {Store} from 'client/framework';
import checkboxGroupActions from './CheckboxGroup.actions';

export default class CheckboxGroupStore extends Store {
  constructor(name: string) {
    super(name);
    this.initialData = fromJS({
      selectedOptions: [] // cast to Set
    });
    this.setInitialData(this.initialData);
    this.writeData('selectedOptions', new Set());
    this.handleTargeted(checkboxGroupActions.RESET_STORE, this._resetStore);
    this.handleTargeted(checkboxGroupActions.CHECKBOX_GROUP_SELECTION, this._setSelectedItems);
    this.handleTargeted(checkboxGroupActions.CHECKBOX_GROUP_DESELECTION, this._removeSelectedItem);
  }

  _resetStore() {
    this.writeData(this.initialData);
    this.writeData('selectedOptions', new Set());
  }

  _setSelectedItems(items: Set<string>) {
    const selectedOptions = this.getSelectedOptions();
    this.writeData('selectedOptions', selectedOptions.union(items));
  }

  _removeSelectedItem(item: string) {
    const selectedOptions = this.getSelectedOptions();
    this.writeData('selectedOptions', selectedOptions.subtract(new Set([item])));
  }

  getSelectedOptions(): Set<string> {
    return this.readData('selectedOptions');
  }
}
