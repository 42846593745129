import classNames from 'classnames';
import React from 'react';

import {QuestionModelV1} from '@albert-io/models';

import {Icon, Text} from '@albert-io/atomic';

import {SaveStatus} from 'client/components/QuestionTypes/QuestionType.types';

import {isGuessOrDraftGuessEmpty} from 'client/AssignmentPracticeView/assignmentPracticeView.utils';

import QuestionTypeStore from 'client/components/QuestionTypes/QuestionType.store';

import './question-actions-wrapper.scss';

interface Props extends PropsWithClassNameOptional, PropsWithChildrenRequired {
  activeQuestion?: QuestionModelV1;
}

export default function QuestionActionsWrapper({activeQuestion, children, className}: Props) {
  const activeQuestionStore = activeQuestion?.getStore();

  const hasError =
    activeQuestionStore !== undefined &&
    activeQuestionStore.getSaveStatus() === SaveStatus.SAVE_ERROR;

  const hasDraftGuess =
    activeQuestionStore !== undefined &&
    activeQuestionStore.getHasDraftGuess() &&
    !isGuessOrDraftGuessEmpty(activeQuestionStore.getDraftGuess());

  let bannerIcon = <Icon icon={['fal', 'info-circle']} />;
  let bannerText = <Text color='secondary'>No answer saved.</Text>;

  if (hasError) {
    bannerIcon = <Icon color='negative' icon={['fal', 'cloud-slash']} />;
    bannerText = (
      <Text color='negative'>Your answer was not saved. Please refresh your browser.</Text>
    );
  } else if (hasDraftGuess) {
    bannerIcon = <Icon icon={['fal', 'circle-check']} />;
    bannerText = (
      <Text color='tertiary'>
        <Text color='secondary'>Answer saved</Text> and will be submitted with your assignment.
      </Text>
    );
  }

  return (
    <div className={classNames(className, 'question-actions-wrapper')}>
      {!QuestionTypeStore.shouldNotSaveDraft() && (
        <div
          className={classNames('question-actions-wrapper__draft-guess-status', {
            'question-actions-wrapper__draft-guess-status--error': hasError,
            'question-actions-wrapper__draft-guess-status--has-draft-guess':
              !hasError && hasDraftGuess
          })}
        >
          <div className='question-actions-wrapper__draft-guess-status-icon-wrapper'>
            {bannerIcon}
          </div>
          {bannerText}
        </div>
      )}
      <div className='u-display_flex u-gap_space-x1 u-justify-content_flex-end u-pad-lr_3 u-pad-tb_2'>
        {children}
      </div>
    </div>
  );
}
