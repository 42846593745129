import React from 'react';

import {Card, Text} from '@albert-io/atomic';

import FeatureFlag from 'client/components/FeatureFlag/FeatureFlag.react';

import classNames from 'classnames';

import {useShallow} from 'zustand/react/shallow';

import {useQuestionEditorV2Store} from './QuestionEditorV2Store';
import {PRIMARY_CONTENT_LANGUAGE} from './QuestionEditorV2Store.types';

import {TranslationStatusManagerSection} from './Translations/TranslationStatusManagerSection';

import questionEditorStore from './QuestionEditor/QuestionEditor.store';

interface SectionCardProps {
  title?: string;
  // a function that determines whether the field should be displayed in this section
  fieldPredicate?: (field: string) => boolean;
  children: React.ReactNode;
  padBody?: boolean;
  className?: string;
}

export const SectionCard = ({
  title,
  children,
  padBody = true,
  className,
  fieldPredicate
}: SectionCardProps) => {
  const currentLanguage = useQuestionEditorV2Store((state) => state.currentLanguage);

  return (
    <Card className='u-width_100pc elevation-shadow-l1'>
      {title && (
        <div className='edit-page__section-card'>
          <Text bold size='l'>
            {title}
          </Text>

          <SectionDirtyIndicator fieldPredicate={fieldPredicate || (() => false)} />
        </div>
      )}
      {currentLanguage !== PRIMARY_CONTENT_LANGUAGE && fieldPredicate && (
        <FeatureFlag name='dennis_translation_authoring'>
          <TranslationStatusManagerSection fieldPredicate={fieldPredicate} />
        </FeatureFlag>
      )}

      <div
        className={classNames(className, {
          'u-pad_4': padBody
        })}
      >
        {children}
      </div>
    </Card>
  );
};

interface SectionDirtyIndicatorProps {
  fieldPredicate: (field: string) => boolean;
}

const SectionDirtyIndicator = ({fieldPredicate}: SectionDirtyIndicatorProps) => {
  const {currentLanguage, dirtyTranslatedFields} = useQuestionEditorV2Store(
    useShallow((state) => ({
      currentLanguage: state.currentLanguage,
      dirtyTranslatedFields: state.dirtyTranslatedFields.filter(
        (f) => f.language === state.currentLanguage
      )
    }))
  );

  const dirtyIndicator = (
    <svg xmlns='http://www.w3.org/2000/svg' width='11' height='11' viewBox='0 0 11 11' fill='none'>
      <circle cx='5.59766' cy='5.33105' r='5' fill='#1E7CB8' />
    </svg>
  );

  if (currentLanguage !== PRIMARY_CONTENT_LANGUAGE) {
    const dirtyFields = dirtyTranslatedFields.filter((field) => fieldPredicate(field.field));
    if (dirtyFields.length > 0) {
      return dirtyIndicator;
    }
    return null;
  }

  const question = questionEditorStore.getQuestion();
  const dirtyFields = question.getChangeMap().filter((_value, key) => {
    return fieldPredicate(key);
  });
  if (dirtyFields.size > 0) {
    return dirtyIndicator;
  }
  return null;
};
