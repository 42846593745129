import {Dispatch, createContext} from 'react';
import {PublicQuestionModelV1} from 'src/resources/augmented/PublicQuestion/PublicQuestionModel.v1';
import {
  previousQuestionDisabled,
  nextQuestionDisabled,
  currentQuestionIndex,
  QueryType,
  getQuestionPath
} from './util';

export interface PublicQuestionsState {
  title: string;
  subjectColor: string;
  subjectSlug: string;
  guideLevelSlug?: string;
  backLocation: string;
  iconUrl: string;
  questions: PublicQuestionModelV1[];
  questionCount: number;
  queryType: QueryType;
  currentQuestion?: PublicQuestionModelV1;
  initialQuestionLoaded: boolean;
  showQuestionList?: boolean;
  router?: any;
}

export type PublicQuestionsAction =
  | {
      type: 'setQuestions';
      questions: PublicQuestionModelV1[];
      currentQuestion?: PublicQuestionModelV1;
    }
  | {type: 'setCurrentQuestion'; question: PublicQuestionModelV1}
  | {type: 'setTitle'; title: string}
  | {type: 'setQuestionCount'; count: number}
  | {type: 'showQuestionList'}
  | {type: 'hideQuestionList'}
  | {type: 'previousQuestion'}
  | {type: 'nextQuestion'};

// update location on question change
// only when in guide level view
const shouldUpdateRouter = (state: PublicQuestionsState) =>
  state.router && state.queryType === 'guideLevel';

const updateRouterLocation = (state: PublicQuestionsState, question: PublicQuestionModelV1) => {
  const questionPath = getQuestionPath(
    state.subjectSlug,
    state.guideLevelSlug,
    question.getQuestionSet().getURLSlugPart()
  );
  state.router.push(questionPath || state.router.location);
};

const setCurrentQuestion = (
  state: PublicQuestionsState,
  question: PublicQuestionModelV1
): PublicQuestionsState => {
  if (shouldUpdateRouter(state)) {
    updateRouterLocation(state, question);
  }
  return {
    ...state,
    currentQuestion: question
  };
};

const setNextQuestion = (state: PublicQuestionsState): PublicQuestionsState => {
  const currentIndex = currentQuestionIndex(state.questions, state.currentQuestion!);
  if (!nextQuestionDisabled(state.questions, state.currentQuestion)) {
    const nextQuestion = state.questions[currentIndex + 1];
    return setCurrentQuestion(state, nextQuestion);
  }
  return state;
};

const setPrevQuestion = (state: PublicQuestionsState): PublicQuestionsState => {
  const currentIndex = currentQuestionIndex(state.questions, state.currentQuestion!);
  if (!previousQuestionDisabled(state.questions, state.currentQuestion)) {
    const prevQuestion = state.questions[currentIndex - 1];
    return setCurrentQuestion(state, prevQuestion);
  }
  return state;
};

const setQuestions = (
  state: PublicQuestionsState,
  questions: PublicQuestionModelV1[],
  currentQuestion?: PublicQuestionModelV1
): PublicQuestionsState => {
  const stateCurrentQuestion = currentQuestion || state.currentQuestion;
  if (stateCurrentQuestion && shouldUpdateRouter(state)) {
    updateRouterLocation(state, stateCurrentQuestion);
  }
  const questionInList =
    questions.length > 0 &&
    stateCurrentQuestion &&
    questions.find((q) => q.getId() === stateCurrentQuestion.getId());
  return {
    ...state,
    questions,
    currentQuestion: stateCurrentQuestion,
    initialQuestionLoaded: !!questionInList
  };
};

export const PublicQuestionsReducer = (
  state: PublicQuestionsState,
  action: PublicQuestionsAction
): PublicQuestionsState => {
  switch (action.type) {
    case 'setQuestions':
      return setQuestions(state, action.questions, action.currentQuestion);
    case 'setCurrentQuestion':
      return setCurrentQuestion(state, action.question);
    case 'setTitle':
      return {...state, title: action.title};
    case 'setQuestionCount':
      return {...state, questionCount: action.count};
    case 'showQuestionList':
      return {...state, showQuestionList: true};
    case 'hideQuestionList':
      return {...state, showQuestionList: false};
    case 'previousQuestion':
      return setPrevQuestion(state);
    case 'nextQuestion':
      return setNextQuestion(state);
    default:
      return state;
  }
};

export const PublicQuestionsContext = createContext({
  title: '',
  subjectColor: '',
  subjectSlug: '',
  backLocation: '',
  iconUrl: '',
  questions: [],
  questionCount: 0,
  queryType: 'guideLevel',
  initialQuestionLoaded: false
} as PublicQuestionsState);
export const PublicQuestionsDispatch = createContext({} as Dispatch<PublicQuestionsAction>);
