import React, {createContext, useState, useEffect} from 'react';
import {List, Map, Set} from 'immutable';
import {useShallow} from 'zustand/react/shallow';

import {SubjectModelV2, GuideLevelModelV2} from '@albert-io/models';

import {GuideLevelMapType} from 'client/components/ContentDiscovery/SubjectAndGuideSelector/TopicsModal/GuideLevel.helpers';
import {getSearchQuestionsQuery} from 'client/components/ContentDiscovery/ContentDiscovery.queries';
import {difficultyToNumMap} from 'client/components/PracticeView/PracticeViewToolbar/Filters/filters.utils';
import {
  Difficulties,
  QuestionTypes,
  constructAllOfFilter,
  constructQuestionTypesFilter,
  constructStandardsStandardSetsFilter
} from 'client/components/ContentDiscovery/ContentDiscovery.utils';
import {useContentDiscoveryStore} from './store';

type Subject = SubjectModelV2;

// todo - would it make more sense to split this into two providers? One for draft states and one for content discovery query actions?

export interface ContextProps {
  questionsQuery: any; // Todo: Need to add a type for queries
  setQuestionsQuery: (any) => void; // todo:  Need to add a type for queries
  questionDifficulties: List<Difficulties>;
  setQuestionDifficulties: (questionDifficulties: List<Difficulties>) => void;
  questionTypes: List<QuestionTypes>;
  setQuestionTypes: (questionTypes: List<QuestionTypes>) => void;
  hidePreviouslyAssigned: boolean;
  setHidePreviouslyAssigned: (boolean) => void;
  hideAssessmentQuestions: boolean;
  setHideAssessmentQuestions: (boolean) => void;
  searchSort: string | null;
  setSearchSort: (string) => void;
  // filters info
  hasFilters: boolean;
  clearFilters: () => void;
  clearTopicsModal: () => void;
  // seperating standards info

  // pick for me/add x number of questions
  addXQuestionsVal: number;
  setAddXQuestionsVal: (val: number) => void;

  // ---- Separating topics info ----
  //
  searchString: string;
  setSearchString: (string) => void;
  selectedTopicsModalSubjects: Set<Subject>; // Set of selected subjects in the topics modal subject dropdown
  setSelectedTopicsModalSubjects: (selectedTopicsModalSubjects) => void;
  // this is a map of guide -> guide levels
  // used for keeping track of all selection and click states in topics modal
  guideCollection: Map<string, GuideLevelMapType>;
  setGuideCollection: (guideCollection) => void;

  selectedGuideLevels: List<GuideLevelModelV2>;
  setSelectedGuideLevels: (selectedGuideLevels) => void;
  selectedSubjects: List<SubjectModelV2>; // List of selected subjects in guideCollection
  setSelectedSubjects: (selectedSubjects) => void;

  // summary information tab
  showSummaryInformation: boolean;
  setShowSummaryInformation: React.Dispatch<React.SetStateAction<boolean>>;
  // filter sidebar info
  sidebarCollapsed: boolean;
  setSidebarCollapsed: React.Dispatch<React.SetStateAction<boolean>>;
  // reset Assignment builder
  resetAssignmentBuilder: () => void;
}

const ContentDiscoveryContext = createContext({} as ContextProps);

const ContentDiscoveryProvider = ({children}: PropsWithChildrenRequired) => {
  // these are from zustand store while migration is still in progress
  // to correctly sync filter states with items that are now living in zustand
  const {
    standardCollection,
    getSelectedStandardsAndSets,
    hasSelectedStandard,
    clearFilters: clearZustandFilters
  } = useContentDiscoveryStore(
    useShallow((state) => ({
      standardCollection: state.standards.standardCollection,
      getSelectedStandardsAndSets: state.standards.getSelectedStandardsAndSets,
      hasSelectedStandard: state.standards.hasSelectedStandard,
      clearFilters: state.filter.clearFilters
    }))
  );

  // pick for me/add x number of questions
  const [addXQuestionsVal, setAddXQuestionsVal] = useState(10);
  // filter values
  const [selectedGuideLevels, setSelectedGuideLevels] = useState<List<any>>(List());
  const [selectedSubjects, setSelectedSubjects] = useState<List<string>>(List());
  const [questionDifficulties, setQuestionDifficulties] = useState<List<Difficulties>>(List());
  const [questionTypes, setQuestionTypes] = useState<List<QuestionTypes>>(List());
  const [hidePreviouslyAssigned, setHidePreviouslyAssigned] = useState(false);
  const [hideAssessmentQuestions, setHideAssessmentQuestions] = useState(false);
  const [hasFilters, setHasFilters] = useState(false);
  // sort values
  const [searchSort, setSearchSort] = useState<string | null>(null);

  // Add questions search query
  const [questionsQuery, setQuestionsQuery] = useState<any>(null);

  // topics
  const [searchString, setSearchString] = useState('');
  const [selectedTopicsModalSubjects, setSelectedTopicsModalSubjects] = useState<Set<Subject>>(
    Set()
  );
  const [guideCollection, setGuideCollection] = useState<Map<string, GuideLevelMapType>>(Map());

  // sidebar collapse info
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  // summary information tab
  const [showSummaryInformation, setShowSummaryInformation] = useState(false);

  /*
  #######################
  Start of add questions query useEffects
  #######################
  */

  // updates guideLevels filter
  // Hide assessment questions
  // !todo move into zustand filter subscription
  useEffect(() => {
    // @ts-ignore - needs typing
    const guideLevelIds = selectedGuideLevels.reduce((acc, value) => {
      // @ts-ignore - needs typing
      const newList = acc?.push(value.getId());
      return newList;
    }, List());

    const baseQuery = questionsQuery
      ? questionsQuery.unset('filter.guide_levels_v2')
      : getSearchQuestionsQuery();
    const newQuery = baseQuery
      .include('guide_levels_v2.guide_v1')
      .fields({
        guide_v1: 'guide_type'
      })
      .filter(
        {
          guide_levels_v2: {
            guide_v1: {
              guide_type: {
                not: 'assessment'
              }
            }
          }
        },
        hideAssessmentQuestions
      )
      .filter(
        {
          guide_levels_v2: {id: guideLevelIds.join(',')}
        },
        guideLevelIds.size > 0
      );

    setQuestionsQuery(newQuery);
    updateHasFilters();
  }, [selectedGuideLevels, hideAssessmentQuestions]);

  // Handles standards, standard sets, and question types.
  // This is because both of those filters are any_of filters and should be grouped together under an all_of
  // The logic for setting and updating individually was messy
  // for now the cleanest way to do this is remake the entire filter for both at once

  // !todo move into zustand filter subscription
  useEffect(() => {
    const baseQuery = questionsQuery
      ? questionsQuery.unset(`filter.all_of`)
      : getSearchQuestionsQuery();

    const {selectedStandardIds, selectedStandardSetIds} =
      getSelectedStandardsAndSets(standardCollection);

    const [standardSetsFilter, standardsFilter] = constructStandardsStandardSetsFilter(
      selectedStandardIds,
      selectedStandardSetIds
    );

    const questionTypesFilter = constructQuestionTypesFilter(questionTypes);

    const allOfFilter = constructAllOfFilter(
      questionTypesFilter,
      standardsFilter,
      standardSetsFilter
    );

    const newQuery = baseQuery.filter(allOfFilter);
    setQuestionsQuery(newQuery);
    updateHasFilters();
  }, [standardCollection, questionTypes]);

  // Updates question difficulty filter

  // !todo move into zustand filter subscription
  useEffect(() => {
    const baseQuery = questionsQuery
      ? questionsQuery.unset('filter.questions_v3.difficulty')
      : getSearchQuestionsQuery();
    let newQuery = baseQuery;
    if (!questionDifficulties.isEmpty()) {
      const difficultyValues = questionDifficulties
        .map((val) => {
          if (val) return difficultyToNumMap[val];
          return null;
        })
        .join(',');
      newQuery = baseQuery.filter({
        questions_v3: {
          difficulty: difficultyValues
        }
      });
    }
    setQuestionsQuery(newQuery);
    updateHasFilters();
  }, [questionDifficulties]);

  // Updates previously assigned

  // !todo move into zustand filter subscription
  useEffect(() => {
    const baseQuery = questionsQuery
      ? questionsQuery
          .unset(`filter['meta.is_used_in_assignment']`)
          .unset('customQuery.meta.context.assignment_usage')
      : getSearchQuestionsQuery();

    let newQuery = baseQuery;
    if (hidePreviouslyAssigned) {
      newQuery = baseQuery
        .filter({
          'meta.is_used_in_assignment': false
        })
        .meta({
          context: {
            assignment_usage: true
          }
        });
    }
    setQuestionsQuery(newQuery);
    updateHasFilters();
  }, [hidePreviouslyAssigned]);

  /*
  #######################
  End of add questions query useEffects
  #######################
  */

  // !todo move into zustand filter subscription
  const updateHasFilters = () => {
    if (
      selectedSubjects.isEmpty() &&
      selectedGuideLevels.isEmpty() &&
      questionDifficulties.isEmpty() &&
      questionTypes.isEmpty() &&
      !hasSelectedStandard() &&
      !hidePreviouslyAssigned &&
      !hideAssessmentQuestions
    ) {
      setHasFilters(false);
      setQuestionsQuery(null);
    } else {
      setHasFilters(true);
    }
  };

  const clearTopicsModal = () => {
    setSelectedGuideLevels(List());
    setSearchString('');
    setSelectedTopicsModalSubjects(Set());
    setSelectedSubjects(List());
    setGuideCollection(Map());
  };

  const clearFilters = () => {
    setQuestionDifficulties(List());
    setQuestionTypes(List());
    setHidePreviouslyAssigned(false);
    setHideAssessmentQuestions(false);
    setQuestionsQuery(null);
    clearTopicsModal();
    clearZustandFilters();
  };

  // -------------------------
  const resetAssignmentBuilder = () => {
    // filter values
    setSelectedGuideLevels(List());
    setSelectedSubjects(List());
    setQuestionDifficulties(List());
    setQuestionTypes(List());
    setHidePreviouslyAssigned(false);
    setHideAssessmentQuestions(false);
    setHasFilters(false);
    clearZustandFilters();
    // sort values
    setSearchSort(null);
    // Add questions search query
    setQuestionsQuery(null);
    // topics
    setSearchString('');
    setSelectedTopicsModalSubjects(Set());
    setGuideCollection(Map());
    // sidebar collapse info
    setSidebarCollapsed(false);
    // summary information tab
    setShowSummaryInformation(false);
  };
  // ------------------------------

  return (
    <ContentDiscoveryContext.Provider
      value={{
        questionsQuery,
        setQuestionsQuery,
        selectedGuideLevels,
        setSelectedGuideLevels,
        selectedSubjects,
        setSelectedSubjects,
        searchSort,
        setSearchSort,
        hasFilters,
        clearFilters,
        clearTopicsModal,
        questionDifficulties,
        setQuestionDifficulties,
        questionTypes,
        setQuestionTypes,
        hidePreviouslyAssigned,
        setHidePreviouslyAssigned,
        hideAssessmentQuestions,
        setHideAssessmentQuestions,
        // pick for me/add x number of questions
        addXQuestionsVal,
        setAddXQuestionsVal,
        // topics
        searchString,
        setSearchString,
        selectedTopicsModalSubjects,
        setSelectedTopicsModalSubjects,
        guideCollection,
        setGuideCollection,
        // summary information tab
        showSummaryInformation,
        setShowSummaryInformation,
        // sidebar collapsed info
        sidebarCollapsed,
        setSidebarCollapsed,
        // reset provider
        resetAssignmentBuilder
      }}
    >
      {children}
    </ContentDiscoveryContext.Provider>
  );
};

export {ContentDiscoveryContext, ContentDiscoveryProvider};
