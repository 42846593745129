import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {List} from 'immutable';
import {
  Dropdown,
  IconButton,
  Table,
  ListGroupItem,
  Text,
  StatusText,
  Toggle,
  Button,
  Icon,
  useTooltipWithLiveRegion
} from '@albert-io/atomic';
import {toCoordsList} from 'components/GraphingInput/GraphingInput.react';

import {upperFirst} from 'lib/stringUtils';
import ArrayToReadableFragment from 'lib/hocs/arrayToReadableFragment';

import './CorrectAnswerSettings.scss';

const options = {
  exact: {
    label: 'all points',
    key: 'exact'
  },
  slope: {
    label: 'slope',
    key: 'slope'
  },
  yIntercept: {
    label: 'y-intercept',
    key: 'yIntercept'
  },
  startingPoint: {
    label: 'starting point',
    key: 'startingPoint'
  },
  direction: {
    label: 'direction',
    key: 'direction'
  },
  length: {
    label: 'length',
    key: 'length'
  },
  centerPoint: {
    label: 'center point',
    key: 'centerPoint'
  },
  radiusLength: {
    label: 'radius length',
    key: 'radiusLength'
  },
  inclusive: {
    label: 'line appearance',
    key: 'inclusive'
  }
};

const entityToAnswerSettings = {
  point: [options.exact],
  line: [options.slope, options.yIntercept],
  segment: [options.exact, options.slope, options.length],
  ray: [options.startingPoint, options.direction],
  polygon: [options.exact],
  circle: [options.centerPoint, options.radiusLength],
  inequality: [options.slope, options.yIntercept, options.inclusive, options.direction],
  parabola: [options.exact]
};

const CorrectAnswerSettings = ({
  correctAnswerEntities,
  settings,
  handleToggleSetting,
  xInterval,
  yInterval
}) => {
  const data = List(correctAnswerEntities);
  return (
    <Table data={data}>
      <Table.Column heading='Elements' content={(entity) => upperFirst(entity.type)} />
      <Table.Column
        heading='Coordinates'
        content={(entity) =>
          toCoordsList(entity.elements)
            .map(([x, y]) => `(${x * xInterval}, ${y * yInterval})`)
            .join(' ')
        }
      />
      <Table.Column
        noClamp
        heading='Correct answer criteria'
        content={(entity) => {
          const index = data.indexOf(entity);
          const entitySettings = settings[index];
          const enabledOptions = entityToAnswerSettings[entity.type]
            .filter(({key}) => entitySettings[key])
            .map(({label}) => label);

          if (enabledOptions.length === 0) {
            return (
              <Text color='negative' italic>
                <Icon className='u-mar-r_1' icon={['far', 'exclamation-triangle']} />
                Select answer criteria
              </Text>
            );
          }

          return (
            <Text>
              Match <ArrayToReadableFragment arr={enabledOptions} as='strong' />
            </Text>
          );
        }}
      />
      <Table.Column
        align='right'
        className='graphing-question-correct-answer-settings__action-menu'
        content={(entity) => {
          const index = data.indexOf(entity);
          const availableSettings = entityToAnswerSettings[entity.type];
          if (availableSettings.length < 2) {
            return <NoAvailableOptions />;
          }

          const entitySettings = settings[index];

          return (
            <Dropdown positionFixed trigger={<DropdownTrigger />} position='bottom-end'>
              <p className='u-pad_2 u-mar_0'>
                <StatusText>Correct answer should match...</StatusText>
              </p>
              {availableSettings.map(({key, label}) => {
                return (
                  <SettingOption
                    checked={entitySettings[key]}
                    onChange={() => {
                      handleToggleSetting({entityIndex: index, setting: key});
                    }}
                    label={upperFirst(label)}
                    key={key}
                  />
                );
              })}
            </Dropdown>
          );
        }}
      />
    </Table>
  );
};

CorrectAnswerSettings.propTypes = {
  correctAnswerEntities: PropTypes.arrayOf(PropTypes.object).isRequired,
  settings: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleToggleSetting: PropTypes.func.isRequired,
  xInterval: PropTypes.number,
  yInterval: PropTypes.number
};

const SettingOption = ({label, checked, onChange}) => {
  return (
    <ListGroupItem as='li' onClick={(e) => e.stopPropagation()}>
      <div className='u-display_flex u-justify-content_space-between u-align-items_center'>
        <Text color='secondary'>{label}</Text>
        <Toggle className='u-mar-l_2' onChange={onChange} checked={checked} />
      </div>
    </ListGroupItem>
  );
};

SettingOption.propTypes = {
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
};

const NoAvailableOptions = () => {
  const ref = useRef(null);
  const {
    closeTooltip,
    handleEscape,
    openTooltip,
    tooltipContentRef,
    tooltipProps,
    TooltipWithLiveRegion
  } = useTooltipWithLiveRegion(ref);
  return (
    <>
      <IconButton
        icon={['far', 'question-circle']}
        label='More info'
        onFocus={openTooltip}
        onBlur={closeTooltip}
        onKeyDown={handleEscape}
        ref={ref}
      />
      <TooltipWithLiveRegion placement='right' {...tooltipProps}>
        <span ref={tooltipContentRef}>
          Correct answer cannot be modified for this element type.
        </span>
      </TooltipWithLiveRegion>
    </>
  );
};

/**
 * Tuple of [correctAnswerSettings, handleEntitiesChange, toggleSetting] where:
 * - correctAnswerSettings: Array<object> The settings for each matching index of
 *   `correctAnswer` entities
 * - handleEntitiesChange: Function Passed to the GraphingInput board to handle adding or
 *   deleting entities
 * - toggleSetting: Function Passed to <CorrectAnswerSettings /> so that settings can be
 *   toggled on/off
 *
 * @typedef {Array<*>} useCorrectAnswerSettingsStateReturn
 */

/**
 * Why is this so weird? Since the graphing input only provides an onChange
 * and doesn't actually tell us what changed, we have to figure that out ourselves.
 * To do this, we store the previous array of entities and we know that:
 *
 * - If the previous array is longer than the current array, something was deleted, and we can
 *   determine what that was by looking for the first index that doesn't match and remove that
 *   settings entry
 * - If the previous array is shorter than the current array, an item or multiple items were added,
 *   so we can create a default settings object for any items past that we don't have entries for
 *
 * @param {*} initialQuestion Question to use for initial correctAnswerSettings
 * @param {Array<object>} correctAnswerEntities `correctAnswer` entities
 * @returns {useCorrectAnswerSettingsStateReturn} See typedef
 */
export function useCorrectAnswerSettingsState(initialQuestion, correctAnswerEntities) {
  const cachedEntitiesRef = useRef();
  if (!cachedEntitiesRef.current) {
    cachedEntitiesRef.current = correctAnswerEntities;
  }

  const [correctAnswerSettings, setCorrectAnswerSettings] = useState(
    initialQuestion
      .getValidResponse()
      .toList()
      .toJS()
      .map((entity) => entity.correctAnswerSettings)
  );

  const handleEntitiesChange = (updatedEntities) => {
    if (cachedEntitiesRef.current.length > updatedEntities.length) {
      // Entity has been deleted so we remove it
      const deletedIndex = cachedEntitiesRef.current.findIndex((entity, i) => {
        return updatedEntities[i] !== entity;
      });

      setCorrectAnswerSettings((currentSettings) =>
        currentSettings.filter((_, i) => i !== deletedIndex)
      );
    } else if (cachedEntitiesRef.current.length < updatedEntities.length) {
      // One or more entities have been added, so we create defaults for them
      const newDefaults = updatedEntities
        .slice(cachedEntitiesRef.current.length)
        .map(makeDefaultSettingsForNewEntity);

      setCorrectAnswerSettings((currentSettings) => currentSettings.concat(newDefaults));
    }

    cachedEntitiesRef.current = updatedEntities;
  };

  const toggleSetting = ({entityIndex, setting}) => {
    setCorrectAnswerSettings((currentSettings) => {
      const entitySettings = currentSettings[entityIndex];
      const currentValue = entitySettings[setting];
      return updateArray(currentSettings, entityIndex, {
        ...entitySettings,
        [setting]: !currentValue
      });
    });
  };

  return [correctAnswerSettings, handleEntitiesChange, toggleSetting];
}

/**
 * When a new entity is added to the graph, we have to initialize it with all of its
 * `correctAnswerSettings` turned off, unless that entity type only has one
 * `correctAnswerSetting`—in which case we initialize it to true.
 *
 * E.g. if the author adds a new line, whose `correctAnswerSettings` can be `slope` or
 * `yIntercept` as defined in the `entityToAnswerSettings` object, we have to create a
 * settings object for this line with `slope` and `yIntercept` set to false.
 *
 * @param {object} entity Incoming new entity
 * @returns {object} Default settings for entity type
 */
function makeDefaultSettingsForNewEntity(entity) {
  return entityToAnswerSettings[entity.type].reduce((settings, {key}, i, arr) => {
    // eslint-disable-next-line no-param-reassign
    settings[key] = arr.length === 1;
    return settings;
  }, {});
}

function updateArray(arr, i, val) {
  return [...arr.slice(0, i), val, ...arr.slice(i + 1)];
}

function DropdownTrigger(props) {
  return (
    <Button size='s' {...props} aria-label='Toggle answer criteria options'>
      <Icon icon='pencil' className='u-mar-r_1' />
      <Icon icon={props['aria-expanded'] ? 'chevron-up' : 'chevron-down'} />
    </Button>
  );
}

DropdownTrigger.propTypes = {
  'aria-expanded': PropTypes.bool.isRequired
};

export default CorrectAnswerSettings;
