import React, {useState, useEffect} from 'react';
import {Set} from 'immutable';

interface SortableContainerProviderProps extends PropsWithChildrenRequired {
  onSortEnd: (
    orderedSortIndices: Set<number>,
    endIndex: number,
    updatedEndIndex: number,
    shouldCancel: boolean
  ) => void;
}

export type SortableContainerProviderExposedValues = {
  sortIndices: Set<number>;
  recentlyMovedIndices: number[] | null;
  isBulkMove: boolean;
  handleSingleSortStart: (e: React.MouseEvent<HTMLDivElement>, index: number) => void;
  handleMultiSortStart: (indexSet: Set<number>) => void;
  handleSortEnd: (
    e: React.MouseEvent<HTMLDivElement>,
    endIndex: number,
    shouldCancel?: boolean
  ) => void;
  hasSorted: boolean;
  setHasSorted: React.Dispatch<React.SetStateAction<boolean>>;
  cancelSortMode: () => void;
  handleMoveToTop: (e: React.MouseEvent<HTMLDivElement>, indexSet: Set<number>) => void;
};

export const SortableContainerContext = React.createContext<any>(null);

/*
  A good example of how this works with the `SortableElement` can be found in `SortableContainer.stories.js`
 */
export const SortableContainerProvider: React.FC<SortableContainerProviderProps> = ({
  children,
  onSortEnd,
  ...props
}) => {
  const [sortIndices, setSortIndices] = useState<Set<number>>(Set());
  const [recentlyMovedIndices, setRecentlyMovedIndices] = useState<number[] | null>(null);
  const [isBulkMove, setIsBulkMove] = useState(false);
  const [hasSorted, setHasSorted] = useState(false);

  let recentlyMovedIndexTimeout;
  let sortEndTimeout;

  useEffect(() => {
    return () => {
      clearTimeout(recentlyMovedIndexTimeout!);
      clearTimeout(sortEndTimeout!);
    };
  }, []);

  const cancelSortMode = () => {
    setSortIndices(Set());
  };

  const handleMultiSortStart = (indexSet: Set<number>) => {
    setSortIndices(indexSet);
  };

  const handleSingleSortStart = (e: React.MouseEvent<HTMLDivElement>, index: number) => {
    const newSortIndices = Set([index]);
    setSortIndices(newSortIndices);
    e.stopPropagation();
  };

  const handleMoveToTop = (e: React.MouseEvent<HTMLDivElement>, indexSet: Set<number>) => {
    setSortIndices(indexSet);
    setIsBulkMove(true);
    handleSortEnd(e, 0);
  };

  const setSortState = (initialEndIndex: number, updatedEndIndex: number) => {
    if (isBulkMove || !sortIndices?.has(initialEndIndex)) {
      const updatedRecentlyMovedIndices = Array.from(
        {length: sortIndices.count()},
        (_, i) => i + updatedEndIndex
      );
      setSortIndices(Set());
      setHasSorted(true);
      setIsBulkMove(false);
      setRecentlyMovedIndices(updatedRecentlyMovedIndices);
      recentlyMovedIndexTimeout = setTimeout(() => setRecentlyMovedIndices(null), 2000);
    } else {
      setSortIndices(Set());
    }
  };

  const handleSortEnd = (
    e: React.MouseEvent<HTMLDivElement>,
    endIndex: number,
    shouldCancel?: boolean
  ) => {
    const orderedSortIndices = sortIndices
      .sort((setA, setB) => {
        return setB - setA;
      })
      .toSet();
    let updatedEndIndex = endIndex;
    let hasElementPastEndIndex = false;
    orderedSortIndices.forEach((index) => {
      if (index && index <= endIndex) {
        updatedEndIndex -= 1;
      } else {
        hasElementPastEndIndex = true;
      }
    });
    if (!hasElementPastEndIndex) {
      updatedEndIndex += 1;
    }
    onSortEnd(orderedSortIndices, endIndex, updatedEndIndex, shouldCancel || false);
    sortEndTimeout = setTimeout(() => {
      setSortState(endIndex, updatedEndIndex);
    }, 0);
    e?.stopPropagation();
  };

  return (
    <SortableContainerContext.Provider
      value={{
        sortIndices,
        recentlyMovedIndices,
        isBulkMove,
        handleSingleSortStart,
        handleMultiSortStart,
        handleSortEnd,
        hasSorted,
        setHasSorted,
        cancelSortMode,
        handleMoveToTop,
        ...props
      }}
    >
      {children}
    </SortableContainerContext.Provider>
  );
};
