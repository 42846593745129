import React from 'react';

import Text from '@albert-io/atomic/atoms/Text/Text.react';
import Heading from '@albert-io/atomic/atoms/Heading/Heading.react';

import CoppaMessage from './CoppaMessage';

export default class CoppaError extends React.Component {
  render() {
    return (
      <CoppaMessage>
        <Heading as='div'>Error</Heading>
        <Text paragraph as='p'>
          We couldn't process your request. This may be because you or somebody else has already
          approved this student or because we've already deleted their account. Please contact
          hello@albert.io if you have questions or concerns.
        </Text>
      </CoppaMessage>
    );
  }
}
