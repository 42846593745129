import React from 'react';
import PropTypes from 'prop-types';
import Subheader from 'generic/Subheader/Subheader.react';
import IEMobileNav from 'client/InteractionEngineV2/shared/IEMobileNav/IEMobileNav.react';
import appStore from 'client/AppStore';
import AssignmentQuestionsListStore from '../AssignmentQuestionsList/AssignmentQuestionsList.store';
import {SUBHEADER_CLASS} from 'client/InteractionEngineV2/interactionEngineConstants';

export default class AssignmentNavigation extends React.Component {
  static propTypes = {
    assignmentStore: PropTypes.instanceOf(AssignmentQuestionsListStore).isRequired,
    assignmentActions: PropTypes.element,
    showMobileActions: PropTypes.bool
  };

  render() {
    const navigationTitle = this.props.assignmentStore.assignmentName;
    let navigation;
    if (appStore.isMobileView()) {
      navigation = (
        <IEMobileNav
          title={navigationTitle}
          assignmentActions={this.props.showMobileActions ? this.props.assignmentActions : null}
        />
      );
    } else {
      navigation = (
        <Subheader
          className={SUBHEADER_CLASS}
          title={navigationTitle}
          rightContent={this.props.assignmentActions}
        />
      );
    }

    return navigation;
  }
}
