import React, {useContext} from 'react';
import {Pagination, SelectField} from '@albert-io/atomic';
import {pushQueryParams} from 'client/history';
import './reports-pagination.scss';
import {ReportsContext} from 'client/Reports/Reports.context';

interface Props {
  limit: string;
  page: string;
  total?: number;
  rowSizeOptions: {value: number}[];
}

export const ReportsPagination = ({limit, page, total, rowSizeOptions}: Props) => {
  const {reportSelected} = useContext(ReportsContext);
  if (!total) {
    return null;
  }
  const totalPages = Math.ceil(total / Number(limit));

  return (
    <div className='u-display_flex u-align-items_center u-justify-content_flex-end u-mar-t_2'>
      <SelectField
        className='reports-table__show-rows'
        options={rowSizeOptions}
        defaultValue={`${limit}`}
        label='Show rows:'
        size='s'
        inline
        onChange={(e) => {
          const rowSize = parseInt(e.target.value, 10);
          pushQueryParams({limit: rowSize});
          localStorage.setItem(
            `${reportSelected === 'gradebook' ? 'gradebook:rowLimit' : 'report:rowLimit'}`,
            rowSize.toString()
          );
        }}
      />
      <Pagination
        className='u-mar-l_2'
        paginationDropdownClassName='reports-pagination__dropdown'
        totalPages={totalPages}
        defaultPage={Number(page)}
        variation={typeof total === 'undefined' ? 'micro' : 'condensed'}
        onChange={(newPage) => {
          pushQueryParams({page: newPage});
        }}
      />
    </div>
  );
};
