import React, {useEffect, useRef, useState} from 'react';
import classnames from 'classnames';
import moment from 'moment-timezone';
import {withRouter} from 'react-router';

import {Button, Dialogue, Icon, Modal, Text} from '@albert-io/atomic';

import {local} from 'lib/StorageUtil';
import systemTimeOffsetStore from 'client/generic/SystemTimeOffset/SystemTimeOffset.store';
import sessionStore from 'client/Session/SessionStore';

type DowntimeType = {
  time: string;
  duration: string;
};

interface MaintenanceModalProps {
  location: {
    pathname?: string;
    query?: {
      DEBUG?: 'true' | 'false';
    };
  };
  downtimes: DowntimeType[];
  daysToShowBefore: number;
}

const formatRules = 'MMMM D, YYYY [at] h:mma z';

const routeOfLastAppearance = 'ALBERT_MAINTENANCE_ROUTE_OF_LAST_APPEARANCE';
const timeOfLastAppearance = 'ALBERT_TIME_OF_LAST_APPEARANCE';

const modalStateKey = 'albert.maintenance-modal';

function MaintenanceModal({location = {}, downtimes, daysToShowBefore = 5}: MaintenanceModalProps) {
  const didMountRef = useRef(false);
  const [hasAcknowledged, setHasAcknowledged] = useState(false);

  const {pathname = '', query = {}} = location;

  useEffect(() => {
    if (didMountRef.current === false) {
      didMountRef.current = true;
    }
  }, []);

  // Handle acknowledgement
  useEffect(() => {
    if (didMountRef.current === true && hasAcknowledged) {
      const state = {
        [routeOfLastAppearance]: pathname,
        [timeOfLastAppearance]: systemTimeOffsetStore.getCurrentTime().valueOf()
      };
      local.set(modalStateKey, state);
      setHasAcknowledged(false);
    }
  }, [hasAcknowledged, pathname]);

  const sortedDowntimeObjects = downtimes.sort((a, b) => {
    if (a.time === b.time) {
      return 0;
    }
    return moment(a.time).isBefore(moment(b.time)) ? -1 : 1;
  });

  const nextMaintenancePeriodObj = sortedDowntimeObjects.find((downtimeObj) =>
    systemTimeOffsetStore.getCurrentTime().isBefore(downtimeObj.time)
  );

  if (!nextMaintenancePeriodObj) {
    return null;
  }

  const nextMaintenancePeriodTime = nextMaintenancePeriodObj.time;
  const nextMaintenancePeriodDuration = nextMaintenancePeriodObj.duration;

  const isNextPeriodWithinTimeToShow =
    moment(nextMaintenancePeriodTime).diff(systemTimeOffsetStore.getCurrentTime(), 'days') <
      daysToShowBefore || query.DEBUG === 'true';

  const modalState = local.get(modalStateKey, {});
  const lastAppearedAt = modalState[timeOfLastAppearance] || 0;
  const sixHoursHaveElapsed =
    query.DEBUG === 'true'
      ? moment(lastAppearedAt).add(30, 'seconds')
      : moment(lastAppearedAt).add(6, 'hours').isBefore(systemTimeOffsetStore.getCurrentTime());
  const routesMatch = modalState[routeOfLastAppearance] === pathname;

  const showModal =
    !hasAcknowledged && !routesMatch && sixHoursHaveElapsed && isNextPeriodWithinTimeToShow;

  const lastDowntime = sortedDowntimeObjects[sortedDowntimeObjects.length - 1].time;
  if (
    !sessionStore.hasValidSession() ||
    systemTimeOffsetStore.getCurrentTime().isAfter(moment(lastDowntime))
  ) {
    return null;
  }

  if (!showModal) {
    return null;
  }

  return (
    <Modal
      ariaLabel='Albert will be temporarily unavailable for upcoming maintenance'
      dismissable={false}
      handleClose={() => setHasAcknowledged(true)}
      role='alertdialog'
    >
      {({modalContentStyle}) => (
        <Dialogue
          className={classnames(modalContentStyle, 'u-text-align_center u-color_slate-600')}
          hideCloseBtn
          inModal
        >
          <Icon className='fa-3x' icon={['far', 'construction']} />
          <Dialogue.Title align='center' className='u-mar-tb_4'>
            Upcoming Maintenance
          </Dialogue.Title>
          <Dialogue.Body>
            <Text as='p' className='u-display_block u-mar-b_3' color='inherit'>
              Maintenance is scheduled for:
            </Text>
            <Text as='p' bold className='u-display_block u-mar-b_3' color='inherit'>
              {moment(nextMaintenancePeriodTime).tz(moment.tz.guess()).format(formatRules)}
            </Text>
            <Text as='p' color='inherit'>
              The entire site will undergo maintenance and be inaccessible for{' '}
              {nextMaintenancePeriodDuration}.
            </Text>
          </Dialogue.Body>
          <Dialogue.BtnGroup className='u-width_100pc'>
            <Button onClick={() => setHasAcknowledged(true)}>OK</Button>
          </Dialogue.BtnGroup>
        </Dialogue>
      )}
    </Modal>
  );
}

export default withRouter(MaintenanceModal);
