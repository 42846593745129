import React, {useCallback, useEffect, useRef, useState} from 'react';
import {WithRouterProps, withRouter} from 'react-router';
import classNames from 'classnames';
import {uniqBy} from 'lodash';
import {history} from 'client/history';
import {
  AnalyticsApiResult,
  Dimension,
  MetricColumn,
  QueryVariables,
  Sort,
  TopLevelFilter
} from 'client/Reports/reports.types';

import './gradebook-table.scss';
import {IconButton, LoadingSpinner, NoDataMsg, Text, WithTooltip} from '@albert-io/atomic';
import {useWindowSize} from 'lib/hooks/useWindowSize';
import {useFixedTableHeader} from 'client/Reports/useFixedTableHeader';

import {DIMENSION_COLUMNS, columnIsAvailable, getAssignment} from 'client/Reports/reports.utils';

import {ReportsPagination} from '../Pagination/ReportsPagination.react';
import {PerformanceHeaderCell} from '../Table/PerformanceHeaderCell';

import {GradebookSorts} from './GradebookSorts';
import {AnswerBreakdownHeaderCell} from '../Table/AnswerBreakdownHeaderCell';

interface Props extends WithRouterProps {
  data: AnalyticsApiResult[];
  metricColumns: MetricColumn[];
  dimensions: Dimension[];
  variables: QueryVariables;
  totals: number[];
  loading?: boolean;
  loadingMore?: boolean;
  loadMore: () => void;
  sorts: Sort[];
  limit: string;
  page: string;
  topLevelFilterType: TopLevelFilter;
}

const getDimensionValueFromResponse = (type: Dimension, item: AnalyticsApiResult) => {
  const dimensionKey = {
    students: 'student',
    assignments: 'secondary_assignment'
  }[type];
  return item[dimensionKey];
};

export const createStudentAssignmentMetricMatrixKey = (studentId: string, assignmentId: string) => {
  return `${studentId}-${assignmentId}`;
};

export const createStudentAssignmentMetricMatrix = (data: AnalyticsApiResult[]) => {
  const metricMap: {[key: string]: AnalyticsApiResult} = data.reduce((acc, item) => {
    const studentId = item.student.id;
    const assignmentId = getAssignment(item).id;

    if (studentId && assignmentId) {
      const key = createStudentAssignmentMetricMatrixKey(studentId, assignmentId);
      acc[key] = item;
    }
    return acc;
  }, {});

  return metricMap;
};

const LOAD_MORE_BUFFER = 30;

const rowSizeOptions = [{value: 10}, {value: 15}, {value: 20}, {value: 25}];

const usePreviousStudentsColumnWidth = (loading?: boolean) => {
  const ref = useRef<HTMLTableCellElement>(null);

  const [prevWidth, setPrevWidth] = useState(0);

  useEffect(() => {
    if (!loading && ref.current) {
      setPrevWidth(ref.current.offsetWidth);
    }
  }, [ref.current?.offsetWidth, loading]);

  return {prevWidth, ref};
};

// This hook is used to trigger a callback **on the next render cycle**
// (this allows refs to be updated before the callback is called)
const useTrigger = (callback: () => any) => {
  const [shouldTrigger, setShouldTrigger] = useState(false);

  const trigger = useCallback(() => {
    setShouldTrigger(true);
  }, []);

  useEffect(() => {
    if (shouldTrigger) {
      callback();
      setShouldTrigger(false);
    }
  }, [shouldTrigger, callback]);

  return trigger;
};

const getUniqueDimensions = (data: AnalyticsApiResult[], type: Dimension) => {
  const uniqueDimensions = uniqBy(
    data.map((item) => getDimensionValueFromResponse(type, item)).filter((item) => !!item),
    'id'
  );
  return uniqueDimensions;
};

/*
  The gradebook table is a 2-way table with students as the rows and assignments as the columns.
  Each assignment "column" has sub-columns that are the metrics for that student+assignment.
  The far left column is the student name, and it is fixed as the table is scrolled right.
*/
const GradebookTable = ({
  data,
  metricColumns,
  dimensions,
  variables,
  totals,
  loading,
  loadingMore,
  loadMore,
  sorts,
  limit,
  page,
  topLevelFilterType,
  location
}: Props) => {
  const {pathname, search} = location;
  const [prevDistFromStart, setPrevDistFromStart] = useState(0);
  const [hoveredAssignmentId, setHoveredAssignmentId] = useState<string | null>(null);

  const assignments = getUniqueDimensions(data, 'assignments');
  const students = getUniqueDimensions(data, 'students');

  const studentAssignmentMetricMatrix = createStudentAssignmentMetricMatrix(data);

  const [windowWidth, windowHeight] = useWindowSize();

  const container = useRef<HTMLDivElement>(null);
  const setTableHeaderRef = useFixedTableHeader();

  const {prevWidth, ref: studentsColumnRef} = usePreviousStudentsColumnWidth(loading);

  const onScroll = useCallback(() => {
    const target = container.current;
    if (!target) {
      return;
    }
    const scrollPosition = target.scrollLeft;
    const {scrollWidth, clientWidth} = target;

    const curDistFromStart = scrollPosition + clientWidth;

    const assignmentsTotal = totals[1];

    // if we are moving from outside the load more buffer to inside the load more buffer,
    // load more
    if (
      !loading &&
      !loadingMore &&
      assignments.length < assignmentsTotal &&
      (clientWidth === scrollWidth ||
        (curDistFromStart + LOAD_MORE_BUFFER >= scrollWidth &&
          prevDistFromStart + LOAD_MORE_BUFFER < scrollWidth))
    ) {
      loadMore();
    }
    setPrevDistFromStart(curDistFromStart);
  }, [loading, loadingMore, loadMore, prevDistFromStart, totals, assignments.length]);

  const triggerOnScroll = useTrigger(onScroll);

  /*
    if there are pages left to load and the container is too small to scroll, load more
    this prevents the user from getting into a state where there's no way to load more because
    the container is not scrollable and scrolling is the way we trigger a load. Should only
    come into play if a user's page is super wide and/or they are showing very few columns.
    We also watch for window resizing because that can change the scrollability of the container.
  */
  useEffect(() => {
    triggerOnScroll();
  }, [triggerOnScroll, data.length, windowHeight, windowWidth]);

  if (!loading && data.length === 0) {
    return (
      <div className='u-display_flex u-justify-content_center'>
        <NoDataMsg.Container centered>
          <NoDataMsg.Heading>No Data Available</NoDataMsg.Heading>
          <NoDataMsg.Body>There is no data available for the current filters.</NoDataMsg.Body>
        </NoDataMsg.Container>
      </div>
    );
  }

  const columns = [
    ...DIMENSION_COLUMNS.gradebook.filter((c) => !c.afterMetrics),
    ...metricColumns,
    ...DIMENSION_COLUMNS.gradebook.filter((c) => c.afterMetrics)
  ];

  const filteredColumns = columns.filter((col) =>
    columnIsAvailable(col, dimensions, variables, topLevelFilterType)
  );

  return (
    <div>
      <div
        className={classNames(
          'gradebook-table__wrapper u-display_flex u-border u-border-radius_1 u-border-color_slate-300'
        )}
        ref={container}
        onScroll={onScroll}
      >
        <table className='gradebook-table__table'>
          <thead ref={setTableHeaderRef} className='gradebook-table__thead'>
            <tr className='gradebook-table__dimension-header'>
              <th aria-hidden='true' />
              {loading && <th className='gradebook-table__assignment-header' aria-hidden />}

              {!loading &&
                assignments.map((assignment) => (
                  <th
                    className='gradebook-table__assignment-header'
                    colSpan={filteredColumns.length}
                    key={assignment.id}
                    title={assignment.name}
                    onMouseEnter={() => {
                      setHoveredAssignmentId(assignment.id);
                    }}
                    onMouseLeave={() => {
                      setHoveredAssignmentId(null);
                    }}
                  >
                    <div className='u-display_flex u-align-items_center u-justify-content_space-between'>
                      <span>{assignment.name}</span>
                      <WithTooltip content='Drilldown' placement='bottom'>
                        <IconButton
                          className={classNames(
                            'gradebook-table__drilldown-button gradebook-table__drilldown-button--assignment',
                            {
                              'gradebook-table__drilldown-button--visible':
                                hoveredAssignmentId === assignment.id
                            }
                          )}
                          variant='solid'
                          color='primary'
                          size='s'
                          label='Drilldown'
                          onClick={() => {
                            history.pushState(
                              null,
                              `${pathname}/assignments/${assignment.id}${search}`
                            );
                          }}
                          icon='arrow-right'
                        />
                      </WithTooltip>
                    </div>
                  </th>
                ))}
            </tr>
            <tr className='gradebook-table__metrics-header'>
              {/* "Students" header */}
              <th className='gradebook-table__students-header' ref={studentsColumnRef}>
                <div className='u-display_flex u-align-items_center u-justify-content_space-between'>
                  <span>Students</span>
                  <GradebookSorts dimensions={dimensions} sorts={sorts} />
                </div>
              </th>

              {/* blank header during loading */}
              {loading && <th aria-hidden />}

              {/* metrics headers when not loading */}
              {!loading &&
                assignments.map((assignment, i) =>
                  filteredColumns.map((col) => {
                    const key = `${col.key}-${assignment.id}`;
                    if (col.columnType === 'metric') {
                      if (col.key === 'performance') {
                        return (
                          <PerformanceHeaderCell
                            showSort={false}
                            key={key}
                            index={i}
                            totalColumns={assignments.length}
                            onMouseEnter={() => {
                              setHoveredAssignmentId(assignment.id);
                            }}
                            onMouseLeave={() => {
                              setHoveredAssignmentId(null);
                            }}
                          />
                        );
                      }
                      if (col.key === 'answer_breakdown') {
                        return <AnswerBreakdownHeaderCell showSort={false} />;
                      }

                      if (col.metricType === 'renderedTitle') {
                        return (
                          <th
                            key={key}
                            aria-label='Title'
                            onMouseEnter={() => {
                              setHoveredAssignmentId(assignment.id);
                            }}
                            onMouseLeave={() => {
                              setHoveredAssignmentId(null);
                            }}
                          >
                            <col.renderTitle dimensions={dimensions} variables={variables} />
                          </th>
                        );
                      }
                    }
                    return (
                      <th
                        key={key}
                        onMouseEnter={() => {
                          setHoveredAssignmentId(assignment.id);
                        }}
                        onMouseLeave={() => {
                          setHoveredAssignmentId(null);
                        }}
                      >
                        {col.title}
                      </th>
                    );
                  })
                )}
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr className='gradebook-table__row'>
                <td style={{width: prevWidth ? `${prevWidth}px` : '30%'}} aria-label='Loading' />
                <td
                  style={{width: prevWidth ? `calc(100% - ${prevWidth}px)` : '70%'}}
                  aria-label='Loading'
                >
                  <LoadingSpinner size={2} />
                </td>
              </tr>
            )}

            {!loading &&
              students.map((student) => (
                <tr key={student.id} className='gradebook-table__row'>
                  <td>
                    <div className='u-display_flex u-align-items_center u-justify-content_space-between'>
                      <span>
                        {student.first_name} {student.last_name}
                      </span>

                      <WithTooltip content='Drilldown' placement='right'>
                        <IconButton
                          className='gradebook-table__drilldown-button gradebook-table__drilldown-button--student'
                          variant='solid'
                          color='primary'
                          size='s'
                          label='Drilldown'
                          onClick={() => {
                            history.pushState(null, `${pathname}/students/${student.id}${search}`);
                          }}
                          icon='arrow-right'
                        />
                      </WithTooltip>
                    </div>
                  </td>
                  {assignments.map((assignment) => {
                    const key = createStudentAssignmentMetricMatrixKey(student.id, assignment.id);

                    const currentResult = studentAssignmentMetricMatrix[key];

                    if (currentResult.metrics.dimensions_are_related) {
                      return filteredColumns.map((col) => {
                        if (col.columnType === 'metric') {
                          return (
                            <td
                              key={`${key}-${col.key}`}
                              onMouseEnter={() => {
                                setHoveredAssignmentId(assignment.id);
                              }}
                              onMouseLeave={() => {
                                setHoveredAssignmentId(null);
                              }}
                            >
                              {col.renderColumn(currentResult, dimensions, variables)}
                            </td>
                          );
                        }
                        return (
                          <td
                            key={`${key}-${col.key}`}
                            onMouseEnter={() => {
                              setHoveredAssignmentId(assignment.id);
                            }}
                            onMouseLeave={() => {
                              setHoveredAssignmentId(null);
                            }}
                          >
                            {col.renderColumn(currentResult, dimensions)}
                          </td>
                        );
                      });
                    }
                    return (
                      <>
                        <td
                          colSpan={filteredColumns.length}
                          key={key}
                          onMouseEnter={() => {
                            setHoveredAssignmentId(assignment.id);
                          }}
                          onMouseLeave={() => {
                            setHoveredAssignmentId(null);
                          }}
                        >
                          <div className='u-text-align_center'>
                            <Text color='tertiary' size='s' italic>
                              Not assigned
                            </Text>
                          </div>
                        </td>
                      </>
                    );
                  })}
                </tr>
              ))}
          </tbody>
        </table>
        {loadingMore && (
          <div className='gradebook-table__loading-spinner u-align-self_stretch u-display_flex u-align-items_center u-pad_1'>
            <LoadingSpinner size={2} />
          </div>
        )}
      </div>
      <ReportsPagination
        limit={limit}
        page={page}
        total={totals[0]}
        rowSizeOptions={rowSizeOptions}
      />
    </div>
  );
};

export default withRouter(GradebookTable);
