import React from 'react';

import {SupplementEditor} from 'client/Supplements/SupplementEditor/SupplementEditor';
import appStore from 'client/AppStore';
import questionEditorStore from 'client/EditPage/V2/QuestionEditor/QuestionEditor.store';
import {SUPPLEMENT_TYPES_LIST} from 'client/Supplements/shared';
import {generateDropdownOptions} from 'lib/generateDropdownOptions';

import {SupplementSearch} from '../SupplementSearch/SupplementSearch.react';
import './supplement-manager.scss';

export const supplementModes = {
  EDIT: 'edit',
  EDIT_ACTIVE: 'editActive',
  SEARCH: 'search'
} as const;

interface Props {
  isModal?: boolean;
}

const SupplementManager = ({isModal = false}: Props) => {
  const renderers = {
    [supplementModes.EDIT]: SupplementEditor,
    [supplementModes.EDIT_ACTIVE]: SupplementEditor,
    [supplementModes.SEARCH]: SupplementSearch
  };

  // Route params
  const {subject} = appStore.routerProps.params;

  // Supplement-related query params
  const {
    supplement,
    supplementMode,
    supplementOwner,
    supplementSearchStr,
    supplementSelectedSubject,
    supplementType
  } = appStore.routerProps.location.query;

  /**
   * There are up to 3 sources for the subject ID filter: 1) the selected subject
   * in the supplement search panel, 2) the selected subject in the queue, or 3)
   * the active question in the queue. We fall back upon each in that order.
   */

  const activeQuestionSetSubjectId = questionEditorStore.getQuestionSet()
    ? questionEditorStore.getQuestionSet().getSubjectId()
    : null;
  const activeSubjectId = supplementSelectedSubject || subject || activeQuestionSetSubjectId;
  const mode = supplementMode || supplementModes.SEARCH;
  const typeOptions = generateDropdownOptions({
    options: SUPPLEMENT_TYPES_LIST,
    includeAll: mode === supplementModes.SEARCH,
    unshiftAll: true
  });
  const Renderer = renderers[mode];
  return (
    <div className='supplement-manager'>
      <Renderer
        isModal={isModal}
        activeSubjectId={activeSubjectId}
        activeSupplementId={supplement}
        owner={supplementOwner}
        searchString={supplementSearchStr}
        showInsertButton={isModal && mode !== supplementModes.EDIT_ACTIVE}
        typeOptions={typeOptions}
        type={supplementType}
      />
    </div>
  );
};

export default SupplementManager;
