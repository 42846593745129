// @flow
import {Map} from 'immutable';
import moment from 'moment';
import {setUpStore, Store} from 'client/framework';
import appStore from 'client/AppStore';
import {resource} from '@albert-io/json-api-framework/request/builder';
import type {QueryBuilder} from '@albert-io/json-api-framework/request/builder';
import {invalidatePartialInterest} from 'resources/mandark.resource';
import SelectableDateRangeStore from 'generic/SelectableDateRange/SelectableDateRange.store';
import assignmentsListActions from './AssignmentsList.actions';
import {AssignmentModelV3} from 'resources/augmented/Assignment/AssignmentModel.v3';
import type {List} from 'immutable';

class AssignmentsListStore extends Store {
  selectableDateRangeStore: SelectableDateRangeStore;

  constructor(name: string) {
    super(name);
    this.selectableDateRangeStore = setUpStore(
      SelectableDateRangeStore,
      'AssignmentsListSelectableDateRangeStore'
    );
    const initialValidDates = Map({
      startDate: null,
      endDate: null
    });
    this.setInitialData(
      Map({
        classroomId: null,
        teacherId: null,
        lastValidDates: initialValidDates,
        assignmentToDelete: null,
        deleteAssignmentPromise: null
      })
    );
    this.handle(
      assignmentsListActions.SET_ASSIGNMENT_TO_DELETE,
      this.setProperty('assignmentToDelete')
    );
    this.handle(assignmentsListActions.SET_LAST_VALID_DATES, this._setLastValidDates);
    this.handle(assignmentsListActions.DELETE_ASSIGNMENT, this._deleteAssignment);
    this.handle(assignmentsListActions.INITIALIZE_TEACHER_AND_CLASSROOM_IDS, this._initializeIds);
  }

  _initializeIds(initialData: Map<string, *>) {
    this.writeData((state) => {
      return state
        .set('classroomId', initialData.get('classroomId'))
        .set('teacherId', initialData.get('teacherId'));
    });
  }

  _setLastValidDates({startDate, endDate}: {startDate: ?string, endDate: ?string}) {
    this.writeData(
      'lastValidDates',
      Map({
        startDate: startDate
          ? moment(startDate).startOf('day').subtract(1, 'second').valueOf()
          : null,
        endDate: endDate ? moment(endDate).endOf('day').valueOf() : null // Includes midnight
      })
    );
  }

  _deleteAssignment() {
    const deleteAssignmentPromise = this.getAssignmentToDelete().delete();
    this.writeData('deleteAssignmentPromise', deleteAssignmentPromise);
    deleteAssignmentPromise.then(() => {
      invalidatePartialInterest({resourcePath: ['assignments_v3']});
      this.writeData('deleteAssignmentPromise', null);
    });
  }

  getAssignmentsListQuery(status: string, shouldFilterByDueDate: boolean = true): QueryBuilder {
    return (
      resource('assignments_v3')
        .mandarkEndpoint(['assignments_v3'])
        .include('sections_v1')
        .include('students_v2')
        .withMeta('assignment_v3')
        .meta({
          context: {
            assignment: {
              stats: true
            }
          }
        })
        .filter({
          assignment_type: 'assignment',
          classrooms_v1: appStore.routerProps.params.classId
        })
        .filter(
          {
            due_date: {
              greater_than_inclusive: this.getLastValidDates().get('startDate'),
              less_than_inclusive: this.getLastValidDates().get('endDate')
            }
          },
          this.filterDatesAreSet() && shouldFilterByDueDate
        )
        // CLOSED:
        // The student has submitted the assignment
        // OR (the assignment is past due and late submissions are not allowed)
        .filter(
          {
            any_of: [
              {
                'meta.count_of_incomplete_students': 0
              },
              {
                due_date: {
                  less_than: 'now'
                },
                allow_late_submissions: false
              }
            ]
          },
          status === 'closed'
        )
        // OPEN: status for 'open' is not set to make it the default tab when query prop is used
        // Start Date < Now
        // AND the student hasn't submitted
        // AND [(Due date < Now )OR (Due date > Now AND Late submissions allowed)]
        .filter(
          {
            all_of: [
              {
                any_of: [
                  {
                    start_date: {
                      less_than: 'now'
                    }
                  },
                  {
                    start_date: {
                      null: true
                    }
                  }
                ]
              },
              {
                any_of: [
                  {
                    due_date: {
                      greater_than: 'now'
                    }
                  },
                  {
                    due_date: {
                      less_than: 'now'
                    },
                    allow_late_submissions: true
                  }
                ]
              }
            ],
            'meta.count_of_incomplete_students': {
              not: 0
            }
          },
          !status
        )
        .filter(
          {
            start_date: {
              greater_than: 'now',
              null: false
            }
          },
          status === 'scheduled'
        )
    );
  }

  getAssignmentsList(): List<AssignmentModelV3> {
    // $FlowFixMe
    return this.getAssignmentsListQuery().getResource();
  }

  getClassroomId(): ?string {
    return this.readData('classroomId');
  }

  filterDatesAreSet(): boolean {
    return this.getLastValidDates().every((date) => Boolean(date));
  }

  getSelectableDateRangeStore = () => this.selectableDateRangeStore;

  getLastValidDates = () => this.readData('lastValidDates');

  getAssignmentToDelete = () => this.readData('assignmentToDelete');

  getDeleteAssignmentPromise = () => this.readData('deleteAssignmentPromise');
}

export default new AssignmentsListStore('AssignmentsListStore');
