/* eslint-disable no-underscore-dangle */
import {fromJS, List, Map, Set} from 'immutable';
import type {Moment} from 'moment';

import {Store} from 'client/framework';
import errorCodes from 'client/errorCodes';

import appStore from 'client/AppStore';
import assignmentsListStore from 'client/Classrooms/Classroom/Teacher/Assignments/AssignmentsList/AssignmentsList.store';
import masqueradeStore from 'generic/Masquerade/Masquerade.store';
import {mandarkEndpoint} from '@albert-io/json-api-framework/request/builder/legacy';
import {resource, query} from '@albert-io/json-api-framework/request/builder';
import {SETTINGS as correctAnswerSettings} from 'client/Assignments/constants';
import {genericMandarkRequest, invalidatePartialInterest} from 'resources/mandark.resource';
import type {QueryBuilder} from '@albert-io/json-api-framework/request/builder/legacy';

import {
  ClassroomModelV1,
  AssignmentModelV3,
  QuestionSetModelV1,
  StudentModel,
  SubjectModelV2
} from '@albert-io/models';

import {invalidateFilters} from 'components/PracticeView/PracticeViewToolbar/Filters/filters.utils';

import {invalidateTeacherAssignmentStats} from '../../framework/MandarkAPI/Assignments/Assignments';

import createAssignmentActions from './CreateAssignment.actions';
import {INPUT_VALIDATORS} from './utils/createAssignment.validators';
import type {FormData} from './CreateAssignment.types';

const defaultFormData: FormData = {
  name: '',
  message: '',
  due_date: null,
  start_date: null,
  time_limit: null,
  allow_late_submissions: false,
  correct_answer_setting: null,
  randomize_question_order: false,
  auto_assign_new_classroom_enrollees: false,
  lti_line_item_id: ''
};

class CreateAssignmentStore extends Store {
  initialData;
  /*
  : Map<
    keyof CreateAssignmentStoreData,
    CreateAssignmentStoreData[keyof CreateAssignmentStoreData]
  >;
  */

  // readData!: (query: string, defaultValue?: unknown) => any;

  // writeData!: (query: unknown, val?: unknown) => void;

  // setInitialData!: (data: Map<string, unknown>) => void;

  // handle!: (action: string, callback: (...args: any) => void) => void;

  // setProperty!: (key: string) => (val: unknown) => void;

  // withSavedPromise!: (
  //   callback: (...args: unknown[]) => void,
  //   key: string
  // ) => (...args: unknown[]) => void;

  // resetStore!: () => void;

  constructor(name: string) {
    super(name);

    this.initialData = fromJS({
      formData: defaultFormData,
      originalAssignment: null, // only exists in edit mode
      activeRecipientGroup: null,
      breadcrumbs: [],
      breadcrumbLabel: 'Source',
      errorData: {},
      hasReceivedAssignmentData: false,
      isAssignmentValid: false,
      isEditMode: false,
      isSavePending: false,
      isSaveSuccessful: false,
      questionCount: null,
      questionDifficultyTotals: {},
      questionSetList: [],
      promiseResponse: null,
      savedAssignmentPayload: [],
      selectedRecipients: {},
      toastMessage: [],
      showOutOfScopeSubjectModal: false,
      isAddSubjectsToOutOfScopeClassroomsPending: false,
      addOutOfScopeSubjectsToClassroomsPromise: null,
      sections: []
    });

    this.setInitialData(this.initialData);
    this.handle(createAssignmentActions.ADD_SINGLE_RECIPIENT, this._addSingleRecipient);
    this.handle(createAssignmentActions.SAVE_ASSIGNMENT, this._saveAssignment);
    this.handle(createAssignmentActions.LAUNCH_ASSIGNMENT_CREATOR, this._launchAssignmentCreator);
    this.handle(createAssignmentActions.REMOVE_SINGLE_RECIPIENT, this._removeSingleRecipient);
    this.handle(createAssignmentActions.RESET_STORE, this._resetStore);
    this.handle(createAssignmentActions.RESET_TOAST_MESSAGE, this._resetToastMessage);
    this.handle(createAssignmentActions.SET_ACTIVE_RECIPIENT_GROUP, this._setActiveRecipientGroup);
    this.handle(createAssignmentActions.SET_FIELD_STATE, this._setFieldState);
    this.handle(createAssignmentActions.SET_MULTIPLE_RECIPIENTS, this._setMultipleRecipients);
    this.handle(
      createAssignmentActions.SET_SAVED_ASSIGNMENT_PAYLOAD,
      this._setSavedAssignmentPayload
    );
    this.handle(
      createAssignmentActions.TOGGLE_AUTO_ASSIGN_NEW_CLASSROOM_ENROLLEES,
      this._toggleAutoAssignNewEnrollees
    );
    this.handle(createAssignmentActions.VALIDATE_ASSIGNMENT, this._validateAssignment);
    this.handle(
      createAssignmentActions.SET_SHOW_OUT_OF_SCOPE_SUBJECT_MODAL,
      this.setProperty('showOutOfScopeSubjectModal')
    );
    this.handle(
      createAssignmentActions.ADD_OUT_OF_SCOPE_SUBJECTS_TO_CLASSROOMS,
      this._addOutOfScopeSubjectsToClassrooms
    );
    this.handle(createAssignmentActions.UPDATE_SECTIONS, this.setProperty('sections'));
  }

  getFormData() {
    return this.readData('formData');
  }

  /*
    ASSIGNMENT OPTIONS HELPERS
  */
  _setFieldState(inputState: Map<string, unknown>) {
    const key = inputState.get('key');
    const value = inputState.get('value');
    const updatedFormData = this.readData('formData').updateIn([key], () => value);
    this.writeData('formData', updatedFormData);
  }

  /*
    ASSIGNMENT MODEL HELPERS
  */
  getOriginalAssignment(): AssignmentModelV3 {
    return this.readData('originalAssignment');
  }

  getSections() {
    return this.readData('sections');
  }

  getDefaultSections() {
    return this.getQueryForAssessment().getResource().getSortedSections();
  }

  /*
    LAUNCH_ASSIGNMENT_CREATOR
  */
  _launchAssignmentCreator({
    assignment: originalAssignment,
    assignmentMetadata,
    isEditMode = false
  }: {
    assignment: AssignmentModelV3,
    assignmentMetadata: Map<string, unknown>,
    isEditMode: boolean
  }) {
    this.writeData((store) => {
      let hydratedStore = store;

      hydratedStore = hydratedStore
        .merge(assignmentMetadata)
        .set('isEditMode', isEditMode)
        .set('hasReceivedAssignmentData', true);

      if (this.isAssigningPracticeExam()) {
        hydratedStore = hydratedStore.set('sections', this.getDefaultSections());
      }

      // edit mode should hydrate form with the classroom assignment settings
      if (isEditMode && originalAssignment) {
        const {classroomId} = appStore.routerProps.params;
        const classroomAssignment = this.getClassroomAssignmentByClassroomId(
          originalAssignment,
          classroomId
        );
        const settings = classroomAssignment.getSettings();
        if (settings) {
          const hydratedFormData = {
            name: originalAssignment.getName(),
            allow_late_submissions: settings.get('allow_late_submissions'),
            auto_assign_new_classroom_enrollees: settings.get(
              'auto_assign_new_classroom_enrollees'
            ),
            correct_answer_setting: settings.get('correct_answer_setting'),
            due_date: settings.get('due_date'),
            message: settings.get('message'),
            start_date: settings.get('start_date'),
            time_limit: settings.get('time_limit'),
            randomize_question_order: settings.get('randomize_question_order'),
            lti_line_item_id: settings.get('lti_line_item_id')
          };
          hydratedStore = hydratedStore
            .set('formData', fromJS(hydratedFormData))
            .set('originalAssignment', originalAssignment);
        }
      } else {
        const defaultCorrectAnswerSetting = this.isAssigningPracticeExam()
          ? correctAnswerSettings.SHOW_AFTER_ALL
          : correctAnswerSettings.SHOW_AFTER_EACH;
        hydratedStore = store
          .updateIn(['formData', 'correct_answer_setting'], () => defaultCorrectAnswerSetting)
          .merge(assignmentMetadata);
      }
      return hydratedStore;
    });
  }
  /*
    LAUNCH_ASSIGNMENT_CREATOR HELPERS
  */

  getBreadcrumbs(): List<string> {
    return this.readData('breadcrumbs');
  }

  getBreadcrumbLabel(): List<string> {
    return this.readData('breadcrumbLabel');
  }

  getQuestionSetList(): List<QuestionSetModelV1> {
    return this.readData('questionSetList');
  }

  getQuestionCount(): number {
    return this.readData('questionCount');
  }

  getTotalEasyQuestions(): number {
    return this.readData('questionDifficultyTotals').get('1', List()).size;
  }

  getTotalModerateQuestions(): number {
    return this.readData('questionDifficultyTotals').get('2', List()).size;
  }

  getTotalDifficultQuestions(): number {
    return this.readData('questionDifficultyTotals').get('3', List()).size;
  }

  getCurrentStep(): number {
    return this.readData('currentStep');
  }

  /*
    LOAD SELECT RECIPIENTS PAGE
  */
  getTeacherId(): string {
    return masqueradeStore.getUserIdByMasqueradeState();
  }

  _getClassroomsQuery(): QueryBuilder {
    return mandarkEndpoint(['teachers_v1', this.getTeacherId(), 'classrooms_v1'])
      .include('students_v1')
      .include('subjects_v1')
      .filter({
        status: 'active'
      })
      .pageSize(250);
  }

  getMyClassrooms(): List<ClassroomModelV1> {
    return this._getClassroomsQuery()
      .getResource()
      .sortBy((classroom) => classroom.getName().toLowerCase())
      .toList();
  }

  getClassroomAssignmentByClassroomId(
    assignment: AssignmentModelV3,
    classroomId: string
  ): AssignmentModelV3 {
    const classroom = assignment
      .getClassrooms()
      .filter((cr) => cr.get('id') === classroomId)
      .first();
    const classroomAssignment = classroom.getAssignmentRelationships(assignment.get('id'));
    return classroomAssignment;
  }

  hasReceivedMyClassrooms(): boolean {
    return this._getClassroomsQuery().isResourceReady();
  }

  /*
    SELECT RECIPIENTS PAGE HELPERS
  */
  _addSingleRecipient(recipientData: Map<string, string | StudentModel>) {
    const selectedRecipients = this.getSelectedRecipients();
    const classroomId = recipientData.get('classroomId');
    const classroomName = recipientData.get('classroomName');
    const student = recipientData.get('student');

    /*
      If the group already exists, push the current value to it. If not,
      wrap the new value in an Immutable list before setting it (or else
      the `remove` functionality will break)
    */
    const updatedSelectedRecipients = selectedRecipients
      .updateIn([classroomId, 'students'], List(), (list) => list.push(student))
      .setIn([classroomId, 'name'], classroomName);

    this.writeData('selectedRecipients', updatedSelectedRecipients);
  }

  _removeSingleRecipient(recipientData: Map<string, string | StudentModel>) {
    const classroomId = recipientData.get('classroomId');
    const student = recipientData.get('student');

    const selectedRecipients = this.getSelectedRecipients();

    /*
      Find the index of the current id and delete it from the group node.
    */
    const indexOfRecipientId = selectedRecipients
      .getIn([classroomId, 'students'])
      .findIndex((recipient) => {
        return recipient.getId() === student.getId();
      });

    let updatedSelectedRecipients;

    updatedSelectedRecipients = selectedRecipients.updateIn([classroomId, 'students'], (list) =>
      list.delete(indexOfRecipientId)
    );

    if (updatedSelectedRecipients.getIn([classroomId, 'students']).isEmpty()) {
      updatedSelectedRecipients = updatedSelectedRecipients.delete(classroomId);
    }

    this.writeData('selectedRecipients', updatedSelectedRecipients);
  }

  _setMultipleRecipients(recipientData: Map<string, string>) {
    const selectedRecipients = this.getSelectedRecipients();
    const addRecipients = recipientData.get('addRecipients');
    const classroomId = recipientData.get('classroomId');
    const classroomName = recipientData.get('classroomName');
    const students = recipientData.get('students');
    const autoAssignNewEnrollees = recipientData.get('autoAssignNewEnrollees');
    /*
      If addRecipients is true, create a new node
      for this classroom and add its recipients. If false,
      remove the node.
    */
    let updatedSelectedRecipients;

    updatedSelectedRecipients = selectedRecipients.update(classroomId, Map(), (classroom) => {
      const updateType = addRecipients ? 'set' : 'delete';
      return classroom[updateType]('students', students)
        [updateType]('name', classroomName)
        [updateType]('autoAssignNewEnrollees', autoAssignNewEnrollees);
    });
    /*
      If this was the last recipient of the classroom, remove the classroom
    */
    if (updatedSelectedRecipients.get(classroomId).isEmpty()) {
      updatedSelectedRecipients = updatedSelectedRecipients.delete(classroomId);
    }

    this.writeData('selectedRecipients', updatedSelectedRecipients);
  }

  _toggleAutoAssignNewEnrollees(recipientData: Map<string, string>) {
    const selectedRecipients = this.getSelectedRecipients();
    const classroomId = recipientData.get('classroomId');
    const autoAssignNewEnrollees = recipientData.get('autoAssignNewEnrollees');

    const updatedSelectedRecipients = selectedRecipients.setIn(
      [classroomId, 'autoAssignNewEnrollees'],
      autoAssignNewEnrollees
    );

    this.writeData('selectedRecipients', updatedSelectedRecipients);
  }

  async _addOutOfScopeSubjectsToClassrooms(hasLicense): Promise<void> {
    this.writeData('isAddSubjectsToOutOfScopeClassroomsPending', true);
    const classroomPromises = this.getClassroomsWithOutOfScopeSubjects().reduce(
      // <ClassroomModelV1[]>
      (acc, subjects, classroom) => {
        const relationship: {
          type: string,
          relation: string[],
          customMeta?: Map<string, Map<string, boolean>>
        } = {
          type: 'subjects_v2',
          relation:
            subjects &&
            subjects
              .map((subject) => (typeof subject === 'string' ? subject : subject.getId()))
              .toJS()
        };
        if (hasLicense) {
          relationship.customMeta =
            subjects &&
            subjects.reduce((prev, curr) => {
              const id = typeof curr === 'string' ? curr : curr.getId();
              return (
                prev
                  // as Map<string, Map<string, boolean>>
                  .set(id, fromJS({restrict_free_practice: true}))
              );
            }, Map());
        }
        const classroomWithSubjects: ClassroomModelV1 = classroom
          .addRelationship(relationship)
          .save();
        if (acc) {
          acc.push(classroomWithSubjects);
        }
        return acc;
      },
      []
    );
    const addOutOfScopeSubjectsToClassroomsPromise = Promise.all(classroomPromises);
    this.writeData(
      'addOutOfScopeSubjectsToClassroomsPromise',
      addOutOfScopeSubjectsToClassroomsPromise
    );
    await addOutOfScopeSubjectsToClassroomsPromise;
    this.writeData((store) =>
      store
        .set('showOutOfScopeSubjectModal', false)
        .set('isAddSubjectsToOutOfScopeClassroomsPending', false)
        .set('addOutOfScopeSubjectsToClassroomsPromise', null)
    );
  }

  /*
    In CreateAssignmentRecipientGroup, `activeGroup` will control
    which group component re-renders on classroom and student select.
  */
  _setActiveRecipientGroup(groupId: string) {
    this.writeData('activeRecipientGroup', groupId);
  }

  getActiveRecipientGroup(): string {
    return this.readData('activeRecipientGroup');
  }

  getSelectedRecipients(): Map<string, ClassroomModelV1> {
    return this.readData('selectedRecipients');
  }

  getSelectedRecipientsTotal(): number {
    return this.getSelectedRecipientSet().size;
  }

  getSelectedRecipientSet(): Set<string> {
    return this.getSelectedRecipients()
      .map((classroom) => {
        return classroom.get('students').reduce((result, student) => {
          return result.push(student.getId());
        }, List());
      })
      .toList()
      .flatten()
      .toSet();
  }

  getClassroomsWithOutOfScopeSubjects(): Map<ClassroomModelV1, SubjectModelV2 | string[]> {
    const selectedRecipientIds = this.getSelectedRecipients().keySeq().toSet();
    const selectedRecipientClasses = this.getMyClassrooms()
      .filter((classroom) => selectedRecipientIds.includes(classroom.getId()))
      .toSet();
    const questionSetSubjects = this.getQuestionSetList()
      .map((questionSet) => {
        let subject = questionSet.getSubject();
        if (subject.isEmpty()) {
          subject = questionSet.getSubjectId();
        }
        return subject;
      })
      .toSet();
    return selectedRecipientClasses.reduce(
      // <Map<ClassroomModelV1, SubjectModelV2 | string[]>>
      (acc, classroom) => {
        const classroomSubjectIds = classroom.getSubjects().keySeq();
        const missingSubjects = questionSetSubjects.filterNot((subject) =>
          classroomSubjectIds.includes(typeof subject === 'string' ? subject : subject.getId())
        );
        if (!missingSubjects.isEmpty() && acc) {
          acc.set(classroom, missingSubjects);
        }
        return acc;
      },
      Map()
    );
  }

  doSelectedClassroomsLackSubjectScope(): boolean {
    return !this.getClassroomsWithOutOfScopeSubjects().isEmpty();
  }

  shouldShowOutOfScopeSubjectModal = () => this.readData('showOutOfScopeSubjectModal');

  isAddSubjectsToOutOfScopeClassroomsPending = () =>
    this.readData('isAddSubjectsToOutOfScopeClassroomsPending');

  getAddOutOfScopeSubjectsToClassroomsPromise = () =>
    this.readData('addOutOfScopeSubjectsToClassroomsPromise');

  _validateAssignment() {
    /**
     * 99% of the cases this first gets called, it's when the user edits the title.
     * On those first passes, we mark the assignment invalid insofar as it's incomplete
     * to suppress the due date error that will appear (at first).
     */
    if (this.getDueDate()) {
      /*
      This method iterates through inputKeys, calling each key's corresponding validator function
    */
      const formData = this.getFormData();
      const errorData = formData.reduce((acc, value, fieldName) => {
        // handle start time error seperately since we only manage start_date in the form
        if (fieldName === 'start_date') {
          const startTimeErrorMessage = INPUT_VALIDATORS.start_time(value, formData);
          if (startTimeErrorMessage) {
            acc = acc.set('start_time', startTimeErrorMessage);
          }
        }
        const validatorFunc = INPUT_VALIDATORS[fieldName];
        if (validatorFunc) {
          const errorMessage = validatorFunc(value, formData);
          return acc[errorMessage ? 'set' : 'delete'](fieldName, errorMessage);
        }
        return acc;
      }, Map());
      this.writeData((store) => {
        const isAssignmentValid = errorData.size === 0;
        return store.set('errorData', errorData).set('isAssignmentValid', isAssignmentValid);
      });
    }
    if (this.isAssigningPracticeExam()) {
      this._validateSectionTimeLimits();
    }
  }

  getId(): string {
    return this.getFormData().get('id');
  }

  getName(): string {
    return this.getFormData().get('name');
  }

  getIsAllowLateSubmissions(): boolean {
    return this.getFormData().get('allow_late_submissions');
  }

  getCorrectAnswerSetting(): string {
    return this.getFormData().get('correct_answer_setting');
  }

  getDueDate(): Moment | string {
    return this.getFormData().get('due_date');
  }

  getStartDate(): Moment | string {
    return this.getFormData().get('start_date');
  }

  getTimeLimit(): number | null {
    return this.isAssigningPracticeExam()
      ? this.getQueryForAssessment().getResource().get('time_limit')
      : this.getFormData().get('time_limit');
  }

  getMessage(): string | null {
    return this.getFormData().get('message');
  }

  getIsRandomizeQuestionOrder(): boolean {
    return this.getFormData().get('randomize_question_order');
  }

  getLtiLineItemId(): string {
    return this.getFormData().get('lti_line_item_id');
  }

  getErrorData(): Map<string, List<string>> {
    return this.readData('errorData');
  }

  getIsAssignmentValid(): boolean {
    return this.readData('isAssignmentValid');
  }

  /*
   Assignment editing helpers
  */
  isAutoAssignNewClassroomEnrollees(): boolean {
    return this.getFormData().get('auto_assign_new_classroom_enrollees');
  }

  getStudentIds(): List<string> {
    return this.getOriginalAssignment().getStudentIds();
  }

  getStudents(): List<StudentModel> {
    return this.getOriginalAssignment().getStudents();
  }

  getClassrooms(): List<ClassroomModelV1> {
    return this.getOriginalAssignment().getClassrooms();
  }

  getAssignmentClassroomId(): string {
    return this.getClassrooms().first().getId();
  }

  getAssignmentClassroomName(): string {
    return this.getClassrooms().first().getName();
  }

  /**
   * Used to check if the assignment is assigned to all students in all classrooms
   * If so, the FE can send an empty studentIds array to the assignment association to let the BE handle assignment of ALL students
   *
   * @returns {boolean} Whether the assignment is assigned to all students in all classrooms
   */
  isAssignedToEveryone(): boolean {
    const selectedRecipients = this.getSelectedRecipients();
    const myClassrooms = this.getMyClassrooms();
    const isAssignedToEveryone = selectedRecipients.every((classroom, id) => {
      const recipientsSelected = classroom.get('students');
      const allClassroomEnrollees = myClassrooms
        .find((cachedClassroom) => cachedClassroom.getId() === id)
        .getStudents();
      return allClassroomEnrollees.size === recipientsSelected.size;
    });
    return isAssignedToEveryone;
  }

  getAssignmentSettings() {
    const ltiLineItemId = this.getLtiLineItemId();
    const message = this.getMessage();
    const timeLimit = this.getTimeLimit();
    const settings = {
      allow_late_submissions: this.getIsAllowLateSubmissions(),
      correct_answer_setting: this.getCorrectAnswerSetting(),
      due_date: this.getDueDate(),
      randomize_question_order: this.getIsRandomizeQuestionOrder(),
      start_date: this.getStartDate() || 'now',
      // if these are empty strings or null, the backend errors
      ...(ltiLineItemId && {lti_line_item_id: ltiLineItemId}),
      ...(message && {message}),
      ...(timeLimit && {time_limit: timeLimit})
    };
    return settings;
  }

  async _saveAssignment() {
    if (this.getIsSavePending()) {
      return;
    }

    this.writeData('isSavePending', true);

    const promiseResponse = this.getIsEditMode()
      ? this.getSelectedRecipients()
          .map((classroom, id) => id && this._processSaveAssignment(classroom, id))
          .toArray()
      : genericMandarkRequest(
          'post',
          query()
            .mandarkEndpoint(['assignments_v3'])
            .include('classrooms_v1.school_v5,students_v1')
            .withMeta('assignment_v3,classroom_v1,student_v1')
            .customQuery({
              add_students_if_empty: this.isAssignedToEveryone(),
              meta: {
                context: {
                  lti_deployment: true,
                  lti_platform_name: true,
                  student: {
                    id: this.getTeacherId()
                  }
                }
              }
            })
            .done(),
          {
            data: this._createAssignmentPayload()
          }
        ).then((assignmentResults) => {
          invalidatePartialInterest({resourcePath: ['assignments_v3']});

          // Invalidates classroom list view metadata query + index query
          invalidatePartialInterest({
            resourcePath: [
              'teachers_v1',
              masqueradeStore.getUserIdByMasqueradeState(),
              'classrooms_v1'
            ]
          });
          // Invalidates classroom by id query driving the teacher classroom detail view
          this.getSelectedRecipients().forEach((_, id) => {
            invalidatePartialInterest({resourcePath: ['classrooms_v1', id]});
            /*
            Store updates for front-end
          */
            this.writeData((store) => {
              return store.set('isSavePending', false).set('isSaveSuccessful', true);
            });
          });

          invalidateFilters();

          invalidateTeacherAssignmentStats();

          return assignmentResults;
        });

    this.writeData('promiseResponse', promiseResponse);
  }

  _createAssignmentPayload() {
    const classrooms = this.getSelectedRecipients();
    const autoAssignNewEnrollees = classrooms.first().get('autoAssignNewEnrollees');
    const questionSets = this.getQuestionSetList();

    const isAssignedToEveryone = this.isAssignedToEveryone();
    const studentIds = isAssignedToEveryone
      ? []
      : classrooms.reduce(
          // <string[]>
          (acc, classroom) => [
            ...acc,
            // as string[]
            ...classroom.get('students').map((student) => student.getId())
          ],
          []
        );
    const uniqueStudentIds = Set(studentIds);

    const teacherId = this.getTeacherId();
    let assignmentModel = new AssignmentModelV3();
    const {folderId} = appStore.routerProps.params;

    if (folderId) {
      assignmentModel = assignmentModel.setTemplateId(folderId);
    }

    const questionSetCustomMeta = questionSets.reduce(
      // <Map<string, {position?: number}>>
      (result, questionSet, index) =>
        result.set(questionSet.getId(), {
          position: index
        }),
      Map()
    );

    const settings = {
      ...this.getAssignmentSettings(),
      auto_assign_new_classroom_enrollees: autoAssignNewEnrollees
    };

    const classroomIds = classrooms.keySeq().toArray();
    const classesCustomMeta = classroomIds.reduce(
      (result, id) =>
        result.set(id, {
          settings: {
            type: 'classroom',
            settings: {
              ...settings,
              auto_assign_new_classroom_enrollees: classrooms.getIn([id, 'autoAssignNewEnrollees'])
            }
          }
        }),
      Map()
    );

    const name = this.getName();

    const payload = assignmentModel
      .setName(name)
      .setStatus('assigned')
      .setSettings({
        type: 'default',
        settings
      })
      .updateRelationship('teacher_v1', teacherId)
      .updateRelationship('question_sets_v1', questionSets, true, questionSetCustomMeta)
      .updateRelationship('classrooms_v1', classroomIds, true, classesCustomMeta)
      .updateRelationship('students_v1', uniqueStudentIds)
      .getConstructedPayload();

    return payload;
  }

  // path for editing assignments only, create works through bulk create, edit is singular
  async _processSaveAssignment(
    classroom: ClassroomModelV1,
    classroomId: string
  ): Promise<AssignmentModelV3 | null> {
    const questionSets = this.getQuestionSetList();
    const classroomName = classroom.get('name');

    const studentIds = classroom.get('students').map((student) => student.getId());

    const teacherId = this.getTeacherId();
    const autoAssignNewEnrollees = classroom.get('autoAssignNewEnrollees');
    /*
      While the creation script is running we set the isSavePending flag
      in order to temporarily lock the 'submit' button on the ACF.
    */
    this.writeData('isSavePending', true);

    try {
      let assignmentModel = this.getOriginalAssignment();

      const {folderId} = appStore.routerProps.params;
      if (folderId) {
        assignmentModel = assignmentModel.setTemplateId(folderId);
      }

      const questionSetCustomMeta = questionSets.reduce(
        // <Map<string, {position?: number}>>
        (result, questionSet, index) =>
          result.set(questionSet.getId(), {
            position: index
          }),
        Map()
      );

      const settings = {
        ...this.getAssignmentSettings(),
        auto_assign_new_classroom_enrollees: autoAssignNewEnrollees
      };

      const classCustomMeta = Map({
        [classroomId]: {
          settings: {
            type: 'classroom',
            settings
          }
        }
      });

      const name = this.getName();

      const promiseResponse = await assignmentModel
        .setName(name)
        .setStatus('assigned')
        .updateRelationship('question_sets_v1', questionSets, true, questionSetCustomMeta)
        .updateRelationship('classrooms_v1', [classroomId], true, classCustomMeta)
        .save({
          customQuery: {
            include: 'classrooms_v1.school_v5,classrooms_v1.students_v1,students_v1',
            customQuery: {
              with_meta: 'assignment_v3,classroom_v1,student_v1',
              meta: {
                context: {
                  lti_deployment: true,
                  lti_platform_name: true,
                  student: {
                    id: teacherId
                  }
                }
              }
            }
          }
        });

      // send separate request to update students
      // we have to send a POST to handle selected students
      // and a DELETE on unselected students
      // otherwise the BE will 403 if we include these updates
      // on the main assignment model since it may modify students
      // not associated to the teachers current classroom
      const assignmentId = promiseResponse.getId();
      if (assignmentId) {
        const assignmentClassrooms = promiseResponse.getClassrooms();

        // fetch the student count from assignment classrooms
        const allClassroomEnrollees = assignmentClassrooms
          .find((assignmentClassroom) => assignmentClassroom.getId() === classroomId)
          .getStudents();

        const unselectedStudents = allClassroomEnrollees.filterNot((student) =>
          studentIds.includes(student.getId())
        );

        const studentQuery = {
          resourcePath: ['assignments_v3', assignmentId, 'relationships', 'students_v1']
        };

        if (unselectedStudents.size) {
          await genericMandarkRequest('delete', studentQuery, {
            data: unselectedStudents.map((student) => ({
              id: student.getId(),
              type: 'students_v1'
            }))
          });
        }

        await genericMandarkRequest('post', studentQuery, {
          data: studentIds.map((studentId) => ({
            id: studentId,
            type: 'students_v1'
          }))
        });
      }

      if (assignmentsListStore.getClassroomId() === classroomId) {
        // invalidate teacher assignments query
        invalidatePartialInterest({
          resourcePath: ['assignments_v3'],
          filter: {
            classrooms_v1: classroomId
          }
        });
      } else {
        invalidatePartialInterest({resourcePath: ['assignments_v3']});
      }

      invalidatePartialInterest({resourcePath: ['assignments_v3', assignmentId]});

      // Invalidates classroom list view metadata query + index query
      invalidatePartialInterest({
        resourcePath: ['teachers_v1', masqueradeStore.getUserIdByMasqueradeState(), 'classrooms_v1']
      });
      // Invalidates classroom by id query driving the teacher classroom detail view
      invalidatePartialInterest({resourcePath: ['classrooms_v1', classroomId]});

      invalidateFilters();

      invalidateTeacherAssignmentStats();

      const updatedToastMessage = this.getToastMessages().push(
        `Your assignment for ${classroomName} has been saved.`
      );

      /*
        Store updates for front-end
      */
      this.writeData((store) => {
        return store
          .set('isSavePending', false)
          .set('toastMessage', updatedToastMessage)
          .set('isSaveSuccessful', true);
      });
      return promiseResponse;
    } catch (e) {
      logger.error('Failed saving assignment: %s', e.message || 'unknown error');
      const error = e.response.body.errors[0];
      let message = 'There was a problem with your submission. Please try again.';
      if (error.code === errorCodes.BAD_REQUEST.VALIDATION_ERROR.QUESTION_SET_IN_HIDDEN_SUBJECT) {
        message =
          'This assignment contains questions from a subject that has been unpublished. No new assignments can be created with these questions.';
      } else if (error.code === errorCodes.BAD_REQUEST.QUESTION_IS_ARCHIVED) {
        message =
          'This assignment contains questions that have been archived. No new assignments can be created with these questions.';
      } else if (error.code === errorCodes.BAD_REQUEST.QUESTION_IS_HIDDEN) {
        message =
          'This assignment contains questions that have been hidden. No new assignments can be created with these questions.';
      }
      const updatedToastMessage = this.getToastMessages().push(message);
      this.writeData((store) => {
        return store
          .set('isSavePending', false)
          .set('isSaveSuccessful', false)
          .set('toastMessage', updatedToastMessage);
      });

      return null;
    }
  }

  getPromiseResponse(): Promise<AssignmentModelV3 | null> {
    return this.readData('promiseResponse');
  }

  getToastMessages(): List<string> {
    return this.readData('toastMessage');
  }

  getIsSavePending(): boolean {
    return this.readData('isSavePending');
  }

  getIsSaveSuccessful(): boolean {
    return this.readData('isSaveSuccessful');
  }

  getIsEditMode(): string {
    return this.readData('isEditMode');
  }

  _resetStore() {
    this.resetStore();
    this.writeData('assignment', AssignmentModelV3.getDefaultModel());
  }

  getInsertedAt(): Moment | null {
    return this.getOriginalAssignment().getInsertedAt();
  }

  hasStartDate(): boolean {
    /*
      We can infer that the assignment has no start date if its
      inserted_at date/updated_at date is identical to its start date.
      This suggests the start_date was created by default. We flag
      such dates as false positives and return "false" if such dates match.
    */
    const insertedAt = this.getInsertedAt();
    const updatedAt = this.getUpdatedAt();
    const insertedAtDateAndStartDateAreSame =
      insertedAt && insertedAt.isSame(this.getStartDate(), 'minute');
    const updatedAtDateAndStartDateAreSame =
      updatedAt && updatedAt.isSame(this.getStartDate(), 'minute');
    return !updatedAtDateAndStartDateAreSame && !insertedAtDateAndStartDateAreSame;
  }

  getUpdatedAt(): Moment | null {
    return this.getOriginalAssignment().getUpdatedAt();
  }

  getHasReceivedAssignmentData(): boolean {
    return this.readData('hasReceivedAssignmentData');
  }

  /**
   * Return the query for bootstrapping the ACF
   *
   * @param {Object} params
   * @param {string} params.assignmentId The route param telling us what kind of assignment to create.
   * @param {string} params.classroomId classroomId to filter by
   * @returns {Object} query builder object
   */
  getQueryForAssignment({assignmentId, classroomId}) {
    return mandarkEndpoint(['assignments_v3', assignmentId])
      .include('classrooms_v1.students_v1.student_assignments_v1')
      .include('question_sets_v1.questions_v1')
      .include('question_sets_v1.guide_levels_v2', this.getTeacherId())
      .include('question_sets_v1.subject_v2', this.getTeacherId())
      .include('students_v1')
      .filter({
        included: {
          students_v1: {
            classrooms_v1: {
              id: classroomId
            }
          },
          'classrooms_v1.students_v1.student_assignments_v1': {
            id: assignmentId,
            teacher_v1: this.getTeacherId()
          },
          ...(classroomId && {
            classrooms_v1: {
              id: classroomId
            }
          })
        }
      });
  }

  /**
   * Return the query for bootstrapping the ACF
   *
   * @param {Object} params
   * @param {string} params.folderId The route param telling us what kind of assignment to create.
   * @returns {Object} query builder object
   */
  getQueryForFolder({folderId}) {
    return mandarkEndpoint(['templates_v1', folderId])
      .include('question_sets_v1.questions_v1')
      .include('question_sets_v1.guide_levels_v2', this.getTeacherId())
      .include('question_sets_v1.subject_v2', this.getTeacherId());
  }

  /**
   * Return the query for bootstrapping the ACF
   *
   * @param {Object} params
   * @param {string} params.subjectSlug The route param telling us what kind of assignment to create
   * @param {string} params.topicSlug The route param telling us what kind of assignment to create
   * @param {?string} params.questionSetSlug The route param telling us what kind of assignment to create
   * @returns {Object} query builder object
   */
  getQueryForSubjectSlug({subjectSlug, topicSlug, questionSetSlug}) {
    const requestFilter = questionSetSlug
      ? {slug_id: questionSetSlug}
      : {
          subject_v2: {
            url_slug: subjectSlug
          },
          guide_levels_v2: {
            url_slug: topicSlug
          }
        };

    return mandarkEndpoint(['question_sets_v1'])
      .include('guide_levels_v2')
      .include('questions_v1')
      .include('subject_v2')
      .filter(requestFilter)
      .sort('difficulty')
      .page(1)
      .pageSize(200);
  }

  // Practice exams
  /**
   * Return the query for bootstrapping the ACF
   *
   * @params {Object} param
   * @param params
   * @param {string} params.examId The route param telling us what kind of assignment to create.
   * @returns {Object} query builder object
   */
  getQueryForAssessment(params = appStore.routerProps.params) {
    const {examId} = params;
    return resource('exam_v1')
      .mandarkEndpoint(['exams_v1', examId])
      .include('sections_v1.question_sets_v1.questions_v1.supplements_v1')
      .include('sections_v1.question_sets_v1.subject_v2')
      .include('sections_v1.question_sets_v1.tags_v1')
      .include('question_sets_v1.questions_v1.supplements_v1')
      .include('question_sets_v1.subject_v2')
      .include('question_sets_v1.tags_v1')
      .include('subject_v2')
      .customQuery({
        question_v1: {
          labels: true
        }
      });
  }

  getSectionTimeLimitError(timeLimit: number): null | string {
    let errorMessage;
    // eslint-disable-next-line no-restricted-globals
    if (timeLimit !== null && (isNaN(timeLimit) || timeLimit <= 0)) {
      errorMessage = 'Time limit must be greater than 0';
    } else if (timeLimit > 60 * 24 * 7) {
      errorMessage = 'Time limit cannot exceed seven days';
    }
    return errorMessage;
  }

  isAssigningPracticeExam(): boolean {
    return appStore.routerProps.params.examId !== undefined;
  }

  getAssignmentTypeLabel(): string {
    return this.isAssigningPracticeExam() ? 'assessment' : 'assignment';
  }

  _validateSectionTimeLimits() {
    const hasError = this.getSections().some((section) => !!section.validators.getTimeLimit());

    if (hasError) {
      this.writeData('isAssignmentValid', false);
    }
  }

  // Practice exams end

  _resetToastMessage() {
    this.writeData('toastMessage', List());
  }

  _setSavedAssignmentPayload(payload: AssignmentModelV3) {
    this.writeData('savedAssignmentPayload', payload);
  }

  getSavedAssignmentPayload(): AssignmentModelV3 {
    return this.readData('savedAssignmentPayload');
  }
}

export default new CreateAssignmentStore('CreateAssignmentStore');
