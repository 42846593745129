import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {Button, Icon, Form, LabeledSelection, InputGroup} from '@albert-io/atomic';
import './content-discovery-survey.scss';

const ContentDiscoverySurvey = ({
  section,
  step,
  handlePageSelections,
  onBackButtonClick,
  onNextButtonClick,
  selectedOptions,
  filteredOptions,
  hasAnsweredSurvey,
  onCancelButtonClick
}) => {
  return (
    <div className='content-discovery-survey__container'>
      {step > 0 && (
        <Button variant='text' className='u-mar-t_2' onClick={onBackButtonClick}>
          <Icon className='u-mar-r_2' icon='arrow-left' /> Back
        </Button>
      )}
      <Form>
        <Form.Header className='u-mar-t_1' subtitle='Select all that apply'>
          {section.get('title')}
        </Form.Header>
        <InputGroup className='content-discovery-survey__options'>
          {section.get('options').map((option, i) => {
            return (
              (!filteredOptions || filteredOptions.has(option)) && (
                <LabeledSelection
                  key={i}
                  checked={selectedOptions.has(option)}
                  onChange={() => handlePageSelections(option)}
                  text={option}
                />
              )
            );
          })}
        </InputGroup>
        <Form.Footer align='left'>
          <Button
            disabled={selectedOptions.size === 0}
            onClick={onNextButtonClick}
            data-testid='content-discovery-survey__next-submit-btn'
          >
            {step !== 2 ? 'Next' : 'Submit'}
          </Button>
          {hasAnsweredSurvey ? (
            // type = 'button' needed for the form element
            <Button type='button' color='secondary' onClick={onCancelButtonClick}>
              Cancel
            </Button>
          ) : null}
        </Form.Footer>
      </Form>
    </div>
  );
};

ContentDiscoverySurvey.propTypes = {
  section: ImmutablePropTypes.map,
  selectedOptions: PropTypes.instanceOf(Set),
  step: PropTypes.number,
  handlePageSelections: PropTypes.func,
  onBackButtonClick: PropTypes.func,
  filteredOptions: PropTypes.instanceOf(Set),
  onNextButtonClick: PropTypes.func,
  onCancelButtonClick: PropTypes.func,
  hasAnsweredSurvey: PropTypes.bool
};

export default ContentDiscoverySurvey;
