import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {getModelForResourceType} from 'resources/modelRegistry';
import ArrayToReadableFragment from 'lib/hocs/arrayToReadableFragment';
import {Banner, Button} from '@albert-io/atomic';

import './classroom-invite-banner.scss';

const ClassroomInviteBanner = ({className, disabled, onClick, invitation, ...rest}) => {
  const salutationsAndNamesArr = [
    ...invitation
      .getClassroom()
      .getTeachers()
      .map((teacher) => `${teacher.getSalutation()} ${teacher.getLastName()}`)
  ];
  return (
    <Banner className={classnames('invite-banner u-width_100pc', className)} {...rest}>
      <div className='invite-banner__inner u-display_flex u-width_100pc'>
        <div className='u-display_flex u-mar-r_auto'>
          <Banner.Icon className='u-align-self_center' icon={['far', 'envelope']} />
          <span className='u-align-self_center'>
            You have been invited to <b>{invitation.getClassroom().getName()}</b> with{' '}
            <ArrayToReadableFragment arr={salutationsAndNamesArr} as='b' />
          </span>
        </div>
        <Button
          className='u-align-self_center invite-banner__btn'
          disabled={disabled}
          onClick={onClick}
          size='s'
        >
          Accept
        </Button>
      </div>
    </Banner>
  );
};

ClassroomInviteBanner.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  invitation: PropTypes.instanceOf(getModelForResourceType('classroom_invitation_v1')),
  onClick: PropTypes.func
};

export default ClassroomInviteBanner;
