import React from 'react';
import PropTypes from 'prop-types';
import {makeParser} from 'generic/MarkdownRendererV2/markdownRendererUtils';
import supplementStore from 'client/Supplement/SupplementStore';
import connectToStores from 'lib/decorators/connectToStores';

@connectToStores(supplementStore)
export default class BasicRenderer extends React.Component {
  static propTypes = {
    text: PropTypes.string.isRequired
  };

  createOutput() {
    const parser = makeParser();
    const syntaxTree = parser.makeSyntaxTree(this.props.text);
    return parser.reactOutput(syntaxTree);
  }

  render() {
    return <>{this.createOutput()}</>;
  }
}
