import React, {useContext, useEffect, useMemo} from 'react';

import {LoadingSpinner, LoadingSpinnerLong, NoDataMsg, addToast} from '@albert-io/atomic';
import {useIsFeatureFlagEnabled} from 'client/components/FeatureFlag/FeatureFlag.react';
import classNames from 'classnames';
import {useHasProUpgrade} from 'lib/hooks/useHasProUpgrade';

import {IS_REPORTS_BLOCKED_FOR_NON_PRO_ADMINS} from '../reports.utils';
import {ReportsContext} from '../Reports.context';
import ReportToolbar from '../ReportToolbar/ReportToolbar.react';

import ReportsTable from './Table/ReportsTable.react';
import GradebookTable from './GradebookTable/GradebookTable.react';

import './report.scss';
import ReportPreview from './ReportPreview.react';

import SummaryStats from './SummaryStats/SummaryStats';

function Report() {
  const {
    data,
    topLevelFilterType,
    topLevelFilterId,
    start,
    end,
    dimensions,
    metrics,
    path,
    loading,
    loadingMore,
    error,
    variables,
    totals,
    fetchMoreData,
    sort,
    limit,
    page,
    reportSelected,
    assignmentId,
    questionId,
    standardId,
    studentId,
    subjectId,
    schoolId,
    classroomId,
    teacherId,
    canFetchSummaryStats
  } = useContext(ReportsContext);

  const isMultiDimensional = dimensions.length > 1;

  const {
    isLoading: isSummaryStatsFeatureFlagLoading,
    isFeatureEnabled: isSummaryStatsFeatureFlagEnabled
  } = useIsFeatureFlagEnabled('reports_summary_stats');

  const {
    hasProUpgrade,
    fetchingProUpgrade,
    error: fetchingProUpgradeError
  } = useHasProUpgrade(topLevelFilterType, topLevelFilterId);

  const isReportPreview = useMemo(() => {
    return (
      !hasProUpgrade &&
      IS_REPORTS_BLOCKED_FOR_NON_PRO_ADMINS(topLevelFilterType, reportSelected, {
        assignmentId,
        questionId,
        standardId,
        studentId,
        subjectId,
        schoolId,
        classroomId,
        teacherId
      })
    );
  }, [
    hasProUpgrade,
    topLevelFilterType,
    reportSelected,
    assignmentId,
    questionId,
    standardId,
    studentId,
    subjectId,
    schoolId,
    classroomId,
    teacherId
  ]);

  useEffect(() => {
    if (fetchingProUpgradeError) {
      addToast({
        title: 'Error fetching pro upgrade status',
        color: 'negative',
        message: `Error message: ${fetchingProUpgradeError}.\nPlease try again`
      });
    }
  }, [fetchingProUpgradeError]);

  const hasAppliedDateRange = start && end;
  const shouldSkipRendering = !topLevelFilterId || !dimensions.length;

  if (shouldSkipRendering) {
    return null;
  }

  let linkToOldReport;
  if (
    topLevelFilterType !== 'classrooms' ||
    !path.includes('assignments') ||
    !variables.assignmentFilter?.values?.[0]
  ) {
    linkToOldReport = '';
  } else if (path[path.length - 1] === 'students') {
    linkToOldReport = `/classes/${topLevelFilterId}/assignments/${variables.assignmentFilter?.values[0]}/student-detail`;
  } else if (path[path.length - 1] === 'questions' && !variables.studentFilter?.values?.[0]) {
    linkToOldReport = `/classes/${topLevelFilterId}/assignments/${variables.assignmentFilter?.values[0]}/question-detail`;
  } else if (path[path.length - 1] === 'questions' && variables.studentFilter?.values?.[0]) {
    linkToOldReport = `/classes/${variables.classroomFilter?.values?.[0]}/assignments/${variables.assignmentFilter?.values[0]}/student-detail/${variables.studentFilter?.values[0]}`;
  }
  if (path[path.length - 3] === 'classrooms' && path[path.length - 1] === 'students') {
    // ability to go back to old insights report only when its viewing all students (equivalent to insight report)
    linkToOldReport = `/classes/${topLevelFilterId}/insights`;
  }

  if (isSummaryStatsFeatureFlagLoading || fetchingProUpgrade) {
    return <LoadingSpinner size={2} />;
  }

  return (
    <>
      <ReportToolbar
        linkToOldReport={linkToOldReport}
        hasProUpgrade={hasProUpgrade}
        isReportPreview={isReportPreview}
      />

      {isSummaryStatsFeatureFlagEnabled && canFetchSummaryStats() && <SummaryStats />}

      {/* gradebook loading is internal so only show if not multidimensional */}
      {!isReportPreview && hasAppliedDateRange && (loading || !data) && !isMultiDimensional && (
        <LoadingSpinnerLong size={2} className='u-pad-t_4' />
      )}

      {!shouldSkipRendering && hasAppliedDateRange && (
        <div
          className={classNames('', {
            'reports-table u-pad-b_4': !isReportPreview // if we're showing a report preview, don't add the padding
          })}
        >
          {isReportPreview && <ReportPreview report={reportSelected} />}

          {!isReportPreview && isMultiDimensional && topLevelFilterType === 'classrooms' && (
            <GradebookTable
              data={data}
              metricColumns={metrics}
              dimensions={dimensions}
              variables={variables}
              loading={loading}
              loadingMore={loadingMore}
              loadMore={() => {
                fetchMoreData(null);
              }}
              totals={totals}
              sorts={sort}
              limit={limit}
              page={page}
              topLevelFilterType={topLevelFilterType}
            />
          )}

          {!isReportPreview && isMultiDimensional && topLevelFilterType !== 'classrooms' && (
            <div className='u-display_flex u-justify-content_center'>
              <NoDataMsg.Container centered>
                <NoDataMsg.Heading>Select an individual classroom</NoDataMsg.Heading>
                <NoDataMsg.Body>
                  This report is only supported for classrooms. Select a specific class to use this
                  report.
                </NoDataMsg.Body>
              </NoDataMsg.Container>
            </div>
          )}

          {!isReportPreview && !isMultiDimensional && data && !loading && (
            <ReportsTable
              loading={loading}
              data={data}
              dimension={dimensions[0]}
              metrics={metrics}
            />
          )}

          {error ? 'Error occured!' : null}
        </div>
      )}
    </>
  );
}

export default Report;
