/**
 * # Logo
 *
 * React component that renders a logo based on the specified type.
 *
 * ## Props
 * - `type` (optional): Specifies the type of logo to render. Can be one of 'mark', 'text', or 'combined'. Defaults to 'combined'.
 * - `light` (optional): Specifies whether the logo should be rendered in light mode. Defaults to false.
 * - `width` (optional): Specifies the width of the logo. Defaults to '159px'.
 *
 * ## Usage
 *
 * ```tsx
 * import Logo from './Logo.react';
 *
 * const MyComponent = () => {
 *   return (
 *     <div>
 *       <Logo type="mark" light width="200px" />
 *     </div>
 *   );
 * };
 * ```
 */

import React from 'react';

import LogoText from './assets/LogoType.react';
import LogoCombined from './assets/LogoCombined.react';
import LogoMark from './assets/LogoMark.react';

import './logo.scss';

interface Props {
  type?: 'mark' | 'text' | 'combined';
  light?: boolean;
  width?: string;
  height?: string;
}

export const Logo = ({
  type = 'combined',
  light = false,
  width = '159px',
  height = 'auto',
  ...rest
}: Props) => {
  let html;
  if (type === 'mark') {
    html = <LogoMark />;
  }
  if (type === 'text') {
    html = <LogoText />;
  }
  if (type === 'combined') {
    html = <LogoCombined />;
  }
  return (
    <div
      className={`a-logo a-logo--${type} ${light ? 'a-logo--light' : ''}`}
      style={{width, height}}
      {...rest}
    >
      {html}
    </div>
  );
};

export default Logo;
