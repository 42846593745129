import React from 'react';
import {getStoreByName} from 'client/framework';
import sessionStore from 'client/Session/SessionStore';
import IEContentWrapper from 'client/InteractionEngineV2/shared/IEContentWrapper/IEContentWrapper.react';
import ActiveQuestion from 'client/InteractionEngineV2/shared/ActiveQuestion/ActiveQuestion.react';
import interactionEngineStore from 'client/InteractionEngineV2/InteractionEngineStore';
import AssignmentInitializer from '../AssignmentInitializer/AssignmentInitializer.react';
import AssignmentNavigation from '../AssignmentNavigation/AssignmentNavigation.react';
import Button from 'sg/Button/Button.react';
import teacherAssignmentTemplateQuestionsListStore from './TeacherAssignmentTemplateQuestionsList/TeacherAssignmentTemplateQuestionsList.store';
import TeacherAssignmentTemplateQuestionsList from './TeacherAssignmentTemplateQuestionsList/TeacherAssignmentTemplateQuestionsList.react';
import {TemplateAssignmentStage} from 'client/Assignments/AssignmentStage/TemplateAssignmentStage/TemplateAssignmentStage.react';
import {handleSaveToTemplates} from 'client/Assignments/AssignmentStage/TemplateAssignmentStage/assignmentTemplate.utils';

export default class TeacherAssignmentTemplateIE extends React.Component {
  getAssignmentActions() {
    if (!teacherAssignmentTemplateQuestionsListStore.activeQuestion) {
      return null;
    }
    return (
      <div className='interaction-engine-v2__actions'>
        <Button onClick={handleSaveToTemplates}>Save to my folders</Button>
      </div>
    );
  }

  getQuestionContent() {
    if (!teacherAssignmentTemplateQuestionsListStore.activeQuestion) {
      return <TemplateAssignmentStage />;
    }

    const activeQuestionId = teacherAssignmentTemplateQuestionsListStore.activeQuestion.getId();
    const questionStoreName = interactionEngineStore.getStoreNameForQuestionId(activeQuestionId);
    const questionStore = getStoreByName(questionStoreName);
    const showPrevButton = teacherAssignmentTemplateQuestionsListStore.hasPreviousQuestion(
      activeQuestionId
    );
    const showNextButton = teacherAssignmentTemplateQuestionsListStore.hasNextQuestion(
      activeQuestionId
    );

    return (
      <ActiveQuestion
        hideSubmitButton
        assignmentId={teacherAssignmentTemplateQuestionsListStore.getTemplateId()}
        question={teacherAssignmentTemplateQuestionsListStore.activeQuestion}
        questionSet={teacherAssignmentTemplateQuestionsListStore.activeQuestionSet}
        questionStoreName={questionStoreName}
        showPrevButton={showPrevButton}
        showNextButton={showNextButton}
        shouldShowExplanation={questionStore && questionStore.shouldShowExplanation()}
        shouldShowExplanationAfterGuessIsSubmitted={true}
        isAssignmentOverdue={false}
      />
    );
  }

  render() {
    return (
      <AssignmentInitializer
        assignmentStore={teacherAssignmentTemplateQuestionsListStore}
        isLoggedIn={sessionStore.hasValidSession()}
        getChildren={() => {
          return (
            <IEContentWrapper
              subheader={
                <AssignmentNavigation
                  assignmentStore={teacherAssignmentTemplateQuestionsListStore}
                  assignmentActions={this.getAssignmentActions()}
                />
              }
              questionsList={<TeacherAssignmentTemplateQuestionsList />}
              questionArea={this.getQuestionContent()}
              statsArea={null}
            />
          );
        }}
      />
    );
  }
}
