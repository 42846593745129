import React, {useState, createRef} from 'react';
import PropTypes from 'prop-types';
import {getModelForResourceType} from 'resources/modelRegistry';

import {
  WithToggle,
  Popover,
  WithTooltip,
  copyToClipboard,
  addToast,
  Text,
  Anchor,
  Dropdown,
  DropdownItem,
  ListGroupItem,
  Button,
  Icon
} from '@albert-io/atomic';
import {
  getCurrentSort,
  clearSortValue
} from 'components/PracticeView/PracticeViewToolbar/Sort/sort.utils';
import sessionStore from 'client/Session/SessionStore';
import PracticeViewToolbar from 'components/PracticeView/PracticeViewToolbar/PracticeViewToolbar.react';
import Sort from 'components/PracticeView/PracticeViewToolbar/Sort/Sort.react';
import Filters from 'components/PracticeView/PracticeViewToolbar/Filters/Filters.react';
import {hasActiveFilter} from 'components/PracticeView/PracticeViewToolbar/Filters/filters.utils';
import './subject-practice-view-toolbar.scss';
import AssignModal, {SmartAssignTooltip} from 'components/AssignModal/AssignModal.react';
import {useTracking} from 'react-tracking';

const SubjectPracticeViewToolbar = ({
  guideLevel,
  location,
  selectedQuestionCount,
  clearQuestionSetSelections,
  handleSelectAssign,
  toggleFoldersModal,
  canAssignGuide,
  isEmptyPracticeView,
  toggleRetailAssessmentModal,
  isLoading
}) => {
  const {trackEvent} = useTracking({
    event: 'click',
    feature: 'Subject Practice View'
  });

  const assignButtonRef = createRef();

  const [smartAssignModal, setSmartAssignModal] = useState(false);
  const hasSelectedQuestions = selectedQuestionCount > 0;
  const isSorting = !!location.query.sort;
  const isFiltering = hasActiveFilter();
  const isSearching = !!location.query.standards || !!location.query.tags;
  const showSubtext = isFiltering || isSearching;

  const handleSmartAssign = () => {
    const questionCount = guideLevel.getMeta().getCountOfQuestions();
    const minSizeQuestionSet = guideLevel.getMeta().getMinSizeQuestionSet();

    if (questionCount === 1) {
      handleSelectAssign();
    } else if (questionCount === minSizeQuestionSet) {
      handleSelectAssign();
    } else {
      setSmartAssignModal(true);
    }
  };

  const shouldShowAssign = !sessionStore.isStudent();
  const getDefaultAssignDropdown = () => {
    return canAssignGuide ? (
      <Dropdown
        trigger={
          <Button
            disabled={isEmptyPracticeView}
            size='s'
            className='u-mar-r_1'
            onClick={() => {
              trackEvent({
                content: 'assign',
                component: 'toolbar',
                type: 'dropdown'
              });
            }}
          >
            <Text bold size='s' color='inherit'>
              Assign
            </Text>
            <Icon icon='caret-down' className='u-mar-l_1' />
          </Button>
        }
      >
        <DropdownItem
          as='button'
          onClick={() => {
            handleSelectAssign();
            trackEvent({
              content: 'Assign all',
              component: 'Assign dropdown',
              type: 'dropdown item'
            });
          }}
        >
          Assign all
        </DropdownItem>
        <DropdownItem
          subtext={showSubtext && 'Any custom selections or filters will be ignored.'}
          as='button'
          onClick={() => {
            trackEvent({
              content: 'Smart assign',
              component: 'Assign dropdown',
              type: 'dropdown item'
            });
            if (!guideLevel) {
              /* No guide level means we are in an Individual question view and should bypass the modal  */
              handleSelectAssign();
            } else {
              handleSmartAssign();
            }
          }}
        >
          <SmartAssignTooltip leftContent='Smart assign' />
        </DropdownItem>
      </Dropdown>
    ) : (
      <WithToggle>
        {({on, onClick: togglePopover, onBlur, onMouseEnter, onMouseLeave}) => (
          <span
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onBlur={onBlur}
            style={{display: 'inline-block'}}
            ref={assignButtonRef}
          >
            <Button color='secondary' onClick={togglePopover} size='s' className='u-mar-r_1'>
              Assign
              <Icon icon='caret-down' className='u-mar-l_1' />
            </Button>
            {on && (
              <Popover
                aria-live='assertive'
                usePortal={false}
                border='none'
                expanded={on}
                targetRef={assignButtonRef}
                shadow='far'
                className='spv-toolbar__assign-popover'
              >
                <>
                  <Text color='secondary' size='m'>
                    Only licensed, approved teachers can assign the entire set of questions.
                  </Text>
                  <br />
                  <br />
                  <Text color='secondary' size='m'>
                    Email{' '}
                    <Anchor href='mailto:hello@albert.io?subject=Inquiry for Albert license'>
                      hello@albert.io
                    </Anchor>{' '}
                    if you would like to obtain an Albert license.
                  </Text>
                </>
              </Popover>
            )}
          </span>
        )}
      </WithToggle>
    );
  };

  return !hasSelectedQuestions ? (
    <PracticeViewToolbar>
      {shouldShowAssign && getDefaultAssignDropdown()}
      <Sort
        additionalDropdownItems={
          <>
            <ListGroupItem
              as={DropdownItem}
              onClick={clearSortValue}
              data-testid='practice-view-toolbar-sort__default-btn'
            >
              <Text bold color={getCurrentSort() === undefined ? 'brand' : 'secondary'}>
                Default
              </Text>
            </ListGroupItem>
          </>
        }
        active={isSorting}
      />
      <WithTooltip
        enabled={hasSelectedQuestions}
        content='Filters cannot be applied while you have questions selected.'
        wrapperProps={{
          className: 'questions-list-header__filters-wrapper'
        }}
      >
        <Filters active={isFiltering} disabled={hasSelectedQuestions} isLoading={isLoading} />
      </WithTooltip>
      {copyToClipboard.isSupported() && (
        <Button
          color='secondary'
          variant='text'
          key='share'
          role='button'
          size='s'
          className='u-mar-r_1'
          onClick={() => {
            copyToClipboard(global.location.href, () => {
              addToast({
                color: 'positive',
                title: 'Link copied!',
                message: 'Share this link with teachers and students.'
              });
            });
          }}
          data-testid='practice-view-toolbar-share__btn'
        >
          <Icon icon='link' className='u-mar-r_1' />
          <Text bold size='s'>
            Share
          </Text>
        </Button>
      )}
      {smartAssignModal && (
        <AssignModal
          resource={guideLevel}
          type='guide'
          onClose={() => setSmartAssignModal(false)}
        />
      )}
      {!!toggleRetailAssessmentModal && (
        <Button
          size='s'
          color='secondary'
          variant='text'
          key='show tips'
          role='button'
          onClick={toggleRetailAssessmentModal}
        >
          <Icon className='u-mar-r_1' icon='lightbulb-on' />
          <Text bold size='s'>
            Tips
          </Text>
        </Button>
      )}
    </PracticeViewToolbar>
  ) : (
    <PracticeViewToolbar>
      <Button size='s' onClick={clearQuestionSetSelections} className='u-mar-r_1'>
        Clear selection
      </Button>
      <Dropdown
        trigger={
          <Button
            size='s'
            className='u-mar-r_1'
            onClick={() => {
              trackEvent({
                content: 'assign',
                component: 'toolbar',
                type: 'dropdown'
              });
            }}
          >
            Assign
            <Icon icon='caret-down' className='u-mar-l_1' />
          </Button>
        }
      >
        <DropdownItem
          as='button'
          onClick={() => {
            trackEvent({
              content: 'assign your selection',
              component: 'Assign dropdown',
              type: 'dropdown item'
            });
            handleSelectAssign();
          }}
        >
          Assign your selection ({selectedQuestionCount})
        </DropdownItem>
        {canAssignGuide && (
          <DropdownItem
            subtext='Any custom selections or filters will be ignored.'
            as='button'
            onClick={() => {
              trackEvent({
                content: 'Smart assign',
                component: 'Assign dropdown',
                type: 'dropdown item'
              });
              if (!guideLevel) {
                handleSelectAssign();
              } else {
                handleSmartAssign();
              }
            }}
          >
            <SmartAssignTooltip leftContent='Smart assign' />
          </DropdownItem>
        )}
      </Dropdown>

      <Button
        size='s'
        color='secondary'
        variant='text'
        onClick={toggleFoldersModal}
        className='u-mar-r_1'
      >
        <Text bold size='s'>
          Add to folder
        </Text>
      </Button>
      {smartAssignModal && (
        <AssignModal
          resource={guideLevel}
          type='guide'
          onClose={() => setSmartAssignModal(false)}
        />
      )}
    </PracticeViewToolbar>
  );
};

SubjectPracticeViewToolbar.propTypes = {
  location: PropTypes.object,
  selectedQuestionCount: PropTypes.number,
  canAssignGuide: PropTypes.bool,
  clearQuestionSetSelections: PropTypes.func,
  handleSelectAssign: PropTypes.func,
  toggleFoldersModal: PropTypes.func,
  guideLevel: PropTypes.instanceOf(getModelForResourceType('guide_level_v2')),
  isEmptyPracticeView: PropTypes.bool,
  toggleRetailAssessmentModal: PropTypes.func,
  isLoading: PropTypes.bool
};

export default SubjectPracticeViewToolbar;
