import * as React from 'react';
import PropTypes from 'prop-types';

const ArrayToReadableFragment = ({arr = [], as: WrapperElement = 'span', conjunction = 'and'}) => {
  if (arr.length === 0) {
    return '';
  }

  if (arr.length === 1) {
    return <WrapperElement>{arr[0]}</WrapperElement>;
  }
  return (
    <>
      <WrapperElement>{arr.slice(0, -1).join(', ')} </WrapperElement>
      {conjunction}
      <WrapperElement> {arr[arr.length - 1]}</WrapperElement>
    </>
  );
};

ArrayToReadableFragment.propTypes = {
  arr: PropTypes.array,
  as: PropTypes.string,
  conjunction: PropTypes.string
};

export default ArrayToReadableFragment;
