import {useState, useCallback} from 'react';
/*
 https://legacy.reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
 */
function useClientRect() {
  const [rect, setRect] = useState<DOMRect | null>(null);
  const ref = useCallback((node: HTMLHeadingElement | null) => {
    if (node !== null) {
      setRect(node.getBoundingClientRect());
    }
  }, []);
  return [rect, ref] as const;
}

export default useClientRect;
