import React, {useState, useEffect} from 'react';
import validator from 'validator';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import classnames from 'classnames';
import {useTracking} from 'react-tracking';

import {login} from '@albert-io/mandark/authentication';

import {Button, Card, Form, Heading, TextField, Text, Anchor, Banner} from '@albert-io/atomic';

import FormDivider from 'client/components/Forms/FormDivider/FormDivider';

import errorCodes from 'client/errorCodes';
import GoogleButton from 'client/SignUp/SignUpForm/SocialButton/Google/GoogleButton.react';
import CleverButton from 'client/SignUp/SignUpForm/SocialButton/Clever/CleverButton.react';

import './log-in-form.scss';

const LOG_IN_FAILURE =
  'The credentials you entered did not match our records. Please double-check and try again.';
const LOG_IN_FAILURE_INACTIVE =
  'Your account has been deactivated. Please contact hello@albert.io if you have any further questions.';
const LOG_IN_FAILURE_EXCEPTION = 'An error occurred during sign in. Please try again later.';

const LogInForm = ({onAuthentication, className, heading = 'Log In', withCloseButtonWrapper}) => {
  const [identifier, setIdentifier] = useState('');
  const [password, setPassword] = useState('');
  const [isRequestInFlight, setRequestInFlight] = useState(false);
  const [validation, setValidation] = useState({
    identifier: null,
    password: null
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const {trackEvent} = useTracking();
  /**
   * We need to use an `isLoading` property in state and create a
   * hook that resembles a `componentDidMount` to ensure the HTML
   * `<form>` action cannot be triggered during the initial SSR
   * load (before the event handler is hydrated).
   */
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(false);
  }, []);

  function validate(property, value) {
    function isValid(v) {
      return v.length >= 1;
    }

    if (property) {
      setValidation((state) => ({
        ...state,
        [property]: isValid(value)
      }));
    } else {
      setValidation({
        identifier: isValid(identifier),
        password: isValid(password)
      });
    }
  }

  function handleChange(e) {
    const {name, value} = e.target;
    if (name === 'identifier') setIdentifier(value);
    if (name === 'password') setPassword(value);
    validate(name, value);
  }

  async function handleSubmit(e) {
    e.preventDefault();

    setErrorMessage(null);

    validate();

    if (!Object.values(validation).every((v) => v === true)) {
      return;
    }

    setRequestInFlight(true);

    const payload = validator.isEmail(identifier)
      ? {
          email: identifier,
          password
        }
      : {
          username: identifier,
          password
        };

    let authenticated = false;
    try {
      await login(payload);

      trackEvent({event: 'login', method: 'Email'});

      authenticated = true;
    } catch (err) {
      if (err.status !== 401) {
        setErrorMessage(LOG_IN_FAILURE_EXCEPTION);
      } else if (err.status === 401) {
        if (err.response.body.errors[0].code === errorCodes.ERRORS.INACTIVE_ACCOUNT_UNAUTHORIZED) {
          setErrorMessage(LOG_IN_FAILURE_INACTIVE);
        } else {
          setErrorMessage(LOG_IN_FAILURE);
        }
      }
    } finally {
      setRequestInFlight(false);
      if (!authenticated) {
        setErrorMessage(LOG_IN_FAILURE);
      } else if (onAuthentication) {
        onAuthentication();
      }
    }
  }

  /**
   * If the `withCloseButtonWrapper` prop is provided we wrap navigation actions
   * with it to ensure proper state clean up; This used to support the
   * closing of the parent `<Modal> when navigation occurs.
   */
  const NavigationActionWrapper = withCloseButtonWrapper || React.Fragment;

  return (
    <Card paddingLevel={4} shadow='default' className={classnames('log-in-form', className)}>
      <div className='u-text-align_center u-pad-b_2'>
        <Heading>{heading}</Heading>
      </div>

      <div className='log-in-form-sso'>
        <div className='log-in-form-sso__button'>
          <GoogleButton disabled={isLoading || isRequestInFlight} />
        </div>
        <div className='log-in-form-sso__button'>
          <CleverButton disabled={isLoading || isRequestInFlight} />
        </div>
      </div>

      <FormDivider>
        <Text color='secondary'>Or log in with email</Text>
      </FormDivider>

      {errorMessage && <Banner type='negative'>{errorMessage}</Banner>}

      <Form data-testid='log-in--form' onSubmit={handleSubmit}>
        <TextField
          border
          name='identifier'
          label='Username or email'
          autoComplete='username'
          required
          defaultValue={identifier}
          error={validation.identifier === false}
          message={
            validation.identifier === false ? 'Please provide your username or email.' : null
          }
          onChange={handleChange}
          className='u-pad-t_2'
          data-testid='log-in--identifier'
        />
        <TextField
          border
          name='password'
          label='Password'
          type='password'
          autoComplete='current-password'
          required
          defaultValue={password}
          error={validation.password === false}
          message={validation.password === false ? 'A password is required.' : null}
          onChange={handleChange}
          className='u-pad-t_2 u-pad-b_2'
          data-testid='log-in--password'
        />
        <Button type='submit' className='u-width_100pc' disabled={isLoading || isRequestInFlight}>
          Log in
        </Button>
      </Form>

      <div className='u-display_flex u-justify-content_center u-pad_2'>
        <NavigationActionWrapper>
          <Anchor as={Link} to='/forgot-password' underlined>
            Forgot Password?
          </Anchor>
        </NavigationActionWrapper>
      </div>

      <FormDivider>
        <Text color='secondary'>Don&apos;t have an account?</Text>
      </FormDivider>
      <NavigationActionWrapper>
        <Button color='secondary' as={Link} to='/signup' className='u-width_100pc'>
          Sign up
        </Button>
      </NavigationActionWrapper>
    </Card>
  );
};

LogInForm.propTypes = {
  withCloseButtonWrapper: PropTypes.func,
  onAuthentication: PropTypes.func,
  heading: PropTypes.string,
  className: PropTypes.string
};

export default LogInForm;
