import React from 'react';
import moment from 'moment';
import {isEqual} from 'lodash';
import {Button, Icon, Text, WithTooltip} from '@albert-io/atomic';

import {AnalyticsApiResult, Dimension} from 'client/Reports/reports.types';
import {Link} from 'react-router';
import {getStudentAssignment} from 'client/Reports/reports.utils';

interface Props {
  result: AnalyticsApiResult;
  dimensions: Dimension[];
}

const getClassroomId = (result: AnalyticsApiResult, dimensions: Dimension[]) => {
  if (isEqual(dimensions, ['assignments'])) {
    return result.assignment.classroom_assignments[0].classroom_id;
  }

  if (isEqual(dimensions, ['students']) || isEqual(dimensions, ['students', 'assignments'])) {
    const studentAssignment = getStudentAssignment(result);

    return studentAssignment.classroom_assignments[0].classroom_id;
  }

  return undefined;
};

const TooltipContent = ({result, dimensions}: Props) => {
  const studentAssignment = getStudentAssignment(result);
  const [submitted, assignmentId, studentId] = [
    studentAssignment.submitted,
    studentAssignment.assignment_id,
    studentAssignment.student_id
  ];

  const classroomId = getClassroomId(result, dimensions);

  if (!classroomId) {
    return null;
  }

  return (
    <div
      className='u-display_flex u-align-items_center u-flex-direction_column u-pad_1'
      style={{minWidth: 250}}
    >
      <Text size='s'>
        {submitted
          ? `Submitted ${moment(submitted).format('L')} @ ${moment(submitted).format('LT')}`
          : 'Not Submitted'}
      </Text>
      <Button
        to={`/classes/${classroomId}/assignments/${assignmentId}/student-detail/${studentId}/view`}
        variant='outlined'
        className='u-mar-t_1 u-width_100pc'
        as={Link}
        target='_blank'
        onClick={(e) => e.stopPropagation()}
      >
        View Submission
      </Button>
    </div>
  );
};

const AssignmentSubmitted = ({result, dimensions}: Props) => {
  const studentAssignment = getStudentAssignment(result);
  const {submitted} = studentAssignment;

  const dueDate =
    studentAssignment?.settings?.due_date ||
    studentAssignment?.classroom_assignments?.[0]?.settings?.due_date;

  if (submitted > dueDate) {
    return (
      <WithTooltip
        content={<TooltipContent result={result} dimensions={dimensions} />}
        theme='light'
        placement='right'
      >
        <div className='u-display_flex u-align-items_center reports_submission-badge u-bgc_yellow-050 u-color_yellow-700 u-border-radius_pill'>
          <span className='u-mar-r_1'>Late</span>
          <Icon icon='info-circle' iconStyle='regular' />
        </div>
      </WithTooltip>
    );
  }

  if (submitted) {
    return (
      <WithTooltip
        content={<TooltipContent result={result} dimensions={dimensions} />}
        theme='light'
        placement='right'
      >
        <div className='u-display_flex u-align-items_center reports_submission-badge u-bgc_green-050 u-color_green-800 u-border-radius_pill'>
          <span className='u-mar-r_1'>Submitted</span>
          <Icon icon='info-circle' iconStyle='regular' />
        </div>
      </WithTooltip>
    );
  }

  return (
    <WithTooltip
      content={<TooltipContent result={result} dimensions={dimensions} />}
      theme='light'
      placement='right'
    >
      <div className='reports_submission-badge u-bgc_white u-color_slate-500 u-border-radius_pill'>
        <span className='u-mar-r_1'>Incomplete</span>
      </div>
    </WithTooltip>
  );
};

export default AssignmentSubmitted;
