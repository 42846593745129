import {Map} from 'immutable';

import Image from './Image.react';

export default Map({
  renderer: Image,
  type: 'image',
  label: 'Image',
  hidePreviewToggle: true
});
