import React from 'react';
import {Set} from 'immutable';
import {get} from 'lodash';
import qs from 'qs';
import {Link} from 'react-router';

import {invalidatePartialInterest, genericMandarkRequest} from 'resources/mandark.resource';
import {resource, query} from '@albert-io/json-api-framework/request/builder';
import {pluralize} from 'lib/stringUtils';
import {Anchor, addToast} from '@albert-io/atomic';

/**
 * @todo: We're invalidating both templates_v1 and assignments_v2 because the legacy <TeacherAssignmentIE /> (to be
 * replaced by the folder practice view) uses assignments_v2 to bootstrap itself. Once the folder practice view is out,
 * we can remove that invalidation.
 */
function invalidateTemplatesRequests() {
  invalidatePartialInterest(resource('templates_v1').mandarkEndpoint(['templates_v1']).done());
  invalidatePartialInterest(resource('assignments_v2').mandarkEndpoint(['assignments_v2']).done());
}

/**
 * This all question sets to a template based on a question sets query's filters and sorts
 *
 * @param {Object} arg
 * @param {Set<string>} arg.folderIds Folders we'll be adding questions to
 * @param {string[]} arg.selectedQuestionSetIds Quesiton sets we'll be ecluding from the add all operation
 * @param {*} arg.questionSetsQuery Query that returned the current list of question sets
 * @param {} arg.closeModal Callback to close the modal
 * @param arg.folders
 * @param arg.selectedQuestionCount
 * @param arg.folderBeingCopiedId
 */
export async function addQuestionSetsToFoldersByQuery({
  folderIds,
  folders,
  selectedQuestionSetIds,
  questionSetsQuery,
  closeModal,
  selectedQuestionCount,
  folderBeingCopiedId
}) {
  const promiseArr = [...folderIds].map((folderId) => {
    const builtQuestionSetsQuery = questionSetsQuery.done();
    const queryContext = get(builtQuestionSetsQuery, 'customQuery.meta.context');
    const queryContextAsString = queryContext ? qs.stringify({meta: {context: queryContext}}) : '';

    // We need to pass the filter part of the built endpoint as a string to the filter_query, so
    // we can't depend on mandarkEndpoint to parse this correctly.
    let filter = questionSetsQuery
      .filter({questions_v3: {status: 'published'}})
      .buildEndpoint()
      .split(/[?&]/)
      .filter((param) => param.startsWith('filter['))
      .join('&');

    if (folderBeingCopiedId) {
      filter = `filter[templates_v1]=${folderBeingCopiedId}`;
    }

    if (filter && queryContext) {
      filter = [filter, queryContextAsString].join('&');
    }

    let sort = get(builtQuestionSetsQuery, 'customQuery.sort');

    let sortContextAsString;
    if (queryContext.guide_level) {
      sortContextAsString = qs.stringify({
        meta: {context: {guide_level: queryContext.guide_level}}
      });
    } else if (queryContext.template) {
      sortContextAsString = qs.stringify({
        meta: {context: {template: queryContext.template}}
      });
    } else if (queryContext.assignment) {
      sortContextAsString = qs.stringify({
        meta: {context: {assignment: queryContext.assignment}}
      });
    }

    if (sort && queryContext) {
      sort = [`sort=${sort}`, sortContextAsString].join('&');
    }

    const addQuestionSetsQuery = resource('template_v1')
      .mandarkEndpoint(['templates_v1', folderId, 'add_question_sets_to_template'])
      .customQuery(
        {
          filter_query: encodeURIComponent(filter)
        },
        filter
      )
      .customQuery(
        {
          sort_query: encodeURIComponent(sort)
        },
        sort
      )
      .customQuery(
        {
          excluded_ids: selectedQuestionSetIds.join(',')
        },
        selectedQuestionSetIds.length
      )
      .done();
    return genericMandarkRequest('patch', addQuestionSetsQuery);
  });
  try {
    await Promise.all(promiseArr);
    invalidateTemplatesRequests();
    const selectedFolders = [...folderIds].map((id) => folders.asMap().get(id));
    makeSuccessToast(selectedFolders, selectedQuestionCount);
  } catch (e) {
    makeErrorToast();
  }
  closeModal();
}

/**
 * Loops through selected folder IDs and appends the newly selected question sets to each relationship
 * Also sends back a list of folder objects to the subjectPracticeView when closing modal
 *
 * @param {Object} arg
 * @param {Set<string>} arg.folderIds Folders we'll be adding questions to
 * @param {*} arg.folders Immutable.List of TemplateModelV1 instances
 * @param {string[]} arg.selectedQuestionSetIds Array of question set id's we'll be adding to the folders
 * @param {*} arg.closeModal Callback to close the modal
 * @param arg.selectedQuestionCount
 */
export async function addQuestionSetsToFoldersByQuestionSetIds({
  folderIds,
  folders,
  selectedQuestionSetIds,
  closeModal,
  selectedQuestionCount
}) {
  const selectedFolders = [...folderIds].map((id) => folders.asMap().get(id));
  try {
    await Promise.all(
      selectedFolders.map((folder) => {
        const existingQuestionSetIds = folder.getQuestionSets().map((set) => set.getId());
        const data = selectedQuestionSetIds.reduce((acc, id, index) => {
          /**
           * If the set already exists in the folder, we will not make a request.
           * If we _did_ this would cause the position of the existing question to change.
           */
          if (existingQuestionSetIds.includes(id)) {
            return acc;
          }
          return acc.add({
            id,
            type: 'question_sets_v1',
            meta: {
              /**
               * New question sets are _always_ added to the end of the folder.
               */
              position: folder.getMeta().getCountOfQuestions() + index
            }
          });
        }, Set([]));

        if (data.isEmpty()) {
          /**
           * If we don't have question sets to add, just resolve.
           */
          return Promise.resolve();
        }
        return genericMandarkRequest(
          'post',
          query()
            .mandarkEndpoint(['templates_v1', folder.getId(), 'relationships', 'question_sets_v1'])
            .done(),
          {
            data: data.toJS()
          }
        );
      })
    );
    invalidateTemplatesRequests();
    makeSuccessToast(selectedFolders, selectedQuestionCount);
  } catch (e) {
    makeErrorToast();
  }
  closeModal();
}

function makeErrorToast() {
  addToast({
    color: 'negative',
    title: 'Something went wrong',
    message: 'We were unable to save your questions to a folder'
  });
}

/**
 * Correctly formats the success toast message
 *
 * @param  {Map} selectedFolders
 * @param  {number} selectedQuestionCount
 */
function makeSuccessToast(selectedFolders, selectedQuestionCount) {
  const firstFolder = selectedFolders[0];
  const firstFolderHrefString = `/folder/${firstFolder.getId()}`;
  const firstFolderLink = (
    <Anchor as={Link} underlined inheritColor to={firstFolderHrefString}>
      {firstFolder.getName()}
    </Anchor>
  );
  const messageFirstSection = `${selectedQuestionCount} ${pluralize(
    'question',
    selectedQuestionCount
  )} added to`;
  let message;
  if (selectedFolders.length === 1) {
    message = (
      <span>
        {messageFirstSection} {firstFolderLink}
      </span>
    );
  } else if (selectedFolders.length === 2) {
    const secondFolder = selectedFolders[1];
    const secondFolderHrefString = `/folder/${secondFolder.getId()}`;
    const secondFolderLink = (
      <Anchor as={Link} underlined inheritColor to={secondFolderHrefString}>
        {secondFolder.getName()}
      </Anchor>
    );
    message = (
      <span>
        {messageFirstSection} {firstFolderLink} and {secondFolderLink}
      </span>
    );
  } else {
    const savedFoldersLink = (
      <Anchor as={Link} underlined inheritColor to='/saved'>
        others
      </Anchor>
    );
    message = (
      <span>
        {messageFirstSection} {firstFolderLink} and {selectedFolders.length - 1} {savedFoldersLink}
      </span>
    );
  }

  addToast({
    color: 'positive',
    title: 'Success!',
    message
  });
}
