import React, {useCallback, useEffect, useState} from 'react';
import {Anchor, Button, Heading, Icon, ListGroup, addToast} from '@albert-io/atomic';
import {AssignmentModelV3} from '@albert-io/models';
import {history} from 'client/history';

import './global-assignments.scss';
import {useIsFeatureFlagEnabled} from 'client/components/FeatureFlag/FeatureFlag.react';

import {invalidatePartialInterest} from 'resources/mandark.resource';

import SearchFilter, {FilterObj} from './SearchFilter.react';
import AssignmentFilters from './AssignmentsFilters.react';
import AssignmentCard from './AssignmentCard.react';
import {getGlobalAssignmentsQuery} from './GlobalAssignments.queries';

const GlobalAssignments = () => {
  const [assignmentsList, setAssignmentsList] = useState<AssignmentModelV3[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>('');
  const [filters, setFilters] = useState<FilterObj>({
    status: '',
    classes: {},
    shared: ''
  });
  const [filtersToUse, setFiltersToUse] = useState<FilterObj>({
    status: '',
    classes: {},
    shared: ''
  });
  const [filtersOpen, setFiltersOpen] = useState<boolean>(false);
  const [sortByOption, setSortByOption] = useState<string>('updated_at');
  const [assignmentsLoading, setAssignmentsLoading] = useState<boolean>(false);
  const [assignmentPage, setAssignmentPage] = useState<number>(1);
  const [totalNumPagesAssignments, setTotalNumPagesAssignments] = useState<number>(0);
  const [lengthAllAssignments, setLengthAllAssignments] = useState<number | null>(null);

  const {isFeatureEnabled} = useIsFeatureFlagEnabled('global_assignments');

  const fetchAssignments = useCallback(
    async ({pageNum} = {pageNum: 1}) => {
      try {
        if (pageNum === 1) {
          setAssignmentsLoading(true);
          setTotalNumPagesAssignments(0);
        }
        const globalAssignmentsQuery = getGlobalAssignmentsQuery({
          name: searchTerm,
          sortOption: sortByOption === 'updated_at' ? '-updated_at' : 'name',
          status: filtersToUse.status,
          classes: Object.keys(filtersToUse.classes || {}),
          page: pageNum,
          shared: filtersToUse.shared
        });
        const globalAssignments = await globalAssignmentsQuery.getResourcePromise();
        const totalNumPagesData = globalAssignmentsQuery
          .getResourceMetadata()
          .getIn(['page', 'total_pages'], 0);
        setTotalNumPagesAssignments(totalNumPagesData);
        invalidatePartialInterest({resourcePath: ['assignments_v3']});
        if (lengthAllAssignments === null) {
          setLengthAllAssignments(globalAssignments.toArray().length);
        }
        if (pageNum === 1) {
          setAssignmentsList(globalAssignments.toArray());
        } else {
          setAssignmentsList((prevAssignments) => [
            ...prevAssignments,
            ...globalAssignments.toArray()
          ]);
        }
        setAssignmentsLoading(false);
      } catch (e) {
        setAssignmentsLoading(false);
        addToast({
          color: 'negative',
          title: "Couldn't fetch assignments",
          message: 'Error fetching assignments.'
        });
      }
    },
    [
      searchTerm,
      sortByOption,
      filtersToUse.classes,
      filtersToUse.status,
      filtersToUse.shared,
      lengthAllAssignments
    ]
  );

  useEffect(() => {
    setAssignmentPage(1);
    fetchAssignments();
  }, [debouncedSearchTerm, filtersToUse, sortByOption]);

  const loadMoreAssignments = useCallback(() => {
    setAssignmentsLoading(true);
    fetchAssignments({pageNum: assignmentPage + 1});
    setAssignmentPage((prevPage) => prevPage + 1);
  }, [fetchAssignments, assignmentPage]);

  if (!isFeatureEnabled) {
    return null;
  }

  return (
    <div className='global-assignments-wrapper'>
      <div className='u-display_flex u-display_flex u-align-items_center u-padding-block_space-x5 u-padding-inline-start_space-x5 content-primary-inverse-static background-brand'>
        <Heading>Assignments</Heading>
      </div>
      <div className='global-assignments__content'>
        {lengthAllAssignments === 0 && (
          <div className='global-assignments__no-results'>
            <div className='global-assignments__no-results__header'>You have no assignments</div>
            <div>Your assignments will appear on this page</div>
            <Button
              onClick={() => history.pushState(null, `/assignment-builder/create-assignment`)}
              className='u-mar-t_4'
            >
              New Assignment
            </Button>
          </div>
        )}
        {!!lengthAllAssignments && (
          <>
            <SearchFilter
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              setFiltersOpen={setFiltersOpen}
              filtersOpen={filtersOpen}
              filters={filters}
              sortByOption={sortByOption}
              setSortByOption={setSortByOption}
            />
            {filtersOpen && (
              <AssignmentFilters
                setFilters={setFilters}
                filters={filters}
                setFiltersToUse={setFiltersToUse}
                assignmentsLoading={assignmentsLoading}
              />
            )}
            <>
              {assignmentsList.length ? (
                <ListGroup className='global-assignments__grid-container u-display_flex u-flex-direction_column u-gap_space-x1-half'>
                  {assignmentsList.map((assignment) => (
                    <AssignmentCard
                      assignment={assignment}
                      setAssignmentsList={setAssignmentsList}
                    />
                  ))}
                </ListGroup>
              ) : (
                <div className='global-assignments__no-results global-assignment__no-filtered-results'>
                  <div className='global-assignments__no-results__header'>No assignments found</div>
                  <Anchor
                    underlined
                    onClick={() => {
                      setFilters({status: '', classes: {}, shared: ''});
                      setFiltersToUse({status: '', classes: {}, shared: ''});
                      setSearchTerm('');
                      setDebouncedSearchTerm('');
                    }}
                  >
                    Clear filters
                  </Anchor>
                </div>
              )}
            </>
            {assignmentPage < totalNumPagesAssignments && (
              <div className='u-display_flex u-justify-content_center'>
                <Button
                  onClick={loadMoreAssignments}
                  className='u-mar-t_3'
                  disabled={assignmentsLoading}
                >
                  Show more
                  {assignmentsLoading && <Icon spin icon='spinner' className='u-mar-l_1' />}
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default GlobalAssignments;
