// @flow
import * as React from 'react';

export const organization = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  url: 'https://www.albert.io',
  name: 'Albert',
  alternateName: 'Albert.io',
  description:
    'Interactive learning for college math and science, Advanced Placement®, SAT®, ACT®, GRE®, GMAT®, literature, social science, history, and more.',
  logo: 'https://www.albert.io/assets/Albert.jpg',
  contactPoint: [
    {
      '@type': 'ContactPoint',
      telephone: '+1-312-470-2290x0',
      contactType: 'Customer Service',
      email: 'hello@albert.io'
    },
    {
      '@type': 'ContactPoint',
      telephone: '+1-312-470-2290x1',
      contactType: 'Sales',
      email: 'schools@albert.io'
    }
  ],
  sameAs: ['https://www.facebook.com/learnwithalbert/', 'https://twitter.com/learnwithalbert']
};

export class JSONLD extends React.PureComponent<{}> {
  render(): React.Node {
    return (
      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(organization)
        }}
      />
    );
  }
}
