import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {callTargetedAction} from 'client/framework';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import CorrectnessIndicatorWrapper from 'client/QuestionTypes/common/CorrectnessIndicatorWrapper/CorrectnessIndicatorWrapper.react';

import multipleChoiceQuestionActions from './MultipleChoiceQuestion.actions';
import {MULTIPLE_CHOICE_LETTERS} from './MultipleChoice.constants';

export default class MultipleChoiceOption extends React.Component {
  static propTypes = {
    index: PropTypes.number,
    option: ImmutablePropTypes.record,
    isCorrectAnswer: PropTypes.bool,
    isSelected: PropTypes.bool,
    targetStore: PropTypes.string,
    isGuessSubmitted: PropTypes.bool,
    isEliminated: PropTypes.bool,
    isSelectMultiple: PropTypes.bool
  };

  handleClick = () => {
    if (this.props.isGuessSubmitted || this.props.isEliminated) {
      return;
    }
    callTargetedAction({
      name: multipleChoiceQuestionActions.SELECT_OPTION,
      payload: this.props.option.getId(),
      targetStore: this.props.targetStore
    });
  };

  render() {
    const {
      option,
      index,
      isCorrectAnswer,
      isGuessSubmitted,
      isSelected,
      isEliminated,
      isSelectMultiple
    } = this.props;
    const value = option.getValue();
    const wrapperClass = classnames('mcq-option', {
      'mcq-option--selected': isSelected,
      'mcq-option--unselected': !isSelected,
      'mcq-option--pre-submission': !isGuessSubmitted,
      'mcq-option--post-submission': isGuessSubmitted,
      'mcq-option--eliminated': isEliminated,
      'mcq-option--checkbox': isSelectMultiple
    });

    return (
      <CorrectnessIndicatorWrapper
        className={wrapperClass}
        onClick={this.handleClick}
        isGuessSubmitted={isGuessSubmitted}
        isCorrectAnswer={isCorrectAnswer}
        isSelected={isSelected}
      >
        <div
          className={classnames('mcq-option__letter-wrapper', {
            'mcq-option__letter-wrapper--checkbox': isSelectMultiple
          })}
        >
          <span className='mcq-option__letter'>{MULTIPLE_CHOICE_LETTERS.charAt(index)}</span>
        </div>
        <MarkdownRendererV2 className='mcq-option__content' text={value} />
      </CorrectnessIndicatorWrapper>
    );
  }
}
