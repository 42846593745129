import React, {useState} from 'react';
import {Icon, Button} from '@albert-io/atomic';
import './hard-cap-warning.scss';
import classnames from 'classnames';
import {pluralize} from 'lib/stringUtils';

interface Props extends PropsWithClassNameOptional {
  seatsRemaining: number;
  rounded?: boolean;
  dismissible?: boolean;
  admin?: boolean;
}

export const LICENSE_SEAT_WARNING_THRESHOLD = 50;

const HardCapWarningBanner = ({
  seatsRemaining,
  className,
  rounded = false,
  dismissible = false,
  admin = false
}: Props) => {
  const [isMessageDismissed, setIsMessageDismissed] = useState(false);
  if (isMessageDismissed) {
    return null;
  }
  if (seatsRemaining <= 0) {
    const contactMessage = admin
      ? 'Contact your account manager or success@albert.io if you need to purchase more seats.'
      : 'Contact your administrator if you need to enroll additional students.';
    return (
      <div
        className={classnames('hard-cap-warning__error u-display_flex', className, {
          'hard-cap-warning__round': rounded
        })}
      >
        <Icon icon='ban' className='u-mar-r_2 u-align-self_center' />
        Your school&apos;s license has 0 seats remaining. No further student enrollments possible.
        &nbsp;{contactMessage}
        {dismissible && (
          <Button
            onClick={() => setIsMessageDismissed(true)}
            variant='text'
            color='negative'
            size='s'
            className='hard-cap-warning__dismiss-error-btn'
          >
            Dismiss message
          </Button>
        )}
      </div>
    );
  }
  if (seatsRemaining <= LICENSE_SEAT_WARNING_THRESHOLD) {
    return (
      <div
        className={classnames('hard-cap-warning__warn u-display_flex', className, {
          'hard-cap-warning__round': rounded
        })}
      >
        <Icon icon={['far', 'exclamation-triangle']} className='u-mar-r_2 u-align-self_center' />
        Your school license currently has {seatsRemaining} remaining{' '}
        {pluralize('seat', seatsRemaining)}. Once the seat limit is reached, no additional students
        will be able to join the class.
        {dismissible && (
          <Button
            onClick={() => setIsMessageDismissed(true)}
            variant='text'
            size='s'
            className='hard-cap-warning__dismiss-warn-btn'
          >
            Dismiss message
          </Button>
        )}
      </div>
    );
  }
  return null;
};

export default HardCapWarningBanner;
