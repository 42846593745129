import React, {useState} from 'react';
import classnames from 'classnames';
import {TransformWrapper} from 'react-zoom-pan-pinch';
import {SupplementModelV1} from '@albert-io/models';
import {Text, Modal} from '@albert-io/atomic';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import useClientRect from 'lib/hooks/useClientRect';

import {ImageControls} from './ImageControls.react';
import {ImageWrapper} from './ImageWrapper.react';

import {MIN_ZOOM, MAX_ZOOM} from './constants';

interface ImageFullScreenModalProps {
  isMobile: boolean;
  caption: string;
  setIsFullScreen: (isFullScreen: boolean) => void;
  supplement: SupplementModelV1;
}

export const ImageFullScreenModal = ({
  isMobile,
  caption,
  setIsFullScreen,
  supplement
}: ImageFullScreenModalProps) => {
  // disable panning on mobile if we are not zoomed in
  const [shouldDisablePanning, setShouldDisablePanning] = useState(false);

  // get the dynamic height of the caption to adjust the image max height, otherwise the image won't respect the caption height
  const [captionRect, captionRef] = useClientRect();
  return (
    <Modal
      fullscreen
      ariaLabel='Fullscreen image modal'
      handleClose={() => setIsFullScreen(false)}
      backdropClassName='image-supplement__modal-backdrop'
    >
      {({modalContentStyle}) => (
        <figure className={classnames(modalContentStyle, 'image-supplement__modal-content')}>
          <TransformWrapper
            centerOnInit
            limitToBounds
            minScale={MIN_ZOOM}
            maxScale={MAX_ZOOM}
            pinch={{
              step: 10
            }}
            panning={{
              disabled: shouldDisablePanning
            }}
          >
            <ImageControls
              isFullScreen
              showFullScreen
              showZoom
              isMobile={isMobile}
              setIsFullScreen={setIsFullScreen}
              setShouldDisablePanning={setShouldDisablePanning}
            />
            <div
              style={{
                height: captionRect?.height ? `calc(100vh - ${captionRect.height}px)` : '100vh'
              }}
            >
              <ImageWrapper className='image-supplement__modal-image' supplement={supplement} />
            </div>
          </TransformWrapper>
          {caption && (
            <div ref={captionRef}>
              <Text className='image-supplement__modal-caption' as='figcaption' size='xs'>
                <MarkdownRendererV2 text={caption} />
              </Text>
            </div>
          )}
        </figure>
      )}
    </Modal>
  );
};
