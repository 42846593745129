import {useEffect, useContext} from 'react';
import {useContentDiscoveryStore} from '../index';
import {ContentDiscoveryContext} from 'client/components/ContentDiscovery/ContentDiscoveryProvider.react';
import {getDraftQuestionSetsQuery} from 'client/components/ContentDiscovery/ContentDiscovery.queries';
import {pages} from '../slices/Navigation.store';

export const useNavigationSubscription = () => {
  const {questionsQuery, setQuestionsQuery} = useContext(ContentDiscoveryContext);

  const store = useContentDiscoveryStore.getState();
  const {draft, navigation} = store;

  useEffect(() => {
    const unsubscribers = [
      useContentDiscoveryStore.subscribe(
        (state) => state.navigation.initialName,
        (initialName) => {
          draft.setDraftName(initialName);
        }
      )
    ];

    return () => {
      unsubscribers.forEach((unsub) => unsub());
    };
  }, []);

  useEffect(() => {
    window.addEventListener('hashchange', navigation.handleHashChange);
    return () => {
      window.removeEventListener('hashchange', navigation.handleHashChange);
    };
  }, [navigation.handleHashChange]);

  useEffect(() => {
    if (navigation.currentPage === pages.review) {
      draft.setDraftQuestionsQuery(draft.draftId ? getDraftQuestionSetsQuery(draft.draftId) : null);
    } else {
      // this is to reinitiate the questions query when we switch from the review to add page
      // However, because `questionsQuery` is being set to itself,
      // it seems to rely on broken behavior where `questionsQuery` is expected to be non-null
      // but is actually null within the `useContentDiscoveryStore.subscribe` callback
      // this can be revisted when we migrate question state into zustand
      setQuestionsQuery(questionsQuery);
    }
  }, [navigation.currentPage]);
};
