'use strict';
import {Map} from 'immutable';
import {Store} from '../../framework';
import subheaderActions from './SubheaderActions';

class SubheaderStore extends Store {
  constructor(name) {
    super(name);
    this.initialData = new Map({
      showMobileMenu: false
    });
    this.setInitialData(this.initialData);
    this.handle(subheaderActions.CLOSE_MOBILE_MENU, this._closeMobileMenu);
    this.handle(subheaderActions.OPEN_MOBILE_MENU, this._openMobileMenu);
  }

  _closeMobileMenu() {
    this.writeData('showMobileMenu', false);
  }

  _openMobileMenu() {
    this.writeData('showMobileMenu', true);
  }

  showMobileMenu() {
    return this.readData('showMobileMenu');
  }
}

const subheaderStore = new SubheaderStore('SubheaderStore');
export default subheaderStore;
