import React, {useEffect, useRef} from 'react';
import {withRouter} from 'react-router';

import useReportRoute, {ContextType, LocationProps} from './useReportRoute';

export const ReportsContext = React.createContext<ContextType>(undefined!); // fix

interface Props extends PropsWithChildrenRequired {
  location: LocationProps;
}

function ReportsProvider({
  children,
  location = {
    query: {},
    pathname: ''
  }
}: Props) {
  const context = useReportRoute({location});
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      return;
    }
    didMountRef.current = true;
  }, []);

  if (!didMountRef.current) {
    return null;
  }

  return <ReportsContext.Provider value={context}>{children}</ReportsContext.Provider>;
}

export default withRouter(ReportsProvider);
