import React, {useState, useCallback} from 'react';
import {Text, Icon, Modal, Card, Button} from '@albert-io/atomic';
import {IconProp} from '@albert-io/atomic/atoms/Icon/Icon.react';

import '../written-submission.scss';

interface ErrorDetails {
  icon: IconProp;
  title: string;
  message: string;
}

interface handleUploadErrorParams {
  error?: any;
  fileName?: string;
  acceptedExtensions?: string;
}
export const useUploadErrorHandler = () => {
  const [showUploadErrorModal, setShowUploadErrorModal] = useState(false);
  const [uploadErrorDetails, setUploadErrorDetails] = useState<ErrorDetails>({} as ErrorDetails);

  const handleUploadError = useCallback(
    ({error, fileName, acceptedExtensions}: handleUploadErrorParams) => {
      const code = error?.response?.body?.errors[0]?.code;

      if (
        code === 'Errors.BadRequest.ValidationError.Media.InvalidContentType' ||
        code === 'Errors.BadRequest.ValidationError.Media.ContentTypeMismatch'
      ) {
        setUploadErrorDetails({
          icon: ['far', 'ban'],
          title: 'Invalid file type',
          message: `"${fileName}" is not a valid file type. Valid file types include "${acceptedExtensions}".`
        });
      } else {
        setUploadErrorDetails({
          icon: ['far', 'frown'],
          title: 'Upload Error',
          message: `We were unable to upload "${fileName}".`
        });
      }

      setShowUploadErrorModal(true);
    },
    []
  );

  return {handleUploadError, showUploadErrorModal, setShowUploadErrorModal, uploadErrorDetails};
};

interface handleErrorParams {
  action: 'download' | 'delete';
}

export const useErrorHandler = () => {
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorDetails, setErrorDetails] = useState<ErrorDetails>({} as ErrorDetails);

  const handleError = useCallback(({action}: handleErrorParams) => {
    if (action === 'download') {
      setErrorDetails({
        icon: ['far', 'frown'],
        title: 'Download Error',
        message: 'An error occured and your file was unable to be downloaded.'
      });
    } else {
      setErrorDetails({
        icon: ['far', 'frown'],
        title: 'Delete Error',
        message: 'An error occured and your file was unable to be deleted.'
      });
    }
    setShowErrorModal(true);
  }, []);

  return {handleError, showErrorModal, setShowErrorModal, errorDetails};
};

interface ErrorModalProps {
  onClose: any;
  errorDetails: ErrorDetails;
}

export const ErrorModal = ({onClose, errorDetails}: ErrorModalProps) => {
  return (
    <Modal handleClose={onClose} ariaLabel='Error Modal'>
      {({CloseButtonWrapper}) => {
        return (
          <Card className='written-submission__modal'>
            <div className='written-submission__modal__icon'>
              <Icon icon={errorDetails.icon} />
            </div>
            <div className='written-submission__modal__body'>
              <Text bold className='u-text-align_center'>
                {errorDetails.title}
              </Text>
              <Text className='u-text-align_center'>{errorDetails.message}</Text>
            </div>
            <CloseButtonWrapper>
              <Button
                color='secondary'
                variant='outlined'
                onClick={onClose}
                className='u-width_100pc'
              >
                Dismiss
              </Button>
            </CloseButtonWrapper>
          </Card>
        );
      }}
    </Modal>
  );
};
