import React, {useCallback} from 'react';
import classnames from 'classnames';
import {Map} from 'immutable';
import {useShallow} from 'zustand/react/shallow';

import TextInput from 'client/generic/Forms/TextInput/TextInput';
import {AudioPlayer} from 'generic/AudioPlayer/AudioPlayer.react';
import supplementEditorStore from 'client/Supplements/SupplementEditor/SupplementEditor.store';
import supplementEditorActions from 'client/Supplements/SupplementEditor/SupplementEditor.actions';
import {useSupplementEditorV2Store} from 'client/Supplements/SupplementEditor/SupplementEditorV2Store';
import {TranslationCommentStatusEditor} from 'client/Supplements/SupplementEditor/TranslationCommentStatusEditor';
import {PRIMARY_CONTENT_LANGUAGE} from 'client/EditPage/V2/QuestionEditorV2Store.types';
import WindowConfirm from 'generic/WindowConfirm/WindowConfirm.react';
import windowConfirmActions from 'generic/WindowConfirm/WindowConfirmActions';
import Button from 'sg/Button/Button.react';
import {AuthoringSupplement as AuthoringSupplementModelV1} from 'resources/GeneratedModels/AuthoringSupplement/AuthoringSupplementModel.v1';

import {callAction, callTargetedAction} from '../../framework';
import constants from '../../constants';

import './audio-editor.scss';

interface Props {
  supplement: AuthoringSupplementModelV1;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  errors: Map<string, any>;
}

const AudioEditor = ({supplement, onChange, errors}: Props) => {
  const {currentLanguage, updateTranslatedField, translatedCaption, translatedName} =
    useSupplementEditorV2Store(
      useShallow((state) => ({
        currentLanguage: state.currentLanguage,
        updateTranslatedField: state.updateTranslatedField,
        translatedName:
          state
            .currentTranslatedSupplement()
            ?.translated_fields?.find((field) => field.field === 'name')?.text || '',
        translatedCaption:
          state
            .currentTranslatedSupplement()
            ?.translated_fields?.find((field) => field.field === 'content.caption')?.text || ''
      }))
    );

  const clearAudioModalStoreName = 'SupplementAudioEditor--ClearAudioModal';

  const clearAudio = useCallback(() => {
    callAction(supplementEditorActions.MODIFY_ACTIVE_SUPPLEMENT, {
      setter: 'setAudio',
      value: ''
    });
    callAction(supplementEditorActions.VALIDATE_SUPPLEMENT, 'audio');
  }, []);

  const uploadAudio = useCallback((e) => {
    const element = e.target;
    callAction(supplementEditorActions.UPLOAD_FILE, {
      name: element.name,
      setter: element.dataset.setterMethod,
      file: element.files[0]
    });
  }, []);

  const showModal = useCallback(() => {
    callTargetedAction({
      name: windowConfirmActions.SHOW_MODAL,
      targetStore: clearAudioModalStoreName
    });
  }, [clearAudioModalStoreName]);

  const isUploadPending = supplementEditorStore.isUploadPending();
  const uploadButtonClasses = classnames('audio-editor__button', {
    'audio-editor__button--disabled': isUploadPending
  });
  const uploadError = errors.get('audio');

  const nameValue =
    currentLanguage === PRIMARY_CONTENT_LANGUAGE ? supplement.getName() : translatedName;
  const captionValue =
    currentLanguage === PRIMARY_CONTENT_LANGUAGE ? supplement.getCaption() : translatedCaption;

  // @todo: Replace the following with a generic uploader component
  const uploadManager = supplement.getAudio() ? (
    <div className='audio-editor__input audio-editor__input--upload-manager u-align-items_center u-mar-tb_1'>
      <AudioPlayer
        caption={captionValue}
        src={`${constants.USER_ASSETS_ORIGIN}/${supplement.getAudio()}`}
      />
      <Button
        disabled={currentLanguage !== PRIMARY_CONTENT_LANGUAGE}
        className='u-max-width_240'
        color='red'
        onClick={showModal}
        text='Clear audio'
      />
    </div>
  ) : (
    <div className='audio-editor__input audio-editor__input--upload-manager'>
      <label className={uploadButtonClasses} htmlFor='audio'>
        Choose File
      </label>
      <input
        className='audio-editor__button'
        data-setter-method='setAudio'
        disabled={isUploadPending}
        id='audio'
        name='audio'
        type='file'
        onChange={uploadAudio}
      />
      <div className='a-form-input__error'>{uploadError}</div>
    </div>
  );

  const handleNameChange = useCallback(
    (e) => {
      if (currentLanguage === PRIMARY_CONTENT_LANGUAGE) {
        onChange(e);
      } else {
        updateTranslatedField(currentLanguage, 'name', 'text', e.target.value);
      }
    },
    [currentLanguage, onChange, updateTranslatedField]
  );
  const handleCaptionChange = useCallback(
    (e) => {
      if (currentLanguage === PRIMARY_CONTENT_LANGUAGE) {
        onChange(e);
      } else {
        updateTranslatedField(currentLanguage, 'content.caption', 'text', e.target.value);
      }
    },
    [currentLanguage, onChange, updateTranslatedField]
  );

  return (
    <div className='audio-editor'>
      <div className='audio-editor__fieldset'>
        <TextInput
          className='audio-editor__input'
          data-setter-method='setName'
          error={currentLanguage === PRIMARY_CONTENT_LANGUAGE && Boolean(errors.get('name'))}
          errorMessage={currentLanguage === PRIMARY_CONTENT_LANGUAGE && errors.get('name', null)}
          label='Name'
          name='name'
          onChange={handleNameChange}
          placeholder=''
          value={nameValue}
        />
        <TranslationCommentStatusEditor fieldName='name' />
        <TextInput
          className='audio-editor__input'
          data-setter-method='setCaption'
          error={currentLanguage === PRIMARY_CONTENT_LANGUAGE && Boolean(errors.get('caption'))}
          errorMessage={currentLanguage === PRIMARY_CONTENT_LANGUAGE && errors.get('caption', null)}
          label='Caption'
          name='caption'
          onChange={handleCaptionChange}
          placeholder=''
          value={captionValue}
        />
        <TranslationCommentStatusEditor fieldName='content.caption' />
      </div>
      {uploadManager}
      <WindowConfirm
        title='Are you sure you want to delete this audio file?'
        message='This action cannot be undone and will impact all questions using this supplement.'
        confirmButtonText='Clear Audio'
        storeName={clearAudioModalStoreName}
        onConfirm={clearAudio}
      />
    </div>
  );
};

export default AudioEditor;
