import React, {useContext, useEffect, useState} from 'react';
import {Heading} from '@albert-io/atomic';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {CLASSROOM_STATUSES} from 'client/constants';
import sessionStore from 'client/Session/SessionStore';

import {ReportsContext} from '../Reports.context';
import {getResourceFromDimension} from '../reports.utils';

interface HeadingProps extends PropsWithClassNameOptional {
  id: string;
  size?: 'xl' | 'l' | 'm' | 's';
}

function AssignmentHeading({className, id, size = 'm'}: HeadingProps) {
  const [assignmentName, setAssignmentName] = useState('');

  useEffect(() => {
    (async () => {
      const fetchedAssignment = await getResourceFromDimension('assignments', id);

      setAssignmentName(fetchedAssignment.getName());
    })();
  }, [id, setAssignmentName]);

  return (
    <Heading className={className} size={size}>
      {assignmentName}
    </Heading>
  );
}

function QuestionHeading({className, id, size}: HeadingProps) {
  const [questionTitle, setQuestionTitle] = useState('');

  useEffect(() => {
    (async () => {
      const fetchedQuestion = await getResourceFromDimension('questions', id);

      setQuestionTitle(fetchedQuestion.getTitle());
    })();
  }, [id, setQuestionTitle]);

  return (
    <Heading className={className} size={size}>
      <MarkdownRendererV2 text={questionTitle} />
    </Heading>
  );
}

function StandardHeading({className, id, size}: HeadingProps) {
  const [standardTitle, setStandardTitle] = useState('');

  useEffect(() => {
    (async () => {
      const fetchedStandard = await getResourceFromDimension('standards', id);

      setStandardTitle(fetchedStandard.getTitle());
    })();
  }, [id, setStandardTitle]);

  return (
    <Heading className={className} size={size}>
      {standardTitle}
    </Heading>
  );
}

function StudentHeading({className, id, size}: HeadingProps) {
  const {getStudentFullName} = useContext(ReportsContext);

  const [student, setStudent] = useState();

  useEffect(() => {
    (async () => {
      const fetchedStudent = await getResourceFromDimension('students', id);
      setStudent(fetchedStudent);
    })();
  }, [id]);

  return (
    <Heading className={className} size={size}>
      {getStudentFullName(student)}
    </Heading>
  );
}

function ClassroomHeading({className, id, size}: HeadingProps) {
  const [classroom, setClassroom] = useState('');

  useEffect(() => {
    (async () => {
      const fetchedClassroom = await getResourceFromDimension('classrooms', id);
      setClassroom(
        fetchedClassroom.getStatus() === CLASSROOM_STATUSES.ARCHIVED
          ? `${fetchedClassroom.getName()} (Archived)`
          : fetchedClassroom?.getName?.()
      );
    })();
  }, [id, setClassroom]);

  return (
    <Heading className={className} size={size}>
      {classroom}
    </Heading>
  );
}

export function TeacherHeading({className, id, size}: HeadingProps) {
  const [teacher, setTeacher] = useState('');

  useEffect(() => {
    (async () => {
      const fetchedTeacher = await getResourceFromDimension('teachers', id);
      setTeacher(`${fetchedTeacher.getDisplayName().trim()}'s Classes`);
    })();
  }, [id, setTeacher]);

  const isOtherTeacher = id !== sessionStore.getUserId();

  return (
    <Heading className={className} size={size}>
      {isOtherTeacher ? teacher : 'My Classes'}
    </Heading>
  );
}

function SchoolHeading({className, id, size}: HeadingProps) {
  const [school, setSchool] = useState('');

  useEffect(() => {
    (async () => {
      const fetchedSchool = await getResourceFromDimension('schools', id);

      setSchool(fetchedSchool.getName());
    })();
  }, [id, setSchool]);

  return (
    <Heading className={className} size={size}>
      {school}
    </Heading>
  );
}

function SubjectHeading({className, id, size}: HeadingProps) {
  const [subject, setSubject] = useState('');

  useEffect(() => {
    (async () => {
      const fetchedSubject = await getResourceFromDimension('subjects', id);
      setSubject(fetchedSubject.getName());
    })();
  }, [id, setSubject]);

  return (
    <Heading className={className} size={size}>
      {subject}
    </Heading>
  );
}

export function SchoolAdminHeading({className, id, size}: HeadingProps) {
  const [teacher, setTeacher] = useState('');

  useEffect(() => {
    (async () => {
      const fetchedTeacher = await getResourceFromDimension('teachers', id);
      setTeacher(`${fetchedTeacher.getDisplayName().trim()}'s Schools`);
    })();
  }, [id, setTeacher]);

  const isOtherTeacher = id !== sessionStore.getUserId();

  return (
    <Heading className={className} size={size}>
      {isOtherTeacher ? teacher : 'My Schools'}
    </Heading>
  );
}

export default {
  assignments: AssignmentHeading,
  questions: QuestionHeading,
  standards: StandardHeading,
  students: StudentHeading,
  classrooms: ClassroomHeading,
  teachers: TeacherHeading,
  schools: SchoolHeading,
  'school-admin': SchoolAdminHeading,
  subjects: SubjectHeading
};
