import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {List} from 'immutable';
import {isNumber} from 'lodash';
import StaticField from 'generic/Forms/StaticField/StaticField.react';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import PassageCorrectionPopover from './shared/PassageCorrectionPopover.react';
import {QuestionModelV3} from 'resources/augmented/Question/QuestionModel.v3';
import {
  doesRegionContainOptionIndex,
  getRegionKeyForOptionIndex,
  getSolutionsForRegionKey,
  getUncorrectedText,
  isRegionDistractor
} from './PassageCorrectionQuestionType.utils';
import './passage-correction-question.scss';

export default class PassageCorrectionPostSubmissionRenderer extends React.Component {
  static propTypes = {
    isGuessSubmitted: PropTypes.bool,
    question: PropTypes.instanceOf(QuestionModelV3),
    onRenderWordFunc: PropTypes.func
  };

  state = {
    activeIndex: null
  };

  handleClick = (e) => {
    const regionIndex = e.target.dataset.regionIndex;
    if (regionIndex === undefined) {
      return;
    }
    this.setState({
      activeIndex: parseInt(e.target.dataset.idx, 10)
    });
  };

  handleActiveIndexReset = () => {
    this.setState({
      activeIndex: null
    });
  };

  wordPropsFunc = (text, optionIndex) => {
    const question = this.props.question;
    const rubric = this.props.isGuessSubmitted ? question.getMeta().getQuestionRubric() : null;
    const validResponse = rubric ? rubric.get('validResponse') : null;
    const matchedRegionIndex = getRegionKeyForOptionIndex(question, optionIndex);
    const baseWordProps = {
      text,
      onRenderWordFunc: this.props.onRenderWordFunc
    };
    if (!matchedRegionIndex) {
      return baseWordProps;
    }
    const matchedRegion = validResponse.get(matchedRegionIndex);
    const regionSolutions = matchedRegion.get('solutions');
    const studentRegionCorrection = question
      .getStore()
      .getCorrectionAsJoinedStrings(matchedRegionIndex);
    const hasStudentRegionCorrection = studentRegionCorrection !== null;
    const isDistractor = isRegionDistractor(question, matchedRegionIndex);
    const isCorrect =
      (isDistractor &&
        hasStudentRegionCorrection === false &&
        Boolean(matchedRegion.get('annotation'))) ||
      (!isDistractor && regionSolutions.some((solution) => solution === studentRegionCorrection));
    const isIncorrect = isDistractor
      ? hasStudentRegionCorrection
      : !hasStudentRegionCorrection ||
        regionSolutions.every((solution) => solution !== studentRegionCorrection);
    // Need to explicitly check for false here. Can not just negate the values, as that would cause undefined
    // to pass the conditions.
    const onClick =
      isCorrect === false && isIncorrect === false
        ? (e) => {
            e.preventDefault();
            e.stopPropagation();
          }
        : null;
    const activeIndex = this.state.activeIndex;
    const matchedStartIndex = matchedRegion.get('start_index');
    const matchedEndIndex = matchedRegion.get('end_index');
    const isActive =
      isNumber(activeIndex) && doesRegionContainOptionIndex(matchedRegion, activeIndex);
    const isRangeStart = matchedStartIndex === optionIndex;
    const isRangeEnd = matchedEndIndex === optionIndex;
    let correctedText = text;
    if (studentRegionCorrection) {
      const splitStudentCorrection = studentRegionCorrection.split(/\s+/);
      const regionIndexDifference = matchedEndIndex - matchedStartIndex;
      const currentIndex = Math.abs(matchedEndIndex - optionIndex - regionIndexDifference);
      /**
       * A solution can have as many or more words than the incorrect region (i.e. 'alot' can be corrected to 'a lot',
       * but not the other way around). Because of this, if we're rendering the last word in an incorrect region, we
       * render the rest of the words in the student's correction, joined by a space.
       */
      correctedText =
        currentIndex === regionIndexDifference
          ? splitStudentCorrection.slice(regionIndexDifference).join(' ')
          : splitStudentCorrection[currentIndex];
    }

    return {
      ...baseWordProps,
      isActive,
      isCorrect,
      isIncorrect,
      matchedRegionIndex,
      isRangeStart,
      isRangeEnd,
      correctedText,
      onClick
    };
  };

  render() {
    const question = this.props.question;
    const activeIndex = this.state.activeIndex;
    let studentGuess;
    let correctAnswer;
    let annotation;
    if (activeIndex !== null) {
      const validResponse = question.getValidResponse();
      const activeRegionKey = getRegionKeyForOptionIndex(question, activeIndex);
      const regionSolutions = isRegionDistractor(question, activeRegionKey)
        ? List.of(question.getStore().getInitialCorrectionAsJoinedString(activeRegionKey))
        : getSolutionsForRegionKey(question, activeRegionKey);

      studentGuess = this.props.guess.getIn(['content', activeRegionKey]);
      correctAnswer = regionSolutions.includes(studentGuess)
        ? studentGuess
        : regionSolutions.first();
      annotation = validResponse.getIn([activeRegionKey, 'annotation']);
    }
    return (
      <MarkdownRendererV2
        passageCorrection
        className='passage-correction-question__input-area'
        text={getUncorrectedText(question)}
        onClick={this.handleClick}
        wordPropsFunc={this.wordPropsFunc}
        popover={
          <Popover
            activeIndex={activeIndex}
            key={activeIndex}
            studentGuess={studentGuess}
            correctAnswer={correctAnswer}
            annotation={annotation}
            onReset={this.handleActiveIndexReset}
          />
        }
      />
    );
  }
}

class Popover extends React.Component {
  static propTypes = {
    studentGuess: PropTypes.string,
    correctAnswer: PropTypes.string,
    annotation: PropTypes.string
  };

  render() {
    const {studentGuess, correctAnswer, annotation} = this.props;
    const isCorrect = studentGuess === correctAnswer;
    return (
      <PassageCorrectionPopover {...this.props}>
        <div className='passage-correction-popover__correction-wrapper'>
          {studentGuess ? (
            <StaticField
              label='Your answer'
              value={studentGuess}
              className={classnames('passage-correction-popover__student-answer', {
                'passage-correction-popover__student-answer--correct': isCorrect,
                'passage-correction-popover__student-answer--incorrect': !isCorrect
              })}
            />
          ) : null}
          <StaticField label='Correct answer' value={correctAnswer} />
        </div>
        {annotation ? (
          <div className='passage-correction-popover__annotation-wrapper'>
            <div className='a-form-input__label'>Annotation:</div>
            <MarkdownRendererV2 text={annotation} />
          </div>
        ) : null}
      </PassageCorrectionPopover>
    );
  }
}
