// @flow
import {fromJS, Record} from 'immutable';
import {GenericModel} from 'resources/Generic.model';

const GoogleCourseRecord = Record({
  /* eslint-disable camelcase */
  alternate_link: '',
  course_group_email: '',
  course_state: '',
  enrollment_code: '',
  guardians_enabled: false,
  id: '',
  name: '',
  owner_id: '',
  room: '',
  section: '',
  teacher_group_email: '',
  update_time: ''
  /* eslint-enable camelcase */
});

const GoogleCourseConfig = fromJS({
  endpoint: 'google_classroom_api',
  relationships: {},
  fieldToMethodMap: {
    id: {
      get: true,
      set: false
    },
    alternate_link: true,
    course_group_email: true,
    course_state: true,
    enrollment_code: true,
    course_group_email: true,
    guardians_enabled: true,
    name: true,
    owner_id: true,
    room: true,
    section: true,
    teacher_group_email: true,
    update_time: true
  }
});

export class GoogleCourseModel extends GenericModel(GoogleCourseRecord, GoogleCourseConfig) {}
