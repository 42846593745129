/**
 * # Divider
 *
 * A horizontal line divider component.
 *
 * ## Props
 *
 * - `spacing` (number): The spacing value for the divider. It determines the margin-bottom of the divider. Default value is `1`.
 *
 * ## Usage
 *
 * ```jsx
 * import React from 'react';
 * import Divider from './Divider.react';
 *
 * const MyComponent = () => {
 *   return (
 *     <div>
 *       <h1>My Component</h1>
 *       <p>This is some content.</p>
 *       <Divider spacing={3} />
 *       <p>This is more content.</p>
 *     </div>
 *   );
 * };
 *
 * export default MyComponent;
 * ```
 */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './divider.scss';

const spacingProps = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const Divider = ({spacing}) => <hr className={classnames('a-divider', `u-mar-b_${spacing}`)} />;

Divider.propTypes = {
  spacing: PropTypes.oneOf(spacingProps)
};

Divider.defaultProps = {
  spacing: 1
};

export default Divider;
