import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {Link} from 'react-router';
import PropTypes from 'prop-types';

import MarkdownRendererV2 from 'client/generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {Chip} from '@albert-io/atomic';
import {TagOrStandardGroup} from 'client/components/TagOrStandardGroup/TagOrStandardGroup.react';
import {resource} from '@albert-io/json-api-framework/request/builder';

import appStore from 'client/AppStore';

import LabelTable from '../LabelTable/LabelTable.react';

import {StandardChip} from './StandardChip.react';

/**
 * @todo Implement Mandark version of `QuestionHelpfulnessVote`
 * @see https://github.com/albert-io/project-management/issues/687
 */
import ResultIndicatorBar from './ResultIndicatorBar/ResultIndicatorBar.react';
/**
 * @todo This stylesheet probably needs to be cleaned up.
 */
import '../question-footer.scss';

export default class GenericExplanation extends React.Component {
  static propTypes = {
    isCorrect: PropTypes.bool,
    question: ImmutablePropTypes.record,
    questionId: PropTypes.string,
    questionSetId: PropTypes.string,
    responseStats: PropTypes.element,
    showLabelTable: PropTypes.bool,
    solutionText: PropTypes.string,
    subjectId: PropTypes.string,
    subjectSlug: PropTypes.string,
    videoExplanationId: PropTypes.string,
    hideResultIndicatorBar: PropTypes.bool,
    isUnsubmittedAssignmentQuestion: PropTypes.bool,
    isInUnpublishedSubject: PropTypes.bool
  };

  static defaultProps = {
    isInUnpublishedSubject: false
  };

  generateSampleResponse() {
    const sample = this.props.question.getSample();
    if (!sample) {
      return null;
    }
    return [
      <h2 key='sampleHeading' className='question-footer__explanation-heading'>
        Sample Response
      </h2>,
      <MarkdownRendererV2
        text={sample}
        key='sampleText'
        className='question-footer__sample-response'
      />
    ];
  }

  generateExplanationContent() {
    const els = [
      <h2 key='heading' className='question-footer__explanation-heading'>
        Explanation
      </h2>
    ];

    if (this.props.isUnsubmittedAssignmentQuestion) {
      return els.concat([
        <div key='solution' className='question-footer__explanation-content'>
          <p className='question-footer__explanation-content-unsubmitted-message'>
            You&apos;ll need to place a guess in order to view this question&apos;s explanation.
          </p>
          <div className='explanation-content-placeholder'>
            <div className='explanation-content-placeholder__line explanation-content-placeholder__line--medium' />
            <div className='explanation-content-placeholder__line' />
            <div className='explanation-content-placeholder__line explanation-content-placeholder__line--small' />
            <div className='explanation-content-placeholder__line' />
            <div className='explanation-content-placeholder__line explanation-content-placeholder__line--medium' />
            <div className='explanation-content-placeholder__line explanation-content-placeholder__line--medium' />
          </div>
        </div>
      ]);
    }

    els.push(
      <div key='solution' className='question-footer__explanation-content'>
        <MarkdownRendererV2 text={this.props.solutionText} />
      </div>
    );

    if (this.props.videoExplanationId) {
      els.push(
        <div key='video'>
          <h3 className='question-footer__explanation-heading'>Video Explanation</h3>
          <MarkdownRendererV2
            text={`[s:${this.props.videoExplanationId}:videoExplanation:video]`}
          />
        </div>
      );
    }

    return els;
  }

  render() {
    // Determines whether or not we should the <LabelTable> component...
    // also used to toggle Label tooltips based on the presence of the LabelTable
    const {showLabelTable} = this.props;
    const isDennis = appStore.isZoneDennis();
    const tagsQuery = resource('tags_v1').mandarkEndpoint([
      isDennis ? 'authoring_question_sets_v1' : 'question_sets_v1',
      this.props.questionSetId,
      'tags_v1'
    ]);
    const standardsQuery = resource('standards_v1')
      .mandarkEndpoint([
        isDennis ? 'authoring_questions_v1' : 'questions_v3',
        this.props.questionId,
        'standards_v1'
      ])
      .include('standard_set_v1');

    return (
      <div className='question-footer__main-content-wrapper'>
        {this.props.hideResultIndicatorBar ? null : (
          <ResultIndicatorBar isCorrect={this.props.isCorrect} />
        )}
        {this.props.children}
        <div className='question-footer__explanation-wrapper'>
          {this.generateSampleResponse()}
          {this.generateExplanationContent()}
        </div>
        {showLabelTable ? <LabelTable query={standardsQuery} /> : null}
        {standardsQuery.isResourcePopulated() && !standardsQuery.getResource().isEmpty() && (
          <div>
            <h2 className='question-footer__explanation-heading'>Standards</h2>
            <TagOrStandardGroup
              query={standardsQuery}
              renderFunc={(standard, key) => (
                <StandardChip
                  as={Link}
                  key={key}
                  enableTooltip={!this.props.showLabelTable}
                  standard={standard}
                  title={`See all questions listed under ${standard.getTitle()}`}
                  to={`/${this.props.subjectSlug}?standards=${standard.getId()}`}
                />
              )}
            />
          </div>
        )}
        {tagsQuery.isResourcePopulated() && !tagsQuery.getResource().isEmpty() && (
          <div>
            <h2 className='question-footer__explanation-heading'>Tags</h2>
            <TagOrStandardGroup
              query={tagsQuery}
              renderFunc={(tag, key) => (
                <Chip
                  as={Link}
                  key={key}
                  title={`See all questions tagged ${tag.getName()}`}
                  to={
                    this.props.isInUnpublishedSubject
                      ? null
                      : `/${this.props.subjectSlug}?tags=${tag.getId()}`
                  }
                >
                  <MarkdownRendererV2 text={tag.getName()} />
                </Chip>
              )}
            />
          </div>
        )}
        {this.props.isUnsubmittedAssignmentQuestion ? null : this.props.responseStats}
      </div>
    );
  }
}
