import React, {createContext, useState} from 'react';
import {WindowSizeConsumer} from '@albert-io/atomic';

interface ContextProps {
  isQuestionsListInView: boolean;
  setIsQuestionsListInView: React.Dispatch<React.SetStateAction<boolean>>;
  isMobile: boolean;
}

export const MobilePracticeViewContext = createContext({} as ContextProps);

export const MobilePracticeViewProvider = ({children}: PropsWithChildrenRequired) => {
  const [isQuestionsListInView, setIsQuestionsListInView] = useState(false);

  return (
    <WindowSizeConsumer>
      {({breakpoints, viewportWidth}) => {
        const isMobile = viewportWidth < breakpoints.s;

        const value = {
          isQuestionsListInView,
          setIsQuestionsListInView,
          isMobile
        };

        return (
          <MobilePracticeViewContext.Provider value={value}>
            {children}
          </MobilePracticeViewContext.Provider>
        );
      }}
    </WindowSizeConsumer>
  );
};
