import Icon, {IconProp} from '@albert-io/atomic/atoms/Icon/Icon.react';
import React from 'react';

interface Props {
  icon: IconProp;
}

export const QuestionEditorIcon = ({icon}: Props) => {
  return (
    <div
      className='content-secondary u-display_flex u-align-items_center u-justify-content_center'
      style={{height: 'var(--space-x6)'}}
    >
      <Icon icon={icon} style={{height: 'var(--text-xxl)'}} />
    </div>
  );
};
