/**
 * # NumberInput
 *
 * A component that renders an input field for numeric values.
 *
 * ## Props
 *
 * - `as?: React.ElementType`: The HTML element or custom React component to be rendered as the input element. Defaults to `'input'`.
 * - `className?: string`: Additional CSS class names to be applied to the input element.
 * - `onBlur?: (e?: React.FocusEvent<HTMLElement>) => void`: Event handler for the `blur` event.
 * - `onFocus?: () => {}`: Event handler for the `focus` event.
 * - `onMouseEnter?: () => {}`: Event handler for the `mouseenter` event.
 * - `onMouseLeave?: () => {}`: Event handler for the `mouseleave` event.
 * - `error?: boolean`: Indicates whether the input has an error.
 * - `size?: 's' | 'regular' | 'l'`: The size of the input. Defaults to `'regular'`.
 * - `success?: boolean`: Indicates whether the input is in a success state.
 * - `defaultValue?: string`: The default value of the input.
 * - `placeholder?: string`: The placeholder text for the input.
 * - `readOnly?: boolean`: Indicates whether the input is read-only.
 * - `type?: string`: The type of the input element.
 * - `children?: React.ReactNode`: The content to be rendered inside the input element.
 * - `value?: any`: The value of the input element.
 *
 * ## Usage
 *
 * ```tsx
 * import NumberInput from './NumberInput';
 *
 * const MyComponent = () => {
 *   return (
 *     <NumberInput
 *       value={10}
 *       onChange={(value) => console.log(value)}
 *     />
 *   );
 * };
 * ```
 */

import React from 'react';

import Input, {Props} from '../Input/Input.react';

export const NumberInput = (props: Props) => <Input type='number' {...props} />;

export default NumberInput;
