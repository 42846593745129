import React from 'react';
import PropTypes from 'prop-types';
import {upperFirst} from 'lib/stringUtils';
import {Map, fromJS, is} from 'immutable';
import {get} from 'lodash';
import {resource} from '@albert-io/json-api-framework/request/builder';
import Button from 'sg/Button/Button.react';
import Tabs from 'sg/Tabs/Tabs.react';
import {setUpStore, callAction, callTargetedAction} from 'client/framework';

import {AuthoringQuestionSetModelV1} from 'resources/GeneratedModels/AuthoringQuestionSet/AuthoringQuestionSetModel.v1';

import {addToast, Modal, Dialogue} from '@albert-io/atomic';
import {QUESTIONSET_STATUSES} from 'client/constants';
import questionEditorActions from 'client/EditPage/V2/QuestionEditor/QuestionEditor.actions';
import questionEditorStore from 'client/EditPage/V2/QuestionEditor/QuestionEditor.store';

import PracticeExamAlignmentList from './PracticeExamAlignmentList.react';
import GuideLevelAlignmentList from './GuideLevelAlignmentList.react';
import QuestionSetAlignmentStore from './QuestionSetAlignment.store';
import QuestionSetAlignmentActions from './QuestionSetAlignment.actions';

const guideTabs = fromJS([
  {
    name: 'Practice',
    type: 'practice'
  },
  {
    name: 'Free Response',
    type: 'free_response'
  },
  {
    name: 'Assessments',
    type: 'assessment'
  }
]);

function getQuestionSetQuery(questionSetId) {
  return resource('authoring_question_set_v1')
    .mandarkEndpoint(['authoring_question_sets_v1', questionSetId])
    .include('authoring_subject_v1.authoring_guides_v1.authoring_guide_levels_v1')
    .include('authoring_questions_v1')
    .include('authoring_guide_levels_v1')
    .include('sections_v1')
    .withMeta('authoring_guide_level_v1,authoring_guide_v1,authoring_question_set_v1')
    .meta({
      context: {
        // eslint-disable-next-line camelcase
        alignment_details: true
      }
    });
}

export class QuestionSetAlignmentModal extends React.Component {
  static propTypes = {
    questionSet: PropTypes.instanceOf(AuthoringQuestionSetModelV1),
    closeModal: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      activeGuide: this.getInitialActiveGuideType(),
      questionSetGuideLevelIds: this.getQuestionSetGuideLevelIds(),
      questionSetSectionId: props.questionSet.getSections().isEmpty()
        ? null
        : props.questionSet.getSections().first().getId(),
      questionSetExamId: props.questionSet.getAuthoringExam()
        ? props.questionSet.getAuthoringExam().getId()
        : null
    };
  }

  /* eslint camelcase: ["error", {allow: ["^UNSAFE_"]}] */
  UNSAFE_componentWillReceiveProps(nextProps) {
    const nextPropsGuideLevelIds = this.getQuestionSetGuideLevelIds(nextProps);
    if (is(this.state.questionSetGuideLevelIds, nextPropsGuideLevelIds) === false) {
      this.setState({
        questionSetGuideLevelIds: nextPropsGuideLevelIds
      });
    }
  }

  getStore() {
    return setUpStore(
      QuestionSetAlignmentStore,
      `QuestionSetAlignmentStore/${this.props.questionSet.getId()}`
    );
  }

  isExamTabActive() {
    return this.state.activeGuide === 'exam';
  }

  tabsContent = (() => {
    const subjectAuthoringGuideTypes = this.props.questionSet
      .getAuthoringSubject()
      .getAuthoringGuides()
      .reduce((acc, guide) => {
        acc.push(guide.getGuideType());
        return acc;
      }, []);
    const tabs = guideTabs
      .filter((tab) => subjectAuthoringGuideTypes.includes(tab.get('type')))
      .map((tab) =>
        Map({
          label: tab.get('name'),
          onClick: () =>
            this.setState({
              activeGuide: tab.get('type')
            }),
          defaultChecked: tab.get('type') === this.getInitialActiveGuideType()
        })
      );
    return tabs;
  })();

  getInitialActiveGuideType() {
    const {questionSet} = this.props;
    if (questionSet.getAuthoringExam()) {
      return 'exam';
    }
    const guideLevelId = questionSet.getAuthoringGuideLevels().isEmpty()
      ? null
      : questionSet
          .getAuthoringGuideLevels()
          .last() // I really just need _any_ guide level on the question set, so the last one will do
          .getId();

    if (guideLevelId) {
      return this.getAuthoringGuides()
        .find((guide) => {
          return guide.getAuthoringGuideLevels().some((level) => level.getId() === guideLevelId);
        })
        .getGuideType();
    }

    // If all of the set's questions are FRQ, show FRQ by default.
    const questions = questionSet.getAuthoringQuestions();
    const isFRQ =
      !questions.isEmpty() && questions.every((q) => q.getQuestionType() === 'free-response');

    return isFRQ ? 'free_response' : 'practice';
  }

  getAuthoringGuides() {
    return this.props.questionSet.getAuthoringSubject().getAuthoringGuides();
  }

  getQuestionSetGuideLevelIds(props = this.props) {
    return props.questionSet.getAuthoringGuideLevels().map((guideLevel) => guideLevel.getId());
  }

  saveAlignment = () => {
    callTargetedAction({
      name: QuestionSetAlignmentActions.SAVE_ALIGNMENT,
      targetStore: this.getStore().getName(),
      payload: this.props.questionSet.getId()
    });
    this.handlePostAlignmentChange();
  };

  removeAlignment = () => {
    callTargetedAction({
      name: QuestionSetAlignmentActions.REMOVE_ALIGNMENT,
      targetStore: this.getStore().getName(),
      payload: this.props.questionSet.getId()
    });
    this.handlePostAlignmentChange();
  };

  handlePostAlignmentChange() {
    this.getStore()
      .getSaveAlignmentPromise()
      .then(() => {
        addToast({
          color: 'positive',
          title: 'Question set alignment updated successfully!',
          message: 'Question set alignment updated successfully!'
        });
        questionEditorStore.getQuestionSetQuery().invalidateInterest();
        getQuestionSetQuery(this.props.questionSet.getId()).invalidateInterest();
        callAction(questionEditorActions.INITIALIZE_STORE);
      })
      .catch((err) => {
        const toastMessage = get(
          err,
          'response.body.errors[0].detail',
          'There was a problem aligning your question'
        );
        addToast({
          color: 'negative',
          title: 'Something went wrong',
          message: upperFirst(toastMessage)
        });
        logger.error('There was an error aligning a question set: ', err.message || err);
      })
      .finally(() => {
        this.props.closeModal();
      });
  }

  getActiveGuide() {
    return this.getAuthoringGuides().find(
      (guide) => this.state.activeGuide === guide.getGuideType()
    );
  }

  isRemoveAlignmentDisabled() {
    return [QUESTIONSET_STATUSES.PUBLISHED, QUESTIONSET_STATUSES.IN_REVIEW].includes(
      this.props.questionSet.getStatus()
    );
  }

  render() {
    const alignmentList = this.isExamTabActive() ? (
      <PracticeExamAlignmentList
        store={this.getStore()}
        exams={this.props.questionSet.getAuthoringSubject().getAuthoringExams()}
        questionSetSectionId={this.state.questionSetSectionId}
        questionSetExamId={this.state.questionSetExamId}
      />
    ) : (
      <GuideLevelAlignmentList
        store={this.getStore()}
        guide={this.getActiveGuide()}
        questionSetGuideLevelIds={this.state.questionSetGuideLevelIds}
      />
    );
    return (
      <Modal
        ariaLabel='question alignment'
        className='question-set-alignment-modal'
        handleClose={this.props.closeModal}
      >
        {({modalContentStyle}) => (
          <Dialogue size='xl' className={modalContentStyle} handleClose={this.props.closeModal}>
            <Dialogue.Title>Question Set Alignment</Dialogue.Title>
            <Dialogue.Body>
              <Tabs content={this.tabsContent} />
              {alignmentList}
              <div className='question-set-alignment-modal__actions-wrapper'>
                <Button
                  text='Remove Alignment'
                  color='gray'
                  disabled={this.getStore().isSavePending() || this.isRemoveAlignmentDisabled()}
                  disabledTooltipContent={
                    this.isRemoveAlignmentDisabled()
                      ? 'You can not remove the alignment of questions that are in review or published.'
                      : null
                  }
                  onClick={this.removeAlignment}
                />
                <Button
                  text='Save'
                  color='green'
                  disabled={this.getStore().isSavePending()}
                  onClick={this.saveAlignment}
                />
              </div>
            </Dialogue.Body>
          </Dialogue>
        )}
      </Modal>
    );
  }
}
