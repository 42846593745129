import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {Card, Button, Heading, Text} from '@albert-io/atomic';

import './marketing-banner.scss';

const MarketingBanner = ({buttonText, headingText, text, className, buttonProps}) => {
  return (
    <Card className={classnames('marketing-banner', className)}>
      <div className='marketing-banner_text-block'>
        <Text className='marketing-banner_text-block--text' color='primary-inverse'>
          {text}
        </Text>
        <Heading color='primary-inverse' className='marketing-banner_text-block--heading u-mar-t_1'>
          {headingText}
        </Heading>
        <Button {...buttonProps} className='marketing-banner_text-block--button u-mar-t_3'>
          {buttonText}
        </Button>
      </div>
    </Card>
  );
};

MarketingBanner.propTypes = {
  buttonText: PropTypes.string,
  headingText: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  buttonProps: PropTypes.object
};

export default MarketingBanner;
