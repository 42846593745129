import React, {useCallback} from 'react';
import classnames from 'classnames';
import {capitalize} from 'lodash';
import Checkbox from 'generic/Forms/Checkbox/Checkbox.react';
import SupplementManagerModalButton from 'client/Supplements/SupplementManager/SupplementManagerModalButton.react';
import {AuthoringQuestionModelV1} from 'resources/augmented/AuthoringQuestion/AuthoringQuestionModel.v1';
import AuthoringTextInput from 'client/EditPage/V2/AuthoringTextInput/AuthoringTextInput.react';
import {useQuestionEditorV2Store} from 'client/EditPage/V2/QuestionEditorV2Store';
import {useShallow} from 'zustand/react/shallow';
import {PRIMARY_CONTENT_LANGUAGE} from 'client/EditPage/V2/QuestionEditorV2Store.types';

interface Props {
  rowIndex: number;
  side: 'left' | 'right';
  column: any;
  question: AuthoringQuestionModelV1;
  onBlur: (e: any) => void;
  onModelChange: (question: AuthoringQuestionModelV1, ...fields: string[]) => void;
}

const TwoWayRowColumn = ({rowIndex, side, column, question, onBlur, onModelChange}: Props) => {
  const {currentLanguage, updateTranslatedQuestionField, translatedText} = useQuestionEditorV2Store(
    useShallow((state) => ({
      currentLanguage: state.currentLanguage,
      updateTranslatedQuestionField: state.updateTranslatedQuestionField,
      translatedText:
        state
          .currentTranslatedQuestion()
          ?.translated_fields?.find(
            (field) => field.field === `rows.${rowIndex}.columns.${side}.text`
          )?.text || ''
    }))
  );

  const isPrimaryLanguage = currentLanguage === PRIMARY_CONTENT_LANGUAGE;

  const onColumnTextChange = useCallback(
    (e) => {
      if (isPrimaryLanguage) {
        const updatedColumn = column.set('text', e.target.value);
        const rows = question.getRows().setIn([rowIndex, 'columns', side], updatedColumn);

        const updatedQuestion = question.setRows(rows);
        onModelChange(updatedQuestion);
      } else {
        updateTranslatedQuestionField(
          currentLanguage,
          `rows.${rowIndex}.columns.${side}.text`,
          'text',
          e.target.value
        );
      }
    },
    [
      column,
      question,
      rowIndex,
      side,
      onModelChange,
      isPrimaryLanguage,
      currentLanguage,
      updateTranslatedQuestionField
    ]
  );

  const setColumnCorrect = useCallback(
    (e) => {
      let validResponse = question.getValidResponse();
      if (e.target.checked) {
        validResponse = validResponse.set(column.get('id'), true);
      } else {
        validResponse = validResponse.remove(column.get('id'));
      }

      const updatedQuestion = question.setValidResponse(validResponse);
      onModelChange(updatedQuestion, 'rubric');
    },
    [column, question, onModelChange]
  );

  const generateColumnHeader = () => {
    if (rowIndex !== 0) {
      return null;
    }

    const columnNumber = side === 'left' ? 1 : 2;
    return (
      <div className='a-form-input__label two-way-editor__column-header'>Column {columnNumber}</div>
    );
  };

  const capitalizedSide = capitalize(side);
  const isChecked = question.getValidResponse().has(column.get('id'));
  const columnClasses = classnames(`two-way-editor__row-input two-way-editor__row-input--${side}`, {
    'is-correct-choice': isChecked
  });
  const fieldPath = `rows,${rowIndex},columns,${side},text`;

  const textValue = isPrimaryLanguage ? column.get('text') : translatedText;

  return (
    <div className='two-way-editor__column'>
      {generateColumnHeader()}
      <div className={`two-way-editor__row two-way-editor__row--${side}`}>
        <Checkbox
          className={`two-way-editor__row-checkbox two-way-editor__row-checkbox--${side}`}
          checked={isChecked}
          onChange={setColumnCorrect}
          disabled={!isPrimaryLanguage}
        />
        <AuthoringTextInput
          displayAs='input'
          className={columnClasses}
          value={textValue}
          onChange={onColumnTextChange}
          onBlur={onBlur}
          data-field-path={fieldPath}
          name={`row${capitalizedSide}`}
        />
        {isPrimaryLanguage && (
          <SupplementManagerModalButton
            className={`two-way-editor__add-supplement-btn two-way-editor__row-add-supplement-btn two-way-editor__row-add-supplement-btn--${side}`}
            color='blue'
            linkButton
            pathToProperty={fieldPath}
          />
        )}
      </div>
    </div>
  );
};

export default TwoWayRowColumn;
