// @flow
import {fromJS, Map} from 'immutable';
import moment from 'moment';

function getNextOccurenceOfDate(month: number, date: number): Date {
  const now = moment();
  const thisYear = new Date(now.year(), month, date);
  const nextYear = new Date(now.year() + 1, month, date);
  return thisYear > now ? thisYear : nextYear;
}

function getLastOccurenceOfDate(month: number, date: number): Date {
  const now = moment();
  const thisYear = new Date(now.year(), month, date);
  const lastYear = new Date(now.year() - 1, month, date);
  return now < thisYear ? lastYear : thisYear;
}

function getThisSchoolYear(): Map<string, *> {
  return Map({
    startDate: moment(getLastOccurenceOfDate(6, 1)), // July 1
    endDate: moment(getNextOccurenceOfDate(5, 30)) // June 30
  });
}

function getLastSchoolYear(): Map<string, *> {
  return Map({
    startDate: moment(getLastOccurenceOfDate(6, 1)).subtract(1, 'year'),
    endDate: moment(getLastOccurenceOfDate(5, 30))
  });
}

export const RANGES = {
  ALL_TIME: 'All-time',
  THIS_WEEK: 'This week',
  THIS_MONTH: 'This month',
  LAST_WEEK: 'Last week',
  LAST_MONTH: 'Last month',
  THIS_SCHOOL_YEAR: 'This school year',
  LAST_SCHOOL_YEAR: 'Last school year',
  CUSTOM: 'Custom'
};

export const dateRangeOptionsWithoutAllTime = fromJS([
  {
    name: RANGES.THIS_WEEK,
    end_date: moment()
      .endOf('week')
      .format('YYYY-MM-DD'),
    id: RANGES.THIS_WEEK,
    start_date: moment()
      .startOf('week')
      .format('YYYY-MM-DD')
  },
  {
    name: RANGES.THIS_MONTH,
    end_date: moment()
      .endOf('month')
      .format('YYYY-MM-DD'),
    id: RANGES.THIS_MONTH,
    start_date: moment()
      .startOf('month')
      .format('YYYY-MM-DD')
  },
  {
    name: RANGES.LAST_WEEK,
    end_date: moment()
      .subtract(1, 'week')
      .startOf('week')
      .add(6, 'days')
      .endOf('day')
      .format('YYYY-MM-DD'),
    id: RANGES.LAST_WEEK,
    start_date: moment()
      .subtract(1, 'week')
      .startOf('week')
      .format('YYYY-MM-DD')
  },
  {
    name: RANGES.LAST_MONTH,
    end_date: moment()
      .subtract(1, 'months')
      .endOf('month')
      .format('YYYY-MM-DD'),
    id: RANGES.LAST_MONTH,
    start_date: moment()
      .subtract(1, 'months')
      .startOf('month')
      .format('YYYY-MM-DD')
  },
  {
    name: RANGES.THIS_SCHOOL_YEAR,
    end_date: getThisSchoolYear()
      .get('endDate')
      .format('YYYY-MM-DD'),
    id: RANGES.THIS_SCHOOL_YEAR,
    start_date: getThisSchoolYear()
      .get('startDate')
      .format('YYYY-MM-DD')
  },
  {
    name: RANGES.LAST_SCHOOL_YEAR,
    end_date: getLastSchoolYear()
      .get('endDate')
      .format('YYYY-MM-DD'),
    id: RANGES.LAST_SCHOOL_YEAR,
    start_date: getLastSchoolYear()
      .get('startDate')
      .format('YYYY-MM-DD')
  }
]);

export const dateRangeOptions = dateRangeOptionsWithoutAllTime.unshift(
  Map({
    name: RANGES.ALL_TIME,
    end_date: '',
    start_date: '',
    id: RANGES.ALL_TIME
  })
);
