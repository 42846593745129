import {fromJS} from 'immutable';
import fillInTheBlankQuestionActions from './FillInTheBlankQuestion.actions';
import QuestionTypeStore from 'client/QuestionTypes/common/V2/QuestionType.store';
import activeQuestionActions from 'client/InteractionEngineV2/shared/ActiveQuestion/ActiveQuestionActions';
import activeQuestionStore from 'client/InteractionEngineV2/shared/ActiveQuestion/ActiveQuestionStore';

export default class FillInTheBlankQuestionStore extends QuestionTypeStore {
  constructor(name, questionId, question) {
    super(name);

    this.initialData = fromJS({
      questionId: questionId,
      selectedOptions: {},
      showExplanation: false
    });

    this.setInitialData(this.initialData);
    this.writeData('__question', question);
    this.handleTargeted(activeQuestionActions.ACTIVE_QUESTION_LOAD_GUESS, this._loadGuess);
    this.handleTargeted(fillInTheBlankQuestionActions.SELECT_OPTION, this._selectOption);
  }

  _selectOption({choiceId, dropdownId}) {
    const selections = this.readData('selectedOptions');
    this.writeData('selectedOptions', selections.set(dropdownId, choiceId));
  }

  _loadGuess(guess) {
    // Parent store needs points data from guess and needs to register this question has a guess
    super._loadGuess(guess);
    this.writeData('selectedOptions', guess.get('content'));
  }

  _clearGuess() {
    // Need to call this on the parent class
    super._clearGuess();
    this.writeData('selectedOptions', this.initialData.get('selectedOptions'));
  }

  buildGuessContent() {
    return this.selectedOptions.toJS();
  }

  getSelectedChoiceForDropdown(dropdownId) {
    return this.readData('selectedOptions').get(dropdownId);
  }

  isSelectedOption(id) {
    return this.readData('selectedOptions').includes(id);
  }

  isChosenOption(choiceId, dropdownId) {
    const selectedOptions = this.readData('selectedOptions');
    const selectedOption = selectedOptions.get(dropdownId);
    return selectedOption === choiceId;
  }

  isCorrectOption(choiceId, dropdownId) {
    const validResponse = this.validResponse;
    const correctChoiceId = validResponse.get(dropdownId);
    return correctChoiceId === choiceId;
  }

  get correctAnswer() {
    return this.validResponse;
  }

  get selectedAnswer() {
    return this.readData('selectedOptions').toSet();
  }

  get selectedOptions() {
    return this.readData('selectedOptions');
  }

  get hasUserSelectedAnswer() {
    return activeQuestionStore.question.getDropdowns().size === this.selectedOptions.size;
  }
}
