/* eslint-disable import/order */
import React, {lazy} from 'react';
import {Route, IndexRoute, Redirect} from 'react-router';

import {resumeSession} from '@albert-io/mandark/authentication';

import {TranslationSandbox} from 'client/TranslationSandbox/TranslationSandbox';

import {callAction} from 'client/framework';
import {appActions} from './AppActions';
import {getFeatureFlagStatus} from 'client/components/FeatureFlag/FeatureFlag.react';
import App from './App.react';
import constants from 'client/constants';
import {requireSessionWithLoginPrompt} from 'lib/decorators/requireSession';
import {withApplicationMetadata} from './Routing/withApplicationMetadata';

import ExpiredSessionPage from './static/ExpiredSession/ExpiredSessionPage.react';
import ErrorPage from './static/Error/ErrorPage.react';
import SoftwareConsiderations from './static/SoftwareConsiderations/SoftwareConsiderations.react';
import Register from 'client/static/UserActions/Register/Register.react';

import schoolAdministrationRoutes from './SchoolAdministration/routes';
import createAssignmentRoutes from './CreateAssignmentV2/routes';
import classesRoutes from './Classrooms/routes';
import {DennisRoutes} from './Dennis/dynamic.routes';
import {AuthoringRoutes} from './Authoring/V2/dynamic.routes';
import ContentDiscoveryRoutes from 'client/components/ContentDiscovery/routes';
import reportsRoutes from './Reports/routes';
import {MarketplaceRoutes} from './Marketplace/dynamic.routes';

import sessionStore from 'client/Session/SessionStore';
import {getUserTypeRedirectTarget} from 'client/User/UserRedirectUtil';

import {ieTypes} from './InteractionEngineV2/interactionEngineConstants';

import {queryTypes} from 'client/SubjectPracticeView/subjectPracticeView.queries';
import {singleQuestionPath} from './SubjectPracticeView/subjectPracticeView.utils';

import {bootstrapSubjectViewQuery, SubjectView} from './SubjectView/V2/SubjectView.react';
import ForgotPassword from './ForgotPassword/ForgotPassword.react';
import SignUpPage from './SignUp/SignUpPage.react';
import LogInPage from './LogIn/LogInPage.react';
import ResetPassword from './ResetPassword/ResetPassword.react';
import ConfirmEmail from 'client/static/UserActions/ConfirmEmail/ConfirmEmail.react';
import Coppa from 'client/Coppa/Coppa.react';
import SocialLanding from './Social/Landing.react';
import CourseLibrary from './CourseLibrary/CourseLibrary.react';
import GlobalAssignments from 'client/Assignments/GlobalAssignments/GlobalAssignments.react';

/**
 * Interaction Engine
 */
import InteractionEngine from './InteractionEngineV2/InteractionEngine.react';
import StudyGuide from './StudyGuide/StudyGuide.react';
import FreeResponseQuestionGuide from './FreeResponseQuestion/FreeResponseQuestionGuide/V2/FreeResponseQuestionGuide.react';
import SubjectFAQList from './SubjectFAQList/SubjectFAQList.react';
import SubjectTours from 'client/SubjectTours/SubjectTours.react';
import FRQViewV2 from 'client/FreeResponseQuestion/FreeResponseQuestionView/FRQViewV2.react';

import SubjectPracticeView from './SubjectPracticeView/SubjectPracticeView.react';

const Account = lazy(() => import('client/AccountSettings/index.react'));
const AccountProfile = lazy(() =>
  import('client/AccountSettings/AccountProfile/AccountProfile.react')
);
const Subscriptions = lazy(() =>
  import('client/AccountSettings/Subscriptions/Subscriptions.react')
);
const PasswordAndSettings = lazy(() =>
  import('client/AccountSettings/PasswordAndSettings/PasswordAndSettings.react')
);
const DeactivateAccount = lazy(() =>
  import('client/AccountSettings/DeactivateAccount/DeactivateAccount.react')
);

const UserDashboard = lazy(() => import('./UserDashboard/UserDashboard.react'));

const StudentDetailView = lazy(() => import('./Student/StudentDetail.react'));

const Folders = lazy(() => import('./Folders/V2/Folders.react'));
const AssignmentDrafts = lazy(() => import('client/AssignmentDrafts/AssignmentDrafts.react'));

const TeacherAssignmentPracticeView = lazy(() =>
  import(
    'client/Classrooms/Classroom/Teacher/Assignments/TeacherAssignmentPracticeView/TeacherAssignmentPracticeView.react'
  )
);
const ViewStudentSubmission = lazy(() =>
  import(
    'client/Classrooms/Classroom/Teacher/Assignments/ViewStudentSubmission/ViewStudentSubmission.react'
  )
);

/**
 * Practice Views
 */
const AssignmentPracticeView = lazy(() =>
  import('./AssignmentPracticeView/AssignmentPracticeView.react')
);
const FolderPracticeView = lazy(() => import('./FolderPracticeView/FolderPracticeView.react'));

const RequireSessionInteractionEngine = requireSessionWithLoginPrompt()(InteractionEngine);

const Sandbox = lazy(() => import(/* webpackChunkName: "Sandbox" */ './Sandbox/Sandbox.react'));
const GeneratedStories = lazy(() =>
  import(/* webpackChunkName: "Stories" */ './GeneratedStories/GeneratedStories.react')
);

const requireGlobalAssignmentsFeatureFlag = async (_, replace, cb) => {
  if (!sessionStore.hasValidSession()) {
    replace('/log-in');
  } else {
    const isGlobalAssignmentsEnabled = await getFeatureFlagStatus('global_assignments');
    if (!isGlobalAssignmentsEnabled) {
      replace('/classes');
    }
  }
  cb();
};

class RemovedFeatureMessage extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <div className='card' style={{maxWidth: '600px', margin: '0 auto'}}>
        <b>This page has been taken down because we are no longer supporting this feature.</b>
        <p style={{margin: '1em 0'}}>
          We&apos;re sorry for any inconvenience this may cause. Don&apos;t worry, we will still be
          putting out lots of other great features to improve your Albert experience.
        </p>
        <p>
          If you have questions or concerns, please email us at{' '}
          <a href='mailto:hello@albert.io'>hello@albert.io</a>.
        </p>
      </div>
    );
  }
}

function redirectOnEnterIfAuthenticated(nextState, replace, callback) {
  if (sessionStore.hasValidSession()) {
    getUserTypeRedirectTarget().then((redirectPath) => {
      replace(redirectPath);
      callback();
    });
  } else {
    callback();
  }
}

/**
 * @todo this should be in a seperate `InteractionEngine/routes` file.
 */
export const IE_EXAMS = {
  /**
   * The `path` used for the `react-router` `<Route>`
   */
  PATH: `/assessments/:assignmentId`,
  OLD_PATH: `/practice-exams/:assignmentId`,
  /**
   * Returns the path for a practice exam using `IE_EXAMS.PATH`
   *
   * @param {string} id
   */
  getRouteForExam(id) {
    return this.PATH.replace(':assignmentId', id);
  },

  TEACHER_PATH: `/assignment-results/:assignmentId`,

  getTeacherRouteForExam(id) {
    return this.TEACHER_PATH.replace(':assignmentId', id);
  }
};

function updateGlobalRouterProps(routerProps, prevRouterProps = {}) {
  callAction(appActions.SET_ROUTER_PROPS, {routerProps, prevRouterProps});
  const zone = routerProps.location.pathname.startsWith('/dennis')
    ? constants.ZONES.DENNIS
    : constants.ZONES.ALBERT;
  callAction(appActions.SET_ZONE, zone);
}

export default function Routes() {
  return (
    <Route
      component={App}
      path='/'
      onEnter={async (nextState, replace, callback) => {
        updateGlobalRouterProps(nextState);
        if (nextState.location.query.state === 'error') {
          callback();
          return;
        }
        await resumeSession();
        callback();
      }}
      onChange={(prevState, nextState) => {
        updateGlobalRouterProps(nextState, prevState);
      }}
    >
      <Route component={ExpiredSessionPage} path='session/expired' />

      {MarketplaceRoutes}

      <Route component={Register} path='register' />

      {/* Assignments */}
      <Route
        path='assignments'
        component={GlobalAssignments}
        onEnter={requireGlobalAssignmentsFeatureFlag}
      />
      <Route path='saved' component={Folders} withWhiteBackground />
      <Route path='drafts' component={AssignmentDrafts} withWhiteBackground />
      <Redirect from='assignment/folders/:assignmentId' to='folder/:assignmentId' />
      <Redirect from='assignment/templates/:assignmentId' to='folder/:assignmentId' />
      <Route
        getComponent={(nextState, cb) => {
          cb(
            null,
            sessionStore.isStudent()
              ? AssignmentPracticeView
              : requireSessionWithLoginPrompt()(TeacherAssignmentPracticeView)
          );
        }}
        ieType={ieTypes.ASSIGNMENT}
        path='assignment/:assignmentId'
      />
      <Route
        path='classes/:classroomId/assignments/:assignmentId/student-detail/:studentId/view'
        component={ViewStudentSubmission}
      />
      <Route
        path='classes/:classroomId/assignments/:assignmentId/student-detail/:studentId/question/:questionId/view'
        component={ViewStudentSubmission}
      />

      {/* Interaction Engine */}
      <Route
        path={IE_EXAMS.PATH}
        component={RequireSessionInteractionEngine}
        ieType={ieTypes.ASSIGNMENT_V2}
      />
      <Redirect from={IE_EXAMS.OLD_PATH} to={IE_EXAMS.PATH} />
      <Route
        path={IE_EXAMS.TEACHER_PATH}
        component={RequireSessionInteractionEngine}
        ieType={ieTypes.TEACHER_ASSIGNMENT_V2}
      />

      {ContentDiscoveryRoutes}

      {/* Folder */}
      <Route
        path='/folder/:folderId'
        component={requireSessionWithLoginPrompt()(FolderPracticeView)}
      />

      {/* Subject Practice View */}
      <Route
        path={singleQuestionPath}
        queryType={queryTypes.singleQuestion}
        component={SubjectPracticeView}
        onEnter={(nextState, replace) => {
          if (!nextState.params.questionSlug) {
            replace('/error');
          }
        }}
      />
      <Route
        isTopicSummary
        path='/learn/:subjectSlug/:guideLevelSlug/topic-summary'
        queryType={queryTypes.guideLevel}
        component={SubjectPracticeView}
      />
      <Route
        path='/learn/:subjectSlug/:guideLevelSlug'
        queryType={queryTypes.guideLevel}
        component={SubjectPracticeView}
      />
      <Route
        path='/learn/:subjectSlug/:guideLevelSlug/:questionSetSlug(/:questionSlug)'
        queryType={queryTypes.guideLevel}
        component={SubjectPracticeView}
      />

      <Route path='software-considerations' component={SoftwareConsiderations} />

      <Route component={ConfirmEmail} path='confirm-email' />
      <Route component={ResetPassword} path='reset-password' withWhiteBackground />

      <Route
        component={withApplicationMetadata(RemovedFeatureMessage)}
        path='leaderboard'
        title='Leaderboard | Albert'
      />

      <Route component={UserDashboard} path='dashboard' withWhiteBackground />
      <Route component={StudentDetailView} path='dashboard/:studentId/:subjectId' />

      {schoolAdministrationRoutes}
      {/* Assignment Creation Form */}
      {createAssignmentRoutes}
      {/* Classes */}
      {classesRoutes}
      {/* Analytics Reports */}
      {reportsRoutes}

      {/* My Account */}
      <Route path='account' name='account' component={Account}>
        <Route path='profile' name='account-profile' component={AccountProfile} />
        <Route path='access' name='account-access' component={Subscriptions} />
        <Route path='settings' name='account-settings' component={PasswordAndSettings} />
      </Route>
      <Route path='account/deactivate' name='account-deactivate' component={DeactivateAccount} />
      {/* My Account End */}

      {/* Course Library Routes */}
      <Route path='subjects' component={CourseLibrary} />
      <Route path='subjects/discover' component={CourseLibrary} content='discover' />
      <Redirect from='subjects/free' to='subjects/discover' />
      <Route path='subjects/class-subjects' component={CourseLibrary} content='class-subjects' />
      <Route path='/subjects/high-school(/:group(/:subgroup))' component={CourseLibrary} />
      <Route path='/subjects/middle-school(/:group(/:subgroup))' component={CourseLibrary} />
      <Route path='/subjects/college(/:group(/:subgroup))' component={CourseLibrary} />
      <Route path='/subjects/professional(/:group(/:subgroup))' component={CourseLibrary} />
      {/* Course Library Routes End */}

      {AuthoringRoutes}
      {DennisRoutes}

      <Redirect from='batch' to='dennis/batch' />
      <Redirect from='batch/:a' to='dennis/batch/:a' />
      <Redirect from='batch/:a/:b' to='dennis/batch/:a/:b' />

      <Route path='social' component={SocialLanding} />
      <Route
        name='signup'
        path='signup'
        component={SignUpPage}
        onEnter={redirectOnEnterIfAuthenticated}
      />
      <Route path='log-in' component={LogInPage} onEnter={redirectOnEnterIfAuthenticated} />
      <Route path='coppa/confirmation' component={Coppa} />
      <Route path='forgot-password' component={ForgotPassword} withWhiteBackground />

      <Route path='error' component={ErrorPage} />
      <Route path='translation-sandbox' component={TranslationSandbox} />

      <Route path='sandbox'>
        <IndexRoute component={Sandbox} />
      </Route>
      <Route component={GeneratedStories} path='storybook(/:atomicLevel(/:component(/:variant)))' />

      {/* These need to be outside of the subject-page component so we don't render the 'subject-view-top-header' */}
      <Route
        path=':slug/assessments/edit(/:practiceExamId)'
        component={InteractionEngine}
        ieType={ieTypes.PRACTICE_EXAM_EDITOR}
      />
      <Redirect
        from=':slug/practice-exams/edit(/:practiceExamId)'
        to=':slug/assessments/edit(/:practiceExamId)'
      />
      <Route
        path=':slug/assessments/:practiceExamId'
        component={InteractionEngine}
        ieType={ieTypes.PRACTICE_EXAM}
      />
      <Redirect
        from=':slug/practice-exams/:practiceExamId'
        to=':slug/assessments/:practiceExamId'
      />
      <Route
        name='subject-page'
        path=':slug'
        component={SubjectView}
        onEnter={async (nextState, replace, callback) => {
          const {slug} = nextState.params;
          let redirect;
          const routes = {
            assessment: `/${slug}/assessments`,
            free_response: `/${slug}/free-response`
          };
          try {
            const subject = await bootstrapSubjectViewQuery(nextState).getResourcePromise();
            const subjectGuideTypes = subject.getGuideTypes();

            if (
              !nextState.location.pathname.includes(`/${slug}/overview`) &&
              !subjectGuideTypes.includes(constants.GUIDE_TYPES.PRACTICE)
            ) {
              if (subjectGuideTypes.includes(constants.GUIDE_TYPES.FREE_RESPONSE)) {
                redirect = routes.free_response;
              } else if (subjectGuideTypes.includes(constants.GUIDE_TYPES.ASSESSMENT)) {
                redirect = routes.assessment;
              }
            }
          } catch (err) {
            logger.error('Subject not found: %s', err.message);
          }
          let shouldRedirect = false;
          shouldRedirect =
            !!redirect &&
            !nextState.location.pathname.includes(redirect) &&
            !nextState.location.pathname.includes(`/${slug}/overview`);

          if (shouldRedirect) {
            replace(redirect);
          }
          callback();
        }}
      >
        <IndexRoute name='study-guide' component={StudyGuide} withWhiteBackground />
        <Route path='free-response' component={FreeResponseQuestionGuide} withWhiteBackground />
        <Route path='assessments' component={StudyGuide} withWhiteBackground />
        <Redirect from='practice-exams' to='assessments' />
        <Redirect from='faq' to='overview' />
        <Route name='subject-overview' path='overview' component={SubjectFAQList} />
        <Route name='subject-tours' path='tours' component={SubjectTours} />
        <Route
          name='free-response-view'
          path='free-response/:set(/:question)'
          component={FRQViewV2}
        />
      </Route>

      <Route name='error-page' path='*' component={ErrorPage} />
    </Route>
  );
}
