import React from 'react';
import PropTypes from 'prop-types';
import makeConstants from 'lib/makeConstants';

const alignOptions = makeConstants('left', 'center', 'right');

export default class Column extends React.PureComponent {
  /* eslint-disable react/no-unused-prop-types */
  static propTypes = {
    align: PropTypes.oneOf(Object.values(alignOptions)),
    className: PropTypes.string,
    content: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    defaultSortParam: PropTypes.bool,
    heading: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    isActionMenu: PropTypes.bool,
    primary: PropTypes.bool,
    sortable: PropTypes.bool,
    noClamp: PropTypes.bool,
    sortBy: PropTypes.string,
    subValue: PropTypes.func,
    // use to override default sort direction at the table level (e.g. asc for strings vs desc for dates)
    defaultColumnSortDirection: PropTypes.string
  };

  static defaultProps = {
    align: 'left',
    content: () => {},
    primary: false,
    sortable: false,
    subValue: () => {}
  };
}
