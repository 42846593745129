/* eslint-disable import/order */
import {Map} from 'immutable';
import {
  GenericListCollectionModel,
  GenericMapCollectionModel
} from './Generic/GenericCollection.model';

import {AssignmentModelV2} from './Assignment/V2/Assignment.model';
import {StudentAssignment as StudentAssignmentModelV1} from 'resources/GeneratedModels/StudentAssignment/StudentAssignmentModel.v1';
import {studentAssignmentModelExtensions} from './extensions/StudentAssignmentModel.extensions';
import {StudentClassroomModelV1} from 'resources/GeneratedModels/StudentClassroom/StudentClassroomModel.v1';
import {AuthoringGuideModelV1} from 'resources/augmented/AuthoringGuide/AuthoringGuideModel.v1';
import {AuthoringGuideLevelModelV1} from 'resources/augmented/AuthoringGuideLevel/AuthoringGuideLevelModel.v1';
import {EmailDomainModelV1} from 'resources/GeneratedModels/EmailDomain/EmailDomainModel.v1';
import {LabelModelV1} from 'resources/GeneratedModels/Label/LabelModel.v1';
import {GuideLevel} from './GuideLevel/GuideLevel.model';
import {GuideLevelModelV2} from 'resources/augmented/GuideLevel/GuideLevelModel.v2';
import {GuideModelV1} from 'resources/augmented/Guide/GuideModel.v1';
import {getQuestionModel} from './Question/getQuestionModel';
import {ClassroomModelV1} from 'resources/augmented/Classroom/ClassroomModel.v1';
import {ClassroomInvitationModelV1} from 'resources/GeneratedModels/ClassroomInvitation/ClassroomInvitationModel.v1';
import {CleverCourseSyncModelV1} from 'resources/GeneratedModels/CleverCourseSync/CleverCourseSyncModel.v1';
import {CoppaConsentGrantModelV1} from 'resources/GeneratedModels/CoppaConsentGrant/CoppaConsentGrantModel.v1';
import {ManagedUserModelV1} from 'resources/GeneratedModels/ManagedUser/ManagedUserModel.v1';
import {MediaModelV1} from 'resources/GeneratedModels/Media/MediaModel.v1';
import {TeacherModelV1} from 'resources/augmented/Teacher/TeacherModel.v1';
import {SearchQuestionModelV1} from 'resources/GeneratedModels/SearchQuestion/SearchQuestionModel.v1';
import {SearchQuestionModelV2} from 'resources/GeneratedModels/SearchQuestion/SearchQuestionModel.v2';
import {SearchStandardModelV1} from 'resources/GeneratedModels/SearchStandard/SearchStandardModel.v1';
import {SearchStandardModelV2} from 'resources/GeneratedModels/SearchStandard/SearchStandardModel.v2';
import {SearchTagModelV1} from 'resources/GeneratedModels/SearchTag/SearchTagModel.v1';
import {SubjectModel} from './Subject/Subject.model';
import {SubjectFaqModelV1} from 'resources/GeneratedModels/SubjectFaq/SubjectFaqModel.v1';
import {SubscriptionModelV1} from 'resources/augmented/Subscription/SubscriptionModel.v1';
import {StudentModel} from './Student/Student.model';
import {SchoolLicenseSubjectGroupModelV1} from 'resources/GeneratedModels/SchoolLicenseSubjectGroup/SchoolLicenseSubjectGroupModel.v1';
import {StudentsListCollectionModel} from './Student/StudentsListCollection.model';
import {GuideLevelsCollectionModel} from './GuideLevel/GuideLevelsCollection.model';
import {SchoolModelV2} from './School/V2/School.model';
import {SchoolModelV4} from 'resources/GeneratedModels/School/SchoolModel.v4';
import {SchoolModelV5} from 'resources/augmented/School/SchoolModel.v5';
import {schoolExtensions} from 'resources/augmented/School/school.extensions';
import {SchoolLicenseModelV1} from 'resources/GeneratedModels/SchoolLicense/SchoolLicenseModel.v1';
import {SchoolPersonnelModelV1} from 'resources/augmented/SchoolPersonnel/SchoolPersonnelModel.v1';
import {TagModelV1} from 'resources/GeneratedModels/Tag/TagModel.v1';
import {SchoolPersonnelInvitationModelV1} from 'resources/GeneratedModels/SchoolPersonnelInvitation/SchoolPersonnelInvitationModel.v1';
import {GoogleCourseModel} from './GoogleCourse/GoogleCourse.model';
import {UserModelV1} from 'resources/GeneratedModels/User/UserModel.v1';
import {UserModelV2} from 'resources/augmented/User/UserModel.v2';
import {ExamModelV1} from 'resources/augmented/Exam/ExamModel.v1';
import {AuthoringFeedbackModelV1} from 'resources/GeneratedModels/AuthoringFeedback/AuthoringFeedbackModel.v1';
import {AuthoringQuestionModelV1} from './augmented/AuthoringQuestion/AuthoringQuestionModel.v1';
import {SubjectModelV2} from 'resources/augmented/Subject/SubjectModel.v2';
import {AuthorModelV1} from 'resources/augmented/Author/AuthorModel.v1';
import {QuestionSetModelV1} from 'resources/augmented/QuestionSet/QuestionSetModel.v1';
import {AuthoringQuestionSetModelV1} from 'resources/augmented/QuestionSet/AuthoringQuestionSetModel.v1';
import {StudentModelV2} from 'resources/augmented/Student/StudentModel.v2';
import {TemplateModelV1} from 'resources/GeneratedModels/Template/TemplateModel.v1';
import {templateModelExtensions} from './extensions/template.extensions';
import {AssignmentModelV3} from './augmented/Assignment/AssignmentModel.v3';
import {AuthoringSupplementModelV1} from './augmented/AuthoringSupplement/AuthoringSupplementModel.v1';
import {SupplementModelV1} from './augmented/Supplement/SupplementModel.v1';
import {AuthoringSubjectModelV1} from './augmented/AuthoringSubject/AuthoringSubjectModel.v1';
import {AuthoringLabelModelV1} from 'resources/GeneratedModels/AuthoringLabel/AuthoringLabelModel.v1';
import {SectionModelV1} from './augmented/Section/SectionModel.v1';
import {SubjectAuthorPermissionModelV1} from './GeneratedModels/SubjectAuthorPermission/SubjectAuthorPermissionModel.v1';
import {UserFeedbackModelV1} from 'resources/GeneratedModels/UserFeedback/UserFeedbackModel.v1';
import {CurriculumAreaModelV1} from 'resources/GeneratedModels/CurriculumArea/CurriculumAreaModel.v1';
import {GuessModelV1} from 'resources/augmented/Guess/GuessModel.v1';
import {QuestionModelV3} from 'resources/augmented/Question/QuestionModel.v3';
import {DistrictModelV1} from 'resources/GeneratedModels/District/DistrictModel.v1';
import {RoleModelV1} from 'resources/GeneratedModels/Role/RoleModel.v1';
import {StandardModelV1} from 'resources/GeneratedModels/Standard/StandardModel.v1';
import {StandardSetModelV1} from 'resources/GeneratedModels/StandardSet/StandardSetModel.v1';
import {StandardSetCreatorModelV1} from 'resources/GeneratedModels/StandardSetCreator/StandardSetCreatorModel.v1';
import {DraftGuessModelV1} from 'resources/GeneratedModels/DraftGuess/DraftGuessModel.v1';
import {DraftAssignmentModelV1} from 'resources/GeneratedModels/DraftAssignment/DraftAssignmentModel.v1';
import {PublicQuestionModelV1} from 'resources/augmented/PublicQuestion/PublicQuestionModel.v1';
import {UpgradeModelV1} from 'resources/GeneratedModels/Upgrade/UpgradeModel.v1';
import {GradeLevelModelV1} from 'resources/GeneratedModels/GradeLevel/GradeLevelModel.v1';
import {AuthoringStandardModelV1} from 'resources/GeneratedModels/AuthoringStandard/AuthoringStandardModel.v1';

import {AuthoringStandardSetModelV1} from 'resources/GeneratedModels/AuthoringStandardSet/AuthoringStandardSetModel.v1';

TemplateModelV1.extend(templateModelExtensions);
StudentAssignmentModelV1.extend(studentAssignmentModelExtensions);
SchoolModelV4.extend(schoolExtensions);

/**
 * @type {Object}
 */
export const MODEL_REGISTRY = {
  /* eslint-disable camelcase */
  assignment_v2: () => AssignmentModelV2,
  assignment_v3: () => AssignmentModelV3,
  author_v1: () => AuthorModelV1,
  authoring_feedback_v1: () => AuthoringFeedbackModelV1,
  authoring_guide_level_v1: () => AuthoringGuideLevelModelV1,
  authoring_guide_v1: () => AuthoringGuideModelV1,
  authoring_label_v1: () => AuthoringLabelModelV1,
  authoring_question_set_v1: () => AuthoringQuestionSetModelV1,
  authoring_question_v1: () => AuthoringQuestionModelV1,
  authoring_subject_v1: () => AuthoringSubjectModelV1,
  authoring_supplement_v1: () => AuthoringSupplementModelV1,
  authoring_standard_v1: () => AuthoringStandardModelV1,
  authoring_standard_set_v1: () => AuthoringStandardSetModelV1,
  classroom_assignment: () => Map,
  classroom_invitation_v1: () => ClassroomInvitationModelV1,
  classroom_v1: () => ClassroomModelV1,
  clever_course_sync_v1: () => CleverCourseSyncModelV1,
  coppa_consent_grant_v1: () => CoppaConsentGrantModelV1,
  curriculum_area_v1: () => CurriculumAreaModelV1,
  district_v1: () => DistrictModelV1,
  draft_assignment_v1: () => DraftAssignmentModelV1,
  draft_guess_v1: () => DraftGuessModelV1,
  email_domain_v1: () => EmailDomainModelV1,
  exam_v1: () => ExamModelV1,
  google_classroom_api_course_v1: () => GoogleCourseModel,
  grade_level_v1: () => GradeLevelModelV1,
  guess_v1: () => GuessModelV1,
  guide_level_v1: () => GuideLevel,
  guide_level_v2: () => GuideLevelModelV2,
  guide_v1: () => GuideModelV1,
  issuer_v1: () => TeacherModelV1,
  label_v1: () => LabelModelV1,
  managed_user_v1: () => ManagedUserModelV1,
  media_v1: () => MediaModelV1,
  public_question_v1: () => PublicQuestionModelV1,
  question_set_v1: () => QuestionSetModelV1,
  question_v1: (data) => getQuestionModel(data.get('question_type')),
  question_v2: (data) => getQuestionModel(data.get('question_type')),
  question_v3: () => QuestionModelV3,
  report: () => Map,
  report_result: () => Map,
  role_v1: () => RoleModelV1,
  school_license_subject_group_v1: () => SchoolLicenseSubjectGroupModelV1,
  school_license_v1: () => SchoolLicenseModelV1,
  school_personnel_invitation_v1: () => SchoolPersonnelInvitationModelV1,
  school_personnel_v1: () => SchoolPersonnelModelV1,
  school_v2: () => SchoolModelV2,
  school_v4: () => SchoolModelV4,
  school_v5: () => SchoolModelV5,
  search_question_v1: () => SearchQuestionModelV1,
  search_question_v2: () => SearchQuestionModelV2,
  search_standard_v1: () => SearchStandardModelV1,
  search_standard_v2: () => SearchStandardModelV2,
  search_tag_v1: () => SearchTagModelV1,
  section_v1: () => SectionModelV1,
  standard_v1: () => StandardModelV1,
  standard_set_v1: () => StandardSetModelV1,
  standard_set_creator_v1: () => StandardSetCreatorModelV1,
  student_assignment_v1: () => StudentAssignmentModelV1,
  student_classroom_v1: () => StudentClassroomModelV1,
  student_v1: () => StudentModel,
  student_v2: () => StudentModelV2,
  subject_author_permission_v1: () => SubjectAuthorPermissionModelV1,
  subject_faq_v1: () => SubjectFaqModelV1,
  subject_v1: () => SubjectModel,
  subject_v2: () => SubjectModelV2,
  subscription_v1: () => SubscriptionModelV1,
  subtopic_v1: () => GuideLevel,
  supplement_v1: () => SupplementModelV1,
  tag_v1: () => TagModelV1,
  teacher_v1: () => TeacherModelV1,
  template_v1: () => TemplateModelV1,
  theme_v1: () => GuideLevel,
  topic_v1: () => GuideLevel,
  translated_question: () => Map,
  translated_supplement: () => Map,
  translated_field: () => Map,
  upgrade_v1: () => UpgradeModelV1,
  user_feedback_v1: () => UserFeedbackModelV1,
  user_v1: () => UserModelV1,
  user_v2: () => UserModelV2
  /* eslint-enable camelcase */
};

/**
 * @type {Object}
 */
export const COLLECTION_MODEL_REGISTRY = {
  /* eslint-disable camelcase */
  assignments_v2: () => GenericListCollectionModel,
  assignments_v3: () => GenericListCollectionModel,
  authoring_feedback_v1: () => GenericListCollectionModel,
  authoring_guide_levels_v1: () => GenericListCollectionModel,
  authoring_guides_v1: () => GenericListCollectionModel,
  authoring_labels_v1: () => GenericListCollectionModel,
  authoring_question_sets_v1: () => GenericListCollectionModel,
  authoring_questions_v1: () => GenericListCollectionModel,
  authoring_subjects_v1: () => GenericListCollectionModel,
  authoring_supplements_v1: () => GenericListCollectionModel,
  authoring_standards_v1: () => GenericListCollectionModel,
  authoring_standard_sets_v1: () => GenericListCollectionModel,
  authors_v1: () => GenericListCollectionModel,
  classroom_assignments: () => GenericListCollectionModel,
  classroom_invitations_v1: () => GenericListCollectionModel,
  classrooms_v1: () => GenericListCollectionModel,
  clever_course_syncs_v1: () => GenericListCollectionModel,
  coppa_consent_grants_v1: () => GenericListCollectionModel,
  curriculum_areas_v1: () => GenericListCollectionModel,
  districts_v1: () => GenericListCollectionModel,
  draft_assignments_v1: () => GenericListCollectionModel,
  draft_guesses_v1: () => GenericListCollectionModel,
  email_domains_v1: () => GenericListCollectionModel,
  exams_v1: () => GenericListCollectionModel,
  google_classroom_api_courses_v1: () => GenericListCollectionModel,
  grade_levels_v1: () => GenericListCollectionModel,
  guesses_v1: () => GenericListCollectionModel,
  guide_levels_v1: () => GenericListCollectionModel,
  guide_levels_v2: () => GuideLevelsCollectionModel,
  guides_v1: () => GenericListCollectionModel,
  issuers_v1: () => GenericListCollectionModel,
  labels_v1: () => GenericListCollectionModel,
  managed_users_v1: () => GenericListCollectionModel,
  medias_v1: () => GenericListCollectionModel, // TODO: what is the pluaral form of this
  public_questions_v1: () => GenericListCollectionModel,
  question_sets_v1: () => GenericListCollectionModel,
  questions_v1: () => GenericListCollectionModel,
  questions_v2: () => GenericListCollectionModel,
  questions_v3: () => GenericListCollectionModel,
  reports: () => GenericListCollectionModel,
  report_results: () => GenericListCollectionModel,
  roles_v1: () => GenericListCollectionModel,
  school_license_subject_groups_v1: () => GenericListCollectionModel,
  school_licenses_v1: () => GenericListCollectionModel,
  school_personnel_invitations_v1: () => GenericListCollectionModel,
  school_personnel_v1: () => GenericListCollectionModel,
  schools_v2: () => GenericListCollectionModel,
  schools_v4: () => GenericListCollectionModel,
  schools_v5: () => GenericListCollectionModel,
  search_questions_v1: () => GenericListCollectionModel,
  search_questions_v2: () => GenericListCollectionModel,
  search_standards_v1: () => GenericListCollectionModel,
  search_standards_v2: () => GenericListCollectionModel,
  search_tags_v1: () => GenericListCollectionModel,
  sections_v1: () => GenericListCollectionModel,
  standards_v1: () => GenericListCollectionModel,
  standard_sets_v1: () => GenericListCollectionModel,
  standard_set_creators_v1: () => GenericListCollectionModel,
  student_assignments_v1: () => GenericListCollectionModel,
  student_classrooms_v1: () => GenericListCollectionModel,
  students_v1: () => GenericListCollectionModel,
  students_v2: () => StudentsListCollectionModel,
  subject_author_permissions_v1: () => GenericListCollectionModel,
  subject_faqs_v1: () => GenericListCollectionModel,
  subjects_v1: () => GenericMapCollectionModel,
  subjects_v2: () => GenericListCollectionModel,
  subscriptions_v1: () => GenericListCollectionModel,
  subtopics_v1: () => GenericListCollectionModel,
  supplements_v1: () => GenericListCollectionModel,
  tags_v1: () => GenericListCollectionModel,
  teachers_v1: () => GenericListCollectionModel,
  templates_v1: () => GenericListCollectionModel,
  themes_v1: () => GenericListCollectionModel,
  topics_v1: () => GenericListCollectionModel,
  translated_fields: () => GenericListCollectionModel,
  translated_questions: () => GenericListCollectionModel,
  translated_supplements: () => GenericListCollectionModel,
  upgrades_v1: () => GenericListCollectionModel,
  user_feedback_v1: () => GenericListCollectionModel,
  users_v1: () => GenericListCollectionModel,
  users_v2: () => GenericListCollectionModel
  /* eslint-enable camelcase */
};

/**
 * @param {string} type
 * @param {*} data
 */
export function getModelForResourceType(type, data) {
  return MODEL_REGISTRY[type](data);
}

// In order to reduce the number of console warnings for missing models, we'll only warn once per model
const missingModels = {};

export function getModel(data) {
  const jsonApiType = data.get('jsonapi_type');

  if (Object.prototype.hasOwnProperty.call(MODEL_REGISTRY, jsonApiType)) {
    return MODEL_REGISTRY[jsonApiType](data);
  }
  if (process.env.NODE_ENV !== 'production') {
    if (!missingModels[jsonApiType]) {
      // eslint-disable-next-line no-console
      console.warn(`[registry] missing model in registry | type=${jsonApiType}`);
    }
    missingModels[jsonApiType] = true;
  }
  return null;
}

export function getCollectionModel(collectionType) {
  if (Object.prototype.hasOwnProperty.call(COLLECTION_MODEL_REGISTRY, collectionType)) {
    return COLLECTION_MODEL_REGISTRY[collectionType]();
  }
  if (process.env.NODE_ENV !== 'production') {
    if (!missingModels[collectionType]) {
      // eslint-disable-next-line no-console
      console.warn(`[registry] missing collection model in registry | type=${collectionType}`);
    }
    missingModels[collectionType] = true;
  }
  return null;
}

export function getEmptyModel(key, data = null) {
  const modelCallback = MODEL_REGISTRY[key];
  if (modelCallback === undefined) {
    throw new Error(`A model with the key of ${key} does not exist`);
  }
  const Model = data ? modelCallback(data) : modelCallback();
  return new Model();
}
