import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Button from 'sg/Button/Button.react';
import makeRef from 'lib/makeRef';
import './button-dropdown.scss';

/*
<ButtonDropdown
  className='not necessary'
  color='green'
  optionsAlignment='right'
  dropdownItems={fromJS([
    {
      disabled: true,
      displayName: 'Edit Assignment',
      callback: () => console.log('foo')
    },
    {
      disabled: false,
      displayName: 'Delete Assignment',
      callback: () => console.log('bar')
    },
    // Items without a callback attribute receive the --inactive modifier class,
    // which is useful for printing plain text.
    {
      displayName: 'Your plain text message here.'
    }
  ])}
>
 Do it.
</ButtonDropdown>
*/

export default class ButtonDropdown extends React.Component {
  wrapperRef = React.createRef();

  static propTypes = {
    buttonClassName: PropTypes.string,
    className: PropTypes.string,
    color: PropTypes.string,
    optionsAlignment: PropTypes.string,
    dropdownItems: PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.func]).isRequired,
    handleClick: PropTypes.func,
    hideCaret: PropTypes.bool,
    small: PropTypes.bool,
    text: PropTypes.string,
    unbutton: PropTypes.bool
  };

  static defaultProps = {
    handleClick: () => {}
  };

  state = {
    isOpen: false
  };

  componentDidMount() {
    global.document.addEventListener('click', this.closeDropdown);
  }

  componentWillUnmount() {
    global.document.removeEventListener('click', this.closeDropdown);
  }

  buttonNode = null;

  toggleDropdownVisibility() {
    const isOpen = this.state.isOpen;
    this.setState({
      isOpen: !isOpen
    });
  }

  closeDropdown = (e) => {
    const wrapperNode = this.wrapperRef.current;
    const isClosed = !this.state.isOpen;
    if (isClosed || wrapperNode.contains(e.target)) {
      return;
    }
    this.setState({
      isOpen: false
    });
  };

  render() {
    const dropdown = this.state.isOpen ? (
      <ButtonDropdownContent
        buttonNode={this.buttonNode}
        dropdownItems={this.props.dropdownItems}
        optionsAlignment={this.props.optionsAlignment}
      />
    ) : null;
    const isOpen = this.state.isOpen;
    const chevronSpan = this.props.hideCaret ? null : (
      <span
        className={classnames('fa', `fa-caret-${isOpen ? 'up' : 'down'}`, 'button-dropdown__icon', {
          'button-dropdown__icon--open': isOpen
        })}
      />
    );
    const content = this.props.text ? this.props.text : this.props.children;
    return (
      <div className={classnames('button-dropdown', this.props.className)} ref={this.wrapperRef}>
        <Button
          className={classnames('button-dropdown__button', this.props.buttonClassName, {
            'button-dropdown__button--unbutton unbutton': this.props.unbutton
          })}
          color={this.props.color}
          small={this.props.small}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            this.toggleDropdownVisibility();
            this.props.handleClick();
          }}
          ref={(component) => {
            if (!component) {
              return;
            }
            this.buttonNode = component.wrapperRef.current;
          }}
        >
          <div>{content}</div>
          {chevronSpan}
        </Button>
        {dropdown}
      </div>
    );
  }
}

class ButtonDropdownContent extends React.Component {
  static propTypes = {
    dropdownItems: PropTypes.oneOfType([ImmutablePropTypes.list, PropTypes.func]).isRequired,
    buttonNode: PropTypes.any,
    optionsAlignment: PropTypes.oneOf(['right', 'left'])
  };

  static defaultProps = {
    optionsAlignment: 'right'
  };

  componentDidMount() {
    this.updatePosition();
    global.addEventListener('scroll', this.optimizedUpdatePosition, true);
  }

  componentWillUnmount() {
    global.removeEventListener('scroll', this.optimizedUpdatePosition);
  }

  dropdownRef = null;

  optimizedUpdatePosition = () => {
    global.requestAnimationFrame(this.updatePosition);
  };

  updatePosition = () => {
    const buttonRect = this.props.buttonNode.getBoundingClientRect();
    this.dropdownRef.style.top = `${buttonRect.top + buttonRect.height}px`;
    this.dropdownRef.style.right = `${global.innerWidth - buttonRect.right}px`;
  };

  generateListItems() {
    const dropdownItems =
      typeof this.props.dropdownItems === 'function'
        ? this.props.dropdownItems()
        : this.props.dropdownItems;
    return dropdownItems.map((dropdownItem, key) => {
      const onClick = dropdownItem.get('callback', () => {});
      const disabled = dropdownItem.get('disabled');
      const dropdownItemIconFaClassName = dropdownItem.get('iconFaClassName');
      const dropdownItemIcon = dropdownItemIconFaClassName ? (
        <span
          className={`button-dropdown-content__list-item-icon fa ${dropdownItemIconFaClassName}`}
          aria-hidden='true'
        />
      ) : null;

      return (
        <li
          key={key}
          className={classnames({
            'button-dropdown-content__list-item': true,
            'button-dropdown-content__list-item--red': dropdownItem.get('color') === 'red',
            'button-dropdown-content__list-item--green': dropdownItem.get('color') === 'green',
            'button-dropdown-content__list-item--blue': dropdownItem.get('color') === 'blue',
            'button-dropdown-content__list-item--disabled': disabled,
            'button-dropdown-content__list-item--inactive': !dropdownItem.get('callback')
          })}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (disabled) {
              return;
            }
            onClick(e);
          }}
        >
          {dropdownItemIcon}
          {dropdownItem.get('displayName')}
        </li>
      );
    });
  }

  makeRef = makeRef.bind(this);

  render() {
    const optionsAlignment = this.props.optionsAlignment;
    const dropdownContentClassNames = classnames({
      'button-dropdown-content': true,
      'button-dropdown-content--left-aligned': optionsAlignment === 'left'
    });

    return (
      <ul className={dropdownContentClassNames} ref={this.makeRef} data-ref-name='dropdownRef'>
        {this.generateListItems()}
      </ul>
    );
  }
}

export const IconDropdown = ({buttonClassName, className, icon, ...rest}) => (
  <ButtonDropdown
    buttonClassName={classnames(buttonClassName, `fa ${icon}`)}
    className={classnames(className)}
    {...rest}
  />
);

IconDropdown.propTypes = {
  buttonClassName: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.string
};
