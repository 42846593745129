import React from 'react';
import {Button, Icon} from '@albert-io/atomic';
import {ButtonProps} from '@albert-io/atomic/atoms/Button/Button.react';
import withTracking from 'lib/hocs/withTracking';

import GoogleContext from '../Google.context';

const TrackedButton = withTracking(Button, {
  onClick: {event: 'click', content: 'Sign into Google'}
});

interface Props extends ButtonProps<'button'> {
  hideIcon?: boolean;
  text?: string;
}

// TODO: define this type
type GoogleC = any;

const GoogleSignInButton = ({hideIcon = false, text = 'Sign into Google', ...btnProps}: Props) => {
  const {handleSignIn, isRequestPending} = React.useContext<GoogleC>(GoogleContext);
  return (
    <TrackedButton
      aria-label={text}
      disabled={isRequestPending}
      onClick={handleSignIn}
      {...btnProps}
    >
      {!hideIcon && <Icon icon={['fab', 'google']} />}
      &nbsp;{text}
    </TrackedButton>
  );
};

export default GoogleSignInButton;
