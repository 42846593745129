import {List, Map, Set} from 'immutable';
import {StateCreator} from 'zustand';
import {
  GuideModelV1,
  GuideLevelModelV2,
  SubjectModelV2,
  CurriculumAreaModelV1
} from '@albert-io/models';

import type {ContentDiscoveryStoreState} from 'client/components/ContentDiscovery/store/ContentDiscoveryStore.types';

import {FilterOptions} from '../../ContentDiscovery.utils';

import {getSubjectsQuery, getGuidesFromSubject} from './TopicsModal.queries';
import {
  buildFilteredSubjectsAndCurriculumAreasList,
  getDescendantGuideLevels,
  updateAscendantGuideLevelSelections,
  getFlattenedGuideList,
  getSelectedSubjectQuestionCounts,
  getTeachersClassroomSubjects,
  guideTypes,
  replaceSelectedGuideLevels
} from './topicsModalUtils';

type TopicsModalState = {
  /* subjects */
  subjectsWithCurriculumAreas: List<CurriculumAreaModelV1 | SubjectModelV2>;
  subjectSearchString: string;
  selectedSubject: SubjectModelV2;
  /* subject guides */
  subjectGuides: List<GuideModelV1>;
  flattenedSubjectGuideLevels: List<GuideLevelModelV2>;
  selectedGuideType: string;
  subjectGuideQuestionCounts: Map<any, any>;
  subjectGuideSearchString: string;
  /* guide level util data structures */
  guideCollection: Map<string, Map<string, any>>;
  guideLevelChildrenMap: Map<any, any>;
  selectedGuideLevels: Set<any>;
  partiallySelectedGuideLevels: Set<any>;
  appliedGuideLevels: List<any>;
  selectedSubjectQuestionCounts: any[];
  expandedGuideLevels: Set<any>;
  matchingTopicsForTopGuideLevelIds: Map<any, any>;
  /* flags */
  areSubjectsPersonalized: boolean;
  isFilteringSubjects: boolean;
  isLoadingSubjectGuides: boolean;
  /* request timestamp */
  lastSubjectGuidesRequestTimestamp: number;
  lastSubjectsRequestTimestamp: number;
};

type TopicsModalActions = {
  setSubjectSearchString: (subjectSearchString: string) => void;
  setSelectedSubject: (selectedSubject: SubjectModelV2) => void;
  setAreSubjectsPersonalized: (areSubjectsPersonalized: boolean) => void;
  setSubjectGuideSearchString: (subjectGuideSearchString: string) => void;
  setSelectedGuideType: (guideType: string) => void;
  selectAllSubjectGuideLevels: () => void;
  deselectAllSubjectGuideLevels: () => void;
  applySelectedGuideLevels: () => void;
  removeAppliedGuideLevel: (guideLevelId: string, guideId: string) => void;
  removeAppliedSubject: (subjectId: string) => void;
  resetSelectedGuideLevels: () => void;
  handleSubjectGuideLevelSelection: (guideLevelId: string, guideId: string) => void;
  handleRemoveSubjectChip: (subjectId: string) => void;
  updateSelectedSubjectQuestionCounts: () => void;
  updateSelectedSubjectGuideLevels: () => void;
  toggleGuideLevelExpansion: (guideLevelId: string) => void;
  expandAllGuideLevels: () => void;
  collapseAllGuideLevels: () => void;
  reset: () => void;

  /* async operations */
  getFilteredSubjects: (
    contentDiscoveryFilters: FilterOptions,
    requestTimestamp: number
  ) => Promise<void>;
  getSubjectGuides: (
    contentDiscoveryFilters: FilterOptions,
    requestTimestamp: number
  ) => Promise<void>;
  getAndReplaceSelectedGuideLevels: (
    subjectId: string,
    contentDiscoveryFilters: FilterOptions
  ) => Promise<void>;
};

const initialState: TopicsModalState = {
  /* subjects */
  subjectsWithCurriculumAreas: List(),
  subjectSearchString: '',
  selectedSubject: null,
  /* subject guides */
  subjectGuides: List(),
  flattenedSubjectGuideLevels: List(),
  selectedGuideType: 'practice',
  subjectGuideQuestionCounts: Map(),
  subjectGuideSearchString: '',
  guideCollection: Map(),
  /* guide level util data structures */
  guideLevelChildrenMap: Map(),
  selectedGuideLevels: Set(),
  partiallySelectedGuideLevels: Set(),
  appliedGuideLevels: List(),
  selectedSubjectQuestionCounts: [],
  expandedGuideLevels: Set(),
  matchingTopicsForTopGuideLevelIds: Map(),
  /* flags */
  isLoadingSubjectGuides: false,
  areSubjectsPersonalized: true,
  isFilteringSubjects: true,
  /* request timestamp */
  lastSubjectGuidesRequestTimestamp: 0,
  lastSubjectsRequestTimestamp: 0
};

export type TopicsModalSlice = TopicsModalState & TopicsModalActions;

/* eslint-disable no-param-reassign */
export const createTopicsModalSlice: StateCreator<
  ContentDiscoveryStoreState,
  [['zustand/immer', never]],
  [],
  TopicsModalSlice
> = (set, get, _): TopicsModalSlice => ({
  ...initialState,
  setSubjectSearchString: (subjectSearchString) =>
    set((state) => {
      state.topicsModal.subjectSearchString = subjectSearchString;
    }),
  setSelectedSubject: (selectedSubject) =>
    set((state) => {
      state.topicsModal.selectedSubject = selectedSubject;
      state.topicsModal.isLoadingSubjectGuides = !!selectedSubject;
    }),
  setAreSubjectsPersonalized: (areSubjectsPersonalized) =>
    set((state) => {
      state.topicsModal.areSubjectsPersonalized = areSubjectsPersonalized;
    }),
  setSubjectGuideSearchString: (subjectGuideSearchString) =>
    set((state) => {
      state.topicsModal.subjectGuideSearchString = subjectGuideSearchString;
    }),
  setSelectedGuideType: (guideType) => {
    const {subjectGuides, guideLevelChildrenMap, subjectGuideSearchString} = get().topicsModal;
    const [
      flattenedSubjectGuideLevels,
      currGuideLevelChildrenMap,
      matchingTopicsForTopGuideLevelIds
    ] = getFlattenedGuideList(
      subjectGuides.find((guide) => guide.getGuideType() === guideType),
      subjectGuideSearchString !== ''
    );
    const newGuideLevelChildrenMap = guideLevelChildrenMap.merge(currGuideLevelChildrenMap);

    set((state) => {
      state.topicsModal.selectedGuideType = guideType;
      state.topicsModal.flattenedSubjectGuideLevels = flattenedSubjectGuideLevels;
      state.topicsModal.guideLevelChildrenMap = newGuideLevelChildrenMap;
      state.topicsModal.matchingTopicsForTopGuideLevelIds = matchingTopicsForTopGuideLevelIds;
    });
  },
  selectAllSubjectGuideLevels: () => {
    const {flattenedSubjectGuideLevels, selectedGuideLevels, partiallySelectedGuideLevels} =
      get().topicsModal;
    set((state) => {
      state.topicsModal.selectedGuideLevels = selectedGuideLevels.union(
        flattenedSubjectGuideLevels
      );
      state.topicsModal.partiallySelectedGuideLevels = partiallySelectedGuideLevels.subtract(
        flattenedSubjectGuideLevels
      );
    });
  },
  deselectAllSubjectGuideLevels: () => {
    const {flattenedSubjectGuideLevels, selectedGuideLevels, partiallySelectedGuideLevels} =
      get().topicsModal;
    set((state) => {
      state.topicsModal.selectedGuideLevels = selectedGuideLevels.subtract(
        flattenedSubjectGuideLevels
      );
      state.topicsModal.partiallySelectedGuideLevels = partiallySelectedGuideLevels.subtract(
        flattenedSubjectGuideLevels
      );
    });
  },
  applySelectedGuideLevels: () => {
    const {selectedGuideLevels} = get().topicsModal;

    set((state) => {
      state.topicsModal.appliedGuideLevels = selectedGuideLevels.toList();
    });
  },
  removeAppliedGuideLevel: (guideLevelId: string, guideId: string) => {
    const {appliedGuideLevels, guideCollection, guideLevelChildrenMap} = get().topicsModal;
    const guideLevel = guideCollection.get(guideId)?.get(guideLevelId)?.guideLevel;
    if (guideLevel == null) {
      return;
    }

    const descendantGuideLevels = getDescendantGuideLevels(
      guideLevelId,
      guideLevelChildrenMap
    ).push(guideLevel);
    const newAppliedGuideLevels = appliedGuideLevels.toSet().subtract(descendantGuideLevels);

    const {selectedGuideLevels} = updateAscendantGuideLevelSelections(
      guideCollection.get(guideId)?.get(guideLevel.getParentId())?.guideLevel,
      guideId,
      guideLevelChildrenMap,
      guideCollection,
      newAppliedGuideLevels,
      Set()
    );

    set((state) => {
      state.topicsModal.appliedGuideLevels = selectedGuideLevels.toList();
    });
  },
  removeAppliedSubject: (subjectId: string) => {
    const {appliedGuideLevels} = get().topicsModal;
    const newAppliedGuideLevels = appliedGuideLevels
      .filter((guideLevel) => guideLevel.getSubject().getId() !== subjectId)
      .toList();
    set((state) => {
      state.topicsModal.appliedGuideLevels = newAppliedGuideLevels;
    });
  },
  // reset the selected guide levels to the applied guide levels
  resetSelectedGuideLevels: () => {
    const {guideLevelChildrenMap, guideCollection, appliedGuideLevels} = get().topicsModal;
    let newSelectedGuideLevels = appliedGuideLevels.toSet();
    let newPartiallySelectedGuideLevels = Set();

    // Process each selected guide level to include descendants and update ascendants
    appliedGuideLevels.forEach((guideLevel) => {
      const guideId = guideLevel.getGuide().getId();
      const guideLevelId = guideLevel.getId();

      // Add descendants to selected guide levels
      const descendants = getDescendantGuideLevels(guideLevelId, guideLevelChildrenMap);
      newSelectedGuideLevels = newSelectedGuideLevels.union(descendants);

      // Update ascendants
      const result = updateAscendantGuideLevelSelections(
        guideCollection.get(guideId)?.get(guideLevel.getParentId())?.guideLevel,
        guideId,
        guideLevelChildrenMap,
        guideCollection,
        newSelectedGuideLevels,
        newPartiallySelectedGuideLevels
      );

      newSelectedGuideLevels = result.selectedGuideLevels;
      newPartiallySelectedGuideLevels = result.partiallySelectedGuideLevels;
    });

    set((state) => {
      state.topicsModal.selectedGuideLevels = newSelectedGuideLevels;
      state.topicsModal.partiallySelectedGuideLevels = newPartiallySelectedGuideLevels;
    });
  },
  // Handles the selection of a subject guide level and subsequently updates the selected and partially selected guide levels
  handleSubjectGuideLevelSelection: (guideLevelId, guideId) => {
    const {
      selectedGuideLevels,
      partiallySelectedGuideLevels,
      guideLevelChildrenMap,
      guideCollection
    } = get().topicsModal;
    const {guideLevel: currGuideLevel} = guideCollection.get(guideId)?.get(guideLevelId);
    if (currGuideLevel == null) {
      return;
    }
    // checked status of the current guide level before selection
    const isChecked =
      selectedGuideLevels.has(currGuideLevel) || partiallySelectedGuideLevels.has(currGuideLevel);

    // first get all descendant guide levels of the current guide level to add/remove them from the selected/partially selected guide levels
    const guideLevelWithDescendants = getDescendantGuideLevels(
      guideLevelId,
      guideLevelChildrenMap
    ).push(currGuideLevel);
    let newSelectedGuideLevels;
    let newPartiallySelectedGuideLevels;

    if (isChecked) {
      newSelectedGuideLevels = selectedGuideLevels.subtract(guideLevelWithDescendants);
      newPartiallySelectedGuideLevels =
        partiallySelectedGuideLevels.subtract(guideLevelWithDescendants);
    } else {
      newSelectedGuideLevels = selectedGuideLevels.union(guideLevelWithDescendants);
      newPartiallySelectedGuideLevels =
        partiallySelectedGuideLevels.subtract(guideLevelWithDescendants);
    }

    // next update the ascendant guide levels recursively based on the updated selected/partially selected guide levels
    const {
      selectedGuideLevels: updatedSelectedGuideLevels,
      partiallySelectedGuideLevels: updatedPartiallySelectedGuideLevels
    } = updateAscendantGuideLevelSelections(
      guideCollection.get(guideId)?.get(currGuideLevel.getParentId())?.guideLevel,
      guideId,
      guideLevelChildrenMap,
      guideCollection,
      newSelectedGuideLevels,
      newPartiallySelectedGuideLevels
    );

    set((state) => {
      state.topicsModal.selectedGuideLevels = updatedSelectedGuideLevels;
      state.topicsModal.partiallySelectedGuideLevels = updatedPartiallySelectedGuideLevels;
    });
  },
  handleRemoveSubjectChip: (subjectId) => {
    const {selectedGuideLevels, partiallySelectedGuideLevels} = get().topicsModal;
    const guideLevelsToRemove = selectedGuideLevels
      .union(partiallySelectedGuideLevels)
      .filter((guideLevel) => guideLevel.getSubject().getId() === subjectId);
    const newSelectedGuideLevels = selectedGuideLevels.subtract(guideLevelsToRemove);
    const newPartiallySelectedGuideLevels =
      partiallySelectedGuideLevels.subtract(guideLevelsToRemove);

    set((state) => {
      state.topicsModal.selectedGuideLevels = newSelectedGuideLevels;
      state.topicsModal.partiallySelectedGuideLevels = newPartiallySelectedGuideLevels;
    });
  },
  reset: () => {
    set((state) => {
      state.topicsModal = {...state.topicsModal, ...initialState};
    });
  },
  updateSelectedSubjectQuestionCounts: () => {
    const {selectedGuideLevels} = get().topicsModal;
    const newSelectedSubjectQuestionCounts = getSelectedSubjectQuestionCounts(selectedGuideLevels);

    set((state) => {
      state.topicsModal.selectedSubjectQuestionCounts = newSelectedSubjectQuestionCounts;
    });
  },
  updateSelectedSubjectGuideLevels: () => {
    const {
      flattenedSubjectGuideLevels,
      appliedGuideLevels,
      selectedGuideLevels,
      partiallySelectedGuideLevels
    } = get().topicsModal;

    if (
      (flattenedSubjectGuideLevels.size === 0 || selectedGuideLevels.size === 0) &&
      appliedGuideLevels.size === 0
    ) {
      return;
    }

    set((state) => {
      state.topicsModal.appliedGuideLevels = replaceSelectedGuideLevels(
        appliedGuideLevels.toSet(),
        flattenedSubjectGuideLevels
      ).toList();
      state.topicsModal.selectedGuideLevels = replaceSelectedGuideLevels(
        selectedGuideLevels,
        flattenedSubjectGuideLevels
      );
      state.topicsModal.partiallySelectedGuideLevels = replaceSelectedGuideLevels(
        partiallySelectedGuideLevels,
        flattenedSubjectGuideLevels
      );
    });
  },
  toggleGuideLevelExpansion: (guideLevelId) => {
    const {expandedGuideLevels} = get().topicsModal;
    const hyphenlessGuideLevelId = guideLevelId.replace(/-/g, '');
    set((state) => {
      state.topicsModal.expandedGuideLevels = expandedGuideLevels.has(hyphenlessGuideLevelId)
        ? expandedGuideLevels.delete(hyphenlessGuideLevelId)
        : expandedGuideLevels.add(hyphenlessGuideLevelId);
    });
  },
  expandAllGuideLevels: () => {
    const {flattenedSubjectGuideLevels, guideLevelChildrenMap, expandedGuideLevels} =
      get().topicsModal;

    // Get all top-level guide level IDs (those with nlevel equal to 1 and have children)
    const topLevelGuideLevelIds = flattenedSubjectGuideLevels
      .filter(
        (guideLevel) =>
          guideLevel.getNlevel() === 1 && guideLevelChildrenMap.has(guideLevel.getId())
      )
      .map((guideLevel) => guideLevel.getId().replace(/-/g, ''));

    set((state) => {
      state.topicsModal.expandedGuideLevels = Set(topLevelGuideLevelIds).union(expandedGuideLevels);
    });
  },
  collapseAllGuideLevels: () => {
    const {flattenedSubjectGuideLevels, guideLevelChildrenMap, expandedGuideLevels} =
      get().topicsModal;

    // Get all top-level guide level IDs (those with nlevel equal to 1 and have children)
    const topLevelGuideLevelIds = flattenedSubjectGuideLevels
      .filter(
        (guideLevel) =>
          guideLevel.getNlevel() === 1 && guideLevelChildrenMap.has(guideLevel.getId())
      )
      .map((guideLevel) => guideLevel.getId().replace(/-/g, ''));

    set((state) => {
      state.topicsModal.expandedGuideLevels = expandedGuideLevels.subtract(
        Set(topLevelGuideLevelIds)
      );
    });
  },
  // build filtered subjects list based on search string and personalized flag
  getFilteredSubjects: async (contentDiscoveryFilters, requestTimestamp) => {
    const {subjectSearchString, areSubjectsPersonalized, subjectGuideSearchString} =
      get().topicsModal;

    // if the request timestamp is less than the last subjects request timestamp, we don't need to fetch the subjects again
    if (requestTimestamp < get().topicsModal.lastSubjectsRequestTimestamp) {
      return;
    }

    set((state) => {
      state.topicsModal.isFilteringSubjects = true;
    });

    try {
      // fetching filtered classroom and non-classroom subjects based on search string and personalized flag
      const [filteredClassroomSubjects, filteredSubjectsList] = await Promise.all([
        areSubjectsPersonalized
          ? getTeachersClassroomSubjects(
              subjectSearchString,
              subjectGuideSearchString,
              contentDiscoveryFilters
            )
          : Promise.resolve(List()),
        getSubjectsQuery(
          subjectSearchString,
          areSubjectsPersonalized,
          subjectGuideSearchString,
          contentDiscoveryFilters
        ).getResourcePromise()
      ]);

      // Check again before updating state in case a newer request finished first
      if (requestTimestamp < get().topicsModal.lastSubjectsRequestTimestamp) {
        return;
      }

      const filteredSubjects = buildFilteredSubjectsAndCurriculumAreasList({
        filteredClassroomSubjects,
        filteredSubjectsList,
        areSubjectsPersonalized
      });

      set((state) => {
        state.topicsModal.isFilteringSubjects = false;
        state.topicsModal.subjectsWithCurriculumAreas = filteredSubjects;
        state.topicsModal.lastSubjectsRequestTimestamp = requestTimestamp;
      });
    } catch (error) {
      set((state) => {
        state.topicsModal.isFilteringSubjects = false;
      });
      console.error('Error fetching filtered subjects', error);
    }
  },
  // async operation to fetch subject guides and build the subsequent guide level data structures
  getSubjectGuides: async (contentDiscoveryFilters, requestTimestamp) => {
    const {selectedSubject, subjectGuideSearchString, guideCollection, guideLevelChildrenMap} =
      get().topicsModal;
    if (selectedSubject == null) {
      return;
    }

    // skip if this request is older than the last processed request
    if (requestTimestamp < get().topicsModal.lastSubjectGuidesRequestTimestamp) {
      return;
    }

    set((state) => {
      state.topicsModal.isLoadingSubjectGuides = true;
    });

    try {
      const subjectGuides = await getGuidesFromSubject(
        selectedSubject.getId(),
        subjectGuideSearchString,
        contentDiscoveryFilters
      ).getResourcePromise();

      // Check again before updating state in case a newer request finished first
      if (requestTimestamp < get().topicsModal.lastSubjectGuidesRequestTimestamp) {
        return;
      }

      if (subjectGuides.size === 0) {
        set((state) => {
          state.topicsModal.subjectGuides = subjectGuides;
          state.topicsModal.isLoadingSubjectGuides = false;
          state.topicsModal.subjectGuideQuestionCounts = Map();
          state.topicsModal.selectedGuideType = 'practice';
          state.topicsModal.flattenedSubjectGuideLevels = List();
          state.topicsModal.lastSubjectGuidesRequestTimestamp = requestTimestamp;
          state.topicsModal.matchingTopicsForTopGuideLevelIds = Map();
        });
        return;
      }

      // build a map of guide types to total question counts for ease of rendering in the guide tabs
      let subjectGuideQuestionCounts = Map();
      let firstGuideTypeWithQuestions = '';
      let flattenedSubjectGuideLevels = List();
      let newGuideLevelChildrenMap = guideLevelChildrenMap;
      let newGuideCollection = guideCollection;
      let newMatchingTopicsForTopGuideLevelIds = Map();

      guideTypes.forEach((guideType) => {
        const subjectGuide = subjectGuides.find((guide) => guide.getGuideType() === guideType);
        if (subjectGuide == null) {
          return;
        }
        const [flattenedList, currGuideLevelChildrenMap, matchingTopicsForTopGuideLevelIds] =
          getFlattenedGuideList(subjectGuide, subjectGuideSearchString !== '');

        // Calculate question count from the already-filtered flattened list
        const questionCount = flattenedList.reduce((count, guideLevel) => {
          if (guideLevel.getLevel().split('.').length === 1) {
            return count + guideLevel.getMeta().getContentDiscoveryQuestionCount();
          }
          return count;
        }, 0);

        subjectGuideQuestionCounts = subjectGuideQuestionCounts.set(
          subjectGuide.getGuideType(),
          questionCount
        );

        // Set the first guide type with questions if not already set
        if (!firstGuideTypeWithQuestions) {
          firstGuideTypeWithQuestions = subjectGuide.getGuideType();
          flattenedSubjectGuideLevels = flattenedList;
          newGuideLevelChildrenMap = newGuideLevelChildrenMap.merge(currGuideLevelChildrenMap);
          newMatchingTopicsForTopGuideLevelIds = matchingTopicsForTopGuideLevelIds;
        }

        // organizing guideLevels by their guide id to be referenced in the top level filter modal
        flattenedList.forEach((guideLevel: GuideLevelModelV2) => {
          const guideId = guideLevel.getGuide().getId();
          const guideLevelId = guideLevel.getId();
          const guideToGuideLevelMap = newGuideCollection.get(guideId, Map());
          newGuideCollection = newGuideCollection.set(
            guideId,
            guideToGuideLevelMap.set(guideLevelId, {guideLevel})
          );
        });
      });

      set((state) => {
        state.topicsModal.subjectGuides = subjectGuides;
        state.topicsModal.isLoadingSubjectGuides = false;
        state.topicsModal.subjectGuideQuestionCounts = subjectGuideQuestionCounts;
        state.topicsModal.selectedGuideType = firstGuideTypeWithQuestions;
        state.topicsModal.guideLevelChildrenMap = newGuideLevelChildrenMap;
        state.topicsModal.flattenedSubjectGuideLevels = flattenedSubjectGuideLevels;
        state.topicsModal.guideCollection = newGuideCollection;
        state.topicsModal.lastSubjectGuidesRequestTimestamp = requestTimestamp;
        state.topicsModal.matchingTopicsForTopGuideLevelIds = newMatchingTopicsForTopGuideLevelIds;
      });
    } catch (error) {
      set((state) => {
        state.topicsModal.isLoadingSubjectGuides = false;
      });
      console.error('Error fetching subject guides', error);
    }
  },
  // during init, fetches the guide levels for the selected subjects and replaces the selected and partially selected guide levels
  // to update the question counts in the event that content filters have been changed
  getAndReplaceSelectedGuideLevels: async (subjectIds, contentDiscoveryFilters) => {
    const {appliedGuideLevels, selectedGuideLevels, partiallySelectedGuideLevels} =
      get().topicsModal;

    const results = await getGuidesFromSubject(
      subjectIds,
      '',
      contentDiscoveryFilters
    ).getResourcePromise();

    const returnedGuideCollection = results.flatMap((guide) =>
      guide.getGuideLevels().getCollection()
    );

    set((state) => {
      state.topicsModal.appliedGuideLevels = replaceSelectedGuideLevels(
        appliedGuideLevels.toSet(),
        returnedGuideCollection
      ).toList();
      state.topicsModal.selectedGuideLevels = replaceSelectedGuideLevels(
        selectedGuideLevels,
        returnedGuideCollection
      );
      state.topicsModal.partiallySelectedGuideLevels = replaceSelectedGuideLevels(
        partiallySelectedGuideLevels,
        returnedGuideCollection
      );
    });
  }
});
