import React, {Suspense, lazy} from 'react';
import {Anchor, LoadingSpinner} from '@albert-io/atomic';

import SimpleMarkdown from 'simple-markdown';

const Code = lazy(() => import('./Code.react'));

export const codeBlock = {
  ...SimpleMarkdown.defaultRules.codeBlock,
  match: (source) => {
    return /^(?: {4}[^\n]+\n)+(?: *\n)+/.exec(source);
  },
  react(node) {
    return (
      <Suspense fallback={<LoadingSpinner />}>
        <Code block content={node.content} language={node.lang} key={node.tokenId} />
      </Suspense>
    );
  }
};

export const inlineCode = {
  ...SimpleMarkdown.defaultRules.inlineCode,
  react(node) {
    return (
      <Suspense fallback={<LoadingSpinner />}>
        <Code content={node.content} />
      </Suspense>
    );
  }
};

export const link = {
  ...SimpleMarkdown.defaultRules.link,
  react(node, output, state) {
    return (
      <Anchor
        as='a'
        href={SimpleMarkdown.sanitizeUrl(node.target) as string}
        rel='noopener noreferrer'
        title={node.title}
        target='_blank'
        key={node.tokenId}
      >
        {output(node.content, state)}
      </Anchor>
    );
  }
};

export const table = {
  ...SimpleMarkdown.defaultRules.table,
  react(node, output, state) {
    return (
      <div className='markdown-table-wrapper' key={node.tokenId}>
        {SimpleMarkdown.defaultRules.table.react(node, output, state)}
      </div>
    );
  }
};
