import React from 'react';
import PropTypes from 'prop-types';
import {List} from 'immutable';
import appStore from 'client/AppStore';
import sessionStore from 'client/Session/SessionStore';
import {callAction} from 'client/framework';
import DennisLogo from 'assets/images/dennis-nav-logo.svg';
import {Logo, NavItem} from '@albert-io/atomic';
import classNames from 'classnames';

import MarketingNavigation from './MarketingNavigation/MarketingNavigation.react';
import MainLinks from './MainLinks/MainLinks.react';
import DropdownLinks from './DropdownLinks/DropdownLinks.react';
import NavWidgets from './NavWidgets/NavWidgets.react';
import globalNavigationActions from './GlobalNavigationActions';
import globalNavigationStore from './GlobalNavigationStore';
import GlobalNavDrawer from './GlobalNavDrawer/GlobalNavDrawer.react';

import stylevars from './global-navigation.scss';
import {GlobalNavBehaviorContext, GlobalNavBehaviorEnum} from './GlobalNavBehavior.context';

const mobileBreakpoint = parseInt(stylevars.mobileBreakpoint, 10);

export default class GlobalNavigation extends React.Component {
  static propTypes = {
    location: PropTypes.object,
    viewportWidth: PropTypes.number,
    requiresEmailVerification: PropTypes.bool
  };

  componentDidMount() {
    const {pathname} = this.props.location;
    callAction(globalNavigationActions.SET_PATHNAME, pathname);
    if (this.windowIsMobile()) {
      callAction(globalNavigationActions.SET_IS_DRAWER_ACTIVE, true);
    }
  }

  componentDidUpdate() {
    if (globalNavigationStore.getIsDrawerActive() === false && this.windowIsMobile()) {
      callAction(globalNavigationActions.SET_IS_DRAWER_ACTIVE, true);
    } else if (globalNavigationStore.getIsDrawerActive() && !this.windowIsMobile()) {
      callAction(globalNavigationActions.SET_IS_DRAWER_ACTIVE, false);
    }
  }

  static contextType = GlobalNavBehaviorContext;

  windowIsMobile() {
    return this.props.viewportWidth < mobileBreakpoint;
  }

  shouldShowMarketingNavigation() {
    const hideHeaderPages = List(['/', '/home']);
    return (
      !this.props.requiresEmailVerification &&
      hideHeaderPages.includes(this.props.location.pathname)
    );
  }

  render() {
    const {navBehavior} = this.context;

    const isUserLoggedIn = sessionStore.hasValidSession();
    if (this.shouldShowMarketingNavigation() || !isUserLoggedIn) {
      return <MarketingNavigation location={this.props.location} />;
    }

    const navWidgetsContent = !this.windowIsMobile() ? <NavWidgets /> : null;

    const mainNavDrawer = globalNavigationStore.getIsDrawerActive() ? (
      <GlobalNavDrawer pathname={this.props.location.pathname} />
    ) : null;

    return (
      <header
        className={classNames('global-nav u-overflow_auto', {
          'global-nav-not-sticky': navBehavior === GlobalNavBehaviorEnum.VISIBLE_NOT_STICKY
        })}
      >
        <div className='global-nav__content'>
          {mainNavDrawer}
          <NavItem className='global-nav__title' to='/classes'>
            {appStore.isZoneDennis() ? (
              <img
                className='global-nav__dennis-logo'
                src={DennisLogo}
                alt=''
                aria-label='Go to Classes'
              />
            ) : (
              <Logo aria-label='Go to Classes' width='112px' light type='combined' />
            )}
          </NavItem>
          {!this.windowIsMobile() && <MainLinks pathname={this.props.location.pathname} />}
          <nav className='global-nav__account' aria-label='account'>
            <ul className='global-nav__account-list'>
              {navWidgetsContent}
              <DropdownLinks />
            </ul>
          </nav>
        </div>
      </header>
    );
  }
}
