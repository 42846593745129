import React, {useContext, useEffect, useState, useRef} from 'react';
import {withRouter, WithRouterProps} from 'react-router';
import classnames from 'classnames';
import {QUESTION_TYPES} from 'client/constants';

// import ImmutablePropTypes from 'react-immutable-proptypes'; TODO-KBC: Build typescript methods
import {Footer, copyToClipboard} from '@albert-io/atomic';
import TitleBar from 'components/PracticeView/TitleBar/TitleBar.react';
import {callAction} from 'client/framework';
import appActions from 'client/AppActions';
// import QuestionTypeStore from 'components/QuestionTypes/QuestionType.store'; TODO-KBC: Build typescript methods
// import {getModelForResourceType} from 'resources/modelRegistry'; TODO-KBC: Build typescript methods
import sessionStore from 'client/Session/SessionStore';
import usePrevious from 'lib/hooks/usePrevious';
import {
  GlobalNavBehaviorContext,
  GlobalNavBehaviorEnum
} from 'client/GlobalNavigation/GlobalNavBehavior.context';

import {OptionEliminatorProvider} from './OptionEliminator/OptionEliminator.react';
import {EmptyQuestionShell} from './EmptyQuestionShell/EmptyQuestionShell.react';
import QuestionsList from './QuestionsList/QuestionsList.react';
import QuestionRenderer from './QuestionRenderer/QuestionRenderer.react';

import {MobilePracticeViewContext} from './MobilePracticeViewProvider.context';

import './practice-view.scss';
import {QuestionListProps, QuestionProps} from './QuestionsList/questionsList.types';

interface PracticeViewProps extends WithRouterProps {
  removeQuestionListOnMobile?: boolean; // needed for the time being to conditionally remove questionlist since its not needed on mobile view TODO remove after moving question list into tool bar for AssignmentPracticeView
  activeQuestion: any; // PropTypes.instanceOf(getModelForResourceType('question_v3')), TODO-KBC: Build typescript methods
  activeQuestionSet: any; // PropTypes.instanceOf(getModelForResourceType('question_set_v1')), TODO-KBC: Build typescript methods
  activeQuestionStore: any; // PropTypes.instanceOf(QuestionTypeStore), TODO-KBC: Build typescript methods
  activeGuess: any; // PropTypes.instanceOf(getModelForResourceType('guess_v1')), TODO-KBC: Build typescript methods
  activeSubject: any; // PropTypes.instanceOf(getModelForResourceType('subject_v2')), TODO-KBC: Build typescript methods
  questionProps?: QuestionProps;
  questionRendererProps: {
    actions: React.ReactNode;
    rendererType: 'PreGuess' | 'PostGuess';
    interactive?: boolean;
    navigateBackProps: {
      disabled: boolean;
      handleNavigation?: () => void;
    } | null;
    navigateNextProps: {
      disabled: boolean;
      handleNavigation?: () => void;
    } | null;
    headingActions: React.ReactNode;
  };
  questionSetProps?: Object;
  questionSets: any; // ImmutablePropTypes.listOf(PropTypes.instanceOf(getModelForResourceType('question_set_v1'))), TODO-KBC: Build typescript methods
  questionsListProps?: QuestionListProps;
  stageComponent?: React.ReactNode;
  showDifficulty?: boolean;
  titleBar?: React.ReactElement;
  isLoading: boolean;
  toolbar?: React.ReactNode;
  activeQuestionDisplayOrientation?: 'default' | 'vertical' | 'horizontal';
  hasQuestionSetAccessPredicate?: (questionSet: any) => boolean;
  QuestionListComponent?: React.ReactElement;
}

const hasQuestionSetAccessPredicateDefaultValue = (questionSet) => {
  const subject = questionSet.getSubject();
  const isRestricted = subject.getMeta().hasRestrictedFreePractice();
  if (sessionStore.isStudent() && isRestricted) {
    return false;
  }
  return (
    (subject.getMeta().isUserHasAccess() as boolean) || (questionSet.getMeta().isFree() as boolean)
  );
};

const MAIN_WRAPPER_PADDING = 32;

const PracticeView = ({
  removeQuestionListOnMobile = false,
  activeQuestion,
  activeQuestionSet,
  activeQuestionStore,
  activeGuess,
  activeSubject,
  activeQuestionDisplayOrientation = 'default',
  questionProps = {
    onQuestionClick: () => {}
  },
  // @ts-ignore - yelling b/c object doesn't have correct props initially
  questionRendererProps = {},
  questionSetProps = {},
  questionsListProps,
  questionSets,
  stageComponent,
  showDifficulty = true,
  titleBar,
  isLoading,
  params,
  toolbar,
  hasQuestionSetAccessPredicate = hasQuestionSetAccessPredicateDefaultValue,
  QuestionListComponent
}: PracticeViewProps) => {
  const {studentId} = params;
  const mainWrapperRef = useRef<HTMLDivElement>(null);
  const postSubmissionScrollPointRef = useRef<HTMLDivElement>(null);
  const previousQuestionId = usePrevious(activeQuestion && activeQuestion.getId());
  const previousStudentId = usePrevious(studentId);
  const previousRenderType = usePrevious(questionRendererProps?.rendererType);
  const {isMobile, isQuestionsListInView, setIsQuestionsListInView} =
    useContext(MobilePracticeViewContext);
  const {navBehavior} = useContext(GlobalNavBehaviorContext);

  // controlling the position of the question resizer to persist the position when switching between questions
  const [activeQuestionResizerPosition, setActiveQuestionResizerPosition] = useState<number>(() => {
    // When there is no logged in user, everything is done via SSR (for SEO purposes).
    // This means that when there is no logged in user, attempting to access `window` will cause a crash.
    // We can safely default to an arbitrary value since this will not be rendered in the browser anyway.
    if (!sessionStore.hasValidSession()) {
      return 0;
    }

    return isMobile
      ? 150
      : ((mainWrapperRef.current?.getBoundingClientRect().width ?? window.innerWidth) -
          MAIN_WRAPPER_PADDING) /
          2;
  });

  useEffect(() => {
    callAction(appActions.SET_FOOTER_DISPLAY, false);
    copyToClipboard.preventCopy();
    return () => {
      callAction(appActions.SET_FOOTER_DISPLAY, true);
      copyToClipboard.allowCopy();
    };
  }, []);

  useEffect(() => {
    // allow copying for free response questions
    if (activeQuestion && activeQuestion.getQuestionType() === QUESTION_TYPES.FREE_RESPONSE.name) {
      copyToClipboard.allowCopy();
    } else {
      copyToClipboard.preventCopy();
    }
  }, [activeQuestion]);

  useEffect(() => {
    updateScrollPosition();
  });

  const makeMobileOnQuestionClick = (args) => {
    return () => {
      if (isMobile && isQuestionsListInView) {
        setIsQuestionsListInView(false);
      }
      questionProps.onQuestionClick(...args)();
    };
  };

  const getIsQuestionChanging = (prevQuestionId) => {
    const activeQuestionId = activeQuestion && activeQuestion.getId();
    return activeQuestionId !== prevQuestionId;
  };

  const getIsStudentChanging = (prevStudentId) => {
    return studentId !== prevStudentId;
  };

  const getIsRendererTypeChanging = (prevRenderType) => {
    // we check if studentId exists or not because in ViewingStudentSubmission.tsx, a teacher can go from viewing a
    // student who didn't answer (PreGuess) to one who did which would trigger scroll behavior. By checking for if
    // studentId exists, we prevent getIsRendererTypeChanging from returning true which prevents scroll behavior
    return questionRendererProps?.rendererType !== prevRenderType && !studentId;
  };

  const updateScrollPosition = () => {
    const isQuestionChanging = getIsQuestionChanging(previousQuestionId);
    const isStudentChanging = getIsStudentChanging(previousStudentId);
    const isRendererTypeChanging = getIsRendererTypeChanging(previousRenderType);
    if (!isQuestionChanging && !isRendererTypeChanging && !isStudentChanging) {
      return;
    }
    if (
      isQuestionChanging ||
      isStudentChanging ||
      questionRendererProps?.rendererType === 'PreGuess'
    ) {
      // If we're going from one question to another, or if we're going from the PostGuess to the PreGuess state, we snap back to the top
      mainWrapperRef?.current?.scrollTo(0, 0);
    } else {
      // Otherwise, we're going from the PreGuess to the PostGuess state, so we scroll to the explanation
      const mainWrapperNode = mainWrapperRef.current;
      const scrollPointNode = postSubmissionScrollPointRef.current;

      if (!scrollPointNode) {
        return;
      }

      if (!mainWrapperNode) {
        return;
      }

      const mainWrapperTop = mainWrapperNode.getBoundingClientRect().top;
      const scrollPointTop = scrollPointNode.getBoundingClientRect().top;
      const offset = scrollPointTop - mainWrapperTop;

      mainWrapperNode.scrollBy({
        top: offset,
        left: 0,
        behavior: 'smooth'
      });
    }
  };

  let QuestionListToRender: JSX.Element | null = null;
  if (QuestionListComponent) {
    QuestionListToRender = QuestionListComponent;
  } else if (questionsListProps) {
    QuestionListToRender = (
      <QuestionsList
        {...questionsListProps!}
        className={classnames(
          'practice-view__questions-list u-display_flex u-flex-direction_column',
          {
            'practice-view__questions-list--in-view': isQuestionsListInView
          }
        )}
        activeQuestion={activeQuestion}
        questionSetProps={{
          ...questionSetProps,
          hasQuestionSetAccessPredicate
        }}
        questionProps={{
          ...questionProps,
          showDifficulty,
          onQuestionClick: (...args) => makeMobileOnQuestionClick(args)
        }}
        questionSets={questionSets}
        isLoading={isLoading}
      />
    );
  }

  return (
    <div
      className={classnames('u-display_flex u-flex-direction_column u-overflow_auto', {
        'practice-view--without-global-nav': navBehavior === GlobalNavBehaviorEnum.NOT_VISIBLE,
        'practice-view': navBehavior === GlobalNavBehaviorEnum.VISIBLE_STICKY
      })}
      data-testid='practice-view__container'
    >
      <div className='practice-view__title-bar'>
        {titleBar &&
          React.cloneElement(
            titleBar,
            isMobile && !isQuestionsListInView && !stageComponent
              ? {
                  imgHref: null,
                  title: 'Questions',
                  backButton: (
                    <TitleBar.BackButton
                      as='button'
                      label='Questions'
                      icon='chevron-left'
                      onClick={() => setIsQuestionsListInView(true)}
                    />
                  )
                }
              : {}
          )}
        {toolbar}
      </div>

      <div className='practice-view__main-wrapper u-display_flex u-justify-content_center u-flex-grow_1 u-overflow_hidden u-bgc_slate-100'>
        {stageComponent ? (
          <div className='practice-view__stage-component-scroll-handler u-bgc_slate-100 u-overflow_auto u-position_absolute u-width_100pc'>
            <div className='practice-view__stage-component-wrapper u-mar-lr_auto'>
              {stageComponent}
              <Footer minimal />
            </div>
          </div>
        ) : (
          <div className='practice-view__main u-display_flex u-flex-grow_1 u-overflow_hidden'>
            {(!isMobile || !removeQuestionListOnMobile) && QuestionListToRender}
            {/* Main Question Area */}
            <div
              className='practice-view__question-area u-flex-grow_1 u-overflow_auto'
              ref={mainWrapperRef}
              data-testid='practice-view__question-area'
            >
              <div
                className={classnames('practice-view__question-area u-flex-grow_1 u-height_100pc', {
                  'practice-view__question-area--dim': isMobile && isQuestionsListInView,
                  'u-mar-b_5': isMobile && removeQuestionListOnMobile, // needed since new sticky question list footer on AssignmentPracticeView cuts footer disclaimer off
                  'practice-view__mobile-redesign': activeQuestionDisplayOrientation !== 'default'
                })}
              >
                <OptionEliminatorProvider>
                  {activeQuestion ? (
                    <QuestionRenderer
                      {...questionRendererProps}
                      activeQuestion={activeQuestion}
                      activeQuestionSet={activeQuestionSet}
                      activeQuestionStore={activeQuestionStore}
                      activeGuess={activeGuess}
                      activeSubject={activeSubject}
                      displayOrientation={activeQuestionDisplayOrientation}
                      isMobile={isMobile}
                      onResize={(position) => setActiveQuestionResizerPosition(position)}
                      showDifficulty={showDifficulty}
                      resizerPosition={activeQuestionResizerPosition}
                      postSubmissionScrollPointRef={postSubmissionScrollPointRef}
                      hasQuestionSetAccessPredicate={hasQuestionSetAccessPredicate}
                    />
                  ) : (
                    <EmptyQuestionShell />
                  )}
                </OptionEliminatorProvider>
                {activeQuestionDisplayOrientation === 'default' && <Footer minimal />}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(PracticeView);
