import {MandarkResourceStatsInterface} from '../Mandark.interface';

export class AssignmentQuestionStatsInterface extends MandarkResourceStatsInterface {
  getId() {
    return 'id';
  }

  getStudentsThatHaveAnswered(id) {
    return this.getStatsForIdInFieldname('questions', id).get('total_answered');
  }

  getStudentsThatHaveAnsweredCorrectly(id) {
    return this.getStatsForIdInFieldname('questions', id).get('correct_answers');
  }

  getAverageTimeElapsed(id) {
    return this.getStatsForIdInFieldname('questions', id).get('average_time_elapsed') || 0;
  }

  getQuestionSetId(id) {
    return this.getStatsForIdInFieldname('questions', id).get('question_set_id');
  }

  getStudentsThatHaveAnsweredIncorrectly(id) {
    return this.getStatsForIdInFieldname('questions', id).get('incorrect_answers');
  }

  get all() {
    return this._data.getIn(['data', 'questions']);
  }
}
