import React, {useState, useCallback, ChangeEvent, useEffect} from 'react';
import {Map, Set} from 'immutable';
import {reduce, round} from 'lodash';
import ReactCrop from 'react-image-crop';
import download from 'downloadjs';
import {useShallow} from 'zustand/react/shallow';

import {Banner, Button, ButtonGroup, Modal, Dialogue, Heading} from '@albert-io/atomic';

import {PRIMARY_CONTENT_LANGUAGE} from 'client/EditPage/V2/QuestionEditorV2Store.types';
import {callAction} from 'client/framework';
import supplementEditorActions from 'client/Supplements/SupplementEditor/SupplementEditor.actions';
import supplementEditorStore from 'client/Supplements/SupplementEditor/SupplementEditor.store';
import {TranslationCommentStatusEditor} from 'client/Supplements/SupplementEditor/TranslationCommentStatusEditor';
import TextInput from 'client/generic/Forms/TextInput/TextInput';
import NumberInput from 'client/generic/Forms/NumberInput/NumberInput.react';
import UploadButton from 'client/generic/UploadButton/UploadButton.react';
import {useSupplementEditorV2Store} from 'client/Supplements/SupplementEditor/SupplementEditorV2Store';
import {AuthoringSupplement as AuthoringSupplementModelV1} from 'resources/GeneratedModels/AuthoringSupplement/AuthoringSupplementModel.v1';

import Image from './Image.react';

import './image-editor.scss';
import 'react-image-crop/dist/ReactCrop.css';

interface Props {
  supplement: AuthoringSupplementModelV1;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  errors: Map<string, any>;
}

const ImageEditor = ({supplement, onChange, errors}: Props) => {
  const {
    currentLanguage,
    updateTranslatedField,
    translatedName,
    translatedCaption,
    translatedAltText
  } = useSupplementEditorV2Store(
    useShallow((state) => ({
      currentLanguage: state.currentLanguage,
      updateTranslatedField: state.updateTranslatedField,
      translatedName:
        state
          .currentTranslatedSupplement()
          ?.translated_fields?.find((field) => field.field === 'name')?.text || '',
      translatedCaption:
        state
          .currentTranslatedSupplement()
          ?.translated_fields?.find((field) => field.field === 'content.caption')?.text || '',
      translatedAltText:
        state
          .currentTranslatedSupplement()
          ?.translated_fields?.find((field) => field.field === 'content.alttext')?.text || ''
    }))
  );

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const caption = supplement.getContent().get('caption', '');
    const alttext = supplement.getContent().get('alttext', '');

    // the point of this is to set the content-specific values for this type, when the type changes,
    // if they do not already exist.
    if (supplement.getCaption() !== caption || supplement.getAlttext() !== alttext) {
      callAction(supplementEditorActions.MODIFY_ACTIVE_SUPPLEMENT, [
        {
          setter: 'setCaption',
          value: caption
        },
        {
          setter: 'setAlttext',
          value: alttext
        }
      ]);
    }
  }, [supplement]);

  const handleShowModal = useCallback(() => {
    setShowModal(true);
  }, [setShowModal]);

  const handleCloseModal = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  const handleImageSizeChange = useCallback((e) => {
    let value = e.target.value ? parseInt(e.target.value, 10) : 100;
    if (value > 100) {
      value = 100;
    } else if (value < 1) {
      value = 1;
    }
    callAction(supplementEditorActions.MODIFY_ACTIVE_SUPPLEMENT, {
      setter: e.target.dataset.setterMethod,
      value
    });
  }, []);

  const uploadImage = useCallback(
    (e) => {
      const element = e.target;
      callAction(supplementEditorActions.UPLOAD_FILE, {
        name: element.name,
        setter: 'setImageUrl',
        file: element.files[0]
      });
      handleCloseModal();
    },
    [handleCloseModal]
  );

  const onCrop = useCallback((reactCropData) => {
    const crop = reduce(
      reactCropData,
      (formatted, value, key) => {
        if (key === 'aspect') {
          return formatted;
        }
        // eslint-disable-next-line no-param-reassign
        formatted[key] = round(value);
        return formatted;
      },
      {}
    );

    callAction(supplementEditorActions.MODIFY_ACTIVE_SUPPLEMENT, {
      setter: 'setCropValues',
      value: crop
    });
  }, []);

  const downloadImage = useCallback(() => {
    download(supplement.getImageLocation().href);
  }, [supplement]);

  const clearImage = useCallback(() => {
    const keySet = Set(['x', 'y', 'width', 'height', 'image']);
    const updatedContent = supplementEditorStore
      .getActiveSupplement()
      .getContent()
      .filterNot((value, key) => keySet.has(key));
    callAction(supplementEditorActions.MODIFY_ACTIVE_SUPPLEMENT, {
      setter: 'setContent',
      value: updatedContent
    });
    callAction(supplementEditorActions.VALIDATE_SUPPLEMENT, ['imageUrl']);
  }, []);

  const hasImage = supplement.getImageLocation().path;
  const source = supplement.getImageLocation().href;
  const crop = supplement.getCropValues().toJS();

  const nameValue =
    currentLanguage === PRIMARY_CONTENT_LANGUAGE ? supplement.getName() : translatedName;
  const captionValue =
    currentLanguage === PRIMARY_CONTENT_LANGUAGE ? supplement.getCaption() : translatedCaption;
  const altTextValue =
    currentLanguage === PRIMARY_CONTENT_LANGUAGE ? supplement.getAlttext() : translatedAltText;

  const handleNameChange = useCallback(
    (e) => {
      if (currentLanguage === PRIMARY_CONTENT_LANGUAGE) {
        onChange(e);
      } else {
        updateTranslatedField(currentLanguage, 'name', 'text', e.target.value);
      }
    },
    [currentLanguage, onChange, updateTranslatedField]
  );

  const handleCaptionChange = useCallback(
    (e) => {
      if (currentLanguage === PRIMARY_CONTENT_LANGUAGE) {
        onChange(e);
      } else {
        updateTranslatedField(currentLanguage, 'content.caption', 'text', e.target.value);
      }
    },
    [currentLanguage, onChange, updateTranslatedField]
  );

  const handleAltTextChange = useCallback(
    (e) => {
      if (currentLanguage === PRIMARY_CONTENT_LANGUAGE) {
        onChange(e);
      } else {
        updateTranslatedField(currentLanguage, 'content.alttext', 'text', e.target.value);
      }
    },
    [currentLanguage, onChange, updateTranslatedField]
  );

  const uploadManager = hasImage ? (
    <div>
      {supplement.mightBePDF() && (
        <Banner type='negative'>
          Based on the filename of this supplement, it looks like it might be a PDF. A PDF uploaded
          as an image supplement will be automatically reformatted to a PNG – this may degrade
          quality. We suggest uploading this asset as a PNG or JPEG.
        </Banner>
      )}
      {currentLanguage === PRIMARY_CONTENT_LANGUAGE && (
        <ReactCrop src={source} onComplete={onCrop} crop={crop} />
      )}

      <Image supplement={supplement} withZoom={false} />
      <ButtonGroup>
        <Button
          disabled={currentLanguage !== PRIMARY_CONTENT_LANGUAGE}
          onClick={handleShowModal}
          color='secondary'
        >
          Replace image
        </Button>
        <Button onClick={downloadImage} color='secondary'>
          Download image
        </Button>
      </ButtonGroup>
    </div>
  ) : (
    <UploadButton
      accept='image/*'
      disabled={supplementEditorStore.isUploadPending()}
      errorMessage={errors.get('imageUrl', null)}
      handleUpload={uploadImage}
    >
      Choose File
    </UploadButton>
  );

  const modal = showModal && (
    <Modal handleClose={handleCloseModal} ariaLabel='Confirm'>
      {({CloseButtonWrapper, modalContentStyle}) => (
        <Dialogue
          inModal
          title='Are you sure you want to replace this image?'
          className={modalContentStyle}
          handleClose={handleCloseModal}
        >
          <Dialogue.Body>
            This action cannot be undone and will impact all questions using this supplement. All
            questions will be updated with the new image.
          </Dialogue.Body>
          <Dialogue.BtnGroup>
            <CloseButtonWrapper>
              <Button color='secondary'>Cancel</Button>
            </CloseButtonWrapper>
            <UploadButton
              accept='image/*'
              disabled={supplementEditorStore.isUploadPending()}
              errorMessage={errors.get('imageUrl', null)}
              handleUpload={(e) => {
                clearImage();
                uploadImage(e);
              }}
            >
              Choose File
            </UploadButton>
          </Dialogue.BtnGroup>
        </Dialogue>
      )}
    </Modal>
  );

  return (
    <div className='image-editor'>
      <div className='image-editor__fieldset'>
        <Heading>Image Supplement Details</Heading>
        <TextInput
          className='image-editor__input'
          data-setter-method='setName'
          error={currentLanguage === PRIMARY_CONTENT_LANGUAGE && Boolean(errors.get('name'))}
          errorMessage={currentLanguage === PRIMARY_CONTENT_LANGUAGE && errors.get('name', null)}
          label='Image Name'
          name='name'
          onChange={handleNameChange}
          placeholder=''
          value={nameValue}
        />
        <TranslationCommentStatusEditor fieldName='name' />
        <TextInput
          className='image-editor__input'
          data-setter-method='setCaption'
          error={currentLanguage === PRIMARY_CONTENT_LANGUAGE && Boolean(errors.get('caption'))}
          errorMessage={currentLanguage === PRIMARY_CONTENT_LANGUAGE && errors.get('caption', null)}
          label='Caption'
          name='caption'
          onChange={handleCaptionChange}
          placeholder=''
          value={captionValue}
        />
        <TranslationCommentStatusEditor fieldName='content.caption' />
        <TextInput
          className='image-editor__input'
          data-setter-method='setAlttext'
          error={currentLanguage === PRIMARY_CONTENT_LANGUAGE && Boolean(errors.get('alttext'))}
          errorMessage={currentLanguage === PRIMARY_CONTENT_LANGUAGE && errors.get('alttext', null)}
          label='Alt text'
          name='alttext'
          onChange={handleAltTextChange}
          placeholder=''
          value={altTextValue}
        />
        <TranslationCommentStatusEditor fieldName='content.alttext' />
        <NumberInput
          disabled={currentLanguage !== PRIMARY_CONTENT_LANGUAGE}
          className='image-editor__input'
          data-setter-method='setImageSize'
          label='Scale (%)'
          name='imageSize'
          min={1}
          max={100}
          error={Boolean(errors.get('imageSize'))}
          errorMessage={errors.get('imageSize', null)}
          onChange={handleImageSizeChange}
          placeholder='100%'
          value={supplement.getImageSize()}
        />
      </div>
      <div className='image-editor__input image-editor__input--upload-manager'>{uploadManager}</div>
      {modal}
    </div>
  );
};

export default ImageEditor;
