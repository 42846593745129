import PropTypes from 'prop-types';
import {range} from 'lodash';

const NUM_PADDING_LEVELS = 12;

export const paddingLevels = range(NUM_PADDING_LEVELS + 1).reduce(
  (accumulator, currentValue) => ({
    ...accumulator,
    [currentValue.toString()]: currentValue
  }),
  {}
);

export const paddingLevelPropType = PropTypes.oneOf(Object.values(paddingLevels));
