/**
 * # IconButton
 *
 * A round button that only contains an icon. `aria-hidden` OR `label` is required as a prop.
 *
 * ## Props<C extends Resct.ElementType>
 *
 * - `as?: React.ElementType`: The HTML element or React component to render as. Default is 'button'.
 * - `aria-hidden?: boolean`: Specifies whether the button is hidden from screen readers.
 * - `color?: ButtonColor`: The color variant of the button. Default is 'secondary'.
 * - `variant?: ButtonVariant`: The visual variant of the button. Default is 'text'.
 * - `roundness?: ButtonRoundness`: The roundness of the button. Default is 'circle'.
 * - `className?: string`: Additional CSS class names for the button.
 * - `iconStyle?: IconStyle`: The style variant of the icon.
 * - `icon: IconProp`: The icon to be displayed.
 * - `iconProps?: IconProps`: Additional props to be passed to the icon component. See the Icon atom for props.
 * - `label?: string`: The label for the button.
 * - `size?: 's' | 'm' | 'l'`: The size variant of the button. Default is 'm'.
 * - `children?: React.ReactNode`: The content to be rendered inside the button.
 * - `...rest: C`: Any props belonging to the component or element passed as `as`.
 *
 *
 * ## Usage
 *
 * ```tsx
 * import IconButton from './IconButton.react';
 *
 * const MyComponent = () => {
 *   return (
 *     <IconButton
 *       icon="heart"
 *       label="Like"
 *       size="m"
 *       color="primary"
 *       variant="contained"
 *       roundness="rounded"
 *     />
 *   );
 * };
 * ```
 */

import React, {forwardRef} from 'react';
import classnames from 'classnames';

import Icon, {IconProp, IconStyle} from '../../atoms/Icon/Icon.react';
import Button, {ButtonColor, ButtonRoundness, ButtonVariant} from '../../atoms/Button/Button.react';
import './icon-button.scss';

interface Props extends PropsWithChildrenOptional, PropsWithClassNameOptional {
  as?: React.ElementType;
  'aria-hidden'?: boolean;
  color?: ButtonColor;
  variant?: ButtonVariant;
  roundness?: ButtonRoundness;
  iconStyle?: IconStyle;
  icon: IconProp;
  iconProps?: any;
  label?: string;
  size?: 's' | 'm' | 'l';
}

type RequireAriaHiddenOrLabel = RequireAtLeastOne<Props, 'aria-hidden' | 'label'>;

export type IconButtonProps<C extends React.ElementType> = PolymorphicComponentPropsWithRef<
  C,
  RequireAriaHiddenOrLabel
>;

type IconButtonComponent = <C extends React.ElementType = 'button'>(
  props: IconButtonProps<C>
) => React.ReactElement | null;

export const IconButton: IconButtonComponent = forwardRef(
  (
    {
      as = 'button',
      className,
      color = 'secondary',
      variant = 'text',
      icon,
      iconStyle,
      label,
      size = 'm',
      children,
      roundness = 'circle',
      iconProps,
      ...rest
    }: RequireAriaHiddenOrLabel,
    ref
  ) => {
    return (
      <Button
        {...rest}
        as={as}
        aria-label={label}
        className={classnames('m-icon-button', className, {
          [`m-icon-button--size-${size}`]: size
        })}
        variant={variant}
        color={color}
        roundness={roundness}
        ref={ref}
      >
        <Icon icon={icon} iconStyle={iconStyle} {...iconProps} />
        {children}
      </Button>
    );
  }
);

export default IconButton;
