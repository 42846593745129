import React, {lazy} from 'react';
import {Route} from 'react-router';

import awaitMandarkQueries from 'lib/hocs/awaitMandarkQueries';
import {getDoesUserBelongToAnyLicensedClassroomsQuery} from 'lib/UserAccessUtil';

import sessionStore from 'client/Session/SessionStore';
import {getFolderQuestionSetsQuery} from 'client/FolderPracticeView/folderPracticeView.queries';

import SecondaryHeader from './shared/SecondaryHeader';
import createAssignmentStore from './CreateAssignment.store';

const CreateAssignmentWrapper = lazy(() => import('./CreateAssignmentWrapper.react'));

function requireTeacherStatus(nextState, replace) {
  if (process.env.IS_BROWSER) {
    if (!sessionStore.isTeacher()) {
      replace('/', null);
    }
  }
}

const CreateAssignmentLayout = (props) => (
  <div>
    <SecondaryHeader {...props} />
    <CreateAssignmentWrapper {...props} />
  </div>
);

export default (
  <Route
    name='create-assignment'
    onEnter={requireTeacherStatus}
    path='create-assignment'
    title='Create an Assignment | Albert.io'
  >
    <Route
      path='new-assignment'
      onEnter={requireTeacherStatus}
      component={awaitMandarkQueries(() => {
        return {
          queries: {
            licensedSubjects: getDoesUserBelongToAnyLicensedClassroomsQuery(true)
          }
        };
      }, CreateAssignmentLayout)}
    />

    <Route
      path='subject/:subjectSlug/topic/:topicSlug'
      onEnter={requireTeacherStatus}
      component={awaitMandarkQueries(({params}) => {
        return {
          queries: {
            licensedSubjects: getDoesUserBelongToAnyLicensedClassroomsQuery(true),
            questionSets: createAssignmentStore.getQueryForSubjectSlug(params)
          }
        };
      }, CreateAssignmentLayout)}
    />
    <Route
      path='subject/:subjectSlug/topic/:topicSlug/question-set/:questionSetSlug'
      onEnter={requireTeacherStatus}
      component={awaitMandarkQueries(({params}) => {
        return {
          queries: {
            licensedSubjects: getDoesUserBelongToAnyLicensedClassroomsQuery(true),
            questionSets: createAssignmentStore.getQueryForSubjectSlug(params)
          }
        };
      }, CreateAssignmentLayout)}
    />
    <Route
      path='folder/:folderId'
      onEnter={requireTeacherStatus}
      component={awaitMandarkQueries(({params}) => {
        const queries = {
          folder: createAssignmentStore.getQueryForFolder(params),
          licensedSubjects: getDoesUserBelongToAnyLicensedClassroomsQuery(true)
        };
        if (global.window.location.search) {
          queries.folderQuestionSets = getFolderQuestionSetsQuery(params.folderId, 'infinite');
        }
        return {
          queries
        };
      }, CreateAssignmentLayout)}
    />
    <Route
      path='assignment/:assignmentId/classroom/:classroomId/edit'
      onEnter={requireTeacherStatus}
      component={awaitMandarkQueries(({params}) => {
        return {
          queries: {
            licensedSubjects: getDoesUserBelongToAnyLicensedClassroomsQuery(true),
            assignment: createAssignmentStore.getQueryForAssignment(params)
          }
        };
      }, CreateAssignmentLayout)}
    />
  </Route>
);
