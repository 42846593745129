/**
 * # TabNavigation
 *
 * A component that renders a tab navigation bar. Provides `TabNavigation.Tab` as a subcomponent`
 *
 * ## Props
 *
 * - `align?: keyof typeof alignValues` (extends `left`, `center`, `right`): The alignment of the tab navigation.
 * - `children?: React.ReactNode`: The content to be rendered inside the tab navigation.
 * - `className?: string`: Additional CSS class name for the tab navigation.
 * - `fillWidth?: boolean`: Whether the tab navigation should fill the entire width.
 * - `paddingLevel?: 0 | 1 | 2 | 3 | 4`: The padding level of the tab navigation.
 *
 * ### `TabNavigation.Tab` Props (Inherits from `Tab<T>`)
 *
 * - `as?: T`: element name or component type to inherit props from. Defaults to 'div'.
 * - `className?: string`: Additional class name for the tab.
 * - `isActive?: boolean`: Determines if the tab is active.
 * - `children?: React.ReactNode`: The content of the tab.
 * - `...rest`: Any props of type `T`.
 *
 * ## Usage
 *
 * ```tsx
 * import TabNavigation from './TabNavigation.react';
 *
 * const MyComponent = () => {
 *   return (
 *     <TabNavigation align="center" fillWidth={true} paddingLevel={2}>
 *       <TabNavigation.Tab>Tab 1</TabNavigation.Tab>
 *       <TabNavigation.Tab>Tab 2</TabNavigation.Tab>
 *       <TabNavigation.Tab>Tab 3</TabNavigation.Tab>
 *     </TabNavigation>
 *   );
 * };
 * ```
 */

import React from 'react';
import classnames from 'classnames';
import makeConstants from 'lib/makeConstants';

import Tab from '../../atoms/Tab/Tab.react';

import './tab-navigation.scss';

const alignValues = makeConstants('left', 'center', 'right');

interface Props extends PropsWithChildrenOptional, PropsWithClassNameOptional {
  align?: keyof typeof alignValues;
  fillWidth?: boolean;
  paddingLevel?: 0 | 1 | 2 | 3 | 4;
}

type TabNavigationProps<C extends React.ElementType> = PolymorphicComponentProps<C, Props>;

const TabNavigation = <C extends React.ElementType = 'div'>({
  as,
  align = 'center',
  fillWidth = false,
  children,
  className,
  paddingLevel = 4,
  ...rest
}: TabNavigationProps<C>) => {
  const WrapperElement = as || 'div';
  return (
    <WrapperElement
      className={classnames(
        'm-tab-navigation',
        `m-tab-navigation--align-${align}`,
        'u-overflow_auto',
        className,
        {
          'm-tab-navigation--fill': fillWidth,
          [`u-pad-lr_${paddingLevel}`]: paddingLevel !== 0
        }
      )}
      {...rest}
    >
      {children}
    </WrapperElement>
  );
};

export default Object.assign(TabNavigation, {Tab});
