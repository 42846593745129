import {history} from 'client/history';

import {showGlobalModal} from '@albert-io/atomic';

import LogInModal from './LogInModal.react';

export const LOG_IN_PAGE_BASE_PATH = '/log-in';

export function redirectToLoginPageFromLocation() {
  history.replaceState(null, LOG_IN_PAGE_BASE_PATH);
}

export function hardRedirectToLoginPage() {
  global.document.location = LOG_IN_PAGE_BASE_PATH;
}

export function showGlobalLoginModal() {
  showGlobalModal(LogInModal);
}
