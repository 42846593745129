/**
 * # TextAreaField
 *
 * A component that combines the functionality of the TextArea and Field components.
 *
 * ## Props
 *
 * - `...TextAreaProps` (extends TextAreaProps): Props for the TextArea component.
 * - `...FieldProps` (extends FieldProps): Props for the Field component.
 *
 * ## Usage
 *
 * ```tsx
 * import TextAreaField from './TextAreaField.react';
 *
 * const Example = () => {
 *   return (
 *     <TextAreaField
 *       name="message"
 *       label="Message"
 *       placeholder="Enter your message"
 *       rows={4}
 *     />
 *   );
 * };
 * ```
 */

import React from 'react';

import TextArea, {TextAreaProps} from '../../atoms/TextArea/TextArea.react';
import Field, {Props as FieldProps} from '../Field/Field.react';

type TextAreaFieldProps = TextAreaProps & FieldProps;

const TextAreaField = (props: TextAreaFieldProps) => <Field as={TextArea} {...props} />;

export default TextAreaField;
