// @flow
import {fromJS} from 'immutable';
import {Store} from 'client/framework';
import tooltipActions from './Tooltip.actions';

export default class TooltipStore extends Store {
  constructor(name: string) {
    super(name);
    this.initialData = fromJS({
      isOpen: false
    });
    this.setInitialData(this.initialData);
    this.handleTargeted(tooltipActions.TOGGLE_VISIBILITY, this._toggleVisibility);
    this.handleTargeted(tooltipActions.SET_VISIBILITY, this._setVisibility);
  }

  _toggleVisibility() {
    this.writeData('isOpen', !this.isOpen());
  }

  _setVisibility(bool: boolean) {
    this.writeData('isOpen', bool);
  }

  isOpen(): boolean {
    return this.readData('isOpen');
  }
}
