import React, {useState, useRef, useEffect} from 'react';
import classnames from 'classnames';
import {useControls, useTransformEffect} from 'react-zoom-pan-pinch';
import {Text, IconButton, WithTooltip} from '@albert-io/atomic';

import {MIN_ZOOM, MAX_ZOOM, ZOOM_LEVELS} from './constants';

import './image-v2.scss';

interface ImageControlsProps {
  isMobile: boolean;
  isFullScreen?: boolean;
  showHideImage?: boolean;
  setIsHidden?: (isHidden: boolean) => void;
  showFullScreen?: boolean;
  setIsFullScreen?: (isFullScreen: boolean) => void;
  showZoom?: boolean;
  setShouldDisablePanning?: (shouldDisablePanning: boolean) => void; // used to disable panning on mobile if we are not zoomed in
}

export const ImageControls = ({
  isMobile,
  isFullScreen,
  showHideImage,
  setIsHidden,
  showFullScreen,
  setIsFullScreen,
  showZoom,
  setShouldDisablePanning
}: ImageControlsProps) => {
  const {centerView} = useControls();
  const [currZoomScale, setCurZoomScale] = useState(MIN_ZOOM);
  const [showZoomOptions, setShowZoomOptions] = useState(false);

  const hideZoomOptionsRef = useRef<HTMLButtonElement>(null);
  const showZoomOptionsRef = useRef<HTMLButtonElement>(null);

  // maintain focus within the zoom when toggling between show/hide
  useEffect(() => {
    if (showZoomOptions) {
      hideZoomOptionsRef.current?.focus();
    } else {
      showZoomOptionsRef.current?.focus();
    }
  }, [showZoomOptions]);

  useEffect(() => {
    if (isMobile && currZoomScale === MIN_ZOOM) {
      setShouldDisablePanning?.(true);
    } else {
      setShouldDisablePanning?.(false);
    }
  }, [isMobile, currZoomScale, setShouldDisablePanning]);

  useTransformEffect(({state}) => {
    const {scale} = state;
    setCurZoomScale(scale);
  });

  // zoom steps should go to the next closest zoom step in the array based on the current zoom scale
  // ie. mouse zoom to 1.14 -> clicking zoom in goes to 1.25
  const handleZoomIn = () => {
    const nextZoom = ZOOM_LEVELS.find((zoomLevel) => zoomLevel > currZoomScale);
    if (nextZoom) {
      centerView(nextZoom);
    }
  };

  const handleZoomOut = () => {
    const reversedZoomLevels = [...ZOOM_LEVELS].reverse();
    const prevZoom = reversedZoomLevels.find((zoomLevel) => zoomLevel < currZoomScale);
    if (prevZoom) {
      centerView(prevZoom);
    }
  };

  const fullScreenBtnText = isFullScreen ? 'Exit fullscreen' : 'Fullscreen';

  // disable all tooltips on mobile, to prevent double clicking of buttons
  const enableToolTips = !isMobile;

  return (
    <div
      className={classnames('image-supplement__controls', {
        'image-supplement__controls--fullscreen': isFullScreen
      })}
    >
      {showHideImage && (
        <WithTooltip enabled={enableToolTips} content='Hide Image' placement='bottom' theme='dark'>
          <IconButton
            size={isMobile ? 'm' : 's'}
            color='secondary'
            variant='outlined'
            icon={['far', 'eye']}
            label='Hide Image'
            className='image-supplement__controls-button'
            onClick={(e) => {
              e.stopPropagation();
              setIsHidden?.(true);
            }}
          />
        </WithTooltip>
      )}
      {showZoom && (
        <div
          className={classnames('image-supplement__controls-zoom', {
            'image-supplement__controls-zoom--expanded': showZoomOptions
          })}
        >
          {showZoomOptions ? (
            <div className='image-supplement__controls-zoom-options'>
              <IconButton
                size={isMobile ? 'm' : 's'}
                color='secondary'
                variant='text'
                icon={['far', 'search-minus']}
                label='Close Zoom Options'
                className='image-supplement__controls-button'
                ref={hideZoomOptionsRef}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowZoomOptions(false);
                }}
              />
              <Text size='m' color='tertiary'>
                {Math.floor(currZoomScale * 100)}%
              </Text>
              <IconButton
                color='secondary'
                variant='text'
                icon={['far', 'minus']}
                size={isMobile ? 'm' : 's'}
                label='Zoom out'
                className='image-supplement__controls-button'
                disabled={currZoomScale === MIN_ZOOM}
                onClick={(e) => {
                  e.stopPropagation();
                  handleZoomOut();
                }}
              />
              <IconButton
                color='secondary'
                variant='text'
                icon={['far', 'plus']}
                size={isMobile ? 'm' : 's'}
                label='Zoom In'
                className='image-supplement__controls-button'
                disabled={currZoomScale === MAX_ZOOM}
                onClick={(e) => {
                  e.stopPropagation();
                  handleZoomIn();
                }}
              />
            </div>
          ) : (
            <WithTooltip
              enabled={enableToolTips}
              content='Zoom Options'
              placement='bottom'
              theme='dark'
            >
              <IconButton
                ref={showZoomOptionsRef}
                color='secondary'
                variant='outlined'
                icon={['far', 'search-plus']}
                size={isMobile ? 'm' : 's'}
                label='Zoom Options'
                className='image-supplement__controls-button'
                onClick={(e) => {
                  e.stopPropagation();
                  setShowZoomOptions(true);
                }}
              />
            </WithTooltip>
          )}
        </div>
      )}
      {showFullScreen && (
        <WithTooltip
          enabled={enableToolTips}
          content={fullScreenBtnText}
          placement='bottom'
          theme='dark'
        >
          <IconButton
            color='secondary'
            variant='outlined'
            icon={[
              'far',
              isFullScreen
                ? 'arrow-down-left-and-arrow-up-right-to-center'
                : 'arrow-up-right-and-arrow-down-left-from-center'
            ]}
            size={isMobile ? 'm' : 's'}
            label={fullScreenBtnText}
            className='image-supplement__controls-button'
            onClick={(e) => {
              e.stopPropagation();
              setIsFullScreen?.(!isFullScreen);
            }}
          />
        </WithTooltip>
      )}
    </div>
  );
};
