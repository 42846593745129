// @flow
import {find} from 'lodash';

export const SETTINGS = {
  SHOW_AFTER_ALL: 'showAfterAll',
  SHOW_AFTER_EACH: 'showAfterEach',
  SHOW_AFTER_DUE_DATE: 'showAfterDueDate'
} as const;

export const correctAnswerSettingsMap = {
  'After each question': SETTINGS.SHOW_AFTER_EACH,
  'After assignment completion': SETTINGS.SHOW_AFTER_ALL,
  'After due date has passed': SETTINGS.SHOW_AFTER_DUE_DATE
} as const;

const CONSTANTS = {
  /**
   * LEGACY – Prefer use of the more verbose objects that provide the expected values
   * and various pieces of copy describing the "correct_answer_setting".
   * @todo – Remove refernces to this legacy object.
   */
  explanationSettings: SETTINGS,

  CORRECT_ANSWER_SETTINGS: {
    SHOW_AFTER_ALL: {
      VALUE: SETTINGS.SHOW_AFTER_ALL,
      DESCRIPTION: {
        STUDENT: 'Explanations will be shown after you complete your assignment.'
      }
    },
    SHOW_AFTER_EACH: {
      VALUE: SETTINGS.SHOW_AFTER_EACH,
      DESCRIPTION: {
        STUDENT: 'Explanations will be shown after you answer each question.'
      }
    },
    SHOW_AFTER_DUE_DATE: {
      VALUE: SETTINGS.SHOW_AFTER_DUE_DATE,
      DESCRIPTION: {
        STUDENT: 'Explanations will be shown after the due date for this assignment passes.'
      }
    }
  }
};

/**
 * Returns the CORRECT_ANSWER_SETTINGS object that matches the provided value.
 */
export function getCorrectAnswerSettingFromValue(value: string): Object | undefined {
  return find(CONSTANTS.CORRECT_ANSWER_SETTINGS, (SETTING) => SETTING.VALUE === value);
}
export default CONSTANTS;
