import {resource} from '@albert-io/json-api-framework/request/builder';
import masqueradeStore from 'client/generic/Masquerade/Masquerade.store';

import {FilterOptions, maybeApplyFilterMetaContext} from '../../ContentDiscovery.utils';

export const getSubjectsQuery = (
  subjectSearchString,
  areSubjectsPersonalized,
  guideSearchString = '',
  contentDiscoveryFilters: FilterOptions
) =>
  maybeApplyFilterMetaContext(
    resource('subjects_v2')
      .mandarkEndpoint(['subjects_v2'])
      .withMeta('subject_v2')
      .filter({
        hidden: false
      })
      .include('curriculum_area_v1')
      .pageSize('infinite')
      .meta({
        context: {
          user: {
            personalization_settings: areSubjectsPersonalized,
            id: masqueradeStore.getUserIdByMasqueradeState()
          }
        }
      })
      .meta(
        {
          context: {
            search: {
              fields: 'name,description',
              term: subjectSearchString
            }
          }
        },
        subjectSearchString.length
      )
      .filter(
        {
          'meta.included_in_personalization_settings': areSubjectsPersonalized
        },
        areSubjectsPersonalized
      )
      .filter(
        {
          any_of: [
            {
              name: {search_keyword: subjectSearchString}
            },
            {
              description: {search_keyword: subjectSearchString}
            }
          ]
        },
        subjectSearchString.length
      )
      .filter(
        {
          'meta.matching_guide_levels_search_count': {
            greater_than: 0
          }
        },
        guideSearchString.length
      )
      .meta(
        {
          context: {
            matching_guide_levels: {
              search_term: guideSearchString,
              search_fields: 'name,description'
            }
          }
        },
        guideSearchString.length
      ),
    contentDiscoveryFilters
  );

export const getClassroomsSubjects = (
  classroomIds,
  subjectSearchString = '',
  guideSearchString = '',
  contentDiscoveryFilters: FilterOptions
) =>
  maybeApplyFilterMetaContext(
    resource('subjects_v2')
      .mandarkEndpoint(['subjects_v2'])
      .withMeta('subject_v2')
      .include('curriculum_area_v1')
      .filter({
        hidden: false,
        classrooms_v1: classroomIds
      })
      .meta({
        context: {
          user: {
            id: masqueradeStore.getUserIdByMasqueradeState()
          }
        }
      })
      .pageSize('infinite')
      .meta(
        {
          context: {
            search: {
              fields: 'name,description',
              term: subjectSearchString
            }
          }
        },
        subjectSearchString.length
      )
      .filter(
        {
          any_of: [
            {
              name: {search_keyword: subjectSearchString}
            },
            {
              description: {search_keyword: subjectSearchString}
            }
          ]
        },
        subjectSearchString.length
      )
      .filter(
        {
          'meta.matching_guide_levels_search_count': {
            greater_than: 0
          }
        },
        guideSearchString.length
      )
      .meta(
        {
          context: {
            matching_guide_levels: {
              search_term: guideSearchString,
              search_fields: 'name,description'
            }
          }
        },
        guideSearchString.length
      ),
    contentDiscoveryFilters
  );

export const getCurriculumAreas = () =>
  resource('curriculum_areas_v1')
    .mandarkEndpoint(['curriculum_areas_v1'])
    .include('subjects_v2')
    .pageSize(200);

// retrieves all guides (practice, assignment & FRQ) for a given subject
export const getGuidesFromSubject = (
  subjectId,
  subjectGuideSearchString,
  contentDiscoveryFilters
) =>
  maybeApplyFilterMetaContext(
    resource('guides_v1')
      .mandarkEndpoint(['guides_v1'])
      .filter({
        subject_v2: subjectId
      })
      .meta({
        context: {
          content_discovery_filtering: true,
          user: {
            id: masqueradeStore.getUserIdByMasqueradeState()
          }
        }
      })
      .meta(
        {
          context: {
            search: {
              fields: 'name,description',
              term: subjectGuideSearchString
            }
          }
        },
        subjectGuideSearchString
      )
      .filter(
        {
          guide_levels_v2: {
            'meta.matches_search': true
          }
        },
        subjectGuideSearchString
      )
      .withMeta('guide_v1,guide_level_v2')
      .include('guide_levels_v2.subject_v2,guide_levels_v2.guide_v1,subject_v2'),
    contentDiscoveryFilters
  );
