import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {Card, Banner, Text, Anchor} from '@albert-io/atomic';
import {resource} from '@albert-io/json-api-framework/request/builder';

export const UnlicensedErrorComponent = () => (
  <div className='u-display_flex u-justify-content_center'>
    <Card shadow='default' paddingLevel={4} className='u-mar_2 u-max-width_text'>
      <Banner theme='danger'>
        <Banner.Icon icon='exclamation-triangle' />
        <Text>You don&apos;t have access to this assignment.</Text>
      </Banner>
      <Text className='u-mar_2'>
        <p>
          This is because you are not licensed for some or all of the questions included in this
          assignment.
        </p>
        <p>
          Please report this issue to your teacher and ask them to check their license status. If
          you are a teacher, and aren&apos;t sure how to resolve this issue please reach out to our
          support team at <Anchor href='mailto:hello@albert.io'>hello@albert.io</Anchor>.
        </p>
      </Text>
    </Card>
  </div>
);

export const StudentErrorComponent = (
  <div className='u-display_flex u-justify-content_center'>
    <Card shadow='default' paddingLevel={4} className='u-mar_2 u-max-width_text'>
      <Banner type='negative'>
        <Banner.Icon icon='exclamation-triangle' />
        <Text>You don&apos;t have access to this assignment.</Text>
      </Banner>
      <Text className='u-mar_2'>
        <p>
          This is either because you have not been assigned the assignment or because the assignment
          has not started. If you believe this is an error, try the following:
        </p>
        <ol>
          <li>Make sure you are logged in with the right account.</li>
          <li>
            Ask your teacher to make sure the assignment has started (i.e. the start date is not in
            the future).
          </li>
          <li>Ask your teacher to make sure you have been assigned the assignment. </li>
        </ol>
        <p>
          If you are still having issues, please contact our support team at{' '}
          <Anchor href='mailto:hello@albert.io'>hello@albert.io</Anchor>.
        </p>
      </Text>
    </Card>
  </div>
);

/**
 * @param id
 * @todo It's unclear if we actually want to use this or not... for now, we won't!
 * @see https://github.com/albert-io/project-management/issues/3941
 */
// export const TeacherErrorComponent = (
//   <div className='u-display_flex u-justify-content_center'>
//     <Card shadow='default' paddingLevel={4} className='u-mar_2 u-max-width_text'>
//       <Banner type='negative'>
//         <Banner.Icon icon='exclamation-triangle' />
//         <Text>
//           Unfortunately, at this time, only students can view assignment links. When your students click on this link, they will be able to take your assignment.
//         </Text>
//       </Banner>
//     </Card>
//   </div>
// );

function getAssignmentQuery(id) {
  return resource('assignment_v3').mandarkEndpoint(['assignments_v3', id]);
}

const AssignmentErrorInterstitial = ({
  assignmentId,
  errorComponent = (
    <div className='u-display_flex u-justify-content_center'>
      <Card shadow='default' paddingLevel={4} className='u-mar_2 u-max-width_text'>
        <Banner theme='danger'>
          <Banner.Icon icon='exclamation-triangle' />
          <Text>An error was encountered while attempting to load that assignment.</Text>
        </Banner>
      </Card>
    </div>
  ),
  children
}) => {
  const [error, setError] = useState(false);
  const [ready, setReady] = useState(false);
  const [assignment, setAssignment] = useState();

  useEffect(() => {
    if (!assignment) {
      getAssignment();
    }
  });

  const getAssignment = async () => {
    try {
      const returnedAssignment = await getAssignmentQuery(assignmentId).getResourcePromise();
      setAssignment(returnedAssignment);
    } catch {
      setError(true);
    } finally {
      setReady(true);
    }
  };

  if (!ready) {
    return null;
  }

  if (error || !assignment) {
    return errorComponent;
  }

  return <>{children}</>;
};

AssignmentErrorInterstitial.propTypes = {
  assignmentId: PropTypes.string.isRequired,
  errorComponent: PropTypes.node,
  children: PropTypes.node
};

export default AssignmentErrorInterstitial;
