import React, {useState, useRef, useCallback} from 'react';
import sessionStore from 'client/Session/SessionStore';
import {
  FindAndApply,
  Button,
  Icon,
  Popover,
  ListGroup,
  ListGroupItem,
  Checkbox,
  Label,
  Text,
  ButtonGroup,
  addGenericErrorToast
} from '@albert-io/atomic';
import {ButtonVariant, ButtonColor} from '@albert-io/atomic/atoms/Button/Button.react';
import {List, Set} from 'immutable';
import {StandardSetModelV1} from 'resources/augmented/StandardSet/StandardSetModel.v1';
import {resource} from '@albert-io/json-api-framework/request/builder';
import classnames from 'classnames';
import {pluralize} from 'lib/stringUtils';

import '../advanced-search.scss';

interface Props {
  onApply: (standardSets: List<StandardSetModelV1>) => void;
  subjectId?: string;
  standardSets: List<StandardSetModelV1>;
}

type FilterOption = {
  [index: string]:
    | {
        search_keyword: string;
      }
    | boolean;
};

const StandardSetFilter = ({onApply, subjectId, standardSets}: Props) => {
  const [showPopover, setShowPopover] = useState(false);

  const buttonRef = useRef(null);

  const getQuery = useCallback(
    (searchString) => {
      const anyOfOptions: FilterOption[] = [
        {
          title: {
            search_keyword: searchString
          }
        },
        {
          description: {
            search_keyword: searchString
          }
        }
      ];
      // only show personalized results if user hasn't typed in any input
      if (searchString === '') {
        anyOfOptions.push({
          'meta.included_in_personalization_settings': true
        });
      }
      return resource('standard_sets_v1')
        .mandarkEndpoint(['standard_sets_v1'])
        .withMeta('standard_set_v1')
        .customQuery({
          meta: {
            context: {
              user: {
                id: sessionStore.getUserId(),
                personalization_settings: true
              }
            }
          }
        })
        .filter(
          subjectId
            ? {
                all_of: [
                  {
                    any_of: anyOfOptions
                  },
                  {
                    standards_v1: {
                      questions_v3: {
                        question_set_v1: {
                          subject_v2: subjectId
                        }
                      }
                    }
                  }
                ]
              }
            : {
                any_of: anyOfOptions
              }
        )
        .pageSize(500)
        .customQuery({meta: {context: {search: {fields: 'title,description'}}}});
    },
    [subjectId]
  );

  return (
    <>
      <span
        className={classnames('u-display_flex u-bgc_transparent u-border-radius_1', {
          'advanced-search__standard-sets-button-wrapper-active': standardSets.size > 0
        })}
        ref={buttonRef}
      >
        <Button
          variant={(standardSets.size ? undefined : 'outlined') as ButtonVariant}
          color={(standardSets.size ? 'inherit' : 'secondary') as ButtonColor}
          onClick={() => setShowPopover(!showPopover)}
        >
          {`${standardSets.size > 0 ? standardSets.size : ''} ${pluralize(
            'Standard Set',
            standardSets.size
          )}`}
          <Icon icon='caret-down' className='u-mar-l_2' />
        </Button>
      </span>
      {showPopover && (
        <Popover
          usePortal={false}
          border='none'
          expanded={showPopover}
          position='bottom-start'
          paddingLevel={null}
          targetRef={buttonRef}
          modifiers={{flip: {enabled: false}}}
        >
          <FindAndApply
            className='advanced-search_filter-dropdown u-width_100pc'
            query={getQuery}
            onApply={onApply}
            onDismiss={() => setShowPopover(!showPopover)}
            fetchInitial
            defaultSelectedItems={standardSets}
            onError={addGenericErrorToast}
          >
            <FindAndApply.Header paddingLevel={1}>
              {({HeaderSearchInput}) => (
                <HeaderSearchInput
                  variant
                  size='s'
                  placeholder='Search standard sets'
                  className='standard-set-filter__search-input'
                />
              )}
            </FindAndApply.Header>
            <FindAndApply.Body paddingLevel={0}>
              {({searchResults, selectedItems, handleChange, searchString}) => {
                if (
                  !searchString &&
                  !searchResults.some((result) =>
                    result.getMeta().isIncludedInPersonalizationSettings()
                  )
                ) {
                  return (
                    <FindAndApply.Message>
                      Search by Standard set name such as &quot;Common Core Math&quot; or &quot;ACT
                      WorkKey Skills&quot;
                    </FindAndApply.Message>
                  );
                }

                // don't show "pinned suggestions" if user has input anything into the search bar
                if (searchString.length) {
                  return (
                    <ListGroup as='ul' border='none' className='u-pad-t_0 u-mar-t_0'>
                      {searchResults.map((result) => (
                        <ListGroupItem
                          key={result.getId()}
                          paddingLevel={1}
                          border={false}
                          as='li'
                          className='u-display_flex u-gap_space-x1 u-align-items_center'
                          onClick={() => handleChange(result)}
                        >
                          <Checkbox
                            aria-hidden={false}
                            checked={selectedItems.has(result)}
                            size='s'
                            className='u-mar-l_1'
                            onClick={(e) => {
                              handleChange(result);
                              e.stopPropagation();
                            }}
                          />
                          <Label htmlFor={result.getId()} className='u-cursor_pointer'>
                            <Text size='m'>{result.getTitle()}</Text>
                          </Label>
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  );
                }
                return (
                  <ListGroup as='ul' border='none' className='u-pad-t_0 u-mar-t_0'>
                    {selectedItems.map((selectedItem) => (
                      <ListGroupItem
                        key={selectedItem.getId()}
                        paddingLevel={1}
                        border={false}
                        as='li'
                        className='u-display_flex u-gap_space-x1 u-align-items_center'
                        onClick={() => handleChange(selectedItem)}
                      >
                        <Checkbox
                          aria-hidden={false}
                          checked
                          size='s'
                          className='u-mar-l_1'
                          onClick={(e) => {
                            handleChange(selectedItem);
                            e.stopPropagation();
                          }}
                        />
                        <Label htmlFor={selectedItem.getId()} className='u-cursor_pointer'>
                          <Text size='m'>{selectedItem.getTitle()}</Text>
                        </Label>
                      </ListGroupItem>
                    ))}
                    <ListGroupItem paddingLevel={0} />
                    <li className='u-display_flex u-gap_space-x1 u-align-items_flex-start u-mar-b_1 u-pad-tb_1 u-pad-lr_2 u-border-b u-border-color_slate-200 u-color_slate-600'>
                      Suggestions
                    </li>
                    {searchResults
                      .filter((result) => !selectedItems.has(result))
                      // this should be handled by the query but mandark can't sort booleans
                      .sort((item) =>
                        item.getMeta().isIncludedInPersonalizationSettings() ? -1 : 1
                      )
                      .map((result) => (
                        <ListGroupItem
                          key={result.getId()}
                          paddingLevel={1}
                          border={false}
                          as='li'
                          className='u-display_flex u-gap_space-x1 u-align-items_center'
                          onClick={() => handleChange(result)}
                        >
                          <Checkbox
                            aria-hidden={false}
                            className='u-mar-l_1'
                            size='s'
                            onClick={(e) => {
                              handleChange(result);
                              e.stopPropagation();
                            }}
                          />
                          <Label htmlFor={result.getId()} className='u-cursor_pointer'>
                            <Text size='m'>{result.getTitle()}</Text>
                          </Label>
                        </ListGroupItem>
                      ))}
                  </ListGroup>
                );
              }}
            </FindAndApply.Body>
            <FindAndApply.Footer paddingLevel={1}>
              {({ApplyButton, onDismiss}) => (
                <ButtonGroup align='right'>
                  <Button variant='text' color='secondary' size='s' onClick={onDismiss}>
                    Cancel
                  </Button>
                  <ApplyButton variant='text' size='s'>
                    Done
                  </ApplyButton>
                </ButtonGroup>
              )}
            </FindAndApply.Footer>
          </FindAndApply>
        </Popover>
      )}
    </>
  );
};

export default StandardSetFilter;
