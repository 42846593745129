import React, {useContext} from 'react';

import {ReportsContext} from 'client/Reports/Reports.context';
import {Button} from '@albert-io/atomic';

export const ResetFiltersButton = ({isCollapsed}: {isCollapsed?: boolean}) => {
  const {clearFilters, query} = useContext(ReportsContext);

  const onClick = () => {
    clearFilters(null);
  };

  if (
    !query.start &&
    !query.end &&
    !query.subject &&
    !query.guideLevel &&
    !query.range &&
    !query.standardSets &&
    !query.personalization &&
    !query.attempt &&
    !query.s &&
    !query.guide
  ) {
    return null;
  }

  return (
    <Button
      variant='text'
      onClick={onClick}
      size={isCollapsed ? 's' : 'm'}
      className='u-text-wrap_nowrap'
    >
      Reset Filters
    </Button>
  );
};
