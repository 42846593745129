import React from 'react';
import {callAction} from 'client/framework';
import vanillaIEQuestionsListStore from '../VanillaIEQuestionsList.store';
import questionsListSettingsActions from '../QuestionsListSettings/QuestionsListSettings.actions';
import './no-questions-found-message.scss';

export default class NoQuestionsFoundMessage extends React.Component {
  resetFilters() {
    callAction(questionsListSettingsActions.RESET_FILTERS);
  }

  render() {
    if (!vanillaIEQuestionsListStore.hasReceivedQuestionSets) {
      return null;
    }
    return (
      <div className='vanilla-ie-no-questions-found-message'>
        <p className='vanilla-ie-no-questions-found-message__text'>
          We couldn't find any questions that meet your filter criteria
        </p>
        <button
          className='unbutton vanilla-ie-no-questions-found-message__reset-button'
          onClick={this.resetFilters}
        >
          Reset filters
        </button>
      </div>
    );
  }
}
