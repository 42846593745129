import React, {FocusEvent, useCallback, useRef} from 'react';
import {Map} from 'immutable';
import {Anchor, Checkbox, Popover, Radio, WithHoverAndFocus} from '@albert-io/atomic';
import AuthoringTextInput from 'client/EditPage/V2/AuthoringTextInput/AuthoringTextInput.react';
import SupplementManagerModalButton from 'client/Supplements/SupplementManager/SupplementManagerModalButton.react';

import {useQuestionEditorV2Store} from 'client/EditPage/V2/QuestionEditorV2Store';
import {PRIMARY_CONTENT_LANGUAGE} from 'client/EditPage/V2/QuestionEditorV2Store.types';

import {useShallow} from 'zustand/react/shallow';

import {MIN_NUMBER_OF_OPTIONS, MULTIPLE_CHOICE_LETTERS} from './V2/MultipleChoice.constants';

interface Props {
  onBlur?: (event: FocusEvent<HTMLElement>) => void;
  option: any;
  index: number;
  question: any;
  onModelChange: (updatedQuestion: any, ...fields: string[]) => void;
  validationErrors: Map<string, string>;
}

export const MultipleChoiceEditorOption = ({
  onBlur,
  option,
  index,
  question,
  onModelChange,
  validationErrors
}: Props) => {
  const {currentLanguage, updateTranslatedQuestionField, translatedOptionText} =
    useQuestionEditorV2Store(
      useShallow((state) => ({
        currentLanguage: state.currentLanguage,
        updateTranslatedQuestionField: state.updateTranslatedQuestionField,
        translatedOptionText: state
          .currentTranslatedQuestion()
          ?.translated_fields.find((field) => field.field === `options.${index}.value`)?.text
      }))
    );

  const id = option.get('id');
  const value =
    currentLanguage === PRIMARY_CONTENT_LANGUAGE ? option.get('value') : translatedOptionText;
  const errors = validationErrors.getIn(['options', id], null);

  const validResponses = question.getValidResponse();
  const isCorrect = validResponses.has(id);

  const removeOptionRef = useRef(null);

  const toggleOptionAsCorrect = useCallback(() => {
    const optionId = option.get('id');
    const currentIds = question.getValidResponse();
    const newIds = currentIds.has(optionId)
      ? currentIds.remove(optionId)
      : currentIds.set(optionId, true);

    const updatedQuestion = question.setValidResponse(newIds);
    onModelChange(updatedQuestion, 'rubric');
  }, [onModelChange, option, question]);

  const markOptionAsCorrect = useCallback(() => {
    const correctOption = Map({
      [option.get('id')]: true
    });

    const updatedQuestion = question.setValidResponse(correctOption);
    onModelChange(updatedQuestion, 'rubric');
  }, [option, onModelChange, question]);

  const onChange = useCallback(
    (e) => {
      if (currentLanguage === PRIMARY_CONTENT_LANGUAGE) {
        const options = question.getOptions();
        const updatedOptions = options.map((o) => {
          return o === option ? o.set('value', e.target.value) : o;
        });

        const updatedQuestion = question.setOptions(updatedOptions);
        onModelChange(updatedQuestion, 'options');
      } else {
        updateTranslatedQuestionField(
          currentLanguage,
          `options.${index}.value`,
          'text',
          e.target.value
        );
      }
    },
    [question, onModelChange, option, currentLanguage, updateTranslatedQuestionField, index]
  );

  const handleRemoveOption = useCallback(
    (e) => {
      if (e.currentTarget.disabled) {
        return;
      }

      const options = question.getOptions();
      const optionToDeleteId = options.get(index).getId();
      const validResponse = question.getValidResponse();

      let updatedQuestion = question.setOptions(options.delete(index));
      const fieldsToValidate = ['options'];

      if (validResponse.has(optionToDeleteId)) {
        updatedQuestion = updatedQuestion.setValidResponse(validResponse.delete(optionToDeleteId));
        fieldsToValidate.push('rubric');
      }

      onModelChange(updatedQuestion, ...fieldsToValidate);
    },
    [question, onModelChange, index]
  );

  const selectionType = question.isSelectMultiple() ? (
    <Checkbox
      disabled={currentLanguage !== PRIMARY_CONTENT_LANGUAGE}
      className='u-mar-r_1'
      label='Correct Answer'
      checked={isCorrect}
      onChange={toggleOptionAsCorrect}
    />
  ) : (
    <Radio
      className='u-mar-r_1'
      checked={isCorrect}
      onClick={markOptionAsCorrect}
      name=''
      disabled={currentLanguage !== PRIMARY_CONTENT_LANGUAGE}
    />
  );
  const pathToOption = ['options', `${index}`, 'value'];
  const isRemoveOptionDisabled = question.getOptions().size <= MIN_NUMBER_OF_OPTIONS;
  return (
    <div className='multiple-choice-editor-answer-option u-display_flex'>
      <b>{MULTIPLE_CHOICE_LETTERS.charAt(index)})</b>

      <div className='multiple-choice-editor-answer-option-text-input'>
        <div className='multiple-choice-editor-answer-option-checkbox'>
          {selectionType}Correct answer
        </div>
        <AuthoringTextInput
          value={value}
          error={currentLanguage === PRIMARY_CONTENT_LANGUAGE ? errors : null}
          onChange={onChange}
          onBlur={onBlur}
          data-field-path={pathToOption.join(',')}
        />
        {/* hide these buttons if editing translations - they only apply to editing the base question */}
        {currentLanguage === PRIMARY_CONTENT_LANGUAGE && (
          <div className='multiple-choice-editor-answer-option-suplement-button u-mar-t_1'>
            <WithHoverAndFocus>
              {({hasHover, onMouseEnter, onMouseLeave}) => (
                <div
                  className='u-display_flex u-align-items_center u-color_red-500'
                  ref={removeOptionRef}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                >
                  <Anchor
                    as='button'
                    underlined
                    inheritColor
                    disabled={isRemoveOptionDisabled}
                    onClick={handleRemoveOption}
                  >
                    Delete
                  </Anchor>
                  {hasHover && isRemoveOptionDisabled && (
                    <Popover expanded targetRef={removeOptionRef} position='bottom-start'>
                      Multiple Choice Questions must have at least two options.
                    </Popover>
                  )}
                </div>
              )}
            </WithHoverAndFocus>
            <SupplementManagerModalButton
              className='add-supplement-btn u-mar-b_2'
              linkButton
              color='blue'
              pathToProperty={pathToOption}
            />
          </div>
        )}
      </div>
    </div>
  );
};
