import React from 'react';
import PropTypes from 'prop-types';

import {Button, Dropdown, DropdownItem, IconInput, Text} from '@albert-io/atomic';
import LoadingIndicator from 'client/generic/LoadingIndicator.react';

import {SEARCH_PARAMETERS, getURLSearchParameter} from '../URLSearchParameters';

import GuideLevelSelector from './GuideLevelSelector/GuideLevelSelector.react';
import './guide-level-dropdown.scss';

const guides = [
  {
    label: 'Practice',
    type: 'practice'
  },
  {
    label: 'FRQ',
    type: 'free_response'
  },
  {
    label: 'Assessments',
    type: 'assessment'
  }
];

export default class GuideLevelDropdown extends React.Component {
  static propTypes = {
    questionSetId: PropTypes.string,
    /** Is used to stop the modal from loading with content of a diff subject */
    isActiveQuestionInSubject: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedType: '',
      selectedLabel: '',
      showModal: false
    };
  }

  toggleModal = () => {
    this.setState((prevState) => ({
      showModal: !prevState.showModal
    }));
  };

  setSelectedAlignmentHandler = ({type, label}) => {
    this.setState({
      selectedType: type,
      selectedLabel: label,
      showModal: true
    });
  };

  getExistingSelections = () => {
    if (!this.state.selectedType) {
      return '';
    }
    const selections =
      this.state.selectedType !== 'exam'
        ? getURLSearchParameter(SEARCH_PARAMETERS.guideLevelIds)
        : getURLSearchParameter(SEARCH_PARAMETERS.examIds);
    return selections ? selections.split(',') : [];
  };

  render() {
    if (!this.props.questionSetId) {
      return <LoadingIndicator />;
    }

    const existingSelections = this.getExistingSelections();

    return this.props.isActiveQuestionInSubject ? (
      <div className='u-mar-l_2'>
        <Text as='p' className='u-mar-t_2 u-mar-b_1' color='secondary' size='s' bold>
          Alignment Type
        </Text>
        <Dropdown
          position='bottom-end'
          className='queue-search-panel__guide-selector'
          trigger={
            <IconInput
              type='button'
              className='queue-search-panel__guide-selector--dropdown-trigger'
              iconRight='caret-down'
              value={this.state.selectedLabel || 'Choose...'}
              border
            />
          }
        >
          {guides.map((guide, i) => (
            <DropdownItem onClick={() => this.setSelectedAlignmentHandler(guide)} key={i}>
              {guide.label}
            </DropdownItem>
          ))}
        </Dropdown>
        {this.state.selectedType && (
          <div className='queue-search-panel__guide-selector--filters'>
            <Text italic size='s' color='tertiary'>
              {existingSelections.length ? 'Filters applied' : 'No selection'}
            </Text>
            <Button size='s' onClick={this.toggleModal}>
              Choose...
            </Button>
            {this.state.showModal && (
              <GuideLevelSelector
                guideType={this.state.selectedType}
                handleClose={this.toggleModal}
                questionSetId={this.props.questionSetId}
                existingSelections={existingSelections}
              />
            )}
          </div>
        )}
      </div>
    ) : (
      <Text as='div' className='u-mar-t_1 u-mar-b_3' size='s' color='tertiary'>
        Please select a question from the current subject to filter questions by alignment.
      </Text>
    );
  }
}
