/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import track from 'react-tracking';

import {addToast} from '@albert-io/atomic';
import notifier from '@albert-io/notifier';
import {getSession, refreshSession} from '@albert-io/mandark/authentication';

import {shouldRedirectFromPathname, redirect} from 'client/User/UserRedirectUtil';
import {genericMandarkRequest} from 'resources/mandark.resource';
import {redirectToLoginPageFromLocation} from 'client/LogIn/utils';

@track({component: 'SocialLanding'})
export default class SocialLanding extends React.Component {
  static propTypes = {
    location: PropTypes.shape({
      query: PropTypes.shape({
        token: PropTypes.string,
        new_user: PropTypes.string,
        error_code: PropTypes.string,
        provider: PropTypes.string,
        error_message: PropTypes.string
      }),
      pathname: PropTypes.string
    }).isRequired,
    tracking: PropTypes.object
  };

  async componentDidMount() {
    const {provider} = this.props.location.query;
    const authenticated = getSession().isValid();
    /**
     * We need one of the two "required" parameters to be able to handle
     * the incoming request.
     */
    if (!this.props.location.query.error_code && !authenticated) {
      notifier.notify(new Error('Social Landing: Missing Required Parameters'), {
        component: 'SocialLanding'
      });
      this.showGenericErrorToast();
      redirectToLoginPageFromLocation();
      return;
    }

    /**
     * If there is an `error_code` in the redirect URL, we process the error and redirect back to the sign in modal.
     */
    if (this.props.location.query.error_code) {
      let error = new Error('Social Landing: Unknown Error');
      const errorCode = this.props.location.query.error_code;
      const errorMessage = this.props.location.query.error_message;
      if (errorMessage) {
        error = new Error('Social Landing: Known Error');
        this.showErrorToast(errorMessage);
      } else {
        this.showGenericErrorToast();
      }
      notifier.notify(error, {
        component: 'SocialLanding',
        provider,
        errorMessage,
        errorCode
      });
      redirectToLoginPageFromLocation();
    }

    if (!authenticated) {
      return;
    }

    const isNewUser = this.props.location.query.new_user || false;

    if (isNewUser) {
      addToast({
        color: 'positive',
        title: 'Success!',
        message: 'Your account was registered successfully!'
      });
    }

    if (isNewUser && provider === 'clever') {
      /**
       * When the provider is `clever`, we need to ensure that the select account type
       * is completed for the incoming user. Account types from clever will match
       * account types in our system. Because the back end has no knowledge of what
       * onboarding steps are named, we have a manual PATCH request happening in
       * the landing page when a new user is being created.
       */
      let steps = ['ACCOUNT_TYPE_SELECTION'];
      if (getSession().isTeacher()) {
        steps = steps.concat(['COPPA_AGE_SCREENER', 'PARENTAL_CONSENT']);
      }

      try {
        await genericMandarkRequest(
          'patch',
          {resourcePath: ['users_v2', getSession().getId()]},
          {
            data: {
              attributes: {
                onboarding_steps: steps
              }
            }
          }
        );
        await refreshSession();
      } catch (e) {
        addToast({
          color: 'negative',
          title: isNewUser ? 'Sign Up Failure' : 'Sign In Failure',
          message: isNewUser
            ? 'We ran into an issue creating your account.'
            : 'We were unable to authenticate your session.'
        });
        notifier.notify(e, {
          component: 'SocialLanding',
          name: `SocialLanding: Error : ${isNewUser ? 'Sign Up' : 'Sign In'}`,
          provider
        });
        redirectToLoginPageFromLocation();
      }
    }

    if (isNewUser) {
      this.props.tracking.trackEvent({
        event: 'sign_up',
        method: provider
      });
    } else {
      this.props.tracking.trackEvent({
        event: 'login',
        method: provider
      });
    }

    if (shouldRedirectFromPathname(this.props.location.pathname)) {
      redirect({
        location: this.props.location
      });
    }
  }

  showErrorToast(message) {
    addToast({
      color: 'negative',
      title: 'Sign In Failure',
      message
    });
  }

  showGenericErrorToast() {
    this.showErrorToast('A problem was encountered while signing in, please try again.');
  }

  render() {
    return null;
  }
}
