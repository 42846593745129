import React, {useState, useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import {CreateAssignmentContext} from 'client/CreateAssignmentV2/CreateAssignmentProvider';
import {history} from 'client/history';
import {
  Modal,
  Button,
  Dialogue,
  Text,
  InputStepper,
  IconButton,
  WithToggle,
  Popover
} from '@albert-io/atomic';
import {useTracking} from 'react-tracking';

import './assign-modal.scss';

import {getModelForResourceType} from 'resources/modelRegistry';

import getSmartSelectQuery, {getQuestionSetsQuery} from './AssignModal.queries';

const typeToResource = {
  guide: 'guide_levels_v2',
  folder: 'templates_v1'
};

const AssignModal = ({onClose, resource, type}) => {
  const {Track, trackEvent} = useTracking({
    component: 'Smart Assign modal',
    feature: 'Smart Assign'
  });
  const maxQuestions = resource.getMeta().getCountOfQuestions();
  const minQuestions = resource.getMeta().getMinSizeQuestionSet();
  const [numOfQuestionSets, setNumOfQuestionSets] = useState(resource.getQuestionSets().size);
  const [questionNum, setQuestionNum] = useState(maxQuestions < 10 ? maxQuestions : 10);
  const context = useContext(CreateAssignmentContext);

  const minError = questionNum < minQuestions;
  const maxError = questionNum > maxQuestions;
  const decimalError = questionNum % 1 !== 0;
  const hasError = maxError || minError || decimalError;

  let errorMessage = `Must be between ${minQuestions} and ${maxQuestions}`;
  if (decimalError) errorMessage = `Number of questions must be a whole number`;

  const onContinue = async () => {
    trackEvent({event: 'click', type: 'button', content: 'Continue'});

    const query = getSmartSelectQuery(questionNum, resource.getId(), type);
    const queryResult = await query.getResourcePromise();

    const allQuestions = queryResult.getQuestionSets();

    const questionSets = queryResult
      .getMeta()
      .getSmartSelectResults()
      .map((question) => {
        const {id} = question.toJS();
        return allQuestions.find((questionSet) => questionSet.getId() === id);
      });

    query.invalidateInterest();

    const {bootstrapAssignment} = context;
    bootstrapAssignment({
      questionSets,
      resourceType: typeToResource[type],
      assignmentOrigin: type === 'folder' ? resource.getName() : 'guide'
    });
    history.pushState(null, `/create-assignment/new-assignment`);
  };

  const handleClose = (e) => {
    trackEvent({event: 'click out', type: 'modal', content: null});
    onClose(e);
  };

  const onCancel = (e) => {
    trackEvent({event: 'click', type: 'button', content: 'Cancel'});
    onClose(e);
  };

  useEffect(() => {
    const getMissingData = async () => {
      const query = getQuestionSetsQuery(resource.getId(), type);
      const queryResult = await query.getResourcePromise();

      const questionSetSize = queryResult.getQuestionSets().size;
      setNumOfQuestionSets(questionSetSize);
    };
    if (numOfQuestionSets === 0) {
      // Resources with no question sets should not reach this modal, so we know we have missing information
      getMissingData();
    }
  }, []);

  return (
    <Track>
      <Modal ariaLabel='Select assignment parameters' handleClose={handleClose} role='dialog'>
        {() => (
          <Dialogue size='s' inModal hideCloseBtn paddingLevel={3} alignTitle='center'>
            <Text bold as='p' className='u-text-align_center'>
              How many questions would <br />
              you like to assign?
            </Text>
            <Dialogue.Body
              className='u-display_flex u-flex-direction_column u-align-items_center u-mar-t_6'
              align='center'
            >
              <InputStepper
                value={questionNum}
                max={maxQuestions}
                min={minQuestions}
                error={hasError}
                onChange={setQuestionNum}
                errorMessage={errorMessage}
                ariaLabel='input-stepper'
              />
            </Dialogue.Body>
            <Dialogue.BtnGroup>
              <Button color='secondary' onClick={onCancel}>
                Cancel
              </Button>
              <Button onClick={onContinue} disabled={hasError}>
                Continue
              </Button>
            </Dialogue.BtnGroup>
          </Dialogue>
        )}
      </Modal>
    </Track>
  );
};

AssignModal.propTypes = {
  type: PropTypes.oneOf(['folder', 'guide']).isRequired,
  onClose: PropTypes.func,
  resource: PropTypes.oneOfType([
    PropTypes.instanceOf(getModelForResourceType('template_v1')),
    PropTypes.instanceOf(getModelForResourceType('guide_level_v2'))
  ]).isRequired
};

export default AssignModal;

export const SmartAssignTooltip = ({className, leftContent}) => {
  const iconRef = React.createRef();
  return (
    <span className={cx('u-display_flex u-align-items_center', className)}>
      {leftContent}
      <WithToggle className='u-mar-l_auto'>
        {({on, onClick: togglePopover, onBlur, onMouseEnter, onMouseLeave}) => (
          <span onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onBlur={onBlur}>
            <IconButton
              size='s'
              icon={['far', 'question-circle']}
              label='More info'
              onClick={togglePopover}
              ref={iconRef}
            />
            {on && (
              <Popover targetRef={iconRef} className='assign-modal__tooltip' position='right-start'>
                <Text as='p' color='primary-inverse' size='xs'>
                  Creates an assignment based on the amount of questions you’d like to use from the
                  entire question bank. <b>Note: Question order will be randomized.</b>{' '}
                </Text>
              </Popover>
            )}
          </span>
        )}
      </WithToggle>
    </span>
  );
};

SmartAssignTooltip.propTypes = {
  className: PropTypes.string,
  leftContent: PropTypes.node
};
