import React from 'react';
import PropTypes from 'prop-types';
import {onboardingPropTypes} from 'client/Onboarding/ForceOnboarding.react';
import {Button, Heading, Grid, Text} from '@albert-io/atomic';
import notifier from '@albert-io/notifier';
import {completeParentalConsent} from 'lib/OnboardingUtil/OnboardingUtil';
import ParentalConsent from 'client/Onboarding/Steps/ParentalConsent/Options/ParentalConsent/ParentalConsent.react';
import ClassroomInvite from 'client/Onboarding/Steps/ParentalConsent/Options/ClassroomInvite/ClassroomInvite.react';
import EnrollCode from 'client/Onboarding/Steps/ParentalConsent/Options/EnrollCode/EnrollCode.react';

import './select-consent-option-shared.scss';

export const consentOptionPropTypes = {
  ...onboardingPropTypes,
  clearConsentState: PropTypes.func
};

/**
 * @todo: SelectConsentOption is acting as a stand-in for the component that makes up the
 * `PARENTAL_CONSENT` step. `PARENTAL_CONSENT` is now a misnomer, because the `SelectConsentOption`
 * "step" exposes multiple ways to be granted consent to use albert. It is overloaded. Previously, students
 * could only be granted consent thru direct parental approval, now they can thru the following ways:
 *     1. Parental Approval email (the old way)
 *     2. Classroom Enrollment Code
 *     3. Classroom Invitations
 * The user has the ability to select what option to use, and each will grant them approval and
 * advance the user onboarding to the next step in the same manner.
 */
export default class SelectConsentOption extends React.Component {
  static propTypes = onboardingPropTypes;

  constructor(props) {
    super();

    /**
     * If the user undergoing onboarding has been granted consent thru means outside
     * of onboarding, we mark their consent step as complete and advance their onboarding
     * journey.
     */
    if (props.user.getMeta().isCoppaApproved()) {
      this.setParentalConsentStep(props.user);
    }

    this.state = {
      activeConsentOption: null
    };
  }

  async setParentalConsentStep(user) {
    try {
      await completeParentalConsent(user).save();
      this.props.onComplete();
    } catch (e) {
      notifier.notify(e, {
        component: 'ParentalConsent',
        name: 'Onboarding: failed to complete parental consent step during isCoppaApproved check',
        userId: this.props.user.getId()
      });
    }
  }

  setConsentState = (state) => {
    this.setState(state);
  };

  clearConsentState = () => {
    this.setState({
      activeConsentOption: null
    });
  };

  render() {
    if (this.state.activeConsentOption) {
      // React requires a capitalized component name
      const ActiveConsentOption = this.state.activeConsentOption;

      return <ActiveConsentOption {...this.props} clearConsentState={this.clearConsentState} />;
    }

    return <ConsentOptionPrompt stateSetter={this.setConsentState} />;
  }
}

class ConsentOptionPrompt extends React.Component {
  static propTypes = {
    stateSetter: PropTypes.func.isRequired
  };

  setEnrollCodeOption = () => {
    this.props.stateSetter({
      activeConsentOption: EnrollCode
    });
  };

  setParentalConsentOption = () => {
    this.props.stateSetter({
      activeConsentOption: ParentalConsent
    });
  };

  setClassroomInviteOption = () => {
    this.props.stateSetter({
      activeConsentOption: ClassroomInvite
    });
  };

  resetConsentOption = () => {
    this.props.stateSetter({
      activeConsentOption: null
    });
  };

  render() {
    return (
      <Grid.Container rowGap={2}>
        <Grid.Row justify='center'>
          <Grid.Col all={8} xs={12}>
            <Grid.Container nested rowGap={2}>
              <Grid.Row justify='center'>
                <Grid.Col className='consent-option-copy' all={6} xs={12}>
                  <Heading as='h2'>Welcome!</Heading>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row justify='center'>
                <Grid.Col className='consent-option-copy' all={6} xs={12}>
                  <Text as='p'>
                    To access Albert, you&#39;ll either need to get your parent&#39;s permission or
                    enroll in a class.
                  </Text>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row justify='center'>
                <Grid.Col all={6} xs={12}>
                  <ul
                    className='u-display_flex u-flex-direction_column u-list-style_none u-pad_0 u-mar_0'
                    role='menu'
                  >
                    <li className='u-mar-b_2' role='menuitem'>
                      <Button className='consent-option-button' onClick={this.setEnrollCodeOption}>
                        Enter an enrollment code
                      </Button>
                    </li>
                    <li className='u-mar-b_2' role='menuitem'>
                      <Button
                        className='consent-option-button'
                        onClick={this.setClassroomInviteOption}
                      >
                        Accept class invitations
                      </Button>
                    </li>
                    <li className='u-mar-b_2' role='menuitem'>
                      <Button
                        className='consent-option-button'
                        onClick={this.setParentalConsentOption}
                      >
                        Request parental permission
                      </Button>
                    </li>
                  </ul>
                </Grid.Col>
              </Grid.Row>
            </Grid.Container>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    );
  }
}
