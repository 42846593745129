import React from 'react';
import PropTypes from 'prop-types';
import {v4 as uuid} from 'uuid';
import classnames from 'classnames';
import './scoop-container.scss';

export default class ScoopContainer extends React.Component {
  static propTypes = {
    className: PropTypes.any,
    scoopSize: PropTypes.number,
    backgroundColor: PropTypes.string
  };

  static defaultProps = {
    scoopSize: 20,
    color: 'white',
    style: {}
  };

  clipPathId = `scoop-${uuid()}`;

  render() {
    const {children, className, scoopSize} = this.props;
    // The deomenator here was computed by our lovely content folks, it assumes the x radius of the
    // ellipse is 55%, so it will NOT work if that number is changed. Therefore, don't change it.
    const computedScoopSize = scoopSize / 0.584;
    return (
      <div className={classnames('scoop-container', className)}>
        <div className='scoop-container__bg'>
          <svg className='scoop-container__svg'>
            <g style={this.props.backgroundColor && {fill: this.props.backgroundColor}}>
              <rect
                x='0'
                y='0'
                width='100%'
                height='100%'
                transform={`translate(0, -${scoopSize})`}
              />
              <ellipse
                cx='50%'
                cy='100%'
                rx='55%'
                ry={computedScoopSize}
                transform={`translate(0, -${computedScoopSize})`}
              />
            </g>
          </svg>
        </div>
        {children}
      </div>
    );
  }
}
