import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import makeRef from 'lib/makeRef';
import makeConstants from 'lib/makeConstants';
import {queryBuilderPropType} from '@albert-io/json-api-framework/request/builder';
import './mandark-paginator.scss';

const directions = makeConstants('left', 'right');

export default class MandarkPaginator extends React.Component {
  static propTypes = {
    jumpBy: PropTypes.number,
    onChange: PropTypes.func,
    query: queryBuilderPropType
  };

  static defaultProps = {
    jumpBy: 10
  };

  handlePageChange = (e) => {
    this.props.onChange(e.currentTarget.dataset.pageNumber);
  };

  handleCaretClick = (e) => {
    const direction = e.currentTarget.dataset.direction;
    const {currentPage, totalPages} = this.getMeta();
    let newPage;
    if (direction === directions.left) {
      newPage = currentPage - this.props.jumpBy > 0 ? currentPage - this.props.jumpBy : 1;
    } else {
      newPage =
        currentPage + this.props.jumpBy <= totalPages
          ? currentPage + this.props.jumpBy
          : totalPages;
    }
    this.props.onChange(newPage);
  };

  getMeta() {
    const pageMeta = this.props.query.getResourceMetadata().get('page');
    return {
      currentPage: pageMeta.get('page'),
      totalPages: pageMeta.get('total_pages')
    };
  }

  render() {
    const {totalPages, currentPage} = this.getMeta();
    if (totalPages <= 1) {
      return null;
    }
    return (
      <div className='pagi'>
        <div className='pagi__actions-wrapper'>
          <Caret
            direction={directions.left}
            onClick={this.handleCaretClick}
            disabled={currentPage === 1}
          />
          <PagesList
            pageCount={totalPages}
            activePage={currentPage}
            onPageClick={this.handlePageChange}
            makeRef={this.makeRef}
          />
          <Caret
            direction={directions.right}
            onClick={this.handleCaretClick}
            disabled={currentPage === totalPages}
          />
        </div>
      </div>
    );
  }
}

class PagesList extends React.PureComponent {
  static propTypes = {
    pageCount: PropTypes.number,
    activePage: PropTypes.number,
    onPageClick: PropTypes.func
  };

  componentDidMount() {
    this.scrollToActivePage();
  }

  componentDidUpdate() {
    this.scrollToActivePage();
  }

  activePageRef = null;

  makeRef = makeRef.bind(this);

  scrollToActivePage() {
    this.activePageRef.scrollIntoView({
      behavior: 'instant'
    });
  }

  render() {
    const {pageCount, activePage, onPageClick} = this.props;
    return (
      <ul className='pagi__pages'>
        {Array(pageCount)
          .fill()
          .map((_, i) => {
            const page = i + 1;
            const isActive = page === activePage;
            return (
              <li
                className='pagi__page'
                ref={isActive ? this.makeRef : null}
                data-ref-name='activePageRef'
                key={page}
              >
                <button
                  className={classnames('unbutton pagi__page-btn', {
                    'pagi__page-btn--active': isActive
                  })}
                  onClick={onPageClick}
                  data-page-number={page}
                >
                  {page}
                </button>
              </li>
            );
          })}
      </ul>
    );
  }
}

class Caret extends React.PureComponent {
  static propTypes = {
    direction: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool
  };

  render() {
    const {direction, onClick, disabled} = this.props;
    return (
      <button
        className='pagi__caret unbutton'
        onClick={disabled ? null : onClick}
        data-direction={direction}
        disabled={disabled}
      >
        <span className={`fa fa-caret-${direction}`} />
      </button>
    );
  }
}
