import React, {useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {v4 as uuid} from 'uuid';
import {Map, Range} from 'immutable';
import Checkbox from 'generic/Forms/Checkbox/Checkbox.react';
import SimpleDropdown from 'generic/SimpleDropdown/SimpleDropdown.react';
import {AuthoringQuestionModelV1} from 'resources/augmented/AuthoringQuestion/AuthoringQuestionModel.v1';

import {useQuestionEditorV2Store} from 'client/EditPage/V2/QuestionEditorV2Store';

import {PRIMARY_CONTENT_LANGUAGE} from 'client/EditPage/V2/QuestionEditorV2Store.types';

import {MultipleChoiceEditorOption} from './MultipleChoiceEditorOption';
import './multiple-choice-editor.scss';
import {MAX_NUMBER_OF_OPTIONS, MIN_NUMBER_OF_OPTIONS} from './V2/MultipleChoice.constants';

const numberOfAnswersOptions = Range(MIN_NUMBER_OF_OPTIONS, MAX_NUMBER_OF_OPTIONS + 1).map((val) =>
  Map({
    id: val,
    name: val
  })
);

const MultipleChoiceQuestionEditor = ({question, onBlur, onModelChange, validationErrors}) => {
  const currentLanguage = useQuestionEditorV2Store((state) => state.currentLanguage);

  const updateRubricValidResponse = useCallback(
    (options) => {
      const optionIds = options.map((option) => option.get('id'));
      const updatedRubric = question.getValidResponse().filter((response, key) => {
        return optionIds.includes(key);
      });

      return updatedRubric;
    },
    [question]
  );

  const setNumberOfOptions = useCallback(
    (option?: any) => {
      const numberOfOptions = option ? option.get('id') : 4;
      const options = question.getOptions().setSize(numberOfOptions);
      const newOption = Map({
        label: '',
        value: ''
      });

      const updatedOptions = options.map((optionItem) => optionItem || newOption.set('id', uuid()));
      const validResponse = updateRubricValidResponse(updatedOptions);

      const updatedQuestion = question.setOptions(updatedOptions).setValidResponse(validResponse);
      onModelChange(updatedQuestion);
    },
    [question, onModelChange, updateRubricValidResponse]
  );

  useEffect(() => {
    const currentOptions = question.getOptions();
    if (currentOptions.isEmpty()) {
      setNumberOfOptions();
    }
  }, [question, setNumberOfOptions]);

  const setIsSelectMultiple = useCallback(
    (e) => {
      let updatedQuestion = question.setIsSelectMultiple(e.target.checked);

      if (!e.target.checked) {
        updatedQuestion = updatedQuestion.setValidResponse(Map());
      }

      onModelChange(updatedQuestion);
    },
    [question, onModelChange]
  );

  const options = question.getOptions();
  const isSelectMultiple = question.isSelectMultiple();

  return (
    <div className='multiplechoice-editor'>
      <div>
        <SimpleDropdown
          disabled={currentLanguage !== PRIMARY_CONTENT_LANGUAGE}
          label='Number of answers'
          activeItemId={options.size}
          options={numberOfAnswersOptions}
          storeName='NumberOfAnswersStore'
          onChange={setNumberOfOptions}
          destroyStoreOnUnmount
        />
        <Checkbox
          disabled={currentLanguage !== PRIMARY_CONTENT_LANGUAGE}
          className='multiple-choice-editor__multiple-answers'
          checked={isSelectMultiple}
          onChange={setIsSelectMultiple}
          label='Multiple correct answers'
          name='isSelectMultiple'
        />
        <div>
          {validationErrors.get('rubric') ? (
            <div className='a-form-input__error'>{validationErrors.get('rubric')}</div>
          ) : (
            ''
          )}
          {options.map((option, index) => (
            <MultipleChoiceEditorOption
              key={option.get('id')}
              index={index}
              option={option}
              onBlur={onBlur}
              question={question}
              onModelChange={onModelChange}
              validationErrors={validationErrors}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

MultipleChoiceQuestionEditor.propTypes = {
  question: PropTypes.instanceOf(AuthoringQuestionModelV1),
  onBlur: PropTypes.func,
  onModelChange: PropTypes.func,
  validationErrors: ImmutablePropTypes.map
};

export default MultipleChoiceQuestionEditor;
