// @flow
import {fromJS, Map} from 'immutable';
import {Store} from 'client/framework';
import simpleDropdownActions from './SimpleDropdown.actions';

export default class SimpleDropdownStore extends Store {
  constructor(name: string) {
    super(name);
    this.setInitialData(
      fromJS({
        selectedItem: {},
        isOpen: false,
        optionsRect: null
      })
    );
    this.handleTargeted(simpleDropdownActions.RESET_STORE, this.resetStore);
    this.handleTargeted(simpleDropdownActions.SET_SELECTED_ITEM, this._setSelectedItem);
    this.handleTargeted(simpleDropdownActions.SET_IS_OPEN, this._setIsOpen);
    this.handleTargeted(simpleDropdownActions.SET_OPTIONS_RECT, this.setProperty('optionsRect'));
    this.handleTargeted(simpleDropdownActions.TOGGLE_IS_OPEN, this._toggleIsOpen);
  }

  _setSelectedItem(item: Map<*, *>) {
    this.writeData('selectedItem', item);
  }

  _setIsOpen(isOpen: boolean) {
    this.writeData('isOpen', isOpen);
  }

  _toggleIsOpen() {
    this.writeData('isOpen', !this.isOpen());
  }

  getSelectedItem(): Map<*, *> {
    return this.readData('selectedItem') || new Map();
  }

  isOpen(): boolean {
    return this.readData('isOpen');
  }

  getOptionsRect = (): Object | null => this.readData('optionsRect');
}
