import React from 'react';
import PropTypes from 'prop-types';
import {setUpStore} from 'client/framework';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import TwoWayQuestionTable from './TwoWayQuestionTable.react';
import TwoWayQuesionStore from './TwoWayQuestion.store';
import {TwoWayQuestionModel} from 'resources/Question/Question.model';
import {AuthoringQuestionModelV1} from 'resources/augmented/AuthoringQuestion/AuthoringQuestionModel.v1';

export default class TwoWayQuestion extends React.Component {
  static propTypes = {
    isGuessSubmitted: PropTypes.bool,
    storeName: PropTypes.string.isRequired,
    question: PropTypes.oneOfType([
      PropTypes.instanceOf(TwoWayQuestionModel),
      PropTypes.instanceOf(AuthoringQuestionModelV1)
    ]),
    questionId: PropTypes.string
  };

  getStore() {
    return setUpStore(TwoWayQuesionStore, this.props.storeName, this.props.questionId);
  }

  render() {
    const {question, isGuessSubmitted} = this.props;
    const prompt = this.props.question.getPrompt();
    return (
      <div className='two-way-question'>
        <div className='two-way-question-content-prompt'>
          <MarkdownRendererV2 text={prompt} />
        </div>
        <TwoWayQuestionTable
          question={question}
          store={this.getStore()}
          isGuessSubmitted={isGuessSubmitted}
        />
      </div>
    );
  }
}
