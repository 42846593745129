import React from 'react';
import PropTypes from 'prop-types';
import ConfirmLeaveModal from 'client/generic/ConfirmLeaveModal/ConfirmLeaveModal.react';

import SubmitSectionModalBody from './shared/SubmitSectionModalBody.react';

export default class ConfirmLeaveUnfinishedSectionModal extends React.Component {
  static propTypes = {
    shouldShow: PropTypes.bool
  };

  makeModalBody = (handleLeave, handleStay) => (
    <SubmitSectionModalBody isLeavingRoute handleLeave={handleLeave} handleStay={handleStay} />
  );

  render() {
    return (
      <ConfirmLeaveModal
        title='You have unanswered questions'
        className='submit-section-modal assignment-v2-ie__confirm-leave-component'
        makeModalBody={this.makeModalBody}
        modalTitle='You have unanswered questions'
        shouldPrompt={this.props.shouldShow}
      />
    );
  }
}
