import {createActions} from '../../framework';

export default createActions(null, [
  'SET_ALLOW_NULL',
  'SET_START_DATE',
  'SET_END_DATE',
  'SET_MAX_RANGE',
  'VALIDATE_FORM',
  'RESET_STORE'
]);
