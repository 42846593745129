// @flow
import {List} from 'immutable';
import {type AuthoringSubjectModelV1} from 'resources/augmented/AuthoringSubject/AuthoringSubjectModel.v1';
/**
 * These extensions are intended to ONLY exist on the USER model.
 *
 * The AUTHOR model has definitions for these already. These are for use when things
 * have been included into a query on a USER.
 */
export const authoringSubjectExtentions = {
  /*
    Retrieves authoring subject records. All usages of these records should funnel thru this in order to pick up
    our primitive wanrings
    *IMPORTANT* This relies on the inclusion of author_v1.authoring_subjects_v1 on user
  */
  getAuthoringSubjects(): List<AuthoringSubjectModelV1> {
    if (this.getAuthor().isEmpty()) {
      logger.warn('Attempting to use an extension method without including appropriate resources.');
      logger.warn(`Please include author_v1.authoring_subjects_v1 on user: ${this.getId()}`);
      return new List();
    }

    return this.getAuthor().getAuthoringSubjects();
  },

  hasAuthoringSubject(subjectId: string): boolean {
    return this.getAuthoringSubjects().find((subject) => {
      return subject.getId() === subjectId;
    });
  }
};
