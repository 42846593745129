import React from 'react';
import {Badge, Button, Text} from '@albert-io/atomic';
import {removeQueryParams} from 'client/history';
import {Link} from 'react-router';

interface LinkItem {
  get: (property: string) => any;
}

interface BetaNavButtonProps {
  link: LinkItem;
  pathname: string;
}

const BetaNavButton = ({link, pathname}: BetaNavButtonProps) => {
  const inAB = pathname.includes('assignment-builder');
  return (
    <Button
      as={Link}
      to={inAB ? pathname : `${link.get('route')}`}
      className='global-nav__beta-button'
      id='beta-button_nav'
      data-testid='launch-assignment-builder'
      onClick={() => {
        if (pathname.includes('/create-assignment')) {
          removeQueryParams('draftId');
        }
      }}
    >
      {link.get('name')}
      <Badge emphasis='bold' size='small' className='global-nav__beta-badge'>
        <Text color='primary-inverse' className='u-text-transform_uppercase u-font-weight_900'>
          Beta
        </Text>
      </Badge>
    </Button>
  );
};

export default BetaNavButton;
