import React from 'react';
import PropTypes from 'prop-types';
import {WithToggle} from '@albert-io/atomic';
import Button from 'sg/Button/Button.react';
import FoldersModal from 'components/PracticeView/FoldersModal/FoldersModal.react';
import sessionStore from 'client/Session/SessionStore';

export class AddFRQToTemplateButton extends React.Component {
  static propTypes = {
    questionSetIds: PropTypes.array
  };

  render() {
    return (
      <WithToggle dismissOnBlur={false}>
        {({on, onClick}) => (
          <>
            <Button small color='green' onClick={onClick} {...this.props}>
              Save to folder
            </Button>
            {on && (
              <FoldersModal
                selectedQuestionSetIds={this.props.questionSetIds}
                userId={sessionStore.getUserId()}
                closeModalFunc={onClick}
                selectedQuestionCount={1}
              />
            )}
          </>
        )}
      </WithToggle>
    );
  }
}
