/**
 * # Text
 *
 * A component for displaying text with various styling options.
 *
 * ## Props<C extends React.ElementType>
 *
 * - `bold?: boolean`: Determines if the text should be displayed in bold.
 * - `children?: React.ReactNode`: The content to be displayed within the text component.
 * - `className?: string`: Additional CSS class names to apply to the text component.
 * - `color?: 'primary' | 'secondary' | 'tertiary' | 'brand' | 'warning' | 'positive' | 'inherit' | 'negative' | 'primary-inverse' | 'info'`: The color of the text.
 * - `italic?: boolean`: Determines if the text should be displayed in italic.
 * - `monospace?: boolean`: Determines if the text should be displayed in monospace font.
 * - `paragraph?: boolean`: Determines if the text should be displayed as a paragraph.
 * - `size?: 'xl' | 'l' | 'regular' | 'm' | 's' | 'xs'`: The size of the text.
 * - `withMargin?: boolean`: Determines if the text should have margin.
 * - `id?: string`: The unique identifier of the text component.
 * - `...rest: C`: Any properties of `React.ElementType`
 *
 * ## Usage
 *
 * ```tsx
 * import React from 'react';
 * import Text from './Text';
 *
 * const Example = () => {
 *   return (
 *     <Text bold color="brand" size="xl">
 *       Hello, World!
 *     </Text>
 *   );
 * };
 *
 * export default Example;
 * ```
 */

import classnames from 'classnames';
import React from 'react';
import './text.scss';

// shared colors for <Heading> and other text based components
export const TEXT_COLORS = [
  'primary',
  'secondary',
  'tertiary',
  'brand',
  'warning',
  'positive',
  'inherit',
  'negative',
  'primary-inverse',
  'info'
] as const;
export type TextColor = (typeof TEXT_COLORS)[number];

export interface Props extends PropsWithChildrenOptional, PropsWithClassNameOptional {
  bold?: boolean;
  color?: TextColor;
  italic?: boolean;
  monospace?: boolean;
  paragraph?: boolean;
  size?: 'xl' | 'l' | 'regular' | 'm' | 's' | 'xs';
  withMargin?: boolean;
  id?: string;
  disabled?: boolean;
}

export type TextProps<C extends React.ElementType> = PolymorphicComponentProps<C, Props>;

type TextComponent = <C extends React.ElementType = 'span'>(
  props: TextProps<C>
) => React.ReactElement | null;

export const Text: TextComponent = <C extends React.ElementType = 'span'>({
  as,
  bold,
  children,
  className,
  color = 'primary',
  italic,
  monospace,
  paragraph,
  size = 'regular',
  withMargin,
  disabled,
  ...rest
}: TextProps<C>) => {
  const WrapperElement = as || 'span';
  let hasMargin;
  if (withMargin !== undefined) {
    hasMargin = withMargin;
  } else {
    hasMargin = !!paragraph;
  }
  const isInherit = color === 'inherit';
  return (
    <WrapperElement
      className={classnames('a-text', className, {
        [`content-${color}`]: !isInherit,
        'a-text--bold': bold,
        'u-font-style_italic': italic,
        [`a-text--size-${size}`]: size,
        'a-text--monospace': monospace,
        'a-text--paragraph': paragraph,
        'a-text--with-margin': hasMargin,
        'a-text--disabled': disabled,
        'u-color-inherit': isInherit
      })}
      {...rest}
    >
      {children}
    </WrapperElement>
  );
};

export default Text;
