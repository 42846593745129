import React from 'react';
import classnames from 'classnames';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';

import '../../../assets/stylesheets/modules/_tooltip.scss';

export default class Tooltip extends React.Component {
  render() {
    const triggerId = this.props.triggerId;
    const tooltipClasses = classnames('tooltip', {
      'tooltip--right': this.props.tooltipPosition === 'right',
      'tooltip--left': this.props.tooltipPosition === 'left',
      'tooltip--bottom': this.props.tooltipPosition === 'bottom',
      'tooltip--white': this.props.tooltipColor === 'white'
    });
    return (
      <div className='tooltip__wrapper'>
        <style>{`#${triggerId}:hover .tooltip {visibility: visible; opacity: 1;}`}</style>
        <div className={tooltipClasses}>
          {this.props.content ? (
            <MarkdownRendererV2 className='tooltip__markdown-renderer' text={this.props.content} />
          ) : null}
          {this.props.children}
        </div>
      </div>
    );
  }
}
