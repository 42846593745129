import {Map, OrderedMap} from 'immutable';
import {get} from 'lodash';

export default class PaginatedResultsManager {
  constructor({initialQuery, onUpdate = () => {}, defaultPageSize = undefined}) {
    const pageSize =
      defaultPageSize || get(initialQuery.done(), ['customQuery', 'page', 'page_size'], 10);

    const pageMeta = initialQuery.getResourceMetadata().get('page', null);

    const isLoaded = pageMeta !== null; // if pageMeta defaults to null, query has not resolved yet

    const currentPage = isLoaded ? pageMeta.get('page') : 1;
    const questionSets = initialQuery.getResource();

    this.isRequestInFlight = !isLoaded;
    this.idToPageNumMap = Map();
    this.onUpdate = onUpdate;
    this.totalPages = isLoaded ? pageMeta.get('total_pages') : 1;
    this.baseQuery = initialQuery.unset('customQuery.page').pageSize(pageSize);
    this.questionSetsMap = this.convertSetsToOrderedMap(questionSets);
    this.updateIdToPageNumMap(currentPage, questionSets);
    this.minPage = currentPage;
    this.maxPage = currentPage;
    this.pageSize = pageSize;
    // Not using memoizer because we don't need to keep stale results in the cache
    this.cache = {};

    if (!isLoaded) {
      this.initialize(initialQuery);
    }
  }

  initialize = async (initialQuery) => {
    await initialQuery.getResourcePromise();

    const pageSize = get(initialQuery.done(), ['customQuery', 'page', 'page_size'], 10);
    const pageMeta = initialQuery.getResourceMetadata().get('page');
    const currentPage = pageMeta.get('page');
    const questionSets = initialQuery.getResource();

    this.isRequestInFlight = false;
    this.pageSize = pageSize;
    this.totalPages = pageMeta.get('total_pages');
    this.baseQuery = initialQuery.unset('customQuery.page').pageSize(pageSize);
    this.questionSetsMap = this.convertSetsToOrderedMap(questionSets);
    this.updateIdToPageNumMap(currentPage, questionSets);
    this.minPage = currentPage;
    this.maxPage = currentPage;
  };

  processPage(pageNum, questionSets) {
    const incomingQuestionSetsMap = this.convertSetsToOrderedMap(questionSets);
    if (pageNum === this.minPage - 1) {
      this.minPage = pageNum;
      this.questionSetsMap = incomingQuestionSetsMap.merge(this.questionSetsMap);
    } else if (pageNum === this.maxPage + 1) {
      this.maxPage = pageNum;
      this.questionSetsMap = this.questionSetsMap.merge(incomingQuestionSetsMap);
    } else {
      throw new Error(
        'You are attempting to process a page that has already been ' +
          'processed, or you are skipping pages. This is not allowed.'
      );
    }

    this.updateIdToPageNumMap(pageNum, questionSets);
  }

  getResults() {
    const currentHashCode = this.questionSetsMap.hashCode();
    if (this.cache.resultsHash !== currentHashCode) {
      this.cache.resultsHash = currentHashCode;
      this.cache.results = this.questionSetsMap.toList();
    }
    return this.cache.results;
  }

  convertSetsToOrderedMap(questionSets) {
    return questionSets.reduce((acc, questionSet) => {
      return acc.set(questionSet.getId(), questionSet);
    }, OrderedMap());
  }

  updateIdToPageNumMap(pageNum, questionSets) {
    this.idToPageNumMap = questionSets.reduce((acc, item) => {
      return acc.set(item.getId(), pageNum);
    }, this.idToPageNumMap);
  }

  getQuestionSetsInPage(pageNum) {
    if (pageNum < this.minPage && pageNum > this.maxPage) {
      throw new Error(
        `Attempting to get questions sets in page ${pageNum}, which we have not fetched.`
      );
    }
    const offset = (this.minPage - 1) * this.pageSize;
    const minIndex = (pageNum - 1) * this.pageSize - offset;
    return this.getResults().slice(minIndex, minIndex + this.pageSize);
  }

  getPageForId(id) {
    return this.idToPageNumMap.get(id);
  }

  hasFetchedPage(pageNum) {
    return this.minPage <= pageNum && this.maxPage >= pageNum;
  }

  hasPreviousPageToFetch() {
    return this.minPage > 1;
  }

  hasNextPageToFetch() {
    return this.maxPage < this.totalPages;
  }

  fetchPreviousPage = () => {
    if (!this.hasPreviousPageToFetch()) {
      return null;
    }
    const prevPageNum = this.minPage - 1;
    return this.fetchPage(prevPageNum);
  };

  fetchNextPage = () => {
    if (!this.hasNextPageToFetch()) {
      return null;
    }
    const nextPageNum = this.maxPage + 1;
    return this.fetchPage(nextPageNum);
  };

  async fetchPage(pageNum) {
    if (this.isRequestInFlight) {
      return null;
    }
    this.isRequestInFlight = true;
    this.onUpdate();
    const query = this.baseQuery.page(pageNum);
    const results = await query.getResourcePromise();
    this.processPage(pageNum, results);

    const newTotalPages = query.getResourceMetadata().getIn(['page', 'total_pages']);
    if (this.totalPages !== newTotalPages) {
      this.totalPages = newTotalPages;
    }
    this.isRequestInFlight = false;
    this.onUpdate(results);
    return results;
  }

  updateQuestionForQuestionSetId({questionSetId, question}) {
    const questionSet = this.questionSetsMap.get(questionSetId);
    /**
     * .getQuestions() returns the cached, sorted questions. Because we need to know the index that
     * the question is at, we can't use the model's getter.
     */
    const questionPos = questionSet
      .get('questions')
      .findIndex((staleQuestion) => staleQuestion.getId() === question.getId());
    const updatedQuestionSet = new questionSet.constructor(
      questionSet.setIn(['questions', questionPos], question)
    );

    this.questionSetsMap = this.questionSetsMap.set(questionSetId, updatedQuestionSet);
  }

  getQuestionSetById(id) {
    return this.questionSetsMap.get(id);
  }

  /*
   * The function expects an Immutable Ordered Set (questionSetIndices) and a number (newIndex)
   */
  moveQuestionSetToIndex(questionSetIndices, newIndex) {
    const originalQuestionSets = this.getResults();
    let mutableQuestionSets = originalQuestionSets;
    const updatedMoveIndex = newIndex;
    let sortedIndices = questionSetIndices;
    sortedIndices.forEach((index) => {
      mutableQuestionSets = mutableQuestionSets.filter(
        (set) => set !== originalQuestionSets.get(index)
      );
    });
    // If the move index is lower than all selected sets, we reverse the insert order to be ascending
    if (sortedIndices.first() < updatedMoveIndex) {
      sortedIndices = sortedIndices.reverse();
    }
    // inserting questions into new position
    sortedIndices.forEach((index) => {
      mutableQuestionSets = mutableQuestionSets.insert(
        updatedMoveIndex,
        originalQuestionSets.get(index)
      );
    });
    this.questionSetsMap = this.convertSetsToOrderedMap(mutableQuestionSets);
  }
}
