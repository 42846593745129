import {Record, List} from 'immutable';

// Fill In The Blank Dropdown Choice
class DropdownChoiceModel extends Record({
  id: '',
  value: ''
}) {
  getId() {
    return this.get('id');
  }

  getValue() {
    return this.get('value');
  }
}

// Fill In The Blank Dropdown
class DropdownModel extends Record({
  choices: new List(),
  id: '',
  position: 0
}) {
  static buildDropdownChoices(dropdown) {
    const choices = dropdown.get('choices');
    return dropdown.set(
      'choices',
      choices.map((choice) => new DropdownChoiceModel(choice))
    );
  }

  constructor(dropdownData) {
    super(DropdownModel.buildDropdownChoices(dropdownData));
  }

  getId() {
    return this.get('id');
  }

  getChoices() {
    return this.get('choices');
  }

  getPosition() {
    return this.get('position');
  }
}

export function fillInTheBlankSetup(data) {
  const dropdowns = data
    .get('dropdowns')
    .map((dropdown) => new DropdownModel(dropdown))
    .toList();
  return data.set('dropdowns', dropdowns);
}
