import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {callAction} from 'client/framework';
import eliminationToolActions from './EliminationTool.actions';
import eliminationToolStore from './EliminationTool.store';
import CheckboxToggle from 'sg/CheckboxToggle/CheckboxToggle.react';
import HideShowButton from './HideShowButton.react';
import './elimination-tool.scss';

export default class EliminationTool extends React.Component {
  static propTypes = {
    hidden: PropTypes.bool,
    storeName: PropTypes.string
  };

  toggleEnabled = (e) => {
    callAction(eliminationToolActions.SET_ENABLED, e.target.checked);
  };

  render() {
    const isEnabled = eliminationToolStore.isEnabled();

    return (
      <div
        className={classnames('elimination-tool', {
          'elimination-tool--hidden': this.props.hidden
        })}
      >
        <CheckboxToggle
          activeText='On'
          checked={isEnabled}
          className='elimination-tool__checkbox-toggle'
          label='Elimination Tool'
          name='Elimination Tool'
          value='elimination-tool'
          onChange={this.toggleEnabled}
        />
        <HideShowButton storeName={this.props.storeName} />
      </div>
    );
  }
}
