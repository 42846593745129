import React, {lazy} from 'react';
import PropTypes from 'prop-types';
import {Route, IndexRoute, IndexRedirect, Redirect} from 'react-router';
import classnames from 'classnames';

import {requireSessionWithLoginPrompt} from 'lib/decorators/requireSession';

import sessionStore from 'client/Session/SessionStore';

import SecondaryHeader from './shared/SecondaryHeader';

const Classrooms = lazy(() => import('client/Classrooms/Classrooms.react'));
const ClassroomsList = lazy(() =>
  import('client/Classrooms/Teacher/ClassroomsList/ClassroomsList.react')
);
const Classroom = lazy(() => import('client/Classrooms/Classroom/Classroom.react'));

const TeacherDashboard = lazy(() =>
  import('client/Classrooms/Classroom/Teacher/TeacherDashboard/TeacherDashboard.react')
);

const People = lazy(() => import('client/Classrooms/Classroom/Teacher/People/People.react'));
const Coteachers = lazy(() =>
  import('client/Classrooms/Classroom/Teacher/People/Coteachers/Coteachers.react')
);

const ManageRoster = lazy(() =>
  import('client/Classrooms/Classroom/Teacher/ManageRoster/ManageRoster.react')
);
const EnrolledStudents = lazy(() =>
  import('client/Classrooms/Classroom/Teacher/ManageRoster/EnrolledStudents/EnrolledStudents.react')
);
const InvitedStudents = lazy(() =>
  import('client/Classrooms/Classroom/Teacher/ManageRoster/InvitedStudents/InvitedStudents.react')
);
const TeacherClassroomSettings = lazy(() =>
  import(
    'client/Classrooms/Classroom/Teacher/TeacherClassroomSettings/TeacherClassroomSettings.react'
  )
);

// @todo make separate student list components for Active Assignments and Past Assignments
const AssignmentsList = lazy(() =>
  import('client/Classrooms/Classroom/Teacher/Assignments/AssignmentsList/AssignmentsList.react')
);

const StudentAssignmentsList = lazy(() =>
  import('client/Classrooms/Classroom/Student/Assignments/StudentAssignmentsList.react')
);

const AssignmentDrilldown = lazy(() =>
  import(
    'client/Classrooms/Classroom/Teacher/Assignments/AssignmentDrilldown/AssignmentDrilldown.react'
  )
);
const AssignmentDrilldownQuestions = lazy(() =>
  import(
    'client/Classrooms/Classroom/Teacher/Assignments/AssignmentDrilldown/Questions/Questions.react'
  )
);
const AssignmentDrilldownStudents = lazy(() =>
  import(
    'client/Classrooms/Classroom/Teacher/Assignments/AssignmentDrilldown/Students/Students.react'
  )
);
const AssignmentDrilldownSingleStudent = lazy(() =>
  import(
    'client/Classrooms/Classroom/Teacher/Assignments/AssignmentDrilldown/SingleStudent/Student.react'
  )
);
const TeacherSubjectsList = lazy(() =>
  import('client/Classrooms/Classroom/Teacher/Subjects/TeacherSubjectsList.react')
);
const TeacherPracticeExamsList = lazy(() =>
  import(
    'client/Classrooms/Classroom/Teacher/PracticeExams/TeacherPracticeExamsList/TeacherPracticeExamsList.react'
  )
);

const SingleStudentView = lazy(() =>
  import('client/Classrooms/Classroom/Teacher/Student/SingleStudentView.react')
);
const StudentOverview = lazy(() =>
  import('client/Classrooms/Classroom/Teacher/Student/Overview/StudentOverview.react')
);
const StudentAssignmentOverview = lazy(() =>
  import('client/Classrooms/Classroom/Teacher/Student/Assignments/AssignmentOverview.react')
);
const StudentTopics = lazy(() =>
  import('client/Classrooms/Classroom/Teacher/Student/Topics/Topics.react')
);
const StudentInfo = lazy(() =>
  import('client/Classrooms/Classroom/Teacher/Student/Info/StudentInfo.react')
);

const ClassroomInsights = lazy(() =>
  import('client/Classrooms/Classroom/Teacher/ClassroomInsights/ClassroomInsights.react')
);
const QuestionInsights = lazy(() =>
  import(
    'client/Classrooms/Classroom/Teacher/ClassroomInsights/InsightsTables/QuestionInsights/QuestionInsights.react'
  )
);
const StandardInsights = lazy(() =>
  import(
    'client/Classrooms/Classroom/Teacher/ClassroomInsights/InsightsTables/StandardInsights/StandardInsights.react'
  )
);
const StudentInsights = lazy(() =>
  import(
    'client/Classrooms/Classroom/Teacher/ClassroomInsights/InsightsTables/StudentInsights/StudentInsights.react'
  )
);

const StudentDashboard = lazy(() => import('./StudentDashboard/StudentDashboard.react'));
const StudentClassrooms = lazy(() => import('./StudentDashboard/Classrooms.react'));

const AssignmentV2Results = lazy(() =>
  import('./Classroom/Teacher/Assignments/AssignmentV2Results/AssignmentV2Results.react')
);
const SingleStudentResults = lazy(() =>
  import(
    './Classroom/Teacher/Assignments/AssignmentV2Results/SingleStudentResults/SingleStudentResults.react'
  )
);
const AssignmentV2Settings = lazy(() =>
  import('./Classroom/Teacher/Assignments/AssignmentV2Settings/AssignmentV2Settings')
);

const ApprovalStatuses = lazy(() => import('./Teacher/ApprovalStatuses/ApprovalStatuses.react'));

function requireTeacherStatus(nextState, replace) {
  if (process.env.IS_BROWSER && !sessionStore.hasTeacherAccess()) {
    replace('/', null);
  }
}

function renderComponentWithStatuses(Component) {
  function ComponentWithStatuses(props) {
    return (
      <>
        <ApprovalStatuses
          className={classnames('u-max-width_default', {
            'u-max-width_legacy': props.route.path === 'insights' || props.route.path === 'overview'
          })}
          type='neutral'
        />
        <Component {...props} />
      </>
    );
  }

  ComponentWithStatuses.propTypes = {
    route: PropTypes.object
  };

  return ComponentWithStatuses;
}

function getClassroomsListComponent(nextState, callback) {
  const component = sessionStore.isTeacher()
    ? renderComponentWithStatuses(ClassroomsList)
    : StudentDashboard;
  callback(null, component);
}

const classroomSessionRequired = requireSessionWithLoginPrompt()(({children, ...rest}) => {
  return sessionStore.isTeacher() ? (
    <>
      <SecondaryHeader {...rest} aria-label='secondary' />
      <Classrooms {...rest}>{children}</Classrooms>
    </>
  ) : (
    <StudentClassrooms {...rest}>{children}</StudentClassrooms>
  );
});

export default (
  <Route title='Classes | Albert.io' path='classes' component={classroomSessionRequired}>
    <IndexRoute getComponent={getClassroomsListComponent} withWhiteBackground />
    <Route
      path='archived'
      onEnter={requireTeacherStatus}
      component={renderComponentWithStatuses(ClassroomsList)}
      title='Archived'
      withWhiteBackground
    />
    {
      /**
       * @todo – When we use `archive_date` to derive whether or not a classroom is archived we will be able to
       * allow users to edit the date.
       * @see https://github.com/albert-io/project-management/milestone/49
       */
      // <Route
      //   path='auto-archive-list'
      //   onEnter={requireTeacherStatus}
      //   component={AutoArchiveClassroomsList}
      // />
    }
    <Route path=':classId' component={Classroom}>
      <Route path='my-assignments' component={StudentAssignmentsList} />
      <Redirect from='my-exams' to='my-assignments' />
      <Redirect from='my-assessments' to='my-assignments' />

      <Route
        path='assignments'
        onEnter={requireTeacherStatus}
        component={renderComponentWithStatuses(AssignmentsList)}
      >
        <Redirect from='templates' to='/saved' />
      </Route>
      {/* Teacher Practice Exams */}
      <Redirect from='assessments' to='assignments' />
      <Route
        path='assessments'
        onEnter={requireTeacherStatus}
        component={TeacherPracticeExamsList}
      />
      <Redirect from='exams' to='assessments' />
      <Route path='assessments/:examId/student/:studentId' component={SingleStudentResults} />
      <Redirect
        from='exams/:examId/student/:studentId'
        to='assessments/:examId/student/:studentId'
      />
      <Route
        path='assessments/:examId'
        onEnter={requireTeacherStatus}
        component={AssignmentV2Results}
      />
      <Redirect from='exams/:examId' to='assessments/:examId' />
      <Route
        path='assessments/:examId/edit'
        onEnter={requireTeacherStatus}
        component={AssignmentV2Settings}
      />
      <Redirect from='exams/:examId/edit' to='assessments/:examId/edit' />
      {/* Teacher Practice Exams End */}
      <Route
        path='people'
        onEnter={requireTeacherStatus}
        component={renderComponentWithStatuses(People)}
        withWhiteBackground
      >
        <IndexRedirect to='students/enrolled' />
        <Route component={ManageRoster}>
          <Route
            path='students/enrolled'
            component={EnrolledStudents}
            title='Enrolled'
            withWhiteBackground
          />
          <Route
            path='students/invited'
            component={InvitedStudents}
            title='Invited'
            withWhiteBackground
          />
        </Route>
        <Route
          path='coteachers/approved'
          component={Coteachers}
          title='Coteachers'
          withWhiteBackground
        />
      </Route>

      <Route
        path='assignments/:assignmentId'
        onEnter={requireTeacherStatus}
        component={AssignmentDrilldown}
      >
        <Route path='student-detail' component={AssignmentDrilldownStudents} />
        <Route path='question-detail' component={AssignmentDrilldownQuestions} />
      </Route>

      <Route
        path='assignments/:assignmentId/student-detail/:studentId'
        onEnter={requireTeacherStatus}
        component={AssignmentDrilldownSingleStudent}
      />
      <Route
        path='subjects'
        component={renderComponentWithStatuses(TeacherSubjectsList)}
        onEnter={requireTeacherStatus}
        withWhiteBackground
      />

      <Route component={renderComponentWithStatuses(ClassroomInsights)} path='insights' />
      <Route component={QuestionInsights} path='insights/topic-drilldown/question/:questionId' />
      <Route component={StandardInsights} path='insights/topic-drilldown/standard/:standardId' />
      <Route component={StudentInsights} path='insights/topic-drilldown/student/:studentId' />

      <Route path='students' onEnter={requireTeacherStatus}>
        <IndexRedirect to='overview' />
        <Route path='overview' component={renderComponentWithStatuses(TeacherDashboard)} />

        <Route path='detail/:userId' component={SingleStudentView}>
          <IndexRedirect to='overview' />
          <Route path='info' component={StudentInfo} />
          <Route path='assignments' component={StudentAssignmentOverview} />
          <Route path='overview' component={StudentOverview} />
          <Route path='topics' component={StudentTopics} />
        </Route>
      </Route>
      <Route path='settings' onEnter={requireTeacherStatus}>
        <IndexRedirect to='details' />
        <Route path='details' component={renderComponentWithStatuses(TeacherClassroomSettings)} />
      </Route>
    </Route>
  </Route>
);
