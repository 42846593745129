import React from 'react';
import PropTypes from 'prop-types';
import {setUpStore} from 'client/framework';
import {TwoWayQuestionModel} from 'resources/Question/Question.model';
import {AuthoringQuestionModelV1} from 'resources/augmented/AuthoringQuestion/AuthoringQuestionModel.v1';
import {QuestionModelV3} from 'resources/augmented/Question/QuestionModel.v3';

import TwoWayQuestionTable from './TwoWayQuestionTable.react';
import TwoWayQuesionStore from './TwoWayQuestion.store';

export default class TwoWayQuestion extends React.Component {
  static propTypes = {
    isGuessSubmitted: PropTypes.bool,
    storeName: PropTypes.string.isRequired,
    question: PropTypes.oneOfType([
      PropTypes.instanceOf(TwoWayQuestionModel),
      PropTypes.instanceOf(AuthoringQuestionModelV1),
      PropTypes.instanceOf(QuestionModelV3)
    ]),
    questionId: PropTypes.string
  };

  getStore() {
    return setUpStore(TwoWayQuesionStore, this.props.storeName, this.props.questionId);
  }

  render() {
    const {question, isGuessSubmitted} = this.props;
    return (
      /**
       * @todo: Drop `two-way-question` once legacy question renderers are gone
       */
      <div className='two-way-question twq-wrapper'>
        <TwoWayQuestionTable
          question={question}
          store={this.getStore()}
          isGuessSubmitted={isGuessSubmitted}
        />
      </div>
    );
  }
}
