/**
 * # Inert
 *
 * Helper component to make DOM nodes and their children non-interactive.
 * Enabled by default, pass a `disabled` prop to make things interactive.
 *
 * ## Props
 *
 * - `disabled` (optional): A boolean value indicating whether the component is disabled or not.
 * - `children`: The content to be rendered inside the Inert component.
 *
 * ## Usage
 *
 * ```tsx
 * <Inert>
 *   <button onClick={function() { console.log('This aint gonna log') }}>
 *     You can not click me, friend
 *   </button>
 * </Inert>
 *
 * <Inert disabled>
 *   <button onClick={function() { console.log('This will def log') }}>
 *     I am clickable
 *   </button>
 * </Inert>
 * ```
 */

import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './inert.scss';

interface InertProps extends PropsWithChildrenRequired {
  disabled?: boolean;
}

const Inert = ({disabled = false, children}: InertProps) => {
  const contentWrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!process.env.IS_BROWSER) {
      return;
    }
    if (disabled) {
      contentWrapper.current?.removeAttribute('inert');
    } else {
      contentWrapper.current?.setAttribute('inert', '');
    }
  }, [disabled]);

  return (
    <div
      className={classnames('h-inert', {
        'h-inert--inert': !disabled
      })}
    >
      <div ref={contentWrapper}>{children}</div>
    </div>
  );
};

Inert.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool
};

export default Inert;
