function makePositionMap(questionSets) {
  return questionSets.reduce((acc, set, i) => {
    acc[set.getId()] = i;
    return acc;
  }, {});
}

export const makeNavigateNextProps = ({
  paginatedQuestionsListManager,
  activeQuestionSet: questionSet,
  activeQuestion: question,
  setActiveQuestionState,
  scrollToQuestionByIdFunc
}: any) => {
  const currentQuestionSets = paginatedQuestionsListManager.getResults();
  if (currentQuestionSets.isEmpty()) {
    return makeNavigateProps(null);
  }
  const nextSetAndQuestion = getNextQuestionState({
    questionSets: currentQuestionSets,
    activeSet: questionSet,
    activeQuestion: question
  });
  return makeNavigateProps(nextSetAndQuestion, setActiveQuestionState, scrollToQuestionByIdFunc);
};

export const makeNavigateBackProps = ({
  paginatedQuestionsListManager,
  activeQuestionSet: questionSet,
  activeQuestion: question,
  setActiveQuestionState,
  scrollToQuestionByIdFunc
}: any) => {
  const currentQuestionSets = paginatedQuestionsListManager.getResults();
  if (currentQuestionSets.isEmpty()) {
    return makeNavigateProps(null);
  }

  const previousSetAndQuestion = getPreviousQuestionState({
    questionSets: currentQuestionSets,
    activeSet: questionSet,
    activeQuestion: question
  });
  return makeNavigateProps(
    previousSetAndQuestion,
    setActiveQuestionState,
    scrollToQuestionByIdFunc
  );
};

export const makeNavigateProps = (
  upcomingSetAndQuestion,
  setActiveQuestionState,
  scrollToQuestionByIdFunc
) => {
  if (!upcomingSetAndQuestion) {
    return {disabled: true, handleNavigation: undefined};
  }
  const {questionSet, question} = upcomingSetAndQuestion;
  return {
    disabled: false,
    handleNavigation: () => {
      scrollToQuestionByIdFunc.current(questionSet.getId());
      setActiveQuestionState(question, questionSet);
    }
  };
};

export function getPreviousQuestionState({questionSets, activeSet, activeQuestion}) {
  if (!activeSet || !activeQuestion) {
    return null;
  }
  const positionMap = makePositionMap(questionSets);
  const currentQuestionSetPos = positionMap[activeSet.getId()];
  const questionPos = activeQuestion.getPosition();

  const updatedActiveSet = questionSets.find((set) => set.getId() === activeSet.getId());

  if (!currentQuestionSetPos || (currentQuestionSetPos === 0 && questionPos === 0)) {
    return null;
  }

  let returnSet;
  let returnQuestion;

  if (questionPos === 0) {
    returnSet = questionSets.get(currentQuestionSetPos - 1);
    returnQuestion = returnSet.getQuestions().last();
  } else {
    returnSet = updatedActiveSet;
    returnQuestion = updatedActiveSet.getQuestions().get(questionPos - 1);
  }

  return {
    questionSet: returnSet,
    question: returnQuestion
  };
}

export function getNextQuestionState({questionSets, activeSet, activeQuestion}) {
  if (!activeSet || !activeQuestion) {
    return null;
  }
  const positionMap = makePositionMap(questionSets);
  const currentQuestionSetPos = positionMap[activeSet.getId()];
  const questionPos = activeQuestion.getPosition();

  const updatedActiveSet = questionSets.find((set) => set.getId() === activeSet.getId());

  const totalQuestionSets = questionSets.size;
  const questionsInSet = activeSet.getQuestions().size;

  if (
    (currentQuestionSetPos !== 0 && !currentQuestionSetPos) ||
    (currentQuestionSetPos === totalQuestionSets - 1 && questionPos === questionsInSet - 1)
  ) {
    return null;
  }

  let returnSet;
  let returnQuestion;

  if (questionPos === questionsInSet - 1) {
    returnSet = questionSets.get(currentQuestionSetPos + 1);
    returnQuestion = returnSet.getQuestions().first();
  } else {
    returnSet = updatedActiveSet;
    returnQuestion = updatedActiveSet.getQuestions().get(questionPos + 1);
  }

  return {
    questionSet: returnSet,
    question: returnQuestion
  };
}
