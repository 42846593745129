// @flow
import {fromJS, List, Map} from 'immutable';
import {Store} from 'client/framework';
import subjectFAQQuestionsActions from './SubjectFAQQuestions.actions';
import subjectFAQListStore from '../SubjectFAQList.store';
import type {SubjectFaqModelV1} from 'resources/GeneratedModels/SubjectFaq/SubjectFaqModel.v1';

class SubjectFAQQuestionsStore extends Store {
  constructor(name: string) {
    super(name);
    this.initialData = fromJS({
      error: {},
      orderedIdList: [],
      saveFAQOrderPromise: null,
      savePending: false,
      showToast: false,
      subjectFAQsIdMap: {}
    });

    this.setInitialData(this.initialData);
    this.handle(subjectFAQQuestionsActions.DISCARD_CHANGES, this._discardChanges);
    this.handle(subjectFAQQuestionsActions.MOVE_FAQ, this._moveFAQ);
    this.handle(subjectFAQQuestionsActions.SAVE_FAQ_ORDER, this._saveFAQOrderWrapper);
    this.handle(
      subjectFAQQuestionsActions.SET_REARRANGE_MODE,
      this._initializeLocalUtilityCollections
    );
    this.handle(subjectFAQQuestionsActions.SAVE_TOAST_EMITTED, this._clearToast);
  }

  _clearToast() {
    this.writeData((store) => {
      return store.set('error', new Map()).set('showToast', false);
    });
  }

  _clearLocalUtilityCollections() {
    this.resetStore();
  }

  _discardChanges() {
    this._clearLocalUtilityCollections();
    subjectFAQListStore.setRearrangeMode({rearrangeMode: false});
  }

  _initializeLocalUtilityCollections({subjectFAQs}: {subjectFAQs: List<SubjectFaqModelV1>}) {
    const orderedIdList = subjectFAQs.map((subjectFAQ) => subjectFAQ.getId()).toList();
    const subjectFAQsIdMap = subjectFAQs.reduce((idMap, subjectFAQ) => {
      return idMap.set(subjectFAQ.getId(), subjectFAQ);
    }, new Map());
    this.writeData((store) => {
      return store.set('orderedIdList', orderedIdList).set('subjectFAQsIdMap', subjectFAQsIdMap);
    });
  }

  _moveFAQ({oldIndex, newIndex}: {oldIndex: number, newIndex: number}) {
    const orderedIdList = this.getOrderedIdList();

    const idToMove = orderedIdList.get(oldIndex);
    const updatedIdList = orderedIdList.delete(oldIndex).insert(newIndex, idToMove);
    this._setOrderedIdList(updatedIdList);
  }

  _saveFAQOrderWrapper() {
    const saveFAQOrderPromise = this._saveFAQOrder();
    this.writeData('saveFAQOrderPromise', saveFAQOrderPromise);
  }

  /**
   * It is indeed a bit unfortunate that we cannot directly patch the relationship through the subjects resource and
   * do all of this in a single request. We're gonna take the hit on this being n requests on the premise that this is
   * only happening internally on a relatively low traffic (internal editing + external viewing) feature.
   */
  async _saveFAQOrder(): Promise<*> {
    try {
      this.writeData('savePending', true);
      const savePromises = this.getOrderedSubjectFAQList().map((subjectFAQ, index) => {
        return subjectFAQ.setOrderPosition(index).save();
      });

      await Promise.all(savePromises);
      this._clearLocalUtilityCollections();
      subjectFAQListStore.setRearrangeMode({rearrangeMode: false});
    } catch (error) {
      this.writeData('error', error);
    } finally {
      this.writeData('savePending', false);
      this.writeData('showToast', true);
    }
  }

  _setOrderedIdList(orderedIdList: List<string>) {
    this.writeData('orderedIdList', orderedIdList);
  }

  getError(): Map<string, *> {
    return this.readData('error');
  }

  getOrderedIdList(): List<string> {
    return this.readData('orderedIdList');
  }

  getOrderedSubjectFAQList(): List<SubjectFaqModelV1> {
    const subjectFAQsIdMap = this.getSubjectFAQsIdMap();
    return this.getOrderedIdList().map((subjectFAQId) => subjectFAQsIdMap.get(subjectFAQId));
  }

  getRearrangeMode(): boolean {
    return this.readData('rearrangeMode');
  }

  getSaveFAQOrderPromise(): Promise<*> {
    return this.readData('saveFAQOrderPromise');
  }

  getSavePending(): boolean {
    return this.readData('savePending');
  }

  getShowToast(): boolean {
    return this.readData('showToast');
  }

  getSubjectFAQsIdMap(): Map<string, SubjectFaqModelV1> {
    return this.readData('subjectFAQsIdMap');
  }
}

export default new SubjectFAQQuestionsStore('SubjectFAQQuestionsStore');
