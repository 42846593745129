/**
 * # SelectField
 *
 * A component that renders a select field with options.
 *
 * ## Props
 *
 * - `options`: An array of option props for the select field. Each option prop passed will be rendered into an `<Option />` atomic component.
 * - `readOnly?`: A boolean indicating whether the select field is read-only.
 * - `className?: string`: Additional class name for the Field component.
 * - `defaultValue?: string`: The default value for the input field.
 * - `error?: boolean`: Indicates whether there is an error with the field.
 * - `disabled?: boolean`: Indicates whether the field is disabled.
 * - `id?: string`: The ID of the field.
 * - `inline?: boolean`: Indicates whether the field should be displayed inline.
 * - `label?: string`: The label text for the field.
 * - `message?: string | null | boolean`: The message text for the field.
 * - `onBlur?: (...params: any) => void`: Event handler for the blur event.
 * - `onChange?: (...params: any) => void`: Event handler for the change event.
 * - `onFocus?: (...params: any) => void`: Event handler for the focus event.
 * - `onMouseEnter?: (...params: any) => void`: Event handler for the mouse enter event.
 * - `onMouseLeave?: (...params: any) => void`: Event handler for the mouse leave event.
 * - `required?: boolean`: Indicates whether the field is required.
 * - `size?: typeof sizesList[number]`: The size of the field.
 * - `success?: boolean`: Indicates whether the field is successful.
 * - `type?: string`: The type of the input field.
 * - `value?: string | number`: The value of the input field.
 * - `border?`: Specifies whether to display a border around the select input. Default is `false`.
 * - `fillWrapper?`: Specifies whether the select input should fill its wrapper container. Default is `true`.
 * - `children?`: The content to be rendered inside the select input.
 * - `...rest?`: Additional props to be passed to the underlying `IconInput` component.
 *
 * ## Usage
 *
 * ```tsx
 * import SelectField from './SelectField.react';
 *
 * const options = [
 *   { value: 'option1', label: 'Option 1' },
 *   { value: 'option2', label: 'Option 2' },
 *   { value: 'option3', label: 'Option 3' },
 * ];
 *
 * const MyComponent = () => {
 *   return (
 *     <SelectField options={options} readOnly={true} />
 *   );
 * };
 * ```
 */

import React, {forwardRef} from 'react';

import Field, {FieldProps} from '../Field/Field.react';
import Select from '../Select/Select.react';
import Option, {OptionProps} from '../../atoms/Option/Option.react';

type SelectFieldProps = {
  options: OptionProps[];
  readOnly?: boolean;
} & FieldProps<typeof Select>;

const SelectField = forwardRef<HTMLSelectElement, SelectFieldProps>(
  ({options, readOnly = false, ...rest}, ref) => (
    <Field
      as={Select}
      ref={ref}
      readOnly={readOnly}
      aria-label={readOnly ? 'Changes cannot be made to this select menu' : undefined}
      {...rest}
    >
      {options.map(({disabled, ...option}) => (
        <Option
          key={option.value}
          disabled={readOnly || disabled}
          {...option}
          label={option.label}
        />
      ))}
    </Field>
  )
);

export default SelectField;
