export default function makeDataProps(props: Object): Object {
  return Object.keys(props)
    .filter((key) => key.startsWith('data-'))
    .reduce(
      (accumulator, key) => ({
        ...accumulator,
        [key]: props[key]
      }),
      {}
    );
}
