import React, {useEffect} from 'react';
import classnames from 'classnames';
import {
  Button,
  ButtonGroup,
  LoadingSpinner,
  Checkbox,
  Radio,
  Label,
  Fieldset
} from '@albert-io/atomic';
import sessionStore from 'client/Session/SessionStore';
import masqueradeStore from 'generic/Masquerade/Masquerade.store';
import {useTracking} from 'react-tracking';

import {
  setFilterValue,
  filterType,
  resetFilterValues,
  isChecked,
  difficulty,
  status,
  radioStatus,
  allFilters,
  isRadio,
  paramOptionsMap,
  ParamValueMap
} from './filters.utils';
import './filters.scss';

interface Props {
  isLoading: boolean;
  isFolder?: boolean;
}

const difficultyText = {
  [difficulty.easy]: 'Easy',
  [difficulty.moderate]: 'Moderate',
  [difficulty.difficult]: 'Difficult'
};

const answeredText = {
  [status.answered]: 'Answered',
  [status.unanswered]: 'Unanswered'
};

const savedInFolderText = {
  [radioStatus.true]: 'Saved to folders',
  [radioStatus.false]: 'Not saved to folders'
};

const usedInAssignmentText = {
  [radioStatus.true]: 'Used in assignments',
  [radioStatus.false]: 'Not used in assignments'
};

const filterText = {
  [filterType.difficulty]: difficultyText,
  [filterType.status]: answeredText,
  [filterType.savedInFolder]: savedInFolderText,
  [filterType.usedInAssignment]: usedInAssignmentText
};

export const FilterDropdown = ({isLoading, isFolder = false}: Props) => {
  const {trackEvent} = useTracking({type: 'dropdown item', component: 'Filter menu'});
  useEffect(() => {
    trackEvent({
      event: 'click',
      type: 'dropdown',
      component: 'Toolbar'
    });
    return () => {
      trackEvent({
        event: 'click off',
        type: 'dropdown',
        component: 'Toolbar',
        content: null
      });
    };
  }, []);
  return (
    <div className='u-pad-lr_2 u-pad-tb_1'>
      {allFilters.map((filter) => {
        if (isFolder && filter === filterType.savedInFolder) {
          return null;
        }
        if (!sessionStore.hasValidSession() && filter !== filterType.difficulty) {
          return null;
        }
        if (
          sessionStore.isStudent() &&
          filter !== filterType.difficulty &&
          filter !== filterType.status
        ) {
          return null;
        }
        if (
          masqueradeStore.isMasquerading() &&
          (filter === filterType.savedInFolder || filter === filterType.usedInAssignment)
        ) {
          return null;
        }
        return (
          <React.Fragment key={`filters-${filter}`}>
            <Fieldset>
              {paramOptionsMap[filter].map((option: ParamValueMap[typeof filter]) => {
                const checked = isChecked(filter, option);
                const id = `filters-${filter}-${option}`;
                return (
                  <Label
                    className='filters-dropdown__label'
                    key={id}
                    htmlFor={id}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {isRadio(filter) ? (
                      <Radio
                        className='u-mar-r_2'
                        id={id}
                        name={filter}
                        onClick={() => {
                          setFilterValue(filter, option, !checked);
                          trackEvent({content: filterText[filter][option], event: 'click'});
                        }}
                        checked={checked}
                      />
                    ) : (
                      <Checkbox
                        aria-hidden={false}
                        className='u-mar-r_2'
                        id={id}
                        name={filter}
                        onChange={() => {
                          setFilterValue(filter, option, !checked);
                          trackEvent({content: filterText[filter][option], event: 'click'});
                        }}
                        checked={checked}
                      />
                    )}
                    {filterText[filter][option]}
                  </Label>
                );
              })}
            </Fieldset>
            <div className='a-divider u-mar-t_1 u-mar-b_2' />
          </React.Fragment>
        );
      })}

      <div className='u-display_flex u-align-items_center'>
        <LoadingSpinner
          data-testid='spinner'
          className={classnames('filters-dropdown__loader', {
            'u-float_right u-visibility_hidden': !isLoading
          })}
          aria-hidden={!isLoading}
        />
        <ButtonGroup>
          <Button
            className='u-mar-r_1'
            aria-label='reset'
            variant='text'
            color='secondary'
            onClick={(e) => {
              e.stopPropagation();
              resetFilterValues();
            }}
            data-testid='practice-view-toolbar-filters__reset-btn'
          >
            Reset
          </Button>
          <Button aria-label='done' data-testid='practice-view-toolbar-filters__done-btn'>
            Done
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};

export default FilterDropdown;
