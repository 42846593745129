/**
 * # Field
 *
 * This component bundles together all the styles, subcomponents, and behavior that the Design System
 * expects of our *Field components, such as TextField and SelectField.
 *
 * The HOC takes just one argument, a React component, and returns a new class passing that
 * component in an instance of Field in the place of the input.  This helps DRY up code around the
 * implementations of Field by eliminating the need to duplicate things like the logic around Field.Message,
 * and how we pass hover and focus states between and among Field's neighboring elements.
 *
 * ## Props<C extends React.ElementType>
 * -  as?: C
 * - `className?: string`: Additional class name for the Field component.
 * - `defaultValue?: string`: The default value for the input field.
 * - `error?: boolean`: Indicates whether there is an error with the field.
 * - `disabled?: boolean`: Indicates whether the field is disabled.
 * - `id?: string`: The ID of the field.
 * - `inline?: boolean`: Indicates whether the field should be displayed inline.
 * - `label?: string`: The label text for the field.
 * - `message?: string | null | boolean`: The message text for the field.
 * - `onBlur?: (...params: any) => void`: Event handler for the blur event.
 * - `onChange?: (...params: any) => void`: Event handler for the change event.
 * - `onFocus?: (...params: any) => void`: Event handler for the focus event.
 * - `onMouseEnter?: (...params: any) => void`: Event handler for the mouse enter event.
 * - `onMouseLeave?: (...params: any) => void`: Event handler for the mouse leave event.
 * - `required?: boolean`: Indicates whether the field is required.
 * - `size?: typeof sizesList[number]`: The size of the field.
 * - `success?: boolean`: Indicates whether the field is successful.
 * - `type?: string`: The type of the input field.
 * - `value?: string | number`: The value of the input field.
 * - `...rest: C`: Any value belonging to type C
 *
 * ## Props (extends React.ElementType)
 *
 * - `as?: C`: The component to render as the input field.
 *
 * ## Usage
 *
 * ```tsx
 * import Field from './Field.react';
 *
 * const Example = () => {
 *   return (
 *     <Field
 *       label="Username"
 *       defaultValue="john.doe"
 *       error={false}
 *       disabled={false}
 *       required={true}
 *       size="regular"
 *       type="text"
 *       value=""
 *     />
 *   );
 * };
 * ```
 */

import React, {forwardRef} from 'react';
import classnames from 'classnames';

import makeConstants from 'lib/makeConstants';
import makeDataProps from 'lib/makeDataProps';
import WithHoverAndFocus from '@albert-io/atomic/helpers/WithHoverAndFocus/WithHoverAndFocus.react';
import Text from '@albert-io/atomic/atoms/Text/Text.react';
import Label from '@albert-io/atomic/atoms/Label/Label.react';
import Input from '@albert-io/atomic/atoms/Input/Input.react';
import './field.scss';

export const sizesList = ['s', 'regular', 'l'] as const;
const sizes = makeConstants(...sizesList);

interface FieldMemberProps extends PropsWithChildrenRequired, PropsWithClassNameOptional {
  error?: boolean;
  hasFocus?: boolean;
  hasHover?: boolean;
  size: (typeof sizesList)[number];
  success?: boolean;
}

interface LabelTextProps extends FieldMemberProps {
  inline?: boolean;
  required?: boolean;
}

const LabelText = ({
  className,
  error,
  hasFocus,
  hasHover,
  inline,
  required,
  success,
  size,
  children
}: LabelTextProps) => (
  <Text
    className={classnames('m-field__label', className, {
      'm-field__label--error': error,
      'm-field__label--focus': hasFocus,
      'm-field__label--hover': hasHover,
      'm-field__label--inline': inline,
      'm-field__label--required': required,
      'm-field__label--success': success
    })}
    color='secondary'
    size={sizes[size]}
  >
    {children}
  </Text>
);

interface MessageProps extends FieldMemberProps {
  id?: string;
  role: string;
}

const Message = ({children, className, error, hasHover, size, success, ...rest}: MessageProps) => {
  return (
    <Text
      className={classnames('m-field__message', className, {
        [`m-field__message--${size}`]: sizes[size],
        'm-field__message--hover': hasHover,
        'm-field__message--success': success,
        'm-field__message--error': error
      })}
      size='xs'
      {...rest}
    >
      {children}
    </Text>
  );
};

export interface Props extends PropsWithClassNameOptional {
  defaultValue?: string;
  error?: boolean;
  disabled?: boolean;
  id?: string;
  inline?: boolean;
  label?: string;
  message?: string | null | boolean;
  onBlur?: (...params: any) => void;
  onChange?: (...params: any) => void;
  onFocus?: (...params: any) => void;
  onMouseEnter?: (...params: any) => void;
  onMouseLeave?: (...params: any) => void;
  required?: boolean;
  size?: (typeof sizesList)[number];
  success?: boolean;
  type?: string;
  value?: string | number;
}

export type FieldProps<C extends React.ElementType> = PolymorphicComponentPropsWithRef<C, Props>;

type FieldComponent = <C extends React.ElementType = 'input'>(
  props: FieldProps<C>
) => React.ReactElement | null;

/**
 * What it do
 * ==========
 *
 * This component bundles together all the styles, subcomponnets, and behavior that the Design System
 * expects of our *Field components, such as TextField and SelectField.
 *
 * How to use it
 * =============
 *
 * The HOC takes just one argument, a React component, and returns a new class passing that
 * component in an instance of Field in the place of the input.  This helps DRY up code around the
 * implementations of Field by eliminating the need to duplicate things like the logic around Field.Message,
 * and how we pass hover and focus states between and among Field's neighboring elements.
 */

export const Field: FieldComponent = forwardRef(function Field<
  C extends React.ElementType = 'input'
>(props: FieldProps<C>, ref: PolymorphicRef<C>) {
  const {
    as: WrappedComponent,
    className,
    defaultValue,
    error,
    id,
    inline,
    label,
    message,
    onBlur,
    onChange,
    onFocus,
    onMouseEnter,
    onMouseLeave,
    required,
    size,
    success,
    ...rest
  } = {...defaultProps, ...props}; // One way to set default props for ...rest
  const dataProps = makeDataProps({...rest});
  const passthroughProps = {id, ...rest, ...dataProps};
  return (
    <WithHoverAndFocus
      customOnBlur={onBlur}
      customOnFocus={onFocus}
      customOnMouseEnter={onMouseEnter}
      customOnMouseLeave={onMouseLeave}
    >
      {({hasFocus, hasHover, onBlur, onFocus, onMouseEnter, onMouseLeave}) => (
        <Label
          className={classnames('m-field', className, {
            'm-field--inline': inline
          })}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <LabelText
            hasFocus={hasFocus}
            hasHover={hasHover}
            inline={inline}
            required={required}
            success={success}
            error={error}
            size={size}
          >
            {label}
          </LabelText>
          <div className='m-field__input-wrapper'>
            {/* @ts-expect-error */}
            <WrappedComponent
              {...passthroughProps}
              aria-describedby={id && message ? `aria${id}` : null}
              aria-invalid={!!error}
              className={classnames({
                'm-field__input--focus': hasFocus,
                'm-field__input--hover': hasHover
              })}
              defaultValue={defaultValue}
              onBlur={onBlur}
              onChange={onChange}
              onFocus={onFocus}
              error={error}
              ref={ref}
              size={size}
              success={success}
            />
            {message ? (
              <Message
                aria-live='assertive'
                error={error}
                hasFocus={hasFocus}
                hasHover={hasHover}
                id={id ? `aria${id}` : undefined}
                role='alert'
                size={size}
                success={success}
              >
                {message}
              </Message>
            ) : null}
          </div>
        </Label>
      )}
    </WithHoverAndFocus>
  );
});

const defaultProps = {
  as: Input,
  onBlur: () => {},
  onFocus: () => {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  disabled: false,
  size: 'regular'
};

export default Field;
