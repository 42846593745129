import {createActions} from 'client/framework';

export default createActions(null, [
  'APPROVE_QUESTION_SET',
  'INITIALIZE_STORE',
  'REASSIGN_QUESTION_SET',
  'REJECT_QUESTION_SET',
  'RESET_STORE',
  'REVERT_TO_DRAFT',
  'SAVE_CURSOR_POSITION',
  'SAVE_QUESTION',
  'SET_IS_PREVIEW_MODE',
  'SET_SUBJECT_ID',
  'SUBMIT_FOR_REVIEW',
  'UPDATE_QUESTION_DETAILS',
  'UPDATE_QUESTION_SET_STATUS',
  'UPDATE_QUESTION_SET_TAGS',
  'UPDATE_QUESTION_STANDARDS',
  'UPDATE_QUESTION',
  'VALIDATE_QUESTION_FIELD'
]);
