import React from 'react';
import PropTypes from 'prop-types';
import {callTargetedAction} from 'client/framework';
import StaticField from 'generic/Forms/StaticField/StaticField.react';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {QuestionModelV3} from 'resources/augmented/Question/QuestionModel.v3';
import {TextField, Button, Anchor, WithTooltip} from '@albert-io/atomic';

import passageCorrectionQuestionActions from './PassageCorrectionQuestion.actions';
import PassageCorrectionPopover from './shared/PassageCorrectionPopover.react';
import {
  getRegionKeyForOptionIndex,
  isOptionIndexInteractive
} from './PassageCorrectionQuestionType.utils';

import './passage-correction-question.scss';

export default class PassageCorrectionPreSubmissionRenderer extends React.Component {
  static propTypes = {
    question: PropTypes.instanceOf(QuestionModelV3),
    onRenderWordFunc: PropTypes.func
  };

  callAction(name, payload) {
    const actionToCall = {
      name,
      targetStore: this.props.question.getStore().getName()
    };
    if (payload !== undefined) {
      actionToCall.payload = payload;
    }
    callTargetedAction(actionToCall);
  }

  handleClick = (e) => {
    const {regionIndex} = e.target.dataset;
    if (regionIndex === undefined) {
      return;
    }
    this.callAction(
      passageCorrectionQuestionActions.SET_ACTIVE_OPTION,
      parseInt(e.target.dataset.idx, 10)
    );
  };

  commitCorrectionChange = (updatedCorrection) => {
    this.callAction(passageCorrectionQuestionActions.COMMIT_CORRECTION_CHANGE, updatedCorrection);
  };

  handleCorrectionReset = () => {
    this.callAction(passageCorrectionQuestionActions.REMOVE_ACTIVE_OPTION_CORRECTION);
  };

  wordPropsFunc = (text, optionIndex) => {
    const {question} = this.props;
    const matchedRegionIndex = getRegionKeyForOptionIndex(question, optionIndex);
    const activeOption = question.getStore().getActiveOption();
    const isActive = activeOption === optionIndex;
    const correctedText = question
      .getStore()
      .getCorrections()
      .hasIn([matchedRegionIndex, optionIndex])
      ? question.getStore().getCorrections().getIn([matchedRegionIndex, optionIndex])
      : text;
    return {
      isActive,
      isRangeEnd: isActive,
      isRangeStart: isActive,
      isCorrected: question.getStore().isOptionCorrected(optionIndex),
      matchedRegionIndex,
      isSelectable: isOptionIndexInteractive(question, optionIndex),
      onRenderWordFunc: this.props.onRenderWordFunc,
      correctedText
    };
  };

  render() {
    const {question} = this.props;
    const activeIndex = question.getStore().hasActiveOption()
      ? question.getStore().getActiveOption()
      : null;
    return (
      <MarkdownRendererV2
        passageCorrection
        className='passage-correction-question__input-area'
        text={question.getUncorrectedText()}
        onClick={this.handleClick}
        wordPropsFunc={this.wordPropsFunc}
        popover={
          <PassthroughPopover
            activeIndex={activeIndex}
            key={activeIndex}
            commitChange={this.commitCorrectionChange}
            correctedValue={question.getStore().getActiveCorrectedValue()}
            onReset={this.handleCorrectionReset}
            originalText={question.getStore().getActiveOriginalValue()}
          />
        }
      />
    );
  }
}

/**
 * This is a shim to enable some accessibility features in the popover below, such as focusing the input
 * when the component mounts. `<PassageCorrectionPopover />` is a shared component and is a bit rigid in its structure,
 * so this shim was a quick way to get the functionality we needed.
 *
 * @todo: Refactor `<PassageCorrectionPopover />`
 *
 * @param {Object} props
 * @returns {React.Element}
 */
function PassthroughPopover(props) {
  return (
    <PassageCorrectionPopover {...props}>{() => <Popover {...props} />}</PassageCorrectionPopover>
  );
}

class Popover extends React.Component {
  static propTypes = {
    commitChange: PropTypes.func,
    correctedValue: PropTypes.string,
    onReset: PropTypes.func,
    originalText: PropTypes.string,
    anchorNode: PropTypes.any // DOM node
  };

  constructor(props) {
    super(props);
    this.state = {
      pendingCorrectionValue: props.correctedValue
    };
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    this.inputRef.current.focus();
  }

  componentWillUnmount() {
    this.props.anchorNode.focus();
  }

  updateCorrection = (e) => {
    this.setState({
      pendingCorrectionValue: e.target.value
    });
  };

  handleCommitChange = () => {
    if (this.state.pendingCorrectionValue.length === 0) {
      return;
    }
    this.props.commitChange(this.state.pendingCorrectionValue);
  };

  onInputEnterKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.handleCommitChange();
    }
  };

  render() {
    const isChangeButtonDisabled = this.state.pendingCorrectionValue.length === 0;
    return (
      <>
        <div className='passage-correction-popover__correction-wrapper'>
          <TextField
            border
            label='Enter correction'
            value={this.state.pendingCorrectionValue}
            onChange={this.updateCorrection}
            onKeyPress={this.onInputEnterKeyPress}
            ref={this.inputRef}
          />
          <div className='passage-correction-popover__button-wrapper u-mar-t_1'>
            <WithTooltip
              enabled={isChangeButtonDisabled}
              content='The correction may not be empty'
              placement='right'
            >
              <Button
                type='button'
                onClick={this.handleCommitChange}
                disabled={isChangeButtonDisabled}
                data-testid='passage-correction-popover__change-btn'
              >
                Change
              </Button>
            </WithTooltip>
          </div>
        </div>
        <div className='passage-correction-popover__original-text'>
          <StaticField
            label='Original text'
            value={this.props.originalText}
            labelRightContent={
              <>
                <Anchor type='button' as='button' onClick={this.props.onReset}>
                  Reset
                </Anchor>
              </>
            }
          />
        </div>
      </>
    );
  }
}
