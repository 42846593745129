import {List, Map} from 'immutable';

export class MandarkResourceInterface {
  constructor(data, versions) {
    this._data = data;
    this._versions = versions;
    this._cache = new Map();
  }

  getMainResourceAttributes() {
    return this._data.getIn(['data', 'attributes']);
  }

  getMainResourceRelationships(id) {
    const cacheKey = `mi_relationships_${id}`;
    if (this._cache.has(cacheKey)) {
      return this._cache.get(cacheKey);
    }
    const relationships = this._data
      .get('data')
      .find((datum) => datum.get('id') === id)
      .get('relationships');

    this._cache = this._cache.set(cacheKey, relationships);

    return relationships;
  }

  getIncludedResourceAttributeById(type, id) {
    const cacheKey = `mi_included_${type}_${id}`;
    if (this._cache.has(cacheKey)) {
      return this._cache.get(cacheKey);
    }
    const includes = this._data
      .get('included', new List())
      .find((datum) => datum.get('id') === id && datum.get('type') === type);

    this._cache = this._cache.set(cacheKey, includes);

    return includes;
  }

  getIncludedResourceAttributesByType(type) {
    if (!this._data.has('included')) {
      return new List();
    }

    const cacheKey = `mi_included_${type}`;
    if (this._cache.has(cacheKey)) {
      return this._cache.get(cacheKey);
    }

    const includes = this._data
      .get('included', new List())
      .filter((datum) => datum.get('type') === type);

    this._cache = this._cache.set(cacheKey, includes);

    return includes;
  }

  get versions() {
    return this._versions;
  }
}

export class MandarkResourceStatsInterface {
  constructor(data, versions) {
    this._data = data;
    this._versions = versions;
  }

  getStatsForId(id) {
    return this._data.getIn(['assignments', id], new Map());
  }

  getStatsForIdInFieldname(fieldname, id) {
    return this._data.getIn(['data', fieldname, id], new Map());
  }
}

export class RelationshipsInterface {
  constructor(data, versions) {
    this._data = data;
    this._versions = versions;
  }

  getRelationshipById(id) {
    return this._data.get('data', new List()).find((datum) => {
      return datum.get('id') === id;
    });
  }

  getRelationshipsByType(type) {
    return this._data.get('data', new List()).filter((datum) => {
      return datum.get('type') === type;
    });
  }
}
