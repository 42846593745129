// @flow
import {stats} from 'lib/StatsUtil';
import {kebabCase} from 'lodash';
import constants from 'client/constants';
import {GuideModelV1} from 'resources/augmented/Guide/GuideModel.v1';

type Location = {
  path: String,
  host: String,
  href: String
};

export const subjectExtensions = {
  isAPSubject(): Boolean {
    return this.getName().startsWith('AP');
  },
  doesUserHaveAccess(): Boolean {
    return this.getMeta().isUserHasAccess();
  },
  /**
   * Returns a `Location` object representing the location of the `logo_image_url`
   *
   * @returns {Object}
   */
  getLogoLocation(): Location {
    return {
      path: this.getLogoImageUrl(),
      /* eslint-disable no-undef */
      host: constants.USER_ASSETS_ORIGIN,
      href: `${constants.USER_ASSETS_ORIGIN}/${this.getLogoImageUrl()}`
      /* eslint-enable no-undef */
    };
  },
  /**
   * Returns the subject's domain as our URL-acceptable slug varient.
   */
  getDomainSlug(): string {
    return kebabCase(this.getDomain().toLowerCase());
  },
  /**
   * Returns the subject's group as our URL-acceptable slug varient.
   */
  getGroupSlug(): string {
    return kebabCase(this.getGroup().toLowerCase());
  },

  getCurriculumAreaSlug(): string {
    const slug = !this.getCurriculumArea().isEmpty()
      ? kebabCase(this.getCurriculumArea().getName())
      : 'default';
    return slug;
  },

  getAverageAccuracy(): number {
    return stats.percentage(this.getTotalCorrectAnswers(), this.getTotalAnswered());
  },

  getAverageAccuracyByDifficulty(difficulty: string): number {
    return stats.percentage(
      this.getCorrectAnswerCountByDifficulty(difficulty),
      this.getTotalAnsweredCountByDifficulty(difficulty)
    );
  },

  getAverageTimeByDifficulty(difficulty: string): number {
    return stats.divide(
      this.getTotalTimeElapsedByDifficulty(difficulty),
      this.getTotalAnsweredCountByDifficulty(difficulty),
      {fixed: false}
    );
  },

  getCompletionPercentageByDifficulty(difficulty: string): number {
    return stats.percentage(
      this.getTotalAnsweredCountByDifficulty(difficulty),
      this.getQuestionCountByDifficulty(difficulty)
    );
  },

  getPracticeGuide(): GuideModelV1 {
    return this.getGuides().find((guide) => guide.getGuideType() === 'practice');
  },

  getGuideTypes(): Array<GuideModelV1> {
    return this.getGuides().reduce((acc, guide) => {
      acc.push(guide.getGuideType());
      return acc;
    }, []);
  },

  getQuestionCountByDifficulty(difficulty: string): number {
    return this.getIn(['meta', `count_of_${difficulty}_questions`], 0);
  },

  getTotalAnswered(): number {
    const total =
      this.getMeta().getCountOfEasyGuesses() +
      this.getMeta().getCountOfMediumGuesses() +
      this.getMeta().getCountOfDifficultGuesses();
    return isNaN(total) ? 0 : total;
  },

  getTotalCorrectAnswers(): number {
    const total =
      this.getMeta().getCountOfEasyCorrectGuesses() +
      this.getMeta().getCountOfMediumCorrectGuesses() +
      this.getMeta().getCountOfDifficultCorrectGuesses();
    return isNaN(total) ? 0 : total;
  },

  getTotalPointsEarned(): number {
    const total =
      this.getMeta().getSumOfEasyGuessesPointsEarned() +
      this.getMeta().getSumOfMediumGuessesPointsEarned() +
      this.getMeta().getSumOfDifficultGuessesPointsEarned();
    return isNaN(total) ? 0 : total;
  },

  getTotalPointsEarnedByDifficulty(difficulty: string): number {
    return this.getIn(['meta', `sum_of_${difficulty}_guesses_points_earned`], 0);
  },

  getCorrectAnswerCountByDifficulty(difficulty: string): number {
    return this.getIn(['meta', `count_of_${difficulty}_correct_guesses`], 0);
  },

  getTotalAnsweredCountByDifficulty(difficulty: string): number {
    return this.getIn(['meta', `count_of_${difficulty}_guesses`], 0);
  },

  getTotalTimeElapsedByDifficulty(difficulty: string): number {
    return this.getIn(['meta', `sum_of_${difficulty}_guesses_time_elapsed`], 0);
  }
};
