import React, {useEffect, useState} from 'react';
import {Icon, Text, Anchor} from '@albert-io/atomic';
import classNames from 'classnames';
import {ClassroomModelV1} from 'resources/augmented/Classroom/ClassroomModel.v1';
import {List} from 'immutable';

import {getClassroomQuery, getTeacherQuery} from '../reports.utils';

interface SharedBannerProps {
  classrooms: List<ClassroomModelV1>;
  topLevelFilterId: string;
  topLevelFilterType: string;
  userId: string;
}

const SharedBanner: React.FC<SharedBannerProps> = ({
  classrooms,
  topLevelFilterId,
  topLevelFilterType,
  userId
}) => {
  const [sharedBannerResources, setSharedBannerResources] = useState<{
    classroom?: any;
    teacher?: any;
  }>({});

  useEffect(() => {
    const fetchSharedBannerResources = async () => {
      if (!topLevelFilterId) {
        setSharedBannerResources({});
      } else if (topLevelFilterType === 'classrooms') {
        const classroom = await getClassroomQuery(topLevelFilterId).getResourcePromise();
        setSharedBannerResources((prev) => ({...prev, classroom}));
      } else if (topLevelFilterType === 'teachers') {
        const teacher = await getTeacherQuery(topLevelFilterId).getResourcePromise();
        setSharedBannerResources((prev) => ({...prev, teacher}));
      }
    };

    fetchSharedBannerResources();
  }, [topLevelFilterType, topLevelFilterId]);

  const isOwnClassroom =
    topLevelFilterType === 'classrooms' &&
    classrooms.some((classroom) => classroom.id === topLevelFilterId);

  let isShared = false;
  if (
    (sharedBannerResources.classroom && !isOwnClassroom) ||
    (sharedBannerResources.teacher && topLevelFilterId !== userId)
  ) {
    isShared = true;
  }

  if (!topLevelFilterId || !isShared) {
    // if there is no topLevelFilterId then user is not viewing a report
    return null;
  }

  return (
    <div
      id='report-selector_shared-report-banner'
      data-testid='report-selector_shared-report-banner'
      className={classNames(
        'u-display_flex u-justify-content_center u-align-items_center u-border-radius_1 u-bgc_slate-100 u-color_slate-500 u-pad_1 background-brand-subtle'
      )}
    >
      <p className='u-mar-b_0 u-text-align_center'>
        <Icon className='u-mar-r_2' icon={['far', 'users']} />
        Shared report from{' '}
        <Text bold>
          {sharedBannerResources.classroom
            ? sharedBannerResources.classroom.getName()
            : `${sharedBannerResources.teacher.getDisplayName()}`}
        </Text>{' '}
        at{' '}
        <Text bold>
          {(sharedBannerResources.classroom || sharedBannerResources.teacher).getSchool().getName()}
        </Text>
        .
        <Anchor className='u-mar-l_1' to='/reports'>
          Exit shared report
        </Anchor>
      </p>
    </div>
  );
};

export default SharedBanner;
