import React from 'react';
import classNames from 'classnames';
import {Icon, Input, Text} from '@albert-io/atomic';
import {AuthoringSupplementModelV1} from 'resources/augmented/AuthoringSupplement/AuthoringSupplementModel.v1';

import {IconProp} from '@albert-io/atomic/atoms/Icon/Icon.react';

import questionEditorStore from '../QuestionEditor/QuestionEditor.store';
import {useQuestionEditorV2Store} from '../QuestionEditorV2Store';
import {TranslatedSupplement} from '../QuestionEditorV2Store.types';

import {TranslationStatusDropdown} from './TranslationStatusDropdown';
import './supplement-translation-panel.scss';

export const SupplementTranslationPanel = () => {
  const question = questionEditorStore.getQuestion();
  const currentTranslatedSupplements = useQuestionEditorV2Store((state) =>
    state.currentTranslatedSupplements()
  );

  if (currentTranslatedSupplements === null || currentTranslatedSupplements.length === 0) {
    return (
      <Text size='s'>
        Translation statuses for supplements added to this question will be displayed below.
      </Text>
    );
  }

  return (
    <div>
      {currentTranslatedSupplements.map((translatedSupplement, i) => {
        const supplement = question
          .getAuthoringSupplements()
          .find((s) => s.getId() === translatedSupplement.id);
        if (!supplement) {
          return null;
        }
        return (
          <React.Fragment key={supplement.id}>
            <SupplementTranslationStatuses
              translatedSupplement={translatedSupplement}
              supplement={supplement}
            />
            {i !== currentTranslatedSupplements.length - 1 && <hr className='u-mar-tb_2' />}
          </React.Fragment>
        );
      })}
    </div>
  );
};

interface SupplementTranslationStatusesProps {
  translatedSupplement: TranslatedSupplement;
  supplement: AuthoringSupplementModelV1;
}

const icons: Record<TranslatedSupplement['content']['type'], IconProp> = {
  passage: ['fal', 'align-left'],
  'sortable-table': ['fal', 'align-left'],
  'free-form': ['fal', 'align-left'],
  image: ['fal', 'image'],
  video: ['fal', 'image'],
  audio: ['fal', 'music']
};

const SupplementTranslationStatuses = ({
  translatedSupplement,
  supplement
}: SupplementTranslationStatusesProps) => {
  const icon = icons[translatedSupplement.content.type];

  return (
    <div>
      <Text size='m'>
        <Icon icon={icon} />
        <Text size='m' className='supplement-translation__name'>
          {supplement.getName()}
        </Text>
      </Text>

      <div className='supplement-translation__fields'>
        {translatedSupplement.required_fields.map((fieldName, i) => {
          const field = translatedSupplement.translated_fields.find((f) => f.field === fieldName);

          return (
            <div key={fieldName} className='u-display_flex'>
              <div
                className={classNames('supplement-translation__field-spacer', {
                  'supplement-translation__field-spacer--not-last':
                    i !== translatedSupplement.required_fields.length - 1
                })}
              >
                <div
                  className={classNames('supplement-translation__field-bullet', {
                    'supplement-translation__field-bullet--last':
                      i === translatedSupplement.required_fields.length - 1
                  })}
                />
              </div>
              <div className='u-display_flex u-flex-direction_column u-gap_space-x1 u-mar-t_1 u-flex-grow_1'>
                <div className='u-display_flex u-justify-content_space-between u-align-items_center'>
                  <Text size='s'>{humanReadableFieldName(fieldName)}</Text>

                  <TranslationStatusDropdown currentStatus={field?.status ?? 'draft'} />
                </div>

                <Input size='s' value={field?.notes ?? ''} placeholder='Comment...' disabled />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const humanReadableFieldName = (field: string) => {
  switch (field) {
    case 'name':
      return 'Name';
    case 'content.text':
      return 'Text';
    case 'content.caption':
      return 'Caption';
    case 'content.alttext':
      return 'Alt Text';
    default:
      return field;
  }
};
