import React from 'react';
import {Link} from 'react-router';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {StudyGuideItem} from 'client/generic/StudyGuideItem/StudyGuideItem.react';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {FreeResponseQuestionSubTypeBadge} from 'client/FreeResponseQuestion/FreeResponseQuestionSubTypeBadge/FreeResponseQuestionSubTypeBadge.react';
import {GuideLevelModelV2} from 'resources/augmented/GuideLevel/GuideLevelModel.v2';
import {getModelForResourceType} from 'resources/modelRegistry';
import ActionsDropdown from 'client/generic/StudyGuideItem/Teacher/ActionsDropdown/ActionsDropdown.react';

const FRQQuestionItem = ({
  className,
  question,
  to,
  heading = 'h1',
  questionSetIds,
  hasAssignAccess,
  guideLevel,
  questionSet,
  usePublicView
}) => {
  return (
    <StudyGuideItem
      aria-label={question.getTitle()}
      className={className}
      itemType='question'
      as={Link}
      to={{
        pathname: to
      }}
    >
      <StudyGuideItem.Content>
        <StudyGuideItem.Heading as={heading}>
          <MarkdownRendererV2 text={question.getTitle()} />
        </StudyGuideItem.Heading>
        {!usePublicView && (
          <StudyGuideItem.Meta className='frq-item__metadata'>
            <FreeResponseQuestionSubTypeBadge question={question} />
          </StudyGuideItem.Meta>
        )}
      </StudyGuideItem.Content>
      {hasAssignAccess && (
        <ActionsDropdown
          guideLevel={guideLevel}
          buttonClassName='study-guide-actions-dropdown__btn unbutton'
          className='study-guide-actions-dropdown'
          questionSetIds={questionSetIds}
          questionCount={1}
          questionSet={questionSet}
          link={to}
        />
      )}
    </StudyGuideItem>
  );
};

FRQQuestionItem.propTypes = {
  question: ImmutablePropTypes.record.isRequired,
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  heading: PropTypes.string,
  questionSetIds: PropTypes.array,
  hasAssignAccess: PropTypes.bool,
  guideLevel: PropTypes.instanceOf(GuideLevelModelV2),
  questionSet: PropTypes.instanceOf(getModelForResourceType('question_set_v1')),
  usePublicView: PropTypes.bool
};

export default FRQQuestionItem;
