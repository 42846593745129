import {useEffect} from 'react';
import {useContentDiscoveryStore} from '../index';

export const useDraftSubscription = () => {
  const store = useContentDiscoveryStore.getState();
  const {draft} = store;

  useEffect(() => {
    const unsubscribers = [
      useContentDiscoveryStore.subscribe(
        (state) => state.assignment.assignment,
        (initialAssignment) => {
          if (initialAssignment && !draft.draftName) {
            draft.setDraftName(initialAssignment.getName());
          } else if (draft.draftName) {
            draft.setDraftName(draft.draftName);
          }
        }
      ),

      useContentDiscoveryStore.subscribe(
        (state) => state.draft.draftId,
        (draftId) => {
          if (draftId) {
            draft.fetchDraftData();
          }
        }
      ),

      useContentDiscoveryStore.subscribe(
        (state) => [state.draft.draftSort, state.draft.draftAssignment],
        () => {
          draft.updateDraftSortOnQuery();
        }
      )
    ];

    return () => {
      unsubscribers.forEach((unsub) => unsub());
    };
  }, []);
};
