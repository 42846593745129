import React from 'react';
import PropTypes from 'prop-types';
import {OrderedMap, List} from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {isArray} from 'lodash';
import classnames from 'classnames';
import {getStoreByName, callTargetedAction, setUpStore} from 'client/framework';
import {memoize} from 'lib/memoizer';
import connectToStores from 'lib/decorators/connectToStores';
import makeRef from 'lib/makeRef';

import {sortDirections} from 'sg/Tables/tableConstants';
import LoadingIndicator from 'generic/LoadingIndicator.react';
import {history} from 'client/history';

import TableStore from './Table.store';
import tableActions from './Table.actions';
import './table.scss';

/**
 * Welcome to the Table component ;-)
 *
 * Basic usage:
 * - import {Table, Column} from 'sg/Tables/Table/Table.react.js';
 * - Pass your data as an array or Immutable.List to the <Table>'s data prop, and give the <Table> a storeName prop.
 * - Add child <Column> components to the table for all the data points you need.
 * - Each <Column> should have, at minimum, a `heading` prop and a `content` prop.
 * - Headings can be strings _or_ elements, if you need to fancy headings with tooltips and such.
 * - Content is a function that returns the content of that cell. Content will get passed every item in your data prop.
 * - If you want to make a column sortable, give your column a `sortable={true}` prop (or just `sortable`).
 * - If you want a column to expand to fit the rest of the available area, give your column a grow={some number}
 *   prop. This works the same as flexbox's flex-grow, so you can give multiple columns different grow numbers.
 * - If you want to pass certain props to the <Table>'s rows, give the <Table> a `rowPropsFunc` prop. This prop should
 *   be a function, which whill receive the item that is rendering the row, and should return an object of props to
 *   pass to that row.
 * - If you want your rows to act as links, use the `rowPropsFunc` along with `makeLinkRowProps` from './table.utils.js'
 *   to accomplish this. It will make it easy to make the necessary props object so that your rows act as links.
 *
 * Making use of Mandark's (or any other custom) sort:
 * - Note that you can not mix the column's `sortable` prop with `customSortHandler`
 * - Give your <Table>'s customSortHandler prop an object with the properties 'currentSortHeading' and 'sortDirection'
 * - currentSortHeading should be the same heading as one of your <Column>s. This is used to determine where
 *   to place the active sort icon.
 * - sortDirection should be either 'ASCENDING' or 'DESCENDING'. This is used to determine which way to point the active
 *   sort icon arrow. To avoid magic strings, you can import the options from 'sg/Table/tableConstants'
 * - To actually handle sorting, give your sortable <Columns> a `customSortFunc` prop. This prop is a function that will
 *   be called when the heading cell is clicked. It's also used to determine whether a column is sortable, which lets us
 *   place an icon next to all sortable headings.
 *
 * Basic Example:
 *
 * @example
 * class BasicTable extends React.Component {
 *   render() {
 *     const animals = fromJS([{
 *       name: 'Peter',
 *       species: 'porcupine',
 *       age: 4
 *     }, {
 *       name: 'Tatiana',
 *       species: 'tortoise',
 *       age: 7
 *     }, {
 *       name: 'Matt',
 *       species: 'mountain goat',
 *       age: 5
 *     }]);
 *     return (
 *       <Table
 *         data={animals}
 *         storeName='basicExampleTableStore'
 *       >
 *         <Column
 *           sortable
 *           heading='Name'
 *           content={(animal) => animal.get('name')}
 *         />
 *         <Column
 *           sortable
 *           heading='Species'
 *           content={(animal) => animal.get('species')}
 *         />
 *         <Column
 *           sortable
 *           heading='Age'
 *           content={(animal) => animal.get('age')}
 *         />
 *       </Table>
 *     );
 *   }
 * }
 */

@connectToStores()
export class Table extends React.Component {
  static propTypes = {
    // addConnectedStore is provided by Table's decorator
    addConnectedStores: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]).isRequired,
    customSortHandler: PropTypes.shape({
      currentSortHeading: PropTypes.string,
      sortDirection: PropTypes.oneOf([sortDirections.ASCENDING, sortDirections.DESCENDING])
    }),
    data: ImmutablePropTypes.list,
    maxHeight: PropTypes.string,
    storeName: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
    noResultsFoundEl: PropTypes.element,
    className: PropTypes.string,
    rowPropsFunc: PropTypes.func,
    mobileBreakpoint: PropTypes.number
  };

  constructor(props) {
    super(props);
    this.cache = memoize();
    // Outermost element in component
    this.mainWrapperNode = null;
    this.mobileClass = 'sg-table--mobile';
    // Direct parent of table element. Not the outermost element.
    this.tableWrapperNode = null;
    this.fixedHeadingsWrapperNode = null;
    this.theadNode = null;
    this.headingCellNodes = new OrderedMap();
    this.fixedHeadingCellNodes = new OrderedMap();
    this.columnSizes = new List();
    this.rowChildren = this.getChildrenByType('Row', props.children);
    this.columnChildren = this.getChildrenByType('Column', props.children);
    this.resizeObserver = null;
  }

  componentDidMount() {
    this.props.addConnectedStores(this.getStore());
    this.setTableColumnSizes();
    this.tableWrapperNode.addEventListener('scroll', this.handleTableScroll);
    if (this.props.mobileBreakpoint) {
      this.setIsMobile();
      global.addEventListener('resize', this.setIsMobile);
    }

    this.resizeObserver = new ResizeObserver(() => {
      this.sizeFixedHeader();
    });
    this.resizeObserver.observe(this.theadNode);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    this.rowChildren = this.getChildrenByType('Row', nextProps.children);
    this.columnChildren = this.getChildrenByType('Column', nextProps.children);
  }

  componentDidUpdate() {
    this.sizeFixedHeader();
  }

  componentWillUnmount() {
    this.tableWrapperNode.removeEventListener('scroll', this.handleTableScroll);
    global.removeEventListener('resize', this.setIsMobile);
    this.resizeObserver.disconnect();
  }

  getStore() {
    return setUpStore(TableStore, this.props.storeName);
  }

  getChildrenByType(type, children) {
    let childList = new List();
    React.Children.forEach(children, (child) => {
      if (child === null) {
        // no op - on purpose to account for a dynamic ROW, that may be occasionally NULL
      } else if (child.type.name === type) {
        childList = childList.push(child);
      }
    });
    return childList;
  }

  setIsMobile = () => {
    const windowWidth = global.innerWidth;
    const isMobile = windowWidth <= this.props.mobileBreakpoint;
    const action = {
      name: tableActions.SET_IS_MOBILE,
      targetStore: this.props.storeName
    };
    if (isMobile && !this.mainWrapperNode.classList.contains(this.mobileClass)) {
      action.payload = true;
    } else if (!isMobile && this.mainWrapperNode.classList.contains(this.mobileClass)) {
      action.payload = false;
    }
    if (action.hasOwnProperty('payload')) {
      callTargetedAction(action);
    }
  };

  setTableColumnSizes() {
    /**
     * In order to have consistent column widths when when someone implements a paginated table,
     * we're going to an upfront calculation of how wide each column should be based on the percentage
     * of each column's default width. We'll then spread that width out to fill the table wrapper area.
     *
     * If any columns have a grow prop, we'll distribute the remaining area, if any, based on the percentage
     * of each column's grow prop divided by the sum of all columns' grow props. This is basically the thing that
     * flex-grow does.
     */
    if (!this.columnSizes.isEmpty() || !isArray(this.props.children)) {
      return;
    }
    let columnSizes;
    const tableInitialWidth = this.theadNode.clientWidth;
    if (this.columnChildren.some((column) => column.props.grow)) {
      const tableWrapperWidth = this.tableWrapperNode.clientWidth;
      const remainingWidth = tableWrapperWidth - tableInitialWidth;
      const totalGrow = this.columnChildren.reduce((total, column) => {
        const currentGrow = column.props.grow;
        return currentGrow ? total + currentGrow : total;
      }, 0);
      columnSizes = this.headingCellNodes
        .map((node, i) => {
          const currentChild = this.props.children[i];
          if (currentChild.props.grow) {
            const additionalWidth = (currentChild.props.grow / totalGrow) * remainingWidth;
            return ((node.clientWidth + additionalWidth) / tableWrapperWidth) * 100;
          }
          return (node.clientWidth / tableWrapperWidth) * 100;
        })
        .toList();
    } else {
      columnSizes = this.headingCellNodes
        .map((node) => (node.clientWidth / tableInitialWidth) * 100)
        .toList();
    }
    this.columnSizes = columnSizes;
    this.forceUpdate();
  }

  sizeFixedHeader = () => {
    if (!process.env.IS_BROWSER || this.sizeFixedHeaderIsRunning) {
      return;
    }
    this.sizeFixedHeaderIsRunning = true;
    global.requestAnimationFrame(() => {
      this.fixedHeadingCellNodes.forEach((node, key) => {
        const headingNode = this.headingCellNodes.get(key);
        if (!node) {
          return;
        }
        node.style.minHeight = `${headingNode.clientHeight + 1}px`;
        node.style.minWidth = `${headingNode.clientWidth + 1}px`;
      });
      this.sizeFixedHeaderIsRunning = false;
    });
  };

  handleTableScroll = () => {
    global.requestAnimationFrame(() => {
      this.fixedHeadingsWrapperNode.scrollLeft = this.tableWrapperNode.scrollLeft;
    });
  };

  setSortBy({sortByFunc, heading, index}) {
    if (!sortByFunc) {
      return;
    }
    callTargetedAction({
      name: tableActions.SET_SORT_BY,
      targetStore: this.props.storeName,
      payload: {sortByFunc, sortByIndex: index, sortByHeading: heading}
    });
  }

  getSortByFunc(col, index) {
    if (this.props.data.size <= 1) {
      return null;
    }
    const {customSortFunc} = col.props;
    const sortByFunc = col.props.sortable
      ? () =>
          this.setSortBy({
            sortByFunc: col.props.sortBy || col.props.content,
            heading: col.props.heading,
            index
          })
      : null;
    if (sortByFunc && this.props.customSortHandler) {
      throw new Error(
        'Tables that have a customSortHandler prop may not contain sortable columns.'
      );
    }
    if (sortByFunc && customSortFunc) {
      throw new Error(
        'Table Columns may either have a sortable' +
          'prop or a customSortFunc prop, they can not have both.'
      );
    }
    if (customSortFunc) {
      return customSortFunc;
    }
    if (sortByFunc) {
      return sortByFunc;
    }
    return null;
  }

  getCurrentSortValues(heading, index) {
    const {customSortHandler} = this.props;
    const store = this.getStore();
    const isCurrentSort = customSortHandler
      ? customSortHandler.currentSortHeading === heading
      : store.getSortByIndex() === index;
    let sortDirection = null;
    if (isCurrentSort) {
      sortDirection = customSortHandler
        ? customSortHandler.sortDirection
        : store.getSortDirection();
    }
    return {
      isCurrentSort,
      sortDirection
    };
  }

  getHeadingData(column, i) {
    const {heading} = column.props;
    const sortByFunc = this.getSortByFunc(column, i);
    const currentSortValues = sortByFunc ? this.getCurrentSortValues(heading, i) : null;
    const sortIcon = currentSortValues ? (
      <SortIcon key={i} direction={currentSortValues.sortDirection} />
    ) : null;
    const thClass = classnames('sg-table__th', {
      'sg-table__th--sortable': sortByFunc
    });
    return {
      thClass,
      sortByFunc,
      heading,
      sortIcon
    };
  }

  renderHead({columns, isFixedHeader = false} = {}) {
    return columns.map((col, i) => {
      const {thClass, sortByFunc, heading, sortIcon} = this.getHeadingData(col, i);
      const refFunc = isFixedHeader
        ? (node) => (this.fixedHeadingCellNodes = this.fixedHeadingCellNodes.set(i, node))
        : (node) => (this.headingCellNodes = this.headingCellNodes.set(i, node));
      const elType = isFixedHeader ? 'div' : 'th';
      const headingContentWrapperClassName = classnames('sg-table__heading-content-wrapper', {
        'sg-table__heading-content-wrapper--align-center': col.props.align === 'center',
        'sg-table__heading-content-wrapper--align-right': col.props.align === 'right'
      });
      return React.createElement(
        elType,
        {
          className: thClass,
          onClick: sortByFunc,
          ref: refFunc,
          key: elType + i
        },
        <div className={headingContentWrapperClassName}>
          {heading} {sortIcon}
        </div>
      );
    });
  }

  getData() {
    const {data} = this.props;
    if (this.props.customSortHandler) {
      return data;
    }
    const store = this.getStore();
    const sortBy = store.getSortByFunc();
    const sortDirection = store.getSortDirection();

    return sortBy
      ? this.cache(`${sortBy.toString()}${sortDirection}`, () => {
          return sortDirection === sortDirections.ASCENDING
            ? data.sortBy(sortBy)
            : data.sortBy(sortBy).reverse();
        })
      : data;
  }

  makeColGroup() {
    if (this.columnSizes.isEmpty()) {
      return null;
    }
    return (
      <colgroup>
        {this.columnSizes.map((percentage, i) => {
          return <col style={{width: `${percentage}%`}} key={i} />;
        })}
      </colgroup>
    );
  }

  makeRef = makeRef.bind(this);

  render() {
    const data = this.getData();
    return (
      <div
        className={classnames('sg-table', this.props.className, {
          [this.mobileClass]: this.getStore().isMobile()
        })}
        data-ref-name='mainWrapperNode'
        ref={this.makeRef}
      >
        <div
          className='sg-table__thead sg-table__thead--fixed-headings'
          data-ref-name='fixedHeadingsWrapperNode'
          ref={this.makeRef}
        >
          <div className='sg-table__tr'>
            {this.renderHead({columns: this.columnChildren, isFixedHeader: true})}
          </div>
        </div>
        <div
          className='sg-table__table-wrapper'
          data-ref-name='tableWrapperNode'
          ref={this.makeRef}
          style={this.props.maxHeight ? {maxHeight: this.props.maxHeight} : null}
        >
          <table
            className='sg-table__table'
            style={{
              width: this.columnSizes.isEmpty() ? 0 : '100%'
            }}
          >
            {this.makeColGroup()}
            <thead className='sg-table__thead' data-ref-name='theadNode' ref={this.makeRef}>
              <tr className='sg-table__tr'>{this.renderHead({columns: this.columnChildren})}</tr>
            </thead>
            <TableBody
              data={this.rowChildren.size ? this.rowChildren.concat(data) : data}
              columns={this.columnChildren}
              isLoading={this.props.isLoading}
              noResultsFoundEl={this.props.noResultsFoundEl}
              rowPropsFunc={this.props.rowPropsFunc}
            />
          </table>
        </div>
      </div>
    );
  }
}

class TableBody extends React.PureComponent {
  static propTypes = {
    columns: ImmutablePropTypes.list,
    data: ImmutablePropTypes.list,
    isLoading: PropTypes.bool,
    noResultsFoundEl: PropTypes.element,
    rowPropsFunc: PropTypes.func
  };

  makeNoResultsFoundEl() {
    const content = this.props.noResultsFoundEl ? (
      this.props.noResultsFoundEl
    ) : (
      <NoResultsFoundEl />
    );
    return (
      <tr>
        <td colSpan={this.props.columns.size || 1}>{content}</td>
      </tr>
    );
  }

  handleLinkRowClick = (e) => {
    history.pushState(null, e.currentTarget.getAttribute('href'));
  };

  renderTableData(data) {
    return data.map((datum, i) => {
      return datum.type && datum.type.name === 'Row'
        ? this.renderRow(datum, `row-${i}`)
        : this.renderData(datum, i);
    });
  }

  getClassnameForCol(col) {
    return classnames('sg-table__td', {
      'sg-table__td--align-center': col.props.align === 'center',
      'sg-table__td--align-right': col.props.align === 'right'
    });
  }

  renderRow(row, key) {
    // row.children is just a normal array here.
    if (row.props.children.length !== this.props.columns.size) {
      logger.warn('Row.children length is not equal to column length');
    }

    const {rowPropsFunc} = this.props;
    const rowProps = rowPropsFunc ? rowPropsFunc(row) : {};

    /*
      A full row is stand-alone from the table.

      We are not rendering a row through the table's columns like we do for things in the data list.
      Rows are independent of the data list.

      Data is also not being passed into a row's content. The content should resolve to whatever it needs to
      be on its own.
    */
    return (
      <tr {...rowProps} className='sg-table__tr' key={key}>
        {React.Children.map(row.props.children, (rowCol, i) => {
          const className = this.getClassnameForCol(rowCol);
          return (
            <td className={className} key={i}>
              <div className='sg-table__td-mobile-heading'>{rowCol.props.heading}</div>
              {rowCol.props.content(row)}
            </td>
          );
        })}
      </tr>
    );
  }

  renderData(datum, key) {
    const {rowPropsFunc} = this.props;
    const rowProps = rowPropsFunc ? rowPropsFunc(datum) : {};

    return (
      <tr {...rowProps} className={classnames('sg-table__tr', rowProps.className)} key={key}>
        {this.props.columns.map((col, j) => {
          const className = this.getClassnameForCol(col);
          return (
            <td className={className} key={j}>
              {col.props.content(datum)}
            </td>
          );
        })}
      </tr>
    );
  }

  render() {
    if (this.props.isLoading) {
      return (
        <tbody>
          <tr>
            <td colSpan={this.props.columns.size}>
              <LoadingIndicator />
            </td>
          </tr>
        </tbody>
      );
    }
    const {data} = this.props;
    return (
      <tbody className='sg-table__tbody'>
        {data.isEmpty() ? this.makeNoResultsFoundEl() : this.renderTableData(data)}
      </tbody>
    );
  }
}

class SortIcon extends React.PureComponent {
  static propTypes = {
    direction: PropTypes.string
  };

  render() {
    const {direction} = this.props;
    const iconClass = classnames('sg-table-sort fa', {
      'fa-caret-down': direction === sortDirections.ASCENDING,
      'fa-caret-up': direction === sortDirections.DESCENDING,
      'fa-sort sg-table-sort--deselected': !direction
    });
    return <span className={iconClass} />;
  }
}

class NoResultsFoundEl extends React.PureComponent {
  render() {
    return <div className='sg-table__no-results-found'>No available data was found</div>;
  }
}

export class Column extends React.PureComponent {
  static propTypes = {
    // align left does nothing, but it's here for consistency
    align: PropTypes.oneOf(['left', 'center', 'right']),
    content: PropTypes.func,
    heading: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    grow: PropTypes.number,
    sortable: PropTypes.bool,
    customSortFunc: PropTypes.func,
    // sortBy lets you specify what to sort `sortable` columns by. If not provided, it defaults
    // to the same function as `content`
    sortBy: PropTypes.func
  };
}

export class Row extends React.PureComponent {}
