import React from 'react';
import {Dropdown, Button, Icon} from '@albert-io/atomic';
import {useTracking} from 'react-tracking';

import FiltersDropdown from './FiltersDropdown.react';

import {hasActiveFilter, getActiveFilterCount} from './filters.utils';

interface Props {
  active: boolean;
  disabled: boolean;
  isLoading: boolean;
  isFolder: boolean;
}

const Filters = ({disabled, active, isLoading, isFolder}: Props) => {
  const text = hasActiveFilter() ? `Filters (${getActiveFilterCount()})` : 'Filter';

  const {Track} = useTracking({
    feature: `${isFolder ? 'Folder' : 'Subject'} Practice view`,
    content: text
  });

  return (
    <Track>
      <Dropdown
        trigger={
          <Button
            size='s'
            disabled={disabled}
            color={active ? 'primary' : 'secondary'}
            variant='text'
            className='u-mar-r_1'
            data-testid='practice-view-toolbar-filters__dropdown-trigger'
          >
            <Icon icon='filter' className='u-mar-r_1' />
            {text}
          </Button>
        }
      >
        <FiltersDropdown isLoading={isLoading} isFolder={isFolder} />
      </Dropdown>
    </Track>
  );
};

export default Filters;
