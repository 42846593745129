// @flow

import {fromJS, Map} from 'immutable';
import {Store} from 'client/framework';
import BackgroundTaskActions from './BackgroundTask.actions';

import {run as excelExport} from './tasks/excel-export';
import {run as genericPromiseDownload} from './tasks/generic-promise-download';

const STATUS = {
  WAITING: 0,
  RUNNING: 1,
  FINSIHED: 2,
  ERROR: 3
};

const runnableMap = new Map({
  'excel-export': excelExport,
  'generic-promise-download': genericPromiseDownload
});

class BackgroundTaskStore extends Store {
  constructor(name: string) {
    super(name);
    this.defaultData = fromJS({
      status: STATUS.WAITING,
      task: null,
      args: {},
      process: null,
      runnable: null
    });

    this.setInitialData(this.defaultData);

    this.handle(BackgroundTaskActions.SET_TASK, this._setTask);
    this.handle(BackgroundTaskActions.SET_ARGS, this._setArgs);
    this.handle(BackgroundTaskActions.RUN, this._run);
    this.handle(BackgroundTaskActions.RESET_STORE, this._reset);
  }

  _reset() {
    this.writeData(this.defaultData);
  }

  _setStatus(status: number) {
    this.writeData('status', status);
  }

  _setTask(task: string) {
    this.writeData((state) => {
      const runnable = runnableMap.get(task);
      if (!runnable) {
        throw new Error('Background task not found');
      }
      return state.set('task', task).set('runnable', runnable);
    });
  }

  _setArgs(args: Map<string, *>) {
    this.writeData('args', args);
  }

  _setProcess(p: Promise<*>) {
    this.writeData('process', p);
  }

  _run(): ?Promise<*> {
    /**
     * For now, we only allow a single task to be run at a time.
     */
    if (this.isRunning()) {
      /**
       * Might NOT actually be what we want to do in this case...
       */
      return this.getProcess();
    }

    this._setStatus(STATUS.RUNNING);

    const p: Promise<*> = this._getRunnable()(this.getArgs())
      .then(() => {
        this._setStatus(STATUS.FINSIHED);
      })
      .catch(() => {
        this._setStatus(STATUS.ERROR);
      });

    this._setProcess(p);
    return this.getProcess();
  }

  _getRunnable(): Function {
    return this.readData('runnable');
  }

  getProcess(): ?Promise<*> {
    return this.readData('process');
  }

  getTask(): string {
    return this.readData('task');
  }

  getArgs(): Map<string, *> {
    return this.readData('args');
  }

  isRunning(): boolean {
    return this.readData('status') === STATUS.RUNNING;
  }

  isError(): boolean {
    return this.readData('status') === STATUS.ERROR;
  }
}

export default new BackgroundTaskStore('BackgroundTaskStore');
