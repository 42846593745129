import {Record} from 'immutable';

// Free Entry Option
class FreeEntryInputModel extends Record({
  id: '',
  text: ''
}) {
  getId() {
    return this.get('id');
  }

  getText() {
    return this.get('text');
  }
}

export function freeEntrySetup(data) {
  const inputs = data.get('inputs').map((input) => new FreeEntryInputModel(input));
  return data.set('inputs', inputs);
}
