import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {callTargetedAction} from 'client/framework';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {Badge, Text, Icon, Fieldset} from '@albert-io/atomic';
import {getModelForResourceType} from 'resources/modelRegistry';

import TwoWayQuestionStore from './TwoWayQuestion.store';
import twoWayQuestionActions from './TwoWayQuestion.actions';

import './two-way-v2.scss';

export default class TwoWayQuestionTable extends React.Component {
  static propTypes = {
    isGuessSubmitted: PropTypes.bool,
    showResponseStats: PropTypes.bool,
    store: PropTypes.instanceOf(TwoWayQuestionStore),
    question: PropTypes.instanceOf(getModelForResourceType('question_v3'))
  };

  static defaultProps = {
    isGuessSubmitted: false,
    showResponseStats: false
  };

  containsLatex = (question) => {
    const func = () => {
      const regex = /\$.+\$/;
      if (regex.test(question.get('header_left')) || regex.test(question.get('header_right'))) {
        return true;
      }
      return question
        .getRows()
        .some(
          (row) =>
            regex.test(row.getLeftColumn().getText()) ||
            regex.test(row.getRightColumn().getText()) ||
            regex.test(row.getStatement())
        );
    };
    return question.cache('containsLatex', func);
  };

  render() {
    const {isGuessSubmitted, question, showResponseStats, store} = this.props;
    const headerLeft = question.get('header_left');
    const headerRight = question.get('header_right');
    const hasHeader = !!(headerLeft || headerRight);
    const rows = question.getRows();
    return (
      <Fieldset>
        <table
          className={classnames('twq-table', {
            'twq-table--full-width': this.containsLatex(question)
          })}
        >
          {hasHeader && (
            <thead>
              <tr>
                <th className='twq-table__heading' />
                <th>
                  <MarkdownRendererV2
                    text={question.get('header_left')}
                    className='twq-table__heading'
                  />
                </th>
                <th>
                  <MarkdownRendererV2
                    text={question.get('header_right')}
                    className='twq-table__heading'
                  />
                </th>
              </tr>
            </thead>
          )}
          <tbody>
            {rows.map((row, i) => (
              <Row
                key={i}
                row={row}
                store={store}
                question={question}
                isGuessSubmitted={isGuessSubmitted}
                showResponseStats={showResponseStats}
              />
            ))}
          </tbody>
        </table>
      </Fieldset>
    );
  }
}

class Row extends React.Component {
  static propTypes = {
    store: PropTypes.instanceOf(TwoWayQuestionStore),
    question: PropTypes.instanceOf(getModelForResourceType('question_v3')),
    row: PropTypes.object,
    isGuessSubmitted: PropTypes.bool
  };

  handleCheck = (e) => {
    if (e.type === 'keypress' && e.key !== 'Enter') {
      return;
    }
    callTargetedAction({
      name: twoWayQuestionActions.SELECT_COLUMN,
      payload: e.currentTarget.dataset.id,
      targetStore: this.props.store.getName()
    });
    if (e.type === 'click') {
      e.currentTarget.blur();
    }
  };

  generateIndicator = (isGuessSubmitted, isSelected, isCorrectAnswer, isMissedAnswer) => {
    if (!isGuessSubmitted || (!isSelected && !isCorrectAnswer && !isMissedAnswer)) {
      return null;
    }
    return (
      <div className='twq-table-row__correctness-indicator'>
        {isCorrectAnswer && (
          <Badge
            emphasis='bold'
            size='small'
            className='twq-table-row__correctness-indicator-correct'
          >
            <Text color='primary-inverse' size='xs'>
              Correct
              <Icon
                className='u-mar-l_1 twq-table-row__correctness-indicator_icon'
                icon={['fas', 'thumbs-up']}
              />
            </Text>
          </Badge>
        )}
        {!isCorrectAnswer && !isMissedAnswer && (
          <Badge emphasis='bold' size='small' status='negative'>
            Incorrect
            <Icon
              className='u-mar-l_1 twq-table-row__correctness-indicator_icon'
              icon={['far', 'xmark']}
            />
          </Badge>
        )}
        {isMissedAnswer && (
          <Badge emphasis='bold' size='small' status='negative'>
            Missed
            <Icon
              className='u-mar-l_1 twq-table-row__correctness-indicator_icon'
              icon={['far', 'xmark']}
            />
          </Badge>
        )}
      </div>
    );
  };

  render() {
    const {question, row, store, isGuessSubmitted} = this.props;
    return (
      <tr className='twq-table-row'>
        <td className='twq-table-row__cell'>
          <MarkdownRendererV2 text={row.getStatement()} />
        </td>
        {['Left', 'Right'].map((side) => {
          const option = row[`get${side}Column`]();
          const optionId = option.getId();
          const isChecked = store.isSelectedColumn(optionId);
          let postSubmitClassNames;
          let isCorrectSelection;
          const rubric = isGuessSubmitted ? question.getMeta().getQuestionRubric() : null;
          const validResponse = rubric ? rubric.get('validResponse') : null;
          const isCorrectOption = validResponse ? validResponse.has(optionId) && isChecked : null;
          const isMissedOption = validResponse ? validResponse.has(optionId) && !isChecked : null;
          if (isGuessSubmitted) {
            isCorrectSelection = isCorrectOption ? isChecked : !isChecked;
            postSubmitClassNames = {
              'twq-table-row__cell--submitted': true,
              'twq-table-row__cell--correct-selection': isCorrectOption,
              'twq-table-row__cell--missed-selection': isMissedOption,
              'twq-table-row__cell--incorrect-selection': !isCorrectSelection
            };
          }
          // If option's contents is empty, or consists only of whitespace, render a pseudo-checkbox
          const cellContents = /^\s*$/.test(option.getText()) ? (
            <div
              className={classnames('twq-table-row__checkmark', {
                'twq-table-row__checkmark--checked': isChecked
              })}
            />
          ) : (
            <MarkdownRendererV2 text={option.getText()} className='twq-table-row__input-text' />
          );
          return (
            <td
              key={side}
              className={classnames(
                'twq-table-row__cell',
                'twq-table-row__cell-option',
                {
                  'twq-table-row__cell-option--checked': isChecked,
                  'twq-table-row__cell-option--unchecked': !isChecked
                },
                postSubmitClassNames
              )}
              onClick={isGuessSubmitted ? null : this.handleCheck}
              onKeyPress={isGuessSubmitted ? null : this.handleCheck}
              data-id={optionId}
              role={isGuessSubmitted ? '' : 'checkbox'}
              aria-checked={isGuessSubmitted ? '' : isChecked}
              tabIndex={isGuessSubmitted ? null : '0'}
            >
              {cellContents}
              {this.generateIndicator(isGuessSubmitted, isChecked, isCorrectOption, isMissedOption)}
            </td>
          );
        })}
      </tr>
    );
  }
}
