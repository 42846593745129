// @flow
import {Map} from 'immutable';
import {Store} from 'client/framework';
import {drawerActions} from './DrawerActions';

export default class DrawerStore extends Store {
  constructor(name: string) {
    super(name);
    this.setInitialData(
      Map({
        isOpen: false
      })
    );
    this.handleTargeted(drawerActions.DRAWER_SET_DRAWER_VISIBILITY, this._setVisibility);
    this.handleTargeted(drawerActions.DRAWER_RESET_STORE, this.resetStore);
  }

  _setVisibility(isOpen: boolean) {
    this.writeData('isOpen', isOpen);
  }

  isOpen(): boolean {
    return this.readData('isOpen');
  }
}
