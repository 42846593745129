import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import LoadingIndicator from 'generic/LoadingIndicator.react';
import {ClassroomModelV1} from 'resources/GeneratedModels/Classroom/ClassroomModel.v1';
import {Map} from 'immutable';
import SubjectCourseCard from 'generic/SubjectMenuRenderer/SubjectCourseCard/SubjectCourseCard.react';
import {Text, Button, Heading, WithTooltip} from '@albert-io/atomic';
import masqueradeStore from 'generic/Masquerade/Masquerade.store';

import './content-discovery-results.scss';

const ContentDiscoveryResults = ({
  surveyResults,
  allCourses,
  makeSubjectCardHref,
  onEditButtonClick,
  isLoading,
  emptyState,
  isUserLicensedTeacher,
  activeClassrooms,
  searchString
}) => {
  const createCourseCards = (ids) => {
    const subjects = ids.reduce((acc, id) => {
      const subject = allCourses.toArray().find((course) => course.getId() === id);
      if (!subject) {
        return acc;
      }
      acc.push(subject);
      return acc;
    }, []);

    const sortedSubjects = subjects.sort((subjectA, subjectB) =>
      subjectA.getName() < subjectB.getName() ? -1 : 1
    );

    return sortedSubjects.map((subject) => (
      <SubjectCourseCard
        key={subject.getId()}
        subject={subject}
        to={makeSubjectCardHref(subject)}
        content={subject.getDescription()}
        className='content-discovery-results__course'
        isAssessmentsOnly={subject
          .getGuides()
          .every((guide) => guide.getGuideType() === 'assessment')}
        isUserLicensedTeacher={isUserLicensedTeacher}
        activeClassrooms={activeClassrooms}
      />
    ));
  };

  /**
   *  Loops through the results and finds any content mappings that have the same subject id list
   *  Then creates a new Map and stores a list of duplicate grade levels alongside the subject Id List
   */
  const combineDuplicates = () => {
    let condensedResults = surveyResults;
    let newResults = Map();
    surveyResults.entrySeq().forEach(([outerMapping, outerArray]) => {
      const duplicateGrades = [outerMapping.split('->')[1]];
      const outerNeed = outerMapping.split('->')[2];
      condensedResults.entrySeq().forEach(([innerMapping, innerArray]) => {
        const innerNeed = innerMapping.split('->')[2];
        // if subject lists are the same and the outer mapping hasn't already been removed
        if (
          outerMapping !== innerMapping &&
          outerArray.equals(innerArray) &&
          condensedResults.has(outerMapping) &&
          outerNeed === innerNeed
        ) {
          duplicateGrades.push(innerMapping.split('->')[1]);
          condensedResults = condensedResults.delete(innerMapping);
        }
      });
      if (condensedResults.has(outerMapping)) {
        newResults = newResults.set(outerMapping, {
          idArray: outerArray,
          duplicateGrades
        });
      }
    });
    return newResults;
  };

  /**
   * Custom sort function for grade level strings
   *
   * @param gradeA
   * @param gradeB
   */
  const sortGradeLevels = (gradeA, gradeB) => {
    const intA = parseInt(gradeA, 10);
    const intB = parseInt(gradeB, 10);
    if (intA < intB) {
      return -1;
    }
    return 1;
  };

  const createSections = () => {
    const removedDuplicatesResults = combineDuplicates();
    return removedDuplicatesResults.entrySeq().reduce((acc, [mapping, obj], i) => {
      const {idArray, duplicateGrades} = obj;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [subject, grade, need] = mapping.split('->');
      const duplicateGradesString = `${duplicateGrades.sort(sortGradeLevels).join(', ')}`;
      const courseCards = createCourseCards(idArray);
      if (courseCards.length === 0) {
        return acc;
      }
      acc.push(
        <div key={i} className='content-discovery__results-container'>
          <Text bold size='xl' color='secondary'>
            {need}
          </Text>
          <Text as='div' color='tertiary' className='u-mar-t_1 u-mar-b_4'>
            {subject} » {duplicateGradesString}
          </Text>
          <div className='content-discovery__course-cards-container'>{courseCards}</div>
        </div>
      );
      return acc;
    }, []);
  };

  const sections = createSections();

  return isLoading ? (
    <LoadingIndicator />
  ) : (
    <div className='content-discovery__results'>
      <div className='content-discovery__results-header'>
        <Heading className='u-mar_0' as='h2'>
          {searchString ? `Results for "${searchString}"` : 'Discover'}
        </Heading>
        {!searchString && (
          <WithTooltip
            enabled={masqueradeStore.isMasquerading()}
            content='You cannot retake the Discover survey for another user.'
          >
            <Button
              disabled={masqueradeStore.isMasquerading()}
              onClick={onEditButtonClick}
              className='u-mar-l_2'
              size='s'
              data-testid='content-discovery-results__retake-survey-btn'
            >
              Retake survey
            </Button>
          </WithTooltip>
        )}
      </div>
      {sections.length !== 0 ? sections : emptyState}
    </div>
  );
};

ContentDiscoveryResults.propTypes = {
  surveyResults: ImmutablePropTypes.map,
  allCourses: ImmutablePropTypes.orderedSet,
  makeSubjectCardHref: PropTypes.func,
  onEditButtonClick: PropTypes.func,
  isLoading: PropTypes.bool,
  emptyState: PropTypes.node,
  isUserLicensedTeacher: PropTypes.bool,
  activeClassrooms: ImmutablePropTypes.listOf(PropTypes.instanceOf(ClassroomModelV1)),
  searchString: PropTypes.string
};

export default ContentDiscoveryResults;
