import React, {useCallback, useEffect, useState} from 'react';
import {List} from 'immutable';
import {setUpStore, callTargetedAction} from 'client/framework';
import searchDropdownActions from 'sg/Dropdowns/SearchDropdown/SearchDropdown.actions';
import SearchDropdown from 'sg/Dropdowns/SearchDropdown/SearchDropdown.react';
import SearchDropdownStore from 'sg/Dropdowns/SearchDropdown/SearchDropdown.store';
import {mandarkEndpoint} from '@albert-io/json-api-framework/request/builder/legacy';
import sessionStore from 'client/Session/SessionStore';
import {TagModelV1} from 'resources/GeneratedModels/Tag/TagModel.v1';
import {
  CheckboxChip,
  Button,
  Text,
  IconButton,
  SingleSearchAndSelect,
  Dropdown,
  DropdownItem
} from '@albert-io/atomic';

import './question-tag-editor.scss';
import FeatureFlag from 'client/components/FeatureFlag/FeatureFlag.react';

import {QuestionEditorIcon} from '../../QuestionEditorIcon/QuestionEditorIcon';

interface Props {
  tags: List<any>;
  onTagClick: (tag: any, remove: boolean) => void;
}

const QuestionTagEditor = ({tags, onTagClick}: Props) => {
  return (
    <FeatureFlag name='question_editor_v2'>
      <QuestionTagEditorV2 tags={tags} onTagClick={onTagClick} />

      <FeatureFlag.Fallback>
        <QuestionTagEditorV1 tags={tags} onTagClick={onTagClick} />
      </FeatureFlag.Fallback>
    </FeatureFlag>
  );
};

const QuestionTagEditorV2 = ({tags, onTagClick}: Props) => {
  return (
    <div className='u-display_flex u-flex-direction_column u-gap_space-x1'>
      <div className='u-display_flex u-justify-content_space-between u-align-items_center'>
        <div className='u-display_flex u-gap_space-x4 u-align-items_center'>
          <QuestionEditorIcon icon={['fal', 'tags']} />
          <Text bold>Tags</Text>
        </div>

        <div className='u-display_flex u-align-items_center u-gap_space-x1 u-width_50pc'>
          <SingleSearchAndSelect
            className='u-width_100pc'
            query={mandarkEndpoint(['tags_v1']).withMeta('tag_v1').pageSize(500)}
            inputType='text'
            placeholder='Search to add tag'
            content={(tag) => tag.getName()}
            searchFilter={(query, searchTerm) => {
              if (!searchTerm) {
                return null;
              }
              return query
                .customQuery({
                  meta: {
                    context: {
                      search_term: searchTerm
                    }
                  }
                })
                .sort('-meta.is_exact_match,name')
                .filter({
                  name: {
                    case_insensitive_substring: searchTerm
                  }
                });
            }}
            replaceOnSelect={false}
            clearOnSelect
            closeOnSelect
            selectedItems={tags}
            onSelect={(tag) => {
              if (tag) {
                onTagClick(tag, false);
              }
            }}
            customFooterRenderer={({searchTerm}) => {
              return <AddNewTagButton searchTerm={searchTerm} />;
            }}
          />
          {tags.size > 0 && (
            <Dropdown
              position='bottom-end'
              trigger={<IconButton icon='ellipsis' label='Remove all tags' />}
            >
              <DropdownItem
                color='red'
                onClick={() => tags.forEach((tag) => onTagClick(tag, true))}
              >
                Remove all tags
              </DropdownItem>
            </Dropdown>
          )}
        </div>
      </div>

      {tags.size > 0 && (
        <div className='u-mar-l_7'>
          {tags.map((t) => (
            <TagChip tag={t} onTagClick={onTagClick} key={t.getId()} />
          ))}
        </div>
      )}
    </div>
  );
};

const AddNewTagButton = ({searchTerm}: {searchTerm: string}) => {
  const [showCreateTagButton, setShowCreateTagButton] = useState(false);

  useEffect(() => {
    (async () => {
      setShowCreateTagButton(false);
      if (searchTerm && searchTerm.length > 0) {
        const tags = await mandarkEndpoint(['tags_v1'])
          .filter({
            name: {
              exact: searchTerm
            }
          })
          .getResourcePromise();

        if (tags.size === 1) {
          setShowCreateTagButton(false);
        } else {
          setShowCreateTagButton(true);
        }
      }
    })();
  }, [searchTerm, setShowCreateTagButton]);

  if (!showCreateTagButton) {
    return null;
  }

  return (
    <div className='u-display_flex u-justify-content_space-around u-mar_1'>
      <Button>Create New Tag: {searchTerm}</Button>
    </div>
  );
};

const TagChip = ({
  tag,
  onTagClick
}: {
  tag: any;
  onTagClick: (tag: any, remove: boolean) => void;
}) => {
  return (
    <CheckboxChip
      color='brand'
      onChange={() => {
        onTagClick(tag, true);
      }}
      defaultChecked
      icon='times'
    >
      {tag.getName()}
    </CheckboxChip>
  );
};

const QuestionTagEditorV1 = ({tags, onTagClick}: Props) => {
  const getStore = useCallback(() => {
    return setUpStore(SearchDropdownStore, 'QuestionTagEditorSearchStore');
  }, []);

  const getQueries = useCallback(() => {
    const searchString = getStore().getSearchString();
    return {
      exactMatchQuery: mandarkEndpoint(['tags_v1']).filter({
        name: {
          exact: searchString
        }
      }),
      searchQuery: mandarkEndpoint(['tags_v1'])
        .filter({
          name: {
            case_insensitive_substring: searchString
          }
        })
        .pageSize(500)
        .sort('name')
    };
  }, [getStore]);

  const areQueriesReady = useCallback(() => {
    return getStore().getSearchString()
      ? Object.values(getQueries()).every((query) => query.isResourcePopulated())
      : true;
  }, [getStore, getQueries]);

  const getOptions = useCallback(() => {
    const searchString = getStore().getSearchString();
    if (!searchString || !areQueriesReady()) {
      return null;
    }
    const {searchQuery, exactMatchQuery} = getQueries();
    const searchResults = searchQuery.getResource();
    return exactMatchQuery
      .getResource()
      .concat(searchResults.filterNot((tag) => tag.getName() === searchString));
  }, [getStore, areQueriesReady, getQueries]);

  const areResultsLoading = useCallback(() => {
    return getStore().getSearchString() !== '' && !areQueriesReady();
  }, [getStore, areQueriesReady]);

  const handleTagClick = useCallback(
    (tag, remove) => {
      onTagClick(tag, remove);
      callTargetedAction({
        name: searchDropdownActions.RESET_STORE,
        targetStore: getStore().getName()
      });
    },
    [onTagClick, getStore]
  );

  const handleCreateNewTag = useCallback(async () => {
    const tag = await new TagModelV1({
      name: getStore().getSearchString()
    }).save();

    handleTagClick(tag, false);
  }, [getStore, handleTagClick]);

  const isCreatingNewTag = useCallback(() => {
    return areQueriesReady() && getQueries().exactMatchQuery.getResource().isEmpty();
  }, [areQueriesReady, getQueries]);

  const createTagButton = (
    <Button size='s' onClick={handleCreateNewTag} disabled={!isCreatingNewTag()}>
      Create new tag: {getStore().getSearchString()}
    </Button>
  );

  return (
    <div className='question-tag-editor'>
      <SearchDropdown
        label='Tags'
        storeName={getStore().getName()}
        options={getOptions()}
        displayName='name'
        handleOptionChange={handleTagClick}
        areResultsLoading={areResultsLoading()}
        placeholder='Search by name'
        bottomMessage={sessionStore.isSuper() && createTagButton}
        emptyResultsComponent={
          sessionStore.isSuper() ? createTagButton : <Text>No results found</Text>
        }
      />
      <div className='question-tag-editor__tag-list'>
        {tags.map((tag) => (
          <CheckboxChip
            defaultChecked
            icon='tag'
            key={tag.get('id')}
            onChange={(e) => handleTagClick(tag, !e.target.checked)}
          >
            {tag.getName()}
          </CheckboxChip>
        ))}
      </div>
    </div>
  );
};

export default QuestionTagEditor;
