import {resource} from '@albert-io/json-api-framework/request/builder';
import {genericMandarkRequest} from 'resources/mandark.resource';
import masqueradeStore from 'generic/Masquerade/Masquerade.store';

// Caution: because of how long it takes (about 3 seconds) this query is shared between FreePracticeBanner.react.js and SubjectMenuRenderer.react.js
export function getActiveClassroomsWithSubjectsQuery() {
  return resource('classrooms_v1')
    .mandarkEndpoint(['teachers_v1', masqueradeStore.getUserIdByMasqueradeState(), 'classrooms_v1'])
    .sort('name')
    .skipValidation({sort: true}) // TODO: Remove this and properly mock allowsSorts in SubjectCourseCard.component.test
    .include('assignments_v3')
    .include('subjects_v2')
    .customQuery({
      meta: {
        context: {
          classroom: {
            counts: true,
            licensed_subjects: true
          }
        }
      }
    })
    .withMeta('classroom_v1')
    .filter({status: 'active'})
    .pageSize(250);
}

export function getClassroomWithSubjectsQuery(classroomId) {
  return resource('classrooms_v1')
    .mandarkEndpoint(['classrooms_v1', classroomId])
    .include('subjects_v2')
    .filter({status: 'active'})
    .pageSize(250);
}

export function addSubjectToClassroomQuery(classroom, subjectId) {
  return classroom
    .addRelationship({
      relation: [subjectId],
      type: 'subjects_v2'
    })
    .save();
}

export function setFreePracticeRestrictionForClassroom(classroomId, subjectId, restricted) {
  return genericMandarkRequest(
    'post',
    {resourcePath: ['classrooms_v1', classroomId, 'relationships', 'subjects_v2']},
    {
      data: [
        {
          id: subjectId,
          meta: {restrict_free_practice: restricted},
          type: 'subject_v2'
        }
      ]
    }
  );
}
