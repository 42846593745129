import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {List, Map} from 'immutable';
import {debounce} from 'lodash';
import makeConstants from 'lib/makeConstants';
import {pushQueryParams, removeQueryParams, getQueryParamsAsObject} from 'client/history';
import {queryBuilderPropType} from '@albert-io/json-api-framework/request/builder';

import Pagination from '../../Pagination/Pagination.react';
import IconButton from '../../../molecules/IconButton/IconButton.react';
import SearchInput from '../../../molecules/SearchInput/SearchInput.react';
import Table from '../Table.react';
import {sortOptions} from '../Header/Header.react';

import './mandark-table.scss';

const alignOptions = makeConstants('left', 'right');
export const MandarkTableContext = React.createContext();
const sortPrefix = {
  [sortOptions.ASCENDING]: '',
  [sortOptions.DESCENDING]: '-'
};

class MandarkTableProvider extends React.Component {
  static propTypes = {
    defaultSortDirection: PropTypes.string,
    query: queryBuilderPropType,
    searchFilters: PropTypes.func,
    sortByParam: PropTypes.string,
    children: PropTypes.node,
    persistSearch: PropTypes.bool,
    persistPage: PropTypes.bool,
    dataMutator: PropTypes.func
  };

  static defaultProps = {
    defaultSortDirection: sortOptions.ASCENDING,
    sortByParam: ''
  };

  constructor(props) {
    super(props);

    this.dataHashCode = null;

    const searchParams = getQueryParamsAsObject();

    this.state = {
      /* eslint-disable react/no-unused-state */
      isReady: false,
      error: null,
      data: List(),
      hasMounted: false,
      pageNumber: searchParams.page || 1,
      totalPages: 0,
      searchTerm: searchParams.search || '',
      setPage: this.setPage,
      resetSearchTerm: this.resetSearchTerm,
      setSearchTerm: this.setSearchTerm,
      setCustomSort: this.setCustomSort,
      sortByParam: props.sortByParam,
      sortDirection: sortOptions[props.defaultSortDirection] || sortOptions.ASCENDING
      /* eslint-enable react/no-unused-state */
    };
  }

  async componentDidMount() {
    await this.fetchData();
    this.setState({hasMounted: true});
  }

  componentDidUpdate() {
    const dataHashCode = this.makeQuery(this.props.query).getResource().hashCode();

    if (this.state.pageNumber > this.state.totalPages && this.state.totalPages > 0) {
      this.setPage(this.state.totalPages);
    }

    if (this.dataHashCode !== dataHashCode) {
      this.dataHashCode = dataHashCode;
      this.fetchData();
    }
  }

  setCustomSort = ({sortByParam, sortDirection}) => {
    if (sortByParam) {
      this.setState({sortByParam});
    }

    if (sortDirection) {
      this.setState({sortDirection});
    }
  };

  setPage = (pageNumber) => {
    if (this.props.persistPage) {
      pushQueryParams({page: pageNumber});
    }

    if (this.props.persistPage && !pageNumber) {
      removeQueryParams('page');
    }

    this.setState({pageNumber});
  };

  setSearchTerm = debounce((e, searchTerm) => {
    if (this.props.persistSearch && searchTerm) {
      pushQueryParams({search: searchTerm});
    } else if (this.props.persistSearch && !searchTerm) {
      removeQueryParams('search');
    }

    this.setState({
      searchTerm,
      pageNumber: 1
    });
  }, 500);

  resetSearchTerm = () => {
    removeQueryParams('search');
    this.setState({searchTerm: ''});
  };

  async fetchData() {
    let data = List();
    let metaData = Map();
    let error;

    if (this.state.hasMounted) {
      /* eslint-disable-next-line react/no-unused-state */
      this.setState({isReady: false});
    }

    try {
      data = await this.makeQuery(this.props.query).getResourcePromise();
      data = this.props.dataMutator ? this.props.dataMutator(data) : data;
      metaData = this.makeQuery(this.props.query).getResourceMetadata();
    } catch (err) {
      error = err;
    }

    const totalPages = metaData.getIn(['page', 'total_pages'], 0);
    this.setState(() => {
      return totalPages ? {data, totalPages, error, isReady: true} : {data, error, isReady: true};
    });
  }

  makeQuery(query) {
    const prefix = sortPrefix[this.state.sortDirection];
    const builtSortParam = prefix + this.state.sortByParam;

    const builtQuery = query
      .sort(builtSortParam, !!this.state.sortByParam)
      .page(this.state.pageNumber);

    if (this.state.searchTerm) {
      return this.props.searchFilters(builtQuery, this.state.searchTerm);
    }

    return builtQuery;
  }

  render() {
    return (
      <MandarkTableContext.Provider value={this.state}>
        {this.props.children}
      </MandarkTableContext.Provider>
    );
  }
}

export default class MandarkTable extends React.Component {
  static propTypes = {
    query: queryBuilderPropType.isRequired,
    searchFilters: PropTypes.func,
    searchPlaceholder: PropTypes.string,
    customEmptyTable: PropTypes.element,
    defaultSortDirection: PropTypes.string,
    buttonAlign: PropTypes.oneOf(Object.values(alignOptions)),
    buttonGroup: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    electiveMobileSearch: PropTypes.bool,
    inputAlign: PropTypes.oneOf(Object.values(alignOptions)),
    inputGroup: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    title: PropTypes.string,
    children: PropTypes.node,
    persistSearch: PropTypes.bool,
    persistPage: PropTypes.bool,
    dataMutator: PropTypes.func
  };

  constructor() {
    super();

    this.state = {
      showMobileSearch: false
    };
  }

  static Column = Table.Column;

  static EmptyTable = Table.EmptyTable;

  render() {
    const {
      children,
      customEmptyTable,
      defaultSortDirection,
      title,
      buttonAlign,
      buttonGroup,
      inputAlign,
      inputGroup,
      electiveMobileSearch,
      searchFilters,
      query,
      persistSearch,
      persistPage,
      dataMutator,
      ...rest
    } = this.props;

    let defaultSortColumn = children.find((child) => child.props.defaultSortParam);
    if (!defaultSortColumn) {
      defaultSortColumn = children.find((child) => child.props.sortBy);
    }
    // allow column level overrides for defaultSortDirection
    const sortDirection =
      defaultSortColumn?.props?.defaultColumnSortDirection || defaultSortDirection;
    const showSearch = electiveMobileSearch ? this.state.showMobileSearch : true;
    return (
      <MandarkTableProvider
        defaultSortDirection={sortDirection}
        query={query}
        searchFilters={searchFilters}
        sortByParam={defaultSortColumn ? defaultSortColumn.props.sortBy : ''}
        persistSearch={persistSearch}
        persistPage={persistPage}
        dataMutator={dataMutator}
      >
        <MandarkTableContext.Consumer>
          {(context) => {
            const emptyTableComponent = customEmptyTable || (
              <Table.EmptyTable header={context.searchTerm ? 'No results found' : null} />
            );

            const isLoading = !context.hasMounted && !context.isReady;
            const isReloading = context.hasMounted && !context.isReady;
            return (
              <div>
                <Table.TitleBar
                  title={title}
                  inputAlign={inputAlign}
                  inputGroup={
                    <>
                      {inputGroup}
                      {searchFilters && (
                        <SearchInput
                          className={classnames({
                            'o-mandark-table__search--hidden': !showSearch
                          })}
                          placeholder={this.props.searchPlaceholder}
                          defaultValue={context.searchTerm}
                          onChange={context.setSearchTerm}
                          onReset={context.resetSearchTerm}
                        />
                      )}
                    </>
                  }
                  buttonGroup={
                    electiveMobileSearch
                      ? [
                          ...buttonGroup,
                          <IconButton
                            className='o-mandark-table__mobile-search-toggle u-bgc_slate-100'
                            disabled={!electiveMobileSearch}
                            icon='search'
                            onClick={() => {
                              this.setState(({showMobileSearch}) => ({
                                showMobileSearch: !showMobileSearch
                              }));
                            }}
                            size='xl'
                          />
                        ]
                      : buttonGroup
                  }
                  buttonAlign={buttonAlign}
                />
                <Table
                  data={context.data}
                  isLoading={isLoading}
                  isReloading={isReloading}
                  customSortFunc={context.setCustomSort}
                  customEmptyTable={emptyTableComponent}
                  defaultSortDirection={defaultSortDirection}
                  tableFooter={
                    <Pagination
                      align='right'
                      totalPages={context.totalPages}
                      defaultPage={
                        context.pageNumber > context.totalPages
                          ? context.totalPages
                          : context.pageNumber
                      }
                      position='top'
                      onChange={context.setPage}
                    />
                  }
                  {...rest}
                >
                  {children}
                </Table>
              </div>
            );
          }}
        </MandarkTableContext.Consumer>
      </MandarkTableProvider>
    );
  }
}
