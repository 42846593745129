import {OrderedMap} from 'immutable';

// eslint-disable-next-line import/prefer-default-export
export const courseLibraryStructure = OrderedMap({
  'Middle School': OrderedMap({
    'ACT®/SAT®': OrderedMap({
      'PSAT®': OrderedMap({
        'PSAT® 8/9 Math (Digital)': '5c6cd02b-a436-4a6d-a92a-27d99f49b1b1',
        'PSAT® 8/9 Reading and Writing': '236b93a4-0b11-4a48-aedd-e656b2b25ecf'
      })
    }),
    ELA: OrderedMap({
      'ACT®/SAT®': OrderedMap({
        'PSAT® 8/9 Reading and Writing (Digital)': '236b93a4-0b11-4a48-aedd-e656b2b25ecf'
      }),
      'Literacy Skills': OrderedMap({
        Grammar: 'e4da33ba-1209-41c1-9910-48c82477aee5',
        Vocabulary: 'f5743ae3-d926-4cdf-aa50-74dc75ef089b',
        'Spelling Bee': 'a97e5f95-c012-4128-9a1a-f0a193469fc8',
        'Reading Skills': '063f6c1c-fcfc-4fe9-9c16-38276c305d4b',
        'Digital and Media Literacy': 'd375499a-407e-4786-8d71-d6af585a772c'
      }),
      'Informational Texts': OrderedMap({
        'Leveled Readings': '22a8d72f-fc16-40ce-9bcc-6d1113f9dc34',
        'Primary Sources': '62e39be0-dfb2-4676-af38-e8383c6f9b03'
      }),
      'Literary Texts': OrderedMap({
        Poetry: '6a9c9fa8-c2f3-4571-bb80-32b540e950d0',
        'Novels and Drama': '2fa901f7-e727-4086-ae0f-98faa5a6403a',
        'Short Stories': '111fdd83-a182-425d-849c-b001f741af0c'
      }),
      'State Exams': OrderedMap({
        'Grade 6 FAST ELA Reading & BEST Writing': 'a105c852-028c-46fb-866c-ab85248376c0',
        'Grade 7 FAST ELA Reading & BEST Writing': '98358657-669d-497f-9d80-4bab11c50dfe',
        'Grade 8 FAST ELA Reading & BEST Writing': 'ebe2acdb-6ee4-451e-a4d3-bfb48d2fc3fd',
        'Grades 6-7 TELPAS': '05b9528c-4707-4556-8ef8-6de0c8c8d901',
        'Grades 8-9 TELPAS': 'd10251ca-d8cf-4067-8f5d-abc5283bad9d',
        'LEAP 2025 Grade 6 ELA': 'b62bea9b-0f5b-4878-adad-b74142c055f5',
        'LEAP 2025 Grade 7 ELA': 'af09bd07-9366-4bbc-8c90-ab4cf7b8c9ee',
        'LEAP 2025 Grade 8 ELA': 'ddecee44-c054-4376-b303-d55048946bae',
        'NJSLA Grade 6 ELA': '9b217ce7-c7f5-4107-b9dd-24d7f26a73c8',
        'NJSLA Grade 7 ELA': '15e7fcc3-c907-4d03-a0e2-315dd2d28a31',
        'NJSLA Grade 8 ELA': 'b8f9d791-c38d-47df-a3ee-a9fea3c19953',
        'NY State Test Grade 6 ELA': '933cd6ed-b83e-4557-a4ab-1d494002661b',
        'NY State Test Grade 7 ELA': 'a3a40c71-26fc-49b6-bb3b-cae364eda9d1',
        'NY State Test Grade 8 ELA': '2533ccea-c288-48cf-86cc-66b024b39f3b',
        'STAAR Grade 6 Reading Language Arts (RLA)': '594c1bc6-7f55-47d5-aa72-c840ff0fcf13',
        'STAAR Grade 7 Reading Language Arts (RLA)': '1debc729-67b7-408a-b1d0-59550db5bb1b',
        'STAAR Grade 8 Reading Language Arts (RLA)': '70259c26-029b-46f8-9ad8-fe18c99fb757'
      })
    }),
    Electives: OrderedMap({
      'Life Skills': OrderedMap({
        'Civics and American Government': 'c5e97beb-3e77-4525-88e2-2c787c4c51ab',
        'Digital and Media Literacy': 'd375499a-407e-4786-8d71-d6af585a772c',
        'Personal Financial Literacy': '904dca89-c839-4fca-9103-66629d80a1a4'
      })
    }),
    'Full-Text Analysis': OrderedMap({
      'American Literature': OrderedMap({
        'Before We Were Free': '25e860cf-5926-41bf-a283-5af2c9f0347d',
        'Bud, Not Buddy': '6f15f549-fd04-4407-a1b5-e12e19ac1370',
        'Copper Sun': '7c90daa4-f847-4948-88f6-292aba62b0bb',
        'Esperanza Rising': '0601b22e-4aec-44ea-8f8d-e2046712efda',
        'Fever 1793': 'de5b3b05-fbe4-4aa5-bf83-3ca0870de410',
        'Flowers for Algernon': 'b7b86115-b42a-418d-a811-a0a04a997cdd',
        Hatchet: 'fcf1a41e-1f73-4957-9c6e-0e87cf682481',
        'Hidden Figures': '8ba7b009-5966-4e74-a424-c6ddf5bbe202',
        Holes: 'a5369302-0b9c-43ba-bef8-07f9d0dba193',
        'Little Women': '356cacda-e399-4f99-b658-4285162cf498',
        'Out of the Dust': 'd95cff16-8ea6-48d0-8265-3ec2ace395f3',
        'Percy Jackson and the Lightning Thief': 'adaa0577-2433-41b6-8add-09a75f6295bf',
        'The Absolutely True Diary of a Part-Time Indian': '2ab5096c-d6ba-41c3-a596-0fe24ae42400',
        "The Devil's Arithmetic": 'd4702652-7a05-4a46-ae20-91a1dfd8734f',
        'The Giver': '854285f7-9634-4dbc-8c17-8e069651c5d8',
        'The Hate U Give': '53137677-0533-4a5d-93f9-4b56bb0d8b0e',
        'The House on Mango Street': '4489020e-44b5-48a3-9717-3e7cde7b1b14',
        'The Outsiders': '16849f7b-8148-40a6-8e9c-bcb729857a2f',
        'The Phantom Tollbooth': 'd8bc5e37-4f15-43aa-b5d8-cf895b9240c5',
        'The Watsons Go to Birmingham': 'ff5b376c-09da-4836-be60-4b78850a2974',
        'To Kill a Mockingbird': '789f56e9-866f-48cb-ab77-814b4c9e71ef',
        'Tuck Everlasting': 'fcdcb9ce-cf95-4292-b3d7-a2bfe1f20482'
      }),
      Nonfiction: OrderedMap({
        'Anne Frank: The Diary of a Young Girl': '3e051295-29f2-47e1-a07a-0853094e1ea8',
        'Born a Crime': '033bdc31-8868-4aff-90c6-df1c8d630187',
        'Hidden Figures': '8ba7b009-5966-4e74-a424-c6ddf5bbe202',
        'I am Malala': '3292f88a-89d8-4323-af43-36190b7ac299',
        'The Boy Who Harnessed the Wind': 'c35b6d29-48dc-440a-ac72-1a2bf3ab33e6'
      }),
      'Voices of Color': OrderedMap({
        'A Long Walk to Water': '3a2c4653-adba-4058-88c8-b48f66a740fc',
        'Before We Were Free': '25e860cf-5926-41bf-a283-5af2c9f0347d',
        'Born a Crime': '033bdc31-8868-4aff-90c6-df1c8d630187',
        'Bud, Not Buddy': '6f15f549-fd04-4407-a1b5-e12e19ac1370',
        'Copper Sun': '7c90daa4-f847-4948-88f6-292aba62b0bb',
        'Esperanza Rising': '0601b22e-4aec-44ea-8f8d-e2046712efda',
        'Hidden Figures': '8ba7b009-5966-4e74-a424-c6ddf5bbe202',
        'I am Malala': '3292f88a-89d8-4323-af43-36190b7ac299',
        'The Absolutely True Diary of a Part-Time Indian': '2ab5096c-d6ba-41c3-a596-0fe24ae42400',
        'The Boy Who Harnessed the Wind': 'c35b6d29-48dc-440a-ac72-1a2bf3ab33e6',
        'The Hate U Give': '53137677-0533-4a5d-93f9-4b56bb0d8b0e',
        'The House on Mango Street': '4489020e-44b5-48a3-9717-3e7cde7b1b14',
        'The Watsons Go to Birmingham': 'ff5b376c-09da-4836-be60-4b78850a2974'
      }),
      'World Literature': OrderedMap({
        'A Long Walk to Water': '3a2c4653-adba-4058-88c8-b48f66a740fc',
        'Anne Frank: The Diary of a Young Girl': '3e051295-29f2-47e1-a07a-0853094e1ea8',
        'Born a Crime': '033bdc31-8868-4aff-90c6-df1c8d630187',
        "Harry Potter and the Sorcerer's Stone": '4b03a087-68e6-4988-80c4-569263ccd6d6',
        'I am Malala': '3292f88a-89d8-4323-af43-36190b7ac299',
        'Life of Pi': 'e12a9ecc-d7b7-47ae-93e1-a95a8ffc28fb',
        'Lord of the Flies': '220ddc79-8faa-439e-8f6c-10425d2273ee',
        'The Book Thief': 'd4fefd72-c9f4-4cad-8f9a-0e0160b5bd94',
        'The Boy Who Harnessed the Wind': 'c35b6d29-48dc-440a-ac72-1a2bf3ab33e6',
        'The Hobbit': '7dbee174-df64-4f3b-ad94-449b4f2c0c47',
        'Treasure Island': '1b4bc833-24f3-4d17-85d8-8bb646e34d5d'
      }),
      'Young Adult Literature': OrderedMap({
        'A Long Walk to Water': '3a2c4653-adba-4058-88c8-b48f66a740fc',
        'Before We Were Free': '25e860cf-5926-41bf-a283-5af2c9f0347d',
        'Bud, Not Buddy': '6f15f549-fd04-4407-a1b5-e12e19ac1370',
        'Copper Sun': '7c90daa4-f847-4948-88f6-292aba62b0bb',
        'Esperanza Rising': '0601b22e-4aec-44ea-8f8d-e2046712efda',
        'Fever 1793': 'de5b3b05-fbe4-4aa5-bf83-3ca0870de410',
        'Flowers for Algernon': 'b7b86115-b42a-418d-a811-a0a04a997cdd',
        "Harry Potter and the Sorcerer's Stone": '4b03a087-68e6-4988-80c4-569263ccd6d6',
        Hatchet: 'fcf1a41e-1f73-4957-9c6e-0e87cf682481',
        Holes: 'a5369302-0b9c-43ba-bef8-07f9d0dba193',
        'I am Malala': '3292f88a-89d8-4323-af43-36190b7ac299',
        'Life of Pi': 'e12a9ecc-d7b7-47ae-93e1-a95a8ffc28fb',
        'Little Women': '356cacda-e399-4f99-b658-4285162cf498',
        'Lord of the Flies': '220ddc79-8faa-439e-8f6c-10425d2273ee',
        'Out of the Dust': 'd95cff16-8ea6-48d0-8265-3ec2ace395f3',
        'Percy Jackson and the Lightning Thief': 'adaa0577-2433-41b6-8add-09a75f6295bf',
        'The Absolutely True Diary of a Part-Time Indian': '2ab5096c-d6ba-41c3-a596-0fe24ae42400',
        'The Book Thief': 'd4fefd72-c9f4-4cad-8f9a-0e0160b5bd94',
        'The Boy Who Harnessed the Wind': 'c35b6d29-48dc-440a-ac72-1a2bf3ab33e6',
        "The Devil's Arithmetic": 'd4702652-7a05-4a46-ae20-91a1dfd8734f',
        'The Giver': '854285f7-9634-4dbc-8c17-8e069651c5d8',
        'The Hate U Give': '53137677-0533-4a5d-93f9-4b56bb0d8b0e',
        'The Hobbit': '7dbee174-df64-4f3b-ad94-449b4f2c0c47',
        'The House on Mango Street': '4489020e-44b5-48a3-9717-3e7cde7b1b14',
        'The Hunger Games': 'e075b980-6952-4e78-8c84-522c73b6b24b',
        'The Hunger Games: Catching Fire': '1f3261e5-2368-465b-8728-86e868a20533',
        'The Hunger Games: Mockingjay': 'a758d495-72ed-47b9-b500-57146af1478e',
        'The Outsiders': '16849f7b-8148-40a6-8e9c-bcb729857a2f',
        'The Phantom Tollbooth': 'd8bc5e37-4f15-43aa-b5d8-cf895b9240c5',
        'The Watsons Go to Birmingham': 'ff5b376c-09da-4836-be60-4b78850a2974',
        'Treasure Island': '1b4bc833-24f3-4d17-85d8-8bb646e34d5d',
        'Tuck Everlasting': 'fcdcb9ce-cf95-4292-b3d7-a2bfe1f20482',
        Wonder: 'd4de2394-3736-4092-96c5-2e4784f6b17f'
      })
    }),
    Math: OrderedMap({
      'ACT®/SAT®': OrderedMap({
        'PSAT® 8/9 Math (Digital)': '5c6cd02b-a436-4a6d-a92a-27d99f49b1b1'
      }),
      Core: OrderedMap({
        '5th Grade Math': 'e24b0c43-3276-48c0-a29c-5e321a737c73',
        '6th Grade Math': 'e9626e4b-8453-4ffe-a61f-e202200aec88',
        '7th Grade Math': '21af87f3-f14c-4f32-b35c-15453b1533f7',
        '8th Grade Math': '54a353f4-228a-4b39-afbc-122a1618cd3a',
        'Algebra 1': '6b7392b2-607a-465e-932b-0aa9e66849ef',
        'Foundations of Algebra': 'af08f524-d6e6-4819-a883-f19c5ffe3913',
        'Personal Financial Literacy': '904dca89-c839-4fca-9103-66629d80a1a4'
      }),
      'State Exams': OrderedMap({
        'Florida (BEST) Algebra 1 EOC': 'ffcb98b9-9ccf-4631-b705-c27d86fee888',
        'Grade 6 FAST Math': 'eb722fb4-898e-4d0f-88ed-8a9e7cd78ab7',
        'Grade 7 FAST Math': '91293e21-974f-481c-bc39-a512e630c626',
        'Grade 8 FAST Math': '6d07a7c8-3b01-4223-89fb-d9373ea6e153',
        'Keystone Algebra I': 'cda5b477-8ed5-4bf8-9349-0d4e771f4592',
        'LEAP 2025 Algebra I': '109bb697-aecc-4005-9dbb-e95aa543e57a',
        'LEAP 2025 Grade 6 Math': '705f46d3-a4cb-4a48-9c23-dd32879d9ad0',
        'LEAP 2025 Grade 7 Math': 'd26feb87-ed2a-4949-a3de-6403ecf59c2e',
        'LEAP 2025 Grade 8 Math': '30c79994-f613-4527-8366-6a66fd28010b',
        'NJSLA Grade 6 Math': '006a231a-936c-4e8a-a060-a3d72af3827b',
        'NJSLA Grade 7 Math': '14dd75cf-8c83-432e-817f-d67ae7d323ae',
        'NJSLA Grade 8 Math': '5dcaa0d3-5273-473c-8c93-e3ee8fa3fa40',
        'NJSLA/NJGPA Algebra I': 'a4497156-44d9-4342-8932-02a9b151f11b',
        'NY State Test Grade 6 Math': '8e2c12b3-6e27-4d50-bf8d-e0d3c4ad3852',
        'NY State Test Grade 7 Math': 'bf6a6c6d-e848-4b9a-b43d-a27ec9446b56',
        'NY State Test Grade 8 Math': '29162422-1f81-4dbd-bbb7-d0ac36c26fa7',
        'OST Algebra 1': '6c9e588c-d8fc-4194-90eb-dfeda794ed92',
        'Regents Algebra I': 'b7eb793f-d810-4335-ab88-0fe3a83c6d2a',
        'STAAR Algebra I': '2a91a35e-f98a-4b71-b7b3-f2f6ebc95279',
        'STAAR Grade 6 Mathematics': 'cf38f913-8155-4c16-bfce-40cf27143b9d',
        'STAAR Grade 7 Mathematics': '8815e64c-8a3f-4fd0-9611-f37a2c96e9da',
        'STAAR Grade 8 Mathematics': '41a0bf6b-c9ad-47c6-99e1-9da9bc946977'
      })
    }),
    Science: OrderedMap({
      Core: OrderedMap({
        'Intermediate NGSS': '48d6dd2f-093f-49ce-826b-2b7a7914d326',
        'Middle School Earth and Space Science': 'e3448b04-abef-439b-b476-8561af06ce12',
        'Middle School Life Science': '73fc12f8-12c9-47ad-8eff-147396e3b6df',
        'Middle School Physical Science': '177652f5-a000-4bad-917b-9a1d8af0a4c9'
      }),
      'State Exams': OrderedMap({
        'Grade 8 Florida Statewide Science Assessment': 'bd0aa4c7-6c7c-47d5-891c-800c654ea3dc',
        'LEAP 2025 Grade 6 Science': '2454a737-283e-47be-a5de-da3bbf02818f',
        'LEAP 2025 Grade 7 Science': 'a1f159f4-f125-44e1-937e-2eedd7dd1d87',
        'LEAP 2025 Grade 8 Science': '81759a00-318f-46de-838e-7f7387b05365',
        'NJSLA Grade 8 Science': '3d836011-1532-4552-993d-aed070619645',
        'NY State Test Grade 8 Intermediate-Level Science': 'edb02a67-b15c-445b-87fc-f02268180960',
        'Regents Living Environment': '15951ae3-e1ba-4de7-b589-2f53ed68d009',
        'STAAR Grade 8 Science': '0804ec56-e5b5-4088-a0bf-354969cb5275'
      })
    }),
    'Social Studies': OrderedMap({
      Core: OrderedMap({
        'Civics and American Government': 'c5e97beb-3e77-4525-88e2-2c787c4c51ab',
        'Digital and Media Literacy': 'd375499a-407e-4786-8d71-d6af585a772c',
        'Leveled Readings': '22a8d72f-fc16-40ce-9bcc-6d1113f9dc34',
        'Middle School Contemporary Geography': 'a1aa45f7-1042-4275-b1d3-69f1d0c31395',
        'Middle School Global History': '4b3ec62d-4370-4a6d-937b-4ffef6a18c7d',
        'Middle School Historical Geography': '9acda41b-85bd-4596-b79b-4d5a05421e8f',
        'Middle School US History': '20575494-1221-437e-8fbf-be3486054e67',
        'Personal Financial Literacy': '904dca89-c839-4fca-9103-66629d80a1a4',
        'Primary Sources': '62e39be0-dfb2-4676-af38-e8383c6f9b03'
      }),
      'State Exams': OrderedMap({
        'LEAP 2025 Grade 6 Social Studies': '2c416f0e-22b1-4788-95c0-d32251552e28',
        'LEAP 2025 Grade 7 Social Studies': 'bda830f4-a08d-4cf5-a010-7f6bbd254544',
        'LEAP 2025 Grade 8 Social Studies': 'af1d7eff-a0ef-44d4-9209-0ef11e278d42',
        'STAAR Grade 8 Social Studies': '826e858f-a30e-4afd-b25a-f7d7566e4070'
      })
    }),
    'State Exams': OrderedMap({
      Florida: OrderedMap({
        'Grade 6 FAST ELA Reading & BEST Writing': 'a105c852-028c-46fb-866c-ab85248376c0',
        'Grade 6 FAST Math': 'eb722fb4-898e-4d0f-88ed-8a9e7cd78ab7',
        'Grade 7 FAST ELA Reading & BEST Writing': '98358657-669d-497f-9d80-4bab11c50dfe',
        'Grade 7 FAST Math': '91293e21-974f-481c-bc39-a512e630c626',
        'Grade 8 FAST ELA Reading & BEST Writing': 'ebe2acdb-6ee4-451e-a4d3-bfb48d2fc3fd',
        'Grade 8 FAST Math': '6d07a7c8-3b01-4223-89fb-d9373ea6e153',
        'Grade 8 Florida Statewide Science Assessment': 'bd0aa4c7-6c7c-47d5-891c-800c654ea3dc'
      }),
      Louisiana: OrderedMap({
        'LEAP 2025 Grade 6 ELA': 'b62bea9b-0f5b-4878-adad-b74142c055f5',
        'LEAP 2025 Grade 6 Math': '705f46d3-a4cb-4a48-9c23-dd32879d9ad0',
        'LEAP 2025 Grade 6 Science': '2454a737-283e-47be-a5de-da3bbf02818f',
        'LEAP 2025 Grade 6 Social Studies': '2c416f0e-22b1-4788-95c0-d32251552e28',
        'LEAP 2025 Grade 7 ELA': 'af09bd07-9366-4bbc-8c90-ab4cf7b8c9ee',
        'LEAP 2025 Grade 7 Math': 'd26feb87-ed2a-4949-a3de-6403ecf59c2e',
        'LEAP 2025 Grade 7 Science': 'a1f159f4-f125-44e1-937e-2eedd7dd1d87',
        'LEAP 2025 Grade 7 Social Studies': 'bda830f4-a08d-4cf5-a010-7f6bbd254544',
        'LEAP 2025 Grade 8 ELA': 'ddecee44-c054-4376-b303-d55048946bae',
        'LEAP 2025 Grade 8 Math': '30c79994-f613-4527-8366-6a66fd28010b',
        'LEAP 2025 Grade 8 Science': '81759a00-318f-46de-838e-7f7387b05365',
        'LEAP 2025 Grade 8 Social Studies': 'af1d7eff-a0ef-44d4-9209-0ef11e278d42'
      }),
      'New Jersey': OrderedMap({
        'NJSLA Grade 6 ELA': '9b217ce7-c7f5-4107-b9dd-24d7f26a73c8',
        'NJSLA Grade 6 Math': '006a231a-936c-4e8a-a060-a3d72af3827b',
        'NJSLA Grade 7 ELA': '15e7fcc3-c907-4d03-a0e2-315dd2d28a31',
        'NJSLA Grade 7 Math': '14dd75cf-8c83-432e-817f-d67ae7d323ae',
        'NJSLA Grade 8 ELA': 'b8f9d791-c38d-47df-a3ee-a9fea3c19953',
        'NJSLA Grade 8 Math': '5dcaa0d3-5273-473c-8c93-e3ee8fa3fa40',
        'NJSLA Grade 8 Science': '3d836011-1532-4552-993d-aed070619645'
      }),
      'New York': OrderedMap({
        'NY State Test Grade 6 ELA': '933cd6ed-b83e-4557-a4ab-1d494002661b',
        'NY State Test Grade 6 Math': '8e2c12b3-6e27-4d50-bf8d-e0d3c4ad3852',
        'NY State Test Grade 7 ELA': 'a3a40c71-26fc-49b6-bb3b-cae364eda9d1',
        'NY State Test Grade 7 Math': 'bf6a6c6d-e848-4b9a-b43d-a27ec9446b56',
        'NY State Test Grade 8 ELA': '2533ccea-c288-48cf-86cc-66b024b39f3b',
        'NY State Test Grade 8 Intermediate-Level Science': 'edb02a67-b15c-445b-87fc-f02268180960',
        'NY State Test Grade 8 Math': '29162422-1f81-4dbd-bbb7-d0ac36c26fa7'
      }),
      Texas: OrderedMap({
        'Grades 6-7 TELPAS': '05b9528c-4707-4556-8ef8-6de0c8c8d901',
        'Grades 8-9 TELPAS': 'd10251ca-d8cf-4067-8f5d-abc5283bad9d',
        'STAAR Algebra I': '2a91a35e-f98a-4b71-b7b3-f2f6ebc95279',
        'STAAR Biology': 'ed34cf85-c487-409e-b9c2-9c3b9f4b7324',
        'STAAR Grade 6 Mathematics': 'cf38f913-8155-4c16-bfce-40cf27143b9d',
        'STAAR Grade 6 Reading Language Arts (RLA)': '594c1bc6-7f55-47d5-aa72-c840ff0fcf13',
        'STAAR Grade 7 Mathematics': '8815e64c-8a3f-4fd0-9611-f37a2c96e9da',
        'STAAR Grade 7 Reading Language Arts (RLA)': '1debc729-67b7-408a-b1d0-59550db5bb1b',
        'STAAR Grade 8 Mathematics': '41a0bf6b-c9ad-47c6-99e1-9da9bc946977',
        'STAAR Grade 8 Reading Language Arts (RLA)': '70259c26-029b-46f8-9ad8-fe18c99fb757',
        'STAAR Grade 8 Science': '0804ec56-e5b5-4088-a0bf-354969cb5275',
        'STAAR Grade 8 Social Studies': '826e858f-a30e-4afd-b25a-f7d7566e4070'
      })
    }),
    'World Languages': OrderedMap({
      Spanish: OrderedMap({
        'Middle School Spanish': '3dadf000-d718-43d3-ab24-769a963c9fd4',
        'Spanish 1': '35807571-96e4-41e0-800c-0729f8b6fc85'
      })
    })
  }),
  'High School': OrderedMap({
    'AP®': OrderedMap({
      ELA: OrderedMap({
        'AP® English Language': '9a32d19f-3fdb-48f5-a279-c7a7df6401c5',
        'AP® English Literature': '92fee573-1eac-469f-a807-7618401f74f2'
      }),
      Math: OrderedMap({
        'AP® Calculus AB-BC': '5bedb647-f913-429b-86c7-16d6ba0c2a2c',
        'AP® Computer Science A': '5e9d1fd4-5982-4cdf-86df-c3f93d3d64e1',
        'AP® Computer Science Principles': 'f349e342-8558-45dc-8448-8cfad315af2c',
        'AP® Precalculus': '2005f70a-1bbd-414e-bd53-d8b797d93e62',
        'AP® Statistics': '1b026ba2-0a62-4147-8ad7-fd92a724666a'
      }),
      Science: OrderedMap({
        'AP® Biology': '7712e9bb-0595-4cb0-b7f9-9aeede95a3d2',
        'AP® Chemistry': 'f1707a65-a966-4732-876b-edbbc80fbc9b',
        'AP® Environmental Science': '1de2e4c2-2e4d-4c35-ab07-a6e66d66138e',
        'AP® Physics 1': '2420d273-d77e-4c63-9008-e5945db0cdb3',
        'AP® Physics 2': '25263484-061c-4c74-95fb-02425bfd893d',
        'AP® Physics C: E & M': '777501f2-c7a7-4151-a202-025757ad2505',
        'AP® Physics C: Mechanics': '3e207550-ce23-42b2-aea1-3ebf9044da51',
        'AP® Psychology': '2bab5308-a372-4877-a915-9af5bc1812c5'
      }),
      'Social Studies': OrderedMap({
        'AP® African American Studies': '54386488-ca92-402c-abdd-4405650078cc',
        'AP® Comparative Government': '9e741d24-50e1-4c81-98ab-37cc716064ed',
        'AP® European History': '87df3993-6fcd-4308-9e30-0b0cb1442c31',
        'AP® Human Geography': 'bb894d4b-e307-4094-8d39-77b1880905f4',
        'AP® Macroeconomics': '50118483-8160-4fd7-ad2e-804465ab52aa',
        'AP® Microeconomics': '17225a1c-7d4a-46f9-80f0-e04784ced466',
        'AP® US Government': 'a5ece7db-45db-44b4-893c-a4e438c56c93',
        'AP® US History': 'f68fb63e-9ef6-4def-8358-56f25954c9e6',
        'AP® World History': '507acbc1-4c32-4fec-aba6-a2240274924a'
      }),
      'World Language': OrderedMap({
        'AP® Chinese Language': '9dbf5480-bb9b-4a11-9231-db0f8bb8ee90',
        'AP® French Language': '26e2bdbf-6e84-4d34-b0b0-f654e4c213d4',
        'AP® German Language': '10adc919-a27d-422e-9afb-4bc3ab41442a',
        'AP® Italian Language': '4b30c88a-3e55-4225-af88-34da5e4ef323',
        'AP® Japanese Language': 'f81c8831-e181-4661-9e16-cc262a38fbb6',
        'AP® Latin': '078e660d-cb34-4be7-a127-698391d4d16c',
        'AP® Spanish Language': '8eaa46a2-7b2e-41e0-a9a0-f219890fa18f',
        'AP® Spanish Literature': 'f10cbd32-db48-4e81-a019-99c139776f29'
      }),
      Arts: OrderedMap({
        'AP® Art History': '2f4b1f85-5277-4bcd-8b3b-190fd2aa308b',
        'AP® Music Theory': '3ba60576-53a8-4f42-9017-8ad1442385d7'
      })
    }),
    'ACT®/SAT®': OrderedMap({
      'ACT®': OrderedMap({
        'ACT® Enhanced English': 'bcf1e6cc-ac69-466a-a95d-81fc7298335e',
        'ACT® Enhanced Math': '33a19066-c893-4a58-b0c6-14e03989dc66',
        'ACT® Enhanced Reading': '2223a768-0673-42aa-bf7d-3a28a3868e3a',
        'ACT® Enhanced Science': 'ef38252d-7340-481a-aa6e-3fa3fb15a29c',
        'ACT® English': '44e655b4-831e-45c5-a0b5-02d8d68f4d93',
        'ACT® Math': '9c9f327a-9cac-4d4a-85e6-b41c6b89c9a1',
        'ACT® Reading': '49c67e23-606e-43bb-bab5-8d090d333edc',
        'ACT® Science': '67c2e1e9-881f-472d-b2dc-7a8a8b9c11e9'
      }),
      'ACT® WorkKeys': OrderedMap({
        'ACT® WorkKeys: Applied Mathematics': '6b965874-37b3-443b-9cbd-98ec04ac05c1',
        'ACT® WorkKeys: Graphic Literacy': '740b804f-4d89-4203-b68e-df8513b9c72d',
        'ACT® WorkKeys: Workplace Documents': 'f5dccf10-d3ef-4f77-b383-92b393b92210'
      }),
      'PreACT®': OrderedMap({
        'PreACT® English': 'c41ca0ae-9230-4a33-82c2-0f853c9c374f',
        'PreACT® Math': '5134de3f-fe98-44e3-8844-ea883358ffc1',
        'PreACT® Reading': 'a263ebf6-dd6f-41b6-92d3-46995a6a6ce3',
        'PreACT® Science': 'e11af414-0478-40f8-9c20-2d68ff6d75c3'
      }),
      'PSAT®': OrderedMap({
        'PSAT® 8/9 Math (Digital)': '5c6cd02b-a436-4a6d-a92a-27d99f49b1b1',
        'PSAT® 8/9 Reading and Writing': '236b93a4-0b11-4a48-aedd-e656b2b25ecf',
        'PSAT® 10/NMSQT Math (Digital)': 'c9bfdcc2-91e9-4149-9437-b9f5ec235e4d',
        'PSAT® 10/NMSQT Reading and Writing': 'ce39a341-b99e-4c97-83f5-3710fe1f9e18',
        'PSAT® Math': '61fd0c08-737f-42b5-9b15-3f887bbb49bd',
        'PSAT® Reading': '9734b544-75e8-42e6-b8e7-d02077705230',
        'PSAT® Writing and Language': '11a048e1-afd4-4ee3-ba45-448003916cce'
      }),
      'SAT®': OrderedMap({
        'SAT® Math': 'f62efc47-93a7-4c1b-8885-16028fbd27f1',
        'SAT® Math (Digital)': '475df885-5663-4fd4-ab65-907085841995',
        'SAT® Reading': '19b8e13d-3ec5-4346-872e-50f8635aa2a5',
        'SAT® Reading and Writing': '47a82e0e-7303-43e9-b17b-b2852808c7c7',
        'SAT® Writing': '78167c97-c9a6-47c1-afc8-b7717e41fb37'
      })
    }),
    ELA: OrderedMap({
      'ACT®/SAT®': OrderedMap({
        'ACT® Enhanced English': 'bcf1e6cc-ac69-466a-a95d-81fc7298335e',
        'ACT® Enhanced Reading': '2223a768-0673-42aa-bf7d-3a28a3868e3a',
        'ACT® English': '44e655b4-831e-45c5-a0b5-02d8d68f4d93',
        'ACT® Reading': '49c67e23-606e-43bb-bab5-8d090d333edc',
        'ACT® WorkKeys: Graphic Literacy': '740b804f-4d89-4203-b68e-df8513b9c72d',
        'ACT® WorkKeys: Workplace Documents': 'f5dccf10-d3ef-4f77-b383-92b393b92210',
        'PreACT® English': 'c41ca0ae-9230-4a33-82c2-0f853c9c374f',
        'PreACT® Reading': 'a263ebf6-dd6f-41b6-92d3-46995a6a6ce3',
        'PSAT® 8/9 Reading and Writing (Digital)': '236b93a4-0b11-4a48-aedd-e656b2b25ecf',
        'PSAT® 10/NMSQT Reading and Writing (Digital)': 'ce39a341-b99e-4c97-83f5-3710fe1f9e18',
        'SAT® Reading and Writing (Digital)': '47a82e0e-7303-43e9-b17b-b2852808c7c7'
      }),
      'AP®': OrderedMap({
        'AP® English Language': '9a32d19f-3fdb-48f5-a279-c7a7df6401c5',
        'AP® English Literature': '92fee573-1eac-469f-a807-7618401f74f2'
      }),
      'Literacy Skills': OrderedMap({
        Grammar: 'e4da33ba-1209-41c1-9910-48c82477aee5',
        Vocabulary: 'f5743ae3-d926-4cdf-aa50-74dc75ef089b',
        'Spelling Bee': 'a97e5f95-c012-4128-9a1a-f0a193469fc8',
        'Reading Skills': '063f6c1c-fcfc-4fe9-9c16-38276c305d4b',
        'Digital and Media Literacy': 'd375499a-407e-4786-8d71-d6af585a772c'
      }),
      'Informational Texts': OrderedMap({
        'Leveled Readings': '22a8d72f-fc16-40ce-9bcc-6d1113f9dc34',
        'Primary Sources': '62e39be0-dfb2-4676-af38-e8383c6f9b03'
      }),
      'Literary Texts': OrderedMap({
        Poetry: '6a9c9fa8-c2f3-4571-bb80-32b540e950d0',
        'Novels and Drama': '2fa901f7-e727-4086-ae0f-98faa5a6403a',
        'Short Stories': '111fdd83-a182-425d-849c-b001f741af0c'
      }),
      'State Exams': OrderedMap({
        'CAASPP ELA': '87dd8f21-8090-4b89-bae8-e625ad697973',
        'Grade 9 FAST ELA Reading & BEST Writing': 'dec3ad5e-64bf-4430-a0e4-9605767617d4',
        'Grade 10 FAST ELA Reading & BEST Writing': '056469ac-c623-454c-8a81-3d154e3c2ac2',
        'Grades 8-9 TELPAS': 'd10251ca-d8cf-4067-8f5d-abc5283bad9d',
        'Grades 10-12 TELPAS': '6a39833c-9284-4ad9-a6cc-95ee3b899485',
        'Keystone Literature': '33f2d765-e18f-4b6c-819a-59352a805bd2',
        'LEAP 2025 English I': 'e66e76e2-b100-4886-96bb-39884509e808',
        'LEAP 2025 English II': '0a0a270c-5f3b-48ab-9f89-ffcbd91db2aa',
        'NJGPA ELA': '8f9c7071-d862-4ebc-9bfe-b66e912bf253',
        'NJSLA ELA (Grade 9)': '579ea983-064c-4a6f-b09e-257a3a019c19',
        'OST ELA II': 'fe12e28a-1d93-431a-af52-42f5c8d22600',
        'Regents English Language Arts': '9c6c91c0-6928-4b74-ade8-19456cdcc57d',
        'STAAR English I': '616b44d8-4bbb-4dc3-8b71-6c04a00ea012',
        'STAAR English II': 'e27dccb5-8fb0-4304-b21f-9728e668c115',
        'TSI ELAR (TSIA2)': '688d187b-7808-45ea-a043-e485808cbee1'
      })
    }),
    Electives: OrderedMap({
      'Life Skills': OrderedMap({
        'ACT® WorkKeys: Applied Mathematics': '6b965874-37b3-443b-9cbd-98ec04ac05c1',
        'ACT® WorkKeys: Graphic Literacy': '740b804f-4d89-4203-b68e-df8513b9c72d',
        'ACT® WorkKeys: Workplace Documents': 'f5dccf10-d3ef-4f77-b383-92b393b92210',
        Civics: 'c5e97beb-3e77-4525-88e2-2c787c4c51ab',
        'Digital and Media Literacy': 'd375499a-407e-4786-8d71-d6af585a772c',
        Health: '70ef871b-a966-43b8-ba23-5706b5f0e1af',
        'Personal Financial Literacy': '904dca89-c839-4fca-9103-66629d80a1a4'
      }),
      'World Languages': OrderedMap({
        'AP® Chinese Language': '9dbf5480-bb9b-4a11-9231-db0f8bb8ee90',
        'AP® French Language': '26e2bdbf-6e84-4d34-b0b0-f654e4c213d4',
        'AP® German Language': '10adc919-a27d-422e-9afb-4bc3ab41442a',
        'AP® Italian Language': '4b30c88a-3e55-4225-af88-34da5e4ef323',
        'AP® Japanese Language': 'f81c8831-e181-4661-9e16-cc262a38fbb6',
        'AP® Latin': '078e660d-cb34-4be7-a127-698391d4d16c',
        'AP® Spanish Language': '8eaa46a2-7b2e-41e0-a9a0-f219890fa18f',
        'AP® Spanish Literature': 'f10cbd32-db48-4e81-a019-99c139776f29',
        French: 'd513ddb7-5a27-4eac-9acf-b9fbdca715ef',
        'Spanish 1': '35807571-96e4-41e0-800c-0729f8b6fc85',
        'Spanish 2': 'ebd8f1f3-f509-4fe7-a5d8-cf5e2d587280',
        'Spanish 3': '299be484-65da-434c-862b-8f6b7aafb49c'
      })
    }),
    'Full-Text Analysis': OrderedMap({
      'American Literature': OrderedMap({
        'A Lesson Before Dying': '5ae9d346-0e75-464b-b1ec-e40efc0c8cb9',
        'A Raisin in the Sun': '5795393a-a73c-4106-bb4b-aa6c072d6442',
        'A Streetcar Named Desire': '0713011c-a4c5-4652-bf5a-68f114328241',
        Beloved: '9364bf6c-2465-4640-90c3-88087a183a75',
        'Between the World and Me': '9ad0393a-aba8-40cb-9f60-0135bab0af13',
        'Catch 22': '8739a2fe-c438-4ee3-9920-830d1b0054f7',
        'Catcher in the Rye': 'e951d0f5-0d22-4d91-8a75-f629cfe8c873',
        Ceremony: 'b235ceef-1da9-41fe-b9b7-3e5991080cfa',
        'Copper Sun': '7c90daa4-f847-4948-88f6-292aba62b0bb',
        Educated: '339970c1-01d2-451c-b507-53312421c796',
        'Everything I Never Told You': 'ed94f414-5016-4ef5-a16e-53fe929bb04a',
        'Fahrenheit 451': '5c21e5fb-0884-4f01-99ba-7fd8bbae08c1',
        'Fever 1793': 'de5b3b05-fbe4-4aa5-bf83-3ca0870de410',
        'Flowers for Algernon': 'b7b86115-b42a-418d-a811-a0a04a997cdd',
        'Giovanni’s Room': 'bea46d8e-9ae2-4fa9-9b7c-d6415de929a7',
        'Hidden Figures': '8ba7b009-5966-4e74-a424-c6ddf5bbe202',
        'I Know Why the Caged Bird Sings': 'a6a6d850-c6f0-470c-931c-b21a7bd888e6',
        'In the Time of the Butterflies': '6b6dd063-45b2-4397-afec-13ed53c3e2c9',
        'Invisible Man': '967c871a-c4da-446b-997e-004c82c4c9a1',
        'Just Mercy': '1e1122c8-9d6c-49d2-a52a-58a1ecb43ed5',
        Kindred: '4897fbe2-88b3-461c-8e99-712688fd89c6',
        'Native Son': '58f7c75d-3327-41b9-8cd4-31f3d539426c',
        'Of Mice and Men': 'ecad97dd-5334-4187-a788-c48783e57213',
        "One Flew Over the Cuckoo's Nest": '0c997b7a-79dc-4c96-924e-f1940fcdc2b0',
        'Other Voices, Other Rooms': '08f40035-7a9a-4062-9f97-2e6dd8ef1c4c',
        'Sing Unburied Sing': '50dcec64-3c8f-4f56-8a9b-0686807b2965',
        'Snow Falling on Cedars': '12f15fc6-b84b-4e29-9678-4a4c282c3903',
        'Song of Solomon': '7138b1ca-f39a-472b-a3f8-b5fad880a209',
        'The Adventures of Huckleberry Finn': 'b108f8fa-4878-47e0-afda-ed0f604ba0e3',
        'The Awakening': 'c0f73904-a3d5-4627-b061-a35ba4d53399',
        'The Crucible': 'affca601-2966-4d42-9b85-2af7f41b79c2',
        'The Grapes of Wrath': 'e4515eca-398f-437d-b119-c90cd31c797f',
        'The Great Gatsby': '59d1013e-ed30-4311-8dd7-39d02eba5313',
        'The Hate U Give': '53137677-0533-4a5d-93f9-4b56bb0d8b0e',
        'The House on Mango Street': '4489020e-44b5-48a3-9717-3e7cde7b1b14',
        'The Immortal Life of Henrietta Lacks': 'd74bc22f-cba3-47bd-b35a-f14353bef2b2',
        'The Joy Luck Club': 'e9bff030-c56d-4a8d-915c-42092ab9871d',
        'The Namesake': 'd408da9e-5815-4864-9a1b-3bf62d6623b8',
        'The Pearl': '56010481-4485-43c3-9737-ad6a2139d78f',
        'The Poet X': '0bc1f8ec-e651-4787-a353-18e77d8a02c7',
        'The Poisonwood Bible': '61842818-aa77-4ba5-8724-aed1a5c4bb5d',
        'The Scarlet Letter': '120a0967-3679-46ff-941c-6cf05457a21c',
        'The Sun Also Rises': 'f37ee0e7-e917-409e-9686-350de1c9406d',
        'The Things They Carried': 'bee7aa03-cc59-468a-a2c7-85182f351445',
        'The Watsons Go To Birmingham': 'ff5b376c-09da-4836-be60-4b78850a2974',
        'The Watsons Go to Birmingham': 'ff5b376c-09da-4836-be60-4b78850a2974',
        'Their Eyes Were Watching God': '712c517e-3bd7-456f-8490-a6024de5e8a5',
        'There There': '79af0e91-53fd-485d-9361-35ad45923584',
        'To Kill a Mockingbird': '789f56e9-866f-48cb-ab77-814b4c9e71ef',
        'Where the Crawdads Sing': 'd688866d-fe93-4459-b66e-bae987e95720',
        Zami: '5845b06f-2b21-418d-a40c-61158f7fa844'
      }),
      Nonfiction: OrderedMap({
        'Between the World and Me': '9ad0393a-aba8-40cb-9f60-0135bab0af13',
        'Born a Crime': '033bdc31-8868-4aff-90c6-df1c8d630187',
        Educated: '339970c1-01d2-451c-b507-53312421c796',
        'Hidden Figures': '8ba7b009-5966-4e74-a424-c6ddf5bbe202',
        'I am Malala': '3292f88a-89d8-4323-af43-36190b7ac299',
        'I Know Why the Caged Bird Sings': 'a6a6d850-c6f0-470c-931c-b21a7bd888e6',
        'Just Mercy': '1e1122c8-9d6c-49d2-a52a-58a1ecb43ed5',
        'Maus: A Survivor’s Tale': '99bfb8fe-cc2b-4086-b3ec-68c8f86c7938',
        Night: 'f1097a48-29a6-4ac0-ac3c-3c5407e69dce',
        'Persepolis: The Story of a Childhood': '4719bb43-75ad-4ed2-a084-57b67bde6412',
        'The 57 Bus': '0cc5906d-0ce0-4d99-8d0a-a42e02bcdd1a',
        'The Diary of a Young Girl': '3e051295-29f2-47e1-a07a-0853094e1ea8',
        'The Immortal Life of Henrietta Lacks': 'd74bc22f-cba3-47bd-b35a-f14353bef2b2',
        Walden: '4594ab13-3b30-4641-974b-9dce642666c7',
        Zami: '5845b06f-2b21-418d-a40c-61158f7fa844'
      }),
      Shakespeare: OrderedMap({
        "A Midsummer Night's Dream": '3b8e80e8-e4be-4c23-bfdb-9f3205fd0844',
        'As You Like It': 'e615f430-94d5-4861-b4ca-d3719b18e1bf',
        Hamlet: '3423eddd-c43c-4c53-8040-366b877c30ec',
        'Henry V': 'bcf76c0c-80a0-484f-8574-54ab892117db',
        'Julius Caesar': '417bd359-85ab-4551-946f-5c19f52f0724',
        'King Lear': 'af92c4da-841a-42c9-a372-8bf910de3b73',
        Macbeth: 'acfcb959-9e9c-4834-96ab-df719ffe2bf6',
        Othello: '294b45ec-a5f7-4378-928d-0aa914887c7f',
        'Romeo and Juliet': '8c4b20eb-12df-48fc-bfc8-9f8e6c5edd07',
        'The Merchant of Venice': '2bb695d4-602f-432c-a3d5-c6f514362fcb'
      }),
      'Voices of Color': OrderedMap({
        'A Lesson Before Dying': '5ae9d346-0e75-464b-b1ec-e40efc0c8cb9',
        'A Raisin in the Sun': '5795393a-a73c-4106-bb4b-aa6c072d6442',
        Beloved: '9364bf6c-2465-4640-90c3-88087a183a75',
        'Between the World and Me': '9ad0393a-aba8-40cb-9f60-0135bab0af13',
        'Before We Were Free': '25e860cf-5926-41bf-a283-5af2c9f0347d',
        'Born a Crime': '033bdc31-8868-4aff-90c6-df1c8d630187',
        Ceremony: 'b235ceef-1da9-41fe-b9b7-3e5991080cfa',
        'Copper Sun': '7c90daa4-f847-4948-88f6-292aba62b0bb',
        'Everything I Never Told You': 'ed94f414-5016-4ef5-a16e-53fe929bb04a',
        'Giovanni’s Room': 'bea46d8e-9ae2-4fa9-9b7c-d6415de929a7',
        'Hidden Figures': '8ba7b009-5966-4e74-a424-c6ddf5bbe202',
        'I am Malala': '3292f88a-89d8-4323-af43-36190b7ac299',
        'I Know Why the Caged Bird Sings': 'a6a6d850-c6f0-470c-931c-b21a7bd888e6',
        'In the Time of the Butterflies': '6b6dd063-45b2-4397-afec-13ed53c3e2c9',
        'Invisible Man': '967c871a-c4da-446b-997e-004c82c4c9a1',
        'Just Mercy': '1e1122c8-9d6c-49d2-a52a-58a1ecb43ed5',
        Kindred: '4897fbe2-88b3-461c-8e99-712688fd89c6',
        'Native Son': '58f7c75d-3327-41b9-8cd4-31f3d539426c',
        'Never Let Me Go': 'b467a061-6179-4509-8b54-79b530fb4b24',
        'Other Voices, Other Rooms': '08f40035-7a9a-4062-9f97-2e6dd8ef1c4c',
        'Persepolis: The Story of a Childhood': '4719bb43-75ad-4ed2-a084-57b67bde6412',
        'Purple Hibiscus': 'd819adf3-5db9-4664-8e88-f3f8c41e3399',
        'Sing Unburied Sing': '50dcec64-3c8f-4f56-8a9b-0686807b2965',
        'Song of Solomon': '7138b1ca-f39a-472b-a3f8-b5fad880a209',
        'The 57 Bus': '0cc5906d-0ce0-4d99-8d0a-a42e02bcdd1a',
        'The God of Small Things': 'f5d2ff31-3eb7-4363-ae7f-4bd47c8b91d5',
        'The Hate U Give': '53137677-0533-4a5d-93f9-4b56bb0d8b0e',
        'The House on Mango Street': '4489020e-44b5-48a3-9717-3e7cde7b1b14',
        'The Joy Luck Club': 'e9bff030-c56d-4a8d-915c-42092ab9871d',
        'The Kite Runner': '6f37f765-9600-43fd-acd7-f23e95749aab',
        'The Namesake': 'd408da9e-5815-4864-9a1b-3bf62d6623b8',
        'The Poet X': '0bc1f8ec-e651-4787-a353-18e77d8a02c7',
        'The Remains of the Day': '595e7b8e-e290-42b8-bd32-7f335dfff418',
        'The Watsons Go to Birmingham': 'ff5b376c-09da-4836-be60-4b78850a2974',
        'Their Eyes Were Watching God': '712c517e-3bd7-456f-8490-a6024de5e8a5',
        'There There': '79af0e91-53fd-485d-9361-35ad45923584',
        'There, There': '79af0e91-53fd-485d-9361-35ad45923584',
        'Things Fall Apart': 'b0fc8f12-e4e1-49da-a052-cff93e0a3560',
        Zami: '5845b06f-2b21-418d-a40c-61158f7fa844'
      }),
      'World Literature': OrderedMap({
        1984: '454c3307-2aa0-4042-807c-7bf9995013e7',
        'A Tale of Two Cities': 'd29546a4-acd2-4db0-b375-80717f8b96d7',
        'All Quiet on the Western Front': 'c4e83bd7-8520-492a-8950-d3f58de1cb2c',
        'Animal Farm': '15170b27-bd54-4ece-8a1e-010967565c5d',
        Antigone: '4e667869-8527-4509-bee1-fd7cdf6992f7',
        'Before We Were Free': '25e860cf-5926-41bf-a283-5af2c9f0347d',
        Beowulf: '531ed01a-6e64-4298-b922-9c1d0852138b',
        'Born a Crime': '033bdc31-8868-4aff-90c6-df1c8d630187',
        'Brave New World': 'b3715336-73ad-422d-8e14-4e8dd695f717',
        'Crime and Punishment': 'f30748aa-41a5-4d61-9341-571b3514cdb7',
        'Dr. Jekyll and Mr. Hyde': 'cfc25f71-f8b9-406d-a3f5-e4efa37d4241',
        Dracula: 'd6587ed2-0f40-4fc5-8ef8-88ad4749b6b1',
        Frankenstein: '2f57784c-09a8-43e7-9f2c-536141365bc3',
        'Heart of Darkness': '731d4dac-f70b-4a37-ace8-13948b4f64e1',
        'I am Malala': '3292f88a-89d8-4323-af43-36190b7ac299',
        'Jane Eyre': '1051a4be-1643-4bc3-8011-0ca3986df88c',
        'Life of Pi': 'e12a9ecc-d7b7-47ae-93e1-a95a8ffc28fb',
        'Lord of the Flies': '220ddc79-8faa-439e-8f6c-10425d2273ee',
        'Never Let Me Go': 'b467a061-6179-4509-8b54-79b530fb4b24',
        Night: 'f1097a48-29a6-4ac0-ac3c-3c5407e69dce',
        'Oedipus Rex': 'a2302922-caa2-4d1f-a569-de0c4c2168fb',
        'Persepolis: The Story of a Childhood': '4719bb43-75ad-4ed2-a084-57b67bde6412',
        'Pride and Prejudice': '695e9ff6-89ea-4d81-b277-efe00fa24424',
        'Purple Hibiscus': 'd819adf3-5db9-4664-8e88-f3f8c41e3399',
        Pygmalion: '0ad680bf-0d86-4eda-80f0-e6c66e2a9d3c',
        'The Diary of a Young Girl': '3e051295-29f2-47e1-a07a-0853094e1ea8',
        'The Epic of Gilgamesh': '3d14ce4e-6a89-4e3b-9930-8cbb0c938be3',
        'The God of Small Things': 'f5d2ff31-3eb7-4363-ae7f-4bd47c8b91d5',
        "The Handmaid's Tale": '86d2ccf8-4a09-4365-b901-dc64a75208de',
        'The Hobbit': '7dbee174-df64-4f3b-ad94-449b4f2c0c47',
        'The Kite Runner': '6f37f765-9600-43fd-acd7-f23e95749aab',
        'The Metamorphosis': 'f49e9779-6990-4f8e-bba4-25af29121587',
        'The Odyssey': '51b0c30b-3762-4387-859c-26447405a9d1',
        'The Picture of Dorian Gray': 'edb37fd1-558e-47f4-9c96-24d106bccff8',
        'The Remains of the Day': '595e7b8e-e290-42b8-bd32-7f335dfff418',
        'The Stranger': 'bf1596f0-34b0-479f-9891-f24ebd9ac543',
        'Things Fall Apart': 'b0fc8f12-e4e1-49da-a052-cff93e0a3560',
        'Treasure Island': '1b4bc833-24f3-4d17-85d8-8bb646e34d5d',
        'Wuthering Heights': '97bd0d07-83cf-42b9-ad37-0cad049b4bd6'
      }),
      'Young Adult Literature': OrderedMap({
        'Before We Were Free': '25e860cf-5926-41bf-a283-5af2c9f0347d',
        'Copper Sun': '7c90daa4-f847-4948-88f6-292aba62b0bb',
        'Fever 1793': 'de5b3b05-fbe4-4aa5-bf83-3ca0870de410',
        'Flowers for Algernon': 'b7b86115-b42a-418d-a811-a0a04a997cdd',
        Hatchet: 'fcf1a41e-1f73-4957-9c6e-0e87cf682481',
        'Maus: A Survivor’s Tale': '99bfb8fe-cc2b-4086-b3ec-68c8f86c7938',
        'Percy Jackson and the Lightning Thief': 'adaa0577-2433-41b6-8add-09a75f6295bf',
        'Persepolis: The Story of a Childhood': '4719bb43-75ad-4ed2-a084-57b67bde6412',
        'Purple Hibiscus': 'd819adf3-5db9-4664-8e88-f3f8c41e3399',
        'Sing Unburied Sing': '50dcec64-3c8f-4f56-8a9b-0686807b2965',
        Speak: 'af4929c8-22de-4e0c-8c3c-5984ff5820f8',
        'The 57 Bus': '0cc5906d-0ce0-4d99-8d0a-a42e02bcdd1a',
        'The Curious Incident of the Dog in the Nighttime': '77ad54ec-7f2d-436a-85d8-4d1b393d1d5e',
        'The Hate U Give': '53137677-0533-4a5d-93f9-4b56bb0d8b0e',
        'The Hobbit': '7dbee174-df64-4f3b-ad94-449b4f2c0c47',
        'The House on Mango Street': '4489020e-44b5-48a3-9717-3e7cde7b1b14',
        'The Outsiders': '16849f7b-8148-40a6-8e9c-bcb729857a2f',
        'The Perks of Being A Wallflower': '0ddaa25d-679b-455f-b95b-e24ddefc4e0d',
        'The Poet X': '0bc1f8ec-e651-4787-a353-18e77d8a02c7',
        'The Poisonwood Bible': '61842818-aa77-4ba5-8724-aed1a5c4bb5d',
        'The Watsons Go to Birmingham': 'ff5b376c-09da-4836-be60-4b78850a2974',
        'Treasure Island': '1b4bc833-24f3-4d17-85d8-8bb646e34d5d'
      })
    }),
    Math: OrderedMap({
      'ACT®/SAT®': OrderedMap({
        'ACT® Enhanced Math': '33a19066-c893-4a58-b0c6-14e03989dc66',
        'ACT® Math': '9c9f327a-9cac-4d4a-85e6-b41c6b89c9a1',
        'ACT® WorkKeys: Applied Mathematics': '6b965874-37b3-443b-9cbd-98ec04ac05c1',
        'PreACT® Math': '5134de3f-fe98-44e3-8844-ea883358ffc1',
        'PSAT® 8/9 Math (Digital)': '5c6cd02b-a436-4a6d-a92a-27d99f49b1b1',
        'PSAT® 10/NMSQT Math (Digital)': 'c9bfdcc2-91e9-4149-9437-b9f5ec235e4d',
        'SAT® Math (Digital)': '475df885-5663-4fd4-ab65-907085841995'
      }),
      'AP®': OrderedMap({
        'AP® Precalculus': '2005f70a-1bbd-414e-bd53-d8b797d93e62',
        'AP® Calculus AB-BC': '5bedb647-f913-429b-86c7-16d6ba0c2a2c',
        'AP® Statistics': '1b026ba2-0a62-4147-8ad7-fd92a724666a'
      }),
      Core: OrderedMap({
        '8th Grade Math (Pre-Algebra)': '54a353f4-228a-4b39-afbc-122a1618cd3a',
        'Foundations of Algebra': 'af08f524-d6e6-4819-a883-f19c5ffe3913',
        'Mastery Math: Algebra 1': '6b7392b2-607a-465e-932b-0aa9e66849ef',
        'Mastery Math: Algebra 2': 'bd5a54f4-622e-44fc-aa88-d112189cb07c',
        'Mastery Math: Geometry': '74823419-4dc6-474d-af15-81a4455e567e',
        'Mastery Math: Pre-Algebra': '3e57f8b2-dc22-4ae4-bfbb-0aa9a619c9d4',
        'Mastery Math: Precalculus': '5df8c19b-e887-4617-bc87-13dad4dde419',
        'Personal Financial Literacy': '904dca89-c839-4fca-9103-66629d80a1a4'
      }),
      'State Exams': OrderedMap({
        'CAASPP Math': '3ed3b952-3d63-41bb-b264-73e9cd326dce',
        'Florida (BEST) Algebra 1 EOC': 'ffcb98b9-9ccf-4631-b705-c27d86fee888',
        'Florida (BEST) Geometry EOC': 'ec438e16-1f7a-4fb3-86c4-cfbbe6c61f7d',
        'Integrated Mathematics': '78b7a6c6-7c4d-479e-9fae-91a4fc3e287b',
        'Keystone Algebra I': 'cda5b477-8ed5-4bf8-9349-0d4e771f4592',
        'LEAP 2025 Algebra I': '109bb697-aecc-4005-9dbb-e95aa543e57a',
        'LEAP 2025 Geometry': '4528b6fc-5440-452f-bc9d-c927c32f7bb4',
        'NJSLA/NJGPA Algebra I': 'a4497156-44d9-4342-8932-02a9b151f11b',
        'NJSLA/NJGPA Geometry': 'dcc8ae92-0054-4db4-bc3c-e291c9d3e19e',
        'OST Algebra 1': '6c9e588c-d8fc-4194-90eb-dfeda794ed92',
        'OST Geometry': '57896f8d-5be4-4926-8f8f-015752f6e1c6',
        'Regents Algebra I': 'b7eb793f-d810-4335-ab88-0fe3a83c6d2a',
        'Regents Algebra II': 'a0360ce7-74dd-4d14-a5b1-4eabf5d72d2c',
        'Regents Geometry': '6e174eb7-7770-4ad2-97af-459ead064a6f',
        'STAAR Algebra I': '2a91a35e-f98a-4b71-b7b3-f2f6ebc95279',
        'TSI Math (TSIA2)': 'cc798c49-9773-4760-84b6-6052bd475938'
      })
    }),
    Science: OrderedMap({
      'ACT®/SAT®': OrderedMap({
        'ACT® Enhanced Science': 'ef38252d-7340-481a-aa6e-3fa3fb15a29c',
        'ACT® Science': '67c2e1e9-881f-472d-b2dc-7a8a8b9c11e9',
        'ACT® WorkKeys: Graphic Literacy': '740b804f-4d89-4203-b68e-df8513b9c72d',
        'PreACT® Science': 'e11af414-0478-40f8-9c20-2d68ff6d75c3'
      }),
      'AP®': OrderedMap({
        'AP® Biology': '7712e9bb-0595-4cb0-b7f9-9aeede95a3d2',
        'AP® Chemistry': 'f1707a65-a966-4732-876b-edbbc80fbc9b',
        'AP® Environmental Science': '1de2e4c2-2e4d-4c35-ab07-a6e66d66138e',
        'AP® Physics 1': '2420d273-d77e-4c63-9008-e5945db0cdb3',
        'AP® Physics 2': '25263484-061c-4c74-95fb-02425bfd893d',
        'AP® Physics C: Electricity & Magnetism': '777501f2-c7a7-4151-a202-025757ad2505',
        'AP® Physics C: Mechanics': '3e207550-ce23-42b2-aea1-3ebf9044da51',
        'AP® Psychology': '2bab5308-a372-4877-a915-9af5bc1812c5'
      }),
      Core: OrderedMap({
        'Anatomy and Physiology': '1585ec40-d360-4a50-97f3-67129d5d390e',
        'General Biology': '4469c8f8-73c0-499f-9c05-0ae45d1780d8',
        'General Chemistry': '897c6357-89bf-4079-be9a-625637699157',
        'General Physics': '41d58d96-8043-44bf-b088-13bba4a4b6ab',
        'General Earth and Space Science': 'bd53972b-6d1b-4657-84bb-2996361faaf4',
        Health: '70ef871b-a966-43b8-ba23-5706b5f0e1af',
        'NGSS Earth and Space Sciences': 'bc96a234-0111-4882-b07d-77766222b029',
        'NGSS Life Sciences': '296c87a7-c8ef-4342-b016-eaed99769ac7',
        'NGSS Physical Sciences': '884bbc9c-b423-4eb8-9486-372ab41e1192'
      }),
      'State Exams': OrderedMap({
        'Arizona Science Test Grade 11 (AzSCI)': 'bad191da-8a17-44eb-a5cd-6faf320fe8b8',
        'California Science Test': '6ce4f1d8-fa64-4763-b169-8a75b66d695d',
        'CMAS Grade 11 Science': '9aec87e6-157b-4192-9771-c755286a515b',
        'Florida (NGSSS) Biology 1 EOC': '8a51471e-3012-4adf-80c4-dcd173ce4c00',
        'Keystone Biology': 'fe66bb51-fa61-4fbc-a635-3e92cce9654c',
        'LEAP 2025 Biology': 'aa84fdef-b9fe-42c0-b7ff-85bd8ce30a5f',
        'NJSLA Science (Grade 11)': '22a048ee-8195-4ee7-a708-74444ea934b5',
        'OST Biology': '41d888ff-ce3e-47ce-ab11-6684d0c88836',
        'Regents Chemistry': '34ab2beb-212f-4ed2-9773-2493ff5aa8cc',
        'Regents Earth and Space Sciences': 'd63dcaa9-ee07-4a5d-992d-1a164925a29a',
        'Regents Earth Science': '64759088-1678-4510-8efd-17f3225e6dd0',
        'Regents Life Science: Biology': '653ff0c5-85a1-4281-ab3c-768fcd2a6b15',
        'Regents Living Environment': '15951ae3-e1ba-4de7-b589-2f53ed68d009',
        'Regents Physics': 'e7b419e0-504f-4348-a274-6de93ecc202d',
        'STAAR Biology': 'ed34cf85-c487-409e-b9c2-9c3b9f4b7324'
      })
    }),
    'Social Studies': OrderedMap({
      'AP®': OrderedMap({
        'AP® African American Studies': '54386488-ca92-402c-abdd-4405650078cc',
        'AP® Comparative Government': '9e741d24-50e1-4c81-98ab-37cc716064ed',
        'AP® European History': '87df3993-6fcd-4308-9e30-0b0cb1442c31',
        'AP® Human Geography': 'bb894d4b-e307-4094-8d39-77b1880905f4',
        'AP® Macroeconomics': '50118483-8160-4fd7-ad2e-804465ab52aa',
        'AP® Microeconomics': '17225a1c-7d4a-46f9-80f0-e04784ced466',
        'AP® Psychology': '2bab5308-a372-4877-a915-9af5bc1812c5',
        'AP® US Government': 'a5ece7db-45db-44b4-893c-a4e438c56c93',
        'AP® US History': 'f68fb63e-9ef6-4def-8358-56f25954c9e6',
        'AP® World History: Modern': '507acbc1-4c32-4fec-aba6-a2240274924a'
      }),
      Core: OrderedMap({
        Civics: 'c5e97beb-3e77-4525-88e2-2c787c4c51ab',
        'Digital and Media Literacy': 'd375499a-407e-4786-8d71-d6af585a772c',
        Economics: '493f41b8-0dcb-4b7f-9ff5-da0be4d41d2c',
        'European History, 1450 - 1814': '2b9deb9c-5cbd-4d7c-8afb-ae0b6b9d9982',
        'European History 1814-Present': 'a62eb657-7be8-4cb0-92dd-5e811c242335',
        'Foundations of European History': '11a70ffc-8763-45ae-9d20-7e698ae804f8',
        'Global History': '4cac28f1-e6c6-4bf7-9b5f-c13c19ca9253',
        'Leveled Readings in History, Politics, and Biography':
          '22a8d72f-fc16-40ce-9bcc-6d1113f9dc34',
        'Personal Financial Literacy': '904dca89-c839-4fca-9103-66629d80a1a4',
        'Primary Sources': '62e39be0-dfb2-4676-af38-e8383c6f9b03',
        'U.S. History': '3d546431-155d-427b-ba36-a672d29f35d4',
        'World Geography': 'e8b26d52-3958-4981-9b7a-82392af639cf'
      }),
      'State Exams': OrderedMap({
        'Florida (NGSSS) Civics EOC': '9a1fcb0d-18ea-4acd-9dec-3e90ac7df0c8',
        'Florida (NGSSS) U.S. History EOC': '54229641-3c14-4e98-b9e6-8773fef7955f',
        'LEAP 2025 Civics': '746b8a8c-b74e-4fad-85b4-f1f8e6b2bc7f',
        'LEAP 2025 U.S. History': '8db2d8f1-d956-4e9f-a4f0-47c56ecce7ee',
        'OST American Government': 'da94af63-2774-4456-9814-17ec21455cf2',
        'OST American History': 'de870284-3d28-4220-bfcb-368667bac846',
        'Regents Global History and Geography': '333d1783-6c10-4e0c-99b1-b1eb7d16eb7a',
        'Regents United States History and Government': 'b9f75396-61ac-4e57-ba99-6dba0dd7b100',
        'STAAR U.S. History': '26fba1fd-046a-4cd7-b37c-0bdc9a31b222'
      })
    }),
    'State Exams': OrderedMap({
      Arizona: OrderedMap({
        'Arizona Science Test Grade 11 (AzSCI)': 'bad191da-8a17-44eb-a5cd-6faf320fe8b8',
        'ACT® Enhanced English': 'bcf1e6cc-ac69-466a-a95d-81fc7298335e',
        'ACT® Enhanced Math': '33a19066-c893-4a58-b0c6-14e03989dc66',
        'ACT® Enhanced Reading': '2223a768-0673-42aa-bf7d-3a28a3868e3a',
        'ACT® Enhanced Science': 'ef38252d-7340-481a-aa6e-3fa3fb15a29c',
        'ACT® English': '44e655b4-831e-45c5-a0b5-02d8d68f4d93',
        'ACT® Math': '9c9f327a-9cac-4d4a-85e6-b41c6b89c9a1',
        'ACT® Reading': '49c67e23-606e-43bb-bab5-8d090d333edc',
        'ACT® Science': '67c2e1e9-881f-472d-b2dc-7a8a8b9c11e9'
      }),
      California: OrderedMap({
        'CAASPP ELA': '87dd8f21-8090-4b89-bae8-e625ad697973',
        'CAASPP Math': '3ed3b952-3d63-41bb-b264-73e9cd326dce',
        CAST: '6ce4f1d8-fa64-4763-b169-8a75b66d695d'
      }),
      Colorado: OrderedMap({
        'CMAS Grade 11 Science': '9aec87e6-157b-4192-9771-c755286a515b',
        'PSAT® 8/9 Math (Digital)': '5c6cd02b-a436-4a6d-a92a-27d99f49b1b1',
        'PSAT® 8/9 Reading and Writing (Digital)': '236b93a4-0b11-4a48-aedd-e656b2b25ecf',
        'PSAT® 10/NMSQT Math (Digital)': 'c9bfdcc2-91e9-4149-9437-b9f5ec235e4d',
        'PSAT® 10/NMSQT Reading and Writing (Digital)': 'ce39a341-b99e-4c97-83f5-3710fe1f9e18',
        'SAT® Math (Digital)': '475df885-5663-4fd4-ab65-907085841995',
        'SAT® Reading and Writing (Digital)': '47a82e0e-7303-43e9-b17b-b2852808c7c7'
      }),
      Florida: OrderedMap({
        'FAST ELA Reading (9th)': 'dec3ad5e-64bf-4430-a0e4-9605767617d4',
        'FAST ELA Reading (10th)': '056469ac-c623-454c-8a81-3d154e3c2ac2',
        'Florida (BEST) Algebra 1 EOC': 'ffcb98b9-9ccf-4631-b705-c27d86fee888',
        'Florida (BEST) Geometry EOC': 'ec438e16-1f7a-4fb3-86c4-cfbbe6c61f7d',
        'Florida (NGSSS) Biology 1 EOC': '8a51471e-3012-4adf-80c4-dcd173ce4c00',
        'Florida (NGSSS) Civics EOC': '9a1fcb0d-18ea-4acd-9dec-3e90ac7df0c8',
        'Florida (NGSSS) U.S. History EOC': '54229641-3c14-4e98-b9e6-8773fef7955f'
      }),
      Louisiana: OrderedMap({
        'LEAP 2025 Algebra I': '109bb697-aecc-4005-9dbb-e95aa543e57a',
        'LEAP 2025 American History': '8db2d8f1-d956-4e9f-a4f0-47c56ecce7ee',
        'LEAP 2025 Biology': 'aa84fdef-b9fe-42c0-b7ff-85bd8ce30a5f',
        'LEAP 2025 Civics': '746b8a8c-b74e-4fad-85b4-f1f8e6b2bc7f',
        'LEAP 2025 English I': 'e66e76e2-b100-4886-96bb-39884509e808',
        'LEAP 2025 English II': '0a0a270c-5f3b-48ab-9f89-ffcbd91db2aa',
        'LEAP 2025 Geometry': '4528b6fc-5440-452f-bc9d-c927c32f7bb4'
      }),
      'New Jersey': OrderedMap({
        'NJGPA ELA': '8f9c7071-d862-4ebc-9bfe-b66e912bf253',
        'NJSLA Algebra I': 'a4497156-44d9-4342-8932-02a9b151f11b',
        'NJSLA ELA (Grade 9)': '579ea983-064c-4a6f-b09e-257a3a019c19',
        'NJSLA Geometry': 'dcc8ae92-0054-4db4-bc3c-e291c9d3e19e',
        'NJSLA Science (Grade 11)': '22a048ee-8195-4ee7-a708-74444ea934b5'
      }),
      'New York': OrderedMap({
        'Regents Algebra II': 'a0360ce7-74dd-4d14-a5b1-4eabf5d72d2c',
        'Regents Chemistry': '34ab2beb-212f-4ed2-9773-2493ff5aa8cc',
        'Regents Earth and Space Sciences': 'd63dcaa9-ee07-4a5d-992d-1a164925a29a',
        'Regents Earth Science': '64759088-1678-4510-8efd-17f3225e6dd0',
        'Regents English Language Arts': '9c6c91c0-6928-4b74-ade8-19456cdcc57d',
        'Regents Geometry': '6e174eb7-7770-4ad2-97af-459ead064a6f',
        'Regents Global History and Geography': '333d1783-6c10-4e0c-99b1-b1eb7d16eb7a',
        'Regents Life Science: Biology': '653ff0c5-85a1-4281-ab3c-768fcd2a6b15',
        'Regents Mathematics (Algebra I)': 'b7eb793f-d810-4335-ab88-0fe3a83c6d2a',
        'Regents Physics': 'e7b419e0-504f-4348-a274-6de93ecc202d',
        'Regents Science': '15951ae3-e1ba-4de7-b589-2f53ed68d009',
        'Regents United States History and Government': 'b9f75396-61ac-4e57-ba99-6dba0dd7b100'
      }),
      Ohio: OrderedMap({
        'OST Algebra 1': '6c9e588c-d8fc-4194-90eb-dfeda794ed92',
        'OST American Government': 'da94af63-2774-4456-9814-17ec21455cf2',
        'OST American History': 'de870284-3d28-4220-bfcb-368667bac846',
        'OST Biology': '41d888ff-ce3e-47ce-ab11-6684d0c88836',
        'OST ELA II': 'fe12e28a-1d93-431a-af52-42f5c8d22600',
        'OST Geometry': '57896f8d-5be4-4926-8f8f-015752f6e1c6'
      }),
      Pennsylvania: OrderedMap({
        'Keystone Algebra I': 'cda5b477-8ed5-4bf8-9349-0d4e771f4592',
        'Keystone Biology': 'fe66bb51-fa61-4fbc-a635-3e92cce9654c',
        'Keystone Literature': '33f2d765-e18f-4b6c-819a-59352a805bd2'
      }),
      Texas: OrderedMap({
        'Grades 8-9 TELPAS': 'd10251ca-d8cf-4067-8f5d-abc5283bad9d',
        'Grades 10-12 TELPAS': '6a39833c-9284-4ad9-a6cc-95ee3b899485',
        'STAAR ELA': '616b44d8-4bbb-4dc3-8b71-6c04a00ea012',
        'STAAR English II': 'e27dccb5-8fb0-4304-b21f-9728e668c115',
        'STAAR Mathematics': '2a91a35e-f98a-4b71-b7b3-f2f6ebc95279',
        'STAAR Social Studies': '26fba1fd-046a-4cd7-b37c-0bdc9a31b222',
        'STAAR Science': 'ed34cf85-c487-409e-b9c2-9c3b9f4b7324',
        'TSI ELAR (TSIA2)': '688d187b-7808-45ea-a043-e485808cbee1',
        'TSI Math (TSIA2)': 'cc798c49-9773-4760-84b6-6052bd475938'
      })
    }),
    'World Languages': OrderedMap({
      Spanish: OrderedMap({
        'AP® Spanish Language': '8eaa46a2-7b2e-41e0-a9a0-f219890fa18f',
        'AP® Spanish Literature': 'f10cbd32-db48-4e81-a019-99c139776f29',
        'Spanish 1': '35807571-96e4-41e0-800c-0729f8b6fc85',
        'Spanish 2': 'ebd8f1f3-f509-4fe7-a5d8-cf5e2d587280',
        'Spanish 3': '299be484-65da-434c-862b-8f6b7aafb49c'
      }),
      French: OrderedMap({
        'AP® French Language': '26e2bdbf-6e84-4d34-b0b0-f654e4c213d4',
        French: 'd513ddb7-5a27-4eac-9acf-b9fbdca715ef'
      }),
      Other: OrderedMap({
        'AP® Chinese Language': '9dbf5480-bb9b-4a11-9231-db0f8bb8ee90',
        'AP® German Language': '10adc919-a27d-422e-9afb-4bc3ab41442a',
        'AP® Italian Language': '4b30c88a-3e55-4225-af88-34da5e4ef323',
        'AP® Japanese Language': 'f81c8831-e181-4661-9e16-cc262a38fbb6',
        'AP® Latin': '078e660d-cb34-4be7-a127-698391d4d16c'
      })
    })
  }),
  College: OrderedMap({
    Math: OrderedMap({
      Algebra: OrderedMap({
        'Abstract Algebra': 'b593346b-3a7a-4b7d-9c15-18416fadbbaa',
        Combinatorics: '2a7fa73a-4324-4908-b7d7-61fc034af9f9',
        'Linear Algebra': '68a495a3-3c06-4f37-9aab-73266eebb306'
      }),
      Analysis: OrderedMap({
        'Differential Equations': 'f7f81254-0614-4616-a722-b5680b4cf881',
        'Single Variable Calculus': 'a49bb6cf-777a-433f-9b82-afbea806c5da',
        'Multivariable Calculus': 'd14d4a97-7403-4458-bad6-1718fc9945bc',
        'Number Theory': 'a2af71d1-bce0-45c8-b09f-23d50d4efb14',
        'College Statistics and Probability': '2b1b3433-c362-4c68-8e41-9e44b4e0bdcc'
      }),
      Geometry: OrderedMap({
        Topology: 'ac834791-c117-4ddd-b098-4db6569312a0',
        'College Trigonometry': '00e8a60d-ba67-4762-a9ae-83da54b94ad1'
      })
    }),
    Science: OrderedMap({
      Biology: OrderedMap({
        Biochemistry: 'bd3c0da1-a23c-4c24-8179-c9970bc73633',
        'Cellular and Molecular Biology': 'ebbc3525-7d6f-4985-a83e-3c1a4afd8a04',
        'Developmental Biology': '52e43da2-1a9c-4d8b-93b4-e4cbe6ba5439',
        'Ecology and Ecosystems': '87723a5a-d4ea-4ae2-b266-41913a23e267',
        'General Biology': '4469c8f8-73c0-499f-9c05-0ae45d1780d8',
        'Genetics and Genomics': '2b3f6aa1-0bde-4d88-b482-3d815cf69cbb',
        Immunology: '196b3edc-8213-485b-93d0-cada9c48bb20',
        Neuroscience: 'd8150994-0b33-4c12-88d3-ecc66fff05fe'
      }),
      Chemistry: OrderedMap({
        'General Chemistry': '897c6357-89bf-4079-be9a-625637699157',
        'Inorganic Chemistry': 'c1a97aaa-3ef1-4c7d-a2af-c6fca90ab7a4',
        'Organic Chemistry 1': '8419998c-272e-425e-99de-931f7c2037ec',
        'Organic Chemistry 2': '826103bd-79bd-496f-9493-b5e0d14a5f55',
        'Physical Chemistry': '041f2928-ecfb-40a0-b8a5-8fbfe06cc824'
      }),
      'Computer Science': OrderedMap({
        'Algorithms & Data Structures': '2f562a70-46c7-4ad5-8c91-e925f3dd51bd'
      }),
      Physics: OrderedMap({
        'General Physics': '41d58d96-8043-44bf-b088-13bba4a4b6ab'
      })
    }),
    'Social Sciences': OrderedMap({
      Economics: OrderedMap({
        Econometrics: 'f61fb955-bdc3-46ae-8caa-29b9d621a1f5',
        Macroeconomics: 'a9eb764c-96f4-4f52-b536-5b0ff6afd591',
        Microeconomics: '73dee6e3-5f0f-43d7-b33b-1c44d71a4e27'
      })
    })
  }),
  Professional: OrderedMap({
    Financial: OrderedMap({
      Accounting: OrderedMap({
        'Financial Accounting': 'c5a0356e-ba28-49fd-bbae-6feeec0b8c13',
        'Managerial Accounting': 'f4564c68-2d74-45bb-8227-5772db95e71e'
      }),
      Finance: OrderedMap({
        'Corporate Finance': 'dd5f7901-15ca-47f3-b0e8-c9902ee2736f'
      })
    }),
    'Graduate Exams': OrderedMap({
      General: OrderedMap({
        'GMAT®': '3f437fc7-2443-438d-b3ef-cc0b4a26a400',
        'GRE®': 'b2c1ae90-2fe0-4fda-8ad0-7f5d10a7aa22'
      }),
      Specialty: OrderedMap({
        'MCAT® Biology': '02ce61d4-dd08-43d0-96d1-535992d8df86'
      })
    })
  })
});
