import React from 'react';
import {callAction} from 'client/framework';
import sessionStore from 'client/Session/SessionStore';
import interactionEngineStore from 'client/InteractionEngineV2/InteractionEngineStore';
import interactionEngineActions from 'client/InteractionEngineV2/InteractionEngineActions';
import './teacher-mode-toggle.scss';

export default class TeacherModeToggle extends React.Component {
  toggleTeacherMode = () => {
    callAction(
      interactionEngineActions.SET_TEACHER_MODE,
      !interactionEngineStore.isTeacherModeEnabled()
    );
  };

  render() {
    if (sessionStore.hasTeacherAccess() === false) {
      return null;
    }

    const buttonCopy = interactionEngineStore.isTeacherModeEnabled()
      ? 'View as student'
      : 'View as teacher';

    return (
      <div className='teacher-mode-toggle'>
        <button className='button button--green' onClick={this.toggleTeacherMode}>
          <span className='fa fa-eye' /> {buttonCopy}
        </button>
      </div>
    );
  }
}
