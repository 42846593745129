import React from 'react';
import {List, Set} from 'immutable';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import {history} from 'client/history';
import makeConstants from 'lib/makeConstants';

import Card from '../../atoms/Card/Card.react';
import Checkbox from '../../atoms/Checkbox/Checkbox.react';
import LoadingSpinnerLong from '../../molecules/LoadingSpinnerLong/LoadingSpinnerLong.react';

import Column from './Column/Column.react';
import EmptyTable from './EmptyTable/EmptyTable.react';
import TitleBar from './TitleBar/TitleBar.react';
import Header, {sortOptions} from './Header/Header.react';
import Row from './Row/Row.react';
import Cell from './Cell/Cell.react';
import './table.scss';

const themeOptions = makeConstants('data', 'list');

export const TableContext = React.createContext({
  selectedRows: Set(),
  sortDirection: sortOptions.ASCENDING,
  sortIndex: 0,
  sortByFunc: () => {},
  setSelectedRows: () => {},
  setSortByFunc: () => {},
  setSortDirection: () => {},
  setSortIndex: () => {}
});

class TableProvider extends React.Component {
  static propTypes = {
    defaultSortDirection: PropTypes.string,
    selectableRowsFunc: PropTypes.func,
    children: PropTypes.node,
    customSortFunc: PropTypes.func,
    defaultSortIndex: PropTypes.number,
    data: ImmutablePropTypes.iterable
  };

  static defaultProps = {
    defaultSortDirection: sortOptions.ASCENDING
  };

  constructor(props) {
    super(props);

    this.state = {
      /* eslint-disable react/no-unused-state */
      sortDirection: props.defaultSortDirection,
      sortIndex: props.defaultSortIndex,
      sortByFunc: () => {},
      selectedRows: Set(),
      setSelectedRows: this.setSelectedRows,
      selectAllRows: this.selectAllRows,
      setSortByFunc: this.setSortByFunc,
      setSortDirection: this.setSortDirection,
      setSortIndex: this.setSortIndex,
      isSelectedRow: this.isSelectedRow,
      hasAllRowsSelected: this.hasAllRowsSelected
      /* eslint-enable react/no-unused-state */
    };
  }

  setSortByFunc = (sortByFunc) => {
    if (this.props.customSortFunc) {
      this.props.customSortFunc({sortByParam: sortByFunc()});
    }

    /* eslint-disable-next-line react/no-unused-state */
    this.setState({sortByFunc});
  };

  setSortDirection = (sortDirection) => {
    if (this.props.customSortFunc) {
      this.props.customSortFunc({sortDirection});
    }

    /* eslint-disable-next-line react/no-unused-state */
    this.setState({sortDirection});
  };

  setSortIndex = (sortIndex) => {
    /* eslint-disable-next-line react/no-unused-state */
    this.setState({sortIndex});
  };

  setSelectedRows = (row) => {
    this.setState((state) => {
      const selectedRows = !state.selectedRows.includes(row)
        ? state.selectedRows.add(row)
        : state.selectedRows.delete(row);

      this.props.selectableRowsFunc(selectedRows);
      return {selectedRows};
    });
  };

  selectAllRows = (rows) => {
    this.setState((state) => {
      const selectedRows =
        state.selectedRows.size !== rows.size
          ? state.selectedRows.concat(rows)
          : state.selectedRows.clear();

      this.props.selectableRowsFunc(selectedRows);
      return {selectedRows};
    });
  };

  isSelectedRow = (row) => {
    return this.state.selectedRows.includes(row);
  };

  hasAllRowsSelected = () => {
    return this.state.selectedRows.size === this.props.data.size && !this.props.data.isEmpty();
  };

  render() {
    return <TableContext.Provider value={this.state}>{this.props.children}</TableContext.Provider>;
  }
}

export default class Table extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    customEmptyTable: PropTypes.element,
    customSortFunc: PropTypes.func,
    data: ImmutablePropTypes.iterable,
    defaultSortDirection: PropTypes.oneOf(Object.values(sortOptions)),
    headerless: PropTypes.bool,
    isLoading: PropTypes.bool,
    isReloading: PropTypes.bool,
    rowLinkFunc: PropTypes.func,
    rowOnClickFunc: PropTypes.func,
    selectableRowsFunc: PropTypes.func,
    tableFooter: PropTypes.node,
    theme: PropTypes.oneOf(Object.values(themeOptions)),
    className: PropTypes.string
  };

  static defaultProps = {
    defaultSortDirection: sortOptions.ASCENDING,
    theme: 'data',
    headerless: false
  };

  static Column = Column;

  static TitleBar = TitleBar;

  static EmptyTable = EmptyTable;

  makeColumnProps() {
    const columns = React.Children.toArray(this.props.children);

    return columns.filter((c) => !!c).map((column) => column.props);
  }

  render() {
    const {
      className,
      customEmptyTable,
      customSortFunc,
      data,
      defaultSortDirection,
      headerless,
      isLoading,
      isReloading,
      rowLinkFunc,
      rowOnClickFunc,
      selectableRowsFunc,
      tableFooter,
      theme
    } = this.props;

    const columnProps = this.makeColumnProps();
    let defaultSortIndex = columnProps.findIndex((column) => column.defaultSortParam);
    if (defaultSortIndex === -1) {
      defaultSortIndex = columnProps.findIndex((column) => column.sortable);
    }
    // allow column level overrides for defaultSortDirection
    const sortDirection =
      columnProps[defaultSortIndex]?.defaultColumnSortDirection || defaultSortDirection;

    return (
      <TableProvider
        selectableRowsFunc={selectableRowsFunc}
        customSortFunc={customSortFunc}
        defaultSortIndex={defaultSortIndex !== -1 ? defaultSortIndex : 0}
        defaultSortDirection={sortDirection}
        data={data}
      >
        <Card className='o-table__wrapper' border={theme === 'list' ? 'none' : 'regular'}>
          <table
            className={classnames('o-table', className, {
              [`o-table--${theme}`]: theme
            })}
          >
            {!headerless && (
              <TableHeaderRender
                data={data}
                defaultSortDirection={defaultSortDirection}
                columnProps={columnProps}
                customSortFunc={customSortFunc}
                hasSelectableRows={!!selectableRowsFunc}
              />
            )}
            <TableBodyRender
              data={data}
              isLoading={isLoading}
              isReloading={isReloading}
              columnProps={columnProps}
              customEmptyTable={customEmptyTable}
              rowLinkFunc={rowLinkFunc}
              rowOnClickFunc={rowOnClickFunc}
              headerless={headerless}
              hasSelectableRows={!!selectableRowsFunc}
              hasCustomSortFunc={!!customSortFunc}
              tableFooter={tableFooter}
            />
          </table>
        </Card>
      </TableProvider>
    );
  }
}

class TableHeaderRender extends React.Component {
  static propTypes = {
    data: ImmutablePropTypes.iterable,
    defaultSortDirection: PropTypes.string,
    columnProps: PropTypes.array,
    customSortFunc: PropTypes.func,
    hasSelectableRows: PropTypes.bool
  };

  makeHeadersWithContext = (context) => {
    return (column, key) => {
      if (typeof column.children === 'function') {
        const contextWithData = {data: this.props.data, ...context};
        // In some cases, <Col /> is returned by a context consumer, meaning
        // it's a function as child.  If so, we return the result of calling
        // the function with the proper context and isolate its props
        column = column.children(contextWithData)?.props;
      }

      if (!column) {
        return null;
      }
      const primaryIndex = this.props.columnProps.findIndex((column) => column.primary);
      const isPrimary = primaryIndex >= 0 ? primaryIndex === key : key === 0;
      const isCurrentSort = context.sortIndex === key;
      const sortByFunc =
        column.sortable || column.sortBy ? () => column.sortBy || column.content : null;

      return (
        <Header
          key={key}
          align={column.align}
          className={classnames(column.className, {
            'o-table__action-menu-header': column.isActionMenu,
            [column.className]: !column.isActionMenu,
            'o-table__header--no-clamp': column.noClamp
          })}
          customSortFunc={this.props.customSortFunc}
          defaultSortDirection={
            column?.defaultColumnSortDirection || this.props.defaultSortDirection
          }
          sortable={column.sortable || !!column.sortBy}
          primary={isPrimary}
          index={key}
          isCurrentSort={isCurrentSort}
          setSortByFunc={() => context.setSortByFunc(sortByFunc)}
          setSortedColumn={context.setSortIndex}
          setSortDirection={context.setSortDirection}
        >
          {column.heading}
        </Header>
      );
    };
  };

  render() {
    return (
      <TableContext.Consumer>
        {(context) => (
          <thead>
            <Row>
              {this.props.hasSelectableRows && (
                <Header className='o-table__selection-header'>
                  <Checkbox
                    className='o-table__row-select'
                    checked={context.hasAllRowsSelected()}
                    onClick={() => context.selectAllRows(this.props.data)}
                  />
                </Header>
              )}
              {this.props.columnProps.map(this.makeHeadersWithContext(context))}
            </Row>
          </thead>
        )}
      </TableContext.Consumer>
    );
  }
}

class TableBodyRender extends React.Component {
  static propTypes = {
    data: ImmutablePropTypes.iterable,
    columnProps: PropTypes.array,
    customEmptyTable: PropTypes.element,
    isLoading: PropTypes.bool,
    isReloading: PropTypes.bool,
    rowLinkFunc: PropTypes.func,
    rowOnClickFunc: PropTypes.func,
    headerless: PropTypes.bool,
    hasSelectableRows: PropTypes.bool,
    hasCustomSortFunc: PropTypes.bool,
    tableFooter: PropTypes.node
  };

  static defaultProps = {
    data: List()
  };

  makeLinkProps(makeHrefFunc, row, rowOnClickFunc) {
    if (rowOnClickFunc) {
      return {
        onClick: () => rowOnClickFunc(row),
        className: 'o-table__row--link',
        role: 'link'
      };
    }

    if (!makeHrefFunc || !makeHrefFunc(row)) {
      return {};
    }

    return {
      role: 'link',
      onClick: (e) => history.pushState(null, e.currentTarget.getAttribute('href')),
      href: makeHrefFunc(row),
      className: 'o-table__row--link'
    };
  }

  getData = (context) => {
    if (this.props.hasCustomSortFunc || this.props.headerless) {
      return this.props.data;
    }

    return context.sortDirection === sortOptions.ASCENDING
      ? this.props.data.sortBy(context.sortByFunc())
      : this.props.data.sortBy(context.sortByFunc()).reverse();
  };

  getRowKey = (row, index) => {
    // Check if getId method exists and use it, otherwise fall back to index
    return row.getId ? row.getId() : index;
  };

  makeRowsWithContext = (context) => {
    return (row, index) => {
      const rowProps = this.makeLinkProps(this.props.rowLinkFunc, row, this.props.rowOnClickFunc);
      const primaryIndex = this.props.columnProps.findIndex((column) => column.primary);
      const rowKey = this.getRowKey(row, index);

      return (
        <Row key={rowKey} isSelected={context.isSelectedRow(row)} {...rowProps}>
          {this.props.hasSelectableRows && (
            <Cell className='o-table__selection-cell'>
              <Checkbox
                className='o-table__row-select'
                checked={context.isSelectedRow(row)}
                onClick={(e) => {
                  e.stopPropagation();
                  context.setSelectedRows(row);
                }}
              />
            </Cell>
          )}
          {this.props.columnProps.map((column, cellKey) => {
            // In the case that a column is a Function as Child Component
            // the column arg here can represent either a 1) node or 2) function.
            // In case of (2) we need to re-assign the props of our result to `column`.
            let columnProps;
            if (typeof column.children === 'function') {
              // We spread the current dataset into the context argument to
              // mimic the dataset that gets passed to a column's content prop
              const contextWithData = {
                data: this.getData(context),
                ...context
              };
              if (!column.children(contextWithData)) {
                return null;
              }
              columnProps = column.children(contextWithData).props;
            } else {
              columnProps = column;
            }

            const isPrimary = primaryIndex >= 0 ? primaryIndex === cellKey : cellKey === 0;
            return (
              <Cell
                key={cellKey}
                align={columnProps.align}
                className={classnames(column.className, {
                  'o-table__action-menu-cell': columnProps.isActionMenu,
                  [columnProps.className]: !columnProps.isActionMenu,
                  'o-table__cell--no-clamp': columnProps.noClamp
                })}
                primary={isPrimary}
                subValue={columnProps.subValue(row)}
              >
                {columnProps.content(row)}
              </Cell>
            );
          })}
        </Row>
      );
    };
  };

  render() {
    if (this.props.isLoading) {
      return (
        <tbody>
          <tr>
            <td className='o-table__loading'>
              <LoadingSpinnerLong />
            </td>
          </tr>
        </tbody>
      );
    }

    return (
      <TableContext.Consumer>
        {(context) => {
          const data = this.getData(context);
          const emptyTableContent = this.props.customEmptyTable || <EmptyTable />;

          return (
            <tbody>
              {this.props.isReloading ? (
                <tr className='o-table__reloading-indicator' role='presentation'>
                  <td className='u-pad_2' colSpan={this.props.columnProps.size}>
                    <LoadingSpinnerLong />
                  </td>
                </tr>
              ) : null}
              {data.isEmpty() ? emptyTableContent : data.map(this.makeRowsWithContext(context))}
              {this.props.tableFooter && (
                <tr role='presentation'>
                  <td className='u-pad_2' colSpan={this.props.columnProps.size}>
                    {this.props.tableFooter}
                  </td>
                </tr>
              )}
            </tbody>
          );
        }}
      </TableContext.Consumer>
    );
  }
}
