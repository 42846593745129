/* eslint-disable no-underscore-dangle */
import {debounce} from 'lodash';
import {Map} from 'immutable';
import {Store} from 'client/framework';
import sessionStore from 'client/Session/SessionStore';
import questionTypeActions from 'components/QuestionTypes/questionType.actions';

import {GuessModelV1} from 'resources/augmented/Guess/GuessModel.v1';

import {getDraftGuess, patchDraftGuess, saveDraftGuess} from './QuestionType.queries';
import {SaveStatus} from './QuestionType.types';

export default class QuestionTypeStore extends Store {
  constructor(name, questionId = null, saveDraftGuessDebounceWait = 250) {
    super(name);

    this.draftGuessInitialData = {
      draftId: null,
      draftGuess: null,
      draftGuessPromise: null,
      editStartTime: null,
      hasPendingDebouncedSaveDraftGuess: false,
      lastDraftTime: null,
      questionId,
      saveStatus: SaveStatus.LOADING,
      timeElapsed: 0
    };

    this.handleTargeted(
      questionTypeActions.CANCEL_DEBOUNCED_SAVE_DRAFT_GUESS,
      this.cancelDebouncedSaveDraftGuess
    );
    this.handleTargeted(questionTypeActions.CLEAR_GUESS, this._clearGuess);
    this.handleTargeted(questionTypeActions.CLEAR_DRAFT_GUESS_DATA, this._clearDraftGuessData);
    this.handleTargeted(questionTypeActions.LOAD_DRAFT_GUESS, this.loadDraftGuess);
    this.handleTargeted(questionTypeActions.LOAD_GUESS, this._loadGuess);
    this.handleTargeted(questionTypeActions.SAVE_DRAFT_GUESS, this.saveDraftGuess);
    this.handleTargeted(questionTypeActions.START_TIMER, this.startTimer);
    this.handleTargeted(questionTypeActions.UPDATE_QUESTION, this.setProperty('__question'));

    this.debouncedSaveDraftGuess = debounce(
      this.saveDraftGuess.bind(this),
      saveDraftGuessDebounceWait
    );
  }

  buildCorrectGuess(question) {
    return new GuessModelV1({
      content: question.getValidResponse(),
      correct: true,
      points_earned: question.getPoints(),
      question_v1: question
    });
  }

  buildEmptyGuess(question) {
    return new GuessModelV1({
      content: Map(),
      correct: false,
      points_earned: 0,
      question_v1: question
    });
  }

  buildGuess({assignmentId, timeElapsed} = {}) {
    if (typeof timeElapsed !== 'number') {
      throw new Error('`timeElapsed` is missing from guess content.');
    }

    const guessData = {
      content: this.buildGuessContent(),
      time_elapsed: this.buildTimeElapsed()
    };

    if (assignmentId) {
      guessData.assignment_id = assignmentId;
    }

    // Only used for Multiple Choice Questions
    if (this.buildEliminatedOptions) {
      guessData.eliminated_options = this.buildEliminatedOptions();
    }

    return new GuessModelV1(guessData)
      .updateRelationship('question_v3', this.getQuestionId())
      .updateRelationship('student_v1', sessionStore.getUserId())
      .updateRelationship('assignment_v2', assignmentId, !!assignmentId);
  }

  buildTimeElapsed() {
    const editStartTime = this.readData('editStartTime');
    const prevTimeElapsed = this.readData('timeElapsed');
    const now = new Date();

    const newTimeElapsed = editStartTime ? now - editStartTime : 0;
    const totalTime = newTimeElapsed + prevTimeElapsed;

    return totalTime;
  }

  cancelDebouncedSaveDraftGuess() {
    this.debouncedSaveDraftGuess.cancel();
    this.writeData('hasPendingDebouncedSaveDraftGuess', false);
  }

  _clearDraftGuessData() {
    this.writeData('draftId', null);
    this.writeData('draftGuess', null);
    this.writeData('timeElapsed', 0);
    this.writeData('saveStatus', SaveStatus.DEFAULT);
    this.writeData('lastDraftTime', null);
  }

  _clearGuess() {
    this.writeData((store) =>
      store
        .set('isGuessCorrect', false)
        .set('rubric', null)
        .set('solutionText', null)
        .set('hasResponse', false)
        .set('pointsPossible', null)
        .set('pointsEarned', null)
    );

    // After clearing the data, we'll force the window to the top of the page
    setTimeout(() => {
      global.scrollTo(0, 0);
    }, 0);
  }

  static getAssignmentId() {
    const params = global.location.pathname.split('/');

    let assignmentId = null;

    if (params.some((param) => param === 'assignment')) {
      assignmentId = params[params.length - 1];
    }

    return assignmentId;
  }

  getDraftId() {
    return this.readData('draftId');
  }

  getDraftGuess() {
    return this.readData('draftGuess');
  }

  getDraftGuessPromise() {
    return this.readData('draftGuessPromise');
  }

  getHasDraftGuess() {
    return this.getDraftGuess() !== null;
  }

  getHasPendingDebouncedSaveDraftGuess() {
    return this.readData('hasPendingDebouncedSaveDraftGuess');
  }

  getLastSaveTime() {
    const saveTime = this.readData('lastDraftTime');
    return saveTime ? new Date(saveTime) : undefined;
  }

  getQuestionId = () => this.readData('questionId');

  getQuestion = () => this.readData('__question');

  getResponseGuess = () => this.readData('responseGuess');

  getSaveStatus() {
    return this.readData('saveStatus');
  }

  kickoffDebouncedSaveDraftGuess() {
    if (QuestionTypeStore.shouldNotSaveDraft()) {
      return;
    }

    this.debouncedSaveDraftGuess();
    this.writeData('hasPendingDebouncedSaveDraftGuess', true);
  }

  async loadDraftGuess() {
    if (QuestionTypeStore.shouldNotSaveDraft()) {
      this.writeData('saveStatus', SaveStatus.DEFAULT);
      return;
    }

    if (typeof this.afterLoadDraftGuess !== 'function') {
      throw new Error('afterLoadDraftGuess must be implemented in order to load drafts.');
    }

    const assignmentId = QuestionTypeStore.getAssignmentId();
    const questionId = this.readData('questionId');

    let response = null;

    try {
      response = await getDraftGuess(questionId, assignmentId);
    } catch (error) {
      console.error(error);
      this.writeData('saveStatus', SaveStatus.LOAD_ERROR);
      // todo how to handle loading draft errors
      return;
    }

    if (response === null || response.size === 0) {
      this._clearDraftGuessData();
      return;
    }

    const draftGuess = response.first();
    const id = draftGuess.get('id', null);
    const timeElapsed = draftGuess.get('time_elapsed', 0);
    const updatedAt = draftGuess.get('updated_at', null);

    this.writeData('draftId', id);
    this.writeData('draftGuess', draftGuess);
    this.writeData('timeElapsed', timeElapsed);
    this.writeData('saveStatus', SaveStatus.SAVED);
    this.writeData('lastDraftTime', updatedAt);

    this.afterLoadDraftGuess(draftGuess);
  }

  _loadGuess(guess) {
    this._loadQuestion();
    this.writeData((store) =>
      store
        .set('isGuessCorrect', guess.isCorrect())
        .set('hasResponse', true)
        .set('pointsEarned', guess.getPointsEarned())
    );
  }

  _loadQuestion(question = this.readData('__question')) {
    /**
     * @todo (sort of): The following assignment operation looks first to the "new-school" V2/V3 way
     * of fetching question rubrics (via the meta) vs. the "old-school" V1 top-level attribute. This
     * view is getting replaced by the new practice view soon, so I am content with foregoing making
     * changes to the queries and extensions.
     */
    const rubric = question.getIn(['meta', 'question_rubric'], Map());

    this.writeData((store) =>
      store
        .set('rubric', rubric)
        .set('solutionText', question.getSolutionText())
        .set('pointsPossible', question.getPoints())
    );
  }

  async saveDraftGuess() {
    if (QuestionTypeStore.shouldNotSaveDraft()) {
      return;
    }

    const draftId = this.readData('draftId');
    const assignmentId = QuestionTypeStore.getAssignmentId();
    const questionId = this.readData('questionId');

    const draftGuessContent =
      typeof this.buildDraftGuessContent === 'function'
        ? this.buildDraftGuessContent()
        : this.buildGuessContent();

    const payload = {timeElapsed: this.buildTimeElapsed(), content: draftGuessContent};

    this.writeData('saveStatus', SaveStatus.SAVING);

    let response;

    try {
      if (draftId !== null) {
        this.writeData('draftGuessPromise', patchDraftGuess(draftId, payload));
        response = await this.getDraftGuessPromise();
      } else {
        this.writeData('draftGuessPromise', saveDraftGuess(payload, questionId, assignmentId));
        response = await this.getDraftGuessPromise();

        const id = response.get('id', null);
        this.writeData('draftId', id);
      }
    } catch (error) {
      // todo: add error toast that there was an error saving?
      // or should this function take an error callback.
      // add error to save status
      this.writeData('saveStatus', SaveStatus.SAVE_ERROR);
      console.log(error);
    }

    this.writeData('hasPendingDebouncedSaveDraftGuess', false);

    if (this.readData('saveStatus') === SaveStatus.SAVE_ERROR) {
      return;
    }

    this.startTimer();

    const updatedAt = response.get('updated_at', JSON.stringify(new Date()));
    const timeElapsed = response.get('time_elapsed', 0);

    this.writeData('draftGuess', response);
    this.writeData('saveStatus', SaveStatus.SAVED);
    this.writeData('lastDraftTime', updatedAt);
    this.writeData('timeElapsed', timeElapsed);
  }

  static shouldNotSaveDraft() {
    const assignmentId = this.getAssignmentId();
    const isStudent = sessionStore.isStudent();
    const userId = sessionStore.getUserId();

    return assignmentId === null || !isStudent || userId === undefined;
  }

  startTimer() {
    this.writeData('editStartTime', new Date());
  }
}
