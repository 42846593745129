import React from 'react';
import PropTypes from 'prop-types';
import {fromJS} from 'immutable';
import {debounce} from 'lodash';
import classnames from 'classnames';
import {Helmet} from 'react-helmet';

import {
  GlobalModal,
  GlobalToastProvider,
  WindowSizeProvider,
  WindowSizeConsumer,
  Footer,
  addToast
} from '@albert-io/atomic';
import {ErrorBoundary} from '@albert-io/notifier/services/honeybadger/browser';
import {getSession} from '@albert-io/mandark/authentication';

import MasqueradePanel from 'generic/Masquerade/MasqueradePanel/MasqueradePanel.react';
import {AudioPlayerProvider} from 'client/generic/AudioPlayer/AudioPlayerProvider.react';
import MaintenanceModal from 'client/components/MaintenanceModal/MaintenanceModal.react';
import systemTimeOffsetActions from 'client/generic/SystemTimeOffset/SystemTimeOffset.actions';
import {callAction} from 'client/framework';
import {GoogleProvider} from 'client/components/Google';
import {CreateAssignmentProvider} from 'client/CreateAssignmentV2/CreateAssignmentProvider';
import ChangePasswordLandingPage from 'client/components/ChangePasswordLandingPage/ChangePasswordLandingPage.react';
import CanvasIframeOverlay from 'client/components/CanvasIframeOverlay/CanvasIframeOverlay.react';
import ConfirmEmail from 'client/static/UserActions/ConfirmEmail/ConfirmEmail.react';
import sessionStore from 'client/Session/SessionStore';
import {redirect} from 'client/User/UserRedirectUtil';
import ForceOnboarding from 'client/Onboarding/ForceOnboarding.react';
import {getFeatureFlagStatus} from 'client/components/FeatureFlag/FeatureFlag.react';

import {title, meta} from 'lib/head';

// Import these so they will add themselves to the distpatcher.
import {initializeMandarkAPI} from 'framework/MandarkAPI/MandarkAPI';

import {setChangeCallback, registerErrorHandler} from 'resources/mandark.resource';

import {getSafariWebContent} from './SafariWebContent/SafariWebContent';
import GlobalNavigation from './GlobalNavigation/GlobalNavigation.react';
import {
  GlobalNavBehaviorProvider,
  GlobalNavBehaviorConsumer,
  GlobalNavBehaviorEnum
} from './GlobalNavigation/GlobalNavBehavior.context';
import constants from './constants';
import ModalCollection from './Modals/ModalCollection.react';
import {getActiveState} from './framework';
import {appActions} from './AppActions';
import appStore from './AppStore';
import {shouldRedirectFromPathname} from './User/UserRedirectUtil';
import {SuspenseWrapper} from './SuspenseWrapper.react';

import '@fortawesome/fontawesome-pro/scss/fontawesome.scss';
import '@fortawesome/fontawesome-pro/scss/solid.scss';
import '@fortawesome/fontawesome-pro/scss/regular.scss';
import '@fortawesome/fontawesome-pro/scss/v4-shims.scss';
import 'assets/stylesheets/main.scss';

function handleErrorsFromAllAPIs(err) {
  if (err.status === 401) {
    addToast({
      color: 'negative',
      title: 'Unauthorized',
      message: "You'll need to log in to perform this action."
    });
  }

  if (err.status === 403) {
    addToast({
      color: 'negative',
      title: 'Forbidden',
      message: "It looks like you don't have access to perform this action."
    });
  }

  if (process.env.IS_BROWSER) {
    if (err?.response?.headers['x-authorization-inactive'] === 'true') {
      global.document.location = '/session/expired';
    }
  }
}

if (process.env.IS_BROWSER) {
  /**
   * Note to curious: The server will initialize its own server-side API manager
   * in express.js. If we were to redo the manager init here, we would end up
   * clobbering the cache on every new client connect on the server side.
   * This ensures that only one instance of the API exists - one created in the
   * server, one created in the client.
   */
  initializeMandarkAPI((err) => {
    handleErrorsFromAllAPIs(err);
  });
  // for mandark.resource
  const handleErrorForMandarkResource = (err) => {
    handleErrorsFromAllAPIs(err);
  };
  registerErrorHandler(handleErrorForMandarkResource);
}

const changeCallback = () => getActiveState().forceUpdate();

const debouncedChangeCallback = debounce(
  () => {
    changeCallback();
  },
  250,
  {
    leading: false,
    trailing: true
  }
);

/**
 * This is a temporary band-aid to IE's being really slow. Whenever data resolves from the backend,
 * we call the CHANGE_CALLBACK, which causes a top-level app rerender by default. Because multiple queries
 * are invalidated when a guess is placed, this causes multiple CHANGE_CALLBACK calls to be called, which
 * causes up to four rerenders back to back.
 *
 * @todo: Figure out a solution for this.
 */
setChangeCallback(() => {
  if (
    process.env.IS_BROWSER &&
    sessionStore.isStudent() &&
    ['/assignment/', '/learn/'].some((path) => window.location.pathname.startsWith(path))
  ) {
    debouncedChangeCallback();
  } else {
    changeCallback();
  }
});

const setMobileViewHandler = () => {
  const isCurrentlyMobile = appStore.isMobileView();
  const windowInnerWidth = global.innerWidth;
  const shouldBeMobile = windowInnerWidth <= constants.MOBILE_VIEW_BREAKPOINTS.DEVICE_SMALL;

  if (shouldBeMobile !== isCurrentlyMobile) {
    callAction(appActions.SET_IS_MOBILE_VIEW, shouldBeMobile);
  }
};

const debouncedSetMobileViewHandler = debounce(setMobileViewHandler, 250);

export default class App extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    location: PropTypes.shape({
      pathname: PropTypes.string
    })
  };

  constructor(props, context) {
    super(props, context);

    if (getSession().isValid()) {
      if (shouldRedirectFromPathname(props.location.pathname)) {
        redirect(props);
      }
    }

    if (process.env.IS_BROWSER) {
      setMobileViewHandler();
      window.addEventListener('resize', debouncedSetMobileViewHandler);
    }
  }

  async componentDidMount() {
    // Dark mode FF
    const isDarkModeFFEnabled = await getFeatureFlagStatus('color_mode_dark');
    if (isDarkModeFFEnabled) {
      import('assets/stylesheets/enable-dark-mode.scss');
    }
    callAction(systemTimeOffsetActions.INIT_STORE);
    getActiveState().on('change', this.timedForceUpdate);
    // After the entire app has finished rendering, go ahead and forceUpdate
    this.forceUpdate();
  }

  componentWillUnmount() {
    getActiveState().removeListener('change', this.timedForceUpdate);
    window.removeEventListener('resize', debouncedSetMobileViewHandler);
  }

  timedForceUpdate = () => {
    // eslint-disable-next-line no-console
    console.time('Top-level App rerender');
    this.forceUpdate(() => {
      // eslint-disable-next-line no-console
      console.timeEnd('Top-level App rerender');
    });
  };

  render() {
    const {location, children} = this.props;

    let pageContent = children && React.cloneElement(children, {});
    /**
     * If it's a request for the "/error" page, don't even attempt to render anything else.
     */
    if (location.pathname === '/error') {
      return (
        <div className='page'>
          <div className='common-content'>{pageContent}</div>
        </div>
      );
    }

    const isConfirmEmailPage = location.pathname === '/confirm-email';
    const isSocialLanding = location.pathname === '/social';
    const isConfirmCoppaPage = location.pathname === '/coppa/confirmation';
    const requiresEmailVerification =
      sessionStore.hasValidSession() && !sessionStore.isUserConfirmed();

    /* parse token for reset_password_on_login flag */
    const mustResetPassword =
      sessionStore.hasValidSession() && getSession().shouldResetPasswordOnLogin();
    if (sessionStore.hasValidSession()) {
      /*
        ALWAYS forces a user that needs to confirm their email to be in that view.

        The terms of use page is the only other acceptible route they can be on.
      */
      if (!sessionStore.isUserConfirmed() && !isConfirmEmailPage && !isConfirmCoppaPage) {
        pageContent = <ConfirmEmail />;
      }

      if (mustResetPassword) {
        pageContent = <ChangePasswordLandingPage userId={sessionStore.getUserId()} />;
      }
    }

    const {routes} = appStore.routerProps;
    const currentRoute = routes[routes.length - 1];
    const useWhiteBg = currentRoute.withWhiteBackground;

    return (
      <ErrorBoundary>
        <WindowSizeProvider>
          <GlobalToastProvider>
            <AudioPlayerProvider>
              <CreateAssignmentProvider>
                <CanvasIframeOverlay />
                <MaintenanceModal
                  downtimes={[{time: 'Jan 12, 2024 17:00 PST', duration: '2 hours'}]}
                />
                <GlobalNavBehaviorProvider>
                  <GlobalNavBehaviorConsumer>
                    {({navBehavior}) => {
                      return (
                        <GoogleProvider>
                          <div className='page'>
                            <div
                              className={classnames('common-content', {
                                'common-content--global-nav-not-sticky':
                                  navBehavior === GlobalNavBehaviorEnum.VISIBLE_NOT_STICKY,
                                'common-content--without-global-nav':
                                  navBehavior === GlobalNavBehaviorEnum.NOT_VISIBLE,
                                'common-content--white-bg': useWhiteBg || mustResetPassword
                              })}
                            >
                              <Helmet>
                                {title(appStore.getTitle())}
                                {meta.description(appStore.getMetaDescription())}
                                {getSafariWebContent()}
                              </Helmet>
                              <ModalCollection location={fromJS(location)} />
                              <MasqueradePanel />

                              {navBehavior !== GlobalNavBehaviorEnum.NOT_VISIBLE && (
                                <WindowSizeConsumer>
                                  {({viewportWidth}) => (
                                    <GlobalNavigation
                                      viewportWidth={viewportWidth}
                                      requiresEmailVerification={requiresEmailVerification}
                                      location={location}
                                    />
                                  )}
                                </WindowSizeConsumer>
                              )}
                              <SuspenseWrapper>
                                {sessionStore.hasValidSession() &&
                                !isSocialLanding &&
                                !isConfirmCoppaPage &&
                                !isConfirmEmailPage ? (
                                  <ForceOnboarding>{pageContent}</ForceOnboarding>
                                ) : (
                                  pageContent
                                )}
                              </SuspenseWrapper>
                            </div>
                          </div>
                          {appStore.isFooterVisible() && <Footer />}
                          <GlobalModal />
                          <div className='app-portal' id='portal' />
                        </GoogleProvider>
                      );
                    }}
                  </GlobalNavBehaviorConsumer>
                </GlobalNavBehaviorProvider>
              </CreateAssignmentProvider>
            </AudioPlayerProvider>
          </GlobalToastProvider>
        </WindowSizeProvider>
      </ErrorBoundary>
    );
  }
}
