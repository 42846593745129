import React from 'react';
import PropTypes from 'prop-types';
import {List} from 'immutable';
import {queryBuilderPropType} from '@albert-io/json-api-framework/request/builder';

/**
 * @typedef {Object} Props
 * @property
 */

/**
 * TagOrStandardGroup
 * ==================
 *
 * This component initializes a Mandark query prop on mount and writes the results to state.
 * It maps over the results in its render method, passing in a renderFunc prop to the iterator.
 * This is especially handy for dealing with tags and standards, which are meant intermittently
 * meant to be linkable (the practice view), removable (the quesiton editor), or display a tooltip
 * on hover (both).
 *
 * @augments {React.Component<Props>}
 */
export class TagOrStandardGroup extends React.Component {
  static propTypes = {
    /** The wrapper element (tbody in LabelTable) */
    as: PropTypes.func,
    /** The Mandark query (endpoint must return a list) */
    query: PropTypes.instanceOf(queryBuilderPropType).isRequired,
    /** The function we pass to the iterator in our render method */
    renderFunc: PropTypes.func.isRequired
  };

  static defaultProps = {
    as: 'div'
  };

  state = {
    items: List()
  };

  componentDidMount() {
    this.fetchItems();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.query.equals(prevProps.query)) {
      this.fetchItems();
    }
  }

  fetchItems = () => {
    this.props.query.getResourcePromise().then((res) => {
      this.setState({items: res});
    });
  };

  getItemTitle = (item) => (item.getTitle ? item.getTitle() : item.getName());

  render() {
    const Wrapper = this.props.as;
    if (this.state.items.isEmpty()) {
      return null;
    }
    return (
      <Wrapper>{this.state.items.sortBy(this.getItemTitle).map(this.props.renderFunc)}</Wrapper>
    );
  }
}
