import {List} from 'immutable';
export default function formatHighlightPrompt(highlightPrompt, highlights = new List()) {
  let currentOffset = 0;
  highlights
    .sortBy((highlight) => {
      return highlight.get('start');
    })
    .forEach((highlight) => {
      const start = highlight.get('start');
      const end = highlight.get('end');
      const highlightSpan = `<span data-start="${start}" data-end="${end}" class="answer-input__highlight">`;
      const closingSpan = '</span>';
      highlightPrompt = splice(highlightPrompt, start + currentOffset, highlightSpan);
      highlightPrompt = splice(
        highlightPrompt,
        end + 1 + currentOffset + highlightSpan.length,
        closingSpan
      );
      currentOffset += highlightSpan.length + closingSpan.length;
    });

  return highlightPrompt;
}

function splice(string, index, addition) {
  return string.slice(0, index) + (addition || '') + string.slice(index, string.length);
}
