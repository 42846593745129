import {StateCreator} from 'zustand';
import {List, Map} from 'immutable';
import {AssignmentModelV3} from '@albert-io/models';
import sessionStore from 'client/Session/SessionStore';

import {getAssignmentQuery} from 'client/components/ContentDiscovery/AssignmentSettings/AssignmentSettings.queries';
import type {ContentDiscoveryStoreState} from 'client/components/ContentDiscovery/store/ContentDiscoveryStore.types';

import type {SelectedRecipients} from '../../Document/Settings/RecipientSettings/RecipientSettings.types';

type AssignmentState = {
  assignment: AssignmentModelV3 | null;
  assignmentLoading: boolean;
  assignmentResults: List<AssignmentModelV3>;
  selectedRecipients: SelectedRecipients; // recipients to display count on confirmation/share list
  isReassign: boolean;
};

type AssignmentActions = {
  getAssignmentById: (assignmentId: string) => void;
  setAssignmentResults: (assignmentResults: List<AssignmentModelV3>) => void;
  setSelectedRecipients: (selectedRecipients: SelectedRecipients) => void;
  reset: () => void;
  setIsReassign: () => void;
};

const initialState: AssignmentState = {
  assignment: null,
  assignmentLoading: false,
  assignmentResults: List(),
  selectedRecipients: Map(),
  isReassign: false
};

export type AssignmentSlice = AssignmentState & AssignmentActions;

/* eslint-disable no-param-reassign */
export const createAssignmentSlice: StateCreator<
  ContentDiscoveryStoreState,
  [['zustand/immer', never]],
  [],
  AssignmentSlice
> = (set, _): AssignmentSlice => ({
  ...initialState,
  // initial fetch of subjects by curriculum areas along with personalized and classroom subjects
  getAssignmentById: async (assignmentId) => {
    if (!assignmentId) {
      return;
    }

    set((state) => {
      state.assignment.assignmentLoading = true;
    });
    try {
      const [fetchedAssignment] = await Promise.all([
        getAssignmentQuery(assignmentId, sessionStore.isSchoolAdministrator())
      ]);
      set((state) => {
        state.assignment.assignmentLoading = false;
        state.assignment.assignment = fetchedAssignment;
      });
    } catch {
      set((state) => {
        state.assignment.assignmentLoading = false;
        state.assignment.assignment = null;
      });
    }
  },

  setAssignmentResults: (assignmentResults) =>
    set((state) => {
      state.assignment.assignmentResults = assignmentResults;
    }),

  setSelectedRecipients: (selectedRecipients) =>
    set((state) => {
      state.assignment.selectedRecipients = selectedRecipients;
    }),

  setIsReassign: () =>
    set((state) => {
      state.assignment.isReassign = true;
    }),

  reset: () =>
    set((state) => {
      state.assignment = {...state.assignment, ...initialState};
    })
});
