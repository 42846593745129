/**
 * USED: special state of attempt for assignments when we don't want to show
 * whether an attempt is correct or not but still need to indicate
 * that the attempt has been used (question has been answered)
 *
 * NOT_USED: question has not been answered even once
 */
const attemptState = {
  CORRECT: 'CORRECT',
  INCORRECT: 'INCORRECT',
  USED: 'USED',
  NOT_USED: 'NOT_USED'
};

const attemptTitle = {
  CORRECT: 'Correct Answer',
  INCORRECT: 'Incorrect Answer',
  USED: 'Answered Question',
  NOT_USED: 'Unanswered Question'
};

export {attemptState, attemptTitle};
