// @flow
import {fromJS, Map} from 'immutable';
import {Store} from 'client/framework';
import sessionStore from 'client/Session/SessionStore';
import type {StudentModelV2} from 'resources/GeneratedModels/Student/StudentModel.v2';

import type {QueryBuilder} from '@albert-io/json-api-framework/request/builder/legacy';
import {mandarkEndpoint} from '@albert-io/json-api-framework/request/builder/legacy';

import studentAssignmentQuestionsListStore from '../StudentAssignmentQuestionsList/StudentAssignmentQuestionsList.store';

import abandonAssignmentModalActions from './AbandonAssignmentModal.actions';

class AbandonAssignmentModalStore extends Store {
  constructor(name) {
    super(name);
    this.initialData = fromJS({
      error: false,
      hasConfirmedWillLeave: false,
      isModalActive: false,
      nextPathName: null,
      showToast: false,
      submitAssignmentPromise: null,
      shouldAdvanceRouterPostSubmission: false,
      toast: {}
    });
    this.setInitialData(this.initialData);
    this.handle(
      abandonAssignmentModalActions.CONFIRM_WILL_LEAVE,
      this.setProperty('hasConfirmedWillLeave')
    );
    this.handle(
      abandonAssignmentModalActions.TOGGLE_ABANDON_MODAL_ACTIVE,
      this.setProperty('isModalActive')
    );
    this.handle(abandonAssignmentModalActions.SET_NEXT_PATH_NAME, this.setProperty('nextPathName'));
    this.handle(abandonAssignmentModalActions.RESET_STORE, this.resetStore);
    this.handle(abandonAssignmentModalActions.RESET_TOAST, this._resetToast);
  }

  isModalActive(): boolean {
    return this.readData('isModalActive');
  }

  getNextPathName(): string {
    return this.readData('nextPathName');
  }

  hasConfirmedWillLeave(): boolean {
    return this.readData('hasConfirmedWillLeave');
  }

  getStudent(): StudentModelV2 {
    return this.getStudentQuery().getResource();
  }

  getStudentQuery(): QueryBuilder {
    const studentId = sessionStore.getUserId();
    return mandarkEndpoint(['students_v2', studentId]);
  }

  async getSubmitPromise(): Promise<StudentModelV2> {
    const submitted = 'now';
    const {assignmentId} = studentAssignmentQuestionsListStore;
    const customMeta = new Map().set(assignmentId, {submitted});
    await this.getStudent()
      .addRelationship({
        type: 'assignments_v2',
        relation: [assignmentId],
        customMeta
      })
      .save();
    this.writeData('shouldAdvanceRouterPostSubmission', true);
  }

  getError(): boolean {
    return this.readData('error');
  }

  getToast(): string {
    return this.readData('toast');
  }

  _resetToast() {
    this.writeData('toast', new Map());
  }

  shouldAdvanceRouterPostSubmission = () => this.readData('shouldAdvanceRouterPostSubmission');
}

export default new AbandonAssignmentModalStore('abandonAssignmentModalStore');
