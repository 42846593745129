import {Store} from 'client/framework';
import {fromJS} from 'immutable';
import {tooltipActions} from './Tooltip.actions';

export default class TooltipStore extends Store {
  constructor(name) {
    super(name);

    this.initialData = fromJS({
      isOpen: false
    });
    this.setInitialData(this.initialData);
    this.handleTargeted(tooltipActions.RESET_STORE, this._resetStore);
    this.handleTargeted(tooltipActions.SET_IS_OPEN, this._setIsOpen);
  }

  _resetStore() {
    this.writeData(this.initialData);
  }

  _setIsOpen(isOpen) {
    this.writeData('isOpen', isOpen);
  }

  getIsOpen() {
    return this.readData('isOpen');
  }
}
