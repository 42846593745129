import {createActions} from 'client/framework';

export default createActions(null, [
  'SET_SORT_DIRECTION',
  'SET_SORT_HEADING',
  'SET_SORT_PARAM',
  'SET_TOTAL_NUMBER_OF_PAGES',
  'SET_HAS_LOADED_DATA',
  'UPDATE_SORT_BY_COLUMN'
]);
