import React from 'react';
import {SecondaryHeader} from 'generic/HorizontalNavigationComponent/HorizontalNavigationComponent.react';

export default function CreateAssignmentSecondaryHeader() {
  return (
    <SecondaryHeader
      identity='CreateAssignment'
      links={null}
      leftContent={null}
      rightContent={null}
    />
  );
}
