import React from 'react';
import {List as VirtualizedList, ListRowProps} from 'react-virtualized';

import Button from '@albert-io/atomic/atoms/Button/Button.react';
import Icon from '@albert-io/atomic/atoms/Icon/Icon.react';

import Dropdown from '../../molecules/Dropdown/Dropdown.react';
import DropdownItem from '../../molecules/DropdownItem/DropdownItem.react';
import './pagination.scss';
import classNames from 'classnames';

interface Props {
  currentPage: number;
  position: string;
  onDropdownItemClick: (page: number) => void;
  totalPages: number;
  paginationDropdownClassName?: string;
}

const PAGE_EL_SIZE = 56;

const MAX_HEIGHT_COEFFICIENT = 8;
const WIDTH_COEFFICIENT = 15;

const MAX_HEIGHT = PAGE_EL_SIZE * MAX_HEIGHT_COEFFICIENT;

const PaginationDropdown = ({
  currentPage,
  position,
  onDropdownItemClick,
  totalPages,
  paginationDropdownClassName
}: Props) => {
  const pageElWidth = totalPages.toString().length * WIDTH_COEFFICIENT;
  const pageElHeight = totalPages * PAGE_EL_SIZE;

  const height = Math.min(pageElHeight, MAX_HEIGHT);
  const width = Math.max(pageElWidth, PAGE_EL_SIZE);

  function rowRenderer({index, key, style}: ListRowProps) {
    return (
      <DropdownItem
        className='o-pagination__dropdown-page'
        key={key}
        onClick={() => {
          onDropdownItemClick(index + 1);
        }}
        selected={currentPage === index + 1}
        style={style}
      >
        {index + 1}
      </DropdownItem>
    );
  }

  return (
    <Dropdown
      className={classNames('o-pagination__dropdown', paginationDropdownClassName)}
      withMinWidth={false}
      position={position}
      trigger={
        <Button
          aria-current
          className='o-pagination__page-button'
          color='secondary'
          roundness='pill'
          size='l'
          variant='text'
        >
          {currentPage} <Icon icon='caret-down' />
        </Button>
      }
    >
      <VirtualizedList
        height={height}
        rowCount={totalPages}
        rowHeight={PAGE_EL_SIZE}
        rowRenderer={rowRenderer}
        width={width}
      />
    </Dropdown>
  );
};

export default PaginationDropdown;
