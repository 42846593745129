import React, {useContext, useRef, useState, useEffect} from 'react';
import classnames from 'classnames';
import {List, Map} from 'immutable';

import {pushQueryParams} from 'client/history';
import {
  addToast,
  Card,
  Dropdown,
  Icon,
  LoadingSpinner,
  Popover,
  WithToggle,
  WithTooltip
} from '@albert-io/atomic';

import type {SubjectModelV2} from 'resources/augmented/Subject/SubjectModel.v2';

import {ReportsContext} from '../../Reports.context';
import {getGuideFromGuideLevelId, getSubjectQuery, getSubjectsQuery} from '../../reports.utils';

import SubjectSelector from './SubjectSelector/SubjectSelector.react';
import '../report.scss';
import ChipButton from './ChipButton';

function SubjectFilter() {
  const {
    topLevelFilterId,
    guideLevel,
    subjectId,
    subjectIdFromSearchParam,
    reportSubjects,
    loading: isReportLoading
  } = useContext(ReportsContext);
  const toggleRef = useRef(null);
  const [didMount, setDidMount] = useState(false);
  const [defaultSelectedItems, setDefaultSelectedItems] = useState<Map<string, any>>(Map());
  const [fetchedSubjects, setFetchedSubjects] = useState<List<SubjectModelV2>>(List());
  const [isReady, setIsReady] = useState(false);

  const hasSubjectDrilldown = !!subjectId;

  useEffect(() => {
    if (didMount === false) {
      setDidMount(true);
    }
  }, [didMount]);

  useEffect(() => {
    async function fetchClassroomSubjects() {
      try {
        if (hasSubjectDrilldown) {
          const subject = await getSubjectQuery(subjectId).getResourcePromise();
          setFetchedSubjects(List([subject]));
        } else {
          const subjects = await getSubjectsQuery(reportSubjects);
          setFetchedSubjects(subjects);
        }

        setDefaultSelectedItems(Map()); // reseting the default selected items to empty
        setIsReady(true);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('error from SubjectFilter component', error);
        addToast({
          title: 'Something went wrong',
          color: 'negative',
          message:
            'There was a problem loading your classroom subjects. If the problem persists, contact us.'
        });
      }
    }
    if (didMount && !isReportLoading) {
      fetchClassroomSubjects();
    }
  }, [topLevelFilterId, didMount, hasSubjectDrilldown, subjectId, isReportLoading, reportSubjects]);

  useEffect(() => {
    if (isReady && (subjectId || subjectIdFromSearchParam) && fetchedSubjects) {
      const matchingSubject = hasSubjectDrilldown
        ? fetchedSubjects.find((s) => s.getId() === subjectId)
        : fetchedSubjects.find((s) => s.getId() === subjectIdFromSearchParam);
      setDefaultSelectedItems((items) => items.set('subject', matchingSubject));

      if (guideLevel.length === 1) {
        const currentGuideLevel = guideLevel[0];
        const matchingGuide = getGuideFromGuideLevelId(matchingSubject, currentGuideLevel);

        const matchingGuideLevel = matchingGuide
          .getGuideLevels()
          .getCollection()
          .find((g) => g.getId() === currentGuideLevel);

        let level = matchingGuideLevel;

        let idTrail = List();
        const predicate = (g) => g.getId() === level.getParentId();

        while (level.getParentId?.()) {
          idTrail = idTrail.push(level.getParentId());
          level = matchingGuide.getGuideLevels().getCollection().find(predicate);
        }

        setDefaultSelectedItems((items) =>
          items
            .set('guideLevel', matchingGuideLevel)
            .set('guide', matchingGuide)
            .set('idTrail', idTrail)
        );
      } else if (guideLevel.length > 1) {
        const matchingGuide = getGuideFromGuideLevelId(matchingSubject, guideLevel[0]);

        setDefaultSelectedItems((items) => items.set('guide', matchingGuide));
      } else {
        setDefaultSelectedItems((items) => items.set('guide', null).set('guideLevel', null));
      }
    }
  }, [
    isReady,
    fetchedSubjects,
    subjectId,
    guideLevel,
    hasSubjectDrilldown,
    subjectIdFromSearchParam
  ]);

  return (
    <WithToggle dismissOnEscape dismissOnBlur key='reports-filter-subject'>
      {({on, onClick}) => (
        <>
          <WithTooltip
            enabled={isReady && !fetchedSubjects.size}
            content='No subjects associated with this class.'
            placement='bottom'
          >
            <ChipButton
              onClick={onClick}
              buttonRef={toggleRef}
              disabled={!isReady || !fetchedSubjects.size}
            >
              {!subjectId && !subjectIdFromSearchParam ? (
                <span>Subjects &amp; Topics</span>
              ) : (
                <span>1 Subject</span>
              )}
              <Icon icon='caret-down' />
            </ChipButton>
          </WithTooltip>

          {/* todo: add the loading state into  */}
          {!isReady && didMount && on ? (
            <Dropdown.Tray
              className='reports-filter__dropdown-tray'
              positionFixed
              placement='bottom-start'
              target={toggleRef.current}
            >
              <Card className='reports-filter__dropdown reports-filter__dropdown--subjects reports-filter__dropdown--subjects--loading u-display_flex u-justify-content_center u-align-items_center'>
                <LoadingSpinner assistiveText='Gathering details about your subjects...' size={2} />
              </Card>
            </Dropdown.Tray>
          ) : null}
          {isReady && didMount && on ? (
            <Popover
              usePortal={false}
              border='none'
              expanded={on}
              position='bottom-start'
              paddingLevel={null}
              targetRef={toggleRef}
              modifiers={{
                flip: {enabled: false},
                preventOverflow: {
                  boundariesElement: 'viewport' // or 'window'
                }
              }}
            >
              <SubjectSelector
                subjects={fetchedSubjects}
                className={classnames(
                  'reports-filter__dropdown--subjects reports-filter__dropdown u-display_block reports-filter__dropdown--open'
                )}
                defaultSelectedItems={defaultSelectedItems}
                onRemoveFilter={() => {
                  pushQueryParams({subject: null, guideLevel: null, guide: null});
                }}
                closeModal={onClick}
                onApply={(items) => {
                  const sortedItems = items.delete('idTrail').reduce(
                    (accumulator, currentValue, key) => {
                      if (
                        typeof accumulator === 'undefined' ||
                        typeof currentValue === 'undefined' ||
                        typeof key === 'undefined'
                      ) {
                        return {guide: null, guideLevel: null, subject: null};
                      }

                      const newAccumulator = {...accumulator};
                      if (currentValue) {
                        newAccumulator[key] = currentValue.getId();

                        if (key === 'guide') {
                          const guideLevelIds = currentValue
                            .getGuideLevels()
                            .getCollection()
                            .map((item) => item.getId())
                            .toJS();

                          newAccumulator.guideLevel = guideLevelIds;
                        }
                      } else {
                        newAccumulator[key] = null;
                      }

                      return newAccumulator;
                    },
                    {guide: null, guideLevel: null, subject: null}
                  );
                  pushQueryParams(sortedItems);
                }}
              />
            </Popover>
          ) : null}
        </>
      )}
    </WithToggle>
  );
}

export default SubjectFilter;
