import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import './app-portal.scss';

export class AppPortal extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    usePortal: PropTypes.bool
  };

  static defaultProps = {
    className: '',
    usePortal: true
  };

  constructor(props) {
    super(props);

    this.state = {
      didMount: false
    };
  }

  componentDidMount = () => {
    if (!this.state.didMount) {
      this.setState({didMount: true});
    }
    this.el = global.document.createElement('div');
    this.rootElement = global.document.getElementById('portal');
    this.rootElement.appendChild(this.el);
  };

  componentWillUnmount = () => {
    this.rootElement.removeChild(this.el);
  };

  stopPropagation = (e) => {
    e.stopPropagation();
  };

  onKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
    }
  };

  render() {
    const {children, className, usePortal} = this.props;
    if (!this.el) {
      return null;
    }
    if (!usePortal) {
      return (
        <div className='app-portal' onKeyPress={this.onKeyPress} onClick={this.stopPropagation}>
          {children}
        </div>
      );
    }
    if (className) {
      this.el.setAttribute('class', this.props.className);
    }
    return ReactDOM.createPortal(
      <div onKeyPress={this.onKeyPress} onClick={this.stopPropagation}>
        {children}
      </div>,
      this.el
    );
  }
}
