/**
 * This function simluates an 'onTap' event, which it defines as a `'touchstart'` followed
 * by a `'touchend'` that occurs on the same target within the span of 500 ms. Use it with
 * the `onTouchStart` attribute.
 * @example
 * <button onTouchStart={(e) => {
 *   handleTap(e.target, () => {
 *     console.log('You tapped me');
 *   });
 * }}>
 *   Tap me
 * </button>
 *
 * @param {global.Element} target
 * @param {() => void} callback
 */
export function handleTap(target, callback) {
  const touchEndHandler = (innerEvent) => {
    const isTap = [...innerEvent.changedTouches].some((touch) => {
      const {clientX, clientY} = touch;
      const element = global.document.elementFromPoint(clientX, clientY);
      return element && element.contains(target);
    });
    if (!isTap) {
      return;
    }
    target.removeEventListener('touchend', touchEndHandler);
    callback(innerEvent);
  };
  target.addEventListener('touchend', touchEndHandler);
  setTimeout(() => {
    target.removeEventListener('touchend', touchEndHandler);
  }, 500);
}
