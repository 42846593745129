import React, {ElementType} from 'react';

import Text from '../../../atoms/Text/Text.react';
import './description.scss';

export interface Props extends PropsWithChildrenOptional {
  as?: ElementType;
}

export const Description = ({as, children, ...rest}: Props) => (
  <Text color='inherit' paragraph as={as} className='o-wall__description' {...rest}>
    {children}
  </Text>
);
