import {useCallback, useState} from 'react';

/**
 * @typedef {import('immutable').List} List
 *
 * @typedef {object} ArrowNavigation
 * @property {number} cursor Index of the currently focused item
 * @property {Function} onKeyDown Keydown handler for moving focus between items
 *
 * @param {List<*>} items - list of navigable items, needed only for initiating the size of our array
 * @returns {ArrowNavigation} - object instance of type ArrowNavigation
 */
export default function useArrowNavigation(items) {
  const [cursor, setCursor] = useState(0);

  const onKeyDown = useCallback(
    (e, currentIndex) => {
      if (e.key === 'ArrowRight') {
        e.preventDefault();
        const isLastItem = currentIndex === items.size - 1;
        const nextIdx = currentIndex + 1;
        const idxToSet = isLastItem ? 0 : nextIdx;
        setCursor(idxToSet);
      } else if (e.key === 'ArrowLeft') {
        e.preventDefault();
        const isFirstItem = currentIndex === 0;
        const nextIdx = currentIndex - 1;
        const idxToSet = isFirstItem ? items.size - 1 : nextIdx;
        setCursor(idxToSet);
      }
    },
    [items]
  );

  return {
    cursor,
    onKeyDown
  };
}
