/* eslint-disable import/no-extraneous-dependencies */
// @flow
import {getHost} from '@albert-io/environment';
import {getRequestAgent} from '@albert-io/mandark/authentication';
import {Map} from 'immutable';

import {resource, type QueryBuilder} from '@albert-io/json-api-framework/request/builder';
import type {TeacherModelV1} from 'resources/augmented/Teacher/TeacherModel.v1';
import {SchoolModelV4} from 'resources/GeneratedModels/School/SchoolModel.v4';

import {
  getIntercomAppId,
  withIntercom,
  shutdownIntercom,
  isIntercomCompatibleDevice
} from './index';

/**
 * Converts our school model to an Intercom "company" (POJO)
 */
function convertSchoolToCompany(school: SchoolModelV4): Object {
  return {
    id: school.getId(),
    name: school.getName(),
    district: school.getDistrict(),
    type: school.getSchoolType(),
    created_at: school.getInsertedAt().unix()
  };
}

let sessionUserId;
let sessionHash;

/**
 * Converts our TeacherModelV1 to a POJO for Intercom
 */
async function getIntercomUserObject(user: TeacherModelV1): Promise<*> {
  /**
   * If there isn't a sessionEmail value, it's an authenticated, isomorphic
   * load... the user_hash injected via the renderer is expected to be the
   * proper, authenticated user's hash.
   */
  if (!sessionHash || (sessionUserId && sessionUserId !== user.getId())) {
    sessionUserId = user.getId();
    const host = getHost();
    try {
      const response = await getRequestAgent().get(`${host}/intercom/user_hash`);
      if (response.ok) {
        sessionHash = response.body.user_hash;
      }
    } catch (e) {
      // intentional suppression
    }
  } else {
    sessionUserId = user.getId();
  }
  const baseMetrics = {
    app_id: getIntercomAppId(),
    /**
     * intercom attributes
     * @see https://developers.intercom.com/installing-intercom/docs/javascript-api-attributes-objects#section-data-attributes
     */
    email: user.getEmail(),
    user_id: user.getId(),
    name: user.getDisplayName(),
    company: null,
    created_at: user.getInsertedAt().unix(),
    user_hash: sessionHash,
    /**
     * custom attributes
     */
    username: user.getUsername(),
    salutation: user.getSalutation(),
    active_student_count: user.getMeta().getCountOfActiveStudents(),
    active_school_license: user.getMeta().hasActiveSchoolLicense()
  };
  if (!user.getSchool().isEmpty()) {
    baseMetrics.company = convertSchoolToCompany(user.getSchool());
  }
  /**
   * Metrics that we just pass-thru from the `intercom_stats=true` context.
   */
  const metricsFromServer = user
    .getIn(['meta', 'intercom_stats'], Map())
    .reduce((reduction, value, key) => {
      /**
       * `guess_subjects` is a bit of a special case at the moment because the attribute names that we wind up
       * sending to Intercom are found in a Map.
       */
      if (key === 'guess_subjects') {
        const allGuessSubjects = [];
        value.forEach((v, k) => {
          /**
           * We also accumulate the `all_guess_subjects` since this is not provided by the API.
           */
          if (k !== 'guess_subjects_0' && v) {
            allGuessSubjects.push(v);
          }
          reduction = reduction.set(k, v || '');
        });
        reduction = reduction.set('all_guess_subjects', allGuessSubjects.join(', '));
      } else {
        reduction = reduction.set(key, value);
      }
      return reduction;
    }, Map());

  return {
    ...baseMetrics,
    ...metricsFromServer.toJS()
  };
}
/**
 * The Mandark query used to fetch the required information for Intercom
 */
function getUserQuery(id: string): QueryBuilder {
  return resource('teacher_v1')
    .mandarkEndpoint(['teachers_v1', id])
    .include('school_v4')
    .withMeta('teacher_v1')
    .meta({
      context: {
        teacher: {
          id,
          intercom_stats: true
        }
      }
    });
}

/**
 * A generic Intercom boot (without a user) for logged out users to occur on start-up.
 */
function bootIntercom() {
  shutdownIntercom();
  withIntercom((Intercom) => {
    Intercom('boot', {
      app_id: getIntercomAppId()
    });
  }, isIntercomCompatibleDevice());
}

function bootIntercomUser(data: {
  user: {id: string},
  session: {
    hasTeacherAccess: boolean,
    isSuper: boolean
  }
}) {
  shutdownIntercom();
  withIntercom(async (Intercom) => {
    let user;
    try {
      user = await getUserQuery(data.user.id).getResourcePromise();
    } catch (e) {
      /**
       * If the request fails, it means the user isn't a teacher... or something else
       * went wrong and we don't want to "boot" Intercom.
       */
      return;
    }
    Intercom('boot', await getIntercomUserObject(user));
  }, isIntercomCompatibleDevice() && data.session.hasTeacherAccess && data.session.isSuper === false);
}

export const IntercomService = {
  bootIntercom,
  bootIntercomUser,
  shutdownIntercom
};
