/**
 * @typedef {import('../../albert-io/json-api-framework/request/builder').QueryBuilder} QueryBuilder
 */

/**
 * @typedef {Object} ReturnValue
 * @property {boolean} isReady - Whether or not all queries are populated
 * @property {*[]} resources - An array of resources in the order that they were passed in
 */

/**
 * @param {QueryBuilder[]} queries
 * @returns {ReturnValue}
 */
const useMandarkQueries = (queries) => {
  const isReady = queries.every((query) => query.isResourcePopulated());
  const resources = queries.map((query) => query.getResource());

  return {
    isReady,
    resources
  };
};

export default useMandarkQueries;
