/* eslint-disable */


// Programatically generated on Mon Mar 31 2025 17:10:40 GMT+0000
import {fromJS, List, Map, Record, Set} from 'immutable';
import {GenericModel} from 'resources/Generic.model';
import {validate, Empty} from '@albert-io/json-api-framework/models/helpers';
import moment from 'moment';

const ModelConfig = fromJS(
  {
    'endpoint': 'authoring_guides_v1',
    'attributes': {
      'published': {
        'type': 'boolean',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'name': {
        'type': 'string',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'guide_type': {
        'type': 'string',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'updated_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'inserted_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'id': {
        'type': 'binary_id',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      }
    },
    'relationships': {
      'authoring_guide_levels_v1': {
        'type': 'one-to-many'
      },
      'authoring_subject_v1': {
        'type': 'one-to-one'
      },
      'guide_levels_v2': {
        'type': 'one-to-many'
      },
      'subject_v1': {
        'type': 'one-to-one'
      },
      'subject_v2': {
        'type': 'one-to-one'
      }
    }
  }
);

const ModelRecord = Record(
  {
    'authoring_subject': Empty,
    'name': Empty,
    'subject': Empty,
    'published': Empty,
    'guide_levels': Empty,
    'updated_at': Empty,
    'inserted_at': Empty,
    'authoring_guide_levels': Empty,
    'id': Empty,
    'relationshipMeta': Empty,
    'guide_type': Empty,
    'meta': new Map({
    'populated_metadata_fields': Empty,
    'structure': Empty
  })
  }
);

const DefaultModel = fromJS(
  {
    'name': '',
    'id': '',
    'guide_type': '',
    'updated_at': new Date(),
    'inserted_at': new Date(),
    'guide_levels': new List(),
    'authoring_guide_levels': new List(),
    'authoring_subject': new Map(),
    'meta': new Map(),
    'subject': new Map(),
    'published': false,
    'relationshipMeta': new Map()
  }
);

export class AuthoringGuideModelV1 extends GenericModel(ModelRecord, ModelConfig, 'AuthoringGuideModelV1') {
  // -- Query Rules --
  /**
   * @returns {boolean}
   */
  static allowsInclude(include) {
    // AllowedIncludes is currently disabled.
    return true; // AllowedIncludes.has(include);
  }

  /**
   * @returns {boolean}
   */
  static allowsFilter(filterField, filterType) {
    return filterType ?
      FilterRules.get(filterField).has(filterType) :
      FilterRules.has(filterField);
  }

  /**
   * @returns {boolean}
   */
  static allowsSort(sort) {
    return sort.split(',').filter((s) => AllowedSorts.has(s)).length > 0;
  }

  /**
   * @returns {Array}
   */
  static getAllowedSorts() {
    return AllowedSorts.keySeq().toJS();
  }

  static getMetaContextQuery(key) {
    if (!MetadataContextRules.hasIn(['optional', key]) && !MetadataContextRules.hasIn(['required', key])) {
      console.error('Rule for meta context key ' + key + ' does not exist');
    }

    return MetadataContextRules.getIn(['optional', key]) || MetadataContextRules.getIn(['required', key]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getRequiredMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values()]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getAllMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values(), ...MetadataContextRules.get('optional').values()]);
  }

  static getDefaultModel() {
    _emptyAuthoringGuideModelV1Model = _emptyAuthoringGuideModelV1Model ? _emptyAuthoringGuideModelV1Model : new AuthoringGuideModelV1(DefaultModel);
    return _emptyAuthoringGuideModelV1Model;
  }

  static supportsMetaContext() {
    return MetadataContextRules !== null;
  }

  /**
   * @returns {string}
   */
  static getModelName() {
    return 'AuthoringGuideModelV1';
  }

  /**
   * @returns {boolean}
   */
  static _isFullSpec() {
    return true;
  }

  constructor() {
    super(...arguments);
    this.__meta = null;
  }

  /**
   * @returns {string}
   */
  getJsonApiType() {
    return 'authoring_guide_v1';
  }

  /**
   * @params {string} - An attribute name
   * @params {*} [def] - Optional default value override for the attribute
   */
  getValueForAttribute(attribute, def) {
    let value = this.get(attribute);
    validate(value, attribute);
    if (value === Empty) {
      value = def !== undefined ? def : DefaultModel.get(attribute);
    }
    return value;
  }

  // -- Primary Attributes --
  
  /**
   * @type {string}
   */
  getGuideType() {
    const value = this.getValueForAttribute('guide_type');
    return value;
  }

  setGuideType(value) {
    return this.setField('guide_type', value);
  }

  /**
   * @type {binary_id}
   */
  getId() {
    const value = this.getValueForAttribute('id');
    return value;
  }

  setId(value) {
    return this.setField('id', value);
  }

  /**
   * @type {datetime}
   */
  getInsertedAt() {
    const value = this.getValueForAttribute('inserted_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {string}
   */
  getName() {
    const value = this.getValueForAttribute('name');
    return value;
  }

  setName(value) {
    return this.setField('name', value);
  }

  /**
   * @type {boolean}
   */
  isPublished() {
    const value = this.getValueForAttribute('published');
    return value;
  }

  setPublished(value) {
    return this.setField('published', value);
  }

  /**
   * @type {datetime}
   */
  getUpdatedAt() {
    const value = this.getValueForAttribute('updated_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  // -- Meta Attributes --
  
    /**
     * LEGACY META ACCESSORS
     * @todo These should no longer be used, instead use this.getMeta()[...]
     */
    
      getPopulatedMetadataFields() {
        return this.getMeta().getPopulatedMetadataFields();
      }
    
      getStructure() {
        return this.getMeta().getStructure();
      }
    
    getMeta() {
      if (this.__meta) {
        return this.__meta;
      }
      const meta = this.get('meta', Map());
      this.__meta = {
        
          /**
           * @type {string[]}
           */
          getPopulatedMetadataFields() {
            const value = meta.get('populated_metadata_fields');
            validate(value, 'populated_metadata_fields');
            return value;
          },
  
          /**
           * @type {map}
           */
          getStructure() {
            const value = meta.get('structure');
            validate(value, 'structure');
            return value;
          },
  
      };
      return this.__meta;
    }
  
  // -- Relationships --
  
  /**
   * @type {authoring_guide_level_v1 (many)}
   */
  getAuthoringGuideLevels() {
    const value = this.getValueForAttribute('authoring_guide_levels');
    return value;
  }

  // -- Relationship Fields: authoring_guide_levels --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {authoring_subject_v1 (one)}
   */
  getAuthoringSubject() {
    const value = this.getValueForAttribute('authoring_subject');
    return value;
  }

  // -- Relationship Fields: authoring_subject --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {guide_level_v2 (many)}
   */
  getGuideLevels() {
    const value = this.getValueForAttribute('guide_levels');
    return value;
  }

  // -- Relationship Fields: guide_levels --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {subject_v2 (one)}
   */
  getSubject() {
    const value = this.getValueForAttribute('subject');
    return value;
  }

  // -- Relationship Fields: subject --
  // no 'fields' found, skipped generating accessor(s)
  
}

// -- Empty Model Instance --
let _emptyAuthoringGuideModelV1Model = null;

// -- Sort Rules --

const AllowedSorts = fromJS({
  'guide_type': null,
  'id': null,
  'inserted_at': null,
  'name': null,
  'published': null,
  'updated_at': null,
});

// -- Include Rules --
// List []

// -- Filter Rules --
const FilterRules = new Map({
  'authoring_guide_levels_v1': new Set(['associated']),
  'authoring_subject_v1': new Set(['associated']),
  'guide_levels_v2': new Set(['associated']),
  'guide_type': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix']),
  'id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'inserted_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.populated_metadata_fields': new Set(['null']),
  'name': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'published': new Set(['exact','not','null']),
  'subject_v1': new Set(['associated']),
  'subject_v2': new Set(['associated']),
  'updated_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
});
    

// -- Metadata Context Rules --
const MetadataContextRules = fromJS({
  optional: {
  },
  required: {
  }
});

// -- Legacy Name Export --
export const AuthoringGuide = AuthoringGuideModelV1;
