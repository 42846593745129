import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import Select from 'react-select';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {Icon} from '@albert-io/atomic';
import {callTargetedAction} from 'client/framework';
import fillInTheBlankQuestionActions from 'client/QuestionTypes/FillInTheBlank/V2/FillInTheBlankQuestion.actions';
import FillInTheBlankQuestionStore from 'client/components/QuestionTypes/FillInTheBlank/FillInTheBlankQuestion.store';
import forceUpdateOnStateChange from 'lib/hocs/forceUpdateOnStateChange';
import {QuestionModelV3} from 'resources/augmented/Question/QuestionModel.v3';

import './fitb-dropdown.scss';

function FitbItem({choices, dropdownId, store, isGuessSubmitted, isExplanation, question}) {
  return isExplanation ? (
    <FitbPresentationalItem
      question={question}
      store={store}
      dropdownId={dropdownId}
      choices={choices}
    />
  ) : (
    <FitbDropdown
      choices={choices}
      dropdownId={dropdownId}
      store={store}
      isGuessSubmitted={isGuessSubmitted}
      isExplanation={isExplanation}
      question={question}
    />
  );
}

FitbItem.propTypes = {
  choices: ImmutablePropTypes.list,
  dropdownId: PropTypes.string,
  store: PropTypes.instanceOf(FillInTheBlankQuestionStore),
  isGuessSubmitted: PropTypes.bool,
  isExplanation: PropTypes.bool,
  question: PropTypes.instanceOf(QuestionModelV3)
};

function FitbDropdown({choices, dropdownId, store, isGuessSubmitted, question}) {
  // Format dropdown options so they jive with `react-select`
  const options = useMemo(() => {
    return choices.toJS().map((choice) => ({
      value: choice.id,
      label: choice.value
    }));
  }, [choices]);

  const selectedChoiceId = store.getSelectedChoiceForDropdown(dropdownId) || undefined;

  const selectedValue =
    (selectedChoiceId && options.find(({value: choiceId}) => selectedChoiceId === choiceId)) ||
    null;

  // Temporary hack to make differently named meta keys work
  // eslint-disable-next-line no-underscore-dangle
  const rubricMetaKey = question.__legacy ? 'rubric' : 'question_rubric';
  const validResponse = isGuessSubmitted
    ? question.getIn(['meta', rubricMetaKey, 'validResponse'], null)
    : null;
  const isCorrectAnswer =
    validResponse && !validResponse.isEmpty() && validResponse.get(dropdownId) === selectedChoiceId;

  return (
    <Select
      className={classnames('fitb-select-container', {
        'fitb-select-container--has-selection': !!selectedValue
      })}
      classNamePrefix='fitb-select'
      components={{
        DropdownIndicator: () => (
          <Icon
            className='fitb-select-indicator'
            {...(() => {
              if (!isGuessSubmitted) {
                return {icon: 'caret-down'};
              }
              return {
                icon: isCorrectAnswer ? 'check' : 'times',
                color: isCorrectAnswer ? 'positive' : 'negative'
              };
            })()}
          />
        )
      }}
      formatOptionLabel={(option) => {
        return <MarkdownRendererV2 text={option.label} />;
      }}
      isDisabled={isGuessSubmitted}
      isSearchable={false}
      name='color'
      onChange={({value: choiceId}) => {
        callTargetedAction({
          name: fillInTheBlankQuestionActions.SELECT_OPTION,
          payload: {
            choiceId,
            dropdownId
          },
          targetStore: store.getName()
        });
      }}
      options={options}
      placeholder='Select Option'
      styles={{menu: (base) => ({...base, zIndex: 2})}}
      value={selectedValue}
    />
  );
}

FitbDropdown.propTypes = {
  choices: ImmutablePropTypes.list,
  dropdownId: PropTypes.string,
  store: PropTypes.instanceOf(FillInTheBlankQuestionStore),
  isGuessSubmitted: PropTypes.bool,
  question: PropTypes.instanceOf(QuestionModelV3)
};

function FitbPresentationalItem({question, store, dropdownId, choices}) {
  const correctAnswers = question.getValidResponse().isEmpty()
    ? store.validResponse
    : question.getValidResponse();
  const correctChoiceId = correctAnswers.get(dropdownId);
  const correctChoice = choices.find((choice) => choice.getId() === correctChoiceId).getValue();

  return <MarkdownRendererV2 className='fitb-presentational-item' text={correctChoice} />;
}

FitbPresentationalItem.propTypes = {
  choices: ImmutablePropTypes.list,
  dropdownId: PropTypes.string,
  store: PropTypes.instanceOf(FillInTheBlankQuestionStore),
  question: PropTypes.instanceOf(QuestionModelV3)
};

export default forceUpdateOnStateChange(FitbItem);
