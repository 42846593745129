import {Map} from 'immutable';
import QuestionTypeStore from 'components/QuestionTypes/QuestionType.store';

import graphingQuestionActions from './GraphingQuestion.actions';

export default class GraphingQuestionStore extends QuestionTypeStore {
  constructor(name, questionId, question) {
    super(name);

    this.initialData = Map({
      ...this.draftGuessInitialData,
      guessEntities: [],
      questionId
    });

    this.setInitialData(this.initialData);
    this.writeData('__question', question);

    this.handleTargeted(graphingQuestionActions.SET_GUESS_ENTITIES, this.setGuessEntities);
  }

  afterLoadDraftGuess(draftGuess) {
    this.writeData('guessEntities', draftGuess.getContent().get('entities').toJS());
  }

  buildGuessContent() {
    return {
      entities: this.getGuessEntities()
    };
  }

  getGuessEntities() {
    return this.readData('guessEntities');
  }

  setGuessEntities(guessEntities) {
    this.writeData('guessEntities', guessEntities);
    super.kickoffDebouncedSaveDraftGuess();
  }
}
