/**
 * # ExpandableMenuListItem
 *
 * This is a menu list item that has expanding abilities to show children.
 *
 * ## Props
 *
 * - `id: string` - The unique identifier for the menu list item.
 * - `text?: string` - The text content of the menu list item.
 * - `children: React.ReactNode` - The content to be displayed when the menu list item is expanded.
 *
 * @see [Design ticket]{@link https://github.com/albert-io/project-management/issues/2780}
 *
 * ## Usage
 *
 * ```tsx
 * import React from 'react';
 * import ExpandableMenuListItem from './ExpandableMenuListItem';
 *
 * const Example = () => {
 *   return (
 *     <ExpandableMenuListItem id="menu-item-1" text="Menu Item 1">
 *       <div>Content for Menu Item 1</div>
 *     </ExpandableMenuListItem>
 *   );
 * };
 * ```
 */

import React, {ElementType} from 'react';

import WithToggle from '../../helpers/WithToggle/WithToggle.react';
import Expandable from '../../helpers/Expandable/Expandable.react';
import MenuListItem, {
  CommonProps,
  ConditionalButtonClickProps,
  ConditionalTextProps
} from '../../atoms/MenuListItem/MenuListItem.react';

interface AddtionalProps {
  defaultOnState?: boolean;
  expandOnMenuItemClick?: boolean;
}

type Props<C extends ElementType> = PolymorphicComponentProps<
  C,
  CommonProps & Omit<ConditionalButtonClickProps, 'onIconButtonClick'> & ConditionalTextProps
> &
  AddtionalProps;

export default function ExpandableMenuListItem<C extends ElementType = 'div'>({
  as,
  children,
  defaultOnState = false,
  expandOnMenuItemClick = false,
  id,
  onMenuItemClick,
  text,
  ...rest
}: Props<C>) {
  const WrapperElement = as ?? 'div';
  const buttonId = `expandable-button-${id}`;
  const expandableId = `expandable-area-${id}`;
  const iconButtonProps = {
    id: buttonId,
    'aria-controls': expandableId
  };
  return (
    // For some reason, wrapping MenuListItemProps with Omit causes the `as` prop to bug out.
    // This discussion looks related, but also looks like hot garbage:
    // https://github.com/microsoft/TypeScript/issues/50896
    <WrapperElement>
      <WithToggle defaultOnState={defaultOnState} dismissOnBlur={false}>
        {({onClick, on}) => (
          <div>
            <MenuListItem
              icon={on ? 'chevron-down' : 'chevron-left'}
              iconButtonProps={iconButtonProps}
              id={buttonId}
              text={text}
              onIconButtonClick={onClick}
              onMenuItemClick={() => {
                onMenuItemClick?.();

                if (expandOnMenuItemClick && !on) {
                  onClick();
                }
              }}
              {...rest}
            />
            <Expandable aria-labelledby={buttonId} expanded={on} id={expandableId}>
              {children}
            </Expandable>
          </div>
        )}
      </WithToggle>
    </WrapperElement>
  );
}
