import {isPlainObject} from 'lodash';

const sizes = ['all', 'xl', 'l', 'm', 's', 'xs', 'xxs'];

/**
 * @param {Object} obj
 * @param {Array} arr
 *
 * backfiller returns an object with breakpoints for
 * keys and the corresponding value for each breakpoint
 * as a value. Where no value was provided, it backfills
 * that breakpoint with the value that _would_ apply in the
 * ordinary course of the CSS cascade.
 *
 * @example
 * backfiller({xl: 4, l: 2, xs: 1}, sizes); // {xl: 4, l: 2, m: 2, s: 2, xs: 1, xxs: 1}
 */

function backfiller(obj, arr) {
  return arr.reduce((prev, curr, i) => {
    if (obj[curr]) {
      prev[curr] = obj[curr];
    } else {
      prev[curr] = prev[arr[Math.max(0, i - 1)]];
    }
    return prev;
  }, {});
}

function getStem(str) {
  return str !== 'all' ? `${str}-` : '';
}

export function parseColProps(colGap) {
  colGap = isPlainObject(colGap) ? colGap : {all: colGap};
  return backfiller(colGap, sizes);
}

export function getClassNamesForCol(
  {dangerouslySetColGap: colGap, ...props},
  selector = 'a-grid__col'
) {
  const keyedColGaps = parseColProps(colGap);
  const keyedCols = backfiller(props, sizes);
  return sizes.reduce((prev, curr, i) => {
    const stem = getStem(curr);
    return prev + `${selector}--${stem}${keyedCols[curr]}--col-gap-${stem}${keyedColGaps[curr]} `;
  }, '');
}

export function getClassNamesForRow(colGap, selector = 'a-grid__row') {
  const keyedColGaps = parseColProps(colGap);
  return sizes.reduce((prev, curr, i) => {
    const stem = getStem(curr);
    return prev + `${selector}--col-gap-${stem}${keyedColGaps[curr]} `;
  }, '');
}
