// @flow
import {fromJS, Set, Map} from 'immutable';
import QuestionTypeStore from 'client/QuestionTypes/common/V2/QuestionType.store';
import {activeQuestionActions} from 'client/InteractionEngineV2/shared/ActiveQuestion/ActiveQuestionActions';
import passageCorrectionQuestionActions from './PassageCorrectionQuestion.actions';
import {GuessModel} from 'resources/Guess/Guess.model';
import questionTypeActions from 'components/QuestionTypes/questionType.actions';

export default class PassageCorrectionQuestionStore extends QuestionTypeStore {
  constructor(name: string, questionId: string, question) {
    super(name);
    this.setInitialData(
      fromJS({
        questionId: questionId,
        selectedOptions: new Set(),
        shouldShowExplanation: false,
        corrections: {},
        initialCorrections: null,
        activeOption: null
      })
    );
    this.writeData('__question', question);
    this.writeData('selectedOptions', new Set());
    this.handleTargeted(questionTypeActions.CLEAR_GUESS, this._clearGuess);
    this.handleTargeted(questionTypeActions.LOAD_GUESS, this._loadGuess);
    this.handleTargeted(activeQuestionActions.ACTIVE_QUESTION_CLEAR_GUESS, this._clearGuess);
    this.handleTargeted(activeQuestionActions.ACTIVE_QUESTION_LOAD_GUESS, this._loadGuess);
    this.handleTargeted(activeQuestionActions.ACTIVE_QUESTION_SUBMIT_ANSWER, this._submitAnswer);
    this.handleTargeted(
      passageCorrectionQuestionActions.COMMIT_CORRECTION_CHANGE,
      this._commitCorrectionChange
    );
    this.handleTargeted(
      passageCorrectionQuestionActions.REMOVE_ACTIVE_OPTION_CORRECTION,
      this._removeActiveOptionCorrection
    );
    this.handleTargeted(
      passageCorrectionQuestionActions.SET_INITIAL_CORRECTIONS,
      this.setProperty('initialCorrections')
    );
    this.handleTargeted(
      passageCorrectionQuestionActions.SET_ACTIVE_OPTION,
      this.setProperty('activeOption')
    );
  }

  isOptionCorrect(optionId: string): boolean {
    return this.getCorrectAnswers().includes(optionId);
  }

  getCorrectAnswers(): Set<string> {
    return this.validResponse.keySeq().toSet();
  }

  buildGuessContent(): Object {
    const corrections = this.getCorrections();
    const initialCorrections = this.getInitialCorrections();
    if (initialCorrections === null || corrections.isEmpty()) {
      return {};
    }
    /**
     * Sorry this is hard to read, I tried splitting this up into smaller functions
     * but I need the closure from the outer reduce in the inner reduce, so it was
     * difficult to do that.
     *
     * Here's what's happening:
     * When we initialize our question, we go through each region, and copy the words which
     * make up that region to our store (initialCorrections).
     *
     * As the student goes and makes corrections, we hold onto those corrections in a separate
     * Map (corrections). When the student goes to submit the guess, we take the initialCorrections,
     * and for every region, we reduce the entries in the region down to a string.
     *
     * We do this by seeing if there is an entry for that index in the corrections. If there is,
     * we use its value to build our string, otherwise we use the initialCorrection.
     */
    return corrections.reduce((acc, regionCorrections, regionKey) => {
      const regionString = initialCorrections
        .get(regionKey)
        .sortBy((val, optionKey) => optionKey)
        .reduce((acc, initialCorrection, optionKey) => {
          const indexString = regionCorrections.has(optionKey)
            ? regionCorrections.get(optionKey)
            : initialCorrection;
          const trimmed = indexString
            .trim()
            .split(/\s+/)
            .join(' ');
          acc.push(trimmed);
          return acc;
        }, [])
        .join(' ');
      acc[regionKey] = regionString;
      return acc;
    }, {});
  }

  getActiveCorrectedValue(): string {
    if (this.getActiveOption() === null) {
      return '';
    }
    const {activeOption, activeRegion} = this.getActiveOptionAndRegion();
    return this.getCorrections().getIn([activeRegion, activeOption], '');
  }

  getActiveOriginalValue(): string {
    const initialCorrections = this.getInitialCorrections();
    return initialCorrections !== null
      ? initialCorrections.flatten().get(this.getActiveOption(), '')
      : '';
  }

  buildCorrectGuess(): GuessModel {
    const question = this._fetchQuestion();
    return new GuessModel({
      content: question.getValidResponse()
        .filter((region) => region.get('solutions'))
        .map((region) => region.get('solutions').first()),
      correct: true,
      rubric: question.getRubric(),
      solutionText: question.getSolutionText(),
      pointsPossible: question.getPoints(),
      pointsEarned: question.getPoints(),
      question_v1: question
    });
  }

  _convertGuessToCorrections(guess: GuessModel): Map<string, Map<number, string>> {
    return guess.getContent().map((regionContent) => {
      return regionContent.split(/\s/).reduce((acc, word, i) => acc.set(i, word), Map());
    });
  }

  _loadGuess(guess: GuessModel) {
    // Parent store needs points data from guess and needs to register this question has a guess
    super._loadGuess(guess);
    const guessContentAsCorrections = this._convertGuessToCorrections(guess);
    this.writeData('corrections', guessContentAsCorrections);
  }

  _clearGuess() {
    // Need to call this on the parent class
    super._clearGuess();
    this.resetProperties('corrections');
  }

  async _submitAnswer(payload: {string: any}): Promise<*> {
    /**
     * @todo Babel does not currently support super in async functions (https://github.com/babel/babel/issues/5784)
     * Update this to use super rather than prototype once Babel can support it.
     */
    await QuestionTypeStore.prototype._submitAnswer.call(this, payload);
    const guessContentAsCorrections = this._convertGuessToCorrections(this.responseGuess);
    this.writeData('corrections', guessContentAsCorrections);
  }

  _commitCorrectionChange(newValue: string) {
    const {activeRegion, activeOption} = this.getActiveOptionAndRegion();
    const updatedCorrections = this.getCorrections().setIn([activeRegion, activeOption], newValue);
    this.writeData('corrections', updatedCorrections);
    this.resetProperties('activeOption');
  }

  _removeActiveOptionCorrection() {
    const {activeRegion, activeOption} = this.getActiveOptionAndRegion();
    let updatedCorrections = this.getCorrections().deleteIn([activeRegion, activeOption]);
    if (updatedCorrections.has(activeRegion) && updatedCorrections.get(activeRegion).isEmpty()) {
      updatedCorrections = updatedCorrections.delete(activeRegion);
    }
    this.writeData('corrections', updatedCorrections);
    this.resetProperties('activeOption');
  }

  getActiveOptionAndRegion(): {
    activeOption: number,
    activeRegion: string
  } {
    const activeOption = this.getActiveOption();
    return {
      activeOption,
      activeRegion: this.getInitialCorrections().findKey((region) => region.has(activeOption))
    };
  }

  getCorrectionAsJoinedStrings(regionKey: string): string | null {
    return this.getCorrections().has(regionKey)
      ? this.getCorrections()
          .get(regionKey)
          .sortBy((val, key) => key)
          .join(' ')
      : null;
  }

  getInitialCorrectionAsJoinedString(regionKey: string): string | null {
    return this.getInitialCorrections().has(regionKey)
      ? this.getInitialCorrections()
          .get(regionKey)
          .sortBy((val, key) => key)
          .join(' ')
      : null;
  }

  isOptionCorrected(optionIndex: number): boolean {
    return this.getCorrections().some((region) => region.has(optionIndex));
  }

  getCorrections = (): Map<string, any> => this.readData('corrections');

  getInitialCorrections = (): Map<string, any> => this.readData('initialCorrections');

  hasInitialCorrections = (): boolean => this.getInitialCorrections() !== null;

  getActiveOption = (): number => this.readData('activeOption');

  hasActiveOption = (): boolean => this.getActiveOption() !== null;
}
