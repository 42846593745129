import React from 'react';
import PropTypes from 'prop-types';
import {omit, isEqual} from 'lodash';
import shallowEqual from 'shallowequal';
import qs from 'qs';

import {callAction, callTargetedAction} from 'client/framework';
import EditPage from 'client/EditPage/V2/EditPage';
import CollapsibleSidebarContainer from 'generic/CollapsibleSidebarContainer/CollapsibleSidebarContainer.react';
import questionEditorStore from 'client/EditPage/V2/QuestionEditor/QuestionEditor.store';
import questionsListActions from 'client/InteractionEngineV2/shared/QuestionsList/QuestionsListActions';
import questionEditorActions from 'client/EditPage/V2/QuestionEditor/QuestionEditor.actions';
import {
  getQuestionSetsQuery,
  getQueueFiltersFromQuery,
  getQueueQuestionEditPath
} from 'client/Dennis/Content/Queue/shared';
import awaitMandarkQueries from 'lib/hocs/awaitMandarkQueries';
import {AuthoringQuestionSetModelV1} from 'resources/GeneratedModels/AuthoringQuestionSet/AuthoringQuestionSetModel.v1';
import WindowConfirm from 'client/generic/WindowConfirm/WindowConfirm.react';
import windowConfirmActions from 'generic/WindowConfirm/WindowConfirmActions';

import QueueSearchPanel from './QueueSearchPanel/QueueSearchPanel.react';
import {QueueResults} from './QueueResults/QueueResults.react';
import {AuthoringFeedbackPanel} from './AuthoringFeedback/AuthoringFeedback.react';
import {getDefaultParameters} from './QueueSearchPanel/URLSearchParameters';
import abandonQueueChangesStore from './AbandonQueueChanges.store';
import abandonQueueChangesActions from './AbandonQueueChanges.actions';
import './queue.scss';

export const queueConstants = {
  MAIN_CONTENT_AREA_CLASS_NAME: 'scroll-listener-utility-class--queue-main-content-area'
};

export const newQuestionConfirmationModalName = 'newQuestionConfirmationModalQuestionSetActions';

/**
 * When there are unsaved changes, prompt the user to abandon them; otherwise: execute the `ifSavedFunction` argument.
 *
 * The action that is executed when a user chooses to abandon changes is set in the `AbandonQueueChanges.store`
 * via the `SET_ABANDON_CHANGES_HANDLER` action. This allows various buttons to provide their own action to
 * the abandon modal's `Leave` (cancel) option on an ad hoc basis.
 *
 * In the below example, we can see that a button handler is
 * 1. setting the `toggleQuestionTypeModal` function as the action to execute when changes are abandoned
 * 2. invoking the prompt with the `toggleQuestionTypeModal` function as the action to execute if there are no unsaved changes
 *
 * @example
 * ```js
 * newQuestionHandler = () => {
 *   callAction(abandonQueueChangesActions.SET_ABANDON_CHANGES_HANDLER, this.toggleQuestionTypeModal);
 *   promptIfUnsavedOr(this.toggleQuestionTypeModal);
 * };
 * ```
 */

export function promptIfUnsavedOr(ifSavedFunction = () => {}) {
  const questionHasInitialized = questionEditorStore.getQuestion() !== null;
  const questionHasChanges =
    questionHasInitialized && !questionEditorStore.getQuestion().getChangeMap().isEmpty();
  if (questionHasChanges) {
    callTargetedAction({
      name: windowConfirmActions.SHOW_MODAL,
      targetStore: newQuestionConfirmationModalName
    });
  } else {
    ifSavedFunction();
  }
}

export const Queue = awaitMandarkQueries(
  (props) => {
    const queries = {};
    if (props.params.questionSetId) {
      queries.questionSet = questionEditorStore.getQuestionSetQuery();
    }
    return {
      queries,
      initialLoadOnly: true
    };
  },
  class Queue extends React.Component {
    static propTypes = {
      isAuthorQueue: PropTypes.bool,
      params: PropTypes.shape({
        questionSetId: PropTypes.string,
        questionId: PropTypes.string
      }),
      routeParams: PropTypes.shape({
        questionSetId: PropTypes.string,
        questionId: PropTypes.string
      }),
      router: PropTypes.object,
      location: PropTypes.shape({
        pathname: PropTypes.string,
        query: PropTypes.shape({
          author: PropTypes.string,
          difficulty: PropTypes.string,
          status: PropTypes.string,
          page: PropTypes.string,
          subject: PropTypes.string
        })
      }),
      questionSet: PropTypes.instanceOf(AuthoringQuestionSetModelV1)
    };

    static defaultProps = {
      isAuthorQueue: false
    };

    constructor(props) {
      super(props);
      this.state = {
        hasBootstrapped: false
      };
      this.bootstrap();
    }

    componentDidUpdate(prevProps) {
      if (!this.state.hasBootstrapped) {
        return;
      }
      const currentParams = getQueueFiltersFromQuery(omit(this.props.location.query, 'page'));
      const prevParams = getQueueFiltersFromQuery(omit(prevProps.location.query, 'page'));
      const didBaseQueryChange = !shallowEqual(currentParams, prevParams);
      const didPageChange = this.props.location.query.page !== prevProps.location.query.page;
      const didRouteParamsChanging = !shallowEqual(prevProps.routeParams, this.props.routeParams);

      if (didBaseQueryChange) {
        const params = qs.stringify({
          ...currentParams,
          page: 1
        });
        const {router, location} = this.props;
        router.replace(`${location.pathname}?${params}`);
      }

      if (didBaseQueryChange || didPageChange) {
        this.updateActiveQuestion();
      }

      /**
       * We are manually keeping track of whether or not a user is choosing to abandon changes when
       * we perform certain actions in the UI that would cause those changes to be discarded.
       *
       * After a route changes, we want to remove what they have selected so we do not
       * interfere with the `ConfirmWillLeave` component used in the QuestionEditor. This is
       * not an ideal situation, but looking to see if the queue's provided params have changed
       * is a litmus test that works here for those cases where we'd conflict with the `ConfirmWillLeave`
       */
      if (didRouteParamsChanging) {
        callAction(abandonQueueChangesActions.RESET_STORE);
      }
    }

    componentWillUnmount() {
      callAction(questionsListActions.CLEAR_ACTIVE_QUESTION);
      callAction(questionEditorActions.RESET_STORE);
      callAction(abandonQueueChangesActions.RESET_STORE);
    }

    bootstrap() {
      const {questionSet, router, location} = this.props;

      const currentParams = getQueueFiltersFromQuery(this.props.location.query);
      const bootstrappedParams = {
        ...getDefaultParameters(),
        ...currentParams,
        ...(!!questionSet && {
          subject: questionSet.getAuthoringSubject().getId(),
          page: questionSet.getId(),
          status: questionSet.getStatus()
        })
      };

      if (isEqual(currentParams, bootstrappedParams) === false) {
        const params = qs.stringify(bootstrappedParams);
        router.replace(`${location.pathname}?${params}`);
      }

      const setIsBootstrapped = () => {
        setImmediate(() => {
          this.setState({
            hasBootstrapped: true
          });
        });
      };

      if (!questionSet) {
        this.updateActiveQuestion().then(setIsBootstrapped);
      } else {
        setIsBootstrapped();
      }
    }

    async updateActiveQuestion() {
      const questionSets = await getQuestionSetsQuery().getResourcePromise();
      const firstSet = questionSets.first();
      if (!firstSet) {
        return;
      }
      const questionSetId = firstSet.getId();
      const questionId = firstSet.getQuestions().first().getId();
      const newPath = getQueueQuestionEditPath(questionSetId, questionId);
      this.props.router.replace(newPath);
    }

    hideAbandonChangesModal = () => {
      callTargetedAction({
        name: windowConfirmActions.HIDE_MODAL,
        targetStore: newQuestionConfirmationModalName
      });
    };

    render() {
      if (!this.state.hasBootstrapped) {
        return null;
      }
      const abandonChangesModal = (
        <WindowConfirm
          title='You have unsaved changes'
          onConfirm={this.hideAbandonChangesModal}
          onCancel={() => {
            callAction(abandonQueueChangesActions.ABANDON_CHANGES);
            const handler = abandonQueueChangesStore.getAbandonChangesHandler();
            handler();
            this.hideAbandonChangesModal();
          }}
          confirmButtonText='Stay'
          dismissButtonText='Leave'
          storeName={newQuestionConfirmationModalName}
          message={
            <p>
              There are unsaved changes on this question. <br />
              Are you sure you want to leave?
            </p>
          }
        />
      );

      const shouldRenderEditor =
        this.props.params.questionSetId ||
        (questionEditorStore.getQuestion() && questionEditorStore.getQuestion().getType()) ||
        (questionEditorStore.getQuestion() && questionEditorStore.getQuestionType());

      let isActiveQuestionInSubject = false;
      if (this.props.questionSet) {
        isActiveQuestionInSubject =
          this.props.questionSet.getSubjectId() === this.props.location.query.subject;
      }
      const searchPanel = (
        <QueueSearchPanel
          questionSetId={this.props.params.questionSetId}
          isAuthorQueue={this.props.isAuthorQueue}
          isActiveQuestionInSubject={isActiveQuestionInSubject}
        />
      );
      return (
        <CollapsibleSidebarContainer
          className='authoring-queue'
          mainContentClassName={queueConstants.MAIN_CONTENT_AREA_CLASS_NAME}
          leftSidebars={[
            {
              content: searchPanel,
              label: 'Queue and Filter Options',
              width: 310,
              initialOpenState: false
            },
            {
              content: (
                <QueueResults
                  paginationStore={this.paginationStore}
                  isAuthorQueue={this.props.isAuthorQueue}
                />
              ),
              label: 'Question List',
              width: 250
            }
          ]}
          rightSidebars={[
            {
              content: <AuthoringFeedbackPanel />,
              label: 'Question Feedback',
              width: 400,
              initialOpenState: false,
              disabled: Boolean(this.props.params.questionId) === false
            }
          ]}
        >
          {abandonChangesModal}
          {shouldRenderEditor && <EditPage />}
        </CollapsibleSidebarContainer>
      );
    }
  }
);

export default Queue;
