import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import makeConstants from 'lib/makeConstants';

import Heading from '../../../atoms/Heading/Heading.react';
import Text from '../../../atoms/Text/Text.react';
import './title-bar.scss';

const alignOptions = makeConstants('left', 'right');

export default class TitleBar extends React.Component {
  static propTypes = {
    buttonAlign: PropTypes.oneOf(Object.values(alignOptions)),
    buttonGroup: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    inputAlign: PropTypes.oneOf(Object.values(alignOptions)),
    inputGroup: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    title: PropTypes.string,
    subText: PropTypes.string,
    subTextAlignBottom: PropTypes.bool,
    subTextItalic: PropTypes.bool
  };

  static defaultProps = {
    buttonAlign: 'right',
    inputAlign: 'right',
    subTextAlignBottom: false,
    subTextItalic: false
  };

  makeTextGroup(title, subText) {
    if (!title) {
      return null;
    }

    return (
      <div
        className={classnames('o-table__title-bar-group', {
          'u-align-items_flex-start u-flex-direction_column': this.props.subTextAlignBottom
        })}
      >
        <Heading>{title}</Heading>
        {subText && (
          <Text
            className={classnames({'u-mar-t_1': !this.props.subTextAlignBottom})}
            as='div'
            color='tertiary'
            italic={this.props.subTextItalic}
          >
            {subText}
          </Text>
        )}
      </div>
    );
  }

  makeGroup(group, isCorrectAlign) {
    return (
      group &&
      isCorrectAlign && (
        <div className='o-table__title-bar-group'>
          {React.Children.map(group, (child) => React.cloneElement(child))}
        </div>
      )
    );
  }

  render() {
    const {buttonAlign, buttonGroup, inputAlign, inputGroup, title, subText} = this.props;

    const inputsLeftAligned = inputAlign === 'left';
    const buttonsLeftAligned = buttonAlign === 'left';

    return (
      <div className='o-table__title-bar'>
        <div className='o-table__title-bar-group--left'>
          {this.makeTextGroup(title, subText)}
          {this.makeGroup(inputGroup, inputsLeftAligned)}
          {this.makeGroup(buttonGroup, buttonsLeftAligned)}
        </div>
        <div className='o-table__title-bar-group--right'>
          {this.makeGroup(inputGroup, !inputsLeftAligned)}
          {this.makeGroup(buttonGroup, !buttonsLeftAligned)}
        </div>
      </div>
    );
  }
}
