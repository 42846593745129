import React from 'react';
import PropTypes from 'prop-types';
import appStore from 'client/AppStore';
import {Link} from 'react-router';
import {renderMoment} from 'lib/timeUtils';
import {resource} from '@albert-io/json-api-framework/request/builder';
import {MandarkTable, Column} from 'sg/Tables/MandarkTable/MandarkTable.react';
import {Dialogue, Modal, Text, TabNavigation} from '@albert-io/atomic';
import TrackedButton from 'client/components/Tracking/TrackedButton';
import withTracking from 'lib/hocs/withTracking';
import './question-tracker-modal.scss';

/**
 * @todo: A lot of this code is directly pulled from the previous QuestionTrackerModal, and there is
 * room for improvement.
 */

const TrackedTab = withTracking(TabNavigation.Tab, {
  onClick: {content: (e, props) => props.children}
});

export default class QuestionTrackerModal extends React.Component {
  static propTypes = {
    userId: PropTypes.string.isRequired,
    questionSetId: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.assignmentsTab = {
      label: 'Assignments',
      onClick: () => this.setState({activeTab: this.assignmentsTab}),
      query: this.getAssignmentsQuery
    };
    this.foldersTab = {
      label: 'Folders',
      onClick: () => this.setState({activeTab: this.foldersTab}),
      query: this.getTemplatesQuery
    };
    this.state = {
      activeTab: this.assignmentsTab
    };
  }

  getTabs() {
    return [this.assignmentsTab, this.foldersTab];
    /**
     * @todo: Implement this once we add Practice Exams
     */
    // if (this.isPracticeExam()) {
    //   return [this.assignmentsTab];
    // }
  }

  isFoldersTab() {
    return this.state.activeTab === this.foldersTab;
  }

  getAssignmentsQuery = () => {
    return resource('assignment_v3')
      .mandarkEndpoint(['assignments_v3'])
      .include('classrooms_v1')
      .include('subject_v2')
      .filter({
        'meta.used_in_active_classroom': true,
        teacher_v1: this.props.userId,
        question_sets_v1: this.props.questionSetId
      })
      .withMeta('assignment_v3')
      .meta({
        context: {
          used_in_active_classroom: true
        }
      });
  };

  getTemplatesQuery = () => {
    return resource('template_v1')
      .mandarkEndpoint(['templates_v1'])
      .include('question_sets_v1')
      .filter({
        teacher_v1: this.props.userId,
        question_sets_v1: this.props.questionSetId
      });
  };

  getAssignmentDetailsContent(item) {
    const classroomNames = item.getClassroomNames();
    return (
      <div>
        <div className='question-association-details__name'>{item.getName()}</div>
        <div className='question-association-details__classroom-content'>
          {classroomNames !== '' && (
            <span>
              {classroomNames}
              <span className='question-tracker-modal__names-content-divider'>&#9679;</span>
            </span>
          )}
          {renderMoment(item.getDueDateFromSettings(), 'dateTime')}
        </div>
      </div>
    );
  }

  getTemplateDetailsContent(item) {
    return <div className='question-association-details__name'>{item.getName()}</div>;
  }

  getAssignmentViewButtonContent = (assignment) => {
    const classrooms = assignment.getClassrooms();
    if (classrooms.isEmpty()) {
      return null;
    }
    const classroom = classrooms.first().getId();
    const assignmentId = assignment.getId();
    // Shouldn't ever use the exams route again but to be safe until the migration is over, we are leaving it as a safeguard
    const slug = assignment.getSections().isEmpty()
      ? `/classes/${classroom}/assignments/${assignmentId}/student-detail`
      : `/classes/${classroom}/exams/${assignmentId}`;
    return (
      <TrackedButton
        as={Link}
        className='question-tracker-modal__view-button'
        size='s'
        to={slug}
        data-testid={assignmentId}
      >
        View
      </TrackedButton>
    );
  };

  getTemplateViewButtonContent = (item) => {
    const folderId = item.getId();
    // If user is already in the folder, close the modal
    if (appStore.routerProps.params.folderId === folderId) {
      return (
        <TrackedButton
          className='question-tracker-modal__view-button'
          size='s'
          onClick={this.props.handleClose}
        >
          View
        </TrackedButton>
      );
    }
    return (
      <TrackedButton
        as={Link}
        className='question-tracker-modal__view-button'
        size='s'
        to={`/folder/${folderId}`}
        data-testid={folderId}
      >
        View
      </TrackedButton>
    );
  };

  render() {
    return (
      <Modal ariaLabel='Question tracker' handleClose={this.props.handleClose}>
        {({CloseButtonWrapper, modalContentStyle}) => {
          return (
            <Dialogue
              inModal
              className={modalContentStyle}
              handleClose={this.props.handleClose}
              title='Question usage'
            >
              <Dialogue.Body>
                <Text paragraph>
                  This question is used in the following places. Note that the assignment list
                  reflects assignments for active classrooms only.
                </Text>
                <TabNavigation>
                  {this.getTabs().map((tab, i) => (
                    <TrackedTab
                      key={i}
                      as='button'
                      isActive={this.state.activeTab === tab}
                      onClick={tab.onClick}
                      data-testid={`question-tracker-modal__${tab.label.toLowerCase()}-tab`}
                    >
                      {tab.label}
                    </TrackedTab>
                  ))}
                </TabNavigation>
                <MandarkTable
                  mobileBreakpoint={600}
                  query={this.state.activeTab.query()}
                  className='question-tracker-modal-table'
                >
                  <Column
                    content={
                      this.isFoldersTab()
                        ? this.getTemplateDetailsContent
                        : this.getAssignmentDetailsContent
                    }
                  />
                  <Column
                    content={
                      this.isFoldersTab()
                        ? this.getTemplateViewButtonContent
                        : this.getAssignmentViewButtonContent
                    }
                  />
                </MandarkTable>
              </Dialogue.Body>
              <Dialogue.BtnGroup>
                <CloseButtonWrapper>
                  <TrackedButton>Done</TrackedButton>
                </CloseButtonWrapper>
              </Dialogue.BtnGroup>
            </Dialogue>
          );
        }}
      </Modal>
    );
  }
}
