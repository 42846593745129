import React, {Suspense, useState, useEffect, useMemo, useRef, lazy} from 'react';
import PropTypes from 'prop-types';
import {fromJS, Map, List} from 'immutable';
import {isEqual} from 'lodash';
import {LoadingSpinner} from '@albert-io/atomic';

import {renderToolbar} from 'components/QuestionTypes/Graphing/Toolbar/Toolbar.react';
import {AuthoringQuestionModelV1} from 'resources/augmented/AuthoringQuestion/AuthoringQuestionModel.v1';
import GraphingInput, {modes, flavorTypes} from 'components/GraphingInput/GraphingInput.react';

import Axis, {useAxisState, getInitialAxisValues} from './Axis.react';
import GraphLabels, {useGraphLabelsState} from './GraphLabels.react';
import CorrectAnswerSettings, {useCorrectAnswerSettingsState} from './CorrectAnswerSettings.react';
import FlavorSelectionTabs from './FlavorSelectionTabs.react';
import {useAllowedEntitiesState} from './useAllowedEntitiesState';

const StudentAllowedEntities = lazy(() => import('./StudentAllowedEntities.react'));

function makeUpdatedQuestion({
  question,
  entities,
  xAxis,
  yAxis,
  graphLabels,
  correctAnswerSettings,
  allowedEntities
}) {
  const [correctAnswer, authorProvided] = entities.reduce(
    (acc, entity) => {
      const {flavor, ...rest} = entity;

      acc[flavor === flavorTypes.correctAnswer ? 0 : 1].push(rest);

      return acc;
    },
    [[], []]
  );

  const validResponse = correctAnswer.map((entity, i) => ({
    ...entity,
    correctAnswerSettings: correctAnswerSettings[i]
  }));

  const updatedQuestion = question
    .setQuestionTypeAttributes(
      fromJS({
        graph_contains: {
          label_left: graphLabels.left,
          label_bottom: graphLabels.bottom,
          x_label: xAxis.label,
          x_min: xAxis.min,
          x_max: xAxis.max,
          x_interval: xAxis.interval,
          y_label: yAxis.label,
          y_min: yAxis.min,
          y_max: yAxis.max,
          y_interval: yAxis.interval,
          provided_entities: authorProvided || [],
          allowed_entities: [...allowedEntities]
        }
      })
    )
    .setValidResponse(fromJS(validResponse));
  return updatedQuestion;
}

const GraphingEditor = ({initialQuestion, updateQuestion}) => {
  const [currentFlavor, setCurrentFlavor] = useState(flavorTypes.correctAnswer);

  const questionTypeAttributes = initialQuestion
    .getQuestionTypeAttributes()
    .get('graph_contains', Map());

  const initialEntities = useMemo(() => {
    const correctAnswers = initialQuestion
      .getValidResponse()
      .toList()
      .toJS()
      .map(({elements, type, attributes}) => {
        const entity = {
          elements,
          flavor: 'correctAnswer',
          type
        };

        if (attributes !== undefined) {
          entity.attributes = attributes || {};
        }

        return entity;
      });
    const authorProvided = questionTypeAttributes
      .get('provided_entities', List())
      .toJS()
      .map((entity) => ({
        ...entity,
        flavor: 'authorProvided'
      }));
    return correctAnswers.concat(authorProvided);
  }, [initialQuestion, questionTypeAttributes]);

  const [entities, setEntities] = useState(initialEntities || []);

  const handleSetEntities = (incoming) => {
    setEntities((current) => {
      if (current.length === incoming.length && isEqual(current, incoming)) {
        return current;
      }
      return incoming;
    });
  };

  const correctAnswerEntities = useMemo(
    () => entities.filter(({flavor}) => flavor === 'correctAnswer'),
    [entities]
  );

  const [xAxis, updateXAxisProperty] = useAxisState(
    getInitialAxisValues({question: initialQuestion, axis: 'x'})
  );
  const [yAxis, updateYAxisProperty] = useAxisState(
    getInitialAxisValues({question: initialQuestion, axis: 'y'})
  );

  const [graphLabels, setGraphLabels] = useGraphLabelsState({
    bottom: questionTypeAttributes.get('label_bottom'),
    left: questionTypeAttributes.get('label_left')
  });

  const [correctAnswerSettings, updateCorrectAnswerSettings, toggleSetting] =
    useCorrectAnswerSettingsState(initialQuestion, correctAnswerEntities);

  const [allowedEntities, toggleAllowedEntity] = useAllowedEntitiesState(
    initialQuestion,
    correctAnswerEntities
  );

  const isMountedRef = useRef(false);

  useEffect(() => {
    // Guard for update on first render, since that will mark the question as dirty
    if (!isMountedRef.current) {
      isMountedRef.current = true;
      return;
    }
    updateQuestion((currentQuestion) =>
      makeUpdatedQuestion(
        {
          question: currentQuestion,
          entities,
          xAxis,
          yAxis,
          graphLabels,
          correctAnswerSettings,
          allowedEntities
        },
        {}
      )
    );
  }, [entities, xAxis, yAxis, graphLabels, correctAnswerSettings, allowedEntities, updateQuestion]);

  return (
    <div>
      <Axis
        direction='Horizontal'
        label={xAxis.label}
        min={xAxis.min}
        max={xAxis.max}
        interval={xAxis.interval}
        onChange={updateXAxisProperty}
      />
      <Axis
        direction='Vertical'
        label={yAxis.label}
        min={yAxis.min}
        max={yAxis.max}
        interval={yAxis.interval}
        onChange={updateYAxisProperty}
      />
      <GraphLabels bottom={graphLabels.bottom} left={graphLabels.left} onChange={setGraphLabels} />
      <FlavorSelectionTabs currentFlavor={currentFlavor} setCurrentFlavor={setCurrentFlavor} />
      <GraphingInput
        className='u-mar-b_4'
        id={`graphing-question-editor--${initialQuestion.getId()}`}
        mode={modes.editing}
        currentFlavor={currentFlavor}
        renderToolbar={renderToolbar}
        boundingBox={useMemo(
          () => [xAxis.min, yAxis.max, xAxis.max, yAxis.min],
          [xAxis.min, yAxis.max, xAxis.max, yAxis.min]
        )}
        xTickInterval={xAxis.interval}
        yTickInterval={yAxis.interval}
        xAxisLabel={xAxis.label}
        yAxisLabel={yAxis.label}
        labels={useMemo(
          () => ({
            left: graphLabels.left,
            bottom: graphLabels.bottom
          }),
          [graphLabels.left, graphLabels.bottom]
        )}
        initialEntities={initialEntities}
        onChange={(updatedEntities) => {
          handleSetEntities(updatedEntities);
          updateCorrectAnswerSettings(
            updatedEntities.filter(({flavor}) => flavor === 'correctAnswer')
          );
        }}
      />
      <CorrectAnswerSettings
        correctAnswerEntities={correctAnswerEntities}
        settings={correctAnswerSettings}
        handleToggleSetting={toggleSetting}
        xInterval={xAxis.interval}
        yInterval={yAxis.interval}
      />
      <div className='u-mar-b_10' />
      <Suspense fallback={<LoadingSpinner />}>
        <StudentAllowedEntities
          correctAnswerEntities={correctAnswerEntities}
          allowedEntities={allowedEntities}
          toggleAllowedEntity={toggleAllowedEntity}
        />
      </Suspense>
    </div>
  );
};

GraphingEditor.propTypes = {
  initialQuestion: PropTypes.instanceOf(AuthoringQuestionModelV1),
  updateQuestion: PropTypes.func.isRequired
};

const GraphingEditorWrapper = ({question, updateQuestion}) => {
  const questionId = question.getId();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialQuestion = useMemo(() => question, [questionId]);

  return (
    <GraphingEditor
      key={questionId}
      initialQuestion={initialQuestion}
      updateQuestion={updateQuestion}
    />
  );
};

GraphingEditorWrapper.propTypes = {
  question: PropTypes.instanceOf(AuthoringQuestionModelV1),
  updateQuestion: PropTypes.func.isRequired
};

export default GraphingEditorWrapper;
