/* eslint-disable import/prefer-default-export */
import * as React from 'react';

import {Anchor, Text} from '@albert-io/atomic';

import sessionStore from 'client/Session/SessionStore';
import {history} from 'client/history';
import logger from 'lib/logger';
import {ERROR_TYPES} from 'client/constants';

import {redirectToLoginPageFromLocation, showGlobalLoginModal} from 'client/LogIn/utils';

class PromptLogIn extends React.Component {
  componentDidMount() {
    if (!sessionStore.hasValidSession()) {
      showGlobalLoginModal();
    }
  }

  showLogInModal = () => {
    showGlobalLoginModal();
  };

  render() {
    const button = <Anchor onClick={this.showLogInModal}>log in</Anchor>;

    return (
      <div className='u-text-align_center'>
        <Text>Please {button} to continue.</Text>
      </div>
    );
  }
}

export function requireSessionWithLoginPrompt(options = {}) {
  return requireSession({
    NoSessionComponent: PromptLogIn,
    ...options
  });
}

/**
 * @param rolePredicate {function}
 * @param NoSessionComponent {null|React.Component}
 * @param UnauthorizedSessionComponent {null|React.Component}
 * @returns {React.Component}
 */
export function requireSession({
  rolePredicate = () => {
    return true;
  },
  NoSessionComponent,
  UnauthorizedSessionComponent
} = {}) {
  return (DecoratedComponent) => (props) => {
    /*
        Renders null if a token is not present.

        The token check is mirrored in the `componentDidMount` method, and rendering
        null here will allow us to show a modal to the user.
        */
    if (!sessionStore.hasValidSession()) {
      logger.debug('No token found; rendering NoSessionComponent');
      if (NoSessionComponent) {
        return <NoSessionComponent />;
      }
      redirectToLoginPageFromLocation();
      return null;
    }

    /*
        If we've successfully retreived a user object from our promise,
        validate that the user against the incoming rolePredicate.

        If the user doesn't fit the predicate, we move to an error page.
      */
    if (rolePredicate()) {
      logger.debug('rolePredicate is true, rendering decorated component');
      return <DecoratedComponent {...props} />;
    }

    logger.debug('rolePredicate is false, rendering Unauthorized component');
    if (UnauthorizedSessionComponent) {
      return <UnauthorizedSessionComponent />;
    }
    history.replaceState(null, `/error?errorCode=${ERROR_TYPES.UNAUTHORIZED}`);
    return null;
  };
}
