import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';

import UpgradeCallToActionModal from './UpgradeCallToActionModal/UpgradeCallToActionModal.react';
import IssuesModal from './IssuesModal/IssuesModal.react';
import QuestionTrackerModal from './QuestionTrackerModal/QuestionTrackerModal.react';

const ModalCollection = ({location}) => (
  <div>
    <UpgradeCallToActionModal location={location} />
    <IssuesModal />
    <QuestionTrackerModal />
  </div>
);

ModalCollection.propTypes = {
  location: ImmutablePropTypes.map
};

export default ModalCollection;
