/* eslint-disable no-underscore-dangle */
// @flow
import {fromJS} from 'immutable';

import {getSession, logout} from '@albert-io/mandark/authentication';

import {Store} from 'client/framework';

import sessionLifecycleActions from './SessionLifecycleActions';

const accountTypesAndMethods = {
  administrator: 'isSchoolAdministrator',
  author: 'isAuthor',
  student: 'isStudent',
  super: 'isSuper',
  teacher: 'isTeacher'
};
class SessionStore extends Store {
  constructor(name: string) {
    super(name);
    this.initialData = fromJS({
      hasSession: false
    });
    this.setInitialData(this.initialData);

    this.handle(sessionLifecycleActions.SET_SESSION_STATE, this.setSessionState);
    this.handle(sessionLifecycleActions.END_SESSION, this.handleLogout);
  }

  handleLogout() {
    const params = global.location.pathname.split('/');

    const inAssignment = params.some((param) => param === 'assignment');
    if (inAssignment && this.isStudent()) {
      if (global.window.confirm('Leaving this page may result in unsaved changes')) {
        logout();
      }
    } else {
      logout();
    }
  }

  setSessionState(hasSession) {
    this.writeData('hasSession', Boolean(hasSession));
  }

  hasValidSession(): boolean {
    return getSession().isValid();
  }

  // returns null for non-teachers
  getUserEmail(): boolean {
    return getSession().getEmail();
  }

  getLastAuthenticatedAt() {
    return getSession().getLastAuthenticatedAt();
  }

  getUserId(): ?string {
    return getSession().getId();
  }

  isStudent(): boolean {
    return getSession().isStudent();
  }

  isTeacher(): boolean {
    return getSession().isTeacher();
  }

  isSchoolAdministrator(): boolean {
    return getSession().isSchoolAdministrator();
  }

  isAuthor(): boolean {
    return getSession().isAuthor();
  }

  isSuper(): boolean {
    return getSession().isSuper();
  }

  hasTeacherAccess(): boolean {
    return getSession().hasTeacherAccess();
  }

  isUserConfirmed(): boolean {
    return getSession().isConfirmed();
  }

  getAccountType(): string {
    return Object.keys(accountTypesAndMethods).find((key) => this[accountTypesAndMethods[key]]());
  }

  getSignUpDate() {
    return getSession().getSignUpDate();
  }

  getDistrictCleverId() {
    return getSession().getDistrictCleverId();
  }
}

export default new SessionStore('SessionStore');
