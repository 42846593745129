import React, {ReactNode} from 'react';

import {Text} from '@albert-io/atomic';

import {QuestionModelV3, QuestionSetModelV1, SubjectModelV2} from '@albert-io/models';

import {QuestionWrapper, HeadingBar} from '../shared/ContentBlocks';

interface NavigationProps {
  disabled: boolean;
  handleNavigation: () => void;
}

interface Props {
  actions?: ReactNode;
  activeQuestion: QuestionModelV3;
  activeQuestionSet: QuestionSetModelV1;
  activeSubject: SubjectModelV2;
  headingActions: ReactNode;
  navigateBackProps: NavigationProps | null;
  navigateNextProps: NavigationProps | null;
}

export default function BlockedAssignmentQuestion({
  activeQuestion,
  activeQuestionSet,
  activeSubject,
  actions,
  navigateBackProps,
  navigateNextProps,
  headingActions
}: Props) {
  return (
    <QuestionWrapper
      question={activeQuestion}
      questionSet={activeQuestionSet}
      subject={activeSubject}
      heading={<HeadingBar question={undefined} questionSet={undefined} showDifficulty={false} />}
      navigateBackProps={navigateBackProps}
      navigateNextProps={navigateNextProps}
      headingActions={headingActions}
    >
      <div className='u-display_flex u-flex-direction_column u-gap_space-x2 u-pad_4 u-pad_2@xs'>
        <Text bold className='u-text-align_center' size='xl'>
          This question is not available
        </Text>
        <Text>
          This question is part of an active assignment, and is not available for self-study. It
          will become available once you have submitted the relevant assignments and are able to
          access the explanations.
        </Text>
      </div>
      {actions || null}
    </QuestionWrapper>
  );
}
