/**
 * # SortableElement
 *
 * This element is only used within the `SortableContainerProvider`.
 * A good example of how this works with the `SortableContainerProvider` can be found in `SortableContainer.stories.js`.
 *
 * SortableElement exposes two components - HoverArea and SortHandler.
 *
 * - HoverArea should be a direct child of whatever element you want to have the hover styles applied to.
 *   Example: For questions, this is added in QuestionSet.react.
 *
 * - SortHandler handles changing between the customSortHandle and customCancelHandle that is passed.
 *   Example: The sort handle is passed to the element in QuestionsList.react but the exposed SortElement isn't used until being passed into Question.react.
 *
 * Separating these two allows for more flexibility and doesn't force the SortHandle to also be a direct child of the element to get the hover styles.
 *
 * ## Props
 *
 * - `children` (PropTypes.node): The children components.
 * - `sortIndices` (ImmutablePropTypes.set): Indices of elements selected to be reordered.
 * - `index` (PropTypes.number): Index of the current element.
 * - `recentlyMovedIndices` (PropTypes.array): Index that an element was recently moved to.
 * - `handleSingleSortStart` (PropTypes.func): From the provider and sets index of selected element to state.
 * - `handleSortEnd` (PropTypes.func): Sets state in provider and runs custom `onSortEnd` function.
 * - `customSortHandle` (PropTypes.node): Element shown before selecting an item to move.
 * - `customCancelHandle` (PropTypes.node): Element on item being sorted that cancels the sort.
 * - `elementHeight` (PropTypes.string): Height of the hover area that will be transitioned to be half above and below an element.
 * - `isBulkMove` (PropTypes.bool): Boolean used for 'move to top/bottom' functionality where we don't show click areas.
 *
 * ## Usage
 *
 * ```javascript
 * import SortableElement from './sortable-element';
 *
 * const MyComponent = () => {
 *   return (
 *     <SortableElement
 *       sortIndices={...}
 *       index={...}
 *       recentlyMovedIndices={...}
 *       handleSingleSortStart={...}
 *       handleSortEnd={...}
 *       customSortHandle={...}
 *       customCancelHandle={...}
 *       elementHeight={...}
 *       isBulkMove={...}
 *     >
 *       {({ SortHandler, HoverArea, isActiveElement, recentlyMovedIndices, isSorting }) => (
 *         <div>
 *           <SortHandler />
 *           <HoverArea />
 *           {/* rest of the component *`/}
 *         </div>
 *       )}
 *     </SortableElement>
 *   );
 * };
 */

import React from 'react';
import {Set} from 'immutable';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import './sortable-element.scss';

/*
  This element is only used within the `SortableContainerProvider`
  A good example of how this works with the `SortableContainerProvider` can be found in `SortableContainer.stories.js`

  SortableElement exposes two components - HoverArea and SortHandler
    HoverArea should be a direct child of whatever element you want to have the hover styles applied to.
      ex: For questions, this is added in QuestionSet.react
    SortHandler handles changing between the customSortHaandle and customCancelHandle that is passed
      ex: The sort handle is passed to the element in QuestionsList.react but the esposed SortElement isn't used until being passed into Question.react

    Separating these two allows for more flexibility and doesn't force the SortHandle to also be a direct child of the element to get the hover styles
 */
const SortableElement = ({
  children,
  sortIndices = Set(),
  index,
  recentlyMovedIndices,
  handleSingleSortStart,
  handleSortEnd,
  customSortHandle,
  customCancelHandle,
  isBulkMove,
  elementHeight = '40px'
}) => {
  // We want to skip showing click areas if we already know where we are moving or if we haven't selected sets to move yet
  const isSorting = !sortIndices.isEmpty() && !isBulkMove;
  let isActiveElement;
  if (isSorting) {
    isActiveElement = sortIndices?.has(index);
  }
  // decides which location to place the hover area
  const hoverLocation = sortIndices.last() > index ? 'above' : 'below';

  let HoverArea = () => null;
  let SortHandler = () => null;
  if (isSorting) {
    SortHandler = () =>
      React.cloneElement(customSortHandle, {
        onClick: () => {},
        style: {visibility: 'hidden'},
        tabIndex: -1
      });

    HoverArea = () => {
      return (
        <button
          aria-label='click to place'
          type='button'
          onClick={(e) => handleSortEnd(e, index)}
          style={{height: elementHeight}}
          className={`sortable-element__hover-area sortable-element__hover-area__${hoverLocation}`}
        />
      );
    };
  } else {
    SortHandler = () =>
      React.cloneElement(customSortHandle, {
        onClick: (e) => handleSingleSortStart(e, index)
      });
  }
  // if we are currently sorting, the active element's handle can cancel the action
  if (isActiveElement) {
    SortHandler = () =>
      React.cloneElement(customCancelHandle, {
        onClick: (e) => handleSortEnd(e, index, true)
      });

    if (index !== sortIndices.last() || sortIndices.size === 1) {
      HoverArea = () => {
        return <span className='active-element__hover-area' />;
      };
    }
  }

  return children({
    SortHandler,
    HoverArea,
    isActiveElement,
    recentlyMovedIndices,
    isSorting
  });
};

SortableElement.propTypes = {
  children: PropTypes.node,
  /* indices of elements selected to be reordered */
  sortIndices: ImmutablePropTypes.set,
  /* index of current element */
  index: PropTypes.number,
  /* index that an element was recently moved to */
  recentlyMovedIndices: PropTypes.array,
  /* From the provider and sets index of selected elm to state */
  handleSingleSortStart: PropTypes.func,
  /* sets state in provider and runs custom `onSortEnd` func */
  handleSortEnd: PropTypes.func,
  /* Element shown before selecting an item to move */
  customSortHandle: PropTypes.node,
  /* Element on item being sorted that cancels the sort */
  customCancelHandle: PropTypes.node,
  /* height of the hover area that will be transitioned to be half above and below and element  */
  elementHeight: PropTypes.string,
  /* Bool used for 'move to top/bottom' functionality where we don't show click areas */
  isBulkMove: PropTypes.bool
};

export default SortableElement;
