import React, {useEffect, useCallback, useMemo} from 'react';
import {fromJS, Map, List} from 'immutable';
import {v4 as uuid} from 'uuid';
import Button from 'sg/Button/Button.react';

import SupplementManagerModalButton from 'client/Supplements/SupplementManager/SupplementManagerModalButton.react';
import {AuthoringQuestionModelV1} from 'resources/augmented/AuthoringQuestion/AuthoringQuestionModel.v1';
import AuthoringTextInput from 'client/EditPage/V2/AuthoringTextInput/AuthoringTextInput.react';

import './two-way-editor.scss';
import {useQuestionEditorV2Store} from 'client/EditPage/V2/QuestionEditorV2Store';
import {useShallow} from 'zustand/react/shallow';

import {PRIMARY_CONTENT_LANGUAGE} from 'client/EditPage/V2/QuestionEditorV2Store.types';

import TwoWayRow from './TwoWayRow';

function createNewRow(index) {
  return fromJS({
    index,
    id: uuid(),
    statement: '',
    columns: {
      left: {
        id: uuid(),
        text: ''
      },
      right: {
        id: uuid(),
        text: ''
      }
    }
  });
}

interface Props {
  question: AuthoringQuestionModelV1;
  onModelChange: (question: AuthoringQuestionModelV1, ...fields: string[]) => void;
  validationErrors: Map<string, string>;
  onBlur: (e) => void;
}

const TwoWayEditor = ({question, onBlur, onModelChange, validationErrors}: Props) => {
  const {
    currentLanguage,
    translatedHeaderLeft,
    translatedHeaderRight,
    updateTranslatedQuestionField
  } = useQuestionEditorV2Store(
    useShallow((state) => ({
      currentLanguage: state.currentLanguage,
      translatedHeaderLeft:
        state
          .currentTranslatedQuestion()
          ?.translated_fields?.find((field) => field.field === 'header_left')?.text || '',
      translatedHeaderRight:
        state
          .currentTranslatedQuestion()
          ?.translated_fields?.find((field) => field.field === 'header_right')?.text || '',
      updateTranslatedQuestionField: state.updateTranslatedQuestionField
    }))
  );

  const isPrimaryLanguage = currentLanguage === PRIMARY_CONTENT_LANGUAGE;

  const setupTwoWayDefaults = useCallback(() => {
    const defaultRows = List()
      .setSize(3)
      .map((row, key) => createNewRow(key));
    const updatedQuestion = question.setRows(defaultRows);
    onModelChange(updatedQuestion);
  }, [question, onModelChange]);

  useEffect(() => {
    if (question.getRows().isEmpty()) {
      setupTwoWayDefaults();
    }
  }, [question, setupTwoWayDefaults]);

  const onChange = useCallback(
    (e) => {
      if (isPrimaryLanguage) {
        let updatedQuestion = question;
        let validation;
        if (e.target.name === 'headerLeft') {
          updatedQuestion = updatedQuestion.setHeaderLeft(e.target.value);
          validation = 'headerLeft';
        } else {
          updatedQuestion = updatedQuestion.setHeaderRight(e.target.value);
          validation = 'headerRight';
        }

        onModelChange(updatedQuestion, validation);
      } else {
        const field = e.target.name === 'headerLeft' ? 'header_left' : 'header_right';
        updateTranslatedQuestionField(currentLanguage, field, 'text', e.target.value);
      }
    },
    [question, onModelChange, isPrimaryLanguage, currentLanguage, updateTranslatedQuestionField]
  );

  const addRow = useCallback(() => {
    const rows = question
      .getRows()
      .push(createNewRow(question.getRows().size))
      .sortBy((row) => row.get('index'))
      .map((row, index) => row.set('index', index));

    const updatedQuestion = question.setRows(rows);
    onModelChange(updatedQuestion, 'rows');
  }, [question, onModelChange]);

  const rows = useMemo(() => {
    return question
      .getRows()
      .sortBy((row) => row.get('index', 0))
      .map((row, rowIndex) => {
        return (
          <TwoWayRow
            key={row.get('id')}
            row={row}
            rowIndex={rowIndex}
            question={question}
            onBlur={onBlur}
            onModelChange={onModelChange}
            validationErrors={validationErrors}
          />
        );
      });
  }, [question, onBlur, onModelChange, validationErrors]);

  const errors = validationErrors;
  const minimumRowError =
    errors.get('rows') && typeof errors.get('rows') === 'string' ? (
      <div className='a-form-input__error'>{errors.get('rows')}</div>
    ) : null;

  const headerLeftValue = isPrimaryLanguage ? question.getHeaderLeft() : translatedHeaderLeft;
  const headerRightValue = isPrimaryLanguage ? question.getHeaderRight() : translatedHeaderRight;

  return (
    <div className='two-way-editor'>
      <div className='two-way-editor__headers'>
        <div className='two-way-editor__header two-way-editor__header--left'>
          <AuthoringTextInput
            displayAs='input'
            value={headerLeftValue}
            error={isPrimaryLanguage && errors.get('headerLeft')}
            onChange={onChange}
            onBlur={onBlur}
            data-field-path='header_left'
            className='two-way-editor__header-input'
            label='Header Left'
            name='headerLeft'
          />
          {isPrimaryLanguage && (
            <SupplementManagerModalButton
              className='two-way-editor__add-supplement-btn two-way-editor__add-supplement-btn--header two-way-editor__add-supplement-btn--header-left'
              color='blue'
              linkButton
              pathToProperty='header_left'
            />
          )}
        </div>
        <div className='two-way-editor__header two-way-editor__header--right'>
          <AuthoringTextInput
            displayAs='input'
            value={headerRightValue}
            error={isPrimaryLanguage && errors.get('headerRight')}
            onChange={onChange}
            onBlur={onBlur}
            data-field-path='header_right'
            className='two-way-editor__header-input'
            label='Header Right'
            name='headerRight'
          />
          {isPrimaryLanguage && (
            <SupplementManagerModalButton
              className='two-way-editor__add-supplement-btn two-way-editor__add-supplement-btn--header two-way-editor__add-supplement-btn--header-right'
              color='blue'
              linkButton
              pathToProperty='header_right'
            />
          )}
        </div>
      </div>
      <hr />
      <div className='two-way-editor__rows'>
        {minimumRowError}
        {rows}
      </div>
      <hr />
      {isPrimaryLanguage && <Button onClick={addRow} color='green' text='New row' />}
    </div>
  );
};

export default TwoWayEditor;
