/**
 * 
 * @typedef {import('resources/augmented/Question/QuestionModel.v3.js').QuestionModelV3} Question
 */

/**
 * This creates an interface that allows you to keep track of how long the
 * user has looked at each question.
 *
 * @param {?Question} incomingQuestion
 */
export default function makeTimeElapsedManager(initialQuestion) {
  let currQuestionId = null;
  let startTime = null;

  /**
   * We're building a map of {questionId: milliseconds}
   */
  const timeLog = {};

  const methods = {
    /**
     * A question to track, or nullish if we want to stop tracking the currently active question
     * @param {?Question} incomingQuestion
     */
    handleQuestionChange(incomingQuestion) {
      const incomingQuestionId = incomingQuestion && incomingQuestion.getId();

      // Nothing to track yet
      if (!currQuestionId && !incomingQuestionId) {
        return;
      }

      // The question isn't changing, so we hold steady
      if (currQuestionId === incomingQuestionId) {
        return;
      }

      const now = new Date();

      // If we're here, we know the question changed in some way,
      // so if there's a current question, we're going to update its
      // time entry
      if (currQuestionId) {
        const timeToDate = timeLog[currQuestionId] ?? 0;
        const elapsed = now - startTime;
        timeLog[currQuestionId] = timeToDate + elapsed;
      }

      // If there's an incoming question, we set up `currQuestionId` and `startTime`
      if (incomingQuestionId) {
        currQuestionId = incomingQuestionId;
        startTime = now;
      } else {
        // Otherwise we clean them up
        currQuestionId = null;
        startTime = null;
      }
    },
    /**
     * This will reset the time for `currQuestionId` in both the `timeLog` and by
     * updating the `startTime` to now. Use this if the user is attempting to answer
     * a question again and you need to reset the timer.
     */
    resetTimeForActiveQuestion() {
      startTime = new Date();
      timeLog[currQuestionId] = 0;
    },
    /**
     * @param {Question} incomingQuestion
     * @returns {number} Time elapsed in milliseconds
     */
    getTimeElapsed(question) {
      const questionId = question.getId();
      const timeToDate = timeLog[questionId] ?? 0;

      // If we're asking for the currently tracked question,
      // we add any previously tracked time to the current time
      if (currQuestionId === questionId) {
        const now = new Date();
        const elapsed = now - startTime;
        return timeToDate + elapsed;
      }

      // Otherwise we return the entry in the timeLog
      return timeToDate;
    },
    getTimeLog() {
      return timeLog;
    }
  }

  if (initialQuestion) {
    methods.handleQuestionChange(initialQuestion);
  }

  return methods;
}
