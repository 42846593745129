import {QuestionSetModelV1} from 'resources/GeneratedModels/QuestionSet/QuestionSetModel.v1';

import {questionSetExtensions} from './questionSet.extensions';

QuestionSetModelV1.extend(questionSetExtensions, {
  hasMultipleQuestions() {
    return this.getQuestions().size > 1;
  }
});

export {QuestionSetModelV1};
