import {StateCreator} from 'zustand';

import type {ContentDiscoveryStoreState} from 'client/components/ContentDiscovery/store/ContentDiscoveryStore.types';

type SaveState = {
  autoSave: boolean;
  hideSaving: boolean;
};

type SaveActions = {
  setAutoSave: (enabled: boolean) => void;
  manualSave: (name: string) => void;
  hasUnsavedChanges: () => boolean;
  reset: () => void;
};

export type SaveSlice = SaveState & SaveActions;

const initialState: SaveState = {
  autoSave: false,
  hideSaving: true
};

export const createSaveSlice: StateCreator<
  ContentDiscoveryStoreState,
  [['zustand/immer', never]],
  [],
  SaveSlice
> = (set, get) => ({
  ...initialState,

  setAutoSave: (enabled) =>
    set((state) => {
      state.save.autoSave = enabled;
    }),

  hasUnsavedChanges: () => {
    const {draftAssignment, draftHasPendingChanges, selectedQuestionSets} = get().draft;
    if (draftAssignment) {
      return draftHasPendingChanges;
    }
    return selectedQuestionSets.size > 0;
  },

  manualSave: (name: string = 'Unnamed draft') => {
    const {selectedQuestionSets, updateDraftQuestionSets, updateDraftName} = get().draft;

    set((state) => {
      state.isLoading = true;
      state.save.autoSave = true;
    });

    if (selectedQuestionSets.size > 0) {
      updateDraftQuestionSets(selectedQuestionSets, name);
    } else {
      updateDraftName(name);
      set((state) => {
        state.isLoading = false;
      });
    }
  },

  reset: () => {
    set((state) => {
      state.save = {...state.save, ...initialState};
    });
  }
});
