import {createActions} from '../framework';

const normalActions = [
  'RESET_STORE',
  'SET_ACTIVE_ITEM',
  /**
   * @todo This looks like an action that is never called.
   */
  'SET_IS_USER_ONBOARDING',
  'SET_PATHNAME',
  'SET_IS_DRAWER_ACTIVE',
  'SET_SHOULD_SHOW_UPGRADE_BUTTON',
  'SET_SHOULD_SHOW_UPGRADE_BUTTON_NO_EMIT'
];

const globalNavigationActions = createActions(null, normalActions);

export default globalNavigationActions;
