import React, {lazy, Suspense} from 'react';
import PropTypes from 'prop-types';
import {LoadingSpinner} from '@albert-io/atomic';

import {toCoordsList} from './entities/entities.utils';
import {flavorTypes, colors, entityTypeNames} from './constants';

import './graphing-input.scss';

const BoardWrapper = lazy(() => import('./BoardWrapper.react'));

const modes = {
  editing: 'editing', // All entities are selectable and editable.
  guessing: 'guessing', // Only studentGuess
  reviewing: 'reviewing'
};

export default function GraphingInput({id, ...rest}) {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <BoardWrapper key={id} id={id} {...rest} />
    </Suspense>
  );
}

GraphingInput.propTypes = {
  id: PropTypes.string.isRequired,
  attributes: PropTypes.object,
  initialEntities: PropTypes.arrayOf(PropTypes.object)
};

export {modes, colors, flavorTypes, entityTypeNames, toCoordsList};
