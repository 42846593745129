import {resource} from '@albert-io/json-api-framework/request/builder';

/**
 * Takes a uuid and returns a query object for a user_v2 object of that ID
 *
 * @param {string} id
 */
export function getBaseUserQuery(id: string) {
  return resource('user_v2')
    .include('school_v4')
    .mandarkEndpoint(['users_v2', id])
    .withMeta('user_v2');
}
