import {format} from 'util';

function getCallerFromTrace(trace) {
  const caller = {
    method: 'unknown',
    filename: 'unknown'
  };
  try {
    const lines = trace.split('\n').map((line) => line.trim());
    const callsite = lines[2].split(' ');
    if (callsite[1]) {
      caller.method = callsite[1];
    }
    if (callsite[2]) {
      caller.filename = callsite[2]
        .split('/')
        .pop()
        .replace(')', '');
    }
  } catch (e) {
    /* ignore */
  }
  return caller;
}

const isProduction = process.env.NODE_ENV === 'production';
/**
 * LOG_LEVEL defaults to `debug` in non-production environments, and `info` elsewhere.
 */
let LOG_LEVEL = isProduction === false ? 'debug' : 'info';
/**
 * process.env.LOG_LEVEL always takes precedence over the default.
 */
if (process.env.hasOwnProperty('LOG_LEVEL')) {
  LOG_LEVEL = process.env.LOG_LEVEL;
}

/**
 * Logger
 *
 * - exposes the same log method API as winston (https://github.com/winstonjs/winston), only supports `console`
 * - uses Node.util.format for message formatting https://nodejs.org/dist/latest-v8.x/docs/api/util.html#util_util_format_format_args()
 *
 * @param {Object} [options={}] configuration for the Logger instance
 * @param {String} [options.level=info] the log level which to log from `LOG.LEVELS`
 * @param {Boolean} [options.stringify] whether or not the output should be sent through JSON.stringify
 */
class Logger {
  constructor(options = {}) {
    options = Object.assign(
      {},
      {
        /**
         * https://docs.npmjs.com/misc/config#loglevel
         */
        levels: {
          error: 0,
          warn: 1,
          info: 2,
          http: 3,
          verbose: 4,
          debug: 5,
          silly: 6
        },
        level: 'info',
        stringify: false
      },
      options
    );

    const LOG_LEVELS = options.levels;
    const LOG_LEVEL = options.level;
    /**
     * The only supported `transport` is `console`
     */
    const transport = global.console ? global.console : false;
    /**
     * when `console` is available, set the default emit method to `console.log`
     */
    const defaultEmitter = typeof transport.log === 'function' ? transport.log : () => {};

    const emitterOverrides = {
      /**
       * console.debug won't always log messages in the browser
       */
      debug: transport ? transport.log : false
    };

    const formatter = options.stringify
      ? (message, data) => {
          return JSON.stringify(format(message, ...data));
        }
      : (message, data) => format(message, ...data);
    /**
     * LOG_LEVEL methods are bound during the constructor to optimize
     * log requests made at lower settings (methods are replaced with no-op functions).
     */
    for (const level in LOG_LEVELS) {
      /**
       * LOG_LEVEL methods are only bound if the processed level is above or
       * equal to the current setting, and there is a valid transport.
       */
      if (!transport || LOG_LEVELS[level] > LOG_LEVELS[LOG_LEVEL]) {
        this[level] = () => {};
      } else {
        let emit = typeof transport[level] === 'function' ? transport[level] : defaultEmitter;
        if (emitterOverrides[level]) {
          emit = emitterOverrides[level];
        }

        this[level] = (message, ...args) => {
          const err = new Error('');
          const trace = err.stacktrace || err.stack || undefined;
          const caller = getCallerFromTrace(trace);
          const pid = process.pid || 'client';
          args.unshift(caller.filename, caller.method || 'unknown');
          emit(formatter(`[${level}][%s][%s][pid:${pid}] ${message}`, args));
        };
      }
    }
  }
}

export default new Logger({
  level: LOG_LEVEL,
  stringify: isProduction
});
