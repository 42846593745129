interface MethodsObject {
  [index: string]: (...args: unknown[]) => unknown;
}

export function verifyNoCollisions(...objs: MethodsObject[]) {
  const methods = objs.reduce((r, obj) => r.concat(Object.keys(obj)), [] as string[]);
  const hasCollisions = methods.some((method, i) => {
    return method.indexOf(method, i + 1) !== -1;
  });
  if (hasCollisions) {
    throw new Error('Colliding methods found in extension objects.');
  }
}
