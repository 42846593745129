import {createActions} from 'client/framework';

export default createActions(null, [
  'CLEAR_ERROR_MESSAGE',
  'CLEAR_SUCCESS_MESSAGE',
  'SET_ERROR_MESSAGE',
  'SET_INITIAL_QUESTION_SETS',
  'SORT_QUESTION_SETS',
  'TOGGLE_QUESTION_SET_REMOVAL',
  'SAVE_TEMPLATE_SET_LIST'
]);
