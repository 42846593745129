import React from 'react';
import PropTypes from 'prop-types';
import {resource, queryBuilderPropType} from '@albert-io/json-api-framework/request/builder';
import LoadingIndicator from 'client/generic/LoadingIndicator.react';
import {renderTimeFromNow} from 'lib/timeUtils';
import classnames from 'classnames';
import {Button, Checkbox, LegacyFindAndApply, ListGroupItem, Modal, Text} from '@albert-io/atomic';
import {invalidateFilters} from 'components/PracticeView/PracticeViewToolbar/Filters/filters.utils';

import EmptyModalContents from './EmptyModalContents/EmptyModalContents.react';
import NewFolderModal from './NewFolderModal/NewFolderModal.react';
import {
  addQuestionSetsToFoldersByQuestionSetIds,
  addQuestionSetsToFoldersByQuery
} from './foldersModal.utils';
import './folders-modal.scss';

/**
 * @param {Object} args
 * @param {string[]} args.folderIds - IDs we're going to add questions to
 * @param {*} args.folders Immutable.List of Folder resources
 * @param {boolean} args.isSelectAllQuestionSetsMode - Whether or not we're adding all questions
 *  (with potential omissions), or individual questions
 * @param {Set<string>} args.selectedQuestionSetIds Set of UUIDs of the question sets the use has selected
 * @param {*} args.questionSetsQuery Query that requested the question sets
 * @param {() => void} args.closeModal Callback to call once we've added the questions so that we may close the modal
 * @param {number} args.selectedQuestionCount number of questions that are currently selected
 * @param args.folderBeingCopiedId
 * @param args.isFiltering
 */
function handleAddToFolders({
  folderIds,
  folders,
  isSelectAllQuestionSetsMode,
  selectedQuestionSetIds,
  questionSetsQuery,
  closeModal,
  selectedQuestionCount,
  folderBeingCopiedId,
  isFiltering
}) {
  if (isSelectAllQuestionSetsMode && !isFiltering) {
    addQuestionSetsToFoldersByQuery({
      folderBeingCopiedId,
      folderIds,
      folders,
      selectedQuestionSetIds,
      questionSetsQuery,
      closeModal,
      selectedQuestionCount
    });
  } else {
    addQuestionSetsToFoldersByQuestionSetIds({
      folderIds,
      folders,
      selectedQuestionSetIds,
      closeModal,
      selectedQuestionCount
    });
  }
  invalidateFilters();
}

const getFolderQuery = (userId) => {
  return resource('template_v1')
    .mandarkEndpoint(['templates_v1'])
    .include('question_sets_v1.subject_v2')
    .customQuery({
      with_meta: 'template_v1'
    })
    .sort('-last_question_set_inserted_at')
    .filter({
      teacher_v1: userId
    })
    .pageSize(250);
};

export default class FoldersModal extends React.Component {
  static propTypes = {
    /** Handles closing overall Modal */
    closeModalFunc: PropTypes.func,
    /** Current user ID for fetching folders */
    userId: PropTypes.string,
    /** Question sets to be added to a folder */
    selectedQuestionSetIds: PropTypes.arrayOf(PropTypes.string),
    /** Count of selected questions being added */
    selectedQuestionCount: PropTypes.number,
    isSelectAllQuestionSetsMode: PropTypes.bool,
    questionSetsQuery: queryBuilderPropType,
    /* Is passed when it is select all mode in the folder PV */
    folderBeingCopiedId: PropTypes.string,
    /** Whether the call site's dependencies are ready */
    isReady: PropTypes.bool,
    /** If the question set view has any applied filters */
    isFiltering: PropTypes.bool
  };

  static defaultProps = {
    isReady: true
  };

  constructor() {
    super();
    this.state = {
      selectedFolders: new Set([]),
      showNewFolderModal: false,
      folders: null,
      isResourceReady: false
    };
  }

  componentDidMount() {
    this.getFolders();
  }

  getFolders = (callback) => {
    getFolderQuery(this.props.userId)
      .getResourcePromise()
      .then((folders) => {
        if (typeof callback === 'function') {
          callback(folders);
        }
        this.setState({
          folders,
          isResourceReady: true
        });
      });
  };

  /**
   * Custom checkHandler for LegacyFindAndApply Component
   * that also updates SET of selected folders on this component state
   *
   * @param e
   */
  handleCheck = (e) => {
    const {selectedFolders} = this.state;
    const {id} = e.target.dataset;
    if (e.target.checked) {
      selectedFolders.add(id);
    } else {
      selectedFolders.delete(id);
    }
    this.setState({selectedFolders});
    return {
      selectedItems: selectedFolders
    };
  };

  /**
   * Clears folders from state forcing us to do another fetch
   */
  clearFolders = () => {
    this.setState({
      folders: null,
      isResourceReady: false
    });
  };

  toggleNewFolderModal = () => {
    this.setState((prevstate) => ({showNewFolderModal: !prevstate.showNewFolderModal}));
  };

  /**
   * Creates a new folder and invalidates the resource to force auto update
   * Adds callback to getFolders func to add the created folder to selectedFolders
   */
  onSaveNewFolderSuccess = () => {
    this.setState({
      isResourceReady: false
    });
    this.getFolders((folders) => {
      const {selectedFolders} = this.state;
      const newFolder = folders.first();
      const updatedFolders = new Set(selectedFolders.add(newFolder.get('id')));
      this.setState({selectedFolders: updatedFolders});
    });
  };

  /**
   * Creates the List of Folders to be rendered
   *
   * @param  {List} foldersList - list of folder IDs
   * @param  {Function} onCheck - function from LegacyFindAndApply given to each checkbox
   * @param selectedItems
   */
  getFoldersListGroup = (foldersList, onCheck, selectedItems) => {
    return foldersList.map((folder, i) => {
      const isChecked = selectedItems.has(folder.getId());
      const updatedAt = folder.getMeta().getQuestionSetRelationshipUpdatedAt();
      const modifiedString = renderTimeFromNow(updatedAt, 'Modified');

      return (
        <ListGroupItem className='folders-list-group__item' key={i}>
          <Checkbox data-id={folder.get('id')} onChange={onCheck} checked={isChecked} />
          <div className='folders-list-group__item-text'>
            <div className='folders-list-group__item-title'>
              <Text bold>{folder.get('name')}</Text>
              <Text color='tertiary' size='s'>
                {folder.getMeta().getCountOfQuestions()} questions
              </Text>
            </div>
            <Text className='folders-list-group__time-string' color='tertiary' size='s'>
              {modifiedString}
            </Text>
          </div>
        </ListGroupItem>
      );
    });
  };

  render() {
    const {
      selectedQuestionSetIds,
      selectedQuestionCount,
      closeModalFunc,
      isSelectAllQuestionSetsMode,
      questionSetsQuery,
      folderBeingCopiedId,
      isFiltering
    } = this.props;

    const {folders, isResourceReady, selectedFolders} = this.state;

    const foldersList = isResourceReady ? folders : null;

    return (
      <Modal
        isLoading={!isResourceReady || !this.props.isReady}
        ariaLabel='Find and apply'
        handleClose={closeModalFunc}
      >
        {({CloseButtonWrapper, modalContentStyle, isLoading}) => {
          return (
            <LegacyFindAndApply
              className={classnames([modalContentStyle, 'folders-modal'])}
              defaultSelectedItems={selectedFolders}
              customOnCheck={this.handleCheck}
            >
              <LegacyFindAndApply.Header className='folders-modal__header'>
                <Button
                  size='s'
                  disabled={isLoading}
                  onClick={this.toggleNewFolderModal}
                  className='u-mar_auto'
                  variant='text'
                >
                  New folder
                </Button>
                {this.state.showNewFolderModal && (
                  <NewFolderModal
                    handleClose={this.toggleNewFolderModal}
                    onSaveSuccess={this.onSaveNewFolderSuccess}
                  />
                )}
              </LegacyFindAndApply.Header>
              <LegacyFindAndApply.Body className='folders-list-group__container'>
                {({onCheck, searchString, selectedItems}) => {
                  if (isLoading) {
                    return <LoadingIndicator />;
                  }
                  const standardizedSearchString = searchString.toLowerCase();
                  const results = foldersList.filter(
                    (item) =>
                      !standardizedSearchString ||
                      item.name.toLowerCase().indexOf(standardizedSearchString) !== -1
                  );
                  if (results.isEmpty()) {
                    return (
                      <EmptyModalContents
                        folders={foldersList}
                        showNewFoldersModal={this.toggleNewFolderModal}
                      />
                    );
                  }
                  const foldersListGroup = this.getFoldersListGroup(
                    results,
                    onCheck,
                    selectedItems
                  );

                  return foldersListGroup;
                }}
              </LegacyFindAndApply.Body>
              <LegacyFindAndApply.Footer>
                {({selectedItems}) => {
                  const selectedItemsString = selectedItems.size && `(${selectedItems.size})`;
                  return (
                    <LegacyFindAndApply.BtnGroup>
                      <CloseButtonWrapper>
                        <Button disabled={isLoading} color='secondary'>
                          Cancel
                        </Button>
                      </CloseButtonWrapper>
                      <Button
                        className='folders-modal__save-button'
                        color='primary'
                        disabled={!selectedItems.size}
                        onClick={() =>
                          handleAddToFolders({
                            folderIds: selectedItems,
                            folders,
                            folderBeingCopiedId,
                            isSelectAllQuestionSetsMode,
                            selectedQuestionSetIds,
                            questionSetsQuery,
                            selectedQuestionCount,
                            isFiltering,
                            closeModal: closeModalFunc
                          })
                        }
                      >
                        Save {selectedItemsString}
                      </Button>
                    </LegacyFindAndApply.BtnGroup>
                  );
                }}
              </LegacyFindAndApply.Footer>
            </LegacyFindAndApply>
          );
        }}
      </Modal>
    );
  }
}
