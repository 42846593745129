/* eslint-disable import/prefer-default-export */
// @flow
import {List, Map} from 'immutable';

export function getSubjectViewLinks({
  subjectSlug,
  navConfig
}: {
  subjectSlug: string,
  navConfig: Map<string, boolean>
}): List<Map<string, *>> {
  let subjectViewLinks = List();

  const hasStudyGuide = navConfig.get('studyGuide');
  if (hasStudyGuide) {
    subjectViewLinks = subjectViewLinks.push(
      Map({
        name: 'Practice',
        to: `/${subjectSlug}`
      })
    );
  }

  const hasFRQ = navConfig.get('FRQ');
  const hasFAQ = navConfig.get('FAQ');
  const hasTours = navConfig.get('Tours');
  const hasAssessmentGuide = navConfig.get('assessments');

  if (!hasFAQ && !hasFRQ && !hasAssessmentGuide && !hasTours) {
    return subjectViewLinks;
  }

  subjectViewLinks = subjectViewLinks.setIn([0, 'match'], new RegExp(`^\/${subjectSlug}$`));

  if (hasFRQ) {
    subjectViewLinks = subjectViewLinks.push(
      Map({
        name: 'Free Response',
        to: `/${subjectSlug}/free-response`,
        match: new RegExp(`^\/${subjectSlug}\/free-response`)
      })
    );
  }

  if (hasAssessmentGuide) {
    subjectViewLinks = subjectViewLinks.push(
      Map({
        name: 'Assessments',
        to: `/${subjectSlug}/assessments`,
        match: new RegExp(`^\/${subjectSlug}\/assessments`)
      })
    );
  }

  if (hasFAQ) {
    subjectViewLinks = subjectViewLinks.push(
      Map({
        name: 'Overview',
        to: `/${subjectSlug}/overview`,
        match: new RegExp(`^\/${subjectSlug}\/overview`)
      })
    );
  }

  if (hasTours) {
    subjectViewLinks = subjectViewLinks.push(
      Map({
        name: 'Tours',
        to: `/${subjectSlug}/tours`,
        match: new RegExp(`^\/${subjectSlug}\/tours`)
      })
    );
  }

  return subjectViewLinks;
}
