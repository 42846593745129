import {List, Map} from 'immutable';

export default function getQuestionDifficultyTotalsFromList(difficulties: List<number>) {
  return difficulties.reduce((reduction = Map(), value) => {
    if (value === undefined) {
      return reduction;
    }

    const valueAsString = value.toString();

    const newReduction = reduction.set(
      valueAsString,
      reduction.get(valueAsString, List()).push(value)
    );

    return newReduction;
  }, Map<string, List<number>>());
}
