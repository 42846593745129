// @flow
import type {Map} from 'immutable';
import constants from 'client/constants';
import type {AuthoringSupplement as AuthoringSupplementModel} from 'resources/GeneratedModels/AuthoringSupplement/AuthoringSupplementModel.v1';
import type {Supplement as SupplementModel} from 'resources/GeneratedModels/Supplement/SupplementModel.v1';

type Location = {
  path: string,
  host: string,
  href: string
};

const requiredAttributesByType = {
  audio: ['audio'],
  'free-form': ['text'],
  image: ['image'],
  passage: ['text'],
  'sortable-table': ['text'],
  video: ['videoId']
};

export const baseSupplementExtensions = {
  getAlttext(): string {
    return this.getContent().get('alttext');
  },

  getAudio(): string {
    return this.getContent().get('audio');
  },

  getCaption(): string {
    return this.getContent().get('caption');
  },

  getCropValues(): Map<string, *> {
    const keySet = ['x', 'y', 'width', 'height'];
    return this.getContent().filter((value, key) => keySet.includes(key));
  },

  getDomain(): string | null {
    return this.getSubject().isEmpty() ? null : this.getSubject().getDomain();
  },

  getExcludeWhiteSpace(): boolean {
    return this.getContent().get('excludeWhiteSpace');
  },

  getHasTotal(): boolean {
    return this.getContent().get('hasTotal');
  },

  getHideLineNumbers(): boolean {
    return this.getContent().get('hideLineNumbers');
  },

  getIgnoreWhiteSpace(): boolean {
    return this.getContent().get('ignoreWhiteSpace');
  },

  /**
   * `getImageSize` is the scale percentage of the original asset (0-100).
   */
  getImageSize(): string {
    return this.getContent().get('imageSize', 100);
  },

  getImageUrl(): string {
    return this.getContent().get('image');
  },
  /**
   * Returns a `Location` object representing the location of the supplement.
   */
  getImageLocation(): Location {
    const host = constants.USER_ASSETS_ORIGIN;
    return {
      path: this.getContent().get('image'),
      /* eslint-disable no-undef */
      host,
      href: `${host}/${this.getContent().get('image')}`
      /* eslint-enable no-undef */
    };
  },

  mightBePDF(): boolean {
    return this.getImageUrl() && this.getImageUrl().toLowerCase().endsWith('.pdf');
  },

  getText(): string {
    return this.getContent().get('text');
  },

  getType(): string {
    return this.getContent().get('type');
  },

  getValueForContentAttribute(attribute: string, def: *): * {
    return this.getContent().get(attribute, def);
  },

  getVideoId(): string {
    return this.getContent().get('videoId');
  },

  isAttributeRequired(attribute: string): boolean {
    if (!this.getType()) {
      return false;
    }
    return requiredAttributesByType[this.getType()].includes(attribute);
  },

  setAlttext(value: *): AuthoringSupplementModel | SupplementModel {
    const content = this.getContent().set('alttext', value);
    return this.setField('content', content);
  },

  setAudio(value: *): AuthoringSupplementModel | SupplementModel {
    const content = this.getContent().set('audio', value);
    return this.setField('content', content);
  },

  setCaption(value: *): AuthoringSupplementModel | SupplementModel {
    const content = this.getContent().set('caption', value);
    return this.setField('content', content);
  },

  setCropValues(value: {
    x: number,
    y: number,
    width: number,
    height: number
  }): AuthoringSupplementModel | SupplementModel {
    const content = this.getContent().merge(value);
    return this.setField('content', content);
  },

  setExcludeWhiteSpace(value: boolean): AuthoringSupplementModel | SupplementModel {
    const content = this.getContent().set('excludeWhiteSpace', value);
    return this.setField('content', content);
  },

  setHasTotal(value: boolean): AuthoringSupplementModel | SupplementModel {
    const content = this.getContent().set('hasTotal', value);
    return this.setField('content', content);
  },

  setHideLineNumbersOnContentMap(value: boolean): AuthoringSupplementModel | SupplementModel {
    const content = this.getContent().set('hideLineNumbers', value);
    return this.setField('content', content);
  },

  setIgnoreWhiteSpace(value: boolean): AuthoringSupplementModel | SupplementModel {
    const content = this.getContent().set('ignoreWhiteSpace', value);
    return this.setField('content', content);
  },

  setImageSize(value: *): AuthoringSupplementModel | SupplementModel {
    const content = this.getContent().set('imageSize', value);
    return this.setField('content', content);
  },

  setImageUrl(value: *): AuthoringSupplementModel | SupplementModel {
    const content = this.getContent().set('image', value);
    return this.setField('content', content);
  },

  setText(value: *): AuthoringSupplementModel | SupplementModel {
    const content = this.getContent().set('text', value);
    return this.setField('content', content);
  },

  setType(value: *): AuthoringSupplementModel | SupplementModel {
    const content = this.getContent().set('type', value);
    return this.setField('content', content);
  },

  setVideoId(value: *): AuthoringSupplementModel | SupplementModel {
    const content = this.getContent().set('videoId', value);
    return this.setField('content', content);
  }
};
