import React from 'react';
import PropTypes from 'prop-types';
import './collapsible-area.scss';

export class CollapsibleArea extends React.Component {
  static propTypes = {
    summary: PropTypes.node,
    content: PropTypes.node,
    isDefaultOpen: PropTypes.bool
  };

  render() {
    return (
      <details className='collapsible-area' open={this.props.isDefaultOpen}>
        <summary className='collapsible-area__summary'>{this.props.summary}</summary>
        {this.props.content}
      </details>
    );
  }
}
