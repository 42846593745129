import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {List} from 'immutable';
import classnames from 'classnames';

import {getModelForResourceType} from 'resources/modelRegistry';
import {invalidatePartialInterest} from 'resources/mandark.resource';
import {
  Anchor,
  IconButton,
  ListGroup,
  ListGroupItem,
  Pagination,
  Text,
  useTooltipWithLiveRegion
} from '@albert-io/atomic';
import {getActiveClassroomsWithSubjectsQuery} from 'client/generic/SubjectMenuRenderer/helpers/queries';

import FreePracticeOptionToggle from './FreePracticeOptionToggle.react';

const FreePracticeOptionsHeader = (props) => {
  const tooltipAnchorRef = useRef();
  const {
    closeTooltip,
    handleEscape,
    openTooltip,
    tooltipContentRef,
    tooltipProps,
    TooltipWithLiveRegion
  } = useTooltipWithLiveRegion(tooltipAnchorRef);
  return (
    <ListGroupItem className='u-display_flex u-justify-content_space-between' {...props}>
      <Text size='s' color='tertiary'>
        Allow independent practice
      </Text>
      <IconButton
        className='u-align-self_center'
        icon={['far', 'question-circle']}
        label='More info'
        size='s'
        onBlur={closeTooltip}
        onClick={(e) => {
          e.stopPropagation();
          openTooltip();
        }}
        onKeyDown={handleEscape}
        ref={tooltipAnchorRef}
      />
      <TooltipWithLiveRegion {...tooltipProps} placement='right'>
        <span ref={tooltipContentRef}>
          By allowing independent practice, your students can practice and view answers to any
          question found under this subject&apos;s Practice tab.
        </span>
      </TooltipWithLiveRegion>
    </ListGroupItem>
  );
};

const FreePracticeOptions = ({
  className,
  classrooms,
  isAssessmentsOnly,
  subject,
  isUserLicensedTeacher,
  ...rest
}) => {
  const queriesToInvalidateRef = useRef({});
  const [page, setPage] = useState(1);

  useEffect(() => {
    const queries = queriesToInvalidateRef.current;

    return () => {
      if (Object.values(queries).length > 0) {
        invalidatePartialInterest({
          resourcePath: getActiveClassroomsWithSubjectsQuery().done().resourcePath
        });
        Object.values(queries).forEach((fn) => fn());
      }
    };
  }, []);

  const isClassroomLicensed = useCallback(
    (classroom) => {
      const licensedSubjects = classroom.getMeta().getSchoolLicensedSubjects();
      const groupLicensedSubjects = licensedSubjects.get('subject_group_subject_ids');
      const standaloneLicensedSubjects = licensedSubjects.get(
        'standalone_school_license_subject_ids'
      );
      return (
        groupLicensedSubjects.includes(subject.getId()) ||
        standaloneLicensedSubjects.includes(subject.getId())
      );
    },
    [subject]
  );

  const classroomsAndStatuses = useMemo(
    () =>
      classrooms
        .sort((a, b) => {
          if (isClassroomLicensed(a) && !isClassroomLicensed(b)) {
            return -1;
          }
          if (!isClassroomLicensed(a) && isClassroomLicensed(b)) {
            return 1;
          }
          return a.getName().localeCompare(b.getName());
        })
        .reduce((prev, curr) => {
          let checked;
          const currentSubject = curr.getSubjects().find((s) => s.getId() === subject.getId());
          const classroomAndSubjectAreUnrelated = currentSubject === undefined;

          if (classroomAndSubjectAreUnrelated) {
            checked = false;
          } else {
            checked =
              currentSubject.getClassroomRelationships(curr.getId()).getRestrictFreePractice() !==
              true;
          }

          return prev.push([curr, checked]);
        }, List()),
    [classrooms, subject, isClassroomLicensed]
  );

  const isSubjectLicensed = useMemo(
    () => classrooms.filter(isClassroomLicensed).size !== 0,
    [classrooms, isClassroomLicensed]
  );

  const handleChange = useCallback((classroomId) => {
    queriesToInvalidateRef.current[classroomId] = () =>
      invalidatePartialInterest({
        resourcePath: ['classrooms_v1', classroomId]
      });
  }, []);

  const pageSize = 10;

  const start = page * pageSize - pageSize;
  const end = start + pageSize;

  const totalPages = Math.ceil(classroomsAndStatuses.size / pageSize);
  const classroomsAndStatusesToShow = classroomsAndStatuses
    .map(([classroom]) => classroom.getId())
    .slice(start, end);

  if (!isUserLicensedTeacher) {
    return (
      <ListGroup className={classnames('u-bgc_white', className)} role='list' {...rest}>
        <FreePracticeOptionsHeader role='listitem' />
        <ListGroupItem role='listitem'>
          <Text as='p' size='s' className='u-mar_2'>
            You can provide students with independent practice for any classroom that is associated
            with a school with an active school license.{' '}
            <Anchor to='/account/access'>View instructional license details.</Anchor>
            &nbsp;
          </Text>
        </ListGroupItem>
      </ListGroup>
    );
  }

  if (classrooms.size === 0 || !isSubjectLicensed) {
    return (
      <ListGroup className={classnames('u-bgc_white', className)} role='list' {...rest}>
        <FreePracticeOptionsHeader role='listitem' />
        <ListGroupItem role='listitem'>
          <Text as='p' size='s' className='u-mar_2'>
            Start by making a class in your <Anchor to='/classes'>class dashboard.</Anchor>
          </Text>
        </ListGroupItem>
      </ListGroup>
    );
  }

  return (
    <>
      <ListGroup className={classnames('u-bgc_white', className)} role='listbox' {...rest}>
        <FreePracticeOptionsHeader />
        {isAssessmentsOnly ? (
          <ListGroupItem role='option'>
            <Text
              as='div'
              className='u-pad_2 u-cursor_auto'
              color='tertiary'
              italic
              onClick={(e) => e.preventDefault()}
            >
              Not available for state assessments
            </Text>
          </ListGroupItem>
        ) : (
          <>
            {classroomsAndStatuses.map(([classroom, checked]) => {
              const hidden = !classroomsAndStatusesToShow.includes(classroom.getId());
              return (
                <ListGroupItem
                  role='option'
                  aria-hidden={hidden}
                  className={hidden ? 'u-display_none' : null}
                  key={classroom.getId()}
                >
                  <FreePracticeOptionToggle
                    classroom={classroom}
                    defaultChecked={checked}
                    onChange={handleChange}
                    subject={subject}
                    disabled={!isClassroomLicensed(classroom)}
                  />
                </ListGroupItem>
              );
            })}
            {classroomsAndStatuses.size > pageSize && (
              <ListGroupItem role='navigation'>
                <Pagination
                  variation='mini'
                  align='center'
                  defaultPage={page}
                  key={`${page}+${totalPages}`}
                  onChange={(val) => setPage(val)}
                  totalPages={totalPages}
                />
              </ListGroupItem>
            )}
          </>
        )}
      </ListGroup>
    </>
  );
};

FreePracticeOptions.propTypes = {
  className: PropTypes.string,
  classrooms: ImmutablePropTypes.listOf(
    PropTypes.instanceOf(getModelForResourceType('classroom_v1'))
  ),
  isAssessmentsOnly: PropTypes.bool,
  isUserLicensedTeacher: PropTypes.bool,
  subject: PropTypes.instanceOf(getModelForResourceType('subject_v2'))
};

export default FreePracticeOptions;
