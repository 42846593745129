import React from 'react';
import {List} from 'immutable';
import {upperFirst, stripNonAlphaNumeric} from 'lib/stringUtils';
import appStore from 'client/AppStore';
import sessionStore from 'client/Session/SessionStore';
import SearchDropdown from 'sg/Dropdowns/SearchDropdown/SearchDropdown.react';
import awaitMandarkQueries from 'lib/hocs/awaitMandarkQueries';

import {resource} from '@albert-io/json-api-framework/request/builder';

import {SEARCH_PARAMETERS, getURLSearchParameter} from '../URLSearchParameters';

interface Props extends PropsWithClassNameOptional {
  handleOptionChange: (option: any) => any;
  showQuestionCount?: boolean;
  storeName: string;
  subjects: List<any>;
  queryParamName: string;
  defaultSelectedId: string;
}

const getSubjectsQuery = (authorId?: string) =>
  resource('authoring_subjects_v1')
    .mandarkEndpoint(
      authorId ? ['authors_v1', authorId, 'authoring_subjects_v1'] : ['authoring_subjects_v1']
    )
    .pageSize('infinite')
    .sort('domain,name');

const getSubjectsWithMetaQuery = () => {
  const userId = sessionStore.getUserId();
  return (
    getSubjectsQuery()
      .withMeta('authoring_subject_v1')
      /**
       * Since `owner_` is not _actually_ a defined resource (maybe it will be),
       * we have to use `.customQuery` instead of `.metaContext`
       */
      .customQuery(
        {
          meta: {
            context: {
              owner: {
                id: userId
              }
            }
          }
        },
        sessionStore.isSuper() === false
      )
      .customQuery({
        meta: {
          context: {
            subject: {
              counts: true
            }
          }
        }
      })
  );
};

const SubjectSearchDropdown = ({
  className,
  handleOptionChange,
  showQuestionCount,
  storeName,
  subjects,
  queryParamName,
  defaultSelectedId
}: Props) => {
  const getDefaultOption = () => {
    const subjectId = defaultSelectedId || appStore.routerProps.location.query[queryParamName];
    const subjectQuery =
      subjectId &&
      resource('authoring_subject_v1').mandarkEndpoint(['authoring_subjects_v1', subjectId]);
    if (!subjectId || !subjectQuery.isResourcePopulated()) {
      return '';
    }
    return subjectQuery.getResource();
  };

  const filterOptions = (option: any, searchString: string) => {
    return stripNonAlphaNumeric(
      `${option.get('domain')} ${option.get('name')}`.toLowerCase()
    ).includes(stripNonAlphaNumeric(searchString.toLowerCase()));
  };

  const getFilteredCountOfQuestions = (option: any) => {
    const status = getURLSearchParameter(SEARCH_PARAMETERS.status);
    const method = status ? `getCountOf${upperFirst(status)}Questions` : `getCountOfQuestions`;
    return option.getMeta()[method]();
  };

  const renderOptions = (option: any) => {
    let displayCount: any;
    if (showQuestionCount && getSubjectsWithMetaQuery().isResourcePopulated()) {
      const subjectsWithCounts = getSubjectsWithMetaQuery().getResource().asMap();
      const count = subjectsWithCounts.has(option.getId())
        ? getFilteredCountOfQuestions(subjectsWithCounts.get(option.getId()))
        : 0;
      displayCount = `(${count})`;
    } else if (showQuestionCount) {
      displayCount = <span className='fa fa-spinner fa-pulse fa-fw' />;
    }

    return (
      <div>
        <small>{option.get('domain')}</small>
        <br />
        {option.get('name')} {displayCount}
      </div>
    );
  };

  return (
    <SearchDropdown
      openOnFocus
      resetOnUnmount
      className={className}
      customClearOption=''
      defaultOption={getDefaultOption()}
      displayName='name'
      filterFunc={filterOptions}
      handleOptionChange={handleOptionChange}
      label='Subject'
      options={subjects}
      placeholder='All Subjects'
      renderFunc={renderOptions}
      storeName={storeName}
    />
  );
};

export default awaitMandarkQueries(
  (props: any) => ({
    queries: {
      subjects: getSubjectsQuery(props.authorId)
    }
  }),
  SubjectSearchDropdown
);
