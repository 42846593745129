import React, {lazy} from 'react';
import {fromJS} from 'immutable';
import {Route} from 'react-router';

import {requireSession} from 'lib/decorators/requireSession';
import sessionStore from 'client/Session/SessionStore';
import Subheader from 'generic/Subheader/Subheader.react';

const BatchSchoolLicenses = lazy(() => import('./BatchSchoolLicenses.react'));
const BatchSchools = lazy(() => import('./BatchSchools.react'));
const BatchUsers = lazy(() => import('./BatchUsers.react'));
const BatchConfirmUsers = lazy(() => import('./Users/BatchConfirmUsers.react'));
const BatchCOPPAConsent = lazy(() => import('./Users/BatchCOPPAConsent.react'));
const BatchClassrooms = lazy(() => import('./Classrooms/BatchClassrooms.react'));
const BatchClassroomSubjects = lazy(() => import('./Classrooms/BatchClassroomSubjects.react'));
const BatchAssign = lazy(() => import('./Classrooms/BatchAssign.react'));
const BatchEnrollStudents = lazy(() => import('./BatchEnrollStudents.react'));

const Batch = requireSession({
  rolePredicate: () => sessionStore.isSuper()
})((props) => (
  <div>
    <Subheader
      links={fromJS([
        {
          name: 'Schools',
          route: '/dennis/batch/schools'
        },
        {
          name: 'School Licenses',
          route: '/dennis/batch/school-licenses'
        },
        {
          name: 'Users',
          route: '/dennis/batch/users'
        },
        {
          name: 'Users : Confirm',
          route: '/dennis/batch/users/confirm'
        },
        {
          name: 'Users : COPPA',
          route: '/dennis/batch/users/coppa'
        },
        {
          name: 'Classrooms',
          route: '/dennis/batch/classrooms'
        },
        {
          name: 'Classroom : Subjects',
          route: '/dennis/batch/classroom/subjects'
        },
        {
          name: 'Classroom : Assign',
          route: '/dennis/batch/classroom/assign'
        },
        {
          name: 'Enroll Students',
          route: '/dennis/batch/enroll-students'
        }
      ])}
    />
    {props.children}
  </div>
));

export default (
  <Route path='batch' component={Batch}>
    <Route path='school-licenses' component={BatchSchoolLicenses} />
    <Route path='schools' component={BatchSchools} />
    <Route path='users' component={BatchUsers} />
    <Route path='users/confirm' component={BatchConfirmUsers} />
    <Route path='users/coppa' component={BatchCOPPAConsent} />
    <Route path='classrooms' component={BatchClassrooms} />
    <Route path='classroom/subjects' component={BatchClassroomSubjects} />
    <Route path='classroom/assign' component={BatchAssign} />
    <Route path='enroll-students' component={BatchEnrollStudents} />
  </Route>
);
