import React from 'react';
import {Link} from 'react-router';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {isArray} from 'lodash';
import {Checkbox, Icon, ListGroupItem, Text, IconButton, WithTooltip} from '@albert-io/atomic';
import {getQuestionEditPath} from 'client/Dennis/Content/Queue/shared';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import './question.scss';

const boolOrFunctionType = PropTypes.oneOfType([PropTypes.bool, PropTypes.func]);

function withActionEventHandler(callback = () => {}) {
  return (e) => {
    e.preventDefault();
    e.stopPropagation();
    callback(e);
  };
}

export const sharedQuestionPropTypes = {
  showDifficulty: boolOrFunctionType,
  showAttemptIndicators: boolOrFunctionType,
  isCorrect: boolOrFunctionType,
  isIncorrect: boolOrFunctionType,
  isBookmarked: boolOrFunctionType,
  onQuestionClick: PropTypes.func
};

const ClickableIndicator = ({active, isRearrangeElement}) => (
  <div
    className={classnames('clickable-indicator', {
      'clickable-indicator--active': active && !isRearrangeElement
    })}
  />
);

ClickableIndicator.propTypes = {
  active: PropTypes.bool,
  isRearrangeElement: PropTypes.bool
};

const attemptIndicatorResultValues = {
  correct: ['correct', 'check'],
  incorrect: ['incorrect', 'times']
};

const AttemptIndicator = ({result}) => {
  if (!result) {
    return null;
  }
  const [color, icon] = attemptIndicatorResultValues[result];
  return (
    <Icon
      icon={icon}
      color={color === 'correct' ? 'positive' : 'negative'}
      className='question-v2-attempt-indicator'
    />
  );
};

AttemptIndicator.propTypes = {
  result: PropTypes.oneOf(Object.keys(attemptIndicatorResultValues))
};

export default class Question extends React.Component {
  static Checkbox = ({onChange, checked}) => (
    <Checkbox
      className='question-v2-checkbox'
      onClick={withActionEventHandler()}
      onChange={onChange}
      checked={checked}
    />
  );

  static DuplicateSetIcon = ({question, questionSet}) => (
    <WithTooltip
      enabled={!question}
      content='One or more questions in this set have duplicate alignment.'
    >
      <IconButton
        as={Link}
        disabled={!question}
        to={
          question &&
          getQuestionEditPath({
            questionSetId: questionSet.getId(),
            questionId: question.getId(),
            customSearch: {
              status: 'published',
              subject: questionSet.getAuthoringSubject().getId(),
              page: questionSet.getId(),
              fallback_page: 1
            }
          })
        }
        className='u-mar-r_1'
        icon={['far', 'exclamation-triangle']}
      />
    </WithTooltip>
  );

  static propTypes = {
    ...sharedQuestionPropTypes,
    as: PropTypes.string,
    clickable: PropTypes.bool,
    questionPath: PropTypes.string,
    isNonMatchingSearchResult: PropTypes.bool
  };

  /* eslint-disable react/default-props-match-prop-types */
  static defaultProps = {
    as: 'li',
    clickable: true,
    showDifficulty: true,
    showAttemptIndicators: true,
    onQuestionClick: () => {}
  };

  keyElement = (element, i) => {
    return React.cloneElement(element, {key: i});
  };

  getActions(actions) {
    if (!actions) {
      return null;
    }
    return isArray(actions) ? actions.map(this.keyElement) : actions;
  }

  handleQuestionClick = (e) => {
    if (this.props.clickable) {
      this.props.onQuestionClick(e);
    }
  };

  render() {
    const {
      actions,
      as,
      attemptIndicatorValue,
      difficulty,
      hasAccess,
      heading,
      isActive,
      isBookmarked,
      showAttemptIndicators,
      showDifficulty,
      strikethroughQuestionInfo,
      title,
      questionPath,
      isNonMatchingSearchResult,
      isSelectable,
      isRearrangeable,
      clickable,
      dragHandle
    } = this.props;
    const WrapperEl = questionPath ? Link : 'div';
    return (
      <ListGroupItem
        as={as}
        className={classnames('question-v2', {
          'question-v2--non-matching-result': isNonMatchingSearchResult,
          'question-v2--selectable': isSelectable,
          'question-v2--clickable': clickable,
          'question-v2--rearrangeable': isRearrangeable
        })}
        paddingLevel={0}
      >
        <WrapperEl
          className='question-v2__wrapper'
          onClick={this.handleQuestionClick}
          to={questionPath}
        >
          {isRearrangeable ? (
            dragHandle
          ) : (
            <div className='question-v2__actions'>{this.getActions(actions)}</div>
          )}
          <div
            className={classnames('question-v2__meta', {
              'question-v2__meta--strikethrough': strikethroughQuestionInfo
            })}
          >
            <Text
              as={MarkdownRendererV2}
              size='m'
              bold={heading}
              className='question-v2__title'
              text={title}
              color={isNonMatchingSearchResult ? 'secondary' : 'primary'}
            />
            {difficulty && showDifficulty && (
              <Text size='xs' color='secondary' className='question-v2__difficulty'>
                {isNonMatchingSearchResult ? 'This question did not match your search' : difficulty}
              </Text>
            )}
          </div>
          <div className='question-v2__info'>
            {showAttemptIndicators && <AttemptIndicator result={attemptIndicatorValue} />}
            {!hasAccess && (
              <Icon icon='lock' color='tertiary' className='question-v2-locked-indicator' />
            )}
            {isBookmarked && (
              <Icon icon='bookmark' className='question-v2-saved-info-el u-color_purple-200' />
            )}
          </div>
        </WrapperEl>
        {clickable && (
          <ClickableIndicator
            active={isActive}
            isRearrangeElement={this.props.isActiveRearrangeElement}
          />
        )}
      </ListGroupItem>
    );
  }
}
