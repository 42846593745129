import React, {useCallback} from 'react';
import {round} from 'lodash';
import TextInput from 'generic/Forms/TextInput/TextInput';
import Button from 'sg/Button/Button.react';
import {AuthoringQuestionModelV1} from 'resources/augmented/AuthoringQuestion/AuthoringQuestionModel.v1';

interface Props {
  disabled?: boolean;
  answer: string;
  inputId: string;
  index: number;
  question: AuthoringQuestionModelV1;
  onModelChange: (question: AuthoringQuestionModelV1, field?: string) => void;
}

const CorrectAnswer = ({answer, inputId, index, question, onModelChange, disabled}: Props) => {
  const updateAnswer = useCallback(
    (e) => {
      const validResponse = question.getValidResponse().setIn([inputId, index], e.target.value);
      const updatedQuestion = question.setValidResponse(validResponse);

      onModelChange(updatedQuestion, 'inputs');
    },
    [question, inputId, index, onModelChange]
  );

  const removeAnswer = useCallback(() => {
    let validResponse = question.getValidResponse().removeIn([inputId, index]);

    if (validResponse.get(inputId).isEmpty()) {
      validResponse = validResponse.remove(inputId);
    }

    const updatedQuestion = question.setValidResponse(validResponse);
    onModelChange(updatedQuestion, 'inputs');
  }, [question, inputId, index, onModelChange]);

  const generateThresholdAcceptance = () => {
    if (question.getRubric().get('type') !== 'mathContains' || !answer) {
      return null;
    }
    const numericAnswer = parseFloat(answer);
    const answerThreshold = numericAnswer * (question.getRubricThreshold() / 100);
    let acceptanceCriteria;
    if (answerThreshold === 0) {
      acceptanceCriteria = 'The answer must match the value provided precisely.';
    } else {
      const min = round(numericAnswer - answerThreshold, 5);
      const max = round(numericAnswer + answerThreshold, 5);
      acceptanceCriteria = `Acceptance threshold: ${min} thru ${max}`;
    }
    return <small className='free-entry-editor__threshold-acceptance'>{acceptanceCriteria}</small>;
  };

  const label = index === 0 ? 'Preferred Answer' : 'Alternative Correct Answer';

  return (
    <div className='free-entry-editor__input-answer'>
      <div className='free-entry-editor__input-answer-item'>
        <TextInput
          label={label}
          value={answer}
          onChange={updateAnswer}
          name='inputAnswer'
          disabled={disabled}
        />
        {!disabled && (
          <Button onClick={removeAnswer} className='fa fa-trash-o' color='red' outline noBorder />
        )}
      </div>
      {generateThresholdAcceptance()}
    </div>
  );
};

export default CorrectAnswer;
