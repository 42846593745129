import React from 'react';
import {OrderedSet} from 'immutable';
import {callAction, callTargetedAction} from 'client/framework';
import classnames from 'classnames';
import {addToast} from '@albert-io/atomic';
import {drawerActions} from 'client/generic/Drawer/DrawerActions';
import templateListActions from 'client/Assignments/Templates/TemplateList.actions';
import templateListStore from 'client/Assignments/Templates/TemplateList.store';
import vanillaIEQuestionsListStore from '../VanillaIEQuestionsList.store';

import './add-questions-to-templates.scss';

const maxQuestions = 200;
const questionWarningCount = 190;

export default class AddQuestionsToTemplates extends React.Component {
  handleAddToTemplateClick = () => {
    const subject = vanillaIEQuestionsListStore.getCurrentSubject();
    if (!subject || subject.isHidden()) {
      addToast({
        color: 'negative',
        title: 'Error',
        // eslint-disable-next-line max-len
        message:
          'This subject has been unpublished. Classes that previously relied on this subject will no longer be able to use it next year. No new folders can be created from it.',
        permanent: true
      });
    } else {
      this.showTemplateDrawer();
    }
  };

  showTemplateDrawer() {
    callAction(templateListActions.SORT_AND_SET_TEMPLATE_QUESTION_SETS);

    callTargetedAction({
      name: drawerActions.DRAWER_SET_DRAWER_VISIBILITY,
      payload: true,
      targetStore: 'VanillaIEDrawerStore'
    });
  }

  selectAll = (e) => {
    callAction(templateListActions.SELECT_FIRST_N_QUESTIONS, maxQuestions);
    e.stopPropagation();
  };

  selectNone = (e) => {
    callAction(templateListActions.SET_SELECTED_QUESTION_SETS, new OrderedSet());
    e.stopPropagation();
  };

  render() {
    const isSingleQuestionSet = vanillaIEQuestionsListStore.questionSets.size === 1;
    const selectedQuestionSets = templateListStore.getSelectedQuestionSets();
    const selectedQuestionsCount = selectedQuestionSets.reduce(
      (acc, questionSet) => acc + questionSet.getQuestions().size,
      0
    );
    const maxSelectionExceeded = selectedQuestionsCount > maxQuestions;
    const showLimitWarning = selectedQuestionsCount >= questionWarningCount;
    const addQuestionsButtonClassName = classnames(
      'unbutton',
      'add-questions-to-templates__add-button',
      {
        'add-questions-to-templates__add-button--disabled':
          selectedQuestionsCount === 0 || maxSelectionExceeded
      }
    );
    const deselectAllButtonClassName = classnames(
      'unbutton',
      'add-questions-to-templates__select-button',
      {
        'add-questions-to-templates__select-button--disabled': selectedQuestionsCount === 0
      }
    );
    const selectAllButtonClassName = classnames(
      'unbutton',
      'add-questions-to-templates__select-button',
      {
        'add-questions-to-templates__select-button--disabled': showLimitWarning
      }
    );
    /** @todo */
    /* eslint-disable react/button-has-type */
    const selectAllButton = (
      <button
        className={selectAllButtonClassName}
        disabled={showLimitWarning}
        onClick={this.selectAll}
      >
        Select all
      </button>
    );
    const deselectAllButton = (
      <button
        className={deselectAllButtonClassName}
        disabled={selectedQuestionsCount === 0}
        onClick={this.selectNone}
      >
        Deselect all
      </button>
    );
    const limitWarning = (
      <span className='add-questions-to-templates__selection-details--max-limit-warning'>
        Folders can contain a maximum of 200 questions
      </span>
    );
    const addQuestionsButton = (
      <button
        className={addQuestionsButtonClassName}
        disabled={selectedQuestionsCount === 0 || maxSelectionExceeded}
        onClick={this.handleAddToTemplateClick}
      >
        Save to folder(s)
      </button>
    );

    return (
      <div className='add-questions-to-templates__content'>
        {!isSingleQuestionSet ? (
          <div className='add-questions-to-templates__content-selectors'>
            {selectAllButton}
            {deselectAllButton}
          </div>
        ) : null}
        <div className='add-questions-to-templates__selection-details'>
          {`${selectedQuestionsCount} question${selectedQuestionsCount === 1 ? '' : 's'} selected`}
          {showLimitWarning ? limitWarning : null}
        </div>
        {addQuestionsButton}
      </div>
    );
  }
}
