// @flow
import sessionStore from 'client/Session/SessionStore';
import {doesUserBelongToAnyLicensedClassrooms} from 'lib/UserAccessUtil';

import type {QueryBuilder} from '@albert-io/json-api-framework/request/builder';
import {resource, query} from '@albert-io/json-api-framework/request/builder';

import {parseKeywordsFromSearchString, parsePhrasesFromSearchString} from './AdvancedSearch.utils';

export function getStandardsByIdQuery(standards: string): QueryBuilder {
  return resource('standards_v1').mandarkEndpoint(['standards_v1']).filter({
    id: standards
  });
}

export function getTagsByIdQuery(tags: string): QueryBuilder {
  return resource('tags_v1').mandarkEndpoint(['tags_v1']).filter({
    id: tags
  });
}

export function getKeywordsAndPhrasesFilters(
  s: string = '',
  filterModifier: (obj: Object) => Object = (obj) => obj
): Object {
  const search = s;
  const keywords = parseKeywordsFromSearchString(search);
  const phrases = parsePhrasesFromSearchString(search);
  const isTeacherOrEquivalent = sessionStore.isTeacher() || sessionStore.isSuper();
  const isLicensed = doesUserBelongToAnyLicensedClassrooms(true).value;
  const isLicenseHoldingTeacher = isTeacherOrEquivalent && isLicensed;
  // This array will hold all of our keyword and phrase filters.
  let keywordsAndPhrasesFilters = [];

  // If our search contains keywords, we conditionally add a pair of logical filters that
  // will check the question text and the solution text for their presence.
  if (keywords) {
    const keywordFilters = [
      filterModifier({
        document_stems: {
          search_tokens: encodeURIComponent(keywords)
        }
      })
    ];
    if (isLicenseHoldingTeacher) {
      keywordFilters.push(
        filterModifier({
          solution_document_stems: {
            search_tokens: encodeURIComponent(keywords)
          }
        })
      );
    }
    keywordsAndPhrasesFilters = keywordsAndPhrasesFilters.concat(keywordFilters);
  }

  // With keywords done, we move on to search phrases, adding similar pairs of logical filters
  // for each quote-delineated search phrase.
  keywordsAndPhrasesFilters = phrases.reduce((prev, curr) => {
    const anyOf = [
      filterModifier({
        document: {
          search_phrases: encodeURIComponent(curr)
        }
      })
    ];
    if (isLicenseHoldingTeacher) {
      anyOf.push(
        filterModifier({
          solution_document: {
            search_phrases: encodeURIComponent(curr)
          }
        })
      );
    }
    return prev.concat(anyOf);
  }, keywordsAndPhrasesFilters);

  return keywordsAndPhrasesFilters.length > 0
    ? {all_of: [{any_of: keywordsAndPhrasesFilters}]}
    : null;
}

export function getTagsFilters(
  tags: string = '',
  filterModifier: (obj: Object) => Object = (obj) => obj
): Object {
  if (!tags) {
    return null;
  }
  return {
    all_of: [
      decodeURIComponent(tags)
        .split(',')
        .reduce((acc, tag) => {
          return acc.filter({
            all_of: [
              filterModifier({
                search_tags_v1: tag
              })
            ]
          });
        }, query())
        .done().filter
    ]
  };
}

export function getStandardsFilters(
  standards: string = '',
  filterModifier: (obj: Object) => Object = (obj) => obj
): Object {
  if (!standards) {
    return null;
  }
  return {
    all_of: [
      decodeURIComponent(standards)
        .split(',')
        .reduce((acc, standard) => {
          return acc.filter({
            all_of: [
              filterModifier({
                search_standards_v2: standard
              })
            ]
          });
        }, query())
        .done().filter
    ]
  };
}

export function getSearchQuestionsForPracticeView({
  s: search = '',
  standards = '',
  subjectSlug,
  tags = '',
  guideLevelSlug,
  page,
  pageSize,
  guideType = 'practice'
}: {
  s: string,
  standards: string,
  guideLevelSlug: string,
  subjectSlug: string,
  tags: string,
  page: number,
  pageSize: number,
  guideType: string
}): QueryBuilder {
  // $FlowFixMe
  const metaContextForUser = {
    user: {
      id: sessionStore.getUserId()
    },
    // If the user is a teacher, spread the teacher meta
    // context into the object we're passing
    ...(sessionStore.isTeacher() && {
      teacher: {
        id: sessionStore.getUserId()
      }
    }),
    // If the user is a student, spread the student meta
    // context into the object we're passing
    ...(!sessionStore.isTeacher() && {
      student: {
        id: sessionStore.getUserId()
      },
      question: {
        show_active_assignment: true
      }
    })
  };

  // @todo: Update questions_v1 and question_v1 references to v2
  const keywordsAndPhrasesFilters = getKeywordsAndPhrasesFilters(search);
  const tagsFilters = getTagsFilters(tags, (obj) => ({
    question_set_v1: obj
  }));
  const standardsFilters = getStandardsFilters(standards);
  return resource('search_questions_v1')
    .mandarkEndpoint(['search_questions_v1'])
    .include('question_set_v1.guide_levels_v2')
    .include('question_set_v1.guide_levels_v2.guide_v1')
    .fields({
      guide_v1: 'guide_type'
    })
    .filter({
      question_set_v1: {
        guide_levels_v2: {
          nlevel: 1
        }
      }
    })
    .filter({
      question_set_v1: {
        guide_levels_v2: {
          guide_v1: {
            guide_type: guideType
          }
        }
      }
    })
    .filter(keywordsAndPhrasesFilters, keywordsAndPhrasesFilters)
    .filter(tagsFilters, tagsFilters)
    .filter(standardsFilters, standardsFilters)
    .include('question_set_v1.subject_v2')
    .include('question_set_v1.tags_v1')
    .include('question_set_v1.questions_v1')
    .include('question_set_v1.questions_v1.standards_v1')
    .include('question_set_v1.guide_levels_v1')
    .filter({
      question_set_v1: {
        subject_v2: {
          url_slug: subjectSlug
        },
        guide_levels_v1: {
          url_slug: guideLevelSlug
        }
      }
    })
    .withMeta('question_set_v1,question_v1,search_question_v1', sessionStore.hasValidSession())
    .customQuery(
      {
        meta: {
          context: metaContextForUser
        }
      },
      sessionStore.hasValidSession()
    )
    .page(page)
    .pageSize(pageSize);
}

export function getGuideLevelsBySearchParams({
  s,
  tags,
  standards,
  subjectId,
  guideType = 'practice'
}: {
  s: string,
  standards: ?string,
  subjectId: string,
  tags: ?string,
  guideType: string
}): QueryBuilder {
  const guideLevelTagMeta = tags
    ? {
        context: {
          questions: {
            have_tags: tags
          }
        }
      }
    : null;
  const guideLevelStandardsMeta = standards
    ? {
        context: {
          questions: {
            have_standards: standards
          }
        }
      }
    : null;

  const searchPhraseMeta = s
    ? {
        context: {
          questions: {
            match_text: s
          }
        }
      }
    : null;

  return resource('guide_levels_v2')
    .mandarkEndpoint(['subjects_v2', subjectId, 'guide_levels_v2'])
    .filter({
      guide_v1: {
        guide_type: guideType
      }
    })
    .filter(
      {
        'meta.total_matching_questions': {
          not: 0
        }
      },
      s || standards || tags // only filter if searching
    )
    .meta(guideLevelTagMeta, guideLevelTagMeta)
    .meta(guideLevelStandardsMeta, guideLevelStandardsMeta)
    .meta(searchPhraseMeta, searchPhraseMeta)
    .withMeta('guide_level_v2')
    .pageSize('infinite');
}

export function hasStandardsQuery(subjectId: string): QueryBuilder {
  return resource('search_standard_v2')
    .mandarkEndpoint(['search_standards_v2'])
    .filter({
      questions_v3: {
        question_set_v1: {
          subject_v2: {
            id: subjectId
          }
        }
      }
    })
    .pageSize(0);
}

export function hasTagsQuery(subjectId: string): QueryBuilder {
  return resource('search_tags_v1')
    .mandarkEndpoint(['search_tags_v1'])
    .filter({
      question_sets_v1: {
        subject_v2: {
          id: subjectId
        }
      }
    })
    .pageSize(0);
}
