/**
 * # Tab
 *
 * A component that represents a tab for navigation.
 *
 * ## Props<C extends React.ElementType>
 *
 * - `className?: string`: Additional class name for the tab.
 * - `isActive?: boolean`: Determines if the tab is active.
 * - `children: React.ReactNode`: The content of the tab.
 * - `...rest: C`: Any Properties of `React.ElementType`
 *
 * ## Usage
 *
 * ```tsx
 * import { Tab } from './Tab';
 *
 * const MyComponent = () => {
 *   return (
 *     <Tab isActive={true}>
 *       Tab Content
 *     </Tab>
 *   );
 * };
 * ```
 */

import React, {ElementType} from 'react';
import classnames from 'classnames';
import './tab.scss';

interface TabProps extends PropsWithChildrenRequired, PropsWithClassNameOptional {
  isActive?: boolean;
}

type Props<C extends ElementType> = PolymorphicComponentProps<C, TabProps>;

export const Tab = <C extends ElementType = 'div'>({
  as,
  className,
  isActive = false,
  children,
  ...rest
}: Props<C>) => {
  const WrapperElement = as ?? 'div';
  return (
    <WrapperElement
      className={classnames('a-tab', className, {
        'a-tab--selected': isActive
      })}
      {...rest}
    >
      <div className='a-tab--content-wrapper'>{children}</div>
    </WrapperElement>
  );
};

export default Tab;
