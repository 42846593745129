// @flow
import {List, Map, Range} from 'immutable';
import moment from 'moment';
import {Store, getStoreByName, setUpStore} from 'client/framework';
import {alphaComparatorAscending} from 'lib/SortUtil';
import appStore from 'client/AppStore';

import systemTimeOffsetStore from 'generic/SystemTimeOffset/SystemTimeOffset.store';
import SelectableDateRangeStore from 'generic/SelectableDateRange/SelectableDateRange.store';
import {RANGES} from 'generic/SelectableDateRange/shared';

// eslint-disable-next-line import/no-cycle
import classroomV2Store from '../../ClassroomV2.store';

const STORE_NAME = 'TeacherClassroomScopeStore';

class TeacherClassroomScopeStore extends Store {
  constructor(name: string) {
    super(name);

    this._selectableDateRangeStore = setUpStore(
      SelectableDateRangeStore,
      `${STORE_NAME}SelectableDateRange`
    );
  }

  getDefaultDateRange(): Map<string, *> {
    const start = systemTimeOffsetStore.getCurrentTime().subtract(1, 'month');
    return Map({
      name: RANGES.CUSTOM,
      id: RANGES.CUSTOM,
      start_date: start.format('YYYY-MM-DD'),
      end_date: systemTimeOffsetStore.getCurrentTime().format('YYYY-MM-DD')
    });
  }

  getStartDate(): number {
    const startDate = this.getDateRangeDropdownStore().getSelectedItem().get('start_date');
    return moment(startDate).startOf('day').valueOf();
  }

  getEndDate(): number {
    const endDate = this.getDateRangeDropdownStore().getSelectedItem().get('end_date');
    return moment(endDate).endOf('day').valueOf();
  }

  getSelectedSubjects(): List<string> {
    const subjectsDropdownStore = getStoreByName(this.getSubjectsDropdownStoreName());
    return subjectsDropdownStore && !subjectsDropdownStore.getSelectedItem().isEmpty()
      ? subjectsDropdownStore.getSelectedItem().get('value')
      : classroomV2Store
          .getActiveClassroom()
          .getSubjects()
          .map((s) => s.getId());
  }

  getSubjectOptions() {
    const subjects = classroomV2Store
      .getClassroomSubjects()
      .toList()
      .map(
        (subject) =>
          new Map({
            id: subject.getId(),
            name: subject.getName(),
            value: new List([subject.getId()])
          })
      )
      .sort((a, b) => alphaComparatorAscending(a.get('name'), b.get('name')));
    if (subjects.size > 1) {
      const firstSubjectId = subjects.first().get('id');
      const allTimeOptionPlusSubjects = subjects.unshift(
        new Map({
          id: 'all-subjects',
          name: 'All Subjects',
          value: classroomV2Store
            .getActiveClassroom()
            .getSubjects()
            .map((s) => s.getId())
        })
      );
      return [allTimeOptionPlusSubjects, firstSubjectId];
    }
    return [subjects, subjects.first().get('id')];
  }

  getAttemptNumberStore(): Store {
    return getStoreByName(this.getAttemptNumberDropdownStoreName());
  }

  getAttemptNumber(): number {
    const attemptNumberStore = this.getAttemptNumberStore();
    return attemptNumberStore && !attemptNumberStore.getSelectedItem().isEmpty()
      ? attemptNumberStore.getSelectedItem().get('value')
      : 1;
  }

  isMostRecentAttempt(): boolean {
    const attemptNumberStore = this.getAttemptNumberStore();
    if (!attemptNumberStore) {
      return true;
    }
    if (attemptNumberStore.getSelectedItem().isEmpty()) {
      return true;
    }
    // Compares the drop down value to the 'Most Recent' value from getAttemptNumberOptions
    return attemptNumberStore.getSelectedItem().get('value') === null;
  }

  getAttemptNumberOptions(): List<Map<string, *>> {
    // If we have a studentId, we can assume we're in the single student view
    const studentId = appStore.routerProps.params.userId;
    const areStudentsReady = classroomV2Store.areStudentsReady();
    let upperLimit = 1;
    if (areStudentsReady && studentId) {
      upperLimit = classroomV2Store
        .getStudents()
        .asMap()
        .get(studentId)
        .getMeta()
        .getMaxAttemptNumber();
    } else if (areStudentsReady) {
      upperLimit = classroomV2Store.getStudents().getMaxNumberOfAttempts();
    }

    // Range does not include the upperLimit, so we add one in order to get that highest number
    return Range(1, upperLimit + 1)
      .toList()
      .map((num) => {
        return new Map({
          id: num,
          name: `${num}`,
          value: num
        });
      })
      .unshift(
        new Map({
          id: 'most-recent',
          name: 'Most Recent',
          value: null
        })
      );
  }

  getSubjectsDropdownStoreName(): string {
    return `TeacherClassroomScopeSubjectsDropdownStore-${classroomV2Store.getActiveClassroomId()}`;
  }

  getAttemptNumberDropdownStoreName(): string {
    return `TeacherClassroomScopeAttemptNumberDropdownStore-${classroomV2Store.getActiveClassroomId()}`;
  }

  getDateRangeStoreName(): string {
    return this.getDateRangeStore().getName();
  }

  getDateRangeDropdownStoreName(): string {
    return this.getDateRangeDropdownStore().getName();
  }

  getDateRangeStore(): Store {
    return this.getSelectableDateRangeStore().getDateRangeStore();
  }

  getDateRangeDropdownStore(): Store {
    return this.getSelectableDateRangeStore().getSimpleDropdownStore();
  }

  getSelectableDateRangeStore(): Store {
    return this._selectableDateRangeStore;
  }
}

export default new TeacherClassroomScopeStore(STORE_NAME);
