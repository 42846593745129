import {Store} from 'client/framework';
import issuesModalActions from './IssuesModalActions';
import {fromJS} from 'immutable';

class IssuesModalStore extends Store {
  constructor(name) {
    super(name);

    this.initialData = fromJS({
      questionId: '',
      subjectId: '',
      comment: '',
      reportedDevice: '',
      type: ''
    });

    this.setInitialData(this.initialData);
    this.handle(issuesModalActions.MODIFY_ISSUE, this._modifyIssue);
  }

  getQuestionId() {
    return this.readData('questionId');
  }

  getType() {
    return this.readData('type');
  }

  getComment() {
    return this.readData('comment');
  }

  getReportedDevice() {
    return this.readData('reportedDevice');
  }

  getIssue() {
    return this.readData();
  }

  _modifyIssue(changeMap) {
    this.writeData((store) => {
      return store.merge(changeMap);
    });
  }

  clearIssue() {
    this.writeData(this.initialData);
  }
}

export default new IssuesModalStore('IssuesModalStore');
