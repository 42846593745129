import {debounce} from 'lodash';

import CONSTANTS, {BREAKPOINTS as breakpoints} from 'client/constants';

/**
 * The `id` of the DOM element Intercom is placed in.
 */
export const INTERCOM_CONTAINER_ID = 'intercom-container';
/**
 * The Intercom application found in the environment.
 */
export function getIntercomAppId() {
  return CONSTANTS.INTERCOM_APP_ID_TEACHERS;
}
/**
 * Whether or not the current environment has the Intercom library
 */
export function hasIntercom() {
  return global.window.hasOwnProperty('Intercom');
}
/**
 * Returns the Intercom instance from the environment.
 */
export function getIntercom() {
  return global.window.Intercom;
}

let interval;
let FAILED = false;
const MAX_ATTEMPTS = 5;
let callbacks = [];
/**
 * Stacks callbacks while we wait for Intercom to bootstrap.
 * @param {function} callback Callback function to be triggered when Intercom is available.
 */
function waitForIntercom(callback) {
  if (FAILED) {
    return;
  }
  callbacks.push(callback);
  if (interval) {
    return;
  }
  let attempt = 0;
  interval = setInterval(() => {
    attempt += 1;
    logger.debug(`Poll attempt ${attempt} for Intercom.`);
    if (hasIntercom()) {
      clearInterval(interval);
      logger.debug(`Intercom loaded after polling. Triggering ${callbacks.length} callback(s).`);
      callbacks.map((cb) => cb(getIntercom()));
    } else if (attempt >= MAX_ATTEMPTS) {
      logger.debug(`Intercom unavailable after polling. Ignoring ${callbacks.length} callback(s).`);
      clearInterval(interval);
      FAILED = true;
      callbacks = [];
    }
  }, 2000);
}
/**
 * Run a callback function when Intercom (and a condition, when provided) is available.
 * @param {function} callback Callback function to trigger when Intercom is available.
 * @param {boolean} condition An additional condition to check before triggering the callback.
 */
export function withIntercom(callback, condition = true) {
  /**
   * If we're not in browser, or the provided condition is not met, we
   * don't even try to run Intercom.
   */
  if (!process.env.IS_BROWSER || !condition) {
    // logger.debug('Intecom will not run in these conditions.');
    return;
  }
  if (hasIntercom()) {
    logger.debug('Triggering Intercom callback.');
    callback(getIntercom());
  } else {
    waitForIntercom(callback);
  }
}

let hidden = false;
/**
 * Hide Intercom visually _and_ via the Intercom API.
 */
export function hideIntercom() {
  withIntercom((Intercom) => {
    hidden = true;
    Intercom('hide');
    const el = global.document.getElementById(INTERCOM_CONTAINER_ID);
    if (el) {
      el.style.display = 'none';
    }
  });
}
/**
 * Show Intercom visually. This **does not** trigger `show` in the Intercom API as
 * this would make the _chat_ appear... this simply toggles the CTA (button).
 */
export function showIntercom() {
  withIntercom(() => {
    const el = global.document.getElementById(INTERCOM_CONTAINER_ID);
    if (!el) {
      return;
    }
    el.style.display = null;
    hidden = false;
  }, hidden);
}

export function shutdownIntercom() {
  withIntercom((Intercom) => {
    Intercom('shutdown');
  });
}

export function isIntercomCompatibleDevice() {
  return global.innerWidth > breakpoints.s;
}
