// @flow
import {has, get} from 'lodash';

export default function makePayloadOmitter(fieldRules: {[string]: () => boolean}): any {
  return (payload: Object) => {
    Object.entries(fieldRules).forEach(([attribute, shouldOmit]) => {
      const path = `data.attributes.${attribute}`;
      // $FlowFixMe
      if (has(payload, path) && shouldOmit(get(payload, path))) {
        delete payload.data.attributes[attribute];
      }
    }, payload);
    return payload;
  };
}
