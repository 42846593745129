import React from 'react';
import PropTypes from 'prop-types';
import {List} from 'immutable';
import classnames from 'classnames';
import {history} from 'client/history';
import {Link} from 'react-router';
import SimpleDropdown from 'generic/SimpleDropdown/SimpleDropdown.react';
import {navbarLinksPropType} from './shared';
/**
 * Shared Styles
 */
import './assets/horizontal-navigation-component.scss';
/**
 * <Header />
 */
import './assets/header.scss';
/**
 * <SecondaryHeader />
 */
import './assets/secondary-header.scss';
/**
 * <TertiaryHeader />
 */
import './assets/tertiary-header.scss';

export default function HorizontalNavigationComponent(className = 'horizontal-navigation') {
  return class extends React.Component {
    static propTypes = {
      fixed: PropTypes.bool,
      links: navbarLinksPropType,
      activeLinkFunction: PropTypes.func,
      onLinkClick: PropTypes.func,
      customWrapperClassName: PropTypes.string,
      disableCommonContentModifier: PropTypes.bool
    };

    static defaultProps = {
      disableCommonContentModifier: false,
      fixed: true
    };

    componentDidMount() {
      this.toggleCommonContentModifier();
    }

    componentWillUnmount() {
      this.toggleCommonContentModifier();
    }

    /**
     * Generates BEM style class names for the current instance while including
     * the "default" classes for the Horizontal Navigation Component.
     * @param  {String} element
     * @param  {String} modifier
     * @return {String}          A string representing multiple classes.
     */
    generateClassNames(element, modifier) {
      let BEMClassname = '';
      if (element) {
        BEMClassname += `__${element}`;
      }
      if (modifier) {
        BEMClassname += `--${modifier}`;
      }
      return [`horizontal-navigation${BEMClassname}`, className + BEMClassname].join(' ');
    }

    generateLink = (link, key) => {
      const icon = link.get('iconBackgroundImage') ? (
        <span
          style={{backgroundImage: `url(${link.get('iconBackgroundImage')})`}}
          className={this.generateClassNames('link-icon')}
        />
      ) : null;

      const classNames = {
        [this.generateClassNames('links-link-wrapper')]: true
      };

      const activeClassName = this.generateClassNames('links-link-wrapper', 'active');

      let useActiveLinkFunction = false;
      if (this.props.activeLinkFunction) {
        useActiveLinkFunction = true;
        classNames[activeClassName] = this.props.activeLinkFunction(link);
      }

      return (
        <Link
          to={link.get('to')}
          activeClassName={useActiveLinkFunction === false ? activeClassName : null}
          className={classnames(classNames)}
          key={key}
        >
          <div className={this.generateClassNames('links-link')} onClick={this.props.onLinkClick}>
            {icon}
            {link.get('name')}
          </div>
        </Link>
      );
    };

    generateLinks() {
      if (!this.props.links || this.props.links.count() === 0) {
        return null;
      }
      return <div>{this.props.links.map(this.generateLink)}</div>;
    }

    toggleCommonContentModifier() {
      if (this.props.disableCommonContentModifier) {
        return;
      }
      const common = global.document.querySelector('.common-content');
      if (!common) {
        return;
      }
      /**
       * Adds one of the following:
       *
       * .common-content--with-header
       * .common-content--with-secondary-header
       * .common-content--with-tertiary-header
       *
       * To ensure proper padding is given to the content to account for the fixed position headers
       */
      common.classList.toggle(`common-content--with-${className}`);
    }

    generateRightContent() {
      return (
        <div
          className={`${this.generateClassNames('content')} ${this.generateClassNames(
            'content',
            'right'
          )}`}
        >
          {this.props.rightContent}
        </div>
      );
    }

    generateLeftContent() {
      return (
        <div
          className={`${this.generateClassNames('content')} ${this.generateClassNames(
            'content',
            'left'
          )}`}
        >
          {this.props.leftContent}
        </div>
      );
    }

    render() {
      const {
        activeLinkFunction,
        customMobileBreakpoint,
        customWrapperClassName,
        disableCommonContentModifier,
        fixed,
        identity,
        leftContent,
        links,
        onLinkClick,
        ...rest
      } = this.props;
      return (
        <nav
          className={classnames(customWrapperClassName, this.generateClassNames(), {
            [`${className}--fixed`]: fixed
          })}
          {...rest}
        >
          <div className={this.generateClassNames('wrapper')}>
            {this.generateLeftContent()}
            {this.generateLinks()}
            {this.generateRightContent()}
          </div>
        </nav>
      );
    }
  };
}

export class Header extends HorizontalNavigationComponent('header') {}

export class SecondaryHeader extends HorizontalNavigationComponent('secondary-header') {
  static propTypes = {
    identity: PropTypes.string.isRequired,
    customMobileBreakpoint: PropTypes.number
  };

  generateLinks() {
    if (!this.props.links || this.props.links.count() === 0) {
      return null;
    }

    let options = new List();
    let activeId;
    const links = this.props.links.map((link, k) => {
      if (!link) {
        return null;
      }
      k = k.toString();
      options = options.push(link.set('id', k));
      if (process.env.IS_BROWSER) {
        const {pathname} = history.getCurrentLocation();
        if (
          /**
           * If the current pathname starts with the to, AND does not have a custom match regex it's active.
           */
          (link.has('match') === false && pathname.indexOf(link.get('to')) === 0) ||
          /**
           * If the link provides a match RegExp, evaluate it against the current path.
           */
          (link.has('match') && link.get('match').test(pathname))
        ) {
          activeId = k;
        }
      }
      return this.generateLink(link, k);
    });

    const {customMobileBreakpoint} = this.props;
    const dropdownWrapperClass = classnames('secondary-header__simple-dropdown-wrapper', {
      'hidden-device-medium-up': !customMobileBreakpoint
    });
    const linksWrapperClass = classnames('secondary-header__links-wrapper', {
      'hidden-device-medium-down': !customMobileBreakpoint
    });

    const breakpointStylesTag = customMobileBreakpoint ? (
      <style>
        {`
          @media (min-width: ${customMobileBreakpoint + 1}px) {
            .secondary-header__simple-dropdown-wrapper {
              display: none;
            }
          }

          @media (max-width: ${customMobileBreakpoint}px) {
            .secondary-header__links-wrapper {
              display: none;
            }
          }
          `}
      </style>
    ) : null;

    return (
      <div className='secondary-header__simple-dropdown-nav-wrapper'>
        <div className={dropdownWrapperClass}>
          <SimpleDropdown
            noInputStyles
            destroyStoreOnUnmount
            storeName={`horizontalNavigationComponentSecondaryHeaderDropdownStore-${this.props.identity}`}
            options={options}
            activeItemId={activeId}
          />
        </div>
        <div className={linksWrapperClass}>{links}</div>
        {breakpointStylesTag}
      </div>
    );
  }
}

export class TertiaryHeader extends HorizontalNavigationComponent('tertiary-header') {}
