import React from 'react';
import {Button, Icon} from '@albert-io/atomic';

import {
  AlternateContentLanguage,
  SUPPORTED_CONTENT_LANGUAGES,
  SupportedContentLanguage
} from '../QuestionEditorV2Store.types';

import './language-toggle.scss';

const langNames: Record<SupportedContentLanguage, string> = {
  en: 'English',
  es: 'Spanish'
} as const;

interface Props {
  currentLanguage: SupportedContentLanguage;
  setCurrentLanguage: (language: SupportedContentLanguage) => void;
  disabled: boolean;
  languagesHaveChanges: Record<SupportedContentLanguage, boolean>;
  languagesHaveUnpublishedTranslations: Record<AlternateContentLanguage, boolean>;
}

export const LanguageToggle = ({
  currentLanguage,
  setCurrentLanguage,
  disabled,
  languagesHaveChanges,
  languagesHaveUnpublishedTranslations
}: Props) => {
  return (
    <div className='u-display_flex u-justify-content_center u-align-items_center u-pad-lr_4 u-pad-tb_1 background-primary'>
      <div className='language-toggle'>
        {SUPPORTED_CONTENT_LANGUAGES.map((lang) => {
          const thisLanguageHasChanges = languagesHaveChanges[lang];
          const thisLanguageHasUnpublishedTranslations = languagesHaveUnpublishedTranslations[lang];

          return (
            <Button
              disabled={disabled}
              key={lang}
              onClick={() => setCurrentLanguage(lang)}
              variant={currentLanguage === lang ? 'solid' : 'text'}
              color='secondary'
              size='s'
              className='language-toggle__button'
            >
              {langNames[lang]}

              {thisLanguageHasChanges && !disabled && (
                <svg
                  className='language-toggle__dirty-indicator'
                  xmlns='http://www.w3.org/2000/svg'
                  width='7'
                  height='7'
                  viewBox='0 0 7 7'
                  fill='none'
                >
                  <circle cx='3.38672' cy='3.5' r='3' fill='#1E7CB8' />
                </svg>
              )}

              {!thisLanguageHasChanges && thisLanguageHasUnpublishedTranslations && (
                <Icon className='u-mar-l_1' icon={['far', 'warning']} color='warning' />
              )}
            </Button>
          );
        })}
      </div>
    </div>
  );
};
