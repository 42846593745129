import {Map, Set} from 'immutable';

/**
 * The `RESOURCES_DEFINITIONS` structure represents `"type" => "endpoint"` mapping for our JSON API.
 * Historically, we've called these "resources" (singular form) and their "plural form"... this classification
 * isn't quite in line with the JSON API specification, but is required for backwards compatibility.
 *
 * @see https://jsonapi.org/format/#document-resource-object-identification
 */
const RESOURCE_DEFINITIONS = Map({
  /* eslint-disable camelcase */
  assignment_v2: 'assignments_v2',
  assignment_v3: 'assignments_v3',
  author_v1: 'authors_v1',
  authoring_feedback_v1: 'authoring_feedback_v1',
  authoring_guide_level_v1: 'authoring_guide_levels_v1',
  authoring_guide_v1: 'authoring_guides_v1',
  authoring_label_v1: 'authoring_labels_v1',
  authoring_question_set_v1: 'authoring_question_sets_v1',
  authoring_question_v1: 'authoring_questions_v1',
  authoring_subject_v1: 'authoring_subjects_v1',
  authoring_supplement_v1: 'authoring_supplements_v1',
  authoring_standard_v1: 'authoring_standards_v1',
  authoring_standard_set_v1: 'authoring_standard_sets_v1',
  classroom_assignment: 'classroom_assignments',
  classroom_invitation_v1: 'classroom_invitations_v1',
  classroom_v1: 'classrooms_v1',
  clever_course_sync_v1: 'clever_course_syncs_v1',
  coppa_consent_grant_v1: 'coppa_consent_grants_v1',
  curriculum_area_v1: 'curriculum_areas_v1',
  district_v1: 'districts_v1',
  draft_assignment_v1: 'draft_assignments_v1',
  draft_guess_v1: 'draft_guesses_v1',
  email_domain_v1: 'email_domains_v1',
  exam_v1: 'exams_v1',
  google_classroom_api_course_v1: 'google_classroom_api_courses_v1',
  grade_level_v1: 'grade_levels_v1',
  guess_v1: 'guesses_v1',
  guide_level_v1: 'guide_levels_v1',
  guide_level_v2: 'guide_levels_v2',
  guide_v1: 'guides_v1',
  issuer_v1: 'issuers_v1',
  label_v1: 'labels_v1',
  managed_user_v1: 'managed_users_v1',
  media_v1: 'medias_v1',
  public_question_v1: 'public_questions_v1',
  question_set_v1: 'question_sets_v1',
  question_v1: 'questions_v1',
  question_v2: 'questions_v2',
  question_v3: 'questions_v3',
  report: 'reports',
  report_result: 'report_results',
  role_v1: 'roles_v1',
  school_license_subject_group_v1: 'school_license_subject_groups_v1',
  school_license_v1: 'school_licenses_v1',
  school_personnel_invitation_v1: 'school_personnel_invitations_v1',
  school_personnel_v1: 'school_personnel_v1',
  school_v2: 'schools_v2',
  school_v4: 'schools_v4',
  school_v5: 'schools_v5',
  search_question_v1: 'search_questions_v1',
  search_question_v2: 'search_questions_v2',
  search_standard_v1: 'search_standards_v1',
  search_standard_v2: 'search_standards_v2',
  search_tag_v1: 'search_tags_v1',
  section_v1: 'sections_v1',
  standard_v1: 'standards_v1',
  standard_set_v1: 'standard_sets_v1',
  standard_set_creator_v1: 'standard_set_creators_v1',
  student_assignment_v1: 'student_assignments_v1',
  student_classroom_v1: 'student_classrooms_v1',
  student_v1: 'students_v1',
  student_v2: 'students_v2',
  subject_author_permission_v1: 'subject_author_permissions_v1',
  subject_faq_v1: 'subject_faqs_v1',
  subject_v1: 'subjects_v1',
  subject_v2: 'subjects_v2',
  subscription_v1: 'subscriptions_v1',
  subtopic_v1: 'subtopics_v1',
  supplement_v1: 'supplements_v1',
  tag_v1: 'tags_v1',
  teacher_v1: 'teachers_v1',
  template_v1: 'templates_v1',
  theme_v1: 'themes_v1',
  topic_v1: 'topics_v1',
  translated_question: 'translated_questions',
  translated_supplement: 'translated_supplements',
  translated_field: 'translated_fields',
  upgrade_v1: 'upgrades_v1',
  user_feedback_v1: 'user_feedback_v1',
  user_v1: 'users_v1',
  user_v2: 'users_v2'
  /* eslint-enable camelcase */
});

export const RESOURCES = Set(RESOURCE_DEFINITIONS.keySeq());
export const COLLECTIONS = Set(RESOURCE_DEFINITIONS.valueSeq());

export const PLURAL_FORMS = RESOURCE_DEFINITIONS;
const SINGULAR_FORMS = RESOURCE_DEFINITIONS.reduce(
  (result, val, key) => result.set(val, key),
  Map()
);

export function getSingularForm(pluralForm) {
  const singularForm = SINGULAR_FORMS.get(pluralForm);
  if (!singularForm) {
    return pluralForm;
  }
  return singularForm;
}

export function getPluralForm(singularForm) {
  const pluralForm = PLURAL_FORMS.get(singularForm);
  if (!pluralForm) {
    return singularForm;
  }
  return pluralForm;
}

/**
 * A list of "known" resources *without* versions.
 * This is used by `Generic.model` to remove properties that need to be updated
 * via `relationship` requests (not part of the main payload).
 */
export const KNOWN_RESOURCES = RESOURCES.concat(COLLECTIONS.valueSeq()).map((type) =>
  type.replace(/_v[0-9]+$/g, '')
);
