import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';

import {history} from 'client/history';
import {callAction} from 'client/framework';
import sessionStore from 'client/Session/SessionStore';
import sessionLifecycleActions from 'client/Session/SessionLifecycleActions';
import {getBaseUserQuery} from 'client/User/UserQueryUtil';
import {redirect} from 'client/User/UserRedirectUtil';
/**
 * /register is part of the admin invitation flow (deep link in email)
 *
 * The deep link exposes three parameters:
 * 1. school_id
 * 2. email
 * 3. source
 *
 * When source === school_personnel_invitation, that is a special teacher invitation
 * case that needs to be handled independently.
 *
 * For the time being I am considering other cases errors - as only the school personnel
 * invitations invoke the /register path
 *
 * This view requires that a user be signed out, because of reasons.
 */
export default withRouter(
  class Register extends React.Component {
    static propTypes = {
      location: PropTypes.shape({
        query: PropTypes.shape({
          school_id: PropTypes.string,
          email: PropTypes.string,
          source: PropTypes.oneOf(['school_personnel_invitation']),
          school_personnel_invitation_id: PropTypes.string
        })
      })
    };

    async componentDidMount() {
      // our incoming q params
      const schoolId = this.props.location.query.school_id;
      const {email, source} = this.props.location.query;
      /*
      TODO: this log out thing should just be a decorator -- but it also is extremely strange
    */
      if (sessionStore.hasValidSession()) {
        logger.error('logged in user accessing registration route');

        /**
         * Checking to see if a user is already associated with the school, so we don't ask them
         * to re-register with the same school. That would be rude.
         */
        const user = await getBaseUserQuery(sessionStore.getUserId()).getResource();

        if (user.getSchool().getId() === schoolId && user.getEmail() === email) {
          logger.info('user school id & email matches registration params, redirecting to /home');
          redirect();
          return;
        }

        logger.info('Ending user session');
        callAction(sessionLifecycleActions.END_SESSION);
      }

      if (source === 'school_personnel_invitation' && email && schoolId) {
        /**
         * When a teacher gets an invite, we inject the email and school into
         * the sign up form and prevent them from changing the values (disabled)
         * to ensure they have the expected access AND their invite is auto-accepted.
         *
         * To allow that, we expose query parameters that the sign up consumes.
         */
        history.replaceState(
          null,
          `/signup?email=${encodeURIComponent(email)}&school_id=${encodeURIComponent(
            schoolId
          )}&source=${source}`
        );
      } else {
        logger.error(
          'missing full registration email parameter list, source: %s, email: %s, schoolId: %s',
          source,
          email,
          schoolId
        );
        history.replaceState(null, `/signup`);
      }
    }

    render() {
      return null;
    }
  }
);
