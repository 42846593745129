import React from 'react';
import PropTypes from 'prop-types';
import {callTargetedAction, setUpStore} from 'client/framework';
import classnames from 'classnames';
import {tooltipActions} from './Tooltip.actions';
import TooltipStore from './Tooltip.store';
import './tooltip.scss';

export default class Tooltip extends React.Component {
  static propTypes = {
    storeName: PropTypes.string.isRequired,
    position: PropTypes.string,
    isOpen: PropTypes.bool,
    onOpen: PropTypes.func,
    onClose: PropTypes.func
  };

  static defaultProps = {
    storeName: 'TooltipStore',
    position: 'bottom',
    isOpen: false,
    onOpen: () => {},
    onClose: () => {}
  };

  constructor(props) {
    super(props);
    this.tooltipWrapper = null;
    this.toggleTooltipBound = this.toggleTooltip.bind(this);
  }

  UNSAFE_componentWillMount() {
    const isOpen = this.props.isOpen;

    if (process.env.IS_BROWSER && isOpen) {
      callTargetedAction({
        name: tooltipActions.SET_IS_OPEN,
        payload: isOpen,
        targetStore: this.props.storeName
      });
      if (this.props.onOpen) {
        this.props.onOpen();
      }
    }
  }

  componentDidMount() {
    global.addEventListener('click', this.toggleTooltipBound);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.storeName && nextProps.storeName !== this.props.storeName) {
      const nextStore = this.getStore(nextProps.storeName);
      if (nextProps.isOpen) {
        callTargetedAction({
          name: tooltipActions.SET_IS_OPEN,
          payload: nextProps.isOpen,
          targetStore: nextStore.getName()
        });
      }
    }
  }

  componentWillUnmount() {
    global.removeEventListener('click', this.toggleTooltipBound);
    callTargetedAction({
      name: tooltipActions.RESET_STORE,
      targetStore: this.props.storeName
    });
  }

  getStore(storeName = this.props.storeName) {
    return setUpStore(TooltipStore, storeName);
  }

  applyTooltipStyles() {
    const position = this.props.position;
    const prevSiblingRect = this.tooltipWrapper.previousSibling.getBoundingClientRect();

    if (position === 'right') {
      this.tooltipWrapper.style.left = `${prevSiblingRect.width + 14}px`;
    }
  }

  toggleTooltip(e) {
    const store = this.getStore();
    if (!this.tooltipWrapper.contains(e.target) && store.getIsOpen()) {
      callTargetedAction({
        name: tooltipActions.SET_IS_OPEN,
        payload: false,
        targetStore: this.props.storeName
      });
      if (this.props.onClose) {
        this.props.onClose();
      }
    } else if (
      (e.target === this.tooltipWrapper.parentNode ||
        (this.tooltipWrapper.parentNode.contains(e.target) &&
          !this.tooltipWrapper.contains(e.target))) &&
      !store.getIsOpen()
    ) {
      callTargetedAction({
        name: tooltipActions.SET_IS_OPEN,
        payload: true,
        targetStore: this.props.storeName
      });
      this.applyTooltipStyles();
      if (this.props.onOpen) {
        this.props.onOpen();
      }
    }
  }

  render() {
    const isOpen = this.getStore().getIsOpen();
    const tooltipWrapperClasses = classnames({
      'tooltip-wrapper': true,
      'tooltip-wrapper--hidden': !isOpen,
      'tooltip-wrapper--right': this.props.position === 'right'
    });

    return (
      <div
        className={tooltipWrapperClasses}
        ref={(ref) => {
          this.tooltipWrapper = ref;
        }}
      >
        <div className='tooltip-content'>{this.props.children}</div>
      </div>
    );
  }
}
