// @flow
import {fromJS, Map, List} from 'immutable';
import {Store} from 'client/framework';
import {mandarkEndpoint} from '@albert-io/json-api-framework/request/builder/legacy';
import appStore from 'client/AppStore';
import sessionStore from 'client/Session/SessionStore';

// eslint-disable-next-line import/no-cycle
import teacherClassroomScopeStore from './Teacher/TeacherClassroomScope/TeacherClassroomScope.store';
import classroomV2Actions from './ClassroomV2.actions';
import {ClassroomModelV1} from 'resources/augmented/Classroom/ClassroomModel.v1';
import {StudentsListCollectionModel} from 'resources/Student/StudentsListCollection.model';
import SimpleDropdownStore from 'generic/SimpleDropdown/SimpleDropdown.store';

import {SchoolModelV5} from 'resources/augmented/School/SchoolModel.v5';

import type {StudentModel} from 'resources/Student/Student.model';
import type {TeacherModelV1} from 'resources/augmented/Teacher/TeacherModel.v1';
import type {SubjectModel} from 'resources/Subject/Subject.model';
import type {ClassroomInvitationModelV1} from 'resources/GeneratedModels/ClassroomInvitation/ClassroomInvitationModel.v1';
import type {QueryBuilder} from '@albert-io/json-api-framework/request/builder/legacy';

class ClassroomV2Store extends Store {
  constructor(name) {
    super(name);
    this.setInitialData(
      fromJS({
        studentEmailsStoreName: '',
        subjectDropdownStoreName: '',
        timeScopeDropdownStoreName: '',
        // session storage state managed MANUALLY
        isEditingSubjects: false
      })
    );

    // init our session storage driven field
    this.handle(classroomV2Actions.INVALIDATE_CLASSROOM, this._invalidateClassroom);
    this.handle(
      classroomV2Actions.TOGGLE_IS_EDITING_SUBJECTS,
      this.toggleProperty('isEditingSubjects')
    );
    this.TeacherClassroomSortByDropdownStore = new SimpleDropdownStore(
      'TeacherClassroomDashboardSortByDropdown'
    );
  }

  isEditingSubjects(): boolean {
    return this.readData('isEditingSubjects');
  }

  _invalidateClassroom() {
    this.getClassroomQuery().invalidateInterest();
  }

  /*
    clears out our tracked toast when the classroom unmounts, so we're able to reliably re-show it
    when the user bounces around classroom routes.
  */

  getActiveClassroomId(): string {
    return appStore.routerProps.params.classId;
  }

  getClassroomQuery(classroomId?: string): Object {
    const notStudent = sessionStore.isStudent() === false;
    return (
      mandarkEndpoint(['classrooms_v1', classroomId || this.getActiveClassroomId()])
        .defaultModel(ClassroomModelV1)
        .include('school_v5')
        .include('subjects_v1')
        .include('teachers_v1')
        .include('grade_levels_v1')
        .include('school_v5.email_domains_v1', notStudent) // Manage Roster V2 dependency
        /**
         * For non-Students (teacher, admin++), we include the classroom's:
         * - students
         * - not-accepted invitations
         * - and classroom metadata
         */
        .include('students_v2', notStudent)
        .include('classroom_invitations_v1', notStudent)
        .filter(
          {
            included: {
              classroom_invitations_v1: {
                accepted_at: {
                  null: true
                }
              }
            }
          },
          notStudent
        )
        .customQuery(
          {
            with_meta: 'classroom_v1'
          },
          notStudent
        )
        .customQuery(
          {
            meta: {
              context: {
                classroom: {
                  counts: true
                }
              }
            }
          },
          notStudent
        )
        .customQuery(
          {
            meta: {
              context: {
                lti_deployment: true,
                lti_platform_name: true
              }
            }
          },
          notStudent
        )
    );
  }

  arePracticeExamsAvailableForClassroomSubjects(): boolean {
    const query = mandarkEndpoint([
      'classrooms_v1',
      appStore.routerProps.params.classId,
      'subjects_v2'
    ]).filter({
      guides_v1: {
        guide_type: 'assessment'
      }
    });
    if (!query.isResourcePopulated()) {
      return false;
    }
    return !query.getResource().isEmpty();
  }

  getActiveClassroom(classroomId?: string): ClassroomModelV1 {
    return this.getClassroomQuery(classroomId).getResource();
  }

  getActiveClassroomPromise(): Promise<ClassroomModelV1> {
    return this.getClassroomQuery().getResourcePromise();
  }

  isActiveClassroomReady(): boolean {
    return this.getClassroomQuery().isResourcePopulated();
  }

  getActiveClassroomMetadata(): any {
    return this.getClassroomQuery().getResourceMetadata();
  }

  getActiveClassroomSchool(): SchoolModelV5 {
    return this.getActiveClassroom().getSchool();
  }

  getClassroomStudents(): List<StudentModel> {
    return this.getActiveClassroom().getStudents();
  }

  getSortedClassroomStudents(): List<StudentModel> {
    return this.getActiveClassroom()
      .getStudents()
      .sortBy((student) => [
        student.getLastName().toLowerCase(),
        student.getFirstName().toLowerCase()
      ]);
  }

  getClassroomSubjects(): List<SubjectModel> {
    return this.getActiveClassroom().getSubjects();
  }

  getClassroomTeacher(): TeacherModelV1 {
    return this.getActiveClassroom().getTeachers().first();
  }

  getClassroomInvitations(classroomId?: string): List<ClassroomInvitationModelV1> {
    return this.getActiveClassroom(classroomId)
      .getClassroomInvitations()
      .sortBy((invite) => invite.getEmail().toLowerCase());
  }

  hasActiveClassroomError(): boolean {
    return !this.getActiveClassroom().isResourceValid();
  }

  getClassroomId(): string {
    return appStore.routerProps.params.classId;
  }

  getTeacherClassroomSortByDropdownStoreName(): string {
    return this.TeacherClassroomSortByDropdownStore.getName();
  }

  getTeacherClassroomSortBy(): Map<string, string> {
    return this.TeacherClassroomSortByDropdownStore.getSelectedItem();
  }

  getStudentsQuery(): QueryBuilder {
    let sortBy = this.getTeacherClassroomSortBy().get('value');
    /**
     * 'raw_accuracy' is currently a magic string because we can't sort by that yet.
     * Going to run with the magic string because it should be a field that we can sort by.
     */
    if (sortBy === 'raw_accuracy') {
      sortBy = null;
    }
    const attemptNumber = teacherClassroomScopeStore.getAttemptNumber();
    const selectedSubjects = teacherClassroomScopeStore.getSelectedSubjects();
    return (
      mandarkEndpoint(['classrooms_v1', this.getClassroomId(), 'students_v2'])
        .defaultModel(StudentsListCollectionModel)
        /**
         * Classes should never have more than 250 students. Since this endpoint returns a paginated list
         * of students, we're sending up 250 so that we can be sure that we have all students in the response
         */
        .pageSize(250)
        .customQuery({
          with_meta: 'student_v2'
        })
        .customQuery(
          {
            sort: sortBy
          },
          sortBy
        )
        .meta({
          context: {
            subject: {
              ids: selectedSubjects.join(',')
            },
            start_date: teacherClassroomScopeStore.getStartDate(),
            end_date: teacherClassroomScopeStore.getEndDate()
          }
        })
        .meta(
          {
            context: {
              guess: {attempt_number: attemptNumber}
            }
          },
          attemptNumber
        )
        .meta(
          {
            context: {
              guess: {most_recent: true}
            }
          },
          !attemptNumber
        )
    );
  }

  getStudents(): List<StudentModel> {
    /**
     * 'raw_accuracy' is currently a magic string because we can't sort by that yet.
     * If the user wants to sort by raw_accuracy, we'll do that on the front end for now.
     */
    const sortByAccuracy = this.getTeacherClassroomSortBy().get('value') === 'raw_accuracy';
    const students = this.getStudentsQuery().getResource();
    return sortByAccuracy ? students.sortedByAccuracy() : students;
  }

  getStudentsPromise(): Promise<*> {
    return this.getStudentsQuery().getResourcePromise();
  }

  areStudentsReady(): boolean {
    return this.isActiveClassroomReady() && this.getStudentsQuery().isResourcePopulated();
  }

  getNextStudentAlphabetized(studentId: string): ?StudentModel {
    const students = this.getStudents().sortBy((student) => student.getLastName());
    return students.find((student, i, iter) => {
      const prev = iter.get(i - 1);
      return prev && prev.getId() === studentId;
    });
  }
}

export default new ClassroomV2Store('ClassroomV2Store');
