import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {Set} from 'immutable';

import SearchInput from '../../molecules/SearchInput/SearchInput.react';
import SplitCard from '../../molecules/SplitCard/SplitCard.react';
import Text from '../../atoms/Text/Text.react';

import './find-and-apply.scss';

/**
 * FindAndApply
 * ============
 *
 * FindAndApply wraps a SplitCard instance and exposes three different sections, FindAndApply.Header,
 * FinAndApply.Body, and FindAndApply.Footer.
 *
 * Each section represents a FindAndApplyContext consumer, which shares several pieces of state
 * in which they all have some interest.
 *
 * Props:
 *  - defaultSelectedItems: An iterable of items, empty or loaded, to which the selected items panel
 *    shall reset upon a user's onReset action.  Useful for persisting selected items across various
 *    searches initiated from the same FindAndApply instance.
 * Context:
 * - searchString: maps to the state of the SearchInput in FindAndApply.Header. Updated upon submit.
 * - selectedItems: array of item IDs, added or removed using context.onCheck. Passed items must
 *    expose a `data-id` attribute.
 * - onCheck: an event handler to be used upon passed items. Passed items must expose a `data-id` attribute.
 * - onClear: a callback passed to the SearchInput's onReset in FindAndApply.Header.  Used to clear
 *    context.searchString.
 * - onReset: Used to clear context.selectedItems.
 * - onSearch: a callback passed to the SearchInput's onSubmit. Used to update context.searchString.
 * - onConfirm: calls the customOnConfirm prop func passing it the current slate of selected items.
 *  Then it clears the items from the context.
 *
 * @example
 *
 * const SearchWithResultsList = ({data}) => (
 *     <FindAndApply>
 *       <FindAndApply.Header /> // Includes a SearchInput hooked up to `searchString` out of the box
 *       <FindAndApply.Body>
 *         {({
 *          searchString,
 *          selectedItems,
 *          onCheck
 *         }) => {
 *           return data.filter((item) => !searchString || item.indexOf(searchString) !== -1))
 *            .map((item, i) => (
 *              <CheckboxChip
 *                checked={selectedItems.has(item)}
 *                key={i}
 *                onChange={onCheck}
 *              >
 *                {item}
 *              </Chip>
 *            ));
 *         }}
 *       </FindAndApply.Body>
 *     </FindAndApply>
 * );
 */

const FindAndApplyContext = React.createContext();

const Header = ({children, className, placeholder = 'Start typing...', ...rest}) => (
  <FindAndApplyContext.Consumer>
    {(context) => {
      const {onChange, onClear, onSearch} = context;
      return (
        <SplitCard.Section
          border='none'
          className={classnames('o-find-and-apply__header', className)}
          {...rest}
        >
          <SearchInput
            aria-label='Search by keyword or phrase'
            className='o-find-and-apply__search'
            onChange={onChange}
            onSubmit={onSearch}
            onReset={onClear}
            placeholder={placeholder}
            data-testid='legacy-find-and-apply__search-input'
          />
          {typeof children !== 'function' ? children : children(context)}
        </SplitCard.Section>
      );
    }}
  </FindAndApplyContext.Consumer>
);

Header.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  placeholder: PropTypes.string
};

const Body = ({children, className, ...rest}) => (
  <FindAndApplyContext.Consumer>
    {(context) => (
      <SplitCard.Section className={classnames('o-find-and-apply__body', className)} {...rest}>
        {typeof children !== 'function' ? children : children(context)}
      </SplitCard.Section>
    )}
  </FindAndApplyContext.Consumer>
);

Body.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

const Footer = ({children, className, ...rest}) => (
  <FindAndApplyContext.Consumer>
    {(context) => (
      <SplitCard.Section className={classnames('o-find-and-apply__footer', className)} {...rest}>
        {typeof children !== 'function' ? children : children(context)}
      </SplitCard.Section>
    )}
  </FindAndApplyContext.Consumer>
);

Footer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

const SelectedItems = ({children, className, ...rest}) => (
  <div className={classnames('u-mar-b_2', className)} {...rest}>
    {children}
  </div>
);

SelectedItems.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

const Message = ({className, ...rest}) => (
  <Text
    as='center'
    className={classnames('o-find-and-apply__msg u-mar_auto u-pad-t_5 u-pad-b_3', className)}
    color='tertiary'
    italic
    size='l'
    {...rest}
  />
);

Message.propTypes = {
  className: PropTypes.string
};

const NoResultsMessage = (props) => <Message {...props}>No results found</Message>;

const BtnGroup = ({className, ...rest}) => (
  <div
    aria-label='Actions'
    className={classnames('o-find-and-apply__btn-group', className)}
    role='group'
    {...rest}
  />
);

BtnGroup.propTypes = {
  className: PropTypes.string
};

const defaultCustomOnCheck = (e, selectedItems) => {
  const {id} = e.target.dataset;
  if (!id) {
    // eslint-disable-next-line no-console
    console.warn('Targeted element is missing required data attribute of id');
  }
  if (selectedItems.has(id)) {
    selectedItems.delete(id);
  } else {
    selectedItems.add(id);
  }
  return {selectedItems};
};

export default class FindAndApply extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    customOnChange: PropTypes.func,
    customOnCheck: PropTypes.func,
    customOnClear: PropTypes.func,
    customOnReset: PropTypes.func,
    customOnConfirm: PropTypes.func,
    customOnSearch: PropTypes.func,
    bypassComponentDidUpdate: PropTypes.bool,
    defaultSelectedItems: (props, propName, componentName) => {
      const isSet = props[propName] instanceof Set || props[propName] instanceof new Set();
      if (!isSet) {
        // eslint-disable-next-line no-console
        console.warn(
          `You are passing a type other than Set to ${propName} in ${componentName}. Update the customOnCheck prop accordingly.`
        );
      }
    }
  };

  static defaultProps = {
    defaultSelectedItems: new Set([]),
    customOnChange: () => {},
    customOnCheck: defaultCustomOnCheck,
    customOnClear: () => {},
    customOnReset: () => {},
    customOnConfirm: () => {},
    customOnSearch: () => {},
    bypassComponentDidUpdate: false
  };

  static Header = Header;

  static Body = Body;

  static BtnGroup = BtnGroup;

  static SelectedItems = SelectedItems;

  static Footer = Footer;

  static Message = Message;

  static NoResultsMessage = NoResultsMessage;

  constructor(props) {
    super(props);

    this.state = {
      searchString: '',
      selectedItems:
        props.defaultSelectedItems instanceof Set
          ? new Set(props.defaultSelectedItems)
          : props.defaultSelectedItems,
      onChange: this.handleChange,
      onCheck: this.handleCheck,
      onClear: this.handleClear,
      onConfirm: this.handleConfirm,
      onReset: this.handleReset,
      onSearch: this.handleSearch
    };
  }

  componentDidUpdate(prevProps) {
    // This conditional covers scenarios in which we must update the collection of items
    // to persist across searches becaues of a user's action, such as a new "Apply"
    if (
      !this.props.bypassComponentDidUpdate &&
      prevProps.defaultSelectedItems !== this.props.defaultSelectedItems
    ) {
      this.setState(() => ({
        selectedItems:
          this.props.defaultSelectedItems instanceof Set
            ? new Set(this.props.defaultSelectedItems)
            : this.props.defaultSelectedItems
      }));
    }
  }

  handleChange = (e) => {
    e.persist();
    this.setState({searchString: e.target.value});
    this.props.customOnChange(e);
  };

  handleCheck = (e, ...args) => {
    e.persist();
    this.setState(({selectedItems}) => this.props.customOnCheck(e, selectedItems, ...args));
  };

  handleClear = () => {
    this.setState({searchString: ''});
    this.props.customOnClear();
  };

  handleConfirm = () => {
    this.props.customOnConfirm(this.state.selectedItems);
  };

  handleReset = () => {
    this.setState(() => ({
      selectedItems:
        this.props.defaultSelectedItems instanceof Set
          ? new Set(this.props.defaultSelectedItems)
          : this.props.defaultSelectedItems
    }));
    this.props.customOnReset();
  };

  handleSearch = (str) => {
    this.setState(
      () => ({searchString: str}),
      () => this.props.customOnSearch(this.state.searchString)
    );
  };

  render() {
    const {
      /* eslint-disable no-unused-vars */
      children,
      className,
      customOnChange,
      customOnCheck,
      customOnClear,
      customOnReset,
      customOnConfirm,
      customOnSearch,
      ...rest
      /* eslint-enable no-unused-vars */
    } = this.props;
    return (
      <FindAndApplyContext.Provider value={this.state} {...rest}>
        <SplitCard className={classnames('o-find-and-apply', className)}>{children}</SplitCard>
      </FindAndApplyContext.Provider>
    );
  }
}
