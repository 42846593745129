import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {range} from 'lodash';

import {getClassNamesForCol} from '../utils/Grid.utils';
import './grid-col.scss';

/**
 * Grid.Col
 * ========
 *
 * Represents a column.
 *
 * Accepts props (xxs through xl) describing the desired width at each breakpoint.
 *
 * Breakpoints are applied as max-widths, meaning each column width you provide will
 * persist at and below the given breakpoint until a lower breakpoint is provided.
 * E.g. `xl={3}` will persist unless `l` or some other breakpoint is added.
 *
 * To set a default that persists at the widest viewports and below, use `all`.
 *
 * `all` will persist until a given breakpoint is found, at which that breakpoint will take over.
 *
 * debug: adds a padding and background-color default (intended for Storybert pages)
 * offset: (NOTE: Use Row props before reaching for offsets) takes an object of key-value pairs of
 *   sizes and column widths describing how many columns to shift to the left
 *
 * @param {Object} props
 * @example
 * <Col
 *   offset={{
 *     xxs: 3
 *   }}
 *   xxs={3}
 *   m={4}
 *   all={2}
 * >
 *   Sidebar content
 * </Col>
 * <Col
 *   offset={{
 *     xxs: 3
 *   }}
 *   xxs={3}
 *   m={8}
 *   all={10}
 * >
 *   Main content
 * </Col>
 */
const Col = (props) => {
  const {
    as: WrapperElement,
    className,
    dangerouslySetColGap,
    debug,
    offset,
    rowGap,
    all,
    xxs,
    xs,
    s,
    m,
    l,
    xl,
    ...rest
  } = props;

  const specs = {
    dangerouslySetColGap,
    all,
    xxs,
    xs,
    s,
    m,
    l,
    xl
  };

  const widths = getClassNamesForCol(specs, 'a-grid__col');
  const offsets = Object.keys(offset).reduce((prev, curr) => {
    prev.push(`a-grid__col--offset-${curr}-${offset[curr]}`);
    return prev;
  }, []);
  const classes = classnames('a-grid__col', className, offsets, widths, {
    [`a-grid__col--row-gap-${rowGap}`]: rowGap,
    'a-grid__col--debug': debug
  });
  return <WrapperElement className={classes} {...rest} />;
};

Col.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  className: PropTypes.string,
  dangerouslySetColGap: PropTypes.oneOfType([PropTypes.oneOf(range(1, 11)), PropTypes.object]),
  debug: PropTypes.bool,
  offset: PropTypes.object,
  rowGap: PropTypes.oneOf(range(1, 11)),
  all: PropTypes.oneOf(range(1, 13)),
  xxs: PropTypes.oneOf(range(1, 13)),
  xs: PropTypes.oneOf(range(1, 13)),
  s: PropTypes.oneOf(range(1, 13)),
  m: PropTypes.oneOf(range(1, 13)),
  l: PropTypes.oneOf(range(1, 13)),
  xl: PropTypes.oneOf(range(1, 13))
};

Col.defaultProps = {
  all: 12,
  as: 'div',
  offset: {}
};

export default Col;
