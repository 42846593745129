import React from 'react';
import QuestionsList from 'client/InteractionEngineV2/shared/QuestionsList/QuestionsList.react';
import teacherAssignmentTemplateQuestionsListStore from './TeacherAssignmentTemplateQuestionsList.store';

export default class TeacherAssignmentQuestionsList extends React.Component {
  render() {
    return (
      <QuestionsList
        questionsListStore={teacherAssignmentTemplateQuestionsListStore}
        questionSets={teacherAssignmentTemplateQuestionsListStore.questionSets}
        singleQuestionWidget={null}
      />
    );
  }
}
