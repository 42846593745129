import React from 'react';
import PropTypes from 'prop-types';
import {Button, Icon} from '@albert-io/atomic';

const iconMap = {
  'Add point': ['fas', 'circle'],
  'Add ray': ['fas', 'long-arrow-alt-right'],
  'Add line': ['fas', 'arrows-alt-h'],
  'Add segment': ['fas', 'horizontal-rule'],
  'Add polygon': ['fas', 'vector-square'],
  'Add parabola': ['fas', 'union'],
  'Add inequality': ['fas', 'greater-than'],
  'Add circle': ['far', 'circle']
};

const Toolbar = ({options, instructions}) => (
  <div className='u-mar-b_4'>
    {options.map(({label, onClick, key}) => {
      const icon = iconMap[label];
      let buttonColor = 'secondary';
      if (['Cancel', 'Done editing'].includes(label)) {
        buttonColor = 'primary';
      } else if (/^delete/i.test(label)) {
        buttonColor = 'negative';
      }
      return (
        <Button
          className='u-mar-r_1 u-mar-b_1'
          size='s'
          color={buttonColor}
          onClick={onClick}
          key={key ?? label}
        >
          {icon && (
            <>
              <Icon icon={icon} />
              &nbsp;&nbsp;
            </>
          )}
          {label}
        </Button>
      );
    })}
    <span aria-live='polite'>{instructions}</span>
  </div>
);

export const renderToolbar = (options, instructions) => (
  <Toolbar options={options} instructions={instructions} />
);

Toolbar.propTypes = {
  instructions: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func
    })
  ).isRequired
};

export default Toolbar;
