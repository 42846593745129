import React, {useEffect, useState} from 'react';
import {Editor, Transforms, Range, CustomTypes, Path} from 'slate';
import {isURL} from 'validator';

import {useSlate, useSlateStatic, useSelected, useFocused} from 'slate-react';

import {Button, Dialogue, IconButton, Modal, Text, TextField} from '@albert-io/atomic';

import {insertLink, unwrapLink, isLinkActive, withWorkaround} from '../Utils/editor.utils';
import {CustomText, LinkElement} from '../slate.types';

import {ElementProps} from './Elements.types';

// Put this at the start and end of an inline component to work around this Chromium bug:
// https://bugs.chromium.org/p/chromium/issues/detail?id=1249405
const InlineChromiumBugfix = () => (
  <span contentEditable={false} style={{fontSize: 0}}>
    ${String.fromCodePoint(160) /* Non-breaking space */}
  </span>
);
/* --------- Links ----------- */

interface LinkProps extends ElementProps {
  element: LinkElement;
}

const Link = ({attributes, children, element, readOnly}: LinkProps) => {
  const editor = useSlate();
  const selected = useSelected();
  const focused = useFocused();

  const [edit, setEdit] = useState(false);

  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    return () => {
      setEdit(false);
    };
  }, []);

  useEffect(() => {
    const {selection} = editor;
    if (selection && Range.isCollapsed(selection)) {
      if (selected) {
        setEdit(true);
      } else {
        setEdit(false);
      }
    } else if (selection && selected) {
      const selectedText = Editor.string(editor, selection);

      const child = element.children[0] as CustomText;
      const isMatching = child.text.includes(selectedText);
      if (isMatching) {
        setEdit(true);
      } else {
        setEdit(false);
      }
    } else {
      setEdit(false);
    }
  }, [editor.selection, selected, editor, element.children]);

  const showPopUp = !readOnly && edit && focused;

  return (
    <span className='u-display_inline u-position_relative'>
      <a {...attributes} href={element.url} target='_blank' rel='noreferrer' className='a-anchor'>
        <InlineChromiumBugfix />
        {children}
        <InlineChromiumBugfix />
      </a>
      {showPopUp && (
        <div className='written-submission__input-popup' contentEditable={false}>
          <a href={element.url} rel='noreferrer' target='_blank' className='a-anchor'>
            {element.url}
          </a>
          <div className='u-display_flex'>
            <IconButton icon='pencil' onClick={() => setShowModal(true)} label='edit link' />
            <IconButton
              icon='unlink'
              onClick={() => {
                unwrapLink(editor);
                setEdit(false);
              }}
              label='unlink'
            />
          </div>
        </div>
      )}
      {showModal && (
        <LinkModal
          onClose={() => {
            setShowModal(false);
            setEdit(false);
          }}
          element={element}
        />
      )}
    </span>
  );
};

export default Link;

/* ------ Modals ----- */

interface ModalProps {
  element?: LinkElement;
  onClose: any;
}

export const LinkModal = ({onClose, element}: ModalProps) => {
  const editor = useSlateStatic();

  const [isEdit, setIsEdit] = useState(false);
  const [text, setText] = useState('');
  const [url, setUrl] = useState('');
  const [urlVisited, setUrlVisited] = useState(false);
  const [textVisited, setTextVisted] = useState(false);

  useEffect(() => {
    const hasLink = isLinkActive(editor);
    setIsEdit(hasLink);
  }, [editor]);

  useEffect(() => {
    const isCollapsed = editor.selection && Range.isCollapsed(editor.selection);
    if (element) {
      setUrl(element.url);
      const child = element.children[0] as CustomText;
      setText(child.text);
      if (!isCollapsed && editor.selection) {
        // we are editing a link, can clear selected text
        Transforms.collapse(editor, {edge: 'start'});
      }
    } else if (isCollapsed) {
      // if cursor is collapsed
      const hasLink = isLinkActive(editor);

      if (hasLink && editor.selection) {
        const [parentNode] = Editor.parent(editor, editor.selection.focus.path) as [
          CustomTypes['Element'],
          Path
        ];
        if (parentNode.type === 'link') {
          setUrl(parentNode.url);
          const child = parentNode.children[0] as CustomText;
          setText(child.text);
        }
      }
    } else if (editor.selection) {
      setText(Editor.string(editor, editor.selection));
    }
  }, [editor, element]);

  const heading = isEdit ? 'Edit Link' : 'Add Link';
  const buttonText = isEdit ? 'Save Edits' : 'Add link';

  const textError = text.length < 1;
  const urlError = !isURL(url, {require_protocol: true});

  return (
    <Modal handleClose={onClose} role='dialog' ariaLabel={heading}>
      {() => {
        return (
          <Dialogue
            inModal
            size='m'
            paddingLevel={4}
            alignTitle='center'
            handleClose={onClose}
            // hideCloseBtn // right now hiding the close button causes the link to not be added to editor if not focused before
          >
            <Text size='xl' bold as='p'>
              {heading}
            </Text>
            <Dialogue.Body>
              <TextField
                error={textVisited && textError}
                message={textVisited && textError ? 'Please enter text' : null}
                className='u-mar-tb_4'
                value={text}
                label='Text'
                border
                onChange={(e) => setText(e.target.value)}
                onBlur={() => setTextVisted(true)}
              />

              <TextField
                className='u-mar-tb_4'
                value={url}
                error={urlVisited && urlError}
                onBlur={() => setUrlVisited(true)}
                message={urlVisited && urlError ? 'Please enter a valid URL' : null}
                border
                type='url'
                placeholder='https://website.com'
                onChange={(e) => setUrl(e.target.value)}
                label='Link'
              />
            </Dialogue.Body>
            <Dialogue.BtnGroup className='u-mar-t_0'>
              <Button color='secondary' onClick={onClose}>
                Cancel
              </Button>
              <Button
                aria-label='add Link'
                onClick={() => {
                  withWorkaround(editor, () => {
                    insertLink(editor, url, text);
                    onClose();
                  });
                }}
                disabled={urlError || textError}
              >
                {buttonText}
              </Button>
            </Dialogue.BtnGroup>
          </Dialogue>
        );
      }}
    </Modal>
  );
};
