import React, {useEffect, useState, useRef, useCallback} from 'react';
import {Transforms} from 'slate';
import {useSlateStatic, ReactEditor, useSelected, useFocused} from 'slate-react';
import classnames from 'classnames';
import {debounce} from 'lodash';
import sessionStore from 'client/Session/SessionStore';

import {Banner, Button, Card, Dialogue, Icon, IconButton, Modal, Text} from '@albert-io/atomic';
import useOnClickOutside from 'lib/hooks/useOnClickOutside';

import WrittenSubmissionContext from '../../WrittenSubmission.context';
import {deleteMedia, deleteGuessMedia, getMedia} from '../../../FreeResponseQuestion.queries';
import {WSStore} from '../../../FreeResponseQuestion.types';

import {handleRotation, getMediaUrl} from '../Utils/editor.utils';
import {DeletedImageElement, ImageElement} from '../slate.types';

import {ElementProps} from './Elements.types';

interface DeletedProps extends ElementProps {}

export const DeletedImage = ({attributes, children}: DeletedProps) => {
  return (
    <div {...attributes}>
      <Banner>
        <Banner.Icon icon='image' />
        Image removed by instructor.
        {children}
      </Banner>
    </div>
  );
};

interface ImageProps extends ElementProps {
  element: ImageElement;
}

const Image = ({attributes, children, element, readOnly}: ImageProps) => {
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);
  const focused = useFocused();
  const selected = useSelected();
  const {rotation} = element;
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [showDeleteErrModal, setShowDeleteErrModal] = useState(false);
  const [imageChecked, setImageChecked] = useState(false);
  const [width, setWidth] = useState(0);
  const [showPopover, setShowPopover] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  useOnClickOutside(
    containerRef,
    useCallback(() => setShowPopover(false), [setShowPopover])
  );

  useEffect(() => {
    // This is a workaround that checks if image was deleted
    const checkImage = async (id: string) => {
      try {
        await getMedia(id);
      } catch (error) {
        const code = (error as any).status;
        if (code === 404) {
          Transforms.removeNodes(editor, {at: path});
          const Node: DeletedImageElement = {type: 'deleted-image', children: [{text: ''}]};
          Transforms.insertNodes(editor, Node, {at: path});
        }
      }
    };
    if (!imageChecked) {
      checkImage(element.id);
      setImageChecked(true);
    }
  }, [element.id, editor, path, imageChecked]);

  const imageClasses = classnames('written-submission__image', {
    'written-submission__image-focused': (focused && selected) || showPopover,
    rotate: !!rotation
  });

  const containerClass = classnames('written-submission__image-container', {
    rotate: !!rotation
  });

  const actionsBarClass = classnames('written-submission__image-popup', {
    'u-visibility_hidden': !(focused && selected) && !showPopover && !readOnly
  });

  const getSize = useCallback(() => {
    if (imageRef.current) {
      setWidth(imageRef.current.offsetWidth);
    }
  }, [imageRef]);

  const debouncedGetSize = useRef(
    debounce(() => {
      getSize();
    }, 500)
  ).current;

  useEffect(() => {
    global.addEventListener('resize', debouncedGetSize);
    return () => {
      debouncedGetSize.cancel();
      global.removeEventListener('resize', debouncedGetSize);
    };
  }, [debouncedGetSize]);

  const handleDelete = async (id, store: WSStore | null) => {
    if (store === null) {
      return;
    }
    try {
      await deleteMedia(id, store.getDraftId());
      Transforms.removeNodes(editor, {at: path});

      // todo determine if delete error gets caught
    } catch (err) {
      setShowDeleteErrModal(true);
    }
  };

  const deleteFromGuess = async (id, store: WSStore | null) => {
    if (store === null) {
      return;
    }
    try {
      await deleteGuessMedia(id, store.getGuessId());
      Transforms.removeNodes(editor, {at: path});
      const Node: DeletedImageElement = {type: 'deleted-image', children: [{text: ''}]};
      Transforms.insertNodes(editor, Node, {at: path});
    } catch (err) {
      setShowDeleteErrModal(true);
    }
  };

  const style = rotation === 1 ? {width: '100%', height: width} : undefined;
  return (
    <WrittenSubmissionContext.Consumer>
      {({store, submitted}) => (
        <div {...attributes}>
          <div contentEditable={false} className={containerClass} style={style} ref={containerRef}>
            <img
              ref={imageRef}
              className={imageClasses}
              src={getMediaUrl(element.id)}
              alt=''
              onLoad={() => getSize()}
            />
            {children}
            <div className={actionsBarClass}>
              <IconButton
                icon={['far', 'magnifying-glass-plus']}
                label='zoom'
                onClick={() => setShowViewModal(true)}
              />
              {!submitted && (
                <IconButton
                  icon='rotate-right'
                  onClick={() => handleRotation(editor)}
                  label='rotate image'
                />
              )}
              {(!submitted || !sessionStore.isStudent()) && (
                <IconButton
                  color='negative'
                  icon={['far', 'trash-can']}
                  onClick={() => {
                    if (!submitted) {
                      handleDelete(element.id, store);
                    } else {
                      setShowDeleteModal(true);
                    }
                  }}
                  label='delete image'
                />
              )}
            </div>
            {showDeleteModal && (
              <DeleteImageModal
                onClose={() => setShowDeleteModal(false)}
                onCancel={() => setShowDeleteModal(false)}
                onConfirm={() => deleteFromGuess(element.id, store)}
              />
            )}
            {showDeleteErrModal && (
              <ImageErrorModal deleted handleClose={() => setShowDeleteErrModal(false)} />
            )}
            {showViewModal && (
              <ViewImageModal
                url={getMediaUrl(element.id)}
                onClose={() => setShowViewModal(false)}
              />
            )}
          </div>
        </div>
      )}
    </WrittenSubmissionContext.Consumer>
  );
};

export default Image;

/* Modals */

interface ViewImageModalProps {
  onClose: any;
  url: string;
}

const ViewImageModal = ({onClose, url}: ViewImageModalProps) => {
  return (
    <Modal handleClose={onClose} ariaLabel='View Image'>
      {({CloseButtonWrapper, modalContentStyle}) => {
        return (
          <Card
            backgroundColor='subtle'
            paddingLevel={1}
            className={`image-supplement--zoom-modal ${modalContentStyle}`}
          >
            <img src={url} alt='' style={{width: '100%'}} />
            <CloseButtonWrapper>
              <Button className='image-supplement-zoom__close' color='secondary'>
                Close
              </Button>
            </CloseButtonWrapper>
          </Card>
        );
      }}
    </Modal>
  );
};

interface DeleteImageModalProps {
  onConfirm: any;
  onCancel: any;
  onClose: any;
}

const DeleteImageModal = ({onClose, onConfirm, onCancel}: DeleteImageModalProps) => {
  return (
    <Modal handleClose={onClose} role='dialog' ariaLabel='Delete Image'>
      {() => {
        return (
          <Dialogue
            inModal
            size='m'
            paddingLevel={4}
            alignTitle='center'
            handleClose={onClose}
            hideCloseBtn
          >
            <Text size='xl' bold as='p'>
              Are you sure you want to delete this image?
            </Text>
            <Dialogue.Body>
              <Text color='secondary'>
                The image will be removed permanently from Albert. This action cannot be undone.
              </Text>
            </Dialogue.Body>
            <Dialogue.BtnGroup align='right'>
              <Button color='secondary' onClick={onCancel}>
                No, cancel
              </Button>
              <Button color='negative' onClick={onConfirm}>
                Yes, delete
              </Button>
            </Dialogue.BtnGroup>
          </Dialogue>
        );
      }}
    </Modal>
  );
};

interface CommonErrorProps {
  handleClose: any;
  // errors?: any[]; @TODO: add error message handling
}

type DeleteProps = {
  deleted: true;
  handleRetry?: never;
};

type UploadProps = {
  deleted?: false;
  handleRetry: any;
};

type ConditionalModalProps = DeleteProps | UploadProps;

type ImageErrorModalProps = CommonErrorProps & ConditionalModalProps;

export const ImageErrorModal = ({
  handleClose,
  handleRetry,
  deleted = false
}: ImageErrorModalProps) => {
  return (
    <Modal handleClose={handleClose} role='dialog' ariaLabel='Image error'>
      {() => {
        return (
          <Dialogue size='m' inModal hideCloseBtn paddingLevel={3} alignTitle='center'>
            <Icon className='u-mar-lr_auto u-width_100pc' icon={['far', 'exclamation-triangle']} />
            <Text size='xl' bold as='p' className='u-text-align_center u-mar-tb_2'>
              {deleted ? 'Delete' : 'Upload'} Error
            </Text>
            <Dialogue.Body className='u-mar-b_0 u-pad-lr_2 u-text-align_center'>
              {deleted && (
                <Text color='secondary'>
                  An error occurred and your image was unable to be deleted.
                </Text>
              )}
              {!deleted && (
                <Text color='secondary'>
                  Images must be in JPG, GIF, or PNG formats with a maximum file size of 10mb.
                </Text>
              )}
            </Dialogue.Body>
            <Dialogue.BtnGroup align='center' direction='column' fillColumn>
              {!deleted && <Button onClick={handleRetry}>Try Again</Button>}
              <Button color='secondary' onClick={handleClose}>
                Dismiss
              </Button>
            </Dialogue.BtnGroup>
          </Dialogue>
        );
      }}
    </Modal>
  );
};
