// @flow
import {baseGuideExtensions} from '../guideHelpers/baseGuide.extensions';
import {type List, Map} from 'immutable';
import type {GuideLevelModelV1} from 'resources/GeneratedModels/GuideLevel/GuideLevelModel.v1';

export const extensions = {
  ...baseGuideExtensions,

  getThemes(): List<GuideLevelModelV1> {
    return this.cache('themes', () => {
      return this.getGuideLevels()
        .getCollection()
        .filter((guideLevel) => guideLevel.isTheme());
    });
  },

  getTopics(themeId: ?string): List<GuideLevelModelV1> {
    if (!themeId) {
      return this.cache('topics', () => {
        return this.getGuideLevels()
          .getCollection()
          .filter((guideLevel) => guideLevel.isTopic());
      });
    }

    return this.cache(`${themeId}-topics`, () => {
      const ids = this.getMeta()
        .getStructure()
        .get(themeId, new Map())
        .keySeq()
        .toList();

      return this.getGuideLevels()
        .getCollection()
        .filter((guideLevel) => {
          return ids.contains(guideLevel.getId());
        });
    });
  },

  getSubtopics(themeId: ?string, topicId: ?string): List<GuideLevelModelV1> {
    // TODO: can support only specifying one of themeId and topicId... but we probably never need that.
    if (!themeId && !topicId) {
      return this.cache('subtopics', () => {
        return this.getGuideLevels()
          .getCollection()
          .filter((guideLevel) => guideLevel.isSubtopic());
      });
    }

    return this.cache(`${themeId}-${topicId}-subtopics`, () => {
      const ids = this.getMeta()
        .getStructure()
        .getIn([themeId, topicId], new Map())
        .keySeq()
        .toList();

      return this.getGuideLevels()
        .getCollection()
        .filter((guideLevel) => {
          return ids.contains(guideLevel.getId());
        });
    });
  }
};
