import React from 'react';

import MarkdownBlock from '../shared/MarkdownBlock/MarkdownBlock';

import TextHighlightPrompt from './TextHighlightPrompt.react';

export default class TextHighlightExplanation extends React.Component {
  render() {
    const questionStoreName = this.props.storeName;
    const {question} = this.props;
    return (
      <div>
        <div className='text-highlight-explanation'>
          <h2>Correct Selections</h2>
          <TextHighlightPrompt
            explanation
            storeName={questionStoreName}
            question={question}
            isGuessSubmitted
          />
        </div>
        <MarkdownBlock heading='Sample Response' text={question.getMeta().getSolutionText()} />
      </div>
    );
  }
}
