// @flow
import * as React from 'react';
import {List} from 'immutable';
import {memoize} from 'lib/memoizer';
import sessionStore from 'client/Session/SessionStore';
import {GuideModelV1} from 'resources/augmented/Guide/GuideModel.v1';

import type {QueryBuilder} from '@albert-io/json-api-framework/request/builder';
import {resource} from '@albert-io/json-api-framework/request/builder';

const cache = memoize();

function getIdsOfLastQuestionsOfThemes(guide: GuideModelV1, usePublic?: boolean): List<string> {
  return cache(
    `idsOfLastQuestionsOfThemes${guide.getId()}${guide.getUpdatedAt().valueOf()}`,
    () => {
      return guide.getLastGuideLevelsFromThemes().reduce((acc, curr) => {
        const guideLevel = guide
          .getSortedGuideLevels()
          .find((guideLevel) => guideLevel.getId() === curr);
        if (guideLevel.getQuestionSets().isEmpty()) {
          return acc;
        }
        const sortedQuestionSets = guideLevel
          .getQuestionSets()
          .sortBy((set) =>
            set.relationshipMeta?.get('guide_level')?.get(guideLevel.getId())?.get('position')
          );
        const lastQuestions = usePublic
          ? sortedQuestionSets.last().getPublicQuestions()
          : sortedQuestionSets.last().getQuestions();
        if (lastQuestions.isEmpty()) {
          return acc;
        }
        const lastQuestion = lastQuestions.sortBy((question) => question.getPosition()).last();
        acc = acc.push(lastQuestion.getId());
        return acc;
      }, List());
    }
  );
}

function getFrqGuideQuery({
  subjectId,
  usePublicView
}: {
  subjectId: string,
  usePublicView?: boolean
}): QueryBuilder {
  const questionsInclude = `guide_levels_v2.question_sets_v1.${
    usePublicView ? 'public_questions_v1' : 'questions_v3'
  }`;
  const withMeta = `guide_v1,guide_level_v2,${
    usePublicView ? 'public_question_v1' : 'question_v3'
  }`;
  return resource('guide_v1')
    .mandarkEndpoint(['guides_v1'])
    .findOne()
    .include('subject_v2')
    .include('guide_levels_v2.subject_v2')
    .include(questionsInclude)
    .filter({
      guide_type: 'free_response',
      subject_v2: subjectId,
      included: {
        'guide_levels_v2.question_sets_v1.questions_v3': {
          question_type: 'free-response'
        }
      }
    })
    .customQuery({
      with_meta: withMeta
    })
    .customQuery(
      {
        with_meta: 'subject_v2',
        meta: {context: {user: {id: sessionStore.getUserId()}}}
      },
      sessionStore.hasValidSession()
    );
}

/**
 * We namespace these cached values by user ID and updated_at in addition to
 * the guide ID in order to 1) guard against cacheing outdated information from
 * Mandark and 2) guard against cacheing the logged-out state of these views.
 *
 * See also src/client/StudyGuide/utils.js
 *
 * @param guide
 * @param isReady
 * @param func
 */
function getCachedFreeResponseGuideItems(
  guide: GuideModelV1,
  isReady: boolean,
  func: (GuideModelV1) => Array<React.ComponentType<*>>
): React.Node<any> {
  const userId = sessionStore.getUserId() || '';
  return cache(
    `frqGuideItemsFor${userId}${guide.getId()}${guide.getUpdatedAt().valueOf()}}isReady=${isReady}`,
    () => func(guide)
  );
}

export default {
  getCachedFreeResponseGuideItems,
  getIdsOfLastQuestionsOfThemes,
  getFrqGuideQuery
};
