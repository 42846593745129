/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import classnames from 'classnames';
import ScoopContainer from 'generic/ScoopContainer/ScoopContainer.react';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {JSONLD as CourseStructuredData} from 'client/StructuredData/Course';
import {SubjectModelV2} from 'resources/GeneratedModels/Subject/SubjectModel.v2';
import './subject-card.scss';

export default class SubjectCard extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    /**
     * @todo in most cases the color is derieved from `subject.getCurriculumArea()` and
     * the same logic for specifying a default... it seems would be a good candidate
     * to move to an extension and just use the provided `subject` prop.
     */
    color: PropTypes.string,
    /**
     * @todo every current implementation is just passing `subject.getName()` for this
     * value, it seems like this prop might not be necessary.
     */
    title: PropTypes.string,
    to: PropTypes.string,
    content: PropTypes.node,
    long: PropTypes.bool,
    longDirection: PropTypes.oneOf(['normal', 'reverse']),
    subject: PropTypes.instanceOf(SubjectModelV2)
  };

  static defaultProps = {
    longDirection: 'normal'
  };

  render() {
    const {color, title, to, content, long, longDirection, className} = this.props;
    const WrapperComponent = to ? Link : 'div';
    const bodyContent =
      typeof content === 'string' ? (
        <MarkdownRendererV2 className='subject-card__body__md-renderer' text={content} />
      ) : (
        content
      );
    return (
      <div
        className={classnames('subject-card', className, {
          'subject-card--long': long,
          'subject-card--long-reverse': long && longDirection === 'reverse'
        })}
      >
        <WrapperComponent className='subject-card__container card card--no-padding' to={to || null}>
          <div className='subject-card__scoop-wrapper'>
            <ScoopContainer
              className='subject-card__icon-wrapper'
              backgroundColor={color}
              scoopSize={long ? 0 : 20}
            >
              <div
                className='subject-card__icon'
                style={{
                  backgroundImage: `url(${this.props.subject.getLogoLocation().href})`
                }}
              />
            </ScoopContainer>
          </div>
          <div className='subject-card__body'>
            <div className='subject-card__title'>{title}</div>
            {bodyContent}
          </div>
          <CourseStructuredData subject={this.props.subject} />
        </WrapperComponent>
      </div>
    );
  }
}
