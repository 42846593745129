//@flow
import {fromJS} from 'immutable';
import {Store} from 'client/framework';
import eliminationToolActions from './EliminationTool.actions';

class EliminationToolStore extends Store {
  constructor(name) {
    super(name);
    this.initialData = fromJS({enabled: false});
    this.setInitialData(this.initialData);
    this.handle(eliminationToolActions.SET_ENABLED, this._setEnabled);
  }

  _setEnabled(enabled) {
    this.writeData('enabled', enabled);
  }

  isEnabled(): boolean {
    return this.readData('enabled');
  }
}

const eliminationToolStore = new EliminationToolStore('LegacyEliminationToolStore');
export default eliminationToolStore;
