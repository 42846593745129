import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {callTargetedAction, setUpStore} from 'client/framework';
import SimpleModal from 'generic/SimpleModal/SimpleModal.react';
import windowConfirmActions from './WindowConfirmActions';
import WindowConfirmStore from './WindowConfirmStore';
import './window-confirm.scss';

/*
 * <WindowConfirm
 *   confirmButtonColor='green' // Options are green (default), blue, or red
 *   confirmButtonText='Do it, brah'
 *   title='Are you sure?'
 *   messageHeading='You are about to do it' // shown as a header IN the window. (for a title w/o the toolbar)
 *   message='Are you sure you wanna do that?'
 *   onConfirm={() => this.doThing()}
 *   onCancel={() => this.doThing()}
 *   onDismiss={() => this.doThing()}
 *   storeName='MyWindowConfirmStoreName'
 *   hideToolbar={true} // Hides the simple modal toolbar!
 * />
 */

export default class WindowConfirm extends React.Component {
  static propTypes = {
    confirmButtonColor: PropTypes.string,
    confirmButtonText: PropTypes.string,
    dismissButtonText: PropTypes.string,
    title: PropTypes.string,
    messageHeading: PropTypes.string,
    message: PropTypes.node,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    onDismiss: PropTypes.func,
    storeName: PropTypes.string.isRequired,
    hideToolbar: PropTypes.bool
  };

  static defaultProps = {
    confirmButtonColor: 'green',
    confirmButtonText: 'Ok',
    title: 'Albert.io',
    message: 'Are you sure you want to do that?',
    onConfirm: () => {},
    onCancel: () => {},
    onDismiss: () => {}
  };

  getStore() {
    return setUpStore(WindowConfirmStore, this.props.storeName);
  }

  hideModal() {
    callTargetedAction({
      name: windowConfirmActions.HIDE_MODAL,
      targetStore: this.props.storeName
    });
  }

  getMessageHeading() {
    if (!this.props.messageHeading) {
      return null;
    }

    return <h2 className='window-confirm__title'>{this.props.messageHeading}</h2>;
  }

  render() {
    const isActive = this.getStore().isActive();
    if (!isActive) {
      return null;
    }
    const buttonClasses = classnames('button', {
      'button--aqua': this.props.confirmButtonColor === 'aqua',
      'button--green': this.props.confirmButtonColor === 'green',
      'button--blue': this.props.confirmButtonColor === 'blue',
      'button--red': this.props.confirmButtonColor === 'red'
    });
    return (
      <SimpleModal
        className='window-confirm'
        bodyWrapperClassName='window-confirm__window'
        title={this.props.title}
        hideToolbar={this.props.hideToolbar}
        onModalClose={() => {
          this.hideModal();
          this.props.onDismiss();
        }}
      >
        {this.getMessageHeading()}
        <div className='window-confirm__message'>{this.props.message}</div>
        <div className='window-confirm__actions'>
          <button
            className='button button--lighter-gray'
            onClick={() => {
              this.hideModal();
              this.props.onCancel();
              this.props.onDismiss();
            }}
          >
            {this.props.dismissButtonText || 'Cancel'}
          </button>
          <button
            type='button'
            className={buttonClasses}
            onClick={() => {
              this.hideModal();
              this.props.onConfirm();
              this.props.onDismiss();
            }}
          >
            {this.props.confirmButtonText}
          </button>
        </div>
      </SimpleModal>
    );
  }
}
