// @flow
import {Map} from 'immutable';
import notifier from '@albert-io/notifier';
import {Store} from 'client/framework';
import appStore from 'client/AppStore';
import {activeQuestionActions} from 'client/InteractionEngineV2/shared/ActiveQuestion/ActiveQuestionActions';
import {activeQuestionStore} from 'client/InteractionEngineV2/shared/ActiveQuestion/ActiveQuestionStore';
import sessionStore from 'client/Session/SessionStore';
import interactionEngineStore from 'client/InteractionEngineV2/InteractionEngineStore';
import {GuessModel} from 'resources/Guess/Guess.model';
import {makeSubmitGuessQuery} from 'resources/Guess/Guess.queries';
import {
  getResourcePromise,
  invalidateInterest,
  invalidatePartialInterest,
  isResourcePopulated
} from 'resources/mandark.resource';
import studentAssignmentQuestionsListStore from 'client/InteractionEngineV2/IESessionTypes/Assignments/StudentAssignmentIE/StudentAssignmentQuestionsList/StudentAssignmentQuestionsList.store';
import studyGuideUtils from 'client/StudyGuide/utils';
import {mandarkEndpoint} from '@albert-io/json-api-framework/request/builder/legacy';

import type {
  MultipleChoiceQuestionModel,
  FreeEntryQuestionModel,
  FillInTheBlankQuestionModel,
  TextHighlightQuestionModel,
  TwoWayQuestionModel
} from 'resources/Question/Question.model';

type QuestionModel =
  | MultipleChoiceQuestionModel
  | FreeEntryQuestionModel
  | FillInTheBlankQuestionModel
  | TextHighlightQuestionModel
  | TwoWayQuestionModel;

// All the question type stores need to hold their own response data, but the process
// of submitting a guess is the same for all of them. This store is meant to be
// extended on the type stores such that we don't have to rewrite this logic over
// and over again
export default class QuestionTypeStore extends Store {
  constructor(name: string) {
    super(name);

    /**
     * This should ideally be handled the same way for all IE views, but right now the previousAttempts component
     * handles it for the IE and it is unhandled for students. It's not worth doing everything for now, but this
     * should definitely be looked at in the eventual refactor of this store and its associated pieces.
     */
    this.handleTargeted(
      activeQuestionActions.FETCH_GUESS_FOR_QUESTION_IN_ASSIGNMENT,
      this._fetchGuessForQuestionInAssignment
    );

    this.handleTargeted(activeQuestionActions.ACTIVE_QUESTION_SUBMIT_ANSWER, this._submitAnswer);
    this.handleTargeted(activeQuestionActions.ACTIVE_QUESTION_CLEAR_GUESS, this._clearGuess);
    this.handleTargeted(
      activeQuestionActions.ACTIVE_QUESTION_SET_SHOULD_SHOW_EXPLANATION,
      this._setShouldShowExplanation
    );
    this.handleTargeted(
      activeQuestionActions.ACTIVE_QUESTION_TOGGLE_SHOW_EXPLANATION,
      this._toggleShowExplanation
    );
    this.handleTargeted(activeQuestionActions.LOAD_QUESTION, this._loadQuestion);
  }

  async _submitAnswer({assignmentId}: {assignmentId: string}): Promise<*> {
    const {questionId} = activeQuestionStore;
    const content = this.guessContent;

    let responseQuestion;
    if (content) {
      this.writeData('isGuessSubmissionPending', true);
      const userId = sessionStore.getUserId();
      const endTime = Date.now();
      let timeElapsed = endTime - activeQuestionStore.startTime;

      /**
       * Time elapsed sometimes is negative. Not sure why but logging might help.
       * With https://github.com/albert-io/mandark/pull/1517, these will return as 400s.
       */
      if (timeElapsed < 0) {
        notifier.notify(new Error('Negative Time Elapsed'), {
          start: activeQuestionStore.startTime,
          end: endTime
        });

        timeElapsed = 0;
      }

      const guessData = {
        content,
        assignment_id: assignmentId,
        time_elapsed: timeElapsed
      };
      // Only used for Multiple Choice Questions
      if (this.buildEliminatedOptions) {
        guessData.eliminated_options = this.buildEliminatedOptions();
      }

      try {
        const newGuess = new GuessModel(guessData)
          .updateRelationship('question_v1', questionId)
          .updateRelationship('student_v1', userId)
          // TODO This should really just either ask for or be told if the IE session type
          // is assignment
          .updateRelationship('assignment_v2', assignmentId, assignmentId !== undefined);

        const responsePromise = newGuess.save({
          customQuery: makeSubmitGuessQuery({studentId: userId})
        });
        this.writeData('responsePromise', responsePromise);

        const responseGuess = await responsePromise;

        /**
         * Ensures that question lists always show the right indicator. Before it was only showing the correct one
         * sometimes, depending on timing of the guess response and when a page rerender happens
         */
        mandarkEndpoint(['questions_v1', questionId]).invalidateInterest();

        // Invalidate study guide stats
        const subjectId = responseGuess.getQuestion().getQuestionSet().getSubject().getId();
        studyGuideUtils.getPracticeGuideBySubjectIdQuery({subjectId}).invalidateInterest();

        if (assignmentId) {
          // TODO: not sure if this is needed anymore... validate against 2nd invalidation and clean up
          invalidateInterest(
            this.makeGuessWithAssignmentQuery({
              assignmentId,
              questionId,
              userId
            })
          );
          studentAssignmentQuestionsListStore.getAssignmentQuery().invalidateInterest();
          /* invalidation mainly meant for the assignments in the student dashboard */
          invalidatePartialInterest(
            mandarkEndpoint(['students_v2', sessionStore.getUserId()]).done()
          );
          // invalidate student list queries
          invalidatePartialInterest({
            resourcePath: ['assignments_v3'],
            filter: {
              classrooms_v1: appStore.routerProps.params.classId,
              students_v1: sessionStore.getUserId(),
              assignment_type: 'assignment'
            }
          });
        }
        this.writeData((store) =>
          store
            .set('__question', responseGuess.getQuestion())
            .set('isGuessCorrect', responseGuess.isCorrect())
            .set('rubric', responseGuess.getQuestion().getRubric())
            .set('solutionText', responseGuess.getQuestion().getSolutionText())
            .set('hasResponse', true)
            .set('pointsPossible', responseGuess.getQuestion().getPoints())
            .set('pointsEarned', responseGuess.getPointsEarned())
            .set('responseGuess', responseGuess)
        );
      } catch (error) {
        logger.error(error);
        this.writeData('error', 'There was an error with your submission');
      } finally {
        this.writeData('isGuessSubmissionPending', false);
      }

      // TODO Reconsider the below (applies to Text Highlight questions only)
      if (this._onSubmitAnswerEnd && responseQuestion) {
        this._onSubmitAnswerEnd(responseQuestion);
      }
    }
  }

  async _fetchGuessForQuestionInAssignment({
    assignmentId,
    question
  }: {
    assignmentId: string,
    question: QuestionModel
  }) {
    const questionId = question.getId();
    if (!assignmentId || this.questionId !== questionId || !question.hasGuess()) {
      return;
    }
    const userId = sessionStore.getUserId();
    const guessWithAssignmentQuery = this.makeGuessWithAssignmentQuery({
      assignmentId,
      questionId,
      userId
    });
    const guesses = await getResourcePromise(guessWithAssignmentQuery);
    if (!guesses.isEmpty()) {
      this._loadGuess(guesses.first());
    }
  }

  _loadGuess(guess: GuessModel | Map<any, any>) {
    this._loadQuestion();
    this.writeData((store) =>
      store
        .set('isGuessCorrect', guess.isCorrect())
        .set('hasResponse', true)
        .set('pointsEarned', guess.getPointsEarned())
    );
  }

  _loadQuestion(question = this.readData('__question')) {
    /**
     * @todo (sort of): The following assignment operation looks first to the "new-school" V2/V3 way
     * of fetching question rubrics (via the meta) vs. the "old-school" V1 top-level attribute. This
     * view is getting replaced by the new practice view soon, so I am content with foregoing making
     * changes to the queries and extensions.
     */
    const rubric = question.getIn(['meta', 'question_rubric'], question.getRubric());

    this.writeData((store) =>
      store
        .set('rubric', rubric)
        .set('solutionText', question.getSolutionText())
        .set('pointsPossible', question.getPoints())
    );
  }

  buildCorrectGuess(question: ?QuestionModel): GuessModel {
    const questionToLoad = question || this._fetchQuestion();
    return new GuessModel({
      content: questionToLoad.getValidResponse(),
      correct: true,
      points_earned: questionToLoad.getPoints(),
      question_v1: questionToLoad
    });
  }

  _clearGuess() {
    this.writeData((store) =>
      store
        .set('isGuessCorrect', false)
        .set('rubric', null)
        .set('solutionText', null)
        .set('hasResponse', false)
        .set('pointsPossible', null)
        .set('pointsEarned', null)
    );

    // After clearing the data, we'll force the window to the top of the page
    setTimeout(() => {
      global.scrollTo(0, 0);
    }, 0);
  }

  _setShouldShowExplanation(shouldShowExplanation: boolean) {
    this.writeData('shouldShowExplanation', shouldShowExplanation);
  }

  _toggleShowExplanation() {
    this.writeData('shouldShowExplanation', !this.shouldShowExplanation());
  }

  userCanSeeExplanation(hasSubjectAccess: boolean): boolean {
    if (sessionStore.hasTeacherAccess()) {
      return hasSubjectAccess || activeQuestionStore.questionSet.isFree();
    }

    return (
      this.hasResponse &&
      (!activeQuestionStore.question.isQuestionInActiveAssignment() ||
        interactionEngineStore.isStudentAssignmentSession ||
        interactionEngineStore.isTeacherAssignmentSession ||
        interactionEngineStore.isAssignmentV2Session)
    );
  }

  shouldShowExplanation(): boolean {
    return this.readData('shouldShowExplanation');
  }

  _fetchQuestion() {
    return this.readData('__question');
  }

  hasReceivedGuessHistory(assignmentId: string): boolean {
    if (this.questionId === false) {
      return true;
    }

    if (this._fetchQuestion().hasGuess() === false) {
      return true;
    }

    return isResourcePopulated(
      this.makeGuessWithAssignmentQuery({
        assignmentId,
        questionId: this.questionId,
        userId: sessionStore.getUserId()
      })
    );
  }

  isGuessCorrect(): boolean {
    return this.readData('isGuessCorrect');
  }

  makeGuessWithAssignmentQuery({
    questionId,
    userId,
    assignmentId
  }: {
    questionId: string,
    userId: string,
    assignmentId: string
  }): Object {
    return mandarkEndpoint(['questions_v1', questionId, 'guesses_v1'])
      .filter({student_v1: userId})
      .filter({assignment_v2: assignmentId})
      .done();
  }

  get hasResponse(): boolean {
    return this.readData('hasResponse', false);
  }

  get responsePromise(): Promise<*> {
    return this.readData('responsePromise');
  }

  get responseGuess(): any {
    return this.readData('responseGuess');
  }

  get pointsEarned(): number {
    return this.readData('pointsEarned', 0);
  }

  get pointsPossible(): number {
    return this.readData('pointsPossible', 0);
  }

  get solutionText(): string {
    return this.readData('solutionText', '');
  }

  get error(): string {
    return this.readData('error', '');
  }

  get validResponse(): Map<*, *> {
    return this.readData(['rubric', 'validResponse'], new Map());
  }

  get guessContent() {
    return this.buildGuessContent();
  }

  get hasUserSelectedAnswer(): boolean {
    const {guessContent} = this;

    return Object.keys(guessContent).length !== 0;
  }

  get isGuessSubmissionPending(): boolean {
    return this.readData('isGuessSubmissionPending');
  }

  get questionId(): string {
    return this.readData('questionId');
  }
}
