import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {List, Map, OrderedMap} from 'immutable';
import sessionStore from 'client/Session/SessionStore';
import classnames from 'classnames';
import {debounce} from 'lodash';
import appStore from 'client/AppStore';
import {WindowSizeConsumer, Heading} from '@albert-io/atomic';
import {resource} from '@albert-io/json-api-framework/request/builder';
import {SubjectModelV2} from 'resources/GeneratedModels/Subject/SubjectModel.v2';
import {ClassroomModelV1} from 'resources/GeneratedModels/Classroom/ClassroomModel.v1';
import awaitMandarkQueries from 'lib/hocs/awaitMandarkQueries';
import {normalizeSearchString} from 'lib/stringUtils';

import SubjectMenuHeader from './SubjectMenuHeader/SubjectMenuHeader.react';
import SubjectCard from './SubjectCard/SubjectCard.react';
import {courseLibraryStructure} from './subjectMenuData';
import {
  sidebarMenuItems,
  getCourseLibraryStructureWithSubjects,
  makeSidebarMenuItemSearchFunc,
  isLongCardMode
} from './subjectMenuData.utils';
import SidebarMenu from './SidebarMenu/SidebarMenu';
import SubgroupMenu from './SubgroupMenu/SubgroupMenu.react';
import FreePracticeBanner from './FreePracticeBanner/FreePracticeBanner.react';
import {withActivePathManager} from './withActivePathManager.hoc';
import {getActiveClassroomsWithSubjectsQuery} from './helpers/queries';
import SubjectCourseCard from './SubjectCourseCard/SubjectCourseCard.react';
import './subject-menu-renderer.scss';

import {getOverriddenDomainString} from './stringOverrideHelpers';

/**
 * This component renders subjects in a menu that is defined by `courseLibraryStructure`. You can place custom
 * content within the individual subject cards, and the cards may be made to act as Links.
 *
 * Props:
 *   activePath - Array of keys that make up the current path in the subject menu structure
 *   className - Class for the outermost component
 *   heroBackgroundColor - Background color for the Header component
 *   headerContent - Content to be passed to the Header component as children
 *   longDirection - When subjects are displayed as long cards, this controls whether their logo image is on the
 *     left (normal), or on the right ('reverse')
 *   makeSidebarItemHref - If passed, sidebar items will be rendered as Links, and will use the return
 *     value of this function as their `to` prop. Receives the current domain as its first argument, and current group
 *     as its second argument.
 *   makeSubjectCardContent - Function used to render subject card contents. Receives the subject as its argument.
 *   makeSubjectCardHref - If passed, subjects will be rendered as Links, and will use the return
 *     value of this function as their `to` prop. Receives the subject as its argument.
 *   onSearchStringUpdate - Function to call when the text in the search input in the Header component changes.
 *     Receives the new text string as its argument.
 *   onSidebarItemClick - Callback to call when sidebar item is clicked. The element will contain its domain
 *     and group as data properties.
 *   subgroupMenuContent - List of Maps to be used as subgroup menu data. <SubgroupMenu /> is just a simple wrapper over
 *     <Tabs />, so subgroupMenuContent can take the the shape of any valid tab content.
 *   subjects - List of subjects to render. This is provided by the awaitMandarkQueries HOC.
 *   useLongSubjectCards (optional) - If true, subject cards will display as long and skinny.
 *
 * @example:
 * <SubjectMenuRenderer
 *   activePath={['High School', 'Test Prep']}
 *   headerContent={<h1>Course library</h1>}
 *   makeSidebarItemHref={(group, domain) => group === 'All' ? base : `${base}/${kebabCase(group)}`}
 *   subgroupMenuContent={getSubgroupItemsAsLinkContents(activePath)}
 *   makeSubjectCardContent={(subject) => subject.getDescription()}
 *   makeSubjectCardHref={(subject) => `/${subject.getUrlSlug()}`}
 * />
 */
class BaseSubjectMenuRenderer extends React.Component {
  static propTypes = {
    makeSubjectCardContent: PropTypes.func.isRequired,
    makeSubjectCardHref: PropTypes.func,
    makeSidebarItemHref: PropTypes.func,
    onSidebarItemClick: PropTypes.func,
    subgroupMenuContent: PropTypes.instanceOf(List),
    activePath: PropTypes.array.isRequired,
    useLongSubjectCards: PropTypes.bool,
    subjects: ImmutablePropTypes.listOf(PropTypes.instanceOf(SubjectModelV2)).isRequired,
    className: PropTypes.string,
    headerContent: PropTypes.node,
    headerProps: PropTypes.object,
    heroBackgroundColor: PropTypes.string,
    onSearchStringUpdate: PropTypes.func,
    longDirection: PropTypes.oneOf(['normal', 'reverse']),
    subjectCardClassName: PropTypes.string,
    searchSubjectNameOnly: PropTypes.bool,
    subjectAreaContent: PropTypes.node,
    queryModifier: PropTypes.func, // eslint-disable-line
    additionalSidebarMenuItems: PropTypes.instanceOf(OrderedMap),
    isUserLicensedTeacher: PropTypes.bool,
    isLoggedOutOrStudent: PropTypes.bool,
    activeClassrooms: ImmutablePropTypes.listOf(PropTypes.instanceOf(ClassroomModelV1)),
    subjectBanner: PropTypes.node
  };

  static defaultProps = {
    onSearchStringUpdate: () => {},
    heroBackgroundColor: '#399fdf'
  };

  constructor(props) {
    super(props);
    this.state = {
      searchString: '',
      isSidebarMenuOpen: false,
      isHeaderVisible: true
    };
    this.headerRef = React.createRef();
    this.observer = null;
  }

  componentDidMount() {
    this._isMounted = true;

    // We toggle between the course library headers using an intersection observer to determine when
    // the initial title is no longer visible
    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          this.setState({isHeaderVisible: entry.isIntersecting});
        });
      },
      {
        threshold: 1
      }
    );
    if (this.headerRef.current) {
      this.observer.observe(this.headerRef.current);
    }
  }

  componentDidUpdate() {
    if (this.headerRef.current) {
      this.observer.observe(this.headerRef.current);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;

    if (this.headerRef.current) {
      this.observer.unobserve(this.headerRef.current);
    }
  }

  _isMounted = false;

  updateSearchString = debounce((e) => {
    if (!this._isMounted) {
      return;
    }
    const searchString = e.target.value;
    const trimmedSearchString = searchString.trim();
    this.setState(
      {
        searchString: trimmedSearchString
      },
      () => {
        this.props.onSearchStringUpdate(trimmedSearchString);
      }
    );
  }, 250);

  getActiveSubjects() {
    const subjects = this.props.subjects.asMap();
    const path = this.isAdditionalSidebarItem() ? [] : this.props.activePath;
    const activeSubjects = courseLibraryStructure
      .getIn(path, Map())
      .flatten()
      .toOrderedSet()
      .map((subjectId) => subjects.get(subjectId))
      .filter((subject) => {
        if (!subject) {
          return false;
        }
        // We don't want courses without a set price to appear in marketplace
        const hideSubject =
          appStore.routerProps.location.pathname.includes('/marketplace/') &&
          !subject.getSubscriptionsPrices().first();
        return !hideSubject;
      });
    const searchString = normalizeSearchString(this.state.searchString);
    return searchString
      ? activeSubjects
          .filter((subject) => {
            const superString = this.props.searchSubjectNameOnly
              ? subject.getName()
              : `${subject.getName()} ${subject.getDescription()}`;
            return normalizeSearchString(superString).includes(searchString);
          })
          .sortBy((subject) => subject.getName())
      : activeSubjects;
  }

  getSidebarMenuItemsForSearchString = makeSidebarMenuItemSearchFunc(
    getCourseLibraryStructureWithSubjects(this.props.subjects)
  );

  /*
    This function stops us from searching the subjectMenuData object for the sidebar if there are no results found when searching
    It is needed due to the addition of `licensed only subjects` that should not be returned in a keyword search while in marketplace.
    The sidebar doesn't have access to full subject objects and does a search on the `subjectMenuData` which doesn't know if a subject is licensed only
  */
  applySearchStringToSidebar = (searchString) => {
    if (this.getActiveSubjects().isEmpty()) {
      // empty search results
      return OrderedMap();
    }
    return this.getSidebarMenuItemsForSearchString(searchString);
  };

  resetSearchString = () => {
    this.setState({searchString: ''});
  };

  toggleSidebarMenu = () => {
    this.setState((state) => ({isSidebarMenuOpen: !state.isSidebarMenuOpen}));
  };

  shouldUseLongCards(viewportWidth) {
    return Object.prototype.hasOwnProperty.call(this.props, 'useLongSubjectCards')
      ? this.props.useLongSubjectCards
      : isLongCardMode(viewportWidth);
  }

  isAdditionalSidebarItem = () => {
    return this.props.additionalSidebarMenuItems
      .valueSeq()
      .includes(this.props.activePath[0]?.toLowerCase().replace(' ', '-'));
  };

  getNoSubjectsFoundContent() {
    return this.state.searchString ? (
      <div className='subject-cards-wrapper__no-subjects-found'>
        Sorry, your search did not return any subjects.
      </div>
    ) : (
      <div className='subject-cards-wrapper__no-subjects-found'>
        Exciting things are in the works - check back later!
      </div>
    );
  }

  render() {
    const {
      makeSubjectCardContent,
      makeSubjectCardHref,
      makeSidebarItemHref,
      onSidebarItemClick,
      subgroupMenuContent,
      activePath,
      className,
      headerContent,
      headerProps,
      longDirection,
      subjectCardClassName,
      isUserLicensedTeacher,
      isLoggedOutOrStudent,
      activeClassrooms,
      subjectBanner
    } = this.props;

    const initialGroup =
      this.props.additionalSidebarMenuItems && this.isAdditionalSidebarItem() ? '' : 'All Subjects';

    const [domain = 'All Subjects', group = initialGroup, subgroup] = activePath;
    let domainGroupHeadingDetail = domain;
    let domainGroupHeadingHeading =
      group !== domain ? `${getOverriddenDomainString(domain) ?? domain} ${group}` : domain;

    const {searchString} = this.state;

    if (searchString) {
      domainGroupHeadingDetail = `Results in ${
        activePath.length ? activePath.slice(0, 2).join(' – ') : 'All Subjects'
      }`;
      domainGroupHeadingHeading = `Results for "${searchString}"`;
    }

    return (
      <WindowSizeConsumer>
        {({viewportWidth}) => (
          <div
            className={classnames(
              'subject-menu-renderer',
              {
                'subject-menu-renderer--with-hero': headerContent
              },
              className
            )}
          >
            {headerContent && (
              <SubjectMenuHeader
                backgroundColor={this.props.heroBackgroundColor}
                searchInputProps={{
                  onChange: this.updateSearchString,
                  onReset: this.resetSearchString,
                  placeholder: 'Find subjects'
                }}
                {...headerProps}
              >
                {headerContent}
              </SubjectMenuHeader>
            )}
            <div className='subject-menu-renderer__main'>
              <SidebarMenu
                menuItems={
                  searchString ? this.applySearchStringToSidebar(searchString) : sidebarMenuItems
                }
                additionalSidebarMenuItems={this.props.additionalSidebarMenuItems}
                makeItemHref={makeSidebarItemHref}
                onItemClick={onSidebarItemClick}
                activePath={activePath}
                group={domainGroupHeadingDetail}
                subgroup={domainGroupHeadingHeading}
              />
              <div className='subject-menu-renderer__subject-area-wrapper'>
                {sessionStore.isTeacher() && <FreePracticeBanner />}
                {this.props.subjectAreaContent ? (
                  React.cloneElement(this.props.subjectAreaContent, {
                    allCourses: this.getActiveSubjects(),
                    useLongSubjectCards: this.shouldUseLongCards(viewportWidth),
                    emptyState: this.getNoSubjectsFoundContent(),
                    isSearching: !!this.state.searchString,
                    searchString: this.state.searchString,
                    longDirection,
                    makeSubjectCardContent,
                    makeSubjectCardHref,
                    viewportWidth
                  })
                ) : (
                  <>
                    <div ref={this.headerRef}>
                      <Heading
                        className='u-display_flex u-gap_space-x1 u-mar-t_2 u-mar-l_5'
                        as='h2'
                      >
                        {getOverriddenDomainString(domain) ?? domain}
                        {group !== domain && <span className='u-font-weight_normal'>{group}</span>}
                      </Heading>
                    </div>
                    <div
                      className={classnames({
                        'subject-menu-renderer__horizontal-menu': !this.state.isHeaderVisible
                      })}
                    >
                      {/* Renders grade level title for scrolling header */}
                      {this.state.isHeaderVisible ? null : (
                        <Heading
                          className='u-mar-t_2 subject-menu-renderer__horizontal-menu-header'
                          as='h2'
                        >
                          {getOverriddenDomainString(domain) ?? domain}
                          {group !== domain && (
                            <span className='u-font-weight_normal u-mar-l_1'> {group}</span>
                          )}
                        </Heading>
                      )}
                      {subgroupMenuContent && (
                        <SubgroupMenu content={subgroupMenuContent} activeGroup={subgroup} />
                      )}
                    </div>
                    {subjectBanner}
                    <SubjectCardsMapper
                      subjects={this.getActiveSubjects()}
                      makeSubjectCardContent={makeSubjectCardContent}
                      makeSubjectCardHref={makeSubjectCardHref}
                      useLongSubjectCards={this.shouldUseLongCards(viewportWidth)}
                      longDirection={longDirection}
                      subjectCardClassName={subjectCardClassName}
                      viewportWidth={viewportWidth}
                      emptyState={this.getNoSubjectsFoundContent()}
                      isUserLicensedTeacher={isUserLicensedTeacher}
                      isLoggedOutOrStudent={isLoggedOutOrStudent}
                      activeClassrooms={activeClassrooms}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </WindowSizeConsumer>
    );
  }
}

class SubjectCardsMapper extends React.PureComponent {
  static propTypes = {
    subjects: ImmutablePropTypes.iterableOf(PropTypes.instanceOf(SubjectModelV2)),
    makeSubjectCardHref: PropTypes.func,
    makeSubjectCardContent: PropTypes.func.isRequired,
    useLongSubjectCards: PropTypes.bool,
    longDirection: PropTypes.oneOf(['normal', 'reverse']),
    subjectCardClassName: PropTypes.string,
    viewportWidth: PropTypes.number,
    emptyState: PropTypes.node,
    isUserLicensedTeacher: PropTypes.bool,
    isLoggedOutOrStudent: PropTypes.bool,
    activeClassrooms: ImmutablePropTypes.listOf(PropTypes.instanceOf(ClassroomModelV1))
  };

  static defaultProps = {
    makeSubjectCardHref: () => {}
  };

  makeSubjectCardProps = (subject) => {
    const curriculumAreaSlug = subject.getCurriculumAreaSlug();
    const color = `var(--background-domain-${curriculumAreaSlug})`;

    return {
      color,
      title: subject.getName(),
      long: this.props.useLongSubjectCards,
      longDirection: this.props.longDirection,
      className: this.props.subjectCardClassName
    };
  };

  render() {
    const {subjects, isUserLicensedTeacher, activeClassrooms, isLoggedOutOrStudent} = this.props;
    const {pathname} = appStore.routerProps.location;
    const isCourseLibrary = !pathname.includes('/marketplace') && pathname.includes('/subjects');
    const content = subjects.isEmpty()
      ? this.props.emptyState
      : this.props.subjects.map((subject) => {
          const CardElement = isCourseLibrary ? SubjectCourseCard : SubjectCard;
          const subjectCardProps = isCourseLibrary
            ? {
                isUserLicensedTeacher,
                activeClassrooms,
                isLoggedOutOrStudent,
                isAssessmentsOnly: subject
                  .getGuides()
                  .every((guide) => guide.getGuideType() === 'assessment')
              }
            : this.makeSubjectCardProps(subject);

          return (
            <CardElement
              key={subject.getId()}
              subject={subject}
              to={this.props.makeSubjectCardHref(subject)}
              content={this.props.makeSubjectCardContent(subject, this.props.viewportWidth)}
              className={this.props.subjectCardClassName}
              {...subjectCardProps}
            />
          );
        });

    return (
      <div
        className={classnames('subject-cards-wrapper', {
          'subject-cards-wrapper--course-library': isCourseLibrary
        })}
      >
        {content}
      </div>
    );
  }
}

export const SubjectMenuRenderer = awaitMandarkQueries((props) => {
  const options = {
    queries: {
      subjects: props.queryModifier(
        resource('subjects_v2')
          .mandarkEndpoint(['subjects_v2'])
          .filter({
            hidden: false
          })
          .include('curriculum_area_v1')
          .include('guides_v1')
          .pageSize('infinite')
      )
    }
  };
  if (sessionStore.isTeacher()) {
    options.queries.activeClassrooms = getActiveClassroomsWithSubjectsQuery();
  }
  return options;
}, BaseSubjectMenuRenderer);

SubjectMenuRenderer.defaultProps = {
  queryModifier: (query) => query
};

export const SubjectMenuRendererWithActivePath = withActivePathManager(SubjectMenuRenderer);
