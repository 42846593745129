// @flow
import {fromJS, Set, Map} from 'immutable';
import QuestionTypeStore from 'client/QuestionTypes/common/V2/QuestionType.store';
import snippetSelectionQuestionActions from './SnippetSelectionQuestion.actions';
import {activeQuestionActions} from 'client/InteractionEngineV2/shared/ActiveQuestion/ActiveQuestionActions';
import questionTypeActions from 'components/QuestionTypes/questionType.actions';

export default class SnippetSelectionQuestionStore extends QuestionTypeStore {
  constructor(name: string, questionId: string, question) {
    super(name);
    this.setInitialData(
      fromJS({
        questionId: questionId,
        selectedOptions: Set(),
        shouldShowExplanation: false
      })
    );
    this.writeData('__question', question);
    this.writeData('selectedOptions', new Set());
    this.handleTargeted(questionTypeActions.CLEAR_GUESS, this._clearGuess);
    this.handleTargeted(questionTypeActions.LOAD_GUESS, this._loadGuess);
    this.handleTargeted(activeQuestionActions.ACTIVE_QUESTION_CLEAR_GUESS, this._clearGuess);
    this.handleTargeted(activeQuestionActions.ACTIVE_QUESTION_LOAD_GUESS, this._loadGuess);
    this.handleTargeted(snippetSelectionQuestionActions.TOGGLE_OPTION, this._toggleOption);
    this.handleTargeted(
      snippetSelectionQuestionActions.SET_SELECTED_OPTIONS,
      this._setSelectedOptions
    );
  }

  _toggleOption(optionId: string) {
    if (this.isOptionSelected(optionId)) {
      this.writeData('selectedOptions', this.getSelectedOptions().delete(optionId));
    } else {
      this.writeData('selectedOptions', this.getSelectedOptions().add(optionId));
    }
  }

  _setSelectedOptions(optionIds: Set<string>) {
    this.writeData('selectedOptions', optionIds);
  }

  getSelectedOptions(): Set<string> {
    return this.readData('selectedOptions');
  }

  isOptionSelected(optionId: string): boolean {
    return this.getSelectedOptions().includes(optionId);
  }

  isOptionCorrect(optionId: string): boolean {
    return this.getCorrectAnswers().includes(optionId);
  }

  getCorrectAnswers(): Set<string> {
    return this.validResponse.keySeq().toSet();
  }

  buildGuessContent(): Object {
    const selectedOptions = this.readData('selectedOptions');
    return selectedOptions.reduce((result, option) => {
      result[option] = true;
      return result;
    }, {});
  }

  _loadGuess(guess: Map<*, *>) {
    // Parent store needs points data from guess and needs to register this question has a guess
    super._loadGuess(guess);
    const selectedOptions = guess.get('content', new Map()).map((val, optionId) => optionId);
    this.writeData('selectedOptions', selectedOptions);
  }

  _clearGuess() {
    // Need to call this on the parent class
    super._clearGuess();
    this.writeData('selectedOptions', this.initialData.get('selectedOptions'));
  }

  isSelectedOption(id: string): boolean {
    return this.readData('selectedOptions').includes(id);
  }
}
