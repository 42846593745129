import React from 'react';
import PropTypes from 'prop-types';
import {Button} from '@albert-io/atomic';

import './assets/social-button.scss';

export default class SocialButton extends React.PureComponent {
  static propTypes = {
    provider: PropTypes.oneOf(['google', 'clever']),
    href: PropTypes.string,
    children: PropTypes.node,
    disabled: PropTypes.bool
  };

  render() {
    return (
      <Button
        as='a'
        href={this.props.href}
        color={`vendor-${this.props.provider}`}
        className='sso-button'
        disabled={this.props.disabled}
      >
        {this.props.children}
      </Button>
    );
  }
}
