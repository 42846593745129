// @flow
import download from 'downloadjs';
import {sanitizeFilename} from 'lib/stringUtilsTs';
import type {Map} from 'immutable';

export const run = function run(args: Map<*, *>): Promise<*> {
  /**
   * This RegExp was pulled from downloadjs...
   */
  if (/(Version)\/(\d+)\.(\d+)(?:\.(\d+))?.*Safari\//.test(window.navigator.userAgent)) {
    window.alert(
      'This feature is not yet supported in Safari.\n' +
        'For best results, please use the latest version of Google Chrome.'
    );
    return new Promise((reject, resolve) => {
      resolve();
    });
  }

  const promise = args.get('promise');
  return promise.then((response) => {
    download(response.body, sanitizeFilename(args.get('filename')), response.type);
  });
};
