import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {setUpStore} from 'client/framework';

import GenericExplanation from '../../common/V2/GenericExplanation.react';

import FillInTheBlankQuestionStore from './FillInTheBlankQuestion.store';

export default class FillInTheBlankExplanation extends React.Component {
  static propTypes = {
    question: PropTypes.object,
    questionStoreName: PropTypes.string,
    questionId: PropTypes.string
  };

  getStore() {
    return setUpStore(
      FillInTheBlankQuestionStore,
      this.props.questionStoreName,
      this.props.questionId
    );
  }

  render() {
    const {question} = this.props;
    const dropdowns = question.getDropdowns();
    return (
      <GenericExplanation {...this.props}>
        <div className='fitb-v2-explanation'>
          <h2>Correct Answer(s)</h2>
          <MarkdownRendererV2
            className={classnames({
              'fitb-input-area-dropdown': !dropdowns.isEmpty()
            })}
            isExplanation
            text={question.getDropdownText()}
            dropdowns={dropdowns}
            store={this.getStore()}
            question={question}
          />
        </div>
      </GenericExplanation>
    );
  }
}
