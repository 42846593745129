import {get} from 'lodash';
import React from 'react';
import {Helmet} from 'react-helmet';
import PropTypes from 'prop-types';

import {title} from 'lib/head';
import {ERROR_TYPES} from 'client/constants';
import {Logo, Button, Text, Grid} from '@albert-io/atomic';

import error404Image from './assets/Error-404-Blue.png';
import errorUnauthorizedImage from './assets/Error-UnauthorizedAccess-Blue.png';
import errorGenericImage from './assets/Error-Generic-Blue.png';

import './error-page.scss';

const ERROR_DATA = {
  [ERROR_TYPES.NOT_FOUND]: {
    text: `Looks like the page you are looking for doesn't exist or may have been moved.`,
    image: error404Image
  },
  [ERROR_TYPES.GENERIC]: {
    text: `Oops! Looks like something went wrong.`,
    image: errorGenericImage
  },
  [ERROR_TYPES.UNAUTHORIZED]: {
    text: `Looks like you don't have access to this page, please sign-in and try again.`,
    image: errorUnauthorizedImage
  }
};

/**
 * The error page component sources an `errorCode` from the direct prop or query parameter (`errorCode`).
 * Exposing a prop, but also using a query parameter let us use this component INLINE, but also
 * at a route level where it can be parameterized.
 *
 * In all callsites it is *recommended* to use the `ERROR_TYPES` constants instead of a "raw" number.
 *
 * @returns {React.Component} The `<ErrorPage>` component
 *
 * @example ```
 *  history.pushState(null, `/error?errorCode=${ERROR_TYPES.UNAUTHORIZED}`);
 * ```
 * @example ```
 *  <ErrorPage errorCode={ERROR_TYPES.NOT_FOUND} />
 * ```
 */
export default class ErrorPage extends React.PureComponent {
  static propTypes = {
    errorCode: PropTypes.oneOf(Object.keys(ERROR_TYPES)),
    errorText: PropTypes.string
  };

  getErrorCodeFromProps = () => {
    return (
      this.props.errorCode ||
      get(this.props, ['location', 'query', 'errorCode'], ERROR_TYPES.GENERIC)
    );
  };

  render() {
    const errorCode = this.getErrorCodeFromProps();
    const errorData = Object.prototype.hasOwnProperty.call(ERROR_DATA, errorCode)
      ? ERROR_DATA[errorCode]
      : ERROR_DATA[ERROR_TYPES.GENERIC];

    return (
      <Grid.Container className='u-text-align_center'>
        <Helmet>{title('Error | Albert')}</Helmet>
        <Grid.Col>
          <Grid.Row align='center' justify='center'>
            <Logo width='200px' />
          </Grid.Row>
          <Grid.Row align='center' justify='center'>
            <img alt='' className='error-page__img' src={errorData.image} />
          </Grid.Row>
          <Grid.Row align='center' justify='center'>
            <Text size='l' paragraph>
              {this.props.errorText || errorData.text}
            </Text>
          </Grid.Row>
          <Grid.Row align='center' justify='center'>
            <Button as='a' href='/'>
              Return home
            </Button>
          </Grid.Row>
        </Grid.Col>
      </Grid.Container>
    );
  }
}
