import React from 'react';
import PropTypes from 'prop-types';
import {Map} from 'immutable';
import {callTargetedAction} from 'client/framework';
import passageCorrectionQuestionActions from './PassageCorrectionQuestion.actions';
import PassageCorrectionPreSubmissionRenderer from './PassageCorrectionPreSubmissionRenderer.react';
import PassageCorrectionPostSubmissionRenderer from './PassageCorrectionPostSubmissionRenderer.react';
import {QuestionModelV3} from 'resources/augmented/Question/QuestionModel.v3';
import './passage-correction-question.scss';

export default class PassageCorrectionInputArea extends React.Component {
  static propTypes = {
    storeName: PropTypes.string,
    question: PropTypes.instanceOf(QuestionModelV3),
    isGuessSubmitted: PropTypes.bool
  };

  componentDidMount() {
    this.setInitialCorrections();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.storeName !== this.props.storeName) {
      this.setInitialCorrections();
    }
  }

  addWordToInitialCorrection = ({text, matchedRegionIndex, optionIndex}) => {
    if (
      matchedRegionIndex === undefined ||
      this.props.question.getStore().hasInitialCorrections()
    ) {
      return;
    }
    this.initialCorrections = this.initialCorrections.setIn(
      [matchedRegionIndex, optionIndex],
      text
    );
  };

  setInitialCorrections() {
    if (this.props.question.getStore().hasInitialCorrections()) {
      return;
    }
    callTargetedAction({
      name: passageCorrectionQuestionActions.SET_INITIAL_CORRECTIONS,
      payload: this.initialCorrections,
      targetStore: this.props.question.getStore().getName()
    });
    this.initialCorrections = Map();
  }

  initialCorrections = Map();

  render() {
    const {isGuessSubmitted} = this.props;

    return (
      <div className='passage-correction-question'>
        <h2 className='question__prompt-heading'>
          {!isGuessSubmitted
            ? 'Click the word(s) you want to edit in the sentence below and type your changes.'
            : 'Click highlighted areas for more information.'}
        </h2>
        {!isGuessSubmitted ? (
          <PassageCorrectionPreSubmissionRenderer
            {...this.props}
            onRenderWordFunc={this.addWordToInitialCorrection}
            isGuessSubmitted={isGuessSubmitted}
          />
        ) : (
          <PassageCorrectionPostSubmissionRenderer
            {...this.props}
            onRenderWordFunc={this.addWordToInitialCorrection}
            isGuessSubmitted={isGuessSubmitted}
          />
        )}
      </div>
    );
  }
}
