import React from 'react';
import PropTypes from 'prop-types';

import {Grid} from '@albert-io/atomic';

const CenteredPage = ({children}) => (
  <Grid.Container>
    <Grid.Row justify='center'>
      <Grid.Col all={5} s={12}>
        {children}
      </Grid.Col>
    </Grid.Row>
  </Grid.Container>
);

CenteredPage.propTypes = {
  children: PropTypes.node
};

export default CenteredPage;
