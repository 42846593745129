import {
  DirtyFieldReference,
  runAndAwaitTranslationJob,
  upsertTranslatedField
} from 'client/EditPage/V2/QuestionEditorV2Store';
import {
  ALTERNATE_CONTENT_LANGUAGES,
  AlternateContentLanguage,
  PRIMARY_CONTENT_LANGUAGE,
  SupportedContentLanguage,
  TranslatedSupplement,
  TranslationRequest
} from 'client/EditPage/V2/QuestionEditorV2Store.types';
import {isEqual, set as lodashSet} from 'lodash';
import {genericMandarkRequest} from 'resources/mandark.resource';
import {create} from 'zustand';
import {devtools} from 'zustand/middleware';

interface SupplementEditorV2State {
  loadingTranslations: boolean;
  generatingTranslations: boolean;
  savingTranslatedFields: boolean;
  currentLanguage: SupportedContentLanguage;
  translatedSupplements: Record<AlternateContentLanguage, TranslatedSupplement | null>;
  dirtyTranslatedFields: DirtyFieldReference[];
  initController: AbortController | null;
}

type UserEditableTranslatedFieldAttributes = 'text' | 'notes' | 'status';

interface SupplementEditorV2Actions {
  init: (supplementId: string | null) => Promise<void>;
  resetTranslations: () => void;
  generateTranslations: (lang: AlternateContentLanguage, type: 'full' | 'partial') => Promise<void>;
  refreshTranslations: (lang: AlternateContentLanguage) => Promise<void>;
  updateTranslatedField: (
    language: AlternateContentLanguage,
    supplementFieldName: string,
    translatedFieldAttributeName: UserEditableTranslatedFieldAttributes,
    value: string
  ) => void;
  saveTranslatedFields: () => Promise<void>;
  setCurrentLanguage: (language: SupportedContentLanguage) => void;
}

interface SupplementEditorV2Queries {
  currentTranslatedSupplement: () => TranslatedSupplement | null;
  hasPublishedTranslations: () => boolean;
  hasChanges: () => boolean;
}

export const useSupplementEditorV2Store = create<
  SupplementEditorV2State & SupplementEditorV2Actions & SupplementEditorV2Queries
>()(
  devtools((set, get) => ({
    loadingTranslations: false,
    generatingTranslations: false,
    savingTranslatedFields: false,
    currentLanguage: 'en',
    translatedSupplements: {es: null},
    dirtyTranslatedFields: [],
    initController: null,
    init: async (supplementId) => {
      const {initController} = get();
      if (initController) {
        initController.abort();
      }

      const controller = new AbortController();
      set({
        loadingTranslations: true,
        initController: controller,
        translatedSupplements: {es: null},
        dirtyTranslatedFields: []
      });

      const translatedSupplements: Record<AlternateContentLanguage, TranslatedSupplement | null> = {
        es: null
      };

      if (supplementId) {
        await Promise.all(
          ALTERNATE_CONTENT_LANGUAGES.map(async (language) => {
            const translatedSupplement = await fetchTranslatedSupplement(
              supplementId,
              language,
              controller.signal
            );
            translatedSupplements[language] = translatedSupplement;
          })
        );
      }

      set({translatedSupplements, initController: null, loadingTranslations: false});
    },

    resetTranslations: () => {
      set({translatedSupplements: {es: null}, dirtyTranslatedFields: []});
    },

    setCurrentLanguage: (language) => {
      set({currentLanguage: language});
    },

    generateTranslations: async (lang, type) => {
      const translatedSupplement = get().translatedSupplements[lang];
      if (!translatedSupplement) {
        return;
      }

      const requiredFields = translatedSupplement.required_fields;
      const actualFields = translatedSupplement.translated_fields.filter(
        (f) => f.status !== 'draft'
      );
      const missingFields = requiredFields.filter(
        (f) => !actualFields.some((af) => af.field === f)
      );

      const translationRequest: TranslationRequest = {
        resource_type: 'supplement',
        resource_id: translatedSupplement.id,
        fields: type === 'full' ? undefined : missingFields
      };

      try {
        set({generatingTranslations: true});
        await runAndAwaitTranslationJob(lang, [translationRequest]);
        await get().refreshTranslations(lang);
      } finally {
        set({generatingTranslations: false});
      }
    },

    refreshTranslations: async (lang) => {
      const {translatedSupplements, initController} = get();
      const translatedSupplement = translatedSupplements[lang];
      if (!translatedSupplement) {
        return;
      }
      if (initController) {
        initController.abort();
      }

      const newController = new AbortController();

      set({loadingTranslations: true, initController: newController});

      const refreshedTranslatedSupplement = await fetchTranslatedSupplement(
        translatedSupplement.id,
        lang,
        newController.signal
      );

      set({
        loadingTranslations: false,
        translatedSupplements: {...translatedSupplements, [lang]: refreshedTranslatedSupplement}
      });
    },

    updateTranslatedField: (language, supplementFieldName, translatedFieldAttributeName, value) => {
      const {translatedSupplements} = get();
      const translatedSupplement = translatedSupplements[language];
      if (!translatedSupplement) {
        return;
      }

      const updatedSupplement = {...translatedSupplement};
      if (translatedFieldAttributeName === 'text') {
        lodashSet(updatedSupplement, supplementFieldName, value);
      }
      let modified = false;
      updatedSupplement.translated_fields = updatedSupplement.translated_fields.map((field) => {
        if (field.field === supplementFieldName) {
          modified = true;
          return {...field, [translatedFieldAttributeName]: value};
        }
        return field;
      });

      if (!modified) {
        updatedSupplement.translated_fields.push({
          id: '',
          field: supplementFieldName,
          language,
          resource_id: updatedSupplement.id,
          status: 'draft',
          notes: '',
          text: '',
          last_modified_by: null,
          [translatedFieldAttributeName]: value
        });
      }

      const newDirtyField: DirtyFieldReference = {
        language,
        field: supplementFieldName
      };
      const prevDirtyFields = get().dirtyTranslatedFields;
      const alreadyExists = prevDirtyFields.some((f) => isEqual(f, newDirtyField));

      set({
        translatedSupplements: {...translatedSupplements, [language]: updatedSupplement},
        dirtyTranslatedFields: alreadyExists ? prevDirtyFields : [...prevDirtyFields, newDirtyField]
      });
    },

    saveTranslatedFields: async () => {
      const {dirtyTranslatedFields, savingTranslatedFields} = get();
      if (dirtyTranslatedFields.length === 0 || savingTranslatedFields) {
        return;
      }
      const fieldsToUpsert = dirtyTranslatedFields.map((f) => {
        const {translatedSupplements} = get();
        const translatedSupplement = translatedSupplements[f.language];
        return translatedSupplement!.translated_fields.find((f2) => f2.field === f.field)!;
      });

      const upsertPromises = fieldsToUpsert.map((field) =>
        upsertTranslatedField('supplement', field)
      );

      await Promise.all(upsertPromises);
      set({dirtyTranslatedFields: [], savingTranslatedFields: false});
    },
    currentTranslatedSupplement: () => {
      const {currentLanguage} = get();
      if (currentLanguage !== PRIMARY_CONTENT_LANGUAGE) {
        return get().translatedSupplements[currentLanguage];
      }
      return null;
    },
    hasPublishedTranslations: () => {
      const translatedSupplement = get().currentTranslatedSupplement();
      if (!translatedSupplement) {
        return false;
      }
      return translatedSupplement.translated_fields.some((f) => f.status === 'published');
    },
    hasChanges: () => {
      return get().dirtyTranslatedFields.length > 0;
    }
  }))
);

const fetchTranslatedSupplement = async (
  supplementId: string,
  language: AlternateContentLanguage,
  signal: AbortSignal
): Promise<TranslatedSupplement> => {
  const response = await genericMandarkRequest(
    'get',
    {
      resourcePath: ['json', 'translations', 'translated_supplements', supplementId],
      customQuery: {language, include: 'translated_fields'}
    },
    null,
    {abortSignal: signal}
  );

  return response.toJS();
};
