import React, {useCallback} from 'react';

import {callAction} from 'client/framework';
import {pushQueryParams} from 'client/history';
import {Modal, Dialogue} from '@albert-io/atomic';

import supplementManagerActions from './SupplementManager.actions';
import supplementManagerStore from './SupplementManager.store';
import SupplementManager from './SupplementManager';

import './supplement-manager.scss';

export default function SupplementManagerModal() {
  const handleModalClose = useCallback((e) => {
    /**
     * Since we wind up stacking `WindowConfirm` components over a `SimpleModal`
     * we have to block the `SimpleModal.handleClickOutsideComponent` `onModalClose`
     * trigger.
     */
    const isConfimation = Array.prototype.some.call(
      global.document.querySelectorAll('.window-confirm'),
      (confirm) => confirm.contains(e.target)
    );
    if (isConfimation) {
      return;
    }
    callAction(supplementManagerActions.SHOW_MODAL, false);
    pushQueryParams({
      supplement: '',
      supplementMode: '',
      supplementOwner: '',
      supplementSearchStr: '',
      supplementSelectedSubject: '',
      supplementType: ''
    });
  }, []);

  if (!supplementManagerStore.showModal()) {
    return null;
  }

  return (
    <Modal handleClose={handleModalClose as any} ariaLabel='Supplement Manager Modal'>
      {({modalContentStyle}) => (
        <Dialogue
          inModal
          className={`supplement-manager--dialogue ${modalContentStyle}`}
          handleClose={handleModalClose as any}
          paddingLevel={0}
        >
          <Dialogue.Body>
            <SupplementManager isModal />
          </Dialogue.Body>
        </Dialogue>
      )}
    </Modal>
  );
}
