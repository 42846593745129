/* eslint-disable import/no-extraneous-dependencies */

/**
 * @memberof @albert-io/notifier
 */
import React from 'react';
import {Honeybadger, HoneybadgerErrorBoundary} from '@honeybadger-io/react';
import {getEnvironment} from '@albert-io/environment';

import {ERROR_TYPES} from 'client/constants';
import ErrorPage from 'client/static/Error/ErrorPage.react';

import notifier from '../../notifier';

const contentFilters = [
  'token',
  'email',
  'username',
  'password',
  'verify_old_password',
  'password_reset_link',
  'verify_password_reset_link',
  'stripe_customer_id',
  'first_name',
  'last_name',
  'salutation',
  'consent_email'
];

function startHoneybadger() {
  Honeybadger.configure({
    apiKey: process.env.HONEYBADGER_API_KEY,
    breadcrumbsEnabled: true,
    environment: getEnvironment(),
    filters: contentFilters,
    reportData: true,
    revision: process.env.VERSION
  });

  // Set up beforeNotify handler
  Honeybadger.beforeNotify(function (notice) {
    const hasTranslate = global.document.getElementsByClassName('translated-ltr').length > 0;
    notice.context = {user: notifier.getIdentity(), hasTranslate};
  });
}

if (process.env.IS_BROWSER && process.env.HONEYBADGER_API_KEY) {
  startHoneybadger();
  notifier.setClient(Honeybadger);

  notifier.setNotifyHandler((error) => {
    Honeybadger.notify(error);
  });
}

export const ErrorBoundary = ({children}) => (
  <HoneybadgerErrorBoundary
    honeybadger={Honeybadger}
    ErrorComponent={() => <ErrorPage errorCode={ERROR_TYPES.GENERIC} />}
  >
    {children}
  </HoneybadgerErrorBoundary>
);

export default notifier;
