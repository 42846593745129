import React from 'react';
import PropTypes from 'prop-types';
import {callAction, destroyStore, setShouldIgnoreActions} from 'client/framework';
import sessionStore from 'client/Session/SessionStore';
import interactionEngineActions from './InteractionEngineActions';
import interactionEngineStore from './InteractionEngineStore';
import {ieTypes} from './interactionEngineConstants';
import {preventContextMenuAndCopy, allowContextMenuAndCopy} from './InteractionEngine.util';
import VanillaIE from './IESessionTypes/VanillaIE/VanillaIE.react';
import TeacherAssignmentIE from './IESessionTypes/Assignments/TeacherAssignmentIE/TeacherAssignmentIE.react';
import StudentAssignmentIE from './IESessionTypes/Assignments/StudentAssignmentIE/StudentAssignmentIE.react';
import TeacherAssignmentTemplateIE from './IESessionTypes/Assignments/TeacherAssignmentTemplateIE/TeacherAssignmentTemplateIE.react';
import AssignmentV2IE from './IESessionTypes/AssignmentV2IE/AssignmentV2IE.react';
import TeacherAssignmentV2IE from './IESessionTypes/TeacherAssignmentV2IE/TeacherAssignmentV2IE.react';
import vanillaIEQuestionsListStore from './IESessionTypes/VanillaIE/VanillaIEQuestionsList/VanillaIEQuestionsList.store';
import teacherAssignmentQuestionsListStore from './IESessionTypes/Assignments/TeacherAssignmentIE/TeacherAssignmentQuestionsList/TeacherAssignmentQuestionsList.store';
import studentAssignmentQuestionsListStore from './IESessionTypes/Assignments/StudentAssignmentIE/StudentAssignmentQuestionsList/StudentAssignmentQuestionsList.store';
import assignmentV2IEQuestionsListStore from './IESessionTypes/AssignmentV2IE/AssignmentV2IEQuestionsList/AssignmentV2IEQuestionsList.store';
import teacherAssignmentV2IEQuestionsListStore from './IESessionTypes/TeacherAssignmentV2IE/TeacherAssignmentV2IEQuestionsList/TeacherAssignmentV2IEQuestionsList.store';
import teacherAssignmentTemplateQuestionsListStore from './IESessionTypes/Assignments/TeacherAssignmentTemplateIE/TeacherAssignmentTemplateQuestionsList/TeacherAssignmentTemplateQuestionsList.store';
import queueResultsStore from 'client/Dennis/Content/Queue/QueueResults/QueueResults.store';
import './interaction-engine.scss';

export function sleepInactiveQuestionsListStores(activeStore) {
  if (activeStore === ieTypes.ASSIGNMENT) {
    /**
     * ieTypes.ASSIGNMENT is a bit of a special case due to the store being dependent on
     * the type of user in addition to the type of the IE session.
     */
    activeStore = sessionStore.isTeacher() ? 'TEACHER_ASSIGNMENT' : 'STUDENT_ASSIGNMENT';
  }
  const stores = {
    TEACHER_ASSIGNMENT: teacherAssignmentQuestionsListStore,
    STUDENT_ASSIGNMENT: studentAssignmentQuestionsListStore,
    [ieTypes.VANILLA_IE]: vanillaIEQuestionsListStore,
    [ieTypes.ASSIGNMENT_V2]: assignmentV2IEQuestionsListStore,
    [ieTypes.TEACHER_ASSIGNMENT_V2]: teacherAssignmentV2IEQuestionsListStore,
    [ieTypes.ASSIGNMENT_TEMPLATE]: teacherAssignmentTemplateQuestionsListStore,
    [ieTypes.QUEUE_RESULTS]: queueResultsStore
  };
  Object.entries(stores).forEach(([storeName, store]) => {
    const shouldSleep = storeName !== activeStore;
    setShouldIgnoreActions(store, shouldSleep);
  });
}

export default class InteractionEngine extends React.Component {
  static propTypes = {
    location: PropTypes.object,
    route: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.setSessionType();
  }

  componentDidMount() {
    const {ieType} = this.props.route;
    if (ieType && ieType === ieTypes.PRACTICE_EXAM_EDITOR) {
      return;
    }
    preventContextMenuAndCopy();
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (interactionEngineStore.getSessionType() !== nextProps.route.ieType) {
      this.setSessionType(nextProps.route.ieType);
    } else if (sessionStore.hasValidSession()) {
      const prevUserRole = sessionStore.withPreviousState(() => sessionStore.isTeacher());
      if (prevUserRole !== sessionStore.isTeacher()) {
        this.setSessionType(nextProps.route.ieType);
      }
    }
  }

  componentWillUnmount() {
    const builtStores = interactionEngineStore.questionStoreNames;
    callAction(interactionEngineActions.RESET_STORE);
    builtStores.forEach((storeName) => destroyStore(storeName));
    allowContextMenuAndCopy();
  }

  setSessionType(ieType) {
    const currentType = ieType || this.props.route.ieType;
    sleepInactiveQuestionsListStores(currentType);
    callAction(interactionEngineActions.SET_SESSION_TYPE, currentType);
  }

  getComponentByType(type) {
    return (
      {
        [ieTypes.VANILLA_IE]: VanillaIE,
        [ieTypes.ASSIGNMENT]: sessionStore.isTeacher() ? TeacherAssignmentIE : StudentAssignmentIE,
        [ieTypes.ASSIGNMENT_V2]: AssignmentV2IE,
        [ieTypes.TEACHER_ASSIGNMENT_V2]: TeacherAssignmentV2IE,
        [ieTypes.ASSIGNMENT_TEMPLATE]: TeacherAssignmentTemplateIE
      }[type] || null
    );
  }

  render() {
    const type = interactionEngineStore.getSessionType();
    if (!type) {
      return null;
    }
    const IEComponent = this.getComponentByType(type);
    return <IEComponent location={this.props.location} />;
  }
}
