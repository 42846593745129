// @flow
import moment from 'moment';
import {fromJS, Iterable} from 'immutable';
import {
  mandarkEndpoint,
  type QueryBuilder
} from '@albert-io/json-api-framework/request/builder/legacy';

export function makeAssignmentQuery({
  assignmentId,
  studentId,
  teacherId
}: {
  assignmentId: string,
  studentId: ?string,
  teacherId: ?string
}): QueryBuilder {
  return mandarkEndpoint(['assignments_v2', assignmentId])
    .include('question_sets_v1.tags_v1')
    .include('question_sets_v1.questions_v1.supplements_v1')
    .include('question_sets_v1.guide_levels_v1', teacherId)
    .include('question_sets_v1.subject_v2', teacherId)
    .meta({student_id: studentId}, studentId)
    .customQuery({
      meta: {
        question_v1: {
          labels: true
        }
      }
    })
    .customQuery(
      {
        meta: {
          teacher_id: teacherId
        }
      },
      teacherId
    );
}

export function makeTeacherAssignmentsAllClassroomsQuery({
  teacherId,
  page,
  pageSize
}: {
  teacherId: string,
  page: number,
  pageSize: number
}): Object {
  return {
    resourcePath: ['assignments_v2'],
    include: 'students_v2',
    filter: {
      teacher_v1: teacherId,
      included: {
        students_v2: {
          teacher_v1: teacherId
        }
      },
      template: false
    },
    customQuery: {
      sort: 'due_date',
      page: {
        page: page,
        page_size: pageSize
      }
    }
  };
}

export const ASSIGNMENTS_DATE_RANGE_OPTION_IDS = {
  DUE_ALL_TIME: 'DUE_ALL_TIME',
  DUE_THIS_MONTH: 'DUE_THIS_MONTH',
  DUE_THIS_WEEK: 'DUE_THIS_WEEK',
  DUE_TODAY: 'DUE_TODAY'
};

export const assignmentsDateRangeOptions = fromJS([
  /* eslint-disable camelcase */
  {
    displayName: 'Due today',
    greater_than: moment()
      .startOf('day')
      .valueOf(),
    id: ASSIGNMENTS_DATE_RANGE_OPTION_IDS.DUE_TODAY,
    less_than: moment()
      .endOf('day')
      .valueOf()
  },
  {
    displayName: 'Due this week',
    greater_than: moment()
      .startOf('week')
      .valueOf(),
    id: ASSIGNMENTS_DATE_RANGE_OPTION_IDS.DUE_THIS_WEEK,
    less_than: moment()
      .endOf('week')
      .valueOf()
  },
  {
    displayName: 'Due this month',
    greater_than: moment()
      .startOf('month')
      .valueOf(),
    id: ASSIGNMENTS_DATE_RANGE_OPTION_IDS.DUE_THIS_MONTH,
    less_than: moment()
      .endOf('month')
      .valueOf()
  },
  {
    displayName: 'Due all time',
    id: ASSIGNMENTS_DATE_RANGE_OPTION_IDS.DUE_ALL_TIME
  }
  /* eslint-enable camelcase */
]);

export function makeTeacherAssignmentsByClassroomIdQuery({
  teacherId,
  classroomId,
  sortBy = '-due_date',
  page,
  pageSize,
  dueDateRangeFilter
}: {
  teacherId: string,
  classroomId: string,
  sortBy: string,
  page: number,
  pageSize: number,
  dueDateRangeFilter: Object
}): Object {
  const queryObject = mandarkEndpoint(['classrooms_v1', classroomId, 'assignments_v2'])
    .filter({
      included: {
        students_v2: {
          classrooms_v1: classroomId
        }
      },
      teacher_v1: teacherId,
      template: false
    })
    .include('students_v2')
    .customQuery({
      page: {
        page: page,
        page_size: pageSize
      },
      sort: sortBy
    })
    .customQuery({
      with_meta: 'assignment_v2'
    })
    .done();

  const dueDateRangeIsValid =
    dueDateRangeFilter &&
    dueDateRangeFilter.get('start_date') &&
    dueDateRangeFilter.get('end_date');

  if (dueDateRangeIsValid) {
    if (Iterable.isIterable(dueDateRangeFilter)) {
      dueDateRangeFilter = dueDateRangeFilter.toJS();
    }

    queryObject.filter.due_date = {
      greater_than: moment(dueDateRangeFilter.start_date)
        .startOf('day')
        .subtract(1, 'second')
        .valueOf(),
      less_than: moment(dueDateRangeFilter.end_date)
        .add(1, 'day')
        .startOf('day')
        .valueOf() // Includes midnight
    };
  }

  return queryObject;
}
