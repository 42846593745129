import {
  Descendant,
  Editor,
  Transforms,
  Element as SlateElement,
  CustomTypes,
  Node,
  Range,
  Path
} from 'slate';
import {ReactEditor} from 'slate-react';
import {getHost} from '@albert-io/environment';

import {IconProp} from '@albert-io/atomic/atoms/Icon/Icon.react';

import {
  maxIndent,
  ImageElement,
  IndentLevel,
  MarkType,
  BlockType,
  LinkElement,
  markTypes,
  blockTypes,
  ListItemElement,
  ParagraphElement,
  AudioChipElement,
  UploadingAudioChipElement
} from '../slate.types';

export const isMark = (type: MarkType | BlockType): type is MarkType => {
  return markTypes.includes(type as MarkType);
};

export const isBlock = (type: MarkType | BlockType): type is BlockType => {
  return blockTypes.includes(type as BlockType);
};

// Media types like links, images will be handled differently
export type NonMediaBlock = Exclude<BlockType, 'image' | 'link' | 'audio-chip'>;

// Empty array does not work, this is how to create a 'blank' slate state
export const blankSlateValue: Descendant[] = [{type: 'paragraph', children: [{text: ''}]}];

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

/**
 * Toggles block based on node type. This is a typed version based on slate js example
 * https://github.com/ianstormtaylor/slate/blob/main/site/examples/richtext.tsx#L67
 *
 * @param {Editor} editor
 * @param {NonMediaBlock} format BlockType excluding image and links
 */
export const toggleBlock = (editor: Editor, format: NonMediaBlock) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n: Node) => {
      return !Editor.isEditor(n) && SlateElement.isElement(n) && LIST_TYPES.includes(n.type);
    },
    split: true
  });

  let type = format;
  if (isActive) {
    type = 'paragraph';
  } else if (isList) {
    type = 'list-item';
  }

  const newProperties: Partial<SlateElement> = {
    type
  };
  Transforms.setNodes<SlateElement>(editor, newProperties);

  if (!isActive && isList) {
    const block = {type: format, children: []} as CustomTypes['Element'];
    Transforms.wrapNodes(editor, block);
  }
};

const getNewMargin = (indent: IndentLevel | 0 = 0, increase: boolean = true) => {
  if (increase && indent + 1 > maxIndent) {
    return maxIndent;
  }
  if (!increase && indent - 1 < 1) {
    return undefined;
  }
  const newMargin = increase ? indent + 1 : indent - 1;
  return newMargin as IndentLevel;
};

export const getMediaUrl = (mediaId) => {
  const domain = getHost();
  const url = `${domain}/files/${mediaId}`;
  return url;
};

export const handleIndent = (editor: Editor, format: 'indent' | 'outdent') => {
  const increase = format === 'indent';
  if (!editor.selection) return;

  const Blocks = Array.from(
    Editor.nodes(editor, {
      mode: 'highest',
      at: Editor.unhangRange(editor, editor.selection),
      match: (n: Node) => {
        return (
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          (n.type === 'paragraph' || n.type === 'list-item')
        );
      }
    })
  );

  Blocks.forEach((block) => {
    const [node, path] = block as [ParagraphElement | ListItemElement, Path];
    const indent = getNewMargin(node.indent, increase);

    const newProperties = {
      indent
    };

    Transforms.setNodes<SlateElement>(editor, newProperties, {at: path});
  });
};

const getNewRotation = (rotation: 0 | 1) => {
  if (rotation === 1) return 0;
  return 1;
};

export const handleRotation = (editor: Editor) => {
  if (!editor.selection) return;
  const Blocks = Array.from(
    Editor.nodes(editor, {
      mode: 'highest',
      at: Editor.unhangRange(editor, editor.selection),
      match: (n: Node) => {
        return !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'image';
      }
    })
  );
  Blocks.forEach((block) => {
    const [node, path] = block as [ImageElement, Path];
    const newRotation = getNewRotation(node.rotation);
    const newProperties = {
      rotation: newRotation
    } as Partial<ImageElement>;

    Transforms.setNodes<SlateElement>(editor, newProperties, {at: path});
  });
};
/**
 * Toggles mark
 *
 * @param {Editor} editor
 * @param {MarkType} format
 */
export const toggleMark = (editor: Editor, format: MarkType) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

/**
 * Determines if a current selection is an active instance of a format.
 *
 * @param {Editor} editor
 * @param {NonMediaBlock} format
 * @returns {boolean}
 */
export const isBlockActive = (editor: Editor, format: NonMediaBlock) => {
  const {selection} = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n: Node) => {
        return !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format;
      }
    })
  );

  return !!match;
};

export const areBlocksActive = (editor: Editor, formats: NonMediaBlock[]) => {
  return formats.some((format) => isBlockActive(editor, format));
};

/**
 * Determines if a current selection is an active instance of a mark
 *
 * @param {Editor} editor
 * @param {MarkType} format
 * @returns {boolean}
 */
export const isMarkActive = (editor: Editor, format: MarkType) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

/**
 * Inserts an ImageElement into the editor
 *
 * @param {Editor} editor
 * @param {string} id
 */
export const insertImage = (editor: Editor, id: string) => {
  const text = {text: ''};
  const image: ImageElement = {type: 'image', rotation: 0, id, children: [text]};
  Transforms.insertNodes(editor, image);
};

export const insertUploadingAudioChip = (editor: Editor) => {
  const uploadingAudioChip: UploadingAudioChipElement = {
    type: 'uploading-audio-chip',
    children: [{text: ''}]
  };

  Transforms.insertNodes(editor, uploadingAudioChip);
};

export const insertAudioChip = (editor: Editor, id: string, userName: string, caption?: string) => {
  const audioChip: AudioChipElement = {
    type: 'audio-chip',
    id,
    userName,
    caption,
    children: [{text: ''}]
  };
  Transforms.insertNodes(editor, audioChip);
};

export const insertLink = (editor, url, text?) => {
  if (editor.selection) {
    wrapLink(editor, url, text);
  }
};

export const isLinkActive = (editor) => {
  const [link] = Array.from(
    Editor.nodes(editor, {
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link'
    })
  );
  return !!link;
};

export const unwrapLink = (editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link'
  });
};

export const deleteLink = (editor) => {
  Transforms.removeNodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === 'link'
  });
};

const wrapLink = (editor: Editor, url: string, text: string = 'New link') => {
  const {selection} = editor;
  if (isLinkActive(editor)) {
    const isCollapsed = selection && Range.isCollapsed(selection);
    if (isCollapsed) {
      deleteLink(editor);
    } else {
      unwrapLink(editor);
      Transforms.insertText(editor, ''); // clear selection of text, this is for cases where link is part of mixed text types
    }
  }

  ReactEditor.focus(editor);

  // Check if a range is collapsed, meaning that both its anchor and focus points refer to the exact same position
  const isCollapsed = selection && Range.isCollapsed(selection);

  const link: LinkElement = {
    type: 'link',
    url,
    children: [{text}]
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else if (selection) {
    const selectedText = Editor.string(editor, selection);
    if (selectedText !== text) {
      Transforms.wrapNodes(editor, link, {split: true});
      Transforms.insertText(editor, text);
      Transforms.collapse(editor, {edge: 'end'});
    } else {
      Transforms.wrapNodes(editor, link, {split: true});

      Transforms.collapse(editor, {edge: 'end'});
    }
  }
};

/* --------------- Converting to payload --------------- */

/**
 * Filter for text in Node
 *
 * @param {Descendant} child Node from state array
 * @returns {boolean} whether child is a text type
 */
const isText = (child: Descendant) => {
  return 'text' in child && !!child.text;
};

/**
 * Filters for type === image
 *
 * @param {Descendant} child Node from state array
 * @returns {boolean} whether or not child is an image type
 */
const isImage = (child: Descendant) => {
  return 'type' in child && child.type === 'image';
};

const isAudio = (child: Descendant) => {
  return 'type' in child && child.type === 'audio-chip';
};

type StateFilter = (child: Descendant) => boolean;

/**
 * Flattens slate js editor state children into one array, with filter of choice
 * Note: This assumes Node you are filtering for will not have a child of the same type
 *
 * @param {Descendant[]} state slate js editor state
 * @param {StateFilter} filter function for filtering decendents
 * @returns {Descendant[]}
 */
export const flattenState = (state: Descendant[], filter: StateFilter = isText) => {
  const flat: Descendant[] = [];
  state.forEach((child) => {
    if (filter(child)) {
      flat.push(child);
    } else if ('children' in child) {
      flat.push(...flattenState(child.children, filter));
    }
  }, []);

  return flat;
};

/**
 * Returns the raw text from slate js editor state
 *
 * @param {Descendant[]} state
 * @returns {string} text of slate js editor state
 */
export const convertStateToString = (state: Descendant[]) => {
  const textArray = flattenState(state) as CustomTypes['Text'][];
  return textArray.map((child) => child.text).join(' ');
};

/**
 * Filters images from  slate js editor state, returns array of urls
 *
 *
 * @param {Descendant[]} state slate js editor state
 * @returns {string[]} array of audio urls
 */
export const convertStateToAudioArr = (state: Descendant[]) => {
  const audioArray = flattenState(state, isAudio) as AudioChipElement[];
  return audioArray.map((child) => getMediaUrl(child.id));
};

/**
 * Filters images from  slate js editor state, returns array of urls
 *
 *
 * @param {Descendant[]} state slate js editor state
 * @returns {string[]} array of image urls
 */
export const convertStateToImgArr = (state: Descendant[]) => {
  const imageArray = flattenState(state, isImage) as ImageElement[];
  return imageArray.map((child) => getMediaUrl(child.id));
};

/**
 * Converts slate js editor state into payload for 'content' attribute on guesses
 *
 * @param {Descendant[]} state slate js editor state
 * @returns {object} formatted payload
 */
export const convertStateToPayload = (state: Descendant[]) => {
  const text = convertStateToString(state);
  const payload = {
    raw: JSON.stringify(state),
    text,
    references: []
  };
  return payload;
};

// Work around

// This is the format for working around not having a selection
// This happens if a user clicks a toolbar button before previously visiting the text input
// workaround is to use a set timeout while focusing on the editor

export const withWorkaround = (editor: Editor, cb: () => void, onTimeout?: () => void) => {
  if (editor.selection) {
    cb();
    ReactEditor.focus(editor);
  } else {
    ReactEditor.focus(editor);
    setTimeout(() => {
      if (onTimeout) {
        onTimeout();
      } else {
        cb();
      }
    }, 100);
  }
};

export interface ButtonProps<T> {
  type: T;
  icon: IconProp;
  'aria-selected'?: boolean;
  tabIndex?: number;
}

// Defining types for button types that we want to render differently than a standard toggle button
export const fontTypes = ['heading', 'heading-one', 'heading-two'] as const;
export type FontType = (typeof fontTypes)[number];

export const isFont = (type: BlockType): type is FontType => {
  return fontTypes.includes(type as FontType);
};

export const getBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64 = getBase64StringFromDataUrl(reader.result as string);
      resolve(base64);
    };
    reader.onerror = (error) => reject(error);
  });
};

export const getBase64StringFromDataUrl = (dataURL: string) =>
  dataURL.replace('data:', '').replace(/^.+,/, '');
