import {StateCreator} from 'zustand';
import {AssignmentSettingsFormSchemaType} from 'client/components/ContentDiscovery/AssignmentSettings/AssignmentSettingsForm/AssignmentSettingsForm.schema';
import type {ContentDiscoveryStoreState} from 'client/components/ContentDiscovery/store/ContentDiscoveryStore.types';

type SettingsFormState = {
  formData: AssignmentSettingsFormSchemaType | null;
};

type SettingsFormActions = {
  setFormData: (formData: AssignmentSettingsFormSchemaType) => void;
  reset: () => void;
};

const initialState: SettingsFormState = {
  formData: null
};

export type SettingsFormSlice = SettingsFormState & SettingsFormActions;

export const createSettingsFormSlice: StateCreator<
  ContentDiscoveryStoreState,
  [['zustand/immer', never]],
  [],
  SettingsFormSlice
> = (set, _): SettingsFormSlice => ({
  ...initialState,
  setFormData: async (formData) => {
    set((state) => {
      state.settingsForm.formData = formData as AssignmentSettingsFormSchemaType;
    });
  },
  reset: () => {
    set((state) => {
      state.settingsForm = {...state.settingsForm, ...initialState};
    });
  }
});
