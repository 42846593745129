import React from 'react';
import PropTypes from 'prop-types';
import {Banner} from '@albert-io/atomic';

const ResultIndicatorBar = ({isCorrect}) => (
  <Banner type={isCorrect ? 'positive' : 'negative'} className='u-mar-b_2'>
    {isCorrect ? <Banner.Icon icon='check' /> : <Banner.Icon icon='times' />}
    <span>{isCorrect ? 'Correct' : 'Incorrect'}</span>
  </Banner>
);

ResultIndicatorBar.propTypes = {
  isCorrect: PropTypes.bool
};

export default ResultIndicatorBar;
