import React from 'react';
import PropTypes from 'prop-types';
import {callAction} from 'client/framework';
import Subheader from 'generic/Subheader/Subheader.react';
import interactionEngineActions from 'client/InteractionEngineV2/InteractionEngineActions';
import interactionEngineStore from 'client/InteractionEngineV2/InteractionEngineStore';
import topicSummaryStore from 'client/InteractionEngineV2/IESessionTypes/VanillaIE/TopicSummary/TopicSummary.store';
import {SUBHEADER_CLASS} from 'client/InteractionEngineV2/interactionEngineConstants';

export default class IEMobileNav extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired
  };

  handleClick = () => {
    if (!interactionEngineStore.isMobileQuestionAreaInView) {
      return;
    }
    callAction(interactionEngineActions.HIDE_MOBILE_QUESTION_AREA);
    if (topicSummaryStore.isTopicSummaryPath()) {
      topicSummaryStore.pushStateToQuestionPath();
    }
  };

  render() {
    const content = interactionEngineStore.isMobileQuestionAreaInView
      ? [<span key='back-button' className='fa fa-chevron-left' />, 'Questions List']
      : this.props.title;
    const leftContent = (
      <div className='ie-mobile-nav__back' onClick={this.handleClick}>
        {content}
      </div>
    );
    return (
      <Subheader
        className={SUBHEADER_CLASS}
        leftContent={leftContent}
        rightContent={this.props.assignmentActions}
      />
    );
  }
}
