import {createActions} from 'client/framework';

export default createActions(null, [
  'RESET_STORE',
  'SET_SESSION_START_TIME',
  'FILTER_BY_UNANSWERED_QUESTIONS',
  'START_ASSIGNMENT',
  'SELECT_FIRST_QUESTION_WITHOUT_GUESS',
  'SELECT_FIRST_QUESTION'
]);
