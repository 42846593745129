import React from 'react';
import PropTypes from 'prop-types';
import {callAction} from 'client/framework';
import Button from 'sg/Button/Button.react';
import {addGenericErrorToast} from '@albert-io/atomic';
import assignmentV2IEQuestionsListActions from '../../AssignmentV2IEQuestionsList/AssignmentV2IEQuestionsList.actions';
import assignmentV2IEQuestionsListStore from '../../AssignmentV2IEQuestionsList/AssignmentV2IEQuestionsList.store';
import assignmentV2IEActions from '../../AssignmentV2IE.actions';
import assignmentV2IEStore from '../../AssignmentV2IE.store';
import {pluralize} from 'lib/stringUtils';
import './submit-section-modal-body.scss';

export default class SubmitSectionModalBody extends React.Component {
  static propTypes = {
    handleLeave: PropTypes.func,
    handleStay: PropTypes.func,
    isLeavingRoute: PropTypes.bool
  };

  static defaultProps = {
    handleLeave: () => {},
    handleStay: () => {}
  };

  constructor() {
    super();
    this.state = {
      unansweredQuestions: 0
    };
  }

  componentDidMount() {
    assignmentV2IEStore
      .getStudentAssignmentQuery()
      .getResourcePromise()
      .then(() => {
        const section = assignmentV2IEStore.getLatestStartedSection();
        return assignmentV2IEStore
          .getSectionWithQuestionSetsQuery(section.getId())
          .getResourcePromise();
      })
      .then((section) => {
        const questionSets = assignmentV2IEStore.getAllQuestionsInSection(
          section.getQuestionSets()
        );
        const unansweredQuestions = assignmentV2IEStore.getSectionUnansweredQuestionCount(
          questionSets
        );
        this.setState({unansweredQuestions});
      });
  }

  dismissSubmitSectionModal = () => {
    if (assignmentV2IEStore.isRequestPending()) {
      return;
    }
    callAction(assignmentV2IEActions.SET_SHOW_SUBMIT_SECTION_MODAL, false);
  };

  showUnansweredQuestions = () => {
    this.props.handleStay();
    if (!assignmentV2IEQuestionsListStore.shouldHideAnsweredQuestions()) {
      callAction(assignmentV2IEQuestionsListActions.TOGGLE_HIDE_ANSWERED_QUESTIONS);
    }
    this.dismissSubmitSectionModal();
  };

  submitAndContinue = async () => {
    this.props.handleStay();
    callAction(assignmentV2IEActions.SUBMIT_CURRENT_SECTION);
    await assignmentV2IEStore.getAssignmentPromise().catch(() => {
      addGenericErrorToast();
    });
    this.dismissSubmitSectionModal();
  };

  render() {
    const unansweredCountCopy = `${this.state.unansweredQuestions} ${pluralize(
      'question',
      this.state.unansweredQuestions
    )}`;
    return (
      <div className='submit-section-modal__body'>
        <span className='fa fa-exclamation-triangle submit-section-modal__warn' />
        <p>
          You left <strong>{unansweredCountCopy} unanswered</strong>. <br />
          What would you like to do?
        </p>
        <div className='submit-section-modal__buttons-wrapper'>
          <Button
            color='green'
            className='submit-section-modal__button'
            text='Show unanswered questions'
            onClick={this.showUnansweredQuestions}
          />
          <Button
            className='submit-section-modal__button'
            text='Submit &amp; Continue to next section'
            onClick={this.submitAndContinue}
          />
          {this.props.isLeavingRoute ? (
            <Button
              linkButton
              className='submit-section-modal__button submit-section-modal__button--leave'
              text='Leave anyway'
              onClick={this.props.handleLeave}
            />
          ) : null}
        </div>
      </div>
    );
  }
}
