import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {startCase} from 'lodash';

import './free-response-question-sub-type-badge.scss';

export const FreeResponseQuestionSubTypeBadge = ({question}) => {
  const questionSubType = question.getQuestionSubType();
  if (!questionSubType) {
    return null;
  }

  return (
    <div
      className={`free-response-question-sub-type-badge free-response-question-sub-type-badge--${questionSubType}`}
    >
      {startCase(questionSubType.replace('_question', ''))}
    </div>
  );
};

FreeResponseQuestionSubTypeBadge.propTypes = {
  question: ImmutablePropTypes.record
};
