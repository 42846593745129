import React, {useContext, useEffect, useState} from 'react';
import {Button, Icon, Dropdown, DropdownItem, LoadingSpinner} from '@albert-io/atomic';
import {resource} from '@albert-io/json-api-framework/request/builder';
import type {SubjectModel} from 'resources/Subject/Subject.model';
import {history} from 'client/history';
import {ReportsContext} from 'client/Reports/Reports.context';

import {List} from 'immutable';
import {AnalyticsApiResult} from 'client/Reports/reports.types';

import './standards-actions.scss';

interface CellProps {
  result: AnalyticsApiResult;
}

const StandardsActions = ({result}: CellProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [subjects, setSubjects] = useState<List<SubjectModel>>(List([]));
  const {
    subjectId: subjectIdFromDrilldown,
    subjectIdFromSearchParam,
    topLevelFilterId
  } = useContext(ReportsContext);

  const subjectId = subjectIdFromDrilldown || subjectIdFromSearchParam;

  const standardId = result.standard.id;

  useEffect(() => {
    const getSubjects = async () => {
      if (isOpen) {
        const query = resource('subjects_v2')
          .mandarkEndpoint(['subjects_v2'])
          .meta({
            context: {
              standard: {
                id: standardId
              }
            }
          })
          .sort('name')
          .filter({
            'meta.belongs_to_standard': true
          });

        // TODO handle teachers?
        const inClassroomQuery = query.filter({
          classrooms_v1: {
            id: topLevelFilterId
          }
        });

        const [inClassroomResp, allResp] = await Promise.all([
          inClassroomQuery.getResourcePromise(),
          query.getResourcePromise()
        ]);

        const inClassroomIds = inClassroomResp.map((item) => item.id);
        let resp = inClassroomResp.concat(
          allResp.filter((item) => !inClassroomIds.includes(item.id))
        );

        if (subjectId) {
          resp = resp.filter((item) => item.id === subjectId);
        }

        setSubjects(resp);
      }
    };
    getSubjects();
  }, [isOpen, result, topLevelFilterId, standardId, subjectId]);

  return (
    <Dropdown
      className='u-display_flex u-align-items_center'
      trayClassName='standards-actions__dropdown-tray'
      positionFixed
      onDismiss={() => setIsOpen(false)}
      withMaxHeight
      trigger={
        <Button variant='text' onClick={() => setIsOpen(true)}>
          Find Questions
          <Icon icon='caret-down' className='u-mar-l_1' />
        </Button>
      }
    >
      {subjects.size ? (
        subjects.map((subjectItem) => (
          <DropdownItem
            as='button'
            onClick={() => history.push(`/${subjectItem.getUrlSlug()}?standards=${standardId}`)}
            icon='search'
          >
            {subjectItem.getName()}
          </DropdownItem>
        ))
      ) : (
        <LoadingSpinner assistiveText='Loading subjects' className='u-mar-t_2' />
      )}
    </Dropdown>
  );
};

export default StandardsActions;
