import React from 'react';
import {history} from 'client/history';
import {CLASSROOM_STATUSES} from 'client/constants';

import {Anchor, Button, Icon, LoadingSpinner, MenuListItem, Text} from '@albert-io/atomic';
import sessionStore from 'client/Session/SessionStore';
import {ClassroomModelV1} from 'resources/augmented/Classroom/ClassroomModel.v1';
import {SchoolModelV5} from 'resources/augmented/School/SchoolModel.v5';
import {List} from 'immutable';

import './reports-selector.scss';
import {TopLevelFilter} from '../reports.types';

interface ReportSelectorOptionsProps {
  showArchived: boolean;
  schoolsOrClassrooms: TopLevelFilter;
  isLoading: boolean;
  schools: List<SchoolModelV5>;
  classrooms: List<ClassroomModelV1>;
}

const ReportSelectorOptions = ({
  showArchived,
  schoolsOrClassrooms,
  isLoading,
  schools,
  classrooms
}: ReportSelectorOptionsProps) => {
  const userId = sessionStore.getUserId();

  if (isLoading) {
    return <LoadingSpinner size={2} className='u-mar-t_2' />;
  }

  if (schoolsOrClassrooms === 'schools') {
    if (!schools || !schools.size) {
      return (
        <div className='reports-selector__no-options-found'>
          <Text size='l' bold>
            No schools found
          </Text>
          <Text size='l'>
            Please reach out to{' '}
            <Anchor href='mailto:support@albert.io' underlined>
              support@albert.io
            </Anchor>{' '}
            for assistance
          </Text>
        </div>
      );
    }
    return (
      <>
        {[
          <ReportSelectorMenuListItem
            key='all-schools' // Add unique key
            id='me'
            label={`All Schools (${schools.toArray().length})`}
            type='school-admin'
          />,
          ...schools
            .toArray()
            .map((school) => (
              <ReportSelectorMenuListItem
                key={school.id}
                id={school.id}
                label={school.name}
                type='schools'
                isArchived={school.is_archived}
                indented
              />
            ))
        ]}
      </>
    );
  }
  if (schoolsOrClassrooms === 'classrooms') {
    const activeClassrooms = classrooms
      .toArray()
      .filter((classroom) => {
        return classroom.status !== CLASSROOM_STATUSES.ARCHIVED;
      })
      .map((classroom) => {
        return (
          <ReportSelectorMenuListItem
            key={classroom.id}
            id={classroom.id}
            label={classroom.name}
            type='classrooms'
            isArchived={false}
            indented
          />
        );
      });
    const archivedClassrooms = classrooms
      .toArray()
      .filter((classroom) => {
        return classroom.status === CLASSROOM_STATUSES.ARCHIVED;
      })
      .map((classroom) => {
        return (
          <ReportSelectorMenuListItem
            key={classroom.id}
            id={classroom.id}
            label={classroom.name}
            type='classrooms'
            isArchived
            indented
          />
        );
      });

    if (
      !classrooms ||
      (!showArchived && !activeClassrooms.length) ||
      (showArchived && !archivedClassrooms.length && !activeClassrooms.length)
    ) {
      return (
        <div className='reports-selector__no-options-found'>
          <Text size='l' bold>
            No classes found
          </Text>
          <Text size='l'>
            <Anchor href='/classes?createClassroomModal=true' underlined>
              Create a class
            </Anchor>{' '}
            to view reporting data
          </Text>
        </div>
      );
    }

    return (
      <>
        {[
          ...(activeClassrooms.length
            ? [
                <ReportSelectorMenuListItem
                  key={userId}
                  id={userId}
                  label='All Classes'
                  type='teachers'
                />
              ]
            : []),
          ...activeClassrooms,
          ...(showArchived ? archivedClassrooms : [])
        ]}
      </>
    );
  }
  return <></>;
};

interface ReportSelectorOption {
  id: string;
  label: string;
  type: TopLevelFilter;
  isArchived?: boolean;
  indented?: boolean;
}

const ReportSelectorMenuListItem = ({
  id,
  label,
  type,
  isArchived,
  indented
}: ReportSelectorOption) => {
  const onClickViewReport = () => {
    if (type === 'teachers' || type === 'classrooms') {
      history.pushState(null, `/reports/${type}/${id}/assignments`);
    } else if (type === 'schools' || type === 'school-admin') {
      history.pushState(null, `/reports/${type}/${id}/students`);
    } else {
      history.pushState(null, `/reports/${type}/${id}`);
    }
  };
  return (
    <MenuListItem
      className='reports-selector__list-item u-pad-lr-1'
      onMenuItemClick={onClickViewReport}
      data-testid='report-selector-menu-list-item'
    >
      <Text className={`u-mar-l_${indented ? 2 : 0} u-align-self_center`}>{label}</Text>
      <div className='reports-selector__list-item-button-wrapper'>
        {isArchived && (
          <Icon
            className='u-mar-r_1'
            icon='archive'
            iconStyle='regular'
            emphasis='medium'
            data-testid='archive-icon'
          />
        )}
        <Button color='primary' variant='text' size='s' onClick={onClickViewReport}>
          View Report <Icon icon='arrow-right' className='u-mar-l_1' />
        </Button>
      </div>
    </MenuListItem>
  );
};

export default ReportSelectorOptions;
