import React from 'react';

import {
  QuestionComponent,
  QuestionComponentProps
} from '../shared/QuestionComponent/QuestionComponent.react';

import {SnippetSelectionInputArea} from './SnippetSelectionInputArea.react';

const SnippetSelectionQuestionComponent = (props: QuestionComponentProps) => {
  return <QuestionComponent {...props} ActiveQuestionInputArea={SnippetSelectionInputArea} />;
};

type SnippetSelectionQuestionTypeProps = Omit<QuestionComponentProps, 'questionState'>;

const SnippetSelectionQuestion = {
  PreGuess: (props: SnippetSelectionQuestionTypeProps) => (
    <SnippetSelectionQuestionComponent {...props} questionState='PreGuess' />
  ),
  PostGuess: (props: SnippetSelectionQuestionTypeProps) => (
    <SnippetSelectionQuestionComponent {...props} questionState='PostGuess' />
  )
};

export {SnippetSelectionQuestion};
