import React, {useState} from 'react';

import {useSlate} from 'slate-react';

import {IconButton} from '@albert-io/atomic';

import {isLinkActive} from '../../Utils/editor.utils';

import {LinkModal} from '../../Elements/LinkElement.react';

interface LinkButtonProps {
  tabIndex?: number;
}

export const LinkButton = ({tabIndex}: LinkButtonProps) => {
  const editor = useSlate();
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <IconButton
        icon='link'
        label='add link'
        variant={isLinkActive(editor) ? 'faded' : 'outlined'}
        tabIndex={tabIndex}
        color={isLinkActive(editor) ? 'primary' : 'secondary'}
        onClick={() => {
          setShowModal(true);
        }}
      />
      {showModal && <LinkModal onClose={() => setShowModal(false)} />}
    </>
  );
};
