/* eslint-disable no-underscore-dangle */

import {fromJS, List, Map, OrderedSet} from 'immutable';
import {
  addQuestionSetsToAssignment,
  removeQuestionSetsFromAssignment
} from 'framework/MandarkAPI/Assignments/Assignments';
import templateListStore from 'client/Assignments/Templates/TemplateList.store';
import interactionEngineActions from 'client/InteractionEngineV2/InteractionEngineActions';
import AssignmentQuestionsListStore from '../../AssignmentQuestionsList/AssignmentQuestionsList.store';
import teacherAssignmentQuestionsListActions from './TeacherAssignmentQuestionsList.actions';
import {invalidatePartialInterest} from 'resources/mandark.resource';
import {mandarkEndpoint} from '@albert-io/json-api-framework/request/builder/legacy';

/**
 * This store copies the questions from the assignmentQuestionsListStore
 * to allow teachers to sort and remove questions from the assignment
 *
 * @class TeacherAssignmentQuestionsListStore
 * @extends {AssignmentQuestionsListStore}
 */
class TeacherAssignmentQuestionsListStore extends AssignmentQuestionsListStore {
  constructor(name) {
    super(name);
    this.initialData = this.initialData.merge(
      fromJS({
        errorMessage: '',
        successMessage: '',
        copiedQuestionSets: [],
        questionSetsPendingRemoval: [], // Converted to Immutable.OrderedSet
        isSavePending: false
      })
    );
    this.setInitialData(this.initialData);
    this.handle(
      teacherAssignmentQuestionsListActions.SET_INITIAL_QUESTION_SETS,
      this._setInitialQuestionSets
    );
    this.handle(teacherAssignmentQuestionsListActions.SORT_QUESTION_SETS, this._sortQuestionSets);
    this.handle(
      teacherAssignmentQuestionsListActions.TOGGLE_QUESTION_SET_REMOVAL,
      this._toggleQuestionSetRemoval
    );
    this.handle(
      teacherAssignmentQuestionsListActions.SAVE_TEMPLATE_SET_LIST,
      this._saveTemplateSetList
    );
    this.handle(teacherAssignmentQuestionsListActions.CLEAR_ERROR_MESSAGE, this._clearErrorMessage);
    this.handle(
      teacherAssignmentQuestionsListActions.CLEAR_SUCCESS_MESSAGE,
      this._clearSuccessMessage
    );
    this.handle(interactionEngineActions.RESET_STORE, this._resetStore);
  }

  async _saveTemplateSetList() {
    const assignmentId = this.assignmentId;
    const currentQuestionSets = this.copiedQuestionSets;
    const questionSetsPendingRemoval = this.questionSetsPendingRemoval.map((set) => set.getId());
    const newQuestionSetList = currentQuestionSets.reduce((result, set) => {
      if (questionSetsPendingRemoval.has(set.getId())) {
        return result;
      } else {
        return result.push(set);
      }
    }, new List());
    const newQuestionSetListIds = newQuestionSetList.map((set) => set.getId());

    this.writeData('isSavePending', true);

    try {
      if (questionSetsPendingRemoval.size > 0) {
        await removeQuestionSetsFromAssignment({
          assignmentId: assignmentId,
          setIds: questionSetsPendingRemoval.toList()
        }).getPromise();
        this.getAssignmentQuery().invalidateInterest();
      }
      // This might seem weird, but Mandark will perform an upsert updating
      // the position if the same question set is POSTed again
      const assignment = await this.getAssignmentQuery().getResourcePromise();
      const questionSetCount = assignment.getQuestionSets().size;

      await addQuestionSetsToAssignment({
        assignmentId: assignmentId,
        setIds: newQuestionSetListIds,
        questionSetCount: questionSetCount
      }).getPromise();

      this.getAssignmentQuery().invalidateInterest();
      invalidatePartialInterest(mandarkEndpoint(['assignments_v2']).done());
      templateListStore.getAssignmentTemplatesQuery().invalidateInterest();

      this.writeData((store) => {
        return store
          .set('questionSetsPendingRemoval', new OrderedSet())
          .set('copiedQuestionSets', newQuestionSetList);
      });
      this.writeData('successMessage', 'You have successfully updated your folder.');
    } catch (err) {
      this.writeData('errorMessage', err);
    } finally {
      this.writeData('isSavePending', false);
    }
  }

  _clearSuccessMessage() {
    this.writeData('successMessage', '');
  }

  _clearErrorMessage() {
    this.writeData('errorMessage', '');
  }

  _resetStore() {
    this.writeData(this.initialData);
  }

  _setInitialQuestionSets() {
    this.writeData('copiedQuestionSets', this.questionSets);
  }

  _setQuestionSets(questionSets) {
    this.writeData('copiedQuestionSets', questionSets);
  }

  _setErrorMessage(errorMessage) {
    this.writeData('errorMessage', errorMessage);
  }

  _sortQuestionSets({oldIndex, newIndex}) {
    if (oldIndex === newIndex) {
      return;
    }
    const questionSetToMove = this.copiedQuestionSets.get(oldIndex);
    const updatedQuestionSet = this.copiedQuestionSets
      .delete(oldIndex)
      .insert(newIndex, questionSetToMove);
    this._setQuestionSets(updatedQuestionSet);
  }

  _toggleQuestionSetRemoval(questionSetToToggle) {
    const questionSetsPendingRemoval = this.questionSetsPendingRemoval;
    const updatedSets = questionSetsPendingRemoval.includes(questionSetToToggle)
      ? questionSetsPendingRemoval.delete(questionSetToToggle)
      : questionSetsPendingRemoval.add(questionSetToToggle);
    this.writeData('questionSetsPendingRemoval', updatedSets);
  }

  isTemplateEmpty() {
    return this.questionSetsPendingRemoval.size === this.copiedQuestionSets.size;
  }

  getFirstCopiedSetAndQuestion() {
    return new Map({
      questionSet: this.copiedQuestionSets.first(),
      question: this.copiedQuestionSets
        .first()
        .getQuestions()
        .first()
    });
  }

  isSavePending() {
    return this.readData('isSavePending');
  }

  get copiedQuestionSets() {
    return this.readData('copiedQuestionSets', new List());
  }

  get successMessage() {
    return this.readData('successMessage');
  }

  get errorMessage() {
    return this.readData('errorMessage');
  }

  get questionSetIds() {
    return this.copiedQuestionSets.map((set) => set.getId());
  }

  get questionSetsPendingRemoval() {
    return this.readData('questionSetsPendingRemoval', new List()).toOrderedSet();
  }

  // Unsure what a better name for this be
  get shouldSetBeDeleted() {
    const questionSetsPendingRemoval = this.questionSetsPendingRemoval;
    const questionSets = this.copiedQuestionSets;

    return questionSets.size === questionSetsPendingRemoval.size;
  }

  get deleteAssignmentRequest() {
    return this.readData('deleteAssignmentRequest');
  }
}

export default new TeacherAssignmentQuestionsListStore('TeacherAssignmentQuestionsListStore');
