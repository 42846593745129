import {resource} from '@albert-io/json-api-framework/request/builder';

const getSmartSelectQuery = (numberOfQuestions, id, type) => {
  let query;
  switch (type) {
    case 'folder':
      query = resource('templates_v1')
        .mandarkEndpoint(['templates_v1', id])
        .include('question_sets_v1')
        .include('question_sets_v1.questions_v3')
        .include('question_sets_v1.subject_v2')
        .meta({
          context: {
            smart_select: {
              total_number_of_questions: numberOfQuestions
            }
          }
        })
        .customQuery({
          with_meta: 'template_v1'
        });
      break;
    default:
      // default is 'guide'
      query = resource('guide_levels_v2')
        .mandarkEndpoint(['guide_levels_v2', id])
        .include('subject_v2')
        .include('question_sets_v1')
        .include('question_sets_v1.questions_v3')
        .include('question_sets_v1.subject_v2')
        .include('question_sets_v1.guide_levels_v2')
        .meta({
          context: {
            smart_select: {
              total_number_of_questions: numberOfQuestions
            }
          }
        })
        .customQuery({
          with_meta: 'guide_level_v2'
        });
  }
  return query;
};

export const getQuestionSetsQuery = (id, type) => {
  let query;

  switch (type) {
    case 'folder':
      query = resource('templates_v1')
        .mandarkEndpoint(['templates_v1', id])
        .include('question_sets_v1');
      break;
    default:
      // default is 'guide'
      query = resource('guide_levels_v2')
        .mandarkEndpoint(['guide_levels_v2', id])
        .include('question_sets_v1');
  }
  return query;
};

export default getSmartSelectQuery;
