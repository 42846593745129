import ImmutablePropTypes from 'react-immutable-proptypes';
import React from 'react';
import PropTypes from 'prop-types';
import {setUpStore} from 'client/framework';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import FreeEntryQuestionStore from './FreeEntryQuestion.store';
import FreeEntryQuestionInput from './FreeEntryQuestionInput.react';
import './free-entry-question.scss';

export default class FreeEntryQuestion extends React.Component {
  static propTypes = {
    isGuessSubmitted: PropTypes.bool,
    storeName: PropTypes.string,
    question: ImmutablePropTypes.record,
    questionId: PropTypes.string
  };

  static defaultProps = {
    storeName: 'freeEntryQuestionStore'
  };

  getStore() {
    return setUpStore(FreeEntryQuestionStore, this.props.storeName, this.props.questionId);
  }

  isInputCorrect({validResponse, type, threshold, inputId, value = ''}) {
    if (validResponse.isEmpty()) {
      return false;
    }

    const correctOptions = validResponse.get(inputId);

    if (type === 'contains') {
      return correctOptions
        .map((option) => option.toLowerCase())
        .includes(value.toLowerCase().trim());
    }

    if (threshold) {
      const parsedVal = parseFloat(value, 10);
      const parsedThreshold = parseFloat(threshold, 10);

      return correctOptions.some((option) => {
        const parsedOption = parseFloat(option, 10);
        if (parsedOption === parsedVal) {
          return true;
        }
        const leeway = (parsedOption / 100) * parsedThreshold;
        return Math.abs(parsedOption - parsedVal) < Math.abs(leeway);
      });
    }

    return correctOptions.includes(value.trim());
  }

  makeInputs = (input) => {
    const {question} = this.props;
    const inputId = input.getId();
    const value = this.getStore().getValueForId(input.getId());
    const validResponse = question.getValidResponse();
    const type = question.getRubric()?.get('type') || null;
    const threshold = this.props.question.getIn(['meta', 'rubric', 'threshold'], false);
    const isMarkedCorrect = this.isInputCorrect({validResponse, type, threshold, inputId, value});
    return (
      <FreeEntryQuestionInput
        key={inputId}
        inputId={inputId}
        inputPrompt={input.getText()}
        isGuessSubmitted={this.props.isGuessSubmitted}
        isMarkedCorrect={isMarkedCorrect}
        question={this.props.question}
        storeName={this.props.storeName}
        value={value}
      />
    );
  };

  render() {
    const {question} = this.props;
    return (
      <div className='free-entry-question free-entry-question--legacy'>
        <MarkdownRendererV2
          text={question.getPrompt()}
          className='free-entry-question__question-content'
        />
        <div className='free-entry-question__inputs-wrapper'>
          {question.getInputs().map(this.makeInputs)}
        </div>
      </div>
    );
  }
}
