import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import SimpleMarkdown from 'simple-markdown';
import {v4 as uuid} from 'uuid';

/**
 * @example
 * [ol-type=DAI]
 * 1. Foo
 * 2. Bar
 *   1. Baz
 *   2. Qux
 *     1. Quux
 *     2. Quuz
 *       1. Corge
 * [/center]
 *
 * ☝️ this will render:
 * 1. Foo
 * 2. Bar
 *   A. Baz
 *   B. Qux
 *     I. Quux
 *     II. Quuz
 *       I. Corge
 */

const charValues = {
  A: 'upper-alpha',
  a: 'lower-alpha',
  I: 'upper-roman',
  i: 'lower-roman',
  D: 'decimal',
  d: 'decimal-leading-zero'
};

const OlTypeBlock = ({types, children}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const className = useMemo(() => `ol-type-block-${uuid()}`, [types]);
  const styles = useMemo(() => {
    return types.split('').reduce((acc, char, i) => {
      const selector = `.${className} ${Array(i + 1)
        .fill('ol ')
        .join('')}`;
      return `${acc} ${selector}{ list-style-type: ${charValues[char]} }`;
    }, '');
  }, [types, className]);
  return (
    <div className={className}>
      <style>{styles}</style>
      {children}
    </div>
  );
};

OlTypeBlock.propTypes = {
  types: PropTypes.string,
  children: PropTypes.node
};

const regex = /^\[ol-type=([AaIiDd]+)\]((?:[\w\W](?!\[\/ol-type]))+[\w\W])\[\/ol-type]/;

const olTypeRule = {
  order: SimpleMarkdown.defaultRules.paragraph.order - 0.5,
  match: (source) => regex.exec(source),
  parse: (capture, recurseParse, state) => {
    const [, listTypes, content] = capture;
    return {
      content: recurseParse(`${content}\n`, state),
      listTypes
    };
  },
  react({content, listTypes}, output, {key}) {
    return (
      <OlTypeBlock types={listTypes} key={key}>
        {output(content)}
      </OlTypeBlock>
    );
  }
};

olTypeRule.react.propTypes = {
  content: PropTypes.node,
  listTypes: PropTypes.string
};

export default olTypeRule;
