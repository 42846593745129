import React from 'react';
import PropTypes from 'prop-types';
import sessionStore from 'client/Session/SessionStore';
import classnames from 'classnames';
import moment from 'moment';
import appStore from 'client/AppStore';
import CustomerFeedbackDialogue from 'client/CustomerFeedback/CustomerFeedbackDialogue.react';
import awaitMandarkQueries from 'lib/hocs/awaitMandarkQueries';
import {UserModelV2} from 'resources/augmented/User/UserModel.v2';
import {UpgradeButton} from 'generic/UpgradeSubjectButton/UpgradeSubjectButton.react';
import {resource} from '@albert-io/json-api-framework/request/builder';
import {Modal, NavItem} from '@albert-io/atomic';
import {useTracking} from 'react-tracking';

import PointsIndicator from '../PointsIndicator/PointsIndicator.react';
import globalNavigationStore from '../GlobalNavigationStore';

// eslint-disable-next-line import/no-webpack-loader-syntax
import icon from '!html-loader!./feedback-icon.svg';

/**
 * Checks the session store and application state to determine whether or not the
 * current session is able to leave feedback.
 */
function canLeaveFeedback() {
  return sessionStore.isTeacher() || sessionStore.isSuper();
}

const NavWidgets = () => {
  if (appStore.isZoneDennis()) {
    return null;
  }

  return (
    <>
      {globalNavigationStore.shouldShowUpgradeButton() ? (
        <li>
          <NavItem stateless>
            <UpgradeButton />
          </NavItem>
        </li>
      ) : null}
      {sessionStore.hasValidSession() && !sessionStore.isStudent() ? (
        <li>
          <ResourceHubLink />
        </li>
      ) : null}
      <li>
        <HelpCenterLink />
      </li>
      {sessionStore.hasValidSession() && sessionStore.isStudent() ? (
        <li>
          <NavItem stateless as='span'>
            <PointsIndicator />
          </NavItem>
        </li>
      ) : null}

      {
        /**
         * We only render the `<FeedbackWidget`> if the session and application state
         * _can_ leave feedback.
         */
        canLeaveFeedback() && <FeedbackWidget />
      }
    </>
  );
};

const ResourceHubLink = () => (
  <NavItem
    as='a'
    icon='graduation-cap'
    href='/resource-hub/'
    activeClassName='global-nav__folders-active'
  >
    Resource Hub
  </NavItem>
);

export const HelpCenterLink = (props) => {
  const {trackEvent} = useTracking({
    event: 'click',
    type: 'link',
    feature: null,
    component: 'primary nav',
    content: 'Help Center'
  });
  return (
    <NavItem
      {...props}
      icon='question-circle'
      href='https://help.albert.io'
      onClick={() => trackEvent()}
    >
      Help
    </NavItem>
  );
};

const FeedbackWidget = awaitMandarkQueries(
  () => ({
    queries: {
      user: resource('users_v2').mandarkEndpoint(['users_v2', sessionStore.getUserId()])
    },
    loadingElement: <div />
  }),
  class FeedbackWidget extends React.PureComponent {
    static propTypes = {
      user: PropTypes.instanceOf(UserModelV2)
    };

    constructor() {
      super();

      this.state = {
        shouldIconPulse: true,
        clearIcon: false,
        showModal: false
      };
    }

    handleToggle = () => {
      this.setState((state) => ({showModal: !state.showModal}));
    };

    handleClick = () => {
      this.setState({shouldIconPulse: false});
      this.handleToggle();
    };

    render() {
      const {user} = this.props;

      const showRatingIcon =
        canLeaveFeedback() &&
        user.getCustomerFeedbackReminderDate() !== null &&
        user.getCustomerFeedbackReminderDate() <= moment();

      if (!showRatingIcon) {
        return null;
      }

      const ratingIconClassNames = classnames({
        'global-nav__rating-icon': !this.state.shouldIconPulse,
        'global-nav__rating-icon-pulsing': this.state.shouldIconPulse
      });

      return (
        <li>
          {!this.state.clearIcon && (
            <NavItem
              className='global-nav__rating-icon-container'
              role='button'
              tabIndex={0}
              as='div'
              active={this.state.showModal}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  this.handleClick();
                }
              }}
              onClick={this.handleClick}
            >
              <div className={ratingIconClassNames} dangerouslySetInnerHTML={{__html: icon}} />
            </NavItem>
          )}
          {this.state.showModal && (
            <Modal
              className='customer-feedback-modal'
              ariaLabel='Customer Feedback Modal'
              handleClose={this.handleToggle}
              longModal
            >
              {() => (
                <CustomerFeedbackDialogue
                  name={user.getFirstName()}
                  handleClose={this.handleToggle}
                  inModal
                  fromNavbar
                  clearIcon={() => this.setState({clearIcon: true})}
                />
              )}
            </Modal>
          )}
        </li>
      );
    }
  }
);

export default NavWidgets;
