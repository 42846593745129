import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {consentOptionPropTypes} from 'client/Onboarding/Steps/ParentalConsent/SelectConsentOption/SelectConsentOption.react';
import awaitMandarkQueries from 'lib/hocs/awaitMandarkQueries';
import {getClassroomInvitationsQuery} from 'lib/ClassroomInvitationUtil/ClassroomInvitationUtil';
import {Button, Heading, Grid, Text} from '@albert-io/atomic';
import {genericMandarkRequest} from 'resources/mandark.resource';
import {completeParentalConsent} from 'lib/OnboardingUtil/OnboardingUtil';
import notifier from '@albert-io/notifier';
import {ClassroomInvitationModelV1} from 'resources/GeneratedModels/ClassroomInvitation/ClassroomInvitationModel.v1';
import ClassroomInviteBanner from 'client/components/ClassroomInviteBanner/ClassroomInviteBanner.react';

export default awaitMandarkQueries(
  (props) => ({
    queries: {
      classroomInvitations: getClassroomInvitationsQuery(props.user.getEmail())
    }
  }),
  class ClassroomInvite extends React.Component {
    static propTypes = {
      ...consentOptionPropTypes,
      classroomInvitations: ImmutablePropTypes.listOf(
        PropTypes.instanceOf(ClassroomInvitationModelV1)
      ).isRequired
    };

    hasInvites = () => {
      return !this.props.classroomInvitations.isEmpty();
    };

    makeInviteHandler = (invite, user) => {
      const {onComplete} = this.props;
      const userId = user.getId();
      return async () => {
        const relationship = {
          type: 'classroom_v1',
          id: invite.getClassroom().getId()
        };

        try {
          await genericMandarkRequest(
            'post',
            {resourcePath: ['students_v1', userId, 'relationships', 'classrooms_v1']},
            {
              data: [relationship]
            }
          );

          try {
            await completeParentalConsent(user).save();
            onComplete();
          } catch (e) {
            notifier.notify(e, {
              component: 'ClassroomInvite',
              name: 'Onboarding: unable to complete parental consent step',
              userId
            });
          }
        } catch (e) {
          notifier.notify(e, {
            component: 'ClassroomInvite',
            name: 'Onboarding: failed to associate user to classroom',
            userId,
            classroomId: invite.getClassroom().getId()
          });
        }
      };
    };

    makeInviteList = () => {
      return this.props.classroomInvitations.map((invitation) => {
        const inviteAcceptHandler = this.makeInviteHandler(invitation, this.props.user);
        return (
          <ClassroomInviteBanner
            as='li'
            className='u-mar-b_1'
            invitation={invitation}
            key={invitation.getId()}
            onClick={inviteAcceptHandler}
          />
        );
      });
    };

    render() {
      return (
        <Grid.Container rowGap={2}>
          <Grid.Row justify='center'>
            <Grid.Col all={6} xs={12}>
              <Grid.Container nested>
                <Grid.Row justify='flex-start'>
                  <Grid.Col all={2} xs={12}>
                    <Button
                      as='div'
                      className='consent-option-back-button'
                      variant='text'
                      onClick={this.props.clearConsentState}
                    >
                      <span className='fa fa-arrow-left' />
                      Back
                    </Button>
                  </Grid.Col>
                </Grid.Row>
              </Grid.Container>
              <Grid.Container nested rowGap={2}>
                <Grid.Row justify='center'>
                  <Grid.Col className='consent-option-copy' all={6} xs={12}>
                    {this.hasInvites() ? (
                      <Heading as='h2'>Accept a class invitation</Heading>
                    ) : (
                      <Heading as='h2'>Accept class invitation</Heading>
                    )}
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row justify='center'>
                  <Grid.Col className='consent-option-copy' all={6} xs={12}>
                    {this.hasInvites() ? (
                      <Text as='p'>Join a class by accepting any of the invites below.</Text>
                    ) : (
                      <Text as='p'>
                        We didn&#39;t find any class invitations. Ask your teacher to invite you in
                        their class roster tab. You can also go back and enter their enrollment code
                        instead.
                      </Text>
                    )}
                  </Grid.Col>
                </Grid.Row>
                {this.hasInvites() ? (
                  <ul className='u-list-style_none u-mar_0 u-pad_0'>{this.makeInviteList()}</ul>
                ) : null}
              </Grid.Container>
            </Grid.Col>
          </Grid.Row>
        </Grid.Container>
      );
    }
  }
);
