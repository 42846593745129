import React from 'react';
import {Link} from 'react-router';
import {MandarkTable, Column} from 'sg/Tables/MandarkTable/MandarkTable.react';
import classroomsStore from '../../ClassroomsStore';
import {history} from 'client/history';
import {mandarkEndpoint} from '@albert-io/json-api-framework/request/builder/legacy';
import './archived-classrooms-list.scss';
import masqueradeStore from 'generic/Masquerade/Masquerade.store';

export const archivedClassroomsPath = '/classes/archived';

export default class ArchivedClassroomsList extends React.Component {
  componentDidMount() {
    this.ensureTeacherHasArchivedClassrooms();
  }

  async ensureTeacherHasArchivedClassrooms() {
    await classroomsStore.getTeacherArchivedClassroomsQuery().getResourcePromise();
    if (classroomsStore.getTeacherArchivedClassrooms().isEmpty()) {
      history.pushState(null, '/classes');
    }
  }

  makeArchivedClassroomsQuery() {
    return mandarkEndpoint([
      'teachers_v1',
      masqueradeStore.getUserIdByMasqueradeState(),
      'classrooms_v1'
    ])
      .filter({status: 'archived'})
      .pageSize(25)
      .withMeta('classroom_v1')
      .meta({
        context: {
          classroom: {
            overview: true
          }
        }
      });
  }

  render() {
    return (
      <section className='archived-classrooms-list max-width card card--no-padding'>
        <div className='archived-classrooms-list__heading-wrapper'>
          <h1>Archived Classes</h1>
        </div>
        <MandarkTable
          query={this.makeArchivedClassroomsQuery()}
          storeName='TeacherArchivedClassroomsList'
        >
          <Column
            grow={3}
            heading='Name'
            sortBy='name'
            content={(classroom) => classroom.getName()}
          />
          <Column
            grow={1}
            heading='Students'
            content={(classroom) => classroom.getCountOfStudents()}
          />
          <Column
            grow={1}
            heading='Questions Answered'
            content={(classroom) => classroom.getCountOfQuestionsAnswered()}
          />
          <Column
            align='right'
            content={(classroom) => {
              return (
                <Link
                  className='button button--green'
                  to={`/classes/${classroom.getId()}/students/overview`}
                >
                  View
                </Link>
              );
            }}
          />
        </MandarkTable>
      </section>
    );
  }
}
