import React from 'react';
import PropTypes from 'prop-types';
import {callAction} from 'client/framework';
import QuestionsList from 'client/InteractionEngineV2/shared/QuestionsList/QuestionsList.react';
import teacherAssignmentV2IEQuestionsListStore from './TeacherAssignmentV2IEQuestionsList.store';
import teacherAssignmentV2IEActions from '../TeacherAssignmentV2IE.actions';
import teacherAssignmentV2IEStore from '../TeacherAssignmentV2IE.store';
import interactionEngineActions from 'client/InteractionEngineV2/InteractionEngineActions';
import {buildStores} from 'client/InteractionEngineV2/InteractionEngine.util';
import questionsListActions from 'client/InteractionEngineV2/shared/QuestionsList/QuestionsListActions';
import QuestionsListTopWidget from 'client/InteractionEngineV2/shared/QuestionsListTopWidget/QuestionsListTopWidget.react';

export default class AssignmentV2IEQuestionsList extends React.Component {
  static propTypes = {
    isReady: PropTypes.bool
  };

  UNSAFE_componentWillMount() {
    this.buildStores();
  }

  UNSAFE_componentWillUpdate() {
    this.buildStores();
  }

  buildStores() {
    const questionSets = teacherAssignmentV2IEStore.getQuestionSets();
    if (questionSets.isEmpty()) {
      return;
    }
    const assignmentId = teacherAssignmentV2IEStore.getAssignment().getId();
    const builtStores = buildStores(teacherAssignmentV2IEStore.getQuestionSets(), assignmentId);
    callAction(interactionEngineActions.SET_BOOTSTRAPPED_STORES, builtStores);
  }

  handleQuestionClick() {
    callAction(teacherAssignmentV2IEActions.SET_IS_VIEWING_STAGING_DETAILS, false);
  }

  toggleStagingDetails() {
    if (!teacherAssignmentV2IEQuestionsListStore.activeQuestion) {
      callAction(questionsListActions.SET_INITIAL_ACTIVE_QUESTION);
    }
    const isViewingStagingDetails = teacherAssignmentV2IEStore.isViewingStagingDetails();
    callAction(
      teacherAssignmentV2IEActions.SET_IS_VIEWING_STAGING_DETAILS,
      !isViewingStagingDetails
    );
  }

  render() {
    const hasNoQuestionSets = teacherAssignmentV2IEQuestionsListStore.questionSets.isEmpty();
    let questionPositionMap;
    if (this.props.isReady) {
      const currentSection = teacherAssignmentV2IEStore.getActiveSection();
      questionPositionMap = currentSection ? currentSection.getQuestionPositionMap() : null;
    }
    return (
      <QuestionsList
        questionsListStore={teacherAssignmentV2IEQuestionsListStore}
        questionSets={teacherAssignmentV2IEQuestionsListStore.questionSets}
        hide={!this.props.isReady || hasNoQuestionSets}
        onQuestionClick={this.handleQuestionClick}
        questionPositionMap={questionPositionMap}
        topDrawer={
          !teacherAssignmentV2IEStore.getQuestionSets().isEmpty() ? (
            <QuestionsListTopWidget
              text={
                teacherAssignmentV2IEStore.isViewingStagingDetails()
                  ? 'View Questions'
                  : 'View Assessment Details'
              }
              onTextClick={this.toggleStagingDetails}
            />
          ) : null
        }
      />
    );
  }
}
