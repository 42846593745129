/**
 * # Dialogue
 *
 * The Dialogue Organism has a title, body, and button group footer that are all wrapped within a Card.
 * The main use case for the Dialogue will be within Modals and popovers.
 * The organism exposes three sections - Dialogue.Title, Dialogue.Body and Dialogue.BtnGroup
 * The title is to be passed in as a prop or created using Dialogue.Title
 *
 * `Dialogue.Title` - children can be anything.
 *
 * `Dialogue.Body` - children can be anything.
 *
 * `Dialogue.BtnGroup` - children should be button elements.
 *
 * @see [Design ticket]{@link https://github.com/albert-io/project-management/issues/2360}
 *
 * ## Props
 *
 * - `alignTitle?: 'left' | 'center'` (optional) - The alignment of the title. Default is 'left'.
 * - `children: React.ReactNode` - The content of the Dialogue.
 * - `className?: string` (optional) - Additional CSS class name for the Dialogue.
 * - `groupTitle?: boolean` (optional) - Whether the title should be grouped with other elements. Default is false.
 * - `inModal?: boolean` (optional) - Whether the Dialogue is inside a modal. Default is false.
 * - `paddingLevel?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11` (optional) - The padding level of the Dialogue. Default is 4.
 * - `shadow?: CardProps<'div'>['shadow']` (optional) - The shadow style of the Dialogue.
 * - `stretchTitle?: boolean` (optional) - Whether the title should stretch. Default is false.
 * - `title?: string` (optional) - The title of the Dialogue.
 * - `size?: 'full' | 'xl' | 'l' | 'm' | 's'` (optional) - The size of the Dialogue. Default is 'm'.
 * - `hideCloseBtn?: boolean` (optional) - Whether to hide the close button. Default is false.
 * - `handleClose?: () => void` (optional) - The callback function for closing the Dialogue.
 *
 * ### `Dialogue.Title` Props
 *
 * - `className?: string`: CSS classname.
 * - `align?: 'left' | 'center'`: Alignment of the title.
 * - `children: React.ReactNode`: Inner content.
 * - `stretchTitle?: boolean` (optional) - Whether the title should stretch. Default is false.
 * - `groupTitle?: boolean` (optional) - Whether the title is part of a group. Default is false.
 * - `size?: 'xl' | 'l' | 'm' | 's' | 'xs' | 'xxs'` (optional) - The size of the title. Default is 'm'.
 *
 * ### `Dialog.Body` Props
 *
 * - `className?: string`: CSS classname.
 * - `align?: 'left' | 'center' | 'right'`: Alignment of body inner content.
 * - `children: React.ReactNode`: Inner content.
 *
 *
 * ### `Dialog.BtnGroup` Props
 *
 * - `className: string`: optional class name for styling purposes
 * - `align: 'left' | 'right' | 'center'`: alignment of the button group (left, right, center)
 * - `direction: 'row' | 'column'`: direction of the button group (row, column)
 * - `fillColumn: boolean`: whether the button group should fill the entire column
 * - `children: React.ReactNode`: Buttons to be passed to the button group.
 *
 *
 * ## Usage
 *
 * ```tsx
 * import Dialogue from './Dialogue';
 *
 * const Example = () => {
 *   return (
 *     <Dialogue
 *       alignTitle="center"
 *       className="custom-dialogue"
 *       groupTitle={true}
 *       inModal={true}
 *       paddingLevel={6}
 *       shadow="medium"
 *       stretchTitle={true}
 *       title="Example Dialogue"
 *       size="l"
 *       hideCloseBtn={false}
 *       handleClose={() => {
 *         // Handle close logic here
 *       }}
 *     >
 *       <Dialogue.Body align="right">
 *         This is the body of the Dialogue.
 *       </Dialogue.Body>
 *       <Dialogue.BtnGroup align="left">
 *         <button>Button 1</button>
 *         <button>Button 2</button>
 *       </Dialogue.BtnGroup>
 *     </Dialogue>
 *   );
 * };
 * ```
 */

import React, {ReactNode} from 'react';
import classnames from 'classnames';

import ButtonGroup from '../../molecules/ButtonGroup/ButtonGroup.react';
import Card, {Props as CardProps} from '../../atoms/Card/Card.react';
import Heading, {Props as HeadingProps} from '../../atoms/Heading/Heading.react';
import IconButton from '../../molecules/IconButton/IconButton.react';
import './dialogue.scss';

interface TitleProps extends PropsWithChildrenRequired, PropsWithClassNameOptional {
  align?: 'left' | 'center';
  stretchTitle?: boolean;
  groupTitle?: boolean;
  size?: HeadingProps['size'];
}

const Title = ({className, align, children, stretchTitle, groupTitle, size = 'm'}: TitleProps) => (
  <Heading
    className={classnames(className, 'o-dialogue__title', {
      [`o-dialogue__title--align-${align}`]: align,
      'o-dialogue__title--stretch': stretchTitle,
      'o-dialogue__title--group': groupTitle
    })}
    size={size}
  >
    {children}
  </Heading>
);

interface BodyProps extends PropsWithChildrenRequired, PropsWithClassNameOptional {
  align?: 'left' | 'center' | 'right';
}

const Body = ({className, align = 'left', children}: BodyProps) => (
  <div
    className={classnames(className, {
      [`o-dialogue__body--align-${align}`]: align
    })}
  >
    {children}
  </div>
);

interface BtnGroupProps extends PropsWithChildrenRequired, PropsWithClassNameOptional {
  align?: 'left' | 'right' | 'center';
  direction?: 'row' | 'column';
  fillColumn?: boolean;
}

const BtnGroup = ({className, align = 'right', children, ...rest}: BtnGroupProps) => (
  <ButtonGroup
    label='Actions'
    align={align}
    className={classnames('o-dialogue__btn-group', className)}
    {...rest}
  >
    {children}
  </ButtonGroup>
);

interface CommonDialogueProps extends PropsWithChildrenRequired, PropsWithClassNameOptional {
  /** Title can't be right aligned due to closeBtn */
  alignTitle?: 'left' | 'center';
  groupTitle?: boolean;
  /** Sets a min & max width if the dialogue is in a modal */
  inModal?: boolean;
  paddingLevel?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;
  shadow?: CardProps<'div'>['shadow'];
  stretchTitle?: boolean;
  title?: ReactNode;
  // Full: 100%; XL: 720px; L (default in modal): 600px, M: 480px, S: 320px
  size?: 'full' | 'xl' | 'l' | 'm' | 's';
}

type CloseButtonProps =
  | {hideCloseBtn?: false; handleClose?: () => void}
  | {hideCloseBtn: true; handleClose?: never};

type DialogueProps = CommonDialogueProps & CloseButtonProps;

export const Dialogue = Object.assign(
  React.forwardRef(
    (
      {
        alignTitle = 'left',
        children,
        className,
        groupTitle,
        handleClose,
        hideCloseBtn = false,
        inModal,
        paddingLevel = 4,
        shadow,
        stretchTitle,
        title,
        size
      }: DialogueProps,
      ref
    ) => {
      // makes sure the closeBtn is at most 24px away from top right
      const buttonMarginLevel =
        Math.max(paddingLevel - 1, 0) > 3 ? 3 : Math.max(paddingLevel - 1, 0);

      // Only want a default size if the component is being used within a modal
      let dialogueSize = size;
      if (inModal && !size) {
        dialogueSize = 'm';
      }

      return (
        <Card
          ref={ref}
          paddingLevel={paddingLevel}
          className={classnames('o-dialogue__content', className, {
            [`o-dialogue--size-${dialogueSize}`]: dialogueSize
          })}
          shadow={shadow}
        >
          {title && (
            <div className='o-dialogue__header'>
              <Title align={alignTitle} stretchTitle={stretchTitle} groupTitle={groupTitle}>
                {title}
              </Title>
            </div>
          )}
          {!hideCloseBtn && (
            <IconButton
              className={classnames(
                'o-dialogue__close-button',
                `u-mar-t_${buttonMarginLevel}`,
                `u-mar-r_${buttonMarginLevel}`
              )}
              icon='times'
              label='Close dialogue'
              onClick={handleClose}
            />
          )}
          {children}
        </Card>
      );
    }
  ),
  {
    BtnGroup,
    Body,
    Title
  }
);

Dialogue.BtnGroup = BtnGroup;

Dialogue.Body = Body;

Dialogue.Title = Title;

export default Dialogue;
