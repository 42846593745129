import React from 'react';
import classnames from 'classnames';
import {Link, withRouter} from 'react-router';
import PropTypes from 'prop-types';
import {isEmpty} from 'lodash';
import qs from 'qs';
import {withApplicationMetadata} from 'client/Routing/withApplicationMetadata';
import sessionStore from 'client/Session/SessionStore';
import {pluralize} from 'lib/stringUtils';
import constants from 'client/constants';
import {showGlobalLoginModal} from 'client/LogIn/utils';
import appStore from 'client/AppStore';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {getQueryParamsAsObject, removeQueryParams} from 'client/history';

import {
  canCreateTemplateForSubject,
  getUserSubjectAccessQuery,
  isUnlicensedTeacherOrAdmin,
  getDoesUserBelongToAnyLicensedClassroomsQuery
} from 'lib/UserAccessUtil';
import LoadingIndicator from 'generic/LoadingIndicator.react';
import {Card, Heading, LoadingSpinner, Icon, WindowSizeConsumer} from '@albert-io/atomic';
import UpgradeSubjectModal from 'components/PracticeView/UpgradeSubjectModal/UpgradeSubjectModal.react';
import ActionsDropdown from 'client/generic/StudyGuideItem/Teacher/ActionsDropdown/ActionsDropdown.react';
import AccuracyBadge from 'client/generic/AccuracyBadge/AccuracyBadge.react';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {
  StudyGuideItemList,
  StudyGuideItem
} from 'client/generic/StudyGuideItem/StudyGuideItem.react';
import AdvancedSearch from 'client/components/AdvancedSearch/AdvancedSearch.react';
import {getGuideLevelsBySearchParams} from 'client/components/AdvancedSearch/AdvancedSearch.queries';
import useMandarkQueries from 'lib/hooks/useMandarkQueries';
import {GuideLevelsCollectionModel} from 'resources/GuideLevel/GuideLevelsCollection.model';
import {SubjectModelV2} from 'resources/augmented/Subject/SubjectModel.v2';
import {GuideModelV1} from 'resources/augmented/Guide/GuideModel.v1';
import {UserModelV2} from 'resources/GeneratedModels/User/UserModel.v2';

import {showPublicViewForSubject} from 'components/PracticeView/practiceView.utils';

import {getSearchParams, isSearchMode} from '../components/AdvancedSearch/AdvancedSearch.utils';
import {hasStandardsQuery, hasTagsQuery} from '../components/AdvancedSearch/AdvancedSearch.queries';

import MarketingBanner from './MarketingBanner/MarketingBanner.react';
import studyGuideUtils from './utils';
import {ThemeMenu, SCROLL_PADDING} from './ThemeMenu/ThemeMenu';

import './study-guide.scss';

const NoResultsFound = () => (
  <Card className='u-width_100pc u-mar-t_2' paddingLevel={5} roundness='small'>
    <Heading as='center' size='m'>
      No matches found
    </Heading>
  </Card>
);

class GuideLevelsList extends React.Component {
  static propTypes = {
    guide: PropTypes.instanceOf(GuideModelV1),
    guideLevelsBySearchParams: PropTypes.instanceOf(GuideLevelsCollectionModel),
    location: PropTypes.shape({
      hash: PropTypes.string,
      query: PropTypes.shape({
        s: PropTypes.string,
        standards: PropTypes.string,
        tags: PropTypes.string
      }),
      search: PropTypes.string
    }),
    subject: PropTypes.instanceOf(SubjectModelV2),
    user: PropTypes.instanceOf(UserModelV2),
    classrooms: ImmutablePropTypes.list,
    usePublicView: PropTypes.bool,
    showAdvancedSearch: PropTypes.bool,
    onSearchCallback: PropTypes.func,
    isReady: PropTypes.bool
  };

  constructor() {
    super();
    this.state = {
      shouldShowUpgradeSubjectModal: false,
      hasPopover: false
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.bootstrapComponentState();
  }

  componentDidUpdate(prevProps) {
    // We may only need to compare if the guide changes but we are comparing all props to be safe
    if (
      prevProps.location?.query?.s !== this.props.location?.query?.s ||
      prevProps.guide !== this.props.guide ||
      prevProps.subject !== this.props.subject ||
      prevProps.classrooms !== this.props.classrooms
    ) {
      this.bootstrapComponentState();
    }
  }

  bootstrapComponentState() {
    const {guide, subject, classrooms} = this.props;
    if (sessionStore.hasValidSession() && !classrooms.isEmpty() && !subject.doesUserHaveAccess()) {
      this.toggleShowUpgradeSubjectModal();
    }

    const guideLevels = guide
      .getSortedGuideLevels()
      .filter((guideLevel) => guideLevel.hasQuestions())
      .filter(this.shouldDisplayGuideLevel);
    const themes = guideLevels.filter((guideLevel) => guideLevel.getNlevel() === 1);
    const themeRefs = themes.reduce((acc, theme) => {
      const ref = React.createRef();
      return {
        ...acc,
        [theme.getId()]: ref
      };
    }, {});

    // add scroll to slug behavior (see https://github.com/albert-io/project-management/issues/4140)
    const themeFromUrl = themes.find(
      (theme) => theme.getUrlSlug() === this.props.location.hash.substring(1)
    );

    this.setState({
      subject,
      classrooms,
      themes,
      themeRefs,
      visibleTheme: themeFromUrl?.getId() || themes?.first()?.getId(),
      initialTheme: themeFromUrl?.getId()
    });
  }

  getMatchingQuestionCount = (guideLevel) => {
    const matchingSearchResult = this.props.guideLevelsBySearchParams
      .getCollection()
      .find((result) => result.getId() === guideLevel.getId());

    return matchingSearchResult.getMeta().getTotalMatchingQuestions() || 0;
  };

  shouldDisplayGuideLevel = (guideLevel) => {
    if (!isSearchMode()) {
      return true;
    }
    const matchingGuideLevelIds = this.props.guideLevelsBySearchParams.cache('f', () => {
      return this.props.guideLevelsBySearchParams
        .getCollection()
        .toSet()
        .map((level) => level.getId());
    });
    return matchingGuideLevelIds.includes(guideLevel.getId());
  };

  generateActionsDropdown(guideLevel, hasAccessToCreateTemplate, linkToQuestions) {
    if (isSearchMode() || (!guideLevel.isFree() && !hasAccessToCreateTemplate)) {
      return null;
    }
    return (
      <ActionsDropdown
        link={linkToQuestions}
        buttonClassName='study-guide-actions-dropdown__btn unbutton'
        className='study-guide-actions-dropdown'
        guideLevel={guideLevel}
      />
    );
  }

  toggleShowUpgradeSubjectModal = () => {
    this.setState(({shouldShowUpgradeSubjectModal}) => ({
      shouldShowUpgradeSubjectModal: !shouldShowUpgradeSubjectModal
    }));
  };

  getStudyGuideLink = (linkToQuestions, queryString) => {
    const currentRoute =
      appStore.routerProps.location.pathname + appStore.routerProps.location.search;
    return {
      pathname: linkToQuestions,
      search: queryString,
      state: {
        from: currentRoute
      }
    };
  };

  onKeyPress = (e, onClick) => {
    if (e.key === 'Enter') {
      onClick(e);
    }
  };

  handleScroll = () => {
    const el = document.querySelector('.study-guide__sticky-search');
    if (!el) {
      const {themeRefs, visibleTheme} = this.state;
      const scrolledToTheme = Object.entries(themeRefs).reduce((selectedTheme, currentThemeRef) => {
        const [topicId, ref] = currentThemeRef;
        const refTop = ref.current?.parentNode?.getBoundingClientRect()?.top - SCROLL_PADDING;
        if (refTop <= 0) {
          return topicId;
        }
        return selectedTheme;
      }, visibleTheme);
      this.setState({visibleTheme: scrolledToTheme});
    } else {
      // handles logic for adding the box shadow to the bottom of the stickied nav
      const stickyTop = parseInt(window.getComputedStyle(el).top, 10);
      const currentTop = el.getBoundingClientRect().top;
      const stickyClass = 'study-guide__sticky-search--is-sticky';
      if (currentTop === stickyTop) {
        el.classList.add(stickyClass);
      } else {
        el.classList.remove(stickyClass);
      }
    }
  };

  render() {
    const {subject, classrooms, themes, themeRefs, initialTheme, hasPopover} = this.state;
    const {guide, showAdvancedSearch, onSearchCallback, guideLevelsBySearchParams, isReady} =
      this.props;
    if (!subject || !guide) {
      return null;
    }
    const guideLevels = guide
      .getSortedGuideLevels()
      .filter((guideLevel) => guideLevel.hasQuestions())
      .filter(this.shouldDisplayGuideLevel);
    const hasTeacherAccess = sessionStore.isTeacher();
    const hasAccessToCreateTemplate = canCreateTemplateForSubject(subject.getId());
    return (
      <WindowSizeConsumer>
        {({breakpoints, viewportWidth}) => {
          const isMobileView = viewportWidth <= breakpoints.s;
          const hideThemeDropdownCTAText = viewportWidth <= breakpoints.xs;
          const isEmptySearches =
            isSearchMode() && guideLevelsBySearchParams.getCollection().isEmpty();
          return (
            <div className='study-guide-wrapper'>
              <div
                className={classnames('study-guide__sticky-search', {
                  // when popover backdrop is open, we need to increase the z-index of the sticky search by 1
                  'study-guide__sticky-search--zIndex':
                    hasPopover && viewportWidth <= breakpoints.xs
                })}
              >
                <AdvancedSearch
                  showFilters={showAdvancedSearch}
                  key={appStore.routerProps.location.pathname}
                  onSearchCallback={onSearchCallback}
                  subjectId={subject.getId()}
                  isMobileView={isMobileView}
                  isEmptySearches={isEmptySearches}
                  themes={themes}
                  themeRefs={themeRefs}
                  hideThemeDropdownCTAText={hideThemeDropdownCTAText}
                  setHasPopover={(val) => this.setState({hasPopover: val})}
                />
              </div>
              {!isReady ? (
                <LoadingIndicator />
              ) : (
                <div className='study-guide-content'>
                  <div className='study-guide-content__split-pane'>
                    <ThemeMenu
                      themes={themes}
                      themeRefs={themeRefs}
                      initialTheme={initialTheme}
                      hideSidebar={isMobileView}
                    />
                    {isEmptySearches ? (
                      <NoResultsFound />
                    ) : (
                      <div className='study-guide-cards'>
                        <StudyGuideItemList>
                          {guideLevels.map((guideLevel, i, guideLevels) => {
                            let metadata;
                            if (isSearchMode()) {
                              const matchingQuestionsCount =
                                this.getMatchingQuestionCount(guideLevel);
                              metadata = (
                                <span className='study-guide__search-result'>
                                  {`${matchingQuestionsCount} ${pluralize(
                                    'question',
                                    matchingQuestionsCount
                                  )}`}
                                </span>
                              );
                            } else {
                              metadata =
                                guideLevel.getQuestionMetadataForSubjectView(hasTeacherAccess);
                            }

                            let queryString = '';
                            if (isSearchMode()) {
                              const searchQuery = {
                                ...this.props.location.query,
                                page: 1
                              };
                              queryString += qs.stringify(searchQuery, {addQueryPrefix: true});
                            }
                            const linkToQuestions = guideLevel.getLinkToQuestions();
                            const nextGuideLevel = guideLevels.get(i + 1);
                            const hasSubtopics =
                              nextGuideLevel &&
                              nextGuideLevel.getNlevel() === guideLevel.getNlevel() + 1;
                            const isLastOfTheme =
                              nextGuideLevel && nextGuideLevel.getNlevel() === 1;
                            const actionsByUserType = hasTeacherAccess ? (
                              this.generateActionsDropdown(
                                guideLevel,
                                hasAccessToCreateTemplate.value,
                                linkToQuestions + queryString
                              )
                            ) : (
                              <AccuracyBadge
                                className='study-guide-actions__accuracy-badge'
                                guideLevel={guideLevel}
                              />
                            );
                            const guideLevelActions = guideLevel.isTheme()
                              ? null
                              : actionsByUserType;
                            /**
                             * We add **1** here to account for the `<HeroUnit>` containing a `h1`.
                             */
                            const headingLevel = guideLevel.getNlevel() + 1;
                            const isAssessment =
                              guide.getGuideType() === constants.GUIDE_TYPES.ASSESSMENT;
                            const isStudent = sessionStore.isStudent();
                            const retailStudentWithAccess =
                              isStudent && classrooms.isEmpty() && subject.doesUserHaveAccess();
                            const isStudentWithoutAccess = isStudent && !retailStudentWithAccess;
                            const {isReady, value: isUnlicensedNonStudent} =
                              isUnlicensedTeacherOrAdmin();
                            /*
                             * when a user can't access the guide level, we need to run a function instead of link
                             * right now, this is for an assessment guide, with unlicensed, logged out, or student users
                             */
                            const teacherOrAdminWithoutAccess =
                              isUnlicensedNonStudent && !subject.doesUserHaveAccess();
                            let isButton;
                            let buttonOnClick;
                            if (!sessionStore.hasValidSession() && isAssessment) {
                              isButton = true;
                              buttonOnClick = showGlobalLoginModal;
                            } else if (
                              isReady &&
                              isAssessment &&
                              (isStudentWithoutAccess || teacherOrAdminWithoutAccess)
                            ) {
                              isButton = true;
                              buttonOnClick = this.toggleShowUpgradeSubjectModal;
                            } else if (
                              isReady &&
                              !classrooms.isEmpty() &&
                              !subject.doesUserHaveAccess() &&
                              sessionStore.hasValidSession()
                            ) {
                              isButton = true;
                              buttonOnClick = this.toggleShowUpgradeSubjectModal;
                            }

                            const guideLevelDisabled =
                              this.props.usePublicView && (guideLevel.isTheme() || hasSubtopics);

                            let typeProps = {
                              as: Link,
                              to: guideLevelDisabled
                                ? ''
                                : this.getStudyGuideLink(linkToQuestions, queryString)
                            };
                            if (this.props.usePublicView && !guideLevelDisabled && isSearchMode()) {
                              typeProps = {
                                as: 'li',
                                onClick: this.toggleShowUpgradeSubjectModal,
                                tabIndex: '0',
                                role: 'button',
                                onKeyPress: () =>
                                  this.onKeyPress(this.toggleShowUpgradeSubjectModal)
                              };
                            } else if (isButton) {
                              typeProps = {
                                as: 'li',
                                onClick: buttonOnClick,
                                tabIndex: '0',
                                role: 'button',
                                onKeyPress: () => this.onKeyPress(buttonOnClick)
                              };
                            }

                            let classNames = 'decoration_none';
                            if (isLastOfTheme) {
                              classNames += ' sgi--last-of-theme';
                            }
                            if (guideLevelDisabled) {
                              classNames += ' disabled';
                            }

                            const actionClassNames = hasTeacherAccess ? 'teacher' : 'student';
                            const guideLevelRef = themeRefs[guideLevel.getId()] || null;

                            return (
                              <StudyGuideItem
                                aria-label={`${guideLevel.getName()}${
                                  guideLevel.isFree() ? '(Free)' : ''
                                }`}
                                className={classNames}
                                itemType={guideLevel.getGuideLevelType()}
                                key={guideLevel.id}
                                id={guideLevel.getUrlSlug()}
                                {...typeProps}
                              >
                                {guideLevel.isTheme() ? (
                                  <StudyGuideItem.Img src={guideLevel.getThemeImg()} />
                                ) : null}
                                <StudyGuideItem.Content>
                                  <StudyGuideItem.Heading as={`h${headingLevel}`}>
                                    <MarkdownRendererV2 text={guideLevel.getName()} />
                                  </StudyGuideItem.Heading>
                                  {!!guideLevel.getDescription() && (
                                    <StudyGuideItem.Body>
                                      <MarkdownRendererV2 text={guideLevel.getDescription()} />
                                    </StudyGuideItem.Body>
                                  )}
                                  {!guideLevel.isTheme() ? (
                                    <StudyGuideItem.Meta>{metadata}</StudyGuideItem.Meta>
                                  ) : null}
                                </StudyGuideItem.Content>
                                <StudyGuideItem.Actions className={actionClassNames}>
                                  {guideLevelDisabled ? <Icon icon='lock' /> : guideLevelActions}
                                </StudyGuideItem.Actions>
                                <span ref={guideLevelRef} />
                              </StudyGuideItem>
                            );
                          })}
                        </StudyGuideItemList>
                      </div>
                    )}
                  </div>
                </div>
              )}
              {this.state.shouldShowUpgradeSubjectModal && (
                <UpgradeSubjectModal
                  subject={this.props.subject}
                  handleClose={this.toggleShowUpgradeSubjectModal}
                />
              )}
            </div>
          );
        }}
      </WindowSizeConsumer>
    );
  }
}

const BootstrappedGuideLevelsList = withRouter(
  withApplicationMetadata(
    (props) => {
      const {subject, location} = props;
      const params = getSearchParams();

      // Caching tags, standards, and subject access queries since we know they are going to be used by children components
      // This allows us to fetch everything in a single load time
      const guideType = location.pathname.includes('assessments') ? 'assessment' : 'practice';
      const {
        isReady,
        resources: [
          guide,
          classrooms,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          __cachedTags,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          __cachedStandards,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          __cachedSubjectAccess,
          guideLevelsBySearchParams
        ]
      } = useMandarkQueries([
        studyGuideUtils.getGuideBySubjectIdQuery(subject.getId(), guideType),
        getDoesUserBelongToAnyLicensedClassroomsQuery(),
        hasTagsQuery(subject.getId()),
        hasStandardsQuery(subject.getId()),
        getUserSubjectAccessQuery(subject.getId()),
        ...(isSearchMode()
          ? [
              getGuideLevelsBySearchParams({
                ...params,
                subjectId: subject.getId(),
                guideType
              })
            ]
          : [])
      ]);

      return (
        <GuideLevelsList
          {...props}
          isReady={isReady}
          guide={guide}
          classrooms={classrooms}
          guideLevelsBySearchParams={guideLevelsBySearchParams}
        />
      );
    },
    (props) => {
      const guideType = props.location.pathname.includes('assessments') ? 'Assessment' : 'Practice';

      return {
        title: `${props.subject.getName()} | ${guideType} | Albert`,
        metaDescription: props.subject.getDescription()
      };
    }
  )
);

const StudyGuide = ({subject, user, onSearchCallback, location}) => {
  const {isReady, value: isUnlicensedNonStudent} = isUnlicensedTeacherOrAdmin();

  if (!isReady) {
    return <LoadingSpinner />;
  }

  const guideType = location.pathname.includes('assessments') ? 'assessment' : 'practice';
  const isAssessment = guideType === 'assessment';
  const usePublicView = showPublicViewForSubject(subject);
  const showAdvancedSearch = !(
    isAssessment &&
    (!sessionStore.hasValidSession() || isUnlicensedNonStudent || sessionStore.isStudent())
  );
  if (!showAdvancedSearch) {
    const params = getQueryParamsAsObject();
    if (!isEmpty(params)) {
      removeQueryParams(...Object.keys(params));
    }
  }
  return (
    <>
      <MarketingBanner user={user} subject={subject} className='u-pad-lr_2' />
      <BootstrappedGuideLevelsList
        subject={subject}
        user={user}
        usePublicView={usePublicView}
        showAdvancedSearch={showAdvancedSearch}
        onSearchCallback={onSearchCallback}
      />
    </>
  );
};

StudyGuide.propTypes = {
  subject: PropTypes.instanceOf(SubjectModelV2),
  user: PropTypes.instanceOf(UserModelV2),
  onSearchCallback: PropTypes.func,
  location: PropTypes.object
};

export default StudyGuide;
