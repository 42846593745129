import {useCallback} from 'react';

import {callTargetedAction, getStoreByName} from 'client/framework';
import sessionStore from 'client/Session/SessionStore';

import questionTypeActions from '../../components/QuestionTypes/questionType.actions';

import {SaveStatus} from '../../components/QuestionTypes/QuestionType.types';

import DraftGuessSaveStatus from './DraftGuessSaveStatus';

export default function useDraftGuess(storeName: string, submitted: boolean) {
  const store = getStoreByName(storeName);

  const cancelDebounce = useCallback(() => {
    if (submitted) {
      return;
    }

    callTargetedAction({
      name: questionTypeActions.CANCEL_DEBOUNCED_SAVE_DRAFT_GUESS,
      targetStore: storeName
    });
  }, [storeName, submitted]);

  const loadDraftGuess = useCallback(() => {
    if (submitted) {
      return;
    }

    callTargetedAction({
      name: questionTypeActions.LOAD_DRAFT_GUESS,
      targetStore: storeName
    });
  }, [storeName, submitted]);

  const saveDraftGuess = useCallback(() => {
    if (submitted) {
      return;
    }

    callTargetedAction({
      name: questionTypeActions.SAVE_DRAFT_GUESS,
      targetStore: storeName
    });
  }, [storeName, submitted]);

  const startTimer = useCallback(() => {
    if (submitted) {
      return;
    }

    callTargetedAction({
      name: questionTypeActions.START_TIMER,
      targetStore: storeName
    });
  }, [storeName, submitted]);

  const warnUser = useCallback(
    (e: BeforeUnloadEvent) => {
      if (submitted || !store || !sessionStore.hasValidSession()) {
        return;
      }

      if (
        store.getHasPendingDebouncedSaveDraftGuess() ||
        store.getSaveStatus() === SaveStatus.SAVE_ERROR
      ) {
        e.preventDefault();
        e.returnValue = 'Leaving this page may result in unsaved changes';
      }
    },
    [store, submitted]
  );

  return {
    cancelDebouncedSaveDraftGuess: cancelDebounce,
    draftGuessPromise: store.getDraftGuessPromise(),
    DraftGuessSaveStatus,
    loadDraftGuess,
    saveDraftGuess,
    saveStatus: store.getSaveStatus(),
    startTimer,
    warnUser
  };
}
