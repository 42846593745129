import React from 'react';

import {Modal, Dialogue, Text, Button} from '@albert-io/atomic';

interface Props {
  onClose: () => void;
  deleteQuestionSet: () => void;
}

export const DeleteQuestionModal = ({onClose, deleteQuestionSet}: Props) => {
  return (
    <Modal ariaLabel='confirm question set deletion modal' handleClose={onClose}>
      {({CloseButtonWrapper, modalContentStyle}) => {
        return (
          <Dialogue
            handleClose={onClose}
            inModal
            className={modalContentStyle}
            title='Delete question'
          >
            <Dialogue.Body>
              <Text>Are you sure you want to delete this question?</Text>
            </Dialogue.Body>
            <Dialogue.BtnGroup>
              <CloseButtonWrapper>
                <Button variant='text'>Cancel</Button>
              </CloseButtonWrapper>
              <Button color='negative' onClick={() => deleteQuestionSet()}>
                Delete Question
              </Button>
            </Dialogue.BtnGroup>
          </Dialogue>
        );
      }}
    </Modal>
  );
};
