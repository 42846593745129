import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {v4 as uuid} from 'uuid';
import makeRef from 'lib/makeRef';
import {callTargetedAction, setUpStore} from 'client/framework';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import tooltipActions from './Tooltip.actions';
import TooltipStore from './Tooltip.store';
import './sg-tooltip.scss';

export default class Tooltip extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string,
    color: PropTypes.oneOf(['light', 'dark']),
    markdown: PropTypes.string,
    storeName: PropTypes.string,
    onHide: PropTypes.func,
    position: PropTypes.oneOf(['top', 'left', 'right', 'bottom']),
    toggleOnHover: PropTypes.bool,
    style: PropTypes.object,
    isFixed: PropTypes.bool,
    keepOpen: PropTypes.bool
  };

  static defaultProps = {
    color: 'dark',
    onHide: () => {},
    position: 'top',
    toggleOnHover: false,
    style: {},
    isFixed: false
  };

  constructor(props) {
    super(props);
    this.storeName = props.storeName || `TooltipStore${uuid()}`;
    this.wrapperNode = null;
    this.getStore();
  }

  componentDidUpdate() {
    if (this.props.toggleOnHover) {
      return;
    }
    if (this.getStore().isOpen()) {
      global.document.addEventListener('click', this.handleClickOutsideComponent);
    } else {
      global.document.removeEventListener('click', this.handleClickOutsideComponent);
    }
  }

  componentWillUnmount() {
    if (!this.props.toggleOnHover) {
      global.document.removeEventListener('click', this.handleClickOutsideComponent);
    }
  }

  getStore() {
    return setUpStore(TooltipStore, this.storeName);
  }

  handleClickOutsideComponent = (e) => {
    if (!this.wrapperNode.contains(e.target) && !this.props.toggleOnHover) {
      callTargetedAction({
        name: tooltipActions.SET_VISIBILITY,
        payload: false,
        targetStore: this.storeName
      });
    }
    this.props.onHide();
  };

  makeRef = makeRef.bind(this);

  render() {
    const content = this.props.markdown ? (
      <MarkdownRendererV2 text={this.props.markdown} />
    ) : (
      this.props.children
    );
    const isOpen = this.props.keepOpen || this.getStore().isOpen();
    const classNames = classnames('sg-tooltip', this.props.className, {
      'sg-tooltip--hidden': !isOpen,
      'sg-tooltip--top': this.props.position === 'top',
      'sg-tooltip--left': this.props.position === 'left',
      'sg-tooltip--right': this.props.position === 'right',
      'sg-tooltip--bottom': this.props.position === 'bottom',
      'sg-tooltip--light': this.props.color === 'light',
      'sg-tooltip--dark': this.props.color === 'dark',
      'sg-tooltip--absolute': !this.props.isFixed
    });
    return (
      <div
        className={classNames}
        ref={this.makeRef}
        data-ref-name='wrapperNode'
        style={this.props.style}
      >
        <div className='sg-tooltip__body'>{content}</div>
      </div>
    );
  }
}

/**
 * A component that can be used to wrap _anything_ and add a tooltip!
 *
 * @example <caption>A simple string tooltip.</caption>
 * ```js
 *  <TooltipWrapper
 *    tooltip='Hello, world.'
 *  >
 *    When you hover you're mouse over this, you'll see a tooltip.
 *  </TooltipWrapper>
 * ```
 * @example <caption>Configuring the tooltip using a props object.</caption>
 * ```js
 *  <TooltipWrapper
 *    tooltip={{
 *     children: (<b>cool</b>),
 *     position: 'bottom'
 *    }}
 *  >
 *    When you hover your mouse over this, you'll see a tooltip.
 *  </TooltipWrapper>
 * ```
 */
export class TooltipWrapper extends React.Component {
  static propTypes = {
    tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.object])
  };

  getTooltip() {
    const tooltipContent = this.props.tooltip;
    if (!tooltipContent) {
      return null;
    }
    if (React.isValidElement(this.props.tooltip) || typeof this.props.tooltip === 'string') {
      return <Tooltip keepOpen>{this.props.tooltip}</Tooltip>;
    }
    return <Tooltip {...this.props.tooltip} keepOpen />;
  }

  render() {
    return (
      <div className='sg-tooltip-wrapper'>
        {this.props.children}
        {this.getTooltip()}
      </div>
    );
  }
}
