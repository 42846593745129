/**
 * # Pagination
 *
 * A component that provides pagination functionality.
 *
 * ## Props
 *
 * - `align` (string): The alignment of the pagination component. Possible values are 'left', 'center', or 'right'. (default: 'center')
 * - `defaultPage` (number): The default page to display. (default: 1)
 * - `className` (string): Additional CSS class name for the component.
 * - `variation` (string): The variation of the pagination component. Possible values are 'full', 'condensed', 'mini', or 'micro'. (default: 'condensed')
 * - `totalPages` (number): The total number of pages.
 * - `position` (string): The position of the pagination component. (default: 'bottom')
 * - `onChange` (function): The callback function triggered when a page is selected.
 *
 * ## Usage
 *
 * ```jsx
 * <Pagination
 *   align="center"
 *   defaultPage={1}
 *   className="pagination-component"
 *   variation="condensed"
 *   totalPages={10}
 *   position="bottom"
 *   onChange={(page) => {
 *     console.log(`Selected page: ${page}`);
 *   }}
 * />
 * ```
 */
import classNames from 'classnames';
import React, {useEffect, useRef, useState} from 'react';

import Button from '@albert-io/atomic/atoms/Button/Button.react';
import Text from '@albert-io/atomic/atoms/Text/Text.react';

import IconButton from '../../molecules/IconButton/IconButton.react';

import PaginationDropdown from './PaginationDropdown.react';

import './pagination.scss';

type AlignOption = 'left' | 'center' | 'right';

enum VariationLevel {
  micro = 1,
  mini,
  condensed,
  full
}
type VariationLevelString = keyof typeof VariationLevel;

interface Props extends PropsWithClassNameOptional {
  align?: AlignOption;
  defaultPage?: number | string;
  onChange?: (page: number) => void;
  position?: string;
  totalPages: number;
  variation?: VariationLevelString;
  paginationDropdownClassName?: string;
}

const Pagination = ({
  align = 'center',
  className,
  defaultPage = 1,
  onChange = () => {},
  position = 'bottom',
  totalPages,
  variation = 'condensed',
  paginationDropdownClassName,
  ...rest
}: Props) => {
  const defaultPageNumber =
    typeof defaultPage === 'string' ? parseInt(defaultPage, 10) : defaultPage;

  if (totalPages === 1) {
    return null;
  }

  const firstUpdate = useRef(true);
  const [currentPage, setCurrentPage] = useState(defaultPageNumber);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    selectPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPages]);

  const isVariationOrLarger = (variationLevelString: VariationLevelString) => {
    return VariationLevel[variation] >= VariationLevel[variationLevelString];
  };

  const selectPage = (page: number) => {
    setCurrentPage(page);
    onChange(page);
  };

  const selectNextPage = () => {
    if (currentPage === totalPages) {
      return;
    }
    selectPage(currentPage + 1);
  };

  const selectPreviousPage = () => {
    if (currentPage === 1) {
      return;
    }

    selectPage(currentPage - 1);
  };

  return (
    <nav
      aria-label='Pagination'
      className={classNames(
        'o-pagination',
        className,
        `o-pagination--${align}`,
        `o-pagination--${variation}`
      )}
      {...rest}
    >
      <IconButton
        disabled={currentPage === 1}
        icon='chevron-left'
        label='previous'
        onClick={selectPreviousPage}
        size='s'
      />
      {isVariationOrLarger('mini') ? (
        <PaginationDropdown
          currentPage={currentPage}
          position={position}
          onDropdownItemClick={selectPage}
          totalPages={totalPages}
          paginationDropdownClassName={paginationDropdownClassName}
        />
      ) : null}
      {isVariationOrLarger('condensed') ? (
        <>
          <Text bold className='o-pagination__divider' color='tertiary'>
            /
          </Text>
          <Button
            className='o-pagination__page-button'
            color='secondary'
            variant='text'
            roundness='pill'
            size='s'
            onClick={() => selectPage(totalPages)}
          >
            {totalPages}
          </Button>
        </>
      ) : null}
      <IconButton
        disabled={currentPage === totalPages}
        icon='chevron-right'
        label='next'
        onClick={selectNextPage}
        size='s'
      />
    </nav>
  );
};

export default Pagination;
