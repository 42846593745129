import {fromJS, List} from 'immutable';
import {Store} from 'client/framework';
import teacherAssignmentV2IEActions from './TeacherAssignmentV2IE.actions';
import {resource} from '@albert-io/json-api-framework/request/builder';
import appStore from 'client/AppStore';
import sessionStore from 'client/Session/SessionStore';

class TeacherAssignmentV2IEStore extends Store {
  constructor(name) {
    super(name);
    this.setInitialData(
      fromJS({
        isViewingStagingDetails: true,
        assignmentPromise: null,
        activeSectionId: null,
        assignmentId: null
      })
    );
    this.handle(
      teacherAssignmentV2IEActions.SET_IS_VIEWING_STAGING_DETAILS,
      this.setProperty('isViewingStagingDetails')
    );
    this.handle(
      teacherAssignmentV2IEActions.SET_SELECTED_ACTIVE_SECTION_ID,
      this.setProperty('activeSectionId')
    );
    this.handle(teacherAssignmentV2IEActions.RESET_STORE, this.resetStore);
  }

  getAssignmentQuery() {
    return resource('assignment_v3')
      .mandarkEndpoint(['assignments_v3', appStore.routerProps.params.assignmentId])
      .include('sections_v1.question_sets_v1.questions_v1.supplements_v1')
      .include('sections_v1.question_sets_v1.subject_v2')
      .include('sections_v1.question_sets_v1.tags_v1')
      .include('question_sets_v1.questions_v1.supplements_v1')
      .include('question_sets_v1.subject_v2')
      .include('question_sets_v1.tags_v1')
      .include('classrooms_v1')
      .withMeta('assignment_v3,section_v1')
      .customQuery({
        question_v1: {
          labels: true
        }
      })
      .customQuery({
        meta: {
          teacher_id: sessionStore.getUserId()
        }
      });
  }

  getAssignment() {
    return this.getAssignmentQuery().getResource();
  }

  getQuestionSets() {
    if (!this.getAssignmentQuery().isResourcePopulated() || this.getActiveSection() === null) {
      return List();
    }
    return this.getActiveSection().getSortedQuestionSets();
  }

  getActiveSection() {
    const activeSectionId = this.getActiveSectionId();
    if (!activeSectionId) {
      return null;
    }
    return this.getAssignment()
      .getSections()
      .find((section) => section.getId() === activeSectionId);
  }

  getAssignmentId = () => this.readData('assignmentId');

  isViewingStagingDetails = () => this.readData('isViewingStagingDetails');

  getActiveSectionId = () => this.readData('activeSectionId');
}

export default new TeacherAssignmentV2IEStore('TeacherAssignmentV2IEStore');
