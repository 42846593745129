import React, {useState, useContext} from 'react';
import constants from 'client/constants';
import {
  Button,
  Dropdown,
  DropdownItem,
  Icon,
  copyToClipboard,
  addToast,
  Dialogue
} from '@albert-io/atomic';
import {history} from 'client/history';
import {invalidatePartialInterest, genericMandarkRequest} from 'resources/mandark.resource';
import ConfirmActionModal from 'client/components/ConfirmActionModal/ConfirmActionModal.react';
import {ReportsContext} from 'client/Reports/Reports.context';
import {AnalyticsApiResult} from 'client/Reports/reports.types';
import {getAssignment} from 'client/Reports/reports.utils';

import './assignment-actions.scss';
import CustomizeReassignModal from 'client/components/CustomizeReassignModal/CustomizeReassignModal.react';

interface Props {
  result: AnalyticsApiResult;
  isAllClassesTab: boolean;
}

const AssignmentActions = ({result, isAllClassesTab}: Props) => {
  const assignment = getAssignment(result);
  const [showModal, setShowModal] = useState(false);
  const [showReassignModal, setShowReassignModal] = useState(false);

  const {fetchData} = useContext(ReportsContext);

  // on the all classes tab, this just keys into the first classroom assignment
  // on a specific classroom, it will already be filtered by the classroom_id
  const classroomAssignment = (assignment?.classroom_assignments || [])?.[0];

  const copyShareLink = () => {
    copyToClipboard(`${constants.ALBERT_FULL_PATH}/assignment/${assignment.id}`, () => {
      addToast({
        color: 'positive',
        title: 'Link Copied!',
        message: 'The link to this assignment has been copied to your clipboard.'
      });
    });
  };

  if (!assignment.id || !classroomAssignment) {
    return null;
  }

  const {classroom_id: classroomId} = classroomAssignment;

  const handleDeleteAssignment = async () => {
    const query = {
      resourcePath: ['assignments_v3', assignment.id, 'relationships', 'classrooms_v1']
    };
    await genericMandarkRequest('delete', query, {
      data: [
        {
          id: classroomId,
          type: 'classrooms_v1'
        }
      ]
    });
    fetchData(undefined);
  };

  return (
    <>
      {showReassignModal && (
        <CustomizeReassignModal
          assignmentId={assignment.id}
          assignmentName={assignment.name}
          handleClose={() => setShowReassignModal(false)}
        />
      )}
      {showModal && (
        <ConfirmActionModal
          handleClose={() => setShowModal(false)}
          title='Are you sure?'
          size='s'
          makeRequest={handleDeleteAssignment}
          hideCloseBtn
          onResolve={() => {
            invalidatePartialInterest({resourcePath: ['assignments_v3']});
          }}
        >
          {({isRequestInflight, onCancel, onConfirm}) => (
            <>
              <Dialogue.Body align='left'>
                <p>
                  You are about to remove this class from <strong>{assignment.name}</strong>. The
                  assignment will still exist in any other classes it&apos;s assigned to. This
                  action cannot be undone.
                </p>
              </Dialogue.Body>
              <Dialogue.BtnGroup align='right' className='u-display_inline'>
                <Button color='secondary' disabled={isRequestInflight} onClick={onCancel}>
                  Cancel
                </Button>
                <Button color='negative' disabled={isRequestInflight} onClick={onConfirm}>
                  Delete
                </Button>
              </Dialogue.BtnGroup>
            </>
          )}
        </ConfirmActionModal>
      )}
      <Dropdown
        trigger={
          <Button variant='text'>
            Actions
            <Icon icon='caret-down' className='u-mar-l_1' />
          </Button>
        }
        positionFixed
        trayClassName='assignment-actions__dropdown-tray'
      >
        <DropdownItem as='button' onClick={() => setShowReassignModal(true)} icon='redo-alt'>
          Reassign
        </DropdownItem>
        <DropdownItem
          as='button'
          icon={['far', 'cog']}
          onClick={() => {
            const editUrl = `/assignment-builder/create-assignment/${assignment.id}/settings`;

            history.pushState(null, editUrl);
          }}
          data-testid='assignment-actions__edit-btn'
        >
          Edit
        </DropdownItem>
        <DropdownItem as='button' onClick={copyShareLink} icon='link'>
          Share
        </DropdownItem>
        {!isAllClassesTab && (
          <DropdownItem
            as='button'
            icon={['far', 'trash-alt']}
            className='u-color_red-500'
            textProps={{color: 'negative'}}
            iconColor='negative'
            onClick={() => {
              setShowModal(true);
            }}
          >
            Delete
          </DropdownItem>
        )}
      </Dropdown>
    </>
  );
};

export default AssignmentActions;
