import {pushQueryParams, getQueryParamsAsObject, removeQueryParams} from 'client/history';
import makeConstants from 'lib/makeConstants';

const sortLabels = makeConstants('difficulty', 'random');
export const directions = makeConstants('asc', 'desc');

export const sorts = {
  [sortLabels.difficulty]: {
    ordered: true,
    isActive: () => getCurrentSort()?.startsWith(sortLabels.difficulty),
    apply: (query) => {
      const prefix = getCurrentSort()?.endsWith(directions.desc) ? '-' : '';
      return query.sort(`${prefix}difficulty`);
    }
  },
  [sortLabels.random]: {
    ordered: false,
    isActive: () => getCurrentSort()?.startsWith(sortLabels.random),
    apply: (query) => {
      const seed = /\d+$/.exec(getCurrentSort());
      if (!seed) {
        return query;
      }
      const asDecimal = parseFloat(`0.${seed[0]}`, 10);
      return query.customQuery({random_seed: asDecimal});
    }
  }
};

export function clearSortValue() {
  removeQueryParams('sort');
}

export function setSortValue(label, postfix) {
  if (!label) {
    return;
  }
  const builtSort = makeOrderedSort(label, postfix);
  if (getCurrentSort() === builtSort) {
    return;
  }
  pushQueryParams({sort: builtSort});
}

export function getCurrentSort() {
  const params = getQueryParamsAsObject();
  return params.sort;
}

export function makeOrderedSort(label, direction) {
  return [label, direction].join('-');
}

export function applySort(query, defaultSort) {
  const sort = Object.values(sorts).find((sortValue) => sortValue.isActive());
  if (!sort && !defaultSort) {
    return query;
  }
  return sort ? sort.apply(query) : query.sort(defaultSort);
}
