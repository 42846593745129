// @flow
import {List} from 'immutable';
import type {QuestionSet as QuestionSetModelV1} from 'resources/GeneratedModels/QuestionSet/QuestionSetModel.v1';

export default function getQuestionDifficultiesFromQuestionSets(
  questionSets: List<QuestionSetModelV1>
): List<string> {
  return questionSets.reduce((acc, curr) => {
    curr.getQuestions().map((question) => {
      acc = acc.push(question.getDifficulty());
    });
    return acc;
  }, new List());
}
