/* eslint-disable no-underscore-dangle */
import {fromJS} from 'immutable';
import request from 'superagent';
import moment from 'moment';
import {handleMandarkErrorResponse} from 'resources/mandark.resource';
import {Store} from 'client/framework';
import constants from 'client/constants';

import systemTimeOffsetActions from './SystemTimeOffset.actions';

class SystemTimeOffsetStore extends Store {
  constructor(name) {
    super(name);
    this.initialState = fromJS({
      offset: null
    });
    this.setInitialData(this.initialState);
    this.handle(systemTimeOffsetActions.INIT_STORE, this._initStore);
    this.handle(systemTimeOffsetActions.UPDATE_OFFSET, this._updateOffset);
    /**
     * Since we only call `systemTimeOffsetActions.INIT_STORE` on `componentDidMount` (`App.react.js`)
     * we need to make sure the value is set for the server rendering context. This means that the initial
     * offset will be set for the difference between the two servers (which _should_ be `0`).
     *
     * This also expects the `INIT_STORE` action handler to overwrite the store's value _unconditionally_.
     */
    this._setServerInitFunction(this._initStore);
  }

  /**
   * @returns {Promise}
   */
  async _initStore() {
    try {
      const response = await request.get(`${constants.MANDARK_FULL_PATH}/`);
      this._updateOffset(response.headers.date);
    } catch (err) {
      handleMandarkErrorResponse(err);
    }
  }

  /**
   * 
   * @param {string} date 
   */
  _updateOffset(date) {
    const offset = moment.duration(moment().diff(moment(date)));
    this.writeData('offset', offset.asMilliseconds());
  }

  /**
   * @returns {?number}
   */
  getSystemTimeOffset() {
    return this.readData('offset');
  }

  /**
   * @returns {import('moment').Moment}
   */
  getCurrentTime() {
    const offset = this.getSystemTimeOffset();
    return moment().subtract(offset, 'milliseconds');
  }
}

export default new SystemTimeOffsetStore('SystemTimeOffsetStore');
