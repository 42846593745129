import React, {useEffect, useRef} from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {Link} from 'react-router';

import useArrowNavigation from 'lib/hooks/useArrowNavigation';

import {TabNavigation} from '@albert-io/atomic';
import appStore from 'client/AppStore';

import './subgroup-menus.scss';

export default function SubgroupMenu({content, activeGroup}) {
  const tabbedItems = useRef(content.map((item) => item.get('to')));
  const {cursor, onKeyDown} = useArrowNavigation(tabbedItems.current);
  const tabsRef = useRef(new Array(content.size));
  const hasUserFocusedRef = useRef(false);

  const handleKeyDown = (e, currentIndex) => {
    e.persist();
    onKeyDown(e, currentIndex);
  };

  useEffect(() => {
    if (hasUserFocusedRef.current && tabsRef.current[cursor]) {
      tabsRef.current[cursor].focus();
    }
  }, [cursor]);

  return (
    <TabNavigation
      align='left'
      as='ul'
      className='u-list-style_none u-pad_0 u-width_100pc u-mar-b_0'
      paddingLevel={0}
      role='tablist'
    >
      {content.map((item, index) => {
        const isActive =
          appStore.routerProps.location.pathname === item.get('to') ||
          (!activeGroup && item.get('label') === 'All') ||
          activeGroup === item.get('label');
        return (
          <TabNavigation.Tab key={item.get('to')} as='li' isActive={isActive} role='presentation'>
            <Link
              className={classnames('subgroup-menu__tab__link u-text-decoration_none u-pad_2', {
                'u-color_blue-500': isActive,
                'subgroup-menu__tab__link--inactive': !isActive
              })}
              tabIndex={cursor === index ? 0 : -1}
              innerRef={(el) => {
                tabsRef.current[index] = el;
              }}
              to={item.get('to')}
              onClick={item.get('onClick')}
              role='tab'
              onKeyDown={(e) => handleKeyDown(e, index)}
              aria-current={isActive ? 'page' : undefined}
              aria-selected={isActive}
              onFocus={() => {
                if (!hasUserFocusedRef.current) {
                  hasUserFocusedRef.current = true;
                }
              }}
            >
              {item.get('label')}
            </Link>
          </TabNavigation.Tab>
        );
      })}
    </TabNavigation>
  );
}

SubgroupMenu.propTypes = {
  content: ImmutablePropTypes.list,
  activeGroup: PropTypes.string
};
