import {createContext} from 'react';

import {WSStore} from '../FreeResponseQuestion.types';

interface WSInterface {
  store: null | WSStore;
  storeName: null | string;
  submitted?: boolean;
}

const WrittenSubmissionContext = createContext<WSInterface>({
  store: null, // default value
  storeName: null,
  submitted: false
});

export default WrittenSubmissionContext;
