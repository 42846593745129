import React from 'react';
import {IconButton, LoadingSpinner, Text} from '@albert-io/atomic';
import './questions-list-paginator.scss';

interface Props {
  currentPage: number;
  totalPages: number;
  isPreviousDisabled: boolean;
  isRequestInFlight: boolean;
  handlePreviousPage: () => void;
  isNextDisabled: boolean;
  handleNextPage: () => void;
}

const QuestionsListPaginator = ({
  currentPage,
  totalPages,
  isPreviousDisabled,
  handlePreviousPage,
  isNextDisabled,
  handleNextPage,
  isRequestInFlight
}: Props) => {
  return (
    <div className='questions-list-paginator-v2 u-pad_1'>
      {isRequestInFlight && (
        <LoadingSpinner className='questions-list-paginator-v2__loading-indicator' size={2} />
      )}
      <IconButton
        disabled={isPreviousDisabled}
        onClick={isPreviousDisabled ? undefined : handlePreviousPage}
        icon='caret-left'
        size='s'
        label='Previous page'
      />
      <Text className='questions-list-paginator-v2__label'>
        {currentPage} / {totalPages}
      </Text>
      <IconButton
        disabled={isNextDisabled}
        onClick={isNextDisabled ? undefined : handleNextPage}
        icon='caret-right'
        size='s'
        label='Next page'
      />
    </div>
  );
};

export default QuestionsListPaginator;
