import React from 'react';
import PropTypes from 'prop-types';
import {Map} from 'immutable';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {setUpStore, callTargetedAction} from 'client/framework';
import passageCorrectionQuestionActions from './PassageCorrectionQuestion.actions';
import PassageCorrectionQuestionStore from './PassageCorrectionQuestion.store';
import PassageCorrectionPreSubmissionRenderer from './PassageCorrectionPreSubmissionRenderer.react';
import PassageCorrectionPostSubmissionRenderer from './PassageCorrectionPostSubmissionRenderer.react';
import {questionPropType} from 'resources/Question/Question.model';
import './passage-correction-question.scss';

export default class PassageCorrectionQuestion extends React.Component {
  static propTypes = {
    storeName: PropTypes.string,
    questionId: PropTypes.string,
    question: questionPropType,
    isGuessSubmitted: PropTypes.bool
  };

  componentDidMount() {
    this.setInitialCorrections();
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (this.getStore().getName() !== nextProps.storeName) {
      const nextStore = this.getStore(nextProps.storeName, nextProps.questionId);
      this.setInitialCorrections(nextStore);
    }
  }

  initialCorrections = Map();

  getStore(storeName = this.props.storeName, questionId = this.props.questionId) {
    return setUpStore(PassageCorrectionQuestionStore, storeName, questionId);
  }

  addWordToInitialCorrection = ({text, matchedRegionIndex, optionIndex}) => {
    if (matchedRegionIndex === undefined || this.getStore().hasInitialCorrections()) {
      return;
    }
    this.initialCorrections = this.initialCorrections.setIn(
      [matchedRegionIndex, optionIndex],
      text
    );
  };

  setInitialCorrections(store = this.getStore()) {
    if (store.hasInitialCorrections()) {
      return;
    }
    callTargetedAction({
      name: passageCorrectionQuestionActions.SET_INITIAL_CORRECTIONS,
      payload: this.initialCorrections,
      targetStore: store.getName()
    });
    this.initialCorrections = Map();
  }

  render() {
    const {question, isGuessSubmitted} = this.props;
    return (
      <div className='passage-correction-question'>
        <MarkdownRendererV2 text={question.getPrompt()} />
        <h2 className='question__prompt-heading'>
          {!isGuessSubmitted
            ? 'Click the word(s) you want to edit in the sentence below and type your changes.'
            : 'Click highlighted areas for more information.'}
        </h2>
        {!isGuessSubmitted ? (
          <PassageCorrectionPreSubmissionRenderer
            {...this.props}
            onRenderWordFunc={this.addWordToInitialCorrection}
            isGuessSubmitted={isGuessSubmitted}
          />
        ) : (
          <PassageCorrectionPostSubmissionRenderer
            {...this.props}
            onRenderWordFunc={this.addWordToInitialCorrection}
          />
        )}
      </div>
    );
  }
}
