import React, {useEffect, useState, ReactElement, ReactNode} from 'react';
import {genericMandarkRequest} from 'resources/mandark.resource';
import {query} from '@albert-io/json-api-framework/request/builder';
import {Map} from 'immutable';

let FEATURE_FLAG_CACHE = Map();

export async function getFeatureFlagStatus(name: string) {
  try {
    if (FEATURE_FLAG_CACHE.has(name)) {
      return FEATURE_FLAG_CACHE.get(name);
    }
    const data = await genericMandarkRequest(
      'get',
      query().mandarkEndpoint(['json', 'platform', 'feature_flags', name]).done()
    );
    const isEnabled = data.get('enabled');
    FEATURE_FLAG_CACHE = FEATURE_FLAG_CACHE.set(name, isEnabled);
    return isEnabled;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return false;
  }
}

export const useIsFeatureFlagEnabled = (name: string) => {
  const [isFeatureEnabled, setIsFeatureEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        const isEnabled = await getFeatureFlagStatus(name);
        setIsFeatureEnabled(isEnabled);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [name]);

  return {isFeatureEnabled, isLoading};
};

interface Props extends PropsWithChildrenRequired {
  name: string;
}

const FeatureFlag = ({name, children}: Props) => {
  const {isFeatureEnabled, isLoading} = useIsFeatureFlagEnabled(name);

  if (isLoading) {
    return (
      <>{React.Children.map(children, (child) => (isLoadingComponent(child) ? child : null))}</>
    );
  }
  if (!isFeatureEnabled) {
    return (
      <>{React.Children.map(children, (child) => (isFallbackComponent(child) ? child : null))}</>
    );
  }
  return (
    <>
      {React.Children.map(children, (child) => {
        if (isLoadingComponent(child)) {
          return null;
        }
        if (isFallbackComponent(child)) {
          return null;
        }
        return child;
      })}
    </>
  );
};

const isReactComponent = (child: ReactNode): child is ReactElement<PropsWithChildrenOptional> => {
  return (
    React.isValidElement(child) &&
    typeof (child.type as any) === 'function' &&
    (child.type as any) === Loading
  );
};

const isFallbackComponent = (
  child: ReactNode
): child is ReactElement<PropsWithChildrenOptional> => {
  return (
    React.isValidElement(child) &&
    typeof (child.type as any) === 'function' &&
    (child.type as any) === Fallback
  );
};

const isLoadingComponent = (
  child: React.ReactNode
): child is React.ReactElement<PropsWithChildrenRequired> => {
  return isReactComponent(child) && (child as any).type === Loading;
};

const Loading = ({children}: PropsWithChildrenRequired) => <>{children}</>;

const Fallback = ({children}: PropsWithChildrenRequired) => <>{children}</>;

FeatureFlag.Loading = Loading;
FeatureFlag.Fallback = Fallback;

export default FeatureFlag;
