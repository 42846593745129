import {values} from 'lodash';
import {getEnvironmentVariable, getOrigin, getHost as getMandarkHost} from '@albert-io/environment';

const domains = [
  'DEBUG',
  'Test Prep',
  'Foundations',
  'Humanities',
  'Math',
  'Professional',
  'Science',
  'Social Sciences'
];

const disclaimerText = {
  ap: `AP® and Advanced Placement® are registered trademarks of the College Board,
  which was not involved in the production of, and does not endorse, this product. `,
  sat: `SAT® is a registered trademark of the College Board, which was not involved in
  the production of, and does not endorse this product. `,
  act: `ACT® is a registered trademark of ACT, Inc., which was not involved in the production of,
  and does not endorse, this product. `,
  gmat: `GMAT® is a registered trademark of the Graduate Management Admission Council®,
  which was not involved in the production of, and does not endorse, this product. `,
  gre: `GRE® is a registered trademark of Educational Testing Services (ETS),
  which was not involved in the production of, and does not endorse, this product. `
};

const CONSTANTS = {
  // Use 427606818638-p01lmj1a5stc07s89050focol07trn6c.apps.googleusercontent.com for local
  GCLOUD_CLIENT_ID: getEnvironmentVariable('GCLOUD_CLIENT_ID'),
  USER_ASSETS_ORIGIN: getEnvironmentVariable('USER_ASSETS_ORIGIN'),
  ERROR_TYPES: {
    CRITICAL: -1,
    GENERIC: 0,
    NOT_FOUND: 1,
    UNAUTHORIZED: 2
  },
  STRIPE_PUBLIC_KEY: getEnvironmentVariable('STRIPE_PUBLIC_KEY'),
  /**
   * Both `INTERCOM_APP_ID_`s default to the _production_ value.
   */
  INTERCOM_APP_ID_TEACHERS: getEnvironmentVariable('INTERCOM_APP_ID_TEACHERS') || 'a3fe58u1',
  ALBERT_TIMEOUT: 60000,
  ALBERT_VERSION_CHECK_INTERVAL: 2 * 60 * 1000,
  ALBERT_VERSION_TOAST_NOTIFY_DELAY: 5 * 60 * 1000,
  ALBERT_FULL_PATH: getOrigin(),
  /**
   * @todo References to `MANDARK_FULL_PATH` will be removed in a future PR; We can use
   * `getMandarkHost` in calling code directly.
   */
  MANDARK_FULL_PATH: getMandarkHost(),
  MANDARK_MAX_PAGE_SIZE: 250,
  FULL_DISCLAIMER: `*${values(disclaimerText).join('')}`,
  DOMAINS: domains,
  REGEX_COLLECTION: {
    PASSWORD: /(^.*(?=.{8,})(?=.*[!@#$%^&*?{}[\]|\\:;"'<,>.?/~`()\-+=_0-9]).*$)/,
    USERNAME: /^([a-zA-Z0-9_]){4,16}$/
  },
  /**
   * Some requests are too intensive for larger classroom sizes, so we have blocked off certain
   * classroom functionality based on classroom size to prevent request errors.
   *
   * @see https://github.com/albert-io/project-management/issues/5822
   */
  MEGA_CLASSES_SIZE: 500,
  CLASSROOM_STATUSES: {
    ACTIVE: 'active',
    ARCHIVED: 'archived'
  },
  N_LEVELS: {
    1: 'theme',
    2: 'topic',
    3: 'subtopic'
  },
  GUIDE_TYPES: {
    ASSESSMENT: 'assessment',
    PRACTICE: 'practice',
    FREE_RESPONSE: 'free_response'
  },
  QUESTION_TYPES: {
    FILL_IN_THE_BLANK: {name: 'fill-in-the-blank', title: 'Fill In The Blank'},
    FREE_ENTRY: {name: 'free-entry', title: 'Free Entry'},
    MULTIPLE_CHOICE: {name: 'multiple-choice', title: 'Multiple Choice'},
    TEXT_HIGHLIGHT: {name: 'text-highlight', title: 'Text Highlight'},
    TWO_WAY: {name: 'two-way', title: 'Two Way'},
    SNIPPET_SELECTION: {name: 'snippet-selection', title: 'Snippet Selection'},
    PASSAGE_CORRECTION: {name: 'passage-correction', title: 'Passage Correction'},
    FREE_RESPONSE: {name: 'free-response', title: 'Free Response'},
    GRAPH_CONTAINS: {name: 'graph-contains', title: 'Graphing'}
  },
  // @todo: Clean this up once status codes go away in dev.
  QUESTIONSET_STATUS: {
    REJECTED: 0,
    DRAFT_MODE: 1,
    IN_REVIEW: 2,
    PUBLISHED: 3
  },
  QUESTIONSET_STATUSES: {
    REJECTED: 'rejected',
    DRAFT_MODE: 'draft',
    IN_REVIEW: 'pending',
    HIDDEN: 'hidden',
    PUBLISHED: 'published',
    ARCHIVED: 'archived'
  },
  QUESTION_IMPORT: {
    DIFFICULTIES: {
      Easy: 1,
      Moderate: 2,
      Difficult: 3
    }
  },
  ZONES: {
    ALBERT: 'ALBERT',
    DENNIS: 'DENNIS'
  },
  MOBILE_VIEW_BREAKPOINTS: {
    DEVICE_SMALL: 600, // comes from _variables.scss, var $device-small
    DEVICE_MEDIUM: 900 // comes from _variables.scss, var $device-medium
  },
  KEYMAP: {
    ENTER: 13,
    ESCAPE: 27,
    UP_ARROW: 38,
    DOWN_ARROW: 40
  },
  CONTACT: {
    EMAIL: {
      SALES: 'schools@albert.io',
      SUPPORT: 'hello@albert.io',
      SUCCESS: 'success@albert.io'
    },
    PHONE: {
      FAX: '312-313-2667',
      NUMBER: '1-312-470-2290',
      EXTENSION: {
        SUPPORT: '0',
        SALES: '1',
        BILLING: '2'
      }
    }
  },
  SIGN_UP_METHODS: {
    DENNIS_WITH_EMAILS: 'dennis-with-email',
    DENNIS_WITHOUT_EMAILS: 'dennis-without-email',
    GOOGLE: 'google',
    FACEBOOK: 'facebook',
    EMAIL: 'email'
  },
  BREAKPOINTS: {
    xxs: 380,
    xs: 440,
    s: 780,
    m: 1020,
    l: 1280,
    xl: 1400
  }
};

/**
 * @todo these named exports are used to ensure current callsites work as expected.
 * we should probably update this to either use all named exports or the `default`.
 */
export const {QUESTION_TYPES} = CONSTANTS;
export const {QUESTIONSET_STATUS} = CONSTANTS;
export const {QUESTIONSET_STATUSES} = CONSTANTS;
export const {N_LEVELS} = CONSTANTS;
export const {SIGN_UP_METHODS} = CONSTANTS;
export const {ERROR_TYPES} = CONSTANTS;
export const {QUESTION_IMPORT} = CONSTANTS;
export const {MANDARK_MAX_PAGE_SIZE} = CONSTANTS;
export const {DOMAINS} = CONSTANTS;
export const {GUIDE_TYPES} = CONSTANTS;
export const {MEGA_CLASSES_SIZE} = CONSTANTS;
export const {CLASSROOM_STATUSES} = CONSTANTS;
export const {BREAKPOINTS} = CONSTANTS;

export default CONSTANTS;
