import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {callAction} from 'client/framework';
import {history} from 'client/history';
import {addToast} from '@albert-io/atomic';

import appStore from 'client/AppStore';
import sessionStore from 'client/Session/SessionStore';

import questionsListActions from 'client/InteractionEngineV2/shared/QuestionsList/QuestionsListActions';

import SimpleModal from 'generic/SimpleModal/SimpleModal.react';
import LoadingIndicator from 'generic/LoadingIndicator.react';

import {invalidatePartialInterest} from 'resources/mandark.resource';
import {invalidateBlockedAssignmentQuestionSetsQuery} from 'client/SubjectPracticeView/subjectPracticeView.queries';

import studentAssignmentQuestionsListStore from '../StudentAssignmentQuestionsList/StudentAssignmentQuestionsList.store';
import studentAssignmentQuestionsListActions from '../StudentAssignmentQuestionsList/StudentAssignmentQuestionsList.actions';

import abandonAssignmentModalActions from './AbandonAssignmentModal.actions';
import abandonAssignmentModalStore from './AbandonAssignmentModal.store';
import './abandon-modal.scss';

class AbandonAssignmentModal extends React.Component {
  static propTypes = {
    router: PropTypes.object.isRequired
  };

  componentDidMount() {
    const {router} = this.props;
    const {setRouteLeaveHook} = router;

    setRouteLeaveHook(appStore.routerProps.routes.slice(-1)[0], this.routerShouldAdvance);
  }

  componentWillUnmount() {
    callAction(abandonAssignmentModalActions.RESET_STORE);
  }

  routerShouldAdvance(nextState) {
    /**
     * The should advance machinery only works if a user is logged in.
     *
     * If a user has logged out, and is being redirected to `/`, we need
     * to skip all the checks occuring here and let them "advance".
     */
    if (!sessionStore.hasValidSession()) {
      return true;
    }

    const assignment = studentAssignmentQuestionsListStore.getAssignment();
    const shouldStopUserAndShowWarning =
      assignment.hasStudentStartedAssignment(sessionStore.getUserId()) &&
      assignment.canAssignmentBeSubmitted() &&
      !abandonAssignmentModalStore.shouldAdvanceRouterPostSubmission() &&
      studentAssignmentQuestionsListStore.hasActiveQuestion &&
      !abandonAssignmentModalStore.hasConfirmedWillLeave();
    if (shouldStopUserAndShowWarning) {
      callAction(abandonAssignmentModalActions.SET_NEXT_PATH_NAME, nextState.pathname);
      callAction(abandonAssignmentModalActions.TOGGLE_ABANDON_MODAL_ACTIVE, true);
      return false;
    }
    return true;
  }

  handleShowQuestions = () => {
    callAction(abandonAssignmentModalActions.CONFIRM_WILL_LEAVE, false);
    callAction(studentAssignmentQuestionsListActions.FILTER_BY_UNANSWERED_QUESTIONS);
    callAction(abandonAssignmentModalActions.TOGGLE_ABANDON_MODAL_ACTIVE, false);
  };

  handleLeaveAnyway = () => {
    callAction(abandonAssignmentModalActions.CONFIRM_WILL_LEAVE, true);
    callAction(abandonAssignmentModalActions.TOGGLE_ABANDON_MODAL_ACTIVE, false);
    history.pushState(null, abandonAssignmentModalStore.getNextPathName());
  };

  hideModal = () => {
    callAction(abandonAssignmentModalActions.TOGGLE_ABANDON_MODAL_ACTIVE, false);
  };

  async handleSubmitAssignment() {
    try {
      await abandonAssignmentModalStore.getSubmitPromise();

      addToast({
        message: 'Your assignment has been submitted',
        title: 'Success!',
        color: 'positive'
      });
      callAction(abandonAssignmentModalActions.CONFIRM_WILL_LEAVE, true);
      callAction(questionsListActions.CLEAR_ACTIVE_QUESTION);
      callAction(studentAssignmentQuestionsListActions.RESET_STORE);
      callAction(abandonAssignmentModalActions.RESET_STORE);
      invalidatePartialInterest({customQuery: {with_meta: 'student_assignment_v1'}});
      invalidateBlockedAssignmentQuestionSetsQuery();
      // invalidate student list queries
      invalidatePartialInterest({
        resourcePath: ['assignments_v3'],
        filter: {
          classrooms_v1: appStore.routerProps.params.classId,
          students_v1: sessionStore.getUserId(),
          assignment_type: 'assignment'
        }
      });
    } catch (error) {
      addToast({
        message: 'There was a problem with submitting your assignment.',
        title: 'Something went wrong',
        color: 'negative'
      });
    }
  }

  render() {
    if (!abandonAssignmentModalStore.isModalActive()) {
      return null;
    }

    if (!abandonAssignmentModalStore.getStudentQuery().isResourcePopulated()) {
      return (
        <SimpleModal>
          <LoadingIndicator />
        </SimpleModal>
      );
    }

    const questionCount = studentAssignmentQuestionsListStore.getAssignment().getCountOfQuestions();
    const questionsAnswered = studentAssignmentQuestionsListStore
      .getAssignment()
      .getMeta()
      .getStudentCountOfGuesses();
    const questionsUnansweredTotal = questionCount - questionsAnswered;

    const leaveAnywayLink = abandonAssignmentModalStore.getNextPathName() ? (
      // we shouldn't use anchors as buttons
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        role='button'
        tabIndex={0}
        className='abandon-modal__link'
        onClick={this.handleLeaveAnyway}
      >
        Leave anyway
      </a>
    ) : null;

    return (
      <SimpleModal
        bodyWrapperClassName='abandon-modal'
        onModalClose={this.hideModal}
        title='You have unanswered questions'
      >
        <div className='abandon-modal__wrapper'>
          <span className='abandon-modal__warning fa fa-warning' role='presentation' />
          <div className='abandon-modal__message-wrapper'>
            You left{' '}
            <strong>
              {questionsUnansweredTotal} question{questionsUnansweredTotal === 1 ? '' : 's'}{' '}
              unanswered
            </strong>
            .<br />
            What would you like to do?
          </div>
          <div className='abandon-modal__action-wrapper'>
            <button
              className='abandon-modal__action abandon-modal__action--show-questions'
              onClick={this.handleShowQuestions}
              type='button'
            >
              Show unanswered questions
            </button>
            <button
              className='abandon-modal__action abandon-modal__action--submit'
              onClick={this.handleSubmitAssignment}
              type='button'
            >
              Submit assignment
            </button>
          </div>
          {leaveAnywayLink}
        </div>
      </SimpleModal>
    );
  }
}

export default withRouter(AbandonAssignmentModal);
