import React from 'react';
import {callAction} from 'client/framework';
import {Link} from 'react-router';
import classnames from 'classnames';
import questionsListSettingsActions from './QuestionsListSettings.actions';
import questionsListSettingsStore, {FILTER_OPTIONS} from './QuestionsListSettings.store';
import sessionStore from 'client/Session/SessionStore';
import './questions-list-settings.scss';
import CheckboxToggle from 'sg/CheckboxToggle/CheckboxToggle.react';
import interactionEngineActions from 'client/InteractionEngineV2/InteractionEngineActions';
import topicSummaryStore from '../../TopicSummary/TopicSummary.store';

export default class QuestionsListSettings extends React.Component {
  constructor() {
    super();
    this.wrapperNode = null;
    this.formSizerNode = null;
  }

  componentWillUnmount() {
    this.closeSettingsPane();
  }

  toggleExpandSettings = (e) => {
    e.stopPropagation();
    if (questionsListSettingsStore.isOpen()) {
      this.closeSettingsPane();
    } else {
      callAction(questionsListSettingsActions.SET_IS_OPEN, true);
      global.addEventListener('click', this.handleClickOutsideComponent);
    }
  };

  handleClickOutsideComponent = (e) => {
    if (!this.wrapperNode.contains(e.target)) {
      this.closeSettingsPane();
    }
  };

  setSortBy = (e) => {
    callAction(questionsListSettingsActions.SET_SORT_BY, e.target.value);
  };

  makeRef = (node) => {
    if (node) {
      this[node.dataset.refName] = node;
    }
  };

  closeSettingsPane() {
    callAction(questionsListSettingsActions.SET_IS_OPEN, false);
    global.removeEventListener('click', this.handleClickOutsideComponent);
  }

  toggleDifficulty(e) {
    callAction(questionsListSettingsActions.TOGGLE_DIFFICULTY, parseInt(e.target.value, 10));
  }

  toggleIncludeAnswerStatus(e) {
    callAction(questionsListSettingsActions.TOGGLE_INCLUDE_ANSWER_STATUS, e.target.value);
  }

  showMobileQuestionArea() {
    callAction(interactionEngineActions.SHOW_MOBILE_QUESTION_AREA);
  }

  render() {
    const isSettingsPaneOpen = questionsListSettingsStore.isOpen();
    const difficulties = questionsListSettingsStore.getDifficulties();
    const sortBy = questionsListSettingsStore.getSortBy();
    const includedAnswerStatuses = questionsListSettingsStore.getIncludedAnswerStatuses();
    const settingsFormHeight = this.formSizerNode
      ? `${this.formSizerNode.firstChild.clientHeight}px`
      : '0px';
    const viewCopy = topicSummaryStore.isTopicSummaryPath() ? 'View Question' : 'View Overview';
    const toggleButtonIconClasses = classnames({
      'questions-list-settings__toggle-icon unbutton': true,
      'fa fa-times': isSettingsPaneOpen,
      'fa fa-cog': !isSettingsPaneOpen
    });
    const topicDescription = topicSummaryStore.isTopicSummaryValid() ? (
      <Link
        className='questions-list-settings__overview-link'
        to={topicSummaryStore.getTopicSummaryLinkPath()}
        onClick={this.showMobileQuestionArea}
      >
        <span className='fa fa-info-circle' /> {viewCopy}
      </Link>
    ) : null;
    return (
      <div className='questions-list-settings' data-ref-name='wrapperNode' ref={this.makeRef}>
        <div className='questions-list-settings__toggle'>
          <div className='questions-list-settings__header-title'>{topicDescription}</div>
          <button
            aria-label='Toggle settings'
            className={toggleButtonIconClasses}
            onClick={this.toggleExpandSettings}
          />
        </div>
        <div
          className='questions-list-settings__form-sizer'
          data-ref-name='formSizerNode'
          ref={this.makeRef}
          style={isSettingsPaneOpen ? {height: settingsFormHeight} : null}
        >
          <form
            className='questions-list-settings__settings-form'
            onSubmit={(e) => e.preventDefault()}
          >
            <div className='questions-list-settings__label'>Sort By</div>
            <label className='questions-list-settings__input-wrapper questions-list-settings__input-wrapper--radio'>
              <input
                id='sort-easy-to-difficult'
                type='radio'
                name='sort-by'
                onChange={this.setSortBy}
                value={FILTER_OPTIONS.SORT.ASCENDING}
                checked={sortBy === FILTER_OPTIONS.SORT.ASCENDING}
              />{' '}
              Easy to Difficult
              <span className='fancy-radio' />
            </label>
            <label className='questions-list-settings__input-wrapper questions-list-settings__input-wrapper--radio'>
              <input
                id='sort-difficult-to-easy'
                type='radio'
                name='sort-by'
                onChange={this.setSortBy}
                value={FILTER_OPTIONS.SORT.DESCENDING}
                checked={sortBy === FILTER_OPTIONS.SORT.DESCENDING}
              />{' '}
              Difficult to Easy
              <span className='fancy-radio' />
            </label>
            <label className='questions-list-settings__input-wrapper questions-list-settings__input-wrapper--radio'>
              <input
                id='sort-random'
                type='radio'
                name='sort-by'
                onChange={this.setSortBy}
                value={FILTER_OPTIONS.SORT.RANDOM}
                checked={sortBy === FILTER_OPTIONS.SORT.RANDOM}
              />{' '}
              Random
              <span className='fancy-radio' />
            </label>
            <div className='questions-list-settings__label'>
              Difficulty Filters
              <span className='fancy-radio' />
            </div>
            <div>
              <CheckboxToggle
                checked={difficulties.includes(FILTER_OPTIONS.DIFFICULTIES.EASY)}
                className='questions-list-settings__input-wrapper'
                label='Easy Questions'
                onChange={this.toggleDifficulty}
                value={FILTER_OPTIONS.DIFFICULTIES.EASY}
              />
              <CheckboxToggle
                checked={difficulties.includes(FILTER_OPTIONS.DIFFICULTIES.MODERATE)}
                className='questions-list-settings__input-wrapper'
                label='Moderate Questions'
                onChange={this.toggleDifficulty}
                value={FILTER_OPTIONS.DIFFICULTIES.MODERATE}
              />
              <CheckboxToggle
                checked={difficulties.includes(FILTER_OPTIONS.DIFFICULTIES.DIFFICULT)}
                className='questions-list-settings__input-wrapper'
                label='Difficult Questions'
                onChange={this.toggleDifficulty}
                value={FILTER_OPTIONS.DIFFICULTIES.DIFFICULT}
              />
            </div>
            {sessionStore.hasValidSession() ? (
              <div>
                <div className='questions-list-settings__label'>Answer Filters</div>
                <div>
                  <CheckboxToggle
                    checked={includedAnswerStatuses.includes(FILTER_OPTIONS.ANSWER_STATUS.ANSWERED)}
                    className='questions-list-settings__input-wrapper'
                    label='Answered Questions'
                    onChange={this.toggleIncludeAnswerStatus}
                    value={FILTER_OPTIONS.ANSWER_STATUS.ANSWERED}
                  />
                  <CheckboxToggle
                    checked={includedAnswerStatuses.includes(
                      FILTER_OPTIONS.ANSWER_STATUS.UNANSWERED
                    )}
                    className='questions-list-settings__input-wrapper'
                    label='Unanswered Questions'
                    onChange={this.toggleIncludeAnswerStatus}
                    value={FILTER_OPTIONS.ANSWER_STATUS.UNANSWERED}
                  />
                </div>
              </div>
            ) : null}
          </form>
        </div>
      </div>
    );
  }
}
