/**
 * # NavItem
 *
 * React component that represents a navigation item.
 *
 * ## Props
 *
 * - `as?: React.ElementType`: The wrapper element type. Defaults to `Link`.
 * - `'aria-label': string`: The ARIA label for accessibility.
 * - `active: boolean`: Whether the navigation item is active.
 * - `disabled: boolean`: Whether the navigation item is disabled.
 * - `icon: IconProp`: The icon to be displayed.
 * - `iconPosition: 'left' | 'right'`: The position of the icon.
 * - `iconStyle: IconStyle`: The style of the icon.
 * - `children: React.ReactNode`: The content of the navigation item.
 * - `className: string`: Additional CSS class names.
 * - `stateless: boolean`: Whether the navigation item is stateless.
 *
 * ## Usage
 *
 * ```tsx
 * import NavItem from './NavItem';
 *
 * const Example = () => {
 *   return (
 *     <NavItem
 *       'aria-label'="Example Nav Item"
 *       active={true}
 *       disabled={false}
 *       icon="example-icon"
 *       iconPosition="left"
 *       iconStyle="regular"
 *       className="example-class"
 *       stateless={false}
 *     >
 *       Example Navigation Item
 *     </NavItem>
 *   );
 * };
 * ```
 */

import React, {ElementType, forwardRef} from 'react';
import classnames from 'classnames';
import {Link} from 'react-router';
import makeConstants from 'lib/makeConstants';

import Icon, {IconStyle, IconProp} from '../Icon/Icon.react';

import './nav-item.scss';

const positions = ['left', 'right'] as const;

const position = makeConstants(...positions);

interface Props extends PropsWithChildrenRequired, PropsWithClassNameRequired {
  as?: ElementType;
  'aria-label': string;
  active: boolean;
  disabled: boolean;
  icon: IconProp;
  iconPosition: 'left' | 'right';
  iconStyle: IconStyle;
  stateless: boolean;
}

export const NavItem = forwardRef(
  (
    {
      as: WrapperElement = Link,
      'aria-label': ariaLabel,
      active = false,
      disabled = false,
      icon,
      iconPosition = 'left',
      iconStyle = 'regular',
      children,
      className,
      stateless = false,
      ...rest
    }: Props,
    ref
  ) => {
    const style = icon === 'caret-down' ? 'solid' : iconStyle;
    const leftIcon = icon && iconPosition === position.left;
    const rightIcon = icon && iconPosition === position.right;
    const isStandaloneIcon = !children;
    const iconSize = isStandaloneIcon ? 'standalone' : undefined;

    return (
      <WrapperElement
        className={classnames('a-nav-item', className, {
          'a-nav-item--active': active,
          'a-nav-item--disabled': disabled,
          'a-nav-item--has-states': !stateless
        })}
        ref={ref}
        aria-label={ariaLabel}
        {...rest}
      >
        {leftIcon && (
          <Icon
            aria-hidden
            icon={icon}
            iconStyle={style}
            size={iconSize}
            className={children ? 'u-mar-r_1' : ''}
          />
        )}
        {children}
        {rightIcon && (
          <Icon
            aria-hidden
            icon={icon}
            iconStyle={style}
            size={iconSize}
            className={children ? 'u-mar-l_1' : ''}
          />
        )}
      </WrapperElement>
    );
  }
);

export default NavItem;
