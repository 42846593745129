import React from 'react';

import {IconButton} from '@albert-io/atomic';

import {
  Column,
  Dimension,
  QueryVariables,
  SortOption,
  TopLevelFilter
} from 'client/Reports/reports.types';
import {columnIsAvailable} from 'client/Reports/reports.utils';
import {useFixedTableHeader} from 'client/Reports/useFixedTableHeader';

import {AnswerBreakdownHeaderCell} from './AnswerBreakdownHeaderCell';
import {PerformanceHeaderCell} from './PerformanceHeaderCell';
import {DueDateRangeHeaderCell} from './DueDateRangeHeaderCell';

import './reports-table.scss';

interface Props {
  columns: Column[];
  dimensions: Dimension[];
  variables: QueryVariables;
  sortDirection: SortOption;
  sortBy: string | null;
  setSortBy: React.Dispatch<React.SetStateAction<string | null>>;
  setSortDirection: React.Dispatch<React.SetStateAction<SortOption>>;
  topLevelFilterType: TopLevelFilter;
}

const HeaderRenderer = ({
  columns,
  sortDirection,
  sortBy,
  setSortBy,
  setSortDirection,
  dimensions,
  variables,
  topLevelFilterType
}: Props) => {
  const setTableHeaderRef = useFixedTableHeader();
  const onSortClick = (sortKey: string | null, initialSortDirection: SortOption = 'asc') => {
    if (sortKey === null) {
      return;
    }
    if (sortBy === sortKey) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(sortKey);
      setSortDirection(initialSortDirection);
    }
  };

  return (
    <thead ref={setTableHeaderRef} className='report-table__thead'>
      <tr className='reports-table__header'>
        {columns
          .filter((c) => columnIsAvailable(c, dimensions, variables, topLevelFilterType))
          .map((column) => {
            const isCurrentSort = sortBy === column.sortKey;
            const isSortable = !!column.sortKey;

            const sortKey =
              typeof column.sortKey === 'function' ? column.sortKey(dimensions) : column.sortKey;

            if (column.key === 'answer_breakdown') {
              return (
                <AnswerBreakdownHeaderCell
                  showSort
                  key={column.key}
                  onSortClick={onSortClick}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                />
              );
            }

            if (column.key === 'performance') {
              return (
                <PerformanceHeaderCell
                  key={column.key}
                  showSort
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                  setSortBy={setSortBy}
                  setSortDirection={setSortDirection}
                />
              );
            }

            if (column.key === 'assignments.due_date_range') {
              return (
                <DueDateRangeHeaderCell
                  key={column.key}
                  onSortClick={onSortClick}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                />
              );
            }

            return (
              <th key={column.key} className='reports-table__header'>
                <div>
                  <span>
                    {column.columnType === 'dimension' && column.title}

                    {column.columnType === 'metric' &&
                      column.metricType === 'default' &&
                      column.title}

                    {column.columnType === 'metric' && column.metricType === 'renderedTitle' && (
                      <column.renderTitle dimensions={dimensions} variables={variables} />
                    )}
                  </span>
                  {isSortable && (
                    <IconButton
                      color={isCurrentSort ? 'primary' : 'secondary'}
                      label='sort'
                      iconStyle='regular'
                      size='s'
                      icon={!isCurrentSort || sortDirection === 'asc' ? 'arrow-up' : 'arrow-down'}
                      onClick={() => onSortClick(sortKey, column.initialSortDirection)}
                    />
                  )}
                </div>
              </th>
            );
          })}
      </tr>
    </thead>
  );
};

export default HeaderRenderer;
