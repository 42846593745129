import {createActions} from 'client/framework';

export default createActions(null, [
  'INSERT_SUPPLEMENT_AT_CURSOR_POSITION',
  'RESET_STORE',
  'SET_ACTIVE_SUBJECT',
  'SET_ACTIVE_SUPPLEMENT',
  'SET_CURRENT_EDITOR_FIELD',
  'SHOW_MODAL'
]);
