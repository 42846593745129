// @flow
// @todo remove all but `getQuestionSetsByGuideLevelIdQuery` function
import {set} from 'lodash';
import sessionStore from 'client/Session/SessionStore';
import {resource} from '@albert-io/json-api-framework/request/builder';
import {applySort} from 'components/PracticeView/PracticeViewToolbar/Sort/sort.utils';
import masqueradeStore from 'generic/Masquerade/Masquerade.store';

type QuestionSetFunctionArguments = {
  difficultyFilters: Array<number>,
  onlyIncludeAnsweredQuestions: boolean,
  onlyIncludeUnansweredQuestions: boolean,
  guideLevelSlug: string,
  guideLevelIds: string,
  page: number,
  pageSize: number,
  randomSeed: number,
  sortBy: string,
  studentId: string,
  teacherId: string,
  subjectSlug: string | Array<string>,
  tags: string,
  questionSetSlug: string
};

function makeBaseQuestionSetsQuery({
  difficultyFilters,
  onlyIncludeAnsweredQuestions = false,
  onlyIncludeUnansweredQuestions = false,
  page = 1,
  pageSize = 25,
  randomSeed,
  sortBy = 'difficulty',
  studentId,
  teacherId
}: QuestionSetFunctionArguments): Object {
  const baseQuery: Object = {
    resourcePath: ['question_sets_v1'],
    filter: {
      category: 'guide'
    },
    include: 'subject_v2,questions_v1.supplements_v1,questions_v1,guide_levels_v2',
    customQuery: {
      with_meta: 'question_set_v1,question_v1',
      page: {
        page,
        page_size: pageSize
      }
    }
  };

  if (difficultyFilters) {
    set(baseQuery, ['filter', 'difficulty'], difficultyFilters.sort().join());
  }

  // Can't randomize AND sort, so we do an if/else here
  if (randomSeed) {
    baseQuery.customQuery.random_seed = randomSeed;
  } else if (sortBy !== '') {
    baseQuery.customQuery.sort = sortBy;
  }

  if (onlyIncludeUnansweredQuestions && studentId) {
    set(baseQuery, ['filter', 'meta.student_count_of_guesses'], 0);
  } else if (onlyIncludeAnsweredQuestions && studentId) {
    set(baseQuery, ['filter', 'meta.student_count_of_guesses', 'greater_than'], 0);
  }

  if (studentId) {
    baseQuery.customQuery.meta = {
      context: {
        question: {
          show_active_assignment: true
        },
        student: {
          id: studentId
        }
      }
    };
  } else if (teacherId) {
    baseQuery.customQuery.meta = {
      context: {
        teacher: {
          id: teacherId
        }
      }
    };
  }

  if (sessionStore.isSuper() || sessionStore.isAuthor()) {
    const {
      meta = {} // Copy existing meta, or instantiate a new object if none exists
    } = baseQuery.customQuery;

    meta.context = {
      ...meta.context, // Spread existing meta and context into new object
      author: {
        id: studentId || teacherId
      }
    };

    baseQuery.customQuery.meta = meta;
  }

  return baseQuery;
}

export function makeQuestionSetBySlugQuery({
  /* eslint-disable no-unused-vars */
  questionSetSlug,
  studentId,
  teacherId
}: /* eslint-enable no-unused-vars */
QuestionSetFunctionArguments): Object {
  const query = makeBaseQuestionSetsQuery(arguments[0]);
  set(query, ['filter', 'slug_id'], questionSetSlug);
  return query;
}

export function makeQuestionSetsByGuideLevelSlugQuery({
  /* eslint-disable no-unused-vars */
  difficultyFilters,
  guideLevelSlug,
  page,
  pageSize,
  randomSeed,
  sortBy,
  studentId,
  subjectSlug,
  teacherId
}: /* eslint-enable no-unused-vars */
QuestionSetFunctionArguments): Object {
  const query = makeBaseQuestionSetsQuery(arguments[0]);
  set(query, ['filter', 'subject_v2', 'url_slug'], subjectSlug);
  set(query, ['filter', 'guide_levels_v1', 'url_slug'], guideLevelSlug);
  return query;
}

export function getQuestionSetsByGuideLevelIdQuery(guideLevelId) {
  const metaContext = {
    meta: {
      context: {
        guide_level: {
          id: guideLevelId
        }
      }
    }
  };

  const userId = masqueradeStore.getUserIdByMasqueradeState();
  if (sessionStore.hasTeacherAccess()) {
    set(metaContext, 'meta.context.user.role', 'teacher');
  }

  if (userId) {
    set(metaContext, 'meta.context.user.id', userId);
  } else {
    set(metaContext, 'meta.context.no_user', true);
  }

  let query = resource('question_sets_v1')
    .mandarkEndpoint(['guide_levels_v2', guideLevelId, 'question_sets_v1'])
    .include('questions_v3')
    .include('guide_levels_v2')
    .withMeta('question_set_v1')
    .customQuery(metaContext, Object.keys(metaContext).length !== 0)
    .pageSize('infinite');
  query = applySort(query, 'meta.guide_level_position');
  return query;
}
