/* eslint-disable import/prefer-default-export */
import {List, Map} from 'immutable';
import {QUESTION_TYPES} from 'client/constants';

export const authoringQuestionModelValidators = {
  getDifficulty() {
    if (this.getDifficulty() === 0) {
      return List(['You must select a difficulty.']);
    }
    return null;
  },

  getDropdownText() {
    if (this.getQuestionType() !== QUESTION_TYPES.FILL_IN_THE_BLANK.name) {
      return null;
    }

    if (!this.getDropdownText() || this.getDropdownText().length === 0) {
      return List(['You must provide a dropdown prompt.']);
    }

    return null;
  },

  getDropdowns() {
    if (this.getQuestionType() !== QUESTION_TYPES.FILL_IN_THE_BLANK.name) {
      return null;
    }

    if (this.getDropdowns().isEmpty()) {
      return List(['You must create a dropdown.']);
    }

    return null;
  },

  getHeaderLeft() {
    if (this.getHeaderLeft() && this.getHeaderLeft().length === 0) {
      return List(['You must provide a left header.']);
    }

    return null;
  },

  getHeaderRight() {
    if (this.getHeaderRight() && this.getHeaderRight().length === 0) {
      return List(['You must provide a right header.']);
    }

    return null;
  },

  getInputs() {
    if (this.getQuestionType() !== QUESTION_TYPES.FREE_ENTRY.name) {
      return null;
    }

    if (this.getInputs().isEmpty()) {
      return List(['You must have at least one input prompt.']);
    }

    const inputsWithoutAnswers = this.getInputs().reduce((result, input) => {
      const correctAnswers = this.getValidResponse().get(input.get('id'));
      if (!correctAnswers || correctAnswers.isEmpty()) {
        return result.set(input.get('id'), 'You must provide a correct answer.');
      }
      return result;
    }, Map());

    if (!inputsWithoutAnswers.isEmpty()) {
      return List([inputsWithoutAnswers]);
    }

    return null;
  },

  getOptions() {
    const optionsWithoutAnswers = this.getOptions()
      .filter((option) => !option.get('value'))
      .reduce((result, option) => {
        return result.set(option.get('id'), 'You must provide an answer.');
      }, new Map());
    if (!optionsWithoutAnswers.isEmpty()) {
      return List([optionsWithoutAnswers]);
    }

    const optionsWhiteList = List([
      QUESTION_TYPES.MULTIPLE_CHOICE.name,
      QUESTION_TYPES.SNIPPET_SELECTION.name
    ]);
    if (optionsWhiteList.includes(this.getQuestionType()) && this.getOptions().isEmpty()) {
      return List(['You must provide an option.']);
    }

    return null;
  },

  getPreferredPermutation() {
    if (this.getQuestionType() !== QUESTION_TYPES.TEXT_HIGHLIGHT.name) {
      return null;
    }

    if (this.getPreferredPermutation() === null) {
      return List(['You must select a preferred permutation.']);
    }

    return null;
  },

  getPrompt() {
    if (this.getPrompt().length === 0) {
      return List(['You must provide a prompt.']);
    }
    return null;
  },

  getRows() {
    if (this.getQuestionType() !== QUESTION_TYPES.TWO_WAY.name) {
      return null;
    }

    if (this.getRows().isEmpty()) {
      return List(['At least one row is required.']);
    }

    return null;
  },

  getRubric() {
    if (this.getQuestionType() === QUESTION_TYPES.FREE_RESPONSE.name) {
      return null;
    }

    if (this.getQuestionType() === QUESTION_TYPES.GRAPH_CONTAINS.name) {
      if (!this.getValidResponse().size) {
        return List(['The question must have at least one correct answer element.']);
      }

      const hasAnswerWithNoCorrectAnswerSetting = this.getValidResponse().some((entity) => {
        return entity.get('correctAnswerSettings').every((value) => value === false);
      });

      if (hasAnswerWithNoCorrectAnswerSetting) {
        return List(['All correct answers must have at least one correct answer setting enabled.']);
      }
    }

    if (
      this.getValidResponse() &&
      this.getValidResponse().isEmpty() &&
      this.getQuestionType() !== QUESTION_TYPES.TWO_WAY.name
    ) {
      return List(['A correct answer is required.']);
    }

    if (this.getQuestionType() === QUESTION_TYPES.FILL_IN_THE_BLANK.name) {
      const dropdownsWithoutAnswers = this.getDropdowns().reduce((result, dropdown) => {
        if (!this.getValidResponse().get(dropdown.get('id'))) {
          return result.set(dropdown.get('id'), 'You must select a correct answer.');
        }
        return result;
      }, Map());

      if (!dropdownsWithoutAnswers.isEmpty()) {
        return List([dropdownsWithoutAnswers]);
      }
    }

    return null;
  },

  getValidResponse() {
    if (
      this.getQuestionType() === QUESTION_TYPES.TEXT_HIGHLIGHT.name &&
      this.getValidResponse().some((permutation) => permutation.isEmpty())
    ) {
      return List(['Answer permutations must have at least one highlighted region.']);
    }

    return null;
  },

  getSnippetPrompt() {
    if (this.getQuestionType() !== QUESTION_TYPES.SNIPPET_SELECTION.name) {
      return null;
    }

    if (this.getSnippetPrompt().length === 0) {
      return List(['You must provide a snippet prompt.']);
    }

    return null;
  },

  getSolutionText() {
    if (!this.getSolutionText()) {
      return List(['You must provide an explanation of the solution.']);
    }
    return null;
  },

  getTitle() {
    if (this.getTitle().length === 0) {
      return List(['You must provide a title.']);
    }
    if (this.getTitle().length > 65) {
      return List(['Question titles must be 65 characters or less.']);
    }
    return null;
  }
};
