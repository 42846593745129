import {resource} from '@albert-io/json-api-framework/request/builder';
import {genericMandarkRequest} from 'resources/mandark.resource';
import {set} from 'lodash';
import masqueradeStore from 'generic/Masquerade/Masquerade.store';
import sessionStore from 'client/Session/SessionStore';

// eslint-disable-next-line import/prefer-default-export
const getSearchQuestionsQuery = (randomize = true) => {
  const query = resource('question_sets_v1')
    .mandarkEndpoint(['question_sets_v1'])
    // active subject for content block
    .include('subject_v2')
    // needed to contextualize tag linking
    .include('guide_levels_v2.guide_v1')
    // standards table
    .include('questions_v3.standards_v1.standard_set_v1')
    // labels table
    .include('questions_v3.question_set_v1.tags_v1')
    // creating correct guess from valid response meta
    .withMeta('question_v3,question_set_v1,standard_v1')
    // filtering out hidden subject questions
    .filter({
      subject_v2: {
        hidden: false
      }
    })
    .filter({
      questions_v3: {
        status: 'published'
      }
    })
    // populating rubric in meta
    .meta({
      context: {
        user: {
          id: masqueradeStore.getUserIdByMasqueradeState(),
          role: 'teacher',
          personalization_settings: true
        },
        default_question_list_position: true
      }
    })
    .pageSize(25);

  if (randomize) {
    let randomSeed = Math.random();
    while (randomSeed === 0) {
      // Though very unlikely, randomSeed must be greater than 0 and less than 1,
      // and it's possible for Math.random() to return 0
      randomSeed = Math.random();
    }
    return query.customQuery({random_seed: `${randomSeed}`});
  }
  return query.sort('meta.default_question_list_position');
};

const getDraftQuery = (id) => {
  const query = resource('draft_assignment_v1')
    .mandarkEndpoint(['draft_assignments_v1', id])
    .include('question_sets_v1')
    .include('question_sets_v1.questions_v3')
    .withMeta('draft_assignment_v1');

  return query;
};

function getDraftQuestionSetsQuery(draftId) {
  if (!draftId) {
    return null;
  }
  const metaContext = {
    meta: {
      context: {
        draft_assignment: {
          id: draftId
        },
        user: {
          personalization_settings: true
        }
      }
    }
  };

  const userId = masqueradeStore.getUserIdByMasqueradeState();
  if (sessionStore.hasTeacherAccess()) {
    set(metaContext, 'meta.context.user.role', 'teacher');
  }

  if (userId) {
    set(metaContext, 'meta.context.user.id', userId);
  } else {
    set(metaContext, 'meta.context.no_user', true);
  }

  const query = resource('question_sets_v1')
    .mandarkEndpoint(['draft_assignments_v1', draftId, 'question_sets_v1'])
    .pageSize(20)
    .include('guide_levels_v2.guide_v1')
    .fields({
      guide_v1: 'guide_type'
    })
    .include('subject_v2')
    .include('questions_v3')
    .include('questions_v3.question_set_v1.tags_v1')
    .include('questions_v3.question_set_v1.subject_v2')
    .include('questions_v3.standards_v1')
    .include('questions_v3.standards_v1.standard_set_v1')
    .withMeta('question_v3,subject_v2,question_set_v1,standard_v1')
    .sort('meta.assignment_position')
    .customQuery(metaContext, Object.keys(metaContext).length !== 0);

  return query;
}

// Not used yet
const getDraftQuestionsRelationshipQuery = (draftId) => {
  // Todo: Why does this only work with the old `genericMandarkRequest`???
  const query = resource('draft_assignment_v1')
    .mandarkEndpoint(['draft_assignments_v1', draftId, 'relationships', 'question_sets_v1'])
    .done();

  return genericMandarkRequest('get', query);
};

export {
  getSearchQuestionsQuery,
  getDraftQuery,
  getDraftQuestionSetsQuery,
  getDraftQuestionsRelationshipQuery
};
