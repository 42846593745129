import {Map, List, fromJS} from 'immutable';
import constants from '../constants';
import {Store} from 'client/framework';
import {resetPasswordActions} from './ResetPasswordActions';

class ResetPasswordStore extends Store {
  constructor(name) {
    super(name);

    this.initialData = new Map({
      userInputData: fromJS([
        {
          name: 'password',
          value: '',
          isValid: true
        }
      ]),
      isFormValid: false,
      formError: new Map(),
      emailLinkId: '',
      userId: '',
      showToast: false,
      successToastContent: {
        color: 'positive',
        title: 'Password has been changed successfully!',
        message: 'Please Log In with your new password'
      }
    });

    this.setInitialData(this.initialData);
    this.handle(resetPasswordActions.RESET_PASSWORD_RESET_STORE, this._resetStore);
    this.handle(resetPasswordActions.RESET_PASSWORD_SET_SHOW_TOAST, this._setShowToast);
    this.handle(resetPasswordActions.RESET_PASSWORD_SET_PARAMETERS, this._setParameters);
    this.handle(resetPasswordActions.RESET_PASSWORD_SET_INPUT_STATE, this._setInputState);
    this.handle(resetPasswordActions.RESET_PASSWORD_VALIDATE_INPUT, this._validateInput);
  }

  get userInputData() {
    return this.readData('userInputData');
  }

  get emailLinkId() {
    return this.readData('emailLinkId');
  }

  get isFormValid() {
    return this.readData('isFormValid');
  }

  get showToast() {
    return this.readData('showToast');
  }

  get successToastContent() {
    return this.readData('successToastContent');
  }

  get formError() {
    return this.readData('formError');
  }

  get formErrorMessage() {
    return this.formError.get('displayMessage', '');
  }

  get isFormError() {
    return !this.formError.isEmpty();
  }

  _getInputState(inputName) {
    const {userInputData} = this;

    return userInputData.find((inputObj) => {
      return inputObj.get('name') === inputName;
    });
  }

  _resetStore() {
    this.writeData(this.initialData);
  }

  _setParameters(parameters) {
    this.writeData((state) => {
      return state.set('emailLinkId', parameters.emailLinkId).set('userId', parameters.userId);
    });
  }

  _setShowToast(boolValue) {
    this.writeData('showToast', boolValue);
  }

  _setFormError(error) {
    this.writeData('formError', new Map(error));
  }

  _setIsFormValid(value) {
    this.writeData('isFormValid', value);
  }

  _setInputState(inputState) {
    let {userInputData} = this;

    const storedInputStateIndex = userInputData.findKey((inputObj) => {
      return inputObj.get('name') === inputState.get('name');
    });
    userInputData = userInputData.delete(storedInputStateIndex);

    userInputData = userInputData.push(inputState);
    this.writeData('userInputData', userInputData);
  }

  _validateInput(inputState) {
    const inputName = inputState.get('name');
    const storedInputState = this._getInputState(inputName);

    const isValid = this._isInputDataValid(storedInputState);
    const newInputState = storedInputState.set('isValid', isValid);

    this._setInputState(newInputState);
  }

  _isInputDataValid(inputState) {
    const passwordRegex = constants.REGEX_COLLECTION.PASSWORD;
    const validators = new List([
      {
        name: 'password',
        validateData: (value) => {
          return passwordRegex.test(value);
        }
      }
    ]);

    const currentInputValidator = validators.find((validator) => {
      return validator.name === inputState.get('name');
    });
    const isInputValid = currentInputValidator.validateData(inputState.get('value'));

    this._setIsFormValid(this.isFormValid && isInputValid);

    return isInputValid;
  }

  userInputDataIsValid() {
    const {userInputData} = this;

    this._setIsFormValid(true);

    userInputData.forEach((inputObj) => {
      this._validateInput(inputObj);
    });

    return this.isFormValid;
  }

  getInputValue(inputName) {
    const inputState = this._getInputState(inputName);

    return inputState.get('value');
  }

  isInputValid(inputName) {
    const inputState = this._getInputState(inputName);

    return inputState.get('isValid');
  }
}
/* eslint-disable-next-line */
export const resetPasswordStore = new ResetPasswordStore('ResetPasswordStore');
