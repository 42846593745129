import React from 'react';
import {Button, ButtonGroup, Dialogue, Icon, Modal, Text} from '@albert-io/atomic';

interface RegenerateTranslationsModalProps {
  doGenerateTranslations: () => Promise<void>;
  setShowConfirmRegenerateModal: (show: boolean) => void;
  type: 'question' | 'supplement';
}

export const RegenerateTranslationsModal = ({
  setShowConfirmRegenerateModal,
  doGenerateTranslations,
  type
}: RegenerateTranslationsModalProps) => {
  return (
    <Modal
      handleClose={() => setShowConfirmRegenerateModal(false)}
      ariaLabel='Regenerate translations'
    >
      {({modalContentStyle}) => (
        <Dialogue
          handleClose={() => setShowConfirmRegenerateModal(false)}
          inModal
          className={modalContentStyle}
        >
          <Dialogue.Body>
            <div className='u-display_flex u-flex-direction_column u-align-items_center u-gap_space-x7'>
              <div className='u-display_flex u-flex-direction_column u-align-items_center u-gap_space-x1'>
                <Icon icon={['far', 'refresh']} style={{fontSize: '32px'}} />

                <Text size='xl' bold>
                  Re-run translations?
                </Text>
              </div>

              <Text size='m' className='u-text-align_center'>
                {type === 'question' ? 'Supplements and fields ' : 'Fields '}
                will be re-translated. However, published translations will not be regenerated.
              </Text>

              <div className='u-align-self_flex-end'>
                <ButtonGroup>
                  <Button color='secondary' onClick={() => setShowConfirmRegenerateModal(false)}>
                    Dismiss
                  </Button>
                  <Button
                    onClick={() => {
                      setShowConfirmRegenerateModal(false);
                      doGenerateTranslations();
                    }}
                  >
                    Re-run Translations
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          </Dialogue.Body>
        </Dialogue>
      )}
    </Modal>
  );
};
