// @flow
import moment from 'moment';
import systemTimeOffsetStore from 'client/generic/SystemTimeOffset/SystemTimeOffset.store';

function getMinutesFromDuration(duration: moment): number {
  const milliseconds = duration.asMilliseconds();
  const seconds = milliseconds / 1000;
  return Math.round(seconds / 60);
}

export function convertDateAndTimeToUnixTimestamp({
  date,
  time
}: {
  date: string,
  time: string
}): moment {
  return convertDateAndTimeToMomentObject({date, time}).valueOf();
}

export function convertDateAndTimeToMomentObject({
  date,
  time
}: {
  date: string,
  time: string
}): moment {
  const formattedDate = moment(date).format('YYYY-MM-DD');
  const formattedTime = moment(time, 'h:mm A').format('HH:mm:ss');
  return moment(`${formattedDate} ${formattedTime}`);
}

/**
  We calculate this ourselves because moment's duration object only
  allows us to get hours / minutes as their own thing. Not combined into
  separate values.

  For instance, 100 minutes as a duration w/ .hours gives 1.x, 2/ minutes gives 100,
  we want 1 hour and 40 minutes for display purposes here.
*/
export function humanReadableDurationString(momentDuration: moment): string {
  const minutes = getMinutesFromDuration(momentDuration);

  const hours = Math.floor(minutes / 60, 0);
  const remainingMinutes = Math.round(minutes % 60);

  if (hours) {
    return `${hours} hrs ${remainingMinutes} min`;
  }
  return `${remainingMinutes} min`;
}

const durationFormats = {
  minutes(duration, options): string {
    duration = duration.asMinutes();
    if (options.max && duration >= options.max) {
      return `${options.max}:00s`;
    }
    let [minutes, fractional] = duration
      .toString()
      .split('.')
      .map((n) => parseInt(n, 10));
    if (Number.isNaN(minutes)) {
      minutes = 0;
    }
    if (Number.isNaN(fractional)) {
      fractional = 0;
    }
    let seconds = fractional ? Math.round(parseFloat(`.${fractional}`) * 60) : 0;
    /**
     * Since we're rounding up milliseconds, it's possible seconds will be 60.
     * Rather than rendering: "01:60s" add to the minutes and set the seconds to 0.
     */
    if (seconds === 60) {
      minutes += 1;
      seconds = 0;
    }
    return `${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}s`;
  },
  'hh:mm:ss': function (duration): string {
    const clone = duration.clone();
    const hours = Math.floor(clone.asHours());
    clone.subtract(hours, 'hours');
    const minutes = Math.floor(clone.asMinutes());
    clone.subtract(minutes, 'minutes');
    const seconds = Math.floor(clone.asSeconds());
    return [hours, minutes, seconds].map((unit) => `${unit}`.padStart(2, '0')).join(':');
  }
};
export function renderDuration(
  duration: moment.duration,
  options: {style: string, max: ?number} = {style: 'minutes'}
): string {
  const formatter = durationFormats[options.style];
  if (!formatter) {
    throw new Error('unknown style value passed to renderDuration');
  }
  return formatter(duration, options);
}

/**
 * "Renders" a moment object to our shared output style.
 *
 * Optionally accepts a `style` parameter sourced from `format`
 *
 */
const formats = {
  date: 'MM/DD/YYYY',
  dateTime: 'MM/DD/YYYY, h:mm a z',
  dateFormal: 'MMM Do[,] YYYY',
  dateFormalNoDateSuffix: 'MMM D[,] YYYY',
  dateFormalShort: 'MMM Do',
  dateFormalShortWithFullMonth: 'MMMM Do',
  /**
   * Convert the moment instance to the format accepted by input[type='date'].value
   * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/date#Value
   */
  dom: 'YYYY-MM-DD',
  time: 'h:mm a z'
};
export function renderMoment(
  m: moment,
  style: string = 'dateTime',
  tz: string = moment.tz.guess()
): string {
  const format = formats[style];
  if (!format) {
    throw new Error('unknown style value passed to renderMoment');
  }
  return moment(m).tz(tz).format(format);
}

/**
 * takes a timestamp and an optional string to be prepended before the date string
 */
export function renderTimeFromNow(timestamp, prependedString: ''): string {
  /** setting default moment.js calendar format */
  const dateString = moment(timestamp).calendar(null, {
    sameDay: '[today]',
    lastDay: '[yesterday]',
    lastWeek: 'll',
    sameElse: 'll'
  });
  const currentTime = systemTimeOffsetStore.getCurrentTime();
  let modifiedString;
  if (currentTime.diff(timestamp, 'seconds') < 60) {
    modifiedString = 'just now';
  } else if (currentTime.diff(timestamp, 'minutes') < 10) {
    modifiedString = timestamp.fromNow();
  } else {
    modifiedString = dateString;
  }

  if (prependedString) {
    return `${prependedString} ${modifiedString}`;
  }
  return modifiedString.charAt(0).toUpperCase() + modifiedString.slice(1);
}
