import classNames from 'classnames';
import React, {ElementType} from 'react';

export function asPercentage(value: number | null) {
  return `${Math.round((value ?? 0) * 100)}%`;
}

interface StyledContainerProps extends PropsWithChildrenRequired, PropsWithClassNameOptional {
  as?: ElementType;
}

export function StyledContainer({
  as: WrapperElement = 'div',
  children,
  className
}: StyledContainerProps) {
  return (
    <WrapperElement className={classNames(className, 'u-flex-grow_1 u-border-radius_1')}>
      {children}
    </WrapperElement>
  );
}
