import {Map} from 'immutable';

import GenericExplanation from '../common/V2/GenericExplanation.react';

import SnippetSelectionQuestionEditor from './SnippetSelectionEditor';
import {SnippetSelectionQuestion} from './SnippetSelectionQuestion.react';

const SnippetSelectionQuestionType = Map({
  type: 'snippet-selection',
  label: 'Snippet Selection',
  questionRenderer: SnippetSelectionQuestion,
  explanationRenderer: GenericExplanation,
  responseStatsRenderer: () => null,
  editor: SnippetSelectionQuestionEditor,
  isHidden: false
});

export default SnippetSelectionQuestionType;
