import React from 'react';
import SimpleMarkdown from 'simple-markdown';

export const underlineRule = {
  order: SimpleMarkdown.defaultRules.em.order - 0.5,
  match: (source) => /^~([^~]+)~/.exec(source),
  parse: (capture, recurseParse, state) => {
    return {
      content: recurseParse(capture[1], state)
    };
  },
  react: (node, output) => <u key={node.tokenId}>{output(node.content)}</u>,
  html: (node, output) => `<u>${output(node.content)}</u>`
};
