import {Store} from 'client/framework';
import {Map} from 'immutable';
import windowConfirmActions from './WindowConfirmActions';

export default class WindowConfirmStore extends Store {
  constructor(name) {
    super(name);
    this.initialData = new Map({
      isActive: false
    });
    this.setInitialData(this.initialData);
    this.handleTargeted(windowConfirmActions.HIDE_MODAL, this._hideModal);
    this.handleTargeted(windowConfirmActions.SHOW_MODAL, this._showModal);
  }

  _hideModal() {
    this.writeData('isActive', false);
  }

  _showModal() {
    this.writeData('isActive', true);
  }

  isActive() {
    return this.readData('isActive');
  }
}
