import {Map} from 'immutable';

import {
  GraphingEditorQuestionPreview,
  GraphingEditorExplanationPreview
} from './GraphingEditorPreview/GraphingQuestionPreview.react';
import GraphingEditor from './GraphingEditor/GraphingEditor.react';

const GraphingQuestionType = Map({
  type: 'graph-contains',
  label: 'Graphing',
  questionRenderer: GraphingEditorQuestionPreview,
  explanationRenderer: GraphingEditorExplanationPreview,
  responseStatsRenderer: () => null,
  editor: GraphingEditor,
  isHidden: false
});

export default GraphingQuestionType;
