import {resource} from '@albert-io/json-api-framework/request/builder';
import {genericMandarkRequest} from 'resources/mandark.resource';
import {mandarkEndpoint} from '@albert-io/json-api-framework/request/builder/legacy';
import sessionStore from 'client/Session/SessionStore';

export const getUser = () => {
  const userId = sessionStore.getUserId();

  const query = resource('users_v2').mandarkEndpoint(['users_v2', userId]).done();

  return genericMandarkRequest('get', query);
};

export const saveDraft = (payload, questionId, assignmentId) => {
  const query = mandarkEndpoint(['draft_guesses_v1']).done();
  const {content, timeElapsed} = payload;
  return genericMandarkRequest('post', query, {
    data: {
      attributes: {
        content,
        time_elapsed: timeElapsed
      },
      relationships: {
        assignment_v3: {
          data: {
            id: assignmentId,
            type: 'assignment_v3'
          }
        },
        student_v2: {
          data: {
            id: sessionStore.getUserId(),
            type: 'student_v2'
          }
        },
        question_v3: {
          data: {
            id: questionId,
            type: 'question_v3'
          }
        }
      }
    }
  });
};

export const patchDraft = (draftId, payload) => {
  const query = mandarkEndpoint(['draft_guesses_v1', draftId]).done();
  const {content, timeElapsed} = payload;
  return genericMandarkRequest('patch', query, {
    data: {
      id: draftId,
      type: 'draft_guess_v1',
      attributes: {
        content,
        time_elapsed: timeElapsed
      }
    }
  });
};

export const getDraft = (questionId, assignmentId) => {
  const studentId = sessionStore.getUserId();

  const query = resource('draft_guesses_v1')
    .mandarkEndpoint(['students_v2', studentId, 'draft_guesses_v1'])
    .filter({
      student_v2: studentId,
      assignment_v3: assignmentId,
      question_v3: questionId
    })
    .done();

  return genericMandarkRequest('get', query);
};

export const saveMedia = (payload, guessDraftId, mediaCategory) => {
  const userId = sessionStore.getUserId();
  const query = resource('media_v1').mandarkEndpoint(['media_v1', 'draft_guess']).done();
  return genericMandarkRequest('post', query, {
    data: {
      attributes: {
        media: payload.media,
        expected_media_category: mediaCategory
      },
      relationships: {
        draft_guess_v1: {
          data: {
            id: guessDraftId,
            type: 'draft_guess_v1'
          }
        },
        user_v2: {
          data: {
            id: userId,
            type: 'user_v2'
          }
        }
      }
    }
  });
};

export const getMedia = (mediaId) => {
  const query = resource('media_v1').mandarkEndpoint(['media_v1', mediaId]).done();
  return genericMandarkRequest('get', query);
};

export const deleteMedia = (mediaId, guessDraftId) => {
  const userId = sessionStore.getUserId();
  const query = resource('media_v1').mandarkEndpoint(['media_v1', mediaId, 'draft_guess']).done();
  return genericMandarkRequest('delete', query, {
    data: {
      id: mediaId,
      type: 'media_v1',
      relationships: {
        draft_guess_v1: {
          data: {
            id: guessDraftId,
            type: 'draft_guess_v1'
          }
        },
        user_v2: {
          data: {
            id: userId,
            type: 'user_v2'
          }
        }
      }
    }
  });
};

export const deleteGuessMedia = (mediaId, guessId) => {
  const userId = sessionStore.getUserId();
  const query = resource('media_v1').mandarkEndpoint(['media_v1', mediaId, 'guess']).done();
  return genericMandarkRequest('delete', query, {
    data: {
      id: mediaId,
      type: 'media_v1',
      relationships: {
        guess_v1: {
          data: {
            id: guessId,
            type: 'guess_v1'
          }
        },
        user_v2: {
          data: {
            id: userId,
            type: 'user_v2'
          }
        }
      }
    }
  });
};
