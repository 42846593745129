import {List, Map} from 'immutable';

import {MultipleChoiceQuestionType} from './MultipleChoice/MultipleChoiceQuestionType';
import {FreeEntryQuestionType} from './FreeEntry/FreeEntryQuestionType';
import {TextHighlightQuestionType} from './TextHighlight/TextHighlightQuestionType';
import {FillInTheBlankQuestionType} from './FillInTheBlank/FillInTheBlankQuestionType';
import {TwoWayQuestionType} from './TwoWay/TwoWayQuestionType';
import {FreeResponseQuestionType} from './FreeResponse/FreeResponseQuestionType';
import SnippetSelectionQuestionType from './SnippetSelection/SnippetSelectionQuestionType';
import PassageCorrectionQuestionType from './PassageCorrection/PassageCorrectionQuestionType';
import GraphingQuestionType from './Graphing/GraphingQuestionType';

const questionTypes = List([
  FillInTheBlankQuestionType,
  FreeEntryQuestionType,
  FreeResponseQuestionType,
  GraphingQuestionType,
  MultipleChoiceQuestionType,
  PassageCorrectionQuestionType,
  SnippetSelectionQuestionType,
  TextHighlightQuestionType,
  TwoWayQuestionType
]);

export function getQuestionTypeDefinition(type) {
  return questionTypes.find(
    (definition) => {
      return definition.get('type') === type;
    },
    this,
    Map()
  );
}

export function getQuestionTypes() {
  return questionTypes;
}
