import {PublicQuestionModelV1} from 'src/resources/augmented/PublicQuestion/PublicQuestionModel.v1';

export const QUERY_PAGE_SIZE = 100;

export type QueryType = 'guideLevel' | 'singleQuestion';

export const currentQuestionIndex = (
  questions: PublicQuestionModelV1[],
  currentQuestion: PublicQuestionModelV1
) => questions.findIndex((q) => q.getId() === currentQuestion.getId());

// first question selected
export const previousQuestionDisabled = (
  questions: PublicQuestionModelV1[],
  currentQuestion?: PublicQuestionModelV1
) => {
  if (!currentQuestion || currentQuestionIndex(questions, currentQuestion) < 0) {
    return true;
  }
  return questions[0].getId() === currentQuestion.getId();
};

// last question selected
export const nextQuestionDisabled = (
  questions: PublicQuestionModelV1[],
  currentQuestion?: PublicQuestionModelV1
) => {
  if (!currentQuestion || currentQuestionIndex(questions, currentQuestion) < 0) {
    return true;
  }
  return questions[questions.length - 1].getId() === currentQuestion.getId();
};

export const getQuestionPath = (
  subjectSlug: string,
  guideLevelSlug?: string,
  questionSlug?: string
) => {
  if (!guideLevelSlug || !questionSlug) {
    return '';
  }
  return `/learn/${subjectSlug}/${guideLevelSlug}/${questionSlug}`;
};
