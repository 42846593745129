/**
 * # Badge
 *
 * A component that displays a badge with optional text and icon.
 *
 * ## Props
 *
 * - `children` (optional): The content to be displayed inside the badge.
 * - `className` (optional): Additional CSS class name for the badge.
 * - `status` (optional): The status variant of the badge. Possible values are `'neutral'`, `'positive'`, `'negative'`, `'info'`, and `'warning'`.
 * - `icon` (optional): The icon to be displayed in the badge.
 * - `iconAlign` (optional): The alignment of the icon within the badge. Possible values are `'center'`, `'left'`, and `'right'`.
 * - `iconStyle` (optional): The style variant of the icon.
 * - `size` (optional): The size variant of the badge. Possible values are `'default'` and `'small'`.
 * - `emphasis` (optional): The emphasis variant of the badge. Possible values are `'default'`, `'subtle'` and `'bold'`.
 *
 * ## Usage
 *
 * ```tsx
 * import Badge from './Badge.react';
 *
 * const Example = () => {
 *   return (
 *     <Badge status="positive" icon="check" iconAlign="right" size="small">
 *       New
 *     </Badge>
 *   );
 * };
 * ```
 */

import React from 'react';
import classnames from 'classnames';

import Text from '../Text/Text.react';
import Icon, {IconProp, IconStyle} from '../Icon/Icon.react';
import './badge.scss';

export type BadgeStatuses = 'neutral' | 'positive' | 'negative' | 'info' | 'warning';

interface Props extends PropsWithChildrenOptional, PropsWithClassNameOptional {
  status?: BadgeStatuses;
  emphasis?: 'default' | 'subtle' | 'bold';
  icon?: IconProp;
  iconAlign?: 'center' | 'left' | 'right';
  iconStyle?: IconStyle;
  size?: 'small' | 'default' | 'large';
  onClick?: () => void;
}

type RequireChildrenOrIcon = RequireAtLeastOne<Props, 'children' | 'icon'>;

export const Badge = ({
  children,
  className,
  status = 'neutral',
  icon,
  iconAlign = 'left',
  emphasis = 'default',
  iconStyle,
  size = 'default',
  onClick
}: RequireChildrenOrIcon) => {
  const textEmphasis = status === 'neutral' ? 'low' : 'high';

  const Element = onClick ? 'button' : 'div';

  return (
    <Element
      onClick={onClick}
      className={classnames(
        'a-badge',
        {
          [`a-badge--status-${status}`]: status,
          [`a-badge--status-${status}--subtle`]: emphasis === 'subtle',
          [`a-badge--status-${status}--default`]: emphasis === 'default',
          [`a-badge--icon-${iconAlign}`]: icon && iconAlign,
          [`a-badge--size-${size}`]: size,
          'a-badge--clickable': !!onClick
        },
        className
      )}
    >
      {icon && (
        <Icon className='a-badge__icon' emphasis={textEmphasis} icon={icon} iconStyle={iconStyle} />
      )}
      {children && (
        <Text color='inherit' className='u-font-size_inherit u-line-height_140pc'>
          {children}
        </Text>
      )}
    </Element>
  );
};

export default Badge;
