import React from 'react';
import PropTypes from 'prop-types';
import {AssignmentModelV3} from 'resources/augmented/Assignment/AssignmentModel.v3';
import {renderMoment} from 'lib/timeUtils';
import Button from 'sg/Button/Button.react';
import appStore from 'client/AppStore';
import './assignment-status-banner.scss';

export default class AssignmentStatusBanner extends React.Component {
  static propTypes = {
    assignment: PropTypes.instanceOf(AssignmentModelV3),
    studentId: PropTypes.string.isRequired
  };

  render() {
    const assignment = this.props.assignment;
    const submitted = assignment.hasStudentSubmittedAssignment(this.props.studentId);
    /**
     * If the student hasn't submitted the assignment, and they can
     * still take action on it (not "over due"), we don't display a
     * status banner.
     */
    if (!submitted && !assignment.isOverDue()) {
      return null;
    }
    let className = 'assignment-status-banner';
    let statusCopy = 'This assessment has been submitted.';
    if (!submitted && assignment.isOverDue()) {
      statusCopy = 'This assessment is past due.';
      className += ' assignment-status-banner--red';
    }

    const backToSubjectButton = assignment.getMeta().isSelfAssigned() ? (
      <Button
        small
        color='translucent'
        text={`Back to ${assignment.getSubject().getName()}`}
        to={`/${assignment.getSubject().getUrlSlug()}`}
      />
    ) : null;

    const resultsCopy = assignment.shouldShowGuessFeedback()
      ? ''
      : `Results will be available on ${renderMoment(assignment.getDueDate(), 'dateFormalShort')}.`;

    const shouldShowMobileFeedbackMessage = Boolean(appStore.isMobileView() && submitted);
    return (
      <div className={className}>
        <p className='u-mar_0'>
          {statusCopy} {resultsCopy}
        </p>
        {shouldShowMobileFeedbackMessage && (
          <p className='u-mar-b_0 u-mar-t_1'>
            To view your full assessment results, please visit this page on a desktop computer.
          </p>
        )}
        {backToSubjectButton}
      </div>
    );
  }
}
