/*
  MDN suggested snippet to detect if local storage is BOTH supported and AVAILABLE

  This will be invoked prior to reading or writing. I added a property check so it short circuits
  the throw-on-purpose checking.
*/
function isStorageAvailable(type) {
  if (!process.env.IS_BROWSER) {
    return false;
  }
  const storage = window[type];
  try {
    if (!storage) {
      return false;
    }
    const x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage.length !== 0
    );
  }
}

function writeTo(storageObject, key, value) {
  // TODO: Replace this with an .isImmutable check once we upgade to 4.0
  if (value.toJS) {
    console.error(
      'You are trying to serialize an immutable object to local storage, this cannot be parsed'
    );
  }
  /*
    Wrapped in try/catch to ensure we don't horrendously bomb
    if our isStorageAvailable check passes, but we fail anyway - probably
    due to a storage limit being hit.

    // TODO: Possibly clear storage and try again? Diagnostic?
  */
  try {
    return storageObject.setItem(key, JSON.stringify(value));
  } catch (e) {
    // deliberately a no-op
    return undefined; // what a setItem call would return if successful.
  }
}

function readFrom(storageObject, key, defaultValue = undefined) {
  // TODO: perhaps try/catch this in the case something serializes very strangely.
  return storageObject.getItem(key) ? JSON.parse(storageObject.getItem(key)) : defaultValue;
}

const session = {
  get: (key, defaultValue) => {
    if (isStorageAvailable('sessionStorage')) {
      return readFrom(sessionStorage, key, defaultValue);
    }
    return defaultValue || undefined;
  },
  set: (key, value) => {
    if (isStorageAvailable('sessionStorage')) {
      return writeTo(sessionStorage, key, value);
    }
    return undefined;
  }
};

const local = {
  get: (key, defaultValue) => {
    if (isStorageAvailable('localStorage')) {
      return readFrom(localStorage, key, defaultValue);
    }
    return defaultValue || undefined;
  },
  set: (key, value) => {
    if (isStorageAvailable('localStorage')) {
      return writeTo(localStorage, key, value);
    }
    return undefined;
  }
};

export {local, session};
