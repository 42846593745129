import React, {useContext, useRef} from 'react';
import classnames from 'classnames';
import {Map} from 'immutable';

import {pushQueryParams} from 'client/history';
import {Card, DatePicker, Icon, Popover, WithToggle} from '@albert-io/atomic';
import {RANGES} from '@albert-io/atomic/organisms/DatePicker/DatePicker.helpers';

import moment from 'moment';

import {ReportsContext} from '../../Reports.context';

import ChipButton from './ChipButton';

function DateFilter() {
  const buttonRef = useRef(null);
  const {
    range: reportDateRange,
    start: reportDateStart,
    end: reportDateEnd
  } = useContext(ReportsContext);

  const displayRange =
    reportDateRange === RANGES.CUSTOM
      ? `${moment(reportDateStart).format('MMM D, YYYY')} - ${moment(reportDateEnd).format(
          'MMM D, YYYY'
        )}`
      : reportDateRange;

  const value = Map({
    id: reportDateRange,
    reportDateStart,
    reportDateEnd,
    value: reportDateRange
  });

  return (
    <WithToggle dismissOnEscape key='reports-filter-date'>
      {({on, onClick}) => (
        <>
          <ChipButton onClick={onClick} buttonRef={buttonRef}>
            {displayRange}
            <Icon icon='caret-down' />
          </ChipButton>
          {on && (
            <Popover
              usePortal={false}
              border='none'
              expanded={on}
              position='bottom-start'
              paddingLevel={null}
              targetRef={buttonRef}
              modifiers={{
                flip: {enabled: false},
                preventOverflow: {
                  boundariesElement: 'viewport' // or 'window'
                }
              }}
            >
              <Card
                className={classnames('reports-filter__date reports-filter__dropdown', {
                  'reports-filter__dropdown--open': on,
                  'reports-filter__dropdown--closed': !on
                })}
                paddingLevel={3}
                shadow='far'
              >
                <DatePicker
                  excludeDates={[RANGES.ALL_TIME, RANGES.LAST_WEEK, RANGES.LAST_MONTH]}
                  onSubmit={({range, start, end}) => {
                    pushQueryParams({
                      range,
                      start,
                      end
                    });
                    onClick();
                  }}
                  value={value}
                />
              </Card>
            </Popover>
          )}
        </>
      )}
    </WithToggle>
  );
}

export default DateFilter;
