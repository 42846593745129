/**
 * # MobileDropdown
 *
 * Wrapper component that turns its children into a dropdown upon entering the mobile breakpoint.
 *
 * @see [GitHub Issue](https://github.com/albert-io/project-management/issues/2783)
 *
 * ## Props
 *
 * - `className` (string): The CSS class name for the wrapping div when not in mobile breakpoint.
 * - `trigger` (node, required): The trigger element for the dropdown.
 *
 * ## Usage
 *
 * ```jsx
 * import MobileDropdown from './MobileDropdown.react';
 *
 * const ExampleComponent = () => {
 *   return (
 *     <MobileDropdown className="my-dropdown" trigger={<button>Open Dropdown</button>}>
 *       <div>Dropdown Content</div>
 *     </MobileDropdown>
 *   );
 * };
 * ```
 */
import React from 'react';
import PropTypes from 'prop-types';

import Dropdown from '../../molecules/Dropdown/Dropdown.react';
import {WindowSizeConsumer} from '../WindowSizeProvider/WindowSizeProvider.react';

/**
 * Wrapper component that turns its children into a dropdown upon entering the
 * mobile breakpoint.
 *
 * @see https://github.com/albert-io/project-management/issues/2783
 * @todo: update the wrapping div to be fragments once using React 16
 * @todo: remove the initial return null once we stop using context polyfill
 */

const MobileDropdown = ({children, className, trigger, ...rest}) => {
  return (
    <WindowSizeConsumer>
      {(value) => {
        if (!value) {
          return null;
        }
        return value.viewportWidth <= value.breakpoints.s ? (
          <Dropdown trigger={trigger} {...rest}>
            {children}
          </Dropdown>
        ) : (
          <div className={className}>{children}</div>
        );
      }}
    </WindowSizeConsumer>
  );
};

MobileDropdown.propTypes = {
  className: PropTypes.string,
  trigger: PropTypes.node.isRequired,
  children: PropTypes.node
};

export default MobileDropdown;
