import {resource} from '@albert-io/json-api-framework/request/builder';
import sessionStore from 'client/Session/SessionStore';
import masqueradeStore from 'generic/Masquerade/Masquerade.store';

export const getAssignmentByIdQuery = (assignmentId) => {
  const teacherId = masqueradeStore.getUserIdByMasqueradeState();
  return resource('assignments_v3')
    .mandarkEndpoint(['assignments_v3', assignmentId])
    .include('classrooms_v1')
    .include('question_sets_v1.questions_v1')
    .include('question_sets_v1.subject_v2')
    .include('question_sets_v1.tags_v1')
    .filter({
      included: {
        classrooms_v1: {
          teachers_v1: teacherId
        }
      }
    });
};

// This is currently being used by the `usehandleParamPrefill` hook and NOT in the `ViewAssignments` component
// We consolidated the AssignmentSettings and ViewAssignments queries to minimize calls to mandark
export const getAssignmentById = (assignmentId) => {
  return getAssignmentByIdQuery(assignmentId).getResourcePromise();
};

export const getAssignmentQuestionSetsQuery = (assignmentId, getAllResults = false) => {
  const query = resource('question_sets_v1')
    .mandarkEndpoint(['assignments_v3', assignmentId, 'question_sets_v1'])
    .include('subject_v2')
    .include('guide_levels_v2.guide_v1')
    .include('questions_v3.standards_v1.standard_set_v1')
    .include('questions_v3.question_set_v1.tags_v1')
    .include('questions_v3.question_set_v1.subject_v2')
    .withMeta('question_set_v1,question_v3')
    .customQuery({
      meta: {
        context: {
          user: {
            id: sessionStore.getUserId(),
            role: 'teacher'
          },
          assignment: {
            id: assignmentId
          }
        }
      },
      page: {
        page_size: getAllResults ? 'infinite' : 20
      }
    })
    .sort('meta.assignment_position');

  return query; // applyFilters(applySort(query, 'meta.assignment_position'));
};

export const getAssignmentQuestionSets = (assignmentId, getAllResults = false) => {
  return getAssignmentQuestionSetsQuery(assignmentId, getAllResults).getResourcePromise();
};
