/*
* This file is auto generated from the parseMappings.js script.
* To recreate this file, go to the directory and run  "node ./parseMappings"
*/
export const subjectMappings = [
  {
    "id": "454c3307-2aa0-4042-807c-7bf9995013e7",
    "mappings": [
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      }
    ]
  },
  {
    "id": "3b8e80e8-e4be-4c23-bfdb-9f3205fd0844",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Shakespeare"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Shakespeare"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Shakespeare"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Shakespeare"
      }
    ]
  },
  {
    "id": "5795393a-a73c-4106-bb4b-aa6c072d6442",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      }
    ]
  },
  {
    "id": "d29546a4-acd2-4db0-b375-80717f8b96d7",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      }
    ]
  },
  {
    "id": "b593346b-3a7a-4b7d-9c15-18416fadbbaa",
    "mappings": []
  },
  {
    "id": "44e655b4-831e-45c5-a0b5-02d8d68f4d93",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      }
    ]
  },
  {
    "id": "9c9f327a-9cac-4d4a-85e6-b41c6b89c9a1",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "Application Questions"
      },
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Application Questions"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Application Questions"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "Application Questions"
      },
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "Test Prep"
      }
    ]
  },
  {
    "id": "49c67e23-606e-43bb-bab5-8d090d333edc",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Extension for Higher-Learning Students"
      }
    ]
  },
  {
    "id": "67c2e1e9-881f-472d-b2dc-7a8a8b9c11e9",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Literacy"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Literacy"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Literacy"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Literacy"
      }
    ]
  },
  {
    "id": "6b7392b2-607a-465e-932b-0aa9e66849ef",
    "mappings": [
      {
        "grade": "7th Grade",
        "area": "Math",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "8th Grade",
        "area": "Math",
        "need": "Skills Practice"
      },
      {
        "grade": "8th Grade",
        "area": "Math",
        "need": "Application Questions"
      },
      {
        "grade": "8th Grade",
        "area": "Math",
        "need": "Conceptual Questions"
      },
      {
        "grade": "8th Grade",
        "area": "Math",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "Skills Practice"
      },
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "Application Questions"
      },
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "Conceptual Questions"
      },
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "Remediation"
      },
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Skills Practice"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Application Questions"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Conceptual Questions"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Remediation"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Remediation"
      },
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "Remediation"
      },
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "TEKS-aligned"
      },
      {
        "grade": "8th Grade",
        "area": "Math",
        "need": "TEKS-aligned"
      },
      {
        "grade": "7th Grade",
        "area": "Math",
        "need": "TEKS-aligned"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "TEKS-aligned"
      },
      {
        "grade": "8th Grade",
        "area": "Math",
        "need": "Regents-aligned"
      },
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "Regents-aligned"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Regents-aligned"
      },
      {
        "grade": "7th Grade",
        "area": "Math",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "8th Grade",
        "area": "Math",
        "need": "Skills Practice"
      },
      {
        "grade": "8th Grade",
        "area": "Math",
        "need": "Application Questions"
      },
      {
        "grade": "8th Grade",
        "area": "Math",
        "need": "Conceptual Questions"
      },
      {
        "grade": "8th Grade",
        "area": "Math",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "Skills Practice"
      },
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "Application Questions"
      },
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "Conceptual Questions"
      },
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "Remediation"
      },
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Skills Practice"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Application Questions"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Conceptual Questions"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Remediation"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Remediation"
      },
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "Remediation"
      },
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "TEKS-aligned"
      },
      {
        "grade": "8th Grade",
        "area": "Math",
        "need": "TEKS-aligned"
      },
      {
        "grade": "7th Grade",
        "area": "Math",
        "need": "TEKS-aligned"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "TEKS-aligned"
      },
      {
        "grade": "8th Grade",
        "area": "Math",
        "need": "Regents-aligned"
      },
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "Regents-aligned"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Regents-aligned"
      },
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "Regents-aligned"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Remediation"
      },
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "Integrated Math"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Integrated Math"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Integrated Math"
      },
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "Integrated Math"
      },
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "Test Prep"
      }
    ]
  },
  {
    "id": "bd5a54f4-622e-44fc-aa88-d112189cb07c",
    "mappings": [
      {
        "grade": "8th Grade",
        "area": "Math",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Skills Practice"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Application Questions"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Conceptual Questions"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Remediation"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Skills Practice"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Application Questions"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Conceptual Questions"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Remediation"
      },
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "Skills Practice"
      },
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "Application Questions"
      },
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "Conceptual Questions"
      },
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "Remediation"
      },
      {
        "grade": "Higher Learning",
        "area": "Math",
        "need": "Remediation"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Regents-aligned"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Regents-aligned"
      },
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "Regents-aligned"
      },
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "Regents-aligned"
      },
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "TEKS-aligned"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "TEKS-aligned"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "TEKS-aligned"
      },
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "TEKS-aligned"
      }
    ]
  },
  {
    "id": "2f562a70-46c7-4ad5-8c91-e925f3dd51bd",
    "mappings": []
  },
  {
    "id": "c4e83bd7-8520-492a-8950-d3f58de1cb2c",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      }
    ]
  },
  {
    "id": "b419fc26-260c-4164-b6a1-246fe30df87b",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Short Stories"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Short Stories"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Short Stories"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Short Stories"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      }
    ]
  },
  {
    "id": "15170b27-bd54-4ece-8a1e-010967565c5d",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      }
    ]
  },
  {
    "id": "4e667869-8527-4509-bee1-fd7cdf6992f7",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Drama"
      }
    ]
  },
  {
    "id": "2f4b1f85-5277-4bcd-8b3b-190fd2aa308b",
    "mappings": [
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Primary Sources"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Critical Thinking"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Primary Sources"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Critical Thinking"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Primary Sources"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Critical Thinking"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Arts",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Arts",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Arts",
        "need": "Test Prep"
      }
    ]
  },
  {
    "id": "7712e9bb-0595-4cb0-b7f9-9aeede95a3d2",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      }
    ]
  },
  {
    "id": "5bedb647-f913-429b-86c7-16d6ba0c2a2c",
    "mappings": [
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "Higher Learning",
        "area": "Math",
        "need": "Remediation"
      }
    ]
  },
  {
    "id": "f1707a65-a966-4732-876b-edbbc80fbc9b",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      }
    ]
  },
  {
    "id": "9dbf5480-bb9b-4a11-9231-db0f8bb8ee90",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Foreign Language",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Foreign Language",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Foreign Language",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Foreign Language",
        "need": "Test Prep"
      },
      {
        "grade": "9th Grade",
        "area": "Foreign Language",
        "need": "Mandarin Speaking, Listening, and Reading"
      },
      {
        "grade": "10th Grade",
        "area": "Foreign Language",
        "need": "Mandarin Speaking, Listening, and Reading"
      },
      {
        "grade": "11th Grade",
        "area": "Foreign Language",
        "need": "Mandarin Speaking, Listening, and Reading"
      },
      {
        "grade": "12th Grade",
        "area": "Foreign Language",
        "need": "Mandarin Speaking, Listening, and Reading"
      }
    ]
  },
  {
    "id": "9e741d24-50e1-4c81-98ab-37cc716064ed",
    "mappings": [
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Quantitative Analysis"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Policy"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Case Studies"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Quantitative Analysis"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Policy"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Case Studies"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Quantitative Analysis"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Policy"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Case Studies"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      }
    ]
  },
  {
    "id": "5e9d1fd4-5982-4cdf-86df-c3f93d3d64e1",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Computer Science",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Computer Science",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Computer Science",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Computer Science",
        "need": "Test Prep"
      }
    ]
  },
  {
    "id": "f349e342-8558-45dc-8448-8cfad315af2c",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Computer Science",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Computer Science",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Computer Science",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Computer Science",
        "need": "Test Prep"
      }
    ]
  },
  {
    "id": "9a32d19f-3fdb-48f5-a279-c7a7df6401c5",
    "mappings": [
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Literacy"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Literacy"
      }
    ]
  },
  {
    "id": "92fee573-1eac-469f-a807-7618401f74f2",
    "mappings": [
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Literacy"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Poetry"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Short Stories"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Shakespeare"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Literacy"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Poetry"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Short Stories"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Shakespeare"
      }
    ]
  },
  {
    "id": "1de2e4c2-2e4d-4c35-ab07-a6e66d66138e",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      }
    ]
  },
  {
    "id": "87df3993-6fcd-4308-9e30-0b0cb1442c31",
    "mappings": [
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Primary Sources"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Secondary Sources"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Critical Thinking"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Primary Sources"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Secondary Sources"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Critical Thinking"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "9th Grade",
        "area": "Social Studies",
        "need": "Extension for Higher-Learning Students"
      }
    ]
  },
  {
    "id": "26e2bdbf-6e84-4d34-b0b0-f654e4c213d4",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Foreign Language",
        "need": "French Speaking, Listening, and Reading"
      },
      {
        "grade": "9th Grade",
        "area": "Foreign Language",
        "need": "French Writing, Grammar, and Vocabulary"
      },
      {
        "grade": "10th Grade",
        "area": "Foreign Language",
        "need": "French Speaking, Listening, and Reading"
      },
      {
        "grade": "10th Grade",
        "area": "Foreign Language",
        "need": "French Writing, Grammar, and Vocabulary"
      },
      {
        "grade": "11th Grade",
        "area": "Foreign Language",
        "need": "French Speaking, Listening, and Reading"
      },
      {
        "grade": "11th Grade",
        "area": "Foreign Language",
        "need": "French Writing, Grammar, and Vocabulary"
      },
      {
        "grade": "12th Grade",
        "area": "Foreign Language",
        "need": "French Speaking, Listening, and Reading"
      },
      {
        "grade": "12th Grade",
        "area": "Foreign Language",
        "need": "French Writing, Grammar, and Vocabulary"
      },
      {
        "grade": "9th Grade",
        "area": "Foreign Language",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Foreign Language",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Foreign Language",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Foreign Language",
        "need": "Test Prep"
      }
    ]
  },
  {
    "id": "10adc919-a27d-422e-9afb-4bc3ab41442a",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Foreign Language",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Foreign Language",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Foreign Language",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Foreign Language",
        "need": "Test Prep"
      },
      {
        "grade": "9th Grade",
        "area": "Foreign Language",
        "need": "German Speaking, Listening, and Reading"
      },
      {
        "grade": "10th Grade",
        "area": "Foreign Language",
        "need": "German Speaking, Listening, and Reading"
      },
      {
        "grade": "11th Grade",
        "area": "Foreign Language",
        "need": "German Speaking, Listening, and Reading"
      },
      {
        "grade": "12th Grade",
        "area": "Foreign Language",
        "need": "German Speaking, Listening, and Reading"
      }
    ]
  },
  {
    "id": "bb894d4b-e307-4094-8d39-77b1880905f4",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Social Studies",
        "need": "Secondary Sources"
      },
      {
        "grade": "9th Grade",
        "area": "Social Studies",
        "need": "Policy"
      },
      {
        "grade": "9th Grade",
        "area": "Social Studies",
        "need": "Quantitative Analysis"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Secondary Sources"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Policy"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Quantitative Analysis"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Secondary Sources"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Policy"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Quantitative Analysis"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Secondary Sources"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Policy"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Quantitative Analysis"
      },
      {
        "grade": "9th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      }
    ]
  },
  {
    "id": "4b30c88a-3e55-4225-af88-34da5e4ef323",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Foreign Language",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Foreign Language",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Foreign Language",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Foreign Language",
        "need": "Test Prep"
      },
      {
        "grade": "9th Grade",
        "area": "Foreign Language",
        "need": "Italian Grammar and Vocabulary"
      },
      {
        "grade": "10th Grade",
        "area": "Foreign Language",
        "need": "Italian Grammar and Vocabulary"
      },
      {
        "grade": "11th Grade",
        "area": "Foreign Language",
        "need": "Italian Grammar and Vocabulary"
      },
      {
        "grade": "12th Grade",
        "area": "Foreign Language",
        "need": "Italian Grammar and Vocabulary"
      },
      {
        "grade": "9th Grade",
        "area": "Foreign Language",
        "need": "Italian Speaking, Listening, and Reading"
      },
      {
        "grade": "10th Grade",
        "area": "Foreign Language",
        "need": "Italian Speaking, Listening, and Reading"
      },
      {
        "grade": "11th Grade",
        "area": "Foreign Language",
        "need": "Italian Speaking, Listening, and Reading"
      },
      {
        "grade": "12th Grade",
        "area": "Foreign Language",
        "need": "Italian Speaking, Listening, and Reading"
      }
    ]
  },
  {
    "id": "f81c8831-e181-4661-9e16-cc262a38fbb6",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Foreign Language",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Foreign Language",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Foreign Language",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Foreign Language",
        "need": "Test Prep"
      },
      {
        "grade": "9th Grade",
        "area": "Foreign Language",
        "need": "Japanese Speaking, Listening, and Reading"
      },
      {
        "grade": "10th Grade",
        "area": "Foreign Language",
        "need": "Japanese Speaking, Listening, and Reading"
      },
      {
        "grade": "11th Grade",
        "area": "Foreign Language",
        "need": "Japanese Speaking, Listening, and Reading"
      },
      {
        "grade": "12th Grade",
        "area": "Foreign Language",
        "need": "Japanese Speaking, Listening, and Reading"
      }
    ]
  },
  {
    "id": "078e660d-cb34-4be7-a127-698391d4d16c",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Foreign Language",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Foreign Language",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Foreign Language",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Foreign Language",
        "need": "Test Prep"
      },
      {
        "grade": "9th Grade",
        "area": "Foreign Language",
        "need": "Latin Reading Comprehension and Translation"
      },
      {
        "grade": "10th Grade",
        "area": "Foreign Language",
        "need": "Latin Reading Comprehension and Translation"
      },
      {
        "grade": "11th Grade",
        "area": "Foreign Language",
        "need": "Latin Reading Comprehension and Translation"
      },
      {
        "grade": "12th Grade",
        "area": "Foreign Language",
        "need": "Latin Reading Comprehension and Translation"
      }
    ]
  },
  {
    "id": "50118483-8160-4fd7-ad2e-804465ab52aa",
    "mappings": [
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "Test Prep"
      }
    ]
  },
  {
    "id": "17225a1c-7d4a-46f9-80f0-e04784ced466",
    "mappings": [
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "Test Prep"
      }
    ]
  },
  {
    "id": "3ba60576-53a8-4f42-9017-8ad1442385d7",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Arts",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Arts",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Arts",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Arts",
        "need": "Test Prep"
      }
    ]
  },
  {
    "id": "2420d273-d77e-4c63-9008-e5945db0cdb3",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      }
    ]
  },
  {
    "id": "25263484-061c-4c74-95fb-02425bfd893d",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      }
    ]
  },
  {
    "id": "777501f2-c7a7-4151-a202-025757ad2505",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      }
    ]
  },
  {
    "id": "3e207550-ce23-42b2-aea1-3ebf9044da51",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      }
    ]
  },
  {
    "id": "2bab5308-a372-4877-a915-9af5bc1812c5",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      }
    ]
  },
  {
    "id": "8eaa46a2-7b2e-41e0-a9a0-f219890fa18f",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Foreign Language",
        "need": "Spanish Speaking, Listening, and Reading"
      },
      {
        "grade": "10th Grade",
        "area": "Foreign Language",
        "need": "Spanish Speaking, Listening, and Reading"
      },
      {
        "grade": "10th Grade",
        "area": "Foreign Language",
        "need": "Spanish Writing, Grammar, and Vocabulary"
      },
      {
        "grade": "11th Grade",
        "area": "Foreign Language",
        "need": "Spanish Speaking, Listening, and Reading"
      },
      {
        "grade": "11th Grade",
        "area": "Foreign Language",
        "need": "Spanish Writing, Grammar, and Vocabulary"
      },
      {
        "grade": "12th Grade",
        "area": "Foreign Language",
        "need": "Spanish Speaking, Listening, and Reading"
      },
      {
        "grade": "12th Grade",
        "area": "Foreign Language",
        "need": "Spanish Writing, Grammar, and Vocabulary"
      },
      {
        "grade": "9th Grade",
        "area": "Foreign Language",
        "need": "Spanish Writing, Grammar, and Vocabulary"
      },
      {
        "grade": "9th Grade",
        "area": "Foreign Language",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Foreign Language",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Foreign Language",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Foreign Language",
        "need": "Test Prep"
      }
    ]
  },
  {
    "id": "f10cbd32-db48-4e81-a019-99c139776f29",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Foreign Language",
        "need": "Spanish Literature"
      },
      {
        "grade": "10th Grade",
        "area": "Foreign Language",
        "need": "Spanish Literature"
      },
      {
        "grade": "11th Grade",
        "area": "Foreign Language",
        "need": "Spanish Literature"
      },
      {
        "grade": "12th Grade",
        "area": "Foreign Language",
        "need": "Spanish Literature"
      },
      {
        "grade": "10th Grade",
        "area": "Foreign Language",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Foreign Language",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Foreign Language",
        "need": "Test Prep"
      },
      {
        "grade": "9th Grade",
        "area": "Foreign Language",
        "need": "Extension for Higher-Learning Students"
      }
    ]
  },
  {
    "id": "1b026ba2-0a62-4147-8ad7-fd92a724666a",
    "mappings": [
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "Higher Learning",
        "area": "Math",
        "need": "Remediation"
      }
    ]
  },
  {
    "id": "a5ece7db-45db-44b4-893c-a4e438c56c93",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Social Studies",
        "need": "SCOTUS Cases"
      },
      {
        "grade": "9th Grade",
        "area": "Social Studies",
        "need": "Constitutionality"
      },
      {
        "grade": "9th Grade",
        "area": "Social Studies",
        "need": "Primary Sources"
      },
      {
        "grade": "9th Grade",
        "area": "Social Studies",
        "need": "Quantitative Analysis"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "SCOTUS Cases"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Constitutionality"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Primary Sources"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Quantitative Analysis"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "SCOTUS Cases"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Constitutionality"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Primary Sources"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Quantitative Analysis"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "SCOTUS Cases"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Constitutionality"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Primary Sources"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Quantitative Analysis"
      },
      {
        "grade": "9th Grade",
        "area": "Social Studies",
        "need": "Policy"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Policy"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Policy"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Policy"
      },
      {
        "grade": "9th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      }
    ]
  },
  {
    "id": "f68fb63e-9ef6-4def-8358-56f25954c9e6",
    "mappings": [
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Primary Sources"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Primary Sources"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Primary Sources"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Secondary Sources"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Secondary Sources"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Secondary Sources"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Constitutionality"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Constitutionality"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Constitutionality"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Critical Thinking"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Critical Thinking"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Critical Thinking"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "SCOTUS Cases"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "SCOTUS Cases"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "SCOTUS Cases"
      },
      {
        "grade": "9th Grade",
        "area": "Social Studies",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      }
    ]
  },
  {
    "id": "507acbc1-4c32-4fec-aba6-a2240274924a",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Social Studies",
        "need": "Primary Sources"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Primary Sources"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Primary Sources"
      },
      {
        "grade": "9th Grade",
        "area": "Social Studies",
        "need": "Secondary Sources"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Primary Sources"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Secondary Sources"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Secondary Sources"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Secondary Sources"
      },
      {
        "grade": "9th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      },
      {
        "grade": "9th Grade",
        "area": "Social Studies",
        "need": "Extension for Higher-Learning Students"
      }
    ]
  },
  {
    "id": "e615f430-94d5-4861-b4ca-d3719b18e1bf",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Shakespeare"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Shakespeare"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Shakespeare"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Shakespeare"
      }
    ]
  },
  {
    "id": "531ed01a-6e64-4298-b922-9c1d0852138b",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Poetry"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Poetry"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Poetry"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Poetry"
      }
    ]
  },
  {
    "id": "bd3c0da1-a23c-4c24-8179-c9970bc73633",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "Higher Learning",
        "area": "Science",
        "need": "Core Knowledge"
      }
    ]
  },
  {
    "id": "4469c8f8-73c0-499f-9c05-0ae45d1780d8",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Core Knowledge"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Core Knowledge"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Core Knowledge"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Core Knowledge"
      },
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Literacy"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Literacy"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Literacy"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Literacy"
      },
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "NGSS Practice"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "NGSS Practice"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "NGSS Practice"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "NGSS Practice"
      },
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "Higher Learning",
        "area": "Science",
        "need": "Remediation"
      },
      {
        "grade": "Higher Learning",
        "area": "Science",
        "need": "Core Knowledge"
      }
    ]
  },
  {
    "id": "b3715336-73ad-422d-8e14-4e8dd695f717",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      }
    ]
  },
  {
    "id": "a11484e0-056b-4ddc-b026-b3af40a70866",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Short Stories"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Short Stories"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Short Stories"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Short Stories"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      }
    ]
  },
  {
    "id": "8739a2fe-c438-4ee3-9920-830d1b0054f7",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      }
    ]
  },
  {
    "id": "e951d0f5-0d22-4d91-8a75-f629cfe8c873",
    "mappings": [
      {
        "grade": "6th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "7th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "8th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      }
    ]
  },
  {
    "id": "ebbc3525-7d6f-4985-a83e-3c1a4afd8a04",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "Higher Learning",
        "area": "Science",
        "need": "Core Knowledge"
      }
    ]
  },
  {
    "id": "897c6357-89bf-4079-be9a-625637699157",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Core Knowledge"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Core Knowledge"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Core Knowledge"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Core Knowledge"
      },
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "NGSS Practice"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "NGSS Practice"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "NGSS Practice"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "NGSS Practice"
      },
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "Higher Learning",
        "area": "Science",
        "need": "Remediation"
      },
      {
        "grade": "Higher Learning",
        "area": "Science",
        "need": "Core Knowledge"
      }
    ]
  },
  {
    "id": "b4374aef-ef6a-4c1e-b36b-dd107dfe62b5",
    "mappings": []
  },
  {
    "id": "e9d2c4b6-6bf8-4a4a-9c8a-f4f75e406f57",
    "mappings": []
  },
  {
    "id": "a9eb764c-96f4-4f52-b536-5b0ff6afd591",
    "mappings": []
  },
  {
    "id": "73dee6e3-5f0f-43d7-b33b-1c44d71a4e27",
    "mappings": []
  },
  {
    "id": "2b1b3433-c362-4c68-8e41-9e44b4e0bdcc",
    "mappings": []
  },
  {
    "id": "00e8a60d-ba67-4762-a9ae-83da54b94ad1",
    "mappings": []
  },
  {
    "id": "2a7fa73a-4324-4908-b7d7-61fc034af9f9",
    "mappings": []
  },
  {
    "id": "dd5f7901-15ca-47f3-b0e8-c9902ee2736f",
    "mappings": []
  },
  {
    "id": "f30748aa-41a5-4d61-9341-571b3514cdb7",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      }
    ]
  },
  {
    "id": "52e43da2-1a9c-4d8b-93b4-e4cbe6ba5439",
    "mappings": [
      {
        "grade": "Higher Learning",
        "area": "Science",
        "need": "Core Knowledge"
      }
    ]
  },
  {
    "id": "f7f81254-0614-4616-a722-b5680b4cf881",
    "mappings": []
  },
  {
    "id": "d6587ed2-0f40-4fc5-8ef8-88ad4749b6b1",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      }
    ]
  },
  {
    "id": "bd53972b-6d1b-4657-84bb-2996361faaf4",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Core Knowledge"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Core Knowledge"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Core Knowledge"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Core Knowledge"
      },
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "NGSS Practice"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "NGSS Practice"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "NGSS Practice"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "NGSS Practice"
      },
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      }
    ]
  },
  {
    "id": "87723a5a-d4ea-4ae2-b266-41913a23e267",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "Higher Learning",
        "area": "Science",
        "need": "Core Knowledge"
      }
    ]
  },
  {
    "id": "f61fb955-bdc3-46ae-8caa-29b9d621a1f5",
    "mappings": []
  },
  {
    "id": "b8b4f16d-13eb-4a1c-b81e-609f38a5c23b",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Short Stories"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Short Stories"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Short Stories"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Short Stories"
      }
    ]
  },
  {
    "id": "130822a0-954f-4d27-ad3e-873ca6c991bc",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Poetry"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Short Stories"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Poetry"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Short Stories"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Poetry"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Short Stories"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Poetry"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Short Stories"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "TEKS-aligned"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "TEKS-aligned"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "TEKS-aligned"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "TEKS-aligned"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Regents-aligned"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Regents-aligned"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Regents-aligned"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Regents-aligned"
      }
    ]
  },
  {
    "id": "5c21e5fb-0884-4f01-99ba-7fd8bbae08c1",
    "mappings": [
      {
        "grade": "6th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "7th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "8th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      }
    ]
  },
  {
    "id": "c5a0356e-ba28-49fd-bbae-6feeec0b8c13",
    "mappings": []
  },
  {
    "id": "242d55c8-9a0c-47ce-8e1c-1d7d4c0c45e7",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "9th Grade",
        "area": "Social Studies",
        "need": "Primary Sources"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Primary Sources"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Primary Sources"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Primary Sources"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Regents-aligned"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Regents-aligned"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Regents-aligned"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Regents-aligned"
      }
    ]
  },
  {
    "id": "2f57784c-09a8-43e7-9f2c-536141365bc3",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      }
    ]
  },
  {
    "id": "2b3f6aa1-0bde-4d88-b482-3d815cf69cbb",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "Higher Learning",
        "area": "Science",
        "need": "Core Knowledge"
      }
    ]
  },
  {
    "id": "74823419-4dc6-474d-af15-81a4455e567e",
    "mappings": [
      {
        "grade": "8th Grade",
        "area": "Math",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "Application Questions"
      },
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "Conceptual Questions"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Application Questions"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Conceptual Questions"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Remediation"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Skills Practice"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Application Questions"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Remediation"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Skills Practice"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Conceptual Questions"
      },
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "Remediation"
      },
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "Regents-aligned"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Regents-aligned"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Regents-aligned"
      },
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "TEKS-aligned"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "TEKS-aligned"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "TEKS-aligned"
      }
    ]
  },
  {
    "id": "3f437fc7-2443-438d-b3ef-cc0b4a26a400",
    "mappings": [
      {
        "grade": "Higher Learning",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "Higher Learning",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "Higher Learning",
        "area": "ELA",
        "need": "Vocabulary Practice"
      },
      {
        "grade": "Higher Learning",
        "area": "Math",
        "need": "Test Prep"
      }
    ]
  },
  {
    "id": "b2c1ae90-2fe0-4fda-8ad0-7f5d10a7aa22",
    "mappings": [
      {
        "grade": "Higher Learning",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "Higher Learning",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "Higher Learning",
        "area": "ELA",
        "need": "Vocabulary Practice"
      },
      {
        "grade": "Higher Learning",
        "area": "Math",
        "need": "Test Prep"
      }
    ]
  },
  {
    "id": "3423eddd-c43c-4c53-8040-366b877c30ec",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Shakespeare"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Shakespeare"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Shakespeare"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Shakespeare"
      }
    ]
  },
  {
    "id": "731d4dac-f70b-4a37-ace8-13948b4f64e1",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      }
    ]
  },
  {
    "id": "bcf76c0c-80a0-484f-8574-54ab892117db",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Shakespeare"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Shakespeare"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Shakespeare"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Shakespeare"
      }
    ]
  },
  {
    "id": "196b3edc-8213-485b-93d0-cada9c48bb20",
    "mappings": [
      {
        "grade": "Higher Learning",
        "area": "Science",
        "need": "Core Knowledge"
      }
    ]
  },
  {
    "id": "c1a97aaa-3ef1-4c7d-a2af-c6fca90ab7a4",
    "mappings": [
      {
        "grade": "Higher Learning",
        "area": "Science",
        "need": "Core Knowledge"
      }
    ]
  },
  {
    "id": "9c82fd83-5a89-4a1f-8f02-76c4ac42d337",
    "mappings": [
      {
        "grade": "6th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "7th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "8th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "6th Grade",
        "area": "ELA",
        "need": "Short Stories"
      },
      {
        "grade": "7th Grade",
        "area": "ELA",
        "need": "Short Stories"
      },
      {
        "grade": "8th Grade",
        "area": "ELA",
        "need": "Short Stories"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Short Stories"
      }
    ]
  },
  {
    "id": "1051a4be-1643-4bc3-8011-0ca3986df88c",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      }
    ]
  },
  {
    "id": "9b7bbd12-b01f-48c4-9403-b2cac7a36e10",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Short Stories"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Short Stories"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Short Stories"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Short Stories"
      }
    ]
  },
  {
    "id": "417bd359-85ab-4551-946f-5c19f52f0724",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Shakespeare"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Shakespeare"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Shakespeare"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Shakespeare"
      }
    ]
  },
  {
    "id": "af92c4da-841a-42c9-a372-8bf910de3b73",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Shakespeare"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Shakespeare"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Shakespeare"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Shakespeare"
      }
    ]
  },
  {
    "id": "e0d0ddab-8ef8-4159-b4e7-8acfe66820b6",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Short Stories"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Short Stories"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Short Stories"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Short Stories"
      }
    ]
  },
  {
    "id": "22a8d72f-fc16-40ce-9bcc-6d1113f9dc34",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Lexile-Leveled Passages"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Lexile-Leveled Passages"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Lexile-Leveled Passages"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Lexile-Leveled Passages"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Regents-aligned"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Regents-aligned"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Regents-aligned"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Regents-aligned"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "9th Grade",
        "area": "Social Studies",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "9th Grade",
        "area": "Social Studies",
        "need": "Core Knowledge"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Core Knowledge"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Core Knowledge"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Core Knowledge"
      }
    ]
  },
  {
    "id": "68a495a3-3c06-4f37-9aab-73266eebb306",
    "mappings": []
  },
  {
    "id": "a97e5f95-c012-4128-9a1a-f0a193469fc8",
    "mappings": [
      {
        "grade": "6th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      },
      {
        "grade": "7th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      },
      {
        "grade": "8th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      }
    ]
  },
  {
    "id": "220ddc79-8faa-439e-8f6c-10425d2273ee",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      }
    ]
  },
  {
    "id": "acfcb959-9e9c-4834-96ab-df719ffe2bf6",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Shakespeare"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Shakespeare"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Shakespeare"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Shakespeare"
      }
    ]
  },
  {
    "id": "f4564c68-2d74-45bb-8227-5772db95e71e",
    "mappings": []
  },
  {
    "id": "02ce61d4-dd08-43d0-96d1-535992d8df86",
    "mappings": [
      {
        "grade": "Higher Learning",
        "area": "Science",
        "need": "Test Prep"
      }
    ]
  },
  {
    "id": "dc8ed498-008d-4a91-a1c0-40423d51fce6",
    "mappings": [
      {
        "grade": "5th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "6th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "7th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "8th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "5th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "6th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "7th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "8th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "5th Grade",
        "area": "ELA",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "5th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "6th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "7th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "8th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "6th Grade",
        "area": "Social Studies",
        "need": "Core Knowledge"
      },
      {
        "grade": "7th Grade",
        "area": "Social Studies",
        "need": "Core Knowledge"
      },
      {
        "grade": "8th Grade",
        "area": "Social Studies",
        "need": "Core Knowledge"
      },
      {
        "grade": "6th Grade",
        "area": "Social Studies",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "7th Grade",
        "area": "Social Studies",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "8th Grade",
        "area": "Social Studies",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "5th Grade",
        "area": "ELA",
        "need": "Short Stories"
      },
      {
        "grade": "5th Grade",
        "area": "ELA",
        "need": "Poetry"
      },
      {
        "grade": "6th Grade",
        "area": "ELA",
        "need": "Poetry"
      },
      {
        "grade": "7th Grade",
        "area": "ELA",
        "need": "Poetry"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Short Stories"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Remediation"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Remediation"
      },
      {
        "grade": "7th Grade",
        "area": "ELA",
        "need": "Short Stories"
      },
      {
        "grade": "6th Grade",
        "area": "ELA",
        "need": "Short Stories"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Remediation"
      },
      {
        "grade": "8th Grade",
        "area": "ELA",
        "need": "Short Stories"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Remediation"
      },
      {
        "grade": "8th Grade",
        "area": "ELA",
        "need": "Poetry"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Poetry"
      }
    ]
  },
  {
    "id": "143e1b6d-f29d-4c33-8439-51ead8e4f93f",
    "mappings": [
      {
        "grade": "6th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "7th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "8th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "5th Grade",
        "area": "ELA",
        "need": "Lexile-Leveled Passages"
      },
      {
        "grade": "6th Grade",
        "area": "ELA",
        "need": "Lexile-Leveled Passages"
      },
      {
        "grade": "7th Grade",
        "area": "ELA",
        "need": "Lexile-Leveled Passages"
      },
      {
        "grade": "8th Grade",
        "area": "ELA",
        "need": "Lexile-Leveled Passages"
      },
      {
        "grade": "5th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "6th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "7th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "8th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "6th Grade",
        "area": "Social Studies",
        "need": "Core Knowledge"
      },
      {
        "grade": "7th Grade",
        "area": "Social Studies",
        "need": "Core Knowledge"
      },
      {
        "grade": "8th Grade",
        "area": "Social Studies",
        "need": "Core Knowledge"
      },
      {
        "grade": "5th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Remediation"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Remediation"
      },
      {
        "grade": "5th Grade",
        "area": "ELA",
        "need": "Short Passages for Building Stamina"
      },
      {
        "grade": "8th Grade",
        "area": "ELA",
        "need": "Short Passages for Building Stamina"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Remediation"
      },
      {
        "grade": "6th Grade",
        "area": "ELA",
        "need": "Short Passages for Building Stamina"
      },
      {
        "grade": "7th Grade",
        "area": "ELA",
        "need": "Short Passages for Building Stamina"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Remediation"
      },
      {
        "grade": "6th Grade",
        "area": "Social Studies",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "7th Grade",
        "area": "Social Studies",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "8th Grade",
        "area": "Social Studies",
        "need": "Interdisciplinary Themes"
      }
    ]
  },
  {
    "id": "48d6dd2f-093f-49ce-826b-2b7a7914d326",
    "mappings": [
      {
        "grade": "5th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "6th Grade",
        "area": "Science",
        "need": "NGSS Practice"
      },
      {
        "grade": "7th Grade",
        "area": "Science",
        "need": "NGSS Practice"
      },
      {
        "grade": "8th Grade",
        "area": "Science",
        "need": "NGSS Practice"
      },
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Remediation"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Remediation"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Remediation"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Remediation"
      }
    ]
  },
  {
    "id": "d14d4a97-7403-4458-bad6-1718fc9945bc",
    "mappings": []
  },
  {
    "id": "9c68e83c-d8ee-4fc7-b07c-d1b03866c616",
    "mappings": [
      {
        "grade": "6th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      },
      {
        "grade": "7th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      },
      {
        "grade": "8th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      }
    ]
  },
  {
    "id": "d8150994-0b33-4c12-88d3-ecc66fff05fe",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "Higher Learning",
        "area": "Science",
        "need": "Core Knowledge"
      }
    ]
  },
  {
    "id": "f1097a48-29a6-4ac0-ac3c-3c5407e69dce",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      }
    ]
  },
  {
    "id": "a2af71d1-bce0-45c8-b09f-23d50d4efb14",
    "mappings": []
  },
  {
    "id": "a2302922-caa2-4d1f-a569-de0c4c2168fb",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Drama"
      }
    ]
  },
  {
    "id": "ecad97dd-5334-4187-a788-c48783e57213",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      }
    ]
  },
  {
    "id": "4194726e-8f30-446f-b920-c105e5e37087",
    "mappings": [
      {
        "grade": "6th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      },
      {
        "grade": "7th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      },
      {
        "grade": "8th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      }
    ]
  },
  {
    "id": "8419998c-272e-425e-99de-931f7c2037ec",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "Higher Learning",
        "area": "Science",
        "need": "Core Knowledge"
      }
    ]
  },
  {
    "id": "826103bd-79bd-496f-9493-b5e0d14a5f55",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "Higher Learning",
        "area": "Science",
        "need": "Core Knowledge"
      }
    ]
  },
  {
    "id": "294b45ec-a5f7-4378-928d-0aa914887c7f",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Shakespeare"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Shakespeare"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Shakespeare"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Shakespeare"
      }
    ]
  },
  {
    "id": "041f2928-ecfb-40a0-b8a5-8fbfe06cc824",
    "mappings": [
      {
        "grade": "Higher Learning",
        "area": "Science",
        "need": "Core Knowledge"
      }
    ]
  },
  {
    "id": "41d58d96-8043-44bf-b088-13bba4a4b6ab",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Core Knowledge"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Core Knowledge"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Core Knowledge"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Core Knowledge"
      },
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "NGSS Practice"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "NGSS Practice"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "NGSS Practice"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "NGSS Practice"
      },
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "Higher Learning",
        "area": "Science",
        "need": "Remediation"
      },
      {
        "grade": "Higher Learning",
        "area": "Science",
        "need": "Core Knowledge"
      }
    ]
  },
  {
    "id": "3e57f8b2-dc22-4ae4-bfbb-0aa9a619c9d4",
    "mappings": [
      {
        "grade": "6th Grade",
        "area": "Math",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "7th Grade",
        "area": "Math",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "8th Grade",
        "area": "Math",
        "need": "Application Questions"
      },
      {
        "grade": "8th Grade",
        "area": "Math",
        "need": "Conceptual Questions"
      },
      {
        "grade": "8th Grade",
        "area": "Math",
        "need": "Skills Practice"
      },
      {
        "grade": "8th Grade",
        "area": "Math",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "7th Grade",
        "area": "Math",
        "need": "Application Questions"
      },
      {
        "grade": "7th Grade",
        "area": "Math",
        "need": "Conceptual Questions"
      },
      {
        "grade": "7th Grade",
        "area": "Math",
        "need": "Skills Practice"
      },
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "Application Questions"
      },
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "Conceptual Questions"
      },
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "Skills Practice"
      },
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "Remediation"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Application Questions"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Conceptual Questions"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Skills Practice"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Remediation"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Remediation"
      },
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "Remediation"
      }
    ]
  },
  {
    "id": "5df8c19b-e887-4617-bc87-13dad4dde419",
    "mappings": [
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Application Questions"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Conceptual Questions"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Skills Practice"
      },
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "Remediation"
      },
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "Skills Practice"
      },
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "Conceptual Questions"
      },
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "Application Questions"
      },
      {
        "grade": "Higher Learning",
        "area": "Math",
        "need": "Remediation"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "TEKS-aligned"
      },
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "TEKS-aligned"
      },
      {
        "grade": "Higher Learning",
        "area": "Math",
        "need": "TEKS-aligned"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Regents-aligned"
      },
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "Regents-aligned"
      },
      {
        "grade": "Higher Learning",
        "area": "Math",
        "need": "Regents-aligned"
      }
    ]
  },
  {
    "id": "695e9ff6-89ea-4d81-b277-efe00fa24424",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      }
    ]
  },
  {
    "id": "0ad680bf-0d86-4eda-80f0-e6c66e2a9d3c",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Drama"
      }
    ]
  },
  {
    "id": "d788758e-ccdb-447b-bb94-241d206bc829",
    "mappings": [
      {
        "grade": "6th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      },
      {
        "grade": "7th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      },
      {
        "grade": "8th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      }
    ]
  },
  {
    "id": "8c4b20eb-12df-48fc-bfc8-9f8e6c5edd07",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Shakespeare"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Shakespeare"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Shakespeare"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Shakespeare"
      }
    ]
  },
  {
    "id": "f62efc47-93a7-4c1b-8885-16028fbd27f1",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "Application Questions"
      },
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Application Questions"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Application Questions"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "Application Questions"
      },
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "PSAT"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "PSAT"
      }
    ]
  },
  {
    "id": "19b8e13d-3ec5-4346-872e-50f8635aa2a5",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "PSAT"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Test Prep"
      }
    ]
  },
  {
    "id": "78167c97-c9a6-47c1-afc8-b7717e41fb37",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "PSAT"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "PSAT"
      }
    ]
  },
  {
    "id": "84c45819-4eed-4919-87c4-d30bb5c12c15",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Short Passages for Building Stamina"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Short Passages for Building Stamina"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Short Passages for Building Stamina"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Short Passages for Building Stamina"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Remediation"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Remediation"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Remediation"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Remediation"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "TEKS-aligned"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "TEKS-aligned"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "TEKS-aligned"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "TEKS-aligned"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Regents-aligned"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Regents-aligned"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Regents-aligned"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Regents-aligned"
      }
    ]
  },
  {
    "id": "a49bb6cf-777a-433f-9b82-afbea806c5da",
    "mappings": []
  },
  {
    "id": "12f15fc6-b84b-4e29-9678-4a4c282c3903",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      }
    ]
  },
  {
    "id": "2ab5096c-d6ba-41c3-a596-0fe24ae42400",
    "mappings": [
      {
        "grade": "5th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "6th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "7th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "8th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "5th Grade",
        "area": "ELA",
        "need": "Vocabulary Practice"
      },
      {
        "grade": "6th Grade",
        "area": "ELA",
        "need": "Vocabulary Practice"
      },
      {
        "grade": "7th Grade",
        "area": "ELA",
        "need": "Vocabulary Practice"
      },
      {
        "grade": "8th Grade",
        "area": "ELA",
        "need": "Vocabulary Practice"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Vocabulary Practice"
      }
    ]
  },
  {
    "id": "b108f8fa-4878-47e0-afda-ed0f604ba0e3",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      }
    ]
  },
  {
    "id": "c0f73904-a3d5-4627-b061-a35ba4d53399",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      }
    ]
  },
  {
    "id": "affca601-2966-4d42-9b85-2af7f41b79c2",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Drama"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Drama"
      }
    ]
  },
  {
    "id": "77ad54ec-7f2d-436a-85d8-4d1b393d1d5e",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      }
    ]
  },
  {
    "id": "3d14ce4e-6a89-4e3b-9930-8cbb0c938be3",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Poetry"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Poetry"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Poetry"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Poetry"
      }
    ]
  },
  {
    "id": "854285f7-9634-4dbc-8c17-8e069651c5d8",
    "mappings": [
      {
        "grade": "5th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "6th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "7th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "8th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "5th Grade",
        "area": "ELA",
        "need": "Vocabulary Practice"
      },
      {
        "grade": "6th Grade",
        "area": "ELA",
        "need": "Vocabulary Practice"
      },
      {
        "grade": "7th Grade",
        "area": "ELA",
        "need": "Vocabulary Practice"
      },
      {
        "grade": "8th Grade",
        "area": "ELA",
        "need": "Vocabulary Practice"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Vocabulary Practice"
      }
    ]
  },
  {
    "id": "f5d2ff31-3eb7-4363-ae7f-4bd47c8b91d5",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      }
    ]
  },
  {
    "id": "59d1013e-ed30-4311-8dd7-39d02eba5313",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      }
    ]
  },
  {
    "id": "e075b980-6952-4e78-8c84-522c73b6b24b",
    "mappings": [
      {
        "grade": "5th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "6th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "7th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "8th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "5th Grade",
        "area": "ELA",
        "need": "Vocabulary Practice"
      },
      {
        "grade": "6th Grade",
        "area": "ELA",
        "need": "Vocabulary Practice"
      },
      {
        "grade": "7th Grade",
        "area": "ELA",
        "need": "Vocabulary Practice"
      },
      {
        "grade": "8th Grade",
        "area": "ELA",
        "need": "Vocabulary Practice"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Vocabulary Practice"
      }
    ]
  },
  {
    "id": "6f37f765-9600-43fd-acd7-f23e95749aab",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      }
    ]
  },
  {
    "id": "f49e9779-6990-4f8e-bba4-25af29121587",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      }
    ]
  },
  {
    "id": "51b0c30b-3762-4387-859c-26447405a9d1",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Poetry"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Poetry"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Poetry"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Poetry"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      }
    ]
  },
  {
    "id": "edb37fd1-558e-47f4-9c96-24d106bccff8",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      }
    ]
  },
  {
    "id": "595e7b8e-e290-42b8-bd32-7f335dfff418",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      }
    ]
  },
  {
    "id": "120a0967-3679-46ff-941c-6cf05457a21c",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      }
    ]
  },
  {
    "id": "bf1596f0-34b0-479f-9891-f24ebd9ac543",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      }
    ]
  },
  {
    "id": "f37ee0e7-e917-409e-9686-350de1c9406d",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      }
    ]
  },
  {
    "id": "bee7aa03-cc59-468a-a2c7-85182f351445",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      }
    ]
  },
  {
    "id": "712c517e-3bd7-456f-8490-a6024de5e8a5",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      }
    ]
  },
  {
    "id": "b0fc8f12-e4e1-49da-a052-cff93e0a3560",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      }
    ]
  },
  {
    "id": "789f56e9-866f-48cb-ab77-814b4c9e71ef",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      }
    ]
  },
  {
    "id": "ac834791-c117-4ddd-b098-4db6569312a0",
    "mappings": []
  },
  {
    "id": "4594ab13-3b30-4641-974b-9dce642666c7",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      }
    ]
  },
  {
    "id": "2200781f-3cfb-483c-9117-52da31ac6e56",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Short Stories"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Short Stories"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Short Stories"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Short Stories"
      }
    ]
  },
  {
    "id": "26fba1fd-046a-4cd7-b37c-0bdc9a31b222",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      },
      {
        "grade": "9th Grade",
        "area": "Social Studies",
        "need": "TEKS-aligned"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "TEKS-aligned"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "TEKS-aligned"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "TEKS-aligned"
      }
    ]
  },
  {
    "id": "333d1783-6c10-4e0c-99b1-b1eb7d16eb7a",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      },
      {
        "grade": "9th Grade",
        "area": "Social Studies",
        "need": "Regents-aligned"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Regents-aligned"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Regents-aligned"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Regents-aligned"
      }
    ]
  },
  {
    "id": "9c6c91c0-6928-4b74-ade8-19456cdcc57d",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Regents-aligned"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Regents-aligned"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Regents-aligned"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Regents-aligned"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Test Prep"
      }
    ]
  },
  {
    "id": "ed34cf85-c487-409e-b9c2-9c3b9f4b7324",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "TEKS-aligned"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "TEKS-aligned"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "TEKS-aligned"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "TEKS-aligned"
      }
    ]
  },
  {
    "id": "b7eb793f-d810-4335-ab88-0fe3a83c6d2a",
    "mappings": [
      {
        "grade": "7th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "8th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "7th Grade",
        "area": "Math",
        "need": "Regents-aligned"
      },
      {
        "grade": "8th Grade",
        "area": "Math",
        "need": "Regents-aligned"
      },
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "Regents-aligned"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Regents-aligned"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Regents-aligned"
      },
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "Regents-aligned"
      }
    ]
  },
  {
    "id": "15951ae3-e1ba-4de7-b589-2f53ed68d009",
    "mappings": [
      {
        "grade": "7th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "8th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "7th Grade",
        "area": "Science",
        "need": "Regents-aligned"
      },
      {
        "grade": "8th Grade",
        "area": "Science",
        "need": "Regents-aligned"
      },
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Regents-aligned"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Regents-aligned"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Regents-aligned"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Regents-aligned"
      }
    ]
  },
  {
    "id": "2a91a35e-f98a-4b71-b7b3-f2f6ebc95279",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "TEKS-aligned"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "TEKS-aligned"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "TEKS-aligned"
      },
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "TEKS-aligned"
      }
    ]
  },
  {
    "id": "616b44d8-4bbb-4dc3-8b71-6c04a00ea012",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "TEKS-aligned"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "TEKS-aligned"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "TEKS-aligned"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "TEKS-aligned"
      }
    ]
  },
  {
    "id": "3d546431-155d-427b-ba36-a672d29f35d4",
    "mappings": [
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Regents-aligned"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "TEKS-aligned"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Regents-aligned"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "TEKS-aligned"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      },
      {
        "grade": "9th Grade",
        "area": "Social Studies",
        "need": "Core Knowledge"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Core Knowledge"
      },
      {
        "grade": "6th Grade",
        "area": "Social Studies",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "7th Grade",
        "area": "Social Studies",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "8th Grade",
        "area": "Social Studies",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "8th Grade",
        "area": "Social Studies",
        "need": "Core Knowledge"
      }
    ]
  },
  {
    "id": "4cac28f1-e6c6-4bf7-9b5f-c13c19ca9253",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Social Studies",
        "need": "Regents-aligned"
      },
      {
        "grade": "9th Grade",
        "area": "Social Studies",
        "need": "TEKS-aligned"
      },
      {
        "grade": "9th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Regents-aligned"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "TEKS-aligned"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      },
      {
        "grade": "8th Grade",
        "area": "Social Studies",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "8th Grade",
        "area": "Social Studies",
        "need": "Core Knowledge"
      },
      {
        "grade": "7th Grade",
        "area": "Social Studies",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "6th Grade",
        "area": "Social Studies",
        "need": "Extension for Higher-Learning Students"
      }
    ]
  },
  {
    "id": "19b8e13d-3ec5-4346-872e-50f8635aa2a5",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Extension for Higher-Learning Students"
      }
    ]
  },
  {
    "id": "78b7a6c6-7c4d-479e-9fae-91a4fc3e287b",
    "mappings": [
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "TEKS-aligned"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "TEKS-aligned"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Regents-aligned"
      }
    ]
  },
  {
    "id": "d4de2394-3736-4092-96c5-2e4784f6b17f",
    "mappings": [
      {
        "grade": "5th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "6th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "5th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "7th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "8th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "6th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "7th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "8th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      }
    ]
  },
  {
    "id": "c5e97beb-3e77-4525-88e2-2c787c4c51ab",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Social Studies",
        "need": "Core Knowledge"
      },
      {
        "grade": "9th Grade",
        "area": "Social Studies",
        "need": "Application Questions"
      },
      {
        "grade": "9th Grade",
        "area": "Social Studies",
        "need": "Constitutionality"
      },
      {
        "grade": "9th Grade",
        "area": "Social Studies",
        "need": "Policy"
      },
      {
        "grade": "9th Grade",
        "area": "Social Studies",
        "need": "Quantitative Analysis"
      },
      {
        "grade": "9th Grade",
        "area": "Social Studies",
        "need": "Primary Sources"
      },
      {
        "grade": "9th Grade",
        "area": "Social Studies",
        "need": "SCOTUS Cases"
      },
      {
        "grade": "9th Grade",
        "area": "Social Studies",
        "need": "Secondary Sources"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "TEKS-aligned"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Application Questions"
      },
      {
        "grade": "8th Grade",
        "area": "Social Studies",
        "need": "Constitutionality"
      },
      {
        "grade": "8th Grade",
        "area": "Social Studies",
        "need": "Core Knowledge"
      },
      {
        "grade": "8th Grade",
        "area": "Social Studies",
        "need": "Application Questions"
      },
      {
        "grade": "8th Grade",
        "area": "Social Studies",
        "need": "Policy"
      },
      {
        "grade": "8th Grade",
        "area": "Social Studies",
        "need": "Quantitative Analysis"
      },
      {
        "grade": "8th Grade",
        "area": "Social Studies",
        "need": "Primary Sources"
      },
      {
        "grade": "8th Grade",
        "area": "Social Studies",
        "need": "SCOTUS Cases"
      },
      {
        "grade": "8th Grade",
        "area": "Social Studies",
        "need": "Secondary Sources"
      },
      {
        "grade": "8th Grade",
        "area": "Social Studies",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "9th Grade",
        "area": "Social Studies",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Constitutionality"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Core Knowledge"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Primary Sources"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Policy"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Quantitative Analysis"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Secondary Sources"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "TEKS-aligned"
      },
      {
        "grade": "9th Grade",
        "area": "Social Studies",
        "need": "Regents-aligned"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Regents-aligned"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Regents-aligned"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Constitutionality"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Core Knowledge"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Primary Sources"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Policy"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Quantitative Analysis"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Secondary Sources"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "TEKS-aligned"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Application Questions"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Regents-aligned"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Constitutionality"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Core Knowledge"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Primary Sources"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Policy"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Quantitative Analysis"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Secondary Sources"
      }
    ]
  },
  {
    "id": "9e093e31-6c51-4449-b92a-84eecfc3e8a8",
    "mappings": [
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Remediation"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Remediation"
      },
      {
        "grade": "9th Grade",
        "area": "Social Studies",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Core Knowledge"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Core Knowledge"
      }
    ]
  },
  {
    "id": "6f15f549-fd04-4407-a1b5-e12e19ac1370",
    "mappings": [
      {
        "grade": "6th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "7th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "8th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "6th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "7th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "8th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      }
    ]
  },
  {
    "id": "e4da33ba-1209-41c1-9910-48c82477aee5",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Remediation"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "TEKS-aligned"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Regents-aligned"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Remediation"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "TEKS-aligned"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Regents-aligned"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Remediation"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "TEKS-aligned"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Regents-aligned"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Remediation"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "TEKS-aligned"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Regents-aligned"
      }
    ]
  },
  {
    "id": "e4da33ba-1209-41c1-9910-48c82477aee5",
    "mappings": []
  },
  {
    "id": "f5743ae3-d926-4cdf-aa50-74dc75ef089b",
    "mappings": [
      {
        "grade": "5th Grade",
        "area": "ELA",
        "need": "Vocabulary Practice"
      },
      {
        "grade": "6th Grade",
        "area": "ELA",
        "need": "Vocabulary Practice"
      },
      {
        "grade": "7th Grade",
        "area": "ELA",
        "need": "Remediation"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Remediation"
      },
      {
        "grade": "8th Grade",
        "area": "ELA",
        "need": "Vocabulary Practice"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Remediation"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Regents-aligned"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Vocabulary Practice"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Regents-aligned"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Vocabulary Practice"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Vocabulary Practice"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Remediation"
      },
      {
        "grade": "5th Grade",
        "area": "ELA",
        "need": "Remediation"
      },
      {
        "grade": "6th Grade",
        "area": "ELA",
        "need": "Remediation"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Vocabulary Practice"
      },
      {
        "grade": "7th Grade",
        "area": "ELA",
        "need": "Vocabulary Practice"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Remediation"
      },
      {
        "grade": "8th Grade",
        "area": "ELA",
        "need": "Remediation"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Regents-aligned"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Regents-aligned"
      }
    ]
  },
  {
    "id": "4489020e-44b5-48a3-9717-3e7cde7b1b14",
    "mappings": [
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Vocabulary Practice"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Interdisciplinary Themes"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Reading Quizzes and Literature Circles"
      }
    ]
  },
  {
    "id": "61fd0c08-737f-42b5-9b15-3f887bbb49bd",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "Application Questions"
      },
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Application Questions"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Application Questions"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "PSAT"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "PSAT"
      }
    ]
  },
  {
    "id": "9734b544-75e8-42e6-b8e7-d02077705230",
    "mappings": [
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "PSAT"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "PSAT"
      }
    ]
  },
  {
    "id": "11a048e1-afd4-4ee3-ba45-448003916cce",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Grammar, Mechanics, and Usage"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Passage-Based Practice"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Skills Practice"
      },
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "PSAT"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "PSAT"
      }
    ]
  },
  {
    "id": "87dd8f21-8090-4b89-bae8-e625ad697973",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Test Prep"
      }
    ]
  },
  {
    "id": "3ed3b952-3d63-41bb-b264-73e9cd326dce",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "Test Prep"
      }
    ]
  },
  {
    "id": "6ce4f1d8-fa64-4763-b169-8a75b66d695d",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Test Prep"
      }
    ]
  },
  {
    "id": "6c9e588c-d8fc-4194-90eb-dfeda794ed92",
    "mappings": [
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Test Prep"
      }
    ]
  },
  {
    "id": "da94af63-2774-4456-9814-17ec21455cf2",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      }
    ]
  },
  {
    "id": "de870284-3d28-4220-bfcb-368667bac846",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Social Studies",
        "need": "Test Prep"
      }
    ]
  },
  {
    "id": "41d888ff-ce3e-47ce-ab11-6684d0c88836",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Test Prep"
      }
    ]
  },
  {
    "id": "fe12e28a-1d93-431a-af52-42f5c8d22600",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "ELA",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "ELA",
        "need": "Test Prep"
      }
    ]
  },
  {
    "id": "57896f8d-5be4-4926-8f8f-015752f6e1c6",
    "mappings": [
      {
        "grade": "9th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "Test Prep"
      }
    ]
  },
  {
    "id": "2005f70a-1bbd-414e-bd53-d8b797d93e62",
    "mappings": [
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "11th Grade",
        "area": "Math",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "12th Grade",
        "area": "Math",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "Higher Learning",
        "area": "Math",
        "need": "Remediation"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Test Prep"
      },
      {
        "grade": "10th Grade",
        "area": "Math",
        "need": "Extension for Higher-Learning Students"
      }
    ]
  },
  {
    "id": "1585ec40-d360-4a50-97f3-67129d5d390e",
    "mappings": [
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Core Knowledge"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Core Knowledge"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Core Knowledge"
      },
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Core Knowledge"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Case Studies"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Case Studies"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Case Studies"
      },
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Case Studies"
      },
      {
        "grade": "Higher Learning",
        "area": "Science",
        "need": "Remediation"
      },
      {
        "grade": "9th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "10th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "11th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      },
      {
        "grade": "12th Grade",
        "area": "Science",
        "need": "Extension for Higher-Learning Students"
      }
    ]
  },
  {
    "id": "d513ddb7-5a27-4eac-9acf-b9fbdca715ef",
    "mappings": [
      {
        "grade": "8th Grade",
        "area": "Foreign Language",
        "need": "French Writing, Grammar, and Vocabulary"
      },
      {
        "grade": "8th Grade",
        "area": "Foreign Language",
        "need": "French Speaking, Listening, and Reading"
      },
      {
        "grade": "9th Grade",
        "area": "Foreign Language",
        "need": "French Writing, Grammar, and Vocabulary"
      },
      {
        "grade": "9th Grade",
        "area": "Foreign Language",
        "need": "French Speaking, Listening, and Reading"
      },
      {
        "grade": "10th Grade",
        "area": "Foreign Language",
        "need": "French Writing, Grammar, and Vocabulary"
      },
      {
        "grade": "10th Grade",
        "area": "Foreign Language",
        "need": "French Speaking, Listening, and Reading"
      },
      {
        "grade": "11th Grade",
        "area": "Foreign Language",
        "need": "French Writing, Grammar, and Vocabulary"
      },
      {
        "grade": "11th Grade",
        "area": "Foreign Language",
        "need": "French Speaking, Listening, and Reading"
      },
      {
        "grade": "12th Grade",
        "area": "Foreign Language",
        "need": "French Writing, Grammar, and Vocabulary"
      },
      {
        "grade": "12th Grade",
        "area": "Foreign Language",
        "need": "French Speaking, Listening, and Reading"
      }
    ]
  }
]