import {useState, useRef, useEffect, useCallback} from 'react';
import practiceViewStyles from 'components/PracticeView/practice-view-variables.scss';
import globalNavStyles from 'client/GlobalNavigation/global-navigation.scss';

const titleBarHeight = parseInt(practiceViewStyles.titleBarHeight, 10);
const mobileToolbarHeight = parseInt(practiceViewStyles.mobileToolbarHeight, 10);
const globalNavHeight = parseInt(globalNavStyles.globalNavHeight, 10);

const labelOffset = 20;
const marginOffset = 20;

const toolbarsHeight = titleBarHeight + mobileToolbarHeight + globalNavHeight;
const verticalScaleMultiplier = 0.85;

export function useMaxGraphDimensions({hasLeftLabel, hasBottomLabel}) {
  const [size, setSize] = useState(null);
  const wrapperRef = useRef();
  const observerRef = useRef(null);

  const handleResize = useCallback(() => {
    if (!wrapperRef.current) {
      return;
    }

    const maxWidth =
      wrapperRef.current.getBoundingClientRect().width -
      marginOffset -
      (hasLeftLabel ? labelOffset : 0);
    const maxHeight =
      (global.window?.innerHeight - toolbarsHeight - (hasBottomLabel ? labelOffset : 0)) *
      verticalScaleMultiplier;

    setSize({
      maxWidth,
      maxHeight
    });
  }, [hasLeftLabel, hasBottomLabel]);

  // use ResizeObserver to fit the graph to the size of the container ref
  useEffect(() => {
    if (!wrapperRef.current) {
      return () => {};
    }

    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    observerRef.current = new ResizeObserver(() => {
      // ensures only one resize update per frame
      requestAnimationFrame(() => handleResize());
    });

    observerRef.current.observe(wrapperRef.current);

    // Browser support, remove freely
    window.addEventListener('resize', handleResize);

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
        observerRef.current = null;
      }
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return {
    wrapperRef,
    ready: !!size,
    maxHeight: size?.maxHeight,
    maxWidth: size?.maxWidth
  };
}
