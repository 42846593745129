import {StateCreator} from 'zustand';
import type {ContentDiscoveryStoreState} from 'client/components/ContentDiscovery/store/ContentDiscoveryStore.types';

type FilterState = {};

type FilterActions = {
  clearFilters: () => void;
  reset: () => void;
};

const initialState: FilterState = {};

export type FilterSlice = FilterState & FilterActions;

export const createFilterSlice: StateCreator<
  ContentDiscoveryStoreState,
  [['zustand/immer', never]],
  [],
  FilterSlice
> = (set, get) => ({
  ...initialState,

  clearFilters: () => {
    get().standards.reset();
  },

  reset: () =>
    set((state) => {
      state.filter = {...state.filter, ...initialState};
    })
});
