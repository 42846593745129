// See: https://github.com/albert-io/nexus/issues/8428
// Essentially, we want to change "Middle School" and "High School" to "Grades 5-8" and "Grades 9-12" respectively.
// Additionally, we want the urls to stay the same. It's much simpler to keep subjectMenuData the same and override the text
// in the UI since otherwise we need to deal with logic related to active routes and such.
export function getOverriddenDomainString(domain: string): string | undefined {
  const domainTextOverrides = {
    'High School': 'Grades 9-12',
    'Middle School': 'Grades 5-8'
  };

  return domainTextOverrides[domain];
}

// See: https://github.com/albert-io/nexus/issues/8900
// The URL for the new `Core | Literacy Skills` subgroup should not include the word "core"
// so we use this function to override the label text set by ./subjectMenuData
export const getOverriddenSubgroupString = (
  domain: string,
  group: string,
  subgroup: string
): string | undefined => {
  const subgroupTextOverrides = {
    'Middle School': {
      ELA: {
        'Literacy Skills': 'Core | Literacy Skills',
        'Informational Texts': 'Core | Informational Texts',
        'Literary Texts': 'Core | Literary Texts'
      }
    },
    'High School': {
      ELA: {
        'Literacy Skills': 'Core | Literacy Skills',
        'Informational Texts': 'Core | Informational Texts',
        'Literary Texts': 'Core | Literary Texts'
      }
    }
  };

  return subgroupTextOverrides[domain]?.[group]?.[subgroup];
};
