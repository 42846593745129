/* eslint-disable import/prefer-default-export */
import {resource} from '@albert-io/json-api-framework/request/builder';
import masqueradeStore from 'generic/Masquerade/Masquerade.store';

export function getStudentQuery() {
  return resource('student_v2')
    .mandarkEndpoint(['students_v2', masqueradeStore.getUserIdByMasqueradeState()])
    .withMeta('student_v2')
    .meta({
      context: {
        assignment_info: true
      }
    });
}

export function getClassroomsQuery(studentId) {
  return resource('student_classrooms_v1')
    .mandarkEndpoint(['students_v2', studentId, 'student_classrooms_v1'])
    .sort('name')
    .include('subjects_v2.curriculum_area_v1,teachers_v1');
}

export function getIndiePracticeSubjectsQuery() {
  return resource('subjects_v2')
    .mandarkEndpoint(['subjects_v2'])
    .filter({
      hidden: false
    })
    .include('curriculum_area_v1')
    .withMeta('subject_v2')
    .customQuery({
      meta: {
        context: {
          student: {
            id: masqueradeStore.getUserIdByMasqueradeState()
          }
        }
      }
    })
    .filter({
      'meta.user_has_access': true
    })
    .sort('name')
    .pageSize('infinite');
}

export function getClassroomInvitationsQuery(email) {
  return resource('classroom_invitations_v1')
    .mandarkEndpoint(['classroom_invitations_v1'])
    .include('classroom_v1.teachers_v1,issuer_v1')
    .filter({
      accepted_at: {
        null: true
      },
      email: encodeURIComponent(email),
      included: {
        classroom_v1: {
          status: 'active'
        }
      }
    });
}
