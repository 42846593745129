// @flow
import {List} from 'immutable';
import sessionStore from 'client/Session/SessionStore';
import QuestionsListStore from 'client/InteractionEngineV2/shared/QuestionsList/QuestionsListStore';
import {makeAssignmentQuery} from 'resources/Assignment/Assignment.queries';
import appStore from 'client/AppStore';

import type {QueryBuilder} from '@albert-io/json-api-framework/request/builder/legacy';

export default class AssignmentQuestionsListStore extends QuestionsListStore {
  constructor(name: string) {
    super(name);
  }

  getAssignment() {
    return this.getAssignmentQuery().getResource();
  }

  getAssignmentQuery(): QueryBuilder {
    return makeAssignmentQuery({
      assignmentId: this.assignmentId,
      [sessionStore.isTeacher() ? 'teacherId' : 'studentId']: sessionStore.getUserId()
    });
  }

  get questionSets() {
    const assignmentId = this.assignmentId;

    if (!this.hasReceivedAssignment) {
      return new List();
    }

    return this.getAssignment()
      .getQuestionSets()
      .sortBy((setModel) => {
        return setModel.getAssignmentPosition(assignmentId);
      });
  }

  get assignmentId(): string {
    return appStore.routerProps.params.assignmentId;
  }

  get assignmentName(): string {
    if (!this.hasReceivedAssignment) {
      return '';
    }
    return this.getAssignment().getName();
  }

  get hasReceivedAssignment(): boolean {
    if (!this.assignmentId) {
      return false;
    }
    return this.getAssignmentQuery().isResourcePopulated();
  }
}
