import React from 'react';
import {LoadingSpinner} from '@albert-io/atomic';
import PracticeView from 'components/PracticeView/PracticeView.react';
import TitleBar from 'components/PracticeView/TitleBar/TitleBar.react';
import PropTypes from 'prop-types';

import './loading-view.scss';

const LoadingView = ({hasTitleBar = true}) => {
  return (
    <PracticeView
      titleBar={hasTitleBar ? <TitleBar className='u-bgc_blue-500' /> : undefined}
      stageComponent={
        <div className='practice-view-spinner-wrapper'>
          <LoadingSpinner size={2} />
        </div>
      }
    />
  );
};
LoadingView.propTypes = {
  hasTitleBar: PropTypes.bool
};

export default LoadingView;
