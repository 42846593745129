import React from 'react';
import PropTypes from 'prop-types';
import {Map, List} from 'immutable';
import {addToast, Banner} from '@albert-io/atomic';

import awaitMandarkQueries from 'lib/hocs/awaitMandarkQueries';
import sessionStore from 'client/Session/SessionStore';
import masqueradeStore from 'generic/Masquerade/Masquerade.store';
import {resource} from '@albert-io/json-api-framework/request/builder';
import {invalidatePartialInterest} from 'resources/mandark.resource';
import {UserModelV2} from 'resources/GeneratedModels/User/UserModel.v2';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {ClassroomModelV1} from 'resources/GeneratedModels/Classroom/ClassroomModel.v1';

import {getActiveClassroomsWithSubjectsQuery} from 'generic/SubjectMenuRenderer/helpers/queries';

import ContentDiscoverySurvey from './ContentDiscoverySurvey/ContentDiscoverySurvey.react';
import ContentDiscoveryResults from './ContentDiscoveryResults/ContentDiscoveryResults.react';

import {contentDiscoveryData} from './contentDiscoveryData';
import {subjectMappings} from './ContentDiscoveryData/generatedSubjectMappings';

/**
 * In order to update subject mappings you need to run the `parseMappings` script in
 * client/generic/SubjectMenuRenderer/ContentDiscovery/ContentDiscoveryData.
 *
 * see more in `generatedSubjectMappings.js`
 */
export default awaitMandarkQueries(
  () => {
    const queries = {
      queries: {
        user: resource('users_v2').mandarkEndpoint([
          'users_v2',
          masqueradeStore.getUserIdByMasqueradeState()
        ])
      }
    };
    if (sessionStore.isTeacher()) {
      queries.queries.activeClassrooms = getActiveClassroomsWithSubjectsQuery();
    }
    return queries;
  },
  class ContentDiscovery extends React.Component {
    static propTypes = {
      allCourses: ImmutablePropTypes.orderedSet,
      makeSubjectCardContent: PropTypes.func,
      makeSubjectCardHref: PropTypes.func,
      user: PropTypes.instanceOf(UserModelV2),
      emptyState: PropTypes.node,
      isUserLicensedTeacher: PropTypes.bool,
      activeClassrooms: ImmutablePropTypes.listOf(PropTypes.instanceOf(ClassroomModelV1)),
      searchString: PropTypes.string
    };

    constructor(props) {
      super(props);

      const preferences =
        props.user.getContentPreferences() &&
        props.user.getContentPreferences().get('preferences', null);
      const allActiveSubjectsMap = props.allCourses.reduce((acc, each) => {
        return acc.set(each.getId(), each);
      }, Map());
      this.state = {
        allActiveSubjectsMap,
        step: 0,
        selections: {
          0: new Set(),
          1: new Set(),
          2: new Set()
        },
        isSurvey: !preferences,
        surveyResults: preferences || Map(),
        isLoading: false
      };
    }

    onCancelButtonClick = () => {
      this.setState({
        isSurvey: false
      });
    };

    onBackButtonClick = () => {
      this.setState((state) => ({
        step: state.step - 1
      }));
    };

    onNextButtonClick = () => {
      this.setState(
        (state) => {
          const nextStep = state.step + 1;
          return {
            step: nextStep,
            isSurvey: nextStep !== 3
          };
        },
        () => {
          if (!this.state.isSurvey) {
            this.handleSubmit();
          }
        }
      );
    };

    resetSurvey = () => {
      this.setState({
        isSurvey: true,
        step: 0
      });
    };

    handlePageSelections = (id) => {
      this.setState((state) => {
        const existingStepSelections = Object.entries(state.selections).reduce(
          (acc, [key, val]) => {
            acc[key] = new Set(val);
            return acc;
          },
          {}
        );
        const currStepSelections = existingStepSelections[state.step];
        if (existingStepSelections[state.step].has(id)) {
          currStepSelections.delete(id);
        } else {
          currStepSelections.add(id);
        }

        for (const step in existingStepSelections) {
          if (step > state.step) {
            existingStepSelections[step] = new Set();
          }
        }

        return {
          selections: {
            ...existingStepSelections
          }
        };
      });
    };

    /**
     * Makes a set of the next possible options.
     * An option is added if a subject exists that matches the previous
     * selections.
     * This helps ensure that a user always gets suggested courses at the end.
     *
     * @returns {Set<string>} A set of options to be displayed on the next step of the survey
     */
    getFilteredOptions = () => {
      if (this.state.step === 0) {
        return null;
      }
      const currStepAccessor = contentDiscoveryData.get(this.state.step).get('mapping-key');
      return subjectMappings.reduce((acc, subject) => {
        // Make sure subjects from mappings are published and active
        if (this.state.allActiveSubjectsMap.has(subject.id)) {
          subject.mappings.forEach((mapping) => {
            let hasAllPrevMappings = true;
            for (let i = 0; i < this.state.step; i += 1) {
              const accessorValue = contentDiscoveryData.get(i).get('mapping-key');
              if (!this.state.selections[i].has(mapping[accessorValue])) {
                hasAllPrevMappings = false;
              }
            }
            if (hasAllPrevMappings) {
              acc.add(mapping[currStepAccessor]);
            }
          });
        }
        return acc;
      }, new Set());
    };

    /**
     * Similar logic to `getFilteredOptions` in that it finds courses that
     * match a path of previous user selections. In this case the user has completed
     * all selections. If a match is found, the results are stored in a Map
     *
     * @returns {Map<string, List<string>>} structured as Immutable Map([stringified mapping]: [List of Ids])
     */
    handleSubmit = () => {
      this.setState({isLoading: true});
      let surveyResults = Map();
      subjectMappings.forEach((subject) => {
        subject.mappings.forEach((mapping) => {
          let hasAllPrevMappings = true;
          const matchedAreas = [];
          for (let i = 0; i < this.state.step; i += 1) {
            // Key of the step we are currently indexing for
            const accessorValue = contentDiscoveryData.get(i).get('mapping-key');
            if (!this.state.selections[i].has(mapping[accessorValue])) {
              hasAllPrevMappings = false;
            }
            matchedAreas.push(mapping[accessorValue]);
          }
          if (hasAllPrevMappings) {
            // subject->grade->need
            const stringifiedMatches = matchedAreas.join('->');
            if (
              surveyResults.has(stringifiedMatches) &&
              !surveyResults.get(stringifiedMatches).includes(subject.id)
            ) {
              const newList = surveyResults.get(stringifiedMatches).push(subject.id);
              surveyResults = surveyResults.set(stringifiedMatches, newList);
            } else {
              surveyResults = surveyResults.set(stringifiedMatches, List([subject.id]));
            }
          }
        });
      });
      this.props.user
        .setContentPreferences({preferences: surveyResults})
        .save()
        .then(() => {
          this.setState({
            surveyResults,
            isLoading: false
          });
          invalidatePartialInterest({resourcePath: ['users_v2', sessionStore.getUserId()]});
        })
        .catch(() => {
          addToast({
            color: 'negative',
            title: 'Error',
            message: 'Something went wrong with saving your preferences'
          });
          this.setState({isLoading: false});
          this.resetSurvey();
        });
    };

    render() {
      const showSurvey = this.state.isSurvey;
      /**
       * We don't allow folks masquerading to take the content discovery survey
       * on other user's behalf.
       */
      if (showSurvey && masqueradeStore.isMasquerading()) {
        return (
          <Banner className='u-pad_4' align='center'>
            <Banner.Icon icon={['far', 'info-circle']} /> This user hasn&apos;t taken the Discover
            survey yet!
          </Banner>
        );
      }
      return showSurvey ? (
        <ContentDiscoverySurvey
          selectedOptions={this.state.selections[this.state.step]}
          section={contentDiscoveryData.get(this.state.step)}
          filteredOptions={this.getFilteredOptions()}
          step={this.state.step}
          onBackButtonClick={this.onBackButtonClick}
          handlePageSelections={this.handlePageSelections}
          onNextButtonClick={this.onNextButtonClick}
          onCancelButtonClick={this.onCancelButtonClick}
          hasAnsweredSurvey={!this.state.surveyResults.isEmpty()}
        />
      ) : (
        <ContentDiscoveryResults
          isLoading={this.state.isLoading}
          allCourses={this.props.allCourses}
          onEditButtonClick={this.resetSurvey}
          surveyResults={this.state.surveyResults}
          makeSubjectCardContent={this.props.makeSubjectCardContent}
          makeSubjectCardHref={this.props.makeSubjectCardHref}
          emptyState={this.props.emptyState}
          isUserLicensedTeacher={this.props.isUserLicensedTeacher}
          activeClassrooms={this.props.activeClassrooms}
          searchString={this.props.searchString}
        />
      );
    }
  }
);
