import React, {useRef} from 'react';
import {
  Button,
  Card,
  Dropdown,
  Icon,
  MenuListItem,
  MenuListItemCategoryText,
  MenuListItemRegularText,
  WithToggle
} from '@albert-io/atomic';
import {Dimension, Sort} from 'client/Reports/reports.types';
import {pushQueryParams} from 'client/history';

import './gradebook-sorts.scss';

interface SortOption {
  label: string;
  field: string;
  style: 'default' | 'side_by_side';
}

interface DimensionSortOptions {
  type: Dimension;
  label: string;
  sortOptions: SortOption[];
}

const dimensionSortOptions: DimensionSortOptions[] = [
  {
    type: 'students',
    label: 'Student Last Name',
    sortOptions: [{label: 'Name', field: 'last_name', style: 'side_by_side'}]
  },
  {
    type: 'assignments',
    label: 'Assignments',
    sortOptions: [
      {label: 'Name', field: 'name', style: 'default'},
      {label: 'Due Date', field: 'due_date', style: 'default'}
    ]
  }
];

interface Props {
  sorts: Sort[];
  dimensions: Dimension[];
}

export const GradebookSorts = ({sorts, dimensions}: Props) => {
  const toggleRef = useRef(null);

  const sortClicked = (type: Dimension, field: string, direction?: 'asc' | 'desc') => {
    // if direction is explicitly set, use that, otherwise toggle
    // if sort is not already applied, default to asc
    const existingSort = sorts.find((el) => el.type === type && el.field === field);
    const newDirection = direction ?? (existingSort?.direction === 'asc' ? 'desc' : 'asc');
    const newSorts = sorts.filter((el) => el.type !== type);
    newSorts.unshift({type, field, direction: newDirection});
    pushQueryParams({
      sort: newSorts.map((el) => `${el.type}.${el.field}:${el.direction}`).join(',')
    });
  };

  return (
    <WithToggle>
      {({on, onClick}) => (
        <>
          <Button color='secondary' variant='outlined' size='s' onClick={onClick} ref={toggleRef}>
            Sort By
            <Icon icon='caret-down' className='u-mar-l_1' />
          </Button>
          {on ? (
            <Dropdown.Tray
              className='gradebook-sorts__dropdown-tray'
              placement='bottom-start'
              positionFixed
              target={toggleRef.current}
              modifiers={{
                preventOverflow: {
                  escapeWithReference: true
                }
              }}
            >
              <Card paddingLevel={1}>
                {dimensions.map((dimension) => {
                  const dimensionSortOption = dimensionSortOptions.find(
                    (d) => d.type === dimension
                  )!;

                  return (
                    <div key={`${dimensionSortOption.type}-sort-title`}>
                      <MenuListItem
                        clickable={false}
                        hoverable={false}
                        className='u-justify-content_flex-start'
                      >
                        <MenuListItemCategoryText size='s' className='u-color_slate-500'>
                          {dimensionSortOption.label}
                        </MenuListItemCategoryText>
                      </MenuListItem>
                      {dimensionSortOption.sortOptions.map((sortOption) => {
                        const key = `${dimensionSortOption.type}-${sortOption.field}-sorter`;
                        const relevantSort = sorts.find(
                          (el) =>
                            el.type === dimensionSortOption.type && el.field === sortOption.field
                        );
                        const isSorted = !!relevantSort;
                        const direction = relevantSort?.direction;
                        if (sortOption.style === 'default') {
                          let icon;
                          if (isSorted) {
                            icon = direction === 'asc' ? 'arrow-up' : 'arrow-down';
                          }
                          return (
                            <MenuListItem
                              className='u-justify-content_space-between u-pad_1'
                              key={key}
                              selected={isSorted}
                              disableWhenSelected={false}
                              onMenuItemClick={() => {
                                sortClicked(dimensionSortOption.type, sortOption.field);
                              }}
                            >
                              <MenuListItemRegularText selected={isSorted}>
                                {sortOption.label}
                              </MenuListItemRegularText>
                              {icon && <Icon icon={icon} />}
                            </MenuListItem>
                          );
                        }
                        return (
                          <div
                            key={key}
                            className='u-display_flex u-gap_space-x1 u-align-items_flex-start u-mar-b_3'
                          >
                            <MenuListItem
                              className='u-pad_1'
                              selected={isSorted && direction === 'asc'}
                              onMenuItemClick={() => {
                                if (!isSorted || direction === 'desc') {
                                  sortClicked(dimensionSortOption.type, sortOption.field, 'asc');
                                }
                              }}
                            >
                              <div>Ascending</div>
                            </MenuListItem>
                            <MenuListItem
                              className='u-pad_1'
                              selected={isSorted && direction === 'desc'}
                              onMenuItemClick={() => {
                                if (!isSorted || direction === 'asc') {
                                  sortClicked(dimensionSortOption.type, sortOption.field, 'desc');
                                }
                              }}
                            >
                              <div>Descending</div>
                            </MenuListItem>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </Card>
            </Dropdown.Tray>
          ) : null}
        </>
      )}
    </WithToggle>
  );
};
