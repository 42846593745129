import {Record, Map} from 'immutable';

// Two-way Question Column
class TwoWayColumn extends Record({
  id: '',
  text: ''
}) {
  getId() {
    return this.get('id');
  }

  getText() {
    return this.get('text');
  }
}

// Two-way Question Rows
class TwoWayRow extends Record({
  columns: new Map({
    left: new Map(),
    right: new Map()
  }),
  id: '',
  index: 0,
  statement: ''
}) {
  static setUpColumns(rowData) {
    const leftColumn = new TwoWayColumn(rowData.getIn(['columns', 'left']));
    const rightColumn = new TwoWayColumn(rowData.getIn(['columns', 'right']));
    return rowData.setIn(['columns', 'left'], leftColumn).setIn(['columns', 'right'], rightColumn);
  }

  constructor(rowData) {
    super(TwoWayRow.setUpColumns(rowData));
  }

  getLeftColumn() {
    return this.getIn(['columns', 'left']);
  }

  getRightColumn() {
    return this.getIn(['columns', 'right']);
  }

  getId() {
    return this.get('id');
  }

  getIndex() {
    return this.get('index');
  }

  getStatement() {
    return this.get('statement');
  }
}

export function twoWaySetup(data) {
  const rows = data.get('rows').map((row) => new TwoWayRow(row));
  return data.set('rows', rows);
}

export const twoWayQuestionExtenions = {
  getLeftHeader() {
    return this.getHeaderLeft();
  },

  getRightHeader() {
    return this.getHeaderRight();
  }
};
