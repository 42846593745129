import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import makeConstants from 'lib/makeConstants';

import './practice-view-toolbar.scss';

const ToolbarDivider = () => <div className='practice-view-toolbar__divider' />;

const alignTypes = makeConstants('end', 'start');

export default class PracticeViewToolbar extends React.Component {
  static propTypes = {
    align: PropTypes.oneOf(Object.values(alignTypes)),
    children: PropTypes.node
  };

  static Divider = ToolbarDivider;

  render() {
    const {align, children} = this.props;
    return (
      <div className='practice-view-toolbar'>
        <div
          className={classnames('practice-view-toolbar__content', {
            'u-justify-content_flex-end': align === alignTypes.end
          })}
        >
          {children}
        </div>
      </div>
    );
  }
}
