// @flow
/* eslint-disable import/prefer-default-export */
import {Iterable, List, Map, Record, Set, Seq} from 'immutable';
import {camelCase, isObject, extend, merge} from 'lodash';
import {memoize} from 'lib/memoizer';
import {
  getPluralForm,
  getSingularForm,
  KNOWN_RESOURCES
} from '@albert-io/json-api-framework/models/registry/resources';

import {
  genericMandarkRequest,
  invalidateInterest,
  invalidateModel
} from 'resources/mandark.resource';

import {Validators} from './Validators';
/*
## Purpose
Updating and creating data in Mandark is inconvenient. This generic base class
model extension strives to make is as easy as possible, eliminating the tedious
tasks of building appropriate JSON API payloads and leveraging the models which
already exist. The approach is to extend a given model with the GenericModel class
which provides a .save() method to the model which will figure out which changes
need to be updated in the server, build the appropriate payload, and send it
to the server with the appropriate method.
## Simple Example
First, we extend our model with the GenericModel
`GenericModel(baseRecord: Record, relationshipConfig: Map<string, Map> = new Map())`
supplying the three required parameters. The `baseRecord` is the same `Record` definition we have been
extending thus far. `relationshipConfig` is a `Map` of relationship names to a configuration describing
the rules surrounding the relationship (see details below). Endpoint is the plural primary-resource
name identifying this resource (e.g. `assignments_v2`, or `classrooms_v1`, or `guesses_v1`).
To take AssignmentModel as an example:
```
export class AssignmentModel extends Record(...)
```
would become
```
const AssignmentRecord = Record(...); // As before
const AssignmentRelationshipConfig = fromJS({
  question_sets_v1: {
    required: false,
    type: 'one-to-many'
  },
  teacher_v1: {
    required: true,
    type: 'one-to-one'
  },
  classrooms_v1: {
    required: false,
    type: 'one-to-many'
  },
  students_v1: {
    required: false,
    type: 'one-to-many'
  }
});
const BaseAssignmentModel = GenericModel(AssignmentRecord, AssignmentRelationshipConfig, 'assignments_v2');
export class AssignmentModel extends BaseAssignmentModel {
```
This will make it such that you can `.set(key, val)` or `.setIn(path, val)` and update fields on the model.
Once you're ready to write the changes to the API, all you do is invoke `.save(): Promise` on your model. This works
whether you pulled a model from cache, modified it, and want to update mandark with the new fields or
relationships or if you instantiated the model yourself in a store and want to create the resource
described by the model in Mandark. The base class handles validation, building the payload appropriately,
and making the server call.
If you want to create new relationships for this resource, you are also provided an
```
updateRelationship(type: string, relation: string | Iterable): GenericModel
```
The `type` is simply the name of the relationship you want to update or create (e.g. `students_v1`)
while `relation` can be a string ID of the specific resource you want to relate to or just explicitly
another resource model (as from the cache) or a collection of string IDs or resource models to relate
to.
## Autogeneration of getters and setters
The GenericModel can be configured to autogenerate the getters and setters of certain fields
in the base record it is provided. This is done by defining a `fieldToMethodMap` in the `config` object passed as
the second argument to the `GenericModel` function.
In the following config object, both `id` and `allow_late_submissions` will have getters defined.
`allow_late_submissions` will also have a set method defined because it has been provided a `true` value.
Getters and setters will be defined if `true` is provided, otherwise they will be defined based on the contents
of the object provided to that attribute. For instance, `due_date` is only going to have a setter defined, because
`true` was given for `due_date`'s `set` value.
These values can be overridden, like you see in `template` below.
Generated methods will be the camelcased results of prefixing get or set to the attribute name:
```
fromJS({
  endpoint: 'example_v1',
  relationships: {
    thing_v1: {
      required: false,
      type: 'one-to-many'
    }
  },
  fieldToMethodMap:
    id: {
      get: true,
    },
    allow_late_submissions: true,
    time_limit: true,
    template: {
      get: 'isTemplate',
      set: 'setIsTemplate'
    }
    status: true,
    start_date: {
      set: true
    },
    name: true,
    message: true,
    due_date: {
      set: true
    },
    correct_answer_setting: true
  }
});
```
The keys in the `fieldToMethodMap` correspond DIRECTLY to things in the record that generic model takes.
If they don't match up an error will be thrown.
`set` values for the `id`, and `meta` fields will be ignored. Both the `get` and `set` attributes are optional,
so you can define one, and not the other.
The resulting object from a GenericModel that autogenerates getters and setters, to the consumer, is still just a
class that extends a Record and can be used as normal. You can extend it and override whatever you like.
Note: Getters and Setters will not be generated for relationships. If you try to do this, you'll get an error.
It was thought that these special cases and likely something you'll want to define yourself anyway.
### Examples:
`allow_late_submissions: true` yields:
  - getAllowLateSubmissions
  - setAllowLateSubmissions
`id: { get: true }` yields:
  - getId
`template: { get: 'isTemplate', set: 'setIsTemplate' }` yields:
  - isTemplate
  - setIsTemplate
## FAQ
### Does it break existing stuff?
No. Fully transparent. `GenericModel`s are opt-in. Even if you opt-in, there will be no difference
other than you now having the ability to persist model changes and instantiations to the server.
### How do I update included models?
You have to make the changes to the included model directly (provided it is extending from `GenericModel`)
and perform the `.save()` operation on that.
### When specifying the relationship config, should I use the plural or the singular form of resource?
Use the singular form.
### What are valid relationshipConfig options?
Relationship config is a Map that maps resource type (e.g. `assignment_v2`) to a `Map` of config options.
Valid config entries at present include:
`type`: describes how your model relates to the one you are creating the relation to. Valid options are
`'one-to-one'` and `'one-to-many'`. I did consider making these `Symbol`s but decided against it as it
makes certain things very annoying. Would be happy to discuss if anyone objects. The only other valid
config field is `required` which is a boolean (defaults to `false`). This is a validation check that
ensures you have specified the appropriate relationship if you are trying to create a new resource.
Full example:
```
const GuessRecord = Record({
  id: '',
  time_elapsed: 0,
  points_earned: 0,
  inserted_at: 0,
  question_v1: new Map(),
  assignment_v2: new Map(),
  student_v1: new Map(),
  content: new Map()
});
const GuessRelationshipConfig = fromJS({
  assignment_v2: {
    type: 'one-to-one'
  },
  student_v1: {
    type: 'one-to-one',
    required: true
  },
  question_v1: {
    type: 'one-to-one',
    required: true
  }
});
export class GuessModel extends GenericModel(GuessRecord, GuessRelationshipConfig, 'guesses-v1') {
```
### What does the future hold?
Dynamically updating the cache based on requested changes without a round-trip to the server, making
`invalidateResource` and `affectsResource` completely unnecessary.
*/

const relationshipPrivateKeys = Object.freeze([
  '__relationships',
  '__relationshipRemovals',
  '__relationshipReplacements'
]);
const modelDataTransformers = {};

export function GenericModel(
  baseRecord: Record<*>,
  config: Map<string, Map> = new Map(),
  modelName: ?string
): Class<any> {
  return class extends baseRecord {
    /**
     * Allows you to add additional methods to a model after the model has been defined. Useful for adding
     * methods to generated models.
     *
     * USAGE:
     * const fooExtensions = {
     *   sayHi: function() {
     *     console.log('hi');
     *   }
     * }
     * FooModel.extend(fooExtensions);
     *
     * var x = new FooModel();
     * x.sayHi(); // "hi"
     *
     * @param {...any} extensionObjs
     */
    static extend(...extensionObjs): * {
      extensionObjs.forEach((obj) => {
        Object.keys(obj).forEach((func) => {
          this.prototype[func] = obj[func];
        });
      });
      return this;
    }

    static addValidators(validators: Object): * {
      this.prototype.__validators = validators;
      return this;
    }

    static addDataTransformer(modelName, method) {
      if (modelDataTransformers.hasOwnProperty(modelName)) {
        throw new Error(`A data transfromer for ${modelName} already exists!`);
      }
      modelDataTransformers[modelName] = method;
    }

    __bindValidatorsToInstance() {
      if (!this.__validators) {
        return;
      }

      this.validators = new Validators();
      for (const getterName of Object.keys(this.__validators)) {
        if (typeof this[getterName] !== 'function') {
          console.error(
            `[Generic.model.js] Unable to validate on model method '${getterName}' of ${this.constructor.name}. Method does not exist.`
          );
        } else {
          this.validators[getterName] = () => {
            return this.__validators[getterName].call(this, this[getterName]());
          };
        }
      }
    }

    constructor(
      data: ?Map<*, *> | ?Object,
      __fromServer: boolean = false,
      __modelMetaParameters: ?GenericModel
    ) {
      const modelData =
        modelName && modelDataTransformers[modelName]
          ? modelDataTransformers[modelName](data)
          : data;
      super(modelData);
      this.cache = memoize();

      if (!config.has('endpoint')) {
        throw new Error('Please specify endpoint in your config.');
      }

      this.__bindValidatorsToInstance();

      if (__modelMetaParameters) {
        // Safe because all parameters are immutables
        Object.assign(this, __modelMetaParameters);
      } else {
        this.__relationshipConfig = config.get('relationships', new Map());
        this.__attribConfig = config.get('attributes', new Map());
        this.__readOnlyAttributes = this.__attribConfig
          .filter((attrib) => attrib.get('isReadOnly'))
          .keySeq();
        this.__endpoint = config.get('endpoint');
        this.__fromServer = __fromServer;
        // The changemap starts empty, if this model is wrapping a response from server (update case)
        // or starts with the default model (with optional data Map passed in representing the new
        // model)
        this.__changeMap = __fromServer
          ? new Map()
          : new Map(modelData ? this.toMap().mergeDeep(data) : this.toJS());
        this.__savePending = false;
      }

      /*
        Field Generation
        Two loops are done below:
          - the first generates a list of getter/setter configs for each provided fieldToMethodMap
            element. These configs hold the name of the getter or setter to add, or false if no config
            is to be added.
          - the second actually inserts the getters and setters into `this` based on a non-false value
            being found in the get/set attribute of a config object.
      */
      const fieldToMethodMap = config.get('fieldToMethodMap');
      if (!fieldToMethodMap) {
        return;
      }

      const relationships = config.get('relationships');
      const relationshipKeys = relationships ? relationships.keySeq() : new Seq();
      const baseRecordInstance = baseRecord();
      function makeMethodSuffix(baseRecordKey) {
        const camelCasedKey = camelCase(baseRecordKey);
        // XXX: Can be replaced w/ _.upperFirst when we get lodash 4.0.0
        const keyHead = camelCasedKey.charAt(0);
        const keyTail = camelCasedKey.slice(1);
        return keyHead.toUpperCase().concat(keyTail);
      }

      const methodConfigs = fieldToMethodMap.keySeq().reduce((acc, baseRecordKey) => {
        if (relationships && relationshipKeys.contains(baseRecordKey)) {
          throw new Error(
            `${baseRecordKey} provided in the fieldToMethodMap represents a relationship and cannot have getters or setters generated automatically`
          );
        }

        // Quick sanity check to ensure our field is IN the base record.
        if (!baseRecordInstance.has(baseRecordKey)) {
          throw new Error(`fieldToMethodMap key '${baseRecordKey}' not found in base record.`);
        }

        const methodMapValue = fieldToMethodMap.get(baseRecordKey);
        // defaults
        let methodConfig = {
          get: false,
          set: false
        };

        /*
          The methodMapValue has get/set properties that are either strings representing
          the names of the relevant methods or a true/false flag representing
          if we should have them be auto generated or not.
          Performing an extend will either absorb the string method name, or absorb the boolean
          value being used below when adding the suffix.
        */
        if (isObject(methodMapValue)) {
          const value = methodMapValue.toJS();
          methodConfig = extend({}, methodConfig, value);
        }

        /*
          Covers the case of simply passing `true` to autogen both methods
          We don't need to do an extend here, we can just short cut straight to true for both.
        */
        if (methodMapValue === true) {
          methodConfig = {
            get: true,
            set: true
          };
        }

        /*
          If these properties are EXPLICITLY true, then we know that we can
          generate a getter or setter name from a generated method suffix.
        */
        const methodSuffix = makeMethodSuffix(baseRecordKey);
        if (methodConfig.get === true) {
          methodConfig.get = `get${methodSuffix}`;
        }

        if (methodConfig.set === true) {
          methodConfig.set = `set${methodSuffix}`;
        }
        return acc.set(baseRecordKey, methodConfig);
      }, new Map());

      /*
        We go thru our configs, check that our get or set property is not explicitly false,
        and then just add the methods.
      */
      fieldToMethodMap.keySeq().forEach((baseRecordKey) => {
        const methodConfig = methodConfigs.get(baseRecordKey);

        if (!methodConfig.get === false) {
          this[methodConfig.get] = () => {
            return this.get(baseRecordKey);
          };
        }

        // We ignore setters for a small set of attributes.
        const ignoredSetFields = new Set(['meta', 'id']);
        if (!methodConfig.set === false) {
          if (ignoredSetFields.contains(baseRecordKey)) {
            console.error(
              `Attempt to define a setter for reserved field: set defined in ${baseRecordKey}'s fieldToMethodMap entry is being ignored.`
            );
            return;
          }

          this[methodConfig.set] = (newValue) => {
            return this.setField(baseRecordKey, newValue);
          };
        }
      });
    }

    addRelationship({
      type,
      relation,
      condition = true,
      customMeta
    }: {
      type: string,
      relation:
        | string
        | List<string>
        | Array<String>
        | GenericModel
        | List<GenericModel>
        | Array<GenericModel>,
      condition: boolean,
      customMeta: Map<string, *>
    }): GenericModel {
      return this.updateRelationship(type, relation, condition, customMeta, false, false);
    }

    deleteRelationship({
      type,
      relation,
      condition = true,
      customMeta
    }: {
      type: string,
      relation:
        | string
        | List<string>
        | Array<String>
        | GenericModel
        | List<GenericModel>
        | Array<GenericModel>,
      condition: boolean,
      customMeta: Map<string, *>
    }): GenericModel {
      return this.updateRelationship(type, relation, condition, customMeta, true, false);
    }

    // XXX From the docs (emphasis added):
    // This sets the relationship from this resource to the one(s) specified
    // to be exactly the id or collection of IDs specified.
    //* * Any already existing relationships to this resource type not specified
    // in the relation will be removed. **
    // Proceed with caution!
    replaceRelationship({
      type,
      relation,
      condition = true,
      customMeta
    }: {
      type: string,
      relation:
        | string
        | List<string>
        | Array<String>
        | GenericModel
        | List<GenericModel>
        | Array<GenericModel>,
      condition: boolean,
      customMeta: Map<string, *>
    }): GenericModel {
      return this.updateRelationship(type, relation, condition, customMeta, false, true);
    }

    // XXX This should ultimately do a lookup with getResource and resolve
    // the actual model into this one. For now, we only keep the changemap
    // up to date for the purposes of writing to server.
    updateRelationship(
      type: string,
      relation:
        | string
        | List<string>
        | Array<String>
        | GenericModel
        | List<GenericModel>
        | Array<GenericModel>,
      condition: boolean = true,
      customMeta: Map<string, *>,
      isDeletion: boolean = false,
      isReplacement: boolean = false
    ): GenericModel {
      if (!condition) {
        return this;
      }

      if (!this.__relationshipConfig.has(type)) {
        throw new Error(`
          Failed to add relationship to model ${this.constructor.name}!
          Model not configured for relationships of type ${type}
        `);
      }

      let updatedChangeMap = this.__changeMap;
      let changeMapRelationshipEntry = null;
      if (isDeletion) {
        changeMapRelationshipEntry = '__relationshipRemovals';
      } else if (isReplacement) {
        changeMapRelationshipEntry = '__relationshipReplacements';
      } else {
        changeMapRelationshipEntry = '__relationships';
      }

      if (this.__relationshipConfig.getIn([type, 'type']) === 'one-to-many') {
        if (!(relation instanceof Array) && !Iterable.isIterable(relation)) {
          throw new Error(`
            Relationship for model ${this.constructor.name} is set as one-to-many
            for type ${type}. You must specify a collection of string IDs to relate to.
          `);
        }

        updatedChangeMap = this.__changeMap.updateIn(
          [changeMapRelationshipEntry, type],
          (relationships) => {
            const relationIds = relation.map((r) => (typeof r === 'string' ? r : r.get('id')));
            return relationships ? relationships.concat(relationIds) : new Set(relationIds);
          }
        );
      } else if (this.__relationshipConfig.getIn([type, 'type']) === 'one-to-one') {
        if (typeof relation === 'string') {
          updatedChangeMap = this.__changeMap.setIn([changeMapRelationshipEntry, type], relation);
        } else {
          updatedChangeMap = this.__changeMap.setIn(
            [changeMapRelationshipEntry, type],
            relation.get('id')
          );
        }
      } else {
        throw new Error(`
          Relationship ${type} of model ${this.constructor.name}
          does not have a recongnizable type.
        `);
      }

      if (customMeta) {
        updatedChangeMap = updatedChangeMap.update('customMeta', (currentCustomMeta) => {
          return currentCustomMeta ? currentCustomMeta.mergeDeep(customMeta) : customMeta;
        });
      }

      // removes deletion from changemap if relationship update is additive
      // prevents bug that deletes whatever was just updated via separate DELETE request
      if (!isDeletion && updatedChangeMap.get('__relationshipRemovals')?.has(type)) {
        updatedChangeMap = updatedChangeMap.deleteIn(['__relationshipRemovals', type]);
      }

      // Get a new instance with all existing state plus updated changemap
      return new this.constructor(this, this.__fromServer, {
        ...this.__getPrivateFields(),
        __changeMap: updatedChangeMap
      });
    }

    async save({
      customQuery = {},
      payloadMutator = (payload) => payload
    }: {
      customQuery: ?Object,
      payloadMutator: ?() => mixed
    } = {}): Promise<*> {
      if (this.__savePending) {
        throw new Error(
          `A save for this model of type ${this.constructor.name} is already pending!`
        );
      }
      if (this.__changeMap.isEmpty()) {
        console.warn('No changes to save on model');
        return new Promise((res) => {
          res(this);
        });
      }
      const method = this.__fromServer ? 'patch' : 'post';

      if (method === 'post') {
        const relationships = this.__changeMap.get('__relationships', new Map()).keySeq().toSet();
        const requiredRelationships = this.__relationshipConfig
          .filter((val) => val.get('required'))
          .keySeq()
          .toSet();
        const missingRelationships = requiredRelationships.subtract(relationships);
        if (!missingRelationships.isEmpty()) {
          throw new Error(
            `Model ${
              this.constructor.name
            } requires relationships ${missingRelationships.toJS()} to be specified`
          );
        }
      }

      if (method === 'post' && this.constructor._isFullSpec) {
        this.validateRequiredFields();
      }

      const payload = payloadMutator(this.__constructPayload());
      let response = null;

      try {
        this.__savePending = true;
        const resourcePath = [this.__endpoint];
        if (method === 'patch') {
          resourcePath.push(this.get('id'));
          payload.data.id = this.get('id');
        }
        const query = {resourcePath, ...customQuery};

        /**
         * This accounts for save() calls on unmodified entities. Often happens when saving relationships.
         *
         * @see {@link https://github.com/albert-io/albert-marketplace/issues/6685}
         */
        response =
          method === 'patch' && Object.values(payload.data).length === 0
            ? this
            : await genericMandarkRequest(method, query, payload);

        const oneToManyRelationships = this.__constructOneToManyRelationshipPayloads();
        if (!oneToManyRelationships.isEmpty() && this.__fromServer) {
          await Promise.all(
            oneToManyRelationships
              .map((payload) => {
                return genericMandarkRequest(
                  'post',
                  {resourcePath: payload.get('resourcePath')},
                  payload.get('payload')
                );
              })
              .toJS()
          );
        }
        /**
         * @todo: in contrast to the oneToManyRelationships above, relationshipDeletions will be fully formed promises
         * See notes inside of `__constructRelationshipDeletions` on why this, for now, is the case.
         */
        const relationshipDeletions = this.__constructRelationshipDeletions();
        if (!relationshipDeletions.isEmpty()) {
          await Promise.all(relationshipDeletions);
        }

        if (this.get('id')) {
          invalidateModel([this.__endpoint, this.get('id')]);
        }

        this.__invalidateRelationships();
        this.__changeMap = new Map();
      } catch (error) {
        throw error;
      } finally {
        this.__savePending = false;
      }

      return response;
    }

    async delete(): Promise<*> {
      if (this.__savePending) {
        throw new Error(
          `A save for this model of type ${this.constructor.name} is already pending!`
        );
      }
      if (!this.get('id')) {
        throw new Error(
          `Attempting to delete on model of type ${this.constructor.name} with no id`
        );
      }

      try {
        this.__savePending = true;
        const query = {resourcePath: [this.__endpoint, this.get('id')]};
        await genericMandarkRequest('delete', query);
        invalidateModel([this.__endpoint, this.get('id')]);
        this.__invalidateRelationships();
      } catch (error) {
        throw error;
      } finally {
        this.__savePending = false;
      }
    }

    setField(field: string, value: any): GenericModel {
      if (this.__relationshipConfig.has(field)) {
        throw new Error(
          `You may not modify related models from the container model.
          Perform your changes on the related model directly`
        );
      }

      return new this.constructor(this.set(field, value), this.__fromServer, {
        ...this.__getPrivateFields(),
        __changeMap: this.__changeMap.set(field, value)
      });
    }

    setFieldIn(fields: Array<string>, value: any): GenericModel {
      if (this.__relationshipConfig.has(fields[0])) {
        throw new Error(
          `You may not modify related models from the container model.
          Perform your changes on the related model directly`
        );
      }

      /**
       * In the case of attributes with nested values, we need to make sure we don't leave anything
       * out of the payload we ultimately construct with our changeMap, so here we clone everything
       * belonging to the attribute first before setting the nested value.
       */
      const updatedChangeMap = this.__changeMap
        .set(fields[0], this.get(fields[0]))
        .setIn(fields, value);

      return new this.constructor(this.setIn(fields, value), this.__fromServer, {
        ...this.__getPrivateFields(),
        __changeMap: updatedChangeMap
      });
    }

    validateRequiredFields() {
      const requiredFields = this.__attribConfig
        .filter((attrConfig) => attrConfig.get('isRequired'))
        .keySeq()
        .toSet();

      const payloadFields = this.__changeMap.keySeq().toSet();

      const missingRequiredFields = requiredFields.subtract(payloadFields);

      if (!missingRequiredFields.isEmpty()) {
        throw new Error(
          `Cannot create a new ${this.constructor.name} as it is missing the` +
            `following required fields in its payload: ${missingRequiredFields.toJS()}`
        );
      }
    }

    existsOnServer(): boolean {
      return this.__fromServer === true;
    }

    getChangeMap(): boolean {
      return this.__changeMap;
    }

    resetChangeMap() {
      this.__changeMap = new Map();
      return this;
    }

    getConstructedPayload(): Object {
      return this.__constructPayload();
    }

    __invalidateRelationships() {
      this.__changeMap
        .filter((val, key) => relationshipPrivateKeys.includes(key))
        .reduce((result, val) => result.mergeDeep(val), new Map())
        .reduce((result, val, relationshipType) => {
          const relationships = Set.isSet(val) ? val : new Set([val]);
          return result.update(getSingularForm(relationshipType), new Set(), (relSet) =>
            relSet.concat(relationships)
          );
        }, new Map())
        .forEach((resourceIds, relType) =>
          resourceIds.forEach((id) => invalidateInterest({resourcePath: [relType, id]}))
        );
    }

    __constructPayload(): Object {
      const customMeta = this.__changeMap.get('customMeta', new Map());

      // Trim any known 'model keys' from the changemap, also remove meta.
      const keyBlacklist = KNOWN_RESOURCES.concat(this.__readOnlyAttributes).concat('meta');
      const filteredChangeMap = this.__changeMap.filterNot((value, key) =>
        keyBlacklist.contains(key)
      );

      return filteredChangeMap.reduce(
        (result, val, key) => {
          if (['id', '__relationshipRemovals'].includes(key)) {
            return result;
          }

          if (['__relationships', '__relationshipReplacements'].includes(key)) {
            const relationshipToReplace = val.reduce((payloadRelationships, relation, type) => {
              // We have to handle one-to-manys as a series of separate POSTs if updating an existing resource
              if (
                key !== '__relationshipReplacements' &&
                this.__relationshipConfig.getIn([type, 'type']) !== 'one-to-one' &&
                this.__fromServer
              ) {
                return payloadRelationships;
              }
              const payloadType =
                this.__relationshipConfig.getIn([type, 'type']) === 'one-to-one'
                  ? type
                  : getPluralForm(type);

              if (!payloadRelationships.hasOwnProperty(payloadType)) {
                payloadRelationships[payloadType] = {};
              }

              payloadRelationships[payloadType].data = Set.isSet(relation)
                ? relation.toJS().map((id) => {
                    return {
                      id,
                      meta: customMeta.get(id),
                      type
                    };
                  })
                : {
                    id: relation,
                    type
                  };
              return payloadRelationships;
            }, {});
            /**
             * The relationships to be replaced are merged in with existing relationships
             * to ensure we are not removing unedited values (relationships).
             */
            result.data.relationships = merge({}, result.data.relationships, relationshipToReplace);
          } else if (this.__attribConfig.getIn([key, 'create_allowed'], true)) {
            if (!result.data.hasOwnProperty('attributes')) {
              result.data.attributes = {};
            }
            result.data.attributes[key] = Iterable.isIterable(val) ? val.toJS() : val;
          }
          return result;
        },
        {
          data: {
            type: getSingularForm(this.__endpoint)
          }
        }
      );
    }

    __constructOneToManyRelationshipPayloads(): List<*> {
      const customMeta = this.__changeMap.get('customMeta', new Map());

      return this.__changeMap
        .get('__relationships', new List())
        .reduce((result, relationship, type) => {
          if (this.__relationshipConfig.getIn([type, 'type']) === 'one-to-one') {
            return result;
          }

          const pluralType = getPluralForm(type);
          const mandarkPayload = Set.isSet(relationship)
            ? relationship.toJS().map((id) => {
                return {
                  id,
                  meta: customMeta.get(id),
                  type
                };
              })
            : [
                {
                  id: relationship,
                  type
                }
              ];

          return result.push(
            new Map({
              payload: {
                data: mandarkPayload
              },
              resourcePath: [this.__endpoint, this.get('id'), 'relationships', pluralType]
            })
          );
        }, new List());
    }

    /**
     * @todo: This method could eventually go away once .save is refactored. Right now this is explcitily handling the DELETE
     * vs. PATCH edge case for one-to-one and non one-to-one relationships.
     */
    __constructRelationshipDeletions(): List<Promise<*>> {
      return this.__changeMap
        .get('__relationshipRemovals', Map())
        .reduce((acc, relationship, type) => {
          if (this.__relationshipConfig.getIn([type, 'type']) === 'one-to-one') {
            const payload = this.__constructDeletePayload(relationship, type, true);
            return acc.push(
              genericMandarkRequest(
                'patch',
                {resourcePath: payload.get('resourcePath')},
                payload.get('payload')
              )
            );
          }
          const payload = this.__constructDeletePayload(relationship, type, false);
          return acc.push(
            genericMandarkRequest(
              'patch',
              {resourcePath: payload.get('resourcePath')},
              payload.get('payload')
            )
          );
        }, List());
    }

    /**
     * @param relationship
     * @param type
     * @param isOneToOne
     * @todo: This is a major hack to get deletions working for one-to-one relationships.
     * In a nutshell, a one-to-one relationship deletion requires us to send a PATCH request to the
     * relationship URL for the resource with a `data: null` payload.
     *
     * DELETE reqs will only work with non-one-to-one relationships. Below we determing which we're
     * dealing with thru the `__relationshipConfig` object and then construct a payload appropriately.
     *
     * In a future PR we may be able to fold the separate DELETE request as well as the separate one-to-one PATCH-style-delete
     * into a single .save PATCH -- this would be _extremely desireable_ as it would save network latency and lower a potential
     * surface for errors. Need to confer with the BE on what JSON API allows there.
     * @todo: with futher refactoring pulling out the getPluralForm(type) into a function parameter would be a good
     * add, would remove the need for an isOneToOne flag; maybe separate functions for the two control flow paths
     */
    __constructDeletePayload(relationship, type, isOneToOne): Map<string, *> {
      if (!isOneToOne) {
        const relationshipName = getPluralForm(type);
        const mandarkPayload = Set.isSet(relationship)
          ? relationship.toJS().map((id) => {
              return {
                id,
                type
              };
            })
          : [
              {
                id: relationship,
                type
              }
            ];

        return new Map({
          payload: {
            data: mandarkPayload
          },
          resourcePath: [this.__endpoint, this.get('id'), 'relationships', relationshipName]
        });
      }

      const relationshipName = getSingularForm(type);
      return new Map({
        payload: {
          data: null
        },
        resourcePath: [this.__endpoint, this.get('id'), 'relationships', relationshipName]
      });
    }

    __getPrivateFields() {
      return {
        __relationshipConfig: this.__relationshipConfig,
        __attribConfig: this.__attribConfig,
        __readOnlyAttributes: this.__readOnlyAttributes,
        __endpoint: this.__endpoint,
        __fromServer: this.__fromServer,
        __changeMap: this.__changeMap,
        __savePending: this.__savePending
      };
    }
  };
}
