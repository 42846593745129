import React from 'react';
import {callAction, getStoreByName} from 'client/framework';
import IEContentWrapper from 'client/InteractionEngineV2/shared/IEContentWrapper/IEContentWrapper.react';
import ActiveQuestion from 'client/InteractionEngineV2/shared/ActiveQuestion/ActiveQuestion.react';
import interactionEngineActions from 'client/InteractionEngineV2/InteractionEngineActions';
import interactionEngineStore from 'client/InteractionEngineV2/InteractionEngineStore';
import systemTimeOffsetStore from 'client/generic/SystemTimeOffset/SystemTimeOffset.store';
import AssignmentV2IESubheader from './AssignmentV2IESubheader/AssignmentV2IESubheader.react';
import AssignmentV2IEQuestionsList from './AssignmentV2IEQuestionsList/AssignmentV2IEQuestionsList.react';
import assignmentV2IEQuestionsListStore from './AssignmentV2IEQuestionsList/AssignmentV2IEQuestionsList.store';
import assignmentV2IEActions from './AssignmentV2IE.actions';
import assignmentV2IEStore from './AssignmentV2IE.store';
import AssignmentV2IEDetails from './AssignmentV2IEDetails/AssignmentV2IEDetails.react';
import Button from 'sg/Button/Button.react';
import Timer from 'generic/Timer/Timer.react';
import appStore from 'client/AppStore';
import sessionStore from 'client/Session/SessionStore';
import SubmitUnfinishedSectionModal from './SubmitSectionModal/SubmitUnfinishedSectionModal.react';
import ConfirmLeaveUnfinishedSectionModal from './SubmitSectionModal/ConfirmLeaveUnfinishedSectionModal.react';
import assignmentConstants from 'client/Assignments/constants';
import AssignmentStatusBanner from './AssignmentStatusBanner/AssignmentStatusBanner.react';
import 'client/InteractionEngineV2/shared/AssignmentsV2SharedStyles/assignment-v2-ie.scss';

export default class AssignmentV2IE extends React.Component {
  constructor() {
    super();
    callAction(assignmentV2IEActions.INIT_ASSIGNMENT);
  }

  componentDidMount() {
    callAction(interactionEngineActions.SHOW_MOBILE_QUESTION_AREA);
  }

  UNSAFE_componentWillUpdate() {
    this.handleWipeQuestionStores();
  }

  componentWillUnmount() {
    callAction(assignmentV2IEActions.RESET_STORE);
  }

  handleWipeQuestionStores() {
    if (
      !assignmentV2IEStore.isPostAssignmentSubmission() ||
      assignmentV2IEStore.hasWipedQuestionStores()
    ) {
      return;
    }
    callAction(interactionEngineActions.WIPE_BUILT_QUESTION_STORES);
    callAction(assignmentV2IEActions.HAS_WIPED_QUESTION_STORES);
  }

  getQuestionArea(isReady, assignment) {
    if (
      assignmentV2IEStore.isViewingStagingDetails() ||
      (!assignment.hasStudentSubmittedAssignment() &&
        assignmentV2IEStore.isLatestStartedSectionOutOfTime())
    ) {
      return (
        <AssignmentV2IEDetails
          isReady={isReady}
          assignment={assignment}
          topBanner={
            <AssignmentStatusBanner assignment={assignment} studentId={sessionStore.getUserId()} />
          }
        />
      );
    }
    const {activeQuestionSet} = assignmentV2IEQuestionsListStore;
    const {activeQuestion} = assignmentV2IEQuestionsListStore;
    const activeQuestionId = activeQuestion.getId();
    const questionStoreName = interactionEngineStore.getStoreNameForQuestionId(activeQuestionId);
    let guessToLoad = assignmentV2IEStore.getGuessForQuestion(activeQuestionId);
    let isUnsubmittedAssignmentQuestion = false;
    if (!guessToLoad && !assignment.isAssignmentOpenForStudent()) {
      const questionStore = getStoreByName(questionStoreName);
      isUnsubmittedAssignmentQuestion = true;
      guessToLoad = questionStore.buildCorrectGuess();
    }
    return (
      <ActiveQuestion
        isPracticeExam
        guessToLoad={guessToLoad}
        assignmentId={assignmentV2IEStore.getAssignmentId()}
        location={appStore.routerProps.location}
        question={activeQuestion}
        questionSet={activeQuestionSet}
        questionStoreName={questionStoreName}
        showPrevButton={assignmentV2IEQuestionsListStore.hasPreviousQuestion(activeQuestionId)}
        showNextButton={assignmentV2IEQuestionsListStore.hasNextQuestion(activeQuestionId)}
        shouldShowExplanationAfterGuessIsSubmitted={
          assignment.getCorrectAnswerSetting() ===
          assignmentConstants.CORRECT_ANSWER_SETTINGS.SHOW_AFTER_EACH.VALUE
        }
        shouldShowExplanation={assignment.shouldShowGuessFeedback()}
        isAssignmentOverdue={!assignment.isAssignmentOpenForStudent()}
        isAssignmentSubmitted={assignment.hasStudentSubmittedAssignment()}
        isUnsubmittedAssignmentQuestion={isUnsubmittedAssignmentQuestion}
        topBanner={
          <AssignmentStatusBanner assignment={assignment} studentId={sessionStore.getUserId()} />
        }
      />
    );
  }

  shouldShowTimerAndSubmitButton(isReady, assignment) {
    if (
      !isReady ||
      !assignment ||
      assignment?.hasStudentSubmittedAssignment() ||
      !assignmentV2IEStore.hasStartedAssignment() ||
      (assignment.hasSections() && !assignmentV2IEStore.isFirstIncompleteSectionStarted())
    ) {
      return false;
    }
    return true;
  }

  handleOutOfTime() {
    callAction(assignmentV2IEActions.SET_IS_VIEWING_STAGING_DETAILS, true);
  }

  getTimer(assignment) {
    const startTime = assignmentV2IEStore
      .getLatestStartedSectionStudentRelationships()
      .getStartTime();
    const systemTimeOffset = systemTimeOffsetStore.getSystemTimeOffset();
    const currentTime = systemTimeOffsetStore.getSystemTimeOffset();
    if (
      !startTime ||
      !systemTimeOffset ||
      !currentTime ||
      assignmentV2IEStore.getFirstIncompleteSection().getTimeLimit() === null
    ) {
      return null;
    }
    const secondsElapsed = systemTimeOffsetStore.getCurrentTime().diff(startTime, 'seconds');
    const totalSeconds = assignmentV2IEStore.getFirstIncompleteSection().getTimeLimit() * 60;
    const secondsRemaining = totalSeconds - secondsElapsed;
    return (
      <Timer
        storeName={`studentAssignmentTimer/${assignment.getId()}`}
        className='assignment-v2-ie-subheader-right-content__timer'
        secondsRemaining={secondsRemaining}
        totalSeconds={totalSeconds}
        onOutOfTime={this.handleOutOfTime}
      />
    );
  }

  showSubmitSectionModal = () => {
    if (assignmentV2IEStore.isRequestPending()) {
      return;
    }
    callAction(assignmentV2IEActions.SET_SHOW_SUBMIT_SECTION_MODAL, true);
  };

  shouldShowConfirmLeaveModal(isReady, assignment) {
    if (
      !isReady ||
      assignment.hasStudentSubmittedAssignment() ||
      !assignmentV2IEStore.hasStartedAssignment() ||
      !assignmentV2IEStore.isOpenAssignmentActiveSectionStarted() ||
      (assignmentV2IEStore.getLatestStartedSection() &&
        assignmentV2IEStore.isLatestStartedSectionOutOfTime())
    ) {
      return false;
    }
    return true;
  }

  render() {
    const query = assignmentV2IEStore.getStudentAssignmentQuery();
    const isReady = query.isResourcePopulated();
    const assignment = assignmentV2IEStore.getAssignment();
    return (
      <IEContentWrapper
        subheader={
          <AssignmentV2IESubheader
            isReady={isReady}
            assignment={assignment}
            rightContent={
              this.shouldShowTimerAndSubmitButton(isReady, assignment) ? (
                <div className='assignment-v2-ie-subheader-right-content'>
                  {this.getTimer(assignment)}
                  <Button
                    color='green'
                    text='Submit section'
                    onClick={this.showSubmitSectionModal}
                    disabled={assignmentV2IEStore.isRequestPending()}
                  />
                </div>
              ) : null
            }
          />
        }
        questionsList={<AssignmentV2IEQuestionsList isReady={isReady} />}
        questionArea={this.getQuestionArea(isReady, assignment)}
        modals={[
          <SubmitUnfinishedSectionModal
            key={1}
            shouldShow={assignmentV2IEStore.shouldShowSubmitSectionModal()}
          />,
          <ConfirmLeaveUnfinishedSectionModal
            key={2}
            shouldShow={this.shouldShowConfirmLeaveModal(isReady, assignment)}
          />
        ]}
      />
    );
  }
}
