import React, {useRef} from 'react';

import {Button, Dropdown, IconButton, Text, WithToggle} from '@albert-io/atomic';

import type {SortOption} from 'client/Reports/reports.types';

interface BaseHeaderCellProps {
  title: JSX.Element;
}

interface SortableHeaderCellProps extends BaseHeaderCellProps {
  showSort: true;
  isCurrentSort: boolean;
  onSortClick: (sortKey: string, sortDirection: SortOption) => void;
  sortDirection: SortOption;
  sortBy: string | null;
  sorts: readonly {label: string; sortKey: string}[];
}

interface NonSortableHeaderCellProps extends BaseHeaderCellProps {
  showSort: false;
}

type HeaderCellProps = SortableHeaderCellProps | NonSortableHeaderCellProps;

export const HeaderCell = ({title, showSort = true, ...props}: HeaderCellProps) => {
  const toggleRef = useRef(null);
  return (
    <th className='reports-table__header'>
      <div className='u-display_flex u-justify-content_space-between u-align-items_center'>
        {title}
        {showSort && (
          <WithToggle>
            {({on, onClick}) => {
              const {isCurrentSort, onSortClick, sortDirection, sortBy, sorts} =
                props as SortableHeaderCellProps;
              return (
                <>
                  <IconButton
                    color={isCurrentSort ? 'primary' : 'secondary'}
                    className='o-table__header-sort'
                    label='sort'
                    iconStyle='regular'
                    size='s'
                    icon={isCurrentSort && sortDirection === 'desc' ? 'arrow-down' : 'arrow-up'}
                    onClick={onClick}
                    ref={toggleRef}
                  />
                  {on ? (
                    <Dropdown.Tray
                      className='u-border-radius_2 u-box-shadow_default u-bgc_white u-display_flex u-flex-direction_column u-border u-border-color_slate-300 u-overflow_hidden u-bgc_white'
                      positionFixed
                      placement='bottom-end'
                      target={toggleRef.current}
                      style={{zIndex: 11}}
                      modifiers={{
                        flip: {
                          enabled: false
                        }
                      }}
                    >
                      <div
                        style={{fontSize: 12}}
                        className='u-bgc_white u-white-space_nowrap u-width_100pc u-display_inline-flex u-justify-content_space-between u-align-items_center u-border-b u-border-color_slate-200 u-border-radius_0 u-clr_slate-500 u-pad-tb_1 u-pad-lr_2'
                      >
                        Sort by...
                      </div>
                      {sorts.map(({label, sortKey}) => (
                        <Button
                          className='u-bgc_white u-white-space_nowrap u-width_100pc u-display_inline-flex u-justify-content_space-between u-align-items_center u-border-b u-border-color_slate-200 u-border-radius_0'
                          color='secondary'
                          key={sortKey}
                          variant='text'
                          onClick={() => onSortClick(sortKey, 'desc')}
                        >
                          <Text color={sortBy === sortKey ? 'brand' : 'primary'}>{label}</Text>
                        </Button>
                      ))}
                    </Dropdown.Tray>
                  ) : null}
                </>
              );
            }}
          </WithToggle>
        )}
      </div>
    </th>
  );
};
