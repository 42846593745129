/* eslint-disable import/prefer-default-export */
import {Map, List} from 'immutable';

export const contentDiscoveryData = List([
  Map({
    description: `Answer a few questions and we'll suggest the courses that best fit your needs`,
    title: 'Which subjects do you teach?',
    'mapping-key': 'area',
    options: List([
      'ELA',
      'Math',
      'Science',
      'Social Studies',
      'Foreign Language',
      'Computer Science',
      'Arts'
    ])
  }),
  Map({
    description: `Answer a few questions and we'll suggest the courses that best fit your needs`,
    title: 'Which grade levels do you teach?',
    'mapping-key': 'grade',
    options: List([
      '5th Grade',
      '6th Grade',
      '7th Grade',
      '8th Grade',
      '9th Grade',
      '10th Grade',
      '11th Grade',
      '12th Grade',
      'Higher learning'
    ])
  }),
  Map({
    description: `Answer a few questions and we'll suggest the courses that best fit your needs`,
    title: 'What are your practice needs?',
    'mapping-key': 'need',
    options: List([
      'Application Questions',
      'Case Studies',
      'Conceptual Questions',
      'Constitutionality',
      'Core Knowledge',
      'Critical Thinking',
      'Drama',
      'Extension for Higher-Learning Students',
      'French Speaking, Listening, and Reading',
      'French Writing, Grammar, and Vocabulary',
      'German Speaking, Listening, and Reading',
      'Grammar, Mechanics, and Usage',
      'Integrated Math',
      'Interdisciplinary Themes',
      'Italian Grammar and Vocabulary',
      'Italian Speaking, Listening, and Reading',
      'Japanese Speaking, Listening, and Reading',
      'Latin Reading Comprehension and Translation',
      'Lexile-Leveled Passages',
      'Literacy',
      'Mandarin Speaking, Listening, and Reading',
      'NGSS Practice',
      'Passage-Based Practice',
      'Poetry',
      'Policy',
      'Primary Sources',
      'PSAT',
      'Quantitative Analysis',
      'Reading Quizzes and Literature Circles',
      'Regents-aligned',
      'Remediation',
      'SCOTUS Cases',
      'Secondary Sources',
      'Shakespeare',
      'Short Passages for Building Stamina',
      'Short Stories',
      'Skills Practice',
      'Spanish Literature',
      'Spanish Speaking, Listening, and Reading',
      'Spanish Writing, Grammar, and Vocabulary',
      'TEKS-aligned',
      'Test Prep',
      'Vocabulary Practice'
    ])
  })
]);
