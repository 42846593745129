import React from 'react';
import {Iterable, is} from 'immutable';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {history, removeQueryParams} from 'client/history';
import {callAction} from 'client/framework';
import sessionStore from 'client/Session/SessionStore';
import {canCreateTemplateForSubject} from 'lib/UserAccessUtil';
import {convertSetAndQuestionToPath} from 'client/InteractionEngineV2/InteractionEngine.util';
import GuessHistoryWidget from './Widgets/GuessHistoryWidget/GuessHistoryWidget.react';
import AddToTemplateWidget from './Widgets/AddToTemplateWidget/AddToTemplateWidget.react';
import QuestionsList from 'client/InteractionEngineV2/shared/QuestionsList/QuestionsList.react';
import questionsListActions from 'client/InteractionEngineV2/shared/QuestionsList/QuestionsListActions';
import vanillaIEQuestionsListActions from './VanillaIEQuestionsList.actions';
import vanillaIEQuestionsListStore from './VanillaIEQuestionsList.store';
import interactionEngineStore from 'client/InteractionEngineV2/InteractionEngineStore';
import templateListStore from 'client/Assignments/Templates/TemplateList.store';
import NoQuestionsFoundMessage from './NoQuestionsFoundMessage/NoQuestionsFoundMessage.react';
// Move below components to the QuestionsList instead of tying them to the VanillaIEQuestionsList
import QuestionsListSettings from './QuestionsListSettings/QuestionsListSettings.react';
import QuestionsListPaginator from './QuestionsListPaginator/QuestionsListPaginator.react';
import AddQuestionsToTemplates from './AddQuestionsToTemplates/AddQuestionsToTemplates.react';
import {getResourcePromise} from 'resources/mandark.resource';
import topicSummaryStore from '../TopicSummary/TopicSummary.store';
import {isSearchMode} from 'client/components/AdvancedSearch/AdvancedSearch.utils';

class VanillaIEQuestionsList extends React.Component {
  static propTypes = {
    location: PropTypes.object,
    params: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.bootstrap(props);
    // Used to check shouldComponentUpdate in the QuestionsList component
    this.lastActiveQuestionId = '';
    this.lastTemplateQuestionSetChangeId = '';
  }

  componentDidUpdate() {
    this.setDefaultActiveQuestion();
    this.handleRouting();
  }

  bootstrap = async (initialProps) => {
    const pageToFetch = initialProps.location.query.page
      ? parseInt(initialProps.location.query.page, 10)
      : 1;

    /**
     * If the URL bar includes a search query, go into search mode
     */
    if (isSearchMode()) {
      getResourcePromise(vanillaIEQuestionsListStore.makeQuestionSetsQuery(pageToFetch)).then(
        () => {
          this.setDefaultActiveQuestion();
          callAction(vanillaIEQuestionsListActions.SET_PAGE_NUMBER, pageToFetch);
        }
      );
      return;
    }

    /**
     * If "set" is defined it was a request for a specific question via the URL.
     */
    if (initialProps.params.set) {
      /**
       * If a request for a single question came in, without a page number, we
       * short-circuit placing the question list into SINGLE_SET_MODE which will
       * only render the requested question.
       */
      if (!initialProps.location.query.page) {
        callAction(vanillaIEQuestionsListActions.SINGLE_SET_MODE, true);
        this.setDefaultActiveQuestion();
        return;
      } else {
        const questionSets = await getResourcePromise(
          vanillaIEQuestionsListStore.makeQuestionSetsQuery(pageToFetch)
        );
        const currentSet = questionSets.find((set) => {
          return set.getURLSlugPart() === initialProps.params.set;
        });
        if (!currentSet) {
          callAction(vanillaIEQuestionsListActions.SINGLE_SET_MODE, true);
          removeQueryParams('page');
        } else {
          callAction(vanillaIEQuestionsListActions.SET_PAGE_NUMBER, pageToFetch);
        }
        this.setDefaultActiveQuestion();
        return;
      }
    }

    callAction(vanillaIEQuestionsListActions.SET_PAGE_NUMBER, pageToFetch);
    await getResourcePromise(vanillaIEQuestionsListStore.makeQuestionSetsQuery(pageToFetch));

    if (initialProps.params.guideLevelSlug) {
      await topicSummaryStore.getGuideLevelQuery().getResourcePromise();
    }

    if (topicSummaryStore.isTopicSummaryValid() && !topicSummaryStore.isTopicSummaryPath()) {
      topicSummaryStore.pushStateToTopicSummaryPath();
    } else {
      this.setDefaultActiveQuestion();
      this.handleRouting();
    }
  };

  handleRouting() {
    // If there is no active question, bail
    if (!vanillaIEQuestionsListStore.hasActiveQuestion) {
      return;
    }

    // If there's no question set param, and we're POPPING back, we're going back to the guide page
    if (!this.props.params.set && this.props.location.action === 'POP') {
      return;
    }

    // If there's no question set param, and our current pathname ends with topic-summary, they're viewing
    // the topic summary, so stay put
    if (!this.props.params.set && this.props.location.pathname.endsWith('topic-summary')) {
      return;
    }

    const activeQuestionSet = vanillaIEQuestionsListStore.activeQuestionSet;

    // If there is no question set param in the URL, replaceState to the correct URL
    if (!this.props.params.set) {
      callAction(vanillaIEQuestionsListActions.UPDATE_ROUTE, {replace: true});
      return;
    }
    const questionSetParam = this.props.params.set;
    const questionParam = this.props.params.question;
    const hasQuestionSetMismatch =
      questionSetParam !== vanillaIEQuestionsListStore.activeQuestionSet.getURLSlugPart();

    // This is more of a just in case than something that should be encountered often. If, for some reason, the URL and
    // the question don't match, we update the active question set and question to the one that the URL says is correct
    if (hasQuestionSetMismatch) {
      const matchingQuestionSet = vanillaIEQuestionsListStore.questionSets.find(
        (set) => set.getURLSlugPart() === questionSetParam
      );
      if (!matchingQuestionSet) {
        return;
      }
      const matchingQuestion =
        matchingQuestionSet.getQuestions().size === 1
          ? matchingQuestionSet.getQuestions().first()
          : activeQuestionSet
              .getQuestions()
              .find((question) => question.getTitleSlug() === questionParam);
      if (!matchingQuestion) {
        return;
      }
      callAction(questionsListActions.SET_ACTIVE_QUESTION_SET, matchingQuestionSet);
      callAction(questionsListActions.SET_ACTIVE_QUESTION, matchingQuestion);
    }
  }

  setDefaultActiveQuestion() {
    const sessionHasQuestions = vanillaIEQuestionsListStore.questionSets.size > 0;
    if (!sessionHasQuestions || vanillaIEQuestionsListStore.hasActiveQuestion) {
      return;
    }

    const pageToFetch = this.props.location.query.page;
    const question = this.props.params.question;
    if (
      pageToFetch &&
      sessionHasQuestions &&
      vanillaIEQuestionsListStore.getQuestionSetByURLSlug()
    ) {
      // If we have a question set url slug in our url, use that to set the active question
      const setModel = vanillaIEQuestionsListStore.getQuestionSetByURLSlug();
      callAction(questionsListActions.SET_ACTIVE_QUESTION_SET, setModel);
      const questionModel = question
        ? setModel.getQuestionByTitleSlug(question) || setModel.getQuestions().first()
        : setModel.getQuestions().first();
      callAction(questionsListActions.SET_ACTIVE_QUESTION, questionModel);
    } else if (sessionHasQuestions) {
      // Otherwise if we have questions, set the first question set and first question
      const firstSetAndQuestionObj = vanillaIEQuestionsListStore.firstSetAndQuestion;
      callAction(
        questionsListActions.SET_ACTIVE_QUESTION_SET,
        firstSetAndQuestionObj.get('questionSet')
      );
      callAction(questionsListActions.SET_ACTIVE_QUESTION, firstSetAndQuestionObj.get('question'));
    } else if (!vanillaIEQuestionsListStore.isValidQuery) {
      // If our query is not valid, go back to the subject guide
      if (process.env.IS_BROWSER) {
        history.replace('/' + this.props.params.subject, null);
      }
    }
    /**
     * If the user doesn't fall into one of the cases above, they've specificed filters that do not yield any
     * results, which is an okay thing to happen and the questions list will handle that
     */
  }

  /**
   * The QuestionsList accepts a onListScroll function prop that is called when the list scrolls.
   * The callback is given the scroll event as its argument, whose e.target is the wrapper list.
   * Here we're measuring  the distance of the last item in the list and fetch the next page if
   * one is available and our last item is less than -200 pixels from the bottom of the list.
   */
  prefetchNextPage(e) {
    if (!vanillaIEQuestionsListStore.hasPagesLeftToFetch()) {
      return;
    }
    const lastChildClientRect = e.target.lastChild.getBoundingClientRect();
    const lastItemDistanceFromBottom = global.innerHeight - lastChildClientRect.bottom;
    if (lastItemDistanceFromBottom < -200) {
      return;
    }

    const nextPage = vanillaIEQuestionsListStore.highestPageFetched + 1;
    const totalPages = vanillaIEQuestionsListStore.totalPages;
    if (
      nextPage <= totalPages &&
      !vanillaIEQuestionsListStore.areQuestionSetsBeingFetched() &&
      !vanillaIEQuestionsListStore.getPagesToFetch().includes(nextPage)
    ) {
      callAction(vanillaIEQuestionsListActions.ADD_PAGE_TO_FETCH, nextPage);
    }
  }

  /**
   * Callback passed to QuestionsList component, which feeds this function its props, and we
   * check to see if we should update or not! This cut rerender times from over a second, down
   * to under 50ms :-D
   */
  shouldQuestionSetsWrapperUpdate = (thisProps, nextProps) => {
    if (vanillaIEQuestionsListStore.questionSets.isEmpty()) {
      return true;
    }
    const activeQuestion = vanillaIEQuestionsListStore.activeQuestion;
    if (!activeQuestion) {
      return true;
    }
    if (this.lastActiveQuestionId !== activeQuestion.getId()) {
      this.lastActiveQuestionId = activeQuestion.getId();
      return true;
    }
    if (
      sessionStore.isTeacher() &&
      this.lastTemplateQuestionSetChangeId !== templateListStore.getChangeId()
    ) {
      this.lastTemplateQuestionSetChangeId = templateListStore.getChangeId();
      return true;
    }

    /**
     * shallowEqual failed me here. Immutable Iterables are special, so we need to use Immutable.is to check equality.
     * Also, passing components as props is a special case and we need to compare their types.
     * This makes answer choices selection fast. Interacting with the question list will still be slow.
     */
    const shouldUpdate = Object.keys(thisProps).some((key) => {
      const current = thisProps[key];
      const next = nextProps[key];
      let propsDontMatch = false;
      if (Iterable.isIterable(current)) {
        propsDontMatch = !is(current, next);
      } else if (next && current && current.type && React.Component.isPrototypeOf(current.type)) {
        propsDontMatch = current.type !== next.type;
      } else {
        propsDontMatch = current !== next;
      }
      return propsDontMatch;
    });
    return shouldUpdate;
  };

  getAddToTemplateWidget(showTeacherFeatures) {
    // This will only work for the IEs that will only ever have 1 subject (true for Vanilla)
    const {isReady, value} = canCreateTemplateForSubject(
      vanillaIEQuestionsListStore.getCurrentSubjectId()
    );
    return isReady &&
      showTeacherFeatures &&
      vanillaIEQuestionsListStore.getCurrentSubjectId() &&
      sessionStore.hasTeacherAccess() ? (
      <AddToTemplateWidget hasSubjectAccess={value} />
    ) : null;
  }

  render() {
    const showTeacherFeatures = interactionEngineStore.isTeacherModeEnabled();
    const singleQuestionWidget = showTeacherFeatures ? null : <GuessHistoryWidget />;
    const questionSetWidget = this.getAddToTemplateWidget(showTeacherFeatures);
    const isSingleQuestionSet = vanillaIEQuestionsListStore.isSingleSetMode();

    // Hide the questions list filter options when user is in either of these modes
    const topDrawer =
      !isSingleQuestionSet && !isSearchMode() ? (
        <div>
          <QuestionsListSettings />
        </div>
      ) : null;

    /**
     * @TODO do not mount the <AddQuestionsToTemplates /> component if
     * a teacher is not licensed/don't have right permissions
     * for this subject
     */
    const bottomDrawer = (
      <div>
        {showTeacherFeatures ? <AddQuestionsToTemplates /> : null}
        {!isSingleQuestionSet ? <QuestionsListPaginator /> : null}
      </div>
    );

    return (
      <QuestionsList
        onListScroll={this.prefetchNextPage}
        questionsListStore={vanillaIEQuestionsListStore}
        questionSets={vanillaIEQuestionsListStore.questionSets}
        topDrawer={topDrawer}
        bottomDrawer={bottomDrawer}
        singleQuestionWidget={singleQuestionWidget}
        questionSetWidget={questionSetWidget}
        questionItemToGenerator={convertSetAndQuestionToPath}
        noQuestionSetsFoundEl={<NoQuestionsFoundMessage />}
        questionSetsAreLoading={vanillaIEQuestionsListStore.areQuestionSetsBeingFetched()}
        shouldQuestionSetsWrapperUpdate={this.shouldQuestionSetsWrapperUpdate}
      />
    );
  }
}

export default withRouter(VanillaIEQuestionsList);
