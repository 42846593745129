/* eslint-disable no-underscore-dangle */
import {fromJS, List, Set} from 'immutable';
import QuestionTypeStore from 'client/QuestionTypes/common/V2/QuestionType.store';

import {activeQuestionActions} from 'client/InteractionEngineV2/shared/ActiveQuestion/ActiveQuestionActions';

import multipleChoiceQuestionActions from './MultipleChoiceQuestion.actions';

export default class MultipleChoiceQuestionStore extends QuestionTypeStore {
  constructor(name, questionId, question) {
    super(name);
    this.initialData = fromJS({
      questionId,
      isSelectMultiple: question.isSelectMultiple(),
      selectedOptions: [],
      shouldShowExplanation: false,
      eliminatedOptions: [], // Converted to Immutable.Set
      hidingEliminatedOptions: false
    });
    this.setInitialData(this.initialData);
    this.writeData('__question', question);
    this.writeData('eliminatedOptions', this.getEliminatedOptions().toSet());
    this.handleTargeted(activeQuestionActions.ACTIVE_QUESTION_LOAD_GUESS, this._loadGuess);
    this.handleTargeted(activeQuestionActions.ACTIVE_QUESTION_CLEAR_GUESS, this._clearGuess);
    this.handleTargeted(multipleChoiceQuestionActions.SET_SELECT_MULTIPLE, this._setSelectMultiple);
    this.handleTargeted(multipleChoiceQuestionActions.SELECT_OPTION, this._selectOption);
    this.handleTargeted(
      multipleChoiceQuestionActions.TOGGLE_ELIMINATE_OPTION,
      this._toggleEliminateOption
    );
    this.handleTargeted(multipleChoiceQuestionActions.TOGGLE_HIDE_SHOW, this._toggleHideShowButton);
    this.handleTargeted(
      multipleChoiceQuestionActions.SET_ELIMINATED_TO_EMPTY,
      this._setEliminatedToEmpty
    );
    this.handleTargeted(
      multipleChoiceQuestionActions.REMOVE_OPTION_FROM_SELECTED,
      this._removeOptionFromSelected
    );
  }

  _setEliminatedToEmpty() {
    this.writeData((store) =>
      store.set('eliminatedOptions', Set()).set('hidingEliminatedOptions', false)
    );
  }

  _toggleEliminateOption(optionId) {
    let newElimintedOptions;
    if (this.isOptionEliminated(optionId)) {
      newElimintedOptions = this.getEliminatedOptions().delete(optionId);
    } else {
      newElimintedOptions = this.getEliminatedOptions().add(optionId);
    }
    this.writeData('eliminatedOptions', newElimintedOptions);
  }

  _toggleHideShowButton() {
    this.writeData('hidingEliminatedOptions', !this.getHideShow());
  }

  _removeOptionFromSelected(optionId) {
    const selectedOptions = this.readData('selectedOptions').toSet();
    this.writeData('selectedOptions', selectedOptions.delete(optionId).toList());
  }

  getHideShow() {
    return this.readData('hidingEliminatedOptions');
  }

  isOptionEliminated(optionId) {
    return this.getEliminatedOptions().includes(optionId);
  }

  getEliminatedOptions() {
    return this.readData('eliminatedOptions');
  }

  areOptionsEliminated() {
    return !this.getEliminatedOptions().isEmpty();
  }

  _setSelectMultiple(val) {
    this.writeData('isSelectMultiple', val);
  }

  _selectOption(id) {
    const isSelectMultiple = this.readData('isSelectMultiple');
    if (!isSelectMultiple) {
      this.writeData('selectedOptions', new List([id]));
      return;
    }

    const selectedOptions = this.readData('selectedOptions').toSet();
    if (selectedOptions.has(id)) {
      this.writeData('selectedOptions', selectedOptions.delete(id).toList());
    } else {
      this.writeData('selectedOptions', selectedOptions.add(id).toList());
    }
  }

  buildGuessContent() {
    const selectedOptions = this.readData('selectedOptions');
    return selectedOptions.reduce((acc, option) => {
      acc[option] = true;
      return acc;
    }, {});
  }

  buildEliminatedOptions() {
    const eliminatedOptions = this.getEliminatedOptions();
    return eliminatedOptions.reduce((acc, option) => {
      acc[option] = true;
      return acc;
    }, {});
  }

  _loadGuess(guess) {
    // Parent store needs points data from guess and needs to register this question has a guess
    super._loadGuess(guess);
    const selectedOptions = guess.get('content').map((val, optionId) => optionId);
    this.writeData('selectedOptions', selectedOptions);
  }

  _clearGuess() {
    // Need to call this on the parent class
    super._clearGuess();
    this.writeData('selectedOptions', this.initialData.get('selectedOptions'));
    this._setEliminatedToEmpty();
  }

  isSelectedOption(id) {
    return this.readData('selectedOptions').includes(id);
  }

  get correctAnswer() {
    return this.validResponse.keySeq().toSet();
  }

  get selectedAnswer() {
    return this.readData('selectedOptions').toSet();
  }
}
