import makeConstants from 'lib/makeConstants';

const examStatuses = makeConstants('draft', 'published', 'unpublished');

export const examExtensions = {
  getSortedQuestionSets() {
    const func = () =>
      this.getQuestionSets().sortBy((questionSet) =>
        questionSet.getIn(['relationshipMeta', 'exam', this.getId(), 'position'], 0)
      );
    return this.cache('getSortedQuestionSets', func);
  },
  getSortedSections() {
    const func = () => this.getSections().sortBy((section) => section.getPosition());
    return this.cache('getSortedSections', func);
  },
  getTimeLimit() {
    return this.hasSections()
      ? this.getSections().reduce((r, section) => r + section.getTimeLimit(), 0)
      : this.get('time_limit');
  },
  hasSections() {
    return !this.getSections().isEmpty();
  },
  isDraft() {
    return this.getStatus() === examStatuses.draft;
  },
  isPublished() {
    return this.getStatus() === examStatuses.published;
  },
  isUnpublished() {
    return this.getStatus() === examStatuses.unpublished;
  },
  async publish() {
    return await this.setStatus(examStatuses.published).save();
  },
  async unpublish() {
    return await this.setStatus(examStatuses.unpublished).save();
  }
};
