import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import makeRef from 'lib/makeRef';
import {AppPortal} from 'client/Portals/AppPortal/AppPortal.react';
import './simple-modal.scss';

// USAGE:
// <SimpleModal
//   title='My Cool Title'
//   className='not-actually-necessary'
//   onModalClose={() => callAction(myStore.HIDE_CONTENT)}
// >
//   Content Goes Here
// </SimpleModal>
//
// Give `hideToolbar={true}` to the component to hide the title/toolbar!`

export default class SimpleModal extends React.Component {
  static propTypes = {
    bodyWrapperClassName: PropTypes.string,
    className: PropTypes.string,
    hideToolbar: PropTypes.bool,
    title: PropTypes.string,
    onModalClose: PropTypes.func,
    footer: PropTypes.node
  };

  static defaultProps = {
    className: '',
    bodyWrapperClassName: ''
  };

  componentDidMount() {
    const body = global.document.getElementsByTagName('body')[0];
    const html = global.document.getElementsByTagName('html')[0];
    html.classList.add('has-active-modal');
    body.classList.add('has-active-modal');
  }

  componentWillUnmount() {
    const body = global.document.getElementsByTagName('body')[0];
    const html = global.document.getElementsByTagName('html')[0];
    html.classList.remove('has-active-modal');
    body.classList.remove('has-active-modal');
  }

  modalWindowNode = null;

  makeRef = makeRef.bind(this);

  handleClick = (e) => {
    // If we're clicking outside of the modal window, we want to close the modal.
    // We can't attach this handler to the shade, since its behind the overflow handler.
    if (!this.modalWindowNode.contains(e.target)) {
      this.props.onModalClose();
    }
  };

  getHeader() {
    if (this.props.hideToolbar) {
      return null;
    }

    return (
      <div className='simple-modal__header'>
        <div className='simple-modal__title'>{this.props.title}</div>
        <div
          className='simple-modal__close-btn'
          onClick={this.props.onModalClose}
          role='button'
          tabIndex={0}
        >
          <span className='fa fa-times' />
        </div>
      </div>
    );
  }

  getFooter() {
    if (!this.props.footer) {
      return null;
    }

    return <div className='simple-modal__footer'>{this.props.footer}</div>;
  }

  render() {
    return (
      <AppPortal>
        <div className={`simple-modal ${this.props.className}`} onClick={this.handleClick}>
          <div className='simple-modal__shade' />
          <div className='simple-modal__overflow-handler'>
            <div className='simple-modal__wrapper'>
              <div
                className={classnames('simple-modal__window', this.props.bodyWrapperClassName)}
                ref={this.makeRef}
                data-ref-name='modalWindowNode'
              >
                {this.getHeader()}
                <div className='simple-modal__body'>{this.props.children}</div>
                {this.getFooter()}
              </div>
            </div>
          </div>
        </div>
      </AppPortal>
    );
  }
}
