/* eslint-disable no-underscore-dangle */
import {fromJS} from 'immutable';
import QuestionTypeStore from 'components/QuestionTypes/QuestionType.store';
import questionTypeActions from 'components/QuestionTypes/questionType.actions';

import fillInTheBlankQuestionActions from './FillInTheBlankQuestion.actions';

export default class FillInTheBlankQuestionStore extends QuestionTypeStore {
  constructor(name, questionId, question) {
    super(name);

    this.initialData = fromJS({
      ...this.draftGuessInitialData,
      questionId,
      selectedOptions: {},
      showExplanation: false
    });

    this.setInitialData(this.initialData);
    this.writeData('__question', question);

    this.handleTargeted(questionTypeActions.LOAD_GUESS, this._loadGuess);
    this.handleTargeted(questionTypeActions.CLEAR_GUESS, this._clearGuess);
    this.handleTargeted(fillInTheBlankQuestionActions.SELECT_OPTION, this._selectOption);
  }

  afterLoadDraftGuess(draftGuess) {
    this.writeData('selectedOptions', this.parseGuess(draftGuess));
  }

  buildGuessContent() {
    return this.selectedOptions.toJS();
  }

  _clearGuess() {
    // Need to call this on the parent class
    super._clearGuess();
    this.writeData('selectedOptions', this.initialData.get('selectedOptions'));
    super.kickoffDebouncedSaveDraftGuess();
  }

  getSelectedChoiceForDropdown(dropdownId) {
    return this.readData('selectedOptions').get(dropdownId);
  }

  get selectedOptions() {
    return this.readData('selectedOptions');
  }

  _loadGuess(guess) {
    // Parent store needs points data from guess and needs to register this question has a guess
    super._loadGuess(guess);
    this.writeData('selectedOptions', this.parseGuess(guess));
  }

  parseGuess(guess) {
    return guess.get('content');
  }

  _selectOption({choiceId, dropdownId}) {
    const selections = this.readData('selectedOptions');
    this.writeData('selectedOptions', selections.set(dropdownId, choiceId));
    super.kickoffDebouncedSaveDraftGuess();
  }
}
