import React, {useState, useMemo} from 'react';
import PropTypes from 'prop-types';
import constants from 'client/constants';
import {
  Button,
  Dropdown,
  DropdownItem,
  Icon,
  copyToClipboard,
  addToast,
  Dialogue
} from '@albert-io/atomic';
import {history} from 'client/history';
import {getNodeText} from 'lib/reactUtils';
import withTracking from 'lib/hocs/withTracking';
import {resource} from '@albert-io/json-api-framework/request/builder';
import {invalidatePartialInterest, genericMandarkRequest} from 'resources/mandark.resource';
import useAreLatestQueriesReady from 'lib/hooks/useAreLatestQueriesReady';
import FoldersModal from 'components/PracticeView/FoldersModal/FoldersModal.react';
import ConfirmActionModal from 'client/components/ConfirmActionModal/ConfirmActionModal.react';
import sessionStore from 'client/Session/SessionStore';
import {getModelForResourceType} from 'resources/modelRegistry';
import CustomizeReassignModal from 'client/components/CustomizeReassignModal/CustomizeReassignModal.react';

const TrackedButton = withTracking(Button, {
  onClick: {
    feature: 'assignment list',
    event: 'click',
    type: 'dropdown',
    component: 'table',
    content: 'Options'
  }
});

const TrackedDropdownItem = withTracking(DropdownItem, {
  onClick: {
    feature: 'assignment list',
    event: 'click',
    type: 'dropdown item',
    component: 'options_menu',
    content: (e, props, ref) => getNodeText(ref)
  }
});

const AssignmentRowOptions = ({assignment, classroomId}) => {
  const assignmentId = assignment.getId();
  const [showFoldersModal, setShowFoldersModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showReassignModal, setShowReassignModal] = useState(false);

  const copyShareLink = () => {
    copyToClipboard(`${constants.ALBERT_FULL_PATH}/assignment/${assignmentId}`, () => {
      addToast({
        color: 'positive',
        title: 'Link Copied!',
        message: 'The link to this assignment has been copied to your clipboard.'
      });
    });
  };

  const handleDeleteClassroomAssignment = async () => {
    const query = {
      resourcePath: ['assignments_v3', assignment.id, 'relationships', 'classrooms_v1']
    };
    await genericMandarkRequest('delete', query, {
      data: [
        {
          id: classroomId,
          type: 'classrooms_v1'
        }
      ]
    });
  };

  return (
    <>
      {showFoldersModal && (
        <AddAssignmentQuestionSetsToFolderModal
          assignmentId={assignmentId}
          handleClose={() => setShowFoldersModal(false)}
          questionCount={assignment.getCountOfQuestions()}
        />
      )}
      {showReassignModal && (
        <CustomizeReassignModal
          assignmentId={assignment.id}
          assignmentName={assignment.getName?.()}
          handleClose={() => setShowReassignModal(false)}
        />
      )}
      {showModal && (
        <ConfirmActionModal
          handleClose={() => setShowModal(false)}
          title='Are you sure?'
          size='s'
          makeRequest={handleDeleteClassroomAssignment}
          hideCloseBtn
          onResolve={() => {
            invalidatePartialInterest({
              resourcePath: ['assignments_v3'],
              filter: {
                classrooms_v1: classroomId
              }
            });
          }}
        >
          {({isRequestInflight, onCancel, onConfirm}) => (
            <>
              <Dialogue.Body align='left'>
                <p>
                  You are about to remove this class from <strong>{assignment?.getName?.()}</strong>
                  . The assignment will still exist in any other classes it&apos;s assigned to. This
                  action cannot be undone.
                </p>
              </Dialogue.Body>
              <Dialogue.BtnGroup align='right' className='u-display_inline'>
                <TrackedButton color='secondary' disabled={isRequestInflight} onClick={onCancel}>
                  Cancel
                </TrackedButton>
                <TrackedButton color='negative' disabled={isRequestInflight} onClick={onConfirm}>
                  Delete
                </TrackedButton>
              </Dialogue.BtnGroup>
            </>
          )}
        </ConfirmActionModal>
      )}
      <Dropdown
        trigger={
          <TrackedButton variant='text'>
            Actions
            <Icon icon='caret-down' className='u-mar-l_1' />
          </TrackedButton>
        }
      >
        <TrackedDropdownItem
          as='button'
          icon={['far', 'cog']}
          data-testid='assignment-row-options__edit-button'
          onClick={() => {
            const editUrl = `/assignment-builder/create-assignment/${assignmentId}/settings`;
            history.pushState(null, editUrl);
          }}
        >
          Settings
        </TrackedDropdownItem>
        <TrackedDropdownItem
          as='button'
          icon={['far', 'file-alt']}
          disabled={!assignment.getStudents()?.first()}
          onClick={() => {
            history.pushState(
              null,
              `/classes/${classroomId}/assignments/${assignmentId}/student-detail/${assignment
                .getStudents()
                .sortBy((student) => {
                  return student.getLastName().toUpperCase();
                })
                .first()
                .getId()}/view`
            );
          }}
        >
          View student answers
        </TrackedDropdownItem>
        <TrackedDropdownItem as='button' onClick={() => setShowReassignModal(true)} icon='redo-alt'>
          Reassign
        </TrackedDropdownItem>
        <TrackedDropdownItem
          as='button'
          icon={['far', 'folder']}
          onClick={() => {
            setShowFoldersModal(true);
          }}
        >
          Save questions to folder
        </TrackedDropdownItem>
        <TrackedDropdownItem as='button' onClick={copyShareLink} icon='link'>
          Share
        </TrackedDropdownItem>
        <TrackedDropdownItem
          as='button'
          icon={['far', 'trash-alt']}
          className='u-color_red-500'
          textProps={{color: 'negative'}}
          iconProps={{color: 'red'}}
          onClick={() => {
            setShowModal(true);
          }}
        >
          Delete
        </TrackedDropdownItem>
      </Dropdown>
    </>
  );
};

AssignmentRowOptions.propTypes = {
  assignment: PropTypes.instanceOf(getModelForResourceType('assignment_v3')),
  classroomId: PropTypes.string
};

export const AddAssignmentQuestionSetsToFolderModal = ({
  assignmentId,
  handleClose,
  questionCount
}) => {
  const userId = sessionStore.getUserId();
  const assignmentQuery = resource('assignment_v3')
    .mandarkEndpoint(['assignments_v3', assignmentId])
    .include('question_sets_v1')
    .customQuery({
      meta: {
        teacher_id: userId
      }
    });
  const assignment = assignmentQuery.getResource();
  const {isReady} = useAreLatestQueriesReady([assignmentQuery]);
  const questionSets = useMemo(
    () =>
      assignment
        .getQuestionSets()
        .sortBy((set) => set.getAssignmentPosition(assignment.getId()))
        .map((set) => set.getId())
        .toJS(),
    [assignment]
  );
  return (
    <FoldersModal
      isReady={isReady}
      selectedQuestionSetIds={questionSets}
      userId={userId}
      closeModalFunc={handleClose}
      selectedQuestionCount={questionCount}
    />
  );
};

AddAssignmentQuestionSetsToFolderModal.propTypes = {
  assignmentId: PropTypes.string,
  handleClose: PropTypes.func,
  questionCount: PropTypes.number
};

export default AssignmentRowOptions;
