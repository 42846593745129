import React from 'react';
import PropTypes from 'prop-types';
import {setUpStore} from 'client/framework';
import awaitMandarkQueries from 'lib/hocs/awaitMandarkQueries';
import SearchDropdown, {searchDropdownProps} from '../SearchDropdown/SearchDropdown.react';
import SearchDropdownStore from '../SearchDropdown/SearchDropdown.store';

import './mandark-search.scss';

export const mandarkSearchDropdownProps = {
  defaultSelectedId: PropTypes.string,
  onClick: PropTypes.func,
  pageSize: PropTypes.number,
  required: PropTypes.bool,
  ...searchDropdownProps
};

/**
 * Packages up the mandark-ness around populating a dropdown with stuff from mandark in the hope of it being more reusable.
 *
 * Exposes normal props, but also two function  props that should return valid mandarkEndpoint results:
 *    byIdQuery - a function that takes an id and SHOULD return a by-id lookup
 *    indexQuery - function that takes a search string and returns a query using that search filter
 *
 */
export default awaitMandarkQueries(
  (props) => ({
    queries: props.defaultSelectedId
      ? {
          defaultSelected: props.byIdQuery(props.defaultSelectedId)
        }
      : {}
  }),
  class MandarkSearchDropdown extends React.Component {
    static propTypes = {
      byIdQuery: PropTypes.func.isRequired,
      indexQuery: PropTypes.func.isRequired,
      ...mandarkSearchDropdownProps
    };

    static defaultProps = {
      renderFunc: (option) => {
        return (
          <div className='search-option'>
            <div className='search-option-value'>{option.getName()}</div>
          </div>
        );
      },
      displayName: 'name',
      resetOnUnmount: true,
      pageSize: 25
    };

    constructor(props) {
      super(props);
      // populated async via did mount
      this.defaultOption = props.defaultSelected;
    }

    async componentDidMount() {
      if (this.props.defaultSelectedId) {
        try {
          this.defaultOption = await this.props
            .byIdQuery(this.props.defaultSelectedId)
            .getResourcePromise();
        } catch (e) {
          this.defaultOption = null;
        }
      }
    }

    getStore() {
      return setUpStore(SearchDropdownStore, this.props.storeName);
    }

    getOptions() {
      const searchString = this.getStore().getSearchString();
      return searchString && this.props.indexQuery(searchString).isResourcePopulated()
        ? this.props.indexQuery(searchString).getResource()
        : null;
    }

    getSearchMeta() {
      const searchString = this.getStore().getSearchString();
      return searchString && this.props.indexQuery(searchString).isResourcePopulated()
        ? this.props.indexQuery(searchString).getResourceMetadata()
        : null;
    }

    areResultsLoading() {
      const searchString = this.getStore().getSearchString();
      return searchString !== '' && !this.props.indexQuery(searchString).isResourcePopulated();
    }

    getMoreOptionsAvailableMessage() {
      const meta = this.getSearchMeta();
      if (!meta) {
        return null;
      }
      const totalEntries = meta.getIn(['page', 'total_entries'], 0);
      const additionalEntries = totalEntries - this.props.pageSize;
      if (additionalEntries <= 0) {
        return null;
      }
      return `${additionalEntries} results are hidden, try a more specific search.`;
    }

    render() {
      return (
        <SearchDropdown
          className={this.props.className}
          customClearOption={this.props.customClearOption}
          resetOnUnmount={this.props.resetOnUnmount}
          disableClear={this.props.disableClear}
          disabled={this.props.disabled}
          emptyResultsComponent={this.props.emptyResultsComponent}
          error={this.props.error}
          errorMessage={this.props.errorMessage}
          onBlur={this.props.onBlur}
          label={this.props.label}
          storeName={this.props.storeName}
          options={this.getOptions()}
          onEmptyResultsFunc={this.props.onEmptyResultsFunc}
          defaultOption={this.defaultOption}
          displayName={this.props.displayName}
          handleOptionChange={this.props.onClick}
          bottomMessage={this.getMoreOptionsAvailableMessage()}
          areResultsLoading={this.areResultsLoading()}
          renderFunc={this.props.renderFunc}
          placeholder={this.props.placeholder}
          required={this.props.required}
        />
      );
    }
  }
);
