import React from 'react';
import PropTypes from 'prop-types';

import TextTypeInput from '../common/TextTypeInput.react';

export default class TextArea extends React.Component {
  static propTypes = {
    autoResize: PropTypes.bool
  };

  textRef = React.createRef();

  render() {
    let height = '';
    if (this.props.autoResize && this.textRef.current) {
      if (
        this.textRef.current.inputNode.offsetHeight < this.textRef.current.inputNode.scrollHeight
      ) {
        // the `+ 5` stops the scrollbar from appearing
        height = this.textRef.current.inputNode.scrollHeight + 5;
        height = height.toString();
      }
      // if there is no text, set textbox height to 50px
      if (this.textRef.current.inputNode.textLength < 1) {
        height = '50';
      }
    }
    return (
      <TextTypeInput
        style={{height: `${height}px`}}
        ref={this.textRef}
        {...this.props}
        textElementTagName='textarea'
      />
    );
  }
}
