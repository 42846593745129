// @flow
import {Map} from 'immutable';
import {Store} from 'client/framework';
import {activeQuestionActions} from './ActiveQuestionActions';
import systemTimeOffsetStore from 'client/generic/SystemTimeOffset/SystemTimeOffset.store';
import interactionEngineActions from 'client/InteractionEngineV2/InteractionEngineActions';
import sessionStore from 'client/Session/SessionStore';
import {resource, type QueryBuilder} from '@albert-io/json-api-framework/request/builder';
import {SubjectModelV2} from 'resources/GeneratedModels/Subject/SubjectModel.v2';

class ActiveQuestionStore extends Store {
  constructor(name) {
    super(name);
    this.initialData = new Map({
      error: '',
      hasResponse: false,
      pointsEarned: 0,
      question: new Map(),
      questionSet: new Map(),
      rubric: new Map(),
      solutionText: '',
      startTime: 0,
      storeName: ''
    });

    this.setInitialData(this.initialData);
    // When the interactionEngine resets we lose a lot of data we're relying on it for -- wipe us too
    this.handle(interactionEngineActions.RESET_STORE, this._resetStore);
    this.handle(activeQuestionActions.ACTIVE_QUESTION_RESET_STORE, this._resetStore);
    this.handle(activeQuestionActions.ACTIVE_QUESTION_VIEW_QUESTION, this._setQuestion);
    this.handle(activeQuestionActions.ACTIVE_QUESTION_SET_QUESTION_SET, this._setQuestionSet);
    this.handle(activeQuestionActions.ACTIVE_QUESTION_SET_START_TIME, this._setStartTime);
  }

  _resetStore() {
    /**
     * We use startTime to derive how long the user was on a question (Date.now - startTime = timeElapsed).
     * We reset the startTime to Date.now() whenever a new question is loaded. Therefore, it's never necessary
     * to reset startTime.
     */
    const propertiesToReset = this.initialData
      .delete('startTime')
      .keySeq()
      .toJS();
    this.resetProperties(...propertiesToReset);
  }

  _setQuestion({question, storeName}) {
    this.writeData((store) => {
      return store.set('question', question).set('storeName', storeName);
    });
  }

  get question(): Map<string, *> {
    return this.readData('question');
  }

  get questionId(): string | null {
    if (!this.question) {
      return null;
    }

    return this.question.get('id');
  }

  _setQuestionSet(questionSet) {
    this.writeData('questionSet', questionSet);
  }

  get questionSet(): Map<string, *> {
    return this.readData('questionSet');
  }

  get questionSetId(): string | null {
    if (!this.questionSet) {
      return null;
    }

    return this.questionSet.getId();
  }

  getActiveQuestionSetAssignmentQuery(): QueryBuilder {
    return resource('student_assignments_v1')
      .mandarkEndpoint(['student_assignments_v1'])
      .filter({
        students_v2: sessionStore.getUserId(),
        question_sets_v1: this.questionSetId,
        included: {
          students_v2: {
            id: sessionStore.getUserId()
          }
        },
        any_of: [
          {
            start_date: {
              null: true
            }
          },
          {
            start_date: {
              less_than: 'now'
            }
          }
        ]
      })
      .include('students_v2')
      .meta({
        context: {
          student: {
            id: sessionStore.getUserId()
          }
        }
      })
      .customQuery({
        with_meta: 'student_assignment_v1'
      })
      .sort('due_date');
  }

  getActiveQuestionSetAssignmentId(): string | null {
    if (!sessionStore.hasValidSession() || !this.questionSetId) {
      return null;
    }

    const activeAssignment = this.getActiveQuestionSetAssignmentQuery()
      .getResource()
      .find(
        (assignment) =>
          assignment.isStudentFinished(
            sessionStore.getUserId(),
            systemTimeOffsetStore.getCurrentTime()
          ) === false || assignment.canAssignmentBeSubmitted()
      );

    return activeAssignment ? activeAssignment.getId() : null;
  }

  get questionSetSubject(): Map<string, *> | null {
    if (!this.questionSet) {
      return null;
    }

    return this.questionSet.getSubject();
  }

  get questionStoreName(): string {
    return this.readData('storeName');
  }

  _setStartTime() {
    this.writeData('startTime', Date.now());
  }

  get startTime() {
    return this.readData('startTime');
  }

  _setQuestionStoreName(storeName) {
    this.writeData('storeName', storeName);
  }

  isMultipleQuestionSet(): boolean {
    return this.questionSet.getQuestions().size > 1;
  }

  // @todo possibly move to utils
  getQuestionDifficulty(): number {
    const questionDifficulty = this.question.get('difficulty');

    switch (questionDifficulty) {
      case 1:
        return 'Easy';
      case 2:
        return 'Moderate';
      case 3:
        return 'Difficult';
      default:
        return 'n/a';
    }
  }

  getSubjectQuery(): QueryBuilder {
    const subjectId = this.questionSet.getSubjectId();
    return resource('subjects_v2').mandarkEndpoint(['subjects_v2', subjectId]);
  }

  getSubject(): SubjectModelV2 {
    return this.getSubjectQuery().isResourcePopulated()
      ? this.getSubjectQuery().getResource()
      : SubjectModelV2.getDefaultModel();
  }
}

export const activeQuestionStore = new ActiveQuestionStore('ActiveQuestionStore');
export default activeQuestionStore;
