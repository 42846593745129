/* eslint-disable import/prefer-default-export */
// @flow
import {fromJS, Record, List, Map, Set} from 'immutable';
import {GuideLevel} from '../GuideLevel/GuideLevel.model';
import {stats} from 'lib/StatsUtil';
import {GenericModel} from 'resources/Generic.model';

const SubjectRecord = Record({
  /* eslint-disable camelcase */
  alternate_name: '',
  code: '',
  description: '',
  domain: '',
  footer_text: '',
  group: '',
  hidden: false,
  id: '',
  logo_image_url: '',
  name: '',
  meta: new Map(),
  free_response_questions_published: false,
  url_slug: '',
  guide_levels: new List(),
  themes: new List(),
  topics: new List(),
  subtopics: new List(),
  subject_faqs: new List(),
  subscriptions: new List(),
  subject_checklist_levels: new List()
  /* eslint-enable camelcase */
});
const SubjectRelationshipConfig = fromJS({
  /* eslint-disable camelcase */
  endpoint: 'subjects_v1',
  relationships: {
    subject_faq_v1: {
      required: false,
      type: 'one-to-many'
    }
  }
  /* eslint-enable camelcase */
});

export class SubjectModel extends GenericModel(SubjectRecord, SubjectRelationshipConfig) {
  areFRQsPublished(): boolean {
    return this.get('free_response_questions_published', false);
  }

  getAlternateName(): string {
    return this.get('alternate_name');
  }

  getCode(): string {
    return this.get('code');
  }

  getDescription(): string {
    return this.get('description');
  }

  getDomain(): string {
    return this.get('domain');
  }

  getFooterText(): string {
    return this.get('footer_text');
  }

  getGroup(): string {
    return this.get('group');
  }

  isHidden(): boolean {
    return this.get('hidden');
  }

  getId(): string {
    return this.get('id');
  }

  getLogoImageUrl(): string {
    return this.get('logo_image_url');
  }

  getName(): string {
    return this.get('name');
  }

  getUrlSlug(): string {
    return this.get('url_slug');
  }

  getGuideLevels(): List<GuideLevel> {
    return this.get('guide_levels');
  }

  getSortedThemes(): List<GuideLevel> {
    return this.cache('themes', () => this.get('themes').sortBy((theme) => theme.getPosition()));
  }

  getThemes(): List<GuideLevel> {
    return this.get('themes');
  }

  getTopics(): List<GuideLevel> {
    return this.get('topics');
  }

  getThemeTags(): List<string> {
    return this._getTagsByLevel().get('themeTags');
  }

  getTopicTags(): List<string> {
    return this._getTagsByLevel().get('topicTags');
  }

  getSubtopicTags(): List<string> {
    return this._getTagsByLevel().get('subtopicTags');
  }

  getSubtopics(): List<GuideLevel> {
    return this.get('subtopics');
  }

  getSubjectSubscriptions(): List<Map<string, number>> {
    return this.get('subscriptions');
  }

  getGuideLevelById(guideLevelId: string): GuideLevel {
    return this.getGuideLevels()
      .getCollection()
      .find((level) => level.getId() === guideLevelId);
  }

  getGuideLevelBySlug(guideLevelSlug: string): GuideLevel {
    return this.getGuideLevels()
      .getCollection()
      .find((level) => level.getUrlSlug() === guideLevelSlug);
  }

  getGuideLevelFullPathObject(guideLevelSlug: string): Map<string, *> | null {
    const levels = ['theme', 'topic', 'subtopic'];
    const getters = [this.getThemes, this.getTopics, this.getSubtopics];
    const currentGuideLevel = this.getGuideLevelBySlug(guideLevelSlug);
    if (!currentGuideLevel) {
      return null;
    }
    const mandarkGuideLevelIds = currentGuideLevel.getLevel().split('.');

    let pathObject = new Map();
    mandarkGuideLevelIds.forEach((id, i) => {
      // If we're at the last guideLevel needed, use the one we already got
      if (i + 1 === mandarkGuideLevelIds.length) {
        pathObject = pathObject.set(levels[i], currentGuideLevel);
      } else {
        // Otherwise find it and set it
        const getter = getters[i].bind(this);
        const level = getter().find((guideLevel) => {
          return guideLevel.getLevel().split('.')[i] === mandarkGuideLevelIds[i];
        });
        pathObject = pathObject.set(levels[i], level);
      }
    });

    return pathObject;
  }

  // -----------------------
  // ---- Stats Methods ----
  // -----------------------
  _getAnsweredQuestionsCount(difficulty: string): Map<string, *> {
    return this.getIn(
      ['meta', 'counts', `${difficulty}_questions`],
      new Map({
        correct_answers: 0,
        count: 0,
        questions_answered: 0
      })
    );
  }

  _getAnsweredQuestionsSums(difficulty: string): Map<string, *> {
    return this.getIn(
      ['meta', 'sums', `${difficulty}_questions`],
      new Map({
        points_earned: 0,
        time_elapsed: 0
      })
    );
  }

  _validateDifficulty(difficulty: string) {
    if (difficulty !== 'difficult' && difficulty !== 'medium' && difficulty !== 'easy') {
      throw new Error(
        `Specified difficulty query ${difficulty} is not one of 'easy', 'medium', or 'difficult`
      );
    }
  }

  getCorrectAnswerCountByDifficulty(difficulty: string): number {
    this._validateDifficulty(difficulty);
    return this._getAnsweredQuestionsCount(difficulty).get('correct_answers', 0);
  }

  getQuestionCountByDifficulty(difficulty: string): number {
    this._validateDifficulty(difficulty);
    return this._getAnsweredQuestionsCount(difficulty).get('count', 0);
  }

  getTotalAnsweredCountByDifficulty(difficulty: string): number {
    this._validateDifficulty(difficulty);
    return this._getAnsweredQuestionsCount(difficulty).get('questions_answered', 0);
  }

  getTotalPointsEarnedByDifficulty(difficulty: string): number {
    this._validateDifficulty(difficulty);
    return this._getAnsweredQuestionsSums(difficulty).get('points_earned', 0) || 0;
  }

  getTotalTimeElapsedByDifficulty(difficulty: string): number {
    this._validateDifficulty(difficulty);
    return this._getAnsweredQuestionsSums(difficulty).get('time_elapsed', 0);
  }

  getAverageTimeByDifficulty(difficulty: string): number {
    this._validateDifficulty(difficulty);
    return stats.divide(
      this.getTotalTimeElapsedByDifficulty(difficulty),
      this.getTotalAnsweredCountByDifficulty(difficulty),
      {fixed: false}
    );
  }

  getCompletionPercentageByDifficulty(difficulty: string): number {
    return stats.percentage(
      this.getTotalAnsweredCountByDifficulty(difficulty),
      this.getQuestionCountByDifficulty(difficulty)
    );
  }

  getAverageAccuracyByDifficulty(difficulty: string): number {
    return stats.percentage(
      this.getCorrectAnswerCountByDifficulty(difficulty),
      this.getTotalAnsweredCountByDifficulty(difficulty)
    );
  }

  getTotalPointsEarned(): number {
    return (
      this.getTotalPointsEarnedByDifficulty('difficult') +
      this.getTotalPointsEarnedByDifficulty('medium') +
      this.getTotalPointsEarnedByDifficulty('easy')
    );
  }

  getTotalAnswered(): number {
    return (
      this.getTotalAnsweredCountByDifficulty('difficult') +
      this.getTotalAnsweredCountByDifficulty('medium') +
      this.getTotalAnsweredCountByDifficulty('easy')
    );
  }

  getTotalQuestions(): number {
    return (
      this.getQuestionCountByDifficulty('difficult') +
      this.getQuestionCountByDifficulty('medium') +
      this.getQuestionCountByDifficulty('easy')
    );
  }

  getTotalTimeElapsed(): number {
    return (
      this.getTotalTimeElapsedByDifficulty('difficult') +
      this.getTotalTimeElapsedByDifficulty('medium') +
      this.getTotalTimeElapsedByDifficulty('easy')
    );
  }

  getTotalCorrectAnswers(): number {
    return (
      this.getCorrectAnswerCountByDifficulty('difficult') +
      this.getCorrectAnswerCountByDifficulty('medium') +
      this.getCorrectAnswerCountByDifficulty('easy')
    );
  }

  getAverageAccuracy(): number {
    return stats.percentage(this.getTotalCorrectAnswers(), this.getTotalAnswered());
  }

  getAverageTime(): number {
    return stats.divide(this.getTotalTimeElapsed(), this.getTotalAnswered());
  }

  getCompletionPercentage(): number {
    return stats.percentage(this.getTotalAnswered(), this.getTotalQuestions());
  }

  _getTagsByLevel(): Map<*, *> {
    return this.cache('tags-by-level', () => {
      return this.getThemes().reduce(
        (tagMap, theme) => {
          theme.getTopics().forEach((topic) => {
            tagMap = tagMap.update('topicTags', (topicTags) => topicTags.concat(topic.getTags()));
            topic.getSubtopics().forEach((subtopic) => {
              tagMap = tagMap.update('subtopicTags', (subtopicTags) =>
                subtopicTags.concat(subtopic.getTags())
              );
            });
          });

          return tagMap;
        },
        new Map({
          themeTags: new Set(),
          topicTags: new Set(),
          subtopicTags: new Set()
        })
      );
    });
  }
}
