/**
 * # SplitButton
 *
 * A component that renders a split button with two clickable elements.
 *
 * ## Props
 *
 * - `className` (optional): Additional CSS class name for the component.
 * - `children`: An array of two React elements representing the two clickable elements.
 *
 * ## Usage
 *
 * ```jsx
 * import SplitButton from './SplitButton';
 *
 * const MyComponent = () => {
 *   return (
 *     <SplitButton className="my-split-button">
 *       <button>Button 1</button>
 *       <button>Button 2</button>
 *     </SplitButton>
 *   );
 * };
 * ```
 */

import React from 'react';
import classnames from 'classnames';

import './split-button.scss';

interface SplitButtonProps extends PropsWithClassNameOptional {
  children: [React.ReactElement, React.ReactElement];
}

const SplitButton = ({className, children, ...rest}: SplitButtonProps) => {
  return (
    <div className={classnames('split-button__wrapper', className)} {...rest}>
      {children}
    </div>
  );
};

export default SplitButton;
