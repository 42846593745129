import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import DropdownRenderer from './renderers/Dropdown/DropdownRenderer.react';
import SnippetSelectionRenderer from './renderers/SnippetSelection/SnippetSelectionRenderer.react';
import PassageCorrectionRenderer from './renderers/PassageCorrection/PassageCorrectionRenderer.react';
import BasicRenderer from './renderers/BasicRenderer/BasicRenderer.react';

import './markdown-renderer.scss';

const MarkdownRendererV2 = (props) => {
  const {dropdowns, snippetSelectionData, passageCorrection, className, text} = props;

  let Renderer;

  if (dropdowns) {
    Renderer = DropdownRenderer;
  } else if (snippetSelectionData) {
    Renderer = SnippetSelectionRenderer;
  } else if (passageCorrection) {
    Renderer = PassageCorrectionRenderer;
  } else {
    Renderer = BasicRenderer;
  }

  return (
    <div className={classnames('markdown-renderer-v2', className)}>
      <Renderer {...props} text={!text ? '' : text} />
    </div>
  );
};

MarkdownRendererV2.propTypes = {
  dropdowns: PropTypes.object,
  snippetSelectionData: PropTypes.object,
  passageCorrection: PropTypes.bool,
  text: PropTypes.string,
  className: PropTypes.string
};

export default MarkdownRendererV2;
