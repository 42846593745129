import React from 'react';
import {Link} from 'react-router';
import {callTargetedAction, getStoreByName} from 'client/framework';
import sessionStore from 'client/Session/SessionStore';
import Drawer from 'generic/Drawer/Drawer.react';
import {drawerActions} from 'client/generic/Drawer/DrawerActions';
import UserAvatar from 'generic/UserAvatar/UserAvatar.react';
import {getBaseUserQuery} from 'client/User/UserQueryUtil';
import CustomerFeedbackDialogue from 'client/CustomerFeedback/CustomerFeedbackDialogue.react';
import {Anchor, Modal, NavItem} from '@albert-io/atomic';

import stylevars from '../global-navigation.scss';
import {getDropdownLinks} from '../navLinks';
import PointsIndicator from '../PointsIndicator/PointsIndicator.react';

const mobileBreakpoint = parseInt(stylevars.mobileBreakpoint, 10);
const tabletBreakpoint = parseInt(stylevars.tabletBreakpoint, 10);

const drawerFooterContents = (
  <p className='drawer-footer__disclaimer'>
    Looking for the{' '}
    <Link
      to='/leaderboard'
      onClick={(e) => {
        e.stopPropagation();
        callTargetedAction({
          name: drawerActions.DRAWER_SET_DRAWER_VISIBILITY,
          payload: false,
          targetStore: 'DropdownLinksDrawer'
        });
      }}
    >
      leaderboard
    </Link>
    ?
  </p>
);

export default class DropdownLinks extends React.Component {
  constructor() {
    super();

    this.state = {
      showModal: false
    };
  }

  getDrawerStore() {
    return getStoreByName('DropdownLinksDrawer') || null;
  }

  handleToggle = () => {
    this.setState((state) => ({showModal: !state.showModal}));
  };

  handleDrawerToggle = () => {
    callTargetedAction({
      name: drawerActions.DRAWER_SET_DRAWER_VISIBILITY,
      payload: true,
      targetStore: 'DropdownLinksDrawer'
    });
  };

  generateDropdownLinks() {
    const filteredLinks = getDropdownLinks();
    const dropdownLinks = filteredLinks.map((link) => {
      if (link.get('key') === 'feedback') {
        return (
          <Anchor
            key={link.get('key')}
            className='global-nav__dropdown-link'
            as='button'
            onClick={this.handleToggle}
          >
            Rate Albert
          </Anchor>
        );
      }
      if (link.has('route')) {
        return link.get('hardLink', false) ? (
          <a key={link.get('key')} href={link.get('route')} className='global-nav__dropdown-link'>
            {link.get('name')}
          </a>
        ) : (
          <Link
            key={link.get('key')}
            to={link.get('route')}
            className='global-nav__dropdown-link'
            onClick={(e) => {
              e.stopPropagation();
              callTargetedAction({
                name: drawerActions.DRAWER_SET_DRAWER_VISIBILITY,
                payload: false,
                targetStore: 'DropdownLinksDrawer'
              });
            }}
          >
            {link.get('name')}
          </Link>
        );
      }
      return (
        <div
          role='button'
          aria-label={link.get('name')}
          tabIndex={0}
          key={link.get('key')}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              link.get('action')();
            }
          }}
          onClick={link.get('action')}
          className='global-nav__dropdown-link'
        >
          {link.get('name')}
        </div>
      );
    });

    return dropdownLinks;
  }

  render() {
    const user = getBaseUserQuery(sessionStore.getUserId()).getResource();
    const username = user.getUsername();
    const fullName = user.getFullName();
    const firstName = user.getFirstName();
    const isMobileView = global.innerWidth < mobileBreakpoint;
    const isTabletView = global.innerWidth < tabletBreakpoint;
    const userAvatarContent = <UserAvatar username={username} fullName={fullName} />;
    const usernameContent = !isTabletView ? (
      <div className='global-nav__username'>{username}</div>
    ) : null;
    return (
      <li>
        <NavItem
          as='div'
          className='global-nav__dropdown-controller'
          role='button'
          tabIndex={0}
          onClick={this.handleDrawerToggle}
          aria-haspopup
          aria-expanded={this.getDrawerStore()?.isOpen() ?? false}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              this.handleDrawerToggle();
            }
          }}
        >
          {userAvatarContent}
          {usernameContent}
        </NavItem>
        <Drawer
          storeName='DropdownLinksDrawer'
          drawerTitle='My Albert'
          drawerContentClassName='global-nav__dropdown-drawer'
          drawerFooterContents={drawerFooterContents}
        >
          <div className='global-nav__dropdown'>
            <div className='global-nav__dropdown-username-wrapper'>
              <Link
                className='global-nav__dropdown-username'
                to='/account/profile'
                onClick={(e) => {
                  e.stopPropagation();
                  callTargetedAction({
                    name: drawerActions.DRAWER_SET_DRAWER_VISIBILITY,
                    payload: false,
                    targetStore: 'DropdownLinksDrawer'
                  });
                }}
              >
                {userAvatarContent}
                <div className='global-nav__username'>{username}</div>
                {!user.isStudent() && (
                  <span className='u-mar-l_auto'>{user.getPointsEarned()} pts</span>
                )}
              </Link>
            </div>
            {isMobileView && sessionStore.isStudent() ? (
              <div className='u-pad-l_3 u-pad-tb_2'>
                <PointsIndicator />
              </div>
            ) : null}
            {this.generateDropdownLinks()}
            {this.state.showModal && (
              <Modal
                className='customer-feedback-modal'
                ariaLabel='Customer Feedback Modal'
                handleClose={this.handleToggle}
                longModal
              >
                {() => (
                  <CustomerFeedbackDialogue
                    name={firstName}
                    handleClose={this.handleToggle}
                    inModal
                  />
                )}
              </Modal>
            )}
          </div>
        </Drawer>
      </li>
    );
  }
}
