import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import {Heading, Text, Button, Anchor} from '@albert-io/atomic';

// eslint-disable-next-line import/no-webpack-loader-syntax
import cactusOfEmptiness from '!html-loader!./desert_cactus.svg';

import './empty-state-cta.scss';

/**
 * Empty state component for teacher classrooms
 *
 * @example
 * <EmptyStateCta>
 *   <EmptyStateCta.Heading>
 *     No overview data
 *   </EmptyStateCta.Heading>
 *   <EmptyStateCta.Text>
 *     View all assignment, assessment and independent practice data by standard.
 *     To get started, create an assignment or open subjects for independent practice.
 *   </EmptyStateCta.Text>
 *   <EmptyStateCta.Button to='/foo/bar'>
 *     Create an assignment
 *   </EmptyStateCta.Button>
 *   <EmptyStateCta.Anchor to='/baz/qux'>
 *     Or learn more about independent practice
 *   </EmptyStateCta.Anchor>
 * </EmptyStateCta>
 * @param {object} props Props
 * @param {Array<React.ElementType>} props.children Props
 * @returns {React.ElementType} Element
 */
export default function EmptyStateCta({children}) {
  return (
    <div className='teacher-classroom-empty-state-cta u-display_flex u-flex-direction_column'>
      <div
        /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={{__html: cactusOfEmptiness}}
        className='u-mar-b_6 teacher-classroom-empty-state-cta__cactus'
      />
      {children}
    </div>
  );
}

EmptyStateCta.propTypes = {
  children: PropTypes.elementType
};

EmptyStateCta.Heading = (props) => <Heading {...props} size='m' className='u-mar-b_2' />;

EmptyStateCta.Text = (props) => (
  <Text {...props} paragraph size='m' color='secondary' className='u-mar-b_5' />
);

EmptyStateCta.Button = (props) => <Button {...props} as={Link} className='u-mar-b_2' />;

EmptyStateCta.Anchor = (props) => <Anchor {...props} target='_blank' rel='noopener' />;

EmptyStateCta.SubText = (props) => (
  <Text {...props} paragraph bold size='s' color='tertiary' className='u-mar-b_8' />
);
