import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Text,
  IconButton,
  Icon,
  ListGroupItem,
  Dropdown,
  DropdownItem
} from '@albert-io/atomic';

import {directions, sorts, setSortValue, makeOrderedSort, getCurrentSort} from './sort.utils';

export default class Sort extends React.Component {
  static propTypes = {
    active: PropTypes.bool,
    triggerButtonText: PropTypes.oneOf(['Sort', 'Order by']),
    icon: PropTypes.string,
    iconPosition: PropTypes.oneOf(['left', 'right']),
    additionalDropdownItems: PropTypes.node,
    disabled: PropTypes.bool,
    /* An array of what sort labels should be ignored aka not appear in dropdown */
    ignoredActions: PropTypes.arrayOf(PropTypes.string)
  };

  static defaultProps = {
    triggerButtonText: 'Sort',
    icon: 'sort-amount-up',
    iconPosition: 'left',
    disabled: false,
    ignoredActions: []
  };

  wrapperRef = React.createRef();

  toggleRef = React.createRef();

  getSortsList() {
    const currentSort = getCurrentSort();
    return Object.entries(sorts)
      .map(([label, option], i, arr) => {
        if (this.props.ignoredActions.includes(label)) {
          return null;
        }
        const isActive = option.isActive();
        const isOrdered = option.ordered;
        return (
          <ListGroupItem
            key={i}
            header={i === 0}
            footer={
              !this.props.additionalDropdownItems &&
              arr.length - 1 - this.props.ignoredActions.length === i
            }
            as={isOrdered ? 'div' : DropdownItem}
            className='questions-list-header__sort-item'
            onClick={this.handleUnorderedClick}
            data-label={label}
            data-testid={`practice-view-toolbar-sort__${label}-btn`}
          >
            <Text
              bold
              color={isActive ? 'brand' : 'secondary'}
              className='questions-list-header__sort-label u-mar-r_2'
            >
              {label}
            </Text>
            {isOrdered && (
              <IconButton
                label={`Sort by ${label} descending`}
                icon='arrow-down'
                size='s'
                color={
                  currentSort === makeOrderedSort(label, directions.desc) ? 'primary' : 'secondary'
                }
                onClick={this.handleOrderedClick}
                data-direction={directions.desc}
                data-label={label}
                data-testid='practice-view-toolbar-sort__desc-btn'
              />
            )}
            {isOrdered && (
              <IconButton
                label={`Sort by ${label} ascending`}
                icon='arrow-up'
                size='s'
                className='u-mar-l_1'
                color={
                  currentSort === makeOrderedSort(label, directions.asc) ? 'primary' : 'secondary'
                }
                onClick={this.handleOrderedClick}
                data-direction={directions.asc}
                data-label={label}
                data-testid='practice-view-toolbar-sort__asc-btn'
              />
            )}
          </ListGroupItem>
        );
      })
      .flat();
  }

  handleUnorderedClick = (e) => {
    e.stopPropagation();
    const {label} = e.currentTarget.dataset;
    let seed = Math.random();
    // Extremely unlikely, but Math.random() can return 0, and our seed must be higher than 0.
    while (seed === 0) {
      seed = Math.random();
    }
    // To avoid putting a long number in the URL, we're gonna cap this at 9999
    seed = Math.trunc(seed * 10000);
    setSortValue(label, seed);
  };

  handleOrderedClick = (e) => {
    e.stopPropagation();
    const {label, direction} = e.currentTarget.dataset;
    setSortValue(label, direction);
  };

  render() {
    const {iconPosition, active, icon, disabled, triggerButtonText} = this.props;
    return (
      <Dropdown
        trigger={
          <Button
            size='s'
            color={active ? 'primary' : 'secondary'}
            variant='text'
            disabled={disabled}
            active={active}
            label='Toggle question set sort menu'
            className='u-mar-r_1'
            data-testid='practice-view-toolbar-sort__dropdown-trigger'
          >
            {iconPosition === 'left' ? <Icon icon={icon} className='u-mar-r_1' /> : null}
            {triggerButtonText}
            {iconPosition === 'right' ? <Icon icon={icon} className='u-mar-l_1' /> : null}
          </Button>
        }
      >
        {this.getSortsList()}
        {this.props.additionalDropdownItems}
      </Dropdown>
    );
  }
}
