import React from 'react';
import PropTypes from 'prop-types';
import {List} from 'immutable';
import classnames from 'classnames';
import {callTargetedAction} from 'client/framework';
import QuestionSetAlignmentActions from './QuestionSetAlignment.actions';
import QuestionSetAlignmentStore from './QuestionSetAlignment.store';
import Button from 'sg/Button/Button.react';

export default class PracticeExamAlignmentList extends React.Component {
  static propTypes = {
    exams: PropTypes.instanceOf(List),
    store: PropTypes.instanceOf(QuestionSetAlignmentStore),
    questionSetSectionId: PropTypes.string,
    questionSetExamId: PropTypes.string
  };

  state = {
    expandedExams:
      this.props.store.hasPendingAlignment() || !this.props.questionSetExamId
        ? {}
        : {
            [this.props.questionSetExamId]: true
          }
  };

  toggleExpandExam = (e) => {
    const id = e.currentTarget.dataset.examId;
    const currentValue = this.state.expandedExams[id];
    this.setState({
      expandedExams: {
        ...this.state.expandedExams,
        [id]: !currentValue
      }
    });
  };

  setExamAlignment = (e) => {
    callTargetedAction({
      name: QuestionSetAlignmentActions.SET_PENDING_EXAM_ALIGNMENT,
      targetStore: this.props.store.getName(),
      payload: {
        examId: e.currentTarget.dataset.examId,
        sectionId: e.currentTarget.dataset.sectionId
      }
    });
  };

  renderExams() {
    return this.props.exams.reduce((acc, exam) => {
      const examId = exam.getId();
      const isExpanded = this.state.expandedExams[examId];
      const examEl = (
        <li key={exam.getId()} className='guide-level-alignment-list__item'>
          <Button
            textButton
            onClick={this.toggleExpandExam}
            data-exam-id={examId}
            disabled={exam.isPublished()}
            disabledTooltipContent='Questions cannot be aligned to assessments that have been published.'
          >
            <span className={`fa fa-fw fa-caret-${isExpanded ? 'down' : 'right'}`} />
            {exam.getName()} {exam.isPublished() ? ' (Published)' : null}
          </Button>
        </li>
      );
      if (!isExpanded) {
        return acc.push(examEl);
      }
      const sections = exam.getSortedSections().reduce((acc, section) => {
        const sectionId = section.getId();
        const isSelectedSection = this.props.store.hasPendingAlignment()
          ? this.props.store.getPendingSectionId() === sectionId
          : this.props.questionSetSectionId === sectionId;

        const sectionEl = (
          <li
            key={sectionId}
            className='guide-level-alignment-list__item guide-level-alignment-list__item--exam-section'
          >
            <Button
              textButton
              onClick={this.setExamAlignment}
              aria-label={`Align to ${section.getTitle()}`}
              data-section-id={sectionId}
              data-exam-id={examId}
            >
              <span
                className={classnames('unbutton guide-level-alignment-list__set-alignment-button', {
                  'guide-level-alignment-list__set-alignment-button--active': isSelectedSection
                })}
              />{' '}
              {section.getTitle()}
            </Button>
          </li>
        );
        return acc.push(sectionEl);
      }, List());
      return acc.push(examEl, ...sections);
    }, List());
  }

  render() {
    return <ul className='guide-level-alignment-list'>{this.renderExams()}</ul>;
  }
}
