// @flow
import {Map} from 'immutable';
import qs from 'qs';
import {getStoreByName} from 'client/framework';
import appStore from 'client/AppStore';
// XXX Definitely do something about this part. Probably move these bindings to
// constants or to questionTypes.
import FillInTheBlankQuestionStore from 'client/QuestionTypes/FillInTheBlank/V2/FillInTheBlankQuestion.store';
import MultipleChoiceQuestionStore from 'client/QuestionTypes/MultipleChoice/V2/MultipleChoiceQuestion.store';
import SnippetSelectionQuestionStore from 'client/QuestionTypes/SnippetSelection/SnippetSelectionQuestion.store';
import FreeResponseQuestionStore from 'client/QuestionTypes/FreeResponse/FreeResponseQuestion.store';
import FreeEntryQuestionStore from 'client/QuestionTypes/FreeEntry/V2/FreeEntryQuestion.store';
import TwoWayQuestionStore from 'client/QuestionTypes/TwoWay/V2/TwoWayQuestion.store';
import TextHighlightQuestionStore from 'client/QuestionTypes/TextHighlight/V2/TextHighlightQuestion.store';
import PassageCorrectionQuestionStore from 'client/QuestionTypes/PassageCorrection/PassageCorrectionQuestion/PassageCorrectionQuestion.store';
import vanillaIEQuestionsListStore from './IESessionTypes/VanillaIE/VanillaIEQuestionsList/VanillaIEQuestionsList.store';
import {CONTAINER_CLASS as COPY_CONTAINER_CLASS} from 'generic/Clipboard/CopyToClipboard.react';
import type {QuestionSet as QuestionSetModelV1} from 'resources/GeneratedModels/QuestionSet/QuestionSetModel.v1';

const typeToStore = {
  'fill-in-the-blank': FillInTheBlankQuestionStore,
  'free-entry': FreeEntryQuestionStore,
  'free-response': FreeResponseQuestionStore,
  'multiple-choice': MultipleChoiceQuestionStore,
  'passage-correction': PassageCorrectionQuestionStore,
  'snippet-selection': SnippetSelectionQuestionStore,
  'text-highlight': TextHighlightQuestionStore,
  'two-way': TwoWayQuestionStore
};

/**
 * @todo: buildStores makes it difficult to make updates to individual question stores, since they're
 * all initialized in the same way. It should not be necessary to iterate through all questions and create
 * stores for them up-front, as we could instead create the stores as the questions are loaded.
 */

// XXX OK, so this may seem insane, but trust me, it's clever.
// This allows us to keep the guess content in all the stores, saving the
// student's response to each question and the full state of each question view
// and submit all answers in bulk at the end of the assignment, as per the spec
// It should incur minimal storage and performance overhead, as we can destroy
// the stores when the IE session unmounts, and we needed to keep track of this
// data anyway. The thing I don't like the most here is that the stores are
// initialized outside of the question type component, and this file cares about
// all the different question type stores. But they do need to be instantiated
// in one centralized place, at the start of each session, otherwise we will
// not be able to pre-populate their responses in the full IE port. That could
// maybe wait, but this solution addresses all the issues.
export function buildStores(
  questionSets,
  storePostfix: string = '',
  shouldOverwrite: boolean = false
): Map<string, *> {
  let questionStoreNames = new Map();
  let builtQuestionSets = new Map();
  questionSets.forEach((questionSet) => {
    // The Vanilla IE is paginated, so we're keeping track of question sets that have been
    // built so that when a new page is loaded, we can get all question sets and filter out
    // any question sets that are included in the Map below. Using Map with values of true for
    // performance reasons.
    builtQuestionSets = builtQuestionSets.set(questionSet.get('id'), true);

    questionSet.getQuestions().forEach((question) => {
      const questionType = question.getType();
      const questionId = question.getId();
      const storeName = questionType + questionId + 'QuestionStore' + storePostfix;
      const TypeStore = typeToStore[question.getType()];

      if (shouldOverwrite) {
        if (!getStoreByName(storeName)) {
          new TypeStore(storeName, questionId, question);
        }
        questionStoreNames = questionStoreNames.set(questionId, storeName);
      } else if (!getStoreByName(storeName)) {
        new TypeStore(storeName, questionId, question);
        questionStoreNames = questionStoreNames.set(questionId, storeName);
      }
    });
  });
  return new Map({questionStoreNames, builtQuestionSets});
}

export function convertSetAndQuestionToPath(
  set: QuestionSetModelV1,
  question: Map<*, *>,
  questionSetIndex: number
): string {
  const parts = ['learn', appStore.routerProps.params.subject];

  if (appStore.routerProps.params.guideLevelSlug) {
    parts.push(appStore.routerProps.params.guideLevelSlug);
  } else {
    parts.push('question');
  }

  parts.push(set.getURLSlugPart());

  if (set.getQuestions().count() > 1 && question.getTitleSlug()) {
    parts.push(question.getTitleSlug());
  }

  let pageNumber = null;
  if (!vanillaIEQuestionsListStore.isSingleSetMode()) {
    const lowestPageFetched = vanillaIEQuestionsListStore.getLowestPageFetched();
    const pageSize = vanillaIEQuestionsListStore.getPageSize();
    pageNumber = Math.floor(questionSetIndex / pageSize) + lowestPageFetched;
  }

  // Add page number to our query params, if we have one
  const query = {
    ...appStore.routerProps.location.query,
    ...(pageNumber && {page: pageNumber})
  };

  const queryString = qs.stringify(query, {addQueryPrefix: true});
  return `/${parts.join('/') + queryString}`;
}

function preventDefault(e) {
  /**
   * Allows our <CopyToClipboard /> component to work as expected in the IE.
   */
  const isClipboardComponent =
    e.target.parentElement &&
    e.target.parentElement.classList &&
    e.target.parentElement.classList.contains(COPY_CONTAINER_CLASS);
  if (isClipboardComponent) {
    return;
  }
  if (process.env.NODE_ENV !== 'production') {
    console.warn('[InteractionEngine.util.js] preventDefault:', e);
  }
  e.preventDefault();
}

export function preventContextMenuAndCopy() {
  document.addEventListener('contextmenu', preventDefault);
  document.addEventListener('copy', preventDefault);
}

export function allowContextMenuAndCopy() {
  document.removeEventListener('contextmenu', preventDefault);
  document.removeEventListener('copy', preventDefault);
}
