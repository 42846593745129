import React, {useState} from 'react';

import QuestionTrackerModal from 'client/components/PracticeView/QuestionTrackerModal/QuestionTrackerModal.react';
import TrackedButton from 'client/components/Tracking/TrackedButton';

interface Props {
  questionSetId: string;
  userId: string;
}

export default function MoreInfo({questionSetId, userId}: Props) {
  const [showModal, setShowModal] = useState(false);

  function toggleModal() {
    setShowModal((value) => !value);
  }

  return (
    <>
      <TrackedButton
        type='button'
        onClick={() => {
          toggleModal();
        }}
        data-testid='practice-view__usage-btn'
      >
        My usage
      </TrackedButton>
      {showModal && (
        <QuestionTrackerModal
          handleClose={() => {
            toggleModal();
          }}
          questionSetId={questionSetId}
          userId={userId}
        />
      )}
    </>
  );
}
