import makeConstants from 'lib/makeConstants';

export const ieTypes = makeConstants(
  'ASSIGNMENT_TEMPLATE',
  'PRACTICE_EXAM_EDITOR',
  'PRACTICE_EXAM',
  'ASSIGNMENT',
  'ASSIGNMENT_V2',
  'TEACHER_ASSIGNMENT_V2',
  // QUEUE_RESULTS refers to the QueueResults.react component, which
  // is a QuestionsList used by the Dennis and Authoring views
  'QUEUE_RESULTS'
);

export const SUBHEADER_CLASS = 'interaction-engine-v2__subheader';
