import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import {callAction} from 'client/framework';
import PureComponent from 'lib/purecomponent';
import {renderMoment, renderDuration} from 'lib/timeUtils';
import {mandarkEndpoint} from '@albert-io/json-api-framework/request/builder/legacy';
import sessionStore from 'client/Session/SessionStore';
import systemTimeOffsetStore from 'client/generic/SystemTimeOffset/SystemTimeOffset.store';
import studentAssignmentQuestionsListActions from 'client/InteractionEngineV2/IESessionTypes/Assignments/StudentAssignmentIE/StudentAssignmentQuestionsList/StudentAssignmentQuestionsList.actions';
import IconTooltip from 'sg/IconTooltip/IconTooltip.react';
import Button from 'sg/Button/Button.react';
import Markdown from 'components/Markdown/Markdown.react';

import {StudentAssignment as StudentAssignmentModelV1} from 'resources/GeneratedModels/StudentAssignment/StudentAssignmentModel.v1';

import './assignment-stage.scss';

export default class AssignmentStage extends React.Component {
  static propTypes = {
    assignmentId: PropTypes.string
  };

  isReady() {
    return this.getAssignmentQuery().isResourcePopulated();
  }

  /**
   * @todo This will throw a 403 (previously a 404) when a student requests
   * an assignment that is scheudled in the future. For now, this still results
   * in our generic error, but we could probably clean up the UX in the new IE.
   * @see https://github.com/albert-io/project-management/issues/1843
   */
  getAssignmentQuery() {
    return mandarkEndpoint(['student_assignments_v1', this.props.assignmentId])
      .include('classrooms_v1')
      .include('teacher_v1')
      .include('students_v2')
      .filter({
        included: {
          students_v2: {
            id: sessionStore.getUserId()
          },
          classrooms_v1: {
            students_v2: {
              id: sessionStore.getUserId()
            }
          }
        }
      })
      .meta({
        context: {
          student: {
            id: sessionStore.getUserId()
          }
        }
      })
      .customQuery({
        with_meta: 'student_assignment_v1'
      });
  }

  getAssignment() {
    return this.getAssignmentQuery().getResource();
  }

  generateSubmissionLabel() {
    const assignment = this.getAssignment();
    const student = assignment.getStudents().first();
    const submittedTimestamp = student
      .getStudentAssignmentRelationships(assignment.getId())
      .getSubmitted();
    const currentTime = systemTimeOffsetStore.getCurrentTime();
    const isStudentSubmitted = assignment.getMeta().isStudentSubmitted();
    const isLate = assignment.getMeta().isStudentLate();
    const isStudentOutOfTime = assignment.isStudentOutOfTime(sessionStore.getUserId(), currentTime);

    let submissionLabel = 'Not submitted';

    if (isStudentSubmitted) {
      submissionLabel = `Submitted ${renderMoment(moment(submittedTimestamp))}`;
      if (isLate) {
        submissionLabel += ' – late';
      }
    }

    if (!isStudentSubmitted && isLate) {
      submissionLabel = 'Not submitted - past due';
    }

    if (!isStudentSubmitted && isStudentOutOfTime) {
      submissionLabel = 'Not submitted - out of time';
    }

    const labelClasses = classnames('assignment-stage-card__submission-status', {
      'assignment-stage-card__submission-status--green': isStudentSubmitted && !isLate,
      'assignment-stage-card__submission-status--red': isLate,
      'assignment-stage-card__submission-status--orange': !isStudentSubmitted
    });

    return <div className={labelClasses}>{submissionLabel}</div>;
  }

  generateButton() {
    const assignment = this.getAssignment();
    const currentTime = systemTimeOffsetStore.getCurrentTime();

    if (
      assignment.isStudentFinished(sessionStore.getUserId(), currentTime) &&
      !assignment.shouldShowGuessFeedback()
    ) {
      return null;
    }

    let buttonContent = 'View questions';
    let handleClick = this.handleViewQuestions;

    const isComplete = assignment.isComplete();
    const isPastDue = !assignment.isAllowLateSubmissions() && assignment.isPastDue();
    const isSubmitted = assignment.getMeta().isStudentSubmitted();
    const isStudentOutOfTime = assignment.isStudentOutOfTime(sessionStore.getUserId(), currentTime);

    /**
     * start_time is a field on the student's relationship to the student_assignment.
     * We're expecting this.getAssignment to include students_v* with metadata request for the signed in user.
     */
    const hasStartedAssignment = assignment.hasStudentStartedAssignment(sessionStore.getUserId());
    const inProgress = hasStartedAssignment && !isComplete && !isPastDue && !isSubmitted;

    if (!hasStartedAssignment && !isPastDue && !isSubmitted) {
      buttonContent = 'Get started!';
      handleClick = this.handleStartAndContinueAssignment;
    }

    if (inProgress && isStudentOutOfTime === false) {
      buttonContent = 'Continue';
      handleClick = this.handleStartAndContinueAssignment;
    }

    return (
      <Button color='green' large onClick={handleClick}>
        {buttonContent}
      </Button>
    );
  }

  handleStartAndContinueAssignment = () => {
    const assignment = this.getAssignment();
    /**
     * If they haven't started, set the start_time.
     *
     * start_time is a field on the student's relationship to the student_assignment.
     * We're expecting this.getAssignment to include students_v* with metadata request for the signed in user.
     */
    const shouldSetStartTime =
      assignment.hasStudentStartedAssignment(sessionStore.getUserId()) === false;
    callAction(studentAssignmentQuestionsListActions.START_ASSIGNMENT, shouldSetStartTime);
    callAction(studentAssignmentQuestionsListActions.SELECT_FIRST_QUESTION_WITHOUT_GUESS);
  };

  handleViewQuestions = () => {
    callAction(studentAssignmentQuestionsListActions.SELECT_FIRST_QUESTION);
  };

  render() {
    if (this.isReady() === false) {
      return null;
    }
    const assignment = this.getAssignment();
    const currentTime = systemTimeOffsetStore.getCurrentTime();
    const classroomName = assignment.getClassrooms().first().getName();
    const teacherName = assignment.getTeacher().getDisplayName('s l');
    const assignmentSummary =
      assignment.isStudentFinished(sessionStore.getUserId(), currentTime) &&
      assignment.shouldShowGuessFeedback() ? (
        <AssignmentSummary assignment={assignment} />
      ) : null;

    return (
      <div className='assignment-stage'>
        <div className='assignment-stage__main-content'>
          <div className='assignment-stage__image-wrapper card card--no-padding' />
          <div className='card assignment-stage-card'>
            <div className='assignment-stage-card__header'>
              <h1>{assignment.getName()}</h1>
              {this.generateSubmissionLabel()}
            </div>
            <div className='assignment-stage-card__subheader'>
              {classroomName} with {teacherName}
            </div>
            {assignmentSummary}
            <AssignmentDetails assignment={assignment} />
          </div>
        </div>
        <div className='assignment-stage__footer-content'>{this.generateButton()}</div>
      </div>
    );
  }
}

class AssignmentSummary extends PureComponent {
  static propTypes = {
    assignment: PropTypes.instanceOf(StudentAssignmentModelV1)
  };

  getGrade(assignment) {
    return (
      <div className='assignment-stage__summary-card card'>
        <h4>Grade:</h4>
        <span className='assignment-stage__summary-content'>{assignment.getGrade()}%</span>
        <span className='assignment-stage__summary-footer'>
          {assignment.getMeta().getStudentCountOfCorrectGuesses()}/
          {assignment.getMeta().getStudentCountOfGuesses()} correct answers
        </span>
      </div>
    );
  }

  getTime(assignment) {
    if (!assignment.getTimeLimit()) {
      return null;
    }

    const currentTime = systemTimeOffsetStore.getCurrentTime();
    const timeSpent = renderDuration(
      assignment.getTimeSpent(sessionStore.getUserId(), currentTime),
      {
        style: 'minutes',
        max: assignment.getTimeLimit()
      }
    );

    return (
      <div className='assignment-stage__summary-card card'>
        <h4>Time Spent:</h4>
        <span className='assignment-stage__summary-content'>{timeSpent}</span>
        <span className='assignment-stage__summary-footer'>
          out of {assignment.getTimeLimit()} min
        </span>
      </div>
    );
  }

  render() {
    const {assignment} = this.props;
    return (
      <div className='assignment-stage__summary-wrapper'>
        <div className='assignment-stage__summary'>
          {this.getGrade(assignment)}
          {this.getTime(assignment)}
        </div>
        <hr />
      </div>
    );
  }
}

class AssignmentDetails extends React.Component {
  static propTypes = {
    assignment: PropTypes.instanceOf(StudentAssignmentModelV1)
  };

  generateTimeLimitBlurb(assignment) {
    if (!assignment.getTimeLimit()) {
      return (
        <div className='assignment-stage__time'>
          <span className='assignment-stage__detail-content'>No limit</span>
        </div>
      );
    }
    return (
      <div className='assignment-stage__time'>
        <span className='assignment-stage__detail-content'>{assignment.getTimeLimit()} min</span>
        <span className='assignment-stage__detail-footer'>
          Continuous timer
          <IconTooltip
            className='assignment-stage__time-tooltip'
            color='light'
            content='The timer will continue to count down even if you sign out, close this tab, or hit the back button.'
            position='top'
            icon='fa-info-circle'
          />
        </span>
      </div>
    );
  }

  render() {
    const {assignment} = this.props;
    const messageContent = assignment.getMessage() ? (
      <div className='assignment-stage__message card'>
        <Markdown.TeacherRules text={assignment.getMessage()} />
      </div>
    ) : null;

    return (
      <div className='assignment-stage__details-wrapper'>
        <div className='assignment-stage__details'>
          <div className='assignment-stage__detail-card card'>
            <h4>Due:</h4>
            <span className='assignment-stage__detail-content'>
              {renderMoment(assignment.getDueDate(), 'dateFormalShort')}
            </span>
            <span className='assignment-stage__detail-footer'>
              @ {renderMoment(assignment.getDueDate(), 'time')}
            </span>
          </div>
          <div className='assignment-stage__detail-card card'>
            <h4>Content:</h4>
            <span className='assignment-stage__detail-content'>
              {assignment.getCountOfQuestions()}
            </span>
            <span className='assignment-stage__detail-footer'>Questions</span>
          </div>
          <div className='assignment-stage__detail-card card'>
            <h4>Time Limit:</h4>
            {this.generateTimeLimitBlurb(assignment)}
          </div>
        </div>
        <div className='assignment-stage__explanation-setting'>
          {assignment.getCorrectAnswerSettingDescriptionForStudent()}
        </div>
        {messageContent}
      </div>
    );
  }
}
