/**
 * # Chip
 *
 * A customizable chip component.
 *
 * ## Props
 *
 * - `as` (optional): The HTML element or React component to render as the wrapper element. Defaults to `'span'`.
 * - `checked` (optional): Specifies whether the chip is checked. Defaults to `false`.
 * - `children` (optional): The content of the chip.
 * - `className` (optional): Additional CSS class names for the chip.
 * - `color`: The color variant of the chip. Must be one of ChipColor (`'neutral'`, `'brand'`, or `'negative'`).
 * - `size`: The size variant of the chip. Must be one of `'small'` or `'default'`.
 * - `disabled` (optional): Specifies whether the chip is disabled. Defaults to `false`.
 * - `hasFocus` (optional): Specifies whether the chip has focus. Defaults to `false`.
 * - `hasHover` (optional): Specifies whether the chip is being hovered. Defaults to `false`.
 * - `rounded` (optional): Specifies whether the chip is rounded. Defaults to `false`.
 *
 * ## Usage
 *
 * ```tsx
 * import Chip from './Chip.react';
 *
 * function App() {
 *   return (
 *     <Chip color="brand" size="default">
 *       Hello, World!
 *     </Chip>
 *   );
 * }
 * ```
 */

import React, {ElementType, forwardRef} from 'react';
import classnames from 'classnames';

import './chip.scss';

const CHIP_COLORS = ['neutral', 'brand', 'negative', 'positive', 'warning'];
export type ChipColor = (typeof CHIP_COLORS)[number];

interface Props<C extends ElementType>
  extends PropsWithChildrenOptional,
    PropsWithClassNameOptional {
  as?: C;
  checked?: boolean;
  color: ChipColor;
  size: 'small' | 'default';
  disabled?: boolean;
  hasFocus?: boolean;
  hasHover?: boolean;
  rounded?: boolean;
}

type ChipProps<C extends React.ElementType> = Props<C> &
  Omit<React.ComponentPropsWithRef<C>, keyof Props<C>>;

function Chip<C extends React.ElementType = 'span'>(
  {
    as,
    checked,
    children,
    className,
    disabled,
    color = 'neutral',
    size = 'default',
    rounded,
    hasFocus,
    hasHover,
    role,
    ...rest
  }: ChipProps<C>,
  ref
) {
  const WrapperComponent = as || 'span';
  return (
    <WrapperComponent
      className={classnames('a-chip', className, {
        [`a-chip--${color}`]: color,
        [`a-chip--${size}`]: size,
        'a-chip--disabled': disabled,
        'a-chip--focus': hasFocus,
        'a-chip--hover': hasHover,
        'a-chip--selected': checked,
        'a-chip--rounded': rounded
      })}
      checked={checked}
      disabled={disabled}
      role={role}
      ref={ref}
      {...rest}
    >
      {children}
    </WrapperComponent>
  );
}

export default forwardRef(Chip);
