// @flow
import notifier from '@albert-io/notifier';
import {fromJS, List, Map} from 'immutable';
import moment from 'moment';
import sessionStore from 'client/Session/SessionStore';
import systemTimeOffsetStore from 'client/generic/SystemTimeOffset/SystemTimeOffset.store';
import {StudentModel} from 'resources/Student/Student.model';
import {invalidateModel} from 'resources/mandark.resource';
import {resource} from '@albert-io/json-api-framework/request/builder';
import type {QueryBuilder} from '@albert-io/json-api-framework/request/builder/legacy';
import {mandarkEndpoint} from '@albert-io/json-api-framework/request/builder/legacy';
import type {StudentAssignmentModelV1} from 'resources/GeneratedModels/StudentAssignment/StudentAssignmentModel.v1';
import type {QuestionSetModelV1} from 'resources/GeneratedModels/QuestionSet/QuestionSetModel.v1';

import AssignmentQuestionsListStore from '../../AssignmentQuestionsList/AssignmentQuestionsList.store';

import studentAssignmentQuestionsListActions from './StudentAssignmentQuestionsList.actions';

/**
 * Extends the AssignmentQuestionsListStore and adds
 * methods relevant to student assignment IE sessions
 *
 * @class StudentAssignmentQuestionsList
 * @extends {AssignmentQuestionsListStore}
 */
class StudentAssignmentQuestionsList extends AssignmentQuestionsListStore {
  constructor(name) {
    super(name);
    this.initialData = this.initialData.merge(
      fromJS({
        filterByUnansweredQuestions: false,
        filteredQuestionSets: [],
        assignmentStartTime: null
      })
    );
    this.setInitialData(this.initialData);
    this.handle(
      studentAssignmentQuestionsListActions.FILTER_BY_UNANSWERED_QUESTIONS,
      this._filterByUnansweredQuestions
    );
    this.handle(studentAssignmentQuestionsListActions.RESET_STORE, this._resetStore);
    this.handle(studentAssignmentQuestionsListActions.START_ASSIGNMENT, this._startAssignment);
    this.handle(
      studentAssignmentQuestionsListActions.SELECT_FIRST_QUESTION_WITHOUT_GUESS,
      this._selectFirstQuestionWithoutGuess
    );
    this.handle(
      studentAssignmentQuestionsListActions.SELECT_FIRST_QUESTION,
      this._selectFirstQuestion
    );
  }

  _resetStore() {
    this.writeData(this.initialData);
  }

  _setAssignmentStartTime(startTime) {
    this.writeData('assignmentStartTime', startTime);
  }

  async _startAssignment(shouldSetStartTime = true): Promise<*> {
    if (shouldSetStartTime) {
      const {assignmentId} = this;
      await this.getStudentAssignmentRelationship()
        .addRelationship({
          type: 'assignment_v2',
          relation: [assignmentId],
          customMeta: Map({
            [assignmentId]: {
              start_time: 'now'
            }
          })
        })
        .save();
      /**
       * Invalidate student_assignments_v* models.
       */
      invalidateModel(['student_assignments_v1', assignmentId]);
      const assignment = await this.getAssignmentQuery().getResourcePromise();
      /**
       * Set the local start time based on the actual result from the server.
       */
      this._setAssignmentStartTime(assignment.getStudentStartTime(sessionStore.getUserId()));
    }
  }

  _selectFirstQuestionWithoutGuess() {
    const result: Map<{
      question: *,
      set: *
    }> = this.getAssignment().getFirstSetAndQuestionWithoutGuess(this.questionSets);
    if (!result) {
      this._selectFirstQuestion();
      return;
    }

    this._setActiveQuestion(result.get('question'));
    this._setActiveQuestionSet(result.get('set'));
  }

  _selectFirstQuestion() {
    const result: Map<{
      question: *,
      set: *
    }> = this.getAssignment().getFirstSetAndQuestion(this.questionSets);
    this._setActiveQuestion(result.get('question'));
    this._setActiveQuestionSet(result.get('set'));
  }

  isRandomized(): boolean {
    return this.getAssignment().isRandomizeQuestionOrder();
  }

  getQuestionSetsQuery(): QueryBuilder {
    if (!this.assignmentId) {
      const error = new Error('There was an error requesting your assignment.');
      notifier.notify(error, {
        component: 'StudentAssignmentQuestionsList.store',
        name: 'Empty Assignment ID'
      });
      throw error;
    }

    /**
     * When assembling the random seed, we combine the student ID with the
     * assignment ID in case the teacher creates an assignment from the same
     * template multiple times in order to randomize across that as well.
     */
    const studentId = sessionStore.getUserId();
    const hash = studentId.split('-')[0] + this.assignmentId.split('-')[0];
    const randomSeed = this.isRandomized() ? `0.${parseInt(hash, 16)}` : null;

    const query = resource('question_sets_v1')
      .mandarkEndpoint(['question_sets_v1'])
      .include('questions_v1')
      .include('questions_v1.supplements_v1')
      .include('questions_v1.labels_v1')
      .include('tags_v1')
      .include('subject_v2')
      .include('student_assignments_v1.students_v2')
      .include('student_assignments_v1.classrooms_v1.students_v2')
      .filter({
        student_assignments_v1: this.assignmentId,
        included: {
          student_assignments_v1: {
            id: this.assignmentId
          },
          'student_assignments_v1.students_v2': {
            id: studentId
          },
          'student_assignments_v1.classrooms_v1.students_v2': {
            id: studentId
          }
        }
      })
      .pageSize(250)
      .meta({
        context: {
          student: {
            id: studentId
          }
        }
      })
      .withMeta('question_v1,student_assignment_v1')
      .customQuery(
        {
          random_seed: randomSeed
        },
        Boolean(randomSeed)
      );

    return query;
  }

  getAssignmentQuery(): QueryBuilder {
    if (!this.assignmentId) {
      const error = new Error('There was an error requesting your assignment.');
      notifier.notify(error, {
        component: 'StudentAssignmentQuestionsList.store',
        name: 'Empty Assignment ID'
      });
      throw error;
    }
    const studentId = sessionStore.getUserId();
    return mandarkEndpoint(['student_assignments_v1', this.assignmentId])
      .include('question_sets_v1.questions_v1')
      .include('question_sets_v1.tags_v1')
      .include('question_sets_v1.questions_v1.labels_v1')
      .include('question_sets_v1.questions_v1.supplements_v1')
      .include('question_sets_v1.subject_v2')
      .include('classrooms_v1')
      .include('students_v2')
      .filter({
        included: {
          students_v2: {
            id: studentId
          },
          classrooms_v1: {
            students_v2: {
              id: studentId
            }
          }
        }
      })
      .meta({
        context: {
          student: {
            id: studentId
          }
        }
      })
      .customQuery({
        with_meta: 'question_v1,student_assignment_v1'
      });
  }

  getAssignment(): StudentAssignmentModelV1 {
    return this.getAssignmentQuery().getResource();
  }

  getStudentAssignmentRelationshipQuery(): QueryBuilder {
    const studentId = sessionStore.getUserId();
    const {assignmentId} = this;
    return mandarkEndpoint(['students_v1', studentId])
      .include('student_assignments_v1')
      .filter({
        included: {
          student_assignments_v1: {
            id: assignmentId
          }
        }
      })
      .meta({
        context: {
          student: {
            id: studentId
          }
        }
      })
      .customQuery({
        with_meta: 'student_assignment_v1'
      });
  }

  getStudentAssignmentRelationship(): StudentModel {
    return this.getStudentAssignmentRelationshipQuery().getResource();
  }

  _filterByUnansweredQuestions() {
    const {assignmentId} = this;
    const filteredQuestionSets = this.questionSets
      .filter((questionSet) => {
        const hasUnansweredQuestions = questionSet
          .getQuestions()
          .some((question) => !question.hasGuessForAssignment(assignmentId));
        return hasUnansweredQuestions;
      })
      .sortBy((setModel) => {
        return setModel.getStudentAssignmentPosition(assignmentId);
      });

    this.writeData((store) => {
      return store
        .set('filterByUnansweredQuestions', true)
        .set('filteredQuestionSets', filteredQuestionSets);
    });
  }

  shouldFilterByUnansweredQuestions(): boolean {
    return this.readData('filterByUnansweredQuestions');
  }

  get activeQuestion() {
    const activeQuestion = this.readData('activeQuestion');
    if (!activeQuestion) {
      return null;
    }
    const activeQuestionId = activeQuestion.getId();
    return this.activeQuestionSet.getQuestions().find((question) => {
      return question.getId() === activeQuestionId;
    });
  }

  get activeQuestionSet(): QuestionSetModelV1 | null {
    const activeQuestionSet = this.readData('activeQuestionSet');
    if (!activeQuestionSet) {
      return null;
    }
    const activeQuestionSetId = activeQuestionSet.getId();
    const ret = this.questionSets.find((set) => {
      return set.getId() === activeQuestionSetId;
    });

    // XXX This can arise due to a timing condition when flipping to "Show unanswered questions"
    // while having an answered question selected.
    // In that case, there is a render frame where our set list no longer has the question, but
    // it is still referenced in this store. We definitely want to pull from the set list (as
    // it is synced to the cache and we need it to be dynamic to pick up invalidations and
    // refreshes) whenever possible. This is a quick fix to use the "copy" version as a fallback
    // for this rare case. We should do a refactor here to strip away the copy of the question
    // and fix the timing such that first we switch to an unanswered question, _then_ we trim
    // the list (such that there is never a point where we are referencing a question that is
    // no longer in the list). In the meantime, this is the fastest way to resolve this issue.
    if (!ret) {
      return activeQuestionSet;
    }

    return ret;
  }

  get questionSets(): List<QuestionSetModelV1> {
    if (!this.hasReceivedAssignment) {
      return List();
    }

    let sets;
    if (
      !this.getAssignment().isStudentFinished(
        sessionStore.getUserId(),
        systemTimeOffsetStore.getCurrentTime()
      ) &&
      this.isRandomized()
    ) {
      sets = this.getQuestionSetsQuery().getResource();
    } else {
      sets = this.getAssignment()
        .getQuestionSets()
        .sortBy((setModel) => {
          return setModel.getStudentAssignmentPosition(this.assignmentId);
        });
    }

    if (this.shouldFilterByUnansweredQuestions()) {
      sets = this.getFilteredQuestionSets(sets);
    }

    return sets;
  }

  getFilteredQuestionSets(questionSets: List<QuestionSetModelV1>): List<QuestionSetModelV1> {
    const filteredQuestionSets = this.readData('filteredQuestionSets');
    return questionSets.filter((questionSet) => {
      const questionSetId = questionSet.getId();
      return filteredQuestionSets.find(
        (filteredQuestionSet) => filteredQuestionSet.getId() === questionSetId
      );
    });
  }

  get hasReceivedAssignment(): boolean {
    if (!this.assignmentId || !sessionStore.hasValidSession()) {
      return false;
    }

    const hasReceivedAssignment =
      this.getAssignmentQuery().isResourcePopulated() &&
      this.getStudentAssignmentRelationshipQuery().isResourcePopulated();

    /**
     * If this isn't a randomized assignment, we short-circuit
     *  the question set query and proceed with just the assignment.
     */
    const hasReceivedQuestionSets =
      !this.isRandomized() || this.getQuestionSetsQuery().isResourcePopulated();

    return hasReceivedAssignment && hasReceivedQuestionSets;
  }

  /**
   * Get the assignment start time for the current assignment and user.
   * This will try to grab the start_time from the assignment, falling back to the store's
   * local value.
   */
  getAssignmentStartTime(): moment | null {
    if (sessionStore.isTeacher()) {
      return null;
    }
    const startTime = this.getAssignment().getStudentStartTime(sessionStore.getUserId());
    if (startTime) {
      return startTime;
    }
    const localStartTime = this.readData('assignmentStartTime');
    return localStartTime ? moment(localStartTime) : null;
  }

  get correctAnswerSetting(): string {
    return this.hasReceivedAssignment ? this.getAssignment().getCorrectAnswerSetting() : '';
  }

  get assignmentMessage(): string {
    return this.hasReceivedAssignment ? this.getAssignment().getMessage() : '';
  }

  get assignmentDueDate(): moment {
    return this.hasReceivedAssignment ? this.getAssignment().getDueDate() : '';
  }

  get isAssignmentDueDatePast(): boolean {
    return moment(this.assignmentDueDate) < systemTimeOffsetStore.getCurrentTime();
  }
}

export default new StudentAssignmentQuestionsList('StudentAssignmentQuestionsList');
