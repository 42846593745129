import React from 'react';
import {List, Map} from 'immutable';
import SimpleDropdown from 'generic/SimpleDropdown/SimpleDropdown.react';
import {QUESTION_TYPES} from 'client/constants';

import {
  SEARCH_PARAMETERS,
  getURLSearchParameter,
  setURLSearchParameter
} from '../URLSearchParameters';

// eslint-disable-next-line import/prefer-default-export
export class QuestionTypeDropdown extends React.Component {
  handleChange(item) {
    setURLSearchParameter(SEARCH_PARAMETERS.type, item.get('id'));
  }

  options = List(Object.values(QUESTION_TYPES))
    .map((type) =>
      Map({
        id: type.name,
        name: type.title
      })
    )
    .sortBy((type) => type.get('name'))
    .unshift(
      Map({
        id: null,
        name: 'All'
      })
    );

  render() {
    return (
      <SimpleDropdown
        activeItemId={getURLSearchParameter(SEARCH_PARAMETERS.type)}
        className='queue-search-panel__facet'
        fullWidth
        label='Question Type'
        options={this.options}
        onChange={this.handleChange}
        storeName='AuthoringQuestionsTypeDropdownStore'
      />
    );
  }
}
