/**
 * # SearchAndSelect
 *
 * A component that provides a search input and a dropdown list for selecting items.
 *
 * !!! note
 *     It is generally not recommended to use the `SearchAndSelect` component by itself. Instead use `MultiSearchAndSelect` or `SingleSearchAndSelect`, which both inherit the props defined here.
 *
 * ## Props
 *
 * - `className` (string): Additional CSS class name for the component.
 * - `data` (ImmutablePropTypes.list): The list of data items to be displayed in the dropdown.
 * - `selectedItems` (ImmutablePropTypes.list): The list of selected items.
 * - `inputType` (string): The type of input to be rendered. Can be 'search' or 'text'.
 * - `label` (string): The label for the input field.
 * - `defaultValue` (string): The default value for the search input.
 * - `query` (queryBuilderPropType): The query builder for fetching data.
 * - `openOnFocus` (boolean): Whether to open the dropdown on input focus.
 * - `iconRight` (IconProp): The icon to be displayed on the right side of the input field.
 * - `replaceOnSelect` (boolean): Whether to replace the search input value with the selected item's content.
 * - `closeOnSelect` (boolean): Whether to close the dropdown after selecting an item.
 * - `searchFilter` (function): The function used to filter the search results.
 * - `content` (function): The function used to render the content of each dropdown item.
 * - `listItemContent` (function): The function used to render the content of each dropdown item in the list.
 * - `onSelect` (function): The function called when an item is selected.
 * - `onClear` (function): The function called when the search input is cleared.
 * - `onChange` (function): The function called when the search input value changes.
 * - `customFooterRenderer` (function): The function used to render a custom footer in the dropdown.
 * - `error` (boolean): Whether there is an error in the input field.
 * - `placeholder` (string): The placeholder text for the input field.
 * - 'message' (string): Optional message to display below the input field.
 *
 * ## Usage
 *
 * ```jsx
 * import SearchAndSelect from './SearchAndSelect.react';
 *
 * const data = [
 *   { id: 1, name: 'Item 1' },
 *   { id: 2, name: 'Item 2' },
 *   { id: 3, name: 'Item 3' },
 * ];
 *
 * const MyComponent = () => {
 *   const handleSelect = (item) => {
 *     console.log('Selected item:', item);
 *   };
 *
 *   return (
 *     <SearchAndSelect
 *       data={data}
 *       selectedItems={[]}
 *       inputType="search"
 *       label="Search"
 *       defaultValue=""
 *       query={null}
 *       openOnFocus={false}
 *       iconRight={null}
 *       replaceOnSelect={false}
 *       closeOnSelect={false}
 *       searchFilter={(query, searchString) => {
 *         // Implement your search filter logic here
 *         return data.filter((item) => item.name.includes(searchString));
 *       }}
 *       content={(item) => item.name}
 *       listItemContent={null}
 *       onSelect={handleSelect}
 *       onClear={() => {
 *         console.log('Search cleared');
 *       }}
 *       onChange={(value) => {
 *         console.log('Search value changed:', value);
 *       }}
 *       customFooterRenderer={null}
 *       error={false}
 *       placeholder="Search items"
 *     />
 *   );
 * };
 * ```
 */
import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {List} from 'immutable';
import classnames from 'classnames';
import {Manager, Target, Popper} from 'react-popper';
import {queryBuilderPropType} from '@albert-io/json-api-framework/request/builder';
import makeConstants from 'lib/makeConstants';
import {IconProp, Text, Card, DropdownItem, SearchInput, IconInput, Field} from '@albert-io/atomic';
import './search-and-select.scss';

const inputOptions = makeConstants('search', 'text');

export default class SearchAndSelect extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    data: ImmutablePropTypes.list,
    selectedItems: ImmutablePropTypes.list,
    inputType: PropTypes.oneOf(Object.values(inputOptions)),
    label: PropTypes.string,
    defaultValue: PropTypes.string,
    query: queryBuilderPropType,
    openOnFocus: PropTypes.bool,
    iconRight: PropTypes.instanceOf(IconProp),
    replaceOnSelect: PropTypes.bool,
    clearOnSelect: PropTypes.bool,
    closeOnSelect: PropTypes.bool,
    searchFilter: PropTypes.func.isRequired,
    content: PropTypes.func.isRequired,
    listItemContent: PropTypes.func,
    onSelect: PropTypes.func,
    onClear: PropTypes.func,
    onChange: PropTypes.func,
    customFooterRenderer: PropTypes.func,
    error: PropTypes.bool,
    placeholder: PropTypes.string,
    message: PropTypes.string
  };

  static defaultProps = {
    data: List(),
    selectedItems: List(),
    inputType: 'search',
    label: '',
    replaceOnSelect: false,
    clearOnSelect: false,
    openOnFocus: false,
    iconRight: null,
    closeOnSelect: false,
    defaultValue: '',
    onSelect: () => {},
    onClear: () => {},
    onChange: () => {},
    error: false,
    message: ''
  };

  constructor(props) {
    super(props);

    this.state = {
      show: false,
      searchString: props.defaultValue,
      data: props.data,
      isQuerySearch: !!props.query
    };

    this.toggleRef = React.createRef();
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.query) {
      this.fetchData();
    }

    global.document.addEventListener('click', this.handleClickOutsideComponent);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.searchString !== this.state.searchString && this.props.query) {
      this.fetchData();
    }

    if (prevProps.data !== this.props.data) {
      this.setInitialData();
    }
  }

  componentWillUnmount() {
    global.document.removeEventListener('click', this.handleClickOutsideComponent);
  }

  async fetchData() {
    const {searchString} = this.state;
    const filteredQuery = this.props.searchFilter(this.props.query, searchString);
    if (filteredQuery) {
      const data = await filteredQuery.getResourcePromise();
      this.setState({data});
    } else {
      this.setState({data: List()});
    }
  }

  setInitialData = () => {
    this.setState({data: this.props.data});
  };

  handleClickOutsideComponent = (e) => {
    if (!this.toggleRef.current.contains(e.target)) {
      this.toggleDropdown(false);
    }
  };

  // Esc key closes results
  handleEscapeKeyPress = (key) => {
    if (this.state.show && key === 'Escape') {
      this.toggleDropdown(false);
    }
  };

  handleKeyDownOnSelect = (e, datum) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      this.setSelection(datum);
    }
  };

  toggleDropdown = (isOpen) => {
    this.setState((state) => {
      const show = isOpen !== undefined ? isOpen : !state.show;

      return {show};
    });
  };

  setSearchString = (searchString) => {
    this.setState(
      {
        searchString,
        show: !!searchString
      },
      () => this.props.onChange(searchString)
    );
  };

  setSelection = (selectedItem) => {
    if (this.props.replaceOnSelect) {
      if (this.props.inputType === 'search') {
        this.inputRef.current.state.value = this.props.content(selectedItem);
      }

      this.setState({
        searchString: this.props.content(selectedItem)
      });
    } else if (this.props.clearOnSelect) {
      this.resetSearch();
    }

    this.props.onSelect(selectedItem);
    if (this.props.closeOnSelect) {
      this.toggleDropdown(false);
    }
  };

  resetSearch = () => {
    if (this.state.searchString) {
      this.setState(
        {
          show: false,
          searchString: ''
        },
        this.props.onClear
      );
    }
  };

  onFocus = () => {
    if (this.state.searchString || this.props.openOnFocus) {
      this.toggleDropdown(true);
      this.fetchData();
    }
  };

  makeInput = () => {
    const {inputType} = this.props;

    if (inputType === 'search') {
      return (
        <SearchInput
          className='o-search-select__search-input'
          onReset={this.resetSearch}
          onChange={(e) => this.setSearchString(e.target.value)}
          onFocus={this.onFocus}
          placeholder='Search'
          error={this.props.error}
          ref={this.inputRef}
        />
      );
    }

    return (
      <Field
        as={IconInput}
        label={this.props.label}
        border
        fillWrapper
        onChange={(e) => this.setSearchString(e.target.value)}
        onFocus={this.onFocus}
        iconRight={this.state.searchString ? 'times' : this.props.iconRight}
        placeholder={this.props.placeholder || 'Type to search'}
        value={this.state.searchString}
        defaultValue={this.state.searchString}
        onIconRightClick={this.resetSearch}
        error={this.props.error}
      />
    );
  };

  makeSearchResults = () => {
    let searchResults = this.state.data;

    if (!this.state.isQuerySearch) {
      const filterWithString = this.props.searchFilter(this.state.searchString);
      searchResults = this.state.data.filter(filterWithString);
    }

    if (searchResults.isEmpty()) {
      return (
        <Text as='div' className='o-search-select__empty-results' color='tertiary'>
          No results found
        </Text>
      );
    }

    return searchResults.map((datum, key) => {
      const isSelected = this.props.selectedItems.includes(datum);

      return (
        <DropdownItem
          aria-checked={isSelected}
          role='checkbox'
          tabIndex={0}
          className='o-search-select__item'
          onClick={() => this.setSelection(datum)}
          onKeyDown={(e) => this.handleKeyDownOnSelect(e, datum)}
          icon={isSelected ? 'check' : null}
          key={key}
        >
          {this.props.listItemContent
            ? this.props.listItemContent(datum)
            : this.props.content(datum)}
        </DropdownItem>
      );
    });
  };

  render() {
    const {show} = this.state;
    const {message, error} = this.props;
    const Footer = this.props.customFooterRenderer;

    return (
      <div className={classnames('o-search-select', this.props.className)} ref={this.toggleRef}>
        <Manager>
          <Target>{this.makeInput()}</Target>
          {!show && message ? <Message error={error}>{message}</Message> : null}
          {show && (
            <Popper
              className='o-search-select__content-wrapper'
              placement='bottom-start'
              modifiers={{
                flip: {
                  enabled: false
                },
                preventOverflow: {
                  boundariesElement: 'window'
                }
              }}
            >
              <Card
                onKeyDown={(e) => this.handleEscapeKeyPress(e.key)}
                className='o-search-select__content'
              >
                {this.makeSearchResults()}
                {Footer && <Footer searchTerm={this.state.searchString} />}
              </Card>
            </Popper>
          )}
        </Manager>
      </div>
    );
  }
}

const Message = ({children, error}) => (
  <Text
    className={classnames('o-search-select__message', {
      'o-search-select__message--error': error
    })}
    size='xs'
  >
    {children}
  </Text>
);

Message.propTypes = {
  children: PropTypes.node,
  error: PropTypes.bool
};
