import React from 'react';
import PropTypes from 'prop-types';
import SimpleMarkdown from 'simple-markdown';
import {debounce} from 'lodash';
import makeConstants from 'lib/makeConstants';

const mathJaxOrder = SimpleMarkdown.defaultRules.paragraph.order + 0.5;

export const dollarEscapeRule = {
  order: mathJaxOrder - 0.1,
  match: (source) => /^\\\$/.exec(source),
  parse: () => ({content: null}),
  react() {
    return <span>&#36;</span>;
  },
  html: () => '<span>&#36;</span>'
};

const mathJaxFormats = makeConstants('block', 'inline');

export const mathJaxRule = {
  order: mathJaxOrder,
  match: (source) => {
    return /^(\${1,2}[^]*?[^\\]\${1,2})/.exec(source);
  },
  parse: (capture) => {
    const [content] = capture;
    return {
      content,
      format: content.startsWith('$$') ? mathJaxFormats.block : mathJaxFormats.inline
    };
  },
  react(node) {
    return <MathJaxElement format={node.format} content={node.content} />;
  },
  html: (node) => node.content
};

class MathJaxElement extends React.Component {
  ref = React.createRef();

  static propTypes = {
    format: PropTypes.oneOf(Object.values(mathJaxFormats)),
    content: PropTypes.string
  };

  static debouncedTypesetPage = debounce(() => {
    MathJax.Hub.Queue(['Typeset', MathJax.Hub]);
  }, 250);

  state = {
    isFlushing: false
  };

  componentDidMount() {
    MathJaxElement.debouncedTypesetPage();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.content !== this.props.content) {
      this.flush().then(this.typesetCurrentNode);
    }
  }

  /**
   * In certain cases, we need to ensure the MarkdownRendererV2's contents are properly cleaned up.
   * For instance, MathJax sometimes causes problems where the MathJax DOM nodes stick around even though
   * the text content has changed. In these cases, we'll "flush" the node (render an empty div) so that any stuck
   * DOM nodes go away before rendering the actual content.
   */
  flush() {
    return new Promise((res) => {
      this.setState(
        {
          isFlushing: true
        },
        () => {
          this.setState(
            {
              isFlushing: false
            },
            res
          );
        }
      );
    });
  }

  typesetCurrentNode = () => {
    MathJax.Hub.Queue(['Typeset', MathJax.Hub, this.ref.current]);
  };

  render() {
    const {format, content} = this.props;
    const Wrapper = format === mathJaxFormats.block ? 'div' : 'span';
    const processedContent = this.state.isFlushing ? '' : content;
    return (
      <Wrapper
        ref={this.ref}
        /**
         * @see: http://docs.mathjax.org/en/v2.7-latest/options/preprocessors/tex2jax.html
         */
        className='tex2jax_process'
      >
        {processedContent}
      </Wrapper>
    );
  }
}
