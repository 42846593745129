import ImmutablePropTypes from 'react-immutable-proptypes';
import React from 'react';
import PropTypes from 'prop-types';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {setUpStore} from 'client/framework';
import {Fieldset, Text} from '@albert-io/atomic';
import classnames from 'classnames';

import FillInTheBlankQuestionStore from './FillInTheBlankQuestion.store';

import './fill-in-the-blank.scss';

export default class FillInTheBlankInputArea extends React.Component {
  static propTypes = {
    storeName: PropTypes.string,
    question: ImmutablePropTypes.record,
    questionId: PropTypes.bool,
    isGuessSubmitted: PropTypes.bool
  };

  static defaultProps = {
    storeName: 'fillInTheBlankQuestionStore'
  };

  getStore() {
    return setUpStore(FillInTheBlankQuestionStore, this.props.storeName, this.props.questionId);
  }

  render() {
    const {question, isGuessSubmitted} = this.props;

    const dropdowns = question.getDropdowns();

    return (
      <Fieldset>
        <Text as='legend' size='m' className='u-mar-b_1' bold>
          Select options below
        </Text>
        <MarkdownRendererV2
          className={classnames('fitb-input-area', {
            'fitb-input-area-dropdown': !dropdowns.isEmpty()
          })}
          text={question.getDropdownText()}
          store={this.getStore()}
          dropdowns={dropdowns}
          isExplanation={false}
          isGuessSubmitted={isGuessSubmitted}
          question={question}
        />
      </Fieldset>
    );
  }
}
