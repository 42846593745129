import {useState, useEffect} from 'react';
import makeConstants from 'lib/makeConstants';

/**
 * @typedef {import('../../albert-io/json-api-framework/request/builder/index.js').QueryBuilder} QueryBuilder
 */

/**
 * @typedef {((QueryBuilder|function(): QueryBuilder))} Query
 */

const queryStatuses = makeConstants('ready', 'fetching', 'notReady');

/**
 * Hook to ensure you're working with queries that are the most up-to-date.
 * Calling the `invalidte()` function will ensure that `isReady` is `false`
 * until the queries are re-fetched.
 * @example
 * ```js
 *   const {isReady, invalidateQueries} = useAreLatestQueriesReady(
 *     [assignmentQuery, getQuestionSetsQuery],
 *     {
 *       resolveInOrder: true
 *     }
 *   );
 * ```
 * @param {Query[]} queries - Queries that need to resolve before isReady is true. Each query may either be
 *   a QueryBuilder object, or a functions that returns a QueryBuilder object. The latter can be useful if you need
 *   some previously loaded data to build your query.
 * @param {Object} options
 * @param {boolean} options.resolveInOrder - If true, queries will be awaited one at a time, in the orded provided, as
 *   opposed to in parallel. This can be useful if a query relies on the result of a previous query.
 * @returns {{
 *   isReady: boolean,
 *   invalidateQueries: () => void
 *   errors: (any|null)[]
 * }}
 */
export default function useAreLatestQueriesReady(queries, options = {resolveInOrder: false}) {
  const [status, setStatus] = useState(
    queries.every((query) => getQueryBuilderObject(query).isResourceReady())
      ? queryStatuses.ready
      : queryStatuses.notReady
  );
  const [errors, setErrors] = useState(queries.map(() => null));

  useEffect(() => {
    const handleQueries = async () => {
      if (status !== queryStatuses.notReady) {
        return;
      }

      setStatus(queryStatuses.fetching);
      setErrors(queries.map(() => null)); // reset errors

      const newErrors = [];

      if (options.resolveInOrder) {
        for (const [idx, query] of queries.entries()) {
          try {
            // eslint-disable-next-line no-await-in-loop
            await getQueryBuilderObject(query).getResourcePromise();
            newErrors[idx] = null;
          } catch (err) {
            newErrors[idx] = err;
          }
        }
      } else {
        await Promise.all(
          queries.map(async (query, idx) => {
            try {
              await getQueryBuilderObject(query).getResourcePromise();
              newErrors[idx] = null;
            } catch (err) {
              newErrors[idx] = err;
            }
          })
        );
      }
      setErrors(newErrors);
      setStatus(queryStatuses.ready);
    };
    handleQueries();
  });

  const isReady = status === queryStatuses.ready;
  const invalidateQueries = () => {
    queries.forEach((query) => getQueryBuilderObject(query).invalidateInterest());
    setStatus(queryStatuses.notReady);
  };

  return {isReady, invalidateQueries, errors};
}

function getQueryBuilderObject(query) {
  return typeof query === 'function' ? query() : query;
}
