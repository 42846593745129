// @flow
import * as React from 'react';

/**
 * What it does
 * ============
 * This compnoent makes it easy to compose components that have some or all of their props provided to them.
 * This allows us to easily do two things:
 *
 * 1. Write PureComponents that are backed by store data:
 *   Traditionally, creating PureComponents that rely on store data has been verbose. One would either need
 *   to create two components (an impure component that wraps the PureComponent and hands its props down), or
 *   you'd have to pass each store value as a prop to the PureComponent whenever you needed to use it. Using
 *   withProps(), you can very easily do the former.
 *
 * 2. Create subcomponents from more generic components that have some of their props partially applied:
 *   E.g. if you have a <Ribbon /> component component that takes a color prop, you can make a <RedRibbon /> component
 *   super quickly. No, this is not a real-world example, just trying to get the point across.
 *
 * How to use
 * ==========
 * This HOC takes two arguments, a function which returns a props object to be passed to the base
 * component, and the component which the HOC will be applied to. Props can be passed to the returned
 * component, and these will supercede the return function's props if there is a collision.
 *
 * @example
 * const ExampleComponent = withProps(() => ({
 *   testCount: someStore.getCounter()
 * }), class ExampleComponent extends React.PureComponent {
 *   render() {
 *     return (
 *       <div>
 *         {this.props.testCount}
 *         <button onClick={() => callAction(testActions.INCREMENT)}>
 *           Click to increment
 *         </button>
 *       </div>
 *     );
 *   }
 * });
 */
export function withProps(
  propsProvider: () => Object,
  WrappedComponent: React.ComponentType<*>,
  options: {
    suppressPureComponentWarning: boolean
  } = {
    suppressPureComponentWarning: false
  }
): React.StatelessFunctionalComponent<*> {
  if (
    !options.suppressPureComponentWarning &&
    process.env.NODE_ENV === 'development' &&
    !React.PureComponent.isPrototypeOf(WrappedComponent)
  ) {
    logger.warn(
      `withProps is wrapping impure component '${WrappedComponent.name}'. Consider making it a PureComponent instead.`
    );
  }
  return function(props): React.Node {
    const passedProps = {...propsProvider(), ...props};
    return <WrappedComponent {...passedProps} />;
  };
}
