import {resource} from '@albert-io/json-api-framework/request/builder';
import masqueradeStore from 'client/generic/Masquerade/Masquerade.store';
import {maybeApplyFilterMetaContext} from 'components/ContentDiscovery/ContentDiscovery.utils';

export const getGuideLevelsQuery = (
  subjectIds,
  search,
  guideType = 'practice',
  pageSize = 50,
  questionDifficulties,
  questionTypes,
  standardIds,
  standardSetIds,
  hideAssessment,
  hidePreviouslyAssigned
) => {
  let query = resource('guide_v1')
    .mandarkEndpoint(['guides_v1'])
    .filter(
      {
        subject_v2: subjectIds
      },
      !!subjectIds
    )
    .filter({
      guide_type: guideType
    })
    .meta({
      context: {
        search: {
          fields: 'name,description',
          term: search
        },
        content_discovery_filtering: true,
        user: {
          id: masqueradeStore.getUserIdByMasqueradeState()
        }
      }
    })
    .filter(
      {
        guide_levels_v2: {
          'meta.matches_search': true
        }
      },
      search
    )
    .withMeta('guide_v1,guide_level_v2')
    .include('guide_levels_v2.subject_v2,guide_levels_v2.guide_v1')
    .include('subject_v2')
    .pageSize(pageSize);

  query = maybeApplyFilterMetaContext(query, {
    questionDifficulties,
    questionTypes,
    standardIds,
    standardSetIds,
    hideAssessment,
    hidePreviouslyAssigned
  });

  return query;
};

export const getSubjectsByIdQuery = (
  subjectIds,
  questionDifficulties,
  questionTypes,
  standardIds,
  standardSetIds,
  hideAssessment,
  hidePreviouslyAssigned
) => {
  let query = resource('subjects_v2')
    .mandarkEndpoint(['subjects_v2'])
    .filter({
      hidden: false
    })
    .filter({
      id: subjectIds
    })
    .meta({
      context: {
        content_discovery_filtering: true,
        user: {
          id: masqueradeStore.getUserIdByMasqueradeState()
        }
      }
    })
    .withMeta('subject_v2');

  query = maybeApplyFilterMetaContext(query, {
    questionDifficulties,
    questionTypes,
    standardIds,
    standardSetIds,
    hideAssessment,
    hidePreviouslyAssigned
  });

  return query;
};

export const getSubjectsQuery = (
  searchString = '',
  questionDifficulties,
  questionTypes,
  standardIds,
  standardSetIds,
  hideAssessment,
  hidePreviouslyAssigned
) => {
  let baseQuery = resource('subjects_v2')
    .mandarkEndpoint(['subjects_v2'])
    .filter({
      hidden: false
    })
    .meta({
      context: {
        content_discovery_filtering: true,
        user: {
          id: masqueradeStore.getUserIdByMasqueradeState()
        }
      }
    })
    .withMeta('subject_v2');

  baseQuery = maybeApplyFilterMetaContext(baseQuery, {
    questionDifficulties,
    questionTypes,
    standardIds,
    standardSetIds,
    hideAssessment,
    hidePreviouslyAssigned
  });

  if (searchString.length > 0) {
    return baseQuery
      .meta({
        context: {
          search: {
            fields: 'name,description',
            term: searchString
          }
        }
      })
      .filter({
        any_of: [
          {
            name: {search_keyword: searchString}
          },
          {
            description: {search_keyword: searchString}
          }
        ]
      })
      .customQuery({
        sort: '-meta.search_relevance'
      });
  }
  return baseQuery.sort('name');
};

export const getClassroomsQuery = () => {
  return resource('classrooms_v1')
    .mandarkEndpoint(['teachers_v1', masqueradeStore.getUserIdByMasqueradeState(), 'classrooms_v1'])
    .filter({
      school_v5: masqueradeStore.getSchoolId()
    })
    .filter({
      status: 'active'
    })
    .pageSize(100);
};

export const getClassroomSubjectsQuery = (
  searchString = '',
  classroomIds = '',
  pageSize,
  questionDifficulties,
  questionTypes,
  standardIds,
  standardSetIds,
  hideAssessment,
  hidePreviouslyAssigned
) => {
  let baseQuery = resource('subjects_v2')
    .mandarkEndpoint(['subjects_v2'])
    .filter({
      hidden: false
    })
    .filter(
      {
        classrooms_v1: classroomIds
      },
      !!classroomIds
    )
    .meta({
      context: {
        content_discovery_filtering: true,
        user: {
          id: masqueradeStore.getUserIdByMasqueradeState()
        }
      }
    })
    .withMeta('subject_v2');

  if (pageSize) {
    baseQuery = baseQuery.pageSize(pageSize);
  }

  baseQuery = maybeApplyFilterMetaContext(baseQuery, {
    questionDifficulties,
    questionTypes,
    standardIds,
    standardSetIds,
    hideAssessment,
    hidePreviouslyAssigned
  });

  if (searchString.length > 0) {
    return baseQuery
      .meta({
        context: {
          search: {
            fields: 'name,description',
            term: searchString
          }
        }
      })
      .filter({
        any_of: [
          {
            name: {search_keyword: searchString}
          },
          {
            description: {search_keyword: searchString}
          }
        ]
      })
      .customQuery({
        sort: '-meta.search_relevance'
      });
  }
  return baseQuery.sort('name');
};

export const getGuideQuestionCountsQuery = (
  subjectIds,
  guideType,
  questionDifficulties,
  questionTypes,
  standardIds,
  standardSetIds,
  hideAssessment,
  hidePreviouslyAssigned
) => {
  let query = resource('guides_v1')
    .mandarkEndpoint(['guides_v1'])
    .filter({
      subject_v2: subjectIds
    })
    .filter({
      guide_type: guideType
    })
    .meta({
      context: {
        content_discovery_filtering: true,
        user: {
          id: masqueradeStore.getUserIdByMasqueradeState()
        }
      }
    })
    .withMeta('guide_v1');

  query = maybeApplyFilterMetaContext(query, {
    questionDifficulties,
    questionTypes,
    standardIds,
    standardSetIds,
    hideAssessment,
    hidePreviouslyAssigned
  });

  return query;
};

export const getGuideLevelsQueryWithoutCounts = (
  subjectIds,
  search,
  guideType = 'practice',
  pageSize = 50
) => {
  const query = resource('guide_v1')
    .mandarkEndpoint(['guides_v1'])
    .filter(
      {
        subject_v2: subjectIds
      },
      !!subjectIds
    )
    .filter({
      guide_type: guideType
    })
    .meta({
      context: {
        search: {
          fields: 'name,description',
          term: search
        },
        user: {
          id: masqueradeStore.getUserIdByMasqueradeState()
        }
      }
    })
    .filter(
      {
        guide_levels_v2: {
          'meta.matches_search': true
        }
      },
      search
    )
    .withMeta('guide_v1,guide_level_v2')
    .include('guide_levels_v2.subject_v2,guide_levels_v2.guide_v1')
    .include('subject_v2')
    .pageSize(pageSize);

  return query;
};

export const getSubjectsByIdQueryWithoutCounts = (subjectIds) => {
  const query = resource('subjects_v2')
    .mandarkEndpoint(['subjects_v2'])
    .filter({
      hidden: false
    })
    .filter({
      id: subjectIds
    })
    .meta({
      context: {
        user: {
          id: masqueradeStore.getUserIdByMasqueradeState()
        }
      }
    })
    .withMeta('subject_v2');

  return query;
};

export const getSubjectsQueryWithoutCounts = (searchString = '') => {
  const baseQuery = resource('subjects_v2')
    .mandarkEndpoint(['subjects_v2'])
    .filter({
      hidden: false
    })
    .meta({
      context: {
        user: {
          id: masqueradeStore.getUserIdByMasqueradeState()
        }
      }
    })
    .withMeta('subject_v2');

  if (searchString.length > 0) {
    return baseQuery
      .meta({
        context: {
          search: {
            fields: 'name,description',
            term: searchString
          }
        }
      })
      .filter({
        any_of: [
          {
            name: {search_keyword: searchString}
          },
          {
            description: {search_keyword: searchString}
          }
        ]
      })
      .customQuery({
        sort: '-meta.search_relevance'
      });
  }
  return baseQuery.sort('name');
};

export const getClassroomSubjectsQueryWithoutCounts = (
  searchString = '',
  classroomIds = '',
  pageSize
) => {
  let baseQuery = resource('subjects_v2')
    .mandarkEndpoint(['subjects_v2'])
    .filter({
      hidden: false
    })
    .filter(
      {
        classrooms_v1: classroomIds
      },
      !!classroomIds
    )
    .meta({
      context: {
        user: {
          id: masqueradeStore.getUserIdByMasqueradeState()
        }
      }
    })
    .withMeta('subject_v2');

  if (pageSize) {
    baseQuery = baseQuery.pageSize(pageSize);
  }

  if (searchString.length > 0) {
    return baseQuery
      .meta({
        context: {
          search: {
            fields: 'name,description',
            term: searchString
          }
        }
      })
      .filter({
        any_of: [
          {
            name: {search_keyword: searchString}
          },
          {
            description: {search_keyword: searchString}
          }
        ]
      })
      .customQuery({
        sort: '-meta.search_relevance'
      });
  }
  return baseQuery.sort('name');
};

export const excludedIds = [
  'c8e2568e-d9cb-41d2-ae2b-f6ab9c579061',
  'e7dad423-7df7-4c88-804d-63005c6f3162',
  'cc0ba69f-eba2-400f-8a09-ecd401b1ab09',
  '920a0285-178b-45d3-a502-9dcce319ab13',
  '0d2c6fea-b690-4f19-bfd3-5121b880c97d',
  '414a2621-8149-47c4-8cdf-206522b4d169',
  '85a11013-9d81-42c8-82d4-5aeb6f92ccf3'
];
