/**
 * # WithHoverAndFocus
 *
 * For fields, inputs, and any other component concerned with :hover and :focus,
 * it is no longer necessary to set state from within onMouseEnter or onBlur.
 * You may now skip ahead to writing the callback behavior that is truly unique
 * to your component.
 *
 * ## Props
 *
 * - `children` (function): A render prop function that receives an object with the following properties:
 * - `customOnBlur` (function): Custom callback function triggered when the component loses focus.
 * - `customOnFocus` (function): Custom callback function triggered when the component gains focus.
 * - `customOnMouseEnter` (function): Custom callback function triggered when the mouse enters the component.
 * - `customOnMouseLeave` (function): Custom callback function triggered when the mouse leaves the component.
 *
 * ## Usage
 *
 * ```jsx
 * import React from 'react';
 * import WithHoverAndFocus from './WithHoverAndFocus';
 *
 * const MyComponent = () => (
 *   <WithHoverAndFocus customOnMouseEnter={handleMouseEnter} customOnMouseLeave={handleMouseLeave}>
 *     {({ hasFocus, hasHover, onBlur, onFocus, onMouseEnter, onMouseLeave }) => (
 *       <div>
 *         <input
 *           type="text"
 *           onFocus={onFocus}
 *           onBlur={onBlur}
 *           onMouseEnter={onMouseEnter}
 *           onMouseLeave={onMouseLeave}
 *         />
 *         {hasFocus && <span>Input has focus</span>}
 *         {hasHover && <span>Mouse is hovering</span>}
 *       </div>
 *     )}
 *   </WithHoverAndFocus>
 * );
 * ```
 */
import React from 'react';
import PropTypes from 'prop-types';

export default class WithHoverAndFocus extends React.Component {
  static propTypes = {
    children: PropTypes.func,
    customOnBlur: PropTypes.func,
    customOnFocus: PropTypes.func,
    customOnMouseEnter: PropTypes.func,
    customOnMouseLeave: PropTypes.func
  };

  static defaultProps = {
    customOnBlur: () => {},
    customOnFocus: () => {},
    customOnMouseEnter: () => {},
    customOnMouseLeave: () => {}
  };

  constructor() {
    super();

    this.state = {
      hasFocus: false,
      hasHover: false
    };
  }

  handleStateChange = (e, callback = () => {}, handleChange) => {
    this.setState(handleChange);
    callback(e);
  };

  onMouseEnter = (e, callback) => this.handleStateChange(e, callback, () => ({hasHover: true}));

  onMouseLeave = (e, callback) => this.handleStateChange(e, callback, () => ({hasHover: false}));

  onBlur = (e, callback) => this.handleStateChange(e, callback, () => ({hasFocus: false}));

  onFocus = (e, callback) => this.handleStateChange(e, callback, () => ({hasFocus: true}));

  render() {
    const {customOnBlur, customOnFocus, customOnMouseEnter, customOnMouseLeave} = this.props;

    return this.props.children({
      ...this.state,
      onBlur: (e) => this.onBlur(e, customOnBlur),
      onFocus: (e) => this.onFocus(e, customOnFocus),
      onMouseEnter: (e) => this.onMouseEnter(e, customOnMouseEnter),
      onMouseLeave: (e) => this.onMouseLeave(e, customOnMouseLeave)
    });
  }
}
