import {reduce, isUndefined, isNull, get} from 'lodash';
import qs from 'qs';
import {fromJS, List, Map, Set} from 'immutable';

import {getRequestAgent} from '@albert-io/mandark/authentication';

import {getActiveState} from '../client/framework';

export const syncMethods = {
  LONG_POLL: Symbol('long-poll')
};

export const APIMethods = {
  GET: Symbol('GET'),
  /**
   * Used when a parameter is too large to send via URL
   * @type {String}
   */
  POST_FETCH: Symbol('POST_FETCH'),
  POST: Symbol('POST'),
  PUT: Symbol('PUT'),
  DELETE: Symbol('DELETE'),
  PATCH: Symbol('PATCH')
};

const DataStatus = {
  EMPTY: Symbol('EMPTY'),
  FRESH: Symbol('FRESH'),
  STALE: Symbol('STALE'),
  PENDING_PUT: Symbol('PENDING_PUT'),
  PENDING_PATCH: Symbol('PENDING_PATCH'),
  PENDING_POST: Symbol('PENDING_POST'),
  PENDING_DELETE: Symbol('PENDING_DELETE'),
  ERROR: Symbol('ERROR')
};

const DEFAULT_CACHE_TTL = 60 * 1000;

function mandarkStringify(query) {
  return qs.stringify(query, {
    arrayFormat: 'brackets',
    encode: false
  });
}

function keyBuilder({query, params, header, payload}) {
  let key = 'CACHEKEY-';

  if (query) {
    key += JSON.stringify(query);
  }
  if (params) {
    key += JSON.stringify(params);
  }
  if (header) {
    key += JSON.stringify(header);
  }
  if (payload) {
    key += JSON.stringify(payload);
  }

  return key;
}

function isInvalidRequest(params, query) {
  const args = {...params, ...query};
  return reduce(args, (result, elem) => result || isUndefined(elem) || isNull(elem), false);
}

function makeHeader(header, defaultHeader) {
  return {...header, ...defaultHeader};
}

/**
 * Returns `true` when `res.status` is 200-299.
 * @param {Object} res
 */
function isSuccessfulResponse(res) {
  return res && res.status && res.status >= 200 && res.status <= 299;
}

export function getEmptyPayload(model, modelInterface, status, parentApi) {
  return new Payload({
    status: status || DataStatus.EMPTY,
    promise: Promise.resolve(model),
    data: model,
    modelInterface,
    parentApi
  });
}

class Payload {
  constructor({
    /* eslint-disable no-unused-vars */
    status = DataStatus.EMPTY,
    data,
    promise = Promise.resolve(new Map()),
    parentApi,
    affectsResources,
    error,
    modelInterface,
    versions
    /* eslint-enable no-unused-vars */
  }) {
    this.status = status;
    this.data = data;
    this.promise = promise;
    this.parentApi = parentApi;
    this.affectedResources = new Set();
    this.error = error;
    this.invalidatedResources = new Set();
    if (modelInterface) {
      this.modelInterface = new modelInterface(this.data, versions);
    }
  }

  hasReceivedData() {
    return this.isFresh() || this.isStale();
  }

  isFresh() {
    return this.status === DataStatus.FRESH;
  }

  isStale() {
    return this.status === DataStatus.STALE;
  }

  isValid() {
    return this.status !== DataStatus.ERROR;
  }

  isPending() {
    return this.isStale() || this.isEmpty();
  }

  isEmpty() {
    return this.status === DataStatus.EMPTY;
  }

  getData() {
    return this.data;
  }

  getError() {
    return this.error;
  }

  getPromise() {
    return this.promise;
  }

  get interface() {
    return this.modelInterface;
  }

  getStatus() {
    return this.status;
  }

  affectsResource(resourceObject, customAPI) {
    this.closure = this.closure ? this.closure : new Map();

    if (!this.closure.has(resourceObject.id) && customAPI) {
      this.closure = this.closure.set(resourceObject.id, customAPI);
    }

    if (this.affectedResources.isEmpty()) {
      this.promise
        .then((response) => {
          this.affectedResources.forEach((resource) => {
            let {resourcePool} = this.parentApi;
            if (this.closure.has(resource.id)) {
              resourcePool = this.closure.get(resource.id).resourcePool;
            }

            const resourceFormattedForKeyGeneration =
              resource.query && typeof resource.query === 'object'
                ? Object.keys(resource).reduce((obj, key) => {
                    if (key === 'query') {
                      obj[key] = mandarkStringify(resource[key]);
                    } else {
                      obj[key] = resource[key];
                    }
                    return obj;
                  }, {})
                : resource;
            const resourceHeader = resourceFormattedForKeyGeneration.header;
            if (resourceHeader) {
              resourceFormattedForKeyGeneration.header = makeHeader(
                resourceHeader,
                this.parentApi.defaultHeader
              );
            }

            const key = keyBuilder(resourceFormattedForKeyGeneration);
            resourcePool.get(resource.id).invalidateCacheKey(key);
          });

          if (resourceObject.customNotifyEvent) {
            resourceObject.customNotifyEvent();
          }
        })
        .catch((err) => {
          console.error('ERROR ON CACHE AFFECT: ', err);
        });
    }

    this.affectedResources = this.affectedResources.add(resourceObject);

    return this;
  }

  invalidatesResource(resourceId, customNotifyEvent, customAPI) {
    this.closure = this.closure ? this.closure : new Map();

    if (!this.closure.has(resourceId) && customAPI) {
      this.closure = this.closure.set(resourceId, customAPI);
    }

    if (this.invalidatedResources.isEmpty()) {
      this.promise
        .then((response) => {
          this.invalidatedResources.forEach((id) => {
            let {resourcePool} = this.parentApi;
            if (this.closure.has(id)) {
              resourcePool = this.closure.get(id).resourcePool;
            }

            const resource = resourcePool.get(id, new Map());

            resource.invalidateCache();
          });
          if (customNotifyEvent) {
            customNotifyEvent();
          }
        })
        .catch((err) => {
          console.error('ERROR ON CACHE INVALIDATION: ', err);
        });
    }

    this.invalidatedResources = this.invalidatedResources.add(resourceId);

    return this;
  }

  setStatus(newStatus) {
    this.status = newStatus;
    return this;
  }
}

/**
 * @memberOf Server.Isomorphism
 */
let registeredAPIs = new List();
/**
 * @memberOf Server.Isomorphism
 */
export function resetAPIs() {
  registeredAPIs.forEach((api) => api.resetAPI());
}

export class ExternalAPI {
  constructor({
    /* eslint-disable no-unused-vars */
    id = null,
    name,
    location,
    errorHandler,
    requestPreHook = () => {},
    syncMethod = syncMethods.LONG_POLL,
    timeout = 60000
    /* eslint-enable no-unused-vars */
  }) {
    this.id = id;
    this.name = name;
    this.location = location;
    this.requestPreHook = requestPreHook;
    this.syncMethod = syncMethod;
    this.timeout = timeout;
    this.defaultHeader = {};
    this.errorHandler = errorHandler;
    this._ISOMORPHISM_promises = [];

    this.resourcePool = new Map();
    registeredAPIs = registeredAPIs.push(this);
  }

  addResource({id, maxAge = DEFAULT_CACHE_TTL, endpoint, model, modelInterface, versions}) {
    if (isUndefined(endpoint) || isUndefined(model)) {
      throw new Error('Could not add resource: endpoint and model must both be specified');
    }

    const resourceKey = id;
    const resource = new Resource({
      id,
      endpointTemplate: endpoint,
      maxAge,
      parentApi: this,
      model,
      modelInterface,
      versions
    });
    this.resourcePool = this.resourcePool.set(resourceKey, resource);

    return id;
  }

  _ISOMORPHISM_getPromises() {
    return this._ISOMORPHISM_promises;
  }

  _ISOMORPHISM_resetPromises() {
    this._ISOMORPHISM_promises = [];
  }

  getResource({
    id,
    query,
    params,
    header = {},
    method,
    payload,
    forceRefresh = false,
    forceUpdate = true
  }) {
    const resource = this.resourcePool.get(id, null);

    if (resource === null) {
      throw new Error(`Resource ${id} was never initialized.`);
    } else {
      let res = null;
      header = makeHeader(header, this.getDefaultHeader());

      query = typeof query === 'object' ? mandarkStringify(query) : query;

      try {
        res = resource.get({
          params,
          query,
          header,
          forceRefresh,
          method,
          payload,
          forceUpdate
        });
        if (res.promise && process.env.IS_BROWSER === false) {
          this._ISOMORPHISM_promises.push(res.promise);
        }
      } catch (err) {
        console.error(err.stack); // eslint-disable-line no-console
      }
      return res;
    }
  }

  // Header to be included with ALL requests
  setDefaultHeader(header = {}) {
    this.defaultHeader = header;
  }

  getDefaultHeader() {
    return this.defaultHeader;
  }

  resetAPI() {
    this.resourcePool.forEach((resource) => resource.resetCache());
    this.setDefaultHeader();
  }
}

// Each resource keeps track of the params keyed and has multiple resources within itself
// and refetches if expired. Keep a fetch timestamp.
// TODO: optionally take a callback which is invoked when the resource updates
// endpointTemplate: e.g. /sets/:id
class Resource {
  constructor({
    /* eslint-disable no-unused-vars */
    id,
    refreshTime,
    endpointTemplate,
    maxAge,
    model,
    parentApi,
    modelInterface,
    versions
    /* eslint-ensable no-unused-vars */
  }) {
    this.id = id;
    this.cache = new Map();
    this.refreshTime = refreshTime;
    this.endpointTemplate = endpointTemplate;
    this.maxAge = maxAge;
    this.model = model;
    this.parentApi = parentApi;
    this.modelInterface = modelInterface;
    this.versions = versions;
  }

  resetCache() {
    this.cache = new Map();
  }

  invalidateCache() {
    this.cache = this.cache.map((value, cacheKey) => {
      return value.has('timestamp') ? value.set('timestamp', 0) : value;
    });
  }

  invalidateCacheKey(key) {
    if (this.cache.has(key)) {
      this.cache = this.cache.setIn([key, 'timestamp'], 0);
    }
  }

  // You always get back a Payload
  get({params, query, header, payload, forceRefresh, forceUpdate, method = APIMethods.GET}) {
    const now = new Date();
    const cacheKey = keyBuilder({
      query,
      params,
      header,
      payload
    });
    let path = this.endpointTemplate;

    if (isInvalidRequest(params, query)) {
      console.warn(
        // eslint-disable-line no-console
        `The specified path parameters ${params} or query parameters
        ${query} were not fully specified in a call to resource ${this.id}.
        No request will be made and you will receive an empty model.`
      );

      return new Payload({
        status: DataStatus.ERROR,
        promise: Promise.resolve(this.model),
        data: this.model,
        modelInterface: this.modelInterface
      });
    }

    // TODO Consider making a reduce
    for (const param in params) {
      path = path.replace(`:${param}`, params[param]);
    }

    const apiParams = {
      method,
      path,
      query,
      header,
      cacheKey,
      payload,
      forceUpdate
    };

    if (method === APIMethods.GET || method === APIMethods.POST_FETCH) {
      if (this.cache.has(cacheKey)) {
        const data = this.cache.get(cacheKey);
        // GET is already pending
        // XXX Should make this better. Currently, there's an edge case where someone
        // can request a resource, never read it again until it becomes stale. In that
        // case we'll continue re-constructing the new payload until the FRESH one comes
        // in again, if they were to suddenly start pinging this STALE resource.
        // Should theoretically never arise, but better to be thorough.
        if (data.has('pendingGet') && data.get('success', true)) {
          // If this is a fresh resource that went stale, return the fresh payload, with the updated status
          if (data.has('payload')) {
            return data.get('payload').setStatus(DataStatus.STALE);
          }
          const status = data.get('data') ? DataStatus.STALE : DataStatus.EMPTY;
          const payload = status === DataStatus.EMPTY ? this.model : data.get('data');
          return new Payload({
            status,
            promise: data.get('pendingGet'),
            data: payload,
            modelInterface: this.modelInterface,
            versions: this.versions
          });
        }
        if (!data.get('success')) {
          // Last GET for this resource failed and the cache is not yet expired
          const promise =
            now - data.get('timestamp') < this.maxAge
              ? new Promise((resolve, reject) => {
                  reject(data.get('data'));
                })
              : this.makeFetch(apiParams);

          return new Payload({
            status: DataStatus.ERROR,
            promise,
            data: this.model,
            error: data.get('data'),
            modelInterface: this.modelInterface,
            versions: this.versions
          });
          // Last GET is fresh
        }
        if (now - data.get('timestamp') < this.maxAge && !forceRefresh) {
          if (data.has('payload')) {
            // Fresh in cache and payload has already been constructed
            return data.get('payload');
          }
          // First access of this fresh payload
          const payload = new Payload({
            status: DataStatus.FRESH,
            promise: new Promise((resolve) => {
              resolve(data.get('data'));
            }),
            data: data.get('data'),
            modelInterface: this.modelInterface,
            versions: this.versions
          });

          this.cache = this.cache.set(cacheKey, data.set('payload', payload));

          return payload;
        }
        // GET is stale --- This should theoretically never hit, and should
        // be caught by the first conditional, but I don't want to rock the boat in this PR.
        return new Payload({
          status: DataStatus.STALE,
          promise: this.makeFetch(apiParams),
          data: data.get('data'),
          modelInterface: this.modelInterface,
          versions: this.versions
        });
      }
      return new Payload({
        status: DataStatus.EMPTY,
        promise: this.makeFetch(apiParams),
        data: this.model,
        modelInterface: this.modelInterface,
        versions: this.versions
      });
    }
    if (method === APIMethods.PUT) {
      return new Payload({
        status: DataStatus.PENDING_PUT,
        promise: this.makePut(apiParams),
        data: null,
        parentApi: this.parentApi
      });
    }
    if (method === APIMethods.PATCH) {
      return new Payload({
        status: DataStatus.PENDING_PATCH,
        promise: this.makePatch(apiParams),
        data: null,
        parentApi: this.parentApi
      });
    }
    if (method === APIMethods.POST) {
      return new Payload({
        status: DataStatus.PENDING_POST,
        promise: this.makePost(apiParams),
        data: null,
        parentApi: this.parentApi
      });
    }
    if (method === APIMethods.DELETE) {
      return new Payload({
        status: DataStatus.PENDING_DELETE,
        promise: this.makeDelete(apiParams),
        data: null,
        parentApi: this.parentApi
      });
    }
    throw new Error('Method must be one of GET, PUT, PATCH, POST, DELETE');
  }

  makeFetch({method, path = '', payload, query, cacheKey, header, forceUpdate}) {
    const fullPath = this.parentApi.location.concat(path);

    const func = method === APIMethods.POST_FETCH ? 'post' : 'get';

    // TODO All of the pending* functions need to be cleaned up, DRYed out, generally better written
    const pendingGet = new Promise((resolve, reject) => {
      getRequestAgent()
        [func](fullPath)
        .query(query)
        .timeout(this.parentApi.timeout)
        .set(header)
        .send(payload)
        .end((err, res) => {
          const timestamp = new Date();
          let status = -1;
          let payload = null;
          if (forceUpdate) {
            setTimeout(() => getActiveState().forceUpdate(), 0);
          }
          if (!err) {
            status = res.status;
            if (res.hasOwnProperty('text')) {
              try {
                payload = JSON.parse(res.text);
              } catch (error) {
                if (process.env.IS_BROWSER) {
                  const data = fromJS({
                    data: error,
                    timestamp,
                    success: false
                  });
                  this.cache = this.cache.set(cacheKey, data);
                }
                return reject(fromJS(error));
              }
            }
          }

          if (err || !isSuccessfulResponse(res)) {
            this.parentApi.errorHandler(err);
            this.cache = this.cache.remove(cacheKey);
            const normalizedError = normalizeError(err, status);
            if (process.env.IS_BROWSER) {
              const data = fromJS({
                data: normalizedError,
                timestamp,
                success: false
              });
              this.cache = this.cache.set(cacheKey, data);
            }
            return reject(normalizedError);
          }
          this.parentApi.requestPreHook(payload);

          const data = fromJS({
            data: payload,
            timestamp,
            success: true
          });

          this.cache = this.cache.set(cacheKey, data);
          return resolve(fromJS(payload));
        });
    });

    let currentValue = null;
    if (this.cache.has(cacheKey)) {
      currentValue = this.cache.get(cacheKey).get('data');
    }
    this.cache = this.cache.set(
      cacheKey,
      new Map({
        pendingGet,
        data: currentValue
      })
    );

    if (!process.env.IS_BROWSER) {
      setTimeout(() => {
        this.cache = this.cache.delete(cacheKey);
      }, 120000);
    }

    return pendingGet;
  }

  makePut({path = '', payload, header, query, forceUpdate}) {
    const fullPath = this.parentApi.location.concat(path);

    const pendingPut = new Promise((resolve, reject) => {
      getRequestAgent()
        .put(fullPath)
        .query(query)
        .send(payload)
        .timeout(this.parentApi.timeout)
        .set(header)
        .end((err, res) => {
          let status = -1;
          let payload = null;
          if (!err) {
            status = res.status;
            if (res.hasOwnProperty('text') && res.text) {
              try {
                payload = JSON.parse(res.text);
              } catch (error) {
                return reject(fromJS(error));
              }
            }
          }

          if (err || !isSuccessfulResponse(res)) {
            this.parentApi.errorHandler(err);
            const normalizedError = normalizeError(err, status);
            return reject(normalizedError);
          }
          resolve(fromJS(payload));
          if (forceUpdate) {
            getActiveState().forceUpdate();
          }
        });
    });

    return pendingPut;
  }

  makePatch({path = '', payload, header, query, forceUpdate}) {
    const fullPath = this.parentApi.location.concat(path);

    const pendingPatch = new Promise((resolve, reject) => {
      getRequestAgent()
        .patch(fullPath)
        .query(query)
        .send(payload)
        .timeout(this.parentApi.timeout)
        .set(header)
        .end((err, res) => {
          let status = -1;
          let payload = null;
          if (!err) {
            status = res.status;
            if (res.hasOwnProperty('text')) {
              try {
                payload = JSON.parse(res.text);
              } catch (error) {
                return reject(fromJS(error));
              }
            }
          }
          if (err || !isSuccessfulResponse(res)) {
            this.parentApi.errorHandler(err);
            const normalizedError = normalizeError(err, status);
            return reject(normalizedError);
          }
          resolve(fromJS(payload));
          if (forceUpdate) {
            getActiveState().forceUpdate();
          }
        });
    });

    return pendingPatch;
  }

  makePost({path = '', payload, header, query, forceUpdate}) {
    const fullPath = this.parentApi.location.concat(path);

    const pendingPost = new Promise((resolve, reject) => {
      getRequestAgent()
        .post(fullPath)
        .query(query)
        .send(payload)
        .timeout(this.parentApi.timeout)
        .set(header)
        .end((err, res) => {
          let status = -1;
          let payload = null;
          if (!err) {
            status = res.status;
            if (res.hasOwnProperty('text') && res.text) {
              try {
                payload = JSON.parse(res.text);
              } catch (error) {
                return reject(fromJS(error));
              }
            }
          }
          if (err || !isSuccessfulResponse(res)) {
            this.parentApi.errorHandler(err);
            const normalizedError = normalizeError(err, status);
            return reject(normalizedError);
          }
          resolve(fromJS(payload));
          if (forceUpdate) {
            getActiveState().forceUpdate();
          }
        });
    });

    return pendingPost;
  }

  makeDelete({path = '', payload, query, header, forceUpdate}) {
    const fullPath = this.parentApi.location.concat(path);

    const pendingDelete = new Promise((resolve, reject) => {
      getRequestAgent()
        .del(fullPath)
        .query(query)
        .send(payload)
        .timeout(this.parentApi.timeout)
        .set(header)
        .end((err, res) => {
          let status = -1;
          if (!err) {
            status = res.status;
          }

          if (err || !isSuccessfulResponse(res)) {
            this.parentApi.errorHandler(err);
            const normalizedError = normalizeError(err, status);
            reject(normalizedError);
          } else {
            resolve(new Map());
            if (forceUpdate) {
              getActiveState().forceUpdate();
            }
          }
        });
    });

    return pendingDelete;
  }
}

function normalizeError(err, status) {
  try {
    const responseText = get(err, 'response.text', '');
    let errorContent;
    if (responseText) {
      errorContent = JSON.parse(responseText);
    } else {
      errorContent = '';
    }
    const errorMessage = errorContent.message;
    const {displayMessage} = errorContent;
    const {statusCode} = errorContent;
    return new Map({
      message: err && err.hasOwnProperty('message') ? err.message : err,
      status,
      statusCode,
      error: errorMessage,
      displayMessage,
      fullObject: err
    });
  } catch (exception) {
    return new Map({
      message: '',
      status: -1,
      error: exception,
      displayMessage:
        'Oops, something went wrong. If this issue persists, please contact hello@albert.io',
      fullObject: exception
    });
  }
}
