import React, {useEffect} from 'react';

import {LoadingSpinner} from '@albert-io/atomic';

import {SaveStatus} from '../../components/QuestionTypes/QuestionType.types';

import useDraftGuess from './useDraftGuess';

interface Props extends PropsWithChildrenOptional {
  storeName?: string;
  submitted?: boolean;
}

export default function DraftGuessWrapper({children, storeName = '', submitted = false}: Props) {
  const {loadDraftGuess, saveStatus, startTimer, warnUser} = useDraftGuess(storeName, submitted);

  useEffect(() => {
    loadDraftGuess();
  }, [loadDraftGuess]);

  useEffect(() => {
    startTimer();
  }, [storeName, startTimer]);

  useEffect(() => {
    window.addEventListener('beforeunload', warnUser);

    return () => {
      window.removeEventListener('beforeunload', warnUser);
    };
  }, [warnUser]);

  return submitted || storeName === '' || saveStatus !== SaveStatus.LOADING ? (
    <>{children}</>
  ) : (
    <LoadingSpinner />
  );
}
