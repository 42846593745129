import React from 'react';
import PropTypes from 'prop-types';
import {callTargetedAction, setUpStore} from 'client/framework';
import classnames from 'classnames';
import freeEntryQuestionActions from './FreeEntryQuestion.actions';
import FreeEntryQuestionStore from './FreeEntryQuestion.store';

export default class FreeEntryQuestionResponseStatsTopFiveAttempts extends React.Component {
  static propTypes = {
    inputId: PropTypes.string,
    topAnswers: PropTypes.object,
    totalAttempts: PropTypes.number,
    usersGuess: PropTypes.string,
    storeName: PropTypes.string
  };

  getStore() {
    return setUpStore(FreeEntryQuestionStore, this.props.storeName);
  }

  toggleTopFive() {
    callTargetedAction({
      name: freeEntryQuestionActions.TOGGLE_TOP_FIVE,
      payload: this.props.inputId,
      targetStore: this.props.storeName
    });
  }

  render() {
    const store = this.getStore();
    const inputId = this.props.inputId;
    const usersGuess = this.props.usersGuess;
    const topFiveAttempts = this.props.topAnswers;
    const isShowingToggleText = store.isTopFiveOpen(inputId)
      ? 'Hide top answers'
      : 'Show top answers';
    const isShowingClasses = classnames('question-response-top-attempts__toggle', {
      'is-showing': store.isTopFiveOpen(inputId)
    });
    const listIsShowingClasses = classnames('question-response-top-attempts__list', {
      'is-showing': store.isTopFiveOpen(inputId)
    });

    return (
      <div className='question-response-top-attempts'>
        <div
          className={isShowingClasses}
          onClick={() => {
            this.toggleTopFive();
          }}
        >
          {isShowingToggleText}
        </div>
        <ul className={listIsShowingClasses}>
          {topFiveAttempts
            .map((stat, i) => {
              const isCorrect = stat.isCorrect();
              const isUsersGuess = stat.getInputValue() === usersGuess;
              const percentageCorrect = stat.getPercentageResponseRate(this.props.totalAttempts);
              const attemptPercentageClasses = classnames(
                'question-response-top-attempts__list-item',
                {
                  correct: isCorrect,
                  selected: isUsersGuess,
                  incorrect: !isCorrect
                }
              );
              const isCorrectIndicator = classnames(null, {
                'fa fa-check-circle': isCorrect,
                'fa fa-times-circle': !isCorrect
              });
              return (
                <li key={i} className={attemptPercentageClasses}>
                  <span className={isCorrectIndicator} />
                  <span className='question-response-top-attempts__attempt-value'>
                    {stat.getInputValue()}
                  </span>
                  <span className='question-response-top-attempts__attempt-percentage'>
                    {percentageCorrect + '%'}
                  </span>
                </li>
              );
            })
            .toList()}
        </ul>
      </div>
    );
  }
}
