// @flow

type Identity = null | {id: string};

const notifier = {
  _identity: null,
  _client: false,
  _notify: false,
  /**
   * send an error to our error tracking provider.
   * every provider will handle the provided payload different (via `setNotifyHandler`), but
   * this facade allows us to _always_ send the format of: `error, context`
   * @param {Error} error
   * @param {Object} context
   */
  notify(error: Error, context: ?Object) {
    if (this.hasClient() === false) {
      logger.debug('notifier.notify: no client : %j %j', error, context);
      return;
    }
    if (this._notify) {
      this._notify(error, context, this.getIdentity());
    } else {
      logger.debug('notifier.notify: no notify handler : %j %j', error, context);
    }
  },
  /**
   * specify a `handler` function to be called when `notifier.notify` is called.
   * The handler will be passed: `(error, context, identity)`.
   */
  setNotifyHandler(handler) {
    this._notify = handler;
  },
  /**
   * set the identity (user) for the notifier instance.
   */
  setIdentity(identity: Identity) {
    this._identity = identity;
  },
  getIdentity(): Identity {
    return this._identity;
  },
  /**
   * "cleans" the notifier instances by wiping the current identity.
   */
  clean() {
    this.setIdentity(null);
  },
  setClient(client) {
    this._client = client;
  },

  hasClient(): boolean {
    return Boolean(this._client);
  },
  /**
   * returns the configured client instance.
   */
  getClient(): any {
    return this._client;
  }
};

export default notifier;
