import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import {Router, match} from 'react-router';
import Cookies from 'js-cookie';
import _decode from 'jwt-decode';
import {once} from 'lodash';
import {loadableReady} from '@loadable/component';
import smoothscroll from 'smoothscroll-polyfill';
import track from 'react-tracking';

/**
 * This is unused by this file but it is included here to resolve circular dependencies in child modules (inclusion of models directly).
 * THE ORDER OF THESE IMPORTS IS INTENTIONAL any file that could import a generated or augmented model should be
 * imported below this group.
 */
import 'resources/modelRegistry';

import {local} from 'lib/StorageUtil';
import {history} from 'client/history';
import sessionStore from 'client/Session/SessionStore';

import {restoreServerCacheForClient} from 'resources/mandark.resource';

import {getActiveState} from './framework';
import getRoutes from './routes';

/**
 * Polyfills <details> and <summary> elements on IE Edge
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/details
 */
import 'details-element-polyfill';

/**
 * Polyfills HTMLElement `inert` attribute
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/inert
 */
if (process.env.IS_BROWSER) {
  // eslint-disable-next-line global-require
  require('inert-polyfill');
}

/**
 * Polyfills DOM scroll methods available. Mode details at:
 * https://drafts.csswg.org/cssom-view/#extensions-to-the-window-interface
 */
if (process.env.IS_BROWSER) {
  smoothscroll.polyfill();
}

/**
 * @todo There is a bit of a race-dependency issue with restoring cache... basically
 * we need to make sure the registry has been loaded before attempting
 * to restore the cache delievered from the server.
 *
 * This seems like the most obvious place to do it for now.
 */
restoreServerCacheForClient();

/**
 * We only inject getActiveState for environments that are explicitly defined here.
 * ...right now, just "development"
 */
if (process.env.IS_BROWSER && process.env.NODE_ENV === 'development') {
  global.getActiveState = getActiveState;
}

/**
 * significant part of this code is copied from
 * https://github.com/rafrex/react-router-hash-link-scroll
 */
function hashLinkScroll() {
  const {hash} = document.location;

  if (hash !== '') {
    // Push onto callback queue so it runs after the DOM is updated,
    // this is required when navigating from a different page so that
    // the element is rendered on the page before trying to getElementById.
    setTimeout(() => {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        /**
         * @todo add calculations for tertiary nav height if we want it fixed
         */
        // either global nav or marketing header render depending on if user is logged in or not.
        const navEl = document.querySelector('.global-nav, .marketing-header');
        const subheaderEl = document.querySelector('.subheader');
        const navElHeight = navEl ? navEl.getBoundingClientRect().height : 0;
        const subheaderElHeight = subheaderEl ? subheaderEl.getBoundingClientRect().height : 0;
        const FIXED_HEADER_HEIGHT = navElHeight + subheaderElHeight;
        const elementRectTop = Math.ceil(element.getBoundingClientRect().top);

        window.scrollTo(0, window.scrollY + elementRectTop - FIXED_HEADER_HEIGHT);
      } else {
        window.scrollTo(0, 0);
      }
    }, 0);
  } else {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }
}

const loginAtKey = 'albert.lms-login-at';

const trackLoginFromLms = once((callback) => {
  const launchContextCookie = Cookies.get('lti_launch_context');

  const loginAt = sessionStore.getLastAuthenticatedAt();
  const lastLoginAt = local.get(loginAtKey, '');
  const userIsResumingSession = typeof loginAt === 'string' && loginAt === lastLoginAt;
  if (launchContextCookie && !userIsResumingSession && loginAt) {
    local.set(loginAtKey, loginAt);
    const launchContextDecoded = _decode(launchContextCookie);
    const lmsPlatformName = launchContextDecoded.tool_platform_product_family_code || 'LMS';
    callback(lmsPlatformName);
  }
});

function onRouteChange() {
  /* TODO: Ideally, we'd pass location, props, and query here, but react-router
  being react-router it does not give you any access to these outside the
  top level route-handling react component
  May want to transition to something like:
  https://github.com/reactjs/react-router/blob/e43293d873abb985d4120f33b7955fc5269341fe/docs/guides/flux.md */
  getActiveState().forceUpdate().then(hashLinkScroll);

  /* Function only runs once per runtime. Includes logic to catch and avoid tracking refreshes and resumed sessions  */
  trackLoginFromLms((method) => this.props.tracking.trackEvent({event: 'login', method}));

  this.props.tracking.trackEvent({
    event: 'gtm.historyChange'
  });
}

const routes = getRoutes();

loadableReady(() => {
  match({history, routes}, (error, redirectLocation, renderProps) => {
    const AlbertRouter = (props) => {
      return (
        <Router
          history={history}
          routes={routes}
          onUpdate={onRouteChange}
          {...props}
          {...renderProps}
        />
      );
    };

    // be cautious of putting values in the first argument since they will initiate values
    // when loadableReady() is called instead of when the callback is called
    // https://github.com/albert-io/project-management/issues/5069#issuecomment-829575973
    const TrackedApp = track(
      {},
      {
        dispatch: (data) => {
          const eventObject = {
            ...data,
            timestamp: Date.now(),
            url: window.location.pathname,
            account_type: sessionStore.getAccountType(),
            user_id: sessionStore.getUserId(),
            logged_in: sessionStore.hasValidSession(),
            sign_up_date: sessionStore.getSignUpDate()
            // license_status @see https://github.com/albert-io/project-management/issues/5747
          };
          if (sessionStore.isTeacher()) {
            eventObject.user_email = sessionStore.getUserEmail();
          }
          window.dataLayer.push({
            event: eventObject.event,
            eventProps: eventObject
          });
        }
      }
    )(AlbertRouter);
    ReactDOM.render(<TrackedApp />, document.getElementById('app'));
  });
});
