import {Map, Record, List} from 'immutable';
import {kebabCase} from 'lodash';
import {v4 as uuid} from 'uuid';
import {GuessModel} from 'resources/Guess/Guess.model';
import {stats} from 'lib/StatsUtil';
import {verifyNoCollisions} from './verifyNoCollisions';
import {multipleChoiceQuestionExtensions} from './questionTypes/multipleChoiceQuestion.extensions';
import {twoWayQuestionExtenions} from './questionTypes/twoWayQuestion.extensions';
import {passageCorrectionQuestionExtensions} from './questionTypes/passageCorrectionQuestion.extensions';

const rubricTypeConstants = {
  'fill-in-the-blank': 'exact',
  'free-entry': 'contains',
  'free-response': 'markAsCorrect',
  'multiple-choice': 'exact',
  'snippet-selection': 'exact',
  'text-highlight': 'mostEqual',
  'two-way': 'exact',
  'graph-contains': 'graphContains'
};

class InputAnswerStat extends Record({
  correct: false,
  count_of_answers: 0,
  input_value: ''
}) {
  getAnswerCount() {
    return this.get('count_of_answers');
  }

  getInputValue() {
    return this.get('input_value');
  }

  getPercentageResponseRate(totalAnswerCount) {
    return stats.percentage(this.getAnswerCount(), totalAnswerCount);
  }

  isCorrect() {
    return this.get('correct');
  }
}

const commonQuestionMethods = {
  getCorrectAnswerCountForInput(inputId) {
    return this.getIn(['meta', 'how_others_answered', inputId, 'count_of_correct_answers'], 0);
  },

  getCorrectAnswerCountForInputValue(inputId, valueId) {
    return this.getIn(
      ['meta', 'how_others_answered', inputId, 'inputs', valueId, 'count_of_correct_answers'],
      0
    );
  },

  getGuessHistory() {
    return this.getGuesses().map((guess) => new GuessModel(guess));
  },

  getPosition() {
    return this.get('position', 0);
  },

  getPercentageCorrectForInput(inputId) {
    return stats.percentage(
      this.getCorrectAnswerCountForInput(inputId),
      this.getTotalAnswerCountForInput(inputId)
    );
  },

  getPercentageCorrectForInputValue(inputId, valueId) {
    return stats.percentage(
      this.getCorrectAnswerCountForInputValue(inputId, valueId),
      this.getTotalAnswerCountForInputValue(inputId, valueId)
    );
  },

  getPercentageSelectedInputValue(inputId, valueId) {
    return stats.percentage(
      this.getTotalAnswerCountForInputValue(inputId, valueId),
      this.getTotalAnswerCountForInput(inputId)
    );
  },

  getResponsePercentageForInput(inputId) {
    return stats.percentage(this.getTotalAnswerCountForInput(inputId), this.getTotalAnswerCount());
  },

  getTitleSlug() {
    return kebabCase(this.getTitle().toLowerCase());
  },

  getTopAnswersForInputSorted(inputId, count = 5) {
    return this.cache(`top-answers-${inputId}-${count}`, () =>
      this.getIn(['meta', 'how_others_answered', inputId, 'inputs'], new Map())
        .sortBy((input) => -input.get('count_of_answers'))
        .take(count)
    ).map((stat, name) => {
      return new InputAnswerStat({
        correct: stat.get('correct'),
        count_of_answers: stat.get('count_of_answers'),
        input_value: name
      });
    });
  },

  getTotalAnswerCount() {
    return this.getIn(['meta', 'how_others_answered'], new Map()).reduce(
      (result, value) => result + value.get('count_of_answers'),
      0
    );
  },

  getTotalAnswerCountForInput(inputId) {
    return this.getIn(['meta', 'how_others_answered', inputId, 'count_of_answers'], 0);
  },

  getTotalAnswerCountForInputValue(inputId, valueId) {
    return this.getIn(
      ['meta', 'how_others_answered', inputId, 'inputs', valueId, 'count_of_answers'],
      0
    );
  },

  getValidResponse() {
    const rubric = this.getRubric();
    if (rubric.hasOwnProperty('validResponse')) {
      return rubric.get('validResponse', Map());
    }
    return rubric.get('valid_response', Map());
  },

  hasGuess() {
    return !this.getGuesses().isEmpty();
  },

  isMostRecentGuessCorrect() {
    return (
      this.getGuesses() &&
      this.getGuesses()
        .first()
        .isCorrect()
    );
  },

  setDefaultsByQuestionType(type) {
    let question = this.setQuestionType(type)
      .setRubricType(rubricTypeConstants[type])
      .setValidResponse(Map())
      .setOptions(List())
      .setDifficulty(1);

    if (type !== 'fill-in-the-blank') {
      question = question.setDropdowns(null);
    }

    if (type !== 'free-entry') {
      question = question.setInputs(null);
    }

    if (type !== 'two-way') {
      question = question.setRows(null);
    }

    if (type === 'multiple-choice') {
      const mcqOption = Map({
        label: '',
        value: ''
      });
      const defaultOptions = List()
        .setSize(4)
        .map(() => {
          return mcqOption.set('id', uuid());
        });
      question = question.setOptions(defaultOptions);
    }

    return question;
  },

  setRubricThreshold(threshold) {
    threshold = parseFloat(threshold);

    if (isNaN(threshold)) {
      threshold = null;
    }
    const rubric = this.getRubric().set('threshold', threshold);
    return this.setRubric(rubric);
  },

  /**
   * The default threshold is 1%
   */
  getRubricThreshold() {
    const threshold = this.getRubric().get('threshold');
    return Number.isFinite(threshold) ? threshold : 1;
  },

  setRubricType(type) {
    const rubric = this.getRubric().set('type', type);
    return this.setRubric(rubric);
  },

  setValidResponse(validResponse) {
    const rubric = this.getRubric().set('valid_response', validResponse);
    return this.setRubric(rubric);
  },

  /* eslint-disable sort-keys */

  /**
   * Assignment helpers
   */

  hasGuessForAssignment(assignmentId) {
    return !this.getGuessHistory()
      .filter((guess) => guess.getAssignmentId() === assignmentId)
      .isEmpty();
  },

  getGuessFeedbackForAssignment(assignmentId) {
    const guessForAssignment = this.getGuessHistory().find(
      (guess) => guess.getAssignmentId() === assignmentId
    );
    return guessForAssignment ? guessForAssignment.isCorrect() : false;
  },

  isQuestionInActiveAssignment() {
    return this.getIn(['meta', 'active_assignment_question'], false);
  },

  /**
   * Legacy accessors
   */

  getType() {
    return this.getQuestionType();
  },

  getVideoExplanationId() {
    return '';
  }
};

verifyNoCollisions(
  commonQuestionMethods,
  multipleChoiceQuestionExtensions,
  twoWayQuestionExtenions
);

/**
 * Question types without extensions:
 * - Fill in the blank
 * - Snippet Selection
 * - Text highlight
 */

export const baseQuestionExtensions = {
  ...commonQuestionMethods,
  ...multipleChoiceQuestionExtensions,
  ...twoWayQuestionExtenions,
  ...passageCorrectionQuestionExtensions
};
