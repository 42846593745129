import React from 'react';
import {addToast, Button, Card, copyToClipboard, Icon, Input, Text} from '@albert-io/atomic';

import constants from 'client/constants';
import {
  getCanonicalLink,
  getEditorLink
} from 'client/SubjectPracticeView/subjectPracticeView.utils';

import FeatureFlag from 'client/components/FeatureFlag/FeatureFlag.react';

import questionEditorStore from '../QuestionEditor/QuestionEditor.store';

export const QuestionEditorLinks = () => {
  let questionLink = getCanonicalLink({
    activeQuestion: questionEditorStore.getQuestion(),
    activeQuestionSet: questionEditorStore.getQuestionSet()
  });

  const editorLink = getEditorLink({
    activeQuestion: questionEditorStore.getQuestion(),
    activeQuestionSet: questionEditorStore.getQuestionSet()
  });

  if (!questionLink || !editorLink) {
    return null;
  }

  const isPublished =
    questionEditorStore.getQuestionSet().getStatus() === constants.QUESTIONSET_STATUSES.PUBLISHED;

  if (!isPublished) {
    /**
     * When a question is unpublished `getCanonicalLink` will cast `null`
     * to a string, since it doesn't have a question slug yet. Here,
     * we're just replacing that for display.
     */
    questionLink = questionLink.replace('null', '{QUESTION SLUG}');
  }
  const fullPath = constants.ALBERT_FULL_PATH + questionLink;
  const fullEditorPath = constants.ALBERT_FULL_PATH + editorLink;

  const copyFullPath = () =>
    copyToClipboard(fullPath, () => {
      addToast({
        color: 'positive',
        ariaLabel: 'Copied to clipboard',
        message: 'Copied to clipboard.'
      });
    });

  const copyEditorPath = () =>
    copyToClipboard(fullEditorPath, () => {
      addToast({
        color: 'positive',
        ariaLabel: 'Copied to clipboard',
        message: 'Copied to clipboard.'
      });
    });

  return (
    <FeatureFlag name='question_editor_v2'>
      <div className='u-display_flex u-align-items_start u-justify-content_space-between u-gap_space-x6'>
        <div className='u-flex_1 u-display_flex u-align-items_center u-gap_space-x1'>
          <div className='u-display_flex u-flex-direction_column u-gap_space-x1 u-flex-grow_1'>
            <Text as='div'>Public Link</Text>
            <div className='u-display_flex u-gap_space-x1 u-align-items_center'>
              <Input readOnly value={fullPath} />
              <Button variant='outlined' color='primary' onClick={copyFullPath}>
                Copy
              </Button>
            </div>
            <Text as='div' size='s' color='secondary'>
              {isPublished
                ? 'Share a public link with anyone.'
                : 'Once published, a question slug will be assigned and the link will be accessible by anyone.'}
            </Text>
          </div>
        </div>
        <div className='u-flex_1 u-display_flex u-align-items_center u-gap_space-x1'>
          <div className='u-display_flex u-flex-direction_column u-gap_space-x1 u-flex-grow_1'>
            <Text as='div'>Editor Link</Text>
            <div className='u-display_flex u-gap_space-x1 u-align-items_center'>
              <Input readOnly value={fullEditorPath} />
              <Button variant='outlined' color='primary' onClick={copyEditorPath}>
                Copy
              </Button>
            </div>
            <Text as='div' size='s' color='secondary'>
              Share with other authors or reviewers.
            </Text>
          </div>
        </div>
      </div>

      <FeatureFlag.Fallback>
        <div className='u-mar-tb_1'>
          <div>
            <Card roundness='small' paddingLevel={3}>
              <Text as='div' bold>
                Editor Link
              </Text>
              <div className='u-display_flex u-align-items_baseline'>
                <Input
                  readOnly
                  value={fullEditorPath}
                  size='regular'
                  type='text'
                  className='u-mar-t_1 u-mar-r_1'
                />
                <Button variant='outlined' onClick={copyEditorPath}>
                  <Icon icon='copy' className='u-mar-r_1' />
                  Copy
                </Button>
              </div>
              <Text className='u-mar-tb_1' size='s' as='div'>
                Share this link with other authors or reviewers.
              </Text>
              <Text as='div' bold className='u-mar-t_5'>
                Public Link
              </Text>
              <div className='u-display_flex u-align-items_baseline'>
                <Input
                  readOnly
                  value={fullPath}
                  size='regular'
                  type='text'
                  className='u-mar-t_1 u-mar-r_1'
                />
                <Button variant='outlined' onClick={copyFullPath}>
                  <Icon icon='copy' className='u-mar-r_1' />
                  Copy
                </Button>
              </div>
              {isPublished ? (
                <Text className='u-mar-tb_1' size='s' as='div'>
                  This link is only accessible once the question has been published.
                </Text>
              ) : (
                <Text className='u-mar-tb_1' size='s' as='div'>
                  A question slug will only be assigned after publication. This link is only
                  accessible once the question has been published.
                </Text>
              )}
            </Card>
          </div>
        </div>
      </FeatureFlag.Fallback>
    </FeatureFlag>
  );
};
