import React from 'react';
import {Map} from 'immutable';
import {Helmet} from 'react-helmet';
import {title} from 'lib/head';
import {callAction} from 'client/framework';
import TextInput from 'generic/Forms/TextInput/TextInput';

import {addToast} from '@albert-io/atomic';

import {mandarkEndpoint} from '@albert-io/json-api-framework/request/builder/legacy';
import {history} from 'client/history';
import notifier from '@albert-io/notifier';
import appStore from 'client/AppStore';
import sessionStore from 'client/Session/SessionStore';
import {redirect} from 'client/User/UserRedirectUtil';
import {genericMandarkRequest} from 'resources/mandark.resource';
import errorCodes from 'client/errorCodes';
import {redirectToLoginPageFromLocation} from 'client/LogIn/utils';

import {resetPasswordActions} from './ResetPasswordActions';
import {resetPasswordStore} from './ResetPasswordStore';

import './assets/reset-password.scss';

/**
 * @todo [SECURITY] Ensure emailLinkId is NOT logged (Stackdriver, Google Analytics, etc.)
 * @todo Update to non-legacy request builder.
 * @todo Remove `UNSAFE_componentWillMount`
 * @todo Remove `Store` usage (ResetPasswordStore)
 * @todo Use atomic components and reduce custom CSS usage.
 */
export default class ResetPassword extends React.Component {
  /* eslint camelcase: ["error", {allow: ["^UNSAFE_"]}] */
  UNSAFE_componentWillMount() {
    // There is a separate flow for logged in users changing their current password.
    if (sessionStore.hasValidSession()) {
      redirect();
    }

    const {userId, emailLinkId} = appStore.routerProps.location.query;

    if (!userId || !emailLinkId) {
      logger.error('incomplete parameter set for reset form');

      notifier.notify(new Error('Reset Password: incomplete parameter set'), {
        component: 'ResetPassword',
        userId,
        emailLinkId
      });

      history.replaceState(null, '/');
    }

    callAction(resetPasswordActions.RESET_PASSWORD_SET_PARAMETERS, {
      userId,
      emailLinkId
    });
  }

  componentDidUpdate() {
    if (resetPasswordStore.showToast) {
      addToast(resetPasswordStore.successToastContent);
      callAction(resetPasswordActions.RESET_PASSWORD_SET_SHOW_TOAST, false);
    }
  }

  componentWillUnmount() {
    callAction(resetPasswordActions.RESET_PASSWORD_RESET_STORE);
  }

  setInputState(e) {
    const targetInput = e.target;
    const inputState = new Map({
      name: targetInput.name,
      value: targetInput.value,
      isValid: true
    });

    callAction(resetPasswordActions.RESET_PASSWORD_SET_INPUT_STATE, inputState);
  }

  validateInput(e) {
    const targetInput = e.target;
    const inputState = new Map({
      name: targetInput.name
    });

    callAction(resetPasswordActions.RESET_PASSWORD_VALIDATE_INPUT, inputState);
  }

  async sendNewPassword(e) {
    e.preventDefault();
    if (!resetPasswordStore.userInputDataIsValid()) {
      return;
    }

    const {userId, emailLinkId} = appStore.routerProps.location.query;

    const payload = {
      data: {
        attributes: {
          password: resetPasswordStore.getInputValue('password')
        }
      }
    };
    /* eslint-disable camelcase */
    const customQuery = {
      verify_password_reset_link: emailLinkId
    };

    try {
      const query = mandarkEndpoint(['users_v2', userId, 'reset_password'])
        .customQuery(customQuery)
        .done();
      await genericMandarkRequest('patch', query, payload);

      callAction(resetPasswordActions.RESET_PASSWORD_SET_SHOW_TOAST, true);
      redirectToLoginPageFromLocation();
    } catch (err) {
      /**
       * User action is required if a code is invalid. They might need to generate a new one.
       */
      if (err.status === 400) {
        const response = err.response.body.errors[0];
        if (
          response.code === errorCodes.BAD_REQUEST.VALIDATION_ERROR.INVALID_PASSWORD_RESET_LINK ||
          response.code ===
            errorCodes.BAD_REQUEST.VALIDATION_ERROR.PASSWORD_RESET_LINK_NO_LONGER_ACTIVE
        ) {
          addToast({
            color: 'negative',
            title: 'Unable to reset password.',
            message:
              'The code you are using is invalid. Please request another reset password email.'
          });
          history.replaceState(null, '/forgot-password');
          return;
        }

        if (response.code === errorCodes.BAD_REQUEST.VALIDATION_ERROR.NO_PASSWORD_RESET_REQUESTED) {
          // Possible bad actor here
          notifier.notify(err, {
            name: 'Reset Password: reset attemmpt without request',
            component: 'ResetPassword',
            userId,
            emailLinkId
          });
          addToast({
            color: 'negative',
            title: 'Unable to reset password.',
            message: 'We were not able to complete your password change request. Please try again.'
          });
          return;
        }
      }

      notifier.notify(err, {
        name: 'Reset Password: unknown exception raised',
        component: 'ResetPassword',
        userId,
        emailLinkId
      });

      addToast({
        color: 'negative',
        title: 'Unable to reset password.',
        message: 'We were not able to complete your password change request. Please try again.'
      });
    }
  }

  render() {
    const metaDescription = `Set your new password so you can keep learning!`;
    const errorMessage = resetPasswordStore.isFormError ? (
      <div className='a-form-row'>
        <div className='a-form-error-container'>
          <span className='a-form-error-title'>Error:</span>
          {resetPasswordStore.formErrorMessage}
        </div>
      </div>
    ) : null;

    return (
      <div className='reset-password-wrapper'>
        <Helmet>{title('Reset Password | Albert')}</Helmet>
        <div className='reset-password-content'>
          <div className='reset-password-meta'>
            <h1 className='reset-password-meta-title'>Reset Password</h1>
            <p className='reset-password-meta-description'>{metaDescription}</p>
          </div>

          <div className='a-form-wrapper'>
            <form className='a-form'>
              {errorMessage}
              <div className='a-form-row'>
                <TextInput
                  name='password'
                  label='New Password'
                  type='password'
                  autoComplete='new-password'
                  required
                  value={resetPasswordStore.getInputValue('password')}
                  error={!resetPasswordStore.isInputValid('password')}
                  errorMessage='Passwords must have at least 8 characters and at least one number or special character.'
                  onFocusValidation
                  onChange={this.setInputState}
                  onBlur={this.validateInput}
                />
              </div>
              <div className='a-form-row'>
                <button
                  className='a-form-submit-button'
                  type='submit'
                  onClick={this.sendNewPassword}
                >
                  Set New Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
