import React from 'react';
import topicSummaryStore from './TopicSummary.store';
import LoadingIndicator from 'generic/LoadingIndicator.react';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import './topic-summary.scss';

export default class TopicSummary extends React.Component {
  handlePracticeButtonClick() {
    topicSummaryStore.pushStateToQuestionPath();
  }

  render() {
    if (!topicSummaryStore.getGuideLevelQuery().isResourcePopulated()) {
      return <LoadingIndicator type='inline' />;
    }
    const guideLevel = topicSummaryStore.getGuideLevel();
    // If no image prop, default to super generic image.
    const imageUrl = guideLevel.getThemeImg();
    const summary = guideLevel.getSummary() ? guideLevel.getSummary() : guideLevel.getDescription();
    return (
      <div className='topic-summary card'>
        <div className='topic-summary__summary-wrapper'>
          <img src={imageUrl} className='topic-summary__image' alt='' />
          <div className='topic-summary__text-wrapper'>
            <h1>
              <MarkdownRendererV2 text={guideLevel.getName()} className='topic-summary__title' />
            </h1>
            <MarkdownRendererV2 text={summary} />
          </div>
        </div>
        <button
          className='topic-summary__button button button--green'
          onClick={this.handlePracticeButtonClick}
        >
          Practice <span className='fa fa-caret-right' />
        </button>
      </div>
    );
  }
}
