import React from 'react';
import {callAction, getStoreByName} from 'client/framework';
import IEContentWrapper from 'client/InteractionEngineV2/shared/IEContentWrapper/IEContentWrapper.react';
import ActiveQuestion from 'client/InteractionEngineV2/shared/ActiveQuestion/ActiveQuestion.react';
import interactionEngineStore from 'client/InteractionEngineV2/InteractionEngineStore';
import TeacherAssignmentV2IESubheader from './TeacherAssignmentV2IESubheader/TeacherAssignmentV2IESubheader.react';
import TeacherAssignmentV2IEQuestionsList from './TeacherAssignmentV2IEQuestionsList/TeacherAssignmentV2IEQuestionsList.react';
import teacherAssignmentV2IEQuestionsListStore from './TeacherAssignmentV2IEQuestionsList/TeacherAssignmentV2IEQuestionsList.store';
import teacherAssignmentV2IEActions from './TeacherAssignmentV2IE.actions';
import teacherAssignmentV2IEStore from './TeacherAssignmentV2IE.store';
import TeacherAssignmentV2IEDetails from './TeacherAssignmentV2IEDetails/TeacherAssignmentV2IEDetails.react';
import questionsListActions from 'client/InteractionEngineV2/shared/QuestionsList/QuestionsListActions';
import appStore from 'client/AppStore';
import 'client/InteractionEngineV2/shared/AssignmentsV2SharedStyles/assignment-v2-ie.scss';

export default class TeacherAssignmentV2IE extends React.Component {
  UNSAFE_componentWillMount() {
    this.loadInitialQuestion();
  }

  componentWillUnmount() {
    callAction(teacherAssignmentV2IEActions.RESET_STORE);
  }

  loadInitialQuestion() {
    const questionIdToLoad = appStore.routerProps.location.query.questionId;
    if (!questionIdToLoad) {
      return;
    }
    teacherAssignmentV2IEStore
      .getAssignmentQuery()
      .getResourcePromise()
      .then((assignment) => {
        const matchingSection = assignment.getSections().find((section) => {
          return section
            .getAllQuestions()
            .some((question) => question.getId() === questionIdToLoad);
        });
        if (!matchingSection) {
          return;
        }
        let matchingQuestion;
        const matchingSet = matchingSection.getQuestionSets().find((questionSet) => {
          const foundQuestion = questionSet
            .getQuestions()
            .find((question) => question.getId() === questionIdToLoad);
          if (foundQuestion) {
            matchingQuestion = foundQuestion;
          }
          return Boolean(foundQuestion);
        });
        callAction(
          teacherAssignmentV2IEActions.SET_SELECTED_ACTIVE_SECTION_ID,
          matchingSection.getId()
        );
        callAction(questionsListActions.SET_ACTIVE_QUESTION, matchingQuestion);
        callAction(questionsListActions.SET_ACTIVE_QUESTION_SET, matchingSet);
        callAction(teacherAssignmentV2IEActions.SET_IS_VIEWING_STAGING_DETAILS, false);
      });
  }

  getQuestionArea(isReady, assignment) {
    if (teacherAssignmentV2IEStore.isViewingStagingDetails()) {
      return <TeacherAssignmentV2IEDetails isReady={isReady} assignment={assignment} />;
    }
    const activeQuestionSet = teacherAssignmentV2IEQuestionsListStore.activeQuestionSet;
    const activeQuestion = teacherAssignmentV2IEQuestionsListStore.activeQuestion;
    const activeQuestionId = activeQuestion.getId();
    const questionStoreName = interactionEngineStore.getStoreNameForQuestionId(activeQuestionId);
    const questionStore = getStoreByName(questionStoreName);
    if (!questionStore) {
      return null;
    }
    const guessToLoad = questionStore.buildCorrectGuess();
    return (
      <ActiveQuestion
        isPracticeExam
        shouldShowExplanation
        guessToLoad={guessToLoad}
        assignmentId={teacherAssignmentV2IEStore.getAssignmentId()}
        location={appStore.routerProps.location}
        question={activeQuestion}
        questionSet={activeQuestionSet}
        questionStoreName={questionStoreName}
        showPrevButton={teacherAssignmentV2IEQuestionsListStore.hasPreviousQuestion(
          activeQuestionId
        )}
        showNextButton={teacherAssignmentV2IEQuestionsListStore.hasNextQuestion(activeQuestionId)}
      />
    );
  }

  render() {
    const query = teacherAssignmentV2IEStore.getAssignmentQuery();
    const isReady = query.isResourcePopulated();
    const assignment = query.getResource();
    const activeSectionName = teacherAssignmentV2IEStore.getActiveSection()
      ? teacherAssignmentV2IEStore.getActiveSection().getTitle()
      : '';
    return (
      <IEContentWrapper
        subheader={
          <TeacherAssignmentV2IESubheader
            isReady={isReady}
            assignment={assignment}
            classroom={teacherAssignmentV2IEStore
              .getAssignment()
              .getClassrooms()
              .first()}
            activeSection={activeSectionName}
          />
        }
        questionsList={<TeacherAssignmentV2IEQuestionsList isReady={isReady} />}
        questionArea={this.getQuestionArea(isReady, assignment)}
      />
    );
  }
}
