import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import {setUpStore} from 'client/framework';
import FreeEntryQuestionStore from './FreeEntryQuestion.store';
import FreeEntryQuestionResponseStatsPercentageIndicator from './FreeEntryQuestionResponseStatsPercentageIndicator.react';
import FreeEntryQuestionResponseStatsTopFiveAttempts from './FreeEntryQuestionResponseStatsTopFiveAttempts.react';

export default class FreeEntryQuestionResponseStats extends React.Component {
  static propTypes = {
    previewMode: PropTypes.bool,
    question: ImmutablePropTypes.record,
    selectedAnswer: ImmutablePropTypes.map,
    storeName: PropTypes.string
  };

  getStore() {
    return setUpStore(
      FreeEntryQuestionStore,
      this.props.storeName,
      this.props.question.getId(),
      this.props.question
    );
  }

  getStatsforInput(inputId, selectedAnswer, index) {
    const question = this.props.question;
    /**
     * @todo: Consolidate this logic into a parent component that manages rendering of How Others Answered.
     */
    if (question.getTotalAnswerCount() < 50) {
      return null;
    }
    let inputValue = null;
    let isCorrectAnswer = null;
    let topFiveAttemptsComponent = null;
    const topAnswers = question.getTopAnswersForInputSorted(inputId);
    const totalAttempts = question.getTotalAnswerCountForInput(inputId);
    const answerPercentage = question.getPercentageCorrectForInput(inputId);

    if (selectedAnswer && !this.props.previewMode) {
      inputValue = selectedAnswer.get(inputId);
      isCorrectAnswer = this.getStore().isGuessCorrect();
    }

    const itemClasses = classnames('question-response-stats__item', {
      correct: this.props.previewMode || isCorrectAnswer,
      incorrect: !this.props.previewMode && !isCorrectAnswer
    });

    if (totalAttempts >= 5) {
      topFiveAttemptsComponent = (
        <FreeEntryQuestionResponseStatsTopFiveAttempts
          key={`stats-top-five-${inputId}`}
          inputId={inputId}
          topAnswers={topAnswers}
          totalAttempts={totalAttempts}
          storeName={this.props.storeName}
          usersGuess={inputValue}
        />
      );
    }

    return (
      <div key={index}>
        <FreeEntryQuestionResponseStatsPercentageIndicator
          className={itemClasses}
          index={index}
          answerPercentage={answerPercentage}
        />
        {topFiveAttemptsComponent}
      </div>
    );
  }

  render() {
    const inputs = this.props.question.getInputs();
    const selectedAnswer = this.props.selectedAnswer;
    const responseStatsBody = inputs.map((input, index) => {
      return this.getStatsforInput(input.getId(), selectedAnswer, index);
    });

    return (
      <div className='question-response-stats'>
        <div className='question-response-stats__header'>How Others Answered</div>
        <div className='question-response-stats__body'>{responseStatsBody}</div>
      </div>
    );
  }
}
