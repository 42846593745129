import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {callAction} from 'client/framework';
import {Footer} from '@albert-io/atomic';
import appStore from 'client/AppStore';
import appActions from 'client/AppActions';
import interactionEngineStore from 'client/InteractionEngineV2/InteractionEngineStore';

export default class IEContentWrapper extends React.Component {
  static propTypes = {
    subheader: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    modals: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
    questionsList: PropTypes.element,
    questionArea: PropTypes.element,
    statsArea: PropTypes.element
  };

  constructor(props) {
    super(props);
    this.ieWrapperNode = null;
    this.IENavigationWrapperNode = null;
  }

  componentDidMount() {
    this.setPrimaryFooterDisplay(false);
  }

  componentWillUnmount() {
    this.setPrimaryFooterDisplay(true);
  }

  setPrimaryFooterDisplay(shouldDisplay) {
    callAction(appActions.SET_FOOTER_DISPLAY, shouldDisplay);
  }

  render() {
    const wrapperClassNames = classnames('interaction-engine-v2', {
      'interaction-engine-v2--mobile': appStore.isMobileView(),
      'interaction-engine-v2--allow-highlight': interactionEngineStore.isPracticeExamEditorSession
    });
    const activeQuestionAreaClassNames = classnames('interaction-engine-v2__active-question-area', {
      'interaction-engine-v2__active-question-area--in-view':
        interactionEngineStore.isMobileQuestionAreaInView
    });

    return (
      <div className={wrapperClassNames}>
        {this.props.subheader}
        <div
          className='interaction-engine-v2__wrapper'
          ref={(node) => {
            this.ieWrapperNode = node;
          }}
        >
          {this.props.questionsList}
          <div className={activeQuestionAreaClassNames}>
            <div className='interaction-engine-v2__active-question-with-stats-area'>
              {this.props.questionArea}
              {this.props.statsArea}
            </div>
            <Footer className='interaction-engine__footer' />
          </div>
        </div>
        {this.props.modals}
      </div>
    );
  }
}
