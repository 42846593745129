import {Editor, Node, Text, Element as SlateElement, Range, Path, Transforms} from 'slate';

import {CustomText, CustomElement} from '../slate.types';

import {isMark, unwrapLink, toggleBlock, handleIndent} from './editor.utils';

import {isStartOfLine} from './keyboardHandlers';

export const withLinks = (editor: Editor) => {
  const {isInline} = editor;

  editor.isInline = (element) => element.type === 'link' || isInline(element);

  return editor;
};

export const withCode = (editor: Editor) => {
  const {normalizeNode} = editor;
  // https://docs.slatejs.org/concepts/11-normalizing
  editor.normalizeNode = (entry) => {
    const [node, path] = entry;

    if (Text.isText(node)) {
      if ((node as CustomText).code) {
        Object.keys(node).forEach((item: any) => {
          if (isMark(item) && item !== 'code') {
            Editor.removeMark(editor, item);
          }
        });
        return;
      }
    }

    if (SlateElement.isElement(node) && node.type === 'link') {
      const childrenArray = Array.from(Node.children(editor, path));
      for (const [child] of childrenArray) {
        if ((child as CustomText).code) {
          unwrapLink(editor);
          return;
        }
      }
    }
    // Fall back to the original `normalizeNode` to enforce other constraints.
    normalizeNode(entry);
  };
  return editor;
};

export const withBackspaceTransform = (editor: Editor) => {
  const {deleteBackward} = editor;

  editor.deleteBackward = (unit) => {
    const {selection} = editor;

    if (selection && isStartOfLine(selection)) {
      const node = editor.children[selection.anchor.path[0]] as CustomElement;
      if (node.type === 'numbered-list' || node.type === 'bulleted-list') {
        toggleBlock(editor, 'list-item');
      } else if (node.type === 'paragraph' && !!node.indent) {
        handleIndent(editor, 'outdent');
      } else {
        deleteBackward(unit);
      }
    } else {
      deleteBackward(unit);
    }
  };

  return editor;
};

/**
 * Extends editor to have image support
 *
 * @param {Editor} editor Slate js Editor
 * @returns {Editor} editor with image support
 */
export const withImages = (editor: Editor) => {
  const {isVoid} = editor;

  editor.isVoid = (element) => {
    return element.type === 'image' || element.type === 'deleted-image' ? true : isVoid(element);
  };

  return editor;
};

/**
 * Extends editor to have audio support
 *
 * @param {Editor} editor Slate js Editor
 * @returns {Editor} editor with audio support
 */
export const withAudioChips = (editor: Editor) => {
  const {isVoid} = editor;
  editor.isVoid = (element) => {
    return element.type === 'audio-chip' ||
      element.type === 'deleted-audio-chip' ||
      element.type === 'uploading-audio-chip'
      ? true
      : isVoid(element);
  };

  return editor;
};

/**
 * see: https://github.com/ianstormtaylor/slate/issues/3991#issuecomment-832160304
 */

/* eslint-disable consistent-return */

export const withCorrectVoidBehavior = (editor: Editor) => {
  const {deleteBackward} = editor;

  // if prev node is a void node, remove the current node and select the void node
  editor.deleteBackward = (unit) => {
    if (
      !editor.selection ||
      !Range.isCollapsed(editor.selection) ||
      editor.selection.anchor.offset !== 0
    ) {
      return deleteBackward(unit);
    }

    const parentPath = Path.parent(editor.selection.anchor.path);
    const parentNode = Node.get(editor, parentPath);
    const parentIsEmpty = Node.string(parentNode).length === 0;

    if (parentIsEmpty && Path.hasPrevious(parentPath)) {
      const prevNodePath = Path.previous(parentPath);
      const prevNode = Node.get(editor, prevNodePath);
      if (Editor.isVoid(editor, prevNode)) {
        return Transforms.removeNodes(editor);
      }
    }

    deleteBackward(unit);
  };

  return editor;
};
