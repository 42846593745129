/**
 * # Input
 *
 * Uses an implementation of `WithHoverAndFocus` that extends functionality of an `input` element
 *
 * ## Props
 *
 * - `as?: React.ElementType`: The HTML element or custom React component to be rendered as the input element. Defaults to `'input'`.
 * - `className?: string`: Additional CSS class names to be applied to the input element.
 * - `onBlur?: (e?: React.FocusEvent<HTMLElement>) => void`: Event handler for the `blur` event.
 * - `onFocus?: () => {}`: Event handler for the `focus` event.
 * - `onMouseEnter?: () => {}`: Event handler for the `mouseenter` event.
 * - `onMouseLeave?: () => {}`: Event handler for the `mouseleave` event.
 * - `size?: 's' | 'regular' | 'l'`: The size of the input. Defaults to `'regular'`.
 * - `status?: 'negative' | 'positive'`: Indicates whether the input is in a success state or an error state.
 * - `defaultValue?: string`: The default value of the input.
 * - `placeholder?: string`: The placeholder text for the input.
 * - `readOnly?: boolean`: Indicates whether the input is read-only.
 * - `type?: string`: The type of the input element.
 * - `children?: React.ReactNode`: The content to be rendered inside the input element.
 * - `value?: any`: The value of the input element.
 * - `error?: boolean`: Indicates whether the input has an error. Use 'status' most of the time instead.
 * - `success?: boolean`: Indicates whether the input is in a success state. Use 'status' most of the time instead.
 *
 * ## Usage
 *
 * ```tsx
 * import React from 'react';
 * import Input from './Input';
 *
 * const Example = () => {
 *   return (
 *     <Input
 *       as="input"
 *       className="custom-input"
 *       onBlur={() => console.log('Blur')}
 *       onFocus={() => console.log('Focus')}
 *       onMouseEnter={() => console.log('Mouse Enter')}
 *       onMouseLeave={() => console.log('Mouse Leave')}
 *       size="regular"
 *       status="positive"
 *       defaultValue="Default Value"
 *       placeholder="Enter text"
 *       readOnly={false}
 *       type="text"
 *       value="Input Value"
 *     >
 *       Example Input
 *     </Input>
 *   );
 * };
 *
 * export default Example;
 * ```
 */

import React, {forwardRef, InputHTMLAttributes} from 'react';
import classnames from 'classnames';
import makeDataProps from 'lib/makeDataProps';
import WithHoverAndFocus from '@albert-io/atomic/helpers/WithHoverAndFocus/WithHoverAndFocus.react';
import './input.scss';

export interface Props
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'>,
    PropsWithChildrenOptional,
    PropsWithClassNameOptional {
  as?: React.ElementType;
  onBlur?: (e: React.FocusEvent<HTMLElement>) => void;
  onFocus?: () => {};
  onMouseEnter?: () => {};
  onMouseLeave?: () => {};
  size?: 's' | 'regular' | 'l';
  status?: 'negative' | 'positive';
  defaultValue?: string;
  placeholder?: string;
  readOnly?: boolean;
  type?: string;
  value?: any;
  error?: boolean;
  success?: boolean;
}

const determineStatusFromErrorSuccess = ({
  error,
  success
}: {
  error?: boolean;
  success?: boolean;
}) => {
  if (error) {
    return 'negative';
  }
  if (success) {
    return 'positive';
  }
  return undefined;
};

export const Input = forwardRef(
  (
    {
      as: WrappedComponent = 'input',
      children,
      className,
      onBlur,
      onFocus,
      onMouseEnter,
      onMouseLeave,
      size = 'regular',
      readOnly,
      ...rest
    }: Props,
    ref
  ) => {
    let {status} = rest;
    const dataProps = makeDataProps({...rest});
    const passthroughProps = {...rest, ...dataProps};

    const {error, success} = passthroughProps;

    if (error || success) {
      status = determineStatusFromErrorSuccess({error, success});
    }

    return (
      <WithHoverAndFocus
        customOnBlur={onBlur}
        customOnFocus={onFocus}
        customOnMouseEnter={onMouseEnter}
        customOnMouseLeave={onMouseLeave}
      >
        {({hasFocus, hasHover, ...handlers}) => {
          const classNames = classnames('a-input', className, {
            'a-input--error': status === 'negative',
            'a-input--focus': hasFocus,
            'a-input--hover': hasHover,
            [`a-input--${size}`]: size,
            'a-input--success': status === 'positive',
            'a-input--read-only': readOnly
          });
          return (
            <WrappedComponent
              ref={ref}
              className={classNames}
              readOnly={readOnly}
              {...passthroughProps}
              {...handlers}
            >
              {children}
            </WrappedComponent>
          );
        }}
      </WithHoverAndFocus>
    );
  }
);

export default Input;
