import {fromJS, Set} from 'immutable';
import QuestionTypeStore from 'client/QuestionTypes/common/V2/QuestionType.store';
import twoWayQuestionActions from './TwoWayQuestion.actions';
import {activeQuestionActions} from 'client/InteractionEngineV2/shared/ActiveQuestion/ActiveQuestionActions';

export default class TwoWayQuestionStore extends QuestionTypeStore {
  constructor(name, questionId, question) {
    super(name);

    this.initialData = fromJS({
      questionId: questionId,
      checkedColumns: [], // converted to Immutable.Set
      shouldShowExplanation: false
    });

    this.setInitialData(this.initialData);
    this.writeData('__question', question);
    this.writeData('checkedColumns', new Set());

    this.handleTargeted(activeQuestionActions.ACTIVE_QUESTION_LOAD_GUESS, this._loadGuess);
    this.handleTargeted(twoWayQuestionActions.SELECT_COLUMN, this._selectColumn);
  }

  _selectColumn(columnId) {
    const checkedColumns = this.isSelectedColumn(columnId)
      ? this.readData('checkedColumns').delete(columnId)
      : this.readData('checkedColumns').add(columnId);

    this.writeData('checkedColumns', checkedColumns);
  }

  _loadGuess(guess) {
    // Parent store needs points data from guess and needs to register this question has a guess
    super._loadGuess(guess);

    const checkedColumns = new Set(guess.get('content').keys());
    this.writeData('checkedColumns', checkedColumns);
  }

  _clearGuess() {
    // Need to call this on the parent class
    super._clearGuess();
    this.writeData('checkedColumns', new Set());
  }

  buildGuessContent() {
    const selectedOptions = this.readData('checkedColumns');
    return selectedOptions.reduce((r, optionId) => {
      r[optionId] = true;
      return r;
    }, {});
  }

  isSelectedColumn(id) {
    return this.readData('checkedColumns').includes(id);
  }

  isCorrectColumn(id) {
    return this.correctAnswer.has(id);
  }

  get correctAnswer() {
    return this.validResponse
      .filter((value) => value)
      .keySeq()
      .toSet();
  }

  get selectedAnswer() {
    return this.readData('checkedColumns');
  }
}
