import React, {useEffect} from 'react';
import {TransformComponent, useControls} from 'react-zoom-pan-pinch';
import {SupplementModelV1} from '@albert-io/models';
import Imgix from 'client/generic/Imgix.react';

interface ImageWrapperProps extends PropsWithClassNameOptional {
  supplement: SupplementModelV1;
  shouldCenter?: boolean;
}

export const ImageWrapper = ({supplement, className, shouldCenter}: ImageWrapperProps) => {
  const {centerView} = useControls();
  useEffect(() => {
    if (shouldCenter) {
      centerView();
    }
  }, [shouldCenter, centerView]);
  return (
    <TransformComponent
      wrapperStyle={{
        width: '100%',
        height: '100%'
      }}
      contentStyle={{
        width: '100%',
        height: '100%'
      }}
    >
      <Imgix className={className} supplement={supplement} />
    </TransformComponent>
  );
};
