import React from 'react';
import {Link} from 'react-router';
import PropTypes from 'prop-types';
import {
  Anchor,
  Button,
  ButtonGroup,
  Dialogue,
  Icon,
  Text,
  Modal,
  LoadingSpinner
} from '@albert-io/atomic';
import classnames from 'classnames';
import sessionStore from 'client/Session/SessionStore';
import {SubjectModelV2} from 'resources/augmented/Subject/SubjectModel.v2';
import {doesUserBelongToAnyLicensedClassrooms} from 'lib/UserAccessUtil';

import appStore from 'client/AppStore';

import benefitsImage from './ImageAssets/benefits.svg';
import homeImage from './ImageAssets/home.svg';
import chalkboardImage from './ImageAssets/chalkboard.svg';

import './upgrade-subject-modal.scss';

/*
Below are all of the cases of the modal state mapped out to the user types.
The object form was the easiest way to keep track of each state and is easy to refer back to
when editing the file or needing to change copy for a specific user scenario.

{
  unlicensedTeacherRetailSubject: {
    heading: 'Purchase a license to unlock the power of Albert for your whole school',
    image: 'stacked books',
    body: [
      'Access to all subjects, for all students and teachers at your school',
      'The ability to assign any question in any subject',
      'Powerful data reports at the school, class and student level'
    ],
    buttons: ['upgrade subject', 'learn about license']
  },
  unlicensedTeacherNonRetailSubject: {
    heading: 'Purchase a license to unlock the power of Albert for your whole school',
    image: 'stacked books',
    body: [
      'Access to all subjects, for all students and teachers at your school',
      'The ability to assign any question in any subject',
      'Powerful data reports at the school, class and student level'
    ],
    buttons: ['learn about license']
  },
  retailStudentRetailSubject: {
    heading: 'Upgrade now and get everything you need to prepare for',
    image: 'backpack',
    body: [
      'Access To {x more}/all practice questions',
      'Access To {x more}/all free response examples',
      'Detailed explanations for every question',
      'Unlimited attempts per question'
    ],
    buttons: ['Upgrade subject']
  },
  retailStudentNonRetailSubject: {
    heading: '[Subject] is only available to teachers',
    image: 'chalkboard',
    body: ['In order to complete this assessment, you must belong to a class. This is not available to individual subscribers.'],
    buttons: ['none']
  },
  licensedStudentViewingAssessment: {
    heading: 'Ask your teacher to grant you access to',
    image: 'backpack',
    body: ['You can access this assessment in the Assessments tab of your class! If you don&apos;t see it there, make sure your teacher has assigned it to you.'],
    buttons: ['none']
  },
  licensedStudentViewingAssignmentWithClosedIP: {
    heading: null,
    image: ['backpack'],
    body: ['Your teacher has restricted access to this subject'],
    buttons: ['none']
    subtext: ['Reach out to your teacher if you should have access to this subject.']
  }
  licensedStudentRetailSubject: {
    heading: 'Want to practice [Subject] at your own pace?',
    image: 'backpack',
    body: ['Ask your teacher to grant you independent practice access'],
    buttons: ['none']
  }
}
*/

export default class UpgradeSubjectModal extends React.Component {
  static propTypes = {
    /** Subject for the current active question set */
    subject: PropTypes.instanceOf(SubjectModelV2),
    /** Modal close function */
    handleClose: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      isNotRetailSubject: !props.subject?.getSubscriptionsPrices().first()
    };
  }

  isLicensedStudentViewingAssessment() {
    return (
      sessionStore.isStudent() &&
      doesUserBelongToAnyLicensedClassrooms().value &&
      appStore.routerProps.location.pathname.includes('/assessments')
    );
  }

  isLicensedStudentViewingClosedIPSubject = () => {
    return (
      sessionStore.isStudent() &&
      doesUserBelongToAnyLicensedClassrooms(false).value &&
      !this.props.subject.doesUserHaveAccess()
    );
  };

  isLoggedInTeacherWithLicense() {
    return sessionStore.isTeacher() && doesUserBelongToAnyLicensedClassrooms().value;
  }

  generateSellingPoint = ({total, content}) => {
    if (total === 0) {
      return null;
    }

    return (
      <li className='upgrade-subject-modal__selling-point-wrapper'>
        <Icon size='standalone' icon='check' color='positive' />
        <Text color='secondary' className='upgrade-subject-modal__selling-point'>
          {content}
        </Text>
      </li>
    );
  };

  generateCallToActionHeader() {
    if (this.isLicensedStudentViewingClosedIPSubject()) {
      return null;
    }
    const {subject} = this.props;
    const titleString = 'Purchase a license to unlock the power of Albert for your whole school';
    if (!subject) {
      return (
        <Text bold as='div' className='upgrade-subject-modal__title' color='secondary'>
          {titleString}
        </Text>
      );
    }
    const subjectName = <Text>{subject.get('name')}</Text>;
    const isLicensed = doesUserBelongToAnyLicensedClassrooms(false).value;
    if (this.state.isNotRetailSubject && !sessionStore.isTeacher() && !isLicensed) {
      return (
        <Text bold as='div' className='upgrade-subject-modal__title' color='secondary'>
          {subjectName} is only available to teachers
        </Text>
      );
    }
    if (!sessionStore.isTeacher()) {
      return isLicensed ? (
        <Text bold as='div' className='upgrade-subject-modal__title' color='secondary'>
          Want to practice {subjectName} at your own pace?
        </Text>
      ) : (
        <Text bold as='div' className='upgrade-subject-modal__title' color='secondary'>
          Upgrade now and get everything you need to prepare for {subjectName}
        </Text>
      );
    }
    return (
      <Text bold as='div' className='upgrade-subject-modal__title' color='secondary'>
        {titleString}
      </Text>
    );
  }

  generateSellingPoints() {
    const {subject} = this.props;
    const questionsTotal = subject?.getMeta()?.getCountOfQuestions();
    const freeResponseTotal = subject?.getMeta()?.getCountOfFreeResponseQuestions();
    if (sessionStore.isTeacher() || !subject) {
      return (
        <ul className='upgrade-subject-modal__selling-point-list'>
          {this.generateSellingPoint({
            content: `Access to all subjects, for all students and teachers at your school`
          })}
          {this.generateSellingPoint({
            content: `The ability to assign any question in any subject`
          })}
          {this.generateSellingPoint({
            content: `Powerful data reports at the school, class and student level`
          })}
          {this.generateSellingPoint({
            content: `Access to search results`
          })}
        </ul>
      );
    }
    if (this.isLicensedStudentViewingAssessment()) {
      return (
        <Text as='p' className='u-text-align_center'>
          Assessments must be assigned by your teacher. You can view assigned assessments in the
          Assessments tab of your class.
        </Text>
      );
    }
    if (this.isLicensedStudentViewingClosedIPSubject()) {
      return (
        <>
          <Text as='p' className='u-text-align_center'>
            Your teacher has restricted access to this subject.
          </Text>
          <Text size='s' as='p' className='u-text-align_center u-mar-t_4' color='secondary'>
            Reach out to your teacher if you should have access to this subject.
          </Text>
        </>
      );
    }
    if (sessionStore.isStudent() && doesUserBelongToAnyLicensedClassrooms(false).value) {
      return (
        <>
          <Text as='p' className='u-text-align_center'>
            Ask your teacher to grant you independent practice access.
          </Text>
          <Text size='s' as='p' className='u-text-align_center u-mar-t_4' color='secondary'>
            If you purchased a subscription and have a question about refunds, please send an email
            to{' '}
            <Anchor
              underlined
              href='mailto:hello@albert.io?subject=Refund%20for%20locked%20questions'
            >
              hello@albert.io
            </Anchor>{' '}
            using the subject line &quot;Refund for locked questions&quot;.
          </Text>
        </>
      );
    }
    if (sessionStore.isStudent() && this.state.isNotRetailSubject) {
      return (
        <Text as='p' className='u-text-align_center'>
          In order to complete this assessment, you must belong to a class. This is not available to
          individual subscribers.
        </Text>
      );
    }
    if (sessionStore.isTeacher()) {
      return (
        <ul className='upgrade-subject-modal__selling-point-list'>
          {this.generateSellingPoint({
            content: ` Access to all subjects, for all students and teachers at your school`
          })}
          {this.generateSellingPoint({
            content: `The ability to assign any question in any subject`
          })}
          {this.generateSellingPoint({
            content: `Powerful data reports at the school, class and student level`
          })}
        </ul>
      );
    }
    return (
      <ul className='upgrade-subject-modal__selling-point-list'>
        {this.generateSellingPoint({
          total: questionsTotal,
          content: `Access to ${
            questionsTotal ? `${questionsTotal} more` : 'all'
          }  practice questions`
        })}
        {this.generateSellingPoint({
          total: freeResponseTotal,
          content: `Access to ${
            freeResponseTotal ? `${freeResponseTotal} more` : 'all'
          } free response examples`
        })}
        {this.generateSellingPoint({
          content: `Detailed explanations for every question`
        })}
        {this.generateSellingPoint({
          content: `Unlimited attempts per question`
        })}
        {this.generateSellingPoint({
          content: `Access to search results`
        })}
      </ul>
    );
  }

  getMarketplaceLink() {
    const {subject} = this.props;
    const domain = subject.getDomainSlug();
    const group = subject.getGroupSlug();
    /**
     * @todo: Remove `&subjectId=` once we update the subject resources
     * with their new domain and group mappings. Until then, we must
     * send the subject ID in order to initialize the course library
     * with the correct filters applied.
     */
    return `/marketplace/subjects?domain=${domain}&group=${group}&subject=${subject.getUrlSlug()}&subjectId=${subject.getId()}`; // eslint-disable-line
  }

  generateButtonLink() {
    if (sessionStore.isTeacher() || this.state.isNotRetailSubject) {
      return (
        <Button
          as='a'
          href='/pricing-schools'
          onClick={this.props.handleClose}
          className='upgrade-subject-modal__button'
        >
          Learn about licenses
        </Button>
      );
    }

    if (!this.state.isNotRetailSubject) {
      return (
        <Button
          as={Link}
          to={this.getMarketplaceLink()}
          onClick={this.props.handleClose}
          className='upgrade-subject-modal__button'
        >
          Upgrade subject
        </Button>
      );
    }

    return null;
  }

  generateSubscriptionLink() {
    if (this.state.isNotRetailSubject) {
      return null;
    }
    return (
      <Button
        variant='text'
        as={Link}
        className='upgrade-subject-modal__button'
        to={this.getMarketplaceLink()}
        onClick={this.props.handleClose}
      >
        Purchase individual subscription
      </Button>
    );
  }

  getImage() {
    let image = homeImage;
    if (sessionStore.isTeacher()) {
      image = benefitsImage;
    }
    if (sessionStore.isStudent() && this.state.isNotRetailSubject) {
      image = chalkboardImage;
    }
    return image;
  }

  render() {
    return (
      <Modal
        focusCloseButton={false}
        ariaLabel='Upgrade Subject Modal'
        handleClose={this.props.handleClose}
      >
        {({modalContentStyle}) => {
          return (
            <Dialogue
              inModal
              className={classnames([modalContentStyle, 'upgrade-subject-modal'])}
              headingClassName='upgrade-subject-modal__header'
              handleClose={this.props.handleClose}
              hideCloseBtn={!this.props.handleClose}
            >
              <Dialogue.Body>
                {!doesUserBelongToAnyLicensedClassrooms().isReady ? (
                  <LoadingSpinner size={3} />
                ) : (
                  <>
                    {this.generateCallToActionHeader()}
                    <img alt='' className='upgrade-subject-modal__img' src={this.getImage()} />
                    {this.generateSellingPoints()}
                    {!doesUserBelongToAnyLicensedClassrooms(false).value && (
                      <ButtonGroup direction='column' align='center'>
                        {this.generateButtonLink()}
                        {this.generateSubscriptionLink()}
                      </ButtonGroup>
                    )}
                  </>
                )}
              </Dialogue.Body>
            </Dialogue>
          );
        }}
      </Modal>
    );
  }
}
