import React, {useCallback, useState} from 'react';
import {List} from 'immutable';
import {callAction} from 'client/framework';
import {resource} from '@albert-io/json-api-framework/request/builder';
import {StandardChip} from 'client/QuestionTypes/common/V2/StandardChip.react';

import {
  Dropdown,
  DropdownItem,
  CheckboxChip,
  Text,
  Button,
  Icon,
  IconButton
} from '@albert-io/atomic';

import FeatureFlag from 'client/components/FeatureFlag/FeatureFlag.react';

import questionEditorActions from '../QuestionEditor/QuestionEditor.actions';

import {QuestionEditorIcon} from '../QuestionEditorIcon/QuestionEditorIcon';

import StandardSelectModal from './StandardSelectModal.react';

import './standard-search.scss';

interface Props {
  subjectId: string;
  standards: List<any>;
  disabled: boolean;
}

const StandardSearch = ({subjectId, standards, disabled}: Props) => {
  const [showAddStandardsModal, setShowAddStandardsModal] = useState(false);

  const getSearchStandardsByNameQuery = useCallback(
    (searchString) => {
      return resource('authoring_standards_v1')
        .mandarkEndpoint(['authoring_standards_v1'])
        .filter({
          authoring_standard_set_v1: {
            authoring_subjects_v1: subjectId
          }
        })
        .filter(
          {
            any_of: [
              {
                title: {
                  case_insensitive_substring: searchString
                }
              }
            ]
          },
          Boolean(searchString)
        )
        .sort('title');
    },
    [subjectId]
  );

  const handleChipClick = useCallback(
    (e, standardToRemove) => {
      const filteredStandards = standards.filter(
        (standard) => standard.getId() !== standardToRemove.getId()
      );
      callAction(questionEditorActions.UPDATE_QUESTION_STANDARDS, filteredStandards);
    },
    [standards]
  );

  const handleRemoveAllStandardsClick = useCallback(() => {
    callAction(questionEditorActions.UPDATE_QUESTION_STANDARDS, List([]));
  }, []);

  return (
    <FeatureFlag name='question_editor_v2'>
      <StandardSearchV2Presentation
        disabled={disabled}
        standards={standards}
        setShowAddStandardsModal={setShowAddStandardsModal}
        handleChipClick={handleChipClick}
        handleRemoveAllStandardsClick={handleRemoveAllStandardsClick}
        showAddStandardsModal={showAddStandardsModal}
        getSearchStandardsByNameQuery={getSearchStandardsByNameQuery}
      />

      <FeatureFlag.Fallback>
        <StandardSearchV1Presentation
          disabled={disabled}
          standards={standards}
          setShowAddStandardsModal={setShowAddStandardsModal}
          handleChipClick={handleChipClick}
          handleRemoveAllStandardsClick={handleRemoveAllStandardsClick}
          showAddStandardsModal={showAddStandardsModal}
          getSearchStandardsByNameQuery={getSearchStandardsByNameQuery}
        />
      </FeatureFlag.Fallback>
    </FeatureFlag>
  );
};

interface StandardSearchPresentationProps {
  standards: List<any>;
  disabled: boolean;
  setShowAddStandardsModal: (show: boolean) => void;
  handleChipClick: (e: any, standardToRemove: any) => void;
  handleRemoveAllStandardsClick: () => void;
  showAddStandardsModal: boolean;
  getSearchStandardsByNameQuery: (searchString: string) => any;
}

const StandardSearchV2Presentation = ({
  standards,
  disabled,
  setShowAddStandardsModal,
  handleChipClick,
  handleRemoveAllStandardsClick,
  showAddStandardsModal,
  getSearchStandardsByNameQuery
}: StandardSearchPresentationProps) => {
  return (
    <div className='u-display_flex u-flex-direction_column u-gap_space-x1'>
      <div className='u-display_flex u-justify-content_space-between'>
        <div className='u-display_flex u-gap_space-x4 u-align-items_center'>
          <QuestionEditorIcon icon={['fal', 'books']} />
          <Text bold>Standards</Text>
        </div>

        <div className='u-display_flex u-justify-content_center u-gap_space-x1'>
          <Button
            disabled={disabled}
            color='primary'
            onClick={() => setShowAddStandardsModal(true)}
          >
            Add Standard
            <Icon icon='plus' className='u-mar-l_1' />
          </Button>
          {standards.size > 0 && (
            <Dropdown position='bottom-end' trigger={<IconButton icon='ellipsis' aria-hidden />}>
              <DropdownItem color='red' onClick={handleRemoveAllStandardsClick}>
                Remove all standards
              </DropdownItem>
            </Dropdown>
          )}
        </div>
      </div>

      {standards.size > 0 && (
        <div className='u-mar-l_7 u-mar-t_2'>
          {standards.map((standard) => (
            <StandardChip
              as={CheckboxChip}
              defaultChecked
              enableTooltip
              icon='tag'
              color='brand'
              key={standard.getId()}
              standard={standard}
              onChange={(e) => handleChipClick(e, standard)}
            />
          ))}
        </div>
      )}

      {showAddStandardsModal && (
        <StandardSelectModal
          onDismiss={() => setShowAddStandardsModal(false)}
          query={getSearchStandardsByNameQuery}
          standards={standards}
        />
      )}
    </div>
  );
};

const StandardSearchV1Presentation = ({
  disabled,
  standards,
  setShowAddStandardsModal,
  handleChipClick,
  handleRemoveAllStandardsClick,
  getSearchStandardsByNameQuery,
  showAddStandardsModal
}: StandardSearchPresentationProps) => {
  return (
    <div className='u-mar-t_6 u-mar-b_6'>
      <div className='u-display_flex u-justify-content_space-between'>
        <div>
          <Text bold className='u-mar-r_1'>
            Standards
          </Text>
          <Button
            size='s'
            variant='outlined'
            disabled={disabled}
            onClick={() => setShowAddStandardsModal(true)}
          >
            Add
            <Icon className='u-mar-l_1' icon='plus' />
          </Button>
        </div>
        <div>
          {standards.size > 0 && (
            <Dropdown
              position='bottom-end'
              trigger={
                <Button size='s' variant='text' color='secondary'>
                  <Icon icon={['far', 'trash-alt']} className='u-mar-r_1' />
                  <Icon icon='caret-down' />
                </Button>
              }
            >
              <DropdownItem color='red' onClick={handleRemoveAllStandardsClick}>
                Remove all standards
              </DropdownItem>
            </Dropdown>
          )}
        </div>
      </div>

      {disabled ? 'Please save the question before adding standards.' : null}
      <div className='standard-search__standard-list'>
        {standards.map((standard) => {
          return (
            <StandardChip
              as={CheckboxChip}
              defaultChecked
              enableTooltip
              icon='tag'
              color='brand'
              key={standard.getId()}
              standard={standard}
              onChange={(e) => handleChipClick(e, standard)}
            />
          );
        })}
      </div>
      {showAddStandardsModal && (
        <StandardSelectModal
          onDismiss={() => setShowAddStandardsModal(false)}
          query={getSearchStandardsByNameQuery}
          standards={standards}
        />
      )}
    </div>
  );
};

export default StandardSearch;
