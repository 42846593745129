import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import withTracking from 'lib/hocs/withTracking';
import {getModelForResourceType} from 'resources/modelRegistry';

import {addToast, Label, Text, Toggle} from '@albert-io/atomic';
import RestrictFreePracticeModal from 'client/Classrooms/Classroom/Teacher/Subjects/RestrictFreePracticeModal.react';
import {setFreePracticeRestrictionForClassroom} from 'client/generic/SubjectMenuRenderer/helpers/queries';
import {Link} from 'react-router';

const TrackedToggle = withTracking(Toggle, {
  onClick: {
    event: 'click',
    type: 'toggle',
    feature: 'Course Library',
    component: 'Practice Settings Menu',
    content: (e) => (e.target.checked ? 'Open' : null)
  }
});

const FreePracticeOptionToggle = ({defaultChecked, classroom, onChange, subject, disabled}) => {
  const [showModal, setShowModal] = useState(false);
  const [isRequestPending, setIsRequestPending] = useState(false);

  const inputRef = useRef(null);

  const handleChange = async (e) => {
    if (e.target.checked) {
      try {
        setIsRequestPending(true);
        await setFreePracticeRestrictionForClassroom(classroom.getId(), subject.getId(), false);
        addToast({
          message: 'Your changes have been saved.',
          title: 'Success!',
          color: 'positive'
        });
        onChange(classroom.getId());
      } catch (error) {
        addToast({
          message:
            'We encountered an error while saving your changes. Please contact us if the problem persists.',
          title: 'Something went wrong',
          color: 'negative'
        });
        inputRef.current.checked = false;
      }
      setIsRequestPending(false);
    } else {
      setShowModal(true);
    }
  };

  const handleModalConfirm = async () => {
    try {
      setIsRequestPending(true);
      await setFreePracticeRestrictionForClassroom(classroom.getId(), subject.getId(), true);
      addToast({
        message: 'Your changes have been saved.',
        title: 'Success!',
        color: 'positive'
      });
      onChange(classroom.getId());
    } catch (error) {
      addToast({
        message:
          'We encountered an error while saving your changes. Please contact us if the problem persists.',
        title: 'Something went wrong',
        color: 'negative'
      });
      inputRef.current.checked = true;
    }
    setIsRequestPending(false);
  };

  return (
    <>
      <Label className='u-display_flex u-align-items_center'>
        <Text as='div' className='u-mar-r_auto' color='secondary' bold size='m'>
          {classroom.getName()}
        </Text>
        {disabled ? (
          <Text color='tertiary' italic size='xs' underline>
            <Link to='/account/access' className='u-color_slate-500 u-white-space_nowrap'>
              Needs License
            </Link>
          </Text>
        ) : (
          <TrackedToggle
            className='u-mar-l_2'
            defaultChecked={defaultChecked}
            disabled={disabled || isRequestPending || showModal}
            onChange={handleChange}
            ref={inputRef}
          />
        )}
      </Label>
      {showModal ? (
        <RestrictFreePracticeModal
          classroomId={classroom.getId()}
          onCancel={() => {
            inputRef.current.checked = true;
          }}
          subject={subject}
          handleClose={() => {
            setShowModal(false);
          }}
          onRestrict={handleModalConfirm}
        />
      ) : null}
    </>
  );
};

FreePracticeOptionToggle.propTypes = {
  classroom: PropTypes.instanceOf(getModelForResourceType('classroom_v1')),
  defaultChecked: PropTypes.bool,
  onChange: PropTypes.func,
  subject: PropTypes.instanceOf(getModelForResourceType('subject_v2')),
  disabled: PropTypes.bool
};

export default FreePracticeOptionToggle;
