/**
 * # Popover
 *
 * Popover takes a targetRef prop and passes this to an instance of AnchoredFixedPosition.
 * This ref behaves as the anchor to the popover, which behaves similarly to Tooltip, but
 * with some significant differences, namely, custom mobile behavior. Wrapping <AnchoredFixedPosition />
 * is an instance of WindowSizeConsumer. At breakpoint XS, a modifier function that we pass to our
 * AnchoredFixedPosition instance will override the default behavior of Popper.js to apply our
 * mobile styles.
 *
 * ## Props
 *
 * - `optimizeForMobile` (boolean): Whether to optimize the popover for mobile devices. Default is `true`.
 * - `position` (string): The position of the popover relative to the target. Default is `'bottom-start'`.
 * - `targetRef` (object): The reference to the target element. Required.
 * - `className` (string): Additional CSS class for the popover.
 * - `expanded` (boolean): Whether the popover is expanded. Required.
 * - `modifiers` (PopperJS.Modifiers): Passed to child `AnchoredFixedPosition` and underlying react-popper instance. See [react-popper Modfier docs](https://popper.js.org/docs/v1/#modifiers)
 * - `noScroll` (boolean): Whether to disable scrolling when the popover is open.
 * - `style` (object): Additional inline styles for the popover.
 * - `zLevel` (string): The z-level of the popover. Default is `'popover'`.
 * - `usePortal` (boolean): Whether to render the popover in a portal. Default is `true`.
 *
 * ## Usage
 *
 * ```jsx
 * import React, { useRef } from 'react';
 * import Popover from './Popover.react';
 *
 * function App() {
 *   const targetRef = useRef();
 *   const expanded = true;
 *
 *   return (
 *     <div>
 *       <button ref={targetRef}>Open Popover</button>
 *       <Popover targetRef={targetRef} expanded={expanded}>
 *         <div>Hello world</div>
 *       </Popover>
 *     </div>
 *   );
 * }
 * ```
 */

/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import makeConstants from 'lib/makeConstants';

import {BREAKPOINTS as breakpoints} from 'client/constants';

import Card from '../../atoms/Card/Card.react';
import {WindowSizeConsumer} from '../../helpers/WindowSizeProvider/WindowSizeProvider.react';
import AnchoredFixedPosition from '../../helpers/AnchoredFixedPosition/AnchoredFixedPosition.react';

import './popover.scss';

const breakpoint = breakpoints.xs;
const positions = makeConstants('bottom-start', 'left-start', 'top-start', 'right-start');
const modifiers = {
  computePosition: {
    enabled: true,
    fn: (data, options) => {
      let styles;
      if (options.isMobile) {
        // For mobile: affix the popover to the bottom of the viewport
        styles = {
          bottom: '1em',
          height: 'auto',
          left: '1em',
          margin: '0 auto',
          maxHeight: '100vh',
          maxWidth: 'calc(100% - 2em)',
          position: 'fixed',
          right: '1em',
          top: '1em',
          width: '100%',
          ...options.inlinedStyles
        };
        return {...data, styles};
      }
      styles = {...data.styles, ...options.inlinedStyles};
      return {...data, styles};
    },
    isMobile: false,
    order: 860
  }
};

const zLevels = makeConstants('popover', 'modal');

function Popover({
  optimizeForMobile,
  position,
  targetRef,
  className,
  expanded,
  modifiers: modifiersProp,
  noScroll,
  style,
  zLevel,
  usePortal,
  paddingLevel,
  ...rest
}) {
  return (
    <WindowSizeConsumer>
      {({viewportWidth}) => {
        const isMobile = optimizeForMobile && viewportWidth <= breakpoint;
        modifiers.computePosition.isMobile = isMobile;
        modifiers.computePosition.inlinedStyles = style;
        return (
          <AnchoredFixedPosition
            className={classnames({
              [`app-portal--z-${zLevels[zLevel]}`]: zLevels[zLevel]
            })}
            modifiers={{...modifiers, ...modifiersProp}}
            placement={position}
            target={targetRef.current}
            eventsEnabled={!noScroll}
            usePortal={usePortal}
          >
            <Card
              aria-expanded={expanded}
              aria-hidden={!expanded}
              className={classnames('m-popover', className, {
                [`m-popover--${position}`]: position,
                'm-popover--mobile': isMobile
              })}
              paddingLevel={paddingLevel}
              roundness='small'
              shadow='default'
              {...rest}
            />
            {isMobile && <div className='m-popover__backdrop' />}
          </AnchoredFixedPosition>
        );
      }}
    </WindowSizeConsumer>
  );
}

Popover.propTypes = {
  className: PropTypes.string,
  expanded: PropTypes.bool.isRequired,
  modifiers: PropTypes.object,
  noScroll: PropTypes.bool,
  onClick: PropTypes.func,
  optimizeForMobile: PropTypes.bool,
  position: PropTypes.oneOf(Object.keys(positions)),
  style: PropTypes.object,
  targetRef: PropTypes.object.isRequired,
  zLevel: PropTypes.oneOf(Object.keys(zLevels)),
  usePortal: PropTypes.bool,
  paddingLevel: PropTypes.number
};

Popover.defaultProps = {
  modifiers: {},
  optimizeForMobile: true,
  position: 'bottom-start',
  zLevel: 'popover',
  usePortal: true,
  paddingLevel: 2
};

export default Popover;
