import React, {useCallback, useEffect, useState} from 'react';
import {Heading, Text} from '@albert-io/atomic';
import {callTargetedAction, setUpStore} from 'client/framework';

import FreeResponseStore from '../FreeResponseQuestion.store';
import freeResponseActions from '../FreeResponseQuestion.actions';
import {WSStore} from '../FreeResponseQuestion.types';

import useDraftGuess from '../../../../AssignmentPracticeView/DraftGuesses/useDraftGuess';

import WrittenSubmissionContext from './WrittenSubmission.context';
import WrittenSubmissionInput from './Editor/WrittenSubmissionInput.react';

interface Props {
  storeName: string;
  submitted?: boolean;
  question: any;
}

const WrittenStudentSubmission = ({storeName, submitted = false, question}: Props) => {
  const {DraftGuessSaveStatus, startTimer} = useDraftGuess(storeName, submitted);

  const [store, setStore] = useState<null | WSStore>(null);
  const getWordCount = useCallback(() => {
    if (!store) return 0;
    return store.getWordCount();
  }, [store]);

  const onChange = useCallback(
    (data) => {
      if (!submitted) {
        callTargetedAction({
          name: freeResponseActions.UPDATE_RESPONSE,
          payload: data,
          targetStore: storeName
        });
      }
    },
    [storeName, submitted]
  );

  useEffect(() => {
    const newStore = setUpStore(FreeResponseStore, storeName, question.getId(), question);
    setStore(null);

    const timeout = setTimeout(() => {
      // hot swapping written submission input editor values causes a bug where if the previous cursor is out of bounds of the next
      // written submission, it causes an error. This only occurs when the next question is another written submission
      startTimer(); // start time_elapsed timer
      setStore(newStore);
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [storeName, question, startTimer]);

  const editorState = store ? store.getValue() : null;
  const labelText = submitted ? 'Your Response' : 'Provide Your Response Below';

  return (
    <div className='u-mar-b_4 u-width_100pc'>
      <Heading as='h2' size='s' id='wss-text-input'>
        {labelText}
      </Heading>

      {store && editorState && (
        <WrittenSubmissionContext.Provider value={{store, storeName, submitted}}>
          <WrittenSubmissionInput
            onChange={onChange}
            value={editorState}
            readOnly={submitted}
            aria-labelledby='wss-text-input'
          />
          {!submitted ? (
            <span className='u-display_flex u-align-items_center u-mar-t_1'>
              <DraftGuessSaveStatus store={store} />
              <Text size='s' className='u-mar-l_auto u-color_slate-500'>
                Word Count: {getWordCount()}
              </Text>
            </span>
          ) : null}
        </WrittenSubmissionContext.Provider>
      )}
    </div>
  );
};

export default WrittenStudentSubmission;
