/**
 * # Fieldset
 *
 * Component for rendering a fieldset with an optional legend.
 *
 * ## Props extends React.HTMLAttributes<HTMLFieldSetElement>
 *
 * - `legend?: string` (optional): The legend text to be displayed.
 * - `...rest: React.HTMLAttributes<HTMLFieldsetElement>`: See [fieldSet MDN](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/fieldset) for available attributes
 *
 * ## Example
 *
 * ```tsx
 * import React from 'react';
 * import Fieldset from './Fieldset.react';
 *
 * const MyComponent = () => {
 *   return (
 *     <Fieldset legend="My Fieldset">
 *       <input type="text" />
 *       <button>Submit</button>
 *     </Fieldset>
 *   );
 * };
 *
 * export default MyComponent;
 * ```
 */
import React from 'react';
import classnames from 'classnames';

import Text from '../../atoms/Text/Text.react';

import './fieldset.scss';

export interface FieldsetProps extends React.HTMLAttributes<HTMLFieldSetElement> {
  legend?: string;
}

const Fieldset = ({children, className, legend, ...rest}: FieldsetProps) => (
  <fieldset className={classnames('m-fieldset', className)} {...(rest as any)}>
    {legend && (
      <legend className='u-mar-b_4 u-pad-lr_0'>
        <Text size='l' bold>
          {legend}
        </Text>
      </legend>
    )}
    {children}
  </fieldset>
);

export default Fieldset;
