export type FlavorTypes = keyof typeof flavorDescriptions;

export const colors = {
  authorProvided: '#adadad',
  correct: 'rgb(27, 183, 152)',
  incorrect: 'rgb(203, 60, 86)',
  highlighted: 'rgb(57, 159, 223)',
  preview: 'rgb(105, 109, 112)',
  studentGuess: 'rgb(146, 58, 149)',
  solution: 'rgb(40, 131, 189)'
} as const;

export const flavorDescriptions = {
  authorProvided: {
    color: colors.authorProvided,
    layerZIndex: 1,
    displayName: 'Prompt'
  },
  solution: {
    color: colors.solution,
    layerZIndex: 2,
    displayName: 'Solution'
  },
  studentGuess: {
    color: colors.studentGuess,
    layerZIndex: 2,
    displayName: 'Student guess'
  },
  incorrectAnswer: {
    color: colors.incorrect,
    layerZIndex: 2,
    displayName: 'Incorrect student guess'
  },
  correctAnswer: {
    color: colors.correct,
    layerZIndex: 3,
    displayName: 'Correct student guess'
  }
} as const;

export const flavorTypes = Object.keys(flavorDescriptions).reduce((acc, name) => {
  acc[name] = name;
  return acc;
}, {}) as {[K in FlavorTypes]: K};

export const modes = {
  editing: 'editing', // All geometryInstances are selectable and editable.
  guessing: 'guessing', // Only studentGuess geometryInstances are selectable and editable.
  reviewing: 'reviewing' // Nothing is selectable or editable.
} as const;

export const strokeWidth = 3;

export const entityTypeNames = [
  'point',
  'line',
  'segment',
  'ray',
  'polygon',
  'circle',
  'inequality',
  'parabola'
];
