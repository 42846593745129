import {createActions} from 'client/framework';

const normalActions = [
  'ADD_OUT_OF_SCOPE_SUBJECTS_TO_CLASSROOMS',
  'ADD_SINGLE_RECIPIENT',
  'LAUNCH_ASSIGNMENT_CREATOR',
  'REMOVE_SINGLE_RECIPIENT',
  'RESET_STORE',
  'RESET_TOAST_MESSAGE',
  'SAVE_ASSIGNMENT',
  'SET_ACTIVE_RECIPIENT_GROUP',
  'SET_FIELD_STATE',
  'SET_MULTIPLE_RECIPIENTS',
  'SET_SAVED_ASSIGNMENT_PAYLOAD',
  'SET_SHOW_OUT_OF_SCOPE_SUBJECT_MODAL',
  'TOGGLE_AUTO_ASSIGN_NEW_CLASSROOM_ENROLLEES',
  'UPDATE_SECTIONS',
  'VALIDATE_ASSIGNMENT'
];

const createAssignmentActions = createActions(null, normalActions);

export default createAssignmentActions;
