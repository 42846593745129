// @flow
import zlib from 'zlib';

import {isUndefined, extend, mapValues, isPlainObject, isArray, forEach} from 'lodash';
import {Dispatcher} from 'flux';
import {Map, Iterable, fromJS} from 'immutable';
import {v4 as uuid} from 'uuid';

import notifier from '@albert-io/notifier';

import {State} from 'lib/state';
import {session} from 'lib/StorageUtil';

const isDev = process.env.NODE_ENV !== 'production';

const GLOBAL_APPLICATION_STATE_PROPERTY = '_appState';

/**
 * Attempts to retrieve application state from the global environment (browser).
 * If no application state is found in the environment, it will default to an empty value.
 *
 * @returns {Map} The application state.
 */
export function parseAppState() {
  const state = global[GLOBAL_APPLICATION_STATE_PROPERTY];
  if (process.env.IS_BROWSER && state) {
    /**
     * If we're running in the browser, we load the state that was provided by the isomorphic render.
     */
    try {
      return fromJS(JSON.parse(zlib.inflateSync(Buffer.from(state, 'base64')).toString('utf8')));
    } catch (error) {
      logger.error('Error loading application state: %o', error);
      notifier.notify(error, {
        component: 'framework',
        name: 'Failed to load application state.'
      });
    }
  }
  return Map();
}

/**
 * The active state context for the running instance of the framework.
 */
export const _stateContext = new State(parseAppState());

/**
 * @returns {State} The current application state (instance).
 */
export function getActiveState() {
  return _stateContext;
}

/**
 * @type {Map<string, Map<string, *>>}
 */
let _storeRegistry = Map();

/**
 * @param options
 * @memberOf Server.Isomorphism
 */
export function resetFramework(
  options: {
    stores: Map<string, *>,
    state: Map<string, *>
  } = {stores: Map(), state: Map()}
) {
  getPendingActionsStore().clearPending();
  _storeRegistry.forEach((entry, name) => {
    if (options.stores.includes(entry) === false) {
      const store = entry.get('store');
      if (store.destroy) {
        store.destroy();
      } else {
        destroyStore(name);
      }
    }
  });

  _storeRegistry = options.stores;
  _stateContext.set(options.state);
}

export function getRegisteredStores(): Map<string, Map<string, *>> {
  return _storeRegistry;
}

/**
 * Iterates through the `_storeRegistry and executes the registered `Store.initFunc`s,
 * returning an array that contains all of their `Promise`s.
 *
 * @returns {Array<Promise>}
 * @see {Store._setServerInitFunction}
 * @memberOf Server.Isomorphism
 */
export function getStoreInitFunctionPromises() {
  return _storeRegistry.reduce((promises, value, key) => {
    const initializer = value.get('initializer');
    if (initializer) {
      const promise = initializer()
        .then(() => {
          logger.debug('initializer run for Store: %s', key);
        })
        .catch(() => {
          logger.error('error encountered in Store initializer: %s', key);
        });
      promises.push(promise);
    }
    return promises;
  }, []);
}

// ********* Store Parent Class ***********
// All stores should derive from this. The store is a cursor into the _state tree
export class Store {
  name: string;

  cursor: *;

  handlers: Map<any, any>;

  shouldEmitChange: boolean;

  initialData: Map<any, any>;

  shouldIgnoreActions: boolean;

  _changeId: string;

  token: string;

  /**
   * `isomorphism` contains configurations related to the server-side instantiation of
   * the state tree (`getActiveState()`).
   */
  static isomorphism = {
    /**
     * `dataTransforms` will be triggered in the `Store.constructor` _after_ the `Store.cursor` has
     * been potentially populated from an isomorphic render. This allows the client to ensure data
     * of the proper type is available.
     *
     * @example The folowing would ensure the `supplements` value on a `Store` was a `Set<SupplmentModel>`:
     *
     *   dataTransforms: {
     *      supplements: (theSet: Set) =>
     *        Set(theSet.map((theMap: Map) => new SupplmentModel(theMap))
     *      )
     *   }
     *
     */
    dataTransforms: {}
  };

  // Creates the store and registers it with the dispatcher such that actions
  // will pass into it
  // @param {string} name The unique name for this store
  constructor(
    name: string,
    {
      hiddenFromRegistry
    }: {
      hiddenFromRegistry: boolean
    } = {}
  ) {
    if (_storeRegistry.has(name)) {
      throw Error(`ERROR: Store with name ${name} already exists!`);
    }
    // Commenting out as there is at least one instance of a dynamic store which does not supply it
    // This check should be in there once we identify which one and fix it.
    // else if (!name) {
    //   throw Error('ERROR: Store must be constructed with a name!');
    // }
    this.name = name;
    this.cursor = getActiveState().cursor([name]);
    this.handlers = Map();
    this.shouldEmitChange = true;
    this.initialData = Map();
    this.shouldIgnoreActions = false;

    this._changeId = '';

    if (!hiddenFromRegistry) {
      _storeRegistry = _storeRegistry.set(
        name,
        Map({
          store: this
        })
      );
    }

    this.token = register(({action, data}) => {
      if (!this.shouldIgnoreActions) {
        this._dispatch(action, data);
      }
    });

    /**
     * `isomorphsim.dataTransform` processing.
     * This is intended to happen after `this.cursor` has potentially been populated from
     * a generated state tree. The use of a `dataTransform` ensures `Store` values are instatiated
     * with the expected types on the initial render after a isomorphic load.
     */
    const {dataTransforms} = this.constructor.isomorphism;
    if (dataTransforms && Object.keys(dataTransforms).length > 0 && this.cursor()) {
      this.cursor((store) =>
        store.reduce((result, val, key) => {
          if (Object.prototype.hasOwnProperty.call(dataTransforms, key)) {
            const transform = dataTransforms[key];
            if (typeof transform !== 'function') {
              throw new Error(
                `On construction of ${name} store, the isomoprhism.dataTransform supplied for ${key} is not a valid function.`
              );
            }
            return result.update(key, transform);
          }
          return result;
        }, store)
      );
    }

    if (!this.cursor()) {
      this.cursor(() => Map());
    }
  }

  /**
   * Registers a `initFunc` on the `Store` which will be processed by the isomorphic rendering cycle.
   *
   * @param {Promise} func A `Promise` to be run as part of the server initialization.
   * @see {getStoreInitFunctionPromises}
   * @memberOf Server.Isomorphism
   */
  _setServerInitFunction(func: Promise<*>) {
    _storeRegistry = _storeRegistry.setIn([this.getName(), 'initializer'], func);
  }

  _dispatch(action: Symbol, data: any) {
    if (this.handlers.has(action)) {
      this.handlers.get(action)(data);
    }
  }

  deregisterHandlers() {
    unregister(this.token);
  }

  // eslint-disable-next-line consistent-return
  withPreviousState(callback: (Store) => any): any {
    try {
      getActiveState().rewind();
      return callback(this);
    } catch (err) {
      logger.error('Error rewinding, or executing withPreviousState: %o', err);
    } finally {
      getActiveState().restore();
    }
  }

  /**
   * Get the currently stored data (executes the function stored at the path)
   *
   * @param {object} query The path in the node of the state tree to retrieve value from
   * @param {object} defaultValue The value to return if the path is not found
   * @returns {object} The currently stored object in the state tree
   */
  readData(query, defaultValue) {
    if (isUndefined(query)) {
      return this.cursor();
    }
    if (Iterable.isIterable(query)) {
      return this.cursor().getIn(query, defaultValue);
    }
    return this.cursor().getIn([].concat(query), defaultValue);
  }

  /**
   * Insert new data into the store's path in the state tree
   *
   * @param {object} data Either a function or any other object that will be
   * interred at the path. For the simple case, you can just pass in an object
   * and readData will return that exact object. Otherwise, you can pass in a
   * more complex function to manipulate the data in place.
   * @param val1
   * @param val2
   */
  writeData(val1, val2): * {
    this._changeId = uuid();
    if (typeof val1 === 'function') {
      this.cursor(val1, this.shouldEmitChange);
    } else if (arguments.length === 1) {
      /**
       * Unlike the other variants of writeData, this one doesn't pass the
       * this.shouldEmitChange book to this.cursor(). This seems wrong, but it also
       * seems like something that could cause issues if we changed it. Look into this
       * and fix it, if possible.
       *
       * @see https://github.com/albert-io/project-management/issues/2125
       */
      this.cursor(() => {
        return val1;
      });
    } else {
      this.cursor((store) => {
        let returnValue;
        if (!store) {
          returnValue = Map().set(val1, val2);
        } else if (Iterable.isIterable(val1)) {
          returnValue = store.setIn(val1, val2);
        } else {
          returnValue = store.setIn([].concat(val1), val2);
        }

        return returnValue;
      }, this.shouldEmitChange);
    }

    return this;
  }

  writeDataNoEmit(val1, val2) {
    this.shouldEmitChange = false;
    this.writeData(val1, val2);
    this.shouldEmitChange = true;
    return this;
  }

  /**
   * @todo This method should not be used and should be removed from it's single callsite.
   */
  writeSessionData(key, value) {
    session.set(this.getStorageKey(key), value);
    this.writeData(key, value);
  }

  getStorageKey(key) {
    return `${this.getName()}-${key}`;
  }

  getChangeId() {
    return this._changeId;
  }

  getName() {
    return this.name;
  }

  /**
   * Lets you define setters in the action handler like so:
   * this.handle(fooActions.SET_COLOR, this.setProperty('color'))
   *
   * @param {string} property
   */
  setProperty(property) {
    return (value) => {
      this.writeData(property, value);
    };
  }

  toggleProperty(property) {
    return () => {
      this.writeData(property, !this.readData(property));
    };
  }

  /**
   * Resets properties back to their initial data
   *
   * @param {...string} properties - One or more properties to reset
   */
  resetProperties(...properties) {
    properties.forEach((property) => {
      if (!this.initialData.has(property)) {
        throw Error(`Property '${property}' not found in Store ${this.name}'s initialData!`);
      }
    });
    const initialData = this.initialData.reduce((acc, value, key) => {
      return properties.includes(key) ? acc.set(key, value) : acc;
    }, Map());
    this.writeData((store) => {
      return store.merge(initialData);
    });
  }

  /**
   * Set up this store to handle a specific action with the passed in handler store function
   *
   * @param {string} action The action to be handled
   * @param {Function} handler The store function to call to handle the payload
   */
  handle(action, handler) {
    this.handlers = this.handlers.set(action, handler.bind(this));

    return this;
  }

  handleTargeted(action, handler, targetStoreName) {
    const storeName = targetStoreName || this.name;
    this.handlers = this.handlers.set(`${storeName}/${action.toString()}`, handler.bind(this));

    return this;
  }

  /**
   * Initialize the store to a specific state
   *
   * If a store's cursor into the state tree finds  a non empty collection, the setIntitialData method will not
   * overwrite it. A populated state tree entry comes from the iso app state.
   *
   * Additionally, `this.initialData` only is updated if the cursor is empty and no initial data was already set.
   *
   * @param {object} data Data to initialize the store to
   */
  setInitialData(data) {
    if (this.cursor().isEmpty()) {
      this.writeData(data);
    }

    /**
     * If we don't have initialData, let's set it. Checking for size instead of isEmpty in case it's not
     * an Immutable object. If not immutable, we'll log the store which is using non-Immutable data.
     */
    const hasInitialData = this.initialData && this.initialData.size !== 0;
    if (!hasInitialData || this.cursor().isEmpty()) {
      this.initialData = data;
    }

    /**
     * this.initialData.toJS is a duck typing check to check whether or not initialData is Immutable.
     *
     * @todo: Swap .toJS check for isImmutable when we bump to Immutable 4.0
     */
    if (this.initialData && !this.initialData.toJS) {
      notifier.notify(
        new Error(`Set initialData of '${this.name}' Store and it is not an Immutable collection`),
        {
          component: 'framework'
        }
      );
    }

    return this;
  }

  resetStore() {
    this.writeData(this.initialData);
  }

  /**
   * Helper method for storing and cleaning up promises. Helpful when needing to call actions from components
   * after doing something asynchronously.
   *
   * @example
   * _saveChanges = this.withSavedPromise(() => {
   *   return this.getDogModel().setName('Poppy').save();
   * }, 'saveDogPromise');
   *
   * @param {Function} callback - A function that returns a promise. The return value of this function is the
   *   promise that will be stored.
   * @param {string} propertyName - The name to give the promise when writing it to the Store.
   */
  withSavedPromise(callback: () => Promise<any>, propertyName: string): () => void {
    if (!propertyName) {
      throw new Error(`Please provide withSavedPromise in ${this.getName()} a propertyName`);
    }
    return (...args) => {
      const promise = callback(...args);
      this.writeData(propertyName, promise);
      promise.finally(() => {
        this.writeData((store) => store.delete(propertyName));
      });
    };
  }
}

export function getStoreByName(name: string): Store | false {
  return _storeRegistry.getIn([name, 'store'], false);
}

export function setUpStore(StoreClass, name, ...args): StoreClass {
  const storeInstance = getStoreByName(name);
  if (storeInstance && !(storeInstance instanceof StoreClass)) {
    throw new Error(`Store '${name}' is not an instance of ${StoreClass.name}`);
  }
  return storeInstance || new StoreClass(name, ...args);
}

export function hasStore(name: string) {
  return _storeRegistry.has(name);
}

export function destroyStore(name: string) {
  if (!hasStore(name)) {
    throw new Error(`Attempted to destroy store ${name} which does not exist!`);
  }

  const storeToDestroy = getStoreByName(name);

  storeToDestroy.deregisterHandlers();

  _storeRegistry = _storeRegistry.delete(name);
  getActiveState().set(getActiveState().get().delete(name), false);
}

export function setShouldIgnoreActions(store, value) {
  let storeInstance = store;
  if (typeof storeInstance === 'string') {
    storeInstance = getStoreByName(store);
    if (!storeInstance) {
      throw new Error(`Attmpted to ignore actions on store ${store} which does not exist!`);
    }
  }
  storeInstance.shouldIgnoreActions = value;
}

// ********* Dispatcher ***********

class FrameworkDispatcher {
  constructor() {
    this.dispatcher = new Dispatcher();
  }

  // XXX This shouldn't need to be exported after we transition everythin to
  // new-style stores
  register(callback: Function) {
    return this.dispatcher.register(callback);
  }

  unregister(tokenId) {
    this.dispatcher.unregister(tokenId);
  }

  dispatchAsync(action, promise) {
    const actionName = typeof action === 'string' ? action : action.toString();

    // We actually want to dispatch this action synchronously, while the promise
    // is resolved asynchronously and is passed back to the action to resolve
    // as the action needs it re solved
    this.dispatchSync(action, null);

    logger.debug('[pending] %s', actionName);

    getPendingActionsStore().addPending(actionName, promise);
    return promise.then(
      (data) => {
        getPendingActionsStore().removePending(actionName);
        return data;
      },
      (reason) => {
        logger.debug('[reject] %s Reason: %o', actionName, reason);
        getPendingActionsStore().removePending(actionName);
        throw reason;
      }
    );
  }

  dispatchSync(action: Function, data: ?Object) {
    logger.debug('%s', action);
    this.dispatcher.dispatch({action, data});
  }

  dispatch(action, data: ?Object, requestId: ?String) {
    if (isDev && action.toString === Function.prototype.toString && typeof action !== 'string') {
      throw new Error(
        `Action ${action} toString method has to be overridden by setToString. Currently is ${action}`
      );
    }

    const looksLikePromise = data && typeof data.then === 'function';

    let ret = null;

    if (looksLikePromise) {
      ret = this.dispatchAsync(action, data, requestId);
    } else {
      this.dispatchSync(action, data);
    }

    return ret;
  }
}

const _dispatcher = new FrameworkDispatcher();

export function register(callback: Function) {
  return _dispatcher.register(callback);
}

export function unregister(tokenId) {
  return _dispatcher.unregister(tokenId);
}

export function dispatch(action: Function, data: ?Object, requestId: ?String) {
  return _dispatcher.dispatch(action, data, requestId);
}

class PendingActionsStore extends Store {
  constructor(name: string) {
    super(name, {hiddenFromRegistry: true});
    this.setInitialData(Map());
  }

  isPending(action) {
    let key = action;
    if (typeof key !== 'string') {
      key = key.toString();
    }
    return this.readData().has(key);
  }

  addPending(action, promise) {
    this.writeData((actionMap) => {
      return actionMap.set(action, promise);
    });
  }

  clearPending() {
    this.writeData(Map());
  }

  removePending(action) {
    this.writeData((actionMap) => {
      return actionMap.delete(action);
    });
  }

  getPendingActions() {
    return this.readData();
  }
}

const _pendingActionsStore = new PendingActionsStore('$pendingActions');

export function getPendingActionsStore() {
  return _pendingActionsStore;
}

// ********* Action Registry ***********
const _actionRegistry = {};

/**
 * Create a new action in the registry. If a handler function is not supplied,
 * a generic action with the standard payload will be dispatched.
 *
 * @param {string} name A string used to reference this action
 * @param {Function|undefined} handler The value to return if the path is not found
 */
function createAction(name, handler) {
  let fullHandler = null;

  if (Object.prototype.hasOwnProperty.call(_actionRegistry, name)) {
    throw new Error(`An action with the name ${name.toString()} already exists!`);
  }

  if (isUndefined(handler)) {
    fullHandler = ({inputPayload, targetStore}) => {
      const dispatchName = targetStore ? `${targetStore}/${name.toString()}` : name;
      const ret = dispatch(dispatchName, inputPayload);
      return ret;
    };
  } else if (typeof handler !== 'function') {
    throw new Error(`The handler for action ${name.toString()} must be a function!`);
  } else {
    fullHandler = ({inputPayload, requestId, targetStore}) => {
      const dispatchName = targetStore ? `${targetStore}/${name.toString()}` : name;
      const processedPayload = handler(inputPayload);
      const ret = dispatch(dispatchName, processedPayload, requestId);
      return ret;
    };
  }

  _actionRegistry[name] = fullHandler;
}

export function callAction(name, payload, requestId) {
  if (!Object.prototype.hasOwnProperty.call(_actionRegistry, name)) {
    throw new Error(`An action with the name ${name.toString()} does not exist!`);
  }

  return _actionRegistry[name]({inputPayload: payload, requestId});
}

// TODO: This should just be callAction, but callAction is used in a million places
// so for now it's being implemented as a separate function. CallAction should
// just be converted to take in an object with named params as below.
export function callTargetedAction(action) {
  const {name, payload, targetStore, requestId} = action;
  const requiredProperties = ['name', 'targetStore'];

  requiredProperties.forEach((property) => {
    if (!Object.prototype.hasOwnProperty.call(action, property)) {
      throw new Error(`callTargetedAction() requires a '${property}' property`);
    }
  });

  if (!Object.prototype.hasOwnProperty.call(_actionRegistry, name)) {
    throw new Error(`An action with the name ${name.toString()} does not exist!`);
  }

  return _actionRegistry[name]({inputPayload: payload, targetStore, requestId});
}

const actionTypes = {
  CUSTOM_ACTION: Symbol('actionTypes.CUSTOM_ACTION'),
  NORMAL_ACTION: Symbol('actionTypes.NORMAL_ACTION')
};

/**
 * Dynamycally create actions using the actionTypes
 *
 * @param {Array|Object} custom custom actions to be instantiated later
 * @param {Array} normal normal/passthough actions
 * @returns {*}
 */
export function createActions(custom, normal) {
  const mapOfActions = extend(
    generateActionObject(custom, actionTypes.CUSTOM_ACTION),
    generateActionObject(normal, actionTypes.NORMAL_ACTION)
  );

  const actionSymbols = mapValues(mapOfActions, (actionType, action) => {
    return Symbol(action);
  });

  forEach(mapOfActions, (actionType, actionName) => {
    if (actionType !== actionTypes.CUSTOM_ACTION) {
      createAction(actionSymbols[actionName]);
    }
  });
  return actionSymbols;
}

function generateActionObject(actions, actionType) {
  let returnObject = {};

  if (actions) {
    if (isPlainObject(actions) && actionType === actionTypes.CUSTOM_ACTION) {
      returnObject = actions;
    } else if (isArray(actions)) {
      actions.forEach((actionName) => {
        returnObject[actionName] = actionType;
      });
    } else if (typeof actions === 'string') {
      returnObject[actions] = actionType;
    }
  }

  return returnObject;
}
