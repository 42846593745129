/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import {Button, Dialogue, Modal, Text} from '@albert-io/atomic';

export default class BlockedQuestionsModal extends React.Component {
  static propTypes = {
    blockedQuestionCount: PropTypes.number,
    questionCount: PropTypes.number,
    subjectSlug: PropTypes.string.isRequired,
    handleDismiss: PropTypes.func
  };

  static defaultProps = {
    blockedQuestionCount: 0,
    questionCount: 0
  };

  render() {
    const {blockedQuestionCount, questionCount} = this.props;
    if (blockedQuestionCount === 0) {
      return null;
    }
    const areAllQuestionsBlocked = questionCount === 0;
    let bodyCopy;
    let secondaryAction;
    if (areAllQuestionsBlocked) {
      bodyCopy = (
        <Text as='p' paragraph>
          All of these questions are part of one or more active assignments. These questions will
          not be visible until you have submitted the relevant assignments and are able to access
          the explanations.
        </Text>
      );
      secondaryAction = (
        <Button variant='text' as={Link} to={`/${this.props.subjectSlug}`}>
          Go back
        </Button>
      );
    } else {
      bodyCopy = (
        <Text as='p' paragraph>
          {blockedQuestionCount} of these questions {blockedQuestionCount === 1 ? 'is' : 'are'} part
          of one or more active assignments. These questions will not be visible until you have
          submitted the relevant assignments and are able to access the explanations.
        </Text>
      );
      secondaryAction = <Button variant='text'>Continue anyway</Button>;
    }
    const handleClose = () => {
      if (!areAllQuestionsBlocked) {
        this.props.handleDismiss();
      }
    };
    return (
      <Modal
        dismissable={!areAllQuestionsBlocked}
        ariaLabel='Blocked assignment questions message'
        handleClose={handleClose}
      >
        {({CloseButtonWrapper, modalContentStyle}) => (
          <Dialogue
            inModal
            hideCloseBtn={areAllQuestionsBlocked}
            className={modalContentStyle}
            handleClose={handleClose}
            alignTitle='center'
            title='Blocked Questions'
          >
            <Dialogue.Body className='u-text-align_center'>{bodyCopy}</Dialogue.Body>
            <Dialogue.BtnGroup fillColumn direction='column'>
              <Button as={Link} to='/classes'>
                Go to classes
              </Button>
              <CloseButtonWrapper>{secondaryAction}</CloseButtonWrapper>
            </Dialogue.BtnGroup>
          </Dialogue>
        )}
      </Modal>
    );
  }
}
