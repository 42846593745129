import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classnames from 'classnames';
import sessionStore from 'client/Session/SessionStore';

export default class GuessHistoryWidget extends React.Component {
  static propTypes = {
    question: ImmutablePropTypes.record
  };

  render() {
    const lastGuess = this.props.question.getGuessHistory().first();
    const isInActiveAssignment = sessionStore.isStudent() &&
      this.props.question.isQuestionInActiveAssignment();

    const indicatorClassNames = classnames('question-attempt-indicator',
      isInActiveAssignment ? 'question-attempt-indicator--blocked' : {
        'question-attempt-indicator--unanswered': !lastGuess,
        'question-attempt-indicator--correct': lastGuess && lastGuess.isCorrect(),
        'question-attempt-indicator--incorrect': lastGuess && !lastGuess.isCorrect()
      }
    );

    return <span className={indicatorClassNames} />;
  }
}
