import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {List} from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';

// TODO: Change onChangeCallback to onChange
// TODO: remove inputClasses
// TODO: Change className to apply to the top level component
const propTypes = {
  label: PropTypes.string,
  errors: PropTypes.oneOfType([ImmutablePropTypes.set, ImmutablePropTypes.list]),
  onChangeCallback: PropTypes.func,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  inputClasses: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  maxLength: PropTypes.number,
  updateInputOnValueMismatch: PropTypes.bool
};

const defaultProps = {
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  onChangeCallback: () => {},
  onKeyDown: () => {},
  errors: new List(),
  updateInputOnValueMismatch: true
};

export class BasicInput extends React.Component {
  wrapperRef = React.createRef();

  static defaultProps = defaultProps;

  static propTypes = propTypes;

  shouldComponentUpdate(nextProps) {
    const el = this.wrapperRef.current;
    const errors = nextProps.errors || new List();

    return (
      nextProps.value !== el.value ||
      !errors.equals(this.props.errors) ||
      nextProps.inputClasses !== this.props.inputClasses ||
      nextProps.className !== this.props.className
    );
  }

  componentWillUpdate(nextProps) {
    if (this.props.updateInputOnValueMismatch && nextProps.value !== this.props.value) {
      const el = this.wrapperRef.current;
      el.value = nextProps.value;
    }
  }
}

export class TextAreaInput extends BasicInput {
  componentDidMount() {
    logger.debug('This version of TextInput is deprecated, please use the new version.');
  }

  render() {
    const {errors} = this.props;
    const hasErrors = errors && errors.size > 0;
    const inputClasses = classnames('form-input', this.props.inputClasses, {
      error: hasErrors
    });
    const label = this.props.label ? (
      <label className='form-input-label'>{this.props.label}</label>
    ) : null;
    return (
      <div className={this.props.className}>
        {label}
        <textarea
          ref={this.wrapperRef}
          name={this.props.name}
          key={this.props.name}
          className={inputClasses}
          disabled={!!this.props.disabled}
          maxLength={this.props.maxLength}
          type='text'
          placeholder={this.props.placeholder}
          onBlur={(e) => this.props.onBlur(e)}
          onChange={(e) => this.props.onChangeCallback(e)}
          defaultValue={this.props.value}
        />
        {hasErrors
          ? errors.map((error, index) => {
              return (
                <div className='form-input-error' key={`form-input-error${index}`}>
                  {error}
                </div>
              );
            })
          : null}
      </div>
    );
  }
}

TextAreaInput.propTypes = propTypes;
