import React from 'react';
import PropTypes from 'prop-types';
import SimpleModal from 'generic/SimpleModal/SimpleModal.react';
import {getQuestionTypes} from 'client/QuestionTypes/QuestionTypeDefinitions';
import Button from 'sg/Button/Button.react';
import './new-question-type-modal.scss';

export default class NewQuestionTypeModal extends React.PureComponent {
  static propTypes = {
    handleQuestionTypeSelection: PropTypes.func,
    handleClose: PropTypes.func
  };

  generateQuestions = () =>
    getQuestionTypes()
      .filterNot((type) => type.get('hidden'))
      .map((type, i) => {
        return (
          <Button
            large
            outline
            key={i}
            data-type={type.get('type')}
            className='new-question-type-modal__button'
            onClick={this.handleQuestionClick}
          >
            {type.get('label')}
          </Button>
        );
      });

  handleQuestionClick = (e) => {
    this.props.handleQuestionTypeSelection(e.currentTarget.dataset.type);
  };

  render() {
    return (
      <SimpleModal
        title='Select Question Type'
        className='new-question-type-modal'
        onModalClose={this.props.handleClose}
      >
        <div className='new-question-type-modal__buttons-wrapper'>{this.generateQuestions()}</div>
      </SimpleModal>
    );
  }
}
