import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import makeDataProps from 'lib/makeDataProps';
import './blocky-date-picker.scss';

if (process.env.IS_BROWSER) {
  /**
   * HTML5 `<input type="date">` polyfill for Firefox, Safari
   */
  import('date-input-polyfill');
}

export default class BlockyDatePicker extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.any,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    inputRef: PropTypes.func,
    label: PropTypes.string,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    required: PropTypes.bool,
    value: PropTypes.string
  };

  static defaultProps = {
    className: '',
    onChange: () => {},
    onFocus: () => {},
    inputRef: () => {}
  };

  handleFocus = (e) => {
    this.props.onChange(e);
    this.props.onFocus(e);
  };

  /*
    XXX: Hacks
    Note to developers about input events below:

    This component works alongside the date picker polyfill `date-input-polyfill`.
    We noticed that for some reason, the onChange events being provided to the input
    below were somehow getting swallowed up or clobbered or not attached somehow, but the blur
    and focus events were.

    With that in mind, we are firing the change event we've been provided as part of the onFocus
    handler. The polyfill still fires that, so we just hook into it.

    In the normal HTML5 date, the polyfill is not used and the change event fires properly.
  */
  render() {
    const dataProps = makeDataProps(this.props);

    const label = this.props.label ? (
      <div className='blocky-date-picker__label'>
        <label htmlFor={this.props.name}>{this.props.label}</label>
      </div>
    ) : null;

    const inputClass = classnames('blocky-date-picker__input', {
      'blocky-date-picker__input--error': this.props.error
    });

    const errorContainer = this.props.errorMessage ? (
      <div className='a-form-input__error'>{this.props.errorMessage}</div>
    ) : null;

    const wrapperClassnames = classnames('blocky-date-picker', this.props.className, {
      'blocky-date-picker--required': this.props.required
    });

    return (
      <div className={wrapperClassnames}>
        {label}
        <input
          className={inputClass}
          disabled={this.props.disabled}
          id={this.props.name}
          name={this.props.name}
          type='date'
          onBlur={this.props.onBlur}
          placeholder='YYYY/MM/DD' // Fallback placeholder for polyfill input when input[type=date] unsupported
          onChange={this.props.onChange}
          onFocus={this.handleFocus}
          ref={(node) => {
            this.inputNode = node;
            this.props.inputRef(node);
          }}
          defaultValue={this.props.defaultValue}
          value={this.props.value}
          {...dataProps}
        />
        {errorContainer}
      </div>
    );
  }
}
