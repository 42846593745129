import {fromJS, Map, List} from 'immutable';
import {Store} from 'client/framework';
import interactionEngineActions from '../../InteractionEngineActions';
import questionsListActions from './QuestionsListActions';

/*
 * Base QuestionsListStore should be extended by Stores who require a QuestionsListStore
 * Stores which extend QuestionsListStore must define a `get questionSets` getter method which
 * fetches question sets from the appropriate endpoint.
 */

export default class QuestionsListStore extends Store {
  constructor(name) {
    super(name);
    this.initialData = fromJS({
      activeQuestion: null,
      activeQuestionSet: null,
      forceAutoScrollOnMount: false // When true, active question will call autoScrollList() on itself on componentDidMount
    });
    this.handle(interactionEngineActions.RESET_STORE, this.resetStore);
    this.handle(
      questionsListActions.SET_FORCE_AUTO_SCROLL_ON_MOUNT,
      this._setForceAutoScrollOnMount
    );
    this.handle(questionsListActions.SET_ACTIVE_QUESTION, this._setActiveQuestion);
    this.handle(questionsListActions.SET_ACTIVE_QUESTION_SET, this._setActiveQuestionSet);
    this.handle(questionsListActions.NEXT_QUESTION, this._setNextQuestion);
    this.handle(questionsListActions.PREVIOUS_QUESTION, this._setPreviousQuestion);
    this.handle(questionsListActions.CLEAR_ACTIVE_QUESTION, this._clearActiveQuestion);
    this.handle(questionsListActions.SET_INITIAL_ACTIVE_QUESTION, this._setInitialActiveQuestion);
    this.writeData(this.initialData);
  }

  _setForceAutoScrollOnMount(bool) {
    this.writeData('forceAutoScrollOnMount', bool);
  }

  _setInitialActiveQuestion() {
    if (this.questionSets.isEmpty()) {
      return;
    }
    const questionSet = this.questionSets.first();
    const question = questionSet.getQuestions().first();
    this.writeData((store) =>
      store.set('activeQuestionSet', questionSet).set('activeQuestion', question)
    );
  }

  _setNextQuestion() {
    if (this.questionSets.isEmpty() || !this.activeQuestion) {
      return;
    }
    const currentQuestionId = this.activeQuestion.getId();
    const currentSetQuestions = this.activeQuestionSet.getQuestions();
    const questionSetPosition = currentSetQuestions.findIndex(
      (question) => question.getId() === currentQuestionId
    );

    let nextQuestion;

    if (questionSetPosition < currentSetQuestions.size - 1) {
      nextQuestion = currentSetQuestions.get(questionSetPosition + 1);
    } else {
      const sets = this.questionSets;
      const currentSetId = this.activeQuestionSet.getId();
      const currentQuestionSetPosition = sets.findIndex((set) => set.getId() === currentSetId);

      if (currentQuestionSetPosition >= sets.size - 1) {
        logger.warn('Attempted to navigate past the end of the question set list');
        return;
      }

      const nextSet = sets.get(currentQuestionSetPosition + 1);
      nextQuestion = nextSet.getQuestions().first();
    }

    setImmediate(() => {
      global.document.getElementById(`question-id-${nextQuestion.getId()}`).click();
    });
  }

  _setPreviousQuestion() {
    if (this.questionSets.isEmpty()) {
      return;
    }
    const currentQuestionId = this.activeQuestion.getId();
    const currentSetQuestions = this.activeQuestionSet.getQuestions();
    const questionSetPosition = currentSetQuestions.findIndex(
      (question) => question.getId() === currentQuestionId
    );

    let prevQuestion;

    if (questionSetPosition > 0) {
      prevQuestion = currentSetQuestions.get(questionSetPosition - 1);
    } else {
      const sets = this.questionSets;
      const currentSetId = this.activeQuestionSet.getId();
      const currentQuestionSetPosition = sets.findIndex((set) => set.getId() === currentSetId);

      if (currentQuestionSetPosition === 0) {
        logger.warn('Attempted to navigate to before the beginning of the question set list');
        return;
      }

      const prevSet = sets.get(currentQuestionSetPosition - 1);
      const prevSetQuestionsLength = prevSet.getQuestions().size;
      prevQuestion = prevSet.getQuestions().get(prevSetQuestionsLength - 1);
    }

    setImmediate(() => {
      global.document.getElementById(`question-id-${prevQuestion.getId()}`).click();
    });
  }

  _setActiveQuestion(question) {
    this.writeData('activeQuestion', question);
  }

  _setActiveQuestionSet(questionSet) {
    this.writeData('activeQuestionSet', questionSet);
  }

  _clearActiveQuestion() {
    this.writeData((store) => {
      return store.set('activeQuestion', null).set('activeQuestionSet', null);
    });
  }

  getActiveItemOffset() {
    return this.readData('activeItemOffset');
  }

  shouldPreventAutoScroll() {
    return this.readData('preventAutoScroll');
  }

  shouldAutoScrollOnMount() {
    return this.readData('forceAutoScrollOnMount');
  }

  get activeQuestion() {
    return this.readData('activeQuestion');
  }

  get activeQuestionSet() {
    return this.readData('activeQuestionSet');
  }

  get hasActiveQuestion() {
    return this.readData('activeQuestion') !== null;
  }

  get questionSetIds() {
    return this.questionSets.get('results', new List()).map((set) => set.getId());
  }

  hasQuestionSets() {
    return this.questionSets !== null && !this.questionSets.isEmpty();
  }

  get firstSetAndQuestion() {
    return new Map({
      questionSet: this.questionSets.first(),
      question: this.questionSets
        .first()
        .getQuestions()
        .first()
    });
  }

  hasPreviousQuestion(questionId) {
    if (this.questionSets.isEmpty() || !questionId) {
      return false;
    }
    // If the question belonging to questionId is not the first question, then there are previous questions.
    return (
      this.questionSets
        .first()
        .getQuestions()
        .first()
        .getId() !== questionId
    );
  }

  hasNextQuestion(questionId) {
    if (this.questionSets.isEmpty() || !questionId) {
      return false;
    }
    // If the question belonging to questionId is not the last question, then there are next questions.
    return (
      this.questionSets
        .last()
        .getQuestions()
        .last()
        .getId() !== questionId
    );
  }
}
