import {makeParser} from 'generic/MarkdownRendererV2/markdownRendererUtils';

const parser = makeParser(null, ['paragraph', 'autolink', 'url', 'link', 'text']);

const createTeacherOutput = ({text}) => {
  const syntaxTree = parser.makeSyntaxTree(text);
  return parser.reactOutput(syntaxTree);
};

export default createTeacherOutput;
