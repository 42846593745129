import React, {useContext} from 'react';
import {AnalyticsApiResult} from 'client/Reports/reports.types';
import './answer-breakdown.scss';
import {ReportsContext} from 'client/Reports/Reports.context';
import {Chip, Icon, Text, WithTooltip} from '@albert-io/atomic';

interface MetricsData {
  totalAttempts: number;
  correctAttempts: number;
  incorrectAttempts: number;
  totalExpectedAttempts: number;
  totalPossibleAttempts?: number;
  percentComplete?: number;
  unattemptedQuestions?: number;
}

interface BoundaryPercentages {
  correct: number;
  incorrect: number;
  unattempted: number;
}

export const SimpleAnswerStatus: React.FC<{metrics: AnalyticsApiResult['metrics']}> = ({
  metrics
}) => {
  if (!metrics.has_guesses) {
    return (
      <div className='simple-answer-breakdown simple-answer-breakdown__unattempted'>
        <Text size='s' italic>
          Unanswered
        </Text>
      </div>
    );
  }

  if (metrics.count_correct_attempts > 0) {
    return (
      <div className='simple-answer-breakdown simple-answer-breakdown__correct'>
        <Icon icon='check' />
        <Text bold size='s'>
          Correct
        </Text>
      </div>
    );
  }

  return (
    <div className='simple-answer-breakdown simple-answer-breakdown__incorrect'>
      <Icon icon='close' />
      <Text bold size='s'>
        Incorrect
      </Text>
    </div>
  );
};

export const AttemptsBar: React.FC<{boundaries: BoundaryPercentages; metrics: MetricsData}> = ({
  boundaries,
  metrics
}) => {
  if (metrics.totalExpectedAttempts === 0) {
    return (
      <div className='attempts-bar' data-testid='attempts-bar'>
        <div className='attempts-bar__unattempted' style={{width: `100%`}} />
      </div>
    );
  }
  return (
    <div className='attempts-bar' data-testid='attempts-bar'>
      {boundaries.correct > 0 && (
        <div className='attempts-bar__correct' style={{width: `${boundaries.correct}%`}} />
      )}
      {boundaries.incorrect > 0 && (
        <div className='attempts-bar__incorrect' style={{width: `${boundaries.incorrect}%`}} />
      )}
      {boundaries.unattempted > 0 && (
        <div className='attempts-bar__unattempted' style={{width: `${boundaries.unattempted}%`}} />
      )}
    </div>
  );
};

export const AttemptChips: React.FC<{metrics: MetricsData}> = ({metrics}) => {
  if (metrics.totalExpectedAttempts === 0) {
    return (
      <div className='answer-breakdown__attempt-chips-container'>
        <Text className='u-color_slate-500 u-white-space_nowrap'>No attempts</Text>
      </div>
    );
  }
  return (
    <div className='answer-breakdown__attempt-chips-container'>
      <Chip
        className='answer-breakdown__attempt-chip answer-breakdown__attempt-chip-correct'
        size='small'
      >
        <Text size='s'>{metrics.correctAttempts}</Text>
        <Icon icon='check' iconStyle='regular' />
      </Chip>
      <Chip
        className='answer-breakdown__attempt-chip answer-breakdown__attempt-chip-incorrect'
        size='small'
      >
        <Text size='s'>{metrics.incorrectAttempts}</Text>
        <Icon icon='times' iconStyle='regular' />
      </Chip>
      {metrics.unattemptedQuestions !== undefined && (
        <Chip
          className='answer-breakdown__attempt-chip answer-breakdown__attempt-chip-unattempted'
          size='small'
        >
          <Text size='s'>{metrics.unattemptedQuestions}</Text>
          <Icon icon='circle-dashed' iconStyle='regular' />
        </Chip>
      )}
      <WithTooltip
        display='flex'
        wrapperProps={{
          className: 'answer-breakdown__attempt-chip answer-breakdown__attempt-chip-total-answered'
        }}
        content={
          <div className='u-gap_space-x1'>
            <div className='u-display_flex u-align-items_center u-gap_space-x1'>
              <Text color='primary-inverse'>Completion Rate</Text>
            </div>
            <div className='u-display_flex u-align-items_center u-gap_space-x1'>
              <Text color='primary-inverse'>{`${metrics.totalAttempts}/${metrics.totalPossibleAttempts} = ${metrics.percentComplete}%`}</Text>
            </div>
          </div>
        }
        enabled={
          metrics.totalPossibleAttempts !== undefined && metrics.percentComplete !== undefined
        }
        theme='dark'
        placement='right'
        as={Chip}
        size='small'
      >
        <Text size='s'>{metrics.totalAttempts}</Text>
        <Icon icon='flag-checkered' iconStyle='regular' />
      </WithTooltip>
    </div>
  );
};

export const calculateMetrics = (metrics: AnalyticsApiResult['metrics']): MetricsData => {
  const totalAttempts = metrics.has_guesses ? metrics.count_attempts : 0;
  const correctAttempts = metrics.has_guesses ? metrics.count_correct_attempts : 0;
  const incorrectAttempts = metrics.has_guesses ? metrics.count_incorrect_attempts : 0;
  const unattemptedQuestions = metrics.count_unattempted ?? 0;
  const totalExpectedAttempts = totalAttempts + unattemptedQuestions;

  const metricsData: MetricsData = {
    totalAttempts,
    correctAttempts,
    incorrectAttempts,
    totalExpectedAttempts
  };

  if (metrics.total_possible_attempts !== undefined)
    metricsData.totalPossibleAttempts = metrics.total_possible_attempts;
  if (metrics.percent_complete !== undefined)
    metricsData.percentComplete = Math.round(metrics.percent_complete * 100);
  if (metrics.count_unattempted !== undefined)
    metricsData.unattemptedQuestions = metrics.count_unattempted;

  return metricsData;
};

export const calculateBoundaries = (metrics: MetricsData): BoundaryPercentages => ({
  correct: metrics.totalExpectedAttempts
    ? (metrics.correctAttempts / metrics.totalExpectedAttempts) * 100
    : 0,
  incorrect: metrics.totalExpectedAttempts
    ? (metrics.incorrectAttempts / metrics.totalExpectedAttempts) * 100
    : 0,
  unattempted:
    metrics.unattemptedQuestions && metrics.totalExpectedAttempts
      ? (metrics.unattemptedQuestions / metrics.totalExpectedAttempts) * 100
      : 0
});

interface Props {
  result: AnalyticsApiResult;
}

export const AnswerBreakdown: React.FC<Props> = ({result}) => {
  const {variables, dimensions} = useContext(ReportsContext);

  // below are instances where we are showing the result of a single question
  const shouldShowSimpleStatus =
    (dimensions.includes('students') && variables.questionFilter) ||
    (dimensions.includes('questions') && variables.studentFilter);

  if (shouldShowSimpleStatus) {
    return (
      <div className='u-display_flex u-justify-content_center u-align-items_center'>
        <SimpleAnswerStatus metrics={result.metrics} />
      </div>
    );
  }

  const metricsData = calculateMetrics(result.metrics);
  const boundaries = calculateBoundaries(metricsData);

  return (
    <div className='answer-breakdown'>
      <AttemptsBar boundaries={boundaries} metrics={metricsData} />
      <AttemptChips metrics={metricsData} />
    </div>
  );
};
