// @flow
import {List, Map} from 'immutable';
import {baseGuideExtensions} from '../guideHelpers/baseGuide.extensions';
import type {AuthoringGuideModelV1} from 'resources/GeneratedModels/AuthoringGuide/AuthoringGuideModel.v1';
import type {AuthoringGuideLevelModelV1} from 'resources/GeneratedModels/AuthoringGuideLevel/AuthoringGuideLevelModel.v1';

function findNLevelDepth(structure: Map<string, *>): number {
  let nLevel = 1;
  structure.keySeq().forEach((key) => {
    if (Map.isMap(structure.get(key))) {
      const depth = findNLevelDepth(structure.get(key)) + 1;
      nLevel = Math.max(depth, nLevel);
    }
  });
  return nLevel;
}

export const extensions = {
  ...baseGuideExtensions,

  getNLevelDepth(): number {
    return this.cache('nlevel_depth', () => {
      const structure = this.getMeta().getStructure();
      return findNLevelDepth(structure);
    });
  },

  getThemes(): List<AuthoringGuideLevelModelV1> {
    return this.cache('themes', () => {
      return this.getAuthoringGuideLevels()
        .getCollection()
        .filter((guideLevel) => guideLevel.isTheme());
    });
  },

  getTopics(themeId: ?string): List<AuthoringGuideLevelModelV1> {
    if (!themeId) {
      return this.cache('topics', () => {
        return this.getAuthoringGuideLevels()
          .getCollection()
          .filter((guideLevel) => guideLevel.isTopic());
      });
    }

    return this.cache(`${themeId}-topics`, () => {
      const ids = this.getMeta()
        .getStructure()
        .get(themeId, new Map())
        .keySeq()
        .toList();

      return this.getAuthoringGuideLevels()
        .getCollection()
        .filter((guideLevel) => {
          return ids.contains(guideLevel.getId());
        });
    });
  },

  getSubtopics(themeId: ?string, topicId: ?string): List<AuthoringGuideLevelModelV1> {
    // TODO: can support only specifying one of themeId and topicId... but we probably never need that.
    if (!themeId && !topicId) {
      return this.cache('subtopics', () => {
        return this.getAuthoringGuideLevels()
          .getCollection()
          .filter((guideLevel) => guideLevel.isSubtopic());
      });
    }

    return this.cache(`${themeId}-${topicId}-subtopics`, () => {
      const ids = this.getMeta()
        .getStructure()
        .getIn([themeId, topicId], new Map())
        .keySeq()
        .toList();

      return this.getAuthoringGuideLevels()
        .getCollection()
        .filter((guideLevel) => {
          return ids.contains(guideLevel.getId());
        });
    });
  },

  getGuideLevelById(id: string): AuthoringGuideLevelModelV1 {
    return this.cache(`getGuideLevelById${id}`, () =>
      this.getAuthoringGuideLevels()
        .getCollection()
        .find((guideLevel) => guideLevel.getId() === id)
    );
  },

  doesGuideLevelHaveChildren(id: string): boolean {
    const guideLevel = this.getGuideLevelById(id);
    return guideLevel.getNlevel() === 1
      ? !this.getTopics(id).isEmpty()
      : !this.getSubtopics(guideLevel.getParentId(), id).isEmpty();
  }
};
