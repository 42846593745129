import React from 'react';
import PropTypes from 'prop-types';
import {Link, withRouter} from 'react-router';
import classnames from 'classnames';
import vanillaIEQuestionsListStore from '../VanillaIEQuestionsList/VanillaIEQuestionsList.store';
import {getBreadcrumbs} from './IEBreadcrumbs.utils';
import {SUBHEADER_CLASS} from 'client/InteractionEngineV2/interactionEngineConstants';
import AccessLabel from 'generic/AccessLabel/AccessLabel.react';
import TeacherModeToggle from './TeacherModeToggle/TeacherModeToggle.react';
import {hasSubjectAccess} from 'lib/UserAccessUtil';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';

import './ie-breadcrumbs.scss';

class IEBreadcrumbs extends React.Component {
  static propTypes = {
    router: PropTypes.object.isRequired
  };

  generateAccessLabel({isCrumb, isFree} = {}) {
    const subjectId = vanillaIEQuestionsListStore.getCurrentSubjectId();
    if (!subjectId) {
      return null;
    }

    const {isReady, value} = hasSubjectAccess(subjectId);

    if (!isReady) {
      return null;
    } else if (isCrumb && isFree && !value) {
      return <AccessLabel isSubscriptionLabel={false} className='ie-breadcrumbs__access-label' />;
    } else if (!isCrumb) {
      return (
        <div className='access-label__breadcrumb-wrapper'>
          <div className='access-label__subject-view-wrapper'>
            <AccessLabel isSubscriptionLabel={true} subjectId={subjectId} />
          </div>
          <TeacherModeToggle />
        </div>
      );
    } else {
      return null;
    }
  }

  generateBreadcrumbs() {
    return getBreadcrumbs().map((crumb, i, list) => {
      const isSubjectOrSearchCrumb =
        crumb.get('type') === 'subject' || crumb.get('type') === 'search';
      const helperCopy = crumb.get('helperCopy');
      /**
       * Don't show the helper on the last item (because that's the one we're currently viewing)
       * unless there is only one item, in which case it's the subject, and we do want to show the helper
       */
      const hoverHelperText =
        i + 1 !== list.size || isSubjectOrSearchCrumb ? (
          <span className='ie-breadcrumbs__item-hover-text'>{helperCopy}</span>
        ) : null;
      const displayName = (
        <MarkdownRendererV2
          className='ie-breadcrumbs__item-label'
          text={crumb.get('displayName')}
        />
      );
      const subject = this.props.router.params.subject;
      const path = isSubjectOrSearchCrumb
        ? `/${crumb.get('urlSlug')}`
        : `/learn/${subject}/${crumb.get('urlSlug')}?page=1`;
      return (
        <Link className='ie-breadcrumbs__item' to={path} key={path}>
          {hoverHelperText}
          {displayName}
          {this.generateAccessLabel({isCrumb: true, isFree: crumb.get('isFree')})}
        </Link>
      );
    });
  }

  render() {
    const className = classnames('ie-breadcrumbs', SUBHEADER_CLASS);
    return (
      <div className={className}>
        {this.generateBreadcrumbs()}
        {this.generateAccessLabel()}
      </div>
    );
  }
}

export default withRouter(IEBreadcrumbs);
