const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export function createYearObject() {
  return MONTHS.reduce((acc, curr) => {
    acc[curr] = {
      count: 0,
      easy: 0,
      moderate: 0,
      difficult: 0
    };
    return acc;
  }, {});
}

export function createTypeObject() {
  return {
    multipleChoice: 0,
    all: 0,
    freeResponse: 0,
    twoWay: 0,
    passageCorrection: 0,
    fillInTheBlank: 0,
    freeEntry: 0,
    snippetSelection: 0,
    textHighlight: 0,
    graphing: 0
  };
}
