/**
 * @todo: If an author's correction is multiple words, ensure that the words are only separated by one space before sending to the back end
 */
import React from 'react';
import {useQuestionEditorV2Store} from 'client/EditPage/V2/QuestionEditorV2Store';
import {PRIMARY_CONTENT_LANGUAGE} from 'client/EditPage/V2/QuestionEditorV2Store.types';
import {AuthoringQuestionModelV1} from 'resources/GeneratedModels/AuthoringQuestion/AuthoringQuestionModel.v1';

import {UncorrectedTextEditor} from './UncorrectedTextEditor';
import {DefineCorrectionsEditor} from './DefineCorrectionsEditor.react';

import './passage-correction-editor.scss';

interface Props {
  question: AuthoringQuestionModelV1;
  onModelChange: (question: AuthoringQuestionModelV1, ...fields: string[]) => void;
}

const PassageCorrectionEditor = ({onModelChange, question}: Props) => {
  const isPrimaryLanguage = useQuestionEditorV2Store(
    (state) => state.currentLanguage === PRIMARY_CONTENT_LANGUAGE
  );
  return (
    <div className='passage-correction-editor'>
      <UncorrectedTextEditor onModelChange={onModelChange} question={question} />
      {isPrimaryLanguage && (
        <DefineCorrectionsEditor onModelChange={onModelChange} question={question} />
      )}
    </div>
  );
};

export default PassageCorrectionEditor;
