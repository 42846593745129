import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import withTracking from 'lib/hocs/withTracking';
import {Button, Dialogue, Icon, Modal, Text} from '@albert-io/atomic';

const TrackedButton = withTracking(Button, {
  onClick: {
    event: 'click',
    type: 'button',
    feature: 'Class Subjects in Classroom',
    component: 'Confirmation Modal',
    content: 'Close access'
  }
});

export default function RestrictFreePracticeModal({
  fromClassroom,
  handleClose,
  onCancel,
  onRestrict
}) {
  return (
    <Modal
      ariaLabel='Restrict free practice of this subject.'
      handleClose={handleClose}
      role='dialog'
    >
      {({modalContentStyle}) => {
        return (
          <Dialogue
            inModal
            smallWidth
            handleClose={() => {
              onCancel();
              handleClose();
            }}
            className={classnames('restrict-free-practice-modal', modalContentStyle)}
          >
            <Dialogue.Title align='center'>
              <Icon icon='exclamation-triangle' iconStyle='regular' />
            </Dialogue.Title>
            {fromClassroom ? (
              <Dialogue.Body align='center'>
                <Text as='p' className='u-mar-b_2'>
                  Close access to independent practice?
                </Text>
                <Text as='p'>
                  You will be able reopen access at any time from the <i>All Class Subjects</i> tab
                  or from the <i>Library</i>.
                </Text>
              </Dialogue.Body>
            ) : (
              <Dialogue.Body align='center'>Close access to independent practice?</Dialogue.Body>
            )}
            <Dialogue.BtnGroup direction='row'>
              <Button
                onClick={() => {
                  onCancel();
                  handleClose();
                }}
                color='secondary'
              >
                Cancel
              </Button>
              <TrackedButton
                onClick={() => {
                  onRestrict();
                  handleClose();
                }}
                color='negative'
              >
                Close access
              </TrackedButton>
            </Dialogue.BtnGroup>
          </Dialogue>
        );
      }}
    </Modal>
  );
}

RestrictFreePracticeModal.propTypes = {
  fromClassroom: PropTypes.bool,
  handleClose: PropTypes.func,
  onCancel: PropTypes.func,
  onRestrict: PropTypes.func
};

RestrictFreePracticeModal.defaultProps = {
  onCancel: () => {},
  onRestrict: () => {}
};
