// @flow
import moment from 'moment-timezone';

export default {
  hasActiveSchoolLicense(): boolean {
    return this.getIn(['meta', 'active_school_license']);
  },

  getLicenseEndDateForSchool(schoolId) {
    const endDate = this.getMeta()
      .getSchoolLicensesInfo()
      .find((item) => item.get('school_id') === schoolId)
      ?.get('school_license_end_date');
    return endDate ? moment(endDate) : null;
  },

  getLicenseStatusForSchool(schoolId) {
    const status = this.getMeta()
      .getSchoolLicensesInfo()
      .find((item) => item.get('school_id') === schoolId)
      ?.get('has_school_license');

    return status;
  }
};
