import React from 'react';
import PropTypes from 'prop-types';
import {resource} from '@albert-io/json-api-framework/request/builder';
import awaitMandarkQueries from 'lib/hocs/awaitMandarkQueries';
import LoadingIndicator from 'client/generic/LoadingIndicator.react';
import {AuthoringQuestionSetModelV1} from 'resources/GeneratedModels/AuthoringQuestionSet/AuthoringQuestionSetModel.v1';
import {
  Button,
  LegacyFindAndApply,
  Text,
  Modal,
  IconButton,
  Checkbox,
  ListGroupItem,
  StatusText
} from '@albert-io/atomic';
import {List, Map} from 'immutable';

import classnames from 'classnames';

import {SEARCH_PARAMETERS, setURLSearchParameter} from '../../URLSearchParameters';

import './guide-level-selector.scss';

class GuideLevelSelector extends React.Component {
  static propTypes = {
    /** Handles disabled state when waiting on mandark request */
    loading: PropTypes.bool,
    /**
     Type of guide that is being filtered
     *IMPORTANT this is named poorly, `exam` is not a guide but instead the exam resource that will soon be deprecated.
     After which, this will be properly named and we can cleanup this file.
     */
    guideType: PropTypes.oneOf(['practice', 'exam', 'free_response', 'assessment']),
    /** AuthoringQuestionSet sent from awaitMandarkQueries */
    questionSet: PropTypes.instanceOf(AuthoringQuestionSetModelV1),
    /** Previously applied filtered elements */
    existingSelections: PropTypes.array,
    /** Closes the modal */
    handleClose: PropTypes.func
  };

  constructor(props) {
    super(props);

    const activeGuide = this.getActiveGuide();
    const shouldGetGuide = !this.props.loading && !!activeGuide;
    this.state = {
      guideTree:
        !shouldGetGuide || props.guideType === 'exam'
          ? null
          : this.getActiveGuide().getMeta().getStructure(),
      guideLevelMap:
        !shouldGetGuide || props.guideType === 'exam'
          ? Map()
          : this.createGuideLevelMap(this.getActiveGuide().getSortedGuideLevels()),
      searchString: '',
      selectedExams:
        props.guideType === 'exam' && props.existingSelections.length
          ? new Set(props.existingSelections)
          : new Set()
    };
  }

  componentDidMount() {
    // We only want to update the guide level if there are existing selections,
    // it is not exam filtered, the questionSet is known, and the guideLevelMap has been created
    if (
      !this.props.loading &&
      this.props.existingSelections &&
      this.props.guideType !== 'exam' &&
      !this.state.guideLevelMap.isEmpty() &&
      this.props.questionSet
    ) {
      this.updateGuideLevelMap();
    }
  }

  /**
   * Returns an empty state for Find and apply
   *
   * @param message
   */
  getEmptyState = (message) => {
    return (
      <div className='guide-level-selector__empty-state'>
        <Text bold color='secondary' size='l'>
          {message}
        </Text>
      </div>
    );
  };

  /**
   * Gets the correct guide type
   *
   * @returns {AuthoringGuideModelV1}
   */
  getActiveGuide = () => {
    return this.props.questionSet
      .getAuthoringSubject()
      .getAuthoringGuides()
      .find((guide) => this.props.guideType === guide.getGuideType());
  };

  /**
   * is called during componentDidMount to ensure that all previously filtered items appear open/selected
   *
   * @param {string} guideLevelId is passed in for recursive calls
   */
  updateGuideLevelMap = (guideLevelId) => {
    const lineage = guideLevelId ? this.getGuideLineage(guideLevelId) : [];
    lineage.pop();
    this.state.guideTree.getIn(lineage).forEach((directChildren, id) => {
      const allChildren = this.getChildren(id);
      const isExpanded = allChildren.some((child) => {
        return this.props.existingSelections.includes(child);
      });
      if (isExpanded) {
        this.toggleExpandGuideLevel(id);
      }
      const shouldBeSelected = this.props.existingSelections.includes(id);
      if (shouldBeSelected && !this.state.guideLevelMap.get(id).isSelected) {
        this.toggleSelectedGuideLevel(id);
      }
      if (!directChildren.isEmpty()) {
        directChildren.keySeq().forEach((id) => {
          this.updateGuideLevelMap(id);
        });
      }
    });
  };

  /**
   * Takes the initial guide and reduces the sorted levels to a Map of ID -> guideLevel
   *
   * @param  {AuthoringGuideModelV1}
   * @param sortedGuideLevels
   * @returns {Map}
   */
  createGuideLevelMap = (sortedGuideLevels) => {
    const guideLevelMap = sortedGuideLevels.reduce((acc, guideLevel) => {
      const guideObj = {
        guideLevel,
        isExpanded: false,
        isSelected: false,
        isIndeterminate: false
      };
      return acc.set(guideLevel.getId(), guideObj);
    }, Map());
    return guideLevelMap;
  };

  /**
   * Toggles the expanded property on a guide level
   *
   * @param  {string} guideLevelId
   */
  toggleExpandGuideLevel = (guideLevelId) => {
    const {guideLevelMap} = this.state;
    const isExpanded = !guideLevelMap.get(guideLevelId).isExpanded;
    this.setState((state) => {
      const guideLevelMap = state.guideLevelMap.update(guideLevelId, (obj) => ({
        ...obj,
        isExpanded
      }));
      return {guideLevelMap};
    });
  };

  /**
   * Handles initial logic of toggling an element and all of its children
   * also updates indeterminate states
   *
   * @param  {string} guideLevelId
   * @param  {boolean} selected If an element
   */
  toggleSelectedGuideLevel = (guideLevelId, selected) => {
    const guideLevel = this.state.guideLevelMap.get(guideLevelId);
    const isSelected = selected !== undefined ? selected : !guideLevel.isSelected;
    const isExpanded = isSelected || guideLevel.isExpanded;
    this.setState(
      (state) => {
        const guideLevelMap = state.guideLevelMap.update(guideLevelId, (obj) => ({
          ...obj,
          isSelected,
          isIndeterminate: false,
          isExpanded
        }));
        return {guideLevelMap};
      },
      () => {
        this.toggleChildrenSelection(guideLevelId, isSelected);
        if (selected === undefined) {
          this.checkIndeterminate(guideLevelId);
        }
      }
    );
  };

  /**
   * If a theme or topic are selected all children must also be selected
   *
   * @param  {string} guideLevelId
   * @param  {boolean} toggleValue
   */
  toggleChildrenSelection = (guideLevelId, toggleValue) => {
    const lineage = this.getGuideLineage(guideLevelId);
    const guideLevel = this.state.guideTree.getIn(lineage);
    guideLevel.keySeq().forEach((id) => {
      this.toggleSelectedGuideLevel(id, toggleValue);
    });
  };

  /**
   * Gets the lineage of element being passed in and checks the parent element
   * to decide what selected state it should be recursively runs up the tree
   * until we are at the Theme level
   *
   * @param  {string} guideLevelId
   */
  checkIndeterminate = (guideLevelId) => {
    const lineage = this.getGuideLineage(guideLevelId);
    // Lineage includes curr ID and we want to start at parent
    lineage.pop();
    if (!lineage.length) {
      return;
    }
    const parentTree = this.state.guideTree.getIn(lineage);
    let selectedElms = 0;
    let unselectedElms = 0;
    let indeterminateElms = false;
    parentTree.forEach((childId, id) => {
      const child = this.state.guideLevelMap.get(id);
      if (child.isIndeterminate) {
        indeterminateElms = true;
      } else if (child.isSelected) {
        selectedElms++;
      } else {
        unselectedElms++;
      }
    });
    const directParentId = lineage.pop();
    if (
      indeterminateElms ||
      (selectedElms && unselectedElms) ||
      (!unselectedElms && selectedElms !== parentTree.size)
    ) {
      this.toggleIndeterminateGuideLevel(directParentId, true);
    } else {
      const isSelected = !unselectedElms && !indeterminateElms;
      this.toggleSelectedGuideLevel(directParentId, isSelected);
    }
  };

  /**
   * handles setting indeterminate property
   *
   * @param  {string}  guideLevelId
   * @param  {boolean} isIndeterminate
   */
  toggleIndeterminateGuideLevel = (guideLevelId, isIndeterminate) => {
    this.setState(
      (state) => {
        const guideLevelMap = state.guideLevelMap.update(guideLevelId, (obj) => ({
          ...obj,
          isIndeterminate,
          isSelected: true
        }));
        return {guideLevelMap};
      },
      () => {
        this.checkIndeterminate(guideLevelId);
      }
    );
  };

  /**
   * Gets the lineage of an element to be used within the guideTree including id of passed in element
   *
   * @param  {string} guideLevelId - a guideLevelId
   * @returns {string[]} of ids ordered theme -> topic -> subtopic if 3 levels exist
   */
  getGuideLineage = (guideLevelId) => {
    const lineage = [];
    let {guideLevel} = this.state.guideLevelMap.get(guideLevelId);
    lineage.push(guideLevelId);
    while (guideLevel.getParentId()) {
      lineage.push(guideLevel.getParentId());
      guideLevel = this.state.guideLevelMap.get(guideLevel.getParentId()).guideLevel;
    }
    return lineage.reverse();
  };

  /**
   * initially filters through all levels for matching search string,
   * then adds all IDs of matching levels and their parents and children to a set, to maintain tree integrity
   * and filters the initial guide levels one more time to include all matches, children, and their parents
   *
   * @param  {Map} sortedGuideLevels
   * @param  {string} searchString
   * @returns {Set} The filtered guide levels
   */
  getFilteredGuideList = (sortedGuideLevels, searchString) => {
    const filteredLevels = sortedGuideLevels.filter((guide) => {
      return guide.getName().toLowerCase().indexOf(searchString.toLowerCase()) > -1;
    });
    const filteredIds = filteredLevels.reduce((acc, level) => {
      const lineage = this.getGuideLineage(level.getId());
      const children = this.getChildren(lineage[lineage.length - 1]);
      return new Set([...acc, ...children, ...lineage]);
    }, new Set());

    return sortedGuideLevels.filter((guide) => {
      return filteredIds.has(guide.getId());
    });
  };

  /**
   * returns an array of ids that are an elements children
   *
   * @param guideLevelId
   */
  getChildren = (guideLevelId) => {
    const lineage = this.getGuideLineage(guideLevelId);
    const guideLevel = this.state.guideTree.getIn(lineage);
    return [...guideLevel.keySeq().toArray(), ...guideLevel.flatten(true).keySeq().toArray()];
  };

  /**
   * Renders the actual list of elements and handles logic for selecting/expanding rows
   *
   * @param  {Object[]}
   * @param sortedGuideLevels
   */
  renderGuideLevels = (sortedGuideLevels) => {
    return sortedGuideLevels.reduce((acc, guideLevel) => {
      const guideLevelId = guideLevel.getId();

      // handles case where all levels are expanded and theme is closed
      // has to ensure that subtopic closes as well
      const lineage = this.getGuideLineage(guideLevelId);
      const guideLevelInTree = this.state.guideTree.getIn(lineage);
      lineage.pop();
      const isHidden = lineage.some((id) => !this.state.guideLevelMap.get(id).isExpanded);
      if (isHidden) {
        return acc;
      }

      const nLevel = guideLevel.getNlevel();
      const hasChildren = !guideLevelInTree.isEmpty();
      const isExpanded = hasChildren && this.state.guideLevelMap.get(guideLevelId).isExpanded;
      const isPublished = guideLevel.isPublished();
      const publishedStatus = isPublished ? 'published' : 'unpublished';
      const icon = (
        <IconButton
          className='guide-level-selector__list-item-icon'
          icon={isExpanded ? 'caret-down' : 'caret-right'}
          label='Expand or close area'
          onClick={() => this.toggleExpandGuideLevel(guideLevelId)}
        />
      );

      return acc.push(
        <ListGroupItem
          key={guideLevelId}
          className={`guide-level-selector__list-item guide-level-selector__list-item--${nLevel}-level`}
        >
          <div className='guide-level-selector__list-item--right'>
            {hasChildren && icon}
            <Checkbox
              onChange={() => this.toggleSelectedGuideLevel(guideLevelId)}
              checked={this.state.guideLevelMap.get(guideLevelId).isSelected}
              className={classnames('u-mar-r_2', {
                'guide-level-selector__list-item-no-children': !hasChildren
              })}
              indeterminate={this.state.guideLevelMap.get(guideLevelId).isIndeterminate}
            />
            <Text>{guideLevel.getName()}</Text>
          </div>
          <StatusText
            bold
            size='s'
            color='secondary'
            italic={!isPublished}
            className={classnames({
              'guide-level-selector__published': isPublished,
              'guide-level-selector__unpublished': !isPublished
            })}
          >
            {publishedStatus}
          </StatusText>
        </ListGroupItem>
      );
    }, List());
  };

  /**
   * Selects all items in the modal
   */
  selectAll = () => {
    if (this.props.guideType !== 'exam') {
      this.state.guideTree.keySeq().forEach((id) => {
        return this.toggleSelectedGuideLevel(id, true);
      });
    } else {
      this.getExamList().forEach((exam) => {
        this.toggleSelectedExams(exam.getId(), true);
      });
    }
  };

  /**
   * Adds/removes exams from selectedExams array in state
   *
   * @param  {string} examId
   * @param isSelectAll
   */
  toggleSelectedExams = (examId, isSelectAll) => {
    this.setState((state) => {
      const existingSelections = state.selectedExams;
      const newSelectedExams = new Set(existingSelections);
      if (existingSelections.has(examId) && !isSelectAll) {
        newSelectedExams.delete(examId);
      } else {
        newSelectedExams.add(examId);
      }
      return {
        selectedExams: newSelectedExams
      };
    });
  };

  /**
   * Returns the List of exams
   *
   * @returns {List}
   */
  getExamList = () => {
    return this.props.questionSet.getAuthoringSubject().getAuthoringExams();
  };

  /**
   * filters exam list for exams that match the search string
   *
   * @param  {List} sortedGuideLevels
   * @param  {string} searchString
   * @returns {List} The filtered exam Ids
   */
  getFilteredExamList = (sortedGuideLevels, searchString) => {
    return sortedGuideLevels.filter((exam) => {
      return exam.getName().toLowerCase().includes(searchString.toLowerCase());
    });
  };

  /**
   * Renders the actual list of exmas and handles logic for selecting
   *
   * @param  {Object[]}
   * @param sortedGuideLevels
   */
  renderExamList = (sortedGuideLevels) => {
    return sortedGuideLevels.reduce((acc, exam) => {
      const examId = exam.getId();

      const isPublished = exam.isPublished();
      const publishedStatus = isPublished ? 'published' : 'unpublished';

      return acc.push(
        <ListGroupItem
          key={examId}
          className='guide-level-selector__list-item guide-level-selector__list-item--1-level'
        >
          <div className='guide-level-selector__list-item--right'>
            <Checkbox
              onChange={() => this.toggleSelectedExams(examId)}
              checked={this.state.selectedExams.has(examId)}
              className='guide-level-selector__list-item-no-children u-mar-r_2'
            />
            <Text>{exam.getName()}</Text>
          </div>
          <StatusText
            bold
            size='s'
            color='secondary'
            italic={!isPublished}
            className={classnames({
              'guide-level-selector__published': isPublished,
              'guide-level-selector__unpublished': !isPublished
            })}
          >
            {publishedStatus}
          </StatusText>
        </ListGroupItem>
      );
    }, List());
  };

  /**
   * Applies filter of selected elements by adding them to URLParams
   * also resets filter of other guide level to stop both exams & frq/practice
   * from being filtered at the same time
   */
  applyFilter = () => {
    let selectedItems;
    if (this.props.guideType !== 'exam') {
      selectedItems = this.state.guideLevelMap
        .filter((guideLevel) => {
          return guideLevel.isSelected && !guideLevel.isIndeterminate;
        })
        .reduce((acc, level) => {
          return acc.push(level.guideLevel.getId());
        }, List());
      this.state.guideTree.keySeq().forEach((id) => {
        const guideItem = this.state.guideLevelMap.get(id);
        if (guideItem.isSelected && !guideItem.isIndeterminate) {
          const guideLevelChildrenIds = this.getChildren(id);
          selectedItems = selectedItems.filter(
            (guideLevelId) => !guideLevelChildrenIds.includes(guideLevelId)
          );
        }
      });
      setURLSearchParameter(SEARCH_PARAMETERS.examIds, '');
      setURLSearchParameter(SEARCH_PARAMETERS.guideLevelIds, selectedItems.join(','));
    } else {
      selectedItems = [...this.state.selectedExams];
      setURLSearchParameter(SEARCH_PARAMETERS.guideLevelIds, '');
      setURLSearchParameter(SEARCH_PARAMETERS.examIds, selectedItems.join(','));
    }
    this.props.handleClose();
  };

  /**
   * Resets the modal to have no selections and nothing expanded
   */
  resetSelections = () => {
    if (this.props.guideType === 'exam') {
      this.setState({
        selectedExams: new Set()
      });
    } else {
      this.setState({
        guideLevelMap: this.createGuideLevelMap(this.getActiveGuide().getSortedGuideLevels())
      });
    }
  };

  emptyModal = () => {
    return (
      <Modal ariaLabel='Find and apply' handleClose={this.props.handleClose}>
        {({CloseButtonWrapper, modalContentStyle}) => {
          return (
            <LegacyFindAndApply className={classnames('guide-level-selector', modalContentStyle)}>
              <LegacyFindAndApply.Header />
              <LegacyFindAndApply.Body className='guide-level-selector__body u-display_flex u-margin_auto'>
                <Text className='u-mar_2' size='xl'>
                  This guide does not exist
                </Text>
              </LegacyFindAndApply.Body>
              <LegacyFindAndApply.Footer>
                {() => {
                  return (
                    <LegacyFindAndApply.BtnGroup>
                      <CloseButtonWrapper>
                        <Button color='secondary'>Cancel</Button>
                      </CloseButtonWrapper>
                    </LegacyFindAndApply.BtnGroup>
                  );
                }}
              </LegacyFindAndApply.Footer>
            </LegacyFindAndApply>
          );
        }}
      </Modal>
    );
  };

  render() {
    const {loading} = this.props;
    const isExams = this.props.guideType === 'exam';
    if (!this.props.questionSet && !isExams) {
      return null;
    }
    const guide = loading && !isExams ? null : this.getActiveGuide();

    if (!guide && !loading && !isExams) {
      return this.emptyModal();
    }

    let sortedGuideLevels;
    if (!loading && this.props.questionSet && guide) {
      sortedGuideLevels = isExams ? this.getExamList : guide?.getSortedGuideLevels();
    }

    const guideIsEmpty = isExams
      ? this.getExamList().isEmpty()
      : guide.getSortedGuideLevels().isEmpty();

    return (
      <Modal ariaLabel='Find and apply' handleClose={this.props.handleClose}>
        {({CloseButtonWrapper, modalContentStyle}) => {
          return (
            <LegacyFindAndApply className={classnames('guide-level-selector', modalContentStyle)}>
              <LegacyFindAndApply.Header />
              <LegacyFindAndApply.Body className='guide-level-selector__body'>
                {({searchString}) => {
                  if (loading) {
                    return <LoadingIndicator />;
                  }
                  const itemList = isExams ? this.getExamList() : guide.getSortedGuideLevels();
                  if (searchString) {
                    sortedGuideLevels = isExams
                      ? this.getFilteredExamList(itemList, searchString)
                      : this.getFilteredGuideList(itemList, searchString);
                    if (sortedGuideLevels.isEmpty()) {
                      return this.getEmptyState(`There are no results for ${searchString}.`);
                    }
                  } else {
                    sortedGuideLevels = itemList;
                    if (sortedGuideLevels.isEmpty()) {
                      return this.getEmptyState('There are no items in this guide.');
                    }
                  }
                  return isExams
                    ? this.renderExamList(sortedGuideLevels)
                    : this.renderGuideLevels(sortedGuideLevels);
                }}
              </LegacyFindAndApply.Body>
              <LegacyFindAndApply.Footer>
                {() => {
                  return (
                    <LegacyFindAndApply.BtnGroup>
                      {!guideIsEmpty && (
                        <Button
                          disabled={loading || guideIsEmpty}
                          color='secondary'
                          onClick={this.selectAll}
                        >
                          Select all
                        </Button>
                      )}
                      <CloseButtonWrapper>
                        <Button disabled={loading} color='secondary'>
                          Cancel
                        </Button>
                      </CloseButtonWrapper>
                      {!guideIsEmpty && (
                        <>
                          <Button
                            disabled={loading || guideIsEmpty}
                            color='secondary'
                            onClick={this.resetSelections}
                          >
                            Clear selection
                          </Button>
                          <Button
                            disabled={loading || guideIsEmpty}
                            color='secondary'
                            onClick={this.applyFilter}
                          >
                            Apply
                          </Button>
                        </>
                      )}
                    </LegacyFindAndApply.BtnGroup>
                  );
                }}
              </LegacyFindAndApply.Footer>
            </LegacyFindAndApply>
          );
        }}
      </Modal>
    );
  }
}

const getGuideLevelsQuery = (questionSetId) => {
  return resource('authoring_question_set_v1')
    .mandarkEndpoint(['authoring_question_sets_v1', questionSetId])
    .include('authoring_subject_v1.authoring_guides_v1.authoring_guide_levels_v1')
    .include('authoring_questions_v1')
    .include('authoring_guide_levels_v1')
    .include('sections_v1')
    .withMeta('authoring_guide_level_v1,authoring_guide_v1,authoring_question_set_v1')
    .meta({
      context: {
        alignment_details: true
      }
    });
};

/**
 * Allows for the overall component to still appear and places the loading icon within it
 */
export default awaitMandarkQueries(
  (props) => ({
    queries: {
      questionSet: getGuideLevelsQuery(props.questionSetId)
    }
  }),
  GuideLevelSelector
);
