/**
 * # SingleSearchAndSelect
 *
 * A component that provides a search and select functionality with a single selection allowed. Inherits all props from `SearchAndSelect`
 * If multi select is wanted, look at `MultiSearchAndSelect`
 *
 * ## Props
 *
 * - `onSelect` (function): Callback function triggered when an item is selected.
 * - `onClear` (function): Callback function triggered when the selection is cleared.
 * - `onChange` (function): Callback function triggered when the selection changes.
 * - `content` (function): Function that renders the content of the selected item.
 * - `defaultValue` (object): The default selected item.
 * - `inputType` (string): The type of input to be rendered. Can be 'search' or 'text'.
 * - `label` (string): The label for the input field.
 * - `defaultValue` (string): The default value for the search input.
 * - `query` (queryBuilderPropType): The query builder for fetching data.
 * - `openOnFocus` (boolean): Whether to open the dropdown on input focus.
 * - `iconRight` (IconProp): The icon to be displayed on the right side of the input field.
 * - `replaceOnSelect` (boolean): Whether to replace the search input value with the selected item's content.
 * - `closeOnSelect` (boolean): Whether to close the dropdown after selecting an item.
 * - `searchFilter` (function): The function used to filter the search results.
 * - `listItemContent` (function): The function used to render the content of each dropdown item in the list.
 * - `onClear` (function): The function called when the search input is cleared.
 * - `onChange` (function): The function called when the search input value changes.
 * - `customFooterRenderer` (function): The function used to render a custom footer in the dropdown.
 * - `error` (boolean): Whether there is an error in the input field.
 * - `placeholder` (string): The placeholder text for the input field.
 *
 * ## Usage
 *
 * ```jsx
 * <SingleSearchAndSelect
 *   onSelect={handleSelect}
 *   onClear={handleClear}
 *   onChange={handleChange}
 *   content={renderContent}
 *   defaultValue={defaultItem}
 * />
 * ```
 */
import React from 'react';
import PropTypes from 'prop-types';

import SearchAndSelect from '../SearchAndSelect.react';

export default class SingleSearchAndSelect extends React.Component {
  static propTypes = {
    onSelect: PropTypes.func,
    onClear: PropTypes.func,
    onChange: PropTypes.func,
    content: PropTypes.func,
    defaultValue: PropTypes.object,
    replaceOnSelect: PropTypes.bool
  };

  static defaultProps = {
    onSelect: () => {},
    onClear: () => {},
    onChange: () => {},
    replaceOnSelect: true
  };

  setSelection = (selectedItem) => {
    this.props.onSelect(selectedItem);
  };

  clearSelection = () => {
    this.props.onSelect(null);
    this.props.onChange(null);
  };

  onChange = (item) => {
    this.props.onChange(item);

    if (!item) {
      this.clearSelection();
    }
  };

  render() {
    const renderedDefaultValue = this.props.defaultValue
      ? this.props.content(this.props.defaultValue)
      : null;
    return (
      <SearchAndSelect
        {...this.props}
        onSelect={this.setSelection}
        onClear={this.clearSelection}
        onChange={this.onChange}
        defaultValue={renderedDefaultValue}
        closeOnSelect
        replaceOnSelect={this.props.replaceOnSelect}
      />
    );
  }
}
