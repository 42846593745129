// @flow
import {Range, Map} from 'immutable';
import type {List} from 'immutable';

export const passageCorrectionQuestionExtensions = {
  getUncorrectedText(): string {
    return this.get('uncorrected_text');
  },

  getRegionIndices(): Map<string, number> {
    return this.get('region_indices', Map());
  },

  getEnumeratedRegionIndices(): List<number> {
    const func = () =>
      this.getRegionIndices().reduce((acc, region) => {
        const indicesInRegion = Range(
          region.get('start_index'),
          region.get('end_index') + 1
        ).reduce((acc, val) => {
          return acc.set(val, true);
        }, Map());
        return acc.merge(indicesInRegion);
      }, Map());

    return this.cache('getEnumeratedRegionIndices', func);
  },

  isOptionIndexInteractive(optionIndex: number): boolean {
    return this.getEnumeratedRegionIndices().get(parseInt(optionIndex, 10)) === true;
  },

  getRegionForOptionIndex(optionIndex: number): Map<string, any> {
    const matchedRegion = this.getRegionKeyForOptionIndex(optionIndex);
    return this.getValidResponse().get(matchedRegion);
  },

  getRegionKeyForOptionIndex(optionIndex: number): Map<string, any> {
    const func = () =>
      this.getRegionIndices().findKey((region) => {
        return (
          [region.get('start_index'), optionIndex, region.get('end_index')].sort(
            (a, b) => a - b
          )[1] === optionIndex
        );
      });
    return this.cache(`getRegionKeyForOptionIndex/${optionIndex}`, func);
  },

  getSolutionsForRegionKey(regionKey: string): List<string> {
    return this.getValidResponse().getIn([regionKey, 'solutions']);
  },

  isRegionDistractor(regionKey: string): boolean {
    return this.getSolutionsForRegionKey(regionKey) === null;
  },

  doesRegionContainOptionIndex(region: Map<string, any>, optionIndex: number): boolean {
    const matchedStartIndex = region.get('start_index');
    const matchedEndIndex = region.get('end_index');
    return matchedStartIndex <= optionIndex && optionIndex <= matchedEndIndex;
  }
};
