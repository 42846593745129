// @flow
import moment from 'moment';
import {fromJS, List, Map} from 'immutable';
import {Store} from 'client/framework';
import dateRangeActions from './DateRange.actions';

function dateRangeValidator(
  startDate: string,
  endDate: string,
  allowNull: boolean
): Map<string, *> {
  let isValid = true;

  if (startDate && endDate) {
    isValid = moment(startDate)
      .startOf('day')
      .isBefore(moment(endDate).endOf('day'));
  } else if (!allowNull) {
    isValid = false;
  }

  const errorMessage = !isValid ? 'Please enter a valid date range' : '';

  return new Map({
    isValid,
    errorMessage
  });
}

function dateMaxRangeValidator(
  startDate: string,
  endDate: string,
  maxRange: number
): Map<string, *> {
  const start = moment(startDate);
  const end = moment(endDate);

  const dayDifference = end.diff(start, 'days');
  const isValid = dayDifference <= maxRange;
  const errorMessage = !isValid ? `Date range must not be greater than ${maxRange} days` : '';

  return new Map({
    isValid,
    errorMessage
  });
}

function dateValidator(date: string): Map<string, *> {
  const isValid = !!date;
  const errorMessage = !isValid ? 'Please enter a date' : '';
  return new Map({
    isValid: !!date,
    errorMessage
  });
}

export default class DateRangeStore extends Store {
  constructor(name: string) {
    super(name);
    this.initialData = fromJS({
      allowNull: true,
      maxRange: null,
      startDate: null,
      endDate: null,
      errorMap: {}
    });
    this.setInitialData(this.initialData);
    this.writeData('errorMap', new Map());
    this.handleTargeted(dateRangeActions.SET_ALLOW_NULL, this.setProperty('allowNull'));
    this.handleTargeted(dateRangeActions.SET_START_DATE, this._setStartDate);
    this.handleTargeted(dateRangeActions.SET_END_DATE, this._setEndDate);
    this.handleTargeted(dateRangeActions.SET_MAX_RANGE, this._setMaxRange);
    this.handleTargeted(dateRangeActions.VALIDATE_FORM, this._validateRange);
    this.handleTargeted(dateRangeActions.RESET_STORE, this._resetStore);
  }

  _resetStore() {
    this.writeData(this.initialData);
  }

  _setStartDate(startDate: string) {
    this.writeData('startDate', startDate);
  }

  _setEndDate(endDate: string) {
    this.writeData('endDate', endDate);
  }

  _setMaxRange(maxRange: number) {
    this.writeData('maxRange', maxRange);
  }

  /**
   * Runs thru validation functions for our form inputs.
   *
   * This produces an error map.
   *
   * The error map contains form field names as keys, and as values
   * contains a map in the following form:
   *   {
   *     isValid: boolean,
   *     errorMessage: string
   *   }
   *
   * If a field is not in the map, we can assume it is valid.
   */
  _validateRange() {
    const fieldValidators = new Map({
      startDate: dateValidator,
      endDate: dateValidator
    });

    const complexValidators = new Map({
      range: () => {
        return dateRangeValidator(this.getStartDate(), this.getEndDate(), this.isAllowNull());
      },
      maxRange: () =>
        dateMaxRangeValidator(this.getStartDate(), this.getEndDate(), this.getMaxRange())
    });

    let errorMap = fieldValidators.reduce((reduction, validator, fieldName) => {
      const fieldValue = this.readData(fieldName);
      const validatorResponse = validator(fieldValue);
      return reduction.set(fieldName, validatorResponse);
    }, new Map());

    // This is a special case. We don't grab literal form values and just instead
    // do what validator tells us is right.
    errorMap = complexValidators.reduce((reduction, validator, fieldName) => {
      const validatorResponse = validator();
      return reduction.set(fieldName, validatorResponse);
    }, errorMap);

    this.writeData('errorMap', errorMap);
  }

  _getErrorMap(): Map<string, *> {
    return this.readData('errorMap');
  }

  hasInput(): boolean {
    return this.getStartDate() && this.getEndDate();
  }

  getStartDate(): string {
    return this.readData('startDate');
  }

  getStartDateMilliseconds(): number {
    return moment(this.getStartDate()).valueOf();
  }

  getEndDate(): string {
    return this.readData('endDate');
  }

  getEndDateMilliseconds(): number {
    return moment(this.getEndDate()).valueOf();
  }

  getMaxRange(): number {
    return this.readData('maxRange');
  }

  hasMaxRange(): boolean {
    return !!this.getMaxRange();
  }

  isStartDateValid(): boolean {
    return this._getErrorMap().getIn(['startDate', 'isValid']);
  }

  isEndDateValid(): boolean {
    return this._getErrorMap().getIn(['endDate', 'isValid']);
  }

  isAllowNull(): boolean {
    return this.readData('allowNull');
  }

  /*
    TODO: This block of predicates is not being used.

    The FE needs to decide if it wants to validate across the range, or in
    the individual values. I think we can safely remove these - at least
    the getErrorMessage functions.
  */

  getStartDateErrorMessage(): string {
    return this._getErrorMap().getIn(['startDate', 'errorMessage']);
  }

  getEndDateErrorMessage(): string {
    return this._getErrorMap().getIn(['endDate', 'errorMessage']);
  }

  _getValidityPredicates(): List<Function> {
    let predicateList = new List([this._isRangeValid]);
    if (this.hasMaxRange()) {
      predicateList = predicateList.push(this._isMaxRangeValid);
    }

    return predicateList;
  }

  isValid(): boolean {
    return this._getValidityPredicates().every((predicate) => {
      return predicate();
    });
  }

  _isRangeValid = (): boolean => {
    return this._getErrorMap().getIn(['range', 'isValid'], false);
  };

  _isMaxRangeValid = (): boolean => {
    return this._getErrorMap().getIn(['maxRange', 'isValid'], false);
  };

  /**
   * Shows one of two messages:
   * - first shows the invalid range error message,
   * - then will show, if the range is valid, the maxRange error
   */
  getRangeErrorMessage(): string {
    const rangeMessage = this._getErrorMap().getIn(['range', 'errorMessage'], '');
    return rangeMessage || this._getErrorMap().getIn(['maxRange', 'errorMessage'], '');
  }
}
