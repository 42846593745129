// @flow
import {callAction} from 'client/framework';
import appStore from 'client/AppStore';
import {addToast} from '@albert-io/atomic';
import {resource, type QueryBuilder} from '@albert-io/json-api-framework/request/builder';
import templateAssignmentStageActions from './TemplateAssignmentStage.actions';

export function getAssignmentTemplateQuery(): QueryBuilder {
  return (
    resource('template_v1')
      .mandarkEndpoint(['templates_v1', appStore.routerProps.params.assignmentId])
      .include('question_sets_v1')
      /**
       * @todo these includes should be dropped as soon as subject_ relationships and count_of_question
       * metadata is available on templates.
       * @see {@link https://albert-io.atlassian.net/browse/ALBERT-7439}
       */
      .include('question_sets_v1.questions_v1')
      .include('question_sets_v1.subject_v2')
      .withMeta('template_v1')
  );
}

function doesTemplateHaveHiddenSubjects(): boolean {
  return getAssignmentTemplateQuery()
    .getResource()
    .getQuestionSets()
    .some((questionSet) => questionSet.getSubject().isHidden());
}

export function handleSaveToTemplates() {
  if (doesTemplateHaveHiddenSubjects()) {
    addToast({
      color: 'negative',
      title: 'Error',
      // eslint-disable-next-line max-len
      message:
        'This folder contains questions in subjects which have been unpublished. No new folders can be created from it.',
      permanent: true
    });
  } else {
    const templateId = appStore.routerProps.params.assignmentId;
    callAction(templateAssignmentStageActions.COPY_TEMPLATE, {templateId});
  }
}
