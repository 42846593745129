import type {AuthoringSubjectModelV1} from 'resources/GeneratedModels/AuthoringSubject/AuthoringSubjectModel.v1';

import {baseGuideLevelExtensions} from '../guideLevelHelpers/baseGuideLevel.extensions';

export const extensions = {
  ...baseGuideLevelExtensions,

  getSubject(): AuthoringSubjectModelV1 {
    return this.getAuthoringSubject();
  }
};
