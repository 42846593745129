import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import 'assets/stylesheets/modules/_loading-indicator.scss';

export default class LoadingIndicator extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    type: PropTypes.string,
    inline: PropTypes.bool,
    style: PropTypes.object
  };

  render() {
    return (
      <div
        className={classnames('loading-indicator', this.props.className, {
          inline: this.props.type === 'inline' || this.props.inline
        })}
        style={this.props.style ? this.props.style : null}
      >
        <div className='loading-indicator__circles-wrapper'>
          <div className='loading-indicator__circle' />
          <div className='loading-indicator__circle' />
          <div className='loading-indicator__circle' />
          <div className='loading-indicator__circle' />
        </div>
      </div>
    );
  }
}
