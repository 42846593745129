// @flow
import {List} from 'immutable';
import {stats} from 'lib/StatsUtil';
import {type SchoolPersonnelModelV1} from 'resources/augmented/SchoolPersonnel/SchoolPersonnelModel.v1';

/**
 * @todo this is technically provided by the resource (validation/JSON Schema).
 */
export const SALUTATIONS = ['Dr.', 'Mr.', 'Mrs.', 'Ms.', 'Mx.', 'Prof.'];

export const userModelExtensions = {
  /**
   * `password` is a write_only field in Mandark so parsedoc doesn't create a getter, but we use it in account
   * creation and password editing to keep track of the users' new password. Therefore, we need to add an
   * accessor ourselves.
   */
  getPassword(): string {
    return this.getValueForAttribute('password');
  },

  getAccuracyFromMeta(): number {
    return stats.percentage(
      this.getMeta().getCountOfCorrectGuesses(),
      this.getMeta().getCountOfGuesses()
    );
  },

  /**
   * @param {String} format A string representing the format of the display data.
   *                        - `s` `user.getSalutation()` (when available, defaulting to `''`)
   *                        - `f` `user.getFirstName()`
   *                        - `l` `user.getLastName()`
   * @param {Boolean|Function|String} condition A condition that must be met in order to display the formatted string, otherwise,
   *                                            it falls back to the `defaultDisplay`
   * @param {String} defaultDisplay The string to return if `condition` is not met.
   *
   * @example <caption>Basic usage, no `condition` or `defaultDisplay`</caption>
   * ```js
   *  user.getDisplayName('l, f');
   *  // "LastNameBar, FirstNameFoo"
   *  user.getDisplayName('s (f) l');
   *  // "Dr. (FirstNameFoo) LastNameBar"
   * ```
   *
   * @example <caption>Basic usage, `String` `condition` and `defaultDisplay`</caption>
   * ```js
   *  // Will only display "lastname, firstname" **IF** `user.getLastName().length > 0`,
   *  // otherwise use `user.getEmail()`
   *  user.getDisplayName('l, f', user.getLastName(), user.getEmail());
   * ```
   */
  getDisplayName(
    format: string = 'f l',
    condition: boolean = true,
    defaultDisplay: string = ''
  ): string {
    if (typeof condition === 'function') {
      condition = condition(this);
    }

    if (typeof condition === 'string') {
      condition = condition.length > 0;
    }

    if (condition === false) {
      return defaultDisplay;
    }
    return format
      .replace(/([flsi])/gi, '[$1]')
      .replace('[s]', typeof this.getSalutation === 'function' ? this.getSalutation() : '')
      .replace('[f]', this.getFirstName())
      .replace('[l]', this.getLastName())
      .replace(
        '[i]',
        this.getFirstChar(this.getFirstName()) + this.getFirstChar(this.getLastName())
      );
  },

  getFirstChar(name: string): string {
    if (name[0]) {
      return name[0];
    } else {
      return '';
    }
  },

  getFirstName(): string {
    const firstName = this.getValueForAttribute('first_name');
    /**
     * first_name CAN be null (mostly on legacy accounts). This ensures a string instead of null.
     */
    return firstName ? firstName : '';
  },

  getFullName({formal = false}: Object = {}): string {
    const firstName = this.getFirstName();
    const lastName = this.getLastName();
    if (firstName && lastName) {
      return formal ? `${lastName}, ${firstName}` : `${firstName} ${lastName}`;
    } else if (!firstName && !lastName) {
      return this.getUsername();
    } else {
      return firstName ? firstName : lastName;
    }
  },

  getLastName(): string {
    const lastName = this.getValueForAttribute('last_name');
    /**
     * last_name CAN be null (mostly on legacy accounts). This ensures a string instead of null.
     */
    return lastName ? lastName : '';
  },

  getSalutation(): string {
    /**
     * salutation CAN be null (mostly on legacy accounts). This ensures a string instead of null.
     */
    return this.get('salutation') ? this.get('salutation') : '';
  },

  /*
   *IMPORTANT* This relies on the inclusion of teacher_v1.school_personnel_v1 on user
   */
  getPersonnelRecordForSchool(schoolId: string): boolean {
    return this.getPersonnelRecords().find((personnel) => {
      return personnel.getSchool().getId() === schoolId;
    });
  },

  /*
    Retrieves personnel records. All usages of these records should funnel thru this in order to pick up
    our primitive wanrings

    *IMPORTANT* This relies on the inclusion of teacher_v1.school_personnel_v1 on user
  */
  getPersonnelRecords(): List<SchoolPersonnelModelV1> {
    // TODO: this is a dubious conditional
    if (this.getTeacher().isEmpty()) {
      /* eslint-disable no-console */
      console.warn(
        'Attempting to use an extension method without including appropriate resources.'
      );
      console.warn(`Please include teacher.school_personnel on user: ${this.getId()}`);
      /* eslint-enable no-console */
      return new List();
    }

    return this.getTeacher().getSchoolPersonnel();
  },

  /*
   *IMPORTANT* This relies on the inclusion of teacher_v1.school_personnel_v1 on user
   */
  hasPersonnelRecords(): boolean {
    return !this.getPersonnelRecords().isEmpty();
  },

  /**
   * Relies on the inclusion of subscriptions_v1.subject_v2 on the user
   */
  hasSubscriptionForSubject(subjectId: string): boolean {
    return this.getSubscriptions().find((subscription) => {
      return subscription.getSubject().getId() === subjectId;
    });
  },

  hasTeacherAccess(): boolean {
    return this.isTeacher() || this.isSchoolAdministrator() || this.isSuper();
  },

  /*
   *IMPORTANT* This relies on the inclusion of teacher_v1.school_personnel_v1 on user
   */
  isAdministratorAtSchool(schoolId: string): boolean {
    const personnelRecordForSchool = this.getPersonnelRecordForSchool(schoolId);
    return personnelRecordForSchool ? personnelRecordForSchool.isSchoolAdministrator() : false;
  },

  isStudent(): boolean {
    return !(this.isTeacher() || this.isSchoolAdministrator() || this.isAuthor() || this.isSuper());
  },

  /*
   *IMPORTANT* This relies on the inclusion of teacher_v1.school_personnel_v1 on user
   */
  isTeacherAtSchool(schoolId: string): boolean {
    const personnelRecordForSchool = this.getPersonnelRecordForSchool(schoolId);
    return personnelRecordForSchool ? personnelRecordForSchool.isTeacher() : false;
  },

  isUserConfirmed(): boolean {
    return this.getStatus() !== 'unconfirmed';
  },

  hasSchool(): boolean {
    return this.getSchool() && !this.getSchool().isEmpty();
  },

  isSocialUser(): boolean {
    return List(['google', 'facebook', 'clever']).includes(this.getSignUpMethod());
  }
};
