// @flow
import {List, Map} from 'immutable';
import {memoize} from '../../lib/memoizer';

import type {OrderedMap} from 'immutable';

export function GenericListCollectionModel(data: ?List<*>): List<*> {
  const collection = List(data);
  collection.cache = memoize();
  collection.getCollection = () => collection;
  collection.asMap = () =>
    collection.cache('asMap', () => {
      return collection.reduce((acc, val) => {
        return acc.set(val.getId(), val);
      }, Map());
    });
  return collection;
}

/* eslint-disable no-extend-native */
Map.prototype.sorted = function(sortFn: (Map<*, *>) => Map<*, *>): OrderedMap<*, *> {
  return this.cache(sortFn.toString(), () => this.sortBy(sortFn));
};
/* eslint-enable no-extend-native */

export function GenericMapCollectionModel(
  data: List<string, *>,
  fromServer: boolean = false
): Map<*, *> {
  const collection = Map(fromServer ? data.toMap().mapKeys((key, val) => val.get('id')) : data);
  collection.cache = memoize();
  collection.getCollection = () => collection;
  return collection;
}
