import React, {MouseEvent, useEffect, useState} from 'react';
import {arc, pie, select} from 'd3';
import classNames from 'classnames';

import {Heading, IconButton, Text, WithTooltip} from '@albert-io/atomic';
import {type SummaryStatsResponse} from 'client/Reports/reports.types';

import {asPercentage, StyledContainer} from '../shared';
import './summary-stats-donut-chart.scss';

export type DonutChartData = Map<DonutChartDataKey, number>;

export type DonutChartDataKey = Exclude<
  keyof SummaryStatsResponse,
  'accuracy' | 'avg_time_spent_per_student' | 'count_attempts'
>;

enum DonutChartMasteryLevel {
  count_not_started_students,
  count_struggling_students,
  count_passing_students,
  count_proficient_students,
  count_excelling_students,
  count_mastery_students
}

interface D3Datum {
  data: [DonutChartDataKey, number];
}

interface Props {
  accuracy: number | null;
  data: DonutChartData;
  isSingleStudentView: boolean;
}

const SUMMARY_STATS_DONUT_CHART_ID = 'summary-stats-donut-chart';

const INNER_RADIUS = 74;
const OUTER_RADIUS = 90;

export default function SummaryStatsDonutChart({accuracy, data, isSingleStudentView}: Props) {
  const [hoveredSliceKey, setHoveredSliceKey] = useState<DonutChartDataKey>();

  function getMasteryLevelColorCssVariable(key: DonutChartDataKey) {
    const donutChartDataKeyToCssVariable = new Map<DonutChartDataKey, string>([
      ['count_excelling_students', 'data-excelling'],
      ['count_mastery_students', 'data-mastery'],
      ['count_not_started_students', 'data-na'],
      ['count_passing_students', 'data-passing'],
      ['count_proficient_students', 'data-proficient'],
      ['count_struggling_students', 'data-struggling']
    ]);

    const cssVariable = donutChartDataKeyToCssVariable.get(key);

    if (cssVariable === undefined) {
      throw Error(`Corresponding CSS variable to key ${key} not found!`);
    }

    return cssVariable;
  }

  function getMasteryLevelCountAsPercentage(key: DonutChartDataKey) {
    if (!hasData()) {
      return '100%';
    }

    const count = data.get(key);

    if (count === undefined) {
      throw Error(`Could not find key ${key}!`);
    }

    const total = Array.from(data.values()).reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      0
    );

    return `${Math.round((count / total) * 100)}%`;
  }

  function getMasteryLevelLabel(key: DonutChartDataKey) {
    const donutChartDataKeyToLabel = new Map<DonutChartDataKey, string>([
      ['count_excelling_students', 'Excelling'],
      ['count_mastery_students', 'Mastery'],
      ['count_not_started_students', 'N/A'],
      ['count_passing_students', 'Passing'],
      ['count_proficient_students', 'Proficient'],
      ['count_struggling_students', 'Struggling']
    ]);

    return donutChartDataKeyToLabel.get(key);
  }

  function getPrimaryMasteryLevelKey(): DonutChartDataKey {
    if (isSingleStudentView) {
      return getMasteryLevelKeyBasedOnAccuracy();
    }

    if (hoveredSliceKey !== undefined) {
      return hoveredSliceKey;
    }

    if (!hasData()) {
      return 'count_not_started_students';
    }

    return Array.from(data.keys()).reduce((accumulatorKey, currentKey) => {
      const accumulatorValue = data.get(accumulatorKey) ?? 0;
      const currentKeyValue = data.get(currentKey) ?? 0;

      if (accumulatorValue > currentKeyValue) {
        return accumulatorKey;
      }

      if (accumulatorValue === currentKeyValue) {
        const accumulatorKeyMasteryLevel = DonutChartMasteryLevel[accumulatorKey];
        const currentKeyMasteryLevel = DonutChartMasteryLevel[currentKey];

        return accumulatorKeyMasteryLevel > currentKeyMasteryLevel ? accumulatorKey : currentKey;
      }

      return currentKey;
    });
  }

  function getPrimaryMasteryLevelLabel() {
    return getMasteryLevelLabel(getPrimaryMasteryLevelKey());
  }

  function getPrimaryMasteryLevelValue() {
    if (isSingleStudentView) {
      return asPercentage(accuracy);
    }

    if (!hasData()) {
      return 0;
    }

    const primaryMasteryLevelCount = data.get(getPrimaryMasteryLevelKey()) ?? 0;

    const totalMasteryLevelCount = Array.from(data.values()).reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      0
    );

    return asPercentage(primaryMasteryLevelCount / totalMasteryLevelCount);
  }

  function getMasteryLevelKeyBasedOnAccuracy(): DonutChartDataKey {
    if (accuracy === null) {
      return 'count_not_started_students';
    }

    if (accuracy < 0.55) {
      return 'count_struggling_students';
    }

    if (accuracy >= 0.55 && accuracy < 0.7) {
      return 'count_passing_students';
    }

    if (accuracy >= 0.7 && accuracy < 0.8) {
      return 'count_proficient_students';
    }

    if (accuracy >= 0.8 && accuracy < 0.9) {
      return 'count_excelling_students';
    }

    return 'count_mastery_students';
  }

  function getSortedMasteryLevels(): [DonutChartDataKey, number][] {
    if (!hasData()) {
      return [['count_not_started_students', 0]];
    }

    return Array.from(data.entries())
      .sort(([aKey], [bKey]) => DonutChartMasteryLevel[aKey] - DonutChartMasteryLevel[bKey])
      .reverse();
  }

  function hasData() {
    return data.size > 0;
  }

  function onSliceMouseOver(event: MouseEvent, datum: D3Datum) {
    updateSliceRadii(datum, INNER_RADIUS * 0.99, OUTER_RADIUS * 1.05);

    const donutChartDataKey = datum.data[0];
    setHoveredSliceKey(donutChartDataKey);
  }

  function onSliceMouseLeave(event: MouseEvent, datum: D3Datum) {
    updateSliceRadii(datum, INNER_RADIUS, OUTER_RADIUS);

    setHoveredSliceKey(undefined);
  }

  function renderDonutChart() {
    const div = document.querySelector(`#${SUMMARY_STATS_DONUT_CHART_ID}`);

    if (div === null) {
      throw Error(`div "#${SUMMARY_STATS_DONUT_CHART_ID}" not found!`);
    }

    const existingSvg = div.querySelector(':scope > svg');

    if (existingSvg !== null) {
      existingSvg.remove();
    }

    let maybeOverriddenData = data;

    if (!hasData()) {
      maybeOverriddenData.set('count_not_started_students', 1);
    }

    if (isSingleStudentView) {
      maybeOverriddenData = new Map([[getMasteryLevelKeyBasedOnAccuracy(), 1]]);
    }

    // append the svg object to the div called 'summary-stats'
    const svg = select(`#${SUMMARY_STATS_DONUT_CHART_ID}`)
      .append('svg')
      .classed('summary-stats-donut-chart__svg', true)
      .attr('width', OUTER_RADIUS * 2)
      .attr('height', OUTER_RADIUS * 2)
      .append('g')
      .attr('transform', `translate(${OUTER_RADIUS},${OUTER_RADIUS})`);

    // Compute the position of each group on the pie:
    const pieFunc = pie().value((d) => d[1]);

    const dataReady = pieFunc(
      Array.from(maybeOverriddenData.entries()).sort(([_aKey, aNum], [_bKey, bNum]) => bNum - aNum)
    );

    // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
    svg
      .selectAll('*')
      .data(dataReady)
      .join('path')
      .on('mouseover', onSliceMouseOver)
      .on('mouseleave', onSliceMouseLeave)
      .attr('d', arc().innerRadius(INNER_RADIUS).outerRadius(OUTER_RADIUS))
      .attr('fill', (d) => {
        const summaryStatsResponseKey = d.data[0];
        const cssVariable = getMasteryLevelColorCssVariable(summaryStatsResponseKey);
        return `var(--${cssVariable})`;
      })
      .attr('stroke', 'white')
      .style('stroke-width', '2px');
  }

  function updateSliceRadii(datum: D3Datum, innerRadius: number, outerRadius: number) {
    select(`#${SUMMARY_STATS_DONUT_CHART_ID}`)
      .selectAll('path')
      .filter((d) => d.data[0] === datum.data[0])
      .transition()
      .duration(150)
      .attr('d', arc().innerRadius(innerRadius).outerRadius(outerRadius));
  }

  useEffect(() => {
    renderDonutChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tooltipContent = (
    <div className='summary-stats-donut-chart__tooltip-content u-pad_1'>
      <Text className='u-display_block u-mar-b_2' size='s'>
        Mastery Levels are calculated based on accuracy
      </Text>
      <ul className='summary-stats-donut-chart__tooltip-mastery-levels u-mar_0 u-pad-l_2'>
        <Text size='s' as='li'>
          Mastery: 90-100%
        </Text>
        <Text size='s' as='li'>
          Excelling: 80-89%
        </Text>
        <Text size='s' as='li'>
          Proficient: 70-79%
        </Text>
        <Text size='s' as='li'>
          Passing: 55-69%
        </Text>
        <Text size='s' as='li'>
          Struggling: 0-54%
        </Text>
      </ul>
    </div>
  );

  return (
    <StyledContainer className='summary-stats-donut-chart u-align-items_center u-display_flex u-gap_space-x4 u-pad-lr_4 u-pad-tb_2 u-justify-content_center u-position_relative'>
      <>
        <div className='summary-stats-donut-chart__tooltip-wrapper u-position_absolute'>
          <WithTooltip content={tooltipContent} placement='bottom-start' theme='light'>
            <IconButton icon={['far', 'question-circle']} label='More info' />
          </WithTooltip>
        </div>
        <div
          aria-label='Summary stats donut chart'
          className='u-position_relative'
          id={SUMMARY_STATS_DONUT_CHART_ID}
          role='figure'
        >
          <div
            style={{
              color: `var(--${getMasteryLevelColorCssVariable(getPrimaryMasteryLevelKey())}-bold)`
            }}
            className={classNames(
              'summary-stats-donut-chart__text-container u-align-items_center u-display_flex u-flex-direction_column u-height_100pc u-justify-content_center u-width_100pc'
            )}
          >
            <Heading color='inherit'>{getPrimaryMasteryLevelValue()}</Heading>
            <Text bold className='u-line-height_140pc' color='inherit' size='xs'>
              {getPrimaryMasteryLevelLabel()}
            </Text>
          </div>
        </div>
        {!isSingleStudentView && (
          <ul className='summary-stats-donut-chart__legend'>
            {getSortedMasteryLevels().map(([key, value]) => (
              <li className='summary-stats-donut-chart__legend-item' key={key}>
                <span
                  className='summary-stats-donut-chart__legend-square'
                  style={{backgroundColor: `var(--${getMasteryLevelColorCssVariable(key)})`}}
                />
                <Text className='u-pad-r_2'>{getMasteryLevelLabel(key)}</Text>
                <Text>({value})</Text>
                <Text color='secondary'>{getMasteryLevelCountAsPercentage(key)}</Text>
              </li>
            ))}
          </ul>
        )}
      </>
    </StyledContainer>
  );
}
