/**
 * # LoadingSpinnerLong
 *
 * A component that displays a loading spinner with optional helper text. Used for when context is expected to take longer than a few seconds to load. Renders changing loading messages to indicate loading is still executing to the user.
 *
 * ## Props
 *
 * - `assistiveText` (optional): The assistive text for screen readers. Default value is 'Content is loading...'.
 * - `className` (optional): Additional CSS class name for the component.
 * - `size` (optional): The size of the loading spinner. Default value is 2.
 *
 * ## Usage
 *
 * ```tsx
 * import LoadingSpinnerLong from './LoadingSpinnerLong';
 *
 * const MyComponent = () => {
 *   return (
 *     <div>
 *       <LoadingSpinnerLong assistiveText="Loading content..." size={3} />
 *     </div>
 *   );
 * };
 * ```
 */

import React, {useEffect, useState} from 'react';
import classnames from 'classnames';

import Icon from '@albert-io/atomic/atoms/Icon/Icon.react';
import Text from '@albert-io/atomic/atoms/Text/Text.react';

import './loading-spinner-long.scss';

interface Props extends PropsWithClassNameOptional {
  assistiveText?: string;
  size?: number;
}

const loadingTextOptions = [
  'Loading...',
  'Hold tight!',
  'Just a little longer...',
  'Almost there...'
];

export const LoadingSpinnerLong = ({
  assistiveText = 'Content is loading...',
  className,
  size = 2
}: Props) => {
  const [currentHelperText, setCurrentHelperText] = useState(loadingTextOptions[0]);
  const [currentOption, setCurrentOption] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentOption < loadingTextOptions.length - 1) {
        setCurrentOption(currentOption + 1);
      } else {
        setCurrentOption(0);
      }
      setCurrentHelperText(loadingTextOptions[currentOption]);
    }, 4000);

    return () => clearInterval(interval);
  });

  return (
    <div
      className={classnames(
        className,
        'u-display_flex u-flex-direction_column u-align-items_center u-justify-content_center'
      )}
    >
      <Icon
        className={classnames('loading-spinner-long fa-fw u-color_blue-800', {
          'fa-lg': size === 1,
          'fa-2x': size === 2,
          'fa-3x': size === 3,
          'fa-4x': size === 4,
          'fa-5x': size === 5
        })}
        icon='spinner-third'
      />
      <Text size='m' bold className='u-mar-t_2 u-color_blue-800'>
        {currentHelperText}
      </Text>
      <label className='sr-only'>
        <progress />
        {assistiveText}
      </label>
    </div>
  );
};

export default LoadingSpinnerLong;
