import React from 'react';
import {Modal, Dialogue, Button, Icon} from '@albert-io/atomic';

import './hard-cap-error-modal.scss';

type Props = {
  onDismiss: () => void;
};

const HardCapErrorModal = ({onDismiss}: Props) => (
  <Modal ariaLabel='exceeded seat limit' handleClose={onDismiss}>
    {({CloseButtonWrapper, modalContentStyle}) => (
      <Dialogue
        inModal
        hideCloseBtn
        className={`${modalContentStyle} u-text-align_center u-color_slate_500`}
        size='s'
      >
        <div className='hard-cap-error__icon'>
          <Icon icon='ban' color='negative' />
        </div>
        <p className='u-mar-b_3'>
          Your school&apos;s license has 0 seats remaining. No further student enrollments possible.
        </p>
        <CloseButtonWrapper className='u-width_100pc'>
          <Button variant='outlined' onClick={onDismiss}>
            Dismiss
          </Button>
        </CloseButtonWrapper>
      </Dialogue>
    )}
  </Modal>
);

export default HardCapErrorModal;
