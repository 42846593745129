import React from 'react';
import classnames from 'classnames';

import {RenderLeafProps} from 'slate-react';

import {CustomText} from '../slate.types';

import {ElementProps} from './Elements.types';

import Image, {DeletedImage} from './ImageElement.react';

import {AudioChip, DeletedAudioChip, UploadingAudioChip} from './AudioChipElement.react';

import Link from './LinkElement.react';

// --------------- Text editor rendering components  ---------------

export const Element = (props: ElementProps) => {
  const {attributes, children, element, ...rest} = props;
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading':
      return (
        <h1 className='written-submission__title' {...attributes}>
          {children}
        </h1>
      );
    case 'heading-one':
      return (
        <h2 className='written-submission__heading' {...attributes}>
          {children}
        </h2>
      );
    case 'heading-two':
      return (
        <h3 className='written-submission__subheading' {...attributes}>
          {children}
        </h3>
      );
    case 'list-item': {
      const classes = classnames({
        [`written-submission__indent-${element.indent}`]: !!element.indent
      });
      return (
        <li className={classes} {...attributes}>
          {children}
        </li>
      );
    }
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    case 'link':
      return (
        <Link attributes={attributes} element={element} {...rest}>
          {children}
        </Link>
      );
    case 'deleted-image':
      return (
        <DeletedImage element={element} attributes={attributes}>
          {children}
        </DeletedImage>
      );
    case 'image':
      return (
        <Image element={element} attributes={attributes} {...rest}>
          {children}
        </Image>
      );
    case 'audio-chip':
      return (
        <AudioChip element={element} attributes={attributes} {...rest}>
          {children}
        </AudioChip>
      );
    case 'uploading-audio-chip':
      return (
        <UploadingAudioChip element={element} attributes={attributes}>
          {children}
        </UploadingAudioChip>
      );
    case 'deleted-audio-chip':
      return (
        <DeletedAudioChip element={element} attributes={attributes}>
          {children}
        </DeletedAudioChip>
      );
    default: {
      const classes = classnames({
        [`written-submission__indent-${element.indent}`]: !!element.indent
      });
      return (
        <p className={classes} {...attributes}>
          {children}
        </p>
      );
    }
  }
};

interface LeafProps extends RenderLeafProps {
  leaf: CustomText;
}
export const Leaf = ({attributes, children, leaf}: LeafProps) => {
  if (leaf.code) {
    children = <code>{children}</code>;
  }
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.superscript) {
    children = <sup>{children}</sup>;
  }

  if (leaf.subscript) {
    children = <sub>{children}</sub>;
  }

  return <span {...attributes}>{children}</span>;
};

// --------------- Custom nodes stylings ---------------
