import React from 'react';
import PropTypes from 'prop-types';
import appStore from 'client/AppStore';
import {Map} from 'immutable';
import classnames from 'classnames';
import {callTargetedAction} from 'client/framework';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';

import {TwoWayQuestionModel} from 'resources/Question/Question.model';
import {AuthoringQuestionModelV1} from 'resources/augmented/AuthoringQuestion/AuthoringQuestionModel.v1';
import {Badge, Text, Icon} from '@albert-io/atomic';

import twoWayQuestionActions from './TwoWayQuestion.actions';
import TwoWayQuestionStore from './TwoWayQuestion.store';
import './two-way-v2.scss';

export default class TwoWayQuestionTable extends React.Component {
  static propTypes = {
    isGuessSubmitted: PropTypes.bool,
    showResponseStats: PropTypes.bool,
    question: PropTypes.oneOfType([
      PropTypes.instanceOf(TwoWayQuestionModel),
      PropTypes.instanceOf(AuthoringQuestionModelV1)
    ]),
    store: PropTypes.instanceOf(TwoWayQuestionStore)
  };

  static defaultProps = {
    isGuessSubmitted: false,
    showResponseStats: false,
    responseStats: new Map()
  };

  render() {
    const isInDennis = appStore.routerProps.location.pathname.includes('/dennis/');
    const stickyClass = isInDennis
      ? 'twq-table__heading--sticky-dennis'
      : 'twq-table__heading--sticky';
    const {isGuessSubmitted, question, showResponseStats, store} = this.props;
    const rows = question.getRows();
    return (
      <table
        className={classnames('twq-table', {
          'twq-table--full-width': question.containsLatex()
        })}
      >
        <thead>
          <tr>
            <th className={classnames(stickyClass, 'twq-table__heading--empty')} />
            <th className={stickyClass}>
              <MarkdownRendererV2 text={question.getLeftHeader()} className='twq-table__heading' />
            </th>
            <th className={stickyClass}>
              <MarkdownRendererV2 text={question.getRightHeader()} className='twq-table__heading' />
            </th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, i) => (
            <Row
              key={i}
              row={row}
              store={store}
              question={question}
              isGuessSubmitted={isGuessSubmitted}
              showResponseStats={showResponseStats}
            />
          ))}
        </tbody>
      </table>
    );
  }
}

class Row extends React.Component {
  static propTypes = {
    store: PropTypes.instanceOf(TwoWayQuestionStore),
    question: PropTypes.oneOfType([
      PropTypes.instanceOf(TwoWayQuestionModel),
      PropTypes.instanceOf(AuthoringQuestionModelV1)
    ]),
    isGuessSubmitted: PropTypes.bool
  };

  handleCheck = (e) => {
    if (e.type === 'keypress' && e.key !== 'Enter') {
      return;
    }
    callTargetedAction({
      name: twoWayQuestionActions.SELECT_COLUMN,
      payload: e.currentTarget.dataset.id,
      targetStore: this.props.store.getName()
    });
    if (e.type === 'click') {
      e.currentTarget.blur();
    }
  };

  generateIndicator = (isGuessSubmitted, isSelected, isCorrectAnswer, isMissedAnswer) => {
    if (!isGuessSubmitted || (!isSelected && !isCorrectAnswer && !isMissedAnswer)) {
      return null;
    }
    return (
      <div className='twq-table-row__correctness-indicator'>
        {isCorrectAnswer && (
          <Badge
            emphasis='bold'
            size='small'
            className='twq-table-row__correctness-indicator-correct'
          >
            <Text color='primary-inverse' size='xs'>
              Correct
              <Icon
                className='u-mar-l_1 twq-table-row__correctness-indicator_icon'
                icon={['fas', 'thumbs-up']}
              />
            </Text>
          </Badge>
        )}
        {!isCorrectAnswer && !isMissedAnswer && (
          <Badge emphasis='bold' size='small' status='negative'>
            Incorrect
            <Icon
              className='u-mar-l_1 twq-table-row__correctness-indicator_icon'
              icon={['far', 'xmark']}
            />
          </Badge>
        )}
        {isMissedAnswer && (
          <Badge emphasis='bold' size='small' status='negative'>
            Missed
            <Icon
              className='u-mar-l_1 twq-table-row__correctness-indicator_icon'
              icon={['far', 'xmark']}
            />
          </Badge>
        )}
      </div>
    );
  };

  render() {
    const {row, store, question, isGuessSubmitted} = this.props;
    return (
      <tr className='twq-table-row'>
        <td className='twq-table-row__cell'>
          <MarkdownRendererV2 text={row.getStatement()} />
        </td>
        {['Left', 'Right'].map((side) => {
          const option = row[`get${side}Column`]();
          const optionId = option.getId();
          const isChecked = store.isSelectedColumn(optionId);
          let postSubmitClassNames;
          let isCorrectSelection;
          const isCorrectOption = question.getValidResponse().has(optionId) && isChecked;
          const isMissedOption = question.getValidResponse().has(optionId) && !isChecked;
          if (isGuessSubmitted) {
            isCorrectSelection = isCorrectOption ? isChecked : !isChecked;
            postSubmitClassNames = {
              'twq-table-row__cell--submitted': true,
              'twq-table-row__cell--correct-selection': isCorrectOption,
              'twq-table-row__cell--missed-selection': isMissedOption,
              'twq-table-row__cell--incorrect-selection': !isCorrectSelection
            };
          }
          // If option's contents is empty, or consists only of whitespace, render a pseudo-checkbox
          const cellContents = /^\s*$/.test(option.getText()) ? (
            <div
              className={classnames('twq-table-row__checkmark', {
                'twq-table-row__checkmark--checked': isChecked
              })}
            />
          ) : (
            <MarkdownRendererV2 text={option.getText()} className='twq-table-row__input-text' />
          );
          return (
            <td
              key={side}
              className={classnames(
                'twq-table-row__cell',
                'twq-table-row__cell-option',
                {
                  'twq-table-row__cell-option--checked': isChecked,
                  'twq-table-row__cell-option--unchecked': !isChecked
                },
                postSubmitClassNames
              )}
              onClick={isGuessSubmitted ? null : this.handleCheck}
              onKeyPress={isGuessSubmitted ? null : this.handleCheck}
              data-id={optionId}
              role={isGuessSubmitted ? '' : 'checkbox'}
              aria-checked={isGuessSubmitted ? '' : isChecked}
              tabIndex={isGuessSubmitted ? null : '0'}
            >
              {cellContents}
              {this.generateIndicator(isGuessSubmitted, isChecked, isCorrectOption, isMissedOption)}
            </td>
          );
        })}
      </tr>
    );
  }
}
