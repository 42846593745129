import {QuestionModelV3, SubjectModelV2} from '@albert-io/models';
import makeConstants from 'lib/makeConstants';

import {
  TranslatedQuestion,
  TranslatedSupplement,
  // AlternateContentLanguage,
  // ALTERNATE_CONTENT_LANGUAGES,
  TranslationCoverage
} from './Translation.types';

const questionTypesArr = [
  'multiple-choice',
  'snippet-selection',
  'free-entry',
  'free-response',
  'fill-in-the-blank',
  'two-way',
  'passage-correction',
  'text-highlight',
  'graph-contains'
] as const;

type QuestionTypes = (typeof questionTypesArr)[number];

const questionTypes = makeConstants(...questionTypesArr);

interface modelPayload {
  question: QuestionModelV3;
  subject: SubjectModelV2;
}

export const getTranslationCoverage = (
  translatedInfo: TranslatedQuestion | null,
  translatedSupplements: TranslatedSupplement[],
  models: modelPayload
): TranslationCoverage => {
  const {question} = models;

  const type: QuestionTypes = question.getQuestionType();

  switch (type) {
    case questionTypes['multiple-choice']:
      return checkMultipleChoiceFields(translatedInfo, translatedSupplements, models);
    case questionTypes['snippet-selection']:
    case questionTypes['free-entry']:
    case questionTypes['free-response']:
    case questionTypes['fill-in-the-blank']:
    case questionTypes['two-way']:
    case questionTypes['passage-correction']:
    case questionTypes['text-highlight']:
    case questionTypes['graph-contains']:
    default:
      return defaultTranslationCoverage(translatedInfo);
  }
};

const checkMultipleChoiceFields = (
  translationInfo: TranslatedQuestion | null,
  translatedSupplements: TranslatedSupplement[],
  models: modelPayload
) => {
  if (!translationInfo) return 'none';

  const {question} = models;
  const hasBasicTranslations = hasBasicFields(translationInfo);

  const translatedOptionFields = getOptionsInfo(translationInfo.translated_fields);

  const supplementCoverage = getSupplementCoverage(translatedSupplements, question);

  const options = question.getOptions();

  const hasTranslatedOptions = translatedOptionFields.length === options.size;

  const meetsRequirments = translationInfo.meets_translation_requirements;

  if (translationInfo.translated_fields.length === 0) {
    return 'none';
  }

  if (meetsRequirments) {
    // TODO: do we need this?
    if (hasBasicTranslations && hasTranslatedOptions && supplementCoverage) {
      return 'full';
    }
  }
  // TODO: eventually determine translation status for prompt and answer area
  if (hasBasicTranslations || hasTranslatedOptions || supplementCoverage) {
    return 'partial';
  }
  // TODO: cleanup undefined return
  // shouldn't reach this
  return 'partial';
};

// TODO: split to prompt/answer area translation
const hasBasicFields = (translationInfo: TranslatedQuestion) => {
  const translatedFields = [...translationInfo.translated_fields];

  const hasPromptTranslation = translatedFields.some((info) => info.field === 'prompt');
  const hasTitleTranslation = translatedFields.some((info) => info.field === 'title');
  const hasSolutionTextTranslation = translatedFields.some(
    (info) => info.field === 'solution_text'
  );

  return hasPromptTranslation && hasTitleTranslation && hasSolutionTextTranslation;
};

const getOptionsInfo = (translatedFields: TranslatedQuestion['translated_fields']) => {
  return translatedFields.filter((info) => info.field.includes('option'));
};

const defaultTranslationCoverage = (translationInfo: any) => {
  if (translationInfo.meets_translation_requirements) return 'full';
  // if false
  if (translationInfo.translated_fields.length > 0) return 'partial';
  return 'none';
};

export const getSupplementCoverage = (translatedSupplements: any, question) => {
  const supplementsAvailable = question.getSupplements();
  return translatedSupplements.length === supplementsAvailable.size;
};
