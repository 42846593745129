// @flow
import {Map, List} from 'immutable';
import moment from 'moment';
import {stats, BUCKETS, getBucketByAccuracy} from 'lib/StatsUtil';

export const sectionExtensions = {
  getSortedAuthoringQuestionSets(): List<*> {
    const func = () =>
      this.getAuthoringQuestionSets().sortBy((questionSet) =>
        questionSet.getIn(['relationshipMeta', 'section', this.getId(), 'position'], 0)
      );
    return this.cache('getSortedAuthoringQuestionSets', func);
  },

  getSortedQuestionSets(): List<*> {
    const func = () =>
      this.getQuestionSets().sortBy((questionSet) =>
        questionSet.getIn(['relationshipMeta', 'section', this.getId(), 'position'], 0)
      );
    return this.cache('getSortedQuestionSets', func);
  },

  getAllQuestions(): List<*> {
    const func = () =>
      this.getSortedQuestionSets().reduce((acc, questionSet) => {
        return acc.concat(questionSet.getQuestions());
      }, List());
    return this.cache('getAllQuestions', func);
  },

  getQuestionPositionMap(): {string: number} {
    const func = () =>
      this.getAllQuestions().reduce((acc, question, index) => {
        acc[question.getId()] = index + 1;
        return acc;
      }, {});
    return this.cache('getQuestionPositionMap', func);
  },

  /**
   * Teacher assignment helpers
   */
  getStudentsByMasteryBuckets(): Map<string, List<*>> {
    const func = () => {
      return this.getStudents().reduce(
        (acc, student) => {
          const studentMeta = student.getMeta();
          const correctGuessCount = studentMeta.getSectionCountOfCorrectGuesses();
          const incorrectGuessCount = studentMeta.getSectionCountOfIncorrectGuesses();
          const totalGuesses = correctGuessCount + incorrectGuessCount;

          const updater = (bucket) => [bucket, (studentsList) => studentsList.push(student)];

          if (
            totalGuesses === 0 ||
            student
              .getSectionRelationships()
              .get(this.getId())
              .getSubmittedAt() === null
          ) {
            return acc.update(...updater('NOT_AVAILABLE'));
          }

          const accuracy = stats.percentage(correctGuessCount, totalGuesses);
          const bucket = getBucketByAccuracy(accuracy);

          return acc.update(...updater(bucket.BUCKET_NAME));
        },
        Map(BUCKETS)
          .map(() => List())
          .set('NOT_AVAILABLE', List())
      );
    };
    return this.cache('getStudentsByMasteryBuckets', func);
  },

  getSubmittedStudents(): List<*> {
    const func = () => {
      return this.getStudents().filter((student) => {
        return (
          student
            .getSectionRelationships()
            .get(this.getId())
            .getSubmittedAt() !== null
        );
      });
    };
    return this.cache('getSubmittedStudents', func);
  },

  getCountOfSubmittedStudents(): number {
    return this.getSubmittedStudents().size;
  },

  getNotSubmittedStudents(): List<*> {
    const func = () => {
      return this.getStudents().filter((student) => {
        return (
          student
            .getSectionRelationships()
            .get(this.getId())
            .getSubmittedAt() === null
        );
      });
    };
    return this.cache('getNotSubmittedStudents', func);
  },

  getCountOfNotSubmittedStudents(): number {
    return this.getNotSubmittedStudents().size;
  },

  getCountOfOnTimeSubmittedStudents(): number {
    const func = () => {
      return this.getStudents().count((student) => {
        const submittedAt = student
          .getSectionRelationships()
          .get(this.getId())
          .getSubmittedAt();
        const dueDate = this.getAssignment().getDueDate();
        return submittedAt !== null && moment(submittedAt).isBefore(dueDate);
      });
    };
    return this.cache('getCountOfOnTimeSubmittedStudents', func);
  },

  getCountOfLateSubmittedStudents(): number {
    const func = () => {
      return this.getStudents().count((student) => {
        const submittedAt = student
          .getSectionRelationships()
          .get(this.getId())
          .getSubmittedAt();
        const dueDate = this.getAssignment().getDueDate();
        return submittedAt !== null && moment(submittedAt).isAfter(dueDate);
      });
    };
    return this.cache('getCountOfLateSubmittedStudents', func);
  },

  getCountOfInProgressStudents(): number {
    const func = () => {
      return this.getStudents().count((student) => {
        const sectionRelationships = student.getSectionRelationships().get(this.getId());
        return (
          sectionRelationships.getStartTime() !== null &&
          sectionRelationships.getSubmittedAt() === null
        );
      });
    };
    return this.cache('getCountOfInProgressStudents', func);
  },

  getCountOfNotStartedStudents(): number {
    const func = () => {
      return this.getStudents().count((student) => {
        return (
          student
            .getSectionRelationships()
            .get(this.getId())
            .getStartTime() === null
        );
      });
    };
    return this.cache('getCountOfNotStartedStudents', func);
  },

  getAverageGradeOfSubmittedStudents(): number {
    const func = () => {
      const totalCorrectGuesses = this.getSubmittedStudents().reduce((acc, student) => {
        return acc + student.getMeta().getSectionCountOfCorrectGuesses();
      }, 0);
      const divisor = this.getMeta().getCountOfQuestions() * this.getSubmittedStudents().size;
      return stats.percentage(totalCorrectGuesses, divisor);
    };
    return this.cache('getAverageGradeOfSubmittedStudents', func);
  },

  getAverageTimeSpentOfSubmittedStudents(): number {
    const func = () => {
      const timeSpentArr = this.getSubmittedStudents()
        .map((student) => student.getMeta().getSectionTimeSpent())
        .toJS();
      const averageTimeSpent = stats.average(...timeSpentArr);
      return moment.duration(averageTimeSpent);
    };
    return this.cache('getAverageTimeSpentOfSubmittedStudents', func);
  }
};
