import {fromJS} from 'immutable';
import {has} from 'lodash';
import {Store, getStoreByName} from 'client/framework';
import PaginationStore from 'generic/PaginationV2/Pagination.store';
import {sortDirections} from 'sg/Tables/tableConstants';
import mandarkTableActions from './MandarkTable.actions';

export default class MandarkTableStore extends Store {
  constructor(
    name,
    {
      sortDirection = sortDirections.ASCENDING,
      currentSortHeading = null,
      currentSortParam = null
    } = {}
  ) {
    super(name);
    this.setInitialData(
      fromJS({
        currentSortHeading,
        currentSortParam,
        sortDirection,
        totalNumberOfPages: 1,
        hasLoadedDataBefore: false
      })
    );
    this.handleTargeted(
      mandarkTableActions.SET_SORT_HEADING,
      this.setProperty('currentSortHeading')
    );
    this.handleTargeted(mandarkTableActions.SET_SORT_PARAM, this.setProperty('currentSortParam'));
    this.handleTargeted(mandarkTableActions.SET_SORT_DIRECTION, this.setProperty('sortDirection'));
    this.handleTargeted(
      mandarkTableActions.SET_TOTAL_NUMBER_OF_PAGES,
      this.setProperty('totalNumberOfPages')
    );
    this.handleTargeted(
      mandarkTableActions.SET_HAS_LOADED_DATA,
      this.setProperty('hasLoadedDataBefore')
    );
    this.handleTargeted(mandarkTableActions.UPDATE_SORT_BY_COLUMN, this._updateSortByColumn);
    const paginationStore = getStoreByName(this.getPaginationStoreName());
    this._paginationStore = paginationStore
      ? paginationStore
      : new PaginationStore(this.getPaginationStoreName());
  }

  _updateSortByColumn(column) {
    const {heading, sortBy} = column.props;
    const isCurrentParam = this.getCurrentSortParam() === sortBy;
    if (isCurrentParam) {
      const currentSortDirection = this.getSortDirection();
      const flipped =
        currentSortDirection === sortDirections.ASCENDING
          ? sortDirections.DESCENDING
          : sortDirections.ASCENDING;
      this.writeData('sortDirection', flipped);
    } else {
      this.writeData((store) => {
        return store
          .set('currentSortHeading', heading)
          .set('currentSortParam', sortBy)
          .set('sortDirection', this.initialData.get('sortDirection'));
      });
    }
  }

  getSortParams() {
    const sortDirection = this.getSortDirection();
    const currentSortHeading = this.getCurrentSortHeading();
    const currentSortParam = this.getCurrentSortParam();
    return {
      sortDirection,
      currentSortHeading,
      currentSortParam,
      currentPage: this.getCurrentPage()
    };
  }

  makeQuery(partialQuery) {
    const {sortDirection, currentSortParam, currentPage} = this.getSortParams();
    const prefix = sortDirection === sortDirections.ASCENDING ? '' : '-';
    const builtSortParam = prefix + currentSortParam;
    // Currently, because of limitations with the queryBuilder, once you add a property to your query,
    // we can't overwrite it. Because of this, we can't accept queries that have either 'sort' or 'page' set.
    if (has(partialQuery.done(), ['customQuery', 'sort'])) {
      throw new Error('Query passed to <MandarkTable /> must not include a sort parameter');
    }
    if (has(partialQuery.done(), ['customQuery', 'page', 'page'])) {
      throw new Error(
        'Query passed to <MandarkTable /> must not include a {page: {page: x}} parameter'
      );
    }
    const hasSortParam = Boolean(currentSortParam);
    return partialQuery.sort(builtSortParam, hasSortParam).page(currentPage);
  }

  getCurrentSortHeading() {
    return this.readData('currentSortHeading');
  }

  getCurrentSortParam() {
    return this.readData('currentSortParam');
  }

  getCurrentPage() {
    return this._paginationStore.activePage;
  }

  getHasLoadedData() {
    return this.readData('hasLoadedDataBefore');
  }

  getSortDirection() {
    return this.readData('sortDirection');
  }

  getTotalNumberOfPages() {
    return this.readData('totalNumberOfPages');
  }

  getPaginationStoreName() {
    return this.name + 'PaginationStore';
  }
}
