import React, {useCallback} from 'react';
import {List, Map} from 'immutable';

import Button from 'sg/Button/Button.react';
import SupplementManagerModalButton from 'client/Supplements/SupplementManager/SupplementManagerModalButton.react';
import {AuthoringQuestionModelV1} from 'resources/augmented/AuthoringQuestion/AuthoringQuestionModel.v1';
import AuthoringTextInput from 'client/EditPage/V2/AuthoringTextInput/AuthoringTextInput.react';

import {useQuestionEditorV2Store} from 'client/EditPage/V2/QuestionEditorV2Store';
import {useShallow} from 'zustand/react/shallow';

import {PRIMARY_CONTENT_LANGUAGE} from 'client/EditPage/V2/QuestionEditorV2Store.types';

import CorrectAnswer from './CorrectAnswer';

interface Props {
  index: number;
  input: Map<string, any>;
  question: AuthoringQuestionModelV1;
  onBlur: () => void;
  onModelChange: (question: AuthoringQuestionModelV1, field?: string) => void;
  validationErrors: Map<string, any>;
}

const FreeEntryInput = ({
  index,
  input,
  question,
  onBlur,
  onModelChange,
  validationErrors
}: Props) => {
  const {currentLanguage, translatedInputText, updateTranslatedQuestionField} =
    useQuestionEditorV2Store(
      useShallow((state) => ({
        currentLanguage: state.currentLanguage,
        updateTranslatedQuestionField: state.updateTranslatedQuestionField,
        translatedInputText:
          state
            .currentTranslatedQuestion()
            ?.translated_fields?.find((field) => field.field === `inputs.${index}.text`)?.text || ''
      }))
    );

  const addAnswer = useCallback(() => {
    const id = input.get('id');
    const validResponse = question.getValidResponse();
    const answers = validResponse.get(id, List()).push('');
    const updatedAnswers = validResponse.set(id, answers);
    const updatedQuestion = question.setValidResponse(updatedAnswers);

    onModelChange(updatedQuestion, 'inputs');
  }, [input, question, onModelChange]);

  const setInputText = useCallback(
    (e) => {
      if (currentLanguage === PRIMARY_CONTENT_LANGUAGE) {
        const id = input.get('id');
        const inputs = question.getInputs().map((questionInput) => {
          if (questionInput.get('id') === id) {
            return questionInput.set('text', e.target.value);
          }
          return questionInput;
        });

        const updatedQuestion = question.setInputs(inputs);
        onModelChange(updatedQuestion);
      } else {
        updateTranslatedQuestionField(
          currentLanguage,
          `inputs.${index}.text`,
          'text',
          e.target.value
        );
      }
    },
    [input, question, onModelChange, currentLanguage, updateTranslatedQuestionField, index]
  );

  const removeInput = useCallback(() => {
    const id = input.get('id');
    const inputs = question
      .getInputs()
      .filterNot((questionInput) => questionInput.get('id') === id);
    const validResponse = question.getValidResponse().remove(id);

    const updatedQuestion = question.setInputs(inputs).setValidResponse(validResponse);

    onModelChange(updatedQuestion, 'inputs');
  }, [input, question, onModelChange]);

  const generateCorrectAnswers = () => {
    const id = input.get('id');
    const answers = question.getValidResponse().get(id, List());

    return answers.map((answer, key) => {
      return (
        <CorrectAnswer
          disabled={currentLanguage !== PRIMARY_CONTENT_LANGUAGE}
          key={key}
          answer={answer}
          index={key}
          inputId={id}
          question={question}
          onModelChange={onModelChange}
        />
      );
    });
  };

  const answers = question.getValidResponse().get(input.get('id'));
  const addAnswerText = !answers ? 'Add answer' : 'Add alternative answer';
  const inputError = validationErrors.getIn(['inputs', input.get('id')]);
  const fieldPath = `inputs,${index},text`;

  const value =
    currentLanguage === PRIMARY_CONTENT_LANGUAGE ? input.get('text') : translatedInputText;

  return (
    <div className='free-entry-editor__inputs'>
      <div className='free-entry-editor__prompt'>
        <AuthoringTextInput
          value={value}
          onChange={setInputText}
          onBlur={onBlur}
          data-field-path={fieldPath}
          className='free-entry-editor__prompt-input'
          label='Input prompt'
          name='title'
        />
        {currentLanguage === PRIMARY_CONTENT_LANGUAGE && (
          <>
            <Button
              className='free-entry-editor__delete-btn fa fa-trash-o'
              onClick={removeInput}
              color='red'
              outline
              noBorder
              title='Delete input'
            />
            <SupplementManagerModalButton
              className='free-entry-editor__add-supplement-btn'
              color='blue'
              linkButton
              pathToProperty={fieldPath}
            />
          </>
        )}
      </div>
      {currentLanguage === PRIMARY_CONTENT_LANGUAGE && inputError ? (
        <div className='a-form-input__error'>{inputError}</div>
      ) : null}
      <div className='free-entry-editor__inputs-answers'>
        {generateCorrectAnswers()}
        {currentLanguage === PRIMARY_CONTENT_LANGUAGE && (
          <Button
            className='free-entry-editor__add-answer-btn'
            onClick={addAnswer}
            text={addAnswerText}
            linkButton
          />
        )}
      </div>
      <hr />
    </div>
  );
};

export default FreeEntryInput;
