import React from 'react';
import PropTypes from 'prop-types';
import {Button, Modal, Dialogue} from '@albert-io/atomic';
import './submit-for-review-modal.scss';

const SubmitForReviewModal = ({handleSubmit, handleClose}) => {
  const contentStandardsLink = (
    <a
      href='https://docs.google.com/document/d/1JX0Omo33Zhfiy7JVCDufLdVNNpCcXS8zGcQBO5brkjs/edit'
      target='__blank'
    >
      Content Standards Guide
    </a>
  );

  const handleSubmitAndClose = () => {
    handleSubmit();
    handleClose();
  };

  return (
    <Modal ariaLabel='Submit question checklist' handleClose={handleClose}>
      {({modalContentStyle}) => {
        return (
          <Dialogue
            inModal
            handleClose={handleClose}
            className={modalContentStyle}
            title='Content Checklist'
          >
            <Dialogue.Body>
              <p>
                By submitting for review to the Albert.io moderators, you are asserting that you
                believe your question to be ready to be published as-is without any further editing
                or proofreading
              </p>
              <p className='h3-font-size'>
                <strong>Quick Checklist</strong>
              </p>
              <ul className='submit-for-review-modal__checklist'>
                <li className='submit-for-review-modal__checklist-item'>
                  <span className='fa fa-check-circle' />I have read my question out loud and
                  ensured it is free of typos and grammatical mistakes
                </li>
                <li className='submit-for-review-modal__checklist-item'>
                  <span className='fa fa-check-circle' />I have made sure all supplements (images,
                  tables, passages) that are needed have been added
                </li>
                <li className='submit-for-review-modal__checklist-item'>
                  <span className='fa fa-check-circle' />I have previewed my question and am proud
                  of the way it looks
                </li>
                <li className='submit-for-review-modal__checklist-item'>
                  <span className='fa fa-check-circle' />
                  My explanation is thorough, detailed, and longer than 1 sentence. Not only does it
                  explain how to arrive at the correct answer, but also adds valuable additional
                  context for the student.
                </li>
                <li className='submit-for-review-modal__checklist-item'>
                  <span className='fa fa-check-circle' />
                  My question and explanation go beyond mere identification / recall, and push
                  students to analyze and apply concepts.
                </li>
                <li className='submit-for-review-modal__checklist-item'>
                  <span className='fa fa-check-circle' />
                  My question follows all other conventions of the Albert.io {contentStandardsLink}.
                </li>
              </ul>
            </Dialogue.Body>
            <Dialogue.BtnGroup>
              <Button onClick={handleClose} variant='text'>
                Cancel
              </Button>
              <Button onClick={handleSubmitAndClose}>Submit for review</Button>
            </Dialogue.BtnGroup>
          </Dialogue>
        );
      }}
    </Modal>
  );
};

SubmitForReviewModal.propTypes = {
  handleSubmit: PropTypes.func,
  handleClose: PropTypes.func
};

export default SubmitForReviewModal;
