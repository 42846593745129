import React from 'react';
import PropTypes from 'prop-types';

/**
 *
 * _If_ you need to use this, consider using `state` within the component itself.
 *
 * August 2019 Changes:
 * - Moved away from using PureStores within a LocalStore
 * - Decorator now micics state interal to the decorated component
 * - All methods for components that use LocalStores have not changed
 *
 * Note:
 * You must pass either a storeName prop into your DecoratedComponent or
 * you must give the decorator a storeName as its second argument.
 *
 * you can pass an optional prop storeNameId and it will be appended to the storeName
 *
 * In order to change the value of a store property, you can use the `setProperty` or
 * `setPropertyForceEmit` function props passed to your decorated component.
 *
 * In most cases, `setProperty` should suffice. This function calls PureStore's writeData,
 * which does _not_ cause a top level app rerender, but it will cause your decorated component
 * to rerender.
 *
 * If you need your setter to trigger a top level app rerender, use `setPropertyForceEmit`. This
 * calls PureStore's writeDataForceEmit, which does trigger a top level app rerender.
 *
 * Example usage:
 *
 * @createLocalStore(new Map({
 *   color: 'red'
 * }), 'someCoolStoreName')
 * class Testing extends React.Component {
 *
 *   changeColor = () => {
 *     const color = this.props.store.get('color') === 'red'
 *       ? 'blue'
 *       : 'red';
 *     this.props.setProperty('color', color);
 *   }
 *
 *   render() {
 *     return (
 *       <div>
 *         Color: {this.props.store.get('color')} <br />
 *         <button onClick={this.changeColor}>
 *           TOGGLE THE COLOR
 *         </button>
 *       </div>
 *     );
 *   }
 * }
 */

export default function createLocalStore(initialData, storeName) {
  return (DecoratedComponent) =>
    class extends React.Component {
      static propTypes = {
        storeName: PropTypes.string,
        storeNameId: PropTypes.string
      };

      constructor(props) {
        super(props);

        const initialState = this.initState();
        this.state = {
          ...initialState
        };
      }

      initState = () => {
        const dataKeys = initialData.keySeq().toJS();
        return dataKeys.reduce((acc, curr) => {
          acc[curr] = initialData.get(curr);
          return acc;
        }, {});
      };

      getState = (property) => {
        return this.state[property];
      };

      setProperty = (property, payload) => {
        this.setState({[property]: payload});
      };

      setPropertyForceEmit = (property, payload) => {
        /*
        This can create some issues within the ConfirmLeaveModal, as it requires a top-level rerender to reroute the user.
        This is still functional, but either this method needs to be deprecated or we need to find a way to force a toplevel rerender
      */
        this.setProperty(property, payload);
      };

      render() {
        const props = Object.assign({}, this.props, {
          store: {get: this.getState},
          setProperty: this.setProperty,
          setPropertyForceEmit: this.setPropertyForceEmit
        });
        return <DecoratedComponent {...props} />;
      }
    };
}

export const localStorePropType = PropTypes.object;
