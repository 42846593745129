/* eslint-disable import/no-webpack-loader-syntax */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';

import {Button, Dialogue, Text, Heading, TextArea, addToast} from '@albert-io/atomic';
import {resource} from '@albert-io/json-api-framework/request/builder';

import appStore from 'client/AppStore';
import sessionStore from 'client/Session/SessionStore';

import awaitMandarkQueries from 'lib/hocs/awaitMandarkQueries';

import {CustomerFeedbackEntryModelV1} from 'resources/GeneratedModels/CustomerFeedbackEntry/CustomerFeedbackEntryModel.v1';
import {UserModelV2} from 'resources/GeneratedModels/User/UserModel.v2';

import letterA from '!html-loader!./letter-a.svg';
import letterB from '!html-loader!./letter-b.svg';
import letterC from '!html-loader!./letter-c.svg';
import letterD from '!html-loader!./letter-d.svg';
import letterF from '!html-loader!./letter-f.svg';

import './customer-feedback.scss';

const toasts = (name) => {
  return [
    {
      color: 'positive',
      title: `Thanks, ${name}!`,
      message: "Rate us any time from your account menu, or, we'll remind you in 90 days."
    },
    {
      color: 'positive',
      title: `Okay ${name}!`,
      message: "We'll remind you again tomorrow."
    },
    {
      color: 'positive',
      title: ' ',
      message: "We'll stop asking. You can still rate us any time via your account menu."
    }
  ];
};

const title = (name) => {
  return (
    <div className='feedback-dialogue__title u-pad-r_0 u-pad-t_0'>
      <Heading size='m'>{name},</Heading>
      <Heading size='m'>how would you grade Albert? </Heading>
    </div>
  );
};

const CustomerFeedbackDialogue = awaitMandarkQueries(
  () => ({
    queries: {
      user: resource('users_v2').mandarkEndpoint(['users_v2', sessionStore.getUserId()])
    }
  }),
  class CustomerFeedbackDialogue extends React.PureComponent {
    static propTypes = {
      name: PropTypes.string,
      handleClose: PropTypes.func,
      inModal: PropTypes.bool,
      fromNavbar: PropTypes.bool,
      clearIcon: PropTypes.func,
      user: PropTypes.instanceOf(UserModelV2)
    };

    static defaultProps = {
      fromNavbar: false
    };

    constructor() {
      super();

      this.state = {
        selectedGrade: null,
        comment: ''
      };
    }

    updateComment = (event) => {
      this.setState({comment: event.target.value});
    };

    handleKeyPress = (event, grade) => {
      if (event.key === 'Enter') {
        this.setState({selectedGrade: grade});
      }
    };

    handleGrade = async () => {
      try {
        await new CustomerFeedbackEntryModelV1()
          .setComment(this.state.comment)
          .setGrade(this.state.selectedGrade)
          .setUrlSlug(appStore.routerProps.location.pathname)
          .addRelationship({
            type: 'user_v1',
            relation: sessionStore.getUserId()
          })
          .save();

        const {user} = this.props;
        await user
          .setCustomerFeedbackReminderDate(
            moment().add(90, 'days').startOf('day').add(8, 'hours').format()
          )
          .save();
      } catch (error) {
        addToast({
          title: '',
          color: 'negative',
          message: 'There was a problem with your request. If the problem persists, contact us.'
        });
      }
      this.props.handleClose();
      if (this.props.fromNavbar) {
        this.props.clearIcon();
      }
    };

    handleRemind = async () => {
      const {user} = this.props;
      try {
        await user
          .setCustomerFeedbackReminderDate(
            moment().add(1, 'days').startOf('day').add(8, 'hours').format()
          )
          .save();
      } catch (error) {
        addToast({
          title: '',
          color: 'negative',
          message: 'There was a problem with your request. If the problem persists, contact us.'
        });
      }
      this.props.handleClose();
      if (this.props.fromNavbar) {
        this.props.clearIcon();
      }
    };

    handleNeverAskAgain = async () => {
      const {user} = this.props;
      try {
        await user.setCustomerFeedbackReminderDate(null).save();
      } catch (error) {
        addToast({
          title: '',
          color: 'negative',
          message: 'There was a problem with your request. If the problem persists, contact us.'
        });
      }
      this.props.handleClose();
      if (this.props.fromNavbar) {
        this.props.clearIcon();
      }
    };

    buttonsWithToasts = () => (
      <>
        <Dialogue.BtnGroup align='center' direction='column' fillColumn>
          <Button
            color='secondary'
            onClick={() => {
              addToast(toasts(this.props.name)[0]);
              this.handleGrade();
            }}
            disabled={!this.state.selectedGrade}
          >
            Submit grade
          </Button>
          {this.props.fromNavbar && (
            <Button
              color='secondary'
              onClick={() => {
                addToast(toasts(this.props.name)[1]);
                this.handleRemind();
              }}
            >
              Remind me later
            </Button>
          )}
          {this.props.fromNavbar && (
            <Button
              color='secondary'
              onClick={() => {
                addToast(toasts(this.props.name)[2]);
                this.handleNeverAskAgain();
              }}
            >
              Never ask me again
            </Button>
          )}
          <Button color='secondary' onClick={this.props.handleClose}>
            Cancel
          </Button>
        </Dialogue.BtnGroup>
      </>
    );

    render() {
      return (
        <Dialogue
          alignTitle='center'
          inModal={this.props.inModal}
          groupTitle
          hideCloseBtn
          stretchTitle
          title={title(this.props.name)}
          handleClose={this.props.handleClose}
          className='feedback-dialogue__container'
        >
          <Dialogue.Body align='center'>
            <Text className='feedback-dialogue__text' size='r' color='secondary'>
              Select a grade
            </Text>
            <br />
            <div className='feedback-dialogue__letters-outer-container u-mar-t_6'>
              <div
                onKeyPress={(e) => this.handleKeyPress(e, 1)}
                onClick={() => {
                  this.setState({selectedGrade: 1});
                }}
                className={classnames({
                  'feedback-dialogue__selected-letter': this.state.selectedGrade === 1
                })}
                dangerouslySetInnerHTML={{__html: letterA}}
                tabIndex='0'
                role='button'
                aria-label='Give Albert an A'
              />
              <div
                onKeyPress={(e) => this.handleKeyPress(e, 2)}
                onClick={() => {
                  this.setState({selectedGrade: 2});
                }}
                className={classnames({
                  'feedback-dialogue__selected-letter': this.state.selectedGrade === 2
                })}
                dangerouslySetInnerHTML={{__html: letterB}}
                tabIndex='0'
                role='button'
                aria-label='Give Albert a B'
              />
              <div
                onKeyPress={(e) => this.handleKeyPress(e, 3)}
                onClick={() => {
                  this.setState({selectedGrade: 3});
                }}
                className={classnames({
                  'feedback-dialogue__selected-letter': this.state.selectedGrade === 3
                })}
                dangerouslySetInnerHTML={{__html: letterC}}
                tabIndex='0'
                role='button'
                aria-label='Give Albert a C'
              />
              <div
                onKeyPress={(e) => this.handleKeyPress(e, 4)}
                onClick={() => {
                  this.setState({selectedGrade: 4});
                }}
                className={classnames({
                  'feedback-dialogue__selected-letter': this.state.selectedGrade === 4
                })}
                dangerouslySetInnerHTML={{__html: letterD}}
                tabIndex='0'
                role='button'
                aria-label='Give Albert a D'
              />
              <div
                onKeyPress={(e) => this.handleKeyPress(e, 5)}
                onClick={() => {
                  this.setState({selectedGrade: 5});
                }}
                className={classnames({
                  'feedback-dialogue__selected-letter': this.state.selectedGrade === 5
                })}
                dangerouslySetInnerHTML={{__html: letterF}}
                tabIndex='0'
                role='button'
                aria-label='Give Albert a F'
              />
            </div>
            <TextArea
              border
              placeholder='Comments (optional)'
              size='regular'
              className='u-mar-t_5'
              value={this.state.comment}
              defaultValue=''
              onChange={this.updateComment}
            />
          </Dialogue.Body>
          {this.buttonsWithToasts()}
        </Dialogue>
      );
    }
  }
);

export default CustomerFeedbackDialogue;
