/**
 * # Dropdown
 *
 * A dropdown component that displays a trigger element and a content element when triggered.
 * The component also exposes the `Dropdown.Tray` if your use case doesn't align with the default `trigger` element.
 *
 * ## Props
 *
 * - `children` (node): The content to be displayed inside the dropdown.
 * - `className` (string): Additional CSS class name for the dropdown.
 * - `fillWrapper` (bool): Whether the dropdown should fill its wrapper element.
 * - `freeze` (bool): Whether the dropdown should freeze in its open state.
 * - `onDismiss` (bool): Callback function to be called when the dropdown is dismissed.
 * - `trigger` (node, required): The trigger element that will toggle the dropdown.
 * - `position` (string): The position of the dropdown relative to the trigger element. (extends `positionTypes`)
 * - `positionFixed` (bool): Whether the dropdown should have a fixed position.
 * - `withMaxHeight` (bool): Whether the dropdown should have a maximum height.
 * - `withMinWidth` (bool): Whether the dropdown should have a minimum width.
 * - `wrapperClassName` (string): Additional CSS class name for the dropdown wrapper.
 * - `trayClassName` (string): Additional CSS class name for the dropdown tray.
 *
 * ## Usage
 *
 * ```jsx
 * import Dropdown from './Dropdown.react';
 *
 * const MyComponent = () => {
 *   return (
 *     <Dropdown
 *       trigger={<button>Toggle Dropdown</button>}
 *       position="bottom-start"
 *       withMinWidth={true}
 *       withMaxHeight={false}
 *     >
 *       <div>Dropdown Content</div>
 *     </Dropdown>
 *   );
 * };
 * ```
 */
/* eslint-disable no-param-reassign */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {Manager, Target, Popper, placements} from 'react-popper';
import makeConstants from 'lib/makeConstants';

import WithToggle from '../../helpers/WithToggle/WithToggle.react';
import IconButton from '../IconButton/IconButton.react';
import Card from '../../atoms/Card/Card.react';
import {WindowSizeConsumer} from '../../helpers/WindowSizeProvider/WindowSizeProvider.react';
import './dropdown.scss';

const positionTypes = makeConstants(...placements);

const modifiers = {
  preventOverflow: {
    padding: 64
  },
  flip: {
    padding: 16
  },
  setMobile: {
    enabled: false,
    order: 840,
    fn: (data) => {
      data.styles.transform = 'none';
      data.styles.top = 'auto';
      data.offsets.popper.position = 'fixed';
      return data;
    }
  }
};

// prevents default click events from bubbling when within another clickable element
function stopClickDefault(e, callback) {
  e.preventDefault();
  callback(e);
}

const Dropdown = ({
  children,
  className,
  fillWrapper,
  freeze,
  onDismiss,
  trigger,
  position,
  positionFixed,
  withMaxHeight,
  withMinWidth,
  wrapperClassName,
  trayClassName,
  onToggleCallback = () => {},
  closeOnItemClicked = false,
  ...restProps
}) => {
  // Esc key closes dropdown element
  const handleKeyPress = (key, on, onClick) => {
    if (on && key === 'Escape') {
      onClick();
    }
  };
  return (
    <WithToggle freeze={freeze} className={wrapperClassName} onDismiss={onDismiss}>
      {({onClick: onToggle, on}) => {
        onToggleCallback(on);
        const onClick =
          on && typeof onDismiss === 'function'
            ? (e) => {
                e.persist();
                onDismiss(e);
                onToggle(e);
              }
            : onToggle;
        const handleItemClick = (e, childOnClick) => {
          if (childOnClick) {
            childOnClick(e);
          }
          if (closeOnItemClicked) {
            onClick(e);
          }
        };
        return (
          <WindowSizeConsumer>
            {(context) => {
              if (!context) {
                return null;
              }
              const {viewportWidth, breakpoints} = context;
              modifiers.setMobile.enabled = viewportWidth <= breakpoints.s;
              return (
                <Manager className={classnames('m-dropdown', className)} {...restProps}>
                  <Target
                    className={classnames('m-dropdown__target', {
                      'm-dropdown__target--fill': fillWrapper
                    })}
                  >
                    {React.Children.toArray(trigger).map((el) => {
                      // @todo This ternary re-enables attaching events to the trigger element
                      // but it suggests that this component is due for a refactor to make its parts
                      // more composable. @see also Dropdown.Tray
                      const handleClick = el.props.onClick
                        ? (e) => {
                            e.persist();
                            el.props.onClick(e);
                            stopClickDefault(e, onClick);
                          }
                        : (e) => stopClickDefault(e, onClick);
                      return React.cloneElement(el, {
                        onClick: handleClick,
                        onKeyDown: el.props.onKeyPress
                          ? (e) => {
                              e.persist();
                              el.props.onKeyPress(e);
                              handleKeyPress(e.key, on, onClick);
                            }
                          : (e) => handleKeyPress(e.key, on, onClick),
                        'aria-haspopup': true,
                        'aria-expanded': on
                      });
                    })}
                  </Target>
                  {on && (
                    <Dropdown.Tray
                      className={classnames(trayClassName, 'm-dropdown__content-wrapper', {
                        'm-dropdown__content-wrapper--show': on
                      })}
                      placement={position}
                      positionFixed={positionFixed}
                    >
                      {viewportWidth <= breakpoints.s ? (
                        <IconButton
                          className='m-dropdown__mobile-close'
                          icon='times'
                          variant='outlined'
                          label='close'
                          onClick={onClick}
                        />
                      ) : null}
                      <Card
                        role='dialog'
                        onClick={onClick}
                        className={classnames('m-dropdown__content', {
                          'm-dropdown__content--min': withMinWidth,
                          'm-dropdown__content--max': withMaxHeight
                        })}
                      >
                        {React.Children.toArray(children).map((child, i, arr) => {
                          const childOnClick = child.props.onClick;
                          return React.cloneElement(child, {
                            // We don't apply a bottom border on the last item
                            className: classnames(child.props.className, {
                              'm-dropdown__content--border-bottom': i + 1 !== arr.length
                            }),
                            tabIndex: '0',
                            onKeyDown: (e) => handleKeyPress(e.key, on, onClick),
                            onClick: (e) => {
                              e.stopPropagation();
                              handleItemClick(e, childOnClick);
                            }
                          });
                        })}
                      </Card>
                    </Dropdown.Tray>
                  )}
                </Manager>
              );
            }}
          </WindowSizeConsumer>
        );
      }}
    </WithToggle>
  );
};

Dropdown.Tray = ({className, children, position, positionFixed, ...rest}) => {
  return (
    <Popper
      className={className}
      modifiers={modifiers}
      placement={position}
      positionFixed={positionFixed}
      {...rest}
    >
      {children}
    </Popper>
  );
};

Dropdown.Tray.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  position: PropTypes.oneOf(Object.values(positionTypes)),
  positionFixed: PropTypes.bool
};

Dropdown.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  fillWrapper: PropTypes.bool,
  freeze: PropTypes.bool,
  onDismiss: PropTypes.bool,
  position: PropTypes.oneOf(Object.values(positionTypes)),
  positionFixed: PropTypes.bool,
  trigger: PropTypes.node.isRequired,
  withMaxHeight: PropTypes.bool,
  withMinWidth: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  trayClassName: PropTypes.string,
  onToggleCallback: PropTypes.func,
  closeOnItemClicked: PropTypes.bool
};

Dropdown.defaultProps = {
  position: 'bottom-start',
  withMinWidth: true,
  withMaxHeight: false
};

export default Dropdown;
