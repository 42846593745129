import React from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {Link} from 'react-router';
import {Text} from '@albert-io/atomic';
import Modal, {ModalContent} from '../Modal.react';
import {hideModal} from '../ModalActions';
import LoadingIndicator from 'client/generic/LoadingIndicator.react';
import upgradeCallToActionStore from './UpgradeCallToActionStore';
import sessionStore from 'client/Session/SessionStore';
import {doesUserBelongToAnyLicensedClassrooms} from 'lib/UserAccessUtil';
import './assets/upgrade-call-to-action.scss';

import questionsImage from 'assets/images/modal-icons/icon1.svg';
import attemptsImage from 'assets/images/modal-icons/icon2.svg';
import explanationImage from 'assets/images/modal-icons/icon3.svg';
import freeResponseImage from 'assets/images/modal-icons/icon4.svg';
import folderImage from 'assets/images/modal-icons/icon5.svg';
import rocketImage from 'assets/images/modal-icons/rocket.svg';

export default class UpgradeCallToActionModal extends React.Component {
  static propTypes = {
    location: ImmutablePropTypes.map
  };

  hideCTAModal = () => {
    hideModal('UpgradeCallToAction');
  };

  isLoggedInStudentViewingAssessments() {
    return (
      sessionStore.isStudent() &&
      doesUserBelongToAnyLicensedClassrooms().value &&
      this.props.location.get('pathname').includes('/assessments')
    );
  }

  generateSellingPoint({total, image, content}) {
    if (total === 0) {
      return null;
    }

    return (
      <li className='upgrade-cta__selling-point-wrapper'>
        <img alt='' className='upgrade-cta__image-icon' src={image} />
        <p className='upgrade-cta__selling-point'>{content}</p>
      </li>
    );
  }

  generateHeaderString() {
    if (doesUserBelongToAnyLicensedClassrooms().value) {
      return `You don't have access`;
    }
    return 'Upgrade now for full access';
  }

  generateCallToActionHeader() {
    const subject = upgradeCallToActionStore.getSubject();
    if (sessionStore.isTeacher()) {
      return (
        <div className='upgrade-cta__header'>
          Take your students to the next level in{' '}
          <span className='upgrade-cta__header-subject'>{subject.get('name')}</span>
        </div>
      );
    }

    return (
      <div className='upgrade-cta__header'>
        Take it to the next level for{' '}
        <span className='upgrade-cta__header-subject'>{subject.get('name')}</span>
      </div>
    );
  }

  generateTagline() {
    if (this.isLoggedInStudentViewingAssessments()) {
      return (
        <Text as='p' className='u-text-align_center'>
          You can access this assessment in the Assessments tab of your class! If you don&apos;t see
          it there, make sure your teacher has assigned it to you.
        </Text>
      );
    }

    if (sessionStore.isTeacher()) {
      return 'With a school license, you get...';
    }

    if (doesUserBelongToAnyLicensedClassrooms().value) {
      return `If you’d like to self-study this subject, you'll need to ask your teacher to grant you access. Once they do, you'll get...`;
    }

    return 'With upgraded access, you get...';
  }

  generateSellingPoints() {
    if (this.isLoggedInStudentViewingAssessments()) {
      return null;
    }
    const subject = upgradeCallToActionStore.getSubject();
    const questionsTotal = subject.getMeta().getCountOfQuestions();
    const freeResponseTotal = subject.getMeta().getCountOfFreeResponseQuestions();
    if (sessionStore.isTeacher()) {
      return (
        <ul className='upgrade-cta__selling-point-list'>
          {this.generateSellingPoint({
            total: questionsTotal,
            image: questionsImage,
            content: `Subscriptions for all of your students with unlimited attempts on all ${questionsTotal} questions`
          })}
          {this.generateSellingPoint({
            total: true,
            image: freeResponseImage,
            content: `The ability to create assignments for the entire subject`
          })}
          {this.generateSellingPoint({
            total: true,
            image: folderImage,
            content: `Access to advanced data reporting features`
          })}
        </ul>
      );
    }

    return (
      <ul className='upgrade-cta__selling-point-list'>
        {this.generateSellingPoint({
          total: questionsTotal,
          image: questionsImage,
          content: `Access to ${questionsTotal} more practice questions`
        })}
        {this.generateSellingPoint({
          total: freeResponseTotal,
          image: freeResponseImage,
          content: `Access to ${freeResponseTotal} more free response examples`
        })}
        {this.generateSellingPoint({
          total: true,
          image: explanationImage,
          content: `Detailed explanations for every question`
        })}
        {this.generateSellingPoint({
          total: true,
          image: attemptsImage,
          content: `Unlimited attempts per question`
        })}
      </ul>
    );
  }

  getMarketplaceLink() {
    const subject = upgradeCallToActionStore.getSubject();
    const domain = subject.getDomainSlug();
    const group = subject.getGroupSlug();
    /**
     * @todo: Remove `&subjectId=` once we update the subject resources
     * with their new domain and group mappings. Until then, we must
     * send the subject ID in order to initialize the course library
     * with the correct filters applied.
     */
    return `/marketplace/subjects?domain=${domain}&group=${group}&subject=${subject.getUrlSlug()}&subjectId=${subject.getId()}`; // eslint-disable-line
  }

  generateButtonLink() {
    if (sessionStore.isTeacher()) {
      return (
        <div className='upgrade-cta__button-wrapper'>
          <a className='button button--green' href='/pricing-schools' onClick={this.hideCTAModal}>
            Learn about licenses
          </a>
        </div>
      );
    }

    if (doesUserBelongToAnyLicensedClassrooms().value) {
      return null;
    }

    return (
      <div className='upgrade-cta__button-wrapper'>
        <Link
          className='button button--green'
          to={this.getMarketplaceLink()}
          onClick={this.hideCTAModal}
        >
          Upgrade Subject
        </Link>
      </div>
    );
  }

  generateSubscriptionLink() {
    if (!sessionStore.isTeacher()) {
      return null;
    }

    return (
      <div className='upgrade-cta__link-wrapper'>
        <Link
          className='upgrade-cta__link'
          to={this.getMarketplaceLink()}
          onClick={this.hideCTAModal}
        >
          Purchase individual subscription
        </Link>
      </div>
    );
  }

  render() {
    if (
      !upgradeCallToActionStore.hasSubjects() ||
      !doesUserBelongToAnyLicensedClassrooms().isReady
    ) {
      return (
        <Modal modalWrapperClass='modal--upgrade-cta' modalKey='UpgradeCallToAction'>
          <ModalContent>
            <LoadingIndicator />
          </ModalContent>
        </Modal>
      );
    }

    return (
      <Modal
        headerString={this.generateHeaderString()}
        modalWrapperClass='modal--upgrade-cta'
        modalKey='UpgradeCallToAction'
      >
        <ModalContent>
          <div className='upgrade-cta'>
            <img alt='' className='upgrade-cta__rocket-img' src={rocketImage} />
            {this.generateCallToActionHeader()}
            <p className='upgrade-cta__header-tagline'>
              <b>{this.generateTagline()}</b>
            </p>
            {this.generateSellingPoints()}
            {this.generateButtonLink()}
            {this.generateSubscriptionLink()}
          </div>
        </ModalContent>
      </Modal>
    );
  }
}
