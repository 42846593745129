import React from 'react';
import PropTypes from 'prop-types';
import SimpleMarkdown from 'simple-markdown';
import classnames from 'classnames';
import {makeParser} from 'generic/MarkdownRendererV2/markdownRendererUtils';
import makeRef from 'lib/makeRef';
import './passage-correction-renderer.scss';

/**
 * This renderer is used to render different forms of the Passage Correction question type (the editor,
 * pre-submission renderer, post-submission renderer, and explanation renderer). It works by adding a rule
 * to the SimpleMarkdown rules that splits text into individual words, indexes each word, and adds the words
 * index as a data property on the word. If regions are provided, if a word is in a given region, it will also
 * add that region index to the word.
 *
 * If a popover prop is provided, the element will be cloned, and it will receive the renderer's wrapper node,
 * as well as the node of the last word that was marked active as props (rendererWrapperNode and anchorNode,
 * respectively)
 */

export default class PassageCorrectionRenderer extends React.Component {
  static propTypes = {
    onClick: PropTypes.func,
    onMouseDown: PropTypes.func,
    onMouseOver: PropTypes.func,
    popover: PropTypes.node,
    text: PropTypes.string.isRequired,
    wordPropsFunc: PropTypes.func
  };

  static defaultProps = {
    onClick: () => {},
    onMouseDown: () => {},
    onMouseOver: () => {},
    wordPropsFunc: () => {}
  };

  constructor() {
    super();
    this.jsxKeyIndex = 0;
    this.optionIndex = 0;
    this.state = {
      lastActiveWordNode: null
    };
  }

  UNSAFE_componentWillUpdate() {
    this.jsxKeyIndex = 0;
    this.optionIndex = 0;
  }

  setLastActiveWordNode = (node) => {
    if (this.state.lastActiveWordNode === node) {
      return;
    }
    this.setState({
      lastActiveWordNode: node
    });
  };

  renderWhitespace(whitespace) {
    return <Whitespace key={this.jsxKeyIndex++} content={whitespace} />;
  }

  /**
   * This is where most of the magic happens. Every word that SimpleMarkdown parser gets run through
   * this method, which will ultimately wrap the word in the Word component with the appropriate prop.
   * The wordPropsFunc prop will determine the word's functionality. It should return an object where the
   * values are as follows:
   *
   * isActive (bool): If true, adds an active class to the word. If true and the word is the last word in the range,
   *   that word's node will be passed to the popover as the `anchorNode` prop.
   * isRangeStart (bool): Whether the current word is the first word in a region. Used for styling.
   * isRangeEnd (bool): Whether the current word is the last word in a region. Used for styling and to determine
   *   what node to make the `lastActiveWordNode` if the word is active.
   * isCorrected (bool): Before the student has placed a guess, this should return whether the word has been corrected
   *   by the student. Used for styling.
   * isCorrect (bool): After the student has placed a guess, this should return whether the guess was correct. Used for
   *   styling.
   * isIncorrect (bool): After the student has placed a guess, this should return whether the guess was incorrect. Used
   *   for styling.
   * isSelectable (bool): Whether the word can be interacted with. If truem, word will be highlighted on-hover.
   * matchedRegionIndex: If the word is in a region, this should be the index of that region.
   * onRenderWordFunc: If provided, this callback will be called for every word. It will get an object with the text,
   *   matchedRegionIndex, and optionIndex as its arguments.
   * correctedText: If provided, this will be used as the contents of the current word.
   * onClick: If provided, this will be called when the word is clicked. This can be used to supercede the click handler on
   *   the wrapper element.
   *
   * @param {string} text
   * @returns {React.Node}
   */
  renderText(text) {
    const {
      correctedText,
      isActive,
      isCorrect,
      isCorrected,
      isIncorrect,
      isRangeEnd,
      isRangeStart,
      isSelectable,
      matchedRegionIndex,
      onClick,
      onRenderWordFunc
    } = this.props.wordPropsFunc(text, this.optionIndex);
    const lastActiveWordRef = isActive && isRangeEnd ? this.setLastActiveWordNode : null;
    const optionIndex = this.optionIndex++;
    const jsxKeyIndex = this.jsxKeyIndex++;
    if (onRenderWordFunc) {
      onRenderWordFunc({text, matchedRegionIndex, optionIndex});
    }
    return (
      <Word
        content={correctedText !== undefined ? correctedText : text}
        isCorrected={isCorrected}
        isCorrect={isCorrect}
        isIncorrect={isIncorrect}
        isActive={isActive}
        isRangeStart={isRangeStart}
        isRangeEnd={isRangeEnd}
        regionIndex={matchedRegionIndex}
        lastActiveWordRef={lastActiveWordRef}
        isSelectable={isSelectable}
        onClick={onClick}
        idx={optionIndex}
        key={jsxKeyIndex}
      />
    );
  }

  createOutput() {
    const passageCorrectionWhitespace = {
      order: SimpleMarkdown.defaultRules.text.order - 0.5,
      match: (source) => {
        return /^(\s+)/.exec(source);
      },
      parse: (capture) => {
        return {
          content: capture[1]
        };
      },
      react: (node) => {
        return this.renderWhitespace(node.content);
      }
    };
    const passageCorrectionText = {
      order: SimpleMarkdown.defaultRules.text.order - 0.25,
      match: (source) => {
        return /^([^\s]+)/.exec(source);
      },
      parse: (capture) => {
        return {
          content: capture[1]
        };
      },
      react: (node) => {
        return this.renderText(node.content);
      }
    };

    const parser = makeParser({passageCorrectionWhitespace, passageCorrectionText});
    const syntaxTree = parser.makeSyntaxTree(this.props.text);
    return parser.reactOutput(syntaxTree);
  }

  makeRef = makeRef.bind(this);

  render() {
    return (
      <div
        className='passage-correction-renderer'
        onClick={this.props.onClick}
        onMouseDown={this.props.onMouseDown}
        onMouseOver={this.props.onMouseOver}
        ref={this.makeRef}
        data-ref-name='wrapperNode'
      >
        <div>{this.createOutput()}</div>
        {this.props.popover &&
          React.cloneElement(this.props.popover, {
            anchorNode: this.state.lastActiveWordNode,
            rendererWrapperNode: this.wrapperNode
          })}
      </div>
    );
  }
}

function Whitespace({content}) {
  return <span className='passage-correction-whitespace'>{content}</span>;
}

Whitespace.propTypes = {
  content: PropTypes.string
};

class Word extends React.Component {
  static propTypes = {
    idx: PropTypes.number,
    content: PropTypes.string,
    isCorrected: PropTypes.bool,
    isCorrect: PropTypes.bool,
    isIncorrect: PropTypes.bool,
    isActive: PropTypes.bool,
    isRangeStart: PropTypes.bool,
    isRangeEnd: PropTypes.bool,
    regionIndex: PropTypes.string,
    isSelectable: PropTypes.bool,
    lastActiveWordRef: PropTypes.func,
    onClick: PropTypes.func
  };

  static defaultProps = {
    onClick: () => {}
  };

  render() {
    const className = classnames('passage-correction-word', {
      'passage-correction-word--selectable': this.props.isSelectable,
      'passage-correction-word--corrected': this.props.isCorrected,
      'passage-correction-word--correct': this.props.isCorrect,
      'passage-correction-word--incorrect': this.props.isIncorrect,
      'passage-correction-word--active': this.props.isActive,
      'passage-correction-word--range-start': this.props.isRangeStart,
      'passage-correction-word--range-end': this.props.isRangeEnd
    });

    const isInteractive = !!this.props.regionIndex;

    const Element = isInteractive ? 'button' : 'span';

    return (
      <Element
        className={className}
        data-idx={this.props.idx}
        data-region-index={this.props.regionIndex}
        ref={this.props.lastActiveWordRef}
        onClick={this.props.onClick}
      >
        {this.props.content}
      </Element>
    );
  }
}
