/* eslint-disable import/prefer-default-export */
import {List} from 'immutable';
import moment from 'moment';

const maxLength = 80;

export const assignmentValidators = {
  getName(name) {
    const errors = [];
    if (!name) {
      errors.push('This field is required.');
    }
    if (name.length > maxLength) {
      errors.push(`This field must be ${maxLength} characters or less.`);
    }
    return errors.length > 0 ? List(errors) : null;
  },

  getStartDateFromSettings(date) {
    if (!date || date === 'now') {
      return null;
    }
    const errors = [];
    if (!date.isValid()) {
      errors.push('Please enter a valid date.');
    }

    const dueDate = this.getDueDateFromSettings();
    if (!date.isSame(dueDate, 'day') && date.isAfter(dueDate)) {
      errors.push('Start date must fall before due date.');
    }
    return errors.length > 0 ? List(errors) : null;
  },

  getDueDateFromSettings(date) {
    if (!date) {
      return List.of('This field is required.');
    }
    const errors = [];
    if (!date.isValid()) {
      errors.push('Please enter a valid date.');
    }
    if (moment().add(1, 'year').isBefore(date)) {
      errors.push('Due date must be within one year from now.');
    }
    return errors.length > 0 ? List(errors) : null;
  },

  getTimeLimitFromSettings(val) {
    const errors = [];
    const timeLimit = val === null ? null : parseInt(val, 10);
    /*
        Invalidate values greater than seven days.
      */
    if (timeLimit > 60 * 24 * 7) {
      errors.push('Time limit cannot exceed seven days');
    }
    if (timeLimit === 0) {
      errors.push('Time limit must be greater than 0');
    }
    return errors.length > 0 ? List(errors) : null;
  }
};
