import {createActions} from 'client/framework';

export default createActions(null, [
  'ADD_GUESS_RESOURCE',
  'CLEAR_ERROR',
  'CONTINUE_ASSIGNMENT',
  'HAS_WIPED_QUESTION_STORES',
  'INIT_ASSIGNMENT',
  'PRACTICE_EXAM_GUESS_PLACED',
  'RESET_STORE',
  'SET_IS_VIEWING_STAGING_DETAILS',
  'SET_SELECTED_ACTIVE_SECTION_ID',
  'SET_SHOW_SUBMIT_SECTION_MODAL',
  'START_ASSIGNMENT',
  'START_NEXT_SECTION',
  'SUBMIT_CURRENT_SECTION'
]);
