// @flow

import {
  EmptyQuestionModel,
  FillInTheBlankQuestionModel,
  FreeEntryQuestionModel,
  MultipleChoiceQuestionModel,
  SnippetSelectionQuestionModel,
  TextHighlightQuestionModel,
  TwoWayQuestionModel,
  PassageCorrectionQuestionModel,
  GraphingQuestionModel
} from './Question.model';

import type {QuestionType} from './Question.model';

export function getQuestionModel(questionType: string): QuestionType {
  return {
    empty: EmptyQuestionModel,
    'fill-in-the-blank': FillInTheBlankQuestionModel,
    'free-entry': FreeEntryQuestionModel,
    'free-response': EmptyQuestionModel,
    'multiple-choice': MultipleChoiceQuestionModel,
    'passage-correction': PassageCorrectionQuestionModel,
    'snippet-selection': SnippetSelectionQuestionModel,
    'text-highlight': TextHighlightQuestionModel,
    'two-way': TwoWayQuestionModel,
    'graph-contains': GraphingQuestionModel
  }[questionType];
}
