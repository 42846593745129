/**
 * head.js
 * A utility for composing elements for the `<head>` of an HTML document.
 * All methods are meant to be consumed by `react-helmet`'s `<Helmet />`.
 */
import * as React from 'react';
import {isArray} from 'lodash';

/**
 * Helper for creating a page `<title>`.
 * This will also return the proper title `<meta>` tag for OpenGraph.
 * @param {string | string[]} value
 * @returns {import('React').ReactNode}
 */
export function title(value) {
  if (!value) {
    return null;
  }

  let processedValue = value;
  if (isArray(value)) {
    /**
     * If the value provided is an Array, we cast it to a string
     * using our standardized seperator.
     */
    processedValue = value.join(' | ');
  }
  return [
    <title key='title'>{processedValue}</title>,
    <meta key='og:title' name='og:title' content={processedValue} />
  ];
}

export const meta = {
  /**
   * Helper for creating a page `<meta name='description'>`.
   * This will also return the proper description `<meta>` tag for OpenGraph.
   * @param {string} content
   * @returns {import('React').ReactNode}
   */
  description(content) {
    if (!content) {
      return null;
    }
    return [
      <meta key='description' name='description' content={content} />,
      <meta key='og:descritpion' name='og:description' content={content} />
    ];
  }
};
