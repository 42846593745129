import React from 'react';
import PropTypes from 'prop-types';
import {List} from 'immutable';
import classnames from 'classnames';
import validator from 'validator';
import SchoolV4Schema from 'resources/augmented/School/schemas/school_v4.schema.json';
import {getStoreByName} from 'client/framework';
import sessionStore from 'client/Session/SessionStore';
import BlockyDropdown from 'client/generic/BlockyDropdown/BlockyDropdown.react';
import {TooltipWrapper} from 'sg/Tooltip/Tooltip.react';
import TextInput from 'generic/Forms/TextInput/TextInput';

import SchoolSearch from './SchoolSearch/SchoolSearch.react';
import './school-search-grouped-inputs.scss';

export default class SchoolSearchGroupedInputs extends React.Component {
  static propTypes = {
    countryDropdownStoreName: PropTypes.string.isRequired,
    required: PropTypes.bool,
    schoolSearchDropdownStoreName: PropTypes.string.isRequired,
    disabled: PropTypes.bool
  };

  state = {
    zipCode: ''
  };

  isUsa() {
    const countryDropdownStore = getStoreByName(this.props.countryDropdownStoreName);
    return (
      Boolean(countryDropdownStore) &&
      countryDropdownStore.getSelectedItem() === 'United States of America'
    );
  }

  onCountryChange = () => {
    if (!this.isUsa()) {
      this.setState({zipCode: ''});
    }
  };

  onZipCodeChange = (e) => {
    this.setState({
      zipCode: e.target.value
    });
  };

  render() {
    const countryDropdownStore = getStoreByName(this.props.countryDropdownStoreName);
    const country = countryDropdownStore ? countryDropdownStore.getSelectedItem() : null;
    const {zipCode} = this.state;
    const isSuperUser = sessionStore.isSuper();
    const disableSchoolSearch =
      this.isUsa() && (!zipCode || !validator.isPostalCode(zipCode, 'any')) && !isSuperUser;

    return (
      <div className='school-search-grouped-inputs'>
        <div className='a-form-row'>
          <div className='a-form-col'>
            <BlockyDropdown
              className={classnames('school-search-grouped-inputs__input--wide', {
                'form__input--required': this.props.required
              })}
              data={List(
                SchoolV4Schema.properties.data.properties.attributes.properties.country.enum
              )}
              defaultValue='United States of America'
              storeName={this.props.countryDropdownStoreName}
              label='Country'
              onChange={this.onCountryChange}
              required={this.props.required}
              disabled={this.props.disabled}
            />
          </div>
          <div data-testid='school-zip' className='a-form-col'>
            <TextInput
              className='school-search-grouped-inputs__input'
              disabled={this.props.disabled || !this.isUsa()}
              label='School zip code'
              onChange={this.onZipCodeChange}
              required={this.props.required && this.isUsa() && !isSuperUser}
              type='text'
              value={this.state.zipCode}
            />
          </div>
        </div>
        <TooltipWrapper
          tooltip={disableSchoolSearch ? 'Fill out a valid zip code for your school' : null}
        >
          <SchoolSearch
            country={country}
            disableClear={this.props.disabled}
            disabled={this.props.disabled || disableSchoolSearch}
            storeName={this.props.schoolSearchDropdownStoreName}
            zipCode={zipCode}
            {...this.props}
          />
        </TooltipWrapper>
      </div>
    );
  }
}
