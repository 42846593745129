// @flow
import {Range, Map} from 'immutable';
import type {List} from 'immutable';

/**
 * This file is a port from the old `passageCorrectionQuestionExtensions`.
 * Instead of adding the methods to the question type these are now just functions
 * that take in the question to reduce noise on the question model.
 */

export function getUncorrectedText(question): string {
  return question.get('uncorrected_text');
}

export function getRegionIndices(question): Map<string, number> {
  return question.get('region_indices', Map());
}

export function getEnumeratedRegionIndices(question): List<number> {
  const func = () =>
    getRegionIndices(question).reduce((acc, region) => {
      const indicesInRegion = Range(region.get('start_index'), region.get('end_index') + 1).reduce(
        (acc, val) => {
          return acc.set(val, true);
        },
        Map()
      );
      return acc.merge(indicesInRegion);
    }, Map());

  return question.cache('getEnumeratedRegionIndices', func);
}

export function isOptionIndexInteractive(question, optionIndex): boolean {
  return getEnumeratedRegionIndices(question).get(parseInt(optionIndex, 10)) === true;
}

export function getRegionForOptionIndex(question, optionIndex): Map<string, any> {
  const matchedRegion = getRegionKeyForOptionIndex(question, optionIndex);
  return question
    .getMeta()
    .getQuestionRubric()
    .get('validResponse')
    .get(matchedRegion);
}

export function getRegionKeyForOptionIndex(question, optionIndex): Map<string, any> {
  const func = () =>
    getRegionIndices(question).findKey((region) => {
      return (
        [region.get('start_index'), optionIndex, region.get('end_index')].sort(
          (a, b) => a - b
        )[1] === optionIndex
      );
    });
  return question.cache(`getRegionKeyForOptionIndex/${optionIndex}`, func);
}

export function getSolutionsForRegionKey(question, regionKey): List<string> {
  return question.getValidResponse().getIn([regionKey, 'solutions']);
}

export function isRegionDistractor(question, regionKey): boolean {
  return getSolutionsForRegionKey(question, regionKey) === null;
}

export function doesRegionContainOptionIndex(region, optionIndex): boolean {
  const matchedStartIndex = region.get('start_index');
  const matchedEndIndex = region.get('end_index');
  return matchedStartIndex <= optionIndex && optionIndex <= matchedEndIndex;
}
