import React from 'react';
import PropTypes from 'prop-types';
import {v4 as uuid} from 'uuid';
import classnames from 'classnames';
import {callTargetedAction} from 'client/framework';
import Tooltip from 'sg/Tooltip/Tooltip.react';
import tooltipActions from 'sg/Tooltip/Tooltip.actions';
import './sg-icon-tooltip.scss';

export default class IconTooltip extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    color: PropTypes.oneOf(['light', 'dark']),
    content: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    icon: (props, propName, componentName) => {
      return !/^fa/.test(props[propName])
        ? new Error(
            `Invalid prop ${propName} passed to ${componentName}. ${propName} is expected to be a Font Awesome
          icon CSS class, and must start with 'fa-'`
          )
        : null;
    },
    position: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
    storeName: PropTypes.string,
    onHover: PropTypes.bool,
    onMouseEnter: PropTypes.func,
    tooltipClassName: PropTypes.string,
    spanTitle: PropTypes.string
  };

  static defaultProps = {
    color: 'dark',
    icon: 'fa-question-circle',
    position: 'right',
    onHover: false
  };

  constructor(props) {
    super(props);
    this.storeName = props.storeName || `IconTooltipStore${uuid()}`;
  }

  toggleDropdown = () => {
    callTargetedAction({
      name: tooltipActions.TOGGLE_VISIBILITY,
      targetStore: this.storeName
    });
  };

  noop = () => {};

  render() {
    const wrapperClass = classnames('sg-icon-tooltip', this.props.className);
    const iconClass = classnames('sg-icon-tooltip__icon', 'fa', this.props.icon);
    return (
      <div className={wrapperClass} onMouseEnter={this.props.onMouseEnter} role='tooltip'>
        <span
          className={iconClass}
          onClick={!this.props.onHover ? this.toggleDropdown : this.noop}
          onMouseEnter={this.props.onHover ? this.toggleDropdown : this.noop}
          onMouseLeave={this.props.onHover ? this.toggleDropdown : this.noop}
          title={this.props.spanTitle}
        />
        <Tooltip
          color={this.props.color}
          position={this.props.position}
          storeName={this.storeName}
          className={this.props.tooltipClassName}
          toggleOnHover={this.props.onHover}
        >
          {this.props.content}
        </Tooltip>
      </div>
    );
  }
}
