// @flow
import {fromJS, Map, Record} from 'immutable';
import moment from 'moment';
import {GenericModel} from 'resources/Generic.model';

const GuessRecord = Record({
  /* eslint-disable camelcase */
  assignment_id: '',
  content: new Map(),
  correct: false,
  eliminated_options: new Map(),
  id: '',
  inserted_at: 0,
  points_earned: 0,
  question: new Map(),
  student: new Map(),
  time_elapsed: 0
  /* eslint-enable camelcase */
});

const GuessRelationshipConfig = fromJS({
  endpoint: 'guesses_v1',
  relationships: {
    assignment_v2: {
      type: 'one-to-one'
    },
    student_v1: {
      type: 'one-to-one',
      required: true
    },
    question_v1: {
      type: 'one-to-one',
      required: true
    }
  },
  attributes: {
    correct: {
      create_allowed: false
    },
    points_earned: {
      create_allowed: false
    },
    question: {
      create_allowed: false
    },
    student: {
      create_allowed: false
    },
    inserted_at: {
      create_allowed: false
    }
  }
});

export class GuessModel extends GenericModel(GuessRecord, GuessRelationshipConfig) {
  getId(): string {
    return this.get('id');
  }

  getTimeElapsed(): number {
    return this.get('time_elapsed');
  }

  getTimeElapsedInSeconds(): number {
    return Math.round(this.getTimeElapsed() / 60);
  }

  getPointsEarned(): number {
    return this.get('points_earned');
  }

  getContent(): Map<*, *> {
    return this.get('content');
  }

  getEliminatedOptions(): Map<*, *> {
    return this.get('eliminated_options');
  }

  getInsertedAt(): string {
    return this.get('inserted_at');
  }

  getTimestamp(): number {
    return this.get('inserted_at');
  }

  getTimestampAsMoment(): number {
    return moment(this.getTimestamp());
  }

  isCorrect(): boolean {
    return this.get('correct');
  }

  getQuestion(): Map<*, *> {
    return this.get('question', new Map());
  }

  getStudent(): Map<*, *> {
    return this.get('student', new Map());
  }

  getAssignmentId(): string {
    return this.get('assignment_id');
  }
}
