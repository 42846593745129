// @flow
import {fromJS} from 'immutable';
import {Store} from 'client/framework';
import tableActions from './Table.actions';
import {sortDirections} from 'sg/Tables/tableConstants';

export default class TableStore extends Store {
  constructor(name: string) {
    super(name);
    this.setInitialData(
      fromJS({
        sortByFunc: null,
        sortByHeading: null,
        sortDirection: null,
        sortByIndex: null,
        isMobile: false
      })
    );
    this.handleTargeted(tableActions.SET_SORT_BY, this._setSortBy);
    this.handleTargeted(tableActions.SET_IS_MOBILE, this.setProperty('isMobile'));
  }

  _setSortBy({sortByFunc, sortByIndex}: {sortByFunc: Function, sortByIndex: number}) {
    let sortDirection;
    if (!this.getSortDirection()) {
      sortDirection = sortDirections.ASCENDING;
    } else if (this.getSortByIndex() === sortByIndex) {
      sortDirection =
        this.getSortDirection() === sortDirections.ASCENDING
          ? sortDirections.DESCENDING
          : sortDirections.ASCENDING;
    } else {
      sortDirection = sortDirections.ASCENDING;
    }
    this.writeData((store) =>
      store
        .set('sortByFunc', sortByFunc)
        .set('sortByIndex', sortByIndex)
        .set('sortDirection', sortDirection)
    );
  }

  getSortByFunc = (): Function | null => this.readData('sortByFunc');

  getSortByIndex = (): string => this.readData('sortByIndex');

  getSortDirection = (): string => this.readData('sortDirection');

  isMobile = (): boolean => this.readData('isMobile');
}
