/**
 * # WithTooltip
 *
 * Helper to render a tooltip around another component.
 *
 * ## Props
 *
 * - `anchorFixedPositionProps` (object): Props to be passed to the AnchoredFixedPosition component.
 * - `as` (string): The HTML tag or React component to be used as the wrapper element. Default is 'div'.
 * - `behavior` (string): The behavior of the tooltip. Possible values are 'hover' and 'click'. Default is 'hover'.
 * - `children` (node): The content to be wrapped by the tooltip.
 * - `className` (string): Additional CSS class name(s) to be applied to the wrapper element.
 * - `content` (node): The content of the tooltip.
 * - `display` (string): The display property of the wrapper element. Possible values are 'block', 'flex', and 'inline-block'. Default is 'inline-block'.
 * - `enabled` (boolean): Whether the tooltip is enabled or not. Default is true.
 * - `focusable` (boolean): Whether the tooltip can receive focus or not. Default is false.
 * - `hideFocus` (boolean): Whether to hide the focus outline or not. Default is false.
 * - `placement` (string): The placement of the tooltip. Possible values are 'top', 'right', 'bottom', and 'left'. Default is 'top'.
 * - `propagateHoverEvent` (boolean): Whether to propagate hover events to the wrapper element or not. Default is false.
 * - `wrapperProps` (object): Props to be passed to the wrapper element.
 * - `zLevel` (string): The z-index level of the tooltip. Default is 'tooltip'.
 *
 * ## Usage
 *
 * ```jsx
 * <WithTooltip
 *   enabled={buttonIsDisabled}
 *   content='Sorry, the button is disabled for some reason'
 * >
 *   <Button disabled={buttonIsDisabled}>
 *     Click Me
 *   </Button>
 * </WithTooltip>
 * ```
 */

/**
 * # Tooltip
 *
 * Tooltip component
 *
 * ## Props
 *
 * - `as` (string|func): The HTML tag or React component to be used as the wrapper element. Default is 'div'.
 * - `children` (node): The content of the tooltip.
 * - `className` (string): Additional CSS class name(s) to be applied to the wrapper element.
 * - `placement` (string): The placement of the tooltip. Possible values are 'top', 'right', 'bottom', and 'left'. Default is 'top'.
 * - `theme` (string): The theme of the tooltip. Possible values are 'dark' and 'light'. Default is 'dark'.
 *
 * ## Usage
 *
 * ```jsx
 * <Tooltip
 *   content='This is a tooltip'
 *   placement='bottom'
 *   theme='light'
 * >
 *   <Button>
 *     Hover Me
 *   </Button>
 * </Tooltip>
 * ```
 */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import makeConstants from 'lib/makeConstants';
import {handleTap} from 'lib/touchUtils';

import Text from '../../atoms/Text/Text.react';

import AnchoredFixedPosition from '../../helpers/AnchoredFixedPosition/AnchoredFixedPosition.react';

import './tooltip.scss';

export const placements = makeConstants(
  'auto-start',
  'auto',
  'auto-end',
  'top-start',
  'top',
  'top-end',
  'right-start',
  'right',
  'right-end',
  'bottom-end',
  'bottom',
  'bottom-start',
  'left-end',
  'left',
  'left-start'
);
export const themes = makeConstants('dark', 'light');
export const behaviors = makeConstants('hover', 'click');

/**
 * Helper to render a tooltip around another component.
 *
 * @example
 * <WithTooltip
 *   enabled={buttonIsDisabled}
 *   content='Sorry, the button is disabled for some reason'
 * >
 *   <Button disabled={buttonIsDisabled}>
 *     Click Me
 *   </Button>
 * </WithTooltip>
 */

export class WithTooltip extends React.Component {
  static propTypes = {
    /**
     * hover - Tooltip show when target is hovered and hide when the mouse leaves the target.
     *         On mobile/touch devices, the tooltip will toggle when the target is tapped.
     * click - Tooltip will show then clicked or tapped, and will hide when clicked or tapped again.
     */
    anchoredFixedPositionProps: PropTypes.object,
    as: PropTypes.string,
    behavior: PropTypes.oneOf(Object.values(behaviors)),
    children: PropTypes.node,
    className: PropTypes.string,
    content: PropTypes.node,
    display: PropTypes.oneOf(['block', 'flex', 'inline-block']),
    enabled: PropTypes.bool,
    focusable: PropTypes.bool,
    hideFocus: PropTypes.bool,
    placement: PropTypes.oneOf(Object.values(placements)),
    propagateHoverEvent: PropTypes.bool,
    wrapperProps: PropTypes.object,
    zLevel: PropTypes.string
  };

  static defaultProps = {
    as: 'div',
    behavior: behaviors.hover,
    display: 'inline-block',
    enabled: true,
    focusable: false,
    placement: placements.top,
    wrapperProps: {},
    zLevel: 'tooltip'
  };

  constructor() {
    super();

    this.state = {
      isActive: false
    };
  }

  wrapperRef = React.createRef();

  handleClick = () => {
    if (this.props.behavior === behaviors.click) {
      this.toggle();
    }
  };

  handleTouchStart = (e) => {
    if (this.props.behavior === behaviors.click) {
      return;
    }
    handleTap(e.target, this.toggle);
  };

  handleMouseEnter = () => {
    if (this.props.behavior === behaviors.hover) {
      this.activate();
    }
  };

  handleMouseLeave = () => {
    if (this.props.behavior === behaviors.hover) {
      this.deactivate();
    }
  };

  handleMouseOver = () => {
    if (this.props.behavior === behaviors.hover) {
      this.activate();
    }
  };

  handleMouseOut = () => {
    if (this.props.behavior === behaviors.hover) {
      this.deactivate();
    }
  };

  handleBlur = () => {
    if (this.props.focusable) {
      this.deactivate();
    }
  };

  handleFocus = () => {
    if (this.props.focusable) {
      this.activate();
    }
  };

  activate = () => this.setState(() => ({isActive: true}));

  deactivate = () => this.setState(() => ({isActive: false}));

  toggle = () => this.setState((state) => ({isActive: !state.isActive}));

  render() {
    const {
      as: Wrapper,
      anchoredFixedPositionProps,
      enabled,
      className,
      content,
      children,
      display,
      focusable,
      hideFocus,
      placement,
      propagateHoverEvent,
      wrapperProps,
      zLevel,
      ...rest
    } = this.props;

    const hoverEventHandlers = propagateHoverEvent
      ? {
          onMouseOut: this.handleMouseOut,
          onMouseOver: this.handleMouseOver
        }
      : {
          onMouseEnter: this.handleMouseEnter,
          onMouseLeave: this.handleMouseLeave
        };

    const style = {
      display,
      ...(hideFocus && {outline: 'none'})
    };

    return (
      <Wrapper
        ref={this.wrapperRef}
        style={style}
        onClick={this.handleClick}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        onTouchStart={this.handleTouchStart}
        tabIndex={focusable ? 0 : null}
        {...hoverEventHandlers}
        {...wrapperProps}
      >
        {children}
        {enabled && this.state.isActive && (
          <AnchoredFixedPosition
            className={classnames(className, `app-portal--z-${zLevel}`)}
            target={this.wrapperRef.current}
            placement={placement}
            {...anchoredFixedPositionProps}
          >
            <Tooltip {...rest}>{content}</Tooltip>
          </AnchoredFixedPosition>
        )}
      </Wrapper>
    );
  }
}

/**
 * Tooltip component
 */

const themeToPropMap = {
  [themes.dark]: {
    color: 'primary-inverse'
  },
  [themes.light]: {
    color: 'secondary'
  }
};

export const Tooltip = ({as, children, className, theme, placement, ...rest}) => (
  <Text
    {...rest}
    as={as}
    className={classnames(
      className,
      'a-tooltip',
      `a-tooltip--theme-${theme}`,
      `a-tooltip--placement-${placement}`
    )}
    color={themeToPropMap[theme].color}
    size='s'
  >
    {children}
  </Text>
);

Tooltip.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  children: PropTypes.node,
  className: PropTypes.string,
  placement: PropTypes.oneOf(Object.values(placements)),
  theme: PropTypes.oneOf(Object.values(themes))
};

Tooltip.defaultProps = {
  as: 'div',
  placement: placements.top,
  theme: themes.dark
};
