import React from 'react';
import PropTypes from 'prop-types';
import {SearchInput} from '@albert-io/atomic';
import './subject-menu-header.scss';

const justifyProps = [
  'center',
  'flex-end',
  'flex-start',
  'initial',
  'inherit',
  'space-around',
  'space-between'
];

const SubjectMenuHeader = ({
  searchInputProps,
  backgroundColor = '#2883bd',
  children,
  justifyContent = 'space-between'
}) => {
  return (
    <div
      className={`subject-menu-header__content-wrapper u-justify-content_${justifyContent} u-bgc_${backgroundColor}`}
    >
      {children}
      {searchInputProps ? (
        <SearchInput
          className='subject-menu-header__search'
          {...searchInputProps}
          data-testid='subject-menu-header__search-input'
        />
      ) : null}
    </div>
  );
};

SubjectMenuHeader.propTypes = {
  className: PropTypes.string,
  searchInputProps: PropTypes.object,
  backgroundColor: PropTypes.string,
  children: PropTypes.node,
  justifyContent: PropTypes.oneOf(justifyProps)
};

export default SubjectMenuHeader;
