// @flow
import * as React from 'react';
import {isArray} from 'lodash';
import LoadingIndicator from 'generic/LoadingIndicator.react';
import {history} from 'client/history';
import type {QueryBuilder} from '@albert-io/json-api-framework/request/builder';

/**
 * HOC for ensuring Mandark queries are populated before rendering a component.
 *
 * @callback optionsFunc - Callback that returns on options object.
 *   The callback function takes an optional argument that incoming props to the
 *   wrapped component will be bound to. Use this if you wish to, for example,
 *   use a prop passed to the wrapped component in any of the `queries` that you
 *   are returning from the `optionsFunc`.
 *
 *   Return object properties:
 *   - queries: What queries need to resolve before the Component is mounted.
 *     Queries can be supplied as an array or an object. If an object is supplied, the
 *     decorated component will receive the result of .getResource() from the query as
 *     a prop with the name of the key of that object.
 *   - loadingElement: The component that will render while the queries are not populated.
 *   - onInvalidQuery: Handler to call if any queries are invalid.
 *   - invalidQueryElement: The component that will render if any queries are invalid.
 *     If onInvalidQuery is provided with a function that reroutes, this element may not
 *     render (due to no longer rendering the original route/component)
 *   - onWillMount: Function that will be called when the HOC mounts. Useful for setting up
 *     any necessary store data your queries may rely on. Receives props as its argument.
 *
 * @example <caption>optionsFunc with a bound props argument</caption>
 * ```javascript
 * awaitMandarkQueries((props) => ({
 *   queries: {
 *     classroomInvitations: getClassroomInvitationsQuery(props.user.getEmail())
 *   }
 * })
 * ```
 *
 * @param {Function} optionsFunc - Function that returns an options object
 * @param {*} Component - The component to decorate
 * @returns {React.Component}
 */
export default function awaitMandarkQueries(
  optionsFunc: (props: Map<string, *>) => {
    queries: Array<QueryBuilder> | {[string]: QueryBuilder},
    loadingElement?: React.Node,
    invalidQueryElement?: React.Node,
    onInvalidQuery?: () => any,
    onWillMount?: () => null,
    initialLoadOnly?: boolean,
    passthroughProps: {[string]: any}
  },
  Component: React.ComponentType<*>
): any {
  return class extends React.Component<any> {
    displayName = `AwaitMandarkQueries/${Component.displayName || 'WrappedComponent'}`;

    UNSAFE_componentWillMount() {
      const {onWillMount} = this.getOptions();
      if (onWillMount) {
        onWillMount(this.props);
      }
    }

    UNSAFE_componentWillUpdate() {
      if (!this.haveInitialQueriesLoaded && this.areQueriesReady()) {
        this.haveInitialQueriesLoaded = true;
      }
    }

    componentDidUpdate() {
      this.handleInvalidQuery();
    }

    haveInitialQueriesLoaded = false;

    getOptions(): Object {
      return optionsFunc(this.props);
    }

    handleInvalidQuery() {
      if (this.areQueriesValid()) {
        return;
      }
      if (this.getOptions().onInvalidQuery) {
        this.getOptions().onInvalidQuery();
      }
      if (!this.getOptions().invalidQueryElement) {
        history.pushState(null, '/error');
      }
    }

    getQueriesAsArray(): Array<QueryBuilder> {
      const {queries} = this.getOptions();
      return isArray(queries) ? queries : Object.values(queries);
    }

    areQueriesReady(): boolean {
      return this.getQueriesAsArray().every((query) => query.isResourcePopulated());
    }

    areQueriesValid(): boolean {
      return this.getQueriesAsArray().every((query) => query.isResourceValid());
    }

    getQueryProps(): {[string]: any} {
      const {queries} = this.getOptions();
      return !this.areQueriesReady() || Array.isArray(queries)
        ? {}
        : Object.entries(queries).reduce((acc, [propName, query]) => {
            acc[propName] = query.getResource();
            return acc;
          }, {});
    }

    getPassthroughProps(): {[string]: any} {
      const {passthroughProps} = this.getOptions();
      return passthroughProps || {};
    }

    render(): React.Node {
      const {loadingElement, invalidQueryElement, initialLoadOnly} = this.getOptions();
      const queries = this.getQueriesAsArray();
      const isLoading =
        queries.length > 0 && initialLoadOnly
          ? this.areQueriesValid() && !this.haveInitialQueriesLoaded
          : this.areQueriesValid() && !this.areQueriesReady();
      if (isLoading) {
        return !loadingElement && loadingElement !== null ? <LoadingIndicator /> : loadingElement;
      }
      if (!this.areQueriesValid()) {
        return invalidQueryElement || null;
      }
      return (
        <Component
          {...{
            ...this.props,
            ...this.getQueryProps(),
            ...this.getPassthroughProps()
          }}
        />
      );
    }
  };
}
