/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {Link} from 'react-router';
import PropTypes from 'prop-types';
import {history} from 'client/history';
import masqueradeStore from 'generic/Masquerade/Masquerade.store';
import {GuideLevelModelV2} from 'resources/augmented/GuideLevel/GuideLevelModel.v2';
import constants from 'client/constants';
import {IconButton, Dropdown, DropdownItem, copyToClipboard, addToast} from '@albert-io/atomic';

import {getQuestionSetsByGuideLevelIdQuery} from 'resources/Question/QuestionSet.queries';
import FoldersModal from 'components/PracticeView/FoldersModal/FoldersModal.react';
import {CreateAssignmentContext} from 'client/CreateAssignmentV2/CreateAssignmentProvider';
import {getQuestionSetsQuery} from 'client/CreateAssignmentV2/utils/createAssignment.queries';
import AssignModal, {SmartAssignTooltip} from 'components/AssignModal/AssignModal.react';
import './actions-dropdown.scss';
import track from 'react-tracking';

@track()
export default class ActionsDropdown extends React.Component {
  static propTypes = {
    guideLevel: PropTypes.instanceOf(GuideLevelModelV2),
    link: PropTypes.string,
    quickAssign: PropTypes.bool,
    questionSetIds: PropTypes.array,
    questionCount: PropTypes.number,
    tracking: PropTypes.object
  };

  static defaultProps = {
    quickAssign: true
  };

  constructor(props) {
    super(props);

    this.state = {
      showFolderModal: false,
      questionSetIds: props.questionSetIds || [],
      questionCount: props.questionCount || 0,
      showSmartModal: false
    };
  }

  trackClick = (content, options = {}) => {
    const {tracking} = this.props;
    tracking.trackEvent({
      event: 'click',
      content,
      component: 'assign dropdown',
      type: 'dropdown item',
      ...options
    });
  };

  handleQuickAssign = async () => {
    const {bootstrapAssignment} = this.context;
    const guideLevelId = this.props.guideLevel.getId();
    const query = getQuestionSetsQuery(
      'guide_levels_v2',
      guideLevelId,
      [],
      this.props.questionSetIds
    );
    bootstrapAssignment({
      query,
      resourceType: 'guide_levels_v2',
      assignmentOrigin: 'guide',
      resourceId: guideLevelId
    });
    history.pushState(null, `/create-assignment/new-assignment`);
  };

  handleSmartAssign = () => {
    const {guideLevel} = this.props;
    const questionCount = guideLevel.getMeta().getCountOfQuestions();
    const minSizeQuestionSet = guideLevel.getMeta().getMinSizeQuestionSet();
    this.trackClick('Smart assign');

    if (questionCount === 1) {
      this.handleQuickAssign();
    } else if (questionCount === minSizeQuestionSet) {
      this.handleQuickAssign();
    } else {
      this.setState({
        showSmartModal: true
      });
    }
  };

  /**
   * Fetches question sets and sets state
   */
  fetchQuestionSets = async () => {
    if (this.state.questionSetIds.length) {
      return;
    }

    const questionSets = await getQuestionSetsByGuideLevelIdQuery(
      this.props.guideLevel.getId()
    ).getResourcePromise();
    const questionSetIds = [];
    let questionCount = 0;
    questionSets.forEach((set) => {
      questionSetIds.push(set.getId());
      questionCount += set.getQuestions().size;
    });
    this.setState({
      questionSetIds,
      questionCount
    });
  };

  toggleFoldersModal = () => {
    this.setState(
      (state) => ({
        showFolderModal: !state.showFolderModal
      }),
      () => {
        if (this.state.showFolderModal) {
          this.fetchQuestionSets();
        }
      }
    );
  };

  handleView = () => {
    const {link} = this.props;
    history.pushState(null, link);
  };

  handleCopy = () => {
    const {link} = this.props;
    copyToClipboard(`${constants.ALBERT_FULL_PATH}${link}`, () => {
      addToast({
        color: 'positive',
        title: 'Link copied!',
        message: 'Share this link.'
      });
    });
  };

  static contextType = CreateAssignmentContext;

  render() {
    const {guideLevel, quickAssign, link} = this.props;
    return (
      <>
        <Dropdown
          trigger={
            <IconButton
              label='Save to folder dropdown'
              className='actions-dropdown__button'
              icon='ellipsis-v'
              onClick={() => this.trackClick('Assign', {component: 'Toolbar', type: 'dropdown'})}
            />
          }
          position='bottom-end'
          wrapperClassName='actions-dropdown__wrapper'
        >
          {link && (
            <DropdownItem
              className='actions-dropdown__no-border'
              as={Link}
              to={link}
              preventDefaultClickHandler={false}
              onClick={() => this.trackClick('View')}
            >
              View
            </DropdownItem>
          )}
          {quickAssign && (
            <DropdownItem
              as='button'
              role='link'
              onClick={() => {
                this.handleQuickAssign();
                this.trackClick('Assign all');
              }}
            >
              {`Assign${this.state.questionCount !== 1 ? ' All' : ''}`}
            </DropdownItem>
          )}
          <DropdownItem as='button' onClick={this.handleSmartAssign}>
            <SmartAssignTooltip leftContent='Smart assign' />
          </DropdownItem>
          <DropdownItem
            as='button'
            onClick={() => {
              this.toggleFoldersModal();
              this.trackClick('Save to Folders');
            }}
          >
            Save to Folders
          </DropdownItem>
          {link && (
            <DropdownItem
              as='button'
              role='link'
              onClick={() => {
                this.handleCopy();
                this.trackClick('Copy Link');
              }}
            >
              Copy Link
            </DropdownItem>
          )}
        </Dropdown>
        {this.state.showFolderModal && (
          <FoldersModal
            selectedQuestionSetIds={this.state.questionSetIds}
            userId={masqueradeStore.getUserIdByMasqueradeState()}
            closeModalFunc={this.toggleFoldersModal}
            selectedQuestionCount={this.state.questionCount}
          />
        )}
        {this.state.showSmartModal && (
          <AssignModal
            resource={guideLevel}
            type='guide'
            onClose={() => {
              this.setState({showSmartModal: false});
            }}
          />
        )}
      </>
    );
  }
}
