// @flow
import {isUndefined} from 'lodash';
import {List, Map, Record} from 'immutable';
import {memoize} from 'lib/memoizer';
import {SubjectModel} from 'resources/Subject/Subject.model';

function throwIfUndefined(fieldName, value) {
  if (isUndefined(value)) {
    throw new Error(`${fieldName} does not exist in the returned meta field`);
  }
}
export class GuideLevel extends Record({
  /* eslint-disable camelcase */
  description: '',
  free: '',
  id: '',
  level: '',
  meta: new Map(),
  name: '',
  objective_data: new List(),
  position: 0,
  question_sets: new List(),
  subject: new Map(),
  subtopics: new Map(),
  tags: new List(),
  theme_img: '',
  themes: new Map(),
  topics: new Map(),
  url_slug: ''
  /* eslint-enable camelcase */
}) {
  constructor(data: Map<*, *>) {
    super(data);
    this.cache = memoize();
  }

  getId(): string {
    return this.get('id');
  }

  isFree(): boolean {
    return this.get('free');
  }

  getLevel(): string {
    return this.get('level');
  }

  getPosition(): number {
    return this.get('position');
  }

  getTags(): List<string> {
    return this.get('tags') || new List();
  }

  getThemeImg(): string {
    return this.get('theme_img');
  }

  getDescription(): string {
    return this.get('description');
  }

  getName(): String {
    return this.get('name');
  }

  getUrlSlug(): String {
    return this.get('url_slug');
  }

  getQuestions(): List<Map<*, *>> {
    return this.get('question_sets');
  }

  getSubject(): SubjectModel {
    return this.get('subject');
  }

  getTopics(): List<GuideLevel> {
    return this.get('topics');
  }

  getSubtopics(): List<GuideLevel> {
    return this.get('subtopics');
  }

  getSortedTopics(): List<GuideLevel> {
    return this.cache('topics', () => this.get('topics').sortBy((topic) => topic.getPosition()));
  }

  getSortedSubtopics(): List<GuideLevel> {
    return this.cache('subtopics', () =>
      this.get('subtopics').sortBy((subtopic) => subtopic.getPosition())
    );
  }
}
