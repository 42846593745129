import React from 'react';
import PropTypes from 'prop-types';
import {List} from 'immutable';
import classnames from 'classnames';

import Button from 'sg/Button/Button.react';

import ScoopContainer from 'generic/ScoopContainer/ScoopContainer.react';

import {navbarLinksPropType} from 'generic/HorizontalNavigationComponent/shared';

import {BreadcrumbList} from './shared/BreadcrumbList.react';
import {Breadcrumb} from './shared/Breadcrumb.react';
import Icon from './shared/Icon.react';
import {KebabMenu} from './shared/KebabMenu';
import {Navbar} from './shared/Navbar.react';
import './hero-unit.scss';

export class HeroUnit extends React.Component {
  static BreadcrumbList = (props) => <BreadcrumbList {...props} />;

  static Breadcrumb = (props) => <Breadcrumb {...props} />;

  static Icon = (props) => <Icon {...props} />;

  static KebabMenu = (props) => <KebabMenu {...props} />;

  static propTypes = {
    backButton: PropTypes.instanceOf(Button),
    className: PropTypes.string,
    navbarLinks: navbarLinksPropType,
    searchInputProps: PropTypes.object,
    scoopSize: PropTypes.number,
    backgroundColor: PropTypes.string
  };

  static defaultProps = {
    navbarLinks: List(),
    backgroundColor: '#c2416e'
  };

  render() {
    const {
      backButton,
      className,
      navbarLinks,
      scoopSize,
      searchInputProps,
      backgroundColor,
      children
    } = this.props;
    const classNames = classnames('hero-unit', className, {
      'hero-unit--with-navbar': !navbarLinks.isEmpty()
    });
    return (
      <div className={classNames}>
        <ScoopContainer scoopSize={scoopSize} backgroundColor={backgroundColor}>
          <div
            className={classnames('hero-unit__content-wrapper', {
              'hero-unit__content-wrapper--with-search-input': searchInputProps
            })}
          >
            {backButton
              ? React.cloneElement(
                  backButton,
                  {
                    className: classnames(backButton.props.className, 'hero-unit__back-btn', {
                      'hero-unit__back-btn--compact': !backButton.props.children
                    }),
                    color: 'white'
                  },
                  backButton.props.children || backButton.props.text || 'Back'
                )
              : null}
            {React.Children.map(children, (child) => {
              return React.cloneElement(child, {});
            })}
          </div>
        </ScoopContainer>
        {searchInputProps ? (
          <SearchInput
            className={classnames({
              'hero-search-input--adjacent-nav': !navbarLinks.isEmpty()
            })}
            {...searchInputProps}
          />
        ) : null}
        {!navbarLinks.isEmpty() ? (
          <Navbar
            className={classnames({
              'hero-unit__navbar--adjacent-search': searchInputProps
            })}
            navbarLinks={navbarLinks}
          />
        ) : null}
      </div>
    );
  }
}

class SearchInput extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyPress: PropTypes.func
  };

  static defaultProps = {
    onChange: () => {},
    onFocus: () => {},
    onBlur: () => {},
    onKeyPress: () => {}
  };

  state = {
    isFocused: false,
    value: ''
  };

  handleChange = (e) => {
    this.setState({value: e.target.value}, () => this.props.onChange(this.state.value));
  };

  handleReset = (e) => {
    e.preventDefault();
    this.setState({value: ''}, () => this.props.onChange(this.state.value));
  };

  handleFocus = (e) => {
    this.setState({isFocused: true});
    this.props.onFocus(e);
  };

  handleBlur = (e) => {
    this.setState({isFocused: false});
    this.props.onBlur(e);
  };

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
    this.props.onKeyPress(e);
  };

  render() {
    /* eslint-disable no-unused-vars */
    const {onFocus, onBlur, onChange, onKeyPress, ...passthroughProps} = this.props;
    /* eslint-enable no-unused-vars */
    return (
      <div
        className={classnames('hero-search-input', this.props.className, {
          'hero-search-input--focused': this.state.isFocused
        })}
      >
        <span className='hero-search-input__search-icon fa fa-search' />
        <form action='#'>
          {' '}
          {/* This no-op is required to display the "Search" button on mobile keyboards */}
          <input
            {...passthroughProps}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
            className='hero-search-input__input'
            type='search'
            value={this.state.value}
          />
          <button
            disabled={!this.state.value}
            className='unbutton hero-search-input__reset-btn fa fa-close'
            onClick={this.handleReset}
            tabIndex={!this.state.value ? '-1' : '0'}
          >
            Reset
          </button>
        </form>
      </div>
    );
  }
}
