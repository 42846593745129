// @flow

const validatedTagCache = {
  browser: {},
  server: {}
};

/**
 * Regex pulled from react-dom/src/server/ReactPartialRenderer.js#L142
 * https://github.com/facebook/react/blob/73fa26a88b68bca77fb234fc405649d0f33a3815/packages/react-dom/src/server/ReactPartialRenderer.js#L142
 */
// We accept any tag to be rendered but since this gets injected into arbitrary
// HTML, we want to make sure that it's a safe tag.
// http://www.w3.org/TR/REC-xml/#NT-Name
const VALID_TAG_REGEX = /^[a-zA-Z][a-zA-Z:_\.\-\d]*$/; // Simplified subset

/**
 * isValidHTMLElement
 *
 * On the server, we use the same validation/RegEx as react-dom/src/server/ReactPartialRenderer.js#L139
 * https://github.com/facebook/react/blob/73fa26a88b68bca77fb234fc405649d0f33a3815/packages/react-dom/src/server/ReactPartialRenderer.js#L139
 *
 * On the client, we use a check similar to react-dom/src/client/ReactDOMFiberComponent.js#L389
 * https://github.com/facebook/react/blob/73fa26a88b68bca77fb234fc405649d0f33a3815/packages/react-dom/src/client/ReactDOMFiberComponent.js#L389
 * https://developer.mozilla.org/en-US/docs/Web/API/HTMLUnknownElement
 *
 * In both cases, we keep a cache of validated tags (not shared).
 */
export function isValidHTMLElement(tag: string): boolean {
  if (process.env.IS_BROWSER) {
    if (!validatedTagCache.browser.hasOwnProperty(tag)) {
      validatedTagCache.browser[tag] =
        Object.prototype.toString.call(document.createElement(tag)) !==
        '[object HTMLUnknownElement]';
    }
    return validatedTagCache.browser[tag];
  } else {
    /**
     * Isomorphic Render
     */
    if (!validatedTagCache.server.hasOwnProperty(tag)) {
      validatedTagCache.server[tag] = VALID_TAG_REGEX.test(tag);
    }
    return validatedTagCache.server[tag];
  }
}
