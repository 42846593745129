import React, {useState, useRef, useCallback, useContext, useEffect} from 'react';
import sessionStore from 'client/Session/SessionStore';
import {
  FindAndApply,
  Button,
  Icon,
  Popover,
  ListGroup,
  ListGroupItem,
  Checkbox,
  Label,
  Text,
  ButtonGroup,
  Anchor,
  WithTooltip,
  WithToggle,
  addGenericErrorToast
} from '@albert-io/atomic';
import {List} from 'immutable';
import {StandardSetModelV1} from 'resources/augmented/StandardSet/StandardSetModel.v1';
import {resource} from '@albert-io/json-api-framework/request/builder';
import classnames from 'classnames';
import {pluralize} from 'lib/stringUtils';
import {pushQueryParams} from 'client/history';

import {ReportsContext} from '../../Reports.context';

import 'client/components/AdvancedSearch/advanced-search.scss';
import ChipButton from './ChipButton';

function StandardSetFilter() {
  const {autoPersonalization, standardSets: standardSetIds} = useContext(ReportsContext);
  const [standardSets, setStandardSets] = useState(List());

  const buttonRef = useRef(null);

  // preload selected standard sets from queryParams
  useEffect(() => {
    if (standardSetIds) {
      resource('standard_sets_v1')
        .mandarkEndpoint(['standard_sets_v1'])
        .pageSize(100)
        .filter({
          id: standardSetIds
        })
        .getResourcePromise()
        .then(setStandardSets);
    }
  }, [standardSetIds]);

  const getQuery = useCallback((searchString) => {
    return resource('standard_sets_v1')
      .pageSize(100)
      .mandarkEndpoint(['standard_sets_v1'])
      .withMeta('standard_set_v1')
      .customQuery({
        meta: {
          context: {
            user: {
              id: sessionStore.getUserId(),
              personalization_settings: true
            }
          }
        }
      })
      .filter({
        all_of: [
          {
            any_of: !searchString
              ? [{'meta.included_in_personalization_settings': true}]
              : [
                  {
                    'meta.included_in_personalization_settings': false
                  },
                  {
                    'meta.search_relevance': {
                      greater_than: 0.5
                    }
                  }
                ]
          }
        ]
      })
      .customQuery(
        {meta: {context: {search: {fields: 'title,description', term: searchString}}}},
        !!searchString
      )
      .sort('-meta.included_in_personalization_settings,-meta.search_relevance', !!searchString)
      .pageSize(10);
  }, []);

  /*
    autoPersonalization (called 'personalization' in query params) is used to determine
    whether we display the "your preferences were automatically applied" message.
    Upon the user doing any manual action with the standard set filter, we immediately
    remove this query param and the message no longer applies. By default, upon entering
    the /standards route with no standard set related query params, a user with
    personalization's standard sets will be applied as queryParams to the URL.
  */
  const onApply = (results: List<StandardSetModelV1>) => {
    if (results.size) {
      pushQueryParams({
        standardSets: results
          .map((standardSet) => standardSet.getId())
          .toArray()
          .join(','),
        personalization: null
      });
    } else {
      pushQueryParams({
        standardSets: null,
        personalization: null
      });
    }
  };

  return (
    <WithToggle dismissOnEscape>
      {({onClick, on}) => (
        <>
          <span
            className={classnames('u-display_flex u-bgc_transparent u-border-radius_1', {
              'advanced-search__standard-sets-button-wrapper-active': standardSets.size > 0
            })}
            ref={buttonRef}
          >
            <ChipButton onClick={onClick}>
              {`${standardSets.size > 0 ? standardSets.size : ''} ${pluralize(
                'Standard Set',
                standardSets.size
              )}`}
              <Icon icon='caret-down' />
            </ChipButton>
          </span>
          {on && (
            <Popover
              usePortal={false}
              border='none'
              expanded={on}
              position='bottom-start'
              paddingLevel={null}
              targetRef={buttonRef}
              modifiers={{
                flip: {enabled: false},
                preventOverflow: {
                  boundariesElement: 'viewport' // or 'window'
                }
              }}
            >
              <FindAndApply
                className='advanced-search_filter-dropdown u-width_100pc'
                query={getQuery}
                onApply={onApply}
                onDismiss={onClick}
                fetchInitial
                defaultSelectedItems={standardSets}
                onError={addGenericErrorToast}
              >
                <FindAndApply.Header paddingLevel={1}>
                  {({HeaderSearchInput}) => (
                    <HeaderSearchInput
                      variant
                      size='s'
                      placeholder='Search standard sets'
                      className='standard-set-filter__search-input u-border-color_slate-300'
                    />
                  )}
                </FindAndApply.Header>
                <FindAndApply.Body paddingLevel={0}>
                  {({searchResults, selectedItems, handleChange, searchString}) => {
                    if (
                      !selectedItems.size &&
                      !searchString &&
                      !searchResults.some((result) =>
                        result.getMeta().isIncludedInPersonalizationSettings()
                      )
                    ) {
                      return (
                        <FindAndApply.Message
                          color='tertiary'
                          size='regular'
                          className='u-pad-lr_4'
                        >
                          Search by Standard set name such as &quot;Common Core Math&quot; or
                          &quot;ACT WorkKey Skills&quot;
                        </FindAndApply.Message>
                      );
                    }
                    // don't show "pinned suggestions" if user has input anything into the search bar
                    if (searchString.length) {
                      return (
                        <ListGroup as='ul' border='none' className='u-pad-t_0 u-mar-t_0'>
                          {searchResults.map((result) => (
                            <ListGroupItem
                              key={result.getId()}
                              paddingLevel={1}
                              border={false}
                              as='li'
                              className='u-display_flex u-gap_space-x1 u-align-items_center'
                              onClick={() => handleChange(result)}
                            >
                              <Checkbox
                                aria-hidden={false}
                                checked={selectedItems.has(result)}
                                size='s'
                                className='u-mar-l_1'
                                onClick={(e) => {
                                  handleChange(result);
                                  e.stopPropagation();
                                }}
                              />
                              <Label htmlFor={result.getId()} className='u-cursor_pointer'>
                                <Text size='m'>{result.getTitle()}</Text>
                              </Label>
                            </ListGroupItem>
                          ))}
                        </ListGroup>
                      );
                    }
                    const allPersonalizedPreferencesSelected = searchResults
                      .filter((searchResult) =>
                        searchResult.getMeta().isIncludedInPersonalizationSettings()
                      )
                      .every((result) => selectedItems.has(result));
                    const shouldShowAutoApplyMessage =
                      autoPersonalization && allPersonalizedPreferencesSelected && !searchString;
                    const shouldShowSuggestionsMessage =
                      (!allPersonalizedPreferencesSelected || !!searchString) &&
                      searchResults.filter((result) => !selectedItems.has(result)).size > 0;
                    return (
                      <ListGroup as='ul' border='none' className='u-pad-t_0 u-mar-t_0'>
                        {shouldShowAutoApplyMessage && (
                          <li className='u-color_slate-700 u-pad-tb_1 u-pad-lr_2 u-mar-tb_1 u-border-b u-border-t u-border-color_slate-300'>
                            <Text
                              size='s'
                              color='secondary'
                              className='u-display_flex u-gap_space-x1 u-align-items_center u-justify-content_space-between'
                            >
                              Your content preferences were auto-applied for this view.
                              <WithTooltip
                                anchoredFixedPositionProps={{usePortal: false}}
                                theme='light'
                                content={
                                  <>
                                    <Text size='regular'>
                                      You can edit your content preferences on your account page.
                                      <Anchor
                                        to='/account/settings'
                                        className='u-color_blue-500 u-mar-l_1'
                                        data-testid='reports-standards__personalization-info-link'
                                      >
                                        Edit Preferences
                                      </Anchor>
                                    </Text>
                                  </>
                                }
                              >
                                <Icon
                                  icon={['far', 'info-circle']}
                                  className='u-cursor-pointer'
                                  data-testid='reports-standards__personalization-info'
                                />
                              </WithTooltip>
                            </Text>
                          </li>
                        )}
                        {selectedItems.map((selectedItem) => (
                          <ListGroupItem
                            key={selectedItem.getId()}
                            paddingLevel={1}
                            border={false}
                            as='li'
                            className='u-display_flex u-gap_space-x1 u-align-items_center'
                            onClick={() => handleChange(selectedItem)}
                          >
                            <Checkbox
                              aria-hidden={false}
                              checked
                              size='s'
                              className='u-mar-l_1'
                              onClick={() => handleChange(selectedItem)}
                            />
                            <Label htmlFor={selectedItem.getId()} className='u-cursor_pointer'>
                              <Text size='m'>{selectedItem.getTitle()}</Text>
                            </Label>
                          </ListGroupItem>
                        ))}
                        <ListGroupItem paddingLevel={0} />
                        {shouldShowSuggestionsMessage && (
                          <li className='u-display_flex u-gap_space-x1 u-align-items_flex-start u-mar-b_1 u-pad-tb_1 u-pad-lr_2 u-border-b u-border-color_slate-300'>
                            <Text size='s' color='secondary'>
                              Suggestions
                            </Text>
                          </li>
                        )}
                        {searchResults
                          .filter((result) => !selectedItems.has(result))
                          // this should be handled by the query but mandark can't sort booleans
                          .sort((item) =>
                            item.getMeta().isIncludedInPersonalizationSettings() ? -1 : 1
                          )
                          .map((result) => (
                            <ListGroupItem
                              key={result.getId()}
                              paddingLevel={1}
                              border={false}
                              as='li'
                              className='u-display_flex u-gap_space-x1 u-align-items_center'
                              onClick={() => handleChange(result)}
                            >
                              <Checkbox
                                aria-hidden={false}
                                className='u-mar-l_1'
                                size='s'
                                onClick={() => handleChange(result)}
                              />
                              <Label htmlFor={result.getId()} className='u-cursor_pointer'>
                                <Text size='m'>{result.getTitle()}</Text>
                              </Label>
                            </ListGroupItem>
                          ))}
                      </ListGroup>
                    );
                  }}
                </FindAndApply.Body>
                <FindAndApply.Footer paddingLevel={1}>
                  {({ApplyButton, ClearButton, onDismiss}) => (
                    <ButtonGroup align='right'>
                      <Button variant='text' color='secondary' size='s' onClick={onDismiss}>
                        Cancel
                      </Button>
                      <ClearButton variant='text' size='s'>
                        Clear
                      </ClearButton>
                      <ApplyButton variant='text' size='s'>
                        Apply
                      </ApplyButton>
                    </ButtonGroup>
                  )}
                </FindAndApply.Footer>
              </FindAndApply>
            </Popover>
          )}
        </>
      )}
    </WithToggle>
  );
}

export default StandardSetFilter;
