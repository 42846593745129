import React from 'react';
import {Checkbox, Text} from '@albert-io/atomic';
import {CheckboxProps} from '@albert-io/atomic/atoms/Checkbox/Checkbox.react';
import './questions-list-header.scss';

interface Props {
  checkboxProps?: CheckboxProps;
  heading: string;
  selectedQuestionCount: number;
}

const QuestionsListHeader = ({checkboxProps, heading, selectedQuestionCount = 0}: Props) => {
  const hasSelectedQuestions = selectedQuestionCount > 0;
  return (
    <div className='questions-list-header'>
      {checkboxProps && (
        <Checkbox {...checkboxProps} className='questions-list-header__checkbox u-mar-r_2' />
      )}
      {heading && (
        <Text bold className='questions-list-header__heading'>
          {hasSelectedQuestions ? `${selectedQuestionCount} selected` : heading}
        </Text>
      )}
    </div>
  );
};

export default QuestionsListHeader;
