import React from 'react';
import PropTypes from 'prop-types';
import {Map} from 'immutable';
import classnames from 'classnames';
import {callAction} from 'client/framework';
import sessionStore from 'client/Session/SessionStore';
import {pluralize} from 'lib/stringUtils';
import LoadingIndicator from 'generic/LoadingIndicator.react';
import TextInput from 'generic/Forms/TextInput/TextInput';
import {addToast} from '@albert-io/atomic';

import templateListActions from './TemplateList.actions';
import templateListStore from './TemplateList.store';
import './template-list.scss';

export default class TemplateList extends React.Component {
  componentDidUpdate() {
    if (templateListStore.showToast) {
      addToast({
        color: 'positive',
        title: 'Success!',
        message: templateListStore.successToastMessage
      });
      callAction(templateListActions.SET_SHOW_TOAST, false);
    }
  }

  componentWillUnmount() {
    callAction(templateListActions.RESET_STORE);
  }

  setInputState = (e) => {
    const inputState = Map({
      name: e.target.name,
      value: e.target.value,
      isValid: true
    });

    callAction(templateListActions.SET_INPUT_STATE, inputState);
  };

  validateInput = (e) => {
    const inputState = Map({
      name: e.target.name
    });

    callAction(templateListActions.VALIDATE_INPUT, inputState);
  };

  createTemplate = (e) => {
    e.preventDefault();
    callAction(templateListActions.CREATE_TEMPLATE);
  };

  render() {
    if (!sessionStore.isTeacher()) {
      return null;
    }

    const {hasMadeSelection} = templateListStore;
    const {isCreateTemplateForm} = templateListStore;
    const {isSavePending} = templateListStore;
    const selectedQuestionsCount = templateListStore.getTotalQuestionCount();
    const selectedTemplatesCount = templateListStore.getSelectedTemplates().size;
    const errorMessage = templateListStore.isFormError ? (
      <div className='a-form-row'>
        <div className='a-form-error-container'>
          <span className='a-form-error-title'>Error:</span>
          {templateListStore.formErrorMessage}
        </div>
      </div>
    ) : null;
    const createTemplateForm = isCreateTemplateForm ? (
      <div className='template-list-form-wrapper'>
        <form className='a-form'>
          {errorMessage}
          <div className='a-form-row'>
            <TextInput
              name='newTemplateName'
              label='Folder Name'
              type='text'
              required
              maxLength={128}
              value={templateListStore.getInputValue('newTemplateName')}
              error={!templateListStore.isInputValid('newTemplateName')}
              errorMessage='Please enter a folder name.'
              onFocusValidation
              onChange={this.setInputState}
              onBlur={this.validateInput}
            />
          </div>
          <div className='a-form-row'>
            <button className='a-form-submit-button' type='submit' onClick={this.createTemplate}>
              Create folder
            </button>
          </div>
        </form>
      </div>
    ) : null;

    const learnMoreLink = (
      <a
        rel='noopener noreferrer'
        target='_blank'
        href='https://help.albert.io/teachers/creating-assignments-or-practice-exams/what-are-folders'
      >
        Learn more.
      </a>
    );

    const templatesContent = templateListStore
      .getAssignmentTemplatesQuery()
      .isResourcePopulated() ? (
      <div className='template-list-templates-wrapper'>
        {templateListStore
          .getAssignmentTemplatesQuery()
          .getResource()
          .map((template) => {
            const isTemplateSelected = templateListStore.isTemplateSelected(template.getId());
            return (
              <TemplateListItem
                id={template.getId()}
                key={template.getId()}
                name={template.getName()}
                isTemplateSelected={isTemplateSelected}
                questionCount={template.getCountOfQuestions()}
              />
            );
          })}
      </div>
    ) : (
      <LoadingIndicator />
    );

    return (
      <div className='template-list-wrapper'>
        <div className='template-list-header__learn-more'>
          You can save questions to folders from multiple topics across multiple subjects. You can
          rearrange, edit, and assign questions in folders. {learnMoreLink}
        </div>
        {/** @todo */}
        {/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/interactive-supports-focus */}
        <div
          className='template-list-actions__create-assignment-button'
          role='button'
          onClick={() => {
            callAction(templateListActions.SET_IS_CREATE_TEMPLATE_FORM, !isCreateTemplateForm);
          }}
        >
          {isCreateTemplateForm ? '- Hide Create Folder Form' : '+ Create New Folder'}
        </div>
        {createTemplateForm}
        {templatesContent}
        <div className='template-list-actions'>
          {/** @todo */}
          {/* eslint-disable react/button-has-type  */}
          <button
            className='template-list-actions__button'
            disabled={isSavePending || !hasMadeSelection}
            onClick={() => callAction(templateListActions.SAVE_SET_LIST_TO_TEMPLATE)}
          >
            {!hasMadeSelection
              ? `Select folder for ${selectedQuestionsCount} ${pluralize(
                  'question',
                  selectedQuestionsCount
                )}`
              : `Add ${selectedQuestionsCount} ${pluralize(
                  'question',
                  selectedQuestionsCount
                )} to ${pluralize('folder', selectedTemplatesCount)}`}
          </button>
        </div>
      </div>
    );
  }
}

class TemplateListItem extends React.Component {
  static propTypes = {
    id: PropTypes.string, // ID of the template
    name: PropTypes.string, // Name of the template
    questionCount: PropTypes.number, // Number of questions in the template
    isTemplateSelected: PropTypes.bool // Is this template currently selected
  };

  selectTemplate(templateId) {
    callAction(templateListActions.SELECT_TEMPLATE, templateId);
  }

  selectTemplateOnEnter(e, templateId) {
    if (e.which === 13) {
      this.selectTemplate(templateId);
    }
  }

  render() {
    const {id: templateId, name: templateName, questionCount, isTemplateSelected} = this.props;
    const selectedQuestionsCount = templateListStore.getTotalQuestionCount();

    /**
     * TODO: make this based on union count to avoid double counting.
     * Need to change save->remove from list logic too.
     */
    const questionAdditionValid = questionCount + selectedQuestionsCount <= 200;

    const templateListItemClassName = classnames('template-list-templates__item', {
      'template-list-templates__item--selected': isTemplateSelected,
      'template-list-templates__item--invalid': !questionAdditionValid
    });
    const spanBoxClassName = classnames('checkbox-checkmark', {
      'checkbox-checkmark--selected': isTemplateSelected
    });

    const checkMarkSelection = (
      // linting rule level 2 states you need text content inside the label, which this doesn't have
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      <label className='template-list-templates__item-checkbox-wrapper'>
        <input
          type='checkbox'
          checked={isTemplateSelected}
          onChange={() => (questionAdditionValid ? this.selectTemplate(templateId) : null)}
          tabIndex='0'
        />
        <span className={spanBoxClassName} />
      </label>
    );
    const warningSelection = (
      <div className='template-list-templates__item-invalid-wrapper'>
        <div className='template-list-templates__item-input-invalid fa fa-exclamation-triangle'>
          <span className='template-list-templates__item-input-invalid-hover-text'>
            Folder can contain a maximum of 200 questions
          </span>
        </div>
      </div>
    );

    return (
      /** @todo */
      /* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/no-noninteractive-tabindex  */
      <div
        className={templateListItemClassName}
        onClick={() => (questionAdditionValid ? this.selectTemplate(templateId) : null)}
        onKeyPress={(e) => {
          this.selectTemplateOnEnter(e, templateId);
        }}
        tabIndex='0'
      >
        <div className='template-list-templates__item-title'>{templateName}</div>
        <div className='template-list-templates__item-content'>
          {questionCount} Question{questionCount !== 1 ? 's' : ''}
        </div>
        {questionAdditionValid ? checkMarkSelection : warningSelection}
      </div>
    );
  }
}
