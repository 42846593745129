import {Map} from 'immutable';

import FillInTheBlankEditor from './FillInTheBlankEditor';
import FillInTheBlankQuestionV2 from './V2/FillInTheBlankQuestion.react';
import FillInTheBlankExplanationArea from './V2/FillInTheBlankExplanation.react';
import FillInTheBlankResponseStats from './V2/FillInTheBlankResponseStats.react';

export const FillInTheBlankQuestionType = Map({
  type: 'fill-in-the-blank',
  label: 'Fill In the Blank',
  questionRenderer: FillInTheBlankQuestionV2,
  explanationRenderer: FillInTheBlankExplanationArea,
  responseStatsRenderer: FillInTheBlankResponseStats,
  editor: FillInTheBlankEditor,
  isHidden: false
});
