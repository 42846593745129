import React from 'react';
import {Helmet} from 'react-helmet';
import {withRouter} from 'react-router';

import {title} from 'lib/head';

import CenteredPage from 'client/Layout/CenteredPage';

import SignUpForm from './SignUpForm/SignUpForm.react';

const Form = withRouter(SignUpForm);

const SignUpPage = () => (
  <>
    <Helmet>{title('Sign Up | Albert')}</Helmet>
    <CenteredPage>
      <Form />
    </CenteredPage>
  </>
);

export default SignUpPage;
