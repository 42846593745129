import {Button, Icon, MenuListItem, Popover, Tab, Text, WithToggle} from '@albert-io/atomic';
import React, {useContext, useRef} from 'react';
import {history} from 'client/history';

import {ReportsContext} from '../Reports.context';
import {IS_REPORTS_BLOCKED_FOR_NON_PRO_ADMINS, DIMENSION_CONFIG} from '../reports.utils';
import './drilldown-selector.scss';
import {ReportTypes} from '../reports.types';

interface DrilldownSelectorProps {
  isCollapsed?: boolean;
  hasProUpgrade: boolean;
}

export const getDimensionOptions = (
  hasProUpgrade: boolean,
  topLevelFilterType,
  reportSelected: ReportTypes,
  drilldownIds
) => {
  const isReportLocked = (dimension: ReportTypes) => {
    if (hasProUpgrade) return false;
    return IS_REPORTS_BLOCKED_FOR_NON_PRO_ADMINS(topLevelFilterType, dimension, drilldownIds);
  };

  const isReportPro = (dimension: ReportTypes) => {
    return IS_REPORTS_BLOCKED_FOR_NON_PRO_ADMINS(topLevelFilterType, dimension, drilldownIds);
  };

  const isTopLevelReport = Object.values(drilldownIds).every((element) => element === ''); // if every drilldownId is empty, we are at the top level of a report
  const dimensionsInReport = new Set([topLevelFilterType]);
  Object.entries(drilldownIds).forEach(([key, value]) => {
    if (value) {
      switch (key) {
        case 'assignmentId':
          dimensionsInReport.add('assignments');
          break;
        case 'questionId':
          dimensionsInReport.add('questions');
          break;
        case 'standardId':
          dimensionsInReport.add('standards');
          break;
        case 'studentId':
          dimensionsInReport.add('students');
          break;
        case 'subjectId':
          dimensionsInReport.add('subjects');
          break;
        case 'schoolId':
          dimensionsInReport.add('schools');
          break;
        case 'classroomId':
          dimensionsInReport.add('classrooms');
          break;
        case 'teacherId':
          dimensionsInReport.add('teachers');
          break;
        default:
      }
    }
  });
  const reports = [
    ...(isTopLevelReport && topLevelFilterType === 'classrooms' ? ['gradebook'] : []),
    'students',
    'assignments',
    'questions',
    'standards',
    'subjects',
    'schools',
    'classrooms',
    'teachers'
  ] as ReportTypes[];

  const shownReports = reports.filter((dimension) => {
    const dimensionItem = DIMENSION_CONFIG[dimension];
    const isPreviouslyDrilledDownDimension = dimensionsInReport.has(dimension);
    const isCurrentlySelectedDimension = reportSelected === dimension;
    const isHidden =
      (isPreviouslyDrilledDownDimension && !isCurrentlySelectedDimension) || // we don't want to show a report that is already in the path
      Array.from(dimensionsInReport).some((drilldown) => {
        return (
          drilldown !== reportSelected &&
          dimensionItem.reportInvalidWhenAnyOfTheseDimensionsInPath.includes(drilldown)
        );
      });
    return !isHidden;
  });

  return [
    ...shownReports.map((dimension) => {
      return {
        isLocked: isReportLocked(dimension),
        isPro: isReportPro(dimension),
        ...DIMENSION_CONFIG[dimension]
      };
    })
  ].sort((dimensionOptionA, dimensionOptionB) => {
    return dimensionOptionA.sortOrder - dimensionOptionB.sortOrder;
  });
};

function DrilldownSelector({isCollapsed, hasProUpgrade}: DrilldownSelectorProps) {
  const {
    path,
    topLevelFilterType,
    assignmentId,
    questionId,
    standardId,
    studentId,
    subjectId,
    schoolId,
    classroomId,
    teacherId,
    reportSelected
  } = useContext(ReportsContext);
  const filteredPath = [...path].filter((item) => item !== '');
  const currentDimension = filteredPath[filteredPath.length - 1];
  const buttonRef = useRef(null);

  const dimensionOptions = getDimensionOptions(hasProUpgrade, topLevelFilterType, reportSelected, {
    assignmentId,
    questionId,
    standardId,
    studentId,
    subjectId,
    schoolId,
    classroomId,
    teacherId
  });

  const selectedDimensionItem = DIMENSION_CONFIG[currentDimension];

  const traverseToDimension = (nextDimension, shouldPopLast) => {
    const nextPath = [...filteredPath];
    if (shouldPopLast) {
      nextPath.pop();
    }
    nextPath.push(nextDimension);
    history.pushState(null, `/${nextPath.join('/')}`);
  };

  if (isCollapsed) {
    return (
      <WithToggle dismissOnEscape className='u-display_flex u-align-items_center u-mar-r_2'>
        {({onClick, on}) => {
          return (
            <>
              <Button
                className='u-gap_space-x1 u-mar-r_space-x2'
                color='primary'
                onClick={onClick}
                ref={buttonRef}
                size='s'
                data-testid='drilldown-selector-popover-button'
              >
                {selectedDimensionItem.Icon}
                <span>{selectedDimensionItem.label}</span>
                <Icon icon='caret-down' />
              </Button>
              {on && (
                <Popover
                  usePortal={false}
                  border='none'
                  expanded={on}
                  position='bottom-start'
                  paddingLevel={null}
                  modifiers={{
                    flip: {enabled: false},
                    preventOverflow: {
                      boundariesElement: 'viewport'
                    }
                  }}
                  data-testid='drilldown-selector-popover'
                  targetRef={buttonRef}
                  className='u-pad_1'
                >
                  {dimensionOptions.map(({dimension, isLocked, label}) => {
                    const isSelected = reportSelected === dimension;
                    return (
                      <MenuListItem
                        selected={isSelected}
                        className='u-display_flex u-justify-content_space-between u-align-items_center'
                        onMenuItemClick={() => {
                          onClick();
                          traverseToDimension(dimension, true);
                        }}
                        data-testid='drilldown-selector-popover-item'
                      >
                        <Text bold>{label}</Text>

                        {isLocked && (
                          <Icon
                            icon='lock'
                            className='u-mar-l_2'
                            emphasis='lowest'
                            iconStyle='regular'
                            color='primary'
                            data-testid='lock-icon'
                          />
                        )}
                        {isSelected && !isLocked && (
                          <Icon
                            className='u-mar-l_2'
                            icon='check'
                            iconStyle='regular'
                            emphasis='lowest'
                            color='primary'
                            data-testid='check-icon'
                          />
                        )}
                      </MenuListItem>
                    );
                  })}
                </Popover>
              )}
            </>
          );
        }}
      </WithToggle>
    );
  }

  return (
    <div className='drilldown-selector u-display_flex u-flex-direction_row u-gap_space-x1 u-align-items_center u-border-b u-border-color_slate-200 u-overflow-x_auto'>
      <Text className='u-mar-r_1'>Report:</Text>
      <div className='u-display_flex u-flex-direction_row u-align-items_center'>
        {dimensionOptions.map(({dimension, isLocked, isPro, label}) => {
          return (
            <>
              <Tab
                key={dimension}
                onClick={() => {
                  if (dimension === reportSelected) {
                    return;
                  }

                  traverseToDimension(dimension, true);
                }}
                className='drilldown-selector__tab'
                as='button'
                isActive={dimension === reportSelected}
              >
                <div className='drilldown-selector__tab-content'>
                  <span>{label}</span>
                  {isPro && (
                    <Icon
                      icon={isLocked ? 'lock' : 'pro'}
                      iconStyle='solid'
                      data-testid={isLocked ? 'lock-icon' : undefined}
                    />
                  )}
                </div>
              </Tab>
            </>
          );
        })}
      </div>
    </div>
  );
}

export default DrilldownSelector;
