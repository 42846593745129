import {resource} from '@albert-io/json-api-framework/request/builder';
import masqueradeStore from 'client/generic/Masquerade/Masquerade.store';
import {genericMandarkRequest} from 'resources/mandark.resource';

export const getGlobalAssignmentsQuery = ({
  name = '',
  sortOption = 'updated_at',
  page = 1,
  status = '',
  classes = [],
  shared = ''
}) =>
  resource('assignments_v3')
    .mandarkEndpoint(['assignments_v3'])
    .include('classrooms_v1')
    .include('classrooms_v1.teachers_v1')
    .include('teacher_v1')
    .withMeta('assignment_v3')
    .meta({
      context: {
        assignment: {
          stats: true
        },
        default: {
          settings: true
        }
      }
    })
    .filter({
      classrooms_v1: {
        teachers_v1: {
          id: masqueradeStore.getUserIdByMasqueradeState()
        }
      }
    })
    .filter(
      {
        teacher_v1: {
          id: {
            not: masqueradeStore.getUserIdByMasqueradeState()
          }
        }
      },
      shared === 'Shared with You'
    )
    .filter(
      {
        teacher_v1: {
          id: masqueradeStore.getUserIdByMasqueradeState()
        }
      },
      shared === 'Owned by You'
    )
    .filter(
      {
        name: {case_insensitive_substring: name}
      },
      !!name
    )
    .page(page)
    .filter(
      {
        all_of: [
          {
            any_of: [
              {
                'meta.default_settings_start_date': {
                  less_than: 'now'
                }
              },
              {
                'meta.default_settings_start_date': {
                  null: true
                }
              }
            ]
          },
          {
            any_of: [
              {
                'meta.default_settings_due_date': {
                  greater_than: 'now'
                }
              },
              {
                'meta.default_settings_allow_late_submissions': true
              }
            ]
          },
          {
            'meta.count_of_students': {
              not: 0
            }
          },
          {
            'meta.count_of_incomplete_students': {
              not: 0
            }
          }
        ]
      },
      status === 'open'
    )
    .filter(
      {
        'meta.default_settings_start_date': {
          greater_than: 'now',
          null: false
        }
      },
      status === 'scheduled'
    )
    .filter(
      {
        any_of: [
          {
            'meta.count_of_incomplete_students': 0
          },
          {
            'meta.default_settings_allow_late_submissions': false,
            'meta.default_settings_due_date': {
              less_than: 'now'
            }
          }
        ]
      },
      status === 'closed'
    )
    .filter(
      {
        classrooms_v1: {
          id: classes.join(',')
        }
      },
      classes.length > 0
    )
    .sort(sortOption);

export const getClassesQuery = ({includeArchived = false, page = 1, isTeacher = false}) =>
  resource('classrooms_v1')
    .mandarkEndpoint(['teachers_v1', masqueradeStore.getUserIdByMasqueradeState(), 'classrooms_v1'])
    .include('teachers_v1')
    .withMeta('classroom_v1')
    .filter(
      {
        any_of: [
          {
            status: 'active'
          },
          {
            status: 'archived'
          }
        ]
      },
      includeArchived
    )
    .filter(
      {
        status: 'active'
      },
      !includeArchived
    )
    .filter(
      {
        teachers_v1: {
          school_personnel_v1: {
            status: 'approved'
          }
        }
      },
      !isTeacher
    )
    .page(page)
    .sort('name');

export const handleDeleteAssignment = async (assignmentId) => {
  const deleteQuery = {
    resourcePath: ['assignments_v3', assignmentId]
  };
  await genericMandarkRequest('delete', deleteQuery);
};
