/**
 * # CheckboxChip
 *
 * A checkbox chip component that combines a checkbox input with a chip.
 *
 * ## Props:
 *
 * - checked?: boolean - Specifies whether the checkbox is checked.
 * - children: React.ReactNode - The content of the checkbox chip.
 * - className?: string - Additional CSS class for the checkbox chip.
 * - color?: ChipColor - The color variant of the checkbox chip.
 * - defaultChecked?: boolean - Specifies whether the checkbox is checked by default.
 * - disabled?: boolean - Specifies whether the checkbox is disabled.
 * - icon: IconProp - The icon to display in the checkbox chip.
 * - size?: 'small' | 'default' - The size variant of the checkbox chip.
 * - onChange?: React.ChangeEventHandler<HTMLInputElement> - The event handler for the checkbox change event.
 * - onBlur?: () => void - The event handler for the checkbox blur event.
 * - onFocus?: () => void - The event handler for the checkbox focus event.
 * - onMouseEnter?: () => void - The event handler for the checkbox mouse enter event.
 * - onMouseLeave?: () => void - The event handler for the checkbox mouse leave event.
 * - role?: React.AriaRole - The ARIA role of the checkbox chip.
 *
 * Usage:
 *
 * ```tsx
 * import CheckboxChip from './CheckboxChip.react';
 *
 * const Example = () => {
 *   return (
 *     <CheckboxChip
 *       checked={true}
 *       color="brand"
 *       icon="check"
 *       onChange={(e) => console.log(e.target.checked)}
 *     >
 *       Example Checkbox Chip
 *     </CheckboxChip>
 *   );
 * };
 * ```
 */
import React from 'react';
import classnames from 'classnames';

import Label from '../../atoms/Label/Label.react';
import Chip, {ChipColor} from '../../atoms/Chip/Chip.react';
import Icon, {IconProp} from '../../atoms/Icon/Icon.react';
import WithHoverAndFocus from '../../helpers/WithHoverAndFocus/WithHoverAndFocus.react';
import './checkbox-chip.scss';

interface Props extends PropsWithChildrenRequired, PropsWithClassNameOptional {
  checked?: boolean;
  color?: ChipColor;
  defaultChecked?: boolean;
  disabled?: boolean;
  icon: IconProp;
  size?: 'small' | 'default';
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: () => void;
  onFocus?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  role?: React.AriaRole;
}

const CheckboxChip = ({
  checked,
  children,
  className,
  color,
  defaultChecked,
  disabled,
  icon,
  size,
  onChange,
  onBlur,
  onFocus,
  onMouseEnter,
  onMouseLeave,
  role,
  ...rest
}: Props) => {
  const error = color === 'negative';
  return (
    <WithHoverAndFocus
      customOnBlur={onBlur}
      customOnFocus={onFocus}
      customOnMouseEnter={onMouseEnter}
      customOnMouseLeave={onMouseLeave}
    >
      {({hasFocus, hasHover, ...withHoverAndFocusHandlers}) => (
        <Chip
          as={Label}
          checked={!error && checked}
          className={classnames('m-checkbox-chip', className)}
          disabled={disabled}
          color={color}
          hasHover={!disabled && hasHover}
          hasFocus={hasFocus}
          role={role}
          size={size}
          {...withHoverAndFocusHandlers}
        >
          <input
            checked={checked}
            className='m-checkbox-chip__input'
            {...rest}
            defaultChecked={defaultChecked}
            disabled={disabled}
            onChange={onChange}
            type='checkbox'
          />
          {children}
          <Icon
            className='m-checkbox-chip__icon fa-fw'
            icon={
              (error && hasHover && 'times') ||
              (error && 'exclamation') ||
              (defaultChecked && 'times') ||
              (checked && 'times') ||
              icon
            }
          />
        </Chip>
      )}
    </WithHoverAndFocus>
  );
};

export default CheckboxChip;
