// @flow
import {fromJS, List, Map} from 'immutable';
import {Store} from 'client/framework';
import formActions from 'client/generic/Forms/ErrorState/Form.actions';

function makeFieldMatch(field: string): (error: Map<string, string>) => boolean {
  return (error: Map<string, string>) => {
    return error.get('field') === field;
  };
}

export default class ErrorStateStore extends Store {
  constructor(name: string) {
    super(name);
    this.setInitialData(
      fromJS({
        errorMessages: null
      })
    );

    this.writeData('errorMessages', List());
    this.handleTargeted(formActions.SET_FORM_ERRORS, this._setFormErrors);
    this.handleTargeted(formActions.CLEAR_FORM_ERRORS, this._clearFormErrors);
    this.handleTargeted(formActions.CLEAR_FIELD_ERROR, this._clearFieldError);
  }

  _setFormErrors(errorMessages: List<Map<string, string>>) {
    this.writeData('errorMessages', errorMessages);
  }

  _clearFormErrors() {
    this.writeData('errorMessages', List());
  }

  _clearFieldError(field: string) {
    if (this.getFormErrorForField(field)) {
      const match = makeFieldMatch(field);
      const clearedErrorList = this.getFormErrors().filterNot((error) => {
        return match(error);
      });

      this.writeData('errorMessages', clearedErrorList);
    }
  }

  getFormErrors(): List<Map<string, string>> {
    return this.readData('errorMessages');
  }

  getFormErrorForField(field: string): ?Map<string, string> {
    const match = makeFieldMatch(field);
    return this.getFormErrors().find(match);
  }
}
