import {colors, flavorDescriptions} from '../constants';

export function buildGeometryInstance({
  board,
  entity,
  geometryElement,
  constituentElements,
  onClick,
  editOptions,
  correctnessIcon,
  // If any clean up has to be done when a geometryInstance is removed,
  // you can handle that here.
  onRemove = () => {}
}) {
  const instance = {
    entity,
    geometryElement,
    editOptions,
    onClick,
    setColor(color) {
      constituentElements.forEach((element) => {
        /**
         * While most every geometryElement only needs its stroke color changed,
         * points need their fill color changed.
         */
        const isPoint = element.elType === 'point';

        element.setAttribute({
          strokeColor: color,
          highlightStrokeColor: color,
          fillColor: isPoint ? color : null,
          highlightFillColor: isPoint ? color : null
        });
      });
    },
    remove() {
      board.removeAncestors(geometryElement);
      if (correctnessIcon) {
        correctnessIcon.remove();
      }
      onRemove();
    }
  };

  // Since JSXGraph doesn't seem to provide a click/tap handler, we simulate one
  // by hooking into the down and up events via this helper class
  instance.__clickHandler = new ClickHandler(instance, constituentElements, onClick);

  const {color} = flavorDescriptions[entity.flavor];

  instance.setColor(color);

  instance.highlight = () => {
    instance.setColor(colors.highlighted);
  };

  instance.unhighlight = () => {
    instance.setColor(color);
  };

  return instance;
}

class ClickHandler {
  static timeout = 200;

  constructor(instance, constituentElements, onClick) {
    this.instance = instance;
    this.onClick = onClick;
    this.intervalId = null;
    constituentElements.forEach((element) => {
      element.on('down', this.handleDown);
      element.on('up', this.handleUp);
    });
  }

  resetPending = () => {
    clearTimeout(this.intervalId);
    this.intervalId = null;
  };

  handleDown = () => {
    this.intervalId = setTimeout(this.resetPending, ClickHandler.timeout);
  };

  handleUp = () => {
    if (this.intervalId) {
      this.onClick(this.instance);
      this.resetPending();
    }
  };
}

/**
 * Converts an entity's elements to an array of coodinate pairs.
 *
 * @param {object} elements An entity's elements in the form of an objects
 *   with arrays or objects as their values. Leaf nodes must be a coordinate pair array,
 *   or an array of coordinate pair arrays
 * @param {Array.<Array.<number>>} _temp Temporary arg to hold the flattened value. Do not
 *   provide.
 * @returns {Array.<number>} Coordinate pairs
 */
export function toCoordsList(elements, _temp = []) {
  const values = Object.values(elements);

  values.forEach((val) => {
    if (!val) {
      return;
    }

    if (!Array.isArray(val)) {
      // eslint-disable-next-line consistent-return
      return toCoordsList(val, _temp);
    }

    if (typeof val[0] === 'number') {
      _temp.push(val);
      return;
    }

    _temp = _temp.concat(val);
  });

  return _temp;
}

export function isSameCoordinatePair(a, b) {
  return a[0] === b[0] && a[1] === b[1];
}

/**
 * Given an entity's elements object, returns whether or not the elements
 * contain the coordinate pair
 *
 * @param {object} elements Entity's elements
 * @param {Array.<number>} coords Coordinate pair
 * @returns {boolean} Whether or not the elements contain the coordinate pair
 */
export function hasCoords(elements, coords) {
  const entityCoords = toCoordsList(elements);

  return entityCoords.some((entityCoord) => isSameCoordinatePair(entityCoord, coords));
}
