import React, {useContext, useEffect, useState} from 'react';
import {Button, Icon} from '@albert-io/atomic';

import sessionStore from 'client/Session/SessionStore';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {CLASSROOM_STATUSES} from 'client/constants';
import {history} from 'client/history';
import classNames from 'classnames';

import {ReportsContext} from '../Reports.context';

import {getResourceFromDimension} from '../reports.utils';

const traverseToBreadcrumb = (to) => {
  history.pushState(null, to);
};

interface CrumbProps extends PropsWithChildrenRequired {
  to: string;
  isLast?: boolean;
}

interface DerivativeCrumbProps extends OmitChildren<CrumbProps> {
  id: string;
}

export function Crumb({to, isLast, children}: CrumbProps) {
  return (
    <>
      <Button
        onClick={() => traverseToBreadcrumb(to)}
        variant='text'
        size='s'
        color='secondary'
        className={classNames('u-font-weight_normal', {
          'u-color_slate-600': isLast,
          'u-color_blue-500': !isLast
        })}
      >
        {children}
      </Button>
      {!isLast && <Icon icon='chevron-right' iconStyle='solid' />}
    </>
  );
}

export function AssignmentCrumb({id, to, isLast}: DerivativeCrumbProps) {
  const [assignmentName, setAssignmentName] = useState('');

  useEffect(() => {
    (async () => {
      const fetchedAssignment = await getResourceFromDimension('assignments', id);
      setAssignmentName(fetchedAssignment.getName());
    })();
  }, [id, setAssignmentName]);

  return (
    <Crumb to={to} isLast={isLast}>
      {assignmentName}
    </Crumb>
  );
}

export function QuestionCrumb({id, to, isLast}: DerivativeCrumbProps) {
  const [questionTitle, setQuestionTitle] = useState('');

  useEffect(() => {
    (async () => {
      const fetchedQuestion = await getResourceFromDimension('questions', id);

      setQuestionTitle(fetchedQuestion.getTitle());
    })();
  }, [id, setQuestionTitle]);

  return (
    <Crumb to={to} isLast={isLast}>
      <MarkdownRendererV2 text={questionTitle} />
    </Crumb>
  );
}

export function StandardCrumb({id, to, isLast}: DerivativeCrumbProps) {
  const [standardTitle, setStandardTitle] = useState('');

  useEffect(() => {
    (async () => {
      const fetchedStandard = await getResourceFromDimension('standards', id);

      setStandardTitle(fetchedStandard.getTitle());
    })();
  }, [id, setStandardTitle]);

  return (
    <Crumb to={to} isLast={isLast}>
      {standardTitle}
    </Crumb>
  );
}

export function StudentCrumb({id, to, isLast}: DerivativeCrumbProps) {
  const {getStudentFullName} = useContext(ReportsContext);

  const [student, setStudent] = useState();

  useEffect(() => {
    (async () => {
      const fetchedStudent = await getResourceFromDimension('students', id);
      setStudent(fetchedStudent);
    })();
  }, [id]);

  return (
    <Crumb to={to} isLast={isLast}>
      {getStudentFullName(student)}
    </Crumb>
  );
}

export function ClassroomCrumb({id, to, isLast}: DerivativeCrumbProps) {
  const [classroom, setClassroom] = useState('');

  useEffect(() => {
    (async () => {
      const fetchedClassroom = await getResourceFromDimension('classrooms', id);
      setClassroom(
        fetchedClassroom.getStatus() === CLASSROOM_STATUSES.ARCHIVED
          ? `${fetchedClassroom.getName()} (Archived)`
          : fetchedClassroom?.getName?.()
      );
    })();
  }, [id, setClassroom]);

  return (
    <Crumb to={to} isLast={isLast}>
      {classroom}
    </Crumb>
  );
}

export function TeacherCrumb({id, to, isLast}: DerivativeCrumbProps) {
  const [teacher, setTeacher] = useState('');

  useEffect(() => {
    (async () => {
      const fetchedTeacher = await getResourceFromDimension('teachers', id);
      setTeacher(`${fetchedTeacher.getDisplayName().trim()}'s Classes`);
    })();
  }, [id, setTeacher]);

  const isOtherTeacher = id !== sessionStore.getUserId();

  return (
    <Crumb to={to} isLast={isLast}>
      {isOtherTeacher ? teacher : 'My Classes'}
    </Crumb>
  );
}

export function SchoolCrumb({id, to, isLast}: DerivativeCrumbProps) {
  const [school, setSchool] = useState('');

  useEffect(() => {
    (async () => {
      const fetchedSchool = await getResourceFromDimension('schools', id);

      setSchool(fetchedSchool.getName());
    })();
  }, [id, setSchool]);

  return (
    <Crumb to={to} isLast={isLast}>
      {school}
    </Crumb>
  );
}

export function SchoolAdminCrumb({id, to, isLast}: DerivativeCrumbProps) {
  const [teacher, setTeacher] = useState('');

  useEffect(() => {
    (async () => {
      const fetchedTeacher = await getResourceFromDimension('teachers', id);
      setTeacher(`${fetchedTeacher.getDisplayName().trim()}'s Schools`);
    })();
  }, [id, setTeacher]);

  const isOtherTeacher = id !== sessionStore.getUserId();

  return (
    <Crumb to={to} isLast={isLast}>
      {isOtherTeacher ? teacher : 'My Schools'}
    </Crumb>
  );
}

export function SubjectCrumb({id, to, isLast}: DerivativeCrumbProps) {
  const [subject, setSubject] = useState('');

  useEffect(() => {
    (async () => {
      const fetchedSubject = await getResourceFromDimension('subjects', id);

      setSubject(fetchedSubject.getName());
    })();
  }, [id, setSubject]);

  return (
    <Crumb to={to} isLast={isLast}>
      {subject}
    </Crumb>
  );
}

export default {
  assignments: AssignmentCrumb,
  questions: QuestionCrumb,
  standards: StandardCrumb,
  students: StudentCrumb,
  classrooms: ClassroomCrumb,
  schools: SchoolCrumb,
  teachers: TeacherCrumb,
  'school-admin': SchoolAdminCrumb,
  subjects: SubjectCrumb
};
