/**
 * # LabeledSelection
 *
 * This component adds a label and subtext to a Toggle or Checkbox (Planning on adding Radio once it is built).
 * The label is limited from extending above the element and will grow downwards once it hits the top.
 *
 * The molecule has an onClick function to make the label clickable to toggle the selectable item
 * and follow A11y principals.
 *
 * @see [Design ticket](https://github.com/albert-io/project-management/issues/2059)
 *
 * ## Props<C extends typeof Checkbox>
 *
 * - `subtextType?: 'success' | 'error'` (extends `SomeOtherType`): The type of subtext to display.
 * - `text?: string`: The label text.
 * - `subText?: string`: The subtext.
 * - `success?: boolean`: Indicates if the component is in a success state.
 * - `error?: boolean`: Indicates if the component is in an error state.
 * - `className?: string`: Additional CSS class names.
 * - `onClick?: HandleChangeType | HandleClickType`: The click event handler.
 * - `...rest: CheckboxProps`: valid props for the `<Checkbox />` Atomic component.
 *
 * ## Usage
 *
 * ```tsx
 * <LabeledSelection
 *   subtextType="success"
 *   text="Label Text"
 *   subText="Subtext"
 *   success={true}
 *   error={false}
 *   className="custom-class"
 *   onClick={handleClick}
 * />
 * ```
 */

import React from 'react';

import classnames from 'classnames';

import Checkbox from '../../atoms/Checkbox/Checkbox.react';
import Text from '../../atoms/Text/Text.react';
import './labeled-selection.scss';

type HandleChangeType = (e: React.ChangeEvent<HTMLInputElement>) => void;
type HandleClickType = (e: React.MouseEvent<HTMLSpanElement>) => void;

interface Props extends PropsWithClassNameOptional {
  subtextType?: 'success' | 'error';
  text?: string;
  subText?: string;
  success?: boolean;
  error?: boolean;
  onClick?: HandleChangeType | HandleClickType;
}

type LabeledSelectionProps<C extends typeof Checkbox> = PolymorphicComponentProps<C, Props>;

const LabeledSelection = <C extends typeof Checkbox>({
  as,
  subText,
  text,
  subtextType,
  className,
  onClick,
  ...rest
}: LabeledSelectionProps<C>) => {
  let subTextColor;
  if (subtextType === 'success') {
    subTextColor = 'positive';
  } else if (subtextType === 'error') {
    subTextColor = 'negative';
  }

  const WrapperElement = as ?? 'label';

  return (
    <WrapperElement className={classnames('m-selection-label', className)} aria-hidden={false}>
      <Checkbox size='m' onChange={onClick as HandleChangeType} {...rest} aria-hidden={false} />
      <div className='m-selection-label__text-block'>
        <Text className='m-selection-label__label' onClick={onClick as HandleClickType}>
          {text}
        </Text>
        {subText && (
          <Text as='div' color={subTextColor} className='u-mar-t_1' size='s'>
            {subText}
          </Text>
        )}
      </div>
    </WrapperElement>
  );
};

LabeledSelection.defaultProps = {
  as: 'label',
  onClick: () => {}
};

export default LabeledSelection;
