import {Map, List} from 'immutable';
import moment, {Moment} from 'moment';

import type {FormData} from '../CreateAssignment.types';

const maxLength = 80;

type ErrorMessages = List<string> | null;

export function validateName(name: string | null): ErrorMessages {
  const errors: string[] = [];
  if (!name) {
    errors.push('This field is required.');
  }
  if (name && name.length > maxLength) {
    errors.push(`This field must be ${maxLength} characters or less.`);
  }
  return errors.length ? List(errors) : null;
}

export function validateStartDate(
  startDate: Moment | string | null,
  formData: Map<keyof FormData, FormData[keyof FormData]>
): ErrorMessages {
  const dueDate = formData.get('due_date') as Moment | string | null;
  const dueDateObject = dueDate && moment(dueDate);
  const startDateObject = startDate && moment(startDate);
  if (!dueDateObject || !startDateObject || startDate === 'now') {
    return null;
  }
  const errors: string[] = [];
  if (!moment(startDateObject).isValid()) {
    errors.push('Please enter a valid date.');
  }
  if (
    !moment(startDateObject).isSame(dueDateObject, 'day') &&
    moment(startDateObject).isAfter(dueDateObject)
  ) {
    errors.push('Start date must fall before due date.');
  }
  return errors.length ? List(errors) : null;
}

export function validateStartTime(
  startDate: Moment | string | null,
  formData: Map<keyof FormData, FormData[keyof FormData]>
): ErrorMessages {
  const dueDate = formData.get('due_date') as Moment | string | null;
  const dueDateObject = dueDate && moment(dueDate);
  const startDateObject = startDate && moment(startDate);
  if (!dueDateObject || !startDateObject || startDate === 'now') {
    return null;
  }
  const errors: string[] = [];
  if (!moment(startDateObject).isValid()) {
    errors.push('Please enter a valid date.');
  }
  const startDateIsIdenticalToDueDate = startDateObject.isSame(dueDateObject);
  const startDateIsSameDayAsDueDate = startDateObject.isSame(dueDateObject, 'day');
  const startDateIsAfterDueDate = startDateObject.isAfter(dueDateObject);
  if (startDateIsIdenticalToDueDate || (startDateIsSameDayAsDueDate && startDateIsAfterDueDate)) {
    errors.push('Start time must fall before deadline');
  }
  return errors.length ? List(errors) : null;
}

export function validateDueDate(date: Moment | string): ErrorMessages {
  const dueDateObject = date && moment(date);
  if (!dueDateObject) {
    return List.of('This field is required.');
  }
  const errors: string[] = [];
  if (!dueDateObject.isValid()) {
    errors.push('Please enter a valid date.');
  }
  if (moment().add(1, 'year').isBefore(dueDateObject)) {
    errors.push('Due date must be within one year from now.');
  }
  return errors.length ? List(errors) : null;
}

export function validateTimeLimit(timeLimit: number | null): ErrorMessages {
  const errors: string[] = [];
  if (!timeLimit) {
    return null;
  }
  /*
      Invalidate values greater than seven days.
    */
  if (timeLimit > 60 * 24 * 7) {
    errors.push('Time limit cannot exceed seven days');
  }
  if (timeLimit === 0) {
    errors.push('Time limit must be greater than 0');
  }
  return errors.length ? List(errors) : null;
}

export const INPUT_VALIDATORS = {
  due_date: validateDueDate,
  name: validateName,
  start_date: validateStartDate,
  start_time: validateStartTime,
  time_limit: validateTimeLimit
};
