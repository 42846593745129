import React, {useEffect} from 'react';
import {Button, Dropdown, Icon, SearchInput, DropdownItem} from '@albert-io/atomic';
import {debounce} from 'lodash';
import {IconName} from '@fortawesome/fontawesome-common-types';

export const SharedOptions = ['Owned by You', 'Shared with You'] as const;

type SharedOption = (typeof SharedOptions)[number];

export interface FilterObj {
  status: 'open' | 'closed' | 'scheduled' | '';
  classes: {
    [key: string]: string;
  };
  shared: SharedOption | '';
}

interface SearchFilterProps {
  searchTerm: string;
  setSearchTerm: (value: string) => void;
  setDebouncedSearchTerm: (value: string) => void;
  setFiltersOpen: (value: boolean) => void;
  filtersOpen: boolean;
  filters: FilterObj;
  sortByOption: string;
  setSortByOption: (value: string) => void;
}

const sortByOptionMap = {
  updated_at: 'Recently Edited',
  name: 'Name'
};

const debounceSearchSet = debounce(
  (setDebouncedSearchTerm, value) => setDebouncedSearchTerm(value),
  200
);

const onChange = (e, setSearchTerm, setDebouncedSearchTerm) => {
  setSearchTerm(e.target.value);
  debounceSearchSet(setDebouncedSearchTerm, e.target.value);
};

const onReset = (setSearchTerm, setDebouncedSearchTerm) => {
  setSearchTerm('');
  debounceSearchSet(setDebouncedSearchTerm, '');
};

const boolSumChecker = (filters) => {
  let sum = 0;
  if (filters.status) {
    sum += 1;
  }
  if (Object.keys(filters.classes).length) {
    sum += 1;
  }
  if (filters.shared) {
    sum += 1;
  }
  return sum;
};

const SearchFilter: React.FC<SearchFilterProps> = ({
  searchTerm,
  setSearchTerm,
  setDebouncedSearchTerm,
  setFiltersOpen,
  filtersOpen,
  filters,
  sortByOption,
  setSortByOption
}) => {
  useEffect(() => {
    return () => {
      debounceSearchSet.cancel();
    };
  }, []);

  const iconNameCircle = boolSumChecker(filters)
    ? `circle-${boolSumChecker(filters)}`
    : 'bars-filter';

  return (
    <div className='global-assignments__search-filter-sort u-gap_space-x1 u-flex-wrap_wrap'>
      <SearchInput
        rectangle
        onChange={(e) => onChange(e, setSearchTerm, setDebouncedSearchTerm)}
        onReset={() => onReset(setSearchTerm, setDebouncedSearchTerm)}
        placeholder='Search by assignment name'
        aria-label='Search by assignment name'
        className='global-assignments__search'
        value={searchTerm}
      />
      <div className='u-display_flex u-align-items_center'>
        <Button
          color='secondary'
          variant='outlined'
          onClick={() => setFiltersOpen(!filtersOpen)}
          className={`${boolSumChecker(filters) ? 'content-link' : ''}`}
          data-testid='filter-trigger'
        >
          Filters
          <Icon icon={iconNameCircle as IconName} className='u-mar-l_1' />
        </Button>
        <Dropdown
          trigger={
            <Button
              className='u-mar-l_1'
              data-testid='sort-trigger'
              color='secondary'
              variant='outlined'
            >
              Sort by: {sortByOptionMap[sortByOption]}
              <Icon className='u-mar-l_1' icon='caret-down' />
            </Button>
          }
          position='bottom-start'
          withMaxHeight
          closeOnItemClicked
        >
          <DropdownItem
            key='sort-updated_at'
            onClick={() => setSortByOption('updated_at')}
            withBottomBorder={false}
          >
            {sortByOptionMap.updated_at}
          </DropdownItem>
          <DropdownItem
            key='sort-name'
            onClick={() => setSortByOption('name')}
            withBottomBorder={false}
          >
            {sortByOptionMap.name}
          </DropdownItem>
        </Dropdown>
      </div>
    </div>
  );
};

export default SearchFilter;
