import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {getStoreByName} from 'client/framework';
import CorrectnessIndicatorWrapper from 'client/QuestionTypes/common/CorrectnessIndicatorWrapper/CorrectnessIndicatorWrapper.react';
import {getModelForResourceType} from 'resources/modelRegistry';

export default class SnippetSelectionOption extends React.Component {
  static propTypes = {
    optionId: PropTypes.string,
    storeName: PropTypes.string.isRequired,
    isExplanation: PropTypes.bool,
    isGuessSubmitted: PropTypes.bool,
    question: PropTypes.instanceOf(getModelForResourceType('question_v3')),
    onOptionClick: PropTypes.func,
    children: PropTypes.node
  };

  constructor(props) {
    super(props);
    this.state = {
      hasFocus: false
    };
  }

  handleFocus = () => {
    this.setState({
      hasFocus: true
    });
  };

  handleBlur = () => {
    this.setState({
      hasFocus: false
    });
  };

  toggleIsSelected = () => {
    this.props.onOptionClick(this.props.optionId);
  };

  render() {
    const {storeName, isExplanation, optionId, isGuessSubmitted, question} = this.props;
    const store = getStoreByName(storeName);
    const isInteractive = !(isExplanation || store.hasResponse);
    const isSelected = store.isOptionSelected(optionId);

    const validResponse = isGuessSubmitted
      ? question
          .getMeta()
          .getQuestionRubric()
          .get('validResponse')
      : null;
    let isCorrectOption = validResponse && !validResponse.isEmpty() && validResponse.get(optionId);

    /**
     * @todo: This next bit is because this component is being used by the legacy IE as well
     * as the practice view. Remove it once the IE is ☠️
     */
    if (!question) {
      isCorrectOption = store.isOptionCorrect(optionId);
    }

    return (
      <label
        className={classnames('snippet-selection-option', {
          'snippet-selection-option--selected': isSelected,
          'snippet-selection-option--correct-option': isCorrectOption,
          'snippet-selection-option--focused': this.state.hasFocus
        })}
      >
        <input
          className='snippet-selection-option__hidden-input'
          type='checkbox'
          checked={isSelected}
          onChange={this.toggleIsSelected}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
        />
        {this.props.children}
        <CorrectnessIndicatorWrapper
          size={24}
          iconOnly
          isSelected={isSelected}
          className='snippet-selection-option__correctness-indicator'
          isGuessSubmitted={!isInteractive}
          isCorrectAnswer={isCorrectOption}
        />
      </label>
    );
  }
}
