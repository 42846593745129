import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import stats, {getStatusNameByAccuracyForStudent} from 'lib/StatsUtil';
import sessionStore from 'client/Session/SessionStore';

import './accuracy-badge.scss';

export default class AccuracyTag extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    guideLevel: PropTypes.object
  };

  generateBadge({percentage = null, status}) {
    const classNames = classnames('accuracy-badge__wrapper', this.props.className);
    const formattedStatus = status.replace(' ', '-').toLowerCase();
    return (
      <div className={classNames}>
        <span
          className={`accuracy-badge accuracy-badge--status accuracy-badge--${formattedStatus}`}
        >
          {status}
        </span>
        {percentage !== null ? (
          <span
            className={`accuracy-badge accuracy-badge--percentage  accuracy-badge--${formattedStatus}`}
          >
            {`${percentage}%`}
          </span>
        ) : null}
      </div>
    );
  }

  render() {
    const topic = this.props.guideLevel;
    /*
      The default badge is shown to users that are not logged in
    */
    const defaultBadge = this.generateBadge({
      status: 'Not started'
    });

    if (!sessionStore.hasValidSession()) {
      return defaultBadge;
    }

    /*
      If a user is logged in they see one of three possible badges:

        1. a badge showing them that they are required to answer more questions before they can see
           their accuracy
        2. a badge showing them their raw accuracy
        3. the default "Not started" badge if they have answered nothing.
    */

    if (topic.getMetaCountOfUniqueQuestionsAnswered() === 0) {
      return defaultBadge;
    }

    /*
      If a user has not 10% of the guide's questions, they will not be shown their accuracy,
      but will instead see a Learning badge with a tooltip letting them know
      how many remaining questions they have to answer.
    */

    /*
       10% or 5 -- whatever is smaller. Rounding the percentage value so we get less odd results.
          ex 10% of 17 is 1.7, but we can round that to be 2 so the user needs to answer 2 questiosn and not 1
    */
    const requiredAnswered = Math.min(Math.round(topic.getMetaCountOfQuestions() * 0.1), 5);

    if (topic.getMetaCountOfUniqueQuestionsAnswered() < requiredAnswered) {
      return this.generateBadge({
        percentage: 0,
        status: getStatusNameByAccuracyForStudent(0)
      });
    }

    /*
      Finally if we make it this far, a student has their raw accuracy computed and shown to them
      with the appropraite badge text and tooltip
    */
    const topicRawAccuracy = stats.percentage(
      topic.getMetaStudentCountOfCorrectGuesses(),
      topic.getMetaCountOfUniqueQuestionsAnswered()
    );

    return this.generateBadge({
      percentage: topicRawAccuracy,
      status: getStatusNameByAccuracyForStudent(topicRawAccuracy)
    });
  }
}
