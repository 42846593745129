import React, {useContext} from 'react';

import {
  AnalyticsApiResult,
  Column,
  Dimension,
  QueryVariables,
  TopLevelFilter
} from 'client/Reports/reports.types';
import {columnIsAvailable} from 'client/Reports/reports.utils';

import {ReportsContext} from '../../Reports.context';

import Cell from './Cell';

import './reports-table.scss';

interface Props {
  columns: Column[];
  dimensions: Dimension[];
  variables: QueryVariables;
  data: any[];
  topLevelFilterType: TopLevelFilter;
}

const BodyRenderer = ({columns, data, dimensions, variables, topLevelFilterType}: Props) => {
  const {isColumnSoftDisabled} = useContext(ReportsContext);

  return (
    <tbody>
      {data.map((row: AnalyticsApiResult) => {
        if (!row) {
          return null;
        }

        const id = row.primary_dimension_id;

        return (
          <tr key={id}>
            {columns
              .filter((column) =>
                columnIsAvailable(column, dimensions, variables, topLevelFilterType)
              )
              .map((column) => {
                const {columnType, key, primaryColumn, renderColumn} = column;

                return (
                  <Cell
                    topLevelFilterType={topLevelFilterType}
                    key={`${key}-${id}`}
                    result={row}
                    resultRenderer={(result) => {
                      if (columnType === 'dimension') {
                        return renderColumn(result, dimensions, isColumnSoftDisabled(column));
                      }
                      return renderColumn(result, dimensions, variables);
                    }}
                    defaultSort={primaryColumn}
                    className='reports-table__cell'
                  />
                );
              })}
          </tr>
        );
      })}
    </tbody>
  );
};

export default BodyRenderer;
