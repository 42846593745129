// @flow
import {List, Map, OrderedMap} from 'immutable';
import {kebabCase} from 'lodash';

import {memoize} from 'lib/memoizer';
import {courseLibraryStructure} from './subjectMenuData';
import {getOverriddenSubgroupString} from './stringOverrideHelpers';

import stylevars from './subject-menu-vars.scss';

import type {KeyedIterable} from 'immutable';
import type {SubjectModelV2} from 'resources/augmented/Subject/SubjectModel.v2';

/**
 * Helper function for turning Immutable.List-like iterables of display names (e.g. 'Avanced Placement')
 * to Maps that look like {'advanced-placement': 'Advanced Placement'}
 */
function makeKebabToValueMap(values: KeyedIterable<number, string>): Map<string, string> {
  return values.reduce((acc, val) => {
    return acc.set(kebabCase(val), val);
  }, Map());
}

const domainsKebabToValueMap = makeKebabToValueMap(courseLibraryStructure.keySeq());
const groupsKebabToValueMap = makeKebabToValueMap(courseLibraryStructure.flatten(1).keySeq());
const subgroupsKebabToValueMap = makeKebabToValueMap(courseLibraryStructure.flatten(2).keySeq());
const kebabToValueMaps = [domainsKebabToValueMap, groupsKebabToValueMap, subgroupsKebabToValueMap];

/**
 * Given a window.location.pathname (e.g. /high-school/test-prep), returns the path into the courseLibraryStructure
 * that we're currently at (for previous example, ["High School", "Test Prep"])
 */
export function getActivePathFromPathname(pathname: string, beginPath: ?string): Array<string> {
  const path = beginPath ? (pathname || '').slice(beginPath.length) : pathname;
  return getActivePathFromKebabPath(path.split('/'));
}

export function getActivePathFromKebabPath(path: Array<string>): Array<string> {
  let activePath = path
    .filter((val) => Boolean(val))
    .map((kebab, i) => kebabToValueMaps[i] && kebabToValueMaps[i].get(kebab))
    .filter((val) => Boolean(val));
  if (!activePath.length) {
    activePath = path.reduce((acc, item) => {
      if (item) {
        acc.push(item.replace('-', ' ').toLowerCase());
      }
      return acc;
    }, []);
  }
  return activePath;
}

/**
 * Given an array of [domain, group] (i.e. an "active path"), returns the subgroups for that active path.
 */
export function getSubgroupItemsByActivePath(
  [domain, group]: [string, string],
  includeAll: boolean = true
): List<string> {
  const subgroupItems = courseLibraryStructure
    .getIn(group ? [domain, group] : [domain], List())
    .keySeq()
    .toList();

  return includeAll ? subgroupItems.unshift('All') : subgroupItems;
}

/**
 * Given an array of [domain, group] (i.e. an "active path"), returns the subgroups for that active as links that
 * can be used by the <SubjectMenuRenderer />'s subgroupMenuContent prop.
 */
export function getSubgroupItemsAsLinkContents(
  [domain, group]: [string, string],
  beginPath: string = ''
): List<Map<string, string>> {
  const kebabDomain = kebabCase(domain);
  if (!group) {
    /**
     * When viewing a top-level domain (without a group) we only display the "All" tab.
     */
    return List([
      Map({
        label: 'All',
        to: `${beginPath}/${kebabDomain}`
      })
    ]);
  }
  const kebabGroup = kebabCase(group);
  const baseUrl = `${beginPath}/${kebabDomain}/${kebabGroup}`;
  return getSubgroupItemsByActivePath([domain, group]).map((subgroup) => {
    const label = getOverriddenSubgroupString(domain, group, subgroup) || subgroup;
    return Map({
      label,
      to: subgroup === 'All' ? baseUrl : `${baseUrl}/${kebabCase(subgroup)}`
    });
  });
}

/**
 * Given an array of [domain, group] (i.e. an "active path") and a click handler, returns the subgroups for that active
 * path as button tabs that can be used by the <SubjectMenuRenderer />'s subgroupMenuContent prop.
 */
export function getSubgroupItemsAsClickHandlerContents(
  [domain, group, activeSubgroup: subgroup]: [string, string, string],
  handler: (string) => void
): List<Map<string, any>> {
  return getSubgroupItemsByActivePath([domain, group]).map((subgroup, i) =>
    Map({
      defaultChecked: (i === 0 && activeSubgroup === undefined) || subgroup === activeSubgroup,
      label: subgroup,
      onClick: () => handler(subgroup)
    })
  );
}

type CourseLibraryStructureWithSubjects = OrderedMap<any, any>;

/**
 * Once subjects have returned from the server, this can be used to convert the courseLibraryStructure
 * into an OrderedMap that follows the following structure.
 * {
 *   domain: {
 *     group: {
 *       subgroup: {
 *         subjectId: SubjectV2Model
 *       }
 *     }
 *   }
 * }
 */
const cache = memoize();
export function getCourseLibraryStructureWithSubjects(
  subjects: List<SubjectModelV2>
): CourseLibraryStructureWithSubjects {
  const key = `processedCourseLibraryStructure/${subjects.hashCode()}`;
  const func = () => {
    return courseLibraryStructure.map((domain) => {
      return domain.map((group) => {
        return group.map((subgroup) => {
          return (
            subgroup
              // eslint-disable-next-line no-unused-vars
              .mapEntries(([subjectName, subjectId]) => {
                return [
                  subjectId,
                  // $FlowFixMe
                  subjects.asMap().get(subjectId)
                ];
              })
              .filter((subject) => Boolean(subject))
          );
        });
      });
    });
  };
  return cache(key, func);
}

type SidebarMenuStructure = OrderedMap<string, List<string>>;

/**
 * Returns a function that, when given a search string, returns a SidebarMenuStructure containing only
 * domains and groups with matching subjects for that search string.
 */
export function makeSidebarMenuItemSearchFunc(
  structure: CourseLibraryStructureWithSubjects
): (string) => SidebarMenuStructure {
  return (searchString) => {
    const match = structure.reduce((acc, groups, domain) => {
      const matchingGroups = groups.filter((group) => {
        return group
          .flatten(1)
          .some((subject) =>
            `${subject.getName()} ${subject.getDescription()}`
              .toLowerCase()
              .includes(searchString.toLowerCase())
          );
      });
      return matchingGroups.isEmpty()
        ? acc
        : acc.set(domain, matchingGroups.keySeq().toList().unshift('All'));
    }, OrderedMap());
    return match;
  };
}

export const sidebarMenuItems: SidebarMenuStructure = courseLibraryStructure.map((val) => {
  return val.keySeq().toList();
});

const drawerModeBreakpoint = parseInt(stylevars.drawerModeBreakpoint, 10);
const longCardBreakpoint = parseInt(stylevars.longCardBreakpoint, 10);

/**
 * Per the designs, the side menu should be a drawer at or below size of drawerModeBreakpoint.
 * This returns whether or not we're at that mode.
 */
export function isDrawerMenuMode(viewportWidth: number): boolean {
  return viewportWidth <= drawerModeBreakpoint;
}

/**
 * Per the designs, cards should be in long mode at or below size of longCardBreakpoint.
 * This returns whether or not we're at that mode.
 */
export function isLongCardMode(viewportWidth: number): boolean {
  return viewportWidth <= longCardBreakpoint;
}

/**
 * This next util is gnarly, but according to JS Perf it is at least 10% more
 * performant than the alternatives. It crawls down each node of the
 * courseLibraryStructure OrderedMap until it finds a positive match for the
 * subject ID. It then saves all the preceding keys to the accumulator.  Nothing
 * special there.  The key is that the logic around each cascading reduce will
 * short-circuit the remaining iterations over the courseLibraryStructure OrderedMap
 * as soon as it finds a match, sparing us any unnecessary operations.
 */
export function getActivePathFromSubjectId(id: string): Array<string> {
  const key = `activePathForSubjectId/${id}`;
  const func = () => {
    const activePath = courseLibraryStructure.reduce((prev, domain, domainName) => {
      return prev.length
        ? prev
        : domain.reduce((prev, group, groupName) => {
            return prev.length
              ? prev
              : group.reduce((prev, subgroup, subgroupName) => {
                  return subgroup.reduce((prev, subject) => {
                    prev = subject === id ? [domainName, groupName, subgroupName] : prev;
                    return prev;
                  }, prev);
                }, prev);
          }, prev);
    }, []);
    return activePath;
  };
  return cache(key, func);
}
