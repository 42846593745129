/* eslint-disable no-underscore-dangle */
// @flow
import zlib from 'zlib';

import {omit, flowRight} from 'lodash';
import {fromJS, List, Map, OrderedSet, Set} from 'immutable';
import qs from 'qs';

import notifier from '@albert-io/notifier';
import {getPluralForm} from '@albert-io/json-api-framework/models/registry/resources';

import {getModel, getCollectionModel} from 'resources/modelRegistry';
import {getRequestAgent} from '@albert-io/mandark/authentication';
import {getMandarkURL, isBrowser} from '@albert-io/environment';

import {getQueryRegistryInterface} from 'lib/activeQueryRegistry';

export type ResourceQueryConfigurationType = {
  resourcePath: Array<string>,
  filter: Object,
  include: string,
  customQuery: Object,
  customHeader: Object
};

export type ResourceType = Map<*, *>;
export type CollectionType = List<ResourceType>;
export type APIRequestType = Promise<?(CollectionType | ResourceType)>;

let INTEREST_REGISTRY = new Map();
let STATUS_CACHE = new Map();
let ACTIVE_PENDING_REQUESTS = new Map();
/**
 * The `DISPATCHED_QUERY_REGISTRY` is a registry of all quereies made in a
 * session, organized by resource. This allows us to invalidate "partially"
 * by invalidating resources at various points in the tree.
 *
 * @see `invalidatePartialInterest`
 */
let DISPATCHED_QUERY_REGISTRY = getQueryRegistryInterface();
let PENDING_INVALIDATIONS = new Map();
let SERVER_PAYLOAD_REGISTRY = new Map();
let INVALIDATION_FROZEN = false;

const DEFAULT_HEADER = {
  'Content-Type': 'application/vnd.api+json'
};

const BUILD_HEADER = (customHeader = {}) => {
  const headers = {
    ...DEFAULT_HEADER,
    ...customHeader
  };

  if (isBrowser()) {
    headers['Mandark-Origin'] = global.window.location.href;
  }

  return headers;
};

let ERROR_HANDLER = () => {};
let RESPONSE_HANDLER = (res) => res;

const CACHE_CONFIG = new Map({
  defaultTimeToLive: 5 * 60 * 1000,
  requestTimeout: 60 * 1000,
  requestWarnCountThreshold: 20,
  requestWarnTimePeriod: 5000
});

let REQUEST_COUNT = 0;

export function restoreServerCacheForClient() {
  if (!global._mandarkResourceData) {
    return;
  }
  let cacheData = null;
  try {
    cacheData = fromJS(
      JSON.parse(
        zlib.inflateSync(Buffer.from(global._mandarkResourceData, 'base64')).toString('utf8')
      )
    );
  } catch (error) {
    logger.error('Error loading Mandark cache. %s\n%j', error.message, error);
    notifier.notify(error, {
      component: 'mandark',
      name: 'Failed to load mandark cache',
      errorMap: global._mandarkResourceData
    });
    cacheData = Map();
  }

  const serverPayloads = cacheData.get('SERVER_PAYLOAD_REGISTRY', Map());
  serverPayloads.forEach((payload, interestKey) => {
    const data = payload.get('payload');
    const resourcePath = payload.get('endpointPath').toJS();
    const customHeader = payload.get('customHeader');
    const processedPayload = _processPayload(data, resourcePath, interestKey);

    _registerInterestFromServer({
      customHeader,
      endpointPath: interestKey,
      meta: data.get('meta'),
      processedPayload,
      resourcePath
    });
  });

  DISPATCHED_QUERY_REGISTRY = getQueryRegistryInterface(
    cacheData.get('DISPATCHED_QUERY_REGISTRY', Map()),
    true
  );
}

export function getCachesForClient(): {
  DISPATCHED_QUERY_REGISTRY: Object,
  SERVER_PAYLOAD_REGISTRY: Object
} {
  return {
    DISPATCHED_QUERY_REGISTRY: DISPATCHED_QUERY_REGISTRY.getRegistryContents().toJS(),
    SERVER_PAYLOAD_REGISTRY: SERVER_PAYLOAD_REGISTRY.toJS()
  };
}

setInterval(() => {
  const REQUEST_THRESHOLD = CACHE_CONFIG.get('requestWarnCountThreshold');
  const THRESHOLD_PERIOD = CACHE_CONFIG.get('requestWarnTimePeriod');

  if (REQUEST_COUNT > REQUEST_THRESHOLD) {
    logger.error(
      'Warning! Your request count of %d has exceeded %d requests in %d!',
      REQUEST_COUNT,
      REQUEST_THRESHOLD,
      THRESHOLD_PERIOD
    );
  }

  REQUEST_COUNT = 0;
}, CACHE_CONFIG.get('requestWarnTimePeriod'));

let CHANGE_CALLBACK = () => {};

// ------------ PUBLIC FUNCTIONS -------------
export function freezeInvalidation() {
  INVALIDATION_FROZEN = true;
}

export function resumeInvalidation() {
  INVALIDATION_FROZEN = false;
  PENDING_INVALIDATIONS.forEach((request) => request());
  PENDING_INVALIDATIONS = new Map();
}

/**
 * @param root0
 * @param root0.wipeErrorHandler
 * @memberOf Server.Isomorphism
 */
export function resetCache({
  wipeErrorHandler = true
}: {
  wipeErrorHandler: boolean
} = {}) {
  INTEREST_REGISTRY = new Map();
  STATUS_CACHE = new Map();
  ACTIVE_PENDING_REQUESTS = new Map();
  SERVER_PAYLOAD_REGISTRY = new Map();
  DISPATCHED_QUERY_REGISTRY = getQueryRegistryInterface();
  if (wipeErrorHandler) {
    ERROR_HANDLER = () => {};
  }
}

export function getHeaders(): Object {
  return BUILD_HEADER();
}

export function setChangeCallback(callback: Function) {
  CHANGE_CALLBACK = callback;
}

export function getActivePendingRequests(): Array<Promise<*>> {
  return ACTIVE_PENDING_REQUESTS.toArray();
}

export function registerErrorHandler(callback: Function) {
  ERROR_HANDLER = callback;
}

export function registerResponseHandler(callback: (Object) => Object) {
  RESPONSE_HANDLER = flowRight(callback, RESPONSE_HANDLER);
}

export function getVersionAgnosticResourceType(type: string): string {
  return type.replace(/_v\d+$/, '');
}

export function getResource({
  resourcePath,
  filter,
  include,
  customQuery,
  customHeader
}: ResourceQueryConfigurationType): any {
  const endpointPath = buildEndpoint({resourcePath, filter, include, customQuery});
  const resourceData = INTEREST_REGISTRY.getIn([endpointPath, 'responseData'], Map());
  const statusData = STATUS_CACHE.getIn(resourcePath, Map());

  if (filter || include || customQuery || resourceData.isEmpty()) {
    if (INTEREST_REGISTRY.has(endpointPath)) {
      _refreshCache(endpointPath);
      return INTEREST_REGISTRY.get(endpointPath).get('responseData', Map());
    }
    _registerInterest(endpointPath, resourcePath, customHeader);
    DISPATCHED_QUERY_REGISTRY = DISPATCHED_QUERY_REGISTRY.addInterestKey(
      {
        customHeader,
        customQuery,
        filter,
        resourcePath
      },
      endpointPath
    );
    return Map();
  }
  if (statusData.get('__dirty', false)) {
    const attachedInterests = STATUS_CACHE.getIn(
      [...resourcePath, '__attachedInterests'],
      OrderedSet()
    );
    if (INTEREST_REGISTRY.has(endpointPath)) {
      _refreshCache(statusData.get('__interestPath'));
    } else if (!attachedInterests.isEmpty()) {
      _refreshCache(attachedInterests.last(), true);
    } else {
      _registerInterest(endpointPath, resourcePath, customHeader);
      DISPATCHED_QUERY_REGISTRY = DISPATCHED_QUERY_REGISTRY.addInterestKey(
        {
          customHeader,
          customQuery,
          filter,
          resourcePath
        },
        endpointPath
      );
    }

    STATUS_CACHE = STATUS_CACHE.setIn([...resourcePath, '__dirty'], false);
    return resourceData;
  }
  if (INTEREST_REGISTRY.has(endpointPath)) {
    _refreshCache(endpointPath);
  }
  return resourceData;
}

/**
 * Retrive the `error` property of a resource from the `INTEREST_REGISTRY`.
 *
 * This method will **NOT** make a request for the resource if you have not already.
 *
 * @param root0
 * @param root0.resourcePath
 * @param root0.filter
 * @param root0.include
 * @param root0.customQuery
 * @param root0.customHeader
 * @returns {Error|null}
 */
export function getResourceError({
  resourcePath,
  filter,
  include,
  customQuery,
  customHeader
}: ResourceQueryConfigurationType): Error | null {
  const endpointPath = buildEndpoint({resourcePath, filter, include, customQuery});
  if (INTEREST_REGISTRY.has(endpointPath) === false) {
    return null;
  }
  return INTEREST_REGISTRY.get(endpointPath).get('error', null);
}

export async function getResourcePromise({
  resourcePath,
  filter,
  include,
  customQuery,
  customHeader
}: ResourceQueryConfigurationType): Promise<*> {
  /**
   * Using 'arguments' only works while there are no defaults for the destructed argument... careful.
   */
  const resource = arguments[0];
  if (isResourceReady(resource)) {
    return getResource(resource);
  }

  const requestId = generateRequestId(buildEndpoint({resourcePath, filter, include, customQuery}));

  await ACTIVE_PENDING_REQUESTS.get(requestId, Promise.resolve());

  return getResource(resource);
}

export function getResourceMetadata({
  resourcePath,
  filter,
  include,
  customQuery,
  customHeader
}: ResourceQueryConfigurationType): Map<string, *> {
  const endpointPath = buildEndpoint({resourcePath, filter, include, customQuery});
  return INTEREST_REGISTRY.getIn([endpointPath, 'meta'], new Map());
}

export function invalidateModel(modelPath) {
  const attachedInterests = STATUS_CACHE.getIn(
    [...modelPath, '__attachedInterests'],
    new OrderedSet()
  );
  attachedInterests.forEach((interestId) => invalidateInterestById(interestId, false));
  setTimeout(CHANGE_CALLBACK, 0);
}

export function invalidatePartialInterest(query) {
  // Strip the model in case the query object has been built with `resource()`
  const sanitizedQuery = omit(query, ['Model', 'defaultModel']);
  let invalidationCount = 0;
  DISPATCHED_QUERY_REGISTRY.getInvalidationKeys(sanitizedQuery).forEach((key) => {
    if (INTEREST_REGISTRY.has(key)) {
      invalidationCount++;
      STATUS_CACHE = STATUS_CACHE.setIn([key, '__dirty'], true);
      STATUS_CACHE.getIn([key, '__derivedResources'], new Set()).forEach((derivedInterest) => {
        STATUS_CACHE = STATUS_CACHE.setIn([...derivedInterest, '__dirty'], true);
      });
      if (ACTIVE_PENDING_REQUESTS.has(key)) {
        ACTIVE_PENDING_REQUESTS.get(key).then(
          () => (STATUS_CACHE = STATUS_CACHE.setIn([key, '__dirty'], true))
        );
      }
    }
  });
  setTimeout(CHANGE_CALLBACK, 0);
  logger.debug('Partial interest invalidation: %d queries invalidated.', invalidationCount);
}

export function invalidateInterest({
  resourcePath,
  filter,
  include,
  customQuery,
  customHeader
}: {
  resourcePath: Array<string>,
  filter: Object,
  include: string,
  customQuery: Object,
  customHeader: Object
}) {
  const endpointPath = buildEndpoint({resourcePath, filter, include, customQuery});
  if (resourcePath.length > 1) {
    STATUS_CACHE = STATUS_CACHE.setIn([...resourcePath, '__dirty'], true);
    const attachedInterests = STATUS_CACHE.getIn(
      [...resourcePath, '__attachedInterests'],
      new List()
    );
    attachedInterests.forEach((key) => {
      STATUS_CACHE = STATUS_CACHE.setIn([key, '__dirty'], true);
    });
  }

  if (filter || include || customQuery || customHeader) {
    STATUS_CACHE = STATUS_CACHE.setIn([endpointPath, '__dirty'], true);
    STATUS_CACHE.getIn([endpointPath, '__derivedResources'], new Set()).forEach(
      (derivedInterest) => {
        STATUS_CACHE = STATUS_CACHE.setIn([...derivedInterest, '__dirty'], true);
      }
    );
  }

  if (ACTIVE_PENDING_REQUESTS.has(endpointPath)) {
    ACTIVE_PENDING_REQUESTS.get(endpointPath).then(() => {
      STATUS_CACHE = STATUS_CACHE.setIn([endpointPath, '__dirty'], true);
    });
  }
  setTimeout(CHANGE_CALLBACK, 0);
}

export function invalidateInterestById(id, shouldTriggerChange = true) {
  STATUS_CACHE = STATUS_CACHE.setIn([id, '__dirty'], true);
  STATUS_CACHE.getIn([id, '__derivedResources'], new Set()).forEach((derivedInterest) => {
    STATUS_CACHE = STATUS_CACHE.setIn([...derivedInterest, '__dirty'], true);
  });
  if (ACTIVE_PENDING_REQUESTS.has(id)) {
    ACTIVE_PENDING_REQUESTS.get(id).then(() => {
      STATUS_CACHE = STATUS_CACHE.setIn([id, '__dirty'], true);
    });
  }
  if (shouldTriggerChange) {
    setTimeout(CHANGE_CALLBACK, 0);
  }
}

export function isResourcePending({
  resourcePath,
  filter,
  include,
  customQuery,
  customHeader
}: ResourceQueryConfigurationType): boolean {
  const statusData = STATUS_CACHE.getIn(resourcePath, new Map());
  getResource({
    customHeader,
    customQuery,
    filter,
    include,
    resourcePath
  }); // If we don't have it, initialize it
  const endpointPath = buildEndpoint({resourcePath, filter, include, customQuery});
  return (
    INTEREST_REGISTRY.getIn([endpointPath, 'status']) === 'pending' ||
    statusData.get('__dirty', false) === true
  );
}

export function isResourceReady({
  resourcePath,
  filter,
  include,
  customQuery,
  customHeader
}: ResourceQueryConfigurationType): boolean {
  const endpointPath = buildEndpoint({resourcePath, filter, include, customQuery});
  getResource({
    customHeader,
    customQuery,
    filter,
    include,
    resourcePath
  }); // If we don't have it, initialize it
  const isRequestPending = ACTIVE_PENDING_REQUESTS.has(endpointPath);

  if (INTEREST_REGISTRY.has(endpointPath)) {
    return !STATUS_CACHE.getIn([endpointPath, '__dirty'], false) && !isRequestPending;
  }
  return !STATUS_CACHE.getIn([...resourcePath, '__dirty'], false);
}

export function isResourcePopulated({
  resourcePath,
  filter,
  include,
  customQuery,
  customHeader
}: ResourceQueryConfigurationType): boolean {
  const resource = getResource({
    customHeader,
    customQuery,
    filter,
    include,
    resourcePath
  });
  const endpointPath = buildEndpoint({resourcePath, filter, include, customQuery});
  return (
    INTEREST_REGISTRY.getIn([endpointPath, 'status']) === 'ready' ||
    INTEREST_REGISTRY.getIn([endpointPath, 'status']) === 'refreshing' ||
    !resource.isEmpty()
  );
}

export function isResourceValid({
  resourcePath,
  filter,
  include,
  customQuery,
  customHeader
}: ResourceQueryConfigurationType): boolean {
  getResource({
    customHeader,
    customQuery,
    filter,
    include,
    resourcePath
  }); // If we don't have it, initialize it

  const endpointPath = buildEndpoint({resourcePath, filter, include, customQuery});
  return INTEREST_REGISTRY.getIn([endpointPath, 'status']) !== 'error';
}

export function isResourceErrorHandled(
  {
    resourcePath,
    filter,
    include,
    customQuery
  }: {
    resourcePath: Array<string>,
    filter: Object,
    include: string,
    customQuery: Object
  },
  handlerId: string
): boolean {
  const endpointPath = buildEndpoint({resourcePath, filter, include, customQuery});
  return INTEREST_REGISTRY.getIn([endpointPath, 'handlers'], new Set()).has(handlerId);
}

export function flagResourceErrorHandled(
  {
    resourcePath,
    filter,
    include,
    customQuery
  }: {
    resourcePath: Array<string>,
    filter: Object,
    include: string,
    customQuery: Object
  },
  handlerId: string
) {
  const endpointPath = buildEndpoint({resourcePath, filter, include, customQuery});
  if (
    INTEREST_REGISTRY.has(endpointPath) &&
    INTEREST_REGISTRY.getIn([endpointPath, 'status']) === 'error'
  ) {
    INTEREST_REGISTRY = INTEREST_REGISTRY.updateIn(
      [endpointPath, 'handlers'],
      new Set(),
      (handlers) => handlers.add(handlerId)
    );
  } else {
    throw new Error(
      `Attempted to flag error as handled for ${endpointPath} but this resource either does not exist or is not an error`
    );
  }
}

export function areAllErrorsHandled(): boolean {
  return getUnhandledErrors().isEmpty();
}

export function getUnhandledErrors(): Map<string, Map<string, *>> {
  return INTEREST_REGISTRY.filter(
    (interest) => interest.get('status') === 'error' && interest.get('handlers', Set()).isEmpty()
  );
}

export function buildEndpoint({
  resourcePath,
  filter,
  include,
  customQuery
}: {
  resourcePath: Array<string>,
  filter: ?Object,
  include: ?string,
  customQuery: ?Object
} = {}): string {
  const basePath = `${resourcePath.join('/')}`;

  let query = List();

  if (filter) {
    query = query.push(
      qs.stringify(
        {filter},
        {
          encode: false
        }
      )
    );
  }

  if (include) {
    query = query.push(
      qs.stringify(
        {include},
        {
          encode: false
        }
      )
    );
  }

  if (customQuery) {
    query = query.push(
      qs.stringify(customQuery, {
        encode: false
      })
    );
  }

  query = query.join('&');

  return query.length > 0 ? `${basePath}?${query}` : basePath;
}

// ------- PRIVATE FUNCTIONS -------
function _registerInterest(
  endpointPath: string,
  resourcePath: Array<string>,
  customHeader: Object
) {
  if (INTEREST_REGISTRY.has(endpointPath)) {
    throw new Error(`Attempted to register interest with id ${endpointPath} which already exists!`);
  }

  const requestFunction = _makeRequest(endpointPath, resourcePath, customHeader);
  const pendingRequest = requestFunction();
  const interestData = new Map({
    error: null,
    promise: pendingRequest,
    requestFunction,
    responseData: new Map(),
    status: 'pending',
    timestamp: new Date()
  });

  INTEREST_REGISTRY = INTEREST_REGISTRY.set(endpointPath, interestData);
}

function _registerInterestFromServer({
  customHeader,
  endpointPath,
  processedPayload,
  resourcePath,
  meta
}: {
  customHeader: object,
  endpointPath: string,
  processedPayload: object,
  resourcePath: Array<string>
}) {
  const requestFunction = _makeRequest(endpointPath, resourcePath, customHeader);
  const interestData = new Map({
    error: null,
    meta,
    promise: Promise.resolve(processedPayload),
    requestFunction,
    responseData: processedPayload,
    status: 'ready',
    timestamp: new Date()
  });

  INTEREST_REGISTRY = INTEREST_REGISTRY.set(endpointPath, interestData);
}

function _refreshCache(interestPath: string, force: boolean = false) {
  // If the request is already pending, do not remake it. This can arise with multiple
  // dependent resources relying on the same query spawning them.
  if (ACTIVE_PENDING_REQUESTS.has(interestPath)) {
    return;
  }

  const timestamp = INTEREST_REGISTRY.getIn([interestPath, 'timestamp'], 0);
  const status = INTEREST_REGISTRY.getIn([interestPath, 'status'], null);
  const requestFunction = INTEREST_REGISTRY.getIn([interestPath, 'requestFunction']);
  const isDirty = STATUS_CACHE.getIn([interestPath, '__dirty'], false);

  if (
    (status === 'ready' && Date.now() - timestamp > CACHE_CONFIG.get('defaultTimeToLive')) ||
    isDirty ||
    force
  ) {
    const refresh = () => {
      const pendingRequest = requestFunction();
      const interestData = new Map({
        error: null,
        promise: pendingRequest,
        request: requestFunction,
        status: 'refreshing',
        timestamp: new Date()
      });
      INTEREST_REGISTRY = INTEREST_REGISTRY.mergeDeepIn([interestPath], interestData);
      STATUS_CACHE = STATUS_CACHE.setIn([interestPath, '__dirty'], false);
    };

    if (INVALIDATION_FROZEN) {
      PENDING_INVALIDATIONS = PENDING_INVALIDATIONS.set(interestPath, refresh);
    } else {
      refresh();
    }
  }
}

function generateRequestId(endpointPath: string): string {
  return endpointPath;
}

export function genericCancelableMandarkRequest(
  method: string,
  query: object,
  payload: object,
  options: object = {raw: false}
): {promise: APIRequestType, cancel: () => void} {
  const fullQuery = buildEndpoint({
    resourcePath: query.resourcePath,
    filter: query.filter,
    include: query.include,
    customQuery: query.customQuery
  });

  const pendingRequest = getRequestAgent()
    [method](getMandarkURL(`/${fullQuery}`))
    .timeout(CACHE_CONFIG.get('requestTimeout'))
    .set(BUILD_HEADER(query.customHeader));

  let finished = false;

  const promise = new Promise((resolve, reject) => {
    pendingRequest.send(payload);
    pendingRequest.end((err, res) => {
      finished = true;
      if (err || (res && !res.ok)) {
        ERROR_HANDLER(err);
        handleMandarkErrorResponse(err, res);
        return reject(err);
      }

      RESPONSE_HANDLER(res);

      if (res.noContent) {
        return resolve();
      }
      if (options.raw) {
        return resolve(res);
      }
      /*
          If our response is not jsonapi, we simply return the response body as an immutable object (/auth/sign_in),
          otherwise it goes thru the normal response parsing route
        */
      if (res.body && res.body.hasOwnProperty('jsonapi')) {
        const processedPayload = _processPayload(
          fromJS(res.body),
          query.resourcePath,
          fullQuery,
          query.customHeader,
          method
        );
        return resolve(processedPayload);
      }
      return res.body ? resolve(fromJS(res.body)) : resolve();
    });
  });

  // idempotency flag
  let canceled = false;

  const cancel = () => {
    if (!canceled && !finished) {
      canceled = true;
      pendingRequest.abort();
    }
  };

  if (options.abortSignal) {
    options.abortSignal.addEventListener('abort', () => {
      cancel();
    });
  }

  return {
    promise,
    cancel
  };
}

/**
 * Generally speaking, you shouldn't use this directly under any circumstances.
 * A good example of when to use is the practice exam creation steps in `CreateAssigment.store.js`.
 * which has to hit a special endpoint that `save()` isn’t able to account for on its own.
 *
 * If you are looking to create a new instance of a model - the `.save()` method is the way to go.
 * To learn more about creating/deleting model instances and the `.save()` method @see /docs/legacy/genericmodel.md
 *
 * @param method
 * @param query
 * @param payload
 * @param options
 */
export function genericMandarkRequest(
  method: string,
  query: object,
  payload: object,
  options: object = {raw: false}
): APIRequestType {
  const {promise} = genericCancelableMandarkRequest(method, query, payload, options);
  return promise;
}

function _makeRequest(endpointPath: string, resourcePath: Array<*>, customHeader: Object): any {
  return async function () {
    const requestId = generateRequestId(endpointPath);
    STATUS_CACHE = STATUS_CACHE.setIn([requestId, '__dirty'], false).setIn(
      [...resourcePath, '__dirty'],
      false
    );
    const pendingGet = new Promise((resolve, reject) => {
      const pendingGetRequest = getRequestAgent()
        .get(getMandarkURL(`/${endpointPath}`))
        .timeout(CACHE_CONFIG.get('requestTimeout'))
        .set(BUILD_HEADER(customHeader));

      pendingGetRequest.end((err, res) => {
        ACTIVE_PENDING_REQUESTS = ACTIVE_PENDING_REQUESTS.delete(requestId);
        if (err || (res && (res.status < 200 || res.status > 299))) {
          ERROR_HANDLER(err);
          handleMandarkErrorResponse(err, res);
          return reject(err);
        }
        RESPONSE_HANDLER(res);
        return resolve(fromJS(res.body));
      });
    });

    ACTIVE_PENDING_REQUESTS = ACTIVE_PENDING_REQUESTS.set(requestId, pendingGet);

    try {
      REQUEST_COUNT++;
      const payload = await pendingGet;
      const processedPayload = _processPayload(payload, resourcePath, endpointPath, customHeader);
      INTEREST_REGISTRY = INTEREST_REGISTRY.mergeDeep(
        new Map({
          [endpointPath]: new Map({
            error: null,
            status: 'ready',
            timestamp: new Date()
          })
        })
      ).setIn([endpointPath, 'responseData'], processedPayload);
    } catch (err) {
      INTEREST_REGISTRY = INTEREST_REGISTRY.mergeDeep(
        new Map({
          [endpointPath]: new Map({
            error: err,
            status: 'error',
            timestamp: new Date()
          })
        })
      );
    } finally {
      ACTIVE_PENDING_REQUESTS = ACTIVE_PENDING_REQUESTS.delete(requestId);
      setTimeout(CHANGE_CALLBACK, 0);
    }
  };
}

export function _processPayload(
  payload: Map<*, *>,
  endpointPath: Array<*>,
  interestId: string,
  customHeader: object,
  responseType: string = 'get'
): CollectionType | ResourceType {
  if (!process.env.IS_BROWSER) {
    SERVER_PAYLOAD_REGISTRY = SERVER_PAYLOAD_REGISTRY.set(
      interestId,
      new Map({
        customHeader,
        endpointPath,
        payload
      })
    );
  }
  payload
    .getIn(['meta', 'warnings'], new List())
    .forEach((warning) =>
      logger.warn(`Query %s has the following warning: %s`, interestId, warning)
    );

  let processedPayload = null;
  // Process main resource
  if (List.isList(payload.get('data'))) {
    // Process collection
    const head = payload.get('data').first();
    let collectionModel;
    if (!head) {
      // default model in the case of an empty list

      const modelNameFromPath = endpointPath.slice(-1)[0];

      collectionModel = getCollectionModel(modelNameFromPath);
      processedPayload = collectionModel ? new collectionModel() : new List();
    } else {
      // Retrieve the model name from the first list element's JSONAPI type attribute
      const resourceType = head.get('type');
      const pluralizedResourceType = getPluralForm(resourceType);
      collectionModel = getCollectionModel(pluralizedResourceType);
      if (collectionModel) {
        processedPayload = payload.get('data').reduce((result, datum) => {
          const processedNode = _processNode(
            payload,
            false,
            datum.get('type'),
            datum.get('id'),
            endpointPath,
            null,
            interestId,
            responseType
          );
          return result.push(processedNode);
        }, new List());

        processedPayload = new collectionModel(processedPayload, true);
      } else {
        processedPayload = payload.get('data').reduce((result, datum) => {
          const processedNode = _processNode(
            payload,
            false,
            datum.get('type'),
            datum.get('id'),
            endpointPath,
            null,
            interestId,
            responseType
          );
          return result.set(datum.get('id'), processedNode);
        }, new Map());
      }
    }
  } else {
    processedPayload = _processNode(
      payload,
      false,
      payload.getIn(['data', 'type'], '_UNKNOWN_'),
      payload.getIn(['data', 'id'], '_UNKNOWN_'),
      endpointPath,
      null,
      interestId,
      responseType
    );
  }

  // Avoid storing pure relationship data on parsing a POST payload as a pseudo-full resource (which it is not)
  // ... Unless it already has it, which means it was resolved by something else already and we just want to
  // merge in any extra relationship info
  if (responseType === 'get' || INTEREST_REGISTRY.has(interestId)) {
    INTEREST_REGISTRY = INTEREST_REGISTRY.mergeDeep(
      new Map({[interestId]: new Map({meta: payload.get('meta')})})
    );
  }

  return processedPayload;
}

function _processNode(
  payload: Map<*, *>,
  included: boolean,
  resourceType: string,
  id: string,
  endpointPath: ?Array<*>,
  passedMeta: ?Map<*, *>,
  interestId: string,
  responseType: string
): Map<*, *> {
  const mainPath = included ? 'included' : 'data';

  let node;
  if (List.isList(payload.get(mainPath))) {
    /**
     * Currently, if a query includes two resources of differetnt types but with the same ID (e.g. classrooms_v1 and student_classrooms_v1),
     * the back-end will only return one of the two resources. This can cause our node to not be found. If this is the case, we're going to
     * lean on the similar resource to build our clobbered resource. This is temporary, and will be removed once the back-end merges a fix
     * for the clobbered duplicate resource.
     */
    const fullMatch = payload
      .get(mainPath)
      .find((datum) => datum.get('id') === id && datum.get('type') === resourceType);
    node = fullMatch || payload.get(mainPath).find((datum) => datum.get('id') === id);
  } else {
    node = payload.get(mainPath);
  }

  const versionAgnosticResourceType = getVersionAgnosticResourceType(resourceType);

  const resolvedRelationships = node
    .get('relationships', new Map())
    .reduce((result, relationship, type) => {
      let resolvedResources;
      const nodeRelationshipData = relationship.get('data');
      const isParent = /parent_v[0-9]+/g.test(type);
      if (nodeRelationshipData) {
        if (List.isList(nodeRelationshipData)) {
          const collectionModel = getCollectionModel(type);
          resolvedResources = nodeRelationshipData.reduce((resolvedResources, relationshipData) => {
            const relationshipMeta = relationshipData.has('meta')
              ? new Map({
                  relationshipMeta: new Map({
                    [versionAgnosticResourceType]: new Map({
                      [id]: relationshipData.get('meta')
                    })
                  })
                })
              : new Map();
            return resolvedResources.push(
              _processNode(
                payload,
                true,
                relationshipData.get('type'),
                relationshipData.get('id'),
                endpointPath,
                relationshipMeta,
                interestId,
                responseType
              )
            );
          }, new List());
          if (collectionModel) {
            resolvedResources = new collectionModel(resolvedResources, true);
          }
        } else {
          const relationshipMeta = nodeRelationshipData.has('meta')
            ? new Map({
                relationshipMeta: new Map({
                  [versionAgnosticResourceType]: new Map({
                    [id]: nodeRelationshipData.get('meta')
                  })
                })
              })
            : new Map();
          resolvedResources = _processNode(
            payload,
            true,
            relationship.getIn(['data', 'type']),
            relationship.getIn(['data', 'id']),
            endpointPath,
            relationshipMeta,
            interestId,
            responseType
          );
        }
      }

      const versionAgnosticType = type.replace(/_v[0-9]+$/g, '');
      return !resolvedResources
        ? result
        : result.set(isParent ? 'parent' : versionAgnosticType, resolvedResources);
    }, new Map());

  const populatedMetadataFieldsKey = 'populated_metadata_fields';

  const populatedMetadataFields = node.getIn(['meta', populatedMetadataFieldsKey]);

  const nodeMeta = node.get('meta', new Map()).reduce((result, value, key) => {
    if (key !== populatedMetadataFieldsKey && !populatedMetadataFields.includes(key)) {
      return result;
    }
    return result.set(key, value);
  }, new Map());

  const resolvedNode = new Map()
    .merge(resolvedRelationships)
    .merge(node.get('attributes'))
    .mergeDeep(new Map({meta: nodeMeta}))
    .mergeDeep(passedMeta)
    .set('id', id)
    .set('jsonapi_type', resourceType);

  const pluralResourceType = getPluralForm(resourceType);

  const typeModel = getModel(resolvedNode);

  const processedNode = typeModel ? new typeModel(resolvedNode, true) : resolvedNode;

  if (process.env.IS_BROWSER) {
    setTimeout(() => {
      STATUS_CACHE = STATUS_CACHE.setIn([pluralResourceType, id, '__dirty'], true);
    }, CACHE_CONFIG.get('defaultTimeToLive'));
  }

  STATUS_CACHE = STATUS_CACHE.setIn([pluralResourceType, id, '__interestPath'], endpointPath);

  // A POST / PUT / PATCH response should never be marked as an attached interest, as it is not a persistent interest
  // in the cache, but rather a one-time payload that we have no valid query key for, therefore this is _not_ an
  // attached interest (nor derived ones)
  if (responseType === 'get') {
    STATUS_CACHE = STATUS_CACHE.updateIn(
      [pluralResourceType, id, '__attachedInterests'],
      new OrderedSet(),
      (ai) => ai.add(interestId)
    ).updateIn([interestId, '__derivedResources'], new Set(), (dr) =>
      dr.add([pluralResourceType, id])
    );
  }

  return processedNode;
}

/**
 * @param {Error} error
 * @param {import('superagent').Response} errorResponse
 */
export function handleMandarkErrorResponse(error, errorResponse) {
  if (!errorResponse || !errorResponse.body) {
    logger.debug(
      'An error was encountered without a response from Mandark.\n %j\n %j',
      error,
      errorResponse
    );
    return;
  }
  try {
    /**
     * In an effort to reduce sampling and noise in our monitoring tools we only send error responses from the
     * API if they are not a 4xx response.
     */
    if (
      (errorResponse.status < 400 || errorResponse.status >= 500) &&
      Object.prototype.hasOwnProperty.call(errorResponse.body, 'errors')
    ) {
      const processedErrors = fromJS(errorResponse.body)
        .get('errors')
        .map((err) =>
          Map({
            code: err.get('code'),
            detail: err.get('detail'),
            status: err.get('status'),
            title: err.get('title'),
            traceId: err.getIn(['meta', 'trace_id']),
            requestId: err.get('id')
          })
        );
      const processedError = !processedErrors.isEmpty() ? processedErrors.first().toJS() : {};
      notifier.notify(error, {
        ...processedError,
        name: 'Mandark Error Response',
        component: 'mandark',
        errors: processedErrors.toJS()
      });
    }
    logger.error('Mandark Error Response: %j', error);
  } catch (e) {
    logger.debug('An error was encountered while trying to handle a Mandark error response. %j', e);
  }
}
