import React, {useState, useRef, useEffect} from 'react';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {useAudioPlayer} from './AudioPlayerProvider.react';
import AudioControls from './AudioControls.react';
import AudioTrack from './AudioTrack.react';
import {useAudioEvents, useTrackEvents} from './AudioPlayer.utils';
import './audio-player.scss';

const playbackRates = [0.5, 1, 2, 3, 4];

interface AudioPlayerProps {
  src: string;
  caption?: string;
}

export const AudioPlayer = ({src, caption}: AudioPlayerProps) => {
  const {players, registerPlayer, updatePlayerState} = useAudioPlayer();
  const playerState = players[src] || {};
  const {playbackRate, timestamp, duration, isPlaying, pauseFired} = playerState;
  const [isRegistered, setIsRegistered] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);
  const trackRef = useRef<HTMLInputElement>(null);

  useAudioEvents(audioRef, src, updatePlayerState);
  useTrackEvents(trackRef);

  useEffect(() => {
    registerPlayer(src);
  }, []);

  useEffect(() => {
    const audio = audioRef.current;
    if (audio && Object.keys(playerState).length > 0 && !isRegistered) {
      audio.currentTime = timestamp;
      audio.playbackRate = playbackRate;
      setIsRegistered(true);
    }
  }, [playerState]);

  useEffect(() => {
    if (audioRef.current && pauseFired) {
      audioRef.current.pause();
      updatePlayerState(src, {pauseFired: false});
    }
  }, [pauseFired]);

  const togglePlayPause = () => {
    if (audioRef.current) {
      const audio = audioRef.current;
      if (isPlaying) {
        audio.pause();
      } else {
        audio.play();
      }

      const currPlayState = players[src].isPlaying;
      updatePlayerState(src, {isPlaying: !currPlayState});
    }
  };

  const seekAudio = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (audioRef.current) {
      const seekTime = parseFloat(event.target.value);
      audioRef.current.currentTime = seekTime;
      updatePlayerState(src, {timestamp: seekTime});
    }
  };

  const restartAudio = () => {
    if (audioRef.current) {
      const audio = audioRef.current;
      audio.currentTime = 0;
      if (!isPlaying) {
        audio.play();
        updatePlayerState(src, {isPlaying: true});
      }
    }
  };

  const changeRate = () => {
    if (audioRef.current) {
      const currentIndex = playbackRates.indexOf(playbackRate);
      const nextIndex = (currentIndex + 1) % playbackRates.length;
      const nextSpeed = playbackRates[nextIndex];
      audioRef.current.playbackRate = nextSpeed;
      updatePlayerState(src, {playbackRate: nextSpeed});
    }
  };

  return (
    <div className='audio-player__container elevation-background-l3'>
      <audio ref={audioRef} src={src} controls={false} preload='metadata'>
        <track kind='captions' />
      </audio>
      <div className='audio-player__title-track'>
        {caption && <MarkdownRendererV2 className='audio-player__title' text={caption} />}
        <AudioTrack
          duration={duration}
          timestamp={timestamp}
          seekAudio={seekAudio}
          isPlaying={isPlaying}
          trackRef={trackRef}
        />
      </div>
      <AudioControls
        isPlaying={isPlaying}
        playbackRate={playbackRate}
        togglePlayPause={togglePlayPause}
        restartAudio={restartAudio}
        changeRate={changeRate}
      />
    </div>
  );
};
