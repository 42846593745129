import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {Button} from '@albert-io/atomic';
import makeDataProps from 'lib/makeDataProps';

import './upload-button.scss';

export default class UploadButton extends React.Component {
  static propTypes = {
    accept: PropTypes.string.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    errorMessage: PropTypes.string,
    handleUpload: PropTypes.func.isRequired,
    multiple: PropTypes.bool,
    children: PropTypes.node
  };

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      files: []
    };
  }

  handleChange = (e) => {
    const files = [...e.target.files];
    this.setState({
      files
    });
    this.props.handleUpload(e);
    this.inputRef.current.value = '';
  };

  formatBytes(bytes) {
    // Byte formatter utility adapted from https://gist.github.com/lanqy/5193417
    const parsedBytes = parseInt(bytes, 10);
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB'];
    if (parsedBytes === 0) {
      return '0 bytes';
    }

    const index = parseInt(Math.floor(Math.log(parsedBytes) / Math.log(1024)), 10);
    if (index === 0) {
      return `${parsedBytes} ${units[index]})`;
    }

    return `${(parsedBytes / 1024 ** index).toFixed(1)} ${units[index]}`;
  }

  render() {
    const dataProps = makeDataProps(this.props);

    const files = this.state.files.map((file, i) => {
      return (
        <li key={i} className='upload-button__file-list-item'>
          <span className='upload-button__file-list-item-name'>{file.name}</span>
          <span className='upload-button__file-list-item-size'>{this.formatBytes(file.size)}</span>
        </li>
      );
    });

    const errorContainer = this.props.errorMessage ? (
      <div className='a-form-input__error upload-button__error-container'>
        {this.props.errorMessage}
      </div>
    ) : null;

    return (
      <div className='upload-button__wrapper'>
        <ul className='upload-button__file-list'>{files}</ul>
        <Button
          as='label'
          className={classnames('upload-button__button', this.props.className)}
          disabled={this.props.disabled}
        >
          {this.props.children}
          <input
            {...dataProps}
            accept={this.props.accept}
            disabled={this.props.disabled}
            multiple={this.props.multiple}
            onChange={this.handleChange}
            ref={this.inputRef}
            type='file'
            className='u-display_none'
          />
        </Button>
        {errorContainer}
      </div>
    );
  }
}
