import React, {useState} from 'react';
import {fromJS} from 'immutable';

import constants from 'client/constants';
import {callAction} from 'client/framework';
import sessionStore from 'client/Session/SessionStore';
import SimpleDropdown from 'generic/SimpleDropdown/SimpleDropdown.react';
import withTracking from 'lib/hocs/withTracking';

import FeatureFlag from 'client/components/FeatureFlag/FeatureFlag.react';

import {
  Badge,
  Button,
  Dialogue,
  Dropdown,
  DropdownItem,
  Icon,
  Modal,
  Text
} from '@albert-io/atomic';

import questionEditorStore from '../QuestionEditor/QuestionEditor.store';
import questionEditorActions from '../QuestionEditor/QuestionEditor.actions';

const TrackedButton = withTracking(Button, {
  onClick: {
    feature: 'dennis-queue',
    event: 'click',
    type: 'button',
    component: 'approve_button',
    content: 'Approved'
  }
});

const statusOptionsV1 = fromJS([
  {
    id: constants.QUESTIONSET_STATUSES.REJECTED,
    name: 'Rejected',
    status: constants.QUESTIONSET_STATUSES.REJECTED
  },
  {
    id: constants.QUESTIONSET_STATUSES.DRAFT_MODE,
    name: 'Draft mode',
    status: constants.QUESTIONSET_STATUSES.DRAFT_MODE
  },
  {
    id: constants.QUESTIONSET_STATUSES.IN_REVIEW,
    name: 'In review',
    status: constants.QUESTIONSET_STATUSES.IN_REVIEW
  },
  {
    id: constants.QUESTIONSET_STATUSES.HIDDEN,
    name: 'Hidden',
    status: constants.QUESTIONSET_STATUSES.HIDDEN
  },
  {
    id: constants.QUESTIONSET_STATUSES.PUBLISHED,
    name: 'Published',
    status: constants.QUESTIONSET_STATUSES.PUBLISHED
  },
  {
    id: constants.QUESTIONSET_STATUSES.ARCHIVED,
    name: 'Archived',
    status: constants.QUESTIONSET_STATUSES.ARCHIVED
  }
]);

export const StatusDropdown = () => {
  const questionSet = questionEditorStore.getQuestionSet();
  const status = questionSet.getStatus();
  const isUnaligned =
    questionSet.getAuthoringExams().isEmpty() && questionSet.getAuthoringGuideLevels().isEmpty();
  const statusError =
    isUnaligned &&
    [constants.QUESTIONSET_STATUSES.IN_REVIEW, constants.QUESTIONSET_STATUSES.PUBLISHED].includes(
      status
    ) ? (
      <p className='a-form-input__error'>
        Please align the question to a guide level or assessment before moving it to &lsquo;in
        review&rsquo; or &lsquo;published&rsquo;.
      </p>
    ) : null;

  const handleStatusChange = (option) => {
    callAction(questionEditorActions.UPDATE_QUESTION_SET_STATUS, {
      status: option.get('status')
    });
  };

  return (
    <FeatureFlag name='question_editor_v2'>
      <StatusDropdownV2Presentation
        status={status}
        handleStatusChange={handleStatusChange}
        statusError={statusError}
      />

      <FeatureFlag.Fallback>
        <StatusDropdownV1Presentation
          status={status}
          handleStatusChange={handleStatusChange}
          statusError={statusError}
        />
      </FeatureFlag.Fallback>
    </FeatureFlag>
  );
};

interface StatusDropdownPresentationProps {
  status: string;
  handleStatusChange: (newStatus: string) => void;
  statusError: any;
}

const StatusDropdownV1Presentation = ({
  status,
  handleStatusChange,
  statusError
}: StatusDropdownPresentationProps) => {
  if (!sessionStore.isSuper()) {
    return null;
  }
  return (
    <div className='question-editor__question-detail--fullwidth'>
      <SimpleDropdown
        label='Status'
        activeItemId={status}
        options={statusOptionsV1}
        storeName='QuestionStatusStore'
        onChange={handleStatusChange}
        destroyStoreOnUnmount
      />
      {statusError}
    </div>
  );
};

const statusOptionsV2 = fromJS([
  {
    id: constants.QUESTIONSET_STATUSES.PUBLISHED,
    name: 'Published',
    status: constants.QUESTIONSET_STATUSES.PUBLISHED
  },
  {
    id: constants.QUESTIONSET_STATUSES.DRAFT_MODE,
    name: 'Draft mode',
    status: constants.QUESTIONSET_STATUSES.DRAFT_MODE
  },
  {
    id: constants.QUESTIONSET_STATUSES.IN_REVIEW,
    name: 'In review',
    status: constants.QUESTIONSET_STATUSES.IN_REVIEW
  },
  {
    id: constants.QUESTIONSET_STATUSES.REJECTED,
    name: 'Rejected',
    status: constants.QUESTIONSET_STATUSES.REJECTED
  },
  {
    id: constants.QUESTIONSET_STATUSES.HIDDEN,
    name: 'Hidden',
    status: constants.QUESTIONSET_STATUSES.HIDDEN
  },
  {
    id: constants.QUESTIONSET_STATUSES.ARCHIVED,
    name: 'Archived',
    status: constants.QUESTIONSET_STATUSES.ARCHIVED
  }
]);

const StatusDropdownV2Presentation = ({
  handleStatusChange,
  status,
  statusError: _s
}: StatusDropdownPresentationProps) => {
  const [showPublishModal, setShowPublishModal] = useState(false);
  const colors = {
    [constants.QUESTIONSET_STATUSES.REJECTED]: 'negative',
    [constants.QUESTIONSET_STATUSES.DRAFT_MODE]: 'info',
    [constants.QUESTIONSET_STATUSES.IN_REVIEW]: 'warning',
    [constants.QUESTIONSET_STATUSES.HIDDEN]: 'neutral',
    [constants.QUESTIONSET_STATUSES.PUBLISHED]: 'positive',
    [constants.QUESTIONSET_STATUSES.ARCHIVED]: 'neutral'
  };

  const names = {
    [constants.QUESTIONSET_STATUSES.REJECTED]: 'Rejected',
    [constants.QUESTIONSET_STATUSES.DRAFT_MODE]: 'Draft mode',
    [constants.QUESTIONSET_STATUSES.IN_REVIEW]: 'In review',
    [constants.QUESTIONSET_STATUSES.HIDDEN]: 'Hidden',
    [constants.QUESTIONSET_STATUSES.PUBLISHED]: 'Published',
    [constants.QUESTIONSET_STATUSES.ARCHIVED]: 'Archived'
  };

  const icons = {
    [constants.QUESTIONSET_STATUSES.REJECTED]: ['fal', 'times'],
    [constants.QUESTIONSET_STATUSES.DRAFT_MODE]: ['fal', 'edit'],
    [constants.QUESTIONSET_STATUSES.IN_REVIEW]: 'spinner',
    [constants.QUESTIONSET_STATUSES.HIDDEN]: 'eye-slash',
    [constants.QUESTIONSET_STATUSES.PUBLISHED]: 'check',
    [constants.QUESTIONSET_STATUSES.ARCHIVED]: ['fal', 'box-archive']
  };

  const isSuper = sessionStore.isSuper();
  const questionSet = questionEditorStore.getQuestionSet();
  const canBePublished = !questionSet.getAuthoringGuideLevels().isEmpty();

  const trigger = (
    <Badge className='u-cursor_pointer' icon='caret-down' status={colors[status]} iconAlign='right'>
      <Icon className='u-mar-r_1' icon={icons[status]} />
      {names[status]}
    </Badge>
  );

  return isSuper ? (
    <>
      <Dropdown trigger={trigger} closeOnItemClicked>
        <div className='u-pad_1'>
          <Text size='xs'>Set question status:</Text>
        </div>
        {statusOptionsV2.map((option) => (
          <DropdownItem
            disabled={option.get('status') === 'published' && !canBePublished}
            onClick={() => {
              if (option.get('status') === constants.QUESTIONSET_STATUSES.PUBLISHED) {
                setShowPublishModal(true);
              } else {
                handleStatusChange(option);
              }
            }}
            key={option.get('id')}
          >
            <div className='u-display_flex u-justify-content_space-between u-align-items_center u-gap_space-x5'>
              <div className='u-display_flex u-align-items_center u-gap_space-x2'>
                <Icon
                  icon='check'
                  style={{
                    opacity: status === option.get('status') ? 1 : 0
                  }}
                />
                <Text>{names[option.get('status')]}</Text>
              </div>
              <Icon icon={icons[option.get('status')] as any} />
            </div>
          </DropdownItem>
        ))}
      </Dropdown>
      {showPublishModal && (
        <Modal ariaLabel='Approve question modal' handleClose={() => setShowPublishModal(false)}>
          {({CloseButtonWrapper, modalContentStyle}) => {
            return (
              <Dialogue
                handleClose={() => setShowPublishModal(false)}
                inModal
                className={modalContentStyle}
                title='Approve question'
              >
                <Dialogue.Body>
                  <Text>Are you sure you want to approve this question?</Text>
                </Dialogue.Body>
                <Dialogue.BtnGroup>
                  <CloseButtonWrapper>
                    <Button variant='text'>Cancel</Button>
                  </CloseButtonWrapper>
                  <TrackedButton
                    onClick={() => {
                      if (!canBePublished) {
                        return;
                      }
                      setShowPublishModal(false);
                      callAction(questionEditorActions.APPROVE_QUESTION_SET);
                    }}
                  >
                    Approve
                  </TrackedButton>
                </Dialogue.BtnGroup>
              </Dialogue>
            );
          }}
        </Modal>
      )}
    </>
  ) : (
    <Badge status={colors[status]}>
      <Icon className='u-mar-r_1' icon={icons[status]} />
      {names[status]}
    </Badge>
  );
};
