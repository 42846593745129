import React, {useState, useEffect, useContext} from 'react';
import {pushQueryParams} from 'client/history';
import {withRouter, WithRouterProps} from 'react-router';
import {isColumnSoftToggleable, sortColumns} from 'client/Reports/reports.utils';
import usePrevious from 'lib/hooks/usePrevious';
import {NoDataMsg} from '@albert-io/atomic';
import classNames from 'classnames';

import {Dimension, MetricColumn, SortOption} from '../../reports.types';
import {ReportsContext} from '../../Reports.context';

import './reports-table.scss';

import {ReportsPagination} from '../Pagination/ReportsPagination.react';

import HeaderRenderer from './HeaderRenderer';
import BodyRenderer from './BodyRenderer';

interface Props extends WithRouterProps {
  data: any[];
  loading: boolean;
  dimension: Dimension;
  metrics: MetricColumn[];
}

const rowSizeOptions = [{value: 25}, {value: 50}, {value: 100}];

const ReportsTable = withRouter(({data, dimension, location, loading, metrics}: Props) => {
  const {sort} = location.query;
  let initialSortBy = null;
  let initialSortDirection: SortOption = 'asc';
  if (sort) {
    const [querySortBy, querySortDirection] = sort.split(':');
    initialSortBy = querySortBy;
    initialSortDirection = querySortDirection;
  }

  const [sortBy, setSortBy] = useState<string | null>(initialSortBy);
  const [sortDirection, setSortDirection] = useState<SortOption>(initialSortDirection);
  const prevSortDirection = usePrevious(sortDirection);
  const prevSortBy = usePrevious(sortBy);

  useEffect(() => {
    if (sortBy && sortDirection && (sortBy !== prevSortBy || sortDirection !== prevSortDirection)) {
      pushQueryParams({sort: `${sortBy}:${sortDirection}`});
    }
  }, [sortBy, sortDirection, prevSortBy, prevSortDirection]);

  const {
    availableDimensionColumns,
    dimensionColumnKeyToIsDimensionToggledOn,
    limit,
    page,
    topLevelFilterType,
    totals,
    variables
  } = useContext(ReportsContext);

  const total = totals[0] || 0;

  const enabledDimensionColumns = availableDimensionColumns.filter((column) => {
    if (isColumnSoftToggleable(column)) {
      return true;
    }

    return dimensionColumnKeyToIsDimensionToggledOn.get(column.key) ?? false;
  });

  const columns = sortColumns(enabledDimensionColumns, metrics);

  if (!loading && data.length === 0) {
    return (
      <div className='u-display_flex u-justify-content_center'>
        <NoDataMsg.Container centered>
          <NoDataMsg.Heading>No Data Available</NoDataMsg.Heading>
          <NoDataMsg.Body>There is no data available for the current filters.</NoDataMsg.Body>
        </NoDataMsg.Container>
      </div>
    );
  }

  return (
    <>
      <div
        className={classNames(
          'reports-table__wrapper u-display_flex u-border u-border-radius_1 u-border-color_slate-300'
        )}
      >
        <table className='reports-table__table'>
          <HeaderRenderer
            columns={columns}
            sortBy={sortBy}
            setSortBy={(value) => {
              setSortBy(value);
            }}
            sortDirection={sortDirection}
            setSortDirection={(value) => {
              setSortDirection(value);
            }}
            dimensions={[dimension]}
            variables={variables}
            topLevelFilterType={topLevelFilterType}
          />
          <BodyRenderer
            columns={columns}
            data={data}
            dimensions={[dimension]}
            variables={variables}
            topLevelFilterType={topLevelFilterType}
          />
        </table>
      </div>
      <ReportsPagination limit={limit} page={page} total={total} rowSizeOptions={rowSizeOptions} />
    </>
  );
});

export default ReportsTable;
