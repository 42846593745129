import React, {useMemo} from 'react';

import {Text, IconButton, Input, WithTooltip} from '@albert-io/atomic';

import {MULTIPLE_CHOICE_LETTERS} from 'client/QuestionTypes/MultipleChoice/V2/MultipleChoice.constants';

import {useShallow} from 'zustand/react/shallow';

import {PRIMARY_CONTENT_LANGUAGE, TranslatedField} from '../QuestionEditorV2Store.types';
import {useQuestionEditorV2Store} from '../QuestionEditorV2Store';

import {TranslationStatusDropdown} from './TranslationStatusDropdown';

interface Props {
  field: string;
  translatedField: TranslatedField | null;
}

export const TranslationStatusManager = ({field, translatedField}: Props) => {
  const {updateTranslatedQuestionField, currentLanguage} = useQuestionEditorV2Store(
    useShallow((state) => ({
      updateTranslatedQuestionField: state.updateTranslatedQuestionField,
      currentLanguage: state.currentLanguage
    }))
  );

  const fieldName = useMemo(() => humanReadableFieldName(field), [field]);

  if (currentLanguage === PRIMARY_CONTENT_LANGUAGE) {
    return null;
  }
  return (
    <div className='u-display_flex u-flex-direction_column u-gap_space-x2 u-mar-l_5 u-border u-border-radius_1 border-subtle background-primary u-pad_2'>
      <div className='u-display_flex u-align-items_center u-justify-content_space-between'>
        <Text size='s' bold>
          {fieldName} Translation
        </Text>

        <TranslationStatusDropdown
          currentStatus={translatedField?.status || 'draft'}
          onChange={(newStatus) => {
            updateTranslatedQuestionField(currentLanguage, field, 'status', newStatus);
          }}
        />
      </div>

      <TranslationNotesInput
        notes={translatedField?.notes || ''}
        setNotes={(newValue) =>
          updateTranslatedQuestionField(currentLanguage, field, 'notes', newValue)
        }
      />
    </div>
  );
};

interface TranslationNotesInputProps {
  notes: string;
  setNotes: (comments: string) => void;
}

const TranslationNotesInput = ({notes, setNotes}: TranslationNotesInputProps) => {
  return (
    <div className='u-display_flex u-align-items_center u-gap_space-x1'>
      <div className='u-flex-grow_1 u-mar-l_1'>
        <Input
          size='s'
          placeholder='Add comment'
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
      </div>

      <WithTooltip enabled={!!notes} content='Remove'>
        <IconButton
          disabled={!notes}
          color='secondary'
          variant='text'
          size='s'
          icon={['fal', 'trash']}
          onClick={() => setNotes('')}
          label='Clear Notes'
        />
      </WithTooltip>
    </div>
  );
};

const humanReadableFieldName = (field: string) => {
  const parts = field.split('.').map((part) => {
    const num = Number(part);
    return Number.isNaN(num) ? part : num;
  });

  if (parts.length === 1) {
    return {
      title: 'Title',
      prompt: 'Prompt',
      solution_text: 'Explanation of Solution',
      dropdown_text: 'Dropdown Text',
      sample: 'Sample Response',
      uncorrected_text: 'Uncorrected Text',
      snippet_prompt: 'Snippet Text',
      highlight_prompt: 'Highlight Prompt',
      header_left: 'Header Left',
      header_right: 'Header Right'
    }[field];
  }

  if (parts[0] === 'dropdowns') {
    const [_dropdowns, index, _choices, choiceIndex, _value] = parts as [
      'dropdowns',
      number,
      'choices',
      number,
      'value'
    ];

    return `Dropdown ${index + 1} Choice ${choiceIndex + 1}`;
  }

  if (parts[0] === 'inputs') {
    const [_inputs, index, _text] = parts as ['inputs', number, 'text'];
    return `Input ${index + 1} Prompt`;
  }

  if (parts[0] === 'options') {
    const [_options, index, _value] = parts as ['options', number, 'value'];
    return `Option ${MULTIPLE_CHOICE_LETTERS.charAt(index)}`;
  }

  if (parts[0] === 'rows') {
    if (parts[2] === 'columns') {
      const [_rows, rowIndex, _columns, leftOrRight, _text] = parts as [
        'rows',
        number,
        'columns',
        'left' | 'right',
        'text'
      ];
      return `Row ${rowIndex + 1} ${leftOrRight === 'left' ? 'Left' : 'Right'} Column`;
    }

    if (parts[2] === 'statement') {
      const [_rows, rowIndex, _statement] = parts as ['rows', number, 'statement'];
      return `Row ${rowIndex + 1} Statement`;
    }
  }

  return field;
};
