import React, {useContext, useRef} from 'react';
import {renderDuration, renderMoment} from 'lib/timeUtils';
import {resource} from '@albert-io/json-api-framework/request/builder';
import masqueradeStore from 'client/generic/Masquerade/Masquerade.store';
import makeConstants from 'lib/makeConstants';
import {isEqual} from 'lodash';
import {pushQueryParams, history} from 'client/history';
import {
  DateRange,
  getLastSchoolYear,
  getThisSchoolYear,
  RANGES
} from '@albert-io/atomic/organisms/DatePicker/DatePicker.helpers';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {genericMandarkRequest} from 'resources/mandark.resource';

import type {ClassroomModelV1} from '@albert-io/models';

import {IconButton, Popover, Text, WithToggle, WithTooltip} from '@albert-io/atomic';

import moment from 'moment-timezone';

import {ReportsContext} from 'client/Reports/Reports.context';

import {OrderedMap} from 'immutable';

import {
  allDimensions,
  AnalyticsApiResult,
  Dimension,
  DimensionColumn,
  MetricColumn,
  QueryVariables,
  ReportTypes,
  TOP_LEVEL_FILTERS,
  TopLevelFilter,
  DimensionConfig,
  CLASSROOM_DIMENSIONS,
  TEACHER_DIMENSIONS,
  SCHOOL_DIMENSIONS,
  SCHOOL_ADMIN_DIMENSIONS,
  Column,
  Pagination,
  Filter
} from './reports.types';
import {AnswerBreakdown} from './Report/Table/Columns/AnswerBreakdown';
import {Performance as PerformanceColumn} from './Report/Table/Columns/Performance';
import StandardsActions from './Report/Table/Columns/StandardsActions';
import AssignmentSubmitted from './Report/Table/Columns/AssignmentSubmitted';
import AssignmentActions from './Report/Table/Columns/AssignmentActions';
import QuestionActions from './Report/Table/Columns/QuestionActions';

const DIMENSIONS = makeConstants(...allDimensions);
const DIMENSIONS_FOR_EACH_TOPLEVELFILTERTYPE = {
  classrooms: CLASSROOM_DIMENSIONS,
  teachers: TEACHER_DIMENSIONS,
  schools: SCHOOL_DIMENSIONS,
  'school-admin': SCHOOL_ADMIN_DIMENSIONS
};

const CLASSROOM_REPORTS = [...CLASSROOM_DIMENSIONS, 'gradebook'] as const;
export type ClassroomReportTypes = (typeof CLASSROOM_REPORTS)[number];

const TEACHER_REPORTS = TEACHER_DIMENSIONS;
export type TeacherReportTypes = (typeof TEACHER_REPORTS)[number];

const SCHOOL_REPORTS = SCHOOL_DIMENSIONS;
export type SchoolReportTypes = (typeof SCHOOL_REPORTS)[number];

const SCHOOL_ADMIN_REPORTS = SCHOOL_ADMIN_DIMENSIONS;
export type SchoolAdminReportTypes = (typeof SCHOOL_ADMIN_REPORTS)[number];

const IS_CLASSROOM_REPORTS_BLOCKED_FOR_NON_PRO_ADMINS_MAP: {
  [reportType in ClassroomReportTypes]: () => boolean;
} = {
  gradebook: () => true,
  assignments: () => false,
  students: () => false,
  questions: () => false,
  standards: () => false,
  subjects: () => false
}; // reports/classrooms

const IS_SCHOOL_REPORTS_BLOCKED_FOR_NON_PRO_ADMINS_MAP: {
  [reportType in SchoolReportTypes]:
    | (() => boolean)
    | ((drilldownIds: {[key: string]: string}) => boolean);
} = {
  assignments: () => true,
  questions: () => true,
  standards: () => true,
  teachers: (drilldownIds) => {
    const {studentId, classroomId} = drilldownIds;
    return studentId !== '' || classroomId !== '';
  },
  classrooms: (drilldownIds) => {
    const {studentId, teacherId} = drilldownIds;
    return studentId !== '' || teacherId !== '';
  },
  students: (drilldownIds) => {
    const {classroomId, teacherId} = drilldownIds;
    return classroomId !== '' || teacherId !== '';
  },
  subjects: (drilldownIds) => {
    const {teacherId, studentId, classroomId} = drilldownIds;
    return studentId !== '' || classroomId !== '' || teacherId !== '';
  }
}; // reports/schools

const IS_SCHOOL_ADMIN_REPORTS_BLOCKED_FOR_NON_PRO_ADMINS_MAP: {
  [reportType in SchoolAdminReportTypes]: () => boolean;
} = {
  students: () => true,
  assignments: () => true,
  questions: () => true,
  standards: () => true,
  subjects: () => true,
  classrooms: () => true,
  teachers: () => true,
  schools: () => true
}; // reports/school-admin

const IS_REPORTS_BLOCKED_FOR_NON_PRO_ADMINS = (
  topLevelFilterType: TopLevelFilter,
  report: ReportTypes,
  drilldownIds: {[key: string]: string}
) => {
  const map = {
    classrooms: IS_CLASSROOM_REPORTS_BLOCKED_FOR_NON_PRO_ADMINS_MAP,
    schools: IS_SCHOOL_REPORTS_BLOCKED_FOR_NON_PRO_ADMINS_MAP,
    'school-admin': IS_SCHOOL_ADMIN_REPORTS_BLOCKED_FOR_NON_PRO_ADMINS_MAP
  };

  if (!map[topLevelFilterType]) return false;
  const isReportBlockedFunction = map[topLevelFilterType][report];
  if (!isReportBlockedFunction) return false;
  return isReportBlockedFunction(drilldownIds);
};

const DIMENSION_CONFIG: {[reportType in ReportTypes]: DimensionConfig} = {
  gradebook: {
    sortOrder: 0,
    label: 'Gradebook',
    dimension: 'gradebook',
    /** ^^^ DrilldownSelector attributes */
    reportInvalidWhenAnyOfTheseDimensionsInPath: [] as Dimension[]
  },
  schools: {
    sortOrder: 1,
    label: 'Schools',
    dimension: 'schools',
    /** ^^^ DrilldownSelector attributes */
    defaultPath: 'students',
    reportInvalidWhenAnyOfTheseDimensionsInPath: [
      'classrooms', // classrooms only has one school therefore this report isn't needed
      'schools', // if its a school report (toplevelfiltertype === schools), we don't want to show the schools report option
      'students', // students typically only have one school therefore this report isn't needed
      'teachers' // teachers typically only have one school therefore this report isn't needed
    ] as Dimension[]
  },
  teachers: {
    sortOrder: 2,
    label: 'Teachers',
    dimension: 'teachers',
    /** ^^^ DrilldownSelector attributes */
    defaultPath: 'assignments',
    reportInvalidWhenAnyOfTheseDimensionsInPath: [
      'classrooms', // the report will return rows with identical metrics and the list will have no distinct values
      'teachers' // if its a all-classes report (toplevelfiltertype === teachers), we don't want to show the teachers report option
    ] as Dimension[]
  },
  classrooms: {
    sortOrder: 3,
    label: 'Classrooms',
    dimension: 'classrooms',
    /** ^^^ DrilldownSelector attributes */
    defaultPath: 'assignments',
    reportInvalidWhenAnyOfTheseDimensionsInPath: ['classrooms'] as Dimension[]
  },
  students: {
    sortOrder: 4,
    label: 'Students',
    dimension: 'students',
    /** ^^^ DrilldownSelector attributes */
    defaultPath: 'assignments',
    reportInvalidWhenAnyOfTheseDimensionsInPath: [] as Dimension[]
  },
  assignments: {
    sortOrder: 5,
    label: 'Assignments',
    dimension: 'assignments',
    /** ^^^ DrilldownSelector attributes */
    defaultPath: 'students',
    reportInvalidWhenAnyOfTheseDimensionsInPath: [] as Dimension[]
  },
  questions: {
    sortOrder: 6,
    label: 'Questions',
    dimension: 'questions',
    /** ^^^ DrilldownSelector attributes */
    defaultPath: 'students',
    reportInvalidWhenAnyOfTheseDimensionsInPath: [] as Dimension[]
  },
  standards: {
    sortOrder: 7,
    label: 'Standards',
    dimension: 'standards',
    /** ^^^ DrilldownSelector attributes */
    defaultPath: 'students',
    reportInvalidWhenAnyOfTheseDimensionsInPath: [] as Dimension[]
  },
  subjects: {
    sortOrder: 8,
    label: 'Subjects',
    dimension: 'subjects',
    /** ^^^ DrilldownSelector attributes */
    defaultPath: 'students',
    reportInvalidWhenAnyOfTheseDimensionsInPath: [
      'questions' // if there is a question filter, this report is expected to only return 1 row (the question's subject) and therefore it isn't a valuable report
    ] as Dimension[]
  }
};

const processReportPath = (
  path: string
): {
  topLevelFilterType: TopLevelFilter;
  topLevelFilterId: string;
  assignmentId: string;
  questionId: string;
  standardId: string;
  studentId: string;
  subjectId: string;
  classroomId: string;
  teacherId: string;
  schoolId: string;
  reportSelected: string;
} => {
  const prevStack: string[][] = [];
  const urlParams = {
    topLevelFilterType: '' as TopLevelFilter,
    topLevelFilterId: '',
    assignmentId: '',
    questionId: '',
    standardId: '',
    studentId: '',
    subjectId: '',
    classroomId: '',
    teacherId: '',
    schoolId: '',
    reportSelected: ''
  };

  const pathPartsStack = removeTrailingSlash(path).split('/').slice(2).reverse();
  if (!pathPartsStack.length) return urlParams;
  while (pathPartsStack.length) {
    const currentItem = pathPartsStack.pop();
    if (prevStack.length === 0) {
      // then we should be expecting a topLevelFilter and its Ids
      const toplevelfilterType = currentItem;
      if (!TOP_LEVEL_FILTERS.includes(toplevelfilterType))
        throw new Error(`INVALID TOPLEVELFILTERTYPE ${toplevelfilterType}`);
      const toplevelfilterId = pathPartsStack.pop();
      if (!toplevelfilterId) throw new Error('TOPLEVELFILTERID UNDEFINED');
      if (
        !(toplevelfilterType === 'school-admin' && toplevelfilterId === 'me') &&
        (!isValidUUID(toplevelfilterId) || typeof toplevelfilterType !== 'string')
      )
        throw new Error(`TOPLEVELFILTERID ${toplevelfilterId} NOT UUID`);
      prevStack.push([toplevelfilterType, toplevelfilterId]);
    } else if (prevStack.length === 1 && currentItem === 'gradebook') {
      prevStack.push(['gradebook']);
    } else if (currentItem && allDimensions.includes(currentItem)) {
      // then we should be expecting either a dimension, dimension/id
      if (pathPartsStack.length === 0) {
        // then current item is report selection
        prevStack.push([currentItem]);
      } else {
        const dimensionId = pathPartsStack.pop();
        if (!isValidUUID(dimensionId) || typeof dimensionId !== 'string')
          throw new Error(`INVALID ID Supplied to ${currentItem} dimension filter ${dimensionId}`);
        prevStack.push([currentItem, dimensionId]);
      }
    } else {
      throw new Error(
        `Unexpected value supplied in report url when expecting dimension: '${currentItem}'`
      );
    }
  }

  prevStack.forEach((urlPart, index) => {
    if (index === 0) {
      const [topLevelFilterType, topLevelFilterId] = urlPart;
      urlParams.topLevelFilterType = topLevelFilterType as TopLevelFilter;
      urlParams.topLevelFilterId = topLevelFilterId;
    } else if (urlPart.length === 2) {
      const [filterType, filterId] = urlPart;
      switch (filterType) {
        case 'assignments':
          urlParams.assignmentId = filterId;
          break;
        case 'questions':
          urlParams.questionId = filterId;
          break;
        case 'students':
          urlParams.studentId = filterId;
          break;
        case 'standards':
          urlParams.standardId = filterId;
          break;
        case 'subjects':
          urlParams.subjectId = filterId;
          break;
        case 'classrooms':
          urlParams.classroomId = filterId;
          break;
        case 'teachers':
          urlParams.teacherId = filterId;
          break;
        case 'schools':
          urlParams.schoolId = filterId;
          break;
        default:
          break;
      }
    } else if (urlPart.length === 1) {
      const [reportSelected] = urlPart;
      if (index === prevStack.length - 1) {
        // only set reportSelected if it is the last urlPart
        urlParams.reportSelected = reportSelected;
      }
    }
  });
  return urlParams;
};

function drilldownDimensionIsValidForPath(dimensionToEvaluate: Dimension, path: string) {
  const dimensionsInPath = path.split('/').filter(isDimension);

  // if any of the dimensions in the path are in the list of dimensions that make dimensionToEvaluate invalid, return false, otherwise return true
  return !dimensionsInPath.some((dimension) => {
    return DIMENSION_CONFIG[
      dimensionToEvaluate
    ].reportInvalidWhenAnyOfTheseDimensionsInPath.includes(dimension);
  });
}

function getValidRemainingDrilldownDimensionsForURL(
  topLevelFilterType: TopLevelFilter,
  path: string
): Dimension[] {
  const dimensionsInPathSet = new Set(path.split('/').filter(isDimension));
  const remainingDimensions = DIMENSIONS_FOR_EACH_TOPLEVELFILTERTYPE[topLevelFilterType].filter(
    (dimension) => {
      return !dimensionsInPathSet.has(dimension);
    }
  );
  const remainingValidDimensions = remainingDimensions.filter((remainingDimension) => {
    return drilldownDimensionIsValidForPath(remainingDimension, path);
  });
  return remainingValidDimensions;
}

const assignmentSubmittedColumn: DimensionColumn = {
  columnType: 'dimension',
  dimension: 'assignments',
  key: 'assignment_submitted',
  isAvailable: (dimensions, filters) => {
    return (
      ((!!filters.assignmentFilter || isEqual(dimensions, ['assignments'])) &&
        (!!filters.studentFilter || isEqual(dimensions, ['students']))) ||
      isEqual(dimensions, ['students', 'assignments'])
    );
  },
  sortKey: (dimensions) => {
    if (dimensions.length !== 1) {
      return null;
    }
    const [dimension] = dimensions;

    return `${dimension}.submitted` as `${string}.${string}`;
  },
  title: 'Status',
  renderColumn: (result, dimensions) => {
    return <AssignmentSubmitted result={result} dimensions={dimensions} />;
  },
  exportHeaders: () => ['Status'],
  export: (data) => {
    const {submitted} = getStudentAssignment(data);
    return submitted
      ? [`Submitted ${moment(submitted).format('L')} @ ${moment(submitted).format('LT')}`]
      : ['Not Submitted'];
  },
  afterMetrics: true,
  isToggleable: (dimensions, {studentFilter}) => {
    return isEqual(dimensions, ['assignments']) && studentFilter !== undefined;
  }
};

const DIMENSION_COLUMNS: {[reportType in ReportTypes]: DimensionColumn[]} = {
  schools: [
    {
      columnType: 'dimension',
      dimension: 'schools',
      primaryColumn: true,
      key: 'schools.name',
      title: 'Name',
      sortKey: 'schools.name',
      renderColumn: (result) => {
        const {school} = result;
        return <div>{school?.name}</div>;
      },
      exportHeaders: () => ['Name'],
      export: (result) => {
        const {school} = result;
        return [school?.name];
      }
    }
  ],
  teachers: [
    {
      columnType: 'dimension',
      dimension: 'teachers',
      primaryColumn: true,
      key: 'teachers.last_name',
      title: 'Name',
      sortKey: 'teachers.last_name',
      renderColumn: (result) => {
        const {teacher} = result;
        return <div>{`${teacher.first_name} ${teacher.last_name}`}</div>;
      },
      exportHeaders: () => ['Name'],
      export: (result) => {
        const {teacher} = result;
        return [`${teacher.first_name} ${teacher.last_name}`];
      }
    },
    {
      columnType: 'dimension',
      dimension: 'teachers',
      key: 'teachers.email',
      title: 'Email',
      sortKey: 'teachers.email',
      renderColumn: (result) => {
        const {teacher} = result;
        return <div>{`${teacher.email}`}</div>;
      },
      exportHeaders: () => ['Name'],
      export: (result) => {
        const {teacher} = result;
        return [`${teacher.email}`];
      }
    }
  ],
  classrooms: [
    {
      columnType: 'dimension',
      dimension: 'classrooms',
      primaryColumn: true,
      key: 'classrooms.name',
      title: 'Name',
      sortKey: 'classrooms.name',
      renderColumn: (result) => {
        const {classroom} = result;
        return <div>{classroom?.name}</div>;
      },
      exportHeaders: () => ['Name'],
      export: (result) => {
        const {classroom} = result;
        return [classroom?.name];
      }
    }
  ],
  subjects: [
    {
      columnType: 'dimension',
      dimension: 'subjects',
      primaryColumn: true,
      key: 'subjects.name',
      title: 'Name',
      sortKey: 'subjects.name',
      renderColumn: (result) => {
        const {subject} = result;
        return <div>{subject?.name}</div>;
      },
      exportHeaders: () => ['Name'],
      export: (result) => {
        const {subject} = result;
        return [subject?.name];
      }
    }
  ],
  assignments: [
    {
      columnType: 'dimension',
      dimension: 'assignments',
      primaryColumn: true,
      key: 'assignments.name',
      title: 'Name',
      sortKey: 'assignments.name',
      renderColumn: (result) => {
        const assignment = getAssignment(result);
        return <div>{assignment?.name}</div>;
      },
      exportHeaders: () => ['Name'],
      export: (result) => {
        const assignment = getAssignment(result);
        return [assignment?.name];
      }
    },
    {
      columnType: 'dimension',
      dimension: 'assignments',
      key: 'assignments.classrooms',
      title: 'Classroom',
      sortKey: null,
      renderColumn: (result) => {
        const assignment = getAssignment(result);
        return (
          <div>
            {assignment?.classroom_assignments?.map?.((c) => c.classroom.name)?.join?.(', ')}
          </div>
        );
      },
      isAvailable: (dimensions, filters) => {
        return isEqual(dimensions, ['assignments']) && filters.classroomFilter === undefined;
      }
    },
    // max/min due date range column - only shows on `All Classes` filter and not scoped to a student
    // special case - there is special sort logic for this column handled in HeaderRenderer.tsx
    // in the DueDateRangeHeaderCell component
    {
      columnType: 'dimension',
      dimension: 'assignments',
      initialSortDirection: 'desc',
      key: 'assignments.due_date_range',
      title: 'Due Date',
      sortKey: null,
      isAvailable: (_, filters, topLevelFilterType) =>
        shouldRenderDueDateRangeCol(topLevelFilterType, filters),
      renderColumn: (result) => {
        const {assignment} = result;
        const dueDateRange = getDueDateRangeFromClassroomAssignments(
          assignment?.classroom_assignments
        );
        return (
          <div>
            {dueDateRange ||
              renderMoment(assignment?.settings?.due_date || assignment?.due_date, 'dateFormal')}
          </div>
        );
      },
      exportHeaders: () => ['Due Date'],
      export: (result) => {
        const {assignment} = result;
        const dueDateRange = getDueDateRangeFromClassroomAssignments(
          assignment?.classroom_assignments
        );
        return [
          dueDateRange ||
            renderMoment(assignment?.settings?.due_date || assignment?.due_date, 'dateFormal')
        ];
      },
      isToggleable: true
    },
    {
      columnType: 'dimension',
      dimension: 'assignments',
      initialSortDirection: 'desc',
      key: 'assignments.contextual_due_date_max',
      title: 'Due Date',
      sortKey: 'assignments.contextual_due_date_max',
      isAvailable: (_, filters, topLevelFilterType) =>
        !shouldRenderDueDateRangeCol(topLevelFilterType, filters),
      // if student assignment settings are present, we get the due date from there
      // otherwise we get the due date from the classroom assignment settings
      renderColumn: (result) => {
        const assignment = getAssignment(result);
        const studentAssignments =
          result.assignments_student_assignments ||
          (result.gradebook_student_assignment ? [result.gradebook_student_assignment] : []);

        let studentSettings: any = null;
        if (studentAssignments.length === 1) {
          studentSettings = studentAssignments[0].settings;
        }

        // classroom_assignments are sorted by due_date desc by the backend,
        // so the first one *is* the most recent due date
        const classroomAssignment = getClassroomAssignments(result)[0];
        const classroomAssignmentSettings = classroomAssignment?.settings;
        const dueDate =
          studentSettings?.due_date ||
          classroomAssignmentSettings?.due_date ||
          assignment?.settings?.due_date;

        return <div>{renderMoment(dueDate, 'dateFormal')}</div>;
      },
      exportHeaders: () => ['Due Date'],
      export: (result) => {
        const assignment = getAssignment(result);
        const studentAssignments =
          result.assignments_student_assignments ||
          (result.gradebook_student_assignment ? [result.gradebook_student_assignment] : []);

        let studentSettings: any = null;
        if (studentAssignments.length === 1) {
          studentSettings = studentAssignments[0].settings;
        }

        // classroom_assignments are sorted by due_date desc by the backend,
        // so the first one *is* the most recent due date
        const classroomAssignment = getClassroomAssignments(result)[0];
        const classroomAssignmentSettings = classroomAssignment?.settings;
        const dueDate =
          studentSettings?.due_date ||
          classroomAssignmentSettings?.due_date ||
          assignment?.settings?.due_date;
        return [renderMoment(dueDate, 'dateFormal')];
      },
      isToggleable: true
    },
    assignmentSubmittedColumn,
    {
      columnType: 'dimension',
      dimension: 'assignments',
      key: 'assignments.assignment_actions',
      sortKey: null,
      title: 'Actions',
      renderColumn: (result, _) => {
        const {pathname} = history.getCurrentLocation();
        const isAllClassesTab = pathname.includes('/reports/teachers/');
        return (
          <div>
            <AssignmentActions result={result} isAllClassesTab={isAllClassesTab} />
          </div>
        );
      },
      afterMetrics: true
    }
  ],
  students: [
    {
      columnType: 'dimension',
      primaryColumn: true,
      dimension: 'students',
      key: 'students.name',
      title: 'Name',
      sortKey: 'students.last_name',
      renderColumn: (data) => {
        const {getStudentFullName} = useContext(ReportsContext);
        const {student} = data;

        return <div>{getStudentFullName(student)}</div>;
      },
      exportHeaders: () => ['First Name', 'Last Name'],
      export: (result) => {
        const {student} = result;
        return [student?.first_name, student?.last_name];
      }
    },
    {
      columnType: 'dimension',
      dimension: 'students',
      key: 'students.email',
      title: 'Email',
      sortKey: 'students.email',
      renderColumn: (data) => {
        const {student} = data;
        return <span>{student?.email}</span>;
      },
      exportHeaders: () => ['Email'],
      export: (data) => {
        const {student} = data;
        return [student?.email];
      },
      isToggleable: true
    },
    assignmentSubmittedColumn
  ],
  standards: [
    {
      columnType: 'dimension',
      primaryColumn: true,
      dimension: 'standards',
      key: 'standards.title',
      title: 'Standard',
      sortKey: 'standards.title',
      renderColumn: (data) => {
        const {standard} = data;

        const iconButtonRef = useRef(null);

        return (
          <span className='u-width_100pc u-display_flex u-justify-content_space-between u-align-items_center'>
            <span className='u-mar-r_2'>{standard?.title}</span>

            <WithToggle>
              {({on, onClick}) => (
                <>
                  <WithTooltip content='Show Description' placement='top' enabled={!on}>
                    <IconButton
                      className='u-mar-r_1 reports-table__column-button'
                      label='Description'
                      icon='info-circle'
                      iconStyle='regular'
                      size='s'
                      variant='outlined'
                      color='primary'
                      onClick={onClick}
                      ref={iconButtonRef}
                    />
                  </WithTooltip>
                  {on && (
                    <Popover targetRef={iconButtonRef} position='top'>
                      <MarkdownRendererV2 text={standard?.description} />
                    </Popover>
                  )}
                </>
              )}
            </WithToggle>
          </span>
        );
      },
      exportHeaders: () => ['Standard'],
      export: (data) => {
        const {standard} = data;
        return [standard?.title];
      }
    },
    {
      columnType: 'dimension',
      dimension: 'standards',
      key: 'standards.standard_set',
      title: 'Standard Set',
      sortKey: 'standard_sets.title',
      renderColumn: (data) => {
        const {standard} = data;
        return <div>{standard?.standard_set?.title}</div>;
      },
      exportHeaders: () => ['Standard Set'],
      export: (data) => {
        const {standard} = data;
        return [standard?.standard_set?.title];
      },
      isToggleable: true
    },
    {
      columnType: 'dimension',
      dimension: 'standards',
      key: 'standards.standardsActions',
      sortKey: null,
      title: 'Actions',
      renderColumn: (result) => {
        return <StandardsActions result={result} />;
      },
      afterMetrics: true
    }
  ],
  questions: [
    {
      columnType: 'dimension',
      dimension: 'questions',
      primaryColumn: true,
      key: 'questions.title',
      title: 'Name',
      sortKey: 'questions.title',
      renderColumn: (data) => {
        const {question} = data;
        return <MarkdownRendererV2 text={question?.title} />;
      },
      exportHeaders: () => ['Name'],
      export: (data) => {
        const {question} = data;
        return [question?.title];
      }
    },
    {
      columnType: 'dimension',
      dimension: 'questions',
      key: 'questions.difficulty',
      title: 'Difficulty',
      sortKey: 'questions.difficulty',
      renderColumn: (data) => <div>{getQuestionDifficulty(data)}</div>,
      exportHeaders: () => ['Difficulty'],
      export: (data) => [getQuestionDifficulty(data)],
      isToggleable: true
    },
    {
      columnType: 'dimension',
      dimension: 'questions',
      key: 'questions.question_actions',
      sortKey: null,
      title: 'Actions',
      renderColumn: (result) => {
        return <QuestionActions result={result} />;
      },
      afterMetrics: true
    }
  ],
  gradebook: [assignmentSubmittedColumn]
};

export const columnIsAvailable = (
  column: DimensionColumn | MetricColumn,
  dimensions: Dimension[],
  filters: QueryVariables,
  topLevelFilterType: TopLevelFilter
) => {
  return column.isAvailable ? column.isAvailable(dimensions, filters, topLevelFilterType) : true;
};

/*
  NOTE this function should ONLY be called when
  - the dimensions are ['students', 'assignments'] OR
  - the dimensions are ['students'] AND there is an assignmentFilter OR
  - the dimensions are ['assignments'] AND there is a studentFilter
*/
export const getStudentAssignment = (result: AnalyticsApiResult) => {
  return (
    // gradebook
    result.gradebook_student_assignment ||
    // students report w/ assignment filter
    result.students_student_assignments?.[0] ||
    // assignments report w/ student filter
    result.assignments_student_assignments?.[0]
  );
};

export const getAssignment = (result: AnalyticsApiResult) => {
  return (
    result.assignment ||
    result.secondary_assignment ||
    result.students_student_assignments?.[0].assignment
  );
};

export const getClassroomAssignments = (result: AnalyticsApiResult) => {
  return (
    result.assignment?.classroom_assignments ??
    (result.students_student_assignments?.length === 1
      ? result.students_student_assignments?.[0]?.classroom_assignments
      : null) ??
    result.gradebook_student_assignment?.classroom_assignments ??
    []
  );
};

export const getIncludes = (dimensions: Dimension[], filters: QueryVariables): string => {
  const includes: string[] = [];

  if (isEqual(dimensions, ['assignments'])) {
    includes.push('assignment.classroom_assignments.classroom');
    if (filters.studentFilter) {
      includes.push('assignments_student_assignments.student');
    }
  }
  if (isEqual(dimensions, ['students'])) {
    includes.push('student');
    if (filters.assignmentFilter) {
      includes.push(
        'students_student_assignments.assignment',
        'students_student_assignments.classroom_assignments.classroom'
      );
    }
  }
  if (isEqual(dimensions, ['questions'])) {
    includes.push('question.question_set.subject');
  }
  if (isEqual(dimensions, ['standards'])) {
    includes.push('standard.standard_set');
  }
  if (isEqual(dimensions, ['students', 'assignments'])) {
    includes.push(
      'student',
      'secondary_assignment',
      'gradebook_student_assignment.classroom_assignments.classroom'
    );
  }
  if (isEqual(dimensions, ['schools'])) {
    includes.push('school');
  }
  if (isEqual(dimensions, ['classrooms'])) {
    includes.push('classroom');
  }
  if (isEqual(dimensions, ['subjects'])) {
    includes.push('subject');
  }
  if (isEqual(dimensions, ['teachers'])) {
    includes.push('teacher');
  }

  return includes.join(',');
};

const getAnswerBreakdownMetrics = (dimensions: Dimension[], filters: QueryVariables) => {
  const metrics = ['count_correct_attempts', 'count_incorrect_attempts', 'count_attempts'];

  // Locations where unattempted shows up:
  // The students dimension if a subject or guide level or assignment filter is present.
  // The questions dimension always.
  // The assignments dimension always.
  if (
    (isEqual(dimensions, ['students']) &&
      (!!filters.subjectFilter || !!filters.guideLevelFilter || !!filters.assignmentFilter)) ||
    isEqual(dimensions, ['questions']) ||
    isEqual(dimensions, ['assignments']) ||
    isEqual(dimensions, ['students', 'assignments'])
  ) {
    metrics.push('count_unattempted', 'total_possible_attempts', 'percent_complete');
  }

  return metrics;
};

const getAccuracy = (
  result: AnalyticsApiResult,
  dimensions: Dimension[],
  filters: QueryVariables
) => {
  const datum = result.metrics;
  if (!datum.has_guesses) return 'N/A';
  let value = '';
  if (
    (isEqual(dimensions, ['students']) && filters.questionFilter) ||
    (isEqual(dimensions, ['questions']) && filters.studentFilter)
  ) {
    value = datum.accuracy === 1 ? 'Correct' : 'Incorrect';
  } else {
    value = `${Math.round((datum?.accuracy || 0) * 100)}%`;
  }

  return value;
};

/*
  note on naming:
  a "metric" and a "metric column" are NOT the same thing.
  A "metric" is the individual field of data we ask the back end for. In some cases, this
  corresponds 1:1 with a metric column, but not always. For example, the "Accuracy" column
  maps directly to the accuracy metric, but the "Answer Breakdown" column
  is a metric column that maps to 3 or 4 metrics depending on context.

  Basically everything displayed in the UI maps to a metric column - including the table columns
  themselves and the items in the metric selector dropdown. Each metric column can choose which
  metrics it wants to load.
*/
const METRIC_COLUMNS: MetricColumn[] = [
  {
    columnType: 'metric',
    initialSortDirection: 'desc',
    metricType: 'renderedTitle',
    key: 'count_teachers',
    sortKey: 'metrics.count_teachers',
    isAvailable: (dimensions, filters) => {
      return (
        isEqual(dimensions, ['schools']) ||
        isEqual(dimensions, ['classrooms']) ||
        (isEqual(dimensions, ['subjects']) && !filters.teacherFilter && !filters.classroomFilter) ||
        (isEqual(dimensions, ['students']) && !filters.teacherFilter)
      );
    },
    renderTitle: () => {
      return (
        <>
          <b>Teachers Count</b>
        </>
      );
    },
    getMetricsToLoad: () => ['count_teachers'],
    renderColumn: (data) => {
      if (!data.metrics.count_teachers) return <div>N/A</div>;
      return <div>{data.metrics.count_teachers}</div>;
    },
    exportHeaders: () => ['Teachers Count'],
    export: (data) => {
      if (!data.metrics.count_teachers) return ['N/A'];
      return [data.metrics.count_teachers];
    }
  },
  {
    columnType: 'metric',
    initialSortDirection: 'desc',
    metricType: 'renderedTitle',
    key: 'count_classrooms',
    sortKey: 'metrics.count_classrooms',
    isAvailable: (dimensions, filters) => {
      return (
        isEqual(dimensions, ['schools']) ||
        isEqual(dimensions, ['teachers']) ||
        (isEqual(dimensions, ['subjects']) && !filters.classroomFilter) ||
        (isEqual(dimensions, ['assignments']) && !filters.classroomFilter) ||
        (isEqual(dimensions, ['students']) && !filters.classroomFilter)
      );
    },
    renderTitle: () => {
      return (
        <>
          <b>Classrooms Count</b>
        </>
      );
    },
    getMetricsToLoad: () => ['count_classrooms'],
    renderColumn: (data) => {
      if (!data.metrics.count_classrooms) return <div>N/A</div>;
      return <div>{data.metrics.count_classrooms}</div>;
    },
    exportHeaders: () => ['Classrooms Count'],
    export: (data) => {
      if (!data.metrics.count_classrooms) return ['N/A'];
      return [data.metrics.count_classrooms];
    }
  },
  {
    columnType: 'metric',
    initialSortDirection: 'desc',
    metricType: 'renderedTitle',
    key: 'count_students',
    sortKey: 'metrics.count_students',
    isAvailable: (dimensions, filters) => {
      return (
        isEqual(dimensions, ['schools']) ||
        isEqual(dimensions, ['classrooms']) ||
        isEqual(dimensions, ['teachers']) ||
        isEqual(dimensions, ['subjects']) ||
        (isEqual(dimensions, ['assignments']) && !filters.studentFilter)
      );
    },
    renderTitle: () => {
      return (
        <>
          <b>Students Count</b>
        </>
      );
    },
    getMetricsToLoad: () => ['count_students'],
    renderColumn: (data) => {
      if (!data.metrics.count_students) return <div>N/A</div>;
      return <div>{data.metrics.count_students}</div>;
    },
    exportHeaders: () => ['Students Count'],
    export: (data) => {
      if (!data.metrics.count_students) return ['N/A'];
      return [data.metrics.count_students];
    }
  },
  {
    columnType: 'metric',
    initialSortDirection: 'desc',
    metricType: 'renderedTitle',
    key: 'time_spent',
    sortKey: 'metrics.avg_time_spent_per_student',
    renderTitle: ({
      dimensions,
      variables
    }: {
      dimensions: Dimension[];
      variables: QueryVariables;
    }) => {
      return (
        <>
          <b>Time Spent</b>{' '}
          {!dimensions.includes('students') && !variables.studentFilter ? (
            <Text color='secondary' size='s'>
              (avg per student)
            </Text>
          ) : null}
        </>
      );
    },
    getMetricsToLoad: () => ['avg_time_spent_per_student'],
    renderColumn: (data) => {
      if (!data.metrics.has_guesses) return <div>N/A</div>;
      return (
        <div>
          {renderDuration(moment.duration(data.metrics.avg_time_spent_per_student || 0), {
            style: 'hh:mm:ss'
          })}
        </div>
      );
    },
    exportHeaders: (dimensions) =>
      isEqual(dimensions, ['students']) || isEqual(dimensions, ['questions'])
        ? ['Time Spent (avg per student)']
        : ['Time Spent'],
    export: (data) => {
      if (!data.metrics.has_guesses) return ['N/A'];
      return [
        renderDuration(moment.duration(data.metrics.avg_time_spent_per_student || 0), {
          style: 'hh:mm:ss'
        })
      ];
    }
  },
  {
    columnType: 'metric',
    metricType: 'renderedTitle',
    key: 'performance',
    sortKey: null,
    renderTitle: () => {
      const {performanceCalculateBy} = useContext(ReportsContext);

      if (performanceCalculateBy === 'accuracy') {
        return <b>Accuracy</b>;
      }
      return <b>Grade</b>;
    },
    isAvailable: (dimensions, filters) => {
      return !(
        (isEqual(dimensions, ['students']) && filters.questionFilter) ||
        (isEqual(dimensions, ['questions']) && filters.studentFilter)
      );
    },
    getMetricsToLoad: (_dimensions, _filters, {performanceCalculateBy}) => [
      performanceCalculateBy === 'accuracy' ? 'accuracy' : 'grade',
      'count_attempts'
    ],
    renderColumn: (data, dimensions, filters) => {
      return <PerformanceColumn result={data} dimensions={dimensions} filters={filters} />;
    },
    exportHeaders: (_dimensions, _filters, {performanceCalculateBy}) =>
      performanceCalculateBy === 'accuracy' ? ['Accuracy'] : ['Grade'],
    export: (data, dimensions, filters, {performanceCalculateBy}) => {
      if (performanceCalculateBy === 'accuracy') {
        const accuracy = getAccuracy(data, dimensions, filters);

        return [accuracy];
      }

      const {grade} = data.metrics;

      return [`${Math.round(grade * 100)}%`];
    }
  },
  {
    columnType: 'metric',
    metricType: 'renderedTitle',
    key: 'answer_breakdown',
    // special case - there is special sort logic for this column handled in HeaderRenderer.tsx
    // in the AnswerBreakdownHeaderCell component
    sortKey: null,
    renderTitle: () => {
      const {variables, dimensions} = useContext(ReportsContext);

      if (
        (isEqual(dimensions, ['students']) && variables.questionFilter) ||
        (isEqual(dimensions, ['questions']) && variables.studentFilter)
      ) {
        return <b>Answer</b>;
      }

      return <div className='u-text-wrap_nowrap'>Answer Breakdown</div>;
    },
    getMetricsToLoad: getAnswerBreakdownMetrics,
    renderColumn: (data) => {
      return <AnswerBreakdown result={data} />;
    },
    exportHeaders: (dimensions, filters) => {
      const metrics = getAnswerBreakdownMetrics(dimensions, filters);

      return metrics.map(
        (m) =>
          ({
            count_correct_attempts: 'Correct Attempts',
            count_incorrect_attempts: 'Incorrect Attempts',
            count_attempts: 'Total Attempts',
            count_unattempted: 'Unanswered'
          }[m]!)
      );
    },
    export: (data, dimensions, filters) => {
      const metricsToExport = getAnswerBreakdownMetrics(dimensions, filters);
      const hasGuesses = data.metrics.has_guesses;

      const correct = hasGuesses ? data.metrics.count_correct_attempts : 'N/A';
      const incorrect = hasGuesses ? data.metrics.count_incorrect_attempts : 'N/A';
      const total = hasGuesses ? data.metrics.count_attempts : 'N/A';
      const unattempted = data.metrics.count_unattempted ?? 'N/A';

      return metricsToExport.map(
        (m) =>
          ({
            count_correct_attempts: `${correct}`,
            count_incorrect_attempts: `${incorrect}`,
            count_attempts: `${total}`,
            count_unattempted: `${unattempted}`
          }[m]!)
      );
    }
  }
];

const getClassroomQuery = (classId) =>
  resource('classroom_v1').mandarkEndpoint(['classrooms_v1', classId]).include('school_v5');

function getClassroomsQuery() {
  return resource('classrooms_v1')
    .mandarkEndpoint(['teachers_v1', masqueradeStore.getUserIdByMasqueradeState(), 'classrooms_v1'])
    .sort('name')
    .pageSize('250');
}

const getTeacherQuery = (teacherId) =>
  resource('teachers_v1').mandarkEndpoint(['teachers_v1', teacherId]).include('school_v5');

const getSchoolQuery = (schoolId: string) =>
  resource('schools_v5')
    .mandarkEndpoint(['schools_v5', schoolId])
    .include('school_licenses_v1.upgrades_v1');

const getSchoolsQuery = (teacherId) =>
  resource('school_v5')
    .mandarkEndpoint(['schools_v5'])
    .filter({
      school_personnel_v1: {
        teacher_v1: {
          id: teacherId
        },
        role_v1: {
          name: {
            not: 'teacher'
          }
        },
        status: 'approved'
      }
    })
    .sort('name')
    .withMeta('school_v5');

const getDistrictQuery = (districtId: string) =>
  resource('districts_v1').mandarkEndpoint(['districts_v1', districtId]);

function getClassroomDatesQuery(classroomId) {
  return resource('classroom_v1').mandarkEndpoint(['classrooms_v1', classroomId]).fields({
    classroom_v1: 'begin_date,end_date'
  });
}

function getSubjectQuery(subjectId) {
  return resource('subjects_v2')
    .mandarkEndpoint(['subjects_v2', subjectId])
    .include('guides_v1.guide_levels_v2')
    .withMeta('guide_v1');
}

async function getSubjectsQuery(subjectIds) {
  if (!subjectIds || subjectIds.length === 0) {
    return OrderedMap();
  }
  const subjectIdBatches: string[][] = [];
  // 20 subjects seem to be the happy medium to prevent the request header from being too large while not needing too many calls
  for (let i = 0; i < subjectIds?.length; i += 20) {
    subjectIdBatches.push(subjectIds.slice(i, i + 20));
  }

  const batchResults = await Promise.all(
    subjectIdBatches.map((batch) =>
      resource('subjects_v2')
        .mandarkEndpoint(['subjects_v2'])
        .filter({
          any_of: batch.map((id) => ({
            id
          })),
          guides_v1: {
            id: {
              null: false
            }
          },
          included: {
            guides_v1: {}
          }
        })
        .include('guides_v1.guide_levels_v2')
        .withMeta('guide_v1')
        .getResourcePromise()
    )
  );

  const allSubjects = batchResults
    .reduce((acc, list) => acc.concat(list))
    .sort((a, b) => {
      return a.get('name').localeCompare(b.get('name'));
    });
  return allSubjects;
}

function getClassroomSubjectsQuery(classroomId) {
  return resource('subjects_v2')
    .mandarkEndpoint(['classrooms_v1', classroomId, 'subjects_v2'])
    .include('guides_v1.guide_levels_v2')
    .filter({
      guides_v1: {
        id: {
          null: false
        }
      },
      included: {
        guides_v1: {}
      }
    })
    .withMeta('guide_v1');
}

function isDimension(str: string | Dimension): str is Dimension {
  return allDimensions.includes(str);
}

function isTopLevelFilter(str: string): str is TopLevelFilter {
  return TOP_LEVEL_FILTERS.includes(str);
}

function isDrillingDown(path: (string | Dimension)[]): boolean {
  return path.filter(isDimension).length >= 2;
}

function isFinalDimension(topLevelFilterType, pathname: string): boolean {
  const validRemainingDrilldownDimensions = getValidRemainingDrilldownDimensionsForURL(
    topLevelFilterType,
    pathname
  );
  return validRemainingDrilldownDimensions.length === 0;
}

function removeMetrics({metricsToRemove, existingMetrics}) {
  const updatedMetrics = existingMetrics.filter((m) => !metricsToRemove.includes(m));
  pushQueryParams({metrics: updatedMetrics.join(',')});
}

function getGuideFromGuideLevelId(subject, guideLevelId) {
  let guides = subject.getGuides();

  while (guides.size) {
    const currentGuide = guides.first();

    if (currentGuide.getId() === guideLevelId) {
      return currentGuide;
    }

    const match = currentGuide
      .getGuideLevels()
      .getCollection()
      .find((gl) => gl.getId() === guideLevelId);

    if (!match) {
      guides = guides.shift();
    } else {
      return currentGuide;
    }
  }
  return null;
}

const getArchivedDateRange = (archivedClassroom: ClassroomModelV1) => {
  const archivedYear = archivedClassroom.getAutoArchiveDate().utc().format('Y');
  // July 1 of year before
  const start = `${archivedYear - 1}-07-01`;
  // June 30 of archived year
  const end = `${archivedYear}-06-30`;

  const lastSchoolYear = getLastSchoolYear();
  const thisSchoolYear = getThisSchoolYear();

  const isLastSchoolYear =
    lastSchoolYear.get('start').isSame(start) && lastSchoolYear.get('end').isSame(end);
  const isThisSchoolYear =
    thisSchoolYear.get('start').isSame(start) && thisSchoolYear.get('end').isSame(end);

  let range: DateRange = RANGES.CUSTOM;
  if (isLastSchoolYear) {
    range = RANGES.LAST_SCHOOL_YEAR;
  } else if (isThisSchoolYear) {
    range = RANGES.THIS_SCHOOL_YEAR;
  }
  return {start, end, range};
};

// max/min range due date column only renders for assignments on `All Classes` filter and not scoped to a student
const shouldRenderDueDateRangeCol = (
  topLevelFilterType: TopLevelFilter,
  filters: QueryVariables
): boolean => {
  const isAllClasses = topLevelFilterType !== 'classrooms';
  const isNotScopedToStudent = !filters.studentFilter;
  return isAllClasses && isNotScopedToStudent;
};

/*
 returns due date range
 if only one classroom assignment, or due dates all fall on the same date, return only that
 */
const getDueDateRangeFromClassroomAssignments = (
  classroomAssignments: {
    settings: any;
  }[]
): string | null => {
  const dueDates = classroomAssignments.reduce<string[]>((acc, assignment) => {
    // handle tz offset and extract only the yyyy-mm-dd
    if (assignment?.settings?.due_date) {
      const assignmentDueDate = moment(assignment.settings.due_date).tz(moment.tz.guess());
      const formattedDate = assignmentDueDate.format('YYYY-MM-DD');
      acc.push(formattedDate);
    }
    return acc;
  }, []);
  if (!dueDates.length) {
    return null;
  }
  const uniqueDueDates = Array.from(new Set(dueDates)).sort();
  if (uniqueDueDates.length === 1) {
    const dueDate = renderMoment(uniqueDueDates[0], 'dateFormal');
    return dueDate;
  }
  const minDueDate = renderMoment(uniqueDueDates[0], 'dateFormal');
  const maxDueDate = renderMoment(uniqueDueDates[uniqueDueDates.length - 1], 'dateFormal');
  return `${minDueDate} - ${maxDueDate}`;
};

const getResourceFromDimension = async (dimension: Dimension, id: string) => {
  let queryData;
  switch (dimension) {
    case 'assignments':
      queryData = await resource('assignments_v3')
        .mandarkEndpoint(['assignments_v3', id])
        .getResourcePromise();
      break;
    case 'questions':
      queryData = await resource('questions_v3')
        .mandarkEndpoint(['questions_v3', id])
        .getResourcePromise();
      break;
    case 'standards':
      queryData = await resource('standards_v1')
        .mandarkEndpoint(['standards_v1', id])
        .getResourcePromise();
      break;
    case 'students':
      queryData = await resource('students_v2')
        .mandarkEndpoint(['students_v2', id])
        .getResourcePromise();
      break;
    case 'subjects':
      queryData = await resource('subjects_v2')
        .mandarkEndpoint(['subjects_v2', id])
        .getResourcePromise();
      break;
    case 'teachers':
      queryData = await getTeacherQuery(id).getResourcePromise();
      break;
    case 'schools':
      queryData = await getSchoolQuery(id).getResourcePromise();
      break;
    case 'classrooms':
      queryData = await getClassroomQuery(id).getResourcePromise();
      break;
    default:
  }
  return queryData;
};

const isValidUUID = (maybeId: string | undefined): boolean => {
  if (!maybeId) {
    return false;
  }
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidRegex.test(maybeId);
};

const removeTrailingSlash = (url: string): string => {
  return url.replace(/\/$/, '');
};

const getGradebookRowLimitFromLocalStorage = () => {
  return localStorage.getItem('gradebook:rowLimit');
};

const getReportRowLimitFromLocalStorage = () => {
  return localStorage.getItem('report:rowLimit');
};

function getQuestionDifficulty(data: AnalyticsApiResult) {
  const {question} = data;
  const difficulty = {
    1: 'Easy',
    2: 'Moderate',
    3: 'Difficult'
  }[question?.difficulty];
  return difficulty;
}

function getAvailableColumns<T extends DimensionColumn | MetricColumn>(
  columns: T[],
  dimensionOrDimensions: Dimension | Dimension[],
  variables: QueryVariables,
  topLevelFilterType: TopLevelFilter
) {
  const normalizedDimensions = Array.isArray(dimensionOrDimensions)
    ? dimensionOrDimensions
    : [dimensionOrDimensions];

  return columns.filter((c) =>
    columnIsAvailable(c, normalizedDimensions, variables, topLevelFilterType)
  );
}

function sortColumns(dimensionColumns: DimensionColumn[], metricColumns: MetricColumn[]) {
  return [
    ...dimensionColumns.filter(({afterMetrics}) => !afterMetrics),
    ...metricColumns,
    ...dimensionColumns.filter(({afterMetrics}) => afterMetrics)
  ];
}

function isColumnSoftToggleable(column: Column) {
  if (column.columnType === 'metric' || !column.isToggleable) {
    return false;
  }

  if (!column.isToggleable) {
    return false;
  }

  return column.toggleableBehavior === 'soft';
}

function getObfuscatedNameString(length: number) {
  return '*'.repeat(length);
}

const pollForReportCompletion = async (report: any, attempt: number = 0) => {
  if (report.status === 'complete') {
    return report;
  }

  const response = await genericMandarkRequest('get', {
    resourcePath: ['json', 'analytics', 'reports', report.id],
    customQuery: {
      fields: {
        report: 'completed_at,count_primary_dimension,count_secondary_dimension,status'
      }
    }
  });

  if (response.get('status') === 'complete') {
    return response.toJS();
  }

  // Poll every 500ms for the first 5 attempts, then add 500ms for each additional attempt, maxing out at 15s
  // i.e. 500, 500, 500, 500, 500, 1000, 1500, 2000, 2500, 3000, ...
  const delay = Math.min(attempt < 5 ? 500 : (attempt - 4) * 500, 15000);
  await new Promise((resolve) => setTimeout(resolve, delay));

  return pollForReportCompletion(response.toJS(), attempt + 1);
};

async function createReport(dimensions: string[], filters: Filter[], metrics: string[]) {
  return genericMandarkRequest(
    'post',
    {
      resourcePath: ['json', 'analytics', 'reports'],
      customQuery: {
        fields: {
          report: 'completed_at,count_primary_dimension,count_secondary_dimension,status'
        }
      }
    },
    {
      data: {
        type: 'report',
        attributes: {
          dimensions,
          filters,
          metrics
        }
      }
    }
  );
}

async function getReportResults(
  reportId: string,
  include: string,
  pagination: Pagination,
  sorts: string
) {
  return genericMandarkRequest('get', {
    resourcePath: ['json', 'analytics', 'reports', reportId, 'results'],
    customQuery: {
      pagination,
      sorts,
      include
    }
  });
}

export {
  getGuideFromGuideLevelId,
  allDimensions,
  getClassroomDatesQuery,
  getClassroomQuery,
  getClassroomsQuery,
  getTeacherQuery,
  getSchoolQuery,
  getSchoolsQuery,
  getDistrictQuery,
  getSubjectQuery,
  getSubjectsQuery,
  getClassroomSubjectsQuery,
  removeMetrics,
  isDimension,
  isTopLevelFilter,
  isDrillingDown,
  isFinalDimension,
  getValidRemainingDrilldownDimensionsForURL,
  DIMENSIONS,
  IS_REPORTS_BLOCKED_FOR_NON_PRO_ADMINS,
  IS_CLASSROOM_REPORTS_BLOCKED_FOR_NON_PRO_ADMINS_MAP,
  IS_SCHOOL_REPORTS_BLOCKED_FOR_NON_PRO_ADMINS_MAP,
  IS_SCHOOL_ADMIN_REPORTS_BLOCKED_FOR_NON_PRO_ADMINS_MAP,
  getArchivedDateRange,
  METRIC_COLUMNS,
  DIMENSION_COLUMNS,
  getDueDateRangeFromClassroomAssignments,
  getResourceFromDimension,
  DIMENSION_CONFIG,
  isValidUUID,
  removeTrailingSlash,
  processReportPath,
  getGradebookRowLimitFromLocalStorage,
  getReportRowLimitFromLocalStorage,
  getAvailableColumns,
  sortColumns,
  isColumnSoftToggleable,
  getObfuscatedNameString,
  pollForReportCompletion,
  createReport,
  getReportResults
};
