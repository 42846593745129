import React, {useMemo, useCallback} from 'react';
import {Map} from 'immutable';
import {callAction} from 'client/framework';

import {getQuestionTypeDefinition} from 'client/QuestionTypes/QuestionTypeDefinitions';
import {AuthoringQuestionModelV1} from 'resources/augmented/AuthoringQuestion/AuthoringQuestionModel.v1';
import FeatureFlag from 'client/components/FeatureFlag/FeatureFlag.react';

import questionEditorStore from '../QuestionEditor/QuestionEditor.store';
import questionEditorActions from '../QuestionEditor/QuestionEditor.actions';

import QuestionTypeManagerV1 from './QuestionTypeManagerV1.react';

interface QuestionTypeManagerProps {
  onBlur: (e: any) => void;
  question: AuthoringQuestionModelV1;
  onModelChange: (model: any, ...methodsToValidate: any[]) => void;
  updateQuestion: (updateFunc: any) => void;
  validationErrors: Map<any, any>;
}

const QuestionTypeManager = (props: QuestionTypeManagerProps) => {
  const Editor = useMemo(() => {
    const type = getQuestionTypeDefinition(props.question.getType());
    return type.get('editor');
  }, [props.question]);
  return (
    <div>
      <Editor {...props} />
    </div>
  );
};

type QuestionAnswerSectionProps = Omit<
  QuestionTypeManagerProps,
  'onModelChange' | 'updateQuestion' | 'onBlur'
>;

const QuestionAnswerSection = (props: QuestionAnswerSectionProps | QuestionTypeManagerProps) => {
  const handleQuestionTypeChange = useCallback((model, ...methodsToValidate) => {
    callAction(questionEditorActions.UPDATE_QUESTION, model);
    if (methodsToValidate.length > 0) {
      methodsToValidate.forEach((method) => {
        callAction(questionEditorActions.VALIDATE_QUESTION_FIELD, method);
      });
    }
  }, []);

  const handleUpdateQuestion = useCallback((updateFunc) => {
    const question = questionEditorStore.getQuestion();
    const updatedQuestion = updateFunc(question);
    callAction(questionEditorActions.UPDATE_QUESTION, updatedQuestion);
  }, []);

  const handleBlur = (e) => {
    const {dataset, selectionStart, selectionEnd} = e.target;
    const name = dataset.fieldPath || e.target.name; // exists on Editor
    callAction(questionEditorActions.SAVE_CURSOR_POSITION, {
      end: selectionEnd,
      name,
      start: selectionStart
    });
  };

  return (
    <FeatureFlag name='question_editor_v2'>
      <div className='question-editor__question-type'>
        <QuestionTypeManager
          {...props}
          onModelChange={handleQuestionTypeChange}
          updateQuestion={handleUpdateQuestion}
          onBlur={handleBlur}
        />
      </div>
      <FeatureFlag.Fallback>
        <QuestionTypeManagerV1 {...props} />
      </FeatureFlag.Fallback>
    </FeatureFlag>
  );
};

export default QuestionAnswerSection;
