import React, {lazy} from 'react';
import {Route, IndexRoute} from 'react-router';
import {getFeatureFlagStatus} from 'client/components/FeatureFlag/FeatureFlag.react';

const AssignmentBuilderLayoutWithRequiredSession = lazy(() =>
  import('./AssignmentBuilderLayout.react').then((module) => ({
    default: module.AssignmentBuilderLayoutWithRequiredSession
  }))
);

const AssignmentSettings = lazy(() =>
  import('./AssignmentSettings/AssignmentSettings.react').then((module) => ({
    default: module.AssignmentSettings
  }))
);

const AssignmentSharing = lazy(() =>
  import('./Document/AssignmentSharing/AssignmentSharingV2.react').then((module) => ({
    default: module.AssignmentSharing
  }))
);

const DocumentContentDiscovery = lazy(() =>
  import('./Document/DocumentContentDiscovery/DocumentContentDiscovery.react')
);

const ReviewQuestions = lazy(() => import('./ViewAssignment/ViewAssignment.react'));

const requireOpenAssignmentsFeatureFlag = async (_, replace, cb) => {
  const isABOpenAssignmentsEnabled = await getFeatureFlagStatus('ab_view_assignments');
  if (!isABOpenAssignmentsEnabled) {
    replace('/assignment-builder/create-assignment');
  }
  cb();
};

const ContentDiscoveryRoutes = (
  <Route
    path='/assignment-builder/create-assignment'
    component={AssignmentBuilderLayoutWithRequiredSession}
  >
    <IndexRoute component={DocumentContentDiscovery} />
    {/* Create routes */}
    <Route path='settings' component={AssignmentSettings} />
    <Route path='classroom/:classroomId/settings' component={AssignmentSettings} />
    <Route
      path='classroom/:classroomId/student/:studentId/settings'
      component={AssignmentSettings}
    />
    <Route path='confirm' component={AssignmentSharing} />
    {/* Edit routes */}
    <Route path='folder/:folderId' component={DocumentContentDiscovery} />
    <Route
      path=':assignmentId'
      component={ReviewQuestions}
      onEnter={requireOpenAssignmentsFeatureFlag}
    />
    <Route path=':assignmentId/settings' component={AssignmentSettings} />
    <Route path=':assignmentId/classroom/:classroomId/settings' component={AssignmentSettings} />
    <Route
      path=':assignmentId/classroom/:classroomId/student/:studentId/settings'
      component={AssignmentSettings}
    />
    <Route path=':assignmentId/confirm' component={AssignmentSharing} />
  </Route>
);

export default ContentDiscoveryRoutes;
