import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ClipboardJS from 'clipboard';
import {v4 as uuid} from 'uuid';
import {addToast} from '@albert-io/atomic';
import TextInput from 'generic/Forms/TextInput/TextInput';
import Button from 'sg/Button/Button.react';

import Tooltip from '../Tooltip/Tooltip.react';

import './copy-to-clipboard.scss';

export const CONTAINER_CLASS = 'copy-to-clipboard';

export default class CopyToClipboard extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    inline: PropTypes.bool,
    className: PropTypes.string,
    button: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    display: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
    showToastOnSuccess: PropTypes.bool,
    toastMessage: PropTypes.string,
    disabledTooltipContent: PropTypes.node,
    tooltipPosition: Tooltip.propTypes.position,
    disabled: PropTypes.bool,
    label: PropTypes.bool
  };

  static defaultProps = {
    label: false,
    inline: false,
    button: 'Copy',
    showToastOnSuccess: false,
    toastMessage: 'Copied!'
  };

  constructor() {
    super();
    const uniqueId = uuid();
    this.actionElementId = `copy-to-clipboard-action-${uniqueId}`;
    this.clipboard = null;
    this.container = null;
  }

  componentDidMount() {
    this.clipboard = new ClipboardJS(`#${this.actionElementId}`, {
      container: this.container,
      text: () => this.props.value
    });
    if (this.props.showToastOnSuccess) {
      this.clipboard.on('success', () => {
        addToast({
          message: this.props.toastMessage,
          title: 'Copied!',
          color: 'positive'
        });
      });
    }
  }

  componentWillUnmount() {
    if (this.clipboard) {
      this.clipboard.destroy();
    }
  }

  generateDisplay() {
    if (this.props.display === false) {
      return null;
    }

    if (React.isValidElement(this.props.display)) {
      return React.cloneElement(this.props.display, {
        className: classnames('copy-to-clipboard__display', this.props.display.className)
      });
    }
    return (
      <TextInput
        name='copy-to-clipboard__display'
        className='copy-to-clipboard__display'
        value={this.props.value}
        readOnly
      />
    );
  }

  generateButton() {
    if (this.isSupported() === false) {
      return null;
    }

    const disabledProps = {
      disabledTooltipContent: this.props.disabledTooltipContent,
      tooltipPosition: this.props.tooltipPosition,
      disabled: this.props.disabled
    };

    if (React.isValidElement(this.props.button)) {
      return React.cloneElement(this.props.button, {
        id: this.actionElementId,
        ...disabledProps
      });
    }

    return (
      <Button
        {...disabledProps}
        id={this.actionElementId}
        className='copy-to-clipboard__button'
        color='green'
      >
        {this.props.button}
      </Button>
    );
  }

  isSupported() {
    return process.env.IS_BROWSER && ClipboardJS.isSupported();
  }

  render() {
    const classNames = classnames(
      CONTAINER_CLASS,
      {
        'copy-to-clipboard--inline': this.props.inline === true,
        'copy-to-clipboard--flex': this.props.inline === false
      },
      this.props.className
    );

    return (
      <div
        className={classNames}
        ref={(node) => {
          this.container = node;
        }}
      >
        {this.generateDisplay()}
        {this.generateButton()}
      </div>
    );
  }
}
