import React, {useState, useEffect} from 'react';
import {UserModelV2, StandardSetCreatorModelV1, CurriculumAreaModelV1} from '@albert-io/models';
import {resource} from '@albert-io/json-api-framework/request/builder';
import {invalidatePartialInterest} from 'resources/mandark.resource';
import awaitMandarkQueries from 'lib/hocs/awaitMandarkQueries';
import sessionStore from 'client/Session/SessionStore';
import {useIsFeatureFlagEnabled} from 'client/components/FeatureFlag/FeatureFlag.react';
import classnames from 'classnames';

import {
  Card,
  Text,
  Select,
  Button,
  Checkbox,
  Banner,
  addGenericErrorToast,
  LoadingSpinner,
  addToast,
  Heading
} from '@albert-io/atomic';

import {List} from 'immutable';

import {completeContentPersonalization} from 'lib/OnboardingUtil/OnboardingUtil';

import {getUserState} from './ContentPersonalization.util';
import './content-personalization.scss';

// default ids to preselect for users who are onboarding
const ONBOARDING_DEFAULT_CHECKED_IDS = [
  '96ffccd1-450f-43f7-a15c-160bd34f6348', // ACT® WorkKeys
  'fdc31a6c-0651-4c1f-82ae-4dfade4e6f48', // College Board (SAT®)
  'b274fd95-6d13-410b-b9c2-ca4515b693de', // ACT®
  'ae3994ee-2392-4469-98a7-37eccab9d5d1', // College Board (Pre-AP®)
  '8aa036cb-5b93-40e6-8b52-3de9d9305496' // College Board (AP®)
];

interface Props extends PropsWithClassNameRequired {
  user: UserModelV2;
  isOnboarding: boolean;
  onComplete: () => void; // for onboarding only, not used in settings menu. see ForceOnboarding.react.js
}

const allStandardSetCreatorsQuery = resource('standard_set_creators_v1')
  .mandarkEndpoint(['standard_set_creators_v1'])
  .pageSize(200);

const allCurriculumAreasQuery = resource('curriculum_areas_v1')
  .mandarkEndpoint(['curriculum_areas_v1'])
  .pageSize(200);

const ContentPersonalization = ({
  user,
  isOnboarding = false,
  onComplete: onOnboardingComplete = () => {},
  className
}: Props) => {
  const {
    isLoading: isCurriculumAreaFeatureFlagLoading,
    isFeatureEnabled: isCurriculumAreaFeatureFlagEnabled
  } = useIsFeatureFlagEnabled('curriculum_area_personalization');

  // all standard creators fetched from server to be displayed
  const [statePreferences, setStatePreferences] = useState<List<StandardSetCreatorModelV1>>(List());
  const [nationalPreferences, setNationalPreferences] = useState<List<StandardSetCreatorModelV1>>(
    List()
  );

  // all curriculum areas fetched from server to be displayed
  const [curriculumAreaPreferences, setCurriculumAreaPreferences] = useState<
    List<CurriculumAreaModelV1>
  >(List());

  // IDs of the actual standard creators the user has selected
  const [userNationalIds, setUserNationalIds] = useState<Set<string>>(
    isOnboarding
      ? List(ONBOARDING_DEFAULT_CHECKED_IDS).toSet()
      : user
          .getStandardSetCreators()
          .filter((preference) => preference.type === 'national' || preference.type === 'internal')
          .map((standardSetCreator) => standardSetCreator.getId())
          .toSet()
  );

  // IDs of actual curriculum areas the user has selected
  const [userCurriculumAreas, setUserCurriculumAreas] = useState<Set<string>>(
    user
      .getCurriculumAreas()
      .map((curriculumArea) => curriculumArea.getId())
      .toSet()
  );

  const [hasCurriculumAreaError, setHasCurriculumAreaError] = useState(false);

  // ID for the ONE state standard creator the user can select from the dropdown
  const [userStateId, setUserStateId] = useState<string>();

  // fetch all curriculum areas and standard set publishers on page load
  useEffect(() => {
    const fetchOptions = async () => {
      const [allStandardSetCreators, allCurriculumAreas] = await Promise.all([
        allStandardSetCreatorsQuery.getResourcePromise(),
        allCurriculumAreasQuery.getResourcePromise()
      ]);
      // handle grouping of standard preferences by state or national
      groupStandardSetCreators(allStandardSetCreators);
      setCurriculumAreaPreferences(allCurriculumAreas);
    };
    fetchOptions();
  }, []);

  // On the DB, national and state standard creators are stored the same way, in the UI,
  // we are splitting out the state standards into the dropdown, and the national standards
  // into the checkbox list.
  const groupStandardSetCreators = (standardSetCreators) => {
    const groupedStandardCreators = standardSetCreators.groupBy((creator) => creator.type);
    setNationalPreferences(
      /// with national preferences, we're including the internal Albert standards
      groupedStandardCreators
        .get('national')
        .concat(groupedStandardCreators.get('internal', List()))
        .sort()
    );
    // sort state standard creators before setting value so they show up in the dropdown correctly
    setStatePreferences(
      groupedStandardCreators.get('state').sortBy((preference) => preference.name)
    );
  };

  useEffect(() => {
    // when state preferences are loaded, set user state ID if the user has a state preference
    // IF the user is onboarding, load state from their associated school
    // otherwise fall back to N/A option (userStateId is undefined)
    const userStateName = getUserState(user, isOnboarding);
    if (userStateName) {
      const userState = statePreferences.find(
        (preference) => preference.getName() === userStateName
      );
      setUserStateId(userState?.getId());
    }
  }, [statePreferences, user, isOnboarding]);

  const handleSetStateId = (value) => {
    // value has to be n/a here or else the select will fall back to the text value of the option if set to null or undefined
    if (value === 'N/A') {
      setUserStateId(undefined);
    } else {
      setUserStateId(value);
    }
  };

  const saveUserPreferences = async () => {
    if (isCurriculumAreaFeatureFlagEnabled && userCurriculumAreas.size === 0) {
      setHasCurriculumAreaError(true);
      return;
    }
    setHasCurriculumAreaError(false);
    const preferencesToAdd = List(userNationalIds).toJS();
    if (userStateId) {
      preferencesToAdd.push(userStateId);
    }
    try {
      await user
        .replaceRelationship({type: 'standard_set_creators_v1', relation: preferencesToAdd})
        .replaceRelationship(
          {type: 'curriculum_areas_v1', relation: List(userCurriculumAreas).toJS()},
          isCurriculumAreaFeatureFlagEnabled
        )
        .save();
      addToast({
        color: 'positive',
        message: 'Content preferences updated'
      });
      invalidatePartialInterest({resourcePath: ['users_v2', sessionStore.getUserId()]});
      if (isOnboarding) {
        await completeContentPersonalization(user).save();
        onOnboardingComplete();
      }
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
      addGenericErrorToast();
    }
  };

  if (isCurriculumAreaFeatureFlagLoading) {
    return <LoadingSpinner className='u-mar-t_4' />;
  }

  // sort 'Other' option to the bottom of the preferences list
  const sortedCurriculumAreaPreferences = curriculumAreaPreferences.sort((preference) =>
    preference.getName() === 'Other' ? 1 : -1
  );

  return (
    <>
      {isOnboarding && (
        <div className='u-margin_auto u-mar-b_4 u-text-align_center'>
          <Text className='u-display_block' bold size='xl'>
            Verify Your Teacher Preferences
          </Text>
          <Text color='tertiary'>We&apos;ll help you find relevant content faster</Text>
        </div>
      )}
      {isCurriculumAreaFeatureFlagEnabled ? (
        <Card
          paddingLevel={isOnboarding ? 4 : 0}
          border={isOnboarding ? 'regular' : 'none'}
          className={classnames({
            'content-personalization-body__onboarding': isOnboarding
          })}
        >
          {!isOnboarding && (
            <>
              <div className='u-mar-t_8'>
                <Heading className='u-mar-b_1'>Content Preferences</Heading>
                <Text color='tertiary'>
                  We&apos;ll use this information to surface only the most relevant standards and
                  subjects.
                </Text>
              </div>
              <hr className='content-personalization__section-divider' />
            </>
          )}
          <PersonalizationSection
            title='Region'
            description='Your location will let us more accurately suggest standards'
          >
            <>
              <Text className='u-display_block u-mar-b_2 u-mar-t_2'>
                In which state do you teach?
              </Text>
              <Select
                value={userStateId}
                onChange={(e) => handleSetStateId(e.target.value)}
                data-testid='personalization__standard-state-dropdown'
              >
                <option key='N/A' value='N/A'>
                  None / Not applicable
                </option>
                {statePreferences.map((preference) => (
                  <option key={preference.getName()} value={preference.getId()}>
                    {preference.getName()}
                  </option>
                ))}
              </Select>
            </>
          </PersonalizationSection>
          <PersonalizationSection title='Standard Groups' description='Select any that you teach.'>
            <div className='u-display_flex u-flex-direction_column u-gap_2'>
              {nationalPreferences.map((preference) => (
                <div className='u-display_flex u-mar-b_1' key={preference.getId()}>
                  <Checkbox
                    size='s'
                    className='u-mar-r_2 personalization__standard-publisher-checkbox'
                    data-testid={`personalization__national-checkbox__${preference.getName()}`}
                    label={preference.getName()}
                    checked={userNationalIds.has(preference.getId())}
                    onChange={() =>
                      setUserNationalIds(
                        (prevIds) =>
                          (prevIds.has(preference.getId())
                            ? prevIds.delete(preference.getId())
                            : prevIds.add(preference.getId())) as Set<string>
                      )
                    }
                  />
                  <Text>{preference.getName()}</Text>
                </div>
              ))}
            </div>
          </PersonalizationSection>
          <PersonalizationSection
            title='Curriculum Areas'
            hr={!isOnboarding}
            description={
              <>
                Select one or more options.
                {user.isSchoolAdministrator() && (
                  <Text italic color='tertiary'>
                    {' '}
                    For admins, we recommend selecting all curriculum areas.
                  </Text>
                )}
              </>
            }
          >
            {hasCurriculumAreaError && <CurriculumAreaErrorBanner />}
            <div className='u-display_flex u-flex-direction_column u-gap_2'>
              {sortedCurriculumAreaPreferences.map((curriculumArea) => (
                <div className='u-display_flex u-mar-b_1' key={curriculumArea.getId()}>
                  <Checkbox
                    size='s'
                    className='u-mar-r_2 personalization__standard-publisher-checkbox'
                    data-testid={`personalization__curriculum-areas-checkbox__${curriculumArea.getName()}`}
                    label={curriculumArea.getName()}
                    checked={userCurriculumAreas.has(curriculumArea.getId())}
                    onChange={() =>
                      setUserCurriculumAreas(
                        (prevIds) =>
                          (prevIds.has(curriculumArea.getId())
                            ? prevIds.delete(curriculumArea.getId())
                            : prevIds.add(curriculumArea.getId())) as Set<string>
                      )
                    }
                  />
                  <Text>
                    {curriculumArea.getName()}
                    {curriculumArea.getName() === 'Other' && ' (Not listed)'}
                  </Text>
                </div>
              ))}
            </div>
          </PersonalizationSection>
        </Card>
      ) : (
        // ---------------------------- BEGIN curriculum_area_personalization fallback ----------------------------
        <Card
          paddingLevel={5}
          className={classnames(className, {
            'content-personalization-body__onboarding': isOnboarding
          })}
        >
          <>
            <Text className='u-display_block u-mar-b_2 u-mar-t_4'>
              In which state do you teach?
            </Text>
            <Select
              value={userStateId}
              onChange={(e) => handleSetStateId(e.target.value)}
              data-testid='personalization__standard-state-dropdown'
            >
              <option key='N/A' value='N/A'>
                None / Not applicable
              </option>
              {statePreferences.map((preference) => (
                <option key={preference.getName()} value={preference.getId()}>
                  {preference.getName()}
                </option>
              ))}
            </Select>
          </>
          <Text className='u-display_block u-mar-t_4 u-mar-b_2'>Select any that you teach:</Text>
          {nationalPreferences.map((preference) => (
            <div className='u-mar-b_1' key={preference.getId()}>
              <Checkbox
                className='u-mar-r_2 personalization__standard-publisher-checkbox'
                data-testid={`personalization__national-checkbox__${preference.getName()}`}
                label={preference.getName()}
                checked={userNationalIds.has(preference.getId())}
                onChange={() =>
                  setUserNationalIds(
                    (prevIds) =>
                      (prevIds.has(preference.getId())
                        ? prevIds.delete(preference.getId())
                        : prevIds.add(preference.getId())) as Set<string>
                  )
                }
              />
              <Text>{preference.getName()}</Text>
            </div>
          ))}
        </Card>
        // ---------------------------- END curriculum_area_personalization fallback logic ----------------------------
      )}
      <div
        className={classnames(
          'u-display_flex u-justify-content_flex-end',
          {
            'content-personalization-body__onboarding': isOnboarding
          },
          'u-mar-t_4'
        )}
      >
        <Button
          data-testid='personalization__submit-changes-btn'
          onClick={saveUserPreferences}
          className={classnames('u-mar-b_4', {
            'content-peronsalization-button__onboarding': isOnboarding
          })}
        >
          Save Preferences
        </Button>
      </div>
    </>
  );
};

interface PersonalizationSectionProps extends PropsWithChildrenRequired {
  title: string;
  description: React.ReactNode;
  hr?: boolean;
}
const PersonalizationSection = ({
  title,
  description,
  hr = true,
  children
}: PersonalizationSectionProps) => (
  <>
    <section className='content-personalization__section'>
      <div className='content-personalization__section-description'>
        <Text bold className='u-align-self_stretch u-mar-b_1'>
          {title}
        </Text>
        <Text className='u-align-self_stretch' color='tertiary'>
          {description}
        </Text>
      </div>
      <div className='content-personalization__section-content'>{children}</div>
    </section>
    {hr && <hr className='content-personalization__section-divider' />}
  </>
);

const CurriculumAreaErrorBanner = () => (
  <Banner
    type='negative'
    className='u-mar-b_2'
    data-testid='content-personalization__curriculum-area-error-banner'
  >
    <Banner.Icon icon={['fal', 'triangle-exclamation']} />
    Select at least one option.
  </Banner>
);

export default awaitMandarkQueries(
  () => ({
    queries: {
      user: resource('users_v2')
        .mandarkEndpoint(['users_v2', sessionStore.getUserId()])
        .include('standard_set_creators_v1,curriculum_areas_v1,school_v5')
    }
  }),
  ContentPersonalization
);
