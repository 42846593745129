import {merge, get, set} from 'lodash';

function addAttributes(val, attributes) {
  return merge(val, {
    data: {
      attributes: attributes
    }
  });
}

function addManyToOneRelationship(val, relationshipName, type, id, meta) {
  // Many to one relationships are always to be single values and NOT lists.
  return merge(val, {
    data: {
      relationships: {
        [relationshipName]: {
          data: {
            type: type,
            id: id,
            meta: meta
          }
        }
      }
    }
  });
}

function addOneToOneRelationship(val, relationshipName, type, id, meta) {
  // Many to one relationships are always to be single values and NOT lists.
  return merge(val, {
    data: {
      relationships: {
        [relationshipName]: {
          data: {
            type: type,
            id: id,
            meta: meta
          }
        }
      }
    }
  });
}

function addOneToManyRelationship(val, relationshipName, type, id, meta) {
  const data = [];

  data.push({
    type,
    id,
    meta
  });

  return merge(val, {
    data: {
      relationships: {
        [relationshipName]: {
          data
        }
      }
    }
  });
}

function addManyToManyRelationship(val, relationshipName, type, id, meta) {
  // LHS defaulting to empty list - these are to be concatenated and spat back out.
  // These relationships are ALWAYS to be lists.
  const currentValRelationships = get(val, ['data', 'relationships', relationshipName, 'data'], []);
  const newRelationship = {
    type: type,
    id: id,
    meta: meta
  };

  // modifies the currentValRelationships list in place
  currentValRelationships.push(newRelationship);
  return set(val, ['data', 'relationships', relationshipName, 'data'], currentValRelationships);
}

function addManyToManyRelationships(val, versions, relationships) {
  // We use our versions map to place the correct relationship versions, ignoring misses.
  return relationships.reduce((acc, relationship) => {
    const type = relationship.get('type');
    const meta = relationship.get('meta', {});
    const version = versions.get(type);
    const relationshipName = versions.getIn(['relationshipNames', type]);

    if (!version) {
      console.error(`API resource version mapping not found for ${type}`);
      return acc;
    } else if (!relationshipName) {
      console.error(`API relationshipName mapping not found for ${type}`);
      return acc;
    } else {
      return addManyToManyRelationship(
        val,
        relationshipName,
        version,
        relationship.get('id'),
        meta
      );
    }
  }, val);
}

export function buildPost(val) {
  if (typeof val === 'string') {
    val = {
      data: {
        type: val
      }
    };
  }

  return {
    addAttributes: function(attributes) {
      val = addAttributes(val, attributes);
      return buildPost(val);
    },
    addManyToManyRelationships(versions, relationships) {
      val = addManyToManyRelationships(val, versions, relationships);
      return buildPost(val);
    },
    addManyToOneRelationship(relationshipName, type, id, meta = {}) {
      val = addManyToOneRelationship(val, relationshipName, type, id, meta);
      return buildPost(val);
    },
    addOneToOneRelationship(relationshipName, type, id, meta = {}) {
      val = addOneToOneRelationship(val, relationshipName, type, id, meta);
      return buildPost(val);
    },
    addOneToManyRelationship(relationshipName, type, id, meta = {}) {
      val = addOneToManyRelationship(val, relationshipName, type, id, meta);
      return buildPost(val);
    },
    payload() {
      return val;
    }
  };
}

export function buildPatch(val) {
  if (typeof val === 'string') {
    val = {
      data: {
        type: val
      }
    };
  }

  return {
    addAttributes: function(attributes) {
      val = addAttributes(val, attributes);
      return buildPatch(val);
    },
    addManyToManyRelationships(versions, relationships) {
      val = addManyToManyRelationships(val, versions, relationships);
      return buildPatch(val);
    },
    addManyToOneRelationship(relationshipName, type, id, meta = {}) {
      val = addManyToOneRelationship(val, relationshipName, type, id, meta);
      return buildPatch(val);
    },
    addOneToOneRelationship(relationshipName, type, id, meta = {}) {
      val = addOneToOneRelationship(val, relationshipName, type, id, meta);
      return buildPost(val);
    },
    payload() {
      return val;
    }
  };
}
