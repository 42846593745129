// @flow
import {Store} from 'client/framework';
import {fromJS} from 'immutable';
import supplementManagerActions from './SupplementManager.actions';
import type {Supplement as SupplementModelV1} from 'resources/GeneratedModels/Supplement/SupplementModel.v1';

class SupplementManagerStore extends Store {
  constructor(name: string) {
    super(name);
    this.initialData = fromJS({
      activeSupplement: {},
      activeSubject: {},
      currentEditorField: '',
      showModal: false
    });

    this.setInitialData(this.initialData);
    this.handle(supplementManagerActions.RESET_STORE, this.resetStore);
    this.handle(
      supplementManagerActions.SET_ACTIVE_SUPPLEMENT,
      this.setProperty('activeSupplement')
    );
    this.handle(supplementManagerActions.SET_ACTIVE_SUBJECT, this.setProperty('activeSubject'));
    this.handle(
      supplementManagerActions.SET_CURRENT_EDITOR_FIELD,
      this.setProperty('currentEditorField')
    );
    this.handle(supplementManagerActions.SHOW_MODAL, this.setProperty('showModal'));
  }

  getActiveSupplement(): SupplementModelV1 {
    return this.readData('activeSupplement');
  }

  getActiveSubject(): string {
    return this.readData('activeSubject');
  }

  getCurrentEditorField(): string {
    return this.readData('currentEditorField');
  }

  showModal(): boolean {
    return this.readData('showModal');
  }
}

export default new SupplementManagerStore('SupplementManagerStore');
