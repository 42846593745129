import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import makeDataProps from 'lib/makeDataProps';
import './checkbox-toggle.scss';

export default class CheckboxToggle extends React.PureComponent {
  static propTypes = {
    activeText: PropTypes.string,
    checked: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    inactiveText: PropTypes.string,
    label: PropTypes.string.isRequired,
    labelDataProps: PropTypes.object,
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  };

  static defaultProps = {
    activeText: '',
    className: '',
    disabled: false,
    inactiveText: '',
    labelDataProps: {},
    name: '',
    onChange: () => {},
    value: ''
  };

  render() {
    const dataProps = makeDataProps(this.props);
    const labelDataProps = makeDataProps(this.props.labelDataProps);
    const checkboxToggleClasses = classnames(this.props.className, {
      'sg-checkbox-toggle': true,
      'sg-checkbox-toggle--checked': this.props.checked,
      'sg-checkbox-toggle--disabled': this.props.disabled
    });
    return (
      <label className={checkboxToggleClasses} {...labelDataProps}>
        <span className='sg-checkbox-toggle__label'>{this.props.label}</span>
        <div className='sg-checkbox-toggle__toggle-wrapper'>
          <input
            {...dataProps}
            checked={this.props.checked}
            disabled={this.props.disabled}
            name={this.props.name}
            onChange={this.props.onChange}
            type='checkbox'
            value={this.props.value}
          />
          <span
            className='sg-checkbox-toggle__toggle'
            data-active-text={this.props.activeText}
            data-inactive-text={this.props.inactiveText}
          />
        </div>
      </label>
    );
  }
}
