import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {addToast} from '@albert-io/atomic';
import {callAction} from 'client/framework';
import interactionEngineStore from 'client/InteractionEngineV2/InteractionEngineStore';

import QuestionsList from 'client/InteractionEngineV2/shared/QuestionsList/QuestionsList.react';
import questionsListActions from 'client/InteractionEngineV2/shared/QuestionsList/QuestionsListActions';

import {QuestionSet as QuestionSetModelV1} from 'resources/GeneratedModels/QuestionSet/QuestionSetModel.v1';

import teacherAssignmentQuestionsListActions from './TeacherAssignmentQuestionsList.actions';
import teacherAssignmentQuestionsListStore from './TeacherAssignmentQuestionsList.store';

import './teacher-assignment-questions-list.scss';

export default class TeacherAssignmentQuestionsList extends React.Component {
  UNSAFE_componentWillMount() {
    this.setInitialQuestionSets();
    this.setInitialActiveQuestion();
  }

  componentDidUpdate() {
    this.updateActiveQuestion();
    this.createToastNotification();
  }

  setInitialQuestionSets() {
    // This writes our questions from Mandark to the teacher's 'copiedQuestionSets'
    callAction(teacherAssignmentQuestionsListActions.SET_INITIAL_QUESTION_SETS);
  }

  async setInitialActiveQuestion() {
    // This ensures the ActiveQuestion component will not render without setting up our store first
    await teacherAssignmentQuestionsListStore.getAssignmentQuery().getResourcePromise();
    if (teacherAssignmentQuestionsListStore.hasQuestionSets()) {
      const firstCopiedSetAndQuestion =
        teacherAssignmentQuestionsListStore.getFirstCopiedSetAndQuestion();
      callAction(
        questionsListActions.SET_ACTIVE_QUESTION,
        firstCopiedSetAndQuestion.get('question')
      );
      callAction(
        questionsListActions.SET_ACTIVE_QUESTION_SET,
        firstCopiedSetAndQuestion.get('questionSet')
      );
    }
  }

  updateActiveQuestion() {
    if (
      teacherAssignmentQuestionsListStore.hasQuestionSets() &&
      !teacherAssignmentQuestionsListStore.hasActiveQuestion &&
      interactionEngineStore.areStoresBootstrapped
    ) {
      const firstSetAndQuestionObj =
        teacherAssignmentQuestionsListStore.getFirstCopiedSetAndQuestion();
      callAction(questionsListActions.SET_ACTIVE_QUESTION, firstSetAndQuestionObj.get('question'));
      callAction(
        questionsListActions.SET_ACTIVE_QUESTION_SET,
        firstSetAndQuestionObj.get('questionSet')
      );
    }
  }

  createToastNotification() {
    if (teacherAssignmentQuestionsListStore.errorMessage) {
      addToast({
        message: teacherAssignmentQuestionsListStore.errorMessage,
        title: 'Something went wrong',
        color: 'negative'
      });
      callAction(teacherAssignmentQuestionsListActions.CLEAR_ERROR_MESSAGE);
    } else if (teacherAssignmentQuestionsListStore.successMessage) {
      addToast({
        message: teacherAssignmentQuestionsListStore.successMessage,
        title: 'Success!',
        color: 'positive'
      });
      callAction(teacherAssignmentQuestionsListActions.CLEAR_SUCCESS_MESSAGE);
    }
  }

  setIsPendingRemovalClassName(questionSet) {
    return teacherAssignmentQuestionsListStore.questionSetsPendingRemoval.contains(questionSet)
      ? 'question-set-pending-removal'
      : '';
  }

  onSortEnd({oldIndex, newIndex}) {
    callAction(teacherAssignmentQuestionsListActions.SORT_QUESTION_SETS, {oldIndex, newIndex});
  }

  render() {
    return (
      <div>
        <QuestionsList
          sortable
          onSortEnd={this.onSortEnd}
          questionsListStore={teacherAssignmentQuestionsListStore}
          questionSets={teacherAssignmentQuestionsListStore.copiedQuestionSets}
          questionSetWidget={<ToggleQuestionSetRemoval />}
          singleQuestionWidget={null}
          questionSetClassName={(questionSet) => this.setIsPendingRemovalClassName(questionSet)}
        />
      </div>
    );
  }
}

class ToggleQuestionSetRemoval extends React.Component {
  static propTypes = {
    questionSet: PropTypes.instanceOf(QuestionSetModelV1)
  };

  toggleQuestionSetRemoval(e) {
    e.stopPropagation();
    callAction(
      teacherAssignmentQuestionsListActions.TOGGLE_QUESTION_SET_REMOVAL,
      this.props.questionSet
    );
  }

  render() {
    const isPendingRemoval =
      teacherAssignmentQuestionsListStore.questionSetsPendingRemoval.includes(
        this.props.questionSet
      );
    const toggleRemovalClassNames = classnames('fa toggle-question-set-removal', {
      'fa-trash': !isPendingRemoval,
      'fa-plus-circle': isPendingRemoval
    });
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions
      <span className={toggleRemovalClassNames} onClick={(e) => this.toggleQuestionSetRemoval(e)} />
    );
  }
}
