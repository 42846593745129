/**
 * # SplitCard
 *
 * A component that represents a split card layout, with sections and a wrapper.
 *
 * ## Props
 *
 * - `className?: string`: Additional CSS class name for the component.
 * - `roundness?: number`: The roundness of the card corners.
 * - `paddingLevel?: number`: The padding level of the card sections.
 * - `children?: React.ReactNode`: The content of the card.
 *
 * ### `SplitCard.Section` Props (inherits from `Card<T>`)
 *
 * - `as` (optional): The HTML element or React component to be used as the wrapper element. Defaults to `'div'`.
 * - `backgroundColor` (optional): The background color of the card. Defaults to `'white'`.
 * - `border` (optional): The type of border for the card. Can be `'none'`, `'regular'`, or `'darker'`. Defaults to `'regular'`.
 * - `className` (optional): Additional CSS class names for the card.
 * - `roundness` (optional): The roundness of the card corners. Can be `'small'`, `'medium'`, `'large'`, `'pill'`, or `'none'`. Defaults to `'small'`.
 * - `shadow` (optional): The shadow intensity of the card. Can be `'closest'`, `'close'`, `'default'`, `'far'`, or `'farthest'`.
 * - `paddingLevel` (optional): The padding level of the card. Can be a number or `null`. Defaults to `0`.
 * - `children` (optional): The content of the card.
 * - `...rest: T`: Any valid prop that can be passed to the component passed with the `as` prop.
 *
 * ## Usage
 *
 * ```tsx
 * import SplitCard from './SplitCard.react';
 *
 * const MyComponent = () => {
 *   return (
 *     <SplitCard>
 *       <SplitCard.Section>
 *         Section 1
 *       </SplitCard.Section>
 *       <SplitCard.Section>
 *         Section 2
 *       </SplitCard.Section>
 *     </SplitCard>
 *   );
 * };
 * ```
 */

import React, {forwardRef} from 'react';
import classnames from 'classnames';

import Card, {Props as CardProps} from '../../atoms/Card/Card.react';
import './split-card.scss';

const Section = forwardRef(
  <C extends React.ElementType>({className, paddingLevel = 2, ...rest}: CardProps<C>, ref) => (
    <Card
      {...rest}
      paddingLevel={paddingLevel}
      className={classnames('split-card__section', className)}
      shadow={undefined}
      ref={ref}
    />
  )
);

type ChildProps = {
  roundness: 'small' | 'medium' | 'large' | 'pill' | 'none';
};

type SplitCardComponent = React.ForwardRefExoticComponent<CardProps<React.ElementType>> &
  React.RefAttributes<unknown> & {Section: typeof Section};

const SplitCard: SplitCardComponent = Object.assign(
  forwardRef(
    <C extends React.ElementType>({children, className, roundness, ...rest}: CardProps<C>, ref) => (
      <Card
        className={classnames('split-card__wrapper', className)}
        roundness={roundness}
        {...rest}
        ref={ref}
      >
        {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              roundness
            } as ChildProps);
          }
          return null;
        })}
      </Card>
    )
  ),
  {Section}
);

export default SplitCard;
