import React from 'react';

import {useSlate} from 'slate-react';

import {IconButton} from '@albert-io/atomic';

import {MarkType} from '../../slate.types';

import {isMarkActive, toggleMark, withWorkaround, ButtonProps} from '../../Utils/editor.utils';

export const MarkButton = ({type, icon, ...optionalProps}: ButtonProps<MarkType>) => {
  const editor = useSlate();
  return (
    <IconButton
      icon={icon}
      variant={isMarkActive(editor, type) ? 'faded' : 'outlined'}
      color={isMarkActive(editor, type) ? 'primary' : 'secondary'}
      onClick={(event) => {
        event.preventDefault();
        withWorkaround(editor, () => toggleMark(editor, type));
      }}
      label={type}
      {...optionalProps}
    />
  );
};
