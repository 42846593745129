import React from 'react';
import PropTypes from 'prop-types';
import {Anchor, Banner, Button, Dialogue} from '@albert-io/atomic';
import constants from 'client/constants';

const GoogleCookiesWarning = ({onCancel, ...rest}) => (
  <Dialogue title='Error' {...rest}>
    <Dialogue.Body>
      <Banner type='negative'>
        <Banner.Icon icon='exclamation-triangle' />
        <div>
          <p>Cookies are disabled in your browser. To use your Google account, turn on cookies.</p>
          <p>
            If the problem persists, please contact&nbsp;
            <Anchor href={`mailto:${constants.CONTACT.EMAIL.SUPPORT}`} inheritColor underlined>
              {constants.CONTACT.EMAIL.SUPPORT}
            </Anchor>
            .
          </p>
        </div>
      </Banner>
    </Dialogue.Body>
    <Dialogue.BtnGroup>
      <Button color='secondary' onClick={onCancel}>
        Back
      </Button>
    </Dialogue.BtnGroup>
  </Dialogue>
);

GoogleCookiesWarning.propTypes = {
  onCancel: PropTypes.func
};

export default GoogleCookiesWarning;
