/**
 * # Toast
 *
 * A component that displays a toast notification with a title, message, and dismiss button.
 * The component also exposes an `addToast({color, title, message})` method which is more commonly used. It can be seen around many queries within the application.
 * ## Props
 *
 * - `duration` (number): The duration in milliseconds for the toast to be displayed. Default is 5000.
 * - `title` (string): The title of the toast.
 * - `color` (string): The color of the toast. Can be one of 'info', 'positive', 'negative', or 'warning'.
 * - `message` (string): The message content of the toast.
 * - `onDismissEnd` (function): A callback function triggered when the toast is dismissed.
 * - `aria-label` (string): The ARIA label for the toast.
 * - `permanent` (boolean): Determines if the toast is permanent and cannot be dismissed. Default is false.
 * - `shouldDismiss` (boolean): Determines if the toast should be dismissed.
 *
 * ## Usage
 *
 * ```jsx
 * import Toast from './Toast.react';
 *
 * function App() {
 *   return (
 *     <Button onClick={
 *        addToast({
 *          color: 'positive',
 *          title: 'Success!',
 *          message: 'hey you made a toast'
 *       });
 *      }>
 *       Toasty
 *     </Button>
 *   );
 * }
 * ```
 */
import React, {createRef, useState, useEffect, useCallback} from 'react';
import classnames from 'classnames';
import {v4 as uuid} from 'uuid';

import Text from '../../atoms/Text/Text.react';
import IconButton from '../IconButton/IconButton.react';
import Icon from '../../atoms/Icon/Icon.react';

import './toast.scss';

const iconStatusToIcon = {
  info: 'circle-info',
  positive: 'circle-check',
  negative: 'frown',
  warning: 'warning'
};

interface ToastProps {
  duration: number;
  title?: string;
  color: string;
  message?: string;
  onDismissEnd: () => void;
  'aria-label': string;
  permanent: boolean;
  shouldDismiss: boolean;
}

export const Toast = ({
  duration = 5000,
  title,
  color = 'info',
  message,
  onDismissEnd,
  'aria-label': ariaLabel,
  permanent = false,
  shouldDismiss
}: ToastProps) => {
  const [isDismissing, setIsDismissing] = useState(false);
  const [isDismissed, setIsDismissed] = useState(false);

  const wrapperRef = createRef<HTMLDivElement>();
  const idPrefix = uuid();

  const handleDismiss = useCallback(() => {
    if (isDismissing) {
      return;
    }
    setIsDismissing(true);
  }, []);

  const handleDismissEnd = useCallback(
    (e) => {
      if (!isDismissing || e.target !== e.currentTarget) {
        return;
      }

      setIsDismissed(true);
      if (onDismissEnd) {
        onDismissEnd();
      }
    },
    [isDismissing, onDismissEnd]
  );

  useEffect(() => {
    let timeOutId;

    if (!permanent && duration) {
      timeOutId = setTimeout(handleDismiss, duration);
    }

    const wrapper = wrapperRef.current;

    wrapper?.addEventListener('transitionend', handleDismissEnd);
    wrapper?.addEventListener('animationend', handleDismissEnd);

    return () => {
      clearTimeout(timeOutId);
      wrapper?.removeEventListener('transitionend', handleDismissEnd);
      wrapper?.removeEventListener('animationend', handleDismissEnd);
    };
  }, [duration, handleDismiss, handleDismissEnd, permanent, wrapperRef]);

  useEffect(() => {
    if (shouldDismiss) {
      handleDismiss();
    }
  }, [shouldDismiss, handleDismiss]);

  if (isDismissed) {
    return null;
  }

  const ariaWrapperProps = {};
  const titleId = `${idPrefix}label`;
  if (ariaLabel) {
    ariaWrapperProps['aria-label'] = ariaLabel;
  } else if (title) {
    ariaWrapperProps['aria-labelledby'] = titleId;
  }

  return (
    <div
      ref={wrapperRef}
      className={classnames(
        'm-toast elevation-background-l2 u-flex-direction_column u-display_inline-flex',
        {
          'm-toast--dismissing': isDismissing
        }
      )}
      data-testid='toast'
      aria-label={ariaLabel}
      role={color === 'negative' ? 'alert' : 'status'}
      {...ariaWrapperProps}
    >
      <div className={`m-toast__decorative-line background-${color}`} />
      <div className='u-display_flex'>
        <div className={`m-toast__icon-wrapper content-${color}`}>
          <Icon aria-hidden icon={['fal', iconStatusToIcon[color]]} className='m-toast__icon' />
        </div>
        <div className='m-toast__icon-divider' />
        <div className='m-toast__content-wrapper'>
          {title && (
            <Text bold as='p' size='m' className={`m-toast__title content-${color}`} id={titleId}>
              {title}
            </Text>
          )}
          <Text
            paragraph
            as='p'
            size='m'
            withMargin={false}
            className='m-toast__description content-primary'
            id={`${idPrefix}description`}
          >
            {message}
          </Text>
        </div>
        <div className='m-toast__dismiss-btn-wrapper'>
          <IconButton
            className='m-toast__dismiss-btn'
            variant='text'
            size='s'
            disabled={isDismissing}
            icon={['fal', 'times']}
            label='Dismiss banner'
            onClick={handleDismiss}
          />
        </div>
      </div>
    </div>
  );
};

export default Toast;
