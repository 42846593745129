import React from 'react';

import {
  QuestionComponent,
  QuestionComponentProps
} from '../shared/QuestionComponent/QuestionComponent.react';

import FreeEntryInputArea from './FreeEntryInputArea.react';
import './free-entry-question.scss';

const FreeEntryQuestionComponent = (props: QuestionComponentProps) => {
  return <QuestionComponent {...props} ActiveQuestionInputArea={FreeEntryInputArea} />;
};

type FreeEntryQuestionTypeProps = Omit<QuestionComponentProps, 'questionState'>;

const FreeEntryQuestion = {
  PreGuess: (props: FreeEntryQuestionTypeProps) => (
    <FreeEntryQuestionComponent {...props} questionState='PreGuess' />
  ),
  PostGuess: (props: FreeEntryQuestionTypeProps) => (
    <FreeEntryQuestionComponent {...props} questionState='PostGuess' />
  )
};

export {FreeEntryQuestion};
