import React from 'react';
import PropTypes from 'prop-types';
import {BasicTable} from '@albert-io/atomic';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {getModelForResourceType} from 'resources/modelRegistry';
import './standards-table.scss';

/**
 * @todo: We hacked this together a bit since the Table component enforces rules which
 * clash with how we want this to work. Design will be making us a modification ticket for
 * tables. Once that's implemented, we should roll this back to a more standard use of Table.
 */

const StandardsTable = ({question}) => {
  return question.getStandards().isEmpty() ? null : (
    <div className='standards-table'>
      <BasicTable>
        <BasicTable.Head>
          <BasicTable.Row>
            <BasicTable.Header>Standard Set</BasicTable.Header>
            <BasicTable.Header>Name</BasicTable.Header>
            <BasicTable.Header>Description</BasicTable.Header>
          </BasicTable.Row>
        </BasicTable.Head>
        <BasicTable.Body>
          {question.getStandards().map((item) => (
            <BasicTable.Row key={item.getId()}>
              <BasicTable.Cell>
                <MarkdownRendererV2 text={item.getStandardSet().getTitle()} />
              </BasicTable.Cell>
              <BasicTable.Cell>
                <MarkdownRendererV2 text={item.getTitle()} />
              </BasicTable.Cell>
              <BasicTable.Cell>
                <MarkdownRendererV2 text={item.getDescription()} />
              </BasicTable.Cell>
            </BasicTable.Row>
          ))}
        </BasicTable.Body>
      </BasicTable>
    </div>
  );
};

StandardsTable.propTypes = {
  question: PropTypes.instanceOf(getModelForResourceType('question_v3'))
};

/* eslint-disable import/prefer-default-export */
export {StandardsTable};
