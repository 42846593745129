// @flow
import {fromJS, Map} from 'immutable';
import {Store} from 'client/framework';
import {resource, type QueryBuilder} from '@albert-io/json-api-framework/request/builder';
import sessionStore from 'client/Session/SessionStore';
import {history} from 'client/history';

import masqueradeActions from './Masquerade.actions';

import type {SchoolModelV5} from 'resources/augmented/School/SchoolModel.v5';
import type {TeacherModelV1} from 'resources/augmented/Teacher/TeacherModel.v1';

export class MasqueradeStore extends Store {
  constructor(name: string) {
    super(name);
    this.initialData = fromJS({
      enabled: false,
      user: {},
      school: {},
      origin: null
    });
    this.setInitialData(this.initialData);
    this.handle(masqueradeActions.ENABLE_MASQUERADING, this.enableMasquerading);
    this.handle(masqueradeActions.DISABLE_MASQUERADING, this.disableMasquerading);
  }

  enableMasquerading({
    user,
    school,
    origin
  }: {
    user: Map<string, *>,
    school: Map<string, *>,
    origin: string
  }) {
    /**
     * To enable masquerading we now require both a user AND school, this ensures
     * we're accounting for `super` users and school administrators of multiple
     * schools... this also accounts for the fact the the `school` on the user
     * can be different than the school the teacher is approved for.
     */
    if (!user || !school) {
      throw Error('An error was encountered while attempting to masquerade.');
    }
    const update = Map({
      user,
      school,
      enabled: true,
      origin: origin || null
    });
    this.writeData((state) => state.merge(update));
  }

  disableMasquerading(options: {preventRedirect: boolean} = {preventRedirect: false}) {
    const origin = this.readData('origin');
    this.writeData(this.initialData);
    if (origin && options.preventRedirect === false) {
      setTimeout(() => {
        history.replace(origin, null);
      }, 0);
    }
  }

  isMasquerading(): boolean {
    return this.readData('enabled') === true;
  }

  /**
   * Returns a `user.id` based on the masquerade state (falling back to the session store).
   *
   * @returns {string|null} If the session is masquerading as a user, it'll return the masquerade `user.id`, if not it'll return `sessionStore.getUserId()`
   */
  getUserIdByMasqueradeState(): ?string {
    return this.isMasquerading() ? this.readData(['user', 'id']) : sessionStore.getUserId();
  }

  getUserQuery(): QueryBuilder {
    return resource('teacher_v1').mandarkEndpoint(['teachers_v1', this.readData(['user', 'id'])]);
  }

  getUser(): TeacherModelV1 | Map<string, *> {
    return this.getUserQuery().getResource();
  }

  getSchoolQuery(): QueryBuilder {
    return resource('school_v5').mandarkEndpoint(['schools_v5', this.getSchoolId()]);
  }

  getSchool(): SchoolModelV5 {
    return this.getSchoolQuery().getResource();
  }

  getSchoolId(): string | null {
    return this.readData(['school', 'id']);
  }
}

export default new MasqueradeStore('MasqueradeStore');
