import React from 'react';
import PropTypes from 'prop-types';

import {
  addToast,
  Button,
  ButtonGroup,
  Card,
  Heading,
  Text,
  Input,
  TextArea,
  Modal,
  Dialogue
} from '@albert-io/atomic';

import sessionStore from 'client/Session/SessionStore';

import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {SubjectFaqModelV1} from 'resources/GeneratedModels/SubjectFaq/SubjectFaqModel.v1';

export default class SubjectFAQItem extends React.Component {
  static propTypes = {
    subjectFAQ: PropTypes.instanceOf(SubjectFaqModelV1),
    isCreatingNew: PropTypes.bool,
    /**
     * `onSave` and `onDelete` exist to handle the tight coupling between
     * this component's state and the wrapping `<SubjectFAQList/>` state (Store).
     *
     * @todo `<SubjectFAQList>` can be refactored to use local state or manage operations removing
     * the need for these props.
     */
    onSave: PropTypes.func,
    onDelete: PropTypes.func
  };

  static defaultProps = {
    isCreatingNew: false,
    onSave: () => {},
    onDelete: () => {}
  };

  constructor(props) {
    super(props);

    this.state = {
      isEditMode: props.isCreatingNew,
      isDeleteModalVisible: false,
      question: props.subjectFAQ.getQuestion() || '',
      answer: props.subjectFAQ.getAnswer() || ''
    };
  }

  launchDeleteFAQModal = () => {
    this.setState({
      isDeleteModalVisible: true
    });
  };

  deleteFAQ = () => {
    if (this.props.isCreatingNew) {
      this.props.onDelete();
      return;
    }
    try {
      this.props.subjectFAQ.delete();
      addToast({
        color: 'positive',
        title: 'Subject FAQ has been deleted.',
        message: 'Subject FAQ has been deleted'
      });
      this.props.onDelete();
    } catch (e) {
      addToast({
        color: 'negative',
        title: 'Unable to delete FAQ.',
        message: 'Unable to delete FAQ.'
      });
    }
  };

  saveFAQ = async () => {
    let faq = this.props.subjectFAQ;
    faq = faq.setQuestion(this.state.question.trim()).setAnswer(this.state.answer.trim());

    const isNew = !faq.getId();
    try {
      await faq.save({
        customQuery: {include: 'subject_v2'},
        payloadMutator: (p) => {
          const payload = p;
          if (payload.data.attributes) {
            payload.data.attributes.question = faq.getQuestion();
          }
          return payload;
        }
      });
      this.setState({
        isEditMode: false
      });
      addToast({
        color: 'positive',
        title: 'Subject FAQ Saved!',
        message: `This FAQ has successfully been ${isNew ? 'added' : 'updated'}!`
      });
      this.props.onSave();
    } catch (e) {
      addToast({
        color: 'negative',
        title: 'Subject FAQ Save Failed!',
        message: 'Subject FAQ Save Failed.'
      });
    }
  };

  isNotValidFAQ = () => {
    /**
     * We allow answer-only FAQs based on legacy data.
     */
    const {answer} = this.state;
    return answer?.length === 0;
  };

  disableEditMode = () => {
    this.setState({
      isEditMode: false
    });
  };

  updateAnswer = (e) => {
    this.setState({
      answer: e.target.value
    });
  };

  updateQuestion = (e) => {
    this.setState({
      question: e.target.value
    });
  };

  getDeleteConfirmationModal() {
    if (this.state.isDeleteModalVisible === false) {
      return null;
    }

    const faq = this.props.subjectFAQ;
    const hide = () => this.setState({isDeleteModalVisible: false});
    const title = 'Delete FAQ';
    const message = this.props.isCreatingNew ? (
      'Are you sure you want to delete this FAQ without saving?'
    ) : (
      <>
        Are you sure you want to delete the question:{' '}
        <Text bold>&quot;{faq.getQuestion()}&quot;</Text>
      </>
    );

    return (
      <Modal ariaLabel={title} dismissable handleClose={hide} role='dialog'>
        {({CloseButtonWrapper, modalContentStyle}) => (
          <Dialogue className={modalContentStyle} handleClose={hide} inModal title={title}>
            <Dialogue.Body>{message}</Dialogue.Body>
            <Dialogue.BtnGroup>
              <CloseButtonWrapper>
                <Button color='secondary'>Cancel</Button>
              </CloseButtonWrapper>
              <Button color='negative' onClick={this.deleteFAQ} type='button'>
                Delete
              </Button>
            </Dialogue.BtnGroup>
          </Dialogue>
        )}
      </Modal>
    );
  }

  generateFAQReadFields = () => {
    const classNames = sessionStore.isSuper() ? 'u-cursor_pointer' : null;

    const faq = this.props.subjectFAQ;
    const {question, answer} = this.state;

    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
      <div
        id={faq.getId()}
        onClick={
          sessionStore.isSuper()
            ? () => {
                this.setState({
                  isEditMode: true
                });
              }
            : null
        }
      >
        {question && (
          <Heading as='h3' size='s' className={classNames}>
            {question}
          </Heading>
        )}
        <Text className={classNames}>
          <MarkdownRendererV2 text={answer} />
        </Text>
      </div>
    );
  };

  generateFAQEditFields() {
    return (
      <div>
        <Input
          type='text'
          autoComplete='off'
          placeholder='Question'
          name='question'
          value={this.state.question}
          onChange={this.updateQuestion}
          border
        />
        <TextArea
          autoComplete='off'
          placeholder='Answer'
          name='answer'
          value={this.state.answer}
          onChange={this.updateAnswer}
          border
          rows={15}
        />
      </div>
    );
  }

  generateFAQAdminButtons() {
    return (
      <ButtonGroup align='right'>
        {this.state.isEditMode ? (
          <Button
            size='s'
            color='secondary'
            onClick={this.disableEditMode}
            disabled={this.isNotValidFAQ()}
          >
            Preview
          </Button>
        ) : null}
        <Button size='s' color='negative' onClick={this.launchDeleteFAQModal}>
          Delete FAQ
        </Button>
        <Button size='s' color='secondary' onClick={this.saveFAQ} disabled={this.isNotValidFAQ()}>
          Save + Publish
        </Button>
        {this.getDeleteConfirmationModal()}
      </ButtonGroup>
    );
  }

  render() {
    return (
      <Card paddingLevel={2} className='u-mar-tb_2'>
        {sessionStore.isSuper() && this.state.isEditMode
          ? this.generateFAQEditFields()
          : this.generateFAQReadFields()}
        {sessionStore.isSuper() ? this.generateFAQAdminButtons() : null}
      </Card>
    );
  }
}
