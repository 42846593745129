import {useCallback, useEffect, useMemo, useReducer} from 'react';
import {
  DateRange,
  RANGES,
  getThisSchoolYear
} from '@albert-io/atomic/organisms/DatePicker/DatePicker.helpers';
import moment from 'moment-timezone';
import {removeQueryParams} from 'client/history';

import {
  DIMENSIONS,
  METRIC_COLUMNS,
  columnIsAvailable,
  createReport,
  getArchivedDateRange,
  getClassroomQuery,
  getIncludes,
  getReportResults,
  isDimension,
  processReportPath,
  getAvailableColumns,
  DIMENSION_COLUMNS,
  isColumnSoftToggleable,
  getObfuscatedNameString,
  pollForReportCompletion
} from 'client/Reports/reports.utils';
import sessionStore from 'client/Session/SessionStore';
import {CLASSROOM_STATUSES} from 'client/constants';

import {genericMandarkRequest} from 'resources/mandark.resource';
import {isEqual, uniqBy} from 'lodash';

import {
  AnalyticsApiResult,
  Dimension,
  MetricColumn,
  Pagination,
  QueryVariables,
  ReportTypes,
  Sort,
  SummaryStatsResponse,
  TopLevelFilter,
  Column,
  DimensionColumn
} from './reports.types';

const SECONDARY_PAGINATION_SIZE = 10;

export interface LocationProps {
  query: {
    assignmentId?: string; // @todo uuid type? also rm ID
    attempt?: string;
    end?: string;
    guideLevel?: string; // @todo uuid type?
    limit?: string;
    performanceCalculateBy?: 'accuracy' | 'grade';
    metrics?: string;
    page?: string;
    range?: DateRange;
    s?: string;
    sort?: string;
    start?: string;
    studentId?: string; // @todo uuid type?
    subject?: string; // @todo uuid type?
    standardSets?: string;
    personalization?: string;
    guide?: string;
    includeDraftGuesses?: string;
  };
  pathname: string;
}

type ActionTypes = typeof actionTypes;

export interface State {
  attemptNumber: number | 'mostRecent';
  topLevelFilterType: TopLevelFilter;
  topLevelFilterId: string;
  assignmentId: string;
  questionId: string;
  standardId: string;
  studentId: string;
  subjectId: string;
  schoolId: string;
  classroomId: string;
  teacherId: string;
  reportSelected: ReportTypes;
  data: AnalyticsApiResult[];
  dimensions: Dimension[];
  error: boolean;
  searchString?: string;
  loading: boolean;
  loadingMore: boolean;
  start: moment.Moment;
  end: moment.Moment;
  range: DateRange;
  subjectIdFromSearchParam: string;
  guideLevel: string[];
  limit: string;
  totals: number[];
  performanceCalculateBy: 'accuracy' | 'grade';
  metrics: MetricColumn[];
  page: string;
  path: string[];
  dimensionsInPath: string[];
  sort: Sort[];
  standardSets: string;
  autoPersonalization: boolean;
  variables: QueryVariables;
  report?: any;
  subjectReport?: any;
  reportSubjects: string[];
  cancelRequest: () => void;
  availableDimensionColumns: DimensionColumn[];
  dimensionColumnKeyToIsDimensionToggledOn: Map<string, boolean>;
  summaryStats?: SummaryStatsResponse;
  includeDraftGuesses: boolean;
}

export interface Action {
  type: ValueOf<ActionTypes>;
  payload: any;
}

export type DispatchActions = Record<keyof ActionTypes, (payload: any) => void>;

export interface Route {
  location: LocationProps;
}

export type ContextType = State &
  DispatchActions & {
    canFetchSummaryStats: () => boolean;
    getStudentFullName: (student: any) => string;
    isColumnEnabledOrSoftToggleable: (column: Column) => boolean;
    isColumnSoftDisabled: (column: Column) => boolean;
    query: LocationProps['query'];
    refreshReport: () => Promise<void>;
  };

const getMetricColumns = (columnKeys: string[]) => {
  const keySet = new Set(columnKeys);
  return METRIC_COLUMNS.filter((c) => keySet.has(c.key));
};

const buildMetricsRequest = (
  columns: MetricColumn[],
  dimensions: Dimension[],
  variables: QueryVariables,
  performanceCalculateBy: 'accuracy' | 'grade',
  topLevelFilterType: TopLevelFilter
) => {
  const metricsToLoad = Array.from(
    new Set(
      columns
        .filter((m) => columnIsAvailable(m, dimensions, variables, topLevelFilterType))
        .map((m) => m.getMetricsToLoad(dimensions, variables, {performanceCalculateBy}))
        .flat()
    )
  );

  metricsToLoad.push('has_guesses');

  return metricsToLoad;
};

const buildSorts = (sort: Sort[]) => {
  return sort.map(
    (sortItem) => `${sortItem.direction === 'asc' ? '' : '-'}${sortItem.type}.${sortItem.field}`
  );
};

// 20 x 20 = 400
const MAX_2D_PAGE_SIZE = 20;
const MAX_1D_PAGE_SIZE = 200;

const generatePaginations = (totals: number[]): Pagination[] => {
  if (totals.length === 1) {
    const [total] = totals;
    const pages = Math.ceil(total / MAX_1D_PAGE_SIZE);
    const paginations: Pagination[] = [];
    for (let i = 0; i < pages; i += 1) {
      paginations.push({
        primary_limit: MAX_1D_PAGE_SIZE,
        primary_offset: i * MAX_1D_PAGE_SIZE
      });
    }
    return paginations;
  }
  if (totals.length === 2) {
    const [primaryTotal, secondaryTotal] = totals;
    const pages1 = Math.ceil(primaryTotal / MAX_2D_PAGE_SIZE);
    const pages2 = Math.ceil(secondaryTotal / MAX_2D_PAGE_SIZE);
    const paginations: Pagination[] = [];
    for (let i = 0; i < pages1; i += 1) {
      for (let j = 0; j < pages2; j += 1) {
        paginations.push({
          primary_limit: MAX_2D_PAGE_SIZE,
          primary_offset: i * MAX_2D_PAGE_SIZE,
          secondary_limit: MAX_2D_PAGE_SIZE,
          secondary_offset: j * MAX_2D_PAGE_SIZE
        });
      }
    }
    return paginations;
  }
  return [];
};

export const fetchDataForCsv = async ({
  metrics,
  variables,
  dimensions,
  sort,
  performanceCalculateBy,
  topLevelFilterType
}: Pick<
  State,
  'variables' | 'dimensions' | 'sort' | 'metrics' | 'performanceCalculateBy' | 'topLevelFilterType'
>): Promise<AnalyticsApiResult[]> => {
  const filters = Object.values(variables).filter((item) => !!item);
  const metricsRequest = buildMetricsRequest(
    metrics,
    dimensions,
    variables,
    performanceCalculateBy,
    topLevelFilterType
  );

  const response = await genericMandarkRequest(
    'post',
    {
      resourcePath: ['json', 'analytics', 'reports'],
      customQuery: {
        fields: {
          report: 'completed_at,count_primary_dimension,count_secondary_dimension,status'
        }
      }
    },
    {
      data: {
        type: 'report',
        attributes: {
          dimensions,
          filters,
          metrics: metricsRequest
        }
      }
    }
  );
  const completedReport = await pollForReportCompletion(response.toJS());

  let paginations: Pagination[] = [];
  if (dimensions.length === 1) {
    paginations = generatePaginations([completedReport.count_primary_dimension]);
  } else {
    paginations = generatePaginations([
      completedReport.count_primary_dimension,
      completedReport.count_secondary_dimension
    ]);
  }

  const promises = paginations.map(async (pagination) => {
    const resultResponse = await genericMandarkRequest('get', {
      resourcePath: ['json', 'analytics', 'reports', response.get('id'), 'results'],
      customQuery: {
        pagination,
        sorts: buildSorts(sort).join(','),
        include: getIncludes(dimensions, variables)
      }
    });

    return resultResponse.toJS();
  });

  return (await Promise.all(promises)).flat();
};

export const getReportSummaryStats = async (
  {
    topLevelFilterType,
    dimensionsInPath,
    variables,
    dimensions
  }: Pick<State, 'topLevelFilterType' | 'dimensionsInPath' | 'variables' | 'dimensions'>,
  setSummaryStats: (payload: any) => void
) => {
  setSummaryStats(undefined);
  const isGradebook = dimensions.length === 2;
  const [dimensionInFocus] = isGradebook ? [topLevelFilterType] : dimensionsInPath.slice(-2, -1);

  const filters = Object.values(variables).filter((item) => item !== undefined);

  const summaryMetrics: Array<keyof SummaryStatsResponse> = [
    'accuracy',
    'avg_time_spent_per_student',
    'count_attempts',
    'count_mastery_students',
    'count_excelling_students',
    'count_proficient_students',
    'count_passing_students',
    'count_struggling_students',
    'count_not_started_students'
  ];

  const createSummaryStatsReportResponse = await createReport(
    [dimensionInFocus],
    filters,
    summaryMetrics
  );
  const completedReport = await pollForReportCompletion(createSummaryStatsReportResponse.toJS());
  const summaryStatsReport = await getReportResults(
    completedReport.id,
    getIncludes(dimensions, variables),
    {
      primary_limit: 1,
      primary_offset: 0
    },
    ''
  );

  let summaryStatsReportData = {
    accuracy: 0,
    avg_time_spent_per_student: 0,
    count_attempts: 0,
    count_excelling_students: 0,
    count_mastery_students: 0,
    count_not_started_students: 0,
    count_passing_students: 0,
    count_proficient_students: 0,
    count_struggling_students: 0
  };

  if (summaryStatsReport.size !== 0) {
    summaryStatsReportData = summaryStatsReport.first().get('metrics').toJS();
  }

  setSummaryStats(summaryStatsReportData);
};

export const decoratedFetchData = async (
  {
    data: prevData,
    fetchMore,
    metrics,
    variables,
    dimensions,
    limit,
    page,
    sort,
    performanceCalculateBy,
    cancelRequest,
    topLevelFilterType
  }: Pick<
    State,
    | 'data'
    | 'metrics'
    | 'variables'
    | 'dimensions'
    | 'limit'
    | 'page'
    | 'sort'
    | 'performanceCalculateBy'
    | 'cancelRequest'
    | 'topLevelFilterType'
  > & {fetchMore: boolean},
  setLoading: (payload: boolean) => void,
  setData: (payload: any) => void,
  setTotals: (payload: number[]) => void,
  setError: (payload: boolean) => void,
  setCancelRequest: (payload: () => void) => void,
  setLoadingMore: (payload: boolean) => void,
  setReport: (payload: any) => void,
  setSubjectReport: (payload: any) => void,
  setReportSubjects: (payload: any) => void
) => {
  cancelRequest();
  setError(false);
  setReport(null);

  // under the async reports API, it's not possible/a bad idea to truly cancel the backend job
  // generating the report (because the reports could be shared)
  // The polling + final results requests are all super lightweight so there's no real harm in letting them
  // continue to run, we just throw away the results below when cancelled === true
  let cancelled = false;
  setCancelRequest(() => {
    cancelled = true;
  });

  const pagination = buildPagination(limit, page, dimensions);

  if (fetchMore && dimensions.length > 1) {
    setLoadingMore(true);
    const numSecondaryDimensionItems = uniqBy(
      prevData.map((el) => el.secondary_assignment),
      'id'
    ).length;
    pagination.secondary_offset = numSecondaryDimensionItems;
  } else {
    setData([]);
    setTotals([]);
    setLoading(true);
  }

  const metricsRequest = buildMetricsRequest(
    metrics,
    dimensions,
    variables,
    performanceCalculateBy,
    topLevelFilterType
  );

  try {
    const filters = Object.values(variables).filter((item) => item !== undefined);
    const response = await createReport(dimensions, filters, metricsRequest);
    const completedReport = await pollForReportCompletion(response.toJS());
    const reportId = completedReport.id;
    const resultResponse = await getReportResults(
      reportId,
      getIncludes(dimensions, variables),
      pagination,
      buildSorts(sort).join(',')
    );
    const reportData = resultResponse.toJS();

    const subjectsReportResponse = await createReport(['subjects'], filters, []);
    const completedSubjectsReport = await pollForReportCompletion(subjectsReportResponse.toJS());
    const subjectReportId = completedSubjectsReport.id;
    const subjectReportResultRepsonse = await getReportResults(
      subjectReportId,
      getIncludes(['subjects'], variables),
      {primary_limit: 200, primary_offset: 0},
      buildSorts(sort).join(',')
    );

    const subjectReportdata = subjectReportResultRepsonse.toJS();

    const reportSubjectIds = subjectReportdata.map((reportRow) => {
      return reportRow.subject.id;
    });

    if (cancelled) {
      return;
    }

    setTotals([
      completedReport.count_primary_dimension,
      ...(completedReport.count_secondary_dimension
        ? [completedReport.count_secondary_dimension]
        : [])
    ]);

    if (fetchMore) {
      setData([...prevData, ...reportData]);
    } else {
      setData(reportData);
    }
    setReport(completedReport);
    setSubjectReport(completedSubjectsReport);
    setReportSubjects(reportSubjectIds);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
    setError(true);
  }
  setLoading(false);
  setLoadingMore(false);
};

export const actionTypes = {
  setReportSelected: 'SET_REPORT_SELECTED',
  setTopLevelFilters: 'SET_TOP_LEVEL_FILTERS',
  setAssignmentId: 'SET_ASSIGNMENT_ID',
  setQuestionId: 'SET_QUESTION_ID',
  setStudentId: 'SET_STUDENT_ID',
  setStandardId: 'SET_STANDARD_ID',
  setSubjectId: 'SET_SUBJECT_ID',
  setSchoolId: 'SET_SCHOOL_ID',
  setClassroomId: 'SET_CLASSROOM_ID',
  setTeacherId: 'SET_TEACHER_ID',
  setAttemptNumber: 'SET_ATTEMPT_NUMBER',
  setData: 'SET_DATA',
  setTotals: 'SET_TOTALS',
  fetchData: 'FETCH_DATA',
  fetchMoreData: 'FETCH_MORE_DATA',
  setDate: 'SET_DATE',
  setDimensions: 'SET_DIMENSIONS',
  setError: 'SET_ERROR',
  setSubjectIdFromSearchParam: 'SET_SUBJECT_ID_FROM_SEARCH_PARAM',
  setGuideLevelId: 'SET_GUIDE_LEVEL_ID',
  setSearchFilter: 'SET_SEARCH_FILTER',
  setLimit: 'SET_LIMIT',
  setLoading: 'SET_LOADING',
  setLoadingMore: 'SET_LOADING_MORE',
  setPerformanceCalculateBy: 'SET_MASTERY_CALCULATE_BY',
  setMetric: 'SET_METRIC',
  setPage: 'SET_PAGE',
  setPath: 'SET_PATH',
  setDimensionsInPath: 'SET_DIMENSIONS_IN_PATH',
  setSort: 'SET_SORT',
  setCancelRequest: 'SET_CANCEL_REQUEST',
  setStandardSets: 'SET_STANDARD_SETS',
  setAutoPersonalization: 'SET_AUTO_PERSONALIZATION',
  clearFilters: 'CLEAR_FILTERS',
  setReport: 'SET_REPORT',
  setReportSubjects: 'SET_REPORT_SUBJECTS',
  setAvailableDimensionColumns: 'SET_AVAILABLE_DIMENSION_COLUMNS',
  setDimensionColumnKeyToIsDimensionToggledOn:
    'SET_DIMENSION_COLUMN_KEY_TO_IS_DIMENSION_TOGGLED_ON',
  setSubjectReport: 'SET_SUBJECT_REPORT',
  setSummaryStats: 'SET_SUMMARY_STATS',
  setIncludeDraftGuesses: 'SET_INCLUDE_DRAFT_GUESSES'
};

/**
 * This function removes the filter created by a previous search query (user typeing in search bar on reports)
 * if a user had a search query in previous report (switching to different report / drilling down) we need to remove the old search filter from variables before the report call
 *
 * @param {QueryVariables} variables the variables state value
 */
const removeSearchFilterFromVariables = (variables: QueryVariables) => {
  const filterToRemove = Object.values(variables).find((filter) => {
    return (
      filter && filter.operator && (filter.operator === 'contains' || filter.operator === 'search')
    );
  });
  if (filterToRemove)
    // eslint-disable-next-line no-param-reassign
    variables[`${filterToRemove.type?.slice(0, filterToRemove.type.length - 1)}Filter`] = undefined;
};

function buildPagination(limit: string, page: string, dimensions: Dimension[]) {
  const basicPagination: Pagination = {
    primary_limit: Number(limit),
    primary_offset: Number(limit) * (Number(page) - 1)
  };

  if (dimensions.length !== 1) {
    basicPagination.secondary_limit = SECONDARY_PAGINATION_SIZE;
    basicPagination.secondary_offset = 0;
  }

  return basicPagination;
}

export function reducer(state: State, {type, payload}: Action): State {
  switch (type) {
    case actionTypes.clearFilters: {
      removeQueryParams(
        's',
        'start',
        'end',
        'range',
        'attempt',
        'subject',
        'guideLevel',
        'guide',
        'standardSets',
        'personalization'
      );
      return {
        ...state
      };
    }

    case actionTypes.setReportSelected: {
      return {
        ...state,
        reportSelected: payload
      };
    }

    case actionTypes.setTopLevelFilters: {
      const {topLevelFilterType, topLevelFilterId} = payload as {
        topLevelFilterType: TopLevelFilter;
        topLevelFilterId: string;
      };
      const variables = {} as QueryVariables;

      if (topLevelFilterId) {
        if (topLevelFilterType === 'teachers') {
          variables.teacherFilter = {
            type: 'teachers',
            field: 'id',
            operator: 'equals',
            values: [topLevelFilterId]
          };
        } else if (topLevelFilterType === 'classrooms') {
          variables.classroomFilter = {
            type: 'classrooms',
            field: 'id',
            operator: 'equals',
            values: [topLevelFilterId]
          };
        } else if (topLevelFilterType === 'schools') {
          variables.schoolFilter = {
            type: 'schools',
            field: 'id',
            operator: 'equals',
            values: [topLevelFilterId]
          };
        } else if (topLevelFilterType === 'districts') {
          variables.districtFilter = {
            type: 'districts',
            field: 'id',
            operator: 'equals',
            values: [topLevelFilterId]
          };
        } else if (topLevelFilterType === 'school-admin') {
          variables.adminFilter = {
            type: 'admins',
            field: 'id',
            operator: 'equals',
            values: [topLevelFilterId === 'me' ? sessionStore.getUserId() : topLevelFilterId]
          };
        }
      }

      return {
        ...state,
        topLevelFilterType,
        topLevelFilterId,
        variables
      };
    }

    case actionTypes.setAssignmentId: {
      const assignmentId = payload;
      const {variables} = state;

      if (assignmentId) {
        variables.assignmentFilter = {
          type: 'assignments',
          field: 'id',
          operator: 'equals',
          values: [assignmentId]
        };
      } else {
        delete variables.assignmentFilter;
      }

      return {
        ...state,
        assignmentId,
        variables
      };
    }

    case actionTypes.setQuestionId: {
      const questionId = payload;
      const {variables} = state;
      if (questionId) {
        variables.questionFilter = {
          type: 'questions',
          field: 'id',
          operator: 'equals',
          values: [questionId]
        };
      } else {
        delete variables.questionFilter;
      }

      return {
        ...state,
        questionId,
        variables
      };
    }
    case actionTypes.setStandardId: {
      const standardId = payload;
      const {variables} = state;
      if (standardId) {
        variables.standardFilter = {
          type: 'standards',
          field: 'id',
          operator: 'equals',
          values: [standardId]
        };
      } else {
        delete variables.standardFilter;
      }

      return {
        ...state,
        standardId,
        variables
      };
    }
    case actionTypes.setStandardSets: {
      const standardSetIds = payload;
      const {variables} = state;
      if (standardSetIds) {
        variables.standardSetFilter = {
          type: 'standard_sets',
          field: 'id',
          operator: 'equals',
          values: payload.split(',')
        };
      } else {
        delete variables.standardSetFilter;
      }

      return {
        ...state,
        standardSets: standardSetIds,
        variables: {...variables}
      };
    }
    case actionTypes.setSubjectId: {
      const subjectId = payload;
      const {variables} = state;
      if (subjectId) {
        variables.subjectFilter = {
          type: 'subjects',
          field: 'id',
          operator: 'equals',
          values: [subjectId]
        };
      } else {
        variables.subjectFilter = undefined;
      }
      return {
        ...state,
        subjectId,
        variables
      };
    }
    case actionTypes.setStudentId: {
      const studentId = payload;
      const {variables} = state;

      if (studentId) {
        variables.studentFilter = {
          type: 'students',
          field: 'id',
          operator: 'equals',
          values: [studentId]
        };
      } else {
        delete variables.studentFilter;
      }

      return {
        ...state,
        studentId,
        variables
      };
    }

    case actionTypes.setSchoolId: {
      const schoolId = payload;
      const {variables, topLevelFilterType} = state;

      if (schoolId) {
        variables.schoolFilter = {
          type: 'schools',
          field: 'id',
          operator: 'equals',
          values: [schoolId]
        };
      } else if (topLevelFilterType !== 'schools') {
        delete variables.schoolFilter;
      }

      return {
        ...state,
        schoolId,
        variables
      };
    }

    case actionTypes.setClassroomId: {
      const classroomId = payload;
      const {variables, topLevelFilterType} = state;

      if (classroomId) {
        variables.classroomFilter = {
          type: 'classrooms',
          field: 'id',
          operator: 'equals',
          values: [classroomId]
        };
      } else if (topLevelFilterType !== 'classrooms') {
        delete variables.classroomFilter;
      }

      return {
        ...state,
        classroomId,
        variables
      };
    }
    case actionTypes.setTeacherId: {
      const teacherId = payload;
      const {variables, topLevelFilterType} = state;

      if (teacherId) {
        variables.teacherFilter = {
          type: 'teachers',
          field: 'id',
          operator: 'equals',
          values: [teacherId]
        };
      } else if (topLevelFilterType !== 'teachers') {
        delete variables.teacherFilter;
      }

      return {
        ...state,
        teacherId,
        variables
      };
    }

    case actionTypes.setSubjectIdFromSearchParam: {
      const subjectIdFromSearchParam = payload;
      const {variables, subjectId} = state;
      if (subjectIdFromSearchParam) {
        variables.subjectFilter = {
          type: 'subjects',
          field: 'id',
          operator: 'equals',
          values: [subjectIdFromSearchParam]
        };
      } else if (!subjectId) {
        // remove the subjectFilter only if there is not a subject drilldown and subjectIdFromSearchParam is undefined
        variables.subjectFilter = undefined;
      }
      return {
        ...state,
        subjectIdFromSearchParam,
        variables
      };
    }

    case actionTypes.setGuideLevelId: {
      const guideLevel = payload;
      const {variables} = state;
      if (guideLevel.length) {
        variables.guideLevelFilter = {
          type: 'guide_levels',
          field: 'id',
          operator: 'equals',
          values: guideLevel
        };
      } else {
        variables.guideLevelFilter = undefined;
      }
      return {
        ...state,
        guideLevel,
        variables
      };
    }

    case actionTypes.setSearchFilter: {
      const {dimensions, searchString, variables} = state;

      if (dimensions.length > 1) {
        return state;
      }

      const [dimension] = dimensions;

      // Search Filter has been cleared
      if (!payload && searchString) {
        variables[`${dimension?.slice(0, dimension.length - 1)}Filter`] = undefined;
        removeSearchFilterFromVariables(variables);

        removeQueryParams('s');

        return {
          ...state,
          variables,
          searchString: payload
        };
      }

      if (!payload) {
        return state;
      }

      if (dimension === DIMENSIONS.assignments) {
        variables.assignmentFilter = {
          type: 'assignments',
          field: 'name',
          operator: 'contains',
          values: [payload]
        };
      }

      if (dimension === DIMENSIONS.questions) {
        variables.questionFilter = {
          type: 'questions',
          field: 'title',
          operator: 'contains',
          values: [payload]
        };
      }

      if (dimension === DIMENSIONS.students) {
        variables.studentFilter = {
          type: 'students',
          field: 'full_name',
          operator: 'search',
          values: [payload]
        };
      }

      if (dimension === DIMENSIONS.standards) {
        variables.standardFilter = {
          type: 'standards',
          field: 'title',
          operator: 'contains',
          values: [payload]
        };
      }

      if (dimension === DIMENSIONS.subjects) {
        variables.subjectFilter = {
          type: 'subjects',
          field: 'name',
          operator: 'contains',
          values: [payload]
        };
      }

      if (dimension === DIMENSIONS.schools) {
        variables.schoolFilter = {
          type: 'schools',
          field: 'name',
          operator: 'contains',
          values: [payload]
        };
      }

      if (dimension === DIMENSIONS.classrooms) {
        variables.classroomFilter = {
          type: 'classrooms',
          field: 'name',
          operator: 'contains',
          values: [payload]
        };
      }

      if (dimension === DIMENSIONS.teachers) {
        variables.teacherFilter = {
          type: 'teachers',
          field: 'full_name',
          operator: 'contains',
          values: [payload]
        };
      }

      return {
        ...state,
        variables,
        searchString: payload
      };
    }

    case actionTypes.setAttemptNumber: {
      const attemptNumber = payload === 'mostRecent' ? payload : parseInt(payload, 10);
      const {variables} = state;

      delete variables.attemptNumberFilter;
      if (attemptNumber === 'mostRecent') {
        variables.attemptNumberFilter = {
          type: 'guesses',
          operator: 'most_recent'
        };
      } else if (attemptNumber) {
        variables.attemptNumberFilter = {
          type: 'guesses',
          field: 'attempt_number',
          operator: 'equals',
          values: [attemptNumber]
        };
      }

      return {
        ...state,
        attemptNumber,
        variables
      };
    }

    case actionTypes.setDate: {
      const {start, end, range} = payload;
      const sanitizedRange = decodeURIComponent(range) as DateRange;
      const {variables} = state;
      if (start) {
        variables.startDateFilter = {
          type: 'guesses',
          field: 'inserted_at',
          operator: 'gte',
          values: [moment(start).startOf('day').toISOString()]
        };
      } else {
        delete variables.startDateFilter;
      }

      if (end) {
        variables.endDateFilter = {
          type: 'guesses',
          field: 'inserted_at',
          operator: 'lte',
          values: [moment(end).endOf('day').toISOString()]
        };
      } else {
        delete variables.endDateFilter;
      }

      return {
        ...state,
        start,
        end,
        range: sanitizedRange,
        variables
      };
    }

    case actionTypes.setDimensions: {
      const {variables} = state;
      const dimensions = payload;
      removeQueryParams('page', 'sort');
      removeSearchFilterFromVariables(variables);

      return {
        ...state,
        dimensions,
        page: '1',
        variables: {
          ...variables
        }
      };
    }

    case actionTypes.setPerformanceCalculateBy: {
      return {
        ...state,
        performanceCalculateBy: payload
      };
    }

    case actionTypes.setMetric: {
      const metrics = payload.split(',');
      const {variables} = state;
      const enabledMetricColumns = getMetricColumns(metrics);

      return {
        ...state,
        metrics: enabledMetricColumns,
        variables
      };
    }

    case actionTypes.setPath: {
      const path = payload;
      return {
        ...state,
        path
      };
    }

    case actionTypes.setDimensionsInPath: {
      const dimensionsInPath = payload;
      return {
        ...state,
        dimensionsInPath
      };
    }

    case actionTypes.setAutoPersonalization: {
      return {
        ...state,
        autoPersonalization: payload
      };
    }

    case actionTypes.setPage: {
      return {
        ...state,
        page: payload
      };
    }

    case actionTypes.setLimit: {
      return {
        ...state,
        limit: payload
      };
    }

    case actionTypes.setTotals: {
      return {
        ...state,
        totals: payload
      };
    }

    case actionTypes.setSort: {
      if (payload) {
        const sortsInQuery = (payload as string).split(',');
        const sorts = sortsInQuery.map((sort) => {
          const [sortBy, sortDirection] = sort.split(':');

          const [sortType, sortField] = sortBy.split('.');

          return {
            type: sortType,
            field: sortField,
            direction: sortDirection as 'asc' | 'desc'
          };
        });

        return {
          ...state,
          page: '1',
          sort: sorts
        };
      }

      return {
        ...state,
        sort: []
      };
    }

    /** post fetch reducer functions */

    case actionTypes.setReport: {
      return {
        ...state,
        report: payload
      };
    }

    case actionTypes.setSubjectReport: {
      return {
        ...state,
        subjectReport: payload
      };
    }

    case actionTypes.setReportSubjects: {
      return {
        ...state,
        reportSubjects: payload
      };
    }

    case actionTypes.setData: {
      return {
        ...state,
        data: payload
      };
    }

    case actionTypes.setError: {
      return {
        ...state,
        error: payload
      };
    }

    case actionTypes.setLoading: {
      return {
        ...state,
        loading: payload
      };
    }

    case actionTypes.setLoadingMore: {
      return {
        ...state,
        loadingMore: payload
      };
    }

    case actionTypes.setCancelRequest: {
      return {
        ...state,
        cancelRequest: payload
      };
    }

    case actionTypes.setAvailableDimensionColumns: {
      return {
        ...state,
        availableDimensionColumns: payload
      };
    }

    case actionTypes.setDimensionColumnKeyToIsDimensionToggledOn: {
      return {
        ...state,
        dimensionColumnKeyToIsDimensionToggledOn: payload
      };
    }

    case actionTypes.setSummaryStats: {
      return {
        ...state,
        summaryStats: payload
      };
    }

    case actionTypes.setIncludeDraftGuesses: {
      const includeDraftGuesses = payload;
      const {variables} = state;

      if (!includeDraftGuesses) {
        variables.guessStatusFilter = {
          // excludes draft guesses by limiting to only published guesses
          type: 'guesses',
          field: 'status',
          operator: 'equals',
          values: ['published']
        };
      } else {
        delete variables.guessStatusFilter;
      }

      return {
        ...state,
        includeDraftGuesses,
        variables
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
}

export default function useReportRoute({location}: Route): ContextType {
  const defaultDimensionColumnKeyToToggledStatus = new Map(
    Object.values(DIMENSION_COLUMNS).flatMap((columnsArray) =>
      columnsArray.map(({key}) => [key, true])
    )
  );

  const {pathname, query} = location;
  const {
    assignmentId = '',
    questionId = '',
    standardId = '',
    studentId = '',
    subjectId = '',
    classroomId = '',
    teacherId = '',
    schoolId = '',
    reportSelected = '',
    topLevelFilterType = '' as TopLevelFilter,
    topLevelFilterId = ''
  } = processReportPath(pathname);

  const metricsDefaults = pathname.endsWith('gradebook')
    ? 'performance'
    : 'time_spent,answer_breakdown,performance';

  const sortDefaults = pathname.endsWith('gradebook')
    ? 'students.last_name:asc,assignments.name:asc'
    : '';

  const gradeIsAvailable =
    (pathname.endsWith('assignments') && studentId) ||
    (pathname.endsWith('students') && assignmentId) ||
    (studentId && assignmentId) ||
    pathname.endsWith('gradebook');

  const {
    attempt = pathname.includes(DIMENSIONS.assignments) || pathname.includes('gradebook')
      ? '1'
      : 'mostRecent',
    limit = pathname.endsWith('gradebook') ? '25' : '100',
    metrics = metricsDefaults,
    page = '1',
    range = RANGES.THIS_SCHOOL_YEAR,
    s: searchString = '', // TODO this should be renamed to search filter
    sort = sortDefaults,
    start = '',
    subject: subjectIdFromSearchParam = '',
    end = '',
    standardSets = '',
    personalization = '',
    includeDraftGuesses = ''
  } = query;

  const performanceCalculateBy = gradeIsAvailable
    ? query.performanceCalculateBy || 'grade'
    : 'accuracy';

  const initialMetrics = getMetricColumns(metrics.split(','));

  const initialState: State = {
    dimensions: [],
    attemptNumber: attempt === 'mostRecent' ? attempt : parseInt(attempt, 10),
    topLevelFilterType,
    topLevelFilterId,
    assignmentId,
    questionId,
    standardId,
    studentId,
    subjectId,
    schoolId,
    classroomId,
    teacherId,
    reportSelected: reportSelected as ReportTypes,
    error: false,
    data: [],
    searchString,
    range,
    start,
    end,
    subjectIdFromSearchParam,
    guideLevel: [],
    limit,
    totals: [],
    loading: true,
    loadingMore: false,
    performanceCalculateBy: 'accuracy',
    metrics: initialMetrics,
    page,
    path: [],
    dimensionsInPath: [],
    sort: [],
    standardSets,
    autoPersonalization: !!personalization,
    variables: {},
    reportSubjects: [],
    cancelRequest: () => {},
    availableDimensionColumns: [],
    dimensionColumnKeyToIsDimensionToggledOn: defaultDimensionColumnKeyToToggledStatus,
    includeDraftGuesses: includeDraftGuesses === ''
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  function isColumnEnabledOrSoftToggleable(column: Column) {
    const {key} = column;

    return (
      (state.dimensionColumnKeyToIsDimensionToggledOn.get(key) ?? false) ||
      isColumnSoftToggleable(column)
    );
  }

  function isColumnSoftDisabled(column: Column) {
    const {columnType, key} = column;

    if (columnType === 'metric') {
      // Currently, metric columns have no concept of 'soft' toggleability.
      return false;
    }

    return (
      isColumnSoftToggleable(column) && !state.dimensionColumnKeyToIsDimensionToggledOn.get(key)
    );
  }

  function getStudentFullName(student: any) {
    const studentNameColumn = DIMENSION_COLUMNS.students.find(({key}) => key === 'students.name');

    if (studentNameColumn === undefined) {
      throw Error('Can\'t find column "students.name"!');
    }

    const {first_name: firstName, last_name: lastName} = student ?? {};

    return isColumnSoftDisabled(studentNameColumn)
      ? getObfuscatedNameString(8)
      : `${firstName || ''} ${lastName || ''}`;
  }

  const canFetchSummaryStats = useCallback(() => {
    const isGradebook = state.dimensions.length === 2;
    const [dimensionInFocus] = isGradebook
      ? [state.topLevelFilterType]
      : state.dimensionsInPath.slice(-2, -1);

    return (
      (state.topLevelFilterType !== 'school-admin' || dimensionInFocus !== undefined) &&
      state.variables.startDateFilter !== undefined &&
      state.variables.endDateFilter !== undefined
    );
  }, [
    state.dimensions.length,
    state.dimensionsInPath,
    state.topLevelFilterType,
    state.variables.endDateFilter,
    state.variables.startDateFilter
  ]);

  const actions = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(actionTypes).map(([action, type]) => [
          action,
          (payload) => {
            dispatch({type, payload});
          }
        ])
      ),
    [dispatch]
  ) as DispatchActions;

  useEffect(() => {
    actions.setTopLevelFilters({topLevelFilterType, topLevelFilterId});
  }, [actions, topLevelFilterType, topLevelFilterId]);

  useEffect(() => {
    actions.setAssignmentId(assignmentId);
  }, [actions, assignmentId]);

  useEffect(() => {
    actions.setQuestionId(questionId);
  }, [actions, questionId]);

  useEffect(() => {
    actions.setSubjectId(subjectId);
  }, [actions, subjectId]);

  useEffect(() => {
    actions.setStandardId(standardId);
  }, [actions, standardId]);

  useEffect(() => {
    actions.setStudentId(studentId);
  }, [actions, studentId]);

  useEffect(() => {
    actions.setClassroomId(classroomId);
  }, [actions, classroomId]);

  useEffect(() => {
    actions.setTeacherId(teacherId);
  }, [actions, teacherId]);

  useEffect(() => {
    actions.setSchoolId(schoolId);
  }, [actions, schoolId]);

  useEffect(() => {
    actions.setReportSelected(reportSelected);
  }, [actions, reportSelected]);

  useEffect(() => {
    actions.setAttemptNumber(attempt);
  }, [actions, attempt]);

  useEffect(() => {
    (async () => {
      if (topLevelFilterId && !start && !end) {
        if (topLevelFilterType === 'classrooms') {
          const classroom = await getClassroomQuery(topLevelFilterId).getResourcePromise();
          if (classroom.getStatus() === CLASSROOM_STATUSES.ARCHIVED) {
            const {start: newStart, end: newEnd, range: newRange} = getArchivedDateRange(classroom);
            actions.setDate({
              start: newStart,
              end: newEnd,
              range: newRange
            });
          } else {
            const schoolYearDateRange = getThisSchoolYear();
            actions.setDate({
              start: schoolYearDateRange.get('start').format('YYYY-MM-DD'),
              end: schoolYearDateRange.get('end').format('YYYY-MM-DD'),
              range: RANGES.THIS_SCHOOL_YEAR
            });
          }
        } else {
          const schoolYearDateRange = getThisSchoolYear();
          actions.setDate({
            start: schoolYearDateRange.get('start').format('YYYY-MM-DD'),
            end: schoolYearDateRange.get('end').format('YYYY-MM-DD'),
            range: RANGES.THIS_SCHOOL_YEAR
          });
        }
      }
    })();
  }, [actions, topLevelFilterType, topLevelFilterId, start, end]);

  useEffect(() => {
    const datesAreValid = start && end && moment(start).isValid() && moment(end).isValid();
    if (datesAreValid) {
      actions.setDate({start, end, range});
    }
  }, [actions, start, end, range]);

  useEffect(() => {
    actions.setStandardSets(standardSets);
  }, [actions, standardSets]);

  useEffect(() => {
    actions.setAutoPersonalization(!!personalization);
  }, [actions, personalization]);

  useEffect(() => {
    const path = pathname.split('/');

    const dimensionsInPath = path.filter((el) => isDimension(el) || el === 'gradebook');
    const currentDimension = dimensionsInPath[dimensionsInPath.length - 1];

    if (currentDimension !== 'standards' && !path.includes('standards')) {
      removeQueryParams('standardSets', 'personalization');
    }

    if (!query.attempt) {
      const attemptNumber =
        pathname.includes(DIMENSIONS.assignments) || pathname.includes('gradebook')
          ? '1'
          : 'mostRecent';
      actions.setAttemptNumber(attemptNumber);
    }

    const currentDimensions =
      {
        assignments: ['assignments'],
        questions: ['questions'],
        students: ['students'],
        standards: ['standards'],
        gradebook: ['students', 'assignments'],
        subjects: ['subjects'],
        teachers: ['teachers'],
        schools: ['schools'],
        classrooms: ['classrooms']
      }[currentDimension] || [];

    actions.setDimensions(currentDimensions);
    actions.setDimensionsInPath(dimensionsInPath);
    actions.setPath(path);
    actions.setData([]);
    actions.setSearchFilter(undefined);
  }, [actions, pathname, query.attempt]);

  useEffect(() => {
    actions.setMetric(metrics);
  }, [actions, metrics]);

  useEffect(() => {
    actions.setSubjectIdFromSearchParam(subjectIdFromSearchParam);
  }, [actions, subjectIdFromSearchParam]);

  useEffect(() => {
    const guideLevelIndexes = Object.keys(query).filter((item) => item.startsWith('guideLevel'));
    const formattedGuideLevels = guideLevelIndexes.map((index) => query[index]);

    if (!isEqual(formattedGuideLevels, state.variables.guideLevelFilter?.values)) {
      actions.setGuideLevelId(formattedGuideLevels);
    }
  }, [actions, query, state.variables.guideLevelFilter?.values]);

  useEffect(() => {
    actions.setIncludeDraftGuesses(includeDraftGuesses === '');
  }, [actions, includeDraftGuesses]);

  useEffect(() => {
    actions.setSearchFilter(searchString);
  }, [actions, searchString]);

  useEffect(() => {
    actions.setLimit(limit);
  }, [actions, limit]);

  useEffect(() => {
    actions.setPage(page);
  }, [actions, page]);

  useEffect(() => {
    actions.setSort(sort);
  }, [actions, sort]);

  useEffect(() => {
    actions.setPerformanceCalculateBy(performanceCalculateBy);
  }, [actions, performanceCalculateBy]);

  useEffect(() => {
    // we only allow gradebook for classroom ID filters, so don't bother fetching it otherwise
    const isNonClassroomIdGradebook =
      topLevelFilterType !== 'classrooms' && topLevelFilterId && pathname.endsWith('gradebook');

    // we only allow multidimensional reports to call with a max of 50 rows as because it is multi-dimensional,
    // it will generated 50 * 10 = 500 which is the max limit of results from the endpoint
    const isMultiDimensionalCallWithLimitExceeding50 =
      state.dimensions.length > 1 && parseInt(limit, 10) > 50;

    if (
      state.variables.startDateFilter &&
      state.variables.endDateFilter &&
      topLevelFilterId &&
      state.dimensions.length > 0 &&
      !isNonClassroomIdGradebook &&
      !isMultiDimensionalCallWithLimitExceeding50
    ) {
      decoratedFetchData(
        {
          data: [],
          fetchMore: false,
          metrics: state.metrics,
          variables: state.variables,
          dimensions: state.dimensions,
          limit,
          page,
          sort: state.sort,
          performanceCalculateBy: state.performanceCalculateBy,
          cancelRequest: state.cancelRequest,
          topLevelFilterType
        },
        actions.setLoading,
        actions.setData,
        actions.setTotals,
        actions.setError,
        actions.setCancelRequest,
        actions.setLoadingMore,
        actions.setReport,
        actions.setSubjectReport,
        actions.setReportSubjects
      );
    } else {
      actions.setData([]);
      actions.setLoading(false);
      actions.setLoadingMore(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    actions,
    state.metrics,
    state.variables,
    state.variables.assignmentFilter,
    state.variables.attemptNumberFilter,
    state.variables.schoolFilter,
    state.variables.classroomFilter,
    state.variables.teacherFilter,
    state.variables.endDateFilter,
    state.variables.guideLevelFilter,
    state.variables.questionFilter,
    state.sort,
    state.variables.standardFilter,
    state.variables.startDateFilter,
    state.variables.studentFilter,
    state.variables.subjectFilter,
    state.dimensions,
    limit,
    page,
    topLevelFilterType,
    topLevelFilterId,
    state.performanceCalculateBy,
    state.includeDraftGuesses
  ]);

  useEffect(() => {
    if (isDimension(state.reportSelected)) {
      const availableDimensionColumns = getAvailableColumns(
        DIMENSION_COLUMNS[state.reportSelected],
        state.reportSelected,
        state.variables,
        state.topLevelFilterType
      );

      actions.setAvailableDimensionColumns(availableDimensionColumns);
    }
  }, [actions, state.reportSelected, state.topLevelFilterType, state.variables]);

  const fetchData = () => {
    decoratedFetchData(
      {
        data: state.data,
        fetchMore: false,
        metrics: state.metrics,
        variables: state.variables,
        dimensions: state.dimensions,
        limit,
        page,
        sort: state.sort,
        performanceCalculateBy: state.performanceCalculateBy,
        cancelRequest: state.cancelRequest,
        topLevelFilterType
      },
      actions.setLoading,
      actions.setData,
      actions.setTotals,
      actions.setError,
      actions.setCancelRequest,
      actions.setLoadingMore,
      actions.setReport,
      actions.setSubjectReport,
      actions.setReportSubjects
    );
  };

  const fetchSummaryStatsData = () => {
    getReportSummaryStats(
      {
        topLevelFilterType: state.topLevelFilterType,
        dimensionsInPath: state.dimensionsInPath,
        variables: state.variables,
        dimensions: state.dimensions
      },
      actions.setSummaryStats
    );
  };

  useEffect(() => {
    if (canFetchSummaryStats()) {
      getReportSummaryStats(
        {
          topLevelFilterType: state.topLevelFilterType,
          dimensionsInPath: state.dimensionsInPath,
          variables: state.variables,
          dimensions: state.dimensions
        },
        actions.setSummaryStats
      );
    }
  }, [
    actions,
    canFetchSummaryStats,
    state.attemptNumber,
    state.dimensions,
    state.dimensionsInPath,
    state.includeDraftGuesses,
    state.topLevelFilterType,
    state.variables,
    state.variables.endDateFilter,
    state.variables.startDateFilter,
    state.variables.subjectFilter
  ]);

  return {
    ...state,
    ...actions,
    canFetchSummaryStats,
    fetchData,
    fetchMoreData: () => {
      decoratedFetchData(
        {
          data: state.data,
          fetchMore: true,
          metrics: state.metrics,
          variables: state.variables,
          dimensions: state.dimensions,
          limit,
          page,
          sort: state.sort,
          performanceCalculateBy: state.performanceCalculateBy,
          cancelRequest: state.cancelRequest,
          topLevelFilterType
        },
        actions.setLoading,
        actions.setData,
        actions.setTotals,
        actions.setError,
        actions.setCancelRequest,
        actions.setLoadingMore,
        actions.setReport,
        actions.setSubjectReport,
        actions.setReportSubjects
      );
    },
    getStudentFullName,
    isColumnEnabledOrSoftToggleable,
    isColumnSoftDisabled,
    query,
    refreshReport: async () => {
      actions.setLoading(true);
      actions.setSummaryStats(undefined);
      try {
        if (state.report && state.report.id) {
          await genericMandarkRequest('delete', {
            resourcePath: ['json', 'analytics', 'reports', state.report.id]
          });
        }
        if (state.subjectReport && state.subjectReport.id) {
          await genericMandarkRequest('delete', {
            resourcePath: ['json', 'analytics', 'reports', state.subjectReport.id]
          });
        }
      } catch (e) {
        // swallow 404 error, this just means the report was already expired and we can simply refresh
      }

      actions.setReport(null);
      fetchData();
      fetchSummaryStatsData();
    }
  };
}
