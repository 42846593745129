import React from 'react';
import PropTypes from 'prop-types';
import SimpleMarkdown from 'simple-markdown';
import {WithTooltip} from '@albert-io/atomic';
import './tooltip-term.scss';

const tooltipRegex = /^\[tooltip:([^\|\n]+)\|([^\]\n]+)]/;

const TooltipTerm = ({term, content}) => {
  return (
    <WithTooltip content={content} behavior='click'>
      <span className='tooltip-term'>{term}</span>
    </WithTooltip>
  );
};

TooltipTerm.propTypes = {
  term: PropTypes.node,
  content: PropTypes.node
};

export const tooltipTermRule = {
  order: SimpleMarkdown.defaultRules.autolink.order - 0.5,
  match: (source) => tooltipRegex.exec(source),
  parse: (capture, recurseParse, state) => {
    return {
      term: recurseParse(capture[1], state),
      content: recurseParse(capture[2], state)
    };
  },
  react: function TooltipTermRule(node, output) {
    return (
      <TooltipTerm term={output(node.term)} content={output(node.content)} key={node.tokenId} />
    );
  }
};
