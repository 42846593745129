import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {List} from 'immutable';
import {Link} from 'react-router';

import {history, replaceQueryParams, pushQueryParams} from 'client/history';
import errorCodes from 'client/errorCodes';
import {ERROR_TYPES} from 'client/constants';
import masqueradeStore from 'generic/Masquerade/Masquerade.store';
import {genericMandarkRequest} from 'resources/mandark.resource';

import {query} from '@albert-io/json-api-framework/request/builder';
import notifier from '@albert-io/notifier';
import {addToast, Button, Dialogue, Modal, Text, Icon} from '@albert-io/atomic';

import {
  getClassroomInvitationsQuery as getInvitationsQuery,
  getClassroomsQuery,
  getIndiePracticeSubjectsQuery as getSubjectsQuery,
  getStudentQuery
} from './StudentDashboard.queries';

const PAGE_SIZE = 6;

// @todo optimize with two different providers, one for state the other for dispatching
const StudentDashboardContext = React.createContext();

export default function StudentDashboardProvider({children, location = {query: {}}}) {
  const {classroomId, page = 1} = location.query;
  const shouldEnrollStudentByParam = Boolean(classroomId);

  const didMountRef = useRef(false);
  const pageRef = useRef(page);

  const [openAssignments, setOpenAssignments] = useState(List());
  const [pastDueAssignments, setPastDueAssignments] = useState(List());
  const [pastDueAssignmentsCount, setPastDueAssignmentsCount] = useState(0);

  const [classrooms, setClassrooms] = useState(List());
  const [duplicateClassroom, setDuplicateClassroom] = useState(null);
  const [invitations, setInvitations] = useState(List());
  const [isReady, setIsReady] = useState(false);
  const [isRequestPending, setIsRequestPending] = useState(false);
  const [student, setStudent] = useState(null);
  const [studentHasInvalidEmail, setStudentHasInvalidEmail] = useState(false);
  const [exceededHardCapLimit, setExceededHardCapLimit] = useState(false);
  const [subjects, setSubjects] = useState(List());
  const [totalPages, setTotalPages] = useState(1);

  const clearDashboardCache = useCallback(async () => {
    const id = student.getId();
    const email = student.getEmail();
    getInvitationsQuery(email).invalidateInterest();
    getClassroomsQuery(id).page(page).pageSize(PAGE_SIZE).invalidateInterest();
    getSubjectsQuery().invalidateInterest();
    getStudentQuery().invalidateInterest();
  }, [student, page]);

  const enrollStudent = useCallback(
    async ({relationship = {}, onResolve = () => {}, onReject = () => {}}) => {
      const id = student.getId();
      try {
        await genericMandarkRequest(
          'post',
          query().mandarkEndpoint(['students_v1', id, 'relationships', 'classrooms_v1']).done(),
          {
            data: [relationship]
          }
        );
        clearDashboardCache();
        addToast({
          duration: 10000,
          message: 'You have joined a new class',
          title: 'Success!',
          color: 'positive'
        });
        setIsReady(false);
        onResolve();
      } catch (err) {
        const exceededHardCapLimitError =
          err.response?.status === 403 &&
          err.response?.body.errors[0].code === errorCodes.FORBIDDEN.EXCEEDED_HARD_CAP_LIMIT;
        if (exceededHardCapLimitError) {
          setExceededHardCapLimit(true);
        }
        // don't notify for 400 errors (to suppress invalid join code)
        if (err.response?.status !== 400) {
          notifier.notify(err, {
            name: `Failed to enroll student ${masqueradeStore.getUserIdByMasqueradeState()} using relationship ${JSON.stringify(
              relationship
            )}`,
            component: 'StudentDashboardProvider'
          });
        }
        /* Catch the Errors.BadRequest.InvalidEmailDomain here to cover join code, email link, and invitation card enrollment flows */
        const invalidEmail =
          err.response?.status === 400 &&
          err.response?.body.errors[0].code ===
            errorCodes.BAD_REQUEST.VALIDATION_ERROR.INVALID_EMAIL_DOMAIN;
        if (invalidEmail) {
          setStudentHasInvalidEmail(true);
        }
        onReject(err);
      }
    },
    [clearDashboardCache, student]
  );

  useEffect(() => {
    if (didMountRef.current === false) {
      didMountRef.current = true;
    }

    if (page > totalPages) {
      pushQueryParams({page: totalPages});
    }
  }, [page, totalPages]);

  useEffect(() => {
    const hasPageChanged = pageRef.current !== page;
    if (hasPageChanged) {
      pageRef.current = page;
      setIsReady(false);
    }
  }, [page]);

  useEffect(() => {
    async function enrollStudentByParam(relationship) {
      await enrollStudent({
        relationship,
        onResolve: () => {
          replaceQueryParams({classroomId: null, classroomInvitationId: null});
        },
        onReject: () => {
          addToast({
            title: 'Something went wrong',
            color: 'negative',
            message:
              'We were unable to complete your enrollment. Please verify the invitation with your teacher and try again.'
          });
        }
      });
    }

    if (shouldEnrollStudentByParam === true && didMountRef.current === true) {
      const relationship = {
        id: classroomId,
        type: 'classroom_v1'
      };
      enrollStudentByParam(relationship);
    }
  }, [classroomId, enrollStudent, shouldEnrollStudentByParam, student]);

  useEffect(() => {
    async function bootstrapStudentDashboard() {
      try {
        const fetchedStudent = await getStudentQuery().getResourcePromise();
        const studentId = fetchedStudent.getId();
        const email = fetchedStudent.getEmail();

        const paginatedClassroomsQuery = getClassroomsQuery(studentId)
          .page(page)
          .pageSize(PAGE_SIZE);
        const fetchedClassrooms = await paginatedClassroomsQuery.getResourcePromise();

        const fetchedInvitations = await getInvitationsQuery(email).getResourcePromise();
        // @todo Refactor getClassroomInvitationsQuery with the query params that will achieve this same behavior
        const filteredInvitations = fetchedInvitations.filter(
          (invitation) => !invitation.getClassroom().isEmpty() && !invitation.getIssuer().isEmpty()
        );

        const fetchedSubjects = await getSubjectsQuery().getResourcePromise();

        const assignmentInfo = fetchedStudent?.getMeta?.()?.getAssignmentInfo?.();
        const fetchedOpenAssignments = assignmentInfo?.get?.('open_assignments');
        const fetchedPastDueAssignments = assignmentInfo?.get?.('past_due_assignments');
        const fetchedPastDueAssignmentsCount = assignmentInfo?.get?.('past_due_assignments_count');

        setOpenAssignments(fetchedOpenAssignments);
        setPastDueAssignments(fetchedPastDueAssignments);
        setPastDueAssignmentsCount(fetchedPastDueAssignmentsCount);
        setClassrooms(fetchedClassrooms);
        setInvitations(filteredInvitations);
        setStudent(fetchedStudent);
        setSubjects(fetchedSubjects);
        setTotalPages(
          paginatedClassroomsQuery.getResourceMetadata().getIn(['page', 'total_pages'], 1)
        );

        setIsReady(true);
      } catch (err) {
        console.log(err);
        // eslint-disable-next-line no-console
        console.error(err);
        addToast({
          title: 'Something went wrong',
          color: 'negative',
          message:
            'There was a problem loading your classrooms. If the problem persists, contact us.'
        });
        notifier.notify(err, {
          name: `Failed to load classroom dashboard for student ${masqueradeStore.getUserIdByMasqueradeState()}`,
          component: 'ClassroomMenu'
        });
        history.pushState(null, `/error?errorCode=${ERROR_TYPES.CRITICAL}`);
      }
    }

    if (isReady === false) {
      bootstrapStudentDashboard();
    }
  }, [isReady, page]);
  return (
    <StudentDashboardContext.Provider
      value={{
        openAssignments,
        pastDueAssignments,
        pastDueAssignmentsCount,
        classrooms,
        duplicateClassroom,
        enrollStudent,
        invitations,
        isReady,
        isRequestPending,
        page,
        setDuplicateClassroom,
        setIsReady,
        setIsRequestPending,
        setStudentHasInvalidEmail,
        setExceededHardCapLimit,
        student,
        subjects,
        totalPages
      }}
    >
      {children}
      {duplicateClassroom && <AlreadyEnrolledModal key={location.pathname} />}
      {studentHasInvalidEmail && <InvalidEmailModal key={location.pathname} />}
      {exceededHardCapLimit && <HardCapStudentErrorModal key={location.pathname} />}
    </StudentDashboardContext.Provider>
  );
}

StudentDashboardProvider.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object
};

const AlreadyEnrolledModal = () => {
  const {duplicateClassroom, setDuplicateClassroom} = useStudentDashboard();

  useEffect(() => {
    return () => {
      setDuplicateClassroom(null);
    };
  }, [setDuplicateClassroom]);

  return (
    <Modal
      ariaLabel='Go to classroom'
      handleClose={() => {
        setDuplicateClassroom(null);
      }}
    >
      {({CloseButtonWrapper, modalContentStyle}) => (
        <Dialogue
          className={modalContentStyle}
          handleClose={() => setDuplicateClassroom(null)}
          inModal
          title='You already belong to that classroom'
        >
          <Dialogue.Body>
            <Text as='p'>You have already enrolled in {`${duplicateClassroom.getName()}`}!</Text>
          </Dialogue.Body>
          <div className='u-display_flex u-flex-direction_column u-width_100pc u-mar-t_5'>
            <Button
              as={Link}
              className='u-mar-b_1'
              to={`/classes/${duplicateClassroom.getId()}/my-assignments`}
            >
              Go to classroom
            </Button>
            <CloseButtonWrapper className='u-width_100pc'>
              <Button
                className='u-width_100pc'
                color='secondary'
                onClick={() => setDuplicateClassroom(null)}
              >
                Cancel
              </Button>
            </CloseButtonWrapper>
          </div>
        </Dialogue>
      )}
    </Modal>
  );
};

const HardCapStudentErrorModal = () => {
  const {setExceededHardCapLimit} = useStudentDashboard();

  useEffect(() => {
    return () => {
      setExceededHardCapLimit(false);
    };
  }, [setExceededHardCapLimit]);
  return (
    <Modal
      handleClose={() => setExceededHardCapLimit(false)}
      ariaLabel='seats already taken'
      size='xs'
    >
      {({CloseButtonWrapper}) => (
        <Dialogue
          handleClose={() => setExceededHardCapLimit(false)}
          inModal
          hideCloseBtn
          className='u-text-align_center u-color_slate-500'
          size='s'
        >
          <div className='hard-cap-error__student-icon'>
            <Icon icon={['fal', 'face-surprise']} />
          </div>
          <p className='u-display_block'>
            Unfortunately, we couldn&apos;t add you to this classroom because all the seats are
            already taken. Sorry about that!
          </p>
          <p className='u-display_block u-mar-b_3'>
            Contact your teacher for help. They&apos;ll be able to assist you further.
          </p>
          <CloseButtonWrapper className='u-width_100pc'>
            <Button variant='outlined' onClick={() => setExceededHardCapLimit(false)}>
              Dismiss
            </Button>
          </CloseButtonWrapper>
        </Dialogue>
      )}
    </Modal>
  );
};

const InvalidEmailModal = () => {
  const {setStudentHasInvalidEmail} = useStudentDashboard();

  useEffect(() => {
    return () => {
      setStudentHasInvalidEmail(false);
    };
  }, [setStudentHasInvalidEmail]);

  return (
    <Modal
      ariaLabel="You don't have permission to join this classroom"
      handleClose={() => {
        setStudentHasInvalidEmail(false);
      }}
    >
      {({CloseButtonWrapper, modalContentStyle}) => (
        <Dialogue
          className={modalContentStyle}
          inModal
          handleClose={() => {
            setStudentHasInvalidEmail(false);
          }}
          title="You don't have permission to join this classroom"
        >
          <Dialogue.Body className='u-text-align_center'>
            <Text as='p'>Please speak with your teacher if this problem persists.</Text>
            <Text as='p'>This can happen for a few reasons:</Text>
            <Text as='div' className='u-text-align_left'>
              <ul>
                <li className='u-mar-b_1'>
                  You signed up using an email address other than your school-issued email
                </li>
                <li className='u-mar-b_1'>
                  You logged into Albert using an old or incorrect username
                </li>
                <li className='u-mar-b_1'>
                  You logged into Albert using an incorrect Google or Clever account
                </li>
              </ul>
            </Text>
          </Dialogue.Body>
          <div className='u-display_flex u-width_100pc u-mar-t_5'>
            <CloseButtonWrapper className='u-width_100pc'>
              <Button className='u-width_100pc' onClick={() => setStudentHasInvalidEmail(false)}>
                OK
              </Button>
            </CloseButtonWrapper>
          </div>
        </Dialogue>
      )}
    </Modal>
  );
};

export function useStudentDashboard() {
  const context = useContext(StudentDashboardContext);

  if (context === undefined) {
    throw new Error('useStudentDashboard must be used within a StudentDashboardProvider');
  }

  return context;
}
