import React, {useCallback} from 'react';

import {Explanation, SampleResponse} from '../shared/ContentBlocks';
import {
  QuestionComponent,
  QuestionComponentProps
} from '../shared/QuestionComponent/QuestionComponent.react';

import CompletenessBanner from './CompletenessBanner.react';
import IncompletenessBanner from './IncompletenessBanner.react';
import ErrorBoundary from './WrittenSubmission/ErrorBoundary.react';
import WrittenSubmission from './WrittenSubmission/WrittenSubmission.react';

const FreeResponseQuestionComponent = (props: QuestionComponentProps) => {
  const {activeQuestion, activeQuestionStore, guess, questionState} = props;
  const InputFragment = useCallback(() => {
    let writtenSubmissionFragment: React.ReactNode = null;
    if (questionState === 'PreGuess') {
      writtenSubmissionFragment = (
        <WrittenSubmission
          storeName={activeQuestionStore.getName()}
          question={activeQuestion}
          submitted={!!guess?.getId()}
        />
      );
    } else if (guess?.getId()) {
      writtenSubmissionFragment = (
        <>
          <CompletenessBanner />
          <WrittenSubmission
            storeName={activeQuestionStore.getName()}
            question={activeQuestion}
            submitted
          />
        </>
      );
    } else if (!guess?.getId()) {
      writtenSubmissionFragment = <IncompletenessBanner />;
    }

    return <ErrorBoundary>{writtenSubmissionFragment}</ErrorBoundary>;
  }, [activeQuestion, activeQuestionStore, guess, questionState]);

  const customExplanation = (
    <>
      <SampleResponse question={activeQuestion} />
      <Explanation question={activeQuestion} />
    </>
  );

  return (
    <QuestionComponent
      {...props}
      customExplanation={customExplanation}
      customHOA={<></>}
      customResultIndicator={<></>}
      ActiveQuestionInputArea={InputFragment}
    />
  );
};

type FreeResponseQuestionTypeProps = Omit<QuestionComponentProps, 'questionState'>;

const FreeResponseQuestion = {
  PreGuess: (props: FreeResponseQuestionTypeProps) => (
    <FreeResponseQuestionComponent {...props} questionState='PreGuess' />
  ),
  PostGuess: (props: FreeResponseQuestionTypeProps) => (
    <FreeResponseQuestionComponent {...props} questionState='PostGuess' />
  )
};

export {FreeResponseQuestion};
