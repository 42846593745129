import React, {useContext, useState, useEffect} from 'react';
import {useSlateStatic, ReactEditor} from 'slate-react';
import {AudioChipElement, DeletedAudioChipElement} from '../slate.types';
import {Transforms} from 'slate';
import {AudioPlayer} from 'generic/AudioPlayer/AudioPlayer.react';
import {getMediaUrl} from '../Utils/editor.utils';
import {deleteMedia, deleteGuessMedia, getMedia} from '../../../FreeResponseQuestion.queries';
import {ElementProps} from './Elements.types';
import {Icon, Chip, Text, Modal, Button, Card, IconButton} from '@albert-io/atomic';
import download from 'downloadjs';
import sessionStore from 'client/Session/SessionStore';
import WrittenSubmissionContext from '../../WrittenSubmission.context';
import {useErrorHandler, ErrorModal} from '../SharedModals/ErrorModal.react';
import '../written-submission.scss';

interface DeletedAudioChipProps extends ElementProps {}

export const DeletedAudioChip = ({attributes, children}: DeletedAudioChipProps) => {
  return (
    <div {...attributes}>
      <div contentEditable={false}>
        <Chip className='u-cursor_default u-gap_space-x1' error>
          <Icon icon={['far', 'music']} color='negative' />
          <Text italic size='s' color='negative'>
            Audio file removed by instructor
          </Text>
        </Chip>
        {children}
      </div>
    </div>
  );
};

interface UploadingAudioChipProps extends ElementProps {}

export const UploadingAudioChip = ({attributes, children}: UploadingAudioChipProps) => {
  return (
    <div {...attributes}>
      <div contentEditable={false}>
        <Chip className='u-cursor_default u-gap_space-x1' color='blue'>
          <Icon spin icon={['far', 'spinner']} />
          <Text italic size='s' color='brand'>
            Uploading file...
          </Text>
        </Chip>
        {children}
      </div>
    </div>
  );
};

interface AudioChipProps extends ElementProps {
  element: AudioChipElement;
}

export const AudioChip = ({attributes, children, element}: AudioChipProps) => {
  const {store, submitted} = useContext(WrittenSubmissionContext);
  const [showAudioModal, setShowAudioModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [audioChecked, setAudioChecked] = useState(false);
  const {handleError, showErrorModal, errorDetails, setShowErrorModal} = useErrorHandler();
  const editor = useSlateStatic();
  const audioUrl = getMediaUrl(element.id);
  const path = ReactEditor.findPath(editor, element);

  useEffect(() => {
    // This is a workaround that checks if audio was deleted
    const checkAudio = async (id: string) => {
      try {
        await getMedia(id);
      } catch (error) {
        const code = (error as any).status;
        if (code === 404) {
          Transforms.removeNodes(editor, {at: path});
          const Node: DeletedAudioChipElement = {
            type: 'deleted-audio-chip',
            children: [{text: ''}]
          };
          Transforms.insertNodes(editor, Node, {at: path});
        }
      }
    };
    if (!audioChecked) {
      checkAudio(element.id);
      setAudioChecked(true);
    }
  }, [element.id, editor, path, audioChecked]);

  const handleDownload = async () => {
    try {
      const response = await fetch(audioUrl);
      const mimeType = response.headers.get('content-type') || 'audio/mpeg';
      const blob = await response.blob();
      download(blob, element.caption, mimeType);
    } catch {
      handleError({action: 'download'});
    }
  };

  const deleteFromDraft = async () => {
    try {
      await deleteMedia(element.id, store!.getDraftId());
      Transforms.removeNodes(editor, {at: path});
    } catch {
      handleError({action: 'delete'});
    }
  };

  const deleteFromGuess = async () => {
    try {
      setShowDeleteModal(false);
      await deleteGuessMedia(element.id, store!.getGuessId());
      Transforms.removeNodes(editor, {at: path});
      const Node: DeletedAudioChipElement = {type: 'deleted-audio-chip', children: [{text: ''}]};
      Transforms.insertNodes(editor, Node, {at: path});
    } catch {
      handleError({action: 'delete'});
    }
  };

  const handleDelete = async () => {
    if (store === null) {
      return;
    }
    setShowAudioModal(false);
    if (!submitted) {
      deleteFromDraft();
    } else {
      setShowDeleteModal(true);
    }
  };

  return (
    <div {...attributes}>
      <div contentEditable={false}>
        <Chip
          onClick={() => {
            setShowAudioModal(true);
          }}
          className='u-cursor_pointer u-gap_space-x1'
          color='brand'
          hasHover
        >
          <Icon icon={['far', 'music']} />
          <Text size='s' color='brand'>
            {element.caption}
          </Text>
          <Icon icon={['far', 'arrow-up-right']} />
        </Chip>
        {showAudioModal && (
          <AudioModal
            id={element.id}
            userName={element.userName}
            caption={element.caption}
            onClose={() => {
              setShowAudioModal(false);
            }}
            handleDelete={handleDelete}
            handleDownload={handleDownload}
            showDeleteButton={!submitted || !sessionStore.isStudent()}
          />
        )}
        {showDeleteModal && (
          <DeleteAudioModal
            onClose={() => setShowDeleteModal(false)}
            onCancel={() => {
              setShowDeleteModal(false);
              setShowAudioModal(true);
            }}
            onConfirm={deleteFromGuess}
          />
        )}
        {showErrorModal && (
          <ErrorModal
            errorDetails={errorDetails}
            onClose={() => {
              setShowErrorModal(false);
            }}
          />
        )}
        {children}
      </div>
    </div>
  );
};

interface AudioModalProps {
  id: string;
  caption?: string;
  onClose: any;
  userName: string;
  handleDelete: any;
  handleDownload: any;
  showDeleteButton: boolean;
}

export const AudioModal = ({
  id,
  caption,
  onClose,
  userName,
  handleDelete,
  handleDownload,
  showDeleteButton
}: AudioModalProps) => {
  const src = getMediaUrl(id);

  return (
    <Modal handleClose={onClose} ariaLabel='Audio Player Modal'>
      {() => {
        return (
          <Card className='written-submission__audio-player-modal'>
            <IconButton
              className='written-submission__audio-player-modal__close-icon'
              icon='times'
              variant='text'
              onClick={onClose}
              aria-hidden={false}
            />
            <Text className='u-pad-t_2'>Submitted by {userName}</Text>
            <AudioPlayer src={src} caption={caption} />
            <div>
              {showDeleteButton && (
                <Button
                  className='u-gap_space-x1'
                  onClick={handleDelete}
                  variant='text'
                  color='secondary'
                >
                  <Icon icon={['far', 'trash-can']} />
                  Delete
                </Button>
              )}
              <Button
                className='u-gap_space-x1'
                onClick={handleDownload}
                variant='text'
                color='secondary'
              >
                <Icon icon={['far', 'download']} />
                Download
              </Button>
            </div>
          </Card>
        );
      }}
    </Modal>
  );
};

interface DeleteAudioModalProps {
  onConfirm: any;
  onCancel: any;
  onClose: any;
}

export const DeleteAudioModal = ({onClose, onConfirm, onCancel}: DeleteAudioModalProps) => {
  return (
    <Modal handleClose={onClose} role='dialog' ariaLabel='Delete File'>
      {({CloseButtonWrapper}) => {
        return (
          <Card className='written-submission__modal'>
            <div className='written-submission__modal__body'>
              <Text bold className='u-text-align_center'>
                Are you sure you want to delete this file?
              </Text>
              <Text className='u-text-align_center'>
                The file will be removed permanently from Albert. This action cannot be undone.
              </Text>
            </div>
            <div className='written-submission__modal__button-group'>
              <Button color='secondary' variant='outlined' onClick={onCancel}>
                No, Cancel
              </Button>
              <CloseButtonWrapper>
                <Button color='negative' onClick={onConfirm}>
                  Yes, Delete
                </Button>
              </CloseButtonWrapper>
            </div>
          </Card>
        );
      }}
    </Modal>
  );
};
