/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import {Link} from 'react-router';
import PropTypes from 'prop-types';
import {Map, List, Set} from 'immutable';
import track from 'react-tracking';

import notifier from '@albert-io/notifier';
import {login} from '@albert-io/mandark/authentication';
import {addToast, Anchor, Text} from '@albert-io/atomic';

import {UserModelV2} from 'resources/GeneratedModels/User/UserModel.v2';

import {setUpStore, callTargetedAction} from 'client/framework';
import errorCodes from 'client/errorCodes';
import ErrorStateStore from 'client/generic/Forms/ErrorState/ErrorState.store';
import formActions from 'client/generic/Forms/ErrorState/Form.actions';
import FormDivider from 'client/components/Forms/FormDivider/FormDivider';
import {LOG_IN_PAGE_BASE_PATH} from 'client/LogIn/utils';
import {redirect} from 'client/User/UserRedirectUtil';

import TextInput from 'generic/Forms/TextInput/TextInput';

import {
  completeAccountTypeSelection,
  completeParentalConsent,
  completeCoppaAgeScreener
} from 'lib/OnboardingUtil/OnboardingUtil';

import './assets/sign-up-form.scss';

import GoogleButton from './SocialButton/Google/GoogleButton.react';
import CleverButton from './SocialButton/Clever/CleverButton.react';

const signUpFormErrorStateStoreName = 'SignUpForm-ErrorStateStore';

/**
 * Errors in the sign up form are managed by two structures:
 *
 * 1. fieldErrors - manages inline validation errors
 * 2. errorState - manages API validation errors
 *
 * The errorState structure is populated during the response processing done in the
 * signUpHandler. While inputs change, any tracked validation error will be cleared by
 * the created input handler (function returned by makeInputChangeHandler), if a matching
 * key is found in the error state store.
 *
 * for "unhandled" errors, the errorMessage state value is available.
 *
 * @param setterName
 * @param fieldName
 * @param validatorName
 */
@track({component: 'SignUpForm'})
export default class SignUpForm extends React.Component {
  static propTypes = {
    location: PropTypes.shape({
      query: PropTypes.shape({
        email: PropTypes.string,
        school_id: PropTypes.string,
        source: PropTypes.oneOf(['school_personnel_invitation'])
      })
    }),
    tracking: PropTypes.object
  };

  constructor(props) {
    super(props);

    let user = UserModelV2.getDefaultModel();

    this.fields = new Set([
      'getFirstName',
      'getLastName',
      'getEmail',
      'getUsername',
      'getPassword'
    ]);

    const email = decodeURIComponent(this.props.location.query.email || '');
    user = email ? user.setEmail(email) : user;

    const school = decodeURIComponent(this.props.location.query.school_id || '');
    user = school ? user.updateRelationship('school_v2', school) : user;

    // Initialize our user data to an actual user object (with email & school set when appropriate)
    this.state = {
      requestInFlight: false,
      user,
      fieldErrors: Map(),
      errorMessage: null,
      isLoading: true
    };
  }

  componentDidMount() {
    this.setState({isLoading: false});
  }

  getFormErrorStateStore() {
    return setUpStore(ErrorStateStore, signUpFormErrorStateStoreName);
  }

  isSchoolPersonnelInvite() {
    return Boolean(
      this.props.location.query.source === 'school_personnel_invitation' &&
        this.props.location.query.email &&
        this.props.location.query.school_id
    );
  }

  /*
    This takes our student or user field list and modifies our error structure after checking the incoming user's
    validator results for every field getter.

    this gives us a fully populated error map.
  */
  fullyValidate = (user) => {
    // eslint-disable-next-line array-callback-return
    this.fields.map((fieldGetter) => {
      let {fieldErrors} = this.state;
      const fieldErrorList = user.validators[fieldGetter]();
      if (fieldErrorList) {
        fieldErrors = fieldErrors.set(fieldGetter, fieldErrorList.first());
      } else {
        fieldErrors = fieldErrors.set(fieldGetter, null);
      }
      this.setState({
        fieldErrors
      });
    });
  };

  hasErrors = () => {
    return this.state.fieldErrors.some((error) => {
      return Boolean(error);
    });
  };

  signUpHandler = async () => {
    if (!this.getFormErrorStateStore().getFormErrors().isEmpty()) {
      callTargetedAction({
        name: formActions.CLEAR_FORM_ERRORS,
        targetStore: signUpFormErrorStateStoreName
      });
    }

    /*
      fullyValidate sets up the error object used in this.hasErrors()

      blur events will also modify this error structure, but will not fully populate it.
      the fullyValidate method will populate it with each field's key allowing us to
      force the form to show all inline error messaging/highlighting on, say, an empty submit
    */
    let {user} = this.state;
    this.fullyValidate(user);
    if (this.hasErrors()) {
      return;
    }

    try {
      this.setState({
        requestInFlight: true
      });

      /*
        A successful user POST here will be followed immediately
        by an attempt to sign in as the created user
      */
      try {
        user = user
          /**
           * CAUTION: These need to be manually nulled out for the POST,
           * but also @see payloadMutator below: there are additional keys
           * that must be deleted!
           */
          .setSentPasswordResetEmailAt(null)
          .setSentConfirmationEmailAt(null)
          // This is required by the sign up endpoint, and the default is not acceptable
          .setSignUpMethod('email');

        /**
         * If a user signing up is coming from a personnel invite - we
         * artifically force a teacher account type selection, and also
         * complete that part of the onboarding.
         */
        if (this.isSchoolPersonnelInvite()) {
          user = user.setIsTeacher(true);
          user = completeAccountTypeSelection(user);
          user = completeParentalConsent(user);
          user = completeCoppaAgeScreener(user);
        }

        await user.save({
          payloadMutator: (payload) => {
            /* eslint-disable no-param-reassign */
            delete payload.data.attributes.sent_created_user_email_at;
            delete payload.data.attributes.customer_feedback_reminder_date;
            /* eslint-enable no-param-reassign */
            return payload;
          }
        });
        this.props.tracking.trackEvent({
          event: 'sign_up',
          method: 'email'
        });
      } catch (e) {
        // Check explicitly for a duplicate email/username response:
        if (e.status === 400) {
          let error = Map({
            field: '',
            message: 'There was a problem creating your account. Please try again later.'
          });
          const response = e.response.body.errors[0];
          if (
            response.code === errorCodes.BAD_REQUEST.VALIDATION_ERROR.UNIQUE_CONSTRAINT_VIOLATED
          ) {
            if (response.source.parameter === 'email') {
              error = Map({
                field: 'email',
                message: 'That email is already in use.'
              });
            } else if (response.source.parameter === 'username') {
              error = Map({
                field: 'username',
                message: 'That username is already in use.'
              });
            }
          }

          callTargetedAction({
            name: formActions.SET_FORM_ERRORS,
            payload: List([error]),
            targetStore: signUpFormErrorStateStoreName
          });
        } else {
          notifier.notify(e, {
            name: 'Sign up failure: error saving user via POST',
            component: 'SignUpForm'
          });
          this.setState({
            errorMessage: 'There was a problem creating your account. Please try again later.'
          });
        }

        throw e;
      }

      const loginPayload = {
        username: user.getUsername(),
        password: user.getPassword()
      };

      try {
        await login(loginPayload);
      } catch (e) {
        this.setState({
          errorMessage:
            'There was a problem signing in to your new account. Please try again later.'
        });
        throw e;
      }

      redirect();

      addToast({
        message: "We've sent you a confirmation to the email you entered.",
        title: 'Your account is registered successfully!',
        color: 'positive'
      });
    } catch (e) {
      // Errors handled inside inner try/catch
    } finally {
      this.setState({
        requestInFlight: false
      });
    }
  };

  makeInputChangeHandler = (setterName) => {
    return (e) => {
      const {user} = this.state;
      const modifiedUser = user[setterName](e.target.value);
      this.setState({
        user: modifiedUser
      });
    };
  };

  makeBlurHandler = (fieldName, validatorName) => {
    return () => {
      let {fieldErrors} = this.state;

      const {user} = this.state;

      // execute the provided validator on the user's validators strucutre
      const errorList = user.validators[validatorName]();

      // write either null / the error returned from the validator
      if (errorList) {
        fieldErrors = fieldErrors.set(fieldName, errorList.first());
      } else {
        fieldErrors = fieldErrors.set(fieldName, null);
      }

      this.setState({
        fieldErrors
      });
    };
  };

  /**
   * Prioritizes errors that are not explicitly handled.
   *
   * @returns {React.ReactNode|null} A renderable node containing errors if they exist.
   */
  getApiErrors() {
    const formErrorStateStore = this.getFormErrorStateStore();
    if (this.state.errorMessage) {
      return (
        <div className='a-form-row'>
          <div className='a-form-error-container'>
            <span className='a-form-error-title'>Error:</span>
            {this.state.errorMessage}
          </div>
        </div>
      );
    }
    if (!formErrorStateStore.getFormErrors().isEmpty()) {
      return (
        <div className='a-form-row'>
          <div className='a-form-error-container'>
            <ul>
              {formErrorStateStore.getFormErrors().map((error, i) => {
                return (
                  <li key={i}>
                    <span className='a-form-error-title'>Error:</span>
                    {error.get('message')}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      );
    }

    return null;
  }

  render() {
    /**
     * In the case of a personnel invitation, we force the user who chooses a social sign up to be a teacher.
     *
     * @todo This is not being used anymore... seems like we should remove it, but also need to verify it's working as expected.
     */
    // const isTeacherString = this.isSchoolPersonnelInvite() ? `?isTeacher=true` : '';
    const socialButtonsContent = !this.isSchoolPersonnelInvite() ? (
      <div>
        <div className='a-form-row'>
          <GoogleButton disabled={this.state.isLoading || this.state.requestInFlight} />
        </div>
        <div className='a-form-row'>
          <CleverButton disabled={this.state.isLoading || this.state.requestInFlight} />
        </div>
      </div>
    ) : null;

    const dividerContent = !this.isSchoolPersonnelInvite() ? (
      <FormDivider>
        <Text color='secondary'>or</Text>
      </FormDivider>
    ) : null;

    return (
      <div className='sign-up-content'>
        <div className='a-form-wrapper sign-up-form-wrapper'>
          <form className='a-form'>
            {socialButtonsContent}
            {dividerContent}
            {this.getApiErrors()}
            <div className='a-form-row'>
              <div className='a-form-col'>
                <TextInput
                  name='getFirstName'
                  label='First Name'
                  type='text'
                  autoComplete='given-name'
                  required
                  maxLength={128}
                  value={this.state.user.getFirstName()}
                  error={Boolean(this.state.fieldErrors.get('getFirstName'))}
                  errorMessage={this.state.fieldErrors.get('getFirstName')}
                  onFocusValidation
                  onChange={this.makeInputChangeHandler('setFirstName')}
                  onBlur={this.makeBlurHandler('getFirstName', 'getFirstName')}
                />
              </div>
              <div className='a-form-col'>
                <TextInput
                  name='getLastName'
                  label='Last Name (or School ID)'
                  type='text'
                  autoComplete='family-name'
                  required
                  maxLength={128}
                  value={this.state.user.getLastName()}
                  error={Boolean(this.state.fieldErrors.get('getLastName'))}
                  errorMessage={this.state.fieldErrors.get('getLastName')}
                  onFocusValidation
                  onChange={this.makeInputChangeHandler('setLastName')}
                  onBlur={this.makeBlurHandler('getLastName', 'getLastName')}
                />
              </div>
            </div>
            <div className='a-form-row'>
              <TextInput
                disabled={this.isSchoolPersonnelInvite() && !this.state.fieldErrors.get('getEmail')}
                name='getEmail'
                label='Email'
                type='email'
                autoComplete='email'
                required
                maxLength={128}
                value={this.state.user.getEmail()}
                error={Boolean(this.state.fieldErrors.get('getEmail'))}
                errorMessage={this.state.fieldErrors.get('getEmail')}
                onFocusValidation
                onChange={this.makeInputChangeHandler('setEmail')}
                onBlur={this.makeBlurHandler('getEmail', 'getEmail')}
              />
            </div>
            <div className='a-form-row'>
              <TextInput
                name='getUsername'
                label='Username'
                type='text'
                autoComplete='username'
                required
                maxLength={128}
                value={this.state.user.getUsername()}
                error={Boolean(this.state.fieldErrors.get('getUsername'))}
                errorMessage={this.state.fieldErrors.get('getUsername')}
                onFocusValidation
                onChange={this.makeInputChangeHandler('setUsername')}
                onBlur={this.makeBlurHandler('getUsername', 'getUsername')}
              />
            </div>
            <div className='a-form-row'>
              <TextInput
                name='getPassword'
                label='Password'
                type='password'
                autoComplete='new-password'
                required
                value={this.state.user.getPassword()}
                error={Boolean(this.state.fieldErrors.get('getPassword'))}
                errorMessage={this.state.fieldErrors.get('getPassword')}
                onFocusValidation
                onChange={this.makeInputChangeHandler('setPassword')}
                onBlur={this.makeBlurHandler('getPassword', 'getPassword')}
              />
            </div>
            <div className='a-form-row'>
              <button
                className='a-form-submit-button'
                disabled={this.state.isLoading || this.state.requestInFlight}
                type='submit'
                ref={(ref) => {
                  this.signUpButton = ref;
                }}
                onClick={(e) => {
                  e.preventDefault();
                  this.signUpHandler();
                }}
              >
                Sign up
              </button>
            </div>
            <div className='a-form-row'>
              <span>
                Already have an account?&nbsp;
                <Anchor as={Link} to={LOG_IN_PAGE_BASE_PATH} tabIndex='0'>
                  Log In
                </Anchor>
              </span>
            </div>
          </form>
        </div>
        <div className='sign-up-form-user-agreement'>
          By signing up for Albert, you agree to our&nbsp;
          <Anchor href='/terms-of-use'>Terms of Use</Anchor>
          &nbsp;and&nbsp;
          <Anchor href='/privacy'>Privacy Policy</Anchor>.
        </div>
      </div>
    );
  }
}
