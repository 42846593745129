// @flow
import {fromJS, List, Map, Record} from 'immutable';
import {GenericModel} from 'resources/Generic.model';

import type {SchoolLicenseModel} from 'resources/SchoolLicense/SchoolLicense.model';

const SchoolRecord = Record({
  address: '',
  district: '',
  id: '',
  meta: new Map({
    count_of_assignments: null,
    count_of_available_seats: null,
    count_of_classrooms: null,
    count_of_students: null,
    count_of_teachers: null,
    count_of_used_seats: null
  }),
  name: '',
  school_licenses_v1: new List(),
  school_type: ''
});

const schoolConfig = fromJS({
  endpoint: 'schools_v2',
  relationships: {}
});

export class SchoolModelV2 extends GenericModel(SchoolRecord, schoolConfig) {
  getId(): string {
    return this.get('id');
  }

  getName(): string {
    return this.get('name');
  }

  getAddress(): string {
    return this.get('address');
  }

  getCountOfAssignments(): number {
    return this.getIn(['meta', 'count_of_assignments']);
  }

  getCountOfClassrooms(): number {
    return this.getIn(['meta', 'count_of_classrooms']);
  }

  getCountOfStudents(): number {
    return this.getIn(['meta', 'count_of_students']);
  }

  getCountOfTeachers(): number {
    return this.getIn(['meta', 'count_of_teachers']);
  }

  getDistrict(): string {
    return this.get('district');
  }

  getSchoolType(): string {
    return this.get('school_type');
  }

  getSchoolLicenses(): List<SchoolLicenseModel> {
    return this.get('school_licenses_v1');
  }

  _getMeta(): Map<string, *> {
    return this.get('meta');
  }

  getCountOfAvailableSeats(): number {
    return this._getMeta().get('count_of_available_seats');
  }

  getCountOfUsedSeats(): number {
    return this._getMeta().get('count_of_used_seats');
  }
}
