import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {defer, isUndefined} from 'lodash';
import {callTargetedAction, setUpStore} from 'client/framework';
import interactionEngineStore from 'client/InteractionEngineV2/InteractionEngineStore';
import studentAssignmentQuestionsListStore from 'client/InteractionEngineV2/IESessionTypes/Assignments/StudentAssignmentIE/StudentAssignmentQuestionsList/StudentAssignmentQuestionsList.store';

import textHighlightQuestionActions from './TextHighlightQuestion.actions';
import TextHighlightQuestionStore from './TextHighlightQuestion.store';

export default class TextHighlightPrompt extends React.Component {
  static propTypes = {
    explanation: PropTypes.bool,
    storeName: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.highlightAreaWrapperNode = null;
    this.mouseMoveTicking = false;
  }

  componentWillUnmount() {
    global.document.removeEventListener('mouseup', this.handleEndSelection);
    global.document.removeEventListener('touchend', this.handleTouchEnd);
  }

  getStore() {
    return setUpStore(TextHighlightQuestionStore, this.props.storeName);
  }

  getClassNames(index) {
    const baseClass = 'text-highlight-area__char';
    const highlightClass = 'text-highlight-area__char--highlight';
    const store = this.getStore();
    return this.props.explanation
      ? classnames(baseClass, {
          [highlightClass]: store.isIndexInPreferredPermutation(index)
        })
      : classnames(baseClass, {
          [highlightClass]: store.isIndexSelected(index),
          'text-highlight-area__char--current': store.isIndexInCurrentSelection(index),
          'text-highlight-area__char--limit':
            index === store.startIndex || index === store.potentialIndex
        });
  }

  getTargetElementFromTouchEventData(eventProperty) {
    const {pageX, pageY} = eventProperty[eventProperty.length - 1];
    const {scrollX, scrollY} = window;
    const xPos = pageX - scrollX;
    const yPos = pageY - scrollY;
    return global.document.elementFromPoint(xPos, yPos);
  }

  handleMouseDown = (e) => {
    global.document.addEventListener('mouseup', this.handleEndSelection);
    this.startSelection(e.target.dataset.index);
  };

  handleTouchStart = (e) => {
    e.preventDefault();
    global.document.addEventListener('touchend', this.handleTouchEnd);
    this.startSelection(e.target.dataset.index);
  };

  startSelection(index) {
    if (this.getStore().isGuessSubmitted) {
      return;
    }
    callTargetedAction({
      name: textHighlightQuestionActions.START_SELECTION,
      payload: index,
      targetStore: this.props.storeName
    });
  }

  handleMouseEnter = (e) => {
    this.pendingSelectionEnd(e.target.dataset.index);
  };

  handleTouchMove = (e) => {
    e.preventDefault();
    if (this.mouseMoveTicking) {
      return;
    }
    this.mouseMoveTicking = true;
    const target = this.getTargetElementFromTouchEventData(e.targetTouches);
    this.pendingSelectionEnd(target.dataset.index);
    defer(() => {
      this.mouseMoveTicking = false;
    });
  };

  pendingSelectionEnd(index) {
    if (this.getStore().startIndex === null) {
      return;
    }
    callTargetedAction({
      name: textHighlightQuestionActions.POTENTIAL_SELECTION,
      payload: index,
      targetStore: this.props.storeName
    });
  }

  handleEndSelection = (e) => {
    global.document.removeEventListener('mouseup', this.handleEndSelection);
    this.endSelection(e.target.dataset.index, e.target);
  };

  handleTouchEnd = (e) => {
    global.document.removeEventListener('touchend', this.handleTouchEnd);
    const target = this.getTargetElementFromTouchEventData(e.changedTouches);
    const {index} = target.dataset;
    this.endSelection(index, target);
  };

  endSelection(index, targetEl) {
    if (isUndefined(index) || !this.highlightAreaWrapperNode.contains(targetEl)) {
      callTargetedAction({
        name: textHighlightQuestionActions.CLEAR_PENDING_SELECTION,
        targetStore: this.props.storeName
      });
      return;
    }
    callTargetedAction({
      name: textHighlightQuestionActions.END_SELECTION,
      payload: index,
      targetStore: this.props.storeName
    });
  }

  render() {
    const store = this.getStore();
    const formattedPrompt = store.highlightPrompt.split('').map((char, i) => {
      return (
        <span
          key={i}
          className={this.getClassNames(i)}
          data-index={`${i}`}
          onMouseDown={this.handleMouseDown}
          onMouseEnter={this.handleMouseEnter}
          onTouchStart={this.handleTouchStart}
          onTouchMove={this.handleTouchMove}
        >
          {char}
        </span>
      );
    });
    const {isGuessSubmitted} = store;
    const isExplanation = this.props.explanation;
    const shouldPreventInteraction = isGuessSubmitted || isExplanation;
    // this.props.isCorrect is false if you haven't answered, which is weird
    // but that's why I'm being so explicit about this
    const isCorrect = !isExplanation && isGuessSubmitted && this.props.isCorrect;
    const isIncorrect = !isExplanation && isGuessSubmitted && !this.props.isCorrect;

    let showGuessFeedback = true;
    if (
      interactionEngineStore.isStudentAssignmentSession &&
      !studentAssignmentQuestionsListStore.getAssignment().shouldShowGuessFeedback()
    ) {
      showGuessFeedback = false;
    }

    const classNames = classnames('text-highlight-area', this.props.className, {
      'text-highlight-area--prevent-interaction': shouldPreventInteraction,
      'text-highlight-area--correct': isCorrect && showGuessFeedback,
      'text-highlight-area--incorrect': isIncorrect && showGuessFeedback
    });
    return (
      <div
        className={classNames}
        ref={(node) => {
          this.highlightAreaWrapperNode = node;
        }}
      >
        {formattedPrompt}
      </div>
    );
  }
}
