export default function makeRef(this: React.Component, node: HTMLElement) {
  // TODO-TS: This conditional logic makes no sense given the parameter type, but since many
  // files that consume this are JS I am hesistant to change the behavior.
  // This should be refactored once all consumers have been migrated to TS.
  if (node) {
    const {refName} = node.dataset;
    if (!refName) {
      throw new Error('node passed to makeRef must have a data-ref-name attribute');
    }
    this[refName] = node;
  }
}
