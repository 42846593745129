import React, {useState} from 'react';
import {UserModelV2} from 'resources/augmented/User/UserModel.v2';
import {Text, Button, Icon} from '@albert-io/atomic';
import notifier from '@albert-io/notifier';
import {
  completeAccountTypeSelection,
  completeCoppaAgeScreener,
  completeParentalConsent
} from 'lib/OnboardingUtil/OnboardingUtil';
import classNames from 'classnames';
import './select-account-type.scss';

const AccountTypes = {
  Teacher: 'Teacher',
  Student: 'Student'
} as const;

type AccountType = (typeof AccountTypes)[keyof typeof AccountTypes];

interface SelectAccountTypeProps {
  user: UserModelV2;
  onComplete: () => {};
}

export const SelectAccountType = ({user: userProp, onComplete}: SelectAccountTypeProps) => {
  const [accountTypeSelection, setAccountTypeSelection] = useState<AccountType | null>(null);

  const saveUser = async () => {
    const isTeacher = accountTypeSelection === AccountTypes.Teacher;
    let user = userProp.setIsTeacher(isTeacher);

    try {
      user = completeAccountTypeSelection(user);

      if (user.isTeacher()) {
        user = user.setNeedsCoppa(false);
        user = completeCoppaAgeScreener(user);
        user = completeParentalConsent(user);
      }

      await user.save();
      onComplete();
    } catch (e) {
      notifier.notify(e, {
        component: 'SelectAccountType',
        name: 'Onboarding: unable to save user account type selection',
        userId: user.getId()
      });
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent, accountType: AccountType) => {
    if (event.key === 'Enter') {
      setAccountTypeSelection(accountType);
    }
  };

  return (
    <div className='select-account-type'>
      <Text bold size='xl' className='u-text-align_center u-align-self_stretch'>
        Sign up for Albert as a...
      </Text>
      <div className='select-account-type__container'>
        <div className='select-account-type__cards'>
          <div
            className={classNames('select-account-type__card', {
              'select-account-type__card--selected': accountTypeSelection === AccountTypes.Student
            })}
            onClick={() => {
              setAccountTypeSelection(AccountTypes.Student);
            }}
            onKeyDown={(event) => handleKeyDown(event, AccountTypes.Student)}
            role='button'
            tabIndex={0}
          >
            <Text bold size='l'>
              Student
            </Text>
            <div className='select-account-type__icon-wrapper'>
              <Icon className='select-account-type__icon' icon='backpack' />
            </div>
          </div>
          <div
            className={classNames('select-account-type__card', {
              'select-account-type__card--selected': accountTypeSelection === AccountTypes.Teacher
            })}
            onClick={() => {
              setAccountTypeSelection(AccountTypes.Teacher);
            }}
            onKeyDown={(event) => handleKeyDown(event, AccountTypes.Teacher)}
            role='button'
            tabIndex={0}
          >
            <Text bold size='l'>
              Teacher
            </Text>
            <div className='select-account-type__icon-wrapper'>
              <Icon className='select-account-type__icon' icon='person-chalkboard' />
            </div>
          </div>
        </div>
        <div className='select-account-type__details'>
          <Text color='tertiary'>Account type cannot be changed after this step.</Text>
          <Button
            className='select-account-type__button'
            disabled={!accountTypeSelection}
            onClick={() => saveUser()}
            role='button'
          >
            {!accountTypeSelection
              ? 'Continue'
              : `Continue As A ${AccountTypes[accountTypeSelection]}`}
          </Button>
        </div>
      </div>
    </div>
  );
};
