import React from 'react';
import PropTypes from 'prop-types';
import {fromJS, Map, List} from 'immutable';
import IESubheader from 'client/InteractionEngineV2/shared/IESubheader/IESubheader.react';
import {AssignmentModelV3} from 'resources/GeneratedModels/Assignment/AssignmentModel.v3';
import appStore from 'client/AppStore';

export default class AssignmentV2IESubheader extends React.Component {
  static propTypes = {
    isReady: PropTypes.bool.isRequired,
    assignment: PropTypes.instanceOf(AssignmentModelV3),
    activeSection: PropTypes.string,
    rightContent: PropTypes.node
  };

  getContent() {
    if (
      !this.props.isReady ||
      /**
       * Temp fix for mobile. If we have a timer and button, there's not enough room to show
       * the breadcrumbs as well, so we'll just hide them for now.
       */
      (this.props.rightContent && appStore.isMobileView())
    ) {
      return List();
    }
    let subheaderContent = fromJS([
      {
        displayName: this.props.assignment.getName()
      }
    ]);
    if (this.props.activeSection) {
      subheaderContent = subheaderContent.push(
        Map({
          displayName: this.props.activeSection
        })
      );
    }
    return subheaderContent;
  }

  render() {
    return (
      <IESubheader
        isLoading={!this.props.isReady}
        content={this.getContent()}
        rightContent={this.props.rightContent}
      />
    );
  }
}
