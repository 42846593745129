import React, {useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import {debounce} from 'lodash';
import {GuessModelV1, QuestionModelV3, QuestionSetModelV1, SubjectModelV2} from '@albert-io/models';
import {Card, Footer, Text} from '@albert-io/atomic';
import SplitPane from 'client/components/SplitPane/SplitPane.react';
import {useShallow} from 'zustand/react/shallow';

import DraftGuessWrapper from '../../../../AssignmentPracticeView/DraftGuesses/DraftGuessWrapper';

import {
  InteractivityHandler,
  Explanation,
  Prompt,
  ResultIndicatorBar,
  StandardsTable,
  Standards,
  Tags
} from '../ContentBlocks';

import HowOthersAnswered from '../HowOthersAnswered/HowOthersAnswered.react';
import {HeadingBar} from '../HeadingBar/HeadingBar.react';
import PostSubmissionScrollPoint from '../PostSubmissionScrollPoint/PostSubmissionScrollPoint';
import QuestionTypeStore from '../../QuestionType.store';
import '../QuestionWrapper/question-wrapper.scss';
import './question-component.scss';
import {useQuestionTranslationStore, TranslationHeader} from '../TranslationSection';

interface PreGuessQuestionContentProps {
  activeQuestion: QuestionModelV3;
  activeQuestionStore: typeof QuestionTypeStore;
  ActiveQuestionInputArea: React.ComponentType<any>;
  interactive?: boolean;
}

const PreGuessQuestionContent = ({
  activeQuestion,
  activeQuestionStore,
  ActiveQuestionInputArea,
  interactive
}: PreGuessQuestionContentProps) => {
  return (
    <DraftGuessWrapper storeName={activeQuestionStore.getName()} submitted={false}>
      <InteractivityHandler interactive={interactive}>
        <ActiveQuestionInputArea
          isGuessSubmitted={false}
          isGuessCorrect={false}
          storeName={activeQuestionStore.getName()}
          question={activeQuestion}
          questionId={activeQuestion.getId()}
        />
      </InteractivityHandler>
    </DraftGuessWrapper>
  );
};

interface PostGuessQuestionContentProps {
  activeQuestion: QuestionModelV3;
  activeQuestionSet: QuestionSetModelV1;
  activeQuestionStore: typeof QuestionTypeStore;
  activeSubject: SubjectModelV2;
  ActiveQuestionInputArea: React.ComponentType<any>;
  customExplanation?: React.ReactElement; // custom explanation component
  customHOA?: React.ReactElement; // custom how others answered component
  customResultIndicator?: React.ReactElement; // custom result indicator component
  // No idea what eslint is complaining about here. The prop is used in multiple places.
  // eslint-disable-next-line react/no-unused-prop-types
  isRedesign?: boolean;
  guess?: GuessModelV1;
  postSubmissionScrollPointRef?: React.RefObject<any>;
}

const PostGuessQuestionContent = ({
  activeQuestion,
  activeQuestionSet,
  activeQuestionStore,
  activeSubject,
  ActiveQuestionInputArea,
  customExplanation,
  customHOA,
  customResultIndicator,
  guess,
  isRedesign,
  postSubmissionScrollPointRef
}: PostGuessQuestionContentProps) => {
  return (
    <DraftGuessWrapper storeName={activeQuestionStore.getName()} submitted>
      <ActiveQuestionInputArea
        isGuessSubmitted
        isGuessCorrect={guess?.isCorrect()}
        storeName={activeQuestionStore.getName()}
        question={activeQuestion}
        questionId={activeQuestion.getId()}
      />
      <PostSubmissionScrollPoint ref={postSubmissionScrollPointRef} />
      {customResultIndicator ?? <ResultIndicatorBar guess={guess} />}
      {customExplanation ?? <Explanation question={activeQuestion} />}
      {customHOA ?? <HowOthersAnswered question={activeQuestion} />}
      {!isRedesign && activeSubject.isShouldShowLabelTable() && (
        <StandardsTable question={activeQuestion} />
      )}
      {!isRedesign && (
        <Standards
          question={activeQuestion}
          subject={activeSubject}
          questionSet={activeQuestionSet}
        />
      )}
      {!isRedesign && (
        <Tags question={activeQuestion} subject={activeSubject} questionSet={activeQuestionSet} />
      )}
    </DraftGuessWrapper>
  );
};

interface QuestionContentProps {
  questionState: 'PreGuess' | 'PostGuess';
}

type CombinedProps = QuestionContentProps &
  (PreGuessQuestionContentProps | PostGuessQuestionContentProps);

const QuestionContent = (props: CombinedProps) => {
  const {questionState, ...rest} = props;

  if (questionState === 'PreGuess') {
    const {activeQuestion, activeQuestionStore, ActiveQuestionInputArea, interactive} =
      rest as PreGuessQuestionContentProps;
    return (
      <PreGuessQuestionContent
        activeQuestion={activeQuestion}
        activeQuestionStore={activeQuestionStore}
        ActiveQuestionInputArea={ActiveQuestionInputArea}
        interactive={interactive}
      />
    );
  }

  const {
    activeQuestion,
    activeQuestionSet,
    activeQuestionStore,
    activeSubject,
    ActiveQuestionInputArea,
    customExplanation,
    customHOA,
    customResultIndicator,
    guess,
    isRedesign,
    postSubmissionScrollPointRef
  } = rest as PostGuessQuestionContentProps;
  return (
    <PostGuessQuestionContent
      activeQuestion={activeQuestion}
      activeQuestionSet={activeQuestionSet}
      activeQuestionStore={activeQuestionStore}
      activeSubject={activeSubject}
      ActiveQuestionInputArea={ActiveQuestionInputArea}
      customExplanation={customExplanation}
      customHOA={customHOA}
      customResultIndicator={customResultIndicator}
      guess={guess}
      isRedesign={isRedesign}
      postSubmissionScrollPointRef={postSubmissionScrollPointRef}
    />
  );
};

export interface QuestionComponentProps
  extends QuestionContentProps,
    PreGuessQuestionContentProps,
    PostGuessQuestionContentProps {
  actions: React.ReactElement;
  customHeader?: React.ReactNode;
  displayOrientation: 'default' | 'vertical' | 'horizontal';
  hasHeading?: boolean;
  hasQuestionSetAccessPredicate: (questionSet: QuestionSetModelV1) => boolean;
  headingActions: React.ReactNode;
  isMobile?: boolean;
  modifyTitle?: (args: any) => void;
  navigateBackProps?: {
    disabled: boolean;
    handleNavigation: () => void;
  };
  navigateNextProps?: {
    disabled: boolean;
    handleNavigation: () => void;
  };
  resizerPosition?: number;
  onResize?: (size: number) => void;
  showDifficulty: boolean;
}

type QuestionComponentContentSplitProps = Omit<
  QuestionComponentProps,
  'displayOrientation' | 'headingActions' | 'showDifficulty'
>;

const PANE_MIN_WIDTH = 280;
const CONTAINER_PADDING = 32;

const QuestionComponentContentSplit = ({
  actions,
  activeQuestion,
  activeQuestionSet,
  activeQuestionStore,
  activeSubject,
  ActiveQuestionInputArea,
  customExplanation,
  customHOA,
  customResultIndicator,
  guess,
  hasQuestionSetAccessPredicate,
  interactive,
  navigateBackProps,
  navigateNextProps,
  onResize,
  postSubmissionScrollPointRef,
  questionState,
  resizerPosition
}: QuestionComponentContentSplitProps) => {
  const splitPaneRef = useRef<HTMLDivElement | null>(null);
  // using the width of the split pane to determine the max size of the left pane (in other words, enforcing the min size for the right pane)
  // falling back on using the viewport width minus padding since we can assume that the split pane will be close to the full width of the viewport
  const [elementSize, setElementSize] = useState<number>(window.innerWidth - CONTAINER_PADDING);

  const {
    // fetchingTranslations,
    showPromptTranslation,
    showQuestionTranslation,
    setShowPromptTranslation,
    setShowQuestionTranslation
  } = useQuestionTranslationStore(
    useShallow((state) => ({
      // fetchingTranslations: state.fetchingTranslations,
      showPromptTranslation: state.toggleStatus.prompt,
      showQuestionTranslation: state.toggleStatus.answer,
      setShowPromptTranslation: state.onToggle.prompt,
      setShowQuestionTranslation: state.onToggle.answer
    }))
  );

  useEffect(() => {
    const handleResize = () => {
      const newSize =
        splitPaneRef.current?.getBoundingClientRect().width ??
        window.innerWidth - CONTAINER_PADDING;
      setElementSize(newSize);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  return (
    <div ref={splitPaneRef} className='u-height_100pc split-content-wrapper'>
      <SplitPane
        allowResize
        primary='first'
        split='vertical'
        maxSize={elementSize - PANE_MIN_WIDTH}
        minSize={PANE_MIN_WIDTH}
        defaultSize={resizerPosition}
        onChange={debounce((size) => onResize?.(size), 100)}
        style={{position: 'relative'}}
        resizerClassName='resizer-grabber question-component-resizer'
        pane1Style={{display: 'flex', flexDirection: 'column', overflow: 'auto'}}
        pane2Style={{display: 'flex', flexDirection: 'column', overflow: 'auto'}}
      >
        <Card className='question-wrapper question-split-pane instructions-pane'>
          <div className='question-wrapper__heading'>
            <HeadingBar
              question={activeQuestion}
              questionSet={activeQuestionSet}
              showDifficulty={false}
              customHeader={
                <div className='q-heading-block__heading'>
                  <Text className='u-mar_0'>Instructions</Text>
                </div>
              }
            />
          </div>

          <TranslationHeader
            showTranslations={showPromptTranslation!}
            onToggle={setShowPromptTranslation}
            // fetchingTranslations={fetchingTranslations}
          />

          <div className='question-wrapper__body u-pad_4 u-pad_2@xs'>
            <Prompt question={activeQuestion} />
          </div>
        </Card>
        <Card
          as='form'
          className='question-wrapper question-split-pane answer-options-pane'
          // @ts-ignore: TS complains about onSubmit not being a valid prop for a `form` Card
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div className='question-wrapper__heading'>
            <HeadingBar
              question={activeQuestion}
              questionSet={activeQuestionSet}
              showDifficulty={false}
              customHeader={
                <div className='q-heading-block__heading'>
                  <Text className='u-mar_0'>Answer Options</Text>
                </div>
              }
            />
          </div>
          <div className='question-wrapper__body'>
            {/* TODO: update so that hasTranslation is passed as a propt instead to determine banner copy */}

            <TranslationHeader
              showTranslations={!!showQuestionTranslation}
              onToggle={setShowQuestionTranslation}
              // fetchingTranslations={fetchingTranslations}
            />

            <div className='u-pad_4 u-pad_2@xs'>
              <QuestionContent
                activeQuestion={activeQuestion}
                activeQuestionSet={activeQuestionSet}
                activeQuestionStore={activeQuestionStore}
                activeSubject={activeSubject}
                ActiveQuestionInputArea={ActiveQuestionInputArea}
                customExplanation={customExplanation}
                customHOA={customHOA}
                customResultIndicator={customResultIndicator}
                guess={guess}
                interactive={interactive}
                isRedesign
                postSubmissionScrollPointRef={postSubmissionScrollPointRef}
                questionState={questionState}
              />
            </div>
            <Footer minimal className='u-mar-t_4 u-border-radius_2' />
            {actions &&
              React.cloneElement(actions, {
                guess,
                hasAccess: hasQuestionSetAccessPredicate?.(activeQuestionSet) || false,
                navigateBackProps,
                navigateNextProps,
                question: activeQuestion,
                questionSet: activeQuestionSet,
                subject: activeSubject
              })}
          </div>
        </Card>
      </SplitPane>
    </div>
  );
};

const QuestionComponentHorizontalLayout = ({
  actions,
  activeQuestion,
  activeQuestionSet,
  activeQuestionStore,
  activeSubject,
  ActiveQuestionInputArea,
  customExplanation,
  customHOA,
  customResultIndicator,
  guess,
  hasQuestionSetAccessPredicate,
  interactive,
  isMobile,
  navigateBackProps,
  navigateNextProps,
  postSubmissionScrollPointRef,
  questionState
}: QuestionComponentContentSplitProps) => {
  const {
    // fetchingTranslations,
    showPromptTranslation,
    showQuestionTranslation,
    setShowPromptTranslation,
    setShowQuestionTranslation
  } = useQuestionTranslationStore(
    useShallow((state) => ({
      // fetchingTranslations: state.fetchingTranslations,
      showPromptTranslation: state.toggleStatus.prompt,
      showQuestionTranslation: state.toggleStatus.answer,
      setShowPromptTranslation: state.onToggle.prompt,
      setShowQuestionTranslation: state.onToggle.answer
    }))
  );
  return (
    <div className='question-component__horizontal-layout-wrapper'>
      <Card className='question-wrapper horizontal-layout__instructions-card'>
        <div className='question-wrapper__heading'>
          <HeadingBar
            question={activeQuestion}
            questionSet={activeQuestionSet}
            showDifficulty={false}
            customHeader={
              <div className='q-heading-block__heading'>
                <Text className='u-mar_0'>Instructions</Text>
                <TranslationHeader
                  showTranslations={showPromptTranslation!}
                  onToggle={setShowPromptTranslation}
                  // fetchingTranslations={fetchingTranslations}
                />
              </div>
            }
          />
        </div>
        <div className='question-wrapper__body u-pad_4 u-pad_2@xs'>
          <Prompt question={activeQuestion} />
        </div>
      </Card>
      <Card
        as='form'
        className='question-wrapper horizontal-layout__answer-options-card'
        // @ts-ignore: TS complains about onSubmit not being a valid prop for a `form` Card
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className='question-wrapper__heading'>
          <HeadingBar
            question={activeQuestion}
            questionSet={activeQuestionSet}
            showDifficulty={false}
            customHeader={
              <div className='q-heading-block__heading'>
                <Text className='u-mar_0'>Answer Options</Text>
                <TranslationHeader
                  showTranslations={!!showQuestionTranslation}
                  onToggle={setShowQuestionTranslation}
                  // fetchingTranslations={fetchingTranslations}
                />
              </div>
            }
          />
        </div>
        <div className='question-wrapper__body'>
          <div className='u-pad_4 u-pad_2@xs'>
            <QuestionContent
              activeQuestion={activeQuestion}
              activeQuestionSet={activeQuestionSet}
              activeQuestionStore={activeQuestionStore}
              activeSubject={activeSubject}
              ActiveQuestionInputArea={ActiveQuestionInputArea}
              customExplanation={customExplanation}
              customHOA={customHOA}
              customResultIndicator={customResultIndicator}
              guess={guess}
              interactive={interactive}
              isRedesign
              postSubmissionScrollPointRef={postSubmissionScrollPointRef}
              questionState={questionState}
            />
          </div>
          {actions &&
            React.cloneElement(actions, {
              guess,
              hasAccess: hasQuestionSetAccessPredicate?.(activeQuestionSet) || false,
              navigateBackProps,
              navigateNextProps,
              question: activeQuestion,
              questionSet: activeQuestionSet,
              subject: activeSubject
            })}
          <Footer
            minimal
            className={classNames('u-mar-t_4 u-border-radius_2', {
              'u-mar-b_8': isMobile
            })}
          />
        </div>
      </Card>
    </div>
  );
};

export const QuestionComponent = ({
  actions,
  activeQuestion,
  activeQuestionSet,
  activeQuestionStore,
  activeSubject,
  ActiveQuestionInputArea,
  customExplanation,
  customHeader,
  customHOA,
  customResultIndicator,
  displayOrientation,
  guess,
  hasHeading = true,
  hasQuestionSetAccessPredicate,
  headingActions,
  interactive,
  isMobile,
  modifyTitle,
  navigateBackProps,
  navigateNextProps,
  onResize,
  postSubmissionScrollPointRef,
  questionState,
  resizerPosition,
  showDifficulty
}: QuestionComponentProps) => {
  const {init, isReady, fetchTranslation} = useQuestionTranslationStore(
    useShallow((state) => ({
      init: state.init,
      fetchTranslation: state.fetchTranslation,
      isReady: state.isReady
    }))
  );

  // initialize translation store, check if translation toggle should be shown
  useEffect(() => {
    if (!isReady) {
      init();
    }
  }, [init, isReady]);

  useEffect(() => {
    if (!activeQuestion?.getId()) {
      return;
    }
    const supplementIds = activeQuestion
      .getAuthoringSupplements()
      .map((s) => s.getId())
      .toJS();

    // the translation store handles early returning if translations are not meant to be shown
    // safe to call here
    fetchTranslation(activeQuestion.getId(), supplementIds, activeQuestion, activeSubject);
  }, [activeQuestion, fetchTranslation, activeQuestionSet, activeSubject]);

  if (displayOrientation === 'vertical' && !isMobile) {
    return (
      <QuestionComponentContentSplit
        actions={actions}
        activeQuestion={activeQuestion}
        activeQuestionSet={activeQuestionSet}
        activeQuestionStore={activeQuestionStore}
        activeSubject={activeSubject}
        ActiveQuestionInputArea={ActiveQuestionInputArea}
        customExplanation={customExplanation}
        customHOA={customHOA}
        customResultIndicator={customResultIndicator}
        guess={guess}
        hasQuestionSetAccessPredicate={hasQuestionSetAccessPredicate}
        interactive={interactive}
        isMobile={isMobile}
        navigateBackProps={navigateBackProps}
        navigateNextProps={navigateNextProps}
        onResize={onResize}
        postSubmissionScrollPointRef={postSubmissionScrollPointRef}
        questionState={questionState}
        resizerPosition={resizerPosition}
      />
    );
  }

  if (
    (displayOrientation === 'horizontal' && !isMobile) ||
    (isMobile && displayOrientation !== 'default')
  ) {
    return (
      <QuestionComponentHorizontalLayout
        actions={actions}
        activeQuestion={activeQuestion}
        activeQuestionSet={activeQuestionSet}
        activeQuestionStore={activeQuestionStore}
        activeSubject={activeSubject}
        ActiveQuestionInputArea={ActiveQuestionInputArea}
        customExplanation={customExplanation}
        customHOA={customHOA}
        customResultIndicator={customResultIndicator}
        guess={guess}
        hasQuestionSetAccessPredicate={hasQuestionSetAccessPredicate}
        interactive={interactive}
        isMobile={isMobile}
        navigateBackProps={navigateBackProps}
        navigateNextProps={navigateNextProps}
        postSubmissionScrollPointRef={postSubmissionScrollPointRef}
        questionState={questionState}
      />
    );
  }

  return (
    <Card
      as='form'
      className='question-wrapper'
      // @ts-ignore: TS complains about onSubmit not being a valid prop for a `form` Card
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <div className='question-wrapper__heading'>
        {hasHeading && (
          <HeadingBar
            question={activeQuestion}
            questionSet={activeQuestionSet}
            navigateBackProps={navigateBackProps}
            navigateNextProps={navigateNextProps}
            headingActions={headingActions}
            modifyTitle={modifyTitle}
            showDifficulty={showDifficulty}
            customHeader={customHeader}
          />
        )}
      </div>
      <div className='question-wrapper__body'>
        <div className='u-pad_4 u-pad_2@xs'>
          <Prompt question={activeQuestion} />
          <QuestionContent
            activeQuestion={activeQuestion}
            activeQuestionSet={activeQuestionSet}
            activeQuestionStore={activeQuestionStore}
            activeSubject={activeSubject}
            ActiveQuestionInputArea={ActiveQuestionInputArea}
            customExplanation={customExplanation}
            customHOA={customHOA}
            customResultIndicator={customResultIndicator}
            guess={guess}
            interactive={interactive}
            isRedesign={false}
            postSubmissionScrollPointRef={postSubmissionScrollPointRef}
            questionState={questionState}
          />
        </div>
        {actions &&
          React.cloneElement(actions, {
            guess,
            hasAccess: hasQuestionSetAccessPredicate?.(activeQuestionSet) || false,
            navigateBackProps,
            navigateNextProps,
            question: activeQuestion,
            questionSet: activeQuestionSet,
            subject: activeSubject
          })}
      </div>
    </Card>
  );
};
