import React, {useCallback} from 'react';
import {callAction} from 'client/framework';
import SupplementManagerModalButton from 'client/Supplements/SupplementManager/SupplementManagerModalButton.react';

import {useShallow} from 'zustand/react/shallow';

import AuthoringTextInput from '../AuthoringTextInput/AuthoringTextInput.react';
import questionEditorActions from '../QuestionEditor/QuestionEditor.actions';
import questionEditorStore from '../QuestionEditor/QuestionEditor.store';
import {useQuestionEditorV2Store} from '../QuestionEditorV2Store';
import {PRIMARY_CONTENT_LANGUAGE} from '../QuestionEditorV2Store.types';

export const ExplanationSection = () => {
  const {currentLanguage, translatedSolutionText, updateTranslatedQuestionField} =
    useQuestionEditorV2Store(
      useShallow((state) => ({
        currentLanguage: state.currentLanguage,
        updateTranslatedQuestionField: state.updateTranslatedQuestionField,
        translatedSolutionText:
          state
            .currentTranslatedQuestion()
            ?.translated_fields?.find((field) => field.field === 'solution_text')?.text || ''
      }))
    );

  const question = questionEditorStore.getQuestion();
  const handleChange = useCallback(
    (e) => {
      if (currentLanguage === PRIMARY_CONTENT_LANGUAGE) {
        const val = e.target.value;
        callAction(questionEditorActions.UPDATE_QUESTION_DETAILS, {
          setter: 'setSolutionText',
          value: val
        });
        callAction(questionEditorActions.VALIDATE_QUESTION_FIELD, 'solution_text');
      } else {
        updateTranslatedQuestionField(currentLanguage, 'solution_text', 'text', e.target.value);
      }
    },
    [currentLanguage, updateTranslatedQuestionField]
  );

  const handleBlur = useCallback(
    (e) => {
      if (currentLanguage === PRIMARY_CONTENT_LANGUAGE) {
        const {selectionStart, selectionEnd} = e.target;

        callAction(questionEditorActions.SAVE_CURSOR_POSITION, {
          start: selectionStart,
          end: selectionEnd,
          name: 'solution_text'
        });
      }
    },
    [currentLanguage]
  );

  const value =
    currentLanguage === PRIMARY_CONTENT_LANGUAGE
      ? question.getSolutionText()
      : translatedSolutionText;
  const errors = questionEditorStore.getValidationErrors();
  const error = errors.get('solution_text');

  return (
    <div className='u-display_flex u-flex-direction_column u-gap_space-x1'>
      <AuthoringTextInput
        data-field-path='solution_text'
        value={value}
        error={currentLanguage === PRIMARY_CONTENT_LANGUAGE ? error : null}
        onChange={handleChange}
        onBlur={handleBlur}
      />

      <SupplementManagerModalButton
        disabled={currentLanguage !== PRIMARY_CONTENT_LANGUAGE}
        pathToProperty='solution_text'
        className='u-align-self_flex-end'
      />
    </div>
  );
};
