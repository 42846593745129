import {useEffect} from 'react';

/**
 * Custom hook for handling clicks outside a component. Please see the note
 * below on the dependency.
 *
 * @callback handlerCallback
 * @param  {object} ref - A node reference to check for event listeners
 * @param  {handlerCallback} handler - Callback for when event listener is triggered
 *
 * @see {@link https://usehooks.com/useOnClickOutside/}
 */
function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    /**
     * It's worth noting that because the passed in handler is
     * a new function on every render, it will cause this
     * effect's callback/cleanup to run every render. To optimize,
     * you can wrap the handler in useCallback before passing
     * it into this hook.
     */
    [ref, handler]
  );
}

export default useOnClickOutside;
