import {set} from 'lodash';
import {List} from 'immutable';
import {resource} from '@albert-io/json-api-framework/request/builder';
import makeConstants from 'lib/makeConstants';
import {applyFilters} from 'components/PracticeView/PracticeViewToolbar/Filters/filters.utils';
import {applySort} from 'components/PracticeView/PracticeViewToolbar/Sort/sort.utils';
import sessionStore from 'client/Session/SessionStore';
import {isSearchMode} from 'components/AdvancedSearch/AdvancedSearch.utils';
import {
  getKeywordsAndPhrasesFilters,
  getTagsFilters,
  getStandardsFilters
} from 'components/AdvancedSearch/AdvancedSearch.queries';
import {invalidatePartialInterest} from 'resources/mandark.resource';

export const queryTypes = makeConstants('singleQuestion', 'guideLevel');

/**
 * @param {string} subjectId
 * @param {string} guideLevelSlug
 * @returns {Promise}
 */
export function getSubjectGuideLevelBySlug(subjectId, guideLevelSlug) {
  if (!guideLevelSlug) {
    return null;
  }
  return resource('guide_level_v2')
    .mandarkEndpoint(['guide_levels_v2'])
    .findOne()
    .filter({
      subject_v2: subjectId,
      url_slug: guideLevelSlug,
      any_of: [
        {
          guide_v1: {
            guide_type: 'practice'
          }
        },
        {
          guide_v1: {
            guide_type: 'assessment'
          }
        }
      ]
    })
    .include('guide_v1')
    .fields({
      guide_v1: 'guide_type'
    })
    .withMeta('guide_level_v2', sessionStore.hasTeacherAccess())
    .getResourcePromise();
}

export const getQuestionGuideLevels = (questionSlug: string) => {
  return resource('guide_levels_v2')
    .mandarkEndpoint(['guide_levels_v2'])
    .filter({
      questions_v3: {
        slug_id: questionSlug
      }
    })
    .include('guide_v1')
    .fields({
      guide_v1: 'guide_type'
    })
    .withMeta('guide_level_v2', sessionStore.hasTeacherAccess())
    .getResourcePromise();
};

function shouldOmitAssignmentQuestions() {
  return sessionStore.hasValidSession() && sessionStore.isStudent();
}

/**
 * @param {string} subjectSlug
 * @param {string} userId
 * @returns {Query}
 */
export function getSubjectBySlugQuery(subjectSlug, userId) {
  let query = resource('subject_v2')
    .mandarkEndpoint(['subjects_v2'])
    .findOne()
    .filter({
      url_slug: subjectSlug
    })
    .include('curriculum_area_v1');
  if (userId) {
    query = query.customQuery(set({}, 'meta.context.student.id', userId)).withMeta('subject_v2');
  }

  return query;
}

/**
 * @param {string} subjectSlug
 * @param {string} userId
 * @returns {Promise}
 */
export function getSubjectBySlug(subjectSlug, userId) {
  const query = getSubjectBySlugQuery(subjectSlug, userId);
  return query.getResourcePromise();
}

export function invalidateBlockedAssignmentQuestionSetsQuery() {
  const partialQuery = resource('question_sets_v1')
    .mandarkEndpoint(['question_sets_v1'])
    .customQuery({
      meta: {
        context: {
          blocked_assignment_question: true
        }
      }
    })
    .done();

  invalidatePartialInterest(partialQuery);
}

function makeBaseQuestionSetsQuery({userId, props, guideLevelId}) {
  const metaContext = guideLevelId
    ? {
        meta: {
          context: {
            guide_level: {
              id: guideLevelId
            }
          }
        }
      }
    : {};

  /**
   * @todo: This is pretty fragile, and I'm not sure if it scales to other user types.
   * Talk to the back-end and see if we can't clean these rules up a little.
   */
  if (sessionStore.hasTeacherAccess()) {
    set(metaContext, 'meta.context.user.role', 'teacher');
  }

  if (userId) {
    set(metaContext, 'meta.context.user.id', userId);
    // This adds the subject access meta we need to ensure the user can place a guess.
    set(metaContext, 'meta.context.student.id', userId);
  } else {
    set(metaContext, 'meta.context.no_user', true);
  }

  set(metaContext, 'meta.context.user.personalization_settings', true);

  const endpointArray = guideLevelId
    ? ['guide_levels_v2', guideLevelId, 'question_sets_v1']
    : ['question_sets_v1'];

  let query = resource('question_sets_v1')
    .mandarkEndpoint(endpointArray)
    .include('questions_v3.question_set_v1.tags_v1')
    .include('questions_v3.standards_v1')
    .include('questions_v3.standards_v1.standard_set_v1')
    .include('subject_v2.curriculum_area_v1')
    .include('guide_levels_v2.guide_v1')
    .fields({
      guide_v1: 'guide_type'
    })
    .withMeta('question_set_v1,question_v3,subject_v2,standard_v1')
    .pageSize(20);

  if (shouldOmitAssignmentQuestions()) {
    set(metaContext, 'meta.context.blocked_assignment_question', true);
    /**
     * @todo: Uncomment this once we we can filter out blocked
     * assignment questions in a performant way.
     */
    // query = query.filter({
    //   questions_v3: {
    //     'meta.blocked_assignment_question': false
    //   }
    // });
  }

  query = query.customQuery(metaContext, Object.keys(metaContext).length !== 0);

  if (isSearchMode()) {
    const {tags, standards, s: search} = props.location.query;
    const keywordsAndPhrasesFilters = getKeywordsAndPhrasesFilters(search, (obj) => ({
      search_questions_v2: obj
    }));
    const standardsFilters = getStandardsFilters(standards, (obj) => ({
      search_questions_v2: obj
    }));
    const tagsFilters = getTagsFilters(tags);

    query = query
      .filter(keywordsAndPhrasesFilters, keywordsAndPhrasesFilters)
      .filter(standardsFilters, standardsFilters)
      .filter(tagsFilters, tagsFilters);
  }

  if (guideLevelId) {
    query = applySort(query, 'meta.guide_level_position');
  }

  return applyFilters(query);
}

const filterIndividualQuestion = ({query, props}) => {
  const {questionSlug} = props.params;
  if (props.route.queryType !== queryTypes.singleQuestion) {
    return query;
  }
  return query.filter({
    questions_v3: {
      slug_id: questionSlug
    }
  });
};

const queryFuncs = {
  async setPage({query, props}) {
    let page = 1;
    const {subjectSlug, questionSetSlug} = props.params;
    if (subjectSlug && questionSetSlug) {
      let questionSet = null;
      try {
        questionSet = await resource('question_set_v1')
          .mandarkEndpoint(['question_sets_v1'])
          .filter({
            slug_id: `${subjectSlug}@${questionSetSlug}`
          })
          .fields({
            question_set_v1: 'id'
          })
          .findOne()
          .getResourcePromise();
      } catch (err) {
        if (err.response.forbidden) {
          throw err;
        }

        return query;
      }
      page = {
        id: questionSet.getId?.(),
        fallback_page: 1
      };
    }
    return query.page(page);
  },

  filterBySubject({query, props}) {
    const {subjectSlug} = props.params;
    if (!subjectSlug || props.route.queryType !== queryTypes.guideLevel) {
      return query;
    }
    return query.filter({
      subject_v2: {url_slug: subjectSlug}
    });
  },

  filterByGuideLevel({query, props}) {
    const {guideLevelSlug} = props.params;
    if (!guideLevelSlug) {
      return query;
    }
    return query.filter({
      guide_levels_v2: {url_slug: guideLevelSlug}
    });
  },

  filterIndividualQuestion
};

export const getSingleQuestionSetQuery = (props) => {
  let query = makeBaseQuestionSetsQuery(props);

  query = filterIndividualQuestion({query, props});
  return query;
};

export async function getQuestionSetsData(props, userId, guideLevelId, skipQuery) {
  let query = makeBaseQuestionSetsQuery({userId, props, guideLevelId});

  try {
    query = await queryFuncs.setPage({query, props});
  } catch (error) {
    return {errorResponse: error.response};
  }

  query = queryFuncs.filterBySubject({query, props});
  query = queryFuncs.filterByGuideLevel({query, props});
  query = queryFuncs.filterIndividualQuestion({query, props});

  if (!skipQuery) {
    try {
      await query.getResourcePromise();
    } catch (err) {
      return {errorResponse: err.response};
    }
  }

  let blockedQuestionCount = 0;
  if (shouldOmitAssignmentQuestions()) {
    const allQuestionsQuery = query
      .unset(['filter', 'questions_v3', 'meta.blocked_assignment_question'])
      .unset('customQuery.page')
      .pageSize(0);
    await allQuestionsQuery.getResourcePromise();
    blockedQuestionCount = allQuestionsQuery
      .getResourceMetadata()
      .getIn(['request', 'count_of_blocked_assignment_questions'], 0);
  }

  let searchQuestionIds = null;
  if (isSearchMode()) {
    const searchQuestionIdsQuery = query
      .unset('customQuery.page')
      .unset('customQuery.sort')
      .unset('customQuery.with_meta')
      .unset('include')
      .withMeta('question_set_v1')
      .customQuery({
        meta: {
          context: {
            search: {
              questions: {
                index: true
              }
            }
          }
        }
      })
      .pageSize(0);
    await searchQuestionIdsQuery.getResourcePromise();
    searchQuestionIds = searchQuestionIdsQuery
      .getResourceMetadata()
      .getIn(['request', 'search_question_ids'], List())
      .reduce((acc, id) => {
        acc[id] = true;
        return acc;
      }, {});
  }

  if (!skipQuery) {
    const questionSets = await query.getResourcePromise();
    return {
      questionSetsQuery: query,
      questionSets,
      blockedQuestionCount,
      searchQuestionIds
    };
  }
  return {
    questionSetsQuery: query,
    questionSets: [],
    blockedQuestionCount,
    searchQuestionIds
  };
}
