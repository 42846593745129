import React from 'react';
import {Card, Text} from '@albert-io/atomic';
import {fromJS} from 'immutable';
import {callAction} from 'client/framework';
import SimpleDropdown from 'generic/SimpleDropdown/SimpleDropdown.react';

import {QuestionEditorIcon} from '../QuestionEditorIcon/QuestionEditorIcon';
import questionEditorStore from '../QuestionEditor/QuestionEditor.store';
import questionEditorActions from '../QuestionEditor/QuestionEditor.actions';

interface SubtypeCardProps {
  className?: string;
}

const SubtypeSection = ({className}: SubtypeCardProps) => {
  if (questionEditorStore.getQuestion().getQuestionType() !== 'free-response') {
    return null;
  }
  const handleSubTypeChange = (option) => {
    callAction(questionEditorActions.UPDATE_QUESTION_DETAILS, {
      setter: 'setQuestionSubType',
      value: option.get('id')
    });
  };
  return (
    <Card paddingLevel={3} className={className}>
      <div className='u-display_flex u-align-items_center u-gap_space-x2 u-justify-content_space-between'>
        <div className='u-display_flex u-align-items_center u-gap_space-x4'>
          <QuestionEditorIcon icon={['fal', 'align-left']} />
          <Text bold>Free Response Sub-Type</Text>
        </div>
        <SimpleDropdown
          activeItemId={questionEditorStore.getQuestion().getQuestionSubType()}
          options={subTypeOptions}
          storeName='QuestionSubTypeStore'
          onChange={handleSubTypeChange}
          destroyStoreOnUnmount
        />
      </div>
    </Card>
  );
};

const subTypeOptions = fromJS([
  {
    id: 'free_response_question',
    name: 'FRQ'
  },
  {
    id: 'document_based_question',
    name: 'DBQ'
  },
  {
    id: 'classroom_sample_task',
    name: 'CST'
  },
  {
    id: 'short_answer',
    name: 'Short Answer'
  },
  {
    id: 'long_answer',
    name: 'Long Answer'
  }
]);

export default SubtypeSection;
