import React, {useEffect, useMemo, useState} from 'react';
import {
  Card,
  IconButton,
  Input,
  LoadingSpinner,
  TextArea,
  addToast,
  copyToClipboard
} from '@albert-io/atomic';
import {resource} from '@albert-io/json-api-framework/request/builder';
import {useIsFeatureFlagEnabled} from 'client/components/FeatureFlag/FeatureFlag.react';
import {getSupplementTypeDefinition} from 'client/SupplementTypes/SupplementTypeDefinitions';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';

import {getModelInput, TranslationSubject} from './utils';

export const TranslationSandbox = () => {
  const {isLoading, isFeatureEnabled} = useIsFeatureFlagEnabled('translation_sandbox');

  const [questionOrSupplementId, setQuestionOrSupplementId] = useState('');
  const [subject, setSubject] = useState<TranslationSubject | null>(null);
  const [modelOutput, setModelOutput] = useState('');

  const modelInput = useMemo(() => getModelInput(subject), [subject]);
  const stringifiedModelInput = useMemo(() => JSON.stringify(modelInput, null, 2), [modelInput]);

  const translations = useMemo(() => {
    try {
      return JSON.parse(modelOutput).translations as Translation[];
    } catch (e) {
      return null;
    }
  }, [modelOutput]);

  useEffect(() => {
    (async () => {
      if (
        questionOrSupplementId &&
        /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
          questionOrSupplementId
        )
      ) {
        try {
          const response = await resource('authoring_questions_v1')
            .mandarkEndpoint(['authoring_questions_v1', questionOrSupplementId])
            .getResourcePromise();

          setSubject({type: 'question', subject: response});
        } catch (qe) {
          try {
            const response = await resource('authoring_supplements_v1')
              .mandarkEndpoint(['authoring_supplements_v1', questionOrSupplementId])
              .getResourcePromise();

            setSubject({type: 'supplement', subject: response});
          } catch (se) {
            setSubject(null);
          }
        }
      } else {
        setSubject(null);
      }
      setModelOutput('');
    })();
  }, [questionOrSupplementId]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!isFeatureEnabled) {
    return <div>Translation sandbox is unavailable</div>;
  }

  return (
    <div style={{maxWidth: '1700px', margin: '0 auto'}}>
      <h1>Translation Sandbox</h1>
      <div>
        <h4>Question or Supplement ID</h4>
        <Input
          value={questionOrSupplementId}
          onChange={(e) => setQuestionOrSupplementId(e.target.value)}
        />

        {modelInput && (
          <>
            <h4 className='u-mar-t_2'>Model Input</h4>
            <p>Copy this and use it as input for the AI model</p>
            <div style={{backgroundColor: 'black', color: 'white'}}>
              <IconButton
                onClick={() =>
                  copyToClipboard(stringifiedModelInput, () => {
                    addToast({
                      message: 'Copied!',
                      color: 'positive'
                    });
                  })
                }
                icon='clipboard'
                aria-hidden
                color='vendor-clever'
                style={{position: 'absolute', top: '16px', right: '16px', zIndex: 1}}
              />
              <code>
                <pre className='u-pad_2' style={{maxHeight: '30vh'}}>
                  {stringifiedModelInput}
                </pre>
              </code>
            </div>
            <h4 className='u-mar-t_2'>Model Output</h4>
            <TextArea
              value={modelOutput}
              onChange={(e) => setModelOutput(e.target.value)}
              placeholder='Paste AI model output here'
              style={{fontFamily: 'Courier', resize: 'vertical'}}
              className='u-mar-b_1'
            />
            {subject && subject.type === 'question' && translations && modelInput && (
              <QuestionTranslations translations={translations} sources={modelInput} />
            )}
            {subject && subject.type === 'supplement' && translations && (
              <SupplementTranslations translations={translations} supplement={subject.subject} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

interface Source {
  key: string;
  value: string;
}

interface Translation {
  key: string;
  text: string;
  warning: string;
  refusal: string;
}

interface QuestionTranslationsProps {
  sources: Source[];
  translations: Translation[];
}

const QuestionTranslations = ({translations, sources}: QuestionTranslationsProps) => {
  return (
    <div>
      <h4>Rendered Translations</h4>
      {translations.map((translation) => {
        const source = sources.find((s) => s.key === translation.key)!;
        return (
          <div className='u-display_flex u-mar-b_1' key={translation.key}>
            <div className='u-mar-r_1' style={{width: '50%'}}>
              <Card paddingLevel={2}>
                <h4>{source.key}</h4>
                <MarkdownRendererV2 text={source?.value} />
              </Card>
            </div>

            <div className='u-mar-r_1' style={{width: '50%'}}>
              <Card paddingLevel={2} className='u-mar-b_1 u-mar-l_1'>
                <h4>{translation.key}</h4>
                {translation.warning && (
                  <div style={{color: '#ff7b00'}}>
                    <b>Warning: </b>
                    {translation.warning}
                  </div>
                )}
                {translation.refusal && (
                  <div style={{color: '#c60000'}}>
                    <b>Refusal: </b>
                    {translation.refusal}
                  </div>
                )}
                <MarkdownRendererV2 text={translation.text} />
              </Card>
            </div>
          </div>
        );
      })}
    </div>
  );
};

interface SupplementTranslationsProps {
  translations: Translation[];
  supplement: any;
}

const SupplementTranslations = ({translations, supplement}: SupplementTranslationsProps) => {
  const Renderer = getSupplementTypeDefinition(supplement.get('type')).get('renderer');

  const translatedContent = translations
    .filter((t) => t.key.startsWith('content'))
    .reduce((acc, t) => {
      const key = t.key.replace('content.', '');
      return acc.set(key, t.text);
    }, supplement.getContent());

  const name = translations.find((t) => t.key === 'name')?.text || 'NAME NOT FOUND';

  const warnings = translations.filter((t) => !!t.warning);
  const refusals = translations.filter((t) => !!t.refusal);

  return (
    <div>
      <h4>Rendered Translations</h4>
      {warnings.length > 0 && (
        <div className='u-mar-b_1'>
          <h5>Warnings</h5>
          {warnings.map((t) => (
            <div key={t.key}>
              <div>
                <b>{t.key}: </b> {t.warning}
              </div>
            </div>
          ))}
        </div>
      )}
      {refusals.length > 0 && (
        <div className='u-mar-b_1'>
          <h5>Refusals</h5>
          {refusals.map((t) => (
            <div key={t.key}>
              <div>
                <b>{t.key}: </b>
                {t.refusal}
              </div>
            </div>
          ))}
        </div>
      )}
      <div className='u-display_flex'>
        <div className='u-mar-r_1' style={{width: '50%'}}>
          <Renderer
            id={supplement.getId()}
            name={supplement.getName()}
            content={supplement.getContent()}
            supplement={supplement}
            attributes={{}}
          />
        </div>
        <div className='u-mar_l_1' style={{width: '50%'}}>
          <Renderer
            id={supplement.getId()}
            name={name}
            content={translatedContent}
            supplement={supplement.setName(name).setContent(translatedContent)}
            attributes={{}}
          />
        </div>
      </div>
    </div>
  );
};
