export const allDimensions = [
  'assignments',
  'students',
  'questions',
  'standards',
  'subjects',
  'classrooms',
  'teachers',
  'schools'
] as const;
export const allReports = [...allDimensions, 'gradebook'] as const;

export const CLASSROOM_DIMENSIONS = [
  'students',
  'assignments',
  'questions',
  'standards',
  'subjects'
] as const;

export const TEACHER_DIMENSIONS = [...CLASSROOM_DIMENSIONS, 'classrooms'] as const;

export const SCHOOL_DIMENSIONS = [...TEACHER_DIMENSIONS, 'teachers'] as const;

export const SCHOOL_ADMIN_DIMENSIONS = [...SCHOOL_DIMENSIONS, 'schools'] as const;

export const TOP_LEVEL_FILTERS = [
  'classrooms',
  'teachers',
  'schools',
  'districts',
  'school-admin'
] as const;
export type TopLevelFilter = (typeof TOP_LEVEL_FILTERS)[number];

export type Dimension = (typeof allDimensions)[number];
export type ReportTypes = (typeof allReports)[number];

export type FilterTypes =
  | 'schools'
  | 'districts'
  | 'teachers'
  | 'admins'
  | 'assignments'
  | 'classrooms'
  | 'guesses'
  | 'guide_levels'
  | 'questions'
  | 'standards'
  | 'standard_sets'
  | 'students'
  | 'subjects'
  | 'guesses';

export type OperatorTypes =
  | 'equals'
  | 'not_equals'
  | 'set'
  | 'not_set'
  | 'lt'
  | 'lte'
  | 'gt'
  | 'gte'
  | 'contains'
  | 'most_recent'
  | 'search';

export type Sort = {
  type: string;
  field: string;
  direction: 'asc' | 'desc';
};

export interface DimensionConfig {
  sortOrder: number;
  label: string;
  dimension: string;
  /** ^^^ DrilldownSelector attributes */
  reportInvalidWhenAnyOfTheseDimensionsInPath: Dimension[];
  defaultPath?: string;
}

export interface Pagination {
  primary_limit: number;
  primary_offset: number;
  secondary_limit?: number;
  secondary_offset?: number;
}

export type Filter = {
  type: FilterTypes;
  field?: string;
  operator: OperatorTypes;
  values?: string[] | number[];
};

export type QueryVariables = {
  assignmentFilter?: Filter;
  attemptNumberFilter?: Filter;
  classroomFilter?: Filter;
  teacherFilter?: Filter;
  schoolFilter?: Filter;
  districtFilter?: Filter;
  adminFilter?: Filter;
  endDateFilter?: Filter;
  guideLevelFilter?: Filter;
  questionFilter?: Filter;
  standardFilter?: Filter;
  standardSetFilter?: Filter;
  startDateFilter?: Filter;
  studentFilter?: Filter;
  subjectFilter?: Filter;
  guessStatusFilter?: Filter;
};

interface ColumnBase {
  initialSortDirection?: SortOption;
  primaryColumn?: boolean;
  key: string;
}

interface MetricColumnBase extends ColumnBase {
  columnType: 'metric';
  sortKey: `metrics.${string}` | null;
  isAvailable?: (
    dimensions: Dimension[],
    filters: QueryVariables,
    topLevelFilterType: TopLevelFilter
  ) => boolean;
  getMetricsToLoad: (
    dimensions: Dimension[],
    filters: QueryVariables,
    opts: {performanceCalculateBy: 'accuracy' | 'grade'}
  ) => string[];
  renderColumn: (
    result: AnalyticsApiResult,
    dimensions: Dimension[],
    filters: QueryVariables
  ) => JSX.Element;
  // metric columns can map to multiple metrics, so we have to know what the headers are and
  // be able to return multiple headers/values. It is the responsibility of each MetricColumn definition
  // to make sure the number of headers and data are the same.
  exportHeaders: (
    dimensions: Dimension[],
    filters: QueryVariables,
    opts: {performanceCalculateBy: 'accuracy' | 'grade'}
  ) => string[];
  export: (
    metrics: AnalyticsApiResult,
    dimensions: Dimension[],
    filters: QueryVariables,
    opts: {performanceCalculateBy: 'accuracy' | 'grade'}
  ) => string[];
}

interface DefaultMetricColumn extends MetricColumnBase {
  metricType: 'default';
  title: string;
}

interface MetricColumnWithRenderedTitle extends MetricColumnBase {
  metricType: 'renderedTitle';
  renderTitle: ({
    dimensions,
    variables
  }: {
    dimensions: Dimension[];
    variables: QueryVariables;
  }) => JSX.Element;
}

export type MetricColumn = DefaultMetricColumn | MetricColumnWithRenderedTitle;

type SortKey = `${string}.${string}` | null;

// Context: "hard" toggleability will completely remove a column from rendering, while "soft" toggleability will change the rendering and .csv export behavior of that column.
// Exactly how the rendering behavior of that column is changed will be up to the developer, but it should always remove or obfuscate the data in some way (otherwise using
// the toggle controls would be confusing behavior). The actual change in behavior is possible by `isColumnSoftDisabled` flags which are passed in to the `renderColumn` and `export`
// functions on the column itself.
//
// Additionally, we decided to rollback the idea of "soft" toggleability for now, but we will be reintroducing it shortly.
// So I'm opting to leave the additional code surrounding it for now.
//
// See: https://github.com/albert-io/nexus/issues/9485#issuecomment-2546607085
type ToggleableConfig =
  | {
      isToggleable: boolean | ((dimensions: Dimension[], filters: QueryVariables) => boolean);
      toggleableBehavior?: 'hard' | 'soft';
    }
  | {
      isToggleable?: never;
      toggleableBehavior?: never;
    };

type ExportConfig =
  | {export?: never; exportHeaders?: never}
  | {
      export: (result: AnalyticsApiResult, isColumnSoftDisabled: boolean) => string[];
      // raw export for CSV - null means this is not an exportable column
      exportHeaders: (dimensions: Dimension[]) => string[];
    };

export type DimensionColumn = ColumnBase &
  ({
    columnType: 'dimension';
    dimension: Dimension;
    title: string;
    sortKey: SortKey | ((dimensions: Dimension[]) => SortKey);
    // if no isAvailable is provided, the column will always be available
    isAvailable?: (
      dimensions: Dimension[],
      filters: QueryVariables,
      topLevelFilterType: TopLevelFilter
    ) => boolean;
    afterMetrics?: boolean;
    renderColumn: (
      result: AnalyticsApiResult,
      dimensions: Dimension[],
      isColumnSoftDisabled?: boolean
    ) => JSX.Element;
  } & ExportConfig &
    ToggleableConfig);

export type Column = DimensionColumn | MetricColumn;

export type SortProps =
  | {defaultSort?: false; sortBy?: string}
  | {defaultSort: true; sortBy: string};

export type ColumnProps = {
  content: (props) => React.ReactNode;
  header?: React.ReactNode;
  isActionMenu?: boolean;
} & PropsWithClassNameOptional &
  SortProps;

export type SortOption = 'asc' | 'desc';

export interface AnalyticsApiResult {
  primary_dimension_id: string;
  primary_dimension_type: Dimension;
  secondary_dimension_id?: string;
  secondary_dimension_type?: Dimension;
  metrics: any;
  assignment?: any;
  student?: any;
  subject?: any;
  teacher?: any;
  school?: any;
  classroom?: any;
  question?: any;
  standard?: any;
  secondary_assignment?: any;
  gradebook_student_assignment?: any;
  assignments_student_assignments?: any[];
  students_student_assignments?: any[];
}

export interface SummaryStatsResponse {
  accuracy: number | null;
  avg_time_spent_per_student: number | null;
  count_attempts: number;
  count_excelling_students: number;
  count_mastery_students: number;
  count_not_started_students: number;
  count_passing_students: number;
  count_proficient_students: number;
  count_struggling_students: number;
}
