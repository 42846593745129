import React, {useState, useEffect} from 'react';
import {history} from 'client/history';
import sessionStore from 'client/Session/SessionStore';
import {resource} from '@albert-io/json-api-framework/request/builder';
import {Anchor, Banner} from '@albert-io/atomic';

import {getActiveClassroomsWithSubjectsQuery} from '../helpers/queries';

export function getTeacherQuery() {
  return resource('teachers_v1')
    .mandarkEndpoint(['teachers_v1', sessionStore.getUserId()])
    .withMeta('teacher_v1');
}

const FreePracticeBanner = () => {
  const [classroomCount, setClassroomCount] = useState(null);
  const [assignmentCount, setAssignmentCount] = useState(null);
  const [unrestrictedSubjectCount, setUnrestrictedSubjectCount] = useState(null);
  const [isTeacherLicensed, setIsTeacherLicensed] = useState(null);

  useEffect(() => {
    let subscribed = true;
    const fetchData = async () => {
      try {
        const res = await getActiveClassroomsWithSubjectsQuery().getResourcePromise();
        const classroomsWithStudents = res.filter((item) => {
          return item.getMeta().getCountOfStudents() > 0;
        });
        if (subscribed) {
          setClassroomCount(classroomsWithStudents.size);
          const assignments = res.filter((item) => {
            return !item.getAssignments().isEmpty();
          });
          setAssignmentCount(assignments.size);
          const classroomsWithUnrestrictedSubjects = res.filter((item) => {
            const classroomId = item.getId();
            const subjectsWithoutRestrictions = item.getSubjects().filter((subject) => {
              return !subject.getClassroomRelationships(classroomId).getRestrictFreePractice();
            });
            return !subjectsWithoutRestrictions.isEmpty();
          });
          setUnrestrictedSubjectCount(classroomsWithUnrestrictedSubjects.size);
        }
      } catch (error) {
        history.pushState(null, '/error');
      }
    };

    const fetchTeacher = async () => {
      try {
        const res = await getTeacherQuery().getResourcePromise();
        if (subscribed) {
          setIsTeacherLicensed(res.getMeta().isLicensed());
        }
      } catch (error) {
        history.pushState(null, '/error');
      }
    };
    fetchData();
    fetchTeacher();
    return () => {
      subscribed = false;
    };
  }, []);

  if (!sessionStore.isTeacher()) {
    return null;
  }

  if (isTeacherLicensed && classroomCount > 0 && assignmentCount === 0) {
    if (unrestrictedSubjectCount === 0) {
      return (
        <Banner className='u-pad_4'>
          <span>
            Students are unable to answer questions in any subjects until a teacher&nbsp;
            <Anchor href='https://help.albert.io/en/articles/1885002-how-do-i-create-an-assignment'>
              creates an assignment
            </Anchor>
            &nbsp;or&nbsp;
            <Anchor href='https://help.albert.io/en/articles/2683941-how-do-i-provide-students-access-to-subjects-for-independent-practice'>
              enables independent practice
            </Anchor>
            .
          </span>
        </Banner>
      );
    }
    return (
      <Banner className='u-pad_4'>
        <span data-testid='fpb__create-assignment'>
          To provide students with specific practice questions,&nbsp;
          <Anchor href='https://help.albert.io/en/articles/1885002-how-do-i-create-an-assignment'>
            create an assignment
          </Anchor>
          .
        </span>
      </Banner>
    );
  }
  return null;
};

export default FreePracticeBanner;
