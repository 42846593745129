import {AuthoringQuestionModelV1} from 'resources/GeneratedModels/AuthoringQuestion/AuthoringQuestionModel.v1';
import {questionTypeSetup} from 'resources/extensions/questionHelpers/questionTypeSetup';
import {authoringQuestionExtensions} from 'resources/extensions/authoringQuestion.extensions';
import {authoringQuestionModelValidators} from 'resources/validators/AuthoringQuestionModel.validators';

AuthoringQuestionModelV1.extend(authoringQuestionExtensions);
AuthoringQuestionModelV1.addValidators(authoringQuestionModelValidators);
AuthoringQuestionModelV1.addDataTransformer('AuthoringQuestionModelV1', questionTypeSetup);

export {AuthoringQuestionModelV1};
