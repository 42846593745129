import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import {callTargetedAction} from 'client/framework';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {Input} from '@albert-io/atomic';
import {QuestionModelV3} from 'resources/augmented/Question/QuestionModel.v3';
import CorrectnessIndicatorWrapper from 'client/QuestionTypes/common/CorrectnessIndicatorWrapper/CorrectnessIndicatorWrapper.react';

import freeEntryQuestionActions from './FreeEntryQuestion.actions';

export default class FreeEntryQuestionInput extends React.Component {
  static propTypes = {
    inputId: PropTypes.string,
    inputPrompt: PropTypes.string,
    isMarkedCorrect: PropTypes.bool,
    isGuessSubmitted: PropTypes.bool,
    question: PropTypes.instanceOf(QuestionModelV3),
    storeName: PropTypes.string,
    value: PropTypes.string
  };

  static defaultProps = {
    value: ''
  };

  handleChange = (e) => {
    if (this.props.isGuessSubmitted) {
      return;
    }
    callTargetedAction({
      name: freeEntryQuestionActions.UPDATE_INPUT,
      payload: {
        inputId: this.props.inputId,
        value: e.target.value
      },
      targetStore: this.props.storeName
    });
  };

  render() {
    const {question, inputPrompt, isGuessSubmitted, isMarkedCorrect, value} = this.props;
    const wrapperClass = classnames('free-entry-input', {
      'free-entry-input--post-submission': isGuessSubmitted
    });
    const inputClass = classnames(
      'free-entry-input__input-v2',
      isGuessSubmitted
        ? {
            'free-entry-input__input-v2--correct': isMarkedCorrect,
            'free-entry-input__input-v2--incorrect': !isMarkedCorrect
          }
        : null
    );
    const placeholderText =
      question.getRubricType() === 'mathContains'
        ? 'Enter answer here. Do not include spaces or units.'
        : 'Type answer here. Be careful with spelling.';

    return (
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      <label className={wrapperClass}>
        <div className='free-entry-input__input-wrapper'>
          <MarkdownRendererV2 text={inputPrompt} className='free-entry-input__prompt' />
          <div>
            {/* We need the component to re-render on value change if it isn't interactable - "isGuessSubmitted && Value" */}
            <Input
              border
              className={inputClass}
              onChange={this.handleChange}
              placeholder={placeholderText}
              size='regular'
              type='text'
              defaultValue={value}
              key={isGuessSubmitted && value}
            />
            <CorrectnessIndicatorWrapper
              iconOnly
              isSelected
              size={24}
              className='free-entry-input__correctness-indicator-v2'
              isGuessSubmitted={isGuessSubmitted}
              isCorrectAnswer={isMarkedCorrect}
            />
          </div>
        </div>
      </label>
    );
  }
}
