/* eslint-disable no-underscore-dangle */
import {fromJS, Set} from 'immutable';
import QuestionTypeStore from 'components/QuestionTypes/QuestionType.store';

import twoWayQuestionActions from './TwoWayQuestion.actions';

export default class TwoWayQuestionStore extends QuestionTypeStore {
  constructor(name, questionId, question) {
    super(name);

    this.initialData = fromJS({
      ...this.draftGuessInitialData,
      questionId,
      checkedColumns: [], // converted to Immutable.Set
      shouldShowExplanation: false
    });

    this.setInitialData(this.initialData);

    this.writeData('__question', question);
    this.writeData('checkedColumns', Set());

    this.handleTargeted(twoWayQuestionActions.SELECT_COLUMN, this._selectColumn);
  }

  afterLoadDraftGuess(draftGuess) {
    this.writeData('checkedColumns', this.parseGuess(draftGuess));
  }

  buildGuessContent() {
    const selectedOptions = this.readData('checkedColumns');
    return selectedOptions.reduce((acc, optionId) => {
      acc[optionId] = true;
      return acc;
    }, {});
  }

  _clearGuess() {
    super._clearGuess();
    this.writeData('checkedColumns', Set());
  }

  isSelectedColumn(id) {
    return this.readData('checkedColumns').includes(id);
  }

  _loadGuess(guess) {
    super._loadGuess(guess);
    this.writeData('checkedColumns', this.parseGuess(guess));
  }

  parseGuess(guess) {
    return Set(guess.get('content').keys());
  }

  _selectColumn(columnId) {
    const checkedColumns = this.isSelectedColumn(columnId)
      ? this.readData('checkedColumns').delete(columnId)
      : this.readData('checkedColumns').add(columnId);

    this.writeData('checkedColumns', checkedColumns);
    super.kickoffDebouncedSaveDraftGuess();
  }
}
