/**
 * # ConfirmLeave
 *
 * A component that provides confirmation before leaving a route.
 *
 * ## Props
 *
 * - `route: string | {path: string}`: The route to be confirmed before leaving.
 * - `router?: any`: The router object.
 * - `children?: React.ReactNode`: The children of the component.
 *
 * ## Usage
 *
 * ```tsx
 * import ConfirmLeave from './ConfirmLeave';
 *
 * const ExampleComponent = () => {
 *   return (
 *     <ConfirmLeave route="/example-route" router={router}>
 *       {({dismiss, confirmLeave}) => (
 *         <div>
 *           <button onClick={dismiss}>Dismiss</button>
 *           <button onClick={confirmLeave}>Confirm Leave</button>
 *         </div>
 *       )}
 *     </ConfirmLeave>
 *   );
 * };
 * ```
 */

import {useEffect, useReducer} from 'react';
import {withRouter} from 'react-router';
import makeConstants from 'lib/makeConstants';

const actionsList = ['setNextPath', 'setHasConfirmedLeave', 'resetState'] as const;

const actions = makeConstants(...actionsList);

type ConfirmLeaveActions =
  | {type: typeof actions.resetState}
  | {type: typeof actions.setHasConfirmedLeave; payload: boolean}
  | {type: typeof actions.setNextPath; payload: string};

interface ConfirmLeaveState {
  nextPath: null | string;
  hasConfirmedLeave: boolean;
}

const initialState: ConfirmLeaveState = {
  nextPath: null,
  hasConfirmedLeave: false
};

const reducer = (state: ConfirmLeaveState, action: ConfirmLeaveActions): ConfirmLeaveState => {
  switch (action.type) {
    case actions.setNextPath:
      return {...state, nextPath: action.payload};
    case actions.setHasConfirmedLeave:
      return {...state, hasConfirmedLeave: true};
    case actions.resetState:
      return initialState;
    default:
      throw new Error();
  }
};

interface ConfirmLeaveProps extends PropsWithChildrenOptional {
  route: string | {path: string};
  router?: any;
}

const ConfirmLeave = ({route, router, children}: ConfirmLeaveProps) => {
  const [{nextPath, hasConfirmedLeave}, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    const removeLeaveHook = router.setRouteLeaveHook(route, (nextRoute) => {
      if (hasConfirmedLeave) {
        return null;
      }
      dispatch({
        type: actions.setNextPath,
        payload: nextRoute.pathname + nextRoute.search
      });
      return false;
    });
    return removeLeaveHook;
  }, [route, router, hasConfirmedLeave]);

  useEffect(() => {
    if (hasConfirmedLeave) {
      router.pushState(null, nextPath);
    }
  }, [nextPath, router, hasConfirmedLeave]);

  const dismiss = () => {
    dispatch({type: actions.resetState});
  };
  const confirmLeave = () => {
    dispatch({
      type: actions.setHasConfirmedLeave,
      payload: true
    });
  };
  return nextPath !== null && typeof children === 'function'
    ? children({dismiss, confirmLeave})
    : null;
};

export default withRouter(ConfirmLeave);
