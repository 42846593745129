import React from 'react';
import PropTypes from 'prop-types';

import {Modal, Dialogue, Text, Button, Icon} from '@albert-io/atomic';

import './retail-assessment-modal.scss';

const RetailAssessmentModal = ({handleClose}) => {
  return (
    <Modal ariaLabel='Taking an assessment tips' handleClose={handleClose}>
      {({CloseButtonWrapper, modalContentStyle}) => {
        return (
          <Dialogue
            inModal
            hideCloseBtn
            alignTitle='center'
            className={modalContentStyle}
            handleClose={handleClose}
            title='Before you begin...'
          >
            <Dialogue.Body>
              <div className='u-display_flex u-pad-tb_2'>
                <div className='u-mar-r_4'>
                  <Icon
                    className='retail-assessment-modal__icon u-color_slate-400'
                    icon={['far', 'clock']}
                  />
                </div>
                <Text>Time yourself using a stopwatch or clock.</Text>
              </div>
              <div className='u-display_flex u-pad-tb_2'>
                <div className='u-mar-r_4'>
                  <Icon
                    className='retail-assessment-modal__icon u-color_slate-400'
                    icon={['far', 'sticky-note']}
                  />
                </div>
                <Text>
                  After you complete the exam, note your accuracy so you can compare with other
                  attempts.
                </Text>
              </div>
              <div className='u-display_flex u-pad-tb_2'>
                <div className='u-mar-r_4'>
                  <Icon
                    className='retail-assessment-modal__icon u-color_slate-400'
                    icon={['far', 'redo']}
                  />
                </div>
                <Text>
                  You can restart the exam at any time by returning to the assessment page.{' '}
                  <Text color='tertiary'>
                    We recommend finishing the entire exam before restarting.
                  </Text>
                </Text>
              </div>
            </Dialogue.Body>
            <Dialogue.BtnGroup align='right'>
              <CloseButtonWrapper>
                <Button>Start</Button>
              </CloseButtonWrapper>
            </Dialogue.BtnGroup>
          </Dialogue>
        );
      }}
    </Modal>
  );
};

RetailAssessmentModal.propTypes = {
  handleClose: PropTypes.func
};

export default RetailAssessmentModal;
