/**
 * # MultiSearchAndSelect
 *
 * A component that allows users to search and select multiple items from a list of search result items. Inherits all props from `SearchAndSelect`.
 *
 * ## Props
 *
 * - `className` (string): The CSS class name for the component.
 * - `onSelect` (function): A callback function triggered when an item is selected or deselected. It receives the selected items as an argument.
 * - `content` (function): A function that returns the content to be displayed for each selected item. It receives the item as an argument.
 * - `defaultSelectedItems` (ImmutablePropTypes.list): The default selected items when the component is initialized.
 * - `data` (ImmutablePropTypes.list): The list of data items to be displayed in the dropdown.
 * - `selectedItems` (ImmutablePropTypes.list): The list of selected items.
 * - `inputType` (string): The type of input to be rendered. Can be 'search' or 'text'.
 * - `label` (string): The label for the input field.
 * - `defaultValue` (string): The default value for the search input.
 * - `query` (queryBuilderPropType): The query builder for fetching data.
 * - `openOnFocus` (boolean): Whether to open the dropdown on input focus.
 * - `iconRight` (IconProp): The icon to be displayed on the right side of the input field.
 * - `replaceOnSelect` (boolean): Whether to replace the search input value with the selected item's content.
 * - `closeOnSelect` (boolean): Whether to close the dropdown after selecting an item.
 * - `searchFilter` (function): The function used to filter the search results.
 * - `listItemContent` (function): The function used to render the content of each dropdown item in the list.
 * - `onClear` (function): The function called when the search input is cleared.
 * - `onChange` (function): The function called when the search input value changes.
 * - `customFooterRenderer` (function): The function used to render a custom footer in the dropdown.
 * - `error` (boolean): Whether there is an error in the input field.
 * - `placeholder` (string): The placeholder text for the input field.
 *
 * ## Usage
 *
 * ```jsx
 * import MultiSearchAndSelect from './MultiSearchAndSelect.react';
 *
 * const MyComponent = () => {
 *   const handleSelect = (selectedItems) => {
 *     console.log('Selected items:', selectedItems);
 *   };
 *
 *   const renderContent = (item) => {
 *     return <span>{item.name}</span>;
 *   };
 *
 *   return (
 *     <MultiSearchAndSelect
 *       className="my-multi-search-select"
 *       onSelect={handleSelect}
 *       content={renderContent}
 *       defaultSelectedItems={Immutable.List([{name: 'Item 1'}, {name: 'Item 2'}])}
 *     />
 *   );
 * };
 *
 * export default MyComponent;
 * ```
 */
import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {List, Set} from 'immutable';
import classnames from 'classnames';

import SearchAndSelect from '../SearchAndSelect.react';
import CheckboxChip from '../../../molecules/CheckboxChip/CheckboxChip.react';

export default class MultiSearchAndSelect extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    onSelect: PropTypes.func,
    content: PropTypes.func,
    defaultSelectedItems: ImmutablePropTypes.list
  };

  static defaultProps = {
    defaultSelectedItems: List(),
    onSelect: () => {},
    content: () => {}
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedItems: Set(props.defaultSelectedItems)
    };
  }

  setSelection = (item) => {
    this.setState(
      (state) => {
        const updatedSelectedItems = state.selectedItems.includes(item)
          ? state.selectedItems.delete(item)
          : state.selectedItems.add(item);

        return {selectedItems: updatedSelectedItems};
      },
      () => this.props.onSelect(this.state.selectedItems)
    );
  };

  makeSelections = () => {
    return this.state.selectedItems
      .sortBy((item) => this.props.content(item))
      .map((item, key) => (
        <CheckboxChip
          key={key}
          icon='times'
          color='brand'
          size='small'
          checked
          onChange={() => this.setSelection(item)}
        >
          {this.props.content(item)}
        </CheckboxChip>
      ));
  };

  render() {
    const {className, ...restProps} = this.props;

    return (
      <div className={className}>
        <SearchAndSelect
          {...restProps}
          onSelect={this.setSelection}
          selectedItems={this.state.selectedItems}
        />
        <div
          className={classnames('o-multi-search-select__selections', {
            'u-mar-t_1': !this.state.selectedItems.isEmpty()
          })}
        >
          {this.makeSelections()}
        </div>
      </div>
    );
  }
}
