import React from 'react';
import PropTypes from 'prop-types';
import {List} from 'immutable';
import {getModelForResourceType} from 'resources/modelRegistry';
import {GUIDE_TYPES} from 'client/constants';

import QuestionWrapper from '../../QuestionWrapper/QuestionWrapper.react';
import {HeadingBar} from '../../HeadingBar/HeadingBar.react';
import {ResultIndicatorBar} from '../../ResultIndicatorBar/ResultIndicatorBar.react';
import {TagsList, StandardsList} from '../../TagsStandardsList/TagsStandardsList.react';
import {StandardsTable} from '../../StandardsTable/StandardsTable.react';
import MarkdownBlock from '../../MarkdownBlock/MarkdownBlock';
import InteractivityHandler from '../../InteractivityHandler/InteractivityHandler.react';

import '../content-blocks.scss';

const QuestionModel = getModelForResourceType('question_v3');
const SubjectModel = getModelForResourceType('subject_v2');
const QuestionSetModel = getModelForResourceType('question_set_v1');

// They key is added to force a rerender on question change. This is useful when translating. See `markdownRendererUtils.js` for more info.
const Prompt = ({question}) => {
  return <MarkdownBlock key={question.getId()} text={question.getPrompt()} />;
};

Prompt.propTypes = {
  question: PropTypes.instanceOf(QuestionModel)
};

const Explanation = ({question}) => (
  <MarkdownBlock heading='Explanation' text={question.getMeta().getSolutionText()} />
);

Explanation.propTypes = {
  question: PropTypes.instanceOf(QuestionModel)
};

const SampleResponse = ({question}) => {
  const sample = question.getMeta().getSample();
  if (sample) {
    return <MarkdownBlock heading='Sample Response' text={sample} />;
  }
  return null;
};

SampleResponse.propTypes = {
  question: PropTypes.instanceOf(QuestionModel)
};

const Standards = ({question, subject, questionSet}) => {
  const guideType = questionSet.getGuideLevels().first()?.getGuide().getGuideType();
  const guideTypeLinkString = guideType === GUIDE_TYPES.ASSESSMENT ? '/assessments' : '';
  return (
    <StandardsList
      standards={question.getStandards()}
      linkToFunc={(item) =>
        `/${subject.getUrlSlug()}${guideTypeLinkString}?standards=${item.getId()}`
      }
    />
  );
};

Standards.propTypes = {
  question: PropTypes.instanceOf(QuestionModel),
  subject: PropTypes.instanceOf(SubjectModel),
  questionSet: PropTypes.instanceOf(QuestionSetModel)
};

const Tags = ({question, subject, questionSet}) => {
  const guideType = questionSet.getGuideLevels().first()?.getGuide().getGuideType();
  const guideTypeLinkString = guideType === GUIDE_TYPES.ASSESSMENT ? '/assessments' : '';
  return (
    <TagsList
      tags={question.getQuestionSet().getTags ? question.getQuestionSet().getTags() : List()}
      linkToFunc={(item) => `/${subject.getUrlSlug()}${guideTypeLinkString}?tags=${item.getId()}`}
    />
  );
};

Tags.propTypes = {
  question: PropTypes.instanceOf(QuestionModel),
  subject: PropTypes.instanceOf(SubjectModel),
  questionSet: PropTypes.instanceOf(QuestionSetModel)
};

export {
  QuestionWrapper,
  HeadingBar,
  Prompt,
  ResultIndicatorBar,
  Explanation,
  SampleResponse,
  StandardsTable,
  Standards,
  Tags,
  InteractivityHandler
};
