import React, {useCallback, useEffect, useRef, useState} from 'react';
import {List} from 'immutable';
import {modes} from 'components/GraphingInput/GraphingInput.react';

import {ResultIndicatorBar} from '../shared/ContentBlocks';

import {
  QuestionComponent,
  QuestionComponentProps
} from '../shared/QuestionComponent/QuestionComponent.react';

import useDraftGuess from '../../../AssignmentPracticeView/DraftGuesses/useDraftGuess';

import {SaveStatus} from '../QuestionType.types';

import GraphingQuestionInput from './GraphingQuestionInput/GraphingQuestionInput.react';

const GraphingQuestionComponent = (questionComponentProps: QuestionComponentProps) => {
  const {guess, activeQuestion, activeQuestionStore, questionState} = questionComponentProps;
  const {saveStatus} = useDraftGuess(activeQuestionStore.getName(), questionState === 'PostGuess');

  const lastQuestionStoreName = useRef(activeQuestionStore.getName());
  const lastSaveStatus = useRef(saveStatus);

  const [draftGuessEntities, setDraftGuessEntities] = useState([]);
  const [hasSetDraftGuessEntitites, setHasSetDraftGuessEntities] = useState(false);

  useEffect(() => {
    if (
      !hasSetDraftGuessEntitites ||
      activeQuestionStore.getName() !== lastQuestionStoreName.current ||
      (lastSaveStatus.current === SaveStatus.LOADING && saveStatus === SaveStatus.SAVED)
    ) {
      setDraftGuessEntities(activeQuestionStore.getGuessEntities());
      setHasSetDraftGuessEntities(true);
    }

    lastQuestionStoreName.current = activeQuestionStore.getName();
    lastSaveStatus.current = saveStatus;
  }, [activeQuestionStore, hasSetDraftGuessEntitites, saveStatus]);

  const customResultIndicator = (
    <>
      <ResultIndicatorBar
        guess={guess}
        additionalInfo={(() => {
          const submittedEntitiesCount = guess?.getIn(['content', 'entities'], List()).size;
          const expectedEntityCount = (activeQuestion.getValidResponse() || List()).size;
          const isSubmitted = !!guess?.getId();

          if (expectedEntityCount > 0 && submittedEntitiesCount === 0 && !isSubmitted) {
            return '';
          }

          if (expectedEntityCount > 0 && submittedEntitiesCount === 0 && isSubmitted) {
            return 'No answer submitted.';
          }

          if (submittedEntitiesCount > expectedEntityCount) {
            return 'Too many answers.';
          }

          if (submittedEntitiesCount < expectedEntityCount) {
            return 'Incomplete answer.';
          }
          return null;
        })()}
      />
      <GraphingQuestionInput
        draftGuessEntities={draftGuessEntities}
        guess={guess}
        isExplanation
        mode={modes.reviewing}
        question={activeQuestion}
        storeName={activeQuestionStore.getName()}
      />
    </>
  );

  // memoizing the input area wrapper to prevent re-render loop when GQI updates guess entities
  const GraphingQuestionInputAreaWrapper = useCallback(
    (props) => (
      <GraphingQuestionInput
        {...props}
        draftGuessEntities={draftGuessEntities}
        guess={guess}
        mode={questionState === 'PreGuess' ? modes.guessing : modes.reviewing}
      />
    ),
    [draftGuessEntities, guess, questionState]
  );

  return (
    <QuestionComponent
      {...questionComponentProps}
      customHOA={<></>}
      customResultIndicator={customResultIndicator}
      ActiveQuestionInputArea={GraphingQuestionInputAreaWrapper}
    />
  );
};

type GraphingQuestionTypeProps = Omit<QuestionComponentProps, 'questionState'>;

const GraphingQuestion = {
  PreGuess: (props: GraphingQuestionTypeProps) => (
    <GraphingQuestionComponent {...props} questionState='PreGuess' />
  ),
  PostGuess: (props: GraphingQuestionTypeProps) => (
    <GraphingQuestionComponent {...props} questionState='PostGuess' />
  )
};

export default GraphingQuestion;
