import Immutable from 'immutable';
import {Store} from '../framework';
import ModalActions from './ModalActions';

class ModalStore extends Store {
  constructor(name) {
    super(name);

    const initialData = Immutable.Map({
      hasActiveModal: false
    });
    this.setInitialData(initialData);
    this.handle(ModalActions.SHOW_MODAL, this._showModal);
    this.handle(ModalActions.HIDE_MODAL, this._hideModal);
  }

  _showModal(key) {
    this.writeData((store) => {
      return store.set('hasActiveModal', true).setIn([key, 'display'], true);
    });
  }

  _hideModal(key) {
    this.writeData((store) => {
      return store.set('hasActiveModal', false).setIn([key, 'display'], false);
    });
  }

  isModalDisplayed(key) {
    return this.readData().getIn([key, 'display'], false);
  }

  hasActiveModal() {
    return this.readData().get('hasActiveModal');
  }
}

export default new ModalStore('ModalStore');
