import React from 'react';
import {Helmet} from 'react-helmet';

import {Heading, Text, Card, Anchor} from '@albert-io/atomic';

import Subheader from 'generic/Subheader/Subheader.react';
import {title, meta} from 'lib/head';

import browser from './images/browser.svg';
import devices from './images/devices.svg';
import email from './images/email.svg';
import security from './images/security.svg';
import systems from './images/systems.svg';

import './software-considerations.scss';

const Section = (props) => <Card className='u-display_flex' paddingLevel={4} {...props} />;
const SectionTitle = (props) => <Heading size='s' withMargin {...props} />;
const SectionItem = (props) => <Heading size='xs' {...props} />;
const SectionDetail = (props) => <Text paragraph as='p' {...props} />;

export default class SoftwareConsiderations extends React.PureComponent {
  render() {
    return (
      <div className='software-considerations'>
        <Helmet>
          {title('Software Considerations | Albert')}
          {meta.description(
            'Software compatibility and considerations when using Albert on your device at home or school.'
          )}
        </Helmet>
        <Subheader title='Software Considerations' />

        <div className='u-pad_2'>
          <Section>
            <img src={browser} alt='' />
            <div className='software-considerations-section__content'>
              <SectionTitle>Browser</SectionTitle>
              <SectionDetail>
                Whenever possible, we recommend that you use the latest versions of Google Chrome,
                Firefox, or Safari to view our site.
              </SectionDetail>
            </div>
          </Section>

          <Section>
            <img src={security} alt='' />
            <div className='software-considerations-section__content'>
              <SectionTitle>Security and Privacy</SectionTitle>
              <SectionItem>Payment Processing</SectionItem>
              <SectionDetail>
                All transactions on Albert.io go through Stripe, our payment processing vendor.
                Stripe allows us to be compliant with PCI and ensures that your transaction data is
                secure.
              </SectionDetail>
              <SectionItem>SSL</SectionItem>
              <SectionDetail>
                We use 256 bit SSL encryption for all actions on our site that exchange personal
                information such as usernames, emails, passwords, and credit card information.
              </SectionDetail>
              <SectionItem>Privacy</SectionItem>
              <SectionDetail>
                Under no circumstances do we ever sell or share user data with external parties,
                except in the operation of our own platform. Your data and info is safe
                with&nbsp;us.
              </SectionDetail>
              <SectionItem>Domain Allowlist Requirements</SectionItem>
              <SectionDetail>
                In order to ensure you can effectively use Albert and access all of our content, you
                or your school will need to allow access to certain domains. To see if you are
                currently blocking any of the necessary domains, use our{' '}
                <Anchor href='/support/compatibility.html'>compatibility check page</Anchor>.
              </SectionDetail>
            </div>
          </Section>

          <Section>
            <img src={devices} alt='' />
            <div className='software-considerations-section__content'>
              <SectionTitle>Devices</SectionTitle>
              <SectionItem>Desktops</SectionItem>
              <SectionDetail>
                We were first built for desktops so as long as your screen size is over 11”, you
                should have no issues rendering our website.
              </SectionDetail>
              <SectionItem>Notebooks</SectionItem>
              <SectionDetail>
                We support Chromebooks and all other cloud-based personal computers. All you need is
                an internet connection and a modern web browser.
              </SectionDetail>
              <SectionItem>Tablets</SectionItem>
              <SectionDetail>
                Tablets should have a very similar experience to desktops and we are compatible with
                any Android or iOS tablet.
              </SectionDetail>
              <SectionItem>Smartphones</SectionItem>
              <SectionDetail>
                As long as you have an internet connection, you can use Albert.io through any
                smartphone browser, although the rendering will not be as usable as on a desktop or
                tablet. Stay tuned for the launch of our native mobile apps!
              </SectionDetail>
            </div>
          </Section>

          <Section>
            <img src={email} alt='' />
            <div className='software-considerations-section__content'>
              <SectionTitle>Email Deliverability</SectionTitle>
              <SectionDetail>
                To make sure you can receive critical emails from us, like account verification and
                password reset emails, ensure that your network’s spam filters allow emails sent
                from Albert.io and mandrill.com. If our emails bounce when trying to send to you,
                your email will be added to a blacklist to protect our sending reputation.
              </SectionDetail>
            </div>
          </Section>

          <Section>
            <img src={systems} alt='' />
            <div className='software-considerations-section__content'>
              <SectionTitle>Operating Systems</SectionTitle>
              <SectionDetail>
                Since Albert.io runs solely as a web application, it requires no downloads and thus
                it does not matter whether you are using Windows or Mac.
              </SectionDetail>
            </div>
          </Section>
        </div>
      </div>
    );
  }
}
