import React from 'react';
import PropTypes from 'prop-types';
import {Inert} from '@albert-io/atomic';

const InteractivityHandler = ({
  interactive,
  children
}) => {
  return (
    <Inert disabled={interactive}>
      {children}
    </Inert>
  );
};

InteractivityHandler.propTypes = {
  interactive: PropTypes.bool,
  children: PropTypes.node
};

InteractivityHandler.defaultProps = {
  interactive: true
};

export default InteractivityHandler;
