import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import eliminationToolStore from './EliminationTool.store';
import multipleChoiceQuestionActions from '../MultipleChoiceQuestion.actions';
import {callTargetedAction} from 'client/framework';

import './elimination-tool.scss';

export default class AnswerOptionEliminator extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    optionId: PropTypes.string,
    isGuessSubmitted: PropTypes.bool,
    store: PropTypes.any
  };

  toggleEliminateOption = () => {
    callTargetedAction({
      name: multipleChoiceQuestionActions.TOGGLE_ELIMINATE_OPTION,
      payload: this.props.optionId,
      targetStore: this.props.store.getName()
    });
    if (
      this.props.store.isOptionEliminated(this.props.optionId) &&
      this.props.store.isSelectedOption(this.props.optionId)
    ) {
      callTargetedAction({
        name: multipleChoiceQuestionActions.REMOVE_OPTION_FROM_SELECTED,
        payload: this.props.optionId,
        targetStore: this.props.store.getName()
      });
    }
  };

  render() {
    if (this.props.isGuessSubmitted) {
      return <div>{this.props.children}</div>;
    }
    const store = this.props.store;
    const optionId = this.props.optionId;
    const isEliminated = store.isOptionEliminated(optionId);
    const isHideEnabled = store.getHideShow();
    const wrapperClass =
      isEliminated && isHideEnabled
        ? 'elimination-tool__hidden-option'
        : 'elimination-tool__eliminator-option-wrapper';
    const iconClass = classnames('fa', {
      'fa-plus-circle': store.isOptionEliminated(optionId),
      'fa-times-circle': !store.isOptionEliminated(optionId)
    });
    const itemClass = classnames({
      'elimination-tool__answer-option--eliminated': store.isOptionEliminated(optionId),
      'elimination-tool__answer-option--not-eliminated': !store.isOptionEliminated(optionId)
    });
    const eliminatorIcon = (
      <div className={`elimination-tool__answer-option ${itemClass}`}>
        <span className={iconClass} onClick={this.toggleEliminateOption} />
      </div>
    );
    return (
      <div className={wrapperClass}>
        {eliminationToolStore.isEnabled() ? eliminatorIcon : null}
        {this.props.children}
      </div>
    );
  }
}
