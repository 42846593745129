/* eslint-disable no-underscore-dangle */
import {fromJS, List, Map} from 'immutable';
import QuestionTypeStore from 'components/QuestionTypes/QuestionType.store';
import {GuessModel} from 'resources/Guess/Guess.model';
import {GuessModelV1} from 'resources/augmented/Guess/GuessModel.v1';

import textHighlightQuestionActions from './TextHighlightQuestion.actions';

export default class TextHighlightQuestionStore extends QuestionTypeStore {
  constructor(name, questionId, question) {
    super(name);

    this.initialData = fromJS({
      ...this.draftGuessInitialData,
      endIndex: null,
      highlightPrompt: '',
      potentialIndex: null,
      preferredPermutation: '',
      questionId,
      selections: [],
      selectionIndicesAsMap: {},
      shouldShowExplanation: false,
      startIndex: null
    });

    this.setInitialData(this.initialData);
    this.writeData('__question', question);

    this.handleTargeted(textHighlightQuestionActions.SET_UP_QUESTION, this._setUpQuestion);
    this.handleTargeted(textHighlightQuestionActions.CLEAR_SELECTIONS, this._clearSelections);
    this.handleTargeted(
      textHighlightQuestionActions.CLEAR_PENDING_SELECTION,
      this._clearPendingSelection
    );
    this.handleTargeted(textHighlightQuestionActions.START_SELECTION, this._startSelection);
    this.handleTargeted(textHighlightQuestionActions.POTENTIAL_SELECTION, this._potentialSelection);
    this.handleTargeted(textHighlightQuestionActions.END_SELECTION, this._endSelection);
    this.handleTargeted(textHighlightQuestionActions.REMOVE_SELECTION, this._removeSelection);
  }

  _addSelection() {
    let newSelection = new List([this.startIndex, this.endIndex]).sort();

    newSelection = this._trimWhitespace(newSelection);

    if (newSelection === null) {
      this._clearPendingSelection();
      return;
    }

    this.writeData(
      'selections',
      this.selections
        .filterNot((selection) => {
          const isInside = newSelection.some(
            (val) => selection.get(0) <= val && val <= selection.get(1)
          );
          const doesSurround =
            newSelection.get(0) <= selection.get(0) && newSelection.get(1) >= selection.get(1);
          return isInside || doesSurround;
        })
        .push(newSelection)
    );

    this._setSelectedIndicesAsMap();
    this._clearPendingSelection();
    super.kickoffDebouncedSaveDraftGuess();
  }

  afterLoadDraftGuess(draftGuess) {
    this.writeData('selections', this.parseGuess(draftGuess));
    this._setSelectedIndicesAsMap();
  }

  buildCorrectGuess(question) {
    return new GuessModel({
      content: new Map({
        guessSet: question.getValidResponse()
      }),
      correct: true,
      rubric: question.getMeta().getQuestionRubric(),
      solutionText: question.getSolutionText(),
      pointsPossible: question.getPoints(),
      pointsEarned: question.getPoints(),
      question_v1: question
    });
  }

  buildEmptyGuess(question) {
    return new GuessModelV1({
      content: Map({
        guessSet: Map()
      }),
      correct: false,
      points_earned: 0,
      question_v1: question
    });
  }

  buildGuessContent() {
    const formattedSelections = this.selections.map((selection) => {
      return {
        start: selection.get(0),
        end: selection.get(1)
      };
    });
    const guessContent = !formattedSelections.isEmpty()
      ? {guessSet: formattedSelections.toJS()}
      : {};
    return guessContent;
  }

  _clearGuess() {
    super._clearGuess();

    this.writeData('selections', this.initialData.get('selections'));
    this._setSelectedIndicesAsMap();
    super.kickoffDebouncedSaveDraftGuess();
  }

  _clearPendingSelection() {
    this.writeData('startIndex', null);
    this.writeData('potentialIndex', null);
    this.writeData('endIndex', null);
  }

  _clearSelections() {
    this.writeData('selections', new List());
    super.kickoffDebouncedSaveDraftGuess();
  }

  _endSelection(index) {
    const parsedIndex = parseInt(index, 10);

    if (this.startIndex === parsedIndex) {
      this._removeSelection(parsedIndex);
      this._clearPendingSelection();
      return;
    }
    this.writeData('endIndex', parsedIndex);
    this._addSelection();
  }

  get endIndex() {
    return this.readData('endIndex');
  }

  get highlightPrompt() {
    const prompt = this.readData('highlightPrompt');
    /**
     * In the `QuestionEditor` when "Preview"ing the `highlight_prompt` on
     * a question can be `null`. The renderer expects it to always be a string.
     */
    return prompt || '';
  }

  get potentialIndex() {
    return this.readData('potentialIndex');
  }

  get preferredPermutation() {
    return this.readData('preferredPermutation');
  }

  get selectionIndicesAsMap() {
    return this.readData('selectionIndicesAsMap');
  }

  get selections() {
    return this.readData('selections');
  }

  get startIndex() {
    return this.readData('startIndex');
  }

  isIndexInCurrentSelection(index) {
    if (this.startIndex === null || this.potentialIndex === null) {
      return false;
    }

    const potentialSelection = new List([this.startIndex, this.potentialIndex]).sort();
    return potentialSelection.get(0) <= index && index <= potentialSelection.get(1);
  }

  isIndexInPreferredPermutation(index) {
    const {preferredPermutation} = this;
    const validResponse = this.getQuestion().getValidResponse();
    if (!preferredPermutation || validResponse === null || validResponse?.isEmpty()) {
      return false;
    }
    return validResponse
      .get(preferredPermutation)
      .map((permutation) => permutation.toList())
      .some((selection) => selection.min() <= index && index <= selection.max());
  }

  isIndexSelected(index) {
    return this.selectionIndicesAsMap.get(index);
  }

  _loadGuess(guess) {
    super._loadGuess(guess);
    this.writeData('selections', this.parseGuess(guess));
    this._setSelectedIndicesAsMap();
  }

  _onSubmitAnswerEnd(responseQuestion) {
    this.writeData('preferredPermutation', responseQuestion.get('correctestPermutation'));
  }

  parseGuess(guess) {
    return (
      guess.getIn(['content', 'guessSet'])?.map((guessObj) => guessObj.toList().sort()) ?? List()
    );
  }

  _potentialSelection(index) {
    this.writeData('potentialIndex', parseInt(index, 10));
  }

  _removeSelection(index) {
    const indexToDelete = this.selections.findIndex((selection) => {
      return selection.get(0) <= index && index <= selection.get(1);
    });
    if (indexToDelete < 0) {
      return;
    }
    this.writeData('selections', this.selections.delete(indexToDelete));
    this._setSelectedIndicesAsMap();
    super.kickoffDebouncedSaveDraftGuess();
  }

  _setSelectedIndicesAsMap() {
    // There is a mismatch between how the store stores a user's answer and how a correct answer is returned from the question itself.
    // I think a more ideal solution would be to update the store's logic to fix this mismatch, but this feels like a non-trivial
    // amount of work that carries significant regression risk. So instead I opted to normalize the values so that the page doesn't crash.
    if (Map.isMap(this.selections)) {
      let normalizedSelections = new List();

      this.selections.first().forEach((value) => {
        const start = value.get('start');
        const end = value.get('end');

        normalizedSelections = normalizedSelections.push(new List([start, end]));
      });

      this.writeData('selections', normalizedSelections);
    }

    let selectionIndicesAsMap = new Map();

    this.selections.forEach((selection) => {
      for (let i = selection.first(); i <= selection.last(); i += 1) {
        selectionIndicesAsMap = selectionIndicesAsMap.set(i, true);
      }
    });

    this.writeData('selectionIndicesAsMap', selectionIndicesAsMap);
  }

  _setUpQuestion({highlightPrompt, preferredPermutation, rubric}) {
    this.writeData((store) => {
      const updatedStore = store
        .set('highlightPrompt', highlightPrompt)
        .set('preferredPermutation', preferredPermutation);

      if (rubric) {
        const formatedRubric = rubric.mapKeys((key) =>
          key === 'valid_response' ? 'validResponse' : key
        );
        return updatedStore.set('rubric', formatedRubric);
      }

      return updatedStore;
    });
  }

  _startSelection(index) {
    this.writeData('startIndex', parseInt(index, 10));
  }

  _trimWhitespace(selection) {
    const prompt = this.highlightPrompt;
    const shouldTrimWhitespace = selection.some((index) => prompt[index].search(/\s/) !== -1);
    if (!shouldTrimWhitespace) {
      return selection;
    }
    const selectionText = prompt.substring(selection.first(), selection.last() + 1);
    const trimmedStart = selection.first() + selectionText.search(/\S/);
    const trimmedEnd = selection.last() - selectionText.split('').reverse('').join('').search(/\S/);

    if (trimmedStart >= trimmedEnd) {
      return null;
    }

    return new List([trimmedStart, trimmedEnd]);
  }
}
