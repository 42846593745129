import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {omit} from 'lodash';
import shallowEqual from 'shallowequal';
import makeDataProps from 'lib/makeDataProps';
import '../common/inputs.scss';
import './number-input.scss';

export default class NumberInput extends React.Component {
  static propTypes = {
    autoCorrect: PropTypes.bool,
    className: PropTypes.string,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    updateInputOnValueMismatch: PropTypes.bool,
    inputNodeRef: PropTypes.func,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    labelInsideInput: PropTypes.string,
    maxLength: PropTypes.number,
    max: PropTypes.number,
    min: PropTypes.number,
    name: PropTypes.string,
    note: PropTypes.any,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    step: PropTypes.number,
    value: PropTypes.number
  };

  static defaultProps = {
    autoCorrect: false,
    className: '',
    disabled: false,
    error: false,
    errorMessage: 'Please enter correct data',
    name: '',
    note: null,
    onBlur: () => {},
    onChange: () => {},
    inputNodeRef: () => {},
    placeholder: '',
    required: false,
    step: 1,
    updateInputOnValueMismatch: true
  };

  constructor() {
    super();

    this.timeoutId = null;
  }

  componentDidMount() {
    this.inputNode.addEventListener('change', this.handleChange);
  }

  shouldComponentUpdate(nextProps) {
    return (
      !shallowEqual(omit(this.props, 'value'), omit(nextProps, 'value')) ||
      nextProps.value !== Number(this.inputNode.value)
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

  handleChange = (e) => {
    this.props.onChange(e);
    this.updateOnMismatch();
  };

  handleOnBlur = (e) => {
    if (this.props.autoCorrect) {
      const value = parseFloat(e.target.value);
      const minimum = parseFloat(this.props.min);
      const maximum = parseFloat(this.props.max);

      if (!Number.isNaN(minimum) && value < minimum) {
        e.target.value = minimum;
        this.props.onChange(e);
      }

      if (!Number.isNaN(maximum) && value > maximum) {
        e.target.value = maximum;
        this.props.onChange(e);
      }
    }

    this.props.onBlur(e);
    this.updateOnMismatch();
  };

  updateOnMismatch = () => {
    this.timeoutId = setTimeout(() => {
      if (
        this.props.updateInputOnValueMismatch &&
        this.props.value !== Number(this.inputNode.value) &&
        Number.isFinite(this.props.value)
      ) {
        this.inputNode.value = this.props.value;
      }
    });
  };

  generateLabel() {
    if (!this.props.label) {
      return null;
    }

    return <div className='a-form-input__label'>{this.props.label}</div>;
  }

  render() {
    const isError = this.props.error;
    const inputClass = classnames('a-form-input__element', {
      'a-form-input__element--error': isError
    });
    const errorContainer = isError ? (
      <div className='a-form-input__error'>{this.props.errorMessage}</div>
    ) : null;

    const noteContainer =
      this.props.note !== null ? <div className='a-form-input__note'>{this.props.note}</div> : null;
    const dataProps = makeDataProps(this.props);
    return (
      <label
        className={`a-form-input number-input__label ${this.props.className}`}
        data-label-inside-input={this.props.labelInsideInput}
      >
        {this.generateLabel()}
        <input
          {...dataProps}
          ref={(node) => {
            this.inputNode = node;
            this.props.inputNodeRef(node);
          }}
          className={inputClass}
          type='number'
          name={this.props.name}
          required={this.props.required}
          maxLength={this.props.maxLength}
          max={this.props.max}
          min={this.props.min}
          placeholder={this.props.placeholder}
          disabled={this.props.disabled}
          defaultValue={this.props.defaultValue || this.props.value}
          onChange={this.handleChange}
          onBlur={this.handleOnBlur}
          step={this.props.step}
        />
        {noteContainer}
        {errorContainer}
      </label>
    );
  }
}
