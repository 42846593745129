import React, {useCallback, useState, useRef, useEffect} from 'react';

import {useSlate} from 'slate-react';

import {Card, DropdownItem, Icon, Button} from '@albert-io/atomic';

import {
  areBlocksActive,
  toggleBlock,
  withWorkaround,
  fontTypes,
  FontType,
  ButtonProps
} from '../../Utils/editor.utils';

type FontDropdownProps = Omit<ButtonProps<FontType>, 'icon' | 'type'>;

const maxIndex = 3;
export const FontDropdown = ({tabIndex, ...rest}: FontDropdownProps) => {
  const editor = useSlate();
  const isActive = areBlocksActive(editor, [...fontTypes]);
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    <Button
      aria-label='font dropdown button'
      className='written-submission__font-button'
      color={isActive ? 'primary' : 'secondary'}
      variant={isActive ? 'faded' : 'outlined'}
      onClick={() => {
        setShowDropdown(true);
      }}
      tabIndex={showDropdown ? -1 : tabIndex}
      {...rest}
    >
      <Icon icon='text-size' />
      <Icon icon='caret-down' />
      {showDropdown && <FontDropdown.Tray onClose={() => setShowDropdown(false)} />}
    </Button>
  );
};
type FontToText = {
  [key in FontType]: {text: string; class: string};
};

const fontTypeToAttr: FontToText = {
  heading: {text: 'Title', class: 'written-submission__title'},
  'heading-one': {text: 'Heading', class: 'written-submission__heading'},
  'heading-two': {text: 'Subheading', class: 'written-submission__subheading'}
};

FontDropdown.Tray = ({onClose}: any) => {
  const editor = useSlate();

  const dropdownRef = useRef<HTMLDivElement>(null);
  const [activeCursor, setActiveCursor] = useState<number | null>(0);

  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (dropdownRef.current) {
        if (!dropdownRef.current.contains(event.target as Node)) {
          onClose();
        }
      }
    },
    [onClose]
  );

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      event.preventDefault();
      if (dropdownRef.current && dropdownRef.current.contains(event.target as Node)) {
        if (event.key === 'ArrowDown') {
          setActiveCursor((oldCursor) => {
            return oldCursor! < maxIndex ? oldCursor! + 1 : 0;
          });
        } else if (event.key === 'ArrowUp') {
          setActiveCursor((oldCursor) => {
            return oldCursor! > 0 ? oldCursor! - 1 : maxIndex;
          });
        } else if (event.key === 'ArrowRight' || event.key === 'ArrowLeft') {
          onClose();
        } else if (event.key === 'Enter' && activeCursor !== null) {
          const selected = dropdownRef.current.children[activeCursor] as HTMLElement;
          if (selected) {
            selected.click();
          }
        }
      }
    },
    [onClose, activeCursor]
  );

  useEffect(() => {
    if (dropdownRef.current && activeCursor !== null) {
      const selected = dropdownRef.current.children[activeCursor] as HTMLElement;
      if (selected) {
        selected.focus();
      }
    }
  }, [activeCursor]);

  useEffect(() => {
    global.document.addEventListener('keydown', handleKeyPress);
    global.document.addEventListener('click', handleClick);
    return () => {
      global.document.removeEventListener('keydown', handleKeyPress);
      global.document.removeEventListener('click', handleClick);
    };
  }, [handleKeyPress, handleClick]);

  return (
    <Card className='written-submission__toolbar-dropdown ' ref={dropdownRef}>
      {fontTypes.map((type, index) => {
        const isCurrent = activeCursor === index;
        return (
          <DropdownItem
            textProps={{className: fontTypeToAttr[type].class}}
            tabIndex={isCurrent ? 0 : -1}
            as='button'
            onClick={() => {
              withWorkaround(editor, () => {
                toggleBlock(editor, type);
                onClose();
              });
            }}
          >
            {fontTypeToAttr[type].text}
          </DropdownItem>
        );
      })}
      <DropdownItem
        as='button'
        tabIndex={-1}
        onClick={() => {
          withWorkaround(editor, () => {
            toggleBlock(editor, 'paragraph');
            onClose();
          });
        }}
      >
        Body
      </DropdownItem>
    </Card>
  );
};
