import React, {useCallback} from 'react';
import {Map} from 'immutable';

import TextInput from 'client/generic/Forms/TextInput/TextInput';
import TextArea from 'client/generic/Forms/TextArea/TextArea.react';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';

import './free-form-editor.scss';
import {useSupplementEditorV2Store} from 'client/Supplements/SupplementEditor/SupplementEditorV2Store';
import {useShallow} from 'zustand/react/shallow';
import {PRIMARY_CONTENT_LANGUAGE} from 'client/EditPage/V2/QuestionEditorV2Store.types';
import {TranslationCommentStatusEditor} from 'client/Supplements/SupplementEditor/TranslationCommentStatusEditor';

interface FreeFormEditorProps {
  supplement: any; // AuthoringSupplementModelV1
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  errors: Map<any, any>;
}

const FreeFormEditor = ({supplement, onChange, errors}: FreeFormEditorProps) => {
  const {currentLanguage, updateTranslatedField, translatedName, translatedText} =
    useSupplementEditorV2Store(
      useShallow((state) => ({
        currentLanguage: state.currentLanguage,
        updateTranslatedField: state.updateTranslatedField,
        translatedName:
          state
            .currentTranslatedSupplement()
            ?.translated_fields?.find((field) => field.field === 'name')?.text || '',
        translatedText:
          state
            .currentTranslatedSupplement()
            ?.translated_fields?.find((field) => field.field === 'content.text')?.text || ''
      }))
    );

  const nameValue =
    currentLanguage === PRIMARY_CONTENT_LANGUAGE ? supplement.getName() : translatedName;
  const textValue =
    currentLanguage === PRIMARY_CONTENT_LANGUAGE ? supplement.getText() : translatedText;

  const handleNameChange = useCallback(
    (e) => {
      if (currentLanguage === PRIMARY_CONTENT_LANGUAGE) {
        onChange(e);
      } else {
        updateTranslatedField(currentLanguage, 'name', 'text', e.target.value);
      }
    },
    [currentLanguage, onChange, updateTranslatedField]
  );

  const handleTextChange = useCallback(
    (e) => {
      if (currentLanguage === PRIMARY_CONTENT_LANGUAGE) {
        onChange(e);
      } else {
        updateTranslatedField(currentLanguage, 'content.text', 'text', e.target.value);
      }
    },
    [currentLanguage, onChange, updateTranslatedField]
  );

  return (
    <div className='free-form-editor'>
      <div className='free-form-editor__fieldset'>
        <TextInput
          className='free-form-editor__input'
          data-setter-method='setName'
          error={currentLanguage === PRIMARY_CONTENT_LANGUAGE && Boolean(errors.get('name'))}
          errorMessage={currentLanguage === PRIMARY_CONTENT_LANGUAGE && errors.get('name', null)}
          label='Name'
          name='name'
          onChange={handleNameChange}
          value={nameValue}
        />
        <TranslationCommentStatusEditor fieldName='name' />
        <TextArea
          className='free-form-editor__input free-form-editor__input--textarea'
          data-setter-method='setText'
          error={currentLanguage === PRIMARY_CONTENT_LANGUAGE && Boolean(errors.get('text'))}
          errorMessage={currentLanguage === PRIMARY_CONTENT_LANGUAGE && errors.get('text', null)}
          label='Text'
          name='text'
          onChange={handleTextChange}
          value={textValue}
        />
        <TranslationCommentStatusEditor fieldName='content.text' />
      </div>
      <div className='free-form-editor-preview__wrapper'>
        <div className='a-form-input__label'>Preview</div>
        <MarkdownRendererV2 className='free-form-editor__markdown-renderer' text={textValue} />
      </div>
    </div>
  );
};

export default FreeFormEditor;
