import React, {ReactElement, useContext, useRef} from 'react';
import {debounce} from 'lodash';
import {Link, browserHistory, withRouter} from 'react-router';
import {Anchor, Button, Icon, IconButton, SearchInput} from '@albert-io/atomic';

import {useScrollDirection} from 'lib/hooks/useScrollDirection';
import {pushQueryParams, removeQueryParams} from 'client/history';
import sessionStore from 'client/Session/SessionStore';
import classNames from 'classnames';

import ReportsExport from '../Report/Export/ReportsExport.react';
import {ReportsContext} from '../Reports.context';
import Breadcrumbs, {Crumb} from '../Breadcrumbs/Breadcrumbs.react';
import Headings from '../Breadcrumbs/BreadcrumbHeadings.react';
import {isDimension, isTopLevelFilter} from '../reports.utils';

import './report-toolbar.scss';
import Filters from '../Report/Filters/Filters.react';
import {ResetFiltersButton} from '../Report/Filters/ResetFiltersButton';
import Metrics from '../Report/Metrics/Metrics.react';

import {RefreshButton} from '../Report/RefreshButton';

import DrilldownSelector from './DrilldownSelector.react';

interface Props {
  location: {
    pathname: string;
    search?: string;
  };
  linkToOldReport: string;
  hasProUpgrade: boolean;
  isReportPreview: boolean;
}

const debouncedOnSearch = debounce((searchStr) => {
  pushQueryParams({s: searchStr});
}, 500);

function ReportToolbar({location, linkToOldReport, hasProUpgrade, isReportPreview}: Props) {
  const {path, data, loading, metrics, dimensions, searchString} = useContext(ReportsContext);
  const scrollDirection = useScrollDirection(50);
  const reportToolbarRef = useRef(null);
  const isMultiDimensional = dimensions.length > 1;

  const breadcrumbTrail = [...path];

  const isCollapsed = scrollDirection === 'down' && !(dimensions.length === 0 || isReportPreview); // do not collapse if we're showing a report preview or gradebook preview or if no report has been selected

  const renderHeading = () => {
    const filteredPath = [...path].filter((item) => item !== '');
    for (let i = filteredPath.length - 2; i >= 0; i -= 1) {
      const currItem = filteredPath[i]; // represents the dimension or toplevelfilter
      let filterId = filteredPath[i + 1]; // represents the id of the toplevelfilter (e.g classroom, school) or dimension (student, question, assignment)
      if (filterId === 'me') {
        filterId = sessionStore.getUserId();
      }
      if (isTopLevelFilter(currItem) || (isDimension(currItem) && filterId)) {
        const ReportToolbarHeading = Headings[currItem];
        return (
          <ReportToolbarHeading
            className={classNames('report-toolbar__heading u-text-transform_capitalize')}
            id={filterId}
            size={isCollapsed ? 's' : 'm'}
          />
        );
      }
    }
    return null;
  };

  const createNewBreadCrumbsfromPath = () => {
    const breadcrumbs = breadcrumbTrail.reduce(
      (prev: React.ReactNode[], curr, index) => {
        if (curr === 'gradebook') {
          const itemHref = `${breadcrumbTrail.slice(0, index + 1).join('/')}${location.search}`;
          prev.push(
            <Crumb key={curr} to={itemHref}>
              Gradebook
            </Crumb>
          );
        } else if (isTopLevelFilter(curr) || isDimension(curr)) {
          let filterId = breadcrumbTrail[index + 1]; // represents the id of the toplevelfilter (e.g classroom, school) or dimension (student, question, assignment)
          if (filterId === 'me') {
            filterId = sessionStore.getUserId();
          }
          const lastSelectedReport = breadcrumbTrail[index + 2];
          if (filterId) {
            const nextItemHref = `${breadcrumbTrail
              .slice(0, index + 1)
              .join('/')}/${filterId}/${lastSelectedReport}${location.search}`; // we want the breadcrumb to remember the report selected using lastSelectedReport
            const Next = Breadcrumbs[curr];
            prev.push(<Next id={filterId} key={filterId} to={nextItemHref} />);
          }
        }
        return prev;
      },
      [
        <Crumb key='Home' to='/reports'>
          Home
        </Crumb>
      ]
    );
    breadcrumbs[breadcrumbs.length - 1] = React.cloneElement(
      breadcrumbs[breadcrumbs.length - 1] as ReactElement,
      {isLast: true}
    );
    return breadcrumbs;
  };

  const newBreadCrumbs = createNewBreadCrumbsfromPath();

  const renderExportButton = () => {
    if (dimensions.length === 0 || isReportPreview) return null;
    if (!data) {
      return (
        <Button
          color='secondary'
          variant='outlined'
          className='u-white-space_nowrap'
          disabled
          size={isCollapsed ? 's' : 'm'}
        >
          Download Report
        </Button>
      );
    }
    return (
      <ReportsExport disabled={loading} metrics={metrics} path={path} isCollapsed={isCollapsed} />
    );
  };

  const heading = renderHeading();
  const exportButton = renderExportButton();

  return (
    <>
      <div
        className={classNames(
          'report-toolbar__nav u-align-items_center u-display_flex u-flex-wrap_wrap u-bgc_white'
        )}
        ref={reportToolbarRef}
      >
        <div className='report-toolbar__nav-list u-bgc_slate-050 u-color_slate-400 u-border-b u-border-color_slate-200 u-display_flex u-flex-wrap_wrap u-list-style_none u-align-items_center'>
          {newBreadCrumbs}
        </div>
        <div className='u-width_100pc'>
          <div
            className={classNames(
              'report-toolbar__title-bar u-display_flex u-flex-wrap_wrap u-border-b u-border-color_slate-200 u-width_100pc u-flex-direction_column',
              {
                'u-pad-t_3 u-pad-b_2': !isCollapsed,
                'u-pad-t_1 u-pad-b_1': isCollapsed
              }
            )}
          >
            <div className='u-display_flex u-align-items_center'>
              <IconButton
                color='primary'
                className='u-mar-r_1'
                variant='text'
                onClick={() => browserHistory.goBack()}
                label='Go back'
                icon='arrow-left'
                size={isCollapsed ? 's' : 'm'}
              />
              {heading}
              <div className='report-toolbar__title-bar-export-wrapper u-display_flex u-flex-direction_row u-gap_1 u-position_absolute'>
                <Button
                  variant='text'
                  as={Anchor}
                  href='https://forms.gle/gHThjifrvZUwyUnf6'
                  color='secondary'
                  target='_blank'
                  size={isCollapsed ? 's' : 'm'}
                >
                  <Icon icon='message-pen' className='u-mar-r_1' iconStyle='regular' />
                  Feedback
                </Button>
                {exportButton}
                <RefreshButton className='u-mar-l_1' isCollapsed={isCollapsed} />
              </div>
            </div>
            {linkToOldReport && (
              <div className='u-pad-l_4'>
                <Anchor
                  as={Link}
                  to={linkToOldReport}
                  className='u-text-decoration_underline'
                  inheritColor
                >
                  Click here
                </Anchor>{' '}
                to access the old version of this report.
              </div>
            )}
          </div>
          {!isCollapsed && <DrilldownSelector hasProUpgrade={hasProUpgrade} />}
          {!(dimensions.length === 0) &&
            !isReportPreview && ( // do not show filters bar when no report has been selected and when showing a report preview
              <div
                className={classNames('report-toolbar u-display_flex', {
                  'u-pad-tb_2 u-pad-lr_2': !isCollapsed,
                  'report-toolbar--collapsed u-pad-t_1 u-pad-b_1 u-mar-b_2': isCollapsed
                })}
              >
                <Filters isCollapsed={isCollapsed} hasProUpgrade={hasProUpgrade} />
                <div className='report-toolbar__reset-search-metrics-container'>
                  <ResetFiltersButton isCollapsed={isCollapsed} />
                  {!isMultiDimensional && ( // do not show search bar when there are multiple dimensions (gradebook)
                    <SearchInput
                      className='u-mar-l_auto'
                      onChange={(e) => debouncedOnSearch(e.target.value)}
                      onReset={() => removeQueryParams('s')}
                      placeholder='Search'
                      defaultValue={searchString}
                      size={isCollapsed ? 's' : 'regular'}
                      variant
                    />
                  )}
                  <Metrics isCollapsed={isCollapsed} />
                </div>
              </div>
            )}
        </div>
      </div>
    </>
  );
}

export default withRouter(ReportToolbar);
