// @flow
import moment from 'moment';
import themeImgPlaceholder from 'assets/images/empty-state-theme-icon.svg';
import constants from 'client/constants';

const nLevelHierarchy = {
  SUBTOPIC: 3,
  THEME: 1,
  TOPIC: 2
};

export const baseGuideLevelExtensions = {
  getGuideLevelType(): string {
    return Object.keys(nLevelHierarchy)
      .find((key) => {
        return nLevelHierarchy[key] === this.getNlevel();
      })
      .toLowerCase();
  },

  isTheme(): boolean {
    return this.getNlevel() === nLevelHierarchy.THEME;
  },

  isTopic(): boolean {
    return this.getNlevel() === nLevelHierarchy.TOPIC;
  },

  isSubtopic(): boolean {
    return this.getNlevel() === nLevelHierarchy.SUBTOPIC;
  },

  getThemeImg(): string {
    return this.cache('theme_img', () => {
      const path =
        this.get('theme_img') ||
        (this.getParent() !== null &&
          !this.getParent().isEmpty() &&
          this.getParent().get('theme_img'));
      if (!path) {
        /**
         * The placeholder will be local to the release (`publicPath`)
         */
        return themeImgPlaceholder;
      }
      return constants.USER_ASSETS_ORIGIN + '/' + path;
    });
  },

  getMetaCountOfQuestions(): number {
    return this.getMeta().getCountOfQuestions();
  },

  /**
   * Introspects the returned payload to count questions for Universal Search. Existing metadata functions, such
   * as getMetaCountOfQuestions do not take into account filters, and therefore are not usable for this feature.
   */
  getCountOfReturnedQuestions(): number {
    return this.getQuestionSets().reduce(
      (count, questionSet) => count + questionSet.getQuestions().size,
      0
    );
  },

  getMetaCountOfUniqueQuestionsAnswered(): number {
    return this.getMeta().getStudentCountOfUniqueGuesses();
  },

  getMetaStudentAccuracy(): number {
    return this.getMeta().getStudentAccuracy();
  },

  getMetaStudentCountOfGuesses(): number {
    return this.getMeta().getStudentCountOfGuesses();
  },

  getMetaStudentCountOfCorrectGuesses(): number {
    return this.getMeta().getStudentCountOfCorrectGuesses();
  },

  getMetaTimeSpentDuration(): moment {
    return moment.duration(this.getMeta().getStudentSumOfGuessesTimeElapsed());
  },

  hasQuestions(): boolean {
    return this.getMeta().getCountOfQuestions() > 0;
  }
};
