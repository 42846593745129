// @flow
import {Map, List} from 'immutable';
import {memoize} from 'lib/memoizer';

import type {GuideLevelV2} from 'resources/GeneratedModels/GuideLevel/GuideLevelModel.v2';

export class GuideLevelsCollectionModel {
  constructor(data = new List()) {
    this.getCollection = () => data;
    this.cache = memoize();
  }

  asMap(): Map<string, GuideLevelV2> {
    return this.cache('asMap', () =>
      this.getCollection().reduce((acc, level) => {
        return acc.set(level.getId(), level);
      }, Map())
    );
  }

  getGuideLevels(): List<GuideLevelV2> {
    return this.getCollection();
  }

  getGuideLevel(id): GuideLevelV2 {
    return this.getCollection().find((guideLevel) => guideLevel.getId() === id);
  }

  getGuideLevelsWithQuestionSets(): List<GuideLevelV2> {
    return this.cache('guide-levels-with-questions', () =>
      this.getGuideLevels().filter((guideLevel) => guideLevel.getMeta().getCountOfQuestions() > 0)
    );
  }

  getIdsToGuideLevelsWithQuestionSetsMap(): Map<string, List<GuideLevelV2>> {
    return this.cache('guide-levels-with-questions-ids', () =>
      this.getGuideLevelsWithQuestionSets().groupBy((guideLevel) => guideLevel.getId())
    );
  }

  getThemes(): List<GuideLevelV2> {
    return this.cache('themes', () =>
      this.getGuideLevels().filter((guideLevel) => guideLevel.isTheme())
    );
  }

  getThemesWithQuestionSets(): List<GuideLevelV2> {
    return this.cache('themes-with-sets', () =>
      this.getThemes().filter((guideLevel) => guideLevel.getMeta().getCountOfQuestions() > 0)
    );
  }

  getGuideLevelImmediateChildren(id: string): List<GuideLevelV2> {
    return this.cache(`guide-level-children-${id}`, () => {
      const childNLevel = this.getGuideLevel(id).getNlevel() + 1;
      return this.getGuideLevels().filter(
        (guideLevel) =>
          guideLevel.getNlevel() === childNLevel &&
          !guideLevel.getParent().isEmpty() &&
          guideLevel.getParent().getId() === id
      );
    });
  }

  isEmpty(): boolean {
    return this.getGuideLevels().size === 0;
  }

  getBreadcrumbStringForLevel(id: string): string {
    return this.cache(`breadcrumb-string-${id}`, () => {
      const guideLevel = this.getGuideLevel(id);
      let parent = guideLevel.getParent();
      let breadcrumbStrings = new List();

      while (parent && !parent.isEmpty()) {
        breadcrumbStrings = breadcrumbStrings.push(parent.getName());
        parent = parent.getParent();
      }

      return breadcrumbStrings.reverse().join(' > ');
    });
  }
}
