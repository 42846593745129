import React, {useContext} from 'react';
import DrilldownSelector from 'client/Reports/ReportToolbar/DrilldownSelector.react';

import FeatureFlag from 'client/components/FeatureFlag/FeatureFlag.react';

import {ReportsContext} from '../../Reports.context';

import AttemptFilter from './AttemptFilter';
import AttemptFilterIncludeExcludeDraftGuesses from './AttemptFilterIncludeExcludeDraftGuesses/AttemptFilterIncludeExcludeDraftGuesses';
import DateFilter from './DateFilter';
import SubjectFilter from './SubjectFilter';
import StandardSetFilter from './StandardSetFilter';

export default function Filters({
  isCollapsed,
  hasProUpgrade
}: {
  isCollapsed?: boolean;
  hasProUpgrade: boolean;
}) {
  const {subjectIdFromSearchParam, guideLevel, standardSets, start, end, attemptNumber} =
    useContext(ReportsContext);

  return (
    <>
      {isCollapsed && <DrilldownSelector isCollapsed hasProUpgrade={hasProUpgrade} />}
      <div className='u-display_flex u-gap_space-x1 u-align-items_center u-overflow-x_auto u-mar-r_auto u-flex-shrink_10 u-pad-r_6'>
        <DateFilter key={`${start}${end}`} />
        <FeatureFlag name='reports_include_exclude_draft_guesses'>
          <AttemptFilterIncludeExcludeDraftGuesses />
          <FeatureFlag.Fallback>
            <AttemptFilter key={attemptNumber} />
          </FeatureFlag.Fallback>
        </FeatureFlag>
        <SubjectFilter
          key={`${subjectIdFromSearchParam || 'no-subject'}${guideLevel || 'no-guide-level'}`}
        />
        <StandardSetFilter key={standardSets || 'no-standard-sets'} />
      </div>
    </>
  );
}
