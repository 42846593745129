import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {Link} from 'react-router';
import {
  Anchor,
  Badge,
  Button,
  Card,
  Icon,
  MenuListItem,
  MenuListItemRegularText,
  NavItem,
  Text
} from '@albert-io/atomic';
import {removeQueryParams} from 'client/history';

import {getFeatureFlagStatus} from 'client/components/FeatureFlag/FeatureFlag.react';

import {getNavigationLinksForPath} from '../navLinks';

import BetaNavButton from './BetaNavButton';

export default class MainLinks extends React.Component {
  static propTypes = {
    pathname: PropTypes.string
  };

  constructor() {
    super();
    this.getGlobalAssignmentsEnabled();
  }

  state = {
    globalAssignmentsEnabled: false
  };

  async getGlobalAssignmentsEnabled() {
    const globalAssignmentsFeatureFlag = await getFeatureFlagStatus('global_assignments');
    this.setState({globalAssignmentsEnabled: globalAssignmentsFeatureFlag});
  }

  isMatchingRoute(link) {
    const {pathname} = this.props;
    const linkRoute = link.get('route');
    let isMatchingRoute = false;
    if (
      /**
       * This insures that the Subjects header link will contain the active class
       * regardless of which group/subject combination is rendered. It will be replaced soon.
       */
      (link.has('matchingRoutes') && link.get('matchingRoutes').contains(pathname)) ||
      linkRoute === pathname ||
      pathname.startsWith(linkRoute) ||
      (link.has('matchingRoutes') &&
        link
          .get('matchingRoutes')
          .filter((path) => pathname.indexOf(path) === 0)
          .count() > 0)
    ) {
      isMatchingRoute = true;
    }
    return isMatchingRoute;
  }

  render() {
    const links = getNavigationLinksForPath(this.props.pathname, {
      globalAssignmentsEnabled: this.state.globalAssignmentsEnabled
    });
    return (
      <nav className='global-nav__main' aria-label='main'>
        <ul className='global-nav__main-list'>
          {links.map((link) => {
            if (link.has('dropdownLinks')) {
              return <Dropdown key={link.get('key')} link={link} pathname={this.props.pathname} />;
            }

            const isMatchingRoute = this.isMatchingRoute(link);
            const props = {
              [link.get('hardLink', false) ? 'href' : 'to']: link.get('route')
            };
            const LinkElement = link.get('hardLink', false) ? 'a' : Link;

            if (link.get('name') === 'Launch Assignment Builder') {
              return (
                <li key={link.get('key')} className='u-white-space_nowrap'>
                  <NavItem stateless as={LinkElement} active={isMatchingRoute}>
                    <BetaNavButton link={link} pathname={this.props.pathname} />
                  </NavItem>
                </li>
              );
            }

            return (
              <li key={link.get('key')}>
                <NavItem
                  {...props}
                  icon={link.get('icon', null)}
                  as={LinkElement}
                  active={isMatchingRoute}
                >
                  {link.get('name')}
                </NavItem>
              </li>
            );
          })}
        </ul>
      </nav>
    );
  }
}

class Dropdown extends React.Component {
  static propTypes = {
    link: ImmutablePropTypes.map,
    pathname: PropTypes.string
  };

  constructor() {
    super();

    this.state = {
      isOpen: false
    };

    this.containerRef = React.createRef();
    this.navItemRef = React.createRef();
    this.dropdownRef = React.createRef();
  }

  componentDidMount() {
    global.addEventListener('click', this.onClickOutsideComponent);
  }

  componentWillUnmount() {
    global.removeEventListener('click', this.onClickOutsideComponent);
  }

  handleToggle = () => {
    this.setState((state) => ({isOpen: !state.isOpen}));
  };

  handleClose = () => {
    this.setState({isOpen: false});
    this.navItemRef.current.focus();
  };

  onClickOutsideComponent = (e) => {
    if (this.state.isOpen && !this.containerRef.current.contains(e.target)) {
      this.handleClose();
    }
  };

  handleNavItemKeyDown = (e) => {
    // Enter Key
    if (e.key === 'Enter') {
      this.handleToggle();
    }
    // ESC Key
    if (e.key === 'Escape') {
      this.handleClose();
    }
    // Right/Down Arrow Keys
    if (['ArrowRight', 'ArrowDown'].includes(e.key) && this.state.isOpen) {
      e.preventDefault();
      this.dropdownRef.current.querySelector('.global-nav__dropdown-item').focus();
    }
  };

  handleDropdownKeyDown = (e) => {
    // ESC & Tab Key
    if (
      e.key === 'Escape' ||
      (e.key === 'Tab' && this.dropdownRef.current.lastChild === e.target.parentNode)
    ) {
      this.handleClose();
    }
    // Right/Down Arrow Keys
    if (['ArrowRight', 'ArrowDown'].includes(e.key)) {
      e.preventDefault();
      const {nextSibling, parentNode} = e.target.parentNode;
      if (nextSibling) {
        nextSibling.firstChild.focus();
      } else {
        parentNode.querySelector('.global-nav__dropdown-item').focus();
      }
    }
    // Left/Up Arrow Keys
    if (['ArrowLeft', 'ArrowUp'].includes(e.key)) {
      e.preventDefault();
      const {previousSibling, parentNode} = e.target.parentNode;
      if (previousSibling) {
        previousSibling.firstChild.focus();
      } else {
        parentNode.lastChild.firstChild.focus();
      }
    }
  };

  render() {
    const {link, pathname} = this.props;
    return (
      <li ref={this.containerRef} className='global-nav__has-dropdown'>
        <NavItem
          ref={this.navItemRef}
          as='span'
          tabIndex={0}
          aria-haspopup
          aria-expanded={this.state.isOpen}
          onKeyDown={this.handleNavItemKeyDown}
        >
          {link.get('name')}
          <Icon icon='caret-down' className='u-mar-lr_1' />
          {link.get('name') === 'Assignments' && (
            <Badge emphasis='bold' size='small' className='global-nav__beta-badge u-mar-l_1'>
              <Text
                color='primary-inverse'
                className='u-text-transform_uppercase u-font-weight_900'
              >
                Beta
              </Text>
            </Badge>
          )}
        </NavItem>
        <Card
          ref={this.dropdownRef}
          hidden={!this.state.isOpen}
          className='global-nav__main-link-dropdown'
          shadow='default'
          onKeyDown={this.handleDropdownKeyDown}
        >
          {link.get('dropdownLinks').map((subLink) => {
            const buttons = {
              'Create Assignment Beta': (
                <>
                  <Button
                    as={Link}
                    to={`${subLink.get('route')}`}
                    onClick={() => {
                      if (pathname.includes('/create-assignment')) {
                        removeQueryParams('draftId');
                        setTimeout(() => {
                          global.window.location.reload();
                        }, 100);
                      }
                    }}
                  >
                    <Icon icon='plus' className='u-mar-r_1' />
                    Create Assignment (Beta)
                  </Button>
                  <div className='a-divider u-mar-t_2 u-mar-b_1' />
                </>
              ),
              'View Drafts': (
                <>
                  <MenuListItem as={Link} to={subLink.get('route')}>
                    <MenuListItemRegularText>
                      <Icon icon={['far', 'file-alt']} className='u-mar-r_2' />
                      {subLink.get('name')}
                    </MenuListItemRegularText>
                  </MenuListItem>
                  <div className='a-divider u-mar-tb_1' />
                </>
              ),
              'Give Beta Feedback': (
                <MenuListItem
                  as={Anchor}
                  href={subLink.get('href')}
                  target='_blank'
                  rel='noopener noreferrer'
                  noUnderline
                >
                  <MenuListItemRegularText>
                    <Icon icon={['far', 'arrow-up-right-from-square']} className='u-mar-r_2' />
                    {subLink.get('name')}
                  </MenuListItemRegularText>
                </MenuListItem>
              )
            };
            return buttons[subLink.get('name')];
          })}
        </Card>
      </li>
    );
  }
}
