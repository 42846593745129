import React from 'react';

import {
  QuestionComponent,
  QuestionComponentProps
} from '../shared/QuestionComponent/QuestionComponent.react';

import TextHighlightInputArea from './TextHighlightInputArea.react';
import TextHighlightExplanation from './TextHighlightExplanation.react';

import './text-highlight-question.scss';

const TextHighlightQuestionComponent = (props: QuestionComponentProps) => {
  const {activeQuestion, activeQuestionStore} = props;
  return (
    <QuestionComponent
      {...props}
      customExplanation={
        <TextHighlightExplanation
          question={activeQuestion}
          storeName={activeQuestionStore.getName()}
        />
      }
      ActiveQuestionInputArea={TextHighlightInputArea}
    />
  );
};

type TextHighlightQuestionTypeProps = Omit<QuestionComponentProps, 'questionState'>;

const TextHighlightQuestion = {
  PreGuess: (props: TextHighlightQuestionTypeProps) => (
    <TextHighlightQuestionComponent {...props} questionState='PreGuess' />
  ),
  PostGuess: (props: TextHighlightQuestionTypeProps) => (
    <TextHighlightQuestionComponent {...props} questionState='PostGuess' />
  )
};

export {TextHighlightQuestion};
