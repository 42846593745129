import React from 'react';
import PropTypes from 'prop-types';
import {Link, withRouter} from 'react-router';
import awaitMandarkQueries from 'lib/hocs/awaitMandarkQueries';
import {resource} from '@albert-io/json-api-framework/request/builder';
import {callTargetedAction} from 'client/framework';
import {drawerActions} from 'client/generic/Drawer/DrawerActions';
import UpgradeSubjectModal from 'components/PracticeView/UpgradeSubjectModal/UpgradeSubjectModal.react';
import sessionStore from 'client/Session/SessionStore';
import {hasSubjectAccess} from 'lib/UserAccessUtil';
import {SubjectModelV2} from 'resources/GeneratedModels/Subject/SubjectModel.v2';
import './upgrade-subject-button.scss';

/**
 * @todo This should be in a utility somewhere or not required at all (passed via router props)
 */
const domainSlugsRegex = /\/(high-school|middle-school|ela|college|other)\//;

/**
 * Since we have routes that use different named props for the subject
 * slug (`slug`, `subject`), this is a helper method for determining if we are on a single
 * subject based on the provided props object.
 *
 * @param {Object} props
 */
function isSingleSubjectView(props) {
  return props.params.slug || props.params.subject || props.params.subjectSlug || false;
}
/**
 * Retrives the `url_slug` of a subject based on the provided props.
 * @param {Object} props
 */
function getUrlSlugFromProps(props) {
  return props.params.slug || props.params.subject || props.params.subjectSlug;
}

export const UpgradeButton = withRouter(
  awaitMandarkQueries(
    (props) => {
      const opts = {
        queries: {}
      };
      if (isSingleSubjectView(props)) {
        opts.queries.subject = resource('subject_v2')
          .mandarkEndpoint(['subjects_v2'])
          .findOne()
          .filter({url_slug: getUrlSlugFromProps(props)});
      }
      return opts;
    },
    class extends React.Component {
      static displayName = 'UpgradeButton';

      static propTypes = {
        location: PropTypes.shape({
          pathname: PropTypes.string
        }),
        params: PropTypes.shape({
          domain: PropTypes.string,
          group: PropTypes.string,
          slug: PropTypes.string
        }),
        subject: PropTypes.instanceOf(SubjectModelV2)
      };

      constructor(props) {
        super(props);
        this.state = {
          showUpgradeModal: false
        };
      }

      shouldShowUpgradeCallToAction = () => {
        return (
          (sessionStore.isTeacher() && isSingleSubjectView(this.props)) ||
          (this.props.subject && !this.props.subject.getSubscriptionsPrices().first())
        );
      };

      handleClick = () => {
        /**
         * @todo This should possibly be moved to a prop.
         */
        callTargetedAction({
          name: drawerActions.DRAWER_SET_DRAWER_VISIBILITY,
          payload: false,
          targetStore: 'DropdownLinksDrawer'
        });
        if (this.shouldShowUpgradeCallToAction()) {
          this.setState({showUpgradeModal: true});
        }
      };

      getMarketplaceLink() {
        /**
         * If we are going to show the "Upgrade" CTA we leave the link
         * empty and expect the modal to contain the actual marketplace link.
         */
        if (this.shouldShowUpgradeCallToAction()) {
          return '';
        }
        let {domain} = this.props.params;
        let {group} = this.props.params;
        let subjectUrlString = '';
        if (isSingleSubjectView(this.props)) {
          /**
           * If there is a `slug` we derive the domain and group from the subject.
           * @todo The subject model methods here might go away with the new course
           * library since a subject can belong to multiple "domains"/"groups" _and_
           * the values on the subject might not actually be representative of this.
           */
          domain = this.props.subject.getDomainSlug();
          group = this.props.subject.getGroupSlug();
          /**
           * We also supply the subject – this can be handled by the Marketplace
           * how it sees fit ("Add to Cart").
           *
           * @todo: Remove `&subjectId=` once we update the subject resources
           * with their new domain and group mappings. Until then, we must
           * send the subject ID in order to initialize the course library
           * with the correct filters applied.
           *
           * See also src/client/Modals/UpgradeCallToActionModal/UpgradeCallToActionModal.react.js
           */
          subjectUrlString = `subject=${getUrlSlugFromProps(
            this.props
          )}&subjectId=${this.props.subject.getId()}`;
        }

        if (!domain) {
          /**
           * @todo Ideally this can be derieved from router props at some point.
           */
          const result = domainSlugsRegex.exec(this.props.location.pathname);
          domain = result ? result[1] : null;
        }

        const params = [];

        if (domain) {
          params.push(`domain=${domain}`);
        }

        if (domain && group) {
          params.push(`group=${group}`);
        }

        if (subjectUrlString) {
          params.push(subjectUrlString);
        }

        const baseUrl = '/marketplace/subjects';
        return params.length === 0 ? baseUrl : `${baseUrl}?${params.join('&')}`;
      }

      render() {
        if (isSingleSubjectView(this.props) && sessionStore.hasValidSession()) {
          const {isReady, value} = hasSubjectAccess(this.props.subject.getId());
          if (isReady !== true || value === true) {
            return null;
          }
        }

        return (
          <>
            <Link
              className='button button--blue-medium upgrade-subject-button'
              to={this.getMarketplaceLink()}
              onClick={this.handleClick}
            >
              {isSingleSubjectView(this.props) ? 'Upgrade Subject' : 'Upgrade Subjects'}
            </Link>
            {this.state.showUpgradeModal && (
              <UpgradeSubjectModal
                subject={this.props.subject}
                handleClose={() => this.setState({showUpgradeModal: false})}
              />
            )}
          </>
        );
      }
    }
  )
);
