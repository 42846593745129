import React, {useState, useEffect, useCallback, useMemo} from 'react';
import {debounce} from 'lodash';
import {List} from 'immutable';
import {
  Modal,
  Dialogue,
  LegacyFindAndApply,
  ListGroup,
  ListGroupItem,
  Checkbox,
  Label,
  Button,
  Text,
  CheckboxChip
} from '@albert-io/atomic';
import type {QueryBuilder} from '@albert-io/json-api-framework/request/builder';
import LoadingIndicator from 'generic/LoadingIndicator.react';
import {callAction} from 'client/framework';
import {StandardModelV1} from 'resources/GeneratedModels/Standard/StandardModel.v1';
import {StandardChip} from 'client/QuestionTypes/common/V2/StandardChip.react';

import questionEditorActions from '../QuestionEditor/QuestionEditor.actions';
import './standard-search.scss';

interface Props {
  onDismiss: () => void;
  query: (searchString: string) => QueryBuilder;
  standards: List<StandardModelV1>;
}

const StandardSelectModal = ({onDismiss, query, standards}: Props) => {
  const [standardSearchValue, setStandardSearchValue] = useState('');
  const [results, setResults] = useState<List<StandardModelV1>>(List());

  const setResultsWithPreselectedStandards = useCallback(
    (newResults: List<StandardModelV1>) => {
      // swap out any standards that are selected with the search result
      // this is so we can easily use set.add and set.delete on the actual objects themselves
      const newOrLoadedStandards = newResults.map((result) => {
        const preselectedStandard = standards.find(
          (standard) => standard.getId() === result.getId()
        );
        return preselectedStandard || result;
      }) as List<StandardModelV1>;
      setResults(newOrLoadedStandards);
    },
    [standards]
  );

  useEffect(() => {
    query(standardSearchValue).getResourcePromise().then(setResultsWithPreselectedStandards);
  }, [standardSearchValue, query, setResultsWithPreselectedStandards]);

  const handleOnCheck = useCallback((e, selectedItems, currentItem) => {
    if (selectedItems.has(currentItem)) {
      selectedItems.delete(currentItem);
    } else {
      selectedItems.add(currentItem);
    }
    return {selectedItems};
  }, []);

  function handleApplyStandards(selectedItems) {
    callAction(questionEditorActions.UPDATE_QUESTION_STANDARDS, List(Array.from(selectedItems)));
    onDismiss();
  }

  const defaultSelectedItems = useMemo(() => {
    return new Set<StandardModelV1>(standards.toArray());
  }, [standards]);

  return (
    <Modal ariaLabel='Add Standards' handleClose={onDismiss}>
      {({CloseButtonWrapper, modalContentStyle}) => (
        <Dialogue inModal className={modalContentStyle} hideCloseBtn paddingLevel={0} size='l'>
          <Dialogue.Body>
            <LegacyFindAndApply
              defaultSelectedItems={defaultSelectedItems}
              customOnChange={debounce((e) => setStandardSearchValue(e.target.value), 750)}
              customOnClear={() => setStandardSearchValue('')}
              customOnCheck={handleOnCheck}
            >
              <LegacyFindAndApply.Header placeholder='Find Standards' />
              <LegacyFindAndApply.Body paddingLevel={0} className='standard-select-modal__body'>
                {({selectedItems, onCheck}) => {
                  if (!query(standardSearchValue).isResourcePopulated()) {
                    return <LoadingIndicator />;
                  }
                  return (
                    <ListGroup as='ul' border='none' className='u-pad-t_0 u-mar-t_0'>
                      {results.map((result) => (
                        <ListGroupItem as='li'>
                          <Checkbox
                            data-id={result.getId()}
                            id={result.getId()}
                            aria-hidden={false}
                            checked={selectedItems.has(result)}
                            onChange={(e) => onCheck(e, result)}
                          />
                          <Label htmlFor={result.getId()}>
                            <Text className='u-mar-l_1' bold>
                              {result.getTitle()}
                            </Text>
                          </Label>
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  );
                }}
              </LegacyFindAndApply.Body>
              <LegacyFindAndApply.Footer>
                {({selectedItems, onCheck}) => (
                  <>
                    <LegacyFindAndApply.SelectedItems className='selected-items__chips'>
                      {Array.from(selectedItems, (selectedItem: StandardModelV1) => (
                        <StandardChip
                          as={CheckboxChip}
                          defaultSelected
                          key={selectedItem.getId()}
                          icon='times'
                          color='brand'
                          size='small'
                          standard={selectedItem}
                          onChange={(e) => onCheck(e, selectedItem)}
                          className='u-mar-b_1'
                        />
                      ))}
                    </LegacyFindAndApply.SelectedItems>
                    <LegacyFindAndApply.BtnGroup>
                      <CloseButtonWrapper>
                        <Button color='secondary' variant='text'>
                          Cancel
                        </Button>
                      </CloseButtonWrapper>
                      <Button onClick={() => handleApplyStandards(selectedItems)}>Apply</Button>
                    </LegacyFindAndApply.BtnGroup>
                  </>
                )}
              </LegacyFindAndApply.Footer>
            </LegacyFindAndApply>
          </Dialogue.Body>
        </Dialogue>
      )}
    </Modal>
  );
};

export default StandardSelectModal;
