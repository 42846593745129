import constants from '../client/constants';

const difficulty = {
  [constants.QUESTION_IMPORT.DIFFICULTIES.Easy]: 'Easy',
  [constants.QUESTION_IMPORT.DIFFICULTIES.Moderate]: 'Moderate',
  [constants.QUESTION_IMPORT.DIFFICULTIES.Difficult]: 'Difficult'
};

const difficultyAbbr = {
  [constants.QUESTION_IMPORT.DIFFICULTIES.Easy]: 'E',
  [constants.QUESTION_IMPORT.DIFFICULTIES.Moderate]: 'M',
  [constants.QUESTION_IMPORT.DIFFICULTIES.Difficult]: 'D'
};

export default function getDifficultyValueFromInt(difficultyInt, isLong = false) {
  if (difficulty[difficultyInt]) {
    return isLong ? difficulty[difficultyInt] : difficulty[difficultyInt][0];
  }
  return '';
}

export function getDifficultyAbbrFromInt(difficultyInt, isLong = false) {
  if (difficultyAbbr[difficultyInt]) {
    return isLong ? difficultyAbbr[difficultyInt] : difficultyAbbr[difficultyInt][0];
  }
  return '';
}
