import {SchoolPersonnelModelV1} from 'resources/GeneratedModels/SchoolPersonnel/SchoolPersonnelModel.v1';

type Extensions = ThisType<SchoolPersonnelModelV1>;

const extensions: Extensions = {
  isSchoolAdministrator(): boolean {
    return this.getSchoolPersonnelType() === 'administrator';
  },

  isTeacher(): boolean {
    return this.getSchoolPersonnelType() === 'teacher';
  }
};

export default extensions;
