import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {Link} from 'react-router';
import qs from 'qs';
import classnames from 'classnames';
import {pushQueryParams} from 'client/history';
import AppStore from 'client/AppStore';
import './sg-tabs.scss';

/**
 * This <Tabs /> component allows you to create a tabbed interface where the tabs can
 * act as Links, buttons, or they may modify the current query params.
 *
 * The component must be given a content prop (type Immutable.List), made up of Immutable.Map
 * objects (tab). Each tab must have a label, and either a query, to, and/or an onClick property.
 * The tab behavior depends on whether it was provided a query, to, or onClick property.
 *
 * ~ Tab Types ~
 *
 * Link tab:
 * A link tab is a tab that is provided a 'to' property (e.g. {
 *   label: 'Link Tabbo',
 *   badgeContent: 420,
 *   to: '/foo/bar'
 * })
 * Link tabs will render as a <Link />, with the appropriate 'to' prop passed. The 'to' property must be
 * an absolute link (i.e. it must start with '/'). If it is not, it will be converted into an absolute link.
 * Optionally, you may pass the <Tabs /> component a 'baseUrl' prop that will be prepended to each tab's 'to'
 * property.
 *
 * Query tabs:
 * A query tab is a tab that is provided with a 'query' property (e.g. {
 *   label: 'Query Tabbo',
 *   badgeContent: 420,
 *   query: {
 *     fish: 'sunfish',
 *     isFunAndFlirty: null
 *   }
 * })
 * Query tabs will render as <button />, and will merge the current path's query params with its own. If you
 * wish to remove a query param, pass null to that property.
 *
 * Button tabs:
 * Button tabs are tabs that are _not_ provided a 'link' or 'query' property,
 * and are passed an 'onClick' property (e.g. {
 *   label: 'Button Tabbo',
 *   badgeContent: 420,
 *   defaultChecked: true,
 *   onClick: (tab) => console.log(`I am the button tabbo and my label is ${tab.get('label')}`)
 * })
 * Note that the tab is passed to the callback function, so feel free to add any properties onto it that
 * you may need to access later.
 *
 * Because button tabs identify whether or not they are active based on which one was last clicked, if you want
 * to specify which tab should appear active by default, give your tab a `defaultChecked: true` key/value pair.
 *
 * ~ Additional Props ~
 * onTabClick: If you're going to pass simliar 'onClick' functions to all your tabs, you could instead pass a single
 * function to the component as a prop. When you click a tab, the onTabClick function will be called with the tab that
 * was clicked as its argument.
 * className: Class applied to the outermost element.
 *
 * ~ Notes ~
 * isActiveFunc: If you want to manually determine whether or not a given tab is active, give you tab a 'getActiveFunc'
 * property, where the value is a function that will be called with your tab as its argument. The function should return
 * true or false, which should match whether or not the tab is active.
 * badgeContent: Many designs have tabs that have a little badge next to the tab's label. This badge usually
 * contains a number. If you need to display this badge, give your tab a 'badgeContent' property. badgeContent may be a
 * value or a funciton. If it is a function, the function should return the value you'd like to display. If this
 * property is ommited, a badge will not render.
 * children: If your <Tabs /> component does not have children, it will render as a free-floating set of tabs.
 * If it does have children, the children will be given a white background, rouded corners, and they tabs will be
 * attached to them.
 */

export default class Tabs extends React.Component {
  static propTypes = {
    content: ImmutablePropTypes.list.isRequired,
    onTabClick: PropTypes.func,
    baseUrl: PropTypes.string,
    className: PropTypes.string
  };

  state = {
    lastClickedIndex: null
  };

  componentDidUpdate(prevProps) {
    /**
     * It is possible for Tabs to receive new props without requiring a new store
     * instance. In that case we must update the store to reflect any incoming defaultChecked
     * rules from the content prop's newest set of tab data.
     */
    if (prevProps.content !== this.props.content) {
      this.updateLastClickedIndex(
        this.props.content.findIndex((tab) => tab.get('defaultChecked')) || null
      );
    }
  }

  isClient() {
    return process.env.IS_BROWSER;
  }

  isActiveTab(tab, index) {
    // history.getCurrentLocation is not available on the server, so if we're there, just bail
    if (!process.env.IS_BROWSER) {
      return false;
    }
    const location = AppStore.routerProps.location;
    if (tab.has('isActiveFunc')) {
      return tab.get('isActiveFunc')(true);
    }
    if (tab.has('to')) {
      return location.pathname.endsWith(tab.get('to'));
    }
    if (tab.has('query')) {
      const currentSearch = qs.parse(location.search.slice(1));
      return tab.get('query').reduce((r, val, key) => {
        if (!r) {
          return false;
        }
        if (val === null) {
          return !currentSearch.hasOwnProperty(key);
        }
        return currentSearch[key] === val;
      }, true);
    }
    const lastClickedIndex = this.state.lastClickedIndex;
    if (lastClickedIndex === null) {
      return tab.get('defaultChecked') === true;
    }
    return lastClickedIndex !== null && lastClickedIndex === index;
  }

  updateLastClickedIndex = (index) => {
    this.setState({
      lastClickedIndex: index
    });
  };

  makeBadge() {
    const badgeContent = this.props.tab.get('badgeContent');
    const value = typeof badgeContent === 'function' ? badgeContent() : badgeContent;
    if (value === null || value === undefined) {
      return null;
    }
    return <span className='sg-tab__badge'>{value}</span>;
  }

  makeTab = (tab, i) => {
    const badgeContent = tab.get('badgeContent');
    const badgeValue = typeof badgeContent === 'function' ? badgeContent() : badgeContent;
    return (
      <Tab
        key={i}
        index={i}
        tab={tab}
        onTabClick={this.props.onTabClick}
        baseUrl={this.props.baseUrl}
        isActiveTab={this.isActiveTab(tab, i)}
        updateLastClickedIndex={this.updateLastClickedIndex}
        badgeContent={badgeValue}
      />
    );
  };

  render() {
    const tabElsClasses = classnames('sg-tabs', {
      'sg-tabs--with-nested-content': this.props.children
    });
    const tabEls = <div className={tabElsClasses}>{this.props.content.map(this.makeTab)}</div>;
    const nestedContent = this.props.children ? (
      <div className='sg-tabs-nested-content'>{this.props.children}</div>
    ) : null;
    return (
      <div className={classnames('sg-tabs-wrapper', this.props.className)}>
        {tabEls}
        {nestedContent}
      </div>
    );
  }
}

class Tab extends React.PureComponent {
  static propTypes = {
    tab: ImmutablePropTypes.map,
    onTabClick: PropTypes.func,
    baseUrl: PropTypes.string,
    isActiveTab: PropTypes.bool,
    updateLastClickedIndex: PropTypes.func,
    index: PropTypes.number
  };

  handleQueryChange() {
    const tabQuery = this.props.tab.get('query').toJS();
    pushQueryParams(tabQuery);
  }

  makeLinkPath(tabUrl) {
    const baseUrl = this.props.baseUrl || '';
    const trimmedBaseUrl = baseUrl.replace(/\/$/g, '');
    const trimmedTabUrl = tabUrl.replace(/^\/|\/$/g, '');
    return `${trimmedBaseUrl}/${trimmedTabUrl}`;
  }

  makeBadge() {
    const badgeContent = this.props.badgeContent;
    if (badgeContent === null || badgeContent === undefined) {
      return null;
    }
    return <span className='sg-tab__badge'>{badgeContent}</span>;
  }

  handleClick = () => {
    const tab = this.props.tab;
    if (this.props.onTabClick) {
      this.props.onTabClick(tab);
    }
    if (tab.has('onClick')) {
      tab.get('onClick')(tab);
    }
    if (tab.has('query')) {
      this.handleQueryChange();
    }
    this.props.updateLastClickedIndex(this.props.index);
  };

  render() {
    const tab = this.props.tab;
    const maybeProps = {};
    const isLink = tab.has('to');
    if (isLink) {
      maybeProps.to = this.makeLinkPath(tab.get('to'));
    }
    const shouldHandleClickEvent =
      this.props.onTabClick || ['onClick', 'query'].some((prop) => tab.has(prop));
    if (shouldHandleClickEvent) {
      maybeProps.onClick = this.handleClick;
    }
    const ElType = isLink ? Link : ButtonEl;
    const classNames = classnames('sg-tab', {
      unbutton: !isLink,
      'sg-tab--active': this.props.isActiveTab
    });
    return (
      <ElType {...maybeProps} className={classNames}>
        <span className='sg-tab__label'>{this.props.tab.get('label')}</span>
        {this.makeBadge()}
      </ElType>
    );
  }
}

function ButtonEl(props) {
  return <button {...props} />;
}
