import React from 'react';
import PropTypes from 'prop-types';
import {List} from 'immutable';
import {debounce} from 'lodash';
import classnames from 'classnames';
import {callTargetedAction, setUpStore} from 'client/framework';
import LoadingIndicator from 'generic/LoadingIndicator.react';
import TextInput from 'generic/Forms/TextInput/TextInput';
import makeRef from 'lib/makeRef';

import DropdownStore from './SearchDropdown.store';
import SearchDropdownActions from './SearchDropdown.actions';
import './sg-dropdown.scss';

// TODO: Docs

export const searchDropdownProps = {
  areResultsLoading: PropTypes.bool,
  bottomMessage: PropTypes.node,
  className: PropTypes.string,
  customClearOption: PropTypes.any,
  defaultOption: PropTypes.any,
  disableClear: PropTypes.bool,
  disabled: PropTypes.bool,
  displayName: PropTypes.string,
  emptyResultsComponent: PropTypes.node,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
  filterFunc: PropTypes.func,
  handleOptionChange: PropTypes.func,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onEmptyResultsFunc: PropTypes.func,
  onFocus: PropTypes.func,
  openOnFocus: PropTypes.bool,
  options: PropTypes.instanceOf(List),
  placeholder: PropTypes.string,
  renderFunc: PropTypes.func,
  required: PropTypes.bool,
  resetOnUnmount: PropTypes.bool,
  storeName: PropTypes.string.isRequired
};

export default class SearchDropdown extends React.Component {
  static propTypes = searchDropdownProps;

  static defaultProps = {
    customClearOption: null,
    disableClear: false,
    handleOptionChange: () => {},
    onBlur: () => {},
    onFocus: () => {},
    openOnFocus: false,
    options: List()
  };

  constructor(props) {
    super(props);
    this.wrapperNode = null;
    this.debouncedSearchStringSetter = debounce(this.setSearchString, 500);
  }

  UNSAFE_componentWillMount() {
    this.setDefaultOption();
  }

  componentDidMount() {
    global.document.addEventListener('click', this.handleClickOutsideComponent);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    const nextStore = this.getStore(nextProps.storeName);

    if (this.props.openOnFocus !== nextStore.isOpenOnFocus()) {
      callTargetedAction({
        name: SearchDropdownActions.SET_IS_OPEN_ON_FOCUS,
        targetStore: nextStore.getName(),
        payload: this.props.openOnFocus
      });
    }
  }

  componentDidUpdate(prevProps) {
    const {defaultOption} = this.props;
    if (defaultOption !== prevProps.defaultOption) {
      this.setDefaultOption();
    }
  }

  componentWillUnmount() {
    if (this.props.resetOnUnmount) {
      callTargetedAction({
        name: SearchDropdownActions.RESET_STORE,
        targetStore: this.getStore().getName()
      });
    }

    global.document.removeEventListener('click', this.handleClickOutsideComponent);
  }

  setDefaultOption = () => {
    const {defaultOption, onBlur} = this.props;
    const store = this.getStore();
    if (!store.getSelectedOption()) {
      const payload = defaultOption || null;
      callTargetedAction({
        name: SearchDropdownActions.SET_SELECTED_OPTION,
        targetStore: store.getName(),
        payload
      });
      // If a selection has been made, we can fire a blur
      onBlur();
    }
  };

  getStore(storeName = this.props.storeName) {
    return setUpStore(DropdownStore, storeName);
  }

  handleClickOutsideComponent = (e) => {
    if (this.getStore().isDropdownOpen() && !this.wrapperNode.contains(e.target)) {
      this.dismissDropdown();
    }
  };

  dismissDropdown = () => {
    const store = this.getStore();
    this.clearSearchString();
    // fire blur, but only when dropdown is open.
    if (store.isDropdownOpen()) {
      this.props.onBlur();
    }
    callTargetedAction({
      name: SearchDropdownActions.SET_IS_DROPDOWN_OPEN,
      targetStore: store.getName(),
      payload: false
    });
  };

  setSearchString = (searchString) => {
    callTargetedAction({
      name: SearchDropdownActions.SET_SEARCH_STRING,
      targetStore: this.getStore().getName(),
      payload: searchString
    });
  };

  handleInputChange = (e) => {
    this.debouncedSearchStringSetter(e.target.value);
  };

  clearSearchString = () => {
    this.setSearchString('');
  };

  handleFocus = () => {
    this.props.onFocus();
    if (!this.props.openOnFocus) {
      return;
    }
    callTargetedAction({
      name: SearchDropdownActions.SET_IS_DROPDOWN_OPEN,
      targetStore: this.getStore().getName(),
      payload: true
    });
  };

  getOptions() {
    const {options} = this.props;
    const {filterFunc} = this.props;
    if (!filterFunc) {
      return options;
    }
    const searchString = this.getStore().getSearchString();
    return options ? options.filter((option) => filterFunc(option, searchString)) : null;
  }

  handleClearOptionClick = () => {
    this.props.handleOptionChange(this.props.customClearOption);
    callTargetedAction({
      name: SearchDropdownActions.RESET_STORE,
      targetStore: this.getStore().getName()
    });
  };

  renderClearButton = () => {
    return !this.props.disableClear && this.getStore().getSelectedOption() ? (
      <button
        className='sg-dropdown__clear-button unbutton fa fa-times'
        onClick={this.handleClearOptionClick}
        type='button'
      />
    ) : null;
  };

  makeRef = makeRef.bind(this);

  render() {
    const store = this.getStore();
    const selectedOption = store.getSelectedOption();
    const inputValue = selectedOption ? selectedOption.get(this.props.displayName) : '';

    return (
      <div
        ref={this.makeRef}
        data-ref-name='wrapperNode'
        className={classnames('sg-dropdown', this.props.className)}
      >
        <TextInput
          disabled={this.props.disabled}
          label={this.props.label}
          value={inputValue}
          onChange={this.handleInputChange}
          onKeyDown={this.handleKeyDown}
          updateInputOnValueMismatch={!store.isDropdownOpen()}
          error={this.props.error}
          errorMessage={this.props.errorMessage}
          onBlur={this.props.onBlur}
          onFocus={this.handleFocus}
          placeholder={this.props.placeholder}
          required={this.props.required}
        />
        {this.renderClearButton()}
        <ResultsList
          displayName={this.props.displayName}
          store={store}
          options={this.getOptions()}
          renderFunc={this.props.renderFunc}
          handleOptionChange={this.props.handleOptionChange}
          onBlur={this.props.onBlur}
          bottomMessage={this.props.bottomMessage}
          areResultsLoading={this.props.areResultsLoading}
          filterFunc={this.props.filterFunc}
          placeholder={this.props.placeholder}
          dismissDropdown={this.dismissDropdown}
          emptyResultsComponent={this.props.emptyResultsComponent}
          onEmptyResultsFunc={this.props.onEmptyResultsFunc}
        />
      </div>
    );
  }
}

class ResultsList extends React.Component {
  static propTypes = {
    store: PropTypes.instanceOf(DropdownStore),
    options: PropTypes.instanceOf(List),
    renderFunc: PropTypes.func,
    displayName: PropTypes.string,
    handleOptionChange: PropTypes.func,
    onBlur: PropTypes.func,
    bottomMessage: PropTypes.node,
    areResultsLoading: PropTypes.bool,
    dismissDropdown: PropTypes.func,
    emptyResultsComponent: PropTypes.node,
    onEmptyResultsFunc: PropTypes.func
  };

  static defaultProps = {
    emptyResultsComponent: 'No results found',
    options: List(),
    onEmptyResultsFunc: () => {}
  };

  setSelectedOption = (option) => {
    callTargetedAction({
      name: SearchDropdownActions.SET_SELECTED_OPTION,
      targetStore: this.props.store.getName(),
      payload: option
    });
    callTargetedAction({
      name: SearchDropdownActions.SET_SEARCH_STRING,
      targetStore: this.props.store.getName(),
      payload: ''
    });
    this.props.handleOptionChange(option);
    this.props.onBlur();
  };

  makeOptions = (option, i) => {
    const content = this.props.renderFunc
      ? this.props.renderFunc(option, i)
      : option.get(this.props.displayName);
    return (
      <Result
        key={i}
        index={i}
        option={option}
        onOptionClick={this.setSelectedOption}
        dismissDropdown={this.props.dismissDropdown}
      >
        {content}
      </Result>
    );
  };

  getBottomMessage() {
    return this.props.bottomMessage ? (
      <div className='sg-dropdown__bottom-message'>{this.props.bottomMessage}</div>
    ) : null;
  }

  render() {
    if (this.props.areResultsLoading) {
      return (
        <div className='sg-dropdown__dropdown-list-wrapper'>
          <LoadingIndicator inline className='sg-dropdown__dropdown-list-loader' />
        </div>
      );
    }
    const {options} = this.props;
    if (!this.props.store.isDropdownOpen() || !List.isList(options)) {
      return null;
    }
    if (options.isEmpty()) {
      return (
        <EmptyResultsComponent
          emptyResultsComponent={this.props.emptyResultsComponent}
          onEmptyResultsFunc={this.props.onEmptyResultsFunc}
        />
      );
    }
    return (
      <div className='sg-dropdown__dropdown-list-wrapper'>
        <ul className='sg-dropdown__dropdown-list'>{options.map(this.makeOptions)}</ul>
        {this.getBottomMessage()}
      </div>
    );
  }
}

class Result extends React.Component {
  static propTypes = {
    onOptionClick: PropTypes.func,
    option: PropTypes.any,
    index: PropTypes.number,
    dismissDropdown: PropTypes.func
  };

  static defaultProps = {
    onOptionClick: () => {}
  };

  handleOptionClick = () => {
    this.props.onOptionClick(this.props.option, this.props.index);
  };

  handleKeyDown = (e) => {
    if (['Enter', ' '].includes(e.key)) {
      this.handleOptionClick();
    }
    if (e.key === 'Escape') {
      this.props.dismissDropdown();
    }
  };

  render() {
    return (
      <li
        onClick={this.handleOptionClick}
        onKeyDown={this.handleKeyDown}
        className='sg-dropdown__dropdown'
        tabIndex='0'
      >
        {this.props.children}
      </li>
    );
  }
}

class EmptyResultsComponent extends React.Component {
  static propTypes = {
    emptyResultsComponent: PropTypes.node,
    onEmptyResultsFunc: PropTypes.func
  };

  componentDidMount() {
    this.props.onEmptyResultsFunc();
  }

  render() {
    return (
      <div className='sg-dropdown__dropdown-list-wrapper'>
        <div className='sg-dropdown__dropdown-list-no-results'>
          {this.props.emptyResultsComponent}
        </div>
      </div>
    );
  }
}
