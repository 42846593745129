/**
 * # NoDataMsg
 *
 * A component that displays a message when there is no data. Has optional children `Heading`, `Body`,`Button`, and `Container`.
 *
 * ## Props
 *
 * ### ContainerProps (extends CardProps<'div'>)
 *
 * - `border?: 'none' | 'regular' | 'darker'`: The border style of the container. Default is 'none'.
 * - `centered?: boolean`: Whether to center the content of the container. Default is false.
 * - `children: React.ReactNode`: The content to be displayed inside the container.
 * - `className?: string`: Additional CSS class name for the container.
 * - `paddingLevel?: number | null`: The padding level of the container. Default is null.
 * - `roundness?: 'small' | 'medium' | 'large' | 'pill'`: The roundness style of the container.
 *
 * ### HeadingProps (extends AtomicHeadingProps)
 *
 * - `centered?: boolean`: Whether to center the heading. Default is false.
 * - `children: React.ReactNode`: The content to be displayed inside the heading.
 * - `className?: string`: Additional CSS class name for the heading.
 *
 * ### BodyProps (extends TextProps<'div'>)
 *
 * - `centered?: boolean`: Whether to center the body text. Default is false.
 * - `children?: React.ReactNode`: The content to be displayed inside the body.
 * - `className?: string`: Additional CSS class name for the body.
 *
 * ### ButtonProps<C extends React.ElementType = 'button'> (extends ButtonProps)
 *
 * - `children: React.ReactNode`: The content to be displayed inside the button.
 * - `className?: string`: Additional CSS class name for the button.
 * - `color?: string`: The color of the button.
 *
 * ## Usage
 *
 * ```tsx
 * import NoDataMsg from './NoDataMsg.react';
 *
 * const ExampleComponent = () => {
 *   return (
 *     <NoDataMsg.Container>
 *       <NoDataMsg.Heading>Oops! No data found.</NoDataMsg.Heading>
 *       <NoDataMsg.Body>Please try again later.</NoDataMsg.Body>
 *       <NoDataMsg.Button color="primary">Retry</NoDataMsg.Button>
 *     </NoDataMsg.Container>
 *   );
 * };
 * ```
 */

import React from 'react';
import classnames from 'classnames';

import Button, {ButtonProps} from '../../atoms/Button/Button.react';
import Card, {Props as CardProps} from '../../atoms/Card/Card.react';
import Heading, {Props as AtomicHeadingProps} from '../../atoms/Heading/Heading.react';
import Text, {TextProps} from '../../atoms/Text/Text.react';
import './no-data-msg.scss';

interface ContainerProps
  extends OmitChildren<CardProps<'div'>>,
    PropsWithChildrenRequired,
    PropsWithClassNameOptional {
  border?: 'none' | 'regular' | 'darker';
  centered?: boolean;
  paddingLevel?: number | null;
  roundness?: 'small' | 'medium' | 'large' | 'pill';
}

interface HeadingProps
  extends AtomicHeadingProps,
    PropsWithChildrenRequired,
    PropsWithClassNameOptional {
  centered?: boolean;
}

interface BodyProps
  extends TextProps<'div'>,
    PropsWithChildrenOptional,
    PropsWithClassNameOptional {
  centered?: boolean;
}

const NoDataMsg = {
  Container({
    border = 'none',
    centered = false,
    children,
    className,
    paddingLevel,
    roundness,
    ...rest
  }: ContainerProps) {
    return (
      <Card
        border={border}
        className={classnames('no-data-msg__container', className, {
          'no-data-msg__container--centered u-max-width_text': centered
        })}
        paddingLevel={paddingLevel}
        roundness={roundness}
        {...rest}
      >
        {React.Children.toArray(children).map(
          (child) =>
            React.isValidElement(child) &&
            React.cloneElement(child, {centered} as HeadingProps | BodyProps)
        )}
      </Card>
    );
  },

  Heading({centered, children, className, ...rest}: HeadingProps) {
    return (
      <Heading
        className={classnames('no-data-msg__heading u-mar-b_2 u-max-width_text', className, {
          'u-text-align_center': centered
        })}
        size='m'
        {...rest}
      >
        {children}
      </Heading>
    );
  },

  Body({centered, children, className, ...rest}: BodyProps) {
    return (
      <Text
        as='div'
        className={classnames('no-data-msg__body u-max-width_text', className, {
          'u-text-align_center': centered
        })}
        color='secondary'
        size='l'
        {...rest}
      >
        {children}
      </Text>
    );
  },

  Button<C extends React.ElementType = 'button'>({
    children,
    className,
    color,
    ...rest
  }: ButtonProps<C>) {
    return (
      <Button
        className={classnames('no-data-msg__btn u-mar-t_5', className)}
        color={color}
        {...rest}
      >
        {children}
      </Button>
    );
  }
};

export default NoDataMsg;
