// @flow
import * as React from 'react';
import {v4 as uuid} from 'uuid';
import PropTypes from 'prop-types';
import {Map, List} from 'immutable';
import sessionStore from 'client/Session/SessionStore';
import classroomsStore from 'client/Classrooms/ClassroomsStore';
import SimpleDropdown from 'generic/SimpleDropdown/SimpleDropdown.react';

import appStore from 'client/AppStore';
import LoadingIndicator from 'generic/LoadingIndicator.react';
import {Heading} from '@albert-io/atomic';
import './left-content.scss';

import type {ClassroomModelV1} from 'resources/augmented/Classroom/ClassroomModel.v1';

import {archivedClassroomsPath} from '../../ClassroomsList/TeacherClassroomsList/ArchivedClassroomsList.react';

export default class LeftContent extends React.Component {
  static propTypes = {
    activeItemId: PropTypes.string
  };

  constructor() {
    super();
    this.id = uuid();
  }

  render(): React.Element {
    const isArchivedAssignmentsListView =
      appStore.routerProps.location.pathname === archivedClassroomsPath;
    if (!this.props.activeItemId && !isArchivedAssignmentsListView) {
      return (
        <Heading as='h1' className='classrooms-subheader__title' size='xs'>
          My Classes
        </Heading>
      );
    }

    const classrooms = sessionStore.hasTeacherAccess()
      ? classroomsStore.getTeacherActiveClassrooms()
      : classroomsStore.getActiveStudentClassrooms();

    const areClassroomsPopulated = sessionStore.hasTeacherAccess()
      ? classroomsStore.getTeacherActiveClassroomsQuery().isResourcePopulated()
      : classroomsStore.areActiveStudentClassroomsReady();

    if (!areClassroomsPopulated) {
      return <LoadingIndicator type='inline' />;
    }

    let options = classrooms.reduce(
      (reducer, classroom: ClassroomModelV1 | Map<string, *>): List<Map<string, *>> => {
        let to = `/classes/${classroom.get('id')}`;
        /**
         * Append the default route based on the user type.
         */
        to += `/${sessionStore.isTeacher() ? 'assignments' : 'my-assignments'}`;
        return reducer.push(
          new Map({
            id: classroom.get('id'),
            to,
            name: classroom.get('name')
          })
        );
      },
      new List()
    );

    let dropdownClass = 'classrooms-secondary-header-left-content';
    if (sessionStore.isTeacher() && !classroomsStore.getTeacherArchivedClassrooms().isEmpty()) {
      options = options.push(
        new Map({
          id: archivedClassroomsPath,
          to: archivedClassroomsPath,
          name: 'Archived classes'
        })
      );

      dropdownClass += ' classrooms-secondary-header-left-content--has-archived-classes';
    }

    return (
      <SimpleDropdown
        noInputStyles
        destroyStoreOnUnmount
        className={dropdownClass}
        storeName={`horizontalNavigationComponentLeftContentDropdownStore${this.id}`}
        options={options}
        activeItemId={this.props.activeItemId}
      />
    );
  }
}
