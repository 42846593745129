import {fromJS} from 'immutable';

/* eslint-disable max-len, import/prefer-default-export */
export const navLinks = fromJS([
  {
    name: 'Explore',
    dropdown: [
      {
        name: 'Testimonials',
        hardLink: true,
        route: '/solutions/testimonials'
      },
      {
        name: 'For Teachers',
        hardLink: true,
        route: '/solutions-teachers'
      },
      {
        name: 'For Admins',
        hardLink: true,
        route: '/solutions-admins'
      },
      {
        name: 'Results',
        hardLink: true,
        route: '/results'
      },
      {
        name: 'Case Studies',
        hardLink: true,
        route: '/case-studies'
      },
      {
        name: 'Professional Development',
        hardLink: true,
        route: '/solutions/professional-development'
      },
      {
        name: 'Try Albert',
        hardLink: true,
        route: '/try-albert'
      }
    ]
  },
  {
    name: 'Solutions',
    dropdown: {
      leftContent: {
        name: 'By Grade Level',
        links: [
          {
            name: 'Middle School',
            color: '#2271a3',
            description:
              'Prepare your students for success with meticulously researched ELA, math, and science practice for grades 5-8.',
            route: '/solutions-middle-school',
            hardLink: true
          },
          {
            name: 'High School',
            color: '#2271a3',
            description:
              "Replace your patchwork of digital curriculum and bring the world's most comprehensive practice resources to all subjects and grade levels.",
            route: '/solutions-high-school',
            hardLink: true
          }
        ]
      },
      rightContent: {
        name: 'By Content Area',
        links: [
          {
            name: 'ELA',
            color: '#ab5fab',
            description:
              'Our ELA courses build the skills that students need to become engaged readers, strong writers, and clear thinkers.',
            route: '/solutions-ela',
            hardLink: true
          },
          {
            name: 'Math',
            color: '#5eafba',
            description:
              'Skill, conceptual, and application questions combine to build authentic and lasting mastery of math concepts.',
            route: '/solutions-math',
            hardLink: true
          },
          {
            name: 'Science',
            color: '#9284ce',
            description:
              'Engage students in scientific inquiry to build skills and content knowledge aligned to NGSS and traditional standards.',
            route: '/solutions-science',
            hardLink: true
          },
          {
            name: 'Advanced Placement',
            color: '#2271a3',
            description:
              'With the largest library of standards-aligned and fully explained questions in the world, Albert is the leader in Advanced Placement®.',
            route: '/solutions-ap',
            hardLink: true
          },
          {
            name: 'College Entrance',
            color: '#2271a3',
            description:
              'Practice with confidence for the ACT® and SAT® knowing Albert has questions aligned to all of the most recent concepts and standards.',
            route: '/solutions-college-entrance',
            hardLink: true
          }
        ]
      }
    }
  },
  {
    name: 'Library'
  },
  {
    name: 'Pricing',
    hardLink: true,
    route: '/pricing-schools'
  }
]);
/* eslint-disable max-len */
