/**
 * # Curve
 *
 * A React component that renders a curved container with customizable depth, position, and padding level.
 *
 * ## Props
 * - `backgroundColor` (string): The background color of the curve.
 * - `className` (string): Additional CSS class name for the curve container.
 * - `depth` (string): The depth of the curve. Can be one of 'xl', 'l', 'm', 's', 'xs'. Default is 'm'.
 * - `paddingLevel` (number): The padding level of the curve. Default is 5.
 * - `position` (string): The position of the curve. Can be one of 'top', 'right', 'bottom', 'left'. Default is 'bottom'.
 * - `children` (node): The content to be rendered inside the curve container.
 *
 * ## Usage
 *
 * ```jsx
 * import Curve from './Curve.react';
 *
 * const MyComponent = () => {
 *   return (
 *     <Curve
 *       backgroundColor="#f0f0f0"
 *       className="my-curve"
 *       depth="l"
 *       paddingLevel={3}
 *       position="right"
 *     >
 *       <p>This is the content inside the curve.</p>
 *     </Curve>
 *   );
 * };
 * ```
 */

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import makeConstants from 'lib/makeConstants';

import {paddingLevelPropType} from '../../utils/paddingLevelProp';
import './curve.scss';

export const depthOptions = makeConstants('xl', 'l', 'm', 's', 'xs');
export const positionOptions = makeConstants('top', 'right', 'bottom', 'left');

const Curve = ({backgroundColor, children, className, depth, position, paddingLevel, ...rest}) => {
  return (
    <div
      className={classnames(
        'a-curve',
        `a-curve--pos-${position}`,
        `a-curve--depth-${depth}`,
        `u-pad_${paddingLevel}`,
        className
      )}
      {...rest}
    >
      <div
        className={classnames('a-curve__bg', `a-curve__bg--pos-${position}`)}
        style={backgroundColor ? {color: backgroundColor} : null}
      />
      {children}
    </div>
  );
};

Curve.propTypes = {
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  depth: PropTypes.oneOf(Object.values(depthOptions)),
  paddingLevel: paddingLevelPropType,
  position: PropTypes.oneOf(Object.values(positionOptions)),
  children: PropTypes.node
};

Curve.defaultProps = {
  depth: 'm',
  paddingLevel: 5,
  position: 'bottom'
};

export default Curve;
