// @flow
import React from 'react';
import {List, OrderedMap} from 'immutable';
import {type UserModelV2} from 'resources/augmented/User/UserModel.v2';
import {SelectAccountType} from 'client/Onboarding/Steps/SelectAccountType/SelectAccountType.react';
import CoppaAgeScreener from 'client/Onboarding/Steps/CoppaAgeScreener/CoppaAgeScreener.react';
import UpdateAccount from 'client/Onboarding/Steps/UpdateAccount/UpdateAccount.react';
import SelectConsentOption from 'client/Onboarding/Steps/ParentalConsent/SelectConsentOption/SelectConsentOption.react';
import ContentPersonalization from 'client/AccountSettings/ContentPersonalization/ContentPersonalization.react';

const ACCOUNT_TYPE_SELECTION = 'ACCOUNT_TYPE_SELECTION';
const COPPA_AGE_SCREENER = 'COPPA_AGE_SCREENER';
const PARENTAL_CONSENT = 'PARENTAL_CONSENT';
const ACCOUNT_COMPLETION = 'ACCOUNT_COMPLETION';
const CONTENT_PERSONALIZATION = 'CONTENT_PERSONALIZATION';

const SIGN_UP_ONBOARDING_STEPS = OrderedMap({
  [ACCOUNT_TYPE_SELECTION]: (props) => {
    return <SelectAccountType {...props} />;
  },
  [COPPA_AGE_SCREENER]: (props) => {
    return <CoppaAgeScreener {...props} />;
  },
  /**
   * @param props
   * @todo: the `PARENTAL_CONSENT` step's component has been replaced with the
   * `SelectConsentOption` component - the `ParentalConsent` component is now
   * a smaller part of the `SelectConsentOption` component. The user now
   * has several options by which they can be granted consent to the website. See
   * the note in `SelectConsentOption`.
   */
  [PARENTAL_CONSENT]: (props) => {
    return <SelectConsentOption {...props} />;
  },
  [ACCOUNT_COMPLETION]: (props) => {
    return <UpdateAccount {...props} />;
  },
  [CONTENT_PERSONALIZATION]: (props) => {
    return <ContentPersonalization {...props} isOnboarding className='u-pad_6' />;
  }
});

const STUDENT_SIGN_UP_ONBOARDING_STEPS = SIGN_UP_ONBOARDING_STEPS.butLast();

const ONBOARDING_STEPS = OrderedMap({
  SIGN_UP_ONBOARDING_STEPS
}).flatten();

const STUDENT_ONBOARDING_STEPS = OrderedMap({
  STUDENT_SIGN_UP_ONBOARDING_STEPS
}).flatten();

export function getActiveOnboardingComponentForUser(user: UserModelV2): ?React.Component<*> {
  const step = getActiveOnboardingStepForUser(user);

  if (!step) {
    return undefined;
  }

  return ONBOARDING_STEPS.get(step);
}

export function getActiveOnboardingStepForUser(user: UserModelV2): ?string {
  return getOnboardingSteps().find((step) => {
    return !hasCompletedOnboardingStep(user, step);
  });
}

export function getOnboardingSteps(isStudent): List<string> {
  const steps = isStudent ? STUDENT_ONBOARDING_STEPS : ONBOARDING_STEPS;
  return steps.keySeq().toList();
}

export function hasCompletedOnboarding(user: UserModelV2): boolean {
  const userSteps = user.getOnboardingSteps();
  return !userSteps.isEmpty() && userSteps.isSuperset(getOnboardingSteps(user.isStudent()));
}

export function hasCompletedOnboardingStep(user: UserModelV2, step: string): boolean {
  return user.getOnboardingSteps().includes(step);
}

export function hasCompletedAccountTypeSelection(user: UserModelV2): boolean {
  return hasCompletedOnboardingStep(user, ACCOUNT_TYPE_SELECTION);
}

export function completeAccountTypeSelection(user: UserModelV2): UserModelV2 {
  return completeOnboardingStep(user, ACCOUNT_TYPE_SELECTION);
}

export function hasCompletedCoppaAgeScreener(user: UserModelV2): boolean {
  return hasCompletedOnboardingStep(user, COPPA_AGE_SCREENER);
}

export function completeCoppaAgeScreener(user: UserModelV2): UserModelV2 {
  return completeOnboardingStep(user, COPPA_AGE_SCREENER);
}

export function hasCompletedParentalConsent(user: UserModelV2): boolean {
  return hasCompletedOnboardingStep(user, PARENTAL_CONSENT);
}

export function completeParentalConsent(user: UserModelV2): UserModelV2 {
  return completeOnboardingStep(user, PARENTAL_CONSENT);
}

export function hasCompletedAccountCompletion(user: UserModelV2): boolean {
  return hasCompletedOnboardingStep(user, ACCOUNT_COMPLETION);
}

export function completeAccountCompletion(user: UserModelV2): UserModelV2 {
  return completeOnboardingStep(user, ACCOUNT_COMPLETION);
}

export function completeContentPersonalization(user: UserModelV2): UserModelV2 {
  return completeOnboardingStep(user, CONTENT_PERSONALIZATION);
}

export function hasCompletedContentPersonalization(user: UserModelV2): boolean {
  return hasCompletedOnboardingStep(user, CONTENT_PERSONALIZATION);
}

export function completeOnboardingStep(user: UserModelV2, step: string): UserModelV2 {
  const userSteps = user.getOnboardingSteps();

  if (!ONBOARDING_STEPS.has(step)) {
    throw new Error(`Invalid onboarding step, ${step} is not found in the ONBOARDING_STEPS map`);
  }
  if (userSteps.includes(step)) {
    throw new Error(
      `Onboarding step already completed, ${step} has already been completed for ${user.getId()}`
    );
  }

  return user.setOnboardingSteps(userSteps.push(step));
}

/**
 * @param user
 * @todo: Make test for this once test issues with Models are resolved
 */
export function isUserOnFinalStep(user: UserModelV2): boolean {
  const finalCompletedStep = user.getOnboardingSteps().last();
  const finalOnboardingStep = getOnboardingSteps().pop().last();

  // compares the completed step list to the full onboarding step list minus the last step
  return finalCompletedStep === finalOnboardingStep;
}
