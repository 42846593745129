import {fromJS, List, Map, OrderedSet} from 'immutable';
import templateListActions from './TemplateList.actions';

import {
  addQuestionSetsToAssignment,
  createAssignmentTemplate
} from 'framework/MandarkAPI/Assignments/Assignments';
import {Store} from 'client/framework';
import vanillaIEQuestionsListStore from 'client/InteractionEngineV2/IESessionTypes/VanillaIE/VanillaIEQuestionsList/VanillaIEQuestionsList.store';
import {makeAssignmentQuery} from 'resources/Assignment/Assignment.queries';
import {mandarkEndpoint} from '@albert-io/json-api-framework/request/builder/legacy';
import {getResourcePromise, invalidatePartialInterest} from 'resources/mandark.resource';
import {AssignmentModelV2} from 'resources/Assignment/V2/Assignment.model';
import masqueradeStore from 'client/generic/Masquerade/Masquerade.store';
import {canCreateTemplateForSubject} from 'lib/UserAccessUtil';
import {isSearchMode} from 'client/components/AdvancedSearch/AdvancedSearch.utils';

class TemplateListStore extends Store {
  constructor(name) {
    super(name);
    this.initialData = fromJS({
      newTemplateName: '',
      selectedTemplates: [], // This is converted to an Immutable.Set
      selectedQuestionSets: [], // This is converted an Immutable.OrderedSet
      questionSets: [], // This is converted to an Immutable.OrderedSet
      isCreateTemplateForm: false,
      error: null,
      userInputData: [
        {
          name: 'newTemplateName',
          value: '',
          isValid: true
        }
      ],
      isFormValid: false,
      isSavePending: false,
      formError: {},
      showToast: false,
      successToastMessage: ''
    });

    this.setInitialData(this.initialData);

    this.writeData('selectedQuestionSets', new OrderedSet());
    this.writeData('questionSets', new OrderedSet());

    this.handle(templateListActions.CREATE_TEMPLATE, this._createTemplate);
    this.handle(templateListActions.SAVE_SET_LIST_TO_TEMPLATE, this._saveSetList);
    this.handle(templateListActions.SELECT_TEMPLATE, this._selectTemplate);
    this.handle(
      templateListActions.SET_ORDERED_TEMPLATE_QUESTION_SETS,
      this._setOrderedTemplateQuestionSets
    );
    this.handle(templateListActions.SET_SELECTED_QUESTION_SETS, this._setSelectedQuestionSets);
    this.handle(
      templateListActions.SORT_AND_SET_TEMPLATE_QUESTION_SETS,
      this._sortAndSetTemplateQuestionSets
    );
    this.handle(templateListActions.SELECT_FIRST_N_QUESTIONS, this._selectFirstNQuestions);
    this.handle(templateListActions.UPDATE_NEW_TEMPLATE_NAME, this._updateNewTemplateName);
    this.handle(templateListActions.RESET_STORE, this._resetStore);
    this.handle(templateListActions.SET_SHOW_TOAST, this._setShowToast);
    this.handle(templateListActions.SET_INPUT_STATE, this._setInputState);
    this.handle(templateListActions.VALIDATE_INPUT, this._validateInput);
    this.handle(templateListActions.SET_IS_CREATE_TEMPLATE_FORM, this._setIsCreateTemplateForm);
  }

  getAssignmentTemplatesQuery() {
    return mandarkEndpoint([
      'teachers_v1',
      masqueradeStore.getUserIdByMasqueradeState(),
      'assignments_v2'
    ])
      .filter({
        template: true
      })
      .sort('name')
      .customQuery({
        with_meta: 'assignment_v2'
      })
      .pageSize(250)
      .defaultModel(List);
  }

  _getAssignmentTemplateQuery(id) {
    return mandarkEndpoint(['assignments_v2', id])
      .customQuery({
        with_meta: 'assignment_v2'
      })
      .defaultModel(AssignmentModelV2);
  }

  _selectTemplate(templateId) {
    const selectedTemplates = this.getSelectedTemplates();
    if (selectedTemplates.has(templateId)) {
      this.writeData('selectedTemplates', selectedTemplates.delete(templateId));
    } else {
      this.writeData('selectedTemplates', selectedTemplates.add(templateId));
    }
    this._setHasMadeSelection(true);
  }

  _setSelectedQuestionSets(selectedQuestionSets) {
    this.writeData('selectedQuestionSets', selectedQuestionSets);
  }

  _sortAndSetTemplateQuestionSets() {
    const templateQuestionSets = this.getSelectedQuestionSets();
    const sortedLoadedQuestions = vanillaIEQuestionsListStore.questionSets
      .filter((questionSet) =>
        templateQuestionSets.find((qSet) => qSet.getId() === questionSet.getId())
      )
      .toOrderedSet();
    const unloadedQuestions = templateQuestionSets.filterNot((questionSet) => {
      return vanillaIEQuestionsListStore.questionSets.find(
        (qSet) => qSet.getId() === questionSet.getId()
      );
    });

    const questionSetsToAdd = sortedLoadedQuestions.concat(unloadedQuestions);

    this._setOrderedTemplateQuestionSets(questionSetsToAdd);
  }

  _setOrderedTemplateQuestionSets(questionSets) {
    this.writeData('questionSets', questionSets);
  }

  /**
   * Make a request for the number of questions we desire. Since the number of questions will always be
   * greater than the number of question sets, we grab that number of question sets and then delete
   * in reverse order until we are under the desired limit.
   * NOTE: This has a hard limit of 250 question sets
   */
  async _selectFirstNQuestions(numQuestions) {
    const questionSetsQuery = vanillaIEQuestionsListStore.makeFirstNQuestionSetsQuery(numQuestions);
    let questionSets = await getResourcePromise(questionSetsQuery);
    let runningCount = 0;

    if (isSearchMode()) {
      const processedQuestionSets = {};
      /**
       * In search mode, question sets must be derived from what has been
       * included on the search_question_v1 resource.
       */
      questionSets = questionSets.reduce((acc, curr) => {
        const currentSet = curr.getQuestionSet();
        if (processedQuestionSets[currentSet.getId()]) {
          return acc;
        }
        processedQuestionSets[currentSet.getId()] = true;
        return acc.push(currentSet);
      }, List());
    }

    questionSets = questionSets
      /**
       * Ensure we're only grabbing question sets that the user can actually assign.
       */
      .filter((questionSet) => {
        const {isReady, value} = canCreateTemplateForSubject(questionSet.getSubjectId());
        return (isReady && !questionSet.getFreeGuideLevels().isEmpty()) || value;
      })
      .takeWhile((questionSet) => {
        const questionCount = questionSet.getQuestions().size;
        runningCount += questionCount;
        return runningCount <= 200;
      });

    this._setSelectedQuestionSets(questionSets.toOrderedSet());
  }

  async _saveSetList() {
    const setIds = this.readData('questionSets').map((questionSet) => questionSet.getId());
    let templates = this.readData('selectedTemplates');
    this.writeData('isSavePending', true);

    try {
      for (const templateId of templates) {
        let template = await this._getAssignmentTemplateQuery(templateId).getResourcePromise();

        await addQuestionSetsToAssignment({
          assignmentId: template.getId(),
          setIds: setIds,
          questionSetCount: template.getQuestionCount()
        }).getPromise();

        // This guarantees the question list in the teacher assignment IE is immediately up to date.
        // (Especially important for templates formerly with zero questions)
        makeAssignmentQuery({assignmentId: template.getId()}).invalidateInterest();

        /*
         * get the updated template to determine if we need to de-select based on the
         * new number of questions
         *
         * @todo this should be updated to the response of the update when
         *       `addQuestionSetsToAssignment` is replaced.
         */
        this._getAssignmentTemplateQuery(templateId).invalidateInterest();
        template = await this._getAssignmentTemplateQuery(templateId).getResourcePromise();

        if (template.getQuestionCount() >= 200) {
          templates = templates.delete(template.getId());
          this.writeData('selectedTemplates', templates);
        }
      }

      this.writeData('successToastMessage', 'Your changes were saved successfully!');
      this._setShowToast(true);
    } catch (err) {
      this.writeData('error', err);
    } finally {
      this.getAssignmentTemplatesQuery().invalidateInterest();
      invalidatePartialInterest(mandarkEndpoint(['assignments_v2']).done());
      this.writeData('isSavePending', false);
    }
  }

  _updateNewTemplateName(name) {
    this.writeData('newTemplateName', name);
  }

  getSelectedTemplates() {
    return this.readData('selectedTemplates', new List()).toSet();
  }

  isTemplateSelected(templateId) {
    const selectedTemplates = this.getSelectedTemplates();
    return selectedTemplates.has(templateId);
  }

  get hasMadeSelection() {
    return !this.readData('selectedTemplates', new List()).isEmpty();
  }

  get isCreateTemplateForm() {
    return this.readData('isCreateTemplateForm');
  }

  _setIsCreateTemplateForm(isCreateTemplateForm) {
    this.writeData('isCreateTemplateForm', isCreateTemplateForm);
  }

  getSelectedQuestionSets() {
    return this.readData('selectedQuestionSets');
  }

  getQuestionSets() {
    return this.readData('questionSets');
  }

  getTotalQuestionCount() {
    return this.readData('questionSets').reduce(
      (acc, questionSet) => acc + questionSet.getQuestions().size,
      0
    );
  }

  get userInputData() {
    return this.readData('userInputData');
  }

  get isFormValid() {
    return this.readData('isFormValid');
  }

  get showToast() {
    return this.readData('showToast');
  }

  get successToastMessage() {
    return this.readData('successToastMessage');
  }

  get formError() {
    return this.readData('formError');
  }

  get formErrorMessage() {
    return this.formError.get('displayMessage', '');
  }

  get isFormError() {
    return !this.formError.isEmpty();
  }

  get isSavePending() {
    return this.readData('isSavePending');
  }

  _getInputState(inputName) {
    const userInputData = this.userInputData;

    return userInputData.find((inputObj) => {
      return inputObj.get('name') === inputName;
    });
  }

  _getCreateAssignmentTemplate() {
    const name = this.getInputValue('newTemplateName');
    const teacherId = masqueradeStore.getUserIdByMasqueradeState();

    return createAssignmentTemplate({
      name,
      teacherId
    });
  }

  _getCreateAssignmentTemplatePromise() {
    return this._getCreateAssignmentTemplate().getPromise();
  }

  _resetStore() {
    this.writeData(this.initialData);
    this.writeData((state) => {
      return state
        .set('selectedQuestionSets', new OrderedSet())
        .set('questionSets', new OrderedSet());
    });
  }

  _setShowToast(boolValue) {
    this.writeData('showToast', boolValue);
  }

  _setFormError(error) {
    this.writeData('formError', new Map(error));
  }

  _setHasMadeSelection(boolValue) {
    this.writeData('hasMadeSelection', boolValue);
  }

  _setIsFormValid(value) {
    this.writeData('isFormValid', value);
  }

  _setInputState(inputState) {
    let userInputData = this.userInputData;

    const storedInputStateIndex = userInputData.findKey((inputObj) => {
      return inputObj.get('name') === inputState.get('name');
    });
    userInputData = userInputData.delete(storedInputStateIndex);

    userInputData = userInputData.push(inputState);
    this.writeData('userInputData', userInputData);
  }

  _validateInput(inputState) {
    const inputName = inputState.get('name');
    const storedInputState = this._getInputState(inputName);

    const isValid = this._isInputDataValid(storedInputState);
    const newInputState = storedInputState.set('isValid', isValid);

    this._setInputState(newInputState);
  }

  _isInputDataValid(inputState) {
    const validators = new List([
      {
        name: 'newTemplateName',
        validateData: (value) => {
          return value.length > 0;
        }
      }
    ]);

    const currentInputValidator = validators.find((validator) => {
      return validator.name === inputState.get('name');
    });
    const isInputValid = currentInputValidator.validateData(inputState.get('value'));

    this._setIsFormValid(this.isFormValid && isInputValid);

    return isInputValid;
  }

  _createTemplate() {
    const userInputData = this.userInputData;
    this._setIsFormValid(true);

    userInputData.forEach((inputObj) => {
      this._validateInput(inputObj);
    });

    if (this.isFormValid) {
      this._sendCreateTemplateRequest();
    }
  }

  async _sendCreateTemplateRequest() {
    try {
      await this._getCreateAssignmentTemplatePromise();
      this.getAssignmentTemplatesQuery().invalidateInterest();
      this.writeData('successToastMessage', 'New folder created successfully!');
      this._setShowToast(true);
      this._setIsCreateTemplateForm(false);
      this._setHasMadeSelection(false);
    } catch (error) {
      this._setFormError(error);
    }
  }

  getInputValue(inputName) {
    const inputState = this._getInputState(inputName);

    return inputState.get('value');
  }

  isInputValid(inputName) {
    const inputState = this._getInputState(inputName);

    return inputState.get('isValid');
  }
}

export default new TemplateListStore('TemplateListStore');
