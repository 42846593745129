import React from 'react';
import {callAction} from 'client/framework';
import vanillaIEQuestionsListActions from 'client/InteractionEngineV2/IESessionTypes/VanillaIE/VanillaIEQuestionsList/VanillaIEQuestionsList.actions';
import vanillaIEQuestionsListStore from 'client/InteractionEngineV2/IESessionTypes/VanillaIE/VanillaIEQuestionsList/VanillaIEQuestionsList.store';
import questionsListActions from 'client/InteractionEngineV2/shared/QuestionsList/QuestionsListActions';
import {getResource, getResourcePromise} from 'resources/mandark.resource';
import {isSearchMode} from 'client/components/AdvancedSearch/AdvancedSearch.utils';

import './questions-list-paginator.scss';

export default class QuestionsListPaginator extends React.Component {
  changePage(page) {
    callAction(vanillaIEQuestionsListActions.SET_PAGE_NUMBER, page);
    const questionSetsQuery = vanillaIEQuestionsListStore.makeQuestionSetsQuery(page);
    getResourcePromise(questionSetsQuery).then(() => {
      this.setActiveQuestion(questionSetsQuery);
    });
  }

  setActiveQuestion(questionSetsQuery) {
    callAction(questionsListActions.SET_FORCE_AUTO_SCROLL_ON_MOUNT, true);

    let firstQuestionSetInPage;
    if (isSearchMode()) {
      firstQuestionSetInPage = getResource(questionSetsQuery)
        .first()
        .getQuestionSet();
    } else {
      firstQuestionSetInPage = getResource(questionSetsQuery).first();
    }
    const firstQuestionInSet = firstQuestionSetInPage.getQuestions().first();
    callAction(questionsListActions.SET_ACTIVE_QUESTION_SET, firstQuestionSetInPage);
    callAction(questionsListActions.SET_ACTIVE_QUESTION, firstQuestionInSet);
    callAction(vanillaIEQuestionsListActions.UPDATE_ROUTE);
  }

  render() {
    const currentPage = vanillaIEQuestionsListStore.currentPage;
    let totalPages = vanillaIEQuestionsListStore.totalPages;
    if (totalPages < 1) {
      totalPages = 1;
    }

    const prevPageButton =
      currentPage !== 1 ? (
        <button className='unbutton' onClick={() => this.changePage(currentPage - 1)}>
          <span className='fa fa-fw fa-chevron-left' />
        </button>
      ) : (
        <span className='fa fa-fw' />
      );

    const nextPageButton =
      currentPage !== totalPages ? (
        <button className='unbutton' onClick={() => this.changePage(currentPage + 1)}>
          <span className='fa fa-fw fa-chevron-right' />
        </button>
      ) : (
        <span className='fa fa-fw' />
      );

    return (
      <div className='questions-list-paginator'>
        {prevPageButton}
        <div className='questions-list-paginator__page-indicator'>
          Page {currentPage} of {totalPages}
        </div>
        {nextPageButton}
      </div>
    );
  }
}
