import {Map, List, Set} from 'immutable';
import {
  ClassroomModelV1,
  CurriculumAreaModelV1,
  GuideModelV1,
  GuideLevelModelV2,
  SubjectModelV2
} from '@albert-io/models';
import makeConstants from 'lib/makeConstants';

import {getClassroomsQuery} from '../TopicsModal/TopicsModal.queries';

import {getClassroomsSubjects} from './TopicsModal.queries';

export const guideTypes = ['practice', 'free_response', 'assessment'] as const;

export const guideType = makeConstants(...guideTypes);

export type GuideTypes = (typeof guideTypes)[number];

export const guideTypeText = {
  [guideType.practice]: 'Practice',
  [guideType.assessment]: 'Assessments',
  [guideType.free_response]: 'Free Response'
};

export const getDescendantGuideLevels = (
  guideLevelId: string,
  guideLevelChildrenMap: Map<string, List<GuideLevelModelV2>>
): List<GuideLevelModelV2> => {
  const children = guideLevelChildrenMap.get(guideLevelId);
  if (children == null) {
    return List();
  }

  return children
    .flatMap((child) => {
      return List([child]).concat(getDescendantGuideLevels(child.getId(), guideLevelChildrenMap));
    })
    .toList();
};

// util function to recursively update ascendant guide levels to selected or partially selected based on the current guide level selections
export const updateAscendantGuideLevelSelections = (
  currentGuideLevel: GuideLevelModelV2 | undefined,
  guideId: string,
  guideLevelChildrenMap: Map<string, List<GuideLevelModelV2>>,
  guideCollection: Map<string, Map<string, GuideModelV1>>,
  selectedGuideLevels: Set<GuideLevelModelV2>,
  partiallySelectedGuideLevels: Set<GuideLevelModelV2>
) => {
  // base case of the recursion i.e. when the previous guide level has no parent
  if (currentGuideLevel == null) {
    return {
      selectedGuideLevels,
      partiallySelectedGuideLevels
    };
  }

  let newSelectedGuideLevels = selectedGuideLevels;
  let newPartiallySelectedGuideLevels = partiallySelectedGuideLevels;

  const currentGuideLevelChildren = guideLevelChildrenMap.get(currentGuideLevel.getId());

  if (currentGuideLevelChildren.every((child) => newSelectedGuideLevels.has(child))) {
    newSelectedGuideLevels = newSelectedGuideLevels.add(currentGuideLevel);
    newPartiallySelectedGuideLevels = newPartiallySelectedGuideLevels.delete(currentGuideLevel);
  } else if (
    currentGuideLevelChildren.some(
      (child) => newSelectedGuideLevels.has(child) || newPartiallySelectedGuideLevels.has(child)
    )
  ) {
    newSelectedGuideLevels = newSelectedGuideLevels.delete(currentGuideLevel);
    newPartiallySelectedGuideLevels = newPartiallySelectedGuideLevels.add(currentGuideLevel);
  } else {
    newSelectedGuideLevels = newSelectedGuideLevels.delete(currentGuideLevel);
    newPartiallySelectedGuideLevels = newPartiallySelectedGuideLevels.delete(currentGuideLevel);
  }

  return updateAscendantGuideLevelSelections(
    guideCollection.get(guideId)?.get(currentGuideLevel.getParentId())?.guideLevel,
    guideId,
    guideLevelChildrenMap,
    guideCollection,
    newSelectedGuideLevels,
    newPartiallySelectedGuideLevels
  );
};

export const isGuideLevelVisible = (
  guideLevel: GuideLevelModelV2,
  expandedGuideLevels: Set<string>
): boolean => {
  // Top-level items are always visible
  const level = guideLevel.getLevel();
  if (/^\w+$/.test(level)) {
    return true;
  }

  // Check if parent is expanded
  const parts = level.split('.');

  // If parent is expanded, show this item
  return expandedGuideLevels.has(parts[0]);
};

// util function to get all subjects in all of the teacher user's classrooms
export const getTeachersClassroomSubjects = async (
  subjectSearchString,
  subjectGuideSearchString,
  contentDiscoveryFilters
): Promise<List<SubjectModelV2>> => {
  const classroomIds = List(await getClassroomsQuery().getResourcePromise())
    .map((classroom: ClassroomModelV1) => classroom.getId())
    .toArray();

  if (classroomIds.length === 0) {
    return List();
  }

  const classroomSubjects = List(
    await getClassroomsSubjects(
      classroomIds.join(','),
      subjectSearchString,
      subjectGuideSearchString,
      contentDiscoveryFilters
    ).getResourcePromise()
  );

  return classroomSubjects;
};

// util function to replace selected guide levels with the newly retrieved guide levels
export const replaceSelectedGuideLevels = (
  selectedGuideLevelSet: Set<GuideLevelModelV2>,
  guideLevelCollection: List<GuideLevelModelV2>
): Set<GuideLevelModelV2> => {
  const guideLevelIdCollection = guideLevelCollection
    .map((guideLevel) => guideLevel.getId())
    .toSet();
  const intersectingGuideLevels = selectedGuideLevelSet.filter((guideLevel) =>
    guideLevelIdCollection.has(guideLevel.getId())
  );
  if (intersectingGuideLevels.size === 0) {
    return selectedGuideLevelSet;
  }

  const intersectingGuideLevelIds = intersectingGuideLevels
    .map((guideLevel) => guideLevel.getId())
    .toSet();
  let newGuideLevelSet = selectedGuideLevelSet.subtract(intersectingGuideLevels);
  newGuideLevelSet = newGuideLevelSet.union(
    guideLevelCollection
      .filter((guideLevel) => intersectingGuideLevelIds.has(guideLevel.getId()))
      .toSet()
  );

  return newGuideLevelSet;
};

// util function to derive the question counts for each subject based on the selected guide levels
export const getSelectedSubjectQuestionCounts = (selectedGuideLevels) => {
  const guideLevelMap = selectedGuideLevels.reduce((acc, guideLevel) => {
    return acc.set(guideLevel.getId(), guideLevel);
  }, Map());

  let subjectIdToQuestionCountMap = Map({});
  let subjectIdToNameMap = Map({});

  selectedGuideLevels.forEach((guideLevel) => {
    if (guideLevel.getParentId() === 'root' || !guideLevelMap.has(guideLevel.getParentId())) {
      const subjectId = guideLevel.getSubject().getId();
      const subjectName = guideLevel.getSubject().getName();

      subjectIdToQuestionCountMap = subjectIdToQuestionCountMap.set(
        subjectId,
        subjectIdToQuestionCountMap.get(subjectId, 0) +
          guideLevel.getMeta().getContentDiscoveryQuestionCount()
      );

      if (!subjectIdToNameMap.has(subjectId)) {
        subjectIdToNameMap = subjectIdToNameMap.set(subjectId, subjectName);
      }
    }
  });

  return subjectIdToQuestionCountMap
    .entrySeq()
    .reduce((acc, entry) => {
      const [subjectId, questionCount] = entry!;
      return acc!.push({
        id: subjectId,
        title: subjectIdToNameMap.get(subjectId),
        count: questionCount
      });
    }, List())
    .sort((a, b) => (a as any).title.localeCompare((b as any).title))
    .toArray();
};

// util function to get an ordered flattened list of guide levels from a subject guide
// that contains an unordered list of hierarchical guide levels
export const getFlattenedGuideList = (
  subjectGuide: GuideModelV1,
  filterNonMatchingGuideLevels: boolean = false
): [List<GuideLevelModelV2>, Map<string, GuideLevelModelV2>, Map<string, number>] => {
  if (subjectGuide == null) {
    return [List(), Map(), Map()];
  }

  let childrenMap = Map<string, List<GuideLevelModelV2>>();
  let matchingGuideLevelIds = Set<string>();
  let matchingTopicsForGuideLevelIds = Map<string, number>();

  // build a map of parent guide level id to its children guide levels
  const guideLevels = subjectGuide.getGuideLevels().getCollection().toList();

  guideLevels.forEach((guideLevel) => {
    const nLevel = guideLevel.getNlevel();
    let parentId = guideLevel.getParentId();

    if (parentId == null && nLevel === 1) {
      parentId = 'root';
    }
    if (parentId == null && nLevel > 1) {
      parentId = 'orphan';
    }

    childrenMap = childrenMap.set(
      parentId,
      childrenMap.get(parentId)?.push(guideLevel) || List([guideLevel])
    );
  });

  if (filterNonMatchingGuideLevels) {
    // check if guide level matches search string
    guideLevels.forEach((guideLevel) => {
      if (guideLevel.getMeta().isMatchesSearch()) {
        // add to count of matching topics under the top level guide level
        const topGuideLevelId = guideLevel.getLevel().split('.')[0];
        matchingTopicsForGuideLevelIds = matchingTopicsForGuideLevelIds.set(
          topGuideLevelId,
          matchingTopicsForGuideLevelIds.get(topGuideLevelId, 0) + 1
        );
        matchingGuideLevelIds = matchingGuideLevelIds.add(guideLevel.getId());

        // add all ancestor guide levels to the matching guide level ids set
        let currentGuideLevel = guideLevel;
        while (
          currentGuideLevel.getParentId() != null &&
          currentGuideLevel.getParentId() !== 'root'
        ) {
          matchingGuideLevelIds = matchingGuideLevelIds.add(currentGuideLevel.getParentId());
          currentGuideLevel = guideLevels.find(
            (gl) => gl.getId() === currentGuideLevel.getParentId()
          );
        }

        const descendants = getDescendantGuideLevels(guideLevel.getId(), childrenMap);
        descendants.forEach((descendant) => {
          matchingGuideLevelIds = matchingGuideLevelIds.add(descendant!.getId());
        });
      }
    });

    childrenMap.forEach((children, parentId) => {
      if (parentId != null && children != null) {
        childrenMap = childrenMap.set(
          parentId,
          children.filter((child) => matchingGuideLevelIds.has(child.getId())).toList()
        );
      }
    });
  }

  // sort children guide levels by position
  childrenMap.entrySeq().forEach((child) => {
    // asserting that child is not undefined
    const [parentId, children] = child!;
    childrenMap = childrenMap.set(
      parentId,
      children.sort((a, b) => a.position - b.position)
    );
  });

  let flatList = List();

  // recursively traverse the children map to build a flat list of guide levels
  const traverse = (nodeId) => {
    const children = childrenMap.get(nodeId);

    if (children != null) {
      children.forEach((child) => {
        flatList = flatList.push(child);
        traverse(child.getId());
      });
    }
  };

  traverse('root');

  return [flatList, childrenMap, matchingTopicsForGuideLevelIds];
};

// util function to build a flat list of curriculum areas and their corresponding subjects (for ease of rendering in the SubjectResults list)
export const buildFilteredSubjectsAndCurriculumAreasList = ({
  filteredClassroomSubjects,
  filteredSubjectsList,
  areSubjectsPersonalized
}: {
  filteredClassroomSubjects: List<SubjectModelV2>;
  filteredSubjectsList: List<SubjectModelV2>;
  areSubjectsPersonalized: boolean;
}): List<CurriculumAreaModelV1 | SubjectModelV2> => {
  if (filteredSubjectsList.size === 0 && filteredClassroomSubjects.size === 0) {
    return List();
  }

  // group subjects by curriculum area
  let curriculumAreaHash = filteredSubjectsList.reduce((acc: any, subject) => {
    const curriculumArea = subject.getCurriculumArea().getName();
    return acc.set(curriculumArea, acc.get(curriculumArea, List()).push(subject));
  }, Map());

  // add classroom subjects to the curriculum area hash if personalized subjects are enabled
  if (areSubjectsPersonalized && filteredClassroomSubjects.size > 0) {
    curriculumAreaHash = curriculumAreaHash.set('Class Subjects', filteredClassroomSubjects);
  }

  // build flat list of curriculum areas and subjects
  return curriculumAreaHash
    .entrySeq()
    .sort(([a, _subjectsA], [b, _subjectsB]) => {
      // sort curriculum areas by name while always putting 'Other' at the end and 'Class Subjects' at the beginning
      if (a === 'Other') return 1;
      if (b === 'Other') return -1;
      if (a === 'Class Subjects') return -1;
      if (b === 'Class Subjects') return 1;
      return a.localeCompare(b);
    })
    .flatMap(([name, subjects]) => {
      const filteredSubjects = subjects.filter((subject) => {
        if (subject.isHidden()) {
          return false;
        }
        // if personalized subjects are enabled, filter out duplicate subjects that are already in the classroom subjects list
        if (
          areSubjectsPersonalized &&
          name !== 'Class Subjects' &&
          filteredClassroomSubjects.find(
            (classroomSubject) => classroomSubject.getId() === subject.getId()
          )
        ) {
          return false;
        }
        return true;
      });

      if (filteredSubjects.size > 0) {
        return List([new CurriculumAreaModelV1({name, subjects})]).concat(
          filteredSubjects.sortBy((subject) => subject.getName())
        );
      }

      return List();
    })
    .toList();
};
