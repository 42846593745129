import React, {useCallback, useMemo} from 'react';
import {Icon, Text, Toggle} from '@albert-io/atomic';

import {callAction} from 'client/framework';

import questionEditorActions from '../QuestionEditor/QuestionEditor.actions';
import questionEditorStore from '../QuestionEditor/QuestionEditor.store';

import {useQuestionEditorV2Store} from '../QuestionEditorV2Store';

import {GenerateTranslationsButton} from './GenerateTranslationsButton';

export const TranslationSection = () => {
  const translationsHaveChanges = useQuestionEditorV2Store((state) => state.hasChanges());
  const question = questionEditorStore.getQuestion();
  const questionSet = questionEditorStore.getQuestionSet();

  const setTranslateQuestion = useCallback((value: boolean) => {
    callAction(questionEditorActions.UPDATE_QUESTION_DETAILS, {
      setter: 'setTranslate',
      value
    });
  }, []);

  const setTranslateSupplements = useCallback((value: boolean) => {
    callAction(questionEditorActions.UPDATE_QUESTION_DETAILS, {
      setter: 'setTranslateSupplements',
      value
    });
  }, []);

  const questionIsNew = question && !question.existsOnServer();
  const questionHasChanges =
    questionSet &&
    (!questionSet.getChangeMap().isEmpty() ||
      !question.getChangeMap().isEmpty() ||
      translationsHaveChanges);

  const translationIsPossible = useMemo(() => {
    const subject = questionSet?.getAuthoringSubject();
    const questionTranslationEnabled = subject?.isTranslateQuestions?.() && question.isTranslate();
    const supplementTranslationEnabled =
      subject?.isTranslateSupplements?.() &&
      question.isTranslateSupplements() &&
      question.getAuthoringSupplements().some((s) => s.isTranslate());

    return questionTranslationEnabled || supplementTranslationEnabled;
  }, [questionSet, question]);

  return (
    <div className='u-display_flex'>
      <div className='u-width_50pc u-pad_6'>
        <div className='u-display_flex u-flex-direction_column u-gap_space-x3'>
          <div className='u-display_flex u-gap_space-x4'>
            <Text size='m' style={{width: '20px'}}>
              <Icon icon={['fal', 'align-left']} />
            </Text>

            <div className='u-display_flex u-justify-content_space-between u-gap_space-x2 u-flex-grow_1'>
              <div className='u-display_flex u-flex-direction_column'>
                <Text size='m'>Question Fields</Text>
                <Text size='s' color='secondary'>
                  Translate title, prompt, answer options, and explanations.
                </Text>
              </div>

              <Toggle
                checked={question?.isTranslate()}
                onChange={() => {
                  if (question?.isTranslate()) {
                    setTranslateQuestion(false);
                  } else {
                    setTranslateQuestion(true);
                  }
                }}
              />
            </div>
          </div>

          <div className='u-display_flex u-gap_space-x4'>
            <Text size='m' style={{width: '20px'}}>
              <Icon icon={['fal', 'photo-film-music']} />
            </Text>

            <div className='u-display_flex u-justify-content_space-between u-gap_space-x2 u-flex-grow_1'>
              <div className='u-display_flex u-flex-direction_column'>
                <Text size='m'>Supplements</Text>
                <Text size='s' color='secondary'>
                  Translate supplement captions, passages, etc
                </Text>
              </div>

              <Toggle
                checked={question?.isTranslateSupplements()}
                onChange={() => {
                  if (question?.isTranslateSupplements()) {
                    setTranslateSupplements(false);
                  } else {
                    setTranslateSupplements(true);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {translationIsPossible && (
        <div className='u-width_50pc u-pad_6 u-display_flex u-flex-direction_column u-align-items_center u-justify-content_center u-gap_space-x2'>
          <GenerateTranslationsButton />

          {questionIsNew || questionHasChanges ? (
            <Text size='m' color='warning'>
              Save changes to generate translations
            </Text>
          ) : (
            <Text size='m'>Or manually enter translations.</Text>
          )}
        </div>
      )}
    </div>
  );
};
