import React from 'react';

interface Props {
  src: string;
}

export default function Icon({src}: Props) {
  return (
    <div className='hero-unit__icon-wrapper'>
      <img alt='' aria-hidden className='hero-unit__icon' src={src} />
    </div>
  );
}
