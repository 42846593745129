import React from 'react';
import PropTypes from 'prop-types';
import {isFinite} from 'lodash';
import appStore from 'client/AppStore';

import './ie-stats.scss';

export default class IEStats extends React.Component {
  static propTypes = {
    accuracy: PropTypes.number,
    numOfQuestionsAnswered: PropTypes.number,
    shouldShowAccuracy: PropTypes.bool,
    totalNumOfQuestions: PropTypes.number
  };

  static defaultProps = {
    accuracy: 0,
    numOfQuestionsAnswered: 0,
    totalNumOfQuestions: 0
  };

  constructor() {
    super();
    this.positionerNode = null;
    this.statsNode = null;
    this.throttlePositionAndSize = false;
  }

  componentDidMount() {
    this.positionAndSizeStats();
    global.window.addEventListener('resize', this.positionAndSizeStats);
  }

  componentDidUpdate() {
    this.positionAndSizeStats();
  }

  componentWillUnmount() {
    global.window.removeEventListener('resize', this.positionAndSizeStats);
  }

  positionAndSizeStats = () => {
    if (this.throttlePositionAndSize || appStore.isMobileView()) {
      return;
    }
    this.throttlePositionAndSize = true;
    global.window.requestAnimationFrame(() => {
      if (!this.positionerNode) {
        return;
      }
      const positionerRect = this.positionerNode.getBoundingClientRect();
      ['left', 'width'].forEach((property) => {
        this.statsNode.style[property] = `${positionerRect[property]  }px`;
      });
      this.throttlePositionAndSize = false;
    });
  };

  makeProgressBar() {
    const {numOfQuestionsAnswered} = this.props;
    const {totalNumOfQuestions} = this.props;

    if (totalNumOfQuestions === 0) {
      return null;
    }

    return (
      <div className='ie-stats-stat'>
        <div className='ie-stats-stat__header'>
          <b>Progress</b>{' '}
          <span className='avoid-wrap'>
            ({numOfQuestionsAnswered} of {totalNumOfQuestions})
          </span>
        </div>
        <div className='ie-stats-stat__content'>
          <progress
            className='progress-meter progress-meter--medium progress-meter--blue'
            value={numOfQuestionsAnswered}
            max={totalNumOfQuestions}
          />
        </div>
      </div>
    );
  }

  makeAccuracyBar() {
    const {accuracy} = this.props;
    if (!isFinite(accuracy) || !this.props.shouldShowAccuracy) {
      return null;
    }
    return (
      <div className='ie-stats-stat'>
        <div className='ie-stats-stat__header'>
          <b>Accuracy</b> {accuracy}%
        </div>
        <div className='ie-stats-stat__content'>
          <progress
            className='progress-meter progress-meter--medium progress-meter--green'
            value={accuracy}
            max={100}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <div
        className='ie-stats-position-helper'
        ref={(node) => {
          this.positionerNode = node;
        }}
      >
        <div
          className='ie-stats-wrapper'
          ref={(node) => {
            this.statsNode = node;
          }}
        >
          {this.makeProgressBar()}
          {this.makeAccuracyBar()}
        </div>
      </div>
    );
  }
}
