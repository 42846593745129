/**
 * # Banner
 *
 * A banner component used to display important messages or notifications.
 *
 * ## Props
 *
 * - `align?: 'left' | 'center'` - The alignment of the banner content. Default is `left`.
 * - `children: React.ReactNode` - The content of the banner.
 * - `className?: string` - Additional CSS class name for the banner.
 * - `dismissable?: boolean` - Determines if the banner can be dismissed. Default is `false`.
 * - `onDismissEnd?: Function` - Callback function triggered when the banner is dismissed.
 * - `type?: 'negative' | 'neutral' | 'positive' | 'info' | 'warning'` - The type of the banner. Default is `neutral`.
 * - `border?: boolean` - Determines if the banner has a border. Default is `true`.
 * - `duration?: number` - The duration in milliseconds before the banner automatically dismisses.
 * - `timeoutId?: any` - The timeout ID used to cancel the dismissal timeout.
 *
 * ## Usage
 *
 * ```tsx
 * import Banner from './Banner.react';
 *
 * const MyComponent = () => {
 *   return (
 *     <Banner
 *       align="center"
 *       dismissable={true}
 *       onDismissEnd={() => console.log('Banner dismissed')}
 *       type="negative"
 *       border={false}
 *       duration={5000}
 *     >
 *       This is an important message!
 *     </Banner>
 *   );
 * };
 * ```
 */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import makeConstants from 'lib/makeConstants';

import IconButton from '../IconButton/IconButton.react';
import Icon, {IconProp as IconProps, IconStyle} from '../../atoms/Icon/Icon.react';
import './banner.scss';

export const alignOptions = makeConstants('left', 'center');

export const types = makeConstants('negative', 'neutral', 'positive', 'info', 'warning');

interface BannerIconProps extends PropsWithClassNameOptional {
  icon: IconProps;
  iconStyle?: IconStyle;
  style?: React.CSSProperties;
}

const BannerIcon = ({className, icon, iconStyle, style, ...rest}: BannerIconProps) => (
  <div className={classnames('m-banner__icon', className)}>
    <Icon {...rest} icon={icon} iconStyle={iconStyle} color='inherit' style={style} />
  </div>
);

interface DefaultBannerButtonProps {
  onClick: (e: any) => void;
}

interface BaseProps extends PropsWithClassNameOptional {
  disabled?: boolean;
  icon: IconProps;
  label?: string;
}

type BannerButtonProps = DefaultBannerButtonProps & BaseProps;

const BannerButton = ({icon, onClick, className, disabled, label}: BannerButtonProps) => {
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.persist();
      onClick(e);
    }
  };

  return (
    <IconButton
      className={classnames('m-banner__btn', className)}
      icon={icon}
      disabled={disabled}
      label={label}
      aria-hidden={!label}
      onClick={onClick}
      onKeyPress={onKeyPress}
      role='button'
      size='l'
      tabIndex={0}
    />
  );
};

interface BannerProps extends PropsWithChildrenRequired, PropsWithClassNameOptional {
  align?: any;
  dismissable?: boolean;
  onDismissEnd?: Function;
  type?: 'negative' | 'neutral' | 'positive' | 'info' | 'warning';
  border?: boolean;
  duration?: number;
  'data-testid'?: string;
}

interface BannerState {
  isDismissing: boolean;
  isDismissed: boolean;
}

export default class Banner extends React.Component<BannerProps, BannerState> {
  private wrapperRef: React.RefObject<any>;

  private timeoutId: null | ReturnType<typeof setTimeout>;

  static propTypes = {
    align: PropTypes.oneOf(Object.values(alignOptions)),
    children: PropTypes.node,
    className: PropTypes.string,
    dismissable: PropTypes.bool,
    onDismissEnd: PropTypes.func,
    type: PropTypes.oneOf(Object.keys(types)),
    duration: PropTypes.number,
    border: PropTypes.bool,
    'data-testid': PropTypes.string
  };

  static defaultProps = {
    align: alignOptions.left,
    type: 'neutral'
  };

  constructor(props) {
    super(props);
    this.state = {
      isDismissing: false,
      isDismissed: false
    };

    this.wrapperRef = React.createRef();
    this.timeoutId = null;
  }

  componentDidMount() {
    if (this.props.duration) {
      this.timeoutId = setTimeout(this.handleDismiss, this.props.duration);
    }

    this.wrapperRef.current.addEventListener('transitionend', this.handleDismissEnd);
    this.wrapperRef.current.addEventListener('animationend', this.handleDismissEnd);
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutId!);
    this.wrapperRef.current?.removeEventListener('transitionend', this.handleDismissEnd);
    this.wrapperRef.current?.removeEventListener('animationend', this.handleDismissEnd);
  }

  handleDismiss = () => {
    if (!this.isDismissable() || this.state.isDismissing) {
      return;
    }
    this.setState({isDismissing: true});
  };

  handleDismissEnd = (e) => {
    if (!this.state.isDismissing || e.target !== e.currentTarget) {
      return;
    }
    this.setState({isDismissed: true});
    if (this.props.onDismissEnd) {
      this.props.onDismissEnd();
    }
  };

  isDismissable() {
    return Boolean(this.props.dismissable || this.props.onDismissEnd);
  }

  static Btn = BannerButton;

  static Icon = BannerIcon;

  render() {
    const {
      align,
      children,
      className,
      type,
      border = true,
      'data-testid': dataTestId = ''
    } = this.props;
    if (this.state.isDismissed) {
      return null;
    }
    const isDismissable = this.isDismissable();
    return (
      <div
        ref={this.wrapperRef}
        className={classnames('m-banner', className, {
          'm-banner--dismissing': this.state.isDismissing
        })}
        data-testid={dataTestId}
      >
        <div
          className={classnames('m-banner__card', {
            [`m-banner__card--${type}`]: type,
            'u-border-color_transparent': !border
          })}
        >
          <div
            className={classnames('m-banner__content', {
              'u-text-align_center': align === alignOptions.center
            })}
          >
            {children}
            {isDismissable && (
              <Banner.Btn
                aria-hidden={!isDismissable}
                className='m-banner__btn--dismiss'
                disabled={this.state.isDismissing}
                icon={['far', 'times']}
                label='Dismiss banner'
                onClick={this.handleDismiss}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
