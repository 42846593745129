import React, {ElementType} from 'react';

import Heading from '../../../atoms/Heading/Heading.react';
import Icon from '../../../atoms/Icon/Icon.react';
import './back-button.scss';

export interface Props extends PropsWithChildrenOptional {
  as?: ElementType;
  text?: string;
}

export const BackButton = ({children, text, as, ...rest}: Props) => (
  <Heading as={as} size='2xs' className='o-wall-back-button' {...rest}>
    <Icon icon='arrow-left' /> {text || children}
  </Heading>
);
