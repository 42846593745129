import {UserModelV2} from 'resources/GeneratedModels/User/UserModel.v2';

import {userModelExtensions} from './user.extensions';
import {userModelValidators} from './UserModel.validators';
import {authoringSubjectExtentions} from './AuthoringSubject.extensions';

UserModelV2.addValidators(userModelValidators);
UserModelV2.extend(userModelExtensions);
UserModelV2.extend(authoringSubjectExtentions);

export {UserModelV2};
