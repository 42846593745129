import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import {Button, ButtonGroup} from '@albert-io/atomic';
import {resource} from '@albert-io/json-api-framework/request/builder';
import * as SortUtil from 'lib/SortUtil';
import {getDoesUserBelongToAnyLicensedClassroomsQuery} from 'lib/UserAccessUtil';

import deferComponentRender from 'lib/hocs/deferComponentRender';
import awaitMandarkQueries from 'lib/hocs/awaitMandarkQueries';

import {history} from 'client/history';
import {callAction} from 'client/framework';
import {withApplicationMetadata} from 'client/Routing/withApplicationMetadata';
import {SubjectModelV2} from 'resources/augmented/Subject/SubjectModel.v2';
import sessionStore from 'client/Session/SessionStore';

import {SubjectFaqModelV1} from 'resources/GeneratedModels/SubjectFaq/SubjectFaqModel.v1';

import UpgradeSubjectModal from 'components/PracticeView/UpgradeSubjectModal/UpgradeSubjectModal.react';

import SubjectFAQItem from './SubjectFAQItem/SubjectFAQItem.react';
import SubjectFAQQuestions from './SubjectFAQQuestions/SubjectFAQQuestions.react';

import subjectFAQListActions from './SubjectFAQList.actions';
import subjectFAQListStore from './SubjectFAQList.store';

const getSubjectFAQsQuery = (slug) => {
  return resource('subject_v2')
    .mandarkEndpoint(['subjects_v2'])
    .findOne()
    .filter({url_slug: slug})
    .include('subject_faqs_v1')
    .customQuery(
      {
        with_meta: 'subject_v2',
        meta: {context: {user: {id: sessionStore.getUserId()}}}
      },
      sessionStore.hasValidSession()
    );
};

class SubjectFAQList extends React.Component {
  static propTypes = {
    subject: PropTypes.instanceOf(SubjectModelV2),
    classrooms: ImmutablePropTypes.list,
    params: PropTypes.shape({
      slug: PropTypes.string
    })
  };

  constructor() {
    super();
    this.state = {showUpgradeSubjectModal: false};
  }

  componentDidMount() {
    const {subject, classrooms} = this.props;
    if (
      sessionStore.hasValidSession() &&
      sessionStore.isStudent() &&
      !classrooms.isEmpty() &&
      !subject.doesUserHaveAccess()
    ) {
      this.setState({showUpgradeSubjectModal: true});
    }
  }

  addNewFAQ = () => {
    callAction(subjectFAQListActions.ADD_NEW_FAQ);
  };

  generateFAQItemsArea(subjectFAQs) {
    const isCreatingNew = subjectFAQListStore.isCreatingNew();
    const {slug} = this.props.params;

    const creationComponent = isCreatingNew ? (
      <SubjectFAQItem
        isCreatingNew={isCreatingNew}
        subjectFAQ={new SubjectFaqModelV1().setOrderPosition(subjectFAQs.size).addRelationship({
          type: 'subject_v2',
          relation: this.props.subject.getId()
        })}
        onDelete={() => {
          if (isCreatingNew) {
            callAction(subjectFAQListActions.DISCARD_NEW_FAQ);
            return;
          }
          getSubjectFAQsQuery(slug).invalidateInterest();
        }}
        onSave={() => {
          getSubjectFAQsQuery(slug).invalidateInterest();
          if (isCreatingNew) {
            callAction(subjectFAQListActions.DISCARD_NEW_FAQ);
          }
        }}
      />
    ) : null;

    return (
      <div>
        {subjectFAQs.map((subjectFAQ) => (
          <SubjectFAQItem key={subjectFAQ.getId()} subjectFAQ={subjectFAQ} />
        ))}
        {creationComponent}
      </div>
    );
  }

  render() {
    const {subject} = this.props;
    // Not admin and no subjectFAQs. Redirect back to subject study guide.
    if (!sessionStore.isSuper() && subject.getSubjectFaqs().isEmpty()) {
      history.replace(`/${this.props.params.slug}`, null);
    }

    // Can't sort on an included resource, so we sort here
    const subjectFAQs = subject.getSubjectFaqs().sort((a, b) => {
      return SortUtil.numericComparatorAscending(a.getOrderPosition(), b.getOrderPosition());
    });

    const isCreatingNew = subjectFAQListStore.isCreatingNew();
    const isRearrangeMode = subjectFAQListStore.isRearrangeMode();

    return (
      <>
        <SubjectFAQQuestions subjectFAQs={subjectFAQs} />
        {!isRearrangeMode ? this.generateFAQItemsArea(subjectFAQs) : null}
        <ButtonGroup align='right'>
          {isCreatingNew === false && isRearrangeMode === false && sessionStore.isSuper() ? (
            <Button onClick={this.addNewFAQ}>Add new FAQ</Button>
          ) : null}
        </ButtonGroup>
        {this.state.showUpgradeSubjectModal && (
          <UpgradeSubjectModal
            subject={subject}
            handleClose={() => this.setState({showUpgradeSubjectModal: false})}
          />
        )}
      </>
    );
  }
}

const SubjectFAQListWithQueries = awaitMandarkQueries(
  (props) => ({
    queries: {
      subject: getSubjectFAQsQuery(props.params.slug),
      classrooms: getDoesUserBelongToAnyLicensedClassroomsQuery()
    }
  }),
  SubjectFAQList
);

export default withApplicationMetadata(deferComponentRender(SubjectFAQListWithQueries), (props) => {
  return {
    title: `${props.subject.getName()} | Frequently Asked Questions | Albert`,
    // eslint-disable-next-line max-len
    metaDescription: `Students often ask the same questions. Here are the most frequently asked questions for ${props.subject.getName()}.`
  };
});
