// @flow
// eslint-disable-next-line no-unused-vars
import {Component} from 'react';
import PropTypes from 'prop-types';

/**
 * Returns the correct PropType when trying to ensure a prop is of a specific component(s).
 *
 * @param {...Component} components - One or more components to type-check against
 * @see https://github.com/facebook/react/issues/2979
 * @returns {(...args: any[]) => ?Error}
 */
export default function componentPropType(...components: Array<Component<any, any>>): () => ?Error {
  const componentShape = PropTypes.shape({
    type: PropTypes.oneOf(components)
  });

  return PropTypes.oneOfType([componentShape, PropTypes.arrayOf(componentShape)]);
}
