import React from 'react';
import {QuestionModelV3} from 'resources/augmented/Question/QuestionModel.v3';

import PropTypes from 'prop-types';

import HowOthersAnswered from '../shared/HowOthersAnswered/HowOthersAnswered.react';

export class MCQHowOthersAnswered extends React.Component {
  static propTypes = {
    question: PropTypes.instanceOf(QuestionModelV3)
  };

  render() {
    const {question} = this.props;
    const correctAnswers = question.getMeta().getQuestionRubric().get('validResponse');
    const questionOptions = question.getOptions();
    const howOthersAnswered = question.getMeta().getHowOthersAnswered();
    const countOfCorrectGuesses = question.getMeta().getCountOfCorrectGuesses();
    const countOfIncorrectGuesses = question.getMeta().getCountOfIncorrectGuesses();
    const totalGuesses =
      correctAnswers.size === 1
        ? howOthersAnswered.reduce((result, value) => result + value.get('count_of_answers'), 0)
        : countOfIncorrectGuesses + countOfCorrectGuesses;

    let questionPercentage;
    const breakdownStats = questionOptions.toArray().map((val, index) => {
      const option = howOthersAnswered.get(val.getId(), null);
      const percentage = option
        ? ((option.get('count_of_answers') / totalGuesses) * 100).toFixed(0)
        : 0;
      const boldedText = String.fromCharCode('A'.charCodeAt() + index);
      const isCorrect = correctAnswers.has(val.getId());

      // if there is a single correct answer, set default percentage to that answer's percentage
      if (isCorrect && correctAnswers.size === 1) {
        questionPercentage = option
          ? ((option.get('count_of_answers') / totalGuesses) * 100).toFixed(0)
          : 0;
      }

      const inputBreakdown = {
        percentage,
        boldedText,
        isCorrect
      };

      return inputBreakdown;
    });

    const questionBreakdown = {
      label: '',
      breakdown: breakdownStats
    };

    return (
      <HowOthersAnswered
        expandable
        shouldRound={correctAnswers.size === 1}
        percentage={questionPercentage || null}
        breakdown={[questionBreakdown]}
        question={question}
      />
    );
  }
}
