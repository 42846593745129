import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {callTargetedAction, setUpStore} from 'client/framework';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {getModelForResourceType} from 'resources/modelRegistry';
import {Fieldset} from '@albert-io/atomic';

import snippetSelectionQuestionActions from './SnippetSelectionQuestion.actions';
import SnippetSelectionQuestionStore from './SnippetSelectionQuestion.store';

import './snippet-selection-question.scss';

class SnippetSelectionInputArea extends React.Component {
  static propTypes = {
    questionId: PropTypes.string,
    question: PropTypes.instanceOf(getModelForResourceType('question_v3')),
    storeName: PropTypes.string.isRequired,
    isGuessSubmitted: PropTypes.bool
  };

  getStore() {
    const {storeName, questionId} = this.props;
    return setUpStore(SnippetSelectionQuestionStore, storeName, questionId);
  }

  onOptionClick = (optionId) => {
    if (this.props.isGuessSubmitted) {
      return;
    }

    callTargetedAction({
      name: snippetSelectionQuestionActions.TOGGLE_OPTION,
      targetStore: this.getStore().getName(),
      payload: optionId
    });
  };

  render() {
    const {question, isGuessSubmitted} = this.props;
    const inputAreaClassNames = classnames('snippet-selection-question__input-area', {
      'snippet-selection-question__input-area--interactive': !(
        isGuessSubmitted || this.getStore().hasResponse
      )
    });
    return (
      <Fieldset legend='Select answer(s) below'>
        <div className='snippet-selection-question u-mar-b_2'>
          <MarkdownRendererV2
            className={inputAreaClassNames}
            isGuessSubmitted={isGuessSubmitted}
            text={question.getSnippetPrompt()}
            snippetSelectionData={{
              isExplanation: isGuessSubmitted,
              options: question.getOptions(),
              storeName: this.getStore().getName(),
              onOptionClick: this.onOptionClick
            }}
            question={question}
          />
        </div>
      </Fieldset>
    );
  }
}

export {SnippetSelectionInputArea};
