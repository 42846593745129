// @flow
import {fromJS} from 'immutable';
import {Store} from 'client/framework';
import passageCorrectionEditorActions from './PassageCorrectionEditor.actions';

class PassageCorrectionEditorStore extends Store {
  constructor(name: string) {
    super(name);
    this.setInitialData(
      fromJS({
        pendingStartIndex: null,
        pendingEndIndex: null,
        activeRegion: null
      })
    );
    this.handle(passageCorrectionEditorActions.SET_ACTIVE_REGION, this._setActiveRegion);
    this.handle(passageCorrectionEditorActions.RESET_STORE, this.resetStore);
    this.handle(passageCorrectionEditorActions.SELECTION_START, this._handleSelectionStart);
    this.handle(passageCorrectionEditorActions.SELECTION_UPDATE, this._handleSelectionUpdate);
    this.handle(passageCorrectionEditorActions.SELECTION_END, this._handleSelectionEnd);
  }

  _setActiveRegion(index: null | string | number) {
    this.writeData('activeRegion', index !== null ? `${index}` : index);
  }

  _handleSelectionStart(index: number) {
    this.writeData((store) => store.set('pendingStartIndex', index).set('pendingEndIndex', index));
  }

  _handleSelectionUpdate({
    pendingStartIndex,
    pendingEndIndex
  }: {
    pendingStartIndex: number,
    pendingEndIndex: number
  }) {
    this.writeData((store) =>
      store.set('pendingStartIndex', pendingStartIndex).set('pendingEndIndex', pendingEndIndex)
    );
  }

  _handleSelectionEnd() {
    this.resetProperties('pendingStartIndex', 'pendingEndIndex');
  }

  getPendingStartIndex = (): number => this.readData('pendingStartIndex');

  getPendingEndIndex = (): number => this.readData('pendingEndIndex');

  getActiveRegion = (): string => this.readData('activeRegion');

  hasActiveRegion = (): boolean => this.getActiveRegion() !== null;

  isSelectingRegion = (): boolean => this.getPendingEndIndex() !== null;
}

export default new PassageCorrectionEditorStore('PassageCorrectionEditorStore');
