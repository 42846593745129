// @flow
import type {QueryBuilder} from '@albert-io/json-api-framework/request/builder';
import {resource} from '@albert-io/json-api-framework/request/builder';
import sessionStore from 'client/Session/SessionStore';

function getGuideBySubjectIdQuery(subjectId: string, guideType: string): QueryBuilder {
  return resource('guide_v1')
    .mandarkEndpoint(['guides_v1'])
    .findOne()
    .include('subject_v2')
    .include('guide_levels_v2.subject_v2')
    .filter({
      guide_type: guideType,
      subject_v2: subjectId
    })
    .meta(
      {
        context: {
          guess: {
            most_recent: true
          },
          student: {
            id: sessionStore.getUserId()
          }
        }
      },
      sessionStore.hasValidSession() && sessionStore.isStudent()
    )
    .customQuery({
      with_meta: 'guide_v1,guide_level_v2'
    });
}

export default {
  getGuideBySubjectIdQuery
};
