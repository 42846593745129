import ImmutablePropTypes from 'react-immutable-proptypes';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {setUpStore} from 'client/framework';

import FillInTheBlankQuestionStore from './FillInTheBlankQuestion.store';

import './fill-in-the-blank.scss';

export default class FillInTheBlankQuestion extends React.Component {
  static propTypes = {
    storeName: PropTypes.string,
    question: ImmutablePropTypes.record,
    questionId: PropTypes.string,
    isGuessSubmitted: PropTypes.bool
  };

  static defaultProps = {
    storeName: 'fillInTheBlankQuestionStore'
  };

  getStore() {
    return setUpStore(FillInTheBlankQuestionStore, this.props.storeName, this.props.questionId);
  }

  render() {
    const {question, isGuessSubmitted} = this.props;
    const dropdowns = question.getDropdowns();
    return (
      <div className='fill-in-the-blank'>
        <div className='fill-in-the-blank-question-content'>
          <MarkdownRendererV2 text={question.getPrompt()} />
        </div>
        <MarkdownRendererV2
          className={classnames('fitb-input-area', {
            'fitb-input-area-dropdown': !dropdowns.isEmpty()
          })}
          text={question.getDropdownText()}
          store={this.getStore()}
          dropdowns={dropdowns}
          isExplanation={false}
          isGuessSubmitted={isGuessSubmitted}
          question={question}
        />
      </div>
    );
  }
}
