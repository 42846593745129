import React from 'react';

import './form-divider.scss';

export default function FormDivider({children}: PropsWithChildrenRequired) {
  return (
    <div className='form-divider'>
      <div className='form-divider__line' />
      <div className='form-divider__content'>{children}</div>
      <div className='form-divider__line' />
    </div>
  );
}
