import {SchoolModelV5} from 'resources/GeneratedModels/School/SchoolModel.v5';

import {schoolExtensions} from 'resources/augmented/School/school.extensions';

import {schoolModelValidators} from './SchoolModel.v5.validators';

SchoolModelV5.addValidators(schoolModelValidators);
SchoolModelV5.extend(schoolExtensions);

export {SchoolModelV5};
