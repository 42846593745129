/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';
import {List} from 'immutable';
import notifier from '@albert-io/notifier';
import {addToast} from '@albert-io/atomic';

import constants from 'client/constants';
import errorCodes from 'client/errorCodes';
import masqueradeStore from 'client/generic/Masquerade/Masquerade.store';
import {genericMandarkRequest, invalidatePartialInterest} from 'resources/mandark.resource';
import {query, resource} from '@albert-io/json-api-framework/request/builder';

import GoogleContext from '../Google.context';

/**
 * Google Context Provider
 * =======================
 *
 * This componenet wraps App.react.js and makes Google implementation available via context.
 *
 * The component's member methods will interface between Google's API and a state object.
 * We pass this state object to the context provider's value prop, where our context consumers
 * can then interact with it.  This can eventually replace GoogleClassroom.store.js.
 */

const scopes = `\
https://www.googleapis.com/auth/classroom.courses.readonly \
https://www.googleapis.com/auth/classroom.rosters.readonly \
https://www.googleapis.com/auth/classroom.profile.emails \
https://www.googleapis.com/auth/classroom.coursework.students \
profile`;

// Writing the token to state somewhere is probably irresponsible. We deliberately keep it in memory here instead.
let googleUserToken = null;
let googleLoadAttempts = 1;

export default class GoogleProvider extends React.Component {
  static propTypes = {
    children: PropTypes.node
  };

  constructor() {
    super();

    this.state = {
      classrooms: List(),
      clearError: () => this.setState({error: null}),
      error: null,
      handleSignIn: this.handleSignIn,
      handleSignOut: this.handleSignOut,
      handleImportClassroom: this.handleImportClassroom,
      handleExportAssignment: this.handleExportAssignment,
      isRequestPending: false,
      isSignedIn: false,
      loggedInUserEmail: null
    };
  }

  /**
   * Lifecycle methods
   *
   * @param _
   * @param prevState
   */
  async componentDidUpdate(_, prevState) {
    // Handle sign-in
    if (this.state.isSignedIn && !prevState.isSignedIn) {
      try {
        // Using genericMandarkRequest here to bypass the cache (see https://github.com/albert-io/project-management/issues/2719)
        const classrooms = await genericMandarkRequest('get', this.getClassroomsQuery().done());
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({classrooms});
      } catch (error) {
        this._handleError();
      }
    }

    // Handle sign-out
    if (prevState.isSignedIn && !this.state.isSignedIn) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({classrooms: List()});
    }
  }

  /**
   * Public member methods
   */
  handleSignIn = () => {
    this.setState({isRequestPending: true});
    if (global.gapi && this.state.isSignedIn) {
      this._initGoogleClient();
    } else {
      const intervalId = setInterval(() => {
        this._initGoogleClient(intervalId);
      }, 5000);
    }
  };

  handleSignOut = () => {
    this.setState({isRequestPending: true});
    global.gapi.auth2
      .getAuthInstance()
      .signOut()
      .then(() => {
        const isSignedIn = !!global.gapi.auth2.getAuthInstance().isSignedIn.get();
        this.setState(
          () => ({
            isRequestPending: false,
            isSignedIn,
            loggedInUserEmail: null
          }),
          () => invalidatePartialInterest(this.getClassroomsQuery().done())
        );
        googleUserToken = null;
      })
      .catch((error) => {
        addToast({
          title: 'Oops! Looks like something went wrong',
          color: 'negative',
          message: 'There was a problem with your request. If the problem persists, contact us.'
        });
        notifier.notify(error, {
          name: 'Google API failed to load',
          component: 'gapi'
        });
        this.setState({isRequestPending: false});
      });
  };

  /**
   * @param {Object} params
   * @param {Assignment} params.assignment The assignment model
   * @param {string} params.assignmentLink The link to the assignment that appears in Google Classroom
   * @param {string} params.googleCourseId The destination Google Classroom iD
   * @param {Function} [params.successCallback] Callback function to be called on success
   * @param {Function} [params.successCallback] Callback function to be called on error
   * @param params.errorCallback
   * @param params.classroomDueDate
   * @returns {Promise}
   */
  handleExportAssignment = ({
    assignment,
    assignmentLink,
    googleCourseId,
    successCallback = () => {},
    errorCallback = () => {},
    classroomDueDate
  }) => {
    const assignmentState = assignment.isScheduled() ? 'DRAFT' : 'PUBLISHED';

    const scheduledTime = assignment.isScheduled()
      ? assignment.getStartDateFromSettings().utc().format()
      : null;

    const dueDate = classroomDueDate ?? assignment.getDueDateFromSettings();

    const payload = {
      courseId: googleCourseId,
      creationTime: assignment.getInsertedAt().utc().format(),
      description: assignment.getMessageFromSettings(),
      dueDate: {
        year: dueDate.utc().format('YYYY'),
        month: dueDate.utc().format('MM'),
        day: dueDate.utc().format('DD')
      },
      dueTime: {
        hours: dueDate.utc().format('HH'),
        minutes: dueDate.utc().format('mm')
      },
      materials: [
        {
          link: {
            url: assignmentLink,
            title: assignment.getName(),
            thumbnailUrl: assignmentLink
          }
        }
      ],
      scheduledTime: null,
      state: assignmentState,
      title: assignment.getName(),
      workType: 'ASSIGNMENT'
    };

    if (scheduledTime) {
      payload.scheduledTime = scheduledTime;
    }

    global.gapi.client.classroom.courses.courseWork
      .create(payload)
      .then(() => {
        successCallback();
        this.setState({isRequestPending: false});
      })
      .catch((error) => {
        errorCallback();
        notifier.notify(error, {
          name: 'Failed to post assignment to Google Classroom',
          component: 'gapi'
        });
        this.setState({isRequestPending: false});
      });
  };

  handleImportClassroom = async ({callback = () => {}, classroomId, googleClassroomId}) => {
    const syncQuery = query()
      .mandarkEndpoint(['google_classroom_api', 'sync'])
      .customHeader({'google-classroom-api-token': googleUserToken})
      .customQuery({
        classroom_id: classroomId,
        course_id: googleClassroomId,
        issuer: masqueradeStore.getUserIdByMasqueradeState()
      })
      .done();

    this.setState({isRequestPending: true});
    try {
      await genericMandarkRequest('post', syncQuery);
      this.setState({isRequestPending: false});
      callback();
    } catch (error) {
      if (
        error.response.body.errors[0].code ===
        errorCodes.BAD_REQUEST.VALIDATION_ERROR.INVALID_EMAIL_DOMAIN
      ) {
        this.setState({
          isRequestPending: false,
          error: errorCodes.BAD_REQUEST.VALIDATION_ERROR.INVALID_EMAIL_DOMAIN
        });
      }
      if (error.response.body.errors[0].code === errorCodes.FORBIDDEN.EXCEEDED_HARD_CAP_LIMIT) {
        this.setState({
          isRequestPending: false,
          error: errorCodes.FORBIDDEN.EXCEEDED_HARD_CAP_LIMIT
        });
        return; // short circuit to not show generic error modal
      }
      addToast({
        title: 'Oops! Looks like something went wrong',
        color: 'negative',
        message: 'There was a problem with your request. If the problem persists, contact us.'
      });
    }
  };

  /**
   * Albert API layer
   */
  getClassroomsQuery() {
    return resource('google_classroom_api_courses_v1')
      .mandarkEndpoint(['google_classroom_api', 'courses'])
      .customHeader({'google-classroom-api-token': googleUserToken});
  }

  /**
   * Private GAPI handlers
   *
   * @param intervalId
   */
  _initGoogleClient = (intervalId) => {
    if (!global.gapi && googleLoadAttempts >= 10) {
      clearInterval(intervalId);
      addToast({
        title: 'Oops! Looks like something went wrong',
        color: 'negative',
        message: 'There was a problem connecting to Google.'
      });
      notifier.notify(new Error('gapi failed to load and exceeded Google load attempts'), {
        name: 'Google API failed to load',
        component: 'gapi'
      });
      return;
    }

    if (global.gapi) {
      clearInterval(intervalId);
      global.gapi.load('client:auth2', {
        callback: this._handleGoogleAuth,
        onerror: this._handleError,
        timeout: 5000,
        ontimeout: this._handleTimeout
      });
    } else {
      googleLoadAttempts += 1;
    }
  };

  _handleGoogleAuth = () => {
    /** Caution: The Promise object returned by Google does not include a .finally() method */
    global.gapi.client
      .init({
        clientId: constants.GCLOUD_CLIENT_ID,
        discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/classroom/v1/rest'],
        scope: scopes
      })
      .then(() => {
        // maybe not the right thing
        const isSignedIn = global.gapi.auth2.getAuthInstance().isSignedIn.get();
        if (isSignedIn) {
          const response = global.gapi.auth2.getAuthInstance().currentUser.get();
          return Promise.resolve(response);
        }
        return global.gapi.auth2.getAuthInstance().signIn();
      })
      .then((auth) => {
        googleUserToken = auth.getAuthResponse(true).access_token;
        this.setState({
          isSignedIn: true,
          isRequestPending: false,
          loggedInUserEmail: auth.getBasicProfile().getEmail()
        });
      })
      .catch((e) => {
        if (e?.error === errorCodes.GOOGLE.POPUP_BLOCKED) {
          this.setState({error: errorCodes.GOOGLE.POPUP_BLOCKED, isRequestPending: false});
          return;
        }
        if (e?.details === errorCodes.GOOGLE.COOKIES_DISABLED) {
          this.setState({error: errorCodes.GOOGLE.COOKIES_DISABLED, isRequestPending: false});
          return;
        }
        addToast({
          title: 'Oops! Looks like something went wrong',
          color: 'negative',
          message: 'There was a problem signing into Google.'
        });
        notifier.notify(e, {
          name: 'Google API client failed to load',
          component: 'gapi'
        });
        this.setState({isRequestPending: false});
      });
  };

  _handleError() {
    addToast({
      title: 'Oops! Looks like something went wrong',
      color: 'negative',
      message: 'There was a problem connecting to Google.'
    });
    notifier.notify(new Error('gapi.client failed to load'), {
      name: 'Google API client failed to load',
      component: 'gapi'
    });
  }

  _handleTimeout() {
    addToast({
      title: 'Oops! Looks like something went wrong',
      color: 'negative',
      message: 'Google took too long to respond.'
    });
    notifier.notify(new Error('timeout while loading gapi.client'), {
      name: 'Google API client timeout',
      component: 'gapi'
    });
  }

  render() {
    return (
      <GoogleContext.Provider value={this.state}>{this.props.children}</GoogleContext.Provider>
    );
  }
}
