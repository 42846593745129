/* eslint-disable no-underscore-dangle */
import {fromJS, Map} from 'immutable';
import QuestionTypeStore from 'components/QuestionTypes/QuestionType.store';

import {activeQuestionActions} from 'client/InteractionEngineV2/shared/ActiveQuestion/ActiveQuestionActions';
import questionTypeActions from 'components/QuestionTypes/questionType.actions';

import snippetSelectionQuestionActions from './SnippetSelectionQuestion.actions';

export default class SnippetSelectionQuestionStore extends QuestionTypeStore {
  constructor(name, questionId, question) {
    super(name);

    this.setInitialData(
      fromJS({
        ...this.draftGuessInitialData,
        questionId,
        selectedOptions: Map(),
        shouldShowExplanation: false
      })
    );

    this.writeData('__question', question);
    this.writeData('selectedOptions', new Map());

    this.handleTargeted(questionTypeActions.CLEAR_GUESS, this._clearGuess);
    this.handleTargeted(questionTypeActions.LOAD_GUESS, this._loadGuess);
    this.handleTargeted(activeQuestionActions.ACTIVE_QUESTION_LOAD_GUESS, this._loadGuess);
    this.handleTargeted(activeQuestionActions.ACTIVE_QUESTION_CLEAR_GUESS, this._clearGuess);
    this.handleTargeted(snippetSelectionQuestionActions.TOGGLE_OPTION, this._toggleOption);
  }

  afterLoadDraftGuess(draftGuess) {
    this.writeData('selectedOptions', this.parseGuess(draftGuess));
  }

  buildGuessContent() {
    const selectedOptions = this.readData('selectedOptions');
    return selectedOptions.toJS();
  }

  _clearGuess() {
    super._clearGuess();

    this.writeData('selectedOptions', this.initialData.get('selectedOptions'));
    super.kickoffDebouncedSaveDraftGuess();
  }

  getCorrectAnswers() {
    return this.validResponse.keySeq().toSet();
  }

  getSelectedOptions() {
    return this.readData('selectedOptions');
  }

  isOptionCorrect(optionId) {
    return this.getCorrectAnswers().includes(optionId);
  }

  isOptionSelected(optionId) {
    return this.getSelectedOptions().get(optionId) === true;
  }

  _loadGuess(guess) {
    super._loadGuess(guess);
    this.writeData('selectedOptions', this.parseGuess(guess));
  }

  parseGuess(guess) {
    return guess.get('content', new Map());
  }

  _toggleOption(optionId) {
    if (this.isOptionSelected(optionId)) {
      this.writeData('selectedOptions', this.getSelectedOptions().delete(optionId));
    } else {
      this.writeData('selectedOptions', this.getSelectedOptions().set(optionId, true));
    }

    super.kickoffDebouncedSaveDraftGuess();
  }
}
