import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import PureComponent from 'lib/purecomponent';
import {callTargetedAction, setUpStore} from 'client/framework';
import Pagination from 'generic/PaginationV2/Pagination.react';
import {Table} from 'sg/Tables/Table/Table.react';
import {queryBuilderPropType} from '@albert-io/json-api-framework/request/builder/legacy';

import paginationActions from 'generic/PaginationV2/Pagination.actions';

import {LoadingSpinnerLong} from '@albert-io/atomic';

import MandarkTableStore from './MandarkTable.store';
import mandarkTableActions from './MandarkTable.actions';

import './mandark-table.scss';

/**
 * In short, this works the almost the same way as `<Table />`, with three differences. For one, instead of
 * passing your data directly to `<Table />`, you pass it a partially built query (currently, the query must
 * be missing `{page: {page: currentPage}}` and `{sort: sortParam}`). Then, any sortable columns get a
 * `sort='some_sort_param'` prop, which is what we send up to Mandark for the sort param. Finally, you must
 * give one of your sortable columns a `defaultSortParam` prop, which will tell the table that that's the
 * property that will be used as the initial sort param.
 *
 * Also, if your request is paginated and there is more than one page, this will stick a paginator below your
 * table and handle setting of the current page for you.
 *
 * Basic example:
 *
 * class BasicExample extends React.Component {
 *   render() {
 *     const query = mandarkEndpoint(['teachers_v1', teacherId, 'classrooms_v1'])
 *       .pageSize(25)
 *       .customQuery({meta: {classroom_v1: true}});
 *     return (
 *       <MandarkTable
 *         query={query}
 *         storeName='ExampleStore'
 *       >
 *         <Column
 *           defaultSortParam
 *           heading='Name'
 *           sortBy='name'
 *           content={(classroom) => classroom.getName()}
 *         />
 *         <Column
 *           heading='Students'
 *           content={(classroom) => classroom.getCountOfStudents()}
 *         />
 *       </MandarkTable>
 *     );
 *   }
 * }
 */

export class MandarkTable extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]).isRequired,
    defaultSortDirection: PropTypes.string,
    query: queryBuilderPropType.isRequired,
    storeName: PropTypes.string,
    emptyTableElement: PropTypes.element,
    onDataChange: PropTypes.func,
    rowPropsFunc: PropTypes.func,
    mobileBreakpoint: PropTypes.number,
    dataMutator: PropTypes.func,
    onSortCallback: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.dataHashCode = null;
    this.setNumberOfPages();
  }

  componentDidUpdate(prevProps) {
    if (!this.props.query.equals(prevProps.query)) {
      this.resetActivePage();
    }

    const data = this.getStore().makeQuery(this.props.query).getResource();
    const dataHasChanged = this.dataHashCode !== data.hashCode();
    if (dataHasChanged) {
      this.dataHashCode = data.hashCode();
      this.setNumberOfPages();
    }
    if (dataHasChanged && this.props.onDataChange) {
      this.props.onDataChange(data);
    }
  }

  getStore() {
    const defaultValues = this.getDefaultStoreValues();
    return setUpStore(MandarkTableStore, this.props.storeName, defaultValues);
  }

  getDefaultStoreValues() {
    const defaultValues = {};
    const defaultSortColumn = this.getDefaultSortColumn();
    if (defaultSortColumn) {
      defaultValues.currentSortHeading = defaultSortColumn.props.heading;
      defaultValues.currentSortParam = defaultSortColumn.props.sortBy;
    }
    if (this.props.defaultSortDirection) {
      defaultValues.sortDirection = this.props.defaultSortDirection;
    }
    return defaultValues;
  }

  async setNumberOfPages() {
    const store = this.getStore();
    const builtQuery = store.makeQuery(this.props.query);
    await builtQuery.getResourcePromise();
    const totalNumberOfPages = builtQuery.getResourceMetadata().getIn(['page', 'total_pages'], 1);
    if (store.getTotalNumberOfPages() !== totalNumberOfPages) {
      this.callMandarkTableStoreAction(
        mandarkTableActions.SET_TOTAL_NUMBER_OF_PAGES,
        totalNumberOfPages
      );
    }

    if (store.getCurrentPage() > totalNumberOfPages) {
      this.resetActivePage();
    }
  }

  resetActivePage() {
    callTargetedAction({
      name: paginationActions.SET_ACTIVE_PAGE,
      payload: 1,
      targetStore: this.getStore().getPaginationStoreName()
    });
  }

  callMandarkTableStoreAction(action, payload) {
    callTargetedAction({
      name: action,
      payload,
      targetStore: this.getStore().getName()
    });
  }

  getDefaultSortColumn() {
    const sortableColumns = this.props.children.filter((child) => child.props.sortBy);
    const defaultSortColumn =
      sortableColumns.length === 1
        ? sortableColumns[0]
        : this.props.children.find((column) => column.props.defaultSortParam);
    if (sortableColumns.length > 1 && !defaultSortColumn) {
      throw new Error(
        'If using a <MandarkTable> with multiple sortable columns, please ' +
          'provide one sortable <Column /> in with a defaultSortParam={true} prop'
      );
    }
    return defaultSortColumn || null;
  }

  getPagination() {
    const store = this.getStore();
    const totalPages = store.getTotalNumberOfPages();
    if (totalPages === 1) {
      return null;
    }
    return (
      <Pagination
        storeName={store.getPaginationStoreName()}
        totalPages={store.getTotalNumberOfPages()}
      />
    );
  }

  render() {
    const store = this.getStore();
    const hasLoadedDataBefore = store.getHasLoadedData();
    const builtQuery = store.makeQuery(this.props.query);
    const isDataReady = builtQuery.isResourcePopulated();
    const data = builtQuery.getResource();

    if (!hasLoadedDataBefore) {
      if (!isDataReady) {
        return (
          <div className='u-display_flex u-align-items_center u-justify-content_center u-pad-tb_7 u-pad-lr_3'>
            <LoadingSpinnerLong />
          </div>
        );
      }
      this.callMandarkTableStoreAction(mandarkTableActions.SET_HAS_LOADED_DATA, true);
    }

    if (isDataReady && data.isEmpty() && this.props.emptyTableElement) {
      return this.props.emptyTableElement;
    }

    const columns = this.props.children.map((column, i) => {
      const customSortFunc = column.props.sortBy
        ? () => {
            this.callMandarkTableStoreAction(mandarkTableActions.UPDATE_SORT_BY_COLUMN, column);
            if (typeof this.props.onSortCallback === 'function') {
              this.props.onSortCallback(column.props.sortBy);
            }
          }
        : null;
      return <Column {...column.props} customSortFunc={customSortFunc} key={i} />;
    });

    return (
      <div className={classnames('mandark-table', this.props.className)}>
        <Table
          className={this.props.className}
          data={this.props.dataMutator ? this.props.dataMutator(data) : data}
          customSortHandler={store.getSortParams()}
          storeName={`${this.props.storeName}BaseTableStore`}
          isLoading={!isDataReady}
          rowPropsFunc={this.props.rowPropsFunc}
          mobileBreakpoint={this.props.mobileBreakpoint}
        >
          {columns}
        </Table>
        {this.getPagination()}
      </div>
    );
  }
}

export class Column extends PureComponent {
  static propTypes = {
    // align left does nothing, but it's here for consistency
    /* eslint-disable react/no-unused-prop-types */
    align: PropTypes.oneOf(['left', 'center', 'right']),
    defaultSortParam: PropTypes.bool,
    heading: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    sortBy: PropTypes.string
    /* eslint-enable react/no-unused-prop-types */
  };
}
