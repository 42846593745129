import {createActions} from 'client/framework';

export default createActions(null, [
  'SELECT_OPTION',
  'SET_SELECT_MULTIPLE',
  'TOGGLE_ELIMINATE_OPTION',
  'TOGGLE_HIDE_SHOW',
  'SET_ELIMINATED_TO_EMPTY',
  'RESET_HIDE_SHOW',
  'REMOVE_OPTION_FROM_SELECTED'
]);
