/**
 * # Button
 *
 * A customizable button component.
 *
 * ## Props
 *
 * - `className?: string`: Additional class name for the button.
 * - `children: React.ReactNode`: The content of the button.
 * - `color?: ButtonColor`: The color of the button. Can be one of: 'primary', 'secondary', 'negative', 'positive', 'inherit', 'vendor-google', 'vendor-clever'.
 * - `disabled?: boolean`: Whether the button is disabled.
 * - `size?: 's' | 'm' | 'l'`: The size of the button. Can be one of: 's', 'm', 'l'.
 * - `type?: 'button' | 'submit' | 'reset' | undefined`: The type of the button.
 * - `roundness?: ButtonRoundness`: The roundness of the button. Can be one of: 'square', 'circle', 'pill'.
 * - `variant?: ButtonVariant`: The variant changes the visual style of the background and border. Can be one of: 'solid', 'text', 'outlined', 'faded'.
 *
 * ## Usage
 *
 * ```tsx
 * import Button from './Button.react';
 *
 * const MyComponent = () => {
 *   return (
 *     <Button color="primary" size="m" variant="solid">
 *       Click me
 *     </Button>
 *   );
 * };
 * ```
 */

import React from 'react';
import classnames from 'classnames';
import './button.scss';

export type ButtonColor =
  | 'primary'
  | 'secondary'
  | 'negative'
  | 'positive'
  | 'inherit'
  | 'vendor-google'
  | 'vendor-clever';

export type ButtonVariant = 'solid' | 'text' | 'outlined' | 'faded';
export type ButtonRoundness = 'square' | 'circle' | 'pill';

interface Props extends PropsWithChildrenRequired, PropsWithClassNameOptional {
  color?: ButtonColor;
  disabled?: boolean;
  size?: 's' | 'm' | 'l';
  type?: 'button' | 'submit' | 'reset' | undefined;
  roundness?: ButtonRoundness;
  variant?: ButtonVariant;
}

export type ButtonProps<C extends React.ElementType> = PolymorphicComponentPropsWithRef<C, Props>;

type ButtonComponent = <C extends React.ElementType = 'span'>(
  props: ButtonProps<C>
) => React.ReactElement | null;

const Button: ButtonComponent = React.forwardRef(
  <C extends React.ElementType = 'span'>(
    {
      as,
      className,
      children,
      color = 'primary',
      variant = 'solid',
      disabled,
      size = 'm',
      roundness = 'square',
      type,
      ...rest
    }: ButtonProps<C>,
    ref: PolymorphicRef<C>
  ) => {
    const WrapperElement = as || 'button';
    const elType = type || (WrapperElement === 'button' ? 'button' : undefined);
    const classNames = classnames(
      'a-button',
      `a-button--${size}`,
      `a-button-${variant}--${color}`,
      `a-button--${color}`,
      {
        [`a-button--${roundness}`]: roundness,
        'a-button--inherit-with-transparent-bg': color === 'inherit',
        'a-button--disabled': disabled
      },
      className
    );
    return (
      <WrapperElement className={classNames} disabled={disabled} type={elType} ref={ref} {...rest}>
        {children}
      </WrapperElement>
    );
  }
); // the problem with this wrapper element pattern is it doesn't allow my component to attach a ref. THe ref just winds up being the JSX expression of what the 'button' in the as prop would resolve down to

export default Button;
