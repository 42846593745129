import React from 'react';
import {getRendererForQuestion} from 'components/QuestionTypes/getRendererForQuestion';
import BlockedAssignmentQuestion from 'client/components/QuestionTypes/BlockedAssignmentQuestion/BlockedAssignmentQuestion';
// import {Store} from 'client/framework'; TODO-KBC: Build typescript methods

interface Props {
  actions: React.ReactNode;
  activeGuess: any;
  activeQuestion: any;
  activeQuestionSet: any;
  activeQuestionStore: any; // PropTypes.instanceOf(Store) TODO-KBC: Build typescript methods
  activeSubject: any;
  customHeader?: any;
  displayOrientation?: 'default' | 'vertical' | 'horizontal';
  hasHeading?: boolean;
  headingActions: React.ReactNode;
  interactive?: boolean;
  isMobile?: boolean;
  modifyTitle?: () => {};
  navigateBackProps: {[key: string]: any} | null;
  navigateNextProps: {[key: string]: any} | null;
  onResize?: (resizerPosition: number) => void;
  postSubmissionScrollPointRef: {[key: string]: any};
  rendererType: 'PreGuess' | 'PostGuess';
  resizerPosition?: number;
  showDifficulty: boolean;
  hasQuestionSetAccessPredicate: (questionSet: any) => boolean;
}

const QuestionRenderer = ({
  actions,
  activeGuess,
  activeQuestion,
  activeQuestionSet,
  activeQuestionStore,
  activeSubject,
  customHeader,
  displayOrientation = 'default',
  hasHeading = true,
  headingActions,
  interactive,
  isMobile,
  modifyTitle,
  navigateBackProps,
  navigateNextProps,
  onResize,
  postSubmissionScrollPointRef,
  rendererType,
  resizerPosition,
  showDifficulty,
  hasQuestionSetAccessPredicate
}: Props) => {
  const Renderer = activeQuestion.getMeta().isBlockedAssignmentQuestion()
    ? BlockedAssignmentQuestion
    : getRendererForQuestion(activeQuestion)[rendererType];
  return (
    <Renderer
      actions={actions}
      activeQuestion={activeQuestion}
      activeQuestionSet={activeQuestionSet}
      activeQuestionStore={activeQuestionStore}
      activeSubject={activeSubject || null}
      customHeader={customHeader || null}
      displayOrientation={displayOrientation}
      guess={activeGuess}
      hasHeading={hasHeading}
      headingActions={headingActions}
      interactive={interactive}
      isMobile={isMobile}
      modifyTitle={modifyTitle}
      navigateBackProps={navigateBackProps}
      navigateNextProps={navigateNextProps}
      onResize={onResize}
      postSubmissionScrollPointRef={postSubmissionScrollPointRef}
      resizerPosition={resizerPosition}
      showDifficulty={showDifficulty}
      hasQuestionSetAccessPredicate={hasQuestionSetAccessPredicate}
    />
  );
};

export default QuestionRenderer;
