import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Text, TextField} from '@albert-io/atomic';

const GraphLabels = ({bottom, left, onChange}) => (
  <div className='u-mar-b_6'>
    <Text className='u-mar-b_2' size='l' as='p' bold>
      Graph labels
    </Text>
    <div className='u-display_flex'>
      <TextField
        border
        className='u-mar-r_1'
        label='Bottom'
        value={bottom}
        onChange={(e) => {
          onChange('bottom', e.target.value);
        }}
      />
      <TextField
        border
        className='u-mar-r_1'
        label='Left'
        value={left}
        onChange={(e) => {
          onChange('left', e.target.value);
        }}
      />
    </div>
  </div>
);

GraphLabels.propTypes = {
  bottom: PropTypes.string,
  left: PropTypes.string,
  onChange: PropTypes.func
};

export function useGraphLabelsState({left = '', bottom = ''} = {}) {
  const [labels, setLabels] = useState({
    left,
    bottom
  });

  const updateProperty = (property, value) => {
    setLabels((state) => ({...state, [property]: value}));
  };

  return [labels, updateProperty];
}

export default GraphLabels;
