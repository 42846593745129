import React, {createContext} from 'react';
import PropTypes from 'prop-types';
import {List, Map, fromJS} from 'immutable';

import appStore from 'client/AppStore';
import getQuestionDifficultiesFromQuestionSets from 'lib/getQuestionDifficultiesFromQuestionSets';
import getQuestionDifficultyTotalsFromList from 'lib/getQuestionDifficultyTotalsFromList';
import {addToast} from '@albert-io/atomic';

import {getQuestionSetPositionsQuery} from './utils/createAssignment.queries';

type QuestionSet =
  import('src/resources/augmented/QuestionSet/QuestionSetModel.v1').QuestionSetModelV1;
type QueryBuilder = import('@albert-io/json-api-framework/request/builder').QueryBuilder;

interface CreateAssignmentProviderState {
  breadcrumbs: List<any>;
  questionSetList: List<QuestionSet> | null;
  questionDifficultyTotals: any | null;
  isLoading: boolean;
  hasError: boolean;
  assignmentName: string;
  resourceType: string;
}

type CreateAssignmentContextFunctions = {
  bootstrapAssignment: ({
    questionSets,
    resourceType,
    assignmentOrigin,
    query,
    assignmentName,
    resourceId
  }: {
    questionSets: List<QuestionSet>;
    resourceType: string;
    assignmentOrigin: string;
    query: QueryBuilder;
    assignmentName: string;
    resourceId: string;
  }) => void;
  clearAssignmentProvider: () => void;
};
export type CreateAssignmentProviderStateWithFunctions = CreateAssignmentProviderState &
  CreateAssignmentContextFunctions;

const CreateAssignmentContext = createContext<
  CreateAssignmentProviderStateWithFunctions | undefined
>(undefined);

class CreateAssignmentProvider extends React.Component<
  PropsWithChildrenRequired,
  CreateAssignmentProviderState
> {
  static propTypes = {
    children: PropTypes.node
  };

  constructor(props: PropsWithChildrenRequired) {
    super(props);
    this.state = {
      breadcrumbs: List([]),
      questionSetList: null,
      questionDifficultyTotals: null,
      isLoading: false,
      hasError: false,
      assignmentName: '',
      resourceType: ''
    };
  }

  /**
   * This handles creating breadcrumb object that the ACF expects.
   * There are different formats depending on where in the app the assignment was created in.
   *
   * The function is called from `bootstrapAssignment()`
   *
   * @param {QuestionSet} firstQuestion - Used to create the guide level URL path for assignments created from the Subject Practice View
   * @param {string} resourceType - Used to decide which case to enter in the switch statement
   * @param {string} assignmentOrigin - Passes the specific folder name or other location if eventually needed.
   * @param allQuestionSets
   * @returns {List} - A list of {name, url} objects used to create the assignment breadcrumb links
   */
  getBreadcrumbs = (
    firstQuestion: QuestionSet,
    resourceType: string,
    assignmentOrigin: string,
    allQuestionSets: List<any>
  ) => {
    const subject = firstQuestion.getSubject();
    switch (resourceType) {
      case 'guide_levels_v2': {
        const commonGuideLevelPath = this.getCommonGuideLevel(allQuestionSets);
        return fromJS(
          commonGuideLevelPath.reduce(
            (acc: any, guideLevel: any) => {
              acc.push({
                name: guideLevel.getName(),
                url: `/learn/${subject.getUrlSlug()}/${guideLevel.getUrlSlug()}?page=1`
              });
              return acc;
            },
            [
              {
                name: subject.getName(),
                url: `/${subject.getUrlSlug()}`
              }
            ]
          )
        );
      }
      case 'templates_v1':
        return fromJS([
          {
            name: assignmentOrigin,
            url: null
          }
        ]);
      case 'assignments_v3':
        return fromJS([
          {
            name: assignmentOrigin,
            url: null
          }
        ]);
      default:
        return List();
    }
  };

  /**
   * Get common guide level path to display correct breadcrumbs
   * See:  https://github.com/albert-io/project-management/issues/6215
   *
   * @param {Immutable.List<QuestionSet>} allQuestionSets - sets that were populated during bootstrapping
   */
  getCommonGuideLevel = (allQuestionSets: List<QuestionSet>) => {
    // Array to get common guide level path - starting out with firstQuestion's path
    let commonPath = allQuestionSets.first().getGuideLevelPath();

    allQuestionSets.forEach((question: QuestionSet) => {
      for (let i = 0; i < commonPath?.size; i += 1) {
        if (commonPath.get(i).id !== question.getGuideLevelPath().get(i).id) {
          commonPath = commonPath.setSize(i);
          break;
        }
      }
    });

    return commonPath;
  };

  /**
   * Is used to get and sort by template or assignment positions.
   *
   * @param {Immutable.List<QuestionSet>} questionSets - sets that were populated during bootstrapping
   * @param {Immutable.Map} questionSetPositions - relationship data for questions sets and their position in the designated resource
   * @param {string} resourceType - to help decide what position value to get
   * @returns {Immutable.List<QuestionSet>} questionSets sorted by position
   */
  getSortedQuestionSets = (
    questionSets: List<QuestionSet>,
    questionSetPositions: Map<any, any>,
    resourceType: string
  ) => {
    // Mutate data structure to Map for easy lookup
    const positionMap = Map(
      questionSetPositions.map((set: any) => {
        const position = resourceType.includes('guide_level')
          ? set.getMeta().getGuideLevelPosition()
          : set.getMeta().getAssignmentPosition();
        return [set.getId(), position];
      })
    );
    return questionSets.sort((setA: QuestionSet, setB: QuestionSet) => {
      // @ts-ignore
      return positionMap.get(setA.getId()) < positionMap.get(setB.getId()) ? -1 : 1;
    });
  };

  /**
   * This function handles most of the logic and creates all of the necessary values that the ACF expects.
   *
   * @param {Immutable.List<QuestionSet>} questionSets - sets that are being passed to the ACF
   * @param {string} resourceType - currently one of ['guide_levels_v2', 'templates_v1', 'assignments_v3']
   * @param {string} assignmentOrigin - The specific origin of the assignment creation. This is currently used for folders
   * to pass the name of the folder along.
   * @param {QueryBuilder} query - If question sets can't be passed directly, a query is provided to fetch them.
   * @param {string} resourceId - the ID of the base folder or assignment model to determine question set order.
   */
  bootstrapAssignment = async ({
    questionSets,
    resourceType,
    assignmentOrigin,
    query,
    assignmentName,
    resourceId
  }: {
    questionSets: List<QuestionSet>;
    resourceType: string;
    assignmentOrigin: string;
    query: QueryBuilder;
    assignmentName: string;
    resourceId: string;
  }) => {
    let allQuestionSets;
    let questionSetPositions;
    this.setState({isLoading: true});
    if (query) {
      try {
        const hasSort = !!appStore.routerProps.location.query.sort;
        const getQuestionPositions = !hasSort && !!resourceId;

        allQuestionSets = await query.getResourcePromise();
        questionSetPositions =
          getQuestionPositions && (await getQuestionSetPositionsQuery(resourceType, resourceId));
      } catch {
        this.setState({hasError: true});
        addToast({
          title: 'Something went wrong',
          color: 'negative',
          message:
            'There was a problem loading your questions. If the problem persists, contact us.'
        });
      }
    } else {
      allQuestionSets = questionSets;
    }
    if (!allQuestionSets) {
      this.setState({
        questionSetList: null,
        questionDifficultyTotals: null,
        isLoading: false
      });
      return;
    }
    if (questionSetPositions) {
      allQuestionSets = this.getSortedQuestionSets(
        allQuestionSets,
        questionSetPositions,
        resourceType
      );
    }
    const difficulties = getQuestionDifficultiesFromQuestionSets(allQuestionSets);
    const total = getQuestionDifficultyTotalsFromList(difficulties);
    const crumbs = this.getBreadcrumbs(
      allQuestionSets.first(),
      resourceType,
      assignmentOrigin,
      allQuestionSets
    );
    this.setState({
      questionSetList: allQuestionSets,
      breadcrumbs: crumbs,
      questionDifficultyTotals: total,
      isLoading: false,
      assignmentName,
      resourceType
    });
  };

  /**
   * clears question sets and related info
   */
  clearAssignmentProvider = () => {
    this.setState({
      questionSetList: null,
      questionDifficultyTotals: null,
      resourceType: ''
    });
  };

  render() {
    return (
      <CreateAssignmentContext.Provider
        value={{
          ...this.state,
          bootstrapAssignment: this.bootstrapAssignment,
          clearAssignmentProvider: this.clearAssignmentProvider
        }}
      >
        {this.props.children}
      </CreateAssignmentContext.Provider>
    );
  }
}

export {CreateAssignmentProvider, CreateAssignmentContext};
