import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {mapValues, isFunction} from 'lodash';
import Question, {sharedQuestionPropTypes} from 'components/PracticeView/Question/Question.react';
import './question-set.scss';

const boolOrFunctionType = PropTypes.oneOfType([PropTypes.bool, PropTypes.func]);

export const questionSetItemProps = {
  questionSetProps: PropTypes.shape({
    selectable: boolOrFunctionType,
    rearrangeable: boolOrFunctionType,
    makeQuestionLink: PropTypes.func,
    idx: PropTypes.number,
    viewingFromFolder: PropTypes.bool
  }),
  questionProps: PropTypes.shape(sharedQuestionPropTypes).isRequired
};

/**
 * IMPORTANT
 * With the removal of multi-question question sets, this serves more as a wrapper for `question.react`
 * I don't want to change/remove the file due to the fact that we still have the notion of question sets
 * throughout the application and the api.
 */
export default class QuestionSet extends React.Component {
  static propTypes = {
    ...questionSetItemProps
  };

  static defaultProps = {
    as: 'li',
    clickable: true,
    questionSetProps: {},
    questionProps: {}
  };

  getQuestionSetProps = () => {
    return mapValues(this.props.questionSetProps, (propValue) => {
      return isFunction(propValue) ? propValue(this.props.questionSet) : propValue;
    });
  };

  getQuestionProps = (question) => {
    const {questionSet} = this.props;
    return mapValues(this.props.questionProps, (propValue) => {
      return typeof propValue === 'function' ? propValue({question, questionSet}) : propValue;
    });
  };

  getActions(questionSetProps, question) {
    const actions = [];
    const {selectable, rearrangeable, isSelected, questionSetOnChangeHandler} = questionSetProps;
    if (selectable && !rearrangeable) {
      actions.push(
        <Question.Checkbox onChange={questionSetOnChangeHandler} checked={isSelected} />
      );
    }
    if (questionSetProps.duplicateQuestionSetIds?.includes(this.props.questionSet.getId())) {
      actions.push(
        <Question.DuplicateSetIcon question={question} questionSet={this.props.questionSet} />
      );
    }
    return actions.length ? actions : null;
  }

  makeQuestion = (question, i, hasAccess, questionSetProps) => {
    const {
      attemptIndicatorValue,
      showAttemptIndicators,
      showDifficulty,
      isBookmarked,
      strikethroughQuestionInfo,
      onQuestionClick,
      questionPath,
      isNonMatchingSearchResult
    } = this.getQuestionProps(question);
    const {activeQuestion, isActiveRearrangeElement, isSorting} = this.props;
    let title = question.getTitle();
    const questionSetPosition = this.props.idx + 1;
    if (questionSetProps.obscureTitles) {
      title = questionSetPosition;
    }
    if (questionSetProps.viewingFromFolder) {
      // prepends the title with a null space and number when in folders
      // null space is to prevent markdownRenderer form making it an `ol`
      title = `&#8291;**${questionSetPosition}**. ${title}`;
    }
    return (
      <Question
        key={question.getId()}
        title={title}
        isActive={activeQuestion && activeQuestion.getId() === question.getId()}
        dragHandle={this.props.dragHandle}
        hasAccess={hasAccess}
        isBookmarked={isBookmarked}
        showDifficulty={showDifficulty}
        showAttemptIndicators={showAttemptIndicators}
        attemptIndicatorValue={attemptIndicatorValue}
        strikethroughQuestionInfo={strikethroughQuestionInfo}
        onQuestionClick={onQuestionClick}
        difficulty={question.getDifficultyLabel()}
        actions={this.getActions(questionSetProps, question)}
        question={question}
        questionPath={questionPath}
        isNonMatchingSearchResult={isNonMatchingSearchResult}
        isSelectable={questionSetProps.selectable}
        isSelected={questionSetProps.isSelected}
        isRearrangeable={questionSetProps.rearrangeable}
        isActiveRearrangeElement={isActiveRearrangeElement}
        clickable={!isSorting}
      />
    );
  };

  render() {
    const {questionSet, style, recentlyMoved} = this.props;
    const questionSetProps = this.getQuestionSetProps();
    // We process this function automatically in `getQuestionSetProps`, so it's been turned from a predicate
    // to a value at this point
    const hasAccess = questionSetProps.hasQuestionSetAccessPredicate;
    return (
      <div
        className={classnames('question-set-v2', {
          'sortable-element__moved': recentlyMoved
        })}
        style={style}
      >
        {this.props.hoverClickArea}
        <ul className='question-set-v2__questions-wrapper'>
          {questionSet
            .getQuestions()
            .map((question, i) => this.makeQuestion(question, i, hasAccess, questionSetProps))}
        </ul>
      </div>
    );
  }
}
