import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {TertiaryHeader} from 'generic/HorizontalNavigationComponent/HorizontalNavigationComponent.react';
import {navbarLinksPropType} from 'generic/HorizontalNavigationComponent/shared';

export const Navbar = ({className, navbarLinks}) => {
  return (
    <TertiaryHeader
      activeLinkFunction={(link) => {
        return process.env.IS_BROWSER && global.document.location.pathname === link.get('to');
      }}
      customWrapperClassName={classnames('hero-unit__navbar', className)}
      disableCommonContentModifier
      fixed={false}
      links={navbarLinks}
    />
  );
};

Navbar.propTypes = {
  className: PropTypes.string,
  navbarLinks: navbarLinksPropType
};
