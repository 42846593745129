import React from 'react';

import Icon, {IconProp} from '@albert-io/atomic/atoms/Icon/Icon.react';
import Badge, {BadgeStatuses} from '@albert-io/atomic/atoms/Badge/Badge.react';
import {Dropdown, DropdownItem, Text} from '@albert-io/atomic';

import {TranslatedFieldStatus} from '../QuestionEditorV2Store.types';

interface TranslationStatusDropdownProps {
  currentStatus: TranslatedFieldStatus;
  onChange?: (status: TranslatedFieldStatus) => void;
}

const TRANSLATION_STATUS_ICONS: Record<TranslatedFieldStatus, IconProp> = {
  draft: ['fal', 'edit'],
  published: ['fal', 'circle-check'],
  published_outdated: ['fal', 'circle-exclamation'],
  published_needs_review: ['fal', 'hand']
};

const TRANSLATION_STATUS_NAMES: Record<TranslatedFieldStatus, string> = {
  draft: 'Draft',
  published: 'Published',
  published_outdated: 'Outdated',
  published_needs_review: 'Needs Review'
};

const TRANSLATION_STATUS_COLORS: Record<TranslatedFieldStatus, BadgeStatuses> = {
  draft: 'info',
  published: 'positive',
  published_outdated: 'warning',
  published_needs_review: 'warning'
};

const USER_CHOOSABLE_STATUSES: TranslatedFieldStatus[] = [
  'published',
  'draft',
  'published_needs_review'
];

export const TranslationStatusDropdown = ({
  currentStatus,
  onChange
}: TranslationStatusDropdownProps) => {
  const trigger = (
    <Badge status={TRANSLATION_STATUS_COLORS[currentStatus]}>
      <Icon icon={TRANSLATION_STATUS_ICONS[currentStatus]} className='u-mar-r_1' />
      {TRANSLATION_STATUS_NAMES[currentStatus]}
      {onChange && <Icon icon='caret-down' className='u-mar-l_1' />}
    </Badge>
  );

  if (!onChange) {
    return trigger;
  }

  return (
    <Dropdown trigger={trigger} closeOnItemClicked positionFixed>
      <div className='u-pad_1'>
        <Text size='xs'>Set translation status:</Text>
      </div>
      {USER_CHOOSABLE_STATUSES.map((status) => (
        <DropdownItem onClick={() => onChange(status)} key={status}>
          <div className='u-display_flex u-justify-content_space-between u-align-items_center u-gap_space-x2'>
            <div className='u-display_flex u-align-items_center u-gap_space-x2'>
              <Icon icon='check' style={{opacity: currentStatus === status ? 1 : 0}} />
              <Text>{TRANSLATION_STATUS_NAMES[status]}</Text>
            </div>
            <Icon icon={TRANSLATION_STATUS_ICONS[status]} className='u-mar-l_1' />
          </div>
        </DropdownItem>
      ))}
      <div className='u-mar_1 u-pad-tb_1 u-pad-lr_2 u-text-align_center background-subtle u-border-radius_0-half'>
        Any other statuses are automatically set.
      </div>
    </Dropdown>
  );
};
