import React from 'react';
import PropTypes from 'prop-types';
import {addToast, Dialogue, Modal} from '@albert-io/atomic';

export default class ConfirmActionModal extends React.Component {
  static propTypes = {
    align: PropTypes.string,
    size: PropTypes.string,
    children: PropTypes.func,
    handleClose: PropTypes.func.isRequired,
    /** makeRequest must return a promise */
    makeRequest: PropTypes.func,
    onReject: PropTypes.func,
    onResolve: PropTypes.func,
    title: PropTypes.func,
    hideCloseBtn: PropTypes.bool
  };

  static defaultProps = {
    onReject: () => {},
    onResolve: () => {}
  };

  constructor() {
    super();

    this.state = {
      isRequestInflight: false
    };
  }

  onConfirm = () => {
    this.setState({isRequestInflight: true});
    this.props
      .makeRequest()
      .then((response) => {
        this.props.onResolve(response);
        this.setState({isRequestInflight: false});
        addToast({
          title: 'Success!',
          color: 'positive',
          message: `Your changes were saved successfully.`
        });
        this.props.handleClose();
      })
      .catch((error) => {
        this.setState({isRequestInflight: false});
        this.props.onReject(error);
        addToast({
          title: 'Oops! Something went wrong',
          color: 'negative',
          message: 'There was a problem saving your changes. If the problem persists, contact us.'
        });
      });
  };

  getStateAndHelpers = () => {
    return {
      ...this.state,
      onCancel: this.props.handleClose,
      onConfirm: this.onConfirm
    };
  };

  render() {
    const {align, handleClose, title, hideCloseBtn, size} = this.props;
    return (
      <Modal handleClose={handleClose} ariaLabel={title} size={size}>
        {({modalContentStyle}) => (
          <Dialogue
            alignTitle={align}
            className={modalContentStyle}
            handleClose={handleClose}
            inModal
            title={title}
            hideCloseBtn={hideCloseBtn}
            size={size}
          >
            {this.props.children(this.getStateAndHelpers())}
          </Dialogue>
        )}
      </Modal>
    );
  }
}
