import React from 'react';

import {
  QuestionComponent,
  QuestionComponentProps
} from '../shared/QuestionComponent/QuestionComponent.react';
import {Explanation} from '../shared/ContentBlocks';

import FillInTheBlankInputArea from './FillInTheBlankInputArea.react';
import FillInTheBlankShowAnswer from './FillInTheBlankShowAnswer.react';

const FillInTheBlankQuestionComponent = (props: QuestionComponentProps) => {
  const {activeQuestion} = props;
  const customPostGuessFragment = (
    <>
      <FillInTheBlankShowAnswer isGuessSubmitted question={activeQuestion} />
      <Explanation question={activeQuestion} />
    </>
  );
  return (
    <QuestionComponent
      {...props}
      customExplanation={customPostGuessFragment}
      ActiveQuestionInputArea={FillInTheBlankInputArea}
    />
  );
};

type FillInTheBlankQuestionTypeProps = Omit<QuestionComponentProps, 'questionState'>;

const FillInTheBlankQuestion = {
  PreGuess: (props: FillInTheBlankQuestionTypeProps) => (
    <FillInTheBlankQuestionComponent {...props} questionState='PreGuess' />
  ),
  PostGuess: (props: FillInTheBlankQuestionTypeProps) => (
    <FillInTheBlankQuestionComponent {...props} questionState='PostGuess' />
  )
};

export {FillInTheBlankQuestion};
