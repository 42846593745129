import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import makeDataProps from 'lib/makeDataProps';

import '../common/inputs.scss';
import './checkbox.scss';

export default class Checkbox extends React.Component {
  static propTypes = {
    defaultChecked: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    checked: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.node,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onKeyPress: PropTypes.func,
    value: PropTypes.string
  };

  static defaultProps = {
    className: '',
    disabled: false,
    name: '',
    onChange: () => {},
    onKeyPress: (e) => {
      if (e.key === 'Enter') {
        this.props.onChange(e);
      }
    },
    type: 'checkbox'
  };

  render() {
    const errorMessage = this.props.error ? (
      <span className='a-form-input__error'>{this.props.errorMessage}</span>
    ) : null;
    const checkboxClasses = classnames(this.props.className, {
      'a-form-checkbox': true,
      checked: true,
      'a-form-checkbox--disabled': this.props.disabled,
      'a-form-checkbox--has-error': this.props.error
    });
    const dataProps = makeDataProps(this.props);
    return (
      <label className={checkboxClasses}>
        <div className='a-form-checkbox-wrapper'>
          <input
            {...dataProps}
            checked={this.props.checked}
            defaultChecked={this.props.defaultChecked}
            disabled={this.props.disabled}
            tabIndex='0'
            type='checkbox'
            value={this.props.value}
            name={this.props.name}
            onChange={this.props.onChange}
            onKeyPress={this.props.onKeyPress}
          />
          <span className='checkbox-checkmark' />
        </div>
        <div className='a-form-checkbox-label-wrapper'>
          <span className='a-form-input__label'>{this.props.label}</span>
          {errorMessage}
        </div>
      </label>
    );
  }
}
