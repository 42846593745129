import React from 'react';
import classnames from 'classnames';

import Text from '../../../atoms/Text/Text.react';
import './cell.scss';

interface Props extends PropsWithChildrenRequired, PropsWithClassNameOptional {
  align?: string;
  primary?: boolean;
  subValue?: string;
  contentType?: 'text' | 'graphic';
}

const Cell = ({
  align,
  children,
  className,
  primary,
  subValue,
  contentType = 'text',
  ...rest
}: Props) => {
  return (
    <td
      className={classnames('o-table__cell', 'u-display_flex', className, {
        [`o-table__cell--${align}`]: align,
        'o-table__cell--primary': primary
      })}
      {...rest}
    >
      {contentType === 'text' ? (
        <Text className='o-table__cell-text' size='s'>
          {children}
        </Text>
      ) : (
        children
      )}
      {subValue && (
        <Text as='div' className='o-table__sub-value' color='tertiary' size='xs'>
          {subValue}
        </Text>
      )}
    </td>
  );
};

export default Cell;
