import React, {ReactElement} from 'react';
import classnames from 'classnames';
import {Curve} from '@albert-io/atomic';

import {BackButton, Props as BackButtonProps} from './BackButton/BackButton.react';
import {Description, Props as DescriptionProps} from './Description/Description.react';
import {Shelf, Props as ShelfProps} from './Shelf/Shelf.react';
import {Supertitle, Props as SupertitleProps} from './Supertitle/Supertitle.react';
import {Title, Props as TitleProps} from './Title/Title.react';
import './wall.scss';

type CurveDepthOption = 'xs' | 's' | 'm' | 'l' | 'xl';

interface Props extends PropsWithClassNameOptional {
  backButton?: ReactElement<BackButtonProps>;
  backgroundColor?: string;
  curveDepth?: CurveDepthOption;
  description?: ReactElement<DescriptionProps>;
  imgHref?: string;
  shelf?: ReactElement<ShelfProps>;
  supertitle?: ReactElement<SupertitleProps>;
  title?: ReactElement<TitleProps>;
}

const Wall = ({
  backButton,
  backgroundColor,
  className,
  curveDepth = 'l',
  description,
  imgHref,
  shelf,
  supertitle,
  title
}: Props) => (
  <div className={classnames('o-wall', className)}>
    <Curve
      className='o-wall__curve'
      paddingLevel={5}
      depth={curveDepth}
      backgroundColor={backgroundColor}
    >
      {backButton ?? null}
      {imgHref !== undefined ? (
        <div
          className='o-wall__img'
          style={{
            backgroundImage: `url(${imgHref})`
          }}
        />
      ) : null}
      <div className='o-wall__info-wrapper'>
        {supertitle ?? null}
        {title ?? null}
        {description ?? null}
      </div>
    </Curve>
    {shelf ?? null}
  </div>
);

Wall.BackButton = BackButton;
Wall.Shelf = Shelf;
Wall.BackButton = BackButton;
Wall.Supertitle = Supertitle;
Wall.Title = Title;
Wall.Description = Description;

export default Wall;
