import React from 'react';
import PropTypes from 'prop-types';
import {Map} from 'immutable';
import {AuthoringSubjectModelV1} from 'resources/augmented/AuthoringSubject/AuthoringSubjectModel.v1';

export const SupplementSubjectDropdownTemplate = ({item}) => {
  return (
    <div className='subject-option-template'>
      <small>{item.getDomain()}</small>
      <br />
      {item.getName()}
    </div>
  );
};

SupplementSubjectDropdownTemplate.propTypes = {
  item: PropTypes.oneOfType([
    PropTypes.instanceOf(AuthoringSubjectModelV1),
    PropTypes.instanceOf(Map)
  ])
};
