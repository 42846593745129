// @flow
import {fromJS} from 'immutable';
import {Store} from 'client/framework';
import titleSearchActions from './TitleSearch.actions';

class TitleSearchStore extends Store {
  constructor(name: string) {
    super(name);
    this.setInitialData(
      fromJS({
        searchString: ''
      })
    );
    this.handle(titleSearchActions.SET_SEARCH_STRING, this.setProperty('searchString'));
    this.handle(titleSearchActions.RESET_STORE, this.resetStore);
  }

  getSearchString = (): string => this.readData('searchString');
}

export default new TitleSearchStore('TitleSearchStore');
