import React from 'react';
import {List, Map} from 'immutable';

import {setUpStore, callTargetedAction} from 'client/framework';
// eslint-disable-next-line import/named
import {SALUTATIONS} from 'resources/augmented/User/user.extensions';
import TextInput from 'generic/Forms/TextInput/TextInput';
import {Button} from '@albert-io/atomic';
import Select from 'generic/Forms/Select/Select.react';
import SchoolSearchGroupedInputs from 'sg/Dropdowns/SchoolSearchGroupedInputs/SchoolSearchGroupedInputs.react';
import notifier from '@albert-io/notifier';
import {onboardingPropTypes} from 'client/Onboarding/ForceOnboarding.react';
// eslint-disable-next-line import/named
import {completeAccountCompletion} from 'lib/OnboardingUtil/OnboardingUtil';
import errorCodes from 'client/errorCodes';
import ErrorStateStore from 'client/generic/Forms/ErrorState/ErrorState.store';
import formActions from 'client/generic/Forms/ErrorState/Form.actions';

import './assets/update-account.scss';

const errorStateStoreName = 'UpdateAccountErrorStateStore';

const startsWithUUID = new RegExp('^[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{2}');

export default class UpdateAccount extends React.Component {
  static propTypes = onboardingPropTypes;

  constructor(props) {
    super(props);

    this.state = {
      user: props.user,
      /**
       * @todo: storing the school here is done due to issues setting/removing the
       * school from a model. The generic model methods for adding/removing/updating
       * relationships will need some fixes before this can be done entirely thru a model
       *
       * As a side effect of this, we need to re-define a school validator for use in this
       * component that differs from the model's own implementation. We also need to handle the
       * setting/removing/replacing ourselves on save
       */
      school: props.user.hasSchool() ? props.user.getSchool().getId() : null,
      /**
       * This field is used to indicate whether or not a user coming into this onboarding
       * step already has a school associated. If a user already has a school associated,
       * they should not be able to complete the form without a school. They should be allowed
       * to change their association, but not remove (fully) the association to _a_ school resource.
       */
      hasIncomingSchool: props.user.hasSchool(),
      visitedFields: {
        salutation: false,
        firstName: false,
        lastName: false,
        username: false,
        school: false
      },
      schoolSearchMounted: false
    };
  }

  getErrorStateStore() {
    return setUpStore(ErrorStateStore, errorStateStoreName);
  }

  hasFormErrors(user) {
    const validators = List([
      user.validators.getSalutation,
      user.validators.getFirstName,
      user.validators.getLastName,
      /**
       * Username is only required for social users. The sign up form itself
       * includes and requires a username field, but any users going the social
       * route obviously skip that form and need to rely on this form to
       * complete the required sign up fields.
       */
      this.state.user.isSocialUser ? user.validators.getUsername : () => null
    ]);

    const errorList = validators.reduce((acc, validator) => {
      return acc.concat(validator());
    }, List());

    /**
     * if anything is non-null, we've got an error in the form
     */
    return (
      errorList.some((value) => {
        return value !== null;
      }) || this.hasSchoolError()
    );
  }

  async updateUserInfo() {
    if (this.hasFormErrors(this.state.user)) {
      return;
    }

    if (!this.getErrorStateStore().getFormErrors().isEmpty()) {
      callTargetedAction({
        name: formActions.CLEAR_FORM_ERRORS,
        targetStore: errorStateStoreName
      });
    }

    let userUnderEdit;
    try {
      userUnderEdit = completeAccountCompletion(this.state.user);

      /**
       * @todo: see constructor note on why this block is here
       */
      // If the school input has a value
      if (this.state.school) {
        // if the user being edited has no school, we add one
        if (!this.state.user.hasSchool()) {
          userUnderEdit = userUnderEdit.addRelationship({
            type: 'school_v4',
            relation: this.state.school
          });
          // if the user being edited already has a school, we replace it
        } else if (this.state.user.hasSchool()) {
          userUnderEdit = userUnderEdit.replaceRelationship({
            type: 'school_v4',
            relation: this.state.school
          });
        }
      }

      await Promise.all([userUnderEdit.save()]);

      this.props.onComplete();
    } catch (e) {
      if (e.status === 400) {
        const {errors} = e.response.body;
        /**
         * Below we're looking for a unique constraint violation only in the username field.
         * In the present state of the UpdateAccount form, this is the only field a possible
         * unique constraint violation could be attached to.
         */
        const constraintViolations = errors
          .reduce((acc, error) => {
            if (error.code === errorCodes.BAD_REQUEST.VALIDATION_ERROR.UNIQUE_CONSTRAINT_VIOLATED) {
              return acc.push(error);
            }

            return acc;
          }, List())
          .reduce((acc, error) => {
            if (error.source.parameter === 'username') {
              return acc.push(
                Map({
                  field: 'username',
                  message: 'That username is already in use.'
                })
              );
            }
            return acc;
          }, List());

        callTargetedAction({
          name: formActions.SET_FORM_ERRORS,
          payload: constraintViolations,
          targetStore: errorStateStoreName
        });
      } else {
        /**
         * If it's an _unhandled_ error, send it
         */
        notifier.notify(e, {
          component: 'UpdateAccount'
        });
      }
    }
  }

  getSchoolInput() {
    const {visitedFields, user, school} = this.state;
    return (
      <div className='a-form-row'>
        <SchoolSearchGroupedInputs
          required={user.isTeacher()}
          countryDropdownStoreName='UpdateAccountCountrySelectorStoreName'
          schoolSearchDropdownStoreName='UpdateAccountSchoolSearchStoreName'
          defaultSelectedId={school}
          onClick={this.onSchoolClick}
          label='School'
          name='school'
          error={visitedFields.school && this.hasSchoolError()}
          onBlur={() => {
            if (!this.state.schoolSearchMounted) {
              this.setState({schoolSearchMounted: true});
            } else {
              this.markFieldAsVisited({target: {name: 'school'}});
            }
          }}
          errorMessage={this.getSchoolError()}
        />
      </div>
    );
  }

  getSalutationInput() {
    const {user, visitedFields} = this.state;
    this.hasError('getSalutation');
    return user.isTeacher() ? (
      <div className='a-form-col'>
        <Select
          name='salutation'
          label='Title'
          autoComplete='honorific-prefix'
          required
          value={user.getSalutation()}
          error={visitedFields.salutation && this.hasError('getSalutation')}
          errorMessage={this.getError('getSalutation')}
          onFocusValidation
          onChange={this.setSalutation}
          onBlur={this.markFieldAsVisited}
        >
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <option value='' selected disabled />
          {SALUTATIONS.map((salutation) => (
            <option key={salutation} value={salutation}>
              {salutation}
            </option>
          ))}
        </Select>
      </div>
    ) : null;
  }

  makeOnChangeForField = (fieldName) => {
    return (e) => {
      if (fieldName === 'username' && this.getErrorStateStore().getFormErrorForField(fieldName)) {
        callTargetedAction({
          name: formActions.CLEAR_FIELD_ERROR,
          targetStore: errorStateStoreName,
          payload: fieldName
        });
      }

      const {value} = e.target;
      this.setState((state) => ({
        user: state.user[fieldName](value)
      }));
    };
  };

  markFieldAsVisited = (e) => {
    const fieldName = e.target.name;
    if (this.state.visitedFields[fieldName]) {
      return;
    }
    this.setState((state) => ({
      visitedFields: {
        ...state.visitedFields,
        [fieldName]: true
      }
    }));
  };

  hasSchoolError() {
    /**
     * If the user (teacher OR student) drops the school they came to the update account form with, we
     * signal an error. They can _change_ the association but cannot remove it.
     */
    if (this.state.hasIncomingSchool && !this.state.school) {
      return true;
    }

    /**
     * Students are not required to have a school - teachers are required to have a
     * school association.
     */
    return this.state.user.isTeacher() && !this.state.school;
  }

  getSchoolError() {
    if (this.hasSchoolError()) {
      return 'School is a required field';
    }
    return null;
  }

  hasError = (validatorName) => {
    return this.state.user.validators[validatorName]() !== null;
  };

  getError = (validatorName) => {
    if (this.hasError(validatorName)) {
      return this.state.user.validators[validatorName]().first();
    }
    return null;
  };

  setSalutation = this.makeOnChangeForField('setSalutation');

  setFirstName = this.makeOnChangeForField('setFirstName');

  setLastName = this.makeOnChangeForField('setLastName');

  setUsername = this.makeOnChangeForField('setUsername');

  onSchoolClick = (schoolObject) => {
    this.setState({
      school: schoolObject ? schoolObject.getId() : null
    });
  };

  render() {
    const usernameError = this.getErrorStateStore().getFormErrorForField('username');
    const {user, visitedFields} = this.state;
    return (
      <div className='update-account-wrapper'>
        <div className='update-account-content'>
          <div className='a-form-wrapper update-account-form-wrapper'>
            <form className='a-form'>
              <div className='a-form-row'>
                {this.getSalutationInput()}
                <div className='a-form-col'>
                  <TextInput
                    name='firstName'
                    label='First Name'
                    type='text'
                    autoComplete='given-name'
                    required
                    maxLength={128}
                    value={user.getFirstName()}
                    error={visitedFields.firstName && this.hasError('getFirstName')}
                    errorMessage={this.getError('getFirstName')}
                    onFocusValidation
                    onChange={this.setFirstName}
                    onBlur={this.markFieldAsVisited}
                    onKeyDown={this.markFieldAsVisited}
                  />
                </div>
                <div className='a-form-col'>
                  <TextInput
                    name='lastName'
                    label='Last Name (or School ID)'
                    type='text'
                    autoComplete='family-name'
                    required
                    maxLength={128}
                    value={user.getLastName()}
                    error={visitedFields.lastName && this.hasError('getLastName')}
                    errorMessage={this.getError('getLastName')}
                    onFocusValidation
                    onChange={this.setLastName}
                    onBlur={this.markFieldAsVisited}
                    onKeyDown={this.markFieldAsVisited}
                  />
                </div>
              </div>
              {user.isSocialUser && (
                <div className='a-form-row'>
                  <div className='a-form-col'>
                    <TextInput
                      name='username'
                      label='Username'
                      type='text'
                      required
                      maxLength={16}
                      value={startsWithUUID.test(user.getUsername()) ? '' : user.getUsername()}
                      error={
                        Boolean(usernameError) ||
                        (visitedFields.username && this.hasError('getUsername'))
                      }
                      errorMessage={
                        (usernameError ? usernameError.get('message') : null) ||
                        this.getError('getUsername')
                      }
                      onFocusValidation
                      onChange={this.setUsername}
                      onBlur={this.markFieldAsVisited}
                      onKeyDown={this.markFieldAsVisited}
                    />
                  </div>
                </div>
              )}
              {this.getSchoolInput()}
              {user.isTeacher() && (
                <div className='a-form-row'>
                  <span>Can&apos;t find your school? </span>
                  <a
                    className='a-form-link update-account-form-link--no-school'
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://albert-licenses.typeform.com/to/QtQ9gHSv'
                  >
                    Send us your school&apos;s name and address.
                  </a>
                </div>
              )}

              <div className='a-form-row'>
                <Button
                  className='u-width_100pc'
                  color='primary'
                  type='submit'
                  disabled={this.hasFormErrors(user)}
                  onClick={(e) => {
                    e.preventDefault();
                    this.updateUserInfo();
                  }}
                >
                  Update my account
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
