import React from 'react';
import {Button, Card, Text} from '@albert-io/atomic';
import {callAction} from 'client/framework';
import classnames from 'classnames';
import constants from 'client/constants';
import FeatureFlag from 'client/components/FeatureFlag/FeatureFlag.react';
import OldButton from 'sg/Button/Button.react';

import {QuestionEditorIcon} from '../QuestionEditorIcon/QuestionEditorIcon';

import questionEditorActions from './QuestionEditor.actions';

interface Props {
  className?: string;
  difficulty: number;
  error?: boolean;
  errorMessage?: string;
}

const DifficultyEditor = ({className, difficulty, error, errorMessage}: Props) => {
  const changeDifficulty = (d: number) => {
    callAction(questionEditorActions.UPDATE_QUESTION_DETAILS, {
      setter: 'setDifficulty',
      value: d
    });
  };

  return (
    <FeatureFlag name='question_editor_v2'>
      <DifficultyEditorV2Presentation
        className={className}
        difficulty={difficulty}
        changeDifficulty={changeDifficulty}
        error={error}
        errorMessage={errorMessage}
      />

      <FeatureFlag.Fallback>
        <DifficultyEditorV1Presentation
          className={className}
          difficulty={difficulty}
          changeDifficulty={changeDifficulty}
          error={error}
          errorMessage={errorMessage}
        />
      </FeatureFlag.Fallback>
    </FeatureFlag>
  );
};

interface DifficultyEditorPresentationProps {
  className?: string;
  difficulty: number;
  error?: boolean;
  errorMessage?: string;
  changeDifficulty: (d: number) => void;
}

const DifficultyEditorV2Presentation = ({
  className,
  difficulty,
  error,
  errorMessage,
  changeDifficulty
}: DifficultyEditorPresentationProps) => {
  return (
    <Card paddingLevel={3} className={className}>
      <div className='u-display_flex u-align-items_center u-gap_space-x2 u-justify-content_space-between'>
        <div className='u-display_flex u-align-items_center u-gap_space-x4'>
          <QuestionEditorIcon icon={['fal', 'dumbbell']} />
          <Text bold>Difficulty</Text>
        </div>

        <div className='u-align-items_center u-display_flex u-flex-wrap_wrap u-gap_space-x1'>
          {(
            [
              [1, 'Easy'],
              [2, 'Moderate'],
              [3, 'Difficult']
            ] as const
          ).map(([buttonDifficulty, text]) => {
            return (
              <Button
                onClick={() => changeDifficulty(buttonDifficulty)}
                key={buttonDifficulty}
                color={difficulty === buttonDifficulty ? 'primary' : 'secondary'}
                variant={difficulty === buttonDifficulty ? 'faded' : 'text'}
              >
                {text}
              </Button>
            );
          })}
        </div>
      </div>

      {error && <div className='a-form-input__error'>{errorMessage}</div>}
    </Card>
  );
};

const DifficultyEditorV1Presentation = ({
  className,
  difficulty,
  changeDifficulty,
  error,
  errorMessage
}: DifficultyEditorPresentationProps) => {
  const easyValue = constants.QUESTION_IMPORT.DIFFICULTIES.Easy;
  const moderateValue = constants.QUESTION_IMPORT.DIFFICULTIES.Moderate;
  const difficultValue = constants.QUESTION_IMPORT.DIFFICULTIES.Difficult;
  const easyDifficultySelect = classnames('question-editor__difficulty-select-option', 'easy', {
    selected: difficulty === easyValue
  });
  const moderateDifficultySelect = classnames(
    'question-editor__difficulty-select-option',
    'moderate',
    {
      selected: difficulty === moderateValue
    }
  );
  const hardDifficultySelect = classnames('question-editor__difficulty-select-option', 'hard', {
    selected: difficulty === difficultValue
  });
  const difficultySelectorClassnames = classnames('question-editor__difficulty-select', className);
  return (
    <div className={difficultySelectorClassnames}>
      <div className='a-form-input__label'>Difficulty</div>
      <OldButton className={easyDifficultySelect} onClick={() => changeDifficulty(easyValue)}>
        <span className='fa fa-star' />
        <br />
        <span className='label'>Easy</span>
      </OldButton>
      <OldButton
        className={moderateDifficultySelect}
        onClick={() => changeDifficulty(moderateValue)}
      >
        <span className='fa fa-star' />
        <span className='fa fa-star' />
        <span className='fa fa-star' />
        <br />
        <span className='label'>Moderate</span>
      </OldButton>
      <OldButton className={hardDifficultySelect} onClick={() => changeDifficulty(difficultValue)}>
        <span className='fa fa-star' />
        <span className='fa fa-star' />
        <span className='fa fa-star' />
        <span className='fa fa-star' />
        <span className='fa fa-star' />
        <br />
        <span className='label'>Difficult</span>
      </OldButton>
      <div className='a-form-input__error'>{error ? errorMessage : null}</div>
    </div>
  );
};

export default DifficultyEditor;
