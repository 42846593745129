import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

export const navbarLinksPropType = ImmutablePropTypes.listOf(
  ImmutablePropTypes.mapContains({
    iconBackgroundImage: PropTypes.string,
    name: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired
  })
);
