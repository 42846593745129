import React from 'react';

import {
  QuestionComponent,
  QuestionComponentProps
} from '../shared/QuestionComponent/QuestionComponent.react';

import PassageCorrectionExplanation from './PassageCorrectionExplanation.react';
import PassageCorrectionInputArea from './PassageCorrectionInputArea.react';

const PassageCorrectionQuestionComponent = (props: QuestionComponentProps) => {
  const {activeQuestion} = props;
  return (
    <QuestionComponent
      {...props}
      customExplanation={<PassageCorrectionExplanation question={activeQuestion} />}
      ActiveQuestionInputArea={PassageCorrectionInputArea}
    />
  );
};

type PassageCorrectionQuestionTypeProps = Omit<QuestionComponentProps, 'questionState'>;

const PassageCorrectionQuestion = {
  PreGuess: (props: PassageCorrectionQuestionTypeProps) => (
    <PassageCorrectionQuestionComponent {...props} questionState='PreGuess' />
  ),
  PostGuess: (props: PassageCorrectionQuestionTypeProps) => (
    <PassageCorrectionQuestionComponent {...props} questionState='PostGuess' />
  )
};

export {PassageCorrectionQuestion};
