import React from 'react';
import {fromJS} from 'immutable';
import SimpleDropdown from 'generic/SimpleDropdown/SimpleDropdown.react';
import {
  SEARCH_PARAMETERS,
  getURLSearchParameter,
  setURLSearchParameter
} from '../URLSearchParameters';
import {getStoreByName} from 'client/framework';

import './sort-by.scss';

const options = fromJS([
  {
    name: 'Name',
    value: 'name',
    id: 0
  },
  {
    name: 'Difficulty',
    value: 'difficulty',
    id: 1
  }
]);

const directions = fromJS([
  {
    name: 'Ascending',
    value: '',
    id: 0
  },
  {
    name: 'Descending',
    value: '-',
    id: 1
  }
]);

export class SortBy extends React.Component {
  directionStoreName = 'QueueFacet-SortByDirection';
  propertyStoreName = 'QueueFacet-SortByProperty';

  handleChange = () => {
    setURLSearchParameter(
      SEARCH_PARAMETERS.sort,
      getStoreByName(this.directionStoreName)
        .getSelectedItem()
        .get('value') +
        getStoreByName(this.propertyStoreName)
          .getSelectedItem()
          .get('value')
    );
  };

  render() {
    let direction = '';
    let parameter = getURLSearchParameter(SEARCH_PARAMETERS.sort) || 'name';
    if (parameter.startsWith('-')) {
      direction = '-';
      parameter = parameter.slice(1);
    }

    return (
      <div className='queue-search-panel__sort-by'>
        <b>Sort by:</b>
        <div className='queue-search-panel__sort-by-options'>
          <SimpleDropdown
            fullWidth
            activeItemId={options.find((o) => o.get('value') === parameter).get('id')}
            className='queue-search-panel__facet queue-search-panel__facet--sort-by'
            options={options}
            onChange={this.handleChange}
            storeName={this.propertyStoreName}
          />
          <SimpleDropdown
            fullWidth
            activeItemId={directions.find((o) => o.get('value') === direction).get('id')}
            className='queue-search-panel__facet queue-search-panel__facet--sort-by'
            options={directions}
            onChange={this.handleChange}
            storeName={this.directionStoreName}
          />
        </div>
      </div>
    );
  }
}
