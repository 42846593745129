import React, {RefObject} from 'react';
import {Text} from '@albert-io/atomic';
import classNames from 'classnames';
import './audio-player.scss';

interface AudioTrackProps {
  duration: number;
  timestamp: number;
  seekAudio: (any) => void;
  isPlaying: boolean;
  trackRef: RefObject<HTMLInputElement>;
}

const AudioTrack = ({duration, timestamp, seekAudio, isPlaying, trackRef}: AudioTrackProps) => {
  const formatTime = (seconds: number) => {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };

  return (
    <div className='audio-player__track-wrapper'>
      <input
        type='range'
        min='0'
        max={duration}
        value={timestamp}
        onChange={seekAudio}
        className={classNames('audio-player__track', {
          'audio-player__track--playing': isPlaying
        })}
        ref={trackRef}
      />
      <div className='audio-player__timestamp-wrapper'>
        <Text className='u-flex_1' size='s'>
          {formatTime(timestamp)}
        </Text>
        <Text className='u-flex_1 u-text-align_right' size='s'>
          {formatTime(duration)}
        </Text>
      </div>
    </div>
  );
};

export default AudioTrack;
