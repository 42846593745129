/**
 * # Radio
 *
 * A custom radio button component.
 *
 * ## Props
 *
 * - `className?: string`: Additional CSS class name for the component.
 * - `checked?: boolean`: Whether the radio button is checked.
 * - `color?: 'blue' | 'purple'`: The color of the radio button. Defaults to 'blue'.
 * - `disabled?: boolean`: Whether the radio button is disabled.
 * - `name: string`: The name of the radio button.
 * - `style?: React.CSSProperties`: Inline style object for the component.
 * - `...rest: React.InputHTMLAttributes<HTMLInputElement>`: Additional HTML attributes for the input element.
 *
 * ## Usage
 *
 * ```tsx
 * import React from 'react';
 * import Radio from './Radio';
 *
 * const Example = () => {
 *   return (
 *     <Radio
 *       className="custom-radio"
 *       checked={true}
 *       color="purple"
 *       disabled={false}
 *       name="example"
 *       style={{ marginTop: '10px' }}
 *       onChange={(e) => console.log(e.target.checked)}
 *     />
 *   );
 * };
 *
 * export default Example;
 * ```
 */

import React from 'react';
import classnames from 'classnames';

import Icon from '../Icon/Icon.react';
import './radio.scss';

interface Props extends PropsWithClassNameOptional, React.InputHTMLAttributes<HTMLInputElement> {
  checked?: boolean;
  color?: 'blue' | 'purple';
  disabled?: boolean;
  name: string;
}

export const Radio = ({
  className,
  checked,
  color = 'blue',
  disabled = false,
  name,
  style,
  ...rest
}: Props) => {
  return (
    <span
      className={classnames(className, `a-radio a-radio--color-${color}`, {
        'a-radio--disabled': disabled
      })}
    >
      <input
        className='a-radio__input'
        type='radio'
        name={name}
        disabled={disabled}
        checked={checked}
        {...rest}
      />
      <span className='a-radio__display a-radio__display'>
        <Icon size='standalone' className='a-radio__icon' icon='circle' />
      </span>
    </span>
  );
};

export default Radio;
