import React from 'react';
import PropTypes from 'prop-types';
import {SortableHandle} from 'react-sortable-hoc';
import classnames from 'classnames';

import './drag-handle.scss';

export const DragHandle = ({className}) => {
  const classNames = classnames('drag-handle', className);
  return <div className={classNames} role='button' tabIndex='0' />;
};

DragHandle.propTypes = {
  className: PropTypes.string
};

export const SortableDragHandle = SortableHandle(({className}) => (
  <DragHandle className={className} />
));
