import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import awaitMandarkQueries from 'lib/hocs/awaitMandarkQueries';
import {withApplicationMetadata} from 'client/Routing/withApplicationMetadata';
import {StudyGuideItemList} from 'client/generic/StudyGuideItem/StudyGuideItem.react';
import {
  canCreateTemplateForSubject,
  getSubjectSubscriptionQuery,
  getDoesUserBelongToAnyLicensedClassroomsQuery
} from 'lib/UserAccessUtil';
import {GuideModelV1} from 'resources/augmented/Guide/GuideModel.v1';
import {SubjectModelV2} from 'resources/GeneratedModels/Subject/SubjectModel.v2';
import {getVersionAgnosticResourceType} from 'resources/mandark.resource';
import sessionStore from 'client/Session/SessionStore';
import UpgradeSubjectModal from 'components/PracticeView/UpgradeSubjectModal/UpgradeSubjectModal.react';
import deferComponentRender from 'lib/hocs/deferComponentRender';

import FRQGuideLevelItem from './FRQGuideLevelItem/FRQGuideLevelItem.react';
import FRQQuestionItem from './FRQQuestionItem/FRQQuestionItem.react';
import frqGuideUtils from './utils';
import {showPublicViewForSubject} from 'components/PracticeView/practiceView.utils';

import '../free-response-question-guide.scss';

class __FreeResponseQuestionGuide extends React.Component {
  static propTypes = {
    guide: PropTypes.instanceOf(GuideModelV1),
    classrooms: ImmutablePropTypes.list,
    subject: PropTypes.instanceOf(SubjectModelV2)
  };

  constructor() {
    super();
    this.state = {showUpgradeSubjectModal: false};
  }

  componentDidMount() {
    const {classrooms, subject} = this.props;
    if (
      sessionStore.hasValidSession() &&
      sessionStore.isStudent() &&
      !classrooms.isEmpty() &&
      !subject.doesUserHaveAccess()
    ) {
      this.setState({showUpgradeSubjectModal: true});
    }
  }

  isItemGuideLevel(item) {
    return (
      typeof item.getJsonApiType === 'function' &&
      getVersionAgnosticResourceType(item.getJsonApiType()) === 'guide_level'
    );
  }

  getContent() {
    const usePublicView = showPublicViewForSubject(this.props.subject);
    const {isReady, value: canAssign} = canCreateTemplateForSubject(
      this.props.guide.getSubject().getId()
    );
    const {guide} = this.props;
    const idsOfLastQuestionsOfThemes = frqGuideUtils.getIdsOfLastQuestionsOfThemes(
      guide,
      usePublicView
    );
    return frqGuideUtils.getCachedFreeResponseGuideItems(guide, isReady, (frqGuide) =>
      frqGuide.getSortedFRQItems(usePublicView).map((item, i) => {
        if (this.isItemGuideLevel(item)) {
          const questionSets = item.getQuestionSets();
          if (questionSets.isEmpty()) {
            return null;
          }
          return (
            <FRQGuideLevelItem
              className='frq-item--guide-level'
              guideLevel={item}
              hasAssignAccess={canAssign}
              key={isReady ? i : item.getId()}
            />
          );
        }
        /**
         * This next block prevents us from applying the selector to a question
         * that appears last in one guide level but is duplicately aligned to another.
         * See https://github.com/albert-io/project-management/issues/1500
         */
        const nextItem = guide.getSortedFRQItems(usePublicView).get(i + 1);
        const isLastOfTheme =
          idsOfLastQuestionsOfThemes.includes(item.getId()) &&
          nextItem &&
          this.isItemGuideLevel(nextItem) &&
          nextItem.isTheme();
        const guideLevel = guide
          .getGuideLevels()
          .getCollection()
          .find((frqGuideLevel) => {
            return frqGuideLevel.getQuestionSets().find((set) => {
              const questions = usePublicView ? set.getPublicQuestions() : set.getQuestions();
              return questions.includes(item);
            });
          });
        const questionSet = guideLevel.getQuestionSets().find((set) => {
          const questions = usePublicView ? set.getPublicQuestions() : set.getQuestions();
          return questions.includes(item);
        });

        let questionPath = `/${this.props.guide
          .getSubject()
          .getUrlSlug()}/free-response/${questionSet.getURLSlugPart()}`;
        /**
         * if there are multiple free response questions in the set, we'll need the question slug too...
         */
        const questionsInSet = usePublicView
          ? questionSet.getPublicQuestions()
          : questionSet.getQuestions();
        if (questionsInSet.size > 1) {
          questionPath += `/${item.getTitleSlug()}`;
        }
        /**
         * We add **2** here to account for the `<HeroUnit>` containing a `h1` and levels starting at `h2`.
         */
        const headingLevel = guideLevel.getNlevel() + 2;
        return (
          <FRQQuestionItem
            className={isLastOfTheme ? 'sgi--last-of-theme' : null}
            question={item}
            questionSet={questionSet}
            to={questionPath}
            questionSetIds={[questionSet.getId()]}
            hasAssignAccess={canAssign}
            guideLevel={guideLevel}
            key={i}
            heading={`h${headingLevel}`}
            usePublicView={usePublicView}
          />
        );
      })
    );
  }

  render() {
    const {subject} = this.props;
    return (
      <>
        {!this.props.guide.getGuideLevels().getCollection().isEmpty() && (
          <div className='free-response-question-guide'>
            <StudyGuideItemList>{this.getContent()}</StudyGuideItemList>
          </div>
        )}
        {this.state.showUpgradeSubjectModal && (
          <UpgradeSubjectModal
            subject={subject}
            handleClose={() => this.setState({showUpgradeSubjectModal: false})}
          />
        )}
      </>
    );
  }
}

const FreeResponseQuestionGuide = awaitMandarkQueries(
  ({subject}) => {
    const usePublicView = showPublicViewForSubject(subject);
    const queries = {
      guide: frqGuideUtils.getFrqGuideQuery({subjectId: subject.getId(), usePublicView}),
      classrooms: getDoesUserBelongToAnyLicensedClassroomsQuery()
    };
    if (sessionStore.hasValidSession()) {
      queries.subscriptions = getSubjectSubscriptionQuery(subject.getId());
    }
    return {queries};
  },
  withApplicationMetadata(
    deferComponentRender(__FreeResponseQuestionGuide),
    /**
     * Provide a custom `getMetadataFromProps` method for the FRQ view.
     *
     * @param props
     * @see https://github.com/albert-io/project-management/issues/1332
     * @see https://github.com/albert-io/project-management/issues/3131
     */
    (props) => {
      const subjectName = props.guide.getSubject().getName();
      return {
        title: `${subjectName} | Free Response Questions | Albert`,
        metaDescription: props.guide.getSubject().isAPSubject()
          ? /* eslint-disable max-len */
            `Original free-response prompts for ${subjectName} that mimic the questions found on the real exam. Our expert authors also provide an exemplary response for each AP free response question so students can better understand what AP graders look for.`
          : `Original free-response prompts for ${subjectName} that stimulate critical thinking through open-ended exploration and reasoning.`
        /* eslint-enable */
      };
    }
  )
);

export default FreeResponseQuestionGuide;
