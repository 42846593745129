/* eslint-disable import/prefer-default-export */
export const schoolExtensions = {
  /**
   * Combines all of the address "parts" into a single string for display.
   *
   * @returns {string} The "full" address for the model.
   */
  getFullAddress() {
    const parts = [
      this.getAddressLine1(),
      this.getAddressLine2(),
      this.getCity(),
      this.getState(),
      this.getPostalCode()
    ];
    /**
     * Since most international school entries will not have full addresses, we add the country.
     */
    if (this.getCountry() && this.getCountry() !== 'United States of America') {
      parts.push(this.getCountry());
    }

    return parts
      .reduce((reduction, v) => {
        if (v) {
          reduction.push(v);
        }
        return reduction;
      }, [])
      .join(', ');
  }
};
