import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {v4 as uuid} from 'uuid';
import {Link} from 'react-router';
import {omit} from 'lodash';
import {callTargetedAction} from 'client/framework';
import tooltipActions from '../Tooltip/Tooltip.actions';
import Tooltip from '../Tooltip/Tooltip.react';
import makeDataProps from 'lib/makeDataProps';
import {AppPortal} from 'client/Portals/AppPortal/AppPortal.react';
import './sg-button.scss';

/**
 * Usage:
 *
 * <Button text='Click me' />
 *
 * or
 *
 * <Button>
 * Click me
 * </Button>
 *
 * Same as a normal buttons, if using the latter option, you can add other elements
 * in there, such as fontawesome <span>s.
 *
 * If you pass a `disabledTooltipContent` prop to the button, your button will show
 * a tooltip on-hover, and the contents of the tooltip will be whatever you pass to
 * `disabledTooltipContent`.
 */

export default class Button extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
    className: PropTypes.string,
    // Blue is the default, it doesn't need to be passed, but a few places do it so we'll
    // add it to suppress the console error
    color: PropTypes.oneOf(['gray', 'green', 'purple', 'red', 'translucent', 'white', 'blue']),
    disabled: PropTypes.bool,
    disabledTooltipContent: Tooltip.propTypes.children,
    icon: PropTypes.string,
    whiteBg: PropTypes.bool,
    large: PropTypes.bool,
    linkButton: PropTypes.bool,
    noBorder: PropTypes.bool,
    noPadding: PropTypes.bool,
    outline: PropTypes.bool,
    small: PropTypes.bool,
    text: PropTypes.string,
    textButton: PropTypes.bool,
    to: PropTypes.string,
    tooltipClassName: PropTypes.string,
    tooltipPosition: Tooltip.propTypes.position,
    // eslint-disable-next-line
    type: PropTypes.string
  };

  static defaultProps = {
    tooltipPosition: 'top',
    type: 'button'
  };

  constructor(props) {
    super(props);
    this.tooltipStoreName = props.disabledTooltipContent ? `DisabledButtonTooltip/${uuid()}` : null;
    this.disabledTooltipPositionerRef = React.createRef();
    this.disabledTooltipRef = React.createRef();
    this.wrapperRef = React.createRef();
  }

  componentWillUpdate(nextProps) {
    if (!this.tooltipStoreName && nextProps.disabledTooltipContent) {
      this.tooltipStoreName = `DisabledButtonTooltip/${uuid()}`;
    } else if (this.tooltipStoreName && !nextProps.disabledTooltipContent) {
      this.tooltipStoreName = null;
    }
  }

  componentDidUpdate() {
    /*
     * Sets position for tooltip
     * In 'DidUpdate' to delay when style is applied
     * this helps ensure that all sibling components have rendered and we have button's final postion on the page
     */

    const tooltipPositionNode = this.disabledTooltipPositionerRef.current;
    const tooltipRef = this.disabledTooltipRef.current;
    if (this.props.disabledTooltipContent && tooltipPositionNode && tooltipRef) {
      const tooltipPosition = this.getDisabledTooltipPosition(tooltipPositionNode);
      Object.entries(tooltipPosition).forEach(([property, value]) => {
        tooltipRef.wrapperNode.style[property] = value;
      });
    }
  }

  handleMouseEnter = () => {
    if (!this.props.disabledTooltipContent) {
      return;
    }
    callTargetedAction({
      name: tooltipActions.SET_VISIBILITY,
      payload: true,
      targetStore: this.tooltipStoreName
    });
  };

  handleMouseLeave = () => {
    if (!this.props.disabledTooltipContent) {
      return;
    }
    callTargetedAction({
      name: tooltipActions.SET_VISIBILITY,
      payload: false,
      targetStore: this.tooltipStoreName
    });
  };

  getDisabledTooltipPosition(disabledTooltipPositionerNode) {
    const helperRect = disabledTooltipPositionerNode.getBoundingClientRect();
    const tooltipPosition = this.props.tooltipPosition;
    let pos = {};
    const offset = '1.15em';
    if (tooltipPosition === 'top') {
      pos = {
        left: `${helperRect.left + helperRect.width / 2}px`,
        top: `${helperRect.top}px`,
        transform: `translateX(-50%) translateY(-100%) translateY(-${offset})`
      };
    } else if (tooltipPosition === 'left') {
      pos = {
        left: `${helperRect.left}px`,
        top: `${helperRect.top + helperRect.height / 2}px`,
        transform: `translateX(-100%) translateX(-${offset}) translateY(-50%)`
      };
    } else if (tooltipPosition === 'right') {
      pos = {
        left: `${helperRect.left + helperRect.width}px`,
        top: `${helperRect.top + helperRect.height / 2}px`,
        transform: `translateX(${offset})  translateY(-50%)`
      };
    } else if (tooltipPosition === 'bottom') {
      pos = {
        left: `${helperRect.left + helperRect.width / 2}px`,
        top: `${helperRect.top + helperRect.height}px`,
        transform: `translateX(-50%) translateY(${offset})`
      };
    }
    return {
      ...pos,
      position: 'fixed',
      zIndex: 9999
    };
  }

  getDisabledTooltipElements() {
    const disabledTooltipContent = this.props.disabledTooltipContent;
    let disabledEventHelper = null;
    let disabledTooltip = null;
    if (this.props.disabled && disabledTooltipContent) {
      disabledEventHelper = (
        <div
          className='sg-button__disabled-event-helper'
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          ref={this.disabledTooltipPositionerRef}
        />
      );
      disabledTooltip = (
        <AppPortal>
          <Tooltip
            isFixed
            className={this.props.tooltipClassName}
            position={this.props.tooltipPosition}
            storeName={this.tooltipStoreName}
            ref={this.disabledTooltipRef}
          >
            {disabledTooltipContent}
          </Tooltip>
        </AppPortal>
      );
    }

    return {
      disabledEventHelper,
      disabledTooltip
    };
  }

  render() {
    let content;
    const {text, icon} = this.props;
    if (text && icon) {
      content = (
        <span>
          {text} <span className={`sg-button__icon fa fa-${icon}`} />
        </span>
      );
    } else if (text) {
      content = text;
    } else {
      content = this.props.children;
    }

    const className = classnames('sg-button', this.props.className, {
      ['sg-button--' + this.props.color]: this.props.color,
      'sg-button--outline': this.props.outline || this.props.whiteBg,
      'sg-button--small': this.props.small,
      'sg-button--large': this.props.large,
      'sg-button--link-button unbutton': this.props.linkButton,
      'sg-button--text-button unbutton': this.props.textButton,
      'sg-button--no-border': this.props.noBorder,
      'sg-button--no-padding': this.props.noPadding,
      'sg-button--disabled': this.props.disabled && this.props.to,
      'sg-button--white-bg': this.props.whiteBg
    });
    const {disabledTooltip, disabledEventHelper} = this.getDisabledTooltipElements();
    let ElementType;
    if (this.props.disabled) {
      ElementType = 'button';
    } else if (this.props.to) {
      ElementType = Link;
    } else {
      ElementType = 'button';
    }
    const dataProps = makeDataProps(this.props);
    const propsBlacklist = [
      'children',
      'className',
      'color',
      'disabledTooltipContent',
      'large',
      'linkButton',
      'outline',
      'noBorder',
      'noPadding',
      'small',
      'text',
      'textButton',
      'tooltipClassName',
      'tooltipPosition',
      'whiteBg'
    ];
    if (ElementType === Link) {
      propsBlacklist.push('type');
    }
    const passthroughProps = omit(this.props, propsBlacklist);
    return (
      <ElementType {...passthroughProps} {...dataProps} ref={this.wrapperRef} className={className}>
        {content}
        {disabledTooltip}
        {disabledEventHelper}
      </ElementType>
    );
  }
}
