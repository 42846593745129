//@flow
import {Store} from 'client/framework';
import {fromJS} from 'immutable';
import abandonQueueChangesActions from './AbandonQueueChanges.actions';

class AbandonQueueChangesStore extends Store {
  constructor(name: string) {
    super(name);
    this.initialData = fromJS({
      abandonChangesHandler: null,
      hasAbandonedChanges: false
    });
    this.setInitialData(this.initialData);
    this.handle(
      abandonQueueChangesActions.SET_ABANDON_CHANGES_HANDLER,
      this._setAbandonChangesHandler
    );
    this.handle(abandonQueueChangesActions.ABANDON_CHANGES, this._abandonChanges);
    this.handle(abandonQueueChangesActions.RESET_STORE, this.resetStore);
  }

  _setAbandonChangesHandler(handler: ?() => void) {
    this.writeData('abandonChangesHandler', handler);
  }

  _abandonChanges() {
    this.writeData('hasAbandonedChanges', true);
  }

  getAbandonChangesHandler(): ?() => void {
    return this.readData('abandonChangesHandler');
  }

  hasAbandonedChanges(): boolean {
    return this.readData('hasAbandonedChanges');
  }
}

export default new AbandonQueueChangesStore('AbandonQueueChangesStore');
