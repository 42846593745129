import {fromJS, Set} from 'immutable';
import {Store} from '../framework';
import supplementActions from './SupplementActions';
import {SupplementModelV1} from 'resources/augmented/Supplement/SupplementModel.v1';
import {resource} from '@albert-io/json-api-framework/request/builder';

const initialState = fromJS({
  supplements: new Set(),
  supplementBeingEdited: ''
});

class SupplementStore extends Store {
  static isomorphism = {
    dataTransforms: {
      supplements: (supplements) => {
        return Set(supplements.map((supplement) => new SupplementModelV1(supplement)));
      }
    }
  };

  constructor(name) {
    super(name);
    this.setInitialData(initialState);
    this.handle(supplementActions.ADD_SUPPLEMENT, this._addSupplement);
    this.handle(supplementActions.FETCH_SUPPLEMENT, this._fetchSupplement);
    this.handle(
      supplementActions.QUESTIONSET_SUPPLEMENTS_RECEIVED,
      this._addSupplementListToSupplementSet
    );
  }

  getSupplement(id) {
    return this.getSupplements().find(
      (supplement) => supplement.getId() === id,
      this,
      SupplementModelV1.getDefaultModel()
    );
  }

  hasSupplement(id) {
    return this.getSupplements().some((supplement) => supplement.getId() === id);
  }

  getSupplements() {
    return this.readData().get('supplements', new Set()).toSet();
  }

  _addSupplementListToSupplementSet(incomingSupplements) {
    const currentSupplements = this.getSupplements();
    const newSupplements = incomingSupplements.filterNot((incomingSupplement) => {
      return this.hasSupplement(incomingSupplement.getId());
    });

    if (!newSupplements.isEmpty()) {
      this.writeData('supplements', currentSupplements.concat(newSupplements));
    }
  }

  _addSupplement(incomingSupplement) {
    const newSupplements = this.getSupplements()
      .filterNot((existingSupplement) => existingSupplement.getId() === incomingSupplement.getId())
      .add(incomingSupplement);
    this.writeData('supplements', newSupplements);
  }

  _fetchSupplement = this.withSavedPromise(async (id) => {
    const query = resource('supplement_v1').mandarkEndpoint(['supplements_v1', id]);
    try {
      const supplement = await query.getResourcePromise();
      this._addSupplement(supplement);
    } catch (error) {
      logger.error('Failed to retrieve supplement ID %s: %s', id, error.message);
      throw error;
    }
  }, 'fetchSupplementPromise');

  getFetchSupplementPromise = () => this.readData('fetchSupplementPromise');
}

export default new SupplementStore('SupplementStore');
