import React from 'react';

import {useShallow} from 'zustand/react/shallow';

import {PRIMARY_CONTENT_LANGUAGE} from 'client/EditPage/V2/QuestionEditorV2Store.types';

import {Input} from '@albert-io/atomic';

import {TranslationStatusDropdown} from 'client/EditPage/V2/Translations/TranslationStatusDropdown';

import FeatureFlag from 'client/components/FeatureFlag/FeatureFlag.react';

import {useSupplementEditorV2Store} from './SupplementEditorV2Store';

interface Props {
  fieldName: string;
}

export const TranslationCommentStatusEditor = ({fieldName}: Props) => {
  const {currentLanguage, translatedField, updateTranslatedField} = useSupplementEditorV2Store(
    useShallow((state) => ({
      currentLanguage: state.currentLanguage,
      updateTranslatedField: state.updateTranslatedField,
      translatedField: state
        .currentTranslatedSupplement()
        ?.translated_fields.find((f) => f.field === fieldName)
    }))
  );

  if (currentLanguage === PRIMARY_CONTENT_LANGUAGE) {
    return null;
  }

  return (
    <FeatureFlag name='dennis_translation_authoring'>
      <div className='u-display_flex u-align-items_center u-gap_space-x2 u-mar-l_3 u-mar-b_2'>
        <Input
          size='s'
          placeholder='Add Comment'
          value={translatedField?.notes || ''}
          onChange={(e) => {
            updateTranslatedField(currentLanguage, fieldName, 'notes', e.target.value);
          }}
        />

        <TranslationStatusDropdown
          currentStatus={translatedField?.status || 'draft'}
          onChange={(newStatus) =>
            updateTranslatedField(currentLanguage, fieldName, 'status', newStatus)
          }
        />
      </div>
    </FeatureFlag>
  );
};
