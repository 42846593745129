import React, {createContext, useCallback, useEffect, useState} from 'react';
import {Map} from 'immutable';
import appStore from 'client/AppStore';
import sessionStore from 'client/Session/SessionStore';

export enum GlobalNavBehaviorEnum {
  VISIBLE_STICKY = 'visible-sticky',
  VISIBLE_NOT_STICKY = 'visible-not-sticky',
  NOT_VISIBLE = 'not-visible'
}
export const DEFAULT_GLOBAL_NAV_BEHAVIOR = GlobalNavBehaviorEnum.VISIBLE_STICKY;

interface ContextProps {
  navBehavior: GlobalNavBehaviorEnum;
  setDetermineNavBehaviorFunctionForPath: (
    path: string,
    determineNavBehavior: () => GlobalNavBehaviorEnum
  ) => void;
}

export const GlobalNavBehaviorContext = createContext({} as ContextProps);

export const GlobalNavBehaviorConsumer = GlobalNavBehaviorContext.Consumer;

type DetermineNavBehaviorMapType = Map<string, {determineNavBehavior: () => GlobalNavBehaviorEnum}>;

const INITIAL_DETERMINE_NAV_BEHAVIOR_MAP = {
  'assignment/:assignmentId': {
    determineNavBehavior: () => {
      if (!sessionStore.hasValidSession()) return GlobalNavBehaviorEnum.NOT_VISIBLE;
      return !sessionStore.isStudent()
        ? DEFAULT_GLOBAL_NAV_BEHAVIOR
        : GlobalNavBehaviorEnum.NOT_VISIBLE;
    }
  }
};

export const GlobalNavBehaviorProvider = ({children}: PropsWithChildrenRequired) => {
  const [navBehavior, setNavBehavior] = useState(DEFAULT_GLOBAL_NAV_BEHAVIOR);
  const [determineNavBehaviorMap, setDetermineNavBehaviorMap] =
    useState<DetermineNavBehaviorMapType>(Map(INITIAL_DETERMINE_NAV_BEHAVIOR_MAP));

  const setDetermineNavBehaviorFunctionForPath = useCallback(
    (path: string, determineNavBehavior: () => GlobalNavBehaviorEnum) => {
      setDetermineNavBehaviorMap((previousDetermineNavBehaviorMap) =>
        previousDetermineNavBehaviorMap.set(path, {determineNavBehavior})
      );
    },
    []
  );

  useEffect(() => {
    const {routes} = appStore.routerProps;
    const currentRoute = routes[1];
    const path = currentRoute && currentRoute.path ? currentRoute.path : undefined;
    if (!determineNavBehaviorMap.has(path)) setNavBehavior(DEFAULT_GLOBAL_NAV_BEHAVIOR);
    else setNavBehavior(determineNavBehaviorMap.get(path).determineNavBehavior());
  }, [appStore.routerProps, determineNavBehaviorMap]);

  return (
    <GlobalNavBehaviorContext.Provider
      value={{navBehavior, setDetermineNavBehaviorFunctionForPath}}
    >
      {children}
    </GlobalNavBehaviorContext.Provider>
  );
};
