import React from 'react';
import {Modal, Dialogue, Button, Text, Heading, LoadingSpinner} from '@albert-io/atomic';
import {history} from 'client/history';

import {withRouter} from 'react-router';

import {useShallow} from 'zustand/react/shallow';

import {useContentDiscoveryStore} from '../ContentDiscovery/store';
import {getAssignmentQuestionSets} from '../ContentDiscovery/ViewAssignment/ViewAssignment.queries';

type Props = {
  assignmentId: string;
  assignmentName?: string;
  handleClose: () => void;
  handleReassign?: () => void;
  router: any;
};

const handleReassignDefault = ({router}) => {
  router.push(`/assignment-builder/create-assignment/settings`);
};

const CustomizeReassignModal = ({
  assignmentId,
  assignmentName,
  handleClose,
  handleReassign,
  router
}: Props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const {setSelectedQuestionSets, setIsReassign, setDraftName} = useContentDiscoveryStore(
    useShallow((state) => ({
      setSelectedQuestionSets: state.draft.setSelectedQuestionSets,
      setIsReassign: state.assignment.setIsReassign,
      setDraftName: state.draft.setDraftName
    }))
  );

  const fetchAndSetQuestionSets = async () => {
    setIsLoading(true);
    const questions = await getAssignmentQuestionSets(assignmentId, true);
    setDraftName(`${assignmentName} (Reassigned)`);
    setIsReassign();
    setSelectedQuestionSets(questions);
    if (handleReassign) {
      handleReassign();
    } else {
      handleReassignDefault({
        router
      });
    }
    handleClose();
    setIsLoading(false);
  };

  return (
    <Modal ariaLabel='customize settings' handleClose={!isLoading ? handleClose : () => {}}>
      {({modalContentStyle}) => (
        <Dialogue
          inModal
          handleClose={!isLoading ? handleClose : () => {}}
          className={modalContentStyle}
          size='m'
        >
          <div className='u-display_flex u-flex-direction_column u-align-items_center u-justify-content_center'>
            <Heading className='u-mar-t_5 u-text-align_center u-text-wrap_pretty'>
              Need to adjust settings for specific students?
            </Heading>
            <Text size='l' className='u-mar-t_2 u-text-align_center u-text-wrap_pretty'>
              Customize time limits, due dates, and more!
            </Text>
            {isLoading ? (
              <LoadingSpinner size={3} className='u-mar-t_5' />
            ) : (
              <>
                <Button
                  variant='solid'
                  size='m'
                  className='u-mar-t_5 u-mar-b_1'
                  onClick={() => {
                    history.pushState(
                      null,
                      `/assignment-builder/create-assignment/${assignmentId}/settings`
                    );
                    handleClose();
                  }}
                >
                  Edit &amp; Customize Assignment
                </Button>
                <Button variant='text' size='m' onClick={fetchAndSetQuestionSets}>
                  Reassign
                </Button>
              </>
            )}
          </div>
        </Dialogue>
      )}
    </Modal>
  );
};

export default withRouter(CustomizeReassignModal);
