/* eslint-disable import/no-cycle */
// @flow
import {Map} from 'immutable';

import {APIMethods} from '../../resource';
import {getMandarkAPI} from '../MandarkAPI';

import {StudentIntervalStatsInterface} from './StudentIntervalStats.interface';

export const resources = {
  STUDENT_INTERVAL_STATISTICS: {
    endpoint: '/stats/students_v1/:studentId/time_series',
    id: 'STUDENT_INTERVAL_STATISTICS',
    model: Map(),
    modelInterface: StudentIntervalStatsInterface,
    maxAge: 60 * 60 * 1000
  }
};

export function getStudentIntervalStatsForTeacher({
  query,
  studentId
}: {
  query: Object,
  studentId: string
}): StudentIntervalStatsInterface {
  return getMandarkAPI().getResource({
    id: resources.STUDENT_INTERVAL_STATISTICS.id,
    query,
    params: {
      studentId
    },
    method: APIMethods.GET,
    forceUpdate: true
  });
}
