import {createActions} from 'client/framework';

export default createActions(null, [
  'CLEAR_ACTIVE_QUESTION',
  'NEXT_QUESTION',
  'SET_FORCE_AUTO_SCROLL_ON_MOUNT',
  'PREVIOUS_QUESTION',
  'SET_ACTIVE_QUESTION_SET',
  'SET_ACTIVE_QUESTION',
  'SET_INITIAL_ACTIVE_QUESTION'
]);
