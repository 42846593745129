import {fromJS, List, Map} from 'immutable';
import QuestionTypeStore from 'client/QuestionTypes/common/V2/QuestionType.store';
import {activeQuestionActions} from 'client/InteractionEngineV2/shared/ActiveQuestion/ActiveQuestionActions';
import textHighlightQuestionActions from './TextHighlightQuestion.actions';
import {GuessModel} from 'resources/Guess/Guess.model';

export default class TextHighlightQuestionStore extends QuestionTypeStore {
  constructor(name, questionId, question) {
    super(name);
    this.initialData = fromJS({
      highlightPrompt: '',
      questionId: questionId,
      preferredPermutation: '',
      selections: [],
      selectionIndicesAsMap: {},
      startIndex: null,
      potentialIndex: null,
      endIndex: null,
      isGuessSubmitted: false,
      shouldShowExplanation: false
    });
    this.setInitialData(this.initialData);
    this.writeData('__question', question);
    this.handleTargeted(activeQuestionActions.ACTIVE_QUESTION_LOAD_GUESS, this._loadGuess);
    this.handleTargeted(textHighlightQuestionActions.SET_UP_QUESTION, this._setUpQuestion);
    this.handleTargeted(textHighlightQuestionActions.CLEAR_SELECTIONS, this._clearSelections);
    this.handleTargeted(
      textHighlightQuestionActions.CLEAR_PENDING_SELECTION,
      this._clearPendingSelection
    );
    this.handleTargeted(textHighlightQuestionActions.START_SELECTION, this._startSelection);
    this.handleTargeted(textHighlightQuestionActions.POTENTIAL_SELECTION, this._potentialSelection);
    this.handleTargeted(textHighlightQuestionActions.END_SELECTION, this._endSelection);
    this.handleTargeted(textHighlightQuestionActions.REMOVE_SELECTION, this._removeSelection);
    this.handleTargeted(textHighlightQuestionActions.SET_GUESS_SUBMITTED, this._setGuessSubmitted);
  }

  _loadGuess(guess) {
    super._loadGuess(guess);
    const previousGuesses = guess
      .getIn(['content', 'guessSet'])
      .map((guessObj) => guessObj.toList().sort());
    this.writeData('selections', previousGuesses);
    this._setSelectedIndicesAsMap();
  }

  _clearGuess() {
    // Need to call this on the parent class
    super._clearGuess();
    this.writeData('selections', this.initialData.get('selections'));
    this._setSelectedIndicesAsMap();
  }

  _setUpQuestion({highlightPrompt, preferredPermutation, rubric}) {
    this.writeData((store) => {
      const updatedStore = store
        .set('highlightPrompt', highlightPrompt)
        .set('preferredPermutation', preferredPermutation);

      if (rubric) {
        const formatedRubric = rubric.mapKeys((key) =>
          key === 'valid_response' ? 'validResponse' : key
        );
        return updatedStore.set('rubric', formatedRubric);
      }

      return updatedStore;
    });
  }

  buildCorrectGuess() {
    const question = this._fetchQuestion();
    return new GuessModel({
      content: new Map({
        guessSet: question.getValidResponse()
      }),
      correct: true,
      rubric: question.getRubric(),
      solutionText: question.getSolutionText(),
      pointsPossible: question.getPoints(),
      pointsEarned: question.getPoints(),
      question_v1: question
    });
  }

  _onSubmitAnswerEnd(responseQuestion) {
    this.writeData('preferredPermutation', responseQuestion.get('correctestPermutation'));
  }

  _clearPendingSelection() {
    this.writeData('startIndex', null);
    this.writeData('potentialIndex', null);
    this.writeData('endIndex', null);
  }

  _clearSelections() {
    this.writeData('selections', new List());
  }

  _startSelection(index) {
    this.writeData('startIndex', parseInt(index, 10));
  }

  _potentialSelection(index) {
    this.writeData('potentialIndex', parseInt(index, 10));
  }

  _endSelection(index) {
    index = parseInt(index, 10);
    if (this.startIndex === index) {
      this._removeSelection(index);
      this._clearPendingSelection();
      return;
    }
    this.writeData('endIndex', index);
    this._addSelection();
  }

  _addSelection() {
    let newSelection = new List([this.startIndex, this.endIndex]).sort();
    newSelection = this._trimWhitespace(newSelection);
    if (newSelection === null) {
      this._clearPendingSelection();
      return;
    }
    this.writeData(
      'selections',
      this.selections
        .filterNot((selection) => {
          const isInside = newSelection.some(
            (val) => selection.get(0) <= val && val <= selection.get(1)
          );
          const doesSurround =
            newSelection.get(0) <= selection.get(0) && newSelection.get(1) >= selection.get(1);
          return isInside || doesSurround;
        })
        .push(newSelection)
    );
    this._setSelectedIndicesAsMap();
    this._clearPendingSelection();
  }

  _trimWhitespace(selection) {
    const prompt = this.highlightPrompt;
    const shouldTrimWhitespace = selection.some((index) => prompt[index].search(/\s/) !== -1);
    if (!shouldTrimWhitespace) {
      return selection;
    }
    const selectionText = prompt.substring(selection.first(), selection.last() + 1);
    const trimmedStart = selection.first() + selectionText.search(/\S/);
    const trimmedEnd =
      selection.last() -
      selectionText
        .split('')
        .reverse('')
        .join('')
        .search(/\S/);

    if (trimmedStart >= trimmedEnd) {
      return null;
    }

    return new List([trimmedStart, trimmedEnd]);
  }

  _removeSelection(index) {
    const indexToDelete = this.selections.findIndex((selection) => {
      return selection.get(0) <= index && index <= selection.get(1);
    });
    if (indexToDelete < 0) {
      return;
    }
    this.writeData('selections', this.selections.delete(indexToDelete));
    this._setSelectedIndicesAsMap();
  }

  _setGuessSubmitted(isGuessSubmitted) {
    this.writeData('isGuessSubmitted', isGuessSubmitted);
  }

  _setSelectedIndicesAsMap() {
    this.writeData(
      'selectionIndicesAsMap',
      this.selections.reduce((r, selection) => {
        for (let i = selection.first(); i <= selection.last(); i++) {
          r = r.set(i, true);
        }
        return r;
      }, new Map())
    );
  }

  isIndexInPreferredPermutation(index) {
    const preferredPermutation = this.preferredPermutation;
    const validResponse = this.validResponse;
    if (!preferredPermutation || validResponse.isEmpty()) {
      return false;
    }
    return validResponse
      .get(preferredPermutation)
      .map((permutation) => permutation.toList())
      .some((selection) => selection.min() <= index && index <= selection.max());
  }

  isIndexSelected(index) {
    return this.selectionIndicesAsMap.get(index);
  }

  isIndexInCurrentSelection(index) {
    if (this.startIndex === null || this.potentialIndex === null) {
      return false;
    }

    const potentialSelection = new List([this.startIndex, this.potentialIndex]).sort();
    return potentialSelection.get(0) <= index && index <= potentialSelection.get(1);
  }

  buildGuessContent() {
    const formattedSelections = this.selections.map((selection) => {
      return {
        start: selection.get(0),
        end: selection.get(1)
      };
    });
    const guessContent = !formattedSelections.isEmpty()
      ? {guessSet: formattedSelections.toJS()}
      : {};
    return guessContent;
  }

  get highlightPrompt() {
    const prompt = this.readData('highlightPrompt');
    /**
     * In the `QuestionEditor` when "Preview"ing the `highlight_prompt` on
     * a question can be `null`. The renderer expects it to always be a string.
     */
    return prompt ? prompt : '';
  }

  get selections() {
    return this.readData('selections');
  }

  get startIndex() {
    return this.readData('startIndex');
  }

  get potentialIndex() {
    return this.readData('potentialIndex');
  }

  get endIndex() {
    return this.readData('endIndex');
  }

  get selectionIndicesAsMap() {
    return this.readData('selectionIndicesAsMap');
  }

  get isGuessSubmitted() {
    return this.readData('isGuessSubmitted');
  }

  get preferredPermutation() {
    return this.readData('preferredPermutation');
  }
}
