import React from 'react';
import PropTypes from 'prop-types';
import FeatureFlag from 'client/components/FeatureFlag/FeatureFlag.react';
import {callAction} from 'client/framework';
import {history} from 'client/history';
import {
  getQuestionSetsQuery,
  getQueueQuestionEditPath,
  getQueueNewQuestionSetPath
} from 'client/Dennis/Content/Queue/shared';
import {Button, WithTooltip} from '@albert-io/atomic';

import OldButton from 'sg/Button/Button.react';
import NewQuestionTypeModal from 'client/EditPage/V2/NewQuestionTypeModal/NewQuestionTypeModal.react';
import QuestionsList from 'client/InteractionEngineV2/shared/QuestionsList/QuestionsList.react';
import questionEditorActions from 'client/EditPage/V2/QuestionEditor/QuestionEditor.actions';
import questionEditorStore from 'client/EditPage/V2/QuestionEditor/QuestionEditor.store';
import appStore from 'client/AppStore';
import {ieTypes} from 'client/InteractionEngineV2/interactionEngineConstants';
import {sleepInactiveQuestionsListStores} from 'client/InteractionEngineV2/InteractionEngine.react';
import {promptIfUnsavedOr} from 'client/Dennis/Content/Queue/Queue.react';
import {getModelForResourceType} from 'resources/modelRegistry';
import MandarkPaginator from 'generic/MandarkPaginator/MandarkPaginator.react';

import abandonQueueChangesActions from '../AbandonQueueChanges.actions';
import {SEARCH_PARAMETERS, setURLSearchParameter} from '../QueueSearchPanel/URLSearchParameters';

import queueResultsActions from './QueueResults.actions';
import queueResultsStore from './QueueResults.store';
import EditQuestionSetModal from './EditQuestionSetModal/EditQuestionSetModal.react';
import './queue-results.scss';

const emptyResults = (
  <div className='queue-results__empty-results'>
    <b>No results found.</b>
    <p>Try changing your search criteria.</p>
  </div>
);

export class QueueResults extends React.Component {
  static propTypes = {
    isAuthorQueue: PropTypes.bool
  };

  UNSAFE_componentWillMount() {
    sleepInactiveQuestionsListStores(ieTypes.QUEUE_RESULTS);
  }

  questionItemToGenerator = (
    set,
    question
    // questionSetIndex
  ) => {
    return getQueueQuestionEditPath(set.getId(), question.getId());
  };

  render() {
    const activeQuestion = questionEditorStore.getQuestion();
    const activeQuestionOverrideId = activeQuestion ? activeQuestion.getId() : null;
    return (
      <QuestionsList
        // @todo: The prop below is temporary. Look at QuestionsList.react for more info.
        activeQuestionOverrideId={activeQuestionOverrideId}
        // @todo: The prop above is temporary. Look at QuestionsList.react for more info.
        className='queue-results-questions-list'
        topDrawer={<CreateQuestionSet />}
        topDrawerHeight={this.props.isAuthorQueue ? 45 : 0}
        questionSets={queueResultsStore.getQuestionSets()}
        questionsListStore={queueResultsStore}
        questionItemToGenerator={this.questionItemToGenerator}
        noQuestionSetsFoundEl={getQuestionSetsQuery().isResourceReady() ? emptyResults : null}
        questionSetWidget={<QuestionSetWidgetManager />}
        singleQuestionWidget={<QuestionHasChangesIndicator />}
        bottomDrawerHeight={200}
        bottomDrawer={
          <div>
            <QuestionsListPagination />
            <EditQuestionSetModal />
          </div>
        }
      />
    );
  }
}

const QuestionSetWidgetManager = ({questionSet, question}) => {
  return question ? (
    <QuestionHasChangesIndicator questionSet={questionSet} question={question} />
  ) : (
    <EditQuestionSetWidget questionSet={questionSet} />
  );
};

QuestionSetWidgetManager.propTypes = {
  question: PropTypes.instanceOf(getModelForResourceType('authoring_question_v1')),
  questionSet: PropTypes.instanceOf(getModelForResourceType('authoring_question_set_v1'))
};

/**
  Button that prompts a user for a question type, then initializes the edit area for that type.

  If a user has unsaved changes, this compoennt will prompt them to discard those changes before
  allowing them to select a new question type to re-initialize the edit area with.

  This is partially handled by a ConfirmWillLeave modal that is triggered on route change, however
  there are several situations where we need to prompt manually. The manually triggered prompt is
  handled by a WindowConfirm component that this CreateQuestionSet component will include.
 */
class CreateQuestionSet extends React.Component {
  constructor() {
    super();
    this.state = {
      isQuestionTypeModalActive: false
    };
  }

  isDisabled() {
    return !appStore.routerProps.location.query.subject;
  }

  toggleQuestionTypeModal = () => {
    this.setState(({isQuestionTypeModalActive}) => ({
      isQuestionTypeModalActive: !isQuestionTypeModalActive
    }));
  };

  createQuestionSet = (type) => {
    callAction(questionEditorActions.SET_IS_PREVIEW_MODE, false);
    history.pushState(null, getQueueNewQuestionSetPath());

    callAction(questionEditorActions.INITIALIZE_STORE, type);
    callAction(questionEditorActions.SET_SUBJECT_ID, appStore.routerProps.location.query.subject);
    this.toggleQuestionTypeModal();
  };

  newQuestionHandler = () => {
    callAction(
      abandonQueueChangesActions.SET_ABANDON_CHANGES_HANDLER,
      this.toggleQuestionTypeModal
    );
    promptIfUnsavedOr(this.toggleQuestionTypeModal);
  };

  render() {
    return (
      <>
        <FeatureFlag name='question_editor_v2'>
          <div className='u-display_flex u-pad_1 background-subtle'>
            <WithTooltip
              content='Please select a subject before creating a new question'
              enabled={this.isDisabled()}
              wrapperProps={{className: 'u-flex-grow_1'}}
              placement='bottom-end'
            >
              <Button
                size='m'
                onClick={this.newQuestionHandler}
                disabled={this.isDisabled()}
                className='u-width_100pc'
              >
                New Question
              </Button>
            </WithTooltip>
          </div>
          <FeatureFlag.Fallback>
            <div className='add-question-set'>
              <OldButton
                small
                text='New question'
                color='green'
                className='add-question-set__button'
                onClick={this.newQuestionHandler}
                disabled={this.isDisabled()}
                disabledTooltipContent='Please select a subject before creating a new question'
                tooltipPosition='bottom'
              />
            </div>
          </FeatureFlag.Fallback>
        </FeatureFlag>

        {this.state.isQuestionTypeModalActive ? (
          <NewQuestionTypeModal
            handleClose={this.toggleQuestionTypeModal}
            handleQuestionTypeSelection={this.createQuestionSet}
          />
        ) : null}
      </>
    );
  }
}

class QuestionsListPagination extends React.Component {
  handleChange = (pageNum) => {
    setURLSearchParameter(SEARCH_PARAMETERS.page, pageNum);
  };

  render() {
    const query = getQuestionSetsQuery();
    if (!query.isResourcePopulated()) {
      return null;
    }
    const total = query.getResourceMetadata().getIn(['request', 'count_of_included_questions'], 0);
    if (total === 0) {
      return null;
    }
    return (
      <div className='queue-results__pagination'>
        <b className='queue-results__question-count'>{total} questions</b>
        <MandarkPaginator query={query} onChange={this.handleChange} />
      </div>
    );
  }
}

const QuestionHasChangesIndicator = ({question}) => {
  const questionUnderEdit = questionEditorStore.getQuestion();
  const questionSetUnderEdit = questionEditorStore.getQuestionSet();
  if (!questionUnderEdit || questionUnderEdit.getId() !== question.getId()) {
    return null;
  }
  const isNew = !questionUnderEdit.existsOnServer();
  const questionHasChanges =
    !questionSetUnderEdit.getChangeMap().isEmpty() || !questionUnderEdit.getChangeMap().isEmpty();
  const isEdited = !isNew && questionHasChanges;
  return isEdited ? <span className='fa fa-asterisk question-has-changes-indicator' /> : null;
};

QuestionHasChangesIndicator.propTypes = {
  question: PropTypes.instanceOf(getModelForResourceType('authoring_question_v1'))
};

export default QuestionHasChangesIndicator;

class EditQuestionSetWidget extends React.Component {
  static propTypes = {
    questionSet: PropTypes.instanceOf(getModelForResourceType('authoring_question_set_v1'))
  };

  setActiveId = () => {
    callAction(queueResultsActions.SET_QUESTION_SET_UNDER_EDIT, this.props.questionSet);
  };

  render() {
    return this.props.questionSet.getQuestions().size > 1 ? (
      <button
        aria-label='Edit question set'
        className='unbutton fa fa-pencil'
        onClick={this.setActiveId}
        type='button'
      />
    ) : null;
  }
}
