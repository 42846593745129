import React from 'react';

import {Banner} from '@albert-io/atomic';
import sessionStore from 'client/Session/SessionStore';

const studentText = 'You did not submit a response for this question.';
const teacherText = 'No response was submitted';

const IncompletenessBanner = () => {
  return (
    <div className='u-mar-b_4'>
      <Banner type={sessionStore.isStudent() ? 'negative' : 'neutral'}>
        <Banner.Icon icon={sessionStore.isStudent() ? 'times' : 'minus'} />
        {sessionStore.isStudent() ? studentText : teacherText}
      </Banner>
    </div>
  );
};

export default IncompletenessBanner;
