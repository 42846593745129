import React, {useState} from 'react';

import {Button, Icon, addToast} from '@albert-io/atomic';
import {callTargetedAction} from 'client/framework';
import {getUserPointsQuery} from 'client/GlobalNavigation/PointsIndicator/PointsIndicator.react';
import {GuessModelV1, QuestionModelV3, SubjectModelV2} from '@albert-io/models';
import questionTypeActions from 'components/QuestionTypes/questionType.actions';
import sessionStore from 'client/Session/SessionStore';
import {SaveStatus} from 'client/components/QuestionTypes/QuestionType.types';
import {showGlobalLoginModal} from 'client/LogIn/utils';
import useDraftGuess from 'client/AssignmentPracticeView/DraftGuesses/useDraftGuess';

import UpgradeSubjectModal from '../UpgradeSubjectModal/UpgradeSubjectModal.react';

interface Props {
  assignmentId?: string;
  disabled?: boolean;
  getTimeElapsed: () => void;
  guess?: GuessModelV1;
  hasAccess?: boolean;
  onSuccess?: (guess: GuessModelV1) => void;
  question: QuestionModelV3;
  subject: SubjectModelV2;
}

export default function SubmitAnswer({
  assignmentId,
  disabled = false,
  getTimeElapsed,
  guess,
  hasAccess = false,
  onSuccess = () => {},
  question,
  subject
}: Props) {
  const {draftGuessPromise, saveStatus, cancelDebouncedSaveDraftGuess} = useDraftGuess(
    question.getStore().getName(),
    false
  );

  const [isSubmitPending, setIsSubmitPending] = useState(false);
  const [showModal, setShowModal] = useState(false);

  async function handleSubmit() {
    const isLoggedIn = sessionStore.hasValidSession();

    if (!isLoggedIn) {
      showGlobalLoginModal();
      return;
    }

    if (!hasAccess) {
      toggleShowUpgradeSubjectModal();
      return;
    }

    if (saveStatus === SaveStatus.SAVING) {
      // Context: In order to prevent race conditions when a draft guess and a guess are submitted at similar times
      // we initially tried just disabling the "Submit Answer" button while a draft guess was saving.
      // This worked, but resulted in bad UX since the button would flicker as it was disabled and then re-enabled very quickly.
      // Additionally, sometimes the user would attempt to click the button only to have it disabled just before their click
      // which resulted in nothing happening.

      // In order to prevent these UX pitfalls, we simply check to see if a draft guess is being saved, and if so, we wait for it
      // to finish before proceeding to submit the guess.
      await draftGuessPromise;
    }

    submitGuess();
  }

  async function submitGuess() {
    if (isSubmitPending) {
      return;
    }

    setIsSubmitPending(true);
    cancelDebouncedSaveDraftGuess();

    const questionStore = question.getStore();
    const builtGuess = questionStore.buildGuess({
      timeElapsed: getTimeElapsed()
    });

    builtGuess
      .submitGuess({
        assignmentId,
        userId: sessionStore.getUserId(),
        questionType: question.getQuestionType()
      })
      .then((response) => {
        callTargetedAction({
          name: questionTypeActions.UPDATE_QUESTION,
          targetStore: question.getStore().getName(),
          payload: question
        });

        getUserPointsQuery(sessionStore.getUserId()).invalidateInterest();
        onSuccess(response);
      })
      .catch((error) => {
        console.error(error);
        addToast({
          color: 'negative',
          title: 'Error',
          message:
            'You are attempting to place a guess on an assignment you no longer have access to. Please return to your dashboard'
        });
      })
      .finally(() => {
        setIsSubmitPending(false);
      });
  }

  function toggleShowUpgradeSubjectModal() {
    setShowModal((value) => !value);
  }

  let stricterDisabled = disabled || isSubmitPending;
  let label = 'Submit Answer';
  let submittedLabel = 'Submitted';

  if (question.getQuestionType() === 'free-response') {
    stricterDisabled = stricterDisabled || !question.getStore().hasUserSelectedAnswer;
    label = 'Submit response';
  } else if (question.getRubricType() === 'markAsCorrect') {
    // in questionmodel, markAsCorrect rubric types have an early return to false
    stricterDisabled = stricterDisabled || !question.hasSelectedAnswer();
    label = 'Mark as completed';
    submittedLabel = 'Completed';
  } else {
    stricterDisabled = stricterDisabled || !question.hasSelectedAnswer();
    label = 'Submit Answer';
  }

  return (
    <Button
      disabled={stricterDisabled}
      onClick={() => {
        handleSubmit();
      }}
      type='submit'
      data-testid='practice-view__submit-answer-btn'
      variant='outlined'
      className={guess ? 'u-color_green-700 u-opacity_full' : undefined}
    >
      {guess ? <Icon icon='check-circle' iconStyle='regular' className='u-mar-r_1' /> : null}
      {guess ? submittedLabel : label}
      {showModal && (
        <UpgradeSubjectModal
          subject={subject}
          handleClose={() => {
            toggleShowUpgradeSubjectModal();
          }}
        />
      )}
    </Button>
  );
}
