import React from 'react';
import classnames from 'classnames';
import './shelf.scss';

type CurveOffsetOption = 'xs' | 's' | 'm' | 'l' | 'xl';

export interface Props extends PropsWithChildrenOptional, PropsWithClassNameOptional {
  curveOffset?: CurveOffsetOption;
  backgroundColor?: 'white' | 'page';
  border?: 'regular' | 'darker';
}

export const Shelf = ({
  children,
  curveOffset = 'l',
  className,
  backgroundColor = 'white',
  border = 'regular',
  ...rest
}: Props) => (
  <div
    className={classnames(
      'o-shelf',
      className,
      `o-shelf--bg-${backgroundColor}`,
      `o-shelf--border-${border}`,
      {
        [`o-shelf--curve-offset-${curveOffset}`]: curveOffset !== undefined
      }
    )}
    {...rest}
  >
    {children}
  </div>
);
