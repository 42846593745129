import React from 'react';
import PropTypes from 'prop-types';
import {hideModal} from './ModalActions';
import modalStore from './ModalStore';

//TODO: You can clean this code up a ton using ES6 features / using default props.
// ALso, the ModalContainer is a bit of a mess and could use a tiny bit of refactoring to make it a bit better
export default class Modal extends React.Component {
  componentDidUpdate() {
    this.lockBody();
  }

  lockBody() {
    const body = global.document.getElementsByTagName('body')[0];
    const html = global.document.getElementsByTagName('html')[0];
    const hasActiveModal = modalStore.hasActiveModal();
    if (hasActiveModal) {
      html.classList.add('has-active-modal');
      body.classList.add('has-active-modal');
    } else {
      html.classList.remove('has-active-modal');
      body.classList.remove('has-active-modal');
    }
  }

  render() {
    var displayModal = modalStore.isModalDisplayed(this.props.modalKey);
    return (
      <ModalContainer
        isModalDisplayed={displayModal}
        headerClass={this.props.headerClass}
        headerString={this.props.headerString}
        hideHeader={this.props.hideHeader}
        modalKey={this.props.modalKey}
        modalWrapperClass={this.props.modalWrapperClass}
        modalClass={this.props.modalClass}
        tail={this.props.children}
        modalContent={this.props.modalContent}
        onClose={this.props.onClose}
      />
    );
  }
}

Modal.propTypes = {
  children: PropTypes.object,
  headerClass: PropTypes.string,
  headerString: PropTypes.string,
  modalKey: PropTypes.string,
  modalWrapperClass: PropTypes.string,
  modalClass: PropTypes.string,
  onClose: PropTypes.func
};

class ModalContainer extends React.Component {
  _closeModal(modalKey) {
    this.props.onClose();
    hideModal(modalKey);
  }

  // @todo add escape key support to close it
  closeModalOnEnter(e) {
    if (e.which === 13) {
      this._closeModal(this.props.modalKey);
    }
  }

  getHeaderContent() {
    return !this.props.hideHeader ? (
      <div className={`modal__header ${this.props.headerClass}`}>
        <div className='modal__header-label'>{this.props.headerString}</div>
        <div
          className='modal__header-close-btn'
          role='button'
          tabIndex='0'
          onKeyPress={(e) => {
            this.closeModalOnEnter(e);
          }}
          onClick={() => this._closeModal(this.props.modalKey)}
        >
          <span className='fa fa-times' />
        </div>
      </div>
    ) : null;
  }

  render() {
    const modalClass = this.props.modalClass;
    const modalWrapperClass = this.props.modalWrapperClass;
    // Keep the modal displayed while it's explicitly displayed or if the closing
    // animation for this specific modal is still playing
    const showModal = this.props.isModalDisplayed;
    const modalComponent = showModal ? (
      <div className={`modal ${modalClass}`}>
        <div className='modal__shade' onClick={() => this._closeModal(this.props.modalKey)} />
        <div className='modal__overflow-handler'>
          <div
            className={`modal__wrapper ${modalWrapperClass}`}
            onClick={(e) => e.stopPropagation()}
          >
            {this.getHeaderContent()}
            <div className='modal__body'>{this.props.tail}</div>
          </div>
        </div>
      </div>
    ) : null;
    return modalComponent;
  }
}

ModalContainer.propTypes = {
  isModalDisplayed: PropTypes.bool,
  headerClass: PropTypes.string,
  headerString: PropTypes.string,
  hideHeader: PropTypes.bool,
  modalKey: PropTypes.string,
  modalWrapperClass: PropTypes.string,
  tail: PropTypes.object,
  modalClass: PropTypes.string,
  onClose: PropTypes.func
};

ModalContainer.defaultProps = {
  modalClass: '',
  headerClass: '',
  hideHeader: false,
  modalWrapperClass: '',
  onClose: () => {}
};

export class ModalContent extends React.Component {
  render() {
    const contentClass = this.props.modalContentClass || '';
    return <div className={`modal__content ${contentClass}`}>{this.props.children}</div>;
  }
}

ModalContent.propTypes = {
  modalContentClass: PropTypes.string
};
