/**
 * # FindAndApply
 *
 * A component that allows users to search and select items from a list.
 *
 * ## Props extends `CardProps<'div'>`
 *
 * - `children`: React.ReactNode - The content of the component.
 * - `onApply`: (selectedValues: List<GenericModelType>) => void - A callback function that is called when the user applies the selected values.
 * - `onDismiss`: () => void - A callback function that is called when the user dismisses the component.
 * - `defaultSelectedItems`: List<GenericModelType> - The default selected items.
 * - `radio`: boolean - Indicates whether the selection should be radio buttons instead of checkboxes.
 * - `onSearchTermChange`: (string) => void - A callback function that is called when the search term changes.
 * - `onSearchTermClear`: () => void - A callback function that is called when the search term is cleared.
 * - `onError`: (e: any) => void - A callback function that is called when an error occurs.
 * - `query`: QueryBuilder - The query builder for fetching search results.
 * - `fetchInitial`: boolean - Indicates whether to fetch search results initially without waiting for user search input.
 * - `...rest: CardProps<'div'>`: See Card component for options
 *
 * ## Usage
 *
 * ```tsx
 * <FindAndApply
 *   onApply={(selectedValues) => {
 *     console.log('Selected Values:', selectedValues);
 *   }}
 *   onDismiss={() => {
 *     console.log('Component dismissed');
 *   }}
 *   defaultSelectedItems={defaultSelectedItems}
 *   radio={true}
 *   onSearchTermChange={(searchTerm) => {
 *     console.log('Search Term:', searchTerm);
 *   }}
 *   onSearchTermClear={() => {
 *     console.log('Search term cleared');
 *   }}
 *   onError={(error) => {
 *     console.error('Error:', error);
 *   }}
 *   query={queryBuilder}
 *   fetchInitial={true}
 * >
 *   {/* Content *\/}
 * </FindAndApply>
 * ```
 */
import React, {useContext, useState, useCallback, useEffect, useRef} from 'react';

import {GenericModel} from 'resources/Generic.model';
import {Set, List} from 'immutable';
import classnames from 'classnames';
import {debounce} from 'lodash';

import QueryBuilder from 'src/albert-io/json-api-framework/request/builder';

import CheckboxChip from '@albert-io/atomic/molecules/CheckboxChip/CheckboxChip.react';
import {ChipColor} from '@albert-io/atomic/atoms/Chip/Chip.react';

import LoadingIndicator from 'generic/LoadingIndicator.react';

import {Props as CardProps} from '../../atoms/Card/Card.react';
import Button, {ButtonProps} from '../../atoms/Button/Button.react';
import SplitCard from '../../molecules/SplitCard/SplitCard.react';
import SearchInput, {
  Props as SearchInputProps
} from '../../molecules/SearchInput/SearchInput.react';
import Radio from '../../atoms/Radio/Radio.react';
import Checkbox from '../../atoms/Checkbox/Checkbox.react';
import ListGroup from '../../atoms/ListGroup/ListGroup.react';
import ListGroupItem from '../../atoms/ListGroupItem/ListGroupItem.react';
import Label from '../../atoms/Label/Label.react';
import Text, {TextProps} from '../../atoms/Text/Text.react';
import ButtonGroup from '../../molecules/ButtonGroup/ButtonGroup.react';
import {IconProp} from '../../atoms/Icon/Icon.react';

import './find-and-apply.scss';

type GenericModelType = {getId: () => string} & typeof GenericModel;

interface State {
  searchString: string;
  selectedItems: Set<GenericModelType>;
  setSelectedItems: (items: Set<GenericModelType>) => void;
  onSearchTermChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setSearchString: (string) => void;
  onSearchTermClear?: () => void;
  radio: boolean;
  onApply: (values: List<GenericModelType>) => void;
  onClear?: () => void;
  searchResults: List<GenericModelType>;
  query: QueryBuilder;
  onDismiss?: () => void;
  fetchInitial: boolean;
}
const FindAndApplyContext = React.createContext<State>(undefined!);

interface Props extends OmitChildren<CardProps<'div'>>, PropsWithChildrenRequired {
  onApply: (selectedValues: List<GenericModelType>) => void;
  onClear?: () => void;
  onDismiss?: () => void;
  defaultSelectedItems?: List<GenericModelType>;
  radio?: boolean;
  onSearchTermChange?: (string) => void;
  onSearchTermClear?: () => void;
  onError?: (e: any) => void;
  query: QueryBuilder;
  fetchInitial?: boolean;
}

const FindAndApply = ({
  children,
  onApply,
  onClear,
  defaultSelectedItems = List(),
  radio = false,
  onSearchTermChange,
  onSearchTermClear,
  fetchInitial = false,
  onDismiss,
  onError = useCallback(() => {}, []),
  query,
  ...rest
}: Props) => {
  const [searchString, setSearchString] = useState('');
  const [selectedItems, setSelectedItems] = useState(Set(defaultSelectedItems));
  const [searchResults, setSearchResults] = useState(List());

  const mergeResults = useCallback(
    (newResults: List<GenericModelType>) => {
      const mergedResults = newResults.map((newResult) => {
        const selectedResult = selectedItems.find(
          (selectedItem) => selectedItem.getId() === newResult.getId()
        );
        return selectedResult || newResult;
      }) as List<GenericModelType>;
      setSearchResults(mergedResults);
    },
    [selectedItems]
  );

  const firstUpdate = useRef(true);

  useEffect(() => {
    setSelectedItems(Set(defaultSelectedItems));
  }, [defaultSelectedItems]);

  useEffect(() => {
    if (fetchInitial && firstUpdate.current) {
      firstUpdate.current = false;
      query(searchString).getResourcePromise().then(mergeResults).catch(onError);
    } else if (searchString.length) {
      // optional user defined event handler
      onSearchTermChange?.(searchString);
      // make actual query and merge results with default items
      query(searchString).getResourcePromise().then(mergeResults).catch(onError);
    } else {
      // optional user defined event handler
      if (fetchInitial) {
        query(searchString).getResourcePromise().then(mergeResults).catch(onError);
      }
      onSearchTermClear?.();
    }
  }, [
    searchString,
    mergeResults,
    query,
    onSearchTermChange,
    onSearchTermClear,
    fetchInitial,
    onError
  ]);

  return (
    <FindAndApplyContext.Provider
      value={{
        searchString,
        setSearchString,
        selectedItems,
        onSearchTermChange,
        onSearchTermClear,
        radio,
        onApply,
        onClear,
        setSelectedItems,
        searchResults,
        fetchInitial,
        onDismiss,
        query
      }}
    >
      <SplitCard {...rest}>{children}</SplitCard>
    </FindAndApplyContext.Provider>
  );
};

type HeaderProps = CardProps<'div'> & {
  children?: (
    state: State & {
      HeaderSearchInput: (props: Omit<SearchInputProps, 'onChange' | 'onReset'>) => JSX.Element;
    }
  ) => React.ReactNode;
  placeholder?: string;
} & PropsWithClassNameOptional;
const Header = ({children, className, ...rest}: HeaderProps) => {
  const {setSearchString, ...context} = useContext(FindAndApplyContext);
  const HeaderSearchInput = useCallback(
    ({
      className: searchInputclassName,
      placeholder = 'Start typing...',
      ...searchInputProps
    }: Omit<SearchInputProps, 'onChange' | 'onReset'>) => (
      <SearchInput
        className={classnames('o-find-and-apply__search', searchInputclassName)}
        aria-label='Search by keyword or phrase'
        placeholder={placeholder}
        onChange={debounce((e) => setSearchString(e.target.value), 500)}
        onReset={() => setSearchString('')}
        {...searchInputProps}
      />
    ),
    [setSearchString]
  );
  return (
    <SplitCard.Section
      border='none'
      className={classnames('o-find-and-apply__header', className)}
      {...rest}
    >
      {children ? (
        children!({HeaderSearchInput, setSearchString, ...context})
      ) : (
        <HeaderSearchInput />
      )}
    </SplitCard.Section>
  );
};

type BodyProps = CardProps<'div'> &
  RequireAtLeastOne<
    {
      children?: (context: State & {handleChange: (GenericModelType) => void}) => React.ReactNode;
      rowLabelRenderer?: (item: GenericModelType) => React.ReactNode;
      beforeSearchMessage?: string;
      noResultsMessage?: string;
    },
    'children' | 'rowLabelRenderer'
  >;
const Body = ({
  children,
  rowLabelRenderer,
  className,
  beforeSearchMessage,
  noResultsMessage,
  ...rest
}: BodyProps) => {
  const {
    searchResults,
    selectedItems,
    setSelectedItems,
    radio,
    searchString,
    query,
    fetchInitial,
    ...context
  } = useContext(FindAndApplyContext);

  const handleMultiSelect = useCallback(
    (currentItem: GenericModelType) => {
      setSelectedItems(
        selectedItems.has(currentItem)
          ? selectedItems.delete(currentItem)
          : selectedItems.add(currentItem)
      );
    },
    [selectedItems, setSelectedItems]
  );

  const handleChange = radio ? (item) => setSelectedItems(Set([item])) : handleMultiSelect;

  // without a higher order component provided, we will default to rendering rows of checkboxes ourselves
  if (!searchString && !fetchInitial) {
    return <Message>{beforeSearchMessage || 'No results found'}</Message>;
  }
  if (!query(searchString).isResourcePopulated() && query(searchString).isResourceValid()) {
    return <LoadingIndicator />;
  }
  if (searchString && query(searchString).isResourcePopulated() && searchResults.isEmpty()) {
    return <Message>{noResultsMessage || `No results found`}</Message>;
  }

  if (children) {
    return (
      <SplitCard.Section className={classnames('o-find-and-apply__body', className)} {...rest}>
        {children({
          searchResults,
          selectedItems,
          setSelectedItems,
          radio,
          searchString,
          query,
          handleChange,
          fetchInitial,
          ...context
        })}
      </SplitCard.Section>
    );
  }

  return (
    <SplitCard.Section className={classnames('o-find-and-apply__body', className)} {...rest}>
      <ListGroup as='ul' border='none' className='u-pad-t_0 u-mar-t_0'>
        {searchResults.map((searchResult) => (
          <ListGroupItem as='li' className='u-display_flex u-gap_space-x1 u-align-items_flex-start'>
            {radio ? (
              <Radio
                id={searchResult.getId()}
                name={searchResult.getId()}
                aria-hidden={false}
                checked={selectedItems.has(searchResult)}
                onChange={() => setSelectedItems(Set([searchResult]))}
              />
            ) : (
              <Checkbox
                id={searchResult.getId()}
                name={searchResult.getId()}
                aria-hidden={false}
                checked={selectedItems.has(searchResult)}
                onChange={() => handleMultiSelect(searchResult)}
              />
            )}
            <Label className='u-mar-l_1' htmlFor={searchResult.getId()}>
              {rowLabelRenderer!(searchResult)}
            </Label>
          </ListGroupItem>
        ))}
      </ListGroup>
    </SplitCard.Section>
  );
};

type SelectedItemsProps = CardProps<'div'> & {
  chipColor?: ChipColor;
  chipIcon?: IconProp;
  innerText: (model: GenericModelType) => React.ReactNode;
};
const SelectedItems = ({
  className,
  innerText,
  chipColor = 'brand',
  chipIcon = 'times',
  ...rest
}: SelectedItemsProps) => {
  const {radio, selectedItems, setSelectedItems} = useContext(FindAndApplyContext);

  // Since radio inputs are designed not to be selectable, it does not make any sense to display the chip panel
  if (radio) {
    // eslint-disable-next-line no-console
    console.error(
      'SelectedItems panel is not available for FindAndApply when radio prop is set to true'
    );
    return null;
  }

  if (selectedItems.isEmpty()) {
    return null;
  }
  return (
    <SplitCard.Section className={className} {...rest}>
      <>
        {selectedItems.map((selectedItem: GenericModelType) => (
          <CheckboxChip
            color={chipColor}
            icon={chipIcon}
            onChange={() => setSelectedItems(selectedItems.delete(selectedItem))}
          >
            {innerText(selectedItem)}
          </CheckboxChip>
        ))}
      </>
    </SplitCard.Section>
  );
};

type FooterProps = CardProps<'div'> & {
  children?: (
    state: State & {
      ApplyButton: (
        props: OmitChildren<ButtonProps<'div'>> & PropsWithChildrenOptional
      ) => JSX.Element;
    } & {
      ClearButton: (props: ButtonProps<'div'>) => JSX.Element;
    }
  ) => React.ReactNode;
};

const Footer = ({children, className, ...rest}: FooterProps) => {
  const {onApply, selectedItems, onDismiss, onClear, setSelectedItems, ...context} =
    useContext(FindAndApplyContext);

  const ApplyButton = useCallback(
    ({
      children: buttonChildren,
      ...buttonProps
    }: OmitChildren<ButtonProps<'div'>> & PropsWithChildrenOptional) => (
      <Button
        onClick={() => {
          onApply(selectedItems.toList());
          onDismiss?.();
        }}
        {...buttonProps}
      >
        {buttonChildren || 'Apply'}
      </Button>
    ),
    [onApply, selectedItems, onDismiss]
  );

  const ClearButton = useCallback(
    ({children: buttonChildren, ...buttonProps}: ButtonProps<'div'>) => (
      <Button
        onClick={() => {
          setSelectedItems(Set());
          onClear?.();
        }}
        {...buttonProps}
      >
        {buttonChildren || 'Clear'}
      </Button>
    ),
    [onClear, setSelectedItems]
  );

  return (
    <SplitCard.Section className={className} {...rest}>
      {children ? (
        children!({
          ApplyButton,
          ClearButton,
          setSelectedItems,
          onApply,
          onDismiss,
          selectedItems,
          ...context
        })
      ) : (
        <ButtonGroup align='right'>
          <ApplyButton>Apply</ApplyButton>
        </ButtonGroup>
      )}
    </SplitCard.Section>
  );
};

const Message = ({className, ...rest}: TextProps<'span'>) => (
  <SplitCard.Section className='o-find-and-apply__message u-text-align_center'>
    <Text
      as='span'
      className={classnames('o-find-and-apply__msg u-mar-auto u-pad-t_5 u-pad-b_5', className)}
      color='tertiary'
      italic
      size='l'
      {...rest}
    />
  </SplitCard.Section>
);

FindAndApply.Header = Header;
FindAndApply.Body = Body;
FindAndApply.Message = Message;
FindAndApply.Footer = Footer;
FindAndApply.SelectedItems = SelectedItems;
export default FindAndApply;
