import {Set} from 'immutable';

import PassageType from './Passage/PassageType';
import ImageType from './Image/ImageType';
import AudioType from './Audio/AudioType';
import FreeFormType from './FreeForm/FreeFormType';
import SortableTableType from './SortableTableSupplement/SortableTableType';
import VideoSupplementType from './VideoSupplement/VideoSupplementType';
import SupplementNotFoundType from './SupplementNotFound/SupplementNotFoundType';

export const supplementTypes = Set([
  AudioType,
  PassageType,
  ImageType,
  FreeFormType,
  SortableTableType,
  VideoSupplementType,
  SupplementNotFoundType
]);

export function getSupplementTypeDefinition(type) {
  return supplementTypes.find(
    (definition) => {
      return definition.get('type') === type;
    },
    this,
    SupplementNotFoundType
  );
}
