import {List} from 'immutable';

import {GuideLevelModelV2} from 'resources/augmented/GuideLevel/GuideLevelModel.v2';
import {SubjectModelV2} from 'resources/GeneratedModels/Subject/SubjectModel.v2';

const DIFFICULTIES = ['easy', 'moderate', 'difficult'] as const;
type Difficulties = (typeof DIFFICULTIES)[number];

const QUESTION_TYPES = [
  'fill-in-the-blank',
  'free-entry',
  'multiple-choice',
  'text-highlight',
  'two-way',
  'snippet-selection',
  'passage-correction',
  'free-response',
  'graph-contains'
] as const;
type QuestionTypes = (typeof QUESTION_TYPES)[number];

const difficultyToNumMap = {
  easy: 1,
  moderate: 2,
  difficult: 3
};

const numToDifficultyMap = {
  1: 'easy',
  2: 'moderate',
  3: 'difficult'
};

const constructStandardsStandardSetsFilter = (selectedStandardIds, selectedStandardSetIds) => {
  const standardsFilter =
    selectedStandardIds.length > 0
      ? {
          questions_v3: {
            standards_v1: selectedStandardIds.join(',')
          }
        }
      : {};

  const standardSetsFilter =
    selectedStandardSetIds.length > 0
      ? {
          questions_v3: {
            standards_v1: {
              standard_set_v1: selectedStandardSetIds.join(',')
            }
          }
        }
      : {};

  return [standardSetsFilter, standardsFilter];
};

const constructQuestionTypesFilter = (selectedQuestionTypes) => {
  const questionTypesFilter: Array<object> = [];
  if (!selectedQuestionTypes.isEmpty()) {
    selectedQuestionTypes.forEach((type) => {
      questionTypesFilter.push({
        questions_v3: {
          question_type: type
        }
      });
    });
  }
  return questionTypesFilter;
};

const constructAllOfFilter = (questionTypesFilter, standardsFilter, standardSetsFilter) => {
  return {
    all_of: [{any_of: [standardsFilter, standardSetsFilter]}, {any_of: [...questionTypesFilter]}]
  };
};

export interface FilterOptions {
  questionDifficulties?: List<string>;
  questionTypes?: List<string>;
  standardIds?: string[];
  standardSetIds?: string[];
  subjects?: List<SubjectModelV2>;
  guideLevels?: List<GuideLevelModelV2>;
  hideAssessment?: boolean;
  hidePreviouslyAssigned?: boolean;
  negateStandardsFilter?: boolean;
  userId?: string;
}

interface QueryParamFilterObject {
  question_difficulties?: string;
  question_types?: string;
  standards?: string;
  standard_sets?: string;
  subjects?: string;
  guide_levels?: string;
  hide_assessment_questions?: boolean;
  hide_previously_assigned?: boolean;
  negate_standards_filter?: boolean;
}

interface FilterObject {
  question_difficulties?: string[];
  question_types?: string[];
  standards?: string[];
  standard_sets?: string[];
  subjects?: string[];
  guide_levels?: string[];
  hide_assessment_questions?: boolean;
  hide_previously_assigned?: boolean;
  negate_standards_filter?: boolean;
  user_id?: string;
}

const maybeApplyFilterMetaContext = (query, filters: FilterOptions) => {
  if (shouldApplyQuestionCountFilters(filters)) {
    const filterObject = getQueryParamFilterObject(filters);
    query = query.meta({context: {content_discovery_filters: filterObject}});
  }
  return query;
};

const shouldApplyQuestionCountFilters = (filters: FilterOptions) => {
  return (
    filters.questionDifficulties?.size ||
    filters.questionTypes?.size ||
    filters.standardIds?.length ||
    filters.standardSetIds?.length ||
    filters.subjects?.size ||
    filters.guideLevels?.size ||
    filters.hideAssessment ||
    filters.hidePreviouslyAssigned
  );
};

const getQueryParamFilterObject = (filters: FilterOptions) => {
  const filterObject: QueryParamFilterObject = {};

  if (filters.questionDifficulties?.size) {
    const difficulties = filters.questionDifficulties
      .toArray()
      .map((d) => difficultyToNumMap[d])
      .join(',');
    filterObject.question_difficulties = difficulties;
  }

  if (filters.questionTypes?.size) {
    filterObject.question_types = filters.questionTypes.toArray().join(',');
  }

  if (filters.standardIds?.length) {
    filterObject.standards = filters.standardIds.join(',');
  }

  if (filters.standardSetIds?.length) {
    filterObject.standard_sets = filters.standardSetIds.join(',');
  }

  if (filters.subjects?.size) {
    filterObject.subjects = filters.subjects.map((subject) => subject.getId()).join(',');
  }

  if (filters.guideLevels?.size) {
    filterObject.guide_levels = filters.guideLevels
      .map((guideLevel) => guideLevel.getId())
      .join(',');
  }

  if (filters.hideAssessment) {
    filterObject.hide_assessment_questions = true;
  }

  if (filters.hidePreviouslyAssigned) {
    filterObject.hide_previously_assigned = true;
  }

  if (filters.negateStandardsFilter) {
    filterObject.negate_standards_filter = true;
  }

  return filterObject;
};

const getFilterObject = (filters: FilterOptions) => {
  const filterObject: FilterObject = {};

  if (filters.questionDifficulties?.size) {
    const difficulties = filters.questionDifficulties
      .toArray()
      .map((d) => difficultyToNumMap[d].toString());
    filterObject.question_difficulties = difficulties;
  }

  if (filters.questionTypes?.size) {
    filterObject.question_types = filters.questionTypes.toArray();
  }

  if (filters.standardIds?.length) {
    filterObject.standards = filters.standardIds;
  }

  if (filters.standardSetIds?.length) {
    filterObject.standard_sets = filters.standardSetIds;
  }

  if (filters.subjects?.size) {
    filterObject.subjects = filters.subjects.map((subject) => subject.getId()).toArray();
  }

  if (filters.guideLevels?.size) {
    filterObject.guide_levels = filters.guideLevels
      .map((guideLevel) => guideLevel.getId())
      .toArray();
  }

  if (filters.hideAssessment) {
    filterObject.hide_assessment_questions = true;
  }

  if (filters.hidePreviouslyAssigned) {
    filterObject.hide_previously_assigned = true;
  }

  if (filters.negateStandardsFilter) {
    filterObject.negate_standards_filter = true;
  }

  if (filters.userId) {
    filterObject.user_id = filters.userId;
  }

  return filterObject;
};

export {
  maybeApplyFilterMetaContext,
  getFilterObject,
  constructAllOfFilter,
  constructQuestionTypesFilter,
  constructStandardsStandardSetsFilter,
  Difficulties,
  QuestionTypes,
  DIFFICULTIES,
  QUESTION_TYPES,
  numToDifficultyMap
};
