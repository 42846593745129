// @flow
import {fromJS, Set} from 'immutable';
import {values} from 'lodash';
import {Store} from 'client/framework';
import interactionEngineActions from 'client/InteractionEngineV2/InteractionEngineActions';
import questionsListSettingsActions from './QuestionsListSettings.actions';

export const FILTER_OPTIONS = {
  ANSWER_STATUS: {
    ANSWERED: 'answered',
    UNANSWERED: 'unanswered'
  },
  DIFFICULTIES: {
    EASY: 1,
    MODERATE: 2,
    DIFFICULT: 3
  },
  SORT: {
    ASCENDING: 'difficulty',
    DESCENDING: '-difficulty',
    RANDOM: 'random'
  }
};

class QuestionsListSettingsStore extends Store {
  constructor(name: string) {
    super(name);
    this.initialData = fromJS({
      difficulties: values(FILTER_OPTIONS.DIFFICULTIES), // Converted to Immutable.Set in getter
      includedAnswerStatuses: values(FILTER_OPTIONS.ANSWER_STATUS), // Converted to Immutable.Set in getter
      sortBy: FILTER_OPTIONS.SORT.ASCENDING,
      randomSeed: null,
      isOpen: false
    });
    this.setInitialData(this.initialData);
    this.handle(interactionEngineActions.RESET_STORE, this.resetStore);
    this.handle(questionsListSettingsActions.TOGGLE_DIFFICULTY, this._toggleDifficulty);
    this.handle(
      questionsListSettingsActions.TOGGLE_INCLUDE_ANSWER_STATUS,
      this._toggleIncludeAnswerStatuses
    );
    this.handle(questionsListSettingsActions.RESET_FILTERS, this.resetStore);
    this.handle(questionsListSettingsActions.SET_SORT_BY, this._setSortBy);
    this.handle(questionsListSettingsActions.SET_IS_OPEN, this._setIsOpen);
  }

  _toggleDifficulty(difficulty: number) {
    const difficulties = this.getDifficulties();

    const updatedDifficulties = difficulties.includes(difficulty)
      ? difficulties.delete(difficulty)
      : difficulties.add(difficulty);

    if (updatedDifficulties.isEmpty()) {
      return;
    }

    this.writeData('difficulties', updatedDifficulties);
  }

  _toggleIncludeAnswerStatuses(answerStatusType: string) {
    const currentStatuses = this.getIncludedAnswerStatuses();
    const updatedStatuses = currentStatuses.includes(answerStatusType)
      ? currentStatuses.delete(answerStatusType)
      : currentStatuses.add(answerStatusType);

    if (updatedStatuses.isEmpty()) {
      return;
    }

    this.writeData('includedAnswerStatuses', updatedStatuses);
  }

  _setSortBy(sortBy: string) {
    if (this.getSortBy() === sortBy) {
      return;
    }

    this.writeData('sortBy', sortBy);

    // If random, set a random seed
    if (sortBy === FILTER_OPTIONS.SORT.RANDOM) {
      let randomSeed = Math.random();
      while (randomSeed === 0) {
        // Though very unlikely, randomSeed must be greater than 0 and less than 1,
        // and it's possible for Math.random() to return 0
        randomSeed = Math.random();
      }
      this.writeData('randomSeed', randomSeed);
    }
  }

  _setIsOpen(isOpen: boolean) {
    this.writeData('isOpen', isOpen);
  }

  getDifficulties(): Set<number> {
    return this.readData('difficulties')
      .toSet()
      .sort();
  }

  getSortBy(): string {
    return this.readData('sortBy');
  }

  getRandomSeed(): number {
    return this.readData('randomSeed');
  }

  getIncludedAnswerStatuses(): Set<string> {
    return this.readData('includedAnswerStatuses').toSet();
  }

  shouldFilterByDifficulties(): boolean {
    return this.getDifficulties().size !== Object.keys(FILTER_OPTIONS.DIFFICULTIES).length;
  }

  shouldFilterByAnswerStatus(): boolean {
    return (
      this.getIncludedAnswerStatuses().size !== Object.keys(FILTER_OPTIONS.ANSWER_STATUS).length
    );
  }

  addFiltersToQuery(query: Object): Object {
    const filters = {};
    if (this.shouldFilterByDifficulties()) {
      filters.difficultyFilters = this.getDifficulties().toJS();
    }

    const sortBy = this.getSortBy();
    if (sortBy === FILTER_OPTIONS.SORT.RANDOM) {
      filters.randomSeed = this.getRandomSeed();
    } else {
      filters.sortBy = sortBy;
    }

    if (this.shouldFilterByAnswerStatus()) {
      // Only two options exist at the moment, so just if we're in here,
      // .first() is going to be the one we want.
      const answerStatusToInclude = this.getIncludedAnswerStatuses().first();
      if (answerStatusToInclude === FILTER_OPTIONS.ANSWER_STATUS.ANSWERED) {
        filters.onlyIncludeAnsweredQuestions = true;
      } else if (answerStatusToInclude === FILTER_OPTIONS.ANSWER_STATUS.UNANSWERED) {
        filters.onlyIncludeUnansweredQuestions = true;
      }
    }

    return { ...query, ...filters};
  }

  isOpen(): boolean {
    return this.readData('isOpen');
  }
}

export default new QuestionsListSettingsStore('QuestionsListSettingsStore');
