import {fromJS, Map, List} from 'immutable';
import {Store} from 'client/framework';
import assignmentV2IEActions from './AssignmentV2IE.actions';
import {resource} from '@albert-io/json-api-framework/request/builder';
import appStore from 'client/AppStore';
import sessionStore from 'client/Session/SessionStore';
import makeConstants from 'lib/makeConstants';
import systemTimeOffsetStore from 'client/generic/SystemTimeOffset/SystemTimeOffset.store';

export const sectionStatuses = makeConstants('COMPLETE', 'IN_PROGRESS', 'NOT_STARTED');

class AssignmentV2IEStore extends Store {
  constructor(name) {
    super(name);
    this.setInitialData(
      fromJS({
        isRequestPending: false,
        error: null,
        isViewingStagingDetails: true,
        assignmentPromise: null,
        // Once the assignment is submitted, the user can choose to navigate between the sections
        selectedActiveSectionId: null,
        /**
         * We're going to store the assignment's guesses on the store so we can show the user which
         * questions they've answered. In assignments v1, we do this via meta, but that is not available
         * here so this will have to do for now.
         *
         * This Map looks like this = Map({
         *   guessId: GuessModel
         * });
         */
        assignmentGuesses: {},
        showSubmitSectionModal: false,
        assignmentId: null,
        isPostAssignmentSubmission: false,
        hasWipedQuestionStores: false
      })
    );
    this.handle(assignmentV2IEActions.CLEAR_ERROR, () => this.writeData('errors', null));
    this.handle(
      assignmentV2IEActions.SET_IS_VIEWING_STAGING_DETAILS,
      this.setProperty('isViewingStagingDetails')
    );
    this.handle(
      assignmentV2IEActions.SET_SELECTED_ACTIVE_SECTION_ID,
      this.setProperty('selectedActiveSectionId')
    );
    this.handle(
      assignmentV2IEActions.SET_SHOW_SUBMIT_SECTION_MODAL,
      this.setProperty('showSubmitSectionModal')
    );
    this.handle(assignmentV2IEActions.INIT_ASSIGNMENT, this._init);
    this.handle(assignmentV2IEActions.START_ASSIGNMENT, this._startAssignment);
    this.handle(assignmentV2IEActions.CONTINUE_ASSIGNMENT, this._continueAssignment);
    this.handle(
      assignmentV2IEActions.PRACTICE_EXAM_GUESS_PLACED,
      this._handlePracticeExamGuessPlaced
    );
    this.handle(assignmentV2IEActions.SUBMIT_CURRENT_SECTION, this._submitCurrentSection);
    this.handle(assignmentV2IEActions.START_NEXT_SECTION, this._startNextSection);
    this.handle(assignmentV2IEActions.HAS_WIPED_QUESTION_STORES, this._setHasWipedQuestionStores);
    this.handle(assignmentV2IEActions.RESET_STORE, this._resetStore);
  }

  async _init() {
    this.writeData('assignmentId', appStore.routerProps.params.assignmentId);
    const student = await this.getStudentAssignmentQuery().getResourcePromise();
    if (this.hasStartedAssignment()) {
      const existingGuesses = student
        .getAssignments()
        .first()
        .getGuesses()
        .reduce((acc, guess) => {
          return acc.set(guess.getQuestion().getId(), guess);
        }, Map());
      this.writeData('assignmentGuesses', existingGuesses);
    }
  }

  _makeAssignmentPromiseHandler(asyncFunc) {
    return (...args) => {
      this.writeData('isRequestPending', true);
      const pendingPromise = asyncFunc(...args).finally(() => {
        this.writeData('isRequestPending', false);
      });
      this.writeData('assignmentPromise', pendingPromise);
    };
  }

  _setHasWipedQuestionStores() {
    this.writeData('hasWipedQuestionStores', true);
  }

  _startAssignment = this._makeAssignmentPromiseHandler(async () => {
    const student = this.getStudentAssignmentQuery().getResource();
    const assignment = this.getAssignment();
    const assignmentId = assignment.getId();

    await student
      .addRelationship({
        type: 'assignments_v3',
        relation: [assignmentId],
        customMeta: Map({
          [assignmentId]: Map({
            start_time: 'now'
          })
        })
      })
      .save();

    await this.getStudentAssignmentQuery().getResourcePromise();
  });

  _continueAssignment = this._makeAssignmentPromiseHandler(async () => {
    if (this.getAssignment().getSections().isEmpty()) {
      await this.getAssignmentWithQuestionSetsQuery().getResourcePromise();
    } else {
      await this.getSectionWithQuestionSetsQuery(
        this.getLatestStartedSection().getId()
      ).getResourcePromise();
    }
    this.writeData('isViewingStagingDetails', false);
  });

  _submitCurrentSection = this._makeAssignmentPromiseHandler(async () => {
    const latestStartedSection = this.getLatestStartedSection();
    const latestSectionId = latestStartedSection.getId();
    const student = this.getStudentAssignmentQuery().getResource();
    await student
      .addRelationship({
        type: 'sections_v1',
        relation: [latestSectionId],
        customMeta: Map({
          [latestSectionId]: Map({
            submitted_at: 'now'
          })
        })
      })
      .save();
    this.getSectionWithQuestionSetsQuery(latestSectionId).invalidateInterest();
    this.writeData('isViewingStagingDetails', true);
    this._handleSectionSubmissionQueryInvalidation();
    await Promise.all([
      this.getStudentAssignmentQuery().getResourcePromise(),
      this.getSectionWithQuestionSetsQuery(latestSectionId).getResourcePromise()
    ]);
  });

  _startNextSection = this._makeAssignmentPromiseHandler(async () => {
    const student = this.getStudentAssignmentQuery().getResource();
    const activeSectionId = this.getOpenAssignmentActiveSectionId();
    await student
      .addRelationship({
        type: 'sections_v1',
        relation: [activeSectionId],
        customMeta: Map({
          [activeSectionId]: Map({
            start_time: 'now'
          })
        })
      })
      .save();
    await this.getStudentAssignmentQuery().getResourcePromise();
  });

  _handlePracticeExamGuessPlaced = this._makeAssignmentPromiseHandler(async (guessModel) => {
    /**
     * Write the guess to the store to avoid having to invalidate the whole guess query after every guess
     */
    const updatedGuesses = this.getAssignmentGuesses().set(
      guessModel.getQuestion().getId(),
      guessModel
    );
    this.writeData('assignmentGuesses', updatedGuesses);

    const shouldAdvanceExam = this.getAssignmentGuesses()
      .keySeq()
      .isSuperset(this.getAllQuestionsInActiveSection().keySeq());

    if (!shouldAdvanceExam) {
      return;
    }

    this._handleSectionSubmissionQueryInvalidation();
    this.getStudentAssignmentQuery().invalidateInterest();
    await this.getStudentAssignmentQuery().getResourcePromise();
    this.writeData('isViewingStagingDetails', true);
  });

  _handleSectionSubmissionQueryInvalidation() {
    const sortedSections = this.getAssignment().getSortedSections();
    const isSubmittingFinalSection =
      sortedSections.last().getId() === this.getLatestStartedSection().getId();
    if (!isSubmittingFinalSection) {
      return;
    }
    this.getAssignmentWithQuestionSetsQuery().invalidateInterest();
    sortedSections.forEach((section) => {
      this.getSectionWithQuestionSetsQuery(section.getId()).invalidateInterest();
    });
    this.writeData('isPostAssignmentSubmission', true);
  }

  _resetStore() {
    // This must happen before resetStore, as it relies on store data.
    this.getStudentAssignmentQuery().invalidateInterest();
    this.resetStore();
  }

  getAssignment() {
    return this.getStudentAssignmentQuery().getResource().getAssignments().first();
  }

  getCurrentStudentAssignmentMeta() {
    return this.getStudentAssignmentQuery()
      .getResource()
      .getAssignments()
      .first()
      .getStudentRelationships()
      .first();
  }

  getQuestionSets() {
    /**
     * We can only request question sets for an exam or section we have started.
     * Because of this, fetching the right question sets is a little more complicated than
     * in other IE's.
     */

    /**
     * If we're not ready, return an empty List.
     */
    if (!this.getStudentAssignmentQuery().isResourcePopulated()) {
      return List();
    }

    const assignment = this.getAssignment();
    const isClosed = !assignment.isAssignmentOpenForStudent();

    /**
     * If our exam is closed, and you're on a section, return the question sets for that section.
     * Return an empty list if viewing the exam details.
     */
    if (isClosed && assignment.hasSections()) {
      return this.getClosedAssignmentActiveSection()
        ? this.getClosedAssignmentActiveSection().getSortedQuestionSets()
        : List();
    }

    if (isClosed) {
      return this.getAssignmentWithQuestionSetsQuery().getResource().getSortedQuestionSets();
    }

    /**
     * If we've on a staging view (i.e. exam or section details), or we don't yet know which
     * question sets to request, return an empty list
     */
    if (this.isViewingStagingDetails() || !this.hasStartedAssignment()) {
      return List();
    }

    /**
     * If our assignment does not have sections, return all question sets in that assignment
     */
    if (this.getAssignment().getSections().isEmpty()) {
      return this.getAssignmentWithQuestionSetsQuery().getResource().getSortedQuestionSets();
    }

    /**
     * If our assignment has sections, return the quetsion sets in the last started section
     */
    if (this.getLatestStartedSection()) {
      return this.getOpenAssignmentActiveSection()
        ? this.getOpenAssignmentActiveSection().getSortedQuestionSets()
        : List();
    }

    /**
     * We shouldn't get here, but if the previous conditions were not met, return an empty list
     */
    return new List();
  }

  getOpenAssignmentActiveSection() {
    const latestStartedSection = this.getLatestStartedSection();
    if (!latestStartedSection) {
      return null;
    }
    return this.getSectionWithQuestionSetsQuery(latestStartedSection.getId()).getResource();
  }

  getClosedAssignmentActiveSection() {
    const selectedActiveSectionId = this.getSelectedActiveSectionId();
    if (!selectedActiveSectionId) {
      return null;
    }
    return this.getSectionWithQuestionSetsQuery(selectedActiveSectionId).getResource();
  }

  getAllQuestionsInActiveSection() {
    const currentSectionQuestionSets = this.getQuestionSets();
    return this.getAllQuestionsInSection(currentSectionQuestionSets);
  }

  getAllQuestionsInSection(section) {
    return section.reduce((acc, questionSet) => {
      const questionsAsMap = questionSet
        .getQuestions()
        .reduce((acc, question) => acc.set(question.getId(), question), Map());
      return acc.merge(questionsAsMap);
    }, Map());
  }

  hasStartedAssignment() {
    return Boolean(this.getAssignment().getStudentRelationships().first().getStartTime());
  }

  getStudentAssignmentQuery() {
    const userId = sessionStore.getUserId();
    return resource('student_v2')
      .mandarkEndpoint(['students_v2', userId])
      .include('assignments_v3.sections_v1.students_v2')
      .include('assignments_v3.guesses_v1.question_v1')
      .include('assignments_v3.subject_v2')
      .withMeta('assignment_v3,section_v1')
      .customQuery({
        meta: {
          context: {
            student: {
              id: userId
            }
          }
        }
      })
      .filter({
        included: {
          assignments_v3: {
            id: this.getAssignmentId()
          },
          'assignments_v3.sections_v1.students_v2': {
            id: userId
          },
          'assignments_v3.guesses_v1': {
            student_v1: {
              id: userId
            }
          }
        }
      });
  }

  getAssignmentWithQuestionSetsQuery() {
    return resource('assignment_v3')
      .mandarkEndpoint(['assignments_v3', this.getAssignmentId()])
      .include('question_sets_v1.questions_v1.supplements_v1')
      .include('question_sets_v1.subject_v2')
      .include('question_sets_v1.tags_v1')
      .meta(
        {
          student_id: sessionStore.getUserId()
        },
        sessionStore.isStudent()
      )
      .meta(
        {
          teacher_id: sessionStore.getUserId()
        },
        sessionStore.isTeacher()
      );
  }

  getSectionWithQuestionSetsQuery(sectionId) {
    return resource('section_v1')
      .mandarkEndpoint(['sections_v1', sectionId])
      .include('question_sets_v1.questions_v1.supplements_v1')
      .include('question_sets_v1.subject_v2')
      .include('question_sets_v1.tags_v1')
      .meta(
        {
          student_id: sessionStore.getUserId()
        },
        sessionStore.isStudent()
      )
      .meta(
        {
          teacher_id: sessionStore.getUserId()
        },
        sessionStore.isTeacher()
      );
  }

  getSortedSections() {
    return this.getStudentAssignmentQuery()
      .getResource()
      .getAssignments()
      .first() // We include assignments filtered by ID, so there is only one
      .getSortedSections();
  }

  getLatestStartedSection() {
    if (!this.getStudentAssignmentQuery().isResourcePopulated()) {
      return null;
    }
    return this.getSortedSections().findLast((section) => {
      return this.getSectionStudentRelationship(section).getStartTime() !== null;
    });
  }

  isLatestStartedSectionOutOfTime() {
    const latestSectionTimeLimit = this.getLatestStartedSection().getTimeLimit();
    if (latestSectionTimeLimit === null) {
      return false;
    }
    const startTime = this.getSectionStudentRelationship(
      this.getLatestStartedSection()
    ).getStartTime();
    return (
      systemTimeOffsetStore.getCurrentTime().diff(startTime, 'seconds') >=
      latestSectionTimeLimit * 60
    );
  }

  getFirstIncompleteSection() {
    return this.getSortedSections().find((section) => {
      return this.getSectionStudentRelationship(section).getSubmittedAt() === null;
    });
  }

  isFirstIncompleteSectionStarted() {
    return (
      this.getSectionStudentRelationship(this.getFirstIncompleteSection()).getStartTime() !== null
    );
  }

  getLatestStartedSectionWithQuestionSetsQuery() {
    const latestSectionId = this.getLatestStartedSection().getId();
    return this.getSectionWithQuestionSetsQuery(latestSectionId);
  }

  getSectionCompletionStatus(section) {
    const studentRelationship = this.getSectionStudentRelationship(section);
    const hasStartTime = Boolean(studentRelationship.getStartTime());
    const hasSubmittedAt = Boolean(studentRelationship.getSubmittedAt());
    if (hasStartTime && hasSubmittedAt) {
      return sectionStatuses.COMPLETE;
    } else if (hasStartTime) {
      return sectionStatuses.IN_PROGRESS;
    } else {
      return sectionStatuses.NOT_STARTED;
    }
  }

  getSectionStudentRelationship(section) {
    return section
      .getStudents()
      .first() // There is only one student
      .getSectionRelationships()
      .first(); // There is only one student/section relationship per section
  }

  hasStudentStartedSection(section) {
    return this.getSectionStudentRelationship(section).getStartTime() !== null;
  }

  getLatestStartedSectionStudentRelationships() {
    return this.getSectionStudentRelationship(this.getLatestStartedSection());
  }

  getGuessForQuestion(questionId) {
    return this.getAssignmentGuesses().get(questionId, null);
  }

  getOpenAssignmentActiveSectionId() {
    const assignment = this.getAssignment();
    return assignment.getSections().isEmpty() || !assignment.hasStudentStartedAssignment()
      ? null
      : this.getFirstIncompleteSection().getId();
  }

  isOpenAssignmentActiveSectionStarted() {
    const activeSectionId = this.getOpenAssignmentActiveSectionId();
    const activeSection = this.getAssignment()
      .getSections()
      .find((section) => section.getId() === activeSectionId);
    return this.getSectionCompletionStatus(activeSection) !== sectionStatuses.NOT_STARTED;
  }

  getQuestionsInQuestionSetList(questionSetsList) {
    return questionSetsList
      .getQuestionSets()
      .reduce((acc, questionSet) => acc.concat(questionSet.getQuestions()), List());
  }

  /**
   * Can't derive this from the section/assignment meta because we don't invalidate the
   * query after every guess.
   */
  getCurrentSectionUnansweredQuestionCount() {
    return this.getSectionUnansweredQuestionCount(this.getAllQuestionsInActiveSection());
  }

  getSectionUnansweredQuestionCount(questionSet) {
    return questionSet.filterNot((question) => this.getAssignmentGuesses().has(question.getId()))
      .size;
  }

  isLatestStartedSectionSubmitted() {
    return (
      this.getSectionStudentRelationship(this.getLatestStartedSection()).getSubmittedAt() !== null
    );
  }

  // This is not being used at the moment. Turns out it was only being used for an analytics event that we
  // no longer care about. Keeping it in here because it works and could be used in the future.
  isLastAssignmentQuestion() {
    if (!this.getAssignment().hasSections()) {
      const questionCount = this.getAssignment().getMeta().getCountOfQuestions();
      const guessCount = this.getAssignmentGuesses().size;
      return guessCount === questionCount - 1;
    }
    const currentSectionId = this.getLatestStartedSection().getId();
    const finalSectionId = this.getSortedSections().last().getId();
    if (currentSectionId !== finalSectionId) {
      return false;
    }
    const countOfCurrentSectionAnsweredQuestions = this.getQuestionSets().reduce(
      (acc, questionSet) => {
        return (
          acc +
          this.getAssignmentGuesses().count((guess, guessQuestionId) =>
            questionSet.getQuestionIds().includes(guessQuestionId)
          )
        );
      },
      0
    );
    return countOfCurrentSectionAnsweredQuestions === this.getQuestionSets().size - 1;
  }

  isAssignmentSubmitted() {
    return this.getAssignment().getMeta().isStudentSubmitted();
  }

  getAssignmentId = () => this.readData('assignmentId');

  isViewingStagingDetails = () => this.readData('isViewingStagingDetails');

  getAssignmentPromise = () => this.readData('assignmentPromise');

  getAssignmentGuesses = () => this.readData('assignmentGuesses');

  getSelectedActiveSectionId = () => this.readData('selectedActiveSectionId');

  shouldShowSubmitSectionModal = () => this.readData('showSubmitSectionModal');

  isRequestPending = () => this.readData('isRequestPending');

  isPostAssignmentSubmission = () => this.readData('isPostAssignmentSubmission');

  hasWipedQuestionStores = () => this.readData('hasWipedQuestionStores');
}

export default new AssignmentV2IEStore('AssignmentV2IEStore');
