// @flow
import {List} from 'immutable';
import AssignmentQuestionsListStore from '../../AssignmentQuestionsList/AssignmentQuestionsList.store';
import teacherAssignmentTemplateQuestionsListActions from './TeacherAssignmentTemplateQuestionsList.actions';
import type {QueryBuilder} from '@albert-io/json-api-framework/request/builder';
import {resource} from '@albert-io/json-api-framework/request/builder';
import sessionStore from 'client/Session/SessionStore';

/**
 * @class TeacherAssignmentTemplateQuestionsListStore
 * @extends {AssignmentQuestionsListStore}
 */
class TeacherAssignmentTemplateQuestionsListStore extends AssignmentQuestionsListStore {
  constructor(name: string) {
    super(name);
    this.handle(
      teacherAssignmentTemplateQuestionsListActions.SELECT_FIRST_QUESTION,
      this._selectFirstQuestion
    );
  }

  /**
   * @override
   */
  get questionSets(): List<*> {
    if (!this.hasReceivedAssignment) {
      return new List();
    }
    return this.getAssignment().getOrderedQuestionSets();
  }

  _selectFirstQuestion() {
    const result: Map<{
      question: *,
      set: *
    }> = this.getAssignment().getFirstSetAndQuestion();

    this._setActiveQuestion(result.get('question'));
    this._setActiveQuestionSet(result.get('set'));
  }

  getTemplateId(): string {
    return this.assignmentId;
  }

  /**
   * This method name is a _little_ confusing, but is required to allow use of the <AssignmentInitializer />
   * component, amoungst other components that take in our *QuestionsList stores.
   *
   * @override
   */
  getAssignmentQuery(): QueryBuilder {
    return this._getAssignmentTemplateQuery();
  }

  _getAssignmentTemplateQuery(): QueryBuilder {
    return resource('template_v1')
      .mandarkEndpoint(['templates_v1', this.getTemplateId()])
      .include('question_sets_v1')
      .include('question_sets_v1.questions_v1')
      .include('question_sets_v1.subject_v2')
      .withMeta('template_v1')
      .customQuery(
        {
          meta: {
            teacher_id: sessionStore.getUserId()
          }
        },
        sessionStore.hasValidSession()
      );
  }
}

export default new TeacherAssignmentTemplateQuestionsListStore(
  'TeacherAssignmentTemplateQuestionsListStore'
);
