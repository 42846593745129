import React from 'react';

import {useSlate} from 'slate-react';

import {IconButton} from '@albert-io/atomic';

import {withWorkaround, handleIndent, ButtonProps} from '../../Utils/editor.utils';

export const InOutdentButton = ({
  type,
  icon,
  ...optionalProps
}: ButtonProps<'indent' | 'outdent'>) => {
  const editor = useSlate();

  return (
    <IconButton
      icon={icon}
      variant='outlined'
      onClick={(event) => {
        event.preventDefault();
        withWorkaround(editor, () => handleIndent(editor, type));
      }}
      label={type}
      {...optionalProps}
    />
  );
};
