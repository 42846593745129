import React from 'react';
import PropTypes from 'prop-types';
import {withApplicationMetadata} from 'client/Routing/withApplicationMetadata';
import tours from 'client/SubjectView/subjectViewTours';
import {Button, ListGroup, ListGroupItem} from '@albert-io/atomic';
import './subject-tours.scss';

const SubjectTours = ({subject}) => {
  const subjectSlug = subject.getUrlSlug();
  const id = tours[subjectSlug];
  return (
    <ListGroup className='subject-tour__list-group'>
      <ListGroupItem className='subject-tour__list-group-item'>
        Learn how the content in this course is organized.
        <Button as='a' href={`/${subjectSlug}?product_tour_id=${id}`}>
          View tour
        </Button>
      </ListGroupItem>
    </ListGroup>
  );
};

SubjectTours.propTypes = {
  subject: PropTypes.instanceOf('subject_v2')
};

export default withApplicationMetadata(SubjectTours, (props) => ({
  title: `${props.subject.getName()} | Tours | Albert`
}));
