// @flow
import {Map, List} from 'immutable';
import moment from 'moment';
import assignmentConstants from 'client/Assignments/constants';
import {stats, BUCKETS, getBucketByAccuracy} from 'lib/StatsUtil';
import {humanReadableDurationString} from 'lib/timeUtils';
import systemTimeOffsetStore from 'client/generic/SystemTimeOffset/SystemTimeOffset.store';
import constants from 'client/constants';
import * as TimeUtil from 'lib/timeUtils';
import type {
  QuestionSetModelV1,
  QuestionType,
  StudentModelV1,
  StudentModelV2
} from '@albert-io/models';

interface IdsToSelect {
  classroomId?: string;
  studentId?: string;
}

const assignmentExtensions = {
  /**
   * Get the average grade for display from `assignment_v*.meta`.
   */
  getAverageGradeForDisplay(): string {
    return Number.isFinite(this.getMeta().getAverageGrade())
      ? `${(this.getMeta().getAverageGrade() * 100).toFixed()}%`
      : 'N/A';
  },

  getClassroomNames(): string {
    return this.getClassrooms()
      .map((classroom) => classroom.getName())
      .join(', ');
  },

  getCountOfStudents(): number {
    return this.getStudents().size;
  },

  getCurriculumColorsArray(): string[] {
    const curriculumColors = this.getMeta().getCurriculumColors();
    return curriculumColors ? this.getMeta().getCurriculumColors().split(',') : [];
  },

  getSortedQuestionSets(): List<*> {
    const func = () =>
      this.getQuestionSets().sortBy((questionSet) =>
        questionSet.getIn(['relationshipMeta', 'assignment', this.getId(), 'position'], 0)
      );
    return this.cache('getSortedQuestionSets', func);
  },

  getQuestionSetForActiveQuestion(questionId: string): QuestionSetModelV1 {
    return this.getQuestionSets().find((set) => {
      const questions = set.getQuestions();
      const matchingSet = questions.find((question) => {
        return question.getId() === questionId;
      });
      return matchingSet;
    });
  },

  getSortedQuestions(): List<QuestionType> {
    const sortedQuestionsFunc = () => {
      return this.getSortedQuestionSets().reduce((acc, questionSet) => {
        return acc.concat(questionSet.getQuestions());
      }, List());
    };
    return this.cache('getSortedQuestions', sortedQuestionsFunc);
  },

  getSortedSections(): List<*> {
    const func = () => this.getSections().sortBy((section) => section.getPosition());
    return this.cache('getSortedSections', func);
  },

  // getters from settings col
  // will fetch settings from student assignment based on studentId
  // or from classroom settings based on classroomId
  // or from assignment settings if no studentId or classroomId is provided
  // or the base assignment setting columns
  getSettingsFromRelations(idsToSelect: IdsToSelect = {}) {
    const {classroomId, studentId} = idsToSelect;

    // student settings should always be returned first
    if (studentId) {
      const students = this.getStudents();
      const student = students.find((st) => st.getId() === studentId);
      const studentAssignmentRelationships = student.get('relationshipMeta').get('assignment');
      const studentAssignment = studentAssignmentRelationships.get(this.getId());
      const settings = studentAssignment?.get('settings');
      if (settings) {
        return settings;
      }
    }
    if (classroomId) {
      const classrooms = this.getClassrooms().isEmpty()
        ? this.getStudentClassrooms()
        : this.getClassrooms();
      const classroom = (classrooms || List()).find((cr) => cr.getId() === classroomId);
      const classroomAssignment =
        classroom?.getAssignmentRelationships?.(this.getId()) ||
        this.getClassroomRelationships(classroomId);
      const settings = classroomAssignment?.getSettings?.();
      if (settings) {
        return settings;
      }
    }
    return this.getSettings();
  },

  getIsAllowLateSubmissionsFromSettings(idsToSelect: IdsToSelect = {}): boolean {
    const settings = this.getSettingsFromRelations(idsToSelect);
    return settings?.get('allow_late_submissions');
  },

  getIsAutoAssignNewClassroomEnrolleesFromSettings(idsToSelect: IdsToSelect = {}): boolean {
    const settings = this.getSettingsFromRelations(idsToSelect);
    return settings?.get('auto_assign_new_classroom_enrollees');
  },

  getCorrectAnswerSettingFromSettings(idsToSelect: IdsToSelect = {}): string {
    const settings = this.getSettingsFromRelations(idsToSelect);
    return settings?.get('correct_answer_setting');
  },

  getDueDateFromSettings(idsToSelect: IdsToSelect = {}): moment {
    // assignments list has due dates coming from meta
    // not using the extensions since they return `now`
    // if undefined
    const metaDueDate =
      this.get('meta').get('student_settings_due_date') ||
      this.get('meta').get('classroom_settings_due_date');

    if (metaDueDate) {
      return metaDueDate;
    }

    const settings = this.getSettingsFromRelations(idsToSelect);
    const settingsValue = settings?.get?.('due_date');
    const momentValue = settingsValue && moment(settingsValue);
    const dueDate = momentValue?.isValid?.() ? momentValue : settingsValue;
    return dueDate;
  },

  getStartDateFromSettings(idsToSelect: IdsToSelect = {}): moment {
    const settings = this.getSettingsFromRelations(idsToSelect);
    const settingsValue = settings?.get?.('start_date');
    const momentValue = settingsValue && moment(settingsValue);
    const startDate = momentValue?.isValid?.() ? momentValue : settingsValue;
    return startDate;
  },

  getTimeLimitFromSettings(idsToSelect: IdsToSelect = {}): number {
    const settings = this.getSettingsFromRelations(idsToSelect);
    return settings?.get('time_limit');
  },

  getIsRandomizeQuestionsFromSettings(idsToSelect: IdsToSelect = {}): boolean {
    const settings = this.getSettingsFromRelations(idsToSelect);
    return settings?.get('randomize_question_order');
  },

  getMessageFromSettings(idsToSelect: IdsToSelect = {}): string {
    const settings = this.getSettingsFromRelations(idsToSelect);
    return settings?.get('message');
  },

  getLtiLineItemIdFromSettings(idsToSelect: IdsToSelect = {}): string {
    const settings = this.getSettingsFromRelations(idsToSelect);
    return settings?.get('lti_line_item_id');
  },

  hasSections(): boolean {
    return !this.getSections().isEmpty();
  },

  hasStartDate(idsToSelect: IdsToSelect = {}) {
    const insertedAtDateAndStartDateAreSame = this.getInsertedAt().isSame(
      this.getStartDateFromSettings(idsToSelect),
      'minute'
    );
    const updatedAtDateAndStartDateAreSame = this.getUpdatedAt().isSame(
      this.getStartDateFromSettings(idsToSelect),
      'minute'
    );
    return !updatedAtDateAndStartDateAreSame && !insertedAtDateAndStartDateAreSame;
  },

  isPastDueDate(idsToSelect: IdsToSelect = {}): boolean {
    return this.getDueDateFromSettings(idsToSelect).isBefore(
      systemTimeOffsetStore.getCurrentTime()
    );
  },

  isAssigned(): boolean {
    return this.getStatus() === 'assigned';
  },

  isOpen(idsToSelect: IdsToSelect = {}): boolean {
    return (
      this.isAssigned() &&
      !this.isScheduled(idsToSelect) &&
      (this.getIsAllowLateSubmissionsFromSettings(idsToSelect) === true ||
        this.isPastDue(idsToSelect) === false)
    );
  },

  getSumOfSectionTimeLimits(): number {
    const func = () =>
      this.getSections().reduce((acc, section) => {
        return acc + section.getTimeLimit();
      }, 0);
    return this.cache('getSumOfSectionTimeLimits', func);
  },

  getQuestionCountsByDifficulty(): Object {
    const func = () => {
      const difficulties = constants.QUESTION_IMPORT.DIFFICULTIES;
      const mirrored = Object.entries(difficulties).reduce((acc, [label, value]) => {
        acc[value] = label;
        return acc;
      }, {});
      const startingCount = Object.keys(difficulties).reduce((acc, key) => {
        acc[key] = 0;
        return acc;
      }, {});
      return this.getSortedQuestions().reduce((acc, question) => {
        acc[mirrored[question.getDifficulty()]]++;
        return acc;
      }, startingCount);
    };
    return this.cache('getQuestionCountsByDifficulty', func);
  },

  // logic used to identify the classroom settings to pick for students
  // that have one assignment for multiple classes that they are a part of
  getClassroomWithLatestAssignmentDueDate(): StudentClassroomModelV1 {
    const classrooms = this.getClassrooms().isEmpty()
      ? this.getStudentClassrooms()
      : this.getClassrooms();

    const classroom = (classrooms || List()).reduce((latest, curr) => {
      if (!latest) {
        return curr;
      }

      const currAssignment = curr.getAssignmentRelationships().first();
      const latestAssignment = latest.getAssignmentRelationships().first();

      if (currAssignment && latestAssignment) {
        // `getSettingsFromRelations` does not exist on these assignment variables
        // but they should be scoped already by the request
        const latestAssignmentSettings = latestAssignment.getSettings();
        const currAssignmentSettings = currAssignment.getSettings();

        const latestDueDate = new Date(latestAssignmentSettings?.get('due_date') || 0);
        const currentDueDate = new Date(currAssignmentSettings?.get('due_date') || 0);

        if (latestDueDate < currentDueDate) {
          return curr;
        }
      }

      return latest;
    }, null);

    return classroom;
  },

  /**
   * Student assignment helpers
   */

  getStudentStartTime(studentId: ?string): string | null {
    checkStudentRelationshipEntityCount(this, studentId, 'getStudentStartTime');
    return getSingleStudentRelationship(this, studentId).getStartTime();
  },

  hasStudentStartedAssignment(studentId: ?string): boolean {
    return this.getStudentStartTime(studentId) !== null;
  },

  getStudentSubmittedAt(studentId: ?string): string {
    checkStudentRelationshipEntityCount(this, studentId, 'getStudentSubmittedAt');
    return getSingleStudentRelationship(this, studentId).getSubmitted();
  },

  hasStudentSubmittedAssignment(studentId: ?string): boolean {
    return this.getStudentSubmittedAt(studentId) !== null;
  },

  isAssignmentOpenForStudent(studentId: ?string, classroomId?: string): boolean {
    checkStudentRelationshipEntityCount(this, studentId, 'isAssignmentOpenForStudent');

    // If the student has submitted the assignment, it is not open
    if (this.hasStudentSubmittedAssignment(studentId)) {
      return false;
    }
    // If the student has not submitted and the assignment has no due date, it's open
    if (
      !this.getDueDateFromSettings({
        classroomId,
        studentId
      })
    ) {
      return true;
    }
    // If the student has not submitted, but the assignment allows late submissions, it's open
    if (
      this.getIsAllowLateSubmissionsFromSettings({
        classroomId,
        studentId
      })
    ) {
      return true;
    }

    // If the student has not submitted, and the due date is in the future, it's open
    if (
      this.getDueDateFromSettings({
        classroomId,
        studentId
      }).isAfter(systemTimeOffsetStore.getCurrentTime())
    ) {
      return true;
    }
    // If the student has not submitted, but the due date is in the past, it's closed
    return false;
  },

  setStartDate(value: string): AssignmentModelV2 {
    if (value === 'now') {
      return this.setField('start_date', value);
    }

    const startDate = moment(value);
    const startTime = startDate.format('HH:mm:ss');

    const startDateToUnixTimestamp = startDate.isValid()
      ? TimeUtil.convertDateAndTimeToUnixTimestamp({
          date: startDate.format('YYYY-MM-DD'),
          time: startTime
        })
      : null;

    return this.setField('start_date', startDateToUnixTimestamp);
  },

  getPercentageCorrect(): number {
    return stats.percentage(
      this.getMeta().getStudentCountOfCorrectGuesses(),
      this.getMeta().getStudentCountOfGuesses()
    );
  },

  getStudentTimeSpentMinutes(idsToSelect: IdsToSelect = {}): string {
    if (this.hasStudentStartedAssignment() === false) {
      /**
       * If they haven't started, it's always 0!
       */
      return humanReadableDurationString(moment.duration(0));
    }

    const timeLimit: number | null = this.getTimeLimitFromSettings(idsToSelect);
    if (timeLimit === null) {
      /**
       * If there isn't a time limit on the assignment, we **ALWAYS** return the time spent from the meta
       * which is calculated from the `timeElapsed` on each individual guess.
       */
      return humanReadableDurationString(moment.duration(this.getMeta().getStudentTimeSpent()));
    }
    /**
     * At this point, we're only dealing with timed assignments.
     */
    const studentStartTime = this.getStudentStartTime(idsToSelect.studentId);
    const studentEndTime = this.hasStudentSubmittedAssignment(idsToSelect.studentId)
      ? moment(this.getStudentSubmittedAt(idsToSelect.studentId))
      : systemTimeOffsetStore.getCurrentTime();
    /**
     * Since it's a timed assignment, we always take the Math.min of these values to account for the fact
     * that students are able to submit _after_ the timer runs out.
     */
    const minutes = Math.min(
      moment(studentEndTime).diff(moment(studentStartTime), 'minutes'),
      timeLimit
    );
    return humanReadableDurationString(moment.duration(minutes, 'minutes'));
  },

  /**
   * NOTE: THE FOLLOWING METHODS MAY DO THE SAME THING AS OTHER METHODS IN THIS FILE.
   * IDENTIFY WHICH ONES ARE DUPLICATED, WHERE THEY ARE BEING USED, AND CONSOLIDATE
   */
  /**
   * IMPORTANT** This method expects assignments_v* metadata and a student ID passed to metacontext
   */
  isComplete(): boolean {
    return this.getMeta().getCountOfQuestions() === this.getMeta().getStudentCountOfGuesses();
  },

  /**
   * An assignment in "over due" if the due date has passed, and it does *NOT* allow for lat submission.
   *
   * @param idsToSelect
   */
  isOverDue(idsToSelect: IdsToSelect = {}): boolean {
    return (
      this.isPastDue(idsToSelect) === true &&
      this.getIsAllowLateSubmissionsFromSettings(idsToSelect) === false
    );
  },

  /**
   * An assignment is "past due" if the due date has passed.
   * This method does *NOT* account for assignments that allow late submissions – see `isOverDue`.
   *
   * @param idsToSelect
   */
  isPastDue(idsToSelect: IdsToSelect = {}): boolean {
    const dueDate = this.getDueDateFromSettings(idsToSelect);
    return dueDate !== null && systemTimeOffsetStore.getCurrentTime().isSameOrAfter(dueDate);
  },

  /* mainly a helper for the due date duration checks below */
  isDueIn(duration: number, timeframe: string, idsToSelect: IdsToSelect = {}): boolean {
    return (
      !this.isPastDue() &&
      this.getDueDateFromSettings(idsToSelect) <
        systemTimeOffsetStore.getCurrentTime().add(duration, timeframe)
    );
  },

  isDueIn1Day(idsToSelect: IdsToSelect = {}): boolean {
    return this.isDueIn(1, 'day', idsToSelect);
  },

  isDueIn1Week(idsToSelect: IdsToSelect = {}): boolean {
    return this.isDueIn(1, 'week', idsToSelect);
  },

  isDueIn1Month(idsToSelect: IdsToSelect = {}): boolean {
    return this.isDueIn(1, 'month', idsToSelect);
  },

  /* gets a duration from now until the due date */
  getTimeLeftUntilDue(idsToSelect: IdsToSelect = {}): moment {
    return moment.duration(
      this.getDueDateFromSettings(idsToSelect) - systemTimeOffsetStore.getCurrentTime()
    );
  },

  isScheduled(idsToSelect: IdsToSelect = {}): boolean {
    const startDate = this.getStartDateFromSettings(idsToSelect);
    return (
      this.isAssigned() &&
      startDate !== null &&
      systemTimeOffsetStore.getCurrentTime().isBefore(startDate)
    );
  },

  /**
   * IMPORTANT** This method expects assignments_v* metadata and a student ID passed to metacontext
   *
   * @param idsToSelect
   */
  isStudentSubmissionLate(idsToSelect: IdsToSelect = {}): boolean {
    const timeOfSubmission = this.getMeta().getStudentSubmittedAt();
    return (
      timeOfSubmission &&
      moment(timeOfSubmission).isSameOrAfter(this.getDueDateFromSettings(idsToSelect))
    );
  },

  /**
   * IMPORTANT** This method expects assignments_v* metadata and a student ID passed to metacontext
   *
   * @param idsToSelect
   */
  isStudentSubmissionOnTime(idsToSelect: IdsToSelect = {}): boolean {
    const timeOfSubmission = this.getMeta().getStudentSubmittedAt();
    return (
      timeOfSubmission &&
      moment(timeOfSubmission).isSameOrBefore(this.getDueDateFromSettings(idsToSelect))
    );
  },

  /**
   * Returns a boolean value depicting whether or not guess feedback (explanations)
   * should be shown based on the assignment's explanation setting and state.
   *
   * IMPORTANT* This method does rely on the inclusion of metadata (meta.student_submitted)
   *
   * @param idsToSelect
   */
  shouldShowGuessFeedback(idsToSelect: IdsToSelect = {}): boolean {
    const setting = this.getCorrectAnswerSettingFromSettings(idsToSelect);
    return (
      /**
       * If it's SHOW_AFTER_EACH, assume this method is being called in scenarios
       * where a guess has been placed for a given question.
       */
      setting === assignmentConstants.CORRECT_ANSWER_SETTINGS.SHOW_AFTER_EACH.VALUE ||
      (setting === assignmentConstants.CORRECT_ANSWER_SETTINGS.SHOW_AFTER_ALL.VALUE &&
        this.getMeta().isStudentSubmitted()) ||
      (setting === assignmentConstants.CORRECT_ANSWER_SETTINGS.SHOW_AFTER_DUE_DATE.VALUE &&
        this.isPastDue(idsToSelect) &&
        (this.getMeta().isStudentSubmitted() ||
          this.getIsAllowLateSubmissionsFromSettings(idsToSelect) === false))
    );
  },

  /**
   * Teacher assignment helpers
   */
  getStudentsByMasteryBuckets(): Map<string, List<*>> {
    const func = () => {
      return this.getStudents().reduce(
        (acc, student) => {
          const studentMeta = student.getMeta();
          const correctGuessCount = studentMeta.getAssignmentCountOfCorrectGuesses();
          const incorrectGuessCount = studentMeta.getAssignmentCountOfIncorrectGuesses();
          const totalGuesses = correctGuessCount + incorrectGuessCount;

          const updater = (bucket) => [bucket, (studentsList) => studentsList.push(student)];

          if (totalGuesses === 0) {
            return acc.update(...updater('NOT_AVAILABLE'));
          }

          const accuracy = stats.percentage(correctGuessCount, totalGuesses);
          const bucket = getBucketByAccuracy(accuracy);

          return acc.update(...updater(bucket.BUCKET_NAME));
        },
        Map(BUCKETS)
          .map(() => List())
          .set('NOT_AVAILABLE', List())
      );
    };
    return this.cache('getStudentsByMasteryBuckets', func);
  },

  getFinishedStudents(): List<StudentModelV1> | List<StudentModelV2> {
    const assignmentId = this.getId();
    return this.getStudents().filter((student) => {
      return student.getAssignmentRelationships(assignmentId).getSubmitted() !== null;
    });
  }
};

function checkStudentRelationshipEntityCount(assignment, studentId, methodName) {
  const relationships = assignment.getStudentRelationships();
  if (relationships.size > 1 && !studentId) {
    // eslint-disable-next-line max-len
    throw new Error(
      `Attempted to call ${methodName} on Assignment ${assignment.getName()} without passing a studentId, but the Assignment has multiple student relationships. Please specify a studentId.`
    );
  }
}

/**
 * In order to use certain extensions, we need to ensure that we have the appropriate assignment meta. Sadly,
 * depending on how you make your request, this meta can either be on the student, or on the assignment.
 * This function checks both cause that's what we gotta do.
 *
 * @param assignment
 * @param studentId
 */
function getSingleStudentRelationship(assignment, studentId: ?string): Object {
  let relationships;
  if (assignment.getStudentRelationships().isEmpty()) {
    relationships = studentId
      ? assignment
          .getStudents()
          .find((student) => student.getId() === studentId)
          .getAssignmentRelationships()
          .get(assignment.getId())
      : assignment.getStudents().first().getAssignmentRelationships().get(assignment.getId());
  } else {
    relationships = studentId
      ? assignment.getStudentRelationships().get(studentId)
      : assignment.getStudentRelationships().first();
  }
  return relationships;
}

export {assignmentExtensions};
