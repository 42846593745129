import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {makeParser} from 'generic/MarkdownRendererV2/markdownRendererUtils';

import createTeacherOutput from './teacherOutput';

const parser = makeParser();

const createDefaultOutput = ({text}) => {
  const syntaxTree = parser.makeSyntaxTree(text);
  return parser.reactOutput(syntaxTree);
};

const makeRenderer = ({outputFunction, displayName = 'Markdown'}) => {
  const Renderer = ({text, className, as: Wrapper = 'div', ...rest}) => {
    return (
      <Wrapper className={classnames('markdown', className)} {...rest}>
        {outputFunction({text})}
      </Wrapper>
    );
  };
  Renderer.propTypes = {
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    text: PropTypes.string,
    className: PropTypes.string
  };
  Renderer.displayName = displayName;

  return Renderer;
};

/**
 * Renderer components below
 */

const Markdown = makeRenderer({
  outputFunction: createDefaultOutput,
  displayName: 'Markdown'
});

Markdown.TeacherRules = makeRenderer({
  outputFunction: createTeacherOutput,
  displayName: 'Markdown.TeacherRules'
});

export default Markdown;
