import React from 'react';

/**
 * Do not add Flow types to this file. Parsedoc uses it, and parsedoc can't run Flow-typed files.
 */

const stateCaptureGroup =
  /\s(A[KLRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|P[AR]|RI|S[CD]|T[NX]|UT|V[AIT]|W[AIVY])[,\s]/;

const getTruncatedStringJSX = ({str, endingChars = 3}) => {
  if (str.length <= endingChars + 1) {
    return <span>{str}</span>;
  }
  const splitLocation = str.length - (endingChars + 1);
  const str1 = str.substring(0, splitLocation);
  const str2 = str.substring(splitLocation, str.length);
  // These are purely presentational
  const span1 = (
    <span aria-hidden='true' className='u-truncate'>
      {str1}
    </span>
  );
  const span2 = <span aria-hidden='true'>{str2}</span>;
  // Title will allow elements to be read as the full string
  return (
    <span title={str} className='u-display_flex u-overflow_hidden'>
      {span1}
      {span2}
    </span>
  );
};

function clamp(str, charLimit = str.length, truncateChar = '') {
  if (str.length > charLimit) {
    return str.slice(0, charLimit) + truncateChar;
  }
  return str;
}

function upperFirst(str) {
  return str.charAt(0).toUpperCase().concat(str.slice(1));
}

function stripNonAlphaNumeric(str = '') {
  return str.replace(/[^a-zA-Z0-9]/g, '');
}

function stripNonAlphaNumericWithSpaces(str = '') {
  return str.replace(/[^\w\s]/gi, '');
}

function findStateAbbr(str) {
  const match = stateCaptureGroup.exec(str);
  return match ? match[1] : null;
}

function pluralize(str, count, customPlural) {
  const pluralForm = customPlural || `${str}s`;
  return count === 1 ? str : pluralForm;
}

function normalizeSearchString(str = '') {
  return stripNonAlphaNumericWithSpaces(str).toLowerCase();
}

const arrayToReadableString = (arr, conjunction = 'and') => {
  if (arr.length === 0) {
    return '';
  }

  if (arr.length === 1) {
    return arr[0];
  }
  return [arr.slice(0, -1).join(', '), arr[arr.length - 1]].join(` ${conjunction} `);
};

function camelCaseToReadableString(value) {
  return value
    .split(/(?=[A-Z])/)
    .map((string) => string.toLowerCase())
    .join(' ');
}

function getNumberWithSuffix(attempt) {
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const value = attempt % 100;
  return attempt + (suffixes[(value - 20) % 10] || suffixes[value] || suffixes[0]);
}

export {
  arrayToReadableString,
  clamp,
  findStateAbbr,
  upperFirst,
  stripNonAlphaNumeric,
  stripNonAlphaNumericWithSpaces,
  pluralize,
  normalizeSearchString,
  camelCaseToReadableString,
  getTruncatedStringJSX,
  getNumberWithSuffix
};
