import React from 'react';
import PropTypes from 'prop-types';
import {Banner, Button, Dialogue} from '@albert-io/atomic';

const GooglePopupBlockerWarning = ({onCancel, ...rest}) => (
  <Dialogue title='Error' {...rest}>
    <Dialogue.Body>
      <Banner type='negative'>
        <Banner.Icon icon='exclamation-triangle' />
        <span>
          Your popup blocker is preventing you from proceeding. To continue, please disable your
          popup blocker for <strong>https://albert.io</strong>.
        </span>
      </Banner>
    </Dialogue.Body>
    <Dialogue.BtnGroup>
      <Button color='secondary' onClick={onCancel}>
        Back
      </Button>
    </Dialogue.BtnGroup>
  </Dialogue>
);

GooglePopupBlockerWarning.propTypes = {
  onCancel: PropTypes.func
};

export default GooglePopupBlockerWarning;
