// @flow
import sessionStore from 'client/Session/SessionStore';
import moment from 'moment';
import {SubscriptionModelV1} from 'resources/augmented/Subscription/SubscriptionModel.v1';
import {UserModelV2} from 'resources/GeneratedModels/User/UserModel.v2';
import {resource, QueryBuilder} from '@albert-io/json-api-framework/request/builder';

function makeReturn(isReady = false, value = false): Object {
  return {
    isReady,
    value
  };
}

function makeReadyReturn(value: boolean): Object {
  return makeReturn(true, value);
}

export type AsyncPredicateResponse = {
  isReady: boolean,
  value: boolean
};

/**
 * Gets user query with teacher meta data
 */
export function getUserQuery(): QueryBuilder {
  const id = sessionStore.getUserId();
  return resource('users_v2')
    .mandarkEndpoint(['users_v2', id])
    .include('teacher_v1')
    .include('teacher_v1.classrooms_v1')
    .include('teacher_v1.school_v5')
    .withMeta('teacher_v1')
    .customQuery({
      meta: {
        context: {
          teacher: {
            license_info: true
          }
        }
      }
    });
}

/**
 * Gets user query for beta testing access
 */
export function getUserBetaTestingQuery(): QueryBuilder {
  const id = sessionStore.getUserId();
  return resource('users_v2')
    .mandarkEndpoint(['users_v2', id])
    .withMeta('user_v2')
    .customQuery({
      meta: {
        context: {
          user: {
            is_beta_testing: true
          }
        }
      }
    });
}

export function getSubjectSubscriptionQuery(subjectId: string): QueryBuilder {
  return resource('subscriptions_v1')
    .mandarkEndpoint(['subscriptions_v1'])
    .include('subject_v2', !subjectId)
    .filter({
      user_v1: sessionStore.getUserId(),
      end_date: {
        greater_than: moment().startOf('day').valueOf()
      },
      start_date: {
        less_than_inclusive: moment().startOf('day').valueOf()
      }
    })
    .filter(
      {
        subject_v2: subjectId
      },
      subjectId
    );
}

/**
 * This _might_ be considered technical debt as it lives in this file.
 * The usage of this specifically is in the `AccessLabel` component,
 * where we need to get info about a subscription.
 *
 * Previously this was used explicitly to check for access. This is now deprecated as an
 * access check in favor of the `hasSubjectAccess` check in this file.
 *
 * This is still provided here in order to keep the access label functioning as normal.
 * This additional query is run to retrieve a particular subscription for use in that component.
 *
 *
 * TODO: see about moving this + the getQuery function into the AccessLabel component.
 * investiage old UserStore usage in question footer / header
 *
 * @param subjectId
 */
export function getSubjectSubscription(subjectId: string): ?SubscriptionModelV1 {
  /**
   * Because we're actually making a request to the list endpoint, we want
   * the first one that matches.
   */
  const subscription: ?SubscriptionModelV1 = getSubjectSubscriptionQuery(subjectId)
    .getResource()
    .first();
  /**
   * We could potentially return an empty model here, but that doesn't seem like expected behavior.
   */
  return subscription;
}

export function getDoesStudentHaveActiveClassroomQuery(studentId: string): QueryBuilder {
  return resource('classrooms_v1')
    .mandarkEndpoint(['students_v2', studentId, 'classrooms_v1'])
    .filter({
      status: 'active'
    });
}

export function getDoesUserBelongToAnyLicensedClassroomsQuery(
  includeTeacher: boolean = false
): QueryBuilder {
  return resource('subjects_v2')
    .mandarkEndpoint(['subjects_v2'])
    .pageSize(1)
    .meta(
      {
        context: {
          student: {
            id: sessionStore.getUserId()
          }
        }
      },
      sessionStore.hasValidSession()
    )
    .customQuery({
      with_meta: 'subject_v2'
    })
    .filter(
      {
        any_of: [
          {
            'meta.has_access_via_license_as_student': true
          }
        ]
      },
      sessionStore.hasValidSession()
    )
    .filter(
      {
        any_of: [
          {
            'meta.has_access_via_license_as_teacher': true
          }
        ]
      },
      sessionStore.hasValidSession() && includeTeacher
    );
}

export function getUserSubjectAccessQuery(subjectId: string): QueryBuilder {
  return resource('subject_v2')
    .mandarkEndpoint(['subjects_v2', subjectId])
    .meta(
      {
        context: {
          student: {
            id: sessionStore.getUserId()
          }
        }
      },
      sessionStore.hasValidSession()
    )
    .customQuery({
      with_meta: 'subject_v2'
    });
}

/**
 * Determines whether or not a user is an unlicensed teacher or admin
 *
 * @returns {AsyncPredicateResponse} object representing the state of the request, and resulting request value
 */
export function isUnlicensedTeacherOrAdmin(): AsyncPredicateResponse {
  if (!sessionStore.hasValidSession()) {
    return makeReadyReturn(true);
  }

  if (!sessionStore.hasTeacherAccess()) {
    return makeReadyReturn(false);
  }

  const userQuery = getUserQuery();
  const isPopulated = userQuery.isResourcePopulated();

  // necessary due to model builder validations restricting access to unpopulated fields
  if (!isPopulated) {
    return makeReturn();
  }
  const isLicensed = userQuery
    .getResource()
    .getTeacher()
    .getMeta?.() // If no teacher is found on the user, bail. This could be the case for admins with no teachers.
    .isLicensed();
  return makeReturn(isPopulated, !isLicensed);
}

/**
 * Determines whether or not a user has access to a subject.
 * Internally, leverages the has_access meta value returned by a subject resource.
 *
 * @param {string} subjectId
 * @returns {AsyncPredicateResponse} object representing the state of the request, and resulting request value
 */
export function hasSubjectAccess(subjectId: string): AsyncPredicateResponse {
  if (!sessionStore.hasValidSession()) {
    return makeReadyReturn(false);
  }
  if (!subjectId) {
    /**
     * In this instance, we could be still querying a slug for an ID in the study guide.
     *
     * We log, but fail and continue without throwing.
     *
     * @todo OSD: We'll probably want to drop this down to a `debug` on/after release.
     */
    logger.error('attempting to test subject access without a subject id');
    return makeReadyReturn(false);
  }

  const userSubjectAccessQuery = getUserSubjectAccessQuery(subjectId);
  const isPopulated = userSubjectAccessQuery.isResourcePopulated();

  // necessary due to model builder validations restricting access to unpopulated fields
  if (!isPopulated) {
    return makeReturn();
  }

  return makeReturn(isPopulated, userSubjectAccessQuery.getResource().doesUserHaveAccess());
}

/**
 * Determines if a user should see freemium content: Logged out, super users, or unlicensed teacher/admin
 *
 * @param {UserModelV2} user - User model used  to check for license permissions
 * @returns {boolean} whether or not a user should see freemium content
 */
export function isFreemiumUser(user): boolean {
  // Can short circuit since we know that super users and logged out users should see free
  if (sessionStore.isSuper() || !sessionStore.hasValidSession()) {
    return true;
  }

  const isLicensed = !sessionStore.isStudent() && user.getTeacher().getMeta?.().isLicensed();
  const isUnlicensedAdminOrTeacher = sessionStore.hasTeacherAccess() && !isLicensed;
  return isUnlicensedAdminOrTeacher;
}

/**
 * Determines if a student is involved in a classroom via their classroom metadata
 *
 * @returns {AsyncPredicateResponse} object representing the state of the request, and resulting request value
 */
export function doesStudentHaveActiveClassroom(): AsyncPredicateResponse {
  if (!sessionStore.hasValidSession()) {
    return makeReadyReturn(false);
  }

  const hasActiveTeacherQuery = getDoesStudentHaveActiveClassroomQuery(sessionStore.getUserId());
  const isReady = hasActiveTeacherQuery.isResourcePopulated();
  const value = !hasActiveTeacherQuery.getResource().isEmpty();

  return makeReturn(isReady, value);
}

/**
 * Determines if a user is involved in a classroom via their subject license metadata
 * specifically, has_access_via_license_as_student on a subject resource.
 *
 * @param includeTeacher
 * @returns {AsyncPredicateResponse} object representing the state of the request, and resulting request value
 */
export function doesUserBelongToAnyLicensedClassrooms(
  includeTeacher: boolean = false
): AsyncPredicateResponse {
  if (!sessionStore.hasValidSession()) {
    return makeReadyReturn(false);
  }

  const query = getDoesUserBelongToAnyLicensedClassroomsQuery(includeTeacher);
  const isReady = query.isResourcePopulated();
  const value = !query.getResource().isEmpty();

  const {isReady: activeTeacherIsReady, value: activeTeacherValue} =
    doesStudentHaveActiveClassroom();

  const combinedIsReady = activeTeacherIsReady && isReady;
  const combinedValue = activeTeacherValue || value;

  return makeReturn(combinedIsReady, combinedValue);
}

/**
 * Checks that a user is both:
 * 1. a teacher
 * 2. has access to a subject via `hasSubjectAccess`
 *
 * @param {string} subjectId
 * @returns {AsyncPredicateResponse} object representing the state of the request, and resulting request value
 */
export function canCreateTemplateForSubject(subjectId: string): Object {
  if (!sessionStore.hasValidSession()) {
    return makeReadyReturn(false);
  }

  // Here, value already takes into account the isReady state of the underlying subject access query,
  // so it is "safe" to "use"
  const {isReady, value} = hasSubjectAccess(subjectId);
  const hasAccessAndIsTeacher = value && sessionStore.hasTeacherAccess();
  return makeReturn(isReady, hasAccessAndIsTeacher);
}

/**
 * Used to determine whether or not the current session can view Practice Exam related functionality in various UIs.
 *
 * @returns {boolean}
 */
export function canSeePracticeExams(): boolean {
  /**
   * Administrators can always see Practice Exam features.
   */
  if (sessionStore.isSuper()) {
    return true;
  }

  /**
   * Signed out users can also see them, interaction for specific functionality should be
   * disabled in views (prompting login modals).
   */
  if (sessionStore.hasValidSession() === false) {
    return false;
  }
  /**
   * If the signed in user is not a student, they can also view Practice Exam features.
   * Again, here specficially functionality will be disabled based on the account state in
   * individual views (prompting upgrade modals).
   */
  if (sessionStore.isStudent() === false) {
    return true;
  }
  /**
   * If the user is a signed in student, we do not show practice exam features if
   * they are in a licensed classroom.
   *
   * Because doesUserBelongToAnyLicensedClassrooms is async, we need to block the exam
   * stuff from showing until that req is completed with an affirmative value
   */
  return false;
}

export function hasLicenseAccess(): AsyncPredicateResponse {
  if (!sessionStore.hasValidSession()) {
    return makeReadyReturn(false);
  }

  if (sessionStore.isSuper()) {
    return makeReadyReturn(true);
  }

  const {isReady, value} = doesUserBelongToAnyLicensedClassrooms(true);
  return makeReturn(isReady, value);
}

/**
 * Determines if a user should see the "Assignment builder" tab in global nav: Teachers, admins, and super users
 *
 * @returns {boolean} whether or not the user's school has access to the "Assignment builder" tab
 */
export function hasAssignmentDraftAccess(): AsyncPredicateResponse {
  // Can short circuit if user is logged out
  if (!sessionStore.hasValidSession()) {
    return makeReadyReturn(false);
  }
  if (sessionStore.isSuper()) {
    return makeReadyReturn(true);
  }
  if (!sessionStore.hasTeacherAccess()) {
    return makeReadyReturn(false);
  }

  return makeReadyReturn(true);
}
