import React, {Children, Suspense, useState, useEffect, ReactElement} from 'react';
import LoadingIndicator from 'generic/LoadingIndicator.react';

/**
 * Waits for the client to mount before rendering children for Suspense, as Suspense is not yet supported on the version (16x) of `react-dom/server` that we are using.
 * supported in (18+) https://react.dev/reference/react-dom/server/renderToPipeableStream
 */
export const SuspenseWrapper = ({children}: {children: ReactElement}) => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  return mounted ? (
    <Suspense fallback={<LoadingIndicator />}>{children}</Suspense>
  ) : (
    <>
      {Children.map(children, (child) => {
        // ensures non dynamicically imported content on the server gets rendered
        const isLazyChild = child.type['$$typeof'] === Symbol.for('react.lazy');
        return isLazyChild ? null : child;
      })}
    </>
  );
};
