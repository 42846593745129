import {useEffect, useCallback} from 'react';

export const useAudioEvents = (audioRef, src, updatePlayerState) => {
  const handleEnded = useCallback(() => {
    updatePlayerState(src, {isPlaying: false, timestamp: 0});
    audioRef!.current.currentTime = 0;
  }, [src, updatePlayerState]);

  const handleLoadedMetadata = useCallback(() => {
    updatePlayerState(src, {duration: audioRef!.current.duration});
  }, [src, updatePlayerState]);

  const updateTime = useCallback(() => {
    updatePlayerState(src, {timestamp: audioRef!.current.currentTime});
  }, [src, updatePlayerState]);

  const addAudioEventListeners = useCallback(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.addEventListener('ended', handleEnded);
      audio.addEventListener('loadedmetadata', handleLoadedMetadata);
      audio.addEventListener('timeupdate', updateTime);
    }
  }, [handleEnded, handleLoadedMetadata, updateTime, audioRef]);

  const removeAudioEventListeners = useCallback(() => {
    const audio = audioRef.current;
    if (audio) {
      audio.removeEventListener('ended', handleEnded);
      audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
      audio.removeEventListener('timeupdate', updateTime);
    }
  }, [handleEnded, handleLoadedMetadata, updateTime, audioRef]);

  useEffect(() => {
    addAudioEventListeners();
    return () => {
      removeAudioEventListeners();
    };
  }, []);
};

export const useTrackEvents = (trackRef) => {
  const updateTrackBackground = useCallback(
    (e) => {
      const track = trackRef!.current;
      const rect = track.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const totalWidth = rect.width;
      const percentage = (x / totalWidth) * 100;
      track.style.setProperty('--hover-percentage', `${percentage}%`);
    },
    [trackRef]
  );

  const clearTrackBackground = useCallback(() => {
    trackRef!.current.style.setProperty('--hover-percentage', '0%');
  }, [trackRef]);

  const addTrackEventListeners = useCallback(() => {
    const track = trackRef.current;
    if (track) {
      track.addEventListener('mousemove', updateTrackBackground);
      track.addEventListener('mouseleave', clearTrackBackground);
    }
  }, [updateTrackBackground, clearTrackBackground, trackRef]);

  const removeTrackEventListeners = useCallback(() => {
    const track = trackRef.current;
    if (track) {
      track.removeEventListener('mousemove', updateTrackBackground);
      track.removeEventListener('mouseleave', clearTrackBackground);
    }
  }, [updateTrackBackground, clearTrackBackground, trackRef]);

  useEffect(() => {
    addTrackEventListeners();
    return () => {
      removeTrackEventListeners();
    };
  }, []);
};
