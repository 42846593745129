import React from 'react';

import {Button} from '@albert-io/atomic';

import {GuessModelV1, QuestionModelV3} from '@albert-io/models';

import clearGuess from './clearGuess';
import loadGuess from './loadGuess';

interface Props {
  handleToggleSolution: (guess: GuessModelV1) => void;
  isViewingSolution: boolean;
  question: QuestionModelV3;
  questionStoreName: string;
}

export default function ToggleViewSolution({
  handleToggleSolution,
  isViewingSolution,
  question,
  questionStoreName
}: Props) {
  const builtGuess = question.getStore().buildCorrectGuess(question);

  return (
    <Button
      type='button'
      data-testid='practice-view__toggle-solution-btn'
      onClick={() => {
        if (isViewingSolution) {
          clearGuess(questionStoreName);
        } else {
          loadGuess(questionStoreName, builtGuess);
        }
        handleToggleSolution(builtGuess);
      }}
    >
      {isViewingSolution ? 'Hide' : 'Show'} solution
    </Button>
  );
}
