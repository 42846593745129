/**
 * # ListGroupItem
 *
 * A component that represents an item in a list group.
 *
 * ## Props
 *
 * - `align?: 'left' | 'center' | 'right'`: The alignment of the content within the item. Default is 'left'.
 * - `className?: string`: Additional CSS class name for the item.
 * - `footer?: boolean`: Rounds bottom corners and removes border. Default is false.
 * - `header?: boolean`: Rounds top corners. Default is false.
 * - `border?: boolean`: Whether the item has a border. Default is true.
 * - `paddingLevel?: number`: The padding level of the item. Default is 2.
 *
 * ## Usage
 *
 * ```tsx
 * import ListGroupItem from './ListGroupItem.react';
 *
 * const MyComponent = () => {
 *   return (
 *     <ListGroupItem align="center" className="my-item" footer border={false} paddingLevel={3}>
 *       My List Group Item
 *     </ListGroupItem>
 *   );
 * };
 * ```
 */

import React, {forwardRef} from 'react';
import classnames from 'classnames';
import './list-group-item.scss';

interface Props extends PropsWithClassNameOptional {
  align?: 'left' | 'center' | 'right';
  footer?: boolean;
  header?: boolean;
  border?: boolean;
  paddingLevel?: number;
}

type ListGroupItemProps<C extends React.ElementType = 'div'> = PolymorphicComponentPropsWithRef<
  C,
  Props
>;

export type ListGroupItemComponent = <C extends React.ElementType = 'div'>(
  props: ListGroupItemProps<C>
) => React.ReactElement | null;

export const ListGroupItem: ListGroupItemComponent = forwardRef(
  <C extends React.ElementType = 'div'>(
    {
      align = 'left',
      as,
      className,
      footer,
      header,
      border = true,
      paddingLevel = 2,
      ...rest
    }: ListGroupItemProps<C>,
    ref
  ) => {
    const WrapperElement = as || 'div';
    return (
      <WrapperElement
        className={classnames(
          'a-list-group-item',
          'u-display_block',
          `a-list-group-item--align-${align}`,
          `u-pad_${paddingLevel}`,
          className,
          {
            'a-list-group-item--footer': footer,
            'a-list-group-item--header': header,
            'u-border_none': !border
          }
        )}
        ref={ref}
        {...rest}
      />
    );
  }
);

export default ListGroupItem;
