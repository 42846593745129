import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {range} from 'lodash';
import makeConstants from 'lib/makeConstants';
import componentPropType from 'lib/componentPropType';
import Col from '../Col/Col.react';
import {getClassNamesForRow} from '../utils/Grid.utils';
import './grid-row.scss';

const alignProps = makeConstants(
  'baseline',
  'center',
  'flex-end',
  'flex-start',
  'inherit',
  'initial',
  'unset'
);

const justifyProps = makeConstants(
  'center',
  'flex-end',
  'flex-start',
  'initial',
  'inherit',
  'space-around',
  'space-between'
);

/**
 * Represents a row.
 *
 * Row consumes the left-most and right-most margins of its Column children to keep them flush
 * with the parent wrapping the outermost <Container />.
 *
 * debug: adds a padding and background-color default (intended for Storybert pages)
 * align: accepts one of the above alignProps to apply the corresponding vertically aligning
 *   `align-items` rules
 * justify: accepts one of the above justifyProps to apply the corresponding horizontally justifying
 *   `justify-content` rules
 *
 * @param {Object} props
 * @example
 * <Row justify='flex-end'>
 *   <Col
 *     m={2}
 *     xl={4}
 *   >
 *     Sidebar content
 *   </Col>
 *   <Col
 *     m={4}
 *     xl={6}
 *   >
 *     Main content
 *   </Col>
 * </Row>
 */
const Row = ({
  align,
  as: WrapperElement,
  children,
  className,
  colGap,
  debug,
  justify,
  rowGap,
  ...rest
}) => (
  <WrapperElement
    className={classnames('a-grid__row', className, getClassNamesForRow(colGap, 'a-grid__row'), {
      ['u-align-items_' + align]: alignProps[align],
      ['a-grid__row--row-gap-' + rowGap]: rowGap,
      ['u-justify-content_' + justify]: justifyProps[justify],
      'a-grid__row--debug': debug
    })}
    {...rest}
  >
    {React.Children.map(children, (child) => {
      if (child && child.type === Col) {
        return React.cloneElement(child, {
          dangerouslySetColGap: child.props.dangerouslySetColGap || colGap,
          rowGap: child.props.rowGap || rowGap
        });
      }
      return child;
    })}
  </WrapperElement>
);

Row.propTypes = {
  align: PropTypes.oneOf(Object.keys(alignProps)),
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  children: componentPropType(Col),
  colGap: PropTypes.oneOfType([PropTypes.oneOf(range(1, 11)), PropTypes.object]),
  className: PropTypes.string,
  debug: PropTypes.bool,
  justify: PropTypes.oneOf(Object.keys(justifyProps)),
  rowGap: PropTypes.oneOf(range(1, 11))
};

Row.defaultProps = {
  as: 'div'
};

export default Row;
