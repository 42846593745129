import React from 'react';
import {Card, Grid} from '@albert-io/atomic';

export default class CoppaMessage extends React.Component {
  render() {
    return (
      <Grid.Container>
        <Grid.Row justify='center'>
          <Grid.Col xs={12} all={6} className='coppa-message'>
            <Card paddingLevel={3}>{this.props.children}</Card>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    );
  }
}
