import React, {createContext, useContext, useCallback, useState} from 'react';

interface PlayerState {
  playbackRate: number;
  timestamp: number;
  duration: number;
  isPlaying: boolean;
  pauseFired: boolean;
}

interface AudioPlayers {
  [src: string]: PlayerState;
}

interface ContextProps {
  players: AudioPlayers;
  registerPlayer: (src: string) => void;
  updatePlayerState: (src: string, changes: Partial<PlayerState>) => void;
}

const AudioPlayerContext = createContext<ContextProps | undefined>(undefined);

const updatePlayers = (current: AudioPlayers, src: string, changes: Partial<PlayerState>) => {
  const updatedPlayers = {...current, [src]: {...current[src], ...changes}};

  if (changes.isPlaying) {
    Object.keys(updatedPlayers).forEach((key) => {
      if (key !== src) {
        updatedPlayers[key] = {...updatedPlayers[key], isPlaying: false, pauseFired: true};
      }
    });
  }

  return updatedPlayers;
};

export const AudioPlayerProvider = ({children}: PropsWithChildrenRequired) => {
  const [players, setPlayers] = useState<AudioPlayers>({});

  const registerPlayer = useCallback((src: string) => {
    setPlayers((current) => ({
      ...current,
      [src]: current[src]
        ? {...current[src], isPlaying: false}
        : {playbackRate: 1, timestamp: 0, duration: 0, isPlaying: false, pauseFired: false}
    }));
  }, []);

  const updatePlayerState = useCallback((src: string, changes: Partial<PlayerState>) => {
    setPlayers((current) => updatePlayers(current, src, changes));
  }, []);

  return (
    <AudioPlayerContext.Provider value={{players, registerPlayer, updatePlayerState}}>
      {children}
    </AudioPlayerContext.Provider>
  );
};

export const useAudioPlayer = () => {
  const context = useContext(AudioPlayerContext);
  if (!context) {
    throw new Error('useAudioPlayer must be used within an AudioPlayerProvider');
  }
  return context;
};
