// @flow
import {mandarkEndpoint} from '@albert-io/json-api-framework/request/builder/legacy';

export function makeGuessQuery({questionId, userId}: {questionId: string, userId: string}): Object {
  return {
    resourcePath: ['questions_v1', questionId, 'guesses_v1'],
    filter: {
      student_v1: userId
    }
  };
}

export function makeSubmitGuessQuery({studentId}: {studentId: string}): Object {
  return {
    include: 'question_v1.question_set_v1.subject_v2,question_v1',
    customQuery: {
      with_meta: 'question_v1',
      meta: {
        context: {
          student: {
            id: studentId
          }
        }
      }
    }
  };
}

export function makeGuessWithAssignmentQuery({
  assignmentId,
  questionId,
  userId
}: {
  assignmentId: string,
  questionId: string,
  userId: string
}): Object {
  return mandarkEndpoint(['questions_v1', questionId, 'guesses_v1'])
    .filter({student_v1: userId})
    .filter({assignment_v2: assignmentId})
    .done();
}
