import React from 'react';

import {useSlate} from 'slate-react';

import {IconButton} from '@albert-io/atomic';

import {BlockType} from '../../slate.types';

import {
  isBlockActive,
  toggleBlock,
  withWorkaround,
  FontType,
  ButtonProps
} from '../../Utils/editor.utils';

type BlockButtonTypes = Exclude<BlockType, 'image' | 'link' | 'audio-chip' | FontType>;

export const BlockButton = ({type, icon, ...optionalProps}: ButtonProps<BlockButtonTypes>) => {
  const editor = useSlate();
  return (
    <IconButton
      icon={icon}
      color={isBlockActive(editor, type) ? 'primary' : 'secondary'}
      variant={isBlockActive(editor, type) ? 'faded' : 'outlined'}
      onClick={(event) => {
        event.preventDefault();
        withWorkaround(editor, () => toggleBlock(editor, type));
      }}
      label={type}
      {...optionalProps}
    />
  );
};
