// @flow
import {fromJS, type List, type Map} from 'immutable';

export function generateDropdownOptions({
  options,
  includeAll = false,
  unshiftAll = false
}: {
  options: Array<string>,
  includeAll: boolean,
  unshiftAll: boolean
}): List<Map<string, string>> {
  const dropdownOptions = options.map((option) => {
    return {
      name: option.charAt(0).toUpperCase() + option.slice(1).replace(/-/g, ' '),
      id: option
    };
  });

  if (includeAll) {
    dropdownOptions[unshiftAll ? 'unshift' : 'push']({
      name: 'All',
      id: ''
    });
  }

  return fromJS(dropdownOptions);
}
