import React, {lazy} from 'react';
import {IndexRedirect, Route} from 'react-router';

const SubjectManager = lazy(() => import('./SubjectManager/SubjectManager.react'));
const SubjectSearch = lazy(() => import('./SubjectSearch/SubjectSearch.react'));
const AuthoringSubject = lazy(() => import('./AuthoringSubject/AuthoringSubject.react'));
const AuthoringSubjectEditor = lazy(() =>
  import('./AuthoringSubject/AuthoringSubjectEditor/AuthoringSubjectEditor.react')
);
const GuideEditor = lazy(() => import('./Guides/GuideEditor/GuideEditor.react'));

export default (
  <Route component={SubjectManager} name='Subject Manager' path='subjects'>
    <IndexRedirect to='search' />
    <Route component={SubjectSearch} name='Subject Lookup' path='search' />
    <Route name='Subject Editor' path='new' component={AuthoringSubjectEditor} />
    <Route component={AuthoringSubject} path=':subjectId'>
      <IndexRedirect to='subject' />
      <Route component={AuthoringSubjectEditor} name='Subject Editor' path='subject' />
      <Route component={GuideEditor} path='guides(/:guideType)' />
    </Route>
  </Route>
);
