import React from 'react';
import {callAction, getStoreByName} from 'client/framework';
import appActions from 'client/AppActions';
import appStore from 'client/AppStore';
import Drawer, {colorSchemes} from 'generic/Drawer/Drawer.react';
import TemplateList from 'client/Assignments/Templates/TemplateList.react';
import IEBreadcrumbs from './IEBreadcrumbs/IEBreadcrumbs.react';
import IEContentWrapper from 'client/InteractionEngineV2/shared/IEContentWrapper/IEContentWrapper.react';
import ActiveQuestion, {
  ActiveQuestionLoading
} from 'client/InteractionEngineV2/shared/ActiveQuestion/ActiveQuestion.react';
import IEStats from 'client/InteractionEngineV2/shared/IEStats/IEStats.react';
import IEMobileNav from 'client/InteractionEngineV2/shared/IEMobileNav/IEMobileNav.react';
import {getBreadcrumbs} from './IEBreadcrumbs/IEBreadcrumbs.utils';
import interactionEngineActions from 'client/InteractionEngineV2/InteractionEngineActions';
import interactionEngineStore from 'client/InteractionEngineV2/InteractionEngineStore';
import VanillaIEQuestionsList from 'client/InteractionEngineV2/IESessionTypes/VanillaIE/VanillaIEQuestionsList/VanillaIEQuestionsList.react';
import vanillaIEQuestionsListStore from 'client/InteractionEngineV2/IESessionTypes/VanillaIE/VanillaIEQuestionsList/VanillaIEQuestionsList.store';
import vanillaIEQuestionsListActions from 'client/InteractionEngineV2/IESessionTypes/VanillaIE/VanillaIEQuestionsList/VanillaIEQuestionsList.actions';
import {hasSubjectAccess} from 'lib/UserAccessUtil';
import globalNavigationActions from 'client/GlobalNavigation/GlobalNavigationActions';
import TopicSummary from './TopicSummary/TopicSummary.react';
import topicSummaryStore from './TopicSummary/TopicSummary.store';
import sessionStore from 'client/Session/SessionStore';
import {isSearchMode} from 'client/components/AdvancedSearch/AdvancedSearch.utils';

export default class VanillaIE extends React.Component {
  UNSAFE_componentWillMount() {
    this.validateSession();
  }

  componentDidMount() {
    callAction(globalNavigationActions.SET_SHOULD_SHOW_UPGRADE_BUTTON, true);
  }

  UNSAFE_componentWillUpdate() {
    this.validateSession();
  }

  componentWillUnmount() {
    callAction(appActions.RESET_METADATA, ['title', 'metaDescription']);
    callAction(globalNavigationActions.SET_SHOULD_SHOW_UPGRADE_BUTTON, false);
  }

  validateSession() {
    const builtQuestionSets = interactionEngineStore.builtQuestionSets;
    const freshQuestionSets = vanillaIEQuestionsListStore.questionSets.filterNot((questionSet) => {
      return builtQuestionSets.has(questionSet.get('id'));
    });
    if (
      !freshQuestionSets.isEmpty() ||
      vanillaIEQuestionsListStore.shouldChangeStoreBuilderStrategy()
    ) {
      const storeBuildingStrategy = vanillaIEQuestionsListStore.getStoreBuilderStrategy();
      const builtStores = storeBuildingStrategy(vanillaIEQuestionsListStore.questionSets);
      callAction(interactionEngineActions.SET_BOOTSTRAPPED_STORES, builtStores);
      callAction(vanillaIEQuestionsListActions.QUESTION_SET_STORES_BUILT);
    }
  }

  getQuestionsList() {
    return <VanillaIEQuestionsList />;
  }

  getActiveQuestion() {
    if (!vanillaIEQuestionsListStore.hasActiveQuestion) {
      return <ActiveQuestionLoading />;
    }
    const activeQuestionId = vanillaIEQuestionsListStore.activeQuestion.getId();
    const questionStoreName = interactionEngineStore.getStoreNameForQuestionId(activeQuestionId);
    const questionStore = getStoreByName(questionStoreName);
    const showPrevButton = vanillaIEQuestionsListStore.hasPreviousQuestion(activeQuestionId);
    const showNextButton = vanillaIEQuestionsListStore.hasNextQuestion(activeQuestionId);
    const subjectId = vanillaIEQuestionsListStore.getCurrentSubjectId();
    const {isReady, value} = hasSubjectAccess(subjectId);

    return (
      <ActiveQuestion
        showGuessHistory={!interactionEngineStore.isTeacherModeEnabled()}
        shouldShowExplanation={questionStore && questionStore.shouldShowExplanation()}
        shouldShowExplanationAfterGuessIsSubmitted
        question={vanillaIEQuestionsListStore.activeQuestion}
        questionSet={vanillaIEQuestionsListStore.activeQuestionSet}
        canEditQuestion={
          vanillaIEQuestionsListStore.activeQuestion.authorCanEdit() || sessionStore.isSuper()
        }
        questionStoreName={questionStoreName}
        showPrevButton={showPrevButton}
        showNextButton={showNextButton}
        showFreeLabel={isReady && !value}
      />
    );
  }

  getStats() {
    if (
      isSearchMode() ||
      vanillaIEQuestionsListStore.questionSets.size === 0 ||
      vanillaIEQuestionsListStore.isSingleSetMode() ||
      interactionEngineStore.isTeacherModeEnabled()
    ) {
      return null;
    }
    return (
      <IEStats
        accuracy={vanillaIEQuestionsListStore.accuracy}
        numOfQuestionsAnswered={vanillaIEQuestionsListStore.questionsAnswered}
        shouldShowAccuracy
        totalNumOfQuestions={vanillaIEQuestionsListStore.totalQuestions}
      />
    );
  }

  getTemplatesContent() {
    return (
      <Drawer
        colorScheme={colorSchemes.DARK}
        hasCloseButton={true}
        storeName='VanillaIEDrawerStore'
        drawerTitle='Select a folder'
      >
        <TemplateList />
      </Drawer>
    );
  }

  getSubheader() {
    if (appStore.isMobileView()) {
      const lastCrumb = getBreadcrumbs().last();
      return <IEMobileNav title={lastCrumb ? lastCrumb.get('displayName') : ''} />;
    } else {
      return <IEBreadcrumbs />;
    }
  }

  getMainContent() {
    return topicSummaryStore.isTopicSummaryPath() && topicSummaryStore.isTopicSummaryValid() ? (
      <TopicSummary />
    ) : (
      this.getActiveQuestion()
    );
  }

  render() {
    return (
      <IEContentWrapper
        subheader={this.getSubheader()}
        questionsList={this.getQuestionsList()}
        questionArea={this.getMainContent()}
        statsArea={this.getStats()}
        modals={this.getTemplatesContent()}
      />
    );
  }
}
