import {resource} from '@albert-io/json-api-framework/request/builder';
import {PublicQuestionModelV1} from 'src/resources/augmented//PublicQuestion/PublicQuestionModel.v1';
import {QUERY_PAGE_SIZE} from './util';

const getBaseQuery = (subjectSlug: string, guideLevelId: string) => {
  return resource('question_sets_v1')
    .mandarkEndpoint(['guide_levels_v2', guideLevelId, 'question_sets_v1'])
    .include('public_questions_v1.guide_levels_v2')
    .include('public_questions_v1.question_set_v1')
    .filter({
      subject_v2: {
        url_slug: subjectSlug
      }
    })
    .meta({
      context: {
        guide_level: {
          id: guideLevelId
        }
      }
    })
    .sort('meta.guide_level_position')
    .withMeta('guide_level_v2,question_set_v1');
};

export const fetchQuestionsForPage = async (
  subjectSlug: string,
  guideLevelId: string,
  page?: number
) => {
  const questionSets = await getBaseQuery(subjectSlug, guideLevelId)
    .pageSize(QUERY_PAGE_SIZE)
    .page(page || 1)
    .getResourcePromise();
  if (questionSets.size === 0) {
    return [];
  }
  const questions: PublicQuestionModelV1[] = [];
  questionSets.forEach((questionSet) => {
    questions.push(...questionSet.getPublicQuestions().toArray());
  });
  return questions;
};

export const fetchInitialQuestions = async (subjectSlug: string, guideLevelId: string) => {
  return fetchQuestionsForPage(subjectSlug, guideLevelId, 1);
};

export const fetchGuideQuestion = async (subjectSlug: string, questionSlug: string) => {
  const results = await resource('question_sets_v1')
    .mandarkEndpoint(['question_sets_v1'])
    .include('public_questions_v1.guide_levels_v2')
    .include('public_questions_v1.question_set_v1')
    .filter({
      slug_id: `${subjectSlug}@${questionSlug}`
    })
    .getResourcePromise();
  return results.first()?.getPublicQuestions().first();
};

export const fetchSingleQuestion = async (questionSlug: string) => {
  const results = await resource('public_questions_v1')
    .mandarkEndpoint(['public_questions_v1'])
    .include('guide_levels_v2')
    .include('question_set_v1')
    .filter({
      slug_id: questionSlug
    })
    .getResourcePromise();
  return results.first();
};

export const fetchGuideLevel = async (guideLevelSlug: string) => {
  const guideLevelResources = await resource('guide_levels_v2')
    .mandarkEndpoint(['guide_levels_v2'])
    .filter({url_slug: guideLevelSlug})
    .getResourcePromise();
  return guideLevelResources.getCollection().toArray()[0];
};

export const getQuestionCount = (subjectSlug: string, guideLevelId: string) => {
  return getBaseQuery(subjectSlug, guideLevelId)
    .pageSize(QUERY_PAGE_SIZE)
    .page(1)
    .getResourceMetadata()
    .getIn(['page', 'total_entries'], 0);
};
