import React from 'react';
import {Link} from 'react-router';
import './assets/sign-up-link.scss';

interface Props {
  toggleMobileMenu: () => void;
}

export default function SignUpLink(props: Props) {
  return (
    <Link
      onClick={props.toggleMobileMenu}
      data-testid='sign-up-button'
      className='sign-up-link-button'
      to='/signup'
    >
      Sign Up
    </Link>
  );
}
