import React from 'react';
import gradebookPreviewImage from 'assets/images/reports/gradebookPreview.svg';
import assignmentsPreviewImage from 'assets/images/reports/assignmentsPreview.svg';
import standardsPreviewImage from 'assets/images/reports/standardsPreview.svg';
import questionsPreviewImage from 'assets/images/reports/questionsPreview.svg';
import studentsPreviewImage from 'assets/images/reports/studentsPreview.svg';
import schoolsPreviewImage from 'assets/images/reports/schoolsPreview.svg';
import classroomsPreviewImage from 'assets/images/reports/classroomsPreview.svg';
import teachersPreviewImage from 'assets/images/reports/teachersPreview.svg';
import subjectsPreviewImage from 'assets/images/reports/subjectsPreview.svg';

import {Anchor, Heading, Text} from '@albert-io/atomic';
import './report-preview.scss';
import {ReportTypes} from 'client/Reports/reports.types';

const REPORT_BLOCK_CONFIG: {[reportType in ReportTypes]: any} = {
  assignments: {
    image: (
      <img
        alt='assignment preview'
        className='report-preview__image'
        src={assignmentsPreviewImage}
      />
    ),
    description: (
      <>
        Evaluate performance across assignments and assessments from all your school&apos;s teachers
        and classes. This data helps you identify gaps in instruction and make more informed
        choices.
      </>
    )
  },
  standards: {
    image: (
      <img alt='standard preview' className='report-preview__image' src={standardsPreviewImage} />
    ),
    description: (
      <>
        Evaluate performance across all of your school&apos;s standards! This data enables more
        targeted instruction and intervention, ensuring all students meet the required benchmarks.{' '}
        <br />
        <br /> Not sure if we have your standards?{' '}
        <Anchor
          href='https://help.albert.io/en/articles/4661774-does-albert-have-state-aligned-content'
          inheritColor
          underlined
        >
          We offer standards-aligned content for all 50 states!
        </Anchor>
      </>
    )
  },
  questions: {
    image: (
      <img alt='questions preview' className='report-preview__image' src={questionsPreviewImage} />
    ),
    description: (
      <>
        Perform powerful question based item analysis to effectively identify precise instructional
        gaps. Fine-tune your school or department&apos;s teaching strategies based on this data to
        address misconceptions and enhance learning outcomes.
      </>
    )
  },
  gradebook: {
    image: (
      <img alt='gradebook preview' className='report-preview__image' src={gradebookPreviewImage} />
    ),
    description: (
      <>
        With Gradebook, you can compare grades across students and assignments in one report so you
        can quickly export data or pinpoint areas needing attention.
      </>
    )
  },
  students: {
    image: (
      <img alt='students preview' className='report-preview__image' src={studentsPreviewImage} />
    ),
    description: (
      <>
        Analyze student performance to spot trends and better support schools in implementing
        effective, data driven interventions
      </>
    )
  },
  classrooms: {
    image: (
      <img
        alt='classrooms preview'
        className='report-preview__image'
        src={classroomsPreviewImage}
      />
    ),
    description: (
      <>
        Compare performance across your school&apos;s classrooms to support data driven decisions
        within your departments or PLCs. Further refine the data using drilldowns to compare class
        performance at the standard, assignment, question, or student level.
      </>
    )
  },
  teachers: {
    image: (
      <img alt='teachers preview' className='report-preview__image' src={teachersPreviewImage} />
    ),
    description: (
      <>
        Analyze usage and performance by teacher. Use the report to supercharge your department or
        PLC meeting and encourage data backed decisions for each teacher. Further refine the data
        using drilldowns to compare class performance at the standard, assignment, question, or
        student level.
      </>
    )
  },
  schools: {
    image: (
      <img alt='schools preview' className='report-preview__image' src={schoolsPreviewImage} />
    ),
    description: (
      <>
        Compare performance across all the schools in your district to enable data-driven decision
        making. Allocate resources more effectively and implement targeted interventions that drive
        overall district success.
      </>
    )
  },
  subjects: {
    image: (
      <img alt='subjects preview' className='report-preview__image' src={subjectsPreviewImage} />
    ),
    description: (
      <>
        Analyze performance across Albert&apos;s subjects to identify important trends across your
        school. Use the data to inform instructional interventions or to better understand general
        usage of the platform.
      </>
    )
  }
};

const ReportPreview = ({report}: {report: ReportTypes}) => {
  const {image, description} = REPORT_BLOCK_CONFIG[report];
  return (
    <div
      className='report-preview u-pad-t_5 u-display_flex u-flex-direction_column u-align-items_center'
      data-testid='report-preview'
    >
      <Heading size='m' className='u-text-transform_capitalize'>
        Upgrade to Pro to Access {report}
      </Heading>
      <Text className='report-preview__secondary-text u-width_100pc u-max-width_480 u-mar-t_3 u-mar-b_3 u-text-align_center'>
        {description}
      </Text>
      <Text className='report-preview__secondary-text u-mar-b_4 u-font-weight_bold'>
        <Anchor
          underlined
          href='mailto:support@albert.io?subject=Upgrade to Pro to get access to gradebook'
        >
          Email our support team
        </Anchor>{' '}
        to upgrade.
      </Text>
      {image}
    </div>
  );
};

export default ReportPreview;
