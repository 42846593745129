import React from 'react';
import PropTypes from 'prop-types';
import {callAction} from 'client/framework';
import {buildStores} from 'client/InteractionEngineV2/InteractionEngine.util';
import interactionEngineActions from 'client/InteractionEngineV2/InteractionEngineActions';
import interactionEngineStore from 'client/InteractionEngineV2/InteractionEngineStore';
import AssignmentQuestionsListStore from '../AssignmentQuestionsList/AssignmentQuestionsList.store';
import appStore from 'client/AppStore';
import LoadingIndicator from 'generic/LoadingIndicator.react';

export default class AssignmentInitializer extends React.Component {
  static propTypes = {
    assignmentStore: PropTypes.instanceOf(AssignmentQuestionsListStore).isRequired,
    isLoggedIn: PropTypes.bool,
    getChildren: PropTypes.func.isRequired
  };

  constructor() {
    super();
    this.state = {
      hasBootstrapped: false
    };
  }

  UNSAFE_componentWillMount() {
    this.initializeStores().then(() => {
      this.setState({
        hasBootstrapped: true
      });
    });
  }

  componentDidMount() {
    if (appStore.isMobileView()) {
      this.mobileStartAssignmentHandler();
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    /**
     * If the user was not logged, in and now they are... make sure the stores
     * are initialized/bootstrapped.
     */
    if (
      !this.props.isLoggedIn &&
      nextProps.isLoggedIn &&
      !interactionEngineStore.areStoresBootstrapped
    ) {
      this.initializeStores();
    }
  }

  componentWillUnmount() {
    callAction(interactionEngineActions.RESET_ARE_STORES_BOOTSTRAPPED);
  }

  mobileStartAssignmentHandler() {
    if (
      this.props.assignmentStore.activeQuestion === null &&
      !interactionEngineStore.isMobileQuestionAreaInView
    ) {
      callAction(interactionEngineActions.SHOW_MOBILE_QUESTION_AREA);
    }
  }

  async initializeStores() {
    /**
     * Before we initialize our stores, we explicitly await both requests
     * that govern the presence of questions in the Student/Teacher list stores.
     */
    const assignmentQuery = this.props.assignmentStore.getAssignmentQuery();

    /**
     * This is a hack so that we ensure that the assignment is always up to date when we enter this
     * IE. For whatever reason, if we've opened the TeacherAssignmentIE and fetched the assignment,
     * the assignment is sticking around even after we've attempted to invalidate it.
     *
     * Why do this here and not invalidate on componentWillUnmount? Tried that and it didn't work...
     */
    if (this.state.hasBootstrapped === false) {
      assignmentQuery.invalidateInterest();
    }

    const assignment = await assignmentQuery.getResourcePromise();
    const builtStores = buildStores(assignment.getQuestionSets(), assignment.getId());
    callAction(interactionEngineActions.SET_BOOTSTRAPPED_STORES, builtStores);
  }

  render() {
    if (
      interactionEngineStore.areStoresBootstrapped === false ||
      /**
       * isResourcePopulated() is used here to ensure we do not cause a re-render at this level
       * when invalidation occurs for things like Start/Continue assignment.
       */
      this.props.assignmentStore.getAssignmentQuery().isResourcePopulated() === false ||
      this.state.hasBootstrapped === false
    ) {
      return <LoadingIndicator />;
    }
    /**
     * @todo this is intended to handle the API error case, but see the @todo in
     * `this.initializeStores` for more details.
     */
    if (this.props.assignmentStore.getAssignmentQuery().isResourceValid() === false) {
      return null;
    }
    /**
     * getChildren, a callback returning children is used here rather than just plain ol' kids
     * to ensure that the AssignmentInitializer is the FIRST component to make the request
     * for a given assignment. This ensures that we catch 403/404 and show a useful
     * error message to the user in the case of isomorphic renders. (Remember, the
     * isomorphic renderer will redirect on the FIRST uncaught request error!)
     */
    return this.props.getChildren();
  }
}
