import React from 'react';
import PropTypes from 'prop-types';
import {isNumber} from 'lodash';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import Button from 'sg/Button/Button.react';
import {QuestionModelV3} from 'resources/augmented/Question/QuestionModel.v3';
import {Anchor, Text} from '@albert-io/atomic';

import MarkdownBlock from '../shared/MarkdownBlock/MarkdownBlock';

import PassageCorrectionPopover from './shared/PassageCorrectionPopover.react';
import {
  doesRegionContainOptionIndex,
  getRegionKeyForOptionIndex,
  getRegionForOptionIndex,
  getUncorrectedText
} from './PassageCorrectionQuestionType.utils';

export default class PassageCorrectionExplanation extends React.Component {
  static propTypes = {
    question: PropTypes.instanceOf(QuestionModelV3)
  };

  constructor() {
    super();
    this.state = {
      activeIndex: null,
      showCorrectPassage: false
    };
  }

  handleClick = (e) => {
    const {regionIndex} = e.target.dataset;
    if (regionIndex === undefined) {
      return;
    }
    this.setState({
      activeIndex: parseInt(e.target.dataset.idx, 10)
    });
  };

  handleActiveIndexReset = () => {
    this.setState({
      activeIndex: null
    });
  };

  toggleShowHide = () => {
    const updatedValue = !this.state.showCorrectPassage;
    const newState = {
      showCorrectPassage: updatedValue
    };
    if (updatedValue === false) {
      newState.activeIndex = null;
    }
    this.setState(newState);
  };

  wordPropsFunc = (text, optionIndex) => {
    const {question} = this.props;
    const matchedRegion = getRegionForOptionIndex(question, optionIndex);
    /**
     * Only non distractors and distractors with solutions should be interactive.
     */
    if (!matchedRegion || !matchedRegion.get('annotation')) {
      return {text};
    }
    const matchedRegionIndex = getRegionKeyForOptionIndex(question, optionIndex);
    const isAnnotatedDistractor = matchedRegion.get('solutions') === null;
    const splitSolution = isAnnotatedDistractor
      ? null
      : matchedRegion.get('solutions').first().split(/\s+/);
    const matchedStartIndex = matchedRegion.get('start_index');
    const matchedEndIndex = matchedRegion.get('end_index');
    const {activeIndex} = this.state;
    const isActive =
      isNumber(activeIndex) && doesRegionContainOptionIndex(matchedRegion, activeIndex);
    const isRangeStart = matchedStartIndex === optionIndex;
    const isRangeEnd = matchedEndIndex === optionIndex;
    const regionIndexDifference = matchedEndIndex - matchedStartIndex;
    const currentIndex = Math.abs(matchedEndIndex - optionIndex - regionIndexDifference);
    /**
     * A solution can have as many or more words than the incorrect region (i.e. 'alot' can be corrected to 'a lot',
     * but not the other way around). Because of this, if we're rendering the last word in an incorrect region, we
     * render the rest of the words in the solution, joined by a space.
     */
    let correctedText;
    if (isAnnotatedDistractor) {
      correctedText = text;
    } else if (currentIndex === regionIndexDifference) {
      correctedText = splitSolution.slice(regionIndexDifference).join(' ');
    } else {
      correctedText = splitSolution[currentIndex];
    }
    return {
      text,
      correctedText,
      isActive,
      isRangeStart,
      isRangeEnd,
      matchedRegionIndex,
      isCorrect: true
    };
  };

  render() {
    const {question} = this.props;
    const {activeIndex} = this.state;
    const annotation =
      activeIndex !== null ? getRegionForOptionIndex(question, activeIndex).get('annotation') : '';
    return (
      <div>
        <div className='passage-correction-explanation'>
          <div className='passage-correction-explanation__show-hide-toggle-wrapper'>
            <Text as='div' size='xl' bold>
              <Anchor as='button' onClick={this.toggleShowHide}>
                {`${this.state.showCorrectPassage ? 'Hide' : 'Show'} correct passage`}
              </Anchor>
            </Text>
          </div>
          {this.state.showCorrectPassage ? (
            <MarkdownRendererV2
              passageCorrection
              className='passage-correction-question__correct-passage'
              text={getUncorrectedText(question)}
              onClick={this.handleClick}
              wordPropsFunc={this.wordPropsFunc}
              popover={
                <Popover
                  activeIndex={activeIndex}
                  key={activeIndex}
                  annotation={annotation}
                  onReset={this.handleActiveIndexReset}
                />
              }
            />
          ) : null}
        </div>
        <MarkdownBlock heading='Explanation' text={question.getMeta().getSolutionText()} />
      </div>
    );
  }
}

const Popover = ({annotation, ...rest}) => {
  return (
    <PassageCorrectionPopover {...rest}>
      <div className='passage-correction-popover__annotation-wrapper'>
        <div className='a-form-input__label'>Annotation:</div>
        <MarkdownRendererV2 text={annotation} />
      </div>
    </PassageCorrectionPopover>
  );
};

Popover.propTypes = {
  annotation: PropTypes.string
};
