/* eslint-disable */
import {AssignmentModelV1} from '../../resources/GeneratedModels//Assignment/AssignmentModel.v1';
import {AssignmentModelV2} from '../../resources/GeneratedModels//Assignment/AssignmentModel.v2';
import {AssignmentModelV3} from '../../resources/GeneratedModels//Assignment/AssignmentModel.v3';
import {AuthorModelV1} from '../../resources/GeneratedModels//Author/AuthorModel.v1';
import {AuthoringExamModelV1} from '../../resources/GeneratedModels//AuthoringExam/AuthoringExamModel.v1';
import {AuthoringFeedbackModelV1} from '../../resources/GeneratedModels//AuthoringFeedback/AuthoringFeedbackModel.v1';
import {AuthoringGuideModelV1} from '../../resources/GeneratedModels//AuthoringGuide/AuthoringGuideModel.v1';
import {AuthoringGuideLevelModelV1} from '../../resources/GeneratedModels//AuthoringGuideLevel/AuthoringGuideLevelModel.v1';
import {AuthoringLabelModelV1} from '../../resources/GeneratedModels//AuthoringLabel/AuthoringLabelModel.v1';
import {AuthoringQuestionModelV1} from '../../resources/augmented/AuthoringQuestion/AuthoringQuestionModel.v1.js';
import {AuthoringQuestionSetModelV1} from '../../resources/GeneratedModels//AuthoringQuestionSet/AuthoringQuestionSetModel.v1';
import {AuthoringStandardModelV1} from '../../resources/GeneratedModels//AuthoringStandard/AuthoringStandardModel.v1';
import {AuthoringStandardSetModelV1} from '../../resources/GeneratedModels//AuthoringStandardSet/AuthoringStandardSetModel.v1';
import {AuthoringSubjectModelV1} from '../../resources/augmented/AuthoringSubject/AuthoringSubjectModel.v1.js';
import {AuthoringSupplementModelV1} from '../../resources/augmented/AuthoringSupplement/AuthoringSupplementModel.v1.js';
import {ClassroomModelV1} from '../../resources/GeneratedModels//Classroom/ClassroomModel.v1';
import {ClassroomInvitationModelV1} from '../../resources/GeneratedModels//ClassroomInvitation/ClassroomInvitationModel.v1';
import {CleverCourseSyncModelV1} from '../../resources/GeneratedModels//CleverCourseSync/CleverCourseSyncModel.v1';
import {CoppaConsentGrantModelV1} from '../../resources/GeneratedModels//CoppaConsentGrant/CoppaConsentGrantModel.v1';
import {CurriculumAreaModelV1} from '../../resources/GeneratedModels//CurriculumArea/CurriculumAreaModel.v1';
import {CustomerFeedbackEntryModelV1} from '../../resources/GeneratedModels//CustomerFeedbackEntry/CustomerFeedbackEntryModel.v1';
import {DistrictModelV1} from '../../resources/GeneratedModels//District/DistrictModel.v1';
import {DraftAssignmentModelV1} from '../../resources/GeneratedModels//DraftAssignment/DraftAssignmentModel.v1';
import {DraftGuessModelV1} from '../../resources/GeneratedModels//DraftGuess/DraftGuessModel.v1';
import {EmailDomainModelV1} from '../../resources/GeneratedModels//EmailDomain/EmailDomainModel.v1';
import {ExamModelV1} from '../../resources/GeneratedModels//Exam/ExamModel.v1';
import {GradeLevelModelV1} from '../../resources/GeneratedModels//GradeLevel/GradeLevelModel.v1';
import {GuessModelV1} from '../../resources/augmented/Guess/GuessModel.v1.js';
import {GuideModelV1} from '../../resources/GeneratedModels//Guide/GuideModel.v1';
import {GuideLevelModelV1} from '../../resources/GeneratedModels//GuideLevel/GuideLevelModel.v1';
import {GuideLevelModelV2} from '../../resources/GeneratedModels//GuideLevel/GuideLevelModel.v2';
import {LabelModelV1} from '../../resources/GeneratedModels//Label/LabelModel.v1';
import {ManagedUserModelV1} from '../../resources/GeneratedModels//ManagedUser/ManagedUserModel.v1';
import {MediaModelV1} from '../../resources/GeneratedModels//Media/MediaModel.v1';
import {PublicQuestionModelV1} from '../../resources/augmented/PublicQuestion/PublicQuestionModel.v1.js';
import {QuestionModelV1} from '../../resources/GeneratedModels//Question/QuestionModel.v1';
import {QuestionModelV2} from '../../resources/GeneratedModels//Question/QuestionModel.v2';
import {QuestionModelV3} from '../../resources/augmented/Question/QuestionModel.v3.js';
import {QuestionSetModelV1} from '../../resources/GeneratedModels//QuestionSet/QuestionSetModel.v1';
import {RoleModelV1} from '../../resources/GeneratedModels//Role/RoleModel.v1';
import {SchoolModelV1} from '../../resources/GeneratedModels//School/SchoolModel.v1';
import {SchoolModelV2} from '../../resources/GeneratedModels//School/SchoolModel.v2';
import {SchoolModelV3} from '../../resources/GeneratedModels//School/SchoolModel.v3';
import {SchoolModelV4} from '../../resources/GeneratedModels//School/SchoolModel.v4';
import {SchoolModelV5} from '../../resources/GeneratedModels//School/SchoolModel.v5';
import {SchoolLicenseModelV1} from '../../resources/GeneratedModels//SchoolLicense/SchoolLicenseModel.v1';
import {SchoolLicenseSubjectGroupModelV1} from '../../resources/GeneratedModels//SchoolLicenseSubjectGroup/SchoolLicenseSubjectGroupModel.v1';
import {SchoolPersonnelModelV1} from '../../resources/augmented/SchoolPersonnel/SchoolPersonnelModel.v1.js';
import {SchoolPersonnelInvitationModelV1} from '../../resources/GeneratedModels//SchoolPersonnelInvitation/SchoolPersonnelInvitationModel.v1';
import {SearchQuestionModelV1} from '../../resources/GeneratedModels//SearchQuestion/SearchQuestionModel.v1';
import {SearchQuestionModelV2} from '../../resources/GeneratedModels//SearchQuestion/SearchQuestionModel.v2';
import {SearchStandardModelV1} from '../../resources/GeneratedModels//SearchStandard/SearchStandardModel.v1';
import {SearchStandardModelV2} from '../../resources/GeneratedModels//SearchStandard/SearchStandardModel.v2';
import {SearchStandardSetModelV1} from '../../resources/GeneratedModels//SearchStandardSet/SearchStandardSetModel.v1';
import {SearchTagModelV1} from '../../resources/GeneratedModels//SearchTag/SearchTagModel.v1';
import {SectionModelV1} from '../../resources/GeneratedModels//Section/SectionModel.v1';
import {StandardModelV1} from '../../resources/GeneratedModels//Standard/StandardModel.v1';
import {StandardSetModelV1} from '../../resources/GeneratedModels//StandardSet/StandardSetModel.v1';
import {StandardSetCreatorModelV1} from '../../resources/GeneratedModels//StandardSetCreator/StandardSetCreatorModel.v1';
import {StudentModelV1} from '../../resources/GeneratedModels//Student/StudentModel.v1';
import {StudentModelV2} from '../../resources/GeneratedModels//Student/StudentModel.v2';
import {StudentAssignmentModelV1} from '../../resources/GeneratedModels//StudentAssignment/StudentAssignmentModel.v1';
import {StudentClassroomModelV1} from '../../resources/GeneratedModels//StudentClassroom/StudentClassroomModel.v1';
import {SubjectModelV1} from '../../resources/GeneratedModels//Subject/SubjectModel.v1';
import {SubjectModelV2} from '../../resources/GeneratedModels//Subject/SubjectModel.v2';
import {SubjectAuthorPermissionModelV1} from '../../resources/GeneratedModels//SubjectAuthorPermission/SubjectAuthorPermissionModel.v1';
import {SubjectFaqModelV1} from '../../resources/GeneratedModels//SubjectFaq/SubjectFaqModel.v1';
import {SubscriptionModelV1} from '../../resources/GeneratedModels//Subscription/SubscriptionModel.v1';
import {SupplementModelV1} from '../../resources/GeneratedModels//Supplement/SupplementModel.v1';
import {TagModelV1} from '../../resources/GeneratedModels//Tag/TagModel.v1';
import {TeacherModelV1} from '../../resources/GeneratedModels//Teacher/TeacherModel.v1';
import {TemplateModelV1} from '../../resources/GeneratedModels//Template/TemplateModel.v1';
import {UpgradeModelV1} from '../../resources/GeneratedModels//Upgrade/UpgradeModel.v1';
import {UserModelV1} from '../../resources/GeneratedModels//User/UserModel.v1';
import {UserModelV2} from '../../resources/GeneratedModels//User/UserModel.v2';
import {UserFeedbackModelV1} from '../../resources/GeneratedModels//UserFeedback/UserFeedbackModel.v1';
import {AuthoringTestPostModelV1} from '../../resources/GeneratedModels//AuthoringTestPost/AuthoringTestPostModel.v1';
import {RateLimitTestPostModelV1} from '../../resources/GeneratedModels//RateLimitTestPost/RateLimitTestPostModel.v1';
import {RateLimitTestPostModelV2} from '../../resources/GeneratedModels//RateLimitTestPost/RateLimitTestPostModel.v2';
import {ReadOnlyTestPostModelV1} from '../../resources/GeneratedModels//ReadOnlyTestPost/ReadOnlyTestPostModel.v1';
import {TestAvatarModelV1} from '../../resources/GeneratedModels//TestAvatar/TestAvatarModel.v1';
import {TestCategoryModelV1} from '../../resources/GeneratedModels//TestCategory/TestCategoryModel.v1';
import {TestCommentModelV1} from '../../resources/GeneratedModels//TestComment/TestCommentModel.v1';
import {TestEditorModelV1} from '../../resources/GeneratedModels//TestEditor/TestEditorModel.v1';
import {TestPostModelV1} from '../../resources/GeneratedModels//TestPost/TestPostModel.v1';
import {TestSelfUnlinkedCommentModelV1} from '../../resources/GeneratedModels//TestSelfUnlinkedComment/TestSelfUnlinkedCommentModel.v1';
import {TestThreadModelV1} from '../../resources/GeneratedModels//TestThread/TestThreadModel.v1';
import {TestTrianglesModelV1} from '../../resources/GeneratedModels//TestTriangles/TestTrianglesModel.v1';

export {
  AssignmentModelV1,
  AssignmentModelV2,
  AssignmentModelV3,
  AuthorModelV1,
  AuthoringExamModelV1,
  AuthoringFeedbackModelV1,
  AuthoringGuideModelV1,
  AuthoringGuideLevelModelV1,
  AuthoringLabelModelV1,
  AuthoringQuestionModelV1,
  AuthoringQuestionSetModelV1,
  AuthoringStandardModelV1,
  AuthoringStandardSetModelV1,
  AuthoringSubjectModelV1,
  AuthoringSupplementModelV1,
  ClassroomModelV1,
  ClassroomInvitationModelV1,
  CleverCourseSyncModelV1,
  CoppaConsentGrantModelV1,
  CurriculumAreaModelV1,
  CustomerFeedbackEntryModelV1,
  DistrictModelV1,
  DraftAssignmentModelV1,
  DraftGuessModelV1,
  EmailDomainModelV1,
  ExamModelV1,
  GradeLevelModelV1,
  GuessModelV1,
  GuideModelV1,
  GuideLevelModelV1,
  GuideLevelModelV2,
  LabelModelV1,
  ManagedUserModelV1,
  MediaModelV1,
  PublicQuestionModelV1,
  QuestionModelV1,
  QuestionModelV2,
  QuestionModelV3,
  QuestionSetModelV1,
  RoleModelV1,
  SchoolModelV1,
  SchoolModelV2,
  SchoolModelV3,
  SchoolModelV4,
  SchoolModelV5,
  SchoolLicenseModelV1,
  SchoolLicenseSubjectGroupModelV1,
  SchoolPersonnelModelV1,
  SchoolPersonnelInvitationModelV1,
  SearchQuestionModelV1,
  SearchQuestionModelV2,
  SearchStandardModelV1,
  SearchStandardModelV2,
  SearchStandardSetModelV1,
  SearchTagModelV1,
  SectionModelV1,
  StandardModelV1,
  StandardSetModelV1,
  StandardSetCreatorModelV1,
  StudentModelV1,
  StudentModelV2,
  StudentAssignmentModelV1,
  StudentClassroomModelV1,
  SubjectModelV1,
  SubjectModelV2,
  SubjectAuthorPermissionModelV1,
  SubjectFaqModelV1,
  SubscriptionModelV1,
  SupplementModelV1,
  TagModelV1,
  TeacherModelV1,
  TemplateModelV1,
  UpgradeModelV1,
  UserModelV1,
  UserModelV2,
  UserFeedbackModelV1,
  AuthoringTestPostModelV1,
  RateLimitTestPostModelV1,
  RateLimitTestPostModelV2,
  ReadOnlyTestPostModelV1,
  TestAvatarModelV1,
  TestCategoryModelV1,
  TestCommentModelV1,
  TestEditorModelV1,
  TestPostModelV1,
  TestSelfUnlinkedCommentModelV1,
  TestThreadModelV1,
  TestTrianglesModelV1,
};
