import React, {useCallback, useMemo} from 'react';

import {Editable, withReact, Slate} from 'slate-react';
import {withHistory} from 'slate-history';
import {createEditor, Descendant, Editor} from 'slate';
import {flow} from 'lodash';

import Toolbar from './Toolbar/Toolbar.react';

import {
  withLinks,
  withCode,
  withBackspaceTransform,
  withImages,
  withCorrectVoidBehavior,
  withAudioChips
} from './Utils/plugins';
import keyboardHandler from './Utils/keyboardHandlers';
import {Element, Leaf} from './Elements/Elements.react';

import './written-submission.scss';

interface Props {
  onChange: (value: Descendant[]) => void;
  value: Descendant[];
  readOnly?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>, editor: Editor) => void;
}

interface PropsWithLabel extends Props {
  'aria-label': string;
}

interface PropsLabelBy extends Props {
  'aria-labelledby': string;
}

const plugins = [
  withReact,
  withHistory,
  withLinks,
  withCode,
  withBackspaceTransform,
  withImages,
  withAudioChips,
  withCorrectVoidBehavior
];
const usePlugins = flow(plugins);

const WrittenSubmissionInput = ({
  value,
  onChange,
  readOnly,
  onKeyDown = keyboardHandler,
  ...rest
}: PropsWithLabel | PropsLabelBy) => {
  const renderElement = useCallback(
    (props) => <Element {...props} readOnly={readOnly} />,
    [readOnly]
  );
  const renderLeaf = useCallback((props) => <Leaf {...props} readOnly={readOnly} />, [readOnly]);
  const editor = useMemo(() => usePlugins(createEditor()), []);
  editor.children = value; // main way to handle outside updates of state

  const keyDownHandler = useCallback((e: any) => onKeyDown(e, editor), [editor, onKeyDown]);

  return (
    <Slate editor={editor} value={value} onChange={onChange}>
      {!readOnly && <Toolbar />}
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        onKeyDown={keyDownHandler}
        spellCheck={false}
        readOnly={readOnly}
        className='written-submission__input'
        onBlur={(e) => e.preventDefault()} // maintains selection
        {...rest}
      />
    </Slate>
  );
};

export default WrittenSubmissionInput;
