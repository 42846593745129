import React from 'react';
import PropTypes from 'prop-types';
import {List} from 'immutable';
import classnames from 'classnames';
import {
  List as VirtualizedList,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache
} from 'react-virtualized';
import {debounce} from 'lodash';
import {easeInOutCirc} from 'js-easing-functions';
import {SortableContainerContext, SortableElement, IconButton} from '@albert-io/atomic';
import QuestionSet, {
  questionSetItemProps
} from 'components/PracticeView/QuestionSet/QuestionSet.react';
import './questions-list.scss';

const propTypes = {
  ...questionSetItemProps,
  questionSets: PropTypes.instanceOf(List),
  scrollToQuestionById: PropTypes.ref
};

class QuestionSetsWrapper extends React.Component {
  static propTypes = propTypes;

  static defaultProps = {
    scrollToQuestionById: () => {}
  };

  componentDidMount() {
    this.listRef.current.measureAllRows();
    this.props.scrollToQuestionById.current = this.scrollToQuestionById;
  }

  componentDidUpdate(prevProps) {
    if (this.shouldClearMeasurerCache(prevProps)) {
      this.cellMeasurerCache.clearAll();
      this.forceUpdateGrid();
      this.listRef.current.measureAllRows();
    } else if (this.shouldForceUpdateList(prevProps)) {
      this.forceUpdateGrid();
    } else if (this.props.scrollToTop === true && this.props.questionSetProps.rearrangeable) {
      this.smoothScrollToPosition(0);
    }
  }

  listRef = React.createRef();

  cellMeasurerCache = new CellMeasurerCache({
    fixedWidth: true,
    minHeight: 0
  });

  /**
   * Not using state for this because we don't want to trigger a rerender on scroll.
   */
  scrollTop = 0;

  /**
   * Clearing the cellMeasurerCache should happen if any props change that may alter
   * the dimensions of the items in the questions list.
   *
   * @param prevProps
   */
  shouldClearMeasurerCache(prevProps) {
    /**
     * @param props
     * @todo: Need to clear if the guess map changes as well
     */
    const getDiffProps = (props) => {
      const {questionProps} = props;
      const {questionSetProps} = props;
      return [
        questionProps.showAttemptIndicators,
        questionProps.showDifficulty,
        questionSetProps.obscureTitles,
        questionSetProps.rearrangeable,
        questionSetProps.selectable && questionSetProps.selectable(),
        props.questionSets.hashCode(),
        props.questionSets
      ];
    };
    const current = getDiffProps(this.props);
    const previous = getDiffProps(prevProps);
    return current.some((val, i) => val !== previous[i]);
  }

  /**
   * Force-updating the list should happen if any props change that don't affect the dimensions
   * of the items in the questions list, but do affect the visual styling of the items.
   *
   * @param prevProps
   */
  shouldForceUpdateList(prevProps) {
    const getDiffProps = (props) => {
      return [
        props.activeQuestion,
        props.questionSets.size,
        props.questionProps.strikethroughQuestionInfo,
        props.questionSetProps.selectedQuestionSets,
        props.questionSetProps.isSelectAllQuestionSetsMode
      ];
    };
    const current = getDiffProps(this.props);
    const previous = getDiffProps(prevProps);
    return current.some((val, i) => val !== previous[i]);
  }

  forceUpdateGrid() {
    setImmediate(() => {
      if (!this.listRef.current) {
        return;
      }
      this.listRef.current.forceUpdateGrid();
    });
  }

  rowRenderer = ({index, key, parent, style}) => {
    const questionSet = this.props.questionSets.get(index);
    const sortHandle = (
      <IconButton
        label={`Reorder icon: select ${questionSet.getName()} to move its position.`}
        variant='solid'
        color='primary'
        size='s'
        className='questions-list__question-sort-handler u-mar-tb_auto u-mar-r_2'
        icon={['far', 'hand-pointer']}
      />
    );
    const cancelHandle = (
      <IconButton
        roundness=''
        label='cancel reordering'
        className='questions-list__question-sort-handler u-mar-tb_auto u-mar-r_2'
        variant='solid'
        color='primary'
        size='s'
        icon='times'
      />
    );
    return (
      <CellMeasurer
        cache={this.cellMeasurerCache}
        columnIndex={0}
        key={key}
        rowIndex={index}
        parent={parent}
      >
        <SortableContainerContext.Consumer>
          {(value) => {
            return (
              <SortableElement
                {...value}
                index={index}
                key={key}
                customCancelHandle={cancelHandle}
                customSortHandle={sortHandle}
              >
                {({SortHandler, isActiveElement, recentlyMovedIndices, HoverArea, isSorting}) => {
                  const dragHandle = <SortHandler className='u-mar-r_2' />;
                  const hoverClickArea = <HoverArea />;
                  const recentlyMoved = recentlyMovedIndices?.includes(index);
                  return (
                    <div
                      style={style}
                      className={classnames('question-set-wrapper', {
                        'question-set-v2__questions-wrapper--active-rearrange-item': isActiveElement
                      })}
                    >
                      <QuestionSet
                        hoverClickArea={hoverClickArea}
                        dragHandle={dragHandle}
                        recentlyMoved={recentlyMoved}
                        key={questionSet.getId()}
                        idx={index}
                        questionSet={questionSet}
                        questionSetProps={this.props.questionSetProps}
                        questionProps={this.props.questionProps}
                        activeQuestion={this.props.activeQuestion}
                        isActiveRearrangeElement={isActiveElement}
                        isSorting={isSorting}
                      />
                    </div>
                  );
                }}
              </SortableElement>
            );
          }}
        </SortableContainerContext.Consumer>
      </CellMeasurer>
    );
  };

  handleRowsRendered = debounce(({stopIndex}) => {
    const isLastQuestionScrolledIntoView = stopIndex + 1 === this.props.questionSets.size;
    if (isLastQuestionScrolledIntoView && this.props.onScrollLastQuestionIntoView) {
      this.props.onScrollLastQuestionIntoView();
    }
  }, 250);

  updateScrollPosition = ({scrollTop}) => {
    this.scrollTop = scrollTop;
  };

  smoothScrollToPosition(endPos, {startPos = this.scrollTop, startTime = Date.now()} = {}) {
    const duration = 1000;
    const elapsed = Date.now() - startTime;
    const nextPos = easeInOutCirc(elapsed, startPos, endPos - startPos, duration);
    this.listRef.current.scrollToPosition(nextPos);
    if (elapsed < duration) {
      global.requestAnimationFrame(() => {
        this.smoothScrollToPosition(endPos, {startPos, startTime});
      });
    }
  }

  scrollToQuestionById = (questionSetId) => {
    const index = this.props.questionSets.findIndex(
      (questionSet) => questionSet.getId() === questionSetId
    );
    if (index === undefined) {
      return;
    }
    const offset = this.listRef.current.getOffsetForRow({
      alignment: 'start',
      index
    });
    this.smoothScrollToPosition(offset);
  };

  render() {
    return (
      <div className='question-sets-wrapper'>
        <AutoSizer>
          {({width, height}) => (
            <VirtualizedList
              ref={this.listRef}
              deferredMeasurementCache={this.cellMeasurerCache}
              height={height}
              width={width}
              rowHeight={this.cellMeasurerCache.rowHeight}
              rowRenderer={this.rowRenderer}
              rowCount={this.props.questionSets.size}
              onRowsRendered={this.handleRowsRendered}
              onScroll={this.updateScrollPosition}
              className='question-sets-wrapper__virtualized-list'
            />
          )}
        </AutoSizer>
      </div>
    );
  }
}

export default QuestionSetsWrapper;
