/**
 * TODO:
 * Eventually possibly automate the generation of these thru parsedoc - adhering to a
 * naming convention outlined below will make this transition hopefully seamless later
 * on if that is needed.
 *
 * Format of keys is the last section of the error string camel cased and capitalized:
 *
 * @example
 * the code: Errors.BadRequest.ValidationError.UniqueConstraintViolated
 * yields the name: UNIQUE_CONSTRAINT_VIOLATED
 */

export default {
  BAD_REQUEST: {
    TIMEOUT: 'Timeout of 60000ms exceeded',
    SOFT_DELETE_RELATIONSHIP_VIOLATION: 'Errors.BadRequest.SoftDeletionRelationshipViolation',
    VALIDATION_ERROR: {
      DESCENDANTS_WITH_QUESTION_SETS:
        'Errors.BadRequest.ValidationError.DescendantsWithQuestionSets',
      GUIDE_LEVEL_NAME_CONFLICT: 'Errors.BadRequest.ValidationError.GuideLevelNameConflict',
      GUIDE_LEVEL_WITH_DUPLICATE_ALIGNMENT:
        'Errors.BadRequest.ValidationError.GuideLevelWithDuplicateAlignment',
      INVALID_EMAIL_DOMAIN: 'Errors.BadRequest.InvalidEmailDomain',
      INVALID_SIGN_UP_METHOD: 'Errors.BadRequest.ValidationError.InvalidSignUpMethod',
      INVALID_PASSWORD_RESET_LINK: 'Errors.BadRequest.ValidationError.InvalidPasswordResetLink',
      LITERAL: 'Errors.BadRequest.ValidationError',
      NO_PASSWORD_RESET_REQUESTED: 'Errors.BadRequest.ValidationError.NoPasswordResetRequested',
      PASSWORD_RESET_LINK_NO_LONGER_ACTIVE:
        'Errors.BadRequest.ValidationError.PasswordResetLinkNoLongerActive',
      PUBLISHING_EMPTY_GUIDE_LEVEL: 'Errors.BadRequest.ValidationError.PublishingEmptyGuideLevel',
      QUESTION_SET_IN_HIDDEN_SUBJECT:
        'Errors.BadRequest.ValidationError.QuestionSetInHiddenSubject',
      STUDENT_SCHOOL_PERSONNEL_INVITATION_ACCEPT:
        'Mandark.Errors.BadRequest.StudentSchoolPersonnelInvitationAccept',
      SUPPLEMENT_USED_IN_QUESTION: 'Errors.BadRequest.ValidationError.SupplementUsedInQuestion',
      UNIQUE_CONSTRAINT_VIOLATED: 'Errors.BadRequest.ValidationError.UniqueConstraintViolated',
      DUPLICATE_EMAIL: 'Errors.BadRequest.ValidationError.ManagedUsers.DuplicateEmailFound',
      DUPLICATE_USERNAME: 'Errors.BadRequest.ValidationError.ManagedUsers.DuplicateUsernameFound',
      UNIQUE_EMAIL_CONSTRAINT_VIOLATED:
        'Errors.BadRequest.ValidationError.ManagedUsers.ExistingEmailFound',
      UNIQUE_USERNAME_CONSTRAINT_VIOLATED:
        'Errors.BadRequest.ValidationError.ManagedUsers.ExistingUsernameFound'
    },
    QUESTION_IS_ARCHIVED: 'Errors.BadRequest.ValidationError.QuestionIsArchived',
    QUESTION_IS_HIDDEN: 'Errors.BadRequest.ValidationError.QuestionIsHidden'
  },
  FORBIDDEN: {
    EXCEEDED_HARD_CAP_LIMIT: 'Errors.Forbidden.ExceededHardCapLimit'
  },
  ERRORS: {
    INACTIVE_ACCOUNT_UNAUTHORIZED: 'Errors.InactiveAccountUnauthorized'
  },
  GOOGLE: {
    POPUP_BLOCKED: 'popup_blocked_by_browser',
    COOKIES_DISABLED: 'Cookies are not enabled in current environment.'
  },
  OAUTH: {
    ERROR: 'OAuth.Error'
  }
};
