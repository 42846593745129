import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {omit} from 'lodash';
import makeConstants from 'lib/makeConstants';

import Text from '../../../atoms/Text/Text.react';
import IconButton from '../../../molecules/IconButton/IconButton.react';
import './header.scss';

const nonPassableProps = [
  'customSortFunc',
  'defaultSortDirection',
  'index',
  'setSortByFunc',
  'setSortedColumn',
  'setSortDirection'
];

export const sortOptions = makeConstants('ASCENDING', 'DESCENDING');

export default class Header extends React.Component {
  static propTypes = {
    align: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    customSortFunc: PropTypes.func,
    defaultSortDirection: PropTypes.string,
    index: PropTypes.number,
    isCurrentSort: PropTypes.bool,
    primary: PropTypes.bool,
    setSortByFunc: PropTypes.func,
    setSortDirection: PropTypes.func,
    setSortedColumn: PropTypes.func,
    sortable: PropTypes.bool,
    iconStyle: PropTypes.string
  };

  static defaultProps = {
    setSortByFunc: () => {}
  };

  constructor(props) {
    super(props);

    this.initializeSortBy(props);

    this.state = {
      sortDirection: props.defaultSortDirection
    };
  }

  initializeSortBy(props) {
    if (props.sortable && props.isCurrentSort) {
      props.setSortByFunc();
    }
  }

  setSort = () => {
    let newSort = this.state.sortDirection;
    if (!this.props.isCurrentSort) {
      this.props.setSortedColumn(this.props.index);
      this.props.setSortByFunc();
    } else {
      newSort = newSort === sortOptions.ASCENDING ? sortOptions.DESCENDING : sortOptions.ASCENDING;
      this.setState({
        sortDirection: newSort
      });
    }

    this.props.setSortDirection(newSort);
  };

  render() {
    const {children, className, align, isCurrentSort, primary, sortable, iconStyle, ...rest} =
      this.props;

    const {sortDirection} = this.state;
    const thProps = omit(rest, nonPassableProps);

    return (
      <th
        className={classnames('o-table__header', 'u-display_flex', className, {
          [`o-table__header--${align}`]: align,
          'o-table__header--primary': primary
        })}
        {...thProps}
      >
        <Text className='o-table__header-text' size='m' bold>
          {children}
        </Text>
        {sortable && (
          <IconButton
            color={isCurrentSort ? 'primary' : 'secondary'}
            className='o-table__header-sort'
            label='sort'
            iconStyle={iconStyle}
            size='s'
            icon={`arrow-${sortDirection === sortOptions.ASCENDING ? 'up' : 'down'}`}
            onClick={this.setSort}
          />
        )}
      </th>
    );
  }
}
