import {useCallback, useEffect} from 'react';

import {isNil} from 'lodash';

function getElementHeightWithMargins(element: HTMLElement | null | undefined) {
  if (isNil(element)) {
    return 0;
  }

  const {marginBottom, marginTop} = window.getComputedStyle(element);
  const margins = parseFloat(marginTop) + parseFloat(marginBottom);

  return Math.ceil(element.offsetHeight + margins);
}

export function useFixedTableHeader() {
  const setTableHeaderRef = useCallback((element) => {
    const translateHeader = () => {
      if (element) {
        const lastScrollY = window.pageYOffset;

        const navBar = document.getElementsByClassName('global-nav')[0];
        const navBarHeight = getElementHeightWithMargins(navBar as HTMLElement);

        const sharedReportBar = document.getElementById('report-selector_shared-report-banner');
        const sharedReportBarHeight = getElementHeightWithMargins(sharedReportBar);

        const summaryStats = document.getElementById('summary-stats');
        const summaryStatsHeight = getElementHeightWithMargins(summaryStats);

        const positionToStartStickyBehavior =
          navBarHeight + sharedReportBarHeight + summaryStatsHeight;

        // don't start the sticky behavior of header until we have scrolled past the navbar
        if (lastScrollY > positionToStartStickyBehavior) {
          // we now want to translate the header down by the amount we have scrolled past the navbar so that it appears to be fixed
          const yTranslation = Math.floor(Math.abs(lastScrollY - positionToStartStickyBehavior));
          element.style.setProperty('transform', `translateY(${yTranslation}px)`);
        } else {
          element.style.removeProperty('transform');
        }
      }
    };

    // Add and clean up the scroll event listener
    window.addEventListener('scroll', translateHeader);
    return () => {
      window.removeEventListener('scroll', translateHeader);
      if (element) {
        element.style.removeProperty('transform');
      }
    };
  }, []);

  // Use effect to manage side effects
  useEffect(() => {
    // No need to use tableHeaderRef.current here
    return () => {};
  }, [setTableHeaderRef]);

  return setTableHeaderRef;
}
