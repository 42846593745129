import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './row.scss';

const Row = ({className, isSelected, ...rest}) => {
  return (
    <tr
      className={classnames('o-table__row', className, {
        'o-table__row--selected': isSelected
      })}
      {...rest}
    />
  );
};

Row.propTypes = {
  className: PropTypes.string,
  isSelected: PropTypes.bool
};

export default Row;
