import React, {useContext} from 'react';

import {ReportsContext} from 'client/Reports/Reports.context';
import {METRIC_COLUMNS} from 'client/Reports/reports.utils';
import type {SortOption} from 'client/Reports/reports.types';
import {Icon, Text, WithTooltip} from '@albert-io/atomic';

import {HeaderCell} from './HeaderCell';

interface SortableAnswerBreakdownHeaderCellProps {
  showSort: true;
  onSortClick: (sortKey: string, sortDirection: SortOption) => void;
  sortDirection: SortOption;
  sortBy: string | null;
}

interface NonSortableAnswerBreakdownHeaderCellProps {
  showSort: false;
}

type AnswerBreakdownHeaderCellProps =
  | SortableAnswerBreakdownHeaderCellProps
  | NonSortableAnswerBreakdownHeaderCellProps;

export const AnswerBreakdownHeaderTooltip = () => (
  <WithTooltip
    content={
      <div className='u-gap_space-x1'>
        <div className='u-display_flex u-align-items_center u-gap_space-x1'>
          <Icon icon='check' />
          <Text color='primary-inverse'>Correct</Text>
        </div>
        <div className='u-display_flex u-align-items_center u-gap_space-x1'>
          <Icon icon='close' /> <Text color='primary-inverse'>Incorrect</Text>
        </div>
        <div className='u-display_flex u-align-items_center u-gap_space-x1'>
          <Icon icon='circle-dashed' iconStyle='regular' />
          <Text color='primary-inverse'>Unanswered</Text>
        </div>
        <div className='u-display_flex u-align-items_center u-gap_space-x1'>
          <Icon icon='flag-checkered' iconStyle='regular' />
          <Text color='primary-inverse'>Total answered</Text>
        </div>
      </div>
    }
    placement='bottom'
  >
    <Icon icon='info-circle' iconStyle='regular' />
  </WithTooltip>
);

export const AnswerBreakdownHeaderCell = ({
  showSort = true,
  ...props
}: AnswerBreakdownHeaderCellProps) => {
  const {variables, dimensions} = useContext(ReportsContext);
  const isSingleStudentQuestion =
    (dimensions.includes('students') && variables.questionFilter) ||
    (dimensions.includes('questions') && variables.studentFilter);

  const columnDefinition = METRIC_COLUMNS.find((c) => c.key === 'answer_breakdown')!;
  // should never happen, just a way to get ts to play nice
  if (columnDefinition.metricType !== 'renderedTitle') {
    return null;
  }

  if (!showSort) {
    return (
      <HeaderCell
        title={
          <div className='u-display_flex u-gap_space-x2'>
            <columnDefinition.renderTitle dimensions={dimensions} variables={variables} />
            <AnswerBreakdownHeaderTooltip />
          </div>
        }
        showSort={false}
      />
    );
  }

  const SORTS = [
    {
      label: 'Correct',
      sortKey: 'metrics.count_correct_attempts'
    },
    {
      label: 'Incorrect',
      sortKey: 'metrics.count_incorrect_attempts'
    },
    {
      label: 'Unanswered',
      sortKey: 'metrics.count_unattempted'
    },
    ...(isSingleStudentQuestion
      ? []
      : [
          {
            label: 'Total',
            sortKey: 'metrics.count_attempts'
          }
        ])
  ] as const;

  const {sortBy, sortDirection, onSortClick} = props as SortableAnswerBreakdownHeaderCellProps;

  const isCurrentSort = !!(
    sortBy &&
    [
      'metrics.count_correct_attempts',
      'metrics.count_incorrect_attempts',
      'metrics.count_attempts',
      'metrics.count_unattempted'
    ].includes(sortBy)
  );

  return (
    <HeaderCell
      title={
        <div className='u-display_flex u-gap_space-x2'>
          <columnDefinition.renderTitle dimensions={dimensions} variables={variables} />
          <AnswerBreakdownHeaderTooltip />
        </div>
      }
      showSort
      isCurrentSort={isCurrentSort}
      onSortClick={onSortClick}
      sortDirection={sortDirection}
      sortBy={sortBy}
      sorts={SORTS}
    />
  );
};
