import React from 'react';
import classnames from 'classnames';
import {ButtonProps} from '@albert-io/atomic/atoms/Button/Button.react';

import {Button, Icon, WithTooltip} from '@albert-io/atomic';

import GoogleContext from '../Google.context';

interface Props extends ButtonProps<'button'> {
  hideIcon?: boolean;
}

// TODO: define this type
type GoogleC = any;

const GoogleSwitchAccountsButton = ({className, hideIcon = false, ...btnProps}: Props) => {
  const {isRequestPending, handleSignOut, loggedInUserEmail} =
    React.useContext<GoogleC>(GoogleContext);
  const hasUserEmail = !!loggedInUserEmail;
  return (
    <WithTooltip
      content={`Currently signed in as ${loggedInUserEmail}`}
      enabled={hasUserEmail}
      propagateHoverEvent
      theme='light'
      zLevel='modal'
    >
      <Button
        aria-label={
          loggedInUserEmail
            ? `Currently signed into Google as ${loggedInUserEmail}. Click to switch accounts.`
            : undefined
        }
        className={classnames('u-display_inline-flex', 'u-align-items_center', className)}
        color='secondary'
        disabled={isRequestPending}
        onClick={handleSignOut}
        {...btnProps}
      >
        {!hideIcon && <Icon className='u-mar-r_1' icon={['fab', 'google']} />}
        Switch accounts
      </Button>
    </WithTooltip>
  );
};

export default GoogleSwitchAccountsButton;
