import {useEffect, useState} from 'react';
import sessionStore from 'client/Session/SessionStore';

import {
  getAllSchoolsUserIsAdminQuery,
  getClassroomQuery,
  getSchoolQuery,
  getTeacherWithMetadataQuery
} from './hooks.utils';

interface hasProUpgradeResponse {
  hasProUpgrade: boolean;
  fetchingProUpgrade: boolean;
  error: Error | undefined;
}

/**
 * helper function that gets the role of the user at a school
 *
 * @param {string} schoolId id of the school
 * @returns {any} the role of the user at the school
 */
const getRoleOfUserAtSchool = async (schoolId: string) => {
  try {
    const schoolWithPersonnelInformation = await getTeacherWithMetadataQuery().getResourcePromise();
    const schoolPersonnelArray = schoolWithPersonnelInformation.getSchoolPersonnel();
    const schoolRole = schoolPersonnelArray.find(
      (personnel) => personnel.getSchool().getId() === schoolId
    );
    if (!schoolRole) {
      return null;
    }
    return schoolRole;
  } catch (e) {
    throw new Error(`useHasProUpgrade: Error getting user's role at school ${e}`);
  }
};

/**
 * helper function to get all the schools where the user is an admin
 *
 * @param {string} adminId the id of the admin
 * @returns {any} the schools where the user is an admin
 */
export const getSchoolsOfAdmin = async (adminId: string) => {
  try {
    const schoolsWhereUserIsAdmin = await getAllSchoolsUserIsAdminQuery(
      adminId
    ).getResourcePromise();
    return schoolsWhereUserIsAdmin;
  } catch (e) {
    throw new Error(`useHasProUpgrade: Error getting schools of admin ${e}`);
  }
};

/**
 * helper function to check if a school has a pro upgrade
 *
 * @param {any} school mandark school_v4 resource with school_licenses_v1.upgrades_v1 included
 * @returns {boolean} true or false depending on if one of the liscenses is active and has a pro upgrade. If any errors in the process, it returns false
 */
export const schoolHasProUpgrade = (school) => {
  let hasProUpgrade = false;
  try {
    const liscenses = school.getSchoolLicenses();
    liscenses.forEach((license) => {
      if (license.active) {
        license.upgrades.forEach((upgrade) => {
          if (upgrade.upgrade_type === 'pro') {
            hasProUpgrade = true;
          }
        });
      }
    });
    return hasProUpgrade;
  } catch (e) {
    return false;
  }
};

/**
 * This hook checks if a school has a pro upgrade or if a classroom is associated with a school that has a pro upgrade
 * @param {string} type the type of the resource to check for pro upgrade. This should be school or classroom
 * @param {string} id the id of the resource to check for pro upgrade
 * @returns {hasProUpgradeResponse} hasProUpgradeResponse and fetchingProUpgrade
 */
export function useHasProUpgrade(
  type: string, // 'classrooms' | 'schools'
  id: string
): hasProUpgradeResponse {
  const [hasProUpgrade, setHasProUpgrade] = useState(false);
  const [fetchingProUpgrade, setFetchingProUpgrade] = useState(true);
  const [error, setError] = useState<Error>();

  useEffect(() => {
    let subscribed = true;
    const fetchActiveSubscriptions = async () => {
      if (!subscribed) return;
      try {
        setFetchingProUpgrade(true);
        setHasProUpgrade(false);
        if (sessionStore.isSuper()) {
          setHasProUpgrade(true);
          setFetchingProUpgrade(false);
          return;
        }
        let schoolId;
        if (type === 'classrooms') {
          const classroom = await getClassroomQuery(id).getResourcePromise();
          schoolId = classroom.getSchool().getId();
        } else if (type === 'schools') {
          schoolId = id;
        } else if (type === 'school-admin') {
          const adminId = id === 'me' ? sessionStore.getUserId() : id;
          const schoolsWhereUserIsAdmin = await getSchoolsOfAdmin(adminId);
          const schoolsThatDontHaveProUpgrade = schoolsWhereUserIsAdmin.filter(
            (school) => !schoolHasProUpgrade(school)
          );
          setHasProUpgrade(schoolsThatDontHaveProUpgrade.size === 0);
          setFetchingProUpgrade(false);
          return;
        } else {
          // if the type is not school, classroom, or school-admin, we don't have to check for pro upgrade
          setHasProUpgrade(false);
          setFetchingProUpgrade(false);
          return;
        }
        const userRole = await getRoleOfUserAtSchool(schoolId);

        if (!userRole || userRole.getStatus() !== 'approved') {
          // if user is not approved at a certain school, they do not have access to any of features under pro upgrades
          setHasProUpgrade(false);
          setFetchingProUpgrade(false);
          return;
        }

        const school = await getSchoolQuery(schoolId).getResourcePromise();

        setHasProUpgrade(schoolHasProUpgrade(school));
        setFetchingProUpgrade(false);
      } catch (e) {
        setError(e as Error);
        setFetchingProUpgrade(false);
      }
    };
    fetchActiveSubscriptions();
    return () => {
      subscribed = false;
    };
  }, [type, id]);

  return {
    hasProUpgrade,
    fetchingProUpgrade,
    error
  };
}
