import {pushQueryParams, removeQueryParams, getQueryParamsAsObject} from 'client/history';
import makeConstants from 'lib/makeConstants';
import sessionStore from 'client/Session/SessionStore';

import {invalidatePartialInterest} from 'resources/mandark.resource';
import {Set} from 'immutable';

export const allFilters = ['difficulty', 'status', 'savedInFolder', 'usedInAssignment'] as const;
export const allDifficulties = ['easy', 'moderate', 'difficult'] as const;
export const allStatuses = ['answered', 'unanswered'] as const;
export const allRadioStatuses = ['true', 'false'] as const;

type FilterType = typeof allFilters[number];
type Difficulty = typeof allDifficulties[number];
type Status = typeof allStatuses[number];
type RadioStatus = typeof allRadioStatuses[number];

export const filterType = makeConstants(...allFilters);
export const difficulty = makeConstants(...allDifficulties);
export const status = makeConstants(...allStatuses);
export const radioStatus = makeConstants(...allRadioStatuses);

export type ParamValueMap = {
  [filterType.status]: Status;
  [filterType.difficulty]: Difficulty;
  [filterType.savedInFolder]: RadioStatus;
  [filterType.usedInAssignment]: RadioStatus;
};

export const paramOptionsMap = {
  [filterType.status]: allStatuses,
  [filterType.difficulty]: allDifficulties,
  [filterType.savedInFolder]: allRadioStatuses,
  [filterType.usedInAssignment]: allRadioStatuses
};

export type Params = {
  [key in FilterType]?: string;
};

export const isRadio = (label: FilterType) => {
  switch (label) {
    case filterType.status:
    case filterType.savedInFolder:
    case filterType.usedInAssignment:
      return true;
    case filterType.difficulty:
    default:
      return false;
  }
};

export const setFilterValue = <L extends keyof ParamValueMap>(
  label: L,
  value: ParamValueMap[L],
  add: boolean
) => {
  const params: Params = getQueryParamsAsObject();

  let nextValues = params[label] ? params[label]!.split(',') : [];

  if (add) {
    if (isRadio(label)) {
      removeQueryParams(label); // clear before adding new params
      nextValues = [];
    }
    nextValues.push(value);
  } else {
    nextValues = nextValues.filter((val) => val !== value);
  }
  if (nextValues.length === 0) {
    removeQueryParams(label);
  } else {
    pushQueryParams({[label]: nextValues.join(',')});
  }
};

export const hasActiveFilter = () => {
  const params: Params = getQueryParamsAsObject();
  const allFilterTypes = allFilters;
  const hasFilter = allFilterTypes.some((type) => {
    return !!params[type];
  });

  return hasFilter;
};

export const getActiveFilterCount = () => {
  const params: Params = getQueryParamsAsObject();
  const allFilterTypes = allFilters;
  const filterCount = allFilterTypes.reduce((count, type) => {
    const isActive = !!params[type];
    if (isActive) {
      return count + params[type]!.split(',').length;
    }
    return count;
  }, 0);

  return filterCount;
};

export const isChecked = <L extends keyof ParamValueMap>(
  label: L,
  value: ParamValueMap[L]
): boolean => {
  const params: Params = getQueryParamsAsObject();
  const filterExists = !!params[label];
  const filterValue = filterExists ? params[label]! : '';

  const includesValue = filterValue.split(',').includes(value);

  return filterExists && includesValue;
};

export const resetFilterValues = () => {
  removeQueryParams(...allFilters);
};

// ---- Query Builder functions

export const difficultyToNumMap = {
  [difficulty.easy]: 1,
  [difficulty.moderate]: 2,
  [difficulty.difficult]: 3
};

const paramsToQueryFilter = (param: keyof ParamValueMap) => {
  const params: Params = getQueryParamsAsObject();

  const value = params[param];
  const valueString = value || '';
  const valuesArray = valueString.split(',');

  let filterParams: [any, boolean] = [{}, false];
  const isLoggedIn = sessionStore.hasValidSession();
  const isStudent = sessionStore.isStudent();

  if (param === filterType.difficulty) {
    const difficultyValues = valuesArray.map((val) => difficultyToNumMap[val]).join(',');
    const hasDifficultyValue = !!value;
    filterParams = [
      {
        questions_v3: {
          difficulty: difficultyValues
        }
      },
      hasDifficultyValue
    ];
  }
  if (param === filterType.status && isLoggedIn) {
    const hasStatusValue = !!value;
    filterParams = [
      {
        'meta.student_question_set_answered': valuesArray[0] === status.answered
      },
      hasStatusValue
    ];
  }
  if (param === filterType.savedInFolder && isLoggedIn && !isStudent) {
    const hasSavedValue = !!value;

    filterParams = [
      {
        'meta.is_used_in_folder': valuesArray[0] === radioStatus.true
      },
      hasSavedValue
    ];
  }
  if (param === filterType.usedInAssignment && isLoggedIn && !isStudent) {
    const hasAssignmentValue = !!value;

    filterParams = [
      {
        'meta.is_used_in_assignment': valuesArray[0] === radioStatus.true
      },
      hasAssignmentValue
    ];
  }

  return filterParams;
};

const addContextFilter = (query) => {
  const {savedInFolder, usedInAssignment}: Params = getQueryParamsAsObject();

  const hasFolderFilter = !!savedInFolder;
  const hasAssignmentFilter = !!usedInAssignment;

  if (!sessionStore.hasValidSession() || sessionStore.isStudent()) {
    return query;
  }

  if (hasFolderFilter || hasAssignmentFilter) {
    let folderUsage = {};
    let assignmentUsage = {};

    if (hasFolderFilter) {
      folderUsage = {folder_usage: true};
    }
    if (hasAssignmentFilter) {
      assignmentUsage = {assignment_usage: true};
    }

    const customQuery = {
      meta: {
        context: {
          user: {
            id: sessionStore.getUserId()
          },
          ...folderUsage,
          ...assignmentUsage
        }
      }
    };
    return query.customQuery(customQuery);
  }

  return query;
};

let filterQueryHistory = Set();

export const applyFilters = (query: any) => {
  const filters = allFilters;
  const queryWithFilters = filters.reduce((returnQuery, label) => {
    const queryFilter = paramsToQueryFilter(label);
    return returnQuery.filter(...queryFilter);
  }, query);

  const queryWithContext = addContextFilter(queryWithFilters);

  filterQueryHistory = filterQueryHistory.add(queryWithContext);

  return queryWithContext;
};

export const invalidateFilters = () => {
  filterQueryHistory.forEach((filter: any) => {
    invalidatePartialInterest({resourcePath: filter.done().resourcePath});
  });
  filterQueryHistory = Set();
};
