import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import PracticeView from 'components/PracticeView/PracticeView.react';
import TitleBar from 'components/PracticeView/TitleBar/TitleBar.react';
import QuestionsListHeader from 'components/PracticeView/QuestionsListHeader/QuestionsListHeader.react';

const NoQuestionSetsFoundSubjectPracticeView = ({subject, guideLevel, subjectSlug, toolbar}) => {
  const curriculumAreaSlug = subject.getCurriculumAreaSlug();
  const backgroundColor = `var(--background-domain-${curriculumAreaSlug})`;

  return (
    <PracticeView
      activeSubject={subject}
      toolbar={toolbar}
      titleBar={
        <TitleBar
          backgroundColor={backgroundColor}
          title={guideLevel ? guideLevel.getName() : subject.getName()}
          imgHref={subject.getLogoLocation().href}
          backButton={
            <TitleBar.BackButton as={Link} label='Back To Guide' to={`/${subjectSlug}`} />
          }
        />
      }
      backButtonProps={{
        as: Link,
        label: 'Back To Guide',
        to: `/${subjectSlug}`
      }}
      questionsListProps={{
        header: <QuestionsListHeader heading='No questions found' />,
        noQuestionSetsMessage: 'You have filtered out all questions.'
      }}
    />
  );
};

NoQuestionSetsFoundSubjectPracticeView.propTypes = {
  subject: PropTypes.any,
  guideLevel: PropTypes.any,
  subjectSlug: PropTypes.string,
  toolbar: PropTypes.node
};

export default NoQuestionSetsFoundSubjectPracticeView;
