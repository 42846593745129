import React, {useState, useCallback} from 'react';
import {withRouter, Link, WithRouterProps} from 'react-router';
import {callAction} from 'client/framework';
import questionEditorActions from 'client/EditPage/V2/QuestionEditor/QuestionEditor.actions';
import questionEditorStore from 'client/EditPage/V2/QuestionEditor/QuestionEditor.store';
import {
  WithTooltip,
  Dropdown,
  DropdownItem,
  Button,
  Icon,
  copyToClipboard,
  addToast,
  Text,
  Toggle,
  IconButton
} from '@albert-io/atomic';

import {QUESTIONSET_STATUSES} from 'client/constants';
import sessionStore from 'client/Session/SessionStore';

import {useIsFeatureFlagEnabled} from 'client/components/FeatureFlag/FeatureFlag.react';

import {DeleteQuestionModal} from './DeleteQuestionModal';
import {deleteQuestionSet} from './QuestionSetActions.utils';
import ReassignQuestionSetModal from './ReassignQuestionSetModal/ReassignQuestionSetModal.react';

const nLevelMap = {
  1: 'theme',
  2: 'topic',
  3: 'subtopic'
};

interface Props extends WithRouterProps {
  isPreviewMode: boolean;
  togglePreviewMode: () => void;
  disablePreviewMode: boolean;
  router: any;
}

const QuestionSetActions = ({
  isPreviewMode,
  togglePreviewMode,
  disablePreviewMode,
  params,
  router
}: Props) => {
  const {isLoading: isFeatureFlagLoading, isFeatureEnabled: isQuestionEditorV2Enabled} =
    useIsFeatureFlagEnabled('question_editor_v2');
  const [isReassignModalActive, setReassignModalActive] = useState(false);
  const [showDeleteQuestionModal, setShowDeleteQuestionModal] = useState(false);

  const toggleReassignModal = useCallback(() => {
    setReassignModalActive((prev) => !prev);
  }, []);

  const toggleShowDeleteQuestionModal = useCallback(() => {
    setShowDeleteQuestionModal((prev) => !prev);
  }, []);

  const reassignQuestionSet = useCallback((newAuthorId) => {
    callAction(questionEditorActions.REASSIGN_QUESTION_SET, newAuthorId);
  }, []);

  const getSuperActions = useCallback(() => {
    if (!sessionStore.isSuper() || !params.questionId || isFeatureFlagLoading) {
      return null;
    }
    const questionSet = questionEditorStore.getQuestionSet();
    const isPublished = questionSet.getStatus() === QUESTIONSET_STATUSES.PUBLISHED;
    let isDuplicateAligned = false;
    const sortedGuides = questionSet.getAuthoringGuideLevels().sort((guideA, guideB) => {
      if (guideA.getNlevel() === guideB.getNlevel()) {
        isDuplicateAligned = true;
      }
      return guideA.getNlevel() - guideB.getNlevel();
    });
    let lowestGuideLevel;
    let guideSlug;
    let guideNLevel;
    let subjectSlug;
    if (!sortedGuides.isEmpty()) {
      lowestGuideLevel = sortedGuides.last();
      guideSlug = lowestGuideLevel.getUrlSlug();
      guideNLevel = lowestGuideLevel.getNlevel();
      subjectSlug = questionSet.getAuthoringSubject()?.getUrlSlug();
    }
    const isAlignmentEditorAvailable =
      !isDuplicateAligned && subjectSlug && guideSlug && isPublished;
    let alignmentTooltipText;
    if (isDuplicateAligned) {
      alignmentTooltipText = 'This question has duplicate alignment.';
    } else if (!guideSlug && !subjectSlug) {
      alignmentTooltipText = 'Must be aligned before reordering is available';
    } else if (!isPublished) {
      alignmentTooltipText = 'Must be published before reordering is available';
    }
    const isSavePending = questionEditorStore.isSavePending();

    if (isQuestionEditorV2Enabled) {
      return (
        <>
          <DropdownItem
            className='u-display_flex u-justify-content_space-between'
            as='button'
            onClick={toggleReassignModal}
            disabled={isSavePending}
          >
            <Icon icon='repeat' className='u-mar-r_1' />
            Reassign
          </DropdownItem>
          <DropdownItem
            preventDefaultClickHandler={false}
            as={Link}
            subtext={alignmentTooltipText}
            disabled={!isAlignmentEditorAvailable}
            to={
              isAlignmentEditorAvailable && {
                pathname: `/dennis/${subjectSlug}/${guideSlug}`,
                state: {
                  from: router.getCurrentLocation().pathname
                }
              }
            }
          >
            <Icon icon={['far', 'arrow-right-arrow-left']} className='u-mar-r_1' />
            {guideNLevel
              ? `Reorder question in ${nLevelMap[guideNLevel]}`
              : 'Reorder question'}{' '}
          </DropdownItem>
        </>
      );
    }
    return (
      <>
        <DropdownItem
          className='u-display_flex u-justify-content_space-between'
          withBottomBorder
          as='button'
          onClick={toggleReassignModal}
          disabled={isSavePending}
          icon='repeat'
        >
          Reassign
        </DropdownItem>
        <DropdownItem
          withBottomBorder
          preventDefaultClickHandler={false}
          as={Link}
          subtext={alignmentTooltipText}
          disabled={!isAlignmentEditorAvailable}
          icon='exchange'
          to={
            isAlignmentEditorAvailable && {
              pathname: `/dennis/${subjectSlug}/${guideSlug}`,
              state: {
                from: router.getCurrentLocation().pathname
              }
            }
          }
        >
          {guideNLevel ? `Reorder question in ${nLevelMap[guideNLevel]}` : 'Reorder question'}{' '}
        </DropdownItem>
      </>
    );
  }, [
    params.questionId,
    router,
    toggleReassignModal,
    isFeatureFlagLoading,
    isQuestionEditorV2Enabled
  ]);

  const copySetId = useCallback(() => {
    copyToClipboard(questionEditorStore.getQuestionSet().getId(), () => {
      addToast({
        color: 'positive',
        title: 'Copied!',
        message: 'The question set identifier has been copied to your clipboard.'
      });
    });
  }, []);

  if (!questionEditorStore.getQuestionSet()) {
    return null;
  }
  const question = questionEditorStore.getQuestion();
  const questionSet = questionEditorStore.getQuestionSet();

  const isPreviewDisabled =
    disablePreviewMode ||
    questionEditorStore.hasErrors() ||
    (params.questionId ? !questionEditorStore.getQuestionQuery().isResourceReady() : true);

  const isSavePending = questionEditorStore.isSavePending();

  if (isQuestionEditorV2Enabled) {
    return (
      <>
        <Dropdown trigger={<IconButton icon='ellipsis-v' aria-hidden />} position='bottom-end'>
          <DropdownItem
            onClick={copySetId}
            disabled={isSavePending}
            subtext={isSavePending && 'You must first save your question to copy its identifier'}
            as='button'
          >
            <Icon icon={['far', 'copy']} className='u-mar-r_1' />
            Copy question set identifier
          </DropdownItem>
          {getSuperActions()}
          <div className='u-mar_1' />
          <DropdownItem onClick={toggleShowDeleteQuestionModal} color='red' as='button'>
            <Icon icon={['far', 'trash']} className='u-mar-r_1' />
            Delete
          </DropdownItem>
        </Dropdown>
        {showDeleteQuestionModal && (
          <DeleteQuestionModal
            onClose={toggleShowDeleteQuestionModal}
            deleteQuestionSet={() => deleteQuestionSet(questionSet, question)}
          />
        )}
        {isReassignModalActive && (
          <ReassignQuestionSetModal
            handleClose={toggleReassignModal}
            handleSubmit={reassignQuestionSet}
            subjectId={questionEditorStore.getQuestionSet().getSubjectId()}
            currentAuthorId={questionEditorStore.getQuestionSet().getMeta().getAuthorId()}
          />
        )}
      </>
    );
  }
  return (
    <div className='edit-page__toolbar'>
      <div className='u-mar-tb_auto'>
        <WithTooltip
          enabled={isPreviewDisabled || questionEditorStore.hasErrors()}
          content='All required fields must be filled out correctly before you can preview the
              question.'
        >
          <label
            className='guide-level-row__toggle-checkbox-toggle u-mar-l_1 u-mar-r_3'
            htmlFor={`preview-toggle-${questionSet.getId()}`}
            data-id={questionSet.getId()}
          >
            <Text>Preview</Text>
            <Toggle
              size='s'
              className='u-mar-l_2'
              data-id={questionSet.getId()}
              id={`preview-toggle-${questionSet.getId()}`}
              onChange={togglePreviewMode}
              checked={isPreviewMode}
            />
          </label>
        </WithTooltip>
      </div>
      <div className='edit-page-controls-buttons'>
        <Dropdown
          trigger={
            <Button>
              Actions <Icon className='u-mar-l_1' icon='caret-down' />
            </Button>
          }
        >
          <DropdownItem
            withBottomBorder
            className='u-display_flex u-justify-content_space-between'
            onClick={copySetId}
            as='button'
            disabled={isSavePending}
            subtext={isSavePending && 'You must first save your question to copy its identifier'}
            icon='link'
          >
            Copy question set identifier
          </DropdownItem>
          {getSuperActions()}
          <DropdownItem
            withBottomBorder
            className='u-display_flex u-justify-content_space-between'
            onClick={toggleShowDeleteQuestionModal}
            icon='trash'
            iconColor='negative'
            color='red'
            as='button'
          >
            Delete
          </DropdownItem>
        </Dropdown>
      </div>
      {showDeleteQuestionModal && (
        <DeleteQuestionModal
          onClose={toggleShowDeleteQuestionModal}
          deleteQuestionSet={() => deleteQuestionSet(questionSet, question)}
        />
      )}
      {isReassignModalActive && (
        <ReassignQuestionSetModal
          handleClose={toggleReassignModal}
          handleSubmit={reassignQuestionSet}
          subjectId={questionEditorStore.getQuestionSet().getSubjectId()}
          currentAuthorId={questionEditorStore.getQuestionSet().getMeta().getAuthorId()}
        />
      )}
    </div>
  );
};

export default withRouter(QuestionSetActions);
