import React, {ChangeEvent, useCallback, useEffect} from 'react';
import TextInput from 'client/generic/Forms/TextInput/TextInput';
import TextArea from 'client/generic/Forms/TextArea/TextArea.react';
import Checkbox from 'client/generic/Forms/Checkbox/Checkbox.react';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {AuthoringSupplement as AuthoringSupplementModelV1} from 'resources/GeneratedModels/AuthoringSupplement/AuthoringSupplementModel.v1';
import './sortable-table-editor.scss';
import {useSupplementEditorV2Store} from 'client/Supplements/SupplementEditor/SupplementEditorV2Store';
import {useShallow} from 'zustand/react/shallow';
import {PRIMARY_CONTENT_LANGUAGE} from 'client/EditPage/V2/QuestionEditorV2Store.types';
import {TranslationCommentStatusEditor} from 'client/Supplements/SupplementEditor/TranslationCommentStatusEditor';
import {callAction} from 'client/framework';
import supplementEditorActions from 'client/Supplements/SupplementEditor/SupplementEditor.actions';

interface Props {
  supplement: AuthoringSupplementModelV1;
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onCheckChange: (event: ChangeEvent<HTMLInputElement>) => void;
  errors: any;
}

const SortableTableEditor = ({supplement, onChange, onCheckChange, errors}: Props) => {
  const {currentLanguage, updateTranslatedField, translatedName, translatedText} =
    useSupplementEditorV2Store(
      useShallow((state) => ({
        currentLanguage: state.currentLanguage,
        updateTranslatedField: state.updateTranslatedField,
        translatedName:
          state
            .currentTranslatedSupplement()
            ?.translated_fields?.find((field) => field.field === 'name')?.text || '',
        translatedText:
          state
            .currentTranslatedSupplement()
            ?.translated_fields?.find((field) => field.field === 'content.text')?.text || ''
      }))
    );

  const hasTotal = supplement.getHasTotal();

  useEffect(() => {
    // the point of this is to set the content-specific values for this type, when the type changes,
    // if they do not already exist.
    const newHasTotalValue = supplement.getContent().get('hasTotal', false);
    if (supplement.getHasTotal() !== newHasTotalValue) {
      callAction(supplementEditorActions.MODIFY_ACTIVE_SUPPLEMENT, {
        setter: 'setHasTotal',
        value: newHasTotalValue
      });
    }
  }, [supplement]);

  const nameValue =
    currentLanguage === PRIMARY_CONTENT_LANGUAGE ? supplement.getName() : translatedName;
  const textValue =
    currentLanguage === PRIMARY_CONTENT_LANGUAGE ? supplement.getText() : translatedText;

  const handleNameChange = useCallback(
    (e) => {
      if (currentLanguage === PRIMARY_CONTENT_LANGUAGE) {
        onChange(e);
      } else {
        updateTranslatedField(currentLanguage, 'name', 'text', e.target.value);
      }
    },
    [currentLanguage, onChange, updateTranslatedField]
  );

  const handleTextChange = useCallback(
    (e) => {
      if (currentLanguage === PRIMARY_CONTENT_LANGUAGE) {
        onChange(e);
      } else {
        updateTranslatedField(currentLanguage, 'content.text', 'text', e.target.value);
      }
    },
    [currentLanguage, onChange, updateTranslatedField]
  );

  return (
    <div className='sortable-table-editor'>
      <div className='sortable-table-editor__fieldset'>
        <TextInput
          className='sortable-table-editor__input'
          data-setter-method='setName'
          error={currentLanguage === PRIMARY_CONTENT_LANGUAGE && Boolean(errors.get('name'))}
          errorMessage={currentLanguage === PRIMARY_CONTENT_LANGUAGE && errors.get('name', null)}
          label='Name'
          name='name'
          onChange={handleNameChange}
          value={nameValue}
        />
        <TranslationCommentStatusEditor fieldName='name' />
        <TextArea
          className='sortable-table-editor__input sortable-table-editor__input--textarea'
          data-setter-method='setText'
          error={currentLanguage === PRIMARY_CONTENT_LANGUAGE && Boolean(errors.get('text'))}
          errorMessage={currentLanguage === PRIMARY_CONTENT_LANGUAGE && errors.get('text', null)}
          label='Text'
          name='text'
          onChange={handleTextChange}
          value={textValue}
        />
        <TranslationCommentStatusEditor fieldName='content.text' />
        <Checkbox
          disabled={currentLanguage !== PRIMARY_CONTENT_LANGUAGE}
          className='sortable-table-editor__input sortable-table-editor__input--checkbox'
          checked={hasTotal}
          data-setter-method='setHasTotal'
          label='Include a TOTAL row'
          name='hasTotal'
          onChange={onCheckChange}
        />
      </div>
      <div className='sortable-table-editor-preview__wrapper'>
        <div className='a-form-input__label'>Preview</div>
        <MarkdownRendererV2 className='sortable-table-editor__markdown-renderer' text={textValue} />
      </div>
    </div>
  );
};

export default SortableTableEditor;
