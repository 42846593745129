import React from 'react';
import {Helmet} from 'react-helmet';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';

import {title} from 'lib/head';

import CenteredPage from 'client/Layout/CenteredPage';
import LogInForm from 'client/LogIn/LogInForm.react';
import {redirect} from 'client/User/UserRedirectUtil';

CenteredPage.propTypes = {
  children: PropTypes.node
};

const LogInPage = () => {
  function redirectAfterAuthentication() {
    redirect();
  }
  return (
    <>
      <Helmet>{title('Log In | Albert')}</Helmet>
      <CenteredPage>
        <LogInForm onAuthentication={redirectAfterAuthentication} />
      </CenteredPage>
    </>
  );
};

export default withRouter(LogInPage);
