/**
 * # FieldsetGroup
 *
 * Component for rendering a fieldset group with an optional legend.
 *
 * ## Props extends React.HTMLAttributes<HTMLFieldSetElement>
 *
 * `className?: string`: CSS classnames
 * `children?: React.ReactNode`
 *
 *
 * ## Example
 *
 * ```tsx
 * import React from 'react';
 * import Fieldset from './Fieldset.react';
 *
 * const MyComponent = () => {
 *   return (
 *     <FieldsetGroup>
 *       <Fieldset legend="My Fieldset">
 *         <input type="text" />
 *         <button>Submit</button>
 *       </Fieldset>
 *     </FieldsetGroup>
 *   );
 * };
 *
 * export default MyComponent;
 * ```
 */

import React from 'react';
import classnames from 'classnames';

import {FieldsetProps} from '../Fieldset/Fieldset.react';
import './fieldset-group.scss';

interface FieldsetGroupProps extends PropsWithClassNameOptional {
  children: React.ReactElement<FieldsetProps>[] | React.ReactElement<FieldsetProps>;
}

const FieldsetGroup = ({className, children}: FieldsetGroupProps) => (
  <div role='group' className={classnames('m-fieldset-group', className)}>
    {children}
  </div>
);

export default FieldsetGroup;
