import {resource} from '@albert-io/json-api-framework/request/builder';
import sessionStore from 'client/Session/SessionStore';
import {set} from 'lodash';
import {applyFilters} from 'components/PracticeView/PracticeViewToolbar/Filters/filters.utils';
import {applySort} from 'components/PracticeView/PracticeViewToolbar/Sort/sort.utils';
import masqueradeStore from 'generic/Masquerade/Masquerade.store';

export function getFolderQuery(folderId) {
  return resource('template_v1')
    .mandarkEndpoint(['templates_v1', folderId])
    .include('question_sets_v1.questions_v3')
    .include('question_sets_v1.subject_v2')
    .withMeta('template_v1,subject_v2');
}

export async function getFolder(folderId) {
  return getFolderQuery(folderId).getResourcePromise();
}

export function getFolderQuestionSetsQuery(folderId, pageSize = 20) {
  const metaContext = {
    meta: {
      context: {
        template: {
          id: folderId
        },
        user: {
          personalization_settings: true
        }
      }
    }
  };

  const userId = masqueradeStore.getUserIdByMasqueradeState();
  if (sessionStore.hasTeacherAccess()) {
    set(metaContext, 'meta.context.user.role', 'teacher');
  }

  if (userId) {
    set(metaContext, 'meta.context.user.id', userId);
  } else {
    set(metaContext, 'meta.context.no_user', true);
  }

  let query = resource('question_sets_v1')
    .mandarkEndpoint(['templates_v1', folderId, 'question_sets_v1'])
    .pageSize(pageSize)
    .include('guide_levels_v2.guide_v1')
    .fields({
      guide_v1: 'guide_type'
    })
    .include('subject_v2')
    .include('questions_v3')
    .include('questions_v3.question_set_v1.tags_v1')
    .include('questions_v3.question_set_v1.subject_v2')
    .include('questions_v3.standards_v1')
    .include('questions_v3.standards_v1.standard_set_v1')
    .withMeta('question_v3,subject_v2,question_set_v1,standard_v1')
    .customQuery(metaContext, Object.keys(metaContext).length !== 0);

  query = applyFilters(applySort(query, 'meta.assignment_position'));
  return query;
}

export async function getFolderQuestionSets(folderId) {
  const questionSets = await getFolderQuestionSetsQuery(folderId).getResourcePromise();
  return questionSets;
}
