import {createActions} from 'client/framework';

const activeQuestionActionsList = [
  'ACTIVE_QUESTION_CLEAR_GUESS',
  'ACTIVE_QUESTION_LOAD_GUESS',
  'ACTIVE_QUESTION_RESET_STORE',
  'ACTIVE_QUESTION_VIEW_QUESTION',
  'ACTIVE_QUESTION_SET_QUESTION_SET',
  'ACTIVE_QUESTION_SET_START_TIME',
  'ACTIVE_QUESTION_SUBMIT_ANSWER',
  'ACTIVE_QUESTION_SET_SHOW_EXPLANATION',
  'USER_EARNED_POINTS',
  'ACTIVE_QUESTION_SET_SHOULD_SHOW_EXPLANATION',
  'ACTIVE_QUESTION_TOGGLE_SHOW_EXPLANATION',
  'FETCH_GUESS_FOR_QUESTION_IN_ASSIGNMENT',
  'LOAD_QUESTION'
];

export const activeQuestionActions = createActions(null, activeQuestionActionsList);
export default activeQuestionActions;
