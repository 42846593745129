/**
 * # IconInput
 *
 * A component that combines an input field with optional left and right icons.
 *
 * ## Props
 *
 * - `className?: string`: Additional CSS class name for the component.
 * - `error?: boolean`: Indicates whether the input has an error.
 * - `success?: boolean`: Indicates whether the input is successful.
 * - `fillWrapper?: boolean`: Indicates whether the wrapper should fill the available space.
 * - `iconLeft?: IconProp`: The icon to display on the left side of the input.
 * - `iconRight?: IconProp`: The icon to display on the right side of the input.
 * - `onBlur?: (e?: React.FocusEvent<HTMLElement>) => void`: Event handler for the blur event.
 * - `onFocus?: (e?: React.FocusEvent<HTMLElement>) => void`: Event handler for the focus event.
 * - `onMouseEnter?: (e?: React.MouseEvent<HTMLElement>) => void`: Event handler for the mouse enter event.
 * - `onMouseLeave?: (e?: React.MouseEvent<HTMLElement>) => void`: Event handler for the mouse leave event.
 * - `onIconLeftClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void`: Event handler for the left icon click event.
 * - `onIconRightClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void`: Event handler for the right icon click event.
 * - `value?: any`: The value of the input field.
 * - `onIconButtonClick?: () => void`: Event handler for the icon button click event. Required in tandem with `iconButtonLabel`.
 * - `iconButtonLabel: string`: The label for the icon button Required in tandem with `onIconButtonClick`.
 *
 * ## Usage
 *
 * ```tsx
 * import IconInput from './IconInput.react';
 *
 * const MyComponent = () => {
 *   return (
 *     <IconInput
 *       className="my-icon-input"
 *       error={false}
 *       success={true}
 *       fillWrapper={true}
 *       iconLeft="left-icon"
 *       iconRight="right-icon"
 *       onBlur={() => console.log('Blur event')}
 *       onFocus={() => console.log('Focus event')}
 *       onMouseEnter={() => console.log('Mouse enter event')}
 *       onMouseLeave={() => console.log('Mouse leave event')}
 *       onIconLeftClick={() => console.log('Left icon click event')}
 *       onIconRightClick={() => console.log('Right icon click event')}
 *       value="Hello World"
 *       onIconButtonClick={() => console.log('Icon button click event')}
 *       iconButtonLabel="Button Label"
 *     />
 *   );
 * };
 *
 * export default MyComponent;
 * ```
 */
import React from 'react';
import classnames from 'classnames';

import Input from '../../atoms/Input/Input.react';
import WithHoverAndFocus from '../../helpers/WithHoverAndFocus/WithHoverAndFocus.react';
import Icon, {IconProp} from '../../atoms/Icon/Icon.react';
import IconButton from '../IconButton/IconButton.react';
import './icon-input.scss';

type ButtonClickProps =
  | {
      onIconButtonClick?: () => void;
      iconButtonLabel: string;
    }
  | {
      onIconButtonClick?: never;
      iconButtonLabel?: never;
    };

type IconInputProps = {
  error?: boolean;
  success?: boolean;
  fillWrapper?: boolean;
  iconLeft?: IconProp;
  iconRight?: IconProp;
  onBlur?: (e?: React.FocusEvent<HTMLElement>) => void;
  onFocus?: (e?: React.FocusEvent<HTMLElement>) => void;
  onMouseEnter?: (e?: React.MouseEvent<HTMLElement>) => void;
  onMouseLeave?: (e?: React.MouseEvent<HTMLElement>) => void;
  onIconLeftClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  onIconRightClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  value?: any;
} & ButtonClickProps &
  PropsWithClassNameOptional;

export type Props<C extends React.ElementType> = PolymorphicComponentProps<C, IconInputProps>;

const IconInput = <C extends React.ElementType = typeof Input>({
  as,
  className,
  error,
  success,
  fillWrapper,
  iconLeft,
  iconRight,
  onBlur,
  onFocus,
  onMouseEnter,
  onMouseLeave,
  onIconLeftClick,
  onIconRightClick,
  iconButtonLabel,
  value,
  ...rest
}: Props<C>) => {
  const LeftIconComponent = onIconLeftClick ? IconButton : Icon;
  const RightIconComponent = onIconRightClick ? IconButton : Icon;

  const WrappedComponent = as ?? Input;

  return (
    <WithHoverAndFocus
      customOnBlur={onBlur}
      customOnFocus={onFocus}
      customOnMouseEnter={onMouseEnter}
      customOnMouseLeave={onMouseLeave}
    >
      {({hasFocus, hasHover: _, ...handlers}) => (
        <div
          className={classnames('m-icon-input__wrapper', {
            'm-icon-input__wrapper--fill': fillWrapper
          })}
        >
          <Input
            as={WrappedComponent}
            className={classnames('m-icon-input', className, {
              'm-icon-input--with-icon-left': iconLeft,
              'm-icon-input--with-icon-right': iconRight
            })}
            error={error}
            success={success}
            value={value}
            {...handlers}
            {...rest}
          />
          {iconLeft && (
            <LeftIconComponent
              className={classnames('m-icon-input__icon m-icon-input__icon--left', {
                'm-icon-input__icon--clickable': onIconLeftClick
              })}
              color={
                (hasFocus && 'accent') ||
                (error && 'destructive') ||
                (success && 'success') ||
                'inherit'
              }
              label={iconButtonLabel}
              icon={iconLeft}
              tabIndex={onIconLeftClick ? 0 : -1}
              onClick={onIconLeftClick}
              aria-hidden={false}
            />
          )}
          {iconRight && (
            <RightIconComponent
              className={classnames('m-icon-input__icon m-icon-input__icon--right', {
                'm-icon-input__icon--clickable': onIconRightClick
              })}
              color={
                (hasFocus && 'accent') ||
                (error && 'destructive') ||
                (success && 'success') ||
                'inherit'
              }
              label={iconButtonLabel}
              icon={iconRight}
              onClick={onIconRightClick}
              tabIndex={onIconRightClick ? 0 : -1}
              role={onIconRightClick ? 'button' : undefined}
              aria-hidden={false}
              {...(onIconRightClick && {size: 's' as any})}
            />
          )}
        </div>
      )}
    </WithHoverAndFocus>
  );
};

IconInput.defaultProps = {
  as: Input
};

export default IconInput;
