import Immutable, {fromJS, Map} from 'immutable';
import moment from 'moment';

export function getNextOccurenceOfDate(month: number, date: number): Date {
  const now = moment();
  const thisYear = new Date(now.year(), month, date);
  const nextYear = new Date(now.year() + 1, month, date);
  return moment(thisYear).isAfter(now) ? thisYear : nextYear;
}

export function getLastOccurenceOfDate(month: number, date: number): Date {
  const now = moment();
  const thisYear = new Date(now.year(), month, date);
  const lastYear = new Date(now.year() - 1, month, date);
  return now.isBefore(thisYear) ? lastYear : thisYear;
}

export function getThisSchoolYear(): Immutable.Map<string, moment.Moment> {
  return Map({
    start: moment(getLastOccurenceOfDate(6, 1)), // July 1
    end: moment(getNextOccurenceOfDate(5, 30)) // June 30
  });
}

export function getLastSchoolYear(): Immutable.Map<string, moment.Moment> {
  return Map({
    start: moment(getLastOccurenceOfDate(6, 1)).subtract(1, 'year'),
    end: moment(getLastOccurenceOfDate(5, 30))
  });
}

export const RANGES = {
  ALL_TIME: 'All-time',
  THIS_WEEK: 'This week',
  THIS_MONTH: 'This month',
  LAST_WEEK: 'Last week',
  LAST_MONTH: 'Last month',
  THIS_SCHOOL_YEAR: 'This school year',
  LAST_SCHOOL_YEAR: 'Last school year',
  CUSTOM: 'Custom'
} as const;

export type Ranges = typeof RANGES;

type ValueOf<T> = T[keyof T];

export type DateRange = ValueOf<Ranges>;

export interface DateRangeOptionProps {
  value: DateRange;
  end: string;
  id: DateRange;
  start: string;
}

export interface DateRangeOption extends Immutable.Map<string, string | DateRange> {
  get<K extends keyof DateRangeOptionProps>(key: K): DateRangeOptionProps[K];
}

export const dateRangeOptionsWithoutAllTime: Immutable.List<DateRangeOption> = fromJS([
  {
    value: RANGES.THIS_WEEK,
    end: moment().endOf('week').format('YYYY-MM-DD'),
    id: RANGES.THIS_WEEK,
    start: moment().startOf('week').format('YYYY-MM-DD')
  },
  {
    value: RANGES.THIS_MONTH,
    end: moment().endOf('month').format('YYYY-MM-DD'),
    id: RANGES.THIS_MONTH,
    start: moment().startOf('month').format('YYYY-MM-DD')
  },
  {
    value: RANGES.LAST_WEEK,
    end: moment()
      .subtract(1, 'week')
      .startOf('week')
      .add(6, 'days')
      .endOf('day')
      .format('YYYY-MM-DD'),
    id: RANGES.LAST_WEEK,
    start: moment().subtract(1, 'week').startOf('week').format('YYYY-MM-DD')
  },
  {
    value: RANGES.LAST_MONTH,
    end: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
    id: RANGES.LAST_MONTH,
    start: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD')
  },
  {
    value: RANGES.THIS_SCHOOL_YEAR,
    end: getThisSchoolYear().get('end').format('YYYY-MM-DD'),
    id: RANGES.THIS_SCHOOL_YEAR,
    start: getThisSchoolYear().get('start').format('YYYY-MM-DD')
  },
  {
    value: RANGES.LAST_SCHOOL_YEAR,
    end: getLastSchoolYear().get('end').format('YYYY-MM-DD'),
    id: RANGES.LAST_SCHOOL_YEAR,
    start: getLastSchoolYear().get('start').format('YYYY-MM-DD')
  }
]);

const allTimeDateRangeOption: DateRangeOption = fromJS({
  value: RANGES.ALL_TIME,
  end: '',
  id: RANGES.ALL_TIME,
  start: ''
});

const customRangeOption: DateRangeOption = fromJS({
  value: RANGES.CUSTOM,
  end: '',
  id: RANGES.CUSTOM,
  start: ''
});

export const dateRangeOptions = dateRangeOptionsWithoutAllTime.unshift(allTimeDateRangeOption);
export const dateRangeOptionsWithCustom = dateRangeOptions.push(customRangeOption);
