import * as React from 'react';
import {Map} from 'immutable';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {STATUSES} from 'client/Dennis/Content/Queue/shared';
import {
  SEARCH_PARAMETERS,
  getURLSearchParameter,
  setURLSearchParameter
} from '../URLSearchParameters';

import './status-selector.scss';

export class StatusSelector extends React.Component {
  render() {
    return (
      <div className='queue-search-panel__facet status-selector'>
        <ul>
          {STATUSES.map((status) => (
            <StatusListItem key={status.get('id')} status={status} />
          )).toList()}
        </ul>
      </div>
    );
  }
}

class StatusListItem extends React.Component {
  static propTypes = {
    status: PropTypes.instanceOf(Map)
  };

  handleClick = () => {
    setURLSearchParameter(SEARCH_PARAMETERS.status, this.props.status.get('id'));
  };

  render() {
    const type = this.props.status.get('id');

    const param = getURLSearchParameter(SEARCH_PARAMETERS.status);
    const isActive = (!param && type === '') || param === type;

    return (
      <li
        onClick={this.handleClick}
        className={classnames('status-selector__item', {
          'status-selector__item--active': isActive
        })}
      >
        {this.props.status.get('name')}
      </li>
    );
  }
}
