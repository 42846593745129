/**
 * # StatusText
 *
 * A component that displays status text with customizable styles.
 *
 * ## Props
 *
 * - `children` (optional): The content to be displayed inside the component.
 * - `className` (optional): Additional CSS class name for the component.
 * - `color` (optional): The color of the text. Possible values are `'black'`, `'blue'`, `'bright-orange'`, `'green'`, `'inherit'`, `'red'`, and `'white'`. Default is `'inherit'`.
 * - `emphasis` (optional): The emphasis level of the text. Possible values are `'high'`, `'medium'`, `'low'`, `'lowest'`, and `'disabled'`.
 * - `italic` (optional): Specifies whether the text should be italic. Default is `false`.
 * - `monospace` (optional): Specifies whether the text should be displayed in a monospace font. Default is `false`.
 * - `withMargin` (optional): Specifies whether the text should have margin. Default is `false`.
 * - `id` (optional): The HTML id attribute for the component.
 *
 * ## Usage
 *
 * ```tsx
 * import StatusText from './StatusText.react';
 *
 * const Example = () => {
 *   return (
 *     <StatusText color="blue" size="l" bold>
 *       This is a status text example.
 *     </StatusText>
 *   );
 * };
 * ```
 */

import React from 'react';
import classnames from 'classnames';

import Text, {Props} from '../Text/Text.react';
import './status-text.scss';

interface StatusTextProps
  extends Omit<Props, 'children' | 'bold' | 'paragraph' | 'size'>,
    PropsWithChildrenRequired,
    PropsWithClassNameOptional {}

export const StatusText = ({children, className, ...rest}: StatusTextProps) => (
  <Text
    {...rest}
    bold={false}
    className={classnames(className, 'a-status-text')}
    paragraph={false}
    size='regular'
  >
    {children}
  </Text>
);

export default StatusText;
