import React from 'react';
import {reduce} from 'lodash';
import PropTypes from 'prop-types';
import './assets/user-avatar.scss';

/**
 * We don't actually have styles that match these modifiers... but keeping
 * the color logic intact for now.
 * @param {string} seed
 */
function getColor(seed) {
  const colors = ['color1', 'color2', 'color3', 'color4', 'color5'];
  const arrayIndex =
    reduce(
      seed,
      (red, el) => {
        return red + el.charCodeAt(0);
      },
      0
    ) % colors.length;
  return colors[arrayIndex];
}

export default class UserAvatar extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    username: PropTypes.string,
    fullName: PropTypes.string,
    inactive: PropTypes.bool
  };

  static defaultProps = {
    inactive: false
  };

  shouldComponentUpdate(nextProps) {
    if (this.props.username !== nextProps.username) {
      return true;
    }
    return false;
  }

  generateAvatarText() {
    if (this.props.fullName) {
      return this.props.fullName
        .split(' ')
        .reduce((abbreviation, name) => abbreviation.concat(name.charAt(0)), '');
    }
    return this.props.username.charAt(0);
  }

  render() {
    const className = this.props.className ? this.props.className : '';
    const spanClass = this.props.inactive
      ? 'user-avatar__container user-avatar__container--gray'
      : `user-avatar__container user-avatar__container--${getColor(this.props.username)}`;
    return <span className={`${spanClass} ${className}`}>{this.generateAvatarText()}</span>;
  }
}
