/* eslint-disable import/no-cycle */
import {List, fromJS, Map, Set} from 'immutable';

import {APIMethods, getEmptyPayload} from '../../resource';
import {getMandarkAPI} from '../MandarkAPI';
import {buildPatch, buildPost} from '../Mandark.utils';

import {AssignmentsInterface} from './Assignments.interface';
import {AssignmentStatsInterface} from './AssignmentStats.interface';
import {AssignmentQuestionStatsInterface} from './AssignmentQuestionStats.interface';
import {AssignmentStudentStatsInterface} from './AssignmentStudentStats.interface';

export const resources = {
  /* eslint-disable camelcase */
  ASSIGNMENTS: {
    endpoint: '/assignments_v2',
    id: 'ASSIGNMENTS',
    model: new Map(),
    versions: fromJS({
      teacher: 'teacher_v1',
      classroom: 'classroom_v1',
      student: 'student_v1',
      question_set: 'question_set_v1',
      relationshipNames: {
        teacher: 'teacher_v1',
        classroom: 'classrooms_v1',
        student: 'students_v1',
        question_set: 'question_sets_v1'
      }
    }),
    authRequired: new Set([APIMethods.POST, APIMethods.GET])
  },
  DELETE_ASSIGNMENT: {
    endpoint: '/assignments_v2/:assignmentId',
    id: 'DELETE_ASSIGNMENT',
    authRequired: new Set([APIMethods.DELETE]),
    model: new Map()
  },
  MODIFY_ASSIGNMENT: {
    endpoint: '/assignments_v2/:assignmentId',
    id: 'MODIFY_ASSIGNMENT',
    authRequired: new Set([APIMethods.PATCH]),
    model: new Map(),
    modelInterface: AssignmentsInterface
  },
  ASSIGNMENT_STATISTICS: {
    endpoint: '/stats/assignments_v1/:assignmentId',
    id: 'ASSIGNMENT_STATISTICS',
    model: new Map(),
    modelInterface: AssignmentStatsInterface,
    authRequired: new Set([APIMethods.GET]),
    maxAge: 60 * 60 * 1000
  },
  ASSIGNMENT_QUESTION_STATISTICS: {
    endpoint: '/stats/assignments_v1/:assignmentId/question_sets_v1',
    id: 'ASSIGNMENT_QUESTION_STATISTICS',
    model: new Map(),
    modelInterface: AssignmentQuestionStatsInterface,
    authRequired: new Set([APIMethods.GET]),
    maxAge: 60 * 60 * 1000
  },
  ASSIGNMENT_STUDENT_STATISTICS: {
    endpoint: '/stats/assignments_v1/:assignmentId/students_v1',
    id: 'ASSIGNMENT_STUDENT_STATISTICS',
    model: new Map(),
    modelInterface: AssignmentStudentStatsInterface,
    authRequired: new Set([APIMethods.GET]),
    maxAge: 60 * 60 * 1000
  },
  MODIFY_QUESTION_SETS_IN_ASSIGNMENT: {
    endpoint: '/assignments_v2/:assignmentId/relationships/question_sets_v1',
    id: 'MODIFY_QUESTION_SETS_IN_ASSIGNMENT',
    model: new Map(),
    authRequired: new Set([APIMethods.POST])
  },
  REMOVE_QUESTION_SETS_IN_ASSIGNMENT: {
    endpoint: '/assignments_v2/:assignmentId/relationships/question_sets_v1',
    id: 'REMOVE_QUESTION_SETS_IN_ASSIGNMENT',
    model: new Map(),
    authRequired: new Set([APIMethods.DELETE])
  },
  CLASSROOM_STUDENT_ASSIGNMENT_GUESS_INSIGHTS: {
    endpoint:
      '/stats/assignments_v1/:assignmentId/guesses_v1?classroom[id]=:classroomId&student[id]=:studentId&assignment[id]=:assignmentQueryParam',
    id: 'CLASSROOM_STUDENT_ASSIGNMENT_GUESS_INSIGHTS',
    model: new Map(),
    modelInterface: null,
    authRequired: new Set([APIMethods.GET]),
    maxAge: 60 * 60 * 1000
  }
  /* eslint-enable camelcase */
};

export function getAssignmentStatistics({assignmentId, classroomId}) {
  const query = {
    classroom: {
      id: classroomId
    }
  };

  return getMandarkAPI().getResource({
    id: resources.ASSIGNMENT_STATISTICS.id,
    params: {
      assignmentId
    },
    query,
    method: APIMethods.GET,
    forceUpdate: true
  });
}

export function getAssignmentQuestionStatistics({assignmentId, classroomId}) {
  const query = {
    classroom: {
      id: classroomId
    }
  };

  return getMandarkAPI().getResource({
    id: resources.ASSIGNMENT_QUESTION_STATISTICS.id,
    params: {
      assignmentId
    },
    query,
    method: APIMethods.GET,
    forceUpdate: true
  });
}

export function getAssignmentStudentStatistics({assignmentId, classroomId}) {
  const query = {
    classroom: {
      id: classroomId
    }
  };

  return getMandarkAPI().getResource({
    id: resources.ASSIGNMENT_STUDENT_STATISTICS.id,
    params: {
      assignmentId
    },
    query,
    method: APIMethods.GET,
    forceUpdate: true
  });
}

export function createAssignment({
  name,
  correctAnswerSetting,
  message,
  dueDate,
  allowLateSubmissions,
  timeLimit,
  teacherId,
  classroomId,
  isTemplate,
  startDate,
  relationships = new List() // expecting [{type: 'type', id: 'id'}, ...]
}) {
  const payload = buildPost('assignment_v2')
    .addAttributes({
      /* eslint-disable camelcase */
      name,
      correct_answer_setting: correctAnswerSetting,
      message,
      due_date: dueDate,
      allow_late_submissions: allowLateSubmissions,
      time_limit: timeLimit,
      status: 'assigned',
      template: isTemplate,
      start_date: startDate
      /* eslint-enable camelcase */
    })
    .addOneToManyRelationship('classrooms_v1', 'classroom_v1', classroomId)
    .addManyToOneRelationship('teacher_v1', 'teacher_v1', teacherId)
    .addManyToManyRelationships(resources.ASSIGNMENTS.versions, relationships);

  return getMandarkAPI()
    .getResource({
      id: resources.ASSIGNMENTS.id,
      payload: payload.payload(),
      method: APIMethods.POST,
      forceUpdate: true
    })
    .invalidatesResource(resources.ASSIGNMENT_STATISTICS.id)
    .invalidatesResource(resources.ASSIGNMENT_QUESTION_STATISTICS.id)
    .invalidatesResource(resources.ASSIGNMENT_STUDENT_STATISTICS.id);
}

export function assignToStudents({assignmentId, studentIds}) {
  const payload = {
    data: studentIds.map((studentId) => {
      return {
        type: 'students_v1',
        id: studentId
      };
    })
  };

  return getMandarkAPI().getResource({
    id: resources.ASSIGNMENT_STUDENTS.id,
    params: {assignmentId},
    payload,
    method: APIMethods.POST,
    forceUpdate: true
  });
}

export function createAssignmentTemplate({teacherId, name}) {
  const payload = buildPost('assignment_v2')
    .addAttributes({
      /* eslint-disable camelcase */
      name,
      correct_answer_setting: 'template',
      message: 'template',
      due_date: 0,
      template: true
      /* eslint-enable camelcase */
    })
    .addManyToOneRelationship('teacher_v1', 'teacher_v1', teacherId)
    .payload();

  return getMandarkAPI().getResource({
    id: resources.ASSIGNMENTS.id,
    method: APIMethods.POST,
    payload,
    forceUpdate: true
  });
}

export function addQuestionSetsToAssignment({assignmentId, setIds, questionSetCount}) {
  const payload = {
    data: setIds.toList().map((setId, index) => {
      return {
        type: 'question_sets_v1',
        id: setId,
        meta: {
          position: questionSetCount + index
        }
      };
    })
  };

  return getMandarkAPI()
    .getResource({
      id: resources.MODIFY_QUESTION_SETS_IN_ASSIGNMENT.id,
      method: APIMethods.POST,
      params: {
        assignmentId
      },
      payload,
      forceUpdate: true
    })
    .invalidatesResource(resources.ASSIGNMENTS.id);
}

export function removeQuestionSetsFromAssignment({assignmentId, setIds}) {
  const payload = {
    data: setIds.map((setId) => {
      return {
        type: 'question_sets_v1',
        id: setId
      };
    })
  };

  return getMandarkAPI().getResource({
    id: resources.REMOVE_QUESTION_SETS_IN_ASSIGNMENT.id,
    method: APIMethods.DELETE,
    params: {
      assignmentId
    },
    payload,
    forceUpdate: true
  });
}

export function getStudentLevelGuessInsights({classroomId, assignmentId, studentId}) {
  return getMandarkAPI().getResource({
    id: resources.CLASSROOM_STUDENT_ASSIGNMENT_GUESS_INSIGHTS.id,
    params: {
      assignmentId,
      assignmentQueryParam: assignmentId,
      classroomId,
      studentId
    },
    method: APIMethods.GET,
    forceUpdate: true
  });
}

export function deleteAssignment({assignmentId}) {
  return getMandarkAPI().getResource({
    id: resources.DELETE_ASSIGNMENT.id,
    params: {
      assignmentId
    },
    method: APIMethods.DELETE,
    forceUpdate: true
  });
}

export function modifyAssignment({
  assignmentId,
  attributes,
  relationships = new List() // expecting immutable [{type: 'typeString', id: idValue}]
}) {
  const payload = buildPatch('assignment_v2')
    .addAttributes(attributes)
    .addManyToManyRelationships(resources.ASSIGNMENTS.versions, relationships)
    .payload();
  return getMandarkAPI().getResource({
    id: resources.MODIFY_ASSIGNMENT.id,
    params: {
      assignmentId
    },
    method: APIMethods.PATCH,
    forceUpdate: true,
    payload
  });
}

export function invalidateTeacherAssignmentStats() {
  return getEmptyPayload(new Map(), null, null, getMandarkAPI())
    .invalidatesResource(resources.ASSIGNMENT_STATISTICS.id)
    .invalidatesResource(resources.ASSIGNMENT_QUESTION_STATISTICS.id)
    .invalidatesResource(resources.ASSIGNMENT_STUDENT_STATISTICS.id);
}
