// @flow
import * as React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {omit} from 'lodash';
import makeRef from 'lib/makeRef';
import {IconDropdown} from 'client/generic/ButtonDropdown/ButtonDropdown.react';
import {ListGroupItem} from '@albert-io/atomic';

import './study-guide-item.scss';

function hasValidChild(child: Object): boolean {
  return Object.values(Object.getOwnPropertyDescriptors(StudyGuideItem)).some((descriptor) => {
    return descriptor.value === child.type;
  });
}

function withStudyGuideItemTypeProps(
  props = {},
  Component: string | React.ComponentType<*>,
  defaultProps = {}
): React.Element<*> {
  const {as, className, children, itemType, ...rest} = props;
  const classNames = classnames(className, defaultProps.className);
  const defaultPassthroughProps = omit(defaultProps, 'className');
  const passedProps = {
    children,
    className: addSuffixToClassNames({
      classNames,
      itemType
    }),
    ...defaultPassthroughProps,
    ...rest
  };

  passedProps.children = React.Children.map(passedProps.children, (child) => {
    if (child && hasValidChild(child)) {
      return React.cloneElement(child, {
        itemType
      });
    }
    return child;
  });

  return <Component {...passedProps} />;
}

withStudyGuideItemTypeProps.propTypes = {
  className: PropTypes.string,
  itemType: PropTypes.string
};

function addSuffixToClassNames({
  classNames = '',
  itemType
}: {
  classNames: string,
  itemType: string
}): string {
  return classNames.split(' ').reduce((prev, curr) => {
    return prev.concat(` ${curr}--${itemType}`);
  }, classNames);
}

const Img = ({className, src}) => (
  <div className={className}>
    <img alt='' src={src} />
  </div>
);
Img.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string
};

export const StudyGuideItemList = ({children}: Object) => <ul className='sgi-list'>{children}</ul>;

export class StudyGuideItem extends React.Component<any> {
  static propTypes = {
    className: PropTypes.string,
    itemType: PropTypes.string,
    onClick: PropTypes.func
  };

  static defaultProps = {
    onClick: () => {}
  };

  // eslint-disable-next-line react/sort-comp
  static Actions = (props: Object) =>
    withStudyGuideItemTypeProps(props, 'div', {className: 'sgi__actions'});

  static Body = (props: Object) =>
    withStudyGuideItemTypeProps(props, 'div', {className: 'sgi__body'});

  static Img = (props: Object) =>
    withStudyGuideItemTypeProps(props, Img, {className: 'sgi__logo-img-wrapper'});

  static Heading = (props: Object) =>
    withStudyGuideItemTypeProps(props, props.as, {className: 'sgi__heading'});

  static Meta = (props: Object) =>
    withStudyGuideItemTypeProps(props, 'div', {className: 'sgi__metadata'});

  static Content = (props: Object) =>
    withStudyGuideItemTypeProps(props, 'div', {className: 'sgi__content'});

  static ActionsDropdown = (props: Object) => {
    return withStudyGuideItemTypeProps(props, IconDropdown, {
      className: 'sgi__actions-dropdown',
      buttonClassName: 'sgi__actions-dropdown-btn unbutton',
      icon: 'fa-ellipsis-v',
      children: 'Actions'
    });
  };

  makeRef = makeRef.bind(this);

  onKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.props.onClick(e);
    }
  };

  render(): React.Node {
    const {children, className, itemType, ...passthroughProps} = this.props;
    const classNames = classnames('sgi', className);
    const suffixedClassNames = addSuffixToClassNames({classNames, itemType});
    return (
      <ListGroupItem className={suffixedClassNames} {...passthroughProps}>
        {React.Children.map(children, (child) => {
          if (child && hasValidChild(child) && itemType) {
            return React.cloneElement(child, {
              itemType
            });
          }
          return child;
        })}
      </ListGroupItem>
    );
  }
}
