import React, {useCallback, ChangeEvent, useEffect} from 'react';
import TextInput from 'client/generic/Forms/TextInput/TextInput';
import TextArea from 'client/generic/Forms/TextArea/TextArea.react';
import Checkbox from 'client/generic/Forms/Checkbox/Checkbox.react';
import {useSupplementEditorV2Store} from 'client/Supplements/SupplementEditor/SupplementEditorV2Store';
import {useShallow} from 'zustand/react/shallow';
import {PRIMARY_CONTENT_LANGUAGE} from 'client/EditPage/V2/QuestionEditorV2Store.types';
import {callAction} from 'client/framework';
import supplementEditorActions from 'client/Supplements/SupplementEditor/SupplementEditor.actions';

import {AuthoringSupplement as AuthoringSupplementModelV1} from 'resources/GeneratedModels/AuthoringSupplement/AuthoringSupplementModel.v1';

import {TranslationCommentStatusEditor} from 'client/Supplements/SupplementEditor/TranslationCommentStatusEditor';

import Passage from './Passage';

import './passage-editor.scss';

interface Props {
  supplement: AuthoringSupplementModelV1;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onCheckChange: (e: ChangeEvent<HTMLInputElement>) => void;
  errors: any;
}

const PassageEditor = ({supplement, onChange, onCheckChange, errors}: Props) => {
  const {
    currentLanguage,
    updateTranslatedField,
    translatedName,
    translatedText,
    translatedSupplement
  } = useSupplementEditorV2Store(
    useShallow((state) => ({
      currentLanguage: state.currentLanguage,
      updateTranslatedField: state.updateTranslatedField,
      translatedName:
        state
          .currentTranslatedSupplement()
          ?.translated_fields?.find((field) => field.field === 'name')?.text || '',
      translatedText:
        state
          .currentTranslatedSupplement()
          ?.translated_fields?.find((field) => field.field === 'content.text')?.text || '',
      translatedSupplement: state.currentTranslatedSupplement()
    }))
  );

  const hideLineNumbers = supplement.getContent().get('hideLineNumbers', false);
  const ignoreWhiteSpace = supplement.getContent().get('ignoreWhiteSpace', false);
  const excludeWhiteSpace = supplement.getContent().get('excludeWhiteSpace', false);

  useEffect(() => {
    // the point of this is to set the content-specific values for this type, when the type changes,
    // if they do not already exist.
    if (
      supplement.getHideLineNumbers() !== hideLineNumbers ||
      supplement.getIgnoreWhiteSpace() !== ignoreWhiteSpace ||
      supplement.getExcludeWhiteSpace() !== excludeWhiteSpace
    ) {
      callAction(supplementEditorActions.MODIFY_ACTIVE_SUPPLEMENT, [
        {
          setter: 'setHideLineNumbersOnContentMap',
          value: hideLineNumbers
        },
        {
          setter: 'setIgnoreWhiteSpace',
          value: ignoreWhiteSpace
        },
        {
          setter: 'setExcludeWhiteSpace',
          value: excludeWhiteSpace
        }
      ]);
    }
  }, [supplement, hideLineNumbers, ignoreWhiteSpace, excludeWhiteSpace]);

  const nameValue =
    currentLanguage === PRIMARY_CONTENT_LANGUAGE ? supplement.getName() : translatedName;
  const textValue =
    currentLanguage === PRIMARY_CONTENT_LANGUAGE ? supplement.getText() : translatedText;

  const handleNameChange = useCallback(
    (e) => {
      if (currentLanguage === PRIMARY_CONTENT_LANGUAGE) {
        onChange(e);
      } else {
        updateTranslatedField(currentLanguage, 'name', 'text', e.target.value);
      }
    },
    [currentLanguage, onChange, updateTranslatedField]
  );

  const handleTextChange = useCallback(
    (e) => {
      if (currentLanguage === PRIMARY_CONTENT_LANGUAGE) {
        onChange(e);
      } else {
        updateTranslatedField(currentLanguage, 'content.text', 'text', e.target.value);
      }
    },
    [currentLanguage, onChange, updateTranslatedField]
  );

  return (
    <div className='passage-editor'>
      <div className='passage-editor__fieldset'>
        <TextInput
          className='passage-editor__input'
          data-setter-method='setName'
          error={currentLanguage === PRIMARY_CONTENT_LANGUAGE && Boolean(errors.get('name'))}
          errorMessage={currentLanguage === PRIMARY_CONTENT_LANGUAGE && errors.get('name', null)}
          label='Name'
          name='name'
          onChange={handleNameChange}
          value={nameValue}
        />
        <TranslationCommentStatusEditor fieldName='name' />
        <TextArea
          className='passage-editor__input passage-editor__input--textarea'
          data-setter-method='setText'
          error={currentLanguage === PRIMARY_CONTENT_LANGUAGE && Boolean(errors.get('text'))}
          errorMessage={currentLanguage === PRIMARY_CONTENT_LANGUAGE && errors.get('text', null)}
          label='Text'
          name='text'
          onChange={handleTextChange}
          value={textValue}
        />
        <TranslationCommentStatusEditor fieldName='content.text' />
        <Checkbox
          disabled={currentLanguage !== PRIMARY_CONTENT_LANGUAGE}
          className='passage-editor__input passage-editor__input--checkbox'
          checked={hideLineNumbers}
          data-setter-method='setHideLineNumbersOnContentMap'
          label='Hide line numbers'
          name='hideLineNumbers'
          onChange={onCheckChange}
        />
        <Checkbox
          disabled={currentLanguage !== PRIMARY_CONTENT_LANGUAGE}
          className='passage-editor__input passage-editor__input--checkbox'
          checked={ignoreWhiteSpace}
          data-setter-method='setIgnoreWhiteSpace'
          label='Ignore white space'
          name='ignoreWhiteSpace'
          onChange={onCheckChange}
        />
        <Checkbox
          disabled={currentLanguage !== PRIMARY_CONTENT_LANGUAGE}
          className='passage-editor__input passage-editor__input--checkbox'
          checked={excludeWhiteSpace}
          data-setter-method='setExcludeWhiteSpace'
          label='Exclude white space from line count'
          name='excludeWhiteSpace'
          onChange={onCheckChange}
        />
      </div>
      <div className='passage-editor-preview__wrapper'>
        <div className='a-form-input__label'>Preview</div>
        <Passage
          content={translatedSupplement ? translatedSupplement.content : supplement.getContent()}
        />
      </div>
    </div>
  );
};

export default PassageEditor;
