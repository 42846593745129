import React from 'react';
import {fromJS} from 'immutable';
import SimpleDropdown from 'generic/SimpleDropdown/SimpleDropdown.react';
import {
  SEARCH_PARAMETERS,
  getURLSearchParameter,
  setURLSearchParameter
} from '../URLSearchParameters';

const options = fromJS([
  {
    name: 'None',
    value: null,
    id: 0
  },
  {
    name: 'Duplicate Alignment',
    value: 'duplicate_alignment',
    id: 1
  }
]);

/**
 * @todo OSD: It seems unlikely that we will have "flags" for the initial release.
 * For now, this input is disabled.
 */
export class FlagDropdown extends React.Component {
  handleChange(item) {
    setURLSearchParameter(SEARCH_PARAMETERS.flag, item.get('value'));
  }

  render() {
    const activeFlag = getURLSearchParameter(SEARCH_PARAMETERS.flag);
    const activeItemId = options.find((option) => option.get('value') === activeFlag).get('id');
    return (
      <SimpleDropdown
        activeItemId={activeItemId}
        className='queue-search-panel__facet'
        destroyStoreOnUnmount
        fullWidth
        label='Flags'
        options={options}
        placeholder='Select additional flag'
        onChange={this.handleChange}
        storeName={'QueueFacet-FlagDropdownStore'}
      />
    );
  }
}
