// @flow
import {Store, setUpStore} from 'client/framework';
import DateRangeStore from 'generic/DateRange/DateRange.store';
import SimpleDropdownStore from 'generic/SimpleDropdown/SimpleDropdown.store';

export default class SelectableDateRangeStore extends Store {
  _dateRangeStore: DateRangeStore;
  _simpleDropdownStore: SimpleDropdownStore;

  constructor(name: string) {
    super(name);

    this._dateRangeStore = setUpStore(DateRangeStore, `${name}--DateRange`);
    this._simpleDropdownStore = setUpStore(SimpleDropdownStore, `${name}--DateRangeDropdown`);
  }

  getDateRangeStore(): DateRangeStore {
    return this._dateRangeStore;
  }

  getSimpleDropdownStore(): SimpleDropdownStore {
    return this._simpleDropdownStore;
  }

  getDateRangeStoreName(): string {
    return this.getDateRangeStore().getName();
  }

  getSimpleDropdownStoreName(): string {
    return this.getSimpleDropdownStore().getName();
  }
}
