/**
 * # Select
 *
 * A component that renders a select input with an optional border and fill wrapper.
 *
 * ## Props
 *
 * - `border` (optional): Specifies whether to display a border around the select input. Default is `false`.
 * - `fillWrapper` (optional): Specifies whether the select input should fill its wrapper container. Default is `true`.
 * - `className` (optional): Additional CSS class name(s) to apply to the select input.
 * - `children` (optional): The content to be rendered inside the select input.
 * - `...rest` (optional): Additional props to be passed to the underlying `IconInput` component.
 *
 * ## Usage
 *
 * ```tsx
 * import Select from './Select.react';
 *
 * const MyComponent = () => {
 *   return (
 *     <Select
 *       border={true}
 *       fillWrapper={false}
 *       className="custom-select"
 *       placeholder="Select an option"
 *     >
 *       <option value="option1">Option 1</option>
 *       <option value="option2">Option 2</option>
 *       <option value="option3">Option 3</option>
 *     </Select>
 *   );
 * };
 * ```
 */

import React from 'react';
import classnames from 'classnames';

import IconInput, {Props as IconInputProps} from '../IconInput/IconInput.react';
import Input from '../../atoms/Input/Input.react';

type SelectProps = {
  border?: boolean;
  fillWrapper?: boolean;
} & IconInputProps<typeof Input> &
  PropsWithChildrenOptional &
  PropsWithClassNameOptional;

const Select = ({className, fillWrapper = true, ...rest}: SelectProps) => (
  <IconInput
    as='select'
    fillWrapper={fillWrapper}
    className={classnames('m-select', className)}
    iconRight='caret-down'
    {...rest}
  />
);

export default Select;
