import {MandarkResourceInterface} from '../Mandark.interface';

export class AssignmentStatsInterface extends MandarkResourceInterface {
  // TODO: Once the response of this endpoint gets normalized, we can make
  // this guy use a better 'parent' class tahn this one. Right now
  // this response is non standard from the other stats.

  getData() {
    return this._data.get('data');
  }

  getStudentsInAssignment() {
    return this.getData().get('students_in_assignment');
  }

  getStudentsInAssignmentAndClassroom() {
    return this.getData().get('students_in_assignment_and_classroom');
  }

  getClassesInAssignment() {
    return this.getData().get('classrooms_in_assignment');
  }

  getFinishedStudents() {
    return this.getData().get('completed_students');
  }

  getQuestionsAnswered() {
    return this.getData().get('questions_answered');
  }

  getQuestionsCorrectlyAnswered() {
    return this.getData().get('questions_answered_correctly');
  }

  getTotalQuestions() {
    return this.getData().get('questions_in_assignment');
  }
}
