import imgix from 'imgix-core-js';
import request from 'superagent';
import {round} from 'lodash';

// eslint-disable-next-line new-cap
const client = new imgix({
  domain: 'imgix.albert.io'
});

/**
 * _Shared_ Imgix API parameters derieved from the supplement.
 */
function getImgixParametersFromSupplement(supplement) {
  const params = {};
  const scale = Math.round(supplement.getImageSize()) * 0.01 || 1.0;
  if (scale !== 1.0) {
    /**
     * @see https://docs.imgix.com/apis/url/size/w
     */
    params.w = scale;
  }
  /**
   * While we do not explicity support PDF supplements, we do
   * have some legacy image supplements that appear to be PDFs.
   * When we encounter these files, we make sure the result from
   * the Imgix API is a file type we support.
   * @see https://docs.imgix.com/apis/url/format/fm
   * @see https://github.com/albert-io/project-management/issues/3152
   */
  if (supplement.mightBePDF()) {
    params.fm = 'png';
  }
  return params;
}

/**
 * Determines whether or not a supplement has crop values saved to the resource.
 */
export function isSupplementCropped(supplement) {
  return !supplement.getCropValues().isEmpty();
}

export function isCroppedOrScaled(supplement) {
  return !supplement.getCropValues().isEmpty() || supplement.getImageSize() !== 100;
}

export function getImgixURL(supplement, parameters = {}) {
  return client.buildURL(supplement.getImageLocation().path, {
    ...getImgixParametersFromSupplement(supplement),
    ...parameters
  });
}

export async function getImgixURLWithCrop(supplement, parameters = {}) {
  /**
   * Just load the metadata about the image to get the original width and height.
   * https://docs.imgix.com/tutorials/extracting-metadata-json-output-format
   * @type {string}
   */
  const url = client.buildURL(supplement.getImageLocation().path, {fm: 'json'});

  const response = await request.get(url);

  let width;
  let height;
  if (response.header['content-type'] === 'application/json') {
    width = response.body.PixelWidth;
    height = response.body.PixelHeight;
  } else {
    /**
     * In the case of SVGs the imgix API does not return JSON, but
     * the actual image asset.
     */
    const img = new global.Image();
    img.src = supplement.getImageLocation().href;
    width = img.width;
    height = img.height;
  }

  const crop = supplement.getCropValues();
  const dimensions = {
    x: round(crop.get('x') * 0.01 * width),
    y: round(crop.get('y') * 0.01 * height),
    width: round(crop.get('width') * 0.01 * width),
    height: round(crop.get('height') * 0.01 * height)
  };

  return getImgixURL(supplement, {
    rect: `${dimensions.x},${dimensions.y},${dimensions.width},${dimensions.height}`,
    ...parameters
  });
}
