/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import appStore from 'client/AppStore';
import {OrderedMap} from 'immutable';

import {getSubgroupItemsAsClickHandlerContents} from './subjectMenuData.utils';

export function withActivePathManager(SubjectMenuRenderer) {
  return class extends React.Component {
    static propTypes = {
      initialActivePath: PropTypes.arrayOf(PropTypes.string),
      subjectAreaContent: PropTypes.node,
      additionalSidebarMenuItems: ImmutablePropTypes.orderedMap
    };

    static defaultProps = {
      additionalSidebarMenuItems: OrderedMap()
    };

    constructor(props) {
      super(props);
      this.state = {
        activePath: props.initialActivePath || [],
        renderExtraSideBarContent: false
      };
    }

    onSidebarItemClick = (category, item) => {
      this.setState({
        renderExtraSideBarContent: this.props.additionalSidebarMenuItems.has(category)
      });
      const activePath = [];
      if (category !== 'All Subjects') {
        activePath.push(category);
      }
      if (activePath.length > 0 && item !== 'All') {
        activePath.push(item);
      }
      this.setState({activePath});
    };

    handleSubgroupItemClick = (subgroup) => {
      const [domain, group] = this.state.activePath;
      const activePath = [domain, group];
      if (subgroup !== 'All') {
        activePath.push(subgroup);
      }
      this.setState({activePath});
    };

    getSubgroupMenuContent() {
      if (this.state.activePath.length > 1) {
        const tabs = getSubgroupItemsAsClickHandlerContents(
          this.state.activePath,
          this.handleSubgroupItemClick
        );
        // we currently don't want `free` tabs to show for a subject since they will always be empty in Marketplace
        return appStore.routerProps.location.pathname.includes('/marketplace/')
          ? tabs.filter((tab) => tab.get('label') !== 'Free')
          : tabs;
      }
      return null;
    }

    handleSearchStringUpdate = (searchString) => {
      if (searchString) {
        this.setState({activePath: []});
      }
    };

    render() {
      return (
        <SubjectMenuRenderer
          {...this.props}
          subjectAreaContent={
            this.state.renderExtraSideBarContent &&
            this.state.activePath.length !== 0 &&
            this.props.subjectAreaContent
          }
          activePath={this.state.activePath}
          onSidebarItemClick={this.onSidebarItemClick}
          subgroupMenuContent={this.getSubgroupMenuContent()}
          onSearchStringUpdate={this.handleSearchStringUpdate}
        />
      );
    }
  };
}
