import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {Map} from 'immutable';
import {callAction} from 'client/framework';
import appStore from 'client/AppStore';
import {history} from 'client/history';
import sessionStore from 'client/Session/SessionStore';
import masqueradeStore from 'generic/Masquerade/Masquerade.store';
import globalNavigationActions from 'client/GlobalNavigation/GlobalNavigationActions';
import ErrorPage from 'client/static/Error/ErrorPage.react';
import {ERROR_TYPES} from 'client/constants';
import {HeroUnit} from 'client/generic/HeroUnit/HeroUnit.react';
import Button from 'sg/Button/Button.react';
import {
  getActivePathFromPathname,
  getSubgroupItemsByActivePath
} from 'generic/SubjectMenuRenderer/subjectMenuData.utils';
import makeConstants from 'lib/makeConstants';

import awaitMandarkQueries from 'lib/hocs/awaitMandarkQueries';

import {Grid, TabNavigation} from '@albert-io/atomic';
import {resource} from '@albert-io/json-api-framework/request/builder';

import {SubjectModelV2} from 'resources/augmented/Subject/SubjectModel.v2';
import {UserModelV2} from 'resources/GeneratedModels/User/UserModel.v2';

import tours from '../subjectViewTours';
import {getSubjectViewLinks} from '../getSubjectViewLinks';

import {subjectViewActions} from './SubjectView.actions';
import subjectViewStore from './SubjectView.store';
import BlockedAccessModal from './BlockedAccessModal.react';

import '../assets/subject-view.scss';
import {Link} from 'react-router';

export function bootstrapSubjectViewQuery(props) {
  return resource('subject_v2')
    .mandarkEndpoint(['subjects_v2'])
    .findOne()
    .filter({url_slug: props.params.slug})
    .include('curriculum_area_v1,subject_faqs_v1,guides_v1')
    .fields({
      guide_v1: 'id,guide_type'
    })
    .withMeta('subject_v2')
    .meta(
      {
        context: {
          student: {
            id: sessionStore.getUserId()
          }
        }
      },
      sessionStore.hasValidSession()
    );
}

function getUserQuery() {
  return sessionStore.isStudent()
    ? resource('users_v2')
        .mandarkEndpoint(['users_v2', sessionStore.getUserId()])
        .include('student_v2.student_classrooms_v1')
    : resource('users_v2')
        .mandarkEndpoint(['users_v2', sessionStore.getUserId()])
        .include('teacher_v1')
        .withMeta('teacher_v1');
}

export const SubjectView = awaitMandarkQueries(
  (props) => {
    const awaitedQueries = {
      subject: bootstrapSubjectViewQuery(props) // @todo figure out these props
    };
    // used in subject banner to know if a user is licensed school_personnel thus deciding freemium
    if (sessionStore.hasValidSession()) {
      awaitedQueries.user = getUserQuery();
    }
    return {
      queries: awaitedQueries
    };
  },
  class SubjectView extends React.Component {
    static propTypes = {
      children: PropTypes.node,
      location: PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.string
      }),
      params: PropTypes.shape({
        slug: PropTypes.string
      }),
      subject: PropTypes.instanceOf(SubjectModelV2).isRequired,
      user: PropTypes.instanceOf(UserModelV2)
    };

    constructor(props) {
      super(props);

      callAction(globalNavigationActions.SET_SHOULD_SHOW_UPGRADE_BUTTON_NO_EMIT, true);

      /**
       * The value of prevPathname updates with each route change, so we save the
       * initial value to our store in order that it will persist while users
       * navigate SubjectView's child routes.  We clear it on componentWillUnmount.
       */
      const {prevRouterProps} = appStore;
      const prevPathname = prevRouterProps.location ? prevRouterProps.location.pathname : '';
      callAction(subjectViewActions.SET_BACK_BUTTON_PATH, prevPathname);

      /**
       * Although we are leveraging awaitMandarkQueries locally here, there
       * are still places in the application that expect a store to expose
       * this component's state to other components, so we save the results
       * of our query to the SubjectViewStore.
       */
      callAction(subjectViewActions.SET_ACTIVE_SUBJECT, props.subject);
    }

    componentDidMount() {
      /*
       * Comment carried over from V1 SubjectView.react.js:
       * The server is freaking out when setting a IndexRedirect on our catch-all
       * :slug path, so we're going to wait till the component mounts to redirect
       * to the StudyGuide :slug/questions path
       */
      if (!this.props.children) {
        this.props.location.pathname = this.props.location.pathname.replace(/\/$/, '');
        const studyGuideRoute = `${this.props.location.pathname}`;
        history.replace({
          pathname: studyGuideRoute
        });
      }
      const subjectId = this.props.subject.getId();
      if (subjectViewStore.getScrollPosition(subjectId)) {
        this.scrollToPrevY();
      }

      callAction(globalNavigationActions.SET_SHOULD_SHOW_UPGRADE_BUTTON, true);
    }

    componentDidUpdate(prevProps) {
      const searchDidUpdate = this.props.location.search !== prevProps.location.search;
      const subjectId = this.props.subject.getId();
      if (searchDidUpdate && subjectViewStore.getScrollPosition(subjectId)) {
        this.scrollToPrevY();
      }
    }

    componentWillUnmount() {
      callAction(globalNavigationActions.SET_SHOULD_SHOW_UPGRADE_BUTTON, false);
      callAction(subjectViewActions.RESET_STORE);
      this.registerScrollY();
    }

    getBackButon() {
      /**
       * As of 11/18, we are only showing this "Courses" button when
       * users are referred from the Course Library. We convert the
       * pathname that got us here to a "path" (i.e. an array) into
       * courseLibraryStructure, which is just a massive Map of domain
       * and group listings. If we reach in and come back empty-handed, it
       * suggests the referrer pathname is unrelated to the Course Library.
       */
      const prevPathname = subjectViewStore.getBackButtonPath();
      const activePath = getActivePathFromPathname(prevPathname, '/subjects');
      const items = getSubgroupItemsByActivePath(activePath, false);
      const referredFromCourseLibrary = !items.isEmpty();
      if (referredFromCourseLibrary) {
        return <Button to={prevPathname}>Courses</Button>;
      }
      return null;
    }

    getLinks() {
      const {subject} = this.props;
      const subjectGuideTypes = makeConstants('assessment', 'practice', 'free_response');
      const subjectGuides = subject.getGuides().reduce((acc, guide) => {
        acc.push(guide.getGuideType());
        return acc;
      }, []);
      const navConfig = Map({
        studyGuide: subjectGuides.includes(subjectGuideTypes.practice),
        FRQ: subjectGuides.includes(subjectGuideTypes.free_response),
        assessments: subjectGuides.includes(subjectGuideTypes.assessment),
        FAQ: sessionStore.isSuper() || !this.props.subject.getSubjectFaqs().isEmpty(),
        Tours: sessionStore.isTeacher() ? !!tours[this.props.subject.getUrlSlug()] : false
      });
      return getSubjectViewLinks({subjectSlug: this.props.params.slug, navConfig});
    }

    scrollToPrevY = () => {
      const subjectId = this.props.subject.getId();
      global.requestAnimationFrame(() => {
        global.requestAnimationFrame(() => {
          global.scrollTo(0, subjectViewStore.getScrollPosition(subjectId));
          this.unregisterScrollY();
        });
      });
    };

    registerScrollY = () =>
      callAction(subjectViewActions.SET_SCROLL_POSITION, {
        subjectId: this.props.subject.getId(),
        scrollYPosition: global.scrollY
      });

    unregisterScrollY = () =>
      callAction(subjectViewActions.SET_SCROLL_POSITION, {
        subjectId: this.props.subject.getId(),
        scrollYPosition: null
      });

    render() {
      const {subject} = this.props;
      if (subject.isHidden() && !sessionStore.isSuper()) {
        return (
          <ErrorPage
            errorCode={ERROR_TYPES.UNAUTHORIZED}
            errorText='This subject has been unpublished.'
          />
        );
      }

      const curriculumAreaSlug = subject.getCurriculumAreaSlug();
      const backgroundColor = `var(--background-domain-${curriculumAreaSlug})`;
      const route = this.props.location.pathname.split('/').pop();
      const isStudyGuide = route === 'free-response' || route === this.props.params.slug;

      return (
        <div
          className={classnames('subject-view-wrapper', {
            'subject-view-wrapper--study-guide': isStudyGuide
          })}
        >
          {masqueradeStore.isMasquerading() && <BlockedAccessModal />}
          <HeroUnit backButton={this.getBackButon()} backgroundColor={backgroundColor}>
            <HeroUnit.Icon src={subject.getLogoLocation().href} />
            <h1>{this.props.subject.getName()}</h1>
          </HeroUnit>
          <TabNavigation>
            {this.getLinks().map((item) => (
              <TabNavigation.Tab
                isActive={this.props.location.pathname === item.get('to')}
                as={Link}
                to={item.get('to')}
              >
                {item.get('name')}
              </TabNavigation.Tab>
            ))}
          </TabNavigation>
          <Grid.Container className='subject-view-content u-mar-t_2 u-mar-b_2'>
            <Grid.Row justify='center' className='u-mar_0 u-width_100pc'>
              <Grid.Col all={12} className='u-mar_0 u-width_100pc'>
                {this.props.children &&
                  React.cloneElement(this.props.children, {
                    subject: this.props.subject,
                    user: this.props.user,
                    onSearchCallback: this.registerScrollY
                  })}
              </Grid.Col>
            </Grid.Row>
          </Grid.Container>
        </div>
      );
    }
  }
);
