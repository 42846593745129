import React from 'react';
import PropTypes from 'prop-types';
import SubSearch from 'client/components/SubSearch/SubSearch.react';
import {LegacyFindAndApply, CheckboxChip} from '@albert-io/atomic';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {parseSearchString} from 'client/components/AdvancedSearch/AdvancedSearch.utils';
import {resource} from '@albert-io/json-api-framework/request/builder';

export default class TagsSubSearch extends React.Component {
  static propTypes = {
    subjectId: PropTypes.string.isRequired,
    guideType: PropTypes.string.isRequired
  };

  makeQuery = (searchString) => {
    const {keywords, phrases, isSearchingForKeywords, isSearchingForPhrases} =
      parseSearchString(searchString);

    return resource('search_tags_v1')
      .sort('name')
      .mandarkEndpoint(['search_tags_v1'])
      .filter({
        question_sets_v1: {
          subject_v2: {
            id: this.props.subjectId
          },
          guide_levels_v2: {
            nlevel: 1,
            guide_v1: {
              guide_type: this.props.guideType
            }
          }
        }
      })
      .filter(
        {
          document_stems: {
            search_tokens: encodeURIComponent(keywords)
          }
        },
        isSearchingForKeywords
      )
      .filter(
        {
          document: {
            search_phrases: encodeURIComponent(phrases)
          }
        },
        isSearchingForPhrases
      )
      .pageSize(250);
  };

  render() {
    return (
      <SubSearch
        {...this.props}
        className='advanced-search__sub-search advanced-search__sub-search--tags'
        queryFunc={this.makeQuery}
        renderResultsFunc={({onCheck, results, selectedItems}) => (
          <LegacyFindAndApply.Body className='advanced-search__sub-search__results'>
            {results.map((result, i) => (
              <CheckboxChip
                data-id={result.getId()}
                icon='tag'
                key={i}
                onChange={(e) => onCheck(e, result)}
                checked={selectedItems.has(result.getId())}
              >
                <MarkdownRendererV2 text={result.getName()} />
              </CheckboxChip>
            ))}
          </LegacyFindAndApply.Body>
        )}
        // @todo: Replace this with finalized copy
        renderStagingFunc={() => (
          <LegacyFindAndApply.Message>
            Albert questions are tagged. You can search for tags by keyword or phrase (e.g.
            history).
          </LegacyFindAndApply.Message>
        )}
      />
    );
  }
}
