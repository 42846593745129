// @flow
import {isNumber} from 'lodash';

type MasteryBucket = {
  BUCKET_NAME: string,
  DISPLAY_NAME: string,
  MIN: number,
  MAX: number
};

export const MASTERY: MasteryBucket = {
  BUCKET_NAME: 'MASTERY',
  DISPLAY_NAME: 'Mastery',
  MIN: 90,
  MAX: 100
};

export const EXCELLING: MasteryBucket = {
  BUCKET_NAME: 'EXCELLING',
  DISPLAY_NAME: 'Excelling',
  MIN: 80,
  MAX: MASTERY.MIN - 1
};

export const PROFICIENT: MasteryBucket = {
  BUCKET_NAME: 'PROFICIENT',
  DISPLAY_NAME: 'Proficient',
  MIN: 70,
  MAX: EXCELLING.MIN - 1
};

export const PASSING: MasteryBucket = {
  BUCKET_NAME: 'PASSING',
  DISPLAY_NAME: 'Passing',
  MIN: 55,
  MAX: PROFICIENT.MIN - 1
};

export const STRUGGLING: MasteryBucket = {
  BUCKET_NAME: 'STRUGGLING',
  DISPLAY_NAME: 'Struggling',
  MIN: 0,
  MAX: PASSING.MIN - 1
};

export const BUCKETS = {
  MASTERY,
  EXCELLING,
  PROFICIENT,
  PASSING,
  STRUGGLING
};

export const BUCKETS_ARRAY_INCREMENTING = Object.values(BUCKETS).sort((a, b) =>
  a.MIN > b.MIN ? 1 : 0
);

export function getBucketByAccuracy(accuracy: number): MasteryBucket {
  if (accuracy >= MASTERY.MIN) {
    return MASTERY;
  } else if (accuracy >= MASTERY.MIN) {
    return MASTERY;
  } else if (accuracy >= EXCELLING.MIN) {
    return EXCELLING;
  } else if (accuracy >= PROFICIENT.MIN) {
    return PROFICIENT;
  } else if (accuracy >= PASSING.MIN) {
    return PASSING;
  } else {
    return STRUGGLING;
  }
}

export function getStatusNameByAccuracy(accuracy: number, hasStarted: boolean = true): string {
  if (!hasStarted) {
    return 'N/A';
  }

  return getBucketByAccuracy(accuracy).DISPLAY_NAME;
}

/**
 * Get the "status name" based on a students accuracy, the main difference here
 * is that "Struggling" is presented as "Learning".
 * @param  {Number} accuracy
 * @return {String}
 */
export function getStatusNameByAccuracyForStudent(accuracy: number): string {
  const bucket = getBucketByAccuracy(accuracy);
  return bucket === STRUGGLING ? 'Learning' : bucket.DISPLAY_NAME;
}

export function getStatusRangeByAccuracy(accuracy: number): string {
  const bucket = getBucketByAccuracy(accuracy);
  return `${bucket.MIN}%-${bucket.MAX}%`;
}

const PRECISION = 0;

type Options = {fixed: boolean};

/**
 * "safe" math operations we use for site-wide statistics.
 */
export const stats = {
  /**
   * When we divide for statistics, the return value should:
   * - Never be Infinity
   * - Never be NaN
   * - Round to the defined, shared, precision.
   */
  divide(dividend: number, divisor: number, {fixed}: ?Options = {fixed: true}): number {
    const quotient = divisor ? dividend / divisor : 0;
    return fixed ? stats.toFixed(quotient) : quotient;
  },

  /**
   * Convert a Number to the shared precision.
   */
  toFixed(value: number): number {
    return stats.forceNumber(parseFloat(value.toFixed(PRECISION)));
  },

  /**
   * Ensures we're never returning NaN (usually after an operation)... always evaluates
   * NaN and non-Numbers as 0 – be sure to consider Infinity.
   */
  forceNumber(candidate: mixed): number {
    if (isNumber(candidate) && isNaN(candidate) === false) {
      return Number(candidate);
    }
    return 0;
  },

  percentage(dividend: number, divisor: number, {fixed}: ?Options = {fixed: true}): number {
    const quotient = stats.divide(dividend, divisor, {fixed: false}) * 100;
    return fixed ? stats.toFixed(quotient) : quotient;
  },

  average(...numbers: Array<number>): number {
    if (numbers.length === 0) {
      return 0;
    }
    return numbers.reduce((acc, number) => acc + number, 0) / numbers.length;
  },

  inBucket(bucket: MasteryBucket, value: number): boolean {
    return value >= bucket.MIN && value <= bucket.MAX;
  },

  inBucketOrBeyond(bucket: MasteryBucket, value: number): boolean {
    return value >= bucket.MIN;
  },

  inBucketOrBelow(bucket: MasteryBucket, value: number): boolean {
    return value <= bucket.MAX;
  },

  getStatusNameByAccuracyForStudent,
  getStatusNameByAccuracy
};

export default stats;
