import {createActions} from 'client/framework';

export default createActions(null, [
  'DISCARD_CHANGES',
  'MOVE_FAQ',
  'SAVE_FAQ_ORDER',
  'SAVE_TOAST_EMITTED',
  'SET_REARRANGE_MODE',
  'VIEW_FAQ_QUESTIONS_LIST'
]);
