import React, {useContext, useRef} from 'react';

import {pushQueryParams} from 'client/history';
import {ReportsContext} from 'client/Reports/Reports.context';
import {SortOption} from 'client/Reports/reports.types';
import {
  Button,
  Card,
  Dropdown,
  DropdownItem,
  Icon,
  IconButton,
  Text,
  WithToggle
} from '@albert-io/atomic';

import './performance-header-cell.scss';
import {isEqual} from 'lodash';
import classNames from 'classnames';

interface BaseProps {
  index?: number;
  totalColumns?: number;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

interface PropsWithoutSort extends BaseProps {
  showSort: false;
}

interface PropsWithSort extends BaseProps {
  sortDirection: SortOption;
  setSortDirection: any;
  setSortBy: React.Dispatch<React.SetStateAction<string | null>>;
  sortBy: string | null;
  showSort: true;
}

type Props = PropsWithoutSort | PropsWithSort;

const PERFORMANCE_GROUPS = [
  {
    className: 'background-mastery',
    name: 'Mastery',
    description: '90 - 100%'
  },
  {
    className: 'background-excelling',
    name: 'Excelling',
    description: '80 - 89%'
  },
  {
    className: 'background-proficient',
    name: 'Proficient',
    description: '70 - 79%'
  },
  {
    className: 'background-passing',
    name: 'Passing',
    description: '55 - 69%'
  },
  {
    className: 'background-struggling',
    name: 'Struggling',
    description: '0 - 54%'
  },
  {
    className: 'background-na',
    name: 'In Progress',
    description: '--'
  }
] as const;

export const PerformanceHeaderCell = (props: Props) => {
  const {performanceCalculateBy, dimensions, variables} = useContext(ReportsContext);
  const popoverTarget = useRef(null);

  const showMastery = !!variables.studentFilter || dimensions.includes('students');

  const gradeIsAvailable =
    (isEqual(dimensions, ['assignments']) && !!variables.studentFilter) ||
    (isEqual(dimensions, ['students']) && !!variables.assignmentFilter) ||
    isEqual(dimensions, ['students', 'assignments']);

  const isCurrentSort =
    props.showSort &&
    props.sortBy &&
    ((performanceCalculateBy === 'accuracy' && props.sortBy === 'metrics.accuracy') ||
      (performanceCalculateBy === 'grade' && props.sortBy === 'metrics.grade'));

  // dirty hack to get z-index of the "next" header cell in gradebook to be 1 more than this one so things don't overlap
  const zIndex =
    props.totalColumns !== undefined && props.index !== undefined
      ? 12 + (props.totalColumns - props.index)
      : 13;

  return (
    <th
      className='reports-table__header'
      style={{zIndex}}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      <div
        className='u-display_flex u-align-items_center u-justify-content_space-between'
        ref={popoverTarget}
      >
        <div className='u-display_flex u-align-items_center'>
          <Text size='m' bold>
            {performanceCalculateBy === 'accuracy' ? 'Accuracy' : 'Grade'}
          </Text>

          <WithToggle>
            {({on, onClick}) => (
              <div className='u-mar-l_1'>
                <IconButton
                  color='secondary'
                  label='Column Settings'
                  iconStyle='regular'
                  size='s'
                  icon='gear'
                  onClick={onClick}
                  className='performance_header__settings'
                />

                {on && (
                  <Dropdown.Tray
                    placement='bottom-start'
                    target={popoverTarget.current}
                    positionFixed
                    modifiers={{
                      offset: {
                        enabled: true,
                        offset: '0, 8px'
                      },
                      preventOverflow: {
                        boundariesElement: 'window'
                      },
                      flip: {
                        enabled: false
                      }
                    }}
                  >
                    <Card paddingLevel={2} className='performance-header-cell__summary-card'>
                      {showMastery && (
                        <>
                          <div className='u-display_flex u-flex-direction_column u-gap_space-x1 u-mar-b_2'>
                            {PERFORMANCE_GROUPS.map((group) => (
                              <div
                                key={group.name}
                                className='u-display_flex u-align-items_center u-justify-content_space-between'
                              >
                                <div className='u-display_flex u-align-items_center'>
                                  <div
                                    className={`${group.className} u-width_1 u-height_1 u-border-radius_1 u-mar-r_1`}
                                    style={{width: '16px', height: '12px'}}
                                  />
                                  <Text size='s' bold className='u-mar-r_4'>
                                    {group.name}
                                  </Text>
                                </div>

                                <Text size='s' className='u-pad-l_2'>
                                  {group.description}
                                </Text>
                              </div>
                            ))}
                          </div>
                          <hr className='u-mar-tb_1' />
                        </>
                      )}

                      <div className={classNames('u-mar-b_1', {'u-mar-t_2': showMastery})}>
                        Calculation Method:
                      </div>

                      <Dropdown
                        position='right-end'
                        fillWrapper
                        trigger={
                          <Button
                            color='secondary'
                            variant='outlined'
                            className='u-width_100pc u-justify-content_space-between'
                          >
                            <span>
                              {performanceCalculateBy === 'accuracy' ? 'Accuracy' : 'Grade'}
                            </span>

                            <Icon icon='caret-down' iconStyle='solid' className='u-pad-l_1' />
                          </Button>
                        }
                      >
                        <DropdownItem
                          disabled={!gradeIsAvailable}
                          subtext='Counts any unattempted questions in the assignment as incorrect attempts.'
                          onClick={() => {
                            pushQueryParams({performanceCalculateBy: 'grade'});
                          }}
                        >
                          Grade{' '}
                          {!gradeIsAvailable && (
                            <span className='a-text--italic'>(Not available for this view)</span>
                          )}
                        </DropdownItem>

                        <DropdownItem
                          subtext='Does not count unattempted questions as correct or incorrect.'
                          onClick={() => {
                            pushQueryParams({performanceCalculateBy: 'accuracy'});
                          }}
                        >
                          Accuracy
                        </DropdownItem>
                      </Dropdown>
                    </Card>
                  </Dropdown.Tray>
                )}
              </div>
            )}
          </WithToggle>
        </div>

        {props.showSort && (
          <IconButton
            color={isCurrentSort ? 'primary' : 'secondary'}
            label='sort'
            iconStyle='regular'
            size='s'
            icon={isCurrentSort && props.sortDirection === 'desc' ? 'arrow-down' : 'arrow-up'}
            onClick={() => {
              const newSortDirection =
                isCurrentSort && props.sortDirection === 'asc' ? 'desc' : 'asc';

              props.setSortDirection(newSortDirection);
              props.setSortBy(
                performanceCalculateBy === 'accuracy' ? 'metrics.accuracy' : 'metrics.grade'
              );
            }}
          />
        )}
      </div>
    </th>
  );
};
