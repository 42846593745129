import React from 'react';
import {Set} from 'immutable';
import sessionStore from 'client/Session/SessionStore';
import {requireSession} from 'lib/decorators/requireSession';
import Button from 'sg/Button/Button.react';
import {renderMoment} from 'lib/timeUtils';
import templateAssignmentStageStore from './TemplateAssignmentStage.store';
import {getAssignmentTemplateQuery, handleSaveToTemplates} from './assignmentTemplate.utils';
import {callAction} from 'client/framework';
import teacherAssignmentTemplateQuestionsListActions from 'client/InteractionEngineV2/IESessionTypes/Assignments/TeacherAssignmentTemplateIE/TeacherAssignmentTemplateQuestionsList/TeacherAssignmentTemplateQuestionsList.actions';

import '../assignment-stage.scss';

/**
 * @todo - This and the various components defined in AssignmentStage seem like they could
 * be refactored to use the same components rather than just the same styles.
 */
@requireSession({
  rolePredicate: () => {
    return sessionStore.isTeacher();
  }
})
export class TemplateAssignmentStage extends React.Component {
  getAssignmentTemplate() {
    return getAssignmentTemplateQuery().getResource();
  }

  generateSaveButton() {
    return (
      <Button
        disabled={
          templateAssignmentStageStore.isCloning() || templateAssignmentStageStore.hasCloned()
        }
        large
        onClick={handleSaveToTemplates}
      >
        {templateAssignmentStageStore.hasCloned() ? 'Saved!' : 'Save'}
      </Button>
    );
  }

  handleViewClick = () => {
    callAction(teacherAssignmentTemplateQuestionsListActions.SELECT_FIRST_QUESTION);
  };

  generateSourcesSection() {
    const subjects = this.getAssignmentTemplate()
      .getQuestionSets()
      .reduce((acc, questionSet) => {
        return acc.includes(questionSet.getSubject()) ? acc : acc.add(questionSet.getSubject());
      }, Set());

    return (
      <div className='assignment-stage__detail-card assignment-stage__detail-card--full-width card'>
        <h4>Sources:</h4>
        {subjects.map((subject) => {
          return (
            <span key={subject.getId()} className='assignment-stage__detail-content'>
              {subject.getName()}
            </span>
          );
        })}
      </div>
    );
  }

  render() {
    if (getAssignmentTemplateQuery().isResourcePopulated() === false) {
      return null;
    }

    const template = this.getAssignmentTemplate();

    return (
      <div className='max-width'>
        <div className='assignment-stage'>
          <div className='assignment-stage__main-content'>
            <div className='assignment-stage__image-wrapper card card--no-padding' />
            <div className='card assignment-stage-card'>
              <div className='assignment-stage-card__header'>
                <h1>{template.getName()}</h1>
                FOLDER
              </div>
              <div className='assignment-stage-card__subheader'>
                Created By {template.getMeta().getTeacherSalutation()}{' '}
                {template.getMeta().getTeacherLastName()}
              </div>
              <div className='assignment-stage__summary-wrapper'>
                <div className='assignment-stage__summary'>
                  <div className='assignment-stage__summary-card card'>
                    <h4>Content:</h4>
                    <span className='assignment-stage__summary-content'>
                      {template.getTotalQuestions()}
                    </span>
                    <span className='assignment-stage__summary-footer'>Questions</span>
                  </div>
                  <div className='assignment-stage__summary-card card'>
                    <h4>Last Modified:</h4>
                    <span className='assignment-stage__summary-content'>
                      {renderMoment(template.getUpdatedAt(), 'dateFormal')}
                    </span>
                    <span className='assignment-stage__summary-footer'>
                      {renderMoment(template.getUpdatedAt(), 'time')}
                    </span>
                  </div>
                </div>
                <hr />
              </div>
              <div className='assignment-stage__details-wrapper'>
                <div className='assignment-stage__details'>{this.generateSourcesSection()}</div>
              </div>
            </div>
          </div>
          <div className='assignment-stage__footer-content'>
            {this.generateSaveButton()}
            <Button color='green' large onClick={this.handleViewClick}>
              View
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
