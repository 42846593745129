import React, {useContext, useState} from 'react';
import PropTypes from 'prop-types';

import {Button, Dialogue, Modal as AtomicModal, TextField} from '@albert-io/atomic';
import {resource} from '@albert-io/json-api-framework/request/builder';

import {useStudentDashboard} from '../StudentDashboard.context';

import './join-code-form.scss';

const JoinCodeFormContext = React.createContext();

const JoinCodeForm = ({children, ...rest}) => {
  const {classrooms, enrollStudent, isRequestPending, setDuplicateClassroom} =
    useStudentDashboard();

  const [error, setError] = useState(false);
  const [value, setValue] = useState('');

  async function handleSubmit() {
    const relationship = {
      meta: {
        classroom_code: value.trim()
      },
      type: 'classroom_v1'
    };
    enrollStudent({
      relationship,
      onReject: async (err) => {
        try {
          if (err.response?.status === 422) {
            const classWithMatchingCode = classrooms.find(
              (classroom) => classroom.getCode() === value
            );
            if (classWithMatchingCode) {
              setDuplicateClassroom(classWithMatchingCode);
              return;
            }
            const classWithMatchingCodeFromServer = await resource('student_classroom_v1')
              .mandarkEndpoint(['student_classrooms_v1'])
              .findOne()
              .filter({code: value})
              .getResourcePromise();
            setDuplicateClassroom(classWithMatchingCodeFromServer);
            return;
          }
          throw new Error('Invalid enrollment code');
        } catch (e) {
          setError(true);
        }
      }
    });
  }

  return (
    <JoinCodeFormContext.Provider
      value={{
        error,
        handleSubmit,
        setError,
        setValue,
        value
      }}
    >
      <form
        {...rest}
        onSubmit={(e) => {
          e.preventDefault();
          if (isRequestPending || error || value === '') {
            return;
          }
          handleSubmit();
        }}
      >
        {children}
      </form>
    </JoinCodeFormContext.Provider>
  );
};

const Input = (props) => {
  const {isRequestPending} = useStudentDashboard();
  const {error, setError, setValue, value} = useContext(JoinCodeFormContext);
  return (
    <TextField
      aria-label='Enrollment code'
      border
      className='u-mar-b_1'
      disabled={isRequestPending}
      error={error}
      id='enrollmentCodeField'
      message={
        error
          ? "We didn't recognize that code. Please verify it with your teacher and try again."
          : null
      }
      onChange={(e) => {
        setError(false);
        setValue(e.target.value);
      }}
      type='text'
      value={value}
      {...props}
    />
  );
};

const SubmitBtn = (props) => {
  const {isRequestPending} = useStudentDashboard();
  const {error, value, handleSubmit} = useContext(JoinCodeFormContext);
  return (
    <Button
      aria-label='Submit code'
      disabled={isRequestPending || error || value === ''}
      onClick={handleSubmit}
      type='button'
      {...props}
    >
      Join class
    </Button>
  );
};

const Modal = ({disabled, handleClose, onSubmit}) => {
  return (
    <AtomicModal ariaLabel='Join a class' dismissable handleClose={handleClose} role='dialog'>
      {({CloseButtonWrapper, modalContentStyle}) => (
        <Dialogue className={modalContentStyle} handleClose={handleClose} hideCloseBtn inModal>
          <JoinCodeForm disabled={disabled} onSubmit={onSubmit}>
            <Dialogue.Body className='u-display_flex u-flex-direction_column'>
              <JoinCodeForm.Input label='Enter enrollment code:' />
            </Dialogue.Body>
            <div className='join-code-form__modal__btn-group'>
              <CloseButtonWrapper>
                <Button
                  className='join-code-form__modal__btn-group__btn join-code-form__modal__btn-group__btn--cancel'
                  color='secondary'
                >
                  Cancel
                </Button>
              </CloseButtonWrapper>
              <JoinCodeForm.SubmitBtn className='join-code-form__modal__btn-group__btn' />
            </div>
          </JoinCodeForm>
        </Dialogue>
      )}
    </AtomicModal>
  );
};

Modal.propTypes = {
  disabled: PropTypes.bool,
  handleClose: PropTypes.func,
  onSubmit: PropTypes.func
};

JoinCodeForm.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func
};

JoinCodeForm.Input = Input;
JoinCodeForm.Modal = Modal;
JoinCodeForm.SubmitBtn = SubmitBtn;
export default JoinCodeForm;
