import React from 'react';

import TextTypeInput from '../common/TextTypeInput.react';

// This component is essentially just TextTypeInput with textElementTagName set to 'input' and all other props passed through.
// Until TextTypeInput is converted to TS, this file cannot be meaningfully typed.
// I'm going to ignore errors caused by this file until TextTypeInput is migrated.
export default function TextInput(props: any) {
  return <TextTypeInput {...props} textElementTagName='input' />;
}
