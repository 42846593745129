/* eslint-disable import/prefer-default-export */
import React from 'react';
import {Badge} from '@albert-io/atomic';

/**
 * @example
 * [badge]Topic[/badge]
 * [badge color=<color>]Topic[/badge]
 */
const regex =
  /^\[badge(?:\s+color=(passive|emphasized|progress|success|danger))?]((?:[\w\W](?!\[\/badge\]))+[\w\W])\[\/badge\]/;

const COLOR_TO_STATUS = {
  passive: 'neutral',
  emphasized: 'neutral',
  progress: 'info',
  success: 'positive',
  danger: 'negative'
};

export const badgeRule = {
  order: 0.1,
  match: (source) => regex.exec(source),
  parse: (capture, recurseParse, state) => {
    // eslint-disable-next-line no-unused-vars
    const [_, color, content] = capture;
    return {
      content: recurseParse(content, state),
      color
    };
  },

  react(node, output) {
    const status = COLOR_TO_STATUS[node.color || 'progress'];
    return (
      <Badge emphasis='bold' key={node.tokenId} status={status}>
        {output(node.content)}
      </Badge>
    );
  }
};
