import React from 'react';
import SimpleMarkdown from 'simple-markdown';
import {getAllowedAttributes, getReactOrHTMLElement} from './htmlRules.utils';
import {isValidHTMLElement} from 'lib/isValidHTMLElement';

/* eslint-disable max-len */
//                                    List of approved empty elements
//                                                   |         Any character but > not followed by two empty lines 0 more times (i.e. the attributes)
//                                                   |                                     ________|________
//                                                   V                                    |                 |
const emptyEls = /^<(area|base|br|col|embed|hr|img|input|link|meta|param|source|track|wbr)((?:[^>](?!\n\n))*)>/i;

// Any elements we find that don't match the above elements will be treated as inline elements
//                  Tag Name
//                     |       Attributes
//                     |           |                Content (capture group of any character not followed by a matching closing tag any number of times [not greedy], followed by the closing tag)
//                     |           |                   |      Closing Tag
//                     |    _______|_______     _______|_______    |
//                     v   |               |   |               |   v
const inlineEls = /^<(\w+)((?:[^>](?!\n\n))*)>((?:[^](?!<\1>))*?)<\/\1>/;
// Same rules as above, but Tag Name is an explicitly defined list of possible tags
const blockEls = /^<(address|article|aside|blockquote|canvas|center|dd|div|dl|dt|fieldset|figcaption|figure|form|h1|h2|h3|h4|h5|h6|header|hgroup|hr|iframe|li|main|nav|noscript|ol|output|p|pre|section|table|tfoot|ul|video)((?:[^>](?!\n\n))*)>((?:[^](?!<\1>))*?)<\/\1>/;

/* eslint-enable max-len */

export const codeHtmlTagRule = {
  order: -2,
  match: (source) => /^<code>([\s\S]*)<\/code>/.exec(source),
  parse: (capture) => {
    return {
      content: capture[1]
    };
  },
  react(node, output, state) {
    return React.createElement('code', {key: state.key}, node.content);
  },
  html: (node) => {
    return `<code>${node.content}</code>`;
  }
};

export const emptyHtmlTagRule = {
  order: 0.3,
  match: (source) => emptyEls.exec(source),
  parse: (capture) => {
    return {
      tagName: capture[1].toLowerCase(),
      attributes: capture[2]
    };
  },
  react: (node, output, state) => {
    const tagName = node.tagName;
    const props = node.attributes ? getAllowedAttributes(node.attributes) : {};
    props.key = state.key;
    return isValidHTMLElement(tagName)
      ? React.createElement(tagName, props)
      : React.createElement('span', props);
  },
  html: (node) => {
    const tagName = node.tagName;
    return isValidHTMLElement(tagName) ? `<${tagName} />` : '<span />';
  }
};

export const blockHtmlRule = {
  order: -1,
  match: (source) => blockEls.exec(source),
  parse: (capture, recurseParse, state) => {
    const tagName = capture[1].toLowerCase();
    return {
      content: SimpleMarkdown.parseBlock(recurseParse, capture[3], state),
      tagName: tagName,
      attributes: capture[2]
    };
  },
  react: (node, output) => {
    return getReactOrHTMLElement(node, output);
  },
  html: (node, output) => {
    return getReactOrHTMLElement(node, output, true);
  }
};

export const inlineHtmlRule = {
  order: SimpleMarkdown.defaultRules.paragraph.order + 0.25,
  match: (source) => inlineEls.exec(source),
  parse: (capture, recurseParse, state) => {
    const tagName = capture[1].toLowerCase();
    return {
      content: SimpleMarkdown.parseInline(recurseParse, capture[3], state),
      tagName: tagName,
      attributes: capture[2]
    };
  },
  react: (node, output) => {
    return getReactOrHTMLElement(node, output);
  },
  html: (node, output) => {
    return getReactOrHTMLElement(node, output, true);
  }
};
