// @flow
import {Map} from 'immutable';
import PropTypes from 'prop-types';
import {difference, hasIn, isPlainObject, isArray, cloneDeep, unset, mergeWith} from 'lodash';
import validator from 'validator';

import {
  flagResourceErrorHandled,
  getResource,
  getResourceMetadata,
  getResourcePromise,
  invalidateInterest,
  isResourceErrorHandled,
  isResourcePopulated,
  isResourceReady,
  isResourceValid,
  buildEndpoint
} from 'resources/mandark.resource';
import {getModel, getCollectionModel} from 'resources/modelRegistry';

import {getSingularForm} from '../../models/registry/resources';

/**
 * @typedef {{
 *   buildEndpoint: function(): string,
 *   csv: function(): QueryBuilder,
 *   customQuery: function(): QueryBuilder,
 *   done: function(): Object,
 *   fields: function(): QueryBuilder,
 *   filter: function(): QueryBuilder,
 *   findOne: function(): QueryBuilder,
 *   getResource: function(): mixed,
 *   getResourcePromise: function(): Promise<*>,
 *   include: function(): QueryBuilder,
 *   invalidateInterest: function(): mixed,
 *   isResourcePopulated: function(): boolean,
 *   isResourceReady: function(): boolean,
 *   isResourceValid: function(): boolean,
 *   mandarkEndpoint: function(): QueryBuilder,
 *   meta: function(): QueryBuilder,
 *   metaContext: function(): QueryBuilder,
 *   unset: function(): QueryBuilder,
 *   page: function(): QueryBuilder,
 *   pageSize: function(): QueryBuilder,
 *   skipValidation: function(): QueryBuilder,
 *   sort: function(): QueryBuilder,
 *   with: function(): QueryBuilder,
 *   withMeta: function(): QueryBuilder
 * }} QueryBuilder
 */

export type QueryBuilder = {
  buildEndpoint: () => string,
  csv: () => QueryBuilder,
  customQuery: () => QueryBuilder,
  done: () => Object,
  fields: () => QueryBuilder,
  filter: () => QueryBuilder,
  findOne: () => QueryBuilder,
  getResource: () => mixed,
  getResourcePromise: () => Promise<*>,
  include: () => QueryBuilder,
  invalidateInterest: () => mixed,
  isResourcePopulated: () => boolean,
  isResourceReady: () => boolean,
  isResourceValid: () => boolean,
  mandarkEndpoint: () => QueryBuilder,
  meta: () => QueryBuilder,
  metaContext: () => QueryBuilder,
  unset: () => QueryBuilder,
  page: () => QueryBuilder,
  pageSize: () => QueryBuilder,
  skipValidation: () => QueryBuilder,
  sort: () => QueryBuilder,
  with: () => QueryBuilder,
  withMeta: () => QueryBuilder
};

function customizer(objValue, srcValue) {
  /**
   * Array concatenation will prevent lists of same-key values from having elements be replaced.
   */
  if (isArray(objValue)) {
    return objValue.concat(srcValue);
  }
  // When undefined is returned, this behaves like a normal merge
  return undefined;
}

/**
 * @param obj
 */
function mergeSpecial(obj): Function {
  return (merger) => {
    return mergeWith(merger, obj, customizer);
  };
}

function filter(obj): Function {
  return (filterParam) => query(obj).with({filter: filterParam}).done();
}

function findOne(obj: Object): Function {
  return () =>
    query(obj)
      .with({resourcePath: obj.resourcePath.unshift('lookup')})
      .done();
}

function customQuery(obj: Object): Function {
  return (queryParam) => query(obj).with({customQuery: queryParam}).done();
}

function customHeader(obj: Object): Function {
  return (header) => query(obj).with({customHeader: header}).done();
}

function skipValidation(obj: Object): Function {
  return (validation) => query(obj).with({skipValidation: validation}).done();
}

function fields(obj: Object): Function {
  return (fields) => customQuery(obj)({fields});
}

function page(obj: Object): Function {
  return (page) => {
    let pageQuery;
    if (validator.isUUID(page.toString())) {
      pageQuery = {id: page};
    } else if (isPlainObject(page)) {
      pageQuery = page;
    } else {
      pageQuery = {page};
    }
    return customQuery(obj)({page: pageQuery});
  };
}

function pageSize(obj: Object): Function {
  return (pageSize) => customQuery(obj)({page: {page_size: pageSize}});
}

function sort(obj: Object): Function {
  return (sortParam) => customQuery(obj)({sort: sortParam});
}

function mandarkEndpoint(obj: Object): Function {
  return (resourcePath) => query(obj).with({resourcePath}).done();
}

function csv(obj: Object): Function {
  return (customMeta) => customQuery(obj)({csv: customMeta});
}

function meta(obj: Object): Function {
  return (customMeta) => customQuery(obj)({meta: customMeta});
}

function withMeta(obj: Object): Function {
  return (str) => customQuery(obj)({with_meta: str});
}

function applyUnset(obj) {
  return (path) => {
    const tempObj = cloneDeep(obj);
    unset(tempObj, path);
    return tempObj;
  };
}

// E.g.
// {
//   subject_v2: {
//     student_id: <id>
//   }
// }
function metaContext(obj: Object): Function {
  return (contextObj) => {
    if (!obj.Model) {
      throw new Error(
        `No model type specified for this query! Did you start your chain with resource(<type>)?`
      );
    }
    const builtMetaContext = {};
    for (const resourceType in contextObj) {
      const ResourceModel = getModel(new Map({jsonapi_type: resourceType}));
      if (!ResourceModel) {
        throw new Error(`Could not resolve model for type ${resourceType}`);
      }

      for (const metaContextKey in contextObj[resourceType]) {
        const queryString = ResourceModel.getMetaContextQuery(metaContextKey);
        if (!queryString) {
          throw new Error(
            `Could not find named metacontext key ${metaContextKey} for resource type ${resourceType}`
          );
        }
        builtMetaContext[queryString] = contextObj[resourceType][metaContextKey];
      }
    }

    return meta(obj)({context: builtMetaContext});
  };
}

function includes(obj: Object): Function {
  return (str) =>
    obj.include ? {...obj, include: `${obj.include},${str}`} : {...obj, include: str};
}

function invoke(obj, params, condition): Function {
  condition = arguments.length === 3 ? Boolean(condition) : true;

  const value = condition ? params : obj;

  return (invokedFunction) => (value === obj ? query(value) : query(invokedFunction(value)));
}

function handleError(obj) {
  if (!obj.hasOwnProperty('_errorHandler') || isResourceValid(obj)) {
    return;
  }

  if (!isResourceErrorHandled(obj, obj._errorHandler.handlerId)) {
    obj._errorHandler.cb(getResource(obj));
    flagResourceErrorHandled(obj, obj._errorHandler.handlerId);
  }
}

export function query(obj = {}): QueryBuilder {
  return {
    /* eslint-disable sort-keys */
    with: (...args) => invoke(obj, ...args)(mergeSpecial(obj)),
    filter: (...args) => invoke(obj, ...args)(filter(obj)),
    findOne: (...args) => invoke(obj, ...args)(findOne(obj)),
    customQuery: (...args) => invoke(obj, ...args)(customQuery(obj)),
    fields: (...args) => invoke(obj, ...args)(fields(obj)),
    page: (...args) => invoke(obj, ...args)(page(obj)),
    pageSize: (...args) => invoke(obj, ...args)(pageSize(obj)),
    sort: (...args) => invoke(obj, ...args)(sort(obj)),
    mandarkEndpoint: (...args) => invoke(obj, ...args)(mandarkEndpoint(obj)),
    csv: (...args) => invoke(obj, ...args)(csv(obj)),
    meta: (...args) => invoke(obj, ...args)(meta(obj)),
    withMeta: (...args) => invoke(obj, ...args)(withMeta(obj)),
    metaContext: (...args) => invoke(obj, ...args)(metaContext(obj)),
    include: (...args) => invoke(obj, ...args)(includes(obj)),
    customHeader: (...args) => invoke(obj, ...args)(customHeader(obj)),
    unset: (...args) => invoke(obj, ...args)(applyUnset(obj)),
    skipValidation: (...args) => invoke(obj, ...args)(skipValidation(obj)),
    done: () => obj,
    handleError: (handlerId, cb) => {
      return query({...obj, _errorHandler: {handlerId, cb}});
    },
    getResource: () => {
      validate(obj);
      handleError(obj);
      return obj.defaultModel && !isResourcePopulated(obj) ? obj.defaultModel : getResource(obj);
    },
    getResourceMetadata: () => validate(obj) && getResourceMetadata(obj),
    getResourcePromise: async () => {
      validate(obj);
      try {
        return await getResourcePromise(obj);
      } catch (error) {
        throw error;
      } finally {
        handleError(obj);
      }
    },
    invalidateInterest: () => validate(obj) && invalidateInterest(obj),
    isResourcePopulated: () => validate(obj) && isResourcePopulated(obj),
    isResourceReady: () => validate(obj) && isResourceReady(obj),
    isResourceValid: () => validate(obj) && isResourceValid(obj),
    equals: (objToCompare) => buildEndpoint(obj) === buildEndpoint(objToCompare.done()),
    buildEndpoint: () => buildEndpoint(obj)
    /* eslint-enable sort-keys */
  };
}

function validate(obj): boolean {
  if (!obj.Model || process.env.NODE_ENV === 'production') {
    return true;
  }

  // if (!validateIncludes(obj)) {
  //   return false;
  // }

  if (!validateSorts(obj)) {
    return false;
  }

  // if (!validateFilters(obj)) {
  //   return false;
  // }

  if (!validateMeta(obj)) {
    return false;
  }

  return true;
}

// function validateIncludes(obj: Object): boolean {
//   if (!obj.include) {
//     return true;
//   }

//   for (const include of obj.include.split(',')) {
//     if (!obj.Model.allowsInclude(include)) {
//       throw new Error(`Model ${obj.Model.getModelName()} does not allow including ${include}!`);
//     }
//   }

//   return true;
// }

// XXX Fix for sorts on included types
function validateSorts(obj: Object): boolean {
  if (
    !obj.customQuery ||
    !obj.customQuery.sort ||
    (obj.skipValidation && obj.skipValidation.sort)
  ) {
    return true;
  }

  if (!obj.Model.allowsSort(obj.customQuery.sort.replace(/-/g, ''))) {
    throw new Error(
      `Model ${obj.Model.getModelName()} does not allow sorting by ${obj.customQuery.sort}!`
    );
  }

  return true;
}

function flattenLogicalFilters(filters = {}): Object {
  const filterKeys = Object.keys(filters);
  const logicalFilters = ['any_of', 'all_of', 'none_of'];
  const hasLogicalFilters = filterKeys.some((key) => logicalFilters.includes(key));
  if (!hasLogicalFilters) {
    return filters;
  }

  const {any_of, all_of, none_of, ...rest} = filters;

  const mergedLogicalFilters = [any_of, all_of, none_of].reduce((acc, filterArr) => {
    if (!filterArr) {
      return acc;
    }
    const currentFilters = filterArr.reduce((acc, val) => Object.assign(acc, val), {});
    return Object.assign(acc, currentFilters);
  }, {});
  return flattenLogicalFilters({
    ...rest,
    ...mergedLogicalFilters
  });
}

// function validateFilters(obj: Object): boolean {
//   if (!obj.filter) {
//     return true;
//   }

//   const baseFilters = flattenLogicalFilters(omit(obj.filter, 'included'));

//   const includedFilter = obj.filter.included || {};
//   if (Object.keys(baseFilters).length === 0 && Object.keys(includedFilter).length === 0) {
//     return true;
//   }

//   for (const filter in baseFilters) {
//     // Does not specify a type
//     if (typeof baseFilters[filter] === 'string') {
//       if (!obj.Model.allowsFilter(filter)) {
//         throw new Error(`Model ${obj.Model.getModelName()} does not allow filtering by ${filter}!`);
//       }
//     } else {
//       for (const filterType in baseFilters[filter]) {
//         if (!obj.Model.allowsFilter(filter, filterType)) {
//           throw new Error(`Model ${obj.Model.getModelName()} does not allow filtering by ${filterType} on ${filter}!`);
//         }
//       }
//     }
//   }

//   for (const resourceType in includedFilter) {
//     const includedModelType = getSingularForm(resourceType);
//     const IncludedModel = getModel(new Map({jsonapi_type: includedModelType}));
//     if (!IncludedModel || !IncludedModel.allowsFilter) {
//       break;
//     }
//     for (const filter in includedFilter[resourceType]) {
//       // Does not specify a type
//       if (typeof includedFilter[resourceType][filter] === 'string') {
//         if (!IncludedModel.allowsFilter(filter)) {
//           throw new Error(`Model ${obj.Model.getModelName()} does not allow filtering included resource ${resourceType} by ${filter}!`);
//         }
//       } else {
//         for (const filterType of Object.keys(includedFilter[resourceType][filter])) {
//           if (!IncludedModel.allowsFilter(filter, filterType)) {
//             throw new Error(`Model ${obj.Model.getModelName()} does not allow filtering included resource ${resourceType} by ${filterType} on ${filter}`);
//           }
//         }
//       }
//     }
//   }

//   return true;
// }

function validateMeta(obj: Object): boolean {
  if (!obj.customQuery || !obj.customQuery.with_meta) {
    return true;
  }

  const requestedMetas = obj.with_meta ? obj.with_meta.split(',') : [];
  const includedResourceTypes = obj.include
    ? obj.include.split(',').map((include) => getSingularForm(include))
    : [];

  // Ensure we're not requestiong meta for something we're not actually including
  const missingIncludes = difference(requestedMetas, includedResourceTypes);
  if (missingIncludes.length > 0) {
    throw new Error(
      `Model ${obj.Model.getModelName()} requested metas for the following resources which were never included: ${missingIncludes}`
    );
  }

  // Ensure any required meta context is specified for the relevant resource
  const RequestedMetaModels = requestedMetas.map((resourceType) =>
    getModel(new Map({jsonapi_type: getSingularForm(resourceType)}))
  );
  for (const Model of RequestedMetaModels) {
    const requiredMeta = Model.getRequiredMetaContextRules();
    for (const metaPiece of requiredMeta) {
      if (!hasIn(obj, metaPiece)) {
        throw new Error(
          `Model ${obj.Model.getModelName()} is missing required meta context ${metaPiece}`
        );
      }
    }
  }

  return true;
}

export function resource(resourceType: string): QueryBuilder {
  const singularType = getSingularForm(resourceType);
  const Model = getModel(new Map({jsonapi_type: singularType}));
  let defaultModel;
  if (resourceType === singularType) {
    defaultModel = Model.getDefaultModel();
  } else {
    const CollectionModel = getCollectionModel(resourceType);
    defaultModel = new CollectionModel();
  }
  return query({defaultModel, Model});
}

export const queryBuilderPropType = PropTypes.shape({
  csv: PropTypes.func,
  customQuery: PropTypes.func,
  done: PropTypes.func,
  fields: PropTypes.func,
  filter: PropTypes.func,
  findOne: PropTypes.func,
  getResource: PropTypes.func,
  getResourcePromise: PropTypes.func,
  include: PropTypes.func,
  isResourcePopulated: PropTypes.func,
  isResourceReady: PropTypes.func,
  isResourceValid: PropTypes.func,
  meta: PropTypes.func,
  metaContext: PropTypes.func,
  page: PropTypes.func,
  pageSize: PropTypes.func,
  sort: PropTypes.func,
  skipValidation: PropTypes.func,
  with: PropTypes.func,
  withMeta: PropTypes.func
});
