// @flow
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';
import Tooltip from 'generic/Tooltip.react';
import sessionStore from 'client/Session/SessionStore';
import {hasSubjectAccess, getSubjectSubscription} from 'lib/UserAccessUtil';
import './access-label.scss';

export default class AccessLabel extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    subjectId: PropTypes.string,
    isSubscriptionLabel: PropTypes.bool
  };

  static defaultProps = {
    isSubscriptionLabel: false
  };

  generateUnlockedLabel(): React.Element {
    return (
      <span className={classnames('access-label', this.props.className)}>
        <span className='fa fa-unlock-alt' />
      </span>
    );
  }

  render(): React.Element {
    if (this.props.isSubscriptionLabel === false) {
      return this.generateUnlockedLabel();
    }

    if (sessionStore.hasValidSession() === false) {
      return null;
    }

    const subjectId = this.props.subjectId;

    /**
     * The access label will only be rendered when the underlying request governing access is
     * completed or 'isReady'.
     *
     * The user may still not have access, so we use the returned predicate `value` from the util
     * to determine what to state to put the access label into.
     */
    const {isReady, value} = hasSubjectAccess(subjectId);
    if (isReady !== true) {
      return null;
    }

    let tooltipContent = 'Upgrade to access all content for this subject';
    if (value) {
      const subscription = getSubjectSubscription(subjectId);
      if (subscription) {
        const end: moment = getSubjectSubscription(subjectId).getEndDate();
        tooltipContent = `Subject subscription ends on ${end.format('MMM Do[,] YYYY')}`;
      } else {
        tooltipContent = `You have access to this subject based on a classroom you are ${
          sessionStore.hasTeacherAccess() ? 'teaching' : 'enrolled in'
        }`;
      }
    }

    const className = classnames(
      'access-label',
      'access-label--subscription',
      this.props.className
    );
    return (
      <span className={className} id='access-label--subscription'>
        <span className={`fa fa-${value ? 'unlock' : 'lock'}`} />
        {value ? 'Full access' : 'Limited access'}
        <Tooltip
          triggerId='access-label--subscription'
          tooltipPosition='bottom'
          content={tooltipContent}
        />
      </span>
    );
  }
}
