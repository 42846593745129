import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {debounce} from 'lodash';
import {List, Map} from 'immutable';
import classnames from 'classnames';
import {queryBuilderPropType} from '@albert-io/json-api-framework/request/builder';
import {Button, CheckboxChip, LegacyFindAndApply} from '@albert-io/atomic';
import LoadingIndicator from 'generic/LoadingIndicator.react';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {phrasesRegex} from 'client/components/AdvancedSearch/AdvancedSearch.utils';
import './sub-search.scss';

/**
 * SubSearch
 * ===
 *
 * SubSearch represents a LegacyFindAndApply instance to which we provide a set of default props,
 * including the formatted selected items, onSelect, onReset, and onClear behavior as specced out
 * in the requirements.
 *
 * Upon each onSearchSubmit, the component initiates a request to Mandark
 * for the resources that match our given phrases and key words.
 *
 * Selected items are recorded in our state.  LegacyFindAndApply references this state when determining
 * which entities to mark as selected or not, as well as which entities to forward back to the user
 * when it comes time to apply the entities' IDs as filters on our larger search.
 */

export default class SubSearch extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    defaultSelectedItems: ImmutablePropTypes.map,
    filters: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    onApply: PropTypes.func,
    onCancel: PropTypes.func,
    queryFunc: PropTypes.func.isRequired,
    renderOptionalHeaderFunc: PropTypes.func,
    renderStagingFunc: PropTypes.func.isRequired,
    renderEmptyResultsFunc: PropTypes.func.isRequired,
    renderResultsFunc: PropTypes.func.isRequired,
    leftFooterBtnFunc: PropTypes.func,
    queryKeyword: PropTypes.string
  };

  static defaultProps = {
    defaultSelectedItems: Map(),
    filters: {},
    renderEmptyResultsFunc: () => <LegacyFindAndApply.NoResultsMessage />,
    renderOptionalHeaderFunc: () => {}
  };

  constructor(props) {
    super(props);

    this.state = {
      defaultSelectedItems: props.defaultSelectedItems,
      searchString: '',
      results: List()
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const {searchString} = this.state;
    const {queryKeyword} = this.props;
    if (
      (searchString && prevState.searchString !== searchString) ||
      prevProps.queryKeyword !== queryKeyword
    ) {
      this.props
        .queryFunc(searchString)
        .getResourcePromise()
        .then((results) => this.setState({results}));
    }
  }

  handleCancel = () => {
    this.props.onCancel();
    this.setState({defaultSelectedItems: this.props.defaultSelectedItems});
  };

  handleCheck = (e, selectedItems, currentItem) => {
    const id = currentItem.getId();
    const newSelectedItems = e.target.checked
      ? selectedItems.set(id, currentItem)
      : selectedItems.delete(id);
    return {
      selectedItems: newSelectedItems
    };
  };

  handleChange = (e) => this.debouncedHandleChange(e.target.value);

  debouncedHandleChange = debounce((searchString) => this.setState({searchString}), 750);

  handleSearchClear = () =>
    this.setState({
      results: List(),
      searchString: ''
    });

  handleReset = () => this.setState({defaultSelectedItems: Map()});

  getSearchPhrases() {
    return this.state.searchString.match(phrasesRegex).join(',');
  }

  render() {
    const {results, searchString} = this.state;
    return (
      <LegacyFindAndApply
        className={classnames('sub-search', this.props.className)}
        customOnChange={this.handleChange}
        customOnCheck={this.handleCheck}
        customOnClear={this.handleSearchClear}
        customOnConfirm={this.props.onApply}
        customOnReset={this.handleReset}
        defaultSelectedItems={this.state.defaultSelectedItems}
      >
        <LegacyFindAndApply.Header>
          {(context) =>
            this.props.renderOptionalHeaderFunc({
              ...context,
              results,
              searchString
            })
          }
        </LegacyFindAndApply.Header>
        <LegacyFindAndApply.Body paddingLevel={!results.isEmpty() ? 0 : 2}>
          {(context) => {
            if (!searchString && !this.props.queryKeyword) {
              return this.props.renderStagingFunc({
                ...context,
                results,
                searchString
              });
            }
            if (!this.props.queryFunc(searchString).isResourcePopulated()) {
              return <LoadingIndicator />;
            }
            if (results.isEmpty()) {
              return this.props.renderEmptyResultsFunc({
                ...context,
                results,
                searchString
              });
            }
            return this.props.renderResultsFunc({
              ...context,
              results,
              searchString
            });
          }}
        </LegacyFindAndApply.Body>
        <LegacyFindAndApply.Footer>
          {({onCheck, onConfirm, onReset, selectedItems}) => (
            <>
              <LegacyFindAndApply.SelectedItems>
                {selectedItems.valueSeq().map((item, i) => (
                  <CheckboxChip
                    checked={selectedItems.has(item.getId())}
                    onChange={(e) => onCheck(e, item)}
                    icon='tag'
                    key={i}
                  >
                    <MarkdownRendererV2
                      text={
                        item.constructor.name.includes('Tag') ? item.getName() : item.getTitle()
                      }
                    />
                  </CheckboxChip>
                ))}
              </LegacyFindAndApply.SelectedItems>
              <LegacyFindAndApply.BtnGroup>
                {this.props.leftFooterBtnFunc &&
                  this.props.leftFooterBtnFunc({
                    results
                  })}
                <Button color='secondary' onClick={this.handleCancel}>
                  Cancel
                </Button>
                <Button color='secondary' disabled={selectedItems.isEmpty()} onClick={onReset}>
                  Clear
                </Button>
                <Button onClick={onConfirm} data-testid='sub-search__apply-btn'>
                  Apply{!selectedItems.isEmpty() ? ` (${selectedItems.size})` : ''}
                </Button>
              </LegacyFindAndApply.BtnGroup>
            </>
          )}
        </LegacyFindAndApply.Footer>
      </LegacyFindAndApply>
    );
  }
}
