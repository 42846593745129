import React from 'react';
import PropTypes from 'prop-types';
import notifier from '@albert-io/notifier';
import {genericMandarkRequest} from 'resources/mandark.resource';
import {resource} from '@albert-io/json-api-framework/request/builder';

import Text from '@albert-io/atomic/atoms/Text/Text.react';

import CoppaMessage from './CoppaMessage';
import CoppaError from './CoppaError.react';
import HandleCoppa from './HandleCoppa.react';

export default class CoppaReject extends React.Component {
  static propTypes = {
    verificationCode: PropTypes.string.isRequired,
    consentId: PropTypes.string.isRequired
  };

  onError = (e) => {
    notifier.notify(e, {
      component: 'CoppaApprove',
      verificationCode: this.props.verificationCode,
      consentId: this.props.consentId
    });
  };

  render() {
    const query = () =>
      genericMandarkRequest(
        'delete',
        resource('coppa_consent_grant_v1')
          .mandarkEndpoint(['coppa_consent_grants_v1', this.props.consentId, 'reject_coppa'])
          .customQuery({
            verification_code: this.props.verificationCode
          })
          .done()
      );

    const successComponent = (
      <CoppaMessage>
        <Text paragraph as='p'>
          You have declined your child's request to use Albert. We have deleted their account.
        </Text>
        <Text paragraph as='p'>
          You may close this page.
        </Text>
      </CoppaMessage>
    );

    const errorComponent = <CoppaError />;

    return (
      <HandleCoppa
        query={query}
        successComponent={successComponent}
        errorComponent={errorComponent}
        onError={this.onError}
      />
    );
  }
}
