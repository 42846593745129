import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {List} from 'immutable';
import {renderMoment} from 'lib/timeUtils';
import LoadingIndicator from 'generic/LoadingIndicator.react';
import awaitMandarkQueries from 'lib/hocs/awaitMandarkQueries';
import IconTooltip from 'sg/IconTooltip/IconTooltip.react';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {getAuthoringFeedbackQuery} from '../shared';
import {AuthoringFeedbackModelV1} from 'resources/GeneratedModels/AuthoringFeedback/AuthoringFeedbackModel.v1';

import './authoring-feedback-messages.scss';

class AuthoringFeedbackMessageListItem extends React.PureComponent {
  static propTypes = {
    feedback: PropTypes.instanceOf(AuthoringFeedbackModelV1)
  };

  generateEmailNotificationIndicator() {
    const sent = Boolean(this.props.feedback.getSentEmailAt());
    let text = 'An email notification has not been sent for this feedback.';
    let icon = 'fa-envelope-o';
    if (sent) {
      text = `An email notification for this feedback was sent at: ${renderMoment(
        this.props.feedback.getSentEmailAt()
      )}`;
      icon = 'fa-envelope';
    }
    return (
      <div
        className={classnames('authoring-feedback-message__notification-indicator', {
          'authoring-feedback-message__notification-indicator--not-sent': sent === false
        })}
      >
        <IconTooltip content={text} icon={icon} onHover={true} position='left' />
      </div>
    );
  }

  render() {
    return (
      <li className='authoring-feedback-message-list__item'>
        <div className='authoring-feedback-message'>
          {this.generateEmailNotificationIndicator()}
          <div>
            <MarkdownRendererV2 text={this.props.feedback.getMessage()} />
          </div>
          <div className='authoring-feedback-message__attribution'>
            <b>
              {this.props.feedback.getMeta().getUserFirstName()}{' '}
              {this.props.feedback.getMeta().getUserLastName()}
            </b>
            <br />
            <a href={`mailto:${this.props.feedback.getMeta().getUserEmail()}`}>
              {this.props.feedback.getMeta().getUserEmail()}
            </a>
            <br />
            {renderMoment(this.props.feedback.getInsertedAt())}
          </div>
        </div>
      </li>
    );
  }
}

export const AuthoringFeedbackMessages = awaitMandarkQueries(
  (props) => ({
    queries: {
      feedback: getAuthoringFeedbackQuery(props.questionId)
    },
    /**
     * Uses a custom `loadingElement` to account for the `min-height` provided
     * by `.authoring-feedback-messages`.
     */
    loadingElement: (
      <div>
        <LoadingIndicator />
      </div>
    )
  }),
  class extends React.Component {
    static propTypes = {
      questionId: PropTypes.string, // eslint-disable-line
      feedback: PropTypes.instanceOf(List)
    };
    render() {
      const feedback = this.props.feedback;
      if (feedback.size === 0) {
        return (
          <div>
            <div className='authoring-feedback-messages-empty'>
              There is no feedback for this question.
            </div>
          </div>
        );
      }
      return (
        <div className='authoring-feedback-messages'>
          <ul className='authoring-feedback-message-list'>
            {feedback.map((f) => (
              <AuthoringFeedbackMessageListItem key={f.getId()} feedback={f} />
            ))}
          </ul>
        </div>
      );
    }
  }
);
