import makeConstants from 'lib/makeConstants';
import {resource} from '@albert-io/json-api-framework/request/builder';
import sessionStore from 'client/Session/SessionStore';

export const promiseStatuses = makeConstants('pending', 'success', 'error');

export const getClassroomQuery = (classId) =>
  resource('classroom_v1').mandarkEndpoint(['classrooms_v1', classId]).include('school_v5');

export const getSchoolQuery = (schoolId: string) =>
  resource('schools_v5')
    .mandarkEndpoint(['schools_v5', schoolId])
    .include('school_licenses_v1.upgrades_v1');

export const getTeacherWithMetadataQuery = () => {
  return resource('teachers_v1')
    .mandarkEndpoint(['teachers_v1', sessionStore.getUserId()])
    .include('school_v5')
    .include('school_personnel_v1.school_v5');
};

export const getAllSchoolsUserIsAdminQuery = (adminId) =>
  resource('school_v5')
    .mandarkEndpoint(['schools_v5'])
    .filter({
      school_personnel_v1: {
        teacher_v1: {
          id: adminId
        },
        role_v1: {
          name: {
            not: 'teacher'
          }
        },
        status: 'approved'
      }
    })
    .sort('name')
    .withMeta('school_v5')
    .include('school_licenses_v1.upgrades_v1');
