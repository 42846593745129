import React, {ElementType} from 'react';

import Heading from '../../../atoms/Heading/Heading.react';
import './supertitle.scss';

export interface Props extends PropsWithChildrenRequired {
  as?: ElementType;
}

export const Supertitle = ({as, children, ...rest}: Props) => (
  <Heading size='2xs' className='o-wall-supertitle' as={as} {...rest}>
    {children}
  </Heading>
);
