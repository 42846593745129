import React from 'react';
import PropTypes from 'prop-types';
import {withRouter, Link} from 'react-router';
import {callAction} from 'client/framework';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {UpgradeButton} from 'generic/UpgradeSubjectButton/UpgradeSubjectButton.react';
import supplementActions from 'client/Supplement/SupplementActions';
import {hasSubjectAccess, doesUserBelongToAnyLicensedClassrooms} from 'lib/UserAccessUtil';
import {SubjectModelV2} from 'resources/GeneratedModels/Subject/SubjectModel.v2';
import LoadingIndicator from 'generic/LoadingIndicator.react';
import awaitMandarkQueries from 'lib/hocs/awaitMandarkQueries';
import {resource} from '@albert-io/json-api-framework/request/builder';
import Button from 'sg/Button/Button.react';
import sessionStore from 'client/Session/SessionStore';
import systemTimeOffsetStore from 'client/generic/SystemTimeOffset/SystemTimeOffset.store';
import {GuessModel} from 'resources/Guess/Guess.model';
import RestrictedQuestionMessage from 'client/InteractionEngineV2/shared/RestrictedQuestionMessage/RestrictedQuestionMessage.react';
import {QuestionSetModelV1} from 'resources/augmented/QuestionSet/QuestionSetModel.v1';
import {getQuestionEditPath} from 'client/Dennis/Content/Queue/shared';
import {TagOrStandardGroup} from 'client/components/TagOrStandardGroup/TagOrStandardGroup.react';
import {StandardChip} from 'client/QuestionTypes/common/V2/StandardChip.react';
import {showGlobalLoginModal} from 'client/LogIn/utils';

import {Anchor, Banner, Chip, Text, addGenericErrorToast} from '@albert-io/atomic';

import {FreeResponseQuestionSubTypeBadge} from '../FreeResponseQuestionSubTypeBadge/FreeResponseQuestionSubTypeBadge.react';
import {AddFRQToTemplateButton} from '../AddFRQToTemplateButton/AddFRQToTemplateButton.react';
import {withApplicationMetadata} from '../../Routing/withApplicationMetadata';
import {showPublicViewForSubject} from 'components/PracticeView/practiceView.utils';

import './frq-view-v2.scss';

const getQuestionSetBySlug = (slug: string, usePublicView: boolean) => {
  if (usePublicView) {
    return resource('question_set_v1')
      .mandarkEndpoint(['question_sets_v1'])
      .include('tags_v1')
      .include('subject_v2')
      .include('public_questions_v1')
      .include('public_questions_v1.standards_v1')
      .filter({
        slug_id: slug,
        included: {
          questions_v3: {
            question_type: 'free-response'
          }
        }
      })
      .findOne();
  }
  return resource('question_set_v1')
    .mandarkEndpoint(['question_sets_v1'])
    .include('tags_v1')
    .include('subject_v2')
    .include('questions_v3')
    .include('questions_v3.supplements_v1')
    .include('questions_v3.standards_v1')
    .filter({
      slug_id: slug,
      included: {
        questions_v3: {
          question_type: 'free-response'
        }
      }
    })
    .withMeta('question_v3', sessionStore.hasValidSession())
    .meta(
      {
        context: {
          user: {
            id: sessionStore.getUserId()
          },
          blocked_assignment_question: true
        }
      },
      sessionStore.hasValidSession()
    )
    .findOne();
};

/**
 * @param questionSetId
 * @todo duplicate of `ActiveQuestionStore.getActiveQuestionSetAssignmentQuery`
 */
function getActiveAssignmentsQuery(questionSetId) {
  return resource('student_assignments_v1')
    .mandarkEndpoint(['student_assignments_v1'])
    .filter({
      students_v2: sessionStore.getUserId(),
      question_sets_v1: questionSetId,
      included: {
        students_v2: {
          id: sessionStore.getUserId()
        }
      },
      any_of: [
        {
          start_date: {
            null: true
          }
        },
        {
          start_date: {
            less_than: 'now'
          }
        }
      ]
    })
    .include('students_v2')
    .meta({
      context: {
        student: {
          id: sessionStore.getUserId()
        }
      }
    })
    .customQuery({
      with_meta: 'student_assignment_v1'
    })
    .sort('due_date');
}
const teacherText =
  'Students can submit written responses to this question within an assignment. Responses will automatically be graded as correct.';
const studentText =
  'To submit a written response to this question, your teacher must assign it to you.';
const getFRQFromSet = (set: any, question?: string, usePublicView?: boolean) => {
  if (usePublicView) {
    return set.getPublicQuestions().first();
  }
  return question
    ? set.getQuestions().find((q) => q.getTitleSlug() === question)
    : set.getQuestions().first();
};

class _FRQView extends React.Component {
  static propTypes = {
    params: PropTypes.shape({
      slug: PropTypes.string.isRequired,
      set: PropTypes.string.isRequired,
      question: PropTypes.string
    }),
    subject: PropTypes.instanceOf(SubjectModelV2),
    set: PropTypes.instanceOf(QuestionSetModelV1)
  };

  componentDidMount() {
    global.document.addEventListener('contextmenu', this.preventCopy);
    global.document.addEventListener('copy', this.preventCopy);
  }

  componentWillUnmount() {
    global.document.removeEventListener('contextmenu', this.preventCopy);
    global.document.removeEventListener('copy', this.preventCopy);
  }

  preventCopy(e) {
    e.preventDefault();
  }

  getFRQ() {
    const usePublicView = showPublicViewForSubject(this.props.subject);
    return getFRQFromSet(this.props.set, this.props.params.question, usePublicView);
  }

  /**
   * @todo This should be moved to the model.
   */
  getSearchLinkText() {
    return <MarkdownRendererV2 text={`Back to "${this.getFRQ().getTitle()}" (Free Response)`} />;
  }

  getContent(showPaywall, usePublicView) {
    const frq = this.getFRQ();

    const tagsQuery = resource('tags_v1').mandarkEndpoint([
      'question_sets_v1',
      this.props.set.getId(),
      'tags_v1'
    ]);

    const standardsQuery = resource('standards_v1').mandarkEndpoint([
      'questions_v3',
      frq.getId(),
      'standards_v1'
    ]);

    const sections = !showPaywall ? (
      [
        <FRQSection key='frq-prompt' content={frq.getPrompt()} />,
        <FRQSolution
          key='frq-solution'
          question={frq}
          questionSetId={this.props.set.getId()}
          questionSetSlugId={`${this.props.params.slug}@${this.props.params.set}`}
        />,
        standardsQuery.getResource().size > 0 && (
          <h2 className='question-footer__labels-header' key='frq-standards-heading'>
            Standards
          </h2>
        ),
        <TagOrStandardGroup
          key='frq-standards'
          query={standardsQuery}
          renderFunc={(standard, key) => (
            <StandardChip
              enableTooltip
              as={Link}
              key={key}
              standard={standard}
              title={`See all questions listed under ${standard.getTitle()}`}
              to={`/${this.props.subject.getUrlSlug()}?standards=${standard.getId()}`}
            />
          )}
        />,
        tagsQuery.getResource().size > 0 && (
          <h2 className='question-footer__labels-header' key='frq-tags-heading'>
            Tags
          </h2>
        ),
        <TagOrStandardGroup
          key='frq-tags'
          query={tagsQuery}
          renderFunc={(tag, key) => (
            <Chip
              as={Link}
              className='frq-view__content__chip frq-view__content__chip--link'
              key={key}
              to={`${this.props.subject.getUrlSlug()}?tags=${tag.getId()}`}
            >
              {tag.getName()}
            </Chip>
          )}
        />
      ]
    ) : (
      <FRQSection key='frq-prompt-preview' content='' />
    );

    const isLoggedIn = sessionStore.hasValidSession();

    return [
      <div key='content' className='frq-view__content'>
        {isLoggedIn && (
          <div className='u-mar-b_2 u-mar-t_0'>
            <Banner>
              <Banner.Icon icon='exclamation-circle' iconStyle='regular' />
              {sessionStore.isStudent() ? studentText : teacherText}
            </Banner>
          </div>
        )}
        <div className='frq-header'>
          <h1 className='frq-header__name'>
            <MarkdownRendererV2 text={frq.getTitle()} />
          </h1>
          {!usePublicView && <FreeResponseQuestionSubTypeBadge question={frq} />}
          {!showPaywall && sessionStore.isTeacher() ? (
            <AddFRQToTemplateButton
              key='add-frq-to-template-button'
              questionSetIds={[this.props.set.getId()]}
              className='frq-header__add-to-template-button'
            />
          ) : null}
        </div>
        {sections}
      </div>,
      showPaywall ? <FRQPaywall key='paywall' subject={this.props.subject} /> : null
    ];
  }

  render() {
    const usePublicView = showPublicViewForSubject(this.props.subject);
    const {isReady, value} = hasSubjectAccess(this.props.subject.getId());
    /**
     * We show a "paywall" varient of the FRQ, if the user does not have
     * access to the subject that the FRQ belongs to.
     */
    const showPaywall = value === false;
    if (isReady === false) {
      return <LoadingIndicator />;
    }

    const inActiveAssignment = usePublicView
      ? false
      : this.getFRQ().getMeta().isBlockedAssignmentQuestion();

    let contents;
    if (inActiveAssignment) {
      const query = getActiveAssignmentsQuery(this.props.set.getId());
      let goToAssignmentButton = null;
      if (query.isResourceReady() === false) {
        goToAssignmentButton = <LoadingIndicator inline />;
      } else {
        /**
         * Once we have the assignments, we find the first assignment the
         * user can actually take an action against.
         *
         * @todo duplicate of `ActiveQuestionStore.getActiveQuestionSetAssignmentId`
         */
        /* eslint-disable no-shadow */
        const assignment = query
          .getResource()
          .find(
            (assignment) =>
              assignment.isStudentFinished(
                sessionStore.getUserId(),
                systemTimeOffsetStore.getCurrentTime()
              ) === false || assignment.canAssignmentBeSubmitted()
          );
        goToAssignmentButton = assignment ? (
          <Link className='button button--light-gray' to={`/assignment/${assignment.getId()}`}>
            Go to Assignment
          </Link>
        ) : null;
      }
      contents = (
        <div className='frq-view__content'>
          <RestrictedQuestionMessage />
          <div className='frq-actions'>{goToAssignmentButton}</div>
        </div>
      );
    } else {
      contents = this.getContent(showPaywall, usePublicView);
    }

    return (
      <div className='frq-view'>
        <div className='frq-view__content-wrapper card'>{contents}</div>
      </div>
    );
  }
}

/**
 * We add the `awaitMandarkQueries` and `withApplicationMetadata` HOCs
 * to the base `_FRQView` before exporting.
 */
const FRQView = awaitMandarkQueries(
  (props) => ({
    queries: {
      set: getQuestionSetBySlug(
        `${props.params.slug}@${props.params.set}`,
        showPublicViewForSubject(props.subject)
      )
    },
    invalidQueryElement: (
      <div className='frq-view frq-view--error card'>
        Sorry, we were unable to locate that free response question.
      </div>
    ),
    onWillMount: async (props) => {
      const usePublicView = showPublicViewForSubject(props.subject);
      if (!usePublicView) {
        const set = await getQuestionSetBySlug(
          `${props.params.slug}@${props.params.set}`
        ).getResourcePromise();
        const frq = getFRQFromSet(set, props.params.question);
        const questionSupplements = frq.getSupplements();
        if (!questionSupplements.isEmpty()) {
          callAction(supplementActions.QUESTIONSET_SUPPLEMENTS_RECEIVED, questionSupplements);
        }
      }
    }
  }),
  withApplicationMetadata(withRouter(_FRQView), (props) => {
    const frq = getFRQFromSet(
      props.set,
      props.params.question,
      showPublicViewForSubject(props.subject)
    );
    return {
      title: `${props.subject.getName()} - ${frq.getTitle()} | Albert`,
      metaDescription: ''
    };
  })
);

export default awaitMandarkQueries(
  (props) => ({
    queries: {
      subject: resource('subject_v2')
        .mandarkEndpoint(['subjects_v2'])
        .filter({url_slug: props.params.slug})
        .withMeta('subject_v2')
        .meta(
          {
            context: {
              student: {
                id: sessionStore.getUserId()
              }
            }
          },
          sessionStore.hasValidSession()
        )
        .findOne()
    }
  }),
  FRQView
);

function FRQSection({content, title}) {
  return (
    <section className='frq-section'>
      {title && <h2>{title}</h2>}
      <MarkdownRendererV2 text={content} />
    </section>
  );
}

FRQSection.propTypes = {
  content: PropTypes.string,
  title: PropTypes.string
};

const FRQPaywall = ({subject}) => {
  const {isReady, value: userBelongsToLicensedClassroom} = doesUserBelongToAnyLicensedClassrooms();
  if (!isReady) {
    return <LoadingIndicator />;
  }
  return userBelongsToLicensedClassroom ? (
    <div className='frq-paywall'>
      <Text as='p'>
        <span className='frq-paywall__top-line'>
          Want to practice <strong>{subject.getName()}</strong> at your own pace?
        </span>
        Ask your teacher to grant you independent practice access
      </Text>
      <Text size='s' as='p' className='u-mar-t_2' color='secondary'>
        If you purchased a subscription and have a question about refunds, please send an email to{' '}
        <Anchor underlined href='mailto:hello@albert.io?subject=Refund%20for%20locked%20questions'>
          hello@albert.io
        </Anchor>{' '}
        using the subject line &quot;Refund for locked questions&quot;.
      </Text>
    </div>
  ) : (
    <div className='frq-paywall'>
      <p className='frq-paywall__copy'>
        <span className='frq-paywall__top-line'>
          By upgrading a subject, you&apos;ll have access to the rest of the&nbsp;
        </span>
        <strong>Prompt,</strong> a <strong>Sample Response,</strong> and an{' '}
        <strong>Explanation.</strong>
      </p>
      <div className='frq-paywall__upgrade-subject-button'>
        <UpgradeButton subject={subject} />
      </div>
    </div>
  );
};

FRQPaywall.propTypes = {
  subject: PropTypes.instanceOf(SubjectModelV2)
};

class FRQSolution extends React.Component {
  static propTypes = {
    question: PropTypes.object,
    questionSetId: PropTypes.string,
    /**
     * this is used for proper invalidation of the view's query.
     */
    questionSetSlugId: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  handleCloseSolution = () => {
    this.setState({isOpen: false});
    global.scrollTo(0, 0);
  };

  handleViewSampleClick = async () => {
    if (!sessionStore.hasValidSession()) {
      showGlobalLoginModal();
      return;
    }
    if (this.props.question.getMeta().isUserAnswered()) {
      this.setState({isOpen: true});
      return;
    }
    /**
     * Basic "free-response" question guess submission.
     */
    try {
      this.setState({isOpen: true});
      const guess = new GuessModel({})
        .updateRelationship('question_v1', this.props.question.getId())
        .updateRelationship('student_v1', sessionStore.getUserId());
      await guess.save();
      getQuestionSetBySlug(this.props.questionSetSlugId).invalidateInterest();
    } catch (err) {
      logger.error(err);
      addGenericErrorToast();
    }
  };

  render() {
    const frq = this.props.question;
    let editButton = null;
    if (sessionStore.isSuper() || frq.getMeta().isAuthorCanEdit()) {
      editButton = (
        <Link
          className='active-question-button'
          to={getQuestionEditPath({
            questionSetId: this.props.questionSetId,
            questionId: frq.getId()
          })}
        >
          Edit Question
        </Link>
      );
    }
    const sample = this.props.question.getMeta().getSample();
    return this.state.isOpen && this.props.question.getMeta().isUserAnswered() ? (
      <div>
        {sample && (
          <FRQSection
            key='frq-sample-response'
            title='Sample response'
            content={this.props.question.getMeta().getSample()}
          />
        )}
        <FRQSection
          key='frq-rubric-explanation'
          title='Rubric and explanation'
          content={this.props.question.getSolutionText()}
        />
        <div className='frq-actions'>
          <Button
            color='green'
            onClick={this.handleCloseSolution}
            text='Close sample and explanation'
            type='button'
            data-testid='FRQ-view-2__close-sample-btn'
          />
          {editButton}
        </div>
      </div>
    ) : (
      <div className='frq-actions'>
        <Button
          color='green'
          onClick={this.handleViewSampleClick}
          text='View sample and explanation'
          type='button'
          data-testid='FRQ-view-2__view-sample-btn'
        />
        {editButton}
      </div>
    );
  }
}
