/* eslint-disable import/prefer-default-export */
import {List} from 'immutable';
import validator from 'validator';
import constants from 'client/constants';
import {Empty} from '@albert-io/json-api-framework/models/helpers';

export const userModelValidators = {
  getEmail() {
    if (this.getEmail().length === 0) {
      return new List(['You must provide an email']);
    }
    if (!validator.isEmail(this.getEmail())) {
      return new List(['Your email address is not valid']);
    }

    return null;
  },
  getFirstName() {
    if (this.getFirstName().length === 0) {
      return new List(['You must provide a first name.']);
    }
    if (this.getFirstName().includes('.')) {
      return new List(['First name cannot contain any periods.']);
    }
    return null;
  },
  getLastName() {
    if (this.getLastName().length === 0) {
      return new List(['Please enter your last name (or school ID)']);
    }
    if (this.getLastName().includes('.')) {
      return new List(['Last name cannot contain any periods.']);
    }
    return null;
  },
  getPassword() {
    if (
      this.get('password') !== Empty &&
      !constants.REGEX_COLLECTION.PASSWORD.test(this.getPassword())
    ) {
      return new List([
        'Passwords must have at least 8 characters and at least one number or special character.'
      ]);
    }
    return null;
  },
  getSalutation() {
    if (this.isTeacher() && !this.getSalutation()) {
      return new List(['You must provide a title']);
    }
    return null;
  },
  getUsername() {
    if (!constants.REGEX_COLLECTION.USERNAME.test(this.getUsername())) {
      return new List(['A username must be 4-16 alphanumeric characters']);
    }
    return null;
  },
  getVerifyOldPassword() {
    if (
      typeof this.getVerifyOldPassword() === 'string' &&
      this.getVerifyOldPassword().length === 0
    ) {
      return new List(['You must provide your old password.']);
    }
    return null;
  }

  /**
   * @todo: This sort of changemap testing should be wholly abstracted away in a util
   * @todo: How should the specific ``school_vX'' accessors be handled when looking at the change map?
   * we might need to define several validators that check specific versions and OR all of them
   * @todo: it is a bug currently that ``__relationshipRemovals'' and either additive ``__relationship''
   * changemaps can be populated with a reource of the same type and id. This needs to be fixed in generic
   * model otherwise this validator just doesn't work
   *
   *
   * This function will be commented out until the above todo items can be addressed. It is unused currently,
   * but the impl is correct assuming generic model gets fixed.
   */
  // getSchool() {
  //   const requiredError = List(['School is a required field']);
  //   const currentSchool = this.getSchool();
  //   const hasCurrentSchool = currentSchool && currentSchool.isEmpty && !currentSchool.isEmpty();

  //   if (this.isTeacher()) {
  //     const potentialExistingSchool = this.__changeMap.getIn(['__relationships', 'school_v4'], null);
  //     const potentialReplacementSchool = this.__changeMap.getIn(['__relationshipReplacements', 'school_v4'], null);
  //     const potentialDeletion = this.__changeMap.getIn(['__relationshipRemovals', 'school_v4'], null);

  //     const hasSchool = Boolean(potentialExistingSchool) || Boolean(potentialReplacementSchool);
  //     const hasNoSchool = !hasSchool && Boolean(potentialDeletion);

  //     if (hasNoSchool) {
  //       return requiredError;
  //     } else if (hasCurrentSchool || hasSchool) {
  //       return null;
  //     }
  //   }

  //   return null;
  // }
};
