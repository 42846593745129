import React, {lazy} from 'react';
import {Redirect, Route, IndexRoute, IndexRedirect} from 'react-router';

const CreateAccounts = lazy(() => import('client/components/CreateAccounts/CreateAccounts.react'));
const SchoolAdmin = lazy(() => import('client/SchoolAdministration/SchoolAdministration.react'));
const SchoolList = lazy(() => import('client/SchoolAdministration/SchoolList/SchoolList.react'));
const School = lazy(() => import('client/SchoolAdministration/School/School.react'));
const SchoolAdminDashboard = lazy(() =>
  import('client/SchoolAdministration/School/Dashboard/Dashboard.react')
);
const SchoolAdminManageStaff = lazy(() =>
  import('client/SchoolAdministration/School/ManageStaff/ManageStaff.react')
);
const SchoolAccounts = lazy(() =>
  import('client/SchoolAdministration/School/SchoolAccounts/SchoolAccounts.react')
);
const SchoolAdminPendingApprovalStaff = lazy(() =>
  import('client/SchoolAdministration/School/ManageStaff/Staff/PendingApproval.react')
);
const SchoolAdminPendingInvitationsStaff = lazy(() =>
  import('client/SchoolAdministration/School/ManageStaff/Staff/PendingInvitations.react')
);
const SchoolAdminApprovedStaff = lazy(() =>
  import('client/SchoolAdministration/School/ManageStaff/Staff/Approved.react')
);
const SchoolAdminRejectedStaff = lazy(() =>
  import('client/SchoolAdministration/School/ManageStaff/Staff/Rejected.react')
);
const SchoolAdminUpdateTeachers = lazy(() =>
  import('client/SchoolAdministration/School/ManageStaff/Staff/UpdateTeachers/UpdateTeachers.react')
);
const SchoolAdminUsageReports = lazy(() =>
  import('client/SchoolAdministration/School/UsageReports/UsageReports')
);
const SchoolAdminTeachersReport = lazy(() =>
  import('client/SchoolAdministration/School/UsageReports/Reports/TeachersReport.react')
);
const SchoolAdminStudentsReport = lazy(() =>
  import('client/SchoolAdministration/School/UsageReports/Reports/StudentsReport.react')
);
const SchoolAdminClassesReport = lazy(() =>
  import('client/SchoolAdministration/School/UsageReports/Reports/ClassesReport.react')
);
const SchoolAdminSubjectsReport = lazy(() =>
  import('client/SchoolAdministration/School/UsageReports/Reports/SubjectsReport.react')
);

export default (
  <Route path='school-admin' component={SchoolAdmin} title='School Administration | Albert.io'>
    <IndexRoute component={SchoolList} />
    <Route component={School} path=':schoolId' title='School Administration | Albert.io'>
      <IndexRedirect to='dashboard' />
      <Route component={SchoolAdminDashboard} path='dashboard' />
      <Redirect from='classes' to='usage-reports/classes' />
      <Redirect from='teachers' to='usage-reports/teachers' />
      <Redirect from='licenses' to='manage-staff' />
      <Route component={SchoolAdminManageStaff} path='manage-staff'>
        <IndexRedirect to='pending-approval' />
        <Route component={SchoolAdminPendingApprovalStaff} path='pending-approval' />
        <Route component={SchoolAdminPendingInvitationsStaff} path='pending-invitations' />
        <Route component={SchoolAdminApprovedStaff} path='approved' />
        <Route component={SchoolAdminRejectedStaff} path='rejected' />
      </Route>
      <Route component={SchoolAdminUpdateTeachers} path='update-teachers' withWhiteBackground />
      <Route component={SchoolAdminUsageReports} path='usage-reports'>
        <IndexRedirect to='teachers' />
        <Route path='teachers' component={SchoolAdminTeachersReport} />
        <Route path='students' component={SchoolAdminStudentsReport} />
        <Route path='classes' component={SchoolAdminClassesReport} />
        <Route path='subjects' component={SchoolAdminSubjectsReport} />
      </Route>
      <Route component={SchoolAccounts} path='school-accounts' withWhiteBackground />
      <Route component={CreateAccounts} path='create-accounts' withWhiteBackground />
    </Route>
  </Route>
);
