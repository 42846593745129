import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {getQuestionTypeDefinition} from 'client/QuestionTypes/QuestionTypeDefinitions';
import {AuthoringQuestionModelV1} from 'resources/augmented/AuthoringQuestion/AuthoringQuestionModel.v1';

// eslint-disable-next-line react/prefer-stateless-function
export default class QuestionTypeManager extends React.Component {
  static propTypes = {
    onBlur: PropTypes.func,
    question: PropTypes.instanceOf(AuthoringQuestionModelV1),
    onModelChange: PropTypes.func,
    updateQuestion: PropTypes.func,
    validationErrors: ImmutablePropTypes.map
  };

  static defaultProps = {
    onModelChange: () => {}
  };

  render() {
    const type = getQuestionTypeDefinition(this.props.question.getType());
    const Editor = type.get('editor');
    return (
      <div>
        <Editor {...this.props} />
      </div>
    );
  }
}
