import React from 'react';
import {IconDropdown} from 'client/generic/ButtonDropdown/ButtonDropdown.react';

export const KebabMenu = ({children, ...passthroughProps}: PropsWithChildrenOptional) => (
  <IconDropdown
    {...passthroughProps}
    icon='fa-ellipsis-v'
    buttonClassName='hero-unit__kebab-btn'
    className='hero-unit__kebab'
  >
    {children}
  </IconDropdown>
);
