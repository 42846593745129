/**
 * # NumberField
 *
 * A component that renders a number input field within a form field.
 *
 * ## Props<C extends React.ElementType>
 *
 * - `as?: C`: Component or element to inherit prop types from.
 * - `className?: string` (extends `FieldProps<typeof NumberInput>`) - Additional CSS class for styling.
 * - `defaultValue?: string` (extends `FieldProps<typeof NumberInput>`) - The default value of the input field.
 * - `error?: boolean` (extends `FieldProps<typeof NumberInput>`) - Indicates if there is an error with the input field.
 * - `disabled?: boolean` (extends `FieldProps<typeof NumberInput>`) - Indicates if the input field is disabled.
 * - `id?: string` (extends `FieldProps<typeof NumberInput>`) - The ID of the input field.
 * - `inline?: boolean` (extends `FieldProps<typeof NumberInput>`) - Indicates if the input field should be displayed inline.
 * - `label?: string` (extends `FieldProps<typeof NumberInput>`) - The label for the input field.
 * - `message?: string | null | boolean` (extends `FieldProps<typeof NumberInput>`) - The message to display below the input field.
 * - `onBlur?: (...params: any) => void` (extends `FieldProps<typeof NumberInput>`) - Event handler for the blur event.
 * - `onChange?: (...params: any) => void` (extends `FieldProps<typeof NumberInput>`) - Event handler for the change event.
 * - `onFocus?: (...params: any) => void` (extends `FieldProps<typeof NumberInput>`) - Event handler for the focus event.
 * - `onMouseEnter?: (...params: any) => void` (extends `FieldProps<typeof NumberInput>`) - Event handler for the mouse enter event.
 * - `onMouseLeave?: (...params: any) => void` (extends `FieldProps<typeof NumberInput>`) - Event handler for the mouse leave event.
 * - `required?: boolean` (extends `FieldProps<typeof NumberInput>`) - Indicates if the input field is required.
 * - `size?: 's' | 'regular' | 'l'` (extends `FieldProps<typeof NumberInput>`) - The size of the input field.
 * - `success?: boolean` (extends `FieldProps<typeof NumberInput>`) - Indicates if the input field is successful.
 * - `type?: string` (extends `FieldProps<typeof NumberInput>`) - The type of the input field.
 * - `value?: string | number` (extends `FieldProps<typeof NumberInput>`) - The value of the input field.
 * - `...rest: C`: Any valid props on the component or element passed as the `as` prop.
 *
 * ## Usage
 *
 * ```tsx
 * import NumberField from './NumberField.react';
 *
 * const Example = () => {
 *   return (
 *     <NumberField
 *       placeholder="Enter a number"
 *       value={42}
 *       label="Number"
 *       onChange={(value) => console.log(value)}
 *     />
 *   );
 * };
 * ```
 */

import React from 'react';

import NumberInput from '../../atoms/NumberInput/NumberInput.react';
import Field, {FieldProps} from '../Field/Field.react';

interface NumberFieldProps extends FieldProps<typeof NumberInput> {
  placeholder: string;
  value: number;
}

const NumberField = (props: NumberFieldProps) => <Field as={NumberInput} {...props} />;

export default NumberField;
