import React from 'react';
import PropTypes from 'prop-types';

import {getHost} from '@albert-io/environment';

import SocialButton from '../SocialButton.react';

import icon from './assets/clever.svg';

function getCleverSocialSignInLink() {
  return `${getHost()}/auth/clever`;
}

const CleverButton = ({disabled = false}) => (
  <SocialButton disabled={disabled} provider='clever' href={getCleverSocialSignInLink()}>
    <img className='sso-button__logo' src={icon} alt='' />
    &nbsp;Log in with Clever
  </SocialButton>
);

CleverButton.propTypes = {
  disabled: PropTypes.bool
};

export default CleverButton;
