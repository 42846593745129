import React from 'react';
import PropTypes from 'prop-types';
import {TabNavigation} from '@albert-io/atomic';
import {flavorTypes} from 'components/GraphingInput/GraphingInput.react';

const FlavorSelectionTabs = ({currentFlavor, setCurrentFlavor}) => (
  <TabNavigation className='u-mar-b_4' align='left' paddingLevel={0}>
    <TabNavigation.Tab
      as='button'
      onClick={() => setCurrentFlavor(flavorTypes.correctAnswer)}
      isActive={currentFlavor === flavorTypes.correctAnswer}
    >
      Correct Answer
    </TabNavigation.Tab>
    <TabNavigation.Tab
      as='button'
      onClick={() => setCurrentFlavor(flavorTypes.authorProvided)}
      isActive={currentFlavor === flavorTypes.authorProvided}
    >
      Drawing
    </TabNavigation.Tab>
  </TabNavigation>
);

FlavorSelectionTabs.propTypes = {
  currentFlavor: PropTypes.oneOf(Object.values(flavorTypes)),
  setCurrentFlavor: PropTypes.func
};

export default FlavorSelectionTabs;
