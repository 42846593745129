import {List} from 'immutable';

export const schoolPersonnelModelValidators = {
  /**
   * Tests that a school value is here and populated.
   *
   * Changemap is prioritized
   */
  getSchool() {
    const isRequired = new List(['School is required']);
    const potentialSchool =
      this.__changeMap.getIn(['__relationships', 'school_v2']) || this.getSchool();

    // string
    if (!potentialSchool) {
      return isRequired;
    }

    if (typeof potentialSchool === 'object') {
      if (potentialSchool.isEmpty && potentialSchool.isEmpty()) {
        return isRequired;
      }
    }

    return null;
  },
  getStatus() {
    if (!this.getStatus()) {
      // check changemap for a value, note: this will be replaced with a more canonical method
      if (this.__changeMap.get('status')) {
        return null;
      }
      return new List(['Status is required']);
    } else {
      return null;
    }
  }
};
