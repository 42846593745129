import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import {SplitCard} from '@albert-io/atomic';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import './subject-split-card.scss';

import classnames from 'classnames';

const SubjectSplitCard = forwardRef(({className, children, ...rest}, ref) => {
  return (
    <SplitCard
      border='none'
      className={classnames(`subject-split-card u-display_flex u-flex-direction_column`, className)}
      ref={ref}
      {...rest}
    >
      {children}
    </SplitCard>
  );
});

SubjectSplitCard.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

SubjectSplitCard.Top = ({children, className, color, ...rest}) => {
  return (
    <div
      className={classnames(
        'subject-split-card__details u-display_flex u-align-items_flex-end u-pad-lr_3 u-pad-tb_3 u-color_white u-overflow_hidden',
        {[`u-bgc_${color}-500`]: color},
        className
      )}
      {...rest}
    >
      {children}
    </div>
  );
};

SubjectSplitCard.Top.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string
};

SubjectSplitCard.Thumbnail = ({className, xlinkHref}) => {
  return (
    <svg
      className={classnames('subject-split-card__thumbnail', className)}
      width='102'
      height='84'
      viewBox='0 0 102 84'
      fill='none'
      role='presentation'
    >
      <ellipse cx='107' cy='-13' rx='107' ry='97' fill='black' opacity='0.1' />
      <image height='40' width='40' transform='translate(47,12)' xlinkHref={xlinkHref} />
    </svg>
  );
};

SubjectSplitCard.Thumbnail.propTypes = {
  className: PropTypes.string,
  xlinkHref: PropTypes.string
};

SubjectSplitCard.Title = ({className, children, ...rest}) => {
  return (
    <div
      className={classnames(
        'u-display_flex u-flex-direction_column u-justify-content_space-between u-height_100pc u-mar-b_auto',
        className
      )}
      {...rest}
    >
      {children}
    </div>
  );
};

SubjectSplitCard.Title.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

SubjectSplitCard.DescriptionText = ({content}) => {
  return <MarkdownRendererV2 text={content} />;
};

SubjectSplitCard.DescriptionText.propTypes = {
  content: PropTypes.node
};

SubjectSplitCard.Bottom = ({children, className, ...rest}) => {
  return (
    <SplitCard.Section className={classnames('u-pad-r_1', className)} {...rest}>
      {children}
    </SplitCard.Section>
  );
};

SubjectSplitCard.Bottom.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default SubjectSplitCard;
