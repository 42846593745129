import {createActions} from 'client/framework';

export default createActions(null, [
  'CANCEL_DEBOUNCED_SAVE_DRAFT_GUESS',
  'CLEAR_DRAFT_GUESS_DATA',
  'CLEAR_GUESS',
  'LOAD_DRAFT_GUESS',
  'LOAD_GUESS',
  'SAVE_DRAFT_GUESS',
  'START_TIMER',
  'UPDATE_QUESTION'
]);
