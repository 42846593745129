import {callTargetedAction} from 'client/framework';
import {GuessModelV1} from '@albert-io/models';
import questionTypeActions from 'components/QuestionTypes/questionType.actions';

export default function loadGuess(questionStoreName: string, guess: GuessModelV1) {
  callTargetedAction({
    name: questionTypeActions.LOAD_GUESS,
    targetStore: questionStoreName,
    payload: guess
  });
}
