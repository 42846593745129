import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import SimpleMarkdown from 'simple-markdown';
import {makeParser} from 'generic/MarkdownRendererV2/markdownRendererUtils';
import {getModelForResourceType} from 'resources/modelRegistry';

import SnippetSelectionOption from './SnippetSelectionOption.react';

export default class SnippetSelectionRenderer extends React.Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    snippetSelectionData: PropTypes.shape({
      isExplanation: PropTypes.bool,
      options: ImmutablePropTypes.list,
      storeName: PropTypes.string.isRequired,
      onOptionClick: PropTypes.func
    }),
    isGuessSubmitted: PropTypes.bool,
    question: PropTypes.instanceOf(getModelForResourceType('question_v3'))
  };

  constructor() {
    super();
    this.optionIndex = 0;
  }

  UNSAFE_componentWillUpdate() {
    this.optionIndex = 0;
  }

  createSnippetElement({snippetOption, children}) {
    if (!snippetOption) {
      return null;
    }
    return (
      <SnippetSelectionOption
        optionId={snippetOption.getId()}
        question={this.props.question}
        isGuessSubmitted={this.props.isGuessSubmitted}
        storeName={this.props.snippetSelectionData.storeName}
        isExplanation={this.props.snippetSelectionData.isExplanation}
        onOptionClick={this.props.snippetSelectionData.onOptionClick}
      >
        {children}
      </SnippetSelectionOption>
    );
  }

  createOutput() {
    const {snippetSelectionData} = this.props;
    const snippetSelectionRule = {
      order: SimpleMarkdown.defaultRules.em.order - 0.5,
      match: (source) => {
        const match = /^{{(.*?)}}/.exec(source);
        return match;
      },
      parse: (capture, parse, state) => {
        return {
          content: parse(capture[1], state)
        };
      },
      react: (node, output) => {
        const snippetOption = this.createSnippetElement({
          snippetOption: snippetSelectionData.options.get(this.optionIndex),
          children: output(node.content)
        });
        this.optionIndex =
          this.optionIndex !== snippetSelectionData.options.size - 1 ? this.optionIndex + 1 : 0;
        return snippetOption;
      }
    };

    const parser = makeParser({snippetSelection: snippetSelectionRule});
    const syntaxTree = parser.makeSyntaxTree(this.props.text);
    return parser.reactOutput(syntaxTree);
  }

  render() {
    return <div className='snippet-selection-renderer'>{this.createOutput()}</div>;
  }
}
