import React from 'react';
import PropTypes from 'prop-types';
import {List} from 'immutable';
import classnames from 'classnames';
import {callTargetedAction} from 'client/framework';
import QuestionSetAlignmentActions from './QuestionSetAlignment.actions';
import QuestionSetAlignmentStore from './QuestionSetAlignment.store';
import {AuthoringGuideModelV1} from 'resources/augmented/AuthoringGuide/AuthoringGuideModel.v1';
import Button from 'sg/Button/Button.react';

export default class GuideLevelAlignmentList extends React.Component {
  static propTypes = {
    questionSetGuideLevelIds: PropTypes.instanceOf(List),
    store: PropTypes.instanceOf(QuestionSetAlignmentStore),
    guide: PropTypes.instanceOf(AuthoringGuideModelV1)
  };

  state = {
    expandedLevels: this.props.questionSetGuideLevelIds.reduce((acc, id) => {
      acc[id] = true;
      return acc;
    }, {})
  };

  toggleExpandGuideLevel = (e) => {
    const guideLevelId = e.currentTarget.dataset.guideLevelId;
    this.setState({
      expandedLevels: {
        ...this.state.expandedLevels,
        [guideLevelId]: !this.state.expandedLevels[guideLevelId]
      }
    });
  };

  setGuideLevelAlignment = (e) => {
    callTargetedAction({
      name: QuestionSetAlignmentActions.SET_PENDING_GUIDE_LEVEL_ID,
      targetStore: this.props.store.getName(),
      payload: e.currentTarget.dataset.guideLevelId
    });
  };

  renderGuideLevels(guide) {
    return guide.getSortedGuideLevels().reduce((acc, guideLevel, i, arr) => {
      const parentId = guideLevel.getParentId();
      if (parentId && this.state.expandedLevels[parentId] !== true) {
        return acc;
      }
      const id = guideLevel.getId();
      const isLast = i + 1 === arr.size;
      const nLevel = guideLevel.getNlevel();
      const hasChildren = !isLast && nLevel < arr.get(i + 1).getNlevel();
      const isExpanded = hasChildren ? this.state.expandedLevels[id] : false;
      const isSelectedGuideLevel = this.props.store.hasPendingAlignment()
        ? this.props.store.getPendingGuideLevelId() === id
        : this.props.questionSetGuideLevelIds.includes(id);
      const icon = hasChildren ? (
        <span className={`fa fa-fw fa-caret-${isExpanded ? 'down' : 'right'}`} />
      ) : (
        <span
          className={classnames('guide-level-alignment-list__set-alignment-button', {
            'guide-level-alignment-list__set-alignment-button--active': isSelectedGuideLevel
          })}
        />
      );
      return acc.push(
        <li
          key={id}
          className={`guide-level-alignment-list__item guide-level-alignment-list__item--${nLevel}-level`}
        >
          <Button
            textButton
            onClick={hasChildren ? this.toggleExpandGuideLevel : this.setGuideLevelAlignment}
            data-guide-level-id={id}
          >
            {icon} {guideLevel.getName()}
          </Button>
        </li>
      );
    }, List());
  }

  render() {
    return (
      <ul className='guide-level-alignment-list'>{this.renderGuideLevels(this.props.guide)}</ul>
    );
  }
}
