import React from 'react';
import PropTypes from 'prop-types';
import LoadingIndicator from 'client/generic/LoadingIndicator.react';

export default class HandleCoppa extends React.Component {
  static propTypes = {
    query: PropTypes.func.isRequired,
    errorComponent: PropTypes.instanceOf(React.element).isRequired,
    successComponent: PropTypes.instanceOf(React.element).isRequired,
    onError: PropTypes.func,
    onSuccess: PropTypes.func
  };

  static defaultProps = {
    onError: () => {},
    onSuccess: () => {}
  };

  state = {
    requestComplete: false,
    hasError: false
  };

  componentDidMount() {
    this.rejectCoppa();
  }

  async rejectCoppa() {
    try {
      await this.props.query();

      this.props.onSuccess();
      this.setState({
        requestComplete: true
      });
    } catch (e) {
      this.props.onError(e);

      this.setState({
        hasError: true,
        requestComplete: true
      });
      throw e;
    }
  }

  render() {
    if (!this.state.requestComplete) {
      return <LoadingIndicator />;
    }

    if (this.state.hasError) {
      return this.props.errorComponent;
    }

    if (this.state.requestComplete) {
      return this.props.successComponent;
    }

    return null;
  }
}
