// @flow
import * as React from 'react';
import PropTypes from 'prop-types';
import {SubjectModelV2} from 'resources/GeneratedModels/Subject/SubjectModel.v2';
import {organization} from './Organization';

export function generateCourseStructuredData(subject: SubjectModelV2): Object {
  return {
    '@context': 'http://schema.org',
    '@type': 'Course',
    name: subject.getName(),
    description: subject.getDescription(),
    thumbnailUrl: subject.getLogoLocation().href,
    provider: organization
  };
}

export class JSONLD extends React.PureComponent<{subject: SubjectModelV2}> {
  static propTypes = {
    subject: PropTypes.instanceOf(SubjectModelV2)
  };

  render(): React.Node {
    return (
      <script
        type='application/ld+json'
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(generateCourseStructuredData(this.props.subject))
        }}
      />
    );
  }
}
