import {createActions} from 'client/framework';

const normalActions = [
  'CREATE_TEMPLATE',
  'SELECT_TEMPLATE',
  'SET_ORDERED_TEMPLATE_QUESTION_SETS',
  'SORT_AND_SET_TEMPLATE_QUESTION_SETS',
  'SELECT_FIRST_N_QUESTIONS',
  'SET_SELECTED_QUESTION_SETS',
  'SAVE_SET_LIST_TO_TEMPLATE',
  'UPDATE_NEW_TEMPLATE_NAME',
  'RESET_STORE',
  'SET_SHOW_TOAST',
  'SET_INPUT_STATE',
  'VALIDATE_INPUT',
  'SET_IS_CREATE_TEMPLATE_FORM'
];

const templateListActions = createActions(null, normalActions);
export default templateListActions;
