import React, {useRef, useEffect} from 'react';
import marked from 'marked';
import {Iterable} from 'immutable';
import {remove, isEmpty, map} from 'lodash';
import tablesort from 'tablesort';
import './sortable-table.scss';

if (process.env.IS_BROWSER) {
  /**
   * tablesort extensions modify the Tablesort object, so we need to attach it to the global
   * scope directly. Since sorting doesn't happen on the server, we only need to do this on the client.
   */
  global.Tablesort = tablesort;
  import('tablesort/src/sorts/tablesort.number');
  import('tablesort/src/sorts/tablesort.date');
  import('tablesort/src/sorts/tablesort.monthname');
}

marked.setOptions({
  breaks: true,
  gfm: true,
  tables: true
});

function getCellCount(content) {
  let lines;
  if (content.includes('\r\n')) {
    lines = content.split(/\r\n/g);
  } else {
    lines = content.split(/\n/g);
  }

  const firstLine = lines[0];
  const splitLine = firstLine.split('|');
  if (splitLine) {
    return remove(splitLine, (entry) => {
      return !isEmpty(entry);
    });
  }
  return null;
}

interface Props {
  content: {
    text: string;
  };
  hasTotal: boolean;
}

export default function SortableTable({content: contentProp = {text: ''}, hasTotal}: Props) {
  const wrapperNode = useRef<HTMLDivElement | null>(null);
  const sortRef = useRef<any>(null);

  const init = () => {
    const table = wrapperNode.current?.firstElementChild;
    if (!table?.nodeName || table.nodeName !== 'TABLE') {
      return;
    }
    sortRef.current = tablesort(table);
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (!sortRef.current) {
      init();
    } else {
      sortRef.current.refresh();
    }
  }, [contentProp]);

  const content = Iterable.isIterable(contentProp) ? (contentProp as any).toJS() : contentProp;
  let {text} = content;
  if (text) {
    const cells = getCellCount(text);
    if (hasTotal && cells && cells.length > 0) {
      const footerCells = map(cells, () => {
        return '<th></th>';
      });
      text = marked(text).replace(
        '</tbody>',
        `<tfoot><tr>${footerCells.join('')}</tr></tfoot></tbody>`
      );
    } else {
      text = marked(text);
    }
  } else {
    text = 'No content entered.';
  }
  return (
    // eslint-disable-next-line react/no-danger
    <div className='sortable-table' ref={wrapperNode} dangerouslySetInnerHTML={{__html: text}} />
  );
}
