import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {callTargetedAction} from 'client/framework';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';

import TextInput from 'generic/Forms/TextInput/TextInput';
import CorrectnessIndicatorWrapper from 'client/QuestionTypes/common/CorrectnessIndicatorWrapper/CorrectnessIndicatorWrapper.react';

import freeEntryQuestionActions from './FreeEntryQuestion.actions';

export default class FreeEntryQuestionInput extends React.Component {
  static propTypes = {
    inputId: PropTypes.string,
    inputPrompt: PropTypes.string,
    isMarkedCorrect: PropTypes.bool,
    isGuessSubmitted: PropTypes.bool,
    question: ImmutablePropTypes.record,
    storeName: PropTypes.string
  };

  handleChange = (e) => {
    if (this.props.isGuessSubmitted) {
      return;
    }
    callTargetedAction({
      name: freeEntryQuestionActions.UPDATE_INPUT,
      payload: {
        inputId: this.props.inputId,
        value: e.target.value.trim()
      },
      targetStore: this.props.storeName
    });
  };

  render() {
    const {question} = this.props;
    const {inputPrompt} = this.props;
    const {isGuessSubmitted} = this.props;
    const {isMarkedCorrect} = this.props;
    const wrapperClass = classnames('free-entry-input', {
      'free-entry-input--post-submission': isGuessSubmitted
    });
    const inputClass = classnames(
      'free-entry-input__input',
      isGuessSubmitted
        ? {
            'free-entry-input__input--correct': isMarkedCorrect,
            'free-entry-input__input--incorrect': !isMarkedCorrect
          }
        : null
    );
    const placeholderText =
      question.getIn(['rubric', 'type']) === 'mathContains'
        ? 'Enter answer here. Do not include spaces or units.'
        : 'Type answer here. Be careful with spelling.';
    return (
      <label className={wrapperClass}>
        <MarkdownRendererV2 text={inputPrompt} className='free-entry-input__prompt' />
        <div className='free-entry-input__input-wrapper'>
          <TextInput
            className={inputClass}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            placeholder={placeholderText}
            value={this.props.value}
          />
          <CorrectnessIndicatorWrapper
            iconOnly
            isSelected
            size={24}
            className='free-entry-input__correctness-indicator'
            isGuessSubmitted={isGuessSubmitted}
            isCorrectAnswer={isMarkedCorrect}
          />
        </div>
      </label>
    );
  }
}
