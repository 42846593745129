// @flow
import * as React from 'react';
import {type List, Map} from 'immutable';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {debounce} from 'lodash';
import {callTargetedAction, setUpStore} from 'client/framework';
import SelectableDateRangeStore from './SelectableDateRange.store';
import DateRange from 'generic/DateRange/DateRange.react';
import dateRangeActions from 'generic/DateRange/DateRange.actions';
import SimpleDropdown from 'generic/SimpleDropdown/SimpleDropdown.react';
import simpleDropdownActions from 'generic/SimpleDropdown/SimpleDropdown.actions';
import {RANGES, dateRangeOptions} from './shared';
import './selectable-date-range.scss';

/**
 * *** WHAT IT DO ***
 * The SelectableDateRange component (SDR for short), accepts a list of date
 * range options and then wraps up a pair of SimpleDropdown and DateRange components,
 * allowing you to manipulate the DateRange's state via the adjacent dropdown.
 *
 *
 * *** HOW IT WORKS ***
 * We derive the dropdown options from a static JS file.  At runtime we generate
 * these options from the getCurrentDateRangeOptions member function.  Doing so allows
 * us to track the "Custom" item's values and pass them around to other stores in real time.
 * Bearing these options as props, the dropdown's onChange handler writes the chosen option's
 * start and end dates to the DateRange component's store.
 *
 * These dates are then exposed via the DateRange store, or via the SimpleDropdown store.
 *
 * When the user manipulates the DateRange component directly, its change handler automatically switches us to
 * "Custom" mode. As described above, the "Custom" option's values track the DateRange's input values in real time.
 * The only thing we need to do here is write the selected option to the dropdown store.  Once again, the user's
 * selection is exposed in both component stores.
 *
 *
 * *** COMMON PITFALLS
 * Watch out for server errors.
 * Make sure the  store has boostrapped before referencing date values.
 * You will nearly always need to instantiate the SelectableDateRangeStore
 * in the parent's constructor.
 *
 * @todo: Add a way to reset this component to its initial default state.
 */

export default class SelectableDateRange extends React.Component<any> {
  selectableDateRangeStore: SelectableDateRangeStore;

  static propTypes = {
    className: PropTypes.string,
    defaultDateRange: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Map)]),
    disabled: PropTypes.bool,
    endDateLabel: PropTypes.string,
    label: PropTypes.string,
    maxRange: PropTypes.number,
    onChange: PropTypes.func,
    onDateChange: PropTypes.func,
    options: ImmutablePropTypes.listOf(
      ImmutablePropTypes.map
      /*
      contains({
        id: PropTypes.string,
        name: PropTypes.string,
        //"Start date"
        start_date: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number
        ]),

        //"End date"
        end_date: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number
        ])
      })
      */
    ),
    placeholder: PropTypes.string,
    preventInvalidChange: PropTypes.bool,
    shouldShowErrors: PropTypes.bool,
    startDateLabel: PropTypes.string,
    storeName: PropTypes.string.isRequired
  };

  static defaultProps = {
    endDateLabel: 'End Date',
    onChange: () => {},
    onDateChange: () => {},
    preventInvalidChange: false,
    shouldShowErrors: true,
    startDateLabel: 'Start Date',
    options: dateRangeOptions
  };

  constructor(props: string) {
    super(props);
    this.initialize();
  }

  getSelectableDateRangeStore(): * {
    return setUpStore(SelectableDateRangeStore, this.props.storeName);
  }

  initialize() {
    const selectableDateRangeStore = this.getSelectableDateRangeStore();

    // Allow previous selection to persist if user is returning to our view with the selector
    if (
      !selectableDateRangeStore
        .getSimpleDropdownStore()
        .getSelectedItem()
        .isEmpty() &&
      selectableDateRangeStore.getDateRangeStore().isStartDateValid() &&
      selectableDateRangeStore.getDateRangeStore().isEndDateValid()
    ) {
      callTargetedAction({
        name: simpleDropdownActions.SET_SELECTED_ITEM,
        payload: selectableDateRangeStore.getSimpleDropdownStore().getSelectedItem(),
        targetStore: selectableDateRangeStore.getSimpleDropdownStoreName()
      });

      this.setStartAndEndDates({
        startDate: selectableDateRangeStore.getDateRangeStore().getStartDate(),
        endDate: selectableDateRangeStore.getDateRangeStore().getEndDate()
      });
    } else {
      const defaultOption = this.getDefaultOption();
      callTargetedAction({
        name: simpleDropdownActions.SET_SELECTED_ITEM,
        payload: defaultOption,
        targetStore: selectableDateRangeStore.getSimpleDropdownStoreName()
      });

      this.setStartAndEndDates({
        startDate: defaultOption.get('start_date'),
        endDate: defaultOption.get('end_date')
      });
    }
  }

  getDefaultOption(): Map<string, *> {
    if (Map.isMap(this.props.defaultDateRange)) {
      return this.props.defaultDateRange;
    }

    return this.props.defaultDateRange
      ? this.getCurrentDateRangeOptions().find(
          (option) => option.get('id') === this.props.defaultDateRange
        )
      : this.props.options.first();
  }

  setStartAndEndDates({startDate, endDate}) {
    const selectableDateRangeStore = this.getSelectableDateRangeStore();
    callTargetedAction({
      name: dateRangeActions.SET_START_DATE,
      payload: startDate,
      targetStore: selectableDateRangeStore.getDateRangeStoreName()
    });
    callTargetedAction({
      name: dateRangeActions.SET_END_DATE,
      payload: endDate,
      targetStore: selectableDateRangeStore.getDateRangeStoreName()
    });
    callTargetedAction({
      name: dateRangeActions.VALIDATE_FORM,
      targetStore: selectableDateRangeStore.getDateRangeStoreName()
    });
  }

  onRangeSelect = (option) => {
    this.setStartAndEndDates({
      startDate: option.get('start_date'),
      endDate: option.get('end_date')
    });

    // Fire the optional callback here.
    this.props.onChange(option);

    /**
     * When working with this component, I noticed that onChange only happens when you select an option from
     * the dropdown, _not_ when the date itself changes. This prop was added so things could be done when the
     * date was changed manually.
     */
    this.props.onDateChange();
  };

  onDateCustomization = debounce(() => {
    const custom = this.getCurrentDateRangeOptions().find(
      (option) => option.get('id') === RANGES.CUSTOM
    );
    callTargetedAction({
      name: simpleDropdownActions.SET_SELECTED_ITEM,
      payload: custom,
      targetStore: this.getSelectableDateRangeStore().getSimpleDropdownStoreName()
    });

    this.props.onDateChange();
  }, 500);

  getCurrentDateRangeOptions(): List<Map<string, *>> {
    const dateRangeStore = this.getSelectableDateRangeStore().getDateRangeStore();
    const startDate = dateRangeStore.getStartDate();
    const endDate = dateRangeStore.getEndDate();

    const customOption = Map({
      name: RANGES.CUSTOM,
      id: RANGES.CUSTOM,
      start_date: startDate,
      end_date: endDate
    });

    return this.props.options.push(customOption);
  }

  render(): React.Node {
    const selectableDateRangeStore = this.getSelectableDateRangeStore();
    const item = selectableDateRangeStore.getSimpleDropdownStore().getSelectedItem();
    return (
      <div className={`selectable-date-range ${this.props.className ? this.props.className : ''}`}>
        <SimpleDropdown
          className='selectable-date-range__item selectable-date-range__item--simple-dropdown'
          destroyStoreOnUnmount={false}
          disabled={this.props.disabled}
          label={this.props.label}
          onChange={this.onRangeSelect}
          options={this.getCurrentDateRangeOptions()}
          placeholder={this.props.placeholder}
          storeName={selectableDateRangeStore.getSimpleDropdownStoreName()}
        />
        {item.get('shouldHideDateRangeSelector', false) ? null : (
          <DateRange
            allowNull
            className='selectable-date-range__item selectable-date-range__item--date-range'
            disabled={this.props.disabled}
            endDateLabel={this.props.endDateLabel}
            key={item.get('id')}
            maxRange={this.props.maxRange}
            onValidChange={this.onDateCustomization}
            onInvalidChange={this.onDateCustomization}
            preventInvalidChange={this.props.preventInvalidChange}
            resetStoreOnUnmount={false}
            shouldShowErrors={this.props.shouldShowErrors}
            startDateLabel={this.props.startDateLabel}
            storeName={selectableDateRangeStore.getDateRangeStoreName()}
          />
        )}
      </div>
    );
  }
}
