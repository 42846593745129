import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {callAction} from 'client/framework';
import LoadingIndicator from 'generic/LoadingIndicator.react';
import Button from 'sg/Button/Button.react';
import {Section as SectionV1} from 'resources/GeneratedModels/Section/SectionModel.v1';
import {AssignmentModelV3} from 'resources/GeneratedModels/Assignment/AssignmentModel.v3';
import teacherAssignmentV2IEActions from '../TeacherAssignmentV2IE.actions';
import teacherAssignmentV2IEStore from '../TeacherAssignmentV2IE.store';
import questionsListActions from '../../../shared/QuestionsList/QuestionsListActions';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import 'client/InteractionEngineV2/shared/AssignmentsV2SharedStyles/assignment-v2-ie-details.scss';

export default class AssignmentV2IEDetails extends React.Component {
  static propTypes = {
    assignment: PropTypes.any,
    isReady: PropTypes.bool,
    topBanner: PropTypes.node
  };

  componentDidMount() {
    if (process.env.IS_BROWSER) {
      global.scrollTo(0, 0);
    }
  }

  setActiveSection = (sectionId) => {
    if (teacherAssignmentV2IEStore.getActiveSectionId() === sectionId) {
      return;
    }

    callAction(teacherAssignmentV2IEActions.SET_SELECTED_ACTIVE_SECTION_ID, sectionId);
    // When moving from section to section, wipe the currently active question or you will get into a spooky state.
    callAction(questionsListActions.SET_ACTIVE_QUESTION, null);
    callAction(questionsListActions.SET_ACTIVE_QUESTION_SET, null);
  };

  render() {
    if (!this.props.isReady) {
      return <LoadingIndicator />;
    }
    const assignment = this.props.assignment;
    const activeSectionId = teacherAssignmentV2IEStore.getActiveSectionId();
    return (
      <div className='assignment-details'>
        {this.props.topBanner}
        <div className='assignment-details__card card card--no-padding'>
          <AssignmentSectionsList
            assignment={assignment}
            activeSectionId={activeSectionId}
            onSectionClick={this.setActiveSection}
          />
          <AssignmentContentArea assignment={assignment} activeSectionId={activeSectionId} />
        </div>
      </div>
    );
  }
}

class AssignmentSectionsList extends React.Component {
  static propTypes = {
    assignment: PropTypes.instanceOf(AssignmentModelV3).isRequired,
    onSectionClick: PropTypes.func.isRequired,
    activeSectionId: PropTypes.string
  };

  render() {
    const {assignment, activeSectionId, onSectionClick} = this.props;
    const timeLimit = assignment.hasSections()
      ? assignment.getSections().reduce((acc, section) => acc + section.getTimeLimit(), 0)
      : assignment.getTimeLimit();
    const isExamDetailsActive = activeSectionId === null;
    return (
      <div className='assignment-sections'>
        <div
          className={classnames('assignment-sections__overview', {
            'assignment-sections__overview--active': isExamDetailsActive
          })}
        >
          <div>
            <h2 className='assignment-sections__heading'>Assessment Details</h2>
            <div className='assignment-sections__about'>
              <span className='fa fa-clock-o' /> {timeLimit} min •{' '}
              {assignment.getMeta().getCountOfQuestions()} questions
            </div>
          </div>
          <ViewSectionButton
            isActive={isExamDetailsActive}
            sectionId={null}
            onSectionClick={this.props.onSectionClick}
          />
        </div>
        <ul className='assignment-sections__list'>
          {assignment.getSortedSections().map((section, i) => (
            <AssignmentSection
              key={i}
              section={section}
              activeSectionId={activeSectionId}
              onSectionClick={onSectionClick}
            />
          ))}
        </ul>
      </div>
    );
  }
}

class AssignmentSection extends React.Component {
  static propTypes = {
    section: PropTypes.instanceOf(SectionV1).isRequired,
    onSectionClick: PropTypes.func.isRequired,
    activeSectionId: PropTypes.string
  };

  render() {
    const section = this.props.section;
    const isActive = this.props.activeSectionId === section.getId();
    const itemClass = classnames('assignment-sections__section', {
      'assignment-sections__section--active': isActive
    });
    return (
      <li className={itemClass}>
        <div className='assignment-sections__details-wrapper'>
          <h3 className='assignment-sections__name'>
            <MarkdownRendererV2 text={section.getTitle()} />
          </h3>
          <div className='assignment-sections__about'>
            <span className='fa fa-clock-o' />
            &nbsp;
            {section.getTimeLimit()} min • {section.getMeta().getCountOfQuestions()} questions
          </div>
        </div>
        <ViewSectionButton
          isActive={this.props.activeSectionId === section.getId()}
          sectionId={section.getId()}
          onSectionClick={this.props.onSectionClick}
        />
      </li>
    );
  }
}

class AssignmentContentArea extends React.Component {
  static propTypes = {
    assignment: PropTypes.instanceOf(AssignmentModelV3).isRequired,
    activeSectionId: PropTypes.string
  };

  getActiveSection() {
    return this.props.assignment
      .getSortedSections()
      .find((section) => section.getId() === this.props.activeSectionId);
  }

  render() {
    const {activeSectionId, assignment} = this.props;
    const isViewingSection = activeSectionId !== null;
    const activeItem = isViewingSection ? this.getActiveSection() : assignment;

    const message =
      !isViewingSection && activeItem.getMessage() ? (
        <div>
          <dt className='assignment-details__heading'>Message</dt>
          <dd className='assignment-details__content'>
            <MarkdownRendererV2 text={activeItem.getMessage()} />
          </dd>
        </div>
      ) : null;

    const description = activeItem.getDescription() ? (
      <div>
        <dt className='assignment-details__heading'>Description</dt>
        <dd className='assignment-details__content'>
          <MarkdownRendererV2 text={activeItem.getDescription()} />
        </dd>
      </div>
    ) : null;

    const instructions = activeItem.getInstructions() ? (
      <div>
        <dt className='assignment-details__heading'>Instructions</dt>
        <dd className='assignment-details__content'>
          <MarkdownRendererV2 text={activeItem.getInstructions()} />
        </dd>
      </div>
    ) : null;

    return (
      <div className='assignment-details__content-wrapper'>
        <h1 className='assignment-details__name'>
          <MarkdownRendererV2
            text={isViewingSection ? activeItem.getTitle() : activeItem.getName()}
          />
        </h1>
        <dl>
          {description}
          {instructions}
          {message}
        </dl>
      </div>
    );
  }
}

class ViewSectionButton extends React.Component {
  static propTypes = {
    isActive: PropTypes.bool,
    sectionId: PropTypes.any,
    onSectionClick: PropTypes.func
  };

  handleSectionChange = () => {
    if (this.props.isActive) {
      return;
    }
    this.props.onSectionClick(this.props.sectionId);
  };

  render() {
    return (
      <Button
        small
        className='assignment-section-view-btn'
        color={this.props.isActive ? 'translucent' : 'green'}
        disabled={this.props.isActive}
        onClick={this.handleSectionChange}
        onKeyDown={this.handleSectionChange}
        text='View'
      />
    );
  }
}
