/**
 * # DropdownItem
 *
 * A component that represents an item within the Dropdown molecule.
 *
 * ## Props<C Extends React.ElementType>
 *
 * - `bold?: boolean` (optional): Whether the text should be displayed in bold. Default is `true`.
 * - `checked?: boolean` (optional): Whether the item is checked. Default is `false`.
 * - `children: React.ReactNode`: The content of the dropdown item.
 * - `className?: string` (optional): Additional CSS class name for the dropdown item.
 * - `closeDropdown?: () => void` (optional): Callback function to close the dropdown.
 * - `color?: 'black' | 'blue' | 'bright-orange' | 'green' | 'inherit' | 'red' | 'white'` (optional): The color of the dropdown item. Default is `'black'`.
 * - `disabled?: boolean` (optional): Whether the dropdown item is disabled. Default is `false`.
 * - `icon?: IconProp` (optional): The icon to display in the dropdown item.
 * - `iconColor?: IconColors` (optional): The color of the icon. Default is `'black'`.
 * - `iconProps?: IconProps` (optional): Additional props for the icon component.
 * - `onCheckboxChange?: (e: React.ChangeEvent<HTMLInputElement>) => void` (optional): Callback function
 *   when the checkbox in the dropdown item is changed.
 * - `onClick?: (e: React.MouseEvent<HTMLElement>) => void` (optional): Callback function when the dropdown
 *   item is clicked.
 * - `preventDefaultClickHandler?: Whether or not the item will preventDefault on the click event. You should only ever need to set this to false if you need the original click event to propagate to your provided as element (ie. <a> or <Link>).
 * - `selected?: boolean` (optional): Whether the dropdown item is selected. Default is `false`.
 * - `subtext?: string` (optional): The subtext to display in the dropdown item.
 * - `textProps?: Partial<TextProps>` (optional): Additional props for the Text component.
 * - `subtextProps?: Partial<TextProps>` (optional): Additional props for the subtext Text component.
 * - `checkboxProps?: Partial<CheckboxProps>` (optional): Additional props for the Checkbox component.
 * - `withBottomBorder?: boolean` (optional): Whether to display a bottom border. Default is `false`.
 * - `overflowHidden?: boolean` (optional): Whether to hide overflow. Default is `false`.
 * - `noWrap?: boolean` (optional): Whether to wrap the text. Default is `true`.
 * - `as?:` C, the string name or Constructor of the element type. Defaults to 'div'.
 * - `...rest: C`: Any properties of the element exctending `React.ElementType`
 *
 * ## Usage
 *
 * ```tsx
 * import {Dropdown, DropdownItem} from '@albert-io/atomic';
 *
 * const MyComponent = () => {
 *   return (
 *    <Dropdown>
 *     <DropdownItem
 *       bold={true}
 *       checked={false}
 *       className="my-dropdown-item"
 *       closeDropdown={() => {}}
 *       color="black"
 *       disabled={false}
 *       icon="icon-name"
 *       iconColor="black"
 *       iconProps={{ size: 'small' }}
 *       onCheckboxChange={(e) => {}}
 *       onClick={(e) => {}}
 *       preventDefaultClickHandler={true}
 *       selected={false}
 *       subtext="Subtext"
 *       textProps={{ size: 'medium' }}
 *       subtextProps={{ size: 'small' }}
 *       checkboxProps={{ size: 'small' }}
 *       withBottomBorder={false}
 *       overflowHidden={false}
 *       noWrap={true}
 *     >
 *       Dropdown Item
 *     </DropdownItem>
 *    </Dropdown>
 *   );
 * };
 * ```
 */
import React, {forwardRef} from 'react';
import classnames from 'classnames';

import Text, {Props as TextProps, TextColor} from '../../atoms/Text/Text.react';
import Icon, {IconProp, IconColors, Props as IconProps} from '../../atoms/Icon/Icon.react';
import Checkbox, {CheckboxProps} from '../../atoms/Checkbox/Checkbox.react';

import './dropdown-item.scss';

type Color = 'black' | 'blue' | 'bright-orange' | 'green' | 'inherit' | 'red' | 'white';

interface Props extends PropsWithChildrenRequired, PropsWithClassNameOptional {
  bold?: boolean;
  checked?: boolean;
  closeDropdown?: () => void;
  color?: Color;
  disabled?: boolean;
  icon?: IconProp;
  iconColor?: IconColors;
  iconProps?: IconProps;
  onCheckboxChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  round?: boolean;
  /**
   * Whether or not the item will `preventDefault` on the click
   * event. You should only ever need to set this to `false` if
   * you need the original click event to propagate to your provided `as`
   * element (ie. `<a>` or `<Link>`).
   */
  preventDefaultClickHandler?: boolean;
  selected?: boolean;
  subtext?: string;
  textProps?: Partial<TextProps>;
  subtextProps?: Partial<TextProps>;
  checkboxProps?: Partial<CheckboxProps>;
  withBottomBorder?: boolean;
  overflowHidden?: boolean;
  noWrap?: boolean;
}

type DropdownItemProps<C extends React.ElementType> = PolymorphicComponentPropsWithRef<C, Props>;

type DropdownItemComponent = <C extends React.ElementType = 'div'>(
  props: DropdownItemProps<C>
) => React.ReactElement | null;

export const DropdownItem: DropdownItemComponent = forwardRef(
  <C extends React.ElementType = 'div'>(
    {
      as,
      bold = true,
      checked = false,
      children,
      className,
      closeDropdown = () => {},
      color = 'black',
      disabled = false,
      icon,
      iconColor = 'primary',
      iconProps,
      onCheckboxChange,
      onClick = () => {},
      preventDefaultClickHandler = true,
      round = false,
      selected = false,
      subtext,
      textProps,
      withBottomBorder,
      subtextProps,
      checkboxProps,
      overflowHidden = false,
      noWrap = true,
      ...restProps
    }: DropdownItemProps<C>,
    ref: PolymorphicRef<C>
  ) => {
    const WrapperComponent = as || 'div';
    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      if (disabled) return;
      onClick(e);
      if (preventDefaultClickHandler) {
        e.preventDefault();
      }
      closeDropdown();
    };

    const textColors = {
      black: 'secondary',
      blue: 'brand',
      'bright-orange': 'warning',
      green: 'positive',
      inherit: 'inherit',
      red: 'negative',
      white: 'primary-inverse'
    };

    return (
      <WrapperComponent
        className={classnames('m-dropdown-item', className, {
          'm-dropdown-item--disabled': disabled,
          'm-dropdown-item--selected': selected,
          'm-dropdown-item--with-bottom-border': withBottomBorder,
          'm-dropdown-item--with-checkbox': !!onCheckboxChange,
          'm-dropdown-item--as-button': WrapperComponent === 'button'
        })}
        onClick={handleClick}
        aria-disabled={disabled}
        ref={ref}
        {...restProps}
      >
        {onCheckboxChange && (
          <Checkbox
            className='m-dropdown-item__checkbox'
            onChange={onCheckboxChange}
            aria-hidden={false}
            checked={checked}
            round={round}
            {...checkboxProps}
          />
        )}
        <div
          className={classnames('m-dropdown-item__label-wrapper', {
            'm-dropdown-item__checkbox-margin': !!onCheckboxChange && !subtext,
            'u-mar-r_2': !!icon && !overflowHidden,
            'm-dropdown-item__label-wrapper--with-icon': !!icon && overflowHidden
          })}
        >
          <Text
            bold={bold}
            as='div'
            color={textColors[color] as TextColor}
            className={classnames(`m-dropdown-item__main-text--${color}`, {
              'u-text-overflow_ellipsis u-overflow-x_hidden': overflowHidden,
              'u-white-space_nowrap': noWrap
            })}
            disabled={disabled}
            {...textProps}
          >
            {children}
          </Text>
          {subtext && (
            <Text color='tertiary' size='s' disabled={disabled} {...subtextProps}>
              {subtext}
            </Text>
          )}
        </div>
        {icon && (
          <Icon
            className={classnames(
              'm-dropdown-item__icon',
              {
                'm-dropdown-item__checkbox-margin': !!onCheckboxChange && !subtext
              },
              iconProps?.className
            )}
            icon={icon}
            color={iconColor}
            emphasis={disabled ? 'disabled' : 'lowest'}
            {...iconProps}
          />
        )}
      </WrapperComponent>
    );
  }
);

export default DropdownItem;
