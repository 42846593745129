import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import classnames from 'classnames';
import {callAction} from '../../framework';
import subheaderActions from './SubheaderActions';
import subheaderStore from './SubheaderStore';
import './subheader.scss';

// TODO: Refactor this component
export default class Subheader extends React.Component {
  static defaultProps = {
    onLinkClick: () => {}
  };

  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    links: PropTypes.object,
    subpath: PropTypes.string,
    activePath: PropTypes.string,
    leftContent: PropTypes.node,
    rightContent: PropTypes.node,
    onLinkClick: PropTypes.func
  };

  render() {
    const subpath = this.props.subpath;
    const activePath = this.props.activePath;
    const links = this.props.links;
    const leftContent = this.props.leftContent;
    const rightContent = this.props.rightContent;
    const showMobileMenu = subheaderStore.showMobileMenu();

    const subheaderClasses = classnames('subheader', this.props.className, {
      'subheader--has-links': links,
      'show-mobile-menu': showMobileMenu
    });
    const title = this.props.title ? (
      <h1 className='subheader__title'>{this.props.title}</h1>
    ) : null;
    return (
      <div className={subheaderClasses}>
        <div className='subheader__wrapper'>
          <div className='subheader__content'>
            {title}
            <SubheaderLinks
              subpath={subpath}
              activePath={activePath}
              links={links}
              onLinkClick={this.props.onLinkClick}
            />
          </div>
          <SubheaderCustomContent leftContent={leftContent} rightContent={rightContent} />
        </div>
      </div>
    );
  }
}

export class SubheaderLinks extends React.Component {
  render() {
    const {subpath, activePath, links, onLinkClick} = this.props;

    let renderContent = null;
    if (links) {
      renderContent = (
        <div className='subheader__links'>
          {links
            .map((link, key) => (
              <SubheaderLink
                link={link}
                key={key}
                subpath={subpath}
                activePath={activePath}
                onLinkClick={onLinkClick}
              />
            ))
            .toList()}
        </div>
      );
    }
    return renderContent;
  }
}

class SubheaderLink extends React.Component {
  static defaultProps = {
    subpath: '',
    activePath: '',
    onLinkClick: () => {}
  };

  render() {
    const {link, subpath, activePath} = this.props;
    const route = link.get('route');
    const path = subpath + route;
    const linkName = link.get('name');
    if (!linkName) {
      return null;
    } else if (!path || path === activePath) {
      const linkClass = classnames('subheader__links-link', {active: path === activePath});

      return (
        <span
          className={linkClass}
          onClick={(e) => {
            callAction(subheaderActions.CLOSE_MOBILE_MENU);
            this.props.onLinkClick(link, e);
          }}
        >
          {linkName}
        </span>
      );
    } else {
      return (
        <Link
          to={path}
          className={'subheader__links-link'}
          onClick={(e) => {
            callAction(subheaderActions.CLOSE_MOBILE_MENU);
            this.props.onLinkClick(link, e);
          }}
        >
          {link.get('name')}
        </Link>
      );
    }
  }
}

class SubheaderCustomContent extends React.Component {
  render() {
    const leftContent = this.props.leftContent;
    const rightContent = this.props.rightContent;
    let renderContent = null;
    if (leftContent || rightContent) {
      renderContent = (
        <div className='subheader__custom-content-wrapper'>
          <div className='subheader__content subheader__custom-content--left'>
            {leftContent ? leftContent : null}
          </div>
          <div className='subheader__content subheader__custom-content--right'>
            {rightContent ? rightContent : null}
          </div>
        </div>
      );
    }
    return renderContent;
  }
}
