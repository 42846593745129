import {resource} from '@albert-io/json-api-framework/request/builder';
import {genericMandarkRequest} from 'resources/mandark.resource';

import masqueradeStore from 'generic/Masquerade/Masquerade.store';

export const getAssignmentQuery = (assignmentId: string, isAdmin: boolean) => {
  const teacherId = masqueradeStore.getUserIdByMasqueradeState();
  return genericMandarkRequest(
    'get',
    resource('assignments_v3')
      .mandarkEndpoint(['assignments_v3', assignmentId])
      .include('question_sets_v1.questions_v1')
      .include('question_sets_v1.guide_levels_v2', teacherId)
      .include('question_sets_v1.subject_v2', teacherId)
      .include('classrooms_v1.students_v2.student_assignments_v1')
      .include('students_v2')
      .include('teacher_v1')
      .include('question_sets_v1.tags_v1')
      .withMeta('assignment_v3')
      .withMeta('classroom_v1')
      .filter({
        included: {
          ...(isAdmin
            ? {}
            : {
                students_v2: {
                  classrooms_v1: {
                    teachers_v1: teacherId
                  }
                },
                classrooms_v1: {
                  teachers_v1: teacherId
                }
              }),
          'classrooms_v1.students_v2.student_assignments_v1': {
            id: assignmentId,
            ...(isAdmin ? {} : {teacher_v1: teacherId})
          }
        }
      })
      .customQuery(
        {
          meta: {
            teacher_id: teacherId
          }
        },
        teacherId
      )
      .done()
  );
};
