import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';
import {Map} from 'immutable';
import createLocalStore, {localStorePropType} from 'lib/decorators/createLocalStore';
import './timer.scss';
import systemTimeOffsetStore from '../SystemTimeOffset/SystemTimeOffset.store';

@createLocalStore(
  new Map({
    counter: null,
    startTime: null,
    secondsElapsed: null,
    hasHandledOnOutOfTime: false
  })
)
export default class Timer extends React.Component {
  static propTypes = {
    secondsRemaining: PropTypes.number.isRequired,
    totalSeconds: PropTypes.number.isRequired,
    className: PropTypes.string,
    setProperty: PropTypes.func,
    store: localStorePropType,
    onOutOfTime: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.intervalId = null;
    this.initialize();
  }

  componentDidUpdate() {
    this.handleOnOutOfTime();
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  initialize() {
    const secondsElapsed = this.props.totalSeconds - this.props.secondsRemaining;
    const startTime = systemTimeOffsetStore.getCurrentTime().subtract(secondsElapsed, 'seconds');
    this.props.setProperty('startTime', startTime);

    this.updateTime();
    if (process.env.IS_BROWSER) {
      this.intervalId = setInterval(this.updateTime, 1000);
    }
  }

  isOutOfTime() {
    return this.getUpdatedSecondsRemaining() <= 0;
  }

  updateTime = () => {
    const startTime = this.props.store.get('startTime');
    const secondsElapsed = systemTimeOffsetStore.getCurrentTime().diff(startTime, 'seconds');
    this.props.setProperty('secondsElapsed', secondsElapsed);

    if (this.intervalId && this.isOutOfTime()) {
      clearInterval(this.intervalId);
    }
  };

  formatTime(time) {
    return `${time}`.length === 2 ? time : `0${time}`;
  }

  getUpdatedSecondsRemaining() {
    return this.props.totalSeconds - this.props.store.get('secondsElapsed');
  }

  handleOnOutOfTime() {
    if (
      !this.props.onOutOfTime ||
      !this.isOutOfTime() ||
      this.props.store.get('hasHandledOnOutOfTime')
    ) {
      return;
    }
    setTimeout(() => {
      this.props.setProperty('hasHandledOnOutOfTime', true);
      this.props.onOutOfTime();
    }, 0);
  }

  generateTimer(timerSeconds) {
    const totalTime = moment.duration(timerSeconds, 'seconds');
    const hours = Math.floor(totalTime.asHours());
    const minutes = Math.floor(totalTime.asMinutes());
    const seconds = Math.floor(totalTime.asSeconds());

    const formattedMinutes = this.formatTime(minutes - hours * 60);
    const formattedSeconds = this.formatTime(seconds - minutes * 60);

    const hasHours = moment.duration(this.props.totalSeconds, 'seconds').asHours() > 0;
    if (hasHours) {
      return `${this.formatTime(hours)}:${formattedMinutes}:${formattedSeconds}`;
    }

    return `${formattedMinutes}:${formattedSeconds}`;
  }

  render() {
    /**
     * Because the <Timer /> component can tick during the render, and lifecycle events, it's possible the
     * `secondsElapsed` at this point will be greater than the `totalSeconds` allowed. This ensures
     * we only ever display the maximum allowed time.
     */
    const secondsElapsed = Math.min(
      this.props.store.get('secondsElapsed'),
      this.props.totalSeconds
    );
    const classNames = classnames('timer', this.props.className, {
      'timer--almost-out': this.getUpdatedSecondsRemaining() <= 60
    });

    return (
      <div className={classNames}>
        <span className='fa fa-clock-o' />
        {this.generateTimer(secondsElapsed)} / {this.generateTimer(this.props.totalSeconds)}
      </div>
    );
  }
}
