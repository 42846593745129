import {StateCreator} from 'zustand';

import type {ContentDiscoveryStoreState} from 'client/components/ContentDiscovery/store/ContentDiscoveryStore.types';
import makeConstants from 'lib/makeConstants';

const viewLocations = ['IN_ASSIGNMENT_BUILDER', 'IN_FOLDER_VIEW'] as const;
export type ViewLocations = (typeof viewLocations)[number];

export const pages = makeConstants('review', 'add', 'settings');
type PageTypes = (typeof pages)[keyof typeof pages];

type NavigationState = {
  currentPage: PageTypes;
  viewLocation: ViewLocations;
  initialName: string;
};

type NavigationActions = {
  setViewLocation: (location: ViewLocations) => void;
  setInitialName: (name: string) => void;
  setCurrentPage: (string: PageTypes, goBack?: boolean) => void;
  handleHashChange: (e) => void; // handler for hashchange event
  reset: () => void;
};

export type NavigationStoreSlice = NavigationState & NavigationActions;

const initialState = {
  currentPage: pages.review,
  viewLocation: viewLocations[0],
  initialName: ''
};

const getPageFromUrl = (url) => {
  if (url.includes(`#${pages.add}`)) return pages.add;
  if (url.includes(`#${pages.review}`)) return pages.review;
  if (url.includes(`#${pages.settings}`)) return pages.settings;
  return null;
};

export const createNavigationStoreSlice: StateCreator<
  ContentDiscoveryStoreState,
  [['zustand/immer', never]],
  [],
  NavigationStoreSlice
> = (set, _get, _): NavigationStoreSlice => ({
  ...initialState,
  setViewLocation: (viewLocation) =>
    set((state) => {
      state.navigation.viewLocation = viewLocation;
    }),

  setInitialName: (name) =>
    set((state) => {
      state.navigation.initialName = name;
    }),

  setCurrentPage: (newPage, goBack = true) =>
    set((state) => {
      switch (newPage) {
        case pages.add:
          window.location.hash = 'add';
          state.navigation.currentPage = pages.add;
          break;
        case pages.review:
          state.navigation.currentPage = pages.review;
          if (goBack) {
            window.history.back();
          }
          break;
        case pages.settings:
          state.navigation.currentPage = pages.settings;
          break;
        default:
      }
    }),

  handleHashChange: (e) =>
    set((state) => {
      const {oldURL, newURL} = e;
      const oldPage = getPageFromUrl(oldURL);
      const newPage = getPageFromUrl(newURL);

      if (oldPage && newPage && oldPage !== newPage) {
        state.navigation.currentPage = newPage;
      }
    }),

  reset: () =>
    set((state) => {
      state.navigation = {...state.navigation, ...initialState};
      window.history.replaceState('', '', window.location.pathname); // remove any hashes
    })
});
