import React from 'react';
import classnames from 'classnames';
import './inline-area.scss';

/**
 * @example
 * [inline]
 * Some element
 *
 * Some other element
 * [/inline]
 */
const regex = /^\[inline(?:\s+align=(center))?]((?:[\w\W](?!\[\/inline\]))+[\w\W])\[\/inline\]/;

const inlineAreaRule = {
  order: 0,
  match: (source) => regex.exec(source),
  parse: (capture, recurseParse, state) => {
    // eslint-disable-next-line no-unused-vars
    const [_, align, content] = capture;
    return {
      content: recurseParse(content, state),
      align
    };
  },
  react(node, output) {
    return (
      <div
        key={node.tokenId}
        className={classnames('markdown-renderer-inline-area', {
          'markdown-renderer-inline-area--align-center': node.align === 'center'
        })}
      >
        {output(node.content)}
      </div>
    );
  }
};

export default inlineAreaRule;
