import {library, config} from '@fortawesome/fontawesome-svg-core';

import {faCcAmex} from '@fortawesome/free-brands-svg-icons/faCcAmex';
import {faCcMastercard} from '@fortawesome/free-brands-svg-icons/faCcMastercard';
import {faCcVisa} from '@fortawesome/free-brands-svg-icons/faCcVisa';
import {faFacebookF} from '@fortawesome/free-brands-svg-icons/faFacebookF';
import {faGoogle} from '@fortawesome/free-brands-svg-icons/faGoogle';
import {faInstagram} from '@fortawesome/free-brands-svg-icons/faInstagram';
import {faTwitter} from '@fortawesome/free-brands-svg-icons/faTwitter';
import {faAlignRight} from '@fortawesome/pro-solid-svg-icons/faAlignRight';
import {faArchive} from '@fortawesome/pro-solid-svg-icons/faArchive';
import {faArrowsRotate as falArrowsRotate} from '@fortawesome/pro-light-svg-icons/faArrowsRotate';
import {faArrowsRotate as farArrowsRotate} from '@fortawesome/pro-regular-svg-icons/faArrowsRotate';
import {faArrowDown} from '@fortawesome/pro-solid-svg-icons/faArrowDown';
import {faArrowLeft} from '@fortawesome/pro-solid-svg-icons/faArrowLeft';
import {faArrowRight} from '@fortawesome/pro-solid-svg-icons/faArrowRight';
import {faArrowsAltH} from '@fortawesome/pro-solid-svg-icons/faArrowsAltH';
import {faArrowsV} from '@fortawesome/pro-solid-svg-icons/faArrowsV';
import {faArrowToTop} from '@fortawesome/pro-solid-svg-icons/faArrowToTop';
import {faArrowUp} from '@fortawesome/pro-solid-svg-icons/faArrowUp';
import {faArrowUpRightFromSquare} from '@fortawesome/pro-solid-svg-icons/faArrowUpRightFromSquare';
import {faAsterisk} from '@fortawesome/pro-solid-svg-icons/faAsterisk';
import {faBackpack} from '@fortawesome/pro-solid-svg-icons/faBackpack';
import {faBackwardStep} from '@fortawesome/pro-solid-svg-icons/faBackwardStep';
import {faBan} from '@fortawesome/pro-solid-svg-icons/faBan';
import {faBarsFilter} from '@fortawesome/pro-solid-svg-icons/faBarsFilter';
import {faBarcodeRead} from '@fortawesome/pro-solid-svg-icons/faBarcodeRead';
import {faBars} from '@fortawesome/pro-solid-svg-icons/faBars';
import {faBold} from '@fortawesome/pro-solid-svg-icons/faBold';
import {faBookmark} from '@fortawesome/pro-solid-svg-icons/faBookmark';
import {faBooks} from '@fortawesome/pro-solid-svg-icons/faBooks';
import {faBullseyeArrow as farBullseyeArrow} from '@fortawesome/pro-regular-svg-icons/faBullseyeArrow';
import {faCalendar} from '@fortawesome/pro-solid-svg-icons/faCalendar';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import {faCaretLeft} from '@fortawesome/pro-solid-svg-icons/faCaretLeft';
import {faCaretRight} from '@fortawesome/pro-solid-svg-icons/faCaretRight';
import {faCaretUp} from '@fortawesome/pro-solid-svg-icons/faCaretUp';
import {faCheck} from '@fortawesome/pro-solid-svg-icons/faCheck';
import {faCertificate} from '@fortawesome/pro-solid-svg-icons/faCertificate';
import {faCheckCircle} from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import {faCheckDouble} from '@fortawesome/pro-solid-svg-icons/faCheckDouble';
import {faChevronDown} from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import {faChevronLeft} from '@fortawesome/pro-solid-svg-icons/faChevronLeft';
import {faChevronRight} from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import {faChevronUp} from '@fortawesome/pro-solid-svg-icons/faChevronUp';
import {faCircle} from '@fortawesome/pro-solid-svg-icons/faCircle';
import {faCircle1} from '@fortawesome/pro-solid-svg-icons/faCircle1';
import {faCircle2} from '@fortawesome/pro-solid-svg-icons/faCircle2';
import {faCircle3} from '@fortawesome/pro-solid-svg-icons/faCircle3';
import {faClipboard} from '@fortawesome/pro-solid-svg-icons/faClipboard';
import {faClock} from '@fortawesome/pro-solid-svg-icons/faClock';
import {faClone} from '@fortawesome/pro-solid-svg-icons/faClone';
import {faCloud} from '@fortawesome/pro-solid-svg-icons/faCloud';
import {faCloudUpload} from '@fortawesome/pro-solid-svg-icons/faCloudUpload';
import {faCode} from '@fortawesome/pro-solid-svg-icons/faCode';
import {faCog} from '@fortawesome/pro-solid-svg-icons/faCog';
import {faComment} from '@fortawesome/pro-solid-svg-icons/faComment';
import {faCopy} from '@fortawesome/pro-solid-svg-icons/faCopy';
import {faCreditCardBlank} from '@fortawesome/pro-solid-svg-icons/faCreditCardBlank';
import {faDownload} from '@fortawesome/pro-solid-svg-icons/faDownload';
import {faDownLeftAndUpRightToCenter} from '@fortawesome/pro-solid-svg-icons/faDownLeftAndUpRightToCenter';
import {faEllipsisH} from '@fortawesome/pro-solid-svg-icons/faEllipsisH';
import {faEllipsisV} from '@fortawesome/pro-solid-svg-icons/faEllipsisV';
import {faEnvelope} from '@fortawesome/pro-solid-svg-icons/faEnvelope';
import {faExchange} from '@fortawesome/pro-solid-svg-icons/faExchange';
import {faExclamation} from '@fortawesome/pro-solid-svg-icons/faExclamation';
import {faExclamationTriangle} from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import {faExpandArrowsAlt} from '@fortawesome/pro-solid-svg-icons/faExpandArrowsAlt';
import {faExpandAlt} from '@fortawesome/pro-regular-svg-icons/faExpandAlt';
import {faExternalLinkAlt} from '@fortawesome/pro-solid-svg-icons/faExternalLinkAlt';
import {faEye} from '@fortawesome/pro-solid-svg-icons/faEye';
import {faEyeSlash} from '@fortawesome/pro-solid-svg-icons/faEyeSlash';
import {faArrowToLeft} from '@fortawesome/pro-solid-svg-icons/faArrowToLeft';
import {faFax} from '@fortawesome/pro-solid-svg-icons/faFax';
import {faFileAlt} from '@fortawesome/pro-solid-svg-icons/faFileAlt';
import {faFileLines} from '@fortawesome/pro-solid-svg-icons/faFileLines';
import {faFilePlus} from '@fortawesome/pro-solid-svg-icons/faFilePlus';
import {faFilter} from '@fortawesome/pro-solid-svg-icons/faFilter';
import {faFolderOpen} from '@fortawesome/pro-solid-svg-icons/faFolderOpen';
import {faH1} from '@fortawesome/pro-solid-svg-icons/faH1';
import {faH2} from '@fortawesome/pro-solid-svg-icons/faH2';
import {faHeading} from '@fortawesome/pro-solid-svg-icons/faHeading';
import {faHistory} from '@fortawesome/pro-solid-svg-icons/faHistory';
import {faFolderPlus} from '@fortawesome/pro-solid-svg-icons/faFolderPlus';
import {faFrownOpen} from '@fortawesome/pro-solid-svg-icons/faFrownOpen';
import {faGreaterThan} from '@fortawesome/pro-solid-svg-icons/faGreaterThan';
import {faHandPointer} from '@fortawesome/pro-solid-svg-icons/faHandPointer';
import {faHeadset} from '@fortawesome/pro-solid-svg-icons/faHeadset';
import {faHorizontalRule} from '@fortawesome/pro-solid-svg-icons/faHorizontalRule';
import {faICursor} from '@fortawesome/pro-solid-svg-icons/faICursor';
import {faImage} from '@fortawesome/pro-solid-svg-icons/faImage';
import {faIndent} from '@fortawesome/pro-solid-svg-icons/faIndent';
import {faInfo} from '@fortawesome/pro-solid-svg-icons/faInfo';
import {faInfoCircle} from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import {faItalic} from '@fortawesome/pro-solid-svg-icons/faItalic';
import {faKey} from '@fortawesome/pro-solid-svg-icons/faKey';
import {faLevelUpAlt} from '@fortawesome/pro-solid-svg-icons/faLevelUpAlt';
import {faArrowLeftToLine} from '@fortawesome/pro-solid-svg-icons/faArrowLeftToLine';
import {faArrowRightToLine} from '@fortawesome/pro-solid-svg-icons/faArrowRightToLine';
import {faLink} from '@fortawesome/pro-solid-svg-icons/faLink';
import {faLightbulbOn} from '@fortawesome/pro-solid-svg-icons/faLightbulbOn';
import {faListOl} from '@fortawesome/pro-solid-svg-icons/faListOl';
import {faListUl} from '@fortawesome/pro-solid-svg-icons/faListUl';
import {faLock} from '@fortawesome/pro-solid-svg-icons/faLock';
import {faLongArrowAltRight} from '@fortawesome/pro-solid-svg-icons/faLongArrowAltRight';
import {faMagnifyingGlass} from '@fortawesome/pro-solid-svg-icons/faMagnifyingGlass';
import {faMagnifyingGlassPlus} from '@fortawesome/pro-solid-svg-icons/faMagnifyingGlassPlus';
import {faMinus} from '@fortawesome/pro-solid-svg-icons/faMinus';
import {faOutdent} from '@fortawesome/pro-solid-svg-icons/faOutdent';
import {faStickyNote} from '@fortawesome/pro-solid-svg-icons/faStickyNote';
import {faPencil} from '@fortawesome/pro-solid-svg-icons/faPencil';
import {faPause} from '@fortawesome/pro-solid-svg-icons/faPause';
import {faPencilAlt} from '@fortawesome/pro-solid-svg-icons/faPencilAlt';
import {faPhone} from '@fortawesome/pro-solid-svg-icons/faPhone';
import {faPlus} from '@fortawesome/pro-solid-svg-icons/faPlus';
import {faPlay} from '@fortawesome/pro-solid-svg-icons/faPlay';
import {faQuestion} from '@fortawesome/pro-solid-svg-icons/faQuestion';
import {faQuestionCircle} from '@fortawesome/pro-solid-svg-icons/faQuestionCircle';
import {faRetweet} from '@fortawesome/pro-solid-svg-icons/faRetweet';
import {faRotateLeft} from '@fortawesome/pro-solid-svg-icons/faRotateLeft';
import {faRotateRight} from '@fortawesome/pro-solid-svg-icons/faRotateRight';
import {faSchool} from '@fortawesome/pro-solid-svg-icons/faSchool';
import {faSearch} from '@fortawesome/pro-solid-svg-icons/faSearch';
import {faShare} from '@fortawesome/pro-solid-svg-icons/faShare';
import {faSliders} from '@fortawesome/pro-solid-svg-icons/faSliders';
import {faSlidersH} from '@fortawesome/pro-solid-svg-icons/faSlidersH';
import {faSortAmountDown} from '@fortawesome/pro-solid-svg-icons/faSortAmountDown';
import {faSortAmountUp} from '@fortawesome/pro-solid-svg-icons/faSortAmountUp';
import {faSparkles} from '@fortawesome/pro-solid-svg-icons/faSparkles';
import {faSpinner} from '@fortawesome/pro-solid-svg-icons/faSpinner';
import {faSpinnerThird} from '@fortawesome/pro-solid-svg-icons/faSpinnerThird';
import {faSubscript} from '@fortawesome/pro-solid-svg-icons/faSubscript';
import {faSuperscript} from '@fortawesome/pro-solid-svg-icons/faSuperscript';
import {faSync} from '@fortawesome/pro-solid-svg-icons/faSync';
import {faRedo} from '@fortawesome/pro-solid-svg-icons/faRedo';
import {faRedoAlt} from '@fortawesome/pro-solid-svg-icons/faRedoAlt';
import {faRepeat} from '@fortawesome/pro-solid-svg-icons/faRepeat';
import {faTable} from '@fortawesome/pro-solid-svg-icons/faTable';
import {faTag} from '@fortawesome/pro-solid-svg-icons/faTag';
import {faTextSize} from '@fortawesome/pro-solid-svg-icons/faTextSize';
import {faThumbsUp} from '@fortawesome/pro-solid-svg-icons/faThumbsUp';
import {faTimes} from '@fortawesome/pro-solid-svg-icons/faTimes';
import {faTrash} from '@fortawesome/pro-solid-svg-icons/faTrash';
import {faUmbrellaBeach} from '@fortawesome/pro-solid-svg-icons/faUmbrellaBeach';
import {faUnderline} from '@fortawesome/pro-solid-svg-icons/faUnderline';
import {faUndo} from '@fortawesome/pro-solid-svg-icons/faUndo';
import {faUnlink} from '@fortawesome/pro-solid-svg-icons/faUnlink';
import {faUnion} from '@fortawesome/pro-solid-svg-icons/faUnion';
import {faUpDown} from '@fortawesome/pro-solid-svg-icons/faUpDown';
import {faUpRightAndDownLeftFromCenter} from '@fortawesome/pro-solid-svg-icons/faUpRightAndDownLeftFromCenter';
import {faUser} from '@fortawesome/pro-solid-svg-icons/faUser';
import {faUserPlus} from '@fortawesome/pro-solid-svg-icons/faUserPlus';
import {faVectorSquare} from '@fortawesome/pro-solid-svg-icons/faVectorSquare';
import {faVideo} from '@fortawesome/pro-solid-svg-icons/faVideo';
import {faVolume} from '@fortawesome/pro-solid-svg-icons/faVolume';
import {faVolumeUp} from '@fortawesome/pro-solid-svg-icons/faVolumeUp';
import {faXmark} from '@fortawesome/pro-solid-svg-icons/faXmark';
import {faArchive as farArchive} from '@fortawesome/pro-regular-svg-icons/faArchive';
import {faArrowDown as farArrowDown} from '@fortawesome/pro-regular-svg-icons/faArrowDown';
import {faArrowRight as farArrowRight} from '@fortawesome/pro-regular-svg-icons/faArrowRight';
import {faArrowUp as farArrowUp} from '@fortawesome/pro-regular-svg-icons/faArrowUp';
import {faArrowUpFromBracket as farArrowUpFromBracket} from '@fortawesome/pro-regular-svg-icons/faArrowUpFromBracket';
import {faArrowsUpDown as farArrowUpDown} from '@fortawesome/pro-regular-svg-icons/faArrowsUpDown';
import {faArrowUpRight as farArrowUpRight} from '@fortawesome/pro-regular-svg-icons/faArrowUpRight';
import {faArrowUpRightFromSquare as farArrowUpRightFromSquare} from '@fortawesome/pro-regular-svg-icons/faArrowUpRightFromSquare';
import {faBan as farBan} from '@fortawesome/pro-regular-svg-icons/faBan';
import {faBell as farBell} from '@fortawesome/pro-regular-svg-icons/faBell';
import {faBook as farBook} from '@fortawesome/pro-regular-svg-icons/faBook';
import {faBooks as farBooks} from '@fortawesome/pro-regular-svg-icons/faBooks';
import {faBracketCurly as farBracketCurly} from '@fortawesome/pro-regular-svg-icons/faBracketCurly';
import {faCalendarAlt as farCalendarAlt} from '@fortawesome/pro-regular-svg-icons/faCalendarAlt';
import {faCaretRight as farCaretRight} from '@fortawesome/pro-regular-svg-icons/faCaretRight';
import {faChalkboard as farChalkboard} from '@fortawesome/pro-regular-svg-icons/faChalkboard';
import {faCheck as farCheck} from '@fortawesome/pro-regular-svg-icons/faCheck';
import {faCheckCircle as farCheckCircle} from '@fortawesome/pro-regular-svg-icons/faCheckCircle';
import {faCheckDouble as farCheckDouble} from '@fortawesome/pro-regular-svg-icons/faCheckDouble';
import {faChevronLeft as farChevronLeft} from '@fortawesome/pro-regular-svg-icons/faChevronLeft';
import {faChevronRight as farChevronRight} from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import {faCircle as farCircle} from '@fortawesome/pro-regular-svg-icons/faCircle';
import {faCirclePlus as farCirclePlus} from '@fortawesome/pro-regular-svg-icons/faCirclePlus';
import {faCircleExclamation as falCircleExclamation} from '@fortawesome/pro-light-svg-icons/faCircleExclamation';
import {faCircleExclamationCheck as falCircleExclamationCheck} from '@fortawesome/pro-light-svg-icons/faCircleExclamationCheck';
import {faCircleCheck as falCircleCheck} from '@fortawesome/pro-light-svg-icons/faCircleCheck';
import {faCircleXmark as farCircleXmark} from '@fortawesome/pro-regular-svg-icons/faCircleXmark';
import {faClockRotateLeft as farClockrotateLeft} from '@fortawesome/pro-regular-svg-icons/faClockRotateLeft';
import {faClock as farClock} from '@fortawesome/pro-regular-svg-icons/faClock';
import {faCloud as farCloud} from '@fortawesome/pro-regular-svg-icons/faCloud';
import {faCloudUpload as farCloudUpload} from '@fortawesome/pro-regular-svg-icons/faCloudUpload';
import {faCog as farCog} from '@fortawesome/pro-regular-svg-icons/faCog';
import {faComment as farComment} from '@fortawesome/pro-regular-svg-icons/faComment';
import {faConstruction as farConstruction} from '@fortawesome/pro-regular-svg-icons/faConstruction';
import {faDownload as farDownload} from '@fortawesome/pro-regular-svg-icons/faDownload';
import {faCopy as farCopy} from '@fortawesome/pro-regular-svg-icons/faCopy';
import {faEnvelope as farEnvelope} from '@fortawesome/pro-regular-svg-icons/faEnvelope';
import {faExclamationCircle as farExclamationCircle} from '@fortawesome/pro-regular-svg-icons/faExclamationCircle';
import {faExclamationTriangle as farExclamationTriangle} from '@fortawesome/pro-regular-svg-icons/faExclamationTriangle';
import {faEye as farEye} from '@fortawesome/pro-regular-svg-icons/faEye';
import {faEyeSlash as farEyeSlash} from '@fortawesome/pro-regular-svg-icons/faEyeSlash';
import {faFileAlt as farFileAlt} from '@fortawesome/pro-regular-svg-icons/faFileAlt';
import {faFileExport as farFileExport} from '@fortawesome/pro-regular-svg-icons/faFileExport';
import {faFileLines as farFileLines} from '@fortawesome/pro-regular-svg-icons/faFileLines';
import {faFilePlus as farFilePlus} from '@fortawesome/pro-regular-svg-icons/faFilePlus';
import {faFilter as farFilter} from '@fortawesome/pro-regular-svg-icons/faFilter';
import {faFolders as farFolders} from '@fortawesome/pro-regular-svg-icons/faFolders';
import {faFolderOpen as farFolderOpen} from '@fortawesome/pro-regular-svg-icons/faFolderOpen';
import {faFolderPlus as farFolderPlus} from '@fortawesome/pro-regular-svg-icons/faFolderPlus';
import {faFrownOpen as farFrownOpen} from '@fortawesome/pro-regular-svg-icons/faFrownOpen';
import {faFrown as farFrown} from '@fortawesome/pro-regular-svg-icons/faFrown';
import {faFolder as farFolder} from '@fortawesome/pro-regular-svg-icons/faFolder';
import {faGraduationCap as farGraduationCap} from '@fortawesome/pro-regular-svg-icons/faGraduationCap';
import {faHandPointLeft as farHandPointLeft} from '@fortawesome/pro-regular-svg-icons/faHandPointLeft';
import {faHeartCrack as farHeartCrack} from '@fortawesome/pro-regular-svg-icons/faHeartCrack';
import {faHexagonExclamation as farHexagonExclamation} from '@fortawesome/pro-regular-svg-icons/faHexagonExclamation';
import {faICursor as farICursor} from '@fortawesome/pro-regular-svg-icons/faICursor';
import {faInfoCircle as farInfoCircle} from '@fortawesome/pro-regular-svg-icons/faInfoCircle';
import {faKey as farKey} from '@fortawesome/pro-regular-svg-icons/faKey';
import {faLaugh as farLaugh} from '@fortawesome/pro-regular-svg-icons/faLaugh';
import {faLightbulbOn as farLightbulbOn} from '@fortawesome/pro-regular-svg-icons/faLightbulbOn';
import {faLink as farLink} from '@fortawesome/pro-regular-svg-icons/faLink';
import {faList as farList} from '@fortawesome/pro-regular-svg-icons/faList';
import {faMagnifyingGlass as farMagnifyingGlass} from '@fortawesome/pro-regular-svg-icons/faMagnifyingGlass';
import {faMagnifyingGlassPlus as farMagnifyingGlassPlus} from '@fortawesome/pro-regular-svg-icons/faMagnifyingGlassPlus';
import {faMusic as farMusic} from '@fortawesome/pro-regular-svg-icons/faMusic';
import {faPaperPlane as farPaperPlane} from '@fortawesome/pro-regular-svg-icons/faPaperPlane';
import {faPencil as farPencil} from '@fortawesome/pro-regular-svg-icons/faPencil';
import {faQuestion as farQuestion} from '@fortawesome/pro-regular-svg-icons/faQuestion';
import {faQuestionCircle as farQuestionCircle} from '@fortawesome/pro-regular-svg-icons/faQuestionCircle';
import {faRedo as farRedo} from '@fortawesome/pro-regular-svg-icons/faRedo';
import {faRotateRight as farRotateRight} from '@fortawesome/pro-regular-svg-icons/faRotateRight';
import {faFrown as falFrown} from '@fortawesome/pro-light-svg-icons/faFrown';
import {faSchool as farSchool} from '@fortawesome/pro-regular-svg-icons/faSchool';
import {faSortAmountUp as farSortAmountUp} from '@fortawesome/pro-regular-svg-icons/faSortAmountUp';
import {faSparkles as farSparkles} from '@fortawesome/pro-regular-svg-icons/faSparkles';
import {faSync as farSync} from '@fortawesome/pro-regular-svg-icons/faSync';
import {faTable as farTable} from '@fortawesome/pro-regular-svg-icons/faTable';
import {faThumbsUp as farThumbsUp} from '@fortawesome/pro-regular-svg-icons/faThumbsUp';
import {faTrash as farTrash} from '@fortawesome/pro-regular-svg-icons/faTrash';
import {faTrashCanSlash as farTrashCanSlash} from '@fortawesome/pro-regular-svg-icons/faTrashCanSlash';
import {faTrashAlt as farTrashAlt} from '@fortawesome/pro-regular-svg-icons/faTrashAlt';
import {faUserPlus as farUserPlus} from '@fortawesome/pro-regular-svg-icons/faUserPlus';
import {faUsers as farUsers} from '@fortawesome/pro-regular-svg-icons/faUsers';
import {faTimes as farTimes} from '@fortawesome/pro-regular-svg-icons/faTimes';
import {faVolume as farVolume} from '@fortawesome/pro-regular-svg-icons/faVolume';
import {faArrowToTop as farArrowToTop} from '@fortawesome/pro-regular-svg-icons/faArrowToTop';
import {faHandPointer as farHandPointer} from '@fortawesome/pro-regular-svg-icons/faHandPointer';
import {faStickyNote as farStickyNote} from '@fortawesome/pro-regular-svg-icons/faStickyNote';
import {faXmark as farXmark} from '@fortawesome/pro-regular-svg-icons/faXmark';
import {faLock as farLock} from '@fortawesome/pro-regular-svg-icons/faLock';
import {faCircle as fasCircle} from '@fortawesome/pro-light-svg-icons/faCircle';
import {faClock as fasClock} from '@fortawesome/pro-light-svg-icons/faClock';
import {faClone as fasClone} from '@fortawesome/pro-light-svg-icons/faClone';
import {faComment as fasComment} from '@fortawesome/pro-light-svg-icons/faComment';
import {faAlignLeft as falAlignLeft} from '@fortawesome/pro-light-svg-icons/faAlignLeft';
import {faEllipsisV as falEllipsisV} from '@fortawesome/pro-light-svg-icons/faEllipsisV';
import {faEnvelope as fasEnvelope} from '@fortawesome/pro-light-svg-icons/faEnvelope';
import {faExclamationCircle as fasExclamationCircle} from '@fortawesome/pro-light-svg-icons/faExclamationCircle';
import {faExclamationTriangle as fasExclamationTriangle} from '@fortawesome/pro-light-svg-icons/faExclamationTriangle';
import {faCalendar as fasCalendar} from '@fortawesome/pro-light-svg-icons/faCalendar';
import {faFaceSurprise as falFaceSurprise} from '@fortawesome/pro-light-svg-icons/faFaceSurprise';
import {faFileAlt as falFileAlt} from '@fortawesome/pro-light-svg-icons/faFileAlt';
import {faFolderOpen as falFolderOpen} from '@fortawesome/pro-light-svg-icons/faFolderOpen';
import {faHand as falHand} from '@fortawesome/pro-light-svg-icons/faHand';
import {faInfoCircle as falInfoCircle} from '@fortawesome/pro-light-svg-icons/faInfoCircle';
import {faKey as fasKey} from '@fortawesome/pro-light-svg-icons/faKey';
import {faRedo as fasRedo} from '@fortawesome/pro-light-svg-icons/faRedo';
import {faRedoAlt as fasRedoAlt} from '@fortawesome/pro-light-svg-icons/faRedoAlt';
import {faStickyNote as fasStickyNote} from '@fortawesome/pro-light-svg-icons/faStickyNote';
import {faSchool as falSchool} from '@fortawesome/pro-light-svg-icons/faSchool';
import {faSearch as falSearch} from '@fortawesome/pro-light-svg-icons/faSearch';
import {faStars as fasStars} from '@fortawesome/pro-light-svg-icons/faStars';
import {faThumbsUp as fasThumbsUp} from '@fortawesome/pro-light-svg-icons/faThumbsUp';
import {faTrash as fasTrash} from '@fortawesome/pro-light-svg-icons/faTrash';
import {faTrashAlt as fasTrashAlt} from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import {faUpload as falUpload} from '@fortawesome/pro-light-svg-icons/faUpload';
import {faUserPlus as fasUserPlus} from '@fortawesome/pro-light-svg-icons/faUserPlus';
import {faOctagonExclamation} from '@fortawesome/pro-light-svg-icons/faOctagonExclamation';
import {faXmark as fasXmark} from '@fortawesome/pro-light-svg-icons/faXmark';
import {faArchive as falArchive} from '@fortawesome/pro-light-svg-icons/faArchive';
import {faUpDown as farUpDown} from '@fortawesome/pro-light-svg-icons/faUpDown';
import {faBookOpen as farBookOpen} from '@fortawesome/pro-regular-svg-icons/faBookOpen';
import {faPersonChalkboard} from '@fortawesome/pro-solid-svg-icons/faPersonChalkboard';
import {faImage as farImage} from '@fortawesome/pro-regular-svg-icons/faImage';
import {faArrowUpRightAndArrowDownLeftFromCenter as farArrowUpRightAndArrowDownLeftFromCenter} from '@fortawesome/pro-regular-svg-icons/faArrowUpRightAndArrowDownLeftFromCenter';
import {faArrowDownLeftAndArrowUpRightToCenter as farArrowDownLeftAndArrowUpRightToCenter} from '@fortawesome/pro-regular-svg-icons/faArrowDownLeftAndArrowUpRightToCenter';
import {faPlus as farPlus} from '@fortawesome/pro-regular-svg-icons/faPlus';
import {faMinus as farMinus} from '@fortawesome/pro-regular-svg-icons/faMinus';
import {faSearchMinus as farSearchMinus} from '@fortawesome/pro-regular-svg-icons/faSearchMinus';
import {faPartyHorn as farPartyHorn} from '@fortawesome/pro-regular-svg-icons/faPartyHorn';
import {faChalkboardUser as farChalkboardUser} from '@fortawesome/pro-regular-svg-icons/faChalkboardUser';
import {faTableColumns as farTableColumns} from '@fortawesome/pro-regular-svg-icons/faTableColumns';
import {faMessagePen as farMessagePen} from '@fortawesome/pro-regular-svg-icons/faMessagePen';
import {faCalendarDay as farCalendarDay} from '@fortawesome/pro-regular-svg-icons/faCalendarDay';
import {faCalendarXmark as farCalendarXmark} from '@fortawesome/pro-regular-svg-icons/faCalendarXmark';
import {faAlarmSnooze as farAlarmSnooze} from '@fortawesome/pro-regular-svg-icons/faAlarmSnooze';
import {faDice as farDice} from '@fortawesome/pro-regular-svg-icons/faDice';
import {faStopwatch as farStopwatch} from '@fortawesome/pro-regular-svg-icons/faStopwatch';
import {faUser as farUser} from '@fortawesome/pro-regular-svg-icons/faUser';
import {faCheckCircle as falCheckCircle} from '@fortawesome/pro-light-svg-icons/faCheckCircle';
import {faSpinner as farSpinner} from '@fortawesome/pro-regular-svg-icons/faSpinner';
import {faArrowRotateLeft} from '@fortawesome/pro-solid-svg-icons/faArrowRotateLeft';
import {faCircleDashed as farCircleDashed} from '@fortawesome/pro-regular-svg-icons/faCircleDashed';
import {faFlagCheckered as farFlagCheckered} from '@fortawesome/pro-regular-svg-icons/faFlagCheckered';
import {faCactus as falCactus} from '@fortawesome/pro-light-svg-icons/faCactus';
import {faPenToSquare as farPenToSquare} from '@fortawesome/pro-regular-svg-icons/faPenToSquare';
import {faUsers as falUsers} from '@fortawesome/pro-light-svg-icons/faUsers';
import {faPencil as falPencil} from '@fortawesome/pro-light-svg-icons/faPencil';
import {faFileLines as falFileLines} from '@fortawesome/pro-light-svg-icons/faFileLines';
import {faArrowUpFromBracket as falArrowUpFromBracket} from '@fortawesome/pro-light-svg-icons/faArrowUpFromBracket';
import {faTrashCan as falTrashCan} from '@fortawesome/pro-light-svg-icons/faTrashCan';
import {faBoxArchive as farBoxArchive} from '@fortawesome/pro-regular-svg-icons/faBoxArchive';
import {faArrowDownWideShort} from '@fortawesome/pro-solid-svg-icons/faArrowDownWideShort';
import {faPencilSlash as farPencilSlash} from '@fortawesome/pro-regular-svg-icons/faPencilSlash';
import {faArrowRightArrowLeft as farArrowRightArrowLeft} from '@fortawesome/pro-regular-svg-icons/faArrowRightArrowLeft';
import {faIdCard as falIdCard} from '@fortawesome/pro-light-svg-icons/faIdCard';
import {faDumbbell as falDumbbell} from '@fortawesome/pro-light-svg-icons/faDumbbell';
import {faEdit as falEdit} from '@fortawesome/pro-light-svg-icons/faEdit';
import {faBooks as falBooks} from '@fortawesome/pro-light-svg-icons/faBooks';
import {faTags as falTags} from '@fortawesome/pro-light-svg-icons/faTags';
import {faListTree as falListTree} from '@fortawesome/pro-light-svg-icons/faListTree';
import {faRectanglePro} from '@fortawesome/pro-solid-svg-icons/faRectanglePro';
import {faLanguage as falLanguage} from '@fortawesome/pro-light-svg-icons/faLanguage';
import {faChalkboard as falChalkboard} from '@fortawesome/pro-light-svg-icons/faChalkboard';
import {faFolder as falFolder} from '@fortawesome/pro-light-svg-icons/faFolder';
import {faPhotoFilmMusic as falPhotoFilmMusic} from '@fortawesome/pro-light-svg-icons/faPhotoFilmMusic';
import {faChartLineUp as farChartLineUp} from '@fortawesome/pro-regular-svg-icons/faChartLineUp';
import {faMusic as falMusic} from '@fortawesome/pro-light-svg-icons/faMusic';
import {faImage as falImage} from '@fortawesome/pro-light-svg-icons/faImage';
import {faCloudSlash as falCloudSlash} from '@fortawesome/pro-light-svg-icons/faCloudSlash';

/**
 * This file acts as a manifest for all supported FontAwesome 5 icons.
 * If you don't see the icon you need listed, add it!
 *
 * @see https://github.com/FortAwesome/react-fontawesome#build-a-library-to-reference-icons-throughout-your-app-more-conveniently
 */

const brandIcons = [
  faCcAmex,
  faCcMastercard,
  faCcVisa,
  faFacebookF,
  faGoogle,
  faInstagram,
  faTwitter
];

const solidIcons = [
  faAlignRight,
  faArchive,
  faArrowDown,
  faArrowLeft,
  faArrowLeftToLine,
  faArrowRightToLine,
  faArrowToLeft,
  faArrowRight,
  faArrowsAltH,
  faArrowsV,
  faArrowToTop,
  faArrowUp,
  faArrowUpRightFromSquare,
  faAsterisk,
  faBackpack,
  faBackwardStep,
  faBan,
  faBarsFilter,
  faBarcodeRead,
  faBars,
  faBold,
  faBookmark,
  faBooks,
  faCalendar,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCertificate,
  faCheck,
  faCheckCircle,
  faCheckDouble,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircle1,
  faCircle2,
  faCircle3,
  faClipboard,
  faClock,
  faClone,
  faCloud,
  faCloudUpload,
  faCode,
  faCog,
  faComment,
  faCopy,
  faCreditCardBlank,
  faDownload,
  faDownLeftAndUpRightToCenter,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faExchange,
  faExclamation,
  faExclamationTriangle,
  faExpandArrowsAlt,
  faExpandAlt,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFax,
  faFileAlt,
  faFileLines,
  faFilePlus,
  faFilter,
  faFolderPlus,
  faFrownOpen,
  faGreaterThan,
  faHandPointer,
  faFolderOpen,
  faH1,
  faH2,
  faHeading,
  faHeadset,
  faHistory,
  faHorizontalRule,
  faICursor,
  faImage,
  faIndent,
  faInfo,
  faInfoCircle,
  faItalic,
  faKey,
  faLevelUpAlt,
  faLink,
  faLightbulbOn,
  faListOl,
  faListUl,
  faLock,
  faLongArrowAltRight,
  faMagnifyingGlass,
  faMagnifyingGlassPlus,
  faMinus,
  faOutdent,
  faPause,
  faPencil,
  faPencilAlt,
  faPersonChalkboard,
  faPhone,
  faPlus,
  faPlay,
  faQuestion,
  faQuestionCircle,
  faRedo,
  faRedoAlt,
  faRepeat,
  faRetweet,
  faRotateLeft,
  faRotateRight,
  faSchool,
  faSearch,
  faShare,
  faSliders,
  faSlidersH,
  faSortAmountDown,
  faSortAmountUp,
  faStickyNote,
  faSparkles,
  faSpinner,
  faSpinnerThird,
  faSubscript,
  faSuperscript,
  faSync,
  faTable,
  faTag,
  faTextSize,
  faThumbsUp,
  faTimes,
  faTrash,
  faUmbrellaBeach,
  faUnderline,
  faUndo,
  faUnlink,
  faUnion,
  faUpDown,
  faUpRightAndDownLeftFromCenter,
  faUser,
  faUserPlus,
  faVectorSquare,
  faVideo,
  faVolume,
  faVolumeUp,
  faXmark,
  faArrowRotateLeft,
  faArrowDownWideShort,
  faRectanglePro
];

const regularIcons = [
  farArchive,
  farArrowDown,
  farArrowRight,
  farArrowToTop,
  farArrowUp,
  farArrowUpFromBracket,
  farArrowUpDown,
  farArrowUpRightFromSquare,
  farArrowsRotate,
  farBan,
  farBell,
  farBook,
  farBullseyeArrow,
  farBookOpen,
  farBooks,
  farBracketCurly,
  farCalendarAlt,
  farCaretRight,
  farCheck,
  farCheckCircle,
  farCheckDouble,
  farChevronRight,
  farChevronLeft,
  farCircle,
  farCirclePlus,
  farCircleXmark,
  farClock,
  farClockrotateLeft,
  farCloud,
  farCloudUpload,
  farCog,
  farComment,
  farConstruction,
  farDownload,
  farCopy,
  farEnvelope,
  farExclamationCircle,
  farExclamationTriangle,
  farEye,
  farEyeSlash,
  farFileAlt,
  farFileExport,
  farFileLines,
  farFilePlus,
  farFilter,
  farFolder,
  farFolders,
  farFolderOpen,
  farFolderPlus,
  farFrownOpen,
  farFrown,
  farGraduationCap,
  farHandPointLeft,
  farHeartCrack,
  farHexagonExclamation,
  farICursor,
  farInfoCircle,
  farKey,
  farLaugh,
  farLightbulbOn,
  farLink,
  farList,
  farMagnifyingGlass,
  farMagnifyingGlassPlus,
  farMusic,
  farPaperPlane,
  farPartyHorn,
  farPencil,
  farQuestion,
  farQuestionCircle,
  farRedo,
  farRotateRight,
  farSchool,
  farSortAmountUp,
  farSparkles,
  farStickyNote,
  farSync,
  farTable,
  farThumbsUp,
  farTrash,
  farTrashCanSlash,
  farTrashAlt,
  farUpDown,
  farUserPlus,
  farUsers,
  farVolume,
  farXmark,
  farImage,
  farArrowUpRight,
  farArrowUpRightAndArrowDownLeftFromCenter,
  farArrowDownLeftAndArrowUpRightToCenter,
  farPlus,
  farMinus,
  farSearchMinus,
  farChalkboardUser,
  farTableColumns,
  farMessagePen,
  farCalendarDay,
  farCalendarXmark,
  farAlarmSnooze,
  farDice,
  farStopwatch,
  farLock,
  farUser,
  farSpinner,
  farCircleDashed,
  farFlagCheckered,
  farPenToSquare,
  farBoxArchive,
  farPencilSlash,
  farArrowRightArrowLeft,
  farChartLineUp
];

const lightIcons = [
  falAlignLeft,
  falArrowsRotate,
  faOctagonExclamation,
  farHandPointer,
  farLink,
  farQuestion,
  farTimes,
  farTrash,
  fasCalendar,
  farChalkboard,
  fasCircle,
  falCircleExclamation,
  falCircleExclamationCheck,
  falCircleCheck,
  fasClock,
  fasClone,
  fasComment,
  falEllipsisV,
  fasEnvelope,
  fasExclamationCircle,
  fasExclamationTriangle,
  falFaceSurprise,
  falFileAlt,
  falFolderOpen,
  falHand,
  falInfoCircle,
  fasKey,
  fasRedo,
  fasRedoAlt,
  fasStickyNote,
  falArchive,
  falFrown,
  falSchool,
  falSearch,
  fasStars,
  fasThumbsUp,
  fasTrash,
  fasTrashAlt,
  falUpload,
  fasUserPlus,
  fasXmark,
  falCactus,
  falCheckCircle,
  falUsers,
  falPencil,
  falFileLines,
  falArrowUpFromBracket,
  falTrashCan,
  falIdCard,
  falDumbbell,
  falEdit,
  falBooks,
  falTags,
  falListTree,
  falLanguage,
  falChalkboard,
  falFolder,
  falPhotoFilmMusic,
  falMusic,
  falImage,
  falCloudSlash
];

/**
 * We manually add the CSS to the page in our SSR
 *
 * @see src/server/Html.react.js
 */
config.autoAddCss = false;

library.add(...brandIcons, ...solidIcons, ...regularIcons, ...lightIcons);
