import React from 'react';
import classnames from 'classnames';

import TableHead from '../TableHead/TableHead';
import TableBody from '../TableBody/TableBody';
import Header from '../Header/Header.react';
import Row from '../Row/Row.react';
import Cell from '../Cell/Cell.react';
import Card from '../../../atoms/Card/Card.react';
import '../table.scss';

export default class BasicTable extends React.Component<PropsWithClassNameRequired> {
  static Head = TableHead;

  static Body = TableBody;

  static Header = Header;

  static Row = Row;

  static Cell = Cell;

  render() {
    const {className, children} = this.props;

    return (
      <Card className='o-table__wrapper'>
        <table className={classnames('o-table o-table--basic', className)}>{children}</table>
      </Card>
    );
  }
}
