/* eslint-disable import/prefer-default-export */
import React, {lazy} from 'react';
import {Route, IndexRedirect} from 'react-router';

import {requireSessionWithLoginPrompt} from 'lib/decorators/requireSession';

const Marketplace = lazy(() => import('./Marketplace.react'));
const CheckoutConfirmation = lazy(() => import('./Checkout/Confirmation/Confirmation.react'));

const MarketplaceRoutes = (
  <Route name='marketplace' path='/marketplace' title='Marketplace | Albert.io'>
    <IndexRedirect to='subjects' />
    <Route component={requireSessionWithLoginPrompt()(Marketplace)} path='subjects' />
    <Route component={requireSessionWithLoginPrompt()(CheckoutConfirmation)} path='confirmation' />
  </Route>
);

export {MarketplaceRoutes};
