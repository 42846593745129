/* eslint-disable no-underscore-dangle */
import {fromJS} from 'immutable';

import QuestionTypeStore from 'components/QuestionTypes/QuestionType.store';
import {GuessModel} from 'resources/Guess/Guess.model';

import freeEntryQuestionActions from './FreeEntryQuestion.actions';

export default class FreeEntryQuestionStore extends QuestionTypeStore {
  constructor(name, questionId, question) {
    super(name);

    this.initialData = fromJS({
      ...this.draftGuessInitialData,
      inputValues: {},
      openTopFive: [],
      questionId,
      shouldShowExplanation: false
    });

    this.setInitialData(this.initialData);
    this.writeData('__question', question);

    this.handleTargeted(freeEntryQuestionActions.UPDATE_INPUT, this._updateInput);
  }

  afterLoadDraftGuess(draftGuess) {
    this.writeData('inputValues', this.parseGuess(draftGuess));
  }

  buildCorrectGuess(question) {
    return new GuessModel({
      content: question.getValidResponse().map((val) => val.first()),
      correct: true,
      rubric: question.getMeta().getQuestionRubric(),
      solutionText: question.getSolutionText(),
      pointsPossible: question.getPoints(),
      pointsEarned: question.getPoints(),
      question
    });
  }

  buildGuessContent() {
    const guessContent = this.readData('inputValues');
    return guessContent.map((value) => value.trim()).toJS();
  }

  _clearGuess() {
    super._clearGuess();

    this.writeData('inputValues', this.initialData.get('inputValues'));
    super.kickoffDebouncedSaveDraftGuess();
  }

  getValueForId(inputId) {
    return this.readData(['inputValues', inputId]);
  }

  _loadGuess(guess) {
    super._loadGuess(guess);

    this.writeData('inputValues', this.parseGuess(guess));
  }

  parseGuess(guess) {
    return guess.get('content');
  }

  _updateInput({inputId, value}) {
    this.writeData((store) => store.setIn(['inputValues', inputId], value));
    super.kickoffDebouncedSaveDraftGuess();
  }
}
