import React from 'react';
import {withRouter} from 'react-router';

import {callAction} from 'client/framework';
import sessionStore from 'client/Session/SessionStore';

import systemTimeOffsetStore from 'client/generic/SystemTimeOffset/SystemTimeOffset.store';
import AssignmentStage from 'client/Assignments/AssignmentStage/AssignmentStage.react';
import assignmentConstants from 'client/Assignments/constants';

import IEContentWrapper from 'client/InteractionEngineV2/shared/IEContentWrapper/IEContentWrapper.react';
import ActiveQuestion from 'client/InteractionEngineV2/shared/ActiveQuestion/ActiveQuestion.react';
import IEStats from 'client/InteractionEngineV2/shared/IEStats/IEStats.react';
import interactionEngineStore from 'client/InteractionEngineV2/InteractionEngineStore';

import StudentAssignmentQuestionsList from './StudentAssignmentQuestionsList/StudentAssignmentQuestionsList.react';
import studentAssignmentQuestionsListStore from './StudentAssignmentQuestionsList/StudentAssignmentQuestionsList.store';
import abandonAssignmentModalActions from './AbandonAssignmentModal/AbandonAssignmentModal.actions';
import AbandonAssignmentModal from './AbandonAssignmentModal/AbandonAssignmentModal.react';

import AssignmentErrorInterstitial, {
  StudentErrorComponent
} from 'client/Assignments/AssignmentErrorInterstitial.react';

import AssignmentInitializer from '../AssignmentInitializer/AssignmentInitializer.react';
import AssignmentNavigation from '../AssignmentNavigation/AssignmentNavigation.react';

import Timer from 'client/generic/Timer/Timer.react';

export default class AssignmentsIE extends React.Component {
  makeRightContent() {
    const assignment = studentAssignmentQuestionsListStore.getAssignment();
    const startTime = studentAssignmentQuestionsListStore.getAssignmentStartTime();
    const systemTimeOffset = systemTimeOffsetStore.getSystemTimeOffset();
    const currentTime = systemTimeOffsetStore.getCurrentTime();
    const minutesRemaining = assignment.getMinutesRemaining(sessionStore.getUserId(), currentTime);
    const secondsRemaining = minutesRemaining * 60;
    /**
     * If the user hasn't started the assignment, don't show the "right content".
     */
    if (!startTime || !systemTimeOffset || !currentTime) {
      return null;
    }

    const showTimer = assignment.getTimeLimit() && startTime;

    const timer = showTimer ? (
      <Timer
        secondsRemaining={secondsRemaining}
        totalSeconds={assignment.getTimeLimit() * 60}
        storeName='studentAssignmentTimerStore'
      />
    ) : null;

    return (
      <div className='interaction-engine-v2__actions'>
        {timer}
        <button
          className='interaction-engine-v2__button interaction-engine-v2__button--submit'
          onClick={this.handleSubmitClick}
        >
          Submit assignment
        </button>
      </div>
    );
  }

  handleSubmitClick = () => {
    callAction(abandonAssignmentModalActions.RESET_STORE);
    callAction(abandonAssignmentModalActions.TOGGLE_ABANDON_MODAL_ACTIVE, true);
  };

  getNavigation() {
    if (studentAssignmentQuestionsListStore.getAssignment().canAssignmentBeSubmitted()) {
      return (
        <AssignmentNavigation
          assignmentStore={studentAssignmentQuestionsListStore}
          assignmentActions={this.makeRightContent()}
          showMobileActions={true}
        />
      );
    }

    return <AssignmentNavigation assignmentStore={studentAssignmentQuestionsListStore} />;
  }

  getQuestionsList() {
    return <StudentAssignmentQuestionsList location={this.props.location} />;
  }

  getQuestionContent() {
    const assignment = studentAssignmentQuestionsListStore.getAssignment();
    const currentTime = systemTimeOffsetStore.getCurrentTime();

    if (!studentAssignmentQuestionsListStore.activeQuestion) {
      return <AssignmentStage assignmentId={assignment.getId()} />;
    }

    const activeQuestionId = studentAssignmentQuestionsListStore.activeQuestion.getId();
    const questionStoreName = interactionEngineStore.getStoreNameForQuestionId(activeQuestionId);
    const showPrevButton = studentAssignmentQuestionsListStore.hasPreviousQuestion(
      activeQuestionId
    );
    const showNextButton = studentAssignmentQuestionsListStore.hasNextQuestion(activeQuestionId);
    const showAfterEach = assignmentConstants.explanationSettings.SHOW_AFTER_EACH;
    const correctAnswerSetting = studentAssignmentQuestionsListStore.correctAnswerSetting;
    return (
      <ActiveQuestion
        assignmentId={studentAssignmentQuestionsListStore.assignmentId}
        location={this.props.location}
        question={studentAssignmentQuestionsListStore.activeQuestion}
        questionSet={studentAssignmentQuestionsListStore.activeQuestionSet}
        canEditQuestion={false}
        questionStoreName={questionStoreName}
        showPrevButton={showPrevButton}
        showNextButton={showNextButton}
        shouldShowExplanationAfterGuessIsSubmitted={correctAnswerSetting === showAfterEach}
        shouldShowExplanation={assignment.shouldShowGuessFeedback()}
        isAssignmentOverdue={assignment.isStudentFinished(sessionStore.getUserId(), currentTime)}
        isAssignmentSubmitted={assignment.getMeta().isStudentSubmitted()}
        isLastAssignmentQuestion={studentAssignmentQuestionsListStore
          .getAssignment()
          .isLastAssignmentQuestion()}
      />
    );
  }

  getStats() {
    const assignment = studentAssignmentQuestionsListStore.getAssignment();

    if (studentAssignmentQuestionsListStore.activeQuestion) {
      return (
        <IEStats
          accuracy={studentAssignmentQuestionsListStore.getAssignment().getAccuracy()}
          numOfQuestionsAnswered={studentAssignmentQuestionsListStore
            .getAssignment()
            .getMeta()
            .getStudentCountOfGuesses()}
          shouldShowAccuracy={assignment.shouldShowGuessFeedback()}
          totalNumOfQuestions={studentAssignmentQuestionsListStore
            .getAssignment()
            .getMeta()
            .getCountOfQuestions()}
        />
      );
    }

    return null;
  }

  render() {
    return (
      <Interstitial>
        <AssignmentInitializer
          assignmentStore={studentAssignmentQuestionsListStore}
          isLoggedIn={sessionStore.hasValidSession()}
          getChildren={() => {
            return (
              <IEContentWrapper
                subheader={this.getNavigation()}
                questionsList={this.getQuestionsList()}
                questionArea={this.getQuestionContent()}
                statsArea={this.getStats()}
                modals={<AbandonAssignmentModal />}
              />
            );
          }}
        />
      </Interstitial>
    );
  }
}

const Interstitial = withRouter(({params, children}) => {
  return (
    <AssignmentErrorInterstitial
      assignmentId={params.assignmentId}
      errorComponent={StudentErrorComponent}
    >
      {children}
    </AssignmentErrorInterstitial>
  );
});
