import React from 'react';
import {AudioPlayer} from 'generic/AudioPlayer/AudioPlayer.react';
import constants from 'client/constants';
import {AuthoringSupplementModelV1} from 'resources/augmented/AuthoringSupplement/AuthoringSupplementModel.v1';
import {SupplementModelV1} from 'resources/augmented/Supplement/SupplementModel.v1';

import './audio.scss';

interface Props {
  supplement: AuthoringSupplementModelV1 | SupplementModelV1;
}

export default function Audio({supplement}: Props) {
  const content = supplement.getContent();
  const audioFile = content.get('audio');

  if (!audioFile) {
    return null;
  }

  return (
    <div className='audio-supplement-wrapper u-width_100pc u-mar-tb_1'>
      <AudioPlayer
        caption={supplement.getCaption()}
        src={`${constants.USER_ASSETS_ORIGIN}/${audioFile}`}
      />
    </div>
  );
}
