// @flow
import {fromJS} from 'immutable';
import moment from 'moment';
import {Store, getStoreByName} from 'client/framework';
import {mandarkEndpoint} from '@albert-io/json-api-framework/request/builder/legacy';
import PaginationStore from 'generic/PaginationV2/Pagination.store';
import masqueradeStore from 'generic/Masquerade/Masquerade.store';

import type {List, Map} from 'immutable';
import type {ClassroomModelV1} from 'resources/augmented/Classroom/ClassroomModel.v1';
import type {QueryBuilder} from '@albert-io/json-api-framework/request/builder/legacy';

type TimeScopeOptions = List<Map<string, *>>;

function getTimeScopeOptions(): TimeScopeOptions {
  return fromJS([
    {
      displayName: 'This Week',
      startTime: moment()
        .startOf('week')
        .valueOf(),
      endTime: moment().valueOf()
    },
    {
      displayName: 'Last 7 Days',
      startTime: moment()
        .subtract(7, 'days')
        .valueOf(),
      endTime: moment().valueOf()
    },
    {
      displayName: 'This Month',
      startTime: moment()
        .startOf('month')
        .valueOf(),
      endTime: moment().valueOf()
    },
    {
      displayName: 'Last 30 days',
      startTime: moment()
        .subtract(30, 'days')
        .valueOf(),
      endTime: moment().valueOf()
    },
    {
      displayName: 'Last 90 Days',
      startTime: moment()
        .subtract(90, 'days')
        .valueOf(),
      endTime: moment().valueOf()
    },
    {
      displayName: 'All Time',
      startTime: 0,
      endTime: moment().valueOf()
    }
  ]);
}

const STORE_NAME = 'ClassroomsStore';

export class ClassroomsStore extends Store {
  constructor(name: string) {
    super(name);
    this.initialData = fromJS({
      timeScopeOptionsStoreName: ''
    });
    this.setInitialData(this.initialData);
    this._paginationStore = new PaginationStore(`${STORE_NAME}PaginationStore`);
    this._pageSize = 250;
  }

  _setTimeScopeOptionsStoreName(storeName: string) {
    this.writeData('timeScopeOptionsStoreName', storeName);
  }

  getTeacherClassroomsQuery(): QueryBuilder {
    return (
      mandarkEndpoint([
        'teachers_v1',
        masqueradeStore.getUserIdByMasqueradeState(),
        'classrooms_v1'
      ])
        /**
         * We have to make sure we're only requesting classrooms an administrator is
         * allowed to see; we do this by filtering on the school of the current
         * masquerade state.
         */
        .filter(
          {
            school_v2: masqueradeStore.getSchoolId()
          },
          masqueradeStore.isMasquerading()
        )
        .sort('name')
        .pageSize(this.getPageSize())
        .include('students_v2')
    );
  }

  getTeacherActiveClassroomsQuery(): QueryBuilder {
    return this.getTeacherClassroomsQuery()
      .filter({
        status: 'active'
      })
      .page(this.getPaginationStore().activePage);
  }

  getTeacherArchivedClassroomsQuery(): QueryBuilder {
    return this.getTeacherClassroomsQuery().filter({
      status: 'archived'
    });
  }

  getTeacherActiveClassrooms(): List<ClassroomModelV1> {
    return this.getTeacherActiveClassroomsQuery().getResource();
  }

  areTeacherActiveClassroomsReady(): boolean {
    return this.getTeacherActiveClassroomsQuery().isResourcePopulated();
  }

  getActiveClassroomMetadataQuery(): QueryBuilder {
    return this.getTeacherActiveClassroomsQuery()
      .withMeta('classroom_v1')
      .customQuery({
        meta: {
          context: {
            classroom: {
              overview: true
            }
          }
        }
      });
  }

  areActiveClassroomsMetadataReady(): boolean {
    return this.getActiveClassroomMetadataQuery().isResourceReady();
  }

  isActiveClassroomsMetadataValid(): boolean {
    return this.getActiveClassroomMetadataQuery().isResourceValid();
  }

  getActiveClassroomMetadata(classroomId: string): ClassroomModelV1 {
    return this.getActiveClassroomMetadataQuery()
      .getResource()
      .find((classroom) => classroom.getId() === classroomId);
  }

  getTeacherArchivedClassrooms(): List<ClassroomModelV1> {
    return this.getTeacherArchivedClassroomsQuery().getResource();
  }

  getStudentQuery(): QueryBuilder {
    return mandarkEndpoint([
      'students_v1',
      masqueradeStore.getUserIdByMasqueradeState(),
      'classrooms_v1'
    ])
      .include('subjects_v1,teachers_v1')
      .filter({status: 'active'})
      .customQuery({
        sort: 'name',
        page: {
          page: this.getPaginationStore().activePage,
          page_size: this.getPageSize()
        }
      });
  }

  getPaginationStore(): Store {
    return this._paginationStore;
  }

  getPageSize(): number {
    return this._pageSize;
  }

  getTeacherActiveClassroomTotalPages(): number {
    const count = this.getTeacherActiveClassroomsQuery().getResourceMetadata();
    return count.getIn(['page', 'total_pages'], 1);
  }

  areActiveStudentClassroomsReady(): boolean {
    return this.getStudentQuery().isResourcePopulated();
  }

  getActiveStudentClassrooms(): List<ClassroomModelV1> {
    return this.getStudentQuery().getResource();
  }

  getTimeScopeOptions(): TimeScopeOptions {
    return getTimeScopeOptions();
  }

  /**
   * @todo  – the only place this is actually used is in AccountUserDetails.react.js
   */
  getTeachingClassrooms(): List<ClassroomModelV1> {
    return this.getTeacherClassroomsQuery().getResource();
  }

  getSelectedTimeScope(): Map<string, *> {
    const selectedItem = getStoreByName(
      this.readData('timeScopeOptionsStoreName')
    ).getSelectedItem();
    return selectedItem;
  }

  teacherHasClassrooms(): boolean {
    return (
      !this.getTeacherActiveClassrooms().isEmpty() || !this.getTeacherArchivedClassrooms().isEmpty()
    );
  }
}

const classroomsStore = new ClassroomsStore(STORE_NAME);
export default classroomsStore;
