import React, {useMemo} from 'react';
import {Banner, Text, Toggle} from '@albert-io/atomic';
import {useQuestionTranslationStore} from './TranslationStore';
import {useShallow} from 'zustand/react/shallow';

interface TranslationHeaderProps {
  showTranslations: boolean;
  onToggle: () => void;
  translationCoverage?: 'none' | 'full' | 'partial';
  // fetchingTranslations?: boolean; // TODO: implment loading symbol or something
}

const TranslationHeader = ({
  showTranslations,
  onToggle,
  translationCoverage = 'none'
}: TranslationHeaderProps) => {
  const [mainText, hasSubText] = useMemo(() => {
    if (translationCoverage !== 'none') {
      return [translationText.es, translationCoverage === 'partial'];
    }
    return [translationText[translationCoverage], false];
  }, [translationCoverage]); // TODO: add language as dependency

  const noTranslation = useMemo(() => translationCoverage === 'none', [translationCoverage]);

  return (
    <Banner
      type={showTranslations ? 'info' : undefined}
      className='u-width_100pc u-border-radius_0 '
      border={false}
    >
      <Banner.Icon icon={['fal', 'language']} className='u-mar-r_1' />
      <Text color={showTranslations ? 'info' : undefined}>{mainText}</Text>
      {hasSubText && (
        <>
          <Banner.Icon className='u-mar-l_auto' icon='warning' />
          <Text color={showTranslations ? 'info' : undefined}>{translationText.partial}</Text>
        </>
      )}
      <Toggle
        disabled={noTranslation}
        checked={showTranslations}
        className='u-mar-l_auto'
        onChange={onToggle}
      />
    </Banner>
  );
};

const translationText = {
  none: 'Translations not available for this section',
  es: 'Translate to Spanish',
  partial: 'Partial translation available'
};

type TranslationWrapperProps = Omit<TranslationHeaderProps, 'translationCoverage'>;

const TranslationHeaderWrapper = (props: TranslationWrapperProps) => {
  const {
    // fetchingTranslations, // TODO: potentially use for loading state?
    translationCoverage,
    isReady,
    hasTranslationAccess
  } = useQuestionTranslationStore(
    useShallow((state) => ({
      // fetchingTranslations: state.fetchingTranslations,
      translationCoverage: state.translationCoverage,
      isReady: state.isReady,
      hasTranslationAccess: state.hasTranslationAccess
    }))
  );

  const showTranslationToggle = useMemo(() => {
    if (isReady && hasTranslationAccess) return true;
    return false;
  }, [isReady, hasTranslationAccess]);

  if (!showTranslationToggle) return null;

  return (
    <TranslationHeader
      showTranslations={props.showTranslations}
      onToggle={props.onToggle}
      translationCoverage={translationCoverage}
    />
  );
};

export default TranslationHeaderWrapper;
