import React from 'react';
import {AnalyticsApiResult, TopLevelFilter} from 'client/Reports/reports.types';
import {history} from 'client/history';
import {isFinalDimension} from 'client/Reports/reports.utils';
import {WithRouterProps, withRouter} from 'react-router';
import classNames from 'classnames';
import {IconButton, WithTooltip} from '@albert-io/atomic';

interface CellProps extends PropsWithClassNameOptional, WithRouterProps {
  topLevelFilterType: TopLevelFilter;
  result?: any;
  resultRenderer: (result: AnalyticsApiResult) => JSX.Element;
  defaultSort?: boolean;
}

const Cell = withRouter(
  ({topLevelFilterType, result, resultRenderer, className, defaultSort, location}: CellProps) => {
    const {pathname, search} = location;

    const id = result.primary_dimension_id;

    const showDrilldown = defaultSort && !isFinalDimension(topLevelFilterType, pathname);

    return (
      <td className={className}>
        <div
          className={classNames({
            'u-display_flex': showDrilldown,
            'u-justify-content_space-between': showDrilldown,
            'u-align-items_center': showDrilldown
          })}
        >
          {resultRenderer(result)}
          {showDrilldown && (
            <WithTooltip content='Drilldown' placement='top'>
              <IconButton
                className='reports-table__column-button reports-table__drilldown-button'
                variant='solid'
                color='primary'
                size='s'
                label='Drilldown'
                onClick={() => {
                  if (!isFinalDimension(topLevelFilterType, pathname)) {
                    history.pushState(null, `${pathname}/${id}${search}`);
                  }
                }}
                icon='arrow-right'
              />
            </WithTooltip>
          )}
        </div>
      </td>
    );
  }
);

export default Cell;
