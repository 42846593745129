import {fromJS, List} from 'immutable';
import QuestionTypeStore from 'components/QuestionTypes/QuestionType.store';

import {
  blankSlateValue,
  convertStateToPayload,
  convertStateToString,
  convertStateToAudioArr,
  convertStateToImgArr
} from './WrittenSubmission/Editor/Utils/editor.utils';

import freeResponseActions from './FreeResponseQuestion.actions';

export default class FreeResponseQuestionStore extends QuestionTypeStore {
  constructor(name, questionId, question) {
    super(name, questionId, 1500);

    this.initialData = fromJS({
      ...this.draftGuessInitialData,
      shouldShowExplanation: false,
      editorValue: blankSlateValue
    });

    this.setInitialData(this.initialData);
    this.writeData('__question', question);

    this.handleTargeted(freeResponseActions.LOAD_GUESS, this.loadGuess);
    this.handleTargeted(freeResponseActions.SAVE_CALLBACK, this.saveDraftCallback);
    this.handleTargeted(freeResponseActions.UPDATE_RESPONSE, this.updateInput);
  }

  afterLoadDraftGuess(draftGuess) {
    const parsedGuess = this.parseGuess(draftGuess);

    if (parsedGuess !== null) {
      this.writeData('editorValue', parsedGuess);
    }
  }

  buildDraftGuessContent() {
    const rawData = this.readData('editorValue');
    return convertStateToPayload(rawData);
  }

  buildGuessContent() {
    const rawData = this.readData('editorValue');
    const content = convertStateToPayload(rawData);

    return content;
  }

  get correctAnswer() {
    return this.validResponse.keySeq().toSet();
  }
  /**
   * For a "free-response" question, the user submits an answer to be graded outside of albert.
   * Although they simply "Mark as Complete" we have a one word minimum for written student responses
   */

  getGuessId() {
    const guessId = super.readData('guessId');
    return guessId;
  }

  get hasUserSelectedAnswer() {
    const rawData = this.readData('editorValue');
    const text = convertStateToString(rawData);
    const images = convertStateToImgArr(rawData);
    const audio = convertStateToAudioArr(rawData);
    const words = text.split(' ').filter((s) => !!s.length);

    return words.length > 0 || images.length > 0 || audio.length > 0;
  }

  getValue() {
    const value = this.readData('editorValue');
    return List.isList(value) ? value.toJS() : value;
  }

  getWordCount() {
    const rawData = this.readData('editorValue');
    const text = convertStateToString(rawData);
    return text.split(' ').filter((s) => s.length > 0).length;
  }

  loadGuess(guess) {
    // eslint-disable-next-line no-underscore-dangle
    super._loadGuess(guess);

    const parsedGuess = this.parseGuess(guess);
    if (parsedGuess !== null) {
      this.writeData('editorValue', parsedGuess);
      this.writeData('guessId', guess.id);
    }
  }

  parseGuess(guess) {
    const rawData = guess.getIn(['content', 'raw'], null);
    return JSON.parse(rawData);
  }

  async saveDraftCallback(cb) {
    super.writeData('hasPendingDebouncedSaveDraftGuess', true);
    await super.saveDraftGuess();
    const draftId = super.readData('draftId');
    cb(draftId);
  }

  updateInput(data) {
    this.writeData('editorValue', data);
    super.kickoffDebouncedSaveDraftGuess();
  }
}
