import React from 'react';
import {Link} from 'react-router';
import {Helmet} from 'react-helmet';

import {title} from 'lib/head';

import CenteredPage from 'client/Layout/CenteredPage';

import {Dialogue, Button, Text} from '@albert-io/atomic';

import laptop from './laptop.svg';

export default () => (
  <CenteredPage>
    <Helmet>{title('Session Expired | Albert')}</Helmet>
    <Dialogue hideCloseBtn>
      <Dialogue.Body align='center' className='u-display_flex u-flex-direction_column'>
        <img className='u-pad_2' src={laptop} alt='' />
        <Text>It looks like your session has expired.</Text>
      </Dialogue.Body>
      <Dialogue.BtnGroup align='center'>
        <Button as={Link} to='/log-in'>
          Log in again
        </Button>
      </Dialogue.BtnGroup>
    </Dialogue>
  </CenteredPage>
);
