// @flow
import {Map, type List} from 'immutable';
import moment from 'moment';
import {stats} from 'lib/StatsUtil';
import {getCorrectAnswerSettingFromValue} from 'client/Assignments/constants';
import {assignmentExtensions} from 'resources/augmented/Assignment/assignment.extensions';
import type {QuestionSetModelV1} from 'resources/augmented/QuestionSet/QuestionSetModel.v1';
import type {QuestionType} from 'resources/Question/Question.model';
import type {StudentModelV2} from 'resources/augmented/Student/StudentModel.v2';
export type QuestionAndSetType = {
  question: QuestionType,
  set: Map<string, *>
};

export const studentAssignmentModelExtensions = {
  /**
   * @see assignmentExtensions.shouldShowGuessFeedback
   */
  shouldShowGuessFeedback: assignmentExtensions.shouldShowGuessFeedback,
  /**
   * @see assignmentExtensions.isOverDue
   */
  isOverDue: assignmentExtensions.isOverDue,
  /**
   * @see assignmentExtensions.isPastDue
   */
  isPastDue: assignmentExtensions.isPastDue,
  /**
   * @see assignmentExtensions.isComplete
   */
  isComplete: assignmentExtensions.isComplete,
  canAssignmentBeSubmitted(): boolean {
    return this.isOverDue() === false && this.getMeta().isStudentSubmitted() === false;
  },

  /**
   * **IMPORTANT** This method expects student_assignments_v* metadata.
   */
  getAccuracy(): number {
    return stats.percentage(
      this.getMeta().getStudentCountOfCorrectGuesses(),
      this.getMeta().getStudentCountOfGuesses()
    );
  },
  getCorrectAnswerSettingDescriptionForStudent(): string {
    return getCorrectAnswerSettingFromValue(this.getCorrectAnswerSetting()).DESCRIPTION.STUDENT;
  },
  getDurationSinceStudentStartTime(student: StudentModelV2 | string, now: moment): moment.duration {
    return moment.duration(now.diff(this.getStudentStartTime(student)));
  },
  /**
   * Returns the number of minutes remaining for a student.
   * Will return `null` is an assignment is not timed.
   *
   * *IMPORTANT* This method relies on the inclusion of students_v* WITH metadata.
   */
  getMinutesRemaining(student: StudentModelV2 | string, now: number): number | null {
    if (this.isTimed() === false) {
      return null;
    }

    const duration = this.getDurationSinceStudentStartTime(student, now);
    if (duration.asMinutes() >= this.getTimeLimit()) {
      return 0;
    }

    return this.getTimeLimit() - duration.asMinutes();
  },
  /**
   * *IMPORTANT* This method relies on the inclusion of students_v* WITH metadata.
   */
  getTimeSpent(student: StudentModelV2 | string, now: moment): moment.duration {
    const submitted = this.getStudentSubmittedTime(student);
    if (submitted) {
      /**
       * If the student has submitted the assignment, the time spent is between the start_time and submission.
       */
      return moment.duration(moment(submitted).diff(this.getStudentStartTime(student, now)));
    }

    if (this.isTimed() === false) {
      /**
       * If the student has NOT submitted, and it's NOT a timed assignment, the time spent is simply
       * the duration since the student start time.
       */
      return this.getDurationSinceStudentStartTime(student, now);
    }

    /**
     * If the student is out of time and they haven't submitted (handled above) the duration is the time limit.
     * Otherise, it's the duration since the start time.
     */
    return this.isStudentOutOfTime(student, now)
      ? moment.duration(this.getTimeLimit(), 'minutes')
      : this.getDurationSinceStudentStartTime(student, now);
  },
  getFirstSetAndQuestion(
    sets: List<QuestionSetModelV1> = this.getOrderedQuestionSets()
  ): Map<QuestionAndSetType> | null {
    const set = sets.first();
    return Map({
      question: set.getQuestions().first(),
      set
    });
  },
  /**
   * *IMPORTANT* Expects question_sets.questions to be included AND assignment meta (for sorting by order).
   *
   * @returns {Immutable.Map|null} Returns a Immutable.Map with the matched question and set.
   */
  getFirstSetAndQuestionWithoutGuess(
    sets: List<QuestionSetModelV1> = this.getOrderedQuestionSets()
  ): Map<QuestionAndSetType> | null {
    let question;
    const set = sets.find((set: {getQuestions: Function}) => {
      question = set.getQuestions().find((q: {hasGuessForAssignment: Function}) => {
        /**
         * @todo This will likely need to be updated when questions_v* are using
         * generated models.
         */
        return q.hasGuessForAssignment(this.getId()) === false;
      });
      return question !== undefined;
    });
    return question
      ? Map({
          question,
          set
        })
      : null;
  },
  /**
   * **IMPORTANT** This method expects student_assignments_v* metadata.
   */
  getGrade(): number {
    return stats.percentage(
      this.getMeta().getStudentCountOfCorrectGuesses(),
      this.getMeta().getCountOfQuestions()
    );
  },
  /**
   * Assignment question_sets ordered by position (cached). Assumes assignment meta for position.
   */
  getOrderedQuestionSets(): List<*> {
    return this.cache('ordered_question_sets', () => {
      return this.getQuestionSets().sortBy((set: {getStudentAssignmentPosition: Function}) =>
        set.getStudentAssignmentPosition(this.getId())
      );
    });
  },

  getQuestionSetPosition(questionSetId: string): number {
    return this.getQuestionSetRelationshipMeta().getIn([questionSetId, 'position'], 0);
  },

  getQuestionSetRelationshipMeta(): Map<string, *> {
    return this.get('relationshipMeta').get('question_set');
  },

  getStudentRelationshipMeta(): Map<string, *> {
    return this.get('relationshipMeta').get('student');
  },
  /**
   * Returns the student's start_time from the relationship.
   *
   * *IMPORTANT* This method relies on the inclusion of students_v* WITH metadata.
   *
   * @param {StudentModelV2|String} student
   */
  getStudentStartTime(student: StudentModelV2 | string): moment | null {
    const id = typeof student === 'string' ? student : student.getId();
    return this.cache(`student_start_time_${id}`, () => {
      /**
       * This is INTENTIONALLY an unsafe chain to ensure proper use.
       * It's expected if you are calling this method you are using "includes" to ensure
       * this won't throw.
       */
      const startTime = this.getStudents()
        .find((s: StudentModelV2) => s.getId() === id)
        .getStudentAssignmentRelationships(this.getId())
        .getStartTime();

      return startTime ? moment(startTime) : null;
    });
  },
  /**
   * Returns the student's `submitted` time from the relationship.
   *
   * *IMPORTANT* This method relies on the inclusion of students_v* WITH metadata.
   *
   * @param {StudentModelV2|String} student
   */
  getStudentSubmittedTime(student: StudentModelV2 | string): moment | null {
    const id = typeof student === 'string' ? student : student.getId();
    return this.cache(`student_submitted_${id}`, () => {
      /**
       * This is INTENTIONALLY an unsafe chain to ensure proper use.
       * It's expected if you are calling this method you are using "includes" to ensure
       * this won't throw.
       */
      const submitted = this.getStudents()
        .find((s: StudentModelV2) => s.getId() === id)
        .getStudentAssignmentRelationships(this.getId())
        .getSubmitted();

      return submitted ? moment(submitted) : null;
    });
  },
  /**
   * Returns a boolean value depicting whether or not a student/user has started an assignment.
   *
   * *IMPORTANT* This method relies on the inclusion of students_v* WITH metadata.
   *
   * @param {StudentModelV2|String} student
   */
  hasStudentStartedAssignment(student: StudentModelV2 | string): boolean {
    return this.getStudentStartTime(student) !== null;
  },
  /**
   * **IMPORTANT** This method expects student_assignments_v* metadata.
   */
  isLastAssignmentQuestion(): boolean {
    return this.getMeta().getStudentCountOfGuesses() === this.getMeta().getCountOfQuestions() - 1;
  },
  /**
   * A student is considered "finished" if they can no longer take action on an assignment:
   *
   * - They've completed the assignment (all questions answered; `isComplete`)
   * - The assignment is "over due" (`isOverDue`)
   * - The assignment is submitted (`getMeta().isStudentSubmitted`)
   * - The student is out of time (`isStudentOutOfTime(student, now)`)
   *
   * **IMPORTANT** This method expects student_assignments_v* metadata.
   */
  isStudentFinished(student: StudentModelV2 | string, now: moment): boolean {
    return (
      this.isComplete() ||
      this.isOverDue() ||
      this.getMeta().isStudentSubmitted() ||
      this.isStudentOutOfTime(student, now)
    );
  },
  /**
   * If there is no time limit on the assignment, this method will return `false`.
   *
   * Expects time limits on the assignment to be minutes.
   *
   * @returns {Boolean} Whether or not the provided student is out of time.
   */
  isStudentOutOfTime(student: StudentModelV2 | string, now: moment): boolean {
    if (!this.getTimeLimit()) {
      return false;
    }
    return this.getDurationSinceStudentStartTime(student, now).asMinutes() >= this.getTimeLimit();
  },
  isTimed(): boolean {
    return this.getTimeLimit() > 0;
  }
};
