// @flow
import {List} from 'immutable';
import {type SubjectModelV2} from 'resources/GeneratedModels/Subject/SubjectModel.v2';

export const subscriptionModelValidators = {
  getStartDate(): ?List<string> {
    if (!this.getStartDate()) {
      return new List(['A start date is required']);
    }

    // validate start is before end
    if (this.getEndDate() && !this.getEndDate().isAfter(this.getStartDate())) {
      return new List(['Start date must be before end date']);
    }

    return null;
  },

  getEndDate(): ?List<string> {
    if (!this.getEndDate()) {
      return new List(['An end date is required']);
    }

    // validate start is before end
    if (this.getStartDate() && !this.getEndDate().isAfter(this.getStartDate())) {
      return new List(['End date must come after start date']);
    }

    return null;
  },

  getSubject(): ?List<string> {
    // we look at the changemap first as this is a relationship. This is hacky.
    const subjectBeingAdded: string = this.__changeMap.getIn(['__relationships', 'subject_v2']);
    const existingSubject: SubjectModelV2 = this.getSubject();
    if (!subjectBeingAdded && existingSubject && existingSubject.isEmpty()) {
      return new List(['Subject is required']);
    }

    return null;
  }
};
