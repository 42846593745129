import React from 'react';
import type {Record} from 'immutable';
import {callTargetedAction, setUpStore} from 'client/framework';
import MultipleChoiceQuestionStore from 'client/components/QuestionTypes/MultipleChoice/MultipleChoiceQuestion.store';
import {Fieldset, Text} from '@albert-io/atomic';
import {OptionEliminatorActions} from 'components/PracticeView/OptionEliminator/OptionEliminator.react';

import DraftGuessWrapper from '../../../AssignmentPracticeView/DraftGuesses/DraftGuessWrapper';

import multipleChoiceQuestionActions from './MultipleChoiceQuestion.actions';
import MultipleChoiceOption from './MultipleChoiceOption.react';
import OptionEliminatorInputWrapper from './MCQOptionEliminator/MCQOptionEliminator.react';

import './multiple-choice-question.scss';

import {useQuestionTranslationStore} from '../shared/TranslationSection';
import {useShallow} from 'zustand/react/shallow';

interface Props {
  isGuessSubmitted: boolean;
  question: Record<string, any>;
  storeName?: string;
}

function MultipleChoiceInputArea({
  isGuessSubmitted,
  storeName = 'multipleChoiceQuestionStore',
  question
}: Props) {
  const isSelectMultiple = question.isSelectMultiple();
  const store = setUpStore(MultipleChoiceQuestionStore, storeName, question.getId(), question);

  /**
   * @todo: Having stores hold onto the rubric doesn't make sense since it's available
   * on the question. We should update other types to do this as well. There's probably
   * more that we can be pulling out of the question directly, rathen than writing these
   * things to the store.
   */
  const rubric = question.getMeta().getQuestionRubric();
  const correctOptions = rubric ? rubric.get('validResponse') : null;

  // Translation info

  const {translatedQuestionInfo, showAnswerTranslation} = useQuestionTranslationStore(
    useShallow((state) => ({
      translatedQuestionInfo: state.translatedQuestionInfo,
      showAnswerTranslation: state.toggleStatus.answer
    }))
  );

  return (
    <DraftGuessWrapper storeName={storeName} submitted={isGuessSubmitted}>
      <div className='u-mar-b_4'>
        {!isGuessSubmitted && <OptionEliminatorActions />}
        <div className='mcq__options-wrapper'>
          <Fieldset>
            {!isGuessSubmitted && (
              <Text as='legend' size='m' className='u-mar-b_2' bold>
                {isSelectMultiple ? 'Select all that apply' : 'Select one answer'}
              </Text>
            )}
            {question.getOptions().map((option, index) => {
              const optionId = option.getId();
              const translatedText: string =
                translatedQuestionInfo?.options[index]?.value || option.getValue();

              return (
                <OptionEliminatorInputWrapper
                  questionId={question.getId()}
                  optionId={optionId}
                  disabled={isGuessSubmitted}
                  onEliminate={() => {
                    callTargetedAction({
                      name: multipleChoiceQuestionActions.REMOVE_OPTION_FROM_SELECTED,
                      payload: optionId,
                      targetStore: storeName
                    });
                  }}
                  key={optionId}
                >
                  {(isEliminated) => (
                    <MultipleChoiceOption
                      index={index}
                      option={option}
                      isGuessSubmitted={isGuessSubmitted}
                      isSelected={store.isSelectedOption(optionId)}
                      isCorrectAnswer={correctOptions && correctOptions.has(optionId)}
                      isEliminated={isEliminated}
                      targetStore={store.getName()}
                      isSelectMultiple={isSelectMultiple}
                      translatedOptionText={translatedText}
                      showTranslation={showAnswerTranslation}
                    />
                  )}
                </OptionEliminatorInputWrapper>
              );
            })}
          </Fieldset>
        </div>
      </div>
    </DraftGuessWrapper>
  );
}

export {MultipleChoiceInputArea};
