/* eslint-disable import/prefer-default-export */
import {hasStore, destroyStore} from 'client/framework';

/**
 * Takes a list of question sets, and destroys any question stores that
 * were created from any questions in those question sets.
 *
 * Returns the number of stores that were destroyed.
 *
 * @param {Immutable.List<*>} questionSets
 * @returns {number}
 */
export function destroyQuestionStoresForQuestionSets(questionSets) {
  let destroyedCount = 0;
  questionSets.forEach((questionSet) => {
    questionSet.getQuestions().forEach((question) => {
      const storeName = question.getStoreName();
      if (hasStore(storeName)) {
        destroyedCount += 1;
        destroyStore(storeName);
      }
    });
  });
  return destroyedCount;
}
