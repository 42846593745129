import React from 'react';
import {Button, Icon, WithTooltip} from '@albert-io/atomic';

import './image-v2.scss';

interface HiddenImageProps {
  setIsHidden: (isHidden: boolean) => void;
  isMobile: boolean;
}

export const HiddenImage = ({setIsHidden, isMobile}: HiddenImageProps) => (
  <div className='image-supplement__hidden'>
    <WithTooltip enabled={!isMobile} content='Show Image' placement='right' theme='dark'>
      <Button
        className='image-supplement__hidden-button'
        color='inherit'
        onClick={() => setIsHidden(false)}
        aria-label='Show Image'
      >
        <Icon icon={['far', 'image']} color='inherit' className='image-supplement__hidden-icon' />
        <Icon icon={['far', 'eye']} color='brand' className='image-supplement__hidden-icon' />
      </Button>
    </WithTooltip>
  </div>
);
