import React, {lazy, Suspense, FocusEvent, ChangeEvent} from 'react';
import {Set} from 'immutable';

import {LoadingSpinner} from '@albert-io/atomic';
import supplementStore from 'client/Supplement/SupplementStore';
import {withProps} from 'lib/hocs/WithProps';

import {displayAsOptions} from './authoringTextInput.utils';

const AuthoringTextInputEditor = lazy(() => import('./AuthoringTextInputEditor.react'));

interface AuthoringTextInputProps extends PropsWithClassNameOptional {
  displayAs?: keyof typeof displayAsOptions;
  error?: string;
  height?: number;
  label?: string;
  name?: string;
  onBlur?: (event: FocusEvent<HTMLElement>) => void;
  onChange?: (event: ChangeEvent<HTMLElement>) => void;
  supplements: Set<any>;
  value?: string;
  isFocused?: boolean;
}

const AuthoringTextInput = (props: AuthoringTextInputProps) => (
  <Suspense fallback={<LoadingSpinner />}>
    <AuthoringTextInputEditor {...props} />
  </Suspense>
);

AuthoringTextInput.defaultProps = {
  displayAs: displayAsOptions.textarea,
  isFocused: false,
  height: 180,
  name: '',
  value: ''
};

export default withProps(
  () => ({
    supplements: supplementStore.getSupplements()
  }),
  AuthoringTextInput,
  {
    suppressPureComponentWarning: true
  }
);
