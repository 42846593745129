/* eslint-disable */


// Programatically generated on Mon Mar 31 2025 17:10:40 GMT+0000
import {fromJS, List, Map, Record, Set} from 'immutable';
import {GenericModel} from 'resources/Generic.model';
import {validate, Empty} from '@albert-io/json-api-framework/models/helpers';
import moment from 'moment';

const ModelConfig = fromJS(
  {
    'endpoint': 'school_licenses_v1',
    'attributes': {
      'active': {
        'type': 'boolean',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'number_of_seats': {
        'type': 'integer',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'license_type': {
        'type': 'string',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'end_date': {
        'type': 'datetime',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'start_date': {
        'type': 'datetime',
        'isRequired': true,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'updated_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'inserted_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'id': {
        'type': 'binary_id',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      }
    },
    'relationships': {
      'upgrades_v1': {
        'type': 'one-to-many'
      },
      'school_v1': {
        'type': 'one-to-one'
      },
      'school_v2': {
        'type': 'one-to-one'
      },
      'school_v3': {
        'type': 'one-to-one'
      },
      'school_v4': {
        'type': 'one-to-one'
      },
      'school_v5': {
        'type': 'one-to-one'
      },
      'school_license_subject_groups_v1': {
        'type': 'one-to-many'
      },
      'authoring_subjects_v1': {
        'type': 'one-to-many'
      },
      'subjects_v1': {
        'type': 'one-to-many'
      },
      'subjects_v2': {
        'type': 'one-to-many'
      }
    }
  }
);

const ModelRecord = Record(
  {
    'active': Empty,
    'subjects': Empty,
    'start_date': Empty,
    'authoring_subjects': Empty,
    'school_license_subject_groups': Empty,
    'end_date': Empty,
    'school': Empty,
    'upgrades': Empty,
    'updated_at': Empty,
    'license_type': Empty,
    'inserted_at': Empty,
    'id': Empty,
    'number_of_seats': Empty,
    'relationshipMeta': Empty,
    'meta': new Map({
    'populated_metadata_fields': Empty,
    'school_name': Empty
  })
  }
);

const DefaultModel = fromJS(
  {
    'meta': new Map(),
    'active': false,
    'license_type': '',
    'id': '',
    'start_date': new Date(),
    'end_date': new Date(),
    'updated_at': new Date(),
    'inserted_at': new Date(),
    'subjects': new List(),
    'authoring_subjects': new List(),
    'school_license_subject_groups': new List(),
    'upgrades': new List(),
    'school': new Map(),
    'number_of_seats': 0,
    'relationshipMeta': new Map()
  }
);

export class SchoolLicenseModelV1 extends GenericModel(ModelRecord, ModelConfig, 'SchoolLicenseModelV1') {
  // -- Query Rules --
  /**
   * @returns {boolean}
   */
  static allowsInclude(include) {
    // AllowedIncludes is currently disabled.
    return true; // AllowedIncludes.has(include);
  }

  /**
   * @returns {boolean}
   */
  static allowsFilter(filterField, filterType) {
    return filterType ?
      FilterRules.get(filterField).has(filterType) :
      FilterRules.has(filterField);
  }

  /**
   * @returns {boolean}
   */
  static allowsSort(sort) {
    return sort.split(',').filter((s) => AllowedSorts.has(s)).length > 0;
  }

  /**
   * @returns {Array}
   */
  static getAllowedSorts() {
    return AllowedSorts.keySeq().toJS();
  }

  static getMetaContextQuery(key) {
    if (!MetadataContextRules.hasIn(['optional', key]) && !MetadataContextRules.hasIn(['required', key])) {
      console.error('Rule for meta context key ' + key + ' does not exist');
    }

    return MetadataContextRules.getIn(['optional', key]) || MetadataContextRules.getIn(['required', key]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getRequiredMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values()]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getAllMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values(), ...MetadataContextRules.get('optional').values()]);
  }

  static getDefaultModel() {
    _emptySchoolLicenseModelV1Model = _emptySchoolLicenseModelV1Model ? _emptySchoolLicenseModelV1Model : new SchoolLicenseModelV1(DefaultModel);
    return _emptySchoolLicenseModelV1Model;
  }

  static supportsMetaContext() {
    return MetadataContextRules !== null;
  }

  /**
   * @returns {string}
   */
  static getModelName() {
    return 'SchoolLicenseModelV1';
  }

  /**
   * @returns {boolean}
   */
  static _isFullSpec() {
    return true;
  }

  constructor() {
    super(...arguments);
    this.__meta = null;
  }

  /**
   * @returns {string}
   */
  getJsonApiType() {
    return 'school_license_v1';
  }

  /**
   * @params {string} - An attribute name
   * @params {*} [def] - Optional default value override for the attribute
   */
  getValueForAttribute(attribute, def) {
    let value = this.get(attribute);
    validate(value, attribute);
    if (value === Empty) {
      value = def !== undefined ? def : DefaultModel.get(attribute);
    }
    return value;
  }

  // -- Primary Attributes --
  
  /**
   * @type {boolean}
   */
  isActive() {
    const value = this.getValueForAttribute('active');
    return value;
  }

  /**
   * @type {datetime}
   */
  getEndDate() {
    const value = this.getValueForAttribute('end_date');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  setEndDate(value) {
    const momentValue = moment(value);
    return this.setField('end_date', momentValue.isValid() ? momentValue : value);
  }

  /**
   * @type {binary_id}
   */
  getId() {
    const value = this.getValueForAttribute('id');
    return value;
  }

  setId(value) {
    return this.setField('id', value);
  }

  /**
   * @type {datetime}
   */
  getInsertedAt() {
    const value = this.getValueForAttribute('inserted_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {string}
   */
  getLicenseType() {
    const value = this.getValueForAttribute('license_type');
    return value;
  }

  setLicenseType(value) {
    return this.setField('license_type', value);
  }

  /**
   * @type {integer}
   */
  getNumberOfSeats() {
    const value = this.getValueForAttribute('number_of_seats');
    
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
  }

  setNumberOfSeats(value) {
    return this.setField('number_of_seats', value);
  }

  /**
   * @type {datetime}
   */
  getStartDate() {
    const value = this.getValueForAttribute('start_date');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  setStartDate(value) {
    const momentValue = moment(value);
    return this.setField('start_date', momentValue.isValid() ? momentValue : value);
  }

  /**
   * @type {datetime}
   */
  getUpdatedAt() {
    const value = this.getValueForAttribute('updated_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  // -- Meta Attributes --
  
    /**
     * LEGACY META ACCESSORS
     * @todo These should no longer be used, instead use this.getMeta()[...]
     */
    
      getPopulatedMetadataFields() {
        return this.getMeta().getPopulatedMetadataFields();
      }
    
      getSchoolName() {
        return this.getMeta().getSchoolName();
      }
    
    getMeta() {
      if (this.__meta) {
        return this.__meta;
      }
      const meta = this.get('meta', Map());
      this.__meta = {
        
          /**
           * @type {string[]}
           */
          getPopulatedMetadataFields() {
            const value = meta.get('populated_metadata_fields');
            validate(value, 'populated_metadata_fields');
            return value;
          },
  
          /**
           * @type {string}
           */
          getSchoolName() {
            const value = meta.get('school_name');
            validate(value, 'school_name');
            return value;
          },
  
      };
      return this.__meta;
    }
  
  // -- Relationships --
  
  /**
   * @type {authoring_subject_v1 (many)}
   */
  getAuthoringSubjects() {
    const value = this.getValueForAttribute('authoring_subjects');
    return value;
  }

  // -- Relationship Fields: authoring_subjects --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {school_license_subject_group_v1 (many)}
   */
  getSchoolLicenseSubjectGroups() {
    const value = this.getValueForAttribute('school_license_subject_groups');
    return value;
  }

  // -- Relationship Fields: school_license_subject_groups --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {subject_v2 (many)}
   */
  getSubjects() {
    const value = this.getValueForAttribute('subjects');
    return value;
  }

  // -- Relationship Fields: subjects --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {upgrade_v1 (many)}
   */
  getUpgrades() {
    const value = this.getValueForAttribute('upgrades');
    return value;
  }

  // -- Relationship Fields: upgrades --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {school_v5 (one)}
   */
  getSchool() {
    const value = this.getValueForAttribute('school');
    return value;
  }

  // -- Relationship Fields: school --
  // no 'fields' found, skipped generating accessor(s)
  
}

// -- Empty Model Instance --
let _emptySchoolLicenseModelV1Model = null;

// -- Sort Rules --

const AllowedSorts = fromJS({
  'active': null,
  'end_date': null,
  'id': null,
  'inserted_at': null,
  'license_type': null,
  'meta.school_name': null,
  'number_of_seats': null,
  'start_date': null,
  'updated_at': null,
});

// -- Include Rules --
// List []

// -- Filter Rules --
const FilterRules = new Map({
  'active': new Set(['exact','not','null']),
  'authoring_subjects_v1': new Set(['associated']),
  'end_date': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'inserted_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'license_type': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'meta.populated_metadata_fields': new Set(['null']),
  'meta.school_name': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'number_of_seats': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'school_license_subject_groups_v1': new Set(['associated']),
  'school_v1': new Set(['associated']),
  'school_v2': new Set(['associated']),
  'school_v3': new Set(['associated']),
  'school_v4': new Set(['associated']),
  'school_v5': new Set(['associated']),
  'start_date': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'subjects_v1': new Set(['associated']),
  'subjects_v2': new Set(['associated']),
  'updated_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'upgrades_v1': new Set(['associated']),
});
    

// -- Metadata Context Rules --
const MetadataContextRules = fromJS({
  optional: {
  },
  required: {
  }
});

// -- Legacy Name Export --
export const SchoolLicense = SchoolLicenseModelV1;
