import qs from 'qs';
import download from 'downloadjs';

import {getRequestAgent} from '@albert-io/mandark/authentication';
import {getHeaders} from 'resources/mandark.resource';
import {getHost} from '@albert-io/environment';
import {sanitizeFilename} from 'lib/stringUtilsTs';

const host = getHost();

export const run = function run(payload) {
  /**
   * This RegExp was pulled from downloadjs...
   */
  if (/(Version)\/(\d+)\.(\d+)(?:\.(\d+))?.*Safari\//.test(window.navigator.userAgent)) {
    /* eslint-disable no-alert */
    window.alert(
      'This feature is not yet supported in Safari.\n' +
        'For best results, please use the latest version of Google Chrome.'
    );
    return new Promise((reject, resolve) => {
      resolve();
    });
  }

  const query = qs.stringify(payload.get('query'), {
    arrayFormat: 'brackets',
    encode: false
  });

  return getRequestAgent()
    .get(`${host}${payload.get('path')}`)
    .query(query)
    .set(getHeaders())
    .set({
      Accept: '*/*'
    })
    .responseType('blob')
    .timeout(60 * 1000)
    .then((response) => {
      download(response.body, sanitizeFilename(payload.get('filename')), response.type);
    });
};
