/* eslint-disable import/prefer-default-export */
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import {List} from 'immutable';
import {resource} from '@albert-io/json-api-framework/request/builder';
import {Anchor, Chip, Heading, WithTooltip, Text, Icon, LoadingSpinner} from '@albert-io/atomic';
import MarkdownRendererV2 from 'client/generic/MarkdownRendererV2/MarkdownRendererV2.react';
import sessionStore from 'client/Session/SessionStore';
import './tags-standards-list.scss';

export const TagsList = ({tags, linkToFunc}) => {
  if (tags.isEmpty()) {
    return null;
  }

  return (
    <div className='tags-standards-list u-mar-b_2'>
      <Heading size='xs'>Tags</Heading>
      <ul className='tags-standards-list__list'>
        {tags.map((tag) => {
          return (
            <Chip key={tag.getId()} as={Link} to={linkToFunc(tag)}>
              {tag.getName()}
            </Chip>
          );
        })}
      </ul>
    </div>
  );
};

TagsList.propTypes = {
  tags: PropTypes.instanceOf(List).isRequired,
  linkToFunc: PropTypes.func.isRequired
};

export const StandardsList = ({standards, linkToFunc}) => {
  if (standards.isEmpty()) {
    return null;
  }

  const [filterUserPreferences, setFilterUserPreferences] = useState(true);

  const displayStandards = filterUserPreferences
    ? standards.filter((standard) => standard.getMeta().isIncludedInPersonalizationSettings())
    : standards;

  return (
    <div className='tags-standards-list u-mar-b_2 u-mar-t_6'>
      <Heading size='s'>Standards</Heading>
      {!sessionStore.isStudent() && (
        <>
          <div className='u-color_purple-500'>
            <Text
              color={filterUserPreferences ? 'inherit' : 'tertiary'}
              data-testid='personalization__teacher-preferences-msg'
            >
              Content preferences {filterUserPreferences ? 'applied' : 'turned off'}
            </Text>
            <WithTooltip theme='light' content={<StandardPreferences />}>
              <Icon
                icon={['far', 'info-circle']}
                className='u-mar-l_1 u-cursor_pointer'
                data-testid='personalization__info-tooltip'
                color={filterUserPreferences ? 'inherit' : 'primary'}
                emphasis={filterUserPreferences ? 'high' : 'lowest'}
              />
            </WithTooltip>
            <Anchor
              data-testid='personalization__toggle-filter-preferences'
              inheritColor
              className='u-text-decoration_underline u-mar-l_1'
              as='button'
              onClick={() => setFilterUserPreferences(!filterUserPreferences)}
            >
              {filterUserPreferences ? 'Turn Off' : 'Turn On'}
            </Anchor>
          </div>
        </>
      )}
      <ul className='tags-standards-list__list'>
        {displayStandards.map((standard) => {
          return (
            <WithTooltip
              as='li'
              key={standard.getId()}
              content={<MarkdownRendererV2 text={standard.getDescription()} />}
              placement='top'
              wrapperProps={{
                className: 'u-list-style_none'
              }}
            >
              <Chip
                key={standard.getId()}
                as={Link}
                to={linkToFunc(standard)}
                className='standards-list__standard-chip'
              >
                {standard.getTitle()}
              </Chip>
            </WithTooltip>
          );
        })}
      </ul>
    </div>
  );
};

StandardsList.propTypes = {
  standards: PropTypes.instanceOf(List).isRequired,
  linkToFunc: PropTypes.func.isRequired
};

export const StandardPreferences = () => {
  const fetchPreferencesQuery = resource('standard_set_creators_v1')
    .mandarkEndpoint(['standard_set_creators_v1'])
    .filter({
      users_v2: {id: sessionStore.getUserId()}
    });

  const [publishers, setPublishers] = useState([]);

  useEffect(() => {
    fetchPreferencesQuery.getResourcePromise().then(setPublishers);
  }, [fetchPreferencesQuery]);

  return (
    <div>
      {!fetchPreferencesQuery.isResourceReady() ? (
        <LoadingSpinner size={2} className='u-color_slate-300 u-mar-lr_auto' />
      ) : (
        <div>
          <Text as='p' bold className='u-pad-lr_2 u-pad-t_1 u-mar-b_0'>
            Content Preferences:
          </Text>
          <div className='u-display_flex u-flex-direction_column u-gap_space-x1 u-pad_2'>
            {publishers.map((publisher) => (
              <Text as='span' className='personalization__tooltip-publisher-name'>
                {publisher.getName()}
              </Text>
            ))}
          </div>
          <div className='u-display_flex u-justify-content_center u-pad_1 u-mar-t_1 u-border-color_slate-300 u-border-t'>
            <Anchor
              noUnderline
              to='/account/settings'
              data-testid='personalization__tooltip-settings-link'
              className='u-color_slate-500 u-text-align_center'
            >
              Edit Preferences <Icon icon='external-link' className='u-mar-l_1' />
            </Anchor>
          </div>
        </div>
      )}
    </div>
  );
};
