export const SUPPLEMENT_TYPES = {
  IMAGE: 'image',
  PASSAGE: 'passage',
  AUDIO: 'audio',
  VIDEO: 'video',
  SORTABLE_TABLE: 'sortable-table',
  FREE_FORM: 'free-form'
};

export const SUPPLEMENT_TYPES_LIST = [
  SUPPLEMENT_TYPES.IMAGE,
  SUPPLEMENT_TYPES.PASSAGE,
  SUPPLEMENT_TYPES.AUDIO,
  // SUPPLEMENT_TYPES.VIDEO,
  SUPPLEMENT_TYPES.SORTABLE_TABLE,
  SUPPLEMENT_TYPES.FREE_FORM
];
