import {useCallback, useMemo, useState} from 'react';
import {List} from 'immutable';

export const useAllowedEntitiesState = (initialQuestion, correctAnswerEntities) => {
  const initialAllowedEntities = initialQuestion
    .getQuestionTypeAttributes()
    .getIn(['graph_contains', 'allowed_entities'], List())
    .toJS();

  const [selectedEntities, setSelectedEntities] = useState<any>(new Set(initialAllowedEntities));

  const boardEntities = new Set(correctAnswerEntities.map(({type}) => type));

  const toggleAllowedEntity = useCallback(
    (name) => {
      setSelectedEntities((state) => {
        const hasEntity = state.has(name);
        const tempState = new Set(state);
        tempState[hasEntity ? 'delete' : 'add'](name);
        return tempState;
      });
    },
    [setSelectedEntities]
  );

  const allowedEntities = Array.from(mergeSets(selectedEntities, boardEntities));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoedAllowedEntities = useMemo(() => allowedEntities, [allowedEntities.length]);

  return [memoedAllowedEntities, toggleAllowedEntity];
};

function mergeSets(...sets) {
  return sets.reduce((acc, curr) => {
    curr.forEach((item) => acc.add(item));
    return acc;
  }, new Set());
}
