import React, {useContext} from 'react';
import {List} from 'immutable';
import classnames from 'classnames';
import {LoadingSpinner, Text, ListGroup, ListGroupItem} from '@albert-io/atomic';

import QuestionSetsWrapper from './QuestionSetsWrapper.react';

import {MobilePracticeViewContext} from 'components/PracticeView/MobilePracticeViewProvider.context';

import './questions-list.scss';
import {QuestionListProps} from './questionsList.types';

export const QuestionsList = ({
  activeQuestion,
  className,
  footer,
  header,
  isLoading,
  noQuestionSetsMessage,
  onScrollLastQuestionIntoView,
  questionProps,
  questionSetProps,
  questionSets = List(),
  scrollToQuestionById,
  scrollToTop
}: QuestionListProps) => {
  const {isMobile, isQuestionsListInView} = useContext(MobilePracticeViewContext);

  return (
    <div
      className={classnames(
        'practice-view__questions-wrapper u-display_flex u-overflow_hidden u-pad-b_2 u-pad-r_2 u-pad-t_2',
        {
          'u-pointer-events_none': isMobile && !isQuestionsListInView
        }
      )}
    >
      <ListGroup
        className={classnames('questions-list-v2', className, {
          'questions-list-v2--loading': isLoading
        })}
      >
        {header ? <ListGroupItem header>{header}</ListGroupItem> : null}
        {questionSets.isEmpty() ? (
          <div className='u-pad-tb_4 u-pad-lr_6 u-text-align_center'>
            <Text as='p' size='l' color='secondary'>
              {noQuestionSetsMessage}
            </Text>
          </div>
        ) : (
          <QuestionSetsWrapper
            useDragHandle
            scrollToTop={scrollToTop}
            helperClass='question-set-sortable-helper'
            questionSets={questionSets}
            questionSetProps={questionSetProps}
            questionProps={questionProps}
            activeQuestion={activeQuestion}
            onScrollLastQuestionIntoView={onScrollLastQuestionIntoView}
            scrollToQuestionById={scrollToQuestionById}
          />
        )}
        {footer ? (
          <ListGroupItem footer paddingLevel={0} className='questions-list-v2__footer'>
            {footer}
          </ListGroupItem>
        ) : null}
        {isLoading ? (
          <LoadingSpinner className='questions-list-v2__loading-spinner' size={2} />
        ) : null}
      </ListGroup>
    </div>
  );
};

export default QuestionsList;
