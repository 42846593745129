/**
 * Given any number of string arguments, this returns an object where key and value is the same.
 *
 * @example
 * makeConstants('foo', 'bar'); // returns {foo: 'foo', bar: 'bar'}
 *
 * @param  {...string} constants One or more strings
 * @returns {object<string, string>} Constants object
 */

type MakeConstantsReturnType<T extends string[]> = {
  [U in T[number]]: U;
};

const makeConstants = <T extends string[]>(...values: T): MakeConstantsReturnType<T> => {
  const asObj = values.reduce((acc, curr) => {
    acc[curr] = curr;
    return acc;
  }, {} as {[k: string]: string});

  return asObj as MakeConstantsReturnType<T>;
};

export default makeConstants;
