import {Record} from 'immutable';
import {stats} from 'lib/StatsUtil';

class MultipleChoiceOptionModel extends Record({
  id: '',
  label: '',
  value: ''
}) {
  getId() {
    return this.get('id');
  }

  getLabel() {
    return this.get('label');
  }

  getValue() {
    return this.get('value');
  }
}

export function multipleChoiceSetup(data) {
  const options = data.get('options').map((option) => new MultipleChoiceOptionModel(option));
  return data.set('options', options);
}

export const multipleChoiceQuestionExtensions = {
  getOptionResponseCount(optionId) {
    return this.getIn(['meta', 'how_others_answered', optionId, 'count_of_answers'], 0);
  },

  getOptionResponsePercentage(optionId) {
    return stats.percentage(this.getOptionResponseCount(optionId), this.getTotalAnswerCount());
  }
};
