import React, {useContext} from 'react';
import {PublicQuestionsContext, PublicQuestionsDispatch} from './PublicQuestionsContext';
import {previousQuestionDisabled, nextQuestionDisabled} from './util';
import {Text, Footer, IconButton} from '@albert-io/atomic';
import MarkdownRenderer from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {ContentBlocker} from './ContentBlocker';

export const PublicQuestion = () => {
  const dispatch = useContext(PublicQuestionsDispatch);
  const state = useContext(PublicQuestionsContext);
  const {currentQuestion, questions, initialQuestionLoaded} = state;
  const canChangeQuestion = initialQuestionLoaded && questions.length > 1;

  return (
    <>
      {currentQuestion && (
        <div className='public-question u-mar-tb_2 u-mar-b_2'>
          <div className='public-question__question-content highlighted-content-area'>
            <div className='public-question__header u-pad-tb_1 u-pad-l_2 u-pad-r_1'>
              <div className='public-question__question-details u-display_flex u-gap_space-x1 u-flex-grow_1'>
                <div className='question-details__title u-font-weight_bold u-flex-wrap_wrap'>
                  <MarkdownRenderer
                    key={currentQuestion.getId()}
                    text={currentQuestion.getTitle()}
                  />
                </div>
                {!!currentQuestion.getDifficulty() && (
                  <div className='question-details__difficulty'>
                    <Text size='s'>{`(${currentQuestion.getDifficultyLabel()})`}</Text>
                  </div>
                )}
              </div>
              <div className='question-details__question-nav'>
                <IconButton
                  icon='chevron-left'
                  label='previous question'
                  disabled={
                    !canChangeQuestion || previousQuestionDisabled(questions, currentQuestion)
                  }
                  onClick={() => {
                    dispatch({
                      type: 'previousQuestion'
                    });
                  }}
                />
                <IconButton
                  icon='chevron-right'
                  label='next question'
                  disabled={!canChangeQuestion || nextQuestionDisabled(questions, currentQuestion)}
                  onClick={() => {
                    dispatch({
                      type: 'nextQuestion'
                    });
                  }}
                />
              </div>
            </div>
            <div className='public-question__prompt u-pad_2'>
              <ContentBlocker />
            </div>
          </div>
          <Footer className='u-pad-b_3' minimal />
        </div>
      )}
    </>
  );
};
