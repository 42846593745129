import React, {useContext, useRef, useCallback} from 'react';

import {v4 as uuid} from 'uuid';

import {useSlate} from 'slate-react';

import {Transforms} from 'slate';

import {callTargetedAction} from 'client/framework';

import {MediaModelV1} from 'resources/GeneratedModels/Media/MediaModel.v1';

import freeResponseActions from '../../../../../../FreeResponseQuestion.actions';

import {saveMedia, getUser} from '../../../../../../FreeResponseQuestion.queries';

import {getBase64, insertUploadingAudioChip} from '../../../../Utils/editor.utils';

import WrittenSubmissionContext from '../../../../../WrittenSubmission.context';

import {Text, Icon, MenuListItem} from '@albert-io/atomic';

import {useUploadErrorHandler, ErrorModal} from '../../../../SharedModals/ErrorModal.react';

import '../../../../written-submission.scss';
import {AudioChipElement} from '../../../../slate.types';

export const AUDIO_EXTENSIONS = ['.mp3', '.m4a', '.mp4', '.ogg'];

interface AudioUploadButtonProps {
  setShowDropdown: (boolean) => void;
  tabIndex: number;
}

export const AudioUploadButton = ({setShowDropdown, tabIndex}: AudioUploadButtonProps) => {
  const {store, storeName} = useContext(WrittenSubmissionContext);
  const {handleUploadError, showUploadErrorModal, uploadErrorDetails, setShowUploadErrorModal} =
    useUploadErrorHandler();
  const presentedAcceptedExtensions = AUDIO_EXTENSIONS.join(', ');
  const acceptedExtensions = AUDIO_EXTENSIONS.join(',');
  const clickable = tabIndex === 0;

  const {current: id} = useRef(uuid());

  const editor = useSlate();

  const getUploadingChipPath = () => {
    return editor.children.findIndex(
      (node) => 'type' in node && node.type === 'uploading-audio-chip'
    );
  };

  const uploadAudio = useCallback(
    async (draftId: string, file: File | null) => {
      let hasUploadingChip = false;
      let chipPath;

      if (!file) {
        handleUploadError({acceptedExtensions: presentedAcceptedExtensions});
        return;
      }

      try {
        insertUploadingAudioChip(editor);
        hasUploadingChip = true;

        const data = await getBase64(file);
        const response = (await saveMedia({media: data}, draftId, 'audio')) as MediaModelV1;
        const mediaId = response.getId();

        const user = await getUser();
        const userName = `${user.getFirstName()} ${user.getLastName()}`;

        chipPath = getUploadingChipPath();
        Transforms.removeNodes(editor, {at: [chipPath]});
        hasUploadingChip = false;

        const Node: AudioChipElement = {
          type: 'audio-chip',
          id: mediaId,
          userName,
          caption: file.name,
          children: [{text: ''}]
        };
        Transforms.insertNodes(editor, Node, {at: [chipPath]});
      } catch (error: any) {
        if (hasUploadingChip) {
          Transforms.removeNodes(editor, {at: [getUploadingChipPath()]});
        }
        handleUploadError({
          error,
          fileName: file.name,
          acceptedExtensions: presentedAcceptedExtensions
        });
      }
    },
    [editor]
  );

  const handleUpload = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const target = e.currentTarget;
      const file = target.files && target.files[0];

      if (store) {
        const draftId = store.getDraftId();
        if (draftId) {
          uploadAudio(draftId, file);
        } else {
          callTargetedAction({
            name: freeResponseActions.SAVE_CALLBACK,
            targetStore: storeName,
            payload: (newDraftId: string) => {
              uploadAudio(newDraftId, file);
            }
          });
        }
      }
    },
    [uploadAudio, store, storeName]
  );

  const handleDropdownClick = () => {
    setShowDropdown(false);
    document.getElementById(`file-input-${id}`)?.click();
  };

  return (
    <MenuListItem
      className='written-submission__media-dropdown__item'
      onMenuItemClick={handleDropdownClick}
      clickable={clickable}
    >
      <Text bold>Audio</Text>
      <Icon icon={['far', 'volume']} />
      <input
        className='u-display_none'
        id={`file-input-${id}`}
        accept={acceptedExtensions}
        type='file'
        onClick={(e) => {
          (e.target as HTMLInputElement).value = ''; // will trigger onChange regardless if same file is added
        }}
        onChange={handleUpload}
      />
      {showUploadErrorModal && (
        <ErrorModal
          errorDetails={uploadErrorDetails}
          onClose={() => setShowUploadErrorModal(false)}
        />
      )}
    </MenuListItem>
  );
};
