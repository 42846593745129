import React from 'react';
import {Text, Button, Icon, Heading} from '@albert-io/atomic';

export const ContentBlocker = () => {
  return (
    <div className='question-content-blocker u-mar-t_4'>
      <div className='question-content-blocker__icon-wrapper'>
        <Icon icon='lock' className='question-content-blocker__lock-icon' size='inherit' />
      </div>
      <div className='question-content-blocker__description u-mar-t_2'>
        <Heading size='l'>Unlock all content with a school or district license!</Heading>
        <Text size='l' as='ul' bold color='inherit' className='u-mar-tb_2'>
          <li>
            All content included with every student license -- Implement Albert.io across all
            departments
          </li>
          <li>Standards-aligned with all state and national standards</li>
          <li>Great for formative practice and summative assessments</li>
          <li>
            Powerful teacher features -- Differentiation, collaboration, powerful data reports, and
            more
          </li>
          <li>World-class support for teachers and administrators</li>
        </Text>
      </div>
      <div className='u-mar-t_6'>
        <Button as='a' href='/pricing-schools' className='view-license-options'>
          <Icon icon='sparkles' className='u-mar-r_1' />
          <Text>View License Options</Text>
        </Button>
      </div>
      <Icon icon='sparkles' className='question-content-blocker__icon--sparkles' />
    </div>
  );
};
