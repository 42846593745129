// @flow
import {fromJS, List, Map, Set} from 'immutable';
import {stats} from 'lib/StatsUtil';
import {getStoreByName} from 'client/framework';
import sessionStore from 'client/Session/SessionStore';
import vanillaIEQuestionsListActions from './VanillaIEQuestionsList.actions';
import QuestionsListStore from 'client/InteractionEngineV2/shared/QuestionsList/QuestionsListStore';
import appStore from 'client/AppStore';
import {
  makeQuestionSetsByGuideLevelSlugQuery,
  makeQuestionSetBySlugQuery
} from 'resources/Question/QuestionSet.queries';
import {getSearchQuestionsForPracticeView} from 'client/components/AdvancedSearch/AdvancedSearch.queries';
import {getSearchParams, isSearchMode} from 'client/components/AdvancedSearch/AdvancedSearch.utils';
import {makeGuessQuery} from 'resources/Guess/Guess.queries';
import {
  getResource,
  getResourcePromise,
  isResourcePopulated,
  isResourceValid,
  isResourcePending,
  isResourceReady,
  getResourceMetadata,
  invalidateInterest
} from 'resources/mandark.resource';
import questionsListSettingsStore, {
  FILTER_OPTIONS
} from './QuestionsListSettings/QuestionsListSettings.store';
import questionsListActions from 'client/InteractionEngineV2/shared/QuestionsList/QuestionsListActions';
import questionsListSettingsActions from './QuestionsListSettings/QuestionsListSettings.actions';
import activeQuestionActions from 'client/InteractionEngineV2/shared/ActiveQuestion/ActiveQuestionActions';
import interactionEngineStore from 'client/InteractionEngineV2/InteractionEngineStore';
import interactionEngineActions from 'client/InteractionEngineV2/InteractionEngineActions';
import {buildResourceKey, memoize} from 'lib/memoizer';
import {convertSetAndQuestionToPath} from 'client/InteractionEngineV2/InteractionEngine.util';
import {history} from 'client/history';

import type {QuestionType} from 'resources/Question/Question.model';
import type {QuestionSet as QuestionSetModelV1} from 'resources/GeneratedModels/QuestionSet/QuestionSetModel.v1';
import type {SubjectModelV2} from 'resources/augmented/Subject/SubjectModel.v2';

import {buildStores} from 'client/InteractionEngineV2/InteractionEngine.util';

/* TODO:
 * Pagination is should be generic enough to live in the QuestionsListStore, since all question sets
 * responses come back paginated. That also means the QuestionsListPaginator should move to the QuestionsList
 * component.
 */

const storeBuilderStrategies = Map({
  TEACHER_MODE: (questionSets) => {
    return buildStores(questionSets, 'TEACHER_MODE', true);
  },
  NON_TEACHER_MODE: (questionSets) => {
    return buildStores(questionSets, '', true);
  }
});

class VanillaIEQuestionsListStore extends QuestionsListStore {
  constructor(name: string) {
    super(name);
    this.initialData = this.initialData.merge(
      fromJS({
        ieQuery: {
          filters: null,
          sort: null,
          pageSize: 25
        },
        currentPage: null,
        pagesToFetch: [], // Converted to Immutable.Set
        /**
         * The single set mode boolean is used to short-circuit what query is made to the API.
         * Basically, if we can't fulfill the user's request for a question on a specific page, or
         * they are requesting just a question... we just fetch that question.
         * @type {Boolean}
         */
        singleSetMode: false,
        /**
         * I need to write the stats to the store because those things are coming back Mandark on a page by page basis.
         * We invalidate the current page resource when a guess is placed so that things like stats and guess history
         * update. Given we're using the meta of the current page to get stats, if I place a guess on page 2, then go
         * to page 1, my stats would come back from page 2, and therefore be out of date.
         */
        cachedStats: {
          questionsAnswered: null,
          totalQuestions: null,
          correctLastAttemptCount: null
        },
        shouldChangeStoreBuilderStrategy: false,
        storeBuilderStrategy: 'NON_TEACHER_MODE'
      })
    );
    this.setInitialData(this.initialData);
    this.handle(interactionEngineActions.RESET_STORE, () => {
      this._clearQuestionSetsCache();
      this.resetStore();
    });
    this.handle(
      activeQuestionActions.ACTIVE_QUESTION_SUBMIT_ANSWER,
      this._invalidateQuestionSetsQuery
    );
    this.handle(questionsListSettingsActions.RESET_FILTERS, this._invalidateQuestionSetsQuery);
    this.handle(questionsListSettingsActions.TOGGLE_DIFFICULTY, this._invalidateQuestionSetsQuery);
    this.handle(
      questionsListSettingsActions.TOGGLE_INCLUDE_ANSWER_STATUS,
      this._invalidateQuestionSetsQuery
    );
    this.handle(questionsListSettingsActions.SET_SORT_BY, this._ensureActiveQuestionSetIsInList);
    this.handle(vanillaIEQuestionsListActions.SET_PAGE_NUMBER, this._setPageNumber);
    this.handle(vanillaIEQuestionsListActions.ADD_PAGE_TO_FETCH, this._addPageToFetch);
    this.handle(vanillaIEQuestionsListActions.SINGLE_SET_MODE, this._setSingleSetMode);
    this.handle(vanillaIEQuestionsListActions.UPDATE_ROUTE, this._updateRoute);
    this.handle(questionsListActions.SET_ACTIVE_QUESTION_SET, (activeQuestionSet) => {
      this._setActiveQuestionSet(activeQuestionSet);
      this._setCurrentPageBasedOnCurrentActiveQuestion();
    });
    this.handle(interactionEngineActions.SET_TEACHER_MODE, () => {
      this._setStoreBuilderStrategy();
      this._setShouldChangeStoreBuilderStrategy();
    });
    this.handle(
      vanillaIEQuestionsListActions.QUESTION_SET_STORES_BUILT,
      this._toggleShouldChangeStoreBuilderStrategy
    );
    this.writeData(this.initialData);
    this.cache = memoize();
  }

  _updateRoute({
    replace
  }: {
    replace: boolean
  } = {}) {
    const {activeQuestion, activeQuestionSet} = this;
    const questionSetIndex = this.getIndexForQuestionSet(activeQuestionSet);
    const newRoute = convertSetAndQuestionToPath(
      activeQuestionSet,
      activeQuestion,
      questionSetIndex
    );
    const method = replace ? 'replaceState' : 'pushState';
    // We put this on the event loop to avoid the dispatch in a dispatch error
    setTimeout(() => {
      history[method](null, newRoute);
    }, 0);
  }

  _clearQuestionSetsCache() {
    this.cache = memoize();
  }

  _setStoreBuilderStrategy() {
    const isTeacherMode = interactionEngineStore.isTeacherModeEnabled();
    const strategy = isTeacherMode ? 'TEACHER_MODE' : 'NON_TEACHER_MODE';
    this.writeData('storeBuilderStrategy', strategy);
  }

  _setShouldChangeStoreBuilderStrategy() {
    this.writeData('shouldChangeStoreBuilderStrategy', true);
  }

  _toggleShouldChangeStoreBuilderStrategy() {
    const strategyBoolean = this.readData('shouldChangeStoreBuilderStrategy');
    this.writeData('shouldChangeStoreBuilderStrategy', !strategyBoolean);
  }

  getStoreBuilderStrategy(): Function {
    const strategyName = this.readData('storeBuilderStrategy');
    return storeBuilderStrategies.get(strategyName);
  }

  shouldChangeStoreBuilderStrategy(): boolean {
    return this.readData('shouldChangeStoreBuilderStrategy');
  }

  _invalidateQuestionSetsQuery() {
    const activeQuestionId = this.activeQuestion.getId();
    const currentQuestionStoreName = interactionEngineStore.getStoreNameForQuestionId(
      activeQuestionId
    );
    const guessQuery = makeGuessQuery({
      questionId: activeQuestionId,
      userId: sessionStore.getUserId()
    });
    const questionStoreResponsePromise = getStoreByName(currentQuestionStoreName).responsePromise;
    if (!questionStoreResponsePromise) {
      // If there's no question store which is now a valid state because users can set filters that
      // return 0 questions, don't try to invalidate anything.
      return;
    }
    questionStoreResponsePromise.then(() => {
      invalidateInterest(guessQuery);
      invalidateInterest(this.makeQuestionSetsQuery());
      getResourcePromise(this.makeQuestionSetsQuery()).then(() => {
        this._cacheStats();
        // After our guess has submitted successfully, we wanna clear our cached sorted question sets
        // so the included guess history updates properly
        this._clearQuestionSetsCache();
      });
    });
  }

  _cacheStats() {
    this.writeData((store) => {
      return store.set('cachedStats', this.initialData.get('cachedStats')).set(
        'cachedStats',
        new Map({
          questionsAnswered: this.getQuestionSetsQueryMetadata().getIn([
            'request',
            'count_of_questions_answered'
          ]),
          totalQuestions: this.getQuestionSetsQueryMetadata().getIn([
            'request',
            'count_of_questions'
          ]),
          correctLastAttemptCount: this.getQuestionSetsQueryMetadata().getIn([
            'request',
            'count_of_latest_attempts_correct'
          ])
        })
      );
    });
  }

  _getCachedStats(): Map<string, *> {
    return this.readData('cachedStats');
  }

  _ensureActiveQuestionSetIsInList() {
    getResourcePromise(this.makeQuestionSetsQuery()).then(() => {
      if (!this.questionSets.includes(this.activeQuestionSet)) {
        this._setActiveQuestion(this.firstSetAndQuestion.get('question'));
        this._setActiveQuestionSet(this.firstSetAndQuestion.get('questionSet'));
        this._setCurrentPageBasedOnCurrentActiveQuestion();
      }
    });
  }

  _setSingleSetMode(value: boolean) {
    this.writeData('singleSetMode', value);
  }

  _setPageNumber(pageNum: number) {
    this._addPageToFetch(pageNum);
    this._setCurrentPage(pageNum);
  }

  _addPageToFetch(pageNum: number | string) {
    const pagesToFetch = this.getPagesToFetch()
      .add(parseInt(pageNum, 10))
      .sort();
    this.writeData('pagesToFetch', pagesToFetch);
  }

  _setCurrentPage(pageNum: number) {
    this.writeData('currentPage', pageNum);
  }

  _setCurrentPageBasedOnCurrentActiveQuestion() {
    if (!this.activeQuestionSet) {
      return;
    }
    const pageSize = this.readData('ieQuery').get('pageSize');
    const currentPosition = this.questionSets.indexOf(this.activeQuestionSet) + 1;
    const currentPage = Math.ceil(currentPosition / pageSize);
    const pageToFetchOffset = this.getPagesToFetch().first() - 1;
    const currentPageWithOffset = currentPage + pageToFetchOffset;
    if (this.currentPage !== currentPageWithOffset) {
      this._setCurrentPage(currentPageWithOffset);
    }
  }

  isSingleSetMode(): boolean {
    return this.readData('singleSetMode');
  }

  getPagesToFetch(): Set<number> {
    return this.readData('pagesToFetch').toSet();
  }

  hasPagesLeftToFetch(): boolean {
    return this.getPagesToFetch().max() !== this.totalPages;
  }

  get questionSets(): List<QuestionSetModelV1> {
    const sets = List();
    if (this.isSingleSetMode() === true) {
      const queryObject = {
        questionSetSlug: `${appStore.routerProps.params.subject}@${appStore.routerProps.params.set}`
      };
      const userId = sessionStore.getUserId();
      if (sessionStore.hasTeacherAccess()) {
        queryObject.teacherId = userId;
      } else {
        queryObject.studentId = userId;
      }

      const query = makeQuestionSetBySlugQuery(queryObject);
      const set = getResource(query);
      return sets.concat(set);
    }

    const sortBy = questionsListSettingsStore.getSortBy();
    let comparator = null;

    if (sortBy === FILTER_OPTIONS.SORT.ASCENDING) {
      comparator = (set) => set.get('difficulty');
    } else if (sortBy === FILTER_OPTIONS.SORT.DESCENDING) {
      comparator = (set) => set.get('difficulty') * -1;
    }

    return this.getPagesToFetch().reduce((reduction, pageNum) => {
      const query = this.makeQuestionSetsQuery(pageNum);

      if (!isResourcePopulated(query)) {
        return reduction;
      }

      let questionSets;

      if (isSearchMode()) {
        /**
         * Advanced Search
         * In search mode, we derive the question sets from the
         * top-level resource (search_questions_v1) as an include.
         */
        const processedQuestionSets = {};
        questionSets = getResource(query).reduce((acc, question) => {
          const currentSet = question.getQuestionSet();
          if (processedQuestionSets[currentSet.getId()]) {
            return acc;
          }
          processedQuestionSets[currentSet.getId()] = true;
          return acc.push(currentSet);
        }, List());
      } else if (comparator && !isResourceReady(query)) {
        questionSets = getResource(query).sortBy(comparator);
      } else if (comparator) {
        questionSets = this.cache(buildResourceKey(query), () =>
          getResource(query).sortBy(comparator)
        );
      } else {
        questionSets = getResource(query);
      }
      return reduction.concat(questionSets);
    }, sets);
  }

  getQuestionSetByURLSlug(): QuestionSetModelV1 | null {
    return this.questionSets.find(
      (set) => appStore.routerProps.params.set === set.getURLSlugPart(),
      this,
      null
    );
  }

  areQuestionSetsBeingFetched(): boolean {
    return this.getPagesToFetch().some((pageNum) => {
      const query = this.makeQuestionSetsQuery(pageNum);
      return isResourcePending(query);
    });
  }

  async getSearchQuestions(): List {
    const params = Object.values(getSearchParams()).join('.');
    const cacheKey = `${appStore.routerProps.location.pathname}.${params}`;
    return this.cache(cacheKey, async () => {
      let questions = List();
      let pageNum = 1;
      const totalPages = this.totalPages;
      while (pageNum <= totalPages) {
        const query = this.makeQuestionSetsQuery(pageNum);
        const results = await getResourcePromise(query);
        questions = questions.concat(results);
        pageNum++;
      }
      return questions;
    });
  }

  /**
   * Given that the subject is included in the question sets response and included in every
   * question set, we can get the current subject by taking it from the first question set.
   */
  getCurrentSubject(): ?SubjectModelV2 {
    const firstQuestion = this.questionSets.first();
    return firstQuestion ? firstQuestion.getSubject() : undefined;
  }

  getCurrentSubjectId(): string {
    const subject = this.getCurrentSubject();
    return subject ? subject.getId() : '';
  }

  get currentSubjectName(): string {
    const firstQuestion = this.questionSets.first();
    return firstQuestion ? firstQuestion.getSubject().getName() : '';
  }

  get hasReceivedQuestionSets(): boolean {
    return isResourcePopulated(this.makeQuestionSetsQuery());
  }

  isValidQuery(): boolean {
    return isResourceValid(this.makeQuestionSetsQuery());
  }

  getPageSize(): number {
    return this.readData('ieQuery').get('pageSize');
  }

  get currentPage(): number {
    return this.readData('currentPage');
  }

  getLowestPageFetched(): number {
    return this.getPagesToFetch().min();
  }

  get highestPageFetched(): number {
    return this.getPagesToFetch().max();
  }

  get totalPages(): number {
    /**
     * On page change, we don't want to try to read this from the metadata of a question sets query that has yet to resolve,
     * so we'll try to read this from a query we can be sure has resolved already
     */
    const firstPageFetched = this.getPagesToFetch().first();
    return this.getQuestionSetsQueryMetadata(firstPageFetched).getIn(['page', 'total_pages'], 0);
  }

  get questionsAnswered(): number {
    if (this._getCachedStats().get('questionsAnswered') !== null) {
      return this._getCachedStats().get('questionsAnswered');
    } else {
      return this.getQuestionSetsQueryMetadata().getIn(
        ['request', 'count_of_questions_answered'],
        0
      );
    }
  }

  get totalQuestions(): number {
    if (this._getCachedStats().get('totalQuestions') !== null) {
      return this._getCachedStats().get('totalQuestions');
    } else {
      return this.getQuestionSetsQueryMetadata().getIn(['request', 'count_of_questions'], 0);
    }
  }

  get correctLastAttemptCount(): number {
    if (this._getCachedStats().get('correctLastAttemptCount') !== null) {
      return this._getCachedStats().get('correctLastAttemptCount');
    } else {
      return this.getQuestionSetsQueryMetadata().getIn(
        ['request', 'count_of_latest_attempts_correct'],
        0
      );
    }
  }

  get accuracy(): number {
    return stats.percentage(this.correctLastAttemptCount, this.questionsAnswered);
  }

  getQuestionSetsQueryMetadata(pageNumber: ?number): Map<string, Map<string, number>> {
    pageNumber = pageNumber ? pageNumber : this.currentPage;
    return getResourceMetadata(this.makeQuestionSetsQuery(pageNumber));
  }

  get activeQuestion(): QuestionType {
    const activeQuestionId = this.readData(['activeQuestion', 'id']);
    if (this.hasReceivedQuestionSets && activeQuestionId) {
      const foundQuestion = this.activeQuestionSet
        .getQuestions()
        .find((question) => question.getId() === activeQuestionId);
      return foundQuestion ? foundQuestion : this.readData('activeQuestion');
    }

    return this.readData('activeQuestion');
  }

  get activeQuestionSet(): QuestionSetModelV1 {
    const activeSetId = this.readData(['activeQuestionSet', 'id']);
    if (this.hasReceivedQuestionSets && activeSetId) {
      const foundSet = this.questionSets.find((set) => set.getId() === activeSetId);
      return foundSet ? foundSet : this.readData('activeQuestionSet');
    }

    return this.readData('activeQuestionSet');
  }

  // TODO: make this handle multiple pages or infinite page size
  makeFirstNQuestionSetsQuery(numQuestionSets: number): Object {
    return this.makeQuestionSetsQuery(1, numQuestionSets);
  }

  makeQuestionSetsQuery(pageNum: number = this.currentPage, pageSize: ?number): Object {
    const queryData = this.readData('ieQuery');
    pageSize = pageSize ? pageSize : queryData.get('pageSize');

    let query: Object = {
      guideLevelSlug: appStore.routerProps.params.guideLevelSlug,
      page: pageNum || 1,
      pageSize: pageSize,
      subjectSlug: appStore.routerProps.params.subject
    };

    /**
     * If a user is a teacher, pass the 'teacher_id' metadata field to get access to rubric.
     * Otherwise, we want to pass the 'student_id' metadata field to Mandark
     * in order to receive question set metadata such as previous attempts
     */
    if (sessionStore.hasValidSession()) {
      if (sessionStore.hasTeacherAccess()) {
        /**
         *  XXX: Technical debt alert
         *
         *  Special case of being a teacher user:
         *
         *  If the teacher toggle is ON, then we supply a teacher ID,
         *  if the teacher toggle is OFF, then we supply a student ID
         *
         *  This is due to how our metadata is currently being generated,
         *  we do not get guess history when the teacher ID is supplied to the
         *  question list.
         *
         *  Once the meta generator is fixed so a teacher id spits out both sets of
         *  meta, this can go back to normal.
         */
        if (interactionEngineStore.isTeacherModeEnabled()) {
          query.teacherId = sessionStore.getUserId();
        } else {
          query.studentId = sessionStore.getUserId();
        }
      } else {
        query.studentId = sessionStore.getUserId();
      }
    }

    if (isSearchMode()) {
      const searchParams = getSearchParams();

      // @todo: Munge filters from QuestionsListSettings dropdown to search query via questionsListSettingsStore.addFiltersToQuery(query)
      return getSearchQuestionsForPracticeView({...query, ...searchParams}).done();
    }

    query = questionsListSettingsStore.addFiltersToQuery(query);
    return makeQuestionSetsByGuideLevelSlugQuery(query);
  }

  getIndexForQuestionSet(questionSet): number {
    return this.questionSets.indexOf(questionSet);
  }
}

export default new VanillaIEQuestionsListStore('VanillaIEQuestionsListStore');
