import type {Record} from 'immutable';
import React from 'react';
import classnames from 'classnames';
import {callTargetedAction} from 'client/framework';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import CorrectnessIndicatorWrapper from 'client/QuestionTypes/common/CorrectnessIndicatorWrapper/CorrectnessIndicatorWrapper.react';

import multipleChoiceQuestionActions from './MultipleChoiceQuestion.actions';

interface Props {
  index: number;
  isCorrectAnswer: boolean;
  isEliminated: boolean;
  isGuessSubmitted: boolean;
  isSelected: boolean;
  isSelectMultiple: boolean;
  option: Record<string, any>;
  targetStore: string;
  // translation props
  translatedOptionText?: string;
  showTranslation?: boolean;
}

export default function MultipleChoiceOption({
  index,
  isCorrectAnswer,
  isEliminated,
  isGuessSubmitted,
  isSelected,
  isSelectMultiple,
  option,
  targetStore,
  showTranslation = false,
  translatedOptionText
}: Props) {
  const optionId = option.getId();
  const wrapperClass = classnames('mcq-option', {
    'mcq-option--selected': isSelected,
    'mcq-option--unselected': !isSelected,
    'mcq-option--pre-submission': !isGuessSubmitted,
    'mcq-option--post-submission': isGuessSubmitted,
    'mcq-option--eliminated': isEliminated,
    'mcq-option--checkbox': isSelectMultiple
  });

  function handleClick() {
    if (isGuessSubmitted || isEliminated) {
      return;
    }

    callTargetedAction({
      name: multipleChoiceQuestionActions.SELECT_OPTION,
      payload: option.getId(),
      targetStore
    });
  }

  return (
    <div className='mcq-option-accessible-wrapper'>
      <input
        className='mcq-option__hidden-input'
        type={isSelectMultiple ? 'checkbox' : 'radio'}
        onChange={handleClick}
        checked={isSelected}
        id={`input-${optionId}`}
      />
      <CorrectnessIndicatorWrapper
        as='label'
        htmlFor={`input-${optionId}`}
        className={wrapperClass}
        isGuessSubmitted={isGuessSubmitted}
        isCorrectAnswer={isCorrectAnswer}
        isSelected={isSelected}
      >
        <div
          className={classnames('mcq-option__letter-wrapper', {
            'mcq-option__letter-wrapper--checkbox': isSelectMultiple
          })}
        >
          <span className='mcq-option__letter'>
            {String.fromCharCode('A'.charCodeAt(0) + index)}
          </span>
        </div>
        <MarkdownRendererV2
          className='mcq-option__content'
          text={showTranslation ? translatedOptionText : option.getValue()}
        />
      </CorrectnessIndicatorWrapper>
    </div>
  );
}
