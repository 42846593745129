import React from 'react';

import PropTypes from 'prop-types';
import {Icon} from '@albert-io/atomic';
import {getOrigin, getMandarkURL} from '@albert-io/environment';

import SocialButton from '../SocialButton.react';

const GoogleButton = ({disabled = false}) => (
  <SocialButton
    provider='google'
    href={getMandarkURL(`/auth/google?redirect_host=${getOrigin()}`)}
    disabled={disabled}
  >
    <Icon icon={['fab', 'google']} />
    &nbsp; Log in with Google
  </SocialButton>
);

GoogleButton.propTypes = {
  disabled: PropTypes.bool
};

export default GoogleButton;
