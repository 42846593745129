import React, {useState, Suspense, lazy, useCallback} from 'react';
import {Button, Dialogue, Icon, LoadingSpinner, Modal} from '@albert-io/atomic';
import {callAction} from 'client/framework';

import SupplementManagerModalButton from 'client/Supplements/SupplementManager/SupplementManagerModalButton.react';

import {useShallow} from 'zustand/react/shallow';

import questionEditorActions from '../QuestionEditor/QuestionEditor.actions';
import questionEditorStore from '../QuestionEditor/QuestionEditor.store';
import AuthoringTextInput from '../AuthoringTextInput/AuthoringTextInput.react';
import {useQuestionEditorV2Store} from '../QuestionEditorV2Store';
import {PRIMARY_CONTENT_LANGUAGE} from '../QuestionEditorV2Store.types';

const MarkdownCheatSheet = lazy(
  () => import('generic/MarkdownCheatSheet/MarkdownCheatSheet.react')
);

const PromptSection = () => {
  const {currentLanguage, translatedPrompt, updateTranslatedQuestionField} =
    useQuestionEditorV2Store(
      useShallow((state) => ({
        currentLanguage: state.currentLanguage,
        updateTranslatedQuestionField: state.updateTranslatedQuestionField,
        translatedPrompt:
          state
            .currentTranslatedQuestion()
            ?.translated_fields?.find((field) => field.field === 'prompt')?.text || ''
      }))
    );

  const question = questionEditorStore.getQuestion();

  const handlePromptChange = useCallback(
    (e) => {
      const val = e.target.value;
      if (currentLanguage === PRIMARY_CONTENT_LANGUAGE) {
        callAction(questionEditorActions.UPDATE_QUESTION_DETAILS, {
          setter: 'setPrompt',
          value: val
        });
        callAction(questionEditorActions.VALIDATE_QUESTION_FIELD, 'prompt');
      } else {
        updateTranslatedQuestionField(currentLanguage, 'prompt', 'text', val);
      }
    },
    [currentLanguage, updateTranslatedQuestionField]
  );

  const handleBlur = (e) => {
    if (currentLanguage === PRIMARY_CONTENT_LANGUAGE) {
      const {selectionStart, selectionEnd} = e.target;
      callAction(questionEditorActions.SAVE_CURSOR_POSITION, {
        end: selectionEnd,
        name: 'prompt',
        start: selectionStart
      });
    }
  };

  const value =
    currentLanguage === PRIMARY_CONTENT_LANGUAGE ? question.get('prompt') : translatedPrompt;
  const errors = questionEditorStore.getValidationErrors();
  const error = errors.get('prompt');

  return (
    <>
      <AuthoringTextInput
        data-field-path='prompt'
        value={value}
        error={currentLanguage === PRIMARY_CONTENT_LANGUAGE ? error : null}
        onChange={handlePromptChange}
        onBlur={handleBlur}
        className='question-editor__question-detail--fullwidth question-editor__prompt'
      />
      <div className='u-display_flex'>
        <ModalRows />
        <SupplementManagerModalButton
          disabled={currentLanguage !== PRIMARY_CONTENT_LANGUAGE}
          className='question-editor__add-supplement-btn'
          pathToProperty='prompt'
        />
      </div>
    </>
  );
};

export default PromptSection;

const ModalRows = () => {
  const [showMarkdownModal, toggleMarkdownModal] = useState(false);
  return (
    <div className='question-editor__cheat-sheet-wrapper'>
      <Button variant='text' onClick={() => toggleMarkdownModal((t) => !t)}>
        Markdown cheat sheet
      </Button>
      {showMarkdownModal && (
        <Modal
          longModal
          ariaLabel='Markdown cheat sheet'
          handleClose={() => toggleMarkdownModal(false)}
        >
          {({modalContentStyle}) => {
            return (
              <Dialogue
                handleClose={() => toggleMarkdownModal(false)}
                className={modalContentStyle}
                size='xl'
              >
                <Dialogue.Body>
                  <Suspense fallback={<LoadingSpinner />}>
                    <MarkdownCheatSheet />
                  </Suspense>
                </Dialogue.Body>
              </Dialogue>
            );
          }}
        </Modal>
      )}
      <Button
        className='u-mar-l_1'
        as='a'
        href='https://docs.google.com/document/d/1EQEOtnk4laR_dMrAIjGcRRAC3XiUPTW4sKc6_kJk20E/edit#heading=h.n9hcmim2zhbz'
        target='_blank'
        variant='text'
      >
        LaTeX cheat sheet
        <Icon className='u-mar-l_1' icon='external-link-alt' />
      </Button>
      <Button
        className='u-mar-l_1'
        as='a'
        href='https://sites.google.com/albert.io/authorwiki/'
        target='_blank'
        variant='text'
      >
        Author Wiki <Icon className='u-mar-l_1' icon='external-link-alt' />
      </Button>
    </div>
  );
};
