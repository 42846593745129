import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import appStore from 'client/AppStore';
import './correctness-indicator-wapper.scss';

export default class CorrectnessIndicatorWrapper extends React.Component {
  static propTypes = {
    isGuessSubmitted: PropTypes.bool,
    isCorrectAnswer: PropTypes.bool,
    isSelected: PropTypes.bool,
    iconOnly: PropTypes.bool,
    className: PropTypes.string,
    passthroughProps: PropTypes.object,
    size: PropTypes.number,
    as: PropTypes.string,
    children: PropTypes.node
  };

  static defaultProps = {
    isGuessSubmitted: false,
    size: 28,
    as: 'div'
  };

  getOffset() {
    return appStore.isMobileView() ? 10 : 20;
  }

  getIndicatorStyles() {
    const size = `${this.props.size}px`;
    const styles = {
      height: size,
      width: size,
      fontSize: `${0.6 * this.props.size}px`
    };
    if (this.props.children) {
      styles.right = `${this.getOffset()}px`;
    }
    return styles;
  }

  getWrapperStyles() {
    if (!this.props.children) {
      return null;
    }
    return {
      paddingRight: `${this.getOffset() + this.props.size}px`
    };
  }

  generateIndicator({isGuessSubmitted, isCorrectAnswer, isSelected}) {
    if (!isGuessSubmitted || (!isSelected && !isCorrectAnswer)) {
      return null;
    }
    return (
      <div
        className={classnames('correctness-indicator-wrapper__indicator fa', {
          'correctness-indicator-wrapper__indicator--icon-only': this.props.iconOnly,
          'fa-check correctness-indicator-wrapper__indicator--correct': isCorrectAnswer,
          'fa-times correctness-indicator-wrapper__indicator--incorrect': !isCorrectAnswer
        })}
        style={this.getIndicatorStyles()}
      />
    );
  }

  render() {
    const {
      as: Wrapper,
      isGuessSubmitted,
      isCorrectAnswer,
      isSelected,
      className,
      children,
      // eslint-disable-next-line no-unused-vars
      iconOnly,
      ...passthroughProps
    } = this.props;
    return (
      <Wrapper
        {...passthroughProps}
        className={classnames('correctness-indicator-wrapper', className, {
          'correctness-indicator-wrapper--with-children': children
        })}
        style={this.getWrapperStyles()}
      >
        {children}
        {this.generateIndicator({isGuessSubmitted, isCorrectAnswer, isSelected})}
      </Wrapper>
    );
  }
}
