import React from 'react';
import PropTypes from 'prop-types';
import Button from 'sg/Button/Button.react';

const validateBreadcrumbProps = (props, propName, componentName) => {
  if (!props.onClick && !props.to) {
    return new Error(`One of 'onClick' or 'to' is required by '${componentName}'.`);
  }
};

export const Breadcrumb = ({children, ...passthroughProps}) => (
  <Button {...passthroughProps} className='hero-unit__breadcrumb-item-link' linkButton={false}>
    {children}
  </Button>
);

Breadcrumb.propTypes = {
  to: validateBreadcrumbProps,
  onClick: validateBreadcrumbProps
};
