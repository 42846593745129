export const PRIMARY_CONTENT_LANGUAGE = 'en' as const;
export const ALTERNATE_CONTENT_LANGUAGES = ['es'] as const;
export const SUPPORTED_CONTENT_LANGUAGES = [
  PRIMARY_CONTENT_LANGUAGE,
  ...ALTERNATE_CONTENT_LANGUAGES
] as const;

export type PrimaryContentLanguage = typeof PRIMARY_CONTENT_LANGUAGE;
export type AlternateContentLanguage = (typeof ALTERNATE_CONTENT_LANGUAGES)[number];
export type SupportedContentLanguage = (typeof SUPPORTED_CONTENT_LANGUAGES)[number];

export interface TranslatedQuestion {
  id: string;
  language: AlternateContentLanguage;
  meets_translation_requirements: boolean;
  required_fields: string[];
  question_type: string;
  translate: boolean;
  translate_supplements: boolean;

  title: string;
  prompt: string;
  solution_text: string;
  dropdown_text: string;
  dropdowns: any[];
  inputs: any[];
  sample: string;
  options: any[];
  uncorrected_text: string;
  snippet_prompt: string;
  highlight_prompt: string;
  header_left: string;
  header_right: string;
  rows: any[];
  translated_fields: TranslatedField[];
}

interface PassageContent {
  text: string;
  hideLineNumbers: boolean;
  excludeWhiteSpace: boolean;
  ignoreWhiteSpace: boolean;
  type: 'passage';
}

interface SortableTableContent {
  text: string;
  hasTotal: boolean;
  type: 'sortable-table';
}

interface FreeFormContent {
  text: string;
  type: 'free-form';
}

interface ImageContent {
  x?: number;
  y?: number;
  height?: number;
  width?: number;
  imageSize?: number;
  image: string;
  caption?: string;
  alttext?: string;
  type: 'image';
}

interface VideoContent {
  videoId: string;
  type: 'video';
}

interface AudioContent {
  audio: string;
  caption?: string;
  type: 'audio';
}

type SupplementContent =
  | PassageContent
  | SortableTableContent
  | FreeFormContent
  | ImageContent
  | VideoContent
  | AudioContent;

export interface TranslatedSupplement {
  id: string;
  language: AlternateContentLanguage;
  meets_translation_requirements: boolean;
  translate: boolean;
  required_fields: string[];

  name: string;
  type: string;
  content: SupplementContent;
  translated_fields: TranslatedField[];
}

export type TranslatedFieldStatus =
  | 'draft'
  | 'published'
  | 'published_outdated'
  | 'published_needs_review';

export interface TranslatedField {
  id: string;
  field: string;
  text: string;
  notes: string;
  last_modified_by: string | null;
  language: AlternateContentLanguage;
  resource_id: string;
  status: TranslatedFieldStatus;
}

export interface TranslationRequest {
  resource_type: 'question' | 'supplement';
  resource_id: string;
  fields?: string[];
}
