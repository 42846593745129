import React from 'react';
import {consentOptionPropTypes} from 'client/Onboarding/Steps/ParentalConsent/SelectConsentOption/SelectConsentOption.react';
import {Button, Heading, Grid, Text, TextField} from '@albert-io/atomic';
import notifier from '@albert-io/notifier';
import {genericMandarkRequest} from 'resources/mandark.resource';
import {completeParentalConsent} from 'lib/OnboardingUtil/OnboardingUtil';
import 'client/Onboarding/Steps/ParentalConsent/SelectConsentOption/select-consent-option-shared.scss';

export default class EnrollCode extends React.Component {
  static propTypes = consentOptionPropTypes;

  state = {
    enrollCode: '',
    error: null,
    apiError: null
  };

  submitCode = async () => {
    await this.validateEnrollCode();

    if (this.state.error) {
      return;
    }

    const relationship = {
      type: 'classroom_v1',
      meta: {
        classroom_code: this.state.enrollCode.trim()
      }
    };
    try {
      await genericMandarkRequest(
        'post',
        {resourcePath: ['students_v1', this.props.user.getId(), 'relationships', 'classrooms_v1']},
        {
          data: [relationship]
        }
      );

      try {
        await completeParentalConsent(this.props.user).save();
        this.props.onComplete();
      } catch (e) {
        notifier.notify(e, {
          component: 'EnrollCode',
          name: 'Onboarding: failed to complete parental consent step',
          userId: this.props.user.getId()
        });
      }
    } catch (e) {
      if (e.status === 400) {
        this.setState({
          apiError: 'There was a problem with your enrollment code, try again!'
        });
      } else {
        notifier.notify(e, {
          component: 'EnrollCode',
          name: 'Onboarding: failed to create classroom association',
          userId: this.props.user.getId()
        });
      }
    }
  };

  validateEnrollCode = () => {
    return new Promise((resolve) => {
      this.setState(
        (state) => ({
          apiError: null,
          error: !state.enrollCode ? 'A classroom enrollment code is required.' : ''
        }),
        () => {
          resolve();
        }
      );
    });
  };

  setCode = (e) => {
    this.setState({
      enrollCode: e.target.value
    });
  };

  hasError = () => {
    return Boolean(this.state.apiError) || Boolean(this.state.error);
  };

  getError = () => {
    return this.state.apiError || this.state.error;
  };

  render() {
    return (
      <Grid.Container rowGap={2}>
        <Grid.Row justify='center'>
          <Grid.Col all={6} xs={12}>
            <Grid.Container nested>
              <Grid.Row justify='flex-start'>
                <Grid.Col all={2} xs={12}>
                  <Button
                    as='div'
                    className='consent-option-back-button'
                    variant='text'
                    onClick={this.props.clearConsentState}
                  >
                    <span className='fa fa-arrow-left' />
                    Back
                  </Button>
                </Grid.Col>
              </Grid.Row>
            </Grid.Container>
            <Grid.Container nested>
              <Grid.Row justify='center'>
                <Grid.Col className='consent-option-copy' all={6} xs={12}>
                  <Heading as='h2'>Enter an enrollment code</Heading>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row justify='center'>
                <Grid.Col className='consent-option-copy' all={6} xs={12}>
                  <Text as='p'>
                    Join a class by entering an enrollment code below. If you haven&#39;t received
                    an enrollment code, ask your teacher for one.
                  </Text>
                </Grid.Col>
              </Grid.Row>
              <Grid.Row justify='center'>
                <Grid.Col xs={12} all={6}>
                  <TextField
                    className='u-mar-b_2'
                    onChange={this.setCode}
                    onBlur={this.validateEnrollCode}
                    value={this.state.enrollCode}
                    error={this.hasError()}
                    message={this.getError()}
                  />
                </Grid.Col>
              </Grid.Row>
              <Grid.Row justify='center'>
                <Grid.Col xs={12} all={6}>
                  <Button className='consent-option-action-button' onClick={this.submitCode}>
                    Enroll
                  </Button>
                </Grid.Col>
              </Grid.Row>
            </Grid.Container>
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    );
  }
}
