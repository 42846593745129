import React from 'react';
import PropTypes from 'prop-types';
import {Card, Heading, ListGroupItem} from '@albert-io/atomic';
import './empty-question-shell.scss';

const EmptyQuestionShell = ({title, body}) => (
  <Card className='empty-question-shell'>
    <ListGroupItem>
      <Heading as='h1' size='xs' className='u-mar_0'>
        {title}
      </Heading>
    </ListGroupItem>
    <div className='u-pad_4 empty-question-shell__body'>{body}</div>
  </Card>
);

EmptyQuestionShell.propTypes = {
  title: PropTypes.node,
  body: PropTypes.node
};

EmptyQuestionShell.defaultProps = {
  title: <span>&nbsp;</span>,
  body: null
};

export {EmptyQuestionShell};
