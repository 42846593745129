import {fromJS} from 'immutable';
import QuestionTypeStore from 'client/QuestionTypes/common/V2/QuestionType.store';
import {activeQuestionActions} from 'client/InteractionEngineV2/shared/ActiveQuestion/ActiveQuestionActions';

export default class FreeResponseQuestionStore extends QuestionTypeStore {
  constructor(name, questionId, question) {
    super(name);
    this.initialData = fromJS({
      questionId: questionId,
      shouldShowExplanation: false
    });
    this.setInitialData(this.initialData);
    this.writeData('__question', question);
    this.handleTargeted(activeQuestionActions.ACTIVE_QUESTION_LOAD_GUESS, this._loadGuess);
    this.handleTargeted(activeQuestionActions.ACTIVE_QUESTION_CLEAR_GUESS, this._clearGuess);
  }

  buildGuessContent() {
    return {};
  }

  get correctAnswer() {
    return this.validResponse.keySeq().toSet();
  }

  /**
   * For a "free-respose" question, the user does not select an answer, they simply "Mark as Complete"
   */
  get hasUserSelectedAnswer() {
    return true;
  }
}
