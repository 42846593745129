import qs from 'qs';
import {Map} from 'immutable';

/**
 * @param {Map<string, any>} cache Initial cache
 * @returns {any} Memoized value
 */
export function memoize(cache = Map()) {
  // func is the function that establishes the value for this key if one is
  // not already present
  return (key, func) => {
    if (cache.has(key)) {
      return cache.get(key);
    }

    const val = func();
    cache = cache.set(key, val);
    return val;
  };
}

/**
 * @param {object} query Query to build string from
 * @returns {string} Stringified query
 */
export function buildResourceKey(query) {
  return qs.stringify(query);
}
