import {create} from 'zustand';
import {genericMandarkRequest} from 'resources/mandark.resource';
import {set as lodashSet, chunk, isEqual} from 'lodash';

import {devtools} from 'zustand/middleware';

import {
  TranslatedQuestion,
  TranslatedSupplement,
  TranslationRequest,
  SupportedContentLanguage,
  AlternateContentLanguage,
  PRIMARY_CONTENT_LANGUAGE,
  TranslatedField,
  ALTERNATE_CONTENT_LANGUAGES
} from './QuestionEditorV2Store.types';

export interface DirtyFieldReference {
  language: AlternateContentLanguage;
  field: string;
}

interface QuestionEditorV2State {
  loadingTranslations: boolean;
  generatingTranslations: boolean;
  savingTranslatedFields: boolean;
  currentLanguage: SupportedContentLanguage;
  translatedQuestions: Record<AlternateContentLanguage, TranslatedQuestion | null>;
  translatedSupplements: Record<AlternateContentLanguage, TranslatedSupplement[] | null>;
  dirtyTranslatedFields: DirtyFieldReference[];
  initController: AbortController | null;
}

type UserEditableTranslatedFieldAttributes = 'text' | 'notes' | 'status';

interface QuestionEditorV2Actions {
  init: (questionId: string, supplementIds: string[]) => Promise<void>;
  resetTranslations: () => void;
  generateTranslations: (lang: AlternateContentLanguage, type: 'full' | 'partial') => Promise<void>;
  refreshTranslations: (lang: AlternateContentLanguage) => Promise<void>;
  updateTranslatedQuestionField: (
    language: AlternateContentLanguage,
    questionFieldName: string,
    translatedFieldAttributeName: UserEditableTranslatedFieldAttributes,
    value: string
  ) => void;
  saveTranslatedFields: () => Promise<void>;
  setCurrentLanguage: (lang: SupportedContentLanguage) => void;
}

interface QuestionEditorV2Queries {
  currentTranslatedQuestion: () => TranslatedQuestion | null;
  currentTranslatedSupplements: () => TranslatedSupplement[] | null;
  hasPublishedTranslatedFields: () => boolean;
  translationsNeedReview: (language: AlternateContentLanguage) => boolean;
  hasChanges: () => boolean;
}

// currently this is only used for translations, but the vision is for it to eventually replace
// the legacy question editor store, hence the name
export const useQuestionEditorV2Store = create<
  QuestionEditorV2State & QuestionEditorV2Actions & QuestionEditorV2Queries
>()(
  devtools((set, get) => ({
    loadingTranslations: false,
    translatedQuestions: {es: null},
    translatedSupplements: {es: null},
    generatingTranslations: false,
    savingTranslatedFields: false,
    currentLanguage: PRIMARY_CONTENT_LANGUAGE,
    dirtyTranslatedFields: [],
    initController: null,

    resetTranslations: () => {
      set({
        translatedQuestions: {es: null},
        translatedSupplements: {es: null},
        dirtyTranslatedFields: []
      });
    },

    setCurrentLanguage: (lang) => {
      set({currentLanguage: lang});
    },

    init: async (questionId, supplementIds) => {
      const {initController} = get();
      if (initController) {
        initController.abort();
      }

      const newController = new AbortController();
      set({loadingTranslations: true, initController: newController});

      const translatedQuestions: Record<AlternateContentLanguage, TranslatedQuestion | null> =
        {} as any;

      const translatedSupplements: Record<AlternateContentLanguage, TranslatedSupplement[] | null> =
        {} as any;

      await Promise.all(
        ALTERNATE_CONTENT_LANGUAGES.map(async (language) => {
          const [translatedQuestion, supplements] = await Promise.all([
            fetchTranslatedQuestion(language, questionId, newController.signal),
            fetchTranslatedSupplements(language, supplementIds, newController.signal)
          ]);
          translatedQuestions[language] = translatedQuestion;
          translatedSupplements[language] = supplements;
        })
      );

      set({
        translatedQuestions,
        translatedSupplements,
        loadingTranslations: false,
        initController: null
      });
    },

    generateTranslations: async (language, type) => {
      const {translatedQuestions, translatedSupplements} = get();
      if (!translatedQuestions[language] || !translatedSupplements[language]) {
        return;
      }

      const translationRequests = getTranslationRequests(
        translatedQuestions[language]!,
        translatedSupplements[language]!,
        type
      );

      if (translationRequests.length === 0) {
        return;
      }

      try {
        set({generatingTranslations: true});

        await runAndAwaitTranslationJob(language, translationRequests);

        await get().refreshTranslations(language);
      } finally {
        set({generatingTranslations: false});
      }
    },

    refreshTranslations: async (language) => {
      const {translatedQuestions, translatedSupplements: allTranslatedSupplements} = get();
      if (!translatedQuestions[language] || !allTranslatedSupplements[language]) {
        return;
      }

      const {initController} = get();
      if (initController) {
        initController.abort();
      }

      const newController = new AbortController();

      set({loadingTranslations: true, initController: newController});

      const [translatedQuestion, translatedSupplements] = await Promise.all([
        fetchTranslatedQuestion(language, translatedQuestions[language]!.id, newController.signal),
        fetchTranslatedSupplements(
          language,
          allTranslatedSupplements[language]!.map((s) => s.id),
          newController.signal
        )
      ]);

      set({
        loadingTranslations: false,
        translatedQuestions: {...translatedQuestions, [language]: translatedQuestion},
        translatedSupplements: {...allTranslatedSupplements, [language]: translatedSupplements}
      });
    },

    updateTranslatedQuestionField: (
      language,
      questionFieldName,
      translatedFieldAttributeName,
      value
    ) => {
      const {translatedQuestions} = get();
      if (!translatedQuestions[language]) {
        return;
      }

      const updatedQuestion = {...translatedQuestions[language]!};
      if (translatedFieldAttributeName === 'text') {
        lodashSet(updatedQuestion, questionFieldName, value);
      }

      let modified = false;
      updatedQuestion.translated_fields = updatedQuestion.translated_fields.map((f) => {
        if (f.field === questionFieldName) {
          modified = true;
          return {...f, [translatedFieldAttributeName]: value};
        }
        return f;
      });

      // if the field doesn't exist yet, add it to the translated fields
      if (!modified) {
        updatedQuestion.translated_fields.push({
          language,
          resource_id: updatedQuestion.id,
          field: questionFieldName,
          id: '',
          status: 'draft',
          notes: '',
          text: '',
          last_modified_by: null,
          [translatedFieldAttributeName]: value
        });
      }

      const newDirtyField: DirtyFieldReference = {
        language,
        field: questionFieldName
      };
      const prevDirtyFields = get().dirtyTranslatedFields;
      const alreadyExists = prevDirtyFields.some((f) => isEqual(f, newDirtyField));

      set({
        translatedQuestions: {...translatedQuestions, [language]: updatedQuestion},
        dirtyTranslatedFields: alreadyExists ? prevDirtyFields : [...prevDirtyFields, newDirtyField]
      });
    },

    saveTranslatedFields: async () => {
      const {dirtyTranslatedFields} = get();

      if (dirtyTranslatedFields.length === 0) {
        return;
      }

      set({savingTranslatedFields: true});

      const translatedFieldsToUpsert = dirtyTranslatedFields.map((f) => {
        const {translatedQuestions} = get();
        const question = translatedQuestions[f.language]!;
        return question.translated_fields.find((f2) => f2.field === f.field)!;
      });

      const upsertPromises = translatedFieldsToUpsert.map((f) =>
        upsertTranslatedField('question', {...f, language: f.language})
      );
      await Promise.all(upsertPromises);
      set({
        dirtyTranslatedFields: [],
        savingTranslatedFields: false
      });
    },

    currentTranslatedQuestion: () => {
      const {currentLanguage} = get();
      if (currentLanguage !== PRIMARY_CONTENT_LANGUAGE) {
        return get().translatedQuestions[currentLanguage];
      }
      return null;
    },

    currentTranslatedSupplements: () => {
      const {currentLanguage} = get();
      if (currentLanguage !== PRIMARY_CONTENT_LANGUAGE) {
        return get().translatedSupplements[currentLanguage];
      }
      return null;
    },

    hasPublishedTranslatedFields: () => {
      const translatedQuestion = get().currentTranslatedQuestion();
      const translatedSupplements = get().currentTranslatedSupplements();
      if (!translatedQuestion || !translatedSupplements) {
        return false;
      }
      return (
        translatedQuestion.translated_fields.some((f) => f.status === 'published') ||
        translatedSupplements.some((s) => s.translated_fields.some((f) => f.status === 'published'))
      );
    },

    translationsNeedReview: (language) => {
      const {translatedQuestions, translatedSupplements: allTranslatedSupplements} = get();
      if (!translatedQuestions[language] || !allTranslatedSupplements[language]) {
        return false;
      }

      const translatedQuestion = translatedQuestions[language]!;
      const translatedSupplements = allTranslatedSupplements[language]!;

      let needsReview = false;

      if (translatedQuestion.translate) {
        needsReview ||= translatedQuestion.required_fields.some((field) => {
          const translatedField = translatedQuestion.translated_fields.find(
            (f) => f.field === field
          );
          return !translatedField || translatedField?.status !== 'published';
        });
      }

      if (translatedQuestion.translate_supplements) {
        needsReview ||= translatedSupplements.some((s) => {
          if (!s.translate) {
            return false;
          }

          return s.required_fields.some((field) => {
            const translatedField = s.translated_fields.find((f) => f.field === field);
            return !translatedField || translatedField?.status !== 'published';
          });
        });
      }

      return needsReview;
    },

    hasChanges: () => {
      return get().dirtyTranslatedFields.length > 0;
    }
  }))
);

const getTranslationRequests = (
  translatedQuestion: TranslatedQuestion,
  translatedSupplements: TranslatedSupplement[],
  type: 'full' | 'partial'
): TranslationRequest[] => {
  const translationRequests: TranslationRequest[] = [];
  if (translatedQuestion.translate) {
    translationRequests.push({
      resource_type: 'question' as const,
      resource_id: translatedQuestion.id
    });
  }

  if (translatedQuestion.translate_supplements) {
    translatedSupplements.forEach((s) => {
      if (s.translate) {
        translationRequests.push({
          resource_type: 'supplement' as const,
          resource_id: s.id
        });
      }
    });
  }

  if (type === 'full') {
    return translationRequests;
  }

  return translationRequests
    .map((r) => {
      const resource =
        r.resource_type === 'question'
          ? translatedQuestion
          : translatedSupplements.find((s) => s.id === r.resource_id)!;
      const requiredFields = resource.required_fields;
      const actualFields = resource.translated_fields.filter((f) => f.status !== 'draft');
      return {
        ...r,
        fields: requiredFields.filter((f) => !actualFields.some((af) => af.field === f))
      };
    })
    .filter((r) => r.fields.length > 0);
};

const fetchTranslatedQuestion = async (
  language: AlternateContentLanguage,
  questionId: string,
  signal: AbortSignal
) => {
  const response = await genericMandarkRequest(
    'get',
    {
      resourcePath: ['json', 'translations', 'translated_questions', questionId],
      customQuery: {language, include: 'translated_fields'}
    },
    null,
    {abortSignal: signal}
  );
  return response.toJS();
};

const fetchTranslatedSupplements = async (
  language: AlternateContentLanguage,
  supplementIds: string[],
  signal: AbortSignal
) => {
  const chunkSize = 10;
  const chunks = chunk(supplementIds, chunkSize);

  const translatedSupplements: TranslatedSupplement[] = [];

  for (const supplementChunk of chunks) {
    const requests = supplementChunk.map((id) =>
      genericMandarkRequest(
        'get',
        {
          resourcePath: ['json', 'translations', 'translated_supplements', id],
          customQuery: {language, include: 'translated_fields'}
        },
        null,
        {abortSignal: signal}
      )
    );

    // eslint-disable-next-line no-await-in-loop
    const responses = await Promise.all(requests);
    translatedSupplements.push(...responses.map((response) => response.toJS()));
  }

  return translatedSupplements;
};

// NOTE THIS CAN THROW
export const runAndAwaitTranslationJob = async (
  language: AlternateContentLanguage,
  translationRequests: TranslationRequest[]
) => {
  const response = await genericMandarkRequest(
    'post',
    {
      resourcePath: ['json', 'translations', 'translation_jobs']
    },
    {
      data: {
        type: 'translation_job',
        attributes: {
          language,
          translation_requests: translationRequests
        }
      }
    }
  );

  await pollForJobCompletion(response.toJS());
};

const pollForJobCompletion = async (job: any, attempt: number = 0) => {
  if (job.status === 'complete') {
    return job;
  }

  if (job.status === 'error') {
    throw new Error('Translation job failed');
  }

  const response = await genericMandarkRequest('get', {
    resourcePath: ['json', 'translations', 'translation_jobs', job.id]
  });

  if (response.get('status') === 'complete') {
    return response.toJS();
  }

  // Poll every 2s for the first 5 attempts, then add 500ms for each additional attempt, maxing out at 8s
  const delay = Math.min(attempt < 5 ? 2000 : (attempt - 4) * 500 + 2000, 8000);
  await new Promise((resolve) => setTimeout(resolve, delay));

  return pollForJobCompletion(response.toJS(), attempt + 1);
};

export const upsertTranslatedField = async (
  parentType: 'question' | 'supplement',
  {language, resource_id: resourceId, field, notes, status, text}: TranslatedField
) => {
  const response = await genericMandarkRequest(
    'post',
    {
      resourcePath: ['json', 'translations', `translated_${parentType}_fields`]
    },
    {
      data: {
        type: 'translated_field',
        attributes: {language, resource_id: resourceId, field, notes, status, text}
      }
    }
  );

  return response;
};
