// @flow
import * as React from 'react';

// Facet Components
import {callAction, callTargetedAction, getStoreByName} from 'client/framework';

import subjectSearchDropdownActions from 'sg/Dropdowns/SearchDropdown/SearchDropdown.actions';

import appStore from 'client/AppStore';
import sessionStore from 'client/Session/SessionStore';

import {pushQueryParams} from 'client/history';

import dateRangeActions from 'generic/DateRange/DateRange.actions';

import simpleDropdownActions from 'generic/SimpleDropdown/SimpleDropdown.actions';

import {RANGES, dateRangeOptions} from 'generic/SelectableDateRange/shared';

import {Button} from '@albert-io/atomic';

import {SortBy} from './SortBy/SortBy.react';

import {StatusSelector} from './StatusSelector/StatusSelector.react';
import SubjectSearchDropdown from './SubjectSearchDropdown/SubjectSearchDropdown.react';
import {AuthorDropdown} from './AuthorDropdown/AuthorDropdown.react';
import {TitleSearch} from './TitleSearch/TitleSearch.react';
import GuideLevelDropdown from './GuideLevelDropdown/GuideLevelDropdown.react';
import {QuestionTypeDropdown} from './QuestionTypeDropdown/QuestionTypeDropdown.react';
import {FlagDropdown} from './FlagDropdown/FlagDropdown.react';
import {DifficultySelector} from './DifficultySelector/DifficultySelector.react';
import {DateFilters, selectableDateRangeStoreName} from './DateFilters/DateFilters.react';

import {setURLSearchParameter, SEARCH_PARAMETERS} from './URLSearchParameters';

import titleSearchActions from './TitleSearch/TitleSearch.actions';

import './queue-search-panel.scss';

const subjectSearchDropdownStoreName = 'QueueSubjectSearchDropdown';
const authorDropdownStoreName = 'AuthoringQuestionsAuthorDropdownStore';
export default class QueueSearchPanel extends React.Component<any> {
  handleResetClick = () => {
    this.resetParams();
    this.resetSearchDropdown();
    this.resetQuestionTitleSearch();
    this.resetAuthorDropdown();
    this.resetDatePublished();
  };

  handleSubjectSearchOptionChange = (option) => {
    const subjectId = option ? option.get('id') : '';
    /*
     * Cleans up all subject specific data from URL params and state
     */
    setURLSearchParameter(SEARCH_PARAMETERS.author, '');
    setURLSearchParameter(SEARCH_PARAMETERS.guideLevelIds, '');
    setURLSearchParameter(SEARCH_PARAMETERS.examIds, '');

    setURLSearchParameter(SEARCH_PARAMETERS.subject, subjectId);
    /**
     * Ensure we are clearing any extraneous question set filters when changing subjects
     */
    if (appStore.routerProps.location.query.questionSetId) {
      setURLSearchParameter(SEARCH_PARAMETERS.questionSetId, '');
    }
  };

  resetParams() {
    const resetObj = Object.entries(SEARCH_PARAMETERS).reduce((acc, [param, config]) => {
      acc[param] = config.default ? config.default() : '';
      return acc;
    }, {});
    pushQueryParams(resetObj);
  }

  resetSearchDropdown() {
    callTargetedAction({
      name: subjectSearchDropdownActions.RESET_STORE,
      targetStore: subjectSearchDropdownStoreName
    });
  }

  resetQuestionTitleSearch() {
    callAction(titleSearchActions.RESET_STORE);
  }

  resetAuthorDropdown() {
    callTargetedAction({
      name: simpleDropdownActions.SET_SELECTED_ITEM,
      targetStore: authorDropdownStoreName,
      payload: ''
    });
  }

  resetDatePublished() {
    /**
     * This is a hacky way to reset the SelectableDateRange component. It should have
     * a way to reset itself, but it does not, since its store has no handlers, and all it
     * really does is set up two other stores. Because of this, it's difficult to implement
     * a reset without breaking the unidirectional data flow.
     */
    const store = getStoreByName(selectableDateRangeStoreName);
    if (!store) {
      return;
    }
    const dateRangeStoreName = store.getDateRangeStoreName();
    const simpleDropdownStoreName = store.getSimpleDropdownStoreName();
    callTargetedAction({
      name: dateRangeActions.RESET_STORE,
      targetStore: dateRangeStoreName
    });
    const allTimeOption = dateRangeOptions.find((option) => option.get('name') === RANGES.ALL_TIME);
    callTargetedAction({
      name: simpleDropdownActions.SET_SELECTED_ITEM,
      targetStore: simpleDropdownStoreName,
      payload: allTimeOption
    });
  }

  render(): React.Node {
    const selectedId = appStore.routerProps.location.query.subject;
    return (
      <div className='queue-search-panel'>
        <StatusSelector />
        <div className='queue-search-panel__section'>
          <SubjectSearchDropdown
            showQuestionCount
            defaultSelectedId={selectedId}
            handleOptionChange={this.handleSubjectSearchOptionChange}
            storeName={subjectSearchDropdownStoreName}
            queryParamName='subject'
            authorId={sessionStore.isSuper() ? null : sessionStore.getUserId()}
          />
          {selectedId && (
            <GuideLevelDropdown
              isActiveQuestionInSubject={this.props.isActiveQuestionInSubject}
              questionSetId={this.props.questionSetId}
            />
          )}
          <AuthorDropdown
            openOnFocus
            filterByName
            className='author-dropdown queue-search-panel__facet'
            defaultSelectedId={appStore.routerProps.location.query.author}
            disabled={!appStore.routerProps.location.query.subject}
            displayName='fullName'
            label='Author Name'
            storeName={authorDropdownStoreName}
            subjectId={
              appStore.routerProps.location.query.subject
                ? appStore.routerProps.location.query.subject
                : ''
            }
          />
        </div>
        <div className='queue-search-panel__section queue-search-panel__section--highlight'>
          <TitleSearch />
          <QuestionTypeDropdown />
          {/* we aren't positive we want this gone yet
            <GuideAlignmentSelector />
          */}
          <FlagDropdown />
          <DifficultySelector />
          <DateFilters />
          <SortBy />
        </div>
        <div className='queue-search-panel__section queue-search-panel__section--highlight queue-search-panel__section--actions'>
          <Button onClick={this.handleResetClick}>Reset</Button>
        </div>
      </div>
    );
  }
}
