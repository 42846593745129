import React from 'react';

import {Button} from '@albert-io/atomic';

import clearGuess from './clearGuess';

interface Props {
  handleResetGuess: () => void;
  questionStoreName: string;
}

export default function TryAgain({handleResetGuess, questionStoreName}: Props) {
  return (
    <Button
      type='button'
      onClick={() => {
        global.scrollTo(0, 0);
        handleResetGuess();
        clearGuess(questionStoreName);
      }}
    >
      Try again
    </Button>
  );
}
