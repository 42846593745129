import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import sessionStore from 'client/Session/SessionStore';
import {StudyGuideItem} from 'client/generic/StudyGuideItem/StudyGuideItem.react';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {GuideLevelModelV2} from 'resources/augmented/GuideLevel/GuideLevelModel.v2';
import ActionsDropdown from 'client/generic/StudyGuideItem/Teacher/ActionsDropdown/ActionsDropdown.react';

const FRQGuideLevelItem = ({className, guideLevel, hasAssignAccess}) => {
  const hasTeacherAccess = sessionStore.isTeacher();
  const metadata = guideLevel.getQuestionMetadataForSubjectView(hasTeacherAccess);
  const hasBody = guideLevel.getDescription() !== null;
  const classNames = classnames(className, {
    'sgi--no-body': !hasBody
  });
  /**
   * We add **1** here to account for the `<HeroUnit>` containing a `h1`.
   */
  const headingLevel = guideLevel.getNlevel() + 1;
  return (
    <StudyGuideItem
      aria-label={guideLevel.getName()}
      className={classNames}
      itemType={guideLevel.getGuideLevelType()}
    >
      {guideLevel.isTheme() ? <StudyGuideItem.Img src={guideLevel.getThemeImg()} /> : null}
      <StudyGuideItem.Content>
        <StudyGuideItem.Heading as={`h${headingLevel}`}>
          <MarkdownRendererV2 text={guideLevel.getName()} />
        </StudyGuideItem.Heading>
        {hasBody && (
          <StudyGuideItem.Body>
            <MarkdownRendererV2 text={guideLevel.getDescription()} />
          </StudyGuideItem.Body>
        )}
        {!guideLevel.isTheme() ? (
          <StudyGuideItem.Meta className='frq-item__metadata'>{metadata}</StudyGuideItem.Meta>
        ) : null}
      </StudyGuideItem.Content>
      {hasAssignAccess && (
        <ActionsDropdown
          buttonClassName='study-guide-actions-dropdown__btn unbutton'
          className='study-guide-actions-dropdown'
          guideLevel={guideLevel}
        />
      )}
    </StudyGuideItem>
  );
};

FRQGuideLevelItem.propTypes = {
  className: PropTypes.string,
  guideLevel: PropTypes.instanceOf(GuideLevelModelV2),
  hasAssignAccess: PropTypes.bool
};

export default FRQGuideLevelItem;
