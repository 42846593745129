import React from 'react';

import {Banner} from '@albert-io/atomic';
import sessionStore from 'client/Session/SessionStore';

const studentText = 'Your response has been submitted and will be graded outside of Albert.';
const teacherText = 'Free response questions are automatically graded as correct.';

const CompletenessBanner = () => {
  return (
    <div className='u-mar-b_4'>
      <Banner type='positive'>
        <Banner.Icon icon='check' />
        {sessionStore.isStudent() ? studentText : teacherText}
      </Banner>
    </div>
  );
};

export default CompletenessBanner;
