import {fromJS} from 'immutable';
import {Store} from '../framework';
import {doesUserBelongToAnyLicensedClassrooms} from 'lib/UserAccessUtil';
import globalNavigationActions from './GlobalNavigationActions';

class GlobalNavigationStore extends Store {
  constructor(name) {
    super(name);
    this.initialData = fromJS({
      activeItem: null,
      activePath: '',
      isDrawerActive: false,
      isUserOnboarding: false,
      navType: null,
      showMobileMenu: false,
      showCartButton: false,
      showUpgradeButton: false
    });
    this.setInitialData(this.initialData);
    this.handle(globalNavigationActions.RESET_STORE, this._resetStore);
    this.handle(globalNavigationActions.SET_ACTIVE_ITEM, this._setActiveItem);
    this.handle(globalNavigationActions.SET_IS_USER_ONBOARDING, this._setIsUserOnboarding);
    this.handle(globalNavigationActions.SET_IS_DRAWER_ACTIVE, this._setIsDrawerActive);
    this.handle(
      globalNavigationActions.SET_SHOULD_SHOW_UPGRADE_BUTTON,
      this._setShouldShowUpgradeButton
    );
    this.handle(
      globalNavigationActions.SET_SHOULD_SHOW_UPGRADE_BUTTON_NO_EMIT,
      this._setShouldShowUpgradeButtonNoEmit
    );
  }

  _hideMobileMenu() {
    this.writeData('showMobileMenu', false);
  }

  _resetStore() {
    this.writeData(this.initialData);
  }

  _setActiveItem(navItem) {
    this.writeData('activeItem', navItem);
  }

  _setIsDrawerActive(bool) {
    this.writeData('isDrawerActive', bool);
  }

  _setShouldShowUpgradeButton(bool) {
    this.writeData('showUpgradeButton', bool);
  }

  _setShouldShowUpgradeButtonNoEmit(bool) {
    this.writeDataNoEmit('showUpgradeButton', bool);
  }

  _setIsUserOnboarding(bool) {
    this.writeData('isUserOnboarding', bool);
  }

  getIsDrawerActive() {
    return this.readData('isDrawerActive');
  }

  getActiveItem() {
    return this.readData('activeItem');
  }

  isUserOnboarding() {
    return this.readData('isUserOnboarding');
  }

  shouldShowUpgradeButton() {
    const userLicenseAccess = doesUserBelongToAnyLicensedClassrooms(true);
    return (
      userLicenseAccess.isReady &&
      userLicenseAccess.value === false &&
      this.readData('showUpgradeButton')
    );
  }
}

const globalNavigationStore = new GlobalNavigationStore('GlobalNavigationStore');
export default globalNavigationStore;
