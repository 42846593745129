// @flow

/**
 * Comparator that can be used with an immutable .sort function.
 *
 * comparator sorts strings in descending order
 */
export function alphaComparatorDescending(valueA: string, valueB: string): number {
  return valueB.localeCompare(valueA);
}

/**
 * Comparator that can be used with an immutable .sort function.
 *
 * comparator sorts strings in ascending order
 */
export function alphaComparatorAscending(valueA: string, valueB: string): number {
  return valueA.localeCompare(valueB);
}

/**
 * Comparator that can be used with an immutable .sort function.
 *
 * comparator sorts numbers in descending order
 */
export function numericComparatorDescending(valueA: number, valueB: number): number {
  if (valueA > valueB) {
    return -1;
  }
  if (valueA < valueB) {
    return 1;
  }
  return 0;
}

/**
 * Comparator that can be used with an immutable .sort function.
 *
 * comparator sorts numbers in ascending order
 */
export function numericComparatorAscending(valueA: number, valueB: number): number {
  if (valueA < valueB) {
    return -1;
  }
  if (valueA > valueB) {
    return 1;
  }
  return 0;
}
