import {Map} from 'immutable';
import makeConstants from 'lib/makeConstants';

/**
 * @typedef {import('resources/augmented/Guess/GuessModel.v1')} Guess
 */

/**
 * Map that holds the result of a guess placed on a given question, and its guess resource if one is available
 * @typedef {Map<{
 *   result: string,
 *   guess: ?Guess
 * }>} GuessResult
 */

/**
 * A Map whose keys are question IDs, and its values are a GuessResult
 * @typedef {Map<string, GuessResult>} GuessResultsMap
 */

export const resultValues = makeConstants('correct', 'incorrect', 'indeterminate');

export function makeGuessResultManager(initialData = {}) {
  const guessResultsMap = Map(initialData);
  return {
    addGuessResults(guessesObj) {
      return makeGuessResultManager(guessResultsMap.merge(guessesObj));
    },
    updateGuess(questionId, guess, storeGuess = false) {
      const result = guess.isCorrect() ? resultValues.correct : resultValues.incorrect;
      return makeGuessResultManager(
        guessResultsMap.set(
          questionId,
          Map({
            result,
            guess: storeGuess ? guess : null
          })
        )
      );
    },
    getGuessResult(questionId) {
      return guessResultsMap.has(questionId) ? guessResultsMap.getIn([questionId, 'result']) : null;
    },
    getGuess(questionId) {
      if (!guessResultsMap.has(questionId)) {
        return null;
      }
      const guess = guessResultsMap.getIn([questionId, 'guess']);
      if (!guess) {
        throw Error(
          `Attempted to get guess for question ${questionId}, which has a guess entry, but no guess result. Please ensure you've bootstrapped the guesses and passing true as the storeGuess param in the updateGuess method.`
        );
      }
      return guess;
    },
    getGuessResultsMap() {
      return guessResultsMap;
    }
  };
}

/**
 * @param {Immutable.List<*>} questionSets - Question sets which include questions and
 *   the questions include `isUserAnsweredCorrect` meta
 * @returns {GuessResultsMap}
 */
export function getGuessesFromQuestionSets(questionSets) {
  return questionSets.reduce((acc, set) => {
    const guesses = set.getQuestions().reduce((questionSetGuesses, question) => {
      const meta = question.getMeta();
      if (meta.isUserAnswered()) {
        return questionSetGuesses.set(
          question.getId(),
          Map({
            result: meta.isUserAnsweredCorrect() ? resultValues.correct : resultValues.incorrect,
            guess: null
          })
        );
      }
      return questionSetGuesses;
    }, Map());

    return acc.merge(guesses);
  }, Map());
}

/**
 * @returns {GuessResultsMap}
 */
export function getGuessesFromAssignment(assignment) {
  return assignment.getGuesses().reduce((acc, guess) => {
    const questionId = guess.getQuestion().getId();
    const result = guess.isCorrect() ? resultValues.correct : resultValues.incorrect;
    return acc.set(
      questionId,
      Map({
        result,
        guess
      })
    );
  }, Map());
}
