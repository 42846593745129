import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Text, TextField} from '@albert-io/atomic';

const Axis = ({direction, label, min, max, interval, onChange, limit = 10}) => (
  <div className='u-mar-b_6'>
    <Text className='u-mar-b_2' size='l' as='p' bold>
      {direction} axis
    </Text>
    <div className='u-display_flex'>
      <TextField
        border
        className='u-mar-r_1'
        label='Label'
        value={label}
        onChange={(e) => {
          onChange('label', e.target.value);
        }}
      />
      <TextField
        border
        className='u-mar-r_1'
        type='number'
        label='Min'
        value={min}
        onChange={(e) => {
          const val = parseInt(e.target.value, 10);
          if (Number.isNaN(val) || val < limit * -1 || val > limit || val >= max) {
            return;
          }
          onChange('min', val);
        }}
      />
      <TextField
        border
        className='u-mar-r_1'
        type='number'
        label='Max'
        value={max}
        onChange={(e) => {
          const val = parseInt(e.target.value, 10);
          if (Number.isNaN(val) || val < limit * -1 || val > limit || val <= min) {
            return;
          }
          onChange('max', val);
        }}
      />
      <TextField
        border
        className='u-mar-r_1'
        type='number'
        label='Interval'
        value={interval}
        onChange={(e) => {
          const val = parseInt(e.target.value, 10);
          if (Number.isNaN(val)) {
            return;
          }
          onChange('interval', val);
        }}
      />
    </div>
  </div>
);

Axis.propTypes = {
  direction: PropTypes.string.isRequired,
  label: PropTypes.string,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  interval: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  limit: PropTypes.number
};

export function useAxisState({label = '', min = -5, max = 5, interval = 1} = {}) {
  const [axis, setAxis] = useState({
    label,
    min,
    max,
    interval
  });

  const updateProperty = (property, value) => {
    setAxis((state) => ({...state, [property]: value}));
  };

  return [axis, updateProperty];
}

export function getInitialAxisValues({question, axis}) {
  return {
    label: question.getQuestionTypeAttributes().getIn(['graph_contains', `${axis}_label`]),
    min: question.getQuestionTypeAttributes().getIn(['graph_contains', `${axis}_min`]),
    max: question.getQuestionTypeAttributes().getIn(['graph_contains', `${axis}_max`]),
    interval: question.getQuestionTypeAttributes().getIn(['graph_contains', `${axis}_interval`])
  };
}

export default Axis;
