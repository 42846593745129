import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {callAction} from 'client/framework';
import {addToast} from '@albert-io/atomic';
import Button from 'sg/Button/Button.react';
import TextArea from 'generic/Forms/TextArea/TextArea.react';
import authoringFeedbackActions from './AuthoringFeedback.actions';
import authoringFeedbackStore from './AuthoringFeedback.store';
import {AuthoringFeedbackMessages} from './AuthoringFeedbackMessages/AuthoringFeedbackMessages.react';
import {resource} from '@albert-io/json-api-framework/request/builder';
import LoadingIndicator from 'generic/LoadingIndicator.react';
import sessionStore from 'client/Session/SessionStore';

import './authoring-feedback.scss';

class AuthoringFeedbackForm extends React.Component {
  static propTypes = {
    questionId: PropTypes.string
  };

  componentDidUpdate() {
    if (authoringFeedbackStore.hasToast()) {
      const toast = authoringFeedbackStore.getToast();
      const type = toast.get('type');
      const title = toast.get('title');
      const message = toast.get('message');
      addToast({
        message,
        title,
        type
      });
      callAction(authoringFeedbackActions.CLEAR_TOAST);
    }
  }

  handleAddFeedback() {
    callAction(authoringFeedbackActions.SUBMIT_FEEDBACK);
  }

  handleUpdateFeedback = (e) => {
    callAction(authoringFeedbackActions.UPDATE_FEEDBACK_MESSAGE, e.target.value);
  };

  render() {
    /**
     * @todo It seems like this could use a shared query with the actual `EditPage` rather
     * than making an additional request.
     */
    const query = resource('authoring_question_v1')
      .mandarkEndpoint(['authoring_questions_v1', this.props.questionId])
      .include('authoring_question_set_v1');

    if (query.isResourceReady() === false) {
      return <LoadingIndicator />;
    }

    const ownQuestion =
      query.getResource().getAuthoringQuestionSet().getMeta().getAuthorId() ===
      sessionStore.getUserId();

    return (
      <div className='authoring-feedback-form'>
        <TextArea
          autoResize
          placeholder={ownQuestion ? `You can't leave yourself feedback!` : ``}
          disabled={ownQuestion || authoringFeedbackStore.isSavePending()}
          value={authoringFeedbackStore.getMessage()}
          onChange={this.handleUpdateFeedback}
        />
        <Button
          text='Add feedback'
          color='green'
          disabled={
            ownQuestion ||
            authoringFeedbackStore.getMessage().length === 0 ||
            authoringFeedbackStore.isSavePending()
          }
          onClick={this.handleAddFeedback}
        />
      </div>
    );
  }
}

class AuthoringFeedback extends React.Component {
  static propTypes = {
    questionId: PropTypes.string
  };

  constructor(props) {
    super();
    callAction(authoringFeedbackActions.UPDATE_QUESTION, props.questionId);
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.questionId !== this.props.questionId) {
      callAction(authoringFeedbackActions.UPDATE_QUESTION, nextProps.questionId);
    }
  }

  render() {
    const {questionId} = this.props;
    if (!questionId) {
      return (
        <div className='authoring-feedback'>
          <div className='authoring-feedback__instructions'>
            Select a question to review feedback.
          </div>
        </div>
      );
    }

    return (
      <div className='authoring-feedback'>
        <AuthoringFeedbackMessages questionId={questionId} />
        <AuthoringFeedbackForm questionId={questionId} />
      </div>
    );
  }
}

class PanelContents extends React.Component {
  static propTypes = {
    params: PropTypes.shape({
      questionId: PropTypes.string
    })
  };

  render() {
    return <AuthoringFeedback questionId={this.props.params.questionId} />;
  }
}

export const AuthoringFeedbackPanel = withRouter(PanelContents);
