import React, {useContext, useEffect, useState} from 'react';
import {Map} from 'immutable';
import moment from 'moment';

import BackgroundTask from 'generic/BackgroundTask/BackgroundTask.react';
import sessionStore from 'client/Session/SessionStore';
import {Button} from '@albert-io/atomic';
import {resource} from '@albert-io/json-api-framework/request/builder';

import {ReportsContext} from 'client/Reports/Reports.context';
import {getClassroomQuery, getClassroomSubjectsQuery} from 'client/Reports/reports.utils';

import './reports-export.scss';

interface Props {
  disabled: boolean;
}

function ExcelExport({disabled}: Props) {
  const {
    assignmentId,
    topLevelFilterId,
    subjectId: subjectIdFromDrilldown,
    subjectIdFromSearchParam
  } = useContext(ReportsContext);

  const [fileName, setFileName] = useState('');

  const selectedClassroom = topLevelFilterId && getClassroomQuery(topLevelFilterId).getResource();
  const subjects = getClassroomSubjectsQuery(topLevelFilterId).getResource();
  const subjectId = subjectIdFromDrilldown || subjectIdFromSearchParam;
  const selectedSubject = subjects.find((s) => s.getId() === subjectId);

  const query: any = {
    teacher: {
      id: sessionStore.getUserId()
    },
    classroom: {
      id: topLevelFilterId
    }
  };

  if (subjectId) {
    query.subject = {id: subjectId};
  }

  if (assignmentId) {
    query.assignment = {id: assignmentId};
  }

  useEffect(() => {
    (async () => {
      if (selectedClassroom && selectedSubject) {
        setFileName(`${selectedClassroom.getName()} – ${selectedSubject.getName()}`);
      } else if (assignmentId) {
        const assignment = await resource('assignments_v3')
          .mandarkEndpoint(['assignments_v3', assignmentId])
          .getResourcePromise();
        setFileName(
          `${moment().format('YYYY-MM-DD')}-${selectedClassroom.getName()}-${assignment.getName()}`
        );
      }
    })();
  }, [assignmentId, selectedClassroom, selectedSubject, setFileName]);

  return (
    <BackgroundTask
      className={disabled ? 'u-pointer-events_none' : ''}
      task='excel-export'
      args={Map({
        filename: fileName,
        path: '/reports/insight',
        query
      })}
    >
      <Button variant='outlined'>Download</Button>
    </BackgroundTask>
  );
}

export default ExcelExport;
