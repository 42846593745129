import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router';

import {Button} from '@albert-io/atomic';

import JoinCodeForm from '../../StudentDashboard/JoinCodeForm/JoinCodeForm.react';

import {useStudentDashboard} from '../../StudentDashboard/StudentDashboard.context';

/**
 * Represents student actions in their classes route shown in the secondary header.
 *
 * RightContent is dependent on a user's school value. We must ensure that is
 * requested before we render our New Class button.
 */

const RightContent = () => {
  const {classrooms, duplicateClassroom, isReady, isRequestPending} = useStudentDashboard();

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(false);
  }, [classrooms.size, duplicateClassroom, setShowModal]);

  if (!isReady) {
    return null;
  }

  return (
    <>
      <Button
        disabled={isRequestPending}
        onClick={() => {
          setShowModal(true);
        }}
        size='s'
      >
        Join a class
      </Button>
      {showModal ? (
        <JoinCodeForm.Modal
          handleClose={() => {
            setShowModal(false);
          }}
        />
      ) : null}
    </>
  );
};

export default withRouter(RightContent);
