import {createActions} from 'client/framework';

export default createActions(null, [
  'RESET_STORE',
  'CONFIRM_WILL_LEAVE',
  'RESET_TOAST',
  'TOGGLE_ABANDON_MODAL_ACTIVE',
  'SET_NEXT_PATH_NAME',
  'SUBMIT_ASSIGNMENT'
]);
