/**
 * # ExpandableDropdownItem
 *
 * Augments DropdownItem with an Expandable area. Children must be of type DropdownItem. When a child DropdownItem is
 * clicked, its specified onClick will be called, and the dropdown will close.
 *
 * ## Props
 *
 * - `expandableAs?: React.ElementType` - 'as' prop provided to the the Expandable area
 * - `triggerAs?: React.ElementType` - 'as' prop provided to the the DropdownItem trigger element
 * - `closeDropdown?: () => void` - Provided by the Dropdown parent component, this is passsed to the children FACC.
 * - `label?: React.ReactNode` - Children to provide to the DropdownItem
 * - `withBottomBorder?: boolean` - Provided automatically by the Dropdown component
 * - `children: React.ReactNode` - children inside of the ExpandableDropdown
 *
 * ## Usage
 *
 * ```tsx
 * import ExpandableDropdownItem from './ExpandableDropdownItem';
 *
 * const MyComponent = () => {
 *   return (
 *     <ExpandableDropdownItem
 *       expandableAs="div"
 *       triggerAs="button"
 *       closeDropdown={() => {}}
 *       label="Expandable Dropdown Item"
 *       withBottomBorder={true}
 *     >
 *       <DropdownItem onClick={() => {}}>Child Dropdown Item 1</DropdownItem>
 *       <DropdownItem onClick={() => {}}>Child Dropdown Item 2</DropdownItem>
 *     </ExpandableDropdownItem>
 *   );
 * };
 * ```
 */
import React, {useState, useMemo} from 'react';
import classnames from 'classnames';
import {v4 as uuid} from 'uuid';

import DropdownItem from '../DropdownItem/DropdownItem.react';
import Expandable from '../../helpers/Expandable/Expandable.react';
import IconButton from '../IconButton/IconButton.react';

import './expandable-dropdown-item.scss';

/**
 * Augments DropdownItem with an Expandable area. Children must be of type DropdownItem. When a child DropdownItem is
 * is clicked, its specified onClick will be called, and the dropdown will close.
 *
 * @augments {React.Component<Object>}
 */

interface ExpandableDropdownItemProps extends PropsWithChildrenRequired {
  /** 'as' prop provided to the the Expandable area */
  expandableAs?: React.ElementType;
  /** 'as' prop provided to the the DropdownItem trigger element */
  triggerAs?: React.ElementType;
  /** Provided by the Dropdown parent component, this is passsed to the children FACC. */
  closeDropdown?: () => void;
  /** Children to provide to the DropdownItem */
  label?: React.ReactNode;
  /** Provided automatically by the Dropdown component */
  withBottomBorder?: boolean;
  // className: string,
}

type ExpandableDropdownItemClonedChild = {
  bold: boolean;
  closeDropdown: () => void;
  withBottomBorder: boolean;
};

type Props<C extends React.ElementType> = PolymorphicComponentProps<C, ExpandableDropdownItemProps>;

type ExpandableDropdownItemComponent = <C extends React.ElementType = 'div'>(
  props: Props<C>
) => React.ReactElement | null;

export const ExpandableDropdownItem: ExpandableDropdownItemComponent = <
  C extends React.ElementType
>({
  as,
  children,
  className,
  closeDropdown,
  expandableAs = 'div',
  withBottomBorder = false,
  triggerAs = 'button',
  label,
  ...rest
}: Props<C>) => {
  const [expanded, setExpanded] = useState(false);

  function toggleExpanded(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();
    setExpanded(!expanded);
  }

  const [triggerId, expandableId] = useMemo(() => [uuid(), uuid()], []);
  const WrapperElement = useMemo(() => as ?? 'div', [as]);

  return (
    <WrapperElement className={classnames('m-expandable-dropdown-item', className)} {...rest}>
      <DropdownItem
        as={triggerAs}
        className='m-expandable-dropdown-item__trigger'
        onClick={toggleExpanded}
        id={triggerId}
        withBottomBorder={withBottomBorder}
        aria-expanded={expanded}
        aria-controls={expandableId}
      >
        <div className='m-expandable-dropdown-item__item-content-wrapper'>
          {label}
          <div
            className={classnames('m-expandable-dropdown-item__expanded-indicator', 'u-mar-l_1', {
              'm-expandable-dropdown-item__expanded-indicator--expanded': expanded
            })}
          >
            <IconButton
              as='div'
              icon='chevron-right'
              aria-hidden
              // active={expanded}
            />
          </div>
        </div>
      </DropdownItem>
      <Expandable
        as={expandableAs}
        id={expandableId}
        expanded={expanded}
        aria-labelledby={triggerId}
      >
        {React.Children.toArray(children).map((child) => {
          return React.isValidElement(child)
            ? React.cloneElement(child, {
                bold: false,
                closeDropdown,
                withBottomBorder: false
              } as ExpandableDropdownItemClonedChild)
            : null;
        })}
      </Expandable>
    </WrapperElement>
  );
};

export default ExpandableDropdownItem;
