import {FreeEntryQuestion} from './FreeEntry/FreeEntryQuestion.react';
import {FreeResponseQuestion} from './FreeResponse/FreeResponseQuestion.react';
import {MultipleChoiceQuestion} from './MultipleChoice/MultipleChoiceQuestion.react';
import {PassageCorrectionQuestion} from './PassageCorrection/PassageCorrectionQuestion.react';
import {TwoWayQuestion} from './TwoWay/TwoWayQuestion.react';
import {FillInTheBlankQuestion} from './FillInTheBlank/FillInTheBlankQuestion.react';
import {SnippetSelectionQuestion} from './SnippetSelection/SnippetSelectionQuestion.react';
import {TextHighlightQuestion} from './TextHighlight/TextHighlightQuestion.react';
import GraphingQuestion from './Graphing/GraphingQuestion.react';

/**
 * @todo: Build this out for all question types...
 */

const questionTypes = {
  'multiple-choice': MultipleChoiceQuestion,
  'snippet-selection': SnippetSelectionQuestion,
  'free-entry': FreeEntryQuestion,
  'free-response': FreeResponseQuestion,
  'fill-in-the-blank': FillInTheBlankQuestion,
  'two-way': TwoWayQuestion,
  'passage-correction': PassageCorrectionQuestion,
  'text-highlight': TextHighlightQuestion,
  'graph-contains': GraphingQuestion
};

// eslint-disable-next-line import/prefer-default-export
export function getRendererForQuestion(question) {
  return questionTypes[question.getQuestionType()];
}
