import React, {useMemo} from 'react';
import {List} from 'immutable';
import {GUIDE_TYPES} from 'client/constants';
import {SubjectModelV2, QuestionModelV3, QuestionSetModelV1} from '@albert-io/models';
import FeatureFlag from 'client/components/FeatureFlag/FeatureFlag.react';
import {
  Prompt as LegacyPrompt,
  Explanation as LegacyExplanation,
  SampleResponse as LegacySampleResponse,
  Standards as LegacyStandards,
  Tags as LegacyTags
} from './legacy/ContentBlocks.react';
import MarkdownBlock from '../MarkdownBlock/MarkdownBlock';
import {useShallow} from 'zustand/react/shallow';
import {useQuestionTranslationStore} from '../TranslationSection';
import './content-blocks.scss';
import {TagsList, StandardsList} from '../TagsStandardsList/TagsStandardsList.react';

interface PromptProps {
  question?: QuestionModelV3;
}

export const Prompt = ({question}: PromptProps) => {
  const {translatedQuestionInfo, showPromptTranslation} = useQuestionTranslationStore(
    useShallow((state) => ({
      translatedQuestionInfo: state.translatedQuestionInfo,
      showPromptTranslation: state.toggleStatus.prompt
    }))
  );

  const text = useMemo(() => {
    if (showPromptTranslation && translatedQuestionInfo) {
      return translatedQuestionInfo.prompt;
    }
    return question.getPrompt();
  }, [question, showPromptTranslation, translatedQuestionInfo]);

  return (
    <FeatureFlag name='assignment_question_translations'>
      <MarkdownBlock key={question?.getId()} text={text} />
      <FeatureFlag.Fallback>
        <LegacyPrompt question={question} />
      </FeatureFlag.Fallback>
    </FeatureFlag>
  );
};

interface ExplainationProps {
  question?: QuestionModelV3;
}

export const Explanation = ({question}: ExplainationProps) => {
  const {translatedQuestionInfo, showAnswerTranslation} = useQuestionTranslationStore(
    useShallow((state) => ({
      translatedQuestionInfo: state.translatedQuestionInfo,
      showAnswerTranslation: state.toggleStatus.answer
    }))
  );

  const text = useMemo(() => {
    if (showAnswerTranslation && translatedQuestionInfo) {
      return translatedQuestionInfo.solution_text;
    }
    return question.getMeta().getSolutionText();
  }, [question, translatedQuestionInfo, showAnswerTranslation]);

  return (
    <FeatureFlag name='assignment_question_translations'>
      <MarkdownBlock heading='Explanation' text={text} />
      <FeatureFlag.Fallback>
        <LegacyExplanation question={question} />
      </FeatureFlag.Fallback>
    </FeatureFlag>
  );
};

interface SampleResponseProps {
  question?: QuestionModelV3;
}

export const SampleResponse = ({question}: SampleResponseProps) => {
  const {translatedQuestionInfo, showAnswerTranslation} = useQuestionTranslationStore(
    useShallow((state) => ({
      translatedQuestionInfo: state.translatedQuestionInfo,
      showAnswerTranslation: state.toggleStatus.answer
    }))
  );
  const sample: string = useMemo(() => {
    if (showAnswerTranslation) {
      return translatedQuestionInfo?.sample;
    }
    return question.getMeta().getSample();
  }, [question, showAnswerTranslation, translatedQuestionInfo]);

  return (
    <FeatureFlag name='assignment_question_translations'>
      {sample ?? <MarkdownBlock heading='Sample Response' text={sample} />}
      <FeatureFlag.Fallback>
        <LegacySampleResponse question={question} />
      </FeatureFlag.Fallback>
    </FeatureFlag>
  );
};

interface StandardsProps {
  question?: QuestionModelV3;
  subject?: SubjectModelV2;
  questionSet?: QuestionSetModelV1;
}

export const Standards = ({question, subject, questionSet}: StandardsProps) => {
  const guideTypeLinkString = useMemo(() => {
    const guideType = questionSet.getGuideLevels().first()?.getGuide().getGuideType();
    const typeLinkString = guideType === GUIDE_TYPES.ASSESSMENT ? '/assessments' : '';
    return typeLinkString;
  }, [questionSet]);

  return (
    <FeatureFlag name='assignment_question_translations'>
      <StandardsList
        standards={question.getStandards()}
        linkToFunc={(item) =>
          `/${subject.getUrlSlug()}${guideTypeLinkString}?standards=${item.getId()}`
        }
      />
      <FeatureFlag.Fallback>
        <LegacyStandards question={question} subject={subject} questionSet={questionSet} />
      </FeatureFlag.Fallback>
    </FeatureFlag>
  );
};

interface TagsProps {
  question?: QuestionModelV3;
  subject?: SubjectModelV2;
  questionSet?: QuestionSetModelV1;
}

export const Tags = ({question, subject, questionSet}: TagsProps) => {
  const guideTypeLinkString = useMemo(() => {
    const guideType = questionSet.getGuideLevels().first()?.getGuide().getGuideType();
    const typeLinkString = guideType === GUIDE_TYPES.ASSESSMENT ? '/assessments' : '';
    return typeLinkString;
  }, [questionSet]);
  return (
    <FeatureFlag name='assignment_question_translations'>
      <TagsList
        tags={question.getQuestionSet().getTags ? question.getQuestionSet().getTags() : List()}
        linkToFunc={(item) => `/${subject.getUrlSlug()}${guideTypeLinkString}?tags=${item.getId()}`}
      />
      <FeatureFlag.Fallback>
        <LegacyTags question={question} subject={subject} questionSet={questionSet} />
      </FeatureFlag.Fallback>
    </FeatureFlag>
  );
};
