import React, {useState} from 'react';
import constants from 'client/constants';
import {callAction} from 'client/framework';
import sessionStore from 'client/Session/SessionStore';
import {Button, WithTooltip} from '@albert-io/atomic';

import questionEditorActions from '../QuestionEditor/QuestionEditor.actions';
import questionEditorStore from '../QuestionEditor/QuestionEditor.store';
import SubmitForReviewModal from '../QuestionActions/SubmitForReviewModal/SubmitForReviewModal.react';

export const AuthorChangeStatusButton = () => {
  const [showReviewModal, setShowReviewModal] = useState(false);

  // supers don't need to see this since they have the "full" status dropdown
  if (sessionStore.isSuper()) {
    return null;
  }

  const isSavePending = questionEditorStore.isSavePending();
  const questionSet = questionEditorStore.getQuestionSet();
  const status = questionSet.getStatus();
  const isDraftOrRejected =
    status === constants.QUESTIONSET_STATUSES.DRAFT_MODE ||
    status === constants.QUESTIONSET_STATUSES.REJECTED;
  const isInReview = status === constants.QUESTIONSET_STATUSES.IN_REVIEW;
  const canBePublished = !questionSet.getAuthoringGuideLevels().isEmpty();

  if (!isDraftOrRejected && !isInReview) {
    return null;
  }

  if (isDraftOrRejected) {
    return (
      <>
        <WithTooltip
          enabled={!canBePublished}
          content='Please align the question to a guide before submitting.'
        >
          <Button
            disabled={!canBePublished || isSavePending}
            onClick={() => setShowReviewModal(true)}
          >
            Submit for Review
          </Button>
        </WithTooltip>

        {showReviewModal && (
          <SubmitForReviewModal
            handleClose={() => setShowReviewModal(false)}
            handleSubmit={() => {
              if (!canBePublished) {
                return;
              }
              callAction(questionEditorActions.SUBMIT_FOR_REVIEW);
            }}
          />
        )}
      </>
    );
  }

  return (
    <Button
      disabled={isSavePending}
      onClick={() => {
        callAction(questionEditorActions.REVERT_TO_DRAFT);
      }}
    >
      Revert to Draft
    </Button>
  );
};
