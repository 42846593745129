import React from 'react';
import {getActiveState} from 'client/framework';

/**
 * This HOC forces a component to update when the global app state changes.
 * It's helpful if you need something to update based on a store change, but
 * that thing is nested inside a PureComponent.
 *
 * The `_isMounted` flag is a hack to prevent trying to forceUpdate
 * if the component is not mounted. You would think removing the change listener
 * on componentWillUnmount would do the trick, but it did not seem to work 100%.
 * I assume this is because the change event gets pushed to the event loop.
 *
 * Further reading: https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
 */

export default function forceUpdateOnStateChange(WrappedComponent) {
  return class extends React.Component {
    _isMounted = false;

    componentDidMount() {
      this._isMounted = true;
      getActiveState().on('change', this.rerender);
    }

    componentWillUnmount() {
      this._isMounted = false;
      getActiveState().removeListener('change', this.rerender);
    }

    rerender = () => {
      if (!this._isMounted) {
        return;
      }
      this.forceUpdate();
    };

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
}
