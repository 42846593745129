import React, {useContext, useRef, useCallback, ChangeEvent} from 'react';

import {v4 as uuid} from 'uuid';

import {useSlate} from 'slate-react';

import {callTargetedAction} from 'client/framework';

import {MediaModelV1} from 'resources/GeneratedModels/Media/MediaModel.v1';

import freeResponseActions from '../../../../../../FreeResponseQuestion.actions';

import {saveMedia} from '../../../../../../FreeResponseQuestion.queries';

import {insertImage, getBase64} from '../../../../Utils/editor.utils';

import WrittenSubmissionContext from '../../../../../WrittenSubmission.context';

import {Text, Icon, MenuListItem} from '@albert-io/atomic';

import {useUploadErrorHandler, ErrorModal} from '../../../../SharedModals/ErrorModal.react';

export const IMAGE_EXTENSIONS = ['.jpg', '.gif', '.png'];

interface ImageUploadButtonProps {
  setShowDropdown: (boolean) => void;
  tabIndex: number;
}

export const ImageUploadButton = ({setShowDropdown, tabIndex}: ImageUploadButtonProps) => {
  const {current: id} = useRef(uuid());
  const {handleUploadError, showUploadErrorModal, uploadErrorDetails, setShowUploadErrorModal} =
    useUploadErrorHandler();
  const presentedAcceptedExtensions = IMAGE_EXTENSIONS.join(', ');
  const acceptedExtensions = IMAGE_EXTENSIONS.join(',');
  const clickable = tabIndex === 0;

  const editor = useSlate();
  const {store, storeName} = useContext(WrittenSubmissionContext);

  const uploadImage = useCallback(
    async (draftId: string, file: File | null) => {
      if (!file) {
        handleUploadError({acceptedExtensions: presentedAcceptedExtensions});
        return;
      }
      try {
        const data = await getBase64(file);
        const response = (await saveMedia({media: data}, draftId, 'image')) as MediaModelV1;
        const mediaId = response.getId();
        insertImage(editor, mediaId);
      } catch (error: any) {
        handleUploadError({
          error,
          fileName: file.name,
          acceptedExtensions: presentedAcceptedExtensions
        });
      }
    },
    [editor]
  );

  const handleUpload = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const target = e.currentTarget;
      const file = target.files && target.files[0];

      if (store) {
        const draftId = store.getDraftId();
        if (draftId) {
          uploadImage(draftId, file);
        } else {
          callTargetedAction({
            name: freeResponseActions.SAVE_CALLBACK,
            targetStore: storeName,
            payload: (newDraftId: string) => {
              uploadImage(newDraftId, file);
            }
          });
        }
      }
    },
    [uploadImage, store, storeName]
  );

  const handleDropdownClick = () => {
    setShowDropdown(false);
    document.getElementById(`file-input-${id}`)?.click();
  };

  return (
    <>
      <MenuListItem
        className='written-submission__media-dropdown__item'
        onMenuItemClick={handleDropdownClick}
        clickable={clickable}
      >
        <Text bold> Image </Text>
        <Icon icon={['far', 'image']} />
        <input
          className='u-display_none'
          id={`file-input-${id}`}
          accept={acceptedExtensions}
          type='file'
          onClick={(e) => {
            (e.target as HTMLInputElement).value = ''; // will trigger onChange regardless if same file is added
          }}
          onChange={handleUpload}
          tabIndex={-1}
          aria-hidden
        />
        {showUploadErrorModal && (
          <ErrorModal
            errorDetails={uploadErrorDetails}
            onClose={() => setShowUploadErrorModal(false)}
          />
        )}
      </MenuListItem>
    </>
  );
};
