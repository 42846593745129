import {fromJS} from 'immutable';
import {Store} from 'client/framework';

import previousAttemptsActions from './PreviousAttempts.actions';

export default class PreviousAttemptsStore extends Store {
  constructor(name) {
    super(name);

    this.initialData = fromJS({
      activeAttemptId: '',
      attemptsByNumberToShow: []
    });

    this.setInitialData(this.initialData);
    this.handleTargeted(previousAttemptsActions.RESET_STORE, this._resetStore);
    this.handleTargeted(previousAttemptsActions.SET_ACTIVE_ATTEMPT_ID, this._setActiveAttemptId);
  }

  _resetStore() {
    this.writeData(this.initialData);
  }

  _setActiveAttemptId(activeAttemptId) {
    this.writeData('activeAttemptId', activeAttemptId);
  }

  _setNumberOfAttemptsToShow(attemptsCount) {
    this.writeData('numberOfAttemptsToShow', attemptsCount);
  }

  getActiveAttemptId() {
    return this.readData('activeAttemptId');
  }
}
