/* eslint-disable no-restricted-globals */
import {List} from 'immutable';

const sectionValidators = {
  getTimeLimit(number) {
    const errors = [];
    const val = number !== null ? parseInt(number, 10) : null;

    if (val !== null && (isNaN(val) || val < 1)) {
      errors.push('Time limit must be greater than 0');
    }
    if (val > 60 * 24 * 7) {
      errors.push('Time limit cannot exceed seven days');
    }

    return errors.length > 0 ? List(errors) : null;
  }
};

export default sectionValidators;
