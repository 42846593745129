/**
 * # VideoSupplement
 *
 * React component that renders a video supplement. It can display a Vimeo video using the
 * provided Vimeo ID or retrieve the video ID from the `content` object.
 *
 * ## Props (extends RequireOnlyOne<Props, 'content' | 'vimeoId'>)
 *
 * - `className` (optional): Additional CSS class name for the component.
 * - `content` (optional): An object with a `get` method that retrieves the video ID.
 * - `vimeoId` (optional): The Vimeo ID of the video.
 * - `title` (required): The title of the video.
 *
 * ## Usage
 *
 * ```tsx
 * import VideoSupplement from './VideoSupplement';
 *
 * const ExampleComponent = () => {
 *   const content = {
 *     get: (prop: string) => {
 *       // Return the video ID based on the prop
 *     }
 *   };
 *
 *   return (
 *     <VideoSupplement
 *       className="custom-video"
 *       content={content}
 *       title="Example Video"
 *     />
 *   );
 * };
 * ```
 */

import React from 'react';
import classnames from 'classnames';

import './video.scss';

interface Props extends PropsWithClassNameOptional {
  content?: {
    get(prop: string): string;
  };
  vimeoId?: string;
  title: string;
}

type RequireVimeoIdOrContent = RequireOnlyOne<Props, 'content' | 'vimeoId'>;

export const VideoSupplement = ({className, content, vimeoId, title}: RequireVimeoIdOrContent) => {
  const videoId = vimeoId || (content && content.get('videoId'));

  return (
    <div className='video-supplement video-editor__preview'>
      <div className={classnames('responsive-video', className)}>
        <div className='responsive-video__sizer'>
          <iframe title={title} frameBorder='0' src={`//player.vimeo.com/video/${videoId}`} />
        </div>
      </div>
    </div>
  );
};

export default VideoSupplement;
