import React from 'react';
import {Icon, Text} from '@albert-io/atomic';
import './audio-player.scss';

interface AudioControlsProps {
  isPlaying: boolean;
  playbackRate: number;
  togglePlayPause: () => void;
  restartAudio: () => void;
  changeRate: () => void;
}

const AudioControls = ({
  isPlaying,
  playbackRate,
  togglePlayPause,
  restartAudio,
  changeRate
}: AudioControlsProps) => {
  return (
    <div className='audio-player__buttons-group'>
      <button
        type='button'
        className='audio-player__button type-1'
        onClick={restartAudio}
        aria-label='restart audio'
        data-testid='audio-controls__restart-audio'
      >
        <Icon className='audio-player__button-icon' icon='backward-step' />
      </button>
      <button
        type='button'
        className='audio-player__button type-2'
        onClick={togglePlayPause}
        aria-label={isPlaying ? 'pause' : 'play'}
        data-testid='audio-controls__play-pause'
      >
        <Icon
          className='audio-player__button-icon-play-pause'
          icon={isPlaying ? 'pause' : 'play'}
        />
      </button>
      <button
        type='button'
        className='audio-player__button type-1'
        onClick={changeRate}
        aria-label='change playback rate'
        data-testid='audio-controls__change-playback-rate'
      >
        <Text className='audio-player__button-icon' bold>{`${playbackRate}x`}</Text>
      </button>
    </div>
  );
};

export default AudioControls;
