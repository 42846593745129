import React, {useState} from 'react';
import {
  Icon,
  Text,
  ListGroupItem,
  copyToClipboard,
  addToast,
  IconButton,
  WithTooltip,
  MenuListItem,
  Dropdown,
  Dialogue,
  Button,
  Modal
} from '@albert-io/atomic';
import moment from 'moment';
import {history} from 'client/history';
import constants from 'client/constants';
import './global-assignments.scss';
import sessionStore from 'client/Session/SessionStore';
import type {AssignmentModelV3} from '@albert-io/models';
import {AddAssignmentQuestionSetsToFolderModal} from 'client/Classrooms/Classroom/Teacher/Assignments/AssignmentsList/AssignmentRowOptions.react';
import {IconProp, IconStyle} from '@albert-io/atomic/atoms/Icon/Icon.react';
import {handleDeleteAssignment} from 'client/Assignments/GlobalAssignments/GlobalAssignments.queries';
import CustomizeReassignModal from 'client/components/CustomizeReassignModal/CustomizeReassignModal.react';

const getStatusColor = (status: string) => {
  switch (status) {
    case 'open':
      return 'positive';
    case 'closed':
      return 'tertiary';
    case 'scheduled':
      return 'warning';
    default:
      return 'tertiary';
  }
};

export const classesString = (classes) => {
  if (typeof classes === 'object' && classes !== null && !Array.isArray(classes)) {
    if (Object.values(classes).length <= 2) {
      return Object.values(classes).join(', ');
    }
    return `${Object.values(classes)[0]}, +${Object.values(classes).length - 1} more`;
  }
  if (classes.length <= 2) {
    return classes.map((c) => c.getName()).join(', ');
  }
  return `${classes[0].getName()}, +${classes.length - 1} more`;
};

const determineAssignmentStatus = (assignment) => {
  const startDate = assignment.getStartDateFromSettings();
  const dueDate = assignment.getDueDateFromSettings();
  const numIncompleteStudents = assignment.getMeta().getCountOfIncompleteStudents();
  const numStudentsAssigned = assignment.getMeta().getCountOfStudents();
  const allowsLateSubmissions = assignment.getIsAllowLateSubmissionsFromSettings();
  if (numIncompleteStudents === 0 || (!allowsLateSubmissions && dueDate < Date.now())) {
    return 'closed';
  }
  if (
    (startDate < Date.now() || !startDate) &&
    (dueDate > Date.now() || allowsLateSubmissions) &&
    numIncompleteStudents !== 0 &&
    numStudentsAssigned !== 0
  ) {
    return 'open';
  }
  if (startDate > Date.now()) {
    return 'scheduled';
  }

  return 'open';
};

const copyShareLink = (assignmentId) => {
  copyToClipboard(`${constants.ALBERT_FULL_PATH}/assignment/${assignmentId}`, () => {
    addToast({
      color: 'positive',
      title: 'Link Copied!',
      message: 'The link to this assignment has been copied to your clipboard.'
    });
  });
};

const generateDueDateElement = (assignment) => {
  const status = determineAssignmentStatus(assignment);

  const elementObj = {
    open: {
      text: `Due ${moment(assignment.getDueDateFromSettings()).format('MMM D, hh:mm A')}`,
      icon: 'circle',
      iconStyle: 'solid'
    },
    closed: {
      text: `Closed`,
      icon: 'ban',
      iconStyle: 'regular'
    },
    scheduled: {
      text: `Scheduled for ${moment(assignment.getStartDateFromSettings()).format(
        'MMM D, hh:mm A'
      )}`,
      icon: 'clock',
      iconStyle: 'light'
    }
  };

  return (
    <Text
      className={`content-${getStatusColor(
        status
      )} u-display_flex u-align-items_center u-white-space_nowrap u-mar-r_3`}
      size='s'
    >
      <Icon
        icon={elementObj[status].icon as IconProp}
        iconStyle={elementObj[status].iconStyle as IconStyle}
        color={getStatusColor(status)}
        className={` u-mar-r_1${
          elementObj[status].icon === 'circle' ? '  global-assignments__card-open-status' : ''
        }`}
      />
      {elementObj[status].text}
    </Text>
  );
};

const AssignmentCard = ({
  assignment,
  setAssignmentsList
}: {
  assignment: AssignmentModelV3;
  setAssignmentsList: (assignments) => void;
}) => {
  const isAdmin = sessionStore.isSchoolAdministrator();
  let classrooms = assignment.getClassrooms().toArray();
  const classroomsTeachingActive = classrooms.filter(
    (c) =>
      c
        .getTeachers()
        .toArray()
        .some((teacher) => teacher.id === sessionStore.getUserId()) && c.status === 'active'
  );
  const classroomsAdminNotTeachingActive = classrooms.filter(
    (c) =>
      c
        .getTeachers()
        .toArray()
        .every((teacher) => teacher.id !== sessionStore.getUserId()) && c.status === 'active'
  );
  if (!isAdmin) {
    classrooms = classrooms.filter((c) =>
      c
        .getTeachers()
        .toArray()
        .some((teacher) => teacher.id === sessionStore.getUserId())
    );
  }
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showFoldersModal, setShowFoldersModal] = useState(false);
  const [showReassignModal, setShowReassignModal] = useState(false);

  return (
    <>
      <div
        role='button'
        tabIndex={0}
        onClick={() =>
          history.pushState(null, `/assignment-builder/create-assignment/${assignment.id}/settings`)
        }
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            history.pushState(
              null,
              `/assignment-builder/create-assignment/${assignment.id}/settings`
            );
          }
        }}
        className='global-assignments__card u-pad-lr_2 u-pad-tb_1 background-primary u-text-d_none u-gap_space-x3'
      >
        <div className='global-assignments__doc-icon-wrapper background-brand'>
          <Icon
            icon='file-alt'
            iconStyle='light'
            color='primary-inverse-static'
            className='text-xl'
          />
        </div>
        <div className='global-assignments__card-details u-display_flex u-align-items_center u-flex-grow_1 u-overflow_hidden'>
          <div className='u-display_flex u-flex-wrap_nowrap u-flex_1 u-mar-r_2 u-overflow_hidden u-width_100pc'>
            <Text as='div' className='u-truncate'>
              {assignment.name}
            </Text>
          </div>
          <div className='u-display_flex u-align-items_center u-flex-shrink_0 u-flex-wrap_wrap'>
            {assignment.getTeacher().getId() !== sessionStore.getUserId() && (
              <WithTooltip placement='bottom' content={assignment.getTeacher().getFullName()}>
                <Icon color='tertiary' icon='users' iconStyle='light' className=' u-mar-r_3' />
              </WithTooltip>
            )}
            {generateDueDateElement(assignment)}
            <Text className='content-tertiary u-white-space_nowrap' size='s'>
              Edited {moment(assignment.updated_at).format('MMM D, YYYY')}
            </Text>
          </div>
        </div>
        <div className='u-display_flex u-align-items_center u-cursor_default'>
          <Dropdown
            trigger={
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                }}
                label='Show details'
                icon={['far', 'info-circle']}
                iconStyle='light'
                tabIndex={0}
              />
            }
            position='bottom-end'
          >
            <div className='u-padding-block_space-x2 u-padding-inline_space-x2'>
              <ListGroupItem
                className='u-pad-b_2'
                paddingLevel={0}
                onClick={(e) => e.stopPropagation()}
              >
                <Icon
                  icon='chalkboard'
                  iconStyle='light'
                  className='u-mar-r_2 content-primary fa-fw'
                />
                <Text size='s'>
                  {classroomsTeachingActive.length} of your active classes
                  {isAdmin && classroomsAdminNotTeachingActive.length > 0 && (
                    <div className='content-tertiary'>
                      + {classroomsAdminNotTeachingActive.length} other active classes at your
                      schools
                    </div>
                  )}
                </Text>
              </ListGroupItem>
              <ListGroupItem
                className='u-pad-tb_2'
                paddingLevel={0}
                border={false}
                onClick={(e) => e.stopPropagation()}
              >
                <Icon icon='pen-to-square' iconStyle='light' className='u-mar-r_2 fa-fw' />
                <Text size='s'>Edited {moment(assignment.updated_at).format('MMM D, YYYY')}</Text>
              </ListGroupItem>
              <ListGroupItem paddingLevel={0} border={false} onClick={(e) => e.stopPropagation()}>
                <Icon icon='users' iconStyle='light' className='u-mar-r_2 fa-fw' />
                <Text size='s'>Owner: {assignment.getTeacher().getFullName()}</Text>
              </ListGroupItem>
            </div>
          </Dropdown>
          <Dropdown
            trigger={
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                }}
                label='More options'
                icon='ellipsis-v'
                iconStyle='solid'
                tabIndex={0}
                className='global-assignments__card-more-menu'
                data-testid={`actions-${assignment.id}`}
              />
            }
            position='bottom-end'
          >
            <div className='u-pad_1'>
              <MenuListItem
                onClick={(e) => {
                  e.stopPropagation();
                  history.pushState(
                    null,
                    `/assignment-builder/create-assignment/${assignment.id}/settings`
                  );
                }}
                onKeyDown={(e) => {
                  e.stopPropagation();
                  if (e.key === 'Enter') {
                    history.pushState(
                      null,
                      `/assignment-builder/create-assignment/${assignment.id}/settings`
                    );
                  }
                }}
                className='global-assignments__card-dropdown-item'
              >
                <Icon icon='pencil' iconStyle='light' className='u-mar-r_2 fa-fw' />
                <Text>Edit</Text>
              </MenuListItem>
              <div className='global-assignments__card-border-line' />
              <MenuListItem
                onClick={(e) => {
                  e.stopPropagation();
                  setShowReassignModal(true);
                }}
                onKeyDown={(e) => {
                  e.stopPropagation();
                  if (e.key === 'Enter') {
                    setShowReassignModal(true);
                  }
                }}
                className='global-assignments__card-dropdown-item'
              >
                <Icon icon='rotate-right' iconStyle='light' className='u-mar-r_2 fa-fw' />
                Duplicate & Reassign
              </MenuListItem>
              <MenuListItem
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFoldersModal(true);
                }}
                onKeyDown={(e) => {
                  e.stopPropagation();
                  if (e.key === 'Enter') {
                    setShowFoldersModal(true);
                  }
                }}
                className='global-assignments__card-dropdown-item u-pad-t_0'
              >
                <Icon icon='folder' iconStyle='light' className='u-mar-r_2 fa-fw' />
                Save Questions to Folder
              </MenuListItem>
              <div className='global-assignments__card-border-line' />
              <MenuListItem
                onClick={(e) => {
                  e.stopPropagation();
                  copyShareLink(assignment.id);
                }}
                onKeyDown={(e) => {
                  e.stopPropagation();
                  if (e.key === 'Enter') {
                    copyShareLink(assignment.id);
                  }
                }}
                className='global-assignments__card-dropdown-item'
              >
                <Icon icon='arrow-up-from-bracket' iconStyle='light' className='u-mar-r_2 fa-fw' />
                Copy Student Share Link
              </MenuListItem>
              <MenuListItem
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDeleteModal(true);
                }}
                onKeyDown={(e) => {
                  e.stopPropagation();
                  if (e.key === 'Enter') {
                    setShowDeleteModal(true);
                  }
                }}
                className='global-assignments__card-dropdown-item u-pad-t_0'
                data-testid={`delete-${assignment.id}`}
              >
                <Icon icon='trash-can' iconStyle='light' className='u-mar-r_2 fa-fw' />
                Delete
              </MenuListItem>
            </div>
          </Dropdown>
        </div>
      </div>
      {showFoldersModal && (
        <AddAssignmentQuestionSetsToFolderModal
          assignmentId={assignment.id}
          handleClose={() => setShowFoldersModal(false)}
          questionCount={assignment.getCountOfQuestions()}
        />
      )}
      {showReassignModal && (
        <CustomizeReassignModal
          assignmentId={assignment.id}
          assignmentName={assignment.name}
          handleClose={() => setShowReassignModal(false)}
        />
      )}
      {showDeleteModal && (
        <Modal handleClose={() => setShowDeleteModal(false)} ariaLabel='Delete Assignment Modal'>
          {({CloseButtonWrapper, modalContentStyle}) => {
            return (
              <Dialogue inModal hideCloseBtn className={modalContentStyle} size='s'>
                <div className='u-display_flex u-flex-direction_column u-align-items_center u-justify-content_center'>
                  <div className='global-assignments__confirm-delete-modal-icon'>
                    <Icon iconStyle='light' icon='hand' />
                  </div>
                  <Text className='u-mar-t_5 u-font-weight_bold'>Are you sure?</Text>
                  <Text className='u-mar-tb_2 u-color_slate-700 u-text-align_center'>
                    You are about to remove the assignment <b>{assignment.name}</b> from all your
                    classrooms.
                  </Text>
                  <Text className='u-text-align_center'>This action cannot be undone.</Text>
                  <CloseButtonWrapper>
                    <Button
                      variant='outlined'
                      color='secondary'
                      size='m'
                      className='u-width_100pc u-mar-t_5 u-mar-b_1'
                    >
                      Cancel
                    </Button>
                  </CloseButtonWrapper>
                  <Button
                    variant='solid'
                    color='negative'
                    size='m'
                    className='u-width_100pc'
                    onClick={() => {
                      handleDeleteAssignment(assignment.id);
                      setAssignmentsList((prevAssignments) =>
                        prevAssignments.filter((a) => a.id !== assignment.id)
                      );
                      setShowDeleteModal(false);
                    }}
                    data-testid='delete-assignment-button'
                  >
                    Delete Assignment
                  </Button>
                </div>
              </Dialogue>
            );
          }}
        </Modal>
      )}
    </>
  );
};

export default AssignmentCard;
