import React from 'react';
import PropTypes from 'prop-types';
import {isNumber} from 'lodash';
import GenericExplanation from '../../common/V2/GenericExplanation.react';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import Button from 'sg/Button/Button.react';
import PassageCorrectionPopover from '../shared/PassageCorrectionPopover.react';
import {questionPropType} from 'resources/Question/Question.model';

export default class PassageCorrectionExplanation extends React.Component {
  static propTypes = {
    question: questionPropType
  };

  state = {
    activeIndex: null,
    showCorrectPassage: false
  };

  handleClick = (e) => {
    const regionIndex = e.target.dataset.regionIndex;
    if (regionIndex === undefined) {
      return;
    }
    this.setState({
      activeIndex: parseInt(e.target.dataset.idx, 10)
    });
  };

  handleActiveIndexReset = () => {
    this.setState({
      activeIndex: null
    });
  };

  toggleShowHide = () => {
    const updatedValue = !this.state.showCorrectPassage;
    const newState = {
      showCorrectPassage: updatedValue
    };
    if (updatedValue === false) {
      newState.activeIndex = null;
    }
    this.setState(newState);
  };

  wordPropsFunc = (text, optionIndex) => {
    const question = this.props.question;
    const matchedRegion = question.getRegionForOptionIndex(optionIndex);
    /**
     * Only non distractors and distractors with solutions should be interactive.
     */
    if (!matchedRegion || !matchedRegion.get('annotation')) {
      return {text};
    }
    const matchedRegionIndex = question.getRegionKeyForOptionIndex(optionIndex);
    const isAnnotatedDistractor = matchedRegion.get('solutions') === null;
    const splitSolution = isAnnotatedDistractor
      ? null
      : matchedRegion
          .get('solutions')
          .first()
          .split(/\s+/);
    const matchedStartIndex = matchedRegion.get('start_index');
    const matchedEndIndex = matchedRegion.get('end_index');
    const activeIndex = this.state.activeIndex;
    const isActive =
      isNumber(activeIndex) && question.doesRegionContainOptionIndex(matchedRegion, activeIndex);
    const isRangeStart = matchedStartIndex === optionIndex;
    const isRangeEnd = matchedEndIndex === optionIndex;
    const regionIndexDifference = matchedEndIndex - matchedStartIndex;
    const currentIndex = Math.abs(matchedEndIndex - optionIndex - regionIndexDifference);
    /**
     * A solution can have as many or more words than the incorrect region (i.e. 'alot' can be corrected to 'a lot',
     * but not the other way around). Because of this, if we're rendering the last word in an incorrect region, we
     * render the rest of the words in the solution, joined by a space.
     */
    let correctedText;
    if (isAnnotatedDistractor) {
      correctedText = text;
    } else if (currentIndex === regionIndexDifference) {
      correctedText = splitSolution.slice(regionIndexDifference).join(' ');
    } else {
      correctedText = splitSolution[currentIndex];
    }
    return {
      text,
      correctedText,
      isActive,
      isRangeStart,
      isRangeEnd,
      matchedRegionIndex,
      isCorrect: true
    };
  };

  render() {
    const question = this.props.question;
    const activeIndex = this.state.activeIndex;
    const annotation =
      activeIndex !== null ? question.getRegionForOptionIndex(activeIndex).get('annotation') : '';
    return (
      <GenericExplanation {...this.props}>
        <div className='passage-correction-explanation'>
          <div className='passage-correction-explanation__show-hide-toggle-wrapper'>
            <Button
              linkButton
              className='passage-correction-explanation__show-hide-toggle'
              onClick={this.toggleShowHide}
              text={`${this.state.showCorrectPassage ? 'Hide' : 'Show'} correct passage`}
            />
          </div>
          {this.state.showCorrectPassage ? (
            <MarkdownRendererV2
              passageCorrection
              className='passage-correction-question__correct-passage'
              text={question.getUncorrectedText()}
              onClick={this.handleClick}
              wordPropsFunc={this.wordPropsFunc}
              popover={
                <Popover
                  activeIndex={activeIndex}
                  key={activeIndex}
                  annotation={annotation}
                  onReset={this.handleActiveIndexReset}
                />
              }
            />
          ) : null}
        </div>
      </GenericExplanation>
    );
  }
}

class Popover extends React.Component {
  static propTypes = {
    annotation: PropTypes.string
  };

  render() {
    return (
      <PassageCorrectionPopover {...this.props}>
        <div className='passage-correction-popover__annotation-wrapper'>
          <div className='a-form-input__label'>Annotation:</div>
          <MarkdownRendererV2 text={this.props.annotation} />
        </div>
      </PassageCorrectionPopover>
    );
  }
}
