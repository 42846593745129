import {createActions} from 'client/framework';

export default createActions(null, [
  'INITIALIZE_STORE',
  'MODIFY_ACTIVE_SUPPLEMENT',
  'RESET_STORE',
  'SET_ACTIVE_SUBJECT',
  'SET_ACTIVE_SUPPLEMENT',
  'UPLOAD_FILE',
  'VALIDATE_SUPPLEMENT'
]);
