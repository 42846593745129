import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './assets/select.scss';

export default class Select extends React.Component {
  static propTypes = {
    autoComplete: PropTypes.string,
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    label: PropTypes.string.isRequired,
    name: PropTypes.string,
    multiple: PropTypes.bool,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocusValidation: PropTypes.bool,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  };

  static defaultProps = {
    autoComplete: 'on',
    autoFocus: false,
    className: '',
    disabled: false,
    error: false,
    errorMessage: 'Please enter correct data',
    name: '',
    multiple: false,
    onBlur: () => {},
    onChange: () => {},
    onFocusValidation: false,
    placeholder: '',
    required: false
  };

  render() {
    const isError = this.props.error;
    const selectClass = classnames('a-form-select__element', {
      'a-form-select__element--error': isError
    });
    const errorContainer = isError ? (
      <div className='a-form-select__error'>{this.props.errorMessage}</div>
    ) : null;

    // 'a-form-input',
    const wrapperClass = classnames('a-form-select', this.props.className, {
      'a-form-input--required': this.props.required
    });

    return (
      <label className={wrapperClass}>
        <div className='a-form-input__label'>{this.props.label}</div>
        <select
          className={selectClass}
          name={this.props.name}
          autoComplete={this.props.autoComplete}
          autoFocus={this.props.autoFocus}
          disabled={this.props.disabled}
          multiple={this.props.multiple}
          required={this.props.required}
          value={this.props.value}
          onChange={this.props.onChange}
          onBlur={this.props.onBlur}
        >
          {this.props.children}
        </select>
        {errorContainer}
      </label>
    );
  }
}
