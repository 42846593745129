import React from 'react';
import PropTypes from 'prop-types';
import eliminationToolStore from './EliminationTool.store';
import multipleChoiceQuestionActions from '../MultipleChoiceQuestion.actions';
import {callTargetedAction, getStoreByName} from 'client/framework';

import './elimination-tool.scss';

export default class HideShowButton extends React.Component {
  static propTypes = {
    storeName: PropTypes.string
  };

  toggleHideShow = () => {
    callTargetedAction({
      name: multipleChoiceQuestionActions.TOGGLE_HIDE_SHOW,
      targetStore: this.props.storeName
    });
  };

  render() {
    const store = getStoreByName(this.props.storeName);

    if (!eliminationToolStore.isEnabled() || !store.areOptionsEliminated()) {
      return null;
    }
    const hideShowText = store.getHideShow() ? 'Show' : 'Hide';
    return (
      <div className='elimination-tool__hide-show-button' onClick={this.toggleHideShow}>
        {hideShowText} eliminated answers
      </div>
    );
  }
}
