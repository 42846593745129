import ImmutablePropTypes from 'react-immutable-proptypes';
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {MULTIPLE_CHOICE_LETTERS} from './MultipleChoice.constants';

export default class MultipleChoiceQuestionResponseStats extends React.Component {
  static propTypes = {
    correctAnswer: ImmutablePropTypes.map,
    previewMode: PropTypes.bool,
    question: ImmutablePropTypes.record,
    selectedAnswer: ImmutablePropTypes.set
  };

  render() {
    const {question} = this.props;
    const correctOptions = this.props.correctAnswer;
    const selectedOptions = this.props.selectedAnswer;
    const isIncomplete = !correctOptions || !selectedOptions;

    if (
      (isIncomplete && !this.props.previewMode) ||
      /**
       * @todo: Consolidate this logic into a parent component that manages rendering of How Others Answered.
       */
      question.getTotalAnswerCount() < 50
    ) {
      return null;
    }

    return (
      <div className='question-response-stats'>
        <div className='question-response-stats__header'>How Others Answered</div>
        <div className='question-response-stats__body'>
          {question.getOptions().map((option, index) => {
            const optionLetter = MULTIPLE_CHOICE_LETTERS[index];
            const optionPercentage = question.getOptionResponsePercentage(option.get('id'));
            const isCorrectOption = correctOptions.has(option.get('id'));
            const isSelectedOption = selectedOptions ? selectedOptions.has(option.get('id')) : null;
            const optionClasses = classnames('question-response-stats__item', {
              correct: isCorrectOption,
              incorrect: isSelectedOption && !isCorrectOption
            });
            return (
              <div className={optionClasses} key={`question-response_${index}`}>
                <span className='question-response-stats__item-label'>{optionLetter}</span>
                <span
                  className='question-response-stats__item-fillbar'
                  data-fill-percentage={optionPercentage}
                />
                <span className='question-response-stats__item-percentage'>
                  {`${optionPercentage}%`}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
