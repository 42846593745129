import {query} from '@albert-io/json-api-framework/request/builder';

export function getAuthoringFeedbackQuery(questionId: string) {
  return query()
    .mandarkEndpoint(['authoring_feedback_v1'])
    .filter({
      authoring_question_v1: questionId
    })
    .withMeta('authoring_feedback_v1')
    .sort('-inserted_at');
}
