// @flow
import {Store} from 'client/framework';
import {Map} from 'immutable';
import {genericMandarkRequest} from 'resources/mandark.resource';
import makeConstants from 'lib/makeConstants';

import questionEditorStore from '../QuestionEditor/QuestionEditor.store';

import questionSetAlignmentActions from './QuestionSetAlignment.actions';

const alignmentTypes = makeConstants('guide', 'exam');

export default class QuestionSetAlignmentStore extends Store {
  constructor(name: string) {
    super(name);
    this.setInitialData(
      Map({
        pendingGuideLevelId: null,
        pendingExamId: null,
        pendingSectionId: null,
        alignmentType: null
      })
    );
    this.handleTargeted(
      questionSetAlignmentActions.SET_PENDING_GUIDE_LEVEL_ID,
      this._setGuideLevelId
    );
    this.handleTargeted(
      questionSetAlignmentActions.SET_PENDING_EXAM_ALIGNMENT,
      this._setExamAlignment
    );
    this.handleTargeted(questionSetAlignmentActions.SAVE_ALIGNMENT, this._saveAlignment);
    this.handleTargeted(questionSetAlignmentActions.REMOVE_ALIGNMENT, this._removeAlignment);
  }

  _setGuideLevelId(guideLevelId) {
    this.writeData((store) =>
      store.set('pendingGuideLevelId', guideLevelId).set('alignmentType', alignmentTypes.guide)
    );
  }

  _setExamAlignment({examId, sectionId}) {
    this.writeData((store) =>
      store
        .set('pendingExamId', examId)
        .set('pendingSectionId', sectionId)
        .set('alignmentType', alignmentTypes.exam)
    );
  }

  _promiseHandler(callback: Function): Function {
    const promiseName = 'saveAlignmentPromise';
    return (...args) => {
      const promise = callback(...args);
      this.writeData(promiseName, promise);
      promise.then(() => {
        this.writeData(promiseName, null);
      });
    };
  }

  _saveAlignment = this._promiseHandler(async (questionSetId: string) => {
    const alignmentType = this.getAlignmentType();
    const isAligningToGuide = alignmentType === alignmentTypes.guide;
    const isAligningToExam = alignmentType === alignmentTypes.exam;
    const examId = isAligningToExam ? this.getPendingExamId() : null;
    const guideLevelId = isAligningToGuide ? this.getPendingGuideLevelId() : null;
    const sectionId = isAligningToExam ? this.getPendingSectionId() : null;

    // We no-op if there are no incoming changes in the modal
    if (!alignmentType) {
      return;
    }

    const questionSet = questionEditorStore.getQuestionSet();
    const sections = questionSet.getSections();
    const exam = questionSet.getAuthoringExam();
    if (
      (isAligningToExam && !sections.isEmpty() && sections.first().getId() === sectionId) ||
      (!sectionId && exam && exam.getId() === examId)
    ) {
      return;
    }

    const payload = this.makeSaveAlignmentPayload(
      questionSetId,
      alignmentType,
      examId,
      guideLevelId,
      sectionId
    );
    await genericMandarkRequest(
      'patch',
      {
        resourcePath: ['authoring_question_sets_v1', questionSetId]
      },
      payload
    );
  });

  _removeAlignment = this._promiseHandler(async (questionSetId: string) => {
    const payload = this.makeRemoveAlignmentPayload(questionSetId);
    await genericMandarkRequest(
      'patch',
      {
        resourcePath: ['authoring_question_sets_v1', questionSetId]
      },
      payload
    );
  });

  _getBasePayload(questionSetId: string): Object {
    return {
      data: {
        type: 'authoring_question_set_v1',
        id: questionSetId
      }
    };
  }

  makeRemoveAlignmentPayload(questionSetId: string): Object {
    const payload = this._getBasePayload(questionSetId);

    payload.data.relationships = {
      authoring_guide_levels_v1: {
        data: []
      }
    };

    return payload;
  }

  makeSaveAlignmentPayload(
    questionSetId: string,
    category: ?string,
    examId: ?string,
    guideLevelId: ?string,
    sectionId: ?string
  ): Object {
    const payload = this._getBasePayload(questionSetId);

    // Predicate determines if we include relationships in our payload
    const isAligning = Boolean(examId) || Boolean(guideLevelId);

    if (category) {
      payload.data.attributes = {
        category
      };
    }
    /**
      We only clobber alignments when there is an incoming change; otherwise do nothing.

      Alignments to guides and exams are mutually exclusive.
     */
    if (isAligning) {
      payload.data.relationships = {
        authoring_guide_levels_v1: {
          data: guideLevelId
            ? [
                {
                  id: guideLevelId,
                  type: 'authoring_guide_level_v1'
                }
              ]
            : []
        }
      };
    }
    if (sectionId) {
      payload.data.relationships.sections_v1 = {
        data: [
          {
            id: sectionId,
            type: 'section_v1'
          }
        ]
      };
    }
    return payload;
  }

  hasPendingAlignment(): boolean {
    return this.getPendingExamId() !== null || this.getPendingGuideLevelId() !== null;
  }

  getPendingGuideLevelId = (): string | null => this.readData('pendingGuideLevelId');

  getPendingExamId = (): string | null => this.readData('pendingExamId');

  getPendingSectionId = (): string | null => this.readData('pendingSectionId');

  getAlignmentType = (): string | null => this.readData('alignmentType');

  getSaveAlignmentPromise = (): Promise<any> => this.readData('saveAlignmentPromise');

  isSavePending = (): boolean => Boolean(this.getSaveAlignmentPromise());
}
