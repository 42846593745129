import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {callTargetedAction, setUpStore} from 'client/framework';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import snippetSelectionQuestionActions from './SnippetSelectionQuestion.actions';
import SnippetSelectionQuestionStore from './SnippetSelectionQuestion.store';
import './snippet-selection-question.scss';

export class SnippetSelectionQuestion extends React.Component {
  static propTypes = {
    questionId: PropTypes.string,
    question: PropTypes.any,
    storeName: PropTypes.string.isRequired,
    isGuessSubmitted: PropTypes.any
  };

  getStore() {
    const {storeName, questionId} = this.props;
    return setUpStore(SnippetSelectionQuestionStore, storeName, questionId);
  }

  onOptionClick = (optionId) => {
    if (this.props.isGuessSubmitted) {
      return;
    }

    callTargetedAction({
      name: snippetSelectionQuestionActions.TOGGLE_OPTION,
      targetStore: this.getStore().getName(),
      payload: optionId
    });
  };

  render() {
    const question = this.props.question;
    const inputAreaClassNames = classnames('snippet-selection-question__input-area', {
      'snippet-selection-question__input-area--interactive': !(
        this.props.isGuessSubmitted || this.getStore().hasResponse
      )
    });
    return (
      <div className='snippet-selection-question'>
        <MarkdownRendererV2 text={question.getPrompt()} />
        <h2 className='question__prompt-heading'>Select answer(s) below</h2>
        <MarkdownRendererV2
          className={inputAreaClassNames}
          text={question.getSnippetPrompt()}
          snippetSelectionData={{
            isExplanation: this.props.isGuessSubmitted,
            options: question.getOptions(),
            storeName: this.getStore().getName(),
            onOptionClick: this.onOptionClick
          }}
        />
      </div>
    );
  }
}
