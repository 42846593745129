import moment, {unitOfTime} from 'moment';
import React, {useEffect, useState} from 'react';

import {Text, Icon} from '@albert-io/atomic';

import {SaveStatus} from '../../components/QuestionTypes/QuestionType.types';

interface StoreWithDraftGuessCapabilities {
  getSaveStatus: () => SaveStatus;
  getLastSaveTime: () => Date | null;
}

interface Props {
  store: StoreWithDraftGuessCapabilities;
}

export default function DraftGuessSaveStatus({store}: Props) {
  const [_, setTick] = useState(0);
  const status = store.getSaveStatus();
  const lastSaveTime = store.getLastSaveTime();
  const [offline, setOffline] = useState(false);

  useEffect(() => {
    const id = setInterval(() => {
      setTick((t) => t + 1); // trigger rerender each minute
    }, 60000);

    return () => {
      clearInterval(id);
    };
  }, [setTick]);

  useEffect(() => {
    const handleOffline = () => {
      setOffline(true);
    };

    const handleOnline = () => {
      setOffline(false);
    };

    global.addEventListener('offline', handleOffline);
    global.addEventListener('online', handleOnline);

    return () => {
      global.removeEventListener('offline', handleOffline);
      global.removeEventListener('online', handleOnline);
    };
  }, [setOffline]);

  function isError() {
    return [SaveStatus.LOAD_ERROR, SaveStatus.SAVE_ERROR].includes(status);
  }

  function isOfflineOrError() {
    return offline || isError();
  }

  function getIconProps() {
    let className;
    let color;
    let emphasis;
    let icon;

    if (isOfflineOrError()) {
      color = 'negative';
      emphasis = 'lowest';
      icon = ['fas', 'exclamation-triangle'];
    } else {
      className = 'u-color_slate-500';
      icon = ['far'];

      if (status === SaveStatus.SAVING) {
        icon.push('sync');
      } else if (status === SaveStatus.SAVED && !!lastSaveTime) {
        icon.push('check');
      } else {
        icon.push('cloud-upload');
      }
    }

    return {
      className,
      color,
      emphasis,
      icon
    };
  }

  function getTextContent() {
    if (offline) {
      let formattedTime;
      if (lastSaveTime !== null) {
        formattedTime = `Last saved ${lastSaveTime.toLocaleString()}`;
      }

      return `No network connection. ${formattedTime}`.trim();
    }

    if (status === SaveStatus.SAVE_ERROR) {
      return 'There was an error saving your draft.';
    }

    if (status === SaveStatus.LOAD_ERROR) {
      return 'There was an error loading your draft.';
    }

    if (status === SaveStatus.SAVING) {
      return 'Saving';
    }

    if (status === SaveStatus.SAVED && lastSaveTime !== null) {
      const now = new Date();
      const timeDiff = getUserFriendlyTimeDiff(lastSaveTime, now);
      return `Saved, ${timeDiff}`;
    }

    return 'Your work will be autosaved';
  }

  function getTextProps() {
    const className = ['u-mar-l_1'];
    let color;
    let emphasis;
    let size;

    if (isOfflineOrError()) {
      color = 'red';
      emphasis = 'high';
    } else {
      className.push('u-color_slate-500');
    }

    if (!isError()) {
      size = 's';
    }

    return {
      className: className.join(' '),
      color,
      emphasis,
      size
    };
  }

  function getUserFriendlyTimeDiff(earlierDate: Date, laterDate: Date) {
    const earlierDateUtc = moment.utc(earlierDate);
    const laterDateUtc = moment.utc(laterDate);

    const diffUnits: unitOfTime.Diff[] = ['days', 'hours', 'minutes'];

    for (let i = 0; i < diffUnits.length; i += 1) {
      const diffUnit = diffUnits[i];

      const clonedEarlierDateUtc = earlierDateUtc.clone().startOf(diffUnit);
      const clonedLaterDateUrc = laterDateUtc.clone().startOf(diffUnit);

      const diff = Math.floor(clonedLaterDateUrc.diff(clonedEarlierDateUtc, diffUnit));

      if (diff > 0) {
        const isPlural = diff !== 1;
        return `Last edit was ${diff} ${isPlural ? diffUnit : diffUnit.slice(0, -1)} ago`;
      }
    }

    return 'Last edit was just now';
  }

  return (
    <>
      <Icon {...getIconProps()} />
      <Text {...getTextProps()}>{getTextContent()}</Text>
    </>
  );
}
