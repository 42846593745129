import {Record} from 'immutable';

// Snippet Selection Option
class SnippetSelectionOptionModel extends Record({
  id: '',
  label: '',
  value: ''
}) {
  getId() {
    return this.get('id');
  }

  getLabel() {
    return this.get('label');
  }

  getValue() {
    return this.get('value');
  }
}

export function snippetSelectionSetup(data) {
  const options = data.get('options').map((option) => new SnippetSelectionOptionModel(option));
  return data.set('options', options);
}
