import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {Set} from 'immutable';
import {IconButton} from '@albert-io/atomic';

import {OptionEliminatorContext} from 'components/PracticeView/OptionEliminator/OptionEliminator.react';

import './mcq-option-eliminator.scss';

const OptionEliminatorInputWrapper = ({
  children,
  questionId,
  optionId,
  disabled,
  onEliminate = () => {}
}) => {
  const {isEnabled, updateQuestionState, getQuestionState, hideEliminatedOptions} = useContext(
    OptionEliminatorContext
  );

  if (disabled) {
    return children(false);
  }

  const isOptionEliminated = !!getQuestionState(questionId)?.includes(optionId);
  if (isOptionEliminated && hideEliminatedOptions) {
    return null;
  }
  return (
    <div className='mcq-option-eliminator'>
      {isEnabled && (
        <IconButton
          className='u-mar-r_1'
          icon={isOptionEliminated ? 'plus' : 'times'}
          label={isOptionEliminated ? 'Restore option' : 'Eliminate option'}
          onClick={() => {
            if (!isOptionEliminated) {
              onEliminate();
            }
            updateQuestionState(questionId, (questionState = Set()) => {
              return questionState.includes(optionId)
                ? questionState.delete(optionId)
                : questionState.add(optionId);
            });
          }}
        />
      )}
      {children(isEnabled && isOptionEliminated)}
    </div>
  );
};

OptionEliminatorInputWrapper.propTypes = {
  children: PropTypes.func.isRequired,
  questionId: PropTypes.string.isRequired,
  optionId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onEliminate: PropTypes.func
};

export default OptionEliminatorInputWrapper;
