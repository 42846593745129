import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import makeConstants from 'lib/makeConstants';
import {callTargetedAction, getStoreByName} from 'client/framework';
import DrawerStore from './DrawerStore';
import {drawerActions} from './DrawerActions';
import './drawer.scss';

/**
 * <Drawer
 *   storeName='MyDrawerStore'
 *   className='my-drawer-class'
 *   drawerTitle='My Drawer'
 *   isOpen={false}
 *   onOpen={() => {
 *     callAction(randomActions.RANDOM_ACTION);
 *   }}
 * >
 *   <div>
 *     I'm a div in a Drawer!
 *   </div>
 * </Drawer>
 */

/**
 * Light: Current default -- Used in global nav drawer
 * Dark: Inverted header and body colors --- Used in Add to Assignment template
 * Blue: Currently unused -- To be added for Experimental Nav
 */
export const colorSchemes = makeConstants('LIGHT', 'DARK', 'BLUE');

export default class Drawer extends React.Component {
  static propTypes = {
    storeName: PropTypes.string,
    drawerContentClassName: PropTypes.string,
    drawerTitle: PropTypes.string.isRequired,
    drawerFooterContents: PropTypes.node,
    hasCloseButton: PropTypes.bool,
    isOpen: PropTypes.bool,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    colorScheme: PropTypes.string,
    direction: PropTypes.string
  };

  static defaultProps = {
    storeName: 'DrawerStore',
    drawerContentClassName: '',
    drawerTitle: '',
    drawerFooterContents: null,
    hasCloseButton: false,
    isOpen: false,
    onOpen: () => {},
    onClose: () => {},
    colorScheme: colorSchemes.LIGHT
  };

  constructor(props) {
    super(props);
    this.getStore();
  }

  UNSAFE_componentWillMount() {
    const isOpen = this.props.isOpen;

    if (process.env.IS_BROWSER && isOpen) {
      callTargetedAction({
        name: drawerActions.DRAWER_SET_DRAWER_VISIBILITY,
        payload: isOpen,
        targetStore: this.props.storeName
      });
      if (this.props.onOpen) {
        this.props.onOpen();
      }
    }
  }

  componentDidMount() {
    this.toggleBodyClass();
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.isOpen !== this.props.isOpen) {
      callTargetedAction({
        name: drawerActions.DRAWER_SET_DRAWER_VISIBILITY,
        payload: nextProps.isOpen,
        targetStore: this.props.storeName
      });
      if (nextProps.isOpen && this.props.onOpen) {
        this.props.onOpen();
      } else if (this.props.onClose) {
        this.props.onClose();
      }
    }
  }

  componentDidUpdate() {
    this.toggleBodyClass();
  }

  componentWillUnmount() {
    callTargetedAction({
      name: drawerActions.DRAWER_RESET_STORE,
      targetStore: this.props.storeName
    });
    global.document.body.classList.remove('a-body--drawer-active');
  }

  getStore() {
    return getStoreByName(this.props.storeName) || new DrawerStore(this.props.storeName);
  }

  toggleBodyClass() {
    if (this.getStore().isOpen()) {
      global.document.body.classList.add('a-body--drawer-active');
    } else {
      global.document.body.classList.remove('a-body--drawer-active');
    }
  }

  handleClose(e) {
    e.stopPropagation();
    callTargetedAction({
      name: drawerActions.DRAWER_SET_DRAWER_VISIBILITY,
      payload: false,
      targetStore: this.props.storeName
    });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  render() {
    const isOpen = this.getStore().isOpen();
    const drawerShadeClassNames = classnames({
      drawer__shade: true,
      'drawer__shade--hidden': !isOpen
    });
    const drawerClassName = classnames('drawer', {
      'drawer--hidden': !isOpen,
      'drawer--light-scheme': this.props.colorScheme === colorSchemes.LIGHT,
      'drawer--dark-scheme': this.props.colorScheme === colorSchemes.DARK,
      'drawer--blue-scheme': this.props.colorScheme === colorSchemes.BLUE
    });

    const closeButton = this.props.hasCloseButton ? (
      <button className='drawer-footer__close-btn' onClick={(e) => this.handleClose(e)}>
        Close
      </button>
    ) : null;

    const drawerWrapperClass = classnames('drawer-wrapper', {
      'drawer-wrapper--left': this.props.direction === 'left'
    });

    return (
      <div className={drawerWrapperClass}>
        <div
          className={drawerShadeClassNames}
          onClick={(e) => {
            e.stopPropagation();
            callTargetedAction({
              name: drawerActions.DRAWER_SET_DRAWER_VISIBILITY,
              payload: false,
              targetStore: this.props.storeName
            });
            if (this.props.onClose) {
              this.props.onClose();
            }
          }}
        />
        <div className={drawerClassName}>
          <div className='drawer-header'>
            <div className='drawer-header__title'>{this.props.drawerTitle}</div>
            <div className='drawer-header__close-btn' onClick={(e) => this.handleClose(e)}>
              <span className='fa fa-times' />
            </div>
          </div>
          <div className={`drawer-content ${this.props.drawerContentClassName}`}>
            {this.props.children}
          </div>
          <div className='drawer-footer'>
            {closeButton}
            {this.props.drawerFooterContents}
          </div>
        </div>
      </div>
    );
  }
}
