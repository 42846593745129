import {createActions} from 'client/framework';

const normalActions = [
  'DELETE_TEMPLATE',
  'HIDE_MOBILE_QUESTION_AREA',
  'RESET_ARE_STORES_BOOTSTRAPPED',
  'RESET_STORE',
  'SET_BOOTSTRAPPED_STORES',
  'SET_GUIDE_LEVEL_IDS',
  'SET_PAGE',
  'SET_PAGE_SIZE',
  'SET_SESSION_START_TIME',
  'SET_SESSION_TYPE',
  'SHOW_MOBILE_QUESTION_AREA',
  'START_ASSIGNMENT',
  'SET_TEACHER_MODE',
  'WIPE_BUILT_QUESTION_STORES'
];

const interactionEngineActions = createActions(null, normalActions);
export default interactionEngineActions;
