import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {callAction} from 'client/framework';
import backgroundTaskStore from './BackgroundTask.store';
import BackgroundTaskActions from './BackgroundTask.actions';

import './background-task.scss';

export default class BackgroundTask extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    task: PropTypes.string
  };

  handleClick = () => {
    if (backgroundTaskStore.isRunning()) {
      return;
    }
    callAction(BackgroundTaskActions.SET_TASK, this.props.task);
    callAction(BackgroundTaskActions.SET_ARGS, this.props.args);
    callAction(BackgroundTaskActions.RUN);
  };

  render() {
    const className = classnames('background-task__trigger', this.props.className, {
      'background-task__trigger--running': backgroundTaskStore.isRunning()
    });

    return (
      <div className={className} onClick={this.handleClick}>
        {this.props.children}
      </div>
    );
  }
}
