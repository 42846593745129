// @flow
import {List} from 'immutable';
import {Empty} from '@albert-io/json-api-framework/models/helpers';
import {GenericListCollectionModel} from 'resources/Generic/GenericCollection.model';
import type {GuideLevel} from 'resources/GuideLevel/GuideLevel.model';
import type {GuideLevelV2} from 'resources/GuideLevel/GuideLevelV2.model';
import type {TagModel} from 'resources/Tag/Tag.model';
import type {QuestionType} from 'resources/Question/Question.model';
import type {ExamModelV1} from 'resources/augmented/Exam/ExamModel.v1';
import type {StudentAssignmentModelV1} from 'resources/GeneratedModels/StudentAssignment/StudentAssignmentModel.v1';
import type {AuthoringExamModelV1} from 'resources/augmented/Exam/AuthoringExamModel.v1';
import type {AuthoringGuideLevelV1} from 'resources/GeneratedModels/AuthoringGuideLevel/AuthoringGuideLevelModel.v1';

export const questionSetExtensions = {
  getAssignmentPosition(assignmentId: string): number {
    return this.getIn(['relationshipMeta', 'assignment', assignmentId, 'position'], 0);
  },

  getTemplatePosition(templateId: string): number {
    return this.getIn(['relationshipMeta', 'template', templateId, 'position'], 0);
  },

  getExam(): ?ExamModelV1 {
    /**
     * Questions can only be aligned to one exam at a time. Because this is an included resource,
     * and there is no .findOne() analogue for included resources, we just return the first one in the list
     */
    return this.getExams().first();
  },

  getAuthoringExam(): ?AuthoringExamModelV1 {
    return this.getAuthoringExams().first();
  },

  getFreeGuideLevels(): List<GuideLevel | GuideLevelV2> {
    return this.getGuideLevels().filter((level) => {
      return level.isFree();
    });
  },

  getGuideLevelPath(): List<GuideLevelV2> | null {
    if (this.getGuideLevels().isEmpty()) {
      return null;
    }
    return this.cache('getGuideLevelPath', () => {
      return this.getGuideLevels().sortBy((guideLevel) => guideLevel.getNlevel());
    });
  },

  getAuthoringGuideLevelPath(): List<AuthoringGuideLevelV1> | null {
    if (this.getAuthoringGuideLevels().isEmpty()) {
      return null;
    }
    return this.cache('getAuthoringGuideLevelPath', () => {
      return this.getAuthoringGuideLevels().sortBy((guideLevel) => guideLevel.getNlevel());
    });
  },

  getGuideLevels(): GenericListCollectionModel {
    // The GuideLevelCollectionModel requires we call .getCollection() in order to get our data.
    // If the data is the empty symbol, we return an empty List().
    const guideLevels = this.get('guide_levels');
    if (guideLevels === Empty) {
      return new GenericListCollectionModel();
    } else {
      return guideLevels.getCollection();
    }
  },

  getPosition(): number {
    return this.getIn(['meta', 'position'], 0);
  },

  getQuestionByTitleSlug(slug: string): QuestionType {
    return this.getQuestions().find((question) => {
      return question.getTitleSlug() === slug;
    });
  },

  getQuestions(): List<QuestionType> {
    const func = () => this.get('questions').sortBy((question) => question.getPosition());
    return this.cache('getQuestions', func);
  },

  getQuestionIds(): List<string> {
    const func = () => this.getQuestions().map((question) => question.getId());
    return this.cache('getQuestionIds', func);
  },

  getStudentAssignmentPosition(assignmentId: string): number {
    return this.getIn(['relationshipMeta', 'student_assignment', assignmentId, 'position'], 0);
  },

  getTagsSortedByName(): List<TagModel> {
    return this.cache('tagsSortedByName', () => {
      return this.getTags()
        .getCollection()
        .sortBy((tag) => tag.getName());
    });
  },

  getURLSlugPart(): string {
    /**
     * The 'slug-id` takes on the following form: subject@question-set-slug
     * This method is used specifically for grabbing the question set slug part
     * for a (the) URL.
     */
    return this.get('slug_id').split('@')[1];
  },

  isAlignedToExam(): boolean {
    return this.getCategory() === 'exam';
  },

  isAlignedToGuide(): boolean {
    return this.getCategory() === 'guide';
  },

  isFree(): boolean {
    return this.cache('isFree', () => !this.getFreeGuideLevels().isEmpty());
  },

  isMultiQuestionSet(): boolean {
    return this.getQuestions().size > 1;
  },

  hasMultipleFreeResponseQuestions(): boolean {
    return (
      this.isMultiQuestionSet() &&
      this.getQuestions().filter((q) => q.getType() === 'free-response').size > 1
    );
  }
};
