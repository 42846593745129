import request from 'superagent';
import {isEmpty} from 'lodash';
import _decode from 'jwt-decode';
import Cookies from 'js-cookie';

import notifier from '@albert-io/notifier';
import {isInternal, getEnvironment, getHost} from '@albert-io/environment';

import {IntercomService} from 'vendor/Intercom/browser/service';
import {callAction} from 'client/framework';
import SessionLifecycleActions from 'client/Session/SessionLifecycleActions';

/**
 * @todo This is probably too coupled to the authorization configuartion in the API
 *       it seems like a decent way to avoid this would be to provide a cookie explicitly
 *       for use by _this_ application.
 * @see  https://github.com/albert-io/mandark/pull/4711 for implementation (and coupling) context.
 */
const COOKIE_NAME = 'guardian_authorization_0_token';
const API_HOST = getHost();
const agent = request.agent().withCredentials();

/**
 * Primarily used by the Express server to extract the authentication cookie from
 * a HTTP request.
 *
 * @param {object} cookies The cookie object provided by ExpressJS
 * @returns {string|null} Returns the value of the matching authorization cookie or `null`
 */
export function getAuthenticationCookie(cookies) {
  return cookies && cookies[COOKIE_NAME] ? cookies[COOKIE_NAME] : null;
}

export function decode(token) {
  if (token) {
    try {
      return _decode(token);
    } catch (e) {
      notifier.notify(e, {
        component: '@albert-io/authentication',
        message: 'Unable to decode token'
      });
    }
  }
  return {};
}

export function getRequestAgent() {
  return agent;
}

export function login(payload, initializeSession = true) {
  const r = getRequestAgent().post(`${API_HOST}/auth/sign_in`).send(payload);
  if (initializeSession) {
    return r.then(resumeSession);
  }
  return r;
}

export function logout(redirect = '/') {
  return getRequestAgent()
    .post(`${API_HOST}/auth/sign_out`)
    .then(() => closeSession(redirect));
}

export function resumeSession() {
  return getSessionDetails()
    .then((res) => {
      if (res.status === 200 && res.body) {
        return bootstrapSessionFromResponse(res);
      }
      return Promise.resolve();
    })
    .catch(() => {
      return getRequestAgent().post(`${API_HOST}/auth/session/reset`);
    });
}

/**
 * @todo I want to figure out a better way to handle this... basically
 * this is used for re-fetching the current session information AND ensuring
 * a re-render by altering the state tree.
 */
export function refreshSession() {
  return resumeSession();
}

export function getSessionDetails() {
  return getRequestAgent().get(`${API_HOST}/auth/session`);
}

let RAW_RESOURCE = {};

export function getSession() {
  return {
    getEmail: () => (RAW_RESOURCE.is_teacher ? RAW_RESOURCE.email : null),
    getId: () => RAW_RESOURCE.id || null,
    getLastAuthenticatedAt: () => RAW_RESOURCE.last_authenticated_at || null,
    getSignUpDate: () => RAW_RESOURCE.inserted_at || null,
    getDistrictCleverId: () => RAW_RESOURCE.district_clever_id || null,
    isSuper: () => Boolean(RAW_RESOURCE.super),
    isAuthor: () => Boolean(RAW_RESOURCE.is_author),
    isTeacher: () => Boolean(RAW_RESOURCE.is_teacher),
    isSchoolAdministrator: () => Boolean(RAW_RESOURCE.is_school_administrator),
    isStudent: () => {
      return !(
        Boolean(RAW_RESOURCE.is_teacher) ||
        Boolean(RAW_RESOURCE.is_school_administrator) ||
        Boolean(RAW_RESOURCE.is_author) ||
        Boolean(RAW_RESOURCE.super)
      );
    },
    shouldResetPasswordOnLogin: () => Boolean(RAW_RESOURCE.reset_password_on_login),
    hasTeacherAccess: () => {
      return (
        Boolean(RAW_RESOURCE.is_teacher) ||
        Boolean(RAW_RESOURCE.is_school_administrator) ||
        Boolean(RAW_RESOURCE.super)
      );
    },
    isConfirmed: () => RAW_RESOURCE.status !== 'unconfirmed',
    isValid: () => !isEmpty(RAW_RESOURCE) && Boolean(RAW_RESOURCE.id)
    // getRawResource: () => RAW_RESOURCE
  };
}

function bootstrapSessionFromResponse(response) {
  const {body} = response;
  if (!body) {
    throw Error('Unable to locate session details.');
  }
  const {user} = body;
  if (!user) {
    return;
  }

  RAW_RESOURCE = user;
  callAction(SessionLifecycleActions.SET_SESSION_STATE, getSession().isValid());
  notifier.setIdentity({
    id: getSession().getId(),
    is_author: user.is_author,
    is_platform_administrator: user.is_platform_administrator,
    is_school_administrator: user.is_school_administrator,
    is_teacher: user.is_teacher,
    last_authenticated_at: user.last_authenticated_at,
    last_authenticated_method: user.last_authenticated_method
  });

  IntercomService.bootIntercomUser({
    user: {
      id: getSession().getId()
    },
    session: {
      isSuper: getSession().isSuper(),
      hasTeacherAccess: getSession().hasTeacherAccess()
    }
  });
}

function closeSession(redirect) {
  notifier.clean();
  IntercomService.shutdownIntercom();
  callAction(SessionLifecycleActions.SET_SESSION_STATE, false);

  let cookieDomain = 'albert.io';
  if (isInternal()) {
    cookieDomain = getEnvironment();
  }

  Cookies.remove('lti_launch_token', {domain: cookieDomain});
  Cookies.remove('lti_launch_context', {domain: cookieDomain});

  /**
   * IMPORTANT**: In the browser we **MUST** do a hard refresh of the page otherwise we
   * could potentially leak application state between session (`window._appState` or anything else
   * populated from a potentially authenticated isomorphic context).
   */
  if (process.env.IS_BROWSER) {
    global.document.location = redirect;
  }
}
