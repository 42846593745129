import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {callAction} from 'client/framework';
import appActions from 'client/AppActions';
import makeRef from 'lib/makeRef';
import './collapsible-sidebar-container.scss';

/**
 * @example
 *  <CollapsibleSidebarContainer
 *    className='authoring-queue'
 *    leftSidebars={[{
 *      content: <SomeComponent />,
 *      label: 'Some Component',
 *      width: 250
 *    }, {
 *      content: <OtherComponent />,
 *      label: 'Other Component',
 *      width: 250
 *    }]}
 *  >
 *    <h1>This is the main area</h1>
 *    <p>Wow, look at it exist the way that it does</p>
 *  </CollapsibleSidebarContainer>
 */
export default class CollapsibleSidebarContainer extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    mainContentClassName: PropTypes.string,
    leftSidebars: PropTypes.arrayOf(
      PropTypes.shape({
        width: PropTypes.number,
        content: PropTypes.node,
        label: PropTypes.string,
        initialOpenState: PropTypes.bool,
        disabled: PropTypes.bool
      })
    ),
    rightSidebars: PropTypes.arrayOf(
      PropTypes.shape({
        width: PropTypes.number,
        content: PropTypes.node,
        label: PropTypes.string,
        initialOpenState: PropTypes.bool,
        disabled: PropTypes.bool
      })
    )
  };

  static defaultProps = {
    leftSidebars: [],
    rightSidebars: []
  };

  componentDidMount() {
    this.sizeWrapper();
    this.mutationObserver = new global.MutationObserver(this.sizeWrapper);
    this.mutationObserver.observe(global.document.body, {
      childList: true,
      subtree: true
    });
    this.setShouldShowFooter(false);
  }

  componentWillUnmount() {
    this.setShouldShowFooter(true);
  }

  sizeWrapper = () => {
    const distanceFromTop = this.wrapperNode.getBoundingClientRect().top + global.scrollY;
    const height = `calc(100vh - ${distanceFromTop}px)`;
    if (this.wrapperNode.style.height !== height) {
      this.wrapperNode.style.height = height;
    }
  };

  setShouldShowFooter(shouldDisplay) {
    callAction(appActions.SET_FOOTER_DISPLAY, shouldDisplay);
  }

  wrapperNode = null;

  makeRef = makeRef.bind(this);

  render() {
    return (
      <div
        className={classnames('collapsible-sidebar-container', this.props.className)}
        ref={this.makeRef}
        data-ref-name='wrapperNode'
      >
        {this.props.leftSidebars.map((item, i) => (
          <Sidebar key={i} {...item} position='left' />
        ))}
        <div
          className={classnames(
            'collapsible-sidebar-container__main',
            this.props.mainContentClassName
          )}
        >
          {this.props.children}
        </div>
        {this.props.rightSidebars.map((item, i) => (
          <Sidebar key={i} {...item} position='right' />
        ))}
      </div>
    );
  }
}

/**
 * @todo When a sidebar is open and becomes disabled, we should make sure to _close_ it.
 */
class Sidebar extends React.Component {
  static propTypes = {
    width: PropTypes.number,
    content: PropTypes.node,
    label: PropTypes.string,
    initialOpenState: PropTypes.bool,
    disabled: PropTypes.bool,
    position: PropTypes.oneOf(['left', 'right'])
  };

  static defaultProps = {
    initialOpenState: true,
    disabled: false,
    position: 'left'
  };

  state = {
    isOpen: this.props.initialOpenState
  };

  toggleExpand = () => {
    if (this.props.disabled) {
      return;
    }
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  generateToggle() {
    const isOpen = this.state.isOpen;
    return (
      <div className='collapsible-sidebar__toggle-wrapper' onClick={this.toggleExpand}>
        <button
          disabled={this.props.disabled}
          className='collapsible-sidebar__toggle unbutton'
          aria-label={`${isOpen ? 'Collapse' : 'Expand'} sidebar`}
        >
          <span
            className={classnames('fa', {
              'fa-chevron-down': isOpen,
              'fa-chevron-up': !isOpen,
              /**
               * For `right` sidebars we make sure the chevron points the proper direction
               * by "mirroring" the `fa`
               */
              'fa--mirrored': this.props.position === 'right'
            })}
          />{' '}
          {this.props.label}
        </button>
      </div>
    );
  }

  generateContent() {
    const width = `${this.props.width}px`;
    return (
      <div
        className='collapsible-sidebar__content-wrapper'
        style={{
          width: this.state.isOpen ? width : '0px'
        }}
      >
        <div className='collapsible-sidebar__content' style={{width}}>
          {this.props.content}
        </div>
      </div>
    );
  }

  render() {
    /**
     * If the `props.position` is `right`, we put the
     * panel's toggle to the left of the content.
     */
    const right = this.props.position === 'right';
    return (
      <div className='collapsible-sidebar'>
        {right ? this.generateToggle() : this.generateContent()}
        {right ? this.generateContent() : this.generateToggle()}
      </div>
    );
  }
}
