import React, {lazy} from 'react';
import {IndexRedirect, Redirect, Route} from 'react-router';

import {requireSession} from 'lib/decorators/requireSession';
import sessionStore from 'client/Session/SessionStore';

import AuthorDashboardProvider from '../../AuthorDashboard/AuthorDashboardProvider/AuthorDashboardProvider.react';

const Queue = lazy(() => import('client/Dennis/Content/Queue/Queue.react'));
const AuthorDashboard = lazy(() => import('../../AuthorDashboard/AuthorDashboard.react'));

const AuthorQueue = (props) => {
  return <Queue isAuthorQueue {...props} />;
};

class AuthorDashboardWithContext extends React.Component {
  render() {
    return (
      <AuthorDashboardProvider>
        <AuthorDashboard {...this.props} />
      </AuthorDashboardProvider>
    );
  }
}

export const AuthoringRoutes = (
  <Route name='authoring' path='/authoring' title='Authoring | Albert.io'>
    <IndexRedirect to='dashboard' />
    <Route
      path='dashboard'
      component={requireSession({rolePredicate: () => sessionStore.isAuthor()})(
        AuthorDashboardWithContext
      )}
      title='Authoring | Albert.io'
    />
    <Route path='questions(/:status)'>
      <Redirect to='/authoring/queue' />
    </Route>
    <Route path='free-response(/:status)'>
      <Redirect to='/authoring/queue' />
    </Route>
    <Route
      path='queue(/:questionSetId(/:questionId))'
      component={AuthorQueue}
      title='Queue | Albert.io'
    />
  </Route>
);
