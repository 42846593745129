import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {withRouter} from 'react-router';
import {history} from 'client/history';
import Button from 'sg/Button/Button.react';
import SimpleModal from 'generic/SimpleModal/SimpleModal.react';
import appStore from 'client/AppStore';
import './confirm-leave-modal.scss';

/**
 * <ConfirmLeaveModal />
 * ------
 * This component prompts the user to confirm they want to leave the specified Route.
 * The only prop that must be passed down is `shouldPrompt`, which will determine whether or not
 * we want to prompt at all.
 *
 * A Route is _not_ the current path, but the Route component specificed via the `route` prop. If a
 * `route` prop is not passed, the component will use the most deeply nested Route it can find by looking
 * at the appStore.routerProps.routes array.
 *
 * For example, if I we have a Route with the following structure and no `route` prop is passed...
 * <Route
 *   component={SomeComponent}
 *   path='foo'
 * >
 *   <Route
 *     component={OtherComponent}
 *     path='bar'
 *   />
 * </Route>
 * ...assuming `shouldPrompt` is true, the modal will prompt when the OtherComponent Route unloads.
 *
 * UI Considerations
 * ------
 * This component has some basic default styles, and you may just pass some string props to override the default
 * content in the modal, or you may pass a `makeModalBody` prop (type function that returns a React.Component), which
 * will replace the entire body of the modal. The modal will pass your function two props: handleStay and handleLeave
 * (both are functions), which you can use to call the appropriate action depending on what the user chooses to do.
 *
 * Props
 * ------
 * shouldPrompt (required if shouldPromptFunc not supplied) - Boolean denoting whether or not the prompt should show
 *   when the given Route will unload
 * shouldPromptFunc (required if shouldPrompt not supplied) - Function that returns whether or not the prompt should
 *   show. If both shouldPrompt and shouldPromptFunc are provided, shouldPromptFunc will take precedence.
 * modalTitle - Title that goes in top bar of the modal
 * modalPrompt - Content that will be displayed as the text in the modal. My be a string or a react element.
 * modalBody - If passed, the modal's body will be replaced with this component
 * onStay - Function to call if the user chooses to stay
 * onLeave - Function to call if the user chooses to leave
 * route - The route that, when it will unload, will cause the modal to show if shouldPrompt is true
 *
 * Example
 * ------
 * class ComponentThatShouldPrompt extends React.Component {
 *   render() {
 *     return (
 *       <div>
 *         <SomeSuperCoolComponent />
 *         <ConfirmLeaveModal
 *           shouldPrompt={someStore.hasUnsavedChanges()} // add this in later when we have validation on the f/e
 *         />
 *       </div>
 *     );
 *   }
 * }
 *
 * Upgrading to react-router 4
 * ------
 * https://github.com/ReactTraining/react-router/issues/4635#issuecomment-297828995
 */

function shouldPromptPropType(props, propName, componentName) {
  const hasShouldPrompt = 'shouldPrompt' in props;
  const hasShouldPromptFunc = 'shouldPromptFunc' in props;
  if (!(hasShouldPrompt || hasShouldPromptFunc)) {
    return new Error(
      `${componentName} must be passed a \`shouldPrompt\` or \`shouldPromptFunc\` prop`
    );
  }
  if (hasShouldPrompt && typeof props.shouldPrompt !== 'boolean') {
    return new Error(
      // eslint-disable-next-line max-len
      `Invalid prop \`shouldPrompt\` of type \`${typeof props.shouldPrompt}\` supplied to \`${componentName}\`, expected \`boolean\`.`
    );
  }
  if (hasShouldPromptFunc && typeof props.shouldPromptFunc !== 'function') {
    return new Error(
      // eslint-disable-next-line max-len
      `Invalid prop \`shouldPromptFunc\` of type \`${typeof props.shouldPromptFunc}\` supplied to \`${componentName}\`, expected \`function\`.`
    );
  }
  return null;
}

class ConfirmLeaveModal extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    shouldPrompt: shouldPromptPropType,
    shouldPromptFunc: shouldPromptPropType,
    modalTitle: PropTypes.string,
    modalPrompt: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    makeModalBody: PropTypes.func,
    onStay: PropTypes.func,
    onLeave: PropTypes.func,
    route: PropTypes.object,
    // withRouter HOC props
    router: PropTypes.object.isRequired,
    stayButtonText: PropTypes.string,
    stayButtonColor: PropTypes.string,
    leaveButtonText: PropTypes.string,
    leaveButtonColor: PropTypes.string
  };

  static defaultProps = {
    modalTitle: 'Confirmation',
    modalPrompt: 'Are you sure you want to leave?',
    onStay: () => {},
    onLeave: () => {},
    stayButtonText: 'Stay',
    stayButtonColor: 'blue',
    leaveButtonText: 'Leave'
  };

  constructor() {
    super();
    this.state = {
      nextRoute: null,
      showModal: false,
      hasConfirmedLeave: false
    };
  }

  componentDidMount() {
    const leaveHookRoute =
      this.props.route || appStore.routerProps.routes[appStore.routerProps.routes.length - 1];
    this.props.router.setRouteLeaveHook(leaveHookRoute, this.handleRouteLeave);
  }

  shouldPrompt() {
    return this.props.shouldPromptFunc ? this.props.shouldPromptFunc() : this.props.shouldPrompt;
  }

  handleRouteLeave = (nextRoute) => {
    if (this.shouldPrompt() && !this.state.hasConfirmedLeave) {
      this.setState({nextRoute, showModal: true});

      return false;
    }
    return null;
  };

  handleLeave = () => {
    this.props.onLeave();
    this.setState({hasConfirmedLeave: true, showModal: false}, () => {
      history.pushState(null, this.state.nextRoute);
    });
  };

  handleStay = () => {
    this.props.onStay();
    this.setState({showModal: false});
  };

  handleClose = () => {
    this.setState({showModal: false});
  };

  getModalBody() {
    if (this.props.makeModalBody) {
      return this.props.makeModalBody(this.handleLeave, this.handleStay);
    }
    const modalPrompt =
      typeof this.props.modalPrompt === 'string' ? (
        <p>{this.props.modalPrompt}</p>
      ) : (
        this.props.modalPrompt
      );
    return (
      <div>
        {modalPrompt}
        <div className='confirm-leave-component-modal__actions'>
          <Button
            className='confirm-leave-component-modal__actions--leave'
            linkButton
            color={this.props.leaveButtonColor}
            onClick={this.handleLeave}
            text={this.props.leaveButtonText}
          />
          <Button
            color={this.props.stayButtonColor}
            className='confirm-leave-component-modal__actions--stay'
            onClick={this.handleStay}
            text={this.props.stayButtonText}
          />
        </div>
      </div>
    );
  }

  render() {
    const classes = classnames(this.props.className, 'confirm-leave-component-modal');
    return this.shouldPrompt() && this.state.showModal ? (
      <SimpleModal
        title={this.props.modalTitle}
        className={classes}
        onModalClose={this.handleClose}
      >
        {this.getModalBody()}
      </SimpleModal>
    ) : null;
  }
}

export default withRouter(ConfirmLeaveModal);
