import React from 'react';
import PropTypes from 'prop-types';

import {addToast, PasswordField} from '@albert-io/atomic';

import ResetPassword from 'client/components/ResetPassword/ResetPassword.react';
import ResetPasswordContext from 'client/components/ResetPassword/ResetPassword.context';

import {resource} from '@albert-io/json-api-framework/request/builder';
import {genericMandarkRequest} from 'resources/mandark.resource';

/**
 * ChangePassword
 * ==============
 *
 * The ChangePassword component appears in:
 *   1) the Account Settings page (ChangePasswordModal.react.js
 *      via UserDetails.react.js)
 *   2) the landing page for users whose token requires them to
 *      update their password (ChangePasswordLandingPage.react.js)
 *
 * It returns an instance of a ResetPassword component but with
 * the addition of an "Old Password" field, plus the necessary
 * handlers for changes, successful saving, and errors.
 *
 * Props:
 *    userId - Required - the ID of the user_v2 resource to which
 *        we send our PATCH request
 *    legend - a string that will appear inside our
 *        <ResetPassword.Legend /> tags
 */

const OldPasswordField = ({onChange, ...rest}) => {
  const {inputType} = React.useContext(ResetPasswordContext);
  return (
    <PasswordField border onChange={onChange} type={inputType} label='Current password' {...rest} />
  );
};

OldPasswordField.propTypes = {
  onChange: PropTypes.func
};

export default class ChangePassword extends React.Component {
  static propTypes = {
    legend: PropTypes.string,
    userId: PropTypes.string.isRequired,
    checkOldPassword: PropTypes.bool
  };

  static defaultProps = {
    checkOldPassword: true
  };

  constructor() {
    super();

    this.state = {
      oldPassword: '',
      oldPasswordError: ''
    };
  }

  handleChange = (e) => this.setState({oldPassword: e.target.value, oldPasswordError: ''});

  handleError = (error) => {
    const errorDetail = error.response.body.errors[0].detail;
    if (errorDetail === 'Old password did not match.') {
      this.setState({oldPasswordError: 'Incorrect password. Please try again.'});
    } else {
      addToast({
        title: 'Oops! Looks like something went wrong',
        color: 'negative',
        message: 'There was a problem with your request. If the problem persists, contact us.'
      });
    }
  };

  handleSubmit = (password) => {
    const {userId} = this.props;
    const {oldPassword} = this.state;
    const payload = {
      data: {
        attributes: {
          password,
          ...(this.props.checkOldPassword && {verify_old_password: oldPassword})
        }
      }
    };

    const query = resource('user_v2')
      .mandarkEndpoint(['users_v2', userId, 'reset_password'])
      .done();
    return genericMandarkRequest('patch', query, payload);
  };

  render() {
    const {legend, checkOldPassword, ...rest} = this.props;
    const {oldPasswordError} = this.state;
    return (
      <ResetPassword
        customDisableSubmitCondition={checkOldPassword && this.state.oldPassword === ''}
        onError={this.handleError}
        onSubmit={this.handleSubmit}
        {...rest}
      >
        {legend && <ResetPassword.Legend>{legend}</ResetPassword.Legend>}
        <ResetPassword.Form>
          {checkOldPassword && (
            <OldPasswordField
              borders
              className='u-mar-b_2'
              error={!!oldPasswordError}
              message={oldPasswordError}
              onChange={this.handleChange}
              value={this.state.oldPassword}
            />
          )}
          <ResetPassword.NewPasswordField />
          <ResetPassword.ConfirmPasswordField />
          <ResetPassword.ShowPasswordToggle />
        </ResetPassword.Form>
      </ResetPassword>
    );
  }
}
