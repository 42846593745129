import {Map} from 'immutable';
import {startCase} from 'lodash';
import notifier from '@albert-io/notifier';
import ImmutablePropTypes from 'react-immutable-proptypes';
import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import classnames from 'classnames';
import {callAction, callTargetedAction, getStoreByName} from 'client/framework';
import sessionStore from 'client/Session/SessionStore';
import {hasSubjectAccess} from 'lib/UserAccessUtil';
import Button from 'sg/Button/Button.react';
import PreviousAttempts from 'generic/PreviousAttempts/PreviousAttempts.react';
import previousAttemptsActions from 'generic/PreviousAttempts/PreviousAttempts.actions';
import upgradeCallToActionModalActions from 'client/Modals/UpgradeCallToActionModal/UpgradeCallToActionModalActions';
import {getQuestionTypeDefinition} from 'client/QuestionTypes/QuestionTypeDefinitions';
import {showModal} from 'client/Modals/ModalActions';
import IssuesModalActions from 'client/Modals/IssuesModal/IssuesModalActions';
import {addToast, addGenericErrorToast} from '@albert-io/atomic';
import issuesModalStore from 'client/Modals/IssuesModal/IssuesModalStore';
import {issuesModalName} from 'client/Modals/IssuesModal/IssuesModal.react';
import questionTrackerActions from 'client/Modals/QuestionTrackerModal/QuestionTrackerModal.actions';
import QuestionDifficultyLabel from 'generic/QuestionDifficultyLabel/QuestionDifficultyLabel.react';

import supplementActions from 'client/Supplement/SupplementActions';

import questionsListActions from 'client/InteractionEngineV2/shared/QuestionsList/QuestionsListActions';
import LoadingIndicator from 'generic/LoadingIndicator.react';
import interactionEngineStore from 'client/InteractionEngineV2/InteractionEngineStore';
import AccessLabel from 'generic/AccessLabel/AccessLabel.react';
import appActions from 'client/AppActions';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {GuessModel} from 'resources/Guess/Guess.model';

import systemTimeOffsetActions from 'client/generic/SystemTimeOffset/SystemTimeOffset.actions';
import {invalidatePartialInterest} from 'resources/mandark.resource';
import {getQuestionEditPath} from 'client/Dennis/Content/Queue/shared';
import studentAssignmentQuestionsListActions from 'client/InteractionEngineV2/IESessionTypes/Assignments/StudentAssignmentIE/StudentAssignmentQuestionsList/StudentAssignmentQuestionsList.actions';
import {invalidateBlockedAssignmentQuestionSetsQuery} from 'client/SubjectPracticeView/subjectPracticeView.queries';
import {showGlobalLoginModal} from 'client/LogIn/utils';

import assignmentV2IEActions from '../../IESessionTypes/AssignmentV2IE/AssignmentV2IE.actions';
import RestrictedQuestionMessage from '../RestrictedQuestionMessage/RestrictedQuestionMessage.react';

import {activeQuestionStore} from './ActiveQuestionStore';
import {activeQuestionActions} from './ActiveQuestionActions';

import './active-question.scss';

export default class ActiveQuestion extends React.Component {
  static propTypes = {
    assignmentId: PropTypes.string,
    question: ImmutablePropTypes.record.isRequired,
    questionSet: ImmutablePropTypes.record.isRequired,
    questionStoreName: PropTypes.string,
    canEditQuestion: PropTypes.bool,
    shouldShowExplanation: PropTypes.bool,
    shouldShowExplanationAfterGuessIsSubmitted: PropTypes.bool,
    isAssignmentOverdue: PropTypes.bool,
    isAssignmentSubmitted: PropTypes.bool,
    showPrevButton: PropTypes.bool,
    showNextButton: PropTypes.bool,
    showGuessHistory: PropTypes.bool,
    isLastAssignmentQuestion: PropTypes.bool,
    showFreeLabel: PropTypes.bool,
    guessToLoad: PropTypes.instanceOf(GuessModel),
    isPracticeExam: PropTypes.bool,
    topBanner: PropTypes.node,
    hideSubmitButton: PropTypes.bool,
    isUnsubmittedAssignmentQuestion: PropTypes.bool
  };

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    this.setupQuestion({
      assignmentId: this.props.assignmentId,
      question: this.props.question,
      questionSet: this.props.questionSet,
      questionStoreName: this.props.questionStoreName,
      guessToLoad: this.props.guessToLoad
    });
    this.setQuestionTrackerQuestionSetId(this.props.questionSet.getId());
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.question && this.props.question.getId() !== nextProps.question.getId()) {
      callAction(activeQuestionActions.ACTIVE_QUESTION_RESET_STORE);
      this.setupQuestion({
        assignmentId: nextProps.assignmentId,
        question: nextProps.question,
        questionSet: nextProps.questionSet,
        questionStoreName: nextProps.questionStoreName,
        guessToLoad: nextProps.guessToLoad,
        isDifferentQuestionById: this.props.question.getId() !== nextProps.question.getId()
      });
      this.setUpSupplements();

      // If we're getting a new question, scroll to the top of the view
      if (process.env.IS_BROWSER) {
        global.scroll(0, 0);
      }
    }

    if (nextProps.questionSet && this.props.questionSet.getId() !== nextProps.questionSet.getId()) {
      this.setQuestionTrackerQuestionSetId(nextProps.questionSet.getId());
    }
  }

  componentWillUnmount() {
    this.clearSEOTitleAndDescription();
    callAction(activeQuestionActions.ACTIVE_QUESTION_RESET_STORE);
  }

  clearSEOTitleAndDescription() {
    callAction(appActions.RESET_METADATA, ['title', 'metaDescription']);
  }

  setupQuestion({
    assignmentId,
    question,
    questionSet,
    questionStoreName,
    guessToLoad,
    // Used to scroll the window to the top and reset the guess time elapsed timer.
    isDifferentQuestionById = true
  }) {
    if (!question || !questionSet) {
      this.clearSEOTitleAndDescription();
      return;
    }

    callAction(appActions.UPDATE_METADATA, {
      title: `${questionSet.getSubject().getName()} - ${question.getTitle()}`,
      metaDescription: question.getPrompt()
    });

    callAction(activeQuestionActions.ACTIVE_QUESTION_VIEW_QUESTION, {
      assignmentId,
      question,
      questionStoreName
    });

    /**
     * If we're explicitly passing a 'guessToLoad' prop, we want that prop to be used as the guess to be loaded
     * by the questionTypeStore.
     */
    if (Object.prototype.hasOwnProperty.call(this.props, 'guessToLoad') && guessToLoad) {
      callTargetedAction({
        name: activeQuestionActions.ACTIVE_QUESTION_LOAD_GUESS,
        targetStore: questionStoreName,
        payload: guessToLoad
      });
    } else if (!Object.prototype.hasOwnProperty.call(this.props, 'guessToLoad') && assignmentId) {
      callTargetedAction({
        name: activeQuestionActions.FETCH_GUESS_FOR_QUESTION_IN_ASSIGNMENT,
        targetStore: questionStoreName,
        payload: {
          assignmentId,
          question
        }
      });
    }

    if (this.props.isPracticeExam) {
      callTargetedAction({
        name: activeQuestionActions.LOAD_QUESTION,
        targetStore: questionStoreName,
        payload: question
      });
    }

    callAction(activeQuestionActions.ACTIVE_QUESTION_SET_QUESTION_SET, questionSet);

    if (isDifferentQuestionById) {
      if (process.env.IS_BROWSER) {
        global.scroll(0, 0);
      }
      callAction(activeQuestionActions.ACTIVE_QUESTION_SET_START_TIME);
    }

    /**
     * More impacts of the way we handle partially completed assignments. Upon entering an assignment, we immediately
     * load any guesses that you've made. As such, we should also show the explanation. This is fine even for assignments
     * that have more restrictive feedback settings since they don't come back from mandark anyways.
     * We also check if the user is a student, so teacher assignemnt template editing doesn't bounce on every question
     */
    if (assignmentId && sessionStore.isStudent()) {
      callTargetedAction({
        name: activeQuestionActions.ACTIVE_QUESTION_SET_SHOULD_SHOW_EXPLANATION,
        targetStore: questionStoreName,
        payload: true
      });
    }
  }

  setUpSupplements() {
    const questionSupplements = this.props.question.getSupplements();
    if (questionSupplements.isEmpty()) {
      return;
    }
    callAction(supplementActions.QUESTIONSET_SUPPLEMENTS_RECEIVED, questionSupplements);
  }

  openIssuesModal() {
    issuesModalStore.clearIssue();
    const {question} = activeQuestionStore;
    const questionId = question.getId();

    callAction(IssuesModalActions.MODIFY_ISSUE, {questionId});
    showModal(issuesModalName);
  }

  submitAnswer() {
    if (!sessionStore.hasValidSession()) {
      showGlobalLoginModal();
      return;
    }

    const {assignmentId} = this.props;
    const {questionStoreName} = this.props;
    const questionStore = getStoreByName(questionStoreName);
    const hasUserSelectedAnswer = questionStore && questionStore.hasUserSelectedAnswer;
    const {isGuessSubmissionPending} = questionStore;
    const {questionSet} = activeQuestionStore;
    const subjectId = questionSet.getSubjectId();

    if (!hasUserSelectedAnswer || isGuessSubmissionPending) {
      return;
    }

    /**
     * These two actions need to be called in this order so that the VanillaIEQuestionsListStore can
     * invalidate the question sets query.
     */
    callTargetedAction({
      name: activeQuestionActions.ACTIVE_QUESTION_SUBMIT_ANSWER,
      payload: {assignmentId},
      targetStore: questionStoreName
    });
    callAction(activeQuestionActions.ACTIVE_QUESTION_SUBMIT_ANSWER);

    questionStore.responsePromise
      .then((response) => {
        const responseQuestion = response.getQuestion();
        const responseQuestionStoreName = interactionEngineStore.getStoreNameForQuestionId(
          responseQuestion.getId()
        );

        /**
         * The following takes the most recent request to the server--the placed guess--
         * and re-syncs the system time offset. This will verify anew that there are no
         * discrepancies between the user's system time and the server time.
         * This is especially useful for thwarting cheaters.
         */
        callAction(systemTimeOffsetActions.UPDATE_OFFSET, response.getInsertedAt());

        if (activeQuestionStore.question.getId() === responseQuestion.getId()) {
          /**
           * A user has answered the question, and therefore there are in a state where they always want to see the
           * explanation. There is a sister check (questionStore.userCanSeeExplanation() that determines whether or
           * not they are actually allowed to see the explanation.
           */
          callAction(activeQuestionActions.ACTIVE_QUESTION_VIEW_QUESTION, {
            question: responseQuestion,
            assignmentId,
            questionStoreName
          });
        }

        callTargetedAction({
          name: activeQuestionActions.ACTIVE_QUESTION_SET_SHOULD_SHOW_EXPLANATION,
          payload: true,
          targetStore: responseQuestionStoreName
        });

        /**
         * We invalidate the user_v2 query here to refresh the points indicator.
         *
         * If we are NOT in an assignment, AND our guess is correct, we update the points
         * value being tracked in the user store. If we are in an assignment, we do not
         * do that but instead invalidate a user query being used to populate the points badge.
         *
         * In-assignment guess points_earned are controlled by the back end. Points are only reflected
         * when the assignment is finished.
         *
         * ALBERT-9420
         */
        if (!this.props.assignmentId && response.isCorrect()) {
          callAction(activeQuestionActions.USER_EARNED_POINTS, response.getPointsEarned());
        } else if (this.props.isLastAssignmentQuestion) {
          invalidatePartialInterest({resourcePath: ['users_v2', sessionStore.getUserId()]});
        }

        if (assignmentId) {
          if (this.props.isLastAssignmentQuestion && !this.props.isPracticeExam) {
            callAction(studentAssignmentQuestionsListActions.RESET_STORE);
            callAction(questionsListActions.CLEAR_ACTIVE_QUESTION);
            invalidateBlockedAssignmentQuestionSetsQuery();
          }

          if (this.props.isPracticeExam) {
            callAction(assignmentV2IEActions.PRACTICE_EXAM_GUESS_PLACED, response);
          }
        }

        if (!this.props.shouldShowExplanationAfterGuessIsSubmitted) {
          callAction(questionsListActions.NEXT_QUESTION);
        }
      })
      .catch((error) => {
        if (error.status === 403) {
          callAction(upgradeCallToActionModalActions.SET_ACTIVE_SUBJECT_ID, subjectId);
          showModal('UpgradeCallToAction');
        } else if (Map.isMap(error) && error.get('detail')) {
          addToast({
            color: 'negative',
            title: 'Error!',
            message: error.get('detail')
          });
        } else {
          addGenericErrorToast();
          // This might be happening due to an error in the actual response processing
          notifier.notify(error, {
            component: 'ActiveQuestion.react',
            name: 'Error occurred with no response detail.'
          });
        }
      });
  }

  clearGuess = () => {
    callTargetedAction({
      name: activeQuestionActions.ACTIVE_QUESTION_CLEAR_GUESS,
      targetStore: this.props.questionStoreName
    });
    callTargetedAction({
      name: previousAttemptsActions.SET_ACTIVE_ATTEMPT_ID,
      payload: '',
      targetStore: this.getTooltipStoreName()
    });
    callTargetedAction({
      name: activeQuestionActions.ACTIVE_QUESTION_SET_SHOULD_SHOW_EXPLANATION,
      payload: false,
      targetStore: this.props.questionStoreName
    });
  };

  getTooltipStoreName() {
    return `ActiveQuestionPreviousAttempts${activeQuestionStore.question.getId()}`;
  }

  generateUpgradeButtonContent() {
    /**
     * @todo should be moved into it's own component as we use
     * same logic and representation inside of the UpgradeCallToActionModal
     * leaving it like that for now.
     */

    const subjectId = activeQuestionStore.questionSet.getSubjectId();
    const guessHistory = activeQuestionStore.question.getGuessHistory();
    const {isReady, value} = hasSubjectAccess(subjectId);
    const isRetry = !guessHistory.isEmpty();

    const {questionStoreName} = this.props;
    const questionStore = getStoreByName(questionStoreName);
    const isGuessSubmitted = questionStore ? questionStore.hasResponse : false;
    const {isAssignmentOverdue} = this.props;
    const isInActiveAssignment = sessionStore.isStudent() && Boolean(this.props.assignmentId);

    if (!isReady) {
      return null;
    }
    if (!isGuessSubmitted && !isAssignmentOverdue && !isInActiveAssignment && !value && isRetry) {
      const subject = activeQuestionStore.getSubject();

      return (
        <Link
          className='active-question-button active-question-button--green'
          to={`/marketplace/subjects?&subject=${subject.getUrlSlug()}`}
        >
          Upgrade to answer again
        </Link>
      );
    }
    return null;
  }

  openQuestionTrackerModal() {
    showModal('questionTracker');
  }

  setQuestionTrackerQuestionSetId(id) {
    callAction(questionTrackerActions.SET_QUESTION_SET_ID, id);
  }

  toggleShowExplanation = () => {
    callTargetedAction({
      name: activeQuestionActions.ACTIVE_QUESTION_TOGGLE_SHOW_EXPLANATION,
      targetStore: this.props.questionStoreName
    });

    if (this.props.shouldShowExplanation) {
      this.clearGuess();
    }
  };

  handleShowHideExplanation = () => {
    const {questionStoreName} = this.props;
    const questionStore = getStoreByName(questionStoreName);
    const subjectId = activeQuestionStore.questionSet.getSubjectId();
    /**
     * This is _kind of_ error-prone. The only reason we "get away" with it
     * is based on `hasSubjectAccess` being called initially in the `render` (~L513)
     */
    const {value} = hasSubjectAccess(subjectId);
    const userCanSeeExplanation = questionStore
      ? questionStore.userCanSeeExplanation(value)
      : false;
    if (!userCanSeeExplanation) {
      callAction(upgradeCallToActionModalActions.SET_ACTIVE_SUBJECT_ID, subjectId);
      showModal('UpgradeCallToAction');
    } else {
      callTargetedAction({
        name: activeQuestionActions.ACTIVE_QUESTION_LOAD_GUESS,
        payload: questionStore.buildCorrectGuess(this.props.question),
        targetStore: questionStoreName
      });

      /**
       * Because this event is called onClick, if this.props.shouldShowExplanation is true,
       * we should toggle it to false, so we'll jump to the top of the page and then hide the explanation.
       */
      if (this.props.shouldShowExplanation) {
        global.scroll(0, 0);
        this.toggleShowExplanation();
      } else {
        this.toggleShowExplanation();
      }
    }
  };

  render() {
    const {question} = activeQuestionStore;
    const {questionSet} = activeQuestionStore;
    const subject = questionSet.getSubject();
    const questionId = question.getId();
    const questionSetId = questionSet.getId();
    const questionType = question.getType();
    const {questionStoreName} = this.props;
    const questionStore = getStoreByName(questionStoreName);
    const {assignmentId} = this.props;
    if (!questionStore || (assignmentId && !questionStore.hasReceivedGuessHistory(assignmentId))) {
      return <LoadingIndicator />;
    }
    const validQuestionType = getQuestionTypeDefinition(questionType);
    const hasUserSelectedAnswer = questionStore && questionStore.hasUserSelectedAnswer;
    const isInActiveAssignment =
      sessionStore.isStudent() && question.isQuestionInActiveAssignment() && !assignmentId;
    const {isAssignmentOverdue} = this.props;
    const {isAssignmentSubmitted} = this.props;

    let isGuessSubmitted = false;

    if (assignmentId && this.props.question.hasGuessForAssignment(assignmentId)) {
      isGuessSubmitted = true;
    } else {
      isGuessSubmitted = questionStore ? questionStore.hasResponse : false;
    }

    const isSubmitButtonDisabled =
      hasUserSelectedAnswer === false ||
      questionStore.isGuessSubmissionPending ||
      isGuessSubmitted ||
      /**
       * If the assignment is submitted or overdue, "Submit Answer" is disabled.
       */
      isAssignmentSubmitted ||
      isAssignmentOverdue;

    const submitButtonDisabledReason = {
      [true]: '',
      [isGuessSubmitted]: `You've already submitted a guess for this question.`,
      [isAssignmentOverdue]: `This assignment is overdue. You can no longer place guesses.`,
      [isAssignmentSubmitted]: `You've submitted this assignment. You can no longer place guesses.`
    }.true;

    const {shouldShowExplanation} = this.props;
    const subjectId = questionSet.getSubjectId();
    const {isReady, value} = hasSubjectAccess(subjectId);
    const userCanSeeExplanation =
      isReady && questionStore ? questionStore.userCanSeeExplanation(value) : false;
    let questionContent = null;
    let explanationContent = null;
    const isFree = questionSet.isFree();
    const isFreeLabel = isFree && this.props.showFreeLabel ? <AccessLabel /> : null;

    if (!validQuestionType.isEmpty()) {
      const QuestionRenderer = validQuestionType.get('questionRenderer');
      const ExplanationRenderer = validQuestionType.get('explanationRenderer');
      const ResponseStatsRenderer = validQuestionType.get('responseStatsRenderer');
      const isCorrect =
        !this.props.isUnsubmittedAssignmentQuestion && questionStore
          ? questionStore.isGuessCorrect()
          : false;
      const solutionText =
        question.getSolutionText() || (questionStore && questionStore.solutionText) || '';
      const videoExplanationId = question.getVideoExplanationId();
      const selectedAnswer = questionStore ? questionStore.selectedAnswer : null;
      const subjectSlug = subject.getUrlSlug();
      const showLabelTable = subject.isShouldShowLabelTable();

      let correctAnswer;
      if (!question.getValidResponse().isEmpty()) {
        correctAnswer = question.getValidResponse();
      } else if (questionStore && questionStore.validResponse) {
        correctAnswer = questionStore.validResponse;
      }
      const statsContent = (
        <ResponseStatsRenderer
          key={questionStoreName}
          question={question}
          correctAnswer={correctAnswer}
          selectedAnswer={selectedAnswer}
          storeName={questionStoreName}
        />
      );
      questionContent = !isInActiveAssignment ? (
        <QuestionInteractionHandler shouldBlock={questionStore.isGuessSubmissionPending}>
          <QuestionRenderer
            key={questionStoreName}
            questionId={questionId}
            question={question}
            storeName={questionStoreName}
            isGuessSubmitted={isGuessSubmitted && shouldShowExplanation}
            isCorrect={isCorrect}
          />
        </QuestionInteractionHandler>
      ) : (
        <RestrictedQuestionMessage />
      );

      explanationContent =
        userCanSeeExplanation && shouldShowExplanation ? (
          <ExplanationRenderer
            key={questionStoreName}
            question={question}
            questionStoreName={questionStoreName}
            isCorrect={isCorrect}
            questionId={questionId}
            questionSetId={questionSetId}
            responseStats={statsContent}
            showLabelTable={showLabelTable}
            solutionText={solutionText}
            subjectId={subjectId}
            subjectSlug={subjectSlug}
            videoExplanationId={videoExplanationId}
            isUnsubmittedAssignmentQuestion={this.props.isUnsubmittedAssignmentQuestion}
          />
        ) : null;
    }
    const viewQuestionTrackerButton = interactionEngineStore.isTeacherModeEnabled() ? (
      <button
        type='button'
        className='unbutton active-question-button'
        onClick={this.openQuestionTrackerModal}
      >
        My usage
      </button>
    ) : null;
    const editButtonContent = this.props.canEditQuestion ? (
      <Link
        className='active-question-button'
        to={getQuestionEditPath({
          questionSetId: questionSet.getId(),
          questionId: question.getId(),
          customSearch: {
            status: 'published',
            subject: questionSet.getSubject().getId(),
            page: questionSet.getId(),
            fallback_page: 1
          }
        })}
      >
        Edit
      </Link>
    ) : null;
    const viewExplanationButton =
      !interactionEngineStore.isPracticeExamEditorSession &&
      !this.props.isPracticeExam &&
      interactionEngineStore.isTeacherModeEnabled() ? (
        <button
          type='button'
          className='unbutton active-question-button active-question-button--blue-medium'
          onClick={this.handleShowHideExplanation}
        >
          {shouldShowExplanation ? 'Hide' : 'Reveal'} solution
        </button>
      ) : null;
    /**
     * The "Submit Answer" button is hidden when we're in the Practice Exam editior,
     * the active question is in an active assignment, we're showing the explanation,
     * or the user needs to upgrade to answer.
     */
    const shouldShowSubmitButton =
      !this.props.hideSubmitButton &&
      !this.generateUpgradeButtonContent() &&
      !isInActiveAssignment &&
      explanationContent === null;

    let submitButtonContent = null;
    if (shouldShowSubmitButton) {
      const submitButtonCopy =
        /**
         * @todo this should be replaced with `getRubricType() === 'markAsComplete'` or something similar.
         */
        activeQuestionStore.question.getType() === 'free-response'
          ? 'Mark as completed'
          : 'Submit answer';

      submitButtonContent = (
        <Button
          color='green'
          className='unbutton active-question-button active-question-button--green'
          disabled={isSubmitButtonDisabled}
          disabledTooltipContent={submitButtonDisabledReason || ''}
          onClick={(e) => {
            this.submitAnswer();
            e.target.blur();
          }}
        >
          {submitButtonCopy}
        </Button>
      );
    }

    let goToAssignmentButtonContent = null;
    if (isInActiveAssignment) {
      if (!activeQuestionStore.getActiveQuestionSetAssignmentQuery().isResourceReady()) {
        goToAssignmentButtonContent = <LoadingIndicator inline />;
      } else {
        goToAssignmentButtonContent = activeQuestionStore.getActiveQuestionSetAssignmentId() ? (
          <Link
            className='active-question-button active-question-button--green'
            to={`/assignment/${activeQuestionStore.getActiveQuestionSetAssignmentId()}`}
          >
            Go to Assignment
          </Link>
        ) : (
          ''
        );
      }
    }
    const hideTitleAndDifficulty = this.props.isPracticeExam && !this.props.shouldShowExplanation;

    return (
      <div className='active-question-wrapper'>
        {this.props.topBanner}
        <div className='active-question-wrapper__question'>
          <ActiveQuestionHeader
            showPrevButton={this.props.showPrevButton}
            showNextButton={this.props.showNextButton}
            showGuessHistory={this.props.showGuessHistory}
            isInActiveAssignment={isInActiveAssignment}
            storeName={this.getTooltipStoreName()}
          />
          <div className='active-question-content'>
            <div className='active-question-content__meta'>
              <h1 className='active-question-content__meta-title'>
                {hideTitleAndDifficulty ? null : <MarkdownRendererV2 text={question.getTitle()} />}
              </h1>
              {isFreeLabel}
              {question.getQuestionSubType() ? (
                <div className='active-question-content__meta-question-sub-type'>
                  {
                    /**
                     * this is basically the same code found in `FreeResponseQuestionSubTypeBadge`
                     */
                    startCase(question.getQuestionSubType().replace('_question', ''))
                  }
                </div>
              ) : null}
              {hideTitleAndDifficulty ? null : (
                <QuestionDifficultyLabel difficulty={activeQuestionStore.getQuestionDifficulty()} />
              )}
            </div>
            <div className='active-question-content__prompt'>{questionContent}</div>
          </div>
          <div className='active-question-content__explanation'>{explanationContent}</div>
          <div className='active-question-footer'>
            <div className='active-question-footer__item'>
              {sessionStore.hasValidSession() ? (
                <span
                  className='active-question-report-issue-link'
                  role='button'
                  tabIndex='0'
                  onClick={this.openIssuesModal}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      this.openIssuesModal();
                    }
                  }}
                >
                  Submit Question Feedback
                </span>
              ) : null}
            </div>
            <div className='active-question-footer__item'>
              {viewQuestionTrackerButton}
              {viewExplanationButton}
              {editButtonContent}
              <FooterNavButtons
                showPrevButton={this.props.showPrevButton}
                showNextButton={this.props.showNextButton}
                isGuessSubmitted={isGuessSubmitted}
                isInActiveAssignment={isInActiveAssignment}
                clearGuessFunc={this.clearGuess}
              />
              {this.generateUpgradeButtonContent()}
              {submitButtonContent}
              {goToAssignmentButtonContent}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// eslint-disable-next-line react/prefer-stateless-function
class ActiveQuestionHeader extends React.Component {
  static propTypes = {
    showPrevButton: PropTypes.bool,
    showNextButton: PropTypes.bool,
    showGuessHistory: PropTypes.bool,
    isInActiveAssignment: PropTypes.bool,
    storeName: PropTypes.string.isRequired
  };

  render() {
    const guessHistory =
      this.props.showGuessHistory && !this.props.isInActiveAssignment ? (
        <PreviousAttempts
          showTooltip
          storeName={this.props.storeName}
          questionId={activeQuestionStore.question.getId()}
          onAttemptSelection={(attempt) => {
            const questionStoreName = interactionEngineStore.getStoreNameForQuestionId(
              activeQuestionStore.question.getId()
            );
            callTargetedAction({
              name: activeQuestionActions.ACTIVE_QUESTION_LOAD_GUESS,
              targetStore: questionStoreName,
              payload: attempt
            });
            callTargetedAction({
              name: activeQuestionActions.ACTIVE_QUESTION_SET_SHOULD_SHOW_EXPLANATION,
              targetStore: questionStoreName,
              payload: true
            });
          }}
        />
      ) : null;
    return (
      <div className='active-question-header'>
        {guessHistory}
        <div className='active-question-header__nav-buttons-wrapper'>
          <button
            type='button'
            disabled={!this.props.showPrevButton}
            onClick={() => callAction(questionsListActions.PREVIOUS_QUESTION)}
            className='active-question-header__nav-button unbutton'
          >
            Back
          </button>
          <button
            type='button'
            disabled={!this.props.showNextButton}
            onClick={() => callAction(questionsListActions.NEXT_QUESTION)}
            className='active-question-header__nav-button unbutton'
          >
            Next
          </button>
        </div>
      </div>
    );
  }
}

// eslint-disable-next-line react/prefer-stateless-function
class FooterNavButtons extends React.Component {
  static propTypes = {
    showPrevButton: PropTypes.bool,
    showNextButton: PropTypes.bool,
    isGuessSubmitted: PropTypes.bool,
    isInActiveAssignment: PropTypes.bool,
    clearGuessFunc: PropTypes.func
  };

  render() {
    if (!this.props.isGuessSubmitted) {
      return null;
    }
    return (
      <div className='active-question-footer__nav-controls'>
        <button
          type='button'
          className='unbutton active-question-button'
          disabled={!this.props.showPrevButton}
          onClick={(e) => {
            callAction(questionsListActions.PREVIOUS_QUESTION);
            e.target.blur();
          }}
        >
          <span className='fa fa-caret-left' /> Back
        </button>
        {this.props.isGuessSubmitted &&
        interactionEngineStore.isVanillaIESession &&
        !this.props.isInActiveAssignment ? (
          <button
            type='button'
            className='unbutton active-question-button'
            onClick={this.props.clearGuessFunc}
          >
            Try again
          </button>
        ) : null}
        <button
          type='button'
          className='unbutton active-question-button'
          disabled={!this.props.showNextButton}
          onClick={(e) => {
            callAction(questionsListActions.NEXT_QUESTION);
            e.target.blur();
          }}
        >
          Next <span className='fa fa-caret-right' />
        </button>
      </div>
    );
  }
}

export const ActiveQuestionLoading = () => (
  <div className='active-question-wrapper'>
    <LoadingIndicator />
  </div>
);

function QuestionInteractionHandler({children, shouldBlock}) {
  return (
    <div className='question-interaction-handler'>
      {children}
      <div
        className={classnames('question-interaction-handler__overlay', {
          'question-interaction-handler__overlay--active': shouldBlock
        })}
      />
    </div>
  );
}

QuestionInteractionHandler.propTypes = {
  children: PropTypes.node,
  shouldBlock: PropTypes.bool
};
