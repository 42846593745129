/**
 * # SearchInput
 *
 * A search input component with customizable props.
 *
 * ## Props extends React.InputHTMLAttributes
 *
 * - `className?: string`: Additional class name for the component.
 * - `defaultValue?: string`: The default value of the input.
 * - `disabled?: boolean`: Whether the input is disabled.
 * - `error?: boolean`: Whether the input has an error state.
 * - `onChange?: (e: React.ChangeEvent<HTMLInputElement>, value: string) => void`: Callback function triggered when the input value changes.
 * - `onReset?: (value: string) => void`: Callback function triggered when the input is reset.
 * - `onSubmit?: (value: string) => void`: Callback function triggered when the form is submitted.
 * - `rectangle?: boolean`: Whether the input has a rectangle style.
 * - `success?: boolean`: Whether the input has a success state.
 * - `size?: 's' | 'regular'`: The size of the input.
 * - `variant?: boolean`: Whether the input has a variant style.
 * - `...rest: React.InputHTMLAttributes`: See [MDN reference](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input) for valid input types.
 *
 * ## Usage
 *
 * ```tsx
 * import SearchInput from './SearchInput.react';
 *
 * function App() {
 *   const handleChange = (e, value) => {
 *     console.log('Input value:', value);
 *   };
 *
 *   const handleReset = (value) => {
 *     console.log('Input reset:', value);
 *   };
 *
 *   const handleSubmit = (value) => {
 *     console.log('Form submitted:', value);
 *   };
 *
 *   return (
 *     <SearchInput
 *       className="custom-search-input"
 *       defaultValue="Initial value"
 *       disabled={false}
 *       error={false}
 *       onChange={handleChange}
 *       onReset={handleReset}
 *       onSubmit={handleSubmit}
 *       success={false}
 *       size="regular"
 *       variant={false}
 *     />
 *   );
 * }
 *
 * export default App;
 * ```
 */
import React, {useEffect, useState, InputHTMLAttributes} from 'react';
import classnames from 'classnames';

import makeConstants from 'lib/makeConstants';

import WithHoverAndFocus from '../../helpers/WithHoverAndFocus/WithHoverAndFocus.react';
import Input from '../../atoms/Input/Input.react';
import Icon from '../../atoms/Icon/Icon.react';

import IconButton from '../IconButton/IconButton.react';

import './search-input.scss';

const sizes = makeConstants('s', 'regular');

export interface Props
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'onReset' | 'onSubmit' | 'size'>,
    PropsWithClassNameOptional {
  defaultValue?: string;
  disabled?: boolean;
  error?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, value: string) => void;
  onReset?: (value: string) => void;
  onSubmit?: (value: string) => void;
  rectangle?: boolean;
  success?: boolean;
  size?: 's' | 'regular';
  variant?: boolean;
}

function SearchInput({
  className,
  defaultValue = '',
  disabled,
  error,
  onChange = () => {},
  onReset = () => {},
  onSubmit = () => {},
  rectangle,
  success,
  size = 'regular',
  variant,
  ...rest
}: Props) {
  const [currentValue, setCurrentValue] = useState(defaultValue);

  useEffect(() => {
    setCurrentValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (e) => {
    e.persist();
    const {value} = e.target;
    setCurrentValue(value);
    onChange(e, value);
  };

  const handleReset = (e) => {
    e.preventDefault();
    setCurrentValue('');
    onReset(currentValue);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(currentValue);
  };

  return (
    <WithHoverAndFocus>
      {({hasFocus, onBlur, onFocus, onMouseEnter, onMouseLeave}) => (
        <form
          action='#' // This no-op action is required to display the "Search" button on mobile keyboards
          className={classnames('m-search-input__wrapper', className)}
          onSubmit={handleSubmit}
        >
          <Input
            className={classnames('m-search-input', {
              [`m-search-input--${sizes[size]}`]: sizes[size],
              'u-pad-l_3': size === 's' && !hasFocus && !currentValue, // this is needed so small search input button is square when not focused and empty
              'm-search-input--variant': variant,
              'm-search-input--variant-focus': variant && hasFocus,
              'm-search-input--rectangle': rectangle
            })}
            disabled={disabled}
            error={error}
            onChange={handleChange}
            success={success}
            type='search'
            onBlur={onBlur}
            onFocus={onFocus}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            value={currentValue}
            size={size}
            {...rest}
          />
          <Icon
            className={classnames('m-search-input__icon', {
              'u-color_blue-400': hasFocus
            })}
            color={(error && 'negative') || (success && 'positive') || 'inherit'}
            icon='search'
          />
          <IconButton
            color={(hasFocus && 'primary') || (error && 'danger') || (success && 'success')}
            className='m-search-input__icon-btn'
            icon='times'
            variant={!variant ? 'solid' : 'text'}
            size='s'
            label='Reset search'
            disabled={disabled || currentValue.length === 0}
            onClick={handleReset}
            type='button'
          />
        </form>
      )}
    </WithHoverAndFocus>
  );
}

export default SearchInput;
