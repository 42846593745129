import React, {useCallback} from 'react';
import {callAction} from 'client/framework';
import {Input, Text} from '@albert-io/atomic';

import {useShallow} from 'zustand/react/shallow';

import questionEditorActions from '../QuestionEditor/QuestionEditor.actions';
import questionEditorStore from '../QuestionEditor/QuestionEditor.store';
import {useQuestionEditorV2Store} from '../QuestionEditorV2Store';
import {PRIMARY_CONTENT_LANGUAGE} from '../QuestionEditorV2Store.types';

export const TitleSection = () => {
  const question = questionEditorStore.getQuestion();
  const errors = questionEditorStore.getValidationErrors();
  const error = errors.get('title');

  const {currentLanguage, translatedTitle, updateTranslatedQuestionField} =
    useQuestionEditorV2Store(
      useShallow((state) => ({
        currentLanguage: state.currentLanguage,
        updateTranslatedQuestionField: state.updateTranslatedQuestionField,
        translatedTitle:
          state
            .currentTranslatedQuestion()
            ?.translated_fields?.find((field) => field.field === 'title')?.text || ''
      }))
    );

  const handleTitleChange = useCallback(
    (e) => {
      if (currentLanguage === PRIMARY_CONTENT_LANGUAGE) {
        const val = e.target.value;
        callAction(questionEditorActions.UPDATE_QUESTION_DETAILS, {
          setter: 'setTitle',
          value: val
        });
        callAction(questionEditorActions.VALIDATE_QUESTION_FIELD, 'title');
      } else {
        updateTranslatedQuestionField(currentLanguage, 'title', 'text', e.target.value);
      }
    },
    [currentLanguage, updateTranslatedQuestionField]
  );

  const value =
    currentLanguage === PRIMARY_CONTENT_LANGUAGE ? question.get('title') : translatedTitle;

  return (
    <>
      <Input
        value={value}
        onChange={handleTitleChange}
        error={currentLanguage === PRIMARY_CONTENT_LANGUAGE && !!error}
      />
      {currentLanguage === PRIMARY_CONTENT_LANGUAGE && error && (
        <Text color='negative' size='s'>
          {error}
        </Text>
      )}
    </>
  );
};
