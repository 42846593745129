// @flow
import appStore from 'client/AppStore';
import {pick} from 'lodash';
import {getQueryStringForParams} from 'client/history';

export const searchCriteria = ['s', 'standards', 'tags'];

export const phrasesRegex = /(\w|\s)*\w(?=")/g;

export const phrasesAndTrailingSpacesRegex = /\s?"(\w|\s)*\w(?=").\s?/g;

export function parseSearchString(str: string): Object {
  const keywords = parseKeywordsFromSearchString(str);
  const phrases = parsePhrasesFromSearchString(str);
  const isSearchingForKeywords = Boolean(keywords);
  const isSearchingForPhrases = phrases.length > 0;

  return {
    isSearchingForKeywords,
    isSearchingForPhrases,
    keywords,
    phrases
  };
}

export function parseKeywordsFromSearchString(str: string): string {
  return str
    .replace(phrasesAndTrailingSpacesRegex, '')
    .split(' ')
    .filter((keyword) => keyword)
    .join(','); // Remove empty strings
}

export function parsePhrasesFromSearchString(str: string): Array<string> {
  return phrasesRegex.test(str) ? str.match(phrasesRegex) : [];
}

export function isSearchingByKey(key: string): boolean {
  return searchCriteria.includes(key) && appStore.routerProps.location.query[key];
}

export function isSearchMode(): boolean {
  const query = getSearchParams();
  return Object.keys(query).some(isSearchingByKey);
}

export function getSearchParams(): Object {
  return pick(appStore.routerProps.location.query, searchCriteria);
}

export function getIndexedSearchParams(): Object {
  const query = getSearchParams();
  return searchCriteria.reduce((acc, curr) => {
    acc[curr] = query[curr] ? query[curr].split(',') : [];
    return acc;
  }, {});
}

export function addSearchParamsToLink(link: string): string {
  const queryString = getQueryStringForParams(...searchCriteria);
  return `${link}${queryString ? '?' + queryString : ''}`;
}
