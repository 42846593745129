import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {callTargetedAction, setUpStore} from 'client/framework';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import TextHighlightPrompt from './TextHighlightPrompt.react';
import textHighlightQuestionActions from './TextHighlightQuestion.actions';
import TextHighlightQuestionStore from './TextHighlightQuestion.store';

import './text-highlight-question.scss';

export default class TextHighlightQuestion extends React.Component {
  static propTypes = {
    isCorrect: PropTypes.bool,
    isGuessSubmitted: PropTypes.bool,
    question: ImmutablePropTypes.map,
    questionId: PropTypes.string,
    storeName: PropTypes.string
  };

  static defaultProps = {
    storeName: 'textHighlightQuestionStore'
  };

  constructor(props) {
    super(props);
    const store = this.getStore();
    this.setInitialStoreData(store, props.question);
  }

  componentDidMount() {
    this.shouldClearSelection();
    if (this.props.isGuessSubmitted !== this.getStore().isGuessSubmitted) {
      this.setGuessSubmitted();
    }
  }

  UNSAFE_componentWillUpdate(nextProps) {
    if (nextProps.questionId !== this.props.questionId) {
      const nextStore = this.getStore(nextProps.storeName, nextProps.questionId);
      this.setInitialStoreData(nextStore, nextProps.question);
    }
  }

  componentDidUpdate() {
    this.shouldClearSelection();
    if (this.props.isGuessSubmitted !== this.getStore().isGuessSubmitted) {
      this.setGuessSubmitted();
    }
  }

  componentWillUnmount() {
    this.setGuessSubmitted();
  }

  getStore(storeName = this.props.storeName, questionId = this.props.questionId) {
    return setUpStore(TextHighlightQuestionStore, storeName, questionId);
  }

  setInitialStoreData(store, question) {
    callTargetedAction({
      name: textHighlightQuestionActions.SET_UP_QUESTION,
      payload: {
        highlightPrompt: question.getHighlightPrompt(),
        preferredPermutation: question.getPreferredPermutation()
      },
      targetStore: store.getName()
    });
  }

  shouldClearSelection() {
    if (this.getStore().isGuessSubmitted === true && this.props.isGuessSubmitted === false) {
      this.clearSelections();
    }
  }

  clearSelections() {
    callTargetedAction({
      name: textHighlightQuestionActions.CLEAR_SELECTIONS,
      targetStore: this.props.storeName
    });
  }

  setGuessSubmitted() {
    callTargetedAction({
      name: textHighlightQuestionActions.SET_GUESS_SUBMITTED,
      payload: this.props.isGuessSubmitted,
      targetStore: this.props.storeName
    });
  }

  render() {
    const question = this.props.question;
    return (
      <div className='text-highlight-question'>
        <MarkdownRendererV2 className='question-prompt' text={question.getPrompt()} />
        <h2>Highlight Answer(s) Below</h2>
        <TextHighlightPrompt storeName={this.props.storeName} isCorrect={this.props.isCorrect} />
      </div>
    );
  }
}
