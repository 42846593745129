import React, {PropsWithChildren} from 'react';

import {Button} from '@albert-io/atomic';

interface Props {
  className?: string;
  disabled: boolean;
  label?: string;
  onClick: () => void;
}

export default function Next({
  children,
  className,
  disabled,
  label = 'Skip For Now',
  onClick,
  ...rest
}: PropsWithChildren<Props>) {
  return (
    <Button
      className={className}
      disabled={disabled}
      onClick={onClick}
      type='button'
      variant='outlined'
      {...rest}
    >
      {children ?? label}
    </Button>
  );
}
