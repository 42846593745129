import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {Button, Dialogue, TextField, Modal, addToast} from '@albert-io/atomic';
import {resource} from '@albert-io/json-api-framework/request/builder';

import {TemplateModelV1} from 'resources/GeneratedModels/Template/TemplateModel.v1';
import {invalidatePartialInterest} from 'resources/mandark.resource';
import {invalidateFilters} from 'components/PracticeView/PracticeViewToolbar/Filters/filters.utils';
import sessionStore from 'client/Session/SessionStore';

export default class NewFolderModal extends React.Component {
  static propTypes = {
    handleClose: PropTypes.func,
    onSaveSuccess: PropTypes.func
  };

  static defaultProps = {
    onSaveSuccess: () => {}
  };

  constructor() {
    super();

    this.state = {
      newFolderName: null,
      hasError: false,
      errorMessage: ''
    };
  }

  folderNameInputHandler = (e) => {
    this.setState({
      newFolderName: e.target.value
    });
  };

  resetErrorMessage = () => {
    this.setState({
      hasError: false,
      errorMessage: ''
    });
  };

  /**
   * Creates a new folder and invalidates the resource to force auto update
   */
  handleSave = async () => {
    const folderName = this.state.newFolderName;
    if (!folderName?.trim()) {
      this.setState({hasError: true, errorMessage: "Folder name can't be blank."});
      return;
    }

    if (folderName.length > 256) {
      this.setState({
        hasError: true,
        errorMessage: 'Folder name must be 256 characters or less.'
      });
      return;
    }

    try {
      await new TemplateModelV1()
        .setName(folderName)
        .addRelationship({
          type: 'teacher_v1',
          relation: sessionStore.getUserId()
        })
        .save();

      invalidatePartialInterest(resource('templates_v1').mandarkEndpoint(['templates_v1']).done());
      invalidateFilters();
      this.props.onSaveSuccess();
    } catch (e) {
      addToast({
        color: 'negative',
        title: 'Error:',
        message: 'Something went wrong and your folder was not created'
      });
    }
    this.props.handleClose();
  };

  render() {
    return (
      <Modal ariaLabel='Create New Folder Modal' handleClose={this.props.handleClose}>
        {({CloseButtonWrapper, modalContentStyle}) => {
          return (
            <Dialogue
              inModal
              hideCloseBtn
              handleClose={this.props.handleClose}
              className={classnames(modalContentStyle, 'folders-modal__new-folder-modal')}
            >
              <Dialogue.Body>
                <TextField
                  border
                  label='Enter a new folder name:'
                  error={this.state.hasError}
                  message={this.state.errorMessage}
                  onChange={this.folderNameInputHandler}
                  value={this.state.newFolderName}
                  placeholder='Type folder name here...'
                  onFocus={this.resetErrorMessage}
                />
              </Dialogue.Body>
              <Dialogue.BtnGroup>
                <CloseButtonWrapper flex>
                  <Button variant='text'>Cancel</Button>
                </CloseButtonWrapper>
                <Button onClick={this.handleSave}>Create</Button>
              </Dialogue.BtnGroup>
            </Dialogue>
          );
        }}
      </Modal>
    );
  }
}
