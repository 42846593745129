// @flow
import {type List, Map} from 'immutable';

export const templateModelExtensions = {
  getFirstSetAndQuestion(): Map<{
    question: List<*>,
    set: Map<string, *>
  }> | null {
    const set = this.getOrderedQuestionSets().first();
    return Map({
      question: set.getQuestions().first(),
      set
    });
  },
  /**
   * Assignment question_sets ordered by position (cached).
   */
  getOrderedQuestionSets(): List<*> {
    return this.cache('ordered_question_sets', () => {
      return this.getQuestionSets().sortBy((set) => {
        return set.getTemplateRelationships(this.getId()).getPosition();
      });
    });
  },

  /**
   * @todo this should be replaced with `this.getMeta().getCountOfQuestions()` when available.
   * @see {@link https://albert-io.atlassian.net/browse/ALBERT-7439}
   */
  getTotalQuestions(): number {
    return this.cache('total_questions', () => {
      return this.getQuestionSets().reduce((total, set) => total + set.getQuestions().size, 0);
    });
  }
};
