import {fromJS, Record, List} from 'immutable';

import sessionStore from 'client/Session/SessionStore';
import constants from 'client/constants';
import {IconProp} from '@albert-io/atomic/atoms/Icon/Icon.react';

export interface IFooterItemData {
  label: string;
  to?: string;
  href?: string;
  icon?: IconProp;
  onClick?: () => void;
}

const FooterItemDefaults: IFooterItemData = {
  label: '',
  to: undefined,
  href: undefined,
  icon: undefined
};

export class FooterItemData extends Record(FooterItemDefaults) {
  get<T extends keyof IFooterItemData>(value: T): IFooterItemData[T] {
    return super.get(value);
  }
}

export interface IFooterColumnData {
  title: string;
  items: List<FooterItemData>;
}

const FooterColumnDefaults = {
  title: '',
  items: List<FooterItemData>()
};

export class FooterColumnData extends Record(FooterColumnDefaults) {
  get<T extends keyof IFooterColumnData>(value: T): IFooterColumnData[T] {
    return super.get(value);
  }
}

export interface IFooterData {
  columns: List<FooterColumnData>;
}

const FooterDataDefaults: IFooterData = {
  columns: List()
};

export class FooterData extends Record(FooterDataDefaults) {
  constructor(rawFooterData) {
    super({
      columns: fromJS(rawFooterData).map((column) => {
        return new FooterColumnData(column);
      })
    });
  }

  get<T extends keyof IFooterData>(value: T): IFooterData[T] {
    return super.get(value);
  }
}

const rawFooterData = [
  {
    key: 'get-started',
    title: 'Get Started',
    items: [
      {
        label: 'Sign Up',
        to: '/signup'
      },
      {
        label: 'Course Library',
        to: '/subjects'
      },
      {
        label: 'Schedule a Demo',
        href: 'https://albert-licenses.typeform.com/to/Yv8f75Vi?utm_source=site-footer'
      }
    ]
  },
  {
    title: 'Company',
    items: [
      {
        label: 'About Us',
        href: '/about-our-team'
      },
      {
        label: 'Careers',
        href: 'https://careers.albert.io'
      },
      {
        label: 'Help Center',
        href: 'https://help.albert.io'
      },
      {
        label: 'Blog',
        href: '/blog'
      },
      {
        label: 'Privacy Policy',
        href: '/privacy'
      },
      {
        label: 'Terms of Use',
        href: '/terms-of-use'
      }
    ]
  },
  {
    title: 'Solutions',
    items: [
      {
        label: 'ELA',
        href: '/solutions-ela'
      },
      {
        label: 'Math',
        href: '/solutions-math'
      },
      {
        label: 'Science',
        href: '/solutions-science'
      },
      {
        label: 'Advanced Placement®',
        href: '/solutions-ap'
      },
      {
        label: 'College Entrance',
        href: '/solutions-college-entrance'
      }
    ]
  },
  {
    title: 'Product',
    items: [
      {
        label: 'Teachers',
        href: '/solutions-teachers'
      },
      {
        label: 'Admins',
        href: '/solutions-admins'
      },
      {
        label: 'Results',
        href: '/results'
      },
      {
        label: 'Technical Requirements',
        to: '/software-considerations'
      }
    ]
  },
  {
    title: 'Contact us',
    items: [
      {
        title: 'Support'
      },
      {
        label: 'Help Center',
        icon: 'headset',
        href: 'https://help.albert.io'
      },
      {
        label: constants.CONTACT.EMAIL.SUPPORT,
        icon: 'envelope',
        href: `mailto:${constants.CONTACT.EMAIL.SUPPORT}`
      },
      {
        title: 'Sales'
      },
      {
        label: constants.CONTACT.EMAIL.SALES,
        icon: 'envelope',
        href: `mailto:${constants.CONTACT.EMAIL.SALES}`
      }
    ]
  }
];

export const getFooterData = (): FooterData => {
  const footerData = new FooterData(rawFooterData);
  if (sessionStore.hasValidSession()) return footerData;

  return footerData.updateIn(['columns', 0, 'items'], (items) => {
    const newItems = items.unshift(
      new FooterItemData({
        label: 'Log In',
        to: '/log-in'
      })
    );
    return newItems;
  });
};
