import React from 'react';
import PropTypes from 'prop-types';
import {setUpStore} from 'client/framework';
import {getModelForResourceType} from 'resources/modelRegistry';
import {Fieldset} from '@albert-io/atomic';

import FreeEntryQuestionStore from './FreeEntryQuestion.store';
import FreeEntryQuestionInput from './FreeEntryQuestionInput.react';

import './free-entry-question.scss';

export default class FreeEntryInputArea extends React.Component {
  static propTypes = {
    isGuessSubmitted: PropTypes.bool,
    guess: PropTypes.instanceOf(getModelForResourceType('guess_v1')),
    storeName: PropTypes.string,
    question: PropTypes.instanceOf(getModelForResourceType('question_v3'))
  };

  static defaultProps = {
    storeName: 'freeEntryQuestionStore'
  };

  getStore() {
    return setUpStore(FreeEntryQuestionStore, this.props.storeName, this.props.question.getId());
  }

  isInputCorrect({validResponse, type, threshold, inputId, value = ''}) {
    if (!validResponse || validResponse.isEmpty()) {
      return false;
    }

    const correctOptions = validResponse.get(inputId);

    if (type === 'contains') {
      return correctOptions
        .map((option) => option.toLowerCase())
        .includes(value.toLowerCase().trim());
    }

    // If the type is not 'contains', it is safe to assume it's a 'mathContains' input

    if (threshold) {
      const parsedVal = parseFloat(value, 10);
      const parsedThreshold = parseFloat(threshold, 10);

      return correctOptions.some((option) => {
        const parsedOption = parseFloat(option, 10);
        if (parsedOption === parsedVal) {
          return true;
        }
        const leeway = (parsedOption / 100) * parsedThreshold;
        return Math.abs(parsedOption - parsedVal) <= Math.abs(leeway);
      });
    }

    return correctOptions.includes(value.trim());
  }

  makeInputKey(inputId) {
    let key = inputId;
    if (this.props.guess) {
      key += this.props.guess.getId();
    }
    return key;
  }

  makeInputs = (input) => {
    const store = this.getStore();
    const inputId = input.getId();
    const value = store.getValueForId(input.getId());
    const rubric = this.props.isGuessSubmitted
      ? this.props.question.getMeta().getQuestionRubric()
      : null;
    const validResponse = rubric ? rubric.get('validResponse') : null;
    const threshold = rubric ? rubric.get('threshold') : null;
    const type = rubric ? rubric.get('type') : null;
    const isMarkedCorrect = this.isInputCorrect({validResponse, type, threshold, inputId, value});
    return (
      <FreeEntryQuestionInput
        key={this.makeInputKey(inputId)}
        inputId={inputId}
        inputPrompt={input.getText()}
        isGuessSubmitted={this.props.isGuessSubmitted}
        isMarkedCorrect={isMarkedCorrect}
        question={this.props.question}
        storeName={this.props.storeName}
        value={value}
      />
    );
  };

  render() {
    const {question} = this.props;
    return (
      <div className='free-entry-question__inputs-wrapper'>
        <Fieldset>{question.getInputs().map(this.makeInputs)}</Fieldset>
      </div>
    );
  }
}
