/* eslint-disable import/no-cycle */
import {extend, map, assign} from 'lodash';
import {Set} from 'immutable';

import {getHost} from '@albert-io/environment';

import {ExternalAPI} from '../resource';

import {resources as AssignmentResources} from './Assignments/Assignments';
import {resources as StudentResources} from './Students/Students';

let _MANDARK_API = null;

export function initializeMandarkAPI(errorHandler = () => {}) {
  const location = getHost();
  if (_MANDARK_API) {
    console.error('Duplicate initialization! MandarkAPI has already been initialized!');
    return _MANDARK_API;
  }
  _MANDARK_API = new ExternalAPI({
    name: '_MANDARK_API',
    location,
    errorHandler
  });

  _MANDARK_API.setDefaultHeader({
    'Content-Type': 'application/vnd.api+json',
    Accept: 'application/vnd.api+json'
  });

  const resources = extend(AssignmentResources, StudentResources);

  map(resources, (config, name) => {
    _MANDARK_API.addResource(
      assign(config, {
        name
      })
    );
  });
  return _MANDARK_API;
}

export function getMandarkAPI() {
  return _MANDARK_API;
}

export function buildIncludes(versions) {
  return versions.reduce((set, version) => set.add(version), new Set()).join(',');
}
