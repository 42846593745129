import qs from 'qs';
import {browserHistory, createMemoryHistory} from 'react-router';

/**
 * The `serverRedirectRoute` is used by isomorphic renderer to allow `301` at the
 * server level rather than just a client-side redirect.
 *
 * @memberOf Server.Isomorphism
 */
let serverRedirectRoute = null;

/**
 * We add a few methods to the process `history` object. This let's us catch things like push/replace
 * server-side for redirects.
 *
 * This also serves as a facade to some legacy callers using this module (`pushState`, `replaceState`)
 *
 * @param {Object} history The `history` object we'll be extending with our custom methods.
 */
function extendHistory(history) {
  return Object.assign({}, history, {
    pushState: function (state, path) {
      if (process.env.IS_BROWSER === false) {
        setServerRedirectRoute(state, path);
      }
      history.push(path, state);
    },
    replaceState: function (state, path) {
      if (process.env.IS_BROWSER === false) {
        setServerRedirectRoute(state, path);
      }
      history.push(path, state);
    }
  });
}

export let history;
/**
 * Based on the execution environment we either use `browserHistory` which will
 * update the URL accordingly or an in-memory history.
 */
if (process.env.IS_BROWSER) {
  history = extendHistory(browserHistory);
} else {
  history = extendHistory(
    createMemoryHistory({
      /**
       * If we don't set `initialEntries` it will default to `["/"]` which
       * doesn't quite make sense for an isomorphic render.
       */
      initialEntries: []
    })
  );
}

/**
 * This function provides a way to reset the `history` singleton
 * back to the initial state _for the server_. This is only useful for
 * isomorphic rendering, and shouldn't be used outside that context.
 *
 * It will also reset the `serverRedirectRoute` to `null`.
 *
 * @memberOf Server.Isomorphism
 */
export function resetServerHistory() {
  history = extendHistory(
    createMemoryHistory({
      initialEntries: []
    })
  );
  serverRedirectRoute = null;
}
function setServerRedirectRoute(state, path) {
  serverRedirectRoute = {state, path};
}

/**
 * Retrieve the current `serverRedirectRoute.path` for redirecting.
 * @memberOf Server.Isomorphism
 */
export function getServerRedirectRoute() {
  return serverRedirectRoute && serverRedirectRoute.path;
}

const modifyQueryParams = (replaceQuery = false) => {
  return (queryObj = {}) =>
    new Promise((res) => {
      setTimeout(() => {
        if (!process.env.IS_BROWSER) {
          return;
        }

        const location = history.getCurrentLocation();
        let currentAsString = location.search;
        currentAsString = currentAsString ? currentAsString.slice(1) : '';
        const currentAsObj = qs.parse(currentAsString);
        const newQuery = replaceQuery ? queryObj : {...currentAsObj, ...queryObj};
        const filteredQuery = Object.keys(newQuery).reduce((r, key) => {
          if (newQuery[key] !== null) {
            r[key] = newQuery[key];
          }
          return r;
        }, {});
        const queryString = qs.stringify(filteredQuery);
        history.push({
          pathname: location.pathname,
          search: queryString ? `?${queryString}` : ''
        });
        res();
      }, 0);
    });
};

/**
 * The difference between these two methods is whether or not the current query params will be kept. pushQueryParams
 * will merge the new query object with the current query params, while replaceQueryParams will, as its name suggests,
 * replace the current query params with the ones passed to it.
 *
 * To remove a query param, set its value to null - e.g. pushQueryParams({someVal: 123, someValToRemove null});
 */
export const pushQueryParams = modifyQueryParams();
export const replaceQueryParams = modifyQueryParams(true);

/**
 * Lets you selectively remove query params from current path by name. E.g. if you are at ?foo=123&bar=hello,
 * calling removeQueryParams('foo') will replace state to ?bar=hello
 */
export function removeQueryParams(...params) {
  setTimeout(() => {
    if (!process.env.IS_BROWSER || params.some((param) => typeof param !== 'string')) {
      logger.debug(
        'removeQueryParams function is bailing early as its required conditions were not met'
      );
      return;
    }

    const currentPath = history.getCurrentLocation().pathname;
    const currentQuery = history.getCurrentLocation().query || {};
    const filteredQuery = Object.keys(currentQuery).reduce((r, property) => {
      if (!params.includes(property)) {
        r[property] = currentQuery[property];
      }
      return r;
    }, {});

    history.replace({
      pathname: currentPath,
      search: params.length > 0 ? '?' + qs.stringify(filteredQuery) : ''
    });
  }, 0);
}

export function getQueryParamsAsObject() {
  return qs.parse(history.getCurrentLocation().search.replace(/^\?/, ''));
}

export function getQueryStringForParams(...params) {
  const currentParams = getQueryParamsAsObject();
  const matches = params
    .filter((param) => currentParams.hasOwnProperty(param))
    .reduce((acc, param) => {
      acc[param] = currentParams[param];
      return acc;
    }, {});
  return qs.stringify(matches);
}
