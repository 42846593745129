/**
 * # TextField
 *
 * A text input field component.
 *
 * ## Props
 *
 * - `placeholder: string`: The placeholder text for the input field.
 * - `type: 'email' | 'hidden' | 'password' | 'search' | 'tel' | 'text' | 'url'` Input types for the TextField.
 *
 * ## Usage
 *
 * ```jsx
 * import TextField from './TextField.react';
 *
 * function MyForm() {
 *   return (
 *     <form>
 *       <TextField placeholder="Enter your email" type="email" />
 *     </form>
 *   );
 * }
 * ```
 */
import React, {forwardRef} from 'react';
import PropTypes from 'prop-types';
import Input from '@albert-io/atomic/atoms/Input/Input.react';

import Field from '../Field/Field.react';

const TextField = forwardRef((props, ref) => <Field ref={ref} as={Input} {...props} />);

TextField.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.oneOf(['email', 'hidden', 'password', 'search', 'tel', 'text', 'url'])
};

TextField.defaultProps = {
  type: 'text'
};

export default TextField;
