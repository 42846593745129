import React from 'react';
import PropTypes from 'prop-types';
import {Card} from '@albert-io/atomic';
import {getModelForResourceType} from 'resources/modelRegistry';
import './question-wrapper.scss';

const QuestionWrapper = ({
  heading,
  children,
  question,
  questionSet,
  guess,
  navigateBackProps,
  navigateNextProps,
  headingActions,
  customHeader,
  subject,
  modifyTitle,
  showDifficulty,
  hasQuestionSetAccessPredicate
}) => {
  const passedProps = {
    question,
    questionSet,
    guess,
    navigateBackProps,
    navigateNextProps,
    subject,
    hasAccess: hasQuestionSetAccessPredicate?.(questionSet) || false
  };
  return (
    <Card
      as='form'
      className='question-wrapper'
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      {heading && (
        <div className='question-wrapper__heading'>
          {React.cloneElement(heading, {
            ...passedProps,
            headingActions,
            modifyTitle,
            showDifficulty,
            customHeader
          })}
        </div>
      )}
      <div className='question-wrapper__body'>
        {React.Children.map(children, (child) => {
          if (!child) {
            return null;
          }
          let props;
          if (typeof child.type === 'function') {
            props = passedProps;
          }
          return React.cloneElement(child, props);
        })}
      </div>
    </Card>
  );
};

QuestionWrapper.propTypes = {
  heading: PropTypes.node,
  children: PropTypes.node,
  question: PropTypes.instanceOf(getModelForResourceType('question_v3')),
  questionSet: PropTypes.instanceOf(getModelForResourceType('question_set_v1')),
  guess: PropTypes.instanceOf(getModelForResourceType('guess_v1')),
  navigateBackProps: PropTypes.shape({
    disabled: PropTypes.bool,
    handleNavigation: PropTypes.func
  }),
  navigateNextProps: PropTypes.shape({
    disabled: PropTypes.bool,
    handleNavigation: PropTypes.func
  }),
  headingActions: PropTypes.node,
  customHeader: PropTypes.node,
  subject: PropTypes.instanceOf(getModelForResourceType('subject_v2')),
  modifyTitle: PropTypes.func,
  showDifficulty: PropTypes.bool,
  hasQuestionSetAccessPredicate: PropTypes.func
};

export default QuestionWrapper;
