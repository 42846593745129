// @flow
import {Map, Set} from 'immutable';

type Registry = Map<string, *>;

type RegistryInterface = {
  getInvalidationKeys: Function,
  addInterestKey: Function,
  getRegistryContents: Function
};

export function getQueryRegistryInterface(
  REGISTRY_CONTENTS?: Registry = Map(),
  shouldRestoryRegistry: boolean = false
): RegistryInterface {
  if (shouldRestoryRegistry && !REGISTRY_CONTENTS.isEmpty()) {
    REGISTRY_CONTENTS = restoreRegistry(REGISTRY_CONTENTS);
  }

  return {
    getInvalidationKeys: (partialQueryObject): Set<string> => {
      if (partialQueryObject.resourcePath) {
        const root = partialQueryObject.resourcePath.join('/');
        return Object.keys(partialQueryObject).reduce((result, key) => {
          if (key === 'resourcePath') {
            return result;
          }

          const invalidations = _getInvalidations({
            subTree: REGISTRY_CONTENTS.getIn([root, key], new Map()),
            query: partialQueryObject[key]
          });

          return result.intersect(invalidations);
        }, REGISTRY_CONTENTS.getIn([root, '__ALL_QUERIES'], new Set()));
      } else {
        return REGISTRY_CONTENTS.keySeq().reduce((result, root) => {
          return result.union(
            Object.keys(partialQueryObject).reduce((subResult, key) => {
              return subResult.intersect(
                _getInvalidations({
                  subTree: REGISTRY_CONTENTS.getIn([root, key], new Map()),
                  query: partialQueryObject[key]
                })
              );
            }, REGISTRY_CONTENTS.getIn([root, '__ALL_QUERIES'], new Set()))
          );
        }, new Set());
      }
    },
    addInterestKey: (queryObject, irKey): RegistryInterface => {
      const root = queryObject.resourcePath.join('/');
      REGISTRY_CONTENTS = REGISTRY_CONTENTS.updateIn(
        [root, '__ALL_QUERIES'],
        new Set(),
        (allQueries) => allQueries.add(irKey)
      ).update(root, (pathTree) =>
        Object.keys(queryObject).reduce((result, key) => {
          if (key === 'resourcePath') {
            return result;
          }
          return result
            .updateIn([key, '__ALL_QUERIES'], new Set(), (allQueries) => allQueries.add(irKey))
            .update(key, (subTree) =>
              _parseSubQuery({
                subTree,
                subQuery: queryObject[key],
                irKey
              })
            );
        }, pathTree)
      );
      return getQueryRegistryInterface(REGISTRY_CONTENTS);
    },
    getRegistryContents: (): Registry => REGISTRY_CONTENTS
  };
}

function _getInvalidations({subTree, query}): Set<string> {
  if (query === null) {
    return subTree.get('__ALL_QUERIES', new Set());
  }
  if (typeof query !== 'object') {
    return subTree.getIn([query, '__ALL_QUERIES'], new Set());
  }
  if (query instanceof RegExp) {
    return subTree
      .keySeq()
      .reduce(
        (result, key) =>
          query.test(key) ? result.union(subTree.getIn([key, '__ALL_QUERIES'])) : result,
        subTree.get('__ALL_QUERIES', new Set())
      );
  }

  return Object.keys(query).reduce((result, key) => {
    return result.intersect(
      _getInvalidations({
        subTree: subTree.get(key, new Map()),
        query: query[key]
      })
    );
  }, subTree.get('__ALL_QUERIES', new Set()));
}

function _parseSubQuery({subTree, subQuery, irKey}): Set<string> {
  if (typeof subQuery !== 'object' || subQuery === null) {
    // base case
    return subTree.updateIn([subQuery, '__ALL_QUERIES'], new Set(), (queries) =>
      queries.add(irKey)
    );
  }

  return Object.keys(subQuery).reduce(
    (result, queryKey) =>
      result.update(queryKey, new Map(), (childSubTree) =>
        _parseSubQuery({
          subTree: childSubTree,
          subQuery: subQuery[queryKey],
          irKey
        })
      ),
    subTree.update('__ALL_QUERIES', new Set(), (allQueries) => allQueries.add(irKey))
  );
}

function restoreRegistry(registry: Registry): Registry {
  return registry.reduce((result, val, key) => {
    if (key === '__ALL_QUERIES') {
      return result.set(key, val.toSet());
    } else if (Map.isMap(val)) {
      return result.set(key, restoreRegistry(val));
    } else {
      return result.set(key, val);
    }
  }, Map());
}
