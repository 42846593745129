import React, {useContext} from 'react';
import {PublicQuestionsDispatch, PublicQuestionsContext} from './PublicQuestionsContext';
import TitleBar from 'components/PracticeView/TitleBar/TitleBar.react';
import {Link} from 'react-router';
import {Button, Icon, addToast, copyToClipboard, WindowSizeConsumer} from '@albert-io/atomic';

export const Header = () => {
  const dispatch = useContext(PublicQuestionsDispatch);
  const {title, subjectColor, backLocation, iconUrl, showQuestionList} =
    useContext(PublicQuestionsContext);
  return (
    <WindowSizeConsumer>
      {({breakpoints, viewportWidth}) => {
        const isMobile = viewportWidth <= breakpoints.m;
        return (
          <>
            <TitleBar
              backgroundColor={subjectColor}
              title={title}
              imgHref={iconUrl}
              backButton={<TitleBar.BackButton as={Link} label='Back To Guide' to={backLocation} />}
            />
            <div className='public-questions__toolbar u-pad-tb_1 u-pad-lr_3 u-display_flex u-gap_space-x1'>
              {isMobile && (
                <>
                  {!showQuestionList ? (
                    <Button
                      color='secondary'
                      variant='outlined'
                      size='s'
                      onClick={() => {
                        dispatch({type: 'showQuestionList'});
                      }}
                    >
                      <Icon icon='list-ul' className='u-mar-r_1' />
                      Show Question List
                    </Button>
                  ) : (
                    <Button
                      size='s'
                      onClick={() => {
                        dispatch({type: 'hideQuestionList'});
                      }}
                    >
                      <Icon icon='times' className='u-mar-r_1' />
                      Hide Question List
                    </Button>
                  )}
                </>
              )}
              <Button
                color='secondary'
                variant='text'
                key='share-question'
                size='s'
                onClick={() => {
                  copyToClipboard(global.location.href, () => {
                    addToast({
                      color: 'positive',
                      title: 'Link copied!',
                      message: 'Share this link with teachers and students.'
                    });
                  });
                }}
              >
                <Icon icon='link' className='u-mar-r_1' />
                Copy Link
              </Button>
            </div>
          </>
        );
      }}
    </WindowSizeConsumer>
  );
};
