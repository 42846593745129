/* eslint-disable no-use-before-define */
// @flow
import {fromJS, List} from 'immutable';
import {callAction} from 'client/framework';
import sessionLifecycleActions from 'client/Session/SessionLifecycleActions';
import sessionStore from 'client/Session/SessionStore';
import appStore from 'client/AppStore';
import {hasLicenseAccess, hasAssignmentDraftAccess} from 'lib/UserAccessUtil';

/**
 * The navlinks structure represents, as config, the available links a user will see in the
 *   1. main navigation (`mainLinks`)
 *   2. user drop down menu (`dropdownLinks`)
 *
 * These links will be different if the user is in Albert (`albertLinks`) vs. if they are in dennis (`dennisLinks`).
 *
 * Dennis links are shown to users unrestricted, because you need to already be an admin user to be in dennis.
 * However, when looking at albert, both the `mainLinks` and `dropdownLinks` under the `albertLinks` key can
 * be filtered by adding a `permissionPredicates` list.
 *
 * `permissionPredicates` is a list of functions returning true or false that will be OR'd together to determine
 * if the navigation item should be shown to a user of the site. Only one predicate needs to return true.
 */

type ListOfLinks = List<Map<string, *>>;
const navLinks: Map<string, Map<string, ListOfLinks>> = fromJS({
  albertLinks: {
    mainLinks: [
      {
        key: 'library',
        name: 'Library',
        route: '/subjects',
        matchingRoutes: ['/subjects'],
        permissionPredicates: [() => sessionStore.isStudent()]
      },
      {
        key: 'library',
        name: 'Library',
        route: '/subjects/discover',
        matchingRoutes: ['/subjects'],
        permissionPredicates: [
          () => {
            const isTeacher = sessionStore.isTeacher();
            return isTeacher;
          },
          () => sessionStore.isAuthor() && !sessionStore.hasTeacherAccess()
        ]
      },
      {
        key: 'classes',
        name: 'Classes',
        route: '/classes'
      },
      {
        key: 'reports',
        name: 'Reports',
        route: '/reports',
        permissionPredicates: [() => sessionStore.hasTeacherAccess()]
      },
      {
        key: 'admin',
        name: 'Admin',
        route: '/school-admin',
        permissionPredicates: [
          () => sessionStore.isSchoolAdministrator(),
          () => sessionStore.isSuper()
        ]
      },
      {
        key: 'pricing',
        name: 'Pricing',
        hardLink: true,
        route: '/pricing-schools',
        permissionPredicates: [
          () => {
            const {isReady, value} = hasLicenseAccess();
            return isReady && !value;
          },
          () => sessionStore.isSuper()
        ]
      },
      {
        key: 'folders',
        name: 'Folders',
        route: '/saved',
        permissionPredicates: [() => sessionStore.hasValidSession() && sessionStore.isTeacher()]
      },
      {
        key: 'assignments',
        name: 'Assignments',
        route: '/assignments',
        permissionPredicates: [() => sessionStore.hasTeacherAccess()]
      },
      {
        key: 'drafts',
        name: 'Launch Assignment Builder',
        route: '/assignment-builder/create-assignment',
        permissionPredicates: [
          () => {
            const {isReady, value} = hasAssignmentDraftAccess();
            return isReady && value;
          },
          () => sessionStore.isSuper()
        ]
      }
      // TODO: determine if dropdown will be used in draft ux https://github.com/albert-io/nexus/issues/6828
      // --------------------------------------
      // {
      //   key: 'drafts',
      //   name: 'Assignments',
      //   dropdownLinks: [
      //     {
      //       name: 'Create Assignment Beta',
      //       route: '/assignment-builder/create-assignment'
      //     },
      //     {
      //       name: 'View Drafts',
      //       route: '/drafts'
      //     },
      //     {
      //       name: 'Give Beta Feedback',
      //       href: 'https://airtable.com/appHlbZdSYA9CbSEu/shrYfC8lac5ljT4JQ',
      //       hardLink: true
      //     }
      //   ],
      //   permissionPredicates: [
      //     () => false
      // () => {
      //   const {isReady, value} = hasAssignmentDraftAccess();
      //   return isReady && value;
      // },
      // () => sessionStore.isSuper()
      //   ]
      // }
      // -----------------------------
    ],
    dropdownLinks: [
      {
        key: 'authoring',
        name: 'Authoring',
        route: '/authoring/dashboard',
        permissionPredicates: [() => sessionStore.isAuthor()]
      },
      {
        key: 'dennis',
        name: 'Dennis',
        route: '/dennis',
        permissionPredicates: [() => sessionStore.isSuper()]
      },
      {
        key: 'dashboard',
        name: 'My Subject Analytics',
        route: '/dashboard'
      },
      {
        key: 'account',
        name: 'My Account',
        route: '/account/profile'
      },
      {
        hardLink: true,
        key: 'resource-hub',
        name: 'Resource Hub',
        route: '/resource-hub/',
        permissionPredicates: [() => sessionStore.hasValidSession() && !sessionStore.isStudent()]
      },
      {
        key: 'feedback',
        name: 'Rate Albert',
        permissionPredicates: [() => sessionStore.isSuper(), () => sessionStore.isTeacher()]
      },
      {
        key: 'logout',
        name: 'Log Out',
        action: () => callAction(sessionLifecycleActions.END_SESSION)
      }
    ]
  },
  dennisLinks: {
    mainLinks: [
      {
        key: 'content',
        name: 'Content',
        route: `/dennis/content`
      },
      {
        key: 'users',
        name: 'Users',
        route: `/dennis/users`
      },
      {
        key: 'districts',
        name: 'Districts',
        route: `/dennis/districts`
      },
      {
        key: 'schools',
        name: 'Schools',
        route: `/dennis/schools`
      }
    ],
    dropdownLinks: [
      {
        key: 'logout',
        name: 'Log Out',
        action: () => callAction(sessionLifecycleActions.END_SESSION)
      },
      {
        key: 'backToAlbert',
        name: 'Back to Albert',
        route: '/subjects',
        hardLink: true
      }
    ]
  }
});

/**
 * Takes a path, and builds a list of navigation links for that path.
 *
 * It also builds links only for the active zone.
 *
 * This function will prune __OR MODIFY__ the default mainLinks defined
 * in this file depending on a variety of factors. Notably:
 *   1. what path is given to the function
 *   2. what the user's session is
 *
 * @param {string} path
 * @returns {ListOfLinks} list of main navigation links based on zone and path
 */
export function getNavigationLinksForPath(path: string, options: Object): ListOfLinks {
  if (appStore.isZoneDennis()) {
    return navLinks.get('dennisLinks').get('mainLinks');
  }

  /**
   * The signup route has the main navigation effectively disabled
   */
  const isSignUpPage = path.includes('/signup');
  if (!sessionStore.hasValidSession() && isSignUpPage) {
    return List();
  }

  let finalLinks = navLinks.get('albertLinks').get('mainLinks');

  const globalAssignmentsFeatureFlag = options.globalAssignmentsEnabled;
  if (!globalAssignmentsFeatureFlag) {
    finalLinks = finalLinks.filter((link) => link.get('key') !== 'assignments');
  }
  finalLinks = applyPermissionPredicates(finalLinks);
  return finalLinks;
}

/**
 * Returns dropdown links based on what zone a user is in
 *
 * @returns {ListOfLinks} list of main navigation dropdown  links
 */
export function getDropdownLinks(): ListOfLinks {
  let links = new List();
  if (appStore.isZoneAlbert()) {
    links = navLinks.getIn(['albertLinks', 'dropdownLinks']);
  } else if (appStore.isZoneDennis()) {
    links = navLinks.getIn(['dennisLinks', 'dropdownLinks']);
  }

  return applyPermissionPredicates(links);
}

/**
 * For a given list of link objects, checks for predicates, and applies them
 *
 * Returns a filtered list of link objects depending on the outcome of the `.some`
 *
 * @param {List<Object} links
 * @returns {ListOfLinks} list of link objects
 */
function applyPermissionPredicates(links: ListOfLinks): ListOfLinks {
  return links.reduce((acc, link) => {
    if (link.has('permissionPredicates')) {
      const hasPermission = link.get('permissionPredicates').some((predicate) => predicate());

      if (link.has('dropdownLinks') && hasPermission) {
        const dropdownLinks = link.get('dropdownLinks').filter((dropdownLink) => {
          if (dropdownLink.has('permissionPredicates')) {
            const predicates = dropdownLink.get('permissionPredicates');
            return predicates.some((predicate) => {
              return predicate();
            });
          }

          return true;
        });

        return acc.push(link.set('dropdownLinks', dropdownLinks));
      }

      return hasPermission ? acc.push(link) : acc;
    }

    return acc.push(link);
  }, List());
}
