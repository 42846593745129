import React from 'react';

import {
  QuestionComponent,
  QuestionComponentProps
} from '../shared/QuestionComponent/QuestionComponent.react';

import TwoWayInputArea from './TwoWayInputArea.react';

const TwoWayQuestionComponent = (props: QuestionComponentProps) => {
  return <QuestionComponent {...props} ActiveQuestionInputArea={TwoWayInputArea} />;
};

type TwoWayQuestionTypeProps = Omit<QuestionComponentProps, 'questionState'>;

const TwoWayQuestion = {
  PreGuess: (props: TwoWayQuestionTypeProps) => (
    <TwoWayQuestionComponent {...props} questionState='PreGuess' />
  ),
  PostGuess: (props: TwoWayQuestionTypeProps) => (
    <TwoWayQuestionComponent {...props} questionState='PostGuess' />
  )
};

export {TwoWayQuestion};
