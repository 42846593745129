// @flow
import {fromJS} from 'immutable';
import {Store} from 'client/framework';

import subjectFAQListActions from './SubjectFAQList.actions';
import subjectFAQQuestionsActions from './SubjectFAQQuestions/SubjectFAQQuestions.actions';

class SubjectFAQListStore extends Store {
  constructor(name: string) {
    super(name);
    this.initialData = fromJS({
      creatingNew: false,
      rearrangeMode: false
    });

    this.setInitialData(this.initialData);
    this.handle(subjectFAQListActions.ADD_NEW_FAQ, this.add);
    this.handle(subjectFAQListActions.DISCARD_NEW_FAQ, this.discard);
    this.handle(subjectFAQQuestionsActions.SET_REARRANGE_MODE, this.setRearrangeMode);
  }

  add() {
    this.setCreatingNew(true);
  }

  discard() {
    this.setCreatingNew(false);
  }

  setCreatingNew(creatingNew: boolean) {
    this.writeData('creatingNew', creatingNew);
  }

  isCreatingNew(): boolean {
    return this.readData('creatingNew');
  }

  isRearrangeMode(): boolean {
    return this.readData('rearrangeMode');
  }

  setRearrangeMode({rearrangeMode}: {rearrangeMode: boolean}) {
    this.writeData('rearrangeMode', rearrangeMode);
  }
}

export default new SubjectFAQListStore('SubjectFAQListStore');
