/**
 * # Form
 *
 * A component that represents a form with a header, footer, and optional children.
 *
 * ## Props
 *
 * - `className` (string): The CSS class name for the form.
 * - `onSubmit` (function): The callback function to be called when the form is submitted.
 *
 * ## Usage
 */

import React, {FormHTMLAttributes} from 'react';
import classnames from 'classnames';

import Heading from '../../atoms/Heading/Heading.react';
import Text from '../../atoms/Text/Text.react';
import ButtonGroup from '../../molecules/ButtonGroup/ButtonGroup.react';
import './form.scss';

type AlignOption = 'left' | 'center';
type ButtonAlignOption = AlignOption | 'right';

interface BaseProps extends PropsWithChildrenOptional, PropsWithClassNameOptional {}

interface HeaderProps extends BaseProps {
  align?: AlignOption;
  subtitle: string;
  title?: string;
}

const Header = ({align = 'left', children, className, subtitle, title}: HeaderProps) => {
  const headingContent = children || title;

  return (
    <div
      className={classnames('o-form__header u-mar-b_5', className, {
        [`o-form__header--${align}`]: align
      })}
    >
      {headingContent && (
        <Heading
          className={classnames('u-max-width_text', {
            'u-mar-b_1': subtitle
          })}
        >
          {headingContent}
        </Heading>
      )}
      {subtitle && (
        <Text as='div' color='secondary' className='u-max-width_text'>
          {subtitle}
        </Text>
      )}
    </div>
  );
};

interface FooterProps extends BaseProps {
  align?: ButtonAlignOption;
}

const Footer = ({align = 'right', children, className, ...rest}: FooterProps) => (
  <div className='u-mar-t_5'>
    <ButtonGroup className={className} align={align} {...rest}>
      {children}
    </ButtonGroup>
  </div>
);

type FormProps = BaseProps & FormHTMLAttributes<HTMLFormElement>;

const Form = ({children, className, onSubmit = () => {}, ...rest}: FormProps) => {
  const onSubmitHandler = (e) => {
    e.preventDefault();
    onSubmit(e);
  };

  return (
    <form className={className} onSubmit={onSubmitHandler} {...rest}>
      {children}
    </form>
  );
};

Form.Header = Header;
Form.Footer = Footer;

export default Form;
