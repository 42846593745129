import React, {useState, useEffect, useRef} from 'react';
import {List, Map} from 'immutable';
import classnames from 'classnames';
import {getVersionAgnosticResourceType} from 'resources/mandark.resource';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import {normalizeSearchString} from 'lib/stringUtils';
import {
  LegacyFindAndApply,
  Button,
  CheckboxChip,
  Radio,
  IconButton,
  Icon,
  Text
} from '@albert-io/atomic';

import type {SubjectModelV2} from 'resources/augmented/Subject/SubjectModel.v2';
import type {GuideLevelModelV2} from 'resources/augmented/GuideLevel/GuideLevelModel.v2';

import './subject-selector.scss';

interface SubjectSelectorProps extends PropsWithClassNameRequired {
  defaultSelectedItems: Map<string, SubjectModelV2 | GuideLevelModelV2>;
  onRemoveFilter: () => void;
  closeModal: () => void;
  onCancel?: () => void;
  onApply: (selectedItems: Map<'guideLevel' | 'idTrail' | 'subject' | 'guide', any>) => void;
  subjects: List<SubjectModelV2>;
}

function SubjectSelector({
  className,
  closeModal = () => {},
  defaultSelectedItems = Map(),
  onApply = () => {},
  onCancel = () => {},
  onRemoveFilter = () => {},
  subjects
}: SubjectSelectorProps) {
  const [subjectGuide, setSubjectGuide] = useState<SubjectModelV2 | null>(null);
  const [currentGuide, setCurrentGuide] = useState<SubjectModelV2 | null>(null);
  const subjectsRef = useRef(subjects);
  const didMountRef = useRef(false);

  const [currentLevel, setCurrentLevel] = useState<List<SubjectModelV2 | GuideLevelModelV2>>(
    List()
  );
  const [breadcrumbs, setBreadcrumbs] = useState<List<GuideLevelModelV2>>(List());

  useEffect(() => {
    setCurrentLevel(subjectsRef.current);
    if (!didMountRef.current) {
      didMountRef.current = true;
    }
  }, []);

  const getGuideLevel = ({nLevel, selectedGuide = subjectGuide, newBreadcrumbs = breadcrumbs}) => {
    const breadcrumbIds = newBreadcrumbs.map((crumb) => crumb.getId()) as List<string>;

    let nextGuideLevel;
    if (nLevel === 0) {
      nextGuideLevel = subjects;
    } else if (nLevel === 1) {
      nextGuideLevel = selectedGuide.getGuides();
    } else if (nLevel === 2) {
      nextGuideLevel = (currentGuide || selectedGuide).getSortedThemes();
    } else if (nLevel === 3) {
      nextGuideLevel = currentGuide.getSortedTopics(...breadcrumbIds.shift().shift().toJS());
    } else if (nLevel === 4) {
      nextGuideLevel = currentGuide.getSortedSubtopics(...breadcrumbIds.shift().shift().toJS());
    }

    return nextGuideLevel;
  };

  const hasDrilldown = (guideLevel) => {
    const resource = getVersionAgnosticResourceType(guideLevel.getJsonApiType());
    if (resource === 'subject' || resource === 'guide') {
      return true;
    }

    const nLevel = guideLevel.getNlevel() + 2;

    if (nLevel > 4) {
      return false;
    }

    const newBreadcrumbs = breadcrumbs.push(guideLevel);

    return !getGuideLevel({nLevel, newBreadcrumbs}).isEmpty();
  };

  const handleDrillDown = (guideLevel) => {
    const resource = getVersionAgnosticResourceType(guideLevel.getJsonApiType());
    let selectedGuide = subjectGuide;
    if (resource === 'subject') {
      selectedGuide = guideLevel;
      setSubjectGuide(guideLevel);
    }

    if (resource === 'guide') {
      selectedGuide = guideLevel;
      setCurrentGuide(guideLevel);
    }

    const newBreadcrumbs = breadcrumbs.push(guideLevel);
    const nLevel = newBreadcrumbs.size <= 2 ? newBreadcrumbs.size : guideLevel.getNlevel() + 2;
    const nextGuideLevel = getGuideLevel({nLevel, selectedGuide, newBreadcrumbs});

    setCurrentLevel(nextGuideLevel);
    setBreadcrumbs(newBreadcrumbs);
  };

  const handleDrillUp = () => {
    const newBreadcrumbs = breadcrumbs.pop();
    const nLevel = newBreadcrumbs.size;

    if (nLevel <= 1) {
      setCurrentGuide(null);
    }

    const prevGuideLevel = getGuideLevel({nLevel});

    setCurrentLevel(prevGuideLevel);
    setBreadcrumbs(newBreadcrumbs);
  };

  const handleCheck = (e, selectedItems, currentItem, currentSubject) => {
    if (!e.target.checked) {
      return {selectedItems: selectedItems.clear()};
    }

    const resource = getVersionAgnosticResourceType(currentItem.getJsonApiType());

    let newSelectedItems = selectedItems;
    if (resource === 'subject') {
      newSelectedItems = selectedItems
        .clear()
        .set('subject', currentItem)
        .set('guide', null)
        .set('guideLevel', null);
    } else if (resource === 'guide_level') {
      const newBreadcrumbs = breadcrumbs.push(currentItem);
      const breadcrumbIds = newBreadcrumbs.map((item) => item.getId());
      newSelectedItems = selectedItems
        .set('subject', currentSubject)
        .set('guideLevel', currentItem)
        .set('guide', null)
        .set('idTrail', breadcrumbIds);
    } else if (resource === 'guide') {
      const newBreadcrumbs = breadcrumbs.push(currentItem);
      const breadcrumbIds = newBreadcrumbs.map((item) => item.getId());

      newSelectedItems = selectedItems
        .clear()
        .set('guideLevel', null)
        .set('subject', currentSubject)
        .set('guide', currentItem)
        .set('idTrail', breadcrumbIds);
    }

    return {
      selectedItems: newSelectedItems
    };
  };

  const handleApply = (selectedItems) => {
    onApply(selectedItems);
    closeModal();
  };

  if (!didMountRef.current) {
    return null;
  }

  return (
    <LegacyFindAndApply
      className={className}
      customOnConfirm={handleApply}
      customOnCheck={handleCheck}
      defaultSelectedItems={defaultSelectedItems}
    >
      <LegacyFindAndApply.Header />
      <LegacyFindAndApply.Body>
        {({onCheck, searchString, selectedItems}) => {
          const results = currentLevel.filter((item) => {
            const updatedSearchString = normalizeSearchString(searchString);
            const subjectString = normalizeSearchString(item.getName());
            return !searchString || subjectString.indexOf(updatedSearchString) !== -1;
          });

          return (
            <div>
              {!breadcrumbs.isEmpty() && (
                <Breadcrumbs breadcrumbs={breadcrumbs} onClick={handleDrillUp} />
              )}
              {!results.isEmpty() ? (
                <ul className='u-mar_0 u-pad_0 subject-selector_guide-list'>
                  {results.map((item, i) => (
                    <GuideLevelItem
                      key={`guide-level-item_${item.getId()}`}
                      index={i}
                      item={item}
                      resultSize={results.size}
                      selectedItems={selectedItems}
                      hasDrilldown={hasDrilldown}
                      handleDrillDown={handleDrillDown}
                      subjectGuide={subjectGuide}
                      onCheck={onCheck}
                    />
                  ))}
                </ul>
              ) : (
                <LegacyFindAndApply.NoResultsMessage />
              )}
            </div>
          );
        }}
      </LegacyFindAndApply.Body>
      <LegacyFindAndApply.Footer>
        {({selectedItems, onCheck, onClear, onReset, onConfirm}) => (
          <div>
            {!selectedItems.isEmpty() && (
              <LegacyFindAndApply.SelectedItems>
                <CheckboxChip color='brand' icon='tag' size='small' onChange={onCheck} checked>
                  <span className='subject-selector__item-name'>
                    {selectedItems.get('subject').getName()}
                  </span>
                  {selectedItems.has('guide') && selectedItems.get('guide') !== null && (
                    <Icon aria-hidden icon='chevron-right' className='u-mar-lr_1' />
                  )}
                  {selectedItems.has('guide') && selectedItems.get('guide') !== null && (
                    <MarkdownRendererV2
                      className='subject-selector__item-name'
                      text={selectedItems.get('guide').getName()}
                    />
                  )}
                  {selectedItems.has('guideLevel') && selectedItems.get('guideLevel') !== null && (
                    <Icon aria-hidden icon='chevron-right' className='u-mar-lr_1' />
                  )}
                  {selectedItems.has('guideLevel') && selectedItems.get('guideLevel') !== null && (
                    <MarkdownRendererV2
                      className='subject-selector__item-name'
                      text={selectedItems.get('guideLevel').getName()}
                    />
                  )}
                </CheckboxChip>
              </LegacyFindAndApply.SelectedItems>
            )}
            <LegacyFindAndApply.BtnGroup>
              <Button
                color='secondary'
                onClick={() => {
                  onClear();
                  onRemoveFilter();
                }}
              >
                Clear
              </Button>
              <Button
                color='secondary'
                onClick={() => {
                  onReset();
                  closeModal();
                  onCancel();
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  if (selectedItems.isEmpty()) {
                    onClear();
                    onRemoveFilter();
                  } else {
                    onConfirm();
                  }
                }}
              >
                Apply
              </Button>
            </LegacyFindAndApply.BtnGroup>
          </div>
        )}
      </LegacyFindAndApply.Footer>
    </LegacyFindAndApply>
  );
}

interface GuideLevelItemProps {
  item: GuideLevelModelV2;
  index: number | undefined;
  resultSize: number;
  selectedItems: Map<string, any>;
  subjectGuide: SubjectModelV2;
  hasDrilldown: (GuideLevelModelV2) => boolean;
  handleDrillDown: (GuideLevelModelV2) => void;
  onCheck: (
    e: React.ChangeEvent<HTMLInputElement>,
    item: object,
    subjectGuide: SubjectModelV2
  ) => void;
}

function GuideLevelItem({
  item,
  resultSize,
  index = 0,
  selectedItems = Map(),
  hasDrilldown,
  handleDrillDown,
  subjectGuide = Map(),
  onCheck
}: GuideLevelItemProps) {
  return (
    <li
      className={classnames('u-display_flex u-justify-content_space-between', {
        'u-pad-tb_1': index + 1 !== resultSize && index !== 0,
        'u-pad-b_1': index === 0,
        'u-pad-t_1': index + 1 === resultSize
      })}
    >
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label className='u-display_flex u-align-items_center'>
        <Radio
          onChange={(e) => onCheck(e, item, subjectGuide)}
          name='clasroom-subjects'
          value={item.getId()}
          checked={
            selectedItems.get('idTrail')?.includes(item.getId()) ||
            selectedItems.get('guideLevel')?.getId?.() === item.getId() ||
            selectedItems.get('guide')?.getId() === item.getId() ||
            selectedItems.get('subject')?.getId() === item.getId()
          }
        />
        <Text size='xs' bold className='u-mar-l_2'>
          <MarkdownRendererV2 text={item.getName()} />
        </Text>
      </label>
      {hasDrilldown(item) && (
        <IconButton
          label='Drill down to guide level'
          icon='chevron-right'
          size='s'
          iconStyle='regular'
          onClick={() => handleDrillDown(item)}
        />
      )}
    </li>
  );
}

interface BreadcrumbsProps {
  breadcrumbs: List<GuideLevelModelV2>;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

function Breadcrumbs({breadcrumbs, onClick}: BreadcrumbsProps) {
  return (
    <div className='u-display_flex u-align-items_center u-mar-b_2' aria-label='breadcrumb'>
      <IconButton
        onClick={onClick}
        icon='arrow-left'
        className='u-mar-r_2'
        size='s'
        label='Go back a guide level'
      />
      <ol className='subject-selector__breadcrumb'>
        {breadcrumbs.map((item, i) => (
          <li key={`subject-selector-crumb_${item.getId()}`}>
            <Text size='xs' bold key={item.getId()} className='u-display_flex u-align-items_center'>
              <MarkdownRendererV2 className='subject-selector__item-name' text={item.getName()} />
              {typeof i === 'number' && i + 1 !== breadcrumbs.size && (
                <Icon
                  aria-hidden
                  icon='chevron-right'
                  className='u-mar-lr_1'
                  emphasis='lowest'
                  color='primary'
                />
              )}
            </Text>
          </li>
        ))}
      </ol>
    </div>
  );
}

export default SubjectSelector;
