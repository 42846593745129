import {callAction, createActions} from 'client/framework';

const modalActions = createActions(null, ['SHOW_MODAL', 'HIDE_MODAL']);

export function showModal(modalName) {
  callAction(modalActions.SHOW_MODAL, modalName);
}

export function hideModal(modalName) {
  callAction(modalActions.HIDE_MODAL, modalName);
}

export default modalActions;
