/**
 * # AnchoredFixedPosition
 *
 * A component that positions its children relative to a target element using the Popper library.
 * An wrapper implementation of the `react-popper` library. Please refer to the [React Popper Docs](https://popper.js.org/docs/v1/#Popper) for more typing details.
 *
 * ## Props extends IPopperProps
 *
 * - `className?: string`: Additional CSS class name for the component.
 * - `target?: HTMLElement`: The target element to position the children relative to.
 * - `eventsEnabled?: boolean`: Whether events are enabled for the Popper component.
 * - `children?: React.ReactNode`: The content to be rendered inside the component.
 * - `placement?: typeof placements[number]`: The placement of the component relative to the target element.
 * - `usePortal?: boolean`: Whether to render the component inside a portal. If `true`, effectively takes element outside of its natural DOM-placement and overlays it above the document.
 * - `...rest: IPopperProps`: Any member of `IPopperProps`
 *
 * ## Usage
 *
 * ```tsx
 * import AnchoredFixedPosition from 'path/to/AnchoredFixedPosition';
 *
 * const ExampleComponent = () => {
 *   const targetRef = useRef(null);
 *
 *   return (
 *     <div>
 *       <button ref={targetRef}>Target Element</button>
 *       <AnchoredFixedPosition target={targetRef.current} placement="bottom">
 *         <div>Positioned Content</div>
 *       </AnchoredFixedPosition>
 *     </div>
 *   );
 * };
 * ```
 */
import React from 'react';
import {IPopperProps, Popper, placements} from 'react-popper';
import {AppPortal} from 'client/Portals/AppPortal/AppPortal.react';

interface AnchoredFixedPositonProps
  extends OmitChildren<IPopperProps>,
    PropsWithChildrenOptional,
    PropsWithClassNameOptional {
  target?: HTMLElement;
  eventsEnabled?: boolean;
  placement?: (typeof placements)[number];
  usePortal?: boolean;
}

const AnchoredFixedPosition = ({
  target,
  children,
  className,
  placement = 'bottom',
  eventsEnabled,
  usePortal,
  ...rest
}: AnchoredFixedPositonProps) => {
  return (
    !!target && (
      <AppPortal usePortal={usePortal} className={className}>
        <Popper
          target={target}
          eventsEnabled={eventsEnabled}
          positionFixed
          placement={placement}
          {...rest}
        >
          {typeof children === 'function' ? children() : children}
        </Popper>
      </AppPortal>
    )
  );
};

export default AnchoredFixedPosition;
