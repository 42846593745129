import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router';
import {Heading, Card, Button} from '@albert-io/atomic';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import './topic-summary.scss';

const TopicSummary = ({guideLevel, practiceButtonLink, onPracticeButtonClick}) => {
  const content = guideLevel.getSummary();

  return (
    <Card className='gl-topic-summary' paddingLevel={4}>
      <div className='gl-topic-summary__wrapper u-mar-b_4'>
        <div className='gl-topic-summary__img-wrapper u-mar-r_4'>
          <img
            src={guideLevel.getThemeImg()}
            className='gl-topic-summary__img'
            alt=''
            role='presentation'
          />
        </div>
        <div className='gl-topic-summary__body'>
          <Heading as='h1'>
            <MarkdownRendererV2 text={guideLevel.getName()} />
          </Heading>
          <MarkdownRendererV2 text={content} />
        </div>
      </div>
      <div className='gl-topic-summary__button-wrapper'>
        <Button
          to={practiceButtonLink}
          as={practiceButtonLink ? Link : 'button'}
          onClick={onPracticeButtonClick}
        >
          Continue to questions
        </Button>
      </div>
    </Card>
  );
};

TopicSummary.propTypes = {
  /**
   * @todo: Type this correctly
   */
  guideLevel: PropTypes.any.isRequired,
  onPracticeButtonClick: PropTypes.func,
  practiceButtonLink: PropTypes.string
};

TopicSummary.defaultProps = {
  onPracticeButtonClick: () => {}
};

export {TopicSummary};
