import React from 'react';
import {debounce} from 'lodash';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {callAction} from 'client/framework';
import sessionStore from 'client/Session/SessionStore';
import templateListActions from 'client/Assignments/Templates/TemplateList.actions';
import templateListStore from 'client/Assignments/Templates/TemplateList.store';

import './add-to-template-widget.scss';

/**
 *  @todo: Make a generic checkbox semi-controlled component
 */

export default class AddToTemplateWidget extends React.Component {
  static propTypes = {
    hasSubjectAccess: PropTypes.bool,
    questionSet: ImmutablePropTypes.record
  };

  debouncedAddOrRemove = debounce(this.addOrRemoveFromTemplateSetList).bind(this);

  /**
   * Note: Order for list should be in order of current question sets view but since it doesn't actually matter
   * until the whole question set is supposed to be added to an assignment template, we postpone sorting until
   * the save button is clicked in AddQuestionsToTemplates.react.js
   */
  addOrRemoveFromTemplateSetList() {
    const {questionSet} = this.props;
    let templateSetList = templateListStore.getSelectedQuestionSets();
    const questionSetInTemplateSetList = templateSetList.find(
      (qSet) => qSet.getId() === questionSet.getId()
    );

    templateSetList = questionSetInTemplateSetList
      ? templateSetList.delete(questionSetInTemplateSetList)
      : templateSetList.add(questionSet);

    callAction(templateListActions.SET_SELECTED_QUESTION_SETS, templateSetList);
  }

  handleClick = (e) => {
    e.stopPropagation();
    this.debouncedAddOrRemove();
  };

  render() {
    const {questionSet} = this.props;
    const isFreeQuestionSet = !questionSet.getFreeGuideLevels().isEmpty();
    const checkboxClassName =
      questionSet.getQuestions().size === 1
        ? 'add-to-template-toggle__checkbox-wrapper--single-question'
        : 'add-to-template-toggle__checkbox-wrapper--multi-question';

    return this.props.hasSubjectAccess || (sessionStore.isTeacher() && isFreeQuestionSet) ? (
      <label className={checkboxClassName} onClick={this.handleClick}>
        <input
          type='checkbox'
          checked={templateListStore
            .getSelectedQuestionSets()
            .some((qSet) => qSet.getId() === questionSet.getId())}
        />
        <span className='checkbox-checkmark' />
      </label>
    ) : null;
  }
}
