import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './assets/question-difficulty-label.scss';

export default class QuestionDifficultyLabel extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    difficulty: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    short: PropTypes.bool
  };

  getColorModifierClassName(difficulty) {
    switch (difficulty) {
      case 'easy':
        return 'question-difficulty-label--easy';
      case 'moderate':
        return 'question-difficulty-label--moderate';
      case 'difficult':
        return 'question-difficulty-label--difficult';
      default:
        return '';
    }
  }

  getDifficultyByNumber(num) {
    return {
      1: 'Easy',
      2: 'Moderate',
      3: 'Difficult'
    }[num];
  }

  render() {
    const difficulty =
      typeof this.props.difficulty === 'number'
        ? this.getDifficultyByNumber(this.props.difficulty)
        : this.props.difficulty;
    const isShortened = this.props.short;
    const labelContent = isShortened ? difficulty.charAt(0) : difficulty;

    const difficultyClassName = classnames(
      'question-difficulty-label',
      this.getColorModifierClassName(difficulty.toLowerCase()),
      {
        'question-difficulty-label--short': isShortened
      },
      this.props.className
    );

    return <div className={difficultyClassName}>{labelContent}</div>;
  }
}
