import React, {useCallback} from 'react';
import AuthoringTextInput from 'client/EditPage/V2/AuthoringTextInput/AuthoringTextInput.react';
import SupplementManagerModalButton from 'client/Supplements/SupplementManager/SupplementManagerModalButton.react';
import {AuthoringQuestionModelV1} from 'resources/augmented/AuthoringQuestion/AuthoringQuestionModel.v1';
import {useQuestionEditorV2Store} from 'client/EditPage/V2/QuestionEditorV2Store';
import {useShallow} from 'zustand/react/shallow';
import {PRIMARY_CONTENT_LANGUAGE} from 'client/EditPage/V2/QuestionEditorV2Store.types';

interface Props {
  question: AuthoringQuestionModelV1;
  onModelChange: (question: AuthoringQuestionModelV1) => void;
}

export const FreeResponseEditor = ({question, onModelChange}: Props) => {
  const {currentLanguage, updateTranslatedQuestionField, translatedSample} =
    useQuestionEditorV2Store(
      useShallow((state) => ({
        currentLanguage: state.currentLanguage,
        updateTranslatedQuestionField: state.updateTranslatedQuestionField,
        translatedSample: state
          .currentTranslatedQuestion()
          ?.translated_fields?.find((field) => field.field === 'sample')?.text
      }))
    );

  const onChange = useCallback(
    (e) => {
      if (currentLanguage === PRIMARY_CONTENT_LANGUAGE) {
        const updatedQuestion = question.setSample(e.target.value);
        onModelChange(updatedQuestion);
      } else {
        updateTranslatedQuestionField(currentLanguage, 'sample', 'text', e.target.value);
      }
    },
    [question, onModelChange, currentLanguage, updateTranslatedQuestionField]
  );

  const value =
    currentLanguage === PRIMARY_CONTENT_LANGUAGE ? question.getSample() : translatedSample;

  return (
    <div className='u-display_flex u-flex-direction_column'>
      <AuthoringTextInput
        className='sample-solution'
        label='Sample Response'
        name='sample'
        onChange={onChange}
        placeholder='A powerful sample of the solution'
        value={value ?? ''}
      />
      <SupplementManagerModalButton
        disabled={currentLanguage !== PRIMARY_CONTENT_LANGUAGE}
        className='question-editor__add-supplement-btn u-mar-t_2'
        pathToProperty='sample'
      />
    </div>
  );
};
