// @flow
import {Store} from 'client/framework';
import {Map} from 'immutable';
import sessionStore from 'client/Session/SessionStore';
import {makeParser} from 'generic/MarkdownRendererV2/markdownRendererUtils';
import authoringFeedbackActions from './AuthoringFeedback.actions';
import {AuthoringFeedbackModelV1} from 'resources/GeneratedModels/AuthoringFeedback/AuthoringFeedbackModel.v1';
import {getAuthoringFeedbackQuery} from './shared';

class AuthoringFeedbackStore extends Store {
  constructor(name: string) {
    super(name);
    this.initialData = Map({
      questionId: null,
      message: '',
      pending: false,
      toast: null
    });

    this.setInitialData(this.initialData);

    this.handle(authoringFeedbackActions.SUBMIT_FEEDBACK, this._submitFeedback);
    this.handle(authoringFeedbackActions.UPDATE_FEEDBACK_MESSAGE, this.setProperty('message'));
    this.handle(authoringFeedbackActions.UPDATE_QUESTION, this.setProperty('questionId'));
    this.handle(authoringFeedbackActions.CLEAR_TOAST, this._clearToast);
  }

  async _submitFeedback(): Promise<*> {
    this.writeData('pending', true);
    const feedback = AuthoringFeedbackModelV1.getDefaultModel()
      .setMessage(this.getMessage())
      .setEmailMessage(this.getParsedMessage())
      .addRelationship({
        type: 'authoring_question_v1',
        relation: this.getQuestionId()
      })
      .addRelationship({
        type: 'user_v2',
        relation: sessionStore.getUserId()
      });
    try {
      await feedback.save();
      this.writeData('message', '');
      /**
       * Email sending in mandark is async. A user with a sent timestamp will not be
       * available until that email actually is sent.
       *
       * this could take a few seconds, we wait 10
       *
       * we still want to invalidate initially so that the message appears
       */
      getAuthoringFeedbackQuery(this.getQuestionId()).invalidateInterest();
      setTimeout(() => {
        getAuthoringFeedbackQuery(this.getQuestionId()).invalidateInterest();
      }, 10000);
      this.writeData(
        'toast',
        Map({
          color: 'positive',
          title: 'Success!',
          message: 'Your feedback has been submitted!'
        })
      );
    } catch (e) {
      this.writeData(
        'toast',
        Map({
          color: 'negative',
          title: 'Error!',
          message: 'There was an error submitting your feedback.'
        })
      );
    } finally {
      this.writeData('pending', false);
    }
  }

  _clearToast() {
    this.writeData('toast', null);
  }

  hasToast(): boolean {
    return this.readData('toast') !== null;
  }

  getToast(): null | Map<string, string> {
    return this.readData('toast');
  }

  isSavePending(): boolean {
    return this.readData('pending') === true;
  }

  getQuestionId(): string {
    return this.readData('questionId');
  }

  getMessage(): string {
    return this.readData('message');
  }

  getParsedMessage(): string {
    const parser = makeParser();
    const syntaxTree = parser.makeSyntaxTree(this.getMessage());
    const htmlOutput = parser.htmlOutput(syntaxTree);
    return htmlOutput.replace(/<div class="paragraph">([^]*?)<\/div>/g, '$1');
  }
}

export default new AuthoringFeedbackStore('AuthoringFeedbackStore');
