import {multipleChoiceSetup} from './questionTypes/multipleChoiceQuestion.extensions';
import {fillInTheBlankSetup} from './questionTypes/fillInTheBlankQuestion.extensions';
import {freeEntrySetup} from './questionTypes/freeEntryQuestion.extensions';
import {snippetSelectionSetup} from './questionTypes/snippetSelectionQuestion.extensions';
import {twoWaySetup} from './questionTypes/twoWayQuestion.extensions';

export function questionTypeSetup(data) {
  const questionType = data.get('question_type');
  if (questionType === 'multiple-choice') {
    data = multipleChoiceSetup(data);
  } else if (questionType === 'fill-in-the-blank') {
    data = fillInTheBlankSetup(data);
  } else if (questionType === 'free-entry') {
    data = freeEntrySetup(data);
  } else if (questionType === 'snippet-selection') {
    data = snippetSelectionSetup(data);
  } else if (questionType === 'two-way') {
    data = twoWaySetup(data);
  } else if (questionType === 'text-highlight') {
    // Text highlight questions do not transform their data
    data = data;
  }
  return data;
}
