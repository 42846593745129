/**
 * # Toggle
 *
 * A toggle component that can be used to switch between two states.
 *
 * ## Props extends React.HTMLProps<HTMLInputElement>
 *
 * - `className?: string`: Additional CSS class name for the toggle.
 * - `disabled?: boolean`: Determines if the toggle is disabled.
 * - `size?: 's' | 'l'`: Determines the size of the toggle. Can be either 's' for small, 'l' for large, or 'xs' for extra small.
 * - `checked?: boolean`: Determines if the toggle is checked.
 * - `onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void`: Event handler for when the toggle value changes.
 * - `style?: React.CSSProperties`: Inline style object for the toggle.
 * - `...rest: React.HTMLProps<HTMLInputElement>`: Any native input element property.
 *
 * ## Usage
 *
 * ```tsx
 * import Toggle from './Toggle';
 *
 * const Example = () => {
 *   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
 *     console.log('Toggle value changed:', event.target.checked);
 *   };
 *
 *   return (
 *     <Toggle
 *       className="custom-toggle"
 *       disabled={false}
 *       size="s"
 *       checked={true}
 *       onChange={handleChange}
 *       style={{ color: 'red' }}
 *     />
 *   );
 * };
 * ```
 */

import React, {forwardRef} from 'react';
import classnames from 'classnames';
import './toggle.scss';

export interface Props
  extends Omit<React.HTMLProps<HTMLInputElement>, 'size'>,
    PropsWithClassNameOptional {
  disabled?: boolean;
  size?: 'xs' | 's' | 'l';
}

export const Toggle = forwardRef<HTMLInputElement, Props>(
  ({className, disabled = false, size = 's', ...rest}: Props, ref) => {
    return (
      <span
        className={classnames(className, 'a-toggle', {
          'a-toggle--disabled': disabled,
          'a-toggle--size-large': size === 'l',
          'a-toggle--size-small': size === 's',
          'a-toggle--size-extra-small': size === 'xs'
        })}
      >
        <input
          className='a-toggle__input'
          type='checkbox'
          disabled={disabled}
          ref={ref}
          // TODO: this is a workaround for a version mismatch bug
          style={rest.style as any}
          {...rest}
        />
        <span className='a-toggle__track'>
          <div className='a-toggle__control' />
        </span>
      </span>
    );
  }
);

export default Toggle;
