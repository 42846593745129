// @flow
import {Store} from 'client/framework';
import {fromJS} from 'immutable';
import {subjectViewActions} from './SubjectView.actions';
import type {SubjectModelV2} from 'resources/augmented/Subject/SubjectModel.v2';

class SubjectViewStore extends Store {
  constructor(name: string) {
    super(name);

    this.setInitialData(
      fromJS({
        activeSubject: null,
        backButtonPath: null,
        scrollYPositions: {}
      })
    );

    this.handle(subjectViewActions.SET_ACTIVE_SUBJECT, this._setActiveSubject);
    this.handle(subjectViewActions.SET_BACK_BUTTON_PATH, this._setBackButtonPath);
    this.handle(subjectViewActions.RESET_STORE, this.resetStore);
    this.handle(subjectViewActions.SET_SCROLL_POSITION, this._setScrollPosition);
  }

  _setActiveSubject(activeSubject: SubjectModelV2) {
    this.writeDataNoEmit('activeSubject', activeSubject);
  }

  _setBackButtonPath(backButtonPath: string) {
    this.writeDataNoEmit('backButtonPath', backButtonPath);
  }

  _setScrollPosition({subjectId, scrollYPosition}: {subjectId: string, scrollYPosition: number}) {
    this.writeData((store) => {
      const updatedScrollPositions = store.get('scrollYPositions').set(subjectId, scrollYPosition);
      return store.set('scrollYPositions', updatedScrollPositions);
    });
  }

  getBackButtonPath = (): string => this.readData('backButtonPath');

  getPrevPathname = (): string => this.readData('prevPathname');

  getScrollPosition = (subjectId: string) => this.readData('scrollYPositions').get(subjectId, null);

  getSubject = (): SubjectModelV2 | null => this.readData('activeSubject');

  getSubjectId = (): string | null => (this.getSubject() ? this.getSubject().getId() : null);

  getSubjectSlug = (): string | null => (this.getSubject() ? this.getSubject().getUrlSlug() : null);
}

export default new SubjectViewStore('SubjectViewStore');
