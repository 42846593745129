import React from 'react';
import {Helmet} from 'react-helmet';
import {title, meta} from 'lib/head';

export function withApplicationMetadata(
  Component,
  /**
   * By default the `getMetadataFromProps` function will use `title` and `metaDescription` attributes on the `route`
   */
  getMetadataFromProps = (props) => ({
    title: props.route.title,
    metaDescription: props.route.metaDescription
  })
) {
  return class WithMetadata extends React.Component {
    static displayName = `WithMetadata(${Component.displayName || Component.name || 'Component'})`;
    render() {
      const metadata = getMetadataFromProps(this.props);
      const description = metadata.metaDescription;
      return (
        <React.Fragment>
          <Helmet>
            {metadata.title ? title(metadata.title) : null}
            {description ? meta.description(description) : null}
          </Helmet>
          <Component {...this.props} />
        </React.Fragment>
      );
    }
  };
}
