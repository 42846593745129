import {fromJS} from 'immutable';
import assignmentV2IEStore from '../AssignmentV2IE.store';
import QuestionsListStore from 'client/InteractionEngineV2/shared/QuestionsList/QuestionsListStore';
import assignmentV2IEQuestionsListActions from './AssignmentV2IEQuestionsList.actions';

class AssignmentV2IEQuestionsListStore extends QuestionsListStore {
  constructor(name) {
    super(name);
    this.setInitialData(
      fromJS({
        isTopDrawerOpen: false,
        hideAnsweredQuestions: false
      })
    );
    this.handle(
      assignmentV2IEQuestionsListActions.TOGGLE_IS_TOP_DRAWER_OPEN,
      this.toggleProperty('isTopDrawerOpen')
    );
    this.handle(
      assignmentV2IEQuestionsListActions.TOGGLE_HIDE_ANSWERED_QUESTIONS,
      this.toggleProperty('hideAnsweredQuestions')
    );
  }

  get questionSets() {
    const guesses = assignmentV2IEStore.getAssignmentGuesses();
    const questionSets = assignmentV2IEStore.getQuestionSets();
    return this.shouldHideAnsweredQuestions() && !assignmentV2IEStore.isAssignmentSubmitted()
      ? questionSets.filterNot((set) => {
          return set.getQuestions().every((question) => guesses.has(question.getId()));
        })
      : questionSets;
  }

  isTopDrawerOpen = () => this.readData('isTopDrawerOpen');

  shouldHideAnsweredQuestions = () => this.readData('hideAnsweredQuestions');
}

export default new AssignmentV2IEQuestionsListStore('AssignmentV2IEQuestionsListStore');
