import React from 'react';
import PropTypes from 'prop-types';
import SimpleMarkdown from 'simple-markdown';
import {Button} from '@albert-io/atomic';

/**
 * @example
 * [button href=https://www.google.com]Go to Google[/button]
 */

const regex = /^\[button href=(?!javascript)(\S+)]((?:[\w\W](?!\[\/button]))+[\w\W])\[\/button]/;

const buttonRule = {
  order: SimpleMarkdown.defaultRules.link.order - 0.5,
  match: (source) => regex.exec(source),
  parse: (capture, recurseParse, state) => {
    const [, href, content] = capture;
    return {
      content: recurseParse(content, state),
      href
    };
  },
  react({content, href}, output, {key}) {
    return (
      <Button key={key} href={href} as='a' target='_blank' rel='noopener'>
        {output(content)}
      </Button>
    );
  }
};

buttonRule.react.propTypes = {
  content: PropTypes.node,
  href: PropTypes.string
};

export default buttonRule;
