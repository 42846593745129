import React from 'react';
import moment from 'moment';

import {Text} from '@albert-io/atomic';

import {type SummaryStatsResponse} from 'client/Reports/reports.types';

import {asPercentage, StyledContainer} from '../shared';

import './summary-stats-other-stats.scss';

export type OtherStatsDataKey = Extract<
  keyof SummaryStatsResponse,
  'accuracy' | 'count_attempts' | 'avg_time_spent_per_student'
>;

export type OtherStatsData = Map<OtherStatsDataKey, number | null>;

interface Props {
  data: OtherStatsData;
  isSingleStudentView: boolean;
}

export default function SummaryStatsOtherStats({data, isSingleStudentView}: Props) {
  function getFormattedValue(key: OtherStatsDataKey, numericValue: number | null) {
    const otherStatsDataKeyToFormattingCallback = new Map<
      OtherStatsDataKey,
      (value: number | null) => string
    >([
      ['accuracy', (value: number | null) => asPercentage(value)],
      [
        'avg_time_spent_per_student',
        (value: number | null) => {
          const momentDuration = moment.duration(value ?? 0);

          const hours = momentDuration.hours().toString().padStart(2, '0');
          const minutes = momentDuration.minutes().toString().padStart(2, '0');
          const seconds = momentDuration.seconds().toString().padStart(2, '0');

          return `${hours}:${minutes}:${seconds}`;
        }
      ],
      ['count_attempts', (value: number | null) => value?.toLocaleString() ?? '0']
    ]);

    const callback = otherStatsDataKeyToFormattingCallback.get(key);

    if (callback === undefined) {
      throw Error(`Cannot find callback corresponding to key ${key}!`);
    }

    return callback(numericValue);
  }

  function getLabel(key: OtherStatsDataKey) {
    const otherStatsDataKeyToLabel = new Map<OtherStatsDataKey, string>([
      ['accuracy', 'Avg. Accuracy'],
      ['avg_time_spent_per_student', 'Avg. Time Spent'],
      ['count_attempts', 'Total Attempts']
    ]);

    if (isSingleStudentView) {
      otherStatsDataKeyToLabel.set('accuracy', 'Total Accuracy');
      otherStatsDataKeyToLabel.set('avg_time_spent_per_student', 'Total Time Spent');
    }

    const label = otherStatsDataKeyToLabel.get(key);

    if (label === undefined) {
      throw Error(`Value corresponding to key ${key} is undefined!`);
    }

    return label;
  }

  return (
    <StyledContainer
      as='dl'
      className='summary-stats-other-stats u-flex-grow_1 u-mar_0 u-pad-lr_5 u-pad-tb_4'
    >
      {Array.from(data.entries()).map(([key, value]) => (
        <div className='summary-stats-other-stats__pair' key={key}>
          <Text as='dd' className='u-font-weight_bold' size='xl'>
            {getFormattedValue(key, value)}
          </Text>
          <Text as='dt' color='secondary' size='xs'>
            {getLabel(key)}
          </Text>
        </div>
      ))}
    </StyledContainer>
  );
}
