import React from 'react';

import type {SortOption} from 'client/Reports/reports.types';

import {HeaderCell} from './HeaderCell';

interface Props {
  onSortClick: (sortKey: string, sortDirection: SortOption) => void;
  sortDirection: SortOption;
  sortBy: string | null;
}

export const DueDateRangeHeaderCell = ({sortDirection, onSortClick, sortBy}: Props) => {
  const SORTS = [
    {
      label: 'Min Due Date',
      sortKey: 'assignments.contextual_due_date_min'
    },
    {
      label: 'Max Due Date',
      sortKey: 'assignments.contextual_due_date_max'
    }
  ] as const;

  const isCurrentSort = !!(
    sortBy &&
    ['assignments.contextual_due_date_min', 'assignments.contextual_due_date_max'].includes(sortBy)
  );

  return (
    <HeaderCell
      title={<div>Due Date</div>}
      isCurrentSort={isCurrentSort}
      onSortClick={onSortClick}
      sortDirection={sortDirection}
      sortBy={sortBy}
      sorts={SORTS}
      showSort
    />
  );
};
