import React from 'react';
import PropTypes from 'prop-types';
import stylePropType from 'react-style-proptype';

export const RESIZER_DEFAULT_CLASSNAME = 'Resizer';

class Resizer extends React.Component {
  static propTypes = {
    eleRef: PropTypes.func,
    className: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    onDoubleClick: PropTypes.func,
    onMouseDown: PropTypes.func.isRequired,
    onTouchStart: PropTypes.func.isRequired,
    onTouchEnd: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func.isRequired,
    split: PropTypes.oneOf(['vertical', 'horizontal']),
    style: stylePropType,
    resizerClassName: PropTypes.string,
    position: PropTypes.number
  };

  static defaultProps = {
    resizerClassName: RESIZER_DEFAULT_CLASSNAME
  };

  render() {
    const {
      eleRef,
      className,
      onClick,
      onDoubleClick,
      onMouseDown,
      onTouchEnd,
      onTouchStart,
      onKeyDown,
      position,
      resizerClassName,
      split,
      style
    } = this.props;
    const classes = [resizerClassName, split, className];

    return (
      <div
        ref={eleRef}
        aria-label='resizer'
        tabIndex={0}
        role='slider'
        aria-valuenow={position}
        aria-orientation={split}
        className={classes.join(' ')}
        style={style}
        onMouseDown={(event) => onMouseDown(event)}
        onTouchStart={(event) => {
          event.preventDefault();
          onTouchStart(event);
        }}
        onTouchEnd={(event) => {
          event.preventDefault();
          onTouchEnd(event);
        }}
        onClick={(event) => {
          if (onClick) {
            event.preventDefault();
            onClick(event);
          }
        }}
        onDoubleClick={(event) => {
          if (onDoubleClick) {
            event.preventDefault();
            onDoubleClick(event);
          }
        }}
        onKeyDown={(event) => {
          onKeyDown(event);
        }}
      />
    );
  }
}

export default Resizer;
