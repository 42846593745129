import {Store} from 'client/framework';
import questionTrackerModalActions from './QuestionTrackerModal.actions';
import {fromJS} from 'immutable';

class QuestionTrackerModalStore extends Store {
  constructor(name) {
    super(name);

    this.setInitialData(
      fromJS({
        questionSetId: ''
      })
    );
    this.handle(questionTrackerModalActions.SET_QUESTION_SET_ID, this.setProperty('questionSetId'));
  }

  getQuestionSetId() {
    return this.readData().get('questionSetId');
  }
}

export default new QuestionTrackerModalStore('QuestionTrackerModalStore');
