import React, {useState, useCallback} from 'react';
import {Iterable} from 'immutable';
import classnames from 'classnames';
import {Icon, Text} from '@albert-io/atomic';
import MarkdownRendererV2 from 'generic/MarkdownRendererV2/MarkdownRendererV2.react';
import './passage.scss';

interface Props {
  content: any;
}

const Passage = ({content}: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleIsExpanded = useCallback(() => {
    setIsExpanded((prevIsExpanded) => !prevIsExpanded);
  }, []);

  const getContent = useCallback(() => {
    return Iterable.isIterable(content) ? content.toJS() : content;
  }, [content]);

  const hasContent = useCallback((line) => {
    return /[^\s]/.test(line);
  }, []);

  const getFormattedLines = useCallback(
    (text: string, ignoreWhiteSpace: boolean) => {
      if (text) {
        const splitText = text.split(/[\n\r]/);
        return ignoreWhiteSpace ? splitText.filter(hasContent) : splitText;
      }
      return null;
    },
    [hasContent]
  );

  const makeLines = useCallback(
    (lines: string[] | null, hideLineNumbers: boolean, excludeWhiteSpace: boolean) => {
      let wasPrevLineEmpty = false;
      let lineNumber = 0;
      if (lines) {
        return lines.map((line, i) => {
          const isEmptyLine = !hasContent(line);
          if (wasPrevLineEmpty && isEmptyLine) {
            return null;
          }
          wasPrevLineEmpty = isEmptyLine;
          if (!excludeWhiteSpace || !isEmptyLine) {
            lineNumber += 1;
          }

          return (
            <PassageLine
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              hideLineNumbers={hideLineNumbers}
              lineNumber={excludeWhiteSpace && isEmptyLine ? null : lineNumber}
              line={isEmptyLine ? '\u00a0' : line}
            />
          );
        });
      }
      return null;
    },
    [hasContent]
  );

  const {excludeWhiteSpace, hideLineNumbers, ignoreWhiteSpace, text} = getContent();
  const lines = getFormattedLines(text, ignoreWhiteSpace);

  return (
    <div className='passage-supplement'>
      <button
        type='button'
        onClick={toggleIsExpanded}
        className={classnames('passage-supplement__toggle', {
          'passage-supplement__toggle--expanded': isExpanded
        })}
      >
        <Icon
          icon={isExpanded ? 'caret-down' : 'caret-right'}
          className='passage-supplement__icon'
        />
        {isExpanded ? 'Hide' : 'Show'} Passage
      </button>
      {isExpanded && (
        <ul className='passage-supplement__passage'>
          {makeLines(lines, hideLineNumbers, excludeWhiteSpace)}
        </ul>
      )}
    </div>
  );
};

export default Passage;

interface PassageLineProps {
  hideLineNumbers: boolean;
  lineNumber: number | null;
  line: string;
}

const PassageLine = ({hideLineNumbers, lineNumber, line}: PassageLineProps) => (
  <li className='passage-supplement-line'>
    {!hideLineNumbers && (
      <Text bold size='m' color='secondary' className='passage-supplement-line__number'>
        {lineNumber}
      </Text>
    )}
    <MarkdownRendererV2 text={line} />
  </li>
);
