import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SimpleMarkdown from 'simple-markdown';

const regex = /^\[color:([^\s\]]+)]((?:.(?!\[\/color]))+.)\[\/color]/;

const ColorEl = ({value, children}) => {
  const isHexCode = value[0] === '#';
  const style = {};
  if (isHexCode) {
    style.color = value;
  }
  return (
    <span
      style={style}
      className={classnames({
        [`u-color_${value}`]: !isHexCode
      })}
    >
      {children}
    </span>
  );
};

ColorEl.propTypes = {
  value: PropTypes.string.isRequired,
  children: PropTypes.node
};

export const colorRule = {
  order: SimpleMarkdown.defaultRules.em.order - 0.5,
  match: (source) => regex.exec(source),
  parse: (capture, recurseParse, state) => {
    // eslint-disable-next-line no-unused-vars
    const [_, value, content] = capture;
    return {
      value,
      content: recurseParse(content, state)
    };
  },
  /* eslint-disable react/display-name */
  react: (node, output) => {
    return (
      <ColorEl key={node.tokenId} value={node.value}>
        {output(node.content)}
      </ColorEl>
    );
  }
};
