/**
 * # FileInput
 *
 * A component that allows users to select and upload files.
 *
 * ## Props extends InputHTMLAttributes<HTMLInputElement>
 *
 * - `accept` (string): The file types to accept, specified as a comma-separated list of MIME types or file extensions.
 * - `className` (string): Additional CSS class name for the component.
 * - `disabled` (boolean): Determines whether the component is disabled or not. Default is `false`.
 * - `error` (boolean): Determines whether the component is in an error state or not. Default is `false`.
 * - `warning` (boolean): Determines whether the component is in a warning state or not. Default is `false`.
 * - `errorMessage` (React.ReactNode): The error message to display when the component is in an error state.
 * - `icon` (IconProp): The icon to display in the component. Default is `'upload'`.
 * - `iconStyle` (IconStyle): The style of the icon. Default is `'light'`.
 * - `multiple` (boolean): Determines whether multiple files can be selected or not. Default is `false`.
 * - `onChange` ((e?: React.ChangeEvent<HTMLInputElement>) => void): The callback function to be called when the value of the input changes.
 * - `placeholder` (string): The placeholder text to display when no file is chosen. Default is `'No file chosen'`.
 * - `...rest: InputHTMLAttributes<HTMLInputElement>`: See [MDN reference](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input) for `input`
 *
 * ## Usage
 *
 * ```tsx
 * import FileInput from './FileInput.react';
 *
 * const MyComponent = () => {
 *   const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
 *     // Handle file change event
 *   };
 *
 *   return (
 *     <FileInput
 *       accept=".jpg,.png"
 *       className="my-file-input"
 *       disabled={false}
 *       error={false}
 *       warning={false}
 *       errorMessage="Invalid file format"
 *       icon="upload"
 *       iconStyle="light"
 *       multiple={false}
 *       onChange={handleFileChange}
 *       placeholder="Select a file"
 *     />
 *   );
 * };
 *
 * export default MyComponent;
 * ```
 */
import React, {useCallback, useState, InputHTMLAttributes} from 'react';
import classnames from 'classnames';

import {arrayToReadableString} from 'lib/stringUtils';

import Icon, {IconProp, IconStyle} from '../../atoms/Icon/Icon.react';
import Text from '../../atoms/Text/Text.react';
import './file-input.scss';

// TODO: Add a drag and drop zone

interface FileInputProps
  extends Omit<
      InputHTMLAttributes<HTMLInputElement>,
      'onChange' | 'placeholder' | 'multiple' | 'disabled' | 'accept'
    >,
    PropsWithClassNameOptional {
  accept: string;
  disabled?: boolean;
  error?: boolean;
  warning?: boolean;
  errorMessage?: React.ReactNode;
  icon?: IconProp;
  iconStyle?: IconStyle;
  multiple?: boolean;
  onChange?: (e?: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
}

function hasFiles(files: FileList | null): files is FileList {
  return files !== null;
}

const FileInput = ({
  accept,
  className,
  disabled = false,
  error = false,
  warning = false,
  errorMessage,
  icon = 'upload',
  iconStyle = 'light',
  multiple = false,
  placeholder = 'No file chosen',
  onChange = () => {},
  ...rest
}: FileInputProps) => {
  const [filenames, setFilenames] = useState<string | null>(null);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.persist();
      const {files} = e.target;
      if (hasFiles(files)) {
        const filenamesArr = Array.from(files).map((file) => file.name);
        const newFilenames = arrayToReadableString(filenamesArr);
        setFilenames(newFilenames);
        onChange(e);
      }
    },
    [onChange]
  );

  return (
    <label
      className={classnames(
        className,
        'u-align-items_center u-justify-content_center u-flex-grow_1 u-flex-wrap_wrap u-text-align_center u-flex-direction_column u-display_flex u-pad-lr_3 u-pad-tb_3 file-input',
        {
          'u-bgc_blue-050 u-color_blue-600 u-border-color_blue-400':
            !warning && !error && !filenames,
          'u-bgc_slate-050 u-color_slate-600 u-border-color_slate-400':
            !warning && !error && filenames,
          // error state takes precedence over warning
          'file-input--warning u-bgc_yellow-050 u-color_yellow-600 u-border-color_yellow-600':
            warning && !error && filenames,
          'file-input--error u-color_red-200 u-bgc_red-050 u-color_red-500 u-border-color_red-400':
            error,
          'u-bgc_slate-050 u-color_slate-400 u-border-color_slate-200 u-pointer-events_none':
            disabled
        }
      )}
    >
      <Icon
        className={classnames(className, 'file-input__icon u-mar-b_2', {
          'u-color_blue-300': !error && !filenames,
          'u-color_slate-400': !error && filenames,
          'u-color_red-200': error,
          'u-color_yellow-600': warning,
          'u-color_slate-300': disabled
        })}
        color='inherit'
        size='inherit'
        icon={!filenames ? icon : 'file-alt'}
        iconStyle={iconStyle}
      />
      <Text className='file-input__content u-flex-grow_1' color='inherit' id='content'>
        {errorMessage || filenames || placeholder}
      </Text>
      {!disabled && (
        <Text className='u-text-decoration_underline u-mar-t_1' color='inherit'>
          {!filenames ? 'Choose...' : 'Change'}
        </Text>
      )}
      <input
        accept={accept}
        aria-describedby='content'
        className='file-input__input u-cursor_pointer'
        disabled={disabled}
        multiple={multiple}
        onChange={handleChange}
        type='file'
        {...(rest as any)}
      />
    </label>
  );
};

export default FileInput;
