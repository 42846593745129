import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import makeRef from 'lib/makeRef';
import './questions-list-top-widget.scss';

export default class QuestionsListTopWidget extends React.Component {
  static propTypes = {
    text: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    onWidgetClick: PropTypes.func,
    onTextClick: PropTypes.func,
    onIconClick: PropTypes.func,
    hide: PropTypes.bool,
    drawerContents: PropTypes.element,
    showDrawer: PropTypes.bool,
    showIconNotifier: PropTypes.bool
  };

  static defaultProps = {
    text: <span>&nbsp;</span>
  };

  constructor() {
    super();
    this.drawerWrapper = null;
    this.drawerSizer = null;
  }

  componentDidUpdate() {
    this.updateDrawerStyles();
  }

  updateDrawerStyles() {
    if (!this.drawerWrapper) {
      return;
    }
    if (this.drawerWrapper.style.height && !this.props.showDrawer) {
      this.drawerWrapper.removeAttribute('style');
      return;
    }
    if (this.props.showDrawer && !this.drawerWrapper.style.height) {
      this.drawerWrapper.style.height = this.drawerSizer.offsetHeight + 'px';
    }
  }

  getElType(onClickFunc) {
    return onClickFunc ? 'button' : 'div';
  }

  makeIconEl() {
    if (!this.props.icon) {
      return null;
    }
    const IconWrapperEl = this.getElType(this.props.onIconClick);
    const icon = this.props.icon;
    const iconContent =
      typeof icon === 'string' ? (
        <span className={`questions-list-top-widget__icon fa fa-${icon}`} />
      ) : (
        icon
      );
    const iconNotifier = this.props.showIconNotifier ? (
      <span className='questions-list-top-widget__icon-notifier' />
    ) : null;
    return (
      <IconWrapperEl
        className={classnames('questions-list-top-widget__icon-wrapper', {
          unbutton: IconWrapperEl === 'button'
        })}
        onClick={this.props.onIconClick}
      >
        {iconContent}
        {iconNotifier}
      </IconWrapperEl>
    );
  }

  makeRef = makeRef.bind(this);

  render() {
    if (this.props.hide) {
      return null;
    }
    const {onWidgetClick, onTextClick, drawerContents} = this.props;
    const BarWrapperEl = this.getElType(onWidgetClick);
    const TextWrapperEl = this.getElType(onTextClick);
    const drawer = drawerContents ? (
      <div
        className={classnames('questions-list-top-widget__drawer-wrapper', {
          'questions-list-top-widget__drawer-wrapper--open': this.props.showDrawer
        })}
        ref={this.makeRef}
        data-ref-name='drawerWrapper'
      >
        <div
          className='questions-list-top-widget__drawer-sizer'
          ref={this.makeRef}
          data-ref-name='drawerSizer'
        >
          {drawerContents}
        </div>
      </div>
    ) : null;
    return (
      <div className='questions-list-top-widget'>
        <BarWrapperEl
          className={classnames('questions-list-top-widget__bar-wrapper', {
            unbutton: BarWrapperEl === 'button'
          })}
          onClick={this.props.onWidgetClick}
        >
          <TextWrapperEl
            className={classnames('questions-list-top-widget__text-wrapper', {
              unbutton: TextWrapperEl === 'button'
            })}
            onClick={this.props.onTextClick}
          >
            {this.props.text}
          </TextWrapperEl>
          {this.makeIconEl()}
        </BarWrapperEl>
        {drawer}
      </div>
    );
  }
}
