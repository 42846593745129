import React, {useCallback, useEffect} from 'react';
import {withRouter, WithRouterProps} from 'react-router';
import {List} from 'immutable';
import FeatureFlag from 'client/components/FeatureFlag/FeatureFlag.react';
import abandonQueueChangesStore from 'client/Dennis/Content/Queue/AbandonQueueChanges.store';
import {callAction} from 'client/framework';
import sessionStore from 'client/Session/SessionStore';
import {Button, Card, Heading, Icon} from '@albert-io/atomic';
import AttributionInfo from 'client/EditPage/V2/AttributionInfo/AttributionInfo';
import SupplementManagerModal from 'client/Supplements/SupplementManager/SupplementManagerModal';
import {getSubjectAuthorPermissionsQuery} from 'client/Dennis/Content/Queue/shared';
import ConfirmLeaveModal from 'generic/ConfirmLeaveModal/ConfirmLeaveModal.react';
import awaitMandarkQueries from 'lib/hocs/awaitMandarkQueries';
import {useShallow} from 'zustand/react/shallow';

import {ExplanationSection} from './ExplanationSection/ExplanationSection';
import DifficultyEditor from './QuestionEditor/DifficultyEditor';
import QuestionActions from './QuestionActions/QuestionActions.react';
import {QuestionEditorLinks} from './QuestionEditorLinks/QuestionEditorLinks';
import QuestionEditor from './QuestionEditor/QuestionEditor';
import questionEditorStore from './QuestionEditor/QuestionEditor.store';
import questionEditorActions from './QuestionEditor/QuestionEditor.actions';
import QuestionPreview from './QuestionPreview/QuestionPreview';
import QuestionSetActions from './QuestionSetActions/QuestionSetActions';
import QuestionSetAlignment from './QuestionSetAlignment/QuestionSetAlignment';
import QuestionTagEditor from './QuestionEditor/QuestionTags/QuestionTagEditor';
import StandardSearch from './StandardSearch/StandardSearch';
import {StatusDropdown} from './StatusDropdown/StatusDropdown';
import SubtypeCard from './SubtypeCard';
import PromptSection from './PromptSection';
import QuestionAnswerSection from './QuestionTypeManager';

import './edit-page.scss';
import {useQuestionEditorV2Store} from './QuestionEditorV2Store';
import {TranslationSection} from './Translations/TranslationSection';
import {AuthorChangeStatusButton} from './AuthorChangeStatusButton/AuthorChangeStatusButton';
import {
  ALTERNATE_CONTENT_LANGUAGES,
  AlternateContentLanguage,
  PRIMARY_CONTENT_LANGUAGE,
  SUPPORTED_CONTENT_LANGUAGES,
  SupportedContentLanguage
} from './QuestionEditorV2Store.types';
import {LanguageToggle} from './Translations/LanguageToggle';
import {SectionCard} from './SectionCard';
import {TitleSection} from './TitleSection/TitleSection';
import {SupplementTranslationPanel} from './Translations/SupplementTranslationPanel';

interface Props
  extends WithRouterProps<{
    questionSetId: string;
    questionId: string;
  }> {
  subjectAuthorPermissions: List<any>;
}

const EditPage = ({params, subjectAuthorPermissions}: Props) => {
  useEffect(() => {
    if (!questionEditorStore.hasChanges()) {
      callAction(questionEditorActions.INITIALIZE_STORE);
    }
  }, []);

  useEffect(() => {
    callAction(questionEditorActions.INITIALIZE_STORE);
  }, [params.questionId, params.questionSetId]);

  const togglePreviewMode = () => {
    const isPreviewMode = questionEditorStore.isPreviewMode();
    callAction(questionEditorActions.SET_IS_PREVIEW_MODE, !isPreviewMode);
  };

  const canEditQuestionSet = () => {
    const questionSet = questionEditorStore.getQuestionSet();
    if (sessionStore.isSuper() || !questionSet || !questionSet.existsOnServer()) {
      return true;
    }
    if (sessionStore.getUserId() === questionSet.getMeta().getAuthorId()) {
      return true;
    }
    const questionSetSubjectId = questionSet.getSubjectId();
    const subjectPermissions = subjectAuthorPermissions.find(
      (perm) => perm.getAuthoringSubject().getId() === questionSetSubjectId
    );
    return subjectPermissions && subjectPermissions.isCanEditQuestions();
  };

  if (!questionEditorStore.isReady()) {
    return null;
  }

  return (
    <FeatureFlag name='question_editor_v2'>
      <EditPageV2Presentation
        params={params}
        togglePreviewMode={togglePreviewMode}
        canEditQuestionSet={canEditQuestionSet}
      />

      <FeatureFlag.Fallback>
        <EditPageV1Presentation
          params={params}
          togglePreviewMode={togglePreviewMode}
          canEditQuestionSet={canEditQuestionSet}
        />
      </FeatureFlag.Fallback>
    </FeatureFlag>
  );
};

interface EditPagePresentationProps {
  params: {
    questionSetId: string;
    questionId: string;
  };
  togglePreviewMode: () => void;
  canEditQuestionSet: () => boolean;
}

const EditPageV2Presentation = ({
  params,
  togglePreviewMode,
  canEditQuestionSet
}: EditPagePresentationProps) => {
  const question = questionEditorStore.getQuestion();
  const questionSet = questionEditorStore.getQuestionSet();
  const errors = questionEditorStore.getValidationErrors();

  const {
    init,
    resetTranslations,
    currentLanguage,
    setCurrentLanguage,
    saveTranslatedFields,
    savingTranslatedFields,
    translationsHaveChanges
  } = useQuestionEditorV2Store(
    useShallow((state) => ({
      init: state.init,
      resetTranslations: state.resetTranslations,
      currentLanguage: state.currentLanguage,
      setCurrentLanguage: state.setCurrentLanguage,
      saveTranslatedFields: state.saveTranslatedFields,
      savingTranslatedFields: state.savingTranslatedFields,
      translationsHaveChanges: state.hasChanges()
    }))
  );

  const isPreviewMode = questionEditorStore.isPreviewMode() && canEditQuestionSet();
  const isSavePending = questionEditorStore.isSavePending() || savingTranslatedFields;

  const questionId = question.getId();
  const supplementIds = question
    .getAuthoringSupplements()
    .map((s) => s.getId())
    .toJS()
    .join(','); // dirty hack to get react to properly understand when this variable changes (if this is an array it does shallow comparison)

  useEffect(() => {
    resetTranslations();
    if (!questionId) {
      return;
    }

    setCurrentLanguage(PRIMARY_CONTENT_LANGUAGE);
    init(
      questionId,
      supplementIds.split(',').filter((id) => !!id)
    );
  }, [questionId, init, supplementIds, resetTranslations, setCurrentLanguage]);

  const handleSave = useCallback(async () => {
    callAction(questionEditorActions.SAVE_QUESTION);
    await questionEditorStore.getSavePromise();
    if (question.isTranslate() || question.isTranslateSupplements()) {
      await saveTranslatedFields();
    }
    await init(
      question.getId(),
      question
        .getAuthoringSupplements()
        .map((s) => s.getId())
        .toJS()
    );
  }, [saveTranslatedFields, question, init]);

  const hasChanges =
    !questionSet.getChangeMap().isEmpty() ||
    !question.getChangeMap().isEmpty() ||
    translationsHaveChanges;

  const shouldPromptLeave = () => {
    if (!questionSet || !question) {
      return false;
    }

    if (hasChanges && abandonQueueChangesStore.hasAbandonedChanges()) {
      return false;
    }

    if (hasChanges && questionEditorStore.isSavePending()) {
      return false;
    }

    return hasChanges;
  };

  return (
    <div className='edit-page background-subtle'>
      <div className='edit-page-v2__header-section'>
        <div className='edit-page-v2__header'>
          <div>
            <Heading size='s' className='u-truncate u-mar-r_2'>
              {questionEditorStore.getQuestion().existsOnServer()
                ? questionEditorStore.getQuestion().get('title') || 'Question'
                : questionEditorStore.getQuestion().get('title') || 'Untitled Question'}
            </Heading>
            {sessionStore.isSuper() && <AttributionInfo questionSetId={params.questionSetId} />}
          </div>

          <div className='u-display_flex u-align-items_center u-gap_space-x2'>
            <Button
              variant={hasChanges ? 'solid' : 'text'}
              color={hasChanges ? 'primary' : 'secondary'}
              onClick={handleSave}
              disabled={isSavePending || !hasChanges}
            >
              {isSavePending && <Icon className='u-mar-r_1' icon='refresh' spin />}
              Save Changes
            </Button>

            <Button
              variant={isPreviewMode ? 'faded' : 'text'}
              color={isPreviewMode ? 'primary' : 'secondary'}
              onClick={togglePreviewMode}
            >
              {isPreviewMode ? 'End Preview' : 'Preview'}
            </Button>

            <StatusDropdown />

            <AuthorChangeStatusButton />

            <QuestionSetActions
              togglePreviewMode={togglePreviewMode}
              isPreviewMode={questionEditorStore.isPreviewMode() || !canEditQuestionSet()}
              disablePreviewMode={!canEditQuestionSet()}
            />
          </div>
        </div>

        <FeatureFlag name='dennis_translation_authoring'>
          <QuestionEditorLanguageToggle />
        </FeatureFlag>
      </div>

      {isPreviewMode ? (
        <QuestionPreview questionId={params.questionId} questionSetId={params.questionSetId} />
      ) : (
        <div className='u-display_flex u-gap_space-x4 u-align-items_flex-start u-pad_3 u-flex-direction_column u-pad-b_8'>
          <FeatureFlag name='dennis_translation_authoring'>
            {currentLanguage !== PRIMARY_CONTENT_LANGUAGE && (
              <SectionCard title='Translation Settings' padBody={false}>
                <TranslationSection />
              </SectionCard>
            )}
          </FeatureFlag>

          {/*
            The various editor sections should show if we are editing the English fields
            If we are in a translation tab, they should only show if translations are enabled for the question
          */}
          {(currentLanguage === PRIMARY_CONTENT_LANGUAGE || question.isTranslate()) && (
            <>
              <SectionCard title='Question Title' fieldPredicate={(field) => field === 'title'}>
                <TitleSection />
              </SectionCard>

              <Card
                paddingLevel={4}
                className='u-width_100pc u-display_flex u-flex-direction_column u-gap_space-x2 elevation-shadow-l1'
              >
                <SubtypeCard />

                <DifficultyEditor
                  difficulty={question.get('difficulty')}
                  error={!!errors.get('difficulty')}
                  errorMessage={errors.get('difficulty')}
                />
              </Card>

              <SectionCard title='Prompt' fieldPredicate={(field) => field === 'prompt'}>
                <PromptSection />
              </SectionCard>

              <SectionCard
                title='Answer Options'
                fieldPredicate={(fieldToTest) => {
                  return [
                    'dropdown_text',
                    'dropdowns',
                    'inputs',
                    'sample',
                    'options',
                    'uncorrected_text',
                    'snippet_prompt',
                    'highlight_prompt',
                    'header_left',
                    'header_right',
                    'rows'
                  ].some((includedField) => {
                    return fieldToTest.startsWith(includedField);
                  });
                }}
              >
                <QuestionAnswerSection question={question} validationErrors={errors} />
              </SectionCard>

              <SectionCard
                title='Explanation of Solution'
                fieldPredicate={(field) => field === 'solution_text'}
              >
                <ExplanationSection />
              </SectionCard>

              {currentLanguage !== PRIMARY_CONTENT_LANGUAGE && (
                <SectionCard title='Supplement Translations'>
                  <SupplementTranslationPanel />
                </SectionCard>
              )}
            </>
          )}

          {/* Only show alignment and links if we are editing the English fields */}
          {currentLanguage === PRIMARY_CONTENT_LANGUAGE && (
            <>
              <SectionCard
                title='Alignment'
                className='u-display_flex u-flex-direction_column u-gap_space-x2'
              >
                <Card paddingLevel={3}>
                  <StandardSearch
                    standards={question.getStandards()}
                    subjectId={questionSet.getSubjectId()}
                    disabled={!questionSet.getId()}
                  />
                </Card>

                <Card paddingLevel={3}>
                  <QuestionTagEditor
                    tags={questionSet.getTags()}
                    onTagClick={(tag, remove = false) => {
                      callAction(questionEditorActions.UPDATE_QUESTION_SET_TAGS, {remove, tag});
                    }}
                  />
                </Card>

                <Card paddingLevel={3}>
                  <QuestionSetAlignment questionSetId={questionSet.getId()} />
                </Card>
              </SectionCard>

              <SectionCard title='Links'>
                <QuestionEditorLinks />
              </SectionCard>
            </>
          )}
        </div>
      )}
      <SupplementManagerModal />
      <ConfirmLeaveModal
        shouldPromptFunc={shouldPromptLeave}
        modalTitle='You have unsaved changes'
        modalPrompt={
          <p>
            There are unsaved changes on this question.
            <br />
            Are you sure you want to leave?
          </p>
        }
      />
    </div>
  );
};

const QuestionEditorLanguageToggle = () => {
  const {
    currentLanguage,
    setCurrentLanguage,
    dirtyTranslatedFields,
    savingTranslatedFields,
    loadingTranslations,
    translatedQuestions,
    allTranslatedSupplements
  } = useQuestionEditorV2Store(
    useShallow((state) => ({
      currentLanguage: state.currentLanguage,
      setCurrentLanguage: state.setCurrentLanguage,
      dirtyTranslatedFields: state.dirtyTranslatedFields,
      savingTranslatedFields: state.savingTranslatedFields,
      loadingTranslations: state.loadingTranslations,
      translatedQuestions: state.translatedQuestions,
      allTranslatedSupplements: state.translatedSupplements
    }))
  );

  const savePending = questionEditorStore.isSavePending() || savingTranslatedFields;
  const question = questionEditorStore.getQuestion();
  const questionSet = questionEditorStore.getQuestionSet();
  const questionSetHasChanges = questionSet && !questionSet.getChangeMap().isEmpty();
  const questionHasChanges = question && !question.getChangeMap().isEmpty();

  // default language has changes if the question has changes that are NOT to the translate and translate_supplements fields
  const defaultLanguageHasChanges =
    questionSetHasChanges ||
    (questionHasChanges &&
      Object.keys(question.getChangeMap().toJS()).some(
        (k) => k !== 'translate' && k !== 'translate_supplements'
      ));

  const questionTranslationSettingsHaveChanges =
    question &&
    Object.keys(question.getChangeMap().toJS()).some((k) =>
      ['translate', 'translate_supplements'].includes(k)
    );

  const languagesHaveChanges = SUPPORTED_CONTENT_LANGUAGES.reduce((acc, lang) => {
    if (lang === PRIMARY_CONTENT_LANGUAGE) {
      return {...acc, [lang]: defaultLanguageHasChanges};
    }
    const relevantDirtyFields = dirtyTranslatedFields.filter((field) => field.language === lang);
    return {
      ...acc,
      [lang]: relevantDirtyFields.length > 0 || questionTranslationSettingsHaveChanges
    };
  }, {} as Record<SupportedContentLanguage, boolean>);

  const languagesHaveUnpublishedTranslations = ALTERNATE_CONTENT_LANGUAGES.reduce((acc, lang) => {
    const translatedQuestion = translatedQuestions[lang];
    const translatedSupplements = allTranslatedSupplements[lang];
    const questionHasUnpublishedTranslations =
      !!translatedQuestion &&
      translatedQuestion.translated_fields.some((f) => f.status !== 'published');

    const supplementsHaveUnpublishedTranslations =
      !!translatedSupplements &&
      translatedSupplements.some((supplement) =>
        supplement.translated_fields.some((f) => f.status !== 'published')
      );

    return {
      ...acc,
      [lang]: questionHasUnpublishedTranslations || supplementsHaveUnpublishedTranslations
    };
  }, {} as Record<AlternateContentLanguage, boolean>);

  return (
    <LanguageToggle
      disabled={savePending || loadingTranslations}
      currentLanguage={currentLanguage}
      setCurrentLanguage={setCurrentLanguage}
      languagesHaveChanges={languagesHaveChanges}
      languagesHaveUnpublishedTranslations={languagesHaveUnpublishedTranslations}
    />
  );
};

const EditPageV1Presentation = ({
  params,
  togglePreviewMode,
  canEditQuestionSet
}: EditPagePresentationProps) => {
  return (
    <div className='edit-page'>
      <div className='edit-page__actions-wrapper'>
        {sessionStore.isSuper() && (
          <div className='edit-page-author-attribution'>
            <AttributionInfo questionSetId={params.questionSetId} />
          </div>
        )}
        <QuestionSetActions
          togglePreviewMode={togglePreviewMode}
          isPreviewMode={questionEditorStore.isPreviewMode() || !canEditQuestionSet()}
          disablePreviewMode={!canEditQuestionSet()}
        />
        <QuestionActions />
      </div>
      <div className='edit-page__question-wrapper'>
        {questionEditorStore.isPreviewMode() || !canEditQuestionSet() ? (
          <QuestionPreview questionId={params.questionId} questionSetId={params.questionSetId} />
        ) : (
          <QuestionEditor />
        )}
      </div>
      <SupplementManagerModal />
    </div>
  );
};

export default withRouter(
  awaitMandarkQueries(
    () => ({
      queries: {
        subjectAuthorPermissions: getSubjectAuthorPermissionsQuery()
      }
    }),
    EditPage
  )
);
