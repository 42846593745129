import React from 'react';
import {fromJS} from 'immutable';
import {addToast} from '@albert-io/atomic';
import {callAction} from 'client/framework';
import Dropdown from '../../generic/DropDown.react';
import {TextAreaInput} from '../../generic/FormInputs.react';
import {hideModal} from 'client/Modals/ModalActions';
import issuesModalStore from './IssuesModalStore';
import issuesModalActions from './IssuesModalActions';
import {UserFeedbackModelV1} from 'resources/GeneratedModels/UserFeedback/UserFeedbackModel.v1';
import sessionStore from 'client/Session/SessionStore';
import Modal, {ModalContent} from 'client/Modals/Modal.react';

export const issuesModalName = 'issuesModal';

/**
  @todo: Replace this form with JSON SCHEMA
 */
export default class IssuesModal extends React.Component {
  _onChange(e) {
    const changeMap = {};
    changeMap[e.target.name] = e.target.value;
    callAction(issuesModalActions.MODIFY_ISSUE, changeMap);
  }

  _onChangeTypeDropdown(type) {
    callAction(issuesModalActions.MODIFY_ISSUE, {
      type
    });
  }

  _onChangeDeviceDropdown(reportedDevice) {
    callAction(issuesModalActions.MODIFY_ISSUE, {
      reportedDevice
    });
  }

  async saveIssue() {
    const feedback = new UserFeedbackModelV1()
      .setComment(issuesModalStore.getComment())
      // Mandark requires these fields to be lowercase
      .setReportedDevice(issuesModalStore.getReportedDevice().toLowerCase())
      .setFeedbackType(issuesModalStore.getType().toLowerCase())
      .addRelationship({
        type: 'question_v2',
        relation: issuesModalStore.getQuestionId()
      })
      .addRelationship({
        type: 'user_v2',
        relation: sessionStore.getUserId()
      });

    try {
      await feedback.save({
        payloadMutator: (payload) => {
          delete payload.data.attributes.inserted_at;
          delete payload.data.attributes.sent_email_at;
          delete payload.data.attributes.updated_at;
          delete payload.data.attributes.user_agent;
          return payload;
        }
      });

      addToast({
        message: 'Your question feedback has been received.',
        title: 'Success',
        color: 'positive'
      });
    } catch (e) {
      addToast({
        message: 'There was a problem processing your question feedback, please try again.',
        title: 'Error',
        color: 'negative'
      });
    } finally {
      hideModal(issuesModalName);
    }
  }

  render() {
    const issueTypeOptions = fromJS([
      {value: 'Typo', displayText: 'Typo'},
      {value: 'Inaccuracy', displayText: 'Inaccuracy'},
      {value: 'Image', displayText: 'Image'},
      {value: 'Formatting', displayText: 'Formatting'},
      {value: 'Suggestion', displayText: 'Suggestion'}
    ]);
    const reportedDeviceOptions = fromJS([
      {value: 'Desktop', displayText: 'Desktop'},
      {value: 'Mobile', displayText: 'Mobile'}
    ]);

    const hasIssueType = issuesModalStore.getType();
    const hasDeviceType = issuesModalStore.getReportedDevice();
    const hasComment = issuesModalStore.getComment();

    /**
      @todo: Turn this into a SimpleModal
     */
    return (
      <Modal
        modalKey={issuesModalName}
        headerString='Submit Question Feedback to Albert.io'
        headerClass='modal-feedback-header'
        modalWrapperClass='modal-feedback'
      >
        <ModalContent>
          <div>
            <Dropdown
              buttonText={issuesModalStore.getType() || 'Issue Type'}
              description='Issue Type'
              options={issueTypeOptions}
              selectCallback={this._onChangeTypeDropdown}
            />

            <Dropdown
              buttonText={issuesModalStore.getReportedDevice() || 'Reported Device'}
              description='Reported Device'
              options={reportedDeviceOptions}
              selectCallback={this._onChangeDeviceDropdown}
            />

            <div className='desc-block'>
              <TextAreaInput
                label='Description'
                className='form-input'
                name='comment'
                placeholder='Please provide a detailed description of the issue you have with the question. If this is a technical issue, please email hello@albert.io.'
                onChangeCallback={this._onChange}
              />
            </div>

            <div className='button-block'>
              <input
                type='button'
                className='feedback-submit-button'
                name='submitIssue'
                id='submitIssue'
                disabled={!hasIssueType || !hasDeviceType || !hasComment}
                onClick={this.saveIssue}
                value='Submit Issue'
              />
            </div>
          </div>
        </ModalContent>
      </Modal>
    );
  }
}
