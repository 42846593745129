import React from 'react';
import PropTypes from 'prop-types';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import ImmutablePropTypes from 'react-immutable-proptypes';

import {
  addToast,
  Anchor,
  Banner,
  Button,
  ButtonGroup,
  Heading,
  Icon,
  Card
} from '@albert-io/atomic';

import {callAction} from 'client/framework';
import sessionStore from 'client/Session/SessionStore';
import {SubjectFaqModelV1} from 'resources/GeneratedModels/SubjectFaq/SubjectFaqModel.v1';

import subjectFAQListStore from '../SubjectFAQList.store';

import subjectFAQQuestionsActions from './SubjectFAQQuestions.actions';
import subjectFAQQuestionsStore from './SubjectFAQQuestions.store';

const DragHandle = SortableHandle(() => (
  <span className='u-pad-r_2'>
    <Icon icon='arrows-v' onClick={(e) => e.stopPropagation()} />
  </span>
));

const SortableFAQList = SortableContainer(({subjectFAQs}) => {
  return (
    <ul className='subject-faq-question__list'>
      {subjectFAQs.map((subjectFAQ, index) => (
        <SortableFAQ index={index} subjectFAQ={subjectFAQ} key={subjectFAQ.getId()} />
      ))}
    </ul>
  );
});

const SortableFAQ = SortableElement(({subjectFAQ}) => {
  return (
    <li className='u-color_blue-400 u-pad-b_2 u-list-style_none'>
      <DragHandle />
      <Anchor as='span'>{subjectFAQ.getQuestion() || '(ANSWER-ONLY FAQ)'}</Anchor>
    </li>
  );
});

export default class SubjectFAQQuestions extends React.Component {
  static propTypes = {
    subjectFAQs: ImmutablePropTypes.listOf(PropTypes.instanceOf(SubjectFaqModelV1))
  };

  componentDidUpdate() {
    if (subjectFAQQuestionsStore.getShowToast() === true) {
      const error = subjectFAQQuestionsStore.getError();
      if (!error.isEmpty()) {
        addToast({
          color: 'negative',
          title: 'Subject FAQ Order Save Failed!',
          message:
            'Please try refreshing the page and rearranging again. If the issue persists, please contact the engineering team.'
        });
      } else {
        addToast({
          color: 'positive',
          title: 'Subject FAQ Order Saved!',
          message: 'Subject FAQ Order has been successfully saved!'
        });
      }
      callAction(subjectFAQQuestionsActions.SAVE_TOAST_EMITTED);
    }
  }

  setRearrangeMode = () => {
    callAction(subjectFAQQuestionsActions.SET_REARRANGE_MODE, {
      rearrangeMode: true,
      subjectFAQs: this.props.subjectFAQs
    });
  };

  discardChanges = () => {
    callAction(subjectFAQQuestionsActions.DISCARD_CHANGES);
  };

  saveFAQOrder = () => {
    callAction(subjectFAQQuestionsActions.SAVE_FAQ_ORDER);
  };

  onSortEnd({oldIndex, newIndex}) {
    if (oldIndex === newIndex) {
      return;
    }

    callAction(subjectFAQQuestionsActions.MOVE_FAQ, {oldIndex, newIndex});
  }

  generateReadOnlyQuestionsList(subjectFAQs) {
    return subjectFAQs.map((subjectFAQ) => {
      /**
       * In the read-only state, we avoid rendering empty list items.
       */
      if (!subjectFAQ.getQuestion()) {
        return null;
      }
      return (
        <li className='u-color_blue-400 u-pad-b_2' key={subjectFAQ.getId()}>
          <Anchor as='a' href={`#${subjectFAQ.getId()}`}>
            {subjectFAQ.getQuestion()}
          </Anchor>
        </li>
      );
    });
  }

  generateEditButtons() {
    return (
      <ButtonGroup align='right'>
        {subjectFAQListStore.isRearrangeMode() ? (
          <>
            <Button color='negative' onClick={this.discardChanges}>
              Discard changes
            </Button>
            <Button
              disabled={subjectFAQQuestionsStore.getSavePending()}
              onClick={this.saveFAQOrder}
            >
              Save
            </Button>
          </>
        ) : (
          <Button onClick={this.setRearrangeMode}>Rearrange FAQs</Button>
        )}
      </ButtonGroup>
    );
  }

  render() {
    const isRearrangeMode = subjectFAQListStore.isRearrangeMode();
    const sortedSubjectFAQs =
      isRearrangeMode === true
        ? subjectFAQQuestionsStore.getOrderedSubjectFAQList()
        : this.props.subjectFAQs;

    return (
      <Card paddingLevel={4}>
        <Heading as='h2'>Frequently Asked Questions</Heading>
        {sessionStore.isSuper() && sortedSubjectFAQs.size === 0 ? (
          <Banner>
            <Banner.Icon icon='exclamation' /> No FAQs have been created for this subject... yet!
            You can only see this message (and page) based on your elevated level of permissions.
          </Banner>
        ) : null}
        {isRearrangeMode === true ? (
          <SortableFAQList
            onSortEnd={this.onSortEnd}
            subjectFAQs={sortedSubjectFAQs}
            useDragHandle
          />
        ) : (
          <ul className='u-pad-l_4'>{this.generateReadOnlyQuestionsList(sortedSubjectFAQs)}</ul>
        )}
        {sessionStore.isSuper() && sortedSubjectFAQs.size > 1 ? this.generateEditButtons() : null}
      </Card>
    );
  }
}
