import {fromJS} from 'immutable';
import {Store} from '../../framework';
import blockyDropdownActions from './BlockyDropdownActions';

export default class BlockyDropdownStore extends Store {
  constructor(name) {
    super(name);
    this.initialData = fromJS({
      isOpen: false,
      selectedItem: null,
      storeName: ''
    });
    this.setInitialData(this.initialData);
    this.handleTargeted(blockyDropdownActions.CLOSE_DROPDOWN, this._closeDropdown);
    this.handleTargeted(blockyDropdownActions.OPEN_DROPDOWN, this._openDropdown);
    this.handleTargeted(blockyDropdownActions.RESET_STORE, this._resetStore);
    this.handleTargeted(blockyDropdownActions.SET_SELECTED_ITEM, this._setSelectedItem);
    this.handleTargeted(blockyDropdownActions.SET_STORE_NAME, this._setStoreName);
  }

  _closeDropdown() {
    this.writeData('isOpen', false);
  }

  _openDropdown() {
    this.writeData('isOpen', true);
  }

  _resetStore() {
    this.writeData(this.initialData);
  }

  _setSelectedItem(item) {
    this.writeData('selectedItem', item);
  }

  _setStoreName(storeName) {
    this.writeData('storeName', storeName);
  }

  getSelectedItem() {
    return this.readData('selectedItem');
  }

  getStoreName() {
    return this.readData('storeName');
  }

  isDropdownOpen() {
    return this.readData('isOpen');
  }
}
