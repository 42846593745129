import ClipboardJS from 'clipboard';
import {v4 as uuid} from 'uuid';

export function copyToClipboard(contentToCopy, onSuccess = false, config = {}) {
  if (!process.env.IS_BROWSER || !ClipboardJS.isSupported()) {
    return;
  }

  const isPrevented = copyToClipboard.__isCopyEventPrevented;
  if (isPrevented) {
    copyToClipboard.allowCopy();
  }

  const id = `c2c-${uuid()}`;
  const tempButton = global.document.createElement('button');
  tempButton.id = id;
  tempButton.setAttribute('data-clipboard-text', contentToCopy);
  global.document.body.appendChild(tempButton);

  const clipboard = new ClipboardJS(`#${id}`, config);
  if (onSuccess !== false) {
    clipboard.on('success', onSuccess);
  }

  tempButton.click();
  global.document.body.removeChild(tempButton);
  clipboard.destroy();

  if (isPrevented) {
    copyToClipboard.preventCopy();
  }
}

copyToClipboard.__isCopyEventPrevented = false;

copyToClipboard.isSupported = () => process.env.IS_BROWSER && ClipboardJS.isSupported();

copyToClipboard.preventCopy = () => {
  global.document.addEventListener('copy', preventDefault);
  copyToClipboard.__isCopyEventPrevented = true;
};

copyToClipboard.allowCopy = () => {
  global.document.removeEventListener('copy', preventDefault);
  copyToClipboard.__isCopyEventPrevented = false;
};

function preventDefault(e) {
  e.preventDefault();
}
