export function isBrowser() {
  return Boolean(global.process.env.IS_BROWSER);
}

function getHostname() {
  return isBrowser() ? global.window.location.hostname : global.process.env.ALBERT_CLIENT_HOST;
}

export function getOrigin() {
  return isBrowser()
    ? global.window.location.origin
    : `https://${getEnvironmentVariable('ALBERT_CLIENT_HOST')}`;
}

/**
 * Returns the environment identifier (hostname)
 *
 * @returns {string} The identifier for the running environment.
 */
export function getEnvironment() {
  return getHostname() || 'local';
}

/**
 * `.net`, `local`, and `localhost` are the only environments we consider internal.
 *
 * @returns {boolean} Whether or no the current environment would be consided internal.
 */
export function isInternal() {
  return getEnvironment().includes('.net') === true || getEnvironment().startsWith('local');
}

/**
 * Retrieve a variable from the running environment.
 * - For Node.JS this looks in `process.env`
 * - For the browser this looks in `global.__CONSTANTS__`
 *
 * @param {string} name The name of the environment variable to retrieve.
 * @returns {*} The value found in the running environment.
 */
export function getEnvironmentVariable(name) {
  // eslint-disable-next-line no-underscore-dangle
  return isBrowser() ? global.__CONSTANTS__[name] : global.process.env[name];
}

/**
 * Returns the Mandark host based on the running environment (`EXTERNAL` for browsers, `INTERNAL` for Node.JS)
 *
 * @returns {string} The full host to be used in the running environment.
 */
export function getHost() {
  if (process.env.NODE_ENV === 'development') {
    // if NODE_ENV === 'development', this means we're running dev server against localhost
    return isBrowser() ? `/api` : `http://localhost:8887/api`;
  }
  // any other value for NODE_ENV means that the application is actually hosted
  return isBrowser() ? process.env.MANDARK_EXTERNAL_ORIGIN : process.env.MANDARK_INTERNAL_ORIGIN;
}

/**
 * A simple utility for constructing a URL to Mandrk using the running environment's host.
 *
 * @param {string} path The relative path of the endpoint.
 * @returns {string} The full Mandark URL including protocol, host, port and path.
 */
export function getMandarkURL(path = '') {
  return `${getHost()}${path}`;
}
