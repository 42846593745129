import {StateCreator} from 'zustand';
import {Map, Set} from 'immutable';
import {
  StandardSetObjectType,
  StandardSet
} from 'client/components/ContentDiscovery/StandardSelector/StandardsModal/StandardsCollection.types';
import type {ContentDiscoveryStoreState} from 'client/components/ContentDiscovery/store/ContentDiscoveryStore.types';

type StandardsState = {
  filteredStandardSets: Set<StandardSet>; // TODO: might need to deprecate
  standardCollection: Map<string, StandardSetObjectType>; // TODO add total counts?
  standardsSearchString: string;
  standardSetSearchString: string;
};

type StandardsActions = {
  setFilteredStandardSets: (filteredStandardSets) => void;
  setStandardCollection: (standardCollection) => void;
  setStandardsSearchString: (string) => void;
  setStandardSetSearchString: (string) => void;
  hasSelectedStandard: () => boolean; // TODO: refactor
  getSelectedStandardsAndSets: (standardCollection: Map<string, any>) => {
    selectedStandardIds: string[];
    selectedStandardSetIds: string[];
  };
  reset: () => void;
};

const initialState: StandardsState = {
  filteredStandardSets: Set(),
  standardCollection: Map(),
  standardsSearchString: '',
  standardSetSearchString: ''
};

export type StandardsSlice = StandardsState & StandardsActions;

export const createStandardsSlice: StateCreator<
  ContentDiscoveryStoreState,
  [['zustand/immer', never]],
  [],
  StandardsSlice
> = (set, get) => ({
  ...initialState,

  setFilteredStandardSets: (filteredStandardSets) =>
    set((state) => {
      state.standards.filteredStandardSets = filteredStandardSets;
    }),

  setStandardCollection: (standardCollection) =>
    set((state) => {
      state.standards.standardCollection = standardCollection;
    }),

  setStandardsSearchString: (string) =>
    set((state) => {
      state.standards.standardsSearchString = string;
    }),

  setStandardSetSearchString: (string) =>
    set((state) => {
      state.standards.standardSetSearchString = string;
    }),

  hasSelectedStandard: () => {
    const {standardCollection} = get().standards;
    return standardCollection.some(
      (standardSetObj) =>
        !!(standardSetObj?.get?.('isSelected') || standardSetObj?.get?.('isIndeterminate'))
    );
  },

  getSelectedStandardsAndSets: (standardCollection) => {
    const selectedStandardSetIds: string[] = [];
    const selectedStandardIds: string[] = [];

    standardCollection.forEach((standardSetObj, standardSetId) => {
      if (!standardSetId) return;

      if (standardSetObj.get('isSelected')) {
        selectedStandardSetIds.push(standardSetId);
      }
      if (standardSetObj.get('isIndeterminate')) {
        // Get selected standard IDs within the set if isIndeterminate is true
        standardSetObj.get('standards').forEach((standardObj, standardId) => {
          if (standardObj.get('isSelected')) {
            selectedStandardIds.push(standardId);
          }
        });
      }
    });
    return {selectedStandardIds, selectedStandardSetIds};
  },

  reset: () =>
    set((state) => {
      state.standards = {...state.standards, ...initialState};
    })
});
