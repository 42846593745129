import {QUESTIONSET_STATUS, QUESTIONSET_STATUSES} from 'client/constants';

const alignmentOptions = {
  EXAM: 'exam',
  GUIDE: 'guide',
  UNALIGNED: null
};

export const authoringQuestionSetExtensions = {
  getExam() {
    /**
     * Questions can only be aligned to one exam at a time. Because this is an included resource,
     * and there is no .findOne() analogue for included resources, we just return the first one in the list
     */
    return this.getExams().first();
  },
  getQuestions() {
    return this.cache('getQuestions', () => {
      return this.getAuthoringQuestions().sortBy((question) => {
        return Number.isFinite(question.getPosition()) ? question.getPosition() : 0;
      });
    });
  },
  hasMultipleQuestions() {
    return this.getMeta().getCountOfQuestions() > 1;
  },
  isFree() {
    // We'll want to add the option for these to be free in the future
    return false;
  },
  /* eslint-disable sort-keys */

  // @todo: Clean this up once status codes go away in dev.
  isRejected() {
    return this.getStatusCode
      ? this.getStatusCode() === QUESTIONSET_STATUS.REJECTED
      : this.getStatus() === QUESTIONSET_STATUSES.REJECTED;
  },
  isDraft() {
    return this.getStatusCode
      ? this.getStatusCode() === QUESTIONSET_STATUS.DRAFT_MODE
      : this.getStatus() === QUESTIONSET_STATUSES.DRAFT_MODE;
  },
  isInReview() {
    return this.getStatusCode
      ? this.getStatusCode() === QUESTIONSET_STATUS.IN_REVIEW
      : this.getStatus() === QUESTIONSET_STATUSES.IN_REVIEW;
  },
  isPublished() {
    return this.getStatusCode
      ? this.getStatusCode() === QUESTIONSET_STATUS.PUBLISHED
      : this.getStatus() === QUESTIONSET_STATUSES.PUBLISHED;
  },
  isAlignedToExam() {
    return this.getCategory() === alignmentOptions.EXAM;
  },
  isAlignedToGuide() {
    return this.getCategory() === alignmentOptions.GUIDE;
  },
  isUnaligned() {
    return this.getCategory() === alignmentOptions.UNALIGNED;
  },
  alignToExam() {
    return this.setCategory(alignmentOptions.EXAM);
  },
  alignToGuide() {
    return this.setCategory(alignmentOptions.GUIDE);
  },
  unalign() {
    return this.setCategory(alignmentOptions.UNALIGNED);
  }
};
