import React from 'react';
import PropTypes from 'prop-types';

import awaitMandarkQueries from 'lib/hocs/awaitMandarkQueries';

import {getModelForResourceType} from 'resources/modelRegistry';
import {resource} from '@albert-io/json-api-framework/request/builder';
import {Grid} from '@albert-io/atomic';
import {refreshSession} from '@albert-io/mandark/authentication';

import {history} from 'client/history';

import ChangePassword from 'client/components/ChangePassword/ChangePassword.react';

/**
 * ChangePasswordLandingPage
 * ==============
 *
 * The ChangePassword component appears on the landing page for
 * users whose token requires them to update their password.
 *
 * It returns an instance of ChangePassword, which returns an
 * instance of ResetPassword but with the addition of an "Old
 * Password" field, plus the necessary handlers for changes,
 * successful saving, and errors.
 *
 * When the user successfully updates their password, the below
 * handleSubmitCallback function will clear their token, refresh it
 * with a new one, and redirect them to the classes dashboard.
 */

function handleSubmitCallback() {
  refreshSession().then(() => {
    history.pushState(null, '/classes');
  });
}

const ChangePasswordLandingPage = (props) => {
  const isSchoolManaged = props.student.getIn(['meta', 'is_managed_user'], false);
  return (
    <Grid.Container>
      <Grid.Row justify='center'>
        <Grid.Col all={6} s={12}>
          <ChangePassword
            hideCloseBtn
            legend='Set your new password so you can keep learning!'
            onSubmitCallback={handleSubmitCallback}
            shadow='default'
            checkOldPassword={!isSchoolManaged}
            {...props}
          />
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
  );
};

ChangePasswordLandingPage.propTypes = {
  userId: PropTypes.string,
  student: PropTypes.instanceOf(getModelForResourceType('student_v2'))
};

export default awaitMandarkQueries(
  (props) => ({
    queries: {
      student: resource('students_v2')
        .mandarkEndpoint(['students_v2', props.userId])
        .withMeta('student_v2')
        .customQuery({
          meta: {
            context: {
              show_is_managed_user: true
            }
          }
        })
    },
    loadingElement: null
  }),
  ChangePasswordLandingPage
);
