// @flow
import {supplementRegexGlobal} from 'generic/MarkdownRendererV2/rules/supplementRules';
import makeConstants from 'lib/makeConstants';

/**
 * Due to how we're rendering supplements, when copying or cutting text, the text that was
 * copied to the clipboard would have line breaks between supplements. This function is used by
 * the copy and cut handlers to ensure that the text we copied is exactly as it is in the plain,
 * undecorated text.
 */
export function getNormalizedSelection(editorState: EditorState): {
  selectionStart: number,
  selectionEnd: number
} {
  const content = editorState.getCurrentContent();
  const selection = editorState.getSelection();

  let anchorKey = selection.getAnchorKey();
  let selectionOffset = 0;
  while (content.getKeyBefore(anchorKey)) {
    // $FlowFixMe
    anchorKey = content.getKeyBefore(anchorKey);
    // The + 1 is to account for the newline character
    // $FlowFixMe
    selectionOffset += content.getBlockForKey(anchorKey).getLength() + 1;
  }

  return {
    selectionStart: selection.getStartOffset() + selectionOffset,
    selectionEnd: selection.getEndOffset() + selectionOffset
  };
}

/**
 * Converts text (e.g. the prompt text on a question) to the shape that is required
 * by Draft.js in order to create a ContentState.
 */
export function convertTextToRawContent(props: {value: ?string | ?null}): {
  blocks: Array<{
    offset: number,
    length: number,
    key: string
  }>,
  entityMap: {
    type: string,
    mutability: string
  }
} {
  return (props.value || '').split(/\n/).reduce(
    (acc, currentLine) => {
      const entityRanges = [];
      let match;

      while ((match = supplementRegexGlobal.exec(currentLine)) !== null) {
        // $FlowFixMe
        const string = match[0];
        const length = string.length;
        // $FlowFixMe
        const offset = match.index;
        entityRanges.push({
          offset,
          length,
          key: string
        });
        acc.entityMap[string] = {
          type: 'SUPPLEMENT',
          mutability: 'IMMUTABLE'
        };
      }

      acc.blocks.push({
        text: currentLine,
        type: 'unstyled',
        entityRanges
      });
      return acc;
    },
    {
      // $FlowFixMe
      blocks: [],
      // $FlowFixMe
      entityMap: {}
    }
  );
}

function findWithRegex(regex, contentBlock, callback) {
  const text = contentBlock.getText();
  let matchArr;
  let start;
  while ((matchArr = regex.exec(text)) !== null) {
    // $FlowFixMe
    start = matchArr.index;
    // $FlowFixMe
    callback(start, start + matchArr[0].length);
  }
}

/**
 * Given a ContentBlock, this returns an array of objects containing each entity's start and end index
 */
export function getEntityIndicesForBlock(block: ContentBlock): Array<{[string]: number}> {
  const blockText = block.getText();
  const entities = [];
  let match;

  while ((match = supplementRegexGlobal.exec(blockText)) !== null) {
    entities.push({
      startIndex: match.index,
      endIndex: match.index + match[0].length
    });
  }

  return entities;
}

/**
 * Used by Draft.js to create content entities from supplement strings.
 */
export function supplementStrategy(contentBlock: ContentBlock, callback: () => void) {
  findWithRegex(supplementRegexGlobal, contentBlock, callback);
}

export const displayAsOptions = makeConstants('textarea', 'input');
